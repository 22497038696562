import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import AccessDenied from "../../AccessDenied";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../Loaders/TableLoader";
import Contact from "./Contact";
import UsePostData from "../../../../CustomHooks/usePostData";
import Tagify from '@yaireo/tagify';
import CustomDoc from "../../../../CustomHooks/CustomDoc";
import ProductsUrl from "../../Docs/Docsdata";
import swal from "sweetalert";
import { InputValidation, HandleKeyDown } from "../../utils/input-validation/InputValidation";


export default function MonitoringAlerts(props) {
  let navigate = useNavigate()
  const [dataFetched, setDataFetched] = useState(false);
  const [access, setAccess] = useState(true);
  const [perPage, setPerPage] = useState(5);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [activePage, setActivePage] = useState(0)
  const [alertData, setAlertData] = useState(null);
  const [dummyAlertData, setDummyAlertData] = useState(null);
  const [marginBottom, setMarginBottom] = useState(window.innerWidth >= 768 ? '' : '3rem');
  const [width, setWidth] = useState(window.innerWidth >= 768 ? '40%' : '70%');
  const dataFetchedRef = useRef(false);
  // const modalRef = useRef(null); 

  useEffect(() => {
    const handleResize = () => {
      setMarginBottom(window.innerWidth >= 768 ? '' : '4rem');
      setWidth(window.innerWidth >= 768 ? '40%' : '70%')
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  // useEffect(() => {
  //   const modalElement = modalRef.current;

  //   if (!modalElement) {
  //     console.error('Modal element is not defined.');
  //     return;
  //   }

  //   // Ensure Bootstrap Modal library is available
  //   if (typeof window.bootstrap === 'undefined') {
  //     console.error('Bootstrap library is not loaded.');
  //     return;
  //   }

  //   const bootstrapModalInstance = new window.bootstrap.Modal(modalElement);

  //   const handleHidden = (e) => {
  //     const mp = document.getElementById('client');
  //     if (mp) mp.value = "";
  //   };

  //   modalElement.addEventListener('hidden.bs.modal', handleHidden);

  //   return () => {
  //     if (modalElement) {
  //       modalElement.removeEventListener('hidden.bs.modal', handleHidden);
  //       bootstrapModalInstance.dispose();
  //     }
  //   };
  // }, []);

  const getAlert = async () => {
    const dt = toast;
    const data = await UseApi("get", "alert", "");
    if (data) {
      setDataFetched(true);
    }
    if ("status" in data) {
      if (data.status == "error") {
        toast.update(dt, {
          render: data.message,
          type: "warning",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (data.message == "Permission denied.") {
          setAccess(false);
        } else {
          setAccess(true)
        }
        setAlertData(null);
        setDummyAlertData(null)
        return;
      }
    }
    if (Object.keys(data).length === 0) {
      setAlertData(null);
      setAccess(true)
      setDummyAlertData(null)
      toast.update(dt, {
        render: "no record found",
        type: "warning",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setAccess(true)
      var alertData = data.alerts;
      setAlertData(alertData.reverse());
      setDummyAlertData(alertData.reverse())
      toast.dismiss();
    }
  };
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getAlert();
  }, []);

  function formatTypeName(inputString) {
    // Split the string by underscores
    var parts = inputString.split('_');

    // Capitalize the first letter of each part and join with a space
    var resultString = parts.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');

    return resultString;
  }

  const deleteDatabaseUser = async (dbid) => {
    var submitBtn = document.querySelector("#delete-btn-" + dbid);

    if (submitBtn) {

      toast.dismiss();
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this Monitoring!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {
            if (submitBtn) {
              var preContent = submitBtn.innerHTML;
              submitBtn.classList.add("disabled");
              submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
              const dt = toast.loading("Please wait...");
              const responseData = await UsePostData('delete', 'alert/' + dbid + '/delete', "");
              if (responseData) {
                submitBtn.classList.remove("disabled");
                submitBtn.innerHTML = preContent;
                if (responseData.status === 'error') {
                  toast.update(dt, {
                    render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
                  });
                } else if (responseData.status === 'success') {
                  toast.update(dt, {
                    render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
                  });
                  getAlert();
                }
              }
            }
          }
        })
    }
  }

  function ProductInfo({ productdata }) {
    return (
      <>
        {productdata != null && Object.keys(productdata).length != 0 ? (productdata && productdata.map((value, index) => (
          <tr>
            <td className="text-start ps-9">
              <div className=" align-items-center">
                {" "}
                <div className="d-flex justify-content-start flex-column">
                  {" "}
                  {/* <a onClick={(e) => ModifyAlert(value.id)} className="text-dark fw-bold text-hover-primary fs-6 sphide">
                    {value.name}
                  </a> */}
                  <div className="text-dark fw-bold fs-6 sphide">
                    {value.name}
                  </div>
                </div>{" "}
              </div>
            </td>
            <td className="text-start">
              <div className=" align-items-start">
                <div className="d-flex justify-content-start flex-column">
                  <a className=" fw-bold d-block fs-6 text-gray-800">
                    {formatTypeName(value.type)}
                  </a>
                </div>
              </div>
            </td>
            {/* <td className="text-start">
              {value?.ref_ids?.includes(',') ? value?.ref_ids?.split(',')?.map((value1) => {
                return (
                  <div className=" align-items-start">
                    <div className="d-flex justify-content-start flex-column">
                      <a className=" fw-bold d-block fs-6" onClick={() =>handleChangeRoute(value1,"manage")}>
                        {value1}
                      </a>
                    </div>
                  </div>
                )
              })
              :
              <div className=" align-items-start">
              <div className="d-flex justify-content-start flex-column">
                <a className=" fw-bold d-block fs-6" onClick={() =>handleChangeRoute("","all")}>
                  {value.ref_ids}
                </a>
              </div>
            </div>
            }
            </td> */}
            <td className="text-center">
              <div className=" align-items-center">
                <div className="d-flex justify-content-center flex-column">
                  <a className=" text-gray-700 fw-bold d-block fs-6">
                    {value.compare}
                  </a>
                </div>
              </div>
            </td>
            <td className="text-center">
              <div className=" align-items-center">
                <div className="d-flex justify-content-center flex-column">
                  <a className=" fw-bold badge badge-primary d-block fs-6 w-50">
                    {value.value}
                  </a>
                </div>
              </div>
            </td>
            <td className="text-center">
              <div className=" align-items-center">
                <div className="d-flex justify-content-center flex-column">
                  <a className=" text-gray-700 fw-bold d-block fs-6">
                    {value.for}
                  </a>
                </div>
              </div>
            </td>
            <td className="text-center d-none">
              <div className="d-flex justify-content-start flex-column">
                {value.status == 1 ?
                  <a className=" btn btn-sm btn-success">Active</a>
                  :
                  <a className=" btn btn-sm btn-warning">Pending</a>
                }
              </div>

            </td>
            <td className="text-end pe-10 sphide d-none">
              {/* <div className="d-flex justify-content-center flex-shrink-0"> */}
              {" "}
              {/* <a onClick={(e) => ModifyAlert(value.id)} id={"modify-btn-" + value.id} className="btn btn-sm btn-primary">
                 Update
              </a> */}
              {/* <a onClick={(e) => deleteDatabaseUser(value.id)} id={"delete-btn-" + value.id} className="btn  btn-icon btn-color-danger btn-active-light-danger">
                <span class="svg-icon svg-icon-1"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black"></path><path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black"></path><path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black"></path></svg></span>
              </a> */}
              {/* </div>{" "} */}
            </td>

            <td className="text-end w-100px" >
              <div className="d-flex justify-content-end">
                <a onClick={(e) => ModifyAlert(value.id)} id={"modify-btn-" + value.id} className="btn btn-sm btn-primary me-5" style={{ width: '6.3rem' }}>
                  Update
                </a>
                <a
                  onClick={(e) => deleteDatabaseUser(value.id)}
                  id={"delete-btn-" + value.id}
                  className="btn btn-sm btn-danger sphide"
                  style={{ width: '6.3rem' }}
                >
                  Delete
                </a>
              </div>
            </td>
          </tr>
        ))) : (<>
          <tr>
            {/* <td colspan="12" className="dataTables_empty" id="loader"> */}
            {/* <div className="loading-spinner"></div> */}
            {/* <TableLoader /> */}
            {/* </td> */}
            <td colspan="12" className="" id="no_record" valign="top">
              <div className="d-flex flex-column flex-center">
                <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
              </div>
            </td>
          </tr>
        </>)}

      </>
    )
  }

  const handleSearch = (text) => {
    const endOffset = itemOffset + perPage;
    const data = alertData.filter(row => {
      return (
        (row.name && row.name.toString().toLowerCase().includes(text.toLowerCase())) ||
        (row.type && row.type.toString().toLowerCase().includes(text.toLowerCase()))
      )
    });
    setDummyAlertData(data);;
    setPageCount(Math.ceil(data.length / perPage));
    // }
  }

  function PaginatedItems({ itemsPerPage, ProductData }) {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(ProductData.length / itemsPerPage));
    const handlePageClick = (event) => {
      setActivePage((old) => old = event.selected);
      const newOffset = event.selected * itemsPerPage % ProductData.length;
      setItemOffset(newOffset);
    };

    return (
      <>
        <ProductInfo productdata={ProductData.slice(itemOffset, endOffset)} />
        <td colspan="12" className="ps-3">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            initialPage={activePage}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </td>
      </>
    );
  }
  const [cloudInfo, setCloudInfo] = useState(null);
  const [contactData, setContactData] = useState([]);
  const getContact = async () => {
    toast.dismiss();
    const data = await UseApi("get", "alert/contact/list", "");
    // //console.log(data);
    if ('status' in data) {
      if (data.status == "error") {
        setContactData(null);
        setDataFetched(true)
        if (data.message == "Permission denied.") {
          setAccess(false);
        } else {
          setAccess(true)
        }
        // loaderFunc();
        return;
      }
    }
    if (data.contacts.length === 0) {
      setContactData(null);
      setAccess(true)
      setDataFetched(true)
      // loaderFunc();
    } else {
      var cData = [];
      setAccess(true)
      setDataFetched(true)
      data?.contacts?.map((value, index) => {
        cData.push(value.name + " (" + value.id + ")");
      })
      setContactData(cData);

    }
  };
  const getCloud = async () => {
    const clouds = await UseApi('get', 'cloud', '');
    if (clouds) {
      if (clouds.message == "Permission denied.") {
        setAccess(false);
        setDataFetched(true)
      } else {
        setAccess(true)
        setDataFetched(true)
        var cData = [];

        clouds.cloud.map((value, index) => {

          cData.push(value.hostname + " (" + value.cloudid + ")");
        })
        // //console.log(cData);
        setCloudInfo(cData);
      }
    }
  };

  useEffect(() => {
    getCloud();
    getContact();
  }, [])


  const addCloudAlertsRef = useRef(null);
  const cloudTagifyRef = useRef(null);

  useEffect(() => {
    if (cloudInfo) {
      var arr = ['Select All'];
      var cloudDataArr = arr.concat(cloudInfo);

      const tagifyOptions = {
        whitelist: cloudDataArr,
        maxTags: 20,
        dropdown: {
          maxItems: 20,
          classname: '',
          enabled: 0,
          closeOnSelect: false,
        },
      };

      const tagifyInstance = new Tagify(addCloudAlertsRef.current, tagifyOptions);

      // Store the Tagify instance in the component's state
      cloudTagifyRef.current = tagifyInstance;
    }
  }, [cloudInfo]);


  const updateCloudTagifyOnChange = (e) => {
    var addCloudInput = document.querySelector("#add_cloud_alert");

    if (addCloudInput && cloudInfo) {
      const value = e.target.value;

      // Handle the "Select All" case
      if (isSubstringPresent(value, "Select All")) {
        cloudTagifyRef.current.removeTags(cloudInfo); // Remove all cloud tags
        addCloudInput.value = "all"; // Set the hidden input to "all"
      } else {
        // Remove "Select All" tag if present
        cloudTagifyRef.current.removeTags(["Select All"]);

        if (value) {
          var cloudData = JSON.parse(value);
          var cloudIds = cloudData
            .map((item) => {
              var match = item.value.match(/\((\d+)\)/);
              return match ? match[1] : null;
            })
            .filter(Boolean);

          var concatenatedCloudIds = cloudIds.join(',');

          // Set the hidden input value to the concatenated cloud IDs
          addCloudInput.value = concatenatedCloudIds;
        } else {
          // If no tags are present, clear the hidden input value
          addCloudInput.value = '';
        }
      }
    }
  }

  const addContactAlertsRef = useRef(null);
  const contactTagifyRef = useRef(null);

  useEffect(() => {
    if (contactData && addContactAlertsRef.current) {
      var arr = ['Select All'];
      var contactDataArr = arr.concat(contactData);

      const tagifyOptions = {
        whitelist: contactDataArr,
        maxTags: 20,
        enforceWhitelist: true,
        skipInvalid: true,
        dropdown: {
          maxItems: 20,
          classname: '',
          enabled: 0,
          closeOnSelect: false,
        },
      };

      const tagifyInstance = new Tagify(addContactAlertsRef.current, tagifyOptions);

      // Store the Tagify instance in the component's state
      contactTagifyRef.current = tagifyInstance;
    }
  }, [contactData]);



  const updateContactTagifyOnChange = (e) => {
    var addContactInput = document.querySelector("#add_contact_alert");

    if (addContactInput && contactData) {
        if (isSubstringPresent(e.target.value, "Select All")) {
            // Filter out duplicate tags before adding them
            if (contactTagifyRef.current) {
                contactTagifyRef.current.removeTags(contactData);
            }
            addContactInput.value = "all";
        } else {
            if (e.target.value && addContactInput) {
                var cloudData = JSON.parse(e.target.value);
                var cloudIds = cloudData.map((item) => {
                    var match = item.value.match(/\((\d+)\)/);
                    return match ? match[1] : null;
                }).filter(Boolean);

                var concatenatedCloudIds = cloudIds.join(',');

                addContactInput.value = concatenatedCloudIds;
            } else {
                // When no value is present, clear the addContactInput
                addContactInput.value = "";
            }
        }
    }

    if (contactTagifyRef.current) {
        // Update the Tagify whitelist and refresh the instance
        var arr = ['Select All'];
        var contactDataArr = arr.concat(contactData);
        contactTagifyRef.current.settings.whitelist = contactDataArr;

        // Handle dropdown loading
        contactTagifyRef.current.loading(true)?.dropdown?.hide?.call(contactTagifyRef.current);
        contactTagifyRef.current.loading(false)?.dropdown?.show?.call(contactTagifyRef.current);

        // Clear the Tagify input if the tag was unselected
        if (!e.target.value) {
            contactTagifyRef.current.removeAllTags();
        }
    }
};

// Ensure that `contactTagifyRef.current` is initialized before attaching event listeners
useEffect(() => {
    if (contactTagifyRef.current) {
        contactTagifyRef.current.on('remove', (e) => {
            if (contactTagifyRef.current) {
                contactTagifyRef.current.removeTags(e.detail.tag.value);
            }
        });
    }
}, [contactTagifyRef.current]); // This useEffect runs when contactTagifyRef.current changes


  // var add_contactTagify = document.querySelector("#add_contacts_alerts");
  // useEffect(()=>{
  //   // var arr=['Select All'];
  //     // var contactDataArr =arr.concat(contactData);
  //   const contactTagify=new window.Tagify(add_contactTagify, {
  //       whitelist: contactData,
  //       maxTags: 20,
  //       dropdown: {
  //           maxItems: 20,          
  //           classname: "",  
  //           enabled: 0,              
  //           closeOnSelect: false    
  //       }
  //   }); 

  //   },[contactData]) 

  const handleChangeAlertType = () => {
    var add_type = document.querySelector("#add_type_alert");
    var add_compare_sec = document.querySelector("#add_compare_alert_sec");
    var add_value_sec = document.querySelector("#add_value_alert_sec");
    if (add_type && add_compare_sec && add_value_sec) {
      if (add_type.value == "nodata") {
        add_value_sec.classList.add("d-none");
        add_compare_sec.classList.add("d-none");
      } else {
        add_value_sec.classList.remove("d-none");
        add_compare_sec.classList.remove("d-none");
      }
    }
  }
  const addNewAlert = async () => {
    toast.dismiss()
    var submitBtn = document.querySelector("#addnewcontact-btn");
    var add_name = document.querySelector("#add_name_alert");
    var add_type = document.querySelector("#add_type_alert");
    var add_compare = document.querySelector("#add_compare_alert");
    var add_value = document.querySelector("#add_value_alert");
    var add_duration = document.querySelector("#add_duration_alert");
    var add_cloud = document.querySelector("#add_cloud_alert");
    var add_contact = document.querySelector("#add_contact_alert");
    var add_status = document.querySelector("#add_status_alert");
    var ref_type = document.querySelector("#ref_type")
    var aname = "";
    var atype = "";
    var acompare = "";
    var avalue = "";
    var aduration = "";
    var acloud = "";
    var acontact = "";
    var astatus = "";
    if (add_name && add_type && add_compare && add_value && add_duration && add_cloud && add_contact && add_status) {

      if (add_name.value == "") {
        const dt = toast.loading("Please wait...");
        setTimeout(() => {
          toast.update(dt, {
            render: "Name must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        }, 2000)
        return;
      } else {
        aname = add_name.value;
      }
      if (add_type.value == "") {
        const dt = toast.loading("Please wait...");
        setTimeout(() => {
          toast.update(dt, {
            render: "Type must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        }, 1500)
        return;
      } else {
        atype = add_type.value;
        if (atype != "nodata") {
          if (add_compare.value == "") {
            const dt = toast.loading("Please wait...");
            setTimeout(() => {
              toast.update(dt, {
                render: "Comparison Operator must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
              });
            }, 1500)
            return;
          } else {
            acompare = add_compare.value;
          }

          if (add_value.value == "") {
            const dt = toast.loading("Please wait...");
            setTimeout(() => {
              toast.update(dt, {
                render: "Comparison value must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
              });
            }, 1500)
            return;
          } else {
            avalue = add_value.value;
          }
        }
      }
      if (add_duration.value == "") {
        const dt = toast.loading("Please wait...");
        setTimeout(() => {
          toast.update(dt, {
            render: "Duration must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        }, 1500)
        return;
      } else {
        aduration = add_duration.value;
      }

      if (add_cloud.value == "") {
        const dt = toast.loading("Please wait...");
        setTimeout(() => {
          toast.update(dt, {
            render: "Please attach Cloud!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        }, 1500)
        return;
      } else {
        acloud = add_cloud.value;
      }
      if (add_contact.value == "") {
        const dt = toast.loading("Please wait...");
        setTimeout(() => {
          toast.update(dt, {
            render: "Please attach Contact!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        }, 1500)
        return;
      } else {
        acontact = add_contact.value;
      }

      if (add_status.value == "") {
        const dt = toast.loading("Please wait...");
        setTimeout(() => {
          toast.update(dt, {
            render: "Please provide status", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        }, 1500)
        return;
      } else {
        astatus = add_status.value;
      }

      if (submitBtn) {
        var preContent = submitBtn.innerHTML;
        submitBtn.classList.add("disabled");
        submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
        const dt = toast.loading("Please wait...");
        const responseData = await UsePostData('post', 'alert', { "name": aname, "ref_type": "cloud", "type": atype, "compare": acompare, "value": avalue, "for": aduration, "contacts": acontact, "status": astatus, "ref_ids": acloud });
        if (responseData) {
          submitBtn.classList.remove("disabled");
          submitBtn.innerHTML = preContent;
          if (responseData.status === 'error') {
            toast.update(dt, {
              render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
          } else if (responseData.status === 'success') {
            toast.update(dt, {
              render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
           
            openDrawer();
           
          
            getAlert();
            add_name.value = "";
            add_type.value = "cpu";
            add_compare.value = "below";
            add_value.value = "";
            add_duration.value = "5m";
            add_cloud.value = "";
            add_contact.value = "";
            add_status.value = "";
            ref_type.value = "cloud"
          }
          // var kmodal = document.querySelector("#close_ssh");
          // if (kmodal) {
          //   kmodal.click();
          // }
          // if (props && props.refreshMonitor) {
          //   props.refreshMonitor();
          // }
            // const subForm = document.querySelector('#monitor-modal');
            //  subForm.reset();
          
        }
      }
    }
  }


  function isSubstringPresent(mainString, substring) {
    // Use indexOf() method to check if the substring is present
    // If present, indexOf() returns the starting index of the substring, otherwise returns -1
    return mainString.indexOf(substring) !== -1;
  }

  function addNewCloud(e) {
    var addCloudInput = document.querySelector("#add_cloud_alert");
    if (addCloudInput && cloudInfo) {
      if (isSubstringPresent(e.target.value, "Select All")) {
        var cloudIds = cloudInfo.map(item => {
          // Extract numeric portion from the value (assuming it's always in parentheses)
          var match = item.match(/\((\d+)\)/);
          return match ? match[1] : null;
        }).filter(Boolean); // Filter out null values
        // Concatenate the cloud IDs into a comma-separated string
        var concatenatedCloudIds = cloudIds.join(',');

        // Set the concatenated string as the value of the add_cloud input
        addCloudInput.value = concatenatedCloudIds;
        // createCloudTagify("all");
      } else {
        // createCloudTagify("custom");
        if (e.target.value && addCloudInput) {
          var cloudData = JSON.parse(e.target.value);
          var cloudIds = cloudData.map(item => {
            // Extract numeric portion from the value (assuming it's always in parentheses)
            var match = item.value.match(/\((\d+)\)/);
            return match ? match[1] : null;
          }).filter(Boolean); // Filter out null values

          // Concatenate the cloud IDs into a comma-separated string
          var concatenatedCloudIds = cloudIds.join(',');

          // Set the concatenated string as the value of the add_cloud input
          addCloudInput.value = concatenatedCloudIds;

          // //console.log(concatenatedCloudIds);
        }
      }
    }
  }


  function addNewContact(e) {
    var addCloudInput = document.querySelector("#add_contact_alert");

    if (e.target.value && addCloudInput) {
      var cloudData = JSON.parse(e.target.value);
      var cloudIds = cloudData.map(item => {
        // Extract numeric portion from the value (assuming it's always in parentheses)
        var match = item.value.match(/\((\d+)\)/);
        return match ? match[1] : null;
      }).filter(Boolean); // Filter out null values

      // Concatenate the cloud IDs into a comma-separated string
      var concatenatedCloudIds = cloudIds.join(',');

      // Set the concatenated string as the value of the add_cloud input
      addCloudInput.value = concatenatedCloudIds;

      // //console.log(concatenatedCloudIds);
    }
  }

  const openDrawer = () => {
    var add_name = document.querySelector("#add_name_alert");
    var add_type = document.querySelector("#add_type_alert");
    var add_compare = document.querySelector("#add_compare_alert");
    var add_value = document.querySelector("#add_value_alert");
    var add_duration = document.querySelector("#add_duration_alert");
    var add_cloud = document.querySelector("#add_cloud_alert");
    var add_contact = document.querySelector("#add_contact_alert");
    var ref_type = document.querySelector("#ref_type")
    var drawerElement = document.querySelector("#monitor-modal");
    var drawer_overlay = document.querySelector("#drawer_overlay");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
        drawerElement.style.width = "100%";
      } else {
        drawerElement.style.width = "60%";
      }
      drawer_overlay.classList.toggle("d-none");
    }
    add_name.value = ''
    add_type.value = 'cpu'
    add_compare.value = 'below'
    add_value.value = ''
    add_contact.value = ''
    ref_type.value = 'cloud'
    add_cloud.value = ''
    add_duration.value = '5m'
  };

  const openDrawerUpdate = () => {

    var drawerElement = document.querySelector("#monitor-modal_update");
    var drawer_overlay = document.querySelector("#drawer_overlay_update");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
        drawerElement.style.width = "100%";
      } else {
        drawerElement.style.width = "60%";
      }
      drawer_overlay.classList.toggle("d-none");
    }
    // updateCloudTagify()
    // updateCloudAlertsRef.current = true
    // updateContactAlertsRef.current = true
  };

  const [alertInfo, setAlertInfo] = useState(null);
  console.log(alertData);

  const ModifyAlert = async (alertid) => {
    var submitBtn = document.querySelector("#modify-btn-" + alertid);
    if (submitBtn) {
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';

      const dt = toast;
      setAlertInfo(null);
      // const data = await UseApi("get", "alert", "");
      if (alertData) {
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;

        alertData.map((value, index) => {
          if (value.id == alertid) {

            var update_alert_id = document.querySelector("#update_alert_id");
            var add_name = document.querySelector("#update_name_alert");
            var add_type = document.querySelector("#update_type_alert");
            var add_compare = document.querySelector("#update_compare_alert");
            var add_value = document.querySelector("#update_value_alert");
            var add_duration = document.querySelector("#update_duration_alert");
            var add_cloud = document.querySelector("#update_cloud_alert");
            var add_contact = document.querySelector("#update_contact_alert");
            var add_status = document.querySelector("#update_status_alert");
            if (add_name && add_type && add_compare && add_value && add_duration && add_cloud && add_contact && add_status && update_alert_id) {
              add_name.value = value?.name;
              add_type.value = value?.type;
              add_compare.value = value?.compare;
              add_value.value = value?.value;
              add_duration.value = value?.for;
              add_cloud.value = value?.ref_ids;
              add_contact.value = value?.contact;
              add_status.value = value?.status;
              update_alert_id.value = alertid;
            }
            setAlertInfo(value);
            toast.dismiss();
            openDrawerUpdate();
            updateTagify();
            return;
          }
        })
      }
    }
  };


  const updateAlertByID = async () => {
    var submitBtn = document.querySelector("#updatealert-btn");
    var update_alert_id = document.querySelector("#update_alert_id");
    var add_name = document.querySelector("#update_name_alert");
    var add_type = document.querySelector("#update_type_alert");
    var add_compare = document.querySelector("#update_compare_alert");
    var add_value = document.querySelector("#update_value_alert");
    var add_duration = document.querySelector("#update_duration_alert");
    var add_cloud = document.querySelector("#update_cloud_alert");
    var add_contact = document.querySelector("#update_contact_alert");
    var add_status = document.querySelector("#update_status_alert");
    var aname = "";
    var atype = "";
    var acompare = "";
    var avalue = "";
    var aduration = "";
    var acloud = "";
    var acontact = "";
    var astatus = "";
    var aid = 0;
    console.log(add_contact.value);
    if (add_name && add_type && add_compare && add_value && add_duration && add_cloud && add_contact && add_status && update_alert_id) {
      if (update_alert_id.value == "") {
        const dt = toast.loading("Please wait...");
        setTimeout(() => {
          toast.update(dt, {
            render: "Something went wrong, Please try again later!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        }, 1500)
        return;
      } else {
        aid = update_alert_id.value;
      }
      if (add_name.value == "") {
        const dt = toast.loading("Please wait...");
        setTimeout(() => {
          toast.update(dt, {
            render: "Name must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        }, 1500)
        return;
      } else {
        aname = add_name.value;
      }
      if (add_type.value == "") {
        const dt = toast.loading("Please wait...");
        setTimeout(() => {
          toast.update(dt, {
            render: "Type must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        }, 1500)
        return;
      } else {
        atype = add_type.value;
      }
      if (add_compare.value == "") {
        const dt = toast.loading("Please wait...");
        setTimeout(() => {
          toast.update(dt, {
            render: "Comparison Operator must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        }, 1500)
        return;
      } else {
        acompare = add_compare.value;
      }

      if (add_value.value == "") {
        const dt = toast.loading("Please wait...");
        setTimeout(() => {
          toast.update(dt, {
            render: "Comparison value must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        }, 1500)
        return;
      } else {
        avalue = add_value.value;
      }

      if (add_duration.value == "") {
        const dt = toast.loading("Please wait...");
        setTimeout(() => {
          toast.update(dt, {
            render: "Duration must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        }, 1500)
        return;
      } else {
        aduration = add_duration.value;
      }

      if (add_cloud.value == "") {
        const dt = toast.loading("Please wait...");
        setTimeout(() => {
          toast.update(dt, {
            render: "Please attach Cloud!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        }, 1500)
        return;
      } else {
        acloud = add_cloud.value;
      }
      if (add_contact.value == "") {
        const dt = toast.loading("Please wait...");
        setTimeout(() => {
          toast.update(dt, {
            render: "Please attach Contact!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        }, 1500)
        return;
      } else {
        acontact = add_contact.value;
      }

      if (add_status.value == "") {
        const dt = toast.loading("Please wait...");
        setTimeout(() => {
          toast.update(dt, {
            render: "Please provide status", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        }, 1500)
        return;
      } else {
        astatus = add_status.value;
      }

      if (submitBtn) {
        var preContent = submitBtn.innerHTML;
        submitBtn.classList.add("disabled");
        submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
        const dt = toast.loading("Please wait...");
        const responseData = await UsePostData('post', 'alert/' + aid + '/update', { "name": aname, "ref_type": "cloud", "type": atype, "compare": acompare, "value": avalue, "for": aduration, "contacts": acontact, "status": astatus, "ref_ids": acloud });
        if (responseData) {
          submitBtn.classList.remove("disabled");
          submitBtn.innerHTML = preContent;
          if (responseData.status === 'error') {
            toast.update(dt, {
              render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
          } else if (responseData.status === 'success') {
            toast.update(dt, {
              render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
            openDrawerUpdate();
            getAlert();

          }
        }
      }
    }
  }


  function updateNewCloud(e) {
    var addCloudInput = document.querySelector("#update_cloud_alert");

    if (e.target.value && addCloudInput) {
      console.log(e.target.value, "targete value");
      var cloudData = JSON.parse(e.target.value);
      var cloudIds = cloudData.map(item => {
        // Extract numeric portion from the value (assuming it's always in parentheses)
        var match = item.value.match(/\((\d+)\)/);
        return match ? match[1] : null;
      }).filter(Boolean); // Filter out null values
      console.log(cloudIds, "cloud ids");
      // Concatenate the cloud IDs into a comma-separated string
      var concatenatedCloudIds = cloudIds.join(',');

      // Set the concatenated string as the value of the add_cloud input
      addCloudInput.value = concatenatedCloudIds;

      //console.log(concatenatedCloudIds);
    }
  }


  function updateNewContact(e) {
    var addCloudInput = document.querySelector("#update_contact_alert");

    if (e.target.value && addCloudInput) {
      var cloudData = JSON.parse(e.target.value);
      var cloudIds = cloudData.map(item => {
        // Extract numeric portion from the value (assuming it's always in parentheses)
        var match = item.value.match(/\((\d+)\)/);
        return match ? match[1] : null;
      }).filter(Boolean); // Filter out null values

      // Concatenate the cloud IDs into a comma-separated string
      var concatenatedCloudIds = cloudIds.join(',');

      // Set the concatenated string as the value of the add_cloud input
      addCloudInput.value = concatenatedCloudIds;

      //console.log(concatenatedCloudIds);
    }
  }




  const updateCloudAlertsRef = useRef(null);
  const updatecloudTagifyRef = useRef(null);

  const updateCloudTagify = (e) => {
    toast.dismiss()
    var addCloudInput = document.querySelector("#update_cloud_alert");
    if (addCloudInput && cloudInfo) {
      if (isSubstringPresent(e.target.value, "Select All")) {
        // Filter out duplicate tags before adding them 
        updatecloudTagifyRef.current.removeTags(cloudInfo);

        // var cloudIds = cloudInfo.map((item) => {
        //   var match = item.match(/\((\d+)\)/);
        //   return match ? match[1] : null;
        // }).filter(Boolean);

        // var concatenatedCloudIds = cloudIds.join(',');

        // addCloudInput.value = concatenatedCloudIds;
        addCloudInput.value = "all";
      } else {
        if (e.target.value && addCloudInput) {
          var cloudData = JSON.parse(e.target.value);
          var cloudIds = cloudData.map((item) => {
            var match = item.value.match(/\((\d+)\)/);
            return match ? match[1] : null;
          }).filter(Boolean);

          var concatenatedCloudIds = cloudIds.join(',');

          addCloudInput.value = concatenatedCloudIds;
        }
      }
    }

    if (updatecloudTagifyRef.current) {
      // Update the Tagify whitelist and refresh the instance
      var arr = ['Select All'];
      var cloudDataArr = arr.concat(cloudInfo);
      updatecloudTagifyRef.current.settings.whitelist = cloudDataArr;
      updatecloudTagifyRef.current.loading(true).dropdown.hide.call(updatecloudTagifyRef.current);
      updatecloudTagifyRef.current.loading(false).dropdown.show.call(updatecloudTagifyRef.current);
    }
  };

  const updateContactAlertsRef = useRef(null);
  const updatecontactTagifyRef = useRef(null);

  const updateContactTagify = (e) => {
    var addContactInput = document.querySelector("#update_contact_alert");
    if (addContactInput && contactData) {
      if (isSubstringPresent(e.target.value, "Select All")) {
        // Filter out duplicate tags before adding them 
        updatecontactTagifyRef.current.removeTags(contactData);

        // var cloudIds = cloudInfo.map((item) => {
        //   var match = item.match(/\((\d+)\)/);
        //   return match ? match[1] : null;
        // }).filter(Boolean);

        // var concatenatedCloudIds = cloudIds.join(',');

        // addCloudInput.value = concatenatedCloudIds;
        addContactInput.value = "all";
      } else {
        if (e.target.value && addContactInput) {
          var cloudData = JSON.parse(e.target.value);
          var cloudIds = cloudData.map((item) => {
            var match = item.value.match(/\((\d+)\)/);
            return match ? match[1] : null;
          }).filter(Boolean);

          var concatenatedCloudIds = cloudIds.join(',');

          addContactInput.value = concatenatedCloudIds;
        }
      }
    }

    if (updatecontactTagifyRef.current) {
      // Update the Tagify whitelist and refresh the instance
      var arr = ['Select All'];
      var contactDataArr = arr.concat(contactData);
      updatecontactTagifyRef.current.settings.whitelist = contactDataArr;
      updatecontactTagifyRef.current.loading(true).dropdown.hide.call(updatecontactTagifyRef.current);
      updatecontactTagifyRef.current.loading(false).dropdown.show.call(updatecontactTagifyRef.current);
    }
  };


  const updateTagify = () => {
    if (cloudInfo && alertData && alertInfo) {
      // alertData.map((value, index)=>{
      var update_cloud_alerts = document.querySelector("#update_cloud_alerts");
      var update_contacts_alerts = document.querySelector("#update_contacts_alerts");
      var add_cloud = document.querySelector("#update_cloud_alert");
      var add_contact = document.querySelector("#update_contact_alert");
      // if(add_cloud && add_contact && update_cloud_alerts && update_contacts_alerts){
      //    add_cloud.value=update_cloud_alerts.value;
      //    add_contact.value=update_contacts_alerts.value;
      // }
      if (update_cloud_alerts && update_contacts_alerts) {
        var alertContactArray = alertInfo?.contacts?.split(',');

        var filteredContactInfo = contactData?.filter(item => {
          var match = item?.match(/\((\d+)\)/);
          return match && alertContactArray?.includes(match[1]);
        });

        var contactString = filteredContactInfo?.map(item => item).join(',');
        update_contacts_alerts.value = contactString;

        if (alertInfo && alertInfo.contacts == "all") {
          update_contacts_alerts.value = "Select All";
        }

        if (contactData) {
          var arr = ['Select All'];
          var contactDataArr = arr?.concat(contactData);

          const tagifyOptions = {
            whitelist: contactDataArr,
            maxTags: 20,
            enforceWhitelist: true,
            skipInvalid: true, // do not remporarily add invalid tags   
            dropdown: {
              maxItems: 20,
              classname: '',
              enabled: 0,
              closeOnSelect: false,
            },
          };

          const tagifyInstance = new Tagify(updateContactAlertsRef.current, tagifyOptions);

          // Store the Tagify instance in the component's state
          updatecontactTagifyRef.current = tagifyInstance;
        }



        var alertIdsArray = alertInfo?.ref_ids.split(',');

        var filteredCloudInfo = cloudInfo?.filter(item => {

          var match = item?.match(/\((\d+)\)/);
          return match && alertIdsArray?.includes(match[1]);
        });

        var cloudString = filteredCloudInfo?.map(item => item).join(',');
        update_cloud_alerts.value = cloudString;
        if (alertInfo && alertInfo?.ref_ids == "all") {
          update_cloud_alerts.value = "Select All";
        }

        //  console.log(cloudString, "latest info");

        if (cloudInfo) {
          var arr = ['Select All'];
          var cloudDataArr = arr?.concat(cloudInfo);

          const tagifyOptions = {
            whitelist: cloudDataArr,
            maxTags: 20,
            enforceWhitelist: true,
            skipInvalid: true, // do not remporarily add invalid tags   
            dropdown: {
              maxItems: 20,
              classname: '',
              enabled: 0,
              closeOnSelect: false,
            },
          };

          const tagifyInstance = new Tagify(updateCloudAlertsRef.current, tagifyOptions);

          // Store the Tagify instance in the component's state
          updatecloudTagifyRef.current = tagifyInstance;
        }

      }

      // })
    }
  }

  useEffect(() => {
    updateTagify();
  }, [alertInfo]);

  const openDoc = () => {
    var drawerElement = document.querySelector("#monitring-doc");
    var drawer_overlay = document.querySelector("#drawer_overlay_monitring_doc");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
        drawerElement.style.width = "100%";
      } else {
        drawerElement.style.width = "90%";
      }
      drawer_overlay.classList.toggle("d-none");
    }
  };

  const openContactSec = () => {
    var contact = document.querySelector("#contact-sec");
    if (contact) {
      openDrawer();
      contact.click();
    }
  }

  if (dataFetched) {
    if (access) {
      return (
        <>
          <div className="col-xl-12">
            <div className="">
              <div className="">
                <ul className="nav nav-pills nav-pills-custom row position-relative mx-0">

                  <li className="nav-item col-6 mx-0 px-0" onClick={getAlert}>
                    <a
                      className="nav-link active d-flex justify-content-center w-100 border-0 h-100"
                      data-bs-toggle="pill"
                      href="#server-tab"
                    >
                      <span className="sphide nav-text text-gray-800 fw-bolder fs-6 mb-3">
                        Resource Alerts
                      </span>
                      <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded" />
                    </a>
                  </li>
                  <li className="nav-item col-6 mx-0 px-0">
                    <a
                      className="nav-link d-flex justify-content-center w-100 border-0 h-100"
                      data-bs-toggle="pill"
                      href="#contact-tab"
                      id="contact-sec"
                    >
                      <span className="sphide nav-text text-gray-800 fw-bolder fs-6 mb-3">
                        Monitoring Contacts
                      </span>
                      <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded" />
                    </a>
                  </li>
                  <span className="position-absolute z-index-1 bottom-0 w-100 h-4px bg-light rounded" />
                </ul>
              </div>
            </div>
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade active show" id="server-tab" role="tabpanel" >
                <div className="card card-xl-stretch mb-5 mb-xl-12">
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">
                        Resource Alerts
                        {/* <span className="svg-icon svg-icon-2 ms-3">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 84 84"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g
                            id="DBaaS-Icon-1C-BLK"
                            transform="translate(0.189181, -0.000000)"
                            fill="#a1a5b7"
                            fillRule="nonzero"
                          >
                            <path d="m66.7 48.9c-7.7 2.1-17.7 3.3-28.4 3.3s-20.8-1.2-28.4-3.3c-2.2-.6-4.2-1.3-5.9-2v22c0 5.2 15.3 9.4 34.3 9.4s34.3-4.2 34.3-9.4v-22c-1.8.8-3.7 1.4-5.9 2zm-6.3 19.8c-2.6 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6 4.6 2.1 4.6 4.6-2 4.6-4.6 4.6z" />
                            <path d="m38.3 5.1c-19 0-34.3 4.2-34.3 9.4v25.4c3.6 2.4 14.8 6.1 34.3 6.1 19.4 0 30.7-3.7 34.3-6.1v-25.4c-.1-5.2-15.4-9.4-34.3-9.4zm22.1 31.7c-2.6 0-4.6-2.1-4.6-4.6 0-2.6 2.1-4.6 4.6-4.6s4.6 2.1 4.6 4.6c.1 2.5-2 4.6-4.6 4.6zm-22.1-14.3c-20.9 0-32.3-4.9-32.3-7.4s11.4-7.4 32.3-7.4 32.3 4.9 32.3 7.4-11.5 7.4-32.3 7.4z" />
                          </g>
                        </svg>
                      </span> */}
                      </span>
                      <span className="text-muted mt-1 fw-bold fs-7">
                        Monitor your servers here.
                        <span className="text-primary ms-1 fw-bold fs-7 d-none" onClick={openDoc} style={{ cursor: 'pointer' }}>Learn more.
                        </span>
                      </span>
                    </h3>
                    <div className="card-toolbar">
                      <div className="position-relative my-1" style={{ marginRight: 20 }}>
                        <CustomDoc url={ProductsUrl[0]?.monitoring + "/getting-started/"} />
                      </div>
                      <div
                        className="position-relative my-1"
                        style={{ marginRight: 20 }}
                      >
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          data-kt-table-widget-4="search"
                          style={{ padding: '0.6rem 0.6rem' }}
                          id="searchClient"
                          onChange={e => handleSearch(e.target.value)}
                          placeholder="Search"
                        />
                      </div>

                      <div style={{ marginRight: 20 }}>
                        <div className="dataTables_length">
                          <label className="w-125px">
                            <select
                              onChange={e => setPerPage(e.target.value)}
                              style={{ padding: '0.6rem 0.6rem' }}
                              //data-control="select2"
                              className="form-select form-select-sm form-select-solid"
                              defaultValue={5}
                              id="perPage"

                            >
                              <option value={5} selected="">
                                5 Records
                              </option>
                              <option value={10}>10 Records</option>
                              <option value={25}>25 Records</option>
                              <option value={50}>50 Records</option>
                              <option value={100}>100 Records</option>
                            </select>
                          </label>
                        </div>
                      </div>
                      <a onClick={openDrawer} className="btn btn-sm btn-primary btn-active-light-primary" style={{ padding: '0.69rem 1.2rem' }}>
                        <span className="svg-icon svg-icon-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              opacity="0.5"
                              x="11.364"
                              y="20.364"
                              width={16}
                              height={2}
                              rx={1}
                              transform="rotate(-90 11.364 20.364)"
                              fill="black"
                            />
                            <rect
                              x="4.36396"
                              y="11.364"
                              width={16}
                              height={2}
                              rx={1}
                              fill="black"
                            />
                          </svg>
                        </span>
                        Create Alert
                      </a>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table
                      id="firewallTable"
                      className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
                    >
                      <thead className="bg-secondary">
                        <tr className="fw-bold ">
                          <th className="w-20 text-start ps-9">Name</th>
                          <th className="w-10 text-start">Type</th>
                          <th className="w-10 text-center d-none">ResoruceID</th>
                          <th className="w-10 text-center">Comparison</th>
                          <th className="w-10 text-start">Value</th>
                          <th className="w-10 text-center">Duration</th>
                          <th className="w-10 text-center d-none">Status</th>
                          <th className="w-20 text-end"></th>
                        </tr>
                      </thead>
                      <tbody>

                        {alertData != null ? (<>
                          {access == false ? (<>
                            <tr>
                              <td colspan={12}>
                                <AccessDenied />
                              </td>
                            </tr>
                          </>) : (<>
                            {alertData.length > 0 ? (<>
                              <PaginatedItems itemsPerPage={perPage} ProductData={dummyAlertData} />
                            </>) : (<>
                              <tr>
                                <td colSpan={12}>
                                  <div className="card-body p-0 mt-10">
                                    {/*begin::Illustration*/}
                                    <div className="text-center px-4 mt-10">
                                      <span className="mw-100 mh-300px">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="234px" height="234px" viewBox="0 0 24 24" fill="none"><path opacity="0.0" d="M18.4 5.59998C21.9 9.09998 21.9 14.8 18.4 18.3C14.9 21.8 9.2 21.8 5.7 18.3L18.4 5.59998Z" fill="#21325b"></path><path d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM19.9 11H13V8.8999C14.9 8.6999 16.7 8.00005 18.1 6.80005C19.1 8.00005 19.7 9.4 19.9 11ZM11 19.8999C9.7 19.6999 8.39999 19.2 7.39999 18.5C8.49999 17.7 9.7 17.2001 11 17.1001V19.8999ZM5.89999 6.90002C7.39999 8.10002 9.2 8.8 11 9V11.1001H4.10001C4.30001 9.4001 4.89999 8.00002 5.89999 6.90002ZM7.39999 5.5C8.49999 4.7 9.7 4.19998 11 4.09998V7C9.7 6.8 8.39999 6.3 7.39999 5.5ZM13 17.1001C14.3 17.3001 15.6 17.8 16.6 18.5C15.5 19.3 14.3 19.7999 13 19.8999V17.1001ZM13 4.09998C14.3 4.29998 15.6 4.8 16.6 5.5C15.5 6.3 14.3 6.80002 13 6.90002V4.09998ZM4.10001 13H11V15.1001C9.1 15.3001 7.29999 16 5.89999 17.2C4.89999 16 4.30001 14.6 4.10001 13ZM18.1 17.1001C16.6 15.9001 14.8 15.2 13 15V12.8999H19.9C19.7 14.5999 19.1 16.0001 18.1 17.1001Z" fill="#21325b"></path></svg>
                                        {/* <svg
                        width="234px"
                        height="234px"
                        viewBox="0 0 84 84"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g
                          id="DBaaS-Icon-1C-BLK"
                          transform="translate(0.189181, -0.000000)"
                          fill="#21325B"
                          fillRule="nonzero"
                        >
                          <path d="m66.7 48.9c-7.7 2.1-17.7 3.3-28.4 3.3s-20.8-1.2-28.4-3.3c-2.2-.6-4.2-1.3-5.9-2v22c0 5.2 15.3 9.4 34.3 9.4s34.3-4.2 34.3-9.4v-22c-1.8.8-3.7 1.4-5.9 2zm-6.3 19.8c-2.6 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6 4.6 2.1 4.6 4.6-2 4.6-4.6 4.6z" />
                          <path d="m38.3 5.1c-19 0-34.3 4.2-34.3 9.4v25.4c3.6 2.4 14.8 6.1 34.3 6.1 19.4 0 30.7-3.7 34.3-6.1v-25.4c-.1-5.2-15.4-9.4-34.3-9.4zm22.1 31.7c-2.6 0-4.6-2.1-4.6-4.6 0-2.6 2.1-4.6 4.6-4.6s4.6 2.1 4.6 4.6c.1 2.5-2 4.6-4.6 4.6zm-22.1-14.3c-20.9 0-32.3-4.9-32.3-7.4s11.4-7.4 32.3-7.4 32.3 4.9 32.3 7.4-11.5 7.4-32.3 7.4z" />
                        </g>
                      </svg> */}
                                      </span>
                                    </div>
                                    {/*end::Illustration*/}
                                    {/*begin::Wrapper*/}
                                    <div className="card-px text-center py-5 mb-20">
                                      {/*begin::Title*/}
                                      <h1 className="fs-2x fw-bolder mb-5">Resource Alerts</h1>
                                      <h2 className="fs-1x fw-bolder mb-5 text-muted">
                                        Monitor Your Servers With Utho
                                      </h2>
                                      {/*end::Title*/}
                                      {/*begin::Description*/}
                                      <p className="text-gray-400 fs-4 fw-bold mb-10">
                                        Effortlessly create a Resource alert for your server instances <br />
                                        , to efficiently manage and scale your data
                                        infrastructure.
                                      </p>
                                      {/*end::Description*/}
                                      {/*begin::Action*/}
                                      <a onClick={openDrawer} className="btn btn-primary mb-10">
                                        Create Resource Alert
                                      </a>
                                      {/*end::Action*/}
                                    </div>
                                    {/*end::Wrapper*/}
                                  </div>
                                </td>
                              </tr>
                            </>)}
                          </>)}
                        </>) : (<>
                          <tr>
                            <td colspan={12}>
                              <TableLoader />
                              <TopBarHeaderLine />
                            </td>
                          </tr>
                        </>)}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="contact-tab" role="tabpanel" >
                <Contact />
              </div>
            </div>

          </div>
          <div id="drawer_overlay" onClick={openDrawer} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>

          <div
            id="monitor-modal"
            className="bg-body shadow drawer drawer-end"
            // data-kt-drawer="true"
            // data-kt-drawer-name="chat"
            // data-kt-drawer-activate="true"
            // data-kt-drawer-direction="end"
            data-kt-drawer-close="#close_ssh"
            data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
            style={{ maxWidth: "100%", important: true, zIndex: 110 }}
            // ref={modalRef}
          >
            {/*begin::Messenger*/}
            <div
              className="card rounded-0 w-100 border-0"
              style={{ height: 'max-content' }}
              id="kt_drawer_chat_messenger"
            >
              {/*begin::Card header*/}
              <div
                className="card-header pe-5"
                id="kt_drawer_chat_messenger_header"
              >
                {/*begin::Title*/}
                <div className="card-title">
                  {/*begin::User*/}
                  <div className="d-flex justify-content-center flex-column me-3">
                    <div className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                      Add Resource Alert
                    </div>
                  </div>
                  {/*end::User*/}
                </div>
                {/*end::Title*/}
                {/*begin::Card toolbar*/}
                <div className="card-toolbar">
                  {/*begin::Close*/}
                  <div
                    onClick={openDrawer}
                    className="btn btn-sm btn-icon btn-active-light-primary"
                    id="close_ssh"
                  >
                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                    <span className="svg-icon svg-icon-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.5"
                          x={6}
                          y="17.3137"
                          width={16}
                          height={2}
                          rx={1}
                          transform="rotate(-45 6 17.3137)"
                          fill="black"
                        />
                        <rect
                          x="7.41422"
                          y={6}
                          width={16}
                          height={2}
                          rx={1}
                          transform="rotate(45 7.41422 6)"
                          fill="black"
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}
                  </div>
                  {/*end::Close*/}
                </div>
                {/*end::Card toolbar*/}
              </div>
              {/*end::Card header*/}
              {/*begin::Card body*/}
              <div className="" id="">
                {/*begin::Form*/}
                {/*begin::Modal body*/}
                <div className="modal-body ">
                  {/*begin::Scroll*/}
                  {/*begin::Input group*/}
                  <div className="mb-5 row">
                    <div className="col-lg-4 col-md-4">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Name
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <input
                        type="text"
                        name="name"
                        id="add_name_alert"
                        defaultValue=""
                        placeholder="Enter name"
                        className="form-control"
                        onChange={(e) => InputValidation(e, "string", ['-', '_'])}
                      />
                      {/*end::Input*/}
                    </div>
                    <div className="col-lg-4 col-md-4">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Type
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <select className="form-select" defaultValue="cpu" onChange={handleChangeAlertType} id="add_type_alert" name="type" >
                        <optgroup label="System">
                          <option value='nodata'>No Data</option>
                          <option value='cpu'>CPU Usage %</option>
                        </optgroup>
                        <optgroup label="Memory">
                          <option value='memory_utilization_percent'>Memory Utilization Percent</option>
                        </optgroup>

                        <optgroup label="Disk">

                          <option value='disk_write'> Disk Write MB/s</option>
                          <option value='disk_read'> Disk Read MB/s</option>
                        </optgroup>

                        <optgroup label="Network">
                          <option value='public_outbound_bandwidth'> Public Outbound Bandwidth</option>
                          <option value='public_inbound_bandwidth'> Public Inbound Bandwidth</option>
                          <option value='private_outbound_bandwidth'> Private Outbound Bandwidth</option>
                          <option value='private_inbound_bandwidth'> Private Inbound Bandwidth</option>
                        </optgroup>
                      </select>
                      {/*end::Input*/}
                    </div>
                    <div className="col-lg-4 col-md-4">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Ref Type
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <select className="form-select" id="ref_type" name="ref_type" defaultValue="cloud" >
                        <option value='cloud'>Cloud</option>
                      </select>
                      {/*end::Input*/}
                    </div>
                  </div>
                  {/*end::Input group*/}


                  {/*begin::Input group*/}
                  <div className="mb-5 row">
                    <div className="col-lg-4 col-md-4" id="add_compare_alert_sec">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Compare
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <select className='form-select' id="add_compare_alert" name="compare">
                        <option value="below">Is below</option>
                        <option value="above">Is above</option>
                        <option value="equalto">Is equal-to</option>

                      </select>
                      {/*end::Input*/}
                    </div>
                    <div className="col-lg-4 col-md-4" id="add_value_alert_sec">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Value
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <input
                        type="text"
                        name="value"
                        id="add_value_alert"
                        className="form-control"
                        defaultValue='1'
                        onChange={(e) => InputValidation(e, "number")}
                      />
                      {/*end::Input*/}
                    </div>
                    <div className="col-lg-4 col-md-4">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Duration
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <select className='form-select' id="add_duration_alert" name="for">
                        <option value="5m">5 minutes</option>
                        <option value="10m">10 minutes</option>
                        <option value="30m">30 minutes</option>
                        <option value="1h">1 hour</option>
                        <option value="3h">3 hours</option>
                        <option value="6h">6 hours</option>
                        <option value="12h">12 hours</option>
                        <option value="24h">24 hours</option>

                      </select>
                      {/*end::Input*/}
                    </div>
                  </div>
                  {/*end::Input group*/}

                  {/*begin::Input group*/}
                  <div className="mb-5 row">
                    <div className="col-12">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Resource Instances
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <div id="addCloudSec">
                        <input class="form-control" value="" ref={addCloudAlertsRef} onChange={(e) => updateCloudTagifyOnChange(e)} id="add_cloud_alerts" />
                      </div>

                      <input type="hidden" name="cloud" id="add_cloud_alert" />
                      {/*end::Input*/}
                    </div>
                  </div>
                  {/*end::Input group*/}

                  {/*begin::Input group*/}
                  <div className="mb-5 row">
                    <div className="col-12">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Monitoring Contacts
                      </label>
                      <span className="text-gray-800 ms-10">( If there is no contacts, please add <a className="cursor-pointer text-primary" onClick={openContactSec} >here</a> )</span>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <input type='hidden' id="add_status_alert" defaultValue="Active" name="status" />
                      <div id="addContactSec">
                        <input class="form-control" value="" ref={addContactAlertsRef} onChange={(e) => updateContactTagifyOnChange(e)} id="add_contacts_alerts" />
                      </div>
                      <input type="hidden" name="contact" id="add_contact_alert" />
                      {/*end::Input*/}
                    </div>
                  </div>
                  {/*end::Input group*/}
                  {/*end::Scroll*/}
                </div>
                {/*end::Modal body*/}
                {/*begin::Modal footer*/}
                <div className="modal-footer "
                  style={{ paddingBottom: marginBottom }}
                >
                  {/*begin::Button*/}
                  <a
                    id="addnewcontact-btn"
                    onClick={addNewAlert}
                    className="btn btn-primary w-100"
                  >
                    <span className="indicator-label">
                      Create Alert
                    </span>
                  </a>
                  {/*end::Button*/}
                </div>
                {/*end::Modal footer*/}
                {/*end::Form*/}
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Messenger*/}
          </div>




          <div id="drawer_overlay_update" onClick={openDrawerUpdate} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>

          <div
            id="monitor-modal_update"
            className="bg-body shadow drawer drawer-end"
            // data-kt-drawer="true"
            // data-kt-drawer-name="chat"
            // data-kt-drawer-activate="true"
            // data-kt-drawer-direction="end"
            data-kt-drawer-close="#close_ssh"
            data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
            style={{ maxWidth: "90%", important: true, zIndex: 110 }}
          >
            {/*begin::Messenger*/}
            <div
              className="card rounded-0 w-100 border-0"
              style={{ height: 'max-content' }}
              id="kt_drawer_chat_messenger"
            >
              {/*begin::Card header*/}
              <div
                className="card-header pe-5"
                id="kt_drawer_chat_messenger_header"
              >
                {/*begin::Title*/}
                <div className="card-title">
                  {/*begin::User*/}
                  <div className="d-flex justify-content-center flex-column me-3">
                    <div className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                      Update Resource Alert
                    </div>
                  </div>
                  {/*end::User*/}
                </div>
                {/*end::Title*/}
                {/*begin::Card toolbar*/}
                <div className="card-toolbar">
                  {/*begin::Close*/}
                  <div
                    onClick={openDrawerUpdate}
                    className="btn btn-sm btn-icon btn-active-light-primary"
                    id="close_ssh"
                  >
                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                    <span className="svg-icon svg-icon-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.5"
                          x={6}
                          y="17.3137"
                          width={16}
                          height={2}
                          rx={1}
                          transform="rotate(-45 6 17.3137)"
                          fill="black"
                        />
                        <rect
                          x="7.41422"
                          y={6}
                          width={16}
                          height={2}
                          rx={1}
                          transform="rotate(45 7.41422 6)"
                          fill="black"
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}
                  </div>
                  {/*end::Close*/}
                </div>
                {/*end::Card toolbar*/}
              </div>
              {/*end::Card header*/}
              {/*begin::Card body*/}
              <div className="" id="">
                {/*begin::Form*/}
                {/*begin::Modal body*/}
                <div className="modal-body ">
                  {/*begin::Scroll*/}
                  {/*begin::Input group*/}
                  <div className="mb-5 row">
                    <div className="col-lg-4 col-md-4">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Name
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <input
                        type="text"
                        name="name"
                        id="update_name_alert"
                        defaultValue={alertInfo ? (alertInfo.name) : ('')}
                        placeholder="Enter name"
                        className="form-control"
                        onChange={(e) => InputValidation(e, "string", ['-', '_'])}
                      />
                      {/*end::Input*/}
                    </div>
                    <div className="col-lg-4 col-md-4">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Duration
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <select className='form-select' defaultValue={alertInfo ? (alertInfo.for) : ('')} id="update_duration_alert" name="for">
                        <option value="5m">5 minutes</option>
                        <option value="10m">10 minutes</option>
                        <option value="30m">30 minutes</option>
                        <option value="1h">1 hour</option>
                        <option value="3h">3 hours</option>
                        <option value="6h">6 hours</option>
                        <option value="12h">12 hours</option>
                        <option value="24h">24 hours</option>

                      </select>
                      <input type="hidden" defaultValue={alertInfo ? (alertInfo.type) : ('')} id="update_type_alert" name="type" />

                      {/*end::Input*/}
                    </div>
                    <div className="col-lg-4 col-md-4">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Ref Type
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <select className='form-select' defaultValue={alertInfo ? (alertInfo.ref_type) : ('')} id="update_ref_type" name="ref_type">
                        <option value="cloud">Cloud</option>
                      </select>
                      {/*end::Input*/}
                    </div>
                  </div>
                  {/*end::Input group*/}


                  {/*begin::Input group*/}
                  <div className="mb-5 row">
                    {alertInfo != null ? (<>
                      {alertInfo.type != "nodata" ? (<>
                        <div className="col-lg-4 col-md-4">
                          {/*begin::Label*/}
                          <label className="required fs-5 fw-bold mb-2">
                            {" "}
                            Compare
                          </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <select className='form-select' defaultValue={alertInfo ? (alertInfo.compare) : ('')} id="update_compare_alert" name="compare">
                            <option value="below">below</option>
                            <option value="above">above</option>
                            <option value="equalto">Is equal-to</option>

                          </select>
                          {/*end::Input*/}
                        </div>
                        <div className="col-lg-4 col-md-4">
                          {/*begin::Label*/}
                          <label className="required fs-5 fw-bold mb-2">
                            {" "}
                            Value
                          </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <input
                            type="text"
                            name="value"
                            id="update_value_alert"
                            defaultValue={alertInfo ? (alertInfo.value) : ('')}
                            className="form-control"
                            onChange={(e) => InputValidation(e, "number")}
                          />
                          {/*end::Input*/}
                        </div>
                      </>) : ('')}
                    </>) : ('')}
                    <div className="col-lg-4 col-md-4 d-none">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Status
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <select
                        id={"update_status_alert"}
                        className="form-select"
                        name="status"
                        defaultValue={alertInfo ? (alertInfo.status) : ('')}
                      >
                        <option value={1}>Active</option>
                        <option value={0}>Inactive </option>

                      </select>
                      {/*end::Input*/}
                    </div>
                  </div>
                  {/*end::Input group*/}

                  {/*begin::Input group*/}
                  <div className="mb-5 row">
                    <div className="col-12">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Resource Instances
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <input class="form-control" ref={updateCloudAlertsRef} onChange={(e) => updateCloudTagify(e)} id="update_cloud_alerts" />
                      <input type="hidden" name="cloud" defaultValue={alertInfo ? (alertInfo.ref_ids) : ('')} id="update_cloud_alert" />
                      {/*end::Input*/}
                    </div>
                  </div>
                  {/*end::Input group*/}

                  {/*begin::Input group*/}
                  <div className="mb-5 row">
                    <div className="col-12">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Monitoring Contacts
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <input type='hidden' id="update_alert_id" defaultValue={alertInfo ? (alertInfo.id) : ('')} />
                      <input class="form-control" ref={updateContactAlertsRef} defaultValue={alertInfo ? (alertInfo.contacts) : ('')} onChange={(e) => updateContactTagify(e)} id="update_contacts_alerts" />
                      <input type="hidden" name="contact" value={alertInfo ? (alertInfo.contacts) : ('')} id="update_contact_alert" />
                      {/*end::Input*/}
                    </div>
                  </div>
                  {/*end::Input group*/}
                  {/*end::Scroll*/}
                </div>
                {/*end::Modal body*/}
                {/*begin::Modal footer*/}
                <div className="modal-footer"
                  style={{ paddingBottom: marginBottom }}
                >
                  {/*begin::Button*/}
                  <a
                    id="updatealert-btn"
                    onClick={updateAlertByID}
                    className="btn btn-primary w-100"
                  >
                    <span className="indicator-label">
                      Update Alert
                    </span>
                  </a>
                  {/*end::Button*/}
                </div>
                {/*end::Modal footer*/}
                {/*end::Form*/}
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Messenger*/}
          </div>
        </>
      );
    } else {
      return (
        <>
          <AccessDenied />
        </>
      )
    }
  } else {
    return (
      <>
        <TableLoader />
      </>
    )
  }
}

