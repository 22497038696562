import React, { useEffect, useState } from 'react';
import UsePost from '../../../../CustomHooks/usePost';
import UseApi from '../../../../CustomHooks/useApi';
import UseCallApi from '../../../../CustomHooks/useCallApi';
import TableLoader from '../../Loaders/TableLoader';
import TopBarHeaderLine from '../../../../CustomHooks/TopBarHeaderLine';
import AccessDenied from '../../AccessDenied';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import ProductsUrl from '../../Docs/Docsdata';
import CustomDoc from '../../../../CustomHooks/CustomDoc';

export default function LoginSession() {
    const [sessionsData, setSessionsData] = useState(null)
    const [dataFetched, setDataFetched] = useState(false);
    const [dummySessionsData, setDummySessionsData] = useState(null)
    const [marginBottom, setMarginBottom] = useState(window.innerWidth >= 768 ? '' : '4rem');
    const [pageCount, setPageCount] = useState(1);
    const [perPage, setPerPage] = useState(5);
    const [itemOffset, setItemOffset] = useState(0);
    const [access, setAccess] = useState(true);
    const [activePage, setActivePage] = useState(0)

    const getActiveSession = async () => {
        var resData = await UseApi("get", "activesessions", "")
        if (resData.status == "error" && resData.message == "Permission denied.") {
            setDataFetched(true)
            setAccess(false)
            setSessionsData([])
            setDummySessionsData([])
        }
        else if (resData.status == "error") {
            setDataFetched(true)
            setSessionsData([])
            setDummySessionsData([])
        } else {
            setDataFetched(true)
            setSessionsData(resData?.sessions?.reverse())
            setDummySessionsData(resData?.sessions?.reverse().reverse())
        }
        // console.log(resData,"resData");
    }

    useEffect(() => {
        getActiveSession()
    }, [])

    function timeAgo(dateString) {
        const date = new Date(dateString);
        const currentDate = new Date();

        const timeDifference = currentDate - date;
        const seconds = Math.floor(timeDifference / 1000);

        if (seconds < 60) {
            return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
        }

        const minutes = Math.floor(seconds / 60);
        if (minutes < 60) {
            return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
        }

        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;

        if (hours < 24) {
            if (remainingMinutes === 0) {
                return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
            }
            return `${hours} hour${hours !== 1 ? 's' : ''} and ${remainingMinutes} minute${remainingMinutes !== 1 ? 's' : ''} ago`;
        }

        const days = Math.floor(hours / 24);
        if (days < 30) {
            return `${days} day${days !== 1 ? 's' : ''} ago`;
        }

        const months = Math.floor(days / 30);
        if (months < 12) {
            return `${months} month${months !== 1 ? 's' : ''} ago`;
        }

        const years = Math.floor(months / 12);
        return `${years} year${years !== 1 ? 's' : ''} ago`;
    }



    useEffect(() => {
        const handleResize = () => {
          setMarginBottom(window.innerWidth >= 768 ? '0rem' : '4rem');
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    const copyClusterName = () => {
        var eleText = document.querySelector("#user_file_url");
        var ele = document.querySelector("#user_file_url_copy_btn");
        // var icon_btn = document.querySelector()

        if (ele && eleText) {
            var copyText = eleText.innerText; // Use innerText to get the text content
            var innerEle = ele.innerHTML;
            console.log(innerEle);
            var tempInput = document.createElement("textarea");
            document.body.appendChild(tempInput);
            tempInput.value = copyText;
            tempInput.select();
            document.execCommand("copy");
            document.body.removeChild(tempInput);

            ele.innerHTML = 'copied';

            setTimeout(() => {
                ele.innerHTML = `(copy)`; // Set your original content here
            }, 2000);
        }
    };

    document.addEventListener('click', function (event) {
        console.log(event.target);
        if (event.target && event.target.id === 'user_file_url_copy_btn') {
            copyClusterName();
        }
    });

    const xyz = () => {
        console.log("helloooooooooooooo");
    }

    const handleLogOutSession = async (id, ip, index) => {
        var submitBtn = document.querySelector(`#delete_logOutSession_${id}`);
        if (submitBtn) {
            toast.dismiss();
            const { value: isDelete } = await Swal.fire({
                title: 'Log Out Active Session',
                html: `
            <div className="d-flex align-items-center flex-wrap"> 
            <div id="kt_clipboard_4" className="me-5 fw-bold text-gray-600">
              <p class="fs-5">Enter the User IP to Log Out Session:</p>
      
              <span class="fw-bold" id="user_file_url" >${ip} &nbsp;</span>
              <span id="user_file_url_copy_btn" style="cursor:pointer" class="text-primary"> (copy)       
               </span> 
            </div>  
          </div>
         `,
                input: 'text',
                inputPlaceholder: 'User Log Out Session',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel',
                customClass: {
                    confirmButton: 'delete-button bg-danger', // Add your custom class here
                },
            });
            if (isDelete == ip) {
                // setPageStatus(true)
                var preContent = submitBtn.innerHTML;
                submitBtn.classList.add("disabled");
                submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
                const dt = toast.loading("User Log Out Session...");
                const dnsp = await UseApi('DELETE', 'activesessions?id=' + id, '');
                submitBtn.classList.remove("disabled");
                submitBtn.innerHTML = preContent;
                //console.log(dnsp);
                if (dnsp.status == 'error') {
                    // setPageStatus(false)
                    toast.update(dt, {
                        render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
                    });
                } else {
                    //   setPageStatus(false)
                    toast.update(dt, {
                        render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
                    });
                    setSessionsData((old) => {
                        old.splice(index, 1);
                        return [...old];
                    })
                    setDummySessionsData((old) => {
                        old.splice(index, 1);
                        return [...old];
                    })
                    // if (props) {
                    //     props.updateDatabase();
                    // }
                }
            } else {
                // setPageStatus(false)/
                swal({
                    title: "Invalid IP Address",
                    text: "Please confirm the valid ip!",
                    icon: "warning",
                    button: "OK",
                });
            }
        }
    }
    function ProductInfo({ productdata }) {
        return (
            <>
                {productdata != null
                    ? Object.keys(productdata).length != 0 ?
                        (productdata && productdata.map((value, index) =>
                        (<>
                            <tr>
                                <td className='w-250px ps-9'>
                                    <span className='text-gray-600'>{value.device ? value.device : "-"}</span>
                                </td>
                                <td></td>
                                <td className='w-250px text-center'>
                                    <span className='text-gray-600'>{value.ip}</span>
                                </td>
                                <td></td>
                                <td className="w-100px text-center">
                                    <span className="badge badge-light-success fs-7 fw-bolder">OK</span>
                                </td>
                                <td></td>
                                <td className="w-150px text-center d-none">
                                    <span className='text-gray-600 text-center'>{value.location ? value.location : "-"}</span>
                                </td>
                                <td></td>
                                <td className="w-150px text-center">
                                    <span className='text-gray-600'>{timeAgo(value.created_at)}</span>
                                </td>
                                <td></td>
                                <td className="w-150px text-center">
                                    <span className='btn btn-light-danger btn-sm sphide' id={`delete_logOutSession_${value.id}`} onClick={() => handleLogOutSession(value.id, value.ip, index)}>Log Out</span>
                                </td>
                            </tr>
                            {/* {value.nodepools && value.nodepools.} */}
                        </>))) : (<>
                            <tr>
                                <td colspan="12" id="no_record" valign="top">
                                    <div className="d-flex flex-column flex-center">
                                        <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                                    </div>
                                </td>
                            </tr>
                        </>) : <td colspan="12">
                        <TableLoader />
                    </td>}

            </>
        )
    }
    const handleSearch = (text) => {
        const endOffset = itemOffset + perPage;
        // Remove special characters from the input text
        const escapedText = text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

        // Create a regular expression pattern to match the filtered text
        const regex = new RegExp(escapedText, "gi");

        // Filter the clusterData based on the regex pattern
        let data = sessionsData.filter((row) => {
            // Check if any property in the row matches the regex pattern
            return (
                (row.ip && row.ip.toString().toLowerCase().includes(text.toLowerCase()))
                // (row.type && row.type.toString().toLowerCase().includes(text.toLowerCase()))
            )
        });

        // Update the state with the filtered data
        setDummySessionsData(data);
        setPageCount(Math.ceil(data.length / perPage));
    }

    function PaginatedItems({ itemsPerPage, ProductData }) {
        setPageCount(Math.ceil(ProductData.length / itemsPerPage));
        const endOffset = itemOffset + parseInt(itemsPerPage);
        const handlePageClick = (event) => {
            setActivePage((old) => old = event.selected)
            const newOffset = event.selected * itemsPerPage;
            setItemOffset(newOffset);
        };
        return (
            <>
                <ProductInfo productdata={ProductData.slice(itemOffset, endOffset)} />
                <td colspan="12">
                    <ReactPaginate
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        initialPage={activePage}
                        previousLabel="< previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                    />
                </td>
            </>
        );
    }

    if (dataFetched) {
        if (access) {
            return (
                <div className="card mb-5 mb-lg-10" style={{marginBottom:marginBottom}}>
                    {/*begin::Card header*/}
                    <div className="card-header">
                        {/*begin::Heading*/}
                        <div className="card-title">
                            <h3>Active Session</h3>
                        </div>
                        {/*end::Heading*/}
                        {/*begin::Toolbar*/}
                        <div className="card-toolbar">
                        <div className="position-relative my-1" style={{ marginRight: 20 }}>
                            <CustomDoc url={ProductsUrl[0]?.active_session+"/activesession/"}/>
                        </div>
                            <div className="my-1 me-4 d-none">
                                {/*begin::Select*/}
                                <select
                                    className="form-select form-select-sm form-select-solid w-125px select2-hidden-accessible"
                                    data-control="select2"
                                    data-placeholder="Select Hours"
                                    data-hide-search="true"
                                    data-select2-id="select2-data-10-ynd7"
                                    tabIndex={-1}
                                    aria-hidden="true"
                                    onChange={(e) => setPageCount(e.target.value)}
                                >
                                    <option
                                        value={1}
                                        selected="selected"
                                        data-select2-id="select2-data-12-jqdu"
                                    >
                                        1 Hours
                                    </option>
                                    <option value={2}>6 Hours</option>
                                    <option value={3}>12 Hours</option>
                                    <option value={4}>24 Hours</option>
                                </select>
                                <span
                                    className="select2 select2-container select2-container--bootstrap5"
                                    dir="ltr"
                                    data-select2-id="select2-data-11-yb0b"
                                    style={{ width: "100%" }}
                                >
                                    <span className="selection">
                                        <span
                                            className="select2-selection select2-selection--single form-select form-select-sm form-select-solid w-125px"
                                            role="combobox"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            tabIndex={0}
                                            aria-disabled="false"
                                            aria-labelledby="select2-n9oi-container"
                                            aria-controls="select2-n9oi-container"
                                        >
                                            <span
                                                className="select2-selection__rendered"
                                                id="select2-n9oi-container"
                                                role="textbox"
                                                aria-readonly="true"
                                                title="1 Hours"
                                            >
                                                1 Hours
                                            </span>
                                            <span className="select2-selection__arrow" role="presentation">
                                                <b role="presentation" />
                                            </span>
                                        </span>
                                    </span>
                                    <span className="dropdown-wrapper" aria-hidden="true" />
                                </span>
                                {/*end::Select*/}
                            </div>
                            <a href="#" className="btn btn-sm btn-primary my-1 d-none">
                                View All
                            </a>
                            <div className="position-relative my-1" style={{ marginRight: 20 }}>
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    data-kt-table-widget-4="search"
                                    id="searchClient"
                                    placeholder="Search By Ip"
                                    defaultValue=""
                                    onChange={(e) => (handleSearch(e.target.value))}
                                />
                            </div>
                            <div style={{ marginRight: 20 }}>
                                <div className="dataTables_length">
                                    <label className="w-125px">
                                        <select
                                            className="form-select form-select-sm form-select-solid"
                                            id="perPage"
                                            onChange={e => setPerPage(e.target.value)}
                                            defaultValue={5}
                                        >
                                            <option value={5} selected="">
                                                5 Records
                                            </option>
                                            <option value={10}>10 Records</option>
                                            <option value={25}>25 Records</option>
                                            <option value={50}>50 Records</option>
                                            <option value={100}>100 Records</option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                            {/* <div style={{ marginRight: 20 }}>
                        <div className="dataTables_length">
                        </div>
                    </div> */}
                        </div>
                        {/*end::Toolbar*/}
                    </div>
                    {/*end::Card header*/}
                    {/*begin::Card body*/}
                    <div className="card-body p-0">
                        {/*begin::Table wrapper*/}
                        <div className="table-responsive">
                            {/*begin::Table*/}
                            <table className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9">
                                {/*begin::Thead*/}
                                <thead className="border-gray-200 fs-5 fw-bold bg-lighten">
                                    <tr>
                                        <th className="w-250px ">Device</th>
                                        <th className="w-100px pe-4"></th>
                                        <th className="w-250px text-center">IP Address</th>
                                        <th className="w-100px pe-4"></th>
                                        <th className="w-100px text-center">Status</th>
                                        <th className="w-100px pe-4"></th>
                                        <th className="w-150px text-center d-none">Location</th>
                                        <th className="w-100px pe-4"></th>
                                        <th className="w-150px text-center">Time</th>
                                        <th className="w-100px pe-4"></th>
                                        <th className="w-150px text-center">Action</th>
                                    </tr>
                                </thead>
                                {/*end::Thead*/}
                                {/*begin::Tbody*/}
                                <tbody className="fw-6 fw-bold text-gray-600">
                                    {dummySessionsData != null ? (<>
                                        {access == false ? (<>
                                            <tr>
                                                <td colspan={12}>
                                                    <AccessDenied />
                                                </td>
                                            </tr>
                                        </>) : (<>
                                            {Object.keys(dummySessionsData).length != 0 ? (<>
                                                <PaginatedItems itemsPerPage={perPage} ProductData={dummySessionsData} />
                                            </>) : (<>
                                                <tr>
                                                    <td colSpan={12}>
                                                        <div className="card-body p-0 mt-10">
                                                            {/*begin::Illustration*/}
                                                            <div className="text-center px-4 mt-10">
                                                                <span className="mw-100 mh-300px">
                                                                    <svg width="234px" height="234px" viewBox="0 -0.5 25 25" fill="#21325B" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M11.75 9.874C11.75 10.2882 12.0858 10.624 12.5 10.624C12.9142 10.624 13.25 10.2882 13.25 9.874H11.75ZM13.25 4C13.25 3.58579 12.9142 3.25 12.5 3.25C12.0858 3.25 11.75 3.58579 11.75 4H13.25ZM9.81082 6.66156C10.1878 6.48991 10.3542 6.04515 10.1826 5.66818C10.0109 5.29121 9.56615 5.12478 9.18918 5.29644L9.81082 6.66156ZM5.5 12.16L4.7499 12.1561L4.75005 12.1687L5.5 12.16ZM12.5 19L12.5086 18.25C12.5029 18.25 12.4971 18.25 12.4914 18.25L12.5 19ZM19.5 12.16L20.2501 12.1687L20.25 12.1561L19.5 12.16ZM15.8108 5.29644C15.4338 5.12478 14.9891 5.29121 14.8174 5.66818C14.6458 6.04515 14.8122 6.48991 15.1892 6.66156L15.8108 5.29644ZM13.25 9.874V4H11.75V9.874H13.25ZM9.18918 5.29644C6.49843 6.52171 4.7655 9.19951 4.75001 12.1561L6.24999 12.1639C6.26242 9.79237 7.65246 7.6444 9.81082 6.66156L9.18918 5.29644ZM4.75005 12.1687C4.79935 16.4046 8.27278 19.7986 12.5086 19.75L12.4914 18.25C9.08384 18.2892 6.28961 15.5588 6.24995 12.1513L4.75005 12.1687ZM12.4914 19.75C16.7272 19.7986 20.2007 16.4046 20.2499 12.1687L18.7501 12.1513C18.7104 15.5588 15.9162 18.2892 12.5086 18.25L12.4914 19.75ZM20.25 12.1561C20.2345 9.19951 18.5016 6.52171 15.8108 5.29644L15.1892 6.66156C17.3475 7.6444 18.7376 9.79237 18.75 12.1639L20.25 12.1561Z" fill="#21325B"></path> </g></svg>
                                                                </span>
                                                            </div>
                                                            {/*end::Illustration*/}
                                                            {/*begin::Wrapper*/}
                                                            <div className="card-px text-center py-5 mb-20">
                                                                {/*begin::Title*/}
                                                                <h1 className="fs-2x fw-bolder mb-5">Active Session</h1>
                                                                <h2 className="fs-1x fw-bolder mb-5 text-muted">
                                                                    No Active Session Found
                                                                    {/* Distribute your web traffic evenly */}
                                                                </h2>
                                                                {/*end::Title*/}
                                                                {/*begin::Description*/}
                                                                <p className="text-gray-400 fs-4 fw-bold mb-10 d-none">
                                                                    Ensure a seamless experience for your users even during high traffic peaks.              </p>
                                                                {/*end::Description*/}
                                                                {/*begin::Action*/}
                                                                {/* <Link to="/loadbalancer/deploy">
                                                            <a
                                                                href="#"
                                                                className="btn btn-primary mb-10"
                                                            >
                                                                Create LoadBalancer
                                                            </a>
                                                        </Link> */}
                                                                {/*end::Action*/}
                                                            </div>
                                                            {/*end::Wrapper*/}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </>)}
                                        </>)}
                                    </>) : (<>
                                        <tr>
                                            <td colspan={12}>
                                                <TableLoader />
                                                <TopBarHeaderLine />
                                            </td>
                                        </tr>

                                    </>)}
                                </tbody>
                                {/*end::Tbody*/}
                            </table>
                            {/*end::Table*/}
                        </div>
                        {/*end::Table wrapper*/}
                    </div>
                    {/*end::Card body*/}
                </div>

            )
        } else {
            return (
                <>
                    <AccessDenied />
                </>
            )
        }
    } else {
        return (
            <>
                <TableLoader />
            </>
        )
    }
}