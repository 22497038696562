import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom/dist";
import UsePost from "../../../../CustomHooks/usePost";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UseApi from "../../../../CustomHooks/useApi";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../Loaders/TableLoader";
import { publicIp, publicIpv4, publicIpv6 } from 'public-ip';
import Dczone from "../../utils/dczone/Dczone";
import { connect } from "react-redux";
import { addObjectDeployData } from "../../../../Redux/Services/Action/actions";
function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}

const DeployObjectStorage = (props) => {
  const forceUpdate = useForceUpdate();
  const [pageStatus, setPageStatus] = useState(false)
  const [adcdata, setAdcdata] = useState([]);
  const [ddcdata, setDdcdata] = useState([]);
  const dataFetchedRef2 = useRef();
  const navigate = useNavigate();
  const [planData, setPlanData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [location, setLocation] = useState("innoida")
  const [deployData, setDeployData] = useState(props.deployInfo[0]);
  const user_data = useRef(null);
  const dczoneStatus = useRef(true);
  const [marginBottom, setMarginBottom] = useState(window.innerWidth >= 768 ? '0rem' : '5rem');

  const makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result.toLowerCase();
  };

  useEffect(() => {
    const handleResize = () => {
      setMarginBottom(window.innerWidth >= 768 ? '0rem' : '5rem');
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getDczone = async () => {
    const data = await UseApi("get", "dczones", "");
    user_data.current = await UseApi("get", "account/info", "");
    setUserData(user_data.current.user);
    if (Object.keys(data).length === 0) {
      setAdcdata([]);
      setDdcdata([]);
    } else {
      if (dczoneStatus.current) {
        data.dczones.map(async (value, index) => {
          if (value.slug == "innoida") {
            if (value.status !== "active") {
              var server_ip = await publicIpv4();
              if (server_ip) {
                if (
                  server_ip !== "103.209.144.51" ||
                  server_ip !== "103.209.144.66" ||
                  server_ip !== "103.209.145.208" ||
                  server_ip !== "103.209.144.81" ||
                  server_ip !== "103.209.144.82" ||
                  server_ip !== "103.209.144.83" ||
                  server_ip !== "103.127.30.91" ||
                  server_ip !== "103.209.144.60" ||
                  server_ip !== "103.127.30.239" ||
                  server_ip !== "103.127.28.52"
                ) {
                  ddcdata.push(value);
                }
                forceUpdate();
              }
            } else {
              adcdata.push(value);
              forceUpdate();
            }
          }
        });
        dczoneStatus.current = false;
      }
    }
  };

  function removeDuplicates(data, key) {
    const uniqueEntries = [];
    const seenKeys = new Set();

    data.forEach(entry => {
      const keyValue = entry[key];

      if (!seenKeys.has(keyValue)) {
        seenKeys.add(keyValue);
        uniqueEntries.push(entry);
      }
    });

    return uniqueEntries;
  }
  const getPlans = async () => {
    var currency = 'INR';
    if (userData) {
      currency = userData.currency;
    }
    const data = await UseApi("get", "pricing/objectstorage?currency=" + currency, "");
    // console.log(data);
    if (data.rcode == "success") {
      var arr = data.pricing;
      var modifyPlanData = removeDuplicates(arr, "name");
      setPlanData(modifyPlanData);
    } else {
      setPlanData(null);
    }
  };
  useEffect(() => {
    getDczone();
    getPlans();
    if (props.deployInfo[0]) {
      props.deployInfo[0].size = 0;
      props.deployInfo[0].price = 0;
    }
    // setTimeout(() => {
    //   selectDc('innoida');
    // }, 1000);
  }, []);

  // useEffect(() => {
  //   const plan_value = document.querySelector('#plan_value')
  //   const plan_cost = document.querySelector('#plan_cost')
  //   if (plan_cost && plan_value && props.deployInfo[0]) {
  //     plan_cost.value = 0
  //     plan_value.value = 0
  //     props.deployInfo[0].size = 0;
  //     props.deployInfo[0].price = 0;
  //   }
  // }, [])

  const selectDc = async (slug) => {
    var dcSelect = document.querySelectorAll([".tickMarkDc"]);
    var currentDc = document.querySelector("#tickMarkDc-" + slug);
    setLocation(slug)
    if (dcSelect) {
      dcSelect.forEach((clist) => {
        clist.classList.add("d-none");
      });
    }
    if (currentDc) {
      currentDc.classList.remove("d-none");
    }

  };

  const isDcSelected = () => {
    const selectedRadio = document.querySelector('input[name="dcslug"]:checked');
    return selectedRadio !== null;
  };
  const addObjectStorage = async () => {
    const dt = toast.loading("Please wait...");
    const plan_value = document.querySelector('#plan_value')
    const plan_cost = document.querySelector('#plan_cost')
    console.log(plan_cost, plan_value);

    if (plan_value.value == 0 && plan_cost.value == 0) {
      setTimeout(() => {
        toast.update(dt, {
          render: "Please select plan type", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
      }, 1000)
    } else {

      if (!isDcSelected()) {
        toast.update(dt, {
          render: "Please select data center location", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        return;
      }

      var submitBtn = document.querySelector("#object-btn");
      if (submitBtn) {
        setPageStatus(true)
        var preContent = submitBtn.innerHTML;
        submitBtn.classList.add("disabled");
        submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
        const responseData = await UsePost('post', 'objectstorage/bucket/create', 'objectdeploy');
        if (responseData) {
          setPageStatus(false)
          submitBtn.classList.remove("disabled");
          submitBtn.innerHTML = preContent;
          if (responseData.status === 'error') {
            toast.update(dt, {
              render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
          } else if (responseData.status === 'success') {
            toast.update(dt, {
              render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
            plan_cost.value = 0
            plan_value.value = 0
            props.deployInfo[0].size = 0;
            props.deployInfo[0].price = 0;
            window.onDeployEvents('deploy_object_storage');
            navigate("/objectStorage");
          }
        }
      }
    }
  }

  function checkInput(val) {
    var db_node_count = document.querySelector("#obj_size");
    if (db_node_count) {
      if (val < 250) {
        const dt = toast.loading("Please wait...");
        toast.update(dt, {
          render: "Node count must be minimum 250 GB!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        db_node_count.value = 250;
      }
    }
  }

  const selectPlan = (planid, price, billing) => {
    //unselect all plans 
    console.log(planid, price, billing);
    var planLabels = document.querySelectorAll(".tick-parent");
    var PlansTicks = document.querySelectorAll(".tickMarkPlan");
    planLabels.forEach((lfield) => {
      lfield.classList.remove("plan_list_active");
    })
    PlansTicks.forEach((tfield) => {
      tfield.classList.add("d-none");
    })

    //select the specific plan

    var activeLabels = document.querySelector("#tick_parent_" + planid);
    var activePlansTicks = document.querySelector("#tickMarkPlan-" + planid);
    if (activeLabels && activePlansTicks) {
      activeLabels.classList.add("plan_list_active");
      activePlansTicks.classList.remove("d-none");
    }

    var plan_cost = document.querySelector("#plan_cost");
    var plan_value = document.querySelector("#plan_value");
    var billing_info = document.querySelector("#billing_info");
    var total_cost = document.querySelector("#total_cost");
    if (plan_cost && plan_value && billing_info) {
      plan_cost.value = parseInt(price);
      total_cost.innerHTML = parseInt(price);
      plan_value.value = planid;

      if (props && props.deployInfo) {
        props.deployInfo[0].size = planid;
        props.deployInfo[0].price = price;
        props.deployInfo[0].billing = billing;
      }
      billing_info.value = billing;
    }
  }

  return (
    <>
      {adcdata.length > 0 ? (<>
        <form id="objectdeploy">
          {/*begin::Tables Widget 9*/}
          <div className="col-xl-12 card mb-5">
            {/*begin::data center*/}
            <Dczone adcdata={adcdata} ddcdata={ddcdata} selectDc={selectDc} location={location} />
            {/* End data center */}
          </div>

          {/*begin::Col*/}
          <div className="col-xl-12 card mb-5 d-none">
            {/*begin::List widget 10*/}

            <div className="card-header border-3 mb-5 pt-2">
              {/*begin::Title*/}
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder text-gray-800">
                  Select storage size
                </span>
                <span className="text-gray-400 mt-1 fw-bold fs-6">
                  Storage size as per your server need
                </span>
              </h3>
            </div>
            <div className="container pb-5">
              <div
                className="row g-9"
                data-kt-buttons="true"
                data-kt-buttons-target="[data-kt-button]"
              >
                <div className="col-md-4 col-lg-4 col-xxl-4">
                  <div className="">
                    {/*begin::Input group*/}
                    <div className="input-group mb-5">
                      <input
                        type="number"
                        min={1}
                        id="obj_size"
                        className="form-control text-center"
                        onChange={(e) => checkInput(e.target.value)}
                        defaultValue={250}
                      />
                      <span className="input-group-text" id="basic-addon2">
                        GB
                      </span>
                    </div>
                    {/*end::Input group*/}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*end::Col*/}

          <div className="col-xl-12 card mb-5">
            <div className="card-header border-3 mb-5 pt-2" style={{ padding: '1rem 1rem 1rem 1.38rem' }}>
              {/*begin::Title*/}
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder text-gray-800">
                  Select Plan Type
                </span>
                <span className="text-gray-400 mt-1 fw-bold fs-6">
                  Object Storage plan
                </span>
              </h3>
            </div>

            {/*end::Nav*/}
            <input
              type="hidden"
              name="billing"
              id="billing_info"
              defaultValue={
                props && props.deployInfo
                  ? props.deployInfo[0].billing
                  : "monthly"
              }
            />
            <input
              type="hidden"
              name="size"
              id="plan_value"
              defaultValue={
                props && props.deployInfo
                  ? props.deployInfo[0].size
                  : 0
              }
            />
            <input
              type="hidden"
              name="price"
              id="plan_cost"
              defaultValue={
                props && props.deployInfo
                  ? props.deployInfo[0].price
                  : 0
              }
            />

            {/* Horizontal PLAN START*/}
            <div className="row mt-5" data-kt-buttons="true">
              <div className="table-responsive" id="ramcpuplans">
                {/*begin::Table*/}
                <table className="table  table-row-bordered table-flush align-middle gy-6 tableramcpu">
                  {/*begin::Thead*/}
                  <thead className="border-bottom border-gray-200 fs-6 fw-bolder bg-secondary">
                    <tr>
                      <th className="w-25 px-0 text-center ps-9">Size</th>
                      <th className="w-20 ps-0 text-center" />
                      <th className="w-25 ps-0 text-center"> Type </th>
                      <th className="w-20 ps-0 text-center">  </th>
                      <th className="w-25 ps-0 text-center">  Price  </th>
                      <th className="w-20 ps-0 text-center">  </th>
                    </tr>
                  </thead>
                  {/*end::Thead*/}
                  {/*begin::Tbody*/}
                  <tbody className="fs-6 fw-bold text-gray-600">
                    {planData != null &&
                      planData ? (<>{
                        planData.map((value, index) => (
                          <>
                            {value.type == "objectstorage" ? (
                              <>
                                <tr
                                  onClick={(e) => selectPlan(value.id, value.price, "monthly")}
                                  className="planseffect tick-parent ps-9 text-center"
                                  id={"tick_parent_" + value.id}
                                >
                                  <td className="ps-0 text-center ps-9">
                                    {value.name}
                                  </td>
                                  <td></td>
                                  <td className="text-center">
                                    <span
                                      className="badge badge-light-success fs-7 fw-bolder"
                                      style={{ marginRight: 5 }} >
                                      Monthly
                                    </span>
                                  </td>
                                  <td></td>
                                  <td className="text-center">
                                    <span
                                      className="badge badge-light-primary fs-7 fw-bolder"
                                      style={{ marginRight: 5 }}
                                      id={"plan_price_" + value.id} >
                                      {userData != null ? (<>{userData.currencyprefix}</>) : ("")}
                                      {value.price}
                                    </span>
                                  </td>
                                  {/* <td></td> */}
                                  <td className="text-center d-none">
                                    <a className="btn btn-sm btn-primary sphide"> Choose Plan
                                      <span className="svg-icon svg-icon-2">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <path
                                            d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z"
                                            fill="black"
                                          />
                                          <path
                                            opacity="0.3"
                                            d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z"
                                            fill="black"
                                          />
                                        </svg>
                                      </span>

                                    </a>
                                  </td>
                                  <td className="ps-0 text-center ribbon ribbon-end ribbon-clip">
                                    <div
                                      className="d-none tickMarkPlan ribbon ribbon-triangle ribbon-top-start border-primary"
                                      id={"tickMarkPlan-" + value.id}
                                      style={{ marginTop: '-0.1rem' }}
                                    >
                                      <div className="ribbon-icon mt-n5">
                                        <i className="bi bi-check2 fs-2 text-white" />
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            ) : (
                              ""
                            )}
                          </>

                        ))}</>) : (<><td colspan="12"><div className="small-spinner"></div></td></>)}
                  </tbody>
                  {/*end::Tbody*/}
                </table>
                {/*end::Table*/}
              </div>
            </div>
          </div>
          {/* Horizontal PLAN START*/}

          {/*begin::Col*/}
          <div className="col-xl-12 card mb-5">
            {/*begin::List widget 10*/}

            <div className="card-header border-3 mb-5 pt-2" style={{ padding: '1rem 1rem 1rem 1.38rem' }}>
              {/*begin::Title*/}
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder text-gray-800">
                  Bucket Name
                </span>
                <span className="text-gray-400 mt-1 fw-bold fs-6">
                  Input the bucket name
                </span>
              </h3>
            </div>
            <div className="mx-5 mb-3">
              <div
                className="row g-9"
                data-kt-buttons="true"
                data-kt-buttons-target="[data-kt-button]"
              >
                <div className="col-md-4 col-lg-4 col-xxl-4">
                  <div className="">
                    {/*begin::Input group*/}
                    <div className="input-group mb-5">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="storage_1"
                        defaultValue={"mybucket" + makeid(8) + makeid(8)}
                      />
                    </div>
                    {/*end::Input group*/}
                  </div>
                </div>
              </div>


            </div>
          </div>
          {/*end::Col*/}

          <div className="col-xl-12 card" style={{ paddingBottom: marginBottom }}>
            <div className="card-header p-0 mx-5 mb-5 pt-2" style={{ border: "none" }}>
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1">
                  Total Cost
                </span>
                <span className="fs-xl-2x fs-2 fw-boldest">
                  <span>{userData != null ? (<>{userData.currencyprefix}</>) : ("")}</span>
                  <span id="total_cost">0</span>&nbsp; /mon </span>
                <span className="text-muted mt-1 fw-bold fs-7" />
              </h3>
              <div className="card-toolbar">
                <a style={{ width: "100%" }}
                  className="btn  btn-primary btn-active-light-primary mt-2" id="object-btn" onClick={addObjectStorage}>
                  Create Storage &nbsp; &nbsp;
                  <span className="svg-icon svg-icon-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z"
                        fill="black"
                      />
                      <path
                        opacity="0.3"
                        d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                </a>
              </div>
              {pageStatus &&
                <TopBarHeaderLine />}
            </div>
          </div>
          {/*end::Tables Widget 9*/}
        </form>
      </>) : (<><TopBarHeaderLine /><TableLoader /></>)}
    </>
  );
};


const mapStateToProps = (state) => ({
  deployInfo: state.objectCluster.objectDeploy,
});

const mapDispatchToProps = (dispatch) => ({
  updateDeployData: (data) => dispatch(addObjectDeployData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeployObjectStorage);

