import React from 'react';

const LoaderGif = () => {
    return (
        <>
             <div className="loader-gif-container">
          <img src="./assets/media/logos/loaderUtho.gif" alt="Loading..." className="loader-gif" />
        </div>
        <div style={{zIndex: "109"}} id="overlay_id" className="drawer-overlay"></div>
        </>
    );
}

export default LoaderGif;
