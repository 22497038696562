import { React, useEffect, useRef, useState } from 'react';
import TableLoader from '../../../Loaders/TableLoader';
import UseApi from '../../../../../CustomHooks/useApi';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function SnapshotListComponent(props) {
  const [snapData, setSnapData] = useState(null);
  const [snapLoader, setSnapLoader] = useState(false);
  const [selectedImage, setSelectedImage] = useState(props.image)
  const [selectedPublicId, setSelectedPublicId] = useState(props.stack)
  const [selectedPrivateId, setSelectedPrivateId] = useState(props.stack)
  const [isSnapSelected, setIsSnapSelected] = useState(false)
  const [snapshotId, setSnapshotId] = useState(props.snapshotid)
  const [type, setType] = useState('')
  const [snapId, setSnapId] = useState('')
  const [publicStack, setPublicStack] = useState(false)
  const [privateStack, setPrivateStack] = useState(false)
  const fieldsArray = useRef([]);
  const getSnapshots = async (dcslug) => {
    const snap_data = await UseApi("get", "snapshot/" + dcslug, "");
    if (snap_data && snap_data.snapshots) {
      setSnapLoader(false);
      if (snap_data.snapshots.length == 0) {
        setSnapData([]);
      } else {
        console.log(snap_data);
        // setSnapId(snap_data.snapshots.some(item => item.id === props.snapshotid))
        setSnapData(snap_data.snapshots);
      }
    }

  }
  console.log(selectedImage);
  const getStack = async (dc) => {
    const data = await UseApi("get", "cloud/getdeploy?dcslug=" + dc, "");
    console.log(data);
    if (Object.keys(data).length === 0) {
    } else {
      console.log(data);
      getStackPublic(data.stacks_public);
      // setStackPublicData([...data.stacks_public])
      getStackPrivate(data.stacks_private);
      setPublicStack(data.stacks_public.some(item => item.id === props.stack))
      setPrivateStack(data.stacks_private.some(item => item.id === props.stack))
    }
  };

  const updateImage = (type, imageid) => {
    if (props && props.selectSnapshot) {
      if (type == "snapshot") {
        props.selectSnapshot(type, imageid, '');
        MarkRibbonTick('marketplace', 0, "off");
        MarkRibbon("snapshot", imageid);
      } else {
        MarkRibbonTick('snapshot', 0, "off");
        var stackImage = document.querySelector("#main_stackimage");
        var mainStack = document.querySelector("#main_stack");
        if (stackImage && mainStack) {
          if (stackImage != '' && mainStack != '') {
            props.selectSnapshot(type, mainStack.value, stackImage.value);
          } else {
            const dt = toast.loading("");
            toast.update(dt, {
              render: "Please Select Stack and Stack Image", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
          }
        }
      }

    }
  }
  const selectOne = (type, select_id) => {
    var appData = ["Os", "backup", "snapshot", "iso"];
    if (appData) {
      appData.forEach((value) => {
        // //console.log("ribbon=> " + value);
        MarkRibbonTick(value, 0, "off");
      });
      // //console.log("active ribbon => " + type);
      MarkRibbonTick(type, select_id, "on");
    }
  };
  const selectSnapshot = (data, snapshot_id, size) => {
    setType("snapshot")
    // updateImage("snapshot", snapshot_id)
    MarkRibbon("snapshot", snapshot_id);
  };

  const selectStackImage = (stack_image, value) => {
    setType(stack_image)
    // console.log(stack_image,"");
    setSelectedImage(value)
    var stackImage = document.querySelector("#main_stackimage");
    if (stackImage) {
      stackImage.value = value;
      // updateImage('stack', 0);
    }
  }

  const handleUpdate = () => {
    console.log(type);
    if (type == "snapshot") {
      updateImage("snapshot", snapId)
      MarkRibbon("snapshot", snapId);
    } else {
      updateImage('stack', 0);
    }
  }

  const selectMarketPlaceOnLoad = async (action, id,publicData) => {
    console.log(action, id);
    fieldsArray.current = [];

    var mainStack = document.querySelector("#main_stack");

    if (mainStack) {
      mainStack.value = id;
      MarkRibbon("marketplace", id);
      // const marketData = await UseApi("get", "stacks", "nothing");
      var marketData = null;
      var market_fields_public = document.querySelector("#market_fields_public");
      var market_fields_private = document.querySelector("#market_fields_private");
      if (market_fields_public && market_fields_private) {
        if (action == "public") {
          console.log("public", marketData, id, publicData);
          marketData = publicData;
          if (marketData) {
            market_fields_public.classList.remove("d-none");
            market_fields_private.classList.add("d-none");
            console.log(marketData);
            setCurrMarketplaceLoaderPublic(false);
            if (marketData.length != 0) {
              marketData.map((value, index) => {
                console.log(value.id, id);
                if (value.id == id) {
                  if (value.distro.length == 0) {
                    setCurrMarketplaceDistroPublic([]);
                  } else {
                    setCurrMarketplaceDistroPublic(value.distro);
                  }
                  if (value.fields.length == 0) {
                    setCurrMarketplacePublic([]);
                  } else {
                    setCurrMarketplacePublic(value.fields);
                  }
                }
              });
            }
          }
        } else if (action == "private") {
          // selectOsApp("private", id, "stacks_private");
          marketData = stackPrivateData;
          if (marketData) {
            market_fields_public.classList.add("d-none");
            market_fields_private.classList.remove("d-none");
            setCurrMarketplaceLoaderPrivate(false);
            if (marketData.length != 0) {
              marketData.map((value, index) => {
                console.log(value.id, id);
                if (value.id == id) {
                  if (value.distro.length == 0) {
                    setCurrMarketplaceDistroPrivate([]);
                  } else {
                    setCurrMarketplaceDistroPrivate(value.distro);
                  }
                  if (value.fields.length == 0) {
                    setCurrMarketplacePrivate([]);
                  } else {
                    setCurrMarketplacePrivate(value.fields);
                  }
                }
              });
            }
          }
        }
      }
    }

  };

  const selectMarketPlace = async (action, id) => {
    console.log(action, id);
    fieldsArray.current = [];

    var mainStack = document.querySelector("#main_stack");

    if (mainStack) {
      mainStack.value = id;
      MarkRibbon("marketplace", id);
      // const marketData = await UseApi("get", "stacks", "nothing");
      var marketData = null;
      var market_fields_public = document.querySelector("#market_fields_public");
      var market_fields_private = document.querySelector("#market_fields_private");
      if (market_fields_public && market_fields_private) {
        if (action == "public") {
          console.log("public", marketData, id, stackPublicData);
          marketData = stackPublicData;
          if (marketData) {
            market_fields_public.classList.remove("d-none");
            market_fields_private.classList.add("d-none");
            console.log(marketData);
            setCurrMarketplaceLoaderPublic(false);
            if (marketData.length != 0) {
              marketData.map((value, index) => {
                console.log(value.id, id);
                if (value.id == id) {
                  if (value.distro.length == 0) {
                    setCurrMarketplaceDistroPublic([]);
                  } else {
                    setCurrMarketplaceDistroPublic(value.distro);
                  }
                  if (value.fields.length == 0) {
                    setCurrMarketplacePublic([]);
                  } else {
                    setCurrMarketplacePublic(value.fields);
                  }
                }
              });
            }
          }
        } else if (action == "private") {
          // selectOsApp("private", id, "stacks_private");
          marketData = stackPrivateData;
          if (marketData) {
            market_fields_public.classList.add("d-none");
            market_fields_private.classList.remove("d-none");
            setCurrMarketplaceLoaderPrivate(false);
            if (marketData.length != 0) {
              marketData.map((value, index) => {
                console.log(value.id, id);
                if (value.id == id) {
                  if (value.distro.length == 0) {
                    setCurrMarketplaceDistroPrivate([]);
                  } else {
                    setCurrMarketplaceDistroPrivate(value.distro);
                  }
                  if (value.fields.length == 0) {
                    setCurrMarketplacePrivate([]);
                  } else {
                    setCurrMarketplacePrivate(value.fields);
                  }
                }
              });
            }
          }
        }
      }
    }

  };

  const handleFieldChange = (index, name, value) => {
    const fieldName = "stack_fields[" + index + "][" + name + "]";

    // Check if an object with the given index exists in the array
    let fieldObject = fieldsArray.current[index];

    // If not, create a new object
    if (!fieldObject) {
      fieldObject = {};
      fieldsArray.current[index] = fieldObject;
    }

    // Update the object with the field value
    fieldObject[name] = value;

    //console.log(fieldsArray.current);
  };

  function MarkRibbon(type, select_id) {

    MarkRibbonTick(type, 0, "off");

    MarkRibbonTick(type, select_id, "on");

  }

  function MarkRibbonTick(type, ribbon_id, action) {
    var tabSelect = document.querySelectorAll([".tickMark" + type]);
    if (tabSelect) {
      tabSelect.forEach((clist) => {
        clist.classList.add("d-none");
      });
    }
    if (action == "on") {
      var currentTab = document.querySelector(
        "#tickMark" + type + "-" + ribbon_id
      );
      if (currentTab) {
        currentTab.classList.remove("d-none");
      }
    }
  }

  useEffect(() => {
    if (props && props.dcslug) {
      getStack(props.dcslug);
      getSnapshots(props.dcslug)
      setTimeout(() => {
        if (props.snapshotid != 0) {
          MarkRibbon("snapshot", props?.snapshotid);
        } else {
          MarkRibbon("marketplace", props?.stack);
          var public_stack_id = document.querySelector("#public_stack_" + props?.stack);
          var private_stack_id = document.querySelector("#private_stack_" + props?.stack);
          if (public_stack_id) {
            public_stack_id.click();
          }
          if (private_stack_id) {
            private_stack_id.click();
          }
        }
      }, 2000)

    }
  }, [props])



  const [stackPublicData, setStackPublicData] = useState([]);
  const [stackPublicLoader, setStackPublicLoader] = useState(false);

  const getStackPublic = (publicData) => {
    setStackPublicLoader(true);
    // const marketData=await UseApi("get","stacks","nothing");
    ////console.log(marketData);
    console.log(publicData);
    if (publicData) {
      setStackPublicLoader(false);
      if (publicData.length != 0) {
        if (publicData) {
          setStackPublicData([...publicData]);
          selectMarketPlaceOnLoad(privateStack ? "private" : "public", props.stack,publicData)
        }
      } else {
        setStackPublicData([]);
      }
    }
  };

  const [stackPrivateData, setStackPrivateData] = useState(null);
  const [stackPrivateLoader, setStackPrivateLoader] = useState(false);

  const getStackPrivate = async (privateData) => {
    setStackPrivateLoader(true);
    if (privateData) {
      setStackPrivateLoader(false);
      if (privateData.length != 0) {
        if (privateData) {
          setStackPrivateData(privateData);
        }
      } else {
        setStackPrivateData([]);
      }
    }
  };

  const [currMarketplacePublic, setCurrMarketplacePublic] = useState(null);
  const [currMarketplaceDistroPublic, setCurrMarketplaceDistroPublic] = useState(null);
  const [currMarketplaceLoaderPublic, setCurrMarketplaceLoaderPublic] = useState(false);

  const [currMarketplacePrivate, setCurrMarketplacePrivate] = useState(null);
  const [currMarketplaceDistroPrivate, setCurrMarketplaceDistroPrivate] = useState(null);
  const [currMarketplaceLoaderPrivate, setCurrMarketplaceLoaderPrivate] = useState(false);
  console.log(stackPrivateData);
  return (<>
    <div className="col-xl-12 card mb-5">
      {/*begin::List widget 10*/}
      {/*begin::Header*/}
      <div className="card-header border-3 mb-5 pt-2">
        {/*begin::Title*/}
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder text-gray-800">
            Select Image
          </span>
          <span className="text-gray-400 mt-1 fw-bold fs-6">
            Stack, Snapshot
          </span>
        </h3>
        {/*end::Title*/}
      </div>
      {/*end::Header*/}
      {/*begin::Body*/}
      <div className="mx-5 pb-5">
        {/*begin::Nav*/}
        <div className='card-header border-3 mb-5 pt-2'>
          <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-trakt_charts_widget_1_chartnsparent fs-5 fw-bolder">
            <div className='d-flex'>
              <li className="nav-item">
                <a
                  className={`nav-link py-5 me-6 ${!isSnapSelected && "text-active-primary active"}`}
                  data-bs-toggle="tab"
                  href="#stack-tab"
                  id="stack_nav"
                  onClick={() => setIsSnapSelected(false)}
                >
                  Stacks
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link py-5 me-6 ${isSnapSelected && "text-active-primary active"}`}
                  data-bs-toggle="tab"
                  href="#snapshot-tabs"
                  onClick={() => setIsSnapSelected(true)}
                >
                  Snapshots
                </a>
              </li>
            </div>
          </ul>
          <div className='card-toolbar' style={{ marginLeft: "auto" }}>
            <a
              className="btn btn-sm btn-primary btn-active-primary"
              id="update-scaling-config-btn"
              // style={{marginLeft:'94rem',marginTop:'-7rem'}}
              // onChange={(e) => selectStackImage("stacks_private", e.target.value)}
              onClick={handleUpdate}
            >
              {" "}
              Update
            </a>
          </div>
          <label className="form-label text-white"></label>

        </div>
        {/*end::Nav*/}
        {/*begin::Tab Content*/}
        <input type="hidden" name="stack" id="main_stack" />
        <input type="hidden" name="stackimage" id="main_stackimage" />
        <div className="tab-content mt-5 pe-8">
          {/*begin::Tap pane*/}
          <div className={`tab-pane fade ${isSnapSelected && 'show active'}`} id="snapshot-tabs">
            <div
              id="getsnapshot"
              className="row g-9"
              data-kt-buttons="true"
            >

              {snapLoader == true ? (
                <>
                  <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                    {/* <div className="small-spinner"></div> */}
                    <TableLoader />
                  </div>
                </>
              ) : (
                <>
                  {snapData != null ? (
                    <>
                      {snapData.length != 0 ? (
                        <>
                          {snapData.map((value, index) => (
                            <>
                              <div className="col-md-4 col-lg-4 col-xxl-4">
                                <input
                                  className="btn-check deploy_checkbox snapshot_radio"
                                  type="radio"
                                  name="image_OS"
                                  defaultValue={value.id}
                                  id={"snapshotid" + value.id}
                                  checked={value.id == snapshotId}
                                  onChange={(e) => setSnapshotId(e.target.value)}
                                />
                                <label
                                  onClick={(e) => {
                                    selectSnapshot(value.id, value.id, value.size)
                                    setSnapId(value.id)
                                  }}
                                  htmlFor={
                                    "snapshotid" + value.id
                                  }
                                  className=" label versionOs text-start border-1 border-gray-300 border px-6 py-1 d-flex"
                                >
                                  <span className="svg-icon svg-icon-primary svg-icon-4x me-4">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={24}
                                      height={24}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        opacity="0.3"
                                        d="M22 5V19C22 19.6 21.6 20 21 20H19.5L11.9 12.4C11.5 12 10.9 12 10.5 12.4L3 20C2.5 20 2 19.5 2 19V5C2 4.4 2.4 4 3 4H21C21.6 4 22 4.4 22 5ZM7.5 7C6.7 7 6 7.7 6 8.5C6 9.3 6.7 10 7.5 10C8.3 10 9 9.3 9 8.5C9 7.7 8.3 7 7.5 7Z"
                                        fill="black"
                                      />
                                      <path
                                        d="M19.1 10C18.7 9.60001 18.1 9.60001 17.7 10L10.7 17H2V19C2 19.6 2.4 20 3 20H21C21.6 20 22 19.6 22 19V12.9L19.1 10Z"
                                        fill="black"
                                      />
                                    </svg>
                                  </span>
                                  <span className="d-block fw-bold text-start py-2">
                                    <span className="text-dark fw-bolder d-block fs-6">
                                      {value.name != null ? (value.name) : (value.id)}
                                    </span>
                                    <span className="text-muted fw-bold fs-6">
                                      {" "}
                                      {value.createdate}{" "}
                                    </span>
                                  </span>
                                  {/*begin::Ribbon*/}
                                  <div
                                    className={`${value.id == snapshotId ? "" : "d-none"} tickMarksnapshot ribbon ribbon-triangle ribbon-top-start border-primary`}
                                    id={
                                      "tickMarksnapshot-" +
                                      value.id
                                    }
                                  >
                                    {/*begin::Ribbon icon*/}
                                    <div className="ribbon-icon mt-n5">
                                      <i className="bi bi-check2 fs-2 text-white" />
                                    </div>
                                    {/*end::Ribbon icon*/}
                                  </div>
                                  {/*end::Ribbon*/}
                                </label>
                              </div>
                            </>
                          ))}
                        </>
                      ) : (
                        <>
                          <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                            {/*begin::Wrapper*/}

                            <div className="pt-lg-10 mb-10">
                              {/*begin::Message*/}
                              <div className="fw-bold fs-5  mb-15 badge badge-light-primary">
                                You don't have any Snapshots on
                                this location
                              </div>
                              {/*end::Message*/}
                            </div>
                            {/*end::Wrapper*/}
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {/*begin::Content*/}
                      <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                        {/*begin::Wrapper*/}
                        <div className="pt-lg-10 mb-10">
                          {/*begin::Message*/}
                          <div className="fw-bold fs-5  mb-15 badge badge-light-primary">
                            Please select Datacenter Location to
                            view snapshots list.
                          </div>
                          {/*end::Message*/}
                        </div>
                        {/*end::Wrapper*/}
                      </div>
                      {/*end::Content*/}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          {/*end::Tap pane*/}
          {/*begin::Tap pane*/}
          <div className={`tab-pane fade ${!isSnapSelected && 'show active'}`} id="stack-tab">
            <ul className="mx-5 mb-3 nav nav-stretch nav-line-tabs nav-line-tabs-2x border-trakt_charts_widget_1_chartnsparent fs-5 fw-bolder">
              <li className="nav-item">
                <a
                  className={`nav-link text-active-primary py-5 me-6 fs-6 ${privateStack && 'active'}`}
                  data-bs-toggle="tab"
                  href="#private-stack-tabs"
                  id="private_tab"
                >
                  Own Stacks
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link text-active-primary py-5 fs-6 me-6 ${publicStack && 'active'}`}
                  data-bs-toggle="tab"
                  href="#public-stack-tabs"
                  id="public_tab"
                >
                  Community Stacks
                </a>
              </li>
            </ul>
            {/* <div className="col-xl-4 mb-xl-4 mt-10"> */}
            {/* </div> */}
            <div className="tab-content mt-5 pe-8">
              <div className={`tab-pane fade ${privateStack && 'show active'}`} id="private-stack-tabs">
                {/*begin::Row*/}
                <div
                  id="stack-private-data"
                  className="row g-9"
                  data-kt-buttons="true"
                  data-kt-buttons-target="[data-kt-button]"
                >
                  {/*begin::Col*/}
                  {stackPrivateLoader == false ? (
                    <>
                      {stackPrivateData != null &&
                        stackPrivateData.length != 0 ? (
                        <>
                          {stackPrivateData.map((value, index) => (
                            <>
                              <div className="col-md-4 col-lg-12 col-xxl-4">
                                {/*begin::Option*/}
                                <input
                                  className="btn-check deploy_checkbox marketplace_radio"
                                  type="radio"
                                  name="image_OS"
                                  defaultValue={value.id}
                                  id={"marketplace" + value.id}
                                  checked={value.id == selectedPrivateId}
                                  onChange={(e) => setSelectedPrivateId(e.target.value)}
                                />
                                <label
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    selectOne(
                                      "marketplace",
                                      value.id
                                    );
                                    selectMarketPlace("private", value.id);
                                  }}
                                  id={"private_stack_" + value.id}
                                  htmlFor={"marketplace" + value.id}
                                  className=" label versionOs text-start border-1 border-gray-300 border px-6 py-2 d-flex"
                                >
                                  {/*begin::Svg Icon | path: icons/duotune/communication/com003.svg*/}
                                  {/*begin::Avatar*/}
                                  <div className="symbol symbol-50px me-5">
                                    <img
                                      src={"/assets/marketplace/private.svg"}
                                      className="pb-2"
                                      alt=""
                                    />

                                  </div>
                                  {/*end::Svg Icon*/}
                                  <span className="d-block  text-start p-3">
                                    <span className="text-dark d-block fs-4">
                                      {value.title}
                                    </span>
                                  </span>
                                  {/*begin::Ribbon*/}
                                  <div
                                    className={`${value.id == selectedPrivateId ? "" : "d-none"} tickMarkmarketplace ribbon ribbon-triangle ribbon-top-start border-primary`}
                                    id={
                                      "tickMarkmarketplace-" +
                                      value.id
                                    }
                                  >
                                    {/*begin::Ribbon icon*/}
                                    <div className="ribbon-icon mt-n5">
                                      <i className="bi bi-check2 fs-2 text-white" />
                                    </div>
                                    {/*end::Ribbon icon*/}
                                  </div>
                                  {/*end::Ribbon*/}
                                </label>
                                {/*end::Option*/}
                              </div>
                            </>
                          ))}
                        </>
                      ) : (
                        <>
                          <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                            {/*begin::Wrapper*/}

                            <div className="pt-lg-10 mb-10">
                              {/*begin::Message*/}
                              <div className="fw-bold fs-5  mb-15 badge badge-light-primary">
                                No Stack Available
                              </div>
                              {/*end::Message*/}
                            </div>
                            {/*end::Wrapper*/}
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                        {/* <div className="small-spinner"></div> */}
                        <TableLoader />
                      </div>
                    </>
                  )}

                  {/*end::Col*/}
                </div>
                {/*end::Row*/}
                {/*begin:: marketplace fields*/}
                <div className='row g-9 mt-5'>
                  <div
                    className="col-xl-12 mb-xl-12 mt-10"
                    id="market_fields_private"
                  >
                    {currMarketplaceLoaderPrivate == false ? (
                      <>
                        {currMarketplaceDistroPrivate != null ? (
                          <>
                            {currMarketplaceDistroPrivate.length != 0 ? (
                              <>
                                <div className="fv-row mb-10 fv-plugins-icon-container">
                                  <label className="form-label ">
                                    Select Stack Image
                                  </label>
                                  <select
                                    className="form-select form-select-sm image_field"
                                    onChange={(e) => selectStackImage("stacks_private", e.target.value)}
                                    value={selectedImage}
                                  >
                                    <option value="">Select Image</option>
                                    {currMarketplaceDistroPrivate.map(
                                      (value, index) => (
                                        <>
                                          <option key={index} value={value}>
                                            {value}
                                          </option>
                                        </>
                                      )
                                    )}
                                  </select>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )}
                        {currMarketplacePrivate != null ? (
                          <>
                            {Object.keys(currMarketplacePrivate).length !=
                              0 ? (
                              <>
                                {currMarketplacePrivate.map((value, index) => (
                                  <>
                                    <div className="fv-row mb-10 fv-plugins-icon-container">
                                      <label className="form-label ">
                                        {value.label}
                                      </label>
                                      <input
                                        type="text"
                                        name={
                                          "stack_fields[" +
                                          index +
                                          "][" +
                                          value.name +
                                          "]"
                                        }
                                        defaultValue=""
                                        placeholder={value.example}
                                        className="form-control form-control-sm form-control form-control-sm-lg"
                                        onChange={(e) => handleFieldChange(index, value.name, e.target.value)}
                                      />
                                    </div>
                                  </>
                                ))}
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                          {/* <div className="small-spinner"></div> */}
                          <TableLoader />
                        </div>
                      </>
                    )}

                  </div>
                </div>
              </div>
              <div className={`tab-pane fade ${publicStack && 'show active'}`} id="public-stack-tabs">
                {/*begin::Row*/}
                <div
                  id="stack-data"
                  className="row g-9"
                  data-kt-buttons="true"
                  data-kt-buttons-target="[data-kt-button]"
                >
                  {/*begin::Col*/}
                  {stackPublicLoader == false ? (
                    <>
                      {stackPublicData != null &&
                        stackPublicData.length != 0 ? (
                        <>
                          {stackPublicData.map((value, index) => (
                            <>
                              <div className="col-md-4 col-lg-12 col-xxl-4">
                                {/*begin::Option*/}
                                <input
                                  className="btn-check deploy_checkbox marketplace_radio"
                                  type="radio"
                                  name="image_OS"
                                  defaultValue={value.id}
                                  id={"marketplace" + value.id}
                                  checked={value.id == selectedPublicId}
                                  onChange={(e) => setSelectedPublicId(e.target.value)}
                                />
                                <label
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    selectOne(
                                      "marketplace",
                                      value.id
                                    );
                                    selectMarketPlace("public", value.id);
                                  }}
                                  id={"public_stack_" + value.id}
                                  htmlFor={"marketplace" + value.id}
                                  className=" label versionOs text-start border-1 border-gray-300 border px-6 py-2 d-flex"
                                >
                                  {/*begin::Svg Icon | path: icons/duotune/communication/com003.svg*/}
                                  {/*begin::Avatar*/}
                                  <div className="symbol symbol-50px me-5">
                                    <img
                                      src={"/assets/marketplace/public.svg"}
                                      className="pb-2"
                                      alt=""
                                    />

                                  </div>
                                  {/*end::Svg Icon*/}
                                  <span className="d-block  text-start p-3">
                                    <span className="text-dark d-block fs-4">
                                      {value.title}
                                    </span>
                                  </span>
                                  {/*begin::Ribbon*/}
                                  <div
                                    className={`${value.id == selectedPublicId ? "" : "d-none"} tickMarkmarketplace ribbon ribbon-triangle ribbon-top-start border-primary`}
                                    id={
                                      "tickMarkmarketplace-" +
                                      value.id
                                    }
                                  >
                                    {/*begin::Ribbon icon*/}
                                    <div className="ribbon-icon mt-n5">
                                      <i className="bi bi-check2 fs-2 text-white" />
                                    </div>
                                    {/*end::Ribbon icon*/}
                                  </div>
                                  {/*end::Ribbon*/}
                                </label>
                                {/*end::Option*/}
                              </div>
                            </>
                          ))}
                        </>
                      ) : (
                        <>
                          <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                            {/*begin::Wrapper*/}

                            <div className="pt-lg-10 mb-10">
                              {/*begin::Message*/}
                              <div className="fw-bold fs-5  mb-15 badge badge-light-primary">
                                No Stack Available
                              </div>
                              {/*end::Message*/}
                            </div>
                            {/*end::Wrapper*/}
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                        {/* <div className="small-spinner"></div> */}
                        <TableLoader />
                      </div>
                    </>
                  )}

                  {/*end::Col*/}
                </div>
                {/*end::Row*/}
                {/*begin:: marketplace fields*/}
                <div
                  className="col-xl-12 mb-xl-12 mt-10"
                  id="market_fields_public"
                >
                  {currMarketplaceLoaderPublic == false ? (
                    <>
                      {currMarketplaceDistroPublic != null ? (
                        <>
                          {currMarketplaceDistroPublic.length != 0 ? (
                            <>
                              <div className="fv-row mb-10 fv-plugins-icon-container">
                                <label className="form-label ">
                                  Select Stack Image
                                </label>
                                <select
                                  className="form-select form-select-sm image_field"
                                  onChange={(e) => selectStackImage("stacks_public", e.target.value)}
                                  value={selectedImage}
                                >
                                  <option value="">Select Image</option>
                                  {currMarketplaceDistroPublic.map(
                                    (value, index) => (
                                      <>
                                        <option value={value}>
                                          {value}
                                        </option>
                                      </>
                                    )
                                  )}
                                </select>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      {currMarketplacePublic != null ? (
                        <>
                          {Object.keys(currMarketplacePublic).length !=
                            0 ? (
                            <>
                              {currMarketplacePublic.map((value, index) => (
                                <>
                                  <div className="fv-row mb-10 fv-plugins-icon-container">
                                    <label className="form-label ">
                                      {value.label}
                                    </label>
                                    <input
                                      type="text"
                                      name={
                                        "stack_fields[" +
                                        index +
                                        "][" +
                                        value.name +
                                        "]"
                                      }
                                      defaultValue=""
                                      placeholder={value.example}
                                      className="form-control form-control-sm form-control form-control-sm-lg"
                                      onChange={(e) => handleFieldChange(index, value.name, e.target.value)}
                                    />
                                  </div>
                                </>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                        {/* <div className="small-spinner"></div> */}
                        <TableLoader />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            {/*end::marketplace fields*/}
          </div>
          {/*end::Tap pane*/}

        </div>
        {/*end::Tab Content*/}
      </div>
      {/*end: Card Body*/}
      {/*end::List widget 10*/}
    </div>
  </>)
}