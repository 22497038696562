import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import UsePost from "../../../../../CustomHooks/usePost";

export default function UpdatePrivateRegistry(props) {
    const openDrawer = () => {

        var drawerElement = document.querySelector("#cr-modal");
        var drawer_overlay = document.querySelector("#drawer_overlay_cr");
        if (drawerElement && drawer_overlay) {
            drawerElement.classList.toggle("drawer-on");
            if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
                drawerElement.style.width = "100%";
            } else {
                drawerElement.style.width = "40%";
            }
            drawer_overlay.classList.toggle("d-none");
        }
    };

    console.log(props);
    const attachCr = async (id) => {
        var update_cr = document.querySelector("#update_cr_id");
        const updateBtn = document.querySelector("#update-cr-btn");
        const idElement = document.querySelector('#private_id_for_update')
        if (update_cr && updateBtn && idElement.value) {
            toast.dismiss();
            const dt = toast.loading("Please wait...");
            if (updateBtn) {
                var preContent = updateBtn.innerHTML;
                updateBtn.classList.add("disabled");
                updateBtn.innerHTML = 'Please wait... <span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
                const responseData = await UsePost("put", `registry/project/${idElement.value}`, 'registryUpdate');

                console.log(responseData);
                if (responseData.status === "error") {
                    toast.update(dt, {
                        render: responseData.message,
                        type: "error",
                        isLoading: false,
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else if (responseData.status === "success") {
                    // props.updateDatabase()
                    toast.update(dt, {
                        render: responseData.message,
                        type: "success",
                        isLoading: false,
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    openDrawer();
                    props.getAllRegistry();
                }
                updateBtn.classList.remove("disabled");
                updateBtn.innerHTML = preContent;
            }
        }

    };

    const handleChangeCrType = (value) => {
        let element = document.querySelector("#cr_type")
        element.value = value == "public" ? true : false
    }

    useEffect(() => {
        console.log(props?.data?.metadata);
        var selectElement = document.getElementById("update_cr_id");
        selectElement.value = props?.data?.metadata?.public == 'true' ? 'public' : 'private';
        let element = document.querySelector("#cr_type")
        element.value = props?.data?.metadata?.public
    }, [])
    return (
        <>
            <div id="drawer_overlay_cr" onClick={openDrawer} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>

            <div
                id="cr-modal"
                className="bg-body shadow drawer drawer-end"
                // data-kt-drawer="true"
                // data-kt-drawer-name="chat"
                // data-kt-drawer-activate="true"
                // data-kt-drawer-direction="end"
                data-kt-drawer-close="#close_ssh"
                // data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
                style={{ important: true, zIndex: 110 }}
            >
                {/*begin::Messenger*/}
                <div
                    className="card rounded-0 w-100 border-0"
                    id="kt_drawer_chat_messenger"
                >
                    {/*begin::Card header*/}
                    <div
                        className="card-header pe-5"
                        id="kt_drawer_chat_messenger_header"
                    >
                        {/*begin::Title*/}
                        <div className="card-title">
                            {/*begin::User*/}
                            <div className="d-flex justify-content-center flex-column me-3">
                                <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                                    Update Container Registry
                                </a>
                            </div>
                            {/*end::User*/}
                        </div>
                        {/*end::Title*/}
                        {/*begin::Card toolbar*/}
                        <div className="card-toolbar">
                            {/*begin::Close*/}
                            <div
                                onClick={openDrawer}
                                className="btn btn-sm btn-icon btn-active-light-primary"
                                id="close_ssh"
                            >
                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                                <span className="svg-icon svg-icon-2">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <rect
                                            opacity="0.5"
                                            x={6}
                                            y="17.3137"
                                            width={16}
                                            height={2}
                                            rx={1}
                                            transform="rotate(-45 6 17.3137)"
                                            fill="black"
                                        />
                                        <rect
                                            x="7.41422"
                                            y={6}
                                            width={16}
                                            height={2}
                                            rx={1}
                                            transform="rotate(45 7.41422 6)"
                                            fill="black"
                                        />
                                    </svg>
                                </span>
                                {/*end::Svg Icon*/}
                            </div>
                            {/*end::Close*/}
                        </div>
                        {/*end::Card toolbar*/}
                    </div>
                    {/*end::Card header*/}
                    {/*begin::Card body*/}
                    <div className="" id="">
                        {/*begin::Form*/}
                        <form
                            name="importkey"
                            id="registryUpdate"
                            className="form"
                        >
                            {/*begin::Modal body*/}
                            <div className="modal-body ">
                                {/*begin::Scroll*/}
                                <div
                                    className="scroll-y me-n7 pe-7"
                                    id="kt_modal_create_api_key_scroll"
                                    data-kt-scroll="true"
                                    data-kt-scroll-activate="{default: false, lg: true}"
                                    data-kt-scroll-max-height="auto"
                                    data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                                    data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                                    data-kt-scroll-offset="300px"
                                >
                                    {/*begin::Input group*/}
                                    <div className="mb-5 fv-row">
                                        {/*begin::Label*/}
                                        <label className="required fs-5 fw-bold mb-2">
                                            {" "}
                                            Update Registry Type
                                        </label>
                                        {/*end::Label*/}
                                        {/*begin::Input*/}
                                        <select className="form-select" id="update_cr_private_id" onChange={(e) => handleChangeCrType(e.target.value)}>
                                            <option value="public">Public</option>
                                            <option value="private">Private</option>
                                            {/* {fwData != null ? (<>
                                                {fwData.map((value, index) => (<>
                                                    <option value={value.id}> {value.name}</option>
                                                </>))}
                                            </>) : ('')} */}
                                        </select>
                                        {/*end::Input*/}
                                        <input
                                            type="hidden"
                                            name="public"
                                            id="cr_type"
                                        />
                                    </div>
                                    {/*end::Input group*/}

                                </div>
                                {/*end::Scroll*/}
                            </div>
                            {/*end::Modal body*/}
                            {/*begin::Modal footer*/}
                            <div className="modal-footer ">
                                {/*begin::Button*/}
                                <a
                                    onClick={attachCr}
                                    className="btn btn-primary w-100"
                                    id="update-cr-btn"
                                >
                                    <span className="indicator-label">
                                        Update Container Registry
                                    </span>
                                </a>
                                {/*end::Button*/}
                            </div>
                            {/*end::Modal footer*/}
                        </form>
                        {/*end::Form*/}
                    </div>
                    {/*end::Card body*/}
                </div>
                <input
                    type="hidden"
                    id="private_id_for_update"
                />
                {/*end::Messenger*/}
            </div>
        </>
    )
}