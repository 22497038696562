import { ACL_CONDITIONS } from '../../../../Redux/Services/constants';

const initialState={
                       aclConditions:[]
                    }
export default function LoadbalancerReducer(state=initialState, action){

       switch (action.type) {
        case ACL_CONDITIONS:
            return(
                {aclConditions:action.data}
               )   
        default:
            return state;
       }
}