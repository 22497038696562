import {React} from 'react';

export default function Regards(props){
    if(props && props.regardsHead && (props.email || props.name || props.mobile)){

    return(<>
     <div style={{ paddingBottom: 10 }}>
                 
                      {props.regardsHead}, 
                      {props.name?(<><br />{props.name}</>):('')}
                      {props.email?(<><br />{props.email}</>):('')}
                      {props.mobile?(<><br />{props.mobile}</>):('')}

                    </div>
    </>)
    }
}