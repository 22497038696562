import React, { useState, useEffect, useRef } from "react";

export default function PublicIpTab(props) {
    const [ip, setIp] = useState("true")
    const [backendIp, setBackendIp] = useState(true)

    const handleOptionChange = (event) => {
        setIp(event.target.value)
        if (event.target.value == "true") {
            setBackendIp(true)
        } else {
            setBackendIp(false)
        }
    };

    console.log(backendIp);

    return (
        <>
                <div className="col-xl-12 card mb-5">
                    {/*begin::List widget 10*/}
                    {/*begin::Header*/}
                    <div className="card-header border-3 pt-4 ps-6 p-0">
                        {/*begin::Title*/}
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bolder text-gray-800">
                                Public IP
                            </span>
                            <span className="text-gray-400 mt-1 fw-bold fs-6">
                                Enable or disable public IP assignment for the server
                            </span>
                        </h3>
                        {/*end::Title*/}
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}

                    <div className=" border-3 mb-5 pt-2">
                        <div className="p-2 border-3 pt-2">
                            {/*begin::Radio group*/}
                            <div data-kt-buttons="true" className="row p-3">
                                <div className="col-6">
                                    {/*begin::Radio button*/}
                                    <label
                                        // onClick={(e) => showAuth("password")}
                                        className="btn btn-outline btn-outline-dashed d-flex flex-stack text-start p-6 mb-5"
                                        style={{ height: "100%" }}
                                    >
                                        {/*end::Description*/}
                                        <div className="d-flex align-items-center me-2">
                                            {/*begin::Radio*/}
                                            <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    // name="auth"
                                                    value={"true"}
                                                    checked={ip == "true"}
                                                    onChange={handleOptionChange}
                                                />
                                            </div>
                                            {/*end::Radio*/}
                                            {/*begin::Info*/}
                                            <div className="flex-grow-1">
                                                <h2 className="d-flex align-items-center fs-3 fw-bolder flex-wrap">
                                                    Enable
                                                </h2>
                                                <div className="fw-bold opacity-50">
                                                    A public IP address will be assigned to your server, allowing it to be accessed directly over the internet.
                                                </div>
                                            </div>
                                            {/*end::Info*/}
                                        </div>
                                        {/*end::Description*/}
                                    </label>
                                    {/*end::Radio button*/}
                                </div>
                                <div className="col-6">
                                    {/*begin::Radio button*/}
                                    <label
                                        // onClick={(e) => showAuth("ssh")}
                                        className="btn btn-outline btn-outline-dashed d-flex flex-stack text-start p-6"
                                        style={{ height: "100%" }}
                                    >
                                        {/*end::Description*/}
                                        <div className="d-flex align-items-center me-2">
                                            {/*begin::Radio*/}
                                            <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    // name="auth"
                                                    value={"false"}
                                                    checked={ip == "false"}
                                                    onChange={handleOptionChange}
                                                />
                                            </div>
                                            {/*end::Radio*/}
                                            {/*begin::Info*/}
                                            <div className="flex-grow-1">
                                                <h2 className="d-flex align-items-center fs-3 fw-bolder flex-wrap">
                                                    Disable
                                                </h2>
                                                <div className="fw-bold opacity-50">
                                                    No public IP will be assigned, meaning the server will only be accessible via internal or private networks
                                                </div>
                                            </div>
                                            {/*end::Info*/}
                                        </div>
                                        {/*end::Description*/}
                                    </label>
                                    {/*end::Radio button*/}
                                </div>
                            </div>
                            {/*end::Radio group*/}
                            <input
                                type="hidden"
                                name="enable_publicip"
                                value={backendIp}
                            />
                        </div>
                        {/*begin::List widget 10*/}


                        {/*end::List widget 10*/}
                    </div>
                    {/*end: Card Body*/}
                    {/*end::List widget 10*/}
                </div>
        </>
    )
}