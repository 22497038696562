import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import Swal from 'sweetalert2';
import ReactPaginate from "react-paginate";
import copy from "copy-to-clipboard";
import UsePost from "../../../../../../CustomHooks/usePost";
import UseApi from "../../../../../../CustomHooks/useApi";
import TopBarHeaderLine from "../../../../../../CustomHooks/TopBarHeaderLine";
const DatabaseUser = (props) => {
  const [pageStatus, setPageStatus] = useState(false)
  const [pageCount, setPageCount] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [itemOffset, setItemOffset] = useState(0);
  const [dummyDatabaseUserData, setDummyDatabaseUserData] = useState(null);
  const [databaseUserData, setDatabaseUserData] = useState(null);
  const [databaseInfo, setDatabaseInfo] = useState(null)
  const [activePage, setActivePage] = useState(0);
  const [currentDatabaseInfo, setCurrentDatabaseInfo] = useState(null);
  var query = window.location.href;
  var vars = query.split("/");
  var id = vars[4];
  const openDrawer = () => {
    var drawerElement = document.querySelector("#databaseUser-modal");
    drawerElement.classList.toggle("drawer-on");
    drawerElement.style.width = '500px';
  }
  const loaderFunc = () => {
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if (loader && no_record) {
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");

      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
  }


  const togglePassword = (action, pid) => {
    // console.log(action,pid);
    var hide_passwd = document.querySelector("#hide_passwd_" + pid);
    var show_passwd = document.querySelector("#show_passwd_" + pid);
    // console.log(hide_passwd,show_passwd);
    if (hide_passwd && show_passwd) {
      // console.log("sjc");
      if (action == "hide") {
        hide_passwd.classList.remove("d-none");
        show_passwd.classList.add("d-none");
      } else {
        hide_passwd.classList.add("d-none");
        show_passwd.classList.remove("d-none");
      }
    }
  }

  const copyToClipboard = (target, targetBy) => {
    var btn_id = document.querySelector("#" + targetBy);
    var target_id = document.querySelector("#" + target);
    if (btn_id && !btn_id.classList.contains('disabled')) {
      var copyText = btn_id.innerHTML;
      var copyEle = target_id.innerHTML;
      copy(copyEle);
      btn_id.innerHTML = `<svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9.89557 13.4982L7.79487 11.2651C7.26967 10.7068 6.38251 10.7068 5.85731 11.2651C5.37559 11.7772 5.37559 12.5757 5.85731 13.0878L9.74989 17.2257C10.1448 17.6455 10.8118 17.6455 11.2066 17.2257L18.1427 9.85252C18.6244 9.34044 18.6244 8.54191 18.1427 8.02984C17.6175 7.47154 16.7303 7.47154 16.2051 8.02984L11.061 13.4982C10.7451 13.834 10.2115 13.834 9.89557 13.4982Z"
        fill="black"
      />
    </svg>`;
      btn_id.classList.add('disabled')
      setTimeout(() => {
        btn_id.innerHTML = copyText;
        btn_id.classList.remove('disabled')
      }, 2000);
    }

  }
  // const copyToClipboard = (id) => {
  //   const ele = document.getElementById("ip_address_sec" + id);
  //   const eleText = document.getElementById("ip_address" + id);

  //   if (ele && eleText) {
  //     const copyText = eleText.innerText;

  //     // Check if the function is already running
  //     if (ele.dataset.isCopying === "true") {
  //       return;
  //     }

  //     // Set the flag to indicate that the function is running
  //     ele.dataset.isCopying = "true";

  //     // Create an off-screen textarea element to copy the text
  //     const tempTextArea = document.createElement("textarea");
  //     tempTextArea.value = copyText;
  //     document.body.appendChild(tempTextArea);
  //     tempTextArea.select();

  //     try {
  //       // Attempt to copy the text to the clipboard
  //       document.execCommand("copy");
  //     } catch (err) {
  //       console.error("Unable to copy:", err);
  //     }

  //     // Remove the temporary textarea from the DOM
  //     document.body.removeChild(tempTextArea);

  //     // Update the element temporarily to indicate it's copied
  //     const originalContent = ele.innerHTML;
  //     ele.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M9.89557 13.4982L7.79487 11.2651C7.26967 10.7068 6.38251 10.7068 5.85731 11.2651C5.37559 11.7772 5.37559 12.5757 5.85731 13.0878L9.74989 17.2257C10.1448 17.6455 10.8118 17.6455 11.2066 17.2257L18.1427 9.85252C18.6244 9.34044 18.6244 8.54191 18.1427 8.02984C17.6175 7.47154 16.7303 7.47154 16.2051 8.02984L11.061 13.4982C10.7451 13.834 10.2115 13.834 9.89557 13.4982Z" fill="black"/></svg>`;

  //     // Revert the element content when the text is copied
  //     const handleCopy = () => {
  //       ele.innerHTML = originalContent;
  //       ele.dataset.isCopying = "false"; // Reset the flag
  //       document.removeEventListener("copy", handleCopy);
  //     };

  //     document.addEventListener("copy", handleCopy);

  //     // Automatically remove the copy event listener after 2 seconds
  //     setTimeout(() => {
  //       document.removeEventListener("copy", handleCopy);
  //       ele.dataset.isCopying = "false"; // Reset the flag
  //     }, 2000);
  //   }
  // }

  console.log(props);
  const getDatabaseUsers = async () => {
    if (props) {
      if (props.data) {
        setDatabaseInfo(props.data)
        if (props.data.users.length == 0) {
          setDatabaseUserData([]);
          setDummyDatabaseUserData([])
          // setDatabaseInfo([])
        } else {
          setDatabaseUserData(props.data.users);
          setDummyDatabaseUserData(props.data.users)
          setPageCount(Math.ceil(props.data.users.length / perPage))
          // setDatabaseInfo(props.data)
          setCurrentDatabaseInfo(props.currentDatabaseInfo);
        }
      }
    }
  }

  const createDatabaseUser = async (event) => {
    toast.dismiss()
    event.preventDefault()
    var submitBtn = document.querySelector("#create-databaseUser-btn");
    var databases_user = document.querySelector('#databasesuser')
    if (submitBtn) {
      setPageStatus(true)
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      const dt = toast.loading("Please wait...");

      const responseData = await UsePost('post', 'databases/' + id + '/user', 'databaseUser-form');
      if (responseData) {
        setPageStatus(false)
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
        if (responseData.status === 'error') {
          toast.update(dt, {
            render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        } else if (responseData.status === 'success') {
          toast.update(dt, {
            render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          databases_user.value = ''
          if (props) {
            openDrawer();
            props.updateDatabase();
          }

        }
      }
    }
  }
  // const deleteDatabase=async(dbid)=>{
  //     var submitBtn=document.querySelector("#delete-database"+dbid+"-btn"); 

  //     if(submitBtn){

  //     toast.dismiss();
  //     swal({
  //       title:"Are you sure?",
  //       text: "Once deleted, you will not be able to recover this database!",
  //       icon: "warning",
  //       buttons: true,
  //       dangerMode: true,
  //       })
  //       .then(async(willDelete) => {
  //       if(willDelete) {
  //         var preContent=submitBtn.innerHTML;
  //         submitBtn.classList.add("disabled");
  //         submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';   
  //         const dt=toast.loading("Database deleting..."); 
  //     const dnsp=await UseApi('DELETE','databases/'+id+'/user/'+dbid,'');
  //     submitBtn.classList.remove("disabled");
  //     submitBtn.innerHTML=preContent;
  //     //console.log(dnsp);
  //     if(dnsp.status=='error'){
  //       toast.update(dt,{render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
  //     });
  //     }else if(dnsp.status=='success'){
  //       toast.update(dt,{render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
  //     }); 
  //     if(props){
  //         openDrawer();
  //         props.updateDatabase();
  //       }
  //     }
  //       }
  //       });
  //     }
  //   }
  useEffect(() => {
    getDatabaseUsers();
  }, [props])

  const copyClusterName = () => {
    var eleText = document.querySelector("#user_file_url");
    var ele = document.querySelector("#user_file_url_copy_btn");

    if (ele && eleText) {
      const copyText = eleText.innerText;
      const originalContent = ele.innerHTML;
      console.log(originalContent);

      navigator.clipboard.writeText(copyText).then(() => {
        ele.innerHTML = "(Copied)";

        setTimeout(() => {
          ele.innerHTML = "(copy)";
        }, 1000);
      }).catch(err => {
        console.error('Failed to copy text: ', err);
      });
    }
  };

  function handleButtonClick(event) {
    console.log(event.target);
    if (event.target && event.target.id === 'user_file_url_copy_btn') {
      copyClusterName();
      event.stopPropagation();
    }
  }

  // Remove any existing listener before adding a new one (if necessary)
  document.removeEventListener('click', handleButtonClick);
  document.addEventListener('click', handleButtonClick);

  const deleteDatabaseUser = async (dbid) => {
    var submitBtn = document.querySelector("#delete-databaseuser" + dbid + "-btn");

    if (submitBtn) {

      toast.dismiss();
      const { value: isDelete } = await Swal.fire({
        title: 'Delete Database User',
        html: `
        <div className="d-flex align-items-center flex-wrap"> 
        <div id="kt_clipboard_4" className="me-5 fw-bold text-gray-600">
          <p class="fs-5">Enter the Database User name to Destroy:</p>
  
          <span class="fw-bold" id="user_file_url" >${dbid} &nbsp;</span><span id="user_file_url_copy_btn" style="cursor:pointer" class="text-primary"> ( copy ) </span> 
        </div>  
      </div>
     `,
        input: 'text',
        inputPlaceholder: 'Database User',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'delete-button bg-danger', // Add your custom class here
        },
      });
      if (isDelete == dbid) {
        setPageStatus(true)
        var preContent = submitBtn.innerHTML;
        submitBtn.classList.add("disabled");
        submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
        const dt = toast.loading("Database user deleting...");
        const dnsp = await UseApi('DELETE', 'databases/' + id + '/user/' + '?confirm=' + dbid, '');
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
        //console.log(dnsp);
        if (dnsp.status == 'error') {
          setPageStatus(false)
          toast.update(dt, {
            render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        } else if (dnsp.status == 'success') {
          setPageStatus(false)
          toast.update(dt, {
            render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          if (props) {
            props.updateDatabase();
          }
        }
      } else {
        setPageStatus(false)
        swal({
          title: "Invalid Database User",
          text: "Please confirm the valid database user!",
          icon: "warning",
          button: "OK",
        });
      }
    }
  }

  function convertTimestamp(timestamp) {
    const date = new Date(timestamp);
    // Format the date
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
      timeZone: 'UTC'
    }).format(date);

    return formattedDate.replace('GMT', '(UTC)');
  }

  function ProductInfo({ productdata }) {
    return (
      <>
        {productdata != null && Object.keys(productdata).length != 0 ? (productdata && productdata.map((value, index) => (
          <tr>
            <td className="ps-9 text-start text-gray-700">{value.db_user}</td>
            <td></td>
            <td className="text-center text-gray-700">
              <div className="d-flex flex-column w-100">
                <div className="">
                  {/* <span> */}
                  <span id={"hide_passwd_" + value.db_user} className="">
                    ******
                    <span className="text-primary ms-5" style={{ cursor: 'pointer' }} onClick={(e) => togglePassword("show", value.db_user)}>show</span>
                  </span>
                  <span id={"show_passwd_" + value.db_user} className="d-none">
                    <span id={"passwd_id_" + value.db_user}>{value.db_password}</span>
                    <span style={{ cursor: "pointer" }} className="text-primary ms-5" onClick={(e) => togglePassword("hide", value.db_user)}>hide</span>
                    <span id={"passwd_copy_" + value.db_user} onClick={(e) => copyToClipboard('passwd_id_' + value.db_user, 'passwd_copy_' + value.db_user)} className="svg-icon svg-icon-2 ms-5">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="#eff2f5"
                      >
                        <path
                          opacity="0.5"
                          d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                          fill="#eff2f5"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                          fill="#eff2f5"
                        />
                      </svg>
                    </span>
                  </span>
                </div>
              </div>
            </td>
            {databaseInfo?.engine?.toLowerCase() == "kafka" &&
              <td className="text-start text-gray-700 ps-9">
                <span className="fs-7 fw-bold">
                  {value.role}
                </span>
              </td>
            }
            <td className="text-center text-gray-700">
              <span className="fs-7 fw-bold">
                {(value.created_at)}
              </span>
            </td>
            {currentDatabaseInfo?.ref_type != "dbaas_node" ? (<>

              <td className="text-end pe-10" >
                <a
                  onClick={(e) => deleteDatabaseUser(value.db_user)}
                  id={"delete-databaseuser" + value.db_user + "-btn"}
                  className="btn btn-sm btn-danger sphide"
                >
                  Delete
                </a>
              </td>
            </>) : ('')}
          </tr >
        ))) : (<>
          <tr>
            <td colspan="12" className="" id="no_record" valign="top">
              <div className="mt-20 mb-20 text-center">
                <span className="fs-6 text-gray-800 fw-bold">no record found</span>
              </div>
            </td>
          </tr>
        </>)}

      </>
    )
  }

  const handleInputChange = (e) => {
    const inputValue = e.target.value.replace(/\s/g, ''); // Remove spaces
    e.target.value = inputValue; // Update input value directly
  };

  const handleSearch = (text) => {
    const endOffset = itemOffset + perPage;
    // Remove special characters from the input text
    const escapedText = text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    // Create a regular expression pattern to match the filtered text
    const regex = new RegExp(escapedText, "gi");

    // Filter the clusterData based on the regex pattern
    console.log(databaseUserData);
    let data = databaseUserData.filter((row) => {
      // Check if any property in the row matches the regex pattern
      return (
        row.db_user && row.db_user.toString().toLowerCase().includes(text.toLowerCase())
      )
    });

    // Update the state with the filtered data
    setDummyDatabaseUserData(data);;
    setPageCount(Math.ceil(data.length / perPage));
  }

  function PaginatedItems({ itemsPerPage, ProductData }) {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(ProductData.length / itemsPerPage));
    const handlePageClick = (event) => {
      setActivePage((old) => old = event.selected);
      const newOffset = event.selected * itemsPerPage;
      setItemOffset(newOffset);
    };

    return (
      <>
        <ProductInfo productdata={ProductData.slice(itemOffset, endOffset)} />
        <td colspan="12" className="ps-3">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            initialPage={activePage}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </td>
      </>
    );
  }
  const handleKeyPress = (event) => {
    console.log(event.key);
    if (event.key === 'Enter') {
      // Call your function here
      createDatabaseUser(event);
    }
  }

  console.log(databaseInfo);
  return (
    <>
      <div className="card">
        <div className="card-header">
          <div className="card-title">
            <h3>Databases Users </h3>
          </div>
          <div className="card-toolbar">
            <div
              className="position-relative my-1"
              style={{ marginRight: 20 }}
            >
              <input
                type="text"
                className="form-control form-control-sm"
                data-kt-table-widget-4="search"
                id="searchClient"
                // value={searchTerm}
                onChange={e => handleSearch(e.target.value)}
                placeholder="Search"
              />
            </div>

            <div style={{ marginRight: 20 }}>
              <div className="dataTables_length">
                <label className="w-125px">
                  <select
                    onChange={e => setPerPage(e.target.value)}
                    //data-control="select2"
                    className="form-select form-select-sm form-select-solid"
                    defaultValue={5}
                    id="perPage"

                  >
                    <option value={5} selected="">
                      5 Records
                    </option>
                    <option value={10}>10 Records</option>
                    <option value={25}>25 Records</option>
                    <option value={50}>50 Records</option>
                    <option value={100}>100 Records</option>
                  </select>
                </label>
              </div>
            </div>
            {props.currentDatabaseInfo?.ref_type != "dbaas_node" ? (<>
              <a onClick={openDrawer} className="btn btn-sm btn-primary my-1 sphide">
                + Add Database User
              </a>
            </>) : ('')}
          </div>
        </div>
        <div className="card-body p-0">
          <div className="table-responsive">
            <table
              className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
              id="kt_security_license_usage_table"
            >
              <thead className="border-gray-200 fs-5 fw-bold bg-secondary">
                <tr>
                  <th className="w-150px text-start ps-9">User</th>
                  <th className="w-50px ps-9"></th>
                  <th className="w-150px text-center">Password</th>
                  {databaseInfo?.engine?.toLowerCase() == "kafka" &&
                    <th className="w-150px ps-9">Role</th>
                  }
                  <th className="text-center w-150px">Created_at</th>
                  {currentDatabaseInfo?.ref_type != "dbaas_node" ? (<>
                    <th className="w-150px ps-9" />
                  </>) : ('')}
                </tr>
              </thead>
              <tbody id="public-IP-list" className="fw-6 fw-bold text-gray-600">
                {dummyDatabaseUserData != null ? (<>
                  {dummyDatabaseUserData.length == 0 ? (<>
                    <tr>
                      <td colspan="12" valign="top">
                        {/* <div className="d-flex flex-column flex-center">
                          <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                        </div> */}
                        <div className="mt-20 mb-20 text-center">
                          <span className="fs-6 text-gray-800 fw-bold">no record found</span>
                        </div>
                      </td>
                    </tr>
                  </>) : (<>
                    <PaginatedItems itemsPerPage={perPage} ProductData={dummyDatabaseUserData} />
                  </>)}
                </>) : ('')}
              </tbody>
            </table>
          </div>
        </div>

        <div
          id="databaseUser-modal"
          className="bg-body shadow drawer drawer-end"
          data-kt-drawer="true"
          data-kt-drawer-name="chat"
          data-kt-drawer-activate="true"
          data-kt-drawer-direction="end"
          data-kt-drawer-close="#close_ssh"
          data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
          style={{ maxWidth: '70%', important: true }}

        >
          {/*begin::Messenger*/}
          <div className="card rounded-0 w-100 border-0" id="kt_drawer_chat_messenger">
            {/*begin::Card header*/}
            <div className="card-header pe-5" id="kt_drawer_chat_messenger_header">
              {/*begin::Title*/}
              <div className="card-title">
                {/*begin::User*/}
                <div className="d-flex justify-content-center flex-column me-3">
                  {databaseInfo?.engine?.toLowerCase() == "kafka" ?
                    <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                      Add New User Permission
                    </a>
                    :
                    <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                      Add New Database User
                    </a>
                  }

                </div>
                {/*end::User*/}
              </div>
              {/*end::Title*/}
              {/*begin::Card toolbar*/}
              <div className="card-toolbar">
                {/*begin::Close*/}
                <div
                  onClick={openDrawer}
                  className="btn btn-sm btn-icon btn-active-light-primary"
                  id="close_ssh"
                >
                  {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                  <span className="svg-icon svg-icon-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.5"
                        x={6}
                        y="17.3137"
                        width={16}
                        height={2}
                        rx={1}
                        transform="rotate(-45 6 17.3137)"
                        fill="black"
                      />
                      <rect
                        x="7.41422"
                        y={6}
                        width={16}
                        height={2}
                        rx={1}
                        transform="rotate(45 7.41422 6)"
                        fill="black"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                </div>
                {/*end::Close*/}
              </div>
              {/*end::Card toolbar*/}
            </div>
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className="" id="">
              {/*begin::Form*/}
              <form id="databaseUser-form" className="form">
                {/*begin::Modal body*/}
                <div className="modal-body ">
                  {/*begin::Scroll*/}
                  <div
                    className="scroll-y me-n7 pe-7"
                    id="kt_modal_create_api_key_scroll"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                    data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                    data-kt-scroll-offset="300px"
                  >
                    {/*begin::Input group*/}
                    {databaseInfo?.engine?.toLowerCase() == "kafka" ?
                      <div className="mb-5 fv-row">
                        {/*begin::Label*/}
                        <label className="required fs-5 fw-bold mb-2"> Database User</label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <input
                          type="text"
                          className="form-control"
                          id="databasesuser"
                          name="name"
                          placeholder="Enter Database Username"
                          onChange={handleInputChange}
                          onKeyPress={handleKeyPress}
                        />

                        <label className="required fs-5 fw-bold mb-2 mt-5">Permission</label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <select className="form-select" name="permission">
                          <option value='admin'>Administrator</option>
                          <option value='producer'>Producer</option>
                          <option value='consumer'>Consumer</option>
                          <option value='both'>Consumer-Producer</option>
                        </select>
                        {/*end::Input*/}
                      </div>
                      :
                      <div className="mb-5 fv-row">
                        {/*begin::Label*/}
                        <label className="required fs-5 fw-bold mb-2"> Database User</label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <input
                          type="text"
                          className="form-control"
                          id="databasesuser"
                          name="name"
                          placeholder="Enter Database Username"
                          onChange={handleInputChange}
                          onKeyPress={handleKeyPress}
                        />
                        {/*end::Input*/}
                      </div>
                    }
                    {/*end::Input group*/}
                  </div>
                  {/*end::Scroll*/}
                </div>
                {/*end::Modal body*/}
                {/*begin::Modal footer*/}
                <div className="modal-footer">
                  {/*begin::Button*/}
                  <button
                    onClick={createDatabaseUser}
                    className="btn btn-primary w-100"
                    id="create-databaseUser-btn"
                  >
                    {databaseInfo?.engine?.toLowerCase() == "kafka" ?
                      <span className="indicator-label"> + Add User Permission</span>
                      :
                      <span className="indicator-label"> + Add Database User</span>
                    }
                  </button>
                  {/*end::Button*/}
                </div>
                {/*end::Modal footer*/}
              </form>
              {/*end::Form*/}
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Messenger*/}
        </div>
        {pageStatus &&
          <TopBarHeaderLine />}
      </div>
    </>
  );
}


export default DatabaseUser 
