import React, { useEffect } from "react";
// import "./custom.css?v=1";
import "../../cloud/helper/custom.css?v=1"
import { Link } from "react-router-dom";
import UseApi from "../../../../../CustomHooks/useApi";
import { toast } from "react-toastify";
export default function ActionList(props) {
    useEffect(() => {
        window.KTScroll.init();
        window.KTDrawer.init();
        window.KTMenu.createInstances();
        window.KTToggle.createInstances();
    }, []);
    function adjustContainerHeight() {
        var dropdown = document.getElementById('dropdown');
        var container = document.querySelector('.container');

        // Get the selected option's height
        var selectedOptionHeight = dropdown.options[dropdown.selectedIndex].offsetHeight;

        // Set the container height to the selected option's height
        container.style.height = selectedOptionHeight + 'px';
    }

    const handleDeleteRegistry = async(name,index) => {
            toast.dismiss();
            const dt = toast.loading("Please wait...");
            const submitBtn = document.querySelector("#delete-registry-btn-" + name);
            if (submitBtn) {
              var preContent = submitBtn.innerHTML;
              submitBtn.classList.add("disabled");
              submitBtn.innerHTML =
                '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
        
              const responseData = await UseApi("delete", `registry/project/${name}`, "");
        
              ////console.log(responseData);
              if (responseData.status === "error") {
                toast.update(dt, {
                  render: responseData.message,
                  type: "error",
                  isLoading: false,
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              } else if (responseData.status === "success") {
                toast.update(dt, {
                  render: responseData.message,
                  type: "success",
                  isLoading: false,
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                // props.updateDatabase()
                props.setAllData((old) => {
                    old.splice(index, 1);
                    return [...old];
                })
              }
              submitBtn.classList.remove("disabled");
              submitBtn.innerHTML = preContent;
            }
    }
    if (props && props.cloudid) {

        return (<>
            {/*begin::Trigger*/}
            <button
                type="button"
                className="btn btn-sm btn-active-light-dark btn-light-dark sphide text-gray-600"
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-start"
            >
                {/* <span className="svg-icon svg-icon-muted svg-icon-2 me-2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                        <path
                            opacity="0.3"
                            d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z"
                            fill="black"
                        />
                        <path
                            d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z"
                            fill="black"
                        />
                    </svg>
                </span> */}
                Action
            </button>
            {/*end::Trigger*/}
            {/*begin::Menu*/}
            <div
                className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-4"
                data-kt-menu="true"
            >
                {/*begin::Menu item*/}
                <div className="menu-item">
                    <a href="#" className="menu-link px-3">
                        <Link to={"/cloud/" + props.cloudid}>
                            <label
                                className="select-cloud"
                                htmlFor={1}
                                aria-hidden="aria-hidden"
                            >
                                {/*begin::Svg Icon | path: assets/media/icons/duotune/electronics/elc004.svg*/}
                                <span className="svg-icon svg-icon-muted svg-icon-2 me-2">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path
                                            d="M19 10C18.9 10 18.9 10 18.8 10C18.9 9.7 19 9.3 19 9C19 7.3 17.7 6 16 6C15.4 6 14.8 6.2 14.3 6.5C13.4 5 11.8 4 10 4C7.2 4 5 6.2 5 9C5 9.3 5.00001 9.7 5.10001 10H5C3.3 10 2 11.3 2 13C2 14.7 3.3 16 5 16H9L13.4 20.4C14 21 15 20.6 15 19.8V16H19C20.7 16 22 14.7 22 13C22 11.3 20.7 10 19 10Z"
                                            fill="black"
                                        />
                                    </svg>

                                </span>
                                {/*end::Svg Icon*/}
                                Summary
                            </label>
                        </Link>
                    </a>
                </div>
                {/*end::Menu item*/}
                {/*begin::Menu item*/}
                <div className="menu-item">
                    <a href="#" className="menu-link px-3">
                        <Link to={"/cloud/" + props.cloudid + "/power"}>
                            <label
                                className="select-cloud"
                                htmlFor={1}
                                aria-hidden="aria-hidden"
                            >
                                {/*begin::Svg Icon | path: assets/media/icons/duotune/electronics/elc004.svg*/}
                                <span className="svg-icon svg-icon-muted svg-icon-2 me-2">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path
                                            d="M2 16C2 16.6 2.4 17 3 17H21C21.6 17 22 16.6 22 16V15H2V16Z"
                                            fill="black"
                                        />
                                        <path
                                            opacity="0.3"
                                            d="M21 3H3C2.4 3 2 3.4 2 4V15H22V4C22 3.4 21.6 3 21 3Z"
                                            fill="black"
                                        />
                                        <path opacity="0.3" d="M15 17H9V20H15V17Z" fill="black" />
                                    </svg>
                                </span>
                                {/*end::Svg Icon*/}
                                Images
                            </label>
                        </Link>
                    </a>
                </div>
                {/*end::Menu item*/}
                {/*begin::Menu item*/}
                <div className="menu-item">
                    <a href="#" className="menu-link px-3">
                        <Link to={"/cloud/" + props.cloudid + "/network"}>
                            <label
                                className="select-cloud"
                                htmlFor={2}
                                aria-hidden="aria-hidden"
                            >
                                {/*begin::Svg Icon | path: assets/media/icons/duotune/maps/map004.svg*/}
                                <span className="svg-icon svg-icon-muted svg-icon-2 me-2">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path
                                            opacity="0.3"
                                            d="M18.4 5.59998C21.9 9.09998 21.9 14.8 18.4 18.3C14.9 21.8 9.2 21.8 5.7 18.3L18.4 5.59998Z"
                                            fill="black"
                                        />
                                        <path
                                            d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM19.9 11H13V8.8999C14.9 8.6999 16.7 8.00005 18.1 6.80005C19.1 8.00005 19.7 9.4 19.9 11ZM11 19.8999C9.7 19.6999 8.39999 19.2 7.39999 18.5C8.49999 17.7 9.7 17.2001 11 17.1001V19.8999ZM5.89999 6.90002C7.39999 8.10002 9.2 8.8 11 9V11.1001H4.10001C4.30001 9.4001 4.89999 8.00002 5.89999 6.90002ZM7.39999 5.5C8.49999 4.7 9.7 4.19998 11 4.09998V7C9.7 6.8 8.39999 6.3 7.39999 5.5ZM13 17.1001C14.3 17.3001 15.6 17.8 16.6 18.5C15.5 19.3 14.3 19.7999 13 19.8999V17.1001ZM13 4.09998C14.3 4.29998 15.6 4.8 16.6 5.5C15.5 6.3 14.3 6.80002 13 6.90002V4.09998ZM4.10001 13H11V15.1001C9.1 15.3001 7.29999 16 5.89999 17.2C4.89999 16 4.30001 14.6 4.10001 13ZM18.1 17.1001C16.6 15.9001 14.8 15.2 13 15V12.8999H19.9C19.7 14.5999 19.1 16.0001 18.1 17.1001Z"
                                            fill="black"
                                        />
                                    </svg>
                                </span>
                                {/*end::Svg Icon*/}
                                Permissions
                            </label>
                        </Link>
                    </a>
                </div>
                {/*end::Menu item*/}
                {/*begin::Menu item*/}
                <div className="menu-item">
                    <a href="#" className="menu-link px-3">
                        <Link to={"/cloud/" + props.cloudid + "/storage"}>
                            <label
                                className="select-cloud"
                                htmlFor={2}
                                aria-hidden="aria-hidden"
                            >
                                {/*begin::Svg Icon | path: assets/media/icons/duotune/files/fil012.svg*/}
                                <span className="svg-icon svg-icon-muted svg-icon-2 me-2">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path
                                            opacity="0.3"
                                            d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z"
                                            fill="black"
                                        />
                                        <path
                                            d="M9.2 3H3C2.4 3 2 3.4 2 4V19C2 19.6 2.4 20 3 20H21C21.6 20 22 19.6 22 19V7C22 6.4 21.6 6 21 6H12L10.4 3.60001C10.2 3.20001 9.7 3 9.2 3Z"
                                            fill="black"
                                        />
                                    </svg>
                                </span>
                                {/*end::Svg Icon*/}
                                Lifecycle policies
                            </label>
                        </Link>
                    </a>
                </div>
                {/*end::Menu item*/}
                {/*begin::Menu item*/}
                <div className="menu-item">
                    <a href="#" className="menu-link px-3">
                        <Link to={"/cloud/" + props.cloudid + "/rebuild"}>
                            <label
                                className="select-cloud"
                                htmlFor={3}
                                aria-hidden="aria-hidden"
                            >
                                {/*begin::Svg Icon | path: assets/media/icons/duotune/arrows/arr029.svg*/}
                                <span className="svg-icon svg-icon-muted svg-icon-2 me-2">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path
                                            d="M14.5 20.7259C14.6 21.2259 14.2 21.826 13.7 21.926C13.2 22.026 12.6 22.0259 12.1 22.0259C9.5 22.0259 6.9 21.0259 5 19.1259C1.4 15.5259 1.09998 9.72592 4.29998 5.82592L5.70001 7.22595C3.30001 10.3259 3.59999 14.8259 6.39999 17.7259C8.19999 19.5259 10.8 20.426 13.4 19.926C13.9 19.826 14.4 20.2259 14.5 20.7259ZM18.4 16.8259L19.8 18.2259C22.9 14.3259 22.7 8.52593 19 4.92593C16.7 2.62593 13.5 1.62594 10.3 2.12594C9.79998 2.22594 9.4 2.72595 9.5 3.22595C9.6 3.72595 10.1 4.12594 10.6 4.02594C13.1 3.62594 15.7 4.42595 17.6 6.22595C20.5 9.22595 20.7 13.7259 18.4 16.8259Z"
                                            fill="black"
                                        />
                                        <path
                                            opacity="0.3"
                                            d="M2 3.62592H7C7.6 3.62592 8 4.02592 8 4.62592V9.62589L2 3.62592ZM16 14.4259V19.4259C16 20.0259 16.4 20.4259 17 20.4259H22L16 14.4259Z"
                                            fill="black"
                                        />
                                    </svg>
                                </span>
                                {/*end::Svg Icon*/}
                                Repositry tags
                            </label>
                        </Link>
                    </a>
                </div>
                {/*end::Menu item*/}
                {/*begin::Menu item*/}
                <div className="menu-item">
                    <a href="#" className="menu-link px-3">
                        <Link to={"/cloud/" + props.cloudid + "/resize"}>
                            <label
                                className="select-cloud"
                                htmlFor={2}
                                aria-hidden="aria-hidden"
                            >
                                {/*begin::Svg Icon | path: assets/media/icons/duotune/coding/cod009.svg*/}
                                <span className="svg-icon svg-icon-muted svg-icon-2 me-2">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path
                                            opacity="0.3"
                                            d="M22.0318 8.59998C22.0318 10.4 21.4318 12.2 20.0318 13.5C18.4318 15.1 16.3318 15.7 14.2318 15.4C13.3318 15.3 12.3318 15.6 11.7318 16.3L6.93177 21.1C5.73177 22.3 3.83179 22.2 2.73179 21C1.63179 19.8 1.83177 18 2.93177 16.9L7.53178 12.3C8.23178 11.6 8.53177 10.7 8.43177 9.80005C8.13177 7.80005 8.73176 5.6 10.3318 4C11.7318 2.6 13.5318 2 15.2318 2C16.1318 2 16.6318 3.20005 15.9318 3.80005L13.0318 6.70007C12.5318 7.20007 12.4318 7.9 12.7318 8.5C13.3318 9.7 14.2318 10.6001 15.4318 11.2001C16.0318 11.5001 16.7318 11.3 17.2318 10.9L20.1318 8C20.8318 7.2 22.0318 7.59998 22.0318 8.59998Z"
                                            fill="black"
                                        />
                                        <path
                                            d="M4.23179 19.7C3.83179 19.3 3.83179 18.7 4.23179 18.3L9.73179 12.8C10.1318 12.4 10.7318 12.4 11.1318 12.8C11.5318 13.2 11.5318 13.8 11.1318 14.2L5.63179 19.7C5.23179 20.1 4.53179 20.1 4.23179 19.7Z"
                                            fill="black"
                                        />
                                    </svg>
                                </span>
                                {/*end::Svg Icon*/}
                                Repository
                            </label>
                        </Link>
                    </a>
                </div>
                {/*end::Menu item*/}
                {/*begin::Menu item*/}
                <div className="menu-item">
                    <a href="#" className="menu-link px-3">
                        <Link to={"/cloud/" + props.cloudid + "/destroy"}>
                            <label
                                className="select-cloud"
                                htmlFor={3}
                                aria-hidden="aria-hidden"
                            >
                                {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen027.svg*/}
                                <span className="svg-icon svg-icon-muted svg-icon-2 me-2">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path
                                            d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                            fill="black"
                                        />
                                        <path
                                            opacity="0.5"
                                            d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                            fill="black"
                                        />
                                        <path
                                            opacity="0.5"
                                            d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                            fill="black"
                                        />
                                    </svg>
                                </span>
                                {/*end::Svg Icon*/}
                                Repository scan filters
                            </label>
                        </Link>
                    </a>
                </div>
                {/*end::Menu item*/}
                {/*begin::Menu item*/}
                <div className="menu-item" id={`delete-registry-btn-${props.cloudid}`}>
                    <a href="#" className="menu-link px-3" onClick={() =>handleDeleteRegistry(props.cloudid,props.index)}>
                        {/* <Link to={"/cloud/" + props.cloudid + "/destroy"}> */}
                            <label
                                className="select-cloud"
                                htmlFor={3}
                                aria-hidden="aria-hidden"
                            >
                                {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen027.svg*/}
                                <span className="svg-icon svg-icon-muted svg-icon-2 me-2">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path
                                            d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                            fill="black"
                                        />
                                        <path
                                            opacity="0.5"
                                            d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                            fill="black"
                                        />
                                        <path
                                            opacity="0.5"
                                            d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                            fill="black"
                                        />
                                    </svg>
                                </span>
                                {/*end::Svg Icon*/}
                                Destroy
                            </label>
                        {/* </Link> */}
                    </a>
                </div>
                {/*end::Menu item*/}
            </div>
            {/*end::Menu*/}
        </>
        )
    }
}