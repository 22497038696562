import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import UseApi from "../../../../../CustomHooks/useApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UsePost from "../../../../../CustomHooks/usePost";
import swal from "sweetalert";
import ReactPaginate from 'react-paginate';
import AccessDenied from "../../../AccessDenied";
import TopBarHeaderLine from "../../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../../Loaders/TableLoader";
import UsePostData from "../../../../../CustomHooks/usePostData";
export default function Ssl(){
  const [dataFetched, setDataFetched] = useState(false);
  const [access, setAccess]=useState(true);
  const [perPage, setPerPage]=useState(5);
  const [sslData,setSslData]=useState(null);
  const [updateBtn, setUpdateBtn]=useState(false);
  const dataFetchedRef=useRef (false);
  const loaderFunc=()=>{
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if(loader && no_record){
    no_record.classList.add("d-none");
    loader.classList.remove("d-none");

    setTimeout(() => {
      loader.classList.add("d-none");
      no_record.classList.remove("d-none");
    }, 100);
  }
  }
  const getSsl=async()=>{ 
       const datas=await UseApi('get','certificates','');
       if (datas) { 
        if (datas.rcode == "error") {
          const dt=toast.loading("SSL loading..."); 
          toast.update(dt, {
            render: datas.message,
            type: "warning",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }); 
          setSslData([]);
          loaderFunc();
          return;
        }else{ 
          setSslData(datas.certificates);
        }
      } 
  }
 
 

  useEffect(()=>{
    if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
      toast.dismiss();
      getSsl(); 
  },[]); 


  const openDrawer = () => {
 
    var drawerElement = document.querySelector("#add_ssl-modal");
    var drawer_overlay = document.querySelector("#drawer_overlay");
    if(drawerElement && drawer_overlay){ 
    drawerElement.classList.toggle("drawer-on");
    if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
     drawerElement.style.width = "100%";
   } else {
     drawerElement.style.width = "70%";
   }
    drawer_overlay.classList.toggle("d-none");
    }
   };
 
  
const addSslCertificate=async()=>{ 
  var submitBtn=document.querySelector("#ssl-btn"); 
 
  if(submitBtn){
    var preContent=submitBtn.innerHTML;
    submitBtn.classList.add("disabled");
    submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>'; 
  const dt=toast.loading("Please wait..."); 
  const responseData=await UsePost('post','certificates','sslCreate');
  if(responseData){   
    submitBtn.classList.remove("disabled");
    submitBtn.innerHTML=preContent;
    if(responseData.status==='error'){
      toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });
    }else if(responseData.status==='success'){
      toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });  
    openDrawer();
    getSsl();
    // navigate("/ssl");
  }
}
    }
  } 

  const deleteSsl=async(sid)=>{ 
    var submitBtn=document.querySelector("#delete-ssl-"+sid); 
  
    if(submitBtn){
      var preContent=submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>'; 
    const dt=toast.loading("Please wait..."); 
    const responseData=await UsePostData('delete','certificates/'+sid, {});
    if(responseData){   
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML=preContent;
      if(responseData.status==='error'){
        toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      }else if(responseData.status==='success'){
        toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });   
      getSsl(); 
    }
  }
      }
    } 

  function formatMessageTime(dateString) {
    const date = new Date(dateString);
    const currentDate = new Date();
    const timeDiff = Math.abs(currentDate.getTime() - date.getTime());
    const minutes = Math.floor(timeDiff / (1000 * 60));
    const hours = Math.floor(minutes / 60);
  
    if (minutes <= 1) {
      return 'Just now';
    } else if (minutes < 30) {
      return `${minutes} mins ago`;
    } else if (minutes < 90) {
      return 'An hour ago';
    } else if (hours < 24) {
      const formattedTime = formatTime(date);
      return `Today at ${formattedTime}`;
    } else if (hours < 48) {
      const formattedTime = formatTime(date);
      return `Yesterday at ${formattedTime}`;
    } else {
      const formattedDate = formatDate(date);
      const formattedTime = formatTime(date);
      return `${formattedDate} at ${formattedTime}`;
    }
  }
  function formatTime(date) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}${period}`;
  }

  function formatDate(date) {
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
  }
function ProductInfo({ productdata }) { 
    return(
        <>
        
       
        {productdata!=null && Object.keys(productdata).length!=0?(productdata && productdata.map((value, index) => (
          <tr>
            {/*begisetSslDatan::ID*/}
            <td className="text-center">
              <span className="fw-bold text-center"></span>
            </td>
            {/*end::ID*/}
            {/*begin::User=*/}
            <td className="">
              {/*begin::User details*/}
              <div className="d-flex flex-column"> 
                <a   className="text-gray-800 fw-bolder text-hover-primary mb-1"   >
                  {value.name}
                </a> 
              </div>
              {/*begin::User details*/}
            </td>
            {/*end::User=*/}
            <td className="text-left">
              {/*begin::User details*/}
              <div className="d-flex flex-column">
                <span className="text-muted fw-bold">{value.dns_names}</span> 
              </div>
              {/*begin::User details*/}
            </td> 
            <td className="text-left">
              {/*begin::User details*/}
              <div className="d-flex flex-column">
                <span className="text-muted fw-bold"><div dangerouslySetInnerHTML={{ __html: value.issuer }} /></span> 
              </div>
              {/*begin::User details*/}
            </td> 
            <td className="text-end">
              
              <div className="d-flex flex-column w-100 me-2">
                
                <div className="d-flex flex-stack mb-2">
                  
                  <span className="text-muted me-2 fs-7 fw-bold">
                    {formatMessageTime(value.expire_at)}
                  </span>
                </div>
              </div>
            </td>
            <td className="text-end">
              
              <div className="d-flex flex-column w-100 me-2">
                
                <div className="d-flex flex-stack mb-2">
                  
                  <span className="text-muted me-2 fs-7 fw-bold">
                    {formatMessageTime(value.created_at)}
                  </span>
                </div>
              </div>
            </td>
            <td className="text-end">
              <div className="d-flex justify-content-end flex-shrink-0"> 
                <a
                  onClick={(e)=>deleteSsl(value.id)}
                  id={"delete-ssl-"+value.id}
                  className="btn btn-danger btn-active-light-danger btn-sm me-1 me-5 "
                >
                  
                  Delete
                  
                </a> 
              </div>
            </td> 
          </tr> 
          ))):( <>
           <tr>
                     <td colspan="12" className="dataTables_empty" id="loader">
                      <div className="loading-spinner"></div>
                    </td>
                    <td colspan="12" className="d-none" id="no_record" valign="top">
                      <div className="d-flex flex-column flex-center"> 
                        <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                      </div>
                    </td>
                    </tr>
            </>)}
            
        </>
    )

                  }
    const [searchTerm,setSearchTerm]=useState('');
    function PaginatedItems({ itemsPerPage, ProductData, search }) {
      // We start with an empty list of items. 
      const [pageCount, setPageCount] = useState(0);
      const [vpcData,setVpcData]=useState(null);
      // Here we use item offsets; we could also use page offsets
      // following the API or data you're working with.
      const [itemOffset, setItemOffset] = useState(0);
    
      useEffect(() => {
        // Fetch items from another resources.
        
        const endOffset = itemOffset + itemsPerPage;
  
  
      //  ////console.log(`Loading items from ${itemOffset} to ${endOffset}`);
         if(ProductData!=null){
        //   var str="Noida";
       // ////console.log(ProductData);
        const data= ProductData.filter(row => {
         return  Object.values(row).some(value => { 
            if(value!=null){
              if(value.toString().toLowerCase().includes(search.toLowerCase())){
                return value;
              } 
            }
          });
        });
        ////console.log(data);
          // const data=ProductData.filter(item => 
          //        item.hostname.toLowerCase().includes(search.toLowerCase())
          //              ); 

                       if(data.length<=0){
                        loaderFunc();
                       }
        setVpcData(data.slice(itemOffset, endOffset));
        //setCurrentItems(items.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(data.length / itemsPerPage));
        // alert(pageCount);
          }
      }, [itemOffset, itemsPerPage]);
    
      // Invoke when user click to request another page.
      
      const handlePageClick = (event) => { 
        const newOffset = event.selected * itemsPerPage % ProductData.length;
        ////console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
        setItemOffset(newOffset);
       
      };
      var searchEle=document.querySelector("#searchClient");
      if(searchEle){
        searchEle.focus();
      }
      return (
        <>
          <ProductInfo productdata={vpcData} />
          <td colspan="12">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
          </td>
        </>
      );
   
    }
 
    
  if(sslData!=null){  
    return(
      <> 
      {sslData.length!=0?(<>
        <div className="col-xl-12">
  {/*begin::Tables Widget 9*/}
  <div className="card card-xl-stretch mb-5 mb-xl-12">
    {/*begin::Header*/}
    <div className="card-header border-0 pt-5">
      <h3 className="card-title align-items-start flex-column">
        <span className="card-label fw-bold fs-3 mb-1">
          SSL Certificates 
        </span>
        <span className="text-muted mt-1 fw-bold fs-7" />
      </h3>
      <div
        className="card-toolbar" 
      >
        <div className="position-relative my-1" style={{ marginRight: 20 }}>
          <input
            type="text"
            className="form-control form-control-sm"
            data-kt-table-widget-4="search"
            id="searchClient"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            placeholder="Search"
          />
        </div>
        <div style={{ marginRight: 20 }}>
  <div className="dataTables_length">
    <label className="w-125px">
      <select  
        onChange={e => setPerPage(e.target.value)}
        //data-control="select2"
        className="form-select form-select-sm form-select-solid"
        defaultValue={5}
        id="perPage"
        
      >
        <option value={5} selected="">
          5 Records
        </option>
        <option value={10}>10 Records</option>
        <option value={25}>25 Records</option>
        <option value={50}>50 Records</option>
        <option value={100}>100 Records</option>
      </select>
    </label>
  </div>
</div>
        <a 
          onClick={openDrawer}
          className="btn btn-sm btn-primary btn-active-light-primary" 
        >
          {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
          <span className="svg-icon svg-icon-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x="11.364"
                y="20.364"
                width={16}
                height={2}
                rx={1}
                transform="rotate(-90 11.364 20.364)"
                fill="black"
              />
              <rect
                x="4.36396"
                y="11.364"
                width={16}
                height={2}
                rx={1}
                fill="black"
              />
            </svg>
          </span>
          {/*end::Svg Icon*/}Add SSL Certificate 
        </a>
      </div>
    </div>
    {/*end::Header*/}
    {/*begin::Body*/}
    {/*begin::Table container*/}
    <div className="table-responsive">
      {/*begin::Table*/}
      <table
        id="ssl_data"
        className="table  table-row-dashed table-row-gray-400 align-middle gs-0 gy-4"
      >
        {/*begin::Table head*/}
        <thead className="bg-secondary">
          <tr className="fw-bold ">
            <th className="w-50px text-center"></th>
            <th className="w-100px">Name</th>
            <th className="w-150px sphide">DNS Name</th> 
            <th className="w-150px">Issuer</th>
            <th className="w-150px sphide">Expire At</th>
            <th className="w-150px sphide">Created At</th> 
            <th className="w-150px text-center" />
          </tr>
        </thead>
        {/*end::Table head*/}
        {/*begin::Table body*/}
        <tbody id="list-ssl"> 
      {sslData!=null?(<>
       <PaginatedItems itemsPerPage={perPage} ProductData={sslData} search={searchTerm}  />
       
</>):(<>
  <tr>
                     <td colspan="12" className="dataTables_empty" id="loader">
                      <div className="loading-spinner"></div>
                    </td>
                    <td colspan="12" className="d-none" id="no_record" valign="top">
                      <div className="d-flex flex-column flex-center"> 
                        <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                      </div>
                    </td>
  </tr>
  </>)}

</tbody>
        {/*end::Table body*/}
      </table>
      {/*end::Table*/}
    </div>
    {/*end::Table container*/}
    {/*begin::Body*/}
  </div>
  {/*end::Tables Widget 9*/}
</div> 
      </>):(<>
        <div className="col-xl-12">
                <div className="card">
                  {/*begin::Card body*/}
                  <div className="card-body p-0 mt-10">
                    {/*begin::Illustration*/}
                    <div className="text-center px-4 mt-10">
                      <span className="mw-100 mh-300px">
                      <svg 
                      viewBox="0 0 64 64"  
                      width="234px"
                      height="234px"
  version={1.0}
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink" 
  enableBackground="new 0 0 64 64"
  xmlSpace="preserve"
  fill="#21325B"
  stroke="#21325B"
>
  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
  <g id="SVGRepo_iconCarrier">
    {" "}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#21325B"
      d="M53.802,25.104C52.003,24.4,50.05,24,48,24c-0.553,0-1-0.447-1-1 s0.447-1,1-1c1.841,0,3.607,0.297,5.278,0.812C50.992,14.287,43.246,8,34,8c-7.532,0-14.079,4.172-17.49,10.324 c1.515,0.393,2.89,1.133,4.037,2.128c0,0,1.182,1.028,0.433,1.777s-1.847-0.363-1.847-0.363c-1.142-0.957-2.556-1.593-4.106-1.792 C14.689,20.03,14.349,20,14,20c-4.418,0-8,3.582-8,8c0,1.1,0.228,2.145,0.632,3.098C8.286,30.404,10.092,30,12,30 c0.553,0,1,0.447,1,1s-0.447,1-1,1c-1.585,0-3.091,0.318-4.474,0.879c-0.123,0.05-0.243,0.105-0.364,0.158 c-0.389,0.174-0.772,0.356-1.138,0.568C2.426,35.675,0,39.551,0,44c0,6.627,5.373,12,12,12h36c8.837,0,16-7.164,16-16 C64,33.214,59.767,27.43,53.802,25.104z M23,45c-0.553,0-1-0.447-1-1c0-2.762-1.119-5.262-2.929-7.071c0,0-0.993-0.913-0.243-1.647 s1.657,0.233,1.657,0.233C22.656,37.687,24,40.687,24,44C24,44.553,23.553,45,23,45z M57,41c-0.553,0-1-0.447-1-1 c0-4.418-3.582-8-8-8c-0.553,0-1-0.447-1-1s0.447-1,1-1c5.522,0,10,4.477,10,10C58,40.553,57.553,41,57,41z"
    />{" "}
  </g>
</svg>

                      </span>
                    </div>
                    {/*end::Illustration*/}
                    {/*begin::Wrapper*/}
                    <div className="card-px text-center py-5 mb-20">
                      {/*begin::Title*/}
                      <h1 className="fs-2x fw-bolder mb-5">SSL Certificate</h1>
                      <h2 className="fs-1x fw-bolder mb-5 text-muted">
                      Add SSL in your account
                      </h2>
                      {/*end::Title*/}
                      {/*begin::Description*/}
                      <p className="text-gray-400 fs-4 fw-bold mb-10">
                      Enhance the security of your account by effortlessly adding an SSL certificate. Safeguard sensitive data and ensure encrypted communication for trusted and secure interactions with your website or application.                      </p>
                      {/*end::Description*/}
                      {/*begin::Action*/} 
                      <a
                        onClick={openDrawer}
                        style={{cursor:"pointer"}}
                        className="btn btn-primary mb-10"
                      >
                        Add SSL Certificate
                      </a> 
                      {/*end::Action*/}
                    </div>
                    {/*end::Wrapper*/}
                  </div>
                  {/*end::Card body*/}
                </div>
              </div>
      </>)}

      <div id="drawer_overlay" onClick={openDrawer} className="drawer-overlay d-none" style={{zIndex: 109, width:"100%"}}></div>   

      <div
  id="add_ssl-modal"
  className="drawer drawer-end"
  data-kt-drawer="true"
  data-kt-drawer-activate="true" 
  data-kt-drawer-overlay="false" 
  data-kt-drawer-width="{default:'500px', 'md': '500px', 'sm': '300px'}" 
  style={{maxWidth: '70%',important:true}}
>
  {/*begin::Messenger*/}
  <div className="card w-100 rounded-0 border-0" id="kt_drawer_chat_messenger">
    {/*begin::Card header*/}
    <div className="card-header pe-5" id="kt_drawer_chat_messenger_header">
      {/*begin::Title*/}
      <div className="card-title">
        {/*begin::User*/}
        <div className="d-flex justify-content-center flex-column me-3">
          <a
            
            className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1"
          >
            Add SSL Certificate
          </a>
        </div>
        {/*end::User*/}
      </div>
      {/*end::Title*/}
      {/*begin::Card toolbar*/}
      <div className="card-toolbar">
        {/*begin::Close*/}
        <div onClick={openDrawer}>
        <div
          className="btn btn-sm btn-icon btn-active-light-primary"
          onClick={(e)=>setUpdateBtn(false)}
        >
          {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
          <span className="svg-icon svg-icon-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x={6}
                y="17.3137"
                width={16}
                height={2}
                rx={1}
                transform="rotate(-45 6 17.3137)"
                fill="black"
              />
              <rect
                x="7.41422"
                y={6}
                width={16}
                height={2}
                rx={1}
                transform="rotate(45 7.41422 6)"
                fill="black"
              />
            </svg>
          </span>
          {/*end::Svg Icon*/}
        </div>
        </div>
        {/*end::Close*/}
      </div>
      {/*end::Card toolbar*/}
    </div>
    {/*end::Card header*/}
    {/*begin::Card body*/}
    <div className="" id="">
      {/*begin::Form*/} 
        {/*begin::Modal body*/}
        <div className="modal-body ">
          {/*begin::Scroll*/}
          <div className="scroll-y me-n7 pe-7">
          <form id="sslCreate">
                      {/*begin::Input group*/}
                      <div className="fv-row mb-10 fv-plugins-icon-container">
                        {/*begin::Label*/}
                        <label className="form-label required">Name</label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <select className="form-select" name="ssl" id="ssl-input">
                          <option></option>
                        </select>
                        {/*end::Input*/}
                        <div className="fv-plugins-message-container invalid-feedback" />
                      </div>
                      {/*end::Input group*/} 
                      </form>
          </div>
          {/*end::Scroll*/}
        </div>
        {/*end::Modal body*/}
        {/*begin::Modal footer*/}
        <div className="modal-footer ">
          {/*begin::Button*/}
           
            <a 
            id="ssl-btn" 
            onClick={addSslCertificate}
            className="btn btn-primary w-100"
          >
            <span className="indicator-label" >+ Add SSL Certificate</span>
          </a>
           
          {/*end::Button*/}
        </div>
        {/*end::Modal footer*/} 
      {/*end::Form*/}
    </div>
    {/*end::Card body*/}
  </div>
  {/*end::Messenger*/}
</div>  
    
 
      </>
  ) 
}else{
return (
<>
<TopBarHeaderLine/>
<TableLoader/>
</>
);
}
}