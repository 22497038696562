// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label {
    height: 60.68px !important; 
  }

.shadowBox {
  box-shadow:-2px 5px 10px 5px rgb(37 45 51 / 17%), 5px -3px 18px 5px rgb(37 45 51 / 17%);
}

p.no-margin {
  margin-bottom: 0; /* or margin-bottom: auto; */
}

.hrLine{
  border: none !important;
  width: 41 !important;
  height: 2 !important;
  color: "#007bfc" !important;
  background-color: "#007bfc" !important;
  margin: 0 auto !important;
}

.activeBorder{
  box-shadow: -2px 5px 10px 5px rgb(37 45 51 / 8%), 5px -3px 18px 5px rgb(37 45 51 / 8%);
  border:2px solid #3498db !important;
}

.w100{
  width: 100% !important;
}

.mhc_res_size{
  width: 828px;
}

.mhc_checkout2{
    z-index: 95 !important;
    position: fixed !important;
    top: 97px !important;
    width: 268px !important;
    left: 1151.8px !important;
}
.mhc_checkout{ 
  position: fixed !important; 
}
.plan_list_active{ 
  outline: 2px solid #009EF7; 
}
 `, "",{"version":3,"sources":["webpack://./src/App/MicroApp/Body/Products/stackScript/custom.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;EAC5B;;AAEF;EACE,uFAAuF;AACzF;;AAEA;EACE,gBAAgB,EAAE,4BAA4B;AAChD;;AAEA;EACE,uBAAuB;EACvB,oBAAoB;EACpB,oBAAoB;EACpB,2BAA2B;EAC3B,sCAAsC;EACtC,yBAAyB;AAC3B;;AAEA;EACE,sFAAsF;EACtF,mCAAmC;AACrC;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,YAAY;AACd;;AAEA;IACI,sBAAsB;IACtB,0BAA0B;IAC1B,oBAAoB;IACpB,uBAAuB;IACvB,yBAAyB;AAC7B;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,0BAA0B;AAC5B","sourcesContent":[".label {\n    height: 60.68px !important; \n  }\n\n.shadowBox {\n  box-shadow:-2px 5px 10px 5px rgb(37 45 51 / 17%), 5px -3px 18px 5px rgb(37 45 51 / 17%);\n}\n\np.no-margin {\n  margin-bottom: 0; /* or margin-bottom: auto; */\n}\n\n.hrLine{\n  border: none !important;\n  width: 41 !important;\n  height: 2 !important;\n  color: \"#007bfc\" !important;\n  background-color: \"#007bfc\" !important;\n  margin: 0 auto !important;\n}\n\n.activeBorder{\n  box-shadow: -2px 5px 10px 5px rgb(37 45 51 / 8%), 5px -3px 18px 5px rgb(37 45 51 / 8%);\n  border:2px solid #3498db !important;\n}\n\n.w100{\n  width: 100% !important;\n}\n\n.mhc_res_size{\n  width: 828px;\n}\n\n.mhc_checkout2{\n    z-index: 95 !important;\n    position: fixed !important;\n    top: 97px !important;\n    width: 268px !important;\n    left: 1151.8px !important;\n}\n.mhc_checkout{ \n  position: fixed !important; \n}\n.plan_list_active{ \n  outline: 2px solid #009EF7; \n}\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
