import React, { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import UseCallApi from "../../../../CustomHooks/useCallApi";
import TableLoader from "../../Loaders/TableLoader";
import swal from "sweetalert";
import { toast } from "react-toastify";
import UseApi from "../../../../CustomHooks/useApi";
import Swal from "sweetalert2";
import CustomDoc from "../../../../CustomHooks/CustomDoc";
import ProductsUrl from "../../Docs/Docsdata";
import AccessDenied from "../../AccessDenied";


export default function Sqs() {
    const [pageStatus, setPageStatus] = useState(false)
    const [allSqsData, setAllSqsData] = useState(null)
    const [dummyAllSqsData, setDummyAllSqsData] = useState(null)
    const [pageCount, setPageCount] = useState(0);
    const [perPage, setPerPage] = useState(5);
    const [itemOffset, setItemOffset] = useState(0);
    const [activePage, setActivePage] = useState(0)
    const [access, setAccess] = useState(true)
    const [dataFetched, setDataFetched] = useState(false);
    // const [pageCount, setPageCount] = useState(0);

    let navigate = useNavigate()
    const loaderFunc = () => {
        var loader = document.querySelector("#loader");
        var no_record = document.querySelector("#no_record");
        if (loader && no_record) {
            no_record.classList.add("d-none");
            loader.classList.remove("d-none");

            setTimeout(() => {
                loader.classList.add("d-none");
                no_record.classList.remove("d-none");
            }, 100);
        }
    }
    const copySQSName = () => {
        var eleText = document.querySelector("#file_url");
        var ele = document.querySelector("#file_url_copy_btn");
        var copiedEle = document.querySelector('#file_url_copied_btn')

        if (ele && eleText) {
            var copyText = eleText.innerText; // Use innerText to get the text content
            var innerEle = ele.innerHTML;
            var tempInput = document.createElement("textarea");
            document.body.appendChild(tempInput);
            tempInput.value = copyText;
            tempInput.select();
            document.execCommand("copy");
            document.body.removeChild(tempInput);

            //   ele.innerHTML = "Copied";
            ele.classList.add('d-none')
            copiedEle.classList.remove('d-none')

            setTimeout(() => {
                ele.classList.remove('d-none')
                copiedEle.classList.add('d-none')
                // ele.innerHTML = innerEle; // Set your original content here
            }, 2500);
        }
    };
    document.addEventListener('click', function (event) {
        if (event.target && event.target.id === 'file_url_copy_btn') {
            copySQSName();
        }
    });
    const handleDestroy = async (id, index, name) => {
        toast.dismiss();
        const { value: isDelete } = await Swal.fire({
            title: 'Destroy SQS',
            html: `
            <div className="d-flex align-items-center flex-wrap"> 
            <div id="kt_clipboard_4" className="me-5 fw-bold text-gray-600">
              <p class="fs-5">Enter the SQS name to Destroy:</p>
      
              <span class="fw-bold" id="file_url" >${name} &nbsp;&nbsp;&nbsp;</span><span id="file_url_copy_btn" style="cursor:pointer" class="text-primary"> ( copy ) </span>
              <span id="file_url_copied_btn" style="cursor:pointer" class="text-primary d-none"> Copied </span> 
            </div>  
          </div>
         `,
            input: 'text',
            inputPlaceholder: 'SQS name',
            showCancelButton: true,
            confirmButtonText: 'Destroy',
            cancelButtonText: 'Cancel',
            customClass: {
                confirmButton: 'delete-button bg-danger', // Add your custom class here
            },
        });
        if (isDelete?.trim() == name) {
            setPageStatus(true)
            const dt = toast.loading("sqs deleting...");
            const dnsp = await UseApi('DELETE', 'sqs/' + id + '/destroy?confirm=' + name, '');
            // //console.log(dnsp);
            if (dnsp.status == 'error') {
                setPageStatus(false)
                toast.update(dt, {
                    render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
                });
            } else {
                setPageStatus(false)
                toast.update(dt, {
                    render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
                });
                setAllSqsData((old) => {
                    old.splice(index, 1);
                    return [...old];
                })
                setDummyAllSqsData((old) => {
                    old.splice(index, 1);
                    return [...old];
                })
                // navigate('/sqs');
            }
        } else {
            swal({
                title: "Invalid SQS Name",
                text: "Please confirm the valid SQS!",
                icon: "warning",
                button: "OK",
            });
        }
    }
    function convertTimestamp(timestamp) {
        const date = new Date(timestamp);
        // Format the date
        const formattedDate = new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZoneName: 'short',
            timeZone: 'UTC'
        }).format(date);

        return formattedDate.replace('GMT', '(UTC)');
    }
    const escapeRegExp = (string) => {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    };

    const handleSearch = (text) => {
        const endOffset = itemOffset + perPage;
        const escapedText = escapeRegExp(text);
        let matches = allSqsData.filter((row) => {
            const regex = new RegExp(`${escapedText}`, "gi");
            return row.name.match(regex);
        })
        setDummyAllSqsData(matches);;
        setPageCount(Math.ceil(matches.length / perPage));
        // }
    }

    function PaginatedItems({ itemsPerPage, ProductData }) {
        const endOffset = itemOffset + itemsPerPage;
        setPageCount(Math.ceil(ProductData.length / itemsPerPage));
        const handlePageClick = (event) => {
            setActivePage((old) => old = event.selected);
            const newOffset = event.selected * itemsPerPage;
            setItemOffset(newOffset);
        };

        return (
            <>
                <ProductInfo productdata={ProductData.slice(itemOffset, endOffset)} />
                <td colspan="12">
                    <ReactPaginate
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        initialPage={activePage}
                        previousLabel="< previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                    />
                </td>
            </>
        );
    }

    function ProductInfo({ productdata }) {
        // console.log(productdata);
        return (
            <>
                {productdata != null && Object.keys(productdata).length != 0 && (productdata && productdata.map((value, index) => (
                    <tr>
                        <td className="text-start ps-9">
                            <span className="fs-6 fw-bold text-dark sphide">
                                {value.name}
                            </span>
                        </td>
                        <td className="text-start w-150px ps-9">
                            <div className="d-flex align-items-center sphide">
                                <div className="symbol symbol-30px me-5">
                                    <img src={"/img/flag_in.png"} />{" "}
                                </div>
                                <div className="d-flex justify-content-start flex-column">
                                    <a className="text-dark  fw-bold text-hover-primary fs-6">
                                        Noida (Delhi)
                                    </a>
                                    <span className=" me-2 fs-7 text-dark fw-bold">India</span>
                                </div>
                            </div>
                        </td>
                        <td className="text-center w-150px ps-9">
                            <span className="fs-6 fw-bold">
                                {value.count}
                            </span>
                        </td>
                        <td className="text-center w-150px ps-9">
                            {/* <span className="fs-6 fw-bold">
                                {value?.status}
                            </span> */}
                            {value?.status === "Active" ?
                                <div className=" flex-column w-100">
                                    <div className=" flex-stack mb-2">
                                        <span className=" me-2 fs-6 fw-bold">
                                            <span className="status-circle" style={{ top: 4 }} />
                                            {value?.status}
                                        </span>
                                    </div>
                                </div>
                                :
                                <div className=" flex-column w-100">
                                    <div className=" flex-stack mb-2">
                                        <span className=" me-2 fs-6 fw-bold">
                                            <span className="status-circle-warning" style={{ top: 2 }} />
                                            {value?.status}
                                        </span>
                                    </div>
                                </div>
                            }
                        </td>
                        <td className="text-center w-150px ps-9">
                            <span className="fs-6 fw-bold">
                                {value.created_at}
                            </span>
                        </td>
                        <td className="text-center pe-5 w-150px ps-9">
                            <Link to={`/sqs/${value.id}`}>
                                <div className="fs-7 fw-bold btn btn-sm btn-light-primary sphide border border-primary">
                                    <div>
                                        Manage SQS&nbsp;
                                        {/*begin::Svg Icon | path: assets/media/icons/duotune/arrows/arr095.svg*/}
                                        <span className="svg-icon svg-icon-muted svg-icon-2">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                            >
                                                <path
                                                    opacity="0.3"
                                                    d="M4.7 17.3V7.7C4.7 6.59543 5.59543 5.7 6.7 5.7H9.8C10.2694 5.7 10.65 5.31944 10.65 4.85C10.65 4.38056 10.2694 4 9.8 4H5C3.89543 4 3 4.89543 3 6V19C3 20.1046 3.89543 21 5 21H18C19.1046 21 20 20.1046 20 19V14.2C20 13.7306 19.6194 13.35 19.15 13.35C18.6806 13.35 18.3 13.7306 18.3 14.2V17.3C18.3 18.4046 17.4046 19.3 16.3 19.3H6.7C5.59543 19.3 4.7 18.4046 4.7 17.3Z"
                                                    fill="black"
                                                />
                                                <rect
                                                    x="21.9497"
                                                    y="3.46448"
                                                    width={13}
                                                    height={2}
                                                    rx={1}
                                                    transform="rotate(135 21.9497 3.46448)"
                                                    fill="black"
                                                />
                                                <path
                                                    d="M19.8284 4.97161L19.8284 9.93937C19.8284 10.5252 20.3033 11 20.8891 11C21.4749 11 21.9497 10.5252 21.9497 9.93937L21.9497 3.05029C21.9497 2.498 21.502 2.05028 20.9497 2.05028L14.0607 2.05027C13.4749 2.05027 13 2.52514 13 3.11094C13 3.69673 13.4749 4.17161 14.0607 4.17161L19.0284 4.17161C19.4702 4.17161 19.8284 4.52978 19.8284 4.97161Z"
                                                    fill="black"
                                                />
                                            </svg>
                                        </span>
                                        {/*end::Svg Icon*/}
                                    </div>

                                </div>
                            </Link>
                        </td>

                        <td className="text-center pe-3" >
                            <div className="card-toolbar">
                                <button
                                    type="button"
                                    className="btn  btn-icon btn-color-danger btn-active-light-danger"
                                    id={`delete-registry-btn`}
                                    onClick={() => handleDestroy(value.id, index, value.name)}
                                >
                                    <span className="svg-icon svg-icon-1">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <path
                                                d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                                fill="black"
                                            />
                                            <path
                                                opacity="0.5"
                                                d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                                fill="black"
                                            />
                                            <path
                                                opacity="0.5"
                                                d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                                fill="black"
                                            />
                                        </svg>
                                    </span>
                                </button>
                            </div>
                        </td>
                        {/* <ActionList cloudid={value.name} index={index} setAllData={setAllData} /> */}
                    </tr>
                )))}
            </>
        )
    }
    const getSqs = async () => {
        let data = await UseCallApi('get', "sqs", "")
        if (data.status === "success") {
            setDataFetched(true)
            const resultArray = Object.keys(data.sqs)
                .filter(key => !isNaN(key)) // Filter out non-numeric keys
                .map(key => data.sqs[key]);
            setAllSqsData(resultArray?.slice()?.reverse());
            setDummyAllSqsData(resultArray?.slice()?.reverse());
        } else {
            setDataFetched(true)
            if (data.message == "Permission denied.") {
                setAccess(false);
            } else {
                setAccess(true)
            }
            setAllSqsData([])
            setDummyAllSqsData([])
        }
        // console.log(data);
    }
    useEffect(() => {
        getSqs()
    }, [])

    const openDoc = () => {
        var drawerElement = document.querySelector("#sqs-doc");
        var drawer_overlay = document.querySelector("#drawer_overlay_sqs_doc");
        if (drawerElement && drawer_overlay) {
            drawerElement.classList.toggle("drawer-on");
            if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
                drawerElement.style.width = "100%";
            } else {
                drawerElement.style.width = "50%";
            }
            drawer_overlay.classList.toggle("d-none");
        }
    };
    if (dataFetched) {
        if (access) {
            return (
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bolder text-gray-800">
                                Simple Queue Service
                            </span>
                         </h3>
                        <div className="card-toolbar">
                        <div className="position-relative my-1" style={{ marginRight: 20 }}>
                        <CustomDoc url={ProductsUrl[0]?.sqs+"/getting-started/"}/>
                        </div>
                            <div
                                className="position-relative my-1"
                                style={{ marginRight: 20 }}
                            >
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    data-kt-table-widget-4="search"
                                    id="searchClient"
                                    // value={searchTerm}
                                    onChange={(e) => {
                                        // setSearchTerm(e.target.value)
                                        handleSearch(e.target.value)
                                    }}
                                    disabled={allSqsData != null && allSqsData.length === 0}
                                    placeholder="Search by name"
                                />
                            </div>

                            <div style={{ marginRight: 20 }}>
                                <div className="dataTables_length">
                                    <label className="w-125px">
                                        <select
                                            onChange={e => setPerPage(e.target.value)}
                                            //data-control="select2"
                                            // placeholder=""
                                            className="form-select form-select-sm form-select-solid"
                                            defaultValue={5}
                                            id="perPage"

                                        >
                                            <option
                                                value={5}
                                                selected=""
                                                onMouseEnter={(e) => e.target.style.backgroundColor = 'var(--bs-primary)'} // Change background color on hover
                                                onMouseLeave={(e) => e.target.style.backgroundColor = ''} // Remove background color when cursor leaves
                                                style={{ transition: 'background-color 0.3s' }}
                                            >
                                                5 Records
                                            </option>
                                            <option
                                                value={10}
                                                onMouseEnter={(e) => e.target.style.backgroundColor = 'var(--bs-primary)'} // Change background color on hover
                                                onMouseLeave={(e) => e.target.style.backgroundColor = ''} // Remove background color when cursor leaves
                                                style={{ transition: 'background-color 0.3s' }}
                                            >
                                                10 Records</option>
                                            <option
                                                value={25}
                                                onMouseEnter={(e) => e.target.style.backgroundColor = 'var(--bs-primary)'} // Change background color on hover
                                                onMouseLeave={(e) => e.target.style.backgroundColor = ''} // Remove background color when cursor leaves
                                                style={{ transition: 'background-color 0.3s' }}
                                            >
                                                25 Records</option>
                                            <option
                                                value={50}
                                                onMouseEnter={(e) => e.target.style.backgroundColor = 'var(--bs-primary)'} // Change background color on hover
                                                onMouseLeave={(e) => e.target.style.backgroundColor = ''} // Remove background color when cursor leaves
                                                style={{ transition: 'background-color 0.3s' }}
                                            >
                                                50 Records</option>
                                            <option
                                                value={100}
                                                onMouseEnter={(e) => e.target.style.backgroundColor = 'var(--bs-primary)'} // Change background color on hover
                                                onMouseLeave={(e) => e.target.style.backgroundColor = ''} // Remove background color when cursor leaves
                                                style={{ transition: 'background-color 0.3s' }}
                                            >
                                                100 Records</option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                            <Link to="deploy">
                                <a
                                    className="btn btn-sm btn-primary my-1 sphide">
                                    + Create New SQS
                                </a>
                            </Link>
                        </div>
                    </div>
                    <div className="card-body p-0">
                        <div className="table-responsive">
                            <table
                                className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
                                id="kt_security_license_usage_table"
                            >
                                <thead className="border-gray-200 fs-5 fw-bold bg-secondary">
                                    <tr>
                                        <th className="text-start w-150px ps-9">Name</th>
                                        <th className="text-center w-150px">Location</th>
                                        <th className="text-center w-150px ps-9">Queue</th>
                                        <th className="text-center w-150px ps-9">Status</th>
                                        <th className="text-center w-150px ps-9 sphide">Created_at</th>
                                        <th className="text-center pe-5 w-50px"></th>
                                        <th className="text-center pe-7 w-150px sphide">Action</th>
                                    </tr>
                                </thead>
                                <tbody id="public-IP-list" className="fw-6 fw-bold text-gray-600">
                                    {allSqsData != null ? (<>
                                        {allSqsData.length == 0 ? (<>
                                            <tr>
                                                <td colspan="12" valign="top">
                                                    <div className="card-body p-0 mt-10">
                                                        <div className="text-center px-4 mt-10">
                                                            <span className="mw-100 mh-300px">
                                                                <svg viewBox="-26.5 0 309 309" width="234px" height="234px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M255.991,100.211 L251.879,100.142 L128.087,63.492 L128.013,61.796 L128.013,0 L256,64.006 L255.991,100.211" fill="#494b74"> </path> <path d="M128,65.5 L128.013,0 L0.017,63.981 L0,244.996 L0.064,245.028 L0.064,245.03 L127.987,309.003 L128.412,308.412 L128.247,247.541 L127.996,247.202 L120,241.5 L21,212 L21.5,97.5 L128,65.5" fill="#494b74"> </path> <path d="M147.141,195.298 L16.351,213.697 L16.36,95.28 L147.15,113.704 L147.141,195.298" fill="#343651"> </path> <path d="M80.864,187.813 L127.996,193.813 L128.004,115.155 L80.873,121.15 L80.864,187.813" fill="#494b74"> </path> <path d="M33.385,181.771 L63.972,185.659 L63.981,123.296 L33.385,127.193 L33.385,181.771" fill="#494b74"> </path> <path d="M16.36,95.28 L128.013,61.796 L255.991,100.211 L147.231,113.713 L16.36,95.28" fill="#343651"> </path> <path d="M255.927,177.376 L127.996,193.521 L128.004,115.155 L255.927,131.451 L255.927,177.376" fill="#494b74"> </path> <path d="M255.918,208.834 L253.165,208.953 L128.389,246.723 L127.996,247.202 L127.987,309.003 L255.918,245.052 L255.918,208.834" fill="#494b74"> </path> <path d="M16.351,213.697 L127.996,247.202 L255.918,208.834 L147.141,195.298 L16.351,213.697" fill="#343651"> </path> </g> </g></svg>
                                                            </span>
                                                        </div>
                                                        <div className="card-px text-center py-5 mb-20">
                                                            <h1 className="fs-2x fw-bolder mb-5">SQS</h1>
                                                            <h2 className="fs-1x fw-bolder mb-5 text-muted">
                                                                Microservices Messaging Made Magical
                                                            </h2>
                                                            <p className="text-gray-400 fs-4 fw-bold mb-10">
                                                                Dive into the Future of Messaging with SQS, Rock-Solid Microservices.{" "}
                                                                <br />
                                                                Ultimate Messaging Powerhouse for Crafting Supercharged{" "}
                                                            </p>
                                                            {/* <a href="/sqs/deploy" /> */}
                                                            <Link to="deploy">
                                                                <a className="btn btn-primary mb-10">
                                                                    Create New SQS
                                                                </a>
                                                            </Link>
                                                        </div>
                                                    </div>

                                                </td>
                                            </tr>
                                        </>) : (<>
                                            <PaginatedItems itemsPerPage={perPage} ProductData={dummyAllSqsData} />
                                        </>)}
                                    </>) : <tr>
                                        <td colspan={12}>
                                            <TableLoader />
                                            {/* <TopBarHeaderLine /> */}
                                        </td>
                                    </tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                 </div>
            )
        } else {
            return (
                <>
                    <AccessDenied />
                </>
            )
        }
    } else {
        return (
            <>
                <TableLoader />
            </>
        )
    }
}