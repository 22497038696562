import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import UsePost from "../../../../CustomHooks/usePost";
import swal from 'sweetalert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './custom.css';
import localStorage from "local-storage";
const PricingContainer = (props) => {

  const [plansDiscount, setPlansDiscount] = useState({hourly:0, monthly:0, month3:0, month6:0, month12:0});
  const [plan_period, setPlan_period] = useState(null);
  const [planDataList, setPlanDataList] = useState(null);
  const [userData, setUserData] = useState(null);
  const [dclocation, setDclocation] = useState(null);   
  useEffect(() => {
    if (props) {
      if(props.discounts){
        setPlansDiscount(props.discounts);
      }
      if (props.plans) {
        if (props.disk_size) {
          var newPlan = props.plans.filter((item) => { 
            return parseInt(item.disk) >= parseInt(props.disk_size);
          });


          setPlanDataList(newPlan);
        } else {
          setPlanDataList(props.plans);
         }
      }
      const userInfo = localStorage.get('user_info'); 
      setUserData(userInfo);
    
      if (props.currPlanData) {

      }
      if (props.dcLocation) {
        setDclocation(props.dcLocation);
      }
    } 
  }, [])

  const selectPlan = (planid) => {
    //unselect all plans 
    console.log("new plans");
    console.log(planDataList);
    var planLabels = document.querySelectorAll(".tick-parent");
    var PlansTicks = document.querySelectorAll(".tickMarkPlan");
    planLabels.forEach((lfield) => {
      lfield.classList.remove("plan_list_active");
    })
    PlansTicks.forEach((tfield) => {
      tfield.classList.add("d-none");
    })

    //select the specific plan

    var activeLabels = document.querySelector("#tick_parent_" + planid);
    var activePlansTicks = document.querySelector("#tickMarkPlan-" + planid);
    if (activeLabels && activePlansTicks) {
      activeLabels.classList.add("plan_list_active");
      activePlansTicks.classList.remove("d-none");
    }

    if (planDataList != null) {
      planDataList.map((value, index) => {
        if (value.id == planid) {
          if (props.currPlanData[0]) {
            var payablePrice = 0;
            var orgCost = 0;
            var planDiscount = 0;
            if (props.currPlanData[0].billing_info == "12month") {
              orgCost = calculateActualPrice(value.price, 0, props.currPlanData[0].billing_info);
              payablePrice = calculateActualPrice(value.price, plansDiscount.month12, props.currPlanData[0].billing_info);
              planDiscount = plansDiscount.month12;
            }else if (props.currPlanData[0].billing_info == "3month") {
              orgCost = calculateActualPrice(value.price, 0, props.currPlanData[0].billing_info);
              payablePrice = calculateActualPrice(value.price, plansDiscount.month3, props.currPlanData[0].billing_info);
              planDiscount = plansDiscount.month3;
            }else if (props.currPlanData[0].billing_info == "6month") {
              orgCost = calculateActualPrice(value.price, 0, props.currPlanData[0].billing_info);
              payablePrice = calculateActualPrice(value.price, plansDiscount.month6, props.currPlanData[0].billing_info);
              planDiscount = plansDiscount.month6;
            } else if (props.currPlanData[0].billing_info == "monthly") {
              orgCost = calculateActualPrice(value.price, 0, props.currPlanData[0].billing_info);
              payablePrice = calculateActualPrice(value.price, plansDiscount.monthly, props.currPlanData[0].billing_info);
              planDiscount = plansDiscount.monthly;
            } else {
              orgCost = value.price;
              payablePrice = value.price;
              planDiscount = plansDiscount.hourly;
            }
            var result = { "ram": value.ram, "cpu": value.cpu, "disk": value.disk, "bandwidth": value.bandwidth, "billing_info": props.currPlanData[0].billing_info, "plan_value": value.id, "plan_cost": payablePrice, "plan_org_value": value.price, "plan_cost_without_discount": orgCost, "plan_discount": planDiscount };
            props.currPlanData[0] = result;
          }
        }
      })
    }
    if (props.currPlanData[0]) {
      if (props.currPlanData[0].ram != "") {
        var planInfo = { "planid": planid, "ram": props.currPlanData[0].ram, "vcpu": props.currPlanData[0].cpu, "disk": props.currPlanData[0].disk, "bandwidth": props.currPlanData[0].bandwidth, "payable_price": props.currPlanData[0].plan_cost, "plan_cost_without_discount": props.currPlanData[0].plan_cost_without_discount, "billing": props.currPlanData[0].billing_info, "plan_org_value": props.currPlanData[0].plan_org_value, "plan_discount": props.currPlanData[0].plan_discount };
        props.updatePlan(planInfo);
      }
    }
  }

  const changeBillingCycle = (planType) => {
    console.log(props.currPlanData[0]);
    
    if (props.currPlanData[0]) {
      props.currPlanData[0].billing_info = planType;
      selectPlan(props.currPlanData[0].plan_value);
    }
    var allBillingLabels = document.querySelectorAll(".activeBilling");
    var activeLabel = document.querySelector("#label-" + planType);
    allBillingLabels.forEach((bfield) => {
      bfield.classList.remove("active");
    })
    if (activeLabel) {
      activeLabel.classList.add("active");
    }
    setPlan_period(planType);

  }

  useEffect(() => {  
    setTimeout(() => {
      if (props) {
        if (props.currPlanid != "") {
          if (props.currPlanData[0]) {
            props.currPlanData[0].plan_value = props.currPlanid; 
            const tick_parent_plan = document.querySelector("#tick_parent_"+props.currPlanid);
            if(tick_parent_plan){
              tick_parent_plan.click();
            }

          }
        }
        console.log(props.currBilling);
        
        if (props.currBilling != "") {
          changeBillingCycle(props.currBilling);
        }

      }
    }, 100) 
  }, [planDataList])

  function calculateActualPrice(monthlyPrice, discount, duration) {
    let actualPrice = monthlyPrice;

    if (duration === 'hourly') {
      actualPrice = monthlyPrice / 720;
      actualPrice -= actualPrice * (discount / 100);
      actualPrice = actualPrice.toFixed(2);
    } else if (duration === '3month') {
      actualPrice = monthlyPrice * 3;
      actualPrice -= actualPrice * (discount / 100);
      actualPrice = actualPrice.toFixed(0);
    } else if (duration === '6month') {
      actualPrice = monthlyPrice * 6;
      actualPrice -= actualPrice * (discount / 100);
      actualPrice = actualPrice.toFixed(0);
    } else if (duration === '12month') {
      actualPrice = monthlyPrice * 12;
      actualPrice -= actualPrice * (discount / 100);
      actualPrice = actualPrice.toFixed(0);
    } else if (duration === 'monthly') {
      actualPrice -= actualPrice * (discount / 100);
      actualPrice = actualPrice.toFixed(0);
    }

    return actualPrice;
  }

  function requestPlan(planId) {
    swal({
      title: "Join Waiting List",
      text: "As this Plan is temporary not availabel, so may be you have to wait to gain the access of this plan.",
      icon: "info",
      buttons: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          if (userData != null) {
            var requestArr = []
            const dt = toast.loading("processing request...");
            const dnsp = await UsePost('post', 'process/start', 'start_fms_' + planId);

            if (dnsp.status == 'error') {
              toast.update(dt, {
                render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
              });
            } else {
              toast.update(dt, {
                render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
              });
            }
          }
        }

      })
  } 

  
  return (
    <>


      {/*begin::Col*/}
      <div className="col-xl-12 card mb-5">
        {/*begin::List widget 10*/}
        {/*begin::Header*/}
        <div className="card-header border-3 mb-5 pt-2 p-0 ps-6">
          {/*begin::Title*/}
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder text-gray-800">
              Select Billing Cycle
            </span>
            <span className="text-gray-400 mt-1 fw-bold fs-6">
              Hourly/Monthly/Annualy
            </span>
          </h3>
        </div>

        {/*begin::Radio group*/}
        <div
          className="btn-group mx-5 pb-5 custom-grid"
          data-kt-buttons="true"
          data-kt-buttons-target="[data-kt-button]"
        >
          {/*begin::Radio*/}
          <label
            onClick={(e) => changeBillingCycle("36month")}
            className="mobile-text-center d-none activeBilling billingCycle btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-primary "
            data-kt-button="true"
            id="label-36month"
          >
            3 years
          </label>
          {/*end::Radio*/}
          {/*begin::Radio*/}
          <label
            onClick={(e) => changeBillingCycle("hourly")}
            className="text-start mobile-text-center ribbon ribbon-top ribbon-vertical activeBilling billingCycle btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-primary active"
            data-kt-button="true"
            id="label-hourly"
            style={{borderRadius:'0.4rem 0rem 0rem 0.4rem'}}
          >
             {
               plansDiscount.hourly>0?(<>
             <div class="ribbon-label bg-success ribbonCustom"
              >{plansDiscount.hourly}% Off</div>
              </>):('')
             }
            Hourly
          </label>
          {/*end::Radio*/}

          {/*begin::Radio*/}
          <label
            onClick={(e) => changeBillingCycle("monthly")}
            className="text-start mobile-text-center ribbon ribbon-top ribbon-vertical activeBilling billingCycle btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-primary"
            data-kt-button="true"
            id="label-monthly"
          >
            {
               plansDiscount.monthly>0?(<>
             <div class="ribbon-label bg-success ribbonCustom"
              >{plansDiscount.monthly}% Off</div>
              </>):('')
             }
            Monthly
          </label>
          {/*end::Radio*/}
           {/*begin::Radio*/}
           <label
            onClick={(e) => changeBillingCycle("3month")}
            className="text-start mobile-text-center ribbon ribbon-top ribbon-vertical activeBilling billingCycle btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-primary"
            data-kt-button="true"
            id="label-3month"
          >  {
               plansDiscount.month3>0?(<>
             <div class="ribbon-label bg-success ribbonCustom"
              >{plansDiscount.month3}% Off</div>
              </>):('')
             }
            3 Months
          </label>
          {/*end::Radio*/}
           {/*begin::Radio*/}
           <label
            onClick={(e) => changeBillingCycle("6month")}
            className="text-start mobile-text-center ribbon ribbon-top ribbon-vertical activeBilling billingCycle btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-primary"
            data-kt-button="true"
            id="label-6month"
          >
             {
               plansDiscount.month6>0?(<>
             <div class="ribbon-label bg-success ribbonCustom"
              >{plansDiscount.month6}% Off</div>
              </>):('')
             }
            6 Months
          </label>
          {/*end::Radio*/}
          {/*begin::Radio*/}
          <label
            onClick={(e) => changeBillingCycle("12month")}
            className="text-start mobile-text-center ribbon ribbon-top ribbon-vertical activeBilling billingCycle btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-primary"
            data-kt-button="true"
            id="label-12month"
          >
             {
               plansDiscount.month12>0?(<>
             <div class="ribbon-label bg-success ribbonCustom"
              >{plansDiscount.month12}% Off</div>
              </>):('')
             }
            Annually
          </label>
          {/*end::Radio*/}
        </div>
        {/*end::Radio group*/}
      </div>
      <div className="col-xl-12 card mb-5">
        <div className="card-header border-3 mb-5 pt-2 p-0 ps-6">
          {/*begin::Title*/}
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder text-gray-800">
              Select Plan Type
            </span>
            <span className="text-gray-400 mt-1 fw-bold fs-6">
              {/* Basic, Dedicated CPU, Dedicated Memory */}
              Basic/CPU Optimized/Memory Optimized
            </span>
          </h3> 
        </div>
        {/*begin::Nav*/}
        <ul className="mx-5 mb-3 nav nav-stretch nav-line-tabs nav-line-tabs-2x border-trakt_charts_widget_1_chartnsparent fs-5 fw-bolder">
          <li className="nav-item">
            <a
               className="nav-link text-active-primary py-5 me-6 active"
              data-bs-toggle="tab"
              href="#basic-tab"
            >
              Basic Plan
            </a>
          </li>
          <li className="nav-item">
            <a
               className="nav-link py-5 me-6 "
              data-bs-toggle="tab"
              href="#dedicated-cpu-tab"
            >
              CPU Optimized
            </a>
          </li>
          <li className="nav-item">
            <a
               className="nav-link py-5 me-6"
              data-bs-toggle="tab"
              href="#dedicated-memory-tab"
            >
              Memory Optimized
            </a>
          </li>
        </ul>

        {/*end::Nav*/} 

        {/* Horizontal PLAN START*/}
        <div className="tab-content" id="myTabContent">
          <div className="tab-pane  show active" id="basic-tab" role="tabpanel">
            <div className="row mt-5" data-kt-buttons="true">
              <div className="table-responsive" id="ramcpuplans">
                {/*begin::Table*/}
                <table className="table  table-row-bordered table-flush align-middle gy-3 tableramcpu">
                  {/*begin::Thead*/}
                  <thead className="border-bottom border-gray-200 fs-6 fw-bolder bg-secondary" style={{height:"60px",verticalAlign:"inherit"}}>
                    <tr>
                      <th className="w-10 text-center">RAM</th>
                      <th className="w-20 text-center">vCPU</th>
                      <th className="w-20 text-center">Storage</th>
                      <th className="w-20 text-center">Bandwidth</th>
                      <th className="w-10 text-center">Plan</th>
                      <th className=""></th>
                      <th className="w-15 ps-8 text-center">
                        Price
                      </th>
                      <th className="w-5 text-center" />
                    </tr>
                  </thead>
                  {/*end::Thead*/}
                  {/*begin::Tbody*/}
                  <tbody className="fs-6 fw-bold text-gray-600">
                     
                      {planDataList!=null && planDataList ? (<>{
                        planDataList.map((value, index) => (
                          <>
                            {value.slug == "basic" ? (
                              <>
                                {value.is_available == "YES" ? (<>
                                  <tr
                                    onClick={(e) => selectPlan(value.id)}
                                    className="planseffect tick-parent"
                                    id={"tick_parent_" + value.id}
                                  >
                                    <td className="ps-0 text-center">
                                      <input type="hidden" id={"plan_cost_" + value.id} defaultValue={value.price} />
                                      <input type="hidden" id={"ram_" + value.id} defaultValue={value.ram} />
                                      {(value.ram / 1024).toFixed(0)} GB
                                      {/* {value.id} */}
                                    </td>
                                    <td className="text-center">
                                      <input type="hidden" id={"cpu_" + value.id} defaultValue={value.cpu} />
                                      {value.dedicated_vcore == "1" ? (<>
                                        {value.cpu} vCPU <br /><span className="badge badge-success">Dedicated</span>
                                      </>) : (<>
                                        {value.cpu} vCPU
                                      </>)}
                                    </td>
                                    <td className="text-center">
                                      <input type="hidden" id={"disk_" + value.id} defaultValue={value.disk} />
                                       {value.disk==0 || value.disk=='0'?('ebs'):(<>{value.disk} GB</>)}</td>
                                    <td className="text-center pe-5">
                                      <input type="hidden" id={"bandwidth_" + value.id} defaultValue={value.bandwidth} />
                                      {value.bandwidth} GB</td>
                                    <td className="text-center">
                                      <span
                                        className="badge badge-success"
                                        id={"plan_type_" + value.id}
                                      >
                                        {plan_period == "12month" ? (<>
                                          Annually
                                        </>) : (<>
                                          {plan_period == "monthly" ? (<>Monthly</>) : (<>
                                            {plan_period == "3month" ? (<>3 Months</>) : (<>
                                              {plan_period == "6month" ? (<>6 Months</>) : (<>Hourly</>)}
                                              </>)}
                                          </>)}
                                        </>)}
                                      </span>
                                    </td>
                                    <td className="text-center">
                                      {/* <span
                                        className="badge badge-success"
                                        id={"plan_type_" + value.id}
                                      >
                                        Available
                                      </span> */}
                                    </td>
                                    <td className="text-center">
                                      <div className="row">
                                        <div className="col-lg-2 col-xxl-2"></div>
                                        <div className="col-lg-10 col-xxl-10">
                                          <span className="text-muted fs-7 fw-bolder">
                                            <del
                                              id={"actual_price_" + value.id}
                                            >
                                              {userData != null ? (
                                                <>
                                                  {plan_period == "12month" && plansDiscount.month12>0 ? (<>
                                                    {userData.currencyprefix}{(parseFloat(value.price) * 12).toFixed(0)}
                                                  </>) : (<>
                                                    {plan_period == "monthly"  && plansDiscount.monthly>0 ? (<>{userData.currencyprefix}{parseFloat(value.price).toFixed(0)}</>) : (<>
                                                      {plan_period == "6month"  && plansDiscount.month6>0 ? (<>{userData.currencyprefix}{(parseFloat(value.price) * 6).toFixed(0)}</>) : (<>
                                                        {plan_period == "3month"  && plansDiscount.month3>0 ? (<>{userData.currencyprefix}{(parseFloat(value.price) * 3).toFixed(0)}</>) : ('')}</>)}</>)}
                                                  </>)}</>) : (<>
                                                        {plan_period == "hourly"  && plansDiscount.hourly>0 ? (<>{userData.currencyprefix}{parseFloat(value.price).toFixed(0)}</>) : ('')}</>)}
                                            </del>
                                          </span>
                                          <br />
                                          <span
                                            className="badge badge-light-primary fs-7 fw-bolder"
                                            style={{ marginRight: 5 }}
                                            id={
                                              "updated_plan_price_" + value.id
                                            }
                                          >
                                            {userData != null ? (
                                              <>
                                                {
                                                  userData.currencyprefix
                                                }
                                                {plan_period == "12month" ? (<>
                                                  {(calculateActualPrice(value.price, plansDiscount.month12, plan_period))}
                                                </>) : (<>
                                                  {plan_period == "monthly" ? (<>{calculateActualPrice(value.price, plansDiscount.monthly, plan_period)}</>) :
                                                    (<>
                                                    {plan_period == "3month" ? (<>{calculateActualPrice(value.price, plansDiscount.month3, plan_period)}</>) :
                                                    (<>
                                                    {plan_period == "6month" ? (<>{calculateActualPrice(value.price, plansDiscount.month6, plan_period)}</>) :
                                                    (<>{calculateActualPrice(value.price, plansDiscount.hourly, plan_period)}</>)}
                                                    </>)}
                                                    </>)}
                                                </>)}


                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </span>
                                          <br /> 
                                        </div>
                                        <div />
                                      </div>
                                    </td>
                                    <td className="ps-0 text-center ribbon ribbon-end ribbon-clip">
                                      <div
                                        className="d-none tickMarkPlan ribbon ribbon-triangle ribbon-top-start border-primary"
                                        id={"tickMarkPlan-" + value.id}
                                        style={{ marginTop: '-1px' }}
                                      >
                                        <div className="ribbon-icon mt-n5">
                                          <i className="bi bi-check2 fs-2 text-white" />
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </>) : (<>
                                  <tr
                                    onClick={(e) => requestPlan(value.id)}
                                    className="planseffect tick-parent "
                                    id={"tick_parent_" + value.id}
                                    style={{position: "relative"}}
                                  >
                                    <td className="ps-0 text-center">
                                      <form id={"start_fms_" + value.id} encType="multipart/form-data" className="form fms">
                                        <input type="hidden" name="field[800]" value={userData != null ? (userData.id) : ('')} />
                                        <input type="hidden" name="field[801]" value={userData != null ? (userData.fullname) : ('')} />
                                        <input type="hidden" name="field[802]" value={"PlanId " + value.id + " / " + value.cpu + "/ vCPU / " + (value.ram / 1024).toFixed(0) + " GB RAM/ " + value.disk + " GB Disk / DC Zone " + dclocation} />
                                        <input type="hidden" name="templateid" value="Product-Waiting-List" />
                                      </form>
                                      <input type="hidden" id={"plan_cost_" + value.id} defaultValue={value.price} />
                                      <input type="hidden" id={"ram_" + value.id} defaultValue={value.ram} />
                                      {(value.ram / 1024).toFixed(0)} GB
                                      {/* {value.id} */}
                                    </td>
                                    <td className="text-center">
                                      <input type="hidden" id={"cpu_" + value.id} defaultValue={value.cpu} />
                                      {value.dedicated_vcore == "1" ? (<>
                                        {value.cpu} vCPU <br /><span className="badge badge-success">Dedicated</span>
                                      </>) : (<>
                                        {value.cpu} vCPU
                                      </>)}
                                    </td>
                                    <td className="text-center">
                                      <input type="hidden" id={"disk_" + value.id} defaultValue={value.disk} /> 
                                      {value.disk!='0' && value.disk!=0?(<>{value.disk} GB</>):('-')}</td>
                                    <td className="text-center pe-5">
                                      <input type="hidden" id={"bandwidth_" + value.id} defaultValue={value.bandwidth} />
                                      {value.bandwidth} GB</td>
                                    <td className="text-center">
                                      <span
                                        className="badge badge-success"
                                        id={"plan_type_" + value.id}
                                      >
                                        {plan_period == "12month" ? (<>
                                          Annually
                                        </>) : (<>
                                          {plan_period == "monthly" ? (<>Monthly</>) : (<>
                                            {plan_period == "3month" ? (<>3 Months</>) : (<>
                                              {plan_period == "6month" ? (<>6 Months</>) : (<>Hourly</>)}
                                              </>)}
                                          </>)}
                                        </>)}
                                      </span>
                                    </td>
                                    <td className="text-center">
                                       <span
                                        className="badge badge-warning plan-overlay plan-overlay-container"
                                        id={"plan_type_" + value.id}
                                      >
                                        <span className="badge badge-danger">Not available</span>
                                      </span>
                                    </td>
                                    <td className="text-center">
                                      <div className="row">
                                        <div className="col-lg-2 col-xxl-2"></div>
                                        <div className="col-lg-10 col-xxl-10">
                                          <span className="text-muted fs-7 fw-bolder">
                                            <del
                                              id={"actual_price_" + value.id}
                                            >
                                              {userData != null ? (
                                                <>
                                                  {plan_period == "12month" && plansDiscount.month12>0 ? (<>
                                                    {userData.currencyprefix}{(parseFloat(value.price) * 12).toFixed(0)}
                                                  </>) : (<>
                                                    {plan_period == "monthly"  && plansDiscount.monthly>0 ? (<>{userData.currencyprefix}{parseFloat(value.price).toFixed(0)}</>) : (<>
                                                      {plan_period == "6month"  && plansDiscount.month6>0 ? (<>{userData.currencyprefix}{(parseFloat(value.price) * 6).toFixed(0)}</>) : (<>
                                                        {plan_period == "3month"  && plansDiscount.month3>0 ? (<>{userData.currencyprefix}{(parseFloat(value.price) * 3).toFixed(0)}</>) : ('')}</>)}</>)}
                                                  </>)}</>) : (<>
                                                        {plan_period == "hourly"  && plansDiscount.hourly>0 ? (<>{userData.currencyprefix}{parseFloat(value.price).toFixed(0)}</>) : ('')}</>)}
                                            </del>
                                          </span>
                                          <br />
                                          <span
                                            className="badge badge-light-primary fs-7 fw-bolder"
                                            style={{ marginRight: 5 }}
                                            id={
                                              "updated_plan_price_" + value.id
                                            }
                                          >
                                            {userData != null ? (
                                              <>
                                                {
                                                  userData.currencyprefix
                                                }
                                                {plan_period == "12month" ? (<>
                                                  {(calculateActualPrice(value.price, plansDiscount.month12, plan_period))}
                                                </>) : (<>
                                                  {plan_period == "monthly" ? (<>{calculateActualPrice(value.price, plansDiscount.monthly, plan_period)}</>) :
                                                    (<>
                                                    {plan_period == "3month" ? (<>{calculateActualPrice(value.price, plansDiscount.month3, plan_period)}</>) :
                                                    (<>
                                                    {plan_period == "6month" ? (<>{calculateActualPrice(value.price, plansDiscount.month6, plan_period)}</>) :
                                                    (<>{calculateActualPrice(value.price, plansDiscount.hourly, plan_period)}</>)}
                                                    </>)}
                                                    </>)}
                                                </>)}


                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </span>
                                          <br /> 
                                        </div>
                                        <div />
                                      </div>
                                    </td>
                                    <td className="ps-0 text-center ribbon ribbon-end ribbon-clip">
                                      <div
                                        className="d-none tickMarkPlan ribbon ribbon-triangle ribbon-top-start border-primary"
                                        id={"tickMarkPlan-" + value.id}
                                        style={{ marginTop: '-1px' }}
                                      >
                                        <div className="ribbon-icon mt-n5">
                                          <i className="bi bi-check2 fs-2 text-white" />
                                        </div>
                                      </div>
                                    </td> 
                                  </tr>
                                </>)}
                              </>
                            ) : (
                              ""
                            )}
                          </>

                        ))}</>):('')}
                  </tbody>
                  {/*end::Tbody*/}
                </table>
                {/*end::Table*/}
              </div>
            </div>
          </div>
          <div className="tab-pane " id="dedicated-cpu-tab" role="tabpanel">
            <div className="row mt-5" data-kt-buttons="true">
              <div className="table-responsive" id="ramcpuplans">
                {/*begin::Table*/}
                <table className="table  table-row-bordered table-flush align-middle gy-3 tableramcpu">
                  {/*begin::Thead*/}
                  <thead className="border-bottom border-gray-200 fs-6 fw-bolder bg-secondary" style={{height:"60px",verticalAlign:"inherit"}}>
                    <tr>
                      <th className="w-10 text-center">RAM</th>
                      <th className="w-20 text-center">vCPU</th>
                      <th className="w-20 text-center">Storage</th>
                      <th className="w-20 text-center">Bandwidth</th>
                      <th className="w-10 text-center">Plan</th>
                      <th className=""></th>
                      <th className="w-15 ps-8 text-center">
                        Price
                      </th>
                      <th className="w-5 text-center" />
                    </tr>
                  </thead>
                  {/*end::Thead*/}
                  {/*begin::Tbody*/}
                  <tbody className="fs-6 fw-bold text-gray-600">
                    {planDataList!=null && planDataList ? (<>{
                        planDataList.map((value, index) => (
                          <>
                            {value.slug == "dedicated-cpu" ? (
                              <>
                                {value.is_available == "YES" ? (<>
                                  <tr
                                    onClick={(e) => selectPlan(value.id)}
                                    className="planseffect tick-parent"
                                    id={"tick_parent_" + value.id}
                                  >
                                    <td className="ps-0 text-center">
                                      <input type="hidden" id={"plan_cost_" + value.id} defaultValue={value.price} />
                                      <input type="hidden" id={"ram_" + value.id} defaultValue={value.ram} />
                                      {(value.ram / 1024).toFixed(0)} GB
                                      {/* {value.id} */}
                                    </td>
                                    <td className="text-center">
                                      <input type="hidden" id={"cpu_" + value.id} defaultValue={value.cpu} />
                                      {value.dedicated_vcore == "1" ? (<>
                                        {value.cpu} vCPU <br /><span className="badge badge-success">Dedicated</span>
                                      </>) : (<>
                                        {value.cpu} vCPU
                                      </>)}
                                    </td>
                                    <td className="text-center">
                                      <input type="hidden" id={"disk_" + value.id} defaultValue={value.disk} />
                                       {value.disk==0 || value.disk=='0'?('ebs'):(<>{value.disk} GB</>)}</td>
                                    <td className="text-center pe-5">
                                      <input type="hidden" id={"bandwidth_" + value.id} defaultValue={value.bandwidth} />
                                      {value.bandwidth} GB</td>
                                    <td className="text-center">
                                      <span
                                        className="badge badge-success"
                                        id={"plan_type_" + value.id}
                                      >
                                       {plan_period == "12month" ? (<>
                                          Annually
                                        </>) : (<>
                                          {plan_period == "monthly" ? (<>Monthly</>) : (<>
                                            {plan_period == "3month" ? (<>3 Months</>) : (<>
                                              {plan_period == "6month" ? (<>6 Months</>) : (<>Hourly</>)}
                                              </>)}
                                          </>)}
                                        </>)}
                                      </span>
                                    </td>
                                    <td className="text-center">
                                      {/* <span
                                        className="badge badge-success"
                                        id={"plan_type_" + value.id}
                                      >
                                        Available
                                      </span> */}
                                    </td>
                                    <td className="text-center">
                                      <div className="row">
                                        <div className="col-lg-2 col-xxl-2"></div>
                                        <div className="col-lg-10 col-xxl-10">
                                          <span className="text-muted fs-7 fw-bolder">
                                            <del
                                              id={"actual_price_" + value.id}
                                            >
                                              {userData != null ? (
                                                <>
                                                  {plan_period == "12month" && plansDiscount.month12>0 ? (<>
                                                    {userData.currencyprefix}{(parseFloat(value.price) * 12).toFixed(0)}
                                                  </>) : (<>
                                                    {plan_period == "monthly"  && plansDiscount.monthly>0 ? (<>{userData.currencyprefix}{parseFloat(value.price).toFixed(0)}</>) : (<>
                                                      {plan_period == "6month"  && plansDiscount.month6>0 ? (<>{userData.currencyprefix}{(parseFloat(value.price) * 6).toFixed(0)}</>) : (<>
                                                        {plan_period == "3month"  && plansDiscount.month3>0 ? (<>{userData.currencyprefix}{(parseFloat(value.price) * 3).toFixed(0)}</>) : ('')}</>)}</>)}
                                                  </>)}</>) : (<>
                                                        {plan_period == "hourly"  && plansDiscount.hourly>0 ? (<>{userData.currencyprefix}{parseFloat(value.price).toFixed(0)}</>) : ('')}</>)}
                                            </del>
                                          </span>
                                          <br />
                                          <span
                                            className="badge badge-light-primary fs-7 fw-bolder"
                                            style={{ marginRight: 5 }}
                                            id={
                                              "updated_plan_price_" + value.id
                                            }
                                          >
                                            {userData != null ? (
                                              <>
                                                {
                                                  userData.currencyprefix
                                                }
                                                {plan_period == "12month" ? (<>
                                                  {(calculateActualPrice(value.price, plansDiscount.month12, plan_period))}
                                                </>) : (<>
                                                  {plan_period == "monthly" ? (<>{calculateActualPrice(value.price, plansDiscount.monthly, plan_period)}</>) :
                                                    (<>
                                                    {plan_period == "3month" ? (<>{calculateActualPrice(value.price, plansDiscount.month3, plan_period)}</>) :
                                                    (<>
                                                    {plan_period == "6month" ? (<>{calculateActualPrice(value.price, plansDiscount.month6, plan_period)}</>) :
                                                    (<>{calculateActualPrice(value.price, plansDiscount.hourly, plan_period)}</>)}
                                                    </>)}
                                                    </>)}
                                                </>)}


                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </span>
                                          <br /> 
                                        </div>
                                        <div />
                                      </div>
                                    </td>
                                    <td className="ps-0 text-center ribbon ribbon-end ribbon-clip">
                                      <div
                                        className="d-none tickMarkPlan ribbon ribbon-triangle ribbon-top-start border-primary"
                                        id={"tickMarkPlan-" + value.id}
                                        style={{ marginTop: '-1px' }}
                                      >
                                        <div className="ribbon-icon mt-n5">
                                          <i className="bi bi-check2 fs-2 text-white" />
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </>) : (<>
                                  <tr
                                    onClick={(e) => requestPlan(value.id)}
                                    className="planseffect tick-parent "
                                    id={"tick_parent_" + value.id}
                                    style={{position: "relative"}}
                                  >
                                    <td className="ps-0 text-center">
                                      <form id={"start_fms_" + value.id} encType="multipart/form-data" className="form fms">
                                        <input type="hidden" name="field[800]" value={userData != null ? (userData.id) : ('')} />
                                        <input type="hidden" name="field[801]" value={userData != null ? (userData.fullname) : ('')} />
                                        <input type="hidden" name="field[802]" value={"PlanId " + value.id + " / " + value.cpu + "/ vCPU / " + (value.ram / 1024).toFixed(0) + " GB RAM/ " + value.disksize + " GB Disk / DC Zone " + dclocation} />
                                        <input type="hidden" name="templateid" value="Product-Waiting-List" />
                                      </form>
                                      <input type="hidden" id={"plan_cost_" + value.id} defaultValue={value.price} />
                                      <input type="hidden" id={"ram_" + value.id} defaultValue={value.ram} />
                                      {(value.ram / 1024).toFixed(0)} GB
                                      {/* {value.id} */}
                                    </td>
                                    <td className="text-center">
                                      <input type="hidden" id={"cpu_" + value.id} defaultValue={value.cpu} />
                                      {value.dedicated_vcore == "1" ? (<>
                                        {value.cpu} vCPU <br /><span className="badge badge-success">Dedicated</span>
                                      </>) : (<>
                                        {value.cpu} vCPU
                                      </>)}
                                    </td>
                                    <td className="text-center">
                                      <input type="hidden" id={"disk_" + value.id} defaultValue={value.disk} />
                                       {value.disk==0 || value.disk=='0'?('ebs'):(<>{value.disk} GB</>)}</td>
                                    <td className="text-center pe-5">
                                      <input type="hidden" id={"bandwidth_" + value.id} defaultValue={value.bandwidth} />
                                      {value.bandwidth} GB</td>
                                    <td className="text-center">
                                      <span
                                        className="badge badge-success"
                                        id={"plan_type_" + value.id}
                                      >
                                        {plan_period == "12month" ? (<>
                                          Annually
                                        </>) : (<>
                                          {plan_period == "monthly" ? (<>Monthly</>) : (<>
                                            {plan_period == "3month" ? (<>3 Months</>) : (<>
                                              {plan_period == "6month" ? (<>6 Months</>) : (<>Hourly</>)}
                                              </>)}
                                          </>)}
                                        </>)}
                                      </span>
                                    </td>
                                    <td className="text-center">
                                    <span
                                        className="badge badge-warning plan-overlay plan-overlay-container"
                                        id={"plan_type_" + value.id}
                                      >
                                        <span className="badge badge-danger">Not available</span>
                                      </span>
                                    </td>
                                    <td className="text-center">
                                      <div className="row">
                                        <div className="col-lg-2 col-xxl-2"></div>
                                        <div className="col-lg-10 col-xxl-10">
                                          <span className="text-muted fs-7 fw-bolder">
                                            <del
                                              id={"actual_price_" + value.id}
                                            >
                                              {userData != null ? (
                                                <>
                                                  {plan_period == "12month" && plansDiscount.month12>0 ? (<>
                                                    {userData.currencyprefix}{(parseFloat(value.price) * 12).toFixed(0)}
                                                  </>) : (<>
                                                    {plan_period == "monthly"  && plansDiscount.monthly>0 ? (<>{userData.currencyprefix}{parseFloat(value.price).toFixed(0)}</>) : (<>
                                                      {plan_period == "6month"  && plansDiscount.month6>0 ? (<>{userData.currencyprefix}{(parseFloat(value.price) * 6).toFixed(0)}</>) : (<>
                                                        {plan_period == "3month"  && plansDiscount.month3>0 ? (<>{userData.currencyprefix}{(parseFloat(value.price) * 3).toFixed(0)}</>) : ('')}</>)}</>)}
                                                  </>)}</>) : (<>
                                                        {plan_period == "hourly"  && plansDiscount.hourly>0 ? (<>{userData.currencyprefix}{parseFloat(value.price).toFixed(0)}</>) : ('')}</>)}
                                            </del>
                                          </span>
                                          <br />
                                          <span
                                            className="badge badge-light-primary fs-7 fw-bolder"
                                            style={{ marginRight: 5 }}
                                            id={
                                              "updated_plan_price_" + value.id
                                            }
                                          >
                                            {userData != null ? (
                                              <>
                                                {
                                                  userData.currencyprefix
                                                }
                                                {plan_period == "12month" ? (<>
                                                  {(calculateActualPrice(value.price, plansDiscount.month12, plan_period))}
                                                </>) : (<>
                                                  {plan_period == "monthly" ? (<>{calculateActualPrice(value.price, plansDiscount.monthly, plan_period)}</>) :
                                                    (<>
                                                    {plan_period == "3month" ? (<>{calculateActualPrice(value.price, plansDiscount.month3, plan_period)}</>) :
                                                    (<>
                                                    {plan_period == "6month" ? (<>{calculateActualPrice(value.price, plansDiscount.month6, plan_period)}</>) :
                                                    (<>{calculateActualPrice(value.price, plansDiscount.hourly, plan_period)}</>)}
                                                    </>)}
                                                    </>)}
                                                </>)}


                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </span>
                                          <br /> 
                                        </div>
                                        <div />
                                      </div>
                                    </td>
                                    <td className="ps-0 text-center ribbon ribbon-end ribbon-clip">
                                      <div
                                        className="d-none tickMarkPlan ribbon ribbon-triangle ribbon-top-start border-primary"
                                        id={"tickMarkPlan-" + value.id}
                                        style={{ marginTop: '-1px' }}
                                      >
                                        <div className="ribbon-icon mt-n5">
                                          <i className="bi bi-check2 fs-2 text-white" />
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </>)}
                              </>
                            ) : (
                              ""
                            )}
                          </>

                        ))}</>) : (<><td colspan="12"><div className="small-spinner"></div></td></>)}
                  </tbody>
                  {/*end::Tbody*/}
                </table>
                {/*end::Table*/}
              </div>
            </div>
          </div>
          <div className="tab-pane " id="dedicated-memory-tab" role="tabpanel">
            <div className="row mt-5" data-kt-buttons="true">
              <div className="table-responsive" id="ramcpuplans">
                {/*begin::Table*/}
                <table className="table  table-row-bordered table-flush align-middle gy-3 tableramcpu">
                  {/*begin::Thead*/}
                  <thead className="border-bottom border-gray-200 fs-6 fw-bolder bg-secondary" style={{height:"60px",verticalAlign:"inherit"}}>
                    <tr>
                      <th className="w-10 text-center">RAM</th>
                      <th className="w-20 text-center">vCPU</th>
                      <th className="w-20 text-center">Storage</th>
                      <th className="w-20 text-center">Bandwidth</th>
                      <th className="w-10 text-center">Plan</th>
                      <th className=""></th>
                      <th className="w-15 ps-8 text-center">
                        Price
                      </th>
                      <th className="w-5 text-center" />
                    </tr>
                  </thead>
                  {/*end::Thead*/}
                  {/*begin::Tbody*/}
                  <tbody className="fs-6 fw-bold text-gray-600">
                    {planDataList!=null && planDataList ? (<>{
                        planDataList.map((value, index) => (
                          <>
                            {value.slug == "dedicated-memory" ? (
                              <>
                                {value.is_available == "YES" ? (<>
                                  <tr
                                    onClick={(e) => selectPlan(value.id)}
                                    className="planseffect tick-parent"
                                    id={"tick_parent_" + value.id}
                                  >
                                    <td className="ps-0 text-center">
                                      <input type="hidden" id={"plan_cost_" + value.id} defaultValue={value.price} />
                                      <input type="hidden" id={"ram_" + value.id} defaultValue={value.ram} />
                                      {(value.ram / 1024).toFixed(0)} GB
                                      {/* {value.id} */}
                                    </td>
                                    <td className="text-center">
                                      <input type="hidden" id={"cpu_" + value.id} defaultValue={value.cpu} />
                                      {value.dedicated_vcore == "1" ? (<>
                                        {value.cpu} vCPU <br /><span className="badge badge-success">Dedicated</span>
                                      </>) : (<>
                                        {value.cpu} vCPU
                                      </>)}
                                    </td>
                                    <td className="text-center">
                                      <input type="hidden" id={"disk_" + value.id} defaultValue={value.disk} />
                                       {value.disk==0 || value.disk=='0'?('ebs'):(<>{value.disk} GB</>)}</td>
                                    <td className="text-center pe-5">
                                      <input type="hidden" id={"bandwidth_" + value.id} defaultValue={value.bandwidth} />
                                      {value.bandwidth} GB</td>
                                    <td className="text-center">
                                      <span
                                        className="badge badge-success"
                                        id={"plan_type_" + value.id}
                                      >
                                        {plan_period == "12month" ? (<>
                                          Annually
                                        </>) : (<>
                                          {plan_period == "monthly" ? (<>Monthly</>) : (<>
                                            {plan_period == "3month" ? (<>3 Months</>) : (<>
                                              {plan_period == "6month" ? (<>6 Months</>) : (<>Hourly</>)}
                                              </>)}
                                          </>)}
                                        </>)}
                                      </span>
                                    </td>
                                    <td className="text-center">
                                      {/* <span
                                        className="badge badge-success"
                                        id={"plan_type_" + value.id}
                                      >
                                        Available
                                      </span> */}
                                    </td>
                                    <td className="text-center">
                                      <div className="row">
                                        <div className="col-lg-2 col-xxl-2"></div>
                                        <div className="col-lg-10 col-xxl-10">
                                          <span className="text-muted fs-7 fw-bolder">
                                            <del
                                              id={"actual_price_" + value.id}
                                            >
                                              {userData != null ? (
                                                <>
                                                  {plan_period == "12month" && plansDiscount.month12>0 ? (<>
                                                    {userData.currencyprefix}{(parseFloat(value.price) * 12).toFixed(0)}
                                                  </>) : (<>
                                                    {plan_period == "monthly"  && plansDiscount.monthly>0 ? (<>{userData.currencyprefix}{parseFloat(value.price).toFixed(0)}</>) : (<>
                                                      {plan_period == "6month"  && plansDiscount.month6>0 ? (<>{userData.currencyprefix}{(parseFloat(value.price) * 6).toFixed(0)}</>) : (<>
                                                        {plan_period == "3month"  && plansDiscount.month3>0 ? (<>{userData.currencyprefix}{(parseFloat(value.price) * 3).toFixed(0)}</>) : ('')}</>)}</>)}
                                                  </>)}</>) : (<>
                                                        {plan_period == "hourly"  && plansDiscount.hourly>0 ? (<>{userData.currencyprefix}{parseFloat(value.price).toFixed(0)}</>) : ('')}</>)}
                                            </del>
                                          </span>
                                          <br />
                                          <span
                                            className="badge badge-light-primary fs-7 fw-bolder"
                                            style={{ marginRight: 5 }}
                                            id={
                                              "updated_plan_price_" + value.id
                                            }
                                          >
                                            {userData != null ? (
                                              <>
                                                {
                                                  userData.currencyprefix
                                                }
                                                {plan_period == "12month" ? (<>
                                                  {(calculateActualPrice(value.price, plansDiscount.month12, plan_period))}
                                                </>) : (<>
                                                  {plan_period == "monthly" ? (<>{calculateActualPrice(value.price, plansDiscount.monthly, plan_period)}</>) :
                                                    (<>
                                                    {plan_period == "3month" ? (<>{calculateActualPrice(value.price, plansDiscount.month3, plan_period)}</>) :
                                                    (<>
                                                    {plan_period == "6month" ? (<>{calculateActualPrice(value.price, plansDiscount.month6, plan_period)}</>) :
                                                    (<>{calculateActualPrice(value.price, plansDiscount.hourly, plan_period)}</>)}
                                                    </>)}
                                                    </>)}
                                                </>)}


                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </span>
                                          <br /> 
                                        </div>
                                        <div />
                                      </div>
                                    </td>
                                    <td className="ps-0 text-center ribbon ribbon-end ribbon-clip">
                                      <div
                                        className="d-none tickMarkPlan ribbon ribbon-triangle ribbon-top-start border-primary"
                                        id={"tickMarkPlan-" + value.id}
                                        style={{ marginTop: '-1px' }}
                                      >
                                        <div className="ribbon-icon mt-n5">
                                          <i className="bi bi-check2 fs-2 text-white" />
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </>) : (<>
                                  <tr
                                    onClick={(e) => requestPlan(value.id)}
                                    className="planseffect tick-parent "
                                    id={"tick_parent_" + value.id}
                                    style={{position: "relative"}}
                                  >
                                    <td className="ps-0 text-center">
                                      <form id={"start_fms_" + value.id} encType="multipart/form-data" className="form fms">
                                        <input type="hidden" name="field[800]" value={userData != null ? (userData.id) : ('')} />
                                        <input type="hidden" name="field[801]" value={userData != null ? (userData.fullname) : ('')} />
                                        <input type="hidden" name="field[802]" value={"PlanId " + value.id + " / " + value.cpu + "/ vCPU / " + (value.ram / 1024).toFixed(0) + " GB RAM/ " + value.disksize + " GB Disk / DC Zone " + dclocation} />
                                        <input type="hidden" name="templateid" value="Product-Waiting-List" />
                                      </form>
                                      <input type="hidden" id={"plan_cost_" + value.id} defaultValue={value.price} />
                                      <input type="hidden" id={"ram_" + value.id} defaultValue={value.ram} />
                                      {(value.ram / 1024).toFixed(0)} GB
                                      {/* {value.id} */}
                                    </td>
                                    <td className="text-center">
                                      <input type="hidden" id={"cpu_" + value.id} defaultValue={value.cpu} />
                                      {value.dedicated_vcore == "1" ? (<>
                                        {value.cpu} vCPU <br /><span className="badge badge-success">Dedicated</span>
                                      </>) : (<>
                                        {value.cpu} vCPU
                                      </>)}
                                    </td>
                                    <td className="text-center">
                                      <input type="hidden" id={"disk_" + value.id} defaultValue={value.disk} />
                                       {value.disk==0 || value.disk=='0'?('ebs'):(<>{value.disk} GB</>)}</td>  
                                    <td className="text-center pe-5">
                                      <input type="hidden" id={"bandwidth_" + value.id} defaultValue={value.bandwidth} />
                                      {value.bandwidth} GB</td>
                                    <td className="text-center">
                                      <span
                                        className="badge badge-success"
                                        id={"plan_type_" + value.id}
                                      >
                                        {plan_period == "12month" ? (<>
                                          Annually
                                        </>) : (<>
                                          {plan_period == "monthly" ? (<>Monthly</>) : (<>
                                            {plan_period == "3month" ? (<>3 Months</>) : (<>
                                              {plan_period == "6month" ? (<>6 Months</>) : (<>Hourly</>)}
                                              </>)}
                                          </>)}
                                        </>)}
                                      </span>
                                    </td>
                                    <td className="text-center">
                                    <span
                                        className="badge badge-warning plan-overlay plan-overlay-container"
                                        id={"plan_type_" + value.id}
                                      >
                                        <span className="badge badge-danger">Not available</span>
                                      </span>
                                    </td>
                                    <td className="text-center">
                                      <div className="row">
                                        <div className="col-lg-2 col-xxl-2"></div>
                                        <div className="col-lg-10 col-xxl-10">
                                          <span className="text-muted fs-7 fw-bolder">
                                            <del
                                              id={"actual_price_" + value.id}
                                            >
                                              {userData != null ? (
                                                <>
                                                  {plan_period == "12month" && plansDiscount.month12>0 ? (<>
                                                    {userData.currencyprefix}{(parseFloat(value.price) * 12).toFixed(0)}
                                                  </>) : (<>
                                                    {plan_period == "monthly"  && plansDiscount.monthly>0 ? (<>{userData.currencyprefix}{parseFloat(value.price).toFixed(0)}</>) : (<>
                                                      {plan_period == "6month"  && plansDiscount.month6>0 ? (<>{userData.currencyprefix}{(parseFloat(value.price) * 6).toFixed(0)}</>) : (<>
                                                        {plan_period == "3month"  && plansDiscount.month3>0 ? (<>{userData.currencyprefix}{(parseFloat(value.price) * 3).toFixed(0)}</>) : ('')}</>)}</>)}
                                                  </>)}</>) : (<>
                                                        {plan_period == "hourly"  && plansDiscount.hourly>0 ? (<>{userData.currencyprefix}{parseFloat(value.price).toFixed(0)}</>) : ('')}</>)}
                                            </del>
                                          </span>
                                          <br />
                                          <span
                                            className="badge badge-light-primary fs-7 fw-bolder"
                                            style={{ marginRight: 5 }}
                                            id={
                                              "updated_plan_price_" + value.id
                                            }
                                          >
                                            {userData != null ? (
                                              <>
                                                {
                                                  userData.currencyprefix
                                                }
                                                {plan_period == "12month" ? (<>
                                                  {(calculateActualPrice(value.price, plansDiscount.month12, plan_period))}
                                                </>) : (<>
                                                  {plan_period == "monthly" ? (<>{calculateActualPrice(value.price, plansDiscount.monthly, plan_period)}</>) :
                                                    (<>
                                                    {plan_period == "3month" ? (<>{calculateActualPrice(value.price, plansDiscount.month3, plan_period)}</>) :
                                                    (<>
                                                    {plan_period == "6month" ? (<>{calculateActualPrice(value.price, plansDiscount.month6, plan_period)}</>) :
                                                    (<>{calculateActualPrice(value.price, plansDiscount.hourly, plan_period)}</>)}
                                                    </>)}
                                                    </>)}
                                                </>)}


                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </span>
                                          <br /> 
                                        </div>
                                        <div />
                                      </div>
                                    </td>
                                    <td className="ps-0 text-center ribbon ribbon-end ribbon-clip">
                                      <div
                                        className="d-none tickMarkPlan ribbon ribbon-triangle ribbon-top-start border-primary"
                                        id={"tickMarkPlan-" + value.id}
                                        style={{ marginTop: '-1px' }}
                                      >
                                        <div className="ribbon-icon mt-n5">
                                          <i className="bi bi-check2 fs-2 text-white" />
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </>)}
                              </>
                            ) : (
                              ""
                            )}
                          </>

                        ))}</>) : (<><td colspan="12"><div className="small-spinner"></div></td></>)}
                  </tbody>
                  {/*end::Tbody*/}
                </table>
                {/*end::Table*/}
              </div>
            </div>
          </div>
        </div>
        {/* Horizontal PLAN START*/}

        {/*end::Header*/}
        {/*end: Card Body*/}
        {/*end::List widget 10*/}
      </div>
      {/*end::Col*/}
    </>
  );
};

const mapStateToProps = state => ({
  currPlanData: state.planData.planDataInfo
})

const mapDispatchToProps = dispatch => ({
  // updateRow:data=>dispatch(DeployNodePoolRow(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(PricingContainer) 