import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UseApi from '../../../../../CustomHooks/useApi';
import UsePostData from "../../../../../CustomHooks/usePostData";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

export default function Schedules() {
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [updateRecurrenceState, setUpdateRecurrenceState] = useState()
  const [pid, setPid] = useState('')
  var recurrence_type_arr = ["Cron", "Every 5 Min", "Every 30 Min", "Every 1 hour", "Every day", "Every week", "Once"];
  var query = window.location.href;
  var vars = query.split("/");
  var id = vars[4];
  const makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
  const loaderFunc = () => {
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if (loader && no_record) {
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");

      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
  };
  function checkObjectForEmpty(obj) {
    for (const key in obj) {
      const value = obj[key];

      if (typeof value === 'string' && value.trim() === '') {
        return `Error: '${key}' should not be empty or blank.`;
      }

      if (typeof value === 'object') {
        const nestedError = checkObjectForEmpty(value);
        if (nestedError) {
          return nestedError;
        }
      }
    }

    return null; // No empty or blank elements found
  }
  const [scheduleArr, setScheduleArr] = useState(null);
  const getAutoScalingSchedules = async () => {

    const data = await UseApi("get", "autoscaling/" + id + "/schedulepolicy", "");
    if (data) {
      if (data.rcode == "error") {
        const dt = toast
        toast.update(dt, {
          render: data.message,
          type: "warning",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setScheduleArr([]);
        loaderFunc();
        return;
      } else {
        var groupdata = data.schedules;
        setScheduleArr(groupdata?.reverse());
      }
    }

  };

  useEffect(() => {
    getAutoScalingSchedules();
  }, [pid])

  function updateAdjustmentValue(id, action) {
    const field = document.getElementById(id);

    if (field) {
      // Get the current value and convert it to a number
      let currentValue = parseFloat(field.value) || 0;

      // Update the value based on the action
      if (action === 'up') {
        currentValue += 1;
      } else if (action === 'down') {
        currentValue -= 1;
      }

      // Update the field with the new value
      if (currentValue <= 0) {
        field.value = 1;
      } else {
        field.value = currentValue;
      }
    }
  }

  const updateAutoScalingSchedules = (id, pdata) => {
    //console.log(pdata,"fdfdg");

    var update_name1 = document.querySelector("#update_name2");
    var update_desiredsize = document.querySelector("#update_desiredsize2");
    var update_timezone = document.querySelector("#update_ist2");
    var update_recurrence = document.querySelector("#update_recurrence2");
    var update_week = document.querySelector("#update_week");
    var update_time = document.querySelector("#update_time");
    var update_recurrenceOrg = document.querySelector("#update_recurrence_");
    var update_start_date = document.querySelector("#update_start_date2");
    setUpdateRecurrenceState(findRecurrenceType(pdata.recurrence))
    if (update_name1 && update_desiredsize && update_timezone && update_recurrence && update_start_date && update_recurrenceOrg && update_time && update_week) {
      //console.log("hello",pdata.name);
      update_recurrenceOrg.value = pdata.recurrence;
      update_name1.value = pdata.name;
      update_desiredsize.value = pdata.desiredsize;
      update_timezone.value = pdata.timezone;
      update_week.value = findDayOfWeek(pdata.recurrence);
      update_time.value = findTimeOfDay(pdata.recurrence);
      update_recurrence.value = findRecurrenceType(pdata.recurrence);
      update_start_date.value = pdata.start_date;
      updateRecurrence("", findRecurrenceType(pdata.recurrence));
    }
    //console.log(update_name1,update_desiredsize,update_timezone,update_start_date,update_recurrence);
    setPid(id)
    updateOpenDrawer()
  };

  const updateSchedules = async () => {
    var update_name = document.querySelector("#update_name2");
    var update_desiredsize = document.querySelector("#update_desiredsize2");
    var update_timezone = document.querySelector("#update_ist2");
    var update_recurrence = document.querySelector("#update_recurrence_");
    var update_start_date = document.querySelector("#update_start_date2");

    //console.log(update_name.value);
    toast.dismiss();
    if (update_name && update_desiredsize && update_timezone && update_recurrence && update_start_date) {
      //console.log(modifyDateFormat(update_start_date.value));
      const dt = toast.loading("Please wait...");
      const submitBtn = document.querySelector("#update-schedule-btn");
      var updateData = { "name": update_name.value, "desiredsize": update_desiredsize.value, "recurrence": update_recurrence.value, "start_date": modifyDateFormat(update_start_date.value) };
      const validationError = checkObjectForEmpty(updateData);

      if (validationError) {
        toast.update(dt, {
          render: validationError,
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
      if (submitBtn) {
        var preContent = submitBtn.innerHTML;
        submitBtn.classList.add("disabled");
        submitBtn.innerHTML =
          'Please wait... <span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
        const responseData = await UsePostData("put", "autoscaling/" + id + "/schedulepolicy/" + pid, updateData);

        ////console.log(responseData);
        if (responseData.status === "error") {
          toast.update(dt, {
            render: responseData.message,
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (responseData.status === "success") {
          toast.update(dt, {
            render: responseData.message,
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          updateOpenDrawer()
          getAutoScalingSchedules();
        }
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
      }
    }
  }

  const addAutoScalingSchedules = async () => {
    var update_name = document.querySelector("#add_name_schedule");
    var update_desiredsize = document.querySelector("#add_desiredsize");
    var update_timezone = document.querySelector("#add_ist");
    var update_recurrence = document.querySelector("#add_recurrence_");
    var update_start_date = document.querySelector("#add_start_date");

    toast.dismiss();
    if (update_name && update_desiredsize && update_timezone && update_recurrence && update_start_date) {
      //console.log(modifyDateFormat(update_start_date.value));
      const dt = toast.loading("Please wait...");
      const submitBtn = document.querySelector("#add-schedule-btn");
      var updateData = { "name": update_name.value, "desiredsize": update_desiredsize.value, "recurrence": update_recurrence.value, "start_date": modifyDateFormat(update_start_date.value) };
      const validationError = checkObjectForEmpty(updateData);

      if (validationError) {
        setTimeout(() => {
          toast.update(dt, {
            render: validationError,
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }, 1000)
        return;
      }
      if (submitBtn) {
        var preContent = submitBtn.innerHTML;
        submitBtn.classList.add("disabled");
        submitBtn.innerHTML =
          'Please wait... <span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';

        const responseData = await UsePostData("post", "autoscaling/" + id + "/schedulepolicy", updateData);

        ////console.log(responseData);
        if (responseData.status === "error") {
          toast.update(dt, {
            render: responseData.message,
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (responseData.status === "success") {
          toast.update(dt, {
            render: responseData.message,
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          openDrawer();
          getAutoScalingSchedules();
        }
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
      }
    }
  };

  const deleteAutoScalingSchedules = async (pid, index) => {
    toast.dismiss();
    const dt = toast.loading("Please wait...");
    const submitBtn = document.querySelector("#delete-schedule-btn-" + pid);
    if (submitBtn) {
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML =
        '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';

      const responseData = await UseApi("delete", "autoscaling/" + id + "/schedulepolicy/" + pid, "");

      ////console.log(responseData);
      if (responseData.status === "error") {
        toast.update(dt, {
          render: responseData.message,
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (responseData.status === "success") {
        toast.update(dt, {
          render: responseData.message,
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setScheduleArr((old) => {
          old.splice(index, 1);
          return [...old];
        });
        // getAutoScalingSchedules();
      }
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML = preContent;
    }
  };

  function togglePolicy() {
    var add_policy_sec = document.querySelector("#add_policy_sec");
    if (add_policy_sec) {
      add_policy_sec.classList.toggle("d-none");
    }
  }

  function findRecurrenceType(inputString) {
    const recurrenceTypeArr = ["Cron", "Every 5 Min", "Every 30 Min", "Every 1 hour", "Every day", "Every week", "Once"];

    const matchingElement = recurrenceTypeArr.find(element => inputString.includes(element));

    return matchingElement || "";
  }

  function findDayOfWeek(inputString) {
    const dayOfWeekArray = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    const matchingElement = dayOfWeekArray.find(day => inputString.includes(day));

    if (matchingElement) {
      return matchingElement;
    }

    // If no match is found, return the current day of the week
    const currentDate = new Date();
    const currentDayIndex = currentDate.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
    const currentDay = dayOfWeekArray[currentDayIndex];

    return currentDay;
  }

  function findTimeOfDay(inputString) {
    // Regular expression to match time in the format "01:23PM"
    const timeRegex = /\b(\d{1,2}:\d{2})([APMapm]{2})\b/;

    // Check if the input string contains a match
    const match = inputString.match(timeRegex);

    if (match) {
      // Extract hours, minutes, and period (AM/PM)
      const [, time, period] = match;

      // Convert to 24-hour format
      const [hours, minutes] = time.split(':');
      let hours24 = parseInt(hours, 10);

      if (period.toLowerCase() === 'pm') {
        hours24 = hours24 === 12 ? 12 : hours24 + 12;
      } else if (period.toLowerCase() === 'am' && hours24 === 12) {
        hours24 = 0;
      }

      // Return the converted time in the format "13:23"
      return `${hours24.toString().padStart(2, '0')}:${minutes}`;
    }

    // If no match is found, return the current time
    const currentDate = new Date();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  }
  function createTimeFormat(timeString) {
    // Split the time string into hours and minutes
    const [hours, minutes] = timeString.split(':').map(Number);

    // Create a new Date object and set the hours and minutes in UTC
    const currentDateUTC = new Date();
    currentDateUTC.setUTCHours(hours);
    currentDateUTC.setUTCMinutes(minutes);
    currentDateUTC.setUTCSeconds(0); // Optional: Set seconds to 0

    // Add one hour
    currentDateUTC.setUTCHours(currentDateUTC.getUTCHours() + 1);

    // Adjust to IST (UTC+5:30)
    const offsetInMinutes = 5 * 60 + 30;
    const timestampInIST = currentDateUTC.getTime() + offsetInMinutes * 60 * 1000;

    // Format the timestamp as 'hh:mmA'
    const formattedTime = new Date(timestampInIST);
    const hours24 = formattedTime.getHours();
    const hours12 = hours24 % 12 || 12; // Convert to 12-hour format
    const ampm = hours24 < 12 ? 'AM' : 'PM';
    const formattedTimeString = `${String(hours12).padStart(2, '0')}:${String(minutes).padStart(2, '0')}${ampm}`;

    return formattedTimeString;
  }


  function getCurrentTime() {
    // Create a new Date object
    var currentTime = new Date();

    // Get hours and minutes
    var hours = currentTime.getHours();
    var minutes = currentTime.getMinutes();

    // Format the time as HH:mm
    var formattedTime = padZero(hours) + ":" + padZero(minutes);

    return formattedTime;
  }

  // Helper function to pad single-digit numbers with a leading zero
  function padZero(num) {
    return (num < 10 ? "0" : "") + num;
  }

  function addRecurrence(id, rvalue) {
    //console.log(rvalue);
    var recurrence_ = document.querySelector("#add_recurrence_");
    // var week_sec= document.querySelector("#week_sec_"+id); 
    // var time_sec= document.querySelector("#time_sec_"+id); 
    var time_value = document.querySelector("#add_time");
    var week_value = document.querySelector("#add_week");
    //console.log(time_value,week_value);
    if (week_value && time_value && recurrence_) {
      if (rvalue == "Cron") {
        time_value.disabled = true;
        week_value.disabled = true;
        recurrence_.value = "Cron_30 2 * * MON";
      } else if (rvalue == "Every week") {
        time_value.disabled = false;
        week_value.disabled = false;
        recurrence_.value = rvalue + " " + week_value.value + " " + createTimeFormat(time_value.value);
      } else if (rvalue == "Every day") {
        time_value.disabled = false;
        week_value.disabled = true;
        recurrence_.value = rvalue + " " + createTimeFormat(time_value.value);
      } else if (rvalue == "Once") {
        time_value.disabled = true;
        week_value.disabled = true;
        recurrence_.value = rvalue;
      } else {
        time_value.disabled = true;
        week_value.disabled = true;
        recurrence_.value = rvalue;
      }

    }
  }

  function addChangeWeekRecurrence(id, value) {
    var recurrence_duration = document.querySelector("#add_recurrence");
    var time_value = document.querySelector("#add_time");
    var recurrence_ = document.querySelector("#add_recurrence_");
    if (recurrence_ && recurrence_duration && time_value) {
      recurrence_.value = recurrence_duration.value + " " + value + " " + createTimeFormat(time_value.value);
    }
  }
  function addChangeTimeRecurrence(id, value) {
    var recurrence_duration = document.querySelector("#add_recurrence");
    var week_value = document.querySelector("#add_week");
    var recurrence_ = document.querySelector("#add_recurrence_");
    if (recurrence_ && week_value && recurrence_duration) {
      if (recurrence_duration.value == "Every week") {
        recurrence_.value = recurrence_duration.value + " " + week_value.value + " " + createTimeFormat(value);
      } else if (recurrence_duration.value == "Every day") {
        recurrence_.value = recurrence_duration.value + " " + createTimeFormat(value);
      }

    }
  }
  function updateRecurrence(id, rvalue) {
    //console.log(rvalue);
    var recurrence_ = document.querySelector("#update_recurrence_");
    // var week_sec= document.querySelector("#week_sec_"+id); 
    // var time_sec= document.querySelector("#time_sec_"+id); 
    var time_value = document.querySelector("#update_time");
    var week_value = document.querySelector("#update_week");
    //console.log(time_value,week_value);
    if (week_value && time_value && recurrence_) {
      if (rvalue == "Cron") {
        time_value.disabled = true;
        week_value.disabled = true;
        recurrence_.value = "Cron_30 2 * * MON";
      } else if (rvalue == "Every week") {
        time_value.disabled = false;
        week_value.disabled = false;
        recurrence_.value = rvalue + " " + week_value.value + " " + createTimeFormat(time_value.value);
      } else if (rvalue == "Every day") {
        time_value.disabled = false;
        week_value.disabled = true;
        recurrence_.value = rvalue + " " + createTimeFormat(time_value.value);
      } else if (rvalue == "Once") {
        time_value.disabled = true;
        week_value.disabled = true;
        recurrence_.value = rvalue;
      } else {
        time_value.disabled = true;
        week_value.disabled = true;
        recurrence_.value = rvalue;
      }

    }
  }

  function changeWeekRecurrence(id, value) {
    var recurrence_duration = document.querySelector("#update_recurrence2");
    var time_value = document.querySelector("#update_time");
    var recurrence_ = document.querySelector("#update_recurrence_");
    if (recurrence_ && recurrence_duration && time_value) {
      recurrence_.value = recurrence_duration.value + " " + value + " " + createTimeFormat(time_value.value);
    }
  }
  function changeTimeRecurrence(id, value) {
    var recurrence_duration = document.querySelector("#update_recurrence2");
    var week_value = document.querySelector("#update_week");
    var recurrence_ = document.querySelector("#update_recurrence_");
    if (recurrence_ && week_value && recurrence_duration) {
      if (recurrence_duration.value == "Every week") {
        recurrence_.value = recurrence_duration.value + " " + week_value.value + " " + createTimeFormat(value);
      } else if (recurrence_duration.value == "Every day") {
        recurrence_.value = recurrence_duration.value + " " + createTimeFormat(value);
      }

    }
  }

  function modifyDateFormat(inputDate) {
    const dateObj = new Date(inputDate);

    if (isNaN(dateObj.getTime())) {
      console.error("Invalid date format");
      return null;
    }

    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const hours = String(dateObj.getHours()).padStart(2, '0');
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    const seconds = String(dateObj.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const minTime = () => {
    if (currentDateTime.toDateString() === new Date().toDateString()) {
      // If the selected date is the current date, set the min time to the current time
      return new Date();
    }
    // Otherwise, set the min time to the start of the day
    return new Date(currentDateTime).setHours(0, 0, 0, 0);
  };

  const maxTime = () => {
    // Set the max time to the end of the day
    return new Date(currentDateTime).setHours(23, 59, 59, 999);
  };

  const toggleSchedule = () => {
    var add_shedules_sec = document.querySelector("#add_shedules_sec");
    if (add_shedules_sec) {
      add_shedules_sec.classList.toggle("d-none");
    }
  }
  const updateOpenDrawer = () => {
    var drawerElement = document.querySelector("#update-schedule-modal");
    var drawer_overlay = document.querySelector("#update_schedule_drawer_overlay");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
        drawerElement.style.width = "100%";
      } else {
        drawerElement.style.width = "40%";
      }
      drawer_overlay.classList.toggle("d-none");
    }
  };
  const openDrawer = () => {
    var drawerElement = document.querySelector("#schedule-modal");
    var drawer_overlay = document.querySelector("#schedule_drawer_overlay");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
        drawerElement.style.width = "100%";
      } else {
        drawerElement.style.width = "40%";
      }
      drawer_overlay.classList.toggle("d-none");
    }
  };

  const dayName = currentDateTime.toLocaleDateString('en-GB', { weekday: 'long' });

  const handleChangeData = (e) => {
    setCurrentDateTime(e)
  }
  console.log(scheduleArr);
  return (<>
    <div className="col-xl-12 card mb-5">
      <div className="card-header border-3 mb-5 pt-2">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder text-gray-800">Scaling Schedules</span>

          <span className="text-gray-400 mt-1 fw-bold fs-6">
            Configure your Auto scaling schedules here
          </span>
        </h3>
        <div className='card-toolbar'>
          <a
            onClick={openDrawer}
            className="btn btn-sm btn-primary"
            style={{ marginRight: 20 }}
          >
            {/*begin::Svg Icon | path: assets/media/icons/duotune/files/fil013.svg*/}
            <span className="svg-icon svg-icon-muted svg-icon-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  opacity="0.3"
                  d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z"
                  fill="black"
                />
                <path
                  d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.2C9.7 3 10.2 3.20001 10.4 3.60001ZM16 12H13V9C13 8.4 12.6 8 12 8C11.4 8 11 8.4 11 9V12H8C7.4 12 7 12.4 7 13C7 13.6 7.4 14 8 14H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
                  fill="black"
                />
                <path
                  opacity="0.3"
                  d="M11 14H8C7.4 14 7 13.6 7 13C7 12.4 7.4 12 8 12H11V14ZM16 12H13V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
                  fill="black"
                />
              </svg>
            </span>
            {/*end::Svg Icon*/}
            {/*end::Svg Icon*/}
            Add New
          </a>
        </div>
      </div>
      <div className="pb-5">
        <div
          id="kt_table_users_wrapper"
          className="dataTables_wrapper dt-bootstrap4 no-footer"
        >
          <div className="table-responsive">
            <table
              className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
              id="page_list"
            >
              <thead className="bg-light-dark">
                <tr className="text-start fw-bolder fs-7 text-uppercase gs-0">
                  <th className="w-150px text-start ps-9">Name </th>
                  <th className="w-100px text-center sphide">Desired size </th>
                  <th className="w-100px text-center">Time Zone</th>
                  <th className="w-200px text-center">Recurrence</th>
                  <th className="w-250px text-center sphide">Start At</th>
                  <th className="w-100px" />
                </tr>
              </thead>

              <tbody className="text-gray-600">
                {scheduleArr != null ? (<>
                  {scheduleArr.length > 0 ? (<>
                    {scheduleArr.map((value, index) => (<>
                      <tr className="openCustomer">
                        <td className="text-start ps-9">
                          <div className="flex-column w-100 me-2" >
                            {" "}
                            <div className=" flex-stack mb-2">
                              {" "}
                              <span className=" me-2 fs-6 fw-bold">{value.name}</span>{" "}
                            </div>{" "}
                          </div>{" "}
                        </td>
                        <td className="text-center">
                          <div className="flex-column w-100 me-2" >
                            {" "}
                            <div className=" flex-stack mb-2">
                              {" "}
                              <span className="badge badge-primary me-2 fs-6 fw-bold">{value.desiredsize}</span>{" "}
                            </div>{" "}
                          </div>{" "}
                        </td>
                        <td>
                          <div className="flex-column w-100 me-2" >
                            {" "}
                            <div className="text-center flex-stack mb-2">
                              {" "}
                              <span className=" me-2 fs-6 fw-bold">{value.timezone}</span>{" "}
                            </div>{" "}
                          </div>{" "}
                        </td>
                        <td className="text-center">

                          <div className="flex-column w-100 me-2" >
                            {" "}
                            <div className=" flex-stack mb-2">
                              {" "}
                              <span className=" me-2 fs-6 fw-bold">{value.recurrence}</span>{" "}
                            </div>{" "}
                          </div>{" "}
                        </td>
                        <td>
                          <div className="flex-column w-100 me-2" >
                            {" "}
                            <div className="text-center flex-stack mb-2">
                              {" "}
                              <span className=" me-2 fs-6 fw-bold">{value.start_date}</span>{" "}
                            </div>{" "}
                          </div>{" "}
                        </td>
                        <td className="text-center sphide">

                          <a
                            onClick={(e) => updateAutoScalingSchedules(value.id, value)}
                            id={"update-schedule-btn-" + value.id}
                            className="btn btn-icon btn-light-primary sphide"
                            style={{ marginRight: 20 }}
                          >
                            {/*begin::Svg Icon | path: assets/media/icons/duotune/files/fil017.svg*/}{" "}
                            <span className="svg-icon svg-icon-muted svg-icon-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  opacity="0.3"
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z"
                                  fill="black"
                                />
                                <path
                                  d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z"
                                  fill="black"
                                />
                                <path
                                  d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z"
                                  fill="black"
                                />
                              </svg>
                            </span>{" "}
                            {/*end::Svg Icon*/}
                          </a>{" "}
                          <a
                            onClick={(e) => deleteAutoScalingSchedules(value.id, index)}
                            id={"delete-schedule-btn-" + value.id}
                            className="btn btn-icon btn-light-danger"
                          >
                            {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen027.svg*/}{" "}
                            <span className="svg-icon svg-icon-muted svg-icon-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                {" "}
                                <path
                                  d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                  fill="black"
                                />{" "}
                                <path
                                  opacity="0.5"
                                  d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                  fill="black"
                                />{" "}
                                <path
                                  opacity="0.5"
                                  d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                  fill="black"
                                />{" "}
                              </svg>
                            </span>{" "}
                            {/*end::Svg Icon*/}
                          </a>
                        </td>
                      </tr>
                    </>))}
                  </>) : ('')}
                </>) : ('')}

              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>

    <div id="schedule_drawer_overlay" onClick={openDrawer} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>

    <div
      id="schedule-modal"
      className="bg-body shadow drawer drawer-end"
      data-kt-drawer-close="#close_ssh"
      // data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
      style={{ important: true, zIndex: 110 }}
    >
      {/*begin::Messenger*/}
      <div
        className="card rounded-0 w-100 border-0"
        id="kt_drawer_chat_messenger"
        style={{ height: 'max-content' }}
      >
        {/*begin::Card header*/}
        <div
          className="card-header pe-5 ps-7"
          id="kt_drawer_chat_messenger_header"
        >
          {/*begin::Title*/}
          <div className="card-title">
            {/*begin::User*/}
            <div className="d-flex justify-content-center flex-column me-3">
              <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                Add Schedule
              </a>
            </div>
            {/*end::User*/}
          </div>
          {/*end::Title*/}
          {/*begin::Card toolbar*/}
          <div className="card-toolbar">
            {/*begin::Close*/}
            <div
              onClick={openDrawer}
              className="btn btn-sm btn-icon btn-active-light-primary"
              id="close_ssh"
            >
              {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
              <span className="svg-icon svg-icon-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x={6}
                    y="17.3137"
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(-45 6 17.3137)"
                    fill="black"
                  />
                  <rect
                    x="7.41422"
                    y={6}
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(45 7.41422 6)"
                    fill="black"
                  />
                </svg>
              </span>
              {/*end::Svg Icon*/}
            </div>
            {/*end::Close*/}
          </div>
          {/*end::Card toolbar*/}
        </div>
        {/*end::Card header*/}
        {/*begin::Card body*/}
        <div className="" id="">
          {/*begin::Form*/}
          {/* {pageStatus ? (<> */}
          <form
            id="updateScaling"
            className="form"
          >
            {/*begin::Modal body*/}
            <div className="modal-body ">
              {/*begin::Scroll*/}
              <div
                className="scroll-y me-n7 pe-7 pt-5"
                id="kt_modal_create_api_key_scroll"
                data-kt-scroll="true"
                data-kt-scroll-activate="{default: false, lg: true}"
                data-kt-scroll-max-height="auto"
                data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                data-kt-scroll-offset="300px"
              >
                <div className="row">
                  <div className="col-8">
                    {/*begin::Input group*/}
                    <div className="mb-5 fv-row">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Name
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Enter Name"
                        id="add_name_schedule"
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    {/*begin::Input group*/}
                    <div className="mb-5 fv-row">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Time Zone
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <select className='form-select' id="add_ist" name="ist" defaultValue="IST">
                        <option value="IST">IST</option> addRecurrence
                      </select>
                      {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                  </div>
                  <div className="col-4">
                    {/*begin::Input group*/}
                    <div className="mb-5 fv-row">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Recurrence
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <select className='form-select' onChange={(e) => { addRecurrence("add", e.target.value) }} id="add_recurrence" defaultValue={"Once"}>
                        <option value="Cron">Cron</option>
                        <option value="Every 5 Min">Every 5 Min</option>
                        <option value="Every 30 Min">Every 30 Min</option>
                        <option value="Every 1 hour">Every 1 hour</option>
                        <option value="Every day">Every day</option>
                        <option value="Every week">Every week</option>
                        <option value="Once">Once</option>
                      </select>
                      <input type="hidden" defaultValue={"Once"} id={"add_recurrence_"} />
                      {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                  </div>

                  <div className="col-4">
                    {/*begin::Input group*/}
                    <div className="mb-5 fv-row">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Date
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <select className='form-select' disabled
                        onChange={(e) => {
                          const selectedDay = e.target.value;

                          // Map the days to their numeric representations
                          const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                          const currentDayIndex = currentDateTime.getDay(); // Get the current day as a number (0-6)
                          const selectedDayIndex = daysOfWeek.indexOf(selectedDay); // Get the index of the selected day

                          // Calculate the difference between the selected day and the current day
                          let dayDifference = (selectedDayIndex - currentDayIndex + 7) % 7;

                          // If the selected day is today but we want the next occurrence, set the difference to 7 days
                          if (dayDifference === 0) {
                            dayDifference = 7;
                          }

                          // Calculate the new date
                          const newDate = new Date(currentDateTime);
                          newDate.setDate(newDate.getDate() + dayDifference);
                          console.log(newDate);

                          setCurrentDateTime(newDate)
                          addChangeWeekRecurrence("add", e.target.value)
                        }
                        }
                        id="add_week"
                        value={dayName}>
                        {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
                          <option key={day} value={day}>
                            {day}
                          </option>
                        ))}
                      </select>

                      {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                  </div>

                  <div className="col-4">
                    {/*begin::Input group*/}
                    <div className="mb-5 fv-row">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Time
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <input
                        type="time"
                        id="add_time"
                        className="form-control"
                        disabled
                        value={currentDateTime
                          ? currentDateTime.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })
                          : ""}
                        onBlur={(e) => {
                          addChangeTimeRecurrence("add", e.target.value)
                        }}
                        onChange={(e) => {
                          const [hours, minutes] = e.target.value.split(':');
                          const updatedDateTime = new Date(currentDateTime);
                          updatedDateTime.setHours(hours);
                          updatedDateTime.setMinutes(minutes);
                          setCurrentDateTime(updatedDateTime);
                        }}
                      />

                      {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                  </div>

                  <div className="col-4">
                    {/*begin::Input group*/}
                    <div className="mb-5 fv-row">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Start_At
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <div className="">
                        <DatePicker
                          className="form-control"
                          selected={currentDateTime}
                          minDate={new Date()}
                          minTime={minTime()}
                          maxTime={maxTime()}
                          id="add_start_date"
                          showTimeSelect
                          timeFormat="HH:mm"
                          onChange={handleChangeData}
                          timeIntervals={15}
                          timeCaption="time"
                          dateFormat="yyyy/MM/dd, h:mm aa"
                          popperPlacement="top" // Set the tooltip placement to top
                        />
                      </div>
                      {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                  </div>

                  <div className="col-6">
                    {/*begin::Input group*/}
                    <div className="mb-5 fv-row">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Desired size
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <div className="d-flex align-items-center">
                        {/*begin::Dialer*/}
                        <div
                          className="input-group w-md-150px"
                          data-kt-dialer="true"
                        >
                          {/*begin::Decrease control*/}
                          <a
                            className="btn btn-icon btn-outline btn-outline-secondary"
                            style={{ height: 'auto' }}
                            onClick={(e) => updateAdjustmentValue("add_desiredsize", "down")}
                          >
                            <i className="bi bi-dash fs-1" />
                          </a>
                          {/*end::Decrease control*/}
                          {/*begin::Input control*/}
                          <input
                            type="text"
                            className="form-control text-center"
                            readOnly={true}
                            id="add_desiredsize"
                            defaultValue={'1'}
                          />
                          {/*end::Input control*/}
                          {/*begin::Increase control*/}
                          <a
                            className="btn btn-icon btn-outline btn-outline-secondary"
                            style={{ height: 'auto' }}
                            onClick={(e) => updateAdjustmentValue("add_desiredsize", "up")}
                          >
                            <i className="bi bi-plus fs-1" />
                          </a>
                          {/*end::Increase control*/}
                        </div>
                        {/*end::Dialer*/}
                      </div>

                      {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer ">
              {/*begin::Button*/}

              <a
                onClick={addAutoScalingSchedules}
                id={"add-schedule-btn"}
                className="btn btn-sm btn-success sphide"
                style={{ marginRight: 20 }}
              >
                Add Schedule
              </a>{" "}
              <a
                onClick={openDrawer}
                className="btn btn-sm btn-danger"
              >
                Cancel
              </a>

              {/*end::Button*/}
            </div>
          </form>
          {/* </>) : (<><TableLoader /></>)} */}
          {/*end::Form*/}
        </div>
        {/*end::Card body*/}
      </div>
      {/*end::Messenger*/}
    </div>

    <div id="update_schedule_drawer_overlay" onClick={updateOpenDrawer} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>

    <div
      id="update-schedule-modal"
      className="bg-body shadow drawer drawer-end"
      data-kt-drawer-close="#close_ssh"
      // data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
      style={{ important: true, zIndex: 110 }}
    >
      {/*begin::Messenger*/}
      <div
        className="card rounded-0 w-100 border-0"
        id="kt_drawer_chat_messenger"
        style={{ height: 'max-content' }}
      >
        {/*begin::Card header*/}
        <div
          className="card-header pe-5 ps-7"
          id="kt_drawer_chat_messenger_header"
        >
          {/*begin::Title*/}
          <div className="card-title">
            {/*begin::User*/}
            <div className="d-flex justify-content-center flex-column me-3">
              <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                Update Schedule
              </a>
            </div>
            {/*end::User*/}
          </div>
          {/*end::Title*/}
          {/*begin::Card toolbar*/}
          <div className="card-toolbar">
            {/*begin::Close*/}
            <div
              onClick={updateOpenDrawer}
              className="btn btn-sm btn-icon btn-active-light-primary"
              id="close_ssh"
            >
              {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
              <span className="svg-icon svg-icon-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x={6}
                    y="17.3137"
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(-45 6 17.3137)"
                    fill="black"
                  />
                  <rect
                    x="7.41422"
                    y={6}
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(45 7.41422 6)"
                    fill="black"
                  />
                </svg>
              </span>
              {/*end::Svg Icon*/}
            </div>
            {/*end::Close*/}
          </div>
          {/*end::Card toolbar*/}
        </div>
        {/*end::Card header*/}
        {/*begin::Card body*/}
        <div className="" id="">
          {/*begin::Form*/}
          {/* {pageStatus ? (<> */}
          <form
            id="updateScaling"
            className="form"
          >
            {/*begin::Modal body*/}
            <div className="modal-body ">
              {/*begin::Scroll*/}
              <div
                className="scroll-y me-n7 pe-7 pt-5"
                id="kt_modal_create_api_key_scroll"
                data-kt-scroll="true"
                data-kt-scroll-activate="{default: false, lg: true}"
                data-kt-scroll-max-height="auto"
                data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                data-kt-scroll-offset="300px"
              >
                <div className="row">
                  <div className="col-8">
                    {/*begin::Input group*/}
                    <div className="mb-5 fv-row">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Name
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Enter Name"
                        id="update_name2"
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    {/*begin::Input group*/}
                    <div className="mb-5 fv-row">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Time Zone
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <select className='form-select' id={"update_ist2"} name="ist" defaultValue="IST">
                        <option value="IST">IST</option> updateRecurrence
                      </select>
                      {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                  </div>
                  <div className="col-4">
                    {/*begin::Input group*/}
                    <div className="mb-5 fv-row">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Recurrence
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <select className='form-select' onChange={(e) => { updateRecurrence("", e.target.value) }} id={"update_recurrence2"} defaultValue={"Every week"}>
                        <option value="Cron">Cron</option>
                        <option value="Every 5 Min">Every 5 Min</option>
                        <option value="Every 30 Min">Every 30 Min</option>
                        <option value="Every 1 hour">Every 1 hour</option>
                        <option value="Every day">Every day</option>
                        <option value="Every week">Every week</option>
                        <option value="Once">Once</option>
                      </select>
                      <input type="hidden" defaultValue="" id={"update_recurrence_"} value={updateRecurrenceState} />

                      {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                  </div>

                  <div className="col-4">
                    {/*begin::Input group*/}
                    <div className="mb-5 fv-row">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Day
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <select className='form-select' onChange={(e) => {
                        const selectedDay = e.target.value;

                        // Map the days to their numeric representations
                        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                        const currentDayIndex = currentDateTime.getDay(); // Get the current day as a number (0-6)
                        const selectedDayIndex = daysOfWeek.indexOf(selectedDay); // Get the index of the selected day

                        // Calculate the difference between the selected day and the current day
                        let dayDifference = (selectedDayIndex - currentDayIndex + 7) % 7;

                        // If the selected day is today but we want the next occurrence, set the difference to 7 days
                        if (dayDifference === 0) {
                          dayDifference = 7;
                        }

                        // Calculate the new date
                        const newDate = new Date(currentDateTime);
                        newDate.setDate(newDate.getDate() + dayDifference);
                        console.log(newDate);

                        setCurrentDateTime(newDate)
                        changeWeekRecurrence("add", e.target.value)
                      }}
                        id={"update_week"} value={dayName}>
                        <option value="Monday">Monday</option>
                        <option value="Tuesday">Tuesday</option>
                        <option value="Wednesday">Wednesday</option>
                        <option value="Thrusday">Thrusday</option>
                        <option value="Friday">Friday</option>
                        <option value="Saturday">Saturday</option>
                        <option value="Sunday">Sunday</option>
                      </select>

                      {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                  </div>

                  <div className="col-4">
                    {/*begin::Input group*/}
                    <div className="mb-5 fv-row">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Time
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <input
                        type="time"
                        id={"update_time"}
                        className="form-control"
                        value={currentDateTime
                          ? currentDateTime.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })
                          : ""}
                        onBlur={(e) => {
                          changeTimeRecurrence("add", e.target.value)
                        }}
                        onChange={(e) => {
                          const [hours, minutes] = e.target.value.split(':');
                          const updatedDateTime = new Date(currentDateTime);
                          updatedDateTime.setHours(hours);
                          updatedDateTime.setMinutes(minutes);
                          setCurrentDateTime(updatedDateTime);
                        }}
                      />

                      {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                  </div>

                  <div className="col-6">
                    {/*begin::Input group*/}
                    <div className="mb-5 fv-row">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Start_At
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <DatePicker
                        className="form-control"
                        selected={currentDateTime}
                        minDate={new Date()}
                        minTime={minTime()}
                        maxTime={maxTime()}
                        id={"update_start_date2"}
                        showTimeSelect
                        timeFormat="HH:mm"
                        onChange={handleChangeData}
                        timeIntervals={15}
                        timeCaption="time"
                        dateFormat="yyyy/MM/dd, h:mm aa"
                        popperPlacement="top" // Set the tooltip placement to top
                      />
                      {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                  </div>

                  <div className="col-6">
                    {/*begin::Input group*/}
                    <div className="mb-5 fv-row">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Desired size
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <div className="d-flex align-items-center">
                        {/*begin::Dialer*/}
                        <div
                          className="input-group w-md-150px"
                          data-kt-dialer="true"
                        >
                          {/*begin::Decrease control*/}
                          <a
                            className="btn btn-icon btn-outline btn-outline-secondary"
                            style={{ height: 'auto' }}
                            onClick={(e) => updateAdjustmentValue("update_desiredsize2", "down")}
                          >
                            <i className="bi bi-dash fs-1" />
                          </a>
                          {/*end::Decrease control*/}
                          {/*begin::Input control*/}
                          <input
                            type="text"
                            className="form-control text-center"
                            readOnly={true}
                            id="update_desiredsize2"
                          />
                          {/*end::Input control*/}
                          {/*begin::Increase control*/}
                          <a
                            className="btn btn-icon btn-outline btn-outline-secondary"
                            style={{ height: 'auto' }}
                            onClick={(e) => updateAdjustmentValue("update_desiredsize2", "up")}
                          >
                            <i className="bi bi-plus fs-1" />
                          </a>
                          {/*end::Increase control*/}
                        </div>
                        {/*end::Dialer*/}
                      </div>

                      {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer ">
              {/*begin::Button*/}

              <a
                onClick={updateSchedules}
                id={"update-schedule-btn"}
                className="btn btn-sm btn-success sphide"
                style={{ marginRight: 20 }}
              >
                Update Schedule
              </a>{" "}
              <a
                onClick={updateOpenDrawer}
                className="btn btn-sm btn-danger"
              >
                Cancel
              </a>

              {/*end::Button*/}
            </div>
          </form>
          {/* </>) : (<><TableLoader /></>)} */}
          {/*end::Form*/}
        </div>
        {/*end::Card body*/}
      </div>
      {/*end::Messenger*/}
    </div>
  </>)
}