import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import Swal from 'sweetalert2';
import copy from "copy-to-clipboard";
import ReactPaginate from "react-paginate";
import UseDevApi from "../../../../../../CustomHooks/useDevApi";
import UsePostData from "../../../../../../CustomHooks/usePostData";
import UseApi from "../../../../../../CustomHooks/useApi";
import DeployReplica from "./DeployReplica";
import { Tooltip } from "@material-ui/core";
const ReplicaNodes = (props) => {
  var query = window.location.href;
  var vars = query.split("/");
  var clusterid = vars[4];

  const [pageStatus, setPageStatus] = useState(true)
  //   const [innerHTML,setInnerHtml] = useState(`  <span className="svg-icon svg-icon-2">
  //   <svg
  //     xmlns="http://www.w3.org/2000/svg"
  //     width={24}
  //     height={24}
  //     viewBox="0 0 24 24"
  //     fill="none"
  //   >
  //     <path
  //       d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
  //       fill="black"
  //     />
  //     <path
  //       opacity="0.5"
  //       d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
  //       fill="black"
  //     />
  //     <path
  //       opacity="0.5"
  //       d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
  //       fill="black"
  //     />
  //   </svg>
  // </span>`)

  const copyToClipboard = (id) => {
    var ele = document.querySelector("#ip_address_sec" + id);
    var eleText = document.querySelector("#ip_address" + id);
    if (ele && !ele.classList.contains('disabled')) {
      var copyEle = ele.innerHTML;
      var copyText = eleText.innerHTML;
      ele.classList.add('disabled')
      ele.style.pointerEvents = "none";
      navigator.clipboard.writeText(copyText).then(() => {
        ele.innerHTML = `<svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M9.89557 13.4982L7.79487 11.2651C7.26967 10.7068 6.38251 10.7068 5.85731 11.2651C5.37559 11.7772 5.37559 12.5757 5.85731 13.0878L9.74989 17.2257C10.1448 17.6455 10.8118 17.6455 11.2066 17.2257L18.1427 9.85252C18.6244 9.34044 18.6244 8.54191 18.1427 8.02984C17.6175 7.47154 16.7303 7.47154 16.2051 8.02984L11.061 13.4982C10.7451 13.834 10.2115 13.834 9.89557 13.4982Z"
          fill="black"
        />
      </svg>`;;

        setTimeout(() => {
          ele.innerHTML = copyEle;
          ele.style.pointerEvents = "auto";
          ele.classList.remove('disabled')
        }, 1000);
      }).catch(err => {
        console.error('Failed to copy text: ', err);
      });
    }
  }
  // const copyToClipboard = (id) => {
  //   const ele = document.getElementById("ip_address_sec" + id);
  //   const eleText = document.getElementById("ip_address" + id);

  //   if (ele && eleText) {
  //     const copyText = eleText.innerText;

  //     // Create an off-screen textarea element to copy the text
  //     const tempTextArea = document.createElement("textarea");
  //     tempTextArea.value = copyText;
  //     document.body.appendChild(tempTextArea);
  //     tempTextArea.select();

  //     try {
  //       // Attempt to copy the text to the clipboard
  //       document.execCommand("copy");
  //     } catch (err) {
  //       console.error("Unable to copy:", err);
  //     }

  //     // Remove the temporary textarea from the DOM
  //     document.body.removeChild(tempTextArea);

  //     // Update the element temporarily to indicate it's copied
  //     const originalContent = ele.innerHTML;
  //     ele.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M9.89557 13.4982L7.79487 11.2651C7.26967 10.7068 6.38251 10.7068 5.85731 11.2651C5.37559 11.7772 5.37559 12.5757 5.85731 13.0878L9.74989 17.2257C10.1448 17.6455 10.8118 17.6455 11.2066 17.2257L18.1427 9.85252C18.6244 9.34044 18.6244 8.54191 18.1427 8.02984C17.6175 7.47154 16.7303 7.47154 16.2051 8.02984L11.061 13.4982C10.7451 13.834 10.2115 13.834 9.89557 13.4982Z" fill="black"/></svg>`;

  //     // Revert the element content when the text is copied
  //     const handleCopy = () => {
  //       ele.innerHTML = originalContent;
  //       document.removeEventListener("copy", handleCopy);
  //     };

  //     document.addEventListener("copy", handleCopy);

  //     // Automatically remove the copy event listener after 2 seconds
  //     setTimeout(() => {
  //       document.removeEventListener("copy", handleCopy);
  //     }, 2000);
  //   }
  // }


  const navigate = useNavigate();
  const switchNode = (type) => {
    navigate("/" + type);
  }
  // const navigate = useNavigate();
  const navRedirect = () => {
    setTimeout(() => {
      navigate('/database-cluster');
    }, 2000);
  }

  const [perPage, setPerPage] = useState(5);
  const [replicaNodeData, setReplicaNodeData] = useState(null);
  const [databaseInfo, setDatabaseInfo] = useState(null);
  const [currentDatabaseInfo, setCurrentDatabaseInfo] = useState(null);
  const getReplica = async () => {
    if (props) {
      if (props.data && props.currentDatabaseInfo) {
        setDatabaseInfo(props.data);
        setCurrentDatabaseInfo(props.currentDatabaseInfo);
      }
    }
  }
  useEffect(() => {
    getReplica();
  }, [props])

  const copyVPCName = () => {
    var eleText = document.querySelector("#replica_node");
    var ele = document.querySelector("#repilca_node_copy_btn");
    // var copiedEle = document.querySelector('#file_url_copied_btn')
   
    if (ele && eleText) {
      const copyText = eleText.innerText;
      const originalContent = ele.innerHTML;

      navigator.clipboard.writeText(copyText).then(() => {
        ele.innerHTML = "Copied";

        setTimeout(() => {
          ele.innerHTML = originalContent;
        }, 1000);
      }).catch(err => {
        console.error('Failed to copy text: ', err);
      });
    }
};

const handleClick = (event) => {
  if (event.target && event.target.id === 'repilca_node_copy_btn') {
    copyVPCName();
    event.stopPropagation(); // Prevent the event from bubbling up
  }
};

document.removeEventListener('click', handleClick);
document.addEventListener('click', handleClick);

  const deleteNode = async (cloudid) => {
    var clustername = '';
    if (databaseInfo != null) {
      clustername = databaseInfo.cluster_name;
    }
    toast.dismiss();
    const { value: isDelete } = await Swal.fire({
      title: 'Delete Node',
      html: `
          <div className="d-flex align-items-center flex-wrap"> 
          <div id="kt_clipboard_4" className="me-5 fw-bold text-gray-600">
            <p class="fs-5">Enter the Cluster Name to delete node:</p>
            <span class="fw-bold" id="replica_node" >${clustername} &nbsp;</span><span id="repilca_node_copy_btn" style="cursor:pointer; width: 70px; display: inline-block;" class="text-primary"> ( copy ) </span> 
          </div>  
        </div>
       `,
      input: 'text',
      inputPlaceholder: 'Cluster name',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'delete-button bg-danger', // Add your custom class here
      },
    });
    if (isDelete?.trim() == clustername) {

      setPageStatus(true)
      var dt = toast.loading("Node Deleting...");
      const dnsp = await UseApi('DELETE', 'databases/' + clusterid + '/replica/' + cloudid + '?confirm=' + clustername, '');
      if (dnsp.status == 'error') {
        setPageStatus(false)
        toast.update(dt, {
          render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
      } else {
        setPageStatus(false)
        toast.update(dt, {
          render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        if (props && props.updateDatabaseInfo) {
          props.updateDatabaseInfo();
        }
        // navigate("/database-cluster");
      }
    } else {
      swal({
        title: "Invalid IP Address",
        text: "Please confirm the valid Replica IP Address name or check the extra white space in the given IP!",
        icon: "warning",
        button: "OK",
      });
    }

  }

  const addReplica = async () => {
    toast.dismiss();
    swal({
      title: "Do you really want to add additional replica node",
      text: "Once added, A new replica node will be attached with your databse cluster!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          setPageStatus(true)
          const dt = toast.loading("Replica Node adding...");
          if (databaseInfo != null) {
            const dnsp = await UsePostData('POST', 'databases/' + clusterid + '/replica', {});
            //console.log(dnsp);
            if (dnsp.status == 'error') {
              setPageStatus(false)
              toast.update(dt, {
                render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
              });
            } else {
              setPageStatus(false)
              toast.update(dt, {
                render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
              });
              // if (props && props.updateDatabaseInfo) {
              //   props.updateDatabaseInfo();
              // }
              // getReplica();
              navRedirect()
              // switchNode("database-cluster/switch/" + dnsp?.id + "/" + dnsp?.cloudid, dnsp?.id, dnsp?.cloudid);
            }
          }
        }
      });

  }

  const isActionCompleted = async (cloudid) => {
    const actionData = await UseApi("get", `cloud/${cloudid}/actions`, "");
    return actionData && actionData.actions[0] && actionData.actions[0].status === "Completed";
  };


  const updateCloudButton = (cloudid, isLoading) => {
    const cloud_btn = document.querySelector(`#cloud-btn-${cloudid}`);
    const delete_icon = document.querySelector(".delete-icon-id");
    if (cloud_btn) {
      // delete_icon.classList.remove('d-none')
      cloud_btn.innerHTML = isLoading
        ? '<span class="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>'
        : delete_icon.innerHTML;
    }
  };

  const checkAndRefreshActions = (nodes, type) => {
    let allActionsCompleted = true;
    nodes.forEach((value) => {
      if (value.cloudid) {
        isActionCompleted(value.cloudid)
          .then(result => {
            if (result) {
              updateCloudButton(value.cloudid, false);
              allActionsCompleted = false;
            } else {
              updateCloudButton(value.cloudid, true);
            }
          })
          .catch(error => {
            console.error(error);
          });
      }
    });
    return allActionsCompleted;
  };

  const getActiveAction = () => {
    if (props.data && props.data?.nodes) {
      const allPrimaryActionCompleted = checkAndRefreshActions(props.data.nodes.primary, "primary");
      const allReplicaActionCompleted = checkAndRefreshActions(props.data.nodes.readonly, "readonly");
      const allReadonlyActionCompleted = checkAndRefreshActions(props.data.nodes.replica, "replica");

      if (!allPrimaryActionCompleted || !allReplicaActionCompleted || !allReadonlyActionCompleted) {
        setTimeout(() => {
          getActiveAction();
        }, 5000);
      }
    } else {
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getActiveAction();
    }, 1000)
  }, [])

  return (
    <>
      <div className="card">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold fs-3 mb-1">
                Nodes
              </span>
              <span className="text-muted mt-1 fw-bold fs-6">View your nodes here</span>
            </h3>
          </div>
          <div className="card-toolbar">
            {props.isActive ? <>
              {databaseInfo?.engine == "pg" ? (<>
                {currentDatabaseInfo?.ref_type != "dbaas_node" ? (<>
                  {databaseInfo?.nodes?.replica?.length >= 3 ?
                    <Tooltip title="You have reached to maximum replica nodes" arrow>
                      <span>
                        <a onClick={addReplica} className="me-3 btn btn-sm btn-primary btn-active-light-primary disabled">
                          {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
                          <span className="svg-icon svg-icon-3">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <rect
                                opacity="0.5"
                                x="11.364"
                                y="20.364"
                                width={16}
                                height={2}
                                rx={1}
                                transform="rotate(-90 11.364 20.364)"
                                fill="black"
                              />
                              <rect
                                x="4.36396"
                                y="11.364"
                                width={16}
                                height={2}
                                rx={1}
                                fill="black"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}Add Replica Node
                        </a>
                      </span>
                    </Tooltip>
                    :
                    <a onClick={addReplica} className="me-3 btn btn-sm btn-primary btn-active-light-primary">
                      {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
                      <span className="svg-icon svg-icon-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <rect
                            opacity="0.5"
                            x="11.364"
                            y="20.364"
                            width={16}
                            height={2}
                            rx={1}
                            transform="rotate(-90 11.364 20.364)"
                            fill="black"
                          />
                          <rect
                            x="4.36396"
                            y="11.364"
                            width={16}
                            height={2}
                            rx={1}
                            fill="black"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}Add Replica Node
                    </a>
                  }
                  <DeployReplica data={databaseInfo} />
                </>) : ('')}
              </>) : ('')}
            </>
              :
              ""
            }
          </div>
        </div>
        <div className="card-body p-0">

          <div className="table-responsive">
            <table
              className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
              id="kt_security_license_usage_table"
            >
              <thead className="border-gray-200 fs-5 fw-bold bg-secondary">
                <tr className="fw-bold ">
                  <th className="text-center w-100px"></th>
                  <th className="text-center w-100px"></th>
                  {databaseInfo?.engine == "kafka" &&
                    <th className="text-center w-100px">Broker</th>
                  }
                  <th className="text-center w-100px">  IPv4 </th>
                  <th className="text-center w-100px pe-10"> Configuration </th>
                  <th className="text-center w-100px">Status</th>
                  <th className=" text-center w-100px"></th>
                </tr>
              </thead>
              <tbody id="public-IP-list" className="fw-6 fw-bold text-gray-600">
                {databaseInfo != null ? (<>
                  {databaseInfo.nodes && databaseInfo.nodes.primary ? (<>
                    <tr>
                      <td colspan={2} className="text-start ps-9" style={{ paddingLeft: "20px" }}>
                        <div className="d-flex flex-stack">
                          {/*begin::Symbol*/}
                          <div className="symbol symbol-40px me-4">
                            <div className="symbol-label fs-2 fw-bold bg-light-primary text-inverse-danger">
                              <img src={"/assets/media/databases/" + databaseInfo.engine + ".svg"} />
                            </div>
                          </div>
                          {/*end::Symbol*/}
                          {/*begin::Section*/}
                          <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                            {/*begin:Author*/}
                            {/* <Link to={"/database-cluster/" + databaseInfo?.id + "/" + databaseInfo?.nodes?.primary[0]?.cloudid}> */}

                            <div style={{ cursor: "pointer" }} onClick={(e) => switchNode("database-cluster/switch/" + databaseInfo?.id + "/" + databaseInfo?.nodes?.primary[0]?.cloudid, databaseInfo?.id, databaseInfo?.nodes?.primary[0]?.cloudid)} className="flex-grow-1 me-2">
                              <a
                                className="text-gray-800 text-hover-primary fs-6 fw-bolder"
                              >
                                {databaseInfo.cluster_name}
                              </a>
                              <span className="text-muted fw-bold text-muted d-block fs-7">
                                {databaseInfo.engine == "pg" ? ("Postgre") : (databaseInfo.engine).charAt(0).toUpperCase() + databaseInfo.engine.slice(1)}({databaseInfo.version})

                              </span>
                            </div>
                            {/* </Link> */}
                            {/*end:Author*/}
                          </div>
                          {/*end::Section*/}
                        </div>
                      </td>
                      {databaseInfo?.engine == "kafka" &&
                        <td className="text-center">
                          <span className="">
                            {databaseInfo?.nodes?.readonly?.length + databaseInfo?.nodes?.replica?.length}
                          </span>
                        </td>
                      }
                      <td className="text-center">
                        <div className="d-flex flex-stack">
                          {databaseInfo?.nodes?.primary[0]?.network.map((value3, index3) => (<>
                            {value3.networktype === "public" ? (<>
                              <span className={`w-100 btn btn-active-${value3.networktype === "public" ? "primary" : "info"} btn-light-${value3.networktype === "public" ? "primary" : "info"} btn-sm sphide me-2 fs-8 fw-bold`}

                                onClick={() => copyToClipboard("_master" + index3 + databaseInfo?.nodes?.primary[0]?.uuid)}>
                                <span>{value3?.networktype?.charAt(0)?.toUpperCase() + value3?.networktype?.slice(1)}&nbsp;IP : </span>
                                <span id={"ip_address_master" + index3 + databaseInfo?.nodes?.primary[0]?.uuid}>
                                  {value3?.ip}
                                </span>&nbsp;
                                <span id={"ip_address_sec_master" + index3 + databaseInfo?.nodes?.primary[0]?.uuid} className="svg-icon svg-icon-light svg-icon-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      opacity="0.5"
                                      d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                                      fill="black"
                                    />
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                                      fill="black"
                                    />
                                  </svg>
                                </span>
                              </span>
                            </>) : ('')}
                          </>))}
                          {databaseInfo?.engine != "kafka" &&
                            <>
                              {databaseInfo?.nodes?.primary[0]?.network.map((value3, index3) => (<>
                                {value3.networktype !== "public" ? (<>
                                  <span className={`w-100 btn btn-active-${value3.networktype === "public" ? "primary" : "info"} btn-light-${value3.networktype === "public" ? "primary" : "info"} btn-sm sphide me-2 fs-8 fw-bold`}

                                    onClick={() => copyToClipboard("_master" + index3 + databaseInfo?.nodes?.primary[0]?.uuid)}>
                                    <span>{value3?.networktype?.charAt(0)?.toUpperCase() + value3?.networktype?.slice(1)}&nbsp;IP : </span>
                                    <span id={"ip_address_master" + index3 + databaseInfo?.nodes?.primary[0]?.uuid}>
                                      {value3?.ip}
                                    </span>&nbsp;
                                    <span id={"ip_address_sec_master" + index3 + databaseInfo?.nodes?.primary[0]?.uuid} className="svg-icon svg-icon-light svg-icon-2">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                      >
                                        <path
                                          opacity="0.5"
                                          d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                                          fill="black"
                                        />
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                                          fill="black"
                                        />
                                      </svg>
                                    </span>
                                  </span>
                                </>) : ('')}
                              </>))}
                            </>
                          }
                        </div>
                      </td>
                      <td className="text-center">
                        <div className="text-center">
                          {databaseInfo?.replica_node_count > 0 ? (<>
                            <span className="me-10">Primary +{parseInt(databaseInfo?.replica_node_count)}</span>
                          </>) : (<>
                            <span className="me-10">Primary</span>
                          </>)}
                          <span
                            className="badge badge-success d-none"
                            style={{ cursor: "pointer" }}
                          >
                            <a id="checknsundefined-btn">HA </a>
                          </span>
                        </div>
                      </td>
                      <td className="text-center">
                        <div className=" flex-column w-100">
                          <div className=" flex-stack mt-0.5">
                            {databaseInfo?.nodes?.primary[0]?.status == "Active" ? (<>
                              <span className=" me-2 fs-6 fw-bold">
                                <span className="status-circle" style={{ top: '4px' }}></span>
                                Active
                              </span>  </>) : (<>
                                <span className=" me-2 fs-6 fw-bold"><span className="status-circle-warning" style={{ top: '4px' }}></span>
                                  Pending
                                </span>
                              </>)}
                          </div>
                        </div>
                      </td>
                      <td className="text-center">

                      </td>
                    </tr>
                  </>) : ('')}
                  {databaseInfo?.engine != "kafka" &&
                    <>
                      {databaseInfo?.nodes && databaseInfo?.nodes?.replica ? (<>
                        {databaseInfo?.nodes?.replica.map((value2, index2) => (<>
                          <tr>

                            <td colspan={2} className="text-start ps-9">

                              <div className="d-flex  align-items-start ps-10">
                                {" "}
                                <span className="svg-icon svg-icon-muted svg-icon-2hx me-3">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M9 18H13.4V16H9C7.9 16 7 15.1 7 14V3C7 2.4 6.6 2 6 2C5.4 2 5 2.4 5 3V14C5 16.2 6.8 18 9 18Z"
                                      fill="black"
                                    />
                                    <path
                                      opacity="0.3"
                                      d="M13.4 12L17.7 16.3C18.1 16.7 18.1 17.3 17.7 17.7L13.4 22V12Z"
                                      fill="black"
                                    />
                                  </svg>
                                </span>
                                <div className="symbol symbol-40px me-4">
                                  <div className="symbol-label fs-2 fw-bold bg-light-primary text-inverse-danger">
                                    <svg
                                      width={24}
                                      height={24}
                                      viewBox="0 0 84 84"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g
                                        id="DBaaS-Icon-1C-BLK"
                                        transform="translate(0.189181, -0.000000)"
                                        fill="#494b74"
                                        fillRule="nonzero"
                                      >
                                        <path d="m66.7 48.9c-7.7 2.1-17.7 3.3-28.4 3.3s-20.8-1.2-28.4-3.3c-2.2-.6-4.2-1.3-5.9-2v22c0 5.2 15.3 9.4 34.3 9.4s34.3-4.2 34.3-9.4v-22c-1.8.8-3.7 1.4-5.9 2zm-6.3 19.8c-2.6 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6 4.6 2.1 4.6 4.6-2 4.6-4.6 4.6z" />
                                        <path d="m38.3 5.1c-19 0-34.3 4.2-34.3 9.4v25.4c3.6 2.4 14.8 6.1 34.3 6.1 19.4 0 30.7-3.7 34.3-6.1v-25.4c-.1-5.2-15.4-9.4-34.3-9.4zm22.1 31.7c-2.6 0-4.6-2.1-4.6-4.6 0-2.6 2.1-4.6 4.6-4.6s4.6 2.1 4.6 4.6c.1 2.5-2 4.6-4.6 4.6zm-22.1-14.3c-20.9 0-32.3-4.9-32.3-7.4s11.4-7.4 32.3-7.4 32.3 4.9 32.3 7.4-11.5 7.4-32.3 7.4z" />
                                      </g>
                                    </svg>
                                  </div>
                                </div>

                                <div style={{ cursor: "pointer" }} onClick={(e) => switchNode("database-cluster/switch/" + databaseInfo?.id + "/" + value2.cloudid, databaseInfo?.id, value2.cloudid)} className="d-flex justify-content-start flex-column">
                                  {" "}
                                  <a className="text-dark fw-bold text-hover-primary fs-6 sphide">
                                    {value2.hostname}
                                  </a>{" "}
                                  <span className="text-muted fw-bold text-muted d-block fs-7">
                                    <span className="w-10">
                                      <img src={"/img/flag_" + value2.dclocation.dccc + ".png"} style={{ width: "7%" }} />{" "}
                                    </span> {value2.dclocation.country}&nbsp;{value2.dclocation.location}
                                  </span>
                                </div>{" "}
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="d-flex flex-stack">
                                {value2.network.map((value3, index3) => (<>
                                  {value3.networktype === "public" ? (<>
                                    <span className={`w-100 btn btn-active-${value3.networktype === "public" ? "primary" : "info"} btn-light-${value3.networktype === "public" ? "primary" : "info"} btn-sm sphide me-2 fs-8 fw-bold`}

                                      onClick={() => copyToClipboard("_replica" + index2 + value3?.networktype)}>
                                      <span>{value3?.networktype?.charAt(0)?.toUpperCase() + value3?.networktype?.slice(1)}&nbsp;IP : </span>
                                      <span id={"ip_address_replica" + index2 + value3?.networktype}>
                                        {value3?.ip}
                                      </span>&nbsp;
                                      <span id={"ip_address_sec_replica" + index2 + value3?.networktype} className="svg-icon svg-icon-light svg-icon-2">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <path
                                            opacity="0.5"
                                            d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                                            fill="black"
                                          />
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                                            fill="black"
                                          />
                                        </svg>
                                      </span>
                                    </span>
                                  </>) : ('')}
                                </>))}
                                {value2.network.map((value3, index3) => (<>
                                  {value3.networktype !== "public" ? (<>
                                    <span className={`w-100 btn btn-active-${value3.networktype === "public" ? "primary" : "info"} btn-light-${value3.networktype === "public" ? "primary" : "info"} btn-sm sphide me-2 fs-8 fw-bold`}

                                      onClick={() => copyToClipboard("_replica" + index2 + value3?.networktype)}>
                                      <span>{value3?.networktype?.charAt(0)?.toUpperCase() + value3?.networktype?.slice(1)}&nbsp;IP : </span>
                                      <span id={"ip_address_replica" + index2 + value3?.networktype}>
                                        {value3?.ip}
                                      </span>&nbsp;
                                      <span id={"ip_address_sec_replica" + index2 + value3?.networktype} className="svg-icon svg-icon-light svg-icon-2">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <path
                                            opacity="0.5"
                                            d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                                            fill="black"
                                          />
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                                            fill="black"
                                          />
                                        </svg>
                                      </span>
                                    </span>
                                  </>) : ('')}
                                </>))}
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="text-center">
                                <span className="me-10 sphide">Replica</span>
                              </div>
                            </td>
                            <td className="text-center">
                              <div className=" flex-column w-100">
                                <div className=" flex-stack mt-0.5">
                                  {value2.status == "Active" ? (<>
                                    <span className=" me-2 fs-6 fw-bold">
                                      <span className="status-circle" style={{ top: '4px' }}></span>
                                      Active
                                    </span>  </>) : (<>
                                      <span className=" me-2 fs-6 fw-bold"><span className="status-circle-warning" style={{ top: '4px' }}></span>
                                        Pending
                                      </span>
                                    </>)}
                                </div>
                              </div>
                            </td>
                            <td className="text-center">
                              {currentDatabaseInfo?.ref_type != "dbaas_node" ? (<>

                                <div className="card-toolbar">
                                  <button
                                    type="button"
                                    onClick={(e) => deleteNode(value2.cloudid)}
                                    id={"cloud-btn-" + value2.cloudid}
                                    className="btn  btn-icon btn-color-danger btn-active-light-danger"
                                  >
                                    <span className="svg-icon svg-icon-2">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                      >
                                        <path
                                          d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                          fill="black"
                                        />
                                        <path
                                          opacity="0.5"
                                          d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                          fill="black"
                                        />
                                        <path
                                          opacity="0.5"
                                          d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                          fill="black"
                                        />
                                      </svg>
                                    </span>
                                  </button>

                                </div>
                              </>) : ('')}
                            </td>
                          </tr>
                        </>))}
                      </>) : ('')}
                      {databaseInfo.nodes && databaseInfo.nodes.readonly ? (<>
                        {databaseInfo.nodes.readonly.map((value2, index2) => (<>
                          <tr class={databaseInfo.nodes.readonly.length == index2 + 1 ? ('tableTrBottomBorder') : ('')}>

                            <td colspan={2} className="text-start ps-9">

                              <div className="d-flex  align-items-start ps-10">
                                {" "}
                                <span className="svg-icon svg-icon-muted svg-icon-2hx me-3">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M9 18H13.4V16H9C7.9 16 7 15.1 7 14V3C7 2.4 6.6 2 6 2C5.4 2 5 2.4 5 3V14C5 16.2 6.8 18 9 18Z"
                                      fill="black"
                                    />
                                    <path
                                      opacity="0.3"
                                      d="M13.4 12L17.7 16.3C18.1 16.7 18.1 17.3 17.7 17.7L13.4 22V12Z"
                                      fill="black"
                                    />
                                  </svg>
                                </span>
                                <div className="symbol symbol-40px me-4">
                                  <div className="symbol-label fs-2 fw-bold bg-light-primary text-inverse-danger">
                                    <svg
                                      width={24}
                                      height={24}
                                      viewBox="0 0 84 84"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g
                                        id="DBaaS-Icon-1C-BLK"
                                        transform="translate(0.189181, -0.000000)"
                                        fill="#494b74"
                                        fillRule="nonzero"
                                      >
                                        <path d="m66.7 48.9c-7.7 2.1-17.7 3.3-28.4 3.3s-20.8-1.2-28.4-3.3c-2.2-.6-4.2-1.3-5.9-2v22c0 5.2 15.3 9.4 34.3 9.4s34.3-4.2 34.3-9.4v-22c-1.8.8-3.7 1.4-5.9 2zm-6.3 19.8c-2.6 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6 4.6 2.1 4.6 4.6-2 4.6-4.6 4.6z" />
                                        <path d="m38.3 5.1c-19 0-34.3 4.2-34.3 9.4v25.4c3.6 2.4 14.8 6.1 34.3 6.1 19.4 0 30.7-3.7 34.3-6.1v-25.4c-.1-5.2-15.4-9.4-34.3-9.4zm22.1 31.7c-2.6 0-4.6-2.1-4.6-4.6 0-2.6 2.1-4.6 4.6-4.6s4.6 2.1 4.6 4.6c.1 2.5-2 4.6-4.6 4.6zm-22.1-14.3c-20.9 0-32.3-4.9-32.3-7.4s11.4-7.4 32.3-7.4 32.3 4.9 32.3 7.4-11.5 7.4-32.3 7.4z" />
                                      </g>
                                    </svg>
                                  </div>
                                </div>
                                <div style={{ cursor: "pointer" }} onClick={(e) => switchNode("database-cluster/switch/" + databaseInfo?.id + "/" + value2.cloudid, databaseInfo?.id, value2.cloudid)} className="d-flex justify-content-start flex-column">
                                  {" "}

                                  <a className="text-dark fw-bold text-hover-primary fs-6 sphide">
                                    {value2.hostname}
                                  </a>{" "}
                                  <span className="text-muted fw-bold text-muted d-block fs-7">
                                    <span className="w-10">
                                      <img src={"/img/flag_" + value2.dclocation.dccc + ".png"} style={{ width: "7%" }} />{" "}
                                    </span> {value2.dclocation.country}&nbsp;{value2.dclocation.location}
                                  </span>
                                </div>{" "}
                              </div>
                            </td>

                            <td className="text-center">
                              <div className="d-flex flex-stack">
                                {value2.network.map((value3, index3) => (<>
                                  {value3.networktype === "public" ? (<>
                                    <span className={`w-100 btn btn-active-${value3.networktype === "public" ? "primary" : "info"} btn-light-${value3.networktype === "public" ? "primary" : "info"} btn-sm sphide me-2 fs-8 fw-bold`}

                                      onClick={() => copyToClipboard("_readonly" + index2 + value3?.networktype)}>
                                      <span>{value3?.networktype?.charAt(0)?.toUpperCase() + value3?.networktype?.slice(1)}&nbsp;IP : </span>
                                      <span id={"ip_address_readonly" + index2 + value3?.networktype}>
                                        {value3?.ip}
                                      </span>&nbsp;
                                      <span id={"ip_address_sec_readonly" + index2 + value3?.networktype} className="svg-icon svg-icon-light svg-icon-2">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <path
                                            opacity="0.5"
                                            d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                                            fill="black"
                                          />
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                                            fill="black"
                                          />
                                        </svg>
                                      </span>
                                    </span>
                                  </>) : ('')}
                                </>))}


                                {value2.network.map((value3, index3) => (<>
                                  {value3.networktype !== "public" ? (<>
                                    <span className={`w-100 btn btn-active-${value3.networktype === "public" ? "primary" : "info"} btn-light-${value3.networktype === "public" ? "primary" : "info"} btn-sm sphide me-2 fs-8 fw-bold`}

                                      onClick={() => copyToClipboard("_readonly" + index2 + value3?.networktype)}>
                                      <span>{value3?.networktype?.charAt(0)?.toUpperCase() + value3?.networktype?.slice(1)}&nbsp;IP : </span>
                                      <span id={"ip_address_readonly" + index2 + value3?.networktype}>
                                        {value3?.ip}
                                      </span>&nbsp;
                                      <span id={"ip_address_sec_readonly" + index2 + value3?.networktype} className="svg-icon svg-icon-light svg-icon-2">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <path
                                            opacity="0.5"
                                            d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                                            fill="black"
                                          />
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                                            fill="black"
                                          />
                                        </svg>
                                      </span>
                                    </span>
                                  </>) : ('')}
                                </>))}
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="text-center">
                                <span className="me-10">Read Only</span>
                              </div>
                            </td>
                            <td className="text-center">
                              <div className=" flex-column w-100">
                                <div className=" flex-stack mt-0.5">
                                  {value2.status == "Active" ? (<>
                                    <span className=" me-2 fs-6 fw-bold">
                                      <span className="status-circle" style={{ top: '4px' }}></span>
                                      Active
                                    </span>  </>) : (<>
                                      <span className=" me-2 fs-6 fw-bold"><span className="status-circle-warning" style={{ top: '4px' }}></span>
                                        Pending
                                      </span>
                                    </>)}
                                </div>
                              </div>
                            </td>

                            <td className="text-center">

                              {currentDatabaseInfo?.ref_type != "dbaas_node" ? (<>
                                <div className="card-toolbar">
                                  <button
                                    type="button"
                                    id={"cloud-btn-" + value2.cloudid}
                                    onClick={(e) => deleteNode(value2.cloudid)}
                                    className="btn  btn-icon btn-color-danger btn-active-light-danger"
                                  >
                                    <span className="svg-icon svg-icon-2">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                      >
                                        <path
                                          d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                          fill="black"
                                        />
                                        <path
                                          opacity="0.5"
                                          d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                          fill="black"
                                        />
                                        <path
                                          opacity="0.5"
                                          d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                          fill="black"
                                        />
                                      </svg>
                                    </span>
                                  </button>

                                </div>
                              </>) : ('')}
                            </td>
                          </tr>
                        </>))}
                      </>) : ('')}
                    </>
                  }
                </>) : ('')}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Common button elements */}
      <div className="delete-icon-id d-none">
        <span className="svg-icon svg-icon-2 ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
              fill="black"
            />
            <path
              opacity="0.5"
              d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
              fill="black"
            />
            <path
              opacity="0.5"
              d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
              fill="black"
            />
          </svg>
        </span>
      </div>
    </>
  );
}

export default ReplicaNodes; 
