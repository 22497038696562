import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import UseApi from "../../../../../CustomHooks/useApi";
import TableLoader from "../../../Loaders/TableLoader";
import UsePostData from "../../../../../CustomHooks/usePostData";

export default function Vpc(props) {
  var query = window.location.href;
  var vars = query.split("/");
  var id = vars[4];
  const [scalingData, setScalingData] = useState(null);
  const [dataFetched, setDataFetched] = useState(false);
  const [activePage, setActivePage] = useState(0);
  const [access, setAccess] = useState(true);
  const [perPage, setPerPage] = useState(5);
  const [pageCount, setPageCount] = useState(0);
  const [vpcDataAll, setVpcDataAll] = useState(null);
  const [dummyVpcDataAll, setDummyVpcDataAll] = useState(null);
  const [itemOffset, setItemOffset] = useState(0);
  const currentPage = useRef(0);

  function ProductInfo({ productdata }) {
    return (
      <>
        {productdata != null && Object.keys(productdata).length != 0 ? (productdata &&
          productdata.map((value, index) => (
            <tr>
              {/* <td /> */}
              <td className="p-3 text-center ps-10">
                {" "}
                <div className="d-flex align-items-center">
                  {" "}
                  <div className="symbol symbol-30px me-5">
                    {" "}
                    {value.dclocation ? (<>{value.dclocation.dccc ? (<>
                      <img src={"/img/flag_" + value.dclocation.dccc + ".png"} />
                    </>) : ('')}</>) : ('')}
                  </div>{" "}
                  <div className="d-flex justify-content-start flex-column">
                    {" "}
                    {value.dclocation ? (<>{value.dclocation.location ? (<>
                      <a className="text-dark fw-bold text-hover-primary fs-6">
                        {value.dclocation.location}
                      </a>
                    </>) : ('')}</>) : ('')}
                    {" "}
                  </div>{" "}
                </div>{" "}
              </td>{" "}
              <td className="text-center">
                {" "}
                {/* <div className="d-flex flex-column w-100 me-2">
                {" "}
                <div className="d-flex flex-stack mb-2"> */}
                {" "}
                <span className=" me-2 fs-7 fw-bold">
                  {value.name}
                </span>{" "}
                {/* </div>{" "}
              </div>{" "} */}
              </td>{" "}
              <td className="text-end">
                {" "}
                <div className="d-flex flex-column w-100 me-2">
                  {" "}
                  <div className="d-flex flex-stack mb-2">
                    {" "}
                    <span className="me-2 fs-7 fw-bold">
                      {value.network}/{value.size}
                    </span>{" "}
                  </div>{" "}
                </div>{" "}
              </td>{" "}
              <td className="text-center">
                {" "}
                <span className="badge badge-secondary badge-lg">{value.available}</span>{" "}
              </td>{" "}
              {/* <td className="text-center">
              {" "}
              <span className="btn btn-danger btn-sm" id={`delete-vpc-btn-${value.id}`} onClick={()=>deleteVpc(value.id)}>Delete</span>{" "}
            </td>{" "} */}
            </tr>
          ))) : (<>
            <tr>
              {/* <td colspan="12" className="dataTables_empty" id="loader"> */}
              {/* <div className="loading-spinner"></div> */}
              {/* <TableLoader /> */}
              {/* </td> */}
              <td colspan="12" className="d-none" id="no_record" valign="top">
                <div className="d-flex flex-column flex-center">
                  <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                </div>
              </td>
            </tr>
          </>)}
      </>
    );
  }

  const getAutoScaling = async () => {
    // setScalingData(null);
    const data = await UseApi("get", "autoscaling/" + id, "");
    if (data) {
      if (data.rcode == "error") {
        setScalingData([]);
        const dt = toast
        toast.update(dt, {
          render: "no record found",
          type: "warning",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // loaderFunc();
      } else {
        console.log(data.groups[0]);
        setScalingData(data.groups[0]);
        toast.dismiss();
        // getVPC()
      }
    }
  };

  useEffect(() =>{
    getAutoScaling()
  },[])

  useEffect(() => {
    if (scalingData !== null) {
      // Call getVPC after scalingData is set
      getVPC();
    }
    // getVPC();
  }, [scalingData]);

  const [vpcData, setVpcData] = useState(null);
  const dataFetchedRef = useRef(false);

  const getVPC = async () => {
    var norecords = document.querySelector("#no_record");
    var loader = document.querySelector("#loader");
    const datas = await UseApi('get', `vpc/dc/${scalingData.dclocation.dc}`, '');
    // console.log(datas);
    if (datas) {
      setDataFetched(true);
    }
    if (datas.status == 'error' && datas.message != "No VPC.") {
      setVpcDataAll([]);
      setDummyVpcDataAll([])
      const dt = toast
      toast.update(dt, {
        render: datas.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      setAccess(false);
      // loaderFunc();
    }
    // delete (datas.html);
    // const data = Object.values(datas);
    //////console.log(data);
    if (datas.status == "error" && datas.message == "No VPC.") {
      setVpcDataAll([]);
      setDummyVpcDataAll([])
      if (norecords && loader) {
        norecords.classList.remove("d-none");
        loader.classList.add("d-none");
      }
      const dt = toast
      toast.update(dt, {
        render: "no record found", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });

    } else {
      console.log(scalingData?.vpc, scalingData, props, props.vpcArr, datas.vpc);
      // if (props && props.vpcArr) {
      var vdata = scalingData?.vpc;
      var vpcInfo = datas?.vpc?.filter((item) => {
        // console.log(vdata.includes(item.id));
        // console.log(vdata.some((id) => id.id == item.id));
        return vdata?.some((id) => id.id == item.id); // Use strict equality and includes
      });
      var vpcInfoData = datas?.vpc?.filter((item) => {
        console.log(item, vdata);
        return !vdata?.some((id) => id && id.id == item.id); // Use strict equality and includes
      });
      console.log(vpcInfoData, vpcInfo);
      setVpcData(vpcInfoData);
      setDummyVpcDataAll(vpcInfo)
      setVpcDataAll(vpcInfo);
      // }

      if (norecords && loader) {
        norecords.classList.add("d-none");
        loader.classList.add("d-none");
      } toast.dismiss();
      //   toast.update(dt,{render: "Your VPC request has been successful", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      // });  

    }

  }

  function PaginatedItems({ itemsPerPage, ProductData }) {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(ProductData.length / itemsPerPage));

    const handlePageClick = (event) => {
      setActivePage((old) => old = event.selected);
      const newOffset = event.selected * itemsPerPage % ProductData.length;
      setItemOffset(newOffset);
    };
    console.log(currentPage.current,itemOffset, endOffset);
    
    return (
      <>
        <ProductInfo productdata={ProductData.slice(itemOffset, endOffset)} />
        <td colspan="12">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="< previous"
            pageClassName="page-item"
            initialPage={activePage}
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </td>
      </>
    );


  }
  const openDrawer = () => {
    var drawerElement = document.querySelector("#vpc-modal");
    var drawer_overlay = document.querySelector("#drawer_overlay_vpc");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
        drawerElement.style.width = "100%";
      } else {
        drawerElement.style.width = "40%";
      }
      drawer_overlay.classList.toggle("d-none");
    }
  };
  function checkObjectForEmpty(obj) {
    for (const key in obj) {
      const value = obj[key];

      if (typeof value === 'string' && value.trim() === '') {
        return `Error: '${key}' should not be empty or blank.`;
      }

      if (typeof value === 'object') {
        const nestedError = checkObjectForEmpty(value);
        if (nestedError) {
          return nestedError;
        }
      }
    }

    return null; // No empty or blank elements found
  }
  const attachVpc = async () => {
    var add_vpc = document.querySelector("#add_vpc_id");

    toast.dismiss();
    const dt = toast.loading("Please wait...");
    const submitBtn = document.querySelector("#add-vpc-btn");
    var updateData = { "vpc": add_vpc.value };
    const validationError = checkObjectForEmpty(updateData);

    if (validationError) {
      toast.update(dt, {
        render: validationError,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (submitBtn) {
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML =
        'Please wait... <span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';

      const responseData = await UsePostData("post", "autoscaling/" + id + "/vpc", updateData);

      ////console.log(responseData);
      if (responseData.status === "error") {
        toast.update(dt, {
          render: responseData.message,
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (responseData.status === "success") {
        toast.update(dt, {
          render: responseData.message,
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        openDrawer();
        getAutoScaling()
      }
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML = preContent;
    }
  };

  // const deleteVpc = () => {

  // }

  const handleSearch = (text) => {
    const endOffset = itemOffset + perPage;
    const data = vpcDataAll.filter(row => {
      console.log(row);
      return (
        (row.name && row.name.toString().toLowerCase().includes(text.toLowerCase())) ||
        (row.network && row.network.toString().toLowerCase().includes(text.toLowerCase())) ||
        (row.dclocation.location && row.dclocation.location.toString().toLowerCase().includes(text.toLowerCase()))
      )
    });
    setDummyVpcDataAll(data);;
    setPageCount(Math.ceil(data.length / perPage));
    // }
  }

  const deleteVpc = async (vid) => {
    toast.dismiss();
    const dt = toast.loading("Please wait...");
    const submitBtn = document.querySelector("#delete-vpc-btn-" + vid);
    console.log(submitBtn);
    if (submitBtn) {
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML =
        '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';

      const responseData = await UseApi("delete", "autoscaling/" + id + "/vpc/" + vid, "");

      ////console.log(responseData);
      if (responseData?.status === "error") {
        toast.update(dt, {
          render: responseData.message,
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (responseData?.status === "success") {
        toast.update(dt, {
          render: responseData.message,
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        getVPC();
      }
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML = preContent;
    }
  };

  return (
    <>
      <div className="col-xl-12">
        {/*begin::Tables Widget 9*/}
        <div className="card card-xl-stretch mb-5 mb-xl-12">
          {/*begin::Header*/}
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold fs-3 mb-1">
                {" "}
                VPC {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                <span className="svg-icon svg-icon-2">
                  <i className="fonticon-share" style={{ fontSize: 20 }} />
                </span>
                {/*end::Svg Icon*/}
              </span>
            </h3>
            <div
              className="card-toolbar"
            >
              <div className="position-relative my-1" style={{ marginRight: 20 }}>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  data-kt-table-widget-4="search"
                  id="searchClient"
                  style={{ padding: '0.6rem 0.6rem' }}
                  onChange={e => handleSearch(e.target.value)}
                  placeholder="Search"
                />
              </div>
              <div style={{ marginRight: 20 }}>
                <div className="dataTables_length">
                  <label className="w-125px">
                    <select
                      onChange={e => setPerPage(e.target.value)}
                      //data-control="select2"
                      style={{ padding: '0.6rem 0.6rem' }}
                      className="form-select form-select-sm form-select-solid"
                      defaultValue={5}
                      id="perPage"

                    >
                      <option value={5} selected="">
                        5 Records
                      </option>
                      <option value={10}>10 Records</option>
                      <option value={25}>25 Records</option>
                      <option value={50}>50 Records</option>
                      <option value={100}>100 Records</option>
                    </select>
                  </label>
                </div>
              </div>
              <a
                onClick={openDrawer}
                className="btn btn-sm btn-active-light-primary btn-primary"
                style={{ padding: '0.65rem 1rem' }}
              >
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
                <span className="svg-icon svg-icon-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x="11.364"
                      y="20.364"
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(-90 11.364 20.364)"
                      fill="black"
                    />
                    <rect
                      x="4.36396"
                      y="11.364"
                      width={16}
                      height={2}
                      rx={1}
                      fill="black"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}Add VPC
              </a>
            </div>
          </div>
          {/*end::Header*/}
          {/*begin::Body*/}
          {/*begin::Table container*/}
          <div className="table-responsive">
            {/*begin::Table*/}
            <table
              id="vpcTable"
              className="table table-striped table-row-gray-300 align-middle gs-0 gy-4 mb-0"
            >
              {/*begin::Table head*/}
              <thead className="bg-secondary">
                <tr className="fw-bold ">
                  {/* <th className="  min-w-20px p-3" /> */}
                  <th className="w-100px p-3 text-start ps-9">Location</th>
                  <th className="w-150px text-center">Name</th>
                  <th className="w-100px ps-7">Network</th>
                  <th className="text-center w-150px">Resources</th>
                  {/* <th className="text-center min-w-150px">Action</th> */}
                </tr>
              </thead>
              {/*end::Table head*/}
              {/*begin::Table body*/}
              <tbody id="mainTable" >
                {vpcDataAll != null ? (<>

                  {Object.keys(vpcDataAll).length != 0 ? (<>
                    <PaginatedItems ProductData={dummyVpcDataAll} itemsPerPage={perPage} />
                  </>) : (<>
                    <tr>
                      <td colspan={12}>
                        <div className="card-body p-0 mt-10">
                          {/*begin::Illustration*/}
                          <div className="text-center px-4 mt-10">
                            <span className="mw-100 mh-300px">
                              <svg
                                viewBox="0 0 64 64"
                                width="234px"
                                height="234px"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                fill="#21325B"
                                stroke="#21325B"
                              >
                                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <title>clo-cloud-network-folder</title> <desc>Created with Sketch.</desc>{" "}
                                  <defs> </defs>{" "}
                                  <g
                                    id="64px-Glyph"
                                    stroke="none"
                                    strokeWidth={1}
                                    fill="none"
                                    fillRule="evenodd"
                                  >
                                    {" "}
                                    <g id="clo-cloud-network-folder" fill="#21325B">
                                      {" "}
                                      <path
                                        d="M12,13 C12,12.447 12.447,12 13,12 L51.444,12 C50.75,10.81 49.474,10 48,10 L30,10 C28,10 27.497,9.329 26.8,8.4 C25.998,7.331 25,6 22,6 L12,6 C9.794,6 8,7.794 8,10 L8,38 C8,40.206 9.794,42 12,42 L12,13 Z"
                                        id="Fill-216"
                                      >
                                        {" "}
                                      </path>{" "}
                                      <path
                                        d="M38.8887,36 L27.8887,36 C24.6417,36 21.9997,33.458 21.9997,30.333 C21.9997,27.51 24.1567,25.163 26.9677,24.736 C27.4307,22.053 29.8597,20 32.7777,20 C34.7387,20 36.5317,20.919 37.6217,22.441 C38.0377,22.369 38.4617,22.333 38.8887,22.333 C42.8097,22.333 45.9997,25.398 45.9997,29.167 C45.9997,32.935 42.8097,36 38.8887,36 M54.9997,14 L51.9997,14 L13.9997,14 L13.9997,42 L51.9997,42 C54.2057,42 55.9997,40.206 55.9997,38 L55.9997,15 C55.9997,14.447 55.5527,14 54.9997,14"
                                        id="Fill-217"
                                      >
                                        {" "}
                                      </path>{" "}
                                      <path
                                        d="M38.8887,24.333 C38.3887,24.333 37.8947,24.401 37.4197,24.535 C36.9657,24.661 36.4837,24.457 36.2637,24.039 C35.6017,22.781 34.2657,22 32.7777,22 C30.6327,22 28.8887,23.645 28.8887,25.667 C28.8887,26.22 28.4417,26.667 27.8887,26.667 C25.7437,26.667 23.9997,28.312 23.9997,30.333 C23.9997,32.356 25.7437,34 27.8887,34 L38.8887,34 C41.7067,34 43.9997,31.832 43.9997,29.167 C43.9997,26.502 41.7067,24.333 38.8887,24.333"
                                        id="Fill-218"
                                      >
                                        {" "}
                                      </path>{" "}
                                      <path
                                        d="M24,50 L5,50 C4.447,50 4,50.447 4,51 L4,55 C4,55.553 4.447,56 5,56 L24,56 L24,50 Z"
                                        id="Fill-219"
                                      >
                                        {" "}
                                      </path>{" "}
                                      <polygon id="Fill-220" points="30 46 36 46 36 44 30 44">
                                        {" "}
                                      </polygon>{" "}
                                      <path
                                        d="M39,48 L27,48 C26.447,48 26,48.447 26,49 L26,57 C26,57.553 26.447,58 27,58 L39,58 C39.553,58 40,57.553 40,57 L40,49 C40,48.447 39.553,48 39,48"
                                        id="Fill-221"
                                      >
                                        {" "}
                                      </path>{" "}
                                      <path
                                        d="M61,50 L42,50 L42,56 L61,56 C61.553,56 62,55.553 62,55 L62,51 C62,50.447 61.553,50 61,50"
                                        id="Fill-222"
                                      >
                                        {" "}
                                      </path>{" "}
                                    </g>{" "}
                                  </g>{" "}
                                </g>
                              </svg>


                            </span>
                          </div>
                          {/*end::Illustration*/}
                          {/*begin::Wrapper*/}
                          <div className="card-px text-center py-5 mb-20">
                            {/*begin::Title*/}
                            <h1 className="fs-2x fw-bolder mb-5"> VPC</h1>
                            <h2 className="fs-1x fw-bolder mb-5 text-muted">
                              Build a custom network in the cloud
                            </h2>
                            {/*end::Title*/}
                            {/*begin::Description*/}
                            <p className="text-gray-400 fs-4 fw-bold mb-10">
                              Take control with Utho's VPC service. Create a secure environment for your<br /> business-critical applications and data                </p>
                            {/*end::Description*/}
                            {/*begin::Action*/}
                            <a
                              onClick={openDrawer}
                              className="btn btn-primary mb-10"
                            >
                              Create VPC
                            </a>
                            {/*end::Action*/}
                          </div>
                          {/*end::Wrapper*/}
                        </div>
                      </td>
                    </tr>
                  </>)}
                </>) : (<>
                  <tr>
                    <td colspan={12}>
                      <TableLoader />
                    </td>
                  </tr>

                </>)}
              </tbody>
              {/*end::Table body*/}
            </table>
            {/*end::Table*/}
          </div>
          {/*end::Table container*/}
          {/*begin::Body*/}
        </div>
        {/*end::Tables Widget 9*/}
      </div>


      <div id="drawer_overlay_vpc" onClick={openDrawer} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>

      <div
        id="vpc-modal"
        className="bg-body shadow drawer drawer-end"
        // data-kt-drawer="true"
        // data-kt-drawer-name="chat"
        // data-kt-drawer-activate="true"
        // data-kt-drawer-direction="end"
        data-kt-drawer-close="#close_ssh"
        // data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
        style={{ important: true, zIndex: 110 }}
      >
        {/*begin::Messenger*/}
        <div
          className="card rounded-0 w-100 border-0"
          id="kt_drawer_chat_messenger"
        >
          {/*begin::Card header*/}
          <div
            className="card-header pe-5"
            id="kt_drawer_chat_messenger_header"
          >
            {/*begin::Title*/}
            <div className="card-title">
              {/*begin::User*/}
              <div className="d-flex justify-content-center flex-column me-3">
                <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                  Add New VPC
                </a>
              </div>
              {/*end::User*/}
            </div>
            {/*end::Title*/}
            {/*begin::Card toolbar*/}
            <div className="card-toolbar">
              {/*begin::Close*/}
              <div
                onClick={openDrawer}
                className="btn btn-sm btn-icon btn-active-light-primary"
                id="close_ssh"
              >
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                <span className="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x={6}
                      y="17.3137"
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(-45 6 17.3137)"
                      fill="black"
                    />
                    <rect
                      x="7.41422"
                      y={6}
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(45 7.41422 6)"
                      fill="black"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
              </div>
              {/*end::Close*/}
            </div>
            {/*end::Card toolbar*/}
          </div>
          {/*end::Card header*/}
          {/*begin::Card body*/}
          <div className="" id="">
            {/*begin::Form*/}
            <form
              name="importkey"
              id="importkey"
              className="form"
            >
              {/*begin::Modal body*/}
              <div className="modal-body ">
                {/*begin::Scroll*/}
                <div
                  className="scroll-y me-n7 pe-7"
                  id="kt_modal_create_api_key_scroll"
                  data-kt-scroll="true"
                  data-kt-scroll-activate="{default: false, lg: true}"
                  data-kt-scroll-max-height="auto"
                  data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                  data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                  data-kt-scroll-offset="300px"
                >
                  {/*begin::Input group*/}
                  <div className="mb-5 fv-row">
                    {/*begin::Label*/}
                    <label className="required fs-5 fw-bold mb-2">
                      {" "}
                      VPC
                    </label>
                    {/*end::Label*/}
                    {/*begin::Input*/}
                    <select className="form-select" id="add_vpc_id">
                      {vpcData != null ? (<>
                        <option value='' selected disabled>Choose a Vpc</option>
                        {vpcData.map((value, index) => (<>
                          <option value={value.id}> {value.name}</option>
                        </>))}
                      </>) : ('')}
                    </select>
                    {/*end::Input*/}
                  </div>
                  {/*end::Input group*/}

                </div>
                {/*end::Scroll*/}
              </div>
              {/*end::Modal body*/}
              {/*begin::Modal footer*/}
              <div className="modal-footer pe-10">
                {/*begin::Button*/}
                <button
                  type="button"
                  onClick={attachVpc}
                  className="btn btn-primary w-100"
                  id="add-vpc-btn"
                >
                  <span className="indicator-label">
                    Add VPC
                  </span>
                </button>
                {/*end::Button*/}
              </div>
              {/*end::Modal footer*/}
            </form>
            {/*end::Form*/}
          </div>
          {/*end::Card body*/}
        </div>
        {/*end::Messenger*/}
      </div>
    </>
  )

}
