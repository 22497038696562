export const IS_CLOUD_UPDATE="IS_CLOUD_UPDATE"

export const DEPLOY_NODE_POOL_ROW="DEPLOY_NODE_POOL_ROW"

export const UPDATE_NODE_POOL_ROW="UPDATE_NODE_POOL_ROW"

export const HOST_COUNT="HOST_COUNT"

export const CLOUD_DEPLOY_DATA="CLOUD_DEPLOY_DATA"

export const DATABASE_NODE_COUNT="NODE_COUNT"

export const CURRENT_OBJECT_PARENTID="CURRENT_OBJECT_PARENTID"

export const OBJECT_BREADCRUMB="OBJECT_BREADCRUMB"

export const DATABASE_ACCESS_IP_DATA="DATABASE_ACCESS_IP_DATA"
 
export const DATABASE_CLUSTER_INFO="DATABASE_CLUSTER_INFO"

export const DEPLOY_DB_REPLICA = "DEPLOY_DB_REPLICA"

export const IS_PLAN_UPDATE="IS_PLAN_UPDATE"

export const ACTIVITY_PAGINATION="ACTIVITY_PAGINATION"

export const CLOUD_PAGINATION="CLOUD_PAGINATION"

export const DEPLOY_AUTO_SCALING="DEPLOY_AUTO_SCALING"

export const ACL_CONDITIONS = "ACL_CONDITIONS"

export const OBJECT_DEPLOY = "OBJECT_DEPLOY"
 