import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {  useNavigate } from "react-router-dom";
import { IsCloudUpdate } from "../../../../../Redux/Services/Action/actions";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import swal from 'sweetalert';
import UseApi from "../../../../../CustomHooks/useApi";
import UsePost from "../../../../../CustomHooks/usePost";
import TopBarHeaderLine from "../../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../../Loaders/TableLoader";
import ProductsUrl from "../../../Docs/Docsdata";
import CustomDoc from "../../../../../CustomHooks/CustomDoc";
import serialize from "form-serialize";
import { HandleKeyDown } from "../../../utils/input-validation/InputValidation";
const Rebuild = (props) => {
  const navigate = useNavigate();
  var query = window.location.href;
  var vars = query.split("/");
  var id = vars[4];
  const [distroData, setDistroData] = useState(null);
  const [pageStatus, setPageStatus] = useState(false)
  const dataFetchedRef = useRef(false);

  const getDistro = async () => {
    setDistroData(null);
    const data = await UseApi('get', 'cloud/getdeploy', ''); 
    if (Object.keys(data).length === 0) {
      setDistroData(null);
      setMarketplaceData([]);
    } else {
      setDistroData(data.distro);
      setMarketplaceData(data.stacks);
      ////console.log(data);
    }
  }

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    getDistro();
  }, []);

  const selectOs = (image, distribution) => {
    const os_version_list_ = document.querySelector("#os_version_list_" + distribution);
    os_version_list_.classList.remove('d-block');
    os_version_list_.classList.remove('dh-gblock');

    const all_radio = document.getElementsByClassName("radio_image");
    for (var i = 0; i < all_radio.length; i++) {
      all_radio[i].checked = false;
    }
    const all_label = document.getElementsByClassName("versionOs");
    for (var i = 0; i < all_label.length; i++) {
      all_label[i].classList.remove('active');
    }
    var pselect = document.querySelectorAll([".deploy_osversioncontainer"]);
    pselect.forEach((clist) => {
      clist.classList.remove('d-block');
    });
    const all_versionText = document.getElementsByClassName("osversionselectmultiple");
    for (var i = 0; i < all_versionText.length; i++) {
      all_versionText[i].innerHTML = "Select Version";
    }

    const select_image = document.querySelector("#select_image");
    select_image.value = image;
    const current_radio = document.querySelector("#select_" + distribution);
    current_radio.checked = true;
    const current_label = document.querySelector("#versionOs_" + distribution);
    current_label.classList.add('active');
    const current_select_version = document.querySelector("#osversion_" + distribution);
    current_select_version.innerHTML = image;


  }

  const rebuildServer = async () => {
    // setPageStatus(true)
    toast.dismiss();
    swal({
      title: "Are you sure?",
      text: "Do you really want to rebuild your cloud server!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          setPageStatus(true)
          var form = document.querySelector("#cloudrebuild");
          var requestData = serialize(form, { hash: true });
          console.log(requestData);
          const dt = toast.loading("cloud server rebuilding, Please wait...");
          const responseData = await UsePost('post', 'cloud/' + id + '/rebuild', 'cloudrebuild');
          // ////console.log(responseData);
          if (responseData.status === 'error') {
            setPageStatus(false)
            toast.update(dt, {
              render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
          } else if (responseData.status === 'success') {
            setPageStatus(false)
            toast.update(dt, {
              render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
            getDistro()
            setTimeout(() =>{
              props.getCloud()
            },1000)
            // const data=await UseApi('get','cloud/'+id,'');
            //  dispatch(props.updateCloudStatus(data.cloud[0]));
            //  navRedirect();
            // setTimeout(() => {
            //   window.location.reload();
            // }, 1000)

          }
        } else {
          setPageStatus(false)
          swal({
            title: "Cloud Rebuild",
            text: "Your cloud server not rebuild!",
            icon: "warning",
            button: "OK",
          });
        }
      });

  }

  const osVersion = (distribution) => {
    var pselect = document.querySelectorAll([".deploy_osversioncontainer"]);
    var pheight = document.querySelectorAll([".versionOs"]);
    pselect.forEach((clist) => {
      clist.classList.remove('d-block');
    });
    //var cl=event.currentTarget.querySelector('.versionOs');

    pheight.forEach((clist) => {
      clist.style.height = "auto";
      clist.classList.remove('active');
    });
    //  event.currentTarget.querySelector('.deploy_osversioncontainer').classList.add('d-block');
    const os_version_list_ = document.querySelector("#os_version_list_" + distribution);
    os_version_list_.classList.add('d-block');
    os_version_list_.classList.add('dh-gblock');
  }



  const [marketplaceData, setMarketplaceData] = useState(null);
  const [marketplaceLoader, setMarketplaceLoader] = useState(false);
  const getMarketplace = async () => {
    //  setMarketplaceLoader(true);
    //  const marketData=await UseApi("get","stacks","nothing");
    //  //console.log(marketData);
    //  if(marketData){
    //    setMarketplaceLoader(false);
    //    if(Object.keys(marketData).length!=0){
    //      if(marketData.stacks){
    //         setMarketplaceData(marketData.stacks);
    //      }
    //    }else{
    //       setMarketplaceData([]);
    //    }
    //  }
  }
  const [currMarketplace, setCurrMarketplace] = useState(null);
  const [currMarketplaceDistro, setCurrMarketplaceDistro] = useState(null);
  const [currMarketplaceLoader, setCurrMarketplaceLoader] = useState(false);
  const selectMarketPlace = async (id) => {
    removeTick();
    var dcSelect = document.querySelectorAll([".tickMarkmarketplace"]);
    var currentDc = document.querySelector("#tickMarkmarketplace-" + id);
    if (dcSelect) {
      dcSelect.forEach((clist) => {
        clist.classList.add("d-none");
      });
    }
    if (currentDc) {
      currentDc.classList.remove("d-none");
    }
    var mainStack = document.querySelector("#main_stack");
    var os_main = document.querySelector("#os_main");
    if (os_main) {
      os_main.value = "";
    }
    if (mainStack) {
      mainStack.value = id;
      setCurrMarketplaceLoader(true);
      // const marketData=await UseApi("get","stacks","nothing");
      // //console.log(marketData);
      if (marketplaceData) {
        setCurrMarketplaceLoader(false);
        // if(Object.keys(marketData).length!=0){
        // if(marketData.stacks){
        marketplaceData.map((value, index) => {
          if (value.id == id) {
            if (value.distro.length == 0) {
              setCurrMarketplaceDistro([]);
            } else {
              setCurrMarketplaceDistro(value.distro);
            }
            if (value.fields.length == 0) {
              setCurrMarketplace([]);
            } else {
              setCurrMarketplace(value.fields);
            }
          }
        })
        // }
        // } 
      }
    }
  }

  //  const selectMarketPlace=async (id)=>{
  //    removeTick();
  //    var mainStack=document.querySelector("#main_stack");
  //    var os_main=document.querySelector("#os_main");
  //    if(os_main){
  //      os_main.value="";
  //    }
  //    if(mainStack){
  //          mainStack.value=id;
  //    setCurrMarketplaceLoader(true);
  //    const marketData=await UseApi("get","stacks","nothing");
  //    // //console.log(marketData);
  //    if(marketData){  
  //      setCurrMarketplaceLoader(false);
  //      if(Object.keys(marketData).length!=0){
  //        if(marketData.stacks){
  //          marketData.stacks.map((value,index)=>{ 
  //            if(value.id==id){ 
  //              if(value.distro.length==0){
  //                setCurrMarketplaceDistro([]);
  //             }else{
  //              setCurrMarketplaceDistro(value.distro);
  //             }
  //              if(value.fields.length==0){
  //                 setCurrMarketplace([]);
  //              }else{
  //                 setCurrMarketplace(value.fields);
  //              }
  //             }
  //          })
  //        }
  //      } 
  //    } 
  //  }
  //  }

  function removeTick() {
    var osSelect = document.querySelectorAll([".tickMarkOs"]);
    if (osSelect) {
      osSelect.forEach((clist) => {
        clist.classList.add("d-none");
      });
    }
    const all_label = document.getElementsByClassName("versionOs");
    for (var i = 0; i < all_label.length; i++) {
      //all_label[i].classList.remove('active');
      all_label[i].classList.remove("shadowBox");
    }

  }

  const selectOne = (type) => {
    var distroRadio = document.querySelectorAll([".distro_radio"]);
    // var marketplaceRadio = document.querySelectorAll([".marketplace_radio"]); 
    if (distroRadio) {
      if (type == "distro") {

      }
    }
  }
  useEffect(() => {
    getMarketplace();
  }, [])
  return (
    <>
      {/* <ToastContainer
    position="top-right"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    /> */}

      <div className="card">
        {/*begin::Card header*/}
        <div
          className="card-header border-0 cursor-pointer ps-6"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_deactivate"
          aria-expanded="true"
          aria-controls="kt_account_deactivate"
        >
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">Rebuild Cloud Server</h3>
          </div>
          <div className="card-toolbar">
          <CustomDoc url={ProductsUrl[0]?.cloud+"/manage-cloud/rebuild/"}/>
          </div>
        </div>
        {/*end::Card header*/}
        {/*begin::Content*/}
        <div id="kt_account_settings_deactivate" className="collapse show">
          {/*begin::Form*/}


          {/*begin::Card body*/}
          <div className="card-body border-top p-5 pt-1">

            {/*begin::Form input row*/}
            <div className="mb-2 mt-2">
              <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-trakt_charts_widget_1_chartnsparent fs-5 fw-bolder">
                <li className="nav-item">
                  <a
                    className="nav-link text-active-primary py-5 me-6 active"
                    data-bs-toggle="tab"
                    href="#distros-tabs"
                  >
                    Operating System
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link text-active-primary py-5 me-6"
                    data-bs-toggle="tab"
                    href="#marketplace-tab"
                  >
                    Marketplace
                  </a>
                </li>

              </ul>
            </div>
            <form id="cloudrebuild">
              {/*begin::Tab Content*/}
              <div className="tab-content mt-5">
                {/*begin::Tap pane*/}

                <div className="tab-pane fade show active" id="distros-tabs">
                  {/*begin::Row*/}
                  <input
                    type="hidden"
                    className="form-control"
                    id="os_cost"
                    defaultValue={
                      props.deployInfo && props.deployInfo
                        ? props.deployInfo[0].os_cost
                        : ""
                    }
                  />
                  <div
                    id="distros"
                    className="row g-3"
                    data-kt-buttons="true"
                    data-kt-buttons-target="[data-kt-button]"
                  >
                    {distroData != null &&
                      distroData ? (<>
                        {distroData.map((value, index) => (
                          <>
                            {/*begin::Col*/}
                            <div
                              className="col-md-4 col-lg-4 col-xxl-4"
                              style={{ height: "73.06px !important" }}
                            >
                              <input
                                type="radio"
                                name="image_OS"
                                id={"select_" + value.distro}
                                defaultValue=""
                                className="deploy_checkbox"
                              />
                              <label
                                onClick={() => osVersion(value.distro)}
                                //className="label versionOs btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start"
                                className=" label versionOs text-start border-1 border-gray-300 border px-6 py-2"
                                id={"versionOs_" + value.distro}
                                style={{ position: "relative" }}
                              >
                                <span className="ms-5">
                                  {/*begin::Item*/}
                                  <div className="d-flex  ">
                                    {/*begin::Text*/}
                                    <div className="flex-grow-1">
                                      <div className="row">
                                        <div className="col-4">
                                          {/*begin::Avatar*/}
                                          <div className="symbol symbol-50px me-5">
                                            <span
                                              className={
                                                "fonticon_" + value.distro
                                              }
                                            />
                                          </div>
                                          {/*end::Avatar*/}
                                        </div>
                                        <div className="col-8">
                                          <p className="text-dark fw-bolder text-hover-primary fs-6">
                                            {value.distribution}

                                            <span
                                              className="text-muted d-block fw-bold deploy_checkbox_line2 osversionselectmultiple w-100px"
                                              id={
                                                "osversion_" +
                                                value.distro
                                              }
                                            >
                                              Select Version
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                      <span
                                        className="deploy_osversioncontainer bg-white p-5 border-left-right-bottom"
                                        // style={{
                                        //   zIndex: "99",
                                        //   width: "257.33px",
                                        //   marginLeft: "-37px",
                                        //   backgroundColor: "#F0FAFF",
                                        //   display: "none",
                                        // }}
                                        style={{
                                          position: "absolute",
                                          top: "100%",
                                          left: 0,
                                          width: "100%",
                                          zIndex: 99,
                                          display: "none"
                                        }}
                                        id={
                                          "os_version_list_" +
                                          value.distro
                                        }
                                      >
                                        {value.images &&
                                          value.images.map(
                                            (value2, index2) => (
                                              <>
                                                <span
                                                  className="deploy_osversionselector alert alert-primary p-3"
                                                  data-image={value2.image}
                                                  data-oscost={value2.cost}
                                                  data-title={
                                                    value2.distro
                                                  }
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    selectOne('distro');
                                                    selectOs(
                                                      value2.image,
                                                      value2.distro,
                                                      value2.cost
                                                    );
                                                  }}
                                                >
                                                  {value2.version}
                                                  {value2.cost > 0 ? (
                                                    <>
                                                      <span className="badge badge-warning">
                                                        {value2.cost}/Mo
                                                      </span>
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                </span>
                                              </>
                                            )
                                          )}
                                      </span>
                                    </div>
                                    {/*end::Text*/}
                                  </div>
                                  {/*end::Item*/}
                                </span>

                                {/*begin::Ribbon*/}
                                <div
                                  className="d-none tickMarkOs ribbon ribbon-triangle ribbon-top-start border-primary"
                                  id={"tickMarkOs-" + value.distro}
                                >
                                  {/*begin::Ribbon icon*/}
                                  <div className="ribbon-icon mt-n5">
                                    <i className="bi bi-check2 fs-2 text-white" />
                                  </div>
                                  {/*end::Ribbon icon*/}
                                </div>
                                {/*end::Ribbon*/}
                              </label>
                            </div>
                            {/*end::Col*/}
                          </>
                        ))}</>) : (<>
                          <TableLoader />
                          {/* <div className="small-spinner"></div> */}
                        </>)}
                  </div>
                  {/*end::Row*/}
                  <input
                    type="hidden"
                    name="image"
                    id="select_image"
                    defaultValue={
                      props.deployInfo && props.deployInfo
                        ? props.deployInfo[0].select_image
                        : ""
                    }
                    className="deploy_checkbox distro_radio"
                  />
                </div>

                {/*end::Tap pane*/}
                {/*begin::Tap pane*/}
                <div className="tab-pane fade" id="marketplace-tab">
                  {/*begin::Row*/}
                  <input
                    type="hidden"
                    name="stack"
                    id="main_stack"
                  />
                  <div
                    id="marketplace-data"
                    className="row g-9"
                    data-kt-buttons="true"
                    data-kt-buttons-target="[data-kt-button]"
                  >
                    {/*begin::Col*/}
                    {marketplaceLoader == false ? (<>
                      {marketplaceData != null && marketplaceData.length != 0 ? (<>
                        {marketplaceData.map((value, index) => (<>
                          <div className="col-md-4 col-lg-12 col-xxl-4">
                            {/*begin::Option*/}
                            <input
                              className="btn-check deploy_checkbox marketplace_radio"
                              type="radio"
                              name="image_OS"
                              defaultValue={value.id}
                              id={"marketplace" + value.id}
                            />
                            <label
                              onClick={(e) => selectMarketPlace(value.id)}
                              htmlFor={"marketplace" + value.id}
                              className=" label versionOs text-start border-1 border-gray-300 border px-6 py-2 d-flex"
                            >
                              {/*begin::Svg Icon | path: icons/duotune/communication/com003.svg*/}
                              {/*begin::Avatar*/}
                              <div className="symbol symbol-50px me-5">
                                <img
                                  src={'/' + value.logo_url}
                                  className=""
                                  alt=""
                                />
                              </div>
                              {/*end::Svg Icon*/}
                              <span className="d-block  text-start p-3">
                                <span className="text-dark d-block fs-4">
                                  {value.title}
                                </span>
                              </span>
                              {/*begin::Ribbon*/}
                              <div
                                className="d-none tickMarkmarketplace ribbon ribbon-triangle ribbon-top-start border-primary"
                                id={
                                  "tickMarkmarketplace-" +
                                  value.id
                                }
                              >
                                {/*begin::Ribbon icon*/}
                                <div className="ribbon-icon mt-n5">
                                  <i className="bi bi-check2 fs-2 text-white" />
                                </div>
                                {/*end::Ribbon icon*/}
                              </div>
                              {/*end::Ribbon*/}
                            </label>
                            {/*end::Option*/}
                          </div>

                        </>))}
                      </>) : (<>
                        <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                          {/*begin::Wrapper*/}

                          <div className="pt-lg-10 mb-10">
                            {/*begin::Message*/}
                            <div className="fw-bold fs-5  mb-15 badge badge-light-primary">
                              No Marketplace Available
                            </div>
                            {/*end::Message*/}
                          </div>
                          {/*end::Wrapper*/}
                        </div>
                      </>)}

                    </>) : (<>
                      <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                        {/* <div className="small-spinner"></div> */}
                        <TableLoader />
                      </div>
                    </>)}

                    {/*end::Col*/}

                  </div>
                  {/*begin::Col*/}
                  <div
                    className="col-xl-12 mb-xl-12 mt-10"
                    id="market_fields"
                  >
                    {currMarketplaceLoader == false ? (<>
                      {currMarketplaceDistro != null ? (<>
                        {currMarketplaceDistro.length != 0 ? (<>
                          {currMarketplaceDistro.length == 1 ? (<>
                            {currMarketplaceDistro.map((valueData, index) => (<>
                              <input type="hidden" name="image" defaultValue={valueData} />
                            </>))}
                          </>) : (<>
                            <div className="fv-row mb-10 fv-plugins-icon-container">
                              <label className="form-label ">Select OS</label>
                              <select className="form-select" name="image">

                                {currMarketplaceDistro.map((value, index) => (<>
                                  <option value={value}>{value}</option>
                                </>))}
                              </select>
                            </div>
                          </>)}
                        </>) : ('')}

                      </>) : ('')}
                      {currMarketplace != null ? (<>
                        {Object.keys(currMarketplace).length != 0 ? (<>

                          {currMarketplace.map((value, index) => (<>
                            <div className="fv-row mb-10 fv-plugins-icon-container">
                              <label className="form-label ">{value.label}</label>
                              <input
                                type="text"
                                name={"stack_fields[" + index + "][" + value.name + "]"}
                                defaultValue=""
                                placeholder={"Enter " + value.label}
                                className="form-control form-control-lg"
                              />
                            </div>
                          </>))}
                        </>) : (<></>)}
                      </>) : (<></>)}
                    </>) : (<>
                      <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                        {/* <div className="small-spinner"></div> */}
                        <TableLoader />
                      </div>
                    </>)}
                  </div>
                  {/*begin::Col*/}
                  {/*end::Row*/}
                </div>
                {/*end::Tap pane*/}

              </div>
              {/*end::Tab Content*/}
              {/*end::Row*/}

              {/* <input
            type="hidden"
            name="image"
            id="select_image"
            defaultValue=""
            className="deploy_checkbox" 
          /> */}
              {/*begin::Notice*/}
              <div className=" mt-10 notice d-flex bg-light-primary rounded border-primary border p-6">
                {/*begin::Icon*/}
                {/*begin::Svg Icon | path: icons/duotune/general/gen044.svg*/}
                <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.3"
                      x={2}
                      y={2}
                      width={20}
                      height={20}
                      rx={10}
                      fill="black"
                    />
                    <rect
                      x={11}
                      y={14}
                      width={7}
                      height={2}
                      rx={1}
                      transform="rotate(-90 11 14)"
                      fill="black"
                    />
                    <rect
                      x={11}
                      y={17}
                      width={2}
                      height={2}
                      rx={1}
                      transform="rotate(-90 11 17)"
                      fill="black"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
                {/*end::Icon*/}
                {/*begin::Wrapper*/}
                <div className="d-flex flex-stack flex-grow-1">
                  {/*begin::Content*/}
                  <div className="fw-bold">
                    <p className="text-gray-900 ">
                      This will destroy your complete data from the server and
                      reinstall a fresh operating system.
                    </p>
                  </div>
                  {/*end::Content*/}
                </div>
                {/*end::Wrapper*/}
              </div>
              {/*end::Notice*/}
              <div className="mb-10 mt-10">
                <label htmlFor="size" className="required form-label">
                  Please fill text in below form to delete server "I am aware this
                  action will delete data permanently and build a fresh server"
                </label>
                <input
                  type="text"
                  className="form-control "
                  name="confirm"
                  id="confirm"
                  defaultValue=""
                  placeholder="I am aware this action will delete data permanently and build a fresh server"
                  onKeyDown={HandleKeyDown}
                />
              </div>
              {/*end::Form input row*/}
            </form>
          </div>
          {/*end::Card body*/}
          {/*begin::Card footer*/}
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <a
              onClick={rebuildServer}
              className="btn btn-primary fw-bold"
            >
              Rebuild Cloud Server
            </a>
          </div>
          {/*end::Card footer*/}
          {/*end::Form*/}
        </div>
        {/*end::Content*/}
      </div>
      {pageStatus &&
        <TopBarHeaderLine />
      }
    </>
  )
}

const mapStateToProps = state => ({
  cloudData: state.cloud.cloudDataInfo
})

const mapDispatchToProps = dispatch => ({
  updateCloudStatus: data => dispatch(IsCloudUpdate(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Rebuild) 