import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import copy from "copy-to-clipboard";
import swal from "sweetalert";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import UsePost from "../../../../CustomHooks/usePost";
import Swal from "sweetalert2";
import UpdateRegistry from "./tab/UpdateRegistry";
import UsePostData from "../../../../CustomHooks/usePostData";
import CustomDoc from "../../../../CustomHooks/CustomDoc";
import ProductsUrl from "../../Docs/Docsdata";

export default function ManageContainerRegistry() {
  const [objectForUpdate, setObjectForUpdate] = useState({})
  const [isPublic, setIsPublic] = useState(true)
  const [pageStatus, setPageStatus] = useState(false)
  const navigate = useNavigate();
  var query = window.location.href;
  var vars = query.split("/");
  var id = vars[4];
  const [repos, setRepos] = useState(null);
  const dataFetchedRef = useRef(false);

  const getAllRegistry = async () => {
    try {
      var privateBtn = document.querySelector("#private_btn")
      var publicBtn = document.querySelector("#public_btn")
      let responseData = await UseApi('get', `registry/project/${id}`, '')
      console.log(responseData);
      if (privateBtn != null && publicBtn != null) {
        if (responseData.status === "success") {
          console.log(responseData.data.metadata);
          if (responseData?.data?.metadata?.public == "true") {
            privateBtn.classList.remove('d-none')
            publicBtn.classList.add('d-none')
            setIsPublic(true)
          } else {
            console.log(privateBtn);
            privateBtn.classList.add('d-none')
            publicBtn.classList.remove('d-none')
            setIsPublic(false)
          }
          // setAllData(responseData.data)
        } else {
          setIsPublic('')
        }
      } else {
        getAllRegistry()
      }
    } catch (error) {
    }
  }

  const [registryUser, setRegistryUser] = useState(null);
  const getRegistryDetails = async () => {
    const dt = toast
    const data = await UseApi('get', 'registry', '');
    if (data) {
      if (data['status'] == 'success') {

        setRegistryUser(data?.info);
      } else {
        setRegistryUser(null);
      }
    }
  }

  const switchPage = () => {
    navigate('/container-registry/create')
  }
  const getRepos = async () => {
    try {
      const data = await UseApi('get', `registry/project/${id}/repositories`, '');
      if (data && data?.data.length > 0) { // Check if the data is valid and not empty
        setRepos(data?.data);
        getAllRegistry()
      } else {
        setRepos([]); // Set to empty array if no repositories found
        getAllRegistry()
      }
    } catch (err) {
      // setError('Failed to fetch repositories. Please try again later.'); // Set error message
    }
  };

  useEffect(() => {
    getRepos();
    getRegistryDetails();
    // getAllRegistry();
  }, [isPublic]);
  //console.log(repos);

  const copyVPCName = () => {
    var eleText = document.querySelector("#file_url");
    var ele = document.querySelector("#file_url_copy_btn");
    var copiedEle = document.querySelector('#file_url_copied_btn')
    if (ele && eleText) {
      var copyText = eleText.innerText; // Use innerText to get the text content
      var innerEle = ele.innerHTML;
      var tempInput = document.createElement("textarea");
      document.body.appendChild(tempInput);
      tempInput.value = copyText;
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);

      //   ele.innerHTML = "Copied";
      ele.classList.add('d-none')
      copiedEle.classList.remove('d-none')

      setTimeout(() => {
        ele.classList.remove('d-none')
        copiedEle.classList.add('d-none')
        // ele.innerHTML = innerEle; // Set your original content here
      }, 2000);
    }
  };
  document.addEventListener('click', function (event) {
    if (event.target && event.target.id === 'file_url_copy_btn') {
      copyVPCName();
    }
  });

  const destroyregistry = async (name) => {
    toast.dismiss();
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Public Registry!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          const dt = toast.loading("Registry deleting...");
          const dnsp = await UseApi("delete", `registry/project/${name}`, "");
          // //console.log(dnsp);
          if (dnsp.status == 'error') {
            setPageStatus(false)
            toast.update(dt, {
              render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
          } else {
            setPageStatus(false)
            toast.update(dt, {
              render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
            const url = window.location.href
            if (query == url) {
              navigate('/container-registry');
            }
          }
        } else {
          swal({
            title: "Invalid Registry Name",
            text: "Please confirm the valid Registry!",
            icon: "warning",
            button: "OK",
          });
        }
      })
  }

  const handleDeleteRegistry = async (name, index) => {
    toast.dismiss();
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Repository!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          const dt = toast.loading("Please wait...");
          const submitBtn = document.querySelector("#delete-registry-btn-" + name);
          if (submitBtn) {
            var preContent = submitBtn.innerHTML;
            submitBtn.classList.add("disabled");
            submitBtn.innerHTML =
              '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';

            const responseData = await UseApi("delete", `registry/project/${id}/repositories/${name}`, "");

            ////console.log(responseData);
            if (responseData.status === "error") {
              toast.update(dt, {
                render: responseData.message,
                type: "error",
                isLoading: false,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else if (responseData.status === "success") {
              toast.update(dt, {
                render: responseData.message,
                type: "success",
                isLoading: false,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setPageStatus(!pageStatus)
              // props.updateDatabase()
              setRepos((old) => {
                old.splice(index, 1);
                return [...old];
              })
            }
            submitBtn.classList.remove("disabled");
            submitBtn.innerHTML = preContent;
          }
        }
      })
  }

  const ActionHostname = async (action) => {
    var showHost = document.querySelector("#showHost");
    var updateHost = document.querySelector("#update_host");
    if (showHost) {
      if (updateHost) {
        if (action == "showHost") {
          console.log(action);
          showHost.classList.add("d-none");
          updateHost.classList.remove("d-none");
        }
        if (action == "cancelHost") {
          showHost.classList.remove("d-none");
          updateHost.classList.add("d-none");
        }
        if (action == "updateHost") {

          toast.dismiss();
          const dt = toast.loading("updating Host name, Please wait...");
          const responseData = await UsePost('put', 'vpc/' + id, 'updateHostname');
          //////console.log(responseData);
          if (responseData.status === 'error') {
            toast.update(dt, {
              render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
          } else if (responseData.status === 'success') {
            toast.update(dt, {
              render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
            showHost.classList.remove("d-none");
            updateHost.classList.add("d-none");
            // const data = await UseApi('get', 'cloud/' + id, '');
            // dispatch(props.updateCloudStatus(data.cloud[0]));
            getRepos()
            // getCloud();
            // navRedirect();
          }
        }
      }
    }
  }

  // console.log(repos);

  function convertTimestamp(timestamp) {
    const date = new Date(timestamp);

    // Format the date
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
      timeZone: 'UTC'
    }).format(date);

    return formattedDate.replace('GMT', '(UTC)');
  }

  console.log(isPublic);
  const openDrawerForUpdate = () => {
    var drawerElement = document.querySelector("#fw-modal");
    var drawer_overlay = document.querySelector("#drawer_overlay_firewall");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
        drawerElement.style.width = "100%";
      } else {
        drawerElement.style.width = "40%";
      }
      drawer_overlay.classList.toggle("d-none");
    }
  };

  // const findObjectForUpdate = (name) => {
  //   // setIsUpdate(true)
  //   // console.log(props.allPublicData, name);
  //   const foundObject = props.allPublicData.find(obj => obj.name === name);
  //   if (foundObject) {
  //     var selectElement = document.getElementById("update_cr_id");
  //     var selectIdElement = document.getElementById("public_id_for_update");
  //     selectElement.value = foundObject?.metadata?.public == 'true' ? 'public' : 'private';
  //     selectIdElement.value = foundObject.name
  //     let element = document.querySelector("#cr_type")
  //     element.value = foundObject?.metadata?.public
  //     setObjectForUpdate(foundObject)
  //   } else {
  //     setObjectForUpdate({})
  //   }
  // }

  const extractString = (input) => {
    if (input.includes("/")) {
      return input.split("/")[1];
    } else {
      return input;
    }
  };

  const copyToClipboard = (target, targetBy) => {
    var btn_id = document.querySelector("#" + targetBy);
    var target_id = document.querySelector("#" + target);
    if (btn_id) {
      var copyText = btn_id.innerHTML;
      var copyEle = target_id.innerHTML;
      copy(copyEle);
      btn_id.classList.add("disabled")
      btn_id.innerHTML = `<svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9.89557 13.4982L7.79487 11.2651C7.26967 10.7068 6.38251 10.7068 5.85731 11.2651C5.37559 11.7772 5.37559 12.5757 5.85731 13.0878L9.74989 17.2257C10.1448 17.6455 10.8118 17.6455 11.2066 17.2257L18.1427 9.85252C18.6244 9.34044 18.6244 8.54191 18.1427 8.02984C17.6175 7.47154 16.7303 7.47154 16.2051 8.02984L11.061 13.4982C10.7451 13.834 10.2115 13.834 9.89557 13.4982Z"
        fill="white"
      />
    </svg>`;
      setTimeout(() => {
        btn_id.innerHTML = copyText;
        btn_id.classList.remove("disabled")
      }, 2000);
    }

  }

  const openDrawer = () => {
    var drawerElement = document.querySelector("#connection-modal");
    var drawer_overlay = document.querySelector("#drawer_overlay_connection");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      drawerElement.classList.toggle("opn_drawer");
      if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
        drawerElement.style.width = "100%";
        drawerElement.style.display = 'block !important'
      } else {
        drawerElement.style.width = "81%";
        drawerElement.style.display = 'block !important'
      }
      drawer_overlay.classList.toggle("d-none");
    }
  };

  const attachCr = async (obj, btn_id) => {
    var submitBtn = document.querySelector(`#${btn_id}`)
    console.log(submitBtn.innerHTML);
    // if (update_cr && updateBtn && idElement.value) {
    toast.dismiss();
    const dt = toast.loading("Please wait...");
    if (submitBtn) {
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = 'Please wait... <span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      const responseData = await UsePostData("put", `registry/project/${id}`, obj);
      console.log(responseData);
      if (responseData.status === "error") {
        toast.update(dt, {
          render: responseData.message,
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (responseData.status === "success") {
        // props.updateDatabase()
        toast.update(dt, {
          render: responseData.message,
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        getAllRegistry()
        // openDrawer();
        // props.getAllRegistry();
      }
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML = preContent;
    }
    // }
  };

  return (
    <>
      <div className="col-xl-12">
        {repos != null ? (<>
          <div className="">
            <div className="" id="appresult">
              <div className="card mb-6 mb-xl-9">
                <div className="card-body pt-9 pb-0">
                  {/*begin::Details*/}
                  <div className="d-flex flex-wrap flex-sm-nowrap mb-6">
                    <div className="d-flex flex-center flex-shrink-0 bg-light rounded w-55px h-55px w-lg-55px h-lg-55px me-7 mb-4">
                      <span className="svg-icon svg-icon-primary svg-icon-2hx">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#494b74"><path opacity="0.3" d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z" fill="#494b74"></path><path d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z" fill="#494b74"></path></svg>
                      </span>
                    </div>
                    <div className="flex-grow-1">
                      <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                        <div className="d-flex flex-column">
                          <div className="d-flex align-items-center mt-5">
                            <a
                              className="text-gray-800 text-hover-primary fs-2 fw-bolder me-3"
                              id="sub-name"
                            >
                              {id}
                            </a>
                          </div>
                          <div
                            className="d-flex flex-wrap fw-bold mb-4 fs-5 text-gray-400 d-none"
                            id="sub-contact"
                          >
                          </div>
                        </div>
                        <div className="d-flex mb-4 me-0 mt-5">
                          <div className="zoom">
                            <button id="subuser-update" className="btn btn-primary btn-sm d-none">
                            </button>
                          </div>
                          <div className=" zoom me-5 btn btn-sm btn-light-primary sphide d-none">
                            <span id="sub-id" className="fw-bold">
                            </span>
                          </div>
                          <form id="delete_subuser" />
                          {/* {isPublic ? */}
                          <a
                            className="btn btn-sm btn-primary me-3 d-none"
                            // data-bs-toggle="modal"
                            // data-bs-target="#cr_cred"
                            id="private_btn"
                            onClick={() => { attachCr({ 'public': 'false' }, 'private_btn') }}
                          >
                            <span className="svg-icon svg-icon-3">
                              <svg
                                version={1}
                                id="Layer_1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 64 64"
                                enableBackground="new 0 0 64 64"
                                xmlSpace="preserve"
                                fill="#000000"
                              >
                                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                <g
                                  id="SVGRepo_tracerCarrier"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <path
                                    fill="black"
                                    d="M52,24h-4v-8c0-8.836-7.164-16-16-16S16,7.164,16,16v8h-4c-2.211,0-4,1.789-4,4v32c0,2.211,1.789,4,4,4h40 c2.211,0,4-1.789,4-4V28C56,25.789,54.211,24,52,24z M32,48c-2.211,0-4-1.789-4-4s1.789-4,4-4s4,1.789,4,4S34.211,48,32,48z M40,24 H24v-8c0-4.418,3.582-8,8-8s8,3.582,8,8V24z"
                                  />{" "}
                                </g>
                              </svg>
                            </span>
                            Make Private
                          </a>
                          {/* : */}
                          <a
                            className="btn btn-sm btn-primary me-3 d-none"
                            // data-bs-toggle="modal"
                            // data-bs-target="#cr_cred"
                            id="public_btn"
                            onClick={() => { attachCr({ 'public': 'true' }, "public_btn") }}
                          >
                            <span className="svg-icon svg-icon-3">
                              <svg viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" fill="black">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                  <g id="🔍-Product-Icons" stroke="none" stroke-width="1" fill="black" fill-rule="evenodd">
                                    <g id="ic_fluent_unlock_28_filled" fill="black" fill-rule="nonzero">
                                      <path d="M13.2881643,2.00489799 L13.5,2 C15.8751084,2 17.8371399,3.84564416 17.9904262,6.20451395 C18.0172864,6.6178557 17.7039812,6.97470986 17.2906394,7.00157003 C16.8772977,7.02843019 16.5204435,6.71512495 16.4935833,6.3017832 C16.3914884,4.73067987 15.0832012,3.5 13.5,3.5 C11.9023191,3.5 10.5963391,4.74891996 10.5050927,6.32372721 L10.5,6.5 L10.4994005,9.499 L19.7504315,9.5 C20.9930721,9.5 22.0004315,10.5073593 22.0004315,11.75 L22.0004315,23.7518352 C22.0004315,24.9944759 20.9930721,26.0018352 19.7504315,26.0018352 L7.24840055,26.0018352 C6.00575986,26.0018352 4.99840055,24.9944759 4.99840055,23.7518352 L4.99840055,11.75 C4.99840055,10.5073593 6.00575986,9.5 7.24840055,9.5 L8.99940055,9.499 L9,6.5 C9,4.08572667 10.9012365,2.11551091 13.2881643,2.00489799 L13.5,2 L13.2881643,2.00489799 Z M13.5,16.0012962 C12.6028952,16.0012962 11.8756481,16.7285433 11.8756481,17.6256481 C11.8756481,18.5227529 12.6028952,19.25 13.5,19.25 C14.3971048,19.25 15.1243519,18.5227529 15.1243519,17.6256481 C15.1243519,16.7285433 14.3971048,16.0012962 13.5,16.0012962 Z" fill="black">
                                      </path>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </span>
                            Make Public
                          </a>
                          {/* } */}
                          {registryUser != null ? (<>
                            <a className="btn btn-sm btn-light-primary border border-primary me-3" data-bs-toggle="modal" data-bs-target="#cr_cred">
                              <span className="svg-icon svg-icon-3">
                                <i className="bi bi-eye fs-2" id="eye-open"></i>
                              </span>
                              View Credentials
                            </a>
                          </>) : ('')}

                          <div className="fs-7 fw-bold btn btn-sm btn-light-primary sphide border border-primary">
                            <div onClick={() => openDrawer()}>
                              View Push Commands&nbsp;
                              <span className="svg-icon svg-icon-muted svg-icon-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    opacity="0.3"
                                    d="M4.7 17.3V7.7C4.7 6.59543 5.59543 5.7 6.7 5.7H9.8C10.2694 5.7 10.65 5.31944 10.65 4.85C10.65 4.38056 10.2694 4 9.8 4H5C3.89543 4 3 4.89543 3 6V19C3 20.1046 3.89543 21 5 21H18C19.1046 21 20 20.1046 20 19V14.2C20 13.7306 19.6194 13.35 19.15 13.35C18.6806 13.35 18.3 13.7306 18.3 14.2V17.3C18.3 18.4046 17.4046 19.3 16.3 19.3H6.7C5.59543 19.3 4.7 18.4046 4.7 17.3Z"
                                    fill="black"
                                  />
                                  <rect
                                    x="21.9497"
                                    y="3.46448"
                                    width={13}
                                    height={2}
                                    rx={1}
                                    transform="rotate(135 21.9497 3.46448)"
                                    fill="black"
                                  />
                                  <path
                                    d="M19.8284 4.97161L19.8284 9.93937C19.8284 10.5252 20.3033 11 20.8891 11C21.4749 11 21.9497 10.5252 21.9497 9.93937L21.9497 3.05029C21.9497 2.498 21.502 2.05028 20.9497 2.05028L14.0607 2.05027C13.4749 2.05027 13 2.52514 13 3.11094C13 3.69673 13.4749 4.17161 14.0607 4.17161L19.0284 4.17161C19.4702 4.17161 19.8284 4.52978 19.8284 4.97161Z"
                                    fill="black"
                                  />
                                </svg>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*end::Details*/}
                  <div className="separator" />
                  {/*begin::Nav*/}
                  <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                    {/*begin::Nav item*/}
                    <li className="nav-item">
                      <a
                        className="nav-link text-active-primary py-5 me-6 active"
                        data-bs-toggle="tab"
                        href="#repository"
                      >
                        Repository
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" data-bs-toggle="tab" href="#destroy_vpc">
                        Destroy
                      </a>
                    </li>
                    {/*end::Nav item*/}
                  </ul>
                  {/*end::Nav*/}
                </div>
              </div>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="repository"
                  role="tabpanel"
                >
                  {/*begin::License usage*/}
                  <div className="card">
                    {/*begin::Card header*/}
                    <div className="card-header">
                      {/*begin::Heading*/}
                      <div className="card-title">
                        <h3>Repository</h3>
                      </div>
                      <div className="card-toolbar">
                        <div className="position-relative my-1" style={{ marginRight: 20 }}>
                          <CustomDoc url={ProductsUrl[0]?.container_registry + "/manage-cr/"} />
                        </div>
                      </div>
                      {/*end::Heading*/}
                    </div>
                    {/*end::Card header*/}
                    {/*begin::Card body*/}
                    <div className="">
                      {/*begin::Table wrapper*/}
                      <div className="table-responsive">
                        {/*begin::Table*/}
                        <table
                          className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
                          id="kt_security_license_usage_table"
                        >
                          {/*begin::Table head*/}
                          <thead className="bg-secondary">
                            <tr className="fw-bold ">
                              <th className="w-150px ps-9">Name</th>
                              <th className="w-50px text-center">Pull Count</th>
                              <th className="w-50px" />
                              <th className="w-150px text-center">Created_at</th>
                              <th className="w-100px" />
                            </tr>
                          </thead>
                          {/*end::Table head*/}
                          <tbody id="vpc-list">
                            {repos != null ? repos.length > 0 ? (repos && repos.map((value, index) => (
                              <tr>
                                {" "}
                                <td className="fw-bold ps-9">
                                  {" "}
                                  <span className="fs-6 fw-bold sphide">
                                    {/* <Link to={"/cloud/" + value.id}> */}
                                    <a  >
                                      {extractString(value.name)}{" "}
                                    </a>
                                    {/* </Link> */}
                                    {" "}
                                    {/* ({value.pull_count}){" "} */}
                                  </span>{" "}
                                </td>{" "}
                                <td className="fw-bold text-center">
                                  {" "}
                                  <div className="mt-1 symbol symbol-45px me-2">
                                    {" "}
                                    {value.pull_count}
                                    {" "}
                                  </div>{" "}
                                </td>{" "}
                                <td></td>
                                <td className="text-center">
                                  {" "}
                                  {/* <Link to={"/cloud/" + value.id}> */}
                                  <span

                                    className="fs-6 fw-bold"
                                  >
                                    {(value.creation_time)}
                                  </span>
                                  {/* </Link> */}
                                </td>{" "}
                                <td className="text-end">
                                  <div className="card-toolbar">
                                    <button
                                      type="button"
                                      onClick={() => {
                                        // openDrawer()
                                        openDrawerForUpdate()
                                        // findObjectForUpdate(value.name)
                                      }}
                                      className="btn  btn-icon btn-color-primary btn-active-light-primary"
                                    >
                                      <span className="svg-icon svg-icon-1 d-none">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <path
                                            opacity="0.3"
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z"
                                            fill="black"
                                          />
                                          <path
                                            d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z"
                                            fill="black"
                                          />
                                          <path
                                            d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z"
                                            fill="black"
                                          />
                                        </svg>

                                      </span>
                                    </button>
                                    <button
                                      type="button"
                                      className="btn  btn-icon btn-color-danger btn-active-light-danger"
                                      id={`delete-registry-btn-${extractString(value.name)}`}
                                      onClick={() => handleDeleteRegistry(extractString(value.name), index)}
                                    >
                                      <span className="svg-icon svg-icon-1">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <path
                                            d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                            fill="black"
                                          />
                                          <path
                                            opacity="0.5"
                                            d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                            fill="black"
                                          />
                                          <path
                                            opacity="0.5"
                                            d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                            fill="black"
                                          />
                                        </svg>
                                      </span>
                                    </button>
                                  </div>
                                </td>{" "}
                              </tr>
                            ))) :
                              <tr>
                                <td colspan="12" className="" id="no_record" valign="top">
                                  <div className="d-flex flex-column flex-center">
                                    <div className="fs-5 fw-bolder text-dark mb-4">No repository found.</div>
                                  </div>
                                </td>
                              </tr> :
                              <tr>
                                <td colspan="12" className="" id="no_record" valign="top">
                                  <div className="d-flex flex-column flex-center">
                                    <div className="fs-5 fw-bolder text-dark mb-4">No repository found.</div>
                                  </div>
                                </td>
                              </tr>
                            }

                          </tbody>
                        </table>
                      </div>
                      {/*end::Table wrapper*/}
                    </div>
                    {/*end::Card body*/}
                  </div>
                  {/*end::License usage*/}
                </div>
                <div className="tab-pane fade" id="destroy_vpc" role="tabpanel">
                  {/*begin::Deactivate Account*/}
                  <div className="card">
                    {/*begin::Card header*/}
                    <div
                      className="card-header border-0 cursor-pointer"
                      role="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#kt_account_deactivate"
                      aria-expanded="true"
                      aria-controls="kt_account_deactivate"
                    >
                      <div className="card-title m-0">
                        <h3 className="fw-bold m-0">Destroy Registry</h3>
                      </div>
                      <div className="card-toolbar">
                        <div className="position-relative my-1" style={{ marginRight: 20 }}>
                          <CustomDoc url={ProductsUrl[0]?.container_registry + "/manage-cr/#destroy"} />
                        </div>
                      </div>
                    </div>
                    {/*end::Card header*/}
                    {/*begin::Content*/}
                    <div id="kt_account_settings_deactivate" className="collapse show">
                      {/*begin::Form*/}
                      <form id="destroyform" className="form">
                        {/*begin::Card body*/}
                        <div className="card-body border-top p-9">
                          {/*begin::Notice*/}
                          <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
                            {/*begin::Icon*/}
                            {/*begin::Svg Icon | path: icons/duotune/general/gen044.svg*/}
                            <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <rect
                                  opacity="0.3"
                                  x={2}
                                  y={2}
                                  width={20}
                                  height={20}
                                  rx={10}
                                  fill="black"
                                />
                                <rect
                                  x={11}
                                  y={14}
                                  width={7}
                                  height={2}
                                  rx={1}
                                  transform="rotate(-90 11 14)"
                                  fill="black"
                                />
                                <rect
                                  x={11}
                                  y={17}
                                  width={2}
                                  height={2}
                                  rx={1}
                                  transform="rotate(-90 11 17)"
                                  fill="black"
                                />
                              </svg>
                            </span>
                            {/*end::Svg Icon*/}
                            {/*end::Icon*/}
                            {/*begin::Wrapper*/}
                            <div className="d-flex flex-stack flex-grow-1">
                              {/*begin::Content*/}
                              <div className="fw-bold">
                                <h4 className="text-gray-900 fw-bold">
                                  This will destroy your Registry.
                                </h4>
                                <div className="fs-6 text-gray-700">
                                  Note: This option will delete your Registry from the server
                                  for permanently and this not be able to undo.
                                </div>
                              </div>
                              {/*end::Content*/}
                            </div>
                            {/*end::Wrapper*/}
                          </div>
                          {/*end::Notice*/}
                          {/*begin::Form input row*/}
                          <div className="mb-10 d-none">
                            <label htmlFor="size" className="required form-label">
                              Your Registry will be permanently destroyed. You will lose the
                              provisioned Network addresses.
                            </label>
                          </div>
                          {/*end::Form input row*/}
                        </div>
                        {/*end::Card body*/}
                        {/*begin::Card footer*/}
                        <div
                          className="card-footer d-flex justify-content-end py-6 px-9"
                          id="destroy_btn"
                        >
                          <button
                            id="destroy-btn"
                            onClick={() => destroyregistry(id)}
                            // onclick="call_api('DELETE','vpc/undefined/destroy','destroy','refdestroy','#vpc')"
                            type="button"
                            className="btn btn-danger fw-bold"
                          >
                            Destroy Registry
                          </button>
                        </div>
                        {/*end::Card footer*/}
                      </form>
                      {/*end::Form*/}
                    </div>
                    {/*end::Content*/}
                  </div>
                  {/*end::Deactivate Account*/}
                </div>
              </div>

              {pageStatus &&
                <TopBarHeaderLine />}
            </div>
          </div>
        </>) : (<><TopBarHeaderLine /></>)}
      </div>
      <div id="drawer_overlay_connection" onClick={openDrawer} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>

      <div
        id="connection-modal"
        className="bg-body shadow drawer drawer-end"
        // data-kt-drawer="true"
        // data-kt-drawer-name="chat"
        // data-kt-drawer-activate="true"
        // data-kt-drawer-direction="end"
        data-kt-drawer-close="#close_ssh"
        style={{ important: true, display: 'block !important', zIndex: 110 }}
      >

        <div className="mb-2">
          {/*begin::Card Body*/}
          <div className="card-body fs-6 text-gray-700">
            {/*begin::Section*/}
            <div className="pt-3">
              {/*begin::Heading*/}
              <div className="card-toolbar d-flex">
                <h1 className=" fw-bolder mb-0" id="rtl-version">
                  <a href="#rtl-version" />
                  Push Commands
                  {/* <div className="card-toolbar"> */}
                  {/*begin::Close*/}
                </h1>
                <div
                  onClick={openDrawer}
                  className="btn btn-sm btn-icon btn-active-light-primary d-flex"
                  id="close_ssh"
                  style={{ marginLeft: 'auto' }}
                >
                  {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                  <span className="svg-icon svg-icon-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.5"
                        x={6}
                        y="17.3137"
                        width={16}
                        height={2}
                        rx={1}
                        transform="rotate(-45 6 17.3137)"
                        fill="black"
                      />
                      <rect
                        x="7.41422"
                        y={6}
                        width={16}
                        height={2}
                        rx={1}
                        transform="rotate(45 7.41422 6)"
                        fill="black"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                </div>
              </div>

              {/*end::Close*/}
              {/* </div> */}
              {/* end::Heading */}
              {/*begin::Block*/}
              <div className="py-0">
                <p className="mb-0">
                  Command references for pushing an artifact to this registry.
                </p>
              </div>
              {/*end::Block*/}
              {/*begin::List*/}
              <div className="separator border-2 my-10"></div>
              <ol className="mb-2">
                <li className="py-3">
                  <h5>Docker Push Command</h5>
                  Login to Utho Registry:
                  <div className="py-5">
                    {/*begin::Highlight*/}
                    <div className="highlight">
                      <button
                        className="highlight-copy btn"
                        data-bs-toggle="tooltip"
                        title=""
                        data-bs-original-title="Copy code"
                        style={{ important: true, display: 'block' }}
                        id="docker_push_command_login_btn1"
                        onClick={() => copyToClipboard('container_string_login', 'docker_push_command_login_btn1')}
                      >
                        copy
                      </button>
                      <div className="highlight-code">
                        <pre className=" language-bash" tabIndex={0}>
                          <code className=" language-bash" id="container_string_login">docker login registry.utho.io</code>
                        </pre>
                      </div>
                    </div>
                    {/*end::Highlight*/}
                  </div>


                  Create build image for this registry::
                  <div className="py-5">
                    {/*begin::Highlight*/}
                    <div className="highlight">
                      <button
                        className="highlight-copy btn"
                        data-bs-toggle="tooltip"
                        title=""
                        data-bs-original-title="Copy code"
                        style={{ important: true, display: 'block' }}
                        id="docker_push_command_build_btn1"
                        onClick={() => copyToClipboard('container_string_build', 'docker_push_command_build_btn1')}
                      >
                        copy
                      </button>
                      <div className="highlight-code">
                        <pre className=" language-bash" tabIndex={0}>
                          <code className=" language-bash" id="container_string_build">docker build -t [NAME]:latest .
                          </code>
                        </pre>
                      </div>
                    </div>
                    {/*end::Highlight*/}
                  </div>
                  Tag an image for this registry:
                  <div className="py-5">
                    {/*begin::Highlight*/}
                    <div className="highlight">
                      <button
                        className="highlight-copy btn"
                        data-bs-toggle="tooltip"
                        title=""
                        data-bs-original-title="Copy code"
                        style={{ important: true, display: 'block' }}
                        id="docker_push_command_btn1"
                        onClick={() => copyToClipboard('container_string_value1', 'docker_push_command_btn1')}
                      >
                        copy
                      </button>
                      <div className="highlight-code">
                        <pre className=" language-bash" tabIndex={0}>
                          <code className=" language-bash" id="container_string_value1">docker tag SOURCE_IMAGE[:TAG] registry.utho.io/{objectForUpdate?.name}/REPOSITORY[:TAG]</code>
                        </pre>
                      </div>
                    </div>
                    {/*end::Highlight*/}
                  </div>
                  Push an image to this registry:
                  <div className="py-5">
                    {/*begin::Highlight*/}
                    <div className="highlight">
                      <button
                        className="highlight-copy btn"
                        data-bs-toggle="tooltip"
                        title=""
                        style={{ important: true, display: 'block' }}
                        data-bs-original-title="Copy code"
                        id="docker_push_command_btn2"
                        onClick={() => copyToClipboard('container_string_value2', 'docker_push_command_btn2')}
                      >
                        copy
                      </button>
                      <div className="highlight-code">
                        <pre className=" language-bash" tabIndex={0}>
                          <code className=" language-bash" id="container_string_value2">
                            docker push registry.utho.io/{objectForUpdate?.name}/REPOSITORY[:TAG]
                          </code>
                        </pre>
                      </div>
                    </div>
                    {/*end::Highlight*/}
                  </div>
                </li>
                <li className="py-3">
                  <h5>Podman Push Command</h5>

                  Push an image to this registry:
                  <div className="py-5">
                    {/*begin::Highlight*/}
                    <div className="highlight">
                      <button
                        className="highlight-copy btn"
                        data-bs-toggle="tooltip"
                        title=""
                        style={{ important: true, display: 'block' }}
                        data-bs-original-title="Copy code"
                        id="docker_push_command_btn3"
                        onClick={() => copyToClipboard('container_string_value3', 'docker_push_command_btn3')}
                      >
                        copy
                      </button>
                      <div className="highlight-code">
                        <pre className=" language-bash" tabIndex={0}>
                          <code className=" language-bash" id="container_string_value3">
                            podman push IMAGE_ID registry.utho.io/{objectForUpdate?.name}/REPOSITORY[:TAG]
                          </code>
                        </pre>
                      </div>
                    </div>
                    {/*end::Highlight*/}
                  </div>
                </li>
                <li className="py-3">
                  <h5>Helm Push Command</h5>
                  Package a chart for this registry:
                  <div className="py-5">
                    {/*begin::Highlight*/}
                    <div className="highlight">
                      <button
                        className="highlight-copy btn"
                        data-bs-toggle="tooltip"
                        title=""
                        style={{ important: true, display: 'block' }}
                        data-bs-original-title="Copy code"
                        id="docker_push_command_btn4"
                        onClick={() => copyToClipboard('container_string_value4', 'docker_push_command_btn4')}
                      >
                        copy
                      </button>
                      <div className="highlight-code">
                        <pre className=" language-bash" tabIndex={0}>
                          <code className=" language-bash" id="container_string_value4">
                            helm package CHART_PATH
                          </code>
                        </pre>
                      </div>
                    </div>
                    {/*end::Highlight*/}
                  </div>

                  Push a chart to this registry:
                  <div className="py-5">
                    {/*begin::Highlight*/}
                    <div className="highlight">
                      <button
                        className="highlight-copy btn"
                        data-bs-toggle="tooltip"
                        title=""
                        style={{ important: true, display: 'block' }}
                        data-bs-original-title="Copy code"
                        id="docker_push_command_btn5"
                        onClick={() => copyToClipboard('container_string_value5', 'docker_push_command_btn5')}
                      >
                        copy
                      </button>
                      <div className="highlight-code">
                        <pre className=" language-bash" tabIndex={0}>
                          <code className=" language-bash" id="container_string_value5">
                            helm push CHART_PACKAGE oci://registry.utho.io/{objectForUpdate?.name}
                          </code>
                        </pre>
                      </div>
                    </div>
                    {/*end::Highlight*/}
                  </div>
                </li>
                <li className="py-3">
                  <h5>CNAB Push Command </h5>
                  Push a CNAB to this registry:
                  <div className="py-5">
                    {/*begin::Highlight*/}
                    <div className="highlight">
                      <button
                        className="highlight-copy btn"
                        data-bs-toggle="tooltip"
                        title=""
                        style={{ important: true, display: 'block' }}
                        data-bs-original-title="Copy code"
                        id="docker_push_command_btn6"
                        onClick={() => copyToClipboard('container_string_value6', 'docker_push_command_btn6')}
                      >
                        copy
                      </button>
                      <div className="highlight-code">
                        <pre className=" language-bash" tabIndex={0}>
                          <code className=" language-bash" id="container_string_value6">
                            cnab-to-oci push CNAB_PATH --target registry.utho.io/{objectForUpdate?.name}/REPOSITORY[:TAG] --auto-update-bundle
                          </code>
                        </pre>
                      </div>
                    </div>
                    {/*end::Highlight*/}
                  </div>
                </li>
              </ol>
              {/*end::List*/}
            </div>
            {/*end::Section*/}
          </div>
          {/*end::Card Body*/}
        </div>
      </div>
      <UpdateRegistry data={objectForUpdate} getAllRegistry={getRepos} />
      <div
        className="modal fade "
        id="cr_cred"
        tabIndex={-1}
        aria-hidden="true"
      >
        {/*begin::Modal header*/}
        <div className="modal-dialog modal-dialog-centered mw-650px">
          {/*begin::Modal content*/}
          <div className="modal-content">
            {/*begin::Modal header*/}
            <div className="modal-header flex-stack">
              {/*begin::Title*/}
              <h2>Container Registry Access Credentials</h2>
              {/*end::Title*/}
              {/*begin::Close*/}
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
              >
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                <span className="svg-icon svg-icon-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x={6}
                      y="17.3137"
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(-45 6 17.3137)"
                      fill="black"
                    />
                    <rect
                      x="7.41422"
                      y={6}
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(45 7.41422 6)"
                      fill="black"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
              </div>
              {/*end::Close*/}
            </div>
            {/*begin::Modal header*/}
            {/*begin::Modal body*/}
            <div className="modal-body scroll-y pt-10 pb-15 px-lg-17">
              {/*begin::Options*/}
              <div data-kt-element="options">
                {/*begin::Notice*/}
                <p className="text-muted fs-5 fw-bold mb-10">
                  These credentials will allow you to log in, push, and pull container images as needed for your projects.            </p>
                {/*end::Notice*/}
                {/*begin::Wrapper*/}
                <div className="pb-10">
                  {/*begin::Option*/}
                  <input
                    type="radio"
                    className="btn-check"
                    name="auth_option"
                    defaultValue="apps"
                    defaultChecked="checked"
                    id="kt_modal_two_factor_authentication_option_1"
                  />
                  <label
                    className="btn btn-outline active btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-5"
                  >
                    <span className="d-block fw-bold text-start">
                      <table className="table table-flush fw-bold gy-1" style={{ height: '155px !important' }}>
                        <tbody>
                          <tr>
                            <td className="text-dark fw-bolder w-75px">Username</td>
                            <td className="text-muted w-25px">&nbsp;=</td>
                            <td className="text-gray-800 w-300px"><span id="cr_username_val">{registryUser != null ? (registryUser?.user_name) : ('')}</span>
                              {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen054.svg*/}
                              <span className="svg-icon svg-icon-primary svg-icon-2 ms-2" id="cr_username_btn" onClick={(e) => copyToClipboard('cr_username_val', 'cr_username_btn')}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    opacity="0.5"
                                    d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                                    fill="black"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                                    fill="black"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}</td>
                          </tr>
                          <tr>
                            <td className="text-dark fw-bolder w-75px">Password</td>
                            <td className="text-muted w-25px">&nbsp;=</td>
                            {/* {databaseInfo.databases()} */}
                            <td className="text-gray-800 w-175px"><span id="cr_password_val">{registryUser != null ? (registryUser?.password) : ('')}</span>
                              {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen054.svg*/}
                              <span className="svg-icon svg-icon-primary svg-icon-2 ms-2" id="cr_password_btn" onClick={(e) => copyToClipboard('cr_password_val', 'cr_password_btn')}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    opacity="0.5"
                                    d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                                    fill="black"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                                    fill="black"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}</td>
                          </tr>
                        </tbody>
                      </table>
                    </span>
                  </label>
                  {/*end::Option*/}
                </div>
                {/*end::Options*/}
                {/*begin::Action*/}
                <p className="text-muted fs-5 fw-bold mb-10">
                  Please keep these credentials secure and do not share them with unauthorized individuals. </p>
                {/*end::Action*/}
              </div>
              {/*end::Options*/}
            </div>
            {/*begin::Modal body*/}
          </div>
          {/*end::Modal content*/}
        </div>
        {/*end::Modal header*/}
      </div>
    </>
  )
}