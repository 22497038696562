import React, { useEffect } from "react";

export default function Tally() {


  return (
    <>
      <div className="col-xl-12">
        {/*begin::Tables Widget 9*/}
        <div className="card card-xl-stretch mb-5 mb-xl-12">
          {/*begin::Header*/}
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bolder fs-3 mb-1">
                Tally {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                <span className="svg-icon svg-icon-2">
                  <i className="las la-chart-pie" style={{ fontSize: 20 }} />
                </span>
                {/*end::Svg Icon*/}
              </span>
              <span className="text-muted mt-1 fw-bold fs-7">
                This addon is no longer available for new purchase, You can deploy new
                windows server and install TallyERP9.{" "}
              </span>
            </h3>
          </div>
          {/*end::Header*/}
          {/*begin::Body*/}
          {/*begin::Table container*/}
          <div className="table-responsive">
            {/*begin::Table*/}
            <table className="table table-striped table-row-dashed table-row-gray-400 align-middle gs-0 gy-4">
              {/*begin::Table head*/}
              <thead className="bg-secondary">
                <tr className="fw-bolder">
                  <th className="w-165px w-125px text-center">#Tally</th>
                  <th className="w-165px w-125px">Location</th>
                  <th className="w-165px w-125px">Name</th>
                  <th className="w-165px w-125px">Users</th>
                  <th className="w-165px w-125px">Gateway</th>
                  <th className="w-125px w-165px">Created</th>
                </tr>
              </thead>
              {/*end::Table head*/}
              {/*begin::Table body*/}
              <tbody className="fs-6 fw-bold text-gray-600" id="tally-list">
                <tr>
                  <td colSpan={12} className="dataTables_empty" valign="top">
                    <div className="d-flex flex-column flex-center">
                      <img
                        src="https://cloud.microhost.com/assets/media/illustrations/dozzy-1/5.png"
                        className="mw-400px"
                      />
                      <div className="fs-1 fw-bolder text-dark mb-4">
                        No items found.
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
              {/*end::Table body*/}
            </table>
            {/*end::Table*/}
          </div>
          {/*end::Table container*/}
          {/*begin::Body*/}
        </div>
        {/*end::Tables Widget 9*/}
      </div>

    </>
  )
}