import React, { useEffect, useState } from "react"; 
import ViewInvoicesTab from "./sections/ViewInvoicesTab";  
export default function Billing() {   
    return(
        <>  
        <div className="col-xl-12">       
        <ViewInvoicesTab /> 
      
  {/*end::Col*/} 
  <input
    type="hidden"
    name="merchant_order_id"
    id="merchant_order_id"
    defaultValue=""
  /> 
  </div>

        </>
    )
 }