import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom/dist";
import UsePost from "../../../../CustomHooks/usePost";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UseApi from "../../../../CustomHooks/useApi";
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../Loaders/TableLoader";
import GenerateLangCode from "../../utils/generate-code/GenerateLangCode";
import { handleInputChange } from "../../utils/utils";

export default function VpcDeploy() {
  const [ddata, setDdata] = useState([]);
  const [location, setLocation] = useState("innoida")
  const [pageStatus, setPageStatus] = useState(false);
  const dataFetchedRef = useRef();
  // const dczonesData=useRef();
  let navigate = useNavigate();
  const deployVpc = async () => {
    var submitBtn = document.querySelector("#vpc-btn");
    if (submitBtn) {
      setPageStatus(true);
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML =
        '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      toast.dismiss();
      const dt = toast.loading("Please wait...");
      const responseData = await UsePost("post", "vpc/create", "createvpc");
      if (responseData) {
        setPageStatus(false);
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
        if (responseData.status === "error") {
          toast.update(dt, {
            render: responseData.message,
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (responseData.status === "success") {
          toast.update(dt, {
            render: responseData.message,
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          window.onDeployEvents('deploy_vpc');
          navigate("/vpc/" + responseData.id);
        }
      }
    }
  };
  useEffect(() => {
    window.addEventListener("keydown", function (event) {
      event.stopPropagation();
      var query = window.location.href;
      var vars = query.split("/");
      var action = vars[4];
      if (event.key == "Enter" && action == "deploy" && vars[3] == "vpc") {
        deployVpc();
      }
    });
  }, []);
  const getDczone = async () => {
    let arr = [];
    const data = await UseApi("get", "dczones", "");
    if (data) {
      if (Object.keys(data).length === 0) {
        setDdata(null);
      } else {
        // var dcArr=[];
        data.dczones.map((value, index) => {
          if (value.status === "active" && value.product_vpc === "1") {
            arr.push(value);
            // dcArr.push(value);
            setDdata(arr);
            // ddata.push(value);
          }
        });
        // setDdata(dcArr);
      }
    }
  };
  useEffect(() => {
    // if (dataFetchedRef.current) return;
    //   dataFetchedRef.current = true;
    toast.dismiss();
    getDczone();
    selectDc("innoida")
  }, []);

  const selectDc = async (slug) => {
    var dczone_slug = document.querySelector("#dcslug" + slug);
    if (dczone_slug) {
      dczone_slug.value = slug;
    }
    setLocation(slug)
    var dcSelect = document.querySelectorAll([".tickMark"]);
    var currentDc = document.querySelector("#tickMark-" + slug);
    if (dcSelect) {
      dcSelect.forEach((clist) => {
        clist.classList.add("d-none");
      });
    }
    if (currentDc) {
      currentDc.classList.remove("d-none");
    }
  }

  // const handleInputChange = (e, regex, inputName) => {
  //   const inputValue = e.target.value;
  //   // Regex pattern to allow only numeric characters
  //   const filteredValue = inputValue.replace(regex, ''); // \D matches any non-digit character
  //   // Update the input value with only the allowed characters
  //   e.target.value = filteredValue;
  // };
  return (
    <>
      {ddata.length > 0 ? (
        <>
          <form id="createvpc" className="form">
            <div className="col-xl-12 card mb-5">
              <div className="card-header border-3 mb-5 pt-2">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bolder text-gray-800">
                    Select DC Location
                  </span>
                  <span className="text-gray-400 mt-1 fw-bold fs-6">
                    Data Centers
                  </span>
                </h3>
              </div>

              {/* Begin body */}
              <div className="mx-5 mb-3">
                {/*begin::Row*/}
                <div
                  id="dc-location"
                  className="row g-9"
                  data-kt-buttons="true"
                  data-kt-buttons-target="[data-kt-button]"
                >
                  {ddata.length > 0 ? (
                    <>
                      {ddata.map((key, value) => {
                        return (
                          <>
                            <div className="col-md-4 col-lg-4 col-xxl-4 dc_sibling">
                              <input
                                className="form-check-input deploy_checkbox "
                                type="radio"
                                name="dcslug"
                                checked={key.slug === location}
                                defaultValue={key.slug}
                                id={"dcslug" + key.slug}
                              />
                              <label
                                onClick={() => selectDc(key.slug)}
                                style={{ WebkitJustifyContent: "unset" }}
                                id={"dcslugl_" + key.slug + ""}
                                htmlFor={"dcslug" + key.slug + ""}
                                className="position-relative dcsluglable border-1 border-gray-300 border px-1 py-1"
                              >
                                {/*begin::Info*/}
                                <div className="d-flex flex-stack mb-3 d-none">
                                  {/*begin::Wrapper*/}
                                  <div className="me-3 p-3">
                                    {/*begin::Icon*/}
                                    <img
                                      src={`/assets/media/flags/svg/${key.cc}.svg`}
                                      className="w-35px ms-n1 me-6"
                                      alt=""
                                    />
                                    {/*end::Icon*/}
                                    {/*begin::Title*/}
                                    <a className="text-gray-800 text-hover-primary fw-bolder">
                                      {key.city}
                                    </a>
                                    {/*end::Title*/}
                                  </div>
                                  {/*end::Wrapper*/}
                                  {/*begin::Action*/}
                                  <div className="me-3">
                                    {/*begin::Menu*/}
                                    <span className=" text-muted fw-bold justify-content-end">
                                      {key.country}
                                    </span>
                                    {/*end::Menu*/}
                                  </div>
                                  {/*end::Action*/}
                                </div>
                                <div className=" child">
                                  <div className="p-3 ">
                                    <div className="row">
                                      <div className="col-4">
                                        <img
                                          src={`/assets/media/flags/svg/${key.cc}.svg`}
                                          className="w-50px "
                                          alt=""
                                        />
                                      </div>
                                      <div className="col-8">
                                        <p className="no-margin text-dark fw-bolder text-hover-primary ">
                                          {key.city}
                                          <span className="text-muted d-block fw-bold ">
                                            {key.country}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/*end::Info*/}
                                <div
                                  className={`tickMarkDc ribbon ribbon-triangle ribbon-top-start border-primary ${key.slug === location ? "" : 'd-none'}`}
                                  id={"tickMarkDc-" + key.slug}
                                >
                                  <div className="ribbon-icon mt-n5">
                                    <i className="bi bi-check2 fs-2 text-white" />
                                  </div>
                                </div>
                                {/*begin::Customer*/}
                                {/*end::Customer*/}
                              </label>
                            </div>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <div className="small-spinner"></div>
                    </>
                  )}
                </div>
                {/*end::Row*/}
              </div>
            </div>
            <div className="col-xl-12 card mb-5">
              <div className="card-header border-3 mb-5 pt-2">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bolder text-gray-800">
                    Define VPC Network
                  </span>
                  <span className="text-gray-400 mt-1 fw-bold fs-6">
                    Configure Your VPC
                  </span>
                </h3>
              </div>

              <div className="mx-5 mb-3">
                {/*begin::Row*/}
                <div
                  id="define-vpc"
                  className="row g-9"
                  data-kt-buttons="true"
                  data-kt-buttons-target="[data-kt-button]"
                >
                  <div className="col-lg-4 col-md-4 col-xxl-4 fw-bold">
                    <label htmlFor="vpcName">VPC Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter vpc name"
                      name="name"
                      id="name"
                      onChange={(e) => handleInputChange(e,false, true)}
                    />
                  </div>
                  <div className="col-lg-4 col-md-4 col-xxl-4 fw-bold">
                    <label htmlFor="vpcName">Network</label>
                    <input
                      type="hidden"
                      name="planid"
                      id="planid"
                      defaultValue={1008}
                    />
                    <input
                      type="text"
                      className="form-control"
                      name="network"
                      id="network"
                      onchange="validatevpc();"
                      defaultValue="10.210.100.0"
                      onChange={(event) => {
                        let { value } = event.target;

                        // Remove any characters that are not digits or periods
                        value = value.replace(/[^\d.]/g, '');
        
                        // Split the value into parts separated by periods
                        const parts = value.split('.');
        
                        // Ensure that each part is a number between 0 and 255
                        if (parts.length > 4) {
                            // Limit to 4 parts
                            parts.length = 4;
                        } else {
                            parts.forEach((part, index) => {
                                let num = parseInt(part, 10);
                                if (isNaN(num) || num < 0 || num > 255) {
                                    parts[index] = '';
                                }
                            });
                        }
        
                        // Update the input value
                        event.target.value = parts.join('.');
                    // }}/
        
                    }}
        
                      // onKeyDown={(e) => {
                      //   // Validate the input value using a regular expression for IP addresses
                      //   const isValidIP = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(e.target.value);

                      //   // If the input value is not a valid IP address, prevent further input
                      //   if (!isValidIP) {
                      //     e.target.value = e.target.value.slice(0, -1); // Remove the last character
                      //   }
                      // }}
                    />
                  </div>
                  <div className="col-lg-4 col-md-4 col-xxl-4 fw-bold">
                    <label htmlFor="vpcName">Network Size</label>
                    <input
                      type="number"
                      className="form-control"
                      name="size"
                      id="size"
                      onchange="validatevpc();"
                      defaultValue={24}
                      min={1}
                    />
                  </div>
                </div>
                {/*end::Row*/}
              </div>
            </div>
            <div className="col-xl-12 card mb-5">
              <div className="card-header border-3 mb-5 pt-2">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bolder text-gray-800">
                    Deploy VPC
                  </span>
                  <span className="text-gray-400 mt-1 fw-bold fs-6">
                    Click the below button to deploy your VPC
                  </span>
                </h3>
              </div> 

              <div className="mx-5 p-5">
                {/*begin::Row*/}
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ width: "100%" }}
                  onClick={deployVpc}
                  id="vpc-btn"
                >
                  Deploy VPC +
                </button>
                {/*end::Row*/}
              </div>
              {pageStatus && <TopBarHeaderLine />}
            </div>

            {/*end::Tables Widget 9*/}
          </form>
        </>
      ) : (
        <>
          <TopBarHeaderLine />
          <TableLoader />
        </>
      )}
    </>
  );
}
