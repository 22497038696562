import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import AccessDenied from "../../AccessDenied";
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../Loaders/TableLoader";
import CustomDoc from "../../../../CustomHooks/CustomDoc";
import ProductsUrl from "../../Docs/Docsdata";
export default function Vpn() {
  const [dataFetched, setDataFetched] = useState(false);
  const [access, setAccess] = useState(true);
  const [perPage, setPerPage] = useState(5);
  const [vpnData, setVpnData] = useState(null);
  const [dummyVpnData, setDummyVpnData] = useState(null);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [activePage, setActivePage] = useState(0);
  const dataFetchedRef = useRef(false);
  const loaderFunc = () => {
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if (loader && no_record) {
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");

      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
  }
  const getVPN = async () => {

    const dt = toast
    const data = await UseApi('get', 'vpn', '');
    if (data) {
      setDataFetched(true);
    }
    if ('status' in data) {
      if (data.status == "error" && data.message == "Permission denied.") {
        toast.update(dt, {
          render: data.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        setDataFetched(true)
        setAccess(false);
        setVpnData([]);
        setDummyVpnData([])
        // loaderFunc();
        return;
      }
    }
    if (Object.keys(data).length === 0) {
      setDataFetched(true)
      setVpnData(null);
      setDummyVpnData(null)
      toast.update(dt, {
        render: "no record found", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      // loaderFunc();
    } else {
      setDataFetched(true)
      setVpnData(data.vpn);
      setDummyVpnData(data.vpn)
      setPageCount(Math.ceil(data.vpn.length / perPage));
      toast.dismiss();
      //   toast.update(dt,{render: "Your VPN request has been successful", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      // });  

    }
  }
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getVPN();
  }, []);



  function ProductInfo({ productdata }) {
    return (
      <>
        {productdata != null && Object.keys(productdata).length != 0 ? (productdata && productdata.map((value, index) => (
          <tr className="text-muted">
            {" "}
            <td className="text-start ps-9">
              {" "}
              <div className="d-flex">
                {" "}
                {value.powerstatus == 'Running' ? (<a className=" btn btn-sm btn-success">Running</a>) : (<a className=" btn btn-sm btn-danger">Inactive</a>)}
                {" "}
              </div>{" "}
            </td>{" "}
            <td className="text-center">
              <div className="d-flex align-items-center">
                {" "}
                <div className="symbol symbol-30px me-3 ms-2">
                  {" "}
                  <img src={"/img/flag_" + value.dclocation.dccc + ".png"} />{" "}
                </div>{" "}
                <div className="d-flex justify-content-start flex-column">
                  {" "}
                  <a className="text-dark  fw-bold text-hover-primary fs-6">
                    {value.dclocation.location}
                  </a>{" "}
                  <span className=" me-2 fs-7 text-dark fw-bold">{value.dclocation.country}</span>
                </div>{" "}
              </div>{" "}
            </td>{" "}
            <td className="text-center">
              {" "}
              <div className="d-flex flex-column w-100 me-2 mt-1">
                {" "}
                <div className="flex-stack mb-2">
                  {" "}
                  <span className=" me-2 fs-7 text-dark fw-bold">
                    {value.name}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
            </td>{" "}
            <td>
              {" "}
              <div className="d-flex flex-column content-justify-center w-100">
                {" "}
                <div className="d-flex fs-6 fw-bold align-items-center">
                  {" "}
                  <div className="bullet w-8px h-6px rounded-2 bg-danger me-3" />{" "}
                  <div className="text-gray-500 flex-grow-1 me-4">Created</div>{" "}
                  <div className="fw-boldest text-gray-700 text-xxl-end">{value.accountcreated}</div>
                </div>{" "}
                <div className="d-flex fs-6 fw-bold align-items-center my-3">
                  {" "}
                  <div className="bullet w-8px h-6px rounded-2 bg-primary me-3" />{" "}
                  <div className="text-gray-500 flex-grow-1 me-4">
                    Purchased
                  </div>{" "}
                  <div className="fw-boldest text-gray-700 text-xxl-end">{value.accountlimit}</div>
                </div>
              </div>{" "}
            </td>{" "}
            <td className="text-center ps-7">
              {" "}
              <div className="mb-2">
                <span className="text-dark me-2 fs-7 fw-bold">
                  {value.ip}
                </span>{" "}
              </div>
            </td>{" "}
            <td className="text-center">
              {" "}
              <div className="mt-10">
                <span className="text-dark me-2 fs-7 fw-bold">
                  {value.created_at}
                </span>{" "}
              </div>
              {/* <a className="p-1 text-end fs-7 fw-bold text-dark mt-4">
                {value.created_at}
              </a> */}
              <br />
              <br />{" "}
              <div
                className="p-1 text-end badge badge-success rounded d-none"
              >
                Due at: {value.nextduedate}
              </div>{" "}
            </td>{" "}
            <td className="text-end pe-5 sphide">
              {" "}
              <div className="d-flex justify-content-end flex-shrink-0 mb-2">
                {" "}
                <Link to={"/vpn/" + value.id + ""}>
                  <a
                    className="btn btn-primary btn-active-light-primary btn-sm me-1 me-5 "
                  >
                    {" "}
                    Manage{" "}
                    <span className="svg-icon ms-2 svg-icon-3">
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        {" "}
                        <rect
                          opacity="0.5"
                          x={18}
                          y={13}
                          width={13}
                          height={2}
                          rx={1}
                          transform="rotate(-180 18 13)"
                          fill="black"
                        />{" "}
                        <path
                          d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                          fill="black"
                        />{" "}
                      </svg>{" "}
                    </span>{" "}
                  </a>{" "}
                </Link>
              </div>{" "}
            </td>{" "}
          </tr>
        ))) : (<>
          <tr>
            {/* <td colspan="12" className="dataTables_empty" id="loader"> */}
              {/* <div className="loading-spinner"></div> */}
              {/* <TableLoader /> */}
            {/* </td> */}
            <td colspan="12" className="" id="no_record" valign="top">
              <div className="d-flex flex-column flex-center">
                <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
              </div>
            </td>
          </tr>
        </>)}


      </>
    )
  }

  const handleSearch = (text) => {
    const endOffset = itemOffset + perPage;
    const data = vpnData.filter(row => {
      console.log(row);
      return Object.values(row).some(value => {
        if (value != null) {
          if (value.toString().toLowerCase().includes(text.toLowerCase())) {
            return value;
          }
        }
      });
    });
    setDummyVpnData(data);;
    setPageCount(Math.ceil(data.length / perPage));
    // }
  }

  function PaginatedItems({ itemsPerPage, ProductData, search }) {
    const endOffset = itemOffset + parseInt(itemsPerPage);
    setPageCount(Math.ceil(ProductData.length / itemsPerPage));
    const handlePageClick = (event) => {
      setActivePage((old) => old = event.selected);
      const newOffset = event.selected * itemsPerPage % ProductData.length;
      setItemOffset(newOffset);
    };
    return (
      <>
        <ProductInfo productdata={ProductData.slice(itemOffset, endOffset)} />
        <td colspan="12" className="ps-3">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="< previous"
            initialPage={activePage}
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </td>
      </>
    );


  }
  //       if(dataFetched){
  //         if(access==false){
  //           return (
  //             <>
  //              <AccessDenied />
  //             </>
  //           );
  //         }
  //  if(vpnData!=null){
  //   if(Object.keys(vpnData).length!=0){
  if (dataFetched) {
    if (access) {
      return (
        <>
          <div className="col-xl-12">
            {/*begin::Tables Widget 9*/}
            <div className="card card-xl-stretch mb-5 mb-xl-12">
              {/*begin::Header*/}
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label  fw-bold fs-3 mb-1"> 
                    Enterprise VPN
                  </span>
                 </h3>
                <div
                  className="card-toolbar"
                >
                   <div className="position-relative my-1" style={{ marginRight: 20 }}>
                        <CustomDoc url={ProductsUrl[0]?.vpn+"/getting-started/"}/>
                    </div>
                  <div className="position-relative my-1" style={{ marginRight: 20 }}>
                    <input
                      type="text"
                      className={`form-control form-control-sm ${vpnData.length > 0 ?"":"disabled"}`}
                      data-kt-table-widget-4="search"
                      id="searchClient"
                      style={{padding:"0.6rem"}}
                      onChange={e => handleSearch(e.target.value)}
                      placeholder="Search"
                    />
                  </div>
                  <div style={{ marginRight: 20 }}>
                    <div className="dataTables_length">
                      <label className="w-125px">
                        <select
                          onChange={e => setPerPage(e.target.value)}
                          style={{padding:"0.6rem"}}
                          //data-control="select2"
                          className="form-select form-select-sm form-select-solid"
                          defaultValue={5}
                          id="perPage"

                        >
                          <option value={5} selected="">
                            5 Records
                          </option>
                          <option value={10}>10 Records</option>
                          <option value={25}>25 Records</option>
                          <option value={50}>50 Records</option>
                          <option value={100}>100 Records</option>
                        </select>
                      </label>
                    </div>
                  </div>
                  <Link to="/vpn/deploy">
                    <a

                      className="btn btn-sm btn-primary btn-active-light-primary"
                    >
                      {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
                      <span className="svg-icon svg-icon-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <rect
                            opacity="0.5"
                            x="11.364"
                            y="20.364"
                            width={16}
                            height={2}
                            rx={1}
                            transform="rotate(-90 11.364 20.364)"
                            fill="black"
                          />
                          <rect
                            x="4.36396"
                            y="11.364"
                            width={16}
                            height={2}
                            rx={1}
                            fill="black"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}Deploy New VPN
                    </a>
                  </Link>
                </div>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              {/*begin::Table container*/}
              <div className="table-responsive">
                {/*begin::Table*/}
                <table
                  id="vpnTable"
                  className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
                >
                  {/*begin::Table head*/}
                  <thead className="bg-secondary">
                    <tr className=" fw-bold ">
                      <th className="w-50px text-start ps-9">#VPN</th>
                      <th className=" w-150px text-center">Location</th>
                      <th className=" w-100px text-center">Name</th>
                      <th className="text-center w-100px">Users</th>
                      <th className=" text-center w-150px">Gateway</th>
                      <th className=" text-center w-150px">Created_at</th>
                      <th className="w-100px text-center">Action</th>
                    </tr>
                  </thead>
                  {/*end::Table head*/}
                  {/*begin::Table body*/}
                  <tbody>
                    {vpnData != null ? (<>
                      {access == false ? (<>
                        <tr>
                          <td colspan={12}>
                            <AccessDenied />
                          </td>
                        </tr>
                      </>) : (<>
                        {Object.keys(vpnData).length != 0 ? (<>
                          <PaginatedItems ProductData={dummyVpnData} itemsPerPage={perPage} />
                        </>) : (<>
                          <tr>
                            <td colspan={12}>
                              <div className="card-body p-0 mt-10">
                                {/*begin::Illustration*/}
                                <div className="text-center px-4 mt-10">
                                  <span className="mw-100 mh-300px">
                                    <svg
                                      viewBox="0 0 34 34"
                                      width="234px"
                                      height="230px"
                                      fill="#21325B"
                                      version="1.1"
                                      xmlns="http://www.w3.org/2000/svg"
                                      stroke="#21325B"
                                    >
                                      <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                                      <g id="SVGRepo_iconCarrier">
                                        {" "}
                                        <title>lock</title>{" "}
                                        <path d="M4 30.016q0 0.832 0.576 1.408t1.44 0.576h20q0.8 0 1.408-0.576t0.576-1.408v-14.016q0-0.832-0.576-1.408t-1.408-0.576v-4q0-2.048-0.8-3.872t-2.144-3.2-3.2-2.144-3.872-0.8q-2.72 0-5.024 1.344t-3.616 3.648-1.344 5.024v4q-0.832 0-1.44 0.576t-0.576 1.408v14.016zM8 28v-9.984h16v9.984h-16zM10.016 14.016v-4q0-2.496 1.728-4.256t4.256-1.76 4.256 1.76 1.76 4.256v4h-12z" />{" "}
                                      </g>
                                    </svg>


                                  </span>
                                </div>
                                {/*end::Illustration*/}
                                {/*begin::Wrapper*/}
                                <div className="card-px text-center py-5 mb-20">
                                  {/*begin::Title*/}
                                  <h1 className="fs-2x fw-bolder mb-5 pe-5">VPN</h1>
                                  <h2 className="fs-1x fw-bolder mb-5 text-muted">
                                    Secure your online communications
                                  </h2>
                                  {/*end::Title*/}
                                  {/*begin::Description*/}
                                  <p className="text-gray-400 fs-4 fw-bold mb-10">
                                    Deploy VPN for a secure and encrypted tunnel for online traffic. Surf securely.                  </p>
                                  {/*end::Description*/}
                                  {/*begin::Action*/}
                                  <Link to="/vpn/deploy">
                                    <a
                                      href="#"
                                      className="btn btn-primary mb-10"
                                    >
                                      Create VPN
                                    </a>
                                  </Link>
                                  {/*end::Action*/}
                                </div>
                                {/*end::Wrapper*/}
                              </div>
                            </td>
                          </tr>
                        </>)}
                      </>)}
                    </>) : (<>
                      <tr>
                        <td colspan={12}>
                          <TableLoader />
                          <TopBarHeaderLine />
                        </td>
                      </tr>

                    </>)}

                  </tbody>
                  {/*end::Table body*/}
                </table>
                {/*end::Table*/}
              </div>
              {/*end::Table container*/}
              {/*begin::Body*/}
            </div>
            {/*end::Tables Widget 9*/}
          </div>
        </>
      )
    } else {
      return (
        <>
          <AccessDenied />
        </>
      )
    }
  } else {
    return (
      <>
        <TableLoader />
      </>
    )
  }
}
//   }
// }
// }