import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UseApi from "../../../../../CustomHooks/useApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import copy from "copy-to-clipboard";
import TopBarHeaderLine from "../../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../../Loaders/TableLoader";
import CreateSubnet from "./CreateSubnet";
import { capitalizeFirstLetter } from "../../../utils/utils";

export default function Subnet() {
  const [dataFetched, setDataFetched] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [itemOffset, setItemOffset] = useState(0);
  const [activePage, setActivePage] = useState(0)
  let navigate = useNavigate()

  const copyToClipboard = (id) => {
    var ip_address = document.querySelector(`#vpc_ip_address_sec${id}`)
    var ele = document.querySelector("#vpcIpIcon_" + id);
    var eleText = document.querySelector("#vpc_ip_address" + id);
    if (ip_address && eleText && ele && !ele.classList.contains("disabled")) {
      var copyText = ele.innerHTML;
      var copyEle = eleText.innerHTML;
      copy(copyEle?.trim());
      ip_address.classList.add("disabled")
      ele.innerHTML = `<svg
      xmlns="http://www.w3.org/2000/svg"
      width=24px
      height=24px
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9.89557 13.4982L7.79487 11.2651C7.26967 10.7068 6.38251 10.7068 5.85731 11.2651C5.37559 11.7772 5.37559 12.5757 5.85731 13.0878L9.74989 17.2257C10.1448 17.6455 10.8118 17.6455 11.2066 17.2257L18.1427 9.85252C18.6244 9.34044 18.6244 8.54191 18.1427 8.02984C17.6175 7.47154 16.7303 7.47154 16.2051 8.02984L11.061 13.4982C10.7451 13.834 10.2115 13.834 9.89557 13.4982Z"
        fill="black"
      />
    </svg>`;
      // ele.classList.remove("badge-primary");
      // ele.classList.add("badge-success");
      setTimeout(() => {
        // ele.classList.add("badge-primary");
        // ele.classList.remove("badge-success");
        ip_address.classList.remove("disabled")
        ele.innerHTML = copyText;
      }, 2000);
    }

  }

  function ProductInfo({ productdata }) {
    return (
      <>
        {productdata != null && Object.keys(productdata)?.length != 0 ? (
          productdata &&
          productdata.map((value, index) => (
            <tr>
              <td />
              <td className="text-center">
                <div className="d-flex flex-column w-100 me-2">
                  <div className="d-flex flex-stack mb-2">
                    <span className=" me-2 fs-7 fw-bold">{value.name}</span>
                  </div>
                </div>
              </td>
              <td className="text-center">
                <div className="d-flex flex-column w-100 me-2">
                  <div className=""> 
                    <span className=" me-2 fs-7 fw-bold">
                      {value.id}
                    </span>
                  </div>
                </div>
              </td>
              <td className="text-center" id={`vpc_ip_address_${index}`}>
              {" "}
              <div className="d-flex flex-column w-100 me-2">
                {" "}
                <div className=" flex-stack mb-2">
                  {" "}
                  <span className="w-100 btn btn-active-primary btn-light-primary btn-sm sphide me-2 fs-7 fw-bold"
                    onClick={() => copyToClipboard(index)}
                    htmlFor="IP Address"
                    id={"vpc_ip_address_sec" + index}
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    title="Click to copy IP Address"
                  >
                    <span id={"vpc_ip_address" + index}>{value.network}/{value.size}</span>&nbsp;&nbsp;
                    {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen054.svg*/}
                    <span className="svg-icon svg-icon-light svg-icon-2" id={`vpcIpIcon_${index}`}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          opacity="0.5"
                          d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                          fill="black"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
            </td>{" "}
              <td className="text-center">
                <span className="me-2 fs-7 fw-bold">
                  {value?.is_default == 0 ? "yes" : 'no'}
                </span>
              </td>
              <td className="text-center d-none">
                <span className="me-2 fs-7 fw-bold">
                  {value?.assign_public}
                </span>
              </td>
              <td className="text-center">
                <div className="d-flex flex-column w-100 me-2">
                  <div className="d-flex flex-stack mb-2">
                    <Link to={"/vpc/" + value.vpcid}>
                      <span className="text-primary me-2 fs-7 fw-bold cursor-pointer">
                        {value.vpcid}
                      </span>
                    </Link>
                  </div>
                </div>
              </td>
              <td className="text-center d-none">
                <div className="d-flex flex-column w-100 me-2">
                  <div className="d-flex flex-stack mb-2">
                    {value.status == "Active" ?
                      <span className="badge badge-success fw-bold">{value.status}</span>
                      :
                      <span className="badge badge-warning fw-bold">{value.status}</span>
                    }
                    {/* <span className="badge badge-success badge-lg fw-bold">{capitalizeFirstLetter(value.subnet_type)}</span> */}
                  </div>
                </div>
              </td>
              <td className="text-end pe-9">
                {" "}
                <div className=" justify-content-end flex-shrink-0">
                  {" "}
                  <Link to={"/vpc/subnets/" + value.id + ""} >
                    <a
                      className="btn btn-primary btn-active-light-primary btn-sm sphide"
                    >
                      {" "}
                      Manage{" "}
                      <span className="svg-icon ms-2 svg-icon-3">
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          {" "}
                          <rect
                            opacity="0.5"
                            x={18}
                            y={13}
                            width={13}
                            height={2}
                            rx={1}
                            transform="rotate(-180 18 13)"
                            fill="black"
                          />{" "}
                          <path
                            d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                            fill="black"
                          />{" "}
                        </svg>{" "}
                      </span>{" "}
                    </a>{" "}
                  </Link>
                </div>{" "}
              </td>
            </tr>
          ))
        ) : (
          <>
            <tr>
              <td colspan="12" className="dataTables_empty" id="loader">
                {/* <div className="loading-spinner"></div> */}
                <TableLoader />
              </td>
              <td colspan="12" className="d-none" id="no_record" valign="top">
                <div className="d-flex flex-column flex-center">
                  <div className="fs-5 fw-bolder text-dark mb-4">
                    No items found.
                  </div>
                </div>
              </td>
            </tr>
          </>
        )}
      </>
    );
  }

  const [subnetDataAll, setSubnetDataAll] = useState(null);
  const [dummySubnetDataAll, setDummySubnetDataAll] = useState(null);
  const dataFetchedRef = useRef(false);

  const getSubnets = async () => {
    const dt = toast;
    const datas = await UseApi("get", "vpc/subnet?subaction=all", "");
    if (datas) {
      setDataFetched(true);
    }
    if (datas.status == "error") {
      setSubnetDataAll([]);
      setDummySubnetDataAll([])
    } else {
      setSubnetDataAll(datas.subnet);
      setDummySubnetDataAll(datas.subnet)
    }
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getSubnets();
  }, []);

  const handleSearch = (text) => {
    let data = subnetDataAll.filter((row) => {
      return (
        (row.name && row.name.toString().toLowerCase().includes(text.toLowerCase())))
    });

    // Update the state with the filtered data
    setDummySubnetDataAll(data);
    setPageCount(Math.ceil(data.length / perPage));
  }

  function PaginatedItems({ itemsPerPage, ProductData }) {
    // Calculate the total number of pages based on itemsPerPage and ProductData
    const pageCount = Math.ceil(ProductData.length / parseInt(itemsPerPage));

    // Calculate the current end offset
    const endOffset = itemOffset + parseInt(itemsPerPage);

    // Handle page click
    const handlePageClick = (event) => {
      const selectedPage = event.selected;
      const newOffset = selectedPage * parseInt(itemsPerPage);
      setActivePage(selectedPage);
      setItemOffset(newOffset);
    };
    return (
      <>
        <ProductInfo productdata={ProductData.slice(parseInt(itemOffset), endOffset)} />
        {ProductData.length > 0 && ProductData.length > 5 ?
          <td colspan="12" className="ps-3">
            <ReactPaginate
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              initialPage={activePage}
              previousLabel="< previous"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </td>
          :
          ""
        }
      </>
    );
  }

  const handleItemsPerPageChange = (newItemsPerPage) => {
    // If the current page goes beyond the total page count, reset to the first page
    const newPageCount = Math.ceil(dummySubnetDataAll.length / parseInt(newItemsPerPage));
    const newActivePage = activePage >= newPageCount ? 0 : activePage;

    // Set the new offset based on the current page and itemsPerPage
    const newOffset = newActivePage * parseInt(newItemsPerPage);

    // Update state
    setItemOffset(newOffset);
    setActivePage(newActivePage);
    setPerPage(newItemsPerPage);  // Update the itemsPerPage state
  };

  if (dataFetched) {
    return (
      <>
        <div className="col-xl-12">
          {/*begin::Tables Widget 9*/}
          <div className="card card-xl-stretch mb-5 mb-xl-12">
            {/*begin::Header*/}
            <div className="card-header border-0 pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1">Subnets</span>
              </h3>
              <div className="card-toolbar">
                <div
                  className="position-relative my-1"
                  style={{ marginRight: 20 }}
                >
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    data-kt-table-widget-4="search"
                    id="searchClient"
                    onChange={(e) => handleSearch(e.target.value)}
                    placeholder="Search"
                  />
                </div>
                <div style={{ marginRight: 20 }}>
                  <div className="dataTables_length">
                    <label className="w-125px">
                      <select
                        onChange={(e) => handleItemsPerPageChange(e.target.value)}
                        //data-control="select2"
                        className="form-select form-select-sm form-select-solid"
                        defaultValue={5}
                        id="perPage"
                      >
                        <option value={5} selected="">
                          5 Records
                        </option>
                        <option value={10}>10 Records</option>
                        <option value={25}>25 Records</option>
                        <option value={50}>50 Records</option>
                        <option value={100}>100 Records</option>
                      </select>
                    </label>
                  </div>
                </div>
                <CreateSubnet getSubnets={getSubnets} />
              </div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            {/*begin::Table container*/}
            <div className="table-responsive">
              {/*begin::Table*/}
              <table
                id="vpcTable"
                className="table table-striped table-row-gray-300 align-middle gs-0 gy-4"
              >
                {/*begin::Table head*/}
                <thead className="bg-secondary">
                  <tr className="fw-bold ">
                    <th className="w-20px p-3" />
                    <th className="w-100px">Name</th>
                    <th className="w-250px text-center sphide">Subnet ID</th>
                    <th className="w-100px text-center sphide">IPv4 CIDR</th>
                    <th className="w-100px text-center sphide">Default</th>
                    <th className="w-100px text-center sphide d-none">Assign Public</th>
                    <th className="w-250px text-center">VPC ID</th>
                    <th className="w-150px d-none">Status</th>
                    <th className="w-150px d-none">Status</th>
                    <th className="w-150px text-center"></th>
                  </tr>
                </thead>
                {/*end::Table head*/}
                {/*begin::Table body*/}
                <tbody id="mainTable">
                  {subnetDataAll != null ? (
                    <>
                      {subnetDataAll.length != 0 ? (
                        <>
                          <PaginatedItems
                            ProductData={dummySubnetDataAll}
                            itemsPerPage={perPage}
                          />
                        </>
                      ) : (
                        <>
                          <tr>
                            <td colspan={12}>
                              <div className="card-body p-0 mt-10">
                                {/*begin::Illustration*/}
                                <div className="text-center px-4 mt-10">
                                  <span className="mw-100 mh-300px">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="234px"
                                      height="234px"
                                      viewBox="0 0 24 24"
                                      fill="#21325B"
                                    >
                                      <path
                                        opacity="0.3"
                                        d="M22 14V18C22 19.1 21.1 20 20 20C20 20.6 19.6 21 19 21C18.4 21 18 20.6 18 20H6C6 20.6 5.6 21 5 21C4.4 21 4 20.6 4 20C2.9 20 2 19.1 2 18V14C2 12.9 2.9 12 4 12H20C21.1 12 22 12.9 22 14ZM6 15C5.4 15 5 15.4 5 16C5 16.6 5.4 17 6 17C6.6 17 7 16.6 7 16C7 15.4 6.6 15 6 15ZM10 15C9.4 15 9 15.4 9 16C9 16.6 9.4 17 10 17C10.6 17 11 16.6 11 16C11 15.4 10.6 15 10 15ZM14 15C13.4 15 13 15.4 13 16C13 16.6 13.4 17 14 17C14.6 17 15 16.6 15 16C15 15.4 14.6 15 14 15Z"
                                        fill="#21325B"
                                      />
                                      <path
                                        d="M18 2C18.6 2 19 2.4 19 3V12H17V3C17 2.4 17.4 2 18 2Z"
                                        fill="#21325B"
                                      />
                                    </svg>
                                  </span>
                                </div>
                                {/*end::Illustration*/}
                                {/*begin::Wrapper*/}
                                <div className="card-px text-center py-5 mb-20">
                                  {/*begin::Title*/}
                                  <h2 className="fs-1x fw-bolder mb-5 mt-5 text-muted">
                                    Build a custom subnet in the VPC
                                  </h2>
                                  {/*end::Title*/}
                                  {/*begin::Description*/}
                                  <p className="text-gray-400 fs-4 fw-bold mb-10">
                                    Take control with Utho's VPC subnets. Create a
                                    subnet for your VPC
                                  </p>
                                  {/*end::Description*/}
                                  {/*begin::Action*/}
                                  <CreateSubnet getSubnets={getSubnets} />
                                  {/*end::Action*/}
                                </div>
                                {/*end::Wrapper*/}
                              </div>
                            </td>
                          </tr>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <tr>
                        <td colspan={12}>
                          <TableLoader />
                          <TopBarHeaderLine />
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
                {/*end::Table body*/}
              </table>
              {/*end::Table*/}
            </div>
            {/*end::Table container*/}
            {/*begin::Body*/}
          </div>
          {/*end::Tables Widget 9*/}
        </div>
      </>
    );
  } else {
    return (<>
      <TableLoader />
      <TopBarHeaderLine />
    </>)
  }
}
