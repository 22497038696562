export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  } 
  
export function getCurrentTimePeriod(type) {
    const now = new Date();
    
    switch (type.toLowerCase()) {
      case 'year':
        return now.getFullYear();
      case 'month':
        return now.getMonth() + 1; // getMonth() returns 0-11, so add 1
      case 'week':
        const startOfYear = new Date(now.getFullYear(), 0, 1);
        const days = Math.floor((now - startOfYear) / (24 * 60 * 60 * 1000));
        const weekNumber = Math.ceil((days + startOfYear.getDay() + 1) / 7);
        return weekNumber;
      case 'day':
        return now.getDay(); // getDay() returns 0-6 (0 for Sunday, 6 for Saturday)
      case 'date':
        return now.getDate();
      case 'time':
        return now.toTimeString().split(' ')[0]; // HH:MM:SS format
      default:
        return 'Invalid type';
    }
  }