import React, { useEffect, useRef, useState } from "react";

export default function InstanceTypeKubernetes(props) {
    const [nodePool, setNodePool] = useState(true)
    const [workers, setWorkers] = useState(true)

    return (
        <div className="col-xl-12 card mb-5">
            <div className="card-header border-3 mb-5 pt-2">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder text-gray-800">Instance Type</span>
                    <span className="text-gray-400 mt-1 fw-bold fs-6">
                        Kubernetes, Node Pool, Worker
                    </span>
                </h3>
            </div>
            <div className="mx-5">
                <div
                    className="row g-9"
                    data-kt-buttons="true"
                    data-kt-buttons-target="[data-kt-button]"
                >
                    <div className="col-md-4 col-lg-4 col-xxl-4">
                        <div className="mb-10">
                            <label htmlFor="ClusterLabel" className="required form-label">
                                Kubernetes
                            </label>
                            <select className="form-select form-select-sm  " onChange={(e) => {
                                props.getKubernetesById(e.target.value)
                                setNodePool(false)
                            }}>
                                <option>please Select</option>
                                {props.cloudData.length > 0 && props.cloudData.map((key, index) =>
                                    <option value={key.cloudid}>{key.hostname}</option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-4 col-xxl-4">
                        <div className="mb-10">
                            <label htmlFor="ClusterLabel" className="required form-label">
                                Node Pool
                            </label>
                            <select className="form-select form-select-sm  " disabled={nodePool} onChange={(e) => {
                                props.getKubernetesById(e.target.value)
                                setWorkers(false)
                            }}>
                                {props.cloudData.length > 0 && props.cloudData.map((key, index) =>
                                    <option value={key.cloudid}>{key.hostname}</option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-4 col-xxl-4">
                        <div className="mb-10">
                            <label htmlFor="ClusterLabel" className="required form-label">
                                Workers
                            </label>
                            <select className="form-select form-select-sm  " disabled={workers} onChange={(e) => {
                                props.getKubernetesById(e.target.value)
                                props.setWorkers(false)
                            }}>
                                <option value="0">Choose Kubernetes Instance</option>
                                {props.cloudData.length > 0 && props.cloudData.map((key, index) =>
                                    <option value={key.cloudid}>{key.hostname}</option>
                                )}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}