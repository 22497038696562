import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import UseApi from "../../../../../CustomHooks/useApi";
import TableLoader from "../../../Loaders/TableLoader";
import UsePostData from "../../../../../CustomHooks/usePostData";
import UsePost from "../../../../../CustomHooks/usePost";
import { useDispatch } from "react-redux";
import CustomDoc from "../../../../../CustomHooks/CustomDoc";
import ProductsUrl from "../../../Docs/Docsdata";

export default function Firewall(props) {
  var query = window.location.href;
  var vars = query.split("/");
  var id = vars[4];
  const [dataFetched, setDataFetched] = useState(false);
  const [itemOffset, setItemOffset] = useState(0);
  const [access, setAccess] = useState(true);
  const [perPage, setPerPage] = useState(5);
  const [pageCount, setPageCount] = useState(1);
  const [firewallData, setFirewallData] = useState(null);
  const [alFirewallData, setAllFirewallData] = useState([])
  const [pageStatus, setPageStatus] = useState(false)

  const [activePage, setActivePage] = useState(0);
  const [dummyFirewallData, setDummyFirewallData] = useState(null);
  const [fwData, setFwData] = useState(null);
  const dataFetchedRef = useRef(false);
  const loaderFunc = () => {
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if (loader && no_record) {
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");

      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navRedirect = () => {
    setTimeout(() => {
      navigate("/kubernetes/" + id);
    }, 3000);
  }
  const getFirewall = async () => {
    // if (props) {
    //   if (props.data) {
    setFirewallData(props.data);
    setDummyFirewallData(props.data)
    // }
    // }

  }
  const getFw = async () => {
    const dt = toast
    const data = await UseApi('get', 'firewall', '');
    if (data) {
      console.log(data);
      if ('status' in data) {
        if (data.status == "error") {
          toast.update(dt, {
            render: data.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          setFirewallData([]);
          setDummyFirewallData([])
          setAllFirewallData([])
          return;
        }
      }
      if (Object.keys(data).length === 0) {
        setFirewallData(null);
        setDummyFirewallData(null)
        setAllFirewallData([])
        toast.update(dt, {
          render: "no record found", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        // loaderFunc();
      } else {
        setAllFirewallData((old) => old = data?.firewalls)
        const fw_Data = data.firewalls.filter(obj1 => !firewallData?.some(obj2 => obj2.id === obj1.id));
        setFwData(fw_Data);
        toast.dismiss();
      }
    }
  }

  useEffect(() => {
    getFirewall();
  }, [props])
  useEffect(() => {
    getFw();
  }, [firewallData])


  const openDrawer = () => {

    var drawerElement = document.querySelector("#fw-modal");
    var drawer_overlay = document.querySelector("#drawer_overlay_firewall");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
        drawerElement.style.width = "100%";
      } else {
        drawerElement.style.width = "40%";
      }
      drawer_overlay.classList.toggle("d-none");
    }
  };
  function checkObjectForEmpty(obj) {
    for (const key in obj) {
      const value = obj[key];

      if (typeof value === 'string' && value.trim() === '') {
        return `Error: '${key}' should not be empty or blank.`;
      }

      if (typeof value === 'object') {
        const nestedError = checkObjectForEmpty(value);
        if (nestedError) {
          return nestedError;
        }
      }
    }

    return null; // No empty or blank elements found
  }
  const attachFw = async () => {
    var add_firewall = document.querySelector("#add_fw_id");
    const submitBtn = document.querySelector("#add-fw-btn");

    if (add_firewall && submitBtn) {
      toast.dismiss();
      const dt = toast.loading("Please wait...");
      var updateData = { "security_groups": add_firewall.value };
      const validationError = checkObjectForEmpty(updateData);

      if (validationError) {
        toast.update(dt, {
          render: validationError,
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
      if (submitBtn) {
        var preContent = submitBtn.innerHTML;
        submitBtn.classList.add("disabled");
        submitBtn.innerHTML = 'Please wait... <span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
        const responseData = await UsePostData("post", "kubernetes/" + id + "/securitygroup/" + add_firewall.value, {});

        ////console.log(responseData);
        if (responseData.status === "error") {
          toast.update(dt, {
            render: responseData.message,
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (responseData.status === "success") {
          toast.update(dt, {
            render: responseData.message,
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          openDrawer();
          getFirewall();
          props.getKubernetes()
        }
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
      }
    }
  };

  const deleteFw = async (fwid, index) => {
    toast.dismiss();
    const dt = toast.loading("Please wait...");
    const submitBtn = document.querySelector("#delete-fw-btn-" + fwid);
    if (submitBtn) {
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML =
        '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';

      const responseData = await UseApi("delete", "kubernetes/" + id + "/securitygroup/" + fwid, "");

      ////console.log(responseData);
      if (responseData.status === "error") {
        toast.update(dt, {
          render: responseData.message,
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (responseData.status === "success") {
        toast.update(dt, {
          render: responseData.message,
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // setAppliDetails((old) => {
        //   // old.splice(index, 1);
        //   // return [...old];
        setFirewallData((old) => {
          old.splice(index, 1);
          return [...old]
        })
        setDummyFirewallData((old) => {
          old.splice(index, 1);
          return [...old]
        })
        getFw()
        getFirewall();
        props.getKubernetes()
      }
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML = preContent;
    }
  };
  function ProductInfo({ productdata }) {
    return (
      <>

        {productdata != null && Object.keys(productdata).length != 0 ? (productdata && productdata.map((value, index) => (
          <tr>
            <td className="text-start ps-9">
              {" "}
              <div className="d-flex justify-content-start flex-column">
                {" "}
                <span className=" me-2 fs-7 fw-bold">
                  {value.id}
                </span>{" "}
              </div>
            </td>
            <td></td>
            <td className="p-3 text-center">
              {" "}
              <div className="d-flex justify-content-center flex-column">
                <a className="text-dark  fw-bold text-hover-primary fs-6">
                  {value.name}
                </a>
              </div>{" "}
            </td>{" "}
            <td></td>
            <td></td>
            <td></td>
            {/* <td></td> */}
            <td className="text-center pe-9">
              {" "}
              {" "}
              <a
                id={"delete-fw-btn-" + value.id}
                onClick={(e) => deleteFw(value.id, index)}
                className="btn  btn-danger btn-active-light-danger btn-sm"
                style={{ padding: '0.73rem' }}
              >
                {" "}
                Detach Firewall
              </a>{" "}
            </td>
          </tr>
        ))) : (<>
          <tr>
            {/* <td colspan="12" className="dataTables_empty" id="loader"> */}
            {/* <div className="loading-spinner"></div> */}
            {/* <TableLoader /> */}
            {/* </td> */}
            <td colspan="12" className="" id="no_record" valign="top">
              <div className="d-flex flex-column flex-center">
                <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
              </div>
            </td>
          </tr>
        </>)}

      </>
    )
  }

  const handleSearch = (text) => {
    const endOffset = itemOffset + perPage;
    const data = firewallData.filter(row => {
      return Object.values(row).some(value => {
        if (value != null) {
          if (value.toString().toLowerCase().includes(text.toLowerCase())) {
            return value;
          }
        }
      });
    });
    setDummyFirewallData(data);;
    setPageCount(Math.ceil(data.length / perPage));
  }

  function PaginatedItems({ itemsPerPage, ProductData }) {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(ProductData.length / itemsPerPage));
    const handlePageClick = (event) => {
      setActivePage((old) => old = event.selected);
      const newOffset = event.selected * itemsPerPage % ProductData.length;
      setItemOffset(newOffset);
    };

    return (
      <>
        <ProductInfo productdata={ProductData.slice(itemOffset, endOffset)} />
        <td colspan="12" className="ps-3">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            initialPage={activePage}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </td>
      </>
    );
  }

  const [availabelFirewallData, setAvailabelFirewallData] = useState(null);

  const getAllFirewall = async () => {
    const data = await UseApi('get', 'firewall', '');

    if (Object.keys(data).length === 0) {
      setAvailabelFirewallData(null);
    } else {
      setAvailabelFirewallData(data.firewalls);

    }
  }
  useEffect(() => {
    getAllFirewall();
  }, [])

  const deployFirewall = async () => {
    toast.dismiss();
    const dt = toast.loading("Firewall creating, Please wait...");
    const firewall_name = document.querySelector("#firewall_name");
    if (firewall_name) {
      if (firewall_name.value == "") {
        toast.update(dt, {
          render: "Please assign a name to firewall", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
      } else {

        setPageStatus(true)
        const responseData = await UsePost('post', 'firewall/create', 'createfirewall');
        if (responseData.status === 'error') {
          setPageStatus(false)
          toast.update(dt, {
            render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        } else if (responseData.status === 'success') {
          setPageStatus(false)
          toast.update(dt, {
            render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          setTimeout(() => {
            firewall_type('assign');
            getAllFirewall();
            navRedirect();
          }, 1500)
        }
      }
    }
  }

  const addFirewallServer = async () => {
    toast.dismiss();
    const dtServer = toast.loading("Firewall adding, Please wait...");
    const firewall_id = document.querySelector('#select_server');
    if (firewall_id) {
      if (firewall_id.value == "") {
        toast.update(dtServer, {
          render: "Please select a firewall", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
      } else {
        setPageStatus(true)
        const responseData = await UsePostData("post", "kubernetes/" + id + "/securitygroup/" + firewall_id.value, {});
        // const responseData = await UsePost('post', 'firewall/' + firewall_id.value + '/server/add', 'addserver');
        // console.log(responseData);
        if (responseData) {
          if (responseData.status === 'error') {
            setPageStatus(false)
            toast.update(dtServer, {
              render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
          } else {
            setPageStatus(false)
            toast.update(dtServer, {
              render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
            const data = await UseApi('get', 'cloud/' + id, '');

            if (props && props.getKubernetes) {
              props.getKubernetes();
            }
            firewall_type('close');
            navRedirect();
          }
        }
      }
    }
  }


  const firewall_type = async (type) => {
    var firewall_close = document.querySelector("#firewall_close");
    //console.log(type);
    if (type != "close") {
      if (firewall_close) {
        firewall_close.classList.remove("d-none");
      }
      if (type == "assign") {
        document.querySelector("#firewall_op").classList.add("d-none");
        document.querySelector("#firewall_create").classList.add("d-none");
        document.querySelector("#firewall_assign").classList.remove("d-none");
      }
      if (type == "create") {
        //console.log("create hua");
        document.querySelector("#firewall_op").classList.add("d-none");
        document.querySelector("#firewall_create").classList.remove("d-none");
        document.querySelector("#firewall_assign").classList.add("d-none");
      }
      if (type == "both") {
        document.querySelector("#firewall_op").classList.add("d-none");
        document.querySelector("#firewall_create").classList.add("d-none");
        document.querySelector("#firewall_assign").classList.remove("d-none");
      }
    } else {
      if (firewall_close) {
        firewall_close.classList.add("d-none");
        document.querySelector("#firewall_op").classList.remove("d-none");
        document.querySelector("#firewall_create").classList.add("d-none");
        document.querySelector("#firewall_assign").classList.add("d-none");
      }
    }

  }
  return (
    <>
      <div className="col-xl-12">
        <div className="card mb-5 mb-xxl-10">
          {/*begin::Header*/}
          <div className="card-header">
            {/*begin::Title*/}
            <div className="card-title">
              <h3>Firewalls</h3>

            </div>
            <div className="card-toolbar">
              <div className="position-relative my-1" style={{ marginRight: 20 }}>
                <CustomDoc url={ProductsUrl[0]?.kubernetes + "/manage-kubernetes/firewall/"} />
              </div>
              <div
                id="firewall_close"
                onClick={(e) => firewall_type('close')}
                className="btn btn-sm btn-icon btn-active-light-primary d-none"
              >
                <span className="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x={6}
                      y="17.3137"
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(-45 6 17.3137)"
                      fill="black"
                    />
                    <rect
                      x="7.41422"
                      y={6}
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(45 7.41422 6)"
                      fill="black"
                    />
                  </svg>
                </span>
              </div>
            </div>

            {/*end::Title*/}
          </div>
          {/*end::Header*/}
          {/*begin::Body*/}
          <div className="card-body py-10">
            {/*begin::Row*/}
            <div className="row mb-10">
              {/*begin::Notice*/}
              <div className="notice  bg-light-primary rounded border-primary border p-6">
                <div id="firewall_assign" className="d-none">
                  {/*begin::Wrapper*/}
                  <div className=" flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                    <div className="row">
                      <div className="col-6">
                        {/*begin::Content*/}
                        <div className="mb-3 mb-md-0 fw-bold">
                          <div className="fs-6 text-gray-700 pe-7">
                            Please assign new firewall to this kubernetes.
                          </div>
                        </div>
                        {/*end::Content*/}
                      </div>
                      <div className="col-3">
                        {/*begin::Content*/}
                        <div className="mb-3 mb-md-0 fw-bold">
                          <div className="fs-6 text-gray-700 pe-7">
                            or
                          </div>
                        </div>
                        {/*end::Content*/}
                      </div>
                      <div className="col-3" style={{ paddingLeft: '5.5rem' }}>
                        {/*begin::Action*/}
                        <a
                          onClick={() => firewall_type('create')}
                          className="btn btn-primary px-6 align-self-center text-nowrap"
                        >
                          Create new firewall
                        </a>
                        {/*end::Action*/}
                      </div>
                    </div>
                  </div>
                  {/*end::Wrapper*/}
                  {/*begin::Form*/}
                  <form id="addserver" className="form">
                    {/*begin::Modal body*/}
                    {/*begin::Scroll*/}
                    {/*begin::Input group*/}
                    <div className="modal-body mb-5 fv-row p-0">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Existing Firewalls
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <select
                        className="form-select"
                        id="select_server"
                        defaultValue=""
                      >
                        <option value="">Choose firewall</option>
                        {availabelFirewallData != null && (availabelFirewallData && availabelFirewallData.map((value, index) => (
                          <option value={value.id}>{value.name}</option>
                        )))}
                      </select>
                      <input type="hidden" name="cloudid" defaultValue={id} />
                      {/*end::Input*/}
                      {/*end::Input group*/}
                    </div>
                    {/*end::Scroll*/}
                    {/*begin::Modal footer*/}
                    {/*begin::Button*/}
                    < a
                      style={{ width: "100%", cursor: "pointer" }}
                      onClick={addFirewallServer}
                      className="btn btn-primary"
                    >
                      <span className="indicator-label">Assign Firewall</span>
                    </a>
                    {/*end::Button*/}
                    {/*end::Modal footer*/}
                  </form>
                  {/*end::Form*/}
                </div>
                <div id="firewall_create" className="d-none">
                  {/*begin::Wrapper*/}
                  <div className=" flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                    <div className="row">
                      <div className="col-6">
                        {/*begin::Content*/}
                        <div className="mb-3 mb-md-0 fw-bold">
                          <div className="fs-6 text-gray-700 pe-7">
                            Please create new firewall to this kubernetes server.
                          </div>
                        </div>
                        {/*end::Content*/}
                      </div>
                      <div className="col-3">
                        {/*begin::Content*/}
                        <div className="mb-3 mb-md-0 fw-bold">
                          <div className="fs-6 text-gray-700 pe-7">
                            or
                          </div>
                        </div>
                        {/*end::Content*/}
                      </div>
                      <div className="col-3">
                        {/*begin::Action*/}
                        <a
                          onClick={(e) => firewall_type('assign')}
                          className="btn btn-primary px-6 align-self-center text-nowrap"
                        >
                          Assign existing firewall
                        </a>
                        {/*end::Action*/}
                      </div>
                    </div>
                  </div>
                  {/*end::Wrapper*/}
                  {/*begin::Form*/}
                  <form id="createfirewall" className="form">
                    {/*begin::Modal body*/}
                    {/*begin::Scroll*/}
                    {/*begin::Input group*/}
                    <div className="modal-body mb-5 fv-row">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        New Firewall Name
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <input
                        type="text"
                        className="form-control"
                        placeholder="my_web_firewall"
                        name="name"
                        defaultValue=""
                        id="firewall_name"
                      />
                      {/*end::Input*/}
                      {/*end::Input group*/}
                    </div>
                    {/*end::Scroll*/}
                    {/*begin::Modal footer*/}
                    {/*begin::Button*/}
                    <button
                      type="button"
                      style={{ width: "100%" }}
                      onClick={deployFirewall}
                      className="btn btn-primary"
                    >
                      <span className="indicator-label">Create Firewall</span>
                    </button>
                    {/*end::Button*/}
                    {/*end::Modal footer*/}
                  </form>
                  {/*end::Form*/}
                </div>

                <div id="firewall_op">

                  {/*begin::Wrapper*/}
                  <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                    {/*begin::Content*/}
                    <div className="mb-3 mb-md-0 fw-bold">
                      <div className="fs-6 text-gray-700 pe-7">
                        {/*begin::Icon*/}
                        {/*begin::Svg Icon | path: icons/duotune/finance/fin001.svg*/}
                        <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M20 19.725V18.725C20 18.125 19.6 17.725 19 17.725H5C4.4 17.725 4 18.125 4 18.725V19.725H3C2.4 19.725 2 20.125 2 20.725V21.725H22V20.725C22 20.125 21.6 19.725 21 19.725H20Z"
                              fill="black"
                            />
                            <path
                              opacity="0.3"
                              d="M22 6.725V7.725C22 8.325 21.6 8.725 21 8.725H18C18.6 8.725 19 9.125 19 9.725C19 10.325 18.6 10.725 18 10.725V15.725C18.6 15.725 19 16.125 19 16.725V17.725H15V16.725C15 16.125 15.4 15.725 16 15.725V10.725C15.4 10.725 15 10.325 15 9.725C15 9.125 15.4 8.725 16 8.725H13C13.6 8.725 14 9.125 14 9.725C14 10.325 13.6 10.725 13 10.725V15.725C13.6 15.725 14 16.125 14 16.725V17.725H10V16.725C10 16.125 10.4 15.725 11 15.725V10.725C10.4 10.725 10 10.325 10 9.725C10 9.125 10.4 8.725 11 8.725H8C8.6 8.725 9 9.125 9 9.725C9 10.325 8.6 10.725 8 10.725V15.725C8.6 15.725 9 16.125 9 16.725V17.725H5V16.725C5 16.125 5.4 15.725 6 15.725V10.725C5.4 10.725 5 10.325 5 9.725C5 9.125 5.4 8.725 6 8.725H3C2.4 8.725 2 8.325 2 7.725V6.725L11 2.225C11.6 1.925 12.4 1.925 13.1 2.225L22 6.725ZM12 3.725C11.2 3.725 10.5 4.425 10.5 5.225C10.5 6.025 11.2 6.725 12 6.725C12.8 6.725 13.5 6.025 13.5 5.225C13.5 4.425 12.8 3.725 12 3.725Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}
                        {/*end::Icon*/}

                        Please create new or assign a existing firewall to this kubernetes.
                      </div>
                    </div>
                    {/*end::Content*/}
                    {/*begin::Action*/}
                    <a
                      onClick={(e) => firewall_type('both')}
                      className="btn btn-primary px-6 align-self-center text-nowrap"
                    >
                      Create or assign Firewall
                    </a>
                    {/*end::Action*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
              </div>
              {/*end::Notice*/}
            </div>
            {/*end::Row*/}
          </div>
          {/*end::Body*/}
        </div>

        {/*begin::Tables Widget 9*/}
        <div className="card card-xl-stretch mb-5 mb-xl-12">
          {/*begin::Header*/}
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label  fw-bold fs-3 mb-1">
                {" "}
                Firewall Management{" "}
                {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                {/* <span className="svg-icon svg-icon-2"> */}
                {/* <i className="bi bi-diagram-3 " style={{ fontSize: 20 }} /> */}
                {/* </span> */}
                {/*end::Svg Icon*/}
              </span>
            </h3>
            <div
              className="card-toolbar"
            >
              <div className="position-relative my-1" style={{ marginRight: 20 }}>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  data-kt-table-widget-4="search"
                  id="searchClient"
                  style={{ padding: '0.64rem' }}
                  // value={searchTerm}
                  onChange={e => handleSearch(e.target.value)}
                  placeholder="Search"
                />
              </div>
              <div style={{ marginRight: 20 }}>
                <div className="dataTables_length">
                  <label className="w-125px">
                    <select
                      style={{ padding: '0.64rem' }}
                      onChange={e => setPerPage(e.target.value)}
                      className="form-select form-select-sm form-select-solid"
                      defaultValue={5}
                      id="perPage"

                    >
                      <option value={5} selected="">
                        5 Records
                      </option>
                      <option value={10}>10 Records</option>
                      <option value={25}>25 Records</option>
                      <option value={50}>50 Records</option>
                      <option value={100}>100 Records</option>
                    </select>
                  </label>
                </div>
              </div>

            </div>
          </div>
          {/*end::Header*/}
          {/*begin::Body*/}
          {/*begin::Table container*/}
          <div className="table-responsive">
            {/*begin::Table*/}
            <table
              id="loadbalancer"
              className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
            >
              {/*begin::Table head*/}
              <thead className="bg-secondary">
                <tr className=" fw-bold ">
                  <th className="text-start ps-9">#ID</th>
                  <th className="text-center"></th>
                  <th className="p-3 text-center">Name</th>
                  <th className="text-center"></th>
                  <th className="text-center"></th>
                  <th className="text-center"></th>
                  <th className="text-center pe-14"></th>
                </tr>
              </thead>
              {/*end::Table head*/}
              {/*begin::Table body*/}
              <tbody>
                {/* {fwData} */}
                {alFirewallData.length > 0 ?
                  <>
                    {firewallData != null ? (<> {Object.keys(firewallData).length != 0 ? (<>
                      <PaginatedItems itemsPerPage={perPage} ProductData={dummyFirewallData} />
                    </>) : (<>
                      <tr>
                        <td colSpan={12} className="text-center">
                          <span className="fs-6 text-gray-800 fw-bold">No record found</span>
                        </td>
                      </tr>
                    </>)}
                    </>) : (<>
                      <tr>
                        <td colspan={12}>
                          <TableLoader />
                        </td>
                      </tr>
                    </>)}
                  </>
                  :
                  <tr>
                    <td colspan={12}>
                      <div className="card-body p-0 mt-10">
                        {/*begin::Illustration*/}
                        <div className="text-center px-4 mt-10">
                          <span className="mw-100 mh-300px">
                            <svg
                              width="234px"
                              height="234px"
                              version="1.1"
                              id="_x32_"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              viewBox="0 0 612 612"
                              xmlSpace="preserve"
                              fill="#21325B"
                              stroke="#21325B"
                            >
                              <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                              <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                              <g id="SVGRepo_iconCarrier">
                                {" "}
                                <style
                                  type="text/css"
                                  dangerouslySetInnerHTML={{ __html: " .st0{fill:#21325B;} " }}
                                />{" "}
                                <g>
                                  {" "}
                                  <rect
                                    x="0.034"
                                    y="0.787"
                                    className="st0"
                                    width="155.35"
                                    height="94.936"
                                  />{" "}
                                  <rect
                                    x="172.645"
                                    y="0.787"
                                    className="st0"
                                    width="155.35"
                                    height="94.936"
                                  />{" "}
                                  <rect x={0} y="112.959" className="st0" width="69.044" height="94.936" />{" "}
                                  <rect
                                    x="86.34"
                                    y="112.985"
                                    className="st0"
                                    width="155.35"
                                    height="94.936"
                                  />{" "}
                                  <path
                                    className="st0"
                                    d="M266.78,160.9l16.756-26.634l9.111,8.58c1.892-10.19,5.36-20.152,10.22-29.862h-43.916v60.279 C263.7,165.796,266.68,161.059,266.78,160.9z"
                                  />{" "}
                                  <path
                                    className="st0"
                                    d="M436.126,148.283c22.542,18.028,41.586,38.129,56.938,59.613h7.509v-94.936h-69.044v31.32 C433.046,145.611,434.47,146.959,436.126,148.283z"
                                  />{" "}
                                  <rect
                                    x="0.034"
                                    y="225.182"
                                    className="st0"
                                    width="155.35"
                                    height="94.936"
                                  />{" "}
                                  <rect x={0} y="337.354" className="st0" width="69.044" height="77.675" />{" "}
                                  <path
                                    className="st0"
                                    d="M368.494,54.627c4.479,0,8.996,1.003,13.068,2.908c9.836,4.517,16.654,14.16,17.653,25.083l1.193,13.106h100.2 V0.787h-155.35V62.87c3.902-2.562,7.324-4.24,10.097-5.302C359.441,55.639,363.904,54.627,368.494,54.627z"
                                  />{" "}
                                  <path
                                    className="st0"
                                    d="M419.957,168.501c-29.116-23.278-40.498-48.985-44.873-67.266c-3.502-14.682-1.656-16.258-1.656-16.258 c-0.16-1.753-1.252-3.262-2.827-3.986c-1.606-0.75-3.456-0.599-4.888,0.405c0,0-5.66,1.222-14.838,10.139 c-20.936,20.328-44.616,54.228-29.023,100.583c16.756,49.743,7.897,60.144-0.864,59.47c-11.37-0.868-13.106-7.872-11.29-29.668 c2.107-25.479-20.999-47.232-20.999-47.232s-35.901,57.076-63.891,97.904c-16.545,24.13-24.471,54.809-24.471,82.799h-0.561 c0,86.044,69.761,155.822,155.827,155.822c86.078,0,155.822-69.778,155.822-155.822 C517.358,280.64,476.759,213.939,419.957,168.501z M355.872,467.335c-47.461,0-85.969-38.492-85.969-85.968 c0-16.157,4.488-31.286,12.238-44.198c6.688,24.855,29.372,43.169,56.352,43.169c32.204,0,58.332-26.11,58.332-58.315 c0-6.153-0.965-12.086-2.735-17.657c28.298,14.076,47.75,43.254,47.75,77.001C441.84,428.843,403.365,467.335,355.872,467.335z"
                                  />{" "}
                                  <path
                                    className="st0"
                                    d="M177.959,320.118c4.522-22.748,13.279-44.35,25.496-62.167c7.021-10.24,14.577-21.568,21.943-32.77h-52.752 v94.936H177.959z"
                                  />{" "}
                                  <path
                                    className="st0"
                                    d="M173.884,337.379H86.34v77.675h97.814c-6.536-18.719-10.27-38.744-10.27-59.664V337.379z"
                                  />{" "}
                                </g>{" "}
                              </g>
                            </svg>


                          </span>
                        </div>
                        {/*end::Illustration*/}
                        {/*begin::Wrapper*/}
                        <div className="card-px text-center py-5 mb-20">
                          {/*begin::Title*/}
                          <h1 className="fs-2x fw-bolder mb-5">Firewall</h1>
                          <h2 className="fs-1x fw-bolder mb-5 text-muted">
                            Safeguard your assets in the kubernetes
                          </h2>
                          {/*end::Title*/}
                          {/*begin::Description*/}
                          <p className="text-gray-400 fs-4 fw-bold mb-10">
                            Deploy Cloud Firewall to protect your resources from online threats. <br />Secure your digital landscape.</p>
                          {/*end::Description*/}
                          {/*begin::Action*/}
                          <Link to="/firewall/deploy">
                            <a
                              href="#"
                              className="btn btn-primary mb-10"
                            >
                              Create Firewall
                            </a>
                          </Link>
                          {/*end::Action*/}
                        </div>
                        {/*end::Wrapper*/}
                      </div>
                    </td>
                  </tr>
                }

              </tbody>
              {/*end::Table body*/}
            </table>
            {/*end::Table*/}
          </div>
          {/*end::Table container*/}
          {/*begin::Body*/}
        </div>
        {/*end::Tables Widget 9*/}
      </div>

    </>
  )
} 