import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import Swal from 'sweetalert2';
import ModifyScript from "./manage/ModifyScript";
import UseApi from "../../../../CustomHooks/useApi";
import TableLoader from "../../Loaders/TableLoader";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import { Link, useNavigate } from "react-router-dom";
import JoinUs from "../JoinUs";

export default function ManageStacks() {
  const navigate = useNavigate();
  var query = window.location.href;
  var vars = query.split("/");
  var id = vars[4];
  const openDrawer = () => {
    var drawerElement = document.querySelector("#ip-modal");
    drawerElement.classList.toggle("drawer-on");
    drawerElement.style.width = "500px";
  };

  const loaderFunc = () => {
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if (loader && no_record) {
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");

      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
  };

  function selectAccessControl() {
    var accessControl = document.querySelector("#access-control");
    var accessControlDesc = document.querySelector("#access-control-desc");
    if (accessControl && accessControlDesc) {
      if (accessControl.value == "private") {
        accessControlDesc.innerHTML =
          "Private: <b>Only you</b> can list, create, overwrite, and delete Objects in this Stacks.";
      } else if (accessControl.value == "authenticated") {
        accessControlDesc.innerHTML =
          "Authenticated <b>Read</b>: <b>All authenticated</b> Object Storage users can list Objects in this Stacks, but only you can create, overwrite, and delete them.";
      } else if (accessControl.value == "public_read") {
        accessControlDesc.innerHTML =
          "Public <b>Read</b>: <b>Everyone</b> can list Objects in this Stacks, but only you can create, overwrite, and delete them.";
      } else if (accessControl.value == "public_read_write") {
        accessControlDesc.innerHTML =
          "Public <b>Read/Write</b>: <b>Everyone</b> can list, create, overwrite, and delete Objects in this Stacks. <b>This is not recommended</b>.";
      }
    }
  }

  const dataFetchedRef = useRef(false);
  const [stacksData, setStacksData] = useState(null);
  const [dataFetched, setDataFetched] = useState(true);

  const getStack = async () => {
    const dt = toast;
    const data = await UseApi("get", "stacks/" + id, "");
    if (data) {
      if ("status" in data) {
        if (data.status == "error") {
          toast.update(dt, {
            render: data.message,
            type: "warning",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // setAccess(false);
          setStacksData([]);
          loaderFunc();
          return;
        }
      }
      if (Object.keys(data).length === 0) {
        setStacksData([]);
        toast.update(dt, {
          render: "no record found",
          type: "warning",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        loaderFunc();
      } else {
        setStacksData(data);
        toast.dismiss();
      }
    }
  };
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getStack();
  }, []);


  const deleteStack = async () => {
    toast.dismiss();
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this SSH Key!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const deletesshkey = document.querySelector("#delete-stack");
        if (deletesshkey) {
          var preContent = deletesshkey.innerHTML;
          deletesshkey.classList.add("disabled");
          deletesshkey.innerHTML =
            'Please wait... <span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';

          const dt = toast.loading("SSH Keys deleting...");
          const dnsp = await UseApi("delete", "stacks/" + id, "");
          if (dnsp.status == "error") {
            toast.update(dt, {
              render: dnsp.message,
              type: "warning",
              isLoading: false,
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.update(dt, {
              render: dnsp.message,
              type: "success",
              isLoading: false,
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            const url = window.location.href
            if (query == url) {
              navigate("/stacks");
            }
          }
          deletesshkey.classList.remove("disabled");
          deletesshkey.innerHTML = preContent;
        }
      }
    });
  };

  if (stacksData != null) {
    if (Object.keys(stacksData).length > 0) {
      return (
        <>
          <div className="card mb-6 mb-xl-9 ">
            <div className="card-body pt-9 pb-0 ">
              <div className="d-flex flex-wrap flex-sm-nowrap mb-6">
                <div
                  className="d-flex flex-center flex-shrink-0 bg-light rounded w-55px h-55px w-lg-55px h-lg-55px me-7 mb-4"
                  id="imageDist"
                >
                  <span className="w-50">
                    <svg
                      fill="#494b74"
                      width={44}
                      height={44}
                      viewBox="0 0 38 32"
                      id="icon"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <defs>
                        <style
                          dangerouslySetInnerHTML={{
                            __html:
                              "\n                                                                                    .cls-1 {\n                                                                                        fill: none;\n                                                                                    }\n                                                                                ",
                          }}
                        />
                      </defs>
                      <path
                        d="M28,20H26v2h2v6H4V22H6V20H4a2.0024,2.0024,0,0,0-2,2v6a2.0024,2.0024,0,0,0,2,2H28a2.0024,2.0024,0,0,0,2-2V22A2.0024,2.0024,0,0,0,28,20Z"
                        transform="translate(0 0)"
                      />
                      <circle cx={7} cy={25} r={1} />
                      <path
                        d="M15,20H8V13h7Zm-5-2h3V15H10Z"
                        transform="translate(0 0)"
                      />
                      <path
                        d="M24,20H17V13h7Zm-5-2h3V15H19Z"
                        transform="translate(0 0)"
                      />
                      <path
                        d="M15,11H8V4h7ZM10,9h3V6H10Z"
                        transform="translate(0 0)"
                      />
                      <path
                        d="M24,11H17V4h7ZM19,9h3V6H19Z"
                        transform="translate(0 0)"
                      />
                    </svg>
                  </span>
                </div>
                <div className="flex-grow-1">
                  <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                    <div className="d-flex flex-column">
                      <div className="d-flex align-items-center mb-1" id="showHost">
                        <a
                          className="text-gray-800 text-hover-primary fs-2 fw-bolder me-3"
                          id="hostname"
                        >
                          {stacksData.name}
                        </a>
                        <a></a>
                      </div>
                      <div className="d-flex flex-wrap fw-bold mb-4 fs-5 text-gray-400">
                        {stacksData.description} &nbsp;
                      </div>
                    </div>
                    <div className="d-flex" id="console">
                      <div className="d-flex flex-stack mb-5">
                        {/*begin::Section*/}
                        <div
                          className="d-flex align-items-center me-5"
                          style={{
                            paddingRight: "28px",
                            borderRight: "3px solid #b5b5c3",
                          }}
                        >
                        </div>
                        {/*end::Section*/}
                        {/*begin::Label*/}
                        <Link to={"/cloud/deploy/" + stacksData.id + "/private"}>
                          <div className="btn btn-primary fw-bold ms-5"> Deploy Server
                            <span className="svg-icon ms-2 svg-icon-3">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                {" "}
                                <rect
                                  opacity="0.5"
                                  x={18}
                                  y={13}
                                  width={13}
                                  height={2}
                                  rx={1}
                                  transform="rotate(-180 18 13)"
                                  fill="black"
                                />{" "}
                                <path
                                  d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                  fill="black"
                                />
                              </svg>
                            </span>

                          </div>
                        </Link>
                        {/*end::Label*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="separator" />
              <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-trakt_charts_widget_1_chartnsparent fs-5 fw-bolder">
                <li className="nav-item">
                  <a
                    className="nav-link text-active-primary py-5 me-6 active sphide"
                    data-bs-toggle="tab"
                    href="#overview"
                  >
                    Overview
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link text-active-primary py-5 me-6 d sphide"
                    data-bs-toggle="tab"
                    href="#modify-script"
                  >
                    Modify
                  </a>
                </li>
                <li className="nav-item d-none">
                  <a
                    className="nav-link text-active-primary py-5 me-6"
                    data-bs-toggle="tab"
                    href="#permissions"
                  >
                    Permissons
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link text-active-primary py-5 me-6"
                    data-bs-toggle="tab"
                    href="#destroy"
                  >
                    Destroy
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade"
              id="modify-script"
              role="tabpanel"
            >
              <ModifyScript sdata={stacksData} />
            </div>
            {false ? (<></>) : (<>
              <div className="tab-pane fade" id="permissions" role="tabpanel">
                <div className="card">
                  <div
                    className="card-header border-0 cursor-pointer"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_account_deactivate"
                    aria-expanded="true"
                    aria-controls="kt_account_deactivate"
                  >
                    <div className="card-title m-0">
                      <h3 className="fw-bolder m-0">Public / Private</h3>
                    </div>
                  </div>
                  {/*begin::Input group*/}
                  <div className="container mb-10">
                    {/*begin::Label*/}
                    <label className="form-label">Access Control</label>
                    {/*end::Label*/}
                    {/*begin::Input*/}
                    <select
                      class="form-select"
                      onChange={selectAccessControl}
                      id="access-control"
                      aria-label="Select example"
                    >
                      <option value="private">Private</option>
                      <option value="authenticated">Authenticated read</option>
                      <option value="public_read">Public read</option>
                    </select>
                    {/*end::Input*/}
                    {/*begin::Description*/}
                    <div className="text-muted fs-6 mt-5" id="access-control-desc">
                      Private: Only you can list, create, overwrite, and delete
                      Objects in this Stacks.
                    </div>
                    {/*end::Description*/}
                  </div>
                  {/*end::Input group*/}
                </div>
              </div>
            </>)}
            <div className="tab-pane fade active show" id="overview" role="tabpanel">
              <div className="card pt-4 mb-6 mb-xl-9">
                <div className="card-header border-0">
                  <div className="card-title">
                    <h2>Script</h2>
                  </div>
                </div>
                <div className="card-body pt-0 pb-5">
                  <div className="table-responsive">
                    <table
                      className="table align-middle table-row-dashed gy-5"
                      id="kt_table_users_login_session"
                    >
                      <tbody className="fs-6 fw-bold text-gray-600">

                        <tr>
                          <td>
                            <div className="highlight">
                              <div className="highlight-code">
                                <pre className=" language-html" tabIndex={0}>
                                  <code className=" language-html">

                                    <span className="token tag">
                                      <span className="token tag">
                                        <span className="token punctuation">{stacksData.script}</span>
                                      </span>
                                    </span>

                                  </code>
                                </pre>
                              </div>
                            </div>

                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

            </div>
            <div className="tab-pane fade" id="destroy" role="tabpanel">
              <div className="card">
                <div
                  className="card-header border-0 cursor-pointer"
                  role="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_account_deactivate"
                  aria-expanded="true"
                  aria-controls="kt_account_deactivate"
                >
                  <div className="card-title m-0">
                    <h3 className="fw-bold m-0">Destroy Script</h3>
                  </div>
                </div>
                <div id="kt_account_settings_deactivate" className="collapse show">
                  <form id="destroyform" className="form">
                    <div className="card-body border-top p-9">
                      <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
                        <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              opacity="0.3"
                              x={2}
                              y={2}
                              width={20}
                              height={20}
                              rx={10}
                              fill="black"
                            />
                            <rect
                              x={11}
                              y={14}
                              width={7}
                              height={2}
                              rx={1}
                              transform="rotate(-90 11 14)"
                              fill="black"
                            />
                            <rect
                              x={11}
                              y={17}
                              width={2}
                              height={2}
                              rx={1}
                              transform="rotate(-90 11 17)"
                              fill="black"
                            />
                          </svg>
                        </span>
                        <div className="d-flex flex-stack flex-grow-1">
                          <div className="fw-bold">
                            <h4 className="text-gray-900 fw-bold">
                              This will destroy your Script.
                            </h4>
                            <div className="fs-6 text-gray-700">
                              Note: This option will delete your Stack from the
                              server for permanently and this not be able to undo.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mb-10">
                        <label htmlFor="size" className="required form-label">
                          Your Stack will be permanently destroyed.
                        </label>
                      </div>
                    </div>
                    <div
                      className="card-footer d-flex justify-content-end py-6 px-9"
                      id="destroy_btn"
                    >
                      <a
                        onClick={deleteStack}
                        id="delete-stack"
                        type="button"
                        className="btn btn-danger fw-bold"
                      >
                        Delete
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div
            id="ip-modal"
            className="bg-body shadow drawer drawer-end"
            data-kt-drawer="true"
            data-kt-drawer-name="chat"
            data-kt-drawer-activate="true"
            data-kt-drawer-direction="end"
            data-kt-drawer-close="#close_ssh"
            data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
            style={{ maxWidth: "70%", important: true }}
          >
            {/*begin::Messenger*/}
            <div
              className="card rounded-0 w-100 border-0"
              id="kt_drawer_chat_messenger"
            >
              {/*begin::Card header*/}
              <div
                className="card-header pe-5"
                id="kt_drawer_chat_messenger_header"
              >
                {/*begin::Title*/}
                <div className="card-title">
                  {/*begin::User*/}
                  <div className="d-flex justify-content-center flex-column me-3">
                    <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                      Attach Storage block_storage_1
                    </a>
                  </div>
                  {/*end::User*/}
                </div>
                {/*end::Title*/}
                {/*begin::Card toolbar*/}
                <div className="card-toolbar">
                  {/*begin::Close*/}
                  <div
                    onClick={openDrawer}
                    className="btn btn-sm btn-icon btn-active-light-primary"
                    id="close_ssh"
                  >
                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                    <span className="svg-icon svg-icon-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.5"
                          x={6}
                          y="17.3137"
                          width={16}
                          height={2}
                          rx={1}
                          transform="rotate(-45 6 17.3137)"
                          fill="black"
                        />
                        <rect
                          x="7.41422"
                          y={6}
                          width={16}
                          height={2}
                          rx={1}
                          transform="rotate(45 7.41422 6)"
                          fill="black"
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}
                  </div>
                  {/*end::Close*/}
                </div>
                {/*end::Card toolbar*/}
              </div>
              {/*end::Card header*/}
              {/*begin::Card body*/}
              <div className="" id="">
                {/*begin::Form*/}
                <form name="access_ip" id="importkey" className="form">
                  {/*begin::Modal body*/}
                  <div className="modal-body ">
                    {/*begin::Scroll*/}
                    <div
                      className="scroll-y me-n7 pe-7"
                      id="kt_modal_create_api_key_scroll"
                      data-kt-scroll="true"
                      data-kt-scroll-activate="{default: false, lg: true}"
                      data-kt-scroll-max-height="auto"
                      data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                      data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                      data-kt-scroll-offset="300px"
                    >
                      {/*begin::Input group*/}
                      <div className="mb-5 fv-row">
                        {/*begin::Label*/}
                        <label className="required fs-5 fw-bold mb-2">
                          {" "}
                          Servers
                        </label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <select className="form-select" aria-label="Select example">
                          <option>select server</option>
                          <option value={1}>server1</option>
                          <option value={2}>server2</option>
                          <option value={3}>server3</option>
                        </select>

                        {/*end::Input*/}
                      </div>
                      {/*end::Input group*/}
                      {/*begin::Input group*/}
                      <div className="mb-5 fv-row">
                        {/*begin::Label*/}
                        <label className="required fs-5 fw-bold mb-2">
                          {" "}
                          Config
                        </label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <select className="form-select" aria-label="Select example">
                          <option>select configuration</option>
                          <option value={1}>config1</option>
                          <option value={2}>config2</option>
                          <option value={3}>config3</option>
                        </select>

                        {/*end::Input*/}
                      </div>
                      {/*end::Input group*/}
                    </div>
                    {/*end::Scroll*/}
                  </div>
                  {/*end::Modal body*/}
                  {/*begin::Modal footer*/}
                  <div className="modal-footer ">
                    {/*begin::Button*/}
                    <button
                      type="button"
                      className="btn btn-primary w-100"
                      id="importKeyBtn"
                    >
                      <span className="indicator-label"> Attach</span>
                    </button>
                    {/*end::Button*/}
                  </div>
                  {/*end::Modal footer*/}
                </form>
                {/*end::Form*/}
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Messenger*/}
          </div>
        </>
      );
    } else {
      <JoinUs />
    }
  } else {
    return (<><TableLoader /> <TopBarHeaderLine /></>)
  }
}
