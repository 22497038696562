import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import ReactPaginate from "react-paginate";
import AccessDenied from "../../AccessDenied";
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import { Table } from "react-bootstrap";
import TableLoader from "../../Loaders/TableLoader";
import { not } from "ajv/dist/compile/codegen";
import ProductsUrl from "../../Docs/Docsdata";
import CustomDoc from "../../../../CustomHooks/CustomDoc";
export default function Snapshot() {
  const [pageStatus, setPageStatus] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [access, setAccess] = useState(true);
  const [invoiceData, setInvoiceData] = useState(null);
  const [showTextArea, setShowTextArea] = useState(false)
  const [userData, setUserData] = useState(null);
  const [perPage, setPerPage] = useState(5);
  const [notesData, setNotesData] = useState("")
  const [snapshotData, setSnapshotData] = useState(null);
  const [dummySnapshotData, setDummySnapshotData] = useState(null);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [activePage, setActivePage] = useState(0);
  const dataFetchedRef = useRef(false);
  const navigate = useNavigate();
  const navRedirect = () => {
    setTimeout(() => {
      navigate("/snapshots");
    }, 3000);
  };
  let app_info = {};
  let siteUrl = "";
  const loaderFunc = () => {
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if (loader && no_record) {
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");

      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
  };
  const AddNotes = async () => {
    let notes = notesData
    const data = await UseApi("put", "snapshot", "add-notes")
    console.log(data);
  }
  const handleChangeState = (e) => {
    setNotesData(e)
  }
  const getSnapshot = async () => {
    const dt = toast;
    const data = await UseApi("get", "snapshot", "");
    if (data) {
      setDataFetched(true);
    }
    if ("status" in data) {
      if (data.status == "error") {
        toast.update(dt, {
          render: data.message,
          type: "warning",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setDataFetched(true)
        if (data.message == "Permission denied.") {
          setAccess(false);
        } else {
          setAccess(true)
        }
        setSnapshotData([]);
        setDummySnapshotData([])
        // loaderFunc();
        return;
      }
    }
    if (Object.keys(data).length === 0) {
      setDataFetched(true)
      setSnapshotData([]);
      setDummySnapshotData([])
      toast.update(dt, {
        render: "no record found",
        type: "warning",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // loaderFunc();
    } else {
      setDataFetched(true)
      setSnapshotData(data.snapshots);
      setDummySnapshotData(data.snapshots)
      setPageCount(Math.ceil(data.snapshots.length / perPage));
      toast.dismiss();
      //    toast.update(dt,{render: "Your Snapshots request has been successful", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      //  });
    }
  };
  const closeInvoice = () => {
    toast.dismiss();
    var drawerElement = document.querySelector("#invoice_drawer");
    drawerElement.classList.toggle("drawer-on");
    drawerElement.style.width = "1100px";
  };
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getSnapshot();
  }, []);
  const restoreSnapshot = async (cloudId, snapId) => {
    toast.dismiss();
    swal({
      title: "Are you sure?",
      text: "Once restored the snapshot, your server move to the state of this Snapshot",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        setPageStatus(true);
        const dt = toast.loading("Snapshot restoring...");
        const dnsp = await UseApi(
          "POST",
          "cloud/" + cloudId + "/snapshot/" + snapId + "/restore",
          ""
        );
        if (dnsp.status == "error") {
          setPageStatus(false);
          toast.update(dt, {
            render: dnsp.message,
            type: "warning",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          setPageStatus(false);
          toast.update(dt, {
            render: dnsp.message,
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          getSnapshot();
          navRedirect();
        }
      }
    });
  };

  const deleteSnapshot = async (cloudId, snapId) => {
    toast.dismiss();
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Snapshot!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        setPageStatus(true);
        const dt = toast.loading("Snapshot deleting...");
        const dnsp = await UseApi(
          "DELETE",
          "cloud/" + cloudId + "/snapshot/" + snapId + "/delete",
          ""
        );
        //console.log(dnsp);
        if (dnsp.status == "error") {
          setPageStatus(false);
          toast.update(dt, {
            render: dnsp.message,
            type: "warning",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          setPageStatus(false);
          toast.update(dt, {
            render: dnsp.message,
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          getSnapshot();
          navRedirect();
        }
      }
    });
  };

  const openDrawer = () => {
    var drawerElement = document.querySelector("#generateapi-modal");
    drawerElement.classList.toggle("drawer-on");
    drawerElement.style.width = "500px";
  };

  function convertTimestamp(timestamp) {
    const date = new Date(timestamp);

    // Format the date
    const formattedDate = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short',
        timeZone: 'UTC'
    }).format(date);

    return formattedDate.replace('GMT', '(UTC)');
}

  function ProductInfo({ productdata }) {
    console.log(productdata);
    return (
      <>
        {productdata != null && Object.keys(productdata).length != 0 ? (
          productdata &&
          productdata.map((snapData, index) => (
            <>
              <tr>
                <td className="text-start ps-9">
                  <div className=" flex-column justify-content-center w-100">
                    <div className=" flex-stack mb-2">
                      <span className=" fs-7 fw-bold">{snapData.id}</span>
                    </div>
                  </div>
                </td>
                <td className="text-center">
                  <div className="d-flex flex-column w-100 me-2">
                    <div className="flex-stack mb-2" style={{ cursor: "pointer" }}>
                      {snapData.name != null && snapData.name != "" ? (<>
                        <div className=" me-2 fs-7 fw-bold" style={{cursor:'default'}}>{snapData.name}</div>
                      </>) : (<>
                        <div className=" me-2 fs-7 fw-bold" style={{cursor:'default'}}>Snapshot-{snapData.id}</div>
                      </>)}
                    </div>
                  </div>
                </td>
                <td className="text-center">
                  <div className="d-flex flex-column w-100 me-2">
                    <div className="flex-stack mb-2">
                      <Link to={"/cloud/" + snapData.cloudid + ""}>
                        <a className="text-dark me-2 fs-7 fw-bold">{snapData.cloudid}</a>
                      </Link>
                    </div>
                  </div>
                </td>
                <td className="text-center">
                  <div className="d-flex flex-column w-100 me-2">
                    <div className="flex-stack mb-2">
                      <span className=" me-2 fs-7 fw-bold">
                        {snapData.size}GB
                      </span>
                    </div>
                  </div>
                </td>
                <td className="text-center sphide">
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-30px me-5">
                      <img
                        src={"/img/flag_" + snapData.dclocation.dccc + ".png"}
                      />
                    </div>
                    <div className="d-flex justify-content-start flex-column">
                      <a className="text-dark fw-bold text-hover-primary fs-6">
                        {snapData.dclocation.location}
                      </a>
                    </div>
                  </div>
                </td>
                <td className="text-center">
                  <div className="d-flex flex-column w-100 me-2">
                    <div className="flex-stack mb-2">
                      <span className="text-dark me-2 fs-7 fw-bold">
                        {snapData.createdate}
                      </span>
                    </div>
                  </div>
                </td>
                <td className="text-center">
                  <div className="card-toolbar">
                    <button
                      type="button"
                      className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                      onClick={() =>
                        restoreSnapshot(snapData.cloudid, snapData.id)
                      }
                    >
                      <span className="svg-icon svg-icon-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M14.5 20.7259C14.6 21.2259 14.2 21.826 13.7 21.926C13.2 22.026 12.6 22.0259 12.1 22.0259C9.5 22.0259 6.9 21.0259 5 19.1259C1.4 15.5259 1.09998 9.72592 4.29998 5.82592L5.70001 7.22595C3.30001 10.3259 3.59999 14.8259 6.39999 17.7259C8.19999 19.5259 10.8 20.426 13.4 19.926C13.9 19.826 14.4 20.2259 14.5 20.7259ZM18.4 16.8259L19.8 18.2259C22.9 14.3259 22.7 8.52593 19 4.92593C16.7 2.62593 13.5 1.62594 10.3 2.12594C9.79998 2.22594 9.4 2.72595 9.5 3.22595C9.6 3.72595 10.1 4.12594 10.6 4.02594C13.1 3.62594 15.7 4.42595 17.6 6.22595C20.5 9.22595 20.7 13.7259 18.4 16.8259Z"
                            fill="black"
                          />
                          <path
                            opacity="0.3"
                            d="M2 3.62592H7C7.6 3.62592 8 4.02592 8 4.62592V9.62589L2 3.62592ZM16 14.4259V19.4259C16 20.0259 16.4 20.4259 17 20.4259H22L16 14.4259Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                    </button>
                  </div>
                </td>
                <td className="text-center">
                  <div className="card-toolbar">
                    <button
                      type="button"
                      className="btn btn-sm btn-icon btn-color-danger btn-active-light-danger"
                      onClick={() =>
                        deleteSnapshot(snapData.cloudid, snapData.id)
                      }
                    >
                      <span className="svg-icon svg-icon-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                            fill="black"
                          />
                          <path
                            opacity="0.5"
                            d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                            fill="black"
                          />
                          <path
                            opacity="0.5"
                            d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                    </button>
                  </div>
                </td>
                <td className="w-150px d-none">
                  <div
                    className="d-flex justify-content-center"
                    data-kt-filemanager-table-toolbar="base"
                  >
                    <a
                      onClick={() => openDrawer(snapData.id, snapData.notes)}
                      className="btn btn-light-primary btn-sm me-3 d-none"
                      id={"invoice_btn_" + snapData.id}
                    >
                      <i class="bi bi-file-earmark-spreadsheet d-none"></i>{snapData.notes == null ? "Create" : "View"}{" "}
                    </a>
                  </div>
                </td>
              </tr>
            </>
          ))
        ) : (
          <>
            <tr>
              <td colspan="12" className="dataTables_empty" id="loader">
                {/* <div className="loading-spinner"></div> */}
                {/* <TableLoader /> */}
              </td>
              <td colspan="12" className="d-none" id="no_record" valign="top">
                <div className="d-flex flex-column flex-center">
                  <div className="fs-5 fw-bolder text-dark mb-4">
                    No items found.
                  </div>
                </div>
              </td>
            </tr>
          </>
        )}
      </>
    );
  }

  const handleSearch = (text) => {
    const endOffset = itemOffset + perPage;
    const data = snapshotData.filter(row => {
      console.log(row);
      return (
        (row?.name && row?.name.toString().toLowerCase().includes(text.toLowerCase())) ||
        (row.cloudid && row.cloudid.toString().toLowerCase().includes(text.toLowerCase()))
      )
    });
    setDummySnapshotData(data);;
    setPageCount(Math.ceil(data.length / perPage));
    // }
  }

  function PaginatedItems({ itemsPerPage, ProductData }) {
    const endOffset = itemOffset + parseInt(itemsPerPage);
    setPageCount(Math.ceil(ProductData.length / itemsPerPage));
    const handlePageClick = (event) => {
      setActivePage((old) => old = event.selected);
      const newOffset = event.selected * itemsPerPage % ProductData.length;
      setItemOffset(newOffset);
    };

    return (
      <>
        <ProductInfo productdata={ProductData.slice(itemOffset, endOffset)} />
        <td colspan="12" className="ps-3">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            initialPage={activePage}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </td>
      </>
    );
  }

  if(dataFetched){
  if (access) {
    return (
      <>
        <div className="col-xl-12">
          {/*begin::Tables Widget 9*/}
          <div className="card card-xl-stretch mb-5 mb-xl-12">
            {/*begin::Header*/}
            <div className="card-header border-0 pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1">
                  Current Snapshots 
                </span>
                <span className="text-muted mt-1 fw-bold fs-7">
                  View your snapshots
                </span>
              </h3>
              <div className="card-toolbar">
              <div className="position-relative my-1" style={{ marginRight: 20 }}>
                  <CustomDoc url={ProductsUrl[0]?.snapshots}/>
              </div>
                <div
                  className="position-relative my-1"
                  style={{ marginRight: 20 }}
                >
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    data-kt-table-widget-4="search"
                    id="searchClient"
                    onChange={(e) => handleSearch(e.target.value)}
                    placeholder="Search"
                  />
                </div>
                <div style={{ marginRight: 20 }}>
                  <div className="dataTables_length">
                    <label className="w-125px">
                      <select
                        onChange={(e) => setPerPage(e.target.value)}
                        //data-control="select2"
                        className="form-select form-select-sm form-select-solid"
                        defaultValue={5}
                        id="perPage"
                      >
                        <option value={5} selected="">
                          5 Records
                        </option>
                        <option value={10}>10 Records</option>
                        <option value={25}>25 Records</option>
                        <option value={50}>50 Records</option>
                        <option value={100}>100 Records</option>
                      </select>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            {/*begin::Table container*/}
            <div className="table-responsive">
              {/*begin::Table*/}
              <table
                id="snapTable"
                className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
              >
                {/*begin::Table head*/}
                <thead className="bg-secondary">
                  <tr className="fw-bold">
                    <th className="w-125px text-start ps-9"> #ID </th>
                    <th className="w-200px text-center"> Name</th>
                    <th className="w-200px text-center"> Cloud ID </th>
                    <th className="w-200px text-center">Size</th>
                    <th className="w-150px text-center sphide">Snapshot Location</th>
                    <th className="w-100px text-center">Created_at</th>
                    <th className="w-100px text-center">Restore</th>
                    <th className="w-100px text-center">Action</th>
                    {/* <th className="w-100px text-center d-none">Notes</th> */}
                  </tr>
                </thead>
                {/*end::Table head*/}
                {/*begin::Table body*/}
                <tbody id="snapshot-list">
                  {dummySnapshotData != null ? (
                    <>
                      {access == false ? (
                        <>
                          <tr>
                            <td colspan={12}>
                              <AccessDenied />
                            </td>
                          </tr>
                        </>
                      ) : (
                        <>
                          {Object.keys(dummySnapshotData).length != 0 ? (
                            <>
                              <PaginatedItems
                                ProductData={dummySnapshotData}
                                // search={searchTerm}
                                itemsPerPage={perPage}
                              />
                            </>
                          ) : (
                            <>
                              <tr>
                                <td colspan="12" id="no_record" valign="top">
                                  <div className="d-flex flex-column flex-center">
                                    <div className="fs-5 fw-bolder text-dark mb-4">
                                      No items found.
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <tr>
                        <td colspan={12}>
                          <TableLoader />
                          <TopBarHeaderLine />
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
                {/*end::Table body*/}
              </table>
              {/*end::Table*/}
            </div>

            <div
              id="generateapi-modal"
              className="bg-body shadow drawer drawer-end"
              data-kt-drawer="true"
              data-kt-drawer-name="chat"
              data-kt-drawer-activate="true"
              data-kt-drawer-direction="end"
              data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
              style={{ maxWidth: "70%", important: true }}
            >
              {/*begin::Messenger*/}
              <div
                className="card w-100 rounded-0 border-0"
                id="kt_drawer_chat_messenger"
              >
                {/*begin::Card header*/}
                <div
                  className="card-header pe-5"
                  id="kt_drawer_chat_messenger_header"
                >
                  {/*begin::Title*/}
                  <div className="card-title">
                    {/*begin::User*/}
                    <div className="d-flex justify-content-center flex-column me-3">
                      <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                        Add New Notes
                      </a>
                    </div>
                    {/*end::User*/}
                  </div>
                  {/*end::Title*/}
                  {/*begin::Card toolbar*/}
                  <div className="card-toolbar">
                    {/*begin::Close*/}
                    <div
                      onClick={openDrawer}
                      className="btn btn-sm btn-icon btn-active-light-primary"
                      id="close_api"
                    >
                      {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                      <span className="svg-icon svg-icon-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <rect
                            opacity="0.5"
                            x={6}
                            y="17.3137"
                            width={16}
                            height={2}
                            rx={1}
                            transform="rotate(-45 6 17.3137)"
                            fill="black"
                          />
                          <rect
                            x="7.41422"
                            y={6}
                            width={16}
                            height={2}
                            rx={1}
                            transform="rotate(45 7.41422 6)"
                            fill="black"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}
                    </div>
                    {/*end::Close*/}
                  </div>
                  {/*end::Card toolbar*/}
                </div>
                {/*end::Card header*/}
                {/*begin::Card body*/}
                <div className="" id="">
                  {/*begin::Form*/}
                  <form name="generateapi" id="generateapi" className="form">
                    {/*begin::Modal body*/}
                    <div className="modal-body ">
                      {/*begin::Scroll*/}
                      <div
                        className="scroll-y me-n7 pe-7"
                        id="kt_modal_create_api_key_scroll"
                        data-kt-scroll="true"
                        data-kt-scroll-activate="{default: false, lg: true}"
                        data-kt-scroll-max-height="auto"
                        data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                        data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                        data-kt-scroll-offset="300px"
                      >
                        {/*begin::Input group*/}
                        <div className="mb-5 fv-row">
                          {/*begin::Label*/}
                          <form id="add-notes">
                            <label className="required fs-5 fw-bold mb-2">
                              {" "}
                              Notes
                            </label>
                            {/*end::Label*/}
                            {/*begin::Input*/}
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Your Notes"
                              name="note"
                              id="apiName"
                            />
                          </form>
                          {/*end::Input*/}
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}

                        {/*end::Input group*/}
                      </div>
                      {/*end::Scroll*/}
                    </div>
                    {/*end::Modal body*/}
                    {/*begin::Modal footer*/}
                    <div className="modal-footer ">
                      {/*begin::Button*/}
                      <button
                        type="button"
                        onClick={AddNotes}
                        className="btn btn-primary w-100"
                        id="importKey"
                      >
                        <span className="indicator-label">Add New Notes</span>
                      </button>
                      {/*end::Button*/}
                    </div>
                    {/*end::Modal footer*/}
                  </form>
                  {/*end::Form*/}
                </div>

                {/*end::Card body*/}
              </div>

              {/*end::Messenger*/}
            </div>
            {pageStatus && <TopBarHeaderLine />}
            {/*end::Table container*/}
            {/*begin::Body*/}
          </div>
          {/*end::Tables Widget 9*/}
        </div>
      </>
    );
  } else {
    return (
      <>
        <AccessDenied />
      </>
    )
  }
}else{
  return (
    <>
      <TableLoader />
    </>
  )
}
}
