import { React, useEffect, useState } from 'react';
import copy from "copy-to-clipboard";
import Vpc from '../Vpc/Vpc';
import { useParams } from 'react-router-dom';
import UseApi from '../../../../../../CustomHooks/useApi';

export default function MariaDbConnectionDetails(props) {
  const [replicaNodeData, setReplicaNodeData] = useState([]);
  const [databaseInfo, setDatabaseInfo] = useState(null);
  const [currentDatabaseInfo, setCurrentDatabaseInfo] = useState(null);
  const getReplica = async () => {
    if (props) {
      if (props.data && props.currentDatabaseInfo) {
        setDatabaseInfo(props.data);
        setCurrentDatabaseInfo(props.currentDatabaseInfo)
      }
    }
  }
  useEffect(() => {
    getReplica();
  }, [props])

  let { clusterid, cloudid } = useParams();
  const downloadSslCertificate = async () => {
    const responseData = await UseApi('get', 'databases/' + clusterid + '/' + cloudid + '/certificate', '');
    const blob = new Blob([responseData], { type: 'application/pem' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = "ssl-" + currentDatabaseInfo?.hostname + '.pem'; // Specify the desired filename here
    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
  }

  const copyToClipboard = (target, targetBy) => {

    var btn_id = document.querySelector("#" + targetBy);
    var target_id = document.querySelector("#" + target);
    if (btn_id) {
      var copyText = btn_id.innerHTML;
      var copyEle = target_id.innerHTML;
      copy(copyEle);
      btn_id.innerHTML = `<svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9.89557 13.4982L7.79487 11.2651C7.26967 10.7068 6.38251 10.7068 5.85731 11.2651C5.37559 11.7772 5.37559 12.5757 5.85731 13.0878L9.74989 17.2257C10.1448 17.6455 10.8118 17.6455 11.2066 17.2257L18.1427 9.85252C18.6244 9.34044 18.6244 8.54191 18.1427 8.02984C17.6175 7.47154 16.7303 7.47154 16.2051 8.02984L11.061 13.4982C10.7451 13.834 10.2115 13.834 9.89557 13.4982Z"
        fill="black"
      />
    </svg>`;
      setTimeout(() => {
        btn_id.innerHTML = copyText;
      }, 2000);
    }

  }

  if (databaseInfo != null && currentDatabaseInfo != null) {
    if (databaseInfo.length != 0 && currentDatabaseInfo.length != 0) {
      return (<>
        <div className="card pt-4 mb-6 mb-xl-9">
          {/*begin::Card header*/}
          <div className="card-header border-0">
            {/*begin::Card title*/}
            <div className="card-title">
              <h2>Connection Details</h2>
            </div>
            {/*end::Card title*/}
          </div>
          {/*end::Card header*/}
          {/*begin::Card body*/}
          <div className="card-body pt-0 pb-5">
            {/*begin::Table wrapper*/}
            <div className="table-responsive">
              {/*begin::Table*/}
              <table
                className="table align-middle table-row-dashed gy-5"
                id="kt_table_users_login_session"
              >
                {/*begin::Table body*/}
                <tbody className="fs-6 fw-bold text-gray-600">

                  <tr>
                    <td>Primary Node IP</td>
                    <td>
                      <div className='d-flex flex-stack w-75'>
                        {databaseInfo?.nodes?.primary[0]?.network.map((value3, index3) => (<>
                          <span className={`w-50 btn btn-active-${value3.networktype === "public" ? "primary" : "info"} btn-light-${value3.networktype === "public" ? "primary" : "info"} btn-sm sphide me-2 fs-7 fw-bold`}
                            onClick={(e) => copyToClipboard('primary_public_ip', 'public_ipBtn')}>
                            <span>{value3?.networktype?.charAt(0)?.toUpperCase() + value3?.networktype?.slice(1)}&nbsp;IP : </span>
                            <span id="primary_public_ip">
                              {value3?.ip}
                            </span>&nbsp;&nbsp;
                            <span id="public_ipBtn" className="svg-icon svg-icon-light svg-icon-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  opacity="0.5"
                                  d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                                  fill="black"
                                />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                                  fill="black"
                                />
                              </svg>
                            </span>
                          </span>
                        </>))}
                      </div>


                    </td>

                  </tr>


                  {databaseInfo?.nodes?.readonly?.map((value, index) => (<>
                    <tr>
                      <td>{value.hostname}</td>
                      <td>


                        {value?.network?.map((value2, index2) => (<>
                          <span className={`w-50 btn btn-active-${value2.networktype === "public" ? "primary" : "info"} btn-light-${value2.networktype === "public" ? "primary" : "info"} btn-sm sphide me-2 fs-7 fw-bold`}

                            onClick={() => copyToClipboard('ip_address_sec' + index + value2?.networktype, 'ip_address' + index + value2?.networktype)}>
                            <span>{value2?.networktype?.charAt(0)?.toUpperCase() + value2?.networktype?.slice(1)}&nbsp;IP : </span>
                            <span id={"ip_address_sec" + index + value2?.networktype} >
                              {value2?.ip}
                            </span>&nbsp;&nbsp;
                            <span id={"ip_address" + index + value2?.networktype} className="svg-icon svg-icon-light svg-icon-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  opacity="0.5"
                                  d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                                  fill="black"
                                />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                                  fill="black"
                                />
                              </svg>
                            </span>
                          </span>
                        </>))}
                      </td>
                    </tr>
                  </>))}

                  <tr>
                    <td>Port</td>
                    <td>{databaseInfo.port}</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <div className="d-flex flex-stack mb-5 mt-10 w-50">
                        {/*begin::Section*/}
                        <div className="d-flex align-items-center me-2">
                          {/*begin::Symbol*/}
                          <div className="symbol symbol-40px me-5">
                            <div className="symbol-label bg-light">
                              <svg
                                viewBox="0 0 50 50"
                                enableBackground="new 0 0 50 50"
                                id="Layer_1"
                                version="1.1"
                                xmlSpace="preserve"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                fill="#000000"
                              >
                                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <g>
                                    {" "}
                                    <path
                                      d="M45.47913,42.49568H4.5208c-1.9445,0-3.52083-1.57633-3.52083-3.52083V6.5606 c0-1.9445,1.57633-3.52083,3.52083-3.52083h40.95833c1.9445,0,3.52083,1.57633,3.52083,3.52083v32.41425 C48.99996,40.91935,47.42363,42.49568,45.47913,42.49568z"
                                      fill="#E7E3E6"
                                    />{" "}
                                    <g>
                                      {" "}
                                      <path
                                        d="M19.02511,16.47119c-0.13232,0.1084-0.19873,0.25293-0.19873,0.43262s0.08105,0.32227,0.24268,0.42871 c0.16211,0.10547,0.53613,0.23047,1.12207,0.375c0.58545,0.14453,1.04053,0.36035,1.36426,0.64941 c0.32373,0.28809,0.48584,0.70898,0.48584,1.2627s-0.20752,1.00293-0.62256,1.34668 c-0.41504,0.34473-0.96143,0.5166-1.63818,0.5166c-0.97754,0-1.85791-0.36133-2.64111-1.08594l0.82129-1.00684 c0.66553,0.58301,1.28076,0.87402,1.84619,0.87402c0.25293,0,0.45166-0.05371,0.59619-0.16309 c0.14404-0.10938,0.21631-0.25586,0.21631-0.44141s-0.07666-0.33301-0.22949-0.44141 c-0.15332-0.10938-0.45654-0.21973-0.90967-0.33203c-0.71875-0.16992-1.24414-0.39258-1.57666-0.66602 c-0.33301-0.27441-0.49902-0.7041-0.49902-1.29004s0.21045-1.03808,0.63135-1.35547 c0.4209-0.31836,0.94629-0.47754,1.57666-0.47754c0.41211,0,0.82422,0.07129,1.23633,0.21289 c0.41211,0.14063,0.77148,0.34082,1.07764,0.60059l-0.69775,1.00683c-0.53564-0.40625-1.08936-0.60938-1.66064-0.60938 C19.33859,16.30811,19.15744,16.36182,19.02511,16.47119z"
                                        fill="#85BD57"
                                      />{" "}
                                      <path
                                        d="M24.65988,16.47119c-0.13232,0.1084-0.19873,0.25293-0.19873,0.43262s0.08105,0.32227,0.24268,0.42871 c0.16211,0.10547,0.53613,0.23047,1.12207,0.375c0.58545,0.14453,1.04053,0.36035,1.36426,0.64941 c0.32373,0.28809,0.48584,0.70898,0.48584,1.2627s-0.20752,1.00293-0.62256,1.34668 c-0.41504,0.34473-0.96143,0.5166-1.63818,0.5166c-0.97754,0-1.85791-0.36133-2.64111-1.08594l0.82129-1.00684 c0.66553,0.58301,1.28076,0.87402,1.84619,0.87402c0.25293,0,0.45166-0.05371,0.59619-0.16309 c0.14404-0.10938,0.21631-0.25586,0.21631-0.44141s-0.07666-0.33301-0.22949-0.44141 c-0.15332-0.10938-0.45654-0.21973-0.90967-0.33203c-0.71875-0.16992-1.24414-0.39258-1.57666-0.66602 c-0.33301-0.27441-0.49902-0.7041-0.49902-1.29004s0.21045-1.03808,0.63135-1.35547 c0.4209-0.31836,0.94629-0.47754,1.57666-0.47754c0.41211,0,0.82422,0.07129,1.23633,0.21289 c0.41211,0.14063,0.77148,0.34082,1.07764,0.60059l-0.69775,1.00683c-0.53564-0.40625-1.08936-0.60938-1.66064-0.60938 C24.97335,16.30811,24.7922,16.36182,24.65988,16.47119z"
                                        fill="#85BD57"
                                      />{" "}
                                      <path
                                        d="M28.8508,21.4126v-6.17383h1.37744v4.94629h2.63232v1.22754H28.8508z"
                                        fill="#85BD57"
                                      />{" "}
                                    </g>{" "}
                                    <path
                                      d="M48.99997,10.066V6.56059c0-1.94453-1.57633-3.52086-3.52081-3.52086H4.52079 c-1.94448,0-3.52081,1.57633-3.52081,3.52086V10.066H48.99997z"
                                      fill="#53B1E2"
                                    />{" "}
                                    <path
                                      d="M17.06462,10.71367H4.45078v-2.7194c0-0.67552,0.54762-1.22313,1.22313-1.22313h10.16758 c0.67552,0,1.22313,0.54762,1.22313,1.22313V10.71367z"
                                      fill="#E7E3E6"
                                    />{" "}
                                    <path
                                      d="M29.67847,10.066H17.06462V7.99427c0-0.67552,0.54762-1.22313,1.22313-1.22313h10.16758 c0.67552,0,1.22313,0.54762,1.22313,1.22313V10.066z"
                                      fill="#D2D2D2"
                                    />{" "}
                                    <circle cx="40.42995" cy="6.85486" fill="#E7E3E6" r="1.34955" />{" "}
                                    <circle cx="36.3145" cy="6.85486" fill="#E7E3E6" r="1.34955" />{" "}
                                    <g>
                                      {" "}
                                      <polygon
                                        fill="#656766"
                                        points="38.25036,36.57332 40.56499,46.96027 35.88287,44.40302 35.88287,36.57332 "
                                      />{" "}
                                      <polygon
                                        fill="#4E4C4D"
                                        points="33.51537,36.57332 31.20074,46.96027 35.88287,44.40302 35.88287,36.57332 "
                                      />{" "}
                                      <path
                                        d="M41.5854,35.56115l-0.00003,0.00003v0.00006c0,1.84353-1.49448,3.33801-3.33801,3.33801h0l-0.006,0.006 c-1.30357,1.30357-3.41708,1.30357-4.72065,0l-0.006-0.006h-0.00006c-1.84353,0-3.33801-1.49448-3.33801-3.33801v-0.00901 l-0.00006-0.00006c-1.30189-1.30192-1.30187-3.41271,0.00003-4.71461l0.00003-0.00003v-0.01326 c0-1.84118,1.49257-3.33375,3.33375-3.33375h0.01332l0,0c1.30191-1.30191,3.41272-1.30191,4.71464,0v0h0.00896 c1.84356,0,3.33806,1.4945,3.33806,3.33806v0l0,0C42.89226,32.13543,42.89227,34.25428,41.5854,35.56115z"
                                        fill="#EC6E62"
                                      />{" "}
                                      <circle cx="35.88287" cy="33.19849" fill="#FFC966" r="3.37482" />{" "}
                                    </g>{" "}
                                    <g>
                                      {" "}
                                      <rect
                                        fill="#D2D2D2"
                                        height="2.22496"
                                        width="18.92076"
                                        x="5.64066"
                                        y="26.51408"
                                      />{" "}
                                      <rect
                                        fill="#D2D2D2"
                                        height="2.22496"
                                        width="18.92076"
                                        x="5.64066"
                                        y="30.83174"
                                      />{" "}
                                      <rect
                                        fill="#D2D2D2"
                                        height="2.22491"
                                        width="11.15332"
                                        x="5.64065"
                                        y="35.14944"
                                      />{" "}
                                    </g>{" "}
                                  </g>{" "}
                                </g>
                              </svg>

                            </div>
                          </div>
                          {/*end::Symbol*/}
                          {/*begin::Title*/}
                          <div>
                            <a href="#" className="fs-6 text-gray-800 text-hover-primary fw-bolder">
                              Download CA Certificate
                            </a>
                            <div className="fs-7 text-muted fw-bold mt-1">
                              This Certificate attached with your Database cluster
                            </div>
                          </div>
                          {/*end::Title*/}
                        </div>
                        {/*end::Section*/}
                        {/*begin::Label*/}
                        <div className="fw-bold py-4 px-3 ms-10">
                          {/*begin::Svg Icon | path: assets/media/icons/duotune/arrows/arr091.svg*/}
                          <span onClick={downloadSslCertificate} className="svg-icon svg-icon-primary svg-icon-2hx" style={{ cursor: "pointer" }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <rect
                                opacity="0.3"
                                width={12}
                                height={2}
                                rx={1}
                                transform="matrix(0 -1 -1 0 12.75 19.75)"
                                fill="black"
                              />
                              <path
                                d="M12.0573 17.8813L13.5203 16.1256C13.9121 15.6554 14.6232 15.6232 15.056 16.056C15.4457 16.4457 15.4641 17.0716 15.0979 17.4836L12.4974 20.4092C12.0996 20.8567 11.4004 20.8567 11.0026 20.4092L8.40206 17.4836C8.0359 17.0716 8.0543 16.4457 8.44401 16.056C8.87683 15.6232 9.58785 15.6554 9.9797 16.1256L11.4427 17.8813C11.6026 18.0732 11.8974 18.0732 12.0573 17.8813Z"
                                fill="black"
                              />
                              <path
                                d="M18.75 15.75H17.75C17.1977 15.75 16.75 15.3023 16.75 14.75C16.75 14.1977 17.1977 13.75 17.75 13.75C18.3023 13.75 18.75 13.3023 18.75 12.75V5.75C18.75 5.19771 18.3023 4.75 17.75 4.75L5.75 4.75C5.19772 4.75 4.75 5.19771 4.75 5.75V12.75C4.75 13.3023 5.19771 13.75 5.75 13.75C6.30229 13.75 6.75 14.1977 6.75 14.75C6.75 15.3023 6.30229 15.75 5.75 15.75H4.75C3.64543 15.75 2.75 14.8546 2.75 13.75V4.75C2.75 3.64543 3.64543 2.75 4.75 2.75L18.75 2.75C19.8546 2.75 20.75 3.64543 20.75 4.75V13.75C20.75 14.8546 19.8546 15.75 18.75 15.75Z"
                                fill="#C4C4C4"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </div>
                        {/*end::Label*/}
                      </div>
                    </td>
                  </tr>
                </tbody>
                {/*end::Table body*/}
              </table>
              {/*end::Table*/}
            </div>
            {/*end::Table wrapper*/}
            <Vpc data={currentDatabaseInfo} />
          </div>
          {/*end::Card body*/}
        </div>
      </>);
    }
  }
}