import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import Swal from 'sweetalert2';
import copy from "copy-to-clipboard";
import UseCallApi from "../../../../CustomHooks/useCallApi";
import ReactPaginate from "react-paginate";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import UseApi from "../../../../CustomHooks/useApi";
import ActionList from "./helpers/ActionList";
import PublicRegistry from "./tab/PublicRegistry";
import PrivateRegistry from "./tab/PrivateRegistry";
import CreateProject from "./tab/CreateProject";
import { PaginatedItems } from "./tab/PaginatedItems";
import CustomDoc from "../../../../CustomHooks/CustomDoc";
import ProductsUrl from "../../Docs/Docsdata";

export default function ContainerRegistry(props) {
    let navigate = useNavigate()
    const [allPrivateData, setAllPrivateData] = useState(null)
    const [allPublicData, setAllPublicData] = useState(null)
    const [dummyAllPrivateData, setDummyAllPrivateData] = useState(null)
    const [dummyAllPublicData, setDummyAllPublicData] = useState(null)
    const [pageStatus, setPageStatus] = useState(false)
    const [perPage, setPerPage] = useState(5);
    const [isUpdate, setIsUpdate] = useState(false)
    const [objectForUpdate, setObjectForUpdate] = useState({})
    var query = window.location.href;
    var vars = query.split("/");
    var id = vars[4];

    let { clusterid, cloudid } = useParams();
    useEffect(() => {
        window.KTScroll.init();
        window.KTDrawer.init();
        window.KTMenu.createInstances();
        window.KTToggle.createInstances();
    }, []);
    const getAllRegistry = async (id) => {
        try {
            let responseData = await UseCallApi('get', `registry/projects`, '')
            if (responseData.status === "success") {
                const publicTrueData = responseData.data.filter(item => item.metadata && item.metadata.public === "true");
                setAllPublicData(publicTrueData?.reverse())
                setDummyAllPublicData(publicTrueData?.reverse())
                const privateTrueData = responseData.data.filter(item => item.metadata && item.metadata.public === "false");
                setAllPrivateData(privateTrueData?.reverse())
                setDummyAllPrivateData(privateTrueData?.reverse())
                setPageStatus(!pageStatus)
                // setAllData(responseData.data)
            } else {
                setAllPrivateData([])
                setAllPublicData([])
                setDummyAllPrivateData([])
                setDummyAllPublicData([])
            }
        } catch (error) {
            setAllPrivateData([])
            setAllPublicData([])
            setDummyAllPrivateData([])
            setDummyAllPublicData([])
        }
    }

    const switchPage = () => {
        navigate('/container-registry/create')
    }

    const [registryUser, setRegistryUser] = useState(null);
    const getRegistryDetails = async () => {
        const dt = toast
        const data = await UseApi('get', 'registry', '');
        if (data) {
            if (data['status'] == 'success') {

                setRegistryUser(data?.info);
            } else {
                setRegistryUser(null);
            }
        }
    }

    useEffect(() => {
        getRegistryDetails();
        let obj = localStorage.getItem('user_info')
        let data = JSON.parse(obj)
        if (data) {
            getAllRegistry(data.id)
        }
    }, [])

    const redirectPage = () => {
        props.switchPage([])
    }

    function convertTimestamp(timestamp) {
        const date = new Date(timestamp);

        // Format the date
        const formattedDate = new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZoneName: 'short',
            timeZone: 'UTC'
        }).format(date);

        return formattedDate.replace('GMT', '(UTC)');
    }
    const openDrawer = () => {

        var drawerElement = document.querySelector("#connection-modal");
        var drawer_overlay = document.querySelector("#drawer_overlay_connection");
        if (drawerElement && drawer_overlay) {
            drawerElement.classList.toggle("drawer-on");
            if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
                drawerElement.style.width = "100%";
            } else {
                drawerElement.style.width = "60%";
            }
            drawer_overlay.classList.toggle("d-none");
        }
    };

    const loaderFunc = () => {
        var loader = document.querySelector("#loader");
        var no_record = document.querySelector("#no_record");
        if (loader && no_record) {
            no_record.classList.add("d-none");
            loader.classList.remove("d-none");

            setTimeout(() => {
                loader.classList.add("d-none");
                no_record.classList.remove("d-none");
            }, 100);
        }
    }

    const openDoc = () => {
        var drawerElement = document.querySelector("#cr-doc");
        var drawer_overlay = document.querySelector("#drawer_overlay_cr_doc");
        if (drawerElement && drawer_overlay) {
            drawerElement.classList.toggle("drawer-on");
            if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
                drawerElement.style.width = "100%";
            } else {
                drawerElement.style.width = "50%";
            }
            drawer_overlay.classList.toggle("d-none");
        }
    };

    const copyToClipboard = (target, targetBy, svgIcon) => {

        var btn_id = document.querySelector("#" + targetBy);
        var target_id = document.querySelector("#" + target);
        var svgIconElement = document.querySelector("#" + svgIcon)
        if (btn_id && !btn_id.classList.contains('disabled')) {
            var copyText = btn_id.innerHTML;
            var copyEle = target_id.innerHTML;
            copy(copyEle);
            // svgIconElement.classList.add('d-none')
            btn_id.innerHTML = `<svg
          xmlns="http://www.w3.org/2000/svg"
          width=24px
          height=24px
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M9.89557 13.4982L7.79487 11.2651C7.26967 10.7068 6.38251 10.7068 5.85731 11.2651C5.37559 11.7772 5.37559 12.5757 5.85731 13.0878L9.74989 17.2257C10.1448 17.6455 10.8118 17.6455 11.2066 17.2257L18.1427 9.85252C18.6244 9.34044 18.6244 8.54191 18.1427 8.02984C17.6175 7.47154 16.7303 7.47154 16.2051 8.02984L11.061 13.4982C10.7451 13.834 10.2115 13.834 9.89557 13.4982Z"
            fill="black"
          />
        </svg>`;
            btn_id.classList.add('disabled')
            btn_id.style.pointerEvents = "none";
            setTimeout(() => {
                btn_id.innerHTML = copyText;
                btn_id.style.pointerEvents = "auto";
                btn_id.classList.remove('disabled')
                // svgIconElement.classList.remove('d-none')
            }, 2000);
        }

    }

    return (<>
        <>
            <div className="col-xl-12">
                <div className="card card-xl-stretch mb-5 mb-xl-12">
                    <div className="card-header border-0 mb-5 pt-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bolder text-gray-800">
                                Container Registry{" "}
                            </span>
                         </h3>
                        <div className="card-toolbar sphide">
                        <div className="position-relative my-1" style={{ marginRight: 20 }}>
                        <CustomDoc url={ProductsUrl[0]?.container_registry+"/getting-started/"}/>
                        </div>
                            {/* <a href="/loadbalancer/deploy" /> */}
                            <div style={{ marginRight: 0 }}>
                                {/* <a href="/loadbalancer/deploy" /> */}
                                {registryUser != null ? (<>
                                    <a className="btn btn-sm btn-light-primary border border-primary me-3" data-bs-toggle="modal" data-bs-target="#cr_cred">
                                        <span className="svg-icon svg-icon-3">
                                            <i className="bi bi-eye fs-2" id="eye-open"></i>
                                        </span>
                                        View Credentials
                                    </a>
                                </>) : ('')}

                                <a className="btn btn-sm btn-primary" onClick={switchPage}>
                                    <span className="svg-icon svg-icon-3">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <rect
                                                opacity="0.5"
                                                x="11.364"
                                                y="20.364"
                                                width={16}
                                                height={2}
                                                rx={1}
                                                transform="rotate(-90 11.364 20.364)"
                                                fill="black"
                                            />
                                            <rect
                                                x="4.36396"
                                                y="11.364"
                                                width={16}
                                                height={2}
                                                rx={1}
                                                fill="black"
                                            />
                                        </svg>
                                    </span>
                                    Create Repository
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="">
                    <ul className="nav nav-pills nav-pills-custom row position-relative mx-0">

                        <li className="nav-item col-6 mx-0 px-0">
                            <a
                                className="nav-link active d-flex justify-content-center w-100 border-0 h-100"
                                data-bs-toggle="pill"
                                href="#public-tab"
                                id="load-balance-tabs"
                            >
                                <span className="sphide nav-text text-gray-800 fw-bolder fs-6 mb-3">
                                    Public Registry
                                </span>
                                <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded" />
                            </a>
                        </li>
                        <li className="nav-item col-6 mx-0 px-0">
                            <a
                                id="target-group-tabs"
                                className="nav-link d-flex justify-content-center w-100 border-0 h-100"
                                data-bs-toggle="pill"
                                href="#private-tab"
                            >
                                <span className="sphide nav-text text-gray-800 fw-bolder fs-6 mb-3">
                                    Private Registry
                                </span>
                                <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded" />
                            </a>
                        </li>
                        <span className="position-absolute z-index-1 bottom-0 w-100 h-4px bg-light rounded" />
                    </ul>
                </div>

                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade active show" id="public-tab" role="tabpanel">
                        <PublicRegistry allPublicData={allPublicData} setAllPublicData={setAllPublicData} setDummyAllPrivateData={setDummyAllPrivateData} dummyAllPublicData={dummyAllPublicData} setDummyAllPublicData={setDummyAllPublicData} switchPage={redirectPage} getAllRegistry={getAllRegistry} allPrivateData={allPrivateData} />
                    </div>
                    <div className="tab-pane fade" id="private-tab" role="tabpanel">
                        <PrivateRegistry showButton={false} allPublicData={allPublicData} pageStatus={pageStatus} switchPage={redirectPage} dummyAllPrivateData={dummyAllPrivateData} setDummyAllPrivateData={setDummyAllPrivateData} setDummyAllPublicData={setDummyAllPublicData} allPrivateData={allPrivateData} setAllPrivateData={setAllPrivateData} getAllRegistry={getAllRegistry} />
                    </div>

                </div>
            </div>
            <CreateProject getAllRegistry={getAllRegistry} />
 
            <div
                className="modal fade "
                id="cr_cred"
                tabIndex={-1}
                aria-hidden="true"
            >
                {/*begin::Modal header*/}
                <div className="modal-dialog modal-dialog-centered mw-650px">
                    {/*begin::Modal content*/}
                    <div className="modal-content">
                        {/*begin::Modal header*/}
                        <div className="modal-header flex-stack">
                            {/*begin::Title*/}
                            <h2>Container Registry Access Credentials</h2>
                            {/*end::Title*/}
                            {/*begin::Close*/}
                            <div
                                className="btn btn-sm btn-icon btn-active-color-primary"
                                data-bs-dismiss="modal"
                            >
                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                                <span className="svg-icon svg-icon-1">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <rect
                                            opacity="0.5"
                                            x={6}
                                            y="17.3137"
                                            width={16}
                                            height={2}
                                            rx={1}
                                            transform="rotate(-45 6 17.3137)"
                                            fill="black"
                                        />
                                        <rect
                                            x="7.41422"
                                            y={6}
                                            width={16}
                                            height={2}
                                            rx={1}
                                            transform="rotate(45 7.41422 6)"
                                            fill="black"
                                        />
                                    </svg>
                                </span>
                                {/*end::Svg Icon*/}
                            </div>
                            {/*end::Close*/}
                        </div>
                        {/*begin::Modal header*/}
                        {/*begin::Modal body*/}
                        <div className="modal-body scroll-y pt-10 pb-15 px-lg-17">
                            {/*begin::Options*/}
                            <div data-kt-element="options">
                                {/*begin::Notice*/}
                                <p className="text-muted fs-5 fw-bold mb-10">
                                    These credentials will allow you to log in, push and pull container images as needed for your projects.</p>
                                {/*end::Notice*/}
                                {/*begin::Wrapper*/}
                                <div className="pb-10">
                                    {/*begin::Option*/}
                                    <input
                                        type="radio"
                                        className="btn-check"
                                        name="auth_option"
                                        defaultValue="apps"
                                        defaultChecked="checked"
                                        id="kt_modal_two_factor_authentication_option_1"
                                    />
                                    <label
                                        className="btn btn-outline active btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-5"
                                    >
                                        <span className="d-block fw-bold text-start">
                                            <table className="table table-flush fw-bold gy-1" style={{ height: '155px !important' }}>
                                                <tbody>
                                                    <tr>
                                                        <td className="text-dark fw-bolder w-75px">Username</td>
                                                        <td className="text-muted w-25px">&nbsp;=</td>
                                                        <td className="text-gray-800 w-300px"><span id="cr_username_val">{registryUser != null ? (registryUser?.user_name) : ('')}</span>
                                                            {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen054.svg*/}
                                                            <span className="svg-icon svg-icon-primary svg-icon-2 ms-2" id="cr_username_btn" onClick={(e) => copyToClipboard('cr_username_val', 'cr_username_btn')}>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={24}
                                                                    height={24}
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                >
                                                                    <path
                                                                        opacity="0.5"
                                                                        d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                                                                        fill="black"
                                                                    />
                                                                    <path
                                                                        fillRule="evenodd"
                                                                        clipRule="evenodd"
                                                                        d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                                                                        fill="black"
                                                                    />
                                                                </svg>
                                                            </span>
                                                            {/*end::Svg Icon*/}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-dark fw-bolder w-75px">Password</td>
                                                        <td className="text-muted w-25px">&nbsp;=</td>
                                                        {/* {databaseInfo.databases()} */}
                                                        <td className="text-gray-800 w-175px"><span id="cr_password_val">{registryUser != null ? (registryUser?.password) : ('')}</span>
                                                            {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen054.svg*/}
                                                            <span className="svg-icon svg-icon-primary svg-icon-2 ms-2" id="cr_password_btn" onClick={(e) => copyToClipboard('cr_password_val', 'cr_password_btn')}>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={24}
                                                                    height={24}
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                >
                                                                    <path
                                                                        opacity="0.5"
                                                                        d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                                                                        fill="black"
                                                                    />
                                                                    <path
                                                                        fillRule="evenodd"
                                                                        clipRule="evenodd"
                                                                        d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                                                                        fill="black"
                                                                    />
                                                                </svg>
                                                            </span>
                                                            {/*end::Svg Icon*/}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </span>
                                    </label>
                                    {/*end::Option*/}
                                </div>
                                {/*end::Options*/}
                                {/*begin::Action*/}
                                <p className="text-muted fs-5 fw-bold mb-10">
                                    Please keep these credentials secure and do not share them with unauthorized individuals. </p>
                                {/*end::Action*/}
                            </div>
                            {/*end::Options*/}
                        </div>
                        {/*begin::Modal body*/}
                    </div>
                    {/*end::Modal content*/}
                </div>
                {/*end::Modal header*/}
            </div>
        </>
    </>)
}