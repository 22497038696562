import {React} from 'react';

export default function FooterSection(props){
    if(props && props.address && props.name && props.year){
    return(
        <>
         <tr>
                <td
                  align="center"
                  valign="center"
                  style={{
                    fontSize: 13,
                    textAlign: "center",
                    padding: 20,
                    color: "#6d6e7c"
                  }}
                >
                  <p>{props.address}</p>
                  <p>
                  ©{props.year} Copyright &nbsp;
                    <a href="#" rel="noopener">
                      {props.name}
                    </a>
                    .
                  </p>
                </td>
              </tr>
        </>
    )
      }
}