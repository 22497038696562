import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UsePost from "../../../../CustomHooks/usePost";
import ReactPaginate from "react-paginate";
import AccessDenied from "../../AccessDenied";
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../Loaders/TableLoader";

export default function Ticket() {
  const [dataFetched, setDataFetched] = useState(false);
  const [pageStatus, setPageStatus] = useState(false)
  const [access, setAccess] = useState(true);
  const [perPage, setPerPage] = useState(5);
  const [ticketData, setTicketData] = useState(null);
  const [allTicketData, setAllTicketData] = useState(null);
  const [openTicket, setOpenTicket] = useState(0);
  const [waitingTicket, setWaitingTicket] = useState(0);
  const [closedTicket, setClosedTicket] = useState(0);
  const dataFetchedRef = useRef(false);
  const loaderFunc = () => {
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if (loader && no_record) {
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");

      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
  };

  const handelTicketData = (data) => {
if(data != null){
  var data = ticketData.filter((key) => key.status == data);
  setAllTicketData(data);
}else{
  setAllTicketData(null)
}
  };

  function ProductInfo({ productdata }) {
    return (
      <>
        {productdata != null && Object.keys(productdata).length != 0 ? (
          productdata &&
          productdata.map((value, index) => (
            <tr>
              {" "}
              <td className="p-3 text-center">
                {" "}
                <div className="d-flex  justify-content-center w-100 me-2">
                  {" "}
                  <div className="d-flex flex-stack text-center mb-2">
                    {" "}
                    <span className=" me-2 fs-7 fw-bold text-center">
                      {value.tid}
                    </span>{" "}
                  </div>{" "}
                </div>{" "}
              </td>{" "}
              <td>
                {" "}
                <div className="d-flex align-items-center">
                  {" "}
                  <div className="d-flex justify-content-start flex-column">
                    {" "}
                    <Link to={"/ticket/" + value.id + ""}>
                      <a className="text-primary fw-bold text-hover-primary fs-6">
                        {value.subject}
                      </a>{" "}
                    </Link>
                  </div>{" "}
                </div>{" "}
              </td>{" "}
              <td className="text-end">
                {" "}
                <div className="d-flex flex-column w-100 me-2">
                  {" "}
                  <div className="d-flex flex-stack mb-2">
                    {" "}
                    <span className=" me-2 fs-7 fw-bold">
                      {value.departmentname}
                    </span>{" "}
                  </div>{" "}
                </div>{" "}
              </td>{" "}
              <td className="text-end">
                {" "}
                <div className="d-flex flex-column w-100 me-2">
                  {" "}
                  <div className="d-flex flex-stack mb-2">
                    {" "}
                    {value.status == "1" ? (
                      <span className="badge badge-light-primary me-2 fs-7 fw-bold">
                        Open{" "}
                      </span>
                    ) : value.status == "2" ? (
                      <span className="badge badge-light-success me-2 fs-7 fw-bold">
                        Answered{" "}
                      </span>
                    ) : value.status == "3" ? (
                      <span class="badge badge-light-primary me-2 fs-7 fw-bold">
                        Customer-Reply
                      </span>
                    ) : value.status == "4" ? (
                      <span class="badge badge-light-danger me-2 fs-7 fw-bold">
                        Closed
                      </span>
                    ) : value.status == "5" ? (
                      <span class="badge badge-light-warning me-2 fs-7 fw-bold">
                        On Hold
                      </span>
                    ) : value.status == "6" ? (
                      <span class="badge badge-light-info me-2 fs-7 fw-bold">
                        Work in Progress
                      </span>
                    ) : value.status == "7" ? (
                      <span class="badge badge-light-warning me-2 fs-7 fw-bold">
                        Pending on Customer
                      </span>
                    ) : value.status == "8" ? (
                      <span class="badge badge-light-warning me-2 fs-7 fw-bold">
                        Waiting for Task
                      </span>
                    ) : (
                      <span class="badge badge-light-warning me-2 fs-7 fw-bold">
                        Pending for Approval
                      </span>
                    )}{" "}
                  </div>{" "}
                </div>{" "}
              </td>{" "}
              <td className="text-end">
                {" "}
                <div className="d-flex flex-column w-100 me-2">
                  {" "}
                  <div className="d-flex flex-stack mb-2">
                    {" "}
                    <span className="text-muted me-2 fs-7 fw-bold">
                      {value.lastreply}
                    </span>{" "}
                  </div>{" "}
                </div>{" "}
              </td>{" "}
              <td>
                {" "}
                <div className="d-flex justify-content-end flex-shrink-0">
                  {" "}
                  <Link to={"/ticket/" + value.id + ""}>
                    <a className="btn btn-primary btn-active-light-primary btn-sm me-1 me-5 sphide">
                      {" "}
                      Manage{" "}
                      <span className="svg-icon ms-2 svg-icon-3">
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          {" "}
                          <rect
                            opacity="0.5"
                            x={18}
                            y={13}
                            width={13}
                            height={2}
                            rx={1}
                            transform="rotate(-180 18 13)"
                            fill="black"
                          />{" "}
                          <path
                            d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                            fill="black"
                          />{" "}
                        </svg>{" "}
                      </span>{" "}
                    </a>{" "}
                  </Link>
                </div>{" "}
              </td>{" "}
            </tr>
          ))
        ) : (
          <>
            <tr>
              <td colspan="12" className="dataTables_empty" id="loader">
                {/* <div className="loading-spinner"></div> */}
                <TableLoader />
              </td>
              <td colspan="12" className="d-none" id="no_record" valign="top">
                <div className="d-flex flex-column flex-center">
                  <div className="fs-5 fw-bolder text-dark mb-4">
                    No items found.
                  </div>
                </div>
              </td>
            </tr>
          </>
        )}
      </>
    );
  }

  const getTickets = async (page) => {
    const dt = toast
    const data = await UseApi("get", "ticket?page=" + page + "", "");
    if (data) {
      setDataFetched(true);
    }
    if ("status" in data) {
      if (data.status == "error") {
        setTicketData([]);
        setAllTicketData(null);
        toast.update(dt, {
          render: data.message,
          type: "warning",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setAccess(false);
        loaderFunc();
        return;
      }
    }
    if (Object.keys(data).length === 0) {
      setTicketData([]);
      setAllTicketData(null);
      toast.update(dt, {
        render: "no record found",
        type: "warning",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      loaderFunc();
    } else {
      setTicketData(data.tickets);
      toast.dismiss();
      //   toast.update(dt,{render: "Your Tickets request has been successful", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      // });
    }
  };

  // const headTool=()=>{
  if (ticketData != null) {
    var open_Ticket = 0;
    var total_Ticket = ticketData.length
    var waiting_Ticket = 0;
    var closed_Ticket = 0;
    ticketData.map((value, index) => {
      if (value.status == 1) {
        open_Ticket = open_Ticket + 1;
      } else if (
        value.status == 3 ||
        value.status == 5 ||
        value.status == 6 ||
        value.status == 2 ||
        value.status == 7 ||
        value.status == 8 ||
        value.status == 12
      ) {
        waiting_Ticket++;
      } else if (value.status == 4) {
        closed_Ticket++;
      }
    });
    ////console.log(closed_Ticket);
    // setOpenTicket(open_Ticket);
    // setWaitingTicket(waiting_Ticket);
    // setClosedTicket(closed_Ticket);
  }
  //}
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getTickets(1);
  }, []);

  const openDrawer = () => {
    var drawerElement = document.querySelector("#openTicket-modal");
    drawerElement.classList.toggle("drawer-on");
    drawerElement.style.width = "700px";
  };

  const navigate = useNavigate();
  const createTicket = async () => {
    toast.dismiss();
    const dt = toast.loading("Please wait...");
    const submitBtn = document.querySelector("#createTicket");
    if (submitBtn) {
      setPageStatus(true)
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML =
        'Please wait... <span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      const responseData = await UsePost("post", "ticket/new", "newticket");
      if (responseData.status === "error") {
        setPageStatus(false)
        toast.update(dt, {
          render: responseData.message,
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (responseData.status === "success") {
        setPageStatus(false)
        toast.update(dt, {
          render: responseData.message,
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        getTickets(1);
        navigate("/ticket/" + responseData.ticketid);
      }
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML = preContent;
    }
  };
  const [searchTerm, setSearchTerm] = useState("");
  function PaginatedItems({ itemsPerPage, ProductData, search }) {
    // We start with an empty list of items.
    const [pageCount, setPageCount] = useState(0);
    const [vpcData, setVpcData] = useState(null);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
      // Fetch items from another resources.

      const endOffset = itemOffset + itemsPerPage;
var data;
      //  ////console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      if (allTicketData != null) {
         data = allTicketData.filter((row) => {
          return Object.values(row).some((value) => {
            if (value != null) {
              if (
                value.toString().toLowerCase().includes(search.toLowerCase())
              ) {
                return value;
              }
            }
          });
        });
      }else{
         data = ProductData.filter((row) => {
          return Object.values(row).some((value) => {
            if (value != null) {
              if (
                value.toString().toLowerCase().includes(search.toLowerCase())
              ) {
                return value;
              }
            }
          });
        });
      }
      // if (ProductData != null) {
      //   //   var str="Noida";
      //   // ////console.log(ProductData);
      //   const data = ProductData.filter((row) => {
      //     return Object.values(row).some((value) => {
      //       if (value != null) {
      //         if (
      //           value.toString().toLowerCase().includes(search.toLowerCase())
      //         ) {
      //           return value;
      //         }
      //       }
      //     });
      //   });
        if (data.length <= 0) {
          loaderFunc();
        }
        setVpcData(data.slice(itemOffset, endOffset));
        //setCurrentItems(items.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(data.length / itemsPerPage));
        // alert(pageCount);
      // }
    }, [itemOffset, itemsPerPage]);

    // Invoke when user click to request another page.

    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % ProductData.length;
      ////console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
      setItemOffset(newOffset);
    };
    var searchEle = document.querySelector("#searchClient");
    if (searchEle) {
      searchEle.focus();
    }
    return (
      <>
        <ProductInfo productdata={vpcData} />
        <td colspan="12">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </td>
      </>
    );
  }
  //   function xyz(data){
  //     console.log(data,"fff");
  //     // PaginatedItems({itemsPerPage:{perPage}, ProductData:{data}, search:{searchTerm}})
  //     return (
  //       <>
  //       {data != null &&
  // <PaginatedItems itemsPerPage={perPage} ProductData={data} search={searchTerm}  />
  //       }
  // </>
  //     )
  //   }
  
  return (
    <>
      {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        /> */}
      <div className="col-xl-12">
        {/*begin::Tables Widget 9*/}
        <div className="row g-5 g-xl-8">
          <div className="col-xl-3">
            {/*begin::Statistics Widget 5*/}
            <a
              className="card bg-primary hoverable card-xl-stretch mb-xl-8"
              style={{ height: "150px" }}
              id="card-open-ticket"
              onClick={(e) => {handelTicketData(null)}}
            >
              {/*begin::Body*/}
              <div className="card-body">
                {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen055.svg*/}
                <span className="svg-icon svg-icon-light svg-icon-2hx">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      opacity="0.3"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z"
                      fill="black"
                    />
                    <path
                      d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z"
                      fill="black"
                    />
                    <path
                      d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z"
                      fill="black"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
                <div className="text-white fw-bolder fs-2 mb-2 mt-5">
                  Total Tickets
                </div>
                <div
                  className="text-white fw-bolder fs-2 mb-2 mt-2"
                  id="open_tickets"
                >
                  {total_Ticket}
                </div>
              </div>
              {/*end::Body*/}
            </a>
            {/*end::Statistics Widget 5*/}
          </div>
          <div className="col-xl-3">
            {/*begin::Statistics Widget 5*/}
            <a
              className="card bg-warning hoverable card-xl-stretch mb-xl-8"
              id="card-waiting-ticket"
              style={{ height: "150px" }}
              onClick={(e) => {
                handelTicketData("1");
              }}
            >
              {/*begin::Body*/}
              <div className="card-body">
                {/*begin::Svg Icon | path: assets/media/icons/duotune/files/fil011.svg*/}
                <span className="svg-icon svg-icon-light svg-icon-2hx">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      opacity="0.3"
                      d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22Z"
                      fill="black"
                    />
                    <g clipPath="url(#clip0_787_1289)">
                      <path
                        d="M9.56133 15.7161C9.72781 15.5251 9.5922 15.227 9.33885 15.227H8.58033C8.57539 15.1519 8.57262 15.0763 8.57262 15C8.57262 13.1101 10.1101 11.5726 12 11.5726C12.7576 11.5726 13.4585 11.8198 14.0265 12.2377C14.2106 12.3731 14.4732 12.3609 14.6216 12.1872L15.1671 11.5491C15.3072 11.3852 15.2931 11.1382 15.1235 11.005C14.2353 10.3077 13.1468 9.92944 12 9.92944C10.6456 9.92944 9.37229 10.4569 8.41458 11.4146C7.4569 12.3723 6.92945 13.6456 6.92945 15C6.92945 15.0759 6.93135 15.1516 6.93465 15.2269H6.29574C6.0424 15.2269 5.90677 15.5251 6.07326 15.7161L7.51455 17.3693L7.81729 17.7166L8.90421 16.4698L9.56133 15.7161Z"
                        fill="black"
                      />
                      <path
                        d="M17.9268 14.7516L16.8518 13.5185L16.1828 12.7511L15.2276 13.8468L14.4388 14.7516C14.2723 14.9426 14.4079 15.2407 14.6612 15.2407H15.4189C15.2949 17.0187 13.809 18.4274 12.0001 18.4274C11.347 18.4274 10.736 18.2437 10.216 17.9253C10.0338 17.8138 9.79309 17.8362 9.6543 17.9985L9.10058 18.6463C8.95391 18.8179 8.97742 19.0782 9.16428 19.2048C9.99513 19.7678 10.9743 20.0706 12.0001 20.0706C13.3545 20.0706 14.6278 19.5432 15.5855 18.5855C16.4863 17.6847 17.0063 16.5047 17.0649 15.2407H17.7043C17.9577 15.2407 18.0933 14.9426 17.9268 14.7516Z"
                        fill="black"
                      />
                    </g>
                    <path
                      d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z"
                      fill="black"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
                <div className="text-white fw-bolder fs-2 mb-2 mt-5">
                  Opened Tickets
                </div>
                <div
                  className="text-white fw-bolder fs-2 mb-2 mt-2"
                  id="waiting_tickets"
                >
                  {open_Ticket}
                </div>
              </div>
              {/*end::Body*/}
            </a>
            {/*end::Statistics Widget 5*/}
          </div>
          <div className="col-xl-3">
            {/*begin::Statistics Widget 5*/}
            <a
              className="card bg-success hoverable card-xl-stretch mb-5 mb-xl-8"
              id="card-closed-ticket"
              style={{ height: "150px" }}
              onClick={(e) => {
                handelTicketData('2');
              }}
            >
              {/*begin::Body*/}
              <div className="card-body">
                {/*begin::Svg Icon | path: assets/media/icons/duotune/files/fil025.svg*/}
                <span className="svg-icon svg-icon-light svg-icon-2hx">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      opacity="0.3"
                      d="M14 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V8L14 2Z"
                      fill="black"
                    />
                    <path
                      d="M20 8L14 2V6C14 7.10457 14.8954 8 16 8H20Z"
                      fill="black"
                    />
                    <path
                      d="M10.3629 14.0084L8.92108 12.6429C8.57518 12.3153 8.03352 12.3153 7.68761 12.6429C7.31405 12.9967 7.31405 13.5915 7.68761 13.9453L10.2254 16.3488C10.6111 16.714 11.215 16.714 11.6007 16.3488L16.3124 11.8865C16.6859 11.5327 16.6859 10.9379 16.3124 10.5841C15.9665 10.2565 15.4248 10.2565 15.0789 10.5841L11.4631 14.0084C11.1546 14.3006 10.6715 14.3006 10.3629 14.0084Z"
                      fill="black"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
                <div className="text-white fw-bolder fs-2 mb-2 mt-5">
                  Answered Tickets
                </div>
                <div
                  className="text-white fw-bolder fs-2 mb-2 mt-2"
                  id="closed_tickets"
                >
                  {waiting_Ticket}
                </div>
              </div>
              {/*end::Body*/}
            </a>
       
            {/*end::Statistics Widget 5*/}
          </div>
          <div className="col-xl-3">
            {/*begin::Statistics Widget 5*/}
            <a
              className="card bg-danger hoverable card-xl-stretch mb-5 mb-xl-8"
              id="card-closed-ticket"
              style={{ height: "150px" }}
              onClick={(e) => {
                handelTicketData("4");
              }}
            >
              {/*begin::Body*/}
              <div className="card-body">
                {/*begin::Svg Icon | path: assets/media/icons/duotune/files/fil025.svg*/}
                <span className="svg-icon svg-icon-light svg-icon-2hx">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      opacity="0.3"
                      d="M14 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V8L14 2Z"
                      fill="black"
                    />
                    <path
                      d="M20 8L14 2V6C14 7.10457 14.8954 8 16 8H20Z"
                      fill="black"
                    />
                    <path
                      d="M10.3629 14.0084L8.92108 12.6429C8.57518 12.3153 8.03352 12.3153 7.68761 12.6429C7.31405 12.9967 7.31405 13.5915 7.68761 13.9453L10.2254 16.3488C10.6111 16.714 11.215 16.714 11.6007 16.3488L16.3124 11.8865C16.6859 11.5327 16.6859 10.9379 16.3124 10.5841C15.9665 10.2565 15.4248 10.2565 15.0789 10.5841L11.4631 14.0084C11.1546 14.3006 10.6715 14.3006 10.3629 14.0084Z"
                      fill="black"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
                <div className="text-white fw-bolder fs-2 mb-2 mt-5">
                  Closed Tickets
                </div>
                <div
                  className="text-white fw-bolder fs-2 mb-2 mt-2"
                  id="closed_tickets"
                >
                  {closed_Ticket}
                </div>
              </div>
              {/*end::Body*/}
            </a>
       
            {/*end::Statistics Widget 5*/}
          </div>
        
        </div>
        <div className="card card-xl-stretch mb-5 mb-xl-12">
          {/*begin::Header*/}
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold fs-3 mb-1">
                Support Tickets{" "}
                {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                <span className="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      opacity="0.3"
                      d="M8 8C8 7.4 8.4 7 9 7H16V3C16 2.4 15.6 2 15 2H3C2.4 2 2 2.4 2 3V13C2 13.6 2.4 14 3 14H5V16.1C5 16.8 5.79999 17.1 6.29999 16.6L8 14.9V8Z"
                      fill="black"
                    />
                    <path
                      d="M22 8V18C22 18.6 21.6 19 21 19H19V21.1C19 21.8 18.2 22.1 17.7 21.6L15 18.9H9C8.4 18.9 8 18.5 8 17.9V7.90002C8 7.30002 8.4 6.90002 9 6.90002H21C21.6 7.00002 22 7.4 22 8ZM19 11C19 10.4 18.6 10 18 10H12C11.4 10 11 10.4 11 11C11 11.6 11.4 12 12 12H18C18.6 12 19 11.6 19 11ZM17 15C17 14.4 16.6 14 16 14H12C11.4 14 11 14.4 11 15C11 15.6 11.4 16 12 16H16C16.6 16 17 15.6 17 15Z"
                      fill="black"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
              </span>
              {/* <span className="text-muted mt-1 fw-bold fs-7">
          Total Tickets{" "}
          <span className="badge badge-lg badge-light-primary " id="tot_ticket">
            12
          </span>
        </span> */}
            </h3>
            <div className="card-toolbar">
              <div
                className="position-relative my-1"
                style={{ marginRight: 20 }}
              >
                <input
                  type="text"
                  className="form-control form-control-sm"
                  data-kt-table-widget-4="search"
                  id="searchClient"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search"
                />
              </div>
              <div style={{ marginRight: 20 }}>
                <div className="dataTables_length">
                  <label className="w-125px">
                    <select
                      onChange={(e) => setPerPage(e.target.value)}
                      //data-control="select2"
                      className="form-select form-select-sm form-select-solid"
                      defaultValue={5}
                      id="perPage"
                    >
                      <option value={5} selected="">
                        5 Records
                      </option>
                      <option value={10}>10 Records</option>
                      <option value={25}>25 Records</option>
                      <option value={50}>50 Records</option>
                      <option value={100}>100 Records</option>
                    </select>
                  </label>
                </div>
              </div>
              {/*begin::select ticket type*/}
              {/*end::select ticket type*/}
              <a
                className="btn btn-sm btn-success btn-active-primary"
                onClick={openDrawer}
              >
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
                <span className="svg-icon svg-icon-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x="11.364"
                      y="20.364"
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(-90 11.364 20.364)"
                      fill="black"
                    />
                    <rect
                      x="4.36396"
                      y="11.364"
                      width={16}
                      height={2}
                      rx={1}
                      fill="black"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}Open New Ticket
              </a>
            </div>
          </div>
          {/*end::Header*/}
          {/*begin::Body*/}
          {/*begin::Table container*/}
          <div className="table-responsive">
            {/*begin::Table*/}
            <table
              id="ticketsTable"
              className="table table-striped table-row-gray-300 align-middle gs-0 gy-4"
            >
              {/*begin::Table head*/}
              <thead className="bg-secondary">
                <tr className="fw-bold ">
                  <th className="min-w-125px p-3 text-center">Ticket Id</th>
                  <th className="min-w-200px">Subject</th>
                  <th className="min-w-150px">Department</th>
                  <th className="min-w-150px">Status</th>
                  <th className="min-w-150px">Created at</th>
                  <th className="min-w-150px"></th>
                </tr>
              </thead>
              {/*end::Table head*/}
              {/*begin::Table body*/}
              <tbody id="ticket-list">
              {ticketData!=null?(<>
        {access==false?(<>
          <tr>
          <td colspan={12}>
         <AccessDenied />
         </td>
         </tr>
        </>):(<> 
        {Object.keys(ticketData).length!=0?(<>
        <PaginatedItems  ProductData={ticketData} search={searchTerm} itemsPerPage={perPage}/>
        </>):(<>
          <tr> 
            <td colspan="12" id="no_record" valign="top">
              <div className="d-flex flex-column flex-center"> 
                <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
              </div>
            </td>
            </tr>
          </>)}
        </>)}
        </>):(<>
          <tr>
            <td colspan={12}>
              <TableLoader />
              <TopBarHeaderLine />
            </td>
          </tr>
          
        </>)} 
                
              </tbody>
              {/*end::Table body*/}
            </table>
            {/*end::Table*/}
            <div className="text-center">
              {/* <ul class="pagination">
            <li class="page-item previous disabled"><a  class="page-link"><i class="previous"></i></a></li>
            <li class="page-item active"><a  class="page-link">1</a></li>
            <li class="page-item "><a  class="page-link">2</a></li>
            <li class="page-item "><a  class="page-link">3</a></li>
            <li class="page-item "><a  class="page-link">4</a></li>
            <li class="page-item "><a  class="page-link">5</a></li>
            <li class="page-item "><a  class="page-link">6</a></li>
            <li class="page-item next"><a   class="page-link"><i class="next"></i></a></li>
            </ul> */}
            </div>
          </div>
          {/*end::Table container*/}
          {/*begin::Body*/}
        </div>
        {/*end::Tables Widget 9*/}
      </div>

      <div
        id="openTicket-modal"
        className="bg-white drawer drawer-end"
        data-kt-drawer="true"
        data-kt-drawer-activate="true"
        data-kt-drawer-toggle="#openTicket-drawer-btn"
        data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
        style={{ maxWidth: "70%", important: true }}
      >
        <div
          className="card w-100  rounded-0 border-0"
          id="kt_drawer_chat_messenger"
        >
          {/*begin::Card header*/}
          <div
            className="card-header pe-5"
            id="kt_drawer_chat_messenger_header"
          >
            {/*begin::Title*/}
            <div className="card-title">
              {/*begin::User*/}
              <h2>Open New Ticket</h2>
              {/*end::User*/}
            </div>
            {/*end::Title*/}
            {/*begin::Card toolbar*/}
            <div className="card-toolbar">
              {/*begin::Close*/}
              <div
                onClick={openDrawer}
                className="btn btn-sm btn-icon btn-active-light-primary"
              >
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                <span className="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x={6}
                      y="17.3137"
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(-45 6 17.3137)"
                      fill="black"
                    />
                    <rect
                      x="7.41422"
                      y={6}
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(45 7.41422 6)"
                      fill="black"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
              </div>
              {/*end::Close*/}
            </div>
            {/*end::Card toolbar*/}
          </div>
          {/*end::Card header*/}
          {/*begin::Card body*/}
          <div className="p-10" id="">
            {/*begin::Form*/}
            <form id="newticket">
              {/*begin::Body*/}
              <div className="d-block">
                <div className="row">
                  <div className="mb-5 col-lg-6 col-md-6 col-xxl-6">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className=" form-label"
                    >
                      Department
                    </label>
                    <select
                      className="form-select"
                      name="department"
                      id="department"
                      tabIndex={-1}
                      aria-hidden="true"
                    >
                      <option
                        value="Cloud Support"
                        data-select2-id="select2-data-34-zobt"
                      >
                        Cloud Support
                      </option>
                      <option value="Cloud Sales">Cloud Sales</option>
                      <option value="Cloud Billing">Cloud Billing</option>
                    </select>
                  </div>
                  <div className="mb-5 col-lg-6 col-md-6 col-xxl-6">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className=" form-label"
                    >
                      Urgency
                    </label>
                    <select
                      className="form-select"
                      name="urgency"
                      id="urgency"
                      data-select2-id="select2-data-urgency"
                      tabIndex={-1}
                      aria-hidden="true"
                    >
                      <option
                        value="Medium"
                        data-select2-id="select2-data-38-zdqt"
                      >
                        Medium
                      </option>
                      <option value="Urgent">Urgent</option>
                      <option value="Low">Low</option>
                    </select>
                  </div>
                </div>
                <div className="mb-10">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className=" form-label"
                  >
                    Subject
                  </label>
                  <input
                    type="text"
                    id="subject"
                    name="subject"
                    className="form-control "
                  />
                </div>
                <div className="mb-10">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className=" form-label"
                  >
                    Message
                  </label>
                  {/*begin::Input group*/}
                  <div className="mb-10">
                    <textarea
                      className="form-control"
                      name="message"
                      rows={6}
                      defaultValue={""}
                    />
                  </div>
                  {/*end::Input group*/}
                </div>
                <div className="mb-10">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className=" form-label"
                  >
                    CC Address
                  </label>
                  <input
                    type="text"
                    id="cc"
                    name="cc"
                    defaultValue=""
                    className="form-control "
                  />
                </div>
              </div>
              {/*end::Body*/}
            </form>
            {/*end::Form*/}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary w-100"
              onClick={createTicket}
              id="createTicket"
            >
              Open New Ticket
            </button>
          </div>
          {/*end::Card body*/}
        </div>
         
      </div>
    </>
  );
}
