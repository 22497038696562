//import React, { useEffect, useState } from "react"; 
import axios from "axios"; 
import localStorage from "local-storage";
 export default async function UseDirectApi(type,url,data){ 
   // const [data,setData]=useState([]);
  //  const location = window.location.origin; 
  var baseUrl="https://api.utho.com";
  const platformName=localStorage.get("platformType");
  if(platformName){
    if(platformName=="dev"){
     baseUrl= "https://dev.api.cloudplatformapp.com";
    }
  } 
   const darr= await axios({
        method: type,
        url: baseUrl+'/v2/'+url, 
        data: data
    }).then(function(response){ 
        // //console.log(response.data);
        return response.data;
    })
    ////console.log(darr);
    return darr;
}