import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import UseApi from "../../../../../CustomHooks/useApi";
import copy from "copy-to-clipboard";
import UsePost from "../../../../../CustomHooks/usePost";
import AccessDenied from "../../../AccessDenied";
import TableLoader from "../../../Loaders/TableLoader";
import TopBarHeaderLine from "../../../../../CustomHooks/TopBarHeaderLine";
const AccessKey = (props) => {
  const [dataFetched, setDataFetched] = useState(false);
  const [access, setAccess] = useState(true);
  const [perPage, setPerPage] = useState(5);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [activePage, setActivePage] = useState(0);
  const [objectData, setObjectData] = useState(null);
  const [dummyObjectData, setDummyObjectData] = useState(null);
  const dataFetchedRef = useRef(false);
  const loaderFunc = () => {
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if (loader && no_record) {
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");

      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
  }

  const openDrawer = () => {
    var drawerElement = document.querySelector("#key-modal");
    var drawer_overlay = document.querySelector("#update_drawer_overlay");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
        drawerElement.style.width = "100%";
      } else {
        drawerElement.style.width = "40%";
      }
      drawer_overlay.classList.toggle("d-none");
    }
  };
  const getObjectStorage = async () => {
    const data = await UseApi('get', 'objectstorage/innoida/accesskeys', '');
    console.log(data);
    if (data) {
      setDataFetched(true);
    }
    if ('status' in data) {
      if (data.status == "error" && data.message == "Permission denied.") {
        setAccess(false);
        setObjectData([]);
        setDummyObjectData([])
        return;
      } else if (data.status == "error") {
        setObjectData([]);
        setDummyObjectData([])
        return;
      }
    }
    if (data.accesskeys.length >= 0) {
      setObjectData(data.accesskeys);
      setDummyObjectData(data.accesskeys)
      setPageCount(Math.ceil(data.accesskeys.length / perPage));
      toast.dismiss();
    }
  }
  var btnId = document.getElementById("accesskey-btn")
  if (btnId) {
    document.getElementById("accesskey-btn").addEventListener("click", function () {
      // Call your function here
      getObjectStorage();
    });
  }

  useEffect(() => {
    // console.log("props.pageStatus:", props.pageStatus);
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getObjectStorage();
  }, [!props.pageStatus]);

  function convertTimestamp(timestamp) {
    const date = new Date(timestamp);

    // Format the date
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
      timeZone: 'UTC'
    }).format(date);

    return formattedDate.replace('GMT', '(UTC)');
  }

  const copyToClipboard = (id) => {
    var eleText = document.querySelector("#access_copy_key_" + id);
    var ele = document.querySelector("#access_copy_btn_" + id);
    if (ele && eleText) {
      var copyText = ele.innerHTML;
      var copyEle = eleText.innerHTML;
      copy(copyEle);
      ele.innerHTML = `<svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9.89557 13.4982L7.79487 11.2651C7.26967 10.7068 6.38251 10.7068 5.85731 11.2651C5.37559 11.7772 5.37559 12.5757 5.85731 13.0878L9.74989 17.2257C10.1448 17.6455 10.8118 17.6455 11.2066 17.2257L18.1427 9.85252C18.6244 9.34044 18.6244 8.54191 18.1427 8.02984C17.6175 7.47154 16.7303 7.47154 16.2051 8.02984L11.061 13.4982C10.7451 13.834 10.2115 13.834 9.89557 13.4982Z"
        fill="white"
      />
    </svg>`;
      ele.classList.add('disabled')
      ele.style.pointerEvents = "none";
      // ele.classList.remove("badge-primary");
      // ele.classList.add("badge-success");
      setTimeout(() => {
        //   ele.classList.add("badge-primary");
        // ele.classList.remove("badge-success");
        ele.innerHTML = copyText;
        ele.classList.remove('disabled')
        ele.style.pointerEvents = "auto";
      }, 2000);
    }
  }

  const addAccesskey = async () => {
    var accesskeyid = document.querySelector("#accesskeyid")
    var submitBtn = document.querySelector("#accesskey-btn");
    if (submitBtn) {
      // setPageStatus(true)
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      const dt = toast.loading("Please wait...");
      const responseData = await UsePost('post', 'objectstorage/innoida/accesskey/create', 'accesskey-add');
      if (responseData) {
        // setPageStatus(false)
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
        if (responseData.status === 'error') {
          toast.update(dt, {
            render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        } else if (responseData.status === 'success') {
          toast.update(dt, {
            render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          // props.callBackFun()
          accesskeyid.value = ''
          var copy_keys = document.querySelector("#copy_keys");
          var accesskey_copy = document.querySelector("#accesskey_copy");
          var secretkey_copy = document.querySelector("#secretkey_copy");
          if (accesskey_copy && secretkey_copy && copy_keys) {
            accesskey_copy.value = responseData.accesskey;
            secretkey_copy.value = responseData.secretkey;
            copy_keys.classList.remove("d-none");
          }
        }
      }
    }
  }

  const updateKey = async (accesskey, name, status) => {
    var selected_key_name = document.querySelector("#selected_key_name");
    var selected_key_status = document.querySelector("#selected_key_status");
    var selected_accesskey = document.querySelector("#selected_accesskey");
    if (selected_key_name && selected_key_status && selected_accesskey) {
      selected_accesskey.value = accesskey;
      selected_key_name.value = name;
      if (status == "Active") {
        selected_key_status.value = "enable";
      } else if (status == "Suspended") {
        selected_key_status.value = "disable";
      }
    }
    openDrawer();
  }

  const updateKeyStatus = async () => {
    var submitBtn = document.querySelector("#key-btn");
    var selected_key_name = document.querySelector("#selected_key_name");
    if (submitBtn && selected_key_name) {
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      const dt = toast.loading("Please wait...");
      var accesskeyname = selected_key_name.value;
      const responseData = await UsePost('post', 'objectstorage/innoida/accesskey/' + accesskeyname + '/status', 'key_status_add');
      if (responseData) {
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
        if (responseData.status === 'error') {
          toast.update(dt, {
            render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        } else if (responseData.status === 'success') {
          toast.update(dt, {
            render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });

          getObjectStorage();
          openDrawer();
        }
      }
    }
  }

  const openDrawerCreate = () => {
    var copy_keys = document.querySelector("#copy_keys");
    if (copy_keys) {
      copy_keys.classList.add("d-none");
    }
    var drawerElement = document.querySelector("#create-keys-modal");
    var drawer_overlay = document.querySelector("#drawer_overlay");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      drawerElement.style.width = "30%";
      drawer_overlay.classList.toggle("d-none");
    }
  };

  function ProductInfo({ productdata }) {
    return (
      <>
        {productdata != null && Object.keys(productdata).length != 0 ? (productdata && productdata.map((value, index) => (
          <tr>
            <td className="text-start text-start ps-9">
              <div className="align-items-center w-100 me-2">
                <div className=" flex-stack">
                  <span className=" me-2 fs-7 fw-bold">{value.name}</span>
                </div>
              </div>
            </td>
            <td className="text-center">
              <div className=" align-items-center w-100 me-2">
                <div className=" flex-stack">
                  <a className=" me-2 fs-7 fw-bold sphide">
                    <span id={"access_copy_key_" + value.accesskey}>{value.accesskey}</span>
                    <span id={"access_copy_btn_" + value.accesskey} onClick={(e) => copyToClipboard(value.accesskey)} className="svg-icon svg-icon-primary svg-icon-2 ms-5">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          opacity="0.5"
                          d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                          fill="black"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                          fill="black"
                        />
                      </svg>
                    </span>

                  </a>
                </div>
              </div>
            </td>
            <td className="text-center">
              <div className="align-items-center">
                <div className="d-flex justify-content-start flex-column sphide">
                  {value.status == "Active" ? (<>
                    <span className="fw-bold fs-6 text-gray-700"><span className="status-circle" style={{ top: 3 }}></span>
                      {value.status}
                    </span>  </>) : (<>
                      {value.status == "Suspended" ? (<>
                        <span className="fw-bold fs-6 text-gray-700"><span className="status-circle-warning" style={{ top: 3 }}></span>
                          {value.status}
                        </span>
                      </>) : (<>
                      </>)}

                    </>)}

                </div>{" "}
              </div>
            </td>
            <td className="text-center">
              <div className="align-items-center">
                {" "}
                <div className="d-flex justify-content-start flex-column">
                  {" "}
                  <a className="text-gray-700 fw-bold text-hover-primary fs-6 sphide">
                    {(value.created_at)}
                  </a>
                </div>{" "}
              </div>
            </td>
            <td className="text-center">

              <div className="card-toolbar">

                <a
                  style={{ cursor: "pointer" }}
                  className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                  onClick={(e) => updateKey(value.accesskey, value.name, value.status)}
                >
                  <span className="svg-icon svg-icon-2">

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        opacity="0.3"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z"
                        fill="black"
                      />
                      <path
                        d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z"
                        fill="black"
                      />
                      <path
                        d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z"
                        fill="black"
                      />
                    </svg>

                  </span>
                </a>

              </div>
            </td>
          </tr>
        ))) : (<>
          <tr>
            {/* <td colspan="12" className="dataTables_empty" id="loader"> */}
            {/* <div className="loading-spinner"></div> */}
            {/* <TableLoader /> */}
            {/* </td> */}
            <td colspan="12" className="" id="no_record" valign="top">
              <div className="d-flex flex-column flex-center">
                <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
              </div>
            </td>
          </tr>
        </>)}

      </>
    )
  }

  const handleSearch = (text) => {
    const endOffset = itemOffset + perPage;
    const data = objectData.filter(row => {
      console.log(row);
      return Object.values(row).some(value => {
        if (value != null) {
          if (value.toString().toLowerCase().includes(text.toLowerCase())) {
            return value;
          }
        }
      });
    });
    setDummyObjectData(data.slice(itemOffset, endOffset));;
    setPageCount(Math.ceil(data.length / perPage));
    // }
  }

  function PaginatedItems({ itemsPerPage, ProductData }) {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(ProductData.length / itemsPerPage));
    const handlePageClick = (event) => {
      setActivePage((old) => old = event.selected);
      const newOffset = event.selected * itemsPerPage % ProductData.length;
      setItemOffset(newOffset);
    };
    return (
      <>
        <ProductInfo productdata={ProductData.slice(itemOffset, endOffset)} />
        <td colspan="12" className="ps-3">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            initialPage={activePage}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </td>
      </>
    );
  }
  return (
    <>
      <div
        className="tab-pane fade"
        id="keys-tab"
        role="tabpanel"
      >
        <div className="mb-5 mb-xl-12">
          <div className="table-responsive">
            <table
              className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
            >
              <thead className="bg-secondary">
                <tr className="fw-bold ">
                  <th className="w-100px text-start ps-9"> Name </th>
                  <th className="w-100px text-center"> Access Keys </th>
                  <th className="w-100px text-center"> Status </th>
                  <th className="w-150px text-center"> Created_at </th>
                  <th className="w-150px text-center">
                    <div
                      className="position-relative my-1"
                      style={{ marginRight: 20 }}
                    >
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        data-kt-table-widget-4="search"
                        id="searchClient"
                        onChange={e => handleSearch(e.target.value)}
                        placeholder="Search"
                      />
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {dummyObjectData != null ? (<>
                  {access == false ? (<>
                    <tr>
                      <td colspan={12}>
                        <AccessDenied />
                      </td>
                    </tr>
                  </>) : (<>
                    {dummyObjectData.length > 0 ? (<>
                      <PaginatedItems itemsPerPage={perPage} ProductData={dummyObjectData} />
                    </>) : (<>
                      <tr>
                        <td colSpan={12}>
                          <div className="card-body p-0 mt-10">
                            {/*begin::Illustration*/}
                            <div className="text-center px-4 mt-10">
                              <span className="mw-100 mh-300px">
                                <svg
                                  width="204px"
                                  height="234px"
                                  fill="#21325B"
                                  viewBox="0 0 36 36"
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  stroke="#21325B"
                                >
                                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                                  <g id="SVGRepo_iconCarrier">
                                    {" "}
                                    <title>alt-key</title>{" "}
                                    <path d="M0 10.016q0 2.016 0.8 3.872t2.144 3.2 3.168 2.112 3.904 0.8q2.048 0 4-0.832v2.848h4v1.984h4v4h4v4h5.984v-8l-9.984-8v-1.984h-2.848q0.832-1.952 0.832-4t-0.8-3.872-2.112-3.2-3.2-2.144-3.872-0.8q-2.72 0-5.024 1.344t-3.648 3.648-1.344 5.024zM4 8q0-1.632 1.184-2.816t2.816-1.184 2.816 1.184 1.184 2.816-1.184 2.848-2.816 1.152-2.816-1.152-1.184-2.848z" />{" "}
                                  </g>
                                </svg>
                              </span>

                            </div>
                            {/*end::Illustration*/}
                            {/*begin::Wrapper*/}
                            <div className="card-px text-center py-5 mb-20">
                              {/*begin::Title*/}
                              <h1 className="fs-2x fw-bolder mb-5">Access Keys</h1>
                              <h2 className="fs-1x fw-bolder mb-5 text-muted">Effortless Access with Access Keys</h2>
                              {/*end::Title*/}
                              {/*begin::Description*/}
                              <p className="text-gray-400 fs-4 fw-bold mb-10">
                                Access keys provide authentication credentials that allow you to interact with <br /> your storage objects securely and efficiently
                              </p>
                              {/*end::Description*/}
                              {/*begin::Action*/}

                              <a
                                style={{ cursor: "pointer" }}
                                onClick={openDrawerCreate}
                                className="btn btn-primary mb-10"
                              >
                                Create Keys
                              </a>

                              {/*end::Action*/}
                            </div>
                            {/*end::Wrapper*/}
                          </div>
                        </td>
                      </tr>
                    </>)}
                  </>)}
                </>) : (<>
                  <tr>
                    <td colspan={12}>
                      <TableLoader />
                      <TopBarHeaderLine />
                    </td>
                  </tr>
                </>)}
              </tbody>
            </table>
          </div>
        </div>

      </div>
      <div
        id="update_drawer_overlay"
        onClick={() => {
          openDrawer()
        }}
        className="drawer-overlay d-none"
        style={{ zIndex: 109, width: "100%" }}></div>
      <div
        id="key-modal"
        className="bg-body shadow drawer drawer-end"
        data-kt-drawer="true"
        data-kt-drawer-name="chat"
        data-kt-drawer-activate="true"
        data-kt-drawer-direction="end"
        data-kt-drawer-close="#close_ssh"
        // data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}" 
        style={{ important: true, zIndex: 110 }}

      >
        {/*begin::Messenger*/}
        <div className="card rounded-0 w-100 border-0" id="kt_drawer_chat_messenger">
          {/*begin::Card header*/}
          <div className="card-header pe-5" id="kt_drawer_chat_messenger_header">
            {/*begin::Title*/}
            <div className="card-title">
              {/*begin::User*/}
              <div className="d-flex justify-content-center flex-column me-3">
                <a

                  className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1"
                >
                  Update Key
                </a>
              </div>
              {/*end::User*/}
            </div>
            {/*end::Title*/}
            {/*begin::Card toolbar*/}
            <div className="card-toolbar">
              {/*begin::Close*/}
              <div
                onClick={openDrawer}
                className="btn btn-sm btn-icon btn-active-light-primary"
                id="close_ssh"
              >
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                <span className="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x={6}
                      y="17.3137"
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(-45 6 17.3137)"
                      fill="black"
                    />
                    <rect
                      x="7.41422"
                      y={6}
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(45 7.41422 6)"
                      fill="black"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
              </div>
              {/*end::Close*/}
            </div>
            {/*end::Card toolbar*/}
          </div>
          {/*end::Card header*/}
          {/*begin::Card body*/}
          <div className="" id="">
            {/*begin::Form*/}
            <form id="key_status_add" className="form">
              {/*begin::Modal body*/}
              <div className="modal-body ">
                {/*begin::Scroll*/}
                <div
                  className="scroll-y me-n7 pe-7"
                  id="kt_modal_create_api_key_scroll"
                  data-kt-scroll="true"
                  data-kt-scroll-activate="{default: false, lg: true}"
                  data-kt-scroll-max-height="auto"
                  data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                  data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                  data-kt-scroll-offset="300px"
                >
                  {/*begin::Input group*/}
                  <div className="mb-5 fv-row">
                    {/*begin::Input*/}

                    <div className="mb-10">
                      <label className="form-label">Select access key</label>
                      <input type="text" className="form-control" readOnly defaultValue="" id="selected_key_name" />
                      <input type="hidden" className="form-control" readOnly defaultValue="" name="accesskey" id="selected_accesskey" />
                    </div>
                    <div className="mb-10">
                      <label className="form-label">Select Status</label>
                      <select
                        className="form-select"
                        name="status"
                        id="selected_key_status" >
                        <option value="enable">Active</option>
                        <option value="disable">Suspend</option>
                        <option value="remove">Delete</option>
                      </select>
                    </div>

                    {/*end::Input*/}
                  </div>
                  {/*end::Input group*/}
                </div>
                {/*end::Scroll*/}
                {/*end::Modal body*/}
                {/*begin::Modal footer*/}
                {/*begin::Button*/}
                <a
                  className="btn btn-primary w-100"
                  id="key-btn"
                  onClick={updateKeyStatus}
                >
                  <span className="indicator-label">Update Access key</span>
                </a>

                {/*end::Button*/}
              </div>
              {/*end::Modal footer*/}
            </form>
            {/*end::Form*/}
          </div>
          {/*end::Card body*/}
        </div>
        {/*end::Messenger*/}
      </div>
      <div id="drawer_overlay" onClick={openDrawerCreate} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>
      <div
        id="create-keys-modal"
        className="bg-body shadow drawer drawer-end"
        data-kt-drawer="true"
        data-kt-drawer-name="chat"
        data-kt-drawer-activate="true"
        data-kt-drawer-direction="end"
        data-kt-drawer-close="#close_ssh"
        data-kt-drawer-width="{default:'650px', 'md': '650px', 'sm': '300px'}"
        style={{ maxWidth: "70%", important: true }}
      >
        <div className="card w-100 rounded-0 border-0" id="kt_drawer_chat_messenger">
          <div className="card-header pe-5 ps-7" id="kt_drawer_chat_messenger_header">
            <div className="card-title">
              <div className="d-flex justify-content-center flex-column me-3">
                <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                  Create Access Key
                </a>
              </div>
            </div>
            <div className="card-toolbar">
              <div className="btn btn-sm btn-icon btn-active-light-primary" onClick={openDrawerCreate}>
                <span className="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x={6}
                      y="17.3137"
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(-45 6 17.3137)"
                      fill="black"
                    />
                    <rect
                      x="7.41422"
                      y={6}
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(45 7.41422 6)"
                      fill="black"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <div className="" id="">
            <form id="accesskey-add">
              <div className="modal-body ">
                <div className="scroll-y me-n7 pe-7">
                  <div className="mb-5 fv-row">
                    <label htmlFor="type" className="required fs-5 fw-bold mb-2">
                      {" "}
                      Key Name
                    </label>
                    <input
                      type="text"
                      id="accesskeyid"
                      name="accesskey"
                      className="form-control"
                    />
                  </div>

                </div>

                <div className="" style={{paddingRight:'0.823rem'}}>
                  <a style={{ cursor: "pointer" }} id="accesskey-btn" onClick={addAccesskey} className="btn btn-primary w-100">
                    <span className="indicator-label">+ Create Key</span>
                  </a>
                </div>
                <div className="mt-10 d-none" id="copy_keys">
                  {/*begin::Input group*/}
                  <div>
                    <label className="form-label mb-5">Access key</label>
                    <div className="d-flex">
                      <input
                        id="accesskey_copy"
                        type="text"
                        className="form-control form-control-solid me-3 flex-grow-1"
                        defaultValue=""
                      />
                      <a style={{ cursor: "pointer" }}
                        id="accesskey_copy_btn"
                        onClick={(e) => copyToClipboard('accesskey')}
                        className="btn btn-light fw-bolder flex-shrink-0"
                      >
                        Copy key
                      </a>
                    </div>
                  </div>
                  {/*end::Input group*/}
                  {/*begin::Input group*/}
                  <div>
                    <label className="form-label mb-5">Secret key</label>
                    <div className="d-flex">
                      <input
                        id="secretkey_copy"
                        type="text"
                        className="form-control form-control-solid me-3 flex-grow-1"
                        defaultValue=""
                      />
                      <a style={{ cursor: "pointer" }}
                        id="secretkey_copy_btn"
                        onClick={(e) => copyToClipboard('secretkey')}
                        className="btn btn-light fw-bolder flex-shrink-0"
                      >
                        Copy Link
                      </a>
                    </div>
                    <div className="text-danger fs-7 mb-7 mt-5">
                      Please collect the credentials, these credentials shows only once!
                    </div>
                  </div>
                  {/*end::Input group*/}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default AccessKey;
