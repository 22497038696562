import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./custom.css";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UsePost from "../../../../CustomHooks/usePost";
import swal from "sweetalert";
import TableLoader from "../../Loaders/TableLoader";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import UsePostData from "../../../../CustomHooks/usePostData";
import Ssl from "./tabs/Ssl";
import AdvanceRouting from "./tabs/AdvanceRouting";
import copy from "copy-to-clipboard";
import Rules from "./tabs/Rules";
import Backends from "./tabs/Backends";
import Setting from "./tabs/Setting";
import CreateLoadbalancerTargetGroup from "./Header/CreateLoadbalancerTargetGroup";
import { capitalizeFirstLetter } from "../../utils/utils";

export default function ManageLoadBalancer() {
  var query = window.location.href;
  var vars = query.split("/");
  var id = vars[4];

  const navigate = useNavigate();
  const [lbData, setLbData] = useState(null);
  const [pageStatus, setPageStatus] = useState(false);
  const dataFetchedRef = useRef(false);
  const [isDestroy, setIsDestroy] = useState(false);
  const [processCount, setProcessCount] = useState(0);
  const [isComplete, setIsComplete] = useState(false)
  const [isAddingFrontend, setIsAddingFrontend] = useState(false);
  const processBarRef = useRef(null);
  const process_bar_parentRef = useRef(null);
  const serverAlertRef = useRef(null);
  const intervalRef = useRef(null);

  const navRedirect = () => {
    setTimeout(() => {
      navigate("/loadbalancer/" + id);
    }, 3000);
  };
  const getLoadbalancer = async () => {
    const data = await UseApi("get", "loadbalancer/" + id, "");
    if (data.loadbalancers.length <= 0) {
      swal({
        title: "Loadbalancer",
        text: "No Loadbalancer record found with id: " + id + "!",
        icon: "warning",
        button: "OK",
      }).then(() => {
        navigate("/loadbalancer");
      });
      return;
    } else {
      setLbData(data.loadbalancers[0]);
      if (data?.loadbalancers[0] != null && data?.loadbalancers[0]?.frontends && data?.loadbalancers[0]?.frontends.length > 0) {
        setIsAddingFrontend(false); // Reset once frontends are available
      }
      getActions()
      // if (data.loadbalancers[0]) {
      //   if (data.loadbalancers[0].backends) {
      //     setLbBackendData(data.loadbalancers[0].backends);
      //   }
      // }
    }
  };
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getLoadbalancer();
  }, []);

  const destroyLb = async () => {
    toast.dismiss();
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Load Balancer!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        setPageStatus(true);
        const dt = toast.loading("Loadbalancer deleting...");
        const dnsp = await UseApi("DELETE", "loadbalancer/" + id, "");
        // //console.log(dnsp);
        if (dnsp.status == "error") {
          setPageStatus(false);
          toast.update(dt, {
            render: dnsp.message,
            type: "warning",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          setPageStatus(false);
          toast.update(dt, {
            render: dnsp.message,
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          navigate("/loadbalancer");
        }
      } else {
        setPageStatus(false);
        swal({
          title: "Load Balancer",
          text: "Your LoadBalancer is safe!",
          icon: "success",
          button: "OK",
        });
      }
    });
  };

  const toggleFront = (fid) => {
    var front_sec = document.querySelector("#front-sec-" + fid);
    var front_close_sec = document.querySelector("#front-close-sec-" + fid);
    var front_open_sec = document.querySelector("#front-open-sec-" + fid);
    if (front_sec && front_close_sec && front_open_sec) {
      front_sec.classList.toggle("fade-out-front");
      front_open_sec.classList.toggle("d-none");
      front_close_sec.classList.toggle("d-none");
    }
  };

  const handleAddSrcProto = () => {
    var src_proto = document.querySelector("#add_src_proto");
    var ssl_certificate_sec = document.querySelector(
      "#add_ssl_certificate_sec"
    );
    if (src_proto && ssl_certificate_sec) {
      if (src_proto.value == "https") {
        ssl_certificate_sec.classList.remove("d-none");
      } else {
        ssl_certificate_sec.classList.add("d-none");
      }
    }
  };
  useEffect(() => {
    handleAddSrcProto();
  }, []);

  const [sslData, setSslData] = useState(null);

  const getSsl = async () => {
    const datas = await UseApi("get", "certificates", "");
    if (datas) {
      if (datas.rcode == "error") {
        const dt = toast.loading("SSL loading...");
        toast.update(dt, {
          render: datas.message,
          type: "warning",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setSslData([]);
        return;
      } else {
        setSslData(datas.certificates);
      }
    }
  };
  useEffect(() => {
    toast.dismiss();
    getSsl();
  }, []);

  const toggleFrontend = () => {
    var add_frontend_sec_btn = document.querySelector("#add_frontend_sec_btn");
    var add_frontend_sec = document.querySelector("#add_frontend_sec");
    if (add_frontend_sec_btn && add_frontend_sec) {
      add_frontend_sec_btn.classList.toggle("d-none");
      add_frontend_sec.classList.toggle("d-none");
    }
  };

  const closeFrontend = () => {
    setIsDestroy((old) => (old = true));
    var add_frontend_sec_btn = document.querySelector("#add_frontend_sec_btn");
    var add_frontend_sec = document.querySelector("#add_frontend_sec");
    if (add_frontend_sec_btn && add_frontend_sec) {
      add_frontend_sec_btn.classList.add("d-none");
      add_frontend_sec.classList.add("d-none");
    }
  };
  console.log(isDestroy);


  const OpenFrontend = () => {
    setIsDestroy(false);
    var add_frontend_sec_btn = document.querySelector("#add_frontend_sec_btn");
    var add_frontend_sec = document.querySelector("#add_frontend_sec");
    if (add_frontend_sec_btn && add_frontend_sec) {
      add_frontend_sec_btn.classList.remove("d-none");
      add_frontend_sec.classList.add("d-none");
    }
  };

  const addFrontend = async () => {
    setIsAddingFrontend(true)
    var submitBtn = document.querySelector("#add-frontend-btn");
    if (submitBtn) {
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML =
        '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      toast.dismiss();
      setPageStatus(true);
      const dt = toast.loading("Adding frontend, Please wait...");
      const responseData = await UsePost(
        "post",
        "loadbalancer/" + id + "/frontend",
        "add_frontend_form"
      );
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML = preContent;
      if (responseData.status === "error") {
        setPageStatus(false);
        toast.update(dt, {
          render: responseData.message,
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setPageStatus(false);
        toast.update(dt, {
          render: responseData.message,
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        toggleFrontend();
        getLoadbalancer();
        setTimeout(() => {
          // Assuming the lbData.frontends is updated elsewhere after adding
          setIsAddingFrontend(false);
        }, 2000);
      }
    }
  };

  const deleteFrontend = async (fid) => {
    toast.dismiss();
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this loadbalancer's frontend!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const dt = toast.loading("Frontend deleting...");
        const dnsp = await UseApi(
          "DELETE",
          "loadbalancer/" + id + "/frontend/" + fid,
          ""
        );
        //console.log(dnsp);
        if (dnsp.status == "error") {
          toast.update(dt, {
            render: dnsp.message,
            type: "warning",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (dnsp.status == "success") {
          toast.update(dt, {
            render: dnsp.message,
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          getLoadbalancer();
        }
      }
    });
  };
  const [actionInfo, setActionInfo] = useState(null);
  const getActions = async () => {
    const actionData = await UseApi("get", "actions", "action-form");
    console.log(actionData);

    if (actionData && actionData.actions) {
      setActionInfo(actionData.actions);
    }
  };
  useEffect(() => {
    getActions();
  }, []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (actionInfo != null) {
        var ispresent = false;
        var dataInfo = null;
        var barStatus = 'false';
        console.log(lbData?.cloudid);

        if (lbData?.cloudid) {
          if (lbData?.cloudid > 0) {
            console.log("fsadsdsed");

            actionInfo.map((value, index) => {
              console.log(value);

              if (value.resource_id == lbData.cloudid) {
                ispresent = true;
                dataInfo = value;
                barStatus = 'true';
              }
              if (barStatus == 'false') {
                if (processBarRef.current && process_bar_parentRef.current && serverAlertRef.current) {
                  process_bar_parentRef.current.classList.add("d-none");
                  processBarRef.current.classList.add("d-none");
                  serverAlertRef.current.classList.add("d-none");
                  setIsComplete(true)
                  // if (props.cloudInfo.stack) {
                  //   // console.log("bar status false");
                  //   var installing_stack = document.querySelector("#installing_stack");
                  //   if (installing_stack) {
                  //     if (props.cloudInfo.stack.status != "Pending") {
                  //       installing_stack.classList.add("d-none");
                  //     } else {
                  //       installing_stack.classList.remove("d-none");
                  //     }
                  //   }
                  // }

                }
                clearInterval(intervalRef.current);
                return;
              }
              if (barStatus == 'false' && dataInfo != null) {
                if (processBarRef.current && process_bar_parentRef.current && serverAlertRef.current) {
                  setProcessCount(100);
                  process_bar_parentRef.current.classList.add("d-none");
                  processBarRef.current.style.width = dataInfo.process + "%";
                  processBarRef.current.classList.add("d-none");
                  serverAlertRef.current.classList.add("d-none");
                  setIsComplete(true)
                  // if (props.cloudInfo.stack) {
                  //   // console.log("bar status");
                  //   var installing_stack = document.querySelector("#installing_stack");
                  //   if (installing_stack) {
                  //     if (props.cloudInfo.stack.status != "Pending") {
                  //       installing_stack.classList.add("d-none");
                  //     } else {
                  //       installing_stack.classList.remove("d-none");
                  //     }
                  //   }
                  // }
                }
                clearInterval(intervalRef.current);
                return;
              }
              if (dataInfo != null) {
                if (dataInfo.id != null) {

                  if (dataInfo.status == "Pending") {
                    // toast.dismiss();
                    if (processBarRef.current && process_bar_parentRef.current) {
                      setProcessCount(dataInfo.process);
                      setIsComplete(false)
                      process_bar_parentRef.current.classList.remove("d-none");
                      processBarRef.current.style.width = dataInfo.process + "%";
                      processBarRef.current.classList.remove("d-none");
                    }
                    intervalRef.current = setTimeout(getActions, 5000);
                  } else if (dataInfo.status == "Support") {
                    // const processBar=document.querySelector("#process_bar");
                    // const process_bar_parent=document.querySelector("#process_bar_parent");
                    // const server_status=document.querySelector("#server-status");
                    //console.log("outer");
                    if (processBarRef.current && process_bar_parentRef.current && serverAlertRef.current) {
                      //console.log("inner");
                      setIsComplete(false)
                      setProcessCount(dataInfo.process);
                      process_bar_parentRef.current.classList.remove("d-none");
                      processBarRef.current.style.width = dataInfo.process + "%";
                      processBarRef.current.classList.remove("d-none");
                      serverAlertRef.current.classList.remove("d-none");
                    }
                    intervalRef.current = setTimeout(getActions, 5000);
                  } else if (dataInfo.status == "Complete") {
                    if (processBarRef.current && process_bar_parentRef.current && serverAlertRef.current) {
                      setProcessCount(dataInfo.process);
                      setIsComplete(true)
                      process_bar_parentRef.current.classList.add("d-none");
                      processBarRef.current.style.width = dataInfo.process + "%";
                      processBarRef.current.classList.add("d-none");
                      serverAlertRef.current.classList.add("d-none");
                      // if (props.cloudInfo.stack) {
                      //   // console.log("complete");
                      //   var installing_stack = document.querySelector("#installing_stack");
                      //   if (installing_stack) {
                      //     if (props.cloudInfo.stack.status != "Pending") {
                      //       installing_stack.classList.add("d-none");
                      //     } else {
                      //       installing_stack.classList.remove("d-none");
                      //     }
                      //   }
                      // }
                    }
                    clearInterval(intervalRef.current);
                  }

                } else {
                  setIsComplete(true)
                  clearInterval(intervalRef.current);
                }
                return;
              }
            })
          } else {
            setIsComplete(true)
          }
        } else {
          getActions()
        }
      }
    }, 2000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [actionInfo]);

  const copyToClipboard = (target, targetBy) => {
    var ip_address = document.querySelector("#load-ip");
    var btn_id = document.querySelector("#dbname_btn");
    var target_id = document.querySelector("#load-ip");
    if (btn_id && !ip_address.classList.contains("disabled")) {
      var copyText = btn_id.innerHTML;
      var copyEle = target_id.innerHTML;
      copy(copyEle);
      ip_address.classList.add("disabled");
      // svgIconElement.classList.add('d-none')
      btn_id.innerHTML = `<svg
      xmlns="http://www.w3.org/2000/svg"
      width=24px
      height=24px
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9.89557 13.4982L7.79487 11.2651C7.26967 10.7068 6.38251 10.7068 5.85731 11.2651C5.37559 11.7772 5.37559 12.5757 5.85731 13.0878L9.74989 17.2257C10.1448 17.6455 10.8118 17.6455 11.2066 17.2257L18.1427 9.85252C18.6244 9.34044 18.6244 8.54191 18.1427 8.02984C17.6175 7.47154 16.7303 7.47154 16.2051 8.02984L11.061 13.4982C10.7451 13.834 10.2115 13.834 9.89557 13.4982Z"
        fill="black"
      />
    </svg>`;
      setTimeout(() => {
        btn_id.innerHTML = copyText;
        ip_address.classList.remove("disabled");
        // svgIconElement.classList.remove('d-none')
      }, 2000);
    }
  };

  console.log(lbData, isComplete)

  return (
    <>
      {lbData != null ? (
        <>
          {/* <CreateLoadbalancerTargetGroup /> */}
          <div className="card mb-6 mb-xl-9">
            <div className="card-body pt-9 pb-0">
              {/*begin::Details*/}
              <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                {/*begin: Pic*/}
                <div className="me-7 mb-4">
                  <div
                    id="flag-icon"
                    className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative"
                  >
                    {/*begin::Image input*/}
                    <div
                      className="image-input image-input-empty"
                      data-kt-image-input="true"
                    >
                      <span className="svg-icon svg-icon-muted svg-icon-4tx">
                        <svg
                          fill="#a1a5b7"
                          viewBox="0 0 32 32"
                          id="icon"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                          <g
                            id="SVGRepo_tracerCarrier"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <defs>
                              {" "}
                              <style
                                dangerouslySetInnerHTML={{
                                  __html: " .cls-1 { fill: none; } ",
                                }}
                              />{" "}
                            </defs>{" "}
                            <path
                              d="M8,30H2V24H8ZM4,28H6V26H4Z"
                              transform="translate(0 0)"
                            />{" "}
                            <path
                              d="M19,30H13V24h6Zm-4-2h2V26H15Z"
                              transform="translate(0 0)"
                            />{" "}
                            <path
                              d="M30,30H24V24h6Zm-4-2h2V26H26Z"
                              transform="translate(0 0)"
                            />{" "}
                            <path
                              d="M16,10a4,4,0,1,1,4-4A4.0045,4.0045,0,0,1,16,10Zm0-6a2,2,0,1,0,2,2A2.002,2.002,0,0,0,16,4Z"
                              transform="translate(0 0)"
                            />{" "}
                            <path
                              d="M26,16H21a4.0045,4.0045,0,0,1-4-4H15a4.0045,4.0045,0,0,1-4,4H6a2.0023,2.0023,0,0,0-2,2v4H6V18h5A5.9694,5.9694,0,0,0,15,16.46V22h2V16.46A5.9694,5.9694,0,0,0,21,18h5v4h2V18A2.0023,2.0023,0,0,0,26,16Z"
                              transform="translate(0 0)"
                            />{" "}
                            <rect
                              id="_Transparent_Rectangle_"
                              data-name="<Transparent Rectangle>"
                              className="cls-1"
                              width={32}
                              height={32}
                            />{" "}
                          </g>
                        </svg>
                      </span>
                    </div>
                    {/*end::Image input*/}
                  </div>
                </div>
                {/*end::Pic*/}
                {/*begin::Info*/}
                <div className="flex-grow-1">
                  {/*begin::Title*/}
                  <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                    {/*begin::User*/}
                    <div className="d-flex flex-column">
                      {/*begin::Name*/}
                      <div className="d-flex align-items-center mb-0">
                        <a
                          className="text-gray-900 text-hover-primary fs-2 fw-bolder me-1"
                          id="head-name"
                        >
                          {lbData != null ? (
                            <> {capitalizeFirstLetter(lbData.name)} </>
                          ) : (
                            ""
                          )}
                        </a>
                      </div>
                      {/*end::Name*/}
                      {/*begin::Info*/}

                      <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                        <a className="d-flex align-items-center text-gray-400 me-3 mb-2">
                          {/*begin::Svg Icon | path: icons/duotune/communication/com006.svg*/}
                          <span className="svg-icon svg-icon-4 me-2 pb-1">
                            {lbData != null ? (
                              <>
                                <img
                                  src={"/img/flag_" + lbData.cc + ".png"}
                                  style={{ width: "16px" }}
                                />
                              </>
                            ) : (
                              ""
                            )}
                          </span>
                          {/*end::Svg Icon*/}
                          {lbData != null ? <> {lbData.city} </> : ""}
                        </a>
                        <a className="d-flex align-items-center text-gray-400 me-5 mb-2">
                          {/*begin::Svg Icon | path: icons/duotune/general/gen018.svg*/}
                          <span className="svg-icon svg-icon-4 me-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                opacity="0.3"
                                d="M22.0318 8.59998C22.0318 10.4 21.4318 12.2 20.0318 13.5C18.4318 15.1 16.3318 15.7 14.2318 15.4C13.3318 15.3 12.3318 15.6 11.7318 16.3L6.93177 21.1C5.73177 22.3 3.83179 22.2 2.73179 21C1.63179 19.8 1.83177 18 2.93177 16.9L7.53178 12.3C8.23178 11.6 8.53177 10.7 8.43177 9.80005C8.13177 7.80005 8.73176 5.6 10.3318 4C11.7318 2.6 13.5318 2 15.2318 2C16.1318 2 16.6318 3.20005 15.9318 3.80005L13.0318 6.70007C12.5318 7.20007 12.4318 7.9 12.7318 8.5C13.3318 9.7 14.2318 10.6001 15.4318 11.2001C16.0318 11.5001 16.7318 11.3 17.2318 10.9L20.1318 8C20.8318 7.2 22.0318 7.59998 22.0318 8.59998Z"
                                fill="black"
                              />
                              <path
                                d="M4.23179 19.7C3.83179 19.3 3.83179 18.7 4.23179 18.3L9.73179 12.8C10.1318 12.4 10.7318 12.4 11.1318 12.8C11.5318 13.2 11.5318 13.8 11.1318 14.2L5.63179 19.7C5.23179 20.1 4.53179 20.1 4.23179 19.7Z"
                                fill="black"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                          {lbData != null ? (
                            <> {capitalizeFirstLetter(lbData.type)} </>
                          ) : (
                            ""
                          )}
                        </a>
                      </div>
                      {/*end::Info*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::Stats*/}
                    <div className="d-flex flex-wrap flex-stack">
                      {/*begin::Actions*/}
                      <div className="d-flex mb-4 ">
                        {/*begin::Input*/}
                        <div
                          className="zoom me-5 btn btn-sm btn-light-primary sphide"
                          style={{ marginRight: 50 }}
                          onClick={() => copyToClipboard()}
                        >
                          {lbData.dns ? (
                            <>DNS Address &nbsp;</>
                          ) : (
                            <>IP Address &nbsp;</>
                          )}
                          <span className="svg-icon svg-icon-muted svg-icon-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z"
                                fill="black"
                              />
                              <path
                                opacity="0.3"
                                d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z"
                                fill="black"
                              />
                            </svg>
                          </span>

                          <span id="load-ip" className="fw-bold">
                            {lbData != null ? (
                              <>
                                {lbData.dns ? (
                                  <>{lbData.dns}</>
                                ) : (
                                  <>{lbData.ip.trim()}</>
                                )}
                              </>
                            ) : (
                              ""
                            )}
                          </span>
                          <span
                            className="svg-icon svg-icon-primary svg-icon-2 ms-2"
                            id="dbname_btn"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                opacity="0.5"
                                d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                                fill="black"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                                fill="black"
                              />
                            </svg>
                          </span>
                        </div>
                        {/*end::Input*/}
                        {lbData != null ? (
                          <>
                            {lbData.status == "Active" ? (
                              <>
                                <a
                                  className="btn btn-sm btn-success"
                                  id="status-type"
                                >
                                  {lbData.status}
                                </a>
                              </>
                            ) : (
                              <>
                                <a
                                  className="btn btn-sm btn-warning"
                                  id="status-type"
                                >
                                  {lbData.status}
                                </a>
                              </>
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {/*end::Actions*/}
                      {/*begin::Progress*/}
                      {/*end::Progress*/}
                    </div>
                    {/*end::Stats*/}
                  </div>
                  {/*end::Title*/}
                </div>
                {/*end::Info*/}
              </div>
              {/*end::Details*/}
              <div className="separator" />
              <div className="row">
                {/*begin::Nav*/}
                <div className="col-lg-10 col-md-10 col-xl-10">
                  <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder">
                    {/*begin::Nav item*/}
                    <li className="nav-item">
                      <a
                        onClick={OpenFrontend}
                        className="nav-link text-active-primary py-5 me-6 active"
                        data-bs-toggle="tab"
                        href="#configurations"
                      >
                        Configuration
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        onClick={closeFrontend}
                        className="nav-link text-active-primary py-5 me-6"
                        data-bs-toggle="tab"
                        href="#destroy-load"
                      >
                        Destroy
                      </a>
                    </li>
                    {/*end::Nav item*/}
                  </ul>
                  {/*end::Nav*/}
                </div>
                <div className="col-lg-2 col-md-2 col-xl-2">
                  {lbData != null && lbData.frontends ? (
                    <>
                      {lbData.frontends.length > 0 ? (
                        <>
                          <a
                            id="add_frontend_sec_btn"
                            onClick={toggleFrontend}
                            className="btn btn-sm btn-primary ms-12 mt-3"
                          >
                            <span className="svg-icon svg-icon-3">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <rect
                                  opacity="0.5"
                                  x="11.364"
                                  y="20.364"
                                  width={16}
                                  height={2}
                                  rx={1}
                                  transform="rotate(-90 11.364 20.364)"
                                  fill="black"
                                />
                                <rect
                                  x="4.36396"
                                  y="11.364"
                                  width={16}
                                  height={2}
                                  rx={1}
                                  fill="black"
                                />
                              </svg>
                            </span>
                            Add Frontend
                          </a>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          {!isDestroy && <>
            <div className="text-center" id="installing_loadbalancer_loader">
              {/* <TableLoader /> */}
            </div>
            <div className="text-center d-none" id="installing_loadbalancer">
              <span className="fw-bold text-center text-gray-600">
                Installing Loadbalancer, Please Wait...
              </span>
              <div className="loadingStack"></div>
            </div>
            <div className="progress d-none mb-5" id="process_bar_parent" ref={process_bar_parentRef}>
              <div
                className="progress-bar progress-bar-striped progress-bar-animated bg-primary d-none"
                ref={processBarRef}
                role="progressbar"
                id="process_bar"
                style={{ width: "0%" }}
                aria-valuenow={0}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                {processCount}%
              </div>
            </div>
            <div className="alert alert-danger d-none" id="server_alert" ref={serverAlertRef}>
              <div className="d-flex flex-column">
                <span>Uh Oh! That was unexpected! An error has occured and we're working to fix the problem!</span>
              </div>
            </div>
          </>}
          <div className="tab-content" id="myTabContent">
            {/* {!isDestroy ? */}
            <div
              className="tab-pane fade show active"
              id="configurations"
              role="tabpanel"
            >
              {isComplete &&
                <>
                  {/*begin:: Add Frontend*/}

                  {/*end::Add Frontend*/}
                  <div
                    className="card mb-5 mb-xl-10 d-none"
                    id="add_frontend_sec"
                  >
                    {/*begin::Card header*/}
                    <div
                      className="card-header border-2 cursor-pointer"
                      role="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#kt_account_deactivate"
                      aria-expanded="true"
                      aria-controls="kt_account_deactivate"
                    >
                      <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">Add New Frontend</h3>
                      </div>
                      {/*begin::Toolbar*/}
                      <div className="card-toolbar">
                        <span
                          onClick={toggleFrontend}
                          className="svg-icon svg-icon-danger svg-icon-2hx me-2"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              opacity="0.3"
                              d="M6 19.7C5.7 19.7 5.5 19.6 5.3 19.4C4.9 19 4.9 18.4 5.3 18L18 5.3C18.4 4.9 19 4.9 19.4 5.3C19.8 5.7 19.8 6.29999 19.4 6.69999L6.7 19.4C6.5 19.6 6.3 19.7 6 19.7Z"
                              fill="black"
                            />
                            <path
                              d="M18.8 19.7C18.5 19.7 18.3 19.6 18.1 19.4L5.40001 6.69999C5.00001 6.29999 5.00001 5.7 5.40001 5.3C5.80001 4.9 6.40001 4.9 6.80001 5.3L19.5 18C19.9 18.4 19.9 19 19.5 19.4C19.3 19.6 19 19.7 18.8 19.7Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                      </div>
                      {/*end::Toolbar*/}
                    </div>
                    {/*end::Card header*/}
                    {/*begin::Content*/}
                    <div
                      id="kt_account_settings_deactivate"
                      className="collapse show"
                    >
                      <form id="add_frontend_form">
                        <div className="container">
                          {/*begin::Row*/}
                          <div
                            className="row g-9 mt-1"
                            data-kt-buttons="true"
                            data-kt-buttons-target="[data-kt-button]"
                            id="load_bal"
                          >
                            <div className="col-md-2 col-lg-2 col-xxl-2">
                              <div className="mb-5 fv-row">
                                <label className="required fs-5 fw-bold mb-2">
                                  Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control form-sm"
                                  name="name"
                                  defaultValue=""
                                />
                              </div>
                            </div>
                            <div className="col-md-2 col-lg-2 col-xxl-2">
                              <div className="mb-5 fv-row">
                                <label className="required fs-5 fw-bold mb-2">
                                  Protocol
                                </label>
                                <select
                                  className="form-select"
                                  id="add_src_proto"
                                  name="proto"
                                  onChange={handleAddSrcProto}
                                  defaultValue="http"
                                >
                                  <option value="http">HTTP</option>
                                  {lbData.type == "network" ? (
                                    <>
                                      <option value="tcp">TCP</option>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  <option value="https">HTTPS</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-2 col-lg-2 col-xxl-2">
                              <div className="mb-5 fv-row">
                                <label className="required fs-5 fw-bold mb-2">
                                  Port
                                </label>
                                <input
                                  type="number"
                                  className="form-control form-sm"
                                  name="port"
                                  defaultValue="80"
                                />
                              </div>
                            </div>
                            <div
                              id="add_ssl_certificate_sec"
                              className="col-md-3 col-lg-12 col-xxl-3 d-none"
                            >
                              <div className="mb-5 fv-row">
                                <label className="required fs-5 fw-bold mb-2">
                                  SSL Certificate &nbsp; (
                                  <Link to="/ssl?action=deploy">
                                    <span className="text-primary"> Add new</span>
                                  </Link>
                                  )
                                </label>
                                <select
                                  className="form-select"
                                  name="certificate_id"
                                  defaultValue={lbData.certificate_id}
                                >
                                  <option value="0">Select SSL</option>
                                  {sslData != null ? (
                                    <>
                                      {sslData.map((value, index) => (
                                        <>
                                          <option value={value.id}>
                                            {value.name}
                                          </option>
                                        </>
                                      ))}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </select>
                              </div>
                            </div>
                            <div className="col-md-3 col-lg-3 col-xxl-3">
                              <div className="mb-5 fv-row">
                                <label className="required fs-5 fw-bold mb-2">
                                  Algorithm
                                </label>
                                <select
                                  className="form-select"
                                  name="algorithm"
                                  defaultValue="roundrobin"
                                >
                                  <option value="roundrobin">Round Robin</option>
                                  <option value="leastconn">
                                    Least Connections
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          {/*end::Row*/}
                          <div className="col-md-6 col-lg-12 col-xxl-6">
                            {/*begin::Input group*/}
                            <div className=" mt-10 d-flex flex-stack">
                              {/*begin::Label*/}
                              <div className="me-5">
                                <label className="fs-5 form-label">
                                  Redirect to HTTPS
                                </label>
                                <div className="fs-6 fw-bold text-muted">
                                  If you need to redirect all traffic to https,
                                  please check redirect to HTTPS
                                </div>
                              </div>
                              {/*end::Label*/}
                              {/*begin::Switch*/}
                              <label className="form-check form-switch form-check-custom form-check">
                                <input
                                  type="hidden"
                                  name="redirecthttps"
                                  id="redirecthttps-add"
                                  defaultValue="0"
                                />
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue={1}
                                  onChange={(e) => {
                                    document.getElementById(
                                      "redirecthttps-add"
                                    ).value = e.target.checked ? "1" : "0";
                                  }}
                                />
                              </label>
                              {/*end::Switch*/}
                            </div>
                            {/*end::Input group*/}
                          </div>
                          <div className="col-md-6 col-lg-12 col-xxl-6">
                            {/*begin::Input group*/}
                            <div className="mt-10 d-flex flex-stack">
                              {/*begin::Label*/}
                              <div className="me-5">
                                <label className="fs-5 form-label">
                                  Sticky Sessions
                                </label>
                                <div className="fs-6 fw-bold text-muted">
                                  If you need cookie session for your algorithm,
                                  please check sticky sessions
                                </div>
                              </div>
                              {/*end::Label*/}
                              {/*begin::Switch*/}
                              <label className="form-check form-switch form-check-custom form-check">
                                <input
                                  type="hidden"
                                  name="cookie"
                                  id="cookie-add"
                                  defaultValue="0"
                                />
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue={1}
                                  onChange={(e) => {
                                    document.getElementById("cookie-add").value = e
                                      .target.checked
                                      ? "1"
                                      : "0";
                                  }}
                                />
                              </label>
                              {/*end::Switch*/}
                            </div>
                            {/*end::Input group*/}
                          </div>
                          {/*begin::Card footer*/}
                          <div className="card-footer border-0 d-flex justify-content-end py-6 px-2">
                            <a
                              onClick={addFrontend}
                              id="add-frontend-btn"
                              className="btn btn-sm btn-primary fw-bold"
                            >
                              Add Frontend
                            </a>
                          </div>
                          {/*end::Card footer*/}
                        </div>
                      </form>
                    </div>
                    {/*end::Content*/}
                  </div>
                  {lbData != null && lbData.frontends ? (
                    <>
                      {lbData.frontends.length > 0 ? (
                        <>
                          {/* {console.log(lbData.frontends)} */}
                          {lbData.frontends.map((value, index) => (
                            <>
                              <div className="card mb-5 mb-xl-10">
                                <div className="card-header border-0">
                                  {/*begin::Heading*/}
                                  <div
                                    onClick={(e) => toggleFront(value.id)}
                                    className="card-title"
                                    style={{ marginLeft: "-7px" }}
                                  >
                                    <h3>
                                      <span
                                        id={"front-close-sec-" + value.id}
                                        className="svg-icon svg-icon-primary svg-icon-2hx"
                                        style={{ marginRight: "2px" }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <rect
                                            x={6}
                                            y={11}
                                            width={12}
                                            height={2}
                                            rx={1}
                                            fill="black"
                                          />
                                        </svg>
                                      </span>
                                      <span
                                        id={"front-open-sec-" + value.id}
                                        className="d-none svg-icon svg-icon-primary svg-icon-2hx"
                                        style={{ marginRight: "20px" }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <rect
                                            opacity="0.5"
                                            x="11.364"
                                            y="20.364"
                                            width={16}
                                            height={2}
                                            rx={1}
                                            transform="rotate(-90 11.364 20.364)"
                                            fill="black"
                                          />
                                          <rect
                                            x="4.36396"
                                            y="11.364"
                                            width={16}
                                            height={2}
                                            rx={1}
                                            fill="black"
                                          />
                                        </svg>
                                      </span>
                                      {value.name}-{lbData.type}
                                    </h3>
                                  </div>
                                  {/*end::Heading*/}
                                  {/*begin::Toolbar*/}
                                  <div className="card-toolbar">
                                    <a
                                      onClick={(e) => deleteFrontend(value.id)}
                                      className="btn btn-sm btn-danger me-2"
                                    >
                                      Delete Frontend
                                    </a>
                                  </div>
                                  {/*end::Toolbar*/}
                                </div>
                                <div className="" id={"front-sec-" + value.id}>
                                  <div className="mb-10 border-line rounded">
                                    <Setting
                                      lData={value}
                                      lbParentsData={lbData}
                                      refreshLb={getLoadbalancer}
                                    />
                                  </div>
                                  {lbData.type == "network" ? (
                                    <>
                                      <div className="mb-10 border-line rounded">
                                        <Backends
                                          bData={value}
                                          refreshLb={getLoadbalancer}
                                        />
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="mb-10 ">
                                        <AdvanceRouting
                                          arData={value}
                                          refreshLb={getLoadbalancer}
                                        />
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </>
                          ))}
                        </>
                      ) : (
                        <>
                          <div id="add_frontend_sec_btn">
                            {!isAddingFrontend ?
                              <div className="card-body p-0 mt-10">
                                {/*begin::Illustration*/}
                                <div className="text-center px-4 mt-10">
                                  <span className="mw-100 mh-300px">
                                    <svg
                                      fill="#21325B"
                                      viewBox="0 0 32 32"
                                      width="234px"
                                      height="234px"
                                      id="icon"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                      <g
                                        id="SVGRepo_tracerCarrier"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <g id="SVGRepo_iconCarrier">
                                        {" "}
                                        <defs>
                                          {" "}
                                          <style
                                            dangerouslySetInnerHTML={{
                                              __html: " .cls-1 { fill: none; } ",
                                            }}
                                          />{" "}
                                        </defs>{" "}
                                        <path
                                          d="M8,30H2V24H8ZM4,28H6V26H4Z"
                                          transform="translate(0 0)"
                                        />{" "}
                                        <path
                                          d="M19,30H13V24h6Zm-4-2h2V26H15Z"
                                          transform="translate(0 0)"
                                        />{" "}
                                        <path
                                          d="M30,30H24V24h6Zm-4-2h2V26H26Z"
                                          transform="translate(0 0)"
                                        />{" "}
                                        <path
                                          d="M16,10a4,4,0,1,1,4-4A4.0045,4.0045,0,0,1,16,10Zm0-6a2,2,0,1,0,2,2A2.002,2.002,0,0,0,16,4Z"
                                          transform="translate(0 0)"
                                        />{" "}
                                        <path
                                          d="M26,16H21a4.0045,4.0045,0,0,1-4-4H15a4.0045,4.0045,0,0,1-4,4H6a2.0023,2.0023,0,0,0-2,2v4H6V18h5A5.9694,5.9694,0,0,0,15,16.46V22h2V16.46A5.9694,5.9694,0,0,0,21,18h5v4h2V18A2.0023,2.0023,0,0,0,26,16Z"
                                          transform="translate(0 0)"
                                        />{" "}
                                        <rect
                                          id="_Transparent_Rectangle_"
                                          data-name="<Transparent Rectangle>"
                                          className="cls-1"
                                          width={32}
                                          height={32}
                                        />{" "}
                                      </g>
                                    </svg>
                                  </span>
                                </div>
                                {/*end::Illustration*/}
                                {/*begin::Wrapper*/}
                                <div className="card-px text-center py-5 mb-20">
                                  {/*begin::Title*/}
                                  <h1 className="fs-2x fw-bolder mb-5">Frontend</h1>
                                  <h2 className="fs-1x fw-bolder mb-5 text-gray-800">
                                    Create your first frontend
                                  </h2>
                                  {/*end::Title*/}
                                  {/*begin::Description*/}
                                  <p className="text-gray-700 fs-4 fw-bold mb-10">
                                    Optimize your cloud infrastructure with Utho's
                                    Load Balancer frontend.
                                  </p>
                                  {/*end::Description*/}
                                  {/*begin::Action*/}
                                  <a
                                    onClick={toggleFrontend}
                                    className="btn btn-primary mb-10"
                                  >
                                    Create Frontend
                                  </a>
                                  {/*end::Action*/}
                                </div>
                                {/*end::Wrapper*/}
                              </div>
                              :
                              <TableLoader />
                            }
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {/* {console.log("------------------>>>>>>><<<<<<<<<<<<")} */}
                      <TableLoader />
                      {/* <TopBarHeaderLine/> */}
                    </>
                  )}
                </>
              }
            </div>
            {/* : */}
            <div className="tab-pane fade" id="destroy-load" role="tabpanel">
              {/*begin::Deactivate Account*/}
              {isDestroy &&
                <div className="card">
                  {/*begin::Card header*/}
                  <div
                    className="card-header border-0 cursor-pointer"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_account_deactivate"
                    aria-expanded="true"
                    aria-controls="kt_account_deactivate"
                  >
                    <div className="card-title m-0">
                      <h3 className="fw-bolder m-0">Destroy Load Balancer</h3>
                    </div>
                  </div>
                  {/*end::Card header*/}
                  {/*begin::Content*/}
                  <div
                    id="kt_account_settings_deactivate"
                    className="collapse show"
                  >
                    {/*begin::Form*/}
                    {/*begin::Card body*/}
                    <div className="card-body border-top p-9">
                      {/*begin::Form input row*/}
                      <div className="mb-10">
                        <label htmlFor="size" className=" form-label">
                          Your Load Balancer will be permanently destroyed. Any
                          associated servers will be disconnected and will stop
                          receiving distributed traffic. Servers will not be
                          destroyed. You will lose the provisioned IP address,
                          which might impact any DNS records pointing to it. This
                          will not affect any associated Servers.{" "}
                        </label>
                      </div>
                      {/*end::Form input row*/}
                    </div>
                    {/*end::Card body*/}
                    {/*begin::Card footer*/}
                    <div
                      className="card-footer d-flex justify-content-end py-6 px-9"
                      id="destroy-load"
                    >
                      <button
                        onClick={destroyLb}
                        type="button"
                        className="btn btn-sm btn-danger fw-bold"
                      >
                        Destroy
                      </button>
                    </div>
                    {/*end::Card footer*/}
                    {/*end::Form*/}
                  </div>
                  {/*end::Content*/}
                </div>
              }
              {/*end::Deactivate Account*/}
            </div>
            {/* } */}
          </div>
        </>
      ) : (
        <>
          {" "}
          <TableLoader />
          <TopBarHeaderLine />
        </>
      )}
    </>
  );
}
