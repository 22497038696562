import { useEffect, useState } from "react";


export default function CustomDoc(props) {
    const [docName, setDocName]= useState("");
    // console.log(props);
    const openDoc = () => {
        var drawerElement = document.querySelector('#drawer-doc-' + docName);
        var drawer_overlay = document.querySelector('#drawer-overlay-doc-' + docName);
        if(drawerElement && drawer_overlay){ 
        drawerElement.classList.toggle("drawer-on");
        if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
         drawerElement.style.width = "100%";
       } else {
         drawerElement.style.width = "50%";
       }
        drawer_overlay.classList.toggle("d-none");
        }
       };

       useEffect(()=>{
         var tempUrl = props.url;
         if(tempUrl){
            var urlArr = tempUrl?.split("/");
            if(urlArr[urlArr.length-1]==""){
                if(urlArr[urlArr.length-2].includes('#')){
                    var doc = urlArr[urlArr.length-2].split("#")[1]
                    setDocName(doc)
                }else{
                    // alert("hello")
                    setDocName(urlArr[urlArr.length-2])
                }
           }else{
            if(urlArr[urlArr.length-1].includes('#')){
                var doc = urlArr[urlArr.length-1].split("#")[1]
                setDocName(doc)
            }else{
                // alert("hello")
                setDocName(urlArr[urlArr.length-1])
            }
           } 
         }
       },[])

    return (
        <>
        <span className="text-muted mt-1 fw-bold fs-5">
        {/* text-primary ms-1 fw-bold fs-7 */}
        {props?.anchor ? (<>
           <a className="fs-6 text-primary" style={{ cursor: 'pointer' }} onClick={openDoc}>Learn more.</a>
        </>):(<>
            <span className="btn btn-sm btn-light-primary btn-active-light-primary" onClick={openDoc} style={{ cursor: 'pointer' }}>Learn more &nbsp;
                 <span className="svg-icon svg-icon-primary">
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                        <g id="SVGRepo_iconCarrier">
                            {" "}
                            <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4 5V19C4 20.6569 5.34315 22 7 22H17C18.6569 22 20 20.6569 20 19V9C20 7.34315 18.6569 6 17 6H5C4.44772 6 4 5.55228 4 5ZM7.25 12C7.25 11.5858 7.58579 11.25 8 11.25H16C16.4142 11.25 16.75 11.5858 16.75 12C16.75 12.4142 16.4142 12.75 16 12.75H8C7.58579 12.75 7.25 12.4142 7.25 12ZM7.25 15.5C7.25 15.0858 7.58579 14.75 8 14.75H13.5C13.9142 14.75 14.25 15.0858 14.25 15.5C14.25 15.9142 13.9142 16.25 13.5 16.25H8C7.58579 16.25 7.25 15.9142 7.25 15.5Z"
                            fill="#009ef7"
                            />{" "}
                            <path
                            d="M4.40879 4.0871C4.75727 4.24338 5 4.59334 5 5H17C17.3453 5 17.6804 5.04375 18 5.12602V4.30604C18 3.08894 16.922 2.15402 15.7172 2.32614L4.91959 3.86865C4.72712 3.89615 4.55271 3.97374 4.40879 4.0871Z"
                            fill="#009ef7"
                            />{" "}
                        </g>
                        </svg>
                 </span>
            </span>
            </>)}
        </span>

            <div id={'drawer-overlay-doc-'+ docName} onClick={openDoc} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>
            <div
                id={'drawer-doc-'+ docName}
                className="bg-body shadow drawer drawer-end"
                // data-kt-drawer="true"
                // data-kt-drawer-name="chat"
                // data-kt-drawer-activate="true"
                data-kt-drawer-open="#open_ssh"
                data-kt-drawer-close="#close_ssh"
                // data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
                style={{ important: true, zIndex: 110 }}
            >
                <div
                    className="card rounded-0 w-100 border-0"
                    id="kt_drawer_chat_messenger"
                >
                    {/*begin::Card header*/}
                    <div
                        className="card-header pe-5"
                        id="kt_drawer_chat_messenger_header"
                    >
                        {/*begin::Title*/}
                        <div className="card-title">
                            {/*begin::User*/}
                            <div className="d-flex justify-content-center flex-column me-3">
                                <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                                    Open in new tab  &nbsp;
                                    <a href={props.url} target="_blank">
                                        {/*begin::Svg Icon | path: assets/media/icons/duotune/arrows/arr095.svg*/}
                                        <span className="svg-icon svg-icon-primary svg-icon-2">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                            >
                                                <path
                                                    opacity="0.3"
                                                    d="M4.7 17.3V7.7C4.7 6.59543 5.59543 5.7 6.7 5.7H9.8C10.2694 5.7 10.65 5.31944 10.65 4.85C10.65 4.38056 10.2694 4 9.8 4H5C3.89543 4 3 4.89543 3 6V19C3 20.1046 3.89543 21 5 21H18C19.1046 21 20 20.1046 20 19V14.2C20 13.7306 19.6194 13.35 19.15 13.35C18.6806 13.35 18.3 13.7306 18.3 14.2V17.3C18.3 18.4046 17.4046 19.3 16.3 19.3H6.7C5.59543 19.3 4.7 18.4046 4.7 17.3Z"
                                                    fill="black"
                                                />
                                                <rect
                                                    x="21.9497"
                                                    y="3.46448"
                                                    width={13}
                                                    height={2}
                                                    rx={1}
                                                    transform="rotate(135 21.9497 3.46448)"
                                                    fill="black"
                                                />
                                                <path
                                                    d="M19.8284 4.97161L19.8284 9.93937C19.8284 10.5252 20.3033 11 20.8891 11C21.4749 11 21.9497 10.5252 21.9497 9.93937L21.9497 3.05029C21.9497 2.498 21.502 2.05028 20.9497 2.05028L14.0607 2.05027C13.4749 2.05027 13 2.52514 13 3.11094C13 3.69673 13.4749 4.17161 14.0607 4.17161L19.0284 4.17161C19.4702 4.17161 19.8284 4.52978 19.8284 4.97161Z"
                                                    fill="black"
                                                />
                                            </svg>
                                        </span>
                                        {/*end::Svg Icon*/}
                                    </a>
                                </a>
                            </div>
                            {/*end::User*/}
                        </div>
                        {/*end::Title*/}
                        {/*begin::Card toolbar*/}
                        <div className="card-toolbar">
                            {/*begin::Close*/}
                            <div
                                onClick={openDoc}
                                className="btn btn-sm btn-icon btn-active-light-primary"
                                id="close_ssh"
                            >
                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                                <span className="svg-icon svg-icon-2">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <rect
                                            opacity="0.5"
                                            x={6}
                                            y="17.3137"
                                            width={16}
                                            height={2}
                                            rx={1}
                                            transform="rotate(-45 6 17.3137)"
                                            fill="black"
                                        />
                                        <rect
                                            x="7.41422"
                                            y={6}
                                            width={16}
                                            height={2}
                                            rx={1}
                                            transform="rotate(45 7.41422 6)"
                                            fill="black"
                                        />
                                    </svg>
                                </span>
                                {/*end::Svg Icon*/}
                            </div>
                            {/*end::Close*/}
                        </div>
                        {/*end::Card toolbar*/}
                    </div>
                    {/*end::Card header*/}
                    <iframe
                        src={props.url}
                        width="100%"
                        height="100%"
                    />
                </div>
            </div>
        </>
    )
}
