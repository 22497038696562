import {React} from 'react';

export default function Card3WithIcon(){
    return (<>
 <table width="100%" border={0} align="center" cellPadding={0} cellSpacing={0}>
        {/* START HEADER/BANNER */}
        <tbody><tr>
            <td align="center">
              <table className="col-600" width={600} border={0} align="center" cellPadding={0} cellSpacing={0}>
                <tbody><tr>
                    <td align="center" valign="top" background="https://designmodo.com/demo/emailtemplate/images/header-background.jpg" bgcolor="#66809b" style={{backgroundSize: 'cover', backgroundPosition: 'top'}} >
                      <table className="col-600" width={600} height={400} border={0} align="center" cellPadding={0} cellSpacing={0}>
                        <tbody><tr>
                            <td height={40} />
                          </tr>
                          <tr>
                            <td align="center" style={{lineHeight: '0px'}}>
                              <img style={{display: 'block', lineHeight: '0px', fontSize: '0px', border: '0px'}} src="https://designmodo.com/demo/emailtemplate/images/logo.png" width={109} height={103} alt="logo" />
                            </td>
                          </tr>
                          <tr>
                            <td align="center" style={{fontFamily: '"Raleway", sans-serif', fontSize: '37px', color: '#ffffff', lineHeight: '24px', fontWeight: 'bold', letterSpacing: '7px'}}>
                              EMAIL <span style={{fontFamily: '"Raleway", sans-serif', fontSize: '37px', color: '#ffffff', lineHeight: '39px', fontWeight: 300, letterSpacing: '7px'}}>TEMPLATE</span>
                            </td>
                          </tr>
                          <tr>
                            <td align="center" style={{fontFamily: '"Lato", sans-serif', fontSize: '15px', color: '#ffffff', lineHeight: '24px', fontWeight: 300}}>
                              A creative email template for your email campaigns, promotions <br />and products across different email platforms.
                            </td>
                          </tr>
                          <tr>
                            <td height={50} />
                          </tr>
                        </tbody></table>
                    </td>
                  </tr>
                </tbody></table>
            </td>
          </tr>
          {/* END HEADER/BANNER */}
          {/* START 3 BOX SHOWCASE */}
          <tr>
            <td align="center">
              <table className="col-600" width={600} border={0} align="center" cellPadding={0} cellSpacing={0} style={{marginLeft: '20px', marginRight: '20px', borderLeft: '1px solid #dbd9d9', borderRight: '1px solid #dbd9d9'}}>
                <tbody><tr>
                    <td height={35} />
                  </tr>
                  <tr>
                    <td align="center" style={{fontFamily: '"Raleway", sans-serif', fontSize: '22px', fontWeight: 'bold', color: '#2a3a4b'}}>A creative way to showcase your content</td>
                  </tr>
                  <tr>
                    <td height={10} />
                  </tr>
                  <tr>
                    <td align="center" style={{fontFamily: '"Lato", sans-serif', fontSize: '14px', color: '#757575', lineHeight: '24px', fontWeight: 300}}>
                      Prepare some  flat icons of your choice. You can place your content below.<br />
                      Make sure it's awesome.
                    </td>
                  </tr>
                </tbody></table>
            </td>
          </tr>
          <tr>
            <td align="center">
              <table className="col-600" width={600} border={0} align="center" cellPadding={0} cellSpacing={0} style={{borderLeft: '1px solid #dbd9d9', borderRight: '1px solid #dbd9d9'}}>
                <tbody><tr>
                    <td height={10} />
                  </tr>
                  <tr>
                    <td>
                      <table className="col3" width={183} border={0} align="left" cellPadding={0} cellSpacing={0}>
                        <tbody><tr>
                            <td height={30} />
                          </tr>
                          <tr>
                            <td align="center">
                              <table className="insider" width={133} border={0} align="center" cellPadding={0} cellSpacing={0}>
                                <tbody><tr align="center" style={{lineHeight: '0px'}}>
                                    <td>
                                      <img style={{display: 'block', lineHeight: '0px', fontSize: '0px', border: '0px'}} src="https://designmodo.com/demo/emailtemplate/images/icon-about.png" width={69} height={78} alt="icon" />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td height={15} />
                                  </tr>
                                  <tr align="center">
                                    <td style={{fontFamily: '"Raleway", Arial, sans-serif', fontSize: '20px', color: '#2b3c4d', lineHeight: '24px', fontWeight: 'bold'}}>About Us</td>
                                  </tr>
                                  <tr>
                                    <td height={10} />
                                  </tr>
                                  <tr align="center">
                                    <td style={{fontFamily: '"Lato", sans-serif', fontSize: '14px', color: '#757575', lineHeight: '24px', fontWeight: 300}}>Place some cool text here.</td>
                                  </tr>
                                </tbody></table>
                            </td>
                          </tr>
                          <tr>
                            <td height={30} />
                          </tr>
                        </tbody></table>
                      <table width={1} height={20} border={0} cellPadding={0} cellSpacing={0} align="left">
                        <tbody><tr>
                            <td height={20} style={{fontSize: 0, lineHeight: 0, borderCollapse: 'collapse'}}>
                              <p style={{paddingLeft: '24px'}}>&nbsp;</p>
                            </td>
                          </tr>
                        </tbody></table>
                      <table className="col3" width={183} border={0} align="left" cellPadding={0} cellSpacing={0}>
                        <tbody><tr>
                            <td height={30} />
                          </tr>
                          <tr>
                            <td align="center">
                              <table className="insider" width={133} border={0} align="center" cellPadding={0} cellSpacing={0}>
                                <tbody><tr align="center" style={{lineHeight: '0px'}}>
                                    <td>
                                      <img style={{display: 'block', lineHeight: '0px', fontSize: '0px', border: '0px'}} src="https://designmodo.com/demo/emailtemplate/images/icon-team.png" width={69} height={78} alt="icon" />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td height={15} />
                                  </tr>
                                  <tr align="center">
                                    <td style={{fontFamily: '"Raleway", sans-serif', fontSize: '20px', color: '#2b3c4d', lineHeight: '24px', fontWeight: 'bold'}}>Our Team</td>
                                  </tr>
                                  <tr>
                                    <td height={10} />
                                  </tr>
                                  <tr align="center">
                                    <td style={{fontFamily: '"Lato", sans-serif', fontSize: '14px', color: '#757575', lineHeight: '24px', fontWeight: 300}}>Place some cool text here.</td>
                                  </tr>
                                </tbody></table>
                            </td>
                          </tr>
                          <tr>
                            <td height={30} />
                          </tr>
                        </tbody></table>
                      <table width={1} height={20} border={0} cellPadding={0} cellSpacing={0} align="left">
                        <tbody><tr>
                            <td height={20} style={{fontSize: 0, lineHeight: 0, borderCollapse: 'collapse'}}>
                              <p style={{paddingLeft: '24px'}}>&nbsp;</p>
                            </td>
                          </tr>
                        </tbody></table>
                      <table className="col3" width={183} border={0} align="right" cellPadding={0} cellSpacing={0}>
                        <tbody><tr>
                            <td height={30} />
                          </tr>
                          <tr>
                            <td align="center">
                              <table className="insider" width={133} border={0} align="center" cellPadding={0} cellSpacing={0}>
                                <tbody><tr align="center" style={{lineHeight: '0px'}}>
                                    <td>
                                      <img style={{display: 'block', lineHeight: '0px', fontSize: '0px', border: '0px'}} src="https://designmodo.com/demo/emailtemplate/images/icon-portfolio.png" width={69} height={78} alt="icon" />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td height={15} />
                                  </tr>
                                  <tr align="center">
                                    <td style={{fontFamily: '"Raleway",  sans-serif', fontSize: '20px', color: '#2b3c4d', lineHeight: '24px', fontWeight: 'bold'}}>Our Portfolio</td>
                                  </tr>
                                  <tr>
                                    <td height={10} />
                                  </tr>
                                  <tr align="center">
                                    <td style={{fontFamily: '"Lato", sans-serif', fontSize: '14px', color: '#757575', lineHeight: '24px', fontWeight: 300}}>Place some cool text here.</td>
                                  </tr>
                                </tbody></table>
                            </td>
                          </tr>
                          <tr>
                            <td height={30} />
                          </tr>
                        </tbody></table>
                    </td>
                  </tr>
                </tbody></table>
            </td>
          </tr>
          <tr>
            <td height={5} />
          </tr>
          {/* END 3 BOX SHOWCASE */}
          {/* START AWESOME TITLE */}
          <tr>
            <td align="center">
              <table align="center" className="col-600" width={600} border={0} cellSpacing={0} cellPadding={0}>
                <tbody><tr>
                    <td align="center" bgcolor="#2a3b4c">
                      <table className="col-600" width={600} align="center" border={0} cellSpacing={0} cellPadding={0}>
                        <tbody><tr>
                            <td height={33} />
                          </tr>
                          <tr>
                            <td>
                              <table className="col1" width={183} border={0} align="left" cellPadding={0} cellSpacing={0}>
                                <tbody><tr>
                                    <td height={18} />
                                  </tr>
                                  <tr>
                                    <td align="center">
                                      <img style={{display: 'block', lineHeight: '0px', fontSize: '0px', border: '0px'}} className="images_style" src="https://designmodo.com/demo/emailtemplate/images/icon-title.png" alt="img" width={156} height={136} />
                                    </td>
                                  </tr>
                                </tbody></table>
                              <table className="col3_one" width={380} border={0} align="right" cellPadding={0} cellSpacing={0}>
                                <tbody><tr align="left" valign="top">
                                    <td style={{fontFamily: '"Raleway", sans-serif', fontSize: '20px', color: '#f1c40f', lineHeight: '30px', fontWeight: 'bold'}}>This title is definitely awesome! </td>
                                  </tr>
                                  <tr>
                                    <td height={5} />
                                  </tr>
                                  <tr align="left" valign="top">
                                    <td style={{fontFamily: '"Lato", sans-serif', fontSize: '14px', color: '#fff', lineHeight: '24px', fontWeight: 300}}>
                                      The use of flat colors in web design is more than a recent trend, it is a style designers have used for years to create impactful visuals. When you hear "flat", it doesn't mean boring it just means minimalist.
                                    </td>
                                  </tr>
                                  <tr>
                                    <td height={10} />
                                  </tr>
                                  <tr align="left" valign="top">
                                    <td>
                                      <table className="button" style={{border: '2px solid #fff'}} bgcolor="#2b3c4d" width="30%" border={0} cellPadding={0} cellSpacing={0}>
                                        <tbody><tr>
                                            <td width={10} />
                                            <td height={30} align="center" style={{fontFamily: '"Open Sans", Arial, sans-serif', fontSize: '13px', color: '#ffffff'}}>
                                              <a href="#" style={{color: '#ffffff'}}>Read more</a>
                                            </td>
                                            <td width={10} />
                                          </tr>
                                        </tbody></table>
                                    </td>
                                  </tr>
                                </tbody></table>
                            </td>
                          </tr>
                          <tr>
                            <td height={33} />
                          </tr>
                        </tbody></table>
                    </td>
                  </tr>
                </tbody></table>
            </td>
          </tr>
          {/* END AWESOME TITLE */}
          {/* START WHAT WE DO */}
          <tr>
            <td align="center">
              <table className="col-600" width={600} border={0} align="center" cellPadding={0} cellSpacing={0} style={{marginLeft: '20px', marginRight: '20px'}}>
                <tbody><tr>
                    <td align="center">
                      <table className="col-600" width={600} border={0} align="center" cellPadding={0} cellSpacing={0} style={{borderLeft: '1px solid #dbd9d9', borderRight: '1px solid #dbd9d9'}}>
                        <tbody><tr>
                            <td height={50} />
                          </tr>
                          <tr>
                            <td align="right">
                              <table className="col2" width={287} border={0} align="right" cellPadding={0} cellSpacing={0}>
                                <tbody><tr>
                                    <td align="center" style={{lineHeight: '0px'}}>
                                      <img style={{display: 'block', lineHeight: '0px', fontSize: '0px', border: '0px'}} className="images_style" src="https://designmodo.com/demo/emailtemplate/images/icon-responsive.png" width={169} height={138} />
                                    </td>
                                  </tr>
                                </tbody></table>
                              <table width={287} border={0} align="left" cellPadding={0} cellSpacing={0} className="col2" style={{}}>
                                <tbody><tr>
                                    <td align="center">
                                      <table className="insider" width={237} border={0} align="center" cellPadding={0} cellSpacing={0}>
                                        <tbody><tr align="left">
                                            <td style={{fontFamily: '"Raleway", sans-serif', fontSize: '23px', color: '#2a3b4c', lineHeight: '30px', fontWeight: 'bold'}}>What we do?</td>
                                          </tr>
                                          <tr>
                                            <td height={5} />
                                          </tr>
                                          <tr>
                                            <td style={{fontFamily: '"Lato", sans-serif', fontSize: '14px', color: '#7f8c8d', lineHeight: '24px', fontWeight: 300}}>
                                              We create responsive websites with modern designs and features for small businesses and organizations that are professionally developed and SEO optimized.
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                  </tr>
                                </tbody></table>
                            </td>
                          </tr>
                        </tbody></table>
                    </td>
                  </tr>
                  {/* END WHAT WE DO */}
                  {/* START READY FOR NEW PROJECT */}
                  <tr>
                    <td align="center">
                      <table align="center" width="100%" border={0} cellSpacing={0} cellPadding={0} style={{borderLeft: '1px solid #dbd9d9', borderRight: '1px solid #dbd9d9'}}>
                        <tbody><tr>
                            <td height={50} />
                          </tr>
                          <tr>
                            <td align="center" bgcolor="#34495e">
                              <table className="col-600" width={600} border={0} align="center" cellPadding={0} cellSpacing={0}>
                                <tbody><tr>
                                    <td height={35} />
                                  </tr>
                                  <tr>
                                    <td align="center" style={{fontFamily: '"Raleway", sans-serif', fontSize: '20px', color: '#f1c40f', lineHeight: '24px', fontWeight: 'bold'}}>Ready for new project?</td>
                                  </tr>
                                  <tr>
                                    <td height={20} />
                                  </tr>
                                  <tr>
                                    <td align="center" style={{fontFamily: '"Lato", sans-serif', fontSize: '14px', color: '#fff', lineHeight: '1px', fontWeight: 300}}>
                                      Check out our price below.
                                    </td>
                                  </tr>
                                  <tr>
                                    <td height={40} />
                                  </tr>
                                </tbody></table>
                            </td>
                          </tr>
                        </tbody></table>
                    </td>
                  </tr>
                  {/* END READY FOR NEW PROJECT */}
                  {/* START PRICING TABLE */}
                  <tr>
                    <td align="center">
                      <table width={600} className="col-600" align="center" border={0} cellSpacing={0} cellPadding={0} style={{borderLeft: '1px solid #dbd9d9', borderRight: '1px solid #dbd9d9'}}>
                        <tbody><tr>
                            <td height={50} />
                          </tr>
                          <tr>
                            <td>
                              <table style={{border: '1px solid #e2e2e2'}} className="col2" width={287} border={0} align="left" cellPadding={0} cellSpacing={0}>
                                <tbody><tr>
                                    <td height={40} align="center" bgcolor="#2b3c4d" style={{fontFamily: '"Raleway", sans-serif', fontSize: '18px', color: '#f1c40f', lineHeight: '30px', fontWeight: 'bold'}}>Small Business Website</td>
                                  </tr>
                                  <tr>
                                    <td align="center">
                                      <table className="insider" width={237} border={0} align="center" cellPadding={0} cellSpacing={0}>
                                        <tbody><tr>
                                            <td height={20} />
                                          </tr>
                                          <tr align="center" style={{lineHeight: '0px'}}>
                                            <td style={{fontFamily: '"Lato", sans-serif', fontSize: '48px', color: '#2b3c4d', fontWeight: 'bold', lineHeight: '44px'}}>$150</td>
                                          </tr>
                                          <tr>
                                            <td height={15} />
                                          </tr>
                                          <tr>
                                            <td height={15} />
                                          </tr>
                                          <tr>
                                            <td align="center">
                                              <table width={100} border={0} align="center" cellPadding={0} cellSpacing={0} style={{border: '2px solid #2b3c4d'}}>
                                                <tbody><tr>
                                                    <td width={10} />
                                                    <td height={30} align="center" style={{fontFamily: '"Lato", sans-serif', fontSize: '14px', fontWeight: 300, color: '#2b3c4d'}}>
                                                      <a href="#" style={{color: '#2b3c4d'}}>Learn More</a>
                                                    </td>
                                                    <td width={10} />
                                                  </tr>
                                                </tbody></table>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td height={30} />
                                  </tr>
                                </tbody></table>
                              <table width={1} height={20} border={0} cellPadding={0} cellSpacing={0} align="left">
                                <tbody><tr>
                                    <td height={20} style={{fontSize: 0, lineHeight: 0, borderCollapse: 'collapse'}}>
                                      <p style={{paddingLeft: '24px'}}>&nbsp;</p>
                                    </td>
                                  </tr>
                                </tbody></table>
                              <table style={{border: '1px solid #e2e2e2'}} className="col2" width={287} border={0} align="right" cellPadding={0} cellSpacing={0}>
                                <tbody><tr>
                                    <td height={40} align="center" bgcolor="#2b3c4d" style={{fontFamily: '"Raleway", sans-serif', fontSize: '18px', color: '#f1c40f', lineHeight: '30px', fontWeight: 'bold'}}>E-commerce Website</td>
                                  </tr>
                                  <tr>
                                    <td align="center">
                                      <table className="insider" width={237} border={0} align="center" cellPadding={0} cellSpacing={0}>
                                        <tbody><tr>
                                            <td height={20} />
                                          </tr>
                                          <tr align="center" style={{lineHeight: '0px'}}>
                                            <td style={{fontFamily: '"Lato", sans-serif', fontSize: '48px', color: '#2b3c4d', fontWeight: 'bold', lineHeight: '44px'}}>$289</td>
                                          </tr>
                                          <tr>
                                            <td height={30} />
                                          </tr>
                                          <tr align="center">
                                            <td>
                                              <table width={100} border={0} align="center" cellPadding={0} cellSpacing={0} style={{border: '2px solid #2b3c4d'}}>
                                                <tbody><tr>
                                                    <td width={10} />
                                                    <td height={30} align="center" style={{fontFamily: '"Lato", sans-serif', fontSize: '14px', fontWeight: 300, color: '#2b3c4d'}}>
                                                      <a href="#" style={{color: '#2b3c4d'}}>Learn More</a>
                                                    </td>
                                                    <td width={10} />
                                                  </tr>
                                                </tbody></table>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td height={20} />
                                  </tr>
                                </tbody></table>
                            </td>
                          </tr>
                        </tbody></table>
                    </td>
                  </tr>
                  {/* END PRICING TABLE */}
                  {/* START FOOTER */}
                  <tr>
                    <td align="center">
                      <table align="center" width="100%" border={0} cellSpacing={0} cellPadding={0} style={{borderLeft: '1px solid #dbd9d9', borderRight: '1px solid #dbd9d9'}}>
                        <tbody><tr>
                            <td height={50} />
                          </tr>
                          <tr>
                            <td align="center" bgcolor="#34495e" background="https://designmodo.com/demo/emailtemplate/images/footer.jpg" height={185}>
                              <table className="col-600" width={600} border={0} align="center" cellPadding={0} cellSpacing={0}>
                                <tbody><tr>
                                    <td height={25} />
                                  </tr>
                                  <tr>
                                    <td align="center" style={{fontFamily: '"Raleway",  sans-serif', fontSize: '26px', fontWeight: 500, color: '#f1c40f'}}>Follow us for some cool stuffs</td>
                                  </tr>
                                  <tr>
                                    <td height={25} />
                                  </tr>
                                </tbody></table><table align="center" width="35%" border={0} cellSpacing={0} cellPadding={0}>
                                <tbody><tr>
                                    <td align="center" width="30%" style={{verticalAlign: 'top'}}>
                                      <a href="https://www.facebook.com/designmodo" target="_blank"> <img src="https://designmodo.com/demo/emailtemplate/images/icon-fb.png" /> </a>
                                    </td>
                                    <td align="center" className="margin" width="30%" style={{verticalAlign: 'top'}}>
                                      <a href="https://twitter.com/designmodo" target="_blank"> <img src="https://designmodo.com/demo/emailtemplate/images/icon-twitter.png" /> </a>
                                    </td>
                                    <td align="center" width="30%" style={{verticalAlign: 'top'}}>
                                      <a href="https://plus.google.com/+Designmodo/posts" target="_blank"> <img src="https://designmodo.com/demo/emailtemplate/images/icon-googleplus.png" /> </a>
                                    </td>
                                  </tr>
                                </tbody></table>
                            </td></tr></tbody></table>
                    </td>
                  </tr>
                </tbody></table>
            </td>
          </tr>
          {/* END FOOTER */}
        </tbody>
        </table>
    </>)
}