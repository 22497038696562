import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom/dist";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UsePost from "../../../../CustomHooks/usePost";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";

export default function DeployDns(props) {
  const [pageStatus, setPageStatus] = React.useState(false)
  const [width, setWidth] = useState(window.innerWidth >= 768 ? '45%' : '50%');
  const [paddingRight, setpaddingRight] = useState(window.innerWidth >= 768 ? '1.3rem' : '0rem');
  const [paddingLeft, setpaddingleft] = useState(window.innerWidth >= 768 ? '0.3rem' : '0.3rem');
  toast.dismiss();
  let navigate = useNavigate();
  const deployDns = async () => {
    var submitBtn = document.querySelector("#dns-btn");
    var domainName = document.querySelector("#domain");

    if (submitBtn) {
      setPageStatus(true)
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      const dt = toast.loading("Please wait...");
      if (domainName) {
        if (domainName.value == "") {
          submitBtn.classList.remove("disabled");
          submitBtn.innerHTML = preContent;
          toast.update(dt, {
            render: "Domain name must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          return;
        }
      }
      const responseData = await UsePost('post', 'dns/adddomain', 'adddomain');
      if (responseData) {
        setPageStatus(false)
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
        //console.log(responseData);
        if (responseData.status === 'error') {
          toast.update(dt, {
            render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        } else if (responseData.status === 'success') {
          toast.update(dt, {
            render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          window.onDeployEvents('deploy_dns');
          openDrawer(); 
            if (props && props.updateDnsList) {
              props.updateDnsList();
            } 
        }
      }
    }
  }
 
  const openDrawer = () => {
    var drawerElement = document.querySelector("#dns-modal");
    var drawer_overlay = document.querySelector("#drawer_overlay");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      drawerElement.style.width = width
      drawer_overlay.classList.toggle("d-none");
    }
  };


  console.log(width);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth >= 768 ? '45%' : '50%');
      setpaddingleft(window.innerWidth >= 768 ? '0.3rem' : '0.3rem')
      setpaddingRight(window.innerWidth >= 768 ? '1.3rem' : '0rem')
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (props) {
      if (props.type == "deploy") {
        openDrawer();
      }
    }
  //   const handleResize = () => {
  //     console.log(window.innerWidth,"sjxisjdoskoxz,oxsijdisjfidj");
  //     if (window.innerWidth >= 1200) {
  //       console.log("desktop");
  //       // elementWidth = '80%';
  //   } else if (window.innerWidth >= 768) {
  //     console.log("loptop");
  //       // elementWidth = '60%';
  //   } else {
  //     console.log("mobile");
  //       // elementWidth = '40%';
  //   }
  //     var drawerElement = document.querySelector("#dns-modal");
  //     if (drawerElement) {
  //       drawerElement.style.width = "50%";
  //   };
  // }

  //   window.addEventListener('resize', handleResize);

  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  }, [])
  return (
    <>
      <a
        onClick={openDrawer}
        className="btn btn-sm btn-primary btn-active-light-primary"
        style={{ padding: '0.6rem 0.6rem' }}
      >
        {/*<a class="btn btn-sm btn-light btn-active-primary" data-bs-toggle="modal" data-bs-target="#createfirewall-modal">
            begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
        <span className="svg-icon svg-icon-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
          >
            <rect
              opacity="0.5"
              x="11.364"
              y="20.364"
              width={16}
              height={2}
              rx={1}
              transform="rotate(-90 11.364 20.364)"
              fill="black"
            />
            <rect
              x="4.36396"
              y="11.364"
              width={16}
              height={2}
              rx={1}
              fill="black"
            />
          </svg>
        </span>
        {/*end::Svg Icon*/}Deploy DNS
      </a>
      <div id="drawer_overlay" onClick={openDrawer} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>

      <div
        id="dns-modal"
        className="bg-body shadow drawer drawer-end"
        data-kt-drawer="true"
        data-kt-drawer-name="chat"
        data-kt-drawer-activate="true"
        data-kt-drawer-direction="end"
        data-kt-drawer-close="#close_ssh"
        data-kt-drawer-width="{default:'30%', 'md': '30%', 'sm': '300px'}"
        style={{ maxWidth: width, important: true }}

      >
        {/*begin::Messenger*/}
        <div className="card rounded-0 w-100 border-0" id="kt_drawer_chat_messenger">
          {/*begin::Card header*/}
          <div className="card-header pe-5 p-0 ps-7" id="kt_drawer_chat_messenger_header">
            {/*begin::Title*/}
            <div className="card-title">
              {/*begin::User*/}
              <div className="d-flex justify-content-center flex-column me-3">
                <a

                  className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1 sphide"
                >
                  Add New DNS
                </a>
              </div>
              {/*end::User*/}
            </div>
            {/*end::Title*/}
            {/*begin::Card toolbar*/}
            <div className="card-toolbar pe-2">
              {/*begin::Close*/}
              <div
                onClick={openDrawer}
                className="btn btn-sm btn-icon btn-active-light-primary"
                id="close_ssh"
              >
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                <span className="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x={6}
                      y="17.3137"
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(-45 6 17.3137)"
                      fill="black"
                    />
                    <rect
                      x="7.41422"
                      y={6}
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(45 7.41422 6)"
                      fill="black"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
              </div>
              {/*end::Close*/}
            </div>
            {/*end::Card toolbar*/}
          </div>
          {/*end::Card header*/}
          {/*begin::Card body*/}
          <div className="" id="">
            {/*begin::Form*/}
            <form id="adddomain" onSubmit={(event) => event.preventDefault()} className="form">
              {/*begin::Modal body*/}
              <div className="modal-body ">
                {/*begin::Scroll*/}
                <div
                  className="scroll-y me-n7 pe-7"
                  id="kt_modal_create_api_key_scroll"
                  data-kt-scroll="true"
                  data-kt-scroll-activate="{default: false, lg: true}"
                  data-kt-scroll-max-height="auto"
                  data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                  data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                  data-kt-scroll-offset="300px"
                >
                  {/*begin::Input group*/}
                  <div className="mb-5 fv-row sphide">
                    {/*begin::Label*/}
                    <label className="required fs-5 fw-bold mb-2"> Domain Name</label>
                    {/*end::Label*/}
                    {/*begin::Input*/}
                    <input
                      type="text"
                      className="form-control"
                      name="domain"
                      id="domain"
                      placeholder="mydomain.xyz"
                    />
                    {/*end::Input*/}
                  </div>
                  {/*end::Input group*/}
                </div>
                {/*end::Scroll*/}
              </div>
              {/*end::Modal body*/}
              {/*begin::Modal footer*/}
              <div className="mx-6" style={{paddingRight:paddingRight, paddingLeft:paddingLeft}}>
                {/*begin::Button*/}
                <button
                  style={{ width: "100%" }}
                  onClick={deployDns}
                  className="btn btn-primary"
                  id="dns-btn"
                >
                  <span className="indicator-label sphide">Deploy DNS</span>
                </button>
                {/*end::Button*/}
              </div>
              {/*end::Modal footer*/}
            </form>
            {/*end::Form*/}
          </div>
          {pageStatus &&
            <TopBarHeaderLine />}
          {/*end::Card body*/}
        </div>
        {/*end::Messenger*/}
      </div>
    </>
  );
}

