// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* DateTimePicker.css */
.react-datetime-picker {
    position: relative; /* Ensure relative positioning for child elements */
  }
  
  .react-datetime-picker input {
    z-index: 10000 !important; /* Set a high z-index for the input field */
  }
  
  /* Add other styling as needed */
  `, "",{"version":3,"sources":["webpack://./src/App/MicroApp/Body/Products/autoScaling/DateTimePicker.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB;IACI,kBAAkB,EAAE,mDAAmD;EACzE;;EAEA;IACE,yBAAyB,EAAE,2CAA2C;EACxE;;EAEA,gCAAgC","sourcesContent":["/* DateTimePicker.css */\n.react-datetime-picker {\n    position: relative; /* Ensure relative positioning for child elements */\n  }\n  \n  .react-datetime-picker input {\n    z-index: 10000 !important; /* Set a high z-index for the input field */\n  }\n  \n  /* Add other styling as needed */\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
