// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/assets/images/container-registry.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.active-plan{
  box-shadow: -2px 5px 10px 5px rgb(37 45 51 / 8%), 5px -3px 18px 5px rgb(37 45 51 / 8%);
  border: 2px solid #3498db !important;
}

.cr-plan:hover{
    box-shadow: -2px 5px 10px 5px rgb(37 45 51 / 8%), 5px -3px 18px 5px rgb(37 45 51 / 8%);
    border: 2px solid #3498db !important;
  }

  
  
  .rcard {
    content: "";
    position: absolute; 
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}); /* Path to your watermark image */
    /* opacity: 0.3;   */
    background-size: cover;
    background-position: center;
    /* z-index: -1;   */
  }
  
  .opn_drawer {
    display: block !important;
  }



`, "",{"version":3,"sources":["webpack://./src/App/MicroApp/Body/Products/containerRegistry/custom.css"],"names":[],"mappings":"AAAA;EACE,sFAAsF;EACtF,oCAAoC;AACtC;;AAEA;IACI,sFAAsF;IACtF,oCAAoC;EACtC;;;;EAIA;IACE,WAAW;IACX,kBAAkB;IAClB,yDAAqE,EAAE,iCAAiC;IACxG,oBAAoB;IACpB,sBAAsB;IACtB,2BAA2B;IAC3B,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".active-plan{\n  box-shadow: -2px 5px 10px 5px rgb(37 45 51 / 8%), 5px -3px 18px 5px rgb(37 45 51 / 8%);\n  border: 2px solid #3498db !important;\n}\n\n.cr-plan:hover{\n    box-shadow: -2px 5px 10px 5px rgb(37 45 51 / 8%), 5px -3px 18px 5px rgb(37 45 51 / 8%);\n    border: 2px solid #3498db !important;\n  }\n\n  \n  \n  .rcard {\n    content: \"\";\n    position: absolute; \n    background-image: url('/public/assets/images/container-registry.jpg'); /* Path to your watermark image */\n    /* opacity: 0.3;   */\n    background-size: cover;\n    background-position: center;\n    /* z-index: -1;   */\n  }\n  \n  .opn_drawer {\n    display: block !important;\n  }\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
