import React, { useEffect, useState } from 'react'
import { allowNumbersOnly, randomid } from '../../../../utils/utils';
import localStorage from 'local-storage';

function Storage(props) {
  const [storageData, setStorageData] = useState(props?.storage);
  const [distroData, setDistroData] = useState(props?.distro);
  const [userData, setUserData] = useState(null);

  const modifyStorage = (action, data) => {
    props.manageStorage(action, data);
    var freshStorage = props?.getFreshStorage();
    if (action != 'update') {
      setStorageData(null);
      setTimeout(() => {
        setStorageData(freshStorage);
      }, 1)
    }
    updateebsPrice();
  }

  const validateSize = (size, id) => {
    var sizeElement = document.querySelector("#size-" + id);
    var sizeInput = document.querySelector("#size-input-" + id);
    var priceInput = document.querySelector("#ebs-price-" + id);
    if (sizeElement) {
      if (id != '1') {
        if (size < 1) {
          sizeElement.style.display = "block";
          sizeElement.innerHTML = "The smallest volume size for a ebs volume is 8 GiB";
        } else {
          sizeElement.style.display = "none";
          sizeElement.innerHTML = "";
        }
      } else {
        if (size < 8) {
          sizeElement.style.display = "block";
          sizeElement.innerHTML = "Volume is smaller than snapshot, must be at least 8";
        } else {
          sizeElement.style.display = "none";
          sizeElement.innerHTML = "";
        }
      }
    }
    updateebsPrice();
  }


  const updateebsPrice = () => {
    if (storageData) {
      storageData.map((value, index) => {
        var sizeInput = document.querySelector("#size-input-" + value?.id);
        var priceInput = document.querySelector("#ebs-price-" + value?.id);

        if (sizeInput && priceInput) {
          priceInput.innerHTML = (parseInt(sizeInput.value) * 1.5).toFixed(2);
        }
      })
    }

    if (props.updateEbsStorage) {
      props.updateEbsStorage();
    }
  }

  useEffect(() => {
    const userInfo = localStorage.get("user_info");
    if (userInfo) {
      setUserData(userInfo);
    }

    updateebsPrice();
  }, [storageData])
  return (
    <>
      <div className="col-xl-12 card mb-5">
        {/*begin::List widget 10*/}
        {/*begin::Header*/}
        <div className="card-header border-3 pt-4 ps-6 p-0">
          {/*begin::Title*/}
          <h3 className="card-title align-items-start text-blue flex-column">
            <span className="card-label fw-bolder text-gray-800">
              Configure Storage
            </span>
            <span className="text-muted mt-1 fw-bold fs-6">
              Specify the elastic block storage for the instance.
            </span>
          </h3>
          {/*end::Title*/}
        </div>
        {/*end::Header*/}
        {/*begin::Body*/}
        <div className='p-5'>
          {storageData && storageData.map((value, index) => (<>
            <div className="row">
              <div className='col-2'>
                <div className="input-group mb-5">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Volume size"
                    name={"ebs[" + index + "][disk]"}
                    id={"size-input-" + value.id}
                    defaultValue={value.size}
                    onKeyDown={(e) => { allowNumbersOnly(e); validateSize(e.target.value, value.id) }}
                    onChange={(e) => validateSize(e.target.value, value.id)}
                    onBlur={(e) => { validateSize(e.target.value, value.id); modifyStorage('update', { id: value.id, size: e.target.value, type: value.type }) }}
                  />
                  <span className="input-group-text">
                    GB
                  </span>
                  <div id={"size-" + value.id} class="invalid-feedback ms-12"></div>
                </div>
              </div>
              <div className='col-6'>
                <div className="input-group mb-5">
                  <select
                    className="form-select"
                    name={"ebs[" + index + "][type]"}
                    defaultValue={value.type}
                    onBlur={(e) => modifyStorage('update', { id: value.id, size: value.size, type: e.target.value })}
                  >
                    <option value="nvme">
                      General Purpose SSD (NVME)
                    </option>
                    {/* <option value="other">
                          General Purpose SSD (Other)
                        </option> */}
                  </select>

                  <span className="input-group-text">
                    {value.id != '1' ? (<>EBS &nbsp;</>) : ('Root')} volume
                  </span>
                </div>
              </div>
              <div className='col-2'>
                {value.id != '1' ? (<>
                  <a
                    onClick={(e) => modifyStorage('delete', value.id)}
                    className="btn  btn-icon btn-color-danger  ms-5"
                  >
                    <i className="la la-trash-o fs-3 me-1" /> Delete
                  </a>
                </>) : ('')}
              </div>
              <div className='col-2'>
                <div className="input-group mt-3">
                  <span className="badge badge-light-primary fw-bolder fs-7 ms-1" >
                    {userData?.currencyprefix} <span id={"ebs-price-" + value.id}>0</span>
                  </span>
                </div>
              </div>
            </div>
          </>))}

          <div className='row'>
            <div className='col-9'></div>
            <div className='col-3'>
              <a
                onClick={(e) => modifyStorage('create', { id: randomid(4), size: 8, type: 'nvme' })}
                className="btn btn-sm btn-light-primary btn-active-primary me-5"
                style={{ marginRight: "0.76rem" }}
              >
                <span className="svg-icon svg-icon-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x="11.364"
                      y="20.364"
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(-90 11.364 20.364)"
                      fill="black"
                    />
                    <rect x="4.36396" y="11.364" width={16} height={2} rx={1} fill="black" />
                  </svg>
                </span>
                Add New Volume
              </a>

            </div>
          </div>
        </div>
        {/*end: Card Body*/}
        {/*end::List widget 10*/}
      </div>
    </>
  )
}

export default Storage

