import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import UseApi from "../../../../../CustomHooks/useApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import TopBarHeaderLine from "../../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../../Loaders/TableLoader";
import { capitalizeFirstLetter } from "../../../utils/utils";
import ManageSubnet from "../subnet/ManageSubnet";
import CreateNatGateway from "./CreateNatGateway";
import UseCallApi from "../../../../../CustomHooks/useCallApi";

export default function NatGateway() {
  const [dataFetched, setDataFetched] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [itemOffset, setItemOffset] = useState(0);
  const [activePage, setActivePage] = useState(0)
  const [natGatewayDataAll, setNatGatewayDataAll] = useState(null);
  const [dummyNatGatewayDataAll, setDummyNatGatewayDataAll] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const dataFetchedRef = useRef(false);


  function ProductInfo({ productdata }) {
    return (
      <>
        {productdata != null && Object.keys(productdata).length != 0 ? (
          productdata &&
          productdata.map((value, index) => (
            <tr>
              {/* <td /> */}
              <td className="text-start ps-9">
                <div className="d-flex flex-column w-100 me-2">
                  <div className="d-flex flex-stack mb-2">
                    <span className=" me-2 fs-7 fw-bold">{value.name}</span>
                  </div>
                </div>
              </td>
              <td className="text-center">
                {/* <div className="d-flex flex-column w-100 me-2">
                  <div className="d-flex flex-stack mb-2"> */}
                <span className="me-2 fs-7 fw-bold">
                  {value.uuid}
                </span>
                {/* </div>
                </div> */}
              </td>
              <td className="text-center">
                {/* <div className="d-flex flex-column w-100 me-2">
                  <div className="d-flex flex-stack mb-2"> */}
                {/* <Link to={"/vpc/subnets/" + value.subnet + ""} > */}
                  <span className="me-2 fs-7 fw-bold">
                    {value.subnet}
                  </span>
                {/* </Link> */}
                {/* </div>
                </div> */}
              </td>
              <td className="text-center d-none">
                {/* <div className="d-flex flex-column w-100 me-2">
                  <div className="d-flex flex-stack mb-2"> */}
                <span className="me-2 fs-7 fw-bold">
                  {value?.vpcid}
                </span>
                {/* </div>
                </div> */}
              </td>
              <td className="text-center">
                {/* <div className="d-flex flex-column w-100 me-2">
                  <div className="d-flex flex-stack mb-2"> */}
                <span className="me-2 fs-7 fw-bold">
                  {value.ip_type}
                </span>
                {/* </div>
                </div> */}
              </td>
              <td className="text-center">
                {/* <div className="d-flex flex-column w-100 me-2">
                  <div className="d-flex flex-stack mb-2"> */}
                {value.status == "active" ?
                  <span className="badge badge-success badge-lg fw-bold">{value.status}</span>
                  :
                  <span className="badge badge-warning badge-lg fw-bold">{value.status}</span>
                }
                {/* </div>
                </div> */}
              </td>
              <td className="text-center">
                {/* <div className="d-flex flex-column w-100 me-2">
                  <div className="d-flex flex-stack mb-2"> */}
                <span className="me-2 fs-7 fw-bold">
                  {value.created_at}
                </span>
                {/* </div>
                </div> */}
              </td>
              <td className="text-end pe-9">
                {" "}
                <div className=" justify-content-end flex-shrink-0">
                  <Link to={"/vpc/natgateways/" + value.id + ""} >
                    <a
                      className="btn btn-primary btn-active-light-primary btn-sm sphide"
                    >
                      {" "}
                      Manage{" "}
                      <span className="svg-icon ms-2 svg-icon-3">
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          {" "}
                          <rect
                            opacity="0.5"
                            x={18}
                            y={13}
                            width={13}
                            height={2}
                            rx={1}
                            transform="rotate(-180 18 13)"
                            fill="black"
                          />{" "}
                          <path
                            d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                            fill="black"
                          />{" "}
                        </svg>{" "}
                      </span>{" "}
                    </a>{" "}
                  </Link>
                </div>
              </td>
            </tr>
          ))
        ) : (
          <>
            <tr>
              <td colspan="12" className="" id="no_record" valign="top">
                <div className="d-flex flex-column flex-center">
                  <div className="fs-5 fw-bolder text-dark mb-4">
                    No items found.
                  </div>
                </div>
              </td>
            </tr>
          </>
        )}
      </>
    );
  }

  const destroyInternetGateway = async (id) => {
    try {
      // Dismiss any previous toasts
      toast.dismiss();

      // Show a loading toast
      const dt = toast.loading("Please wait... System is processing your request.");

      // Find the submit button element
      const submitBtn = document.querySelector(`#destroy-btn_${id}`);

      if (submitBtn) {
        // Save the current button content and disable it while processing
        const preContent = submitBtn.innerHTML;
        submitBtn.classList.add("disabled");
        submitBtn.innerHTML = `Please wait... <span class="indicator-label">
          <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>`;

        // Send a POST request
        const responseData = await UseCallApi("delete", `vpc/natgateway?gateway_id=${id}`, "");

        // Check for errors in the response
        if (responseData.status === "error") {
          toast.update(dt, {
            render: responseData.message,
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        // Handle success
        else if (responseData.status === "success") {
          toast.update(dt, {
            render: responseData.message,
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // navigate("/vpc-internet-gateways")
          // Call the getGateways function if it exists in props
          getGateways();
        }

        // Re-enable the button and restore its content
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
      }
    } catch (error) {
      // Catch any errors and display an error toast
      toast.error("An unexpected error occurred. Please try again.");
      console.error("Error adding VPC route:", error);
    }
  };

  const getGateways = async () => {
    const dt = toast;
    const datas = await UseApi("get", "vpc/natgateway", "");
    if (datas) {
      setDataFetched(true);
    }

    if (datas.status == "error") {
      setNatGatewayDataAll([]);
      setDummyNatGatewayDataAll([])
    } else {
      setNatGatewayDataAll(datas.data);
      setDummyNatGatewayDataAll(datas.data)
    }
  };
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getGateways();
  }, []);

  const handleSearch = (text) => {
    let data = natGatewayDataAll.filter((row) => {
      return (
        (row.name && row.name.toString().toLowerCase().includes(text.toLowerCase())))
    });

    // Update the state with the filtered data
    setDummyNatGatewayDataAll(data)
    setPageCount(Math.ceil(data.length / perPage));
  }


  function PaginatedItems({ itemsPerPage, ProductData }) {
    // Calculate the total number of pages based on itemsPerPage and ProductData
    const pageCount = Math.ceil(ProductData.length / parseInt(itemsPerPage));

    // Calculate the current end offset
    const endOffset = itemOffset + parseInt(itemsPerPage);

    // Handle page click
    const handlePageClick = (event) => {
      const selectedPage = event.selected;
      const newOffset = selectedPage * parseInt(itemsPerPage);
      setActivePage(selectedPage);
      setItemOffset(newOffset);
    };
    return (
      <>
        <ProductInfo productdata={ProductData.slice(parseInt(itemOffset), endOffset)} />
        {ProductData.length > 0 && ProductData.length > 5 ?
          <td colspan="12" className="ps-3">
            <ReactPaginate
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              initialPage={activePage}
              previousLabel="< previous"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </td>
          :
          ''
        }
      </>
    );
  }

  const handleItemsPerPageChange = (newItemsPerPage) => {
    // If the current page goes beyond the total page count, reset to the first page
    const newPageCount = Math.ceil(dummyNatGatewayDataAll.length / parseInt(newItemsPerPage));
    const newActivePage = activePage >= newPageCount ? 0 : activePage;

    // Set the new offset based on the current page and itemsPerPage
    const newOffset = newActivePage * parseInt(newItemsPerPage);

    // Update state
    setItemOffset(newOffset);
    setActivePage(newActivePage);
    setPerPage(newItemsPerPage);  // Update the itemsPerPage state
  };

  if (dataFetched) {
    return (
      <>
        <div className="col-xl-12">
          {/*begin::Tables Widget 9*/}
          <div className="card card-xl-stretch mb-5 mb-xl-12">
            {/*begin::Header*/}
            <div className="card-header border-0 pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1">NAT gateways</span>
              </h3>
              <div className="card-toolbar">
                <div
                  className="position-relative my-1"
                  style={{ marginRight: 20 }}
                >
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    data-kt-table-widget-4="search"
                    id="searchClient"
                    onChange={(e) => handleSearch(e.target.value)}
                    placeholder="Search"
                  />
                </div>
                <div style={{ marginRight: 20 }}>
                  <div className="dataTables_length">
                    <label className="w-125px">
                      <select
                        onChange={(e) => handleItemsPerPageChange(e.target.value)}
                        //data-control="select2"
                        className="form-select form-select-sm form-select-solid"
                        defaultValue={5}
                        id="perPage"
                      >
                        <option value={5} selected="">
                          5 Records
                        </option>
                        <option value={10}>10 Records</option>
                        <option value={25}>25 Records</option>
                        <option value={50}>50 Records</option>
                        <option value={100}>100 Records</option>
                      </select>
                    </label>
                  </div>
                </div>
                <CreateNatGateway getGateways={getGateways} />
              </div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            {/*begin::Table container*/}
            <div className="table-responsive">
              {/*begin::Table*/}
              <table
                id="vpcTable"
                className="table table-striped table-row-gray-300 align-middle gs-0 gy-4"
              >
                {/*begin::Table head*/}
                <thead className="bg-secondary">
                  <tr className="fw-bold ">
                    {/* <th className="w-20px p-3" /> */}
                    <th className="w-100px text-start ps-9">Name</th>
                    <th className="w-250px text-center sphide">InternetGateway_ID</th>
                    <th className="w-250px text-center sphide">Subnet ID</th>
                    <th className="w-250px text-center d-none">VPC</th>
                    <th className="w-100px text-center">Type</th>
                    <th className="w-100px text-center">Status</th>
                    <th className="w-150px text-center">Created_at</th>
                    <th className="w-150px text-center"></th>
                  </tr>
                </thead>
                {/*end::Table head*/}
                {/*begin::Table body*/}
                <tbody id="mainTable">
                  {dummyNatGatewayDataAll != null ? (
                    <>
                      {dummyNatGatewayDataAll.length != 0 ? (
                        <>
                          <PaginatedItems
                            ProductData={dummyNatGatewayDataAll}
                            itemsPerPage={perPage}
                          />
                        </>
                      ) : (
                        <>
                          <tr>
                            <td colspan={12}>
                              <div className="card-body p-0 mt-10">
                                {/*begin::Illustration*/}
                                <div className="text-center px-4 mt-10">
                                  <span className="mw-100 mh-300px">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="234px" height="234px" fill="currentColor" class="bi bi-bezier2" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1 2.5A1.5 1.5 0 0 1 2.5 1h1A1.5 1.5 0 0 1 5 2.5h4.134a1 1 0 1 1 0 1h-2.01q.269.27.484.605C8.246 5.097 8.5 6.459 8.5 8c0 1.993.257 3.092.713 3.7.356.476.895.721 1.787.784A1.5 1.5 0 0 1 12.5 11h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5H6.866a1 1 0 1 1 0-1h1.711a3 3 0 0 1-.165-.2C7.743 11.407 7.5 10.007 7.5 8c0-1.46-.246-2.597-.733-3.355-.39-.605-.952-1-1.767-1.112A1.5 1.5 0 0 1 3.5 5h-1A1.5 1.5 0 0 1 1 3.5zM2.5 2a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm10 10a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z"></path></svg>
                                  </span>
                                </div>
                                {/*end::Illustration*/}
                                {/*begin::Wrapper*/}
                                <div className="card-px text-center py-5 mb-20">
                                  {/*begin::Title*/}
                                  <h2 className="fs-1x fw-bolder mb-5 mt-5 text-muted">
                                    Configure your NAT Gateway in the VPC
                                  </h2>
                                  <p className="text-gray-400 fs-4 fw-bold mb-10">
                                    Manage outbound internet traffic for your private instances with Utho's NAT Gateway. Easily configure your NAT Gateway within the VPC.
                                  </p>
                                  {/*end::Description*/}
                                  {/*begin::Action*/}
                                  <CreateNatGateway getGateways={getGateways} />
                                  {/*end::Action*/}
                                </div>
                                {/*end::Wrapper*/}
                              </div>
                            </td>
                          </tr>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <tr>
                        <td colspan={12}>
                          <TableLoader />
                          <TopBarHeaderLine />
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
                {/*end::Table body*/}
              </table>
              {/*end::Table*/}
            </div>
            {/*end::Table container*/}
            {/*begin::Body*/}
          </div>
          {/*end::Tables Widget 9*/}
        </div>
      </>
    );
  } else {
    return (<>
      <TableLoader />
      <TopBarHeaderLine />
    </>)
  }
}
