import React, { useEffect, useRef, useState } from 'react';  
import { RingProgress, Gauge } from '@ant-design/plots';  
import UseApi from '../../../../../CustomHooks/useApi';
const Dashboard = (props) => {

 
  const [monitoringDatas, setMonitoringDatas]= useState(null);
  const getMonitoringLists=async()=>{   
     if(props){
      if(props.chartInfo){
        //console.log("props");
        //console.log(props.chartInfo);
      setMonitoringDatas(props.chartInfo); 
    } 
  }
  }  
  useEffect(()=>{
    getMonitoringLists();
  },[props])


function sortByDateAscending(array) {
  array.sort((a, b) => new Date(a.Date) - new Date(b.Date));
  return array;
}
function formatFloat(value) {
  const floatValue = parseFloat(value);
  return (floatValue < 1 ? "0." + (floatValue * 10000).toFixed(0) : floatValue.toFixed(2));
}
const ServerLoadProgress = ({ percent }) => {
  var serverLoad;
  if (percent == "0.00") {
    serverLoad = 0.00;
} else {
  serverLoad = (parseFloat(percent) / 120) * 100;
}

  var value=formatFloat(serverLoad); 
  var percentValue=parseFloat(value); 
  const config = {
    percent: percentValue/100,
    range: {
      color: 'l(0) 0:#B8E1FF 1:#3D76DD',
    },
    startAngle: Math.PI,
    endAngle: 2 * Math.PI,
    indicator: null,
    statistic: {
      title: {
        offsetY: -36,
        style: {
          fontSize: '36px',
          color: '#4B535E',
        }, 
      },
      content: {
        style: {
          fontSize: '24px',
          lineHeight: '44px',
          color: '#4B535E',
        }, 
      },
    },
  };
  return <Gauge {...config} />;
};

  const CpuRingProgress = ({ percent }) => {
    
    var value=formatFloat(percent); 
    var percentValue=parseFloat(value);   
    const config = {
      percent: percentValue/100,
      type: 'meter',
      innerRadius: 0.75,
      range: {
        ticks: [0, 1 / 3, 2 / 3, 1],
        color: ['#30BF78', '#FAAD14', '#F4664A'],
      },
      indicator: {
        pointer: {
          style: {
            stroke: '#D0D0D0',
          },
        },
        pin: {
          style: {
            stroke: '#D0D0D0',
          },
        },
      },
      statistic: {
        content: {
          style: {
            fontSize: '36px',
            lineHeight: '36px',
          },
        },
      },
    };
    return <Gauge {...config} />;
  };
  const RamRingProgress = ({ totalRam, usedRam }) => {
    var ramUsedPercentage;
    if (totalRam == "0.00") {
      ramUsedPercentage = 0.00;
  } else {
      ramUsedPercentage = (usedRam / totalRam) * 100;
  }
    
    var value=formatFloat(ramUsedPercentage);
    var percentValue=parseFloat(value);  
    
    const config = {
      percent: percentValue/100,
      range: {
        color: '#3D76DD',
      },
      indicator: {
        pointer: {
          style: {
            stroke: '#D0D0D0',
          },
        },
        pin: {
          style: {
            stroke: '#D0D0D0',
          },
        },
      },
      axis: {
        label: {
          formatter(v) {
            return Number(v) * 100;
          },
        },
        subTickLine: {
          count: 3,
        },
      },
      statistic: {
        content: {
          formatter: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
          style: {
            color: 'rgba(0,0,0,0.65)',
            fontSize: 48,
          },
        },
      },
    };
    return <Gauge {...config} />;
  };
  const DiskRingProgress = ({ percent }) => {
    var value=formatFloat(percent); 
    var percentValue=parseFloat(value);
    const config = {
      percent: percentValue/100,
      range: {
        color: '#30BF78',
      },
      indicator: {
        pointer: {
          style: {
            stroke: '#D0D0D0',
          },
        },
        pin: {
          style: {
            stroke: '#D0D0D0',
          },
        },
      },
      axis: {
        label: {
          formatter(v) {
            return Number(v) * 100;
          },
        },
        subTickLine: {
          count: 3,
        },
      },
      statistic: {
        content: {
          formatter: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
          style: {
            color: 'rgba(0,0,0,0.65)',
            fontSize: 48,
          },
        },
      },
    };
    return <Gauge {...config} />;
  }; 
    return (
        <>
        {monitoringDatas!=null?(<>
        <div className="card">
           {/*begin::Card header*/}
      <div
        className="card-header border-0 cursor-pointer" 
      >
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">Dashboard</h3>
        </div>
      </div>
      {/*end::Card header*/}
      <div className="row g-5 g-xl-8 container">
          <div className="col-xl-6">
            {/*begin::Charts Widget 2*/}
            <div className=" mb-5 mb-xl-8 border shadow">
              {/*begin::Header*/}
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bolder fs-3 mb-1">
                    CPU Utilization
                  </span>
                  <span className="text-muted fw-bold fs-7">
                    Monitor your CPU utilization here
                  </span>
                </h3>  
                </div>
 {/*begin::Body*/}
 <div className="card-body" id="chart-cpu-id">
 {monitoringDatas.quickstats?(<> 
    {monitoringDatas.quickstats.cpuused!=null?(<>
      <CpuRingProgress percent={monitoringDatas.quickstats.cpuused}/>
      </>):(<>
      <CpuRingProgress percent="0.00"/>
    </>)} 
    </>):('')}
              </div>
              {/*end::Body*/}
              </div>
              {/*end::Header*/}
             
            </div>
            <div className="col-xl-6">
            {/*begin::Charts Widget 2*/}
            <div className="border shadow mb-5 mb-xl-8">
              {/*begin::Header*/}
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bolder fs-3 mb-1">
                    Disk Utilization
                  </span>
                  <span className="text-muted fw-bold fs-7">
                    Monitor your disk utilization here
                  </span>
                </h3>  
                </div>
 {/*begin::Body*/}
 <div className="card-body" id="chart-cpu-id">
 {monitoringDatas.quickstats?(<> 
    {monitoringDatas.quickstats.totaldiskusedp?(<>
      <DiskRingProgress percent={monitoringDatas.quickstats.totaldiskusedp}/>
      </>):(<>
      <DiskRingProgress percent="0.00"/>
    </>)} 
    </>):('')}
              </div>
              {/*end::Body*/}
              </div>
              {/*end::Header*/}
             
            </div>
            <div className="col-xl-6">
            {/*begin::Charts Widget 2*/}
            <div className="border shadow mb-5 mb-xl-8">
              {/*begin::Header*/}
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bolder fs-3 mb-1">
                    RAM Utilization
                  </span>
                  <span className="text-muted fw-bold fs-7">
                    Monitor your RAM utilization here
                  </span>
                </h3>  
                </div>
 {/*begin::Body*/}
 <div className="card-body" id="chart-cpu-id">
 {monitoringDatas.quickstats?(<> 
    {monitoringDatas.quickstats.ramused?(<>
      <RamRingProgress totalRam={monitoringDatas.quickstats.ramtotal} usedRam={monitoringDatas.quickstats.ramused}/>
      </>):(<>
      <RamRingProgress totalRam="0.00" usedRam="0.00" />
    </>)} 
    </>):('')}
              </div>
              {/*end::Body*/}
              </div>
              {/*end::Header*/}
             
            </div>
            <div className="col-xl-6">
            {/*begin::Charts Widget 2*/}
            <div className="border shadow mb-5 mb-xl-8">
              {/*begin::Header*/}
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bolder fs-3 mb-1">
                  Load Averages
                  </span>
                  <span className="text-muted fw-bold fs-7">
                    Monitor your load averageshere
                  </span>
                </h3>  
                </div>
 {/*begin::Body*/}
 <div className="card-body" id="chart-cpu-id">
 {monitoringDatas.quickstats?(<>
      {monitoringDatas.quickstats.load1==""?(<><ServerLoadProgress percent="0.00" /></>):(<><ServerLoadProgress percent={monitoringDatas.quickstats.load1} /></>)}
      </>):('')}
              </div>
              {/*end::Body*/}
              </div>
              {/*end::Header*/}
             
            </div> 
            {/*end::Charts Widget 2*/}
          </div>
          {/*end::Row*/}
        </div> 
        </>):('')}
        </>
    );
}

export default Dashboard;
