import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom"; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate'; 
import UseApi from "../../../../../CustomHooks/useApi";
import TableLoader from "../../../Loaders/TableLoader";
import UsePostData from "../../../../../CustomHooks/usePostData";

export default function FirewallKubernetes(props){
  var query = window.location.href; 
  var vars = query.split("/"); 
  var id=vars[4]; 
  const [dataFetched, setDataFetched] = useState(false);
  const [access, setAccess]=useState(true); 
  const [perPage, setPerPage]=useState(5);
  const [firewallData,setFirewallData]=useState(null);
  const [fwData,setFwData]=useState(null);
  const dataFetchedRef=useRef (false);
  const loaderFunc=()=>{
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if(loader && no_record){
    no_record.classList.add("d-none");
    loader.classList.remove("d-none");

    setTimeout(() => {
      loader.classList.add("d-none");
      no_record.classList.remove("d-none");
    }, 100);
  }
  }
  const getFirewall=async()=>{ 
    const dt=toast 
       const data=await UseApi('get','autoscaling/'+id+'/securitygroup','');
       if(data){   
        console.log(data,"kjxizjxiz");
        if(data.status === 'error'){
          setFirewallData([]);
          toast.update(dt,{render: data.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
         }); 
        }else{
       if(data.security_groups && data.security_groups.length == 0){
         setFirewallData([]);
             toast.update(dt,{render: "no record found", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });  
       }else{
        setFirewallData(data.security_groups);  
        toast.dismiss(); 
       }   
       }
      }
  }

  const getFw=async()=>{ 
    const dt=toast 
       const data=await UseApi('get','firewall','');
       if(data){ 
       if('status' in data){
        if(data.status=="error"){ 
           toast.update(dt,{render: data.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });   
          setFirewallData([]);  
          return;
        }
     }
       if(Object.keys(data).length === 0){
        setFirewallData(null);
             toast.update(dt,{render: "no record found", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            }); 
            // loaderFunc();
       }else{
        console.log(data.firewalls,props);
        const fw_Data = data.firewalls.filter(obj2 => !props.firewallData.some(obj1 => obj1.id === obj2.id));
        // var fw_Data=data.firewalls.filter((item)=>{
        //   if(props.firewallData){
        //   return !props.firewallData.some(id => id === item.id);
        //   }else{
        //     return item.id;
        //   }
        // })
        console.log(fw_Data);
        setFwData(fw_Data);  
        toast.dismiss();  
       }
      }
  }
  useEffect(()=>{
    if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
      toast.dismiss();
      getFirewall(); 
  },[]);
  
  useEffect(()=>{
      getFw();
  },[firewallData])


  const openDrawer = () => {
 
    var drawerElement = document.querySelector("#fw-modal");
    var drawer_overlay = document.querySelector("#drawer_overlay_firewall");
    if(drawerElement && drawer_overlay){ 
    drawerElement.classList.toggle("drawer-on");
    if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
      drawerElement.style.width = "100%";
    } else {
      drawerElement.style.width = "40%";
    }
    drawer_overlay.classList.toggle("d-none");
    }
   };
   function checkObjectForEmpty(obj) {
    for (const key in obj) {
      const value = obj[key];
  
      if (typeof value === 'string' && value.trim() === '') {
        return `Error: '${key}' should not be empty or blank.`;
      }
  
      if (typeof value === 'object') {
        const nestedError = checkObjectForEmpty(value);
        if (nestedError) {
          return nestedError;
        }
      }
    }
  
    return null; // No empty or blank elements found
  }
   const attachFw = async () => { 
    var add_firewall = document.querySelector("#add_fw_id"); 
    const submitBtn = document.querySelector("#add-fw-btn");

    if(add_firewall && submitBtn){
    toast.dismiss();
     const dt = toast.loading("Please wait..."); 
    var updateData={"security_groups":add_firewall.value};
    const validationError = checkObjectForEmpty(updateData);
  
  if (validationError) {
    toast.update(dt, {
      render: validationError,
      type: "error",
      isLoading: false,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });  
    return;
  }
    if (submitBtn) { 
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = 'Please wait... <span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
        const responseData = await UsePostData("post", "autoscaling/"+id+"/securitygroup/"+add_firewall.value, {});
  
      ////console.log(responseData);
      if (responseData.status === "error") { 
        toast.update(dt, {
          render: responseData.message,
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });  
      } else if (responseData.status === "success") { 
        toast.update(dt, {
          render: responseData.message,
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });    
        openDrawer(); 
        getFirewall();          
      }
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML = preContent;
    }  
  }
  };
console.log(fwData);
  const deleteFw = async (fwid) => {  
    toast.dismiss();
     const dt = toast.loading("Please wait...");
    const submitBtn = document.querySelector("#delete-fw-btn-"+fwid);
     if (submitBtn) { 
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML =
        '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
     
        const responseData = await UseApi("delete", "kubernetes/"+id+"/securitygroup/"+fwid, "");
  
      ////console.log(responseData);
      if (responseData.status === "error") { 
        toast.update(dt, {
          render: responseData.message,
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });  
      } else if (responseData.status === "success") { 
        toast.update(dt, {
          render: responseData.message,
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });      
        getFirewall();          
      }
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML = preContent;
    }  
  };
  function ProductInfo({ productdata }) { 
     return(
        <>
        
        {productdata!=null && Object.keys(productdata).length!=0?(productdata && productdata.map((value, index) => (
          <tr>
            <td className="text-center">
              {" "}
              <div className="d-flex justify-content-start flex-column">
                {" "}
                <span className=" me-2 fs-7 fw-bold">
                  {value.id}
                </span>{" "}
              </div>
            </td>
            <td className="p-3 text-center">
              {" "}
              <div className="d-flex justify-content-center flex-column">
                <a className="text-dark  fw-bold text-hover-primary fs-6">
                  {value.name}
                </a> 
              </div>{" "}
            </td>{" "} 
            <td className="text-center">
              {" "}
              <div className="d-flex justify-content-center flex-shrink-0">
                {" "} 
                <a  
                  id={"delete-fw-btn-"+value.id}
                  onClick={(e)=>deleteFw(value.id)}
                  className="btn  btn-danger btn-active-light-danger btn-sm me-1 me-5 "
                >
                  {" "}
                  Detached
                </a>{" "} 
              </div>{" "}
            </td>
          </tr>
          ))):( <>
           <tr>
                     <td colspan="12" className="dataTables_empty" id="loader">
                      {/* <div className="loading-spinner"></div> */}
                      <TableLoader />
                    </td>
                    <td colspan="12" className="d-none" id="no_record" valign="top">
                      <div className="d-flex flex-column flex-center"> 
                        <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                      </div>
                    </td>
                    </tr>
            </>)}
            
        </>
    )
}


const [searchTerm,setSearchTerm]=useState('');
function PaginatedItems({ itemsPerPage, ProductData, search }) {
  // We start with an empty list of items. 
  const [pageCount, setPageCount] = useState(0);
  const [vpcData,setVpcData]=useState(null);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    
    const endOffset = itemOffset + itemsPerPage;


  //  //////console.log(`Loading items from ${itemOffset} to ${endOffset}`);
     if(ProductData!=null){
    //   var str="Noida";
   // //////console.log(ProductData);
    const data= ProductData.filter(row => {
     return  Object.values(row).some(value => { 
        if(value!=null || value!=undefined){
          if(value.toString().toLowerCase().includes(search.toLowerCase())){
            return value;
          } 
        }
      });
    });
    ////////console.log(data);
      // const data=ProductData.filter(item => 
      //        item.hostname.toLowerCase().includes(search.toLowerCase())
      //              ); 

                   if(data.length<=0){
                    loaderFunc();
                   }
    setVpcData(data.slice(itemOffset, endOffset));
    //setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / itemsPerPage));
    // alert(pageCount);
      }
  }, [itemOffset, itemsPerPage]);

  // Invoke when user click to request another page.
  
  const handlePageClick = (event) => { 
    const newOffset = event.selected * itemsPerPage % ProductData.length;
    //////console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setItemOffset(newOffset);
   
  };
  var searchEle=document.querySelector("#searchClient");
  if(searchEle){
    searchEle.focus();
  }
  return (
    <>
      <ProductInfo productdata={vpcData} />
      <td colspan="12">
      <ReactPaginate
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="< previous"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
      </td>
    </>
  );
} 
return(
  <> 
  <div className="col-xl-12">
  {/*begin::Tables Widget 9*/}
  <div className="card card-xl-stretch mb-5 mb-xl-12">
    {/*begin::Header*/}
    <div className="card-header border-0 pt-5">
      <h3 className="card-title align-items-start flex-column">
        <span className="card-label  fw-bold fs-3 mb-1">
          {" "}
          Firewall Management{" "}
          {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
          <span className="svg-icon svg-icon-2">
            <i className="bi bi-diagram-3 " style={{ fontSize: 20 }} />
          </span>
          {/*end::Svg Icon*/}
        </span>
      </h3>
      <div
        className="card-toolbar" 
      >
        <div className="position-relative my-1" style={{ marginRight: 20 }}>
          <input
            type="text"
            className="form-control form-control-sm"
            data-kt-table-widget-4="search"
            id="searchClient"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            placeholder="Search"
          />
        </div>
        <div style={{ marginRight: 20 }}>
  <div className="dataTables_length">
    <label className="w-125px">
      <select  
        onChange={e => setPerPage(e.target.value)}
        //data-control="select2"
        className="form-select form-select-sm form-select-solid"
        defaultValue={5}
        id="perPage"
        
      >
        <option value={5} selected="">
          5 Records
        </option>
        <option value={10}>10 Records</option>
        <option value={25}>25 Records</option>
        <option value={50}>50 Records</option>
        <option value={100}>100 Records</option>
      </select>
    </label>
  </div>
</div> 
        <a 
           onClick={openDrawer}
          className="btn btn-sm btn-active-light-primary btn-primary"
        >
          {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
          <span className="svg-icon svg-icon-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x="11.364"
                y="20.364"
                width={16}
                height={2}
                rx={1}
                transform="rotate(-90 11.364 20.364)"
                fill="black"
              />
              <rect
                x="4.36396"
                y="11.364"
                width={16}
                height={2}
                rx={1}
                fill="black"
              />
            </svg>
          </span>
          {/*end::Svg Icon*/}Add Firewall
        </a> 
      </div>
    </div>
    {/*end::Header*/}
    {/*begin::Body*/}
    {/*begin::Table container*/}
    <div className="table-responsive">
      {/*begin::Table*/}
      <table
        id="loadbalancer"
        className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
      >
        {/*begin::Table head*/}
        <thead className="bg-secondary">
          <tr className=" fw-bold ">
            <th className="p-3 text-center">#ID</th>
            <th className="p-3 text-center">Name</th> 
            <th className="text-center">Action</th>
          </tr>
        </thead>
        {/*end::Table head*/}
        {/*begin::Table body*/}
        <tbody> 
        {firewallData!=null?(<> {Object.keys(props.firewallData).length!=0?(<>
        <PaginatedItems itemsPerPage={perPage}  ProductData={props.firewallData} search={searchTerm}  />
        </>):(<>
          <tr>
            <td colSpan={12} className="text-center">
               <span className="fs-6 text-gray-800 fw-bold">no record found</span>
            </td>
          </tr>
          </>)} 
        </>):(<>
          <tr>
            <td colspan={12}>
              <TableLoader />
             </td>
          </tr>
          
        </>)} 
  
  </tbody>
        {/*end::Table body*/}
      </table>
      {/*end::Table*/}
    </div>
    {/*end::Table container*/}
    {/*begin::Body*/}
  </div>
  {/*end::Tables Widget 9*/}
</div>

<div id="drawer_overlay_firewall" onClick={openDrawer} className="drawer-overlay d-none" style={{zIndex: 109, width:"100%"}}></div>   

<div
  id="fw-modal"
  className="bg-body shadow drawer drawer-end"
  // data-kt-drawer="true"
  // data-kt-drawer-name="chat"
  // data-kt-drawer-activate="true"
  // data-kt-drawer-direction="end"
  data-kt-drawer-close="#close_ssh"
  // data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
  style={{ important: true, zIndex:110 }}
>
                      {/*begin::Messenger*/}
                      <div
                        className="card rounded-0 w-100 border-0"
                        id="kt_drawer_chat_messenger"
                      >
                        {/*begin::Card header*/}
                        <div
                          className="card-header pe-5"
                          id="kt_drawer_chat_messenger_header"
                        >
                          {/*begin::Title*/}
                          <div className="card-title">
                            {/*begin::User*/}
                            <div className="d-flex justify-content-center flex-column me-3">
                              <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                                Add New Firewall
                              </a>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Title*/}
                          {/*begin::Card toolbar*/}
                          <div className="card-toolbar">
                            {/*begin::Close*/}
                            <div
                              onClick={openDrawer}
                              className="btn btn-sm btn-icon btn-active-light-primary"
                              id="close_ssh"
                            >
                              {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                              <span className="svg-icon svg-icon-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <rect
                                    opacity="0.5"
                                    x={6}
                                    y="17.3137"
                                    width={16}
                                    height={2}
                                    rx={1}
                                    transform="rotate(-45 6 17.3137)"
                                    fill="black"
                                  />
                                  <rect
                                    x="7.41422"
                                    y={6}
                                    width={16}
                                    height={2}
                                    rx={1}
                                    transform="rotate(45 7.41422 6)"
                                    fill="black"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}
                            </div>
                            {/*end::Close*/}
                          </div>
                          {/*end::Card toolbar*/}
                        </div>
                        {/*end::Card header*/}
                        {/*begin::Card body*/}
                        <div className="" id="">
                          {/*begin::Form*/}
                          <form
                            name="importkey"
                            id="importkey"
                            className="form"
                          >
                            {/*begin::Modal body*/}
                            <div className="modal-body ">
                              {/*begin::Scroll*/}
                              <div
                                className="scroll-y me-n7 pe-7"
                                id="kt_modal_create_api_key_scroll"
                                data-kt-scroll="true"
                                data-kt-scroll-activate="{default: false, lg: true}"
                                data-kt-scroll-max-height="auto"
                                data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                                data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                                data-kt-scroll-offset="300px"
                              >
                                {/*begin::Input group*/}
                                <div className="mb-5 fv-row">
                                  {/*begin::Label*/}
                                  <label className="required fs-5 fw-bold mb-2">
                                    {" "}
                                    Firewall
                                  </label>
                                  {/*end::Label*/}
                                  {/*begin::Input*/}
                                  <select className="form-select" id="add_fw_id">
                                    {fwData!=null && fwData.length > 0?(<>
                                    {fwData.map((value, index)=>(<>
                                    <option value={value.id}> {value.name}</option>
                                    </>))}
                                    </>):<option value="">No firewall</option>} 
                                  </select>
                                  {/*end::Input*/}
                                </div>
                                {/*end::Input group*/}
                                
                              </div>
                              {/*end::Scroll*/}
                            </div>
                            {/*end::Modal body*/}
                            {/*begin::Modal footer*/}
                            <div className="modal-footer ">
                              {/*begin::Button*/}
                              <a 
                                onClick={attachFw}
                                className="btn btn-primary w-100"
                                id="add-fw-btn"
                              >
                                <span className="indicator-label">
                                  Add Firewall
                                </span>
                              </a>
                              {/*end::Button*/}
                            </div>
                            {/*end::Modal footer*/}
                          </form>
                          {/*end::Form*/}
                        </div> 
                        {/*end::Card body*/}
                      </div>
                      {/*end::Messenger*/}
</div>
  </>
)
} 