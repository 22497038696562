import React, { useEffect } from "react";
import PropTypes from "prop-types";

function Stacks(props) {
  const {
    stackPrivateData,
    stackPublicData,
    currMarketplaceDistroPrivate,
    currMarketplaceDistroPublic,
    currMarketplacePrivate,
    currMarketplacePublic,
    selectOne,
    selectMarketPlace,
    selectStackImage,
  } = props;

  useEffect(() => {
    if (stackPrivateData === undefined)
      throw new Error("Missing prop: stackPrivateData");
    if (stackPublicData === undefined)
      throw new Error("Missing prop: stackPublicData");
    // if (currMarketplaceDistroPrivate==undefined || currMarketplaceDistroPrivate==null) throw new Error("Missing prop: currMarketplaceDistroPrivate");
    // if (currMarketplaceDistroPublic==undefined || currMarketplaceDistroPublic==null) throw new Error("Missing prop: currMarketplaceDistroPublic");
    // if (currMarketplacePrivate==undefined || currMarketplacePrivate==null) throw new Error("Missing prop: currMarketplacePrivate");
    // if (currMarketplacePublic==undefined || currMarketplacePublic==null) throw new Error("Missing prop: currMarketplacePublic");
    if (selectOne === undefined)
      throw new Error("Missing prop: selectOne");
    if (selectMarketPlace === undefined)
      throw new Error("Missing prop: selectMarketPlace");
    if (selectStackImage === undefined)
      throw new Error("Missing prop: selectStackImage");
  }, [
    stackPrivateData,
    stackPublicData,
    currMarketplaceDistroPrivate,
    currMarketplaceDistroPublic,
    currMarketplacePrivate,
    currMarketplacePublic,
    selectOne,
    selectMarketPlace,
    selectStackImage,
  ]);

  return (
    <>
      <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-trakt_charts_widget_1_chartnsparent fs-5 fw-bolder">
        <li className="nav-item">
          <a
            className="nav-link text-active-primary py-5 me-6 fs-6 active"
            data-bs-toggle="tab"
            href="#private-stack-tabs"
            id="private_tab"
          >
            Own Stacks
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link text-active-primary py-5 fs-6 me-6"
            data-bs-toggle="tab"
            href="#public-stack-tabs"
            id="public_tab"
          >
            Community Stacks
          </a>
        </li>
      </ul>
      <div className="tab-content mt-5 pe-8">
        <div className="tab-pane fade show active" id="private-stack-tabs">
          {/*begin::Row*/}
          <div
            id="stack-private-data"
            className="row g-9"
            data-kt-buttons="true"
            data-kt-buttons-target="[data-kt-button]"
          >
            {/*begin::Col*/}
            {/* {stackPrivateLoader == false ? (
                                  <> */}
            {stackPrivateData != null && stackPrivateData.length != 0 ? (
              <>
                {stackPrivateData.map((value, index) => (
                  <>
                    <div className="col-md-4 col-lg-12 col-xxl-4">
                      {/*begin::Option*/}
                      <input
                        className="btn-check deploy_checkbox marketplace_radio"
                        type="radio"
                        name="image_OS"
                        defaultValue={value.id}
                        id={"marketplace" + value.id}
                      />
                      <label
                        onClick={(e) => {
                          e.stopPropagation();
                          selectOne("marketplace", value.id);
                          selectMarketPlace("private", value.id);
                        }}
                        id={"private_stack_" + value.id}
                        htmlFor={"marketplace" + value.id}
                        className=" label versionOs text-start border-1 border-gray-300 border px-6 py-2 d-flex"
                      >
                        {/*begin::Svg Icon | path: icons/duotune/communication/com003.svg*/}
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-50px">
                          <img
                            src={"/assets/marketplace/private.svg"}
                            className="pb-2"
                            alt=""
                          />
                        </div>
                        {/*end::Svg Icon*/}
                        <span className="d-block  text-start p-3 sphide w-100">
                          <span className="text-dark d-block fs-4 sphide2">
                            {value.title}
                          </span>
                        </span>
                        {/*begin::Ribbon*/}
                        <div
                          className="d-none tickMarkmarketplace ribbon ribbon-triangle ribbon-top-start border-primary"
                          id={"tickMarkmarketplace-" + value.id}
                        >
                          {/*begin::Ribbon icon*/}
                          <div className="ribbon-icon mt-n5">
                            <i className="bi bi-check2 fs-2 text-white" />
                          </div>
                          {/*end::Ribbon icon*/}
                        </div>
                        {/*end::Ribbon*/}
                      </label>
                      {/*end::Option*/}
                    </div>
                  </>
                ))}
              </>
            ) : (
              <>
                <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                  {/*begin::Wrapper*/}

                  <div className="pt-lg-10 mb-10">
                    {/*begin::Message*/}
                    <div className="fw-bold fs-5  mb-15 badge badge-light-primary">
                      No Stack Available
                    </div>
                    {/*end::Message*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
              </>
            )}
            {/* </>
                                ) : (
                                  <>
                                    <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                                       <TableLoader />
                                    </div>
                                  </>
                                )} */}

            {/*end::Col*/}
          </div>
          {/*end::Row*/}
          {/*begin:: marketplace fields*/}
          <div className="col-xl-12 mb-xl-12 mt-10" id="market_fields_private">
            {/* {currMarketplaceLoaderPrivate == false ? (
                                  <> */}
            {currMarketplaceDistroPrivate != null ? (
              <>
                {currMarketplaceDistroPrivate.length != 0 ? (
                  <>
                    {currMarketplaceDistroPrivate.length == 1 ? (
                      <>
                        {currMarketplaceDistroPrivate.map(
                          (valueData, index) => (
                            <>
                              <input
                                type="hidden"
                                onChange={(e) =>
                                  selectStackImage(e.target.value)
                                }
                                className="image_field"
                                defaultValue={valueData}
                              />
                            </>
                          )
                        )}
                      </>
                    ) : (
                      <>
                        <div className="fv-row mb-10 fv-plugins-icon-container">
                          <label className="form-label ">Select OS</label>
                          <select
                            className="form-select image_field"
                            onChange={(e) => selectStackImage(e.target.value)}
                          >
                            {currMarketplaceDistroPrivate.map(
                              (value, index) => (
                                <>
                                  <option value={value}>{value}</option>
                                </>
                              )
                            )}
                          </select>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
            {currMarketplacePrivate != null ? (
              <>
                {Object.keys(currMarketplacePrivate).length != 0 ? (
                  <>
                    {currMarketplacePrivate.map((value, index) => (
                      <>
                        <div className="fv-row mb-10 fv-plugins-icon-container">
                          <label className="form-label ">{value.label}</label>
                          <input
                            type="text"
                            name={
                              "stack_fields[" + index + "][" + value.name + "]"
                            }
                            defaultValue=""
                            placeholder={value.example}
                            className="form-control form-control-lg"
                          />
                        </div>
                      </>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
            {/* </>
                                ) : (
                                  <>
                                    <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                                       <TableLoader />
                                    </div>
                                  </>
                                )} */}
          </div>
        </div>
        <div className="tab-pane fade" id="public-stack-tabs">
          {/*begin::Row*/}
          <div
            id="stack-data"
            className="row g-9"
            data-kt-buttons="true"
            data-kt-buttons-target="[data-kt-button]"
          >
            {/*begin::Col*/}
            {/* {stackPublicLoader == false ? (
                                  <> */}
            {stackPublicData != null && stackPublicData.length != 0 ? (
              <>
                {stackPublicData.map((value, index) => (
                  <>
                    <div className="col-md-4 col-lg-12 col-xxl-4">
                      {/*begin::Option*/}
                      <input
                        className="btn-check deploy_checkbox marketplace_radio"
                        type="radio"
                        name="image_OS"
                        defaultValue={value.id}
                        id={"marketplace" + value.id}
                      />
                      <label
                        onClick={(e) => {
                          e.stopPropagation();
                          selectOne("marketplace", value.id);
                          selectMarketPlace("public", value.id);
                        }}
                        id={"public_stack_" + value.id}
                        htmlFor={"marketplace" + value.id}
                        className=" label versionOs text-start border-1 border-gray-300 border px-6 py-2 d-flex"
                      >
                        {/*begin::Svg Icon | path: icons/duotune/communication/com003.svg*/}
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-50px">
                          <img
                            src={"/assets/marketplace/public.svg"}
                            className="pb-2"
                            alt=""
                          />
                        </div>
                        {/*end::Svg Icon*/}
                        <span className="d-block  text-start p-3 sphide w-100">
                          <span className="text-dark d-block fs-4 sphide2">
                            {value.title}
                          </span>
                        </span>
                        {/*begin::Ribbon*/}
                        <div
                          className="d-none tickMarkmarketplace ribbon ribbon-triangle ribbon-top-start border-primary"
                          id={"tickMarkmarketplace-" + value.id}
                        >
                          {/*begin::Ribbon icon*/}
                          <div className="ribbon-icon mt-n5">
                            <i className="bi bi-check2 fs-2 text-white" />
                          </div>
                          {/*end::Ribbon icon*/}
                        </div>
                        {/*end::Ribbon*/}
                      </label>
                      {/*end::Option*/}
                    </div>
                  </>
                ))}
              </>
            ) : (
              <>
                <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                  {/*begin::Wrapper*/}

                  <div className="pt-lg-10 mb-10">
                    {/*begin::Message*/}
                    <div className="fw-bold fs-5  mb-15 badge badge-light-primary">
                      No Stack Available
                    </div>
                    {/*end::Message*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
              </>
            )}
            {/* </>
                                ) : (
                                  <>
                                    <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                                       <TableLoader />
                                    </div>
                                  </>
                                )} */}

            {/*end::Col*/}
          </div>
          {/*end::Row*/}
          {/*begin:: marketplace fields*/}
          <div className="col-xl-12 mb-xl-12 mt-10" id="market_fields_public">
            {/* {currMarketplaceLoaderPublic == false ? (
                                  <> */}
            {currMarketplaceDistroPublic != null ? (
              <>
                {currMarketplaceDistroPublic.length != 0 ? (
                  <>
                    {currMarketplaceDistroPublic.length == 1 ? (
                      <>
                        {currMarketplaceDistroPublic.map((valueData, index) => (
                          <>
                            <input
                              type="hidden"
                              onChange={(e) => selectStackImage(e.target.value)}
                              className="image_field"
                              defaultValue={valueData}
                            />
                          </>
                        ))}
                      </>
                    ) : (
                      <>
                        <div className="fv-row mb-10 fv-plugins-icon-container">
                          <label className="form-label ">Select OS</label>
                          <select
                            className="form-select image_field"
                            onChange={(e) => selectStackImage(e.target.value)}
                          >
                            {currMarketplaceDistroPublic.map((value, index) => (
                              <>
                                <option value={value}>{value}</option>
                              </>
                            ))}
                          </select>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
            {currMarketplacePublic != null ? (
              <>
                {Object.keys(currMarketplacePublic).length != 0 ? (
                  <>
                    {currMarketplacePublic.map((value, index) => (
                      <>
                        <div className="fv-row mb-10 fv-plugins-icon-container">
                          <label className="form-label ">{value.label}</label>
                          <input
                            type="text"
                            name={
                              "stack_fields[" + index + "][" + value.name + "]"
                            }
                            defaultValue=""
                            placeholder={value.example}
                            className="form-control form-control-lg"
                          />
                        </div>
                      </>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
            {/* </>
                                ) : (
                                  <>
                                    <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                                       <TableLoader />
                                    </div>
                                  </>
                                )} */}
          </div>
        </div>
      </div>
    </>
  );
}

Stacks.propTypes = {
  stackPrivateData: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.oneOf([null]),
  ]).isRequired,
  stackPublicData: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.oneOf([null]),
  ]).isRequired,
  currMarketplaceDistroPrivate: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.oneOf([null]),
  ]).isRequired,
  currMarketplaceDistroPublic: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.oneOf([null]),
  ]).isRequired,
  currMarketplacePrivate: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.oneOf([null]),
  ]).isRequired,
  currMarketplacePublic: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.oneOf([null]),
  ]).isRequired,
  selectOne: PropTypes.func.isRequired,
  selectMarketPlace: PropTypes.func.isRequired,
  selectStackImage: PropTypes.func.isRequired,
};
export default Stacks;
