import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthSocial from '../../App/Auth/AuthSocial';
import Login from '../../App/Auth/Login';
import ActivateUser from '../../App/MicroApp/Body/Products/subuser/ActivateUser';
import Signup from '../../App/Auth/Signup';
import ForgotPassword from '../../App/Auth/ForgotPassword';
import NewPassword from '../../App/Auth/NewPassword';
import LoginWithOTP from '../../App/Auth/LoginWithOTP';
import LoginOtp from '../../App/Auth/LoginOtp';
import PlatformChange from '../../App/PlatformConfig/PlatformChange';

export default function PublicRoutes(){
     return (
        <> 
         <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Routes>
            <Route path="/" element={<AuthSocial />} />
            <Route path="/login" element={<AuthSocial />} />
            <Route path="/partner-login" element={<Login />} />
            <Route path="/activateuser/:id" element={<ActivateUser/>} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/partner-signup" element={<Signup />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/new-password" element={<NewPassword />} />
            <Route path="/login-with-otp" element={<LoginWithOTP />} />
            <Route path="/verify-otp" element={<LoginOtp />} />
            <Route path="/change-platform/:pType" element={<PlatformChange />} />
            <Route path="*" element={<AuthSocial />} />
          </Routes>
        </>
      );
}