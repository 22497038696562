import React from 'react'
import "./custom.css"
function Tooltip(props) {

   return (
    <>
      <span class="tooltip__1">
            <span class="tooltip fs-6 fw-bold">{props?.message ?? ""}</span>
            <span class="text">
            <span className="svg-icon svg-icon-muted svg-icon-2">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect
        opacity="0.3"
        x={2}
        y={2}
        width={20}
        height={20}
        rx={10}
        fill="black"
      />
      <rect
        x={11}
        y={17}
        width={7}
        height={2}
        rx={1}
        transform="rotate(-90 11 17)"
        fill="black"
      />
      <rect
        x={11}
        y={9}
        width={2}
        height={2}
        rx={1}
        transform="rotate(-90 11 9)"
        fill="black"
      />
    </svg>
  </span>
            </span>
         </span> 
    </>
  )
}
 

export default Tooltip

