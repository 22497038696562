import PermissionInfoModal from "../../tabs/permissioInfoModal/PermissionInfoModal"

const UpdateUser = (props) => {

    const handleChangeOption = (e) => {
        let passwordBox = document.querySelector('#passwordBox')
        let cpasswordBox = document.querySelector('#cpasswordBox')
        let text = document.querySelector('#text')
        if (passwordBox && text && cpasswordBox) {
            if (e.target.value == "password") {
                passwordBox.classList.remove('d-none')
                cpasswordBox.classList.remove('d-none')
                text.classList.add('d-none')
            } else {
                passwordBox.classList.add('d-none')
                cpasswordBox.classList.add('d-none')
                text.classList.remove('d-none')
            }
        }
    }

    const openModal = (class2) => {
        let addbackend_modal = document.querySelector('#permissionInfo-modal')
        if (addbackend_modal) {
            addbackend_modal.classList.toggle('show')
            addbackend_modal.classList.toggle('d-block')
        }
    }
    return (
        <>
            <div className="card mt-5">
                <div
                    className="card-header border-0 cursor-pointer"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_account_deactivate"
                    aria-expanded="true"
                    aria-controls="kt_account_deactivate"
                >
                    <div className="card-title m-0">
                        <h3 className="fw-bold m-0">Update User</h3>
                    </div>
                </div>
                <div id="kt_account_settings_deactivate" className="collapse show">
                    <form id="destroyform" className="form">
                        <div className="card-body border-top p-9">
                            <div className="row">
                                <div className="col-4">
                                    <div className="mt-3 fv-row">
                                        <label className=" fs-5 fw-bold mb-2">
                                            {/* Confrim Password */}
                                        </label>
                                        <select className="form-select" onChange={handleChangeOption}>
                                            <option value="password">Password</option>
                                            <option value="no_password">No Password</option>
                                        </select>
                                    </div>

                                </div>
                                <div className="col-4" id="passwordBox">
                                    <div className="mb-5 fv-row">
                                        <label className="required fs-5 fw-bold mb-2">
                                            Password
                                        </label>
                                        <input className="form-control" type="text" name="password" />
                                    </div>

                                </div>
                                <div className="col-4" id="cpasswordBox">
                                    <div className="mb-5 fv-row">
                                        <label className="required fs-5 fw-bold mb-2">
                                            Confrim Password
                                        </label>
                                        <input className="form-control" type="text" name="cpassword" />
                                    </div>

                                </div>
                                <div className="col-6 d-none" id="text">
                                    <div className="mt-12 fv-row">
                                        <span className="fs-5 fw-bold mb-2">User cannot log in using password</span>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="mb-5 mt-5 fv-row">
                                        <label className="required fs-5 fw-bold mb-2">
                                            Tags
                                        </label>
                                        <select className="form-select" name="tags" id="tags" onChange={() => openModal('d-block')}>
                                            <option className="administrator">Admin</option>
                                            <option className="monitoring">Monitoring</option>
                                            <option className="policymaker">Policymaker</option>
                                            <option className="management">Management</option>
                                            <option className="impersonator">Impersonator</option>
                                            <option className="none">None</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-8">
                                    <span className="">

                                    </span>
                                </div>
                            </div>
                        </div>
                        <div
                            className="card-footer d-flex justify-content-end py-6 px-9"
                            id="destroy_btn"
                        >
                            {/* {currentDatabaseInfo?.ref_type=="dbaas_master"?(<> */}
                            <a
                                id="destroy-btn"
                                // onClick={(e) => deleteDatabase(databaseInfo.cluster_name)}
                                className="btn btn-primary fw-bold"
                            >

                                Update User
                            </a>
                            {/* </>):('')} */}
                        </div>
                    </form>
                </div>
            </div>
            <PermissionInfoModal openModal={openModal}/>
        </>
    )
}

export default UpdateUser