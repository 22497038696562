import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import AccessDenied from "../../AccessDenied";
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../Loaders/TableLoader";
import CustomDoc from "../../../../CustomHooks/CustomDoc";
import ProductsUrl from "../../Docs/Docsdata";

export default function Vpc() {
  const [dataFetched, setDataFetched] = useState(false);
  const [access, setAccess] = useState(true);
  const [perPage, setPerPage] = useState(5);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [activePage, setActivePage] = useState(0);
  const currentPage = useRef(0);
  const loaderFunc = () => {
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if (loader && no_record) {
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");

      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
  }
  function ProductInfo({ productdata }) {
    return (
      <>
        {productdata != null && Object.keys(productdata).length != 0 ? (productdata && productdata.map((value, index) => (
          <tr>
            <td className="p-0 text-start">
              {" "}
              <div className="d-flex align-items-center">
                <td />
                {" "}
                <div className="symbol symbol-30px me-5">
                  {" "}
                  {value.dclocation ? (<>{value.dclocation.dccc ? (<>
                    <img src={"/img/flag_" + value.dclocation.dccc + ".png"} />
                  </>) : ('')}</>) : ('')}
                </div>{" "}
                <div className="d-flex justify-content-start flex-column">
                  {" "}
                  {value.dclocation ? (<>{value.dclocation.location ? (<>
                    <a className="text-dark fw-bold text-hover-primary fs-6">
                      {value.dclocation.location}
                    </a>
                  </>) : ('')}</>) : ('')}
                  {" "}
                </div>{" "}
              </div>{" "}
            </td>{" "}
            <td></td>
            <td className="text-center">
              {" "}
              <div className="d-flex flex-column w-100 me-2">
                {" "}
                {/* <div className="d-flex flex-stack mb-2"> */}
                {" "}
                <span className=" me-2 fs-7 fw-bold">
                  {value.name}
                </span>{" "}
                {/* </div>{" "} */}
              </div>{" "}
            </td>{" "}
            <td className="text-center">
              {" "}
              <div className="d-flex flex-column w-100 me-2">
                {" "}
                {/* <div className="d-flex flex-stack mb-2"> */}
                {" "}
                <span className="me-2 fs-7 fw-bold">
                  {value.network}/{value.size}
                </span>{" "}
                {/* </div>{" "} */}
              </div>{" "}
            </td>{" "}
            <td className="text-center">
              {" "}
              <span className="badge badge-secondary badge-lg">{value.available}</span>{" "}
            </td>{" "}
            <td className="text-end pe-9">
              {" "}
              <div className=" justify-content-end flex-shrink-0">
                {" "}
                <Link to={"/vpc/" + value.id + ""} >
                  <a
                    className="btn btn-primary btn-active-light-primary btn-sm sphide"
                  >
                    {" "}
                    Manage{" "}
                    <span className="svg-icon ms-2 svg-icon-3">
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        {" "}
                        <rect
                          opacity="0.5"
                          x={18}
                          y={13}
                          width={13}
                          height={2}
                          rx={1}
                          transform="rotate(-180 18 13)"
                          fill="black"
                        />{" "}
                        <path
                          d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                          fill="black"
                        />{" "}
                      </svg>{" "}
                    </span>{" "}
                  </a>{" "}
                </Link>
              </div>{" "}
            </td>
          </tr>
        ))) : (<>
          <tr>
            {/* <td colspan="12" className="dataTables_empty" id="loader"> */}
            {/* <div className="loading-spinner"></div> */}
            {/* <TableLoader /> */}
            {/* </td> */}
            <td colspan="12" className="" id="no_record" valign="top">
              <div className="d-flex flex-column flex-center">
                <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
              </div>
            </td>
          </tr>
        </>)}
      </>
    );
  }

  const [vpcDataAll, setVpcDataAll] = useState(null);
  const [dummyVpcDataAll, setDummyVpcDataAll] = useState(null);
  const dataFetchedRef = useRef(false);
  const getVPC = async () => {
    var norecords = document.querySelector("#no_record");
    var loader = document.querySelector("#loader");
    const dt = toast
    const datas = await UseApi('get', 'vpc', '');
    if (datas) {
      setDataFetched(true);
    }
    if (datas.status == 'error' && datas.message != "No VPC.") {
      setVpcDataAll(null);
      setDummyVpcDataAll(null)
      setDataFetched(true)
      toast.update(dt, {
        render: datas.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      setAccess(false);
      // loaderFunc();
    }
    if (datas.status == "error" && datas.message == "No VPC.") {
      setDataFetched(true)
      setVpcDataAll([]);
      setDummyVpcDataAll([])
      if (norecords && loader) {
        norecords.classList.remove("d-none");
        loader.classList.add("d-none");
      }
      toast.update(dt, {
        render: "no record found", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });

    } else {
      setDataFetched(true)
      setVpcDataAll(datas?.vpc?.reverse());
      setDummyVpcDataAll(datas?.vpc?.reverse())
      setPageCount(Math.ceil(datas?.vpc?.reverse().length / perPage));
      if (norecords && loader) {
        norecords.classList.add("d-none");
        loader.classList.add("d-none");
      } toast.dismiss();
      //   toast.update(dt,{render: "Your VPC request has been successful", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      // });  

    }

  }
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getVPC();
  }, [pageCount, dummyVpcDataAll]);

  const handleSearch = (text) => {
    const endOffset = itemOffset + perPage;
    const data = vpcDataAll.filter(row => {
      return (
        (row.name && row.name.toString().toLowerCase().includes(text.toLowerCase())) ||
        row.dclocation.location && row.dclocation.location.toString().toLowerCase().includes(text.toLowerCase())
      )
    });
    setDummyVpcDataAll(data)
    setPageCount((old) => old = Math.ceil(data.length / perPage));
  }

  function PaginatedItems({ itemsPerPage, ProductData }) {

    // Calculate the total number of pages based on itemsPerPage and ProductData
    const pageCount = Math.ceil(ProductData.length / parseInt(itemsPerPage));

    // Calculate the current end offset
    const endOffset = itemOffset + parseInt(itemsPerPage);

    // Handle page click
    const handlePageClick = (event) => {
      const selectedPage = event.selected;
      const newOffset = selectedPage * parseInt(itemsPerPage);
      setActivePage(selectedPage);
      setItemOffset(newOffset);
    };

    return (
      <>
        <ProductInfo productdata={ProductData.slice(itemOffset, endOffset)} />
        {ProductData.length > 0 && ProductData.length > 5 ?
          <td colspan="12">
            <ReactPaginate
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              initialPage={activePage}
              previousLabel="< previous"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </td>
          :
          ""
        }
      </>
    );
  }

  const handleItemsPerPageChange = (newItemsPerPage) => {
    // If the current page goes beyond the total page count, reset to the first page
    const newPageCount = Math.ceil(dummyVpcDataAll.length / parseInt(newItemsPerPage));
    const newActivePage = activePage >= newPageCount ? 0 : activePage;

    // Set the new offset based on the current page and itemsPerPage
    const newOffset = newActivePage * parseInt(newItemsPerPage);

    // Update state
    setItemOffset(newOffset);
    setActivePage(newActivePage);
    setPerPage(newItemsPerPage);  // Update the itemsPerPage state
  };

  if (dataFetched) {
    if (access) {
      return (
        <>
          <div className="col-xl-12">
            {/*begin::Tables Widget 9*/}
            <div className="card card-xl-stretch mb-5 mb-xl-12">
              {/*begin::Header*/}
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold fs-3 mb-1">
                    VPC
                  </span>
                </h3>
                <div
                  className="card-toolbar"
                >
                  <div className="position-relative my-1" style={{ marginRight: 20 }}>
                    <CustomDoc url={ProductsUrl[0]?.vpc + "/getting-started/"} />
                  </div>
                  <div className="position-relative my-1" style={{ marginRight: 20 }}>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      data-kt-table-widget-4="search"
                      id="searchClient"
                      style={{ padding: '0.6rem' }}
                      onChange={e => handleSearch(e.target.value)}
                      placeholder="Search"
                    />
                  </div>
                  <div style={{ marginRight: 20 }}>
                    <div className="dataTables_length">
                      <label className="w-125px">
                        <select
                          onChange={e => handleItemsPerPageChange(e.target.value)}
                          //data-control="select2"
                          style={{ padding: '0.6rem' }}
                          className="form-select form-select-sm form-select-solid"
                          defaultValue={5}
                          id="perPage"

                        >
                          <option value={5} selected="">
                            5 Records
                          </option>
                          <option value={10}>10 Records</option>
                          <option value={25}>25 Records</option>
                          <option value={50}>50 Records</option>
                          <option value={100}>100 Records</option>
                        </select>
                      </label>
                    </div>
                  </div>

                  <Link to="/vpc/deploy">
                    <a
                      className="btn btn-primary btn-active-light-primary"
                      style={{ padding: '0.5192rem' }}
                    >
                      {/*<a class="btn btn-sm btn-light btn-active-primary" data-bs-toggle="modal" data-bs-target="#createvpc-modal">
                begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
                      <i className="bi bi-globe" />
                      {/*end::Svg Icon*/}Create New VPC
                    </a>
                  </Link>

                </div>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              {/*begin::Table container*/}
              <div className="table-responsive">
                {/*begin::Table*/}
                <table
                  id="vpcTable"
                  className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
                >
                  {/*begin::Table head*/}
                  <thead className="bg-secondary">
                    <tr className="fw-bold ">
                      <th className="  w-100px text-start ps-9">Location</th>
                      <th className="  w-20px p-3" />
                      <th className=" w-150px text-center">Name</th>
                      <th className=" w-100px text-center">Network</th>
                      <th className="text-center w-150px">Resources</th>
                      <th className="w-150px text-end pe-20">Action</th>
                    </tr>
                  </thead>
                  {/*end::Table head*/}
                  {/*begin::Table body*/}
                  <tbody id="mainTable" >
                    {dummyVpcDataAll != null ? (<>
                      {access == false ? (<>
                        <tr>
                          <td colspan={12}>
                            <AccessDenied />
                          </td>
                        </tr>
                      </>) : (<>
                        {Object.keys(dummyVpcDataAll).length != 0 ? (<>
                          <PaginatedItems ProductData={dummyVpcDataAll} itemsPerPage={perPage} />
                        </>) : (<>
                          <tr>
                            <td colspan={12}>
                              <div className="card-body p-0 mt-10">
                                {/*begin::Illustration*/}
                                <div className="text-center px-4 mt-10">
                                  <span className="mw-100 mh-300px">
                                    <svg
                                      viewBox="0 0 64 64"
                                      width="234px"
                                      height="234px"
                                      version="1.1"
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      fill="#21325B"
                                      stroke="#21325B"
                                    >
                                      <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                                      <g id="SVGRepo_iconCarrier">
                                        {" "}
                                        <title>clo-cloud-network-folder</title> <desc>Created with Sketch.</desc>{" "}
                                        <defs> </defs>{" "}
                                        <g
                                          id="64px-Glyph"
                                          stroke="none"
                                          strokeWidth={1}
                                          fill="none"
                                          fillRule="evenodd"
                                        >
                                          {" "}
                                          <g id="clo-cloud-network-folder" fill="#21325B">
                                            {" "}
                                            <path
                                              d="M12,13 C12,12.447 12.447,12 13,12 L51.444,12 C50.75,10.81 49.474,10 48,10 L30,10 C28,10 27.497,9.329 26.8,8.4 C25.998,7.331 25,6 22,6 L12,6 C9.794,6 8,7.794 8,10 L8,38 C8,40.206 9.794,42 12,42 L12,13 Z"
                                              id="Fill-216"
                                            >
                                              {" "}
                                            </path>{" "}
                                            <path
                                              d="M38.8887,36 L27.8887,36 C24.6417,36 21.9997,33.458 21.9997,30.333 C21.9997,27.51 24.1567,25.163 26.9677,24.736 C27.4307,22.053 29.8597,20 32.7777,20 C34.7387,20 36.5317,20.919 37.6217,22.441 C38.0377,22.369 38.4617,22.333 38.8887,22.333 C42.8097,22.333 45.9997,25.398 45.9997,29.167 C45.9997,32.935 42.8097,36 38.8887,36 M54.9997,14 L51.9997,14 L13.9997,14 L13.9997,42 L51.9997,42 C54.2057,42 55.9997,40.206 55.9997,38 L55.9997,15 C55.9997,14.447 55.5527,14 54.9997,14"
                                              id="Fill-217"
                                            >
                                              {" "}
                                            </path>{" "}
                                            <path
                                              d="M38.8887,24.333 C38.3887,24.333 37.8947,24.401 37.4197,24.535 C36.9657,24.661 36.4837,24.457 36.2637,24.039 C35.6017,22.781 34.2657,22 32.7777,22 C30.6327,22 28.8887,23.645 28.8887,25.667 C28.8887,26.22 28.4417,26.667 27.8887,26.667 C25.7437,26.667 23.9997,28.312 23.9997,30.333 C23.9997,32.356 25.7437,34 27.8887,34 L38.8887,34 C41.7067,34 43.9997,31.832 43.9997,29.167 C43.9997,26.502 41.7067,24.333 38.8887,24.333"
                                              id="Fill-218"
                                            >
                                              {" "}
                                            </path>{" "}
                                            <path
                                              d="M24,50 L5,50 C4.447,50 4,50.447 4,51 L4,55 C4,55.553 4.447,56 5,56 L24,56 L24,50 Z"
                                              id="Fill-219"
                                            >
                                              {" "}
                                            </path>{" "}
                                            <polygon id="Fill-220" points="30 46 36 46 36 44 30 44">
                                              {" "}
                                            </polygon>{" "}
                                            <path
                                              d="M39,48 L27,48 C26.447,48 26,48.447 26,49 L26,57 C26,57.553 26.447,58 27,58 L39,58 C39.553,58 40,57.553 40,57 L40,49 C40,48.447 39.553,48 39,48"
                                              id="Fill-221"
                                            >
                                              {" "}
                                            </path>{" "}
                                            <path
                                              d="M61,50 L42,50 L42,56 L61,56 C61.553,56 62,55.553 62,55 L62,51 C62,50.447 61.553,50 61,50"
                                              id="Fill-222"
                                            >
                                              {" "}
                                            </path>{" "}
                                          </g>{" "}
                                        </g>{" "}
                                      </g>
                                    </svg>


                                  </span>
                                </div>
                                {/*end::Illustration*/}
                                {/*begin::Wrapper*/}
                                <div className="card-px text-center py-5 mb-20">
                                  {/*begin::Title*/}
                                  <h1 className="fs-2x fw-bolder mb-5"> VPC</h1>
                                  <h2 className="fs-1x fw-bolder mb-5 text-muted">
                                    Build a custom network in the cloud
                                  </h2>
                                  {/*end::Title*/}
                                  {/*begin::Description*/}
                                  <p className="text-gray-400 fs-4 fw-bold mb-10">
                                    Take control with Utho's VPC service. Create a secure environment for your<br /> business-critical applications and data                </p>
                                  {/*end::Description*/}
                                  {/*begin::Action*/}
                                  <Link to="/vpc/deploy">
                                    <a
                                      href="#"
                                      className="btn btn-primary mb-10"
                                    >
                                      Create VPC
                                    </a>
                                  </Link>
                                  {/*end::Action*/}
                                </div>
                                {/*end::Wrapper*/}
                              </div>
                            </td>
                          </tr>
                        </>)}
                      </>)}
                    </>) : (<>
                      <tr>
                        <td colspan={12}>
                          <TableLoader />
                          <TopBarHeaderLine />
                        </td>
                      </tr>

                    </>)}
                  </tbody>
                  {/*end::Table body*/}
                </table>
                {/*end::Table*/}
              </div>
              {/*end::Table container*/}
              {/*begin::Body*/}
            </div>
            {/*end::Tables Widget 9*/}
          </div>
        </>
      )
    } else {
      return (
        <>
          <AccessDenied />
        </>
      )
    }
  } else {
    return (
      <>
        <TableLoader />
      </>
    )
  }
}

// }
// }
// else{
// return (
// <>
// <TopBarHeader/>
//   {/* <div class="loading-spinner2"></div> */}
// </>
// );
// }
// }