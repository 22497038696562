import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import Swal from 'sweetalert2';
import ReactPaginate from "react-paginate";
import UsePost from "../../../../../../CustomHooks/usePost";
import UseApi from "../../../../../../CustomHooks/useApi";
import TopBarHeaderLine from "../../../../../../CustomHooks/TopBarHeaderLine";
const AccessIp = (props) => {
  var query = window.location.href;
  const [pageStatus, setPageStatus] = useState(false)
  const [activePage, setActivePage] = useState(0);
  var vars = query.split("/");
  var id = vars[4];

  const [pageCount, setPageCount] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [itemOffset, setItemOffset] = useState(0);
  const [databaseIPData, setDatabaseIPData] = useState(null);
  const [dummyDatabaseIPData, setDummyDatabaseIPData] = useState(null);
  const [currentDatabaseInfo, setCurrentDatabaseInfo] = useState(null);

  const openDrawer = () => {
    var drawerElement = document.querySelector("#databaseIp-modal");
    drawerElement.classList.toggle("drawer-on");
    drawerElement.style.width = '500px';
  }
  function removeFirstAndLastComma(str) {
    if (str.startsWith(",")) {
      str = str.substring(1);
    }
    if (str.endsWith(",")) {
      str = str.substring(0, str.length - 1);
    }
    return str;
  }
  const getDatabasesIP = async () => {
    if (props) {
      if (props.data) {
        if (props.data.trusted_hosts == "") {
          setDatabaseIPData([]);
          setDummyDatabaseIPData([])
        } else if (props.currentDatabaseInfo && props.data) {
          setCurrentDatabaseInfo(props.currentDatabaseInfo);
          var trusted_ip = removeFirstAndLastComma(props.data.trusted_hosts);
          var trustedArr = trusted_ip.split(",");
          setDatabaseIPData(trustedArr);
          setDummyDatabaseIPData(trustedArr)
        }
      }
    }
  }
  const copyTrustedHostName = () => {
    var eleText = document.querySelector("#trusted_host_text");
    var ele = document.querySelector("#file_url_copy_trusted_host");
    // var copiedEle = document.querySelector('#file_url_copied_btn')

    if (ele && eleText) {
      const copyText = eleText.innerText;
      const originalContent = ele.innerHTML;

      navigator.clipboard.writeText(copyText).then(() => {
        ele.innerHTML = "Copied";

        setTimeout(() => {
          ele.innerHTML = originalContent;
        }, 1000);
      }).catch(err => {
        console.error('Failed to copy text: ', err);
      });
    }
  };
  document.addEventListener('click', function (event) {
    if (event.target && event.target.id === 'file_url_copy_trusted_host') {
      copyTrustedHostName();
      event.stopPropagation();
    }
  });
  const deleteDatabaseIP = async (dbid, ip_name) => {
    var submitBtn = document.querySelector("#delete-databaseip" + dbid + "-btn");
    if (submitBtn) {
      toast.dismiss();
      const { value: isDelete } = await Swal.fire({
        title: 'Delete Trusted Host',
        html: `
        <div className="d-flex align-items-center flex-wrap"> 
        <div id="kt_clipboard_4" className="me-5 fw-bold text-gray-600">
          <p class="fs-5">Enter the Trusted IPv4 to delete:</p>
  
          <span class="fw-bold" id="trusted_host_text">${ip_name} &nbsp;</span><span id="file_url_copy_trusted_host" style="cursor:pointer; width: 70px; display: inline-block;" class="text-primary"> ( copy ) </span>  
        </div>  
      </div>
     `,
        input: 'text',
        inputPlaceholder: 'Database Trusted IPv4',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'delete-button bg-danger', // Add your custom class here
        },
      });
      //   console.log(isDelete.toString()+" == "+ip_name.trim());
      if (isDelete == ip_name.trim()) {
        setPageStatus(true)
        var preContent = submitBtn.innerHTML;
        submitBtn.classList.add("disabled");
        submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
        const dt = toast.loading("Database trusted ip deleting...");
        const dnsp = await UseApi('DELETE', 'databases/' + id + '/trustedhost/' + ip_name.trim() + '?confirm=' + ip_name.trim(), '');
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
        //console.log(dnsp);
        if (dnsp.status == 'error') {
          setPageStatus(false)
          toast.update(dt, {
            render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        } else if (dnsp.status == 'success') {
          setPageStatus(false)
          toast.update(dt, {
            render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          if (props) {
            props.updateDatabase();
          }
        }
      } else {
        setPageStatus(false)
        swal({
          title: "Invalid Database IP",
          text: "Please confirm the valid database IP!",
          icon: "warning",
          button: "OK",
        });
      }
    }
  }
  const whitelistIP = async (event) => {
    event.preventDefault()
    toast.dismiss()
    var submitBtn = document.querySelector("#create-databaseIp-btn");
    var trusted_host = document.querySelector('#trustedHost')
    if (submitBtn) {
      setPageStatus(true)
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      const dt = toast.loading("Please wait...");

      const responseData = await UsePost('post', 'databases/' + id + '/trustedhost', 'databaseIp-form');
      if (responseData) {
        setPageStatus(false)
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
        if (responseData.status === 'error') {
          toast.update(dt, {
            render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        } else if (responseData.status === 'success') {
          toast.update(dt, {
            render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          trusted_host.value = ''
          if (props) {
            openDrawer();
            props.updateDatabase();
          }

        }
      }
    }
  }

  useEffect(() => {
    getDatabasesIP();
  }, [props])


  function ProductInfo({ productdata }) {
    return (
      <>
        {productdata != null && Object.keys(productdata).length != 0 ? (productdata && productdata.map((value, index) => (
          <tr>
            <td className="ps-9 text-gray-700 text-start">{value}</td>
            <td></td>
            {currentDatabaseInfo?.ref_type == "dbaas_master" || currentDatabaseInfo?.ref_type == "dbaas_replica" ? (<>
              <td className="text-center ps-9" >
                <a
                  onClick={(e) => deleteDatabaseIP(index, value)}
                  id={"delete-databaseip" + index + "-btn"}
                  className="btn btn-danger btn-sm sphide"
                >
                  Delete
                </a>
              </td>
            </>) : ('')}
          </tr>
        ))) : (<>
          <tr>
            {/* <td colspan="12" className="dataTables_empty" id="loader">
              <div className="loading-spinner"></div>
            </td> */}
            <td colspan="12" className="" id="no_record" valign="top">
              {/* <div className="d-flex flex-column flex-center">
                <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
              </div> */}
              <div className="mt-20 mb-20 text-center">
                <span className="fs-6 text-gray-800 fw-bold">no record found</span>
              </div>
            </td>
          </tr>
        </>)}

      </>
    )
  }
  const handleSearch = (text) => {
    const endOffset = itemOffset + perPage;
    let matches = databaseIPData.filter((name) => {
      const regex = new RegExp(`${text}`, "gi");
      return name.match(regex);
    })
    setDummyDatabaseIPData(matches);;
    setPageCount(Math.ceil(matches.length / perPage));
  }
  function PaginatedItems({ itemsPerPage,ProductData }) {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(ProductData.length / itemsPerPage));
    const handlePageClick = (event) => {
      setActivePage((old) => old = event.selected);
      const newOffset = event.selected * itemsPerPage % ProductData.length;
      setItemOffset(newOffset);
    };

    return (
      <>
        <ProductInfo productdata={ProductData.slice(itemOffset, endOffset)} />
        <td colspan="12">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            initialPage={activePage}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </td>
      </>
    );
  }

    const handleKeyPress = (event) => {
      console.log(event.key);
      if (event.key === 'Enter') {
        // Call your function here
        whitelistIP(event);
      }
    }
  return (
    <>
      <div className="card">
        <div className="card-header">
          <div className="card-title">
            <h3>Trusted IPv4 Address </h3>
          </div>
          <div className="card-toolbar">
            <div
              className="position-relative my-1"
              style={{ marginRight: 20 }}
            >
              <input
                type="text"
                className="form-control form-control-sm"
                data-kt-table-widget-4="search"
                id="searchClient"
                // value={searchTerm}
                onChange={e => handleSearch(e.target.value)}
                placeholder="Search"
              />
            </div>

            <div style={{ marginRight: 20 }}>
              <div className="dataTables_length">
                <label className="w-125px">
                  <select
                    onChange={e => setPerPage(e.target.value)}
                    //data-control="select2"
                    className="form-select form-select-sm form-select-solid"
                    defaultValue={5}
                    id="perPage"

                  >
                    <option value={5} selected="">
                      5 Records
                    </option>
                    <option value={10}>10 Records</option>
                    <option value={25}>25 Records</option>
                    <option value={50}>50 Records</option>
                    <option value={100}>100 Records</option>
                  </select>
                </label>
              </div>
            </div>

            {/* {currentDatabaseInfo?.ref_type=="dbaas_master" || currentDatabaseInfo?.ref_type=="dbaas_replica"?(<> */}
            {props.currentDatabaseInfo?.ref_type != "dbaas_node" ?
              <a onClick={openDrawer} className="btn btn-sm btn-primary my-1 sphide">
                + Add IPv4
              </a>
              :
              ""
            }
            {/* </>):('')} */}
          </div>
        </div>
        <div className="card-body p-0">
          <div className="table-responsive">
            <table
              className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
              id="kt_security_license_usage_table"
            >
              <thead className="border-gray-200 fs-5 fw-bold bg-secondary">
                <tr>
                  <th className="w-150px ps-9 text-start">IPv4 Address</th>
                  <th className="w-50px ps-9"></th>
                  <th className="text-center w-150px ps-9" ></th>
                </tr>
              </thead>
              <tbody id="public-IP-list" className="fw-6 fw-bold text-gray-600">
                {dummyDatabaseIPData != null ? (<>
                  {dummyDatabaseIPData.length == 0 ? (<>
                    <tr>
                      <td colspan="12" id="no_record" valign="top">
                        {/* <div className="d-flex flex-column flex-center">
                          <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                        </div> */}
                        <div className="mt-20 mb-20 text-center">
                          <span className="fs-6 text-gray-800 fw-bold">no record found</span>
                        </div>
                      </td>
                    </tr>
                  </>) : (<>
                    <PaginatedItems itemsPerPage={perPage} ProductData={dummyDatabaseIPData}/>
                  </>)}
                </>) : ('')}
              </tbody>
            </table>
          </div>
        </div>
        <div
          id="databaseIp-modal"
          className="bg-body shadow drawer drawer-end"
          data-kt-drawer="true"
          data-kt-drawer-name="chat"
          data-kt-drawer-activate="true"
          data-kt-drawer-direction="end"
          data-kt-drawer-close="#close_ssh"
          data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
          style={{ maxWidth: '70%', important: true }}

        >
          {/*begin::Messenger*/}
          <div className="card rounded-0 w-100 border-0" id="kt_drawer_chat_messenger">
            {/*begin::Card header*/}
            <div className="card-header pe-5" id="kt_drawer_chat_messenger_header">
              {/*begin::Title*/}
              <div className="card-title">
                {/*begin::User*/}
                <div className="d-flex justify-content-center flex-column me-3">
                  <a

                    className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1"
                  >
                    Add New IPv4
                  </a>
                </div>
                {/*end::User*/}
              </div>
              {/*end::Title*/}
              {/*begin::Card toolbar*/}
              <div className="card-toolbar">
                {/*begin::Close*/}
                <div
                  onClick={openDrawer}
                  className="btn btn-sm btn-icon btn-active-light-primary"
                  id="close_ssh"
                >
                  {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                  <span className="svg-icon svg-icon-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.5"
                        x={6}
                        y="17.3137"
                        width={16}
                        height={2}
                        rx={1}
                        transform="rotate(-45 6 17.3137)"
                        fill="black"
                      />
                      <rect
                        x="7.41422"
                        y={6}
                        width={16}
                        height={2}
                        rx={1}
                        transform="rotate(45 7.41422 6)"
                        fill="black"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                </div>
                {/*end::Close*/}
              </div>
              {/*end::Card toolbar*/}
            </div>
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className="" id="">
              {/*begin::Form*/}
              <form id="databaseIp-form" className="form">
                {/*begin::Modal body*/}
                <div className="modal-body ">
                  {/*begin::Scroll*/}
                  <div
                    className="scroll-y me-n7 pe-7"
                    id="kt_modal_create_api_key_scroll"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                    data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                    data-kt-scroll-offset="300px"
                  >
                    {/*begin::Input group*/}
                    <div className="mb-5 fv-row">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2"> IPv4 Address</label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <input type="hidden" name="type" value="add" />
                      <input
                        type="text"
                        className="form-control"
                        id="trustedHost"
                        name="trusted_host"
                        placeholder="Enter IP Address"
                        onKeyPress={handleKeyPress}
                      />
                      {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                  </div>
                  {/*end::Scroll*/}
                </div>
                {/*end::Modal body*/}
                {/*begin::Modal footer*/}
                <div className="modal-footer ">
                  {/*begin::Button*/}
                  <button
                    onClick={whitelistIP}
                    className="btn btn-primary w-100"
                    id="create-databaseIp-btn"
                  >
                    <span className="indicator-label"> + Add IPv4</span>
                  </button>
                  {/*end::Button*/}
                </div>
                {/*end::Modal footer*/}
              </form>
              {/*end::Form*/}
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Messenger*/}
        </div>
      </div>
    </>
  );
}


export default AccessIp 
