import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import Swal from 'sweetalert2';
import ReactPaginate from "react-paginate";
import UsePost from "../../../../../../CustomHooks/usePost";
import UseApi from "../../../../../../CustomHooks/useApi";
import UsePostData from "../../../../../../CustomHooks/usePostData";
import TopBarHeaderLine from "../../../../../../CustomHooks/TopBarHeaderLine";

const Database = (props) => {
  var query = window.location.href;
  var vars = query.split("/");
  var id = vars[4];

  const [permissionArr, setPermissionArr] = useState(["read", "full", "none"]);
  const [pageStatus, setPageStatus] = useState(false)
  const [currPermission, setCurrPermission] = useState('');
  const [activePage, setActivePage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [itemOffset, setItemOffset] = useState(0);
  const [databaseData, setDatabaseData] = useState(null);
  const [dummyDatabaseData, setDummyDatabaseData] = useState(null);
  const [currentDatabaseInfo, setCurrentDatabaseInfo] = useState(null);
  const [databasePermissions, setDatabasePermissions] = useState(null);
  const [userArr, setUserArr] = useState(null);
  const openDrawer = () => {
    var drawerElement = document.querySelector("#database-modal");
    drawerElement.classList.toggle("drawer-on");
    drawerElement.style.width = '500px';
  }
  const openPermissionDrawer = () => {
    var drawerElement = document.querySelector("#databasePemission-modal");
    drawerElement.classList.toggle("drawer-on");
    drawerElement.style.width = '500px';
  }

  const loaderFunc = () => {
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if (loader && no_record) {
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");

      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
  }

  const togglePermSec = () => {
    // var addPermSec = document.querySelector("#addPerm-sec");
    // var addPermBtn = document.querySelector("#addPerm-btn");
    // if (addPermBtn && addPermSec) {
    //   addPermBtn.classList.toggle("d-none");
    //   addPermSec.classList.toggle("d-none");
    // }
  }

  const getDatabases = async () => {
    if (props) {
      if (props.data) {
        if (props.data.databases.length == 0) {
          setDatabaseData([]);
          setDummyDatabaseData([])
        } else {
          setDatabaseData(props.data.databases);
          setDummyDatabaseData(props.data.databases)
          setPageCount(Math.ceil(props.data.databases.length / perPage))
          setCurrentDatabaseInfo(props.currentDatabaseInfo);
        }
      }
    }
  }

  function deleteUserFromArray(array, userToDelete) {
    var filteredArray = array.filter(function (item) {
      return item.db_user !== userToDelete;
    });
    return filteredArray;
  }
  const updatePermission = (db_name) => {
    var pdb_name = document.querySelector("#pdb_name");
    if (pdb_name) {
      pdb_name.value = db_name;
    }
    if (props) {
      if (props.data) {
        if (props.data.permissions.length == 0) {
          setDatabasePermissions([]);
          var users = props.data.users;
          setUserArr(users);
        } else {
          var dbPermissions = []
          var users = props.data.users;
          props.data.permissions.map((value, index) => {
            if (db_name == value.db_name) {
              dbPermissions.push({ "db_name": value.db_name, "db_user": value.db_user, "permission": value.permission });
            }
            deleteUserFromArray(users, value.db_user);
          })
          setUserArr(users);
          setDatabasePermissions(dbPermissions);
        }
      }
    }
    openPermissionDrawer();
  }
  const createDatabase = async (event) => {
    toast.dismiss();
    event.preventDefault();
    var submitBtn = document.querySelector("#create-database-btn");
    var databases_name = document.querySelector('#databases_name')
    if (submitBtn) {
      const toastBar = toast.loading("Please wait...");
      setPageStatus(true)
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';

      const responseData = await UsePost('post', 'databases/' + id + '/database', 'database-form');
      if (responseData) {
        setPageStatus(false)
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
        if (responseData.status === 'error') {
          toast.update(toastBar, {
            render: responseData.message,
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        } else if (responseData.status === 'success') {
          toast.update(toastBar, {
            render: responseData.message,
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          databases_name.value = ''
          if (props) {
            openDrawer();
            props.updateDatabase();
          }

        }
      }
    }
  }
  const copyClusterName = () => {
    var eleText = document.querySelector("#file_url");
    var ele = document.querySelector("#file_url_copy_btn");
    // var copiedEle = document.querySelector('#file_url_copied_btn')

    if (ele && eleText) {
      const copyText = eleText.innerText;
      const originalContent = ele.innerHTML;
      console.log(originalContent);

      navigator.clipboard.writeText(copyText).then(() => {
        ele.innerHTML = "(Copied)";

        setTimeout(() => {
          ele.innerHTML = "(copy)";
        }, 1000);
      }).catch(err => {
        console.error('Failed to copy text: ', err);
      });
    }
  };
  function handleButtonClick(event) {
    console.log(event.target);
    if (event.target && event.target.id === 'file_url_copy_btn') {
      copyClusterName();
      event.stopPropagation();
    }
  }

  // Remove any existing listener before adding a new one (if necessary)
  document.removeEventListener('click', handleButtonClick);
  document.addEventListener('click', handleButtonClick);
  // const copyToClipboard = (target, targetBy, svgIcon) => {
  //   var eleText = document.querySelector("#file_url");
  //   var ele = document.querySelector("#file_url_copy_btn");
  //   if (ele && eleText) {
  //     var copyText = eleText.innerText; // Use innerText to get the text content
  //     var innerEle = ele.innerHTML;
  //     copy(copyEle);
  //     // svgIconElement.classList.add('d-none')
  //     btn_id.innerHTML = `<svg
  //     xmlns="http://www.w3.org/2000/svg"
  //     width=24px
  //     height=24px
  //     viewBox="0 0 24 24"
  //     fill="none"
  //   >
  //     <path
  //       d="M9.89557 13.4982L7.79487 11.2651C7.26967 10.7068 6.38251 10.7068 5.85731 11.2651C5.37559 11.7772 5.37559 12.5757 5.85731 13.0878L9.74989 17.2257C10.1448 17.6455 10.8118 17.6455 11.2066 17.2257L18.1427 9.85252C18.6244 9.34044 18.6244 8.54191 18.1427 8.02984C17.6175 7.47154 16.7303 7.47154 16.2051 8.02984L11.061 13.4982C10.7451 13.834 10.2115 13.834 9.89557 13.4982Z"
  //       fill="black"
  //     />
  //   </svg>`;
  //     setTimeout(() => {
  //       btn_id.innerHTML = copyText;
  //       // svgIconElement.classList.remove('d-none')
  //     }, 2000);
  //   }

  // }
  const createNewPermission = async () => {
    toast.dismiss()
    var submitBtn = document.querySelector("#create-permission-btn");
    var user_permission = document.querySelector("#userPermission");
    var db_user_name = document.querySelector("#dbUserName");
    if (submitBtn) {
      const dt = toast.loading("Please wait...");
      setPageStatus(true)
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      var pdb_name = document.querySelector("#pdb_name");
      if (pdb_name) {
        const responseData = await UsePost('post', 'databases/' + id + '/database/' + pdb_name.value + '/user', 'databasePermission-form');
        if (responseData) {
          setPageStatus(false)
          submitBtn.classList.remove("disabled");
          submitBtn.innerHTML = preContent;
          if (responseData.status === 'error') {
            toast.update(dt, {
              render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
          } else if (responseData.status === 'success') {
            toast.update(dt, {
              render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
            db_user_name.value = ''
            user_permission.value = ''
            if (props) {
              togglePermSec();
              openPermissionDrawer();
              props.updateDatabase();
            }

          }
        }
      }
    }
  }

  const updateDbPermission = async (db_name, db_user) => {
    var submitBtn = document.querySelector("#update-permission" + db_user + "-btn");
    if (submitBtn) {
      setPageStatus(true)
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      const dt = toast.loading("Please wait...");
      var newpermission = document.querySelector("#newpermission_field" + db_user);
      if (newpermission) {
        const responseData = await UsePostData('post', 'databases/' + id + '/database/' + db_name + '/user', { "db_name": db_name, "db_user": db_user, "permission": newpermission.value });
        if (responseData) {
          setPageStatus(false)
          submitBtn.classList.remove("disabled");
          submitBtn.innerHTML = preContent;
          if (responseData.status === 'error') {
            toast.update(dt, {
              render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
          } else if (responseData.status === 'success') {
            toast.update(dt, {
              render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
            if (props) {
              openPermissionDrawer();
              props.updateDatabase();
            }
          }
        }
      }
    }

  }

  const deleteDatabase = async (dbid) => {
    var submitBtn = document.querySelector("#delete-database" + dbid + "-btn");
    if (submitBtn) {

      toast.dismiss();
      const { value: isDelete } = await Swal.fire({
        title: 'Delete Database',
        html: `
        <div className="d-flex align-items-center flex-wrap"> 
        <div id="kt_clipboard_4" className="me-5 fw-bold text-gray-600">
          <p class="fs-5">Enter the Database name to Destroy:</p>
  
          <span class="fw-bold" id="file_url" >${dbid} &nbsp;</span><span id="file_url_copy_btn" style="cursor:pointer" class="text-primary"> ( copy ) </span> 
          <span id="file_url_copied_btn" style="cursor:pointer" class="text-primary d-none"> Copied </span>  
        </div>  
      </div>
     `,
        input: 'text',
        inputPlaceholder: 'Database name',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'delete-button bg-danger', // Add your custom class here
        },
      });
      if (isDelete == dbid) {
        setPageStatus(true)
        const dt = toast.loading("Database deleting...");
        console.log(dt);
        var preContent = submitBtn.innerHTML;
        submitBtn.classList.add("disabled");
        submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
        const dnsp = await UseApi('DELETE', 'databases/' + id + '/database/' + dbid + '?confirm=' + dbid, '');
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
        //console.log(dnsp);
        if (dnsp.status == 'error') {
          setPageStatus(false)
          toast.update(dt, {
            render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        } else if (dnsp.status == 'success') {
          setPageStatus(false)
          toast.update(dt, {
            render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          if (props) {
            props.updateDatabase();
          }
        }
      } else {
        setPageStatus(false)
        swal({
          title: "Invalid Database Name",
          text: "Please confirm the valid database name!",
          icon: "warning",
          button: "OK",
        });
      }
    }
  }
  useEffect(() => {
    getDatabases();
  }, [props])

  function convertTimestamp(timestamp) {
    const date = new Date(timestamp);

    // Format the date
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
      timeZone: 'UTC'
    }).format(date);

    return formattedDate.replace('GMT', '(UTC)');
  }

  function ProductInfo({ productdata }) {
    return (
      <>
        {productdata != null && Object.keys(productdata).length != 0 ? (productdata && productdata.map((value, index) => (
          <tr>
            {/* <td></td> */}
            <td className="ps-9 text-start text-gray-700">{value.db_name}</td>
            <td></td>
            <td className="text-center">
              <span className="fs-7 fw-bold text-gray-700">
                {(value.created_at)}
              </span>
            </td>
            {currentDatabaseInfo?.ref_type != "dbaas_node" ? (<>
              <td className="text-end pe-10" >
                <a
                  onClick={(e) => updatePermission(value.db_name)}
                  className="btn btn-sm btn-primary sphide me-5"
                >
                  Manage Permissions
                </a>
                <a
                  onClick={(e) => deleteDatabase(value.db_name)}
                  id={"delete-database" + value.db_name + "-btn"}
                  className="btn btn-sm btn-danger sphide"
                >
                  Delete
                </a>
              </td>
            </>) : ('')}
          </tr>
        ))) : (<>
          <tr>
            <td colspan="12" className="" id="no_record" valign="top">
              {/* <div className="d-flex flex-column flex-center">
                <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
              </div> */}
              <div className="mt-20 mb-20 text-center">
                <span className="fs-6 text-gray-800 fw-bold">no record found</span>
              </div>
            </td>
          </tr>
        </>)}

      </>
    )
  }

  const handleInputChange = (e) => {
    const regex = new RegExp('^[a-zA-Z0-9 ]*$');
    const { value } = e.target;
    if (!regex.test(value)) {
      // Filter out special characters from the pasted text
      e.target.value = value.replace(/[^a-zA-Z0-9 ]/g, '');
    }
  };

  const handleKeyPress = (event) => {
    console.log(event.key);
    if (event.key === 'Enter') {
      // Call your function here
      createDatabase(event);
    }
  }

  const handleSearch = (text) => {
    console.log(text);
    const endOffset = itemOffset + perPage;
    // Remove special characters from the input text
    const escapedText = text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    // Create a regular expression pattern to match the filtered text
    const regex = new RegExp(escapedText, "gi");
    console.log(databaseData);
    // Filter the clusterData based on the regex pattern
    let data = databaseData.filter((row) => {
      return (
        row.db_name && row.db_name.toString().toLowerCase().includes(text.toLowerCase())
        // (row.tid && row.tid.toString().toLowerCase().includes(text.toLowerCase()))
      )
    });
    console.log(data);

    // console.log(data,"data",itemOffset, endOffset);
    // Update the state with the filtered data
    setDummyDatabaseData(data);
    setPageCount(Math.ceil(data.length / perPage));
  }

  function PaginatedItems({ itemsPerPage, ProductData }) {
    console.log(itemsPerPage, ProductData);
    const endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(ProductData.length / itemsPerPage));
    const handlePageClick = (event) => {
      setActivePage((old) => old = event.selected);
      const newOffset = event.selected * itemsPerPage;
      console.log(newOffset);
      setItemOffset(newOffset);
    };

    return (
      <>
        <ProductInfo productdata={ProductData.slice(itemOffset, endOffset)} />
        <td colspan="12">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            initialPage={activePage}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </td>
      </>
    );
  }
  return (
    <>
      <div className="card">
        <div className="card-header">
          <div className="card-title">
            <h3>Databases </h3>
          </div>
          <div className="card-toolbar">
            <div
              className="position-relative my-1"
              style={{ marginRight: 20 }}
            >
              <input
                type="text"
                className="form-control form-control-sm"
                data-kt-table-widget-4="search"
                id="searchClient"
                // value={searchTerm}
                onChange={e => handleSearch(e.target.value)}
                placeholder="Search"
              />
            </div>

            <div style={{ marginRight: 20 }}>
              <div className="dataTables_length position-relative my-1">
                <label className="w-125px">
                  <select
                    onChange={e => setPerPage(e.target.value)}
                    //data-control="select2"
                    className="form-select form-select-sm form-select-solid"
                    defaultValue={5}
                    id="perPage"

                  >
                    <option value={5} selected="">
                      5 Records
                    </option>
                    <option value={10}>10 Records</option>
                    <option value={25}>25 Records</option>
                    <option value={50}>50 Records</option>
                    <option value={100}>100 Records</option>
                  </select>
                </label>
              </div>
            </div>
            {currentDatabaseInfo?.ref_type != "dbaas_node" ? (<>
              <a onClick={openDrawer} className="btn btn-sm btn-primary my-1 sphide">
                + Add Database
              </a>
            </>) : ('')}
          </div>
        </div>
        <div className="card-body p-0">
          <div className="table-responsive">
            <table
              className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
              id="kt_security_license_usage_table"
            >
              <thead className="border-gray-200 fs-5 fw-bold bg-secondary">
                <tr>
                  {/* <th className="w-50px ps-9"></th> */}
                  <th className="w-150px text-start ps-9">Database</th>
                  <th className="w-50px ps-9"></th>
                  <th className="text-center pe-5 w-150px">Created_at</th>
                  {currentDatabaseInfo?.ref_type != "dbaas_node" ? (<>
                    <th className="w-150px ps-9" />
                  </>) : ('')}
                </tr>
              </thead>
              <tbody id="public-IP-list" className="fw-6 fw-bold text-gray-600">
                {dummyDatabaseData != null ? (<>
                  <PaginatedItems itemsPerPage={perPage} ProductData={dummyDatabaseData} />
                </>) : ('')}
              </tbody>
            </table>
          </div>
        </div>
        <div
          id="database-modal"
          className="bg-body shadow drawer drawer-end"
          data-kt-drawer="true"
          data-kt-drawer-name="chat"
          data-kt-drawer-activate="true"
          data-kt-drawer-direction="end"
          data-kt-drawer-close="#close_ssh"
          data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
          style={{ maxWidth: '70%', important: true }}

        >
          {/*begin::Messenger*/}
          <div className="card rounded-0 w-100 border-0" id="kt_drawer_chat_messenger">
            {/*begin::Card header*/}
            <div className="card-header pe-5" id="kt_drawer_chat_messenger_header">
              {/*begin::Title*/}
              <div className="card-title">
                {/*begin::User*/}
                <div className="d-flex justify-content-center flex-column me-3">
                  <a

                    className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1"
                  >
                    Add New Database
                  </a>
                </div>
                {/*end::User*/}
              </div>
              {/*end::Title*/}
              {/*begin::Card toolbar*/}
              <div className="card-toolbar">
                {/*begin::Close*/}
                <div
                  onClick={openDrawer}
                  className="btn btn-sm btn-icon btn-active-light-primary"
                  id="close_ssh"
                >
                  {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                  <span className="svg-icon svg-icon-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.5"
                        x={6}
                        y="17.3137"
                        width={16}
                        height={2}
                        rx={1}
                        transform="rotate(-45 6 17.3137)"
                        fill="black"
                      />
                      <rect
                        x="7.41422"
                        y={6}
                        width={16}
                        height={2}
                        rx={1}
                        transform="rotate(45 7.41422 6)"
                        fill="black"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                </div>
                {/*end::Close*/}
              </div>
              {/*end::Card toolbar*/}
            </div>
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className="" id="">
              {/*begin::Form*/}
              <form id="database-form" className="form">
                {/*begin::Modal body*/}
                <div className="modal-body ">
                  {/*begin::Scroll*/}
                  <div
                    className="scroll-y me-n7 pe-7"
                    id="kt_modal_create_api_key_scroll"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                    data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                    data-kt-scroll-offset="300px"
                  >
                    {/*begin::Input group*/}
                    <div className="mb-5 fv-row">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2"> Database Name</label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        id="databases_name"
                        placeholder="Enter Database Name"
                        onKeyPress={handleKeyPress}
                        onChange={handleInputChange}
                      />
                      {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                  </div>
                  {/*end::Scroll*/}
                </div>
                {/*end::Modal body*/}
                {/*begin::Modal footer*/}
                <div className="modal-footer ">
                  {/*begin::Button*/}
                  <a
                    onClick={(e) => createDatabase(e)}
                    className="btn btn-primary w-100"
                    id="create-database-btn"
                  >
                    <span className="indicator-label"> + Add Database</span>
                  </a>
                  {/*end::Button*/}
                </div>
                {/*end::Modal footer*/}
              </form>
              {/*end::Form*/}
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Messenger*/}
        </div>
        <div
          id="databasePemission-modal"
          className="bg-body shadow drawer drawer-end"
          data-kt-drawer="true"
          data-kt-drawer-name="chat"
          data-kt-drawer-activate="true"
          data-kt-drawer-direction="end"
          data-kt-drawer-close="#close_ssh"
          data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
          style={{ maxWidth: '70%', important: true }}

        >
          {/*begin::Messenger*/}
          <div className="card rounded-0 w-100 border-0" id="kt_drawer_chat_messenger">
            {/*begin::Card header*/}
            <div className="card-header pe-5" id="kt_drawer_chat_messenger_header">
              {/*begin::Title*/}
              <div className="card-title">
                {/*begin::User*/}
                <div className="d-flex justify-content-center flex-column me-3">
                  <a

                    className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1"
                  >
                    Update Permission
                  </a>
                </div>
                {/*end::User*/}
              </div>
              {/*end::Title*/}
              {/*begin::Card toolbar*/}
              <div className="card-toolbar">
                {/*begin::Close*/}
                <div
                  onClick={openPermissionDrawer}
                  className="btn btn-sm btn-icon btn-active-light-primary"
                  id="close_ssh"
                >
                  {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                  <span className="svg-icon svg-icon-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.5"
                        x={6}
                        y="17.3137"
                        width={16}
                        height={2}
                        rx={1}
                        transform="rotate(-45 6 17.3137)"
                        fill="black"
                      />
                      <rect
                        x="7.41422"
                        y={6}
                        width={16}
                        height={2}
                        rx={1}
                        transform="rotate(45 7.41422 6)"
                        fill="black"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                </div>
                {/*end::Close*/}
              </div>
              {/*end::Card toolbar*/}
            </div>
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className="" id="">

              {/*begin::Modal body*/}
              <div className=" ">
                {/*begin::Scroll*/}
                <div
                  className="scroll-y me-n7 pe-7"
                  id="kt_modal_create_api_key_scroll"
                  data-kt-scroll="true"
                  data-kt-scroll-activate="{default: false, lg: true}"
                  data-kt-scroll-max-height="auto"
                  data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                  data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                  data-kt-scroll-offset="300px"
                >
                  {/*begin::Input group*/}
                  <div className="p-5" id="addPerm-sec">
                    <form id="databasePermission-form" className="form">
                      <div className="row">
                        <div className="col-6">
                          <div className="mb-5 fv-row">
                            {/*begin::Label*/}
                            <label className="required fs-5 fw-bold mb-2"> Database Permission</label>
                            {/*end::Label*/}
                            {/*begin::Input*/}
                            <input
                              type="hidden"
                              className="form-control"
                              name="db_name"
                              id="pdb_name"
                            />
                            <select className='form-select' id="dbUserName" name="db_user" tabIndex={-1} aria-hidden="true">
                              <option value="" disabled selected>Select database user</option>
                              {userArr && userArr.map((value2, index) => (<>
                                <option value={value2.db_user}>{value2.db_user}</option>
                              </>
                              ))}
                            </select>
                            {/*end::Input*/}
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="mb-5 fv-row">
                            {/*begin::Label*/}
                            <label className="required fs-5 fw-bold mb-2"> Select Permission</label>
                            {/*end::Label*/}
                            {/*begin::Input*/}

                            <select className='form-select' id="userPermission" name="permission" tabIndex={-1} aria-hidden="true">
                              <option value="" disabled selected>Select permission</option>
                              {permissionArr && permissionArr.map((value2, index) => (<>
                                <option value={value2}>{value2}</option>
                              </>
                              ))}
                            </select>
                            {/*end::Input*/}
                          </div>
                        </div>
                      </div>
                      <div className="mb-5 fv-row">
                        <a className="btn btn-primary me-3" id="create-permission-btn" onClick={createNewPermission}>+Add Permission</a>
                        <a className="btn btn-danger d-none" onClick={togglePermSec}>Cancel</a>
                      </div>
                    </form>
                  </div>
                  {/*end::Input group*/}
                  <div className="mb-5 mt-5 text-right" style={{ float: "right" }}>
                    <a className="btn btn-primary d-none" id="addPerm-btn" onClick={togglePermSec}>+Add New</a>
                  </div>
                  <div className="table-responsive w-100">
                    <table
                      className="table table-striped table-flush align-middle table-row-bordered table-row-solid gy-4"
                      id="kt_security_license_usage_table"
                    >
                      <thead className="border-gray-200 fs-5 fw-bold bg-secondary">
                        <tr>
                          <th className="w-50px ps-9"></th>
                          <th className="w-150px ps-9">User</th>
                          <th className="w-150px ps-9">Permission</th>
                          <th className="w-150px ps-9" />
                        </tr>
                      </thead>
                      <tbody className="fw-6 fw-bold text-gray-600">
                        {databasePermissions != null ? (<>
                          {databasePermissions.map((value, index) => (<>

                            <tr>
                              <td></td>
                              <td className="text-start" style={{ paddingLeft: "20px" }}>
                                <div className=" align-items-center">
                                  {" "}
                                  <div className="d-flex justify-content-start flex-column">
                                    {" "}
                                    <a className="text-dark fw-bold text-hover-primary fs-6 sphide">
                                      {value.db_user}
                                    </a>{" "}
                                  </div>{" "}
                                </div>
                              </td>
                              <td className="text-center">
                                <select className='form-select' id={"newpermission_field" + value.db_user}>
                                  {permissionArr && permissionArr.map((value2, index) => (<>
                                    {value.permission == value2 ? (<>
                                      <option value={value2} selected={true}>{value2}</option>
                                    </>) : (<>
                                      <option value={value2}>{value2}</option>
                                    </>)}

                                  </>
                                  ))}
                                </select>
                              </td>
                              <td className="text-center">
                                <a
                                  onClick={(e) => updateDbPermission(value.db_name, value.db_user)}
                                  id={"update-permission" + value.db_user + "-btn"}

                                  className="btn btn-sm btn-primary sphide"
                                >
                                  update
                                </a>
                              </td>
                            </tr>
                          </>))}
                        </>) : ('')}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/*end::Scroll*/}
              </div>
              {/*end::Modal body*/}
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Messenger*/}
        </div>
      </div>
    </>
  );
}


export default Database 
