import {React} from 'react';

export default function Heading(props){

    if(props && props.title){
        return(
            <>
             <div style={{ paddingBottom: 30, fontSize: 17 }}>
                <strong>{props.title}</strong>
             </div>
            </>
        )
    }
   
}