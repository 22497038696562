import React, { useEffect, useRef, useState } from "react";    
import { Link, useNavigate } from "react-router-dom"; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import swal from "sweetalert"; 
import copy from "copy-to-clipboard";
import Charts from "./tabs/Charts";
import Ram from "./tabs/Ram";
import Disk from "./tabs/Disk";
import Network from "./tabs/Network";
import Incidents from "./tabs/Incidents";
import AlertRules from "./tabs/AlertRules";
import Destroy from "./tabs/Destroy";
import UseApi from "../../../../CustomHooks/useApi";
import Cpu from "./tabs/Cpu"; 
import Dashboard from "./tabs/Dashboard";
import TableLoader from "../../Loaders/TableLoader";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
const ManageMonitoring=(props)=>{  
  
  var query = window.location.href; 
    var vars = query.split("/"); 
    var id=vars[4];    

    const [codeString, setCodeString]=useState("");
  const copyToClipboard=(copyid)=> {   
       if(copyid=="ip"){
        var eleText = document.querySelector("#copy_"+copyid); 
        if(eleText){ 
          var copyEle=eleText.innerHTML;
        copy(copyEle);   
        eleText.innerHTML="Copied"; 
         setTimeout(() => { 
          eleText.innerHTML=copyEle;
        }, 2000);
      }
       }else{
    var eleText = document.querySelector("#copy_"+copyid);
    var ele = document.querySelector("#copy_btn_"+copyid);
    if(ele && eleText){
      var copyText=ele.innerHTML;
      var copyEle=eleText.innerHTML;
    copy(copyEle);   
    ele.innerHTML="Copied"; 
     setTimeout(() => { 
      ele.innerHTML=copyText;
    }, 2000);
  }
} 
  }

  const copyCodeToClipboard=(copyid)=> {    
     var eleBtn = document.querySelector("#copy_btn_code"); 
     if(eleBtn && codeString!=""){ 
       var copyEle=eleBtn.innerHTML;
     copy(codeString);   
     eleBtn.innerHTML="Copied"; 
      setTimeout(() => { 
        eleBtn.innerHTML=copyEle;
     }, 2000);   
}
  }
  
 const [monitorData, setMonitorData]=useState(null);
  const getMonitoring=async()=>{   
    const data=await UseApi('get','monitoring/server/'+id,'');
    if(data){
      if(data.servers){
        var code="wget -N --no-check-certificate https://api.microhost.com/monitoring/install.sh && chmod 755 install.sh && chmod +x install.sh && ./install.sh "+data.servers.key+" https://api.microhost.com/monitoring";
         setCodeString(code);
      }
      setMonitorData(data.servers);
    }
    //console.log(data); 
  } 
  useEffect(()=>{
    getMonitoring();
  },[])

  if(monitorData!=null){
    if(monitorData.status=="OK"){
  return(
        <>  
        <div className="p-2">
        <div className="card mb-6 mb-xl-9 ">
  <div className="card-body pt-9 pb-0 ">
    {/*begin::Details*/}
    <div className="d-flex flex-wrap flex-sm-nowrap mb-6">
      {/*begin::Image*/}
      <div
        className="d-flex flex-center flex-shrink-0 bg-light rounded w-55px h-55px w-lg-55px h-lg-55px me-7 mb-4"
        id="imageDist"
      > 
      {monitorData.os?(<><span className={"fonticon_"+monitorData.os.base} /></>):('')}
          
        
        
      </div>
      {/*end::Image*/}
      {/*begin::Wrapper*/}
      <div className="flex-grow-1">
        {/*begin::Head*/}
        <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
          {/*begin::Details*/}
          <div className="d-flex flex-column">
            {/*begin::Status*/}
            <div className="d-flex align-items-center mb-1 d-none" id="updateHost">
  
  {/*end::Title*/}
            </div>
            <div className="d-flex align-items-center mb-1" id="showHost">
              <a 
              style={{cursor: "pointer"}}
                className="text-gray-800 text-hover-primary fs-2 fw-bolder me-3"
                id="hostname"
                 
              >
                Monitoring for {monitorData.name}
              </a>
              {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen055.svg*/}
 
  {/*end::Svg Icon*/}
            </div>
            {/*end::Status*/}
            {/*begin::Description*/}
            <div
              className="d-flex flex-wrap fw-bold mb-4 fs-5 text-gray-400"
              
            >
                {monitorData.os?(monitorData.os.base):('')} - {monitorData.os?(monitorData.os.full):('')}
            </div>
            {/*end::Description*/}
          </div>
          {/*end::Details*/}
          {/* <span id="action_process_cloud_84469"></span> */}
          {/*begin::Actions*/}
          <div className="" id="console">
            {/*begin::Input*/}
          
            <div className=" zoom me-5 btn btn-sm btn-light-primary" onClick={(e)=>copyToClipboard("ip")} >
              Primary IPv4:{" "}
              <span id="copy_ip" className="fw-bold">
              {monitorData.primary_ipv4}
              </span>
            </div>
            {/*end::Input*/}
            {monitorData.status?(<>
            {monitorData.status=="OK"?(<>
              <a className="btn btn-sm btn-success me-3">Active</a>
            </>):(<>
              <a className="btn btn-sm btn-warning me-3">Pending</a>
            </>)}
            </>):('')}
              
          </div>
          {/*end::Actions*/}
        </div>
        {/*end::Head*/}
      </div>
      {/*end::Wrapper*/}
    </div>
    <div className="separator" />
    {/*begin::Nav*/}
    <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-trakt_charts_widget_1_chartnsparent fs-5 fw-bolder">
      {/*begin::Nav item*/}
      <li className="nav-item">
        <a
          className="nav-link text-active-primary py-5 me-6 active"
          data-bs-toggle="tab"
          href="#Dashboard" 
        >
          Dashboard
        </a>
      </li>
      {/*end::Nav item*/} 
       {/*begin::Nav item*/}
       <li className="nav-item">
        <a
          className="nav-link text-active-primary py-5 me-6"
          data-bs-toggle="tab"
          href="#Charts" 
        >
          Charts
        </a>
      </li>
      {/*end::Nav item*/}
        {/*begin::Nav item*/}
        <li className="nav-item">
        <a
          className="nav-link text-active-primary py-5 me-6"
          data-bs-toggle="tab"
          href="#cpu" 
        >
          CPU
        </a>
      </li>
      {/*end::Nav item*/} 
      {/*begin::Nav item*/}
      <li className="nav-item">
        <a
          className="nav-link text-active-primary py-5 me-6"
          data-bs-toggle="tab"
          href="#ram" 
        >
          RAM
        </a>
      </li>
      {/*end::Nav item*/}
      {/*begin::Nav item*/}
      <li className="nav-item">
        <a
          className="nav-link text-active-primary py-5 me-6"
          data-bs-toggle="tab"
          href="#disk"
        >
          Disk
        </a>
      </li>
      {/*end::Nav item*/}
      {/*begin::Nav item*/}
      <li className="nav-item">
        <a
          className="nav-link text-active-primary py-5 me-6"
          data-bs-toggle="tab"
          href="#network"
        >
          Network
        </a>
      </li>
      {/*end::Nav item*/}
      {/*begin::Nav item*/}
      <li className="nav-item">
        <a
          className="nav-link text-active-primary py-5 me-6"
          onclick="resizeDiskRam(1)"
          data-bs-toggle="tab"
          href="#incidents"
        >
          Incidents
        </a>
      </li>
      {/*end::Nav item*/}
      {/*begin::Nav item*/}
      <li className="nav-item">
        <a
          className="nav-link text-active-primary py-5 me-6"
          data-bs-toggle="tab"
          href="#alertRules"
        >
          Alert Rules
        </a>
      </li>
      {/*end::Nav item*/}
      {/*begin::Nav item*/}
      <li className="nav-item">
        <a
          className="nav-link text-active-primary py-5 me-6"
          data-bs-toggle="tab"
          href="#destroy"
        >
          Destroy
        </a>
      </li>
      {/*end::Nav item*/}
     
    </ul>
    {/*end::Nav*/}
  </div>
</div>
 
       
<div className="tab-content" id="myTabContent">
<div className="tab-pane fade show active" id="Dashboard" role="tabpanel">
    {/*begin::Charts*/}
    {monitorData.charts?(<>
      <Dashboard chartInfo={monitorData} />
    </>):('')}
     
    {/*end::Charts*/}
  </div>
  {/*end::Post*/} 
  <div className="tab-pane fade" id="Charts" role="tabpanel">
    {/*begin::Charts*/}
    {monitorData.charts?(<>
      <Charts chartInfo={monitorData.charts} />
    </>):('')}
     
    {/*end::Charts*/}
  </div>
  {/*end::Post*/} 
  <div className="tab-pane fade" id="cpu" role="tabpanel">
    {/*begin::Charts*/}
    {monitorData.charts?(<>
      <Cpu chartInfo={monitorData.charts} />
    </>):('')}
     
    {/*end::Charts*/}
  </div>
  {/*end::Post*/} 
  <div className="tab-pane fade" id="ram" role="tabpanel">
    {/*begin::ram*/} 
    {monitorData.charts?(<>
      <Ram chartInfo={monitorData.charts} />
    </>):('')}
    {/*end:: ram*/}
  </div>
  <div className="tab-pane fade" id="disk" role="tabpanel">
    {/*begin:: disk*/}
    {monitorData.charts?(<>
      <Disk chartInfo={monitorData.charts} diskData={monitorData}  />
    </>):('')}
     {/*end:: disk*/}
  </div>
  <div className="tab-pane fade" id="network" role="tabpanel">
    {/*begin:: network*/}
    {monitorData.charts?(<>
      <Network chartInfo={monitorData.charts}  networkData={monitorData} />
    </>):('')}
    {/*end:: network*/}
  </div>
  <div className="tab-pane fade" id="incidents" role="tabpanel">
    {/*begin:: incidents*/}
     <Incidents />
    {/*end:: incidents*/}
  </div>
  <div className="tab-pane fade" id="alertRules" role="tabpanel">
    {/*begin:: alertRules*/}
      <AlertRules alertInfo={monitorData} updateMonitoring={getMonitoring}/>
    {/*end:: alertRules*/}
  </div>
   
  <div className="tab-pane fade" id="destroy" role="tabpanel">
    <Destroy serverid={id}/>
  </div>
</div>
        </div>  
    </>)
    }else{
      return(
        <>  
        <div className="p-2">
        <div className="card mb-6 mb-xl-9 ">
  <div className="card-body pt-9 pb-0 ">
    {/*begin::Details*/}
    <div className="d-flex flex-wrap flex-sm-nowrap mb-6">
      {/*begin::Image*/}
      <div
        className="d-flex flex-center flex-shrink-0 bg-light rounded w-55px h-55px w-lg-55px h-lg-55px me-7 mb-4"
        id="imageDist"
      > 
      {/*begin::Svg Icon | path: assets/media/icons/duotune/social/soc002.svg*/} 
  <svg viewBox="-4 0 26 16" xmlns="http://www.w3.org/2000/svg" fill="none">
  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
  <g id="SVGRepo_iconCarrier">
    <path
      fill="#202020"
      d="M13.338 12.033c-.1-.112-.146-.319-.197-.54-.05-.22-.107-.457-.288-.61v-.001a.756.756 0 00-.223-.134c.252-.745.153-1.487-.1-2.157-.312-.823-.855-1.54-1.27-2.03-.464-.586-.918-1.142-.91-1.963.014-1.254.138-3.579-2.068-3.582-.09 0-.183.004-.28.012-2.466.198-1.812 2.803-1.849 3.675-.045.638-.174 1.14-.613 1.764-.515.613-1.24 1.604-1.584 2.637-.162.487-.24.984-.168 1.454-.023.02-.044.041-.064.063-.151.161-.263.357-.388.489-.116.116-.282.16-.464.225-.183.066-.383.162-.504.395v.001a.702.702 0 00-.077.339c0 .108.016.217.032.322.034.22.068.427.023.567-.144.395-.163.667-.061.865.102.199.31.286.547.335.473.1 1.114.075 1.619.342l.043-.082-.043.082c.54.283 1.089.383 1.526.284a.99.99 0 00.706-.552c.342-.002.717-.146 1.318-.18.408-.032.918.145 1.503.113a.806.806 0 00.068.183l.001.001c.227.455.65.662 1.1.627.45-.036.928-.301 1.315-.762l-.07-.06.07.06c.37-.448.982-.633 1.388-.878.203-.123.368-.276.38-.499.013-.222-.118-.471-.418-.805z"
    />
    <path
      fill="#F8BF11"
      d="M13.571 12.828c-.007.137-.107.24-.29.35-.368.222-1.019.414-1.434.918-.362.43-.802.665-1.19.696-.387.03-.721-.13-.919-.526v-.002c-.123-.233-.072-.6.031-.987s.251-.785.271-1.108v-.001c.02-.415.044-.776.114-1.055.07-.28.179-.468.373-.575a.876.876 0 01.027-.014c.022.359.2.725.514.804.343.09.838-.204 1.047-.445l.122-.004c.184-.005.337.006.495.143v.001c.121.102.179.296.229.512.05.217.09.453.239.621.287.32.38.534.371.672zM6.592 13.843v.003c-.034.435-.28.672-.656.758-.377.086-.888 0-1.398-.266-.565-.3-1.237-.27-1.667-.36-.216-.045-.357-.113-.421-.238-.064-.126-.066-.345.071-.72v-.001l.001-.002c.068-.209.018-.438-.015-.653-.033-.214-.049-.41.024-.546l.001-.001c.094-.181.232-.246.403-.307.17-.062.373-.11.533-.27l.001-.001h.001c.148-.157.26-.353.39-.492.11-.117.22-.195.385-.196h.005a.61.61 0 01.093.008c.22.033.411.187.596.437l.533.971v.001c.142.296.441.622.695.954.254.333.45.666.425.921z"
    />
    <path
      fill="#D6A312"
      d="M9.25 4.788c-.043-.084-.13-.164-.28-.225-.31-.133-.444-.142-.617-.254-.28-.181-.513-.244-.706-.244a.834.834 0 00-.272.047c-.236.08-.392.25-.49.342-.02.019-.044.035-.104.08-.06.043-.15.11-.28.208-.117.086-.154.2-.114.332.04.132.167.285.4.417h.001c.145.085.244.2.358.291a.801.801 0 00.189.117c.072.031.156.052.26.058.248.015.43-.06.59-.151.16-.092.296-.204.452-.255h.001c.32-.1.548-.301.62-.493a.324.324 0 00-.008-.27z"
    />
    <path
      fill="#202020"
      d="M8.438 5.26c-.255.133-.552.294-.869.294-.316 0-.566-.146-.745-.289-.09-.07-.163-.142-.218-.193-.096-.075-.084-.181-.045-.178.066.008.076.095.117.134.056.052.126.12.211.187.17.135.397.266.68.266.284 0 .614-.166.816-.28.115-.064.26-.179.379-.266.09-.067.087-.147.162-.138.075.009.02.089-.085.18-.105.092-.27.214-.403.283z"
    />
    <path
      fill="#ffffff"
      d="M12.337 10.694a1.724 1.724 0 00-.104 0h-.01c.088-.277-.106-.48-.621-.713-.534-.235-.96-.212-1.032.265-.005.025-.009.05-.011.076a.801.801 0 00-.12.054c-.252.137-.389.386-.465.692-.076.305-.098.674-.119 1.09-.013.208-.099.49-.186.79-.875.624-2.09.894-3.122.19-.07-.11-.15-.22-.233-.328a13.85 13.85 0 00-.16-.205.65.65 0 00.268-.05.34.34 0 00.186-.192c.063-.17 0-.408-.202-.68-.201-.273-.542-.58-1.043-.888-.368-.23-.574-.51-.67-.814-.097-.305-.084-.635-.01-.96.143-.625.51-1.233.743-1.614.063-.046.023.086-.236.567-.232.44-.667 1.455-.072 2.248.016-.564.15-1.14.377-1.677.329-.747 1.018-2.041 1.072-3.073.029.02.125.086.169.11.126.075.221.184.344.283a.85.85 0 00.575.2c.24 0 .427-.079.582-.168.17-.096.304-.204.433-.245.27-.085.486-.235.608-.41.21.83.7 2.027 1.014 2.611.167.31.5.969.643 1.762.091-.002.191.01.299.038.375-.973-.319-2.022-.636-2.314-.128-.124-.135-.18-.07-.177.343.304.795.917.96 1.608.075.315.09.646.01.973.04.017.08.034.12.054.603.293.826.548.719.897z"
    />
    <path
      fill="#E6E6E6"
      d="M8.04 8.062c-.556.002-1.099.251-1.558.716-.46.464-.814 1.122-1.018 1.888l.061.038v.004c.47.298.805.598 1.012.878.219.296.316.584.223.834a.513.513 0 01-.27.283l-.041.015c.074.097.146.197.213.3.944.628 2.042.396 2.867-.172.08-.278.153-.536.163-.698.021-.415.042-.792.124-1.12.082-.33.242-.63.544-.795.017-.01.034-.015.051-.023a.756.756 0 01.022-.094c-.242-.622-.591-1.14-1.01-1.5-.42-.36-.897-.551-1.382-.554zm2.37 2.155l-.002.005v-.002l.001-.004z"
    />
    <path
      fill="#ffffff"
      d="M9.278 3.833a1.05 1.05 0 01-.215.656 4.119 4.119 0 00-.218-.09l-.127-.045c.029-.035.085-.075.107-.127a.669.669 0 00.05-.243l.001-.01a.673.673 0 00-.035-.236.434.434 0 00-.108-.184.223.223 0 00-.156-.07H8.57a.228.228 0 00-.151.06.434.434 0 00-.122.175.676.676 0 00-.05.243v.01a.718.718 0 00.009.14 1.773 1.773 0 00-.354-.12 1.196 1.196 0 01-.01-.133v-.013a1.035 1.035 0 01.088-.447.793.793 0 01.25-.328.554.554 0 01.346-.123h.006c.125 0 .232.036.342.116a.78.78 0 01.257.324c.063.138.094.273.097.433l.001.012zM7.388 3.997a1.05 1.05 0 00-.277.125.623.623 0 00.002-.15v-.008a.651.651 0 00-.048-.192.37.37 0 00-.096-.141.158.158 0 00-.119-.045c-.042.004-.077.024-.11.065a.372.372 0 00-.07.156.626.626 0 00-.013.205v.008a.634.634 0 00.048.193.367.367 0 00.116.156l-.102.08-.078.056a.706.706 0 01-.16-.24c-.053-.12-.082-.24-.09-.381v-.001a1.071 1.071 0 01.045-.39.668.668 0 01.167-.292.359.359 0 01.264-.118c.084 0 .158.028.235.09a.68.68 0 01.199.271c.053.12.08.24.089.382v.001c.003.06.003.115-.002.17z"
    />
    <path
      fill="#202020"
      d="M7.806 4.335c.01.034.065.029.097.045.027.014.05.045.08.046.03.001.076-.01.08-.04.005-.038-.052-.063-.088-.077-.047-.019-.107-.028-.151-.003-.01.005-.021.018-.018.03zM7.484 4.335c-.01.034-.065.029-.096.045-.028.014-.05.045-.081.046-.03.001-.076-.01-.08-.04-.005-.038.052-.063.088-.077.047-.019.108-.028.152-.003.01.005.02.018.017.03z"
    />
  </g>
</svg> 
  {/*end::Svg Icon*/}  
           
      </div>
      {/*end::Image*/}
      {/*begin::Wrapper*/}
      <div className="flex-grow-1">
        {/*begin::Head*/}
        <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
          {/*begin::Details*/}
          <div className="d-flex flex-column">
            {/*begin::Status*/}
            <div className="d-flex align-items-center mb-1 d-none" id="updateHost">
  
  {/*end::Title*/}
            </div>
            <div className="d-flex align-items-center mb-1" id="showHost">
              <a 
              style={{cursor: "pointer"}}
                className="text-gray-800 text-hover-primary fs-2 fw-bolder me-3"
                id="hostname" >
                Monitoring for {monitorData.name}
              </a>
              
              {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen055.svg*/}
 
  {/*end::Svg Icon*/}
            </div>
             {/*begin::Description*/}
             <div
              className="d-flex flex-wrap fw-bold mb-4 fs-5 text-gray-400"
              
            >
             Please run the following command as root to install or reinstall the Monitoring agent on server <span className="text-primary">&nbsp; {monitorData.name}</span>     
               </div>
            {/*end::Description*/}
            {/*end::Status*/} 
          </div>
          {/*end::Details*/}
          {/* <span id="action_process_cloud_84469"></span> */}
          {/*begin::Actions*/}
          <div className="" id="console">
            {/*begin::Input*/}
          
             
            {/*end::Input*/}
            {monitorData.status?(<>
            {monitorData.status=="OK"?(<>
              <a className="btn btn-sm btn-success me-3">Active</a>
            </>):(<>
              <a className="btn btn-sm btn-warning me-3">Pending</a>
            </>)}
            </>):('')}
              
          </div>
          {/*end::Actions*/}
        </div>
        {/*end::Head*/}
      </div>
      {/*end::Wrapper*/}
    </div> 
  </div>
</div>
 
<div className="py-5">
  {/*begin::Highlight*/}
  <div className="highlight">
    <button
      id="copy_btn_code"
      onClick={copyCodeToClipboard}
      className="highlight-copy btn"
      data-bs-toggle="tooltip"
      title=""
      data-bs-original-title="Copy code"
    >
      copy
    </button>
    <div className="highlight-code">
      <pre className=" language-html" tabIndex={0}>
        <code className=" language-html">
        
          <span className="token tag">
            <span className="token tag">
            <span className="token punctuation">"</span>
            <span id="copy_code"  dangerouslySetInnerHTML={{ __html: codeString }} />
            <span className="token punctuation">"</span>
            </span> 
          </span>
      
        
        </code>
      </pre>
    </div>
  </div>
  {/*end::Highlight*/}
</div>

 
        </div>  
    </>)
    }
  }else{
    return(<><TableLoader/><TopBarHeaderLine/></>);
  }
}
 

export default ManageMonitoring;