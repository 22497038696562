import React, { useEffect, useState } from 'react';
import TableLoader from '../../../../Loaders/TableLoader';

const GrafanaViewer = (props) => {
  // Function to get a cookie by its name
  var query = window.location.href; 
  var vars = query.split("/"); 
  var id=vars[4]; 
   const [isLoading, setIsLoading] = useState(true);
  const [dataForm, setDataFrom]= useState('1h');

  useState(()=>{
      if(props && props.dataTime){
        setDataFrom(props.dataTime);
      }
  },[props.dataTime])

  const getCookie = (name) => {
    const cookieArray = document.cookie.split('; ');
    const cookie = cookieArray.find((row) => row.startsWith(name + '='));
    return cookie ? cookie.split('=')[1] : null;
  };

  // Function to set the iframe URL
  const setIframeSrc = () => {
    const apiKey = getCookie('microhost_api_auth');
    if (apiKey) {
      const iframeUrl = `https://mon.utho.com/d/Instance/instance?orgId=1&var-host_id=${id}&kiosk&theme=light&from=now-${dataForm}&to=now&refresh=10s&apikey=${apiKey}`;
      document.getElementById('grafanaframe').src = iframeUrl;
      setTimeout(()=>{
        setIsLoading(false);
      },1000)
     } else {
      console.error('API key not found');
    }
  };

  useEffect(() => {
    // Set the iframe source after the component is mounted
    setIframeSrc();

    // Add event listener for blocking keys
    const grafanaFrame = document.getElementById('grafanaframe');
    const keydownListener = (event) => {
      // Block user from exiting Grafana UI easily (esc and F keys)
      if (event.keyCode === 27 || event.keyCode === 70) {
        event.preventDefault();
        event.stopPropagation();
        return false;
      }
    };
    if(grafanaFrame && grafanaFrame.contentWindow){
    grafanaFrame.contentWindow.addEventListener('keydown', keydownListener, true);
      }
    // Clean up the event listener when the component unmounts
    

    return () => {
      if(grafanaFrame && grafanaFrame.contentWindow){
      grafanaFrame.contentWindow.removeEventListener('keydown', keydownListener, true);
      }
    };
  }, [dataForm]); // Empty dependency array ensures this effect runs once after initial render

  return (
    <div style={{ height: '955px', margin: 0, padding: 0, overflow: 'hidden' }}>
      {isLoading && <TableLoader />}
      <iframe
      
        id="grafanaframe"
        title="Grafana Viewer"
        style={{ border: 'none', width: '100%', height: '100%' }}
      >
        {/* Iframe content will be loaded here */}
      </iframe>
    </div>
  );
};

export default GrafanaViewer;
