import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import UseDevApi from "../../../../../CustomHooks/useDevApi";
import UseApi from "../../../../../CustomHooks/useApi";
import TopBarHeader from "../../../../../CustomHooks/TopBarHeader";
import TableLoader from "../../../Loaders/TableLoader";
import TopBarHeaderLine from "../../../../../CustomHooks/TopBarHeaderLine";
import AccessDenied from "../../../AccessDenied";

const StorageList = () => {
  const [dataFetched, setDataFetched] = useState(false);
  const [access, setAccess] = useState(true);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [activePage, setActivePage] = useState(0);
  const [perPage, setPerPage] = useState(5);
  const [objectData, setObjectData] = useState(null);
  const [dummyObjectData, setDummyObjectData] = useState(null);
  const dataFetchedRef = useRef(false);
  const loaderFunc = () => {
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if (loader && no_record) {
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");

      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
  };
  const getObjectStorage = async () => {
    const data = await UseApi("get", "objectstorage/innoida/bucket", "");
    //  console.log(data);
    if (data) {
      // console.log(data.buckets);
      if ("status" in data) {
        if (data.status == "error" && data.message == "Permission denied.") {
          toast.dismiss();
          const dt = toast.loading("Object Storage loading...");
          toast.update(dt, {
            render: data.message,
            type: "warning",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setAccess(false);
          setObjectData([]);
          setDummyObjectData([])
          loaderFunc();
          return;
        } else if (data.status == "error") {
          toast.dismiss();
          // const dt = toast.loading("Object Storage loading...");
          // toast.update(dt, {
          //   render: data.message,
          //   type: "warning",
          //   isLoading: false,
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });
          setObjectData([]);
          setDummyObjectData([])
          loaderFunc();
          return;
        }
      }
      // console.log(data.buckets.length);
      if (data.buckets.length > 0) {
        //  console.log(data.buckets);
        setObjectData(data.buckets);
        setDummyObjectData(data.buckets)
        setPageCount(Math.ceil(data.buckets.length / perPage));
        toast.dismiss();
      }
    }
  };
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getObjectStorage();
  }, []);

  function convertTimestamp(timestamp) {
    const date = new Date(timestamp);

    // Format the date
    const formattedDate = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short',
        timeZone: 'UTC'
    }).format(date);

    return formattedDate.replace('GMT', '(UTC)');
}

  function ProductInfo({ productdata }) {
    return (
      <>
        {productdata != null && Object.keys(productdata).length != 0 ? (
          productdata &&
          productdata.map((value, index) => (
            <tr>
              {/* <td></td> */}
              <td className="text-start ps-9">
                <div className=" align-items-end">
                  {" "}
                  <div className="d-flex justify-content-end flex-column">
                    {" "}
                    <a className="text-gray-700 fw-bold text-hover-primary fs-6">
                      {value.name}
                    </a>{" "}
                  </div>{" "}
                </div>
              </td>
              <td></td>
              <td className="text-start">
                <div className="d-flex align-items-center sphide ">
                  {" "}
                  <div className="symbol symbol-30px me-5">
                    {" "}
                    {value.dclocation && value.dclocation ? (
                      <>
                        <img
                          src={"/img/flag_" + value.dclocation.dccc + ".png"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </div>{" "}
                  <div className="d-flex justify-content-start flex-column">
                    {" "}
                    <a className="text-gray-700 fw-bold text-hover-primary fs-6 text-start">
                      {value.dclocation && value.dclocation ? (
                        <>{value.dclocation.location}</>
                      ) : (
                        ""
                      )}
                    </a>{" "}
                    <span className="text-muted fw-bold text-muted d-block fs-7 text-start">
                      {value.dclocation && value.dclocation ? (
                        <>{value.dclocation.country}</>
                      ) : (
                        ""
                      )}
                    </span>{" "}
                  </div>{" "}
                </div>
              </td>
              <td className="text-center d-none">
                <div className="align-items-center">
                  {" "}
                  <div className="d-flex justify-content-center flex-column">
                    {" "}
                    <a className="text-gray-700 fw-bold text-hover-primary fs-6">
                      {/* {value.size} bytes */}
                    </a>
                  </div>{" "}
                </div>
              </td>
              <td className="text-center">
                <div className="align-items-center">
                  {" "}
                  <div className="d-flex justify-content-center flex-column">
                    {" "}
                    <a className="text-gray-700 fw-bold text-hover-primary fs-6">
                      {value.object_count ? value.object_count : 0}
                    </a>
                  </div>{" "}
                </div>
              </td>
              <td className="text-center">
                <div className="align-items-center">
                  <div className="d-flex justify-content-start flex-column sphide">
                    {value.status == "active" ? (
                      <>
                        <span className="fw-bold fs-6 text-gray-700">
                          <div className=" flex-column w-100">
                            <div className=" flex-stack mt-0.5">
                              <span className=" me-2 fs-6 fw-bold">
                                <span className="status-circle" style={{ top: 3 }}></span>
                                Active
                              </span>
                              {/* <span className="status-circle" style={{ top: 4 }} />
                          Active
                        </span> */}
                            </div>
                          </div>
                        </span>{" "}
                      </>
                    ) : (
                      <>
                        {" "}
                        <span className="fw-bold fs-6 text-gray-700">
                          <div className=" flex-column w-100">
                            <div className=" flex-stack mt-0.5">
                              <span className=" me-2 fs-6 fw-bold">
                                <span className="status-circle" style={{ top: 3 }}></span>
                                Active
                              </span>
                              {/* <span className="status-circle" style={{ top: 4 }} />
                          Active
                        </span> */}
                            </div>
                          </div>
                        </span>
                      </>
                    )}
                  </div>{" "}
                </div>
              </td>
              <td className="text-center">
                <div className="align-items-center">
                  {" "}
                  <div className="d-flex justify-content-start flex-column">
                    {" "}
                    <a className="text-gray-700 fw-bold text-hover-primary fs-6 sphide">
                      {(value.created_at)}
                    </a>
                  </div>{" "}
                </div>
              </td>
              <td className="text-center ps-15">
                <div className="sphide">
                  {" "}
                  {value.status != "active" ? (
                    <>
                      <Link
                        to={"/ObjectStorage/" + value.dcslug + "/" + value.name}
                      >
                        <a className="btn btn-primary btn-active-light-primary btn-sm ">
                          {" "}
                          Manage{" "}
                          <span className="svg-icon ms-2 svg-icon-3">
                            {" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              {" "}
                              <rect
                                opacity="0.5"
                                x={18}
                                y={13}
                                width={13}
                                height={2}
                                rx={1}
                                transform="rotate(-180 18 13)"
                                fill="black"
                              />{" "}
                              <path
                                d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                fill="black"
                              />{" "}
                            </svg>{" "}
                          </span>{" "}
                        </a>{" "}
                      </Link>
                    </>
                  ) : (
                    <>
                      <a className="btn btn-primary btn-active-light-primary btn-sm disabled">
                        {" "}
                        Manage{" "}
                        <span className="svg-icon ms-2 svg-icon-3">
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            {" "}
                            <rect
                              opacity="0.5"
                              x={18}
                              y={13}
                              width={13}
                              height={2}
                              rx={1}
                              transform="rotate(-180 18 13)"
                              fill="black"
                            />{" "}
                            <path
                              d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                              fill="black"
                            />{" "}
                          </svg>{" "}
                        </span>{" "}
                      </a>
                    </>
                  )}
                </div>{" "}
              </td>
            </tr>
          ))
        ) : (
          <>
            <tr>
              <td colspan="12" className="dataTables_empty" id="loader">
                {/* <div className="loading-spinner"></div> */}
                {/* <TableLoader /> */}
              </td>
              <td colspan="12" className="d-none" id="no_record" valign="top">
                <div className="d-flex flex-column flex-center">
                  <div className="fs-5 fw-bolder text-dark mb-4">
                    No items found.
                  </div>
                </div>
              </td>
            </tr>
          </>
        )}
      </>
    );
  }

  function PaginatedItems({ itemsPerPage, ProductData }) {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(ProductData.length / itemsPerPage));
    const handlePageClick = (event) => { 
      setActivePage((old) => old = event.selected);
      const newOffset = event.selected * itemsPerPage % ProductData.length;
      setItemOffset(newOffset);
    };
    return (
      <>
        <ProductInfo productdata={ProductData.slice(itemOffset, endOffset)} />
        <td colspan="12" className="ps-3">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            initialPage={activePage}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </td>
      </>
    );
  }

  const handleSearch = (text) => {
    const endOffset = itemOffset + perPage;
    const data= objectData.filter(row => {
     return  Object.values(row).some(value => { 
        if(value!=null){
          if(value.toString().toLowerCase().includes(text.toLowerCase())){
            return value;
          } 
        }
      });
    });
    setDummyObjectData(data.slice(itemOffset, endOffset));;
    setPageCount(Math.ceil(data.length / perPage));
  // }
}

  return (
    <>
      <div
        className="tab-pane fade active show"
        id="storage-tab"
        role="tabpanel"
      >
        <div className="mb-5 mb-xl-12">
          <div className="table-responsive">
            <table
              id="firewallTable"
              className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
            >
              <thead className="bg-secondary">
                <tr className="fw-bold ">
                  {/* <th className="w-20px text-center"></th> */}
                  <th className="w-200px text-start ps-9">Storage</th>
                  <th className="w-150px text-center"></th>
                  <th className="w-225px text-center pe-15">Location</th>
                  <th className="w-150px text-center d-none">Size</th>
                  <th className="w-150px text-center">Objects</th>
                  <th className="w-150px text-center">Status</th>
                  <th className="w-250px text-center">Created_at</th>
                  <th className="w-250px text-center">
                    <div
                      className="position-relative"
                      style={{ marginRight: 20 }}
                    >
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        data-kt-table-widget-4="search"
                        id="searchClient"
                        onChange={(e) => handleSearch(e.target.value)}
                        placeholder="Search"
                      />
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {dummyObjectData != null ? (
                  <>
                    {access == false ? (
                      <>
                        <tr>
                          <td colspan={12}>
                            <AccessDenied />
                          </td>
                        </tr>
                      </>
                    ) : (
                      <>
                        {dummyObjectData.length > 0 ? (
                          <>
                            <PaginatedItems
                              itemsPerPage={perPage}
                              ProductData={dummyObjectData}
                            />
                          </>
                        ) : (
                          <>
                            <tr>
                              <td colSpan={12}>
                                <div className="card-body p-0 mt-10">
                                  {/*begin::Illustration*/}
                                  <div className="text-center px-4 mt-10">
                                    <span className="mw-100 mh-300px">
                                      <svg
                                        fill="#21325B"
                                        viewBox="0 0 34 34"
                                        id="icon"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="234px"
                                        height="234px"
                                      >
                                        <g
                                          id="SVGRepo_bgCarrier"
                                          strokeWidth={0}
                                        />
                                        <g
                                          id="SVGRepo_tracerCarrier"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <g id="SVGRepo_iconCarrier">
                                          <defs>
                                            <style
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  "\n                                .cls-1 {\n                                    fill: none;\n                                }\n                            ",
                                              }}
                                            />
                                          </defs>
                                          <path d="M28,20H26v2h2v6H4V22H14V20H4a2.0023,2.0023,0,0,0-2,2v6a2.0023,2.0023,0,0,0,2,2H28a2.0023,2.0023,0,0,0,2-2V22A2.0023,2.0023,0,0,0,28,20Z" />
                                          <circle cx={7} cy={25} r={1} />
                                          <path d="M30,8H22v6H16v8h8V16h6ZM22,20H18V16h4Zm6-6H24V10h4Z" />
                                          <path d="M18,10H10V2h8ZM12,8h4V4H12Z" />
                                        </g>
                                      </svg>
                                    </span>
                                  </div>
                                  {/*end::Illustration*/}
                                  {/*begin::Wrapper*/}
                                  <div className="card-px text-center py-5 mb-20">
                                    {/*begin::Title*/}
                                    <h1 className="fs-2x fw-bolder mb-5">
                                      Buckets
                                    </h1>
                                    <h2 className="fs-1x fw-bolder mb-5 text-muted">
                                      Seamlessly Scale and Store Data
                                    </h2>
                                    {/*end::Title*/}
                                    {/*begin::Description*/}
                                    <p className="text-gray-400 fs-4 fw-bold mb-10">
                                      Attach fault-tolerant, high-performance
                                      object storage volumes
                                      <br /> to your Cloud Servers or Kubernetes
                                      Clusters
                                    </p>
                                    {/*end::Description*/}
                                    {/*begin::Action*/}
                                    <Link to="/objectStorage/deploy">
                                      <a
                                        href="#"
                                        className="btn btn-primary mb-10"
                                      >
                                        Create bucket
                                      </a>
                                    </Link>
                                    {/*end::Action*/}
                                  </div>
                                  {/*end::Wrapper*/}
                                </div>
                              </td>
                            </tr>
                          </>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <tr>
                      <td colspan={12}>
                        <TableLoader />
                        <TopBarHeaderLine />
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default StorageList;
