// import { React, useEffect, useState } from 'react';
// import ContainerInfo from './ContainerInfo';
// import DeployContainerRegistry from './DeployContainerRegistry';
// import UsePost from '../../../../CustomHooks/usePost';
// import UseCallApi from '../../../../CustomHooks/useCallApi';
// import ManageContainerRegistry from './ManageContainerRegistry';
// import TopBarHeaderLine from '../../../../CustomHooks/TopBarHeaderLine';
// import TableLoader from '../../Loaders/TableLoader';

// export default function CreateRegistry() {

//     const [currentPage, setCurrentPage] = useState('home');
//     const [allData, setAllData] = useState(null)
//     const switchPage = (page) => {
//         setCurrentPage(page);
//     }

//     useEffect(() => {
//         let obj = localStorage.getItem('user_info')
//         let data = JSON.parse(obj)
//         console.log(data);
//         if (data) {
//             console.log("smxksxjks");
//           getAllRegistry(data.id)
//         }
//     }, [])

//     const getAllRegistry = async(id) => {
//         try {
//             let responseData = await UseCallApi('get', `registry/projects`, '')
//             console.log(responseData);
//             if (responseData.status === "success") {
//                 setAllData(responseData.data)
//             } else {
//                 setAllData([])
//             }
//         } catch (error) {
//             setAllData([])
//         }
//     }
//     console.log(allData,allData?.length);
//     return (
//         <>
//             {allData != null ?
//                 allData &&
//                     allData.length <= 0 ?
//                     (<><ContainerInfo switchPage={setAllData} />
//                     </>
//                     )
//                     :
//                     (<><ManageContainerRegistry switchPage={setAllData} />
//                     </>
//                     )
//                 :
//                 <>
//                 <TopBarHeaderLine />
//                 <TableLoader/>
//                 </>
//             }
//         </>
//     )
// }

import { React, useEffect, useState } from 'react';
import ContainerInfo from './ContainerInfo';
import DeployContainerRegistry from './DeployContainerRegistry';
import UsePost from '../../../../CustomHooks/usePost';
import UseCallApi from '../../../../CustomHooks/useCallApi';
import ManageContainerRegistry from './ManageContainerRegistry';
import TopBarHeaderLine from '../../../../CustomHooks/TopBarHeaderLine';
import TableLoader from '../../Loaders/TableLoader';
import ContainerRegistry from './ContainerRegistry';

export default function CreateRegistry() {
    const [loading, setLoading] = useState(true);
    const [allData, setAllData] = useState([]);

    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            try {
                let obj = localStorage.getItem('user_info');
                let data = JSON.parse(obj);
                if (data) {
                    let responseData = await UseCallApi('get', `registry/projects`, '');
                    if (responseData.status === "success") {
                        if (isMounted) {
                            setAllData(responseData.data);
                            setLoading(false);
                        }
                    } else {
                        if (isMounted) {
                            setAllData([]);
                            setLoading(false);
                        }
                    }
                }
            } catch (error) {
                if (isMounted) {
                    setAllData([]);
                    setLoading(false);
                }
            }
        };

        fetchData();

        return () => {
            isMounted = false; // Cleanup to avoid setting state on unmounted component
        };
    }, []);

    return (
        <>
            {loading ? (
                <>
                    <TopBarHeaderLine />
                    <TableLoader />
                </>
            ) : allData.length <= 0 ? (
                <ContainerInfo switchPage={setAllData} />
            ) : (
                <ContainerRegistry switchPage={setAllData} />
            )}
        </>
    );
}
