import localStorage from "local-storage";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UseApi from "../../../CustomHooks/useApi";
import SwitchAccount from "./SwitchAccount";
import Cookies from "js-cookie";
import "./custom.css";
import SwicthPartner from "./SwicthPartner";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import IsSubUserAccount from "../../Body/Products/IsSubUserAccount";
import UsePostData from "../../../CustomHooks/usePostData";
import UseCallApi from "../../../CustomHooks/useCallApi";
import { openInNewTabAndClose } from "../../Body/utils/utils";

export default function Toolbar() {
  var current_url_string = window.location.href;
  const current_url = new URL(current_url_string);
  const current_domain = current_url.hostname;
  const domain = '.' + current_domain; 
  const [isStaffLogin, setIsStaffLogin] = useState(null);
  const [isNewBuild, setIsNewBuild] = useState('no');
  useEffect(() => {
    if (Cookies.get("microhost_admin_api_auth")) {
      setIsStaffLogin(true);
    } else {
      setIsStaffLogin(false);
    }
    // updateBuild()
  }, [])

  const updateBuild = async() => { 
    toast.dismiss()
    const resData = await UseCallApi('post','account/version/update',{build:userData?.build})
    Cookies.set("buildVersion",userData?.build,{ expires: 30, path: '/', domain: "." + current_domain });
    
    if ('caches' in window) {
      caches.keys().then(cacheNames => {
          cacheNames.forEach(cacheName => {
              caches.delete(cacheName);
          });
      });
  }

    openInNewTabAndClose();
    // const location_url = window.location.origin;
    // window.location.href = location_url;
    // window.location.href = window.location.href;
    // console.log(resData,"-------------->>>>>><<<<<<<<<<<");
  }

  const navigate = useNavigate();

  function renderComponent(type) {

    navigate("/processing/" + type)
    // console.log(type); 
  }

  // const refreshPage = () =>{
  //   updateBuild()
  //   // const location_url = window.location.origin;
  //   // window.location.href = location_url;
  // }
  
  const dataFetchedRef = useRef(false);
  const logout = async () => {
    toast.dismiss();
    const decoded = Cookies.get("microhost_api_auth");
    const dt = toast.loading("User Log Out Session...");
    const dnsp = await UseApi('DELETE', 'logout', '');
    //console.log(dnsp);
    if (dnsp.status == 'error') {
      // setPageStatus(false)
      toast.update(dt, {
        render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
    } else {
      //   setPageStatus(false)
      toast.update(dt, {
        render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });

      localStorage.clear();
  
      const cookieNames = Object.keys(Cookies.get());
      const domain = '.' + current_domain; // Specify the domain
  
      // Remove each cookie with the specified domain
      cookieNames.forEach((cookieName) => {
        Cookies.remove(cookieName, { domain, path: '/' }); // Make sure to include the path
      });
  
      cookieNames.forEach((cookieName) => {
        Cookies.remove(cookieName, { current_domain, path: '/' }); // Make sure to include the path
      });
      window.location.reload();
    }
  }

  const [userData, setUserData] = useState(null);
  const getUserInfo = async () => {
    const themeInfo = document.querySelector("#themeSelect");
    if (localStorage.get("darkmode") == "yes") {
      if (themeInfo) {
        themeInfo.checked = true;
      }
    }
    //  const data=await UseApi('get','account/info',''); 
    const data = localStorage.get("user_info");

    //   if(data.build ==  Cookies.get("buildVersion")){
    //   // updateBuild() 
    //   setIsNewBuild('no')
    // }else{
    //   setIsNewBuild('yes')
    // }
    if (data) {
      setUserData(data);
    }
  }
  const [logo_dark, setLogo_dark] = useState(null);
  const getAppInfo = async () => {
    let toolbarData = localStorage.get('app_info')
    var baseUrl = "https://api.utho.com";
    var baseEndPoint = "api";
    const platformName = localStorage.get("platformType");
    if (platformName) {
      if (platformName == "dev") {
        baseUrl = "https://dev.api.cloudplatformapp.com";
        baseEndPoint = "dev.api";
      }
    }
    if (toolbarData == null) {
      const app_info = await UseApi('get', '/appinfo', '');
      console.log(app_info);
      if (app_info && app_info) {
        var siteUrlString = app_info.site;

        var siteUrlArr = siteUrlString.split(".");
        var siteLength = siteUrlArr.length;

        var siteOrg = "https://" + baseEndPoint + "." + siteUrlArr[siteLength - 2] + "." + siteUrlArr[siteLength - 1];
        var DarkLogo = siteOrg + "/publicfiles/" + app_info.logo;
        setLogo_dark(DarkLogo);
        window.localStorage.setItem('app_info', JSON.stringify(app_info))
      }
    } else {
      var siteUrlString = toolbarData.site;

      var siteUrlArr = siteUrlString.split(".");
      var siteLength = siteUrlArr.length;

      var siteOrg = "https://" + baseEndPoint + "." + siteUrlArr[siteLength - 2] + "." + siteUrlArr[siteLength - 1];
      var DarkLogo = siteOrg + "/publicfiles/" + toolbarData.logo;
      setLogo_dark(DarkLogo);
    }
  }

  useEffect(() => {
    getUserInfo();
    getAppInfo();
  }, []);

  const setTheme = (event) => {
    if (event.target.checked) {
      localStorage.set("darkmode", 'yes');
      window.location.reload();
    } else {
      localStorage.set("darkmode", 'no');
      window.location.reload();
    }
  }


  // useEffect(() => {
  //   const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  //   const morningTargetTime = new Date(); // Current date and time
  //   morningTargetTime.setHours(7, 0, 0, 0); // Set to 7:00 AM

  //   const eveningTargetTime = new Date(); // Current date and time
  //   eveningTargetTime.setHours(19, 0, 0, 0); // Set to 7:00 PM
  //   const now = new Date();

  //   const morningTimeUntilTarget = morningTargetTime - now;
  //   const eveningTimeUntilTarget = eveningTargetTime - now;

  //   const morningDelay = morningTimeUntilTarget > 0 ? morningTimeUntilTarget : 24 * 60 * 60 * 1000 + morningTimeUntilTarget;
  //   const eveningDelay = eveningTimeUntilTarget > 0 ? eveningTimeUntilTarget : 24 * 60 * 60 * 1000 + eveningTimeUntilTarget;
  //   // Schedule the function to run at the morning target time
  //   const morningTimeoutId = setTimeout(() => {
  //     localStorage.set("darkmode", 'no');
  //     window.location.reload();
  //   }, morningDelay);

  //   const eveningTimeoutId = setTimeout(() => {
  //     localStorage.set("darkmode", 'yes');
  //     window.location.reload();
  //   }, eveningDelay);
  //   // Clean up the timeout on component unmount
  //   return () => {
  //     clearTimeout(morningTimeoutId);
  //     clearTimeout(eveningTimeoutId);
  //   };

  // }, []);

  const handleMenu = (type) => {
    renderComponent(type);
    const parentMenuDiv = document.querySelector("#parentMenuDiv");
    const childMenuDiv = document.querySelector("#childMenuDiv");
    if (parentMenuDiv && childMenuDiv) {
      parentMenuDiv.classList.remove('show');
      childMenuDiv.classList.remove('show');
    }
  }

  useEffect(()=>{
    if(userData){
      const buildVersion = Cookies.get("buildVersion");
 
      console.log("buildVersion", buildVersion);
      console.log("userData.build", userData.build);
      if(buildVersion){
     if (buildVersion!=userData.build) { 
       setIsNewBuild('yes');
        } else {
       setIsNewBuild('no'); 
     }
   }else{
     setIsNewBuild('yes');
   }
   }
  },[userData])

  // console.log(IsSubUserAccount());
  return (
    <>
      {/* {userData!=null?(<>  */}
      {/*begin::Header*/}
      <div id="kt_header" style={{}} className="header align-items-stretch">

        {/*begin::Container*/}
        <div className="container-fluid d-flex align-items-stretch justify-content-between ps-5">
          {/*begin::Aside mobile toggle*/}



          {/* <div
            className="d-none d-flex align-items-center d-lg-none ms-n2 me-2"
            title="Show aside menu"
          >
            <div
              className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
              id="kt_aside_mobile_toggle"
            > 
              <span className="svg-icon svg-icon-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                    fill="black"
                  />
                  <path
                    opacity="0.3"
                    d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                    fill="black"
                  />
                </svg>
              </span> 
            </div>
          </div> */}
          {/*end::Aside mobile toggle*/}
          {/*begin::Mobile logo*/}
          <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
            <a className="d-lg-none">
              {logo_dark != null ? (<>
                <img
                  alt="Logo"
                  src={logo_dark}
                  className="h-30px logo"
                />
              </>) : ('')}

            </a>
          </div>
          {/*end::Mobile logo*/}
          {/*begin::Wrapper*/}
          <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
            {/*begin::Navbar*/}
            <div className="d-flex align-items-stretch" id="kt_header_nav">
              {/*begin::Menu wrapper*/}
              <div
                className="header-menu align-items-stretch"
                data-kt-drawer="true"
                data-kt-drawer-name="header-menu"
                data-kt-drawer-activate="{default: true, lg: false}"
                data-kt-drawer-overlay="true"
                data-kt-drawer-width="{default:'200px', '300px': '250px'}"
                data-kt-drawer-direction="end"
                data-kt-drawer-toggle="#kt_header_menu_mobile_toggle"
                data-kt-swapper="true"
                data-kt-swapper-mode="prepend"
                data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
              >
                {/*begin::Menu*/}

                <div
                  className="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch slide-animation"
                  id="#kt_header_menu"
                  data-kt-menu="true"
                >
                  <div
                    data-kt-menu-trigger="click"
                    data-kt-menu-placement="bottom-start"
                    className="menu-item here show  menu-lg-down-accordion me-lg-1"
                    id="parentMenuDiv"
                  >
                    <span className="menu-link py-3 btn btn-sm ">
                      <span className="text-primary sphide">Deploy New</span>
                      <span className="menu-arrow " />
                    </span>
                    <div id="childMenuDiv" className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 py-lg-4 w-lg-225px">
                      <div className="menu-item border">
                        {/* <Link to="cloud/deploy"> */}
                        <a onClick={(e) => handleMenu("cloud")}
                          className="menu-link active py-3"
                        >
                          <span className="menu-icon">
                            {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                            <span className="svg-icon svg-icon-2">
                              <i
                                className="las la-cloud"
                                style={{ fontSize: 20 }}
                              />
                            </span>
                            {/*end::Svg Icon*/}
                          </span>
                          <span className="menu-title">Cloud Instances</span>
                        </a>
                        {/* </Link> */}
                      </div>
                      <div className="menu-item border">
                        {/* <Link to="kubernetes/deploy"> */}
                        <a onClick={(e) => handleMenu("kubernetes")}
                          className="menu-link py-3"

                        >
                          <span className="menu-icon">
                            {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                            <span className="svg-icon svg-icon-2">
                              <i
                                className="las la-binoculars"
                                style={{ fontSize: 20 }}
                              />
                            </span>
                            {/*end::Svg Icon*/}
                          </span>
                          <span className="menu-title">Kubernetes</span>
                        </a>
                        {/* </Link> */}
                      </div>
                      <div className="menu-item border">
                        {/* <Link to="vpc/deploy"> */}
                        <a onClick={(e) => handleMenu("vpc")}
                          className="menu-link py-3"

                        >
                          <span className="menu-icon">
                            {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                            <span className="svg-icon svg-icon-2">
                              <i
                                className="fonticon-share"
                                style={{ fontSize: 20 }}
                              />
                            </span>
                            {/*end::Svg Icon*/}
                          </span>
                          <span className="menu-title">VPC</span>
                        </a>
                        {/* </Link> */}
                      </div>
                      <div className="menu-item border">
                        {/* <Link to="vpn/deploy"> */}
                        <a onClick={(e) => handleMenu("vpn")}
                          className="menu-link py-3"

                        >
                          <span className="menu-icon">
                            {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                            <span className="svg-icon svg-icon-2">
                              <i className="las la-lock" style={{ fontSize: 20 }} />
                            </span>
                            {/*end::Svg Icon*/}
                          </span>
                          <span className="menu-title">VPN</span>
                        </a>
                        {/* </Link> */}
                      </div>
                      <div className="menu-item border">
                        {/* <Link to="firewall?action=deploy"> */}
                        <a onClick={(e) => handleMenu("firewall")}
                          className="menu-link py-3"

                        >
                          <span className="menu-icon">
                            {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                            <span className="svg-icon svg-icon-2">
                              <i
                                className="lab la-hotjar"
                                style={{ fontSize: 20 }}
                              />
                            </span>
                            {/*end::Svg Icon*/}
                          </span>
                          <span className="menu-title">Cloud Firewall</span>
                        </a>
                        {/* </Link> */}
                      </div>
                      <div className="menu-item border">
                        {/* <Link to="loadbalancer/deploy"> */}
                        <a onClick={(e) => handleMenu("loadbalancer")}
                          className="menu-link py-3"

                        >
                          <span className="menu-icon">
                            {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                            <span className="svg-icon svg-icon-2">
                              <i
                                className="bi bi-diagram-3 "
                                style={{ fontSize: 20 }}
                              />
                            </span>
                            {/*end::Svg Icon*/}
                          </span>
                          <span className="menu-title">Load Balancer</span>
                        </a>
                        {/* </Link> */}
                      </div>
                      <div className="menu-item border">
                        {/* <Link to="dns?action=deploy"> */}
                        <a onClick={(e) => handleMenu("dns")}
                          className="menu-link py-3"

                        >
                          <span className="menu-icon">
                            {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                            <span className="svg-icon svg-icon-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  opacity="0.3"
                                  d="M18.4 5.59998C21.9 9.09998 21.9 14.8 18.4 18.3C14.9 21.8 9.2 21.8 5.7 18.3L18.4 5.59998Z"
                                  fill="black"
                                />
                                <path
                                  d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM19.9 11H13V8.8999C14.9 8.6999 16.7 8.00005 18.1 6.80005C19.1 8.00005 19.7 9.4 19.9 11ZM11 19.8999C9.7 19.6999 8.39999 19.2 7.39999 18.5C8.49999 17.7 9.7 17.2001 11 17.1001V19.8999ZM5.89999 6.90002C7.39999 8.10002 9.2 8.8 11 9V11.1001H4.10001C4.30001 9.4001 4.89999 8.00002 5.89999 6.90002ZM7.39999 5.5C8.49999 4.7 9.7 4.19998 11 4.09998V7C9.7 6.8 8.39999 6.3 7.39999 5.5ZM13 17.1001C14.3 17.3001 15.6 17.8 16.6 18.5C15.5 19.3 14.3 19.7999 13 19.8999V17.1001ZM13 4.09998C14.3 4.29998 15.6 4.8 16.6 5.5C15.5 6.3 14.3 6.80002 13 6.90002V4.09998ZM4.10001 13H11V15.1001C9.1 15.3001 7.29999 16 5.89999 17.2C4.89999 16 4.30001 14.6 4.10001 13ZM18.1 17.1001C16.6 15.9001 14.8 15.2 13 15V12.8999H19.9C19.7 14.5999 19.1 16.0001 18.1 17.1001Z"
                                  fill="black"
                                />
                              </svg>
                            </span>
                            {/*end::Svg Icon*/}
                          </span>
                          <span className="menu-title">DNS/Domain</span>
                        </a>
                        {/* </Link> */}
                      </div>
                      <div className="menu-item border">
                        {/* <Link to="dns?action=deploy"> */}
                        <a onClick={(e) => handleMenu("database-cluster")}
                          className="menu-link py-3"

                        >
                          <span className="menu-icon">
                            {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                            <span className="svg-icon svg-icon-2">
                              <svg
                                width={24}
                                height={24}
                                viewBox="0 0 84 84"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g
                                  id="DBaaS-Icon-1C-BLK"
                                  transform="translate(0.189181, -0.000000)"
                                  fill="#a1a5b7"
                                  fillRule="nonzero"
                                >
                                  <path d="m66.7 48.9c-7.7 2.1-17.7 3.3-28.4 3.3s-20.8-1.2-28.4-3.3c-2.2-.6-4.2-1.3-5.9-2v22c0 5.2 15.3 9.4 34.3 9.4s34.3-4.2 34.3-9.4v-22c-1.8.8-3.7 1.4-5.9 2zm-6.3 19.8c-2.6 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6 4.6 2.1 4.6 4.6-2 4.6-4.6 4.6z" />
                                  <path d="m38.3 5.1c-19 0-34.3 4.2-34.3 9.4v25.4c3.6 2.4 14.8 6.1 34.3 6.1 19.4 0 30.7-3.7 34.3-6.1v-25.4c-.1-5.2-15.4-9.4-34.3-9.4zm22.1 31.7c-2.6 0-4.6-2.1-4.6-4.6 0-2.6 2.1-4.6 4.6-4.6s4.6 2.1 4.6 4.6c.1 2.5-2 4.6-4.6 4.6zm-22.1-14.3c-20.9 0-32.3-4.9-32.3-7.4s11.4-7.4 32.3-7.4 32.3 4.9 32.3 7.4-11.5 7.4-32.3 7.4z" />
                                </g>
                              </svg>
                            </span>
                            {/*end::Svg Icon*/}
                          </span>
                          <span className="menu-title">DataBase</span>
                        </a>
                        {/* </Link> */}
                      </div>
                      <div className="menu-item border">
                        {/* <Link to="dns?action=deploy"> */}
                        <a onClick={(e) => handleMenu("objectStorage")}
                          className="menu-link py-3"

                        >
                          <span className="menu-icon">
                            {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                            <span className="svg-icon svg-icon-2">
                              <svg
                                fill="#a1a5b7"
                                viewBox="0 0 34 24"
                                id="icon"
                                xmlns="http://www.w3.org/2000/svg"
                                width={34}
                                height={24}
                              >
                                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                <g
                                  id="SVGRepo_tracerCarrier"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <g id="SVGRepo_iconCarrier">
                                  <defs>
                                    <style
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          "\n                                .cls-1 {\n                                    fill: none;\n                                }\n                            ",
                                      }}
                                    />
                                  </defs>
                                  <path d="M28,20H26v2h2v6H4V22H14V20H4a2.0023,2.0023,0,0,0-2,2v6a2.0023,2.0023,0,0,0,2,2H28a2.0023,2.0023,0,0,0,2-2V22A2.0023,2.0023,0,0,0,28,20Z" />
                                  <circle cx={7} cy={25} r={1} />
                                  <path d="M30,8H22v6H16v8h8V16h6ZM22,20H18V16h4Zm6-6H24V10h4Z" />
                                  <path d="M18,10H10V2h8ZM12,8h4V4H12Z" />
                                </g>
                              </svg>
                            </span>
                            {/*end::Svg Icon*/}
                          </span>
                          <span className="menu-title">Object Storage</span>
                        </a>
                        {/* </Link> */}
                      </div>
                      <div className="menu-item border d-none">
                        {/* <Link to="dns?action=deploy"> */}
                        <a onClick={(e) => handleMenu("gpu")}
                          className="menu-link py-3"

                        >
                          <span className="menu-icon d-none">
                            {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                            <span className="svg-icon svg-icon-2">
                              <svg
                                fill="#a1a5b7"
                                height="200px"
                                width="200px"
                                version="1.1"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 512 512"
                                xmlSpace="preserve"
                                stroke="#a1a5b7"
                              >
                                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <g>
                                    {" "}
                                    <path d="M480.003,128H48c0-22.056-17.944-40-40-40c-4.418,0-8,3.582-8,8s3.582,8,8,8c13.234,0,24,10.766,24,24v288 c0,4.418,3.582,8,8,8s8-3.582,8-8v-8h16.01C77.238,408,88,397.238,88,384.01V384h392.003C497.646,384,512,369.646,512,352.003 V159.997C512,142.354,497.646,128,480.003,128z M496,352.003c0,8.821-7.176,15.997-15.997,15.997H80c-4.418,0-8,3.582-8,8v8.01 c0,4.406-3.584,7.99-7.99,7.99H48V144h432.003c8.821,0,15.997,7.176,15.997,15.997V352.003z" />{" "}
                                    <path d="M240,192c-22.922,0-43.057,12.12-54.363,30.28c-0.784,0.849-1.379,1.856-1.737,2.954c-5.03,9.136-7.9,19.621-7.9,30.766 c0,11.406,3.013,22.115,8.264,31.399c0.187,0.398,0.407,0.778,0.656,1.14C196.078,307.354,216.586,320,240,320 c35.29,0,64-28.71,64-64c0-11.406-3.013-22.115-8.264-31.399c-0.187-0.398-0.407-0.778-0.656-1.14 C283.922,204.646,263.414,192,240,192z M192,256c0-4.395,0.605-8.648,1.717-12.695c3.596,3.178,8.453,6.73,15.035,10.53 c6.376,3.681,11.742,6.078,16.208,7.612c-2.622,2.061-5.987,4.385-10.208,6.821c-8.449,4.878-14.816,7.039-18.36,7.752 C193.58,269.922,192,263.144,192,256z M288,256c0,4.103-0.52,8.087-1.493,11.891c-3.617-3.227-8.542-6.848-15.259-10.726 c-5.96-3.441-11.036-5.758-15.321-7.298c2.483-1.885,5.564-3.966,9.321-6.135c8.447-4.876,14.816-7.039,18.36-7.752 C286.42,242.078,288,248.856,288,256z M273.948,222.099c-4.562,1.524-10.087,3.96-16.699,7.777 c-6.252,3.61-10.952,6.997-14.49,10.051C242.31,236.682,242,232.717,242,228c0-9.763,1.314-16.361,2.469-19.785 C255.934,209.279,266.244,214.384,273.948,222.099z M227.619,209.627C226.655,214.344,226,220.354,226,228 c0,7.056,0.557,12.721,1.401,17.26c-3.022-1.232-6.59-2.938-10.65-5.282c-8.302-4.793-13.33-9.159-15.769-11.883 C207.376,219.18,216.739,212.535,227.619,209.627z M206.049,289.898c4.564-1.524,10.086-3.954,16.702-7.774 c6.252-3.61,10.952-6.997,14.49-10.051C237.69,275.318,238,279.283,238,284c0,9.763-1.314,16.361-2.469,19.785 C224.065,302.721,213.753,297.615,206.049,289.898z M252.381,302.373C253.345,297.656,254,291.646,254,284 c0-7.633-0.653-13.635-1.614-18.347c3.066,1.237,6.708,2.97,10.863,5.368c8.764,5.06,13.892,9.652,16.163,12.33 C273.012,292.546,263.486,299.405,252.381,302.373z" />{" "}
                                    <path d="M440,168c-4.418,0-8,3.582-8,8s3.582,8,8,8c8.822,0,16,7.178,16,16v112c0,8.822-7.178,16-16,16H240 c-39.701,0-72-32.299-72-72s32.299-72,72-72h168c4.418,0,8-3.582,8-8s-3.582-8-8-8H240c-48.523,0-88,39.477-88,88s39.477,88,88,88 h200c17.645,0,32-14.355,32-32V200C472,182.355,457.645,168,440,168z" />{" "}
                                    <path d="M112,232H88c-8.822,0-16,7.178-16,16v16c0,8.822,7.178,16,16,16h24c8.822,0,16-7.178,16-16v-16 C128,239.178,120.822,232,112,232z M88,264v-16h24l0.001,16H88z" />{" "}
                                    <path d="M112,176H88c-8.822,0-16,7.178-16,16v16c0,8.822,7.178,16,16,16h24c8.822,0,16-7.178,16-16v-16 C128,183.178,120.822,176,112,176z M88,208v-16h24l0.001,16H88z" />{" "}
                                    <path d="M112,288H88c-8.822,0-16,7.178-16,16v16c0,8.822,7.178,16,16,16h24c8.822,0,16-7.178,16-16v-16 C128,295.178,120.822,288,112,288z M88,320v-16h24l0.001,16H88z" />{" "}
                                    <path d="M432,200h-24c-4.418,0-8,3.582-8,8s3.582,8,8,8h24c4.418,0,8-3.582,8-8S436.418,200,432,200z" />{" "}
                                    <path d="M432,296h-24c-4.418,0-8,3.582-8,8s3.582,8,8,8h24c4.418,0,8-3.582,8-8S436.418,296,432,296z" />{" "}
                                    <path d="M432,224h-24c-4.418,0-8,3.582-8,8s3.582,8,8,8h24c4.418,0,8-3.582,8-8S436.418,224,432,224z" />{" "}
                                    <path d="M432,248h-24c-4.418,0-8,3.582-8,8s3.582,8,8,8h24c4.418,0,8-3.582,8-8S436.418,248,432,248z" />{" "}
                                    <path d="M432,272h-24c-4.418,0-8,3.582-8,8s3.582,8,8,8h24c4.418,0,8-3.582,8-8S436.418,272,432,272z" />{" "}
                                  </g>{" "}
                                </g>
                              </svg>

                            </span>
                            {/*end::Svg Icon*/}
                          </span>
                          <span className="menu-title d-none">GPU</span>
                        </a>
                        {/* </Link> */}
                      </div>
                      <div className="menu-item border">
                        {/* <Link to="dns?action=deploy"> */}
                        <a onClick={(e) => handleMenu("auto-scaling")}
                          className="menu-link py-3"

                        >
                          <span className="menu-icon">
                            {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                            <span className="svg-icon svg-icon-2">
                              <svg
                                fill="#a1a5b7"
                                viewBox="0 0 24 24"
                                id="icon"
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                              >
                                <path
                                  opacity="0.3"
                                  d="M4.05424 15.1982C8.34524 7.76818 13.5782 3.26318 20.9282 2.01418C21.0729 1.98837 21.2216 1.99789 21.3618 2.04193C21.502 2.08597 21.6294 2.16323 21.7333 2.26712C21.8372 2.37101 21.9144 2.49846 21.9585 2.63863C22.0025 2.7788 22.012 2.92754 21.9862 3.07218C20.7372 10.4222 16.2322 15.6552 8.80224 19.9462L4.05424 15.1982ZM3.81924 17.3372L2.63324 20.4482C2.58427 20.5765 2.5735 20.7163 2.6022 20.8507C2.63091 20.9851 2.69788 21.1082 2.79503 21.2054C2.89218 21.3025 3.01536 21.3695 3.14972 21.3982C3.28408 21.4269 3.42387 21.4161 3.55224 21.3672L6.66524 20.1802L3.81924 17.3372ZM16.5002 5.99818C16.2036 5.99818 15.9136 6.08615 15.6669 6.25097C15.4202 6.41579 15.228 6.65006 15.1144 6.92415C15.0009 7.19824 14.9712 7.49984 15.0291 7.79081C15.0869 8.08178 15.2298 8.34906 15.4396 8.55884C15.6494 8.76862 15.9166 8.91148 16.2076 8.96935C16.4986 9.02723 16.8002 8.99753 17.0743 8.884C17.3484 8.77046 17.5826 8.5782 17.7474 8.33153C17.9123 8.08486 18.0002 7.79485 18.0002 7.49818C18.0002 7.10035 17.8422 6.71882 17.5609 6.43752C17.2796 6.15621 16.8981 5.99818 16.5002 5.99818Z"
                                  fill="black"
                                />
                                <path
                                  d="M4.05423 15.1982L2.24723 13.3912C2.15505 13.299 2.08547 13.1867 2.04395 13.0632C2.00243 12.9396 1.9901 12.8081 2.00793 12.679C2.02575 12.5498 2.07325 12.4266 2.14669 12.3189C2.22013 12.2112 2.31752 12.1219 2.43123 12.0582L9.15323 8.28918C7.17353 10.3717 5.4607 12.6926 4.05423 15.1982ZM8.80023 19.9442L10.6072 21.7512C10.6994 21.8434 10.8117 21.9129 10.9352 21.9545C11.0588 21.996 11.1903 22.0083 11.3195 21.9905C11.4486 21.9727 11.5718 21.9252 11.6795 21.8517C11.7872 21.7783 11.8765 21.6809 11.9402 21.5672L15.7092 14.8442C13.6269 16.8245 11.3061 18.5377 8.80023 19.9442ZM7.04023 18.1832L12.5832 12.6402C12.7381 12.4759 12.8228 12.2577 12.8195 12.032C12.8161 11.8063 12.725 11.5907 12.5653 11.4311C12.4057 11.2714 12.1901 11.1803 11.9644 11.1769C11.7387 11.1736 11.5205 11.2583 11.3562 11.4132L5.81323 16.9562L7.04023 18.1832Z"
                                  fill="black"
                                />
                              </svg>
                            </span>
                            {/*end::Svg Icon*/}
                          </span>
                          <span className="menu-title">Auto Scaling</span>
                        </a>
                        {/* </Link> */}
                      </div>
                      <div className="menu-item border">
                        {/* <Link to="dns?action=deploy"> */}
                        <a onClick={(e) => handleMenu("stack")}
                          className="menu-link py-3"

                        >
                          <span className="menu-icon">
                            {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                            <span className="svg-icon svg-icon-2">
                              <svg
                                fill="#a1a5b7"
                                viewBox="0 0 24 24"
                                id="icon"
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                              >
                                <path
                                  opacity="0.3"
                                  d="M18 21.6C16.3 21.6 15 20.3 15 18.6V2.50001C15 2.20001 14.6 1.99996 14.3 2.19996L13 3.59999L11.7 2.3C11.3 1.9 10.7 1.9 10.3 2.3L9 3.59999L7.70001 2.3C7.30001 1.9 6.69999 1.9 6.29999 2.3L5 3.59999L3.70001 2.3C3.50001 2.1 3 2.20001 3 3.50001V18.6C3 20.3 4.3 21.6 6 21.6H18Z"
                                  fill="black"
                                />
                                <path
                                  d="M12 12.6H11C10.4 12.6 10 12.2 10 11.6C10 11 10.4 10.6 11 10.6H12C12.6 10.6 13 11 13 11.6C13 12.2 12.6 12.6 12 12.6ZM9 11.6C9 11 8.6 10.6 8 10.6H6C5.4 10.6 5 11 5 11.6C5 12.2 5.4 12.6 6 12.6H8C8.6 12.6 9 12.2 9 11.6ZM9 7.59998C9 6.99998 8.6 6.59998 8 6.59998H6C5.4 6.59998 5 6.99998 5 7.59998C5 8.19998 5.4 8.59998 6 8.59998H8C8.6 8.59998 9 8.19998 9 7.59998ZM13 7.59998C13 6.99998 12.6 6.59998 12 6.59998H11C10.4 6.59998 10 6.99998 10 7.59998C10 8.19998 10.4 8.59998 11 8.59998H12C12.6 8.59998 13 8.19998 13 7.59998ZM13 15.6C13 15 12.6 14.6 12 14.6H10C9.4 14.6 9 15 9 15.6C9 16.2 9.4 16.6 10 16.6H12C12.6 16.6 13 16.2 13 15.6Z"
                                  fill="black"
                                />
                                <path
                                  d="M15 18.6C15 20.3 16.3 21.6 18 21.6C19.7 21.6 21 20.3 21 18.6V12.5C21 12.2 20.6 12 20.3 12.2L19 13.6L17.7 12.3C17.3 11.9 16.7 11.9 16.3 12.3L15 13.6V18.6Z"
                                  fill="black"
                                />
                              </svg>
                            </span>
                            {/*end::Svg Icon*/}
                          </span>
                          <span className="menu-title">Stacks</span>
                        </a>
                        {/* </Link> */}
                      </div>
                      <div className="menu-item border">
                        {/* <Link to="dns?action=deploy"> */}
                        <a onClick={(e) => handleMenu("ssl")}
                          className="menu-link py-3"

                        >
                          <span className="menu-icon">
                            {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                            <span className="svg-icon svg-icon-2">
                              <svg
                                fill="#a1a5b7"
                                viewBox="0 0 24 24"
                                id="icon"
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                              >
                                <path
                                  opacity="0.3"
                                  d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                                  fill="black"
                                />
                                <path
                                  d="M14.854 11.321C14.7568 11.2282 14.6388 11.1818 14.4998 11.1818H14.3333V10.2272C14.3333 9.61741 14.1041 9.09378 13.6458 8.65628C13.1875 8.21876 12.639 8 12 8C11.361 8 10.8124 8.21876 10.3541 8.65626C9.89574 9.09378 9.66663 9.61739 9.66663 10.2272V11.1818H9.49999C9.36115 11.1818 9.24306 11.2282 9.14583 11.321C9.0486 11.4138 9 11.5265 9 11.6591V14.5227C9 14.6553 9.04862 14.768 9.14583 14.8609C9.24306 14.9536 9.36115 15 9.49999 15H14.5C14.6389 15 14.7569 14.9536 14.8542 14.8609C14.9513 14.768 15 14.6553 15 14.5227V11.6591C15.0001 11.5265 14.9513 11.4138 14.854 11.321ZM13.3333 11.1818H10.6666V10.2272C10.6666 9.87594 10.7969 9.57597 11.0573 9.32743C11.3177 9.07886 11.6319 8.9546 12 8.9546C12.3681 8.9546 12.6823 9.07884 12.9427 9.32743C13.2031 9.57595 13.3333 9.87594 13.3333 10.2272V11.1818Z"
                                  fill="black"
                                />
                              </svg>
                            </span>
                            {/*end::Svg Icon*/}
                          </span>
                          <span className="menu-title">SSL Certificate</span>
                        </a>
                        {/* </Link> */}
                      </div>
                      <div className="menu-item border d-none">
                        {/* <Link to="dns?action=deploy"> */}
                        <a onClick={(e) => handleMenu("reserved-ip")}
                          className="menu-link py-3"

                        >
                          <span className="menu-icon">
                            {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                            <span className="svg-icon svg-icon-2">
                              <svg
                                width="800px"
                                height="800px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22"
                                  stroke="#a1a5b7"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <g opacity="0.4">
                                  <path
                                    d="M7.99961 3H8.99961C7.04961 8.84 7.04961 15.16 8.99961 21H7.99961"
                                    stroke="#a1a5b7"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M15 3C15.97 5.92 16.46 8.96 16.46 12"
                                    stroke="#a1a5b7"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M3 16V15C5.92 15.97 8.96 16.46 12 16.46"
                                    stroke="#a1a5b7"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M3 9.0001C8.84 7.0501 15.16 7.0501 21 9.0001"
                                    stroke="#a1a5b7"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </g>
                                <path
                                  d="M18.2 21.4C19.9673 21.4 21.4 19.9673 21.4 18.2C21.4 16.4327 19.9673 15 18.2 15C16.4327 15 15 16.4327 15 18.2C15 19.9673 16.4327 21.4 18.2 21.4Z"
                                  stroke="#a1a5b7"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M22 22L21 21"
                                  stroke="#a1a5b7"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>

                            </span>
                            {/*end::Svg Icon*/}
                          </span>
                          <span className="menu-title">Reserved IP</span>
                        </a>
                        {/* </Link> */}
                      </div>
                    </div>
                  </div>{" "}
                  {userData != null ? (<>
                    {userData.credit !== undefined && userData.credit !== null ? (<>
                      <div
                        data-kt-menu-trigger="click"
                        className=" menu-item here  menu-lg-down-accordion me-lg-1"
                      >
                        <Link to="/currentUsage">
                          <a

                            className=" py-3 btn btn-sm btn-light-primary"
                          >
                            <span className="menu-title sphide" id="current_usage">
                              Current Usage: {userData != null ? (userData.currencyprefix) : (<></>)}  {userData != null ? (userData.currentusages) : (<></>)}
                            </span>
                          </a>
                        </Link>
                      </div>
                      <div
                        data-kt-menu-trigger="click"
                        className="  menu-item here menu-lg-down-accordion me-lg-1"
                      >
                        <Link to="/billing">
                          <a


                            className="btn btn-sm btn-light-primary py-3"
                          >
                            <span className="menu-title sphide" id="available_credit">
                              Available Credits: {userData != null ? (userData.currencyprefix) : (<>hur</>)} {userData != null ? ((userData?.credit + userData?.freecredit).toFixed(2)) : (<></>)}
                            </span>
                          </a>
                        </Link>
                      </div>
                    </>) : ('')}

                    <div
                      data-kt-menu-trigger="click"
                      className=" menu-item here menu-lg-down-accordion me-lg-1"
                    >
                      <a className="btn btn-sm btn-light-primary py-3 sphide" style={{ cursor: 'arow' }}>
                        User ID <b># {userData != null ? (userData.id) : (<></>)}</b>.
                      </a>
                    </div>
                    <div
                      data-kt-menu-trigger="click"
                      className="d-none menu-item here menu-lg-down-accordion me-lg-1"
                    >
                      <a
                        target="_blank"
                        href="https://cloud.microhost.com/old"
                        className="btn btn-sm btn-light-primary py-3"
                      >
                        <span className="menu-title sphide">
                          Old Platform{" "}
                          {/*begin::Svg Icon | path: assets/media/icons/duotune/arrows/arr076.svg*/}
                          <span className="svg-icon svg-icon-muted svg-icon-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <rect
                                opacity="0.3"
                                width={12}
                                height={2}
                                rx={1}
                                transform="matrix(-1 0 0 1 15.5 11)"
                                fill="black"
                              />
                              <path
                                d="M13.6313 11.6927L11.8756 10.2297C11.4054 9.83785 11.3732 9.12683 11.806 8.69401C12.1957 8.3043 12.8216 8.28591 13.2336 8.65206L16.1592 11.2526C16.6067 11.6504 16.6067 12.3496 16.1592 12.7474L13.2336 15.3479C12.8216 15.7141 12.1957 15.6957 11.806 15.306C11.3732 14.8732 11.4054 14.1621 11.8756 13.7703L13.6313 12.3073C13.8232 12.1474 13.8232 11.8526 13.6313 11.6927Z"
                                fill="black"
                              />
                              <path
                                d="M8 5V6C8 6.55228 8.44772 7 9 7C9.55228 7 10 6.55228 10 6C10 5.44772 10.4477 5 11 5H18C18.5523 5 19 5.44772 19 6V18C19 18.5523 18.5523 19 18 19H11C10.4477 19 10 18.5523 10 18C10 17.4477 9.55228 17 9 17C8.44772 17 8 17.4477 8 18V19C8 20.1046 8.89543 21 10 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H10C8.89543 3 8 3.89543 8 5Z"
                                fill="#C4C4C4"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </span>
                      </a>
                    </div>
                    <SwitchAccount />
                    {/* {userData.is_partner==1?(<><SwicthPartner /></>):(<></>)} */}
                  </>) : (<> </>)}
                </div>

                {/*end::Menu*/}
              </div>
              {/*end::Menu wrapper*/}
            </div>
            {/*end::Navbar*/}
            {/*begin::Toolbar wrapper*/}
            <div className="d-flex align-items-stretch flex-shrink-0">
              {/*begin::User menu*/}
              <div
                className="d-flex align-items-center ms-1 ms-lg-3"
                id="kt_header_user_menu_toggle"
              >
                {/*begin::Menu wrapper*/}
                <div
                  className="cursor-pointer symbol symbol-30px symbol-md-40px"
                  data-kt-menu-trigger="click"
                  data-kt-menu-attach="parent"
                  data-kt-menu-placement="bottom-end"
                >
                  <div
                    className="vis-item-content"
                    style={{ transform: "translateX(0px)" }}
                  >
                    <div className="rounded-pill bg-light-danger d-flex align-items-center position-relative h-40px w-100 p-2 overflow-hidden">
                      <div
                        className="position-absolute rounded-pill d-block bg-light-primary start-0 top-0 h-100 z-index-1"
                        style={{ width: "100%" }}
                      />
                      <div className="d-flex align-items-center position-relative z-index-2">
                        <div className="symbol-group symbol-hover flex-nowrap me-3">
                          <div className="symbol symbol-circle symbol-25px">
                            {/*begin::Svg Icon | path: assets/media/icons/duotune/communication/com006.svg*/}
                            <span className="svg-icon svg-icon-primary svg-icon-2hx">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  opacity="0.3"
                                  d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z"
                                  fill="black"
                                />
                                <path
                                  d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z"
                                  fill="black"
                                />
                              </svg>
                            </span>
                            {/*end::Svg Icon*/}
                          </div>
                        </div>
                        <a

                          className="fw-bold  text-hover-dark"
                          id="header_profile_name"
                        >
                          {userData != null ? (userData.fullname) : (<><div className="small-spinner2"></div></>)}
                        </a>
                        <div className="d-flex flex-center bg-body rounded-pill fs-7 fw-bolder ms-auto h-100 px-3 position-relative z-index-2"></div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*begin::Header menu toggle*/}
                {/* <div
                  className="d-flex align-items-center d-lg-none ms-2 me-n3"
                  title="Show header menu"
                >
                  <div
                    className="btn btn-icon btn-active-light-primary w-50px h-30px w-md-40px h-md-40px"
                    id="kt_header_menu_mobile_toggle"
                  >
                    <img src="https://www.microhost.com/assets/favicon/favicon-32x32.png?v=3" />
                  </div>
                </div> */}
                {/*end::Header menu toggle*/}
                {/*begin::User account menu*/}
                <div
                  className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
                  data-kt-menu="true"
                >
                  {/*begin::Menu item*/}
                  <div className="menu-item px-3">
                    <div className="menu-content d-flex align-items-center px-3">
                      {/*begin::Avatar*/}
                      <div className="symbol symbol-50px me-5 d-none">
                        <img
                          alt="Logo"
                          src="https://www.microhost.com/assets/favicon/favicon-32x32.png?v=3"
                        />
                      </div>
                      {/*end::Avatar*/}
                      {/*begin::Username*/}
                      <div className="d-flex flex-column">
                        <div className="fw-bolder d-flex align-items-center fs-5">
                          <span id="profile_name">{userData != null ? (userData.fullname) : (<></>)}</span>
                        </div>
                        <a

                          className="fw-bold text-muted text-hover-primary fs-7"
                          id="profile_email"
                        >
                          {userData != null ? (userData.email) : (<></>)}
                        </a>
                      </div>
                      {/*end::Username*/}
                    </div>
                  </div>
                  {/*end::Menu item*/}
                  {/*begin::Menu separator*/}
                  <div className="separator my-2" />
                  {/*end::Menu separator*/}
                  {/*begin::Menu item*/}
                  <div className="menu-item px-5">
                    <Link to="account" >
                      <a className="menu-link px-5">
                        My Profile
                      </a>
                    </Link>
                  </div>
                  {/*end::Menu item*/}
                  {/*begin::Menu item*/}
                  <div className="menu-item px-5">
                    <Link to="cloud" >
                      <a className="menu-link px-5">
                        <span className="menu-text">My Servers</span>
                        <span className="menu-badge"></span>
                      </a>
                    </Link>
                  </div>
                  {/*end::Menu item*/}
                  {/*begin::Menu separator*/}
                  <div className="separator my-2" />
                  {/*end::Menu separator*/}
                  {/*begin::Menu item*/}
                  <div className="menu-item px-5 my-1">
                    <Link to="accountManagement">
                      <a className="menu-link px-5">
                        Account Settings
                      </a>
                    </Link>
                  </div>
                  {/*end::Menu item*/}
                  {/*begin::Menu item*/}
                  <div className="menu-item px-5">
                    <a
                      onClick={logout}

                      className="menu-link px-5"
                    >
                      Sign Out
                    </a>
                  </div>
                  {/*end::Menu item*/}
                  {/*begin::Menu separator*/}
                  <div className="separator my-2" />
                  {/*end::Menu separator*/}
                  <div className="menu-item px-5">
                    {userData != null && userData.is_partner == 1 ? (<><SwicthPartner /></>) : (<></>)}
                  </div>
                  {/*begin::Menu separator*/}
                  <div className="separator my-2" />
                  {/*end::Menu separator*/}
                  {/*begin::Menu item*/}
                  <div className="menu-item px-5">
                    <div className="menu-content px-5">
                      <label
                        className="form-check form-switch form-check-custom form-check-solid pulse pulse-success"
                        htmlFor="kt_user_menu_dark_mode_toggle"
                      >
                        <div id="darkmodeChange">
                          <input
                            className="form-check-input w-30px h-20px"
                            type="checkbox"
                            name="mode"
                            id="themeSelect"
                            onChange={setTheme}
                          />
                        </div>
                        <span className="pulse-ring ms-n1" />
                        <span className="form-check-label text-gray-600 fs-7">
                          Dark Mode{" "}
                        </span>
                      </label>
                    </div>
                  </div>
                  {/*begin::Menu separator*/}
                  <div className="separator my-2" />
                  {/*end::Menu separator*/}
                  {/*end::Menu item*/}
                </div>
                {/*end::User account menu*/}
                {/*end::Menu wrapper*/}
              </div>
              {/*end::User menu*/}
            </div>
            {/*end::Toolbar wrapper*/}
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*end::Container*/}

      </div>
      {isStaffLogin != null ? (<>
        {isStaffLogin == true ? (<>
          <div className="d-flex flex-column flex-column-fluid mt-5 w-100 p-3">
            <div class="p-3 w-100 alert alert-danger text-center">
              <a className="m-5 alert alert-danger">Alert: You have logged in as staff within a customer account. Any unauthorized or illegal activity will result in the removal of your staff privileges.</a>
            </div>
          </div>
        </>) : ('')}
      </>) : ('')}

      {isNewBuild != null ? (<>
        {isNewBuild == 'yes' ? (<>
          <div className="d-flex flex-column flex-column-fluid mt-5 w-100 p-3">
            <div class="p-3 w-100 alert alert-success text-center">
              <a className="m-5" style={{color:"#205237"}}>New updates are here! <span onClick={updateBuild} className="text-primary" style={{cursor:"pointer"}}>Click</span> to refresh and experience the latest improvements.</a>
            </div>
          </div>
        </>) : ('')}
      </>) : ('')}
      
      {/*end::Header*/}
      {/* </>):(<></>)} */}
    </>

  )
}