import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import AccessKey from "./accessKey/AccessKey";
import StorageList from "./storage/StorageList";
import UsePost from "../../../../CustomHooks/usePost";
import CreateAccesskey from "./accessKey/CreateAccesskey";
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";
import AccessDenied from "../../AccessDenied";
import TableLoader from "../../Loaders/TableLoader";

export default function ObjectStorage() {
  const [pageStatus, setPageStatus] = useState(false)
  const [dataFetched, setDataFetched] = useState(false);
  const [access, setAccess] = useState(true);
  const [perPage, setPerPage] = useState(5);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [activePage, setActivePage] = useState(0);
  const [objectData, setObjectData] = useState(null);
  const [dummyObjectData, setDummyObjectData] = useState(null);
  // const [objectData, setObjectData] = useState(null);
  // const dataFetchedRef=useRef (false); 

  const getObjectStorage = async () => {
    const data = await UseApi('get', 'objectstorage/innoida/bucket', '');
    if (data) {
      if (data.status == "error" && data.message == "Permission denied.") {
        setDataFetched(true)
        setAccess(false)
      } else {
        setDataFetched(true)
        setAccess(true)
      }
    }
  }
  useEffect(() => {
    getObjectStorage()
  }, [])
  //      if('status' in data){
  //       if(data.status=="error"){ 
  //         const dt=toast; 
  //         //  toast.update(dt,{render: data.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
  //         // });   
  //         setObjectData([]);  
  //         return;
  //       }
  //    }
  //      if(data.buckets.length === 0){
  //       const dt=toast.loading("Object Storage loading..."); 
  //       setObjectData(null);
  //            toast.update(dt,{render: "no record found", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
  //           });  
  //      }else{
  //       setObjectData(data.buckets);  
  //       toast.dismiss();  

  //      }
  // }
  // useEffect(()=>{
  //   if (dataFetchedRef.current) return;
  //     dataFetchedRef.current = true;
  //     toast.dismiss(); 
  //     getObjectStorage(); 
  // },[]); 
  function handleSearch(e) {
    console.log(e);
  }
  function callBackFun() {
    console.log("called");
    setPageStatus(!pageStatus)
  }

  if(dataFetched){
  if (access) {
    return (
      <>
        <div className="col-xl-12 card">
          <div className="">
            <CreateAccesskey onSearch={handleSearch} callBackFun={callBackFun} />
            <div className="">
              <ul className="nav nav-pills nav-pills-custom row position-relative mx-0">

                <li className="nav-item col-6 mx-0 px-0">
                  <a
                    className="nav-link active d-flex justify-content-center w-100 border-0 h-100"
                    data-bs-toggle="pill"
                    href="#storage-tab"
                  >
                    <span className="sphide nav-text text-gray-800 fw-bolder fs-6 mb-3">
                      Buckets
                    </span>
                    <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded" />
                  </a>
                </li>
                <li className="nav-item col-6 mx-0 px-0">
                  <a
                    className="nav-link d-flex justify-content-center w-100 border-0 h-100"
                    data-bs-toggle="pill"
                    href="#keys-tab"
                  >
                    <span className="sphide nav-text text-gray-800 fw-bolder fs-6 mb-3">
                      Access Keys
                    </span>
                    <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded" />
                  </a>
                </li>
                <span className="position-absolute z-index-1 bottom-0 w-100 h-4px bg-light rounded" />
              </ul>
            </div>
          </div>
          <div className="tab-content" id="myTabContent">
            <AccessKey callBackFun={callBackFun} pageStatus={pageStatus} />
            <StorageList />

          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <AccessDenied />
      </>
    )
  }
}else{
  return (
    <>
      <TableLoader />
    </>
  )
}
}
