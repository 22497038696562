import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UsePost from "../../../../CustomHooks/usePost";
import copy from "copy-to-clipboard";
import swal from "sweetalert";
import ReactPaginate from "react-paginate";
import AccessDenied from "../../AccessDenied";
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../Loaders/TableLoader";

export default function ReservedIP() {
  const [dataFetched, setDataFetched] = useState(false);
  const [pageStatus, setPageStatus] = useState(false)
  const [access, setAccess]=useState(true);
  const [perPage, setPerPage] = useState(5);
  const navigate = useNavigate();
  const [apiData, setApiData] = useState(null);
  const dataFetchedRef = useRef(false);
  const apiKeyValue = useRef("");
  const navRedirect = () => {
    setTimeout(() => {
      navigate("/api");
    }, 3000);
  };

  const loaderFunc=()=>{
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if(loader && no_record){
    no_record.classList.add("d-none");
    loader.classList.remove("d-none");

    setTimeout(() => {
      loader.classList.add("d-none");
      no_record.classList.remove("d-none");
    }, 100);
  }
  }
  const getAPI = async () => {
    toast.dismiss();
    const dt = toast
    const data = await UseApi("get", "api", "");
    if(data){
      setDataFetched(true);
    }
    if('status' in data){
      if(data.status=="error"){ 
         toast.update(dt,{render: data.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });  
        setAccess(false);
        setApiData([]); 
        loaderFunc(); 
        return;
      }
   }
    if (Object.keys(data).length === 0) {
      
      setApiData([]);
      toast.update(dt, {
        render: "no record found",
        type: "warning",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      loaderFunc();
    } else {
      setApiData(data.api);
      toast.dismiss();
      //    toast.update(dt,{render: "Your API Keys request has been successful", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      //  });
    }
  };
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getAPI();
  }, []);

  const deleteAPI = async (id) => {
    toast.dismiss();
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this API Key!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const deleteBtn = document.querySelector("#deleteApi-" + id);
        if (deleteBtn) {
          setPageStatus(true)
          var preContent = deleteBtn.innerHTML;
          deleteBtn.classList.add("disabled");
          deleteBtn.innerHTML =
            'Please wait... <span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
          const dt = toast.loading("API Keys deleting...");
          const dnsp = await UseApi("delete", "api/" + id + "/delete", "");
          if (dnsp.status == "error") {
            setPageStatus(false)
            toast.update(dt, {
              render: dnsp.message,
              type: "warning",
              isLoading: false,
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            setPageStatus(false)
            toast.update(dt, {
              render: dnsp.message,
              type: "success",
              isLoading: false,
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            getAPI();
            navRedirect();
          }
          deleteBtn.classList.remove("disabled");
          deleteBtn.innerHTML = preContent;
        }
      }
    });
  };
  const openDrawer = () => {
    var drawerElement = document.querySelector("#generateapi-modal");
    drawerElement.classList.toggle("drawer-on");
    drawerElement.style.width = "500px";
  };
  const addReservedIP = async () => {
    toast.dismiss();
    const dt = toast.loading("Please wait...");
    const apiName = document.querySelector("#apiName");
    if (apiName && apiName.value == "") {
      toast.update(dt, {
        render: "Api name must be required!",
        type: "error",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    const submitBtn = document.querySelector("#importKey");
    if (submitBtn) {
      setPageStatus(true)
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML =
        'Please wait... <span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      const responseData = await UsePost("post", "api/generate", "generateapi");

      //console.log(responseData);
      if (responseData.status === "error") {
      setPageStatus(false)
        toast.update(dt, {
          render: responseData.message,
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        var ApiForm = document.querySelector("#generateapi");
        ApiForm.reset();
      } else if (responseData.status === "success") {
      setPageStatus(false)
        toast.update(dt, {
          render: responseData.message,
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        apiKeyValue.current = responseData.apikey;
        var element = document.querySelector("#apikey");
        element.classList.remove("d-none");
        var copyButton = document.querySelector("#cbutton");
        copyButton.classList.remove("d-none");
        getAPI();
        var ApiForm = document.querySelector("#generateapi");
        ApiForm.reset();
        navRedirect();
      }
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML = preContent;
    }
  };
  const copyToClipboard = (id) => {
    var element = document.querySelector("#calert");
    var element2 = document.querySelector("#apikey");

    var copyText = document.querySelector("#" + id).innerHTML;
    copy(copyText);
    element.classList.remove("d-none");
    element2.classList.add("d-none");
    var copyButton = document.querySelector("#cbutton");
    copyButton.classList.add("d-none");
    element2.classList.add("d-none");
    setTimeout(() => {
      element.classList.add("d-none");
      openDrawer();
    }, 2000);
  };
  function ProductInfo({ productdata }) {
    return (
      <>
        {productdata != null && Object.keys(productdata).length != 0 ? (
          productdata &&
          productdata.map((value, index) => (
            <tr>
              <td className="ps-9 text-dark">{value.name}</td>
              <td>
              <td className="text-start">
                <div className="d-flex align-items-center sphide ">
                  {" "}
                  <div className="symbol symbol-30px me-5">
                    {" "} 
                        <img
                          src={"/img/flag_in.png"}
                        /> 
                  </div>{" "}
                  <div className="d-flex justify-content-start flex-column">
                    {" "}
                    <a className="text-gray-700 fw-bold text-hover-primary fs-6 text-start">
                      Delhi (Noida)
                    </a>{" "}
                    <span className="text-muted fw-bold text-muted d-block fs-7 text-start">
                     India
                    </span>{" "}
                  </div>{" "}
                </div>
              </td>
              </td>
              
              <td className="text-start">
              {" "}
              <div className="d-flex flex-column w-100 me-2">
                {" "}
                <div className=" flex-stack mb-2">
                  {" "}
                  <span className="w-100 btn btn-active-primary btn-light-primary btn-sm sphide me-2 fs-7 fw-bold"
                  htmlFor="IP Address"
                  // id={"ip_address_sec"+index}
                  // onClick={()=>copyToClipboard(index)}
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  title="Click to copy IP Address"
                  >
                   <span  > 103.127.34.221</span>&nbsp;&nbsp;
                    {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen054.svg*/}
  <span className="svg-icon svg-icon-light svg-icon-2 d-none">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        opacity="0.5"
        d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
        fill="black"
      />
    </svg>
  </span>
  {/*end::Svg Icon*/}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
            </td>
            <td className="text-center">{value.created_at}</td>
              <td className="text-center">
                <button
                  type="button"
                  // onClick={() => deleteAPI(value.id)}
                  className="btn btn-sm btn-danger btn-active-light-danger"
                  id={"deleteApi-" + value.id}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))
        ) : ( <>
         <tr>
                     <td colspan="12" className="dataTables_empty" id="loader">
                      {/* <div className="loading-spinner"></div> */}
                      <TableLoader />
                    </td>
                    <td colspan="12" className="d-none" id="no_record" valign="top">
                      <div className="d-flex flex-column flex-center"> 
                        <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                      </div>
                    </td>
                    </tr>
          </>
        )}
        
      </>
    );
  }

  const [searchTerm, setSearchTerm] = useState("");
  function PaginatedItems({ itemsPerPage, ProductData, search }) {
    // We start with an empty list of items.
    const [pageCount, setPageCount] = useState(0);
    const [vpcData, setVpcData] = useState(null);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
      // Fetch items from another resources.

      const endOffset = itemOffset + itemsPerPage;

      //  ////console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      if (ProductData != null) {
        //   var str="Noida";
        // ////console.log(ProductData);
        const data = ProductData.filter((row) => {
          return Object.values(row).some((value) => {
            if (value != null) {
              if (
                value.toString().toLowerCase().includes(search.toLowerCase())
              ) {
                return value;
              }
            }
          });
        });
        //////console.log(data);
        // const data=ProductData.filter(item =>
        //        item.hostname.toLowerCase().includes(search.toLowerCase())
        //              );

        if (data.length <= 0) {
          loaderFunc();
        }
        setVpcData(data.slice(itemOffset, endOffset));
        //setCurrentItems(items.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(data.length / itemsPerPage));
        // alert(pageCount);
      }
    }, [itemOffset, itemsPerPage]);

    // Invoke when user click to request another page.

    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % ProductData.length;
      ////console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
      setItemOffset(newOffset);
    };
    var searchEle = document.querySelector("#searchClient");
    if (searchEle) {
      searchEle.focus();
    }
    return (
      <>
        <ProductInfo productdata={vpcData} />
        <td colspan="12">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </td>
      </>
    );
  } 
 
  return (
    <>
      <div className="col-xl-12">
        {/*begin::Tables Widget 9*/}
        <div className="card card-xl-stretch mb-5 mb-xl-12">
          {/*begin::Header*/}
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold fs-3 mb-1">
                Reserved IP{" "}
                {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                <span className="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      opacity="0.3"
                      d="M19.0687 17.9688H11.0687C10.4687 17.9688 10.0687 18.3687 10.0687 18.9688V19.9688C10.0687 20.5687 10.4687 20.9688 11.0687 20.9688H19.0687C19.6687 20.9688 20.0687 20.5687 20.0687 19.9688V18.9688C20.0687 18.3687 19.6687 17.9688 19.0687 17.9688Z"
                      fill="black"
                    />
                    <path
                      d="M4.06875 17.9688C3.86875 17.9688 3.66874 17.8688 3.46874 17.7688C2.96874 17.4688 2.86875 16.8688 3.16875 16.3688L6.76874 10.9688L3.16875 5.56876C2.86875 5.06876 2.96874 4.46873 3.46874 4.16873C3.96874 3.86873 4.56875 3.96878 4.86875 4.46878L8.86875 10.4688C9.06875 10.7688 9.06875 11.2688 8.86875 11.5688L4.86875 17.5688C4.66875 17.7688 4.36875 17.9688 4.06875 17.9688Z"
                      fill="black"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
              </span>
              <span className="text-muted mt-1 fw-bold fs-7">
                View your Reserved IPs
              </span>
            </h3>
            <div className="card-toolbar">
              <div
                className="position-relative my-1"
                style={{ marginRight: 20 }}
              >
                <input
                  type="text"
                  className="form-control form-control-sm"
                  data-kt-table-widget-4="search"
                  id="searchClient"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search"
                />
              </div>
              <div style={{ marginRight: 20 }}>
                <div className="dataTables_length">
                  <label className="w-125px">
                    <select
                      onChange={(e) => setPerPage(e.target.value)}
                      //data-control="select2"
                      className="form-select form-select-sm form-select-solid"
                      defaultValue={5}
                      id="perPage"
                    >
                      <option value={5} selected="">
                        5 Records
                      </option>
                      <option value={10}>10 Records</option>
                      <option value={25}>25 Records</option>
                      <option value={50}>50 Records</option>
                      <option value={100}>100 Records</option>
                    </select>
                  </label>
                </div>
              </div>
              <Link to="/reserved-ip/deploy">
              <a
                // onClick={openDrawer}
                className="btn btn-sm btn-primary btn-active-light-primary"
              >
                <span className="svg-icon ">
                <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none">
  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
  <g id="SVGRepo_iconCarrier">
    {" "}
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M9 17a1 1 0 102 0v-6h6a1 1 0 100-2h-6V3a1 1 0 10-2 0v6H3a1 1 0 000 2h6v6z"
    />{" "}
  </g>
</svg>

                </span>
                Add New Reserved IP
              </a>
              </Link>
            </div>
          </div>
          {/*end::Header*/}
          {/*begin::Body*/}
          {/*begin::Table container*/}
          <div className="table-responsive">
            {/*begin::Table*/}
            <table
              id="apiTable"
              className="table table-striped table-row-dashed table-row-gray-400 align-middle gs-0 gy-4"
            >
              {/*begin::Table head*/}
              <thead className="bg-secondary">
                <tr className="fw-bold">
                  <th className="w-20 ps-9">Name</th>
                  <th className="w-15">Location</th>
                  <th className="w-20 text-center">IP Address</th>
                  <th className="w-20 text-center">Created At</th> 
                  <th className="w-25 text-center">Action</th>
                </tr>
              </thead>
              {/*end::Table head*/}
              {/*begin::Table body*/}
              <tbody id="apiList" className="fs-6 fw-bold text-gray-600">
              {apiData!=null?(<>
        {access==false?(<>
          <tr>
          <td colspan={12}>
         <AccessDenied />
         </td>
         </tr>
        </>):(<> 
        {Object.keys(apiData).length!=0?(<>
        <PaginatedItems  ProductData={apiData} search={searchTerm} itemsPerPage={perPage}/>
        </>):(<>
          <tr>
          <td colspan={12}>
          <div className="card-body p-0 mt-10">
            {/*begin::Illustration*/}
            <div className="text-center px-4 mt-10">
              <span className="mw-100 mh-300px">
              <svg 
              viewBox="0 0 25 25"  
              width="234px"
              height="234px"
             xmlns="http://www.w3.org/2000/svg" fill="#21325B">
              <g id="SVGRepo_bgCarrier" strokeWidth={0} />
              <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
              <g id="SVGRepo_iconCarrier">
                <path d="M21.3,19a2.42,2.42,0,0,1-2.5.56l-2.35,2.35a.34.34,0,0,1-.49,0l-1-1a.36.36,0,0,1,0-.49l2.36-2.35a2.39,2.39,0,0,1,3.39-2.91L19.12,16.8l1,1,1.62-1.62A2.39,2.39,0,0,1,21.3,19ZM22,8v5.76A4.47,4.47,0,0,0,19.5,13a4.57,4.57,0,0,0-1.29.19V9.29H16.66V14A4.5,4.5,0,0,0,15,17.5a4.07,4.07,0,0,0,0,.5H4a2,2,0,0,1-2-2V8A2,2,0,0,1,4,6H20A2,2,0,0,1,22,8ZM11,15,9.09,9.27H7L5.17,15h1.7l.29-1.07H9L9.29,15Zm4.77-3.89a1.67,1.67,0,0,0-.55-1.35,2.43,2.43,0,0,0-1.62-.47h-2V15h1.54V13.11h.44a2.75,2.75,0,0,0,1-.17,1.82,1.82,0,0,0,.67-.44,1.63,1.63,0,0,0,.36-.64A2.36,2.36,0,0,0,15.75,11.11Zm-7.3.62-.12-.44-.15-.58c0-.21-.08-.37-.11-.5a4.63,4.63,0,0,1-.1.48c0,.19-.08.38-.13.57s-.08.34-.12.47l-.24.93H8.69Zm5.59-1a.63.63,0,0,0-.5-.17h-.4v1.31h.31a.9.9,0,0,0,.37-.07.59.59,0,0,0,.27-.22.75.75,0,0,0,.11-.42A.57.57,0,0,0,14,10.71Z" />
                <rect width={24} height={24} fill="none" />
              </g>
            </svg>
            

              </span>
            </div>
            {/*end::Illustration*/}
            {/*begin::Wrapper*/}
            <div className="card-px text-center py-5 mb-20">
              {/*begin::Title*/}
              <h1 className="fs-2x fw-bolder mb-5">API Keys</h1>
              <h2 className="fs-1x fw-bolder mb-5 text-muted">
              Interact with our services seamlessly
              </h2>
              {/*end::Title*/}
              {/*begin::Description*/}
              <p className="text-gray-400 fs-4 fw-bold mb-10">
              Safely interact with Utho's cloud services using our API and customize your<br/> cloud experience programmatically.              </p>
              {/*end::Description*/}
              {/*begin::Action*/} 
              <a
              onClick={openDrawer}
                href="#"
                className="btn btn-primary mb-10"
              >
                Create API Key
              </a> 
              {/*end::Action*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          </td>
          </tr>
          </>)}
        </>)}
        </>):(<>
          <tr>
            <td colspan={12}>
              <TableLoader />
              <TopBarHeaderLine />
            </td>
          </tr>
          
        </>)} 
               
              </tbody>
              {/*end::Table body*/}
            </table>
            {/*end::Table*/}
          </div>
          {/*end::Table container*/}
          {/*begin::Body*/}
        </div>
        {/*end::Tables Widget 9*/}
      </div>

      <div
        id="generateapi-modal"
        className="bg-body shadow drawer drawer-end"
        data-kt-drawer="true"
        data-kt-drawer-name="chat"
        data-kt-drawer-activate="true"
        data-kt-drawer-direction="end"
        data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
        style={{ maxWidth: "70%", important: true }}
      >
        {/*begin::Messenger*/}
        <div
          className="card w-100 rounded-0 border-0"
          id="kt_drawer_chat_messenger"
        >
          {/*begin::Card header*/}
          <div
            className="card-header pe-5"
            id="kt_drawer_chat_messenger_header"
          >
            {/*begin::Title*/}
            <div className="card-title">
              {/*begin::User*/}
              <div className="d-flex justify-content-center flex-column me-3">
                <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                  Add New Reserved IP
                </a>
              </div>
              {/*end::User*/}
            </div>
            {/*end::Title*/}
            {/*begin::Card toolbar*/}
            <div className="card-toolbar">
              {/*begin::Close*/}
              <div
                onClick={openDrawer}
                className="btn btn-sm btn-icon btn-active-light-primary"
                id="close_api"
              >
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                <span className="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x={6}
                      y="17.3137"
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(-45 6 17.3137)"
                      fill="black"
                    />
                    <rect
                      x="7.41422"
                      y={6}
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(45 7.41422 6)"
                      fill="black"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
              </div>
              {/*end::Close*/}
            </div>
            {/*end::Card toolbar*/}
          </div>
          {/*end::Card header*/}
          {/*begin::Card body*/}
          <div className="" id="">
            {/*begin::Form*/}
            <form name="generateapi" id="generateapi" className="form">
              {/*begin::Modal body*/}
              <div className="modal-body ">
                {/*begin::Scroll*/}
                <div
                  className="scroll-y me-n7 pe-7"
                  id="kt_modal_create_api_key_scroll"
                  data-kt-scroll="true"
                  data-kt-scroll-activate="{default: false, lg: true}"
                  data-kt-scroll-max-height="auto"
                  data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                  data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                  data-kt-scroll-offset="300px"
                >
                  {/*begin::Input group*/}
                  <div className="mb-5 fv-row">
                    {/*begin::Label*/}
                    <label className="required fs-5 fw-bold mb-2">
                      {" "}
                      IP Name
                    </label>
                    {/*end::Label*/}
                    {/*begin::Input*/}
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Your Reserved IP Name"
                      name="name"
                      id="apiName"
                    />
                    {/*end::Input*/}
                  </div>
                  {/*end::Input group*/} 
                </div>
                {/*end::Scroll*/}
              </div>
              {/*end::Modal body*/}
              {/*begin::Modal footer*/}
              <div className="modal-footer ">
                {/*begin::Button*/}
                <button
                  type="button"
                  onClick={addReservedIP}
                  className="btn btn-primary w-100"
                  id="importKey"
                >
                  <span className="indicator-label">Add Reserved IP</span>
                </button>
                {/*end::Button*/}
              </div>
              {/*end::Modal footer*/}
            </form>
            {/*end::Form*/}
          </div>
          <div
            className="btn btn-primary d-none"
            onClick={() => copyToClipboard("apikey")}
            id="cbutton"
            style={{ width: "100%" }}
          >
            Click to copy
          </div>
          <div
            id="calert"
            className="d-flex bg-light-success rounded border-success border border-dashed mb-12 p-6 d-none w-100 mt-7"
          >
            API key copied
          </div>
          <div
            id="apikey"
            className=" bg-light-warning rounded border-warning border border-dashed mb-12 p-6 d-none w-100 mt-7"
          >
            {apiKeyValue.current}
          </div>
          {/*end::Card body*/}
        </div>
        
        {/*end::Messenger*/}
      </div>
    </>
  );
}  
