import localStorage from "local-storage";

export function getLocalStorage(name){
    return localStorage.get(name);
 }
 
 export function setLocalStorage(key,value){
    localStorage.set(key,value);
 }
 
 export function deleteLocalStorage(name){
    localStorage.remove(name);
 }

 export function clearLocalStorage(){
   localStorage.clear();
 }