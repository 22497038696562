import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UseApi from "../../../../../../CustomHooks/useApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import TopBarHeaderLine from "../../../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../../../Loaders/TableLoader";
import { capitalizeFirstLetter } from "../../../../utils/utils";
import ManageSubnet from "../../subnet/ManageSubnet";
import CreateRoutes from "../../RouteTable/CreateRoutes";
import AttachRouteTable from "./AttachRouteTable";
import UseCallApi from "../../../../../../CustomHooks/useCallApi";


export default function RouteTable() {
    const [dataFetched, setDataFetched] = useState(false);
    const [pageCount, setPageCount] = useState(1);
    const [perPage, setPerPage] = useState(5);
    const [itemOffset, setItemOffset] = useState(0);
    const [activePage, setActivePage] = useState(0)
    const [subnetDataAll, setSubnetDataAll] = useState(null);
    const [dummySubnetDataAll, setDummySubnetDataAll] = useState(null);
    let navigate = useNavigate()
    const dataFetchedRef = useRef(false);
    var query = window.location.href;
    var vars = query.split("/");
    var id = vars[4];

    const handleChangeNavigate = (routeName, id) => {
        navigate(`/${routeName}/${id}`)
    }

    function ProductInfo({ productdata }) {
        return (
            <>
                {productdata != null && Object.keys(productdata)?.length != 0 ? (
                    productdata &&
                    productdata.map((value, index) => (
                        <tr>
                            <td className="text-start ps-9">
                                <span className="text-primary me-2 fs-7 fw-bold cursor-pointer" onClick={() => handleChangeNavigate("vpc-route-table", value.id)}>
                                    {value.id}
                                </span>
                            </td>
                            <td className="text-center">
                                <span className="me-2 fs-7 fw-bold">
                                    {value.vpcid}
                                </span>
                            </td>
                            <td className="text-center">
                                <span className="me-2 fs-7 fw-bold">
                                    {value.created_at}
                                </span>
                            </td>
                            <div className="d-flex justify-content-end flex-shrink-0">
                                <Link to={"/vpc-route-table/" + value.id + ""} >
                                    <a className="btn btn-primary btn-active-light-primary btn-sm"> Manage
                                        <span className="svg-icon ms-2 svg-icon-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"> <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black"></rect> <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black"></path>
                                            </svg>
                                        </span>
                                    </a>
                                </Link>
                            </div>
                        </tr>
                    ))
                ) : (
                    <>
                        <tr>
                            <td colspan="12" className="" id="no_record" valign="top">
                                <div className="d-flex flex-column flex-center">
                                    <div className="fs-5 fw-bolder text-dark mb-4">
                                        No Route found.
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </>
                )}
            </>
        );
    }
    const dettachedRoute = async (routeid) => {
        try {
            // Dismiss any previous toasts
            toast.dismiss();

            // Show a loading toast
            const dt = toast.loading("Please wait... System is processing your request.");

            // Find the submit button element
            const submitBtn = document.querySelector(`#del_route_btn_${routeid}`);

            if (submitBtn) {
                // Save the current button content and disable it while processing
                const preContent = submitBtn.innerHTML;
                submitBtn.classList.add("disabled");
                submitBtn.innerHTML = `Please wait... <span class="indicator-label">
              <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>`;

                // Send a POST request
                const responseData = await UseCallApi("post", `vpc/${id}/routetable/${routeid}/attach`, "");

                // Check for errors in the response
                if (responseData.status === "error") {
                    toast.update(dt, {
                        render: responseData.message,
                        type: "error",
                        isLoading: false,
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                // Handle success
                else if (responseData.status === "success") {
                    toast.update(dt, {
                        render: responseData.message,
                        type: "success",
                        isLoading: false,
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    // Call the getGateways function if it exists in props
                    getRoutes()
                }

                // Re-enable the button and restore its content
                submitBtn.classList.remove("disabled");
                submitBtn.innerHTML = preContent;
            }
        } catch (error) {
            // Catch any errors and display an error toast
            toast.error("An unexpected error occurred. Please try again.");
            console.error("Error adding VPC route:", error);
        }
    };
    const getRoutes = async () => {
        const dt = toast;
        const datas = await UseApi("get", 'vpc/' + id, "");
        if (datas) {
            setDataFetched(true);
        }

        if (!datas.routetable) {
            setSubnetDataAll([]);
            setDummySubnetDataAll([])
        } else {
            setSubnetDataAll(datas?.routetable);
            setDummySubnetDataAll(datas?.routetable)
        }
    };
    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        toast.dismiss();
        getRoutes();
    }, []);

    const handleSearch = (text) => {
        let data = subnetDataAll.filter((row) => {
            return (
                (row.name && row.name.toString().toLowerCase().includes(text.toLowerCase())))
        });
        // Update the state with the filtered data
        setDummySubnetDataAll(data);
        setPageCount(Math.ceil(data.length / perPage));
    }


    function PaginatedItems({ itemsPerPage, ProductData }) {
        setPageCount(Math.ceil(ProductData.length / parseInt(itemsPerPage)));
        const endOffset = parseInt(itemOffset) + parseInt(itemsPerPage);
        const handlePageClick = (event) => {
            setActivePage((old) => old = event.selected)
            const newOffset = event.selected * parseInt(itemsPerPage);
            setItemOffset(newOffset);
        };
        return (
            <>
                <ProductInfo productdata={ProductData.slice(parseInt(itemOffset), endOffset)} />
                <td colspan="12" className="ps-3">
                    <ReactPaginate
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        initialPage={activePage}
                        previousLabel="< previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                    />
                </td>
            </>
        );
    }
    if (dataFetched) {
        return (
            <>
                <div className="col-xl-12">
                    {/*begin::Tables Widget 9*/}
                    <div className="card card-xl-stretch mb-5 mb-xl-12">
                        {/*begin::Header*/}
                        <div className="card-header border-0 pt-5">
                            <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bold fs-3 mb-1">Route Table</span>
                            </h3>
                            <div className="card-toolbar">
                                <div
                                    className="position-relative my-1"
                                    style={{ marginRight: 20 }}
                                >
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        data-kt-table-widget-4="search"
                                        id="searchClient"
                                        // value={searchTerm}
                                        onChange={(e) => handleSearch(e.target.value)}
                                        placeholder="Search"
                                    />
                                </div>
                                <div style={{ marginRight: 20 }}>
                                    <div className="dataTables_length">
                                        <label className="w-125px">
                                            <select
                                                onChange={(e) => setPerPage(e.target.value)}
                                                //data-control="select2"
                                                className="form-select form-select-sm form-select-solid"
                                                defaultValue={5}
                                                id="perPage"
                                            >
                                                <option value={5} selected="">
                                                    5 Records
                                                </option>
                                                <option value={10}>10 Records</option>
                                                <option value={25}>25 Records</option>
                                                <option value={50}>50 Records</option>
                                                <option value={100}>100 Records</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>
                                {/* <AttachRouteTable/> */}
                            </div>
                        </div>
                        {/* <Header setPerPage={setPerPage} setPageCount={setPageCount} name={"Route Table"} data={subnetDataAll} /> */}
                        {/*end::Header*/}
                        {/*begin::Body*/}
                        {/*begin::Table container*/}
                        <div className="table-responsive">
                            {/*begin::Table*/}
                            <table
                                id="vpcTable"
                                className="table table-striped table-row-gray-300 align-middle gs-0 gy-4"
                            >
                                {/*begin::Table head*/}
                                <thead className="bg-secondary">
                                    <tr className="fw-bold ">
                                        {/* <th className="w-20px p-3" /> */}
                                        <th className="w-100px text-start ps-9 d-none">Name</th>
                                        <th className="w-250px text-start ps-9 sphide">Route Table ID</th>
                                        <th className="w-100px text-center sphide d-none">Subnet ID</th>
                                        <th className="w-250px text-center">VPC ID</th>
                                        <th className="w-150px text-center">Created_at</th>
                                        <th className="w-150px d-none">Status</th>
                                        <th className="w-150px text-center"></th>
                                    </tr>
                                </thead>
                                {/*end::Table head*/}
                                {/*begin::Table body*/}
                                <tbody id="mainTable">
                                    {subnetDataAll != null ? (
                                        <>
                                            {subnetDataAll.length != 0 ? (
                                                <>
                                                    <PaginatedItems
                                                        ProductData={dummySubnetDataAll}
                                                        itemsPerPage={perPage}
                                                    />
                                                    {/* <ProductInfo productdata={dummySubnetDataAll.slice(parseInt(itemOffset), endOffset)} />
                                                    <td colspan="12" className="ps-3">
                                                        <Footer itemsPerPage={perPage} ProductData={dummySubnetDataAll} setActivePage={setActivePage} setPageCount={setPageCount} setItemOffset={setItemOffset} itemOffset={itemOffset} activePage={activePage} pageCount={pageCount} />
                                                    </td> */}
                                                </>
                                            ) : (
                                                <>
                                                    <tr>
                                                        <td colspan={12}>
                                                            <div className="d-flex flex-column flex-center">
                                                                <div className="fs-5 fw-bolder text-dark mb-4">
                                                                    No Routes found.
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <tr>
                                                <td colspan={12}>
                                                    <TableLoader />
                                                    <TopBarHeaderLine />
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                </tbody>
                                {/*end::Table body*/}
                            </table>
                            {/*end::Table*/}
                        </div>
                        {/*end::Table container*/}
                        {/*begin::Body*/}
                    </div>
                    {/*end::Tables Widget 9*/}
                </div>
            </>
        );
    } else {
        return (<>
            <TableLoader />
            <TopBarHeaderLine />
        </>)
    }
}
