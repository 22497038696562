import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UsePost from "../../../../../CustomHooks/usePost";
import UseApi from "../../../../../CustomHooks/useApi";
import FormRest from "../../../utils/formReset/FormReset";

export default function CreatePeeringConnection(props) {
  const openDrawer = () => {
    var drawerElement = document.querySelector("#create-routes-modal");
    var drawer_overlay = document.querySelector("#drawer_overlay_routes");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      if (window.innerWidth <= 576) {
        // Adjust this value based on your breakpoint
        drawerElement.style.width = "100%";
      } else {
        drawerElement.style.width = "40%";
      }
      drawer_overlay.classList.toggle("d-none");
    }
  };
  const [vpcData, setVpcData] = useState(null);
  const [dczoneData, setDczoneData] = useState(null);
  const getDczone = async () => {
    let arr = [];
    const data = await UseApi("get", "dczones", "");
    if (data) {
      if (Object.keys(data).length === 0) {
        setDczoneData(null);
      } else {
        // var dcArr=[];
        data.dczones.map((value, index) => {
          if (value.status === "active" && value.product_vpc === "1") {
            arr.push(value);
            // dcArr.push(value);
            setDczoneData(arr);
            // ddata.push(value);
          }
        });
        // setDdata(dcArr);
      }
    }
  };

  const getVPC = async (slug) => {
    setVpcData(null);
    const datas = await UseApi('get', 'vpc/dc/' + slug, '');
    if (datas.status == "error" && datas.message == "No VPC.") {
      setVpcData([]);
    } else {
      setVpcData(datas?.vpc);
    }
  }

  const chooseVpc = (type) => {
    var requester_vpc_id = document.querySelector("#requester_vpc_id");
    var feedback_requester_vpc_id = document.querySelector("#feedback_requester_vpc_id");
    var accepter_vpc_id = document.querySelector("#accepter_vpc_id");
    var feedback_accepter_vpc_id = document.querySelector("#feedback_accepter_vpc_id");
    if (requester_vpc_id && accepter_vpc_id) {
      if (requester_vpc_id.value == accepter_vpc_id.value) {
        if (type == "requester") {
          feedback_requester_vpc_id.classList.remove("text-muted");
          feedback_requester_vpc_id.classList.add("text-danger");
          feedback_requester_vpc_id.innerHTML = "VPC can't be duplicate, Please select different VPC or create new one"
        }
        if (type == "accepter") {
          feedback_accepter_vpc_id.classList.remove("text-muted");
          feedback_accepter_vpc_id.classList.add("text-danger");
          feedback_accepter_vpc_id.innerHTML = "VPC can't be duplicate, Please select different VPC or create new one"
        }
      }
      else {
        feedback_requester_vpc_id.classList.remove("text-danger");
        feedback_requester_vpc_id.classList.add("text-muted");
        feedback_requester_vpc_id.innerHTML = "VPC ID (Requester)";
        feedback_accepter_vpc_id.classList.remove("text-danger");
        feedback_accepter_vpc_id.classList.add("text-muted");
        feedback_accepter_vpc_id.innerHTML = "VPC ID (Accepter)";
      }
    }
  }
  useEffect(() => {
    getDczone();
  }, [])

  const addVpcPeering = async () => {
    toast.dismiss();
    const dt = toast.loading(
      "Please wait... System is processing your request."
    );
    const submitBtn = document.querySelector("#add-vpc-peering-btn");
    const connection_name = document.querySelector("#connection_name");
    console.log(connection_name.value);

    if (submitBtn) {
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML =
        'Please wait... <span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      if (connection_name.value != '') {
        const responseData = await UsePost(
          "post",
          "vpc/peering",
          "create-peering-form"
        );

        if (responseData.status === "error") {
          toast.update(dt, {
            render: responseData.message,
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          FormRest('create-peering-form')
          submitBtn.classList.remove("disabled");
          submitBtn.innerHTML = preContent;
        } else if (responseData.status === "success") {
          toast.update(dt, {
            render: responseData.message,
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          FormRest('create-peering-form')
          submitBtn.classList.remove("disabled");
          submitBtn.innerHTML = preContent;
          if (props && props.getPeering) {
            props.getPeering();
          }
          openDrawer();
        }
      } else {
        setTimeout(() => {
          toast.update(dt, {
            render: "Please provide the name of the peering connection",
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }, 1000)
        FormRest('create-peering-form')
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
      }
    }
  };
  return (
    <>
      <a className="btn btn-sm btn-primary me-2" onClick={openDrawer}>
        {/*begin::Svg Icon | path: assets/media/icons/duotune/arrows/arr009.svg*/}
        <span className="svg-icon svg-icon-muted svg-icon-1hx">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              opacity="0.3"
              d="M21 13H3C2.4 13 2 12.6 2 12C2 11.4 2.4 11 3 11H21C21.6 11 22 11.4 22 12C22 12.6 21.6 13 21 13Z"
              fill="black"
            />
            <path
              d="M12 22C11.4 22 11 21.6 11 21V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V21C13 21.6 12.6 22 12 22Z"
              fill="black"
            />
          </svg>
        </span>
        {/*end::Svg Icon*/}
        Create Peering Connection
      </a>
      <div
        id="drawer_overlay_routes"
        onClick={openDrawer}
        className="drawer-overlay d-none"
        style={{ zIndex: 109, width: "100%" }}
      ></div>
      <div
        id="create-routes-modal"
        className="bg-body shadow drawer drawer-end"
        data-kt-drawer="true"
        data-kt-drawer-name="chat"
        data-kt-drawer-activate="true"
        data-kt-drawer-direction="end"
        // data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
        style={{ important: true, zIndex: 110 }}
      >
        {/*begin::Messenger*/}
        <div
          className="card w-100 rounded-0 border-0"
          id="kt_drawer_chat_messenger"
        >
          {/*begin::Card header*/}
          <div
            className="card-header pe-5"
            id="kt_drawer_chat_messenger_header"
          >
            {/*begin::Title*/}
            <div className="card-title">
              {/*begin::User*/}
              <div className="d-flex justify-content-center flex-column me-3">
                <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                  Create peering connection
                </a>
              </div>
              {/*end::User*/}
            </div>
            {/*end::Title*/}
            {/*begin::Card toolbar*/}
            <div className="card-toolbar">
              {/*begin::Close*/}
              <div
                onClick={openDrawer}
                className="btn btn-sm btn-icon btn-active-light-primary"
                id="close_api"
              >
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                <span className="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x={6}
                      y="17.3137"
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(-45 6 17.3137)"
                      fill="black"
                    />
                    <rect
                      x="7.41422"
                      y={6}
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(45 7.41422 6)"
                      fill="black"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
              </div>
              {/*end::Close*/}
            </div>
            {/*end::Card toolbar*/}
          </div>
          {/*end::Card header*/}
          {/*begin::Card body*/}
          <div className="" id="">
            {/*begin::Form*/}
            <form id="create-peering-form" className="form">
              {/*begin::Modal body*/}
              <div className="modal-body ">
                {/*begin::Scroll*/}
                <div className=" me-n7 pe-7">
                  <div className="pt-0 row">
                    <div className="col-lg-12">
                      {/*begin::Input group*/}
                      <div className="mb-10 fv-row fv-plugins-icon-container">
                        {/*begin::Label*/}
                        <label className="required form-label">
                          Name
                        </label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <input
                          type="text"
                          name="name"
                          id="connection_name"
                          className="form-control mb-2"
                          placeholder="Enter the name"
                          defaultValue=""
                        />
                        {/*end::Input*/}
                        {/*begin::Description*/}
                        <div className="text-muted fs-7">
                          A Peering name is required and recommended to be
                          unique.
                        </div>
                        {/*end::Description*/}
                        <div className="fv-plugins-message-container invalid-feedback" />
                      </div>
                      {/*end::Input group*/}
                    </div>
                    <div className="col-lg-12">
                      {/*begin::Input group*/}
                      <div className="mb-10 fv-row fv-plugins-icon-container">
                        {/*begin::Label*/}
                        <label className="required form-label">
                          Select the data-center location
                        </label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <select
                          name="dcslug"
                          defaultValue=""
                          id="dcslug"
                          className="form-select mb-2"
                          onChange={(e) => getVPC(e.target.value)}
                        >
                          <option value="">Select Dc-zone</option>
                          {dczoneData != null ? (<>
                            {dczoneData.map((value, index) => (
                              <>
                                <option value={value.slug}>{value.city} ({value.country})</option>
                              </>
                            ))}
                          </>) : ('')}
                        </select>
                        {/*end::Input*/}
                        {/*begin::Description*/}
                        <div className="text-muted fs-7">
                          Data center location of the vpc's
                        </div>
                        {/*end::Description*/}
                        <div className="fv-plugins-message-container invalid-feedback" />
                      </div>
                      {/*end::Input group*/}
                    </div>
                    <div className="col-lg-12">
                      {/*begin::Input group*/}
                      <div className="mb-10 fv-row fv-plugins-icon-container">
                        {/*begin::Label*/}
                        <label className="required form-label">
                          Select a local VPC to peer with
                        </label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <select
                          name="requester_vpc_id"
                          id="requester_vpc_id"
                          defaultValue=""
                          className="form-select mb-2"
                          onChange={(e) => chooseVpc('requester')}
                        >
                          <option value="">Select a VPC</option>
                          {vpcData != null ? (<>
                            {vpcData.map((value, index) => (
                              <>
                                <option value={value.id}>VPC_{value.network}/{value.size}-{value.id}</option>
                              </>
                            ))}
                          </>) : ('')}
                        </select>
                        {/*end::Input*/}
                        {/*begin::Description*/}
                        <div className="text-muted fs-7" id="feedback_requester_vpc_id">
                          VPC ID (Requester)
                        </div>
                        {/*end::Description*/}
                        <div className="fv-plugins-message-container invalid-feedback" />
                      </div>
                      {/*end::Input group*/}
                    </div>
                    <div className="col-lg-12">
                      {/*begin::Input group*/}
                      <div className="mb-10 fv-row fv-plugins-icon-container">
                        {/*begin::Label*/}
                        <label className="required form-label">
                          Select another VPC to peer with
                        </label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <select
                          name="accepter_vpc_id"
                          defaultValue=""
                          id="accepter_vpc_id"
                          className="form-select mb-2"
                          onChange={(e) => chooseVpc('accepter')}
                        >
                          <option value="">Select a VPC</option>
                          {vpcData != null ? (<>
                            {vpcData.map((value, index) => (
                              <>
                                <option value={value.id}>VPC_{value.network}/{value.size}-{value.id}</option>
                              </>
                            ))}
                          </>) : ('')}
                        </select>
                        {/*end::Input*/}
                        {/*begin::Description*/}
                        <div className="text-muted fs-7" id="feedback_accepter_vpc_id">
                          VPC ID (Requester)
                        </div>
                        {/*end::Description*/}
                        <div className="fv-plugins-message-container invalid-feedback" />
                      </div>
                      {/*end::Input group*/}
                    </div>


                    <div className="col-lg-12">
                      <a
                        className="btn btn-primary mt-5 w-100"
                        onClick={addVpcPeering}
                        id="add-vpc-peering-btn"
                      >
                        <span className="svg-icon svg-icon-muted svg-icon-1hx">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              opacity="0.3"
                              d="M21 13H3C2.4 13 2 12.6 2 12C2 11.4 2.4 11 3 11H21C21.6 11 22 11.4 22 12C22 12.6 21.6 13 21 13Z"
                              fill="black"
                            />
                            <path
                              d="M12 22C11.4 22 11 21.6 11 21V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V21C13 21.6 12.6 22 12 22Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        Create Peering Connection
                      </a>
                    </div>
                  </div>
                </div>
                {/*end::Scroll*/}
              </div>
              {/*end::Modal body*/}
            </form>
            {/*end::Form*/}
          </div>
          {/*end::Card body*/}
        </div>

        {/*end::Messenger*/}
      </div>
    </>
  );
}
