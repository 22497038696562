import Cookies from "js-cookie";

export function getCookie(name){
   return Cookies.get(name);
   
}

export function getAllCookie(){
   return Cookies.get();
   
}

export function setCookie(key, value, expiresAt=30){
   var current_url_string = window.location.href;
   const current_url = new URL(current_url_string);
   const current_domain = current_url.hostname; 

   Cookies.set(key, value, { expires: expiresAt, path: '/', domain: "." + current_domain });
}

export function deleteCookie(name, domain){
    Cookies.remove(name, { domain, path: "/" });;
}
