import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Search from "../../../../utils/search/Search";

function Marketplace(props) {
  const { marketplaceData, selectOne, selectMarketPlace, marketplaceid} = props;

  useEffect(() => {
    if (marketplaceData === undefined)
      throw new Error("Missing prop: marketplaceData");
    if (selectOne === undefined)
      throw new Error("Missing prop: selectOne");
    if (marketplaceid === undefined || marketplaceid === null)
      throw new Error("Missing prop: marketplaceid");
    if (selectMarketPlace === undefined)
      throw new Error("Missing prop: selectMarketPlace");
  }, [marketplaceData, selectOne, selectMarketPlace]);

  const [mpData, setMpData] = useState(marketplaceData);

  useEffect(()=>{
    if(marketplaceid){
    selectOne("marketplace", marketplaceid);
    selectMarketPlace("stack", marketplaceid);
    }
  },[mpData])
  return (
    <>
    {mpData!=null?(<>
      <Search results={marketplaceData} onSearch={setMpData} fields={['title']} searchPlaceholder="marketplace app" />
      {mpData.length != 0 ? (<>
          {mpData.map((value, index) => (
            <>
              <div className="col-md-4 col-lg-12 col-xxl-4">
                {/*begin::Option*/}
                <input
                  className="btn-check deploy_checkbox marketplace_radio"
                  type="radio"
                  name="image_OS"
                  defaultValue={value.id}
                  id={"marketplace" + value.id}
                />
                <label
                  onClick={(e) => {
                    e.stopPropagation();
                    selectOne("marketplace", value.id);
                    selectMarketPlace("stack", value.id);
                  }}
                  htmlFor={"marketplace" + value.id}
                  className=" label versionOs text-start border-1 border-gray-300 border px-6 py-2 d-flex"
                >
                  {/*begin::Svg Icon | path: icons/duotune/communication/com003.svg*/}
                  {/*begin::Avatar*/}
                  <div className="symbol symbol-50px me-5">
                    <img src={"/" + value.logo_url} className="" alt="" />
                  </div>
                  {/*end::Svg Icon*/}
                  <span className="d-block  text-start p-3">
                    <span className="text-dark d-block fs-4">
                      {value.title}
                    </span>
                  </span>
                  {/*begin::Ribbon*/}
                  <div
                    className="d-none tickMarkmarketplace ribbon ribbon-triangle ribbon-top-start border-primary"
                    id={"tickMarkmarketplace-" + value.id}
                  >
                    {/*begin::Ribbon icon*/}
                    <div className="ribbon-icon mt-n5">
                      <i className="bi bi-check2 fs-2 text-white" />
                    </div>
                    {/*end::Ribbon icon*/}
                  </div>
                  {/*end::Ribbon*/}
                </label>
                {/*end::Option*/}
              </div>
            </>
          ))}
        </>
      ) : (
        <>
          <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
            {/*begin::Wrapper*/}

            <div className="pt-lg-10 mb-10">
              {/*begin::Message*/}
              <div className="fw-bold fs-5  mb-15 badge badge-light-primary">
                No Marketplace Available
              </div>
              {/*end::Message*/}
            </div>
            {/*end::Wrapper*/}
          </div>
        </>
      )}
    </>):('')
}
</>)
}

Marketplace.propTypes = {
  marketplaceData: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.oneOf([null]),
  ]).isRequired,
  marketplaceid: PropTypes.number.isRequired,
  selectOne: PropTypes.func.isRequired,
  selectMarketPlace: PropTypes.func.isRequired,
};

export default Marketplace;
