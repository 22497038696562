import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import UseApi from "../../../../../CustomHooks/useApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import TopBarHeaderLine from "../../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../../Loaders/TableLoader";
import { capitalizeFirstLetter } from "../../../utils/utils";
import ManageSubnet from "../subnet/ManageSubnet";
import CreateRoutes from "./CreateRoutes";

export default function RouteTable() {
  const [dataFetched, setDataFetched] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [itemOffset, setItemOffset] = useState(0);
  const [activePage, setActivePage] = useState(0)
  const [subnetDataAll, setSubnetDataAll] = useState(null);
  const [dummySubnetDataAll, setDummySubnetDataAll] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const dataFetchedRef = useRef(false);

  function ProductInfo({ productdata }) {
    return (
      <>
        {productdata != null && Object.keys(productdata)?.length != 0 ? (
          productdata &&
          productdata.map((value, index) => (
            <tr>
              {/* <td /> */}
              <td className="text-start ps-9">
                {/* <div className="d-flex flex-column w-100 me-2">
                  <div className="d-flex flex-stack mb-2"> */}
                <span className=" me-2 fs-7 fw-bold">
                  {value.uuid}
                </span>
                {/* </div>
                </div> */}
              </td>
              <td className="text-center">
                {/* <div className="d-flex flex-column w-100 me-2">
                  <div className="d-flex flex-stack mb-2"> */}
                <span className=" me-2 fs-7 fw-bold">
                  {value.vpc_id}
                </span>
                {/* </div>
                </div> */}
              </td>
              <td className="text-center">
                <span className=" me-2 fs-7 fw-bold">
                  {value.created_at}
                </span>
                {/* <div className="d-flex flex-column w-100 me-2">
                  <div className="d-flex flex-stack mb-2"> */}
                {/* <span className="badge badge-success badge-lg fw-bold">{capitalizeFirstLetter(value.subnet_type)}</span> */}
                {/* </div>
                </div> */}
              </td>
              <td className="text-end pe-9">
                {" "}
                <div className=" justify-content-end flex-shrink-0">
                  <Link to={"/vpc/routetable/" + value.id + ""} >
                    <a
                      className="btn btn-primary btn-active-light-primary btn-sm sphide"
                    >
                      {" "}
                      Manage{" "}
                      <span className="svg-icon ms-2 svg-icon-3">
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          {" "}
                          <rect
                            opacity="0.5"
                            x={18}
                            y={13}
                            width={13}
                            height={2}
                            rx={1}
                            transform="rotate(-180 18 13)"
                            fill="black"
                          />{" "}
                          <path
                            d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                            fill="black"
                          />{" "}
                        </svg>{" "}
                      </span>{" "}
                    </a>{" "}
                  </Link>
                </div>
              </td>
            </tr>
          ))
        ) : (
          <>
            <tr>
              <td colspan="12" className="" id="no_record" valign="top">
                <div className="d-flex flex-column flex-center">
                  <div className="fs-5 fw-bolder text-dark mb-4">
                    No items found.
                  </div>
                </div>
              </td>
            </tr>
          </>
        )}
      </>
    );
  }

  const getRoutes = async () => {
    const dt = toast;
    const datas = await UseApi("get", "vpc/routetable", "");
    if (datas) {
      setDataFetched(true);
    }

    if (datas.status == "error") {
      setSubnetDataAll([]);
      setDummySubnetDataAll([])
    } else {
      setSubnetDataAll(datas.data);
      setDummySubnetDataAll(datas.data)
    }
  };
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getRoutes();
  }, []);

  const handleSearch = (text) => {
    let data = subnetDataAll.filter((row) => {
      return (
        (row.name && row.name.toString().toLowerCase().includes(text.toLowerCase())))
    });

    // Update the state with the filtered data
    setDummySubnetDataAll(data)
    setPageCount(Math.ceil(data.length / perPage));
  }


  function PaginatedItems({ itemsPerPage, ProductData }) {
    setPageCount(Math.ceil(ProductData.length / parseInt(itemsPerPage)));
    const endOffset = parseInt(itemOffset) + parseInt(itemsPerPage);
    const handlePageClick = (event) => {
      setActivePage((old) => old = event.selected)
      const newOffset = event.selected * parseInt(itemsPerPage);
      setItemOffset(newOffset);
    };
    return (
      <>
        <ProductInfo productdata={ProductData.slice(parseInt(itemOffset), endOffset)} />
        {ProductData.length > 0 && ProductData.length > 5 ?
          <td colspan="12" className="ps-3">
            <ReactPaginate
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              initialPage={activePage}
              previousLabel="< previous"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </td>
          :
          ""
        }
      </>
    );
  }

  if (dataFetched) {
    return (
      <>
        <div className="col-xl-12">
          {/*begin::Tables Widget 9*/}
          <div className="card card-xl-stretch mb-5 mb-xl-12">
            {/*begin::Header*/}
            <div className="card-header border-0 pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1">Route Table</span>
              </h3>
              <div className="card-toolbar">
                <div
                  className="position-relative my-1"
                  style={{ marginRight: 20 }}
                >
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    data-kt-table-widget-4="search"
                    id="searchClient"
                    onChange={(e) => handleSearch(e.target.value)}
                    placeholder="Search"
                  />
                </div>
                <div style={{ marginRight: 20 }}>
                  <div className="dataTables_length">
                    <label className="w-125px">
                      <select
                        onChange={(e) => setPerPage(e.target.value)}
                        //data-control="select2"
                        className="form-select form-select-sm form-select-solid"
                        defaultValue={5}
                        id="perPage"
                      >
                        <option value={5} selected="">
                          5 Records
                        </option>
                        <option value={10}>10 Records</option>
                        <option value={25}>25 Records</option>
                        <option value={50}>50 Records</option>
                        <option value={100}>100 Records</option>
                      </select>
                    </label>
                  </div>
                </div>
                <CreateRoutes getRoutes={getRoutes} />
              </div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            {/*begin::Table container*/}
            <div className="table-responsive">
              {/*begin::Table*/}
              <table
                id="vpcTable"
                className="table table-striped table-row-gray-300 align-middle gs-0 gy-4"
              >
                {/*begin::Table head*/}
                <thead className="bg-secondary">
                  <tr className="fw-bold ">
                    {/* <th className="w-20px p-3" /> */}
                    <th className="w-100px text-start ps-9 d-none">Name</th>
                    <th className="w-100px text-start ps-9 sphide">Route Table ID</th>
                    <th className="w-100px text-center sphide d-none">Subnet ID</th>
                    <th className="w-250px text-center">VPC ID</th>
                    <th className="w-150px text-center">Created_at</th>
                    <th className="w-150px d-none">Status</th>
                    <th className="w-150px text-center"></th>
                  </tr>
                </thead>
                {/*end::Table head*/}
                {/*begin::Table body*/}
                <tbody id="mainTable">
                  {subnetDataAll != null ? (
                    <>
                      {subnetDataAll.length != 0 ? (
                        <>
                          <PaginatedItems
                            ProductData={dummySubnetDataAll}
                            itemsPerPage={perPage}
                          />
                        </>
                      ) : (
                        <>
                          <tr>
                            <td colspan={12}>
                              <div className="card-body p-0 mt-10">
                                {/*begin::Illustration*/}
                                <div className="text-center px-4 mt-10">
                                  <span className="mw-100 mh-300px">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="234px"
                                      height="234px"
                                      viewBox="0 0 24 24"
                                      fill="#21325B"
                                    >
                                      <path
                                        opacity="0.3"
                                        d="M22 14V18C22 19.1 21.1 20 20 20C20 20.6 19.6 21 19 21C18.4 21 18 20.6 18 20H6C6 20.6 5.6 21 5 21C4.4 21 4 20.6 4 20C2.9 20 2 19.1 2 18V14C2 12.9 2.9 12 4 12H20C21.1 12 22 12.9 22 14ZM6 15C5.4 15 5 15.4 5 16C5 16.6 5.4 17 6 17C6.6 17 7 16.6 7 16C7 15.4 6.6 15 6 15ZM10 15C9.4 15 9 15.4 9 16C9 16.6 9.4 17 10 17C10.6 17 11 16.6 11 16C11 15.4 10.6 15 10 15ZM14 15C13.4 15 13 15.4 13 16C13 16.6 13.4 17 14 17C14.6 17 15 16.6 15 16C15 15.4 14.6 15 14 15Z"
                                        fill="#21325B"
                                      />
                                      <path
                                        d="M18 2C18.6 2 19 2.4 19 3V12H17V3C17 2.4 17.4 2 18 2Z"
                                        fill="#21325B"
                                      />
                                    </svg>
                                  </span>
                                </div>
                                {/*end::Illustration*/}
                                {/*begin::Wrapper*/}
                                <div className="card-px text-center py-5 mb-20">
                                  {/*begin::Title*/}
                                  <h2 className="fs-1x fw-bolder mb-5 mt-5 text-muted">
                                    Build a custom route in the VPC
                                  </h2>
                                  {/*end::Title*/}
                                  {/*begin::Description*/}
                                  <p className="text-gray-400 fs-4 fw-bold mb-10">
                                    Take control with Utho's VPC routes. Create a
                                    route for your VPC
                                  </p>
                                  {/*end::Description*/}
                                  {/*begin::Action*/}
                                  <CreateRoutes getRoutes={getRoutes} />
                                  {/*end::Action*/}
                                </div>
                                {/*end::Wrapper*/}
                              </div>
                            </td>
                          </tr>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <tr>
                        <td colspan={12}>
                          <TableLoader />
                          <TopBarHeaderLine />
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
                {/*end::Table body*/}
              </table>
              {/*end::Table*/}
            </div>
            {/*end::Table container*/}
            {/*begin::Body*/}
          </div>
          {/*end::Tables Widget 9*/}
        </div>
      </>
    );
  } else {
    return (<>
      <TableLoader />
      <TopBarHeaderLine />
    </>)
  }
}
