import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import UseApi from "../../../../../CustomHooks/useApi";
// import Subnet from "../InternetGateway/subnet/Subnet";
import UsePost from "../../../../../CustomHooks/usePost";
import { useNavigate } from "react-router-dom";
import TopBarHeaderLine from "../../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../../Loaders/TableLoader";
import copy from "copy-to-clipboard";
import UseCallApi from "../../../../../CustomHooks/useCallApi";
import SubnetNatGateway from "./manageNatGateway/SubnetNatGateway";

export default function ManageNatGateway() {
    let navigate = useNavigate()
    const [internetGatewayData, setInternetGatewayData] = useState(null)
    var query = window.location.href;
    var vars = query.split("/");
    var id = vars[5];

    const getNatGateway = async () => {
        const dt = toast.dismiss();
        const datas = await UseApi("get", `vpc/natgateway/${id}`, "");
        if (datas) {
            // setDataFetched(true);
        }
        if (datas.value == "error") {
            setInternetGatewayData([])
        } else {
            setInternetGatewayData(datas.data)
        }

    }
    useEffect(() => {
        getNatGateway()
    }, [])

    const destroyNatGateway = async (id) => {
        try {
            // Dismiss any previous toasts
            toast.dismiss();

            // Show a loading toast
            const dt = toast.loading("Please wait... System is processing your request.");

            // Find the submit button element
            const submitBtn = document.querySelector(`#destroy-btn_${id}`);

            if (submitBtn) {
                // Save the current button content and disable it while processing
                const preContent = submitBtn.innerHTML;
                submitBtn.classList.add("disabled");
                submitBtn.innerHTML = `Please wait... <span class="indicator-label">
                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>`;

                // Send a POST request
                const responseData = await UseCallApi("delete", `vpc/natgateway/${id}`, "");

                // Check for errors in the response
                if (responseData.status === "error") {
                    toast.update(dt, {
                        render: responseData.message,
                        type: "error",
                        isLoading: false,
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                // Handle success
                else if (responseData.status === "success") {
                    toast.update(dt, {
                        render: responseData.message,
                        type: "success",
                        isLoading: false,
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    navigate("/vpc/natgateways")
                    // Call the getGateways function if it exists in props
                    // getGateways();
                }

                // Re-enable the button and restore its content
                submitBtn.classList.remove("disabled");
                submitBtn.innerHTML = preContent;
            }
        } catch (error) {
            // Catch any errors and display an error toast
            toast.error("An unexpected error occurred. Please try again.");
            console.error("Error adding VPC route:", error);
        }
    };

    const copyToClipboard = (target, targetBy) => {
        var ip_address = document.querySelector("#ip_address")
        var btn_id = document.querySelector("#" + targetBy);
        var target_id = document.querySelector("#" + target);
        if (btn_id && !ip_address.classList.contains('disabled')) {
            var copyText = btn_id.innerHTML;
            var copyEle = target_id.innerHTML;
            copy(copyEle);
            ip_address.classList.add("disabled")
            // svgIconElement.classList.add('d-none')
            btn_id.innerHTML = `<svg
          xmlns="http://www.w3.org/2000/svg"
          width=24px
          height=24px
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M9.89557 13.4982L7.79487 11.2651C7.26967 10.7068 6.38251 10.7068 5.85731 11.2651C5.37559 11.7772 5.37559 12.5757 5.85731 13.0878L9.74989 17.2257C10.1448 17.6455 10.8118 17.6455 11.2066 17.2257L18.1427 9.85252C18.6244 9.34044 18.6244 8.54191 18.1427 8.02984C17.6175 7.47154 16.7303 7.47154 16.2051 8.02984L11.061 13.4982C10.7451 13.834 10.2115 13.834 9.89557 13.4982Z"
            fill="black"
          />
        </svg>`;
            setTimeout(() => {
                btn_id.innerHTML = copyText;
                ip_address.classList.remove("disabled")
                // svgIconElement.classList.remove('d-none')
            }, 2000);
        }

    }

    return (
        <>
            <div className="col-xl-12">
                {internetGatewayData != null ? (<>
                    {/* <div className="post d-flex flex-column-fluid">
                    <div className="container-xxl" id="appresult"> */}
                    <div className="">
                        <div className="" id="appresult">
                            <div className="card mb-6 mb-xl-9">
                                <div className="card-body pt-9 pb-0">
                                    {/*begin::Details*/}
                                    <div className="d-flex flex-wrap flex-sm-nowrap mb-6">
                                        {/*begin::Image*/}
                                        <div className="d-flex flex-center flex-shrink-0 bg-light rounded w-55px h-55px w-lg-55px h-lg-55px me-7 mb-4" id="flag-icon">
                                            {/*begin::Image input*/}
                                            {/* <div className="image-input image-input-empty mt-5" data-kt-image-input="true"> */}
                                            <span className="w-50">
                                                {/* <span className="mw-100 mh-300px"> */}
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="40px" fill="currentColor" class="bi bi-bezier2" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M1 2.5A1.5 1.5 0 0 1 2.5 1h1A1.5 1.5 0 0 1 5 2.5h4.134a1 1 0 1 1 0 1h-2.01q.269.27.484.605C8.246 5.097 8.5 6.459 8.5 8c0 1.993.257 3.092.713 3.7.356.476.895.721 1.787.784A1.5 1.5 0 0 1 12.5 11h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5H6.866a1 1 0 1 1 0-1h1.711a3 3 0 0 1-.165-.2C7.743 11.407 7.5 10.007 7.5 8c0-1.46-.246-2.597-.733-3.355-.39-.605-.952-1-1.767-1.112A1.5 1.5 0 0 1 3.5 5h-1A1.5 1.5 0 0 1 1 3.5zM2.5 2a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm10 10a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z" />
                                                </svg>
                                            </span>
                                            {/* </span> */}

                                            {/* <div className="image-input-wrapper w-60px h-40px" /> */}
                                            {/* </div> */}
                                            {/*end::Image input*/}
                                        </div>
                                        {/*end::Image*/}
                                        {/*begin::Wrapper*/}
                                        <div className="flex-grow-1">
                                            {/*begin::Head*/}
                                            <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                                {/*begin::Details*/}
                                                <div className="d-flex flex-column">
                                                    {/*begin::Status*/}
                                                    <div className="d-flex align-items-center mb-1">
                                                        <a
                                                            className="text-gray-800 text-hover-primary fs-2 fw-bold me-3"
                                                            id="clustername"
                                                        >
                                                            {internetGatewayData[0]?.name}
                                                        </a>
                                                    </div>
                                                    {/*end::Status*/}
                                                    {/*begin::Description*/}
                                                    <div className="flex-grow-1 d-none">
                                                        <p className="text-muted">
                                                            <span
                                                                className="text-muted text-hover-primary fw-bold fs-6"
                                                                id="country"
                                                            >
                                                                {/* {sqsData?.dclocation?.country} */}
                                                            </span>
                                                            <span className="text-muted fw-bold" id="location">
                                                                {/* {sqsData?.dclocation?.location} */}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    {/*end::Description*/}
                                                    {/*begin::Wrapper*/}
                                                    <div className="d-flex flex-column flex-grow-1 pe-8 d-none">
                                                        {/*begin::Stats*/}
                                                        <div className="d-flex flex-wrap">
                                                            {/*begin::Stat*/}
                                                            <div className="min-w-125px py-3 px-4 me-6 mb-3">
                                                                {/*begin::Number*/}
                                                                <div className="d-flex align-items-center">
                                                                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
                                                                    <span className="svg-icon svg-icon-3 svg-icon-success me-2">
                                                                        {/*begin::Svg Icon | path: assets/media/icons/duotune/abstract/abs027.svg*/}
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width={24}
                                                                            height={24}
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                        >
                                                                            <path
                                                                                opacity="0.3"
                                                                                d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z"
                                                                                fill="black"
                                                                            />
                                                                            <path
                                                                                d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z"
                                                                                fill="black"
                                                                            />
                                                                        </svg>
                                                                        {/*end::Svg Icon*/}
                                                                    </span>
                                                                    {/*end::Svg Icon*/}
                                                                    {/*begin::Label*/}
                                                                    <div className="fw-bold fs-6 text-gray-400 me-3">Cluster IP : </div>
                                                                    {/*end::Label*/}
                                                                    <div className="fs-2 fw-bold" id="masterip">
                                                                        {/* {sqsIp} */}
                                                                    </div>
                                                                </div>
                                                                {/*end::Number*/}

                                                            </div>
                                                            {/*end::Stat*/}
                                                        </div>
                                                        {/*end::Stats*/}
                                                    </div>
                                                    {/*end::Wrapper*/}
                                                </div>
                                                {/*end::Details*/}
                                                {/*begin::Actions*/}
                                                <div className="d-flex mb-4">
                                                    <a className="btn btn-sm btn-light me-2" id="ip_address" onClick={(e) => copyToClipboard('nat_ip_copy', 'nat_btn')}>
                                                        <div style={{marginTop:'0.3rem'}}>
                                                            <span className="indicator-label">IPv4: &nbsp;
                                                                <span id="nat_ip_copy" className="fw-bold">
                                                                    {internetGatewayData[0]?.publicip}
                                                                </span>
                                                                <span className="svg-icon svg-icon-primary svg-icon-2 ms-2" id="nat_btn">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={24}
                                                                        height={24}
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                    >
                                                                        <path
                                                                            opacity="0.5"
                                                                            d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                                                                            fill="black"
                                                                        />
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            clipRule="evenodd"
                                                                            d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                                                                            fill="black"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </a>

                                                    {/*end::Number*/}

                                                    <div id="kstatus">
                                                        {/* <div className="text-gray-800 fs-1 me-3">
                                                        {sqsData.status == "Active" ? (<a className=" btn btn-sm btn-success" style={{cursor:'default'}}>Active</a>) : (<a className=" btn btn-sm btn-warning" style={{cursor:'default'}}>Pending</a>)}
                                                    </div> */}
                                                        {internetGatewayData[0]?.status == "active" ?
                                                            <div className="btn btn-success fw-bold ms-5">  Active  </div>
                                                            :
                                                            <div className="btn btn-warning fw-bold ms-5">  {internetGatewayData[0]?.status}  </div>
                                                        }
                                                    </div>
                                                </div>
                                                {/*end::Actions*/}
                                            </div>
                                            {/*end::Head*/}
                                        </div>
                                        {/*end::Wrapper*/}
                                    </div>
                                    {/*end::Details*/}
                                    <div className="separator" />
                                    {/*begin::Nav*/}
                                    <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                                        {/*begin::Nav item*/}
                                        <li className="nav-item">
                                            <a
                                                className="nav-link text-active-primary py-5 me-6 active"
                                                data-bs-toggle="tab"
                                                href="#subnet"
                                            >
                                                Subnet
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link text-active-primary py-5 me-6" data-bs-toggle="tab" href="#destroy_ig">
                                                Destroy
                                            </a>
                                        </li>
                                        {/*end::Nav item*/}
                                    </ul>
                                    {/*end::Nav*/}
                                </div>
                            </div>
                            <div className="tab-content" id="myTabContent">
                                <div
                                    className="tab-pane fade show active"
                                    id="subnet"
                                    role="tabpanel"
                                >
                                    <SubnetNatGateway />
                                    {/* <Subnet /> */}
                                </div>
                                <div className="tab-pane fade" id="destroy_ig" role="tabpanel">
                                    {/*begin::Deactivate Account*/}
                                    <div className="card">
                                        {/*begin::Card header*/}
                                        <div
                                            className="card-header border-0 cursor-pointer"
                                            role="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#kt_account_deactivate"
                                            aria-expanded="true"
                                            aria-controls="kt_account_deactivate"
                                        >
                                            <div className="card-title m-0">
                                                <h3 className="fw-bold m-0">Destroy Nat Gateway</h3>
                                            </div>
                                            <div className="card-toolbar">
                                                <div className="d-flex justify-content-end py-6" style={{ marginRight: 1 }}>
                                                    {/* <CustomDoc url={ProductsUrl[0]?.vpc + "/manage-vpc/#destroy"} /> */}
                                                </div>
                                            </div>
                                        </div>
                                        {/*end::Card header*/}
                                        {/*begin::Content*/}
                                        <div id="kt_account_settings_deactivate" className="collapse show">
                                            {/*begin::Form*/}
                                            <form id="destroyform" className="form">
                                                {/*begin::Card body*/}
                                                <div className="card-body border-top p-9">
                                                    {/*begin::Notice*/}
                                                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
                                                        {/*begin::Icon*/}
                                                        {/*begin::Svg Icon | path: icons/duotune/general/gen044.svg*/}
                                                        <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={24}
                                                                height={24}
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                            >
                                                                <rect
                                                                    opacity="0.3"
                                                                    x={2}
                                                                    y={2}
                                                                    width={20}
                                                                    height={20}
                                                                    rx={10}
                                                                    fill="black"
                                                                />
                                                                <rect
                                                                    x={11}
                                                                    y={14}
                                                                    width={7}
                                                                    height={2}
                                                                    rx={1}
                                                                    transform="rotate(-90 11 14)"
                                                                    fill="black"
                                                                />
                                                                <rect
                                                                    x={11}
                                                                    y={17}
                                                                    width={2}
                                                                    height={2}
                                                                    rx={1}
                                                                    transform="rotate(-90 11 17)"
                                                                    fill="black"
                                                                />
                                                            </svg>
                                                        </span>
                                                        {/*end::Svg Icon*/}
                                                        {/*end::Icon*/}
                                                        {/*begin::Wrapper*/}
                                                        <div className="d-flex flex-stack flex-grow-1">
                                                            {/*begin::Content*/}
                                                            <div className="fw-bold">
                                                                <h4 className="text-gray-900 fw-bold">
                                                                    This action will permanently remove your NAT Gateway...
                                                                </h4>
                                                                <div className="fs-6 text-gray-700">
                                                                    <strong>Note:</strong> Once deleted, the NAT Gateway will be permanently removed from the server and cannot be undone.
                                                                </div>
                                                            </div>
                                                            {/*end::Content*/}
                                                        </div>
                                                        {/*end::Wrapper*/}
                                                    </div>
                                                    {/*end::Notice*/}
                                                    {/*begin::Form input row*/}
                                                    <div className="mb-10 d-none">
                                                        <label htmlFor="size" className="required form-label">
                                                            Your Internet Gateway will be permanently destroyed. You will lose the
                                                            provisioned Network addresses.
                                                        </label>
                                                    </div>
                                                    {/*end::Form input row*/}
                                                </div>
                                                {/*end::Card body*/}
                                                {/*begin::Card footer*/}
                                                <div
                                                    className="card-footer d-flex justify-content-end py-6 px-9"
                                                    id="destroy_btn"
                                                >
                                                    <button
                                                        id={`destroy-btn_${internetGatewayData[0]?.id}`}
                                                        onClick={() => destroyNatGateway(internetGatewayData[0]?.id)}
                                                        // onclick="call_api('DELETE','vpc/undefined/destroy','destroy','refdestroy','#vpc')"
                                                        type="button"
                                                        className="btn btn-danger btn-sm fw-bold"
                                                    >
                                                        Destroy Nat Gateway
                                                    </button>
                                                </div>
                                                {/*end::Card footer*/}
                                            </form>
                                            {/*end::Form*/}
                                        </div>
                                        {/*end::Content*/}
                                    </div>
                                    {/*end::Deactivate Account*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </>)
                    : (
                        <>
                            <TopBarHeaderLine />
                            <TableLoader />
                        </>
                    )}
            </div >
        </>
    )
}