import React, { useCallback, useEffect, useRef, useState } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import swal from 'sweetalert';
import useRazorpay from "react-razorpay";
import spinner from "../spinner.svg";
import UsePost from "../../../../../CustomHooks/usePost";
import UsePostData from "../../../../../CustomHooks/usePostData";
import UseApi from "../../../../../CustomHooks/useApi";
import ReactPaginate from 'react-paginate';
import localStorage from "local-storage";
import TableLoader from "../../../Loaders/TableLoader";
export default function ViewInvoicesTab() {
  const [perPage, setPerPage] = useState(5);
  const dataFetchedRef = useRef();
  const [invoicesData, setInvoicesData] = useState(null);
  const [invoiceData, setInvoiceData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [activePage, setActivePage] = useState(0);
  const [invoicesDummyData, setInvoicesDummyData] = useState(null);
  const filesUrl = localStorage.get("filesUrl");
  const [siteUrl, setSiteUrl] = useState(null);
  const app_info = localStorage.get("appInfo");
  const getInvoices = async () => {
    if (filesUrl) {
      setSiteUrl(filesUrl);
    }
    const invoice = await UseApi('get', 'billing', '');
    // const data = await UseApi('get', 'account/info', '');
    const userInfo = localStorage.get("user_info");
    if(userInfo){
    setUserData(userInfo);
    }

    if (Object.keys(invoice).length === 0) {
      setInvoicesData(null);
      setInvoicesDummyData(null)
    } else {
      setInvoicesData(invoice.invoices);
      setInvoicesDummyData(invoice.invoices)
    }


  }
  if (app_info != null) {
    app_info.company = "Utho Platforms Private Limited";
    app_info.address = "HD-204, WeWork, Berger Delhi One, Sector 16B, C-001/A2, Gautam Buddha Nagar, Noida 201301 Uttar Pradesh - India";
    app_info.gst = "09AAICM9463A1ZB";
    app_info.pan = "AAICM9463A";
    app_info.sac = "998315";
  }
  const loaderFunc = () => {
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if (loader && no_record) {
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");

      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
  }
  const getInvoice = async (id) => {
    const invoice = await UseApi('get', 'billing/' + id, '');
    setInvoiceData(invoice.invoices[0]);
    ////console.log(invoice);
    const transTabel = document.querySelector("#trans-tabel");
    const invoiceCredit = document.querySelector("#invoice-credit");
    var userAmount = 0;
    if (userData != null) {
      userAmount = userData.availablecredit;
    }
    if (invoice.invoices[0]) {
      if (transTabel) {

        if (invoice.invoices[0].transs.length <= 0) {
          transTabel.classList.add("d-none");
        } else {
          transTabel.classList.remove("d-none");
        }
      }
      // //console.log(parseFloat(invoice.invoices[0].rowtotal));
      if (invoiceCredit) {
        if (parseFloat(userAmount) > parseFloat(invoice.invoices[0].rowtotal)) {
          invoiceCredit.classList.remove("d-none");
        } else {
          invoiceCredit.classList.add("d-none");
        }
      }
    }
    var drawerElement = document.querySelector("#invoice_drawer");
    drawerElement.classList.toggle("drawer-on");
    drawerElement.style.width = '1100px';
  }

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getInvoices();
  }, []);

  const closeInvoice = () => {
    toast.dismiss();
    var drawerElement = document.querySelector("#invoice_drawer");
    var drawer_overlay = document.querySelector("#drawer_overlay");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
        drawerElement.style.width = "100%";
      } else {
        drawerElement.style.width = "75%";
      }
      drawer_overlay.classList.toggle("d-none");
    }
  }
  const openInvoice = async (id) => {
    toast.dismiss();

    const invoiceViewBtn = document.querySelector("#invoice_btn_" + id);
    if (invoiceViewBtn) {
      var preContent = invoiceViewBtn.innerHTML;
      invoiceViewBtn.classList.add("disabled");
      invoiceViewBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';

      const invoice = await UseApi('get', 'billing/' + id, '');
      setInvoiceData(invoice.invoices[0]);
      ////console.log(invoice);
      const transTabel = document.querySelector("#trans-tabel");
      const invoiceCredit = document.querySelector("#invoice-credit");
      var userAmount = 0;
      if (userData != null) {
        userAmount = userData.availablecredit;
      }
      if (invoice.invoices[0]) {
        if (transTabel) {

          if (invoice.invoices[0].transs.length <= 0) {
            transTabel.classList.add("d-none");
          } else {
            transTabel.classList.remove("d-none");
          }
        }
        // //console.log(parseFloat(invoice.invoices[0].rowtotal));
        if (invoiceCredit) {
          if (parseFloat(userAmount) > parseFloat(invoice.invoices[0].rowtotal)) {
            invoiceCredit.classList.remove("d-none");
          } else {
            invoiceCredit.classList.add("d-none");
          }
        }
      }
      var drawerElement = document.querySelector("#invoice_drawer");
      var drawer_overlay = document.querySelector("#drawer_overlay");
      if (drawerElement && drawer_overlay) {
        drawerElement.classList.toggle("drawer-on");
        if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
          drawerElement.style.width = "100%";
        } else {
          drawerElement.style.width = "75%";
        }
        drawer_overlay.classList.toggle("d-none");
      }

      invoiceViewBtn.classList.remove("disabled");
      invoiceViewBtn.innerHTML = preContent;
    }
  }


  const applyCreditAmount = async (id) => {
    toast.dismiss();
    swal({
      title: "Are you sure?",
      text: "Once Applied, your available credit will reduce according to this amount!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          const dt = toast.loading("Invoice processing...");
          const dnsp = await UsePost('post', 'billing/applycredit', 'applycredit');
          if (dnsp.status == 'error') {
            toast.update(dt, {
              render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
          } else {
            toast.update(dt, {
              render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
          }
        } else {
          swal({
            title: "Apply Credit",
            text: "Your not applied for the invoice!",
            icon: "success",
            button: "OK",
          });
        }
      });

  }
  console.log(app_info,siteUrl);

  const Razorpay = useRazorpay();


  const invoicePayment = useCallback(async () => {
    toast.dismiss();
    const payInvoiceBtn = document.querySelector("#invoice-payment-button");
    if (payInvoiceBtn) {
      var preContent = payInvoiceBtn.innerHTML;
      payInvoiceBtn.classList.add("disabled");
      payInvoiceBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';

      // const u_data = await UseApi('get', 'account/info', '');
      // const uData = u_data.user;
      const uData = localStorage.get("user_info");
      const u_razorpay_info = await UseApi('get', 'mhcsecinfo', '');

      ////console.log(razorpay_info); 
      const amountFund = document.querySelector("#invoiceAmountFund");
      const descp_invoice_id = document.querySelector("#invoice_id");
      var invoice_id = "";
      //console.log(descp_invoice_id.value);
      if (descp_invoice_id) {
        invoice_id = descp_invoice_id.value;
      }
      var rorder_id = "";
      var rfullname = "";
      var rmobile = "";
      var remail = "";
      var ramount = 0;
      var rcurrency = "";
      if (amountFund) {
        ramount = amountFund.value;
      }

      if (uData) {
        // rorder_id=uData.razorpay_orderid;
        rfullname = uData.fullname;
        rmobile = uData.mobile;
        remail = uData.email;
        rcurrency = uData.currency;

        if (rcurrency != "INR") {
          rcurrency = "USD";
        }
      }

      const options = {
        //key: "rzp_live_aeMFERiIAsYFFF",
        key: u_razorpay_info.razorpay_key,
        amount: parseInt(ramount * 100),
        currency: rcurrency,
        name: app_info?.brand == "Utho" ? ("Utho Platform") : (app_info),
        description: invoice_id,
        image: (siteUrl != null ? (siteUrl) : ('')) + "/publicfiles/" + (app_info ? (app_info?.logo) : ('')),
        prefill: {
          name: rfullname,
          email: remail,
          contact: rmobile,
        },
        options: {
          checkout: {
            method: {
              netbanking: 1,
              card: 0,
              upi: 1,
            }
          }
        },
        handler: async (res) => {
          //res.razorpay_payment_id
          //res.status_code
          //pay_L0C8DacFM1Voqi
          //console.log(res);
          if (res.status_code == 200) {

            swal({
              title: "Processing please wait...",
              icon: spinner,
              html: true,
              showSpinner: true
            });
            const resData = await UsePostData('post', 'billing/addpayment', { 'platform': '2023', 'invoiceid': invoice_id, 'transid': res.razorpay_payment_id, 'amount': ramount, 'currency': rcurrency, 'gateway': 'Razorpay' });

            if (resData.status == "success") {
              swal({
                title: "Invoice",
                text: "Invoice paid successfuly",
                icon: "success",
                button: "OK",
              });
              setTimeout(() => {
                window.location.reload();
              }, 4000);
            } else {
              swal({
                title: "Invoice",
                text: "Invoice not paid!",
                icon: "warning",
                button: "OK",
              });
            }
          }
        },
        notes: {
          u_userid: uData.id,
          u_invoice: invoice_id,
          u_type: "Invoice",
        },
        theme: {
          color: "#377dff",
        },
      };

      const rzpay = new Razorpay(options);
      rzpay.open();
      payInvoiceBtn.classList.remove("disabled");
      payInvoiceBtn.innerHTML = preContent;
    }
  }, [Razorpay]);
  function ProductInfo({ productdata }) {
    return (
      <>
        {productdata != null && Object.keys(productdata).length != 0 ? (productdata && productdata.map((value, index) => (
          <tr className="text-muted">
            <td className="text-start ps-9">
              <div id="applink" className="fs-7 text-dark d-block">{value.id}</div>
            </td>
            <td className="text-center text-dark">{value.type == "AddFunds" ? ("Reciept") : ("Invoice")}</td>
            <td className="text-center text-dark">
              <a className="fs-7 text-dark  text-hover-primary d-block">{value.date}</a></td>
            <td className="text-center">
              <a className="fs-7 text-dark text-hover-primary d-block">{userData != null ? (userData.currencyprefix) : ('')} {value.amount}</a></td>
            <td className="text-center">
              {value.status == 'Unpaid' ? (<span className="badge badge-light-danger">Unpaid</span>)
                : (value.status == 'Paid' || 'cloud' || 'billingcycle' || 'AddFunds' ? (<span className="badge badge-light-success">Paid</span>) : (''))}
              {/* {value.type=='cloud'?(<span className="badge badge-light-success">INVOICE</span>)
            :(value.type=='billingcycle'?(<span className="badge badge-light-success">BILLING CHANGE</span>)
            :(value.type=='AddFunds'?(<span className="badge badge-light-success">ADD FUNDS</span>)
            :(value.status=='Paid'?(<span className="badge badge-light-success">PAID</span>)
            :(value.status=='Unpaid'?(<span className="badge badge-light-danger">UNPAID</span>):('')))))}   */}
            </td>
            <td className="text-center">
              <div className="d-flex justify-content-center" data-kt-filemanager-table-toolbar="base">
                <a onClick={() => openInvoice(value.id)} className="btn btn-light-primary btn-sm me-3 sphide" id={"invoice_btn_" + value.id}><i class="bi bi-file-earmark-spreadsheet"></i>View </a>
              </div></td>
            <td className="text-center pe-4">
              <div className="justify-content-start" data-kt-filemanager-table-toolbar="base">
                {userData != null ? (<>
                  <a href={"https://api.utho.com/v2/billing/download/" + userData.id + "/" + value.id + ""} class="btn btn-primary btn-sm sphide"><i class="bi bi-file-pdf"></i> PDF</a>
                </>) : ('')}
              </div>
            </td>
          </tr>
        ))) : (
          <tr>
            <td colspan="12" className="" id="no_record" valign="top">
              <div className="d-flex flex-column flex-center">
                <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
              </div>
            </td>
          </tr>)}
      </>
    )
  }

  const handleSearch = (text) => {
    const endOffset = itemOffset + perPage;
    const data = invoicesData.filter(row => {
      return (
        (row.id && row.id.toString().toLowerCase().includes(text.toLowerCase())) ||
        (row.date && row.date.toString().toLowerCase().includes(text.toLowerCase())) ||
        // (row.amount && row.amount.toString().toLowerCase().includes(text.toLowerCase())) ||
        (row.status && row.status.toString().toLowerCase().includes(text.toLowerCase()))
      )
    });
    setInvoicesDummyData(data);;
    setPageCount(Math.ceil(data.length / perPage));
  }

  function PaginatedItems({ itemsPerPage, ProductData }) {
    const endOffset = itemOffset + parseInt(itemsPerPage);
    setPageCount(Math.ceil(ProductData.length / itemsPerPage));
    const handlePageClick = (event) => {
      setActivePage((old) => old = event.selected);
      const newOffset = event.selected * itemsPerPage % ProductData.length;
      setItemOffset(newOffset);
    };
    return (
      <>
        <ProductInfo productdata={ProductData.slice(itemOffset, endOffset)} />
        <td colspan="12" className="ps-3">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            initialPage={activePage}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </td>
      </>
    );
  }
  return (
    <> 
        {/*begin::Deactivate Account*/}
        <div className="card border border-2">
          {/*begin::Card header*/}
          <div
            className=""
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_account_deactivate"
            aria-expanded="true"
            aria-controls="kt_account_deactivate"
          >
            <div className="card-title m-0">
              <div
                className="card-header border-0 cursor-pointer"
                role="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_account_deactivate"
                aria-expanded="true"
                aria-controls="kt_account_deactivate"
              >
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold fs-3 mb-1">
                    Billing Invoices/Receipt{" "}
                  </span>
                  <span className="text-muted mt-1 fw-bold fs-7">
                    Here you can view/pay invoices in your account.
                  </span>
                </h3>
                <div
                  className="card-toolbar"
                >
                  <div className="position-relative my-1" style={{ marginRight: 20 }}>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      data-kt-table-widget-4="search"
                      id="searchClient"
                      onChange={e => handleSearch(e.target.value)}
                      placeholder="Search"
                    />
                  </div>
                  <div style={{ marginRight: 0 }}>
                    <div className="dataTables_length">
                      <label className="w-125px">
                        <select
                          onChange={e => setPerPage(e.target.value)}
                          //data-control="select2"
                          className="form-select form-select-sm form-select-solid"
                          defaultValue={5}
                          id="perPage"

                        >
                          <option value={5} selected="">
                            5 Records
                          </option>
                          <option value={10}>10 Records</option>
                          <option value={25}>25 Records</option>
                          <option value={50}>50 Records</option>
                          <option value={100}>100 Records</option>
                        </select>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*end::Card header*/}
          {/*begin::Content*/}
          <div
            id="kt_account_settings_deactivate"
            className="collapse show"
          >
            {/*begin::Form*/}
            <form id="destroyform" className="form">
              {/*begin::Card body*/}
              <div className=" border-top ">
                {/*begin::Notice*/}
                {/*begin::Table container*/}
                <div className="table-responsive">
                  {/*begin::Table*/}
                  <table
                    className="table table-striped table-row-dashed table-row-gray-400 align-middle gs-4 gy-4"
                    id="table-id"
                  >
                    {/*begin::Table head*/}
                    <thead className="bg-secondary">
                      <tr className=" fw-bold">
                        <th className="fw-bold w-50px text-start ps-9">#ID</th>
                        <th className="fw-bold text-center w-50px">Type</th>
                        <th className="fw-bold text-center w-100px">Date</th>
                        <th className="fw-bold text-center w-100px">Amount</th>
                        <th className="fw-bold text-center w-50px">Status</th>
                        <th className="w-50px" />
                        <th className="w-50px" />
                      </tr>
                    </thead>
                    {/*end::Table head*/}
                    {/*begin::Table body*/}
                    <tbody id="invoice-list">
                      {invoicesData != null ? (<>
                        <PaginatedItems itemsPerPage={perPage} ProductData={invoicesDummyData} />
                      </>) : (<>
                        <tr>
                          <td colspan="12" className="dataTables_empty" id="loader">
                            {/* <div className="loading-spinner"></div> */}
                            <TableLoader />
                          </td>
                          {/* <td colspan="12" className="d-none" id="no_record" valign="top">
                      <div className="d-flex flex-column flex-center"> 
                        <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                      </div>
                    </td> */}
                        </tr>
                      </>)}
                    </tbody>
                  </table>
                  {/*end::Table*/}
                </div>
                {/*end::Table container*/}
                {/*end::Notice*/}
              </div>
              {/*end::Card body*/}
            </form>
            {/*end::Form*/}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Deactivate Account*/} 
      <div id="drawer_overlay" onClick={closeInvoice} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>

      <div
        id="invoice_drawer"
        className="bg-white drawer drawer-end"
        data-kt-drawer="true"
        data-kt-drawer-overlay="true"
        data-kt-drawer-activate="true"
        data-kt-drawer-width="1200px"
        style={{ width: "1200px !important" }}
      >
        <div className=" container-fluid">
          {/*begin::Body*/}
          <div className=" p-5">
            {/*begin::Layout*/}
            <div className="d-flex flex-column flex-xl-row">
              {/*begin::Content*/}
              <div className="flex-lg-row-fluid me-xl-18 mb-10 mb-xl-0">
                {/*begin::Invoice 2 content*/}
                <div className="mt-n1">
                  {/*begin::Top*/}
                  <div className="d-flex flex-stack pb-10">
                    {/*begin::Logo*/}
                    <a >
                      <img
                        alt="Logo"
                        src={siteUrl != null ? (siteUrl + "/publicfiles/" + app_info.logo) : ('')}
                        width="200px"
                      />
                    </a>
                    {/*end::Logo*/}
                    {/*begin::Action*/}
                    <div id="payinvoice" />
                    <div id="paidinvoice" className="d-flex">
                      {invoiceData != null && invoiceData.status == "Paid" ? (<>
                        <button className="btn btn-success me-5" style={{cursor:'default'}}>Paid</button>
                      </>) : (<>
                        <button className="btn btn-success me-5" id="invoice-payment-button" onClick={invoicePayment}>Pay</button>
                      </>)}



                      <button className="btn btn-light-danger me-5" onClick={closeInvoice}>close</button>

                    </div>

                    {/*end::Action*/}
                  </div>
                  {/*end::Top*/}
                  {/*begin::Wrapper*/}
                  <div className="m-0">
                    {/*begin::Label*/}
                    <div className="fw-bolder fs-3 text-gray-800 mb-8">
                      Invoice <span>{invoiceData != null && invoiceData.id ? (invoiceData.id) : ('')}</span>
                    </div>
                    {/*end::Label*/}
                    {/*begin::Row*/}
                    <div className="row g-5 mb-11">
                      {/*end::Col*/}
                      <div className="col-sm-6">
                        {/*end::Label*/}
                        <div className="fw-bold fs-7 text-gray-600 mb-1">
                          Issue Date:
                        </div>
                        {/*end::Label*/}
                        {/*end::Col*/}
                        <div
                          className="fw-bolder fs-6 text-gray-800"
                          id="invoice-date"
                        >
                          {invoiceData != null && invoiceData.date ? (invoiceData.date) : ('')}
                        </div>
                        {/*end::Col*/}
                      </div>
                      {/*end::Col*/}
                      {/*end::Col*/}
                      <div className="col-sm-6"></div>
                      {/*end::Col*/}
                    </div>
                    {/*end::Row*/}
                    {/*begin::Row*/}
                    <div className="row g-5 mb-12">
                      {/*end::Col*/}
                      <div className="col-sm-6">
                        {/*end::Label*/}
                        <div className="fw-bold fs-7 text-gray-600 mb-1">
                          Issue For:
                        </div>
                        {/*end::Label*/}
                        {/*end::Text*/}
                        <div
                          className="fw-bolder fs-6 text-gray-800"
                          id="company_name"
                        >
                          {userData != null && userData.company ? (userData.company) : ('')}
                        </div>
                        {/*end::Text*/}
                        {/*end::Description*/}
                        <div className="fw-bold fs-7 text-gray-600">
                          <span id="user-address">{userData != null && userData.address ? (userData.address + ", ") : ('')} {userData != null && userData.city ? (userData.city + ", ") : ('')} {userData != null && userData.country ? (userData.country + ", ") : ('')}</span>
                          <br />
                          <span id="user-mobile" >{userData != null && userData.mobile ? (userData.mobile) : ('')}</span>
                          <br />
                          <span id="user-gst" >{userData != null && userData.gstnumber ? (userData.gstnumber) : ('')}</span>
                        </div>
                        {/*end::Description*/}
                      </div>
                      {/*end::Col*/}
                      {/*end::Col*/}
                      <div className="col-sm-6 text-end">
                        {/*end::Label*/}
                        <div className="fw-bold fs-7 text-gray-600 mb-1">
                          Issued By:
                        </div>
                        {/*end::Label*/}
                        {/*end::Text*/}
                        <div className="fw-bolder fs-6 text-gray-800">
                          {app_info != null ? (app_info.company) : ('')}
                        </div>
                        {/*end::Text*/}
                        {/*end::Description*/}
                        <div className="fw-bold fs-7 text-gray-600">
                          {app_info != null ? (app_info.brand) : ('')}
                          <br />
                          {app_info != null ? (app_info.address) : ('')}
                          <br />
                          <strong>GSTIN:</strong> {app_info != null ? (app_info.gst) : ('')}
                          <br />
                          <strong>PAN Number:</strong> {app_info != null ? (app_info.pan) : ('')}
                          <br />
                          <strong>SAC:</strong> {app_info != null ? (app_info.sac) : ('')}
                        </div>
                        {/*end::Description*/}
                      </div>
                      {/*end::Col*/}
                    </div>
                    {/*end::Row*/}
                    {/*begin::Content*/}
                    <div className="flex-grow-1">
                      {/*begin::Table*/}
                      <div className="table-responsive border mb-9">
                        <table className="table mb-3 table-row-bordered">
                          <thead className="bg-secondary">
                            <tr className="border-bottom fs-6 fw-bolder">
                              <th className="min-w-10px" />
                              <th className="min-w-175px pb-2 ">Item Description</th>
                              <th className="min-w-70px text-end pb-2" />
                              <th className="min-w-100px text-start pb-2">Amount</th>
                              <th className="min-w-100px text-start pb-2">Discount</th>
                            </tr>
                          </thead>
                          <tbody id="item-list">
                            {invoiceData != null ? (invoiceData.items != null ? (invoiceData.items.map((value, index) => (<>
                              <tr className="fw-bold text-gray-700">
                                <td></td>
                                <td className="d-flex align-items-center pt-6">{value.description}</td>
                                <td></td>
                                <td className="pt-6 fw-bold">{userData != null && userData.currencyprefix ? (userData.currencyprefix) : ('')} {value.amount}</td>
                                <td className="pt-6 fw-bold">{userData != null && userData.currencyprefix ? (userData.currencyprefix) : ('')} {value.discount ? (value.discount) : (0)}</td>
                              </tr>
                            </>))) : ('')) : ('')}
                          </tbody>
                        </table>
                      </div>
                      {/*end::Table*/}
                      {/*begin::Container*/}
                      <div className="d-flex row">


                        <div className="col-lg-8 col-md-8 col-xxl-8">
                          {userData != null && invoiceData != null && invoiceData.status != "Paid" && (userData.credit !== undefined && userData.credit !== null && userData.credit != 0) ? (<>
                            <div
                              className="d-flex flex-wrap flex-stack mb-6"
                              id="invoice-credit"
                            >

                              {" "}
                              <div className="d-flex my-2">
                                {" "}
                                <div className="d-flex align-items-center position-relative me-4">
                                  {" "}
                                  <form id="applycredit" name="applycredit">
                                    {" "}
                                    <div className="input-group mb-5" id="wallet">
                                      <input
                                        type="hidden"
                                        name="invoiceid"
                                        value={invoiceData != null && invoiceData ? (invoiceData.id) : ('')} />
                                      <span class="input-group-text" >{userData.currencyprefix ? (userData.currencyprefix) : ('')}</span>  <input type="text" class="form-control" placeholder="amount" id="amount" name="amount" min="10" value={userData.credit > invoiceData.rowbalance ? (invoiceData.rowbalance) : (userData.credit)} /><a class="btn btn-primary btn-sm" id="applycredit-btn" onClick={applyCreditAmount}>Apply Credit</a>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </>) : (<></>)}
                        </div>


                        <div className="col-lg-4 col-md-4 col-xxl-4">
                          {/*begin::Section*/}
                          <div className="mw-300px" id="invoice-final">
                            {/*begin::Item*/}{" "}
                            <div className="d-flex flex-stack mb-3">
                              {/*begin::Accountname*/}
                              <div className="fw-bold pe-10 text-gray-600 fs-7">Subtotal:</div>
                              {/*end::Accountname*/}
                              {/*begin::Label*/}
                              <div className="text-end fw-bolder fs-6 text-gray-800" id="subtotal-amount">
                                {invoiceData != null && invoiceData.subtotal ? (invoiceData.subtotal) : ('')}
                              </div>
                              {/*end::Label*/}
                            </div>
                            {/*end::Item*/}

                            {invoiceData != null && invoiceData.taxrate != '0.00' && invoiceData.taxrate2 == '0.00' ? (<>
                              {/*begin::Item*/}{" "}
                              <div className="d-flex flex-stack mb-3">
                                {/*begin::Accountname*/}
                                <div className="fw-bold pe-10 text-gray-600 fs-7">{invoiceData.taxrate}% IGST</div>
                                {/*end::Accountname*/}
                                {/*begin::Label*/}
                                <div className="text-end fw-bolder fs-6 text-gray-800">{userData != null && userData.currencyprefix ? (userData.currencyprefix) : ('')} {invoiceData.tax}</div>
                                {/*end::Label*/}
                              </div>
                              {/*end::Item*/}

                            </>) : ('')}


                            {invoiceData != null && invoiceData.taxrate != '0.00' && invoiceData.taxrate2 != '0.00' ? (<>
                              {/*begin::Item*/}{" "}
                              <div className="d-flex flex-stack mb-3">
                                {/*begin::Accountname*/}
                                <div className="fw-bold pe-10 text-gray-600 fs-7">{invoiceData.taxrate}% CGST</div>
                                {/*end::Accountname*/}
                                {/*begin::Label*/}
                                <div className="text-end fw-bolder fs-6 text-gray-800">{userData != null && userData.currencyprefix ? (userData.currencyprefix) : ('')} {invoiceData.tax2}</div>
                                {/*end::Label*/}
                              </div>
                              {/*end::Item*/}

                            </>) : ('')}

                            {invoiceData != null && invoiceData.taxrate2 != '0.00' ? (<>
                              {/*begin::Item*/}{" "}
                              <div className="d-flex flex-stack mb-3">
                                {/*begin::Accountname*/}
                                <div className="fw-bold pe-10 text-gray-600 fs-7">{invoiceData.taxrate}% SGST</div>
                                {/*end::Accountname*/}
                                {/*begin::Label*/}
                                <div className="text-end fw-bolder fs-6 text-gray-800">{userData != null && userData.currencyprefix ? (userData.currencyprefix) : ('')} {invoiceData.tax2}</div>
                                {/*end::Label*/}
                              </div>
                              {/*end::Item*/}

                            </>) : ('')}

                            {invoiceData != null && invoiceData.credit != '0' ? (<>
                              {/*begin::Item*/}{" "}
                              <div className="d-flex flex-stack mb-3">
                                {/*begin::Accountname*/}
                                <div className="fw-bold pe-10 text-gray-600 fs-7">Credits</div>
                                {/*end::Accountname*/}
                                {/*begin::Label*/}
                                <div className="text-end fw-bolder fs-6 text-gray-800">{userData != null && userData.currencyprefix ? (userData.currencyprefix) : ('')} {invoiceData.credit}</div>
                                {/*end::Label*/}
                              </div>
                              {/*end::Item*/}

                            </>) : ('')}


                            {/*begin::Item*/}{" "}
                            <div className="d-flex flex-stack mb-3">
                              {/*begin::Accountname*/}
                              <div className="fw-bold pe-10 text-gray-600 fs-7">Total</div>
                              {/*end::Accountname*/}
                              {/*begin::Label*/}
                              <div className="text-end fw-bolder fs-6 text-gray-800" id="total-amount">
                                {userData != null && userData.currencyprefix ? (userData.currencyprefix) : ('')} {invoiceData != null && invoiceData.total}
                                <input type="hidden" id="invoiceAmountFund" value={invoiceData != null && invoiceData.rowbalance} />
                                <input type="hidden" id="invoice_id" value={invoiceData != null && invoiceData.id} />
                              </div>
                              {/*end::Label*/}
                            </div>
                            {/*end::Item*/}
                            {invoiceData != null && invoiceData.rowbalance > 0 ? (<>
                              {/*begin::Item*/}{" "}
                              <div className="d-flex flex-stack mb-3">
                                {/*begin::Accountname*/}
                                <div className="fw-bold pe-10 text-gray-600 fs-7">Balance</div>
                                {/*end::Accountname*/}
                                {/*begin::Label*/}
                                <div className="text-end fw-bolder fs-6 text-gray-800" id="total-amount">
                                  {userData != null && userData.currencyprefix ? (userData.currencyprefix) : ('')} {invoiceData != null && invoiceData.rowbalance}
                                </div>
                                {/*end::Label*/}
                              </div>
                              {/*end::Item*/}
                            </>) : ('')}
                          </div>

                        </div>
                        {/*end::Section*/}
                      </div>
                      {/*end::Container*/}
                      <div id="trans-tabel" className="d-none">
                        <div className="fw-bolder fs-2">Transactions</div>
                        <table className="table mb-3 table-row-bordered">
                          <thead className="bg-secondary">
                            <tr className="border-bottom fs-6 fw-bolder">
                              <th className="min-w-175px pb-2 text-center">
                                Transaction Date
                              </th>
                              <th className="min-w-70px text-end pb-2">
                                Transaction Gateways
                              </th>
                              <th className="min-w-80px text-end pb-2">
                                Transaction id
                              </th>
                              <th className="min-w-100px text-center pb-2">
                                Transaction Amount
                              </th>
                            </tr>
                          </thead>
                          <tbody
                            className="fw-bold text-gray-600"
                            id="trans-list"
                          >
                            {invoiceData != null && invoiceData.transs.map((value, index) => (<>
                              <tr>
                                <td class="text-center">{value.date}</td>
                                <td class="text-end">{value.gateway}</td>
                                <td class="text-end">{value.transid}</td>
                                <td class="text-center">{userData != null && userData.currencyprefix ? (userData.currencyprefix) : ('')} {value.amountin ? (value.amountin) : (value.amountout)}</td>
                              </tr>
                            </>))}
                          </tbody>
                        </table>
                        {/*end::Table*/}
                        <div id="due-price" style={{ marginTop: 25 }} >
                          <div class="d-flex justify-content-end">
                            <div class="mw-300px" id="invoice-final">
                              <div class="d-flex flex-stack mb-3">
                                <div class="fw-bold pe-10 text-danger-600 fs-7">Due Amount</div>
                                <div class="text-end fw-bolder fs-6 text-danger-800">{userData != null && userData.currencyprefix ? (userData.currencyprefix) : ('')} {invoiceData != null ? (invoiceData.balance) : ('')}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*end::Content*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Invoice 2 content*/}
              </div>
              {/*end::Content*/}
            </div>
            {/*end::Layout*/}
          </div>
          {/*end::Body*/}
        </div>
      </div>
      {/*end::View component*/}
    </>
  );
}
