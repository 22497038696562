import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import swal from "sweetalert";
import ReactPaginate from 'react-paginate';
import AccessDenied from "../../AccessDenied";
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../Loaders/TableLoader";
import CustomDoc from "../../../../CustomHooks/CustomDoc";
import ProductsUrl from "../../Docs/Docsdata";
export default function Backups() {
  const [pageStatus, setPageStatus] = useState(false)
  const [dataFetched, setDataFetched] = useState(false);
  const [access, setAccess] = useState(true);
  const [perPage, setPerPage] = useState(5);
  const [backupData, setBackupData] = useState(null);
  const [dummyBackupData, setDummyBackupData] = useState(null);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [activePage, setActivePage] = useState(0);
  const dataFetchedRef = useRef(false);
  const navigate = useNavigate();
  const navRedirect = () => {
    setTimeout(() => {
      navigate('/backups');
    }, 3000);

  }
  const loaderFunc = () => {
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if (loader && no_record) {
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");

      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
  }
  const getBackup = async () => {
    const dt = toast
    const data = await UseApi('get', 'backups', '');
    if (data) {
      setDataFetched(true);
    }
    if ('status' in data) {
      if (data.status == "error") {
        toast.update(dt, {
          render: data.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        if (data.message == "Permission denied.") {
          setAccess(false);
        } else {
          setAccess(true)
        }
        setBackupData([]);
        setDummyBackupData([])
        loaderFunc();
        return;
      }
    }
    if (Object.keys(data).length === 0) {
      setBackupData([]);
      setDummyBackupData([])
      toast.update(dt, {
        render: "no record found", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      loaderFunc();
    } else {
      setBackupData(data.backups);
      setDummyBackupData(data.backups)
      setPageCount(Math.ceil(data.backups.length / perPage));
      toast.dismiss();
      //    toast.update(dt,{render: "Your Backups request has been successful", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      //  });  

    }
  }
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getBackup();
  }, []);

  const restoreBackup = async (cloudId, backupId) => {
    toast.dismiss();
    swal({
      title: "Are you sure?",
      text: "Once restored the backup, your server move to the state of this Backup",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          setPageStatus(true)
          const dt = toast.loading("Backup restoring...");
          const dnsp = await UseApi('POST', 'cloud/' + cloudId + '/backup/' + backupId + '/restore', '');
          if (dnsp.status == 'error') {
            setPageStatus(false)
            toast.update(dt, {
              render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
          } else {
            setPageStatus(false)
            toast.update(dt, {
              render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
            getBackup();
            navRedirect();
          }
        }
      });

  }

  const deleteBackup = async (backupId) => {
    toast.dismiss();
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this backup!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          setPageStatus(true)
          const dt = toast.loading("Backup deleting...");
          const dnsp = await UseApi('DELETE', 'backup/' + backupId + '/delete', '');
          //console.log(dnsp);
          if (dnsp.status == 'error') {
            setPageStatus(false)
            toast.update(dt, {
              render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
          } else {
            setPageStatus(false)
            toast.update(dt, {
              render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
            getBackup();
            navRedirect();
          }
        }
      });

  }
  function ProductInfo({ productdata }) {
    return (
      <>
        {productdata != null && Object.keys(productdata).length != 0 ? (productdata && productdata.map((backupData, index) => (
          <tr>

            <td className="text-center p-3">

              <div className="align-items-center w-100 me-2">

                <div className=" flex-stack mb-2">

                  <span className=" me-2 fs-7 fw-bold">{backupData.id}</span>
                </div>
              </div>
            </td>
            <td className="text-center">
              <div className=" align-items-center w-100 me-2">
                <div className=" flex-stack mb-2">
                  <Link to={"/cloud/" + backupData.cloudid + ""}>
                    <a
                      className=" me-2 fs-7 fw-bold"
                    >
                      {backupData.cloudid}
                    </a>
                  </Link>
                </div>
              </div>
            </td>
            <td className="text-center">

              <div className=" flex-column w-100 me-2">

                <div className=" flex-stack mb-2">

                  <span className=" me-2 fs-7 fw-bold">{backupData.size}GB</span>
                </div>
              </div>
            </td>
            <td className="text-center ps-10">
              <div className="d-flex align-items-center">
                <div className="symbol symbol-30px me-5">
                  <img src={"/img/flag_" + backupData.dclocation.dccc + ".png"} />
                </div>
                <div className="justify-content-start flex-column">
                  <a
                    className="text-dark fw-bold text-hover-primary fs-6"
                  >
                    {backupData.dclocation.location}
                  </a>
                </div>
              </div>
            </td>
            <td className="text-center">

              <div className="d-flex flex-column w-100 me-2">

                <div className="flex-stack mb-2">

                  <span className="text-muted me-2 fs-7 fw-bold">
                    {backupData.created_at}
                  </span>
                </div>
              </div>
            </td>
            <td className="text-center">
              {backupData.status == 'Success' ? (<span className="badge badge-light-success">{backupData.status}</span>) : (<span className="badge badge-light-danger">{backupData.status}</span>)}
            </td>
            <td className="text-center">

              <div className="card-toolbar">

                <button
                  type="button"
                  className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                  onClick={() => restoreBackup(backupData.cloudid, backupData.id)}
                >

                  <span className="svg-icon svg-icon-2">

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >

                      <path
                        d="M14.5 20.7259C14.6 21.2259 14.2 21.826 13.7 21.926C13.2 22.026 12.6 22.0259 12.1 22.0259C9.5 22.0259 6.9 21.0259 5 19.1259C1.4 15.5259 1.09998 9.72592 4.29998 5.82592L5.70001 7.22595C3.30001 10.3259 3.59999 14.8259 6.39999 17.7259C8.19999 19.5259 10.8 20.426 13.4 19.926C13.9 19.826 14.4 20.2259 14.5 20.7259ZM18.4 16.8259L19.8 18.2259C22.9 14.3259 22.7 8.52593 19 4.92593C16.7 2.62593 13.5 1.62594 10.3 2.12594C9.79998 2.22594 9.4 2.72595 9.5 3.22595C9.6 3.72595 10.1 4.12594 10.6 4.02594C13.1 3.62594 15.7 4.42595 17.6 6.22595C20.5 9.22595 20.7 13.7259 18.4 16.8259Z"
                        fill="black"
                      />
                      <path
                        opacity="0.3"
                        d="M2 3.62592H7C7.6 3.62592 8 4.02592 8 4.62592V9.62589L2 3.62592ZM16 14.4259V19.4259C16 20.0259 16.4 20.4259 17 20.4259H22L16 14.4259Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                </button>
              </div>
            </td>
            <td className="text-center">

              <div className="card-toolbar">

                <button
                  type="button"
                  className="btn  btn-icon btn-color-danger btn-active-light-danger"
                  onClick={() => deleteBackup(backupData.id)}
                >

                  <span className="svg-icon svg-icon-2">

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >

                      <path
                        d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                        fill="black"
                      />
                      <path
                        opacity="0.5"
                        d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                        fill="black"
                      />
                      <path
                        opacity="0.5"
                        d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                </button>
              </div>
            </td>
          </tr>
        ))) : (<>
          <tr>
            <td colspan="12" className="dataTables_empty" id="loader">
              {/* <div className="loading-spinner"></div> */}
              {/* <TableLoader /> */}
            </td>
            <td colspan="12" className="d-none" id="no_record" valign="top">
              <div className="d-flex flex-column flex-center">
                <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
              </div>
            </td>
          </tr>
        </>)}

      </>
    )
  }

  const handleSearch = (text) => {
    const endOffset = itemOffset + perPage;
    const data = backupData.filter(row => {
      console.log(row);
      return Object.values(row).some(value => {
        if (value != null) {
          if (value.toString().toLowerCase().includes(text.toLowerCase())) {
            return value;
          }
        }
      });
    });
    setDummyBackupData(data);;
    setPageCount(Math.ceil(data.length / perPage));
    // }
  }

  function PaginatedItems({ itemsPerPage, ProductData }) {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(ProductData.length / itemsPerPage));
    const handlePageClick = (event) => {
      setActivePage((old) => old = event.selected);
      const newOffset = event.selected * itemsPerPage % ProductData.length;
      setItemOffset(newOffset);
    };

    return (
      <>
        <ProductInfo productdata={ProductData.slice(itemOffset, endOffset)} />
        <td colspan="12">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            initialPage={activePage}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </td>
      </>
    );
  }

  if(dataFetched){
  if (access) {
    return (
      <>
        <div className="col-xl-12">
          {/*begin::Tables Widget 9*/}
          <div className="card card-xl-stretch mb-5 mb-xl-12">
            {/*begin::Header*/}
            <div className="card-header border-0 pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1">
                  Current Backups 
                </span>
                <span className="text-muted mt-1 fw-bold fs-7">View your Backups here</span>
              </h3>
              <div className="card-toolbar">
              <div className="position-relative my-1" style={{ marginRight: 20 }}>
                  <CustomDoc url={ProductsUrl[0]?.backups+"/backups/"}/>
                  </div>
                <div className="position-relative my-1" style={{ marginRight: 20 }}>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    data-kt-table-widget-4="search"
                    id="searchClient"
                    onChange={e => handleSearch(e.target.value)}
                    placeholder="Search"
                  />
                </div>
                <div style={{ marginRight: 0 }}>
                  <div className="dataTables_length">
                    <label className="w-125px">
                      <select
                        onChange={e => setPerPage(e.target.value)}
                        //data-control="select2"
                        className="form-select form-select-sm form-select-solid"
                        defaultValue={5}
                        id="perPage"

                      >
                        <option value={5} selected="">
                          5 Records
                        </option>
                        <option value={10}>10 Records</option>
                        <option value={25}>25 Records</option>
                        <option value={50}>50 Records</option>
                        <option value={100}>100 Records</option>
                      </select>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            {/*begin::Table container*/}
            <div className="table-responsive">
              {/*begin::Table*/}
              <table
                className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
                id="backupTable"
              >
                {/*begin::Table head*/}
                <thead className="bg-secondary">
                  <tr className="fw-bold">
                    <th className="w-100px text-start ps-9"> #ID </th>
                    <th className="w-100px text-center"> Cloud #ID </th>
                    <th className="w-100px text-center">Backup Size</th>
                    <th className="w-100px text-center sphide">Backup Location</th>
                    <th className="w-100px text-center">Created at</th>
                    <th className="w-100px text-center">Status</th>
                    <th className="w-100px text-center">Restore</th>
                    <th className="w-100px text-center">Action</th>
                  </tr>
                </thead>
                {/*end::Table head*/}
                {/*begin::Table body*/}
                <tbody id="backup-list">
                  {dummyBackupData != null ? (<>
                    {access == false ? (<>
                      <tr>
                        <td colspan={12}>
                          <AccessDenied />
                        </td>
                      </tr>
                    </>) : (<>
                      {Object.keys(dummyBackupData).length != 0 ? (<>
                        <PaginatedItems ProductData={dummyBackupData} itemsPerPage={perPage} />
                      </>) : (<>
                        <tr>
                          <td colspan="12" id="no_record" valign="top">
                            <div className="d-flex flex-column flex-center">
                              <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                            </div>
                          </td>
                        </tr>
                      </>)}
                    </>)}
                  </>) : (<>
                    <tr>
                      <td colspan={12}>
                        <TableLoader />
                        <TopBarHeaderLine />
                      </td>
                    </tr>

                  </>)}
                </tbody>
                {/*end::Table body*/}
              </table>
              {/*end::Table*/}

            </div>
            {pageStatus &&
              <TopBarHeaderLine />}
            {/*end::Table container*/}
            {/*begin::Body*/}
          </div>
          {/*end::Tables Widget 9*/}
        </div>
      </>
    )
  } else {
    return (
      <>
        <AccessDenied />
      </>
    )
  }
}else{
  return (
    <>
      <TableLoader />
    </>
  )
}
}