import React, { useEffect, useState } from "react";
import GrafanaViewer from "./charts/GrafanaViwer";
import TableLoader from "../../../Loaders/TableLoader";
import GrafanaViewerOverview from "./charts/GrafanaViwerOverview";
import GrafanaViewerBandwidth from "./charts/GrafanaViwerBandwidth";
import GrafanaViewerBandwidthOverview from "./charts/GrafanaViwerBandwidthOverview";
import localStorage from "local-storage";

export default function ChartOverview(props) {

  const [dataDuration, setDataDuration] = useState('4h');
  const [cloudid, setCloudid] = useState(null);

  useState(() => {
    if (props && props.cloudid) {
      setCloudid(props.cloudid);
    }
  }, [props.cloudid])

  const toggleMenu = () => {
    var chartMenu = document.querySelector("#chart-menu");
    if (chartMenu) {
      chartMenu.classList.toggle("d-block");
    }
  };

  const changeCycle = (value, innerValue) => {
    // Remove this line: setDataDuration(null);
    var currentCycle = document.querySelector("#currentCycle");
    if (currentCycle) {
      currentCycle.innerHTML = innerValue;
    }
    setDataDuration(value);
    toggleMenu();
  };


  const [dataDurationBandwidth, setDataDurationBandwidth] = useState('-0');
  const [dataManualDurationBandwidth, setDataManualDurationBandwidth] = useState('-0');
  const toggleMenuBandwidth = () => {
    var chartMenu = document.querySelector("#chart-menu-Bandwidth");
    if (chartMenu) {
      chartMenu.classList.toggle("d-block");
    }
  };

  const changeBandwidthCycle = (value, manualValue, innerValue) => {
    // Remove this line: setDataDuration(null);
    var currentCycle = document.querySelector("#currentCycleBandwidth");
    if (currentCycle) {
      currentCycle.innerHTML = innerValue;
    }
    setDataDurationBandwidth(value);
    setDataManualDurationBandwidth(manualValue);
    toggleMenuBandwidth();
  };

  useEffect(() => {
    if (localStorage.get("graphBandwidthCycle") >= 0 && localStorage.get("graphBandwidthCycle") <= 12 && localStorage.get("graphBandwidthCycle") != null) {
      // changeBandwidthCycles(localStorage.get("graphBandwidthCycle"));
    } else {
      localStorage.set("graphBandwidthCycle", 0);
    }

  }, [])
  const changeBandwidthCycles = (value) => {
    // Remove this line: setDataDuration(null);
    var currentCycleValue = document.querySelector("#bandwidthCycleValue");
    var currentCycleBandwidthNext = document.querySelector("#currentCycleBandwidthNext");
    var currentCycleBandwidthPrev = document.querySelector("#currentCycleBandwidthPrev");

    if (currentCycleValue && currentCycleBandwidthNext && currentCycleBandwidthPrev) {
      var bvalue = parseInt(currentCycleValue.value);

      if (localStorage.get("graphBandwidthCycle") >= 0 && localStorage.get("graphBandwidthCycle") <= 12 && localStorage.get("graphBandwidthCycle") != null) {
        var blvalue = parseInt(localStorage.get("graphBandwidthCycle"));
      } else {
        localStorage.set("graphBandwidthCycle", 0);
      }
      if (value == "next") {
        if (blvalue > 0 && blvalue <= 12) {
          blvalue--;
          setDataDurationBandwidth("-" + blvalue);
          setDataManualDurationBandwidth("-" + blvalue);
          currentCycleBandwidthNext.classList.remove("disabled");
          currentCycleBandwidthPrev.classList.remove("disabled");
          localStorage.set("graphBandwidthCycle", blvalue);
          // currentCycleValue.value=blvalue;
        } else if (blvalue == 0) {

          setDataDurationBandwidth("-" + blvalue);
          setDataManualDurationBandwidth("-" + blvalue);
          currentCycleBandwidthNext.classList.add("disabled");
          currentCycleBandwidthPrev.classList.remove("disabled");
          // currentCycleValue.value=0;
          localStorage.set("graphBandwidthCycle", 0);
        }
      } else {
        if (blvalue >= 0 && blvalue < 12) {
          blvalue++;
          setDataDurationBandwidth("-" + blvalue);
          setDataManualDurationBandwidth("-" + blvalue);
          currentCycleBandwidthNext.classList.remove("disabled");
          currentCycleBandwidthPrev.classList.remove("disabled");
          // currentCycleValue.value=blvalue;
          localStorage.set("graphBandwidthCycle", blvalue);
        } else if (blvalue == 12) {
          setDataDurationBandwidth("-" + blvalue);
          setDataManualDurationBandwidth("-" + blvalue);
          currentCycleBandwidthNext.classList.remove("disabled");
          currentCycleBandwidthPrev.classList.add("disabled");
          // currentCycleValue.value=12;
          localStorage.set("graphBandwidthCycle", 12);
        }
      }

    }

  };

  function getPreviousMonthName(offset) {
    const currentMonth = new Date().getMonth();
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const targetMonth = (currentMonth - offset + 12) % 12;
    return months[targetMonth];
  }
  return (
    <>

      <div className="post flex-column-fluid" id="kt_post">
        {/*begin::Container*/}
        <div className="card card-flush h-80px bg-dark mb-10" style={{ borderRadius: "0" }}>
          <div className="card-header pt-5 pb-5">
            {/*begin::Title*/}
            <h3 className="align-items-start flex-column">
              <span className="card-label fw-bolder text-light">
                Performance Overview
              </span>
              <p className="text-gray-400 mt-3 fw-bold fs-6">
                View Your Server Performance
              </p>
            </h3>
            {/*end::Title*/}
            {/*begin::Toolbar*/}
            <div className="card-toolbar">
              <ul className="nav" id="kt_chart_widget_8_tabs">
                <li className="nav-item">
                  {/*begin::Trigger*/}
                  <p style={{ marginRight: "102px" }}>
                    <button
                      type="button"
                      className="btn btn-dark mb-4"
                      data-kt-menu-trigger="click"
                      data-kt-menu-placement="bottom-start"
                    >
                      Chart Cycle :

                    </button>
                    <button
                      type="button"
                      onClick={toggleMenu}
                      className="btn btn-primary btn-sm mb-6"
                      data-kt-menu-trigger="click"
                      data-kt-menu-placement="bottom-start"
                    >
                      <span id="currentCycle">4 hours</span>
                      <span className="svg-icon svg-icon-5 rotate-180 ms-3 me-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                            fill="black"
                          />
                        </svg>

                      </span>
                    </button>
                  </p>
                  {/*end::Trigger*/}
                  {/*begin::Menu*/}
                  <div id="chart-menu"
                    className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4"
                    data-kt-menu="true"
                  >
                    {/*begin::Menu item*/}
                    <div className="menu-item px-3">
                      <a onClick={(e) => changeCycle('1h', '1 hour')} className="menu-link px-3">
                        1 hour
                      </a>
                    </div>
                    {/*end::Menu item*/}
                    {/*begin::Menu item*/}
                    <div className="menu-item px-3">
                      <a onClick={(e) => changeCycle('4h', '4 hours')} className="menu-link px-3">
                        4 hours
                      </a>
                    </div>
                    {/*end::Menu item*/}
                    {/*begin::Menu item*/}
                    <div className="menu-item px-3">
                      <a onClick={(e) => changeCycle('12h', '12 hours')} className="menu-link px-3">
                        12 hours
                      </a>
                    </div>
                    {/*end::Menu item*/}
                    {/*begin::Menu item*/}
                    <div className="menu-item px-3">
                      <a onClick={(e) => changeCycle('24h', '24 hours')} className="menu-link px-3">
                        24 hours
                      </a>
                    </div>
                    {/*end::Menu item*/}
                    {/*begin::Menu item*/}
                    <div className="menu-item px-3">
                      <a onClick={(e) => changeCycle('1w', '7 days')} className="menu-link px-3">
                        7 days
                      </a>
                    </div>
                    {/*end::Menu item*/}
                    {/*begin::Menu item*/}
                    <div className="menu-item px-3">
                      <a onClick={(e) => changeCycle('1M', '30 days')} className="menu-link px-3">
                        30 days
                      </a>
                    </div>
                    {/*end::Menu item*/}
                    {/*begin::Menu item*/}
                    <div className="menu-item px-3">
                      <a onClick={(e) => changeCycle('2M', '60 days')} className="menu-link px-3">
                        60 days      </a>
                    </div>
                    {/*end::Menu item*/}
                  </div>
                  {/*end::Menu*/}
                </li>
              </ul>
            </div>
            {/*end::Toolbar*/}
          </div>
        </div>

        <div id="" className="">
          {/*begin::Row*/}
          {dataDuration ? (<>
            {cloudid != null ? (<>
              <GrafanaViewerOverview cloudid={cloudid} key={dataDuration} dataTime={dataDuration} />
            </>) : ('')}
          </>) : (
            <><TableLoader /></>
          )}
        </div>
        {/*end::Row*/}


        <div className="card card-flush h-80px bg-dark mb-5 mt-5">
          <div className="card-header pt-5 pb-5">
            {/*begin::Title*/}
            <h3 className="align-items-start flex-column">
              <span className="card-label fw-bolder text-light">
                Bandwidth Overview
              </span>
              <p className="text-gray-400 mt-3 fw-bold fs-6">
                View Your Bandwidth Usage
              </p>
            </h3>
            {/*end::Title*/}
            {/*begin::Toolbar*/}
            <div className="card-toolbar">
              <ul className="nav" id="kt_chart_widget_8_tabs">
                <li className="nav-item">
                  {/*begin::Trigger*/}
                  <p style={{ marginRight: "102px" }}>
                    <button
                      type="button"
                      className="btn btn-dark mb-4"
                      data-kt-menu-trigger="click"
                      data-kt-menu-placement="bottom-start"
                    >
                      {localStorage.get("graphBandwidthCycle") != '0' ? ("Last ") : ('')}
                      {getPreviousMonthName(localStorage.get("graphBandwidthCycle"))} Month's Graph :

                    </button>
                    <button
                      type="button"
                      id="currentCycleBandwidthPrev"
                      onClick={(e) => changeBandwidthCycles("prev")}
                      className="btn btn-light-primary btn-sm mb-6"
                      style={{ width: "120px" }}
                    >
                      <span className="svg-icon svg-icon-5 rotate-180 ms-3 me-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M17.6 4L9.6 12L17.6 20H13.6L6.3 12.7C5.9 12.3 5.9 11.7 6.3 11.3L13.6 4H17.6Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                      &nbsp;<span >Previous</span>
                    </button>
                    &nbsp;&nbsp;

                    <input type="hidden" id="bandwidthCycleValue" value="0" />
                    <button
                      type="button"
                      onClick={(e) => changeBandwidthCycles("next")}
                      id="currentCycleBandwidthNext"
                      className="btn btn-light-primary disabled btn-sm mb-6"
                      style={{ width: "120px" }}
                    >
                      <span >Next</span>

                      <span className="svg-icon svg-icon-5 rotate-180 ms-3 me-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M6 4L14 12L6 20H10L17.3 12.7C17.7 12.3 17.7 11.7 17.3 11.3L10 4H6Z"
                            fill="black"
                          />
                        </svg>

                      </span>
                    </button>
                  </p>
                  {/*end::Trigger*/}
                  {/*begin::Menu*/}
                  <div id="chart-menu-Bandwidth"
                    className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4"
                    data-kt-menu="true"
                  >
                    {/*begin::Menu item*/}
                    <div className="menu-item px-3">
                      <a onClick={(e) => changeBandwidthCycle('0', '0', 'Current Month')} className="menu-link px-3">
                        Current Month
                      </a>
                    </div>
                    {/*end::Menu item*/}
                    {/*begin::Menu item*/}
                    <div className="menu-item px-3">
                      <a onClick={(e) => changeBandwidthCycle('-1M', '-1M', 'Previous Month')} className="menu-link px-3">
                        Previous Month
                      </a>
                    </div>
                    {/*end::Menu item*/}
                    {/*begin::Menu item*/}
                    <div className="menu-item px-3">
                      <a onClick={(e) => changeBandwidthCycle('-2M', '-2M', 'Last 2 Months')} className="menu-link px-3">
                        Last 2 Months
                      </a>
                    </div>
                    {/*end::Menu item*/}
                    {/*begin::Menu item*/}
                    <div className="menu-item px-3">
                      <a onClick={(e) => changeBandwidthCycle('-3M', '-3M', 'Last 3 Months')} className="menu-link px-3">
                        Last 3 Months
                      </a>
                    </div>
                    {/*end::Menu item*/}
                    {/*begin::Menu item*/}
                    <div className="menu-item px-3">
                      <a onClick={(e) => changeBandwidthCycle('-6M', '-6M', 'Last 6 Months')} className="menu-link px-3">
                        Last 6 Months
                      </a>
                    </div>
                    {/*end::Menu item*/}
                    {/*begin::Menu item*/}
                    <div className="menu-item px-3">
                      <a onClick={(e) => changeBandwidthCycle('-9M', '-9M', 'Last 9 Months')} className="menu-link px-3">
                        Last 9 Months
                      </a>
                    </div>
                    {/*end::Menu item*/}
                    {/*begin::Menu item*/}
                    <div className="menu-item px-3">
                      <a onClick={(e) => changeBandwidthCycle('-12M', '-12M', 'Last 12 Months')} className="menu-link px-3">
                        Last 12 Months
                      </a>
                    </div>
                    {/*end::Menu item*/}
                  </div>
                  {/*end::Menu*/}
                </li>
              </ul>
            </div>
            {/*end::Toolbar*/}
          </div>
        </div>

        <div id="" className="">
          {/*begin::Row*/}
          {dataDurationBandwidth ? (<>
            {cloudid != null ? (<>
              <GrafanaViewerBandwidthOverview cloudid={cloudid} key={dataDurationBandwidth} dataTime={dataDurationBandwidth} />
            </>) : ('')}
          </>) : (
            <><TableLoader /></>
          )}
        </div>
      </div>


    </>
  )
}


