import localStorage from "local-storage";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UseApi from "../../../CustomHooks/useApi";
import swal from "sweetalert"; 
// import Joyride from 'react-joyride';
export default function AsideBar() {
  useEffect(() => {
    window.KTScroll.init();
    window.KTDrawer.init();
    window.KTMenu.createInstances();
    window.KTToggle.createInstances();
    // window.initSelect2();
  }, []);

  const [logo_white, setLogo_white] = useState(null);
  const [appInfo, setAppInfo] = useState(null); 
  const [siteUrl, setSiteUrl] = useState(null);
   
  // const [privateAppLock, setPrivateAppLock] = useState([]);

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function asideCall() {
    var app_infoData = localStorage.get("app_info");
    var user_info_data = localStorage.get("user_info");
    if (app_infoData == null || user_info_data == null) {
      const user_infoData = await UseApi("get", "account/info", "");
      const app_info = await UseApi("get", "/appinfo", "");
      if (user_infoData) {
        localStorage.set("user_info", user_infoData.user);
        localStorage.set("app_info", app_info);
        var user_info = user_infoData.user;
      }
      if (app_info && user_info) {
        ////console.log("app info =>");
        ////console.log(app_info);
        var siteUrlString = app_info.site;

        var siteUrlArr = siteUrlString.split(".");
        var siteLength = siteUrlArr.length;

        var siteOrg =
          "https://api." +
          siteUrlArr[siteLength - 2] +
          "." +
          siteUrlArr[siteLength - 1];
        localStorage.set("filesUrl", siteOrg);
        var whiteLogo = siteOrg + "/publicfiles/" + app_info.logo_light;
        setLogo_white(whiteLogo);
        setSiteUrl(siteOrg); 
        setAppInfo(app_info); 
      }
    } else {
      var siteUrlString = app_infoData.site;

      var siteUrlArr = siteUrlString.split(".");
      var siteLength = siteUrlArr.length;

      var siteOrg =
        "https://api." +
        siteUrlArr[siteLength - 2] +
        "." +
        siteUrlArr[siteLength - 1];
      localStorage.set("filesUrl", siteOrg);
      var whiteLogo = siteOrg + "/publicfiles/" + app_infoData.logo_light;
      setLogo_white(whiteLogo);
      setSiteUrl(siteOrg); 
      setAppInfo(app_infoData); 
    }
  }  
  let navigate = useNavigate()
  const handelRouteChange = (route) => { 
    navigate(`/${route}`)
  }


  const location = useLocation();

  useEffect(() => { 
    asideCall(); 
  }, [location]);
  return (
    <>
      {/*begin::Aside*/}
      <div
        id="kt_aside"
        className="aside aside-dark aside-hoverable"
        data-kt-drawer="true"
        data-kt-drawer-name="aside"
        data-kt-drawer-activate="{default: true, lg: false}"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="{default:'200px', '300px': '250px'}"
        data-kt-drawer-direction="start"
        data-kt-drawer-toggle="#kt_aside_mobile_toggle"
      >
        {/*begin::Brand*/}
        <div className="aside-logo flex-column-auto" id="kt_aside_logo">
          {/*begin::Logo*/}

          <a>
            {logo_white != null ? (
              <>
                <img alt="Logo" src={logo_white} className="h-30px logo" />
              </>
            ) : (
              ""
            )}
          </a>

          {/*end::Logo*/}
          {/*begin::Aside toggler*/}
          <div
            id="kt_aside_toggle"
            className="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle"
            data-kt-toggle="true"
            data-kt-toggle-state="active"
            data-kt-toggle-target="body"
            data-kt-toggle-name="aside-minimize"
          >
            {/*begin::Svg Icon | path: icons/duotune/arrows/arr079.svg*/}
            <span className="svg-icon svg-icon-1 rotate-180">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  opacity="0.5"
                  d="M14.2657 11.4343L18.45 7.25C18.8642 6.83579 18.8642 6.16421 18.45 5.75C18.0358 5.33579 17.3642 5.33579 16.95 5.75L11.4071 11.2929C11.0166 11.6834 11.0166 12.3166 11.4071 12.7071L16.95 18.25C17.3642 18.6642 18.0358 18.6642 18.45 18.25C18.8642 17.8358 18.8642 17.1642 18.45 16.75L14.2657 12.5657C13.9533 12.2533 13.9533 11.7467 14.2657 11.4343Z"
                  fill="black"
                />
                <path
                  d="M8.2657 11.4343L12.45 7.25C12.8642 6.83579 12.8642 6.16421 12.45 5.75C12.0358 5.33579 11.3642 5.33579 10.95 5.75L5.40712 11.2929C5.01659 11.6834 5.01659 12.3166 5.40712 12.7071L10.95 18.25C11.3642 18.6642 12.0358 18.6642 12.45 18.25C12.8642 17.8358 12.8642 17.1642 12.45 16.75L8.2657 12.5657C7.95328 12.2533 7.95328 11.7467 8.2657 11.4343Z"
                  fill="black"
                />
              </svg>
            </span>
            {/*end::Svg Icon*/}
          </div>
          {/*end::Aside toggler*/}
        </div>
        {/*end::Brand*/}
        {/*begin::Aside menu*/}
        <div className="aside-menu flex-column-fluid">
            {/*begin::Aside Menu*/}
            <div
              className="hover-scroll-overlay-y my-5 my-lg-5"
              id="kt_aside_menu_wrapper"
              data-kt-scroll="true"
              data-kt-scroll-activate="{default: false, lg: true}"
              data-kt-scroll-height="auto"
              data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
              data-kt-scroll-wrappers="#kt_aside_menu"
              data-kt-scroll-offset={0}
            >
              {/*begin::Menu*/}
              <div
                className="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"
                id="#kt_aside_menu"
                data-kt-menu="true"
                data-kt-menu-expand="false"
              >
                <div 
                  className="menu-item menu-accordion"
                >
                  <span className="menu-link fs-4">
                    <span className="menu-icon">
                      {/*begin::Svg Icon | path: icons/duotune/general/gen025.svg*/}
                      <span className="svg-icon svg-icon-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <rect
                            x={2}
                            y={2}
                            width={9}
                            height={9}
                            rx={2}
                            fill="black"
                          />
                          <rect
                            opacity="0.3"
                            x={13}
                            y={2}
                            width={9}
                            height={9}
                            rx={2}
                            fill="black"
                          />
                          <rect
                            opacity="0.3"
                            x={13}
                            y={13}
                            width={9}
                            height={9}
                            rx={2}
                            fill="black"
                          />
                          <rect
                            opacity="0.3"
                            x={2}
                            y={13}
                            width={9}
                            height={9}
                            rx={2}
                            fill="black"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}
                    </span>
                    <span className="menu-title" onClick={(e) => {handelRouteChange('dashboard')}}>Dashboards</span>
                  </span>

                </div>
                <div className="menu-item">
                  <div className="menu-content pt-8 pb-2">
                    <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                      Services
                    </span>
                  </div>
                </div>
                
                <div 
                  className="menu-item menu-accordion d-none"
                >
             
                </div>
                <div
                  data-kt-menu-trigger="click"
                  className="menu-item menu-accordion d-none"
                >
                  <span className="menu-link fs-4">
                    <span className="menu-icon">
                      {/*begin::Svg Icon | path: icons/duotune/technology/teh004.svg*/}
                      <span className="svg-icon svg-icon-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            opacity="0.3"
                            d="M21 10.7192H3C2.4 10.7192 2 11.1192 2 11.7192C2 12.3192 2.4 12.7192 3 12.7192H6V14.7192C6 18.0192 8.7 20.7192 12 20.7192C15.3 20.7192 18 18.0192 18 14.7192V12.7192H21C21.6 12.7192 22 12.3192 22 11.7192C22 11.1192 21.6 10.7192 21 10.7192Z"
                            fill="black"
                          />
                          <path
                            d="M11.6 21.9192C11.4 21.9192 11.2 21.8192 11 21.7192C10.6 21.4192 10.5 20.7191 10.8 20.3191C11.7 19.1191 12.3 17.8191 12.7 16.3191C12.8 15.8191 13.4 15.4192 13.9 15.6192C14.4 15.7192 14.8 16.3191 14.6 16.8191C14.2 18.5191 13.4 20.1192 12.4 21.5192C12.2 21.7192 11.9 21.9192 11.6 21.9192ZM8.7 19.7192C10.2 18.1192 11 15.9192 11 13.7192V8.71917C11 8.11917 11.4 7.71917 12 7.71917C12.6 7.71917 13 8.11917 13 8.71917V13.0192C13 13.6192 13.4 14.0192 14 14.0192C14.6 14.0192 15 13.6192 15 13.0192V8.71917C15 7.01917 13.7 5.71917 12 5.71917C10.3 5.71917 9 7.01917 9 8.71917V13.7192C9 15.4192 8.4 17.1191 7.2 18.3191C6.8 18.7191 6.9 19.3192 7.3 19.7192C7.5 19.9192 7.7 20.0192 8 20.0192C8.3 20.0192 8.5 19.9192 8.7 19.7192ZM6 16.7192C6.5 16.7192 7 16.2192 7 15.7192V8.71917C7 8.11917 7.1 7.51918 7.3 6.91918C7.5 6.41918 7.2 5.8192 6.7 5.6192C6.2 5.4192 5.59999 5.71917 5.39999 6.21917C5.09999 7.01917 5 7.81917 5 8.71917V15.7192V15.8191C5 16.3191 5.5 16.7192 6 16.7192ZM9 4.71917C9.5 4.31917 10.1 4.11918 10.7 3.91918C11.2 3.81918 11.5 3.21917 11.4 2.71917C11.3 2.21917 10.7 1.91916 10.2 2.01916C9.4 2.21916 8.59999 2.6192 7.89999 3.1192C7.49999 3.4192 7.4 4.11916 7.7 4.51916C7.9 4.81916 8.2 4.91918 8.5 4.91918C8.6 4.91918 8.8 4.81917 9 4.71917ZM18.2 18.9192C18.7 17.2192 19 15.5192 19 13.7192V8.71917C19 5.71917 17.1 3.1192 14.3 2.1192C13.8 1.9192 13.2 2.21917 13 2.71917C12.8 3.21917 13.1 3.81916 13.6 4.01916C15.6 4.71916 17 6.61917 17 8.71917V13.7192C17 15.3192 16.8 16.8191 16.3 18.3191C16.1 18.8191 16.4 19.4192 16.9 19.6192C17 19.6192 17.1 19.6192 17.2 19.6192C17.7 19.6192 18 19.3192 18.2 18.9192Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}
                    </span>
                    <span className="menu-title" onClick={()=>{handelRouteChange('renewals')}}>Renewal</span>
                  </span>
                </div>
                <div
                  data-kt-menu-trigger="click"
                  className="menu-item menu-accordion d-none"
                >
                  <span className="menu-link fs-4">
                    <span className="menu-icon">
                      {/*begin::Svg Icon | path: icons/duotune/art/art009.svg*/}
                      <span className="svg-icon svg-icon-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            opacity="0.3"
                            d="M21 18.3V4H20H5C4.4 4 4 4.4 4 5V20C10.9 20 16.7 15.6 19 9.5V18.3C18.4 18.6 18 19.3 18 20C18 21.1 18.9 22 20 22C21.1 22 22 21.1 22 20C22 19.3 21.6 18.6 21 18.3Z"
                            fill="black"
                          />
                          <path
                            d="M22 4C22 2.9 21.1 2 20 2C18.9 2 18 2.9 18 4C18 4.7 18.4 5.29995 18.9 5.69995C18.1 12.6 12.6 18.2 5.70001 18.9C5.30001 18.4 4.7 18 4 18C2.9 18 2 18.9 2 20C2 21.1 2.9 22 4 22C4.8 22 5.39999 21.6 5.79999 20.9C13.8 20.1 20.1 13.7 20.9 5.80005C21.6 5.40005 22 4.8 22 4Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}
                    </span>
                    <span className="menu-title" onClick={()=>{handelRouteChange("earnings")}}>Earning</span>
                  </span>

                </div>
                <div 
                  className="menu-item menu-accordion"
                >
                  <span className="menu-link fs-4">
                    <span className="menu-icon">
                      {/*begin::Svg Icon | path: icons/duotune/general/gen022.svg*/}
                      <span className="svg-icon svg-icon-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M11.2929 2.70711C11.6834 2.31658 12.3166 2.31658 12.7071 2.70711L15.2929 5.29289C15.6834 5.68342 15.6834 6.31658 15.2929 6.70711L12.7071 9.29289C12.3166 9.68342 11.6834 9.68342 11.2929 9.29289L8.70711 6.70711C8.31658 6.31658 8.31658 5.68342 8.70711 5.29289L11.2929 2.70711Z"
                            fill="black"
                          />
                          <path
                            d="M11.2929 14.7071C11.6834 14.3166 12.3166 14.3166 12.7071 14.7071L15.2929 17.2929C15.6834 17.6834 15.6834 18.3166 15.2929 18.7071L12.7071 21.2929C12.3166 21.6834 11.6834 21.6834 11.2929 21.2929L8.70711 18.7071C8.31658 18.3166 8.31658 17.6834 8.70711 17.2929L11.2929 14.7071Z"
                            fill="black"
                          />
                          <path
                            opacity="0.3"
                            d="M5.29289 8.70711C5.68342 8.31658 6.31658 8.31658 6.70711 8.70711L9.29289 11.2929C9.68342 11.6834 9.68342 12.3166 9.29289 12.7071L6.70711 15.2929C6.31658 15.6834 5.68342 15.6834 5.29289 15.2929L2.70711 12.7071C2.31658 12.3166 2.31658 11.6834 2.70711 11.2929L5.29289 8.70711Z"
                            fill="black"
                          />
                          <path
                            opacity="0.3"
                            d="M17.2929 8.70711C17.6834 8.31658 18.3166 8.31658 18.7071 8.70711L21.2929 11.2929C21.6834 11.6834 21.6834 12.3166 21.2929 12.7071L18.7071 15.2929C18.3166 15.6834 17.6834 15.6834 17.2929 15.2929L14.7071 12.7071C14.3166 12.3166 14.3166 11.6834 14.7071 11.2929L17.2929 8.70711Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}
                    </span>
                    <span className="menu-title" onClick={()=>{handelRouteChange('customers')}}>Customers</span>
                  </span>
  
                </div>
                <div 
                  className="menu-item menu-accordion"
                >
                  <span className="menu-link fs-4">
                    <span className="menu-icon">
                      {/*begin::Svg Icon | path: icons/duotune/ecommerce/ecm007.svg*/}
                      <span className="svg-icon svg-icon-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M21 9V11C21 11.6 20.6 12 20 12H14V8H20C20.6 8 21 8.4 21 9ZM10 8H4C3.4 8 3 8.4 3 9V11C3 11.6 3.4 12 4 12H10V8Z"
                            fill="black"
                          />
                          <path
                            d="M15 2C13.3 2 12 3.3 12 5V8H15C16.7 8 18 6.7 18 5C18 3.3 16.7 2 15 2Z"
                            fill="black"
                          />
                          <path
                            opacity="0.3"
                            d="M9 2C10.7 2 12 3.3 12 5V8H9C7.3 8 6 6.7 6 5C6 3.3 7.3 2 9 2ZM4 12V21C4 21.6 4.4 22 5 22H10V12H4ZM20 12V21C20 21.6 19.6 22 19 22H14V12H20Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}
                    </span>
                    <span className="menu-title" onClick={()=>{handelRouteChange('orders')}}>Orders</span>
                  </span>
                   
                </div>
                <div
                  data-kt-menu-trigger="click"
                  className="menu-item menu-accordion mb-1 d-none"
                >
                   <Link to="/leads">
                  <span className="menu-link fs-4">
                    <span className="menu-icon">
                      {/*begin::Svg Icon | path: icons/duotune/graphs/gra006.svg*/}
                      <span className="svg-icon svg-icon-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M13 5.91517C15.8 6.41517 18 8.81519 18 11.8152C18 12.5152 17.9 13.2152 17.6 13.9152L20.1 15.3152C20.6 15.6152 21.4 15.4152 21.6 14.8152C21.9 13.9152 22.1 12.9152 22.1 11.8152C22.1 7.01519 18.8 3.11521 14.3 2.01521C13.7 1.91521 13.1 2.31521 13.1 3.01521V5.91517H13Z"
                            fill="black"
                          />
                          <path
                            opacity="0.3"
                            d="M19.1 17.0152C19.7 17.3152 19.8 18.1152 19.3 18.5152C17.5 20.5152 14.9 21.7152 12 21.7152C9.1 21.7152 6.50001 20.5152 4.70001 18.5152C4.30001 18.0152 4.39999 17.3152 4.89999 17.0152L7.39999 15.6152C8.49999 16.9152 10.2 17.8152 12 17.8152C13.8 17.8152 15.5 17.0152 16.6 15.6152L19.1 17.0152ZM6.39999 13.9151C6.19999 13.2151 6 12.5152 6 11.8152C6 8.81517 8.2 6.41515 11 5.91515V3.01519C11 2.41519 10.4 1.91519 9.79999 2.01519C5.29999 3.01519 2 7.01517 2 11.8152C2 12.8152 2.2 13.8152 2.5 14.8152C2.7 15.4152 3.4 15.7152 4 15.3152L6.39999 13.9151Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}
                    </span>
                    <span className="menu-title"  >Leads</span>
                  </span>
                </Link>
                </div>

                <div className="menu-item ">
                <Link to="/invoices">
                  <a href="#" className="menu-link fs-4">
                    <span className="menu-icon">
                      {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                      <span className="svg-icon svg-icon-2">
                        <i
                          className="las la-rupee-sign"
                          style={{ fontSize: 20 }}
                        />
                      </span>
                      {/*end::Svg Icon*/}
                    </span>
                    <span className="menu-title">Invoices</span>
                    <span className="billingTab d-none" id="billing-tab">
                      <svg
                        viewBox="0 0 34 34"
                        width={24}
                        height={24}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M5.5 10V7C5.5 5.27609 6.18482 3.62279 7.40381 2.40381C8.62279 1.18482 10.2761 0.5 12 0.5C13.7239 0.5 15.3772 1.18482 16.5962 2.40381C17.8152 3.62279 18.5 5.27609 18.5 7V10H19C20.6569 10 22 11.3431 22 13V20C22 21.6569 20.6569 23 19 23H5C3.34315 23 2 21.6569 2 20V13C2 11.3431 3.34315 10 5 10H5.5ZM9.52513 4.52513C10.1815 3.86875 11.0717 3.5 12 3.5C12.9283 3.5 13.8185 3.86875 14.4749 4.52513C15.1313 5.1815 15.5 6.07174 15.5 7V10H8.5V7C8.5 6.07174 8.86875 5.1815 9.52513 4.52513Z"
                            fill="#494b74"
                          />{" "}
                        </g>
                      </svg>
                    </span>
                  </a>
                </Link>
              </div>
              <div className="menu-item d-none">
                <Link to="/email">
                  <a href="#" className="menu-link fs-4">
                    <span className="menu-icon">
                      {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                      <span className="svg-icon svg-icon-2">
                      <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M6 8.725C6 8.125 6.4 7.725 7 7.725H14L18 11.725V12.925L22 9.725L12.6 2.225C12.2 1.925 11.7 1.925 11.4 2.225L2 9.725L6 12.925V8.725Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M22 9.72498V20.725C22 21.325 21.6 21.725 21 21.725H3C2.4 21.725 2 21.325 2 20.725V9.72498L11.4 17.225C11.8 17.525 12.3 17.525 12.6 17.225L22 9.72498ZM15 11.725H18L14 7.72498V10.725C14 11.325 14.4 11.725 15 11.725Z"
        fill="black"
      />
    </svg>
                      </span>
                      {/*end::Svg Icon*/}
                    </span>
                    <span className="menu-title">Send Email</span>
                    <span className="billingTab d-none" id="billing-tab">
                      <svg
                        viewBox="0 0 34 34"
                        width={24}
                        height={24}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M5.5 10V7C5.5 5.27609 6.18482 3.62279 7.40381 2.40381C8.62279 1.18482 10.2761 0.5 12 0.5C13.7239 0.5 15.3772 1.18482 16.5962 2.40381C17.8152 3.62279 18.5 5.27609 18.5 7V10H19C20.6569 10 22 11.3431 22 13V20C22 21.6569 20.6569 23 19 23H5C3.34315 23 2 21.6569 2 20V13C2 11.3431 3.34315 10 5 10H5.5ZM9.52513 4.52513C10.1815 3.86875 11.0717 3.5 12 3.5C12.9283 3.5 13.8185 3.86875 14.4749 4.52513C15.1313 5.1815 15.5 6.07174 15.5 7V10H8.5V7C8.5 6.07174 8.86875 5.1815 9.52513 4.52513Z"
                            fill="#494b74"
                          />{" "}
                        </g>
                      </svg>
                    </span>
                  </a>
                </Link>
              </div>
                <div className="menu-item">
                  <div className="menu-content pt-8 pb-2">
                    <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                      Account
                    </span>
                  </div>
                </div>
                <div className="menu-item d-none">
                <Link to="/account/kyc">
                  <a className="menu-link fs-4">
                    <span className="menu-icon">
                      {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                      <span className="svg-icon svg-icon-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            opacity="0.3"
                            d="M18 21.6C16.3 21.6 15 20.3 15 18.6V2.50001C15 2.20001 14.6 1.99996 14.3 2.19996L13 3.59999L11.7 2.3C11.3 1.9 10.7 1.9 10.3 2.3L9 3.59999L7.70001 2.3C7.30001 1.9 6.69999 1.9 6.29999 2.3L5 3.59999L3.70001 2.3C3.50001 2.1 3 2.20001 3 3.50001V18.6C3 20.3 4.3 21.6 6 21.6H18Z"
                            fill="black"
                          />
                          <path
                            d="M12 12.6H11C10.4 12.6 10 12.2 10 11.6C10 11 10.4 10.6 11 10.6H12C12.6 10.6 13 11 13 11.6C13 12.2 12.6 12.6 12 12.6ZM9 11.6C9 11 8.6 10.6 8 10.6H6C5.4 10.6 5 11 5 11.6C5 12.2 5.4 12.6 6 12.6H8C8.6 12.6 9 12.2 9 11.6ZM9 7.59998C9 6.99998 8.6 6.59998 8 6.59998H6C5.4 6.59998 5 6.99998 5 7.59998C5 8.19998 5.4 8.59998 6 8.59998H8C8.6 8.59998 9 8.19998 9 7.59998ZM13 7.59998C13 6.99998 12.6 6.59998 12 6.59998H11C10.4 6.59998 10 6.99998 10 7.59998C10 8.19998 10.4 8.59998 11 8.59998H12C12.6 8.59998 13 8.19998 13 7.59998ZM13 15.6C13 15 12.6 14.6 12 14.6H10C9.4 14.6 9 15 9 15.6C9 16.2 9.4 16.6 10 16.6H12C12.6 16.6 13 16.2 13 15.6Z"
                            fill="black"
                          />
                          <path
                            d="M15 18.6C15 20.3 16.3 21.6 18 21.6C19.7 21.6 21 20.3 21 18.6V12.5C21 12.2 20.6 12 20.3 12.2L19 13.6L17.7 12.3C17.3 11.9 16.7 11.9 16.3 12.3L15 13.6V18.6Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}
                    </span>
                    <span className="menu-title">KYC</span>
                    <span className="kycTab d-none" id="kyc-tab">
                      <svg
                        viewBox="0 0 34 34"
                        width={24}
                        height={24}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M5.5 10V7C5.5 5.27609 6.18482 3.62279 7.40381 2.40381C8.62279 1.18482 10.2761 0.5 12 0.5C13.7239 0.5 15.3772 1.18482 16.5962 2.40381C17.8152 3.62279 18.5 5.27609 18.5 7V10H19C20.6569 10 22 11.3431 22 13V20C22 21.6569 20.6569 23 19 23H5C3.34315 23 2 21.6569 2 20V13C2 11.3431 3.34315 10 5 10H5.5ZM9.52513 4.52513C10.1815 3.86875 11.0717 3.5 12 3.5C12.9283 3.5 13.8185 3.86875 14.4749 4.52513C15.1313 5.1815 15.5 6.07174 15.5 7V10H8.5V7C8.5 6.07174 8.86875 5.1815 9.52513 4.52513Z"
                            fill="#494b74"
                          />{" "}
                        </g>
                      </svg>
                    </span>
                  </a>
                </Link>
              </div>
                <div className="menu-item ">
                <Link to="/account">
                  <a href="#" className="menu-link fs-4">
                    <span className="menu-icon">
                      {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                      <span className="svg-icon svg-icon-2">
                        <i className="bi bi-gear" style={{ fontSize: 20 }} />
                      </span>
                      {/*end::Svg Icon*/}
                    </span>
                    <span className="menu-title">Setting</span>
                    <span className="accountTab d-none" id="account-tab">
                      <svg
                        viewBox="0 0 34 34"
                        width={24}
                        height={24}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M5.5 10V7C5.5 5.27609 6.18482 3.62279 7.40381 2.40381C8.62279 1.18482 10.2761 0.5 12 0.5C13.7239 0.5 15.3772 1.18482 16.5962 2.40381C17.8152 3.62279 18.5 5.27609 18.5 7V10H19C20.6569 10 22 11.3431 22 13V20C22 21.6569 20.6569 23 19 23H5C3.34315 23 2 21.6569 2 20V13C2 11.3431 3.34315 10 5 10H5.5ZM9.52513 4.52513C10.1815 3.86875 11.0717 3.5 12 3.5C12.9283 3.5 13.8185 3.86875 14.4749 4.52513C15.1313 5.1815 15.5 6.07174 15.5 7V10H8.5V7C8.5 6.07174 8.86875 5.1815 9.52513 4.52513Z"
                            fill="#494b74"
                          />{" "}
                        </g>
                      </svg>
                    </span>
                  </a>
                </Link>
              </div>
             
              <div className="menu-item ">
                <Link to="/activity">
                  <a href="#" className="menu-link fs-4">
                    <span className="menu-icon">
                      {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                      <span className="svg-icon svg-icon-2">
                        <i
                          className="las la-sliders-h"
                          style={{ fontSize: 20 }}
                        />
                      </span>
                      {/*end::Svg Icon*/}
                    </span>
                    <span className="menu-title">Activity Logs</span>
                    <span className="activityTab d-none" id="activity-tab">
                      <svg
                        viewBox="0 0 34 34"
                        width={24}
                        height={24}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M5.5 10V7C5.5 5.27609 6.18482 3.62279 7.40381 2.40381C8.62279 1.18482 10.2761 0.5 12 0.5C13.7239 0.5 15.3772 1.18482 16.5962 2.40381C17.8152 3.62279 18.5 5.27609 18.5 7V10H19C20.6569 10 22 11.3431 22 13V20C22 21.6569 20.6569 23 19 23H5C3.34315 23 2 21.6569 2 20V13C2 11.3431 3.34315 10 5 10H5.5ZM9.52513 4.52513C10.1815 3.86875 11.0717 3.5 12 3.5C12.9283 3.5 13.8185 3.86875 14.4749 4.52513C15.1313 5.1815 15.5 6.07174 15.5 7V10H8.5V7C8.5 6.07174 8.86875 5.1815 9.52513 4.52513Z"
                            fill="#494b74"
                          />{" "}
                        </g>
                      </svg>
                    </span>
                  </a>
                </Link>
              </div>
              {/* Account end */}
              {/* Support start */}
              <div className="menu-item">
                <div className="menu-content pt-8 pb-0">
                  <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                    Support
                  </span>
                </div>
              </div> 
                  <div className="menu-item ">
                    <Link to="/ticket">
                      <a href="#" className="menu-link fs-4">
                        <span className="menu-icon">
                          {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                          <span className="svg-icon svg-icon-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                opacity="0.3"
                                d="M8 8C8 7.4 8.4 7 9 7H16V3C16 2.4 15.6 2 15 2H3C2.4 2 2 2.4 2 3V13C2 13.6 2.4 14 3 14H5V16.1C5 16.8 5.79999 17.1 6.29999 16.6L8 14.9V8Z"
                                fill="black"
                              />
                              <path
                                d="M22 8V18C22 18.6 21.6 19 21 19H19V21.1C19 21.8 18.2 22.1 17.7 21.6L15 18.9H9C8.4 18.9 8 18.5 8 17.9V7.90002C8 7.30002 8.4 6.90002 9 6.90002H21C21.6 7.00002 22 7.4 22 8ZM19 11C19 10.4 18.6 10 18 10H12C11.4 10 11 10.4 11 11C11 11.6 11.4 12 12 12H18C18.6 12 19 11.6 19 11ZM17 15C17 14.4 16.6 14 16 14H12C11.4 14 11 14.4 11 15C11 15.6 11.4 16 12 16H16C16.6 16 17 15.6 17 15Z"
                                fill="black"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </span>
                        <span className="menu-title">Tickets</span>
                        <span className="ticketTab d-none" id="ticket-tab">
                          <svg
                            viewBox="0 0 34 34"
                            width={24}
                            height={24}
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                            <g
                              id="SVGRepo_tracerCarrier"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <g id="SVGRepo_iconCarrier">
                              {" "}
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M5.5 10V7C5.5 5.27609 6.18482 3.62279 7.40381 2.40381C8.62279 1.18482 10.2761 0.5 12 0.5C13.7239 0.5 15.3772 1.18482 16.5962 2.40381C17.8152 3.62279 18.5 5.27609 18.5 7V10H19C20.6569 10 22 11.3431 22 13V20C22 21.6569 20.6569 23 19 23H5C3.34315 23 2 21.6569 2 20V13C2 11.3431 3.34315 10 5 10H5.5ZM9.52513 4.52513C10.1815 3.86875 11.0717 3.5 12 3.5C12.9283 3.5 13.8185 3.86875 14.4749 4.52513C15.1313 5.1815 15.5 6.07174 15.5 7V10H8.5V7C8.5 6.07174 8.86875 5.1815 9.52513 4.52513Z"
                                fill="#494b74"
                              />{" "}
                            </g>
                          </svg>
                        </span>
                      </a>
                    </Link>
                  </div>   
                  <div className="menu-item">
                    <a
                      target="_blank"
                      className="menu-link fs-4 apiDocument"
                      href="https://cloud.microhost.com/api-docs/"
                    >
                      <span className="menu-icon">
                        {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                        <span className="svg-icon svg-icon-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              opacity="0.3"
                              d="M19.0687 17.9688H11.0687C10.4687 17.9688 10.0687 18.3687 10.0687 18.9688V19.9688C10.0687 20.5687 10.4687 20.9688 11.0687 20.9688H19.0687C19.6687 20.9688 20.0687 20.5687 20.0687 19.9688V18.9688C20.0687 18.3687 19.6687 17.9688 19.0687 17.9688Z"
                              fill="black"
                            />
                            <path
                              d="M4.06875 17.9688C3.86875 17.9688 3.66874 17.8688 3.46874 17.7688C2.96874 17.4688 2.86875 16.8688 3.16875 16.3688L6.76874 10.9688L3.16875 5.56876C2.86875 5.06876 2.96874 4.46873 3.46874 4.16873C3.96874 3.86873 4.56875 3.96878 4.86875 4.46878L8.86875 10.4688C9.06875 10.7688 9.06875 11.2688 8.86875 11.5688L4.86875 17.5688C4.66875 17.7688 4.36875 17.9688 4.06875 17.9688Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}
                      </span>
                      <span className="menu-title">API Documents</span>
                      <span className="apiDocTab"></span>
                    </a>
                  </div>
              <div className="menu-item">
                <div className="menu-content">
                  <div className="separator mx-1 my-4" />
                </div>
              </div>
              <div className="menu-item text-center">
                <a href="#" className="menu-link fs-6 text-center">
                  <span className="menu-title text-center">
                    ©{new Date().getFullYear()} Copyright{" "}
                    {appInfo != null ? appInfo.brand : ""}
                  </span>
                </a>
              </div>
              </div>
              {/*end::Menu*/}
            </div>
            {/*end::Aside Menu*/}
          </div>
          {/*end::Aside menu*/}
        {/*begin::Footer*/}
        <div
          className="aside-footer flex-column-auto pt-5 pb-7 px-5"
          id="kt_aside_footer"
        >
          <a
            target="_blank"
            href={appInfo != null ? appInfo.docs_url : ""}
            className="btn btn-custom btn-primary w-100 docs"
            data-bs-toggle="tooltip"
            data-bs-trigger="hover"
            data-bs-dismiss-="click"
          >
            <span className="btn-label">Docs </span>
            {/*begin::Svg Icon | path: icons/duotune/general/gen005.svg*/}
            <span className="svg-icon btn-icon svg-icon-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  opacity="0.3"
                  d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM12.5 18C12.5 17.4 12.6 17.5 12 17.5H8.5C7.9 17.5 8 17.4 8 18C8 18.6 7.9 18.5 8.5 18.5L12 18C12.6 18 12.5 18.6 12.5 18ZM16.5 13C16.5 12.4 16.6 12.5 16 12.5H8.5C7.9 12.5 8 12.4 8 13C8 13.6 7.9 13.5 8.5 13.5H15.5C16.1 13.5 16.5 13.6 16.5 13ZM12.5 8C12.5 7.4 12.6 7.5 12 7.5H8C7.4 7.5 7.5 7.4 7.5 8C7.5 8.6 7.4 8.5 8 8.5H12C12.6 8.5 12.5 8.6 12.5 8Z"
                  fill="black"
                />
                <rect x={7} y={17} width={6} height={2} rx={1} fill="black" />
                <rect x={7} y={12} width={10} height={2} rx={1} fill="black" />
                <rect x={7} y={7} width={6} height={2} rx={1} fill="black" />
                <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black" />
              </svg>
            </span>
            {/*end::Svg Icon*/}
          </a>
        </div>
        {/*end::Footer*/}
      </div>
      {/*end::Aside*/}
    </>
  );
}
