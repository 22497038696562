// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .symbol.symbol-45px>img {
    width: 45px;
    height: 30px;
    padding: 2px;background: #ebebeb75;
    } */
     
    /* .symbol.symbol-45px {
    width: 45px;
    height: 30px;
    padding: 2px;
    background: #ebebeb75;
    } */
 `, "",{"version":3,"sources":["webpack://./src/App/MicroApp/Body/Products/monitoringAlerts/tabs/manageClouds/cloud.css"],"names":[],"mappings":"AAAA;;;;OAIO;;IAEH;;;;;OAKG","sourcesContent":["/* .symbol.symbol-45px>img {\n    width: 45px;\n    height: 30px;\n    padding: 2px;background: #ebebeb75;\n    } */\n     \n    /* .symbol.symbol-45px {\n    width: 45px;\n    height: 30px;\n    padding: 2px;\n    background: #ebebeb75;\n    } */\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
