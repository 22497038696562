import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';

function PrivateNetwork(props) {
  const {
    privateData,
    deletePrivateIp
  } = props;

  useEffect(() => {
    if (privateData === undefined)
      throw new Error("Missing prop: privateData");
    if (deletePrivateIp === undefined)
      throw new Error("Missing prop: deletePrivateIp");
  }, [
    privateData,
    deletePrivateIp
  ]);
  console.log(props,"sxskjxlsk");

  return (
    <>
      <div className="card">
        {/*begin::Card header*/}
        <div className="card-header">
          {/*begin::Heading*/}
          <div className="card-title">
            <h3>IPv4 Private IP Address </h3>
          </div>
          {/*end::Heading*/}
        </div>
        {/*end::Card header*/}
        {/*begin::Card body*/}
        <div className="card-body p-0">
          {/*begin::Table wrapper*/}
          <div className="table-responsive">
            {/*begin::Table*/}
            <table
              className="table table-striped table-flush align-middle table-row-bordered table-row-solid gy-4"
              id="kt_security_license_usage_table"
            >
              {/*begin::Thead*/}
              <thead className="border-gray-200 fs-5 fw-bold bg-secondary">
                <tr>
                  <th className="w-150px ps-9">IP Address</th>
                  <th className="w-150px text-center">Gateway</th>
                  <th className="w-150px text-center">Netmask</th>
                  <th className="w-150px ps-9" />
                </tr>
              </thead>
              {/*end::Thead*/}
              {/*begin::Tbody*/}
              <tbody
                id="private-IP-list"
                className="fw-6 fw-bold text-gray-600"
              >
                {privateData != null && privateData?.length > 0 ? (<> {privateData.map((value, index) => (
                  <>
                    {Object.keys(value).length === 0 ? (<>
                    </>) : (<>
                      {value?.noip == '0' ? (<></>) : (
                        <tr>
                          <td className="ps-9 text-dark">{value.ip_address}</td>
                          {value.vpc_id ? (<>
                            <td className="text-center text-dark">
                              <span className="badge badge-light-success fs-7 fw-bolder">VPC </span>
                              <Link to={"/vpc/" + value.vpc_id} ><a >{value.network}</a></Link>
                            </td>
                          </>) : (<td className="text-center text-dark">{value?.gateway}</td>)}

                          <td className="text-center text-dark">{value?.netmask}</td>
                          <td className="text-center">
                            <a id={"delete-private-ip-" + value?.ip_address?.replace(/\./g, "-")} onClick={(e) => { e.preventDefault(); deletePrivateIp(value.ip_address) }} className="btn btn-danger btn-sm sphide">Delete</a>
                          </td>
                        </tr>
                      )}
                    </>)}
                  </>
                ))}</>) : (<>
                  <tr>
                    <td colspan="12" >
                      <div className="d-flex flex-column flex-center">
                        <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                      </div>
                    </td>
                  </tr></>)}
              </tbody>
              {/*end::Tbody*/}
            </table>
            {/*end::Table*/}
          </div>
          {/*end::Table wrapper*/}
        </div>
        {/*end::Card body*/}
      </div>
    </>
  )
}

PrivateNetwork.propTypes = {

  privateData: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.oneOf([null]),
  ]).isRequired,

  deletePrivateIp: PropTypes.func.isRequired,
}

export default PrivateNetwork

