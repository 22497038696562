import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import Swal from 'sweetalert2';
import ReactPaginate from "react-paginate";
import copy from "copy-to-clipboard";
import UsePost from "../../../../../../CustomHooks/usePost";
import UseApi from "../../../../../../CustomHooks/useApi";
import TopBarHeaderLine from "../../../../../../CustomHooks/TopBarHeaderLine";
import ConnectionDetails from "./ConnectionDetails";
import { tr } from "date-fns/locale";
import ToastMessage from "../../../../utils/toast-message/ToastMessage";
const PgBouncer = (props) => {
  const [pageStatus, setPageStatus] = useState(false)
  const [databaseInfo, setDatabaseInfo] = useState(null);
  const [objectForUpdate, setObjectForUpdate] = useState({})
  const [activePage, setActivePage] = useState(0);
  const [isUpdate, setIsUpdate] = useState(false)
  const [pageCount, setPageCount] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [itemOffset, setItemOffset] = useState(0);
  const [databaseUserData, setDatabaseUserData] = useState(null);
  const [currentDatabaseInfo, setCurrentDatabaseInfo] = useState(null);
  const [dummyDatabaseUserData, setDummyDatabaseUserData] = useState(null);
  var query = window.location.href;
  var vars = query.split("/");
  var id = vars[4];
  let { clusterid, cloudid } = useParams();

  const openDrawer = (type = 'open') => {
    console.log(type);
    if (type == 'update') {
      setIsUpdate(true);
    }
    var drawerElement = document.querySelector("#connection-modal");
    var drawer_overlay = document.querySelector("#drawer_overlay_connection");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
        drawerElement.style.width = "100%";
      } else {
        drawerElement.style.width = "60%";
      }
      drawer_overlay.classList.toggle("d-none");
    }
  };

  const addConnectionPool = () => {
    openDrawer()
    setObjectForUpdate({})
    setIsUpdate(false)
  }

  const getDatabaseUsers = async () => {
    if (!props?.data) return;

    const { users } = props.data;
    if (users.length === 0) {
      setDatabaseUserData([]);
      setDummyDatabaseUserData([]);
    } else {
      setDatabaseUserData(users);
      setDummyDatabaseUserData(props.currentDatabaseInfo.pools || []);
      setCurrentDatabaseInfo(props.currentDatabaseInfo);
      setDatabaseInfo(props.data);
    }
  };

  useEffect(() => {
    getDatabaseUsers();
  }, [props, isUpdate]);


  function ProductInfo({ productdata }) {
    return (
      <>
        {productdata != null && Object.keys(productdata).length != 0 ? (productdata && productdata.map((value, index) => (
          <tr>
            <td className="text-start ps-9">
              <span className="fs-6 fw-bold">
                {value.name?.charAt(0)?.toUpperCase() + value.name?.slice(1)?.toLowerCase()}
              </span>
            </td>
            <td></td>
            <td className="text-center">
              <span className="fs-6 fw-bold">
                {value.db?.charAt(0)?.toUpperCase() + value.db?.slice(1)?.toLowerCase()}
              </span>
            </td>
            <td className="text-center">
              <span className="fs-6 fw-bold">
                {value.user?.charAt(0)?.toUpperCase() + value.user?.slice(1)?.toLowerCase()}
              </span>
            </td>
            <td className="text-center">
              <span className="fs-6 fw-bold">
                {value.mode?.charAt(0)?.toUpperCase() + value.mode?.slice(1)?.toLowerCase()}
              </span>
            </td>
            <td className="text-center">
              <span className="fs-6 fw-bold">
                {value.size?.charAt(0)?.toUpperCase() + value.size?.slice(1)?.toLowerCase()}
              </span>
            </td>
            <td className="text-center">
              <span data-bs-toggle="modal" data-bs-target="#kt_modal_1" className="fs-7 fw-bold btn btn-sm btn-light-primary sphide border border-primary">
                <div onClick={() => findObjectForUpdate(value.id)}>
                  Connection Details&nbsp;
                  {/*begin::Svg Icon | path: assets/media/icons/duotune/arrows/arr095.svg*/}
                  <span className="svg-icon svg-icon-muted svg-icon-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        opacity="0.3"
                        d="M4.7 17.3V7.7C4.7 6.59543 5.59543 5.7 6.7 5.7H9.8C10.2694 5.7 10.65 5.31944 10.65 4.85C10.65 4.38056 10.2694 4 9.8 4H5C3.89543 4 3 4.89543 3 6V19C3 20.1046 3.89543 21 5 21H18C19.1046 21 20 20.1046 20 19V14.2C20 13.7306 19.6194 13.35 19.15 13.35C18.6806 13.35 18.3 13.7306 18.3 14.2V17.3C18.3 18.4046 17.4046 19.3 16.3 19.3H6.7C5.59543 19.3 4.7 18.4046 4.7 17.3Z"
                        fill="black"
                      />
                      <rect
                        x="21.9497"
                        y="3.46448"
                        width={13}
                        height={2}
                        rx={1}
                        transform="rotate(135 21.9497 3.46448)"
                        fill="black"
                      />
                      <path
                        d="M19.8284 4.97161L19.8284 9.93937C19.8284 10.5252 20.3033 11 20.8891 11C21.4749 11 21.9497 10.5252 21.9497 9.93937L21.9497 3.05029C21.9497 2.498 21.502 2.05028 20.9497 2.05028L14.0607 2.05027C13.4749 2.05027 13 2.52514 13 3.11094C13 3.69673 13.4749 4.17161 14.0607 4.17161L19.0284 4.17161C19.4702 4.17161 19.8284 4.52978 19.8284 4.97161Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                </div>

              </span>
            </td>
            {currentDatabaseInfo?.ref_type != "dbaas_node" ? (<>

              <td className="text-center" >
                <div className="card-toolbar">
                  <button
                    type="button"
                    onClick={() => {
                      openDrawer('update');
                      findObjectForUpdate(value.id)
                    }}
                    className="btn  btn-icon btn-color-primary btn-active-light-primary"
                  >
                    <span className="svg-icon svg-icon-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          opacity="0.3"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z"
                          fill="black"
                        />
                        <path
                          d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z"
                          fill="black"
                        />
                        <path
                          d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z"
                          fill="black"
                        />
                      </svg>

                    </span>
                  </button>
                  <button
                    type="button"
                    className="btn  btn-icon btn-color-danger btn-active-light-danger"
                    id={`delete-pgb-btn-${value.id}`}
                    onClick={() => handleDeletePgBouncer(value.id, index)}
                  >
                    <span className="svg-icon svg-icon-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                          fill="black"
                        />
                        <path
                          opacity="0.5"
                          d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                          fill="black"
                        />
                        <path
                          opacity="0.5"
                          d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                  </button>
                </div>
              </td>
            </>) : ('')}
          </tr>
        ))) : (<>
          <tr>
            <td colspan="12" className="dataTables_empty" id="loader">
              {/* <div className="loading-spinner"></div> */}
            </td>
            <td colspan="12" className="d-none" id="no_record" valign="top">
              {/* <div className="d-flex flex-column flex-center">
                <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
              </div> */}
              <div className="mt-20 mb-20 text-center">
                <span className="fs-6 text-gray-800 fw-bold">No Record Found</span>
              </div>
            </td>
          </tr>
        </>)}

      </>
    )
  }

  const handleDeletePgBouncer = async (pgbId, index) => {
    toast.dismiss();
    const loadingToast = toast.loading("Please wait...");
    const submitBtn = document.querySelector(`#delete-pgb-btn-${pgbId}`);

    if (!submitBtn) return;

    const preContent = submitBtn.innerHTML;
    setButtonLoadingState(submitBtn);

    try {
      const response = await UseApi("delete", `databases/${clusterid}/${cloudid}/pool?id=${pgbId}`, "");
      ToastMessage(response, loadingToast);
      if (response.status === "success") {
        props.updateDatabase();
        // Update your state if needed, e.g., removing the item from the list
      }
    } catch (error) {
      console.error("Error deleting PgBouncer:", error);
      toast.update(loadingToast, {
        render: "An error occurred",
        type: "error",
        isLoading: false,
      });
    } finally {
      resetButtonState(submitBtn, preContent);
    }
  };

  const handleUpdatePgBouncer = async (pgbId, index) => {
    toast.dismiss();
    const loadingToast = toast.loading("Please wait...");
    const submitBtn = document.querySelector(`#delete-pgb-btn-${pgbId}`);

    if (!submitBtn) return;

    const preContent = submitBtn.innerHTML;
    setButtonLoadingState(submitBtn);

    try {
      const response = await UseApi("put", `databases/${clusterid}/${cloudid}/pool?id=${pgbId}`, "pgbouncerData");
      ToastMessage(response, loadingToast);
      if (response.status === "success") {
        props.updateDatabase();
      }
    } catch (error) {
      console.error("Error updating PgBouncer:", error);
      toast.update(loadingToast, {
        render: "An error occurred",
        type: "error",
        isLoading: false,
      });
    } finally {
      resetButtonState(submitBtn, preContent);
    }
  };

  const handleAddConnectionPool = async () => {
    const submitBtn = document.querySelector("#create-pgbouncer-btn");

    if (!submitBtn) return;

    setPageStatus(true);
    const preContent = submitBtn.innerHTML;
    setButtonLoadingState(submitBtn);
    const loadingToast = toast.loading("Please wait...");

    try {
      const response = await UsePost('post', `databases/${clusterid}/${cloudid}/pool`, 'pgbouncerData');
      ToastMessage(response, loadingToast);
      if (response.status === "success") {
        setIsUpdate(true);
        if (props) {
          openDrawer();
          props.updateDatabase();
        }
      }
    } catch (error) {
      console.error("Error adding connection pool:", error);
      toast.update(loadingToast, {
        render: "An error occurred",
        type: "error",
        isLoading: false,
      });
    } finally {
      setPageStatus(false);
      resetButtonState(submitBtn, preContent);
    }
  };

  const findObjectForUpdate = (targetId) => {
    const foundObject = currentDatabaseInfo.pools.find(obj => obj.id === targetId);
    setObjectForUpdate(foundObject || {});
    setIsUpdate(true);
  };

  const handleSearch = (text) => {
    const escapedText = text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(escapedText, "gi");

    const filteredData = currentDatabaseInfo?.pools?.filter(row => {
      return (
        row.db?.toString().toLowerCase().includes(text.toLowerCase()) ||
        row.name?.toString().toLowerCase().includes(text.toLowerCase()) ||
        row.mode?.toString().toLowerCase().includes(text.toLowerCase())
      );
    });

    setDummyDatabaseUserData(filteredData);
    setPageCount(Math.ceil(filteredData.length / perPage));
  };

  const setButtonLoadingState = (button) => {
    button.classList.add("disabled");
    button.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
  };

  const resetButtonState = (button, preContent) => {
    button.classList.remove("disabled");
    button.innerHTML = preContent;
  };

  function PaginatedItems({ itemsPerPage, ProductData }) {

    const [itemOffset, setItemOffset] = useState(0);
    const [activePage, setActivePage] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    useEffect(() => {
      setPageCount(Math.ceil(ProductData.length / itemsPerPage));
    }, [ProductData.length, itemsPerPage]);

    const endOffset = Math.min(itemOffset + itemsPerPage, ProductData.length);

    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % ProductData.length;
      setItemOffset(newOffset);
      setActivePage(event.selected);
    };

    return (
      <>
        <ProductInfo productdata={ProductData.slice(itemOffset, endOffset)} />
        <td colspan="12">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            initialPage={activePage}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </td>
      </>
    );
  }

  console.log(isUpdate);

  if (databaseInfo != null && currentDatabaseInfo != null) {
    if (databaseInfo.length != 0 && currentDatabaseInfo.length != 0) {
      return (
        <>
          <div className="card">
            <div className="card-header">
              <div className="card-title">
                <h3>Connection Pool</h3>
              </div>
              <div className="card-toolbar">
                <div
                  className="position-relative my-1"
                  style={{ marginRight: 20 }}
                >
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    data-kt-table-widget-4="search"
                    id="searchClient"
                    // value={searchTerm}
                    onChange={e => handleSearch(e.target.value)}
                    placeholder="Search"
                  />
                </div>

                <div style={{ marginRight: 20 }}>
                  <div className="dataTables_length">
                    <label className="w-125px">
                      <select
                        onChange={e => setPerPage(e.target.value)}
                        //data-control="select2"
                        className="form-select form-select-sm form-select-solid"
                        defaultValue={5}
                        id="perPage"

                      >
                        <option value={5} selected="">
                          5 Records
                        </option>
                        <option value={10}>10 Records</option>
                        <option value={25}>25 Records</option>
                        <option value={50}>50 Records</option>
                        <option value={100}>100 Records</option>
                      </select>
                    </label>
                  </div>
                </div>
                {currentDatabaseInfo?.ref_type != "dbaas_node" ? (<>
                  <a onClick={addConnectionPool}
                    className="btn btn-sm btn-primary my-1 sphide">
                    + Add Connection Pool
                  </a>
                </>) : ('')}
              </div>
            </div>
            <div className="pb-5">
              <div
                id="kt_table_users_wrapper"
                className="dataTables_wrapper dt-bootstrap4 no-footer"
              >
                <div className="table-responsive">
                  <table
                    className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
                    id="kt_security_license_usage_table"
                  >
                    <thead className="bg-light-dark">
                      <tr className="text-start fw-bolder fs-7 text-uppercase gs-0">
                      <th className="w-150px text-start ps-9">Pool Name</th>
                        <th className="w-50px ps-9"></th>
                        <th className="w-150px text-center">Database</th>
                        <th className="w-150px text-center">Username</th>
                        <th className="w-150px text-center">Pool Mode</th>
                        <th className="w-150px text-center">Size</th>
                        <th className="text-center pe-5 w-150px sphide">Connection Details</th>
                        {currentDatabaseInfo?.ref_type != "dbaas_node" ? (<>
                          <th className="w-150px ps-9" />
                        </>) : ('')}
                      </tr>
                    </thead>
                    <tbody id="public-IP-list" className="fw-6 fw-bold text-gray-600">
                      {dummyDatabaseUserData != null ? (<>
                        {dummyDatabaseUserData.length == 0 ? (<>
                          <tr>
                            <td colspan="12" valign="top">
                              {/* <div className="d-flex flex-column flex-center">
                              <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                            </div> */}
                              <div className="mt-20 mb-20 text-center">
                                <span className="fs-6 text-gray-800 fw-bold">No Record Found</span>
                              </div>
                            </td>
                          </tr>
                        </>) : (<>
                          <PaginatedItems itemsPerPage={perPage} ProductData={dummyDatabaseUserData} />
                        </>)}
                      </>) : ('')}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* Connection details modal start */}
            <div className="modal fade" tabIndex={-1} id="kt_modal_1">
              <div className="modal-dialog modal-dialog-centered mw-1000px">
                <div className="modal-content">
                  <div className="modal-body">
                    {currentDatabaseInfo != null && databaseInfo != null ? (<>
                      <ConnectionDetails currentDatabaseInfo={objectForUpdate} data={databaseInfo} />
                    </>) : ('')}
                  </div>
                </div>
              </div>
            </div>
            {/* Connection details modal end */}

            {/* Connection create modal start */}
            <div id="drawer_overlay_connection" onClick={openDrawer} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>

            <div
              id="connection-modal"
              className="bg-body shadow drawer drawer-end"
              // data-kt-drawer="true"
              // data-kt-drawer-name="chat"
              // data-kt-drawer-activate="true"
              // data-kt-drawer-direction="end"
              data-kt-drawer-close="#close_ssh"
              // data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
              style={{ important: true, zIndex: 110 }}
            >
              {/*begin::Messenger*/}
              <div
                className="card rounded-0 w-100 border-0"
                id="kt_drawer_chat_messenger"
                // style={{ height: 'max-content' }}
              >
                <div
                  className="card-header pe-5"
                  id="kt_drawer_chat_messenger_header"
                >
                  {/*begin::Title*/}
                  <div className="card-title">
                    {/*begin::User*/}
                    <div className="d-flex justify-content-center flex-column me-3">
                      <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                        {isUpdate ? ('Update Connection Pool') : ('Create Connection Pool')}
                      </a>
                      {/* <span className="fs-7 text-gray-500">Connection pooling uses PgBouncer to manage backend processes. Each pool shares its assigned backend processes with up to <strong>5000</strong> client connections</span> */}
                    </div>
                    {/*end::User*/}
                  </div>
                  {/*end::Title*/}
                  {/*begin::Card toolbar*/}
                  <div className="card-toolbar">
                    {/*begin::Close*/}
                    <div
                      onClick={openDrawer}
                      className="btn btn-sm btn-icon btn-active-light-primary"
                      id="close_ssh"
                    >
                      {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                      <span className="svg-icon svg-icon-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <rect
                            opacity="0.5"
                            x={6}
                            y="17.3137"
                            width={16}
                            height={2}
                            rx={1}
                            transform="rotate(-45 6 17.3137)"
                            fill="black"
                          />
                          <rect
                            x="7.41422"
                            y={6}
                            width={16}
                            height={2}
                            rx={1}
                            transform="rotate(45 7.41422 6)"
                            fill="black"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}
                    </div>
                    {/*end::Close*/}
                  </div>
                  {/*end::Card toolbar*/}
                </div>
                {/*begin::Card body*/}
                <div className="" id="">
                  {/*begin::Form*/}
                  <form
                    name="importkey"
                    id="pgbouncerData"
                    className="form"
                  >
                    {/*begin::Modal body*/}
                    <div className="modal-body">
                      {/* <div className="d-flex justify-content-center flex-column me-3 mb-10 border-bottom pb-5">
                        <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                          Create Connection Pool
                        </a>
                        <span className="fs-7 text-gray-500">Connection pooling uses PgBouncer to manage backend processes. Each pool shares its assigned backend processes with up to <strong>5000</strong> client connections</span>
                      </div> */}
                      {/*begin::Scroll*/}
                      <div
                        className="scroll-y me-n7 pe-7"
                        id="kt_modal_create_api_key_scroll"
                        data-kt-scroll="true"
                        data-kt-scroll-activate="{default: false, lg: true}"
                        data-kt-scroll-max-height="auto"
                        data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                        data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                        data-kt-scroll-offset="300px"
                      >
                        <div className="row">
                          <div className="col-4">
                            {/*begin::Input group*/}
                            <div className="mb-5 fv-row">
                              {/*begin::Label*/}
                              <label className="fs-5 fw-bold mb-2">
                                {" "}
                                Pool Name
                                <span className="fs-7 fw-bold text-gray-600"></span>
                              </label>
                              {/*end::Label*/}
                              {/*begin::Input*/}
                              <input
                                type="text"
                                readOnly={isUpdate ? true : false}
                                placeholder="Enter the pool name"
                                name="name" className="form-control"
                                defaultValue={objectForUpdate.name}
                                onKeyPress={(e) => {
                                  const allowedKeys = ['_', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'];
                                  const isAlphaNumeric = /[a-zA-Z0-9]/;

                                  // Allow alphabets, numbers, underscores, backspace, arrow keys, and delete
                                  if (allowedKeys.includes(e.key) || isAlphaNumeric.test(e.key)) {
                                    return;
                                  }

                                  // Prevent any other characters
                                  e.preventDefault();
                                }}
                                onChange={(e) => {
                                  // Allow only alphanumeric characters and underscores
                                  e.target.value = e.target.value.replace(/[^a-zA-Z0-9_]/g, '');
                                }}
                              />
                              {/*end::Input*/}
                            </div>
                            {/*end::Input group*/}
                          </div>
                          <div className="col-4">
                            {/*begin::Input group*/}
                            <div className="mb-10 fv-row fv-plugins-icon-container">
                              {/*begin::Label*/}
                              <label className="form-label mb-3">Database User</label>
                              {/*end::Label*/}
                              {/*begin::Input*/}
                              <select
                                className="form-select"
                                name="user"
                                id="database_users_list"
                                defaultValue={objectForUpdate.user}
                              // onChange={(e) => selectDatabaseUserName(e.target.value)}
                              >

                                {databaseInfo.users && databaseInfo.users.length > 0 && databaseInfo.users.map((key, index) => {

                                  return (
                                    <option value={key.db_user}>{key.db_user}</option>
                                  )
                                })}
                                {/* <option value="connection_string">User2</option> */}
                              </select>
                              {/*end::Input*/}
                            </div>
                            {/*end::Input group*/}
                          </div>
                          <div className="col-4">
                            {/*begin::Input group*/}
                            <div className="mb-10 fv-row fv-plugins-icon-container">
                              {/*begin::Label*/}
                              <label className="form-label mb-3">Databse Name</label>
                              {/*end::Label*/}
                              {/*begin::Input*/}
                              <select
                                className="form-select"
                                name="db"
                                id="database_list"
                                defaultValue={objectForUpdate.db}
                              // onChange={(e) => setDatabaseName(e.target.value)}
                              >
                                <option value="all">All</option>
                                {/* <option value={currentDatabaseInfo?.connection_strings?.database}>{currentDatabaseInfo?.connection_strings?.database}</option> */}
                                {databaseInfo.databases && databaseInfo.databases.length > 0 && databaseInfo.databases.map((key, index) => {

                                  return (
                                    <option value={key.db_name}>{key.db_name}</option>
                                  )
                                })}
                              </select>
                              {/*end::Input*/}
                            </div>
                            {/*end::Input group*/}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            {/*begin::Input group*/}
                            <div className="mb-5 fv-row">
                              {/*begin::Label*/}
                              <label className="fs-5 fw-bold mb-2">
                                {" "}
                                Pool Mode<br />
                                <span className="fs-7 fw-bold text-gray-600">The pool mode determines how PgBouncer manages the backend server connections in its pool.</span></label>
                              {/*end::Label*/}
                              {/*begin::Input*/}
                              <div className="fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                                {/*begin:Option*/}
                                <label className="d-flex flex-stack mb-5 cursor-pointer">
                                  {/*begin:Label*/}
                                  <span className="d-flex align-items-center me-2">
                                    {/*begin:Icon*/}
                                    <span className="symbol symbol-50px me-6">
                                      <span className="symbol-label bg-light-primary">
                                        {/*begin::Svg Icon | path: icons/duotune/maps/map004.svg*/}
                                        <span className="svg-icon svg-icon-1 svg-icon-primary">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                          >
                                            <path
                                              opacity="0.3"
                                              d="M18.4 5.59998C21.9 9.09998 21.9 14.8 18.4 18.3C14.9 21.8 9.2 21.8 5.7 18.3L18.4 5.59998Z"
                                              fill="black"
                                            />
                                            <path
                                              d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM19.9 11H13V8.8999C14.9 8.6999 16.7 8.00005 18.1 6.80005C19.1 8.00005 19.7 9.4 19.9 11ZM11 19.8999C9.7 19.6999 8.39999 19.2 7.39999 18.5C8.49999 17.7 9.7 17.2001 11 17.1001V19.8999ZM5.89999 6.90002C7.39999 8.10002 9.2 8.8 11 9V11.1001H4.10001C4.30001 9.4001 4.89999 8.00002 5.89999 6.90002ZM7.39999 5.5C8.49999 4.7 9.7 4.19998 11 4.09998V7C9.7 6.8 8.39999 6.3 7.39999 5.5ZM13 17.1001C14.3 17.3001 15.6 17.8 16.6 18.5C15.5 19.3 14.3 19.7999 13 19.8999V17.1001ZM13 4.09998C14.3 4.29998 15.6 4.8 16.6 5.5C15.5 6.3 14.3 6.80002 13 6.90002V4.09998ZM4.10001 13H11V15.1001C9.1 15.3001 7.29999 16 5.89999 17.2C4.89999 16 4.30001 14.6 4.10001 13ZM18.1 17.1001C16.6 15.9001 14.8 15.2 13 15V12.8999H19.9C19.7 14.5999 19.1 16.0001 18.1 17.1001Z"
                                              fill="black"
                                            />
                                          </svg>
                                        </span>
                                        {/*end::Svg Icon*/}
                                      </span>
                                    </span>
                                    {/*end:Icon*/}
                                    {/*begin:Info*/}
                                    <span className="d-flex flex-column">
                                      <span className="fw-bolder fs-6">Transaction</span>
                                      <span className="fs-7 text-muted">
                                        A backend process is assigned to the client for the duration of a single transaction. At the end of that transaction, the backend process is released back into the pool, though the client can remain connected in an idle state.
                                      </span>
                                    </span>
                                    {/*end:Info*/}
                                  </span>
                                  {/*end:Label*/}
                                  {/*begin:Input*/}
                                  <span className="form-check form-check-custom form-check-solid">
                                    {isUpdate ?
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="mode"
                                        defaultValue="transaction"
                                        defaultChecked={objectForUpdate.mode === "transaction"}
                                      // defaultChecked={objectForUpdate.mode == "transaction" ? true : false}
                                      />
                                      :
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="mode"
                                        defaultValue="transaction"
                                      // checked={isUpdate && objectForUpdate.mode == "transaction"}
                                      // defaultChecked={objectForUpdate.mode == "transaction" ? true : false}
                                      />
                                    }
                                  </span>
                                  {/*end:Input*/}
                                </label>
                                {/*end::Option*/}
                                {/*begin:Option*/}
                                <label className="d-flex flex-stack mb-5 cursor-pointer">
                                  {/*begin:Label*/}
                                  <span className="d-flex align-items-center me-2">
                                    {/*begin:Icon*/}
                                    <span className="symbol symbol-50px me-6">
                                      <span className="symbol-label bg-light-danger">
                                        {/*begin::Svg Icon | path: icons/duotune/general/gen025.svg*/}
                                        <span className="svg-icon svg-icon-1 svg-icon-danger">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                          >
                                            <rect x={2} y={2} width={9} height={9} rx={2} fill="black" />
                                            <rect
                                              opacity="0.3"
                                              x={13}
                                              y={2}
                                              width={9}
                                              height={9}
                                              rx={2}
                                              fill="black"
                                            />
                                            <rect
                                              opacity="0.3"
                                              x={13}
                                              y={13}
                                              width={9}
                                              height={9}
                                              rx={2}
                                              fill="black"
                                            />
                                            <rect
                                              opacity="0.3"
                                              x={2}
                                              y={13}
                                              width={9}
                                              height={9}
                                              rx={2}
                                              fill="black"
                                            />
                                          </svg>
                                        </span>
                                        {/*end::Svg Icon*/}
                                      </span>
                                    </span>
                                    {/*end:Icon*/}
                                    {/*begin:Info*/}
                                    <span className="d-flex flex-column">
                                      <span className="fw-bolder fs-6">Session</span>
                                      <span className="fs-7 text-muted">
                                        A backend server connection is assigned to the client until the client disconnects
                                      </span>
                                    </span>
                                    {/*end:Info*/}
                                  </span>
                                  {/*end:Label*/}
                                  {/*begin:Input*/}
                                  <span className="form-check form-check-custom form-check-solid">
                                    {isUpdate ?
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="mode"
                                        defaultValue="session"
                                        defaultChecked={objectForUpdate.mode === "session"}
                                      // defaultChecked={/objectForUpdate.mode == "session" ? true : false}
                                      />
                                      :
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="mode"
                                        defaultValue="session"
                                      // checked={isUpdate&&objectForUpdate.mode == "session"}
                                      // defaultChecked={/objectForUpdate.mode == "session" ? true : false}
                                      />
                                    }
                                  </span>
                                  {/*end:Input*/}
                                </label>
                                {/*end::Option*/}
                                {/*begin:Option*/}
                                <label className="d-flex flex-stack cursor-pointer">
                                  {/*begin:Label*/}
                                  <span className="d-flex align-items-center me-2">
                                    {/*begin:Icon*/}
                                    <span className="symbol symbol-50px me-6">
                                      <span className="symbol-label bg-light-success">
                                        {/*begin::Svg Icon | path: icons/duotune/general/gen013.svg*/}
                                        <span className="svg-icon svg-icon-1 svg-icon-success">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                          >
                                            <path
                                              opacity="0.3"
                                              d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z"
                                              fill="black"
                                            />
                                            <path
                                              d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z"
                                              fill="black"
                                            />
                                          </svg>
                                        </span>
                                        {/*end::Svg Icon*/}
                                      </span>
                                    </span>
                                    {/*end:Icon*/}
                                    {/*begin:Info*/}
                                    <span className="d-flex flex-column">
                                      <span className="fw-bolder fs-6">Statement</span>
                                      <span className="fs-7 text-muted">
                                        A backend server connection is assigned to the client for the duration of a single statement, refusing any transactions with multiple statements.
                                      </span>
                                    </span>
                                    {/*end:Info*/}
                                  </span>
                                  {/*end:Label*/}
                                  {/*begin:Input*/}
                                  <span className="form-check form-check-custom form-check-solid">
                                    {isUpdate ?
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="mode"
                                        defaultValue="statement"
                                        defaultChecked={objectForUpdate.mode === "statement"}
                                      // defaultChecked={objectForUpdate.mode == "statement" ? true : false}
                                      />
                                      :
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="mode"
                                        defaultValue="statement"
                                      // checked={objectForUpdate.mode == "statement"}
                                      // defaultChecked={objectForUpdate.mode == "statement" ? true : false}
                                      />
                                    }
                                  </span>
                                  {/*end:Input*/}
                                </label>
                                {/*end::Option*/}
                                <div className="fv-plugins-message-container invalid-feedback" />
                              </div>

                              {/*end::Input*/}
                            </div>
                            {/*end::Input group*/}
                          </div>
                        </div>
                        {/*begin::Input group*/}
                        <div className="mb-5 fv-row">
                          {/*begin::Label*/}
                          <label className="fs-5 fw-bold mb-2">
                            {" "}
                            Pool Size<br />
                            <span className="fs-7 fw-bold text-gray-600">The size of your pool determines how many backend server connections are allocated</span>
                          </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <input
                            type="number"
                            className="form-control"
                            name="size"
                            onKeyPress={(e) => {
                              if (e.key === '-' || e.key === '+' || (e.key === '0' && e.target.selectionStart === 0)) {
                                e.preventDefault();
                              }
                            }
                            }
                            onChange={(e) => {
                              const value = parseInt(e.target.value, 10);
                              if (value < 1) {
                                e.target.value = 1;
                              }
                            }}
                            defaultValue={objectForUpdate.size} />
                          <span className="fs-7 fw-bold text-gray-600 pt-3">Available backend server connections: <strong>{databaseInfo?.available_nodepools} / {databaseInfo?.total_nodepools}</strong> </span>
                          {/*end::Input*/}
                        </div>
                        {/*end::Input group*/}

                        <div className="mb-5 fv-row">
                          {/*begin::Button*/}
                          {/* {isUpdate ? */}
                            <a
                              // onClick={attachFw}
                              className={`btn btn-primary w-100 ${isUpdate ? "" : 'd-none'}`}
                              id="create-pgbouncer-btn"
                              onClick={() => handleUpdatePgBouncer(objectForUpdate.id)}
                            >
                              <span className="indicator-label">
                                Update Connection Pool
                              </span>
                            </a>
                            {/* : */}
                            <a
                              // onClick={attachFw}
                              className={`btn btn-primary w-100 ${isUpdate ? 'd-none' : ''}`}
                              id="create-pgbouncer-btn"
                              onClick={() => handleAddConnectionPool()}
                            >
                              <span className="indicator-label">
                                Create Connection Pool
                              </span>
                            </a>
                          {/* } */}
                          {/*end::Button*/}
                        </div>
                      </div>
                      {/*end::Scroll*/}
                    </div>
                    {/*end::Modal body*/}
                  </form>
                  {/*end::Form*/}
                </div>
                {/*end::Card body*/}
              </div>
              {/*end::Messenger*/}
            </div>
            {/* Connection create modal end */}
            {pageStatus &&
              <TopBarHeaderLine />}
          </div>
        </>
      );
    }
  }
}

export default PgBouncer; 
