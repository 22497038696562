import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import swal from "sweetalert";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import UsePost from "../../../../CustomHooks/usePost";
import Swal from "sweetalert2";
import CustomDoc from "../../../../CustomHooks/CustomDoc";
import ProductsUrl from "../../Docs/Docsdata";
import Subnet from "./manage-vpc/subnets/Subnet";
import RouteTable from "./manage-vpc/routetable/RouteTable";
import copy from "copy-to-clipboard";
import VpcInternetGateway from "./manage-vpc/vpcInternetgateway/VpcInternetGateway";
// import BastionList from "./bastion/BastionList";

export default function ManageVpc() {
  const [pageStatus, setPageStatus] = useState(false)
  const navigate = useNavigate();
  var query = window.location.href;
  var vars = query.split("/");
  console.log(id,);
  var id = vars[4];
  console.log(id);

  const [vpcData, setVpcData] = useState(null);
  const dataFetchedRef = useRef(false);

  const getVPC = async () => {
    if (id) {
      const data = await UseApi('get', 'vpc/' + id, '');
      if (Object.keys(data).length <= 2) {
        swal({
          title: "VPC",
          text: "No VPC record found with id: " + id + "!",
          icon: "warning",
          button: "OK",
        }).then(() => {
          navigate("/vpc");
        })
        return;
      } else {
        setVpcData(data);
        //   toast.update(dt,{render: "Your VPC info listed below", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        // });  

      }
    }
  }
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getVPC();
  }, []);

  const copyVPCName = () => {
    var eleText = document.querySelector("#file_url");
    var ele = document.querySelector("#file_url_copy_btn");
    // var copiedEle = document.querySelector('#file_url_copied_btn')

    if (ele && eleText) {
      const copyText = eleText.innerText;
      const originalContent = ele.innerHTML;

      navigator.clipboard.writeText(copyText).then(() => {
        ele.innerHTML = "Copied";

        setTimeout(() => {
          ele.innerHTML = originalContent;
        }, 1000);
      }).catch(err => {
        console.error('Failed to copy text: ', err);
      });
    }
  };

  const handleClick = (event) => {
    if (event.target && event.target.id === 'file_url_copy_btn') {
      copyVPCName();
      event.stopPropagation(); // Prevent the event from bubbling up
    }
  };

  document.removeEventListener('click', handleClick);
  document.addEventListener('click', handleClick);

  const destroyvpc = async (name) => {
    toast.dismiss();
    const { value: isDelete } = await Swal.fire({
      title: 'Destroy VPC',
      html: `
      <div className="d-flex align-items-center flex-wrap"> 
      <div id="kt_clipboard_4" className="me-5 fw-bold text-gray-600">
        <p class="fs-5">Enter the VPC name to Destroy:</p>

        <span class="fw-bold" id="file_url" >${name}</span><span id="file_url_copy_btn" style="cursor:pointer; width: 70px; display: inline-block;" class="text-primary"> ( copy ) </span> 
        <span id="file_url_copied_btn" style="cursor:pointer; width: 70px; display: inline-block;" class="text-primary d-none"> Copied </span> 
      </div>  
    </div>
   `,
      input: 'text',
      inputPlaceholder: 'Vpc name',
      showCancelButton: true,
      confirmButtonText: 'Destroy',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'delete-button bg-danger', // Add your custom class here
      },
    });
    if (isDelete?.trim() == name) {
      setPageStatus(true)
      const dt = toast.loading("Vpc deleting...");
      const dnsp = await UseApi('DELETE', 'vpc/' + id + '', '');
      if (dnsp.status == 'error') {
        setTimeout(() => {
          setPageStatus(false)
        }, 1500)
        toast.update(dt, {
          render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
      } else {
        toast.update(dt, {
          render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        setTimeout(() => {
          setPageStatus(false)
          navigate('/vpc');
        }, 1500)
      }
    } else {
      swal({
        title: "Invalid VPC Name",
        text: "Please confirm the valid VPC!",
        icon: "warning",
        button: "OK",
      });
    }
  }

  const ActionHostname = async (action) => {
    var showHost = document.querySelector("#showHost");
    var updateHost = document.querySelector("#update_host");
    if (showHost) {
      if (updateHost) {
        if (action == "showHost") {
          showHost.classList.add("d-none");
          updateHost.classList.remove("d-none");
        }
        if (action == "cancelHost") {
          showHost.classList.remove("d-none");
          updateHost.classList.add("d-none");
        }
        if (action == "updateHost") {

          toast.dismiss();
          const dt = toast.loading("updating Host name, Please wait...");
          const responseData = await UsePost('put', 'vpc/' + id, 'updateHostname');
          if (responseData.status === 'error') {
            toast.update(dt, {
              render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
          } else if (responseData.status === 'success') {
            toast.update(dt, {
              render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
            showHost.classList.remove("d-none");
            updateHost.classList.add("d-none");
            // const data = await UseApi('get', 'cloud/' + id, '');
            // dispatch(props.updateCloudStatus(data.cloud[0]));
            getVPC()
            // getCloud();
            // navRedirect();
          }
        }
      }
    }
  }

  const copyToClipboard = (target, targetBy) => {
    var ip_address = document.querySelector("#ip_address")
    var btn_id = document.querySelector("#" + targetBy);
    var target_id = document.querySelector("#" + target);
    if (btn_id && !ip_address.classList.contains('disabled')) {
      var copyText = btn_id.innerHTML;
      var copyEle = target_id.innerHTML;
      copy(copyEle);
      ip_address.classList.add("disabled")
      // svgIconElement.classList.add('d-none')
      btn_id.innerHTML = `<svg
      xmlns="http://www.w3.org/2000/svg"
      width=24px
      height=24px
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9.89557 13.4982L7.79487 11.2651C7.26967 10.7068 6.38251 10.7068 5.85731 11.2651C5.37559 11.7772 5.37559 12.5757 5.85731 13.0878L9.74989 17.2257C10.1448 17.6455 10.8118 17.6455 11.2066 17.2257L18.1427 9.85252C18.6244 9.34044 18.6244 8.54191 18.1427 8.02984C17.6175 7.47154 16.7303 7.47154 16.2051 8.02984L11.061 13.4982C10.7451 13.834 10.2115 13.834 9.89557 13.4982Z"
        fill="black"
      />
    </svg>`;
      setTimeout(() => {
        btn_id.innerHTML = copyText;
        ip_address.classList.remove("disabled")
        // svgIconElement.classList.remove('d-none')
      }, 2000);
    }

  }

  return (
    <>
      <div className="col-xl-12">
        {vpcData != null ? (<>
          <div className="">
            <div className="" id="appresult">
              <div className="card mb-6 mb-xl-9">
                <div className="card-body pt-9 pb-0">
                  {/*begin::Details*/}
                  <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                    {/*begin: Pic*/}
                    <div className="mb-4 mt-1">
                      <div
                        id="flag-icon"
                        className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative"
                      >
                        {/*begin::Image input*/}
                        <div
                          className="image-input image-input-empty"
                          data-kt-image-input="true"
                        >
                          <div className="d-flex flex-center flex-shrink-0 bg-light rounded w-55px h-55px w-lg-55px h-lg-55px me-7 mb-4">
                            <span class="svg-icon svg-icon-2"><i className="fonticon-share" style={{ fontSize: '20px' }}></i></span>
                          </div>
                          {" "}
                          {/* <img
                            src={"/img/flag_" + vpcData.dclocation.dccc + ".png"}
                            className="image-input-wrapper w-100 h-45px"
                          />{" "} */}
                        </div>
                        {/*end::Image input*/}
                      </div>
                    </div>
                    {/*end::Pic*/}
                    {/*begin::Info*/}
                    <div className="flex-grow-1">
                      {/*begin::Title*/}
                      <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                        {/*begin::User*/}
                        <div className="d-flex flex-column">
                          {/*begin::Name*/}
                          <div className="d-flex align-items-center mb-1 d-none" id="update_host">
                            <form id="updateHostname" name="updateHostname">
                              {/*begin::Title*/}
                              <div className="d-flex">
                                <input
                                  type="text"
                                  className="form-control form-control-sm  me-3 flex-grow-1"
                                  name="name"
                                  defaultValue={vpcData.name}
                                />
                                <input
                                  type={"button"}
                                  onClick={() => ActionHostname('updateHost')}
                                  className="btn btn-primary btn-sm fw-bolder flex-shrink-0 me-2"
                                  value={"save"}
                                />
                                <input
                                  type={"button"}
                                  onClick={() => ActionHostname('cancelHost')}
                                  className="btn btn-danger btn-sm fw-bolder flex-shrink-0"
                                  value={"cancel"}
                                />

                              </div>
                            </form>
                            {/*end::Title*/}
                          </div>
                          <div className="d-flex align-items-center mb-1" id="showHost">
                            <a
                              onClick={() => ActionHostname('showHost')}
                              style={{ cursor: "pointer" }}
                              className="text-gray-900 text-hover-primary fs-2 fw-bold me-1"
                              id="head-name"
                            >
                              {vpcData.name}
                            </a>
                          </div>
                          {/*end::Name*/}
                          {/*begin::Info*/}
                          <span className="text-muted fw-bold text-muted d-block fs-7 pt-1">
                            <a className="d-flex align-items-center text-gray-400 me-3 mb-2">
                              <span className="svg-icon svg-icon-4 me-1 mb-1">
                                <img
                                  src={"/img/flag_" + vpcData.dclocation.dccc + ".png"}
                                  style={{ width: 16 }} />
                                {/* <i class="fonticon-location fs-1x"></i> */}
                              </span>
                              {vpcData.dclocation.location}
                              {/* <a className="d-flex align-items-center text-gray-400 me-3 mb-2"> */}
                              <i class="bi bi-geo fs-1x me-1 ms-1"></i>
                              VPC Range-{(parseInt(vpcData.total) - parseInt(vpcData.available))}/254
                            </a>
                            {/* </a> */}
                          </span>

                          <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2 d-none">
                            <div
                              className="d-flex flex-wrap fw-bold mb-4 fs-5 text-gray-400"
                              id="location"
                            >
                              {vpcData.dclocation.location}
                            </div>
                          </div>
                          {/*end::Info*/}
                        </div>
                        <div className="d-flex">
                          {/*begin::Stat*/}
                          <div className="d-flex align-items-center bg-light-success rounded p-5 mb-7 d-none">
                            {/*begin::Icon*/}
                            <div className="d-flex align-items-center">
                              {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
                              <span className="svg-icon svg-icon-3 svg-icon-success me-2">
                                {/*begin::Svg Icon | path: assets/media/icons/duotune/abstract/abs027.svg*/}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    opacity="0.3"
                                    d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z"
                                    fill="black"
                                  />
                                  <path
                                    d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z"
                                    fill="black"
                                  />
                                </svg>
                                {/*end::Svg Icon*/}
                              </span>
                              {/*end::Svg Icon*/}
                              <div className="fs-6 fw-bold" id="vpc-id">
                                {vpcData.network}/{vpcData.size}-
                                <span className="fw-bold fs-6 text-gray-400">VPC</span>
                              </div>
                            </div>
                            {/*end::Lable*/}
                          </div>

                          <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 d-none">
                            {/*begin::Number*/}
                            <div className="d-flex align-items-center">
                              {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
                              <span className="svg-icon svg-icon-3 svg-icon-success me-2">
                                {/*begin::Svg Icon | path: assets/media/icons/duotune/abstract/abs027.svg*/}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    opacity="0.3"
                                    d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z"
                                    fill="black"
                                  />
                                  <path
                                    d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z"
                                    fill="black"
                                  />
                                </svg>
                                {/*end::Svg Icon*/}
                              </span>
                              {/*end::Svg Icon*/}
                              <div className="fs-6 fw-bold" id="vpc-id">
                                {vpcData.network}/{vpcData.size}-
                                <span className="fw-bold fs-6 text-gray-400">VPC</span>
                              </div>
                            </div>
                            {/*end::Number*/}
                            {/*begin::Label*/}

                            {/*end::Label*/}
                          </div>
                          {/*end::Stat*/}
                          {/*begin::Stat*/}
                          <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 d-none">
                            {/*begin::Number*/}
                            <div className="d-flex align-items-center">
                              {/*begin::Svg Icon | path: icons/duotune/arrows/arr065.svg*/}
                              <span className="svg-icon svg-icon-3 svg-icon-primary me-2">
                                <svg height="200px" width="200px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.234 20.234" fill="#009ef7">
                                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                  <g id="SVGRepo_iconCarrier"> <g>
                                    <path style={{ fill: '#009ef7' }} d="M6.776,4.72h1.549v6.827H6.776V4.72z M11.751,4.669c-0.942,0-1.61,0.061-2.087,0.143v6.735h1.53 V9.106c0.143,0.02,0.324,0.031,0.527,0.031c0.911,0,1.691-0.224,2.218-0.721c0.405-0.386,0.628-0.952,0.628-1.621 c0-0.668-0.295-1.234-0.729-1.579C13.382,4.851,12.702,4.669,11.751,4.669z M11.709,7.95c-0.222,0-0.385-0.01-0.516-0.041V5.895 c0.111-0.03,0.324-0.061,0.639-0.061c0.769,0,1.205,0.375,1.205,1.002C13.037,7.535,12.53,7.95,11.709,7.95z M10.117,0 C5.523,0,1.8,3.723,1.8,8.316s8.317,11.918,8.317,11.918s8.317-7.324,8.317-11.917S14.711,0,10.117,0z M10.138,13.373 c-3.05,0-5.522-2.473-5.522-5.524c0-3.05,2.473-5.522,5.522-5.522c3.051,0,5.522,2.473,5.522,5.522 C15.66,10.899,13.188,13.373,10.138,13.373z">
                                    </path> </g> </g></svg>
                              </span>
                              {/*end::Svg Icon*/}
                              <div className="fs-6 fw-bold" id="vpc-address">
                                {vpcData.available}-
                                <span className="fw-bold fs-6 text-gray-400">Available Addresses</span>
                              </div>
                            </div>
                            {/*end::Number*/}
                            {/*begin::Label*/}
                            <div className="fw-bold fs-6 text-gray-400 d-none">
                              Available Addresses
                            </div>
                            {/*end::Label*/}
                          </div>
                          {/*end::Stat*/}
                          <div className="d-flex align-items-center bg-light-info rounded p-5 d-none">
                            {/*begin::Icon*/}
                            <span className="svg-icon svg-icon-3 svg-icon-secondary me-2">
                              <svg height="200px" width="200px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.234 20.234" fill="#009ef7">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier"> <g>
                                  <path style={{ fill: '#009ef7' }} d="M6.776,4.72h1.549v6.827H6.776V4.72z M11.751,4.669c-0.942,0-1.61,0.061-2.087,0.143v6.735h1.53 V9.106c0.143,0.02,0.324,0.031,0.527,0.031c0.911,0,1.691-0.224,2.218-0.721c0.405-0.386,0.628-0.952,0.628-1.621 c0-0.668-0.295-1.234-0.729-1.579C13.382,4.851,12.702,4.669,11.751,4.669z M11.709,7.95c-0.222,0-0.385-0.01-0.516-0.041V5.895 c0.111-0.03,0.324-0.061,0.639-0.061c0.769,0,1.205,0.375,1.205,1.002C13.037,7.535,12.53,7.95,11.709,7.95z M10.117,0 C5.523,0,1.8,3.723,1.8,8.316s8.317,11.918,8.317,11.918s8.317-7.324,8.317-11.917S14.711,0,10.117,0z M10.138,13.373 c-3.05,0-5.522-2.473-5.522-5.524c0-3.05,2.473-5.522,5.522-5.522c3.051,0,5.522,2.473,5.522,5.522 C15.66,10.899,13.188,13.373,10.138,13.373z">
                                  </path> </g> </g></svg>
                            </span>
                            {/*end::Svg Icon*/}
                            <div className="fs-6 fw-bold" id="vpc-address">
                              {vpcData.available}-
                              <span className="fw-bold fs-6 text-gray-400">Available Addresses</span>
                            </div>
                          </div>

                        </div>
                        <div className="d-flex mb-4">
                          <a className="btn btn-sm btn-light me-2" id="ip_address" onClick={(e) => copyToClipboard('ip_copy', 'dbname_btn')}>
                            <span className="indicator-label">IP: &nbsp;
                              <span id="ip_copy" className="fw-bold">
                                {vpcData.network}/{vpcData.size}
                              </span>
                              <span className="svg-icon svg-icon-primary svg-icon-2 ms-2" id="dbname_btn">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    opacity="0.5"
                                    d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                                    fill="black"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                                    fill="black"
                                  />
                                </svg>
                              </span>
                            </span>
                          </a>
                          <div className="text-gray-800 fs-1 me-3  d-none">Status: </div>
                          <a className=" btn btn-sm btn-success" style={{ cursor: "default" }}>
                            Running
                          </a>
                        </div>
                      </div>
                      {/*end::User*/}
                    </div>
                    {/*end::Title*/}
                    {/*begin::Stats*/}
                    <div className="d-flex flex-wrap flex-stack d-none">
                      {/*begin::Wrapper*/}
                      <div className="d-flex flex-column flex-grow-1 pe-8">
                        {/*begin::Stats*/}
                        <div className="d-flex flex-wrap">
                          {/*begin::Stat*/}
                          <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                            {/*begin::Number*/}
                            <div className="d-flex align-items-center">
                              {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
                              <span className="svg-icon svg-icon-3 svg-icon-success me-2">
                                {/*begin::Svg Icon | path: assets/media/icons/duotune/abstract/abs027.svg*/}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    opacity="0.3"
                                    d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z"
                                    fill="black"
                                  />
                                  <path
                                    d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z"
                                    fill="black"
                                  />
                                </svg>
                                {/*end::Svg Icon*/}
                              </span>
                              {/*end::Svg Icon*/}
                              <div className="fs-2 fw-bold" id="vpc-id">
                                {vpcData.network}
                              </div>
                            </div>
                            {/*end::Number*/}
                            {/*begin::Label*/}
                            <div className="fw-bold fs-6 text-gray-400">VPC</div>
                            {/*end::Label*/}
                          </div>
                          {/*end::Stat*/}
                          {/*begin::Stat*/}
                          <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                            {/*begin::Number*/}
                            <div className="d-flex align-items-center">
                              {/*begin::Svg Icon | path: icons/duotune/arrows/arr065.svg*/}
                              <span className="svg-icon svg-icon-3 svg-icon-primary me-2">
                                <svg height="200px" width="200px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.234 20.234" fill="#009ef7">
                                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                  <g id="SVGRepo_iconCarrier"> <g>
                                    <path style={{ fill: '#009ef7' }} d="M6.776,4.72h1.549v6.827H6.776V4.72z M11.751,4.669c-0.942,0-1.61,0.061-2.087,0.143v6.735h1.53 V9.106c0.143,0.02,0.324,0.031,0.527,0.031c0.911,0,1.691-0.224,2.218-0.721c0.405-0.386,0.628-0.952,0.628-1.621 c0-0.668-0.295-1.234-0.729-1.579C13.382,4.851,12.702,4.669,11.751,4.669z M11.709,7.95c-0.222,0-0.385-0.01-0.516-0.041V5.895 c0.111-0.03,0.324-0.061,0.639-0.061c0.769,0,1.205,0.375,1.205,1.002C13.037,7.535,12.53,7.95,11.709,7.95z M10.117,0 C5.523,0,1.8,3.723,1.8,8.316s8.317,11.918,8.317,11.918s8.317-7.324,8.317-11.917S14.711,0,10.117,0z M10.138,13.373 c-3.05,0-5.522-2.473-5.522-5.524c0-3.05,2.473-5.522,5.522-5.522c3.051,0,5.522,2.473,5.522,5.522 C15.66,10.899,13.188,13.373,10.138,13.373z">
                                    </path> </g> </g></svg>
                              </span>
                              {/*end::Svg Icon*/}
                              <div className="fs-2 fw-bold" id="vpc-address">
                                {vpcData.available}
                              </div>
                            </div>
                            {/*end::Number*/}
                            {/*begin::Label*/}
                            <div className="fw-bold fs-6 text-gray-400">
                              Available Addresses
                            </div>
                            {/*end::Label*/}
                          </div>
                          {/*end::Stat*/}
                        </div>
                        {/*end::Stats*/}
                      </div>
                      {/*end::Wrapper*/}
                      {/*begin::Actions*/}
                      <div className="d-flex mb-4 ">
                        <div className="text-gray-800 fs-1 me-3">Status: </div>
                        <a className=" btn btn-sm btn-success" style={{ cursor: "default" }}>
                          Running
                        </a>
                      </div>
                      {/*end::Actions*/}
                      {/*begin::Progress*/}
                      {/*end::Progress*/}
                    </div>
                    {/*end::Info*/}
                  </div>
                  {/*end::Details*/}
                  <div className="separator" />
                  {/*begin::Nav*/}
                  <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                    {/*begin::Nav item*/}
                    <li className="nav-item">
                      <a
                        className="nav-link text-active-primary py-5 me-6 active"
                        data-bs-toggle="tab"
                        href="#resources"
                      >
                        Resources
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link text-active-primary py-5 me-6"
                        data-bs-toggle="tab"
                        href="#subnet"
                      >
                        Subnet
                      </a>
                    </li>
                    <li className="nav-item d-none">
                      <a
                        className="nav-link text-active-primary py-5 me-6"
                        data-bs-toggle="tab"
                        href="#route_table"
                      >
                        Route Table
                      </a>
                    </li>
                    <li className="nav-item d-none">
                      <a
                        className="nav-link text-active-primary py-5 me-6"
                        data-bs-toggle="tab"
                        href="#bastion_server"
                      >
                        Bastion
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link text-active-primary py-5 me-6"
                        data-bs-toggle="tab"
                        href="#internet_gateway"
                      >
                        Internet Gateway
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" data-bs-toggle="tab" href="#destroy_vpc">
                        Destroy VPC
                      </a>
                    </li>
                    {/*end::Nav item*/}
                  </ul>
                  {/*end::Nav*/}
                </div>
              </div>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="resources"
                  role="tabpanel"
                >
                  {/*begin::License usage*/}
                  <div className="card">
                    {/*begin::Card header*/}
                    <div className="card-header">
                      {/*begin::Heading*/}
                      <div className="card-title">
                        <h3>VPC Resources</h3>
                      </div>
                      <div className="card-toolbar ">
                        <div className="d-flex justify-content-end py-6" style={{ marginRight: 1 }}>
                          <CustomDoc url={ProductsUrl[0]?.vpc + "/manage-vpc/"} />
                        </div>
                      </div>
                      {/*end::Heading*/}
                    </div>
                    {/*end::Card header*/}
                    {/*begin::Card body*/}
                    <div className="">
                      {/*begin::Table wrapper*/}
                      <div className="table-responsive">
                        {/*begin::Table*/}
                        <table
                          className="table table-striped table-flush align-middle table-row-bordered table-row-solid gy-4"
                          id="kt_security_license_usage_table"
                        >
                          {/*begin::Table head*/}
                          <thead className="border-gray-200 fs-5 fw-bold bg-secondary">
                            <tr className="fw-bold ">
                              <th className="min-w-250px ps-9">Name</th>
                              <th className="min-w-200px text-center">IP</th>
                              <th className="min-w-50px" />
                              <th className="min-w-10px" />
                            </tr>
                          </thead>
                          {/*end::Table head*/}
                          <tbody id="vpc-list">
                            {vpcData != null ? (vpcData.resources && vpcData.resources.map((value, index) => (
                              <tr>
                                {" "}
                                <td className="fw-bold ps-9">
                                  {" "}
                                  <div className="mt-1 fw-bold symbol symbol-45px me-2">
                                    <Link to={"/cloud/" + value.id}>
                                      <a  >
                                        {value.name}{" "}
                                      </a>
                                    </Link>
                                    {" "}
                                    ({value.type}){" "}
                                  </div>{" "}
                                </td>{" "}
                                <td className="fw-bold text-center">
                                  {" "}
                                  <div className="mt-1 symbol symbol-45px me-2">
                                    {" "}
                                    {value.ip}
                                    {" "}
                                  </div>{" "}
                                </td>{" "}
                                <td className="text-end me-2">
                                  {" "}
                                  <Link to={"/cloud/" + value.id}>
                                    <a

                                      className="btn btn-success btn-sm"
                                    >
                                      View Cloud{" "}
                                    </a>
                                  </Link>
                                </td>{" "}
                                {/* <td className="text-end"> </td>{" "} */}
                              </tr>
                            ))) : ('')}

                          </tbody>
                        </table>
                      </div>
                      {/*end::Table wrapper*/}
                    </div>
                    {/*end::Card body*/}
                  </div>
                  {/*end::License usage*/}
                </div>
                {/* <div className="tab-pane fade" id="bastion_server" role="tabpanel">
                  <BastionList/>
                </div> */}
                <div className="tab-pane fade" id="destroy_vpc" role="tabpanel">
                  {/*begin::Deactivate Account*/}
                  <div className="card">
                    {/*begin::Card header*/}
                    <div
                      className="card-header border-0 cursor-pointer"
                      role="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#kt_account_deactivate"
                      aria-expanded="true"
                      aria-controls="kt_account_deactivate"
                    >
                      <div className="card-title m-0">
                        <h3 className="fw-bold m-0">Destroy VPC</h3>
                      </div>
                      <div className="card-toolbar">
                        <div className="d-flex justify-content-end py-6" style={{ marginRight: 1 }}>
                          <CustomDoc url={ProductsUrl[0]?.vpc + "/manage-vpc/#destroy"} />
                        </div>
                      </div>
                    </div>
                    {/*end::Card header*/}
                    {/*begin::Content*/}
                    <div id="kt_account_settings_deactivate" className="collapse show">
                      {/*begin::Form*/}
                      <form id="destroyform" className="form">
                        {/*begin::Card body*/}
                        <div className="card-body border-top p-9">
                          {/*begin::Notice*/}
                          <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
                            {/*begin::Icon*/}
                            {/*begin::Svg Icon | path: icons/duotune/general/gen044.svg*/}
                            <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <rect
                                  opacity="0.3"
                                  x={2}
                                  y={2}
                                  width={20}
                                  height={20}
                                  rx={10}
                                  fill="black"
                                />
                                <rect
                                  x={11}
                                  y={14}
                                  width={7}
                                  height={2}
                                  rx={1}
                                  transform="rotate(-90 11 14)"
                                  fill="black"
                                />
                                <rect
                                  x={11}
                                  y={17}
                                  width={2}
                                  height={2}
                                  rx={1}
                                  transform="rotate(-90 11 17)"
                                  fill="black"
                                />
                              </svg>
                            </span>
                            {/*end::Svg Icon*/}
                            {/*end::Icon*/}
                            {/*begin::Wrapper*/}
                            <div className="d-flex flex-stack flex-grow-1">
                              {/*begin::Content*/}
                              <div className="fw-bold">
                                <h4 className="text-gray-900 fw-bold">
                                  This will destroy your VPC.
                                </h4>
                                <div className="fs-6 text-gray-700">
                                  Note: This option will delete your VPC from the server
                                  for permanently and this not be able to undo.
                                </div>
                              </div>
                              {/*end::Content*/}
                            </div>
                            {/*end::Wrapper*/}
                          </div>
                          {/*end::Notice*/}
                          {/*begin::Form input row*/}
                          <div className="mb-10 d-none">
                            <label htmlFor="size" className="required form-label">
                              Your VPC will be permanently destroyed. You will lose the
                              provisioned Network addresses.
                            </label>
                          </div>
                          {/*end::Form input row*/}
                        </div>
                        {/*end::Card body*/}
                        {/*begin::Card footer*/}
                        <div
                          className="card-footer d-flex justify-content-end py-6 px-9"
                          id="destroy_btn"
                        >
                          <button
                            id="destroy-btn"
                            onClick={() => destroyvpc(vpcData.name)}
                            // onclick="call_api('DELETE','vpc/undefined/destroy','destroy','refdestroy','#vpc')"
                            type="button"
                            className="btn btn-danger btn-sm fw-bold"
                          >
                            Destroy VPC
                          </button>
                        </div>
                        {/*end::Card footer*/}
                      </form>
                      {/*end::Form*/}
                    </div>
                    {/*end::Content*/}
                  </div>
                  {/*end::Deactivate Account*/}
                </div>
                <div className="tab-pane fade" id="subnet" role="tabpanel">
                  {vpcData != null ? (<>
                    <Subnet data={vpcData.subnets} getVPC={getVPC} />
                  </>) : ('')}
                </div>
                <div className="tab-pane fade" id="route_table" role="tabpanel">
                  <RouteTable />
                </div>
                <div className="tab-pane fade" id="internet_gateway" role="tabpanel">
                  <VpcInternetGateway getVPC={getVPC} />
                </div>
              </div>

              {pageStatus &&
                <TopBarHeaderLine />}
            </div>
          </div>
        </>) : (<><TopBarHeaderLine /></>)}
      </div>

    </>
  )
}