import localStorage from 'local-storage';
import React, { useEffect, useState } from 'react'

export default function StaticKubernetes(props) {
  // console.log(props);
  const [currency_prefix, setcurrency_prefix] = useState("");

  useEffect(() => {
    let userInfo = localStorage.get("user_info");
    if (userInfo) {
      // console.log(userInfo);
      setcurrency_prefix(userInfo.currencyprefix);
    }
    props.poolData.forEach((key) => {
      const total_plan_price = (parseFloat(key.totalCost) * parseInt(key.desiredCount)).toFixed(2);
      const show_select_plans = document.querySelector("#show_select_plans_" + key.pool_id);
      const node_select_plan = document.querySelector("#node_select_plan_" + key.pool_id);
      const select_kubernetes_plans = document.querySelector("#select_kubernetes_plans_" + key.pool_id);
      const order_total = document.querySelector(`#order_total_${key.pool_id}`);
      // console.log(show_select_plans, node_select_plan, select_kubernetes_plans, order_total);
      if (show_select_plans && node_select_plan && select_kubernetes_plans && order_total) {
        node_select_plan.value = key.nodeSize;
        order_total.innerHTML = `Rs.${total_plan_price}/mon`;
        if (key.nodeSize === 0) {
          show_select_plans.classList.add('d-none');
          select_kubernetes_plans.classList.remove('d-none');
        } else {
          show_select_plans.classList.remove('d-none');
          select_kubernetes_plans.classList.add('d-none');
        }
      }
    });
  }, [currency_prefix, props.poolData])
  // console.log(props.poolData);
  return (<>
    {props.poolData != null && (props.poolData && props.poolData.map((value, index) => (
      <>
        {props.index === index &&
          <>
            <tr>
              <td className='text-start ps-9 pe-9'>
                <div className="d-flex align-items-center">
                  <input
                    type="hidden"
                    id={"plan_price_" + value.pool_id}
                    value={value.totalCost}
                  />
                  <input
                    type="text"
                    className="form-control  "
                    name={"nodepools[" + index + "][label]"}
                    // name={"nodepools"+index+"label"}
                    id={"pool_name_" + value.pool_id}
                    onChange={() => props.nodePoolUpdate(value.pool_id)}
                    defaultValue={value.nodePoolValue}
                    // value={value.nodePoolValue}
                    placeholder="Pool name"
                  />
                </div>
              </td>
              <td className="text-center">
                <input
                  type="hidden"
                  id={"node_select_plan_" + value.pool_id}
                  name={"nodepools[" + index + "][size]"}
                  // name={"nodepools"+index+"size"}
                  onChange={() => props.nodePoolUpdate(value.pool_id)}
                />
                <div
                  id="select_node_section_0"
                  className="align-items-center"
                >
                  <div
                    onClick={() => props.openDrawer(value.pool_id)}
                    className="d-flex d-none"
                    id={"show_select_plans_" + value.pool_id}
                  >
                    <div className="col-lg-3">
                      <div className="position-relative ps-4 pe-3 py-2">
                        <a

                          className="mb-1 text-dark text-hover-primary fw-bolder"
                        >
                          RAM
                        </a>
                        <div
                          className="fs-7 text-muted fw-bolder"
                          id={"ram_" + value.pool_id}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div
                        className="position-relative ps-6 pe-3 py-2"
                        style={{
                          borderLeft: "3px solid #009ef7"
                        }}
                      >
                        <a

                          className="mb-1 text-dark text-hover-primary fw-bolder"
                        >
                          CPU
                        </a>
                        <div
                          className="fs-7 text-muted fw-bolder"
                          id={"cpu_" + value.pool_id}
                        />
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <div
                        className="position-relative ps-6 pe-3 py-2"
                        style={{
                          borderLeft: "3px solid #009ef7"
                        }}
                      >
                        <a

                          className="mb-1 text-dark text-hover-primary fw-bolder"
                        >
                          Bandwidth
                        </a>
                        <div
                          className="fs-7 text-muted fw-bolder"
                          id={"bandwidth_" + value.pool_id}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => props.openDrawer(value.pool_id)}
                    id={"select_kubernetes_plans_" + value.pool_id}
                    className="btn btn-sm btn-light align-items-center px-4 bg-light-dark sphide"
                    style={{ backgroundColor: "white" }}
                  >
                    {/*begin::Display range*/}
                    <div className="text-gray-600 fw-bolder">
                      Select Node Size +{/*end::Display range*/}
                      {/*begin::Svg Icon | path: icons/duotune/general/gen014.svg*/}
                      <span className="svg-icon svg-icon-1 ms-2 me-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            opacity="0.3"
                            d="M18.041 22.041C18.5932 22.041 19.041 21.5932 19.041 21.041C19.041 20.4887 18.5932 20.041 18.041 20.041C17.4887 20.041 17.041 20.4887 17.041 21.041C17.041 21.5932 17.4887 22.041 18.041 22.041Z"
                            fill="black"
                          />
                          <path
                            opacity="0.3"
                            d="M6.04095 22.041C6.59324 22.041 7.04095 21.5932 7.04095 21.041C7.04095 20.4887 6.59324 20.041 6.04095 20.041C5.48867 20.041 5.04095 20.4887 5.04095 21.041C5.04095 21.5932 5.48867 22.041 6.04095 22.041Z"
                            fill="black"
                          />
                          <path
                            opacity="0.3"
                            d="M7.04095 16.041L19.1409 15.1409C19.7409 15.1409 20.141 14.7409 20.341 14.1409L21.7409 8.34094C21.9409 7.64094 21.4409 7.04095 20.7409 7.04095H5.44095L7.04095 16.041Z"
                            fill="black"
                          />
                          <path
                            d="M19.041 20.041H5.04096C4.74096 20.041 4.34095 19.841 4.14095 19.541C3.94095 19.241 3.94095 18.841 4.14095 18.541L6.04096 14.841L4.14095 4.64095L2.54096 3.84096C2.04096 3.64096 1.84095 3.04097 2.14095 2.54097C2.34095 2.04097 2.94096 1.84095 3.44096 2.14095L5.44096 3.14095C5.74096 3.24095 5.94096 3.54096 5.94096 3.84096L7.94096 14.841C7.94096 15.041 7.94095 15.241 7.84095 15.441L6.54096 18.041H19.041C19.641 18.041 20.041 18.441 20.041 19.041C20.041 19.641 19.641 20.041 19.041 20.041Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}
                    </div>
                  </div>
                </div>
              </td>
              {/* <td className="p-0" style={{ textAlign: '-webkit-center !important' }}>
                <div className="align-items-center" style={{ textAlign: '-webkit-center' }}> */}
              <td className="text-center">
                <div className="d-flex align-items-center">
                  {/*begin::Dialer*/}
                  <div
                    className="input-group w-md-150px"
                    data-kt-dialer="true"
                  >
                    {/*begin::Decrease control*/}
                    <a
                      className="btn btn-icon btn-outline btn-outline-secondary disabled"
                      id={"desireddowncount_" + value.pool_id}
                      onClick={() => props.nodePoolCount(value.pool_id, 'desired_down')}
                    >
                      <i className="bi bi-dash fs-1" />
                    </a>
                    {/*end::Decrease control*/}
                    {/*begin::Input control*/}
                    <input
                      type="text"
                      className="form-control text-center"
                      readOnly={true}
                      id={"desiredcount_" + value.pool_id}
                      name={"nodepools[" + index + "][count]"}
                      value={value.nodeCount}
                      // name={"nodepools"+index+"count"}
                      defaultValue={1}
                      onChange={() => props.nodePoolUpdate(value.pool_id)}
                    />
                    {/*end::Input control*/}
                    {/*begin::Increase control*/}
                    <a
                      className="btn btn-icon btn-outline btn-outline-secondary disabled"
                      id={"desiredupcount_" + value.pool_id}
                      onClick={() => props.nodePoolCount(value.pool_id, 'desired_up')}
                    >
                      <i className="bi bi-plus fs-1" />
                    </a>
                    {/*end::Increase control*/}
                  </div>
                  {/*end::Dialer*/}
                </div>
              </td>
              {/* <td className={`p-2 ${props.showMax}`} style={{ textAlign: '-webkit-center !important' }}>
                <div className={`align-items-center ${props.showMax}`} style={{ textAlign: '-webkit-center' }}> */}
              <td className={`text-center ${props.showMax}`}>
                <div className="d-flex align-items-center">
                  {/*begin::Dialer*/}
                  <div
                    className="input-group w-md-150px"
                    data-kt-dialer="true"
                  >
                    {/*begin::Decrease control*/}
                    <a
                      className="btn btn-icon btn-outline btn-outline-secondary disabled"
                      id={"maxdowncount_" + value.pool_id}
                      onClick={() => props.nodePoolCount(value.pool_id, 'max_down')}
                    >
                      <i className="bi bi-dash fs-1" />
                    </a>
                    {/*end::Decrease control*/}
                    {/*begin::Input control*/}
                    <input
                      type="text"
                      className="form-control text-center"
                      readOnly={true}
                      id={"maxcount_" + value.pool_id}
                      name={"nodepools[" + index + "][maxCount]"}
                      value={value.nodeCount}
                      // name={"nodepools"+index+"count"}
                      defaultValue={1}
                      onChange={() => props.nodePoolUpdate(value.pool_id)}
                    />
                    {/*end::Input control*/}
                    {/*begin::Increase control*/}
                    <a
                      className="btn btn-icon btn-outline btn-outline-secondary"
                      id={"maxupcount_" + value.pool_id}
                      onClick={() => props.nodePoolCount(value.pool_id, 'max_up')}
                    >
                      <i className="bi bi-plus fs-1" />
                    </a>
                    {/*end::Increase control*/}
                  </div>
                  {/*end::Dialer*/}
                </div>
                {/* <span className='text-danger fs-8 d-none' id={`error_message_${value.pool_id}`}>Kindly adjust the maximum count before proceeding.</span> */}
              </td>
              <td className='text-center p-0'>
                <div className="align-items-center">
                  <div
                    className="fw-bold fs-3 text-primary"
                  >
                    <span className="nodePoolCost sphide ps-10" id={"order_total_" + value.pool_id}></span>
                  </div>
                </div>
              </td>
              {/* <td className="text-center">
                        <div
                          onClick={() => props.deleteFormField(value.pool_id)}
                          className="btn btn-icon  btn-sm d-none"
                        >
                          {/*begin::Svg Icon | path: icons/duotune/general/gen027.svg*/}
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td className='text-center'>
                <span className='text-danger fs-8 d-none' id={`error_message_${value.pool_id}`}>Kindly adjust the maximum count before proceeding.</span>
              </td>
              <td></td>
            </tr>

          </>}</>)))}
  </>
  )
}
