import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import copy from "copy-to-clipboard";
import TableLoader from "../../../Loaders/TableLoader";
import ChartOverview from "../../cloud/cloudFeatures/ChartOverview";
const Overview = (props) => {
  // CSS End
  const [perPage, setPerPage] = useState(5);
  const [cloudData, setCloudData] = useState(null);
  const [dummyCloudData, setDummyCloudData] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const loaderFunc = () => {
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if (loader && no_record) {
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");

      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
  }

  const getCloud = async () => {
    if (props && props.cloud_data) {
      setCloudData(props.cloud_data);
      setDummyCloudData(props.cloud_data)
    }
  };

  const [cloudid, setCloudid] = useState(null);
  const getCloudOverview = async (bid) => {
    setCloudid(null);
    var cloudBtn = document.querySelector("#cloud_overview_btn_" + bid);
    if (cloudBtn) {
      var cloudBtnHtml = cloudBtn.innerHTML;
      cloudBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      cloudBtn.classList.add("disabled");

      setCloudid(bid)
      cloudBtn.innerHTML = cloudBtnHtml;
      cloudBtn.classList.remove("disabled");
      openCloudOverviewDrawer();
    }
  };

  useEffect(() => {
    getCloud();
    // //console.log("cloud coming");
  }, []);

  const copyToClipboard = (id) => {

    var ele = document.querySelector("#ip_address_sec" + id);
    var eleText = document.querySelector("#ip_address" + id);
    if (ele && !ele.classList.contains('disabled')) {
      var copyText = ele.innerHTML;
      var copyEle = eleText.innerHTML;
      copy(copyEle);
      ele.innerHTML = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Copied&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";
      ele.classList.remove("badge-primary");
      ele.classList.add("badge-success");
      ele.classList.add('disabled')
      setTimeout(() => {
        ele.classList.add("badge-primary");
        ele.classList.remove("badge-success");
        ele.classList.remove('disabled')
        ele.innerHTML = copyText;
      }, 2000);
    }

  }
  const openCloudOverviewDrawer = () => {

    var drawerElement = document.querySelector("#cloud-overview-modal");
    var drawer_overlay = document.querySelector("#drawer_overlay_cloud_overview");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      drawerElement.style.width = "75%";
      drawer_overlay.classList.toggle("d-none");
    }
  };
  const closeCloudOverviewDrawer = () => {
    setCloudid(null);
    var drawerElement = document.querySelector("#cloud-overview-modal");
    var drawer_overlay = document.querySelector("#drawer_overlay_cloud_overview");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      drawerElement.style.width = "75%";
      drawer_overlay.classList.toggle("d-none");
    }
  };

  function ProductInfo({ productdata,endOffset }) {
    console.log(productdata);
    return (
      <>
        {productdata != null && Object.keys(productdata).length != 0 ? (productdata && 
        productdata.slice(itemOffset, endOffset)?.map((cloud, index) => (
          <tr>
            {" "}

            <td className="ps-6">
              <div className="d-flex align-items-center">

                <div className="d-flex justify-content-start flex-column">
                  <div style={{ cursor: "pointer" }} className="text-dark fw-bolder text-hover-primary fs-6">
                    {cloud.hostname}
                  </div>
                  {/* <span className="text-muted fw-bold text-muted d-block fs-7">
                    {cloud.created_at}
                  </span> */}
                </div>
              </div>


            </td>

            <td className="text-center">
              {" "}
              <div className="d-flex flex-column w-100 me-2">
                {" "}
                <div className=" flex-stack mb-2">
                  {" "}
                  <span className="w-100 btn btn-active-primary btn-light-primary btn-sm sphide me-2 fs-7 fw-bold"
                    htmlFor="IP Address"
                    id={"ip_address_sec" + index}
                    onClick={() => copyToClipboard(index)}
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    title="Click to copy IP Address"
                  >
                    <span id={"ip_address" + index}> {cloud.ip}</span>&nbsp;&nbsp;
                    {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen054.svg*/}
                    <span className="svg-icon svg-icon-light svg-icon-2 d-none">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          opacity="0.5"
                          d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                          fill="black"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
            </td>{" "}
            <td className="text-center">
              <span className="text-dark me-2 fs-7 fw-bold">{cloud.created_at}</span>
              </td>
            <td className="text-center">
              {/* <div className="d-flex justify-content-center flex-shrink-0"> */}
                {cloud.status == "Active" ? (<>
                  <a className="btn btn-success btn-success btn-sm" style={{cursor:'default'}}>{cloud.status}</a>
                </>) : (<>
                  <a className="btn btn-warning btn-warning btn-sm" style={{cursor:'default'}}>{cloud.status}</a>
                </>)}
              {/* </div> */}
            </td>{" "}
            <td className="text-center p-0 pe-6">
              {" "}
              <div className="d-flex justify-content-end flex-shrink-0">
                {" "}
                <a onClick={(e) => getCloudOverview(cloud.cloudid)}
                  id={"cloud_overview_btn_" + cloud.cloudid}
                  className="btn btn-primary btn-active-light-primary btn-sm sphide"
                >
                  Monitor ➔
                </a>{" "}
              </div>{" "}
            </td>{" "}
          </tr>
        ))) : (<>
          <tr>
            <td colspan="12" className="dataTables_empty" id="loader">
              {/* <TableLoader /> */}
              {/* <div className="loading-spinner"></div> */}
            </td>
            <td colspan="12" className="d-none" id="no_record" valign="top">
              <div className="d-flex flex-column flex-center">
                <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
              </div>
            </td>
          </tr>
        </>)}


      </>
    )
  }

  
  const handleSearch = (text) => {
    const endOffset = itemOffset + perPage;
    const data = cloudData.filter(row => {
      console.log(row);
      return (
        row.hostname && row.hostname.toString().toLowerCase().includes(text.toLowerCase())
      )
    });
    setDummyCloudData(data);;
    setPageCount(Math.ceil(data.length / perPage));
    // }
  }

  function PaginatedItems({ itemsPerPage, ProductData, search }) {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(ProductData.length / itemsPerPage));

    const handlePageClick = (event) => {
      setActivePage((old) => old = event.selected);
      const newOffset = event.selected * itemsPerPage % ProductData.length;
      ////console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
      setItemOffset(newOffset);
    };

    return (
      <>
        <ProductInfo productdata={ProductData.slice(itemOffset, endOffset)}/>
        <td colspan="12" className="ps-7">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            initialPage={activePage}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </td>
      </>
    );
  }
  return (
    <>
      <div className="col-xl-12">
        {/*begin::Tables Widget 9*/}
        <div className="card card-xl-stretch mb-5 mb-xl-12">
          {/*begin::Header*/}
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold fs-3 mb-1">
                Cloud Instances
                {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                <span className="svg-icon svg-icon-2 d-none">
                  <i className="las la-cloud" style={{ fontSize: 20 }} />
                </span>
                {/*end::Svg Icon*/}
              </span>
              <span className="text-muted mt-1 fw-bold fs-7" />
            </h3>
            <div
              className="card-toolbar"
            >

              <div
                className="position-relative my-1"
                style={{ marginRight: 20 }}
              >
                <input
                  type="text"
                  className="form-control form-control-sm"
                  data-kt-table-widget-4="search"
                  id="searchClient"
                  style={{ padding: '0.6rem 0.6rem' }}
                  onChange={e => handleSearch(e.target.value)}
                  placeholder="Search"
                />
              </div>

              <div style={{ marginRight: 20 }}>
                <div className="dataTables_length">
                  <label className="w-125px">
                    <select
                      onChange={e => setPerPage(e.target.value)}
                      //data-control="select2"
                      style={{ padding: '0.6rem 0.6rem' }}
                      className="form-select form-select-sm form-select-solid"
                      defaultValue={5}
                      id="perPage"

                    >
                      <option value={5} selected="">
                        5 Records
                      </option>
                      <option value={10}>10 Records</option>
                      <option value={25}>25 Records</option>
                      <option value={50}>50 Records</option>
                      <option value={100}>100 Records</option>
                    </select>
                  </label>
                </div>
              </div>

            </div>
          </div>
          {/*end::Header*/}
          {/*begin::Body*/}
          {/*begin::Table container*/}

          <div className="table-responsive">
            {/*begin::Table*/}
            <table
              id="clouddata"
              className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
            >
              {/*begin::Table head*/}
              <thead className="bg-secondary">
                <tr className="fw-bold ">
                  <th className="w-50 text-start ps-9">Name</th>
                  <th className="w-25 text-center">IPv4</th>
                  <th className="w-25 text-center">Created_at</th>
                  <th className="w-25 text-center" >Status</th>
                  <th className="w-25 text-end ps-10" ></th>
                </tr>
              </thead>
              {/*end::Table head*/}
              {dummyCloudData != null ? (<>
                {Object.keys(dummyCloudData).length != 0 ? (<>
                  {dummyCloudData.length > 0 ? (<>
                    <PaginatedItems itemsPerPage={perPage} ProductData={dummyCloudData}/>
                  </>) : (<>  </>)}
                </>) : (<>
                  <tr>
                    <td className="text-center" colspan={12}>
                      <span className="text-gray-800 fw-bold">No Instance Found</span>
                    </td>
                  </tr>
                </>)}
              </>) : (<>
                <tr>
                  <td colspan={12}>
                    <TableLoader />
                  </td>
                </tr>

              </>)}



            </table>
            {/*end::Table*/}
          </div>
          {/*end::Table container*/}
          {/*begin::Body*/}
        </div>
        {/*end::Tables Widget 9*/}
      </div>

      <div id="drawer_overlay_cloud_overview" onClick={closeCloudOverviewDrawer} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>

      <div
        id="cloud-overview-modal"
        className="bg-body shadow drawer drawer-end"
        data-kt-drawer="true"
        data-kt-drawer-name="chat"
        data-kt-drawer-activate="true"
        data-kt-drawer-direction="end"
        data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
        style={{ maxWidth: "70%", important: true }}
      >
        {/*begin::Messenger*/}
        <div
          className="card w-100 rounded-0 border-0"
          id="kt_drawer_chat_messenger"
        >
          {/*begin::Card body*/}
          <div className="" id="">
            {cloudid != null ? (<>
              <ChartOverview cloudid={cloudid} />
            </>) : (<><TableLoader /></>)}
          </div>

          {/*end::Card body*/}
        </div>

        {/*end::Messenger*/}
      </div>

    </>
  )

}

export default Overview;
