import React from "react";
import { useNavigate } from "react-router-dom/dist";
import UsePost from "../../../../CustomHooks/usePost";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from "react";
import { tr } from "date-fns/locale";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";

export default function DeployFirewall(props) {
  const [pageStatus, setPageStatus] = React.useState(false)
  toast.dismiss();
  let navigate = useNavigate();
  const deployFirewall = async () => {
    var submitBtn = document.querySelector("#firewall-btn");
    var firewallName = document.querySelector("#name");

    if (submitBtn) {
      setPageStatus(true)
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      const dt = toast.loading("Please wait...");
      if (firewallName) {
        if (firewallName.value == "") {
          submitBtn.classList.remove("disabled");
          submitBtn.innerHTML = preContent;
          toast.update(dt, {
            render: "Firewall name must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          return;
        }
      }
      const responseData = await UsePost('post', 'firewall/create', 'createfirewall');
      if (responseData) {
        setPageStatus(false)
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
        if (responseData.status === 'error') {
          toast.update(dt, {
            render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        } else if (responseData.status === 'success') {
          toast.update(dt, {
            render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          window.onDeployEvents('deploy_cloud_firewall');
          openDrawer(); 
          if (props) {
            if (props.type == "page") {
              props.updateFirewallList();
            }
          }
        }
      }
    }
  }
  useEffect(() => {
    window.addEventListener("keydown", function (event) {
      event.stopPropagation();
      var query = window.location.href;
      var vars = query.split("/");
      var action = vars[4];
      //console.log(vars);
      if (event.key == "Enter" && action == "deploy" && vars[3] == "firewall") {
        deployFirewall();
      }
    });
  }, []);


  useEffect(() => {
    // if (props) {
    //   if (props.type == "deploy") {
    //     openDrawer();
    //   }
    // }
  }, [])

  const openDrawer = () => {
    var drawerElement = document.querySelector("#firewall-modal");
    var drawer_overlay = document.querySelector("#drawer_overlay");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      drawerElement.style.width = "30%";
      drawer_overlay.classList.toggle("d-none");
    }
    const urlParams = new URLSearchParams(window.location.search);
    const actionParam = urlParams.get('action');
    if (actionParam === 'deploy') {
      const currentURL = window.location.href;
      const updatedURL = currentURL.split('?')[0]; // Remove everything after '?'
      window.history.replaceState(null, '', updatedURL);
      props.setActionType('page')
    }
  };

  return (
    <>
      { }
      <div id="drawer_overlay" onClick={openDrawer} className="drawer-overlay" style={{ zIndex: 109, width: "100%" }}></div>

      <div
        id="firewall-modal"
        className="bg-body shadow drawer drawer-end drawer-on"
        data-kt-drawer="true"
        data-kt-drawer-name="chat"
        data-kt-drawer-activate="true"
        data-kt-drawer-direction="end"
        data-kt-drawer-close="#close_ssh"
        data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
        style={{ maxWidth: '70%', width: '30%', important: true }}

      >
        {/*begin::Messenger*/}
        <div className="card rounded-0 w-100 border-0" id="kt_drawer_chat_messenger">
          {/*begin::Card header*/}
          <div className="card-header pe-5" id="kt_drawer_chat_messenger_header">
            {/*begin::Title*/}
            <div className="card-title">
              {/*begin::User*/}
              <div className="d-flex justify-content-center flex-column me-3">
                <a

                  className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1"
                >
                  Add New Firewall
                </a>
              </div>
              {/*end::User*/}
            </div>
            {/*end::Title*/}
            {/*begin::Card toolbar*/}
            <div className="card-toolbar">
              {/*begin::Close*/}
              <div
                onClick={openDrawer}
                className="btn btn-sm btn-icon btn-active-light-primary"
                id="close_ssh"
              >
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                <span className="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x={6}
                      y="17.3137"
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(-45 6 17.3137)"
                      fill="black"
                    />
                    <rect
                      x="7.41422"
                      y={6}
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(45 7.41422 6)"
                      fill="black"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
              </div>
              {/*end::Close*/}
            </div>
            {/*end::Card toolbar*/}
          </div>
          {/*end::Card header*/}
          {/*begin::Card body*/}
          <div className="" id="">
            {/*begin::Form*/}
            <form id="createfirewall" className="form">
              {/*begin::Modal body*/}
              <div className="modal-body ">
                {/*begin::Scroll*/}
                <div
                  className="scroll-y me-n7 pe-7"
                  id="kt_modal_create_api_key_scroll"
                  data-kt-scroll="true"
                  data-kt-scroll-activate="{default: false, lg: true}"
                  data-kt-scroll-max-height="auto"
                  data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                  data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                  data-kt-scroll-offset="300px"
                >
                  {/*begin::Input group*/}
                  <div className="mb-5 fv-row">
                    {/*begin::Label*/}
                    <label className="required fs-5 fw-bold mb-2"> Name</label>
                    {/*end::Label*/}
                    {/*begin::Input*/}
                    <input
                      type="text"
                      className="form-control  "
                      placeholder="my_web_firewall"
                      name="name"
                      id="name"
                    />
                    {/*end::Input*/}
                  </div>
                  {/*end::Input group*/}
                </div>
                {/*end::Scroll*/}
              </div>
              {/*end::Modal body*/}
              {/*begin::Modal footer*/}
              <div className="container">
                {/*begin::Button*/}
                <button
                  type="button"
                  style={{ width: "100%" }}
                  onClick={deployFirewall}
                  className="btn btn-primary"
                  id="firewall-btn"
                >
                  <span className="indicator-label">Deploy Firewall</span>
                </button>
                {/*end::Button*/}
              </div>
              {/*end::Modal footer*/}
            </form>
            {/*end::Form*/}
          </div>
          {/*end::Card body*/}
        </div>
        {pageStatus &&
          <TopBarHeaderLine />}
        {/*end::Messenger*/}
      </div>
    </>
  );
}

