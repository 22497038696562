import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom/dist";
import UsePost from "../../../../CustomHooks/usePost";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UseApi from "../../../../CustomHooks/useApi";
import { connect } from "react-redux";
import "./custom.css";
import { AddDatabaseDeploy, AddDatabaseNodeCount } from "../../../../Redux/Services/Action/actions";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import PricingContainer from "../pricing/PricingContainer";
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../Loaders/TableLoader";
import { publicIp, publicIpv4, publicIpv6 } from 'public-ip';
import PricingContainerWithoutBillingCycleForSqs from "../pricing/PricingContainerWithoutBillingCycleForSqs";
import Dczone from "../../utils/dczone/Dczone";
var serialize = require("form-serialize");
function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}

const SqsDeploy = (props) => {
  const [nodeCount, setNodeCount] = useState([]);
  const forceUpdate = useForceUpdate();
  const [adcdata, setAdcdata] = useState([]);
  const [ddcdata, setDdcdata] = useState([]);
  const [vpcLoader, setVpcLoader] = useState(false);
  const [vpcArray, setVpcArray] = useState([])
  const [firewallData, setFirewallData] = useState(null);
  const [randomName, setRandomName] = useState("")
  const [distroData, setDistroData] = useState(null);
  const [securityGroups, setSecurityGroups] = useState([])
  const [vpc_id, setVpc_id] = useState('')
  const [vpcMultipleInfo, setVpcMultipleInfo] = useState([]);
  const [vpcData, setVpcData] = useState(null);
  const dataFetchedRef2 = useRef();
  const [planData, setPlanData] = useState([]);
  const [userData, setUserData] = useState(null);
  const [location, setLocation] = useState("innoida")
  const user_data = useRef(null);
  const dczoneStatus = useRef(true);

  const navigate = useNavigate();
  const navRedirect = () => {
    setTimeout(() => {
      navigate('/database-cluster');
    }, 2000);

  }

  const getDczone = async () => {
    const data = await UseApi("get", "dczones", "");
    user_data.current = await UseApi("get", "account/info", "");
    const PlanData = await UseApi("get", `plans/sqs/${location}`, "");
    if (PlanData) {
      setPlanData((prePlanData) => [...PlanData.plans]);
    }
    setUserData(user_data.current.user);
    if (Object.keys(data).length === 0) {
      setAdcdata([]);
      setDdcdata([]);
    } else {
      if (dczoneStatus.current) {
        data.dczones.map(async (value, index) => {
          if (value.status !== "active") {
                var server_ip = await publicIpv4();
                if (server_ip) {
                  if (
                    server_ip !== "103.209.144.51" ||
                    server_ip !== "103.209.144.66" ||
                    server_ip !== "103.209.145.208" ||
                    server_ip !== "103.209.144.81" ||
                    server_ip !== "103.209.144.82" ||
                    server_ip !== "103.209.144.83" ||
                    server_ip !== "103.127.30.91" ||
                    server_ip !== "103.209.144.60" ||
                    server_ip !== "103.127.30.239" ||
                    server_ip !== "103.127.28.52"
                  ) {
                    ddcdata.push(value);
                  }
                  forceUpdate();
                }
          } else {
            adcdata.push(value);
            forceUpdate();
          }
        });
        dczoneStatus.current = false;
      }
      setTimeout(() => {
        var clickTo = document.querySelector("#dcslugl_innoida");
        if (clickTo) {
          clickTo.click();
        }
      }, 1000)

    }
  };
  console.log(props);
  useEffect(() => {
    if (dataFetchedRef2.current) return;
    dataFetchedRef2.current = true;
    getDczone();
  }, []);

  const selectDc = async (slug) => {
    var dczone_slug = document.querySelector("#dczone_slug");
    if (dczone_slug) {
      dczone_slug.value = slug;
    }
    setLocation(slug)
    var dcSelect = document.querySelectorAll([".tickMarkDc"]);
    var currentDc = document.querySelector("#tickMarkDc-" + slug);
    if (dcSelect) {
      dcSelect.forEach((clist) => {
        clist.classList.add("d-none");
      });
    }
    if (currentDc) {
      currentDc.classList.remove("d-none");
    }
    const vpc_data = await UseApi("get", "vpc/dc/" + slug, "");
    if (vpc_data.status === "error") {
      setVpcLoader(false);
      setVpcData(null);
    } else {
      setVpcLoader(false);
      delete vpc_data.html;
      if (Object.keys(vpc_data).length != 0) {
        var vpcArr = Object.values(vpc_data);
        setVpcData(vpcArr);
      }
    }
  };
  const makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    setRandomName((old) => old = "sqs-" + result?.toLowerCase())
    return result.toLowerCase();
  };
  // end- select plans
  function ChangeScreen() {
    useEffect(() => {
      function handleResize() {
        const sideCheckout = document.querySelector("#sideCheckout");
        if (sideCheckout) {
          if (window.innerWidth > 990) {
            sideCheckout.classList.add("mhc_checkout");
          } else {
            sideCheckout.classList.remove("mhc_checkout");
          }
        }
      }
      handleResize();
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
    return null;
  }

  const [allowIp, setAllowIp] = useState(null);
  function getAllowIp() {
    if (props) {
      if (props.nodeCountData) {
        setAllowIp(props.nodeCountData);
      }
    }
  }
  // useEffect(() => {
  //   let vdata = [];
  //   if (vpcData && vpcData.length > 0) {
  //     vpcData.map((value, index) => {

  //       vdata.push(value[1].name + " (" + value[1].id + ")");
  //     })
  //   }

  //   setVpcMultipleInfo(vdata);
  // }, [vpcData])
  useEffect(() => {
    makeid(8)
    getAllowIp();
    updateDeploy();
    getDistro();
  }, [props])
  const addIpField = async () => {
    if (props) {
      if (props.nodeCountData) {
        const newArray = [...props.nodeCountData, makeid(8)];
        props.updateNodeCountData(newArray);
        //   console.log(newArray);
      }
    }
  }
  const deleteIpField = async (ip_id) => {
    if (props) {
      if (props.nodeCountData) {
        const newArray = props.nodeCountData;
        // console.log(newArray);
        for (var i = 0; i < newArray.length; i++) {
          if (newArray[i] == ip_id) {
            const index = newArray.indexOf(newArray[i]);
            if (index > -1) { // only splice array when item is found
              newArray.splice(index, 1); // 2nd parameter means remove one item only
            }
          }
        }
        //    console.log(newArray);
        props.updateNodeCountData([]);
        props.updateNodeCountData(newArray);

      }
    }
  }
  const getDistro = async () => {
    toast.dismiss();
    setDistroData(null);
    var dc = "innoida"
    // if(props && props.deployInfo[0] && props.deployInfo[0].dcslug){
    //      dc=props.deployInfo[0].dcslug;
    // }
    const data = await UseApi("get", "cloud/getdeploy?dcslug=" + dc, "");
    user_data.current = await UseApi("get", "account/info", "");
    setUserData(user_data.current.user);
    if (data.firewalls.length <= 0) {
      setFirewallData(null);
    } else {
      setVpcLoader(false)
      setFirewallData(data.firewalls);
    }
  };
  useEffect(() => {
    var vpc_field = document.querySelector("#vpc_field");
    if (vpc_field && vpcMultipleInfo) {
      //console.log(vpcMultipleInfo);
      const contactTagify = new window.Tagify(vpc_field, {
        whitelist: vpcMultipleInfo,
        maxTags: 20,
        dropdown: {
          maxItems: 20,
          classname: "",
          enabled: 0,
          closeOnSelect: false
        }
      });
    }
  }, [vpcMultipleInfo])


  useEffect(() => {
    var firewall_field = document.querySelector("#firewall_field");
    if (firewall_field && firewallData) {
      var fdata = [];
      firewallData.map((value, index) => {
        fdata.push(value.name + " (" + value.id + ")");
      })
      const contactTagify = new window.Tagify(firewall_field, {
        whitelist: fdata,
        maxTags: 20,
        dropdown: {
          maxItems: 20,
          classname: "",
          enabled: 0,
          closeOnSelect: false
        }
      });
    }
  }, [firewallData])
  function updateNewVpc(e) {
    if (e.target.value) {
      var eleData = JSON.parse(e.target.value);
      var eleIds = eleData.map(item => {
        // Extract string portion from the value within parentheses
        var match = item.value.match(/\(([^)]+)\)/);
        return match ? match[1] : null;
      }).filter(Boolean); // Filter out null values

      // Concatenate the extracted strings into a comma-separated string
      var concatenatedVpcIds = eleIds.join(',');
      let arr = []
      arr.push(concatenatedVpcIds)
      const filteredFirewallIds = arr.filter(id => id !== '');
      setVpcArray(() => [...filteredFirewallIds])
      // // Set the concatenated string as the value of the add_cloud input
      // if (props && props.deployInfo[0]) {
      //   props.deployInfo[0].vpc = concatenatedVpcIds;
      // }
    }
  }
  function updateNewFirewall(e) {
    if (e.target.value) {
      var eleData = JSON.parse(e.target.value);
      var eleIds = eleData.map(item => {
        // Extract numeric portion from the value (assuming it's always in parentheses)
        var match = item.value.match(/\((\d+)\)/);
        return match ? match[1] : null;
      }).filter(Boolean); // Filter out null values

      // Concatenate the cloud IDs into a comma-separated string
      var concatenatedFirewallIds = eleIds.join(',');
      let arr = []
      arr.push(concatenatedFirewallIds)
      const filteredFirewallIds = arr.filter(id => id !== '');
      setSecurityGroups(() => [...filteredFirewallIds])

      // // Set the concatenated string as the value of the add_cloud input
      // if (props && props.deployInfo[0]) {
      //   props.deployInfo[0].security_groups = concatenatedFirewallIds;
      // }
    }
  }

  const [databaseData, setDatabaseData] = useState(null);
  const getDatabases = async () => {

    const data = {
      "databases": [
        {
          "id": "1",
          "appid": "1",
          "type": "SQL",
          "name": "MySQL",
          "slug": "mariadb",
          "versions": "mariadb-version-15.1",
          "status": "active",
        },
        {
          "id": "2",
          "appid": "1",
          "type": "SQL",
          "name": "Postgre SQL",
          "slug": "pg",
          "versions": "pg-version-16,pg-version-15,pg-version-14,pg-version-13",
          "status": "active",
        }
      ]
    }
    if (data) {
      setDatabaseData(data.databases);
    }
  }

  useEffect(() => {
    getDatabases();
  }, []);


  const databaseVersion = (dbImage) => {
    var pselect = document.querySelectorAll([".deploy_dbVersioncontainer"]);
    var pheight = document.querySelectorAll([".versionDb"]);
    pselect.forEach((clist) => {
      clist.classList.remove("d-block");
    });
    //var cl=event.currentTarget.querySelector('.versionOs');

    pheight.forEach((clist) => {
      clist.style.height = "auto";
      clist.classList.remove("active");
    });

    const database_version_list = document.querySelector(
      "#database_version_list_" + dbImage
    );
    database_version_list.classList.add("d-block");
  };

  function database_Name(str) {
    if (!str) {
      return "";
    }
    // Split the string into an array using the delimiter "-"
    var arr = str.split("-");

    // Return the element at the 0th index
    return arr[0].toLowerCase();
  }
  function database_Version(str) {
    if (!str) {
      return "";
    }
    // Split the string into an array using the delimiter "-" 
    var arr = str.split("-");

    // Return the element at the 0th index
    return arr[2].toLowerCase();
  }

  const selectDb = (slug, version) => {
    if (props) {
      if (props.deployInfo[0]) {
        if (props.deployInfo[0].db_version != version) {
          const updatedObject = { ...props.deployInfo[0], db_version: version };
          const newArray = [updatedObject];
          props.updateDeployInfo(newArray);
          console.log(newArray);
        }
      }
    }

    var database_version = document.querySelector("#database_Version");
    if (database_version) {
      database_version.value = database_Version(version);
    }
    var database_engine = document.querySelector("#database_engine");
    if (database_engine) {
      database_engine.value = database_Name(version);
    }

    var osSelect = document.querySelectorAll([".tickMarkDb"]);
    var currentOs = document.querySelector("#tickMarkDb-" + slug);
    if (osSelect) {
      osSelect.forEach((clist) => {
        clist.classList.add("d-none");
      });
    }
    if (currentOs) {
      currentOs.classList.remove("d-none");
    }

    const os_cost = document.querySelector("#selectedDatabaseVersion");
    if (os_cost) {
      os_cost.value = version;
    }
    const current_db_version = document.querySelector("#database_version_list_" + slug);
    if (current_db_version) {
      current_db_version.classList.remove("d-block");
    }
    const all_radio = document.getElementsByClassName("database_radio");
    if (all_radio) {
      for (var i = 0; i < all_radio.length; i++) {
        all_radio[i].checked = false;
      }
    }
    const all_label = document.getElementsByClassName("versionDb");
    if (all_label) {
      for (var i = 0; i < all_label.length; i++) {
        //all_label[i].classList.remove('active');
        all_label[i].classList.remove("shadowBox");
      }
    }
    var pselect = document.querySelectorAll([".deploy_dbversioncontainer"]);
    if (pselect) {
      pselect.forEach((clist) => {
        clist.classList.remove("d-block");
      });
    }
    const all_versionText = document.getElementsByClassName(
      "dbversionselectmultiple"
    );
    if (all_versionText) {
      for (var i = 0; i < all_versionText.length; i++) {
        all_versionText[i].innerHTML = "Select Version";
      }
    }


    const current_radio = document.querySelector("#select_" + slug);
    if (current_radio) {
      current_radio.checked = true;
    }
    const current_label = document.querySelector(
      "#versionDb_" + slug
    );
    if (current_label) {
      current_label.classList.add("shadowBox");
    }
    //current_label.classList.add('active');
    const current_select_version = document.querySelector(
      "#dbversion_" + slug
    );
    if (current_select_version) {
      current_select_version.innerHTML = version;
    }
  };



  const addDatabase = async () => {
    var submitBtn = document.querySelector("#database-btn");
    if (submitBtn) {
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      const dt = toast.loading("Please wait...");
      const responseData = await UsePost('post', 'databases', 'databaseDeploy');
      if (responseData) {
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
        if (responseData.status === 'error') {
          toast.update(dt, {
            render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        } else if (responseData.status === 'success') {
          toast.update(dt, {
            render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          navRedirect();
        }
      }

    }
  }
  function databaseName(str) {
    // Split the string into an array using the delimiter "-"
    if (str != "" && str != undefined && str != null) {
      var arr = str.split("-");

      // Return the element at the 0th index
      return arr[0].toLowerCase();
    }
  }


  function updateDeploy() {
    if (props && props.deployInfo[0]) {
      if (props.deployInfo[0].db_version != "") {
        var database_version = document.querySelector("#database_version");
        var database_engine = document.querySelector("#database_engine");
        if (database_version && database_engine) {
          database_version.value = database_Version(props.deployInfo[0].db_version);
          database_engine.value = database_Name(props.deployInfo[0].db_version);
          var dbslug = databaseName(props.deployInfo[0].db_version);
          selectDb(dbslug, props.deployInfo[0].db_version);
        }
      }
      //   if(props.deployInfo[0].price!=""){
      //   var plan_value=document.querySelector("#plan_value");
      //   if(plan_value){
      //     plan_value.value=props.deployInfo[0].plan_id;
      //     show_plan_price(props.deployInfo[0].plan_id, props.deployInfo[0].price)
      //   }
      // }
      // if(props.deployInfo[0].billingcycle!=""){
      //   var billing_info=document.querySelector("#billing_info");
      //   if(billing_info){
      //     billing_info.value=props.deployInfo[0].billingcycle;
      //   }
      // }
      if (props.deployInfo[0].nodes_count != "") {
        var replica_input = document.querySelector("#replica_input");
        if (replica_input) {
          replica_input.value = props.deployInfo[0].nodes_count;
        }
      }
      if (props.deployInfo[0].cluster_label != "") {
        var db_label = document.querySelector("#db_label");
        if (db_label) {
          db_label.value = props.deployInfo[0].cluster_label;
        }
      }
      if (props.deployInfo[0].dcslug != "") {
        var dczone_slug = document.querySelector("#dczone_slug");
        if (dczone_slug) {
          dczone_slug.value = props.deployInfo[0].dcslug;
          selectDc(props.deployInfo[0].dcslug);
        }
      }
    }
    calcFinalCost();
  }

  useEffect(() => {
    if (props) {
      updateDeploy();
      // console.log(props.deployInfo[0]);
    }
  }, [props])

  function checkInput(val) {
    var replica_input = document.querySelector("#replica_input");
    if (replica_input) {
      if (val < 1) {
        replica_input.value = 1;
      }
    }
    calcFinalCost();
  }

  function calcFinalCost() {
    var current_plan_price = document.querySelector("#plan_cost");
    // var replica_input = document.querySelector("#replica_input");
    var finalCost = 0;
    if (current_plan_price) {
      // console.log(parseInt(current_plan_price.value)+" => "+parseInt(replica_input.value));
      finalCost = parseInt(current_plan_price.value)

    }
    var currency_final = document.querySelector("#currency_final");
    var total_cost = document.querySelector("#total_cost");
    var billing_final = document.querySelector("#billing_final");
    console.log(billing_final, total_cost);
    if (currency_final && total_cost && billing_final) {
      if (user_data && user_data.current && user_data.current.user) {
        currency_final.innerHTML = user_data.current.user.currencyprefix;
      }
      total_cost.innerHTML = finalCost;
      const billing_info = document.querySelector("#billing_info");
      if (billing_info) {
        // if (billing_info.value == "12month") {
        //   billing_final.innerHTML = " /year";
        // } else if (billing_info.value == "monthly") {
        //   billing_final.innerHTML = " /mon";
        // } else if (billing_info.value == "hourly") {
        billing_final.innerHTML = " /mon";
        // }
      }
    }
  }


  function updatedPlanInfo(plan = []) {
    // console.log("info ") ;
    //  console.log(props.deployInfo[0]);
    const billing_info = document.querySelector("#billing_info");
    const plan_value = document.querySelector("#size_value");
    const plan_cost = document.querySelector("#plan_cost");
    console.log(plan);
    if (billing_info && plan_value && plan_cost) {
      billing_info.value = plan.billing;
      plan_value.value = plan.planid;
      plan_cost.value = plan.payable_price;

    }
    if (props.deployInfo) {

      props.deployInfo[0].plan_id = plan.planid;
      props.deployInfo[0].billingcycle = plan.billing;
      props.deployInfo[0].price = plan.payable_price;
    }
    calcFinalCost();
  }
  const selectTickMark = (sshid, name) => {
    var allTickmarks = document.querySelectorAll([".tickMark" + name]);
    var currentPlan = document.querySelector("#tickMark" + name + "-" + sshid);
    var mainTickMark = document.querySelectorAll([".border" + name]);
    var currentMain = document.querySelector("#border" + name + "-" + sshid);
    setVpc_id(sshid)
    // props.deployInfo[0].vpc = sshid
    if (mainTickMark) {
      mainTickMark.forEach((clist) => {
        clist.classList.remove("activeBorder");
      });
    }
    if (allTickmarks) {
      allTickmarks.forEach((clist) => {
        clist.classList.add("d-none");
      });
    }
    if (currentPlan) {
      currentPlan.classList.remove("d-none");
    }
    if (currentMain) {
      currentMain.classList.add("activeBorder");
    }
  };

  const handleChangeReplicaCount = (status) => {
    let plusElement = document.querySelector(`#replica_up`)
    let inputElement = document.querySelector("#replica_input")
    let minusElement = document.querySelector('#replica_down')

    const increaseReplicaCount = () => {
      minusElement.classList.remove('disabled');
      if (parseInt(inputElement.value) < 3) {
        inputElement.value = parseInt(inputElement.value) + 1;
      } else {
        plusElement.classList.add('disabled');
      }
    };
    const decreaseReplicaCount = () => {
      plusElement.classList.remove('disabled');
      if (parseInt(inputElement.value) > 0) {
        inputElement.value = parseInt(inputElement.value) - 1;
      } else {
        minusElement.classList.add('disabled');
      }
    };

    if (status == 'up') {
      increaseReplicaCount();
    } else if (status == 'down') {
      decreaseReplicaCount();
    }
    calcFinalCost()
  }


  const choosePlan = (pid) => {
    let diskSize = document.querySelector(`#disk_size_${pid}`)
    let planType = document.querySelector(`#plan_type_${pid}`)
    let repoSize = document.querySelector(`#repo_size_${pid}`)
    let selectElement = document.getElementById("selected_type");
    let selectedValue = selectElement.value;
    let assignValueToPlan = document.querySelector("#plan_value")
    let assignValueToRepo = document.querySelector("#repo_value")
    let assignValueToDisk = document.querySelector("#disk_value")
    let assignValueToType = document.querySelector("#registry_type")
    assignValueToDisk.value = diskSize.textContent
    assignValueToRepo.value = repoSize.textContent
    assignValueToPlan.value = planType.textContent
    assignValueToType.value = selectedValue === "public" ? true : false
    for (let i = 1; i <= 4; i++) {
      var planActive = document.querySelector("#plan-active-" + i);
      var ribbonActive = document.querySelector("#ribbon-active-" + i);
      if (planActive && ribbonActive) {
        if (pid === i) {
          planActive.classList.add("active-plan");
          ribbonActive.classList.remove("d-none");
        } else {
          planActive.classList.remove("active-plan");
          ribbonActive.classList.add("d-none");
        }
      }
    }

  }

  setTimeout(() => {
    var clickTo = document.querySelector("#plan-active-3");
    if (clickTo) {
      clickTo.click();
    }
  }, 1000)

  const handleChangeRegistryType = (type) => {
    let assignValueToType = document.querySelector("#registry_type")
    assignValueToType.value = type === "public" ? true : false
  }
  const handleCreateRegistry = async () => {
    try {
      var submitBtn = document.querySelector("#sqs-btn");
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      toast.dismiss();
      const dt = toast.loading("SQS creating, Please wait...");
      const responseData = await UsePost("post", "sqs", "sqsDeploy")
      if (responseData.status === "success") {
        toast.update(dt, {
          render: responseData.message,
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        window.onDeployEvents('deploy_sqs');
        setTimeout(() => {
          navigate('/sqs')
        }, 2000)
      } else {
        toast.update(dt, {
          render: responseData.message,
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML = preContent;
    } catch (error) {
      console.log(error);
    }
  }

  const fireTooltip = (toolId, type) => {
    console.log(type);
    window.KTDrawer.createInstances();
    var drawerElement = document.querySelector("#" + toolId);

    if (drawerElement) {
      if (type == "show") {
        drawerElement.classList.add("drawer-on");
      } else {
        drawerElement.classList.remove("drawer-on");
      }
      drawerElement.style.width = "25%";
    }
  }

  const handleChange = (e) => {
    const value = e.target.value;
    const validCharacters = /^[a-zA-Z0-9-_]*$/;
    if (validCharacters.test(value) && value.length <= 80) {
      setRandomName(value);
    }
  };

  return (
    <>
      {props && adcdata.length > 0 ? (<>
        <ChangeScreen />
        <form id="sqsDeploy">
          {/*begin::Tables Widget 9*/}
          <div className=" col-xl-12 card mb-5">
            {/*begin::Header*/}
            <input
              type="hidden"
              name=""
              id="dczone_slug"
              className="form-control text-center"
            // defaultValue={props?.deployInfo[0]?.dcslug}
            />
            {/*begin::data center*/}
            <Dczone adcdata={adcdata} ddcdata={ddcdata} selectDc={selectDc} location={location}/>
            {/* End data center */}
          </div>

          {userData != null &&
            planData != null &&
            props &&
            props.deployInfo ? (
            <>
              <PricingContainerWithoutBillingCycleForSqs
                updatePlan={updatedPlanInfo}
                currBilling="monthly"
                currPlanid={props.deployInfo[0].planid}
                dcLocation={props.deployInfo[0].dcslug}
                user={userData}
                plans={planData}
              />
            </>
          ) : (
            <>
              <div className="col-xl-12 card mb-5">
                <div className="card-header border-3 mb-5 pt-2">
                  {/*begin::Title*/}
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder text-gray-800">
                      Select Your Plan
                    </span>
                    <span className="text-gray-400 mt-1 fw-bold fs-6">
                      Basic Plan
                    </span>
                  </h3>
                </div>
                <div className="mx-5 mb-3 pb-5">
                  {/* <div className="small-spinner"></div> */}
                  <TableLoader />
                </div>
              </div>
            </>
          )}
          <input type="hidden" id="size_value" name="planid" />
          <input type="hidden" id="billing_info" />
          <input type="hidden" id="plan_cost" />
          <div className="col-xl-12 card mb-5">
            <div className="card-header border-3 mb-5 pt-2" style={{padding:'1rem 1rem 1rem 1.38rem'}}>
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder text-gray-800">
                  Name
                </span>
                <span className="text-gray-400 mt-1 fw-bold fs-6">
                  A SQS Name is case-sensitive and can have up to 80 characters. You can use alphanumeric characters, hyphens (-) and underscores ( _ ).
                </span>
              </h3>
            </div>
            <div className="mx-5 mb-3">
              <div
                className="row g-9"
                data-kt-buttons="true"
                data-kt-buttons-target="[data-kt-button]"
              >
                <div className="col-md-4 col-lg-4 col-xxl-4">
                  <div className="">
                    <div className="input-group mb-5">
                      <input
                        type="text"
                        name="name"
                        id="db_label"
                        className="form-control"
                        placeholder="cluster label"
                        value={randomName}
                        // value={}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-12 card mb-5">
            <div className="card-header  mb-5 pt-2" style={{ border: "none",padding:'1rem 1rem 1rem 1.38rem' }}>
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1">
                  Total Cost
                </span>
                <span className="fs-xl-2x fs-2 fw-boldest">
                  <span id="currency_final"></span>
                  <span id="total_cost"></span>
                  <span id="billing_final"></span> </span>
                <span className="text-muted mt-1 fw-bold fs-7" />
              </h3>
              <div className="card-toolbar">
                <a style={{ width: "100%" }}
                  className="btn  btn-primary btn-active-light-primary" id="sqs-btn" onClick={handleCreateRegistry}>
                  Create SQS &nbsp; &nbsp;
                  <span className="svg-icon svg-icon-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z"
                        fill="black"
                      />
                      <path
                        opacity="0.3"
                        d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                </a>
              </div>
            </div>
          </div>

          {/*end::Tables Widget 9*/}
        </form>

        {/* Tooltip Drawer start */}
        <div
          id="standard-info-tooltip"
          className="bg-white shadow"
          data-kt-drawer="true"
          data-kt-drawer-activate="true"
          data-kt-drawer-toggle="#kt_drawer_example_basic_button"
          data-kt-drawer-close="#kt_drawer_example_basic_close"
          data-kt-drawer-width="500px"
        >
          <div className="card w-100 rounded-0">
            {/*begin::Card header*/}
            <div className="card-header pe-5">
              {/*begin::Title*/}
              <div className="card-title">
                {/*begin::User*/}
                <div className="d-flex justify-content-center flex-column me-3">
                  <a
                    href="#"
                    className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 lh-1"
                  >
                    Standard Type
                  </a>
                </div>
                {/*end::User*/}
              </div>
              {/*end::Title*/}
              {/*begin::Card toolbar*/}
              <div className="card-toolbar">
                {/*begin::Close*/}
                <div
                  className="btn btn-sm btn-icon btn-active-light-primary"
                  id="kt_drawer_example_basic_close"
                >
                  {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                  <span className="svg-icon svg-icon-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.5"
                        x={6}
                        y="17.3137"
                        width={16}
                        height={2}
                        rx={1}
                        transform="rotate(-45 6 17.3137)"
                        fill="black"
                      />
                      <rect
                        x="7.41422"
                        y={6}
                        width={16}
                        height={2}
                        rx={1}
                        transform="rotate(45 7.41422 6)"
                        fill="black"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                </div>
                {/*end::Close*/}
              </div>
              {/*end::Card toolbar*/}
            </div>
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className="card-body hover-scroll-overlay-y">
              <p className="text-gray-700 fs-6">Standard queues support a nearly unlimited number of transactions per second (TPS) per API action (SendMessage, ReceiveMessage, or DeleteMessage). A message is delivered at least once, but occasionally more than one copy of a message is delivered. Standard queues provide best-effort ordering. Occasionally, messages might be delivered in an order different from which they were sent.</p>
              <p className="text-gray-700 fs-6">Standard queues are useful when very high throughput is important.</p>
              <p className="fs-6"><b>Examples include:</b></p>
              <p>
                <ul>
                  <li className="text-gray-700 fs-6">
                    Decouple live user requests from intensive background work: let users upload media while resizing or encoding it.
                  </li>
                  <li className="text-gray-700 fs-6">
                    Allocate tasks to multiple worker nodes: process a high number of credit card validation requests.
                  </li>
                </ul>
              </p>
            </div>
            {/*end::Card body*/}
          </div>

        </div>
      </>) : (<><TopBarHeaderLine /><TableLoader /></>)}
    </>
  );
};


const mapStateToProps = (state) => ({
  nodeCountData: state.databaseClusterCount.nodeCountDataInfo,
  deployInfo: state.databaseClusterCount.deployClusterInfo,
});

const mapDispatchToProps = (dispatch) => ({
  updateNodeCountData: (data) => dispatch(AddDatabaseNodeCount(data)),
  updateDeployInfo: (data) => dispatch(AddDatabaseDeploy(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SqsDeploy); 
