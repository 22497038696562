import React, { useEffect, useRef, useState } from "react"; 
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; 
import swal from "sweetalert";   
import copy from "copy-to-clipboard";
export default function Configuration(props) {
    // CSS Start
    const sphide={
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
        }
    // CSS End
  var query = window.location.href; 
  var vars = query.split("/"); 
  var id=vars[4]; 
  const dataFetchedRef2 = useRef();
  const navigate=useNavigate();
  const navRedirect=()=>{
    setTimeout(() => {
      navigate('/archiveStorage');
     }, 2000);
      
  }     
    const copyToClipboard=(id)=> {   
       
      var inputField = document.querySelector("#mhc_step"+id);
      var copyBtn = document.querySelector("#mhc_step"+id+"_btn");
      if(copyBtn && inputField){
        var copyText=copyBtn.innerHTML;
        var copyEle=inputField.value;
      copy(copyEle);  
      inputField.classList.add("bg-success");
      inputField.classList.add("text-inverse-success");
      copyBtn.innerHTML="Copied";
      copyBtn.classList.remove("badge-primary");
      copyBtn.classList.add("badge-success");
      setTimeout(() => {
        inputField.classList.remove("bg-success");
      inputField.classList.remove("text-inverse-success");
        copyBtn.classList.add("badge-primary");
        copyBtn.classList.remove("badge-success");
        copyBtn.innerHTML=copyText;
      }, 2000);
    }
     
    }
 
  return (
    <>   
           <div
          className="tab-pane fade active show"
          id="connection-details"
          role="tabpanel"
        >
          <div className="card pt-4 mb-6 mb-xl-9">
            {/*begin::Card header*/}
            <div className="card-header border-0">
              {/*begin::Card title*/}
              <div className="card-title">
                <h2>Configuration Details</h2>
              </div>
              {/*end::Card title*/}
            </div>
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className="card-body pt-0 pb-5">
              <ul>
                <li>  
              {/*begin::Step description*/}
              <div className="w-lg-50 mb-15">
                {/*begin::Title*/}
                <h4 className="fs-5 fw-bold text-gray-800 mb-5">
                  In order to begin utilizing a new archive storage, it is
                  essential to create a filesystem on it.
                </h4>
                {/*end::Title*/}
                {/*begin::Title*/}
                <div className="d-flex">
                  <input
                    id="mhc_step1"
                    type="text"
                    className="form-control form-control-solid me-3 flex-grow-1"
                    name="search"
                    defaultValue="mkfs.ext4 '/dev/disk/by-id/scsi-0utho_archive_storage_1'"
                  />
                  <button
                    id="mhc_step1_btn"
                    className="btn btn-light fw-bolder flex-shrink-0"
                    data-clipboard-target="#mhc_step1"
                    onClick={(e)=> copyToClipboard(1)}
                  >
                    Copy
                  </button>
                </div>
                {/*end::Title*/}
              </div>
              {/*end::Step description*/}
              </li>
                <li> 
              {/*begin::Step description*/}
              <div className="w-lg-50 mb-15">
                {/*begin::Title*/}
                <h4 className="fs-5 fw-bold text-gray-800  mb-5">
                  After successfully creating the filesystem on the archive
                  storage, the subsequent action is to establish a mountpoint
                  specifically assigned to it.
                </h4>
                {/*end::Title*/}
                {/*begin::Title*/}
                <div className="d-flex">
                  <input
                    id="mhc_step2"
                    type="text"
                    className="form-control form-control-solid me-3 flex-grow-1"
                    name="search"
                    defaultValue="mkdir '/mnt/archive_storage_1'"
                  />
                  <button
                    id="mhc_step2_btn"
                    className="btn btn-light fw-bolder flex-shrink-0"
                    data-clipboard-target="#mhc_step2"
                    onClick={(e)=> copyToClipboard(2)}
                  >
                    Copy
                  </button>
                </div>
                {/*end::Title*/}
              </div>
              {/*end::Step description*/} 
              </li>
                <li> 
              {/*begin::Step description*/}
              <div className="w-lg-50 mb-15">
                {/*begin::Title*/}
                <h4 className="fs-5 fw-bold text-gray-800  mb-5">
                  Once all the necessary preparations are complete, you can
                  proceed to mount the newly generated storage.
                </h4>
                {/*end::Title*/}
                {/*begin::Title*/}
                <div className="d-flex">
                  <input
                    id="mhc_step3"
                    type="text"
                    className="form-control form-control-solid me-3 flex-grow-1"
                    name="search"
                    defaultValue="mount '/dev/disk/by-id/scsi-0utho_archive_storage_1' '/mnt/archive_storage_1'"
                  />
                  <button
                    id="mhc_step3_btn"
                    className="btn btn-light fw-bolder flex-shrink-0"
                    data-clipboard-target="#mhc_step3"
                    onClick={(e)=> copyToClipboard(3)}
                  >
                    Copy
                  </button>
                </div>
                {/*end::Title*/}
              </div>
              {/*end::Step description*/} 
              </li>
                <li> 
              {/*begin::Step description*/}
              <div className="w-lg-50 mb-15">
                {/*begin::Title*/}
                <h4 className="fs-5 fw-bold text-gray-800  mb-5">
                  If you want the volume to be mounted automatically when your
                  server boots up, make sure to add a line similar to the
                  following to your "/etc/fstab" file.
                </h4>
                {/*end::Title*/}
                {/*begin::Title*/}
                <div className="d-flex">
                  <input
                    id="mhc_step4"
                    type="text"
                    className="form-control form-control-solid me-3 flex-grow-1"
                    name="search"
                    defaultValue="/dev/disk/by-id/scsi-0utho_archive_storage_1 /mnt/archive_storage_1 ext4 defaults,noatime,nofail 0 2"
                  />
                  <button
                    id="mhc_step4_btn"
                    className="btn btn-light fw-bolder flex-shrink-0"
                    data-clipboard-target="#mhc_step4"
                    onClick={(e)=> copyToClipboard(4)}
                  >
                    Copy
                  </button>
                </div>
                {/*end::Title*/}
              </div>
              {/*end::Step description*/} 
              </li>
              </ul>
            </div>
            {/*end::Card body*/}
          </div>
        </div>
      </>); 
}
