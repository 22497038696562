import React, { useState } from "react";
import { Link } from "react-router-dom";
import ResourceList from "./ResourceList";

export default function TransferResource() {
  const [resourceType, setResourceType] = useState("cloud");

  const handleResourceType = (event) => {
    setResourceType(event.target.value);
  };
  return (
    <>
      <div className="col-xl-12 card mb-5">
        {/*begin::Header*/}
        <div className="card-header pt-5 pb-5 border-2">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder text-gray-800 mb-1" >Transfer the resources</span>
            <span className="text-gray-400 fw-bold fs-6">Send resources to another account.</span>
            {/* <ol class="breadcrumb text-muted fs-6 fw-bold">

              <li class="breadcrumb-item pe-2"><Link to="/resources"><a href="#" class="pe-2">Receive</a></Link></li>

              <li class="breadcrumb-item pe-2"><a href="#" class="pe-2 text-muted">Transfer</a></li>
            </ol> */}
          </h3>
          <div className="card-toolbar">
            {/* <div style={{ marginRight: 20 }}>
              <div className="dataTables_length">

                <label className="w-100"> */}
                  <Link to="/resource/receive">
                    <a className="btn btn-sm btn-primary" style={{padding:'0.16rem 1rem'}}>

                      {/*begin::Svg Icon | path: assets/media/icons/duotune/files/fil022.svg*/}
                      <span className="svg-icon svg-icon-light svg-icon-2hx">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            opacity="0.3"
                            d="M19 15C20.7 15 22 13.7 22 12C22 10.3 20.7 9 19 9C18.9 9 18.9 9 18.8 9C18.9 8.7 19 8.3 19 8C19 6.3 17.7 5 16 5C15.4 5 14.8 5.2 14.3 5.5C13.4 4 11.8 3 10 3C7.2 3 5 5.2 5 8C5 8.3 5 8.7 5.1 9H5C3.3 9 2 10.3 2 12C2 13.7 3.3 15 5 15H19Z"
                            fill="black"
                          />
                          <path
                            d="M13 17.4V12C13 11.4 12.6 11 12 11C11.4 11 11 11.4 11 12V17.4H13Z"
                            fill="black"
                          />
                          <path
                            opacity="0.3"
                            d="M8 17.4H16L12.7 20.7C12.3 21.1 11.7 21.1 11.3 20.7L8 17.4Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}
                      Receive Resource
                      </a>
                  </Link>
                {/* </label>
              </div>
            </div> */}
          </div>
        </div>
        {/*end::Header*/}
      </div>
      <div className="col-xl-12 card mb-5">
        {/*begin::List widget 10*/}
        {/*begin::Header*/}
        <div className="card-header border-2">
          {/*begin::Title*/}
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder text-gray-800">
              Choose Resource Type &nbsp;&nbsp;
            </span>
          </h3>
          {/*end::Title*/}
        </div>
        {/*end::Header*/}
        {/*begin::Body*/}
        <div className=" row card-body">
          <div className="col-md-6 col-sm-6 col-xl-6 col-lg-6 col-xxl-6 ps-2">
            <select
              className="form-select"
              id="resource-type"
              name="type"
              onChange={handleResourceType}
            >
              <option value="cloud">Cloud Instance</option>
              {/* <option value="kubernetes">Kubernetes</option> */}
              {/* <option value="database">Database</option> */}
              {/* <option value="object">Object Storage</option> */}
              {/* <option value="loadbalancer">Load Balancer</option> */}
              {/* <option value="firewall">Cloud Firewall</option> */}
              <option value="dns">DNS</option>
              {/* <option value="snapshots">Snapshots</option> */}
              {/* <option value="backups">Backups</option> */}
              <option value="iso">ISO</option>
              {/* <option value="ssh">SSH</option> */}
              {/* <option value="api">API</option>   */}
            </select>
          </div>
        </div>
        {/*end: Card Body*/}
        {/*end::List widget 10*/}
      </div>
      {resourceType ? (<>
        <ResourceList type={resourceType} />
      </>) : ('')}

    </>
  )
}