import axios from 'axios';
import Cookies from 'js-cookie';
import localStorage from 'local-storage'; 

export default async function CallAppinfo(){ 
        var baseUrl="https://api.utho.com";
       const platformName=localStorage.get("platformType");
       if(platformName){
         if(platformName=="dev"){
          baseUrl= "https://dev.api.cloudplatformapp.com";
         }
       } 
       await axios({
        method: "get",
        url: baseUrl+"/v2/appinfo",
    }).then(async function(response){  
      localStorage.set("appInfo",response.data); 
      return true; 
    }) 
}

