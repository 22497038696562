import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Search from "../../../../utils/search/Search";
import { propTypes } from "react-bootstrap/esm/Image";

function Snapshots(props) {
  const { snapData, selectSnapshot, snapid, os_disk_size } = props;

  useEffect(() => {
    if (snapData === undefined) throw new Error("Missing prop: snapData");
    if (snapid === undefined) throw new Error("Missing prop: snapid");
    if (os_disk_size === undefined) throw new Error("Missing prop: os_disk_size");
    if (selectSnapshot === undefined)
      throw new Error("Missing prop: selectSnapshot");
  }, [snapData, selectSnapshot]);

  const [snapList, setSnapList] = useState(snapData);

  useEffect(()=>{
    if(snapid){
    selectSnapshot(snapid, os_disk_size);
    }
  },[snapList])
  return (
    <>
      {snapData != null ? (
        <>
          <Search results={snapData} onSearch={setSnapList} fields={['name']} searchPlaceholder="snapshot" />
          {snapList.length != 0 ? (
            <>
              {snapList.map((value, index) => (
                <>
                  <div className="col-md-4 col-lg-4 col-xxl-4">
                    <input
                      className="btn-check deploy_checkbox snapshot_radio"
                      type="radio"
                      name="image_OS"
                      defaultValue={value.id}
                      id={"snapshotid" + value.id}
                    />
                    <label
                      onClick={(e) =>
                        selectSnapshot(value.id, value.size)
                      }
                      htmlFor={"snapshotid" + value.id}
                      className=" label versionOs text-start border-1 border-gray-300 border px-6 py-1 d-flex"
                    >
                      <span className="svg-icon svg-icon-muted svg-icon-4x me-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            opacity="0.3"
                            d="M22 5V19C22 19.6 21.6 20 21 20H19.5L11.9 12.4C11.5 12 10.9 12 10.5 12.4L3 20C2.5 20 2 19.5 2 19V5C2 4.4 2.4 4 3 4H21C21.6 4 22 4.4 22 5ZM7.5 7C6.7 7 6 7.7 6 8.5C6 9.3 6.7 10 7.5 10C8.3 10 9 9.3 9 8.5C9 7.7 8.3 7 7.5 7Z"
                            fill="black"
                          />
                          <path
                            d="M19.1 10C18.7 9.60001 18.1 9.60001 17.7 10L10.7 17H2V19C2 19.6 2.4 20 3 20H21C21.6 20 22 19.6 22 19V12.9L19.1 10Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                      <span className="d-block fw-bold text-start py-2 sphide">
                        <span className="text-dark fw-bolder d-block fs-6 sphide2">
                          {value.name != null ? value.name : value.id}
                        </span>
                        <span className="text-muted fw-bold fs-7">
                          {" "}
                          {value.createdate}{" "}
                        </span>
                      </span>
                      {/*begin::Ribbon*/}
                      <div
                        className="d-none tickMarksnapshot ribbon ribbon-triangle ribbon-top-start border-primary"
                        id={"tickMarksnapshot-" + value.id}
                      >
                        {/*begin::Ribbon icon*/}
                        <div className="ribbon-icon mt-n5">
                          <i className="bi bi-check2 fs-2 text-white" />
                        </div>
                        {/*end::Ribbon icon*/}
                      </div>
                      {/*end::Ribbon*/}
                    </label>
                  </div>
                </>
              ))}
            </>
          ) : (
            <>
              <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                {/*begin::Wrapper*/}

                <div className="pt-lg-10 mb-10">
                  {/*begin::Message*/}
                  <div className="fw-bold fs-5  mb-15 badge badge-light-primary">
                    You don't have any Snapshots on this location
                  </div>
                  {/*end::Message*/}
                </div>
                {/*end::Wrapper*/}
              </div>
            </>
          )}
        </>
      ) : (
        <>
          {/*begin::Content*/}
          <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
            {/*begin::Wrapper*/}
            <div className="pt-lg-10 mb-10">
              {/*begin::Message*/}
              <div className="fw-bold fs-5  mb-15 badge badge-light-primary">
                Please select Datacenter Location to view snapshots list.
              </div>
              {/*end::Message*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Content*/}
        </>
      )}
    </>
  );
}

Snapshots.propTypes = {
  snapid: PropTypes.number.isRequired,
  os_disk_size: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  snapData: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.oneOf([null]),
  ]).isRequired,
  selectSnapshot: PropTypes.func.isRequired,
};

export default Snapshots;
