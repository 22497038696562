import React, { useEffect, useRef, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import swal from "sweetalert";   
import UsePostData from '../../../../CustomHooks/usePostData';
import UseApi from '../../../../CustomHooks/useApi';
import Contact from './Contact';
import TableLoader from '../../Loaders/TableLoader';
const AlertRules = (props) => {
  const [comparisonArr, setComparsionArr]=useState(["below", "above", "equalto",]);
  const [durationArr, setDurationArr] = useState(["5m", "10m", "30m", "1h", "3h", "6h", "12h", "24h"]);

  const [dataFetched, setDataFetched] = useState(false);
  const [access, setAccess] = useState(true);
  const loaderFunc=()=>{
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if(loader && no_record){
    no_record.classList.add("d-none");
    loader.classList.remove("d-none");

    setTimeout(() => {
      loader.classList.add("d-none");
      no_record.classList.remove("d-none");
    }, 100);
  }
  }  
 
    const dataFetchedRef =useRef(false);
const [alertData, setAlertData]=useState(null);
const [monitorData, setMonitorData]=useState(null); 
const [contactData, setContactData]=useState([]);
const [cloudInfo, setCloudInfo] = useState(null);
const getContact = async () => {
  toast.dismiss(); 
  const data = await UseApi("get","alert/contact/list","");
  // console.log(data);
  if('status' in data){
    if(data.status=="error"){   
      setContactData(null); 
      loaderFunc(); 
      return;
    }
 }
  if (data.contacts.length === 0) { 
    setContactData(null);  
    loaderFunc();
  } else {
    var cData=[];
    
    data.contacts.map((value,index)=>{ 
      cData.push(value.name+" ("+value.id+")");
    })
    setContactData(cData); 
       
  }
}; 
 

  const getCloud = async () => { 
    const clouds = await UseApi('get', 'cloud', '');
  
    if (clouds) {
      var cData=[];
    
      clouds.cloud.map((value,index)=>{ 
         
      cData.push(value.hostname+" ("+value.cloudid+")");
    })
    console.log(cData);
    setCloudInfo(cData); 
    }  
  };
const getAlert = async () => {
  const dt = toast;
  const data = await UseApi("get", "alert", "");
  if (data) {
    setDataFetched(true);
  }
  if ("status" in data) {
    if (data.status == "error") {
      toast.update(dt, {
        render: data.message,
        type: "warning",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setAccess(false);
      setAlertData([]);
      loaderFunc();
      return;
    }
  }
  if (Object.keys(data).length === 0) {
    setAlertData([]);
    toast.update(dt, {
      render: "no record found",
      type: "warning",
      isLoading: false,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    loaderFunc();
  } else {
    setAlertData(data.alerts);
    toast.dismiss();
    //    toast.update(dt,{render: "Your Snapshots request has been successful", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    //  });
  }
};  

useEffect(() => {
  if (dataFetchedRef.current) return;
  dataFetchedRef.current = true;
  toast.dismiss();
  getAlert();
  getContact();
  getCloud();
}, []);

function removeFirstAndLastComma(str) {
  if (str.startsWith(",")) {
    str = str.substring(1);
  }
  if (str.endsWith(",")) {
    str = str.substring(0, str.length - 1);
  }
  return str;
}
    

const deleteAlert=async(alertid)=>{
  toast.dismiss();
  var submitBtn=document.querySelector("#deletealert"+alertid+"-btn");
  if(submitBtn){
  
  swal({
    title:"Are you sure?",
    text: "Once deleted, you will not be able to recover this alert rule!",
    icon: "warning",
    buttons: true,
    dangerMode: true,
    })
    .then(async(willDelete) => {
    if(willDelete) {
      var preContent=submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>'; 

      const dt=toast.loading("Alert deleting...");  
  const dnsp=await UseApi('DELETE','alert/'+alertid+'/delete','');
  ////console.log(dnsp);
  if(dnsp){
  submitBtn.classList.remove("disabled");
  submitBtn.innerHTML=preContent;
  if(dnsp.status=='error'){
    toast.update(dt,{render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
  });
  }else if(dnsp.status=='success'){
    toast.update(dt,{render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
  });  
  getAlert();
  }
}  
  } 
    });
  }
}
useEffect(()=>{
  getAlert();
},[props])

const updateAlertByID=async(aid)=>{ 
  const inputArr=['name', 'type', 'compare', 'value', 'duration', 'cloud','contact','status'];
  var submitBtn=document.querySelector("#updatealert"+aid+"-btn");
  var add_name=document.querySelector("#update_name_"+aid);
  var add_type=document.querySelector("#update_type_"+aid);
  var add_compare=document.querySelector("#update_compare_"+aid); 
  var add_value=document.querySelector("#update_value_"+aid); 
  var add_duration=document.querySelector("#update_duration_"+aid); 
  var add_cloud=document.querySelector("#update_cloud_"+aid); 
  var add_contact=document.querySelector("#update_contact_"+aid); 
  var add_status=document.querySelector("#update_status_"+aid);
  var aname=""; 
  var atype="";
  var acompare="";
  var avalue="";
  var aduration="";
  var acloud="";
  var acontact="";
  var astatus=""; 
  if(add_name && add_type && add_compare && add_value && add_duration && add_cloud && add_contact && add_status){
   
     

    if(add_name.value==""){
      const dt=toast.loading("Please wait..."); 
      toast.update(dt,{render: "Name must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });
    return;
    }else{
      aname=add_name.value;
    }
    if(add_type.value==""){
      const dt=toast.loading("Please wait..."); 
      toast.update(dt,{render: "Type must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });
    return;
    }else{
      atype=add_type.value;
    }
    if(add_compare.value==""){
      const dt=toast.loading("Please wait..."); 
      toast.update(dt,{render: "Comparison Operator must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });
    return;
    }else{
      acompare=add_compare.value;
    }

    if(add_value.value==""){
      const dt=toast.loading("Please wait..."); 
      toast.update(dt,{render: "Comparison value must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });
    return;
    }else{
      avalue=add_value.value;
    }

    if(add_duration.value==""){
      const dt=toast.loading("Please wait..."); 
      toast.update(dt,{render: "Duration must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });
    return;
    }else{
      aduration=add_duration.value;
    }
    
    if(add_cloud.value==""){
      const dt=toast.loading("Please wait..."); 
      toast.update(dt,{render: "Please attach Cloud!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });
    return;
    }else{ 
        acloud=add_cloud.value;
    }
    if(add_contact.value==""){
      const dt=toast.loading("Please wait..."); 
      toast.update(dt,{render: "Please attach Contact!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });
    return;
    }else{ 
        acontact=add_contact.value;
    }

    if(add_status.value==""){
      const dt=toast.loading("Please wait..."); 
      toast.update(dt,{render: "Please provide status", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });
    return;
    }else{
      astatus=add_status.value;
    }
  
  if(submitBtn){
    var preContent=submitBtn.innerHTML;
    submitBtn.classList.add("disabled");
    submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>'; 
  const dt=toast.loading("Please wait..."); 
  const responseData=await UsePostData('post','alert/'+aid+'/update',{"name":aname,"ref_type":"cloud", "type":atype, "compare":acompare, "value":avalue,"for":aduration, "contacts":acontact, "status":astatus, "ref_ids":acloud});
  if(responseData){
    submitBtn.classList.remove("disabled");
    submitBtn.innerHTML=preContent;
    if(responseData.status==='error'){
      toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });
    }else if(responseData.status==='success'){
      toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });  
    
    ToogleAddAlert();
    getAlert();
    add_name="";
    add_type="";
    add_compare="";
    add_value="";
    add_duration="";
    add_cloud="";
    add_contact="";
    add_status="";
    
  }
}
    }
  }
  }


const addNewAlert=async()=>{ 
  const inputArr=['name', 'type', 'compare', 'value', 'duration', 'cloud','contact','status'];
  var submitBtn=document.querySelector("#addnewcontact-btn");
  var add_name=document.querySelector("#add_name");
  var add_type=document.querySelector("#add_type");
  var add_compare=document.querySelector("#add_compare"); 
  var add_value=document.querySelector("#add_value"); 
  var add_duration=document.querySelector("#add_duration"); 
  var add_cloud=document.querySelector("#add_cloud"); 
  var add_contact=document.querySelector("#add_contact"); 
  var add_status=document.querySelector("#add_status");
  var aname=""; 
  var atype="";
  var acompare="";
  var avalue="";
  var aduration="";
  var acloud="";
  var acontact="";
  var astatus=""; 
  if(add_name && add_type && add_compare && add_value && add_duration && add_cloud && add_contact && add_status){
   
     

    if(add_name.value==""){
      const dt=toast.loading("Please wait..."); 
      toast.update(dt,{render: "Name must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });
    return;
    }else{
      aname=add_name.value;
    }
    if(add_type.value==""){
      const dt=toast.loading("Please wait..."); 
      toast.update(dt,{render: "Type must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });
    return;
    }else{
      atype=add_type.value;
    }
    if(add_compare.value==""){
      const dt=toast.loading("Please wait..."); 
      toast.update(dt,{render: "Comparison Operator must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });
    return;
    }else{
      acompare=add_compare.value;
    }

    if(add_value.value==""){
      const dt=toast.loading("Please wait..."); 
      toast.update(dt,{render: "Comparison value must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });
    return;
    }else{
      avalue=add_value.value;
    }

    if(add_duration.value==""){
      const dt=toast.loading("Please wait..."); 
      toast.update(dt,{render: "Duration must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });
    return;
    }else{
      aduration=add_duration.value;
    }
    
    if(add_cloud.value==""){
      const dt=toast.loading("Please wait..."); 
      toast.update(dt,{render: "Please attach Cloud!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });
    return;
    }else{ 
        acloud=add_cloud.value;
    }
    if(add_contact.value==""){
      const dt=toast.loading("Please wait..."); 
      toast.update(dt,{render: "Please attach Contact!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });
    return;
    }else{ 
        acontact=add_contact.value;
    }

    if(add_status.value==""){
      const dt=toast.loading("Please wait..."); 
      toast.update(dt,{render: "Please provide status", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });
    return;
    }else{
      astatus=add_status.value;
    }
  
  if(submitBtn){
    var preContent=submitBtn.innerHTML;
    submitBtn.classList.add("disabled");
    submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>'; 
  const dt=toast.loading("Please wait..."); 
  const responseData=await UsePostData('post','alert',{"name":aname,"ref_type":"cloud", "type":atype, "compare":acompare, "value":avalue,"for":aduration, "contacts":acontact, "status":astatus, "ref_ids":acloud});
  if(responseData){
    submitBtn.classList.remove("disabled");
    submitBtn.innerHTML=preContent;
    if(responseData.status==='error'){
      toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });
    }else if(responseData.status==='success'){
      toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });  
    
    ToogleAddAlert();
    getAlert();
    add_name="";
    add_type="";
    add_compare="";
    add_value="";
    add_duration="";
    add_cloud="";
    add_contact="";
    add_status="";
    
  }
}
    }
  }
  }
  const ToogleAddAlert=()=>{
   
    var addAlertSec=document.querySelector("#add_alert_sec");
    var addAlertBtn=document.querySelector("#addalert-btn");
    if(addAlertSec && addAlertBtn){
        addAlertSec.classList.toggle("d-none");
        addAlertBtn.classList.toggle("disabled");
    }
} 
var add_contactTagify = document.querySelector("#add_contacts_alerts");
var add_cloud_alerts = document.querySelector("#add_cloud_alerts");

useEffect(()=>{
  const cloudTagify=new window.Tagify(add_cloud_alerts, {
    whitelist: cloudInfo,
    maxTags: 20,
    dropdown: {
        maxItems: 20,          
        classname: "",  
        enabled: 0,              
        closeOnSelect: false    
    }
  });  
},[cloudInfo])
useEffect(()=>{
const contactTagify=new window.Tagify(add_contactTagify, {
    whitelist: contactData,
    maxTags: 20,
    dropdown: {
        maxItems: 20,          
        classname: "",  
        enabled: 0,              
        closeOnSelect: false    
    }
}); 
 
},[contactData])


function updateNewCloud(aid, e) {
  var addCloudInput = document.querySelector("#update_cloud_"+aid);

  if (e.target.value && addCloudInput) {
    var cloudData = JSON.parse(e.target.value);
    var cloudIds = cloudData.map(item => {
      // Extract numeric portion from the value (assuming it's always in parentheses)
      var match = item.value.match(/\((\d+)\)/);
      return match ? match[1] : null;
    }).filter(Boolean); // Filter out null values

    // Concatenate the cloud IDs into a comma-separated string
    var concatenatedCloudIds = cloudIds.join(',');

    // Set the concatenated string as the value of the add_cloud input
    addCloudInput.value = concatenatedCloudIds;

    console.log(concatenatedCloudIds);
  }
}


function updateNewContact(aid,e) {
  var addCloudInput = document.querySelector("#update_contact_"+aid);

  if (e.target.value && addCloudInput) {
    var cloudData = JSON.parse(e.target.value);
    var cloudIds = cloudData.map(item => {
      // Extract numeric portion from the value (assuming it's always in parentheses)
      var match = item.value.match(/\((\d+)\)/);
      return match ? match[1] : null;
    }).filter(Boolean); // Filter out null values

    // Concatenate the cloud IDs into a comma-separated string
    var concatenatedCloudIds = cloudIds.join(',');

    // Set the concatenated string as the value of the add_cloud input
    addCloudInput.value = concatenatedCloudIds;

    console.log(concatenatedCloudIds);
  }
} 


function addNewCloud(e) {
  var addCloudInput = document.querySelector("#add_cloud");

  if (e.target.value && addCloudInput) {
    var cloudData = JSON.parse(e.target.value);
    var cloudIds = cloudData.map(item => {
      // Extract numeric portion from the value (assuming it's always in parentheses)
      var match = item.value.match(/\((\d+)\)/);
      return match ? match[1] : null;
    }).filter(Boolean); // Filter out null values

    // Concatenate the cloud IDs into a comma-separated string
    var concatenatedCloudIds = cloudIds.join(',');

    // Set the concatenated string as the value of the add_cloud input
    addCloudInput.value = concatenatedCloudIds;

    console.log(concatenatedCloudIds);
  }
}


function addNewContact(e) {
  var addCloudInput = document.querySelector("#add_contact");

  if (e.target.value && addCloudInput) {
    var cloudData = JSON.parse(e.target.value);
    var cloudIds = cloudData.map(item => {
      // Extract numeric portion from the value (assuming it's always in parentheses)
      var match = item.value.match(/\((\d+)\)/);
      return match ? match[1] : null;
    }).filter(Boolean); // Filter out null values

    // Concatenate the cloud IDs into a comma-separated string
    var concatenatedCloudIds = cloudIds.join(',');

    // Set the concatenated string as the value of the add_cloud input
    addCloudInput.value = concatenatedCloudIds;

    console.log(concatenatedCloudIds);
  }
} 

useEffect(()=>{
  // console.log(cloudInfo);
  // console.log(alertData);
if(cloudInfo && alertData){
  alertData.map((value, index)=>{
    var update_cloud_alerts= document.querySelector("#update_cloud_alerts_"+value.id);
    var update_contacts_alerts= document.querySelector("#update_contacts_alerts_"+value.id);
    var add_cloud=document.querySelector("#update_cloud_"+value.id); 
    var add_contact=document.querySelector("#update_contact_"+value.id); 
    if(add_cloud && add_contact){
       add_cloud.value=value.ref_ids;
       add_contact.value=value.contacts;
    }
    if(update_cloud_alerts && update_contacts_alerts){
     // Contact START
     var alertContactArray = value.contacts.split(',');
      
     // Filter contactData based on alertIdsArray
     var filteredContactInfo = contactData.filter(item => {
       
         var match = item.match(/\((\d+)\)/);
         return match && alertContactArray.includes(match[1]);
     });

     // Create a string from the filtered data
     var contactString = filteredContactInfo.map(item => item).join(',');
      update_contacts_alerts.value=contactString;

     const contactTagify=new window.Tagify(update_contacts_alerts, {
       whitelist: contactData,
       maxTags: 20,
       dropdown: {
           maxItems: 20,          
           classname: "",  
           enabled: 0,              
           closeOnSelect: false    
       }
     }); 
      
      // CLOUD START
      var alertIdsArray = value.ref_ids.split(',');
      
      // Filter cloudInfo based on alertIdsArray
      var filteredCloudInfo = cloudInfo.filter(item => {
        
          var match = item.match(/\((\d+)\)/);
          return match && alertIdsArray.includes(match[1]);
      });
 
      // Create a string from the filtered data
      var cloudString = filteredCloudInfo.map(item => item).join(',');
      console.log(cloudString);
      update_cloud_alerts.value=cloudString;

      const cloudTagify=new window.Tagify(update_cloud_alerts, {
        whitelist: cloudInfo,
        maxTags: 20,
        dropdown: {
            maxItems: 20,          
            classname: "",  
            enabled: 0,              
            closeOnSelect: false    
        }
      }); 
    }

  })
}
},[alertData, cloudInfo]);

function formatTypeName(inputString) {
  // Split the string by underscores
  var parts = inputString.split('_');

  // Capitalize the first letter of each part and join with a space
  var resultString = parts.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');

  return resultString;
}
    return (
        <> 
      <div id="" className="card">
           {/*begin::Card header*/}
      <div
        className="card-header border-0 cursor-pointer"
        role="button"
        data-bs-toggle="collapse"
        data-bs-target="#kt_account_deactivate"
        aria-expanded="true"
        aria-controls="kt_account_deactivate"
      >
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">Alert Rules</h3>
        </div>
        <div className="card-toolbar">
               
              <Contact />
              <a 
                            id="addalert-btn"
                            onClick={ToogleAddAlert} 
                            className="btn btn-primary btn-sm"
                            style={{ marginRight: 20 }}
                          >
                            {/*begin::Svg Icon | path: assets/media/icons/duotune/files/fil017.svg*/}
                            {/*begin::Svg Icon | path: assets/media/icons/duotune/files/fil013.svg*/}
                            <span className="svg-icon svg-icon-muted svg-icon-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  opacity="0.3"
                                  d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z"
                                  fill="black"
                                />
                                <path
                                  d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.2C9.7 3 10.2 3.20001 10.4 3.60001ZM16 12H13V9C13 8.4 12.6 8 12 8C11.4 8 11 8.4 11 9V12H8C7.4 12 7 12.4 7 13C7 13.6 7.4 14 8 14H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
                                  fill="black"
                                />
                                <path
                                  opacity="0.3"
                                  d="M11 14H8C7.4 14 7 13.6 7 13C7 12.4 7.4 12 8 12H11V14ZM16 12H13V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
                                  fill="black"
                                />
                              </svg>
                            </span>
                            {/*end::Svg Icon*/}
                            {/*end::Svg Icon*/}
                            Add New
                          </a>
            </div>
      </div>
      {/*end::Card header*/}
       {/*begin::Table*/} 
       <div
                id="kt_table_users_wrapper"
                className="dataTables_wrapper dt-bootstrap4 no-footer"
              >
                <div className="table-responsive">
                <table
                    className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9 d-none" id="add_alert_sec">
                    {/*begin::Table head*/}
                    <thead className="bg-secondary">
                      {/*begin::Table row*/}
                      <tr className="text-start fw-bolder fs-7 text-uppercase gs-0">
                      <th className="w-250px text-center" style={{paddingLeft:"20px"}}>Name </th>
                        <th className="w-200px text-center" >Type </th>
                        <th className="w-150px">Comparison </th>
                        <th className="w-100px text-center">Value</th>
                        <th className="w-150px text-center">Duration</th>
                        <th className="w-100px text-center">Contacts</th>
                        <th className="w-100px text-center">Cloud</th> 
                        <th className="w-100px text-center" > <span
                             
                             id="addalert-btn"
                             onClick={ToogleAddAlert}
                              className="text-danger"
                             style={{ cursor: "pointer" }}
                           > 
                            {/*begin::Svg Icon | path: assets/media/icons/duotune/arrows/arr015.svg*/}
  <span className="svg-icon svg-icon-danger svg-icon-2">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        opacity="0.3"
        d="M12 10.6L14.8 7.8C15.2 7.4 15.8 7.4 16.2 7.8C16.6 8.2 16.6 8.80002 16.2 9.20002L13.4 12L12 10.6ZM10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 8.99999 16.4 9.19999 16.2L12 13.4L10.6 12Z"
        fill="black"
      />
      <path
        d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM13.4 12L16.2 9.20001C16.6 8.80001 16.6 8.19999 16.2 7.79999C15.8 7.39999 15.2 7.39999 14.8 7.79999L12 10.6L9.2 7.79999C8.8 7.39999 8.2 7.39999 7.8 7.79999C7.4 8.19999 7.4 8.80001 7.8 9.20001L10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 9 16.4 9.2 16.2L12 13.4L14.8 16.2C15 16.4 15.3 16.5 15.5 16.5C15.7 16.5 16 16.4 16.2 16.2C16.6 15.8 16.6 15.2 16.2 14.8L13.4 12Z"
        fill="black"
      />
    </svg>
  </span>
  {/*end::Svg Icon*/}
                           </span></th>
                      </tr>
                      {/*end::Table row*/}
                    </thead>
                    {/*end::Table head*/}
                    {/*begin::Table body*/}
                    <tbody className="text-gray-600" id="con-data">
                      <tr className="openCustomer" >
                      <td className="text-center">
                          <input
                            type="text" 
                            name="name"
                            id="add_name"
                            defaultValue=""
                            placeholder="Enter name"
                            className="form-control form-control-sm" 
                          />
                        </td>
                        <td className="text-center">
                        <select className="form-select form-select-sm" id="add_type" name="type" >
                        <optgroup  label="System">
                        <option value='nodata'>No Data</option>
                        <option value='cpu'>CPU Usage %</option> 
                    </optgroup>

                    <optgroup label="Disk">
                         
                        <option value='disk_write'> Disk Write MB/s</option>
                        <option value='disk_read'> Disk Read MB/s</option>
                     </optgroup>

                    <optgroup label="Network"> 
                      <option value='public_outbound_bandwidth'> Public Outbound Bandwidth</option>
                      <option value='public_inbound_bandwidth'> Public Inbound Bandwidth</option>
                      <option value='private_outbound_bandwidth'> Private Outbound Bandwidth</option>
                      <option value='private_inbound_bandwidth'> Private Inbound Bandwidth</option>
                    </optgroup>
                          </select> 
                         
                        </td>
                        <td className="text-center">
                        <select className='form-select form-select-sm' id="add_compare" name="compare">
                        <option value="below">below</option>
                        <option value="above">above</option>
                        <option value="equalto">equalto</option>
                         
                      </select>
                        </td>
                        <td className="text-center">
                          <input
                            type="text" 
                            name="value"
                            id="add_value"
                            defaultValue=""
                            className="form-control form-control-sm" 
                          />
                        </td>
                        <td className="text-center">
                        <select className='form-select form-select-sm' id="add_duration" name="for">
                        <option value="5m">5 minutes</option>
                        <option value="10m">10 minutes</option>
                        <option value="30m">30 minutes</option>
                        <option value="1h">1 hour</option>
                        <option value="3h">3 hours</option>
                        <option value="6h">6 hours</option>
                        <option value="12h">12 hours</option>
                        <option value="24h">24 hours</option>
                         
                      </select>
                        </td>
                        <td className="text-center"> 
                            <input class="form-control form-control-sm" value="" onChange={(e)=>addNewCloud(e)}  id="add_cloud_alerts"/>
                            <input type="hidden" name="cloud" id="add_cloud" />
                         </td>
                        <td className="text-center">
                          <input type='hidden' id="add_status" defaultValue="Active" name="status" />
                          <input type='hidden' id="ref_type" defaultValue="cloud" name="ref_type" />
                          <input class="form-control form-control-sm" value="" onChange={(e)=>addNewContact(e)} id="add_contacts_alerts"/>
                          <input type="hidden" name="contact" id="add_contact" />
                        </td>
                        <td className="text-center"> 
                          <a
                            id="addnewcontact-btn"
                            onClick={(e)=>addNewAlert()}
                             className="btn btn-primary btn-sm sphide"
                            style={{ marginRight: 20 }}
                          >  
                           Add Alert  </a> 
                          
                        </td> 
                      </tr>
                      
                    </tbody>
                    {/*end::Table body*/}
                  </table>
                  <table
                    className="table table-row-dashed border table-row-bordered gy-5 gs-7"
                    id="page_list"
                  >
                    {/*begin::Table head*/}
                    <thead className="bg-secondary">
                      {/*begin::Table row*/}
                      <tr className="text-start fw-bolder fs-7 text-uppercase gs-0">
                      <th className="w-250px text-center" style={{paddingLeft:"20px"}}>Name </th>
                        <th className="w-50px text-center" >Type </th>
                        <th className="w-150px">Comparison </th>
                        <th className="w-100px text-center">Value</th>
                        <th className="w-250px text-center">Duration</th>
                        <th className="w-100px text-center">Contacts</th>
                        <th className="w-100px text-center">Cloud</th>
                        <th className="w-200px text-center">Status</th>
                        <th className="w-100px sorting" />
                      </tr>
                      {/*end::Table row*/}
                    </thead>
                    {/*end::Table head*/}
                    {/*begin::Table body*/}
                    <tbody className="text-gray-600" id="con-data">
                      {alertData!=null?(<>
                       {alertData.map((alertValue,index)=>(<>
                        <tr className="openCustomer" data-id="">
                        {" "}
                        <td className="text-center">
                          <input
                            type="text" 
                            name="name"
                            id={"update_name_"+alertValue.id}
                            defaultValue={alertValue.name}
                            className="form-control form-control-sm" 
                          />
                        </td>
                        <td className="text-center"> 
                        <div className="align-items-center"> 
                         <div className=" justify-content-center flex-column"> 
                           <a className="text-gray-700 fw-bold text-hover-primary fs-6 mt-4">{formatTypeName(alertValue.type)}</a>
                           <input type='hidden' defaultValue={alertValue.type} id={"update_type_"+alertValue.id} />
                         </div>
                        </div>
                        </td> 
                        <td className="text-center">
                        <select className='form-select form-select-sm' defaultValue={alertValue.compare} id={"update_compare_"+alertValue.id}  tabIndex={-1} aria-hidden="true">
                        {comparisonArr && comparisonArr.map((value2,index)=>(<>
                          {alertValue.compare==value2?(<>
                            <option value={value2} selected={true}>{value2}</option>
                          </>):(<>
                            <option value={value2}>{value2}</option>
                          </>)}
                          
                          </>
                        ))}
                      </select>
                        </td> 
                        <td className="text-center">
                          <input
                            type="text" 
                            id={"update_value_"+alertValue.id}
                            defaultValue={alertValue.value}
                            className="form-control form-control-sm text-center" 
                          />
                        </td> 
                        <td className="text-center">
                        <select className='form-select form-select-sm' defaultValue={alertValue.for} id={"update_duration_"+alertValue.id}  tabIndex={-1} aria-hidden="true">
                        <option value="5m">5 minutes</option>
                        <option value="10m">10 minutes</option>
                        <option value="30m">30 minutes</option>
                        <option value="1h">1 hour</option>
                        <option value="3h">3 hours</option>
                        <option value="6h">6 hours</option>
                        <option value="12h">12 hours</option>
                        <option value="24h">24 hours</option>
                      </select>
                        </td> 
                        <td className="text-center"> 
                            <input class="form-control form-control-sm" value={alertValue.ref_ids} onChange={(e)=>updateNewCloud(alertValue.id, e)}  id={"update_cloud_alerts_"+alertValue.id}/>
                            <input type="hidden" name="cloud" id={"update_cloud_"+alertValue.id} />
                         </td>
                        <td className="text-center"> 
                          <input type='hidden' id="ref_type" defaultValue="cloud" name="ref_type" />
                          <input class="form-control form-control-sm" value={alertValue.contacts} onChange={(e)=>updateNewContact(alertValue.id, e)} id={"update_contacts_alerts_"+alertValue.id}/>
                          <input type="hidden" name="contact" id={"update_contact_"+alertValue.id} />
                        </td>
                        <td className="text-center">
                          <select
                            className="form-select form-select-sm"
                            id={"update_status_"+alertValue.id} 
                            defaultValue={alertValue.id}
                          >
                            {alertValue.status=="1"?(<>
                            <option value={1} selected={true}>Active</option>
                            <option value={0}>Inactive </option>
                            </>):(<>
                            <option value={1}>Active</option>
                            <option value={0} selected={true}>Inactive </option>
                            </>)}
                          </select>
                        </td> 
                        <td className="text-center sphide">
                          <a 
                             
                            id={"updatealert"+alertValue.id+"-btn"}
                            onClick={(e)=>updateAlertByID(alertValue.id)}
                             className="btn btn-sm btn-success sphide"
                            style={{ marginRight: 20 }}
                          >
                             <span className="svg-icon svg-icon-muted svg-icon-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                {" "}
                                <path
                                  opacity="0.3"
                                  d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z"
                                  fill="black"
                                />{" "}
                                <path
                                  opacity="0.3"
                                  d="M13 14.4V9C13 8.4 12.6 8 12 8C11.4 8 11 8.4 11 9V14.4H13Z"
                                  fill="black"
                                />{" "}
                                <path
                                  d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.20001C9.70001 3 10.2 3.20001 10.4 3.60001ZM13 14.4V9C13 8.4 12.6 8 12 8C11.4 8 11 8.4 11 9V14.4H8L11.3 17.7C11.7 18.1 12.3 18.1 12.7 17.7L16 14.4H13Z"
                                  fill="black"
                                />{" "}
                              </svg>
                            </span>{" "}
                            Update
                          </a>{" "}
                          <a
                             
                            id={"deletealert"+alertValue.id+"-btn"}
                            onClick={(e)=>deleteAlert(alertValue.id)}
                             className="btn btn-icon btn-light-danger"
                          >
                            <span className="svg-icon svg-icon-muted svg-icon-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                {" "}
                                <path
                                  d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                  fill="black"
                                />{" "}
                                <path
                                  opacity="0.5"
                                  d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                  fill="black"
                                />{" "}
                                <path
                                  opacity="0.5"
                                  d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                  fill="black"
                                />{" "}
                              </svg>
                            </span>{" "}
                           </a>
                        </td>{" "}
                      </tr>
                        </>))}
                      </>):(<> <tr><td colSpan={12}><TableLoader /></td></tr></>)}
                     
                     </tbody>
                     </table>
                   
                  
                </div>
              </div>
              {/*end::Table*/}
        </div> 
        </>
    );
}

export default AlertRules;
