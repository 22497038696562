import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import UseDevApi from "../../../../CustomHooks/useDevApi";
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";
import AccessDenied from "../../AccessDenied";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../Loaders/TableLoader";
import "./custom.css";
import CustomDoc from "../../../../CustomHooks/CustomDoc";
import ProductsUrl from "../../Docs/Docsdata";
export default function DatabaseCluster() {
  const [dataFetched, setDataFetched] = useState(false);
  const [access, setAccess] = useState(true);
  const [pageCount, setPageCount] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [itemOffset, setItemOffset] = useState(0);
  const [clusterData, setClusterData] = useState(null);
  const [activePage, setActivePage] = useState(0);
  const [dummyClusterData, setDummyClusterData] = useState(null);
  const [databaseInfo, setDatabaseInfo] = useState([])
  const [html, setHtml] = useState(`<span class="fw-bold fs-6 text-gray-700"><span class="status-circle"></span>
  Active
</span>`)
  const dataFetchedRef = useRef(false);
  function datbaseName(str) {
    // Split the string into an array using the delimiter "-"
    var arr = str.split("-");

    // Return the element at the 0th index
    return arr[0].toLowerCase();
  }

  function formatMessageTime(dateString) {
    const date = new Date(dateString);
    const currentDate = new Date();
    const timeDiff = Math.abs(currentDate.getTime() - date.getTime());
    const minutes = Math.floor(timeDiff / (1000 * 60));
    const hours = Math.floor(minutes / 60);

    if (minutes <= 1) {
      return 'Just now';
    } else if (minutes < 30) {
      return `${minutes} mins ago`;
    } else if (minutes < 90) {
      return 'An hour ago';
    } else if (hours < 24) {
      const formattedTime = formatTime(date);
      return `Today at ${formattedTime}`;
    } else if (hours < 48) {
      const formattedTime = formatTime(date);
      return `Yesterday at ${formattedTime}`;
    } else {
      const formattedDate = formatDate(date);
      const formattedTime = formatTime(date);
      return `${formattedDate} at ${formattedTime}`;
    }
  }

  function formatTime(date) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}${period}`;
  }

  function formatDate(date) {
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
  }
  const loaderFunc = () => {
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if (loader && no_record) {
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");

      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
  }

  const getDatabases = async () => {
    const dt = toast
    const data = await UseApi('get', 'databases', '');
    if (data) {
      if (data.status == "error" && data.message == "Permission denied.") {
        setAccess(false);
        setDataFetched(true)
        setClusterData([]);
        setDummyClusterData([])
        toast.update(dt, {
          render: data.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
      } else if (data.status == "error") {
        setDataFetched(true)
        setClusterData([]);
        setDummyClusterData([])
        toast.update(dt, {
          render: "no record found", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
      }
      if (data.databases) {
        if (data.databases.length == 0) {
          setDataFetched(true)
          setClusterData([]);
          setDummyClusterData([])
          toast.update(dt, {
            render: "no record found", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        } else {
          setDataFetched(true)
          // getActiveAction(data.databases)
          // getActiveAction()
          setDatabaseInfo([...data.databases])
          setClusterData(data.databases);
          setDummyClusterData(data.databases)
          toast.dismiss();
        }
      }
    }
  }

  const getDatabasesClusters = async () => {
    const dt = toast.loading("Databases loading...");
    const data = await UseDevApi('get', 'dbcluster', '');
    if (data) {
      setDataFetched(true);
    }
    if (data.status == "error") {
      setClusterData([]);
      toast.update(dt, {
        render: "no record found", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
    } else {
      setClusterData(data.db);
      toast.dismiss();

    }
  }

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
  }, []);

  const isActionCompleted = async (cloudid) => {
    // const actionData = await UseApi("get", `cloud/${cloudid}/actions`, "");
    const actionData = await UseApi('get', 'databases', "")
    console.log(actionData);
    // return actionData && actionData.actions[0] && actionData.actions[0].status === "Completed";
  };

  const updateCloudButton = (cloudid, isLoading) => {
    const cloud_btn = document.querySelector(`#cloud-btn-${cloudid}`);
    if (cloud_btn) {
      cloud_btn.innerHTML = isLoading
        ? '<span class="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>'
        : html; // Note: You need to define 'html' before using it here
    }
  };

  const checkAndRefreshActions = async (nodes, type) => {
    const promises = nodes.map(async (value) => {
      if (value.cloudid) {
        try {
          const result = await isActionCompleted(value.cloudid);
          if (result) {
            updateCloudButton(value.cloudid, false);
            return false; // One action not completed
          } else {
            updateCloudButton(value.cloudid, true);
            return true; // All actions completed
          }
        } catch (error) {
          return true; // Treat error as all actions completed
        }
      }
    });

    const results = await Promise.all(promises);
    return !results.includes(false); // If any action is not completed, return false
  };

  const getActiveAction = async () => {
    if (clusterData && clusterData.length > 0) {
      const promises = clusterData.map(async (key, index) => {
        const allPrimaryActionCompleted = await checkAndRefreshActions(key.nodes.primary, "primary");
        const allReplicaActionCompleted = await checkAndRefreshActions(key.nodes.readonly, "readonly");
        const allReadonlyActionCompleted = await checkAndRefreshActions(key.nodes.replica, "replica");
        if (!allPrimaryActionCompleted || !allReplicaActionCompleted || !allReadonlyActionCompleted) {
          return true; // Indicate that further execution should be stopped
        }

        return false;
      });

      const results = await Promise.all(promises);
      if (results.some(result => result)) {
        console.log("Not all actions completed. Stopping further execution.");
        return;
      }
    }
  }
  // const getActiveAction = async () => {
  // In this Function we check cloud Action Nd this is important function for future
  //   if (clusterData && clusterData.length > 0) {
  //     const promises = clusterData.map(async (key, index) => {
  //       const allPrimaryActionCompleted = await checkAndRefreshActions(key.nodes.primary, "primary");
  //       const allReplicaActionCompleted = await checkAndRefreshActions(key.nodes.readonly, "readonly");
  //       const allReadonlyActionCompleted = await checkAndRefreshActions(key.nodes.replica, "replica");
  //       if (!allPrimaryActionCompleted || !allReplicaActionCompleted || !allReadonlyActionCompleted) {
  //         return true; // Indicate that further execution should be stopped
  //       }

  //       return false;
  //     });

  //     const results = await Promise.all(promises);
  //     if (results.some(result => result)) {
  //       console.log("Not all actions completed. Stopping further execution.");
  //       return;
  //     }
  //   }
  // }


  useEffect(() => {
    getDatabases();
  }, [])
  useEffect(() => {
    if (clusterData && clusterData.length > 0) {
      const hasPendingApp = clusterData.some(item => {
        return Object.values(item.nodes).some(nodeArray => {
          if (nodeArray.length > 0) {
            return nodeArray.some(node => node.app_status === "Pending");
          } else {
            return true
          }
        });
      });
      console.log(hasPendingApp, "hasPendingApp");
      if (!hasPendingApp) {
        setTimeout(() => {
          getDatabases();
        }, 9000)
      }
    }
  }, [clusterData])

  function convertTimestamp(timestamp) {
    if (timestamp) {
      const date = new Date(timestamp);
      // Format the date
      const formattedDate = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short',
        timeZone: 'UTC'
      }).format(date);

      return formattedDate.replace('GMT', '(UTC)');
    }
  }
  function ProductInfo({ productdata }) {
    return (
      <>
        {productdata != null
          ? Object.keys(productdata).length != 0 ?
            (productdata && productdata.map((value, index) =>
            (<>
              {value.nodes && value.nodes.primary ? (<>
                <tr>
                  <td colspan={2} className="text-start ps-9" style={{ paddingLeft: "20px" }}>
                    <div className="d-flex flex-stack">
                      {/*begin::Symbol*/}
                      <div className="symbol symbol-40px me-4">
                        <div className="symbol-label fs-2 fw-bold bg-light-primary text-inverse-danger">
                          <img src={"/assets/media/databases/" + value.engine + ".svg"} /> 
                        </div>
                      </div>
                      {/*end::Symbol*/}
                      {/*begin::Section*/}
                      <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                        {/*begin:Author*/}
                        <div className="flex-grow-1 me-2">
                          <Link to={"/database-cluster/" + value.id + "/" + value?.nodes?.primary[0]?.cloudid}>
                            <a style={{ cursor: "pointer" }} className="text-dark fw-bold text-hover-primary fs-6 sphide">
                              {value.cluster_name}
                            </a>
                          </Link>

                          <span className="text-muted fw-bold text-muted d-block fs-7">
                            {value.engine == "pg" ? ("PostgreSQL") : (value.engine).charAt(0).toUpperCase() + value.engine.slice(1)}({value.version})
                          </span>
                        </div>
                        {/*end:Author*/}
                      </div>
                      {/*end::Section*/}
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="d-flex align-items-center sphide ms-10 mt-1">

                      <div className="symbol symbol-30px me-5">

                        {value?.nodes?.primary[0]?.dclocation ? (<>
                          <img src={"/img/flag_" + value?.nodes?.primary[0]?.dclocation?.dccc + ".png"} />
                        </>) : ('')}

                      </div>
                      <div className="d-flex justify-content-start flex-column">

                        <a className="text-gray-700 fw-bold text-hover-primary fs-6 text-start">
                          {value?.nodes?.primary[0]?.dclocation ? (<>
                            {value?.nodes?.primary[0]?.dclocation.location}
                          </>) : ('')}
                        </a>
                        <span className="text-muted fw-bold text-muted d-block fs-7 text-start">
                          {value?.nodes?.primary[0]?.dclocation ? (<>
                            {value?.nodes?.primary[0]?.dclocation.country}
                          </>) : ('')}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="mb-4 text-center fw-bold">
                      {value.replica_node_count > 0 ? (<>
                        <span className="me-10">Primary +{parseInt(value.replica_node_count)}</span>
                      </>) : (<>
                        <span className="me-10">Primary</span>
                      </>)}
                      <span
                        className="badge badge-success d-none"
                        style={{ cursor: "pointer" }}
                      >
                        <a id="checknsundefined-btn">HA </a>
                      </span>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className=" flex-column w-100">
                      <div className=" flex-stack mt-0.5">
                        <span className=" me-2 fs-6 fw-bold">
                          {value?.nodes?.primary[0]?.app_status == "Installed" ? (<>
                            <span className="fw-bold fs-6 text-gray-700" id={`cloud-btn-${value?.nodes?.primary[0]?.cloudid}`}><span className="status-circle" style={{ top: 3 }} ></span>
                              Active
                            </span>
                          </>) : (<>
                            <span class="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2" style={{ top: 3 }} ></span></span>
                            {/* <span className="fw-bold fs-6 text-gray-700" id={`cloud-btn-${value?.nodes?.primary[0]?.cloudid}`}><span className="status-circle-warning"></span>
                          Pending
                        </span> */}
                          </>)}
                        </span>
                        {/* <span className="status-circle" style={{ top: 4 }} />
                          Active
                        </span> */}
                      </div>
                    </div>
                    {/* <div className=" mb-4 sphide">

                    </div> */}
                  </td>
                  <td className="text-center">
                    <span className="fs-7 fw-bold mt-1">
                      {(value.created_at)}
                    </span>
                  </td>
                  <td className="text-center">
                    <div className="d-flex justify-content-end flex-shrink-0">

                      {/* {value?.nodes?.primary[0]?.app_status == "Installed" ? (<> */}
                      {/* {value.status == "Active" ? (<> */}
                      <Link to={"/database-cluster/" + value.id + "/" + value?.nodes?.primary[0]?.cloudid}>
                        <a className="btn btn-primary btn-active-light-primary btn-sm sphide">
                          Manage
                          <span className="svg-icon ms-2 svg-icon-3">

                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                            >

                              <rect
                                opacity="0.5"
                                x={18}
                                y={13}
                                width={13}
                                height={2}
                                rx={1}
                                transform="rotate(-180 18 13)"
                                fill="black"
                              />
                              <path
                                d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                fill="black"
                              />
                            </svg>
                          </span>
                        </a>
                      </Link>
                      {/* </>) : (<>
                        <a className="btn btn-primary btn-active-light-primary btn-sm me-1 me-5">

                          Manage
                          <span className="svg-icon ms-2 svg-icon-3">

                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                            >

                              <rect
                                opacity="0.5"
                                x={18}
                                y={13}
                                width={13}
                                height={2}
                                rx={1}
                                transform="rotate(-180 18 13)"
                                fill="black"
                              />
                              <path
                                d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                fill="black"
                              />
                            </svg>
                          </span>
                        </a>
                      </>)} */}
                    </div>
                  </td>
                </tr>
              </>) : ('')}
              {value.engine != "kafka" && <>
                {value.nodes && value.nodes.replica ? (<>
                  {value.nodes.replica.map((value2, index2) => (<>
                    <tr>
                      <td colspan={2} className="text-end">

                        <div className="d-flex  align-items-center ps-3">

                          <span className="svg-icon svg-icon-muted svg-icon-2hx me-3">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M9 18H13.4V16H9C7.9 16 7 15.1 7 14V3C7 2.4 6.6 2 6 2C5.4 2 5 2.4 5 3V14C5 16.2 6.8 18 9 18Z"
                                fill="black"
                              />
                              <path
                                opacity="0.3"
                                d="M13.4 12L17.7 16.3C18.1 16.7 18.1 17.3 17.7 17.7L13.4 22V12Z"
                                fill="black"
                              />
                            </svg>
                          </span>
                          {/*begin::Symbol*/}
                          <div className="symbol symbol-40px me-4">
                            <div className="symbol-label fs-2 fw-bold bg-light-primary text-inverse-danger">
                              <svg
                                width={24}
                                height={24}
                                viewBox="0 0 84 84"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g
                                  id="DBaaS-Icon-1C-BLK"
                                  transform="translate(0.189181, -0.000000)"
                                  fill="#494b74"
                                  fillRule="nonzero"
                                >
                                  <path d="m66.7 48.9c-7.7 2.1-17.7 3.3-28.4 3.3s-20.8-1.2-28.4-3.3c-2.2-.6-4.2-1.3-5.9-2v22c0 5.2 15.3 9.4 34.3 9.4s34.3-4.2 34.3-9.4v-22c-1.8.8-3.7 1.4-5.9 2zm-6.3 19.8c-2.6 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6 4.6 2.1 4.6 4.6-2 4.6-4.6 4.6z" />
                                  <path d="m38.3 5.1c-19 0-34.3 4.2-34.3 9.4v25.4c3.6 2.4 14.8 6.1 34.3 6.1 19.4 0 30.7-3.7 34.3-6.1v-25.4c-.1-5.2-15.4-9.4-34.3-9.4zm22.1 31.7c-2.6 0-4.6-2.1-4.6-4.6 0-2.6 2.1-4.6 4.6-4.6s4.6 2.1 4.6 4.6c.1 2.5-2 4.6-4.6 4.6zm-22.1-14.3c-20.9 0-32.3-4.9-32.3-7.4s11.4-7.4 32.3-7.4 32.3 4.9 32.3 7.4-11.5 7.4-32.3 7.4z" />
                                </g>
                              </svg>
                            </div>
                          </div>
                          {/*end::Symbol*/}
                          <div className="d-flex justify-content-start flex-column">
                            <Link to={"/database-cluster/" + value.id + "/" + value2.cloudid}>
                              <a style={{ cursor: "pointer" }} className="text-dark fw-bold text-hover-primary fs-6 sphide">
                                {value2.hostname}
                              </a>
                            </Link>
                          </div>
                        </div>
                      </td>
                      <td className="text-center">
                        <div className="d-flex align-items-center sphide ms-10 mt-1">

                          <div className="symbol symbol-30px me-5">

                            {value2.dclocation ? (<>
                              <img src={"/img/flag_" + value2.dclocation.dccc + ".png"} />
                            </>) : ('')}

                          </div>
                          <div className="d-flex justify-content-start flex-column">

                            <a className="text-gray-700 fw-bold text-hover-primary fs-6 text-start">
                              {value2.dclocation ? (<>
                                {value2.dclocation.location}
                              </>) : ('')}
                            </a>
                            <span className="text-muted fw-bold text-muted d-block fs-7 text-start">
                              {value2.dclocation ? (<>
                                {value2.dclocation.country}
                              </>) : ('')}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className="text-center">
                        <div className="mb-5 text-center">
                          <span className="me-10 sphide fw-bold">Replica</span>
                        </div>
                      </td>
                      <td className="text-center">
                        <div className=" flex-column w-100">
                          <div className=" flex-stack mt-0.5">
                            <span className=" me-2 fs-6 fw-bold">
                              {value?.nodes?.primary[0]?.app_status == "Installed" ? (<>
                                <span className="fw-bold fs-6 text-gray-700" id={`cloud-btn-${value2.cloudid}`}><span className="status-circle" style={{ top: 3 }} ></span>
                                  Active
                                </span>
                              </>) : (<>
                                <span class="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2" style={{ top: 3 }} ></span></span>
                              </>)}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className="text-center">
                        <span className="fs-7 fw-bold mt-1">
                          {(value2.created_at)}
                        </span>
                      </td>
                      <td className="text-center">
                        <div className="d-flex justify-content-end flex-shrink-0">

                          <Link to={"/database-cluster/" + value.id + "/" + value2.cloudid}>
                            <a className="btn btn-primary btn-active-light-primary btn-sm">

                              Manage
                              <span className="svg-icon ms-2 svg-icon-3">

                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >

                                  <rect
                                    opacity="0.5"
                                    x={18}
                                    y={13}
                                    width={13}
                                    height={2}
                                    rx={1}
                                    transform="rotate(-180 18 13)"
                                    fill="black"
                                  />
                                  <path
                                    d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                    fill="black"
                                  />
                                </svg>
                              </span>
                            </a>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  </>))}
                </>) : ('')}
                {value.nodes && value.nodes.readonly ? (<>
                  {value.nodes.readonly.map((value2, index2) => (<>
                    <tr class={value.nodes.readonly.length == index2 + 1 ? ('tableTrBottomBorder') : ('')}>

                      <td colspan={2} className="text-end">

                        <div className="d-flex  align-items-center ps-3">

                          <span className="svg-icon svg-icon-muted svg-icon-2hx me-3">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M9 18H13.4V16H9C7.9 16 7 15.1 7 14V3C7 2.4 6.6 2 6 2C5.4 2 5 2.4 5 3V14C5 16.2 6.8 18 9 18Z"
                                fill="black"
                              />
                              <path
                                opacity="0.3"
                                d="M13.4 12L17.7 16.3C18.1 16.7 18.1 17.3 17.7 17.7L13.4 22V12Z"
                                fill="black"
                              />
                            </svg>
                          </span>
                          {/*begin::Symbol*/}
                          <div className="symbol symbol-40px me-4">
                            <div className="symbol-label fs-2 fw-bold bg-light-primary text-inverse-danger">
                              <svg
                                width={24}
                                height={24}
                                viewBox="0 0 84 84"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g
                                  id="DBaaS-Icon-1C-BLK"
                                  transform="translate(0.189181, -0.000000)"
                                  fill="#494b74"
                                  fillRule="nonzero"
                                >
                                  <path d="m66.7 48.9c-7.7 2.1-17.7 3.3-28.4 3.3s-20.8-1.2-28.4-3.3c-2.2-.6-4.2-1.3-5.9-2v22c0 5.2 15.3 9.4 34.3 9.4s34.3-4.2 34.3-9.4v-22c-1.8.8-3.7 1.4-5.9 2zm-6.3 19.8c-2.6 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6 4.6 2.1 4.6 4.6-2 4.6-4.6 4.6z" />
                                  <path d="m38.3 5.1c-19 0-34.3 4.2-34.3 9.4v25.4c3.6 2.4 14.8 6.1 34.3 6.1 19.4 0 30.7-3.7 34.3-6.1v-25.4c-.1-5.2-15.4-9.4-34.3-9.4zm22.1 31.7c-2.6 0-4.6-2.1-4.6-4.6 0-2.6 2.1-4.6 4.6-4.6s4.6 2.1 4.6 4.6c.1 2.5-2 4.6-4.6 4.6zm-22.1-14.3c-20.9 0-32.3-4.9-32.3-7.4s11.4-7.4 32.3-7.4 32.3 4.9 32.3 7.4-11.5 7.4-32.3 7.4z" />
                                </g>
                              </svg>
                            </div>
                          </div>
                          {/*end::Symbol*/}
                          <div className="d-flex justify-content-start flex-column">

                            <Link to={"/database-cluster/" + value.id + "/" + value2.cloudid}>
                              <a style={{ cursor: "pointer" }} className="text-dark fw-bold text-hover-primary fs-6 sphide">
                                {value2.hostname}
                              </a>
                            </Link>
                          </div>
                        </div>
                      </td>
                      <td className="text-center">
                        <div className="d-flex align-items-center sphide ms-10 mt-5">

                          <div className="symbol symbol-30px me-5">

                            {value2.dclocation ? (<>
                              <img src={"/img/flag_" + value2.dclocation.dccc + ".png"} />
                            </>) : ('')}

                          </div>
                          <div className="d-flex justify-content-start flex-column">

                            <a className="text-gray-700 fw-bold text-hover-primary fs-6 text-start">
                              {value2.dclocation ? (<>
                                {value2.dclocation.location}
                              </>) : ('')}
                            </a>
                            <span className="text-muted fw-bold text-muted d-block fs-7 text-start">
                              {value2.dclocation ? (<>
                                {value2.dclocation.country}
                              </>) : ('')}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className="text-center">
                        <div className="mb-5 text-center">
                          <span className="me-10 fw-bold">Read Only</span>
                        </div>
                      </td>
                      <td className="text-center">
                        <div className=" flex-column w-100">
                          <div className=" flex-stack mt-0.5">
                            <span className=" me-2 fs-6 fw-bold">
                              {value?.nodes?.primary[0]?.app_status == "Installed" ? (<>
                                <span className="fw-bold fs-6 text-gray-700" id={`cloud-btn-${value2.cloudid}`}><span className="status-circle" style={{ top: 3 }} ></span>
                                  Active
                                </span>
                              </>) : (<>
                                <span class="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2" style={{ top: 3 }} ></span></span>
                              </>)}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className="text-center">
                        <span className="fs-7 fw-bold mt-1">
                          {value2.created_at}
                        </span>
                      </td>
                      <td className="text-center">
                        <div className="d-flex justify-content-end flex-shrink-0">
                          <Link to={"/database-cluster/" + value.id + "/" + value2.cloudid}>
                            <a className="btn btn-primary btn-active-light-primary btn-sm">
                              Manage
                              <span className="svg-icon ms-2 svg-icon-3">

                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >

                                  <rect
                                    opacity="0.5"
                                    x={18}
                                    y={13}
                                    width={13}
                                    height={2}
                                    rx={1}
                                    transform="rotate(-180 18 13)"
                                    fill="black"
                                  />
                                  <path
                                    d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                    fill="black"
                                  />
                                </svg>
                              </span>
                            </a>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  </>))}
                </>) : ('')}
              </>}
              {/* {value.nodepools && value.nodepools.} */}
            </>))) : (<>
              <tr>
                <td colspan="12" id="no_record" valign="top">
                  <div className="d-flex flex-column flex-center">
                    <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                  </div>
                </td>
              </tr>
            </>) : <td colspan="12">
            <TableLoader />
          </td>}

      </>
    )
  }
  const handleSearch = (text) => {
    const endOffset = itemOffset + perPage;
    // Remove special characters from the input text
    const escapedText = text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    // Create a regular expression pattern to match the filtered text
    const regex = new RegExp(escapedText, "gi");

    // Filter the clusterData based on the regex pattern
    let data = clusterData.filter((row) => {
      // Check if any property in the row matches the regex pattern
      return Object.keys(row).some(key => {
        // Exclude 'created_at' property from the search
        if (key !== 'created_at') {
          return regex.test(row[key]);
        }
        return false;
      });
    });

    // Update the state with the filtered data
    setDummyClusterData(data);
    setPageCount(Math.ceil(data.length / perPage));
  }


  function PaginatedItems({ itemsPerPage, ProductData }) {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(ProductData.length / itemsPerPage));
    const handlePageClick = (event) => {
      setActivePage((old) => old = event.selected);
      const newOffset = event.selected * itemsPerPage % ProductData.length;
      ////console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
      setItemOffset(newOffset);
    };
    return (
      <>
        <ProductInfo productdata={ProductData.slice(itemOffset, endOffset)} />
        <td colspan="12">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            initialPage={activePage}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </td>
      </>
    );
  }

  const openDoc = () => {
    var drawerElement = document.querySelector("#database-doc");
    var drawer_overlay = document.querySelector("#drawer_overlay_database_doc");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
        drawerElement.style.width = "100%";
      } else {
        drawerElement.style.width = "50%";
      }
      drawer_overlay.classList.toggle("d-none");
    }
  };

  if (dataFetched) {
    if (access) {
      return (
        <>
          <div className="col-xl-12">
            <div className="card card-xl-stretch mb-5 mb-xl-12">
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold fs-3 mb-1">
                    Database Clusters
                    <span className="badge badge-success ms-1">Beta</span>
                  </span> 
                </h3>
                <div className="card-toolbar">
                <div className="position-relative my-1" style={{ marginRight: 20 }}>
                  <CustomDoc url={ProductsUrl[0]?.databases+"/mysql/getting-started/"}/>
                  </div>

                  <div
                    className="position-relative my-1"
                    style={{ marginRight: 20 }}
                  >
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      data-kt-table-widget-4="search"
                      id="searchClient"
                      // value={searchTerm}
                      onChange={e => handleSearch(e.target.value)}
                      placeholder="Search by cluster name"
                    />
                  </div>

                  <div style={{ marginRight: 20 }}>
                    <div className="dataTables_length">
                      <label className="w-125px">
                        <select
                          onChange={e => setPerPage(e.target.value)}
                          //data-control="select2"
                          className="form-select form-select-sm form-select-solid"
                          defaultValue={5}
                          id="perPage"

                        >
                          <option value={5} selected="">
                            5 Records
                          </option>
                          <option value={10}>10 Records</option>
                          <option value={25}>25 Records</option>
                          <option value={50}>50 Records</option>
                          <option value={100}>100 Records</option>
                        </select>
                      </label>
                    </div>
                  </div>
                  <Link to="/database-cluster/deploy">
                    <a className="btn btn-sm btn-primary btn-active-light-primary">
                      <span className="svg-icon svg-icon-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <rect
                            opacity="0.5"
                            x="11.364"
                            y="20.364"
                            width={16}
                            height={2}
                            rx={1}
                            transform="rotate(-90 11.364 20.364)"
                            fill="black"
                          />
                          <rect
                            x="4.36396"
                            y="11.364"
                            width={16}
                            height={2}
                            rx={1}
                            fill="black"
                          />
                        </svg>
                      </span>
                      Create Cluster
                    </a>
                  </Link>
                </div>
              </div>
              <div className="table-responsive">
                <table
                  id="firewallTable"
                  className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
                >
                  <thead className="bg-secondary">
                    <tr className="fw-bold ">
                      <th className="w-150px text-center ps-9">Cluster</th>
                      <th className=""></th>
                      <th className="w-225px text-center">Location</th>
                      {/* <th className=" min-w-150px text-center">Engine</th> */}
                      <th className="text-center w-200px pe-10">
                        Configuration
                      </th>
                      <th className="text-center w-100px">Status</th>
                      <th className="text-center w-150px">Created_at</th>
                      <th className="w-250px text-center"></th>
                    </tr>
                  </thead>
                  <tbody>

                    {dummyClusterData != null ? (<>
                      {access == false ? (<>
                        <tr>
                          <td colspan={12}>
                            <AccessDenied />
                          </td>
                        </tr>
                      </>) : (<>
                        {clusterData.length > 0 ? (<>
                          <PaginatedItems itemsPerPage={perPage} ProductData={dummyClusterData} />
                        </>) : (<>
                          <tr>
                            <td colSpan={12}>
                              <div className="card-body p-0 mt-10">
                                {/*begin::Illustration*/}
                                <div className="text-center px-4 mt-10">
                                  <span className="mw-100 mh-300px">
                                    <svg
                                      width="234px"
                                      height="234px"
                                      viewBox="0 0 84 84"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g
                                        id="DBaaS-Icon-1C-BLK"
                                        transform="translate(0.189181, -0.000000)"
                                        fill="#21325B"
                                        fillRule="nonzero"
                                      >
                                        <path d="m66.7 48.9c-7.7 2.1-17.7 3.3-28.4 3.3s-20.8-1.2-28.4-3.3c-2.2-.6-4.2-1.3-5.9-2v22c0 5.2 15.3 9.4 34.3 9.4s34.3-4.2 34.3-9.4v-22c-1.8.8-3.7 1.4-5.9 2zm-6.3 19.8c-2.6 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6 4.6 2.1 4.6 4.6-2 4.6-4.6 4.6z" />
                                        <path d="m38.3 5.1c-19 0-34.3 4.2-34.3 9.4v25.4c3.6 2.4 14.8 6.1 34.3 6.1 19.4 0 30.7-3.7 34.3-6.1v-25.4c-.1-5.2-15.4-9.4-34.3-9.4zm22.1 31.7c-2.6 0-4.6-2.1-4.6-4.6 0-2.6 2.1-4.6 4.6-4.6s4.6 2.1 4.6 4.6c.1 2.5-2 4.6-4.6 4.6zm-22.1-14.3c-20.9 0-32.3-4.9-32.3-7.4s11.4-7.4 32.3-7.4 32.3 4.9 32.3 7.4-11.5 7.4-32.3 7.4z" />
                                      </g>
                                    </svg>
                                  </span>
                                </div>
                                {/*end::Illustration*/}
                                {/*begin::Wrapper*/}
                                <div className="card-px text-center py-5 mb-20">
                                  {/*begin::Title*/}
                                  <h1 className="fs-2x fw-bolder mb-5">Database Cluster</h1>
                                  <h2 className="fs-1x fw-bolder mb-5 text-muted">
                                    Power Up Your Data Management
                                  </h2>
                                  {/*end::Title*/}
                                  {/*begin::Description*/}
                                  <p className="text-gray-400 fs-4 fw-bold mb-10">
                                    Effortlessly deploy a high-performance and fault-tolerant
                                    database <br />
                                    cluster, to efficiently manage and scale your data
                                    infrastructure.
                                  </p>
                                  {/*end::Description*/}
                                  {/*begin::Action*/}
                                  <Link to="/database-cluster/deploy">
                                    <a href="#" className="btn btn-primary mb-10">
                                      Create Database Cluster
                                    </a>
                                  </Link>
                                  {/*end::Action*/}
                                </div>
                                {/*end::Wrapper*/}
                              </div>
                            </td>
                          </tr>
                        </>)}
                      </>)}
                    </>) : (<>
                      <tr>
                        <td colspan={12}>
                          <TableLoader />
                          <TopBarHeaderLine />
                        </td>
                      </tr>
                    </>)}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* <div className="mb-4 sphide active-icon-id d-none">
        <span className="fw-bold fs-6 text-gray-700"><span className="status-circle"></span>
          Active
        </span>
      </div> */}
        </>
      );
    } else {
      return (
        <>
          <AccessDenied />
        </>
      )
    }
  } else {
    return (
      <>
        <TableLoader />
      </>
    )
  }
}

