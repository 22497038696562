import React, { useEffect, useRef, useState } from "react";
import TableLoader from "../../Loaders/TableLoader";
import Search from "../search/Search";

export default function Dczone({ adcdata, ddcdata, selectDc, location,setRecommendedCpu }) {
  console.log(adcdata, ddcdata);
  
  const [availableDcZones, setAvailableDcZones] = useState(adcdata);
  const [unavailableDcZones, setUnavailableDcZones] = useState(ddcdata);
  useEffect(() => {
    var dcslug_location = document.querySelector("#dcslugl_" + location);
    if (dcslug_location) {
      dcslug_location.click();
    }
  }, [availableDcZones])
  console.log(availableDcZones,"availableDcZones");
  
  return (
    <>
      {/* <form id="dczoneTab"> */}
        <div
          className="card-header border-3 mb-5"
          style={{ padding: "1rem 1rem 1rem 1.38rem" }}
        >
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder text-gray-800 mb-1">
              Select DC Location
            </span>
            <span className="text-gray-400 mt-1 fw-bold fs-6">Data Centers</span>
          </h3>
          {/* <Search results={adcdata} onSearch={setAvailableDcZones} searchPlaceholder="datacenter location" /> */}
        </div>

        <div className="mx-5 mb-3">
          {/*begin::Row*/}
          <div
            id="dc-location"
            className="row g-7"
            data-kt-buttons="true"
            data-kt-buttons-target="[data-kt-button]"
          >
            {availableDcZones.length > 0 ? (
              availableDcZones &&
              availableDcZones.map((value, index) => (
                <>
                  <div className="col-md-4 col-lg-4 col-sm-6 col-xxl-4 dc_sibling">
                    <input
                      className="form-check-input deploy_checkbox "
                      type="radio"
                      name="dcslug"
                      checked={value.slug === location}
                      defaultValue={value.slug}
                      id={"dcslug" + value.slug + ""}
                    />

                    <label
                      onClick={(e) => {
                        console.log(value?.default_cpu);
                        
                        selectDc(value.slug, 'yes')
                        setRecommendedCpu(value?.default_cpu)
                      }
                    }
                      style={{ WebkitJustifyContent: "unset" }}
                      id={"dcslugl_" + value.slug + ""}
                      htmlFor={"dcslug" + value.slug + ""}
                      className="position-relative  dcsluglable border-1 border-gray-300 border px-1 py-1"
                    >
                      <div className=" child">
                        <div className="p-3 ">
                          <div className="row">
                            <div className="col-4">
                              <img
                                src={
                                  "/assets/media/flags/svg/" + value.cc + ".svg"
                                }
                                className="w-50px "
                                alt=""
                              />
                            </div>
                            <div className="col-8">
                              <p className="no-margin text-dark fw-bolder text-hover-primary ">
                                {value.city}
                                <span className="text-muted d-block fw-bold ">
                                  {value.country}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <span className="d-none child position-absolute top-0 start-100 translate-middle  badge badge-circle badge-primary">

                      </span>
                      {/*begin::Ribbon*/}
                      <div
                        className={`tickMarkDc ribbon ribbon-triangle ribbon-top-start border-primary ${value.slug === location ? "" : "d-none"
                          }`}
                        id={"tickMarkDc-" + value.slug}
                      >
                        {/*begin::Ribbon icon*/}
                        <div className="ribbon-icon mt-n5">
                          <i className="bi bi-check2 fs-2 text-white" />
                        </div>
                        {/*end::Ribbon icon*/}
                      </div>
                      {/*end::Ribbon*/}
                    </label>
                  </div>
                </>
              ))
            ) : (
              <>
                <p className="text-gray-400 fs-6 fw-bold text-center">No datacenter location found</p>
                {/* <TableLoader /> */}
              </>
            )}
            {unavailableDcZones.length > 0 ? (
              !unavailableDcZones &&
              unavailableDcZones.map((value, index) => (
                <>
                  <div className="col-md-4 col-lg-4 col-sm-6 col-xxl-4 dc_sibling">
                    <input
                      className="form-check-input deploy_checkbox deploylocationsoldout"
                      type="radio"
                      name="dcslug"
                      onClick={(e) => (e.target.checked = false)}
                      defaultValue={value.slug}
                      id={"dcslug" + value.slug + ""}
                    />
                    <label
                      onClick={() => selectDc(value.slug, 'yes')}
                      style={{ WebkitJustifyContent: "unset" }}
                      id={"dcslugl_" + value.slug + ""}
                      htmlFor={"dcslug" + value.slug + ""}
                      className=" dcsluglable border-1 border-gray-300 border px-1 py-1"
                    >
                      <div className="child">
                        <div className="p-3 ">
                          <div className="row">
                            <div className="col-4">
                              <img
                                src={
                                  "/assets/media/flags/svg/" + value.cc + ".svg"
                                }
                                className="w-50px "
                                alt=""
                              />
                            </div>
                            <div className="col-8">
                              <p className="no-margin text-dark fw-bolder text-hover-primary ">
                                {value.city}
                                <span className="text-muted d-block fw-bold ">
                                  {value.country}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </label>
                  </div>
                </>
              ))
            ) : (
              <></>
            )}
          </div>
          {/*end::Row*/}
        </div>
      {/* </form> */}
    </>
  );
}
