import React from 'react';
import UseApi from '../../../../../CustomHooks/useApi';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import { useNavigate } from 'react-router-dom';
const Destroy = (props) => {
  const query = window.location.href
  const navigate = useNavigate();
  const destroyMonitoring = async () => {
    toast.dismiss();
    var submitBtn = document.querySelector("#destroy-btn");
    if (submitBtn) {
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';

      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this Monitoring!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {
            const dt = toast.loading("Monitoring deleting...");
            if (props) {
              const dnsp = await UseApi('DELETE', 'monitoring/server/' + props.serverid + '/delete', '');

              ////console.log(dnsp);
              if (dnsp) {
                submitBtn.classList.remove("disabled");
                submitBtn.innerHTML = preContent;
                if (dnsp.status == 'error') {
                  toast.update(dt, {
                    render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
                  });
                } else if (dnsp.status == 'success') {
                  toast.update(dt, {
                    render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
                  });
                  const url = window.location.href
                  if (query == url) {
                    navigate("/monitoring");
                  }
                }
              }
            }
          }
        });
    }
  }

  return (
    <>
      {/*begin::Deactivate Account*/}
      <div className="card">
        {/*begin::Card header*/}
        <div
          className="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_deactivate"
          aria-expanded="true"
          aria-controls="kt_account_deactivate"
        >
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">Destroy Monitoring</h3>
          </div>
        </div>
        {/*end::Card header*/}
        {/*begin::Content*/}
        <div id="kt_account_settings_deactivate" className="collapse show">
          {/*begin::Form*/}
          {/* <form id="destroyform" className="form"> */}
          {/*begin::Card body*/}
          <div className="card-body border-top p-9">
            {/*begin::Form input row*/}
            <form id="destroyform" className="form">
              <label htmlFor="size" className="form-label">
                This will delete all monitoring stats and remove this server from our monitoring system
              </label>
              <p className='mt-5'>
                <a
                  onClick={destroyMonitoring}
                  className="btn btn-danger"
                  id="destroy-btn"
                > Destroy Monitoring</a></p>
              {/*end::Form input row*/}
            </form>
          </div>
          {/*end::Card body*/}
          {/*end::Form*/}
        </div>
        {/*end::Content*/}
      </div>
      {/*end::Deactivate Account*/}
    </>
  );
}

export default Destroy;
