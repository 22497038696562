// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ribbonCustom {
    padding: 0px 8px !important;
    min-width: 25px !important;
    min-height: 20px !important; 
    right: -10px !important;
}

.plan-overlay-container {
    position: relative;
  }
  
.plan-overlay {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(225, 222, 222, 0.45); /* semi-transparent background */
color: #8e8e8e;
display: flex;
justify-content: center;
align-items: center;
font-size: 1em;
z-index: 2;
pointer-events: none; /* allows clicks to pass through the overlay */
}
  
@media only screen and (max-width: 767px) {
    .custom-grid {
      display: grid;
      text-align: center;
    }
    .mobile-text-center{
        text-align: center !important;
    }
    .ribbon-top {
        top: 3 !important;
    }
  }
`, "",{"version":3,"sources":["webpack://./src/App/MicroApp/Body/Products/pricing/custom.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,0BAA0B;IAC1B,2BAA2B;IAC3B,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;EACpB;;AAEF;AACA,kBAAkB;AAClB,MAAM;AACN,OAAO;AACP,WAAW;AACX,YAAY;AACZ,qCAAqC,EAAE,gCAAgC;AACvE,cAAc;AACd,aAAa;AACb,uBAAuB;AACvB,mBAAmB;AACnB,cAAc;AACd,UAAU;AACV,oBAAoB,EAAE,8CAA8C;AACpE;;AAEA;IACI;MACE,aAAa;MACb,kBAAkB;IACpB;IACA;QACI,6BAA6B;IACjC;IACA;QACI,iBAAiB;IACrB;EACF","sourcesContent":[".ribbonCustom {\n    padding: 0px 8px !important;\n    min-width: 25px !important;\n    min-height: 20px !important; \n    right: -10px !important;\n}\n\n.plan-overlay-container {\n    position: relative;\n  }\n  \n.plan-overlay {\nposition: absolute;\ntop: 0;\nleft: 0;\nwidth: 100%;\nheight: 100%;\nbackground: rgba(225, 222, 222, 0.45); /* semi-transparent background */\ncolor: #8e8e8e;\ndisplay: flex;\njustify-content: center;\nalign-items: center;\nfont-size: 1em;\nz-index: 2;\npointer-events: none; /* allows clicks to pass through the overlay */\n}\n  \n@media only screen and (max-width: 767px) {\n    .custom-grid {\n      display: grid;\n      text-align: center;\n    }\n    .mobile-text-center{\n        text-align: center !important;\n    }\n    .ribbon-top {\n        top: 3 !important;\n    }\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
