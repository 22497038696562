import disableDevtool from "disable-devtool";
import getUrlDetails from "../services/Url";
import { useEffect } from "react";

export default function DisableDevtools(){
   
    const UrlInfo = getUrlDetails();
        // disableDevtool({
        //     url: UrlInfo?.origin + '/unauthorized',
        // }); 
 
              console.log = function () { };
              console.warn = function () { };
              console.error = function () { };
              console.info = function () { };
              console.debug = function () { };
              console.trace = function () { };
              console.log = () => { };
              console.warn = () => { };
              console.error = () => { };
              console.info = () => { };
              console.debug = () => { };
              console.trace = () => { }; 
}