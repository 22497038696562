import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import LoadBalancerList from "./LoadBalanacerList";
import CreateLoadbalancerTargetGroup from "./Header/CreateLoadbalancerTargetGroup";
import TargetGroupList from "./TargetGroupList";

export default function LoadBalancer() {
  var query = window.location.href;
  var vars = query.split("/");

  useEffect(() => {
    var lbTab = document.querySelector("#load-balance-tabs");
    var trTab = document.querySelector("#target-group-tabs");
    if (lbTab && trTab) {
      if (vars[3] == "targetgroup") {
        trTab.click();
      } else {
        lbTab.click();
      }
    }
  }, [])

  return (
    <>
      <div className="col-xl-12">
        {/* <CreateLoadbalancerTargetGroup /> */}

        <div className="">
          {/* <ul className="nav nav-pills nav-pills-custom row position-relative mx-0">

            <li className="nav-item col-6 mx-0 px-0">
              <a
                className="nav-link active d-flex justify-content-center w-100 border-0 h-100"
                data-bs-toggle="pill"
                href="#loadbalancers-tab"
                id="load-balance-tabs"
              >
                <span className="sphide nav-text text-gray-800 fw-bolder fs-6 mb-3">
                  LoadBalancers
                </span>
                <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded" />
              </a>
            </li>
            <li className="nav-item col-6 mx-0 px-0">
              <a
                id="target-group-tabs"
                className="nav-link d-flex justify-content-center w-100 border-0 h-100"
                data-bs-toggle="pill"
                href="#target-tab"
              >
                <span className="sphide nav-text text-gray-800 fw-bolder fs-6 mb-3">
                  Target groups
                </span>
                <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded" />
              </a>
            </li>
            <span className="position-absolute z-index-1 bottom-0 w-100 h-4px bg-light rounded" />
          </ul> */}
        </div>

        {/* <div className="tab-content" id="myTabContent">

          <div className="tab-pane fade active show" id="loadbalancers-tab" role="tabpanel"> */}
            <LoadBalancerList />
          {/* </div>
          <div className="tab-pane fade" id="target-tab" role="tabpanel">
            <TargetGroupList showButton={false} />
          </div> */}

        {/* </div> */}
        {/* <LoadBalancerList /> */}
      </div>

    </>
  )
} 