import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom/dist";
import UsePost from "../../../../CustomHooks/usePost";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './DateTimePicker.css';
import UseApi from "../../../../CustomHooks/useApi";
import { connect, useDispatch } from "react-redux";
import { DeployAutoScalingInfo } from "../../../../Redux/Services/Action/actions";
import "./custom.css";
import PricingContainer from "../pricing/PricingContainer";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../Loaders/TableLoader";
import PricingContainerWithoutBillingCycle from "../pricing/PricingContainerWithoutBillingCycle";
import UsePostData from "../../../../CustomHooks/usePostData";
import CpuModelTab from "../../../../CustomHooks/CpuModelTab";
import { fi } from "date-fns/locale";
import { publicIp, publicIpv4, publicIpv6 } from 'public-ip';
import Dczone from "../../utils/dczone/Dczone";
import VpcComponent from "../../utils/vpc/VpcComponent";
import Vpc from "../../utils/vpc/Vpc";
function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}

const DeployAutoScaling = (props) => {
  const [location, setLocation] = useState("innoida")
  const [cpuValue, setCpuValue] = useState("amd")
  const navigate = useNavigate();
  const makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
  const [plan_period, setPlan_period] = useState("hourly");

  const forceUpdate = useForceUpdate();
  const [adcdata, setAdcdata] = useState([]);
  const [ddcdata, setDdcdata] = useState([]);
  const [vpc_id, setVpc_id] = useState('')
  const [pageStatus, setPageStatus] = useState(false);
  const [recommendedCpu, setRecommendedCpu] = useState('')
  const dataFetchedRef = useRef();
  const [currentDateTime, setCurrentDateTime] = useState(
    Array(props?.deployInfo[0]?.schedules.length).fill(new Date())
  );
  const [distroData, setDistroData] = useState(null);
  const [planData, setPlanData] = useState(null);
  const [userData, setUserData] = useState(null);
  const user_data = useRef(null);
  const dczoneStatus = useRef(true);
  const [vpcData, setVpcData] = useState(null);
  const [backupData, setBackupData] = useState(null);
  const [snapData, setSnapData] = useState(null);
  const [firewallData, setFirewallData] = useState(null);
  const [loadbalancerData, setLoadbalancerData] = useState([]);
  const [vpcLoader, setVpcLoader] = useState(false);
  const [backupLoader, setBackupLoader] = useState(false);
  const [snapLoader, setSnapLoader] = useState(false);
  const [osDiskSize, setOsDiskSize] = useState(80);
  function MarkRibbonTick(type, ribbon_id, action) {
    var tabSelect = document.querySelectorAll([".tickMark" + type]);
    if (tabSelect) {
      tabSelect.forEach((clist) => {
        clist.classList.add("d-none");
      });
    }
    if (action == "on") {
      var currentTab = document.querySelector(
        "#tickMark" + type + "-" + ribbon_id
      );
      if (currentTab) {
        currentTab.classList.remove("d-none");
      }
    }
  }

  const selectSnapshot = (data, snapshot_id, size) => {
    selectOsApp("snapshot", snapshot_id, data);
    // var snapshot_field=document.querySelector("#snapshot_field");
    // if(snapshot_field){
    //   snapshot_field.value=data;
    // }
    selectOsDiskSize(size);
    MarkRibbon("snapshot", snapshot_id);
  };
  const selectBackup = (data, backup_id, size) => {
    selectOsApp("backup", backup_id, data);
    // var backup_field=document.querySelector("#backup_field");
    // if(backup_field){
    //   backup_field.value=data;
    // }
    selectOsDiskSize(size);
    MarkRibbon("backup", backup_id);
  };
  function MarkRibbon(type, select_id) {
    var appData = ["Os", "backup", "snapshot", "iso"];
    if (appData) {
      appData.forEach((value) => {
        MarkRibbonTick(value, 0, "off");
      });
      MarkRibbonTick(type, select_id, "on");
    }
  }


  const selectOne = (type, select_id) => {
    var appData = ["Os", "backup", "snapshot", "iso"];
    if (appData) {
      appData.forEach((value) => {
        // //console.log("ribbon=> " + value);
        MarkRibbonTick(value, 0, "off");
      });
      // //console.log("active ribbon => " + type);
      MarkRibbonTick(type, select_id, "on");
    }
  };
  const getDczone = async (dczones) => {
    console.log(dczones,"dczonesss");
    
    // const data = await UseApi("get", "dczones", "");
    if (dczones) {
      if (dczones.length === 0) {
        setAdcdata([]);
        setDdcdata([]);
      } else {
        if (dczoneStatus.current) {
          dczones.map(async (value, index) => {
            if (value.status !== "active") {
              var server_ip = await publicIpv4();
              if (server_ip) {
                if (
                  server_ip !== "103.209.144.51" ||
                  server_ip !== "103.209.144.66" ||
                  server_ip !== "103.209.145.208" ||
                  server_ip !== "103.209.144.81" ||
                  server_ip !== "103.209.144.82" ||
                  server_ip !== "103.209.144.83" ||
                  server_ip !== "103.127.30.91" ||
                  server_ip !== "103.209.144.60" ||
                  server_ip !== "103.127.30.239" ||
                  server_ip !== "103.127.28.52"
                ) {
                  ddcdata.push(value);
                }
                forceUpdate();
              }
            } else {
              adcdata.push(value);
              forceUpdate();
            }
          });
          dczoneStatus.current = false;
        }

        setTimeout(() => {
          var dc = "innoida";
          if (props && props.deployInfo[0] && props.deployInfo[0].dcslug) {
            dc = props.deployInfo[0].dcslug;
          }
          var clickTo = document.querySelector("#dcslugl_" + dc);
          if (clickTo) {
            clickTo.click();
          }
        }, 1000);
      }
    }
  };

  const getDistro = async () => {
    toast.dismiss();
    setDistroData(null);
    var dc = "innoida"
    if (props && props.deployInfo[0] && props.deployInfo[0].dcslug) {
      dc = props.deployInfo[0].dcslug;
    }
    const data = await UseApi("get", "cloud/getdeploy?dcslug=" + dc, "");
    user_data.current = await UseApi("get", "account/info", "");
    setUserData(user_data.current.user);
    // ////////console.log(user_data.current);
    if (Object.keys(data).length === 0) {
      setDistroData(null);
      setFirewallData(null);
      setPlanData(null);
    } else {
      getDczone(data.dczones);
      setPlanData(data.plans);
      getMarketplace(data.stacks);
      getStackPublic(data.stacks_public);
      getStackPrivate(data.stacks_private);
      setFirewallData(data.firewalls);
      setDistroData(data.distro);
    }
    getLoadBalancer();
    getLoadBalancerTargetGroups();
    forceUpdate();
  };

  const [marketplaceData, setMarketplaceData] = useState(null);
  const [marketplaceLoader, setMarketplaceLoader] = useState(false);
  const getMarketplace = async (marketData) => {
    setMarketplaceLoader(true);
    // const marketData=await UseApi("get","stacks","nothing");
    ////console.log(marketData);
    if (marketData) {
      setMarketplaceLoader(false);
      if (marketData.length != 0) {
        if (marketData) {
          setMarketplaceData(marketData);
        }
      } else {
        setMarketplaceData([]);
      }
    }
  };

  const [stackPublicData, setStackPublicData] = useState(null);
  const [stackPublicLoader, setStackPublicLoader] = useState(false);

  const getStackPublic = async (publicData) => {
    setStackPublicLoader(true);
    // const marketData=await UseApi("get","stacks","nothing");
    ////console.log(marketData);
    if (publicData) {
      setStackPublicLoader(false);
      if (publicData.length != 0) {
        if (publicData) {
          setStackPublicData(publicData);
        }
      } else {
        setStackPublicData([]);
      }
    }
  };

  const [stackPrivateData, setStackPrivateData] = useState(null);
  const [stackPrivateLoader, setStackPrivateLoader] = useState(false);

  const getStackPrivate = async (privateData) => {
    setStackPrivateLoader(true);
    if (privateData) {
      setStackPrivateLoader(false);
      if (privateData.length != 0) {
        if (privateData) {
          setStackPrivateData(privateData);
        }
      } else {
        setStackPrivateData([]);
      }
    }
  };

  const [currMarketplace, setCurrMarketplace] = useState(null);
  const [currMarketplaceDistro, setCurrMarketplaceDistro] = useState(null);
  const [currMarketplaceLoader, setCurrMarketplaceLoader] = useState(false);

  const [currMarketplacePublic, setCurrMarketplacePublic] = useState(null);
  const [currMarketplaceDistroPublic, setCurrMarketplaceDistroPublic] = useState(null);
  const [currMarketplaceLoaderPublic, setCurrMarketplaceLoaderPublic] = useState(false);

  const [currMarketplacePrivate, setCurrMarketplacePrivate] = useState(null);
  const [currMarketplaceDistroPrivate, setCurrMarketplaceDistroPrivate] = useState(null);
  const [currMarketplaceLoaderPrivate, setCurrMarketplaceLoaderPrivate] = useState(false);

  // const resultArray = [];

  const fieldsArray = useRef([]);

  // Assuming you have a function to handle form field changes
  const handleFieldChange = (index, name, value) => {
    const fieldName = "stack_fields[" + index + "][" + name + "]";

    // Check if an object with the given index exists in the array
    let fieldObject = fieldsArray.current[index];

    // If not, create a new object
    if (!fieldObject) {
      fieldObject = {};
      fieldsArray.current[index] = fieldObject;
    }

    // Update the object with the field value
    fieldObject[name] = value;

    //console.log(fieldsArray.current);
    selectOsApp("marketplace", 0, "stacks");
  };

  const selectStackImage = (type, stack_image) => {
    var stackImage = document.querySelector("#main_stackimage");
    if (stackImage) {
      // //console.log("main stack image");
      stackImage.value = stack_image;
    }
    selectOsApp("marketplace", 0, type);
  }

  const selectMarketPlace = async (action, id) => {
    fieldsArray.current = [];

    var mainStack = document.querySelector("#main_stack");

    if (mainStack) {
      mainStack.value = id;
      MarkRibbon("marketplace", id);

      setCurrMarketplaceLoader(true);
      // const marketData = await UseApi("get", "stacks", "nothing");
      var marketData = null;
      var market_fields = document.querySelector("#market_fields");
      var market_fields_public = document.querySelector("#market_fields_public");
      var market_fields_private = document.querySelector("#market_fields_private");
      if (market_fields && market_fields_public && market_fields_private) {
        if (action == "stack") {
          selectOsApp("marketplace", id, "stacks");
          marketData = marketplaceData;
          if (marketData) {
            market_fields.classList.remove("d-none");
            market_fields_public.classList.add("d-none");
            market_fields_private.classList.add("d-none");
            setCurrMarketplaceLoader(false);
            if (marketData.length != 0) {
              marketData.map((value, index) => {
                if (value.id == id) {
                  if (value.distro.length == 0) {
                    setCurrMarketplaceDistro([]);
                  } else {
                    setCurrMarketplaceDistro(value.distro);
                  }
                  if (value.fields.length == 0) {
                    setCurrMarketplace([]);
                  } else {
                    setCurrMarketplace(value.fields);
                  }
                }
              });
            }
          }
        } else if (action == "public") {
          selectOsApp("public", id, "stacks_public");
          marketData = stackPublicData;
          if (marketData) {
            market_fields.classList.add("d-none");
            market_fields_public.classList.remove("d-none");
            market_fields_private.classList.add("d-none");
            setCurrMarketplaceLoaderPublic(false);
            if (marketData.length != 0) {
              marketData.map((value, index) => {
                if (value.id == id) {
                  if (value.distro.length == 0) {
                    setCurrMarketplaceDistroPublic([]);
                  } else {
                    setCurrMarketplaceDistroPublic(value.distro);
                  }
                  if (value.fields.length == 0) {
                    setCurrMarketplacePublic([]);
                  } else {
                    setCurrMarketplacePublic(value.fields);
                  }
                }
              });
            }
          }
        } else if (action == "private") {
          selectOsApp("private", id, "stacks_private");
          marketData = stackPrivateData;
          if (marketData) {
            market_fields.classList.add("d-none");
            market_fields_public.classList.add("d-none");
            market_fields_private.classList.remove("d-none");
            setCurrMarketplaceLoaderPrivate(false);
            if (marketData.length != 0) {
              marketData.map((value, index) => {
                if (value.id == id) {
                  if (value.distro.length == 0) {
                    setCurrMarketplaceDistroPrivate([]);
                  } else {
                    setCurrMarketplaceDistroPrivate(value.distro);
                  }
                  if (value.fields.length == 0) {
                    setCurrMarketplacePrivate([]);
                  } else {
                    setCurrMarketplacePrivate(value.fields);
                  }
                }
              });
            }
          }
        }
      }
    }

  };

  const selectDc = async (slug) => {
    // console.log(slug);
    getAllProductData(slug)
    setVpcLoader(true);
    setBackupLoader(true);
    setSnapLoader(true);
    if (props && props.deployInfo[0] && props.deployInfo[0].dcslug) {
      props.deployInfo[0].dcslug = slug;
      //console.log(slug);
    }
    showPropsData();
    setLocation(slug)
    var dcSelect = document.querySelectorAll([".tickMarkDc"]);
    var currentDc = document.querySelector("#tickMarkDc-" + slug);
    if (dcSelect) {
      dcSelect.forEach((clist) => {
        clist.classList.add("d-none");
      });
    }
    if (currentDc) {
      currentDc.classList.remove("d-none");
    }
    setPlanData(null);
  };

  async function getAllProductData(slug) {
    const vpc_data = await UseApi("get", "vpc/dc/" + slug, "");
    const backup_data = await UseApi("get", "backups/" + slug, "");
    const snap_data = await UseApi("get", "snapshot/" + slug, "");
    const iso_data = await UseApi("get", "iso/" + slug, "");
    const data = await UseApi("get", "cloud/getdeploy?dcslug=" + slug, "");
    getLoadBalancer();
    getLoadBalancerTargetGroups();
    if (data && data.plans) {

      setPlanData(data.plans);
    }
    console.log(vpc_data, "======??><<<<<");
    if (vpc_data?.vpc?.status === "error") {
      setVpcLoader(false);
      setVpcData(null);
    } else {
      setVpcLoader(false);
      setVpcData(vpc_data?.vpc);
      // }
    }

    if (backup_data && backup_data.backups) {
      setBackupLoader(false);
      if (backup_data.backups.length == 0) {
        setBackupData([]);
      } else {
        setBackupData(backup_data.backups);
      }
    }

    if (snap_data && snap_data.snapshots) {
      setSnapLoader(false);
      if (snap_data.snapshots.length == 0) {
        setSnapData([]);
      } else {
        setSnapData(snap_data.snapshots);
      }
    }
  }

  useEffect(() => {
    // navigate('/process/data:cloud/deploy')
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    getDistro();
    setPageStatus(!pageStatus);
  }, [pageStatus]);

  function selectOsApp(type, os_id, data) {
    if (type != "snapshot") {
      selectOsDiskSize(80);
    }
    if (props && props.deployInfo[0]) {
      if (type == "snapshot") {
        delete props.deployInfo[0].image_id;
        delete props.deployInfo[0].backupid;
        delete props.deployInfo[0].stack;
        delete props.deployInfo[0].stackid;
        delete props.deployInfo[0].stackimage;
        delete props.deployInfo[0].stack_fields;

        props.deployInfo[0].snapshotid = os_id;
        props.deployInfo[0].image_name = data;
      } else if (type == "backup") {
        delete props.deployInfo[0].image_id;
        delete props.deployInfo[0].snapshotid;
        delete props.deployInfo[0].stack;
        delete props.deployInfo[0].stackid;
        delete props.deployInfo[0].stackimage;
        delete props.deployInfo[0].stack_fields;

        props.deployInfo[0].backupid = os_id;
        props.deployInfo[0].image_name = data;
      } else if (type == "os") {
        delete props.deployInfo[0].snapshotid;
        delete props.deployInfo[0].backupid;
        delete props.deployInfo[0].stack;
        delete props.deployInfo[0].stackid;
        delete props.deployInfo[0].stackimage;
        delete props.deployInfo[0].stack_fields;

        props.deployInfo[0].image_id = os_id;
        props.deployInfo[0].image_name = data;
      } else if (type == "marketplace" || type == "private" || type == "public") {
        //console.log("inside")
        var stackid = document.querySelector("#main_stack");
        var stackimage = document.querySelector("#main_stackimage");
        if (stackid && stackimage) {
          //console.log(stackimage.value+", stack image")
          delete props.deployInfo[0].snapshotid;
          delete props.deployInfo[0].backupid;
          delete props.deployInfo[0].image_id;
          delete props.deployInfo[0].image_name;
          props.deployInfo[0].stack = stackid.value;
          props.deployInfo[0].stackid = stackid.value;
          props.deployInfo[0].stackimage = stackimage.value;
          if (fieldsArray.current.length > 0) {
            props.deployInfo[0].stack_fields = fieldsArray.current;
          } else {
            delete props.deployInfo[0].stack_fields;
          }

        }

      }

    }
    showPropsData();
  }

  function containsWindows(inputString) {
    // Use a regular expression to match the word "windows" with optional digits
    const windowsRegex = /\bwindows\d*\b/i;

    // Test if the input string matches the regular expression
    return windowsRegex.test(inputString);
  }
  const [showSSH, setShowSSH] = useState(true);
  const selectOs = (os_id, image, distribution, cost) => {
    if (containsWindows(image)) {
      setShowSSH(false);
    } else {
      setShowSSH(true);
    }

    MarkRibbon("Os", distribution);



    const os_version_list_ = document.querySelector(
      "#os_version_list_" + distribution
    );
    os_version_list_.classList.remove("d-block");

    const all_radio = document.getElementsByClassName("radio_image");
    for (var i = 0; i < all_radio.length; i++) {
      all_radio[i].checked = false;
    }
    var pselect = document.querySelectorAll([".deploy_osversioncontainer"]);
    pselect.forEach((clist) => {
      clist.classList.remove("d-block");
    });
    const all_versionText = document.getElementsByClassName(
      "osversionselectmultiple"
    );
    for (var i = 0; i < all_versionText.length; i++) {
      all_versionText[i].innerHTML = "Select Version";
    }

    selectOsApp("os", os_id, image);

    const current_radio = document.querySelector("#select_" + distribution);
    current_radio.checked = true;

    const current_select_version = document.querySelector(
      "#osversion_" + distribution
    );
    current_select_version.innerHTML = image;

  };

  const osVersion = (distribution) => {
    var pselect = document.querySelectorAll([".deploy_osversioncontainer"]);
    var pheight = document.querySelectorAll([".versionOs"]);
    pselect.forEach((clist) => {
      clist.classList.remove("d-block");
    });
    //var cl=event.currentTarget.querySelector('.versionOs');

    pheight.forEach((clist) => {
      clist.style.height = "auto";
      clist.classList.remove("active");
    });
    //  event.currentTarget.querySelector('.deploy_osversioncontainer').classList.add('d-block');
    const os_version_list_ = document.querySelector(
      "#os_version_list_" + distribution
    );
    if (os_version_list_) {
      os_version_list_.classList.add("d-block");
    }
  };

  function updatedPlanInfo(plan = []) {
    if (props && props.deployInfo[0]) {
      props.deployInfo[0].planid = plan.planid;
      props.deployInfo[0].planname = plan.planType;
    }
    showPropsData();
  }


  function showPropsData() {
    if (props && props.deployInfo) {
      //console.log(props.deployInfo[0]);
    }
  }

  const getLoadBalancer = async () => {
    const data = await UseApi('get', 'loadbalancer', '');
    if (data) {

      if ('status' in data) {
        if (data.status == "error") {
          setLoadbalancerData([]);
          return;
        }
      }
      if (Object.keys(data).length === 0) {
        setLoadbalancerData([]);
      } else {
        if (data.loadbalancers) {
          var loadbalancer_field = document.querySelector("#loadbalancer_field");
          if (loadbalancer_field) {
            //console.log("lb field");
            var ldata = [];
            var filterData = data.loadbalancers.filter((item) => {
              return item.type == "classic" || item.type == "network"
            })
            if (filterData) {
              filterData.map((value, index) => {
                ldata.push(value.name + " (" + value.id + ")");
              })
            }

            const contactTagify = new window.Tagify(loadbalancer_field, {
              whitelist: ldata,
              maxTags: 20,
              dropdown: {
                maxItems: 20,
                classname: "",
                enabled: 0,
                closeOnSelect: false
              }
            });
            setLoadbalancerData(filterData);
          } else {
            //console.log("no lb field");
          }
        }

      }
    }
  }

  const [targetData, setTargetData] = useState([]);

  const getLoadBalancerTargetGroups = async () => {
    const data = await UseApi('get', 'targetgroup', '');
    if (data) {

      if (data.status != "success") {
        setTargetData([]);
      } else {
        if (data.targetgroups) {
          var loadbalancer_field = document.querySelector("#loadbalancer_target_field");
          if (loadbalancer_field) {
            //console.log("lb field");
            var ldata = [];

            data.targetgroups.map((value, index) => {
              ldata.push(value.name + " (" + value.id + ")");
            })
            const contactTagify = new window.Tagify(loadbalancer_field, {
              whitelist: ldata,
              maxTags: 20,
              dropdown: {
                maxItems: 20,
                classname: "",
                enabled: 0,
                closeOnSelect: false
              }
            });
          } else {
            //console.log("no lb field");
          }
        }
        setTargetData(data.targetgroups);

      }
    }
  }

  const [vpcMultipleInfo, setVpcMultipleInfo] = useState([]);

  // useEffect(() => {
  //   let vdata = [];
  //   if (vpcData && vpcData.length > 0) {
  //     vpcData.map((value, index) => {

  //       vdata.push(value[1].name + " (" + value[1].id + ")");
  //     })
  //   }

  //   setVpcMultipleInfo(vdata);
  // }, [vpcData])

  useEffect(() => {
    var vpc_field = document.querySelector("#vpc_field");
    if (vpc_field && vpcMultipleInfo) {
      //console.log(vpcMultipleInfo);
      const contactTagify = new window.Tagify(vpc_field, {
        whitelist: vpcMultipleInfo,
        maxTags: 20,
        dropdown: {
          maxItems: 20,
          classname: "",
          enabled: 0,
          closeOnSelect: false
        }
      });
    }
  }, [vpcMultipleInfo])


  useEffect(() => {
    var firewall_field = document.querySelector("#firewall_field");
    if (firewall_field && firewallData) {
      var fdata = [];
      firewallData.map((value, index) => {
        fdata.push(value.name + " (" + value.id + ")");
      })
      const contactTagify = new window.Tagify(firewall_field, {
        whitelist: fdata,
        maxTags: 20,
        dropdown: {
          maxItems: 20,
          classname: "",
          enabled: 0,
          closeOnSelect: false
        }
      });
    }
  }, [firewallData])

  function updateNewVpc(e) {
    if (e.target.value) {
      var eleData = JSON.parse(e.target.value);
      var eleIds = eleData.map(item => {
        // Extract string portion from the value within parentheses
        var match = item.value.match(/\(([^)]+)\)/);
        return match ? match[1] : null;
      }).filter(Boolean); // Filter out null values

      // Concatenate the extracted strings into a comma-separated string
      var concatenatedVpcIds = eleIds.join(',');

      // Set the concatenated string as the value of the add_cloud input
      if (props && props.deployInfo[0]) {
        props.deployInfo[0].vpc = concatenatedVpcIds;
      }
    }
  }

  function updateNewFirewall(e) {
    if (e.target.value) {
      var eleData = JSON.parse(e.target.value);
      var eleIds = eleData.map(item => {
        // Extract numeric portion from the value (assuming it's always in parentheses)
        var match = item.value.match(/\((\d+)\)/);
        return match ? match[1] : null;
      }).filter(Boolean); // Filter out null values

      // Concatenate the cloud IDs into a comma-separated string
      var concatenatedFirewallIds = eleIds.join(',');

      // Set the concatenated string as the value of the add_cloud input
      if (props && props.deployInfo[0]) {
        props.deployInfo[0].security_groups = concatenatedFirewallIds;
      }
    }
  }

  function updateNewLoadbalancer(e) {
    if (e.target.value) {
      var eleData = JSON.parse(e.target.value);
      var eleIds = eleData.map(item => {
        // Extract numeric portion from the value (assuming it's always in parentheses)
        var match = item.value.match(/\((\d+)\)/);
        return match ? match[1] : null;
      }).filter(Boolean); // Filter out null values

      // Concatenate the cloud IDs into a comma-separated string
      var concatenatedLoadbalancerIds = eleIds.join(',');

      // Set the concatenated string as the value of the add_cloud input
      if (props && props.deployInfo[0]) {
        props.deployInfo[0].load_balancers = concatenatedLoadbalancerIds;
      }
    }
  }

  function updateLoadbalancer() {
    var loadbalancer_field = document.querySelector("#loadbalancer_field");
    if (loadbalancer_field.value) {
      var eleData = JSON.parse(loadbalancer_field.value);
      var eleIds = eleData.map(item => {
        // Extract numeric portion from the value (assuming it's always in parentheses)
        var match = item.value.match(/\((\d+)\)/);
        return match ? match[1] : null;
      }).filter(Boolean); // Filter out null values

      // Concatenate the cloud IDs into a comma-separated string
      var concatenatedLoadbalancerIds = eleIds.join(',');

      // Set the concatenated string as the value of the add_cloud input
      if (props && props.deployInfo[0]) {
        props.deployInfo[0].load_balancers = concatenatedLoadbalancerIds;
      }
    }
  }

  function updateNewLoadbalancerTargetGroups(e) {
    if (e.target.value) {
      var eleData = JSON.parse(e.target.value);
      var eleIds = eleData.map(item => {
        // Extract numeric portion from the value (assuming it's always in parentheses)
        var match = item.value.match(/\((\d+)\)/);
        return match ? match[1] : null;
      }).filter(Boolean); // Filter out null values

      // Concatenate the cloud IDs into a comma-separated string
      var concatenatedLoadbalancerIds = eleIds.join(',');

      // Set the concatenated string as the value of the add_cloud input
      if (props && props.deployInfo[0]) {
        props.deployInfo[0].target_groups = concatenatedLoadbalancerIds;
      }
    }
  }

  function updateLoadbalancerTargetGroups() {
    var loadbalancer_target_field = document.querySelector("#loadbalancer_target_field");
    if (loadbalancer_target_field.value) {
      var eleData = JSON.parse(loadbalancer_target_field.value);
      var eleIds = eleData.map(item => {
        // Extract numeric portion from the value (assuming it's always in parentheses)
        var match = item.value.match(/\((\d+)\)/);
        return match ? match[1] : null;
      }).filter(Boolean); // Filter out null values

      // Concatenate the cloud IDs into a comma-separated string
      var concatenatedLoadbalancerIds = eleIds.join(',');

      // Set the concatenated string as the value of the add_cloud input
      if (props && props.deployInfo[0]) {
        props.deployInfo[0].target_groups = concatenatedLoadbalancerIds;
      }
    }
  }

  const [policyArr, setPolicyArr] = useState([]);
  const [scheduleArr, setScheduleArr] = useState(props.deployInfo[0]?.schedules || []);

  console.log(scheduleArr, "-------------->>>>>><<<<<<<");

  function getPolicy() {
    if (props && props.deployInfo[0] && props.deployInfo[0].policies.length > 0) {
      setPolicyArr(props.deployInfo[0].policies);
    }
  }
  function getSchedule() {
    console.log(props.deployInfo[0].schedules);

    if (props && props.deployInfo[0] && props.deployInfo[0].schedules.length > 0) {
      setScheduleArr(props.deployInfo[0].schedules);
    }
  }

  useEffect(() => {
    getPolicy();
  }, [])

  useEffect(() => {
    getSchedule();
  }, [props.deployInfo[0]])

  function modifyPolicies(action, index, newData) {
    // console.log(action, index, newData);
    // //console.log(index);
    // Clone the existing array to avoid modifying the original array directly
    if (props && props.deployInfo[0]) {
      var parr = props.deployInfo[0].policies;
      const updatedPolicies = [...parr];

      switch (action) {
        case 'add':
          // Add a new policy to the array
          const newPolicy = {
            policyname: "Policy-" + makeid(5),
            policytype: "cpu",
            compare: "above",
            value: "90",
            adjust: "1",
            period: "5m",
            cooldown: "300"
          };
          updatedPolicies.push(newPolicy);
          break;
        case 'delete':
          // Delete a policy at the specified index
          if (index !== null && index >= 0 && index < updatedPolicies.length) {
            updatedPolicies.splice(index, 1);
          } else {
            console.error('Invalid index for deletion.');
          }
          break;
        case 'update':
          // Update a policy at the specified index with new data
          if (index !== null && index >= 0 && index < updatedPolicies.length) {
            // policyArr[index] = { ...policyArr[index], ...newData }
            updatedPolicies[index] = { ...updatedPolicies[index], ...newData };
            setPolicyArr(updatedPolicies);
          } else {
            console.error('Invalid index for update.');
          }
          break;
        default:
          console.error('Invalid action.');
          break;
      }

      // Log the updated array for verification (you can remove this in production)
      //console.log(updatedPolicies);

      // Return the updated array
      if (props && props.deployInfo[0]) {
        props.deployInfo[0].policies = updatedPolicies;
      }
      if (action != "update") {
        setPolicyArr(updatedPolicies);
      }
      return updatedPolicies;
    }

  }

  // console.log(scheduleArr, policyArr, "-------------------------------->>>>>>>><<<<<<<<<<<<<<<<<<<<<,");

  function modifySchedules(action, index, newData) {
    console.log(action, index, newData);

    // console.log(index);
    // Create a new Date object to get the current timestamp
    const currentDate = new Date();

    // Format the date and time
    const formattedDate = currentDate.toISOString().split('T')[0]; // YYYY-MM-DD

    // Get hours and minutes in 24-hour format
    const hours = currentDate.getHours().toString().padStart(2, '0'); // 01-23
    const minutes = currentDate.getMinutes().toString().padStart(2, '0'); // 00-59

    const formattedTime = `${hours}: ${minutes}`;
    // //console.log(index);
    // Clone the existing array to avoid modifying the original array directly
    if (props && props.deployInfo[0]) {
      var parr = props.deployInfo[0].schedules;
      // console.log(parr);
      const updatedPolicies = [...parr];
      // console.log(updatedPolicies);
      switch (action) {
        case 'add':
          // Add a new policy to the array 
          const newPolicy = {
            name: "Schedule-" + makeid(6),
            desiredsize: "2",
            recurrence: "Cron",
            recurrence_duration: 'Cron',
            start_date: new Date(),
            selectedTime: formattedTime,
            selectedDate: formattedDate
          };
          updatedPolicies.push(newPolicy);
          setCurrentDateTime(Array(updatedPolicies?.length).fill(new Date()))
          // setScheduleArr(null);
          break;
        case 'delete':
          // Delete a policy at the specified index
          if (index !== null && index >= 0 && index < updatedPolicies.length) {
            updatedPolicies.splice(index, 1);
          } else {
            console.error('Invalid index for deletion.');
          }
          break;
        case 'update':
          // Update a policy at the specified index with new data
          console.log(updatedPolicies.length);

          if (index !== null && index >= 0 && index < updatedPolicies.length) {
            console.log(scheduleArr[parseInt(index)], { ...scheduleArr[parseInt(index)], ...newData }, scheduleArr[parseInt(index)] = { ...scheduleArr[parseInt(index)], ...newData });

            scheduleArr[parseInt(index)] = { ...scheduleArr[parseInt(index)], ...newData }
            updatedPolicies[parseInt(index)] = { ...updatedPolicies[parseInt(index)], ...newData };
            // Check if there's a change before setting state
            console.log(JSON.stringify(scheduleArr) !== JSON.stringify(updatedPolicies))
            if (JSON.stringify(scheduleArr) !== JSON.stringify(updatedPolicies)) {
              props.deployInfo[0].schedules = updatedPolicies
              console.log(props.deployInfo[0].schedules);

              setScheduleArr(updatedPolicies);
            }
            console.log(scheduleArr, props.deployInfo[0].schedules, updatedPolicies);

            // setScheduleArr(updatedPolicies)
          } else {
            console.error('Invalid index for update.');
          }
          break;
        default:
          console.error('Invalid action.');
          break;
      }

      // Log the updated array for verification (you can remove this in production)
      // console.log(updatedPolicies);

      // Return the updated array
      if (props && props.deployInfo[0]) {
        props.deployInfo[0].schedules = updatedPolicies;
      }
      if (action != "update") {
        setScheduleArr(null);
        getSchedule();
      }

      return updatedPolicies;
    }

  }

  console.log(scheduleArr);

  useEffect(() => {
    console.log("hello buddy", props.deployInfo[0].schedules);

    if (props && props.deployInfo && props.deployInfo[0] && props.deployInfo[0].schedules) {
      setScheduleArr(props.deployInfo[0].schedules);
    }
  }, [props.deployInfo, scheduleArr]);

  function updateAdjustmentValue(id, action, indexid, actionType, value, conditional) {
    // console.log(id, action, indexid, actionType, value);
    const field = document.getElementById(id);
    if (field) {
      // Get the current value and convert it to a number
      let currentValue = parseFloat(field.value) || 0;
      // console.log(currentValue, field);
      // Update the value based on the action
      if (action === 'up') {
        currentValue += 1;
      } else if (action === 'down') {
        currentValue -= 1;
      }

      // console.log(currentValue);
      // Update the field with the new value
      if (actionType == "policy") {
        var compareIndex = document.querySelector("#compare" + indexid);
        // console.log(compareIndex);
        if (compareIndex) {
          if (compareIndex.value == "below") {
            if (currentValue >= 0) {
              field.value = -1;
            } else {
              field.value = currentValue;
            }
          } else {
            if (currentValue <= 0) {
              field.value = 1;
            } else {
              field.value = currentValue;
            }
          }
        }
      } else {
        if (currentValue <= 0) {
          field.value = 1;
        } else {
          field.value = currentValue;
        }
      }
      // console.log(field.value);
      if (conditional != "compare") {
        modifyPolicies("update", indexid, { policyname: value.policyname, policytype: value.policytype, compare: value.compare, value: value.value, adjust: field.value, period: value.period, cooldown: value.cooldown })
      }
    }
  }

  const updateSchedulesAdjustmentValue = (id, action, indexid, actionType, value) => {
    const field = document.getElementById(id);

    if (field) {
      // Get the current value and convert it to a number
      let currentValue = parseFloat(field.value) || 0;
      // console.log(currentValue);
      // Update the value based on the action
      if (action === 'up') {
        currentValue += 1;
      } else if (action === 'down') {
        currentValue -= 1;
      }
      if (currentValue <= 0) {
        currentValue = 1;
      } else if (currentValue > 5) {
        currentValue = 5;
      }
      modifySchedules('update', indexid, { name: value.name, desiredsize: JSON.stringify(currentValue), recurrence: value.recurrence, start_date: value.start_date, recurrence_duration: value.recurrence_duration, recurrence_week: value.recurrence_week, selectedTime: value.selectedTime, selectedDate: value.selectedDate })
      field.value = currentValue;
    }
  }

  const manageAdjustment = (indexid, e, value) => {
    if (e.target.value == "above") {
      modifyPolicies("update", indexid, { policyname: value.policyname, policytype: value.policytype, compare: e.target.value, value: value.value, adjust: 1, period: value.period, cooldown: value.cooldown })
      // updateAdjustmentValue("adjust_field_" + indexid + "", "up", indexid, "policy",value,"compare");
    } else {
      modifyPolicies("update", indexid, { policyname: value.policyname, policytype: value.policytype, compare: e.target.value, value: value.value, adjust: -1, period: value.period, cooldown: value.cooldown })
    }
  }

  function changeWeekRecurrence(id, adata, value) {
    console.log(id, adata, value);

    var scArr = adata;
    var recurrence_ = document.querySelector("#recurrence_" + id);
    if (recurrence_) {
      scArr.recurrence_week = value;
      scArr.recurrence = scArr.recurrence_duration + " " + value + " " + createTimeFormat(scArr.selectedTime);
      recurrence_.value = scArr.recurrence_duration + " " + value + " " + createTimeFormat(scArr.selectedTime);
    }
    // console.log(scArr);
    modifySchedules("update", "" + id + "", scArr);
  }
  function changeTimeRecurrence(id, adata, value) {
    var scArr = adata;
    var recurrence_ = document.querySelector("#recurrence_" + id);
    // var start_date = document.querySelector("#start_date_"+id); 
    if (recurrence_) {
      if (scArr.recurrence_duration == "Every week") {
        scArr.selectedTime = value;
        scArr.recurrence = scArr.recurrence_duration + " " + scArr.recurrence_week + " " + createTimeFormat(value);
        recurrence_.value = scArr.recurrence_duration + " " + scArr.recurrence_week + " " + createTimeFormat(value);
        //  start_date.value=createTimestampFromTimeInIST(value);
      } else if (scArr.recurrence_duration == "Every day") {
        scArr.selectedTime = value;
        scArr.recurrence = scArr.recurrence_duration + " " + createTimeFormat(value);
        recurrence_.value = scArr.recurrence_duration + " " + createTimeFormat(value);
        // start_date.value=createTimestampFromTimeInIST(value);
      }

    }
    //console.log(scArr);
    modifySchedules("update", "" + id + "", scArr);
  }


  function createTimeFormat(timeString) {
    // Split the time string into hours and minutes
    const [hours, minutes] = timeString.split(':').map(Number);

    // Create a new Date object and set the hours and minutes in UTC
    const currentDateUTC = new Date();
    currentDateUTC.setUTCHours(hours);
    currentDateUTC.setUTCMinutes(minutes);
    currentDateUTC.setUTCSeconds(0); // Optional: Set seconds to 0

    // Adjust to IST (UTC+5:30)
    const offsetInMinutes = 5 * 60 + 30;
    const timestampInIST = currentDateUTC.getTime() + offsetInMinutes * 60 * 1000;

    // Format the timestamp as 'hh:mmA'
    const formattedTime = new Date(timestampInIST);
    const hours24 = formattedTime.getHours();
    const hours12 = hours24 % 12 || 12; // Convert to 12-hour format
    const ampm = hours24 < 12 ? 'AM' : 'PM';
    const formattedTimeString = `${String(hours12).padStart(2, '0')}:${String(minutes).padStart(2, '0')}${ampm}`;

    return formattedTimeString;
  }
  function updateRecurrence(id, adata, rvalue) {
    console.log(id, adata, rvalue);
    var scArr = adata;
    var recurrence_ = document.querySelector("#recurrence_" + id);
    console.log(recurrence_);

    var week_sec = document.querySelector("#week_sec_" + id);
    var date_sec = document.querySelector("#date_sec_" + id);
    var time_sec = document.querySelector("#time_sec_" + id);
    var date_value_ = document.querySelector("#date_value_" + id);
    //  var start_date = document.querySelector("#start_date_"+id);
    var time_value = document.querySelector("#time_value" + id);
    var week_value = document.querySelector("#week_value" + id);
    if (week_value && date_value_ && time_value && recurrence_ && week_sec && date_sec && time_sec) {
      if (rvalue == "Cron") {
        week_value.disabled = true;
        date_value_.disabled = true;
        time_value.disabled = true;
        scArr.recurrence = "Cron_30 2 * * MON";
        recurrence_.value = "Cron_30 2 * * MON";
      } else if (rvalue == "Every week") {
        console.log("kxcxjco");

        week_value.disabled = false;
        date_value_.disabled = true;
        time_value.disabled = false;
        scArr.recurrence = rvalue + " " + scArr?.recurrence_week + " " + createTimeFormat(time_value.value);
        recurrence_.value = rvalue + " " + scArr?.recurrence_week + " " + createTimeFormat(time_value.value);
      } else if (rvalue == "Every day") {
        week_value.disabled = true;
        date_value_.disabled = true;
        time_value.disabled = false;
        scArr.recurrence = rvalue + " " + createTimeFormat(time_value.value);
        recurrence_.value = rvalue + " " + createTimeFormat(time_value.value);
      } else if (rvalue == "Once") {
        week_value.disabled = true;
        date_value_.disabled = true;
        time_value.disabled = true;
        recurrence_.value = rvalue;
        scArr.recurrence = rvalue;
        // start_date.value=createTimestampFromDateAndTimeInIST(date_value_.value, time_value.value);
      } else {
        console.log("jdxksjdis");

        week_value.disabled = true;
        date_value_.disabled = true;
        time_value.disabled = true;
        recurrence_.value = rvalue;
        scArr.recurrence = rvalue;
        // start_date.value=createTimeFormat(time_value.value);
      }
      //console.log(scArr);
      scArr.recurrence_duration = rvalue;
      console.log(scArr);

      modifySchedules("update", "" + id + "", scArr);
    }
  }
  console.log(scheduleArr);


  function checkObjectForEmpty(obj) {
    for (const key in obj) {
      const value = obj[key];

      if (typeof value === 'string' && value.trim() === '') {
        if (key == "security_groups" || key == "load_balancers" || key == "vpc") {
          continue;
        }
        return `Error: '${key}' should not be empty or blank.`;
      }

      // Check if the value is a number and greater than 0
      // if(value)
      // console.log(value != "below");
      // if (value != "below") {
      //   if (typeof value === 'number' && value <= 0) {
      //     return `Error: '${key}' should be greater than 0.`;
      //   }
      // }

      // Check if the value is an object (for nested objects)
      if (typeof value === 'object' && value !== null) {
        const nestedError = checkObjectForEmpty(value);
        if (nestedError) {
          return nestedError; // Return the error from nested object check
        }
      }

      // Handle case for null or undefined values
      if (value === null || value === undefined) {
        return `Error: '${key}' should not be null or undefined.`;
      }

      // Special condition for 'compare' field in policies, only if 'compare' is "below"
      if (key === 'compare' && value === 'below') {
        // Do nothing for 'below', but validate the 'adjust' field below
        const adjustValue = obj['adjust'];
        if (typeof adjustValue === 'number' && adjustValue >= 0) {
          return `Error: 'adjust' should be negative when 'compare' is below.`;
        }
      }

    }

    return null; // No empty or blank elements found
  }

  const AddAutoScaling = async () => {
    const currentDate = new Date();
    // Format the date and time
    const formattedDate = currentDate.toISOString().split('T')[0]; // YYYY-MM-DD
    // Get hours and minutes in 24-hour format
    const hours = currentDate.getHours().toString().padStart(2, '0'); // 01-23
    const minutes = currentDate.getMinutes().toString().padStart(2, '0'); // 00-59

    const formattedTime = `${hours}:${minutes}`;
    toast.dismiss();
    const dt = toast.loading("Please wait...");
    const submitBtn = document.querySelector("#as-btn");
    console.log(props.deployInfo[0]);

    if (props && props.deployInfo[0]) {
      const validationError = checkObjectForEmpty(props.deployInfo[0]);

      if (validationError) {
        setTimeout(() => {
          toast.update(dt, {
            render: validationError,
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }, 1000);
        return;
      }
      props.deployInfo[0].policies = props.deployInfo[0].policies.map(policy => {
        if (policy?.value <= 0 || policy?.cooldown <= 0) {
          // handleInvalidPolicy(policy); 
          setTimeout(() => {
            toast.update(dt, {
              render: "cooldown and value should not be zero",
              type: "warning",
              isLoading: false,
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }); // Call your function here
          }, 1000)
          return
        }
        return {
          name: policy.policyname ? policy.policyname : policy.name,
          type: policy.policytype ? policy.policytype : policy.type,
          compare: policy.compare,
          value: policy.value,
          adjust: policy.adjust,
          period: policy.period,
          cooldown: policy.cooldown
        }
      });
      if (submitBtn) {
        setPageStatus(true);
        var preContent = submitBtn.innerHTML;
        submitBtn.classList.add("disabled");
        submitBtn.innerHTML =
          'Please wait... <span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';

        const responseData = await UsePostData("post", "autoscaling", props.deployInfo[0]);

        ////console.log(responseData);
        if (responseData.status === "error") {
          toast.update(dt, {
            render: responseData.message,
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (responseData.status === "success") {
          toast.update(dt, {
            render: responseData.message,
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          window.onDeployEvents('deploy_auto_scaling');
          props.deployInfo[0] = { name: "Auto-scaling-" + makeid(8) + ".utho", dcslug: "innoida", minsize: "1", maxsize: "5", desiredsize: "1", planid: "10045", planname: "basic", instance_templateid: "none", image_id: "", image_name: "", public_ip_enabled: true, vpc: "", load_balancers: "", security_groups: "", policies: [{ policyname: "Policy-16H2jh", policytype: "cpu", compare: "above", value: "90", adjust: "1", period: "5m", cooldown: "300" }], schedules: [{ name: "Schedule-3P28Ngs", desiredsize: "3", recurrence: "Once", recurrence_duration: "Once", recurrence_week: "Monday", start_date: new Date(), selectedTime: formattedTime, selectedDate: formattedDate }] };
          navigate("/auto-scaling");
          props.deployInfo[0].planid = ''
        }
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
      }
    }
  };

  const toggleAttachLb = (action) => {
    var networkLb = document.querySelector("#network-lb-sec");
    var applicationLb = document.querySelector("#application-lb-sec");
    if (networkLb && applicationLb) {
      if (action == "network") {
        networkLb.classList.remove("d-none");
        applicationLb.classList.add("d-none");
        if (props && props.deployInfo[0]) {
          props.deployInfo[0].target_groups = [];
        }
        updateLoadbalancer();
      } else {
        networkLb.classList.add("d-none");
        applicationLb.classList.remove("d-none");
        if (props && props.deployInfo[0]) {
          props.deployInfo[0].load_balancers = [];
        }
        updateLoadbalancerTargetGroups();
      }
    }
  }
  const selectOsDiskSize = (size) => {
    setOsDiskSize(0);
    if (props) {
      if (props.deployInfo) {
        props.deployInfo[0].os_disk_size = size;
        setTimeout(() => {
          setOsDiskSize(size);
        }, 1000)

      }
    }
  }

  useEffect(() => {
    if (props) {
      if (props.deployInfo) {
        setOsDiskSize(props.deployInfo[0].os_disk_size);
      }
    }
  }, [])

  console.log(scheduleArr);

  const handleChangeData = (index, newDate) => {
    console.log(index, newDate)
    const updatedDateTimeArray = [...currentDateTime];
    updatedDateTimeArray[index] = newDate; // Update the specific index
    setCurrentDateTime(updatedDateTimeArray); // Update the state
  };


  const selectTickMark = (sshid, name, inputElement) => {
    var currentPlan = document.querySelector("#tickMark" + name + "-" + sshid);
    var currentMain = document.querySelector("#border" + name + "-" + sshid);
    var input_element = document.querySelector("#" + inputElement + sshid)

    if (currentPlan && currentMain) {
      // Toggle the tick mark visibility
      currentPlan.classList.toggle("d-none");

      // Toggle the border's active class
      if (currentPlan.classList.contains("d-none")) {
        currentMain.classList.remove("activeBorder");
        input_element.classList.remove("deploy_checkbox")
      } else {
        currentMain.classList.add("activeBorder");
        input_element.classList.add("deploy_checkbox")
      }
      // If the tick mark is now visible, remove the tick mark from other elements
      if (!currentPlan.classList.contains("d-none")) {
        var allTickmarks = document.querySelectorAll(".tickMark" + name);
        allTickmarks.forEach((tickMark) => {
          if (tickMark !== currentPlan) {
            tickMark.classList.add("d-none");
          }
        });

        // Remove active border from other elements
        var mainTickMarks = document.querySelectorAll(".border" + name);
        mainTickMarks.forEach((border) => {
          if (border !== currentMain) {
            border.classList.remove("activeBorder");
          }
        });
      }
    }
  };
  console.log(currentDateTime);
  const minTime = (index) => {
    const today = new Date();
    const selectedDate = currentDateTime[index];

    if (!selectedDate) {
      // If selectedDate is undefined, return a default value (e.g., today)
      return new Date(today.setHours(today.getHours(), today.getMinutes(), 0, 0));
    }

    // If the selected date is today, set the min time to the current time
    if (selectedDate.toDateString() === today.toDateString()) {
      return new Date(today.setHours(today.getHours(), today.getMinutes(), 0, 0));
    }

    // Otherwise, set the min time to the start of the day
    return new Date(selectedDate).setHours(0, 0, 0, 0);
  };

  const maxTime = (index) => {
    const selectedDate = currentDateTime[index];

    if (!selectedDate) {
      // If selectedDate is undefined, return a default value (e.g., end of today)
      return new Date().setHours(23, 59, 59, 999);
    }

    // Set the max time to the end of the selected day
    return new Date(selectedDate).setHours(23, 59, 59, 999);
  };


  // console.log(currentDateTime);

  const handleMinSizeChange = (e) => {
    let minValue = parseInt(e.target.value, 10);
    let maxValue = parseInt(props.deployInfo[0].maxsize, 10);
    if (minValue < 1) {
      minValue = 1;
    }
    if (minValue >= maxValue) {
      minValue = maxValue - 1;
    }
    props.deployInfo[0].minsize = minValue;
    e.target.value = minValue;
    document.getElementById('min_size').value = minValue;
  };

  const handleMaxSizeChange = (e) => {
    let maxValue = parseInt(e.target.value, 10);
    let minValue = parseInt(props.deployInfo[0].minsize, 10);
    if (maxValue > 5) {
      maxValue = 5;
    }
    if (maxValue <= minValue) {
      maxValue = minValue + 1;
    }
    props.deployInfo[0].maxsize = maxValue;
    e.target.value = maxValue;
    document.getElementById('max_size').value = maxValue;
  };

  const handleDesiredSizeChange = (e) => {
    let value = parseInt(e.target.value, 10);
    const minValue = parseInt(props.deployInfo[0].minsize, 10);
    const maxValue = parseInt(props.deployInfo[0].maxsize, 10);
    if (value < minValue) {
      value = minValue;
    } else if (value > maxValue) {
      value = maxValue;
    }
    props.deployInfo[0].desiredsize = value;
    e.target.value = value;
  };

  console.log(scheduleArr);

  return (
    <>
      {props && props.deployInfo && adcdata != null && distroData != null ? (
        <>
          <div className="row">
            <div className="col-xl-12">
              <form id="clouddeploy">
                {/*begin::Tables Widget 9*/}
                <div className="mb-5 mb-xl-12">
                  <div className="col-xl-12 card mb-5">
                    {/*begin::data center*/}
                    <Dczone adcdata={adcdata} ddcdata={ddcdata} selectDc={selectDc} location={location} setRecommendedCpu={setRecommendedCpu} />
                    {/* End data center */}
                  </div>
                  {/*begin::Col*/}
                  <div className="col-xl-12 card mb-5">
                    {/*begin::List widget 10*/}
                    {/*begin::Header*/}
                    <div className="card-header border-3 mb-5 pt-2 ps-6">
                      {/*begin::Title*/}
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bolder text-gray-800">
                          Select OS/APPS
                        </span>
                        <span className="text-gray-400 mt-1 fw-bold fs-6">
                          Snapshot/Stack
                        </span>
                      </h3>
                      {/*end::Title*/}
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="mx-0 pb-5 ps-6">
                      {/*begin::Nav*/}
                      <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-trakt_charts_widget_1_chartnsparent fs-5 fw-bolder">
                        <li className="nav-item d-none">
                          <a
                            className="nav-link text-active-primary py-5 me-6"
                            data-bs-toggle="tab"
                            href="#distros-tabs"
                          >
                            Operating System
                          </a>
                        </li>
                        <li className="nav-item d-none">
                          <a
                            className="nav-link text-active-primary py-5 me-6"
                            data-bs-toggle="tab"
                            href="#marketplace-tab"
                          >
                            Marketplace
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link text-active-primary py-5 me-6 ms-0 active"
                            data-bs-toggle="tab"
                            href="#stack-tab"
                            id="stack_nav"
                          >
                            Stacks
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link text-active-primary py-5 me-6"
                            data-bs-toggle="tab"
                            href="#snapshot-tabs"
                          >
                            Snapshots
                          </a>
                        </li>
                        <li className="nav-item d-none">
                          <a
                            className="nav-link text-active-primary py-5 me-6"
                            data-bs-toggle="tab"
                            href="#backups-tabs"
                          >
                            Backups
                          </a>
                        </li>
                      </ul>

                      {/*end::Nav*/}
                      {/*begin::Tab Content*/}
                      <div className="tab-content mt-5 pe-8">
                        {/*begin::Tap pane*/}
                        <div
                          className="tab-pane fade "
                          id="distros-tabs"
                        >
                          {/*begin::Row*/}

                          <div
                            id="distros"
                            className="row g-3"
                            data-kt-buttons="true"
                            data-kt-buttons-target="[data-kt-button]"
                          >
                            {distroData != null && distroData ? (
                              <>
                                {distroData.map((value, index) => (
                                  <>
                                    {/*begin::Col*/}
                                    <div
                                      className="col-md-4 col-lg-4 col-xxl-4"
                                      style={{ height: "73.06px !important" }}
                                    >
                                      <input
                                        type="radio"
                                        name="image_OS"
                                        id={"select_" + value.distro}
                                        defaultValue=""
                                        className="deploy_checkbox distro_radio Os_radio"
                                      />
                                      <label
                                        onClick={() => osVersion(value.distro)}
                                        //className="label versionOs btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start"
                                        className=" label versionOs text-start border-1 border-gray-300 border px-6 py-2"
                                        id={"versionOs_" + value.distro}
                                        style={{ position: "relative" }}
                                      >
                                        <span className="ms-5">
                                          {/*begin::Item*/}
                                          <div className="d-flex  ">
                                            {/*begin::Text*/}
                                            <div className="flex-grow-1">
                                              <div className="row">
                                                <div className="col-4">
                                                  {/*begin::Avatar*/}
                                                  <div className="symbol symbol-50px me-5">
                                                    <span
                                                      className={
                                                        "fonticon_" +
                                                        value.distro
                                                      }
                                                    />
                                                  </div>
                                                  {/*end::Avatar*/}
                                                </div>
                                                <div className="col-8">
                                                  <p className="text-dark fw-bolder text-hover-primary fs-6">
                                                    {value.distribution}

                                                    <span
                                                      className="text-muted d-block fw-bold deploy_checkbox_line2 osversionselectmultiple w-100px"
                                                      id={
                                                        "osversion_" +
                                                        value.distro
                                                      }
                                                    >
                                                      Select Version
                                                    </span>
                                                  </p>
                                                </div>
                                              </div>
                                              <span
                                                className="deploy_osversioncontainer bg-white p-5 border-left-right-bottom"
                                                // style={{
                                                //   zIndex: "99",
                                                //   width: "257.33px",
                                                //   marginLeft: "-37px",
                                                //   backgroundColor: "#F0FAFF",
                                                //   display: "none",
                                                // }}
                                                style={{
                                                  position: "absolute",
                                                  top: "100%",
                                                  left: 0,
                                                  width: "100%",
                                                  zIndex: 99,
                                                  display: "none",
                                                }}
                                                id={
                                                  "os_version_list_" +
                                                  value.distro
                                                }
                                              >
                                                {value.images &&
                                                  value.images.map(
                                                    (value2, index2) => (
                                                      <>
                                                        <span
                                                          className="deploy_osversionselector alert alert-primary p-3"
                                                          data-image={
                                                            value2.image
                                                          }
                                                          data-oscost={
                                                            value2.cost
                                                          }
                                                          data-title={
                                                            value2.distro
                                                          }
                                                          onClick={(e) => {
                                                            e.stopPropagation();
                                                            selectOne(
                                                              "Os",
                                                              value.distribution
                                                            );
                                                            selectOs(
                                                              value2.id,
                                                              value2.image,
                                                              value2.distro,
                                                              value2.cost
                                                            );
                                                          }}
                                                        >
                                                          {value2.version}
                                                          {value2.cost > 0 ? (
                                                            <>
                                                              <span className="badge badge-warning">
                                                                {value2.cost}/Mo
                                                              </span>
                                                            </>
                                                          ) : (
                                                            ""
                                                          )}
                                                        </span>
                                                      </>
                                                    )
                                                  )}
                                              </span>
                                            </div>
                                            {/*end::Text*/}
                                          </div>
                                          {/*end::Item*/}
                                        </span>

                                        {/*begin::Ribbon*/}
                                        <div
                                          className="d-none tickMarkOs ribbon ribbon-triangle ribbon-top-start border-primary"
                                          id={"tickMarkOs-" + value.distro}
                                        >
                                          {/*begin::Ribbon icon*/}
                                          <div className="ribbon-icon mt-n5">
                                            <i className="bi bi-check2 fs-2 text-white" />
                                          </div>
                                          {/*end::Ribbon icon*/}
                                        </div>
                                        {/*end::Ribbon*/}
                                      </label>
                                    </div>
                                    {/*end::Col*/}
                                  </>
                                ))}
                              </>
                            ) : (
                              <>
                                {/* <div className="small-spinner"></div> */}
                                <TableLoader />
                              </>
                            )}
                          </div>
                          {/*end::Row*/}

                        </div>
                        {/*end::Tap pane*/}
                        {/*begin::Tap pane*/}
                        <div className="tab-pane fade" id="snapshot-tabs">
                          <div
                            id="getsnapshot"
                            className="row g-9"
                            data-kt-buttons="true"
                          >

                            {snapLoader == true ? (
                              <>
                                <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                                  {/* <div className="small-spinner"></div> */}
                                  <TableLoader />
                                </div>
                              </>
                            ) : (
                              <>
                                {snapData != null ? (
                                  <>
                                    {snapData.length != 0 ? (
                                      <>
                                        {snapData.map((value, index) => (
                                          <>
                                            <div className="col-md-4 col-lg-4 col-xxl-4">
                                              <input
                                                className="btn-check deploy_checkbox snapshot_radio"
                                                type="radio"
                                                name="image_OS"
                                                defaultValue={value.id}
                                                id={"snapshotid" + value.id}
                                              />
                                              <label
                                                onClick={(e) =>
                                                  selectSnapshot(
                                                    value.id,
                                                    value.id,
                                                    value.size
                                                  )
                                                }
                                                htmlFor={
                                                  "snapshotid" + value.id
                                                }
                                                className=" label versionOs text-start border-1 border-gray-300 border px-6 py-1 d-flex"
                                              >
                                                <span className="svg-icon svg-icon-primary svg-icon-4x me-4">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={24}
                                                    height={24}
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                  >
                                                    <path
                                                      opacity="0.3"
                                                      d="M22 5V19C22 19.6 21.6 20 21 20H19.5L11.9 12.4C11.5 12 10.9 12 10.5 12.4L3 20C2.5 20 2 19.5 2 19V5C2 4.4 2.4 4 3 4H21C21.6 4 22 4.4 22 5ZM7.5 7C6.7 7 6 7.7 6 8.5C6 9.3 6.7 10 7.5 10C8.3 10 9 9.3 9 8.5C9 7.7 8.3 7 7.5 7Z"
                                                      fill="black"
                                                    />
                                                    <path
                                                      d="M19.1 10C18.7 9.60001 18.1 9.60001 17.7 10L10.7 17H2V19C2 19.6 2.4 20 3 20H21C21.6 20 22 19.6 22 19V12.9L19.1 10Z"
                                                      fill="black"
                                                    />
                                                  </svg>
                                                </span>
                                                <span className="d-block fw-bold text-start py-2">
                                                  <span className="text-dark fw-bolder d-block fs-6">
                                                    {value.name != null ? (value.name) : (value.id)}
                                                  </span>
                                                  <span className="text-muted fw-bold fs-6">
                                                    {" "}
                                                    {value.createdate}{" "}
                                                  </span>
                                                </span>
                                                {/*begin::Ribbon*/}
                                                <div
                                                  className="d-none tickMarksnapshot ribbon ribbon-triangle ribbon-top-start border-primary"
                                                  id={
                                                    "tickMarksnapshot-" +
                                                    value.id
                                                  }
                                                >
                                                  {/*begin::Ribbon icon*/}
                                                  <div className="ribbon-icon mt-n5">
                                                    <i className="bi bi-check2 fs-2 text-white" />
                                                  </div>
                                                  {/*end::Ribbon icon*/}
                                                </div>
                                                {/*end::Ribbon*/}
                                              </label>
                                            </div>
                                          </>
                                        ))}
                                      </>
                                    ) : (
                                      <>
                                        <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                                          {/*begin::Wrapper*/}

                                          <div className="pt-lg-10 mb-10">
                                            {/*begin::Message*/}
                                            <div className="fw-bold fs-5  mb-15 badge badge-light-primary">
                                              You don't have any Snapshots on
                                              this location
                                            </div>
                                            {/*end::Message*/}
                                          </div>
                                          {/*end::Wrapper*/}
                                        </div>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {/*begin::Content*/}
                                    <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                                      {/*begin::Wrapper*/}
                                      <div className="pt-lg-10 mb-10">
                                        {/*begin::Message*/}
                                        <div className="fw-bold fs-5  mb-15 badge badge-light-primary">
                                          Please select Datacenter Location to
                                          view snapshots list.
                                        </div>
                                        {/*end::Message*/}
                                      </div>
                                      {/*end::Wrapper*/}
                                    </div>
                                    {/*end::Content*/}
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                        {/*end::Tap pane*/}
                        {/*begin::Tap pane*/}
                        <div className="tab-pane fade" id="backups-tabs">
                          <div
                            id="getbackup"
                            className="row g-9"
                            data-kt-buttons="true"
                          >
                            {backupLoader == true ? (
                              <>
                                <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                                  {/* <div className="small-spinner"></div> */}
                                  <TableLoader />
                                </div>
                              </>
                            ) : (
                              <>
                                {backupData != null ? (
                                  <>
                                    {backupData.length != 0 ? (
                                      <>
                                        {backupData.map((value, index) => (
                                          <>
                                            <div className="col-md-4 col-lg-12 col-xxl-4">
                                              {/*begin::Option*/}
                                              <input
                                                className="btn-check deploy_checkbox backup_radio"
                                                type="radio"
                                                name="image_OS"
                                                defaultValue={value.id}
                                                id={"backupid" + value.id}
                                              />
                                              <label
                                                onClick={(e) =>
                                                  selectBackup(
                                                    value.id,
                                                    value.id,
                                                    value.size
                                                  )
                                                }
                                                htmlFor={"backupid" + value.id}
                                                className=" label versionOs text-start border-1 border-gray-300 border px-6 py-1 d-flex"
                                                style={{
                                                  height: "73.06px !important",
                                                }}
                                              >
                                                {/*begin::Svg Icon | path: icons/duotune/communication/com003.svg*/}
                                                <span className="svg-icon svg-icon-primary svg-icon-4x me-4">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={24}
                                                    height={24}
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                  >
                                                    <path
                                                      opacity="0.3"
                                                      d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM6.39999 9.89999C6.99999 8.19999 8.40001 6.9 10.1 6.4C10.6 6.2 10.9 5.7 10.7 5.1C10.5 4.6 9.99999 4.3 9.39999 4.5C7.09999 5.3 5.29999 7 4.39999 9.2C4.19999 9.7 4.5 10.3 5 10.5C5.1 10.5 5.19999 10.6 5.39999 10.6C5.89999 10.5 6.19999 10.2 6.39999 9.89999ZM14.8 19.5C17 18.7 18.8 16.9 19.6 14.7C19.8 14.2 19.5 13.6 19 13.4C18.5 13.2 17.9 13.5 17.7 14C17.1 15.7 15.8 17 14.1 17.6C13.6 17.8 13.3 18.4 13.5 18.9C13.6 19.3 14 19.6 14.4 19.6C14.5 19.6 14.6 19.6 14.8 19.5Z"
                                                      fill="black"
                                                    />
                                                    <path
                                                      d="M16 12C16 14.2 14.2 16 12 16C9.8 16 8 14.2 8 12C8 9.8 9.8 8 12 8C14.2 8 16 9.8 16 12ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
                                                      fill="black"
                                                    />
                                                  </svg>
                                                </span>
                                                {/*end::Svg Icon*/}
                                                <span className="d-block fw-bold text-start py-2">
                                                  <span className="text-dark fw-bolder d-block fs-6">
                                                    {value.id}
                                                  </span>
                                                  <span className="text-muted fw-bold fs-6">
                                                    {" "}
                                                    {value.created_at}
                                                  </span>
                                                </span>
                                                {/*begin::Ribbon*/}
                                                <div
                                                  className="d-none tickMarkbackup ribbon ribbon-triangle ribbon-top-start border-primary"
                                                  id={
                                                    "tickMarkbackup-" + value.id
                                                  }
                                                >
                                                  {/*begin::Ribbon icon*/}
                                                  <div className="ribbon-icon mt-n5">
                                                    <i className="bi bi-check2 fs-2 text-white" />
                                                  </div>
                                                  {/*end::Ribbon icon*/}
                                                </div>
                                                {/*end::Ribbon*/}
                                              </label>
                                              {/*end::Option*/}
                                            </div>
                                          </>
                                        ))}
                                      </>
                                    ) : (
                                      <>
                                        <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                                          {/*begin::Wrapper*/}

                                          <div className="pt-lg-10 mb-10">
                                            {/*begin::Message*/}
                                            <div className="fw-bold fs-5  mb-15 badge badge-light-primary">
                                              You don't have any Backups on this
                                              location
                                            </div>
                                            {/*end::Message*/}
                                          </div>
                                          {/*end::Wrapper*/}
                                        </div>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {/*begin::Content*/}
                                    <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                                      {/*begin::Wrapper*/}
                                      <div className="pt-lg-10 mb-10">
                                        {/*begin::Message*/}
                                        <div className="fw-bold fs-5  mb-15 badge badge-light-primary">
                                          Please select Datacenter Location to
                                          view backup list.
                                        </div>
                                        {/*end::Message*/}
                                      </div>
                                      {/*end::Wrapper*/}
                                    </div>
                                    {/*end::Content*/}
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                        {/*end::Tap pane*/}
                        {/*begin::Tap pane*/}
                        <div className="tab-pane fade" id="marketplace-tab">
                          {/*begin::Row*/}
                          <input type="hidden" name="stack" id="main_stack" />
                          <input type="hidden" name="stackimage" id="main_stackimage" />
                          <div
                            id="marketplace-data"
                            className="row g-9"
                            data-kt-buttons="true"
                            data-kt-buttons-target="[data-kt-button]"
                          >
                            {/*begin::Col*/}
                            {marketplaceLoader == false ? (
                              <>
                                {marketplaceData != null &&
                                  marketplaceData.length != 0 ? (
                                  <>
                                    {marketplaceData.map((value, index) => (
                                      <>
                                        <div className="col-md-4 col-lg-12 col-xxl-4">
                                          {/*begin::Option*/}
                                          <input
                                            className="btn-check deploy_checkbox marketplace_radio"
                                            type="radio"
                                            name="image_OS"
                                            defaultValue={value.id}
                                            id={"marketplace" + value.id}
                                          />
                                          <label
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              selectOne(
                                                "marketplace",
                                                value.id
                                              );
                                              selectMarketPlace("stack", value.id);
                                            }}
                                            htmlFor={"marketplace" + value.id}
                                            className=" label versionOs text-start border-1 border-gray-300 border px-6 py-2 d-flex"
                                          >
                                            {/*begin::Svg Icon | path: icons/duotune/communication/com003.svg*/}
                                            {/*begin::Avatar*/}
                                            <div className="symbol symbol-50px me-5">
                                              <img
                                                src={"/" + value.logo_url}
                                                className=""
                                                alt=""
                                              />
                                            </div>
                                            {/*end::Svg Icon*/}
                                            <span className="d-block  text-start p-3">
                                              <span className="text-dark d-block fs-4">
                                                {value.title}
                                              </span>
                                            </span>
                                            {/*begin::Ribbon*/}
                                            <div
                                              className="d-none tickMarkmarketplace ribbon ribbon-triangle ribbon-top-start border-primary"
                                              id={
                                                "tickMarkmarketplace-" +
                                                value.id
                                              }
                                            >
                                              {/*begin::Ribbon icon*/}
                                              <div className="ribbon-icon mt-n5">
                                                <i className="bi bi-check2 fs-2 text-white" />
                                              </div>
                                              {/*end::Ribbon icon*/}
                                            </div>
                                            {/*end::Ribbon*/}
                                          </label>
                                          {/*end::Option*/}
                                        </div>
                                      </>
                                    ))}
                                  </>
                                ) : (
                                  <>
                                    <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                                      {/*begin::Wrapper*/}

                                      <div className="pt-lg-10 mb-10">
                                        {/*begin::Message*/}
                                        <div className="fw-bold fs-5  mb-15 badge badge-light-primary">
                                          No Marketplace Available
                                        </div>
                                        {/*end::Message*/}
                                      </div>
                                      {/*end::Wrapper*/}
                                    </div>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                                  {/* <div className="small-spinner"></div> */}
                                  <TableLoader />
                                </div>
                              </>
                            )}

                            {/*end::Col*/}
                          </div>
                          {/*begin:: marketplace fields*/}
                          <div
                            className="col-xl-12 mb-xl-12 mt-10"
                            id="market_fields"
                          >
                            {currMarketplaceLoader == false ? (
                              <>
                                {currMarketplaceDistro != null ? (
                                  <>
                                    {currMarketplaceDistro.length != 0 ? (
                                      <>
                                        <div className="separator border-2 mb-5"></div>
                                        <div className="fv-row mb-10 fv-plugins-icon-container">
                                          <label className="form-label ">
                                            Select Stack Image
                                          </label>
                                          <select
                                            className="form-select form-select-sm image_field"
                                            onChange={(e) => selectStackImage("marketplace", e.target.value)}
                                          >
                                            <option value="">Select Image</option>
                                            {currMarketplaceDistro.map(
                                              (value, index) => (
                                                <>
                                                  <option value={value}>
                                                    {value}
                                                  </option>
                                                </>
                                              )
                                            )}
                                          </select>
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                                {currMarketplace != null ? (
                                  <>
                                    {Object.keys(currMarketplace).length !=
                                      0 ? (
                                      <>
                                        {currMarketplace.map((value, index) => (
                                          <>
                                            <div className="fv-row mb-10 fv-plugins-icon-container">
                                              <label className="form-label ">
                                                {value.label}
                                              </label>
                                              <input
                                                type="text"
                                                name={
                                                  "stack_fields[" +
                                                  index +
                                                  "][" +
                                                  value.name +
                                                  "]"
                                                }
                                                defaultValue=""
                                                placeholder={value.example}
                                                className="form-control form-control-sm form-control form-control-sm-lg"
                                                onChange={(e) => handleFieldChange(index, value.name, e.target.value)}
                                              />
                                            </div>
                                          </>
                                        ))}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <>
                                <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                                  {/* <div className="small-spinner"></div> */}
                                  <TableLoader />
                                </div>
                              </>
                            )}
                          </div>
                          {/*end::marketplace fields*/}
                          {/*end::Row*/}
                        </div>
                        {/*end::Tap pane*/}
                        {/*begin::Tap pane*/}
                        <div className="tab-pane fade show active" id="stack-tab">
                          <ul className="mx-0 mb-3 nav nav-stretch nav-line-tabs nav-line-tabs-2x border-trakt_charts_widget_1_chartnsparent fs-5 fw-bolder">
                            <li className="nav-item">
                              <a
                                className="nav-link text-active-primary py-5 me-6 fs-6 active"
                                data-bs-toggle="tab"
                                href="#private-stack-tabs"
                                id="private_tab"
                              >
                                Own Stacks
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link text-active-primary py-5 fs-6 me-6"
                                data-bs-toggle="tab"
                                href="#public-stack-tabs"
                                id="public_tab"
                              >
                                Community Stacks
                              </a>
                            </li>
                          </ul>
                          <div className="tab-content mt-5 pe-8">
                            <div className="tab-pane fade show active" id="private-stack-tabs">
                              {/*begin::Row*/}
                              <div
                                id="stack-private-data"
                                className="row g-9"
                                data-kt-buttons="true"
                                data-kt-buttons-target="[data-kt-button]"
                              >
                                {/*begin::Col*/}
                                {stackPrivateLoader == false ? (
                                  <>
                                    {stackPrivateData != null &&
                                      stackPrivateData.length != 0 ? (
                                      <>
                                        {stackPrivateData.map((value, index) => (
                                          <>
                                            <div className="col-md-4 col-lg-4 col-xl-4 col-sm-4 col-xxl-4">
                                              {/*begin::Option*/}
                                              <input
                                                className="btn-check deploy_checkbox marketplace_radio"
                                                type="radio"
                                                name="image_OS"
                                                defaultValue={value.id}
                                                id={"marketplace" + value.id}
                                              />
                                              <label
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  selectOne(
                                                    "marketplace",
                                                    value.id
                                                  );
                                                  selectMarketPlace("private", value.id);
                                                }}
                                                id={"private_stack_" + value.id}
                                                htmlFor={"marketplace" + value.id}
                                                className=" label versionOs text-start border-1 border-gray-300 border px-6 py-2 d-flex"
                                              >
                                                {/*begin::Svg Icon | path: icons/duotune/communication/com003.svg*/}
                                                {/*begin::Avatar*/}
                                                <div className="symbol symbol-50px me-5">
                                                  <img
                                                    src={"/assets/marketplace/private.svg"}
                                                    className="pb-2"
                                                    alt=""
                                                  />

                                                </div>
                                                {/*end::Svg Icon*/}
                                                <span className="d-block  text-start p-3">
                                                  <span className="text-dark d-block fs-4">
                                                    {value.title}
                                                  </span>
                                                </span>
                                                {/*begin::Ribbon*/}
                                                <div
                                                  className="d-none tickMarkmarketplace ribbon ribbon-triangle ribbon-top-start border-primary"
                                                  id={
                                                    "tickMarkmarketplace-" +
                                                    value.id
                                                  }
                                                >
                                                  {/*begin::Ribbon icon*/}
                                                  <div className="ribbon-icon mt-n5">
                                                    <i className="bi bi-check2 fs-2 text-white" />
                                                  </div>
                                                  {/*end::Ribbon icon*/}
                                                </div>
                                                {/*end::Ribbon*/}
                                              </label>
                                              {/*end::Option*/}
                                            </div>
                                          </>
                                        ))}
                                      </>
                                    ) : (
                                      <>
                                        <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                                          {/*begin::Wrapper*/}

                                          <div className="pt-lg-10 mb-10">
                                            {/*begin::Message*/}
                                            <div className="fw-bold fs-5  mb-15 badge badge-light-primary">
                                              No Stack Available
                                            </div>
                                            {/*end::Message*/}
                                          </div>
                                          {/*end::Wrapper*/}
                                        </div>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                                      {/* <div className="small-spinner"></div> */}
                                      <TableLoader />
                                    </div>
                                  </>
                                )}

                                {/*end::Col*/}
                              </div>
                              {/*end::Row*/}
                              {/*begin:: marketplace fields*/}
                              <div
                                className="col-xl-12 mb-xl-12 mt-10"
                                id="market_fields_private"
                              >
                                {currMarketplaceLoaderPrivate == false ? (
                                  <>
                                    {currMarketplaceDistroPrivate != null ? (
                                      <>
                                        {currMarketplaceDistroPrivate.length != 0 ? (
                                          <>
                                            <div className="separator border-2 mb-5"></div>
                                            <div className="fv-row mb-10 fv-plugins-icon-container">
                                              <label className="form-label ">
                                                Select Stack Image
                                              </label>
                                              <select
                                                className="form-select form-select-sm image_field"
                                                onChange={(e) => selectStackImage("stacks_private", e.target.value)}
                                              >
                                                <option value="">Select Image</option>
                                                {currMarketplaceDistroPrivate.map(
                                                  (value, index) => (
                                                    <>
                                                      <option value={value}>
                                                        {value}
                                                      </option>
                                                    </>
                                                  )
                                                )}
                                              </select>
                                            </div>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {currMarketplacePrivate != null ? (
                                      <>
                                        {Object.keys(currMarketplacePrivate).length !=
                                          0 ? (
                                          <>
                                            {currMarketplacePrivate.map((value, index) => (
                                              <>
                                                <div className="fv-row mb-10 fv-plugins-icon-container">
                                                  <label className="form-label ">
                                                    {value.label}
                                                  </label>
                                                  <input
                                                    type="text"
                                                    name={
                                                      "stack_fields[" +
                                                      index +
                                                      "][" +
                                                      value.name +
                                                      "]"
                                                    }
                                                    defaultValue=""
                                                    placeholder={value.example}
                                                    className="form-control form-control-sm form-control form-control-sm-lg"
                                                    onChange={(e) => handleFieldChange(index, value.name, e.target.value)}
                                                  />
                                                </div>
                                              </>
                                            ))}
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                                      {/* <div className="small-spinner"></div> */}
                                      <TableLoader />
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="tab-pane fade" id="public-stack-tabs">
                              {/*begin::Row*/}
                              <div
                                id="stack-data"
                                className="row g-9"
                                data-kt-buttons="true"
                                data-kt-buttons-target="[data-kt-button]"
                              >
                                {/*begin::Col*/}
                                {stackPublicLoader == false ? (
                                  <>
                                    {stackPublicData != null &&
                                      stackPublicData.length != 0 ? (
                                      <>
                                        {stackPublicData.map((value, index) => (
                                          <>
                                            <div className="col-md-4 col-lg-4 col-xl-4 col-sm-4 col-xxl-4">
                                              {/*begin::Option*/}
                                              <input
                                                className="btn-check deploy_checkbox marketplace_radio"
                                                type="radio"
                                                name="image_OS"
                                                defaultValue={value.id}
                                                id={"marketplace" + value.id}
                                              />
                                              <label
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  selectOne(
                                                    "marketplace",
                                                    value.id
                                                  );
                                                  selectMarketPlace("public", value.id);
                                                }}
                                                id={"public_stack_" + value.id}
                                                htmlFor={"marketplace" + value.id}
                                                className=" label versionOs text-start border-1 border-gray-300 border px-6 py-2 d-flex"
                                              >
                                                {/*begin::Svg Icon | path: icons/duotune/communication/com003.svg*/}
                                                {/*begin::Avatar*/}
                                                <div className="symbol symbol-50px me-5">
                                                  <img
                                                    src={"/assets/marketplace/public.svg"}
                                                    className="pb-2"
                                                    alt=""
                                                  />

                                                </div>
                                                {/*end::Svg Icon*/}
                                                <span className="d-block  text-start p-3">
                                                  <span className="text-dark d-block fs-4">
                                                    {value.title}
                                                  </span>
                                                </span>
                                                {/*begin::Ribbon*/}
                                                <div
                                                  className="d-none tickMarkmarketplace ribbon ribbon-triangle ribbon-top-start border-primary"
                                                  id={
                                                    "tickMarkmarketplace-" +
                                                    value.id
                                                  }
                                                >
                                                  {/*begin::Ribbon icon*/}
                                                  <div className="ribbon-icon mt-n5">
                                                    <i className="bi bi-check2 fs-2 text-white" />
                                                  </div>
                                                  {/*end::Ribbon icon*/}
                                                </div>
                                                {/*end::Ribbon*/}
                                              </label>
                                              {/*end::Option*/}
                                            </div>
                                          </>
                                        ))}
                                      </>
                                    ) : (
                                      <>
                                        <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                                          {/*begin::Wrapper*/}

                                          <div className="pt-lg-10 mb-10">
                                            {/*begin::Message*/}
                                            <div className="fw-bold fs-5  mb-15 badge badge-light-primary">
                                              No Stack Available
                                            </div>
                                            {/*end::Message*/}
                                          </div>
                                          {/*end::Wrapper*/}
                                        </div>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                                      {/* <div className="small-spinner"></div> */}
                                      <TableLoader />
                                    </div>
                                  </>
                                )}

                                {/*end::Col*/}
                              </div>
                              {/*end::Row*/}
                              {/*begin:: marketplace fields*/}
                              <div
                                className="col-xl-12 mb-xl-12 mt-10"
                                id="market_fields_public"
                              >
                                {currMarketplaceLoaderPublic == false ? (
                                  <>
                                    {currMarketplaceDistroPublic != null ? (
                                      <>
                                        {currMarketplaceDistroPublic.length != 0 ? (
                                          <>
                                            <div className="separator border-2 mb-5"></div>
                                            <div className="fv-row mb-10 fv-plugins-icon-container">
                                              <label className="form-label ">
                                                Select Stack Image
                                              </label>
                                              <select
                                                className="form-select form-select-sm image_field"
                                                onChange={(e) => selectStackImage("stacks_public", e.target.value)}
                                              >
                                                <option value="">Select Image</option>
                                                {currMarketplaceDistroPublic.map(
                                                  (value, index) => (
                                                    <>
                                                      <option value={value}>
                                                        {value}
                                                      </option>
                                                    </>
                                                  )
                                                )}
                                              </select>
                                            </div>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {currMarketplacePublic != null ? (
                                      <>
                                        {Object.keys(currMarketplacePublic).length !=
                                          0 ? (
                                          <>
                                            {currMarketplacePublic.map((value, index) => (
                                              <>
                                                <div className="fv-row mb-10 fv-plugins-icon-container">
                                                  <label className="form-label ">
                                                    {value.label}
                                                  </label>
                                                  <input
                                                    type="text"
                                                    name={
                                                      "stack_fields[" +
                                                      index +
                                                      "][" +
                                                      value.name +
                                                      "]"
                                                    }
                                                    defaultValue=""
                                                    placeholder={value.example}
                                                    className="form-control form-control-sm form-control form-control-sm-lg"
                                                    onChange={(e) => handleFieldChange(index, value.name, e.target.value)}
                                                  />
                                                </div>
                                              </>
                                            ))}
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                                      {/* <div className="small-spinner"></div> */}
                                      <TableLoader />
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          {/*end::marketplace fields*/}
                        </div>
                        {/*end::Tap pane*/}

                      </div>
                      {/*end::Tab Content*/}
                    </div>
                    {/*end: Card Body*/}
                    {/*end::List widget 10*/}
                  </div>
                  {/*end::Col*/}

                  {/*begin::Col*/}

                  {userData != null &&
                    planData != null &&
                    osDiskSize > 0 &&
                    props &&
                    props.deployInfo ? (
                    <>
                      <PricingContainerWithoutBillingCycle
                        updatePlan={updatedPlanInfo}
                        currBilling="hourly"
                        currPlanid={props.deployInfo[0].planid}
                        dcLocation={props.deployInfo[0].dcslug}
                        disk_size={osDiskSize}
                        user={userData}
                        plans={planData}
                      />
                    </>
                  ) : (
                    <>
                      <div className="col-xl-12 card mb-5">
                        <div className="card-header border-3 mb-5 pt-2 ps-6">
                          {/*begin::Title*/}
                          <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bolder text-gray-800">
                              Select Plan Type
                            </span>
                            <span className="text-gray-400 mt-1 fw-bold fs-6">
                              Basic/Dedicated CPU/Dedicated Memory
                            </span>
                          </h3>
                        </div>
                        <div className="mx-5 mb-3 pb-5">
                          {/* <div className="small-spinner"></div> */}
                          <TableLoader />
                        </div>
                      </div>
                    </>
                  )}
                  {/*end::Col*/}
                  {/*begin::Col*/}
                  {/* {vpcLoader ?
                    <>
                      <Vpc vpcData={vpcData} selectTickMark={selectTickMark} vpcd={props.deployInfo[0]} vpcLoader={vpcLoader} />
                    </> : <TableLoader />} */}
                  <div className="col-xl-12 card mb-5">
                    {/* begin::List widget 10*/}
                    {vpcData != null ? (<>
                      <div className="">
                        {/*begin::List widget 10*/}
                        <div className=" h-lg-100 mb-5" id="vpc_section">
                          {/*begin::Header*/}
                          <div className="card-header border-3 mb-5 pt-2 ps-6">
                            {/*begin::Title*/}
                            <h3 className="card-title align-items-start flex-column">
                              <span className="card-label fw-bolder text-gray-800">
                                VPC Network
                              </span>
                              <span className="text-gray-400 mt-1 fw-bold fs-6">
                                Select VPC for the Cluster
                              </span>
                            </h3>
                            {/*end::Title*/}
                          </div>
                          {vpcLoader == true ? (
                            <>
                              <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                                {/* <div className="small-spinner"></div> */}
                                <TableLoader />
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="mx-5 mb-3">
                                {/*begin::Radio group*/}
                                <div
                                  id="getvpc"
                                  className="row"
                                  data-kt-buttons="true"
                                >
                                  {vpcData != null ? (
                                    <>
                                      {Object.keys(vpcData).length != 0
                                        ? Object.entries(vpcData).map(
                                          (value, index) => (
                                            <>
                                              <div className="col-md-4 col-lg-4 col-xxl-4 mt-5">
                                                {/*begin::Option*/}
                                                <input
                                                  className="btn-check deploy_checkbox"
                                                  type="radio"
                                                  name="vpc"
                                                  defaultValue={value[1].id}
                                                  id={"vpc" + value[1].id}
                                                  value={vpc_id}
                                                />
                                                <label
                                                  htmlFor={"vpc" + value[1].id}
                                                  onClick={() => {
                                                    selectTickMark(value[1].id, "Vpc", "vpc")
                                                    props.deployInfo[0].vpc = value[1].id
                                                  }
                                                  }
                                                  className="borderVpc btn btn-outline btn-outline btn-outline-default  d-flex align-items-center"
                                                  id={"borderVpc-" + value[1].id}
                                                  style={{ overflow: "hidden" }}
                                                >
                                                  {/*begin::Svg Icon | path: icons/duotune/communication/com003.svg*/}
                                                  <span className="svg-icon svg-icon-primary svg-icon-4x me-1">
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width={24}
                                                      height={24}
                                                      viewBox="0 0 24 24"
                                                      fill="none"
                                                    >
                                                      <path
                                                        opacity="0.3"
                                                        d="M19 18C20.7 18 22 16.7 22 15C22 13.3 20.7 12 19 12C18.9 12 18.9 12 18.8 12C18.9 11.7 19 11.3 19 11C19 9.3 17.7 8 16 8C15.4 8 14.8 8.2 14.3 8.5C13.4 7 11.8 6 10 6C7.2 6 5 8.2 5 11C5 11.3 5.00001 11.7 5.10001 12H5C3.3 12 2 13.3 2 15C2 16.7 3.3 18 5 18H19Z"
                                                        fill="black"
                                                      />
                                                    </svg>
                                                  </span>
                                                  {/*end::Svg Icon*/}
                                                  <span className="d-block fw-bold text-start">
                                                    <span className="text-dark fw-bolder d-block fs-3">
                                                      {value[1].network}
                                                    </span>
                                                    <span className="text-muted fw-bold fs-8 w-100">
                                                      {" "}
                                                      {value[1].name}
                                                    </span>
                                                  </span>
                                                  <div
                                                    className="d-none tickMarkVpc ribbon ribbon-triangle ribbon-top-start border-primary"
                                                    id={
                                                      "tickMarkVpc-" + value[1].id
                                                    }
                                                  >
                                                    <div className="ribbon-icon mt-n5">
                                                      <i className="bi bi-check2 fs-2 text-white" />
                                                    </div>
                                                  </div>
                                                </label>
                                                {/*end::Option*/}
                                              </div>
                                            </>
                                          )
                                        )
                                        : ""}
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  <div className="col-md-4 col-lg-4 col-xxl-4 mt-5">
                                    {/*begin::Option*/}
                                    {/*begin::Radio button*/}
                                    {/* <a href="/vpc/deploy" target="_blank"> */}
                                    <input
                                      className="btn-check deploy_checkbox"
                                      type="radio"
                                      name="vpc"

                                    // defaultValue={value[1].id}
                                    // id={"vpc" + value[1].id}
                                    // value={vpc_id}
                                    />
                                    <label className="btn btn-outline btn-outline btn-outline-default d-flex flex-stack text-start" onClick={() => window.open("/vpc/deploy")}>
                                      {/*end::Description*/}
                                      <div className="d-flex align-items-center me-20 mt-5">
                                        {/*begin::Info*/}
                                        <div className="flex-grow-1">
                                          <div className="fw-bold opacity-50">
                                            {" "}
                                            Add New VPC
                                          </div>
                                        </div>
                                        {/*end::Info*/}
                                      </div>
                                      {/*end::Description*/} {/*begin::Price*/}
                                      <div className="ms-20 ps-20 mt-2">
                                        {/*begin::Svg Icon | path: icons/duotune/communication/com003.svg*/}
                                        <span className="svg-icon svg-icon-3x me-4">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                          >
                                            <rect
                                              opacity="0.3"
                                              x={2}
                                              y={2}
                                              width={20}
                                              height={20}
                                              rx={10}
                                              fill="black"
                                            />
                                            <rect
                                              x="10.8891"
                                              y="17.8033"
                                              width={12}
                                              height={2}
                                              rx={1}
                                              transform="rotate(-90 10.8891 17.8033)"
                                              fill="black"
                                            />
                                            <rect
                                              x="6.01041"
                                              y="10.9247"
                                              width={12}
                                              height={2}
                                              rx={1}
                                              fill="black"
                                            />
                                          </svg>
                                        </span>
                                        {/*end::Svg Icon*/}
                                      </div>
                                      {/*end::Price*/}
                                    </label>
                                    {/* </a> */}
                                    {/*end::Radio button*/}
                                    {/*end::Option*/}
                                  </div>
                                </div>
                                {/*end::Radio group*/}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </>) : ('')}

                    {/*end::List widget 10 */}
                  </div>
                  {/*end::Col*/}
                  {/*begin::Col*/}
                  <CpuModelTab setCpuValue={setCpuValue} cpuValue={cpuValue} recommendedCpu={recommendedCpu} setRecommendedCpu={setRecommendedCpu} />
                  <input
                    type="hidden"
                    name="cpumodel"
                    value={cpuValue}
                  />
                  <div className="col-xl-12 card mb-5">
                    {/*begin::List widget 10*/}
                    <div className=" h-lg-100 mb-5">
                      {/*begin::Header*/}
                      <div className="card-header border-3 mb-5 pt-2 ps-6">
                        {/*begin::Title*/}
                        <h3 className="card-title align-items-start flex-column">
                          <span className="card-label fw-bolder text-gray-800">
                            Add security group
                          </span>
                          <span className="text-gray-400 mt-1 fw-bold fs-6">
                            Select firewalls for the server
                          </span>
                        </h3>

                        {/*end::Title*/}
                      </div>
                      {/*end::Header*/}
                      {/*begin::Body*/}
                      <div className="mx-5 mb-3">
                        {/*begin::Radio group*/}
                        <div
                          className="row"
                          data-kt-buttons="true"
                        >
                          <div className="col-md-12 col-lg-12 col-xxl-12 mt-5">
                            <input class="form-control form-control-sm" onChange={(e) => updateNewFirewall(e)} id="firewall_field" />
                          </div>

                        </div>
                        {/*end::Radio group*/}
                      </div>
                      {/*end: Card Body*/}
                    </div>
                    {/*end::List widget 10*/}
                  </div>
                  {/*end::Col*/}
                  {/*begin::Col*/}
                  <div className="col-xl-12 card mb-5">
                    {/*begin::List widget 10*/}
                    <div className=" h-lg-100 mb-5">
                      {/*begin::Header*/}
                      <div className="card-header border-3 mb-5 pt-2 ps-6">
                        {/*begin::Title*/}
                        <h3 className="card-title align-items-start flex-column">
                          <span className="card-label fw-bolder text-gray-800">
                            Attach to an existing load balancer(Optional)
                          </span>
                          <span className="text-gray-400 mt-1 fw-bold fs-6">
                            Select loadbalancer for your auto scaling group
                          </span>
                        </h3>

                        {/*end::Title*/}
                      </div>
                      {/*end::Header*/}
                      {/*begin::Body*/}
                      <div className="mx-5 mb-3">
                        {/*begin::Radio group*/}
                        <div className="border-3 mb-5 pt-2">
                          <div className="p-1 border-3 pt-2">
                            <div data-kt-buttons="true" className="row">
                              <div className="col-6">
                                <label onClick={(e) => toggleAttachLb("application")} className="btn btn-outline btn-outline-dashed d-flex flex-stack text-start p-6 mb-5">
                                  <div className="d-flex align-items-center me-2">
                                    <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="auth"
                                        defaultValue="option1"
                                        defaultChecked={true}
                                      />
                                    </div>
                                    <div className="flex-grow-1">
                                      <h2 className="d-flex align-items-center fs-3 fw-bolder flex-wrap">
                                        Choose from your target groups
                                      </h2>
                                      <div className="fw-bold opacity-50">
                                        Select target group for your auto scaling group
                                      </div>
                                    </div>
                                  </div>
                                </label>
                              </div>
                              <div className="col-6">
                                <label onClick={(e) => toggleAttachLb("network")} className="btn btn-outline btn-outline-dashed d-flex flex-stack text-start p-6">
                                  <div className="d-flex align-items-center me-2">
                                    <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="auth"
                                        defaultValue="option2"
                                      />
                                    </div>
                                    <div className="flex-grow-1">
                                      <h2 className="d-flex align-items-center fs-3 fw-bolder flex-wrap">
                                        Choose from Network Load Balancer
                                      </h2>
                                      <div className="fw-bold opacity-50">
                                        Select network load balancer for your auto scaling group
                                      </div>
                                    </div>
                                  </div>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-12  mb-5" id="application-lb-sec">

                            <div className="pb-5 ps-2">
                              <label for="targetgroups" class="form-label">Existing load balancer target groups</label>
                              <input class="form-control form-control-sm" onChange={(e) => updateNewLoadbalancerTargetGroups(e)} id="loadbalancer_target_field" />
                            </div>
                          </div>
                          <div className="col-xl-12 mb-5 d-none" id="network-lb-sec">
                            <div className="pb-5 ps-2">
                              <label for="loadbalancers" class="form-label">Network load balancers</label>

                              <input class="form-control form-control-sm" onChange={(e) => updateNewLoadbalancer(e)} id="loadbalancer_field" />
                            </div>
                          </div>
                        </div>

                        {/* <div className="row"
                              data-kt-buttons="true"
                            >
                              <div className="col-md-12 col-lg-12 col-xxl-12 mt-5">
                              <input class="form-control form-control-sm" onChange={(e)=>updateNewLoadbalancer(e)} id="loadbalancer_field"/>
                              </div>
                               
                            </div> */}
                        {/*end::Radio group*/}
                      </div>
                      {/*end: Card Body*/}
                    </div>
                    {/*end::List widget 10*/}
                  </div>
                  {/*end::Col*/}

                  <div className="col-xl-12 card mb-5">
                    <div className="card-header border-3 mb-5 pt-2 ps-6">
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bolder text-gray-800">Instance Size</span>
                        <span className="text-gray-400 mt-1 fw-bold fs-6">
                          Min Size/Max Size/Desired Size
                        </span>
                      </h3>
                    </div>
                    <div className="mx-6 mb-3 pb-5">
                      <div
                        className="row g-9"
                        data-kt-buttons="true"
                        data-kt-buttons-target="[data-kt-button]"
                      >
                        <div className="col-md-4 col-lg-4 col-xxl-4">
                          <div className="mb-10">
                            <label htmlFor="ClusterLabel" className="required form-label">
                              Min Size
                            </label>
                            <input
                              className="form-control form-control-sm  "
                              type="number"
                              name="minsize"
                              id="min_size"
                              placeholder="Enter Min Size"
                              // onChange={(e) => { props.deployInfo[0].minsize = e.target.value }}
                              onChange={handleMinSizeChange}
                              defaultValue={props.deployInfo[0].minsize}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 col-lg-4 col-xxl-4">
                          <div className="mb-10">
                            <label htmlFor="ClusterLabel" className="required form-label">
                              Max Size
                            </label>
                            <input
                              className="form-control form-control-sm  "
                              type="number"
                              name="maxsize"
                              id="max_size"
                              placeholder="Enter Min Size"
                              onChange={handleMaxSizeChange}
                              // onChange={(e) => { props.deployInfo[0].maxsize = e.target.value }}
                              defaultValue={props.deployInfo[0].maxsize}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 col-lg-4 col-xxl-4">
                          <div className="mb-10">
                            <label htmlFor="ClusterLabel" className="required form-label">
                              Desired Size
                            </label>
                            <input
                              className="form-control form-control-sm  "
                              type="number"
                              name="desiredsize"
                              id="desired_size"
                              placeholder="Enter Desired Size"
                              onChange={handleDesiredSizeChange}
                              // onChange={(e) => { props.deployInfo[0].desiredsize = e.target.value }}
                              defaultValue={props.deployInfo[0].desiredsize}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-12 card mb-5">
                    <div className="card-header border-3 mb-5 pt-2 ps-6">
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bolder text-gray-800">Scaling Policy</span>
                        <span className="text-gray-400 mt-1 fw-bold fs-6">
                          Configure your Auto scaling policy here
                        </span>
                      </h3>

                    </div>
                    <div className="pb-5">
                      <div
                        id="kt_table_users_wrapper"
                        className="dataTables_wrapper dt-bootstrap4 no-footer"
                      >
                        <div className="table-responsive">
                          <table
                            className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
                            id="page_list"
                          >
                            <thead className="bg-light-dark">
                              <tr className="text-start fw-bolder fs-7 text-uppercase gs-0">
                                <th className="w-250px text-start ps-6">Name </th>
                                <th className="w-20px"></th>
                                <th className="w-150px text-center pe-5">Type </th>
                                <th className="w-20px"></th>
                                <th className="w-150px text-center">Compare</th>
                                <th className="w-20px"></th>
                                <th className="w-100px text-center">value</th>
                                <th className="w-20px"></th>
                                <th className="w-100px text-center pe-5">Adjust</th>
                                <th className="w-20px"></th>
                                <th className="w-150px text-center pe-5">Period</th>
                                <th className="w-20px"></th>
                                <th className="w-150px text-center px-2">CoolDown</th>
                                <th className="w-100px" />
                                <th className="w-100px" />
                              </tr>
                            </thead>
                            <tbody className="text-gray-600" id="con-data">
                              {/* {policyArr != null ? (<> */}
                              {policyArr.length > 0 ? (<>
                                {policyArr.map((value, index) => (<>
                                  <tr className="openCustomer">
                                    <td className="text-start ps-6">
                                      <input
                                        type="text"
                                        name="name"
                                        className="form-control form-control-sm"
                                        placeholder="Enter Name"
                                        onChange={(e) => modifyPolicies("update", index, { policyname: e.target.value, policytype: value.policytype, compare: value.compare, value: value.value, adjust: value.adjust, period: value.period, cooldown: value.cooldown })}
                                        value={value?.policyname}
                                      />
                                    </td>
                                    <td></td>
                                    <td className="text-center">
                                      <select className="form-select form-select-sm" value={value.policytype} name="type" id="type" onChange={(e) => modifyPolicies("update", index, { policyname: value.policyname, policytype: e.target.value, compare: value.compare, value: value.value, adjust: value.adjust, period: value.period, cooldown: value.cooldown })}>
                                        <option value="cpu">CPU</option>
                                        <option value="memory_utilization_percent">RAM</option>
                                      </select>
                                    </td>
                                    <td></td>
                                    <td className="text-center">
                                      <select className="form-select form-select-sm" id={"compare" + index} name="compare" value={value.compare} onChange={(e) => { manageAdjustment(index, e, value) }}>
                                        <option value="above">Is above</option>
                                        <option value="below">Is below</option>
                                        {/* <option value="equalto">Is equal-to</option> */}
                                      </select>
                                    </td>
                                    <td></td>
                                    <td className="text-center">
                                      <input
                                        type="number"
                                        id="value"
                                        name="value"
                                        className="form-control form-control-sm"
                                        value={value.value}
                                        onChange={(e) => modifyPolicies("update", index, { policyname: value.policyname, policytype: value.policytype, compare: value.compare, value: e.target.value.replace(/[^0-9]/g, ''), adjust: value.adjust, period: value.period, cooldown: value.cooldown })}
                                      />
                                    </td>
                                    <td></td>
                                    <td className="text-center">
                                      <div className="d-flex align-items-center">
                                        {/*begin::Dialer*/}
                                        <div
                                          className="input-group w-md-150px"
                                          data-kt-dialer="true"
                                        >
                                          {/*begin::Decrease control*/}
                                          <a
                                            className="btn btn-sm btn-icon btn-outline btn-outline-secondary"
                                            style={{ height: "auto" }}
                                            onClick={(e) => updateAdjustmentValue("adjust_field_" + index + "", "down", index, "policy", value, "adjust")}
                                          >
                                            <i className="bi bi-dash fs-1" />
                                          </a>
                                          {/*end::Decrease control*/}
                                          {/*begin::Input control*/}
                                          <input
                                            type="text"
                                            className="form-control form-control-sm text-center"
                                            readOnly={true}
                                            id={"adjust_field_" + index}
                                            value={value.adjust}
                                            onChange={(e) => modifyPolicies("update", index, { policyname: value.policyname, policytype: value.policytype, compare: value.compare, value: value.value, adjust: e.target.value, period: value.period, cooldown: value.cooldown })}
                                          />
                                          {/*end::Input control*/}
                                          {/*begin::Increase control*/}
                                          <a
                                            className="btn btn-sm btn-icon btn-outline btn-outline-secondary"
                                            style={{ height: "auto" }}
                                            onClick={(e) => updateAdjustmentValue("adjust_field_" + index + "", "up", index, "policy", value, "adjust")}

                                          >
                                            <i className="bi bi-plus fs-1" />
                                          </a>
                                          {/*end::Increase control*/}
                                        </div>
                                        {/*end::Dialer*/}
                                      </div>
                                    </td>
                                    <td></td>
                                    <td>
                                      <select className='form-select form-select-sm' id="period" name="period" value={value.period} onChange={(e) => modifyPolicies("update", index, { policyname: value.policyname, policytype: value.policytype, compare: value.compare, value: value.value, adjust: value.adjust, period: e.target.value, cooldown: value.cooldown })}>
                                        <option value="5m">5 minutes</option>
                                        <option value="10m">10 minutes</option>
                                        <option value="30m">30 minutes</option>
                                        <option value="1h">1 hour</option>
                                        <option value="3h">3 hours</option>
                                        <option value="6h">6 hours</option>
                                        <option value="12h">12 hours</option>
                                        <option value="24h">24 hours</option>
                                      </select>
                                    </td>
                                    <td></td>
                                    <td className="text-center">
                                      <div className="input-group">
                                        <input
                                          type="number"
                                          id="cooldown"
                                          name="cooldown"
                                          className="form-control form-control-sm"
                                          value={value.cooldown}
                                          aria-describedby="basic-addon1"
                                          onChange={(e) => modifyPolicies("update", index, { policyname: value.policyname, policytype: value.policytype, compare: value.compare, value: value.value, adjust: value.adjust, period: value.period, cooldown: e.target.value.replace(/[^0-9]/g, '') })}
                                        />
                                        <span className="input-group-text" style={{ lineHeight: "12px" }} id="basic-addon1">sec</span>
                                      </div>
                                    </td>
                                    <td></td>
                                    <td className="text-center">
                                      {policyArr.length > 1 ? (<>
                                        <a
                                          onClick={(e) => modifyPolicies('delete', index, {})}
                                          className="btn btn-icon btn-light-danger"
                                        >
                                          {" "}
                                          <span className="svg-icon svg-icon-muted svg-icon-2">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width={24}
                                              height={24}
                                              viewBox="0 0 24 24"
                                              fill="none"
                                            >
                                              <path
                                                opacity="0.3"
                                                d="M12 10.6L14.8 7.8C15.2 7.4 15.8 7.4 16.2 7.8C16.6 8.2 16.6 8.80002 16.2 9.20002L13.4 12L12 10.6ZM10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 8.99999 16.4 9.19999 16.2L12 13.4L10.6 12Z"
                                                fill="black"
                                              />
                                              <path
                                                d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM13.4 12L16.2 9.20001C16.6 8.80001 16.6 8.19999 16.2 7.79999C15.8 7.39999 15.2 7.39999 14.8 7.79999L12 10.6L9.2 7.79999C8.8 7.39999 8.2 7.39999 7.8 7.79999C7.4 8.19999 7.4 8.80001 7.8 9.20001L10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 9 16.4 9.2 16.2L12 13.4L14.8 16.2C15 16.4 15.3 16.5 15.5 16.5C15.7 16.5 16 16.4 16.2 16.2C16.6 15.8 16.6 15.2 16.2 14.8L13.4 12Z"
                                                fill="black"
                                              />
                                            </svg>
                                          </span>{" "}
                                        </a>
                                      </>) : ('')}
                                    </td>
                                  </tr>
                                </>))}
                              </>) : ('')}
                              {/* </>) : ('')} */}
                              <tr>
                                <td colSpan={16} className="text-end">
                                  <a
                                    onClick={(e) => modifyPolicies('add', 0, {})}
                                    className="btn btn-sm btn-primary"
                                    style={{ marginRight: 20 }}
                                  >
                                    {/*begin::Svg Icon | path: assets/media/icons/duotune/files/fil013.svg*/}
                                    <span className="svg-icon svg-icon-muted svg-icon-2">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                      >
                                        <path
                                          opacity="0.3"
                                          d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z"
                                          fill="black"
                                        />
                                        <path
                                          d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.2C9.7 3 10.2 3.20001 10.4 3.60001ZM16 12H13V9C13 8.4 12.6 8 12 8C11.4 8 11 8.4 11 9V12H8C7.4 12 7 12.4 7 13C7 13.6 7.4 14 8 14H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
                                          fill="black"
                                        />
                                        <path
                                          opacity="0.3"
                                          d="M11 14H8C7.4 14 7 13.6 7 13C7 12.4 7.4 12 8 12H11V14ZM16 12H13V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
                                          fill="black"
                                        />
                                      </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                    {/*end::Svg Icon*/}
                                    Add New
                                  </a></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div className="col-xl-12 card mb-5">
                    <div className="card-header border-3 mb-5 pt-2 ps-6">
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bolder text-gray-800">Schedules</span>
                        <span className="text-gray-400 mt-1 fw-bold fs-6">
                          Configure your auto scaling schedules here
                        </span>
                      </h3>
                    </div>
                    <div className="pb-5">
                      <div
                        id="kt_table_users_wrapper"
                        className="dataTables_wrapper dt-bootstrap4 no-footer"
                      >
                        <div className="table-responsive">
                          <table
                            className="table table-row-dashed border table-row-bordered gy-5 gs-7"
                            id="page_list"
                          >
                            <thead className="bg-light-dark">
                              <tr className="text-start fw-bolder fs-7 text-uppercase gs-0">
                                <th className="w-300px text-start ps-6">Name </th>
                                <th className="w-150px text-center sphide">Desired size </th>
                                <th className="w-150px text-center">Time Zone</th>
                                <th className="w-200px text-center">Recurrence</th>
                                <th className="w-250px text-center pe-7">Day</th>
                                <th className="w-100px text-center">Time</th>
                                <th className="w-300px  text-center sphide pe-9">Start At</th>
                                <th className="w-100px" />
                              </tr>
                            </thead>
                            <tbody className="text-gray-600" id="con-data">
                              {/* {scheduleArr != null ? (<> */}
                              {scheduleArr != null && scheduleArr.length > 0 ? (<>
                                {scheduleArr.map((value, index) => {
                                  const dayName = currentDateTime[index]?.toLocaleDateString('en-GB', { weekday: 'long' });
                                  // console.log(dayName);
                                  console.log(currentDateTime[index]?.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }))
                                  return (
                                    <>
                                      {/* {console.log(value)} */}
                                      <tr className="openCustomer">
                                        <td className="text-start ps-6">
                                          <input
                                            type="text"
                                            name="name"
                                            className="form-control form-control-sm"
                                            placeholder="Enter Name"
                                            defaultValue={value.name}
                                            onChange={(e) => modifySchedules('update', index, { name: e.target.value, desiredsize: value.desiredsize, recurrence: value.recurrence, start_date: value.start_date, recurrence_duration: value.recurrence_duration, recurrence_week: value.recurrence_week, selectedTime: value.selectedTime, selectedDate: value.selectedDate })}
                                          />
                                        </td>
                                        <td className="text-center">
                                          <div className="d-flex align-items-center">
                                            {/*begin::Dialer*/}
                                            <div
                                              className="input-group w-md-150px"
                                              data-kt-dialer="true"
                                            >
                                              {/*begin::Decrease control*/}
                                              <a
                                                className="btn btn-sm btn-icon btn-outline btn-outline-secondary"
                                                style={{ height: "auto" }}
                                                onClick={(e) => updateSchedulesAdjustmentValue("desiredsize_" + index + "", "down", index, "schedule", value)}
                                              >
                                                <i className="bi bi-dash fs-1" />
                                              </a>
                                              {/*end::Decrease control*/}
                                              {/*begin::Input control*/}
                                              <input
                                                type="text"
                                                name="desiredsize"
                                                className="form-control form-control-sm text-center"
                                                readOnly={true}
                                                id={"desiredsize_" + index}
                                                value={value.desiredsize}
                                                onChange={(e) => modifySchedules('update', index, { name: value.name, desiredsize: e.target.value, recurrence: value.recurrence, start_date: value.start_date, recurrence_duration: value.recurrence_duration, recurrence_week: value.recurrence_week, selectedTime: value.selectedTime, selectedDate: value.selectedDate })}
                                              />
                                              {/*end::Input control*/}
                                              {/*begin::Increase control*/}
                                              <a
                                                className="btn btn-sm btn-icon btn-outline btn-outline-secondary"
                                                style={{ height: "auto" }}
                                                onClick={(e) => updateSchedulesAdjustmentValue("desiredsize_" + index + "", "up", index, "schedule", value)}
                                              >
                                                <i className="bi bi-plus fs-1" />
                                              </a>
                                              {/*end::Increase control*/}
                                            </div>
                                            {/*end::Dialer*/}
                                          </div>
                                        </td>
                                        <td>
                                          <select className='form-select form-select-sm' id="ist" name="ist" defaultValue="IST">
                                            <option value="IST">IST</option>
                                          </select>
                                        </td>
                                        <td>
                                          {/* {updateRecurrence("" + index + "", {
                                        name: value.name,
                                        desiredsize: value.desiredsize,
                                        recurrence: value.recurrence,
                                        start_date: value.start_date,
                                        recurrence_duration: value.recurrence_duration,
                                        recurrence_week: value.recurrence_week,
                                        selectedTime: value.selectedTime,
                                        selectedDate: value.selectedDate
                                      }, value.recurrence_duration)} */}
                                          <select className='form-select form-select-sm' id={"recurrence_duration_" + index} defaultValue={value.recurrence_duration} onChange={(e) => {
                                            console.log(e.target.value);

                                            updateRecurrence("" + index + "", {
                                              name: value.name,
                                              desiredsize: value.desiredsize,
                                              recurrence: e.target.value,
                                              start_date: value.start_date,
                                              recurrence_duration: e.target.value,
                                              recurrence_week: value.recurrence_week,
                                              selectedTime: value.selectedTime,
                                              selectedDate: value.selectedDate
                                            }, e.target.value)
                                          }}>
                                            <option value="Cron">Cron</option>
                                            <option value="Every 5 Min">Every 5 Min</option>
                                            <option value="Every 30 Min">Every 30 Min</option>
                                            <option value="Every 1 hour">Every 1 hour</option>
                                            <option value="Every day">Every day</option>
                                            <option value="Every week">Every week</option>
                                            <option value="Once">Once</option>
                                          </select>
                                          <input type="hidden" defaultValue={value.recurrence} id={"recurrence_" + index} onChange={(e) => modifySchedules('update', index, { name: value.name, desiredsize: value.desiredsize, recurrence: e.target.value, start_date: value.start_date, recurrence_duration: value.recurrence_duration, recurrence_week: value.recurrence_week, selectedTime: value.selectedTime, selectedDate: value.selectedDate })} />
                                          <input type="hidden" defaultValue={value.start_date} id={"start_date_" + index} onChange={(e) => modifySchedules('update', index, { name: value.name, desiredsize: value.desiredsize, recurrence: value.recurrence, start_date: e.target.value, recurrence_duration: value.recurrence_duration, recurrence_week: value.recurrence_week, selectedTime: value.selectedTime, selectedDate: value.selectedDate })} />

                                        </td>
                                        <td className="text-center">
                                          <span className="" id={"week_sec_" + index}>
                                            <select className='form-select form-select-sm' id={"week_value" + index} value={dayName}
                                              onChange={(e) => {
                                                const selectedDay = e.target.value;

                                                // Map the days to their numeric representations
                                                const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                                                const currentDayIndex = currentDateTime[index].getDay(); // Get the current day as a number (0-6)
                                                const selectedDayIndex = daysOfWeek.indexOf(selectedDay); // Get the index of the selected day

                                                // Calculate the difference between the selected day and the current day
                                                let dayDifference = (selectedDayIndex - currentDayIndex + 7) % 7;

                                                // If the selected day is today but we want the next occurrence, set the difference to 7 days
                                                if (dayDifference === 0) {
                                                  dayDifference = 7;
                                                }

                                                // Calculate the new date
                                                const newDate = new Date(currentDateTime[index]);
                                                newDate.setDate(newDate.getDate() + dayDifference);

                                                console.log(`Selected Day: ${selectedDay}, New Date: ${newDate}`, value);

                                                // Update the state with the new date and change the recurrence
                                                handleChangeData(index, newDate);
                                                changeWeekRecurrence(index, {
                                                  name: value.name,
                                                  desiredsize: value.desiredsize,
                                                  recurrence: value.recurrence,
                                                  start_date: value.start_date,
                                                  recurrence_duration: value.recurrence_duration,
                                                  recurrence_week: selectedDay, // The newly selected day
                                                  selectedTime: value.selectedTime,
                                                  selectedDate: value.selectedDate
                                                }, selectedDay);
                                              }}
                                            >
                                              {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
                                                <option key={day} value={day}>
                                                  {day}
                                                </option>
                                              ))}
                                            </select>
                                          </span>
                                        </td>
                                        <td className="text-center d-none">
                                          <span className="" id={"week_sec_" + index}>
                                            {/* {value.recurrence_duration=="Every week"?(<> */}
                                            <select className='form-select form-select-sm' id={"week_value" + index} value={dayName}
                                              onChange={(e) => {
                                                const selectedDay = e.target.value;
                                                console.log(selectedDay);
                                                console.log(dayName);
                                                if (selectedDay !== dayName) {
                                                  changeWeekRecurrence(index, {
                                                    name: value.name,
                                                    desiredsize: value.desiredsize,
                                                    recurrence: value.recurrence,
                                                    start_date: value.start_date,
                                                    recurrence_duration: value.recurrence_duration,
                                                    recurrence_week: e.target.value,
                                                    selectedTime: value.selectedTime,
                                                    selectedDate: value.selectedDate
                                                  }, e.target.value)
                                                  const currentDate = new Date(currentDateTime);
                                                  const selectedValue = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].indexOf(e.target.value);
                                                  const newDate = currentDate.setDate(currentDate.getDate() + (7 + selectedValue - currentDate.getDay()) % 7);
                                                  setCurrentDateTime(new Date(newDate));
                                                }
                                              }}
                                            >
                                              {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
                                                <option key={day} value={day}>
                                                  {day}
                                                </option>
                                              ))}
                                            </select>
                                            {/* </>):(<>
                              <input type="hidden" defaultValue={value.recurrence_duration} id={"week_value"+index}  />
                              </>)} */}
                                          </span>
                                          <span id={"date_sec_" + index}>
                                            {/* {value.recurrence_duration=="Once" || value.recurrence_duration=="Crone"?(<>
                            <input
                                type="date" 
                                className="form-control form-control-sm"  
                                id={"date_value_"+index}
                                defaultValue={value.selectedDate}
                                onBlur={(e)=>modifySchedules('update', index, {name:value.name, desiredsize:value.desiredsize, start_date: value.start_date,recurrence_duration:value.recurrence_duration,recurrence_week: value.recurrence_week, selectedTime:value.selectedTime, selectedDate:e.target.value})}
                              />
                              </>):(<>  */}
                                            <input
                                              type="hidden"
                                              className="form-control form-control-sm"
                                              id={"date_value_" + index}
                                              defaultValue={value.selectedDate}
                                            />
                                            {/* </>)} */}
                                          </span>
                                        </td>
                                        <td className="text-center">
                                          <span id={"time_sec_" + index}>
                                            {/* {value.recurrence_duration=="Crone" || value.recurrence_duration=="Once" || value.recurrence_duration=="Every day" || value.recurrence_duration=="Every week"?(<> */}
                                            <input
                                              type="time"
                                              id={"time_value" + index}
                                              className="form-control form-control-sm"
                                              // defaultValue={currentDateTime.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })}
                                              // value={currentDateTime.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })}
                                              value={currentDateTime[index]
                                                ? currentDateTime[index].toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })
                                                : ""}
                                              onChange={(e) => {
                                                const [hours, minutes] = e.target.value.split(':');
                                                const updatedDateTime = new Date(currentDateTime[index]);
                                                updatedDateTime.setHours(hours);
                                                updatedDateTime.setMinutes(minutes);
                                                // setCurrentDateTime(updatedDateTime);
                                                handleChangeData(index, updatedDateTime);
                                              }}
                                              onBlur={(e) => {
                                                changeTimeRecurrence(index, {
                                                  name: value.name,
                                                  desiredsize: value.desiredsize,
                                                  recurrence: value.recurrence,
                                                  start_date: value.start_date,
                                                  recurrence_duration: value.recurrence_duration,
                                                  recurrence_week: value.recurrence_week,
                                                  selectedTime: e.target.value,
                                                  selectedDate: value.selectedDate
                                                }, e.target.value)
                                              }}
                                            />
                                            {/* </>):(<>
                                  <input
                                type="hidden" 
                                id={"time_value"+index}
                                className="form-control form-control-sm"  
                                defaultValue={value.selectedTime} 
                                />
                                </>)} */}
                                          </span>
                                        </td>
                                        <td>
                                          <div>
                                            <DatePicker
                                              className="form-control form-control-sm"
                                              selected={currentDateTime[index]}
                                              minDate={new Date()}
                                              minTime={minTime(index)}
                                              maxTime={maxTime(index)}
                                              // defaultValue={value.start_date}
                                              onChange={(date) => handleChangeData(index, date)}
                                              onSelect={(eValue) => modifySchedules('update', index, { name: value.name, desiredsize: value.desiredsize, recurrence: value.recurrence, start_date: eValue, recurrence_duration: value.recurrence_duration, recurrence_week: value.recurrence_week, selectedTime: value.selectedTime, selectedDate: value.selectedDate })}
                                              showTimeSelect
                                              timeFormat="HH:mm"
                                              timeIntervals={15}
                                              timeCaption="time"
                                              dateFormat="MMMM d, yyyy h:mm aa"
                                              popperPlacement="left" // Set the tooltip placement to top
                                              locale="en-US"
                                            />
                                          </div>
                                        </td>
                                        <td className="text-center">
                                          {/* {scheduleArr.length>1?(<> */}
                                          <div
                                            onClick={(e) => modifySchedules('delete', index, {})}
                                            className="btn btn-icon btn-light-danger"
                                          >
                                            {" "}
                                            <span className="svg-icon svg-icon-muted svg-icon-2">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                              >
                                                <path
                                                  opacity="0.3"
                                                  d="M12 10.6L14.8 7.8C15.2 7.4 15.8 7.4 16.2 7.8C16.6 8.2 16.6 8.80002 16.2 9.20002L13.4 12L12 10.6ZM10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 8.99999 16.4 9.19999 16.2L12 13.4L10.6 12Z"
                                                  fill="black"
                                                />
                                                <path
                                                  d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM13.4 12L16.2 9.20001C16.6 8.80001 16.6 8.19999 16.2 7.79999C15.8 7.39999 15.2 7.39999 14.8 7.79999L12 10.6L9.2 7.79999C8.8 7.39999 8.2 7.39999 7.8 7.79999C7.4 8.19999 7.4 8.80001 7.8 9.20001L10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 9 16.4 9.2 16.2L12 13.4L14.8 16.2C15 16.4 15.3 16.5 15.5 16.5C15.7 16.5 16 16.4 16.2 16.2C16.6 15.8 16.6 15.2 16.2 14.8L13.4 12Z"
                                                  fill="black"
                                                />
                                              </svg>
                                            </span>{" "}
                                          </div>
                                          {/* </>):('')} */}
                                        </td>
                                      </tr>
                                    </>)
                                })}
                              </>) : ('')}
                              {/* </>) : ('')} */}
                              <tr>
                                <td colSpan={12} className="text-end">
                                  <div
                                    onClick={(e) => modifySchedules('add', 0, {})}
                                    className="btn btn-sm btn-primary"
                                    style={{ marginRight: 20 }}
                                  >
                                    {/*begin::Svg Icon | path: assets/media/icons/duotune/files/fil013.svg*/}
                                    <span className="svg-icon svg-icon-muted svg-icon-2">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                      >
                                        <path
                                          opacity="0.3"
                                          d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z"
                                          fill="black"
                                        />
                                        <path
                                          d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.2C9.7 3 10.2 3.20001 10.4 3.60001ZM16 12H13V9C13 8.4 12.6 8 12 8C11.4 8 11 8.4 11 9V12H8C7.4 12 7 12.4 7 13C7 13.6 7.4 14 8 14H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
                                          fill="black"
                                        />
                                        <path
                                          opacity="0.3"
                                          d="M11 14H8C7.4 14 7 13.6 7 13C7 12.4 7.4 12 8 12H11V14ZM16 12H13V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
                                          fill="black"
                                        />
                                      </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                    {/*end::Svg Icon*/}
                                    Add New
                                  </div></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-12 card mb-5">
                    {/*begin::List widget 10*/}
                    {/*begin::Header*/}
                    <div className="card-header ps-6">
                      {/*begin::Title*/}
                      <h3 className="card-title align-items-start text-blue flex-column">
                        <span className="card-label fw-bolder text-gray-800">
                          Server Label
                        </span>
                      </h3>
                      {/*end::Title*/}
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}

                    <div className="row card-body ps-6">
                      <div className="col-md-6 col-lg-6 col-xxl-6">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="name"
                          onChange={(e) => { props.deployInfo[0].name = e.target.value }}
                          defaultValue={props.deployInfo[0].name}
                          placeholder="Auto-Scaling!.utho"
                        />
                      </div>
                    </div>

                    {/*end: Card Body*/}
                    {/*end::List widget 10*/}
                  </div>

                  <div className="col-xl-12 card mb-5">
                    {/*begin::List widget 10*/}
                    {/*begin::Header*/}
                    <div className="card-header ps-6">
                      {/*begin::Title*/}
                      <h3 className="card-title align-items-start text-blue flex-column">
                        <span className="card-label fw-bolder text-gray-800">
                          Create Auto Scaling
                        </span>
                      </h3>
                      {/*end::Title*/}
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}

                    <div className=" row card-body">
                      <div className="btn btn-primary w-100" id="as-btn" onClick={AddAutoScaling}>Create Auto Scaling</div>
                    </div>

                    {/*end: Card Body*/}
                    {/*end::List widget 10*/}
                  </div>

                </div>
                {/*end::Tables Widget 9*/}
              </form>
            </div>
          </div>
        </>
      ) : (
        <>
          <TopBarHeaderLine />
          <TableLoader />

        </>
      )}

    </>
  );
};

const mapStateToProps = (state) => ({
  deployInfo: state.autoScalingReducerData.autoScalingData,
});

const mapDispatchToProps = (dispatch) => ({
  updateAutoScalingData: (data) => dispatch(DeployAutoScalingInfo(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeployAutoScaling);
