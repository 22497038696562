import axios from 'axios';
import React from 'react'
import GitHubLogin from "react-github-login"
import axiosJsonp from 'axios-jsonp';
import UseDirectApi from '../../../../CustomHooks/useDirectApi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

export default function LoginWithGitHub() {
  var current_url_string = window.location.href;
  var vars = current_url_string.split("/");
  var loginType = vars[3];
  const current_url = new URL(current_url_string);
  const current_domain = current_url.hostname;
  const navigate = useNavigate();
  const onSuccess = async (response) => {
    const dt = toast.loading("Please wait...");
    const params = {
      client_id: "47ced66b0a03731b4734",
      client_secert: "df3443cf34a447182b4b4020337ae8255be11d3a",
      code: response.code,
    };
    console.log(params);
    // Step 1: Generate a random 5-digit number
    const random5Digit = Math.floor(10000 + Math.random() * 90000);

    // Step 2: Generate a random 7-digit number
    const random7Digit = Math.floor(1000000 + Math.random() * 9000000);

    // Original number
    const originalNumber = params.code;

    // Step 3: Append the 5-digit number to the beginning
    const modifiedNumber1 = `${random5Digit}${originalNumber}`;

    // Step 4: Append the 7-digit number to the end
    const modifiedNumber2 = `${modifiedNumber1}${random7Digit}`;
    // You can send tokenId to your server for further processing

    // Step 5: Encode the final number (optional)
    const encodedNumber = btoa(modifiedNumber2);
    try {
      let obj = { oauth_github_uid: encodedNumber }
      let responseData = await UseDirectApi("post", "oauth", obj)
      console.log(responseData,"responseData");
      if (responseData.status == 'signupsuccess' || 'loginsuccess') {
        toast.update(dt, {
          render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        localStorage.setItem("Auth", responseData.token);
        Cookies.set('microhost_api_auth', responseData.token, { expires: 30, path: '/', domain: "." + current_domain });
        var baseUrl;
        const platformName = localStorage.getItem("platformType");
        if (platformName) {
          if (platformName == "dev") {
            baseUrl = "https://dev.api.cloudplatformapp.com";
          }else{
            baseUrl = "https://api.utho.com";
          }
        }
        const apiUrl = baseUrl + '/v2/appinfo';
        const headers = {
          'Content-Type': 'application/json', // Adjust as needed
        };

        // Define the Fetch options
        const fetchOptions = {
          method: 'GET',
          headers: headers // Change to 'POST', 'PUT', 'DELETE', etc. if needed 
        };

        const AppData = fetch(apiUrl, fetchOptions)
          .then(response => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json(); // Parse the response body as JSON
          })
          .then(data => {
            if (data) {
              localStorage.setItem("appInfo", JSON.stringify(data));
            }

          })
          .catch(error => {
            console.error('API request failed:', error);
          });

        if (AppData) {
          setTimeout(() => {
            navigate("/");
          }, 2000)
          // const location_url = window.location.origin;
          //  window.location.href = location_url;
        }
      };
      if (responseData.status == 'loginfailed' || responseData.status == "error") {
        toast.update(dt, {
          render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
      }
    } catch (error) {

    }
}

  const onFailure = error => {
    console.error('Error:', error);
    // Handle the failed authentication response here
  };
  return (
    // // <div className='btn-light-primary fw-bolder w-100 mb-10'>
    //   // <div className='h-20px me-3 '>
    //     {/* <img
    // className='me-3'
    //   src="assets/media/svg/brand-logos/google-icon.svg"
    //   alt="Google icon"
    // /> */}
    //     {/* Google */}
    //     {/* {showGoogleLogin && ( */}
    <>
      <GitHubLogin
        clientId="47ced66b0a03731b4734"
        onSuccess={onSuccess}
        onFailure={onFailure}
        // buttonText="LOGIN WITH GITHUB"
        className="githubbutton btn"
        // style={{position:'relative',marginRight:'12.5px',top:'-0.5rem'}}
        redirectUri="https://dev.utho.com"
        valid={true}
      // className="btn btn-primary"
      ></GitHubLogin>
      {/* )} */}
    </>
    // </div>
    // </div>
  )
}
