import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import ReactPaginate from "react-paginate";
import TableLoader from "../../Loaders/TableLoader";
import UseApi from "../../../../CustomHooks/useApi";
import AccessDenied from "../../AccessDenied";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import ProductsUrl from "../../Docs/Docsdata";
import CustomDoc from "../../../../CustomHooks/CustomDoc";
export default function ResourceList(props) {
  const [dataFetched, setDataFetched] = useState(false);
  const [perPage, setPerPage] = useState(1000);
  const [access, setAccess] = useState(true);
  const [resourceData, setResourceData] = useState(null);
  const [dummyResourceData, setDummyResourceData] = useState(null);
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [activePage, setActivePage] = useState(0);
  const dataFetchedRef = useRef(false);
  const navigate = useNavigate();

  const loaderFunc = () => {
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if (loader && no_record) {
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");

      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
  };
  const handleSendToken = async (id) => {
    // const data = await UseApi('get', 'transfer?type='+props.type+'&id='+id, "");
    var submitBtn = document.querySelector("#transfer-btn-" + id);
    if (submitBtn) {
      setLoaderStatus(true);
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML =
        '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      const dt = toast.loading("Token generating...");
      const data = await UseApi('get', 'transfer/' + props.type + '/' + id, "");
      if (data) {
        setLoaderStatus(false);
        if (data.status == "error") {
          toast.update(dt, {
            render: data.message,
            type: "warning",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.update(dt, {
            render: data.message,
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
      }

    }

  }
  const getCloud = async () => {
    setResourceData(null);
    setDummyResourceData(null)
    const dt = toast;
    const data = await UseApi("get", 'cloud?perpage=1000&page=1', "");
    if (data) {
      setDataFetched(true);
    }
    if ("status" in data) {
      if (data.status == "error") {
        toast.update(dt, {
          render: data.message,
          type: "warning",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setDataFetched(true)
        if (data.message == "Permission denied.") {
          setAccess(false);
        } else {
          setAccess(true)
        }
        setResourceData([]);
        setDummyResourceData([])
        // loaderFunc();
        return;
      }
    }
    if (Object.keys(data).length === 0) {
      setDataFetched(true)
      setResourceData([]);
      setDummyResourceData([])
      toast.update(dt, {
        render: "no record found",
        type: "warning",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // loaderFunc();
    } else {
      setDataFetched(true)
      var cloudArr = [];
      data.cloud.map((value, index) => {
        cloudArr.push(
          {
            "id": value.cloudid,
            "name": value.hostname
          }
        )
      })
      setResourceData(cloudArr);
      setDummyResourceData(cloudArr)
      setPageCount(Math.ceil(cloudArr.length / perPage));
      toast.dismiss();
      //    toast.update(dt,{render: "Your Snapshots request has been successful", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      //  });
    }
  };

  const getIso = async () => {
    setResourceData(null);
    setDummyResourceData(null)
    const dt = toast
    const data = await UseApi('get', 'iso', '');
    if (data) {
      setDataFetched(true);
    }
    if ('status' in data) {
      if (data.status == "error") {
        toast.update(dt, {
          render: data.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        if (data.message == "Permission denied.") {
          setAccess(false);
        } else {
          setAccess(true)
        }
        setResourceData([]);
        setDummyResourceData([])
        // loaderFunc();
        return;
      }
    }
    if (Object.keys(data).length === 0) {

      setResourceData([]);
      setDummyResourceData([])
      toast.update(dt, {
        render: "no record found", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      // loaderFunc();
    } else {
      var isoArr = [];
      data.isos.map((value, index) => {
        isoArr.push(
          {
            "id": index + 1,
            "name": value.name
          }
        )
      })
      setResourceData(isoArr);
      setDummyResourceData(isoArr)
      setPageCount(Math.ceil(isoArr.length / perPage));
      toast.dismiss();
      //    toast.update(dt,{render: "Your ISO request has been successful", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      //  });  

    }
  }

  const getDns = async () => {
    setResourceData(null);
    setDummyResourceData(null)
    const dt = toast
    const data = await UseApi('get', 'dns', '');
    if (data) {
      setDataFetched(true);
    }
    if ('status' in data) {
      if (data.status == "error" && data.message == "Permission denied.") {
        toast.update(dt, {
          render: data.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        setAccess(false);
        setResourceData([]);
        setDummyResourceData([])
        // loaderFunc();
        return;
      }
    }
    if (Object.keys(data).length === 0) {
      setResourceData([]);
      setDummyResourceData([])
      toast.update(dt, {
        render: "no record found", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      // loaderFunc();
    } else {
      var dnsArr = [];
      data.domains.map((value, index) => {
        dnsArr.push(
          {
            "id": index + 1,
            "name": value.domain
          }
        )
      })
      setResourceData(dnsArr);
      setDummyResourceData(dnsArr)
      setPageCount(Math.ceil(dnsArr.length / perPage));
      toast.dismiss();
      //   toast.update(dt,{render: "Your DNS request has been successful", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      // });  

    }
  }

  useEffect(() => {
    toast.dismiss();
    let searchId = document.querySelector("#searchClient")
      if (props && props.type) {
        if (props.type == "cloud") {
          getCloud();
        }
        if (props.type == "dns") {
          getDns();
        }
        if (props.type == "iso") {
          getIso();
        }
        if(searchId){
        searchId.value = ''
        }
    }
  }, [props]);

  function ProductInfo({ productdata }) {
    return (
      <>
        {productdata != null && Object.keys(productdata).length != 0 ? (
          productdata &&
          productdata.map((value, index) => (
            <>
              <tr>
                <td className="text-start ps-9">
                  <div className=" flex-column justify-content-center w-100 me-2">
                    <div className=" flex-stack mb-2">
                      <span className=" me-2 fs-7 fw-bold">{value.id}</span>
                    </div>
                  </div>
                </td>
                <td className="text-center">
                  <div className=" align-items-center ps-10 pe-10">
                    <div className="d-flex justify-content-start flex-column">
                      <a href="/cloud/970497">
                        <div
                          className="text-primary text-hover-primary fs-6 sphide"
                          style={{ cursor: "pointer" }}
                        >
                          {value.name}
                        </div>
                      </a>
                    </div>
                  </div>

                </td>
                <td className="text-end pe-6">
                  <div className="card-toolbar">
                    <a className="btn btn-sm btn-primary btn-active-light-primary me-3 sphide" id={"transfer-btn-" + value.id} onClick={() => { handleSendToken(value.id) }} style={{padding:'0.714rem 1rem'}}>
                      Get Token
                      <span className="svg-icon ms-2 svg-icon-3">
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          {" "}
                          <rect
                            opacity="0.5"
                            x={18}
                            y={13}
                            width={13}
                            height={2}
                            rx={1}
                            transform="rotate(-180 18 13)"
                            fill="black"
                          />{" "}
                          <path
                            d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                            fill="black"
                          />{" "}
                        </svg>{" "}
                      </span>{" "}
                    </a>
                  </div>
                </td>
              </tr>
            </>
          ))
        ) : (
          <>
            <tr>
              {/* <td colspan="12" className="dataTables_empty" id="loader"> */}
              {/* <div className="loading-spinner"></div> */}
              {/* <TableLoader /> */}
              {/* </td> */}
              <td colspan="12" className="" id="no_record" valign="top">
                <div className="d-flex flex-column flex-center">
                  <div className="fs-5 fw-bolder text-dark mb-4">
                    No items found.
                  </div>
                </div>
              </td>
            </tr>
          </>
        )}
      </>
    );
  }

  const handleSearch = (text) => {
    const endOffset = itemOffset + perPage;
    const data = resourceData.filter(row => {
      return Object.values(row).some(value => {
        if (value != null) {
          if (value.toString().toLowerCase().includes(text.toLowerCase())) {
            return value;
          }
        }
      });
    });
    setDummyResourceData(data);;
    setPageCount(Math.ceil(data.length / perPage));
    // }
  }

  function PaginatedItems({ itemsPerPage, ProductData }) {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(ProductData.length / itemsPerPage));
    const handlePageClick = (event) => {
      setActivePage((old) => old = event.selected);
      const newOffset = event.selected * itemsPerPage;
      setItemOffset(newOffset);
    };

    return (
      <>
        <ProductInfo productdata={ProductData.slice(itemOffset, endOffset)} />
        <td colspan="12" className="ps-3">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            initialPage={activePage}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </td>
      </>
    );
  }
  if (dataFetched) {
    if (access) {
      return (<>
        <div className="col-xl-12">
          {/*begin::Tables Widget 9*/}
          <div className="card card-xl-stretch mb-5 mb-xl-12">
            {/*begin::Header*/}
            <div className="card-header pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1">
                  Get token to share your resource
                </span>
              </h3>
              <div className="card-toolbar">
              <div className="position-relative my-1" style={{ marginRight: 20 }}>
                        <CustomDoc url={ProductsUrl[0]?.transfer+"/getting-started/"}/>
              </div>
                <div
                  className="position-relative my-1"
                  style={{ marginRight: 20 }}
                >
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    data-kt-table-widget-4="search"
                    id="searchClient"
                    onChange={(e) => handleSearch(e.target.value)}
                    placeholder="Search"
                  />
                </div>
                <div style={{ marginRight: 20 }}>
                  <div className="dataTables_length">
                    <label className="w-125px">
                      <select
                        onChange={(e) => setPerPage(e.target.value)}
                        //data-control="select2"
                        className="form-select form-select-sm form-select-solid"
                        defaultValue={10}
                        id="perPage"
                      >
                        <option value={10}>10 Records</option>
                        <option value={25}>25 Records</option>
                        <option value={50}>50 Records</option>
                        <option value={100}>100 Records</option>
                      </select>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            {/*begin::Table container*/}
            <div className="table-responsive" style={{ maxHeight: '19rem' }}>
              {/*begin::Table*/}
              <table
                id="resourceTable"
                className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
              >
                {/*begin::Table head*/}
                <thead className="bg-secondary">
                  <tr className="fw-bold">
                    <th className="w-20 text-start ps-9 sphide">Resource ID </th>
                    <th className="w-50 text-center ps-10 sphide"> Resource Name </th>
                    <th className="w-30 text-center"></th>
                    {/* <th className="w-100px text-center d-none">Notes</th> */}
                  </tr>
                </thead>
                {/*end::Table head*/}
                {/*begin::Table body*/}
                <tbody id="resource-list">

                  {resourceData != null ? (
                    <>
                      {access == false ? (
                        <>
                          <tr>
                            <td colspan={12}>
                              <AccessDenied />
                            </td>
                          </tr>
                        </>
                      ) : (
                        <>
                          {Object.keys(resourceData).length != 0 ? (
                            <>
                              <PaginatedItems
                                ProductData={dummyResourceData}
                                itemsPerPage={perPage}
                              />
                            </>
                          ) : (
                            <>
                              <tr>
                                <td colspan="12" id="no_record" valign="top">
                                  <div className="d-flex flex-column flex-center">
                                    <div className="fs-5 fw-bolder text-dark mb-4">
                                      No items found.
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <tr>
                        <td colspan={12}>
                          <TableLoader />
                          <TopBarHeaderLine />
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
                {/*end::Table body*/}
              </table>
              {/*end::Table*/}
            </div>
            {/*end::Table container*/}
            {/*begin::Body*/}
          </div>
          {/*end::Tables Widget 9*/}
        </div>
        {loaderStatus == true ? (<>
          <TopBarHeaderLine />
        </>) : ('')}
      </>)
    } else {
      return (
        <>
          <AccessDenied />
        </>
      )
    }
  } else {
    return (
      <>
        <TableLoader />
      </>
    )
  }
}