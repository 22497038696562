import React, { useEffect, useState } from "react";
import AddingStripeCard from "./AddCards/AddingStripeCard";
import AddingRazorpayCard from "./AddCards/AddingRazorpayCard";
import localStorage from "local-storage";
import AddingUpi from "../AddUpi/AddingUpi";
export default function AddingCard(props) {
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    setUserData(localStorage.get("user_info"));
  }, []);
  return (
    <>
      {/*begin::Tab panel*/}
      <div
        id="kt_billing_creditcard"
        className="tab-pane fade show active"
        role="tabpanel"
      >
        <div className="row gx-9 gy-6">
          {/*begin::Col*/}
          <div className="col-xl-12">
            {/*begin::Notice*/}
            <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed h-lg-100 p-6">
              {/*begin::Wrapper*/}
              <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                {/*begin::Content*/}
                <div className="mb-3 mb-md-0 fw-bold">
                  <h4 className="text-gray-900 fw-bolder">
                    Add New {props.type}
                  </h4>
                  <div className="fs-6 text-gray-700 pe-7">
                    To get most out of the Utho, be sure to
                    add a {props.type} method to your account.
                  </div>
                </div>
                {/*end::Content*/}
                {/*begin::Action*/}
                {props.type == "Upi" ? <AddingUpi /> :
                  <>
                    {userData != null && userData.currency == "INR" ? (
                      <>
                        <AddingRazorpayCard />
                      </>
                    ) : (
                      <>
                        <AddingStripeCard />
                      </>
                    )}
                  </>
                }
                {/*end::Action*/}
              </div>
              {/*end::Wrapper*/}
            </div>
            {/*end::Notice*/}
          </div>
          {/*end::Col*/}
        </div>
      </div>
      {/*end::Tab panel*/}
    </>
  );
}
