import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import AccessDenied from "../../AccessDenied";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../Loaders/TableLoader";

export default function BlockStorage(){
  const [dataFetched, setDataFetched] = useState(true);
  // const [dataFetched, setDataFetched] = useState(false);
  const [access, setAccess]=useState(true);
  const [perPage, setPerPage]=useState(5);
  const [blockData,setBlockData]=useState(null);
  const dataFetchedRef=useRef (false);
  const loaderFunc=()=>{
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if(loader && no_record){
    no_record.classList.add("d-none");
    loader.classList.remove("d-none");

    setTimeout(() => {
      loader.classList.add("d-none");
      no_record.classList.remove("d-none");
    }, 100);
  }
  }
  const getBlockStorage=async()=>{ 
       const data=await UseApi('get','ebs','');
       if(data){
        setDataFetched(true);
       } 
        if(data.status=="error"){ 
          const dt=toast.loading("Block Storage loading..."); 
           toast.update(dt,{render: data.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });  
          setAccess(false);
          setBlockData(null); 
          loaderFunc(); 
          return; 
     }else{
      setBlockData(data.ebs);  
      toast.dismiss();  
     } 
  }

  useEffect(()=>{
    if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
      toast.dismiss();
      toast.dismiss();
      getBlockStorage(); 
  },[]); 
  function ProductInfo({ productdata }) { 
    return(
        <> 
           
        {productdata!=null && Object.keys(productdata).length!=0?(productdata && productdata.map((value, index) => (
         <tr>
          <td className="text-gray-700 fw-bold fs-6 text-center"></td>
         <td className="text-start">
           <div className="align-items-start">
             {" "}
             <div className="d-flex justify-content-center flex-column">
               {" "}
               <a className="text-dark fw-bold text-hover-primary fs-6">
                 {value.name}
               </a>
               {value.location?(<>
               <span className="text-gray-700  fw-bold text-hover-primary fs-7">
                   <img src={"/img/flag_" + value.location.dccc + ".png"} style={{ width: "8%" }} /> {value.location.city}
                 </span>
                </>):(<></>)}
             </div>
           </div>
         </td>
         
         <td className="text-center">
         <div className=" align-items-center">
             {" "}
             <div className=" justify-content-center flex-column">
               {" "}
               <a className="text-gray-700 fw-bold text-hover-primary fs-6">
                 {value.size} GB
               </a> 
             </div>{" "}
           </div> 
         </td> 
         <td className="text-center">
         <div className=" align-items-center"> 
             <div className="justify-content-center flex-column ">
              {value.status=="Active"?(<>
                <span className="fw-bold fs-6 text-gray-700"><span className="status-circle"></span>
               Active
               </span> 
              </>):(<>
                <span className="fw-bold fs-6 text-gray-700"><span className="status-circle-danger"></span>
               Inactive
               </span> 
              </>)}
                  
             </div>{" "}
           </div> 
         </td> 
         <td className="text-center">
         <div className=" align-items-center">
             {" "}
             <div className="d-flex justify-content-center flex-column">
               {" "}
               <a className="text-gray-700 fw-bold text-hover-primary fs-6">
                {value.cloudid != '0'?(<>
               <Link to={"/cloud/"+value.cloudid}><span className="text-gray-600 sphide w-50">Resource-{value.cloudid}&nbsp;</span>
               <span className="svg-icon svg-icon-primary svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      opacity="0.3"
                      d="M4.7 17.3V7.7C4.7 6.59543 5.59543 5.7 6.7 5.7H9.8C10.2694 5.7 10.65 5.31944 10.65 4.85C10.65 4.38056 10.2694 4 9.8 4H5C3.89543 4 3 4.89543 3 6V19C3 20.1046 3.89543 21 5 21H18C19.1046 21 20 20.1046 20 19V14.2C20 13.7306 19.6194 13.35 19.15 13.35C18.6806 13.35 18.3 13.7306 18.3 14.2V17.3C18.3 18.4046 17.4046 19.3 16.3 19.3H6.7C5.59543 19.3 4.7 18.4046 4.7 17.3Z"
                      fill="black"
                    />
                    <rect
                      x="21.9497"
                      y="3.46448"
                      width={13}
                      height={2}
                      rx={1}
                      transform="rotate(135 21.9497 3.46448)"
                      fill="black"
                    />
                    <path
                      d="M19.8284 4.97161L19.8284 9.93937C19.8284 10.5252 20.3033 11 20.8891 11C21.4749 11 21.9497 10.5252 21.9497 9.93937L21.9497 3.05029C21.9497 2.498 21.502 2.05028 20.9497 2.05028L14.0607 2.05027C13.4749 2.05027 13 2.52514 13 3.11094C13 3.69673 13.4749 4.17161 14.0607 4.17161L19.0284 4.17161C19.4702 4.17161 19.8284 4.52978 19.8284 4.97161Z"
                      fill="black"
                    />
                  </svg>
                </span> 
               </Link>
               </>):('-')}
               </a> 
             </div>{" "}
           </div> 
         </td>  
         
         <td className="text-start">
         <div className="d-flex align-items-center">
             {" "}
             <div className="d-flex justify-content-start flex-column">
               {" "}
               <a className="text-gray-700 fw-bold text-hover-primary fs-6">
                 {value.created_at}
               </a> 
             </div>{" "}
           </div> 
         </td> 
         <td className="text-center">
           <div className="d-flex justify-content-center flex-shrink-0">
             {" "}
             {value.status=="Active"?(<>
             <Link to={"/BlockStorage/"+value.did}>
               <a className="btn btn-primary btn-active-light-primary btn-sm me-1 me-5 ">
                 {" "}
                 Manage{" "}
                 <span className="svg-icon ms-2 svg-icon-3">
                   {" "}
                   <svg
                     xmlns="http://www.w3.org/2000/svg"
                     width={24}
                     height={24}
                     viewBox="0 0 24 24"
                     fill="none"
                   >
                     {" "}
                     <rect
                       opacity="0.5"
                       x={18}
                       y={13}
                       width={13}
                       height={2}
                       rx={1}
                       transform="rotate(-180 18 13)"
                       fill="black"
                     />{" "}
                     <path
                       d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                       fill="black"
                     />{" "}
                   </svg>{" "}
                 </span>{" "}
               </a>{" "}
             </Link>
             </>):(<>
              <a className="btn btn-primary btn-active-light-primary btn-sm me-1 me-5 disabled">
                 {" "}
                 Manage{" "}
                 <span className="svg-icon ms-2 svg-icon-3">
                   {" "}
                   <svg
                     xmlns="http://www.w3.org/2000/svg"
                     width={24}
                     height={24}
                     viewBox="0 0 24 24"
                     fill="none"
                   >
                     {" "}
                     <rect
                       opacity="0.5"
                       x={18}
                       y={13}
                       width={13}
                       height={2}
                       rx={1}
                       transform="rotate(-180 18 13)"
                       fill="black"
                     />{" "}
                     <path
                       d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                       fill="black"
                     />{" "}
                   </svg>{" "}
                 </span>{" "}
               </a>{" "}
             </>)}
           </div>{" "}
         </td>
       </tr>  
           ))):( <>
            <tr>
                     <td colspan="12" className="dataTables_empty" id="loader">
                      <div className="loading-spinner"></div>
                    </td>
                    <td colspan="12" className="d-none" id="no_record" valign="top">
                      <div className="d-flex flex-column flex-center"> 
                        <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                      </div>
                    </td>
                    </tr>
            </>)}
            
        </>
    )
}


const [searchTerm,setSearchTerm]=useState('');
function PaginatedItems({ itemsPerPage, ProductData, search }) {
  // We start with an empty list of items. 
  const [pageCount, setPageCount] = useState(0);
  const [vpcData,setVpcData]=useState(null);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    
    const endOffset = itemOffset + itemsPerPage;


  //  ////console.log(`Loading items from ${itemOffset} to ${endOffset}`);
     if(ProductData!=null){
    //   var str="Noida";
   // ////console.log(ProductData);
    const data= ProductData.filter(row => {
     return  Object.values(row).some(value => { 
        if(value!=null){
          if(value.toString().toLowerCase().includes(search.toLowerCase())){
            return value;
          } 
        }
      });
    });
    //////console.log(data);
      // const data=ProductData.filter(item => 
      //        item.hostname.toLowerCase().includes(search.toLowerCase())
      //              ); 

                   if(data.length<=0){
                    loaderFunc();
                   }
    setVpcData(data.slice(itemOffset, endOffset));
    //setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / itemsPerPage));
    // alert(pageCount);
      }
  }, [itemOffset, itemsPerPage]);

  // Invoke when user click to request another page.
  
  const handlePageClick = (event) => { 
    const newOffset = event.selected * itemsPerPage % ProductData.length;
    ////console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setItemOffset(newOffset);
   
  };
  var searchEle=document.querySelector("#searchClient");
  if(searchEle){
    searchEle.focus();
  }
  return (
    <>
      <ProductInfo productdata={vpcData} />
      <td colspan="12">
      <ReactPaginate
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="< previous"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
      </td>
    </>
  );
} 
if(dataFetched){
  if(access==false){
    return (
      <>
       <AccessDenied />
      </>
    );
  }

if(blockData!=null){
 if(Object.keys(blockData).length!=0){
return(
  <> 
  <div className="col-xl-12">
              <div className="card card-xl-stretch mb-5 mb-xl-12">
                <div className="card-header border-0 pt-5">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold fs-3 mb-1">
                      Block Storage
                      <span className="svg-icon svg-icon-2 ms-3">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 84 84"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g
                            id="DBaaS-Icon-1C-BLK"
                            transform="translate(0.189181, -0.000000)"
                            fill="#a1a5b7"
                            fillRule="nonzero"
                          >
                            <path d="m66.7 48.9c-7.7 2.1-17.7 3.3-28.4 3.3s-20.8-1.2-28.4-3.3c-2.2-.6-4.2-1.3-5.9-2v22c0 5.2 15.3 9.4 34.3 9.4s34.3-4.2 34.3-9.4v-22c-1.8.8-3.7 1.4-5.9 2zm-6.3 19.8c-2.6 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6 4.6 2.1 4.6 4.6-2 4.6-4.6 4.6z" />
                            <path d="m38.3 5.1c-19 0-34.3 4.2-34.3 9.4v25.4c3.6 2.4 14.8 6.1 34.3 6.1 19.4 0 30.7-3.7 34.3-6.1v-25.4c-.1-5.2-15.4-9.4-34.3-9.4zm22.1 31.7c-2.6 0-4.6-2.1-4.6-4.6 0-2.6 2.1-4.6 4.6-4.6s4.6 2.1 4.6 4.6c.1 2.5-2 4.6-4.6 4.6zm-22.1-14.3c-20.9 0-32.3-4.9-32.3-7.4s11.4-7.4 32.3-7.4 32.3 4.9 32.3 7.4-11.5 7.4-32.3 7.4z" />
                          </g>
                        </svg>
                      </span>
                    </span>
                    <span className="text-muted mt-1 fw-bold fs-7">
                      Manage your Block storage
                    </span>
                  </h3>
                  <div className="card-toolbar">
                    <div
                      className="position-relative my-1"
                      style={{ marginRight: 20 }}
                    >
                       <input
            type="text"
            className="form-control form-control-sm"
            data-kt-table-widget-4="search"
            id="searchClient"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            placeholder="Search"
          />
        </div>
        <div style={{ marginRight: 20 }}>
  <div className="dataTables_length">
    <label className="w-125px">
      <select  
        onChange={e => setPerPage(e.target.value)}
        //data-control="select2"
        className="form-select form-select-sm form-select-solid"
        defaultValue={5}
        id="perPage"
        
      >
        <option value={5} selected="">
          5 Records
        </option>
        <option value={10}>10 Records</option>
        <option value={25}>25 Records</option>
        <option value={50}>50 Records</option>
        <option value={100}>100 Records</option>
      </select>
    </label>
                      </div>
                    </div>
                    <Link to="/ebs/deploy">
                      <a className="btn btn-sm btn-primary btn-active-light-primary">
                        <span className="svg-icon svg-icon-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              opacity="0.5"
                              x="11.364"
                              y="20.364"
                              width={16}
                              height={2}
                              rx={1}
                              transform="rotate(-90 11.364 20.364)"
                              fill="black"
                            />
                            <rect
                              x="4.36396"
                              y="11.364"
                              width={16}
                              height={2}
                              rx={1}
                              fill="black"
                            />
                          </svg>
                        </span>
                        Create Storage
                      </a>
                    </Link>
                  </div>
                </div>
                <div className="table-responsive">
                  <table
                    id="firewallTable"
                    className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
                  >
                    <thead className="bg-secondary">
                      <tr className="fw-bold ">
                      <th className="w-10px text-center"></th> 
                        <th className="w-200px text-start">Storage</th>    
                        <th className="w-150px text-center">Size</th>
                        <th className="w-150px text-center">Status</th>
                        <th className="w-250px text-center sphide">Attached Resource</th> 
                        <th className="w-150px text-center">Created_at</th>
                        <th className="w-250px text-center"></th>
                      </tr>
                    </thead>
                    <tbody>
                      
  {blockData!=null?(<>
<PaginatedItems itemsPerPage={perPage} ProductData={blockData} search={searchTerm}  />
  </>):(<>
    <tr>
                     <td colspan="12" className="dataTables_empty" id="loader">
                      <div className="loading-spinner"></div>
                    </td>
                    <td colspan="12" className="d-none" id="no_record" valign="top">
                      <div className="d-flex flex-column flex-center"> 
                        <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                      </div>
                    </td>
                    </tr>
  </>)}
  </tbody>
                  </table>
                </div>
              </div>
            </div>
  </>
)
} else{
  return (
    <>
      <div className="col-xl-12">
        <div className="card">
          {/*begin::Card body*/}
          <div className="card-body p-0 mt-10">
            {/*begin::Illustration*/}
            <div className="text-center px-4 ">
              <span className="mw-100 mh-300px">
              <svg
                        fill="#21325B"
                        width="234px"
                        height="234px"
                        viewBox="0 0 34 24"
                        id="icon"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <defs>
                          <style
                            dangerouslySetInnerHTML={{
                              __html:
                                "\n                                                                                    .cls-1 {\n                                                                                        fill: none;\n                                                                                    }\n                                                                                ",
                            }}
                          />
                        </defs>
                        <path
                          d="M28,20H26v2h2v6H4V22H6V20H4a2.0024,2.0024,0,0,0-2,2v6a2.0024,2.0024,0,0,0,2,2H28a2.0024,2.0024,0,0,0,2-2V22A2.0024,2.0024,0,0,0,28,20Z"
                          transform="translate(0 0)"
                        />
                        <circle cx={7} cy={25} r={1} />
                        <path
                          d="M15,20H8V13h7Zm-5-2h3V15H10Z"
                          transform="translate(0 0)"
                        />
                        <path
                          d="M24,20H17V13h7Zm-5-2h3V15H19Z"
                          transform="translate(0 0)"
                        />
                        <path
                          d="M15,11H8V4h7ZM10,9h3V6H10Z"
                          transform="translate(0 0)"
                        />
                        <path
                          d="M24,11H17V4h7ZM19,9h3V6H19Z"
                          transform="translate(0 0)"
                        />
                      </svg>
              </span>
            </div>
            {/*end::Illustration*/}
            {/*begin::Wrapper*/}
            <div className="card-px text-center py-5 mb-20">
              {/*begin::Title*/}
              <h1 className="fs-2x fw-bolder mb-5">Block Storage</h1>
              <h2 className="fs-1x fw-bolder mb-5 text-muted">
              Efficient Data Management Solution
              </h2>
              {/*end::Title*/}
              {/*begin::Description*/}
              <p className="text-gray-400 fs-4 fw-bold mb-10">
              Block storage provides a scalable and reliable data management solution. With its ability to allocate storage in fixed-sized blocks, it offers optimal flexibility and performance for storing and retrieving data. Harness the power of block storage to enhance your data infrastructure and streamline your operations.
              </p>
              {/*end::Description*/}
              {/*begin::Action*/}
              <Link to="/ebs/deploy">
              <a
                href="#"
                className="btn btn-primary mb-10"
              >
                Create Block Storage
              </a>
              </Link>
              {/*end::Action*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Card body*/}
        </div>
      </div>
    </>
  );
}
}else{
  return(
    <>
    <TopBarHeaderLine/>
    <TableLoader />
    </>
  )
}
}else{
return (
<>
<TopBarHeaderLine/>
<TableLoader/>
</>
);
}
}