import React, { useEffect, useRef, useState } from "react";
import { Link ,useNavigate } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UsePost from "../../../../CustomHooks/usePost";
import swal from 'sweetalert';
import validator from 'validator';
import { stateObject } from "./state";
import { countryObject } from "./country"; 
import localStorage from "local-storage"; 
import Cookies from "js-cookie";
import ApplyCoupon from "../../../../Auth/ApplyCoupon";
import TableLoader from "../../Loaders/TableLoader";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";

export default function CustomerComparison(props){ 
  const navigate=useNavigate();
  const [userData,setUserData]=useState(null);
  const dataFetchedRef=useRef (false); 
  const [stateData,setStateData]=useState('');

  function updateStates(countryValue){ 
    const newStateData = stateObject
      .filter(state => state.country_code === countryValue)
      .map(state => state.name);
    // console.log(newStateData);
    setStateData(newStateData);
  } 
  const getUser=async()=>{ 
    if(props && props.userData){
      const data=props.userData;

        if(data && data.country){
          updateStates(data.country);
            }
        setUserData(data);   
      }
  }
  useEffect(()=>{
    if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
      toast.dismiss();
      getUser(); 
  },[]);   
  
  const filledValue=()=>{
    var formf_supportneed_title=document.querySelector("#formf_supportneed_title");
    var formf_supportneed_usecase=document.querySelector("#formf_supportneed_usecase");
    var formf_supportneed_businesstype=document.querySelector("#formf_supportneed_businesstype");
    var formf_supportneed_monthlyspend=document.querySelector("#formf_supportneed_monthlyspend");
    var formf_supportneed_employeesize=document.querySelector("#formf_supportneed_employeesize"); 
    if(formf_supportneed_title && formf_supportneed_usecase && formf_supportneed_businesstype && formf_supportneed_monthlyspend && formf_supportneed_employeesize){
      // console.log("data coimer");
      if(userData!=null){
      formf_supportneed_title.value=userData.supportneed_title;
      formf_supportneed_usecase.value= userData.supportneed_usecase;
      formf_supportneed_businesstype.value=userData.supportneed_businesstype;
      formf_supportneed_monthlyspend.value=userData.supportneed_monthlyspend;
      formf_supportneed_employeesize.value=userData.supportneed_employeesize;
    } 
  }
  }

  useEffect(()=>{
    filledValue();
  },[userData])
  const updateAccount=async ()=>{ 
    const dt=toast.loading("Please wait..."); 
    var isFields=checkAllField();
    if(!isFields){
      toast.update(dt,{render: "All fields are required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      }); 
      return;
    }
    toast.dismiss();
   
   const submitBtn=document.querySelector("#updateAccountBtn");
   if(submitBtn){
     var preContent=submitBtn.innerHTML; 
     submitBtn.classList.add("disabled");
     submitBtn.innerHTML='Please wait... <span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';

    const responseData=await UsePost('post','account/update','updateprofile');
    if(responseData){
      //console.log(responseData);
      if(responseData.status==='error'){
        toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      }); 
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML=preContent;
      }else{
        window.afterAccountTypeSelection();
        toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });  
        const userInfo=await UseApi('get','account/info','');  
        if(userInfo){
        localStorage.remove("user_info");
        localStorage.set("user_info",userInfo.user);  
        window.location.reload();
        submitBtn.classList.remove("disabled");
      submitBtn.innerHTML=preContent; 
      // navigate("/Welcome");
      // const location_url = window.location.origin;
      //   window.location.href = location_url;
        }
      }
    }
    }
  }
 
 
 const checkAllField=()=>{
      var formf_supportneed_title=document.querySelector("#formf_supportneed_title");
      var formf_supportneed_usecase=document.querySelector("#formf_supportneed_usecase");
      var formf_supportneed_businesstype=document.querySelector("#formf_supportneed_businesstype");
      var formf_supportneed_monthlyspend=document.querySelector("#formf_supportneed_monthlyspend");
      var formf_supportneed_employeesize=document.querySelector("#formf_supportneed_employeesize"); 
      if(formf_supportneed_title && formf_supportneed_usecase && formf_supportneed_businesstype && formf_supportneed_monthlyspend && formf_supportneed_employeesize){
        if(formf_supportneed_title.value!="" && formf_supportneed_usecase.value!="" && formf_supportneed_businesstype.value!="" && formf_supportneed_monthlyspend.value!="" && formf_supportneed_employeesize.value!=""){
         return true;
        }else{
          return false;
        }    
      } 
 }
    return(
        <> 
          {userData!=null?(<> 
          <form id="updateprofile" name="updateprofile" className="form">
       {/*begin::Input group*/}
       <div className="row fv-row mb-7 fv-plugins-icon-container">
             {/*begin::Col*/}
           <div className="col-xl-12">
            <label className="form-label fw-bolder text-gray-400 fs-6 required">
              What is your current designation in your company?
            </label>
            <select
              id="formf_supportneed_title"
              name="supportneed_title" 
              className="form-select form-select-lg form-select-solid fw-bold"  
               >
             <option value="">Select</option>
              <option value="CEO">CEO</option>
<option value="CTO">CTO</option>
<option value="CIO">CIO</option>
<option value="IT Manager / Head">IT Manager / Head</option>
<option value="Cloud Engineer / Manager / Architect">Cloud Professional</option>
<option value="System Administrator">System Administrator</option>
<option value="Network Administrator">Network Administrator</option>
<option value="Software Engineer / Developer">Software Developer</option>
<option value="Solutions Architect">Solutions Architect</option>
<option value="DevOps Engineer">DevOps Engineer</option>
<option value="Database Administrator">Database Administrator</option>
<option value="Security Analyst">Security Analyst</option>
<option value="Infrastructure Engineer">Infrastructure Engineer</option>
<option value="Technical Support Specialist">Technical Support Specialist</option>
<option value="Front-end Developer">Front-end Developer</option>
<option value="Back-end Developer">Back-end Developer</option>
<option value="Full Stack Developer">Full Stack Developer</option>
<option value="Data Scientist / Analyst">Data Scientist / Analyst</option>
<option value="Quality Assurance Engineer">Quality Assurance Engineer</option>
<option value="UI UX Designer">UI UX Designer</option>
<option value="Technical Account Manager">Technical Account Manager</option>
<option value="Head of Department">Head of Department</option>
<option value="VP of Department">VP of Department</option>
            </select>   
          </div>
          {/*end::Col*/}  
        </div>
        {/*end::Input group*/}
       {/*begin::Input group*/}
         <div className="row fv-row mb-7 fv-plugins-icon-container">
             {/*begin::Col*/}
           <div className="col-xl-12">
            <label className="form-label fw-bolder text-gray-400 fs-6 required">
              What do you plan to build on Utho Cloud?
            </label>
            <select
              id="formf_supportneed_usecase"
              name="supportneed_usecase" 
              className="form-select form-select-lg form-select-solid fw-bold"  
               >
              <option value="">Select</option>
              <option value="AI and machine learning">AI and machine learning</option>  
              <option value="A web or mobile application">A web or mobile application</option>
              <option value="Web3 or decentralized application">Web3 or decentralized application</option>
              <option value="Video or livestreaming platform">Video or livestreaming platform</option>
              <option value="An API">An API</option>
              <option value="A website or content site">A website or content site</option>
            </select>   
          </div>
          {/*end::Col*/}  
        </div>
        {/*end::Input group*/}
        {/*begin::Input group*/}
        <div className="row fv-row mb-7 fv-plugins-icon-container">
            {/*begin::Col*/}
          <div className="col-xl-12">
          <label className="form-label fw-bolder text-gray-400 fs-6 required">
            What is your role or business type??
          </label>
          <select
            id="formf_supportneed_businesstype"
            name="supportneed_businesstype" 
            className="form-select form-select-lg form-select-solid fw-bold"  
              >
            <option value="">Select</option>    
            <option value="E-commerce company">E-commerce company</option> 
            <option value="Freelancer or Consultant">Freelancer or Consultant</option>
            <option value="Hobbyist or Student">Hobbyist or Student</option>
            <option value="Software company">Software company</option>
            <option value="Digital agency / MSP">Digital agency / MSP</option>
            <option value="Other">Other</option> 
          </select>   
        </div>
        {/*end::Col*/}  
      </div>
      {/*end::Input group*/}
      {/*begin::Input group*/}
      <div className="row fv-row mb-7 fv-plugins-icon-container">
             {/*begin::Col*/}
           <div className="col-xl-12">
            <label className="form-label fw-bolder text-gray-400 fs-6 required">
              What is your monthly spend on cloud infrastructure across cloud platforms?
            </label>
            <select
              id="formf_supportneed_monthlyspend"
              name="supportneed_monthlyspend" 
              className="form-select form-select-lg form-select-solid fw-bold"  
               >
                {userData!=null?(<>
                {userData.country=="IN"?(<>
                  <option value="">Select</option>
                  <option value="Rs.0 - Rs.3,650">Rs.0 - Rs.3,650</option>
                  <option value="Rs.3,650 - Rs.18,250">Rs.3,650 - Rs.18,250</option>
                  <option value="Rs.18,250 - Rs.36,500">Rs.18,250 - Rs.36,500</option>
                  <option value="Rs.36,500 - Rs.73,000">Rs.36,500 - Rs.73,000</option>
                  <option value="Rs.73,000 - Rs.365,000">Rs.73,000 - Rs.365,000</option>
                  <option value="Rs.365,000 - Rs.730,000+">Rs.365,000 - Rs.730,000</option>
                  <option value="Rs.730,000 - Rs.7,300,000+">Rs.730,000 - Rs.7,300,000</option>
                  <option value="More than Rs.7,300,000+">More than Rs.7,300,000</option>
                </>):(<> 
                  <option value="">Select</option>
                  <option value="$0 - $50">$0 - $50</option> 
                  <option value="$50 - $250">$50 - $250</option>
                  <option value="$250 - $500">$250 - $500</option>
                  <option value="$500 - $1000">$500 - $1000</option>
                  <option value="$1000 - $5000">$1000 - $5000</option>
                  <option value="$5000 - $10,000+">$5000 - $10,000</option> 
                  <option value="$10,000 - $100,000+">$10,000 - $100,000</option> 
                  <option value="More than $100,000+">More than $100,000</option> 
              </>)}
                </>):('')}
            </select>   
          </div>
          {/*end::Col*/}  
        </div>
        {/*end::Input group*/}

       {/*begin::Input group*/}
       <div className="row fv-row mb-7 fv-plugins-icon-container">
             {/*begin::Col*/}
           <div className="col-xl-12">
            <label className="form-label fw-bolder text-gray-400 fs-6 required">
              How many employees work at your company?
            </label>
            <select
              id="formf_supportneed_employeesize"
              name="supportneed_employeesize" 
              className="form-select form-select-lg form-select-solid fw-bold"  
               >
              <option value="">Select</option>
              <option value="1">I work alone</option> 
              <option value="2-9">2-9</option>
              <option value="10-99">10-99</option>
              <option value="100-499">100-499</option>
              <option value="500-999">500-999</option>
              <option value="1000+">1000+</option> 
            </select>   
          </div>
          {/*end::Col*/}  
        </div>
        {/*end::Input group*/}
    </form> 
       {/*begin::Actions*/}
       <div className="text-center">
          <a
            style={{cursor:"pointer"}}
            className="btn btn-lg btn-primary w-100"
            onClick={updateAccount}
            id="updateAccountBtn"
          >
            <span className="indicator-label" >Save Changes</span>
            </a>
        </div>
        {/*end::Actions*/}
</>):(<><TableLoader/>
<TopBarHeaderLine/>
</>)}
        </>
    )
}