import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UsePost from "../../../../CustomHooks/usePost";
import swal from "sweetalert";
import ReactPaginate from 'react-paginate';
import AccessDenied from "../../AccessDenied";
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../Loaders/TableLoader";
import CustomDoc from "../../../../CustomHooks/CustomDoc";
import ProductsUrl from "../../Docs/Docsdata";
export default function AccountManage() {
  const [dataFetched, setDataFetched] = useState(false);
  const [pageStatus, setPageStatus] = useState(false)
  const [marginBottom, setMarginBottom] = useState(window.innerWidth >= 768 ? '' : '5.5rem');
  const [marginRight, setMarginRight] = useState(window.innerWidth >= 768 ? '22px' : '6px');
  const [isMobile, setIsMobile] = useState(window.innerWidth >= 768 ? 'col-1' : '')
  const [access, setAccess] = useState(true);
  const [perPage, setPerPage] = useState(5);
  const [subData, setSubData] = useState(null);
  const [dummySubData, setDummySubData] = useState(null);
  const [showNameWarning, setShowNameWarning] = useState('')
  const [selectAll, setSelectAll] = useState(false);
  const [activePage, setActivePage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const dataFetchedRef = useRef(false);
  const loaderFunc = () => {
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if (loader && no_record) {
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");

      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
  }
  const getSubAccount = async () => {
    const dt = toast
    const datas = await UseApi('get', 'user', '');
    if (datas) {
      setDataFetched(true);
    }
    if (datas.status == 'error' && datas.message != 'No Records Found.') {
      toast.update(dt, {
        render: datas.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      setAccess(false);
      setSubData([]);
      setDummySubData([])
      // loaderFunc();
    } else {
      delete (datas.html);
      const data = Object.values(datas);
      if (datas.status == "error" && datas.message == "No Records Found.") {
        toast.update(dt, {
          render: "no record found", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        setSubData([]);
        setDummySubData([])
      } else {
        setSubData(data[1]);
        setDummySubData(data[1])
        toast.dismiss();
      }
    }
  }
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getSubAccount();
  }, []);

  const openDrawer = () => {

    var drawerElement = document.querySelector("#add_subuser-modal");
    var drawer_overlay = document.querySelector("#drawer_overlay");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
        drawerElement.style.width = "100%";
      } else {
        drawerElement.style.width = "50%%";
      }
      drawer_overlay.classList.toggle("d-none");
    }
    const subForm = document.querySelector('#subuser-add');
    if (subForm) {
      subForm.reset();
    }
    setSelectAll(false)
  };
  var permission_arr = {
    'compute': 'Cloud Instances',
    'kubernetes': 'Kubernetes',
    'autoscaling': 'Auto Scaling',
    'stacks': 'Stacks',
    'ssl': 'SSL',
    'database': 'Database',
    'objectstorage': 'Object Storage',
    'monitoring': 'Monitoring',
    'loadbalancer': 'Load balancer',
    'vpn': 'VPN',
    'vpc': 'VPC',
    'firewall': 'Firewall',
    'dns': 'DNS',
    'snapshot': 'Snapshot',
    'backup': 'Backup',
    'iso': 'ISO',
    'billing': 'Billing',
    'sshkey': 'SSH Key',
    'api': 'API',
    'activity': 'Activity',
    'ticket': 'Ticket',
    'billingaddfund': 'Billing Add Fund',
    'user': 'User',
    'image': 'Image',
    'kyc': 'KYC',
    "sqs": 'SQS',
    'container_registry': 'Container Registry',
    'target_group': 'Target Group',
    'account': "Account",
    'transfer': 'Resource Transfer',
    'active_session': 'Active Session'
  }

  permission_arr = Object.entries(permission_arr);

  // const permissionInput = () => {
  //   permission_arr.map((value, index) => {
  //     const readCheck = document.querySelector('#' + value[0] + '_read');
  //     const writeCheck = document.querySelector('#' + value[0] + '_write');
  //     const deleteCheck = document.querySelector('#' + value[0] + '_delete');
  //     const eachPermission = document.querySelector('#' + value[0] + '_set');
  //     if (deleteCheck.checked == true) {
  //       eachPermission.value = '' + value[0] + '_delete' + ',' + value[0] + '_read' + ',' + value[0] + '_write';
  //       readCheck.checked = true;
  //       writeCheck.checked = true;
  //     } else if (readCheck.checked === true && writeCheck.checked === true) {
  //       eachPermission.value = '' + value[0] + '_read' + ',' + value[0] + '_write';
  //       deleteCheck.checked = false;
  //     }
  //     else if (readCheck.checked === false && writeCheck.checked === true) {
  //       eachPermission.value = '' + value[0] + '_read' + ',' + value[0] + '_write';
  //       deleteCheck.checked = false;
  //       readCheck.checked = true;
  //     } else if (readCheck.checked === true && writeCheck.checked === true) {
  //       eachPermission.value = '' + value[0] + '_read' + ',' + value[0] + '_write';
  //       deleteCheck.checked = false;
  //     } else if (readCheck.checked === true && writeCheck.checked === false && deleteCheck.checked === false) {
  //       eachPermission.value = '' + value[0] + '_read';
  //     } else if (readCheck.checked === false && writeCheck.checked === false && deleteCheck.checked === false) {
  //       eachPermission.value = "blank";
  //     }
  //   })
  // }

  const permissionInput = () => {
    if (permission_arr) {
      permission_arr.map((value, index) => {
        const readCheck = document.querySelector('#' + value[0] + '_read');
        const writeCheck = document.querySelector('#' + value[0] + '_write');
        const deleteCheck = document.querySelector('#' + value[0] + '_delete');
        const eachPermission = document.querySelector('#' + value[0] + '_set');
        if (readCheck && writeCheck && deleteCheck && eachPermission) {
          if (deleteCheck.checked == true) {
            eachPermission.value = '' + value[0] + '_delete' + ',' + value[0] + '_read' + ',' + value[0] + '_write';
            readCheck.checked = true;
            writeCheck.checked = true;
          } else if (readCheck.checked === true && writeCheck.checked === true) {
            eachPermission.value = '' + value[0] + '_read' + ',' + value[0] + '_write';
            deleteCheck.checked = false;
          }
          else if (readCheck.checked === false && writeCheck.checked === true) {
            eachPermission.value = '' + value[0] + '_read' + ',' + value[0] + '_write';
            deleteCheck.checked = false;
            readCheck.checked = true;
          } else if (readCheck.checked === true && writeCheck.checked === true) {
            eachPermission.value = '' + value[0] + '_read' + ',' + value[0] + '_write';
            deleteCheck.checked = false;
          } else if (readCheck.checked === true && writeCheck.checked === false && deleteCheck.checked === false) {
            eachPermission.value = '' + value[0] + '_read';
          } else if (readCheck.checked === false && writeCheck.checked === false && deleteCheck.checked === false) {
            eachPermission.value = "blank";
          }

        }
      })
    }
    // Update "Select All" checkbox state based on individual checkboxes
    const checkboxes = document.querySelectorAll('#permission-list input[type="checkbox"]');
    let isAllChecked = true;

    checkboxes.forEach((checkbox) => {
      if (!checkbox.checked) {
        isAllChecked = false;
      }
    });

    const selectAllCheckbox = document.querySelector('.form-check-input[type="checkbox"]');
    if (selectAllCheckbox) {
      selectAllCheckbox.checked = isAllChecked;
    }
  };


  const addUser = () => {
    toast.dismiss();
    var permissions = "";
    permission_arr.map((value, index) => {
      const setPerms = document.querySelector('#' + value[0] + '_set');
      var permission_value = setPerms.value;
      if (permission_value != "blank") {
        permissions += permission_value + ",";
      }
    });
    const permField = document.querySelector('#p_info');
    permField.value = permissions;
    swal({
      title: "Are you sure?",
      text: "Once subuser created, your subuser can access your account with the given permissions!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          setPageStatus(true)
          const dt = toast.loading("Subuser creating...");
          const dnsp = await UsePost('POST', 'user', 'subuser-add');
          if (dnsp.status == 'error') {
            setPageStatus(false)
            toast.update(dt, {
              render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
          } else {
            setPageStatus(false)
            toast.update(dt, {
              render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
            openDrawer();
            const subForm = document.querySelector('#subuser-add');
            subForm.reset();
            getSubAccount();
          }
        } else {
          setPageStatus(false)
          openDrawer();
          const subForm = document.querySelector('#subuser-add');
          subForm.reset();
        }
      });
  }

  useEffect(() => {
    const handleResize = () => {
      setMarginBottom(window.innerWidth >= 768 ? '' : '5.5rem');
      setMarginRight(window.innerWidth >= 768 ? '22px' : '6px')
      setIsMobile(window.innerWidth >= 768 ? 'col-1' : '')
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function convertTimestamp(timestamp) {
    if (timestamp) {
      const date = new Date(timestamp);
      // Format the date
      const formattedDate = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short',
        timeZone: 'UTC'
      }).format(date);

      return formattedDate.replace('GMT', '(UTC)');
    }
  }

  ////console.log(subData);
  function ProductInfo({ productdata }) {
    return (
      <>
        {productdata != null && Object.keys(productdata).length != 0 ? (productdata && productdata.map((value, index) => (
          <tr>
            {/*begin::ID*/}
            <td className="text-start ps-10">
              <span className="fw-bold text-center">{++index}</span>
            </td>
            {/*end::ID*/}
            {/*begin::User=*/}
            <td className="text-start">
              {/*begin::User details*/}
              <div className="d-flex flex-column">

                <Link to={"/accountManagement/2323"}>
                  <a

                    className="text-gray-800 fw-bolder text-hover-primary mb-1"
                  >
                    {value?.fullname}
                  </a>
                </Link>
              </div>
              {/*begin::User details*/}
            </td>
            {/*end::User=*/}
            <td className="text-center">
              {/*begin::User details*/}
              <div className="d-flex flex-column">
                <span className="text-muted fw-bold">{value.email}</span>
                <span className="text-muted fw-bold" />
              </div>
              {/*begin::User details*/}
            </td>
            {/*begin::Role=*/}
            <td className="text-center">
              {value.status == 'Pending' ? (<div className="badge badge-warning">Pending</div>) : (<div className="badge badge-success">Active</div>)}
            </td>
            <td className="text-center">
              <div className="d-flex justify-content-start flex-column">
                <span className="me-2 fs-7 fw-bold">{(value.dateadded)}</span>
              </div>
            </td>
            {/*end::Role=*/}
            {/*begin::Last login=*/}
            {/* <td className="text-center">
              <div className="badge badge-light fw-bolder">{value.last_login_time}</div>
            </td> */}
            {/*end::Last login=*/}
            {/*begin::Joined*/}
            {/* <td className="text-center">
              <div className="badge badge-light fw-bolder">{value.created_at}</div>
            </td> */}
            {/*begin::Joined*/}
            {/*begin::Action=*/}
            <td className="text-end pe-3 sphide">
              <div className="d-flex justify-content-end flex-shrink-0">
                <Link to={"/accountManagement/" + value.id + ""}>
                  <a
                    className="btn btn-primary btn-active-light-primary btn-sm me-1 me-11 "
                  >
                    Manage
                    <span className="svg-icon ms-2 svg-icon-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >

                        <rect
                          opacity="0.5"
                          x={18}
                          y={13}
                          width={13}
                          height={2}
                          rx={1}
                          transform="rotate(-180 18 13)"
                          fill="black"
                        />
                        <path
                          d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                  </a>
                </Link>
              </div>
            </td>
            {/*end::Action=*/}
          </tr>
        ))) : (<>
          <tr>
            {/* <td colspan="12" className="dataTables_empty" id="loader"> */}
            {/* <div className="loading-spinner"></div> */}
            {/* <TableLoader /> */}
            {/* </td> */}
            <td colspan="12" className="" id="no_record" valign="top">
              <div className="d-flex flex-column flex-center">
                <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
              </div>
            </td>
          </tr>
        </>)}

      </>
    )

  }

  function PaginatedItems({ itemsPerPage, ProductData }) {
    var endOffset = endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(ProductData.length / itemsPerPage));

    const handlePageClick = (event) => {
      setActivePage((old) => old = event.selected);
      const newOffset = event.selected * itemsPerPage;
      setItemOffset(newOffset);
    };
    return (
      <>
        <ProductInfo productdata={ProductData.slice(itemOffset, endOffset)} />
        <td colspan="12" className="ps-3">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            initialPage={activePage}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </td>
      </>
    );
  }

  const handleSearch = (text) => {
    const endOffset = itemOffset + perPage;
    const data = subData.filter(row => {
      return Object.values(row).some(value => {
        if (value != null) {
          if (value.toString().toLowerCase().includes(text.toLowerCase())) {
            return value;
          }
        }
      });
    });
    setDummySubData(data);;
    setPageCount(Math.ceil(data.length / perPage));
  }

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);

    // Select or unselect all checkboxes
    const checkboxes = document.querySelectorAll('#permission-list input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      checkbox.checked = isChecked;
    });
  };

  const handleInputChange = (e, regex, inputName) => {
    const inputValue = e.target.value;
    // Regex pattern to allow only numeric characters
    const filteredValue = inputValue.replace(regex, ''); // \D matches any non-digit character
    // Update the input value with only the allowed characters
    e.target.value = filteredValue;
    if (inputName == "name") {
      setShowNameWarning(filteredValue)
    }
  };

  const renderWarningMessage = () => {
    if (showNameWarning.length === 0) {
      return null; // Don't show any warning if showNameWarning is 0
    } else if (showNameWarning.length >= 1 && showNameWarning.length <= 3) {
      return (
        <span className="text-danger fs-7" role="alert">
          Warning: Please enter at least 3 word name.
        </span>
      );
    }
    // Add additional conditions if needed
  };

  console.log(marginBottom);
  return (
    <>
      {subData != null ?
        <div className="col-xl-12">
          {/*begin::Tables Widget 9*/}
          <div className="card card-xl-stretch mb-5 mb-xl-12">
            {/*begin::Header*/}
            <div className="card-header border-0 pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1">
                  Subusers management
                </span>
              </h3>
              <div
                className="card-toolbar me-5"
              >
                <div className="position-relative my-1" style={{ marginRight: 20 }}>
                  <CustomDoc url={ProductsUrl[0]?.subuser + "/getting-started/"} />
                </div>
                <div className="position-relative my-1" style={{ marginRight: 22 }}>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    data-kt-table-widget-4="search"
                    id="searchClient"
                    onChange={e => handleSearch(e.target.value)}
                    placeholder="Search"
                  />
                </div>
                <div style={{ marginRight: marginRight }}>
                  <div className="dataTables_length">
                    <label className="w-125px">
                      <select
                        onChange={e => setPerPage(e.target.value)}
                        //data-control="select2"
                        className="form-select form-select-sm form-select-solid"
                        defaultValue={5}
                        id="perPage"

                      >
                        <option value={5} selected="">
                          5 Records
                        </option>
                        <option value={10}>10 Records</option>
                        <option value={25}>25 Records</option>
                        <option value={50}>50 Records</option>
                        <option value={100}>100 Records</option>
                      </select>
                    </label>
                  </div>
                </div>
                <a
                  onClick={openDrawer}
                  className="btn btn-sm btn-primary btn-active-light-primary"
                >
                  {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
                  <span className="svg-icon svg-icon-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.5"
                        x="11.364"
                        y="20.364"
                        width={16}
                        height={2}
                        rx={1}
                        transform="rotate(-90 11.364 20.364)"
                        fill="black"
                      />
                      <rect
                        x="4.36396"
                        y="11.364"
                        width={16}
                        height={2}
                        rx={1}
                        fill="black"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}Add Subuser
                </a>
              </div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            {/*begin::Table container*/}
            <div className="table-responsive">
              {/*begin::Table*/}
              <table
                id="subuser_data"
                className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
              >
                {/*begin::Table head*/}
                <thead className="bg-secondary">
                  <tr className="fw-bold ">
                    <th className="w-125px text-start ps-9">S.No</th>
                    <th className="w-150px text-start">User</th>
                    <th className="w-200px text-center">Email</th>
                    <th className="w-150px text-center">Status</th>
                    <th className="w-150px text-center">Created_at</th>
                    {/* <th className="w-150px text-center">Last login</th>
            <th className="w-150px text-center">Created at</th> */}
                    <th className="w-150px text-center" />
                  </tr>
                </thead>
                {/*end::Table head*/}
                {/*begin::Table body*/}
                <tbody id="list-subuser">
                  {subData != null ? (<>
                    {access == false ? (<>
                      <tr>
                        <td colspan={12}>
                          <AccessDenied />
                        </td>
                      </tr>
                    </>) : (<>
                      {Object.keys(subData).length != 0 ? (<>
                        <PaginatedItems ProductData={dummySubData} itemsPerPage={perPage} />
                      </>) : (<>
                        <tr>
                          <td colspan="12" valign="top">
                            <div className="card-body p-0 mt-10">
                              {/*begin::Illustration*/}
                              <div className="text-center px-4 mt-10">
                                <span className="mw-100 mh-300px">
                                  <svg
                                    // viewBox="0 0 64 64"  
                                    width="234px"
                                    height="234px"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M16.0173 9H15.3945C14.2833 9 13.263 9.61425 12.7431 10.5963L12.154 11.7091C12.0645 11.8781 12.1072 12.0868 12.2559 12.2071L12.6402 12.5183C13.2631 13.0225 13.7556 13.6691 14.0764 14.4035L14.2321 14.7601C14.2957 14.9058 14.4396 15 14.5987 15H18.6747C19.7297 15 20.4057 13.8774 19.912 12.945L18.6686 10.5963C18.1487 9.61425 17.1285 9 16.0173 9Z"
                                      fill="#21325B"
                                    />
                                    <rect opacity="0.3" x={14} y={4} width={4} height={4} rx={2} fill="black" />
                                    <path
                                      d="M4.65486 14.8559C5.40389 13.1224 7.11161 12 9 12C10.8884 12 12.5961 13.1224 13.3451 14.8559L14.793 18.2067C15.3636 19.5271 14.3955 21 12.9571 21H5.04292C3.60453 21 2.63644 19.5271 3.20698 18.2067L4.65486 14.8559Z"
                                      fill="#21325B"
                                    />
                                    <rect opacity="0.3" x={6} y={5} width={6} height={6} rx={3} fill="black" />
                                  </svg>

                                </span>
                              </div>
                              {/*end::Illustration*/}
                              {/*begin::Wrapper*/}
                              <div className="card-px text-center py-5 mb-20">
                                {/*begin::Title*/}
                                <h1 className="fs-2x fw-bolder mb-5">Subuser</h1>
                                <h2 className="fs-1x fw-bolder mb-5 text-muted">
                                  Unlock the Power of Cloud
                                </h2>
                                {/*end::Title*/}
                                {/*begin::Description*/}
                                <p className="text-gray-400 fs-4 fw-bold mb-10">
                                  Streamline your deployment process by harnessing the power of the cloud.<br /> Seamlessly migrate your systems and explore a world of endless possibilities for your organization.
                                </p>
                                {/*end::Description*/}
                                {/*begin::Action*/}
                                <a
                                  onClick={openDrawer}
                                  href="#"
                                  className="btn btn-primary mb-10"
                                >
                                  Create Subuser
                                </a>
                                {/*end::Action*/}
                              </div>
                              {/*end::Wrapper*/}
                            </div>
                          </td>
                        </tr>
                      </>)}
                    </>)}
                  </>) : (<>
                    <tr>
                      <td colspan={12}>
                        <TableLoader />
                        <TopBarHeaderLine />
                      </td>
                    </tr>

                  </>)}


                </tbody>
                {/*end::Table body*/}
              </table>
              {/*end::Table*/}
            </div>
            {/*end::Table container*/}
            {/*begin::Body*/}
          </div>
          {/*end::Tables Widget 9*/}
        </div>
        :
        <TableLoader />
      }
      <div id="drawer_overlay" onClick={openDrawer} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>

      <div
        id="add_subuser-modal"
        className="drawer drawer-end"
        data-kt-drawer="true"
        data-kt-drawer-activate="true"
        data-kt-drawer-overlay="false"
        // data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}" 
        style={{ important: true, zIndex: 110 }}
      >
        {/*begin::Messenger*/}
        <div className="card w-100 rounded-0 border-0" id="kt_drawer_chat_messenger" style={{ height: 'fit-content' }}>
          {/*begin::Card header*/}
          <div className="card-header pe-5 ps-7" id="kt_drawer_chat_messenger_header">
            {/*begin::Title*/}
            <div className="card-title">
              {/*begin::User*/}
              <div className="d-flex justify-content-center flex-column me-3">
                <div

                  className="fs-4 fw-bolder text-gray-900 me-1 mb-2 lh-1"
                >
                  Add Subuser
                </div>
              </div>
              {/*end::User*/}
            </div>
            {/*end::Title*/}
            {/*begin::Card toolbar*/}
            <div className="card-toolbar">
              {/*begin::Close*/}
              <div
                className="btn btn-sm btn-icon btn-active-light-primary"
                onClick={openDrawer}
              >
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                <span className="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x={6}
                      y="17.3137"
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(-45 6 17.3137)"
                      fill="black"
                    />
                    <rect
                      x="7.41422"
                      y={6}
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(45 7.41422 6)"
                      fill="black"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
              </div>
              {/*end::Close*/}
            </div>
            {/*end::Card toolbar*/}
          </div>
          {/*end::Card header*/}
          {/*begin::Card body*/}
          <div className="" id="">
            {/*begin::Form*/}
            <form id="subuser-add">
              {/*begin::Modal body*/}
              <div className="modal-body ">
                {/*begin::Scroll*/}
                <div className="scroll-y me-n7 pe-7">
                  {/*begin::Input group*/}
                  <div className="mb-5 fv-row">
                    {/*begin::Label*/}
                    <label htmlFor="type" className="required fs-5 fw-bold mb-2">
                      {" "}
                      Full Name
                    </label>
                    {/*end::Label*/}
                    {/*begin::Input*/}
                    <input
                      type="text"
                      id="fullname"
                      name="fullname"
                      className="form-control"
                      placeholder="Enter Full Name"
                      onChange={(e) => handleInputChange(e, /[^a-zA-Z\s]/g, 'name')}
                    />
                    {renderWarningMessage()}
                    {/* <span className={`text-danger fs-6 ${showNameWaring.length <= 3?"":"d-none"}`}>Please enter at least 3 word name.</span> */}
                    {/*end::Input*/}
                  </div>
                  {/*end::Input group*/}
                  {/*begin::Input group*/}
                  <div className="mb-5 fv-row">
                    {/*begin::Label*/}
                    <label htmlFor="type" className="required fs-5 fw-bold mb-2">
                      {" "}
                      Mobile
                    </label>
                    {/*end::Label*/}
                    {/*begin::Input*/}
                    <div className="d-flex flex-wrap flex-stack ">
                      <input
                        type="text"
                        id="mobile"
                        defaultValue=""
                        maxLength={10}
                        name="mobile"
                        className="form-control border-input"
                        placeholder="Enter your Mobile Number"
                        onChange={(e) => handleInputChange(e, /\D/g, "mobile")}
                      />
                      {/* <span className={`text-danger fs-6 ${showMobileWaring.length >= 3?"":"d-none"}`}>Please enter your 10 digit valid number.</span> */}
                    </div>
                    {/*end::Input*/}
                  </div>
                  {/*end::Input group*/}
                  {/*begin::Input group*/}
                  <div className="d-flex flex-column mb-10 fv-row">
                    {/*begin::Label*/}
                    <label htmlFor="feedback" className="required fs-5 fw-bold mb-2">
                      Email
                    </label>
                    {/*end::Label*/}
                    {/*begin::Input*/}
                    <input
                      type="text"
                      name="email"
                      id="email"
                      className="form-control"
                      placeholder="name@example.com"
                    // onChange={(e)=> handleEmailChange(e)}
                    />
                    <input
                      type="hidden"
                      id="p_info"
                      className="form-control"
                      name="permissions"
                      defaultValue={0}
                    />
                    {/*end::Input*/}
                  </div>
                  <div className="separator border-2 my-8"></div>
                  <div className="d-flex flex-column mb-5 ">
                    {/*begin::Label*/}
                    <div className="d-flex">
                      <label htmlFor="feedback" className="required fs-5 fw-bold mb-2 col-8 col-sm-7 col-lg-8 col-xxl-8 mt-1 sphide">
                        Select required permissions
                      </label>
                      <div className={`${isMobile}`}></div>
                      <label className="form-check form-check-sm form-check-custom form-check ms-10 me-lg-20 col-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={selectAll}
                          onChange={(e) => handleSelectAll(e)}
                        />
                        <span className="form-check-label fw-bold fs-5">Select All</span>
                      </label>
                    </div>
                    <div class="separator border-2 my-6"></div>
                    {/* <div className="d-flex">
                      <input type="checkbox" data-kt-check='true' data-kt-check-target='#kt_customers_table .form-check-input' className="form-check form-check-sm form-check-custom form-check-solid ms-2" />
                      <span className="form-check-label ms-2">Select All</span>
                    </div> */}
                    {/*end::Label*/}
                    {/*begin::Table*/}
                    <table className="table align-middle table-row-dashed fs-6 gy-5">
                      {/*begin::Table body*/}
                      <tbody className="text-gray-600 fw-bold" id="permission-list">

                        {permission_arr.map((value, index) => (
                          <>
                            {/*begin::Table row*/}
                            <tr>
                              {/*begin::Label*/}
                              <td className="text-gray-800">{value[1]}</td>
                              <input type="hidden" class="form-control" id={"" + value[0] + "_set"} />
                              {/*end::Label*/}
                              {/*begin::Options*/}
                              <td>
                                {/*begin::Wrapper*/}
                                <div className="d-flex">
                                  {/*begin::Checkbox*/}
                                  <label className="form-check form-check-sm form-check-custom form-check me-5 me-lg-20">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      onChange={permissionInput}
                                      defaultValue={"" + value[0] + "_read"}
                                      id={"" + value[0] + "_read"}
                                    />
                                    <span className="form-check-label">Read</span>
                                  </label>
                                  {/*end::Checkbox*/}
                                  {/*begin::Checkbox*/}
                                  <label className="form-check form-check-sm form-check-custom form-check me-5 me-lg-20">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      onChange={permissionInput}
                                      defaultValue={"" + value[0] + "_write"}
                                      id={"" + value[0] + "_write"}
                                    />
                                    <span className="form-check-label">Write</span>
                                  </label>
                                  {/*end::Checkbox*/}
                                  {/*begin::Checkbox*/}
                                  <label className="form-check form-check-sm form-check-custom form-check ">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      onChange={permissionInput}
                                      defaultValue={"" + value[0] + "_delete"}
                                      id={"" + value[0] + "_delete"}
                                    />
                                    <span className="form-check-label">Delete</span>
                                  </label>
                                  {/*end::Checkbox*/}
                                </div>
                                {/*end::Wrapper*/}
                              </td>
                              {/*end::Options*/}
                            </tr>
                            {/*end::Table row*/}
                          </>
                        ))}
                      </tbody>
                      {/*end::Table body*/}
                    </table>
                    {/*end::Table*/}
                  </div>
                </div>
                {/*end::Scroll*/}

              </div>
              {/*end::Modal body*/}
              {/*begin::Modal footer*/}
              <div className="modal-footer border-2">
                {/*begin::Button*/}
                <button
                  type="button"
                  onClick={addUser}
                  className="btn btn-primary w-100"
                  style={{
                    marginBottom: marginBottom
                  }}
                >
                  <span className="indicator-label">+ Add subuser</span>
                </button>
                {/*end::Button*/}
              </div>
              {/*end::Modal footer*/}
            </form>
            {/*end::Form*/}
          </div>
          {pageStatus &&
            <TopBarHeaderLine />}
          {/*end::Card body*/}
        </div>
        {/*end::Messenger*/}
      </div>
    </>
  )
}