import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import UsePost from "../../../../../../CustomHooks/usePost";

export default function CreateTopics(props) {
    const [pageStatus, setPageStatus] = useState(false)

    var query = window.location.href;
    var vars = query.split("/");
    var id = vars[4];

    const handleChangeRepFacValue = (action) => {
        let replicationFactorDown = document.querySelector('#replicationFactorDown');
        let replicationFactorUp = document.querySelector('#replicationFactorUp');
        let replicationFactorValue = document.querySelector('#replicationFactor');

        if (action === "up") {
            if (parseInt(replicationFactorValue.value) >= 3) {
                replicationFactorUp.classList.add('disabled');
            } else {
                replicationFactorValue.value = parseInt(replicationFactorValue.value) + 1;
                replicationFactorDown.classList.remove('disabled');
            }
        } else {
            if (parseInt(replicationFactorValue.value) <= 2) {
                replicationFactorDown.classList.add('disabled');
            } else {
                replicationFactorValue.value = parseInt(replicationFactorValue.value) - 1;
                replicationFactorUp.classList.remove('disabled');
            }
        }
    };

    const createTopic = async(event) => {
        event.preventDefault()
        toast.dismiss()
        var submitBtn = document.querySelector("#create-kafkaUser-btn");
        var retention_in_bytes = document.querySelector('#retention_in_bytes')
        var retention_in_hours = document.querySelector('#retention_in_hours')
        var partitionCount = document.querySelector('#partitionCount')
        var topicName = document.querySelector('#topicName')
        var replicationFactor = document.querySelector('#replicationFactor')
        // var retention_in_bytes = document.querySelector('#retention_in_bytes')
        if (submitBtn) {
          setPageStatus(true)
          var preContent = submitBtn.innerHTML;
          submitBtn.classList.add("disabled");
          submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
          const dt = toast.loading("Please wait...");
    
          const responseData = await UsePost('post', 'databases/' + id + '/topic', 'topicData');
          if (responseData) {
            setPageStatus(false)
            submitBtn.classList.remove("disabled");
            submitBtn.innerHTML = preContent;
            if (responseData.status === 'error') {
              toast.update(dt, {
                render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
              });
            } else if (responseData.status === 'success') {
              toast.update(dt, {
                render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
              });
              replicationFactor.value = '2'
              retention_in_bytes.value = ''
              retention_in_hours.value = ''
              partitionCount.value = ''
              topicName.value = ''
              if (props) {
                props.openDrawer();
                props.callBack();
              }
    
            }
          }
        }
    }

    return (
        <div
            id="topics-modal"
            className="bg-body shadow drawer drawer-end"
            // data-kt-drawer="true"
            // data-kt-drawer-name="chat"
            // data-kt-drawer-activate="true"
            // data-kt-drawer-direction="end"
            data-kt-drawer-close="#close_ssh"
            // data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
            style={{ important: true, zIndex: 110 }}
        >
            {/*begin::Messenger*/}
            <div
                className="card rounded-0 w-100 border-0"
                id="kt_drawer_chat_messenger"
            >
                {/*begin::Card header*/}
                <div
                    className="card-header pe-5"
                    id="kt_drawer_chat_messenger_header"
                >
                    {/*begin::Title*/}
                    <div className="card-title">
                        {/*begin::User*/}
                        <div className="d-flex justify-content-center flex-column me-3">
                            <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                                Add New Topics
                            </a>
                        </div>
                        {/*end::User*/}
                    </div>
                    {/*end::Title*/}
                    {/*begin::Card toolbar*/}
                    <div className="card-toolbar">
                        {/*begin::Close*/}
                        <div
                            onClick={props.openDrawer}
                            className="btn btn-sm btn-icon btn-active-light-primary"
                            id="close_ssh"
                        >
                            {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                            <span className="svg-icon svg-icon-2">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <rect
                                        opacity="0.5"
                                        x={6}
                                        y="17.3137"
                                        width={16}
                                        height={2}
                                        rx={1}
                                        transform="rotate(-45 6 17.3137)"
                                        fill="black"
                                    />
                                    <rect
                                        x="7.41422"
                                        y={6}
                                        width={16}
                                        height={2}
                                        rx={1}
                                        transform="rotate(45 7.41422 6)"
                                        fill="black"
                                    />
                                </svg>
                            </span>
                            {/*end::Svg Icon*/}
                        </div>
                        {/*end::Close*/}
                    </div>
                    {/*end::Card toolbar*/}
                </div>
                {/*end::Card header*/}
                {/*begin::Card body*/}
                <div className="" id="">
                    {/*begin::Form*/}
                    <form
                        name="importkey"
                        id="topicData"
                        className="form"
                    >
                        {/*begin::Modal body*/}
                        <div className="modal-body ">
                            {/*begin::Scroll*/}
                            <div
                                className="scroll-y me-n7 pe-7"
                                id="kt_modal_create_api_key_scroll"
                                data-kt-scroll="true"
                                data-kt-scroll-activate="{default: false, lg: true}"
                                data-kt-scroll-max-height="auto"
                                data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                                data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                                data-kt-scroll-offset="300px"
                            >
                                {/*begin::Input group*/}
                                <div className="mb-5 row">
                                    {/*begin::Label*/}
                                    <div className="col-lg-12 col-md-12">
                                        <label className="required fs-5 fw-bold mb-2">
                                            {" "}
                                            Topic Name
                                        </label>
                                        {/*end::Label*/}
                                        {/*begin::Input*/}
                                        <input className="form-control" type="text" name="topic_name" id="topicName" />
                                    </div>
                                </div>
                                <div className="mb-5 row">
                                <div className="col-lg-6 col-md-6">
                                        <label className="required fs-5 fw-bold mb-2">
                                            {" "}
                                            Partition Count
                                        </label>
                                        {/*end::Label*/}
                                        {/*begin::Input*/}
                                        <input
                                            className="form-control"
                                            type="number"
                                            id="partitionCount"
                                            name="partition"
                                            onChange={(event) => {
                                                var inputValue = parseInt(event.target.value);

                                                // Check if the input value is smaller than 3
                                                if (inputValue < 3) {
                                                    inputValue = 3;
                                                }

                                                // Check if the input value is greater than 2048
                                                if (inputValue > 2048) {
                                                    inputValue = 2048;
                                                }

                                                // Update the input value
                                                event.target.value = inputValue;
                                            }}
                                        />
                                        <span className="fs-8 text-gray-500">Enter a number between 3 and 2048:</span>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <label className="required fs-5 fw-bold mb-2">
                                            {" "}
                                            Replication Factor
                                        </label>
                                        {/*end::Label*/}
                                        {/*begin::Input*/}
                                        {/* <input className="form-control" type="text" name="replicationFactor" /> */}
                                        <div className="d-flex align-items-center">
                                            <div className="input-group w-md-200px" data-kt-dialer="true">
                                                <a className="btn btn-icon btn-outline btn-outline-secondary" id="replicationFactorDown" onClick={() => handleChangeRepFacValue('down')}>
                                                    <i className="bi bi-dash fs-1" />
                                                </a>
                                                <input
                                                    type="text"
                                                    className="form-control text-center"
                                                    readOnly=""
                                                    name="replication"
                                                    id="replicationFactor"
                                                    defaultValue={2}
                                                />
                                                <a className="btn btn-icon btn-outline btn-outline-secondary" id="replicationFactorUp" onClick={() => handleChangeRepFacValue('up')}>
                                                    <i className="bi bi-plus fs-1" />
                                                </a>
                                            </div>
                                        </div>
                                        <span className="fs-8 text-gray-500">Must be between 2 and 3</span>
                                    </div>
                                </div>
                                <div className="mb-5 row">
                                <div className="col-lg-6 col-md-6">
                                        <label className="required fs-5 fw-bold mb-2">
                                            {" "}
                                            Retention (hours)
                                        </label>
                                        {/*end::Label*/}
                                        {/*begin::Input*/}
                                        <input className="form-control" type="text" name="retension_period" id="retention_in_hours" />
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <label className="required fs-5 fw-bold mb-2">
                                            {" "}
                                            Retention (bytes)
                                        </label>
                                        {/*end::Label*/}
                                        {/*begin::Input*/}
                                        <input className="form-control" type="text" name="retension_size" id="retention_in_bytes" />
                                    </div>
                                </div>
                                {/*end::Input*/}
                            </div>
                            {/*end::Input group*/}

                        </div>
                        {/*end::Scroll*/}
                        {/* </div> */}
                        {/*end::Modal body*/}
                        {/*begin::Modal footer*/}
                        <div className="modal-footer ">
                            {/*begin::Button*/}
                            <a
                                onClick={createTopic}
                                className="btn btn-primary w-100"
                                id="create-kafkaUser-btn"
                            >
                                <span className="indicator-label">
                                    Add Topics
                                </span>
                            </a>
                            {/*end::Button*/}
                        </div>
                        {/*end::Modal footer*/}
                    </form>
                    {/*end::Form*/}
                </div>
                {/*end::Card body*/}
            </div>
            {/*end::Messenger*/}
        </div>
    )
}