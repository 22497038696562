import axios from "axios";
import localStorage from "local-storage";
 
export async function getAppInfo() {

  const platformInfo = await getPlatformType();

  const AppData = await axios.get(platformInfo?.baseUrl + "/v2/appinfo");

  if (AppData && AppData.data) {
    var siteUrlString = AppData.data.site;
    var siteUrlArr = siteUrlString.split(".");
    var siteLength = siteUrlArr.length;
    var siteOrg =
      "https://" +
      platformInfo?.baseEndPoint +
      "." +
      siteUrlArr[siteLength - 2] +
      "." +
      siteUrlArr[siteLength - 1];
    localStorage.set("filesUrl", siteOrg);
    AppData.data["origin"] = siteOrg;
    AppData.data["publicDir"] = "publicfiles";
    return AppData.data;
  }
}

export function getPlatformType() {
  const platformName = localStorage.get("platformType");
  console.log(platformName,"platform");
  if (platformName) {
    console.log("hello buddy");
    if (platformName == "dev") {
      return {
        baseUrl: "https://dev.api.cloudplatformapp.com",
        baseEndPoint: "dev.api",
      };
    } else {
      return {
        baseUrl: "https://api.utho.com",
        baseEndPoint: "api",
      };
    }
  } else {
    return {
      baseUrl: "https://api.utho.com",
      baseEndPoint: "api",
    };
  }
}

export function setPlatformType(location) {
  const searchParams = new URLSearchParams(location?.search);
  const env = searchParams.get("env");
  const isdebug = searchParams.get("debug");
  const platformName = localStorage.get("platformType");

  if (platformName) {
    if (env) {
      if (platformName != env) {
        localStorage.remove("platformType");
        setTimeout(() => {
          if (env === "dev") {
            localStorage.set("platformType", "dev");
          } else if (env === "prod") {
            localStorage.set("platformType", "prod");
          }
        }, 100);
      }
    }
  } else {
    if (env === "dev") {
      localStorage.set("platformType", "dev");
    } else if (env === "prod") {
      localStorage.set("platformType", "prod");
    }
  }

  if(isdebug=="on"){
     localStorage.set("debug", "on");
  }else{
     localStorage.set("debug", "off");
  }
}
