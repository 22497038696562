import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UseApi from "../../../../../CustomHooks/useApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UsePost from "../../../../../CustomHooks/usePost";
import swal from "sweetalert";
import TableLoader from "../../../Loaders/TableLoader";
import TopBarHeaderLine from "../../../../../CustomHooks/TopBarHeaderLine";
import UsePostData from "../../../../../CustomHooks/usePostData";
import QueryString from "./advanceRouting/QueryString";
import HttpHeader from "./advanceRouting/HttpHeader";
import { appendAclConditions } from "../../../../../Redux/Services/Action/actions";
import { connect } from "react-redux";
import Conditions from "./advanceRouting/Conditions";

function AdvanceRouting(props) {
  var query = window.location.href;
  var vars = query.split("/");
  var id = vars[4];

  const [aclConditionList, setAclConditionList] = useState(["http_user_agent", "http_referer", "url_path", "", "http_method", "ssl_cipher_suite", "ssl_certificate", "network_protocol", "ip_geolocation", "authenticated_users", "custom_header"]);
  const [lbData, setLbData] = useState(null);
  const [frontendid, setFrontendid] = useState(0);

  const openDrawerAcl = () => {
    var drawerElement = document.querySelector("#acl-modal_" + frontendid);
    var drawer_overlay = document.querySelector("#drawer_overlay_acl" + frontendid);
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
        drawerElement.style.width = "100%";
      } else {
        drawerElement.style.width = "60%";
      }
      drawer_overlay.classList.toggle("d-none");
    }
  }
  const openDrawerRouting = () => {
    var drawerElement = document.querySelector("#routing-modal_" + frontendid);
    var drawer_overlay = document.querySelector("#drawer_overlay_routing" + frontendid);
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
        drawerElement.style.width = "100%";
      } else {
        drawerElement.style.width = "40%";
      }
      drawer_overlay.classList.toggle("d-none");
    }
  }


  const updateOpenDrawerRouting = () => {
    var drawerElement = document.querySelector("#update-routing-modal_" + frontendid);
    var drawer_overlay = document.querySelector("#update_drawer_overlay_routing" + frontendid);
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
        drawerElement.style.width = "100%";
      } else {
        drawerElement.style.width = "40%";
      }
      drawer_overlay.classList.toggle("d-none");
    }

  }


  useEffect(() => {
    if (props && props.arData) {
      setLbData(props.arData);
      setFrontendid(props.arData.id)
    }
  }, [props])

  const addAdvanceRouting = async () => {
    var submitBtn = document.querySelector("#addRouteBtn" + frontendid);
    var targetGroupField = document.querySelector("#targetInputField" + frontendid);
    var form = document.querySelector("#addRoutingForm" + frontendid); // Get the form
  
    // Check if targetGroupField is empty or unselected
   
  
    if (submitBtn) {
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
  
      toast.dismiss();
      const dt = toast.loading("Adding Advance Routing, Please wait...");
      const responseData = await UsePost(
        "post",
        "loadbalancer/" + id + "/route",
        "addRoutingForm" + frontendid
      );
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML = preContent;
  
      if (responseData.status === "error") {
        toast.update(dt, {
          render: responseData.message,
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
  
        if (form) {
          form.reset();
        }
        if (props && props.refreshLb) {
          props.refreshLb();
        }
      } else {
        toast.update(dt, {
          render: responseData.message,
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
  
        // Clear input fields
        targetGroupField.value = "";
        openDrawerRouting();
  
        // Reset the form after submission
        if (form) {
          form.reset();
        }
  
        if (props && props.refreshLb) {
          props.refreshLb();
        }
      }
    }
  };
  
  
  const deleteAdvanceRouting = async (acl_id) => {
    var submitBtn = document.querySelector("#delete-advance-routing-btn-" + acl_id);

    if (submitBtn) {
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';

      toast.dismiss();
      const dt = toast.loading("Advance Routing deleting, Please wait...");
      const responseData = await UsePostData("delete", "loadbalancer/" + id + "/route/" + acl_id, {});
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML = preContent;
      if (responseData.status === "error") {
        toast.update(dt, {
          render: responseData.message,
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (props && props.refreshLb) {
          props.refreshLb();
        }
      } else {
        toast.update(dt, {
          render: responseData.message,
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (props && props.refreshLb) {
          props.refreshLb();
        }
      }
    }
  };

  const toggleAclBtn = (action) => {
    var AddAclBtn = document.querySelector("#AddAclBtn");
    if (AddAclBtn) {
      if (action == "close") {
        AddAclBtn.classList.add("d-none");
      } else {
        AddAclBtn.classList.remove("d-none");
      }
    }
  }
  function checkObjectForEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];

        if (Array.isArray(value)) {
          // If property is an array, check each element for empty or blank values
          for (let i = 0; i < value.length; i++) {
            if (typeof value[i] === 'object') {
              // Recursively check nested objects
              const nestedResult = checkObjectForEmpty(value[i]);
              if (nestedResult) {
                return nestedResult;
              }
            } else if (value[i] === undefined || value[i] === null || value[i].toString().trim() === '') {
              return true;
            }
          }
        } else if (typeof value === 'object') {
          // If property is an object, recursively check nested objects
          const nestedResult = checkObjectForEmpty(value);
          if (nestedResult) {
            return nestedResult;
          }
        } else if (value === undefined || value === null || value.toString().trim() === '') {
          return true;
        }
      }
    }

    return false; // No empty or blank values found
  }

  const addAclRule = async () => {
    if (frontendid != 0 && indexIds != "nothing") {
      var name = document.querySelector("#name" + frontendid);
      var conditionType = document.querySelector("#conditionType" + frontendid);

      // Check if the name field is empty
      if (!name || !name.value.trim()) {
        toast.error("Name field cannot be empty!", {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return; // Prevents form submission if name is empty
      }

      if (props && props.aclConditionData) {
        if (checkObjectForEmpty(props.aclConditionData[indexIds])) {
          const dt = toast.loading("ACL Rule adding...");
          toast.update(dt, {
            render: "All fields are required!",
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return;
        }

        var submitBtn = document.querySelector("#AddAclBtn");
        if (submitBtn) {
          var preContent = submitBtn.innerHTML;
          submitBtn.classList.add("disabled");
          submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';

          toast.dismiss();
          const dt = toast.loading("ACL Rule adding, Please wait...");
          if (conditionType) {
            const responseData = await UsePostData(
              "post",
              "loadbalancer/" + id + "/acl",
              {
                name: name.value,
                conditionType: conditionType.value,
                frontend_id: frontendid,
                value: JSON.stringify(props.aclConditionData[indexIds]),
              }
            );
            submitBtn.classList.remove("disabled");
            submitBtn.innerHTML = preContent;
            if (responseData.status === "error") {
              if (props && props.refreshLb) {
                props.refreshLb();
              }
              toast.update(dt, {
                render: responseData.message,
                type: "error",
                isLoading: false,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else {
              if (props && props.refreshLb) {
                props.refreshLb();
              }
              toast.update(dt, {
                render: responseData.message,
                type: "success",
                isLoading: false,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              openDrawerAcl();
              if (props && props.refreshLb) {
                props.refreshLb();
              }
            }
          }
        }
      }
    }
  };


  const deleteAclRule = async (acl_id) => {
    var submitBtn = document.querySelector("#delete-acl-btn-" + acl_id);

    if (submitBtn) {
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';

      toast.dismiss();
      const dt = toast.loading("ACL Rule deleting, Please wait...");
      const responseData = await UsePostData("delete", "loadbalancer/" + id + "/acl/" + acl_id, {});
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML = preContent;
      if (responseData.status === "error") {
        toast.update(dt, {
          render: responseData.message,
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (props && props.refreshLb) {
          props.refreshLb();
        }
      } else {
        toast.update(dt, {
          render: responseData.message,
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (props && props.refreshLb) {
          props.refreshLb();
        }
      }
    }
  };

  const updateAcl = async (aid) => {
    var acl_name = document.querySelector("#acl_name" + frontendid + "" + aid);
    var acl_conditionType = document.querySelector("#acl_conditionType" + frontendid + "" + aid);
    var acl_value = document.querySelector("#acl_value" + frontendid + "" + aid);
    if (acl_name && acl_conditionType && acl_value) {
      toast.dismiss();
      const dt = toast.loading(
        "Updating the ACL, Please wait..."
      );
      const responseData = await UsePostData(
        "put",
        "loadbalancer/" + id + "/acl/" + aid,
        { name: acl_name.value, conditionType: acl_conditionType.value, value: acl_value.value, frontend_id: frontendid }
      );
      if (responseData.status === "error") {
        toast.update(dt, {
          render: responseData.message,
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (props && props.refreshLb) {
          props.refreshLb();
        }
      } else {
        toast.update(dt, {
          render: responseData.message,
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (props && props.refreshLb) {
          props.refreshLb();
        }
      }
    }
  }

  const updateRouting = async (rid) => {
    var acl_id = document.querySelector("#acl_id" + frontendid + "" + rid);
    var route_condition = document.querySelector("#route_condition" + frontendid + "" + rid);
    var backend = document.querySelector("#backend" + frontendid + "" + rid);
    if (acl_id && route_condition && backend) {
      toast.dismiss();
      const dt = toast.loading(
        "Updating the Routing, Please wait..."
      );
      const responseData = await UsePostData(
        "put",
        "loadbalancer/" + id + "/route/" + rid,
        { acl_id: acl_id.value, route_condition: route_condition.value, target_groups: backend.value, frontend_id: frontendid }
      );
      if (responseData.status === "error") {
        toast.update(dt, {
          render: responseData.message,
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (props && props.refreshLb) {
          props.refreshLb();
        }
      } else {
        toast.update(dt, {
          render: responseData.message,
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (props && props.refreshLb) {
          props.refreshLb();
        }
      }
    }
  }

  const updateAdvanceRoutingSubmit = async () => {
    var routing_id = document.querySelector("#update_routing_id" + frontendid);
    var acl_id = document.querySelector("#update_acl_id" + frontendid);
    var route_condition = document.querySelector("#update_route_condition" + frontendid);
    var backend = document.querySelector("#updateTargetInputField" + frontendid);
    if (!backend.value) {
      toast.error("Please select a target group before submitting.");
      return; // Prevent form submission if the field is empty
    }
    var updateRouteBtn = document.querySelector("#updateRouteBtn" + frontendid);
    if (updateRouteBtn) {
      var preContent = updateRouteBtn.innerHTML;
      updateRouteBtn.classList.add("disabled");
      updateRouteBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';

      if (acl_id && route_condition && backend && routing_id) {
        toast.dismiss();
        const dt = toast.loading(
          "Updating the Routing, Please wait..."
        );
        const responseData = await UsePostData(
          "put",
          "loadbalancer/" + id + "/route/" + routing_id.value,
          { acl_id: acl_id.value, route_condition: route_condition.value, target_groups: backend.value, frontend_id: frontendid }
        );
        updateRouteBtn.classList.remove("disabled");
        updateRouteBtn.innerHTML = preContent;
        if (responseData.status === "error") {
          toast.update(dt, {
            render: responseData.message,
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          
          if (props && props.refreshLb) {
            props.refreshLb();
          }
        } else {
          toast.update(dt, {
            render: responseData.message,
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          backend.value = ""
          if (props && props.refreshLb) {
            props.refreshLb();
          }
        }
      }
    }
  }

  const [currentCondition, setCurrentCondition] = useState("");
  // const [currentConditionData, setCurrentConditionData] = useState(null);
  const createNewCondition = (key) => {
    setCurrentCondition(key);
    if (props) {
      if (props.aclConditionData) {
        if (key == "query_string") {
          var updateArray = manipulateArray(props.aclConditionData, 'create', { frontend_id: frontendid, type: "query_string", data: { "": "" } });
        } else if (key == "http_header") {
          var updateArray = manipulateArray(props.aclConditionData, 'create', { frontend_id: frontendid, type: "http_header", data: { header_name: "", value: "" } });
        } else {
          var updateArray = manipulateArray(props.aclConditionData, 'create', { frontend_id: frontendid, type: "" + key + "", data: "" });
        }

        if (props.addAclProps) {
          props.addAclProps(updateArray);

        }

      }
    }
  }



  // useEffect(()=>{
  //   if(props && props.aclConditionData){
  //     setCurrentConditionData(props.aclConditionData[frontendid]);
  //    }
  //     },[props])

  const findElementIndex = (array, frontendsid) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].frontend_id === frontendsid) {
        return i; // Found a match, return the index
      }
    }
    return -1; // Frontend id not found in the array
  };
  const [indexIds, setIndexIds] = useState("nothing");

  const setIndexId = () => {
    if (props && props.aclConditionData)
      var aclIndex = findElementIndex(props.aclConditionData, frontendid);
    if (aclIndex == -1) {
    } else {
      setIndexIds(aclIndex);
    }
  }

  function manipulateArray(arr, operation, options) {
    // find index in associative array using key 
    function findIndexByKey(array, key) {
      for (let i = 0; i < array.length; i++) {
        if (key in array[i]) {
          return i;
        }
      }
      // If key is not found in any object, return -1
      return -1;
    }

    // Create a new array element based on the specified type
    function createArrayElement(frontend_id, type, data) {
      switch (type) {
        case "query_string":
          return { frontend_id, type, data: [data] };
        case "http_header":
          return { frontend_id, type, header_name: data.header_name, data: [data.value] };
        default:
          return { frontend_id, type, data: [data] };
      }
    }

    switch (operation) {
      case "create":
        const { frontend_id, type, data } = options;
        var aclIndex = findElementIndex(arr, frontendid);
        if (aclIndex == -1) {
          const newElement = createArrayElement(frontend_id, type, data);
          if (newElement) {
            arr.push(newElement);
          }
        } else {
          arr[aclIndex] = [];
          const newElement = createArrayElement(frontend_id, type, data);
          if (newElement) {
            arr[aclIndex] = newElement;

          }
        }
        setTimeout(() => {
          setIndexId();
        }, 10)

        console.log(arr);

        return arr;

      case "read":
        return arr;

      case "update":
        const { updateType, newData } = options;
        if (updateType === "http_header") {
          arr[0].data = newData.data;
          arr[0].header_name = newData.header_name;
        } else {
          arr[0].data = newData;
        }
        return arr;

      case "delete":
        const { deleteType, key } = options;

        if (deleteType == "http_header") {
          if (arr[0].data.length > 0) {
            let deleteIndex = arr[0].data.indexOf(key)
            arr[0].data.splice(deleteIndex, 1);
          }
        }
        else if (deleteType == "query_string") {
          let deleteIndex = findIndexByKey(arr[0].data, key);
          arr[0].data.splice(deleteIndex, 1);
        } else {
          if (arr[0].data.length > 0) {
            let deleteIndex = arr[0].data.indexOf(key)
            arr[0].data.splice(deleteIndex, 1);
          }
        }
        return arr;

      default:
        console.error("Invalid operation");
        return arr;
    }
  }

  const [targetData, setTargetData] = useState([]);
  const [updatedtargetData, setUpdatedTargetData] = useState([]);

  const getLoadBalancerTargetGroups = async () => {
    if (frontendid) {
      const data = await UseApi('get', 'targetgroup', '');
      if (data) {
        if (data.status != "success") {
          setTargetData([]);
        } else {
          if (data.targetgroups) {
            var loadbalancer_field = document.querySelector("#loadbalancer_target_field" + frontendid);
            if (loadbalancer_field) {
              console.log("lb field");
              var ldata = [];

              data.targetgroups.map((value, index) => {
                console.log(value.id)
                ldata.push(value.name + " (" + value.id + ")");
              })
              console.log(ldata, "all groups");
              const contactTagify = new window.Tagify(loadbalancer_field, {
                whitelist: ldata,
                maxTags: 20,
                dropdown: {
                  maxItems: 20,
                  classname: "",
                  enabled: 0,
                  closeOnSelect: false
                }
              });
            } else {
              //console.log("no lb field");
            }
          }
          setTargetData(data.targetgroups);

        }
      }
    }
  }

  const getUpdatedTargetList = async (fid) => {
    if (targetData) {
      var loadbalancer_list = document.querySelector("#backend" + fid);
      var backendSpan = document.querySelector("#backendSpan" + fid);
      // var loadbalancer_field = document.querySelector("#backend-field" + fid);
      // console.log("#backend-field" + fid);
      // if (loadbalancer_field && loadbalancer_list && backendSpan) {
      if (loadbalancer_list && backendSpan) {
        var tgdata = loadbalancer_list.value;
        if (tgdata) {
          var tgList = [];
          var tgListInclude = [];
          var currentList = tgdata.split(",");
          console.log(tgdata);
          tgList = targetData.filter((item) => {
            return !currentList.includes(item.id);
          });
          tgListInclude = targetData.filter((item) => {
            return currentList.includes(item.id);
          });
          var ldata = [];
          var ldataSaved = [];

          tgList.map((value, index) => {
            ldata.push(value.name + " (" + value.id + ")");
          });

          tgListInclude.map((value, index) => {
            ldataSaved.push(value.name + " (" + value.id + ")");
          });

          var tgInfo = ldataSaved.join(",");
          // loadbalancer_field.value = tgInfo;
          // console.log(ldata, "ldata list");
          backendSpan.innerHTML = tgInfo;
          // Initialize Tagify after setting the value
          // if(ldata.length>0){
          //    console.log(ldata, "list");
          //   const contactTagify = new window.Tagify(loadbalancer_field, {
          //       whitelist: ldata,
          //       maxTags: 20,
          //       dropdown: {
          //           maxItems: 20,
          //           classname: "",
          //           enabled: 0,
          //           closeOnSelect: false
          //       }
          //   }); 
          // }else{
          //   if(currentList.length==targetData.length){
          //     const contactTagify = new window.Tagify(loadbalancer_field, {
          //       whitelist: ldata,
          //       maxTags: 20,
          //       dropdown: {
          //           maxItems: 20,
          //           classname: "",
          //           enabled: 0,
          //           closeOnSelect: false
          //       }
          //   }); 
          //   }
          // }

        }
      }
    }
  };

  const getUpdatedTargetListAdvanceRouting = async (fid) => {
    console.log(fid);
    
    if (targetData) {
      var loadbalancer_list = document.querySelector("#updateTargetInputField" + fid);
      var loadbalancer_field = document.querySelector("#update_loadbalancer_target_field" + fid);
      console.log("#update_loadbalancer_target_field" + fid,loadbalancer_field, loadbalancer_list);
      if (loadbalancer_field && loadbalancer_list) {
        var tgdata = loadbalancer_list.value;
        if (tgdata) {
          var tgList = [];
          var tgListInclude = [];
          var currentList = tgdata.split(",");
          console.log(tgdata);
          tgList = targetData.filter((item) => {
            return !currentList.includes(item.id);
          });
          tgListInclude = targetData.filter((item) => {
            return currentList.includes(item.id);
          });
          var ldata = [];
          var ldataSaved = [];

          tgList.map((value, index) => {
            ldata.push(value.name + " (" + value.id + ")");
          });

          tgListInclude.map((value, index) => {
            ldataSaved.push(value.name + " (" + value.id + ")");
          });

          var tgInfo = ldataSaved.join(",");
          loadbalancer_field.value = tgInfo;
          // console.log(ldata, "ldata list");
          console.log(tgInfo,ldata);
          
          // Initialize Tagify after setting the value
          if (ldata.length > 0) {
            console.log(ldata, "list");
            const contactTagify = new window.Tagify(loadbalancer_field, {
              whitelist: ldata,
              maxTags: 20,
              dropdown: {
                maxItems: 20,
                classname: "",
                enabled: 0,
                closeOnSelect: false
              }
            });
          } else {
            if (currentList.length == targetData.length) {
              const contactTagify = new window.Tagify(loadbalancer_field, {
                whitelist: ldata,
                maxTags: 20,
                dropdown: {
                  maxItems: 20,
                  classname: "",
                  enabled: 0,
                  closeOnSelect: false
                }
              });
            }
          }

        }else{
          getLoadBalancerTargetGroups()
        }
      }
    }
  };


  function updateLoadbalancerTargetGroups(fid, e) {
    if (e.target.value) {
      var eleData = JSON.parse(e.target.value);
      var eleIds = eleData.map(item => {
        // Extract numeric portion from the value (assuming it's always in parentheses)
        var match = item.value.match(/\((\d+)\)/);
        return match ? match[1] : null;
      }).filter(Boolean); // Filter out null values

      // Concatenate the cloud IDs into a comma-separated string
      var concatenatedLoadbalancerIds = eleIds.join(',');

      // Set the concatenated string as the value of the add_cloud input
      var targetInputField = document.querySelector("#backend" + fid);
      if (targetInputField) {
        targetInputField.value = concatenatedLoadbalancerIds;
      }
    }
    getUpdatedTargetList(fid);
  }

  // function addNewLoadbalancerTargetGroups(e) {
  //   if (e.target.value) {
  //     var eleData = JSON.parse(e.target.value);
  //     var eleIds = eleData.map(item => {
  //       // Extract numeric portion from the value (assuming it's always in parentheses)
  //       var match = item.value.match(/\((\d+)\)/);
  //       return match ? match[1] : null;
  //     }).filter(Boolean); // Filter out null values
  //     console.log(eleData);

  //     // Concatenate the cloud IDs into a comma-separated string
  //     var concatenatedLoadbalancerIds = eleIds.join(',');

  //     // Set the concatenated string as the value of the add_cloud input
  //     var targetInputField = document.querySelector("#targetInputField" + frontendid);
  //     if (targetInputField) {
  //       console.log(concatenatedLoadbalancerIds);
  //       targetInputField.value = concatenatedLoadbalancerIds;
  //     }
  //   }
  // }

  function addNewLoadbalancerTargetGroups(e) {
    // Check if the input value exists (i.e., there is some selection)
    if (e.target.value) {
      try {
        var eleData = JSON.parse(e.target.value); // Parse the selected value
        var eleIds = eleData.map(item => {
          // Extract numeric portion from the value (assuming it's always in parentheses)
          var match = item.value.match(/\((\d+)\)/);
          return match ? match[1] : null;
        }).filter(Boolean); // Filter out null values

        // Concatenate the cloud IDs into a comma-separated string
        var concatenatedLoadbalancerIds = eleIds.join(',');

        // Set the concatenated string as the value of the hidden input
        var targetInputField = document.querySelector("#targetInputField" + frontendid);
        if (targetInputField) {
          console.log(concatenatedLoadbalancerIds);
          targetInputField.value = concatenatedLoadbalancerIds;
        }
      } catch (error) {
        console.error("Failed to parse input value", error);
      }
    } else {
      // If input value is cleared, clear the hidden input field as well
      var targetInputField = document.querySelector("#targetInputField" + frontendid);
      if (targetInputField) {
        targetInputField.value = ''; // Clear the hidden input
        getLoadBalancerTargetGroups(frontendid)
        console.log("Cleared target groups selection");
      }
    }
}


  // function updateNewLoadbalancerTargetGroups(e) {
  //   if (e.target.value) {
  //     var eleData = JSON.parse(e.target.value);
  //     var eleIds = eleData.map(item => {
  //       // Extract numeric portion from the value (assuming it's always in parentheses)
  //       var match = item.value.match(/\((\d+)\)/);
  //       return match ? match[1] : null;
  //     }).filter(Boolean); // Filter out null values

  //     // Concatenate the cloud IDs into a comma-separated string
  //     var concatenatedLoadbalancerIds = eleIds.join(',');

  //     // Set the concatenated string as the value of the target_groups hidden input
  //     var targetInputField = document.querySelector("#updateTargetInputField" + frontendid);
  //     if (targetInputField) {
  //       targetInputField.value = concatenatedLoadbalancerIds;
  //       console.log("Updated target groups:", concatenatedLoadbalancerIds);
  //     }
  //   }
  // }

  function updateNewLoadbalancerTargetGroups(e) {
    // Check if input has value (i.e., something is selected)
    if (e.target.value) {
      try {
        // Parse the selected value (assuming it's in JSON format)
        var eleData = JSON.parse(e.target.value);
        var eleIds = eleData.map(item => {
          // Extract numeric portion from the value (assuming it's always in parentheses)
          var match = item.value.match(/\((\d+)\)/);
          return match ? match[1] : null;
        }).filter(Boolean); // Filter out null values
  
        // Concatenate the IDs into a comma-separated string
        var concatenatedLoadbalancerIds = eleIds.join(',');
  
        // Set the concatenated string as the value of the hidden input field
        var targetInputField = document.querySelector("#updateTargetInputField" + frontendid);
        if (targetInputField) {
          targetInputField.value = concatenatedLoadbalancerIds;
          console.log("Updated target groups:", concatenatedLoadbalancerIds);
        }
      } catch (error) {
        console.error("Failed to parse input value", error);
      }
    } else {
      // If the input value is cleared, clear the hidden input field as well
      var targetInputField = document.querySelector("#updateTargetInputField" + frontendid);
      if (targetInputField) {
        targetInputField.value = ''; // Clear the hidden input
        getUpdatedTargetListAdvanceRouting(frontendid)
        console.log("Cleared target groups selection");
      }
    }
  }
  

  useEffect(() => {
    setTimeout(() => {
      getLoadBalancerTargetGroups();
    }, 2000)
  }, [frontendid])

  function isJSON(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }


  const updateAdvanceRouting = (rid, acl_id, route_condition, targetInputField) => {
    if (!targetInputField) {
      toast.error("Please select a target group before submitting.");
      return; // Prevent form submission if the field is empty
    }
    if (frontendid) {
      var update_routing_id = document.querySelector("#update_routing_id" + frontendid);
      var update_acl_id = document.querySelector("#update_acl_id" + frontendid);
      var update_route_condition = document.querySelector("#update_route_condition" + frontendid);
      var updateTargetInputField = document.querySelector("#updateTargetInputField" + frontendid);

      if (update_acl_id && update_route_condition && updateTargetInputField && update_routing_id) {
        update_acl_id.value = acl_id;
        update_route_condition.value = route_condition;
        updateTargetInputField.value = targetInputField;
        update_routing_id.value = rid;
      }
    }
    getUpdatedTargetListAdvanceRouting(frontendid);
    updateOpenDrawerRouting();
  }
  return (<>
    <div className="border-dashed rounded mb-10">
      {/*begin::ACL Rules*/}
      <div className="mb-10">
        {/*begin::Card header*/}
        <div className="card-header">
          {/*begin::Heading*/}
          <div className="card-title">
            <h3>ACL Rules</h3>
          </div>
          {/*end::Heading*/}
          {/*begin::Toolbar*/}
          <div className="card-toolbar">
            <a
              className="btn btn-sm btn-primary me-2"
              onClick={openDrawerAcl}
            >
              <span className="svg-icon svg-icon-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="11.364"
                    y="20.364"
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(-90 11.364 20.364)"
                    fill="black"
                  />
                  <rect x="4.36396" y="11.364" width={16} height={2} rx={1} fill="black" />
                </svg>
              </span>
              Add New Rule
            </a>
          </div>
          {/*end::Toolbar*/}
        </div>
        {/*end::Card header*/}
        {/*begin::Body*/}
        <div className="card-body p-0">
          {/*begin::Table wrapper*/}
          <div className="table-responsive">
            {/*begin::Table*/}
            <table
              className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
              id="kt_api_keys_table"
            >
              {/*begin::Thead*/}
              <thead className="border-gray-200 fs-5 fw-bold bg-secondary">
                <tr>
                  <th className="w-125px">Name</th>
                  <th className="w-45px" />
                  <th className="w-125px text-center">Condition</th>
                  <th className="w-45px" />
                  <th className="w-125px text-center">Condition Value</th>
                  <th className="w-25px" />
                </tr>
              </thead>
              {/*end::Thead*/}
              {/*begin::Tbody*/}
              <tbody
                className="fs-6 fw-bold text-gray-600"
                id="acl-list"
              >
                {lbData != null ? (<>
                  {lbData.acls ? (<>
                    {lbData.acls.length == 0 ? (<>
                      <tr>
                        <td colSpan={12} className="text-center"> No records found</td>
                      </tr>
                    </>) : (<>
                      {lbData.acls.map((AclValue, index) => (<>
                        <tr>
                          <td className="fw-bold text-start ps-10">
                            <div className="d-flex flex-column w-100 me-2">
                              <div className="flex-stack">
                                <span className=" me-2 fs-5 fw-bold">{AclValue.name}</span>
                              </div>
                            </div>
                            {/* <div className="mt-1"> 
                              <input
                                type="text"
                                className="form-control read border-none"
                                placeholder="Name"
                                id={"acl_name"+frontendid+""+AclValue.id}
                                defaultValue={AclValue.name}
                              />
                            </div> */}
                          </td>
                          <td />
                          <td className="fw-bold text-center">
                            <div className="d-flex flex-column w-100 me-2">
                              <div className="flex-stack">
                                <span className=" me-2 fs-5 fw-bold">{AclValue.acl_condition}</span>
                              </div>
                            </div>
                            {/* <select className="form-select" id={"acl_conditionType"+frontendid+""+AclValue.id} defaultValue={AclValue.acl_condition}>
                          <option value="http_user_agent">Http User Agent</option>
                          <option value="http_referer">Http Referer</option>
                          <option value="url_path">Url Path</option>
                          <option value="http_method">Http Method</option>
                          <option value="ssl_cipher_suite">SSL Cipher Suite</option>
                          <option value="ssl_certificate">SSL Certificate</option>
                          <option value="network_protocol">Network Protocol</option>
                          <option value="ip_geolocation">IP Geolocation</option>
                          <option value="authenticated_users">Authenticated Users</option>
                          <option value="custom_header">Custom Header</option>  
                        </select>   */}
                          </td>
                          <td />
                          <td className="fw-bold text-center">
                            {AclValue.acl_condition == "http_header" ? (<>
                              <div className="flex-column w-100 me-2 mt-7">
                                <div className="flex-stack mt-3 sphide">
                                  <p className="fw-bold text-gray-700">Header Name:&nbsp;&nbsp; <span className="text-muted me-2 fs-7 fw-bold">
                                    {JSON.parse(AclValue.value)?.header_name}
                                  </span>
                                  </p>
                                  <p className="fw-bold text-gray-700">Values: &nbsp;&nbsp;
                                    <span className="text-primary me-2 fs-6 fw-bold">
                                      {JSON.parse(AclValue.value)?.data.map((url, index) => (
                                        <span key={index}>
                                          {url}
                                          {index < JSON.parse(AclValue.value)?.data.length - 1 && ", "}
                                        </span>
                                      ))}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </>) : (<>
                              {AclValue.acl_condition == "query_string" ? (<>
                                <div className="flex-column w-100 me-2 mt-7">
                                  <div className="flex-stack mb-2 sphide">

                                    <p className="fw-bold text-gray-700">
                                      Values:&nbsp;&nbsp;
                                      <span className="text-primary me-2 fs-6 fw-bold">
                                        {AclValue.value && isJSON(AclValue.value)
                                          ? JSON.parse(AclValue.value)?.data.map((url, index) => (<>
                                            <span className="text-muted">[</span>{Object.keys(url)} <span className="text-muted">:</span> &nbsp;
                                            {/* <span className="text-primary me-2 fs-6 fw-bold"> */}

                                            <span key={index}>
                                              {Object.values(url)}
                                            </span>
                                            <span className="text-muted">]
                                              {/* </span> */}
                                              {index < JSON.parse(AclValue.value).data.length - 1 && ", "}
                                            </span>
                                          </>))
                                          : AclValue.value}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </>) : (<>
                                <div className="flex-column w-100 me-2 mt-7">
                                  <div className="flex-stack mb-2 sphide">

                                    <p className="fw-bold text-gray-700">Values: &nbsp;&nbsp;
                                      <span className="text-primary me-2 fs-6 fw-bold">
                                        {AclValue.value && isJSON(AclValue.value)
                                          ? JSON.parse(AclValue.value)?.data.map((url, index) => (
                                            <span key={index}>
                                              {url}
                                              {index < JSON.parse(AclValue.value).data.length - 1 && ", "}
                                            </span>
                                          ))
                                          : AclValue.value}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </>)}
                            </>)}

                            {/* <div className="mt-1">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Value"
                                id={"acl_value"+frontendid+""+AclValue.id}
                                defaultValue={AclValue.value}
                              />
                            </div> */}
                          </td>
                          <td className="fw-bold text-end sphide">
                            {/* <a  className="btn btn-sm btn-success" style={{ marginRight: 20 }}>
                            <span className="svg-icon svg-icon-muted svg-icon-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  opacity="0.3"
                                  d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z"
                                  fill="black"
                                />
                                <path
                                  d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.2C9.7 3 10.2 3.20001 10.4 3.60001ZM16 12H13V9C13 8.4 12.6 8 12 8C11.4 8 11 8.4 11 9V12H8C7.4 12 7 12.4 7 13C7 13.6 7.4 14 8 14H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
                                  fill="black"
                                />
                                <path
                                  opacity="0.3"
                                  d="M11 14H8C7.4 14 7 13.6 7 13C7 12.4 7.4 12 8 12H11V14ZM16 12H13V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
                                  fill="black"
                                />
                              </svg>
                            </span>
                            update
                          </a> */}

                            <a className="btn btn-sm btn-danger" id={"delete-acl-btn-" + AclValue.id} onClick={(e) => deleteAclRule(AclValue.id)}>
                              Delete
                            </a>
                          </td>
                        </tr>
                      </>))}
                    </>)}
                  </>) : ('')}
                </>) : ('')}

              </tbody>
              {/*end::Tbody*/}
            </table>
            {/*end::Table*/}
          </div>

          {/*end::Table wrapper*/}
        </div>
        {/*end::Body*/}
      </div>
      {/*end::ACL Rules*/}
    </div>
    <div className="border-dashed rounded">
      {/*begin::Advance Routing*/}
      <div className="card border-dashed rounded">

        {/*begin::Card header*/}
        <div className="card-header">
          {/*begin::Heading*/}
          <div className="card-title">
            <h3>Advance Routing</h3>
          </div>
          {/*end::Heading*/}
          {/*begin::Toolbar*/}
          <div className="card-toolbar">
            <a
              className="btn btn-sm btn-primary me-2"
              onClick={openDrawerRouting}
            >
              <span className="svg-icon svg-icon-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="11.364"
                    y="20.364"
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(-90 11.364 20.364)"
                    fill="black"
                  />
                  <rect x="4.36396" y="11.364" width={16} height={2} rx={1} fill="black" />
                </svg>
              </span>
              Add New Routing
            </a>
          </div>
          {/*end::Toolbar*/}
        </div>
        {/*end::Card header*/}
        {/*begin::Body*/}
        <div className="card-body p-0">
          {/*begin::Table wrapper*/}
          <div className="table-responsive">
            {/*begin::Table*/}
            <table
              className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
              id="kt_api_keys_table"
            >
              {/*begin::Thead*/}
              <thead className="border-gray-200 fs-5 fw-bold bg-secondary">
                <tr>
                  <th className="w-125px">Advance Rule</th>
                  <th className="w-15px" />
                  <th className="w-125px text-center">Condition</th>
                  <th className="w-15px" />
                  <th className="w-125px text-center">Target Groups</th>
                  <th className="w-25px" />
                </tr>
              </thead>
              {/*end::Thead*/}
              {/*begin::Tbody*/}
              <tbody
                className="fs-6 fw-bold text-gray-600"
                id="advance-routing-list"
              >
                {lbData != null ? (<>
                  {lbData.routes ? (<>
                    {lbData.routes.length == 0 ? (<>
                      <tr>
                        <td colSpan={12} className="text-center"> No records found</td>
                      </tr>
                    </>) : (<>
                      {lbData.routes.map((value2, index) => (<>
                        <tr>
                          <td className="fw-bold text-start ps-10">
                            {lbData != null ? (<>
                              {lbData.acls ? (<>
                                {lbData.acls.length == 0 ? (<>
                                  <span>-</span>
                                </>) : (<>
                                  <span>
                                    {lbData.acls.map((value3, index) => (<>
                                      {value3.id == value2.acl_id ? (value3.name) : ('')}
                                    </>))}
                                  </span>
                                </>)}
                              </>) : ('')}
                            </>) : ('')}
                          </td>
                          <td />
                          <td className="fw-bold text-center">
                            <span>{value2.routing_condition}</span>
                            <select className="form-select d-none" id={"route_condition" + frontendid + "" + value2.id} defaultValue={value2.routing_condition}>
                              <option value="true">True</option>
                              <option value="false">False</option>
                            </select>

                          </td>
                          <td />
                          <td className="fw-bold text-center">
                            <div className="mt-1">
                              {lbData != null ? (
                                <>
                                  {lbData.backends ? (
                                    <>
                                      <input type="hidden" id={"backend" + frontendid + "" + value2.id} defaultValue={value2.target_groups} />
                                      <span id={"backendSpan" + frontendid + "" + value2.id}></span>
                                      {/* <input
              type="text"
              className="form-control d-none"
              id={"backend-field" + frontendid + "" + value2.id}
              onChange={(e) => updateLoadbalancerTargetGroups(frontendid + "" + value2.id, e)}
            /> */}

                                      {(() => {
                                        const timerId = setTimeout(() => {
                                          getUpdatedTargetList(frontendid + "" + value2.id);
                                        }, 1000);

                                        // Cleanup the timer when the component unmounts
                                        return () => clearTimeout(timerId);
                                      })()}
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </>
                              ) : (
                                ''
                              )}
                            </div>
                          </td>

                          <td className="fw-bold text-end sphide">
                            {/* <a onClick={(e)=>updateRouting(value2.id)} className="btn btn-sm btn-success" style={{ marginRight: 20 }}> */}
                            <a onClick={(e) => updateAdvanceRouting(value2.id, value2.acl_id, value2.routing_condition, value2.target_groups)} className="btn btn-sm btn-success" style={{ marginRight: 20 }}>
                              <span className="svg-icon svg-icon-muted svg-icon-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    opacity="0.3"
                                    d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z"
                                    fill="black"
                                  />
                                  <path
                                    d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.2C9.7 3 10.2 3.20001 10.4 3.60001ZM16 12H13V9C13 8.4 12.6 8 12 8C11.4 8 11 8.4 11 9V12H8C7.4 12 7 12.4 7 13C7 13.6 7.4 14 8 14H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
                                    fill="black"
                                  />
                                  <path
                                    opacity="0.3"
                                    d="M11 14H8C7.4 14 7 13.6 7 13C7 12.4 7.4 12 8 12H11V14ZM16 12H13V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
                                    fill="black"
                                  />
                                </svg>
                              </span>
                              update
                            </a>
                            <a className="btn btn-sm btn-danger" id={"delete-advance-routing-btn-" + value2.id} onClick={(e) => deleteAdvanceRouting(value2.id)}>
                              Delete
                            </a>
                          </td>
                        </tr>
                      </>))}
                    </>)}
                  </>) : ('')}
                </>) : ('')}

              </tbody>
              {/*end::Tbody*/}
            </table>
            {/*end::Table*/}
          </div>

          {/*end::Table wrapper*/}
        </div>
        {/*end::Body*/}
      </div>
      {/*end::Advance Routing*/}
    </div>
    <div id={"drawer_overlay_routing" + frontendid} onClick={openDrawerRouting} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>

    <div
      id={"routing-modal_" + frontendid}
      className="bg-body shadow drawer drawer-end"
      data-kt-drawer="true"
      data-kt-drawer-name="chat"
      data-kt-drawer-activate="true"
      data-kt-drawer-direction="end"
      data-kt-drawer-close="#close_ssh"
      data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
      style={{ maxWidth: "70%", important: true }}
    >
      {/*begin::Messenger*/}
      <div
        className="card rounded-0 w-100 border-0"
        id="kt_drawer_chat_messenger"
      >
        {/*begin::Card header*/}
        <div
          className="card-header pe-5"
          id="kt_drawer_chat_messenger_header"
        >
          {/*begin::Title*/}
          <div className="card-title">
            {/*begin::User*/}
            <div className="d-flex justify-content-center flex-column me-3">
              <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                Add Advance Routing
              </a>
            </div>
            {/*end::User*/}
          </div>
          {/*end::Title*/}
          {/*begin::Card toolbar*/}
          <div className="card-toolbar">
            {/*begin::Close*/}
            <div
              onClick={openDrawerRouting}
              className="btn btn-sm btn-icon btn-active-light-primary"
              id="close_ssh"
            >
              {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
              <span className="svg-icon svg-icon-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x={6}
                    y="17.3137"
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(-45 6 17.3137)"
                    fill="black"
                  />
                  <rect
                    x="7.41422"
                    y={6}
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(45 7.41422 6)"
                    fill="black"
                  />
                </svg>
              </span>
              {/*end::Svg Icon*/}
            </div>
            {/*end::Close*/}
          </div>
          {/*end::Card toolbar*/}
        </div>
        {/*end::Card header*/}
        {/*begin::Card body*/}
        <div className="" id="">
          {/*begin::Form*/}

          <form id={"addRoutingForm" + frontendid} className="form">
            {/*begin::Modal body*/}
            <div className="modal-body ">
              {/*begin::Scroll*/}
              <div
                className="scroll-y me-n7 pe-7"
                id="kt_modal_create_api_key_scroll"
                data-kt-scroll="true"
                data-kt-scroll-activate="{default: false, lg: true}"
                data-kt-scroll-max-height="auto"
                data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                data-kt-scroll-offset="300px"
              >
                {/*begin::Input group*/}
                <div className="mb-5 fv-row">
                  {/*begin::Label*/}
                  <label className="required fs-5 fw-bold mb-2">
                    {" "}
                    Advance Rule
                  </label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <input type="hidden" defaultValue={frontendid} name="frontend_id" />
                  {lbData != null ? (<>
                    {lbData.acls ? (<>
                      <select className="form-select" name="acl_id">
                        {lbData.acls.length == 0 ? (<>
                          <option>No ACL rule found</option>
                        </>) : (<>

                          {lbData.acls.map((value2, index) => (<>
                            <option value={value2.id}>{value2.name}</option>
                          </>))}

                        </>)}
                      </select>
                    </>) : ('')}
                  </>) : ('')}

                  {/*end::Input*/}
                </div>
                {/*end::Input group*/}
                {/*begin::Input group*/}
                <div className="mb-5 fv-row">
                  {/*begin::Label*/}
                  <label className="required fs-5 fw-bold mb-2">
                    {" "}
                    Condition
                  </label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <select className="form-select" name="route_condition">
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </select>
                  {/*end::Input*/}
                </div>
                {/*end::Input group*/}


                {/*begin::Input group*/}

                <div className="mb-5 fv-row">
                  {/*begin::Label*/}
                  <label className="required fs-5 fw-bold mb-2">
                    Target Groupss
                  </label>
                  {/*end::Label*/}

                  {/* Hidden input field */}
                  <input
                    type="hidden"
                    name="target_groups"
                    id={"targetInputField" + frontendid}
                  />

                  {/* Text input field */}
                  <input
                    type="text"
                    className="form-control"
                    id={"loadbalancer_target_field" + frontendid}
                    onChange={(e) => addNewLoadbalancerTargetGroups(e)}
                  />

                  {/* Conditionally render options */}
                  {targetData.length === 0 ? (
                    <div>No backend resource found</div>
                  ) : (""

                  )}
                </div>



                {/*end::Input group*/}
              </div>
              {/*end::Scroll*/}
            </div>
            {/*end::Modal body*/}
            {/*begin::Modal footer*/}
            <div className="modal-footer ">
              {/*begin::Button*/}
              <a
                onClick={addAdvanceRouting}
                className="btn btn-primary w-100"
                id={"addRouteBtn" + frontendid}
              >
                <span className="indicator-label"> Add Routing</span>
              </a>
              {/*end::Button*/}
            </div>
            {/*end::Modal footer*/}
          </form>
          {/*end::Form*/}
        </div>
        {/*end::Card body*/}
      </div>
      {/*end::Messenger*/}
    </div>

    <div id={"drawer_overlay_acl" + frontendid} onClick={openDrawerAcl} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>

    <div
      id={"acl-modal_" + frontendid}
      className="bg-body shadow drawer drawer-end"
      data-kt-drawer="true"
      data-kt-drawer-name="chat"
      data-kt-drawer-activate="true"
      data-kt-drawer-direction="end"
      data-kt-drawer-close="#close_ssh"
      data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
      style={{ maxWidth: "70%", important: true }}
    >
      {/*begin::Messenger*/}
      <div
        className=" rounded-0 w-100 border-0"
      >
        {/*begin::Card header*/}
        <div
          className="card-header pe-5"
          id="kt_drawer_chat_messenger_header"
        >
          {/*begin::Title*/}
          <div className="card-title">
            {/*begin::User*/}
            <div className="d-flex justify-content-center flex-column me-3">
              <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                Add ACL Rule
              </a>
            </div>
            {/*end::User*/}
          </div>
          {/*end::Title*/}
          {/*begin::Card toolbar*/}
          <div className="card-toolbar">
            {/*begin::Close*/}
            <div
              onClick={openDrawerAcl}
              className="btn btn-sm btn-icon btn-active-light-primary"
              id="close_ssh"
            >
              {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
              <span className="svg-icon svg-icon-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x={6}
                    y="17.3137"
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(-45 6 17.3137)"
                    fill="black"
                  />
                  <rect
                    x="7.41422"
                    y={6}
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(45 7.41422 6)"
                    fill="black"
                  />
                </svg>
              </span>
              {/*end::Svg Icon*/}
            </div>
            {/*end::Close*/}
          </div>
          {/*end::Card toolbar*/}
        </div>
        {/*end::Card header*/}
        {/*begin::Card body*/}
        <div className="" id="">
          {/*begin::Form*/}
          <form id={"addAclForm" + frontendid} className="form">
            {/*begin::Modal body*/}
            <div className="modal-body ">
              {/*begin::Scroll*/}
              <div
                className="scroll-y me-n7 pe-7"
                id="kt_modal_create_api_key_scroll"
                data-kt-scroll="true"
                data-kt-scroll-activate="{default: false, lg: true}"
                data-kt-scroll-max-height="auto"
                data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                data-kt-scroll-offset="300px"
              >
                {/*begin::Input group*/}
                <div className="mb-5 fv-row">
                  {/*begin::Label*/}
                  <label className="required fs-5 fw-bold mb-2">
                    {" "}
                    Name
                  </label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <input type="hidden" defaultValue={frontendid} name="frontend_id" />
                  <input
                    type="text"
                    className="form-control  "
                    placeholder="ACL Name"
                    name="name"
                    id={"name" + frontendid}
                  />

                  {/*end::Input*/}
                </div>
                {/*end::Input group*/}
                {/*begin::Input group*/}
                <div className="mb-5 fv-row">
                  {/*begin::Label*/}
                  <label className="required fs-5 fw-bold mb-2">
                    {" "}
                    Condition
                  </label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <select className="form-select" name="conditionType" id={"conditionType" + frontendid} onChange={(e) => createNewCondition(e.target.value)}>
                    <option value="">Select Condition</option>
                    <optgroup label="Include one of each">
                      <option value="http_user_agent">Http User Agent</option>
                      <option value="http_referer">Http Referer</option>
                      <option value="url_path">Url Path</option>
                      <option value="http_method">Http Method</option>
                    </optgroup>
                    <optgroup label="Include one or more of each">
                      <option value="query_string">Query String</option>
                      <option value="http_header">HTTP Header</option>
                    </optgroup>
                  </select>
                  {/*end::Input*/}
                </div>
                {/*end::Input group*/}
                {/*begin::Input group*/}
                {/* {currentConditionData!=null?(<> */}
                {frontendid != 0 && indexIds != "nothing" ? (<>
                  {currentCondition == "query_string" ? (<>
                    <QueryString indexId={indexIds} fid={frontendid} toggleBtn={toggleAclBtn} />
                  </>) : (<>
                    {currentCondition == "http_header" ? (<>
                      <HttpHeader indexId={indexIds} fid={frontendid} toggleBtn={toggleAclBtn} />
                    </>) : (<>
                      {currentCondition != "" ? (<>
                        <Conditions indexId={indexIds} fid={frontendid} toggleBtn={toggleAclBtn} />
                      </>) : (<></>)}
                    </>)}
                  </>)}
                </>) : ('')}
                {/* </>):('')} */}

                {/*end::Input group*/}
              </div>
              {/*end::Scroll*/}
            </div>
            {/*end::Modal body*/}
            {/*begin::Modal footer*/}
            <div className="modal-footer border-0">
              {/*begin::Button*/}
              {frontendid != 0 && indexIds != "nothing" ? (<>
                <a
                  onClick={addAclRule}
                  className="btn btn-primary w-100"
                  id="AddAclBtn"
                >
                  <span className="indicator-label">Add ACL Rule</span>
                </a>
                {/*end::Button*/}
              </>) : ('')}
            </div>
            {/*end::Modal footer*/}
          </form>
          {/*end::Form*/}
        </div>
        {/*end::Card body*/}
      </div>
      {/*end::Messenger*/}
    </div>


    <div id={"update_drawer_overlay_routing" + frontendid} onClick={updateOpenDrawerRouting} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>
    <div
      id={"update-routing-modal_" + frontendid}
      className="bg-body shadow drawer drawer-end"
      data-kt-drawer="true"
      data-kt-drawer-name="chat"
      data-kt-drawer-activate="true"
      data-kt-drawer-direction="end"
      data-kt-drawer-close="#close_ssh"
      data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
      style={{ maxWidth: "70%", important: true }}
    >
      {/*begin::Messenger*/}
      <div
        className="card rounded-0 w-100 border-0"
        id="kt_drawer_chat_messenger"
      >
        {/*begin::Card header*/}
        <div
          className="card-header pe-5"
          id="kt_drawer_chat_messenger_header"
        >
          {/*begin::Title*/}
          <div className="card-title">
            {/*begin::User*/}
            <div className="d-flex justify-content-center flex-column me-3">
              <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                Update Advance Routinggg
              </a>
            </div>
            {/*end::User*/}
          </div>
          {/*end::Title*/}
          {/*begin::Card toolbar*/}
          <div className="card-toolbar">
            {/*begin::Close*/}
            <div
              onClick={updateOpenDrawerRouting}
              className="btn btn-sm btn-icon btn-active-light-primary"
              id="close_ssh"
            >
              {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
              <span className="svg-icon svg-icon-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x={6}
                    y="17.3137"
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(-45 6 17.3137)"
                    fill="black"
                  />
                  <rect
                    x="7.41422"
                    y={6}
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(45 7.41422 6)"
                    fill="black"
                  />
                </svg>
              </span>
              {/*end::Svg Icon*/}
            </div>
            {/*end::Close*/}
          </div>
          {/*end::Card toolbar*/}
        </div>
        {/*end::Card header*/}
        {/*begin::Card body*/}
        <div className="" id="">
          {/*begin::Form*/}
          <form id={"updateRoutingForm" + frontendid} className="form">
            {/*begin::Modal body*/}
            <div className="modal-body ">
              {/*begin::Scroll*/}
              <div
                className="scroll-y me-n7 pe-7"
                id="kt_modal_create_api_key_scroll"
                data-kt-scroll="true"
                data-kt-scroll-activate="{default: false, lg: true}"
                data-kt-scroll-max-height="auto"
                data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                data-kt-scroll-offset="300px"
              >
                {/*begin::Input group*/}
                <div className="mb-5 fv-row">
                  {/*begin::Label*/}
                  <label className="required fs-5 fw-bold mb-2">
                    {" "}
                    Advance Rule
                  </label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <input type="hidden" defaultValue={frontendid} name="frontend_id" />
                  <input type="hidden" id={"update_routing_id" + frontendid} />
                  {lbData != null ? (<>
                    {lbData.acls ? (<>
                      <select className="form-select" name="acl_id" id={"update_acl_id" + frontendid}>
                        {lbData.acls.length == 0 ? (<>
                          <option>No ACL rule found</option>
                        </>) : (<>

                          {lbData.acls.map((value2, index) => (<>
                            <option value={value2.id}>{value2.name}</option>
                          </>))}

                        </>)}
                      </select>
                    </>) : ('')}
                  </>) : ('')}

                  {/*end::Input*/}
                </div>
                {/*end::Input group*/}
                {/*begin::Input group*/}
                <div className="mb-5 fv-row">
                  {/*begin::Label*/}
                  <label className="required fs-5 fw-bold mb-2">
                    {" "}
                    Condition
                  </label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <select className="form-select" name="route_condition" id={"update_route_condition" + frontendid}>
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </select>
                  {/*end::Input*/}
                </div>
                {/*end::Input group*/}


                {/*begin::Input group*/}
                <div className="mb-5 fv-row">
                  {/*begin::Label*/}
                  <label className="required fs-5 fw-bold mb-2">
                    {" "}
                    Target Groups
                  </label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <input type="hidden" name="target_groups" id={"updateTargetInputField" + frontendid} />
                  {/* {targetData!=null?(<>  */}
                  <input type="text" onChange={(e) => updateNewLoadbalancerTargetGroups(e)} className="form-control" id={"update_loadbalancer_target_field" + frontendid} />
                  {/* onChange={(e)=>updateNewLoadbalancerTargetGroups(e)} */}

                  {/* </>):('')} */}

                  {/*end::Input*/}
                </div>
                {/*end::Input group*/}
              </div>
              {/*end::Scroll*/}
            </div>
            {/*end::Modal body*/}
            {/*begin::Modal footer*/}
            <div className="modal-footer ">
              {/*begin::Button*/}
              <a
                onClick={updateAdvanceRoutingSubmit}
                className="btn btn-primary w-100"
                id={"updateRouteBtn" + frontendid}
              >
                <span className="indicator-label"> Update Routing</span>
              </a>
              {/*end::Button*/}
            </div>
            {/*end::Modal footer*/}
          </form>
          {/*end::Form*/}
        </div>
        {/*end::Card body*/}
      </div>
      {/*end::Messenger*/}
    </div>
  </>)
}

const mapStateToProps = (state) => ({
  aclConditionData: state.aclConditionInfo?.aclConditions,
});

const mapDispatchToProps = (dispatch) => ({
  addAclProps: (data) => dispatch(appendAclConditions(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdvanceRouting);

