import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import Swal from 'sweetalert2';
import ReactPaginate from "react-paginate";
import copy from "copy-to-clipboard";
import UsePost from "../../../../../../CustomHooks/usePost";
import UseApi from "../../../../../../CustomHooks/useApi";
import TopBarHeaderLine from "../../../../../../CustomHooks/TopBarHeaderLine";
import UsersPermissionDrawer from "../usersPermissionDrawer/UsersPermissionDrawer";
import PermissionInfoModal from "../permissioInfoModal/PermissionInfoModal";
import UpdateRabbitMqUsers from "./updateRabbitMqUsers/UpdateRabbitMqUsers";

const RabbitMqUsers = (props) => {
  const [pageStatus, setPageStatus] = useState(false)
  const [pageCount, setPageCount] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [itemOffset, setItemOffset] = useState(0);
  const [dummyDatabaseUserData, setDummyDatabaseUserData] = useState(null);
  const [databaseUserData, setDatabaseUserData] = useState(null);
  const [databaseInfo, setDatabaseInfo] = useState(null)
  const [activePage, setActivePage] = useState(0);
  const [updateObject, setUpdateObject] = useState(null)
  const [currentDatabaseInfo, setCurrentDatabaseInfo] = useState(null);
  var query = window.location.href;
  var vars = query.split("/");
  var id = vars[4];


  const openDrawer = () => {
    var drawerElement = document.querySelector("#databaseUser-modal");
    var drawer_overlay = document.querySelector("#drawer_overlay-co");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      drawer_overlay.classList.toggle('d-none')
      drawerElement.style.width = '500px';
    }
  }

  const openUpdateDrawer = (value) => {
    setUpdateObject(value)
    var drawerElement = document.querySelector("#rabbitmqUpdate-modal");
    var drawer_overlay = document.querySelector("#drawer_overlay-rabbitmqUpdate");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      drawer_overlay.classList.toggle('d-none')
      drawerElement.style.width = '500px';
    }
  }

  const loaderFunc = () => {
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if (loader && no_record) {
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");

      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
  }


  const togglePassword = (action, pid) => {
    // console.log(action,pid);
    var hide_passwd = document.querySelector("#hide_passwd_" + pid);
    var show_passwd = document.querySelector("#show_passwd_" + pid);
    // console.log(hide_passwd,show_passwd);
    if (hide_passwd && show_passwd) {
      // console.log("sjc");
      if (action == "hide") {
        hide_passwd.classList.remove("d-none");
        show_passwd.classList.add("d-none");
      } else {
        hide_passwd.classList.add("d-none");
        show_passwd.classList.remove("d-none");
      }
    }
  }

  const copyToClipboard = (target, targetBy) => {
    var btn_id = document.querySelector("#" + targetBy);
    var target_id = document.querySelector("#" + target);
    if (btn_id && !btn_id.classList.contains('disabled')) {
      var copyText = btn_id.innerHTML;
      var copyEle = target_id.innerHTML;
      copy(copyEle);
      btn_id.innerHTML = `<svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9.89557 13.4982L7.79487 11.2651C7.26967 10.7068 6.38251 10.7068 5.85731 11.2651C5.37559 11.7772 5.37559 12.5757 5.85731 13.0878L9.74989 17.2257C10.1448 17.6455 10.8118 17.6455 11.2066 17.2257L18.1427 9.85252C18.6244 9.34044 18.6244 8.54191 18.1427 8.02984C17.6175 7.47154 16.7303 7.47154 16.2051 8.02984L11.061 13.4982C10.7451 13.834 10.2115 13.834 9.89557 13.4982Z"
        fill="black"
      />
    </svg>`;
      btn_id.classList.add('disabled')
      setTimeout(() => {
        btn_id.innerHTML = copyText;
        btn_id.classList.remove('disabled')
      }, 2000);
    }

  }


  console.log(props);
  const getDatabaseUsers = async () => {
    if (props) {
      if (props.data) {
        console.log(props);

        setDatabaseInfo(props.data)
        if (props.data.users.length == 0) {
          setDatabaseUserData([]);
          setDummyDatabaseUserData([])
          // setDatabaseInfo([])
        } else {
          setDatabaseUserData(props?.data?.users[0]);
          setDummyDatabaseUserData(props?.data?.users[0])
          setPageCount(Math.ceil(props?.data?.users[0]?.length / perPage))
          // setDatabaseInfo(props.data)
          setCurrentDatabaseInfo(props.currentDatabaseInfo);
        }
      }
    }
  }

  console.log(databaseUserData);

  const createDatabaseUser = async (event) => {
    toast.dismiss()
    event.preventDefault()
    var submitBtn = document.querySelector("#create-databaseUser-btn");
    var databases_user = document.querySelector('#databasesuser')
    if (submitBtn) {
      setPageStatus(true)
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      const dt = toast.loading("Please wait...");

      const responseData = await UsePost('post', 'databases/' + id + '/user', 'databaseUser-form');
      if (responseData) {
        setPageStatus(false)
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
        if (responseData.status === 'error') {
          toast.update(dt, {
            render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        } else if (responseData.status === 'success') {
          toast.update(dt, {
            render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          databases_user.value = ''
          if (props) {
            openDrawer();
            props.updateDatabase();
          }

        }
      }
    }
  }

  useEffect(() => {
    getDatabaseUsers();
  }, [props])

  const copyClusterName = () => {
    var eleText = document.querySelector("#user_file_url");
    var ele = document.querySelector("#user_file_url_copy_btn");

    if (ele && eleText) {
      const copyText = eleText.innerText;
      const originalContent = ele.innerHTML;
      console.log(originalContent);

      navigator.clipboard.writeText(copyText).then(() => {
        ele.innerHTML = "(Copied)";

        setTimeout(() => {
          ele.innerHTML = "(copy)";
        }, 1000);
      }).catch(err => {
        console.error('Failed to copy text: ', err);
      });
    }
  };

  function handleButtonClick(event) {
    console.log(event.target);
    if (event.target && event.target.id === 'user_file_url_copy_btn') {
      copyClusterName();
      event.stopPropagation();
    }
  }

  // Remove any existing listener before adding a new one (if necessary)
  document.removeEventListener('click', handleButtonClick);
  document.addEventListener('click', handleButtonClick);

  const deleteDatabaseUser = async (dbid) => {
    var submitBtn = document.querySelector("#delete-rabbitmquser" + dbid + "-btn");

    if (submitBtn) {

      toast.dismiss();
      const { value: isDelete } = await Swal.fire({
        title: 'Delete RabbitMq User',
        html: `
        <div className="d-flex align-items-center flex-wrap"> 
        <div id="kt_clipboard_4" className="me-5 fw-bold text-gray-600">
          <p class="fs-5">Enter the RabbitMq User name to Destroy:</p>
  
          <span class="fw-bold" id="user_file_url" >${dbid} </span>
          <span id="user_file_url_copy_btn" style="cursor:pointer; width: 70px; display: inline-block;" class="text-primary"> ( copy ) </span> 
        </div>  
      </div>
     `,
        input: 'text',
        inputPlaceholder: 'RabitMq User',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'delete-button bg-danger', // Add your custom class here
        },
      });
      if (isDelete == dbid) {
        setPageStatus(true)
        var preContent = submitBtn.innerHTML;
        submitBtn.classList.add("disabled");
        submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
        const dt = toast.loading("Database user deleting...");
        const dnsp = await UseApi('DELETE', 'databases/' + id + '/user/' + '?confirm=' + dbid, '');
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
        //console.log(dnsp);
        if (dnsp.status == 'error') {
          setPageStatus(false)
          toast.update(dt, {
            render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        } else if (dnsp.status == 'success') {
          setPageStatus(false)
          toast.update(dt, {
            render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          if (props) {
            props.updateDatabase();
          }
        }
      } else {
        setPageStatus(false)
        swal({
          title: "Invalid Database User",
          text: "Please confirm the valid database user!",
          icon: "warning",
          button: "OK",
        });
      }
    }
  }

  function ProductInfo({ productdata }) {
    return (
      <>
        {productdata != null && Object.keys(productdata).length != 0 ? (productdata && productdata.map((value, index) => (
          <tr>
            <td className="ps-9 text-start text-gray-700">{value?.db_user}</td>
            <td className="text-center">{value?.role}</td>
            <td className="text-center text-gray-700">
              <div className="d-flex flex-column w-100">
                <div className="">
                  {/* <span> */}
                  <span id={"hide_passwd_" + value?.db_user} className="">
                    ******
                    <span className="text-primary ms-5 d-none" style={{ cursor: 'pointer' }} onClick={(e) => togglePassword("show", value.db_user)}>show</span>
                  </span>
                  <span id={"show_passwd_" + value.db_user} className="d-none">
                    <span id={"passwd_id_" + value.db_user}>{value.db_password}</span>
                    <span style={{ cursor: "pointer" }} className="text-primary ms-5" onClick={(e) => togglePassword("hide", value.db_user)}>hide</span>
                    <span id={"passwd_copy_" + value.db_user} onClick={(e) => copyToClipboard('passwd_id_' + value.db_user, 'passwd_copy_' + value.db_user)} className="svg-icon svg-icon-2 ms-5">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="#eff2f5"
                      >
                        <path
                          opacity="0.5"
                          d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                          fill="#eff2f5"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                          fill="#eff2f5"
                        />
                      </svg>
                    </span>
                  </span>
                </div>
              </div>
            </td>

            <td className="text-center text-gray-700">
              <span className="fs-7 fw-bold">
                {(value.created_at)}
              </span>
            </td>
            <td className="text-center">
              <a className="btn btn-success btn-active-light-success btn-sm sphide" onClick={() => openUpdateDrawer(value)}>
                Update User
                <span className="svg-icon ms-2 svg-icon-3 d-none">

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >

                    <rect
                      opacity="0.5"
                      x={18}
                      y={13}
                      width={13}
                      height={2}
                      rx={1}
                      transform="rotate(-180 18 13)"
                      fill="black"
                    />
                    <path
                      d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                      fill="black"
                    />
                  </svg>
                </span>
              </a>
            </td>
            <td className="text-end">
              <a
                onClick={(e) => deleteDatabaseUser(value.db_user)}
                id={"delete-rabbitmquser" + value.db_user + "-btn"}
                className="btn btn-sm btn-danger sphide"
              >
                Delete
              </a>
            </td>
          </tr >
        ))) : (<>
          <tr>
            <td colspan="12" className="" id="no_record" valign="top">
              <div className="mt-20 mb-20 text-center">
                <span className="fs-6 text-gray-800 fw-bold">no record found</span>
              </div>
            </td>
          </tr>
        </>)}

      </>
    )
  }

  const openPermissionDrawer = () => {
    var drawerElement = document.querySelector("#rabbitmqUser-modal");
    var drawer_overlay = document.querySelector("#rabbitmqDrawer_overlay");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
        drawerElement.style.width = "100%";
      } else {
        drawerElement.style.width = "70%";
      }
      drawer_overlay.classList.toggle("d-none");
    }
  }

  const handleInputChange = (e) => {
    const inputValue = e.target.value.replace(/\s/g, ''); // Remove spaces
    e.target.value = inputValue; // Update input value directly
  };

  const handleSearch = (text) => {
    const endOffset = itemOffset + perPage;
    // Remove special characters from the input text
    const escapedText = text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    // Create a regular expression pattern to match the filtered text
    const regex = new RegExp(escapedText, "gi");

    // Filter the clusterData based on the regex pattern
    console.log(databaseUserData);
    let data = databaseUserData.filter((row) => {
      // Check if any property in the row matches the regex pattern
      return (
        row.db_user && row.db_user.toString().toLowerCase().includes(text.toLowerCase())
      )
    });

    // Update the state with the filtered data
    setDummyDatabaseUserData(data);;
    setPageCount(Math.ceil(data.length / perPage));
  }

  function PaginatedItems({ itemsPerPage, ProductData }) {

    const [itemOffset, setItemOffset] = useState(0);
    const [activePage, setActivePage] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    useEffect(() => {
      setPageCount(Math.ceil(ProductData.length / itemsPerPage));
    }, [ProductData.length, itemsPerPage]);

    const endOffset = Math.min(itemOffset + itemsPerPage, ProductData.length);

    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % ProductData.length;
      setItemOffset(newOffset);
      setActivePage(event.selected);
    };

    return (
      <>
        <ProductInfo productdata={ProductData?.slice(itemOffset, endOffset)} />
        <td colspan="12">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            initialPage={activePage}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </td>
      </>
    );
  }

  const handleKeyPress = (event) => {
    console.log(event.key);
    if (event.key === 'Enter') {
      // Call your function here
      createDatabaseUser(event);
    }
  }

  console.log(databaseInfo);

  const openModal = () => {
    let addbackend_modal = document.querySelector('#permissionInfo-modal')
    console.log(addbackend_modal);

    if (addbackend_modal) {
      addbackend_modal.classList.toggle('show')
      addbackend_modal.classList.toggle('d-block')
    }
  }

  return (
    <>
      <div className="card">
        <div className="card-header">
          <div className="card-title">
            <h3>RabbitMq Users</h3>
          </div>
          <div className="card-toolbar">
            <div
              className="position-relative my-1"
              style={{ marginRight: 20 }}
            >
              <input
                type="text"
                className="form-control form-control-sm"
                data-kt-table-widget-4="search"
                id="searchClient"
                // value={searchTerm}
                onChange={e => handleSearch(e.target.value)}
                placeholder="Search"
              />
            </div>

            <div style={{ marginRight: 20 }}>
              <div className="dataTables_length">
                <label className="w-125px">
                  <select
                    onChange={e => setPerPage(e.target.value)}
                    //data-control="select2"
                    className="form-select form-select-sm form-select-solid"
                    defaultValue={5}
                    id="perPage"

                  >
                    <option value={5} selected="">
                      5 Records
                    </option>
                    <option value={10}>10 Records</option>
                    <option value={25}>25 Records</option>
                    <option value={50}>50 Records</option>
                    <option value={100}>100 Records</option>
                  </select>
                </label>
              </div>
            </div>
            {props.currentDatabaseInfo?.ref_type != "dbaas_node" ? (<>
              <a onClick={openDrawer} className="btn btn-sm btn-primary my-1 sphide">
                + Add RabbitMq User
              </a>
            </>) : ('')}
          </div>
        </div>
        <div className="card-body p-0">
          <div className="table-responsive">
            <table
              className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
              id="kt_security_license_usage_table"
            >
              <thead className="border-gray-200 fs-5 fw-bold bg-secondary">
                <tr>
                  <th className="w-150px text-start ps-9">Name</th>
                  <th className="w-100px text-center">Role</th>
                  <th className="text-center w-150px">Password</th>
                  <th className="w-150px text-center">Created_at</th>
                  <th className="w-50px ps-9"></th>
                  <th className="w-100px ps-9"></th>
                  {/* <th className="w-150px text-center">virtual Host</th> */}


                </tr>
              </thead>
              <tbody id="public-IP-list" className="fw-6 fw-bold text-gray-600">
                {dummyDatabaseUserData != null ? (<>
                  {dummyDatabaseUserData.length == 0 ? (<>
                    <tr>
                      <td colspan="12" valign="top">
                        {/* <div className="d-flex flex-column flex-center">
                          <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                        </div> */}
                        <div className="mt-20 mb-20 text-center">
                          <span className="fs-6 text-gray-800 fw-bold">no record found</span>
                        </div>
                      </td>
                    </tr>
                  </>) : (<>
                    <PaginatedItems itemsPerPage={perPage} ProductData={dummyDatabaseUserData} />
                  </>)}
                </>) : ('')}
              </tbody>
            </table>
          </div>
        </div>
        <div id="drawer_overlay-co" onClick={openDrawer} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>
        <div
          id="databaseUser-modal"
          className="bg-body shadow drawer drawer-end"
          data-kt-drawer="true"
          data-kt-drawer-name="chat"
          data-kt-drawer-activate="true"
          data-kt-drawer-direction="end"
          data-kt-drawer-close="#close_ssh"
          data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
          style={{ maxWidth: '70%', important: true }}

        >
          {/*begin::Messenger*/}
          <div className="card rounded-0 w-100 border-0" id="kt_drawer_chat_messenger">
            {/*begin::Card header*/}
            <div className="card-header pe-5" id="kt_drawer_chat_messenger_header">
              {/*begin::Title*/}
              <div className="card-title">
                {/*begin::User*/}
                <div className="d-flex justify-content-center flex-column me-3">

                  <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                    Add New RabbitMq User
                  </a>


                </div>
                {/*end::User*/}
              </div>
              {/*end::Title*/}
              {/*begin::Card toolbar*/}
              <div className="card-toolbar">
                {/*begin::Close*/}
                <div
                  onClick={openDrawer}
                  className="btn btn-sm btn-icon btn-active-light-primary"
                  id="close_ssh"
                >
                  {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                  <span className="svg-icon svg-icon-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.5"
                        x={6}
                        y="17.3137"
                        width={16}
                        height={2}
                        rx={1}
                        transform="rotate(-45 6 17.3137)"
                        fill="black"
                      />
                      <rect
                        x="7.41422"
                        y={6}
                        width={16}
                        height={2}
                        rx={1}
                        transform="rotate(45 7.41422 6)"
                        fill="black"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                </div>
                {/*end::Close*/}
              </div>
              {/*end::Card toolbar*/}
            </div>
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className="" id="">
              {/*begin::Form*/}
              <form id="databaseUser-form" className="form">
                {/*begin::Modal body*/}
                <div className="modal-body ">
                  {/*begin::Scroll*/}
                  <div
                    className="scroll-y me-n7 pe-7"
                    id="kt_modal_create_api_key_scroll"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                    data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                    data-kt-scroll-offset="300px"
                  >
                    {/*begin::Input group*/}
                    {/* {databaseInfo?.engine?.toLowerCase() == "kafka" ? */}
                    <div className="mb-5 fv-row">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">Name</label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <input
                        type="text"
                        className="form-control"
                        id="databasesuser"
                        name="name"
                        placeholder="Name"
                        onChange={handleInputChange}
                        onKeyPress={handleKeyPress}
                      />


                    </div>
                    <input
                      type="hidden"
                      className="form-control"
                      id="clusterid"
                      name="clusterid"
                      value={id}
                    />
                    <div className="mb-5 fv-row">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2"> Password</label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <input
                        type="text"
                        className="form-control"
                        id="databasesuser"
                        name="password"
                        placeholder="Password"
                        onChange={handleInputChange}
                        onKeyPress={handleKeyPress}
                      />
                      {/*end::Input*/}
                    </div>

                    <div className="mb-5 fv-row">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2"> Tags</label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <select className="form-select" name="permission" id="tags" onChange={() => openModal('d-block')}>
                        <option className="administrator">Admin</option>
                        <option className="monitoring">Monitoring</option>
                        <option className="policymaker">Policymaker</option>
                        <option className="management">Management</option>
                        <option className="impersonator">Impersonator</option>
                        <option className="none">None</option>
                      </select>
                      {/*end::Input*/}
                    </div>



                    <div className="mb-5 fv-row d-none">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">Can Access virtual Host</label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <input
                        type="text"
                        className="form-control"
                        id="databasesuser"
                        name="name"
                        placeholder="Can Access virtual Host"
                        onChange={handleInputChange}
                        onKeyPress={handleKeyPress}
                      />
                      {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                  </div>
                  {/*end::Scroll*/}
                </div>
                {/*end::Modal body*/}
                {/*begin::Modal footer*/}
                <div className="modal-footer">
                  {/*begin::Button*/}
                  <button
                    onClick={createDatabaseUser}
                    className="btn btn-primary w-100"
                    id="create-databaseUser-btn"
                  >

                    <span className="indicator-label">  Add RabbitMq User</span>

                  </button>
                  {/*end::Button*/}
                </div>
                {/*end::Modal footer*/}
              </form>
              {/*end::Form*/}
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Messenger*/}
        </div>
        <UpdateRabbitMqUsers openUpdateDrawer={openUpdateDrawer} updateObject={updateObject} setUpdateObject={setUpdateObject} />
        {pageStatus &&
          <TopBarHeaderLine />}
      </div>
      <UsersPermissionDrawer openPermissionDrawer={openPermissionDrawer} />
      <div
        className="modal fade"
        id={"permissionInfo-modal"}
        tabIndex={-1}
        aria-hidden="true"
      >
        {/*begin::Modal dialog*/}
        <div className="modal-dialog modal-dialog-centered mw-650px">
          {/*begin::Modal content*/}
          <div className="modal-content">
            {/*begin::Modal header*/}
            <div
              className="modal-header ps-18"
              id="kt_modal_create_api_key_header"
            >
              <h2 >Add Target</h2>
            </div>
            <div className="modal-body">
              <p className="fs-4">
                Comma-separated list of tags to apply to the user. Currently{" "}
                <a
                  href="https://www.rabbitmq.com/management.html#permissions"
                  target="_blank"
                >
                  supported by the management plugin
                </a>
                :
              </p>
              <dl>
                <dt className="fw-bolder fs-4 mt-3">Management</dt>
                <dd style={{ marginLeft: 'revert' }} className="fs-5 text-dark-300">User can access the management plugin.</dd>
                <dt className="fw-bolder fs-4 mt-3">Policymaker</dt>
                <dd style={{ marginLeft: 'revert' }} className="fs-5 text-dark-300">
                  User can access the management plugin and manage policies and parameters
                  for the vhosts they have access to.
                </dd>
                <dt className="fw-bolder fs-4 mt-3">Monitoring</dt>
                <dd style={{ marginLeft: 'revert' }} className="fs-5 text-dark-300">
                  User can access the management plugin and see all connections and
                  channels, as well as node-related information.
                </dd>
                <dt className="fw-bolder fs-4 mt-3">Administrator</dt>
                <dd style={{ marginLeft: 'revert' }} className="fs-5 text-dark-300">
                  User can do everything monitoring can do, manage users, vhosts, and
                  permissions, close other users' connections, and manage policies and
                  parameters for all vhosts.
                </dd>
              </dl>
              <p className="fs-5 text-dark-300">
                Note that you can set any tag here; the links for the above four tags are
                just for convenience.
              </p>
            </div>

            <div className="modal-footer flex-start" id="cloudFooter">
              {/*begin::Button*/}
              <button
                id="addserver"
                type="button"
                onClick={() => openModal('d-none')}
                // onClick={addBackendServer}
                className="btn btn-primary w-20"
              >
                Close
              </button>
              {/*end::Button*/}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}


export default RabbitMqUsers
