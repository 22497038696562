// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.code-block-container code {

    background-color: transparent !important;
  
  }`, "",{"version":3,"sources":["webpack://./src/App/MicroApp/Body/utils/generate-code/style.css"],"names":[],"mappings":"AAAA;;IAEI,wCAAwC;;EAE1C","sourcesContent":[".code-block-container code {\n\n    background-color: transparent !important;\n  \n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
