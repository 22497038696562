/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import UseApi from "../../../../CustomHooks/useApi";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import AccessDenied from "../../AccessDenied";
import { connect } from "react-redux";
import { UpdateActivityPagination } from "../../../../Redux/Services/Action/actions";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../Loaders/TableLoader";
import CustomDoc from "../../../../CustomHooks/CustomDoc";
import ProductsUrl from "../../Docs/Docsdata";

const Activity = (props) => {
  const [access, setAccess] = useState(true);
  const [dataFetched, setDataFetched] = useState(false);
  // const [perPage, setPerPage]=useState(10);
  // const [currentPage, setCurrentPage]=useState(1);
  const [activityData, setActivityData] = useState(null);
  const [metaData, setMetaData] = useState(null);
  const dataFetchedRef = useRef(false);
  const loaderFunc = () => {
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if (loader && no_record) {
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");

      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
  }
  const getActivity = async () => {
    if (props && props.paginationInfo) {
      const dt = toast
      var pageCount = props.paginationInfo[0].perpage;
      var CurrPage = props.paginationInfo[0].currentPage;
      if (pageCount && CurrPage) {
        const data = await UseApi('get', 'activity?perpage=' + pageCount + '&page=' + CurrPage, '');

        if ('status' in data) {
          if (data.status === "error") {
            toast.update(dt, {
              render: data.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
            setDataFetched(true)
            if (data.message == "Permission denied.") {
              setAccess(false);
            } else {
              setAccess(true)
            }
            setActivityData([]);
            // loaderFunc();
            return;
          }
        }
        if (Object.keys(data).length === 0) {
          setDataFetched(true)
          setActivityData([]);
          setDataFetched(true)
          toast.update(dt, {
            render: "no record found", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          // loaderFunc();
        } else {
          setActivityData(data.activitys);
          setDataFetched(true)
          setMetaData(data.meta);
          toast.dismiss();
          //   toast.update(dt,{render: "Your acitvity logs request has been successful", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          // });  

        }
      }
    }
  }

  useEffect(() => {
    getActivity();
  },[]);


  function ProductInfo({ productdata }) {
    return (
      <>
        {productdata && Object.keys(productdata).length > 0 ? (
          productdata && productdata.map((value, index) => (
            <tr>

              <td className="text-center d-none">

                <div className="d-flex flex-column w-100 me-2">

                  <div className=" flex-stack">

                    <span className=" me-2 fs-7 fw-bold">{value.id}</span>
                  </div>
                </div>
              </td>
              <td className="text-center ps-9 sphide">

                <div className="d-flex align-items-center">

                  <div className="d-flex justify-content-start flex-column">

                    <span className=" d-block fs-7">
                      {<div dangerouslySetInnerHTML={{ __html: value.message }} />}
                    </span>
                  </div>
                </div>
              </td>
              <td className="text-center">

                <div className="d-flex flex-column w-100 me-2">

                  <div className="flex-stack">

                    <span className=" me-2 fs-7 fw-bold">
                      {value.remoteip}
                    </span>
                  </div>
                </div>
              </td>
              <td className="text-center">

                <div className="d-flex flex-column w-100 me-2">

                  <div className="flex-stack">

                    <span className="me-2 fs-7 fw-bold">
                     {value.time}
                    </span>
                  </div>
                </div>
              </td>
            </tr>
          ))) : (
          <>
            <tr>
              {/* <td colspan="12" className="dataTables_empty" id="loader"> */}
              {/* <div className="loading-spinner"></div> */}
              {/* <TableLoader /> */}
              {/* </td> */}
              <td colspan="12" className="" id="no_record" valign="top">
                <div className="d-flex flex-column flex-center">
                  <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                </div>
              </td>
            </tr>
          </>)}
      </>
    )
  }

  const handlePageClick = (event) => {
    const matched = props.paginationInfo[0].currentPage === (parseInt(event.selected + 1));
    if (props?.updatePaginationData) {
      props.paginationInfo[0].currentPage = event.selected + 1;
      if (!matched) {
        getActivity();
      }
    }
  };
  function PaginatedItems({ ProductData }) {

    if (metaData?.totalpages) {
      return (
        <>
          <ProductInfo productdata={ProductData} />
          <td colspan="12" className="ps-3">
            <ReactPaginate
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={metaData.totalpages}
              previousLabel="< previous"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
              initialPage={props.paginationInfo[0].currentPage - 1}
            />
          </td>
        </>
      );
    }
  }
  if (dataFetched) {
    if (access) {
      return (
        <>
          <div className="col-xl-12">
            {/*begin::Tables Widget 9*/}
            <div className="card card-xl-stretch mb-5 mb-xl-12">
              {/*begin::Header*/}
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bolder fs-1 mb-1">
                    Activity Logs
                    {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                    <span className="svg-icon svg-icon-2">
                      <i className="las la-sliders-h" style={{ fontSize: 20 }} />
                    </span>
                    {/*end::Svg Icon*/}
                  </span>
                  <span className="text-muted mt-1 fw-bold fs-7">
                    View your account activity
                  </span>
                </h3>
                <div className="card-toolbar">
                <div className="position-relative my-1" style={{ marginRight: 20 }}>
                  <CustomDoc url={ProductsUrl[0]?.activity_logs+"/"}/>
                  </div>
                  {/* <div className="position-relative my-1" style={{ marginRight: 20 }}>
          <input
            type="text"
            className="form-control form-control-sm"
            data-kt-table-widget-4="search"
            id="searchClient"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            placeholder="Search"
          />
        </div> */}
                  <div style={{ marginRight: 0 }}>
                    <div className="dataTables_length">
                      <label className="w-125px">
                        <select
                          
                          //data-control="select2"
                          className="form-select form-select-sm form-select-solid"
                          defaultValue={10}
                          id="perPage"

                        >
                          <option value={10}>10 Records</option>
                          <option value={25}>25 Records</option>
                          <option value={50}>50 Records</option>
                          <option value={100}>100 Records</option>
                        </select>
                      </label>
                    </div>
                  </div>

                </div>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              {/*begin::Table container*/}
              <div className="table-responsive">
                {/*begin::Table*/}
                <table
                  className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
                  id="table-id"
                >
                  {/*begin::Table head*/}
                  <thead className="bg-secondary">
                    <tr className="fw-bold">
                      <th className="w-50px text-center d-none">ID</th>
                      <th className="w-150px text-start ps-9">Message</th>
                      <th className="w-150px text-center">IP Address</th>
                      <th className="w-100px text-center sphide">Created_at</th>
                    </tr>
                  </thead>
                  {/*end::Table head*/}
                  {/*begin::Table body*/}
                  <tbody  >
                    {/* {activityData!=null?(<>
<PaginatedItems ProductData={activityData}  />
  </>):(<>
    <tr>
                     <td colspan="12" className="dataTables_empty" id="loader">
                      <div className="loading-spinner"></div>
                    </td>
                    <td colspan="12" className="d-none" id="no_record" valign="top">
                      <div className="d-flex flex-column flex-center">
                        <img
                          src="/assets/media/illustrations/dozzy-1/5.png"
                          className="mw-400px"
                        />
                        <div className="fs-1 fw-bolder text-dark mb-4">No items found.</div>
                      </div>
                    </td>
                    </tr>
  </>)} */}
                    {activityData != null ? (<>
                      {access === false ? (<>
                        <tr>
                          <td colspan={12}>
                            <AccessDenied />
                          </td>
                        </tr>
                      </>) : (<>
                        {Object.keys(activityData ?? {}).length === 0 ? (<>
                          <tr>
                            <td colspan="12" className="" id="no_record" valign="top">
                              <div className="d-flex flex-column flex-center">
                                <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                              </div>
                            </td>
                          </tr>
                        </>) : (<>
                          <PaginatedItems ProductData={activityData} />
                        </>)}
                      </>)}
                    </>) : (<>
                      <tr>
                        <td colspan={12}>
                          <TableLoader />
                          <TopBarHeaderLine />
                        </td>
                      </tr>

                    </>)}
                  </tbody>
                  {/*end::Table body*/}
                </table>
                {/*end::Table*/}
              </div>
              {/*end::Table container*/}
              {/*begin::Body*/}
            </div>
            {/*end::Tables Widget 9*/}
          </div>
        </>
      )
    } else {
      return (
        <>
          <AccessDenied />
        </>
      )
    }
  } else {
    return (
      <>
        <TableLoader />
      </>
    )
  }

}
  
const mapStateToProps = (state) => ({
  paginationInfo: state.activityStore.activityPaginationInfo,
});

const mapDispatchToProps = (dispatch) => ({
  updatePaginationData: (data) => dispatch(UpdateActivityPagination(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Activity);