// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` 
  .select-cloud {
    display: flex;
    padding: 5px;
    width:100%;
    /* background-color: #fff; */
  }
  .select-cloud:hover, .select-cloud:focus {
    /* background-color: #f1faff; */
    color: #009ef7;
    transition: color 0.2s ease, background-color 0.2s ease;
  }
  
  @keyframes HideList {
    from {
      transform: scaleY(1);
    }
    to {
      transform: scaleY(0);
    }
  }`, "",{"version":3,"sources":["webpack://./src/App/MicroApp/Body/Products/cloud/helper/custom.css"],"names":[],"mappings":";EACE;IACE,aAAa;IACb,YAAY;IACZ,UAAU;IACV,4BAA4B;EAC9B;EACA;IACE,+BAA+B;IAC/B,cAAc;IACd,uDAAuD;EACzD;;EAWA;IACE;MACE,oBAAoB;IACtB;IACA;MACE,oBAAoB;IACtB;EACF","sourcesContent":[" \n  .select-cloud {\n    display: flex;\n    padding: 5px;\n    width:100%;\n    /* background-color: #fff; */\n  }\n  .select-cloud:hover, .select-cloud:focus {\n    /* background-color: #f1faff; */\n    color: #009ef7;\n    transition: color 0.2s ease, background-color 0.2s ease;\n  }\n  \n  @-webkit-keyframes HideList {\n    from {\n      transform: scaleY(1);\n    }\n    to {\n      transform: scaleY(0);\n    }\n  }\n  \n  @keyframes HideList {\n    from {\n      transform: scaleY(1);\n    }\n    to {\n      transform: scaleY(0);\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
