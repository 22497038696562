import React, { useEffect, useState } from "react";
import GrafanaViewer from "./charts/GrafanaViwer";
import TableLoader from "../../../Loaders/TableLoader";
import GrafanaViewerBandwidth from "./charts/GrafanaViwerBandwidth";
import localStorage from "local-storage";
import { sleep } from "../../../../../../services/TimeFunctions";
import CustomDoc from "../../../../../CustomHooks/CustomDoc";
import ProductsUrl from "../../../Docs/Docsdata";
import BandwidthInboundLineChart from "./charts/BandwidthInboundLineChart";
import BandwidthOutboundLineChart from "./charts/BandwidthOutboundLineChart";
import UseApi from "../../../../../CustomHooks/useApi";

export default function Overview(props) {
  var query = window.location.href;
  var vars = query.split("/");
  var id = vars[4];

  const { cloud } = props;
  const [cloudData, setCloudData] = useState(null);
  
  const [chartBillingCycle, setChartBillingCycle] = useState([]); 
  const [currentCycle, setCurrentCycle] = useState('1 hour');

  useEffect(() => {
    if (cloud === undefined) throw new Error("Missing prop: cloud");
    
    if (cloud !== null) {
       setCloudData(cloud);
      handleCycleChange();
    }
  }, [currentCycle]);

  const [dataDuration, setDataDuration] = useState('1h');
  const [dataManualDuration, setDataManualDuration] = useState('1hour');
  const toggleMenu = () => {
    var chartMenu = document.querySelector("#chart-menu");
    if (chartMenu) {
      chartMenu.classList.toggle("d-block");
    }
  };

  const [dataDurationBandwidth, setDataDurationBandwidth] = useState('-0');
  const [dataManualDurationBandwidth, setDataManualDurationBandwidth] = useState('-0');
  const toggleMenuBandwidth = () => {
    var chartMenu = document.querySelector("#chart-menu-Bandwidth");
    if (chartMenu) {
      chartMenu.classList.toggle("d-block");
    }
  };

  const changeCycle = (value, manualValue, innerValue) => {
    // Remove this line: setDataDuration(null);
    var currentCycle = document.querySelector("#currentCycle");
    if (currentCycle) {
      currentCycle.innerHTML = innerValue;
    }
    setDataDuration(value);
    setDataManualDuration(manualValue);
    toggleMenu();
  };

  const changeBandwidthCycle = (value, manualValue, innerValue) => {
    // Remove this line: setDataDuration(null);
    var currentCycle = document.querySelector("#currentCycleBandwidth");
    if (currentCycle) {
      currentCycle.innerHTML = innerValue;
    }
    setDataDurationBandwidth(value);
    setDataManualDurationBandwidth(manualValue);
    toggleMenuBandwidth();
  };

  useEffect(() => {
    if (localStorage.get("graphBandwidthCycle") >= 0 && localStorage.get("graphBandwidthCycle") <= 12 && localStorage.get("graphBandwidthCycle") != null) {
    } else {
      localStorage.set("graphBandwidthCycle", 0);
    }

  }, [])
  const changeBandwidthCycles = (value) => {
    // Remove this line: setDataDuration(null);
    var currentCycleValue = document.querySelector("#bandwidthCycleValue");
    var currentCycleBandwidthNext = document.querySelector("#currentCycleBandwidthNext");
    var currentCycleBandwidthPrev = document.querySelector("#currentCycleBandwidthPrev");

    if (currentCycleValue && currentCycleBandwidthNext && currentCycleBandwidthPrev) {
      var bvalue = parseInt(currentCycleValue.value);

      if (localStorage.get("graphBandwidthCycle") >= 0 && localStorage.get("graphBandwidthCycle") <= 12 && localStorage.get("graphBandwidthCycle") != null) {
        var blvalue = parseInt(localStorage.get("graphBandwidthCycle"));
      } else {
        localStorage.set("graphBandwidthCycle", 0);
      }
      if (value == "next") {
        if (blvalue > 0 && blvalue <= 12) {
          blvalue--;
          setDataDurationBandwidth("-" + blvalue);
          setDataManualDurationBandwidth("-" + blvalue);
          currentCycleBandwidthNext.classList.remove("disabled");
          currentCycleBandwidthPrev.classList.remove("disabled");
          localStorage.set("graphBandwidthCycle", blvalue);
          // currentCycleValue.value=blvalue;
        } else if (blvalue == 0) {

          setDataDurationBandwidth("-" + blvalue);
          setDataManualDurationBandwidth("-" + blvalue);
          currentCycleBandwidthNext.classList.add("disabled");
          currentCycleBandwidthPrev.classList.remove("disabled");
          // currentCycleValue.value=0;
          localStorage.set("graphBandwidthCycle", 0);
        }
      } else {
        if (blvalue >= 0 && blvalue < 12) {
          blvalue++;
          setDataDurationBandwidth("-" + blvalue);
          setDataManualDurationBandwidth("-" + blvalue);
          currentCycleBandwidthNext.classList.remove("disabled");
          currentCycleBandwidthPrev.classList.remove("disabled");
          // currentCycleValue.value=blvalue;
          localStorage.set("graphBandwidthCycle", blvalue);
        } else if (blvalue == 12) {
          setDataDurationBandwidth("-" + blvalue);
          setDataManualDurationBandwidth("-" + blvalue);
          currentCycleBandwidthNext.classList.remove("disabled");
          currentCycleBandwidthPrev.classList.add("disabled");
          // currentCycleValue.value=12;
          localStorage.set("graphBandwidthCycle", 12);
        }
      }

    }

  };

  function getPreviousMonthName(offset) {
    const currentMonth = new Date().getMonth();
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const targetMonth = (currentMonth - offset + 12) % 12;
    return months[targetMonth];
  }

  function filterBillingCycle(created_at) {
    const billingCycle = [
      { label: "1 hour", timeCode: '1h', time: '1hour' },
      { label: "4 hours", timeCode: '4h', time: '4hours' },
      { label: "12 hours", timeCode: '12h', time: '12hours' },
      { label: "24 hours", timeCode: '24h', time: '24hours' },
      { label: "7 days", timeCode: '1w', time: '7days' },
      { label: "30 days", timeCode: '1M', time: '30days' },
      { label: "60 days", timeCode: '2M', time: '60days' }
    ];

    const createdAt = new Date(created_at);
    const now = new Date();
    const diffTime = Math.abs(now - createdAt);
    const diffHours = diffTime / (1000 * 60 * 60); // Total difference in hours

    // if (diffHours <= 24) {
    //   return billingCycle.slice(0, 1); // return only "1 hour"
    // } else if (diffHours <= 4 * 24) {
    //   return billingCycle.slice(0, 2); // return up to "4 hours"
    // } else if (diffHours <= 12 * 24) {
    //   return billingCycle.slice(0, 3); // return up to "12 hours"
    // } else if (diffHours <= 24 * 24) {
    //   return billingCycle.slice(0, 4); // return up to "24 hours"
    // } else if (diffHours <= 7 * 24) {
    //   return billingCycle.slice(0, 5); // return up to "7 days"
    // } else if (diffHours <= 30 * 24) {
    //   return billingCycle.slice(0, 6); // return up to "30 days"
    // } else if (diffHours <= 60 * 24) {
    //   return billingCycle.slice(0, 7); // return up to "60 days"
    // } else {
    //   return billingCycle; // return all billing cycles
    // }
    return billingCycle;
  }

  useEffect(() => { 
    if (cloudData != null) {
       var cycleData = filterBillingCycle(cloudData?.created_at);
      setChartBillingCycle(cycleData);
      window.selectDropDown('cycle_dropdown', {})
    }
  }, [cloudData])

  const handleCycleChange = async() => { 
      const cycle_dropdown = document.querySelector("#cycle_dropdown");
      if(cycle_dropdown){
        setCurrentCycle(cycle_dropdown.value);
      const selectedValue = cycle_dropdown.value; 
  
      const selectedOption = chartBillingCycle.find(option => option.label === selectedValue);
        if (selectedOption) {
        changeCycle(selectedOption.timeCode, selectedOption.time, selectedOption.label);
      } 
    }
  }

  useEffect(() => {
    const anchorElement = document.getElementById('cycle-parent');
     const observer = new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {
          console.log("works");
           handleCycleChange();
        }
      }
    });

    observer.observe(anchorElement, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  const [inboundData, setInboundData] = useState(null);
  const [totalInbound, setTotalInbound] = useState(null);
  const [outboundData, setOutboundData] = useState(null);
  const [totalOutbound, setTotalOutbound] = useState(null);

  async function getBandwidthInfo(){ 
    const formattedDate = formatDate(displayYear, displayMonth);
    var res = await UseApi("get", "cloud/"+id+"/bandwidth/"+formattedDate, "");
    if(res){
      if(res.in){
        let inboundTotal = 0;
        Object.entries(res.in).map((value, index)=>{
          inboundTotal+= value[1]/1024;
        })
        setTotalInbound(inboundTotal);
        setInboundData(res.in);
      }
      if(res.out){
        let outboundTotal = 0;
        Object.entries(res.out).map((value, index)=>{
          outboundTotal+= value[1]/1024;
        })
        setTotalOutbound(outboundTotal);
        setOutboundData(res.out);
      } 
    }
     var displayYearText = document.querySelector("#displayYear-text");
     var displayMonthText = document.querySelector("#displayMonth-text");
     if(displayYearText && displayMonthText){
      displayMonthText.innerHTML = getMonthName(displayMonth);
      displayYearText.innerHTML = displayYear;
     } 
   }

   // Function to format the year and month into "YYYYMM" format
function formatDate(year, month) {
  if(year && month){
  return year.toString() + (month < 10 ? '0' + month : month).toString();
  }
}

// Initializing the current month and year
let currentDate = new Date();
let currentYear = currentDate.getFullYear();
let currentMonth = currentDate.getMonth() + 1; // JavaScript months are 0-based, so add 1
 

const [displayYear, setDisplayYear] =useState(currentYear);
const [displayMonth, setDisplayMonth] =useState(currentMonth);
   useEffect(()=>{
    getBandwidthInfo();
    updateButtons();
   },[])


// Function to get the month name from the month number
function getMonthName(month) {
  const monthNames = ["January", "February", "March", "April", "May", "June", 
                      "July", "August", "September", "October", "November", "December"];
  return monthNames[month - 1];  // Month is 1-based, array is 0-based
}

// Update buttons based on the current state
function updateButtons() {
  const prevButton = document.getElementById('currentCycleBandwidthPrev');
  const nextButton = document.getElementById('currentCycleBandwidthNext');

  // If displaying the current month, hide the "Next" button, otherwise show it
  if (displayYear === currentYear && displayMonth === currentMonth) {
      nextButton.classList.add("disabled");
  } else {
    nextButton.classList.remove("disabled");
  } 
}

// Previous button click handler
function handlePrevClick() {
  if (displayMonth === 1) {
      setDisplayMonth(12);
      var yearCalc = displayYear-1;
      setDisplayYear(yearCalc);
  } else {
    var monthCalc = displayMonth-1;
      setDisplayMonth(monthCalc);
  }

  // Update the API call and buttons
  getBandwidthInfo();
  updateButtons();
}

// Next button click handler
function handleNextClick() {
  if (displayMonth === 12) {
    setDisplayMonth(1); 
    var yearCalc = displayYear+1;
      setDisplayYear(yearCalc); 
  } else {
    var monthCalc = displayMonth+1;
    setDisplayMonth(monthCalc); 
  }

  // Update the API call and buttons
  getBandwidthInfo();
  updateButtons();
}
  return (
    <>
      {/*begin::Container*/}
      <div className="card card-flush h-80px mb-10">
        <div className="card-header pt-5 pb-5">
          {/*begin::Title*/}
          <h3 className="align-items-start flex-column" >
            <span className="card-label fw-bolder">
              Performance Overview
            </span>
            <p className="text-gray-400 mt-3 fw-bold fs-6">
              View Your Server Performance
            </p>
          </h3>
          {/*end::Title*/}
          {/*begin::Toolbar*/}
          <div className="card-toolbar pb-5">
          <a  style={{marginRight: "20px"}}>
          <CustomDoc url={ProductsUrl[0]?.cloud+"/manage-cloud/overview/"}/>
          </a>
            {/*begin::Trigger*/}
            <a id="cycle-parent" style={{width: "130px"}}>
              <select
                className="form-select form-select-sm"
                id="cycle_dropdown"
                defaultValue={"1 hour"}
                data-hide-search={true}
              >
                {chartBillingCycle && chartBillingCycle.length > 0 ? (
                  <>
                    {chartBillingCycle.map((value, index) => (
                      <option value={value.label} key={index}>
                        {value.label}
                      </option>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </select>
            </a>
            {/*end::Trigger*/}
          </div>
          {/*end::Toolbar*/}
        </div>
      </div>

      <div id="" className="">
        {/*begin::Row*/}
        {dataDuration ? (
          <GrafanaViewer key={dataDuration} dataTime={dataDuration} />
        ) : (
          <><TableLoader /></>
        )}
      </div>
      <div className="card card-flush  mb-10">
        <div className="card-header pt-5">
          {/*begin::Title*/}
          <h3 className="align-items-start flex-column">
            <span className="card-label fw-bolder text-drak">
              Bandwidth Overview
            </span>
            <p className="text-gray-400 mt-3 fw-bold fs-6">
              View Your Bandwidth Usage
            </p>
          </h3>
          {/*end::Title*/}
          {/*begin::Toolbar*/}
          <div className="card-toolbar">
            <a
              className="btn mb-6"
            >
              
               <span id="displayYear-text"></span>-
               <span id="displayMonth-text"></span>&nbsp;
                Month's Graph :

            </a>
            <a
            onClick={handlePrevClick}
              id="currentCycleBandwidthPrev" 
              className="btn btn-light-primary btn-sm mb-6"
              style={{ width: "120px" }}
            >
              <span className="svg-icon svg-icon-5 rotate-180 ms-3 me-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M17.6 4L9.6 12L17.6 20H13.6L6.3 12.7C5.9 12.3 5.9 11.7 6.3 11.3L13.6 4H17.6Z"
                    fill="black"
                  />
                </svg>
              </span>
              &nbsp;<span >Previous</span>
            </a>

            <input type="hidden" id="bandwidthCycleValue" value="0" />
            <a 
            onClick={handleNextClick}
              id="currentCycleBandwidthNext"
              className="btn btn-light-primary btn-sm mb-6"
              style={{ width: "120px" }}
            >
              <span >Next</span>

              <span className="svg-icon svg-icon-5 rotate-180 ms-3 me-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M6 4L14 12L6 20H10L17.3 12.7C17.7 12.3 17.7 11.7 17.3 11.3L10 4H6Z"
                    fill="black"
                  />
                </svg>

              </span>
            </a>
          </div>
          {/*end::Toolbar*/}
        </div>
      </div>

      <div id="" className="bg-light">
        {/*begin::Row*/}
        {/* {dataDurationBandwidth ? (<>
          <GrafanaViewerBandwidth key={dataDurationBandwidth} dataTime={dataDurationBandwidth} />
        </>) : (
          <><TableLoader /></>
        )} */}
        <div className="row">
           <div className="col-lg-6"> 
           <div className="d-flex flex-stack flex-wrap container">
            <div className="me-2">
              <a className="text-dark text-hover-primary fw-bolder fs-4">Bandwidth Inbound Data</a>
              <div className="text-muted fs-7 fw-bold">Monitor bandwidth inbound data here</div>
            </div>
            <div className="fw-bolder fs-5"> Total : {totalInbound && totalInbound.toFixed(0)} GB</div>
          </div> 
           {inboundData!=null?(<>
            <BandwidthInboundLineChart data={inboundData} />
            </>):('')} 
           </div>
           <div className="col-lg-6">
           <div className="d-flex flex-stack flex-wrap container">
            <div className="me-2">
              <a className="text-dark text-hover-primary fw-bolder fs-4">Bandwidth Outbound Data</a>
              <div className="text-muted fs-7 fw-bold">Monitor bandwidth outbound data here</div>
            </div>
            <div className="fw-bolder fs-5"> Total : {totalOutbound && totalOutbound.toFixed(0)} GB</div>
          </div> 
           {outboundData!=null?(<>
            <BandwidthOutboundLineChart data={outboundData} />
            </>):('')}
           </div>
        </div> 
      </div>
      {/*end::Row*/}
    </>
  )
}


