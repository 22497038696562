import { React, useEffect, useState } from 'react';
import "./custom.css";
import { useNavigate } from 'react-router-dom';
import CreateProject from './tab/CreateProject';
import UseCallApi from '../../../../CustomHooks/useCallApi';
import AccessDenied from '../../AccessDenied';
import TableLoader from '../../Loaders/TableLoader';
export default function ContainerInfo(props) {
  const [dataFetched, setDataFetched] = useState(false);
  const [access, setAccess] = useState(true)
  let navigate = useNavigate()
  const switchPage = () => {
    navigate('/container-registry/create')
    // if (props && props.switchPage) {
    //   props.switchPage([]);
    // }
  }
  const getAllRegistry = async (id) => {
    try {
      let responseData = await UseCallApi('get', `registry/projects`, '')
      setDataFetched(true)
      if (responseData.status == "error") {
        if(responseData.message == "Permission denied."){
          setAccess(false)
        }else{
          setAccess(true)
        }
      }
    } catch {

    }
  }
  useEffect(() => {
    getAllRegistry()
  }, [])
  if (dataFetched) {
    if (access) {
      return (
        <>
          <div className="card card-docs mb-2">
            {/*begin::Card Body*/}
            <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
              {/*begin::Section*/}
              <div className="px-md-10 pt-md-10 pb-md-5">
                {/*begin::Block*/}
                <div className='row'>

                  <div className='col-8'>
                    <div className="text-start mb-20">
                      <h1 className="fs-2tx fw-bolder mb-8">
                        Streamline container image management for&nbsp;
                        <span className="d-inline-block position-relative">
                          <div className='mt-2'>
                            <span className="d-inline-block mb-3" style={{ whiteSpace: 'nowrap' }}>Fast Deployment</span>
                          </div>
                          <span className="d-inline-block position-absolute h-8px bottom-0 end-0 start-0 bg-success translate rounded" />
                        </span>
                      </h1>
                      <div className="fw-bold fs-2 text-gray-500 mb-10">
                        Our scalable registry solution is optimized for seamless integration with <span className="fw-bolder text-gray-900">Docker</span> environments and <span className="fw-bolder text-gray-900">Utho Container Registry</span>, allowing you to build container images for rapid deployment.
                      </div>
                      <a className='btn btn-primary sphide' onClick={switchPage}>Create Container Registry &nbsp;
                        {/*begin::Svg Icon | path: assets/media/icons/duotune/arrows/arr001.svg*/}
                        <span className="svg-icon svg-icon-muted svg-icon-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z"
                              fill="black"
                            />
                            <path
                              opacity="0.3"
                              d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}
                      </a>
                    </div>
                  </div>
                  <div className='col-4 mt-5'>
                    <img src='/assets/images/container-registry.jpg' width="100%" />
                  </div>
                </div>
                {/*end::Block*/}
                {/*begin::Row*/}
                <div className="row">
                  {/*begin::Col*/}
                  <div className="col-md-4 mb-10">
                    <div className="bg-light bg-opacity-50 rounded-3 p-10 h-md-100 border">
                      <div className="d-flex flex-center w-60px h-60px rounded-3 bg-light-info bg-opacity-90 mb-10">
                        {/*begin::Svg Icon | path: icons/duotune/coding/cod001.svg*/}
                        <span className="svg-icon svg-icon-info svg-icon-3x">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              opacity="0.3"
                              d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z"
                              fill="black"
                            />
                            <path
                              d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z"
                              fill="black"
                            />
                          </svg>

                        </span>
                        {/*end::Svg Icon*/}
                      </div>
                      <h1 className="mb-5 sphide">Scalability</h1>
                      <div className="fs-4 text-gray-600 py-3">
                        Our container registry is designed to handle large-scale deployments, ensuring smooth operations as your needs grow.
                      </div>
                    </div>
                  </div>
                  {/*end::Col*/}
                  {/*begin::Col*/}
                  <div className="col-md-4 mb-10">
                    <div className="bg-light bg-opacity-50 rounded-3 p-10 h-md-100 border">
                      <div className="d-flex flex-center w-60px h-60px rounded-3 bg-light-success bg-opacity-90 mb-10">
                        {/*begin::Svg Icon | path: icons/duotune/art/art006.svg*/}
                        <span className="svg-icon svg-icon-success svg-icon-3x">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              opacity="0.3"
                              d="M22 19V17C22 16.4 21.6 16 21 16H8V3C8 2.4 7.6 2 7 2H5C4.4 2 4 2.4 4 3V19C4 19.6 4.4 20 5 20H21C21.6 20 22 19.6 22 19Z"
                              fill="black"
                            />
                            <path
                              d="M20 5V21C20 21.6 19.6 22 19 22H17C16.4 22 16 21.6 16 21V8H8V4H19C19.6 4 20 4.4 20 5ZM3 8H4V4H3C2.4 4 2 4.4 2 5V7C2 7.6 2.4 8 3 8Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}
                      </div>
                      <h1 className="mb-5 sphide fs-2">Compatibility</h1>
                      <div className="fs-4 text-gray-600 py-3">
                        Seamlessly integrates with Docker environments and Utho Kubernetes clusters, offering a hassle-free experience.
                      </div>
                    </div>
                  </div>
                  {/*end::Col*/}
                  {/*begin::Col*/}
                  <div className="col-md-4 mb-10">
                    <div className="bg-light bg-opacity-50 rounded-3 p-10 h-md-100 border">
                      <div className="d-flex flex-center w-60px h-60px rounded-3 bg-light-danger bg-opacity-90 mb-10">
                        {/*begin::Svg Icon | path: icons/duotune/abstract/abs027.svg*/}
                        <span className="svg-icon svg-icon-danger svg-icon-3x">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              opacity="0.3"
                              d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z"
                              fill="black"
                            />
                            <path
                              d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}
                      </div>
                      <h1 className="mb-5 sphide fs-2">Security</h1>
                      <div className="fs-4 text-gray-600 py-3">
                        Built-in security measures protect your container images, ensuring they remain safe and accessible only to authorized users.
                      </div>
                    </div>
                  </div>
                  {/*end::Col*/}
                </div>
                {/*end::Row*/}

                <div className="card" style={{ background: "#1C325E" }}>
                  {/*begin::Body*/}
                  <div className="card-body ps-xl-15">
                    <div className='row'>
                      <div className='col-12'>
                        {/*begin::Action*/}
                        <div className="m-0">
                          {/*begin::Title*/}
                          <div className="position-relative fs-2x z-index-2 fw-bolder text-white mb-7">
                            <span className="me-2">
                              Get Started by setting up your container&nbsp;
                              <span className="position-relative d-inline-block text-danger">
                                <div
                                   className="text-danger"
                                >
                                  image
                                </div>
                                {/*begin::Separator*/}
                                <span className="position-absolute opacity-50 bottom-0 start-0 border-4 border-danger border-bottom w-100" />
                                {/*end::Separator*/}
                              </span>
                            </span>
                            repository with UTHO
                          </div>
                          {/*end::Title*/}
                          {/* <span className='text-light fs-6'>Safely store and organize all your container images in a centralized location.</span> */}
                        </div>
                      </div>
                    </div>

                    {/*begin::Action*/}
                    <div className='col-12'>
                      {/*begin::Action*/}
                      <div className="mt-5">
                        <a className="btn btn-danger fw-bold me-2" onClick={switchPage}>
                          Create Container Registry
                          &nbsp;
                          {/*begin::Svg Icon | path: assets/media/icons/duotune/arrows/arr001.svg*/}
                          <span className="svg-icon svg-icon-muted svg-icon-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z"
                                fill="black"
                              />
                              <path
                                opacity="0.3"
                                d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z"
                                fill="black"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </a>
                      </div>
                      {/*begin::Action*/}
                    </div>
                  </div>
                  {/*end::Body*/}
                </div>

              </div>
              {/*end::Section*/}
            </div>
            {/*end::Card Body*/}
          </div>

        </>)
    } else {
      return (
        <>
          <AccessDenied />
        </>
      )
    }
  } else {
    return (
      <>
        <TableLoader />
      </>
    )
  }
}