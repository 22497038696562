import React, { useEffect, useRef, useState } from "react";
 import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; 
import swal from "sweetalert";  
import Resize from "./tabs/Resize";
import Attach from "./tabs/Attach";
import Configuration from "./tabs/Configuration";
import TableLoader from "../../Loaders/TableLoader";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import UseApi from "../../../../CustomHooks/useApi";
export default function ManageBlockStorage() {
  
  var query = window.location.href; 
  var vars = query.split("/"); 
  var id=vars[4]; 

  const navigate=useNavigate();
  const navRedirect=()=>{
    setTimeout(() => {
      navigate('/ebs');
     }, 2000);
      
  }
const dataFetchedRef=useRef(false); 
// const [blockData, setBlockData] = useState(null);
const [blockData,setBlockData]=useState([{id:'131', label: "block 2", location:{dczones:[{cc:'in', city: 'Delhi (Noida)', country: 'India'}]}, type:"NVME", size: 12, status: 'active', cloudid: 1213313, created_at:"12-03-2023 12:02:34"}]);

const getBlockStorage=async()=>{   
  const data=await UseApi('get','ebs/'+id,'');
  //console.log(data);
 
  if(data.status == "error"){
    setBlockData(null);
    swal({
        title: "No Record found ",
        text: "We are unable to find record with this Id: "+id+"!",
        icon: "warning",
        button: "OK",
      });
      navRedirect();
  }else{ 
    if(data.ebs){
    setBlockData(data.ebs[0]);
      
  } 
 }
}  

const deleteBlock=async()=>{
  var submitBtn=document.querySelector("#destroy-btn"); 
   
  if(submitBtn){
    var preContent=submitBtn.innerHTML;
    submitBtn.classList.add("disabled");
    submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>'; 

  toast.dismiss();
  swal({
    title:"Are you sure?",
    text: "Once deleted, you will not be able to recover this block storage!",
    icon: "warning",
    buttons: true,
    dangerMode: true,
    })
    .then(async(willDelete) => {
    if(willDelete) {
      const dt=toast.loading("Block Storage deleting..."); 
      const dnsp=await UseApi('DELETE','ebs/'+id+'/destroy','');
      //console.log(dnsp);
      if(dnsp.status=='error'){
        toast.update(dt,{render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      }else{
        toast.update(dt,{render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });  
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML=preContent;
      navRedirect();
      }
    } 
    });
  }
  
}

useEffect(()=>{  
  if(dataFetchedRef.current) return;
    dataFetchedRef.current=true; 
    getBlockStorage();  
},[]);   
if(blockData!=null){
  return (
    <>  
      <div className="card mb-6 mb-xl-9 ">
        <div className="card-body pt-9 pb-0 ">
          <div className="d-flex flex-wrap flex-sm-nowrap mb-6">
            <div
              className="d-flex flex-center flex-shrink-0 bg-light rounded w-55px h-55px w-lg-55px h-lg-55px me-7 mb-4"
              id="imageDist"
            >
              <span className="w-50">
                <svg
                  fill="#494b74"
                  width={44}
                  height={44}
                  viewBox="0 0 38 32"
                  id="icon"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <defs>
                    <style
                      dangerouslySetInnerHTML={{
                        __html:
                          "\n                                                                                    .cls-1 {\n                                                                                        fill: none;\n                                                                                    }\n                                                                                ",
                      }}
                    />
                  </defs>
                  <path
                    d="M28,20H26v2h2v6H4V22H6V20H4a2.0024,2.0024,0,0,0-2,2v6a2.0024,2.0024,0,0,0,2,2H28a2.0024,2.0024,0,0,0,2-2V22A2.0024,2.0024,0,0,0,28,20Z"
                    transform="translate(0 0)"
                  />
                  <circle cx={7} cy={25} r={1} />
                  <path
                    d="M15,20H8V13h7Zm-5-2h3V15H10Z"
                    transform="translate(0 0)"
                  />
                  <path
                    d="M24,20H17V13h7Zm-5-2h3V15H19Z"
                    transform="translate(0 0)"
                  />
                  <path
                    d="M15,11H8V4h7ZM10,9h3V6H10Z"
                    transform="translate(0 0)"
                  />
                  <path
                    d="M24,11H17V4h7ZM19,9h3V6H19Z"
                    transform="translate(0 0)"
                  />
                </svg>
              </span>
            </div>
            <div className="flex-grow-1">
              <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-1" id="showHost">
                    <a
                      className="text-gray-800 text-hover-primary fs-2 fw-bolder me-3"
                     
                    >
                      {blockData.name}
                    </a>
                    <a></a>
                  </div>
                  <div className="d-flex flex-wrap fw-bold mb-4 fs-5 text-gray-400">
                  <a 
                    className="d-flex align-items-center text-gray-400 me-3 mb-2"
                    >
                    {/*begin::Svg Icon | path: icons/duotune/communication/com006.svg*/}
                    <span className="svg-icon svg-icon-4 me-2 pb-1">
                    <img src={"/img/flag_" + blockData?.location?.dccc + ".png"} style={{ width: "16px" }} /> 
                    </span>
                    {/*end::Svg Icon*/}
                    {blockData?.location?.city}
                    </a> 
                  <span className="svg-icon svg-icon-muted svg-icon-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        opacity="0.3"
                        d="M5 8.04999L11.8 11.95V19.85L5 15.85V8.04999Z"
                        fill="black"
                      />
                      <path
                        d="M20.1 6.65L12.3 2.15C12 1.95 11.6 1.95 11.3 2.15L3.5 6.65C3.2 6.85 3 7.15 3 7.45V16.45C3 16.75 3.2 17.15 3.5 17.25L11.3 21.75C11.5 21.85 11.6 21.85 11.8 21.85C12 21.85 12.1 21.85 12.3 21.75L20.1 17.25C20.4 17.05 20.6 16.75 20.6 16.45V7.45C20.6 7.15 20.4 6.75 20.1 6.65ZM5 15.85V7.95L11.8 4.05L18.6 7.95L11.8 11.95V19.85L5 15.85Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  Size - &nbsp;
                  <span className="fw-bolder"> {parseInt(blockData?.size)} GB</span> &nbsp;
                  
                  {blockData?.cloudid!=0?(<>
                  
                  <span className="svg-icon svg-icon-muted svg-icon-2 ms-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        opacity="0.3"
                        d="M18.4 5.59998C18.7766 5.9772 18.9881 6.48846 18.9881 7.02148C18.9881 7.55451 18.7766 8.06577 18.4 8.44299L14.843 12C14.466 12.377 13.9547 12.5887 13.4215 12.5887C12.8883 12.5887 12.377 12.377 12 12C11.623 11.623 11.4112 11.1117 11.4112 10.5785C11.4112 10.0453 11.623 9.53399 12 9.15698L15.553 5.604C15.9302 5.22741 16.4415 5.01587 16.9745 5.01587C17.5075 5.01587 18.0188 5.22741 18.396 5.604L18.4 5.59998ZM20.528 3.47205C20.0614 3.00535 19.5074 2.63503 18.8977 2.38245C18.288 2.12987 17.6344 1.99988 16.9745 1.99988C16.3145 1.99988 15.661 2.12987 15.0513 2.38245C14.4416 2.63503 13.8876 3.00535 13.421 3.47205L9.86801 7.02502C9.40136 7.49168 9.03118 8.04568 8.77863 8.6554C8.52608 9.26511 8.39609 9.91855 8.39609 10.5785C8.39609 11.2384 8.52608 11.8919 8.77863 12.5016C9.03118 13.1113 9.40136 13.6653 9.86801 14.132C10.3347 14.5986 10.8886 14.9688 11.4984 15.2213C12.1081 15.4739 12.7616 15.6039 13.4215 15.6039C14.0815 15.6039 14.7349 15.4739 15.3446 15.2213C15.9543 14.9688 16.5084 14.5986 16.975 14.132L20.528 10.579C20.9947 10.1124 21.3649 9.55844 21.6175 8.94873C21.8701 8.33902 22.0001 7.68547 22.0001 7.02551C22.0001 6.36555 21.8701 5.71201 21.6175 5.10229C21.3649 4.49258 20.9947 3.93867 20.528 3.47205Z"
                        fill="black"
                      />
                      <path
                        d="M14.132 9.86804C13.6421 9.37931 13.0561 8.99749 12.411 8.74695L12 9.15698C11.6234 9.53421 11.4119 10.0455 11.4119 10.5785C11.4119 11.1115 11.6234 11.6228 12 12C12.3766 12.3772 12.5881 12.8885 12.5881 13.4215C12.5881 13.9545 12.3766 14.4658 12 14.843L8.44699 18.396C8.06999 18.773 7.55868 18.9849 7.02551 18.9849C6.49235 18.9849 5.98101 18.773 5.604 18.396C5.227 18.019 5.0152 17.5077 5.0152 16.9745C5.0152 16.4413 5.227 15.93 5.604 15.553L8.74701 12.411C8.28705 11.233 8.28705 9.92498 8.74701 8.74695C8.10159 8.99737 7.5152 9.37919 7.02499 9.86804L3.47198 13.421C2.52954 14.3635 2.00009 15.6417 2.00009 16.9745C2.00009 18.3073 2.52957 19.5855 3.47202 20.528C4.41446 21.4704 5.69269 21.9999 7.02551 21.9999C8.35833 21.9999 9.63656 21.4704 10.579 20.528L14.132 16.975C14.5987 16.5084 14.9689 15.9544 15.2215 15.3447C15.4741 14.735 15.6041 14.0815 15.6041 13.4215C15.6041 12.7615 15.4741 12.108 15.2215 11.4983C14.9689 10.8886 14.5987 10.3347 14.132 9.86804Z"
                        fill="black"
                      />
                    </svg>
                  </span> 
                  Linked to - &nbsp;
                    <Link to={"/"+blockData.cloudid}><span className="fw-bolder"> cloud-{blockData.cloudid}</span></Link>
                    </>):('')}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="d-flex flex-stack mb-5"> 
                    {/*begin::Label*/}
                    {blockData.status=="Active"?(<><div className="btn btn-sm btn-success fw-bold ms-5">Active</div></>):
                    (<><div className="btn btn-sm btn-danger fw-bold ms-5">Inactive</div></>)}
                    
                    {/*end::Label*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="separator" />
          <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-trakt_charts_widget_1_chartnsparent fs-5 fw-bolder">
            <li className="nav-item">
              <a
                className="nav-link text-active-primary py-5 me-6 active sphide"
                data-bs-toggle="tab"
                href="#connection-details"
              >
                Configuration
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link text-active-primary py-5 me-6 d sphide"
                data-bs-toggle="tab"
                href="#access-control"
              >
                Resize
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link text-active-primary py-5 me-6"
                data-bs-toggle="tab"
                href="#backups"
              >
                Attach / Dettach
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link text-active-primary py-5 me-6"
                data-bs-toggle="tab"
                href="#destroy"
              >
                Destroy
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="tab-content" id="myTabContent">
        <Configuration />
        <Resize data={blockData} updateBlock={getBlockStorage} />
         <Attach data={blockData} updateBlock={getBlockStorage} />
        <div className="tab-pane fade" id="destroy" role="tabpanel">
          <div className="card">
            <div
              className="card-header border-0 cursor-pointer"
              role="button"
              data-bs-toggle="collapse"
              data-bs-target="#kt_account_deactivate"
              aria-expanded="true"
              aria-controls="kt_account_deactivate"
            >
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">Destroy Cluster</h3>
              </div>
            </div>
            <div id="kt_account_settings_deactivate" className="collapse show">
              <form id="destroyform" className="form">
                <div className="card-body border-top p-9">
                  <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
                    <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={10}
                          fill="black"
                        />
                        <rect
                          x={11}
                          y={14}
                          width={7}
                          height={2}
                          rx={1}
                          transform="rotate(-90 11 14)"
                          fill="black"
                        />
                        <rect
                          x={11}
                          y={17}
                          width={2}
                          height={2}
                          rx={1}
                          transform="rotate(-90 11 17)"
                          fill="black"
                        />
                      </svg>
                    </span>
                    <div className="d-flex flex-stack flex-grow-1">
                      <div className="fw-bold">
                        <h4 className="text-gray-900 fw-bold">
                          This will destroy your Block Storage.
                        </h4>
                        <div className="fs-6 text-gray-700">
                          Note: This option will delete your block storage from the
                          server for permanently and this not be able to undo.
                        </div>
                      </div>
                    </div>
                  </div> 
                </div>
                <div
                  className="card-footer d-flex justify-content-end py-6 px-9"
                  id="destroy_btn"
                >
                  <button
                    id="destroy-btn"
                    type="button"
                    onClick={deleteBlock}
                    className="btn btn-danger fw-bold"
                  >
                    Destroy
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      
      </>);
      
    }else{
      return(<>
      <div>
        <TableLoader/>
        <TopBarHeaderLine/>
      {/* <div class="loading-spinner2"></div> */}
      </div>
      </>);
    }  
}
