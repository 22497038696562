import {React} from 'react';

export default function Exp3(){
    return(
        <>  
        <div
          style={{
            fontFamily: "Arial,Helvetica,sans-serif",
            lineHeight: "1.5",
            fontWeight: "normal",
            fontSize: 15,
            color: "#2F3044",
            minHeight: "100%",
            margin: 0,
            padding: 0,
            width: "100%",
            backgroundColor: "#edf2f7"
          }}
        >
  <table
    border={0}
    cellPadding={0}
    cellSpacing={0}
    width="100%"
    style={{ tableLayout: "fixed", backgroundColor: "#f9f9f9" }}
    id="bodyTable"
  >
    <tbody>
      <tr>
        <td
          style={{ paddingRight: 10, paddingLeft: 10 }}
          align="center"
          valign="top"
          id="bodyCell"
        >
          
          <table
            border={0}
            cellPadding={0}
            cellSpacing={0}
            width="100%"
            className="wrapperBody"
            style={{ maxWidth: 600 }}
          >
            <tbody>
              <tr>
                <td align="center" valign="top">
                  <table
                    border={0}
                    cellPadding={0}
                    cellSpacing={0}
                    width="100%"
                    className="tableCard"
                    style={{
                      backgroundColor: "#fff",
                      borderColor: "#e5e5e5",
                      borderStyle: "solid",
                      borderWidth: "0 1px 1px 1px"
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          style={{
                            backgroundColor: "#00d2f4",
                            fontSize: 1
                          }}
                          className="topBorder"
                          height={3}
                        >
                          &nbsp;
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ paddingTop: 60, paddingBottom: 20 }}
                          align="center"
                          valign="middle"
                          className="emailLogo"
                        >
                          <a
                            href="#"
                            style={{ textDecoration: "none" }}
                            target="_blank"
                          >
                            <img
                              alt=""
                              border={0}
                              src="http://email.aumfusion.com/vespro/img/hero-img/blue/logo.png"
                              style={{
                                width: "100%",
                                maxWidth: 150,
                                height: "auto",
                                display: "block"
                              }}
                              width={150}
                            />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ paddingBottom: 20 }}
                          align="center"
                          valign="top"
                          className="imgHero"
                        >
                          <a
                            href="#"
                            style={{ textDecoration: "none" }}
                            target="_blank"
                          >
                            <img
                              alt=""
                              border={0}
                              src="http://email.aumfusion.com/vespro/img/hero-img/blue/heroGradient/user-account.png"
                              style={{
                                width: "100%",
                                maxWidth: 600,
                                height: "auto",
                                display: "block",
                                color: "#f9f9f9"
                              }}
                              width={600}
                            />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            paddingBottom: 5,
                            paddingLeft: 20,
                            paddingRight: 20
                          }}
                          align="center"
                          valign="top"
                          className="mainTitle"
                        >
                          <h2
                            className="text"
                            style={{
                              color: "#000",
                              fontFamily: "Poppins,Helvetica,Arial,sans-serif",
                              fontSize: 28,
                              fontWeight: 500,
                              lineHeight: 3,
                              fontStyle: "normal",
                              letterSpacing: "normal", 
                              textTransform: "none",
                              textAlign: "center",
                              padding: 0,
                              margin: 0
                            }}
                          >
                            Hi "John Doe"
                          </h2>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            paddingBottom: 30,
                            paddingLeft: 20,
                            paddingRight: 20
                          }}
                          align="center"
                          valign="top"
                          className="subTitle"
                        >
                          <h4
                            className="text"
                            style={{
                              color: "#999",
                              fontFamily: "Poppins,Helvetica,Arial,sans-serif",
                              fontSize: 16,
                              fontWeight: 500,
                              fontStyle: "normal",
                              letterSpacing: "normal", 
                              textTransform: "none",
                              textAlign: "center",
                              padding: 0,
                              margin: 0
                            }}
                          >
                            Verify Your Email Account
                          </h4>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ paddingLeft: 20, paddingRight: 20 }}
                          align="center"
                          valign="top"
                          className="containtTable ui-sortable"
                        >
                          <table
                            border={0}
                            cellPadding={0}
                            cellSpacing={0}
                            width="100%"
                            className="tableDescription"
                            style={{}}
                          >
                            <tbody>
                              <tr>
                                <td
                                  style={{ paddingBottom: 20 }}
                                  align="center"
                                  valign="top"
                                  className="description"
                                >
                                  <p
                                    className="text"
                                    style={{
                                      color: "#666",
                                      fontFamily:
                                        '"Open Sans",Helvetica,Arial,sans-serif',
                                      fontSize: 14,
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      letterSpacing: "normal", 
                                      textTransform: "none",
                                      textAlign: "center",
                                      padding: 0,
                                      margin: 0
                                    }}
                                  >
                                    Thanks for subscribe for the Vespro
                                    newsletter. Please click confirm button for
                                    subscription to start receiving our emails.
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table
                            border={0}
                            cellPadding={0}
                            cellSpacing={0}
                            width="100%"
                            className="tableButton"
                            style={{}}
                          >
                            <tbody>
                              <tr>
                                <td
                                  style={{ paddingTop: 20, paddingBottom: 20 }}
                                  align="center"
                                  valign="top"
                                >
                                  <table
                                    border={0}
                                    cellPadding={0}
                                    cellSpacing={0}
                                    align="center"
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          style={{
                                            backgroundColor: "rgb(0, 210, 244)",
                                            padding: "12px 35px",
                                            borderRadius: 50
                                          }}
                                          align="center"
                                          className="ctaButton"
                                        >
                                          {" "}
                                          <a
                                            href="#"
                                            style={{
                                              color: "#fff",
                                              fontFamily:
                                                "Poppins,Helvetica,Arial,sans-serif",
                                              fontSize: 13,
                                              fontWeight: 600,
                                              fontStyle: "normal",
                                              letterSpacing: 1, 
                                              textTransform: "uppercase",
                                              textDecoration: "none",
                                              display: "block"
                                            }}
                                            target="_blank"
                                            className="text"
                                          >
                                            Confirm Email
                                          </a>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: 1, lineHeight: 1 }} height={20}>
                          &nbsp;
                        </td>
                      </tr> 
                    </tbody>
                  </table> 
                </td>
              </tr>
            </tbody>
          </table>
          
        </td>
      </tr>
    </tbody>
  </table>
  </div>
</>

    )
}