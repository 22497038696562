/* eslint-disable no-redeclare */
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom/dist";
import UsePost from "../../../../CustomHooks/usePost";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UseApi from "../../../../CustomHooks/useApi";
import { connect, useDispatch } from "react-redux";
import { DeployAutoScalingInfo, DeployNodePoolRow } from "../../../../Redux/Services/Action/actions";
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../Loaders/TableLoader";
import ReactDatePicker from "react-datepicker";
import StaticKubernetes from "./deployComponents/StaticKubernetes";
import KubernetesInstance from "./deployComponents/KubernetesInstance";
import KubernetesPolicy from "./deployComponents/KubernetesPolicy";
import KubernetesSchedule from "./deployComponents/KubernetesSchedule";
import UseCallApi from "../../../../CustomHooks/useCallApi";
import CpuModelTab from "../../../../CustomHooks/CpuModelTab";
import { publicIp, publicIpv4, publicIpv6 } from 'public-ip';
import Vpc from "../../utils/vpc/Vpc";
import Firewall from "../../utils/firewall/Firewall";
import Dczone from "../../utils/dczone/Dczone"; 
import NetworkType from "./tab/NetworkType";
var serialize = require("form-serialize");

function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue(value => value + 1);
}

const KubernetesDeploy = (props) => {
  const [pageStatus, setPageStatus] = useState(false)
  const [firewallData, setFirewallData] = useState(null);
  const [firewallLoader, setFirewallLoader] = useState(true)
  const [arrayStatus, setArrayStatus] = useState([])
  const [securityGroups, setSecurityGroups] = useState([])
  const [distroData, setDistroData] = useState(null);
  const [marginBottom, setMarginBottom] = useState(window.innerWidth >= 768 ? '' : '5.5rem');
  const [width, setWidth] = useState(window.innerWidth >= 768 ? '50%' : '80%');
  // const [poolType,setPoolType] = useState("Static Node Pool")
  const [autoScalingLoader, setAutoScalingLoader] = useState(false)
  const [vpcMultipleInfo, setVpcMultipleInfo] = useState([]);
  const [userData, setUserData] = useState(null);
  const [vpcData, setVpcData] = useState(null);
  const [loadBalancers, setLoadBalancers] = useState([])
  const [recommendedCpu, setRecommendedCpu] = useState('')
  const [targetGroups, setTargetGroups] = useState([])
  const [cpuValue, setCpuValue] = useState("amd")
  const [vpcId, setVpcId] = useState('')
  const [k8sVersions, setK8sVersions] = useState(['1.27.13-utho', '1.28.9-utho', '1.29.4-utho', '1.30.0-utho']);
  const makeid = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters?.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  // console.log(props);
  const forceUpdate = useForceUpdate();
  const [adcdata, setAdcdata] = useState([]);
  const [ddcdata, setDdcdata] = useState([]);
  const [vpcLoader, setVpcLoader] = useState(false);
  const dataFetchedRef = useRef();
  const [location, setLocation] = useState("innoida")
  const [loadbalancerData, setLoadbalancerData] = useState([]);
  const dataFetchedRef2 = useRef();
  const [asData, setAsData] = useState(null);
  const [planData, setPlanData] = useState(null);
  const dispatch = useDispatch();
  const user_data = useRef(null);
  const [poolData, setPoolData] = useState(null);
  const rowCount = useRef(0);
  let navigate = useNavigate();
  const getDczone = async () => {
    // setAdcdata([]);
    // setDdcdata([]);
    const data = await UseApi('get', 'cloud/getdeploy', '');
    console.log(data,"dczone");
    
    if (Object.keys(data?.dczones)?.length === 0) {
      setAdcdata([]);
      setDdcdata([]);
    } else {
      data?.dczones.map(async (value, index) => {
        console.log(value);
        if (value.status != 'active' || value.cc == "de") {
          var server_ip = await publicIpv4();
          if (server_ip) {
            if (server_ip !== '103.209.144.51' || server_ip !== '103.209.144.66' || server_ip !==
              '103.209.145.208' || server_ip !== '103.209.144.81' || server_ip !== '103.209.144.82' ||
              server_ip !== '103.209.144.83' || server_ip !== '103.127.30.91' || server_ip !==
              '103.209.144.60' || server_ip !== '103.127.30.239' || server_ip !== '103.127.28.52') {
              ddcdata.push(value);
            }
          }
          // var client=await fetch(`https://geolocation-db.com/json/`)
          // .then(res => res.json()); 
          // var server_ip=client.IPv4;
          // if(server_ip !== '103.209.144.51' || server_ip !== '103.209.144.66' || server_ip !==
          //   '103.209.145.208' || server_ip !== '103.209.144.81' || server_ip !== '103.209.144.82' ||
          //   server_ip !== '103.209.144.83' || server_ip !== '103.127.30.91' || server_ip !==
          //   '103.209.144.60' || server_ip !== '103.127.30.239' || server_ip !== '103.127.28.52'){
          //     ddcdata.push(value); 
          //   } 
          forceUpdate();
        } else {
          adcdata.push(value);
          forceUpdate();
        }
      })
    }
  }
  const getAutoScaling = async () => {
    const dt = toast;
    const data = await UseApi("get", "autoscaling", "");
    if (data) {
      if (data.rcode == "error") {
        setAsData([]);
        // loaderFunc();
        return;
      } else {
        setAutoScalingLoader(true)
        var groupdata = data.groups;
        console.log(groupdata, "groupsData");
        setAsData(groupdata.reverse());
      }
    }

  };
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getDczone();
    getAutoScaling()
  }, []);


  useEffect(() => {
    const handleResize = () => {
      setMarginBottom(window.innerWidth >= 768 ? '' : '5.5rem');
      setWidth(window.innerWidth >= 768 ? '50%' : '80%')
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getPlans = async () => {
    const data = await UseApi('get', 'cloud/getdeploy', '');
    user_data.current = await UseApi('get', 'account/info', '');
    // ////console.log(user_data.current);
    if (Object.keys(data)?.length === 0) {
      setPlanData(null);
    } else {
      setPlanData(data.plans);
      // ////console.log(data.plans);
    }
    const tempArray = new Array(props.nodePoolData?.length).fill(false);
    setArrayStatus(tempArray)
    console.log(props.nodePoolData);
    setPoolData(props.nodePoolData);
    //console.log(props.nodePoolData);
    setTimeout(() => {
      calcTotalCost();
    }, 100)
  }

  useEffect(() => {
    if (dataFetchedRef2.current) return;
    dataFetchedRef2.current = true;
    getPlans();
    getDistro()
    // getVpcDataByLocation()
    setTimeout(() => {
      selectDc('innoida');
    }, 1000);
  }, [poolData]);

  const openDrawer = (datas) => {
    var drawerElement = document.querySelector("#kubernetes-modal");
    var drawer_overlay = document.querySelector("#drawer_overlay");
    if (drawerElement && drawer_overlay) {
      drawerElement.style.width = width;
      if (datas == 'close') {
        drawerElement.classList.remove("drawer-on");
        drawer_overlay.classList.add("d-none");
      } else {
        drawerElement.classList.add("drawer-on");
        drawer_overlay.classList.remove("d-none");
        const node_plan_pool_plan_id = document.querySelector("#node_plan_pool_plan_id");
        node_plan_pool_plan_id.value = datas;
      }
    }
  }



  const updatePool = (pid) => {
    const update_pool_yes = document.querySelector('#update_pool_yes_' + pid);
    const update_pool_no = document.querySelector('#update_pool_no_' + pid);
    const update_pool = document.querySelector('#update_pool_' + pid);
    update_pool.classList.toggle('d-none');
    update_pool_yes.classList.toggle('d-none');
    update_pool_no.classList.toggle('d-none');

  }

  const selectNodeSize = (pid, ram, cpu, disk, bandwidth, price, currencyprefix) => {
    const node_plan_pool_plan_id = document.querySelector("#node_plan_pool_plan_id");
    const poolId = node_plan_pool_plan_id.value;
    console.log(node_plan_pool_plan_id.value, "node_plan_pool_plan_id", "bandwidth_" + poolId);
    const show_select_plans = document.querySelector("#show_select_plans_" + poolId);
    const select_kubernetes_plans = document.querySelector("#select_kubernetes_plans_" + poolId);
    const select_ram = document.querySelector("#ram_" + poolId);
    const select_cpu = document.querySelector("#cpu_" + poolId);
    const select_bandwidth = document.querySelector("#bandwidth_" + poolId);
    const node_select_plan = document.querySelector("#node_select_plan_" + poolId);
    const order_total = document.querySelector("#order_total_" + poolId);
    const currency_ = document.querySelector("#currency_final");
    const billing_ = document.querySelector("#billing_final");
    const node_count = document.querySelector("#desiredcount_" + poolId);
    const plan_price = document.querySelector("#plan_price_" + poolId);
    const desired_upCountBtn = document.querySelector(`#desiredupcount_${poolId}`);
    console.log(select_bandwidth, select_ram, select_cpu, node_count);
    if (node_count) {
      node_count.value = 1
      var total_plan_price = (parseFloat(price) * parseInt(node_count.value) * 720).toFixed(2);
      plan_price.value = (parseFloat(price) * 720).toFixed(2);
      if (user_data.current != null) {
        if (currency_ && order_total && billing_) {
          currency_.innerHTML = user_data.current.user.currencyprefix;
          order_total.innerHTML = `${currencyprefix}  ${total_plan_price}/mon`;
          billing_.innerHTML = "/mon";
        }
      }
      node_select_plan.value = pid;
      show_select_plans.classList.remove('d-none');
      select_kubernetes_plans.classList.add('d-none');
      select_ram.innerHTML = ram;
      select_cpu.innerHTML = cpu;
      select_bandwidth.innerHTML = bandwidth;
      if (show_select_plans.classList.contains('d-none')) {
        desired_upCountBtn.classList.add('disabled')
      } else {
        desired_upCountBtn.classList.remove('disabled')
      }
    }
    openDrawer('close');
    // var drawerElement = document.querySelector("#kubernetes-modal");
    // if(drawerElement){
    //     drawerElement.classList.remove("drawer-on");
    // }
    // if (poolData) {
    //   for (var i = 0; i < poolData?.length; i++) {
    //     if (poolData[i]) {
    //       if (node_plan_pool_plan_id) {
    //         if (poolData[i].pool_id == node_plan_pool_plan_id.value) {
    //           poolData[i].totalCost = (parseFloat(price) * 720).toFixed(2);
    //           poolData[i].nodeSize = pid;
    //         }

    //       }
    //     }
    //   }
    // }
    if (poolData) {
      const updatedPoolData = poolData.map(item => {
        if (item && node_plan_pool_plan_id && item.pool_id == node_plan_pool_plan_id.value) {
          return {
            ...item,
            totalCost: (parseFloat(price) * 720).toFixed(2),
            nodeSize: pid,
          };
        }
        return item;
      });
      setPoolData((prevPoolData) => {
        return [...updatedPoolData];
      });
    }
    calcTotalCost();
  }

  function calcTotalCost() {
    var nodePoolCost = document.querySelectorAll(".nodePoolCost");
    console.log(nodePoolCost);
    var finalCost = 0;
    nodePoolCost.forEach((ncost) => {
      var currCost = ncost.innerHTML;
      const match = currCost.match(/Rs\.(.*?)\/mon/);
      currCost = match ? match[1] : null;
      if (currCost) {
        finalCost += parseInt(currCost);
      }
    });
    var total_cost = document.querySelector("#total_cost");
    const currency_ = document.querySelector("#currency_final");
    const billing_ = document.querySelector("#billing_final");
    if (user_data.current != null) {
      if (total_cost && currency_ && billing_) {
        currency_.innerHTML = user_data.current.user.currencyprefix;
        total_cost.innerHTML = finalCost.toFixed(0);
        billing_.innerHTML = "/mon";
      }
    }
  }

  const nodePoolUpdate = (npid) => {
    const pool_name = document.querySelector(`#pool_name_${npid}`);
    const node_select_plan = document.querySelector(`#node_select_plan_${npid}`);
    const desired_node_count = document.querySelector(`#desiredcount_${npid}`);
    const max_node_count = document.querySelector(`#maxcount_${npid}`);
    const plan_price = document.querySelector(`#plan_price_${npid}`);
    const node_plan_pool_plan_id = document.querySelector("#node_plan_pool_plan_id");
    const updatedNodePoolData = poolData.map((node) => {
      console.log(node.pool_id, node_plan_pool_plan_id.value);
      // if (node.pool_id == node_plan_pool_plan_id.value) {
      if (node.pool_id == npid) {
        return {
          ...node,
          nodePoolValue: pool_name.value,
          nodeSize: node_select_plan.value,
          desiredCount: desired_node_count.value,
          maxCount: max_node_count.value,
          totalCost: plan_price.value,
        };
      }
      return node;
    });
    setPoolData(updatedNodePoolData)
    calcTotalCost();
  }

  const nodePoolUpdateInstance = (npid) => {
    const min_size = document.querySelector(`#min_size${npid}`);
    const max_size = document.querySelector(`#max_size${npid}`);
    const desired_size = document.querySelector(`#desired_size${npid}`);
    // const node_plan_pool_plan_id = document.querySelector("#node_plan_pool_plan_id");

    for (var i = 0; i < props.nodePoolData?.length; i++) {
      if (props.nodePoolData[i]) {
        // if (node_plan_pool_plan_id) {
        // if (props.nodePoolData[i].pool_id == node_plan_pool_plan_id.value) {
        props.nodePoolData[i].minSize = min_size.value;
        props.nodePoolData[i].maxSize = max_size.value;
        props.nodePoolData[i].desiredSize = desired_size.value;
        // }
        // }
      }
    }
    calcTotalCost();
  }

  const nodePoolUpdatePolicy = (npid, fieldName) => {
    console.log(npid);
    const policy_name = document.querySelector(`#policy_name${npid}`);
    const policy_type = document.querySelector(`#policy_type${npid}`);
    const above_value = document.querySelector(`#above_value${npid}`);
    const below_value = document.querySelector(`#below_value${npid}`);
    const policy_adjust = document.querySelector(`#policy_adjust${npid}`);
    const policy_period = document.querySelector(`#policy_period${npid}`);
    const policy_cooldown = document.querySelector(`#policy_cooldown${npid}`);
    // const node_plan_pool_plan_id = document.querySelector("#node_plan_pool_plan_id");
    if (parseFloat(above_value.value) <= 100 && parseFloat(below_value.value) <= 100) {
      for (var i = 0; i < props.nodePoolData?.length; i++) {
        if (props.nodePoolData[i]) {
          // if (node_plan_pool_plan_id) {
          // if (props.nodePoolData[i].pool_id == node_plan_pool_plan_id.value) {
          props.nodePoolData[i].policyName = policy_name.value;
          props.nodePoolData[i].policyType = policy_type.value;
          props.nodePoolData[i].aboveValue = above_value.value;
          props.nodePoolData[i].belowValue = below_value.value;
          props.nodePoolData[i].policyAdjust = policy_adjust.value;
          props.nodePoolData[i].policyPeriod = policy_period.value;
          props.nodePoolData[i].policyCooldown = policy_cooldown.value;
          //   }
          // }
        }
      }
    }
    calcTotalCost();
  }


  const handleDeletePolicy = (poolIndex, policyIndex) => {
    setPoolData(prevPoolData => {
      const updatedPoolData = [...prevPoolData];
      updatedPoolData[poolIndex].policies.splice(policyIndex, 1);
      return updatedPoolData;
    });
  };

  const addFormField = () => {
    rowCount.current += 1;
    var newRow = { pool_id: rowCount.current, nodePoolValue: 'pool-' + makeid(8), nodeSize: 0, desiredCount: 1, maxCount: 1, totalCost: 0.00, policies: [{ policyName: 'policy-' + makeid(8), policyType: "cpu", policyCompare: "isabove", policyCompareValue: "", policyAdjust: "1", policyPeriod: "5m", policyCooldown: "300", minSize: "1", maxSize: "5", desiredSize: "1", aboveValue: 60, belowValue: 20 }] };
    setPoolData((old) => [...old, newRow]);
    setArrayStatus((old => [...old, false]))
    calcTotalCost();
  }
  const deleteFormField = (frid, indexToRemove) => {
    setPoolData((old) => {
      const updatedState = old.filter(item => item.pool_id !== frid);
      return [...updatedState];
    });
    if (indexToRemove >= 0 && indexToRemove < arrayStatus?.length) {
      arrayStatus.splice(indexToRemove, 1); // Removes one element at the specified index
      setArrayStatus((arrayStatus) => {
        return [...arrayStatus];
      });
    }
    // getPlans()
    calcTotalCost();
  }

  const handleAddPolicy = (poolIndex) => {
    console.log(poolIndex);
    let policy_name = 'policy-' + makeid(8)
    const newPolicy = {
      policyName: policy_name,
      policyType: 'cpu',
      aboveValue: '60',
      belowValue: '20',
      policyAdjust: '1',
      policyPeriod: '5m',
      policyCooldown: '300',
      minSize: '1',
      maxSize: '5',
      desiredSize: '1'
    }

    setPoolData((prevPoolData) => {
      const updatedPoolData = [...prevPoolData]
      updatedPoolData[poolIndex].policies.push(newPolicy)
      return updatedPoolData
    })
    // const newPolicyElement = document.getElementById(`policy_card_${policy_name}`);

    // // Scroll to the newly added policy element
    // if (newPolicyElement) {
    //   newPolicyElement.scrollIntoView({ behavior: 'smooth' });
    // }
  }

  const nodePoolCount = (npid, status) => {
    const error_message = document.querySelector(`#error_message_${npid}`)
    const desired_node_count = document.querySelector(`#desiredcount_${npid}`);
    const desired_downCountBtn = document.querySelector(`#desireddowncount_${npid}`);
    const desired_upCountBtn = document.querySelector(`#desiredupcount_${npid}`);
    const max_node_count = document.querySelector(`#maxcount_${npid}`);
    const max_downCountBtn = document.querySelector(`#maxdowncount_${npid}`);
    const max_upCountBtn = document.querySelector(`#maxupcount_${npid}`);
    const order_total = document.querySelector(`#order_total_${npid}`);
    const currency_ = document.querySelector("#currency_final");
    const currencyText = currency_.textContent;
    const billing_ = document.querySelector("#billing_final");
    const plan_price = document.querySelector(`#plan_price_${npid}`);

    const increaseDesiredCount = () => {
      desired_downCountBtn.classList.remove('disabled');
      console.log(parseInt(max_node_count.value), parseInt(desired_node_count.value));
      if (parseInt(desired_node_count.value) < parseInt(max_node_count.value)) {
        desired_node_count.value = parseInt(desired_node_count.value) + 1;
        updateDesiredNodeCount(npid);
      } else {
        error_message.classList.remove('d-none')
        desired_upCountBtn.classList.add('disabled');
      }
    };

    const decreaseDesiredCount = () => {
      error_message.classList.add('d-none')
      desired_upCountBtn.classList.remove('disabled');
      if (parseInt(desired_node_count.value) > 1) {
        desired_node_count.value = parseInt(desired_node_count.value) - 1;
        updateDesiredNodeCount(npid);
      } else {
        desired_downCountBtn.classList.add('disabled');
      }
    };

    // const updateNodeCount = (npid) => {
    //   for (var i = 0; i < poolData?.length; i++) {
    //     if (poolData[i] && poolData[i].pool_id == npid) {
    //       poolData[i].desiredCount = parseInt(desired_node_count.value);
    //     }
    //   }
    // };

    const increaseMaxCount = () => {
      error_message.classList.add('d-none')
      max_downCountBtn.classList.remove('disabled');
      desired_upCountBtn.classList.remove('disabled');
      desired_downCountBtn.classList.remove('disabled')
      // if (parseInt(max_node_count.value) < parseInt(max_node_count.value)) {
      max_node_count.value = parseInt(max_node_count.value) + 1;
      updateMaxNodeCount(npid);
      // } else {
      //   max_upCountBtn.classList.add('disabled');
      // }
    };

    const decreaseMaxCount = () => {
      error_message.classList.add('d-none')
      max_upCountBtn.classList.remove('disabled');
      // desired_downCountBtn
      if (parseInt(max_node_count.value) > parseInt(desired_node_count.value)) {
        max_node_count.value = parseInt(max_node_count.value) - 1;
        updateMaxNodeCount(npid);
      } else {
        desired_downCountBtn.classList.remove('disabled');
      }
    };
    const updateMaxNodeCount = (npid) => {
      for (var i = 0; i < poolData?.length; i++) {
        if (poolData[i] && poolData[i].pool_id == npid) {
          poolData[i].maxCount = parseInt(max_node_count.value);
        }
      }
    };
    const updateDesiredNodeCount = (npid) => {
      for (var i = 0; i < poolData?.length; i++) {
        if (poolData[i] && poolData[i].pool_id == npid) {
          poolData[i].desiredCount = parseInt(desired_node_count.value);
        }
      }
    };


    const updateTotalPlanPrice = () => {
      const total_plan_price = (parseFloat(plan_price.value) * parseInt(desired_node_count.value)).toFixed(2);
      if (user_data.current != null && currency_ && order_total && billing_) {
        currency_.innerHTML = user_data.current.user.currencyprefix;
        order_total.innerHTML = `${currencyText}${total_plan_price}/mon`;
        billing_.innerHTML = "/mon";
      }
    };

    if (status == 'desired_up') {
      increaseDesiredCount();
    } else if (status == 'desired_down') {
      decreaseDesiredCount();
    } else if (status == 'max_up') {
      increaseMaxCount();
    } else if (status == 'max_down') {
      decreaseMaxCount();
    }

    updateTotalPlanPrice();
    calcTotalCost();
  };

  const staticNodePoolCount = (npid, status) => {
    const desired_node_count = document.querySelector(`#desiredcount_${npid}`);
    const desired_downCountBtn = document.querySelector(`#desireddowncount_${npid}`);
    const desired_upCountBtn = document.querySelector(`#desiredupcount_${npid}`);
    // const max_node_count = document.querySelector(`#maxcount_${npid}`);
    // const max_downCountBtn = document.querySelector(`#maxdowncount_${npid}`);
    // const max_upCountBtn = document.querySelector(`#maxupcount_${npid}`);
    const order_total = document.querySelector(`#order_total_${npid}`);
    const currency_ = document.querySelector("#currency_final");
    const currencyText = currency_.textContent;
    const billing_ = document.querySelector("#billing_final");
    const plan_price = document.querySelector(`#plan_price_${npid}`);

    const increaseDesiredCount = () => {
      desired_downCountBtn.classList.remove('disabled');
      // console.log(parseInt(max_node_count.value), parseInt(desired_node_count.value));
      if (parseInt(desired_node_count.value) < 5) {
        desired_node_count.value = parseInt(desired_node_count.value) + 1;
        updateDesiredNodeCount(npid);
      } else {
        desired_upCountBtn.classList.add('disabled');
      }
    };

    const decreaseDesiredCount = () => {
      desired_upCountBtn.classList.remove('disabled');
      if (parseInt(desired_node_count.value) > 1) {
        desired_node_count.value = parseInt(desired_node_count.value) - 1;
        updateDesiredNodeCount(npid);
      } else {
        desired_downCountBtn.classList.add('disabled');
      }
    };

    // const updateNodeCount = (npid) => {
    //   for (var i = 0; i < poolData?.length; i++) {
    //     if (poolData[i] && poolData[i].pool_id == npid) {
    //       poolData[i].desiredCount = parseInt(desired_node_count.value);
    //     }
    //   }
    // };

    // const increaseMaxCount = () => {
    //   max_downCountBtn.classList.remove('disabled');
    //   desired_upCountBtn.classList.remove('disabled');
    //   desired_downCountBtn.classList.remove('disabled')
    //   // if (parseInt(max_node_count.value) < parseInt(max_node_count.value)) {
    //   max_node_count.value = parseInt(max_node_count.value) + 1;
    //   updateMaxNodeCount(npid);
    //   // } else {
    //   //   max_upCountBtn.classList.add('disabled');
    //   // }
    // };

    // const decreaseMaxCount = () => {
    //   max_upCountBtn.classList.remove('disabled');
    //   if (parseInt(max_node_count.value) > parseInt(desired_node_count.value)) {
    //     max_node_count.value = parseInt(max_node_count.value) - 1;
    //     updateMaxNodeCount(npid);
    //   } else {
    //     desired_downCountBtn.classList.add('disabled');
    //   }
    // };
    // const updateMaxNodeCount = (npid) => {
    //   for (var i = 0; i < poolData?.length; i++) {
    //     if (poolData[i] && poolData[i].pool_id == npid) {
    //       poolData[i].maxCount = parseInt(max_node_count.value);
    //     }
    //   }
    // };
    const updateDesiredNodeCount = (npid) => {
      for (var i = 0; i < poolData?.length; i++) {
        if (poolData[i] && poolData[i].pool_id == npid) {
          poolData[i].desiredCount = parseInt(desired_node_count.value);
        }
      }
    };


    const updateTotalPlanPrice = () => {
      const total_plan_price = (parseFloat(plan_price.value) * parseInt(desired_node_count.value)).toFixed(2);
      if (user_data.current != null && currency_ && order_total && billing_) {
        currency_.innerHTML = user_data.current.user.currencyprefix;
        order_total.innerHTML = `${currencyText}${total_plan_price}/mon`;
        billing_.innerHTML = "/mon";
      }
    };

    if (status == 'desired_up') {
      increaseDesiredCount();
    } else if (status == 'desired_down') {
      decreaseDesiredCount();
    } else if (status == 'max_up') {
      // increaseMaxCount();
    } else if (status == 'max_down') {
      // decreaseMaxCount();
    }

    updateTotalPlanPrice();
    calcTotalCost();
  };
  const checkObjectForEmpty = (requestData) => {
    console.log(requestData);
    console.log(requestData, "request");
    if (requestData.hasOwnProperty('vpc') && requestData.vpc.trim() !== '') {
      return true
      // console.log("VPC key is available and its value is not empty:", d/ata.vpc);
    } else {
      return false
      // console.log("VPC key is not available or its value is empty.");
    }

  }

  function validatePoolWorkerArray(data) {
    console.log(data);
    const errors = [];
    data.forEach((item, index) => {
      if (!item.hasOwnProperty('label') || !item.label) {
        console.log(item);
        errors.push(`poolName at index ${index}`);
      }
      if (!item.hasOwnProperty('size') || !item.size || parseFloat(item.size) <= 0) {
        console.log(item);
        errors.push(`size at index ${index}`);
      }
    })
    console.log(errors);
    // if (errors.length > 0) {
    //   throw new Error(`Please provide values for the following fields: ${errors?.join(', ')}`);
    // }
    return errors
  }

  const deployKubernetes = async () => {
    var submitBtn = document.querySelector("#deploykubernetes-btn");
    if (submitBtn) {
      toast.dismiss();
      const dt = toast.loading("Kubernetes deploying, Please wait...");
      var form = document.querySelector("#" + "deploykubernetes");
      var requestData = serialize(form, { hash: true });
      if (vpcData != null && vpcData?.length > 0) {
        const validatePoolWorker = validatePoolWorkerArray(requestData.nodepools);
        if (validatePoolWorker.length <= 0) {
          const validationError = checkObjectForEmpty(requestData);
          if (validationError) {
            setPageStatus(true)
            var preContent = submitBtn.innerHTML;
            submitBtn.classList.add("disabled");
            submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
            requestData.nodepools = requestData.nodepools.map(nodepool => {
              const { label, size, count, policyCooldown, policyPeriod, maxCount, policyName, policyType, policyAdjust, above_value, below_value } = nodepool;
              // // Create the policies array only if both below_value and above_value are present
              const baseData = {
                "label": label,
                "size": size,
                "count": count,
                "maxCount": maxCount
              };
              // let poolType;
              let policies;
              if (nodepool?.policies?.length > 0) {
                if (nodepool?.policies?.every(policy => policy.below_value !== undefined && policy.above_value !== undefined)) {
                  var pool_type = "autoscale";
                  policies = baseData.label && baseData.size && baseData.count && baseData.maxCount ? nodepool.policies.flatMap(policy => {
                    const policyAdjust = parseInt(policy.policyAdjust, 10);
                    const policyCooldown = parseInt(policy.policyCooldown, 10);
                    const policyPeriod = policy.policyPeriod;
                    const policyName = policy.policyName;
                    const policyType = policy.policyType;
                    const above_value = policy.above_value;
                    const below_value = policy.below_value;

                    return [
                      {
                        "adjust": policyAdjust,
                        "compare": "above",
                        "cooldown": policyCooldown,
                        "period": policyPeriod,
                        "name": policyName,
                        "type": policyType,
                        "value": above_value,
                        "product": "kubernetes_worker",
                        "maxsize": baseData.maxCount,
                        "minsize": baseData.count
                      },
                      {
                        "adjust": `-${policyAdjust}`,
                        "compare": "below",
                        "cooldown": policyCooldown,
                        "period": policyPeriod,
                        "name": policyName,
                        "type": policyType,
                        "value": below_value,
                        "product": "kubernetes_worker",
                        "maxsize": baseData.maxCount,
                        "minsize": baseData.count
                      }
                    ];
                  }) : undefined;
                  console.log(policies);
                  return { label, size, pool_type, maxCount, count, policies };

                }
              } else {
                var pool_type = "static";
                return { label, size, pool_type, count };
              }
            });
            // console.log(requestData);
            const responseData = await UseCallApi('post', 'kubernetes/deploy', requestData);
            if (responseData) {
              setPageStatus(false)
              submitBtn.classList.remove("disabled");
              submitBtn.innerHTML = preContent;
              if (responseData.status === 'error') {
                toast.update(dt, {
                  render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
                });
              } else if (responseData.status === 'success') {
                toast.update(dt, {
                  render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
                });
                window.onDeployEvents('deploy_kubernetes');
                setTimeout(() => {
                  navigate("/kubernetes/" + responseData.id);
                }, 1500)
              }
              selectTickMark(vpcId, "Vpc", "vpc")
            }
          } else {
            selectTickMark(vpcId, "Vpc", "vpc")
            setTimeout(() => {
              toast.update(dt, {
                render: "Please select vpc", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
              });
            }, 1000)
          }
        } else {
          setTimeout(() => {
            toast.update(dt, {
              render: `Please provide values for the following fields: ${validatePoolWorker?.join(', ')}`, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
          }, 1000)
        }
      } else {
        selectTickMark(vpcId, "Vpc", "vpc")
        setTimeout(() => {
          toast.update(dt, {
            render: "Vpc must required to deploy kubernetes for the selected dczone", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        }, 1000)
      }
    }
  }

  const getDistro = async () => {
    toast.dismiss();
    setDistroData(null);
    var dc = "innoida"
    const data = await UseApi("get", "cloud/getdeploy?dcslug=" + dc, "");
    user_data.current = await UseApi("get", "account/info", "");
    console.log(data);

    setUserData(user_data.current.user);
    if (data.firewalls?.length <= 0) {
      setFirewallData(null);
      setFirewallLoader(false)
    } else {
      // setVpcLoader(true)
      setFirewallLoader(false)
      setFirewallData(data.firewalls);
      getLoadBalancerTargetGroups();
      getLoadBalancer()
    }
  };

  const getLoadBalancer = async () => {
    const data = await UseApi('get', 'loadbalancer', '');
    if (data) {
      if (Array.isArray(data)) {
        if ('status' in data) {
          if (data.status == "error") {
            setLoadbalancerData([]);
            return;
          }
        }
      }
      if (Object.keys(data)?.length === 0) {
        setLoadbalancerData([]);
      } else {
        if (data.loadbalancers) {
          var loadbalancer_field = document.querySelector("#loadbalancer_field");
          if (loadbalancer_field) {
            //console.log("lb field");
            var ldata = [];
            var filterData = data.loadbalancers.filter((item) => {
              return item.type == "classic" || item.type == "network"
            })
            if (filterData) {
              filterData.map((value, index) => {
                ldata.push(value.name + " (" + value.id + ")");
              })
            }

            const contactTagify = new window.Tagify(loadbalancer_field, {
              whitelist: ldata,
              maxTags: 20,
              dropdown: {
                maxItems: 20,
                classname: "",
                enabled: 0,
                closeOnSelect: false
              }
            });
            setLoadbalancerData(filterData);
          } else {
            //console.log("no lb field");
          }
        }

      }
    }
  }
  const selectDc = async (slug) => {
    setLocation((old) => old = slug)
    getVpcDataByLocation(slug)
    getAllVpc(slug)
    getLoadBalancer();
    getLoadBalancerTargetGroups();
    var dczone_slug = document.querySelector("#dczone_slug");
    if (dczone_slug) {
      dczone_slug.value = slug;
    }
    var dcSelect = document.querySelectorAll([".tickMarkDc"]);
    var currentDc = document.querySelector("#tickMarkDc-" + slug);
    console.log(currentDc);
    if (dcSelect) {
      dcSelect.forEach((clist) => {
        clist.classList.add("d-none");
      });
    }
    if (currentDc) {
      currentDc.classList.remove("d-none");
    }
  };

  async function getAllVpc(slug) {
    console.log(slug);
    setVpcLoader(true)
    const vpc_data = await UseApi("get", "vpc/dc/" + slug, "");
    console.log(vpc_data);
    if (vpc_data.status === "error") {
      setVpcLoader(false);
      setVpcData([]);
    } else {
      setVpcLoader(false);
      setVpcData((old) => old = vpc_data?.vpc);
    }
  }
  const [policyArr, setPolicyArr] = useState(null);
  const [scheduleArr, setScheduleArr] = useState(null);

  function getPolicy() {
    if (props && props.deployInfo[0] && props.deployInfo[0].policies?.length > 0) {
      setPolicyArr(props.deployInfo[0].policies);
    }
  }

  function getSchedule() {
    if (props && props.deployInfo[0] && props.deployInfo[0].schedules?.length > 0) {
      setScheduleArr(props.deployInfo[0].schedules);
    }
  }
  const selectTickMark = (serviceid, name, input_name, custom = 'no') => {
    var currentPlan = document.querySelector("#tickMark" + name + "-" + serviceid);
    var currentMain = document.querySelector("#border" + name + "-" + serviceid);
    const inputElement = document.getElementById(input_name + serviceid);


    if (currentPlan && currentMain) {
      // Toggle the tick mark visibility
      if (custom == 'no') {
        currentPlan.classList.toggle("d-none");
      } else {
        currentPlan.classList.remove("d-none");
      }

      // Toggle the border's active class
      if (currentPlan.classList.contains("d-none")) {
        inputElement.checked = false;
        inputElement.classList.remove('deploy_checkbox')
        currentMain.classList.remove("activeBorder");
        // if (name == 'Vpc') {
        //   if (props) {
        //     if (props.deployInfo) {
        //       props.deployInfo[0].vpcid = 0;
        //     }
        //   }
        // }

        // if (name == 'Firewall') {
        //   if (props) {
        //     if (props.deployInfo) {
        //       props.deployInfo[0].firewallid = 0;
        //     }
        //   }
        // }
      } else {
        inputElement.checked = true;
        currentMain.classList.add("activeBorder");
        inputElement.classList.add('deploy_checkbox')
        // if (name == 'Vpc') {
        //   if (props) {
        //     if (props.deployInfo) {
        //       props.deployInfo[0].vpcid = serviceid;
        //     }
        //   }
        // }

        // if (name == 'Firewall') {
        //   if (props) {
        //     if (props.deployInfo) {
        //       props.deployInfo[0].firewallid = serviceid;
        //     }
        //   }
        // }
      }

      // If the tick mark is now visible, remove the tick mark from other elements
      if (!currentPlan.classList.contains("d-none")) {
        var allTickmarks = document.querySelectorAll(".tickMark" + name);
        allTickmarks.forEach((tickMark) => {
          if (tickMark !== currentPlan) {
            tickMark.classList.add("d-none");
          }
        });
        // Remove active border from other elements
        var mainTickMarks = document.querySelectorAll(".border" + name);
        mainTickMarks.forEach((border) => {
          if (border !== currentMain) {
            border.classList.remove("activeBorder");
          }
        });
        // console.log("All Tickmarks:", allTickmarks);
        // console.log("Main TickMarks:", mainTickMarks);
      }
    }
  };
  // const selectTickMark = (sshid, name, input_element) => {
  //   var allTickmarks = document.querySelectorAll([".tickMark" + name]);
  //   var currentPlan = document.querySelector("#tickMark" + name + "-" + sshid);
  //   var mainTickMark = document.querySelectorAll([".border" + name]);
  //   var currentMain = document.querySelector("#border" + name + "-" + sshid);
  //   const inputElement = document.getElementById(input_element + sshid);

  //   // Check if the current item is already selected (active)
  //   const isAlreadySelected = currentMain && currentMain.classList.contains("activeBorder");

  //   if (isAlreadySelected) {
  //     // Deselect if already active (double-click)
  //     if (currentPlan) currentPlan.classList.add("d-none");
  //     if (currentMain) currentMain.classList.remove("activeBorder");
  //     if (inputElement) inputElement.classList.remove('deploy_checkbox')
  //   } else {
  //     // Select the current item and deselect others
  //     if (mainTickMark) {
  //       mainTickMark.forEach((clist) => clist.classList.remove("activeBorder"));
  //     }
  //     if (allTickmarks) {
  //       allTickmarks.forEach((clist) => clist.classList.add("d-none"));
  //     }
  //     if (currentPlan) {
  //       currentPlan.classList.remove("d-none");
  //     }
  //     if (currentMain) {
  //       currentMain.classList.add("activeBorder");
  //     }
  //   }
  // };

  // const selectTickMark = (sshid, name) => {
  //   var allTickmarks = document.querySelectorAll([".tickMark" + name]);
  //   var currentPlan = document.querySelector("#tickMark" + name + "-" + sshid);
  //   var mainTickMark = document.querySelectorAll([".border" + name]);
  //   var currentMain = document.querySelector("#border" + name + "-" + sshid);

  //   if (mainTickMark) {
  //     mainTickMark.forEach((clist) => {
  //       clist.classList.remove("activeBorder");
  //     });
  //   }
  //   if (allTickmarks) {
  //     allTickmarks.forEach((clist) => {
  //       clist.classList.add("d-none");
  //     });
  //   }
  //   if (currentPlan) {
  //     currentPlan.classList.remove("d-none");
  //   }
  //   if (currentMain) {
  //     currentMain.classList.add("activeBorder");
  //   }
  // };
  const getVpcDataByLocation = async (slug) => {
    const vpc_data = await UseApi("get", "vpc/dc/" + slug, "");
    if (vpc_data.status === "error") {
      setVpcLoader(false);
      setVpcData([]);
    } else {
      setVpcLoader(false);
      setVpcData(vpc_data?.vpc);
    }
  }

  useEffect(() => {
    getPolicy();
    getLoadBalancerTargetGroups();
  }, [policyArr])

  useEffect(() => {
    getSchedule();
  }, [scheduleArr])

  function updateNewVpc(e) {
    let resData = setVpc()
    if (resData && e.target.value) {
      var eleData = JSON.parse(e.target.value);
      eleData.map(item => {
        // Extract string portion from the value within parentheses
        var match = item.value.match(/\(([^)]+)\)/);
        return match ? match[1] : null;
      }).filter(Boolean); // Filter out null values
    }
  }

  let setFireWall = () => {
    var firewall_field = document.querySelector("#firewall_field");
    if (firewall_field && firewallData) {
      var fdata = [];
      firewallData.map((value, index) => {
        fdata.push(value.name + " (" + value.id + ")");
      })
      const contactTagify = new window.Tagify(firewall_field, {
        whitelist: fdata,
        maxTags: 20,
        dropdown: {
          maxItems: 20,
          classname: "",
          enabled: 0,
          closeOnSelect: false
        }
      });
    }
  }

  const setVpc = () => {
    let vdata = [];
    if (vpcData && vpcData?.length > 0) {
      vpcData.map((value, index) => {
        vdata.push(`${value[1].name} (${value[1].id})`);
      })
    }
    var vpc_field = document.querySelector("#vpc_field");
    if (vpc_field && vdata) {
      const contactTagify = new window.Tagify(vpc_field, {
        whitelist: vdata,
        maxTags: 20,
        dropdown: {
          maxItems: 20,
          classname: "",
          enabled: 0,
          closeOnSelect: false
        }
      });
      return true
    } else {
      return false
    }
  }

  function updateNewFirewall(e) {
    if (e.target.value) {
      var eleData = JSON.parse(e.target.value);
      var eleIds = eleData.map(item => {
        // Extract numeric portion from the value (assuming it's always in parentheses)
        var match = item.value.match(/\((\d+)\)/);
        return match ? match[1] : null;
      }).filter(Boolean); // Filter out null values
    }
    var concatenatedFirewallIds = eleIds?.join(',');
    console.log(concatenatedFirewallIds);
    let arr = []
    arr.push(concatenatedFirewallIds)
    console.log(arr);
    const filteredFirewallIds = arr.filter(id => id !== '');
    setSecurityGroups(() => [...filteredFirewallIds])
    // Set the concatenated string as the value of the add_cloud input
    if (props && props.deployInfo[0]) {
      props.deployInfo[0].security_groups = concatenatedFirewallIds;
    }
  }
  console.log(securityGroups);
  const setAutoScaling = () => {
    var auto_scaling = document.querySelector("#get_auto_scaling");
    if (auto_scaling && asData) {
      var fdata = [];
      asData.map((value, index) => {
        fdata.push(value.name + " (" + value.id + ")");
      })
      const contactTagify = new window.Tagify(auto_scaling, {
        whitelist: fdata,
        maxTags: 20,
        dropdown: {
          maxItems: 20,
          classname: "",
          enabled: 0,
          closeOnSelect: false
        }
      });
      return true
    } else {
      return false
    }
  }

  const toggleFront = (fid) => {
    var front_sec = document.querySelector("#front-sec-" + fid);
    var front_close_sec = document.querySelector("#front-close-sec-" + fid);
    var front_open_sec = document.querySelector("#front-open-sec-" + fid);
    if (front_sec && front_close_sec && front_open_sec) {
      front_sec.classList.toggle("fade-out-front");
      front_open_sec.classList.toggle("d-none");
      front_close_sec.classList.toggle("d-none");
    }
  }

  function updateNewLoadbalancer(e) {
    if (e.target.value) {
      var eleData = JSON.parse(e.target.value);
      var eleIds = eleData.map(item => {
        // Extract numeric portion from the value (assuming it's always in parentheses)
        var match = item.value.match(/\((\d+)\)/);
        return match ? match[1] : null;
      }).filter(Boolean); // Filter out null values

      // Concatenate the cloud IDs into a comma-separated string
      var concatenatedLoadbalancerIds = eleIds.join(',');
      setLoadBalancers(() => [...concatenatedLoadbalancerIds])
      // Set the concatenated string as the value of the add_cloud input
      if (props && props.deployInfo[0]) {
        props.deployInfo[0].load_balancers = concatenatedLoadbalancerIds;
      }
    }
  }

  function updateNewLoadbalancerTargetGroups(e) {
    if (e.target.value) {
      var eleData = JSON.parse(e.target.value);
      var eleIds = eleData.map(item => {
        // Extract numeric portion from the value (assuming it's always in parentheses)
        var match = item.value.match(/\((\d+)\)/);
        return match ? match[1] : null;
      }).filter(Boolean); // Filter out null values

      // Concatenate the cloud IDs into a comma-separated string
      var concatenatedLoadbalancerIds = eleIds.join(',');
      setTargetGroups(() => [...concatenatedLoadbalancerIds])
      // Set the concatenated string as the value of the add_cloud input
      if (props && props.deployInfo[0]) {
        props.deployInfo[0].target_groups = concatenatedLoadbalancerIds;
      }
    }
  }

  console.log(props);
  const toggleAttachLb = (action) => {
    var networkLb = document.querySelector("#network-lb-sec");
    var applicationLb = document.querySelector("#application-lb-sec");
    if (networkLb && applicationLb) {
      if (action == "network") {
        networkLb.classList.remove("d-none");
        applicationLb.classList.add("d-none");
        if (props && props.deployInfo[0]) {
          props.deployInfo[0].target_groups = [];
        }
        updateLoadbalancer();
      } else {
        networkLb.classList.add("d-none");
        applicationLb.classList.remove("d-none");
        if (props && props.deployInfo[0]) {
          props.deployInfo[0].load_balancers = [];
        }
        updateLoadbalancerTargetGroups();
      }
    }
  }
  function updateLoadbalancer() {
    var loadbalancer_field = document.querySelector("#loadbalancer_field");
    if (loadbalancer_field.value) {
      var eleData = JSON.parse(loadbalancer_field.value);
      var eleIds = eleData.map(item => {
        // Extract numeric portion from the value (assuming it's always in parentheses)
        var match = item.value.match(/\((\d+)\)/);
        return match ? match[1] : null;
      }).filter(Boolean); // Filter out null values

      // Concatenate the cloud IDs into a comma-separated string
      var concatenatedLoadbalancerIds = eleIds.join(',');

      // Set the concatenated string as the value of the add_cloud input
      if (props && props.deployInfo[0]) {
        props.deployInfo[0].load_balancers = concatenatedLoadbalancerIds;
      }
    }
  }
  function updateLoadbalancerTargetGroups() {
    var loadbalancer_target_field = document.querySelector("#loadbalancer_target_field");
    if (loadbalancer_target_field.value) {
      var eleData = JSON.parse(loadbalancer_target_field.value);
      var eleIds = eleData.map(item => {
        // Extract numeric portion from the value (assuming it's always in parentheses)
        var match = item.value.match(/\((\d+)\)/);
        return match ? match[1] : null;
      }).filter(Boolean); // Filter out null values

      // Concatenate the cloud IDs into a comma-separated string
      var concatenatedLoadbalancerIds = eleIds.join(',');

      // Set the concatenated string as the value of the add_cloud input
      if (props && props.deployInfo[0]) {
        props.deployInfo[0].target_groups = concatenatedLoadbalancerIds;
      }
    }
  }

  const [targetData, setTargetData] = useState([]);

  const getLoadBalancerTargetGroups = async () => {
    const data = await UseApi('get', 'targetgroup', '');
    if (data) {

      if (data.status != "success") {
        setTargetData([]);
      } else {
        if (data.targetgroups) {
          var loadbalancer_field = document.querySelector("#loadbalancer_target_field");
          if (loadbalancer_field) {
            //console.log("lb field");
            var ldata = [];
            console.log(data);
            data.targetgroups.map((value, index) => {
              ldata.push(value.name + " (" + value.id + ")");
            })
            const contactTagify = new window.Tagify(loadbalancer_field, {
              whitelist: ldata,
              maxTags: 20,
              dropdown: {
                maxItems: 20,
                classname: "",
                enabled: 0,
                closeOnSelect: false
              }
            });
          } else {
            //console.log("no lb field");
          }
        }
        setTargetData(data.targetgroups);

      }
    }
  }
  const updateAutoScaling = (e) => {
    const resData = setAutoScaling()
    if (resData) {
      if (e.target.value) {
        var eleData = JSON.parse(e.target.value);
        eleData.map(item => {
          // Extract string portion from the value within parentheses
          var match = item.value.match(/\(([^)]+)\)/);
          return match ? match[1] : null;
        }).filter(Boolean); // Filter out null values
      }
    }
  }

  const handleCheckBox = (index, name, id) => {
    // setPoolType(name)
    if (name === "Static Node Pool") {
      const error_message = document.querySelector(`#error_message_${id}`)
      error_message.classList.add('d-none')
    }
    setArrayStatus(prevArray => {
      const newArray = [...prevArray];
      newArray[index] = name === "Static Node Pool" ? false : true;
      return newArray;
    });
  }

  console.log(adcdata);
  return (
    <>
      {adcdata != null && poolData != null ? (<>
        <form id="deploykubernetes" className="form">
          <div className="col-xl-12 card mb-5">
            {/*begin::data center*/}
            <Dczone adcdata={adcdata} ddcdata={ddcdata} selectDc={selectDc} location={location} setRecommendedCpu={setRecommendedCpu}/>
            {/* End data center */}
          </div>
          {/*end::Row*/}
          <div className="col-xl-12 card mb-5">
            {/*begin::Tables Widget 9*/}
            {/*begin::Header*/}
            <div className="card-header border-3 mb-5" style={{ padding: '1rem 1rem 1rem 1.38rem' }}>
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder text-gray-800">
                  Select Version
                </span>
                <span className="text-gray-400 mt-1 fw-bold fs-6">
                  Cluster Label, Version
                </span>
              </h3>
            </div>
            {/*end::Header*/}
            {/*begin::Row*/}
            <div className="mx-5 mb-3">
              <div
                id="define-vpc"
                className="row g-9"
                data-kt-buttons="true"
                data-kt-buttons-target="[data-kt-button]"
              >
                <div className="col-md-4 col-lg-4 col-xxl-4">
                  <div className="mb-10">
                    <label htmlFor="ClusterLabel" className="required form-label">
                      Cluster Label
                    </label>
                    <input
                      className="form-control  "
                      type="text"
                      name="cluster_label"
                      id="cluster_label"
                      defaultValue={"MyK8S-" + makeid(8) + "-" + (Math.random() + 1).toString(36).substring(8)}
                      placeholder="pool-nkeunuewd"
                    />
                  </div>
                </div>
                <div className="col-md-4 col-lg-4 col-xxl-4">
                  <div className="mb-10" id="cluster_v">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="required form-label"
                    >
                      Cluster Version
                    </label>
                    <select
                      className="form-select"
                      name="cluster_version"
                      id="cluster_version"
                    >
                      {/* <option value="1.26.0" data-select2-id="select2-data-51-3nrk">
                        1.26.0
                      </option> */}
                      <option value="" disabled selected data-select2-id="select2-data-51-3nrk">
                        Please Select Kubernetes Version
                      </option>
                      {k8sVersions && k8sVersions.map((version, index) => (<>
                        <option value={version}>
                          {version}
                        </option>
                      </>))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-5 mb-xl-10 bg-transparent" style={{ border: "none", boxShadow: "none" }}>
            <div className="card-header pt-2 ps-2 pe-2" style={{ border: "none" }}>
              {/*begin::Title*/}
              <h3 className="card-title align-items-start flex-column ps-4">
                <span className="card-label fw-bolder text-gray-800">
                  Manage Node Worker Pools
                </span>
                <span className="text-gray-400 mt-1 fw-bold fs-6">
                  Add node pools for your kubernetes cluster
                </span>
              </h3>
              <div className="d-flex my-4" onClick={(e) => addFormField()}>
                <span className="btn btn-primary" id="addnodepool">
                  <span className="svg-icon svg-icon-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.5"
                        x="11.364"
                        y="20.364"
                        width={16}
                        height={2}
                        rx={1}
                        transform="rotate(-90 11.364 20.364)"
                        fill="black"
                      />
                      <rect
                        x="4.36396"
                        y="11.364"
                        width={16}
                        height={2}
                        rx={1}
                        fill="black"
                      />
                    </svg>
                  </span>
                  Add Node Pool
                </span>
              </div>
              {/*end::Title*/}
            </div>
            {/*end::Row */}
            {/*begin::Col*/}
          </div>
          <input
            type="hidden"
            id="node_plan_pool_plan_id"
            defaultValue=""
          />
          {poolData !== null && (poolData && poolData.map((value, index) => (
            <>
              <div className="card col-md-12 col-lg-12 col-xxl-12 mb-5">
                <div className=" h-lg-100">
                  <div className="card-header border-3" style={{ padding: '1rem 1rem 1rem 1.38rem' }}>
                    <h3 className="card-title align-items-start flex-column" > Pool Worker - {value.nodePoolValue}</h3>
                    {poolData?.length > 1 &&
                      <div className="card-toolbar" onClick={() => deleteFormField(value.pool_id, index)}>
                        <div className="btn btn-sm btn-danger">
                          Delete pool
                        </div>
                      </div>
                    }
                  </div>
                  {/*begin::Tables Widget 11*/}
                  <div className=" mb-5 mb-xl-8">
                    {/*begin::Body*/}
                    <div className=" py-3">
                      {/*begin::Table container*/}
                      <div className="table-responsive">
                        {/*begin::Table*/}
                        <div className="mb-10 mt-5 mx-3 d-none">
                          {/*begin::Label*/}
                          <h3 className="card-title align-items-start flex-column mb-5 ps-3">
                            <span className="card-label text-gray-800 fs-4">Pool Type:</span>
                            <br></br>
                            <span className="text-gray-400 mt-1 fw-bold fs-6">Select Pool Type You Want</span>
                          </h3>
                          <div className="separator border-2"></div>
                          {/*end::Label*/}
                          {/*begin::Options*/}
                          <div className="d-flex ps-5 pt-5 d-none">
                            {/*begin::Options*/}
                            <label className="form-check form-check-sm form-check-custom form-check-solid me-5">
                              <input className="form-check-input" type="radio" onClick={() => handleCheckBox(index, "Static Node Pool", value.pool_id)} checked={!arrayStatus[index] && "checked"} />
                              <span className="form-check-label">Static Node Pool</span>
                            </label>
                            {/*end::Options*/}
                            {/*begin::Options*/}
                            <label className="form-check form-check-sm form-check-custom form-check-solid">
                              <input
                                className="form-check-input"
                                type="radio"
                                // defaultValue={2}
                                onClick={() => handleCheckBox(index, "Auto Scale Node Pool", value.pool_id)}
                                checked={arrayStatus[index] && "checked"}
                              />
                              <span className="form-check-label">Auto Scale Node Pool</span>
                            </label>
                            {/*end::Options*/}
                          </div>
                          {/*end::Options*/}
                        </div>
                        <table
                          className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
                          id="nodepool_list"
                        >
                          {/*begin::Table head*/}
                          <thead>
                            <tr className="fw-bold  bg-light">
                              <th className="ps-10 min-w-225px">Pool Name</th>
                              <th className="text-center min-w-325px">
                                Node Size
                              </th>
                              <th className="text-center min-w-150px">
                                Desired Count
                              </th>
                              <th className={`text-center min-w-100px ${!arrayStatus[index] && "d-none"}`}>
                                Maximum Count
                              </th>
                              <th className="text-center min-w-200px">
                                Total Cost
                              </th>
                              <th className="min-w-100px " />
                            </tr>
                          </thead>
                          {/*end::Table head*/}
                          {/*begin::Table body*/}
                          <tbody>
                            {arrayStatus[index] ?
                              <>
                                <StaticKubernetes props={props} poolData={poolData} openDrawer={openDrawer} addFormField={addFormField} nodePoolUpdate={nodePoolUpdate} nodePoolCount={nodePoolCount} index={index} deleteFormField={deleteFormField} ids={value.pool_id} showMax={""} />

                                {/* <KubernetesInstance props={props} poolData={poolData} nodePoolUpdate={nodePoolUpdateInstance} index={index} ids={value.pool_id} /> */}
                                <KubernetesPolicy props={props} poolData={poolData} nodePoolUpdate={nodePoolUpdatePolicy} index={index} ids={value.pool_id} handleAddPolicy={handleAddPolicy} handleDeletePolicy={handleDeletePolicy} isUpdated={false} />
                              </>
                              // </div>
                              :
                              // <div className="border-primary  p-1 mb-5" style={{border:"1.5px solid blue",borderStyle:"dashed"}}>
                              <StaticKubernetes props={props} poolData={poolData} openDrawer={openDrawer} addFormField={addFormField} nodePoolUpdate={nodePoolUpdate} nodePoolCount={staticNodePoolCount} index={index} deleteFormField={deleteFormField} ids={value.pool_id} showMax={" d-none"} />
                              // </div>
                            }
                          </tbody>
                          {/*end::Table body*/}
                        </table>
                        {/*end::Table*/}
                      </div>
                      {/*end::Table container*/}
                    </div>
                    {/*begin::Body*/}
                  </div>
                  {/*end::Tables Widget 11*/}
                </div>
              </div>
            </>
          )))}
          <div className="col-xl-12 card mb-5 d-none">
            {/*begin::List widget 10*/}
            <div className=" h-lg-100 mb-5">
              {/*begin::Header*/}
              <div className="card-header border-3 mb-5 pt-2">
                {/*begin::Title*/}
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bolder text-gray-800">
                    Attach to an existing Load Balancer
                  </span>
                  <span className="text-gray-400 mt-1 fw-bold fs-6">
                    Select Load Balancer for your auto scaling group
                  </span>
                </h3>

                {/*end::Title*/}
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="mx-5 mb-3">
                {/*begin::Radio group*/}
                <div className="border-3 mb-5 pt-2">
                  <div className="p-2 border-3 pt-2">
                    <div data-kt-buttons="true" className="row">
                      <div className="col-6">
                        <label onClick={(e) => toggleAttachLb("application")} className="btn btn-outline btn-outline-dashed d-flex flex-stack text-start p-6 mb-5">
                          <div className="d-flex align-items-center me-2">
                            <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="auth"
                                defaultValue="option1"
                                defaultChecked={true}
                              />
                            </div>
                            <div className="flex-grow-1">
                              <h2 className="d-flex align-items-center fs-3 fw-bolder flex-wrap">
                                Choose from your Load Balancer target groups
                              </h2>
                              <div className="fw-bold opacity-50">
                                Select target group for you auto scaling groups
                              </div>
                            </div>
                          </div>
                        </label>
                      </div>
                      <div className="col-6">
                        <label onClick={(e) => toggleAttachLb("network")} className="btn btn-outline btn-outline-dashed d-flex flex-stack text-start p-6">
                          <div className="d-flex align-items-center me-2">
                            <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="auth"
                                defaultValue="option2"
                              />
                            </div>
                            <div className="flex-grow-1">
                              <h2 className="d-flex align-items-center fs-3 fw-bolder flex-wrap">
                                Choose from Network Load Balancer
                              </h2>
                              <div className="fw-bold opacity-50">
                                Select network load balancer for your auto scaling group
                              </div>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-12  mb-5" id="application-lb-sec">

                    <div className="pb-5">
                      <label for="targetgroups" class="form-label">Existing load balancer target groups</label>
                      <input class="form-control form-control-sm" onChange={(e) => updateNewLoadbalancerTargetGroups(e)} id="loadbalancer_target_field" />
                    </div>
                  </div>
                  <div className="col-xl-12 mb-5 d-none" id="network-lb-sec">
                    <div className="pb-5">
                      <label for="loadbalancers" class="form-label">Network load balancers</label>
                      <input class="form-control form-control-sm" onChange={(e) => updateNewLoadbalancer(e)} id="loadbalancer_field" />
                    </div>
                  </div>
                </div>
                <input type="hidden" name="load_balancers" value={loadBalancers} />
                <input type="hidden" name="target_groups" value={targetGroups} />


                {/* <div className="row"
                              data-kt-buttons="true"
                            >
                              <div className="col-md-12 col-lg-12 col-xxl-12 mt-5">
                              <input class="form-control form-control-sm" onChange={(e)=>updateNewLoadbalancer(e)} id="loadbalancer_field"/>
                              </div>
                               
                            </div> */}
                {/*end::Radio group*/}
              </div>
              {/*end: Card Body*/}
            </div>
            {/*end::List widget 10*/}
          </div>
          {/* {vpcData != null ? (<> */}
          {/* <div className="col-xl-12 card mb-5"> */}
          {/*begin::List widget 10*/}
          {vpcLoader != true ? (<>
            <Vpc vpcData={vpcData} selectTickMark={selectTickMark} vpcd={props.deployInfo[0]} vpcLoader={vpcLoader} setVpcDataId={setVpcId} />
          </>) : (<><TableLoader /></>)}
          <div className=" h-lg-100 mb-5 d-none" id="vpc_section">
            {/*begin::Header*/}
            <div className="card-header border-3 mb-5" style={{ padding: '1rem 1rem 1rem 1.38rem' }}>
              {/*begin::Title*/}
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder text-gray-800">
                  VPC Network
                </span>
                <span className="text-gray-400 mt-1 fw-bold fs-6">
                  Select VPC for the Cluster
                </span>
              </h3>
              {/*end::Title*/}
            </div>
            {vpcLoader == true ? (
              <>
                <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                  {/* <div className="small-spinner"></div> */}
                  <TableLoader />
                </div>
              </>
            ) : (
              <>
                <div className="mx-5 mb-3">
                  {/*begin::Radio group*/}
                  <div
                    id="getvpc"
                    className="row"
                    data-kt-buttons="true"
                  >
                    {vpcData != null ? (
                      <>
                        {Object.keys(vpcData)?.length != 0
                          ? Object.entries(vpcData).map(
                            (value, index) => (
                              <>
                                <div className="col-md-4 col-lg-4 col-xxl-4 mt-5">
                                  {/*begin::Option*/}
                                  <input
                                    className="btn-check deploy_checkbox"
                                    type="radio"
                                    name="vpc"
                                    defaultValue={value[1].id}
                                    id={"vpc" + value[1].id}
                                  />
                                  <label
                                    htmlFor={"vpc" + value[1].id}
                                    onClick={() =>
                                      selectTickMark(
                                        value[1].id,
                                        "Vpc"
                                      )
                                    }
                                    className="borderVpc btn btn-outline btn-outline btn-outline-default  d-flex align-items-center"
                                    id={"borderVpc-" + value[1].id}
                                    style={{ overflow: "hidden", padding: '0.72rem' }}
                                  >
                                    {/*begin::Svg Icon | path: icons/duotune/communication/com003.svg*/}
                                    <span className="svg-icon svg-icon-primary svg-icon-4x me-1">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                      >
                                        <path
                                          opacity="0.3"
                                          d="M19 18C20.7 18 22 16.7 22 15C22 13.3 20.7 12 19 12C18.9 12 18.9 12 18.8 12C18.9 11.7 19 11.3 19 11C19 9.3 17.7 8 16 8C15.4 8 14.8 8.2 14.3 8.5C13.4 7 11.8 6 10 6C7.2 6 5 8.2 5 11C5 11.3 5.00001 11.7 5.10001 12H5C3.3 12 2 13.3 2 15C2 16.7 3.3 18 5 18H19Z"
                                          fill="black"
                                        />
                                      </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                    <span className="d-block fw-bold text-start">
                                      <span className="text-dark fw-bolder d-block fs-3">
                                        {value[1].network}
                                      </span>
                                      <span className="text-muted fw-bold fs-8 w-100">
                                        {" "}
                                        {value[1].name}
                                      </span>
                                    </span>
                                    <div
                                      className="d-none tickMarkVpc ribbon ribbon-triangle ribbon-top-start border-primary"
                                      id={
                                        "tickMarkVpc-" + value[1].id
                                      }
                                    >
                                      <div className="ribbon-icon mt-n5">
                                        <i className="bi bi-check2 fs-2 text-white" />
                                      </div>
                                    </div>
                                  </label>
                                  {/*end::Option*/}
                                </div>
                              </>
                            )
                          )
                          : ""}
                      </>
                    ) : (
                      ""
                    )}

                    <div className="col-md-4 col-lg-4 col-xxl-4 mt-5">
                      {/*begin::Option*/}
                      {/*begin::Radio button*/}
                      <a href="/vpc/deploy" target="_blank">
                        <label className="btn btn-outline btn-outline btn-outline-default d-flex flex-stack text-start" style={{ padding: '1.22rem' }}>
                          {/*end::Description*/}
                          <div className="d-flex align-items-center me-2">
                            {/*begin::Info*/}
                            <div className="flex-grow-1">
                              <div className="fw-bold opacity-50">
                                {" "}
                                Add New VPC
                              </div>
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::Description*/} {/*begin::Price*/}
                          <div className="ms-5">
                            {/*begin::Svg Icon | path: icons/duotune/communication/com003.svg*/}
                            <span className="svg-icon svg-icon-3x me-4">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <rect
                                  opacity="0.3"
                                  x={2}
                                  y={2}
                                  width={20}
                                  height={20}
                                  rx={10}
                                  fill="black"
                                />
                                <rect
                                  x="10.8891"
                                  y="17.8033"
                                  width={12}
                                  height={2}
                                  rx={1}
                                  transform="rotate(-90 10.8891 17.8033)"
                                  fill="black"
                                />
                                <rect
                                  x="6.01041"
                                  y="10.9247"
                                  width={12}
                                  height={2}
                                  rx={1}
                                  fill="black"
                                />
                              </svg>
                            </span>
                            {/*end::Svg Icon*/}
                          </div>
                          {/*end::Price*/}
                        </label>
                      </a>
                      {/*end::Radio button*/}
                      {/*end::Option*/}
                    </div>
                  </div>
                  {/*end::Radio group*/}
                </div>
              </>
            )}
          </div>
          {/* </div> */}

          <NetworkType />
          
          <div className="col-xl-12 card mb-5">
            {/*begin::List widget 10*/}
            {firewallLoader != true ? (<>
              <Firewall firewallData={firewallData} selectTickMark={selectTickMark} firewalld={props.deployInfo[0]} firewallLoader={firewallLoader} />
            </>) : (<><TableLoader /></>)}
          </div>
          {/* </>) : ('')} */}
          {/*begin::Col*/}
          <div className="col-xl-12 card mb-5 d-none">
            {/*begin::List widget 10*/}
            <div className=" h-lg-100 mb-5" id="vpc_section">
              {/*begin::Header*/}
              <div className="card-header border-3 mb-5 pt-2">
                {/*begin::Title*/}
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bolder text-gray-800">
                    Auto Scaling
                  </span>
                  <span className="text-gray-400 mt-1 fw-bold fs-6">
                    Select Auto Scaling for the server
                  </span>
                </h3>
                {/*end::Title*/}
              </div>
              <>
                <div className="mx-5 mb-3">
                  {/*begin::Radio group*/}
                  <div
                    // id="get_auto_scaling"
                    className="row"
                    data-kt-buttons="true"
                  >
                    <div className="col-md-12 col-lg-12 col-xxl-12 mt-5">
                      <input class="form-control" onChange={(e) => updateAutoScaling(e)} onMouseOver={setAutoScaling} id="get_auto_scaling" name="auto_scaling" />
                    </div>
                  </div>
                  {/*end::Radio group*/}
                </div>
              </>
              {/* )} */}
              {/*end: Card Body*/}
            </div>
            {/*end::List widget 10*/}
          </div>
          {/*end::Col*/}
          {/*begin::Col*/}
          <CpuModelTab setCpuValue={setCpuValue} cpuValue={cpuValue} recommendedCpu={recommendedCpu} setRecommendedCpu={setRecommendedCpu} />
          {/* <input
            type="hidden"
            name="cpumodel"
            value={cpuValue}
          /> */}
          <div className="col-xl-12 card mb-5 d-none">
            {/*begin::List widget 10*/}
            <Firewall firewallData={firewallData} selectTickMark={selectTickMark} firewalld={props.deployInfo[0]} firewallLoader={firewallLoader} />
            <div className=" h-lg-100 mb-5 d-none">
              {/*begin::Header*/}
              <div className="card-header border-3 mb-5" style={{ padding: '1rem 1rem 1rem 1.38rem' }}>
                {/*begin::Title*/}
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bolder text-gray-800">
                    Add security group
                  </span>
                  <span className="text-gray-400 mt-1 fw-bold fs-6">
                    Select firewalls for the server
                  </span>
                </h3>
                {/*end::Title*/}
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="mx-5 mb-3">
                {/*begin::Radio group*/}
                <div
                  className="row"
                  data-kt-buttons="true"
                >
                  <div className="col-md-12 col-lg-12 col-xxl-12 mt-5">
                    <input class="form-control" onChange={(e) => updateNewFirewall(e)} onMouseOver={setFireWall} id="firewall_field" />
                  </div>
                  <input type="hidden" name="security_groups" value={[securityGroups]} />
                </div>
                {/*end::Radio group*/}
              </div>
              {/*end: Card Body*/}
            </div>
            {/*end::List widget 10*/}
          </div>
          {/*end::Col*/}
          <div className="col-xl-12 card" style={{ paddingBottom: marginBottom }}>
            <div className="card-header  mb-5 pt-2" style={{ border: "none" }}>
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1">
                  Total Cost
                </span>
                <span className="fs-xl-2x fs-2 fw-boldest">
                  <span id="currency_final"></span>
                  <span id="total_cost"></span>
                  <span id="billing_final"></span> </span>
                <span className="text-muted mt-1 fw-bold fs-7" />
              </h3>
              <div className="card-toolbar">
                <a id="deploykubernetes-btn"
                  style={{ width: "100%" }}
                  onClick={deployKubernetes} className="btn btn-sm btn-primary btn-active-light-primary mt-2">
                  Deploy Cluster &nbsp; &nbsp;
                  <span className="svg-icon svg-icon-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z"
                        fill="black"
                      />
                      <path
                        opacity="0.3"
                        d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </form>
        <div id="drawer_overlay" onClick={(e) => openDrawer('close')} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>
        <div
          id="kubernetes-modal"
          className="bg-body shadow drawer drawer-end"
          data-kt-drawer="true"
          data-kt-drawer-name="chat"
          data-kt-drawer-activate="true"
          data-kt-drawer-direction="end"
          data-kt-drawer-close="#close_ssh"
          data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
          style={{ maxWidth: '80%', important: true, zIndex: 110 }}
        >

          {/*begin::Messenger*/}
          <div className="card w-100 rounded-0 border-0" id="kt_drawer_chat_messenger" style={{ height: 'fit-content' }}>
            {/*begin::Card header*/}
            <div className="card-header pe-5" id="kt_drawer_chat_messenger_header">
              {/*begin::Title*/}
              <div className="card-title">
                {/*begin::User*/}
                <div className="d-flex justify-content-center flex-column me-3">
                  <a

                    className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1"
                  >
                    Choose plan for Kubernetes
                  </a>
                </div>
                {/*end::User*/}
              </div>
              {/*end::Title*/}
              {/*begin::Card toolbar*/}
              <div className="card-toolbar">
                {/*begin::Close*/}
                <div
                  className="btn btn-sm btn-icon btn-active-light-primary"
                  onClick={() => openDrawer("close")}
                >
                  {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                  <span className="svg-icon svg-icon-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.5"
                        x={6}
                        y="17.3137"
                        width={16}
                        height={2}
                        rx={1}
                        transform="rotate(-45 6 17.3137)"
                        fill="black"
                      />
                      <rect
                        x="7.41422"
                        y={6}
                        width={16}
                        height={2}
                        rx={1}
                        transform="rotate(45 7.41422 6)"
                        fill="black"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                </div>
                {/*end::Close*/}
              </div>
              {/*end::Card toolbar*/}
            </div>
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className="" id="">
              {/*begin::Modal body*/}
              <div className="modal-body ">
                {/*begin::Col*/}
                <div className="col-xl-12">
                  {/*begin::List widget 10*/}
                  <div className="">
                    {/*begin::Body*/}
                    {/*begin::Nav*/}
                    <ul className="nav nav-pills nav-pills-custom row position-relative mx-0 mb-9">
                      {/*begin::Item*/}
                      <li className="nav-item col-4 mx-0 p-0">
                        {/*begin::Link*/}
                        <a
                          className="nav-link active d-flex justify-content-center w-100 border-0 h-100"
                          data-bs-toggle="pill"
                          href="#basic-tab"
                        >
                          {/*begin::Subtitle*/}
                          <span className="nav-text text-gray-800 fw-bolder fs-6 mb-3 sphide">
                            Basic
                          </span>
                          {/*end::Subtitle*/}
                          {/*begin::Bullet*/}
                          <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded" />
                          {/*end::Bullet*/}
                        </a>
                        {/*end::Link*/}
                      </li>
                      {/*end::Item*/}
                      {/*begin::Item*/}
                      <li className="nav-item col-2 mx-0 px-0 d-none">
                        {/*begin::Link*/}
                        <a
                          className="nav-link d-flex justify-content-center w-100 border-0 h-100"
                          data-bs-toggle="pill"
                          href="#dedicated-standard-tab"
                        >
                          {/*begin::Subtitle*/}
                          <span className="nav-text text-gray-800 fw-bolder fs-6 mb-3 sphide">
                            Dedicated Standard
                          </span>
                          {/*end::Subtitle*/}
                          {/*begin::Bullet*/}
                          <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded" />
                          {/*end::Bullet*/}
                        </a>
                        {/*end::Link*/}
                      </li>
                      {/*end::Item*/}
                      {/*begin::Item*/}
                      <li className="nav-item col-4 mx-0 px-0">
                        {/*begin::Link*/}
                        <a
                          className="nav-link d-flex justify-content-center w-100 border-0 h-100"
                          data-bs-toggle="pill"
                          href="#dedicated-cpu-tab"
                        >
                          {/*begin::Subtitle*/}
                          <span className="nav-text text-gray-800 fw-bolder fs-6 mb-3 sphide">
                            CPU Optimized
                          </span>
                          {/*end::Subtitle*/}
                          {/*begin::Bullet*/}
                          <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded" />
                          {/*end::Bullet*/}
                        </a>
                        {/*end::Link*/}
                      </li>
                      {/*end::Item*/}
                      {/*begin::Item*/}
                      <li className="nav-item col-4 mx-0 px-0">
                        {/*begin::Link*/}
                        <a
                          className="nav-link d-flex justify-content-center w-100 border-0 h-100"
                          data-bs-toggle="pill"
                          href="#dedicated-memory-tab"
                        >
                          {/*begin::Subtitle*/}
                          <span className="nav-text text-gray-800 fw-bolder fs-6 mb-3 sphide">
                            Memory Optimized
                          </span>
                          {/*end::Subtitle*/}
                          {/*begin::Bullet*/}
                          <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded" />
                          {/*end::Bullet*/}
                        </a>
                        {/*end::Link*/}
                      </li>
                      {/*end::Item*/}
                      {/*begin::Bullet*/}
                      <span className="position-absolute z-index-1 bottom-0 w-100 h-4px bg-light rounded" />
                      {/*end::Bullet*/}
                    </ul>
                    {/*end::Nav*/}
                    {/*begin::Tab Content*/}
                    <div className="tab-content">
                      {/*begin::Tap pane*/}
                      <div className="tab-pane fade show active" id="basic-tab">
                        {/*begin::Radio group*/}
                        <div className="row" data-kt-buttons="true">
                          <div className="table-responsive" id="ramcpuplans">
                            {/*begin::Table*/}
                            <table className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9 tableramcpu">
                              {/*begin::Thead*/}
                              <thead className="border-bottom border-gray-200 fs-6 fw-bolder bg-secondary">
                                <tr>
                                  <th className="w-125px px-0 text-center">RAM</th>
                                  <th className="w-250px pe-8"></th>
                                  <th className="w-125px">vCPU</th>
                                  <th className="w-250px pe-8"></th>
                                  <th className="w-125px sphide">SSD Disk</th>
                                  <th className="w-250px pe-8"></th>
                                  <th className="w-125px">Bandwidth</th>
                                  <th className="w-250px pe-8"></th>
                                  <th className="w-125px ps-0 text-start">
                                    Price
                                  </th>
                                </tr>
                              </thead>
                              {/*end::Thead*/}
                              {/*begin::Tbody*/}
                              <tbody
                                id="basic-plan"
                                className="fs-6 fw-bold text-gray-600"
                              >
                                {planData != null ? (planData && planData.map((value, index) => (
                                  <>
                                    {value.slug === 'basic' ? (<>
                                      <tr class="planseffect" onClick={() => selectNodeSize(value.id, value.ram / 1024, value.cpu, value.disk, value.bandwidth, parseFloat(value.price / 720).toFixed(2), value.currencyprefix)}>
                                        <td class="ps-0 text-center sphide">{value.ram / 1024} GB <input type="hidden" id={"plan_field_row_" + value.id} /></td>
                                        <td></td>
                                        <td className="sphide">
                                          {value.dedicated_vcore == "1" ? (<>
                                            {value.cpu} vCPU <br /><span className="badge badge-success">Dedicated</span>
                                          </>) : (<>
                                            {value.cpu} vCPU
                                          </>)}</td>
                                        <td></td>
                                        <td className="sphide">{value.disk} GB</td>
                                        <td></td>
                                        <td className="sphide">{value.bandwidth} GB</td>
                                        <td></td>
                                        <td className="sphide">
                                          <span class="badge badge-light-success fs-7 fw-bolder  me-3">{user_data.current != null ? (user_data.current.user.currencyprefix) : ('')} {parseFloat(value.price / 720).toFixed(2)}/hourly</span>
                                          {value.free_availabel == 1 ? (
                                            <span class="badge badge-warning fs-base">Free {value.free_days} days</span>
                                          ) : ('')}

                                        </td>
                                      </tr>
                                    </>) : ('')}
                                  </>
                                ))) : <></>}

                              </tbody>
                              {/*end::Tbody*/}
                            </table>
                            {/*end::Table*/}
                          </div>
                        </div>
                        {/*end::Radio group*/}
                      </div>
                      {/*end::Tap pane*/}
                      {/*begin::Tap pane*/}
                      <div className="tab-pane fade" id="dedicated-standard-tab">
                        {/*begin::Radio group*/}
                        <div className="row" data-kt-buttons="true">
                          <div className="table-responsive">
                            {/*begin::Table*/}
                            <table className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9 tableramcpu">
                              {/*begin::Thead*/}
                              <thead className="border-bottom border-gray-200 fs-6 fw-bolder bg-secondary">
                                <tr>
                                  <th className="w-125px px-0 text-center">RAM</th>
                                  <th className="w-125px pe-8"></th>
                                  <th className="w-125px">vCPU</th>
                                  <th className="w-125px pe-8"></th>
                                  <th className="w-125px sphide">SSD Disk</th>
                                  <th className="w-125px pe-8"></th>
                                  <th className="w-125px">Bandwidth</th>
                                  <th className="w-125px pe-8"></th>
                                  <th className="w-125px ps-0 text-start">
                                    Price
                                  </th>
                                </tr>
                              </thead>
                              {/*end::Thead*/}
                              {/*begin::Tbody*/}
                              <tbody
                                id="standard-plan"
                                className="fs-6 fw-bold text-gray-600"
                              >
                                {planData != null && (planData && planData.map((value, index) => (
                                  <>
                                    {value.slug == 'dedicated-standard' ? (<>
                                      <tr class="planseffect" onClick={() => selectNodeSize(value.id, value.ram / 1024, value.cpu, value.disk, value.bandwidth, parseFloat(value.price / 720).toFixed(2), value.currencyprefix)}>
                                        <td class="ps-0 text-center sphide">{value.ram / 1024} GB</td>
                                        <td></td>
                                        <td className="sphide">
                                          {value.dedicated_vcore == "1" ? (<>
                                            {value.cpu} vCPU <br /><span className="badge badge-success">Dedicated</span>
                                          </>) : (<>
                                            {value.cpu} vCPU
                                          </>)}</td>
                                        <td></td>
                                        <td className="sphide">{value.disk} GB</td>
                                        <td></td>
                                        <td className="sphide">{value.bandwidth} GB</td>
                                        <td></td>
                                        <td className="sphide">
                                          <span class="badge badge-light-success fs-7 fw-bolder  me-3">{user_data.current != null ? (user_data.current.user.currencyprefix) : ('')} {parseFloat(value.price / 720).toFixed(2)}/hourly</span>
                                          {value.free_availabel == 1 ? (
                                            <span class="badge badge-warning fs-base">Free {value.free_days} days</span>
                                          ) : ('')}

                                        </td>
                                      </tr>
                                    </>) : ('')}
                                  </>
                                )))}
                              </tbody>
                              {/*end::Tbody*/}
                            </table>
                            {/*end::Table*/}
                          </div>
                        </div>
                        {/*end::Radio group*/}
                      </div>
                      {/*end::Tap pane*/}
                      {/*begin::Tap pane*/}
                      <div className="tab-pane fade" id="dedicated-memory-tab">
                        {/*begin::Radio group*/}
                        <div className="row" data-kt-buttons="true">
                          <div className="table-responsive">
                            {/*begin::Table*/}
                            <table className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9 tableramcpu">
                              {/*begin::Thead*/}
                              <thead className="border-bottom border-gray-200 fs-6 fw-bolder bg-secondary">
                                <tr>
                                  <th className="w-125px px-0 text-center">RAM</th>
                                  <th className="w-125px pe-8"></th>
                                  <th className="w-125px">vCPU</th>
                                  <th className="w-125px pe-8"></th>
                                  <th className="w-125px sphide">SSD Disk</th>
                                  <th className="w-125px pe-8"></th>
                                  <th className="w-125px">Bandwidth</th>
                                  <th className="w-125px pe-8"></th>
                                  <th className="w-125px ps-0 text-start">
                                    Price
                                  </th>
                                </tr>
                              </thead>
                              {/*end::Thead*/}
                              {/*begin::Tbody*/}
                              <tbody
                                id="memory-plan"
                                className="fs-6 fw-bold text-gray-600"
                              >
                                {planData != null && planData?.length > 0 ? (
                                  planData.map((value, index) => (
                                    <>
                                      {value.slug == 'dedicated-memory' ? (<>
                                        <tr class="planseffect" onClick={() => selectNodeSize(value.id, value.ram / 1024, value.cpu, value.disk, value.bandwidth, parseFloat(value.price / 720).toFixed(2), value.currencyprefix)}>
                                          <td class="ps-0 text-center sphide">{value.ram / 1024} GB</td>
                                          <td></td>
                                          <td className="sphide">
                                            {value.dedicated_vcore == "1" ? (<>
                                              {value.cpu} vCPU <br /><span className="badge badge-success">Dedicated</span>
                                            </>) : (<>
                                              {value.cpu} vCPU
                                            </>)}</td>
                                          <td></td>
                                          <td className="sphide">{value.disk} GB</td>
                                          <td></td>
                                          <td className="sphide">{value.bandwidth} GB</td>
                                          <td></td>
                                          <td className="sphide">
                                            <span class="badge badge-light-success fs-7 fw-bolder  me-3">{user_data.current != null ? (user_data.current.user.currencyprefix) : ('')} {parseFloat(value.price / 720).toFixed(2)}/hourly</span>
                                            {value.free_availabel == 1 ? (
                                              <span class="badge badge-warning fs-base">Free {value.free_days} days</span>
                                            ) : ('')}

                                          </td>
                                        </tr>
                                      </>) : ('')}
                                    </>
                                    // Your code here
                                  ))
                                ) : (
                                  <><TableLoader /></>
                                )}

                              </tbody>
                              {/*end::Tbody*/}
                            </table>
                            {/*end::Table*/}
                          </div>
                        </div>
                        {/*end::Radio group*/}
                      </div>
                      {/*end::Tap pane*/}
                      {/*begin::Tap pane*/}
                      <div className="tab-pane fade" id="dedicated-cpu-tab">
                        {/*begin::Radio group*/}
                        <div className="row" data-kt-buttons="true">
                          <div className="table-responsive" id="ramcpuplans">
                            {/*begin::Table*/}
                            <table className="table table-row-bordered table-flush align-middle gy-6 tableramcpu">
                              {/*begin::Thead*/}
                              <thead className="border-bottom border-gray-200 fs-6 fw-bolder bg-secondary">
                                <tr>
                                  <th className="w-125px px-0 text-center">RAM</th>
                                  <th className="w-125px pe-8"></th>
                                  <th className="w-125px">vCPU</th>
                                  <th className="w-125px pe-8"></th>
                                  <th className="w-125px sphide">SSD Disk</th>
                                  <th className="w-125px pe-8"></th>
                                  <th className="w-125px">Bandwidth</th>
                                  <th className="w-125px pe-8"></th>
                                  <th className="w-125px ps-0 text-start">
                                    Price
                                  </th>
                                </tr>
                              </thead>
                              {/*end::Thead*/}
                              {/*begin::Tbody*/}
                              <tbody
                                id="cpu-plan"
                                className="fs-6 fw-bold text-gray-600"
                              >
                                {planData != null && (planData && planData.map((value, index) => (
                                  <>
                                    {value.slug == 'dedicated-cpu' ? (<>
                                      <tr class="planseffect" onClick={() => selectNodeSize(value.id, value.ram / 1024, value.cpu, value.disk, value.bandwidth, parseFloat(value.price / 720).toFixed(2), value.currencyprefix)}>
                                        <td class="ps-0 text-center sphide">{value.ram / 1024} GB</td>
                                        <td></td>
                                        <td className="sphide">
                                          {value.dedicated_vcore == "1" ? (<>
                                            {value.cpu} vCPU <br /><span className="badge badge-success">Dedicated</span>
                                          </>) : (<>
                                            {value.cpu} vCPU
                                          </>)}</td>
                                        <td></td>
                                        <td className="sphide">{value.disk} GB</td>
                                        <td></td>
                                        <td className="sphide">{value.bandwidth} GB</td>
                                        <td></td>
                                        <td className="sphide">
                                          <span class="badge badge-light-success fs-7 fw-bolder  me-3">{user_data.current != null ? (user_data.current.user.currencyprefix) : ('')} {parseFloat(value.price / 720).toFixed(2)}/hourly</span>
                                          {value.free_availabel == 1 ? (
                                            <span class="badge badge-warning fs-base">Free {value.free_days} days</span>
                                          ) : ('')}

                                        </td>
                                      </tr>
                                    </>) : ('')}
                                  </>
                                )))}
                              </tbody>
                              {/*end::Tbody*/}
                            </table>
                            {/*end::Table*/}
                          </div>
                        </div>
                        {/*end::Radio group*/}
                      </div>
                      {/*end::Tap pane*/}
                    </div>
                    {/*end::Tab Content*/}
                  </div>
                  {/*end: Card Body*/}
                  {/*end::List widget 10*/}
                </div>
                {/*end::Col*/}
              </div>
              {/*end::Modal body*/}
              {/*begin::Modal footer*/}
              {/* <div class="modal-footer ">
              <button type="button" id="importkey-btn"
                  onclick="call_api('POST','key/import','importkey','getDeployAPI')" class="btn btn-primary">
                  <span class="indicator-label">Import SSH Key</span>
              </button>
          </div> */}
              {/*end::Modal footer*/}
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Messenger*/}
        </div>
      </>) : (<><TopBarHeaderLine /><TableLoader /></>)
      }
    </>

  )

}

const mapStateToProps = state => ({
  nodePoolData: state.kubernetes.deployPoolRow,
  deployInfo: state.autoScalingReducerData.autoScalingData
})

const mapDispatchToProps = dispatch => ({
  updateRow: data => dispatch(DeployNodePoolRow(data)),
  updateAutoScalingData: (data) => dispatch(DeployAutoScalingInfo(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(KubernetesDeploy) 