import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom/dist";
import UsePost from "../../../../CustomHooks/usePost";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UseApi from "../../../../CustomHooks/useApi";
import { connect } from "react-redux";
import "./custom.css";
import { AddDatabaseDeploy, AddDatabaseNodeCount } from "../../../../Redux/Services/Action/actions";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import PricingContainer from "../pricing/PricingContainer";
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../Loaders/TableLoader";
import PricingContainerWithoutBillingCycle from "../pricing/PricingContainerWithoutBillingCycle";
import { publicIp, publicIpv4, publicIpv6 } from 'public-ip';
import Dczone from "../../utils/dczone/Dczone";
 var serialize = require("form-serialize");
function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}

const DeployContainerRegistry = (props) => {
  const [location, setLocation] = useState("innoida")
  const [nodeCount, setNodeCount] = useState([]);
  const forceUpdate = useForceUpdate();
  const [adcdata, setAdcdata] = useState([]);
  const [ddcdata, setDdcdata] = useState([]);
  const [vpcLoader, setVpcLoader] = useState(false);
  const [vpcArray, setVpcArray] = useState([])
  const [firewallData, setFirewallData] = useState(null);
  const [distroData, setDistroData] = useState(null);
  const [securityGroups, setSecurityGroups] = useState([])
  const [vpc_id, setVpc_id] = useState('')
  const [vpcMultipleInfo, setVpcMultipleInfo] = useState([]);
  const [vpcData, setVpcData] = useState(null);
  const dataFetchedRef2 = useRef();
  const [planData, setPlanData] = useState([]);
  const [userData, setUserData] = useState(null);
  const user_data = useRef(null);
  const dczoneStatus = useRef(true);

  const navigate = useNavigate();
  const navRedirect = () => {
    setTimeout(() => {
      navigate('/database-cluster');
    }, 2000);

  }

  const getDczone = async () => {
    const data = await UseApi("get", "dczones", "");
    user_data.current = await UseApi("get", "account/info", "");
    const PlanData = await UseApi("get", "plans?type=db_rdbms", "");
    if (PlanData) {
      setPlanData((prePlanData) => [...PlanData.plans]);
    }
    setUserData(user_data.current.user);
    if (Object.keys(data).length === 0) {
      setAdcdata([]);
      setDdcdata([]);
    } else {
      if (dczoneStatus.current) {
        data.dczones.map(async (value, index) => {
          console.log(value, "==========>>>>");
          if (value.slug == 'innoida') {
            if (value.status !== "active") {
                  var server_ip = await publicIpv4();
                  if (server_ip) {
                    if (
                      server_ip !== "103.209.144.51" ||
                      server_ip !== "103.209.144.66" ||
                      server_ip !== "103.209.145.208" ||
                      server_ip !== "103.209.144.81" ||
                      server_ip !== "103.209.144.82" ||
                      server_ip !== "103.209.144.83" ||
                      server_ip !== "103.127.30.91" ||
                      server_ip !== "103.209.144.60" ||
                      server_ip !== "103.127.30.239" ||
                      server_ip !== "103.127.28.52"
                    ) {
                      ddcdata.push(value);
                    }
                    forceUpdate();
                  }
            } else {
              adcdata.push(value);
              forceUpdate();
            }
          }
        });
        dczoneStatus.current = false;
      }
      setTimeout(() => {
        var clickTo = document.querySelector("#dcslugl_innoida");
        if (clickTo) {
          clickTo.click();
        }
      }, 1000)

    }
  };

  useEffect(() => {
    if (dataFetchedRef2.current) return;
    dataFetchedRef2.current = true;
    getDczone();
  }, []);

  const selectDc = async (slug) => {
    var dczone_slug = document.querySelector("#dczone_slug");
    console.log(dczone_slug);
    if (dczone_slug) {
      dczone_slug.value = slug;
    }
    setLocation(slug)
    // if (props) {
    //   if (props.deployInfo[0]) {
    //     if (props.deployInfo[0].dcslug != slug) {
    //       const updatedObject = { ...props.deployInfo[0], dcslug: slug };
    //       const newArray = [updatedObject];
    //       props.updateDeployInfo(newArray);
    //     }
    //   }
    // }
    var dcSelect = document.querySelectorAll([".tickMarkDc"]);
    var currentDc = document.querySelector("#tickMarkDc-" + slug);
    if (dcSelect) {
      dcSelect.forEach((clist) => {
        clist.classList.add("d-none");
      });
    }
    if (currentDc) {
      currentDc.classList.remove("d-none");
    }
    const vpc_data = await UseApi("get", "vpc/dc/" + slug, "");
    if (vpc_data.status === "error") {
      setVpcLoader(false);
      setVpcData(null);
    } else {
      setVpcLoader(false);
      delete vpc_data.html;
      if (Object.keys(vpc_data).length != 0) {
        var vpcArr = Object.values(vpc_data);
        setVpcData(vpcArr);
      }
    }
  };
  const makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result.toLowerCase();
  };
  // end- select plans
  function ChangeScreen() {
    useEffect(() => {
      function handleResize() {
        const sideCheckout = document.querySelector("#sideCheckout");
        if (sideCheckout) {
          if (window.innerWidth > 990) {
            sideCheckout.classList.add("mhc_checkout");
          } else {
            sideCheckout.classList.remove("mhc_checkout");
          }
        }
      }
      handleResize();
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
    return null;
  }

  const [allowIp, setAllowIp] = useState(null);
  function getAllowIp() {
    if (props) {
      if (props.nodeCountData) {
        setAllowIp(props.nodeCountData);
      }
    }
  }
  // useEffect(() => {
  //   let vdata = [];
  //   if (vpcData && vpcData.length > 0) {
  //     vpcData.map((value, index) => {

  //       vdata.push(value[1].name + " (" + value[1].id + ")");
  //     })
  //   }

  //   setVpcMultipleInfo(vdata);
  // }, [vpcData])
  useEffect(() => {
    getAllowIp();
    updateDeploy();
    getDistro();
  }, [props])
  const addIpField = async () => {
    if (props) {
      if (props.nodeCountData) {
        const newArray = [...props.nodeCountData, makeid(8)];
        props.updateNodeCountData(newArray);
        //   console.log(newArray);
      }
    }
  }
  const deleteIpField = async (ip_id) => {
    if (props) {
      if (props.nodeCountData) {
        const newArray = props.nodeCountData;
        // console.log(newArray);
        for (var i = 0; i < newArray.length; i++) {
          if (newArray[i] == ip_id) {
            const index = newArray.indexOf(newArray[i]);
            if (index > -1) { // only splice array when item is found
              newArray.splice(index, 1); // 2nd parameter means remove one item only
            }
          }
        }
        //    console.log(newArray);
        props.updateNodeCountData([]);
        props.updateNodeCountData(newArray);

      }
    }
  }
  const getDistro = async () => {
    toast.dismiss();
    setDistroData(null);
    var dc = "innoida"
    // if(props && props.deployInfo[0] && props.deployInfo[0].dcslug){
    //      dc=props.deployInfo[0].dcslug;
    // }
    const data = await UseApi("get", "cloud/getdeploy?dcslug=" + dc, "");
    user_data.current = await UseApi("get", "account/info", "");
    setUserData(user_data.current.user);
    if (data.firewalls.length <= 0) {
      setFirewallData(null);
    } else {
      setVpcLoader(false)
      setFirewallData(data.firewalls);
    }
  };
  useEffect(() => {
    var vpc_field = document.querySelector("#vpc_field");
    if (vpc_field && vpcMultipleInfo) {
      //console.log(vpcMultipleInfo);
      const contactTagify = new window.Tagify(vpc_field, {
        whitelist: vpcMultipleInfo,
        maxTags: 20,
        dropdown: {
          maxItems: 20,
          classname: "",
          enabled: 0,
          closeOnSelect: false
        }
      });
    }
  }, [vpcMultipleInfo])


  useEffect(() => {
    var firewall_field = document.querySelector("#firewall_field");
    if (firewall_field && firewallData) {
      var fdata = [];
      firewallData.map((value, index) => {
        fdata.push(value.name + " (" + value.id + ")");
      })
      const contactTagify = new window.Tagify(firewall_field, {
        whitelist: fdata,
        maxTags: 20,
        dropdown: {
          maxItems: 20,
          classname: "",
          enabled: 0,
          closeOnSelect: false
        }
      });
    }
  }, [firewallData])
  function updateNewVpc(e) {
    if (e.target.value) {
      var eleData = JSON.parse(e.target.value);
      var eleIds = eleData.map(item => {
        // Extract string portion from the value within parentheses
        var match = item.value.match(/\(([^)]+)\)/);
        return match ? match[1] : null;
      }).filter(Boolean); // Filter out null values

      // Concatenate the extracted strings into a comma-separated string
      var concatenatedVpcIds = eleIds.join(',');
      let arr = []
      arr.push(concatenatedVpcIds)
      const filteredFirewallIds = arr.filter(id => id !== '');
      setVpcArray(() => [...filteredFirewallIds])
      // // Set the concatenated string as the value of the add_cloud input
      // if (props && props.deployInfo[0]) {
      //   props.deployInfo[0].vpc = concatenatedVpcIds;
      // }
    }
  }
  function updateNewFirewall(e) {
    if (e.target.value) {
      var eleData = JSON.parse(e.target.value);
      var eleIds = eleData.map(item => {
        // Extract numeric portion from the value (assuming it's always in parentheses)
        var match = item.value.match(/\((\d+)\)/);
        return match ? match[1] : null;
      }).filter(Boolean); // Filter out null values

      // Concatenate the cloud IDs into a comma-separated string
      var concatenatedFirewallIds = eleIds.join(',');
      let arr = []
      arr.push(concatenatedFirewallIds)
      const filteredFirewallIds = arr.filter(id => id !== '');
      setSecurityGroups(() => [...filteredFirewallIds])

      // // Set the concatenated string as the value of the add_cloud input
      // if (props && props.deployInfo[0]) {
      //   props.deployInfo[0].security_groups = concatenatedFirewallIds;
      // }
    }
  }

  const [databaseData, setDatabaseData] = useState(null);
  const getDatabases = async () => {

    const data = {
      "databases": [
        {
          "id": "1",
          "appid": "1",
          "type": "SQL",
          "name": "MySQL",
          "slug": "mariadb",
          "versions": "mariadb-version-15.1",
          "status": "active",
        },
        {
          "id": "2",
          "appid": "1",
          "type": "SQL",
          "name": "Postgre SQL",
          "slug": "pg",
          "versions": "pg-version-16,pg-version-15,pg-version-14,pg-version-13",
          "status": "active",
        }
      ]
    }
    if (data) {
      setDatabaseData(data.databases);
    }
  }

  useEffect(() => {
    getDatabases();
  }, []);


  const databaseVersion = (dbImage) => {
    var pselect = document.querySelectorAll([".deploy_dbVersioncontainer"]);
    var pheight = document.querySelectorAll([".versionDb"]);
    pselect.forEach((clist) => {
      clist.classList.remove("d-block");
    });
    //var cl=event.currentTarget.querySelector('.versionOs');

    pheight.forEach((clist) => {
      clist.style.height = "auto";
      clist.classList.remove("active");
    });

    const database_version_list = document.querySelector(
      "#database_version_list_" + dbImage
    );
    database_version_list.classList.add("d-block");
  };

  function database_Name(str) {
    if (!str) {
      return "";
    }
    // Split the string into an array using the delimiter "-"
    var arr = str.split("-");

    // Return the element at the 0th index
    return arr[0].toLowerCase();
  }
  function database_Version(str) {
    if (!str) {
      return "";
    }
    // Split the string into an array using the delimiter "-" 
    var arr = str.split("-");

    // Return the element at the 0th index
    return arr[2].toLowerCase();
  }

  const selectDb = (slug, version) => {
    if (props) {
      if (props.deployInfo[0]) {
        if (props.deployInfo[0].db_version != version) {
          const updatedObject = { ...props.deployInfo[0], db_version: version };
          const newArray = [updatedObject];
          props.updateDeployInfo(newArray);
          console.log(newArray);
        }
      }
    }

    var database_version = document.querySelector("#database_Version");
    if (database_version) {
      database_version.value = database_Version(version);
    }
    var database_engine = document.querySelector("#database_engine");
    if (database_engine) {
      database_engine.value = database_Name(version);
    }

    var osSelect = document.querySelectorAll([".tickMarkDb"]);
    var currentOs = document.querySelector("#tickMarkDb-" + slug);
    if (osSelect) {
      osSelect.forEach((clist) => {
        clist.classList.add("d-none");
      });
    }
    if (currentOs) {
      currentOs.classList.remove("d-none");
    }

    const os_cost = document.querySelector("#selectedDatabaseVersion");
    if (os_cost) {
      os_cost.value = version;
    }
    const current_db_version = document.querySelector("#database_version_list_" + slug);
    if (current_db_version) {
      current_db_version.classList.remove("d-block");
    }
    const all_radio = document.getElementsByClassName("database_radio");
    if (all_radio) {
      for (var i = 0; i < all_radio.length; i++) {
        all_radio[i].checked = false;
      }
    }
    const all_label = document.getElementsByClassName("versionDb");
    if (all_label) {
      for (var i = 0; i < all_label.length; i++) {
        //all_label[i].classList.remove('active');
        all_label[i].classList.remove("shadowBox");
      }
    }
    var pselect = document.querySelectorAll([".deploy_dbversioncontainer"]);
    if (pselect) {
      pselect.forEach((clist) => {
        clist.classList.remove("d-block");
      });
    }
    const all_versionText = document.getElementsByClassName(
      "dbversionselectmultiple"
    );
    if (all_versionText) {
      for (var i = 0; i < all_versionText.length; i++) {
        all_versionText[i].innerHTML = "Select Version";
      }
    }


    const current_radio = document.querySelector("#select_" + slug);
    if (current_radio) {
      current_radio.checked = true;
    }
    const current_label = document.querySelector(
      "#versionDb_" + slug
    );
    if (current_label) {
      current_label.classList.add("shadowBox");
    }
    //current_label.classList.add('active');
    const current_select_version = document.querySelector(
      "#dbversion_" + slug
    );
    if (current_select_version) {
      current_select_version.innerHTML = version;
    }
  };



  const addDatabase = async () => {
    var submitBtn = document.querySelector("#database-btn");
    if (submitBtn) {
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      const dt = toast.loading("Please wait...");
      const responseData = await UsePost('post', 'databases', 'databaseDeploy');
      if (responseData) {
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
        if (responseData.status === 'error') {
          toast.update(dt, {
            render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        } else if (responseData.status === 'success') {
          toast.update(dt, {
            render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          navRedirect();
        }
      }

    }
  }
  function databaseName(str) {
    // Split the string into an array using the delimiter "-"
    if (str != "" && str != undefined && str != null) {
      var arr = str.split("-");

      // Return the element at the 0th index
      return arr[0].toLowerCase();
    }
  }


  function updateDeploy() {
    if (props && props.deployInfo[0]) {
      if (props.deployInfo[0].db_version != "") {
        var database_version = document.querySelector("#database_version");
        var database_engine = document.querySelector("#database_engine");
        if (database_version && database_engine) {
          database_version.value = database_Version(props.deployInfo[0].db_version);
          database_engine.value = database_Name(props.deployInfo[0].db_version);
          var dbslug = databaseName(props.deployInfo[0].db_version);
          selectDb(dbslug, props.deployInfo[0].db_version);
        }
      }
      //   if(props.deployInfo[0].price!=""){
      //   var plan_value=document.querySelector("#plan_value");
      //   if(plan_value){
      //     plan_value.value=props.deployInfo[0].plan_id;
      //     show_plan_price(props.deployInfo[0].plan_id, props.deployInfo[0].price)
      //   }
      // }
      // if(props.deployInfo[0].billingcycle!=""){
      //   var billing_info=document.querySelector("#billing_info");
      //   if(billing_info){
      //     billing_info.value=props.deployInfo[0].billingcycle;
      //   }
      // }
      if (props.deployInfo[0].nodes_count != "") {
        var replica_input = document.querySelector("#replica_input");
        if (replica_input) {
          replica_input.value = props.deployInfo[0].nodes_count;
        }
      }
      if (props.deployInfo[0].cluster_label != "") {
        var db_label = document.querySelector("#db_label");
        if (db_label) {
          db_label.value = props.deployInfo[0].cluster_label;
        }
      }
      if (props.deployInfo[0].dcslug != "") {
        var dczone_slug = document.querySelector("#dczone_slug");
        if (dczone_slug) {
          dczone_slug.value = props.deployInfo[0].dcslug;
          selectDc(props.deployInfo[0].dcslug);
        }
      }
    }
    calcFinalCost();
  }

  useEffect(() => {
    if (props) {
      updateDeploy();
      // console.log(props.deployInfo[0]);
    }
  }, [props])

  function checkInput(val) {
    var replica_input = document.querySelector("#replica_input");
    if (replica_input) {
      if (val < 1) {
        replica_input.value = 1;
      }
    }
    calcFinalCost();
  }

  function calcFinalCost() {
    var current_plan_price = document.querySelector("#plan_cost");
    // var replica_input = document.querySelector("#replica_input");
    // console.log(current_plan_price.value);
    var finalCost = 0;
    if (current_plan_price) {
      // console.log(parseInt(current_plan_price.value)+" => "+parseInt(replica_input.value));
      finalCost = parseInt(current_plan_price.value);

    }
    var currency_final = document.querySelector("#currency_final");
    var total_cost = document.querySelector("#total_cost");
    var billing_final = document.querySelector("#billing_final");

    if (currency_final && total_cost && billing_final) {
      if (user_data && user_data.current && user_data.current.user) {
        currency_final.innerHTML = user_data.current.user.currencyprefix;
      }
      total_cost.innerHTML = finalCost;
      const billing_info = document.querySelector("#billing_info");
      if (billing_info) {
        if (billing_info.value == "12month") {
          billing_final.innerHTML = " /year";
        } else if (billing_info.value == "monthly") {
          billing_final.innerHTML = " /mon";
        } else if (billing_info.value == "hourly") {
          billing_final.innerHTML = " /mon";
        }
      }
    }
  }


  function updatedPlanInfo(plan = []) {
    // console.log("info ") ;
    //  console.log(props.deployInfo[0]);
    const billing_info = document.querySelector("#billing_info");
    const plan_value = document.querySelector("#size_value");
    const plan_cost = document.querySelector("#plan_cost");

    if (billing_info && plan_value && plan_cost) {
      billing_info.value = plan.billing;
      plan_value.value = plan.planid;
      plan_cost.value = plan.payable_price;

    }
    if (props.deployInfo) {

      props.deployInfo[0].plan_id = plan.planid;
      props.deployInfo[0].billingcycle = plan.billing;
      props.deployInfo[0].price = plan.payable_price;
    }
    calcFinalCost();
  }
  const selectTickMark = (sshid, name) => {
    var allTickmarks = document.querySelectorAll([".tickMark" + name]);
    var currentPlan = document.querySelector("#tickMark" + name + "-" + sshid);
    var mainTickMark = document.querySelectorAll([".border" + name]);
    var currentMain = document.querySelector("#border" + name + "-" + sshid);
    setVpc_id(sshid)
    // props.deployInfo[0].vpc = sshid
    if (mainTickMark) {
      mainTickMark.forEach((clist) => {
        clist.classList.remove("activeBorder");
      });
    }
    if (allTickmarks) {
      allTickmarks.forEach((clist) => {
        clist.classList.add("d-none");
      });
    }
    if (currentPlan) {
      currentPlan.classList.remove("d-none");
    }
    if (currentMain) {
      currentMain.classList.add("activeBorder");
    }
  };

  const handleChangeReplicaCount = (status) => {
    let plusElement = document.querySelector(`#replica_up`)
    let inputElement = document.querySelector("#replica_input")
    let minusElement = document.querySelector('#replica_down')

    const increaseReplicaCount = () => {
      minusElement.classList.remove('disabled');
      if (parseInt(inputElement.value) < 3) {
        inputElement.value = parseInt(inputElement.value) + 1;
      } else {
        plusElement.classList.add('disabled');
      }
    };
    const decreaseReplicaCount = () => {
      plusElement.classList.remove('disabled');
      if (parseInt(inputElement.value) > 0) {
        inputElement.value = parseInt(inputElement.value) - 1;
      } else {
        minusElement.classList.add('disabled');
      }
    };

    if (status == 'up') {
      increaseReplicaCount();
    } else if (status == 'down') {
      decreaseReplicaCount();
    }
    calcFinalCost()
  }


  const choosePlan = (pid) => {
    let diskSize = document.querySelector(`#disk_size_${pid}`)
    let priceElement = document.querySelector(`#price_${pid}`)
    let planType = document.querySelector(`#plan_type_${pid}`)
    let repoSize = document.querySelector(`#repo_size_${pid}`)
    let selectElement = document.getElementById("selected_type");
    let selectedValue = selectElement.value;
    const priceText = priceElement.textContent.trim();
    console.log(priceText);
    var price = 0
    if (priceText.toLowerCase() === 'free') {
      price = 0;
    } else {
      const match = priceText.match(/(\d+(\.\d+)?)/);

      // Check if there is a match and get the first capturing group
      price = match ? match[1] : 0;
      // price = parseFloat(priceText.replace(/[^0-9.]/g, ''));

    }
    let assignValueToPlan = document.querySelector("#plan_value")
    let assignValueToPrice = document.querySelector("#plan_cost")
    let assignValueToRepo = document.querySelector("#repo_value")
    let assignValueToDisk = document.querySelector("#disk_value")
    let assignValueToType = document.querySelector("#registry_type")
    assignValueToDisk.value = diskSize.textContent
    assignValueToRepo.value = repoSize.textContent
    assignValueToPlan.value = planType.textContent
    assignValueToPrice.value = price
    assignValueToType.value = selectedValue === "public" ? true : false
    for (let i = 1; i <= 4; i++) {
      var planActive = document.querySelector("#plan-active-" + i);
      var ribbonActive = document.querySelector("#ribbon-active-" + i);
      if (planActive && ribbonActive) {
        if (pid === i) {
          planActive.classList.add("active-plan");
          ribbonActive.classList.remove("d-none");
        } else {
          planActive.classList.remove("active-plan");
          ribbonActive.classList.add("d-none");
        }
      }
    }
    calcFinalCost()
  }

  setTimeout(() => {
    var clickTo = document.querySelector("#plan-active-3");
    if (clickTo) {
      clickTo.click();
    }
  }, 1000)

  const handleChangeRegistryType = (type) => {
    let assignValueToType = document.querySelector("#registry_type")
    assignValueToType.value = type === "public" ? true : false
  }
  const handleCreateRegistry = async () => {
    try {
      var submitBtn = document.querySelector("#deployregistry-btn");
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      toast.dismiss();
      const dt = toast.loading("container registry creating, Please wait...");
      const responseData = await UsePost("post", "registry", "registryDeploy")
      if (responseData.status === "success") {
        toast.update(dt, {
          render: responseData.message,
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        window.onDeployEvents('create_container_registry');
        setTimeout(() => {
          navigate('/container-registry')
        }, 1500)
      } else {
        toast.update(dt, {
          render: responseData.message,
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML = preContent;
    } catch (error) {
      console.log(error);
    }
  }

  const handleChange = (e) => {
    const inputElement = e.target;
    const value = inputElement.value;
    const regex = /^[a-zA-Z0-9-]*$/; // Allow alphanumeric characters and hyphens

    if (!regex.test(value)) {
      // inputElement.style.borderColor = 'red';
      // document.getElementById('error-message').textContent = 'Only alphanumeric characters and hyphens are allowed.';
      // Remove invalid characters
      inputElement.value = value.replace(/^-+|-+$/g, '');
    } else {
      // inputElement.style.borderColor = 'black';
      // document.getElementById('error-message').textContent = '';
    }
  }
  return (
    <>
      {props && adcdata.length > 0 ? (<>
        <ChangeScreen />
        <form id="registryDeploy">
          <input
            type="hidden"
            name="dczone"
            id="dczone_slug"
            className="form-control text-center"
          // defaultValue={props?.deployInfo[0]?.dcslug}
          />
          {/*begin::Tables Widget 9*/}
          <div className=" col-xl-12 card mb-5">
            {/*begin::data center*/}
            <Dczone adcdata={adcdata} ddcdata={ddcdata} selectDc={selectDc} location={location} />
            {/* End data center */}
          </div>
          {/*begin::Col*/}
          <input
            type="hidden"
            name="size"
            id="size_value"
            defaultValue={
              props.deployInfo && props.deployInfo
                ? props.deployInfo[0].plan_id
                : ""
            }
          />
          <input
            type="hidden"
            id="plan_cost"
            defaultValue={
              props.deployInfo && props.deployInfo
                ? props.deployInfo[0].price
                : ""
            }
          />
          <input
            type="hidden"
            name="billing"
            id="billing_info"
            defaultValue={
              props.deployInfo && props.deployInfo
                ? props.deployInfo[0].billingcycle
                : ""
            }
          />

          <div className="col-xl-12 card mb-5 d-none">
            <div className="card-header border-3 mb-5 pt-2">
              {/*begin::Title*/}
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder text-gray-800">
                  Select Plan Type
                </span>
                <span className="text-gray-400 mt-1 fw-bold fs-6">
                  Choose plan for your container
                </span>
              </h3>
            </div>
            <div className="mx-5 mb-3 mt-5">
              <div className="row gy-5 g-xl-10">
                {/*begin::Col*/}
                <div className="col-xl-4">
                  {/*begin::item plan*/}
                  <div id="plan-active-1" onClick={(e) => choosePlan(1)} className=" position-relative card card-flush h-xl-100 uptrans border border-1 border-gray-300 cr-plan">
                    {/*begin::Header*/}
                    <div className=" text-center pt-3">
                      {/*begin::Title*/}
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bolder text-gray-800" id="plan_type_1">
                          Buisness
                        </span>
                      </h3>
                      {/*end::Title*/}
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body pt-4">
                      {/*begin::Item*/}
                      <div className="text-center flex-stack">
                        {/*begin::Section*/}
                        <div className="align-items-center">

                          {/*begin::Content*/}
                          <del>
                            {/*begin::Title*/}
                            <a

                              className="text-center text-gray-800 fw-bolder text-hover-primary fs-6"
                              id="price_1"
                            >
                              Rs.1655.29 /mon
                            </a>
                            {/*end::Title*/}
                          </del>
                          <div className="badge badge-success me-2"><span className="fs-400">Free</span></div>
                          {/*end::Content*/}
                        </div>
                        {/*end::Section*/}
                      </div>
                      {/*end::Item*/}
                      {/*begin::Separator*/}
                      <div className="separator separator-dashed my-5" />
                      {/*end::Separator*/}

                      {/*begin::Item*/}
                      <div className="d-flex flex-stack">
                        {/*begin::Section*/}
                        <div className="d-flex align-items-center me-5">
                          {/*begin::Symbol*/}
                          <div className="symbol symbol-40px me-4">
                            <span className="symbol-label">
                              <i className="fonticon-ship fs-1 p-0 " style={{ color: "#494b74" }} />
                            </span>
                          </div>
                          {/*end::Symbol*/}
                          {/*begin::Content*/}
                          <div className="me-5">
                            {/*begin::Title*/}
                            <a

                              className="text-gray-800 fw-bolder text-hover-primary fs-6"
                            >
                              Repository
                            </a>
                            {/*end::Title*/}
                            {/*begin::Desc*/}
                            <span className="text-gray-400 fw-bold fs-7 d-block text-start ps-0">
                              Containers
                            </span>
                            {/*end::Desc*/}
                          </div>
                          {/*end::Content*/}
                        </div>
                        {/*end::Section*/}
                        {/*begin::Wrapper*/}
                        <div className="text-gray-400 fw-bolder fs-7 text-end">
                          {/*begin::Number*/}
                          <span className="text-gray-800 fw-bolder fs-6 d-block sphide" id="repo_size_1">
                            Endless
                          </span>
                          {/*end::Number*/}
                        </div>
                        {/*end::Wrapper*/}
                      </div>
                      {/*end::Item*/}
                      {/*begin::Separator*/}
                      <div className="separator separator-dashed my-5" />
                      {/*end::Separator*/}
                      {/*begin::Item*/}
                      <div className="d-flex flex-stack">
                        {/*begin::Section*/}
                        <div className="d-flex align-items-center me-5">
                          {/*begin::Symbol*/}
                          <div className="symbol symbol-40px me-4">
                            <span className="symbol-label">
                              <svg
                                width={24}
                                height={24}
                                viewBox="0 0 84 84"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g
                                  id="DBaaS-Icon-1C-BLK"
                                  transform="translate(0.189181, -0.000000)"
                                  fill="#494b74"
                                  fillRule="nonzero"
                                >
                                  <path d="m66.7 48.9c-7.7 2.1-17.7 3.3-28.4 3.3s-20.8-1.2-28.4-3.3c-2.2-.6-4.2-1.3-5.9-2v22c0 5.2 15.3 9.4 34.3 9.4s34.3-4.2 34.3-9.4v-22c-1.8.8-3.7 1.4-5.9 2zm-6.3 19.8c-2.6 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6 4.6 2.1 4.6 4.6-2 4.6-4.6 4.6z" />
                                  <path d="m38.3 5.1c-19 0-34.3 4.2-34.3 9.4v25.4c3.6 2.4 14.8 6.1 34.3 6.1 19.4 0 30.7-3.7 34.3-6.1v-25.4c-.1-5.2-15.4-9.4-34.3-9.4zm22.1 31.7c-2.6 0-4.6-2.1-4.6-4.6 0-2.6 2.1-4.6 4.6-4.6s4.6 2.1 4.6 4.6c.1 2.5-2 4.6-4.6 4.6zm-22.1-14.3c-20.9 0-32.3-4.9-32.3-7.4s11.4-7.4 32.3-7.4 32.3 4.9 32.3 7.4-11.5 7.4-32.3 7.4z" />
                                </g>
                              </svg>

                              {/* <i className="fonticon-database fs-1 p-0 text-gray-600" /> */}
                            </span>
                          </div>
                          {/*end::Symbol*/}
                          {/*begin::Content*/}
                          <div className="me-5">
                            {/*begin::Title*/}
                            <a

                              className="text-gray-800 fw-bolder text-hover-primary fs-6"
                            >
                              Storage
                            </a>
                            {/*end::Title*/}
                            {/*begin::Desc*/}
                            <span className="text-gray-400 fw-bold fs-7 d-block text-start ps-0">
                              Disk size
                            </span>
                            {/*end::Desc*/}
                          </div>
                          {/*end::Content*/}
                        </div>
                        {/*end::Section*/}
                        {/*begin::Wrapper*/}
                        <div className="text-gray-400 fw-bolder fs-7 text-end">
                          {/*begin::Number*/}
                          <span className="text-gray-800 fw-bolder fs-6 d-block sphide" id="disk_size_1">
                            100 GB
                          </span>
                          {/*end::Number*/}
                        </div>
                        {/*end::Wrapper*/}
                      </div>
                      {/*end::Item*/}
                    </div>
                    {/*end::Body*/}
                    <div id="ribbon-active-1" className="ribbon ribbon-triangle ribbon-top-start border-primary d-none" style={{ borderRadius: '6px', borderTopLeftRadius: 'initial', borderBottomRightRadius: 'initial' }}>
                      <div className="ribbon-icon mt-n5">
                        <i className="bi bi-check2 fs-2 text-white" />
                      </div>
                    </div>
                  </div>
                  {/*end::item plan*/}
                </div>
                <div className="col-xl-4">
                  {/*begin::item plan*/}
                  <div id="plan-active-2" onClick={(e) => choosePlan(2)} className=" position-relative card card-flush h-xl-100 uptrans border border-1 border-gray-300 cr-plan">
                    {/*begin::Header*/}
                    <div className=" text-center pt-3">
                      {/*begin::Title*/}
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bolder text-gray-800" id="plan_type_2">
                          Indivisual
                        </span>
                      </h3>
                      {/*end::Title*/}
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body pt-4">
                      {/*begin::Item*/}
                      <div className="text-center flex-stack">
                        {/*begin::Section*/}
                        <div className="align-items-center">

                          {/*begin::Content*/}
                          <del className="">
                            {/*begin::Title*/}
                            <a
                              className="text-center text-gray-800 fw-bolder text-hover-primary fs-6"
                              id="price_2"
                            >
                              Rs.827.64 /mon
                            </a>
                            {/*end::Title*/}
                          </del>
                          {/*end::Content*/}
                          <div className="badge badge-success me-2"><span className="fs-400">Free</span></div>
                        </div>
                        {/*end::Section*/}
                      </div>
                      {/*end::Item*/}
                      {/*begin::Separator*/}
                      <div className="separator separator-dashed my-5" />
                      {/*end::Separator*/}

                      {/*begin::Item*/}
                      <div className="d-flex flex-stack">
                        {/*begin::Section*/}
                        <div className="d-flex align-items-center me-5">
                          {/*begin::Symbol*/}
                          <div className="symbol symbol-40px me-4">
                            <span className="symbol-label">
                              <i className="fonticon-ship fs-1 p-0 " style={{ color: "#494b74" }} />
                            </span>
                          </div>
                          {/*end::Symbol*/}
                          {/*begin::Content*/}
                          <div className="me-5">
                            {/*begin::Title*/}
                            <a

                              className="text-gray-800 fw-bolder text-hover-primary fs-6"
                            >
                              Repository
                            </a>
                            {/*end::Title*/}
                            {/*begin::Desc*/}
                            <span className="text-gray-400 fw-bold fs-7 d-block text-start ps-0">
                              Containers
                            </span>
                            {/*end::Desc*/}
                          </div>
                          {/*end::Content*/}
                        </div>
                        {/*end::Section*/}
                        {/*begin::Wrapper*/}
                        <div className="text-gray-400 fw-bolder fs-7 text-end">
                          {/*begin::Number*/}
                          <span className="text-gray-800 fw-bolder fs-6 d-block sphide" id="repo_size_2">
                            5 Repo
                          </span>
                          {/*end::Number*/}
                        </div>
                        {/*end::Wrapper*/}
                      </div>
                      {/*end::Item*/}
                      {/*begin::Separator*/}
                      <div className="separator separator-dashed my-5" />
                      {/*end::Separator*/}
                      {/*begin::Item*/}
                      <div className="d-flex flex-stack">
                        {/*begin::Section*/}
                        <div className="d-flex align-items-center me-5">
                          {/*begin::Symbol*/}
                          <div className="symbol symbol-40px me-4">
                            <span className="symbol-label">
                              <svg
                                width={24}
                                height={24}
                                viewBox="0 0 84 84"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g
                                  id="DBaaS-Icon-1C-BLK"
                                  transform="translate(0.189181, -0.000000)"
                                  fill="#494b74"
                                  fillRule="nonzero"
                                >
                                  <path d="m66.7 48.9c-7.7 2.1-17.7 3.3-28.4 3.3s-20.8-1.2-28.4-3.3c-2.2-.6-4.2-1.3-5.9-2v22c0 5.2 15.3 9.4 34.3 9.4s34.3-4.2 34.3-9.4v-22c-1.8.8-3.7 1.4-5.9 2zm-6.3 19.8c-2.6 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6 4.6 2.1 4.6 4.6-2 4.6-4.6 4.6z" />
                                  <path d="m38.3 5.1c-19 0-34.3 4.2-34.3 9.4v25.4c3.6 2.4 14.8 6.1 34.3 6.1 19.4 0 30.7-3.7 34.3-6.1v-25.4c-.1-5.2-15.4-9.4-34.3-9.4zm22.1 31.7c-2.6 0-4.6-2.1-4.6-4.6 0-2.6 2.1-4.6 4.6-4.6s4.6 2.1 4.6 4.6c.1 2.5-2 4.6-4.6 4.6zm-22.1-14.3c-20.9 0-32.3-4.9-32.3-7.4s11.4-7.4 32.3-7.4 32.3 4.9 32.3 7.4-11.5 7.4-32.3 7.4z" />
                                </g>
                              </svg>

                              {/* <i className="fonticon-database fs-1 p-0 text-gray-600" /> */}
                            </span>
                          </div>
                          {/*end::Symbol*/}
                          {/*begin::Content*/}
                          <div className="me-5">
                            {/*begin::Title*/}
                            <a

                              className="text-gray-800 fw-bolder text-hover-primary fs-6"
                            >
                              Storage
                            </a>
                            {/*end::Title*/}
                            {/*begin::Desc*/}
                            <span className="text-gray-400 fw-bold fs-7 d-block text-start ps-0">
                              Disk size
                            </span>
                            {/*end::Desc*/}
                          </div>
                          {/*end::Content*/}
                        </div>
                        {/*end::Section*/}
                        {/*begin::Wrapper*/}
                        <div className="text-gray-400 fw-bolder fs-7 text-end">
                          {/*begin::Number*/}
                          <span className="text-gray-800 fw-bolder fs-6 d-block sphide" id="disk_size_2">
                            5 GB
                          </span>
                          {/*end::Number*/}
                        </div>
                        {/*end::Wrapper*/}
                      </div>
                      {/*end::Item*/}
                    </div>
                    {/*end::Body*/}
                    <div id="ribbon-active-2" className="ribbon ribbon-triangle ribbon-top-start border-primary d-none" style={{ borderRadius: '6px', borderTopLeftRadius: 'initial', borderBottomRightRadius: 'initial' }}>
                      <div className="ribbon-icon mt-n5">
                        <i className="bi bi-check2 fs-2 text-white" />
                      </div>
                    </div>
                  </div>
                  {/*end::item plan*/}
                </div>
                <div className="col-xl-4">
                  {/*begin::item plan*/}
                  <div id="plan-active-3" onClick={(e) => choosePlan(3)} className=" position-relative card card-flush h-xl-100 uptrans border border-1 border-gray-300 cr-plan">
                    {/*begin::Header*/}
                    <div className=" text-center pt-3">
                      {/*begin::Title*/}
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bolder text-gray-800" id="plan_type_3">
                          Starter
                        </span>
                      </h3>
                      {/*end::Title*/}
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body pt-4">
                      {/*begin::Item*/}
                      <div className="text-center flex-stack">
                        {/*begin::Section*/}
                        <div className="align-items-center">

                          {/*begin::Content*/}
                          <div className="">
                            {/*begin::Title*/}
                            <a

                              className="text-center text-gray-800 fw-bolder text-hover-primary fs-6"
                              id="price_3"
                            >
                              Free
                            </a>
                            {/*end::Title*/}
                          </div>
                          {/*end::Content*/}
                        </div>
                        {/*end::Section*/}
                      </div>
                      {/*end::Item*/}
                      {/*begin::Separator*/}
                      <div className="separator separator-dashed my-5" />
                      {/*end::Separator*/}

                      {/*begin::Item*/}
                      <div className="d-flex flex-stack">
                        {/*begin::Section*/}
                        <div className="d-flex align-items-center me-5">
                          {/*begin::Symbol*/}
                          <div className="symbol symbol-40px me-4">
                            <span className="symbol-label">
                              <i className="fonticon-ship fs-1 p-0 " style={{ color: "#494b74" }} />
                            </span>
                          </div>
                          {/*end::Symbol*/}
                          {/*begin::Content*/}
                          <div className="me-5">
                            {/*begin::Title*/}
                            <a

                              className="text-gray-800 fw-bolder text-hover-primary fs-6"
                            >
                              Repository
                            </a>
                            {/*end::Title*/}
                            {/*begin::Desc*/}
                            <span className="text-gray-400 fw-bold fs-7 d-block text-start ps-0">
                              Containers
                            </span>
                            {/*end::Desc*/}
                          </div>
                          {/*end::Content*/}
                        </div>
                        {/*end::Section*/}
                        {/*begin::Wrapper*/}
                        <div className="text-gray-400 fw-bolder fs-7 text-end">
                          {/*begin::Number*/}
                          <span className="text-gray-800 fw-bolder fs-6 d-block sphide" id="repo_size_3">
                            1 Repo
                          </span>
                          {/*end::Number*/}
                        </div>
                        {/*end::Wrapper*/}
                      </div>
                      {/*end::Item*/}
                      {/*begin::Separator*/}
                      <div className="separator separator-dashed my-5" />
                      {/*end::Separator*/}
                      {/*begin::Item*/}
                      <div className="d-flex flex-stack">
                        {/*begin::Section*/}
                        <div className="d-flex align-items-center me-5">
                          {/*begin::Symbol*/}
                          <div className="symbol symbol-40px me-4">
                            <span className="symbol-label">
                              <svg
                                width={24}
                                height={24}
                                viewBox="0 0 84 84"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g
                                  id="DBaaS-Icon-1C-BLK"
                                  transform="translate(0.189181, -0.000000)"
                                  fill="#494b74"
                                  fillRule="nonzero"
                                >
                                  <path d="m66.7 48.9c-7.7 2.1-17.7 3.3-28.4 3.3s-20.8-1.2-28.4-3.3c-2.2-.6-4.2-1.3-5.9-2v22c0 5.2 15.3 9.4 34.3 9.4s34.3-4.2 34.3-9.4v-22c-1.8.8-3.7 1.4-5.9 2zm-6.3 19.8c-2.6 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6 4.6 2.1 4.6 4.6-2 4.6-4.6 4.6z" />
                                  <path d="m38.3 5.1c-19 0-34.3 4.2-34.3 9.4v25.4c3.6 2.4 14.8 6.1 34.3 6.1 19.4 0 30.7-3.7 34.3-6.1v-25.4c-.1-5.2-15.4-9.4-34.3-9.4zm22.1 31.7c-2.6 0-4.6-2.1-4.6-4.6 0-2.6 2.1-4.6 4.6-4.6s4.6 2.1 4.6 4.6c.1 2.5-2 4.6-4.6 4.6zm-22.1-14.3c-20.9 0-32.3-4.9-32.3-7.4s11.4-7.4 32.3-7.4 32.3 4.9 32.3 7.4-11.5 7.4-32.3 7.4z" />
                                </g>
                              </svg>

                              {/* <i className="fonticon-database fs-1 p-0 text-gray-600" /> */}
                            </span>
                          </div>
                          {/*end::Symbol*/}
                          {/*begin::Content*/}
                          <div className="me-5">
                            {/*begin::Title*/}
                            <a

                              className="text-gray-800 fw-bolder text-hover-primary fs-6"
                            >
                              Storage
                            </a>
                            {/*end::Title*/}
                            {/*begin::Desc*/}
                            <span className="text-gray-400 fw-bold fs-7 d-block text-start ps-0">
                              Disk size
                            </span>
                            {/*end::Desc*/}
                          </div>
                          {/*end::Content*/}
                        </div>
                        {/*end::Section*/}
                        {/*begin::Wrapper*/}
                        <div className="text-gray-400 fw-bolder fs-7 text-end">
                          {/*begin::Number*/}
                          <span className="text-gray-800 fw-bolder fs-6 d-block sphide" id="disk_size_3">
                            500 MB
                          </span>
                          {/*end::Number*/}
                        </div>
                        {/*end::Wrapper*/}
                      </div>
                      {/*end::Item*/}
                    </div>
                    {/*end::Body*/}
                    <div id="ribbon-active-3" className="ribbon ribbon-triangle ribbon-top-start border-primary d-none" style={{ borderRadius: '6px', borderTopLeftRadius: 'initial', borderBottomRightRadius: 'initial' }}>
                      <div className="ribbon-icon mt-n5">
                        <i className="bi bi-check2 fs-2 text-white" />
                      </div>
                    </div>
                  </div>
                  {/*end::item plan*/}
                </div>
                <input
                  type="hidden"
                  name="disk"
                  id="disk_value"
                />
                <input
                  type="hidden"
                  name="plan"
                  id="plan_value"
                />
                <input
                  type="hidden"
                  name="plan"
                  id="price_value"
                />
                <input
                  type="hidden"
                  name="public"
                  id="registry_type"
                />
                <input
                  type="hidden"
                  name="repo"
                  id="repo_value"
                />
                <div className="col-xl-3 d-none">
                  {/*begin::item plan*/}
                  <div id="plan-active-4" onClick={(e) => choosePlan(4)} className=" position-relative card card-flush h-xl-100 uptrans border border-1 border-gray-300 cr-plan">
                    {/*begin::Header*/}
                    <div className=" text-center pt-3">
                      {/*begin::Title*/}
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bolder text-gray-800">
                          Custom
                        </span>
                      </h3>
                      {/*end::Title*/}
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body pt-4">
                      {/*begin::Item*/}
                      <div className="text-center flex-stack">
                        {/*begin::Section*/}
                        <div className="align-items-center">

                          {/*begin::Content*/}
                          <div className="">
                            {/*begin::Title*/}
                            <a

                              className="text-center text-gray-800 fw-bolder text-hover-primary fs-6"
                            >
                              Rs.1655.29 /mon
                            </a>
                            {/*end::Title*/}
                          </div>
                          {/*end::Content*/}
                        </div>
                        {/*end::Section*/}
                      </div>
                      {/*end::Item*/}
                      {/*begin::Separator*/}
                      <div className="separator separator-dashed my-5" />
                      {/*end::Separator*/}

                      {/*begin::Item*/}
                      <div className="d-flex flex-stack">
                        {/*begin::Section*/}
                        <div className="d-flex align-items-center me-5">
                          {/*begin::Symbol*/}
                          <div className="symbol symbol-40px me-4">
                            <span className="symbol-label">
                              <i className="fonticon-ship fs-1 p-0 " style={{ color: "#494b74" }} />
                            </span>
                          </div>
                          {/*end::Symbol*/}
                          {/*begin::Content*/}
                          <div className="">
                            {/*begin::Title*/}
                            <a
                              className="text-gray-800 fw-bolder text-hover-primary fs-6"
                            >
                              Repository
                            </a>
                            {/*end::Title*/}
                            {/*begin::Desc*/}
                            <span className="text-gray-400 fw-bold fs-7 d-block text-start ps-0">
                              Containers
                            </span>
                            {/*end::Desc*/}
                          </div>
                          {/*end::Content*/}
                        </div>
                        {/*end::Section*/}
                        {/*begin::Wrapper*/}
                        <div className="text-gray-400 fw-bolder fs-7 text-end">
                          {/*begin::Number*/}
                          <span className="text-gray-800 fw-bolder fs-6 d-block sphide">
                            <input
                              type="text"
                              defaultValue={1}
                              className="text-center text-gray-800 fw-bolder fs-6"
                              style={{
                                width: `${(1 * 8) + 8}px`,
                                outline: "none",
                                border: "none",
                                borderBottom: "1px solid black"
                              }}
                              onInput={(e) => {
                                let value = e.target.value;
                                if (value === "" || parseInt(value) < 1) {
                                  value = "1"; // Set to minimum value of 1 if empty or less than 1
                                } else if (value.length > 3) {
                                  value = value.slice(0, 3); // Limit to 5 characters
                                }
                                e.target.value = value;
                                e.target.style.width = `${(value.length * 8) + 8}px`;
                              }}
                            />


                            &nbsp; Repo
                          </span>
                          {/*end::Number*/}
                        </div>
                        {/*end::Wrapper*/}
                      </div>
                      {/*end::Item*/}
                      {/*begin::Separator*/}
                      <div className="separator separator-dashed my-5" />
                      {/*end::Separator*/}
                      {/*begin::Item*/}
                      <div className="d-flex flex-stack">
                        {/*begin::Section*/}
                        <div className="d-flex align-items-center me-5">
                          {/*begin::Symbol*/}
                          <div className="symbol symbol-40px me-4">
                            <span className="symbol-label">
                              <svg
                                width={24}
                                height={24}
                                viewBox="0 0 84 84"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g
                                  id="DBaaS-Icon-1C-BLK"
                                  transform="translate(0.189181, -0.000000)"
                                  fill="#494b74"
                                  fillRule="nonzero"
                                >
                                  <path d="m66.7 48.9c-7.7 2.1-17.7 3.3-28.4 3.3s-20.8-1.2-28.4-3.3c-2.2-.6-4.2-1.3-5.9-2v22c0 5.2 15.3 9.4 34.3 9.4s34.3-4.2 34.3-9.4v-22c-1.8.8-3.7 1.4-5.9 2zm-6.3 19.8c-2.6 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6 4.6 2.1 4.6 4.6-2 4.6-4.6 4.6z" />
                                  <path d="m38.3 5.1c-19 0-34.3 4.2-34.3 9.4v25.4c3.6 2.4 14.8 6.1 34.3 6.1 19.4 0 30.7-3.7 34.3-6.1v-25.4c-.1-5.2-15.4-9.4-34.3-9.4zm22.1 31.7c-2.6 0-4.6-2.1-4.6-4.6 0-2.6 2.1-4.6 4.6-4.6s4.6 2.1 4.6 4.6c.1 2.5-2 4.6-4.6 4.6zm-22.1-14.3c-20.9 0-32.3-4.9-32.3-7.4s11.4-7.4 32.3-7.4 32.3 4.9 32.3 7.4-11.5 7.4-32.3 7.4z" />
                                </g>
                              </svg>

                              {/* <i className="fonticon-database fs-1 p-0 text-gray-600" /> */}
                            </span>
                          </div>
                          {/*end::Symbol*/}
                          {/*begin::Content*/}
                          <div className="me-5">
                            {/*begin::Title*/}
                            <a

                              className="text-gray-800 fw-bolder text-hover-primary fs-6"
                            >
                              Storage
                            </a>
                            {/*end::Title*/}
                            {/*begin::Desc*/}
                            <span className="text-gray-400 fw-bold fs-7 d-block text-start ps-0">
                              Disk size
                            </span>
                            {/*end::Desc*/}
                          </div>
                          {/*end::Content*/}
                        </div>
                        {/*end::Section*/}
                        {/*begin::Wrapper*/}
                        <div className="text-gray-400 fw-bolder fs-7 text-end">
                          {/*begin::Number*/}
                          <span className="text-gray-800 fw-bolder fs-6 d-block sphide">
                            <input
                              type="text"
                              defaultValue={1}
                              className="text-center text-gray-800 fw-bolder fs-6"
                              style={{
                                width: `${(1 * 8) + 8}px`,
                                outline: "none",
                                border: "none",
                                borderBottom: "1px solid black"
                              }}
                              onInput={(e) => {
                                let value = e.target.value;
                                if (value === "" || parseInt(value) < 1) {
                                  value = "1"; // Set to minimum value of 1 if empty or less than 1
                                } else if (value.length > 3) {
                                  value = value.slice(0, 3); // Limit to 5 characters
                                }
                                e.target.value = value;
                                e.target.style.width = `${(value.length * 8) + 8}px`;
                              }}
                            />

                            &nbsp;GB</span>
                          {/*end::Number*/}
                        </div>
                        {/*end::Wrapper*/}
                      </div>
                      {/*end::Item*/}
                    </div>
                    {/*end::Body*/}
                    <div id="ribbon-active-4" className="ribbon ribbon-triangle ribbon-top-start border-primary d-none" style={{ borderRadius: '6px', borderTopLeftRadius: 'initial', borderBottomRightRadius: 'initial' }}>
                      <div className="ribbon-icon mt-n5">
                        <i className="bi bi-check2 fs-2 text-white" />
                      </div>
                    </div>
                  </div>
                  {/*end::item plan*/}
                </div>
                {/*end::Col*/}

                {/*begin::Col*/}
                {/*end::Col*/}
              </div>

            </div>
          </div>
          {/*end::Col*/}
          <div class="col-xl-12 card mb-5">
            <div class=" h-lg-100 mb-5">
              <div class="card-header border-3 mb-5 pt-2 ps-6 p-0">
                <h3 class="card-title align-items-start flex-column">
                  <span class="card-label fw-bolder text-gray-800">Container Registry Type</span>
                  <span class="text-gray-400 mt-1 fw-bold fs-6">Select your container registry type</span>
                </h3>
              </div>
              <div class="ps-6 pe-5">
                <div class="border-3 mb-5 pt-2">
                  <div class="col-xl-12  mb-5" id="application-lb-sec">
                    <div class="pb-5"><label for="targetgroups" class="form-label">Container Registry</label>
                      <select class="form-select form-select-sm" id="selected_type" style={{ cursor: 'pointer' }} onChange={(e) => handleChangeRegistryType(e.target.value)}>
                        <option value="public">Public</option>
                        <option value="private">Private</option>
                      </select>
                      <span className="text-muted fs-7 d-none">Note: For the first-time user, the container registry will be available in a public repository only</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-12 card mb-5">
            <div className="card-header border-3 mb-5 pt-2 ps-6 p-0">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder text-gray-800">
                  Name Your Registry Container
                </span>
                <span className="text-gray-400 mt-1 fw-bold fs-6">
                  Can only contain lowercase alphanumeric characters and dashes.
                </span>
              </h3>
            </div>
            <div className="mx-5 mb-3">
              <div
                className="row g-9"
                data-kt-buttons="true"
                data-kt-buttons-target="[data-kt-button]"
              >
                <div className="col-md-4 col-lg-4 col-xxl-4">
                  <div className="">
                    <div className="input-group mb-5">
                      <input
                        type="text"
                        name="project_name"
                        id="db_label"
                        className="form-control"
                        placeholder="cluster label"
                        defaultValue={"registry-" + makeid(8) + "-container"}
                        onChange={handleChange}
                      />
                      {/* <p id="error-message" style={{ color: 'red' }}></p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-12 card mb-5">
            <div className="card-header  mb-5 pt-2" style={{ border: "none" }}>
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1 d-none">
                  Total Cost
                </span>
                <span className="fs-xl-2x fs-2 fw-boldest d-none">
                  <span id="currency_final"></span>
                  <span id="total_cost"></span>
                  <span id="billing_final"></span> </span>
                <span className="text-muted mt-1 fw-bold fs-7" />
              </h3>
              <div className="card-toolbar">
                <a style={{ width: "100%" }}
                  className="btn  btn-primary btn-active-light-primary" id="deployregistry-btn" onClick={handleCreateRegistry}>
                  Create Container &nbsp; &nbsp;
                  <span className="svg-icon svg-icon-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z"
                        fill="black"
                      />
                      <path
                        opacity="0.3"
                        d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                </a>
              </div>
            </div>
          </div>

          {/*end::Tables Widget 9*/}
        </form>
      </>) : (<><TopBarHeaderLine /><TableLoader /></>)}
    </>
  );
};


const mapStateToProps = (state) => ({
  nodeCountData: state.databaseClusterCount.nodeCountDataInfo,
  deployInfo: state.databaseClusterCount.deployClusterInfo,
});

const mapDispatchToProps = (dispatch) => ({
  updateNodeCountData: (data) => dispatch(AddDatabaseNodeCount(data)),
  updateDeployInfo: (data) => dispatch(AddDatabaseDeploy(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeployContainerRegistry); 
