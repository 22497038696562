import React, { useEffect, useRef, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import swal from "sweetalert";
import ReactPaginate from "react-paginate";
import UseApi from '../../../../../../CustomHooks/useApi';
import UsePostData from '../../../../../../CustomHooks/usePostData';
import TableLoader from '../../../../Loaders/TableLoader';
import TopBarHeaderLine from '../../../../../../CustomHooks/TopBarHeaderLine';
import AccessDenied from '../../../../AccessDenied';
import CustomDoc from '../../../../../../CustomHooks/CustomDoc';
import ProductsUrl from '../../../../Docs/Docsdata';
const Contacts = ({ contact_ids }) => {
  const [access, setAccess] = useState(true);
  const [perPage, setPerPage] = useState(5);

  const loaderFunc = () => {
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if (loader && no_record) {
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");

      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
  }
  const openDrawer = (id) => {
    var drawerElement = document.querySelector("#" + id);
    drawerElement.classList.toggle("drawer-on");
  };

  const ToogleAddContact = () => {
    var addContactSec = document.querySelector("#add_contact_sec");
    var addContactBtn = document.querySelector("#addcontact-btn");
    if (addContactSec && addContactBtn) {
      addContactSec.classList.toggle("d-none");
      addContactBtn.classList.toggle("disabled");
    }
  }

  const dataFetchedRef = useRef(false);
  const [contacts, setContacts] = useState(null);

  const getContact = async () => {
    toast.dismiss();
    const data = await UseApi("get", "alert/contact/list", "");
    // //console.log(data);
    if ('status' in data) {
      if (data.status == "error") {
        setContacts(null);
        loaderFunc();
        setAccess(false);
        return;
      }
    }
    if (data.contacts.length === 0) {
      setContacts(null);
      loaderFunc();
      setAccess(true);
    } else {
      var contactArr = contact_ids.split(",");
      var cdata = data.contacts.filter(contactEle => contactArr.includes(contactEle.id));
      setContacts(cdata);
      setAccess(true);
    }
  };


  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getContact();
  }, []);
  const addNewContact = async () => {
    var submitBtn = document.querySelector("#addnewcontact-btn");
    var add_name = document.querySelector("#add_name");
    var add_email = document.querySelector("#add_email");
    var add_mobilenumber = document.querySelector("#add_mobilenumber");
    var add_status = document.querySelector("#add_status");
    var cname = "";
    var cemail = "";
    var cmobilenumber = "";
    var cstatus = ""
    if (add_name && add_email && add_mobilenumber && add_status) {

      if (add_name.value == "") {
        const dt = toast.loading("Please wait...");
        toast.update(dt, {
          render: "Name must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        return;
      } else {
        cname = add_name.value;
      }
      if (add_email.value == "") {
        const dt = toast.loading("Please wait...");
        toast.update(dt, {
          render: "Email must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        return;
      } else {
        cemail = add_email.value;
      }
      if (add_mobilenumber.value == "") {
        const dt = toast.loading("Please wait...");
        toast.update(dt, {
          render: "Mobile must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        return;
      } else {
        cmobilenumber = add_mobilenumber.value;
      }
      if (add_status.value == "") {
        const dt = toast.loading("Please wait...");
        toast.update(dt, {
          render: "Please provide status", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        return;
      } else {
        cstatus = add_status.value;
      }

      if (submitBtn) {
        var preContent = submitBtn.innerHTML;
        submitBtn.classList.add("disabled");
        submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
        const dt = toast.loading("Please wait...");
        const responseData = await UsePostData('post', 'alert/contact/add', { "name": cname, "email": cemail, "mobilenumber": cmobilenumber, "status": cstatus });
        if (responseData) {
          submitBtn.classList.remove("disabled");
          submitBtn.innerHTML = preContent;
          if (responseData.status === 'error') {
            toast.update(dt, {
              render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
          } else if (responseData.status === 'success') {
            toast.update(dt, {
              render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
            add_name.value = "";
            add_mobilenumber = "";
            add_status = "";
            add_email = "";
            ToogleAddContact();
            getContact();
          }
        }
      }
    }
  }

  const updateContact = async (id) => {
    var submitBtn = document.querySelector("#updatecontact" + id + "-btn");
    var add_name = document.querySelector("#name_" + id);
    var add_email = document.querySelector("#email_" + id);
    var add_mobilenumber = document.querySelector("#mobilenumber_" + id);
    // var add_status=document.querySelector("#add_status"); 
    var cname = "";
    var cemail = "";
    var cmobilenumber = "";
    var cstatus = ""
    if (add_name && add_email && add_mobilenumber) {

      if (add_name.value == "") {
        const dt = toast.loading("Please wait...");
        toast.update(dt, {
          render: "Name must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        return;
      } else {
        cname = add_name.value;
      }
      if (add_email.value == "") {
        const dt = toast.loading("Please wait...");
        toast.update(dt, {
          render: "Email must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        return;
      } else {
        cemail = add_email.value;
      }
      if (add_mobilenumber.value == "") {
        const dt = toast.loading("Please wait...");
        toast.update(dt, {
          render: "Mobile must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        return;
      } else {
        cmobilenumber = add_mobilenumber.value;
      }
    }
    if (submitBtn) {
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      const dt = toast.loading("Please wait...");
      const responseData = await UsePostData('post', 'alert/contact/' + id + '/update', { "name": cname, "email": cemail, "mobilenumber": cmobilenumber, "status": cstatus });
      if (responseData) {
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
        if (responseData.status === 'error') {
          toast.update(dt, {
            render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        } else if (responseData.status === 'success') {
          toast.update(dt, {
            render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          getContact();
        }
      }
    }
  }

  const deleteContact = async (id) => {
    toast.dismiss();
    var submitBtn = document.querySelector("#deletecontact" + id + "-btn");
    if (submitBtn) {
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';

      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this contact!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {
            const dt = toast.loading("Contact deleting...");
            const dnsp = await UseApi('DELETE', 'alert/contact/' + id + '/delete', '');
            ////console.log(dnsp);
            if (dnsp) {
              submitBtn.classList.remove("disabled");
              submitBtn.innerHTML = preContent;
              if (dnsp.status == 'error') {
                toast.update(dt, {
                  render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
                });
              } else if (dnsp.status == 'success') {
                toast.update(dt, {
                  render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
                });
                getContact();
              }
            }
          }
        });
    }
  }

  function ProductInfo({ productdata }) {
    return (
      <>

        {productdata != null && Object.keys(productdata).length != 0 ? (productdata && productdata.map((value, index) => (
          <tr className="openCustomer" data-id="">
            {" "}
            <td className="text-center">
              <div className=" align-items-center">
                <div className="d-flex justify-content-center flex-column">
                  <a className=" text-gray-700 fw-bold d-block fs-6 pt-3">
                    {value.name}
                  </a>
                </div>
              </div>
            </td>
            <td className="text-center">
              <div className=" align-items-center">
                <div className="d-flex justify-content-center flex-column">
                  <a className=" text-gray-700 fw-bold d-block fs-6 pt-3">
                    {value.email}
                  </a>
                </div>
              </div>
            </td>
            <td className="text-center">
              <div className=" align-items-center">
                <div className="d-flex justify-content-center flex-column">
                  <a className=" text-gray-700 fw-bold d-block fs-6 pt-3">
                    {value.mobilenumber}
                  </a>
                </div>
              </div>
            </td>
            <td className="text-center">
              <div className=" align-items-center">
                <div className="d-flex justify-content-center flex-column">
                  {value.status == "1" ? (<>
                    <a className="fw-bold d-block fs-6 btn btn-light-success">Active</a>
                  </>) : (<>
                    <a className="fw-bold d-block fs-6 btn btn-light-warning">Inactive</a>
                  </>)}
                </div>
              </div>
            </td>
            <td className="text-center">

              <a

                id={"deletecontact" + value.id + "-btn"}
                onClick={(e) => deleteContact(value.id)}
                className="btn btn-icon btn-light-danger"
              >
                {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen027.svg*/}{" "}
                <span className="svg-icon svg-icon-muted svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    {" "}
                    <path
                      d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                      fill="black"
                    />{" "}
                    <path
                      opacity="0.5"
                      d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                      fill="black"
                    />{" "}
                    <path
                      opacity="0.5"
                      d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                      fill="black"
                    />{" "}
                  </svg>
                </span>{" "}
                {/*end::Svg Icon*/}
              </a>
            </td>{" "}
          </tr>
        ))) : (<>
          <tr>
            <td colspan="12" className="dataTables_empty" id="loader">
              {/* <div className="loading-spinner"></div> */}
              <TableLoader />
            </td>
            <td colspan="12" className="d-none" id="no_record" valign="top">
              <div className="d-flex flex-column flex-center">
                <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
              </div>
            </td>
          </tr>
        </>)}

      </>
    )
  }


  const [searchTerm, setSearchTerm] = useState('');
  function PaginatedItems({ itemsPerPage, ProductData, search }) {
    // We start with an empty list of items. 
    const [pageCount, setPageCount] = useState(0);
    const [vpcData, setVpcData] = useState(null);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
      // Fetch items from another resources.

      const endOffset = itemOffset + itemsPerPage;


      //  //////console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      if (ProductData != null) {
        //   var str="Noida";
        // //////console.log(ProductData);
        const data = ProductData.filter(row => {
          return Object.values(row).some(value => {
            if (value != null) {
              if (value.toString().toLowerCase().includes(search.toLowerCase())) {
                return value;
              }
            }
          });
        });
        ////////console.log(data);
        // const data=ProductData.filter(item => 
        //        item.hostname.toLowerCase().includes(search.toLowerCase())
        //              ); 

        if (data.length <= 0) {
          loaderFunc();
        }
        setVpcData(data.slice(itemOffset, endOffset));
        //setCurrentItems(items.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(data.length / itemsPerPage));
        // alert(pageCount);
      }
    }, [itemOffset, itemsPerPage]);

    // Invoke when user click to request another page.

    const handlePageClick = (event) => {
      const newOffset = event.selected * itemsPerPage % ProductData.length;
      //////console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
      setItemOffset(newOffset);

    };
    var searchEle = document.querySelector("#searchClient");
    if (searchEle) {
      searchEle.focus();
    }
    return (
      <>
        <ProductInfo productdata={vpcData} />
        <td colspan="12">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </td>
      </>
    );
  }

  const openDoc = () => {
    var drawerElement = document.querySelector("#contact-doc");
    var drawer_overlay = document.querySelector("#drawer_overlay_contact_doc");
    if(drawerElement && drawer_overlay){ 
    drawerElement.classList.toggle("drawer-on");
    if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
     drawerElement.style.width = "100%";
   } else {
     drawerElement.style.width = "50%";
   }
    drawer_overlay.classList.toggle("d-none");
    }
   };
  return (
    <>

      <div className="card rounded-0 w-100">
        {/*begin::Card header*/}
        <div className="card-header border-0 pe-5 ">
          {/*begin::Title*/}
          <div className="card-title">
            {/*begin::User*/}
            <div
              className="d-flex justify-content-center flex-column me-3"
              style={{ marginRight: 20 }}
            >
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1">
                  Monitoring Contacts &nbsp;
                  <span className="badge badge-success"> Beta</span>
                </span>
                <span className="text-muted mt-1 fw-bold fs-7">
                  Manage your contacts here.
                  <span className="text-primary ms-1 fw-bold fs-7 d-none" onClick={openDoc} style={{ cursor: 'pointer' }}>Learn more.
                  </span>
                </span>
              </h3>
            </div>
            {/*end::User*/}
          </div>
          {/*end::Title*/}
          {/*begin::Card toolbar*/}
          <div className="card-toolbar">
            <div
              className="position-relative my-1"
              style={{ marginRight: 20 }}
            >
              <input
                type="text"
                className="form-control form-control-sm"
                data-kt-table-widget-4="search"
                id="searchClient"
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                placeholder="Search"
              />
            </div>

            <div style={{ marginRight: 20 }}>
              <div className="dataTables_length">
                <label className="w-125px">
                  <select
                    onChange={e => setPerPage(e.target.value)}
                    //data-control="select2"
                    className="form-select form-select-sm form-select-solid"
                    defaultValue={5}
                    id="perPage"

                  >
                    <option value={5} selected="">
                      5 Records
                    </option>
                    <option value={10}>10 Records</option>
                    <option value={25}>25 Records</option>
                    <option value={50}>50 Records</option>
                    <option value={100}>100 Records</option>
                  </select>
                </label>
              </div>
            </div>
            {/*begin::Close*/}
            <a
              id="addcontact-btn"
              onClick={ToogleAddContact}
              // onclick="call_api('POST','monitoring/contact/add','addcontact','getContact')"
              className="btn btn-sm btn-primary"
              style={{ marginRight: 20 }}
            >
              {/*begin::Svg Icon | path: assets/media/icons/duotune/files/fil017.svg*/}
              {/*begin::Svg Icon | path: assets/media/icons/duotune/files/fil013.svg*/}
              <span className="svg-icon svg-icon-muted svg-icon-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    opacity="0.3"
                    d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z"
                    fill="black"
                  />
                  <path
                    d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.2C9.7 3 10.2 3.20001 10.4 3.60001ZM16 12H13V9C13 8.4 12.6 8 12 8C11.4 8 11 8.4 11 9V12H8C7.4 12 7 12.4 7 13C7 13.6 7.4 14 8 14H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
                    fill="black"
                  />
                  <path
                    opacity="0.3"
                    d="M11 14H8C7.4 14 7 13.6 7 13C7 12.4 7.4 12 8 12H11V14ZM16 12H13V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
                    fill="black"
                  />
                </svg>
              </span>
              {/*end::Svg Icon*/}
              {/*end::Svg Icon*/}
              Add New
            </a>
            {/*end::Close*/}
          </div>

          {/*end::Card toolbar*/}
        </div>
        <div className=" hover-scroll-overlay-y">
          <div
            className="tab-pane fade show active"
            id="customer-details-section"
            role="tabpanel"
          >
            {/*begin::Deactivate Account*/}
            <div className="">
              <div
                id="kt_account_settings_profile_details"
                className="collapse show"
              >
                {/*begin::Card body*/}
                <div className="" id="server_sec">
                  {/*begin::Table*/}
                  <div
                    id="kt_table_users_wrapper"
                    className="dataTables_wrapper dt-bootstrap4 no-footer"
                  >
                    <div className="table-responsive">
                      <table
                        className="table table-row-dashed border table-row-bordered gy-5 gs-7"
                        id="page_list"
                      >
                        {/*begin::Table head*/}
                        <thead className="bg-secondary">
                          {/*begin::Table row*/}
                          <tr className="text-start fw-bolder fs-7 text-uppercase gs-0">
                            <th className="text-center">Name </th>
                            <th className="text-center">Email </th>
                            <th className="text-center">Mobile</th>
                            <th className="text-center">Status</th>
                            <th className="min-w-300px sorting" />
                          </tr>
                          {/*end::Table row*/}
                        </thead>
                        {/*end::Table head*/}
                        {/*begin::Table body*/}
                        <tbody className="text-gray-600" id="con-data">
                          <tr className="openCustomer d-none" id="add_contact_sec" >
                            <td className="text-center">
                              <input
                                type="text"
                                id="add_name"
                                name="name"
                                defaultValue=""
                                className="form-control w-75"
                                placeholder="Enter Name"
                              />

                            </td>
                            <td className="text-center">
                              <input
                                type="text"
                                id="add_email"
                                name="email"
                                defaultValue=""
                                className="form-control w-75"
                                placeholder="Enter Email"
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="text"
                                id="add_mobilenumber"
                                name="mobilenumber"
                                defaultValue=""
                                className="form-control w-75"
                                placeholder="Enter Mobile"
                              />
                            </td>
                            <td className="text-center">
                              <select
                                className="form-select"
                                id="add_status"
                                name="status"
                              >
                                <option
                                  value={1}
                                >
                                  Active
                                </option>
                                <option value={0}>Inactive</option>
                              </select>
                            </td>
                            <td className="text-center">
                              <a

                                id="addnewcontact-btn"
                                onClick={addNewContact}
                                className="btn btn-primary"
                                style={{ marginRight: 20 }}
                              >
                                Add Contact
                              </a>
                              <a

                                id="addcontact-btn"
                                onClick={ToogleAddContact}
                                className="btn btn-danger"
                                style={{ marginRight: 20 }}
                              >
                                Cancel
                              </a>
                            </td>
                          </tr>
                          {contacts != null ? (<>
                            {access == false ? (<>
                              <tr>
                                <td colspan={12}>
                                  <AccessDenied />
                                </td>
                              </tr>
                            </>) : (<>
                              {contacts.length > 0 ? (<>
                                <PaginatedItems itemsPerPage={perPage} ProductData={contacts} search={searchTerm} />
                              </>) : (<>  </>)}
                            </>)}
                          </>) : (<>
                            <tr>
                              <td colspan={12}>
                                <TableLoader />
                                <TopBarHeaderLine />
                              </td>
                            </tr>
                          </>)}
                        </tbody>
                        {/*end::Table body*/}
                      </table>

                    </div>
                  </div>
                  {/*end::Table*/}
                </div>
                {/*end::Card body*/}
                {/*end::Form*/}
              </div>
            </div>
          </div>
        </div>
        {/*end::Card body*/}
      </div>
      <CustomDoc id='contact' openDoc={openDoc} url={ProductsUrl[0].monitring_contact} iframeUrl={ProductsUrl[0].monitring_contact} />
    </>
  );
}

export default Contacts;
