import React, { useEffect } from "react";
import PropTypes from "prop-types";

function MarketplaceFields(props) {
  const { currMarketplaceDistro, currMarketplace, selectStackImage } = props;

  useEffect(() => {
    if (currMarketplaceDistro === undefined)
      throw new Error("Missing prop: currMarketplaceDistro");
    if (currMarketplace === undefined)
      throw new Error("Missing prop: currMarketplace");
    if (selectStackImage === undefined)
      throw new Error("Missing prop: selectStackImage");
  }, [currMarketplaceDistro, currMarketplace, selectStackImage]);

  return (
    <>
      {currMarketplaceDistro != null ? (
        <>
          {currMarketplaceDistro.length != 0 ? (
            <>
              {currMarketplaceDistro.length == 0 ? (
                <>
                  {currMarketplaceDistro.map((valueData, index) => (
                    <>
                      <input
                        type="hidden"
                        onChange={(e) => selectStackImage(e.target.value)}
                        className="image_field"
                        defaultValue={valueData}
                      />
                    </>
                  ))}
                </>
              ) : (
                <>
                  <div className="fv-row mb-10 fv-plugins-icon-container">
                    <label className="form-label ">Select OS</label>
                    <select
                      className="form-select image_field"
                      onChange={(e) => selectStackImage(e.target.value)}
                    >
                      {currMarketplaceDistro.map((value, index) => (
                        <>
                          <option value={value}>{value}</option>
                        </>
                      ))}
                    </select>
                  </div>
                </>
              )}
            </>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
      {currMarketplace != null ? (
        <>
          {Object.keys(currMarketplace).length != 0 ? (
            <>
              {currMarketplace.map((value, index) => (
                <>
                  <div className="fv-row mb-10 fv-plugins-icon-container">
                    <label className="form-label ">{value.label}</label>
                    <input
                      type="text"
                      name={"stack_fields[" + index + "][" + value.name + "]"}
                      defaultValue=""
                      placeholder={value.example}
                      className="form-control form-control-lg"
                    />
                  </div>
                </>
              ))}
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}
MarketplaceFields.propTypes = {
  currMarketplaceDistro: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.oneOf([null]),
  ]).isRequired,
  currMarketplace: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.oneOf([null]),
  ]).isRequired,
  selectStackImage: PropTypes.func.isRequired,
};

export default MarketplaceFields;
