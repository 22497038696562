import React, { useEffect, useState } from 'react';
import PricingContainer from '../pricing/PricingContainer';
import UseApi from '../../../../CustomHooks/useApi';


const GetCloud = () => {
    const [distroData, setDistroData]=useState(null);
    const [planData, setPlanData]=useState(null);
    const [userData, setUserData]=useState(null);
    const getDistro = async () => { 
        setDistroData(null);
        const data = await UseApi("get", "cloud/getdeploy", "");
        const user_data = await UseApi("get", "account/info", "");
         if(user_data){
            setUserData(user_data.user);
         } 
        if (Object.keys(data).length === 0) {
          setDistroData(null);
          setPlanData(null); 
        } else {
          setPlanData(data.plans);
          setDistroData(data.distro);   
        } 
      };

      useEffect(()=>{
        getDistro();
      },[])

      function updatedPlanInfo(plan=[]){ 
      //  console.log("selected plan \n");
      //  console.log(plan);
      }
      if(userData!=null && planData!=null){
    return (
        <>
            <PricingContainer updatePlan={updatedPlanInfo} currBilling="monthly" currPlanid="10045" user={userData} plans={planData} />
        </>
    );
      }else{
        return(<>
        Loading, please wait...
        </>)
      }
}

export default GetCloud;
