import React, { useEffect, useRef, useState } from "react";
import UseApi from "../../../../CustomHooks/useApi";
import { useNavigate } from "react-router-dom";
import TableLoader from "../../Loaders/TableLoader";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate"; 
import AccessDenied from "../../AccessDenied";
export default function Orders() {  
  const [access, setAccess] = useState(true); 
  const [perPage, setPerPage] = useState(5);
  const [notesData,setNotesData] = useState("")
  const [ordersData, setOrdersData] = useState(null);
  const dataFetchedRef = useRef(false);
  const navigate = useNavigate();  
  const loaderFunc = () => {
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if (loader && no_record) {
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");

      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
  };
  const AddNotes = async() => {
    console.log(notesData);
    // const data = await UseApi("post","snapshot",notes)
    // console.log(data);
  }
  const getSnapshot = async () => {
    const dt = toast;
    const data = await UseApi("get", "partner/order", "");
    
   if(data){
    if ("rcode" in data) {
      if (data.rcode == "error") {
        toast.update(dt, {
          render: data.message,
          type: "warning",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setAccess(false);
        setOrdersData([]);
        loaderFunc();
        return;
      }
    }
    if ("orders" in data) {
      
      setOrdersData(data.orders);
      toast.dismiss();
      //    toast.update(dt,{render: "Your Snapshots request has been successful", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      //  });
    } else {
      setOrdersData([]);
      toast.update(dt, {
        render: "no record found",
        type: "warning",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      loaderFunc();
    }
  }
  }; 
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getSnapshot();
  }, []); 

  

  function ProductInfo({ productdata }) {
    // console.log(productdata);
    return (
      <>
        {productdata != null && Object.keys(productdata).length != 0 ? (
          productdata &&
          productdata.map((value, index) => (
            <>
              <tr>
                <td className="p-3 text-center">
                  <div className=" flex-column justify-content-center w-100 me-2">
                    <div className=" flex-stack mb-2">
                      <span className=" me-2 fs-7 fw-bold">{value.id}</span>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex flex-column w-100 me-2">
                    <div className="d-flex flex-stack mb-2"> 
                        <a className=" me-2 fs-7 fw-bold">{value.fullname}</a> 
                    </div>
                  </div>
                </td>
                <td className="text-start">
                <div className="d-flex align-items-center ps-10">
            {/* {cloud.stack?(<>
  <div className="symbol symbol-45px me-5 text-center">
    <img src={'/'+cloud.stack.logo_url} alt="" style={{ width: "45px",height: "45px",padding: "2px",background: "#ebebeb75"}} />
  </div>
  </>):(<>
    <div className="symbol symbol-45px me-5  text-center pt-2" style={{ width: "45px",height: "45px",padding: "2px",background: "#ebebeb75"}}>
    <span className={"fonticon_"+(cloud.image.distribution).replaceAll(" ","")} />
    </div>
  </>)} */}
  <div className="d-flex justify-content-start flex-column"> 
    <div style={{cursor:"pointer"}} className="text-dark fw-bolder text-hover-primary fs-6">
    {value.hostname} ({value.ref_type})
    </div> 
    <span className="text-muted fw-bold text-muted d-block fs-7">
    {value.cpu} vCPU /{(value.ram/1024).toFixed(0)} GB / {value.image} / {value.dcslug}
    </span>
  </div>
</div>
                </td>
                <td className="text-center">
                <div className="d-flex align-items-center ">
                {" "}
                <div className="d-flex justify-content-start flex-column">
                  {" "}
                  {value.billingcycle?(<>
                  <a className="fw-bold btn badge badge-success fs-6 text-start mb-3 sphide">
                    {value.billingcycle}
                  </a>
                  </>):('')} 
                </div>{" "}
              </div>

                </td>
                  <td className="text-center">
                  <div className="d-flex align-items-center ">
                {" "}
                <div className="d-flex justify-content-start flex-column">
                  {" "}
                  {value.support?(<>
                  <a className="fw-bold btn btn btn-sm btn-primary fs-6 text-start mb-3 sphide">
                    {value.support}
                  </a>
                  </>):('')} 
                </div>{" "}
              </div>
                </td> 
                <td className="text-center">
                <div className="align-items-center">
                  <div className="d-flex justify-content-start flex-column ">
                    {value.status == "Active" ? (
                      <>
                        <span className="fw-bold fs-6 text-gray-700 sphide">
                          <span className="status-circle"></span>
                          Active
                        </span>{" "}
                      </>
                    ) : (
                      <>
                        {" "}
                        <span className="fw-bold fs-6 text-gray-700 sphide">
                          <span className="status-circle"></span>
                          Inactive
                        </span>
                      </>
                    )}
                  </div>{" "}
                </div>
              </td>
              <td className="text-center">
                  <div className=" flex-column w-100 me-2">
                    <div className=" flex-stack mb-2">
                      <span className="text-muted me-2 fs-7 fw-bold sphide">
                        {value.nextinvoicedate}
                      </span>
                    </div>
                  </div>
                </td>
                <td className="text-center">
                  <div className=" flex-column w-100 me-2">
                    <div className=" flex-stack mb-2">
                      <span className="text-muted me-2 fs-7 fw-bold sphide">
                        {value.created_at}
                      </span>
                    </div>
                  </div>
                </td>
               
                <td className="text-center d-none">
                  <div className="card-toolbar">
                    <button
                      type="button"
                      className="btn btn-sm btn-icon btn-color-danger btn-active-light-danger"
                       
                    >
                      <span className="svg-icon svg-icon-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                            fill="black"
                          />
                          <path
                            opacity="0.5"
                            d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                            fill="black"
                          />
                          <path
                            opacity="0.5"
                            d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                    </button>
                  </div>
                </td>
                
              </tr>
            </>
          ))
        ) : (
          <>
            <tr>
              <td colspan="12" className="dataTables_empty" id="loader">
                {/* <div className="loading-spinner"></div> */}
                <TableLoader />
              </td>
              <td colspan="12" className="d-none" id="no_record" valign="top">
                <div className="d-flex flex-column flex-center">
                  <div className="fs-5 fw-bolder text-dark mb-4">
                    No items found.
                  </div>
                </div>
              </td>
            </tr>
          </>
        )}
      </>
    );
  }

  const [searchTerm, setSearchTerm] = useState("");
  function PaginatedItems({ itemsPerPage, ProductData, search }) {
    // We start with an empty list of items.
    const [pageCount, setPageCount] = useState(0);
    const [vpcData, setVpcData] = useState(null);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
      // Fetch items from another resources.

      const endOffset = itemOffset + itemsPerPage;

      //  ////console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      if (ProductData != null) {
        //   var str="Noida";
        // ////console.log(ProductData);
        const data = ProductData.filter((row) => {
          return Object.values(row).some((value) => {
            if (value != null) {
              if (
                value.toString().toLowerCase().includes(search.toLowerCase())
              ) {
                return value;
              }
            }
          });
        });
        //////console.log(data);
        // const data=ProductData.filter(item =>
        //        item.hostname.toLowerCase().includes(search.toLowerCase())
        //              );

        if (data.length <= 0) {
          loaderFunc();
        }
        setVpcData(data.slice(itemOffset, endOffset));
        //setCurrentItems(items.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(data.length / itemsPerPage));
        // alert(pageCount);
      }
    }, [itemOffset, itemsPerPage]);

    // Invoke when user click to request another page.

    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % ProductData.length;
      ////console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
      setItemOffset(newOffset);
    };
    var searchEle = document.querySelector("#searchClient");
    if (searchEle) {
      searchEle.focus();
    }
    return (
      <>
        <ProductInfo productdata={vpcData} />
        <td colspan="12">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </td>
      </>
    );
  }
  return (
    <>
      <div className="col-xl-12">
        {/*begin::Tables Widget 9*/}
        <div className="card card-xl-stretch mb-5 mb-xl-12">
          {/*begin::Header*/}
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold fs-3 mb-1">Orders</span>
              <span className="text-muted mt-1 fw-bold fs-7">
                Manage your all orders here
              </span>
            </h3>
            <div className="card-toolbar">
              <div
                className="position-relative my-1"
                style={{ marginRight: 20 }}
              >
                <input
                  type="text"
                  className="form-control form-control-sm"
                  data-kt-table-widget-4="search"
                  id="searchClient"
                  value={searchTerm}
                  onChange={e => setSearchTerm(e.target.value)}
                  placeholder="Search"
                />
              </div>
              <div style={{ marginRight: 20 }}>
                <div className="dataTables_length">
                  <label className="w-125px">
                    <select
                      onChange={e => setPerPage(e.target.value)}
                      data-control="select2"
                      className="form-select form-select-sm form-select-solid"
                      defaultValue={5}
                      id="perPage"
                    >
                      <option value={5} selected="">
                        5 Records
                      </option>
                      <option value={10}>10 Records</option>
                      <option value={25}>25 Records</option>
                      <option value={50}>50 Records</option>
                      <option value={100}>100 Records</option>
                    </select>
                  </label>
                </div>
              </div>
            </div>
          </div>
          {/*end::Header*/}
          {/*begin::Body*/}
          {/*begin::Table container*/}
          <div className="table-responsive">
            {/*begin::Table*/}
            <table
              id="snapTable"
              className="table table-striped table-row-dashed table-row-gray-400 align-middle gs-0 gy-4"
            >
              {/*begin::Table head*/}
              <thead className="bg-secondary">
                <tr className="fw-bold">
                  <th className="w-5 p-3 text-center"> #ID </th>
                  <th className="w-10"> Full Name </th>
                  <th className="w-25 text-center">Service</th>
                  <th className="w-20 text-center">Billing</th>
                   <th className="w-20 text-center">Support</th>
                  <th className="w-10 text-center">Status</th>
                  <th className="w-5 text-center sphide">Next invoice</th>
                  <th className="w-5 text-center sphide">Created At</th>
                  <th className="w-10 text-center d-none">Action</th>
                </tr>
              </thead>
              {/*end::Table head*/}
              {/*begin::Table body*/}
              <tbody id="customer-list">
              {ordersData != null ? (
                  <>
                    {access == false ? (
                      <>
                        <tr>
                          <td colspan={12}>
                            <AccessDenied />
                          </td>
                        </tr>
                      </>
                    ) : (
                      <>
                        {Object.keys(ordersData).length != 0 ? (
                          <>
                            <PaginatedItems
                              ProductData={ordersData}
                              search={searchTerm}
                              itemsPerPage={perPage}
                            />
                          </>
                        ) : (
                          <>
                            <tr>
                              <td colspan="12" id="no_record" valign="top">
                                <div className="d-flex flex-column flex-center">
                                  <div className="fs-5 fw-bolder text-dark mb-4">
                                    No items found.
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <tr>
                      <td colspan={12}>
                        <TableLoader />
                        <TopBarHeaderLine />
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
              {/*end::Table body*/}
            </table>
            {/*end::Table*/}
          </div>
          {/*end::Table container*/}
          {/*begin::Body*/}
        </div>
        {/*end::Tables Widget 9*/}
      </div>
    </>
  );
}
