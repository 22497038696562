import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom/dist";
import UsePost from "../../../../CustomHooks/usePost";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UseApi from "../../../../CustomHooks/useApi";
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../Loaders/TableLoader";

export default function ReservedIPDeploy() {
  const [ddata, setDdata] = useState(null);
  const [pageStatus, setPageStatus] = useState(false)
  const dataFetchedRef = useRef();
  // const dczonesData=useRef();
  let navigate = useNavigate();
  const deployVpc = async () => {
    var submitBtn = document.querySelector("#vpc-btn");
    if (submitBtn) {
      setPageStatus(true)
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      toast.dismiss();
      const dt = toast.loading("Please wait...");
      const responseData = await UsePost('post', 'vpc/create', 'createvpc');
      if (responseData) {
        setPageStatus(false)
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
        if (responseData.status === 'error') {
          toast.update(dt, {
            render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        } else if (responseData.status === 'success') {
          toast.update(dt, {
            render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });

          navigate("/vpc/" + responseData.id);
        }
      }
    }
  }
  useEffect(() => {
    window.addEventListener("keydown", function (event) {
      event.stopPropagation();
      var query = window.location.href;
      var vars = query.split("/");
      var action = vars[4];
      //console.log(vars);
      if (event.key == "Enter" && action == "deploy" && vars[3] == "vpc") {
        deployVpc();
      }
    });
  }, []);
  const getDczone = async () => {
    const data = await UseApi('get', 'dczones', '');
    if (Object.keys(data).length === 0) {
      setDdata(null);
    } else {
      data.dczones.map((value, index) => {
        if (value.status === 'active' && value.product_vpc === '1') {
          //console.log("value");
          setDdata(value);

        }
      })

    }
  }
  const [cloudData, setCloudData] = useState(null);
  const getCloud = async () => {
    const data = await UseApi("get", "cloud", "");
    if (Object.keys(data).length === 0) {
      setCloudData(null);
    } else {
      setCloudData(data.cloud);
    }
  };
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getCloud();
    getDczone();
  }, []);
  //console.log(ddata);
  return (
    <>
      {ddata != null ? (<>
        <form id="createvpc" className="form">
          <div className="col-xl-12 card mb-5">
            <div className="card-header border-3 mb-5 pt-2">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder text-gray-800">
                  Select DC Location
                </span>
                <span className="text-gray-400 mt-1 fw-bold fs-6">
                  Data Centers
                </span>
              </h3>
            </div>

            {/* Begin body */}
            <div className="container pb-5">
              {/*begin::Row*/}
              <div
                id="dc-location"
                className="row g-9"
                data-kt-buttons="true"
                data-kt-buttons-target="[data-kt-button]"
              >
                {ddata != null ? (
                  <>
                    <div className="col-md-3 col-lg-3 col-xxl-3 dc_sibling">
                      <input
                        className="form-check-input deploy_checkbox "
                        type="radio"
                        name="dcslug"
                        defaultValue={ddata.slug}
                        id={"dcslug" + ddata.slug + ""}
                      />
                      <label
                        style={{ WebkitJustifyContent: 'unset' }}
                        id={"dcslugl_" + ddata.slug + ""}
                        htmlFor={"dcslug" + ddata.slug + ""}
                        className=" dcsluglable border-1 border-gray-300 border px-1 py-1">
                        {/*begin::Info*/}
                        <div className="d-flex flex-stack mb-3">
                          {/*begin::Wrapper*/}
                          <div className="me-3 p-3">
                            {/*begin::Icon*/}
                            <img
                              src={"/assets/media/flags/svg/" + ddata.cc + ".svg"}
                              className="w-35px ms-n1 me-6"
                              alt=""
                            />
                            {/*end::Icon*/}
                            {/*begin::Title*/}
                            <a
                              className="text-gray-800 text-hover-primary fw-bolder"
                            >
                              {ddata.city}
                            </a>
                            {/*end::Title*/}
                          </div>
                          {/*end::Wrapper*/}
                          {/*begin::Action*/}
                          <div className="me-3">
                            {/*begin::Menu*/}
                            <span className=" text-muted fw-bold justify-content-end">{ddata.country}</span>
                            {/*end::Menu*/}
                          </div>
                          {/*end::Action*/}
                        </div>
                        {/*end::Info*/}
                        {/*begin::Customer*/}
                        {/*end::Customer*/}
                      </label>
                    </div>
                  </>
                ) : (<><div className="small-spinner"></div></>)}
              </div>
              {/*end::Row*/}

            </div>
          </div>
          <div className="col-xl-12 card mb-5">
            <div className="card-header border-3 mb-5 pt-2">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder text-gray-800">
                  Define IP Address
                </span>
                <span className="text-gray-400 mt-1 fw-bold fs-6">
                  select the IP Address to reserved
                </span>
              </h3>
            </div>
            <div className="container pb-5">
              <div
                className="row g-9"
                data-kt-buttons="true"
                data-kt-buttons-target="[data-kt-button]"
              >
                <div className="col-md-4 col-lg-4 col-xxl-4">
                  <div className="">
                    <div className="input-group mb-5">
                      <select
                        className="form-select"
                        name="cloudid"
                        id="cloudSet"
                      >
                        <option value="">Choose IP Address</option>
                        {cloudData != null &&
                          cloudData &&
                          cloudData.map((value, index) => (
                            <option value={value.cloudid}>
                              {value.hostname + " " + value.ip}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-12 card mb-5">
            <div className="card-header border-3 mb-5 pt-2">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder text-gray-800">
                  Name Your IP
                </span>
                <span className="text-gray-400 mt-1 fw-bold fs-6">
                  Input the name for reserved IP
                </span>
              </h3>
            </div>
            <div className="container pb-5">
              <div
                className="row g-9"
                data-kt-buttons="true"
                data-kt-buttons-target="[data-kt-button]"
              >
                <div className="col-md-4 col-lg-4 col-xxl-4">
                  <div className="">
                    <div className="input-group mb-5">
                      <input
                        type="text"
                        name="cluster_label"
                        id="db_label"
                        className="form-control"
                        placeholder="Reserved IP Name"
                      // defaultValue={"utho-"+makeid(8)+"-cluster"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-12 card mb-5">
            <div className="card-header border-3 mb-5 pt-2">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder text-gray-800">
                  Cost Summary
                </span>

              </h3>
            </div>
            <div className="card-header  mb-5 pt-2" style={{ border: "none" }}>
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1">
                  Total Cost
                </span>
                <span className="fs-xl-2x fs-2 fw-boldest">
                  <span id="currency_final">Rs.</span>
                  <span id="total_cost">350</span>
                  <span id="billing_final">/mon</span> </span>
                <span className="text-muted mt-1 fw-bold fs-7" />
              </h3>
              <div className="card-toolbar">
                <a style={{ width: "100%" }}
                  className="btn  btn-primary btn-active-light-primary" id="database-btn"  >
                  Add Reserved IP &nbsp; &nbsp;
                  <span className="svg-icon svg-icon-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z"
                        fill="black"
                      />
                      <path
                        opacity="0.3"
                        d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                </a>
              </div>
            </div>
          </div>
          {/* <div className="col-xl-12 card mb-5">
      <div className="card-header border-3 mb-5 pt-2">
           <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder text-gray-800">
            Define Reserved IP Name
            </span>
            <span className="text-gray-400 mt-1 fw-bold fs-6">
            Configure Your IP
            </span>
          </h3>
        </div>  
 
            <div className="container pb-5"> 
              <div
                id="define-vpc"
                className="row g-9"
                data-kt-buttons="true"
                data-kt-buttons-target="[data-kt-button]"
              >
                <div className="col-lg-4 col-md-4 col-xxl-4 fw-bold">
                  <label htmlFor="vpcName">IP Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter reserved ip name"
                    name="name"
                    id="name"
                  />
                </div> 
              </div> 
            </div>
          </div> 
          <div className="col-xl-12 card mb-5">
      <div className="card-header border-3 mb-5 pt-2">
           <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder text-gray-800">
            Deploy Reserved IP
            </span>
            <span className="text-gray-400 mt-1 fw-bold fs-6">
            Click the below btn to add Reserved-IP
            </span>
          </h3>
        </div>  
 
            <div className="container pb-5"> 
              <button
            type="button"
            className="btn btn-primary"
            style={{ width: "100%" }}
            onClick={deployVpc}
            id="vpc-btn"
          > 
            <span className="svg-icon ">
                <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none">
  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
  <g id="SVGRepo_iconCarrier">
    {" "}
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M9 17a1 1 0 102 0v-6h6a1 1 0 100-2h-6V3a1 1 0 10-2 0v6H3a1 1 0 000 2h6v6z"
    />{" "}
  </g>
</svg>

                </span>
                Add IP
          </button>  
            </div>
            {pageStatus &&
            <TopBarHeaderLine/>}
          </div> */}

          {/*end::Tables Widget 9*/}

        </form>
      </>) : (<>
        <TopBarHeaderLine />
        <TableLoader />
      </>)}
    </>

  )
}