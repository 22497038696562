import { DEPLOY_NODE_POOL_ROW, UPDATE_NODE_POOL_ROW } from "../../../../Redux/Services/constants"
const makeid = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
const initialState = {
    nodePoolRow: [
        { pool_id: 0, nodePoolValue: 'pool-' + makeid(8), nodeSize: 0, desiredCount: 1, maxCount: 1, totalCost: 0.00, policies: [{ policyName: 'policy-' + makeid(8), policyType: "cpu", aboveValue: "60", belowValue: "20", policyAdjust: "1", policyPeriod: "5m", policyCooldown: "300", minSize: "1", maxSize: "5", desiredSize: "1" }] },
        // {pool_id:1, nodePoolValue:'pool-'+makeid(8), nodeSize:0, nodeCount:1, totalCost:0.00}
    ],
    nodePoolPoliciesArr: [
        { pool_id: 0, nodePoolValue: 'pool-' + makeid(8), nodeSize: 0, desiredCount: 1, maxCount: 1, totalCost: 0.00, policies: [{ policyName: 'policy-' + makeid(8), policyType: "cpu", aboveValue: "60", belowValue: "20", policyAdjust: "1", policyPeriod: "5m", policyCooldown: "300", minSize: "1", maxSize: "5", desiredSize: "1" }] },
        // {pool_id:1, nodePoolValue:'pool-'+makeid(8), nodeSize:0, nodeCount:1, totalCost:0.00}
    ],
    deployPoolPoliciesArr: [
        { pool_id: 0, nodePoolValue: 'pool-' + makeid(8), nodeSize: 0, desiredCount: 1, maxCount: 1, totalCost: 0.00, policies: [{ policyName: 'policy-' + makeid(8), policyType: "cpu", aboveValue: "60", belowValue: "20", policyAdjust: "1", policyPeriod: "5m", policyCooldown: "300", minSize: "1", maxSize: "5", desiredSize: "1" }] }
        // {pool_id:0, nodePoolValue:'pool-'+makeid(8), nodeSize:0, desiredCount:1,maxCount:1, totalCost:0.00,minSize:"1",maxSize:"5",desiredSize:"1",policies:[{policyname:'policy-'+makeid(8), policytype:"cpu", compare:"above", value:"60", adjust:"1", period:"5m",cooldown:"300"},{policyname:'policy-'+makeid(8), policytype:"cpu", compare:"below", value:"20", adjust:"-1", period:"5m",cooldown:"300"}]},
        // {pool_id:1, nodePoolValue:'pool-'+makeid(8), nodeSize:0, nodeCount:1, totalCost:0.00}
    ],
    deployPoolRow: [
        { pool_id: 0, nodePoolValue: 'pool-' + makeid(8), nodeSize: 0, desiredCount: 1, maxCount: 1, totalCost: 0.00, policies: [{ policyName: 'policy-' + makeid(8), policyType: "cpu", aboveValue: "60", belowValue: "20", policyAdjust: "1", policyPeriod: "5m", policyCooldown: "300", minSize: "1", maxSize: "5", desiredSize: "1" }] }
        // {pool_id:0, nodePoolValue:'pool-'+makeid(8), nodeSize:0, desiredCount:1,maxCount:1, totalCost:0.00,minSize:"1",maxSize:"5",desiredSize:"1",policies:[{policyname:'policy-'+makeid(8), policytype:"cpu", compare:"above", value:"60", adjust:"1", period:"5m",cooldown:"300"},{policyname:'policy-'+makeid(8), policytype:"cpu", compare:"below", value:"20", adjust:"-1", period:"5m",cooldown:"300"}]},
        // {pool_id:1, nodePoolValue:'pool-'+makeid(8), nodeSize:0, nodeCount:1, totalCost:0.00}
    ],
}

export default function KubernetesReducer(state = initialState, action) {


    switch (action.type) {
        case DEPLOY_NODE_POOL_ROW:
            return [
                { deployPoolRow: action.data }
            ]
        case UPDATE_NODE_POOL_ROW:
            return [
                { nodePoolRow: action.data }
            ]
        default:
            return state
    }
}

