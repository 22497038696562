import localStorage from "local-storage";
import React, { useEffect, useState } from "react";
import { getCurrentTimePeriod } from "../../../services/TimeFunctions";

export default function Footer(){
  const [appInfo, setAppInfo] = useState(null);

  useEffect(()=>{
    const appDetails = localStorage.get("app_info");
    if(appDetails){
      setAppInfo(appDetails);
    }
  })
    return(
        <>
  {/*begin::Footer*/}
  <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
  {/*begin::Container*/}
  {appInfo!=null?(<>
  <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
    {/*begin::Copyright*/}
    <div className="text-dark order-2 order-md-1">
      <span className="text-muted fw-bold me-1">{getCurrentTimePeriod('year')}©</span>
      <a
        href={appInfo?.site ?? "https://cloud.microhost.com"}
        target="_blank"
        className="text-gray-800 text-hover-primary"
      >
        {appInfo?.brand}
      </a>
    </div>
    {/*end::Copyright*/}
    {/*begin::Menu*/}
    <ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
      <li className="menu-item">
        <a
          target="_blank"
          href={appInfo?.docs_url ?? "https://utho.com/docs"}
          className="menu-link px-2"
        >
          {" "}
          Docs
        </a>{" "}
      </li>
    </ul>
    {/*end::Menu*/}
  </div>
  </>):('')}
  {/*end::Container*/}
</div>

  {/*end::Footer*/}
</>

    )
}
