import React from "react";
import "./custom.css";
export default function SelectTest() {

  return (
    <>
      <div className="select-box">
        <div className="select-box__current form-select form-select-sm form-select-solid" tabIndex={1}>
          <input
            className="select-box__input"
            type="radio"
            id={0}
            defaultValue={10}
            name="Ben"
            defaultChecked="checked"
          // onClick={(e) => {props.setPerPage(e.target.value);}}
          />
          <p className="select-box__input-text">10 records</p>
          <input
            className="select-box__input"
            type="radio"
            id={1}
            defaultValue={25}
            name="Ben"
          // onClick={(e) => {props.setPerPage(e.target.value);}}
          />
          <p className="select-box__input-text">25 records</p>
          <input
            className="select-box__input"
            type="radio"
            id={2}
            defaultValue={50}
            name="Ben"
          // onClick={(e) => {console.log(e.target.value);}}
          />
          <p className="select-box__input-text">50 records</p>
          <input
            className="select-box__input"
            type="radio"
            id={3}
            defaultValue={100}
            name="Ben"
          // onClick={(e) => {props.setPerPage(e.target.value);}}
          />
          <p className="select-box__input-text">100 records</p>
          <img
            className="select-box__icon"
            src="http://cdn.onlinewebfonts.com/svg/img_295694.svg"
            alt="Arrow Icon"
            aria-hidden="true"
          />
        </div>
        <ul className="select-box__list">
          <li>
            <label
              className="select-box__option"
              htmlFor={0}
              aria-hidden="aria-hidden"
            >
              10 records
            </label>
          </li>
          <li>
            <label
              className="select-box__option"
              htmlFor={1}
              aria-hidden="aria-hidden"
            >
              25 records
            </label>
          </li>
          <li>
            <label
              className="select-box__option"
              htmlFor={2}
              aria-hidden="aria-hidden"
            >
              50 records
            </label>
          </li>
          <li>
            <label
              className="select-box__option"
              htmlFor={3}
              aria-hidden="aria-hidden"
            >
              100 records
            </label>
          </li>
        </ul>
      </div>

    </>)
}