import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import TableLoader from "../../../../../Loaders/TableLoader";
import TopBarHeader from "../../../../../../../CustomHooks/TopBarHeader";
import TopBarHeaderLine from "../../../../../../../CustomHooks/TopBarHeaderLine";
import AccessDenied from "../../../../../AccessDenied";
import UseApi from "../../../../../../../CustomHooks/useApi";
import AttachSubnet from "./AttachSubnet";
import UseCallApi from "../../../../../../../CustomHooks/useCallApi";

export default function SubnetAssociation() {
  const [dataFetched, setDataFetched] = useState(false);
  const [access, setAccess] = useState(true);
  const [perPage, setPerPage] = useState(5);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [activePage, setActivePage] = useState(0);
  const [routesDataAll, setRoutesDataAll] = useState(null);
  const [dummyRoutesDataAll, setDummyRoutesDataAll] = useState(null);

  var query = window.location.href;
  var vars = query.split("/");
  var routeid = vars[5];

  function ProductInfo({ productdata }) {
    return (
      <>
        {productdata != null && Object.keys(productdata).length != 0 ? (productdata && productdata.map((value, index) => (
          <tr>
            <td className="text-start">

              <div className="d-flex flex-column w-100 me-2">

                {/* <div className="d-flex flex-stack mb-2"> */}

                <span className="me-2 fs-7 fw-bold">
                  {value.name}
                </span>
                {/* </div> */}
              </div>
            </td>
            <td className="text-center">

              <div className="d-flex flex-column w-100 me-2">

                <span className=" me-2 fs-7 fw-bold">
                  {value.uuid}
                </span>
              </div>
            </td>

            <td className="text-center">

              <div className="d-flex flex-column w-100 me-2">
                <span className=" me-2 fs-7 fw-bold">
                  {value.created_at}
                </span>
              </div>
            </td>
            <td className="text-end pe-9">
              {" "}
              <a
                onClick={(e) => dettachedSunbet(value.id)}
                id={`del_subnet_btn_${value.id}`}
                className="btn btn-sm btn-danger sphide"
              >
                Dettached
              </a>
            </td>
          </tr>
        ))) : (<>
          <tr>
            {/* <td colspan="12" className="dataTables_empty" id="loader"> */}
            {/* <div className="loading-spinner"></div> */}
            {/* <TableLoader /> */}
            {/* </td> */}
            <td colspan="12" className="" id="no_record" valign="top">
              <div className="d-flex flex-column flex-center">
                <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
              </div>
            </td>
          </tr>
        </>)}
      </>
    );
  }

  const dettachedSunbet = async (id) => {
    try {
      // Dismiss any previous toasts
      toast.dismiss();

      // Show a loading toast
      const dt = toast.loading("Please wait... System is processing your request.");

      // Find the submit button element
      const submitBtn = document.querySelector(`#del_subnet_btn_${id}`);

      if (submitBtn) {
        // Save the current button content and disable it while processing
        const preContent = submitBtn.innerHTML;
        submitBtn.classList.add("disabled");
        submitBtn.innerHTML = `Please wait... <span class="indicator-label">
          <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>`;

        // Send a POST request
        const responseData = await UseCallApi("post", `vpc/routetable/${routeid}/subnet/${id}/dissociate`, "");

        // Check for errors in the response
        if (responseData.status === "error") {
          toast.update(dt, {
            render: responseData.message,
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        // Handle success
        else if (responseData.status === "success") {
          toast.update(dt, {
            render: responseData.message,
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          // Call the getGateways function if it exists in props
          getVPC()
        }

        // Re-enable the button and restore its content
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
      }
    } catch (error) {
      // Catch any errors and display an error toast
      toast.error("An unexpected error occurred. Please try again.");
      console.error("Error adding VPC route:", error);
    }
  };

  const dataFetchedRef = useRef(false);
  const getVPC = async () => {
    const dt = toast
    const datas = await UseApi('get', `vpc/routetable/${routeid}/subnets`, '');
    // const datas = { status: 'success', routes: [{ ipv4: "172.31.0.0/16", subnetid: "subnet-0fb18e12c7cef389e", name: "associate-subnet-1" }, { ipv4: "192.13.0.0/26", subnetid: "subnet-0fb18e12c7cef389e", name: "associate-subnet-1" }] }
    if (datas) {
      setDataFetched(true);
    }

    if (datas.status == "error") {
      setDataFetched(true)
      setRoutesDataAll([]);
      setDummyRoutesDataAll([])

    } else {
      setDataFetched(true)
      setRoutesDataAll(datas?.data);
      setDummyRoutesDataAll(datas?.data)
    }

  }
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getVPC();
  }, [pageCount, dummyRoutesDataAll]);

  const handleSearch = (text) => {
    const endOffset = itemOffset + perPage;
    const data = routesDataAll.filter(row => {
      return (
        (row.name && row.name.toString().toLowerCase().includes(text.toLowerCase())) ||
        row.dclocation.location && row.dclocation.location.toString().toLowerCase().includes(text.toLowerCase())
      )
    });
    setDummyRoutesDataAll(data)
    setPageCount((old) => old = Math.ceil(data.length / perPage));
  }

  function PaginatedItems({ itemsPerPage, ProductData }) {
    setPageCount(Math.ceil(ProductData.length / parseInt(itemsPerPage)));
    const endOffset = parseInt(itemOffset) + parseInt(itemsPerPage);
    const handlePageClick = (event) => {
      setActivePage((old) => old = event.selected)
      const newOffset = event.selected * parseInt(itemsPerPage);
      setItemOffset(newOffset);
    };
    return (
      <>
        <ProductInfo productdata={ProductData.slice(parseInt(itemOffset), endOffset)} />
        <td colspan="12" className="ps-3">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            initialPage={activePage}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </td>
      </>
    );
  }

  if (dataFetched) {
    if (access) {
      return (
        <>
          <div className="col-xl-12">
            {/*begin::Tables Widget 9*/}
            <div className="card card-xl-stretch mb-5 mb-xl-12">
              {/*begin::Header*/}
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold fs-3 mb-1">
                    Explicit subnet associations
                  </span>
                </h3>
                <div
                  className="card-toolbar"
                >
                  <div className="position-relative my-1" style={{ marginRight: 20 }}>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      data-kt-table-widget-4="search"
                      id="searchClient"
                      style={{ padding: '0.6rem' }}
                      onChange={e => handleSearch(e.target.value)}
                      placeholder="Search"
                    />
                  </div>
                  <div style={{ marginRight: 20 }}>
                    <div className="dataTables_length">
                      <label className="w-125px">
                        <select
                          onChange={e => setPerPage(e.target.value)}
                          //data-control="select2"
                          style={{ padding: '0.6rem' }}
                          className="form-select form-select-sm form-select-solid"
                          defaultValue={5}
                          id="perPage"

                        >
                          <option value={5} selected="">
                            5 Records
                          </option>
                          <option value={10}>10 Records</option>
                          <option value={25}>25 Records</option>
                          <option value={50}>50 Records</option>
                          <option value={100}>100 Records</option>
                        </select>
                      </label>
                    </div>
                  </div>
                  <AttachSubnet getVpc={getVPC} />
                </div>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              {/*begin::Table container*/}
              <div className="table-responsive">
                {/*begin::Table*/}
                <table
                  id="vpcTable"
                  className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
                >
                  {/*begin::Table head*/}
                  <thead className="bg-secondary">
                    <tr className="fw-bold ">
                      <th className="w-100px text-start">Name</th>
                      <th className="w-100px text-center">Subnet ID</th>
                      <th className="w-100px text-center">Created_at</th>
                      <th className="w-100px text-center"></th>
                    </tr>
                  </thead>
                  {/*end::Table head*/}
                  {/*begin::Table body*/}
                  <tbody id="mainTable" >

                    {dummyRoutesDataAll != null ? (<>
                      {access == false ? (<>
                        <tr>
                          <td colspan={12}>
                            <AccessDenied />
                          </td>
                        </tr>
                      </>) : (<>
                        {Object.keys(dummyRoutesDataAll).length != 0 ? (<>
                          <PaginatedItems ProductData={dummyRoutesDataAll} itemsPerPage={perPage} />
                        </>) : (<>
                          <tr>
                            <td colspan={12}>
                              <div className="d-flex flex-column flex-center">
                                <div className="fs-5 fw-bolder text-dark mb-4">No Subnet found.</div>
                              </div>
                            </td>
                          </tr>
                        </>)}
                      </>)}
                    </>) : (<>
                      <tr>
                        <td colspan={12}>
                          <TableLoader />
                          <TopBarHeaderLine />
                        </td>
                      </tr>

                    </>)}
                  </tbody>
                  {/*end::Table body*/}
                </table>
                {/*end::Table*/}
              </div>
              {/*end::Table container*/}
              {/*begin::Body*/}
            </div>
            {/*end::Tables Widget 9*/}
          </div>
        </>
      )
    } else {
      return (
        <>
          <AccessDenied />
        </>
      )
    }
  } else {
    return (
      <>
        <TableLoader />
      </>
    )
  }
}