import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import useRazorpay from "react-razorpay";
import localStorage from "local-storage";
import UseApi from "../../../../../../CustomHooks/useApi";
import UsePostData from "../../../../../../CustomHooks/usePostData";
import axios from "axios";
export default function AddingUpi() {
  console.log("slkjcish");
  const filesUrl = localStorage.get("filesUrl");
  const Razorpay = useRazorpay();

  const saveRazorpayCard = useCallback(async () => {
    var cardBtn = document.querySelector("#rzp-button1");
    toast.dismiss();
    //change button innerHtml with loader onclick
    var btnHtml = cardBtn.innerHTML;
    if (cardBtn) {
      cardBtn.innerHTML =
        'Please wait...<span class="spinner-border spinner-border-sm align-middle ms-2"></span>';
      cardBtn.classList.add("disabled");
    }

    //calling userinfo api and application info
    const u_data = await UseApi("get", "account/info", "");
    const uData = u_data.user;
    const u_razorpay_info = await UseApi("get", "mhcsecinfo", "");

    var ruserid = "";
    var rorder_id = "";
    var rcustomer_id = "";
    var rfullname = "";
    var rmobile = "";
    var remail = "";
    var ramount = 0;
    var rcurrency = "";

    if (uData) {
      ruserid = uData.id;
      rcustomer_id = uData.razorpay_customerid;
      rfullname = uData.fullname;
      rmobile = uData.mobile;
      remail = uData.email;
      rcurrency = uData.currency;
    }

    const createOrder = await UsePostData("post", "razorpay/createorder", {
      currency: "INR",
      type: "upi",
      amount: 1,
    });
    console.log(createOrder, "oderid");
    if (createOrder && createOrder.status == "success") {
      rorder_id = createOrder.id;
    }
    // var options = {
    //   key: u_razorpay_info.razorpay_key,
    //   // order_id: rorder_id,
    //   customer_id: rcustomer_id,
    //   order_id: "order_MmQRPL6ufE5iFQ",
    //   // customer_id: "cust_MmQKmOWc0jmWqI",
    //   recurring: "1",
    //   // callback_url: "https://eneqd3r9zrjok.x.pipedream.net/",
    //        prefill: {
    //     contact: rmobile,
    //   },
    //   notes: {
    //     "note_key 1": "Beam me up Scotty",
    //     "note_key 2": "Tea. Earl Gray. Hot.",
    //   },
    //   theme: {
    //     color: "#F37254",
    //   },
    // };
    const saveOptions = {
      key: u_razorpay_info.razorpay_key,
      // key:'rzp_test_bitmgRLdeZKT2C',
      amount: 100,
      currency: "INR",
      name: "Utho Cloud",
      description: "Welcome to Utho Cloud",
      image:
        (filesUrl != null ? filesUrl : "") +
        "/publicfiles/" +
        (u_razorpay_info ? u_razorpay_info.logo : ""),
      order_id: "order_MmQRPL6ufE5iFQ",
      customer_id: rcustomer_id,
      prefill: {
        contact: rmobile,
      },
      notes: {
        microhost_order_id: "cardauth-" + ruserid,
      },
      options: {
        checkout: {
          method: {
            netbanking: 0,
            card: 0,
            upi: 1,
          },
        },
      },
      recurring: 1,
      handler: async (res) => {
        console.log(res);
        if (res.status_code == 200) {
          const ResData = await UsePostData("post", "razorpay/addtoken", {
            verify: 1,
            action: "upi",
            razorpay_payment_id: res.razorpay_payment_id,
            razorpay_order_id: res.razorpay_order_id,
            razorpay_signature: res.resrazorpay_signature,
          });
          if (ResData.status == "success") {
            swal({
              title: "Card",
              text: "Card added in your account!",
              icon: "success",
              button: "OK",
            });
            setTimeout(() => {
              window.location.reload();
            }, 4000);
          } else {
            swal({
              title: "Card",
              text: "Card not added in your account!",
              icon: "warning",
              button: "OK",
            });
          }
          if (cardBtn) {
            cardBtn.innerHTML = btnHtml;
            cardBtn.classList.remove("disabled");
          }
        }
      },
      notes: {
        address: "Utho Cloud- Fund add",
      },
      theme: {
        color: "#377dff",
      },
    };
    console.log(saveOptions);
    const rzpay = new Razorpay(saveOptions);
    console.log(rzpay, "kjk");
    rzpay.on("payment.failed", function (response) {
      console.log(response, "lkjkj");
      swal({
        title: "Payment failed",
        text: response.error.description,
        icon: "warning",
        button: "OK",
      });
    });
    rzpay.open();
    if (cardBtn) {
      cardBtn.innerHTML = btnHtml;
      cardBtn.classList.remove("disabled");
    }
  }, [Razorpay]);

  return (
    <>
    {/* <script src="https://checkout.razorpay.com/v1/checkout.js"></script> */}
      <a
        onClick={saveRazorpayCard}
        id="rzp-button1"
        className="btn btn-primary px-6 align-self-center text-nowrap"
      >
        Add New Upi
      </a>
    </>
  );
}
