import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import UseApi from "../../../../../../CustomHooks/useApi";
import ListCards from "./ListCards";
import AddingCard from "./AddingCard"; 
export default function AddCardTab() {
  const dataFetchedRef = useRef();
  const [razorpayCardList, setRazorpayCardList] = useState(null);
  const [stripeCardList, setStripeCardList] = useState(null);
  const [isUpi, setIsUpi] = useState(false);

  const getcards = async () => {
    const rdata = await UseApi("get", "razorpay/listcard", "");
    const sdata = await UseApi("get", "stripe/listcard", "");

    setStripeCardList(sdata.cards);
    setRazorpayCardList(rdata.cards);
  };
  const deleteCard = async (cardid) => {
    toast.dismiss();
    swal({
      title: "Are you sure?",
      text: "Do you really want to delete this card from your account!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const dt = toast.loading("Card deleting...");
        const dnsp = await UseApi(
          "DELETE",
          "razorpay/" + cardid + "/deletecard",
          "delete"
        );
        if (dnsp.status == "error") {
          toast.update(dt, {
            render: dnsp.message,
            type: "warning",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.update(dt, {
            render: dnsp.message,
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    });
  };
  const handleState = (condition) => {
    setIsUpi(condition);
  };
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getcards();
  }, []);

  return (
    <>
      <div className="tab-pane fade" id="addcredit" role="tabpanel">
        {/*begin::Deactivate Account*/}
        <div className="">
          {/*begin::Content*/}
          <div id="kt_account_settings_deactivate" className="collapse show">
            {/*begin::Payment methods*/}
            <div className="card mb-5 mb-xl-10">
              {/*begin::Card header*/}
              <div className="card-header card-header-stretch pb-0 pe-5">
                {/*begin::Title*/}
                <div className="card-title">
                  <h3 className="m-0">Payment Methods</h3>
                </div>
                {/*end::Title*/}
                {/*begin::Toolbar*/}
                <div className="card-toolbar m-0">
                  {/*begin::Tab nav*/}
                  <ul
                    className="nav nav-stretch nav-line-tabs border-transparent"
                    role="tablist"
                  >
                    {/*begin::Tab item*/}
                    <li className="nav-item" role="presentation">
                      <a
                        id="kt_billing_creditcard_tab"
                        className="nav-link fs-5 fw-bolder me-5 active"
                        data-bs-toggle="tab"
                        role="tab"
                        href="#kt_billing_creditcard"
                        onClick={()=>{handleState(false)}}
                      >
                        Credit / Debit Card
                      </a>
                    </li>
                    {/*end::Tab item*/}
                    {/*begin::Tab item*/}
                    <li className="nav-item" role="presentation">
                      <a
                        id="kt_billing_paypal_tab"
                        className="nav-link fs-5 fw-bolder d-none"
                        data-bs-toggle="tab"
                        role="tab"
                        href="#kt_billing_paypal"
                        onClick={()=>{handleState(true)}}
                      >
                        Upi
                      </a>
                    </li>
                    {/*end::Tab item*/}
                  </ul>
                  {/*end::Tab nav*/}
                </div>
                {/*end::Toolbar*/}
              </div>
              {/*end::Card header*/}
              {/*begin::Tab content*/}
              <div
                id="kt_billing_payment_tab_content"
                className="card-body tab-content"
              >
                {/*begin::Tab panel*/}
                <div
                  id="kt_billing_creditcard"
                  className="tab-pane fade show active"
                  role="tabpanel"
                >
                  {!isUpi ? (
                    <>
                      <ListCards />
                      <AddingCard type = {"Payment"}/>
                    </>
                  ) : (
                    <>
                    <ListCards/>
                    <AddingCard type = {"Upi"}/>
                    </>
                  )}
                </div>
                {/*end::Tab panel*/}
              </div>
              {/*end::Tab content*/}
            </div>
            {/*end::Payment methods*/}
          </div>
        </div>
      </div>
    </>
  );
}
