import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UsePost from "../../../../../CustomHooks/usePost";
import UseApi from "../../../../../CustomHooks/useApi";
import UseCallApi from "../../../../../CustomHooks/useCallApi";

export default function CreateNatGateway(props) {
  const [vpcData, setVpcData] = useState(null);
  const [elasticData, setElasticData] = useState(null);
  const [subnetAvailable, setSubnetAvailable] = useState(null);

  const openDrawer = () => {
    var drawerElement = document.querySelector("#create-routes-modal");
    var drawer_overlay = document.querySelector("#drawer_overlay_routes");
    var natGatewayName = document.querySelector("#natGatewayName");
    var subnet_select = document.querySelector("#subnet_select");
    const dc_name = document.querySelector("#dc_slug");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      if (window.innerWidth <= 576) {
        // Adjust this value based on your breakpoint
        drawerElement.style.width = "100%";
        subnet_select.value = ''
        natGatewayName.value = ''
        dc_name.value = ''
      } else {
        drawerElement.style.width = "40%";
        subnet_select.value = ''
        natGatewayName.value = ''
        dc_name.value = ''
      }
      drawer_overlay.classList.toggle("d-none");
      subnet_select.value = ''
      natGatewayName.value = ''
      dc_name.value = ''
    }
  };

  const getVPC = async () => {
    const datas = await UseApi('get', 'vpc/subnet?subaction=all', '');
    if (datas.subnet) {
      setSubnetAvailable(datas?.subnet)
    } else {
      setSubnetAvailable([])
    }
  }

  useEffect(() => {
    getVPC();
    elasticIp();
  }, [])

  const elasticIp = async () => {
    const datas = await UseApi('get', 'elasticip', '');

    if (datas.status == "error") {
      setElasticData([]);
    } else {
      setElasticData(datas.ips);
    }
  }
  const addVpcRoute = async () => {
    toast.dismiss();
    const dt = toast.loading(
      "Please wait... System is processing your request."
    );
    const submitBtn = document.querySelector("#add-vpc-subnet");
    if (submitBtn) {
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML =
        'Please wait... <span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      const responseData = await UsePost(
        "post",
        "vpc/natgateway",
        "create-subnet-form"
      );

      if (responseData.status === "error") {
        toast.update(dt, {
          render: responseData.message,
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (responseData.status === "success") {
        toast.update(dt, {
          render: "Subnet created successfully",
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (props && props.getGateways) {
          props.getGateways();
        }
        openDrawer()
      }
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML = preContent;
    }
  };

  const allocateIp = async () => {
    toast.dismiss();
    const dt = toast.loading(
      "Please wait... System is processing your request."
    );
    const submitBtn = document.querySelector("#allocateIpBtn");
    const dc_name = document.querySelector("#dc_slug");
    if (dc_name.value) {
      if (submitBtn) {
        var preContent = submitBtn.innerHTML;
        submitBtn.classList.add("disabled");
        submitBtn.innerHTML =
          'Please wait... <span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
        const responseData = await UseCallApi("post", "elasticip/allocate", { "dcslug": dc_name.value });

        if (responseData.status === "error") {
          toast.update(dt, {
            render: responseData.message,
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (responseData.status === "success") {
          toast.update(dt, {
            render: "Subnet created successfully",
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          elasticIp()
          // openDrawer()
        }
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
      }
    } else {
      setTimeout(() => {
        toast.update(dt, {
          render: "Please select subnet first",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }, 1000)
      return
    }
  }

  const handleSelectSubnet = (dcname) => {
    const dc_name = document.querySelector('#dc_slug')
    dc_name.value = dcname
  }

  return (
    <>
      <a className="btn btn-sm btn-primary me-2" onClick={openDrawer}>
        {/*begin::Svg Icon | path: assets/media/icons/duotune/arrows/arr009.svg*/}
        <span className="svg-icon svg-icon-muted svg-icon-1hx">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              opacity="0.3"
              d="M21 13H3C2.4 13 2 12.6 2 12C2 11.4 2.4 11 3 11H21C21.6 11 22 11.4 22 12C22 12.6 21.6 13 21 13Z"
              fill="black"
            />
            <path
              d="M12 22C11.4 22 11 21.6 11 21V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V21C13 21.6 12.6 22 12 22Z"
              fill="black"
            />
          </svg>
        </span>
        {/*end::Svg Icon*/}
        Create NAT Gateway
      </a>
      <div
        id="drawer_overlay_routes"
        onClick={openDrawer}
        className="drawer-overlay d-none"
        style={{ zIndex: 109, width: "100%" }}
      ></div>
      <div
        id="create-routes-modal"
        className="bg-body shadow drawer drawer-end"
        data-kt-drawer="true"
        data-kt-drawer-name="chat"
        data-kt-drawer-activate="true"
        data-kt-drawer-direction="end"
        // data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
        style={{ important: true, zIndex: 110 }}
      >
        {/*begin::Messenger*/}
        <div
          className="card w-100 rounded-0 border-0"
          id="kt_drawer_chat_messenger"
          style={{ height: 'max-content' }}
        >
          {/*begin::Card header*/}
          <div
            className="card-header pe-5"
            id="kt_drawer_chat_messenger_header"
          >
            {/*begin::Title*/}
            <div className="card-title">
              {/*begin::User*/}
              <div className="d-flex justify-content-center flex-column me-3">
                <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                  Create NAT Gateway
                </a>
              </div>
              {/*end::User*/}
            </div>
            {/*end::Title*/}
            {/*begin::Card toolbar*/}
            <div className="card-toolbar">
              {/*begin::Close*/}
              <div
                onClick={openDrawer}
                className="btn btn-sm btn-icon btn-active-light-primary"
                id="close_api"
              >
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                <span className="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x={6}
                      y="17.3137"
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(-45 6 17.3137)"
                      fill="black"
                    />
                    <rect
                      x="7.41422"
                      y={6}
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(45 7.41422 6)"
                      fill="black"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
              </div>
              {/*end::Close*/}
            </div>
            {/*end::Card toolbar*/}
          </div>
          {/*end::Card header*/}
          {/*begin::Card body*/}
          <div className="" id="">
            {/*begin::Form*/}
            <form id="create-subnet-form" className="form">
              {/*begin::Modal body*/}
              <div className="modal-body ">
                {/*begin::Scroll*/}
                <div className=" me-n7 pe-7">
                  <div className="pt-0 row">
                    <div className="col-lg-12">
                      {/*begin::Input group*/}
                      <div className="mb-10 fv-row fv-plugins-icon-container">
                        {/*begin::Label*/}
                        <label className="required form-label">
                          Name
                        </label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <input
                          type="text"
                          name="name"
                          className="form-control mb-2"
                          placeholder="Enter the name"
                          defaultValue=""
                          id="natGatewayName"
                        />
                        {/*end::Input*/}
                        {/*begin::Description*/}
                        <div className="text-muted fs-7">
                          A Gateway name is required and recommended to be
                          unique.
                        </div>
                        {/*end::Description*/}
                        <div className="fv-plugins-message-container invalid-feedback" />
                      </div>
                      {/*end::Input group*/}
                    </div>
                    <div className="col-lg-12">
                      {/*begin::Input group*/}
                      <div className="mb-10 fv-row fv-plugins-icon-container">
                        {/*begin::Label*/}
                        <label className="required form-label">
                          Subnet
                        </label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <select
                          name="subnet"
                          defaultValue="monthly"
                          className="form-select mb-2"
                          id="subnet_select"
                          onChange={(e) => handleSelectSubnet(e.target.options[e.target.selectedIndex].dataset.dcslug)}
                        >
                          <option value="" disabled selected>Choose a Subnet</option>
                          {subnetAvailable != null && subnetAvailable.length > 0 &&
                            subnetAvailable.map((value, index) => {
                              return (
                                <option value={value.id} data-dcslug={value.dcslug}>{value.name}</option>
                              )
                            })}
                        </select>
                        {/*end::Input*/}
                        {/*begin::Description*/}
                        <input
                          type="hidden"
                          id="dc_slug"
                          name="dcslug"
                        />
                        <div className="text-muted fs-7">
                          Select a subnet in which to create the NAT gateway.
                        </div>
                        {/*end::Description*/}
                        <div className="fv-plugins-message-container invalid-feedback" />
                      </div>
                      {/*end::Input group*/}
                    </div>
                    <div className="col-lg-12">
                      {/*begin::Input group*/}
                      <div className="mb-10 fv-row fv-plugins-icon-container">
                        {/*begin::Label*/}
                        <label className="required form-label">
                          Connectivity Type
                        </label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <select
                          name="type"
                          defaultValue="monthly"
                          className="form-select mb-2"
                        // id="type"
                        >
                          <option value="public">Public</option>
                          <option value="private">Private</option>
                        </select>
                        {/*end::Input*/}
                        {/*begin::Description*/}
                        <div className="text-muted fs-7">
                          Select a connectivity type for the NAT gateway.
                        </div>
                        {/*end::Description*/}
                        <div className="fv-plugins-message-container invalid-feedback" />
                      </div>
                      {/*end::Input group*/}
                    </div>
                    <div className="row">
                      <div className="col-lg-8">
                        {/*begin::Input group*/}
                        <div className="mb-10 fv-row fv-plugins-icon-container">
                          {/*begin::Label*/}
                          <label className="required form-label">
                            Elastic IP allocation ID
                          </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <select
                            name="publicip"
                            defaultValue="monthly"
                            className="form-select mb-2"
                          >
                            <option value="">Select a Elastic IP</option>
                            {elasticData != null && elasticData.length > 0 &&
                              elasticData.map((value, index) => {
                                return (
                                  <option value={value.ip}>reserved-ip-{value.ip}</option>
                                )
                              })}
                            {/* <option value="eipalloc-09a3d2036fd7de964">eipalloc-09a3d2036fd7de964</option> */}
                          </select>
                          {/*end::Input*/}
                          {/*begin::Description*/}
                          <div className="text-muted fs-7">
                            Assign an Elastic IP address to the NAT gateway.
                          </div>
                          {/*end::Description*/}
                          <div className="fv-plugins-message-container invalid-feedback" />
                        </div>
                        {/*end::Input group*/}
                      </div>
                      <div className="col-lg-4">
                        {/*begin::Input group*/}
                        <div className="mt-8 fv-row fv-plugins-icon-container">
                          <a className="btn btn-primary btn-active-light-primary sphide" id="allocateIpBtn" onClick={allocateIp}>Allocate IP</a>
                        </div>
                        {/*end::Description*/}
                        {/*end::Input group*/}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <a
                        className="btn btn-primary mt-5 w-100"
                        onClick={addVpcRoute}
                        id="add-vpc-subnet"
                      >
                        <span className="svg-icon svg-icon-muted svg-icon-1hx">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              opacity="0.3"
                              d="M21 13H3C2.4 13 2 12.6 2 12C2 11.4 2.4 11 3 11H21C21.6 11 22 11.4 22 12C22 12.6 21.6 13 21 13Z"
                              fill="black"
                            />
                            <path
                              d="M12 22C11.4 22 11 21.6 11 21V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V21C13 21.6 12.6 22 12 22Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        Create NAT Gateway
                      </a>
                    </div>
                  </div>
                </div>
                {/*end::Scroll*/}
              </div>
              {/*end::Modal body*/}
            </form>
            {/*end::Form*/}
          </div>
          {/*end::Card body*/}
        </div>

        {/*end::Messenger*/}
      </div>
    </>
  );
}
