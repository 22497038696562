import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import UseApi from "../../../../../../CustomHooks/useApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import TopBarHeaderLine from "../../../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../../../Loaders/TableLoader";
import CreateInternetGateway from "../CreateInternetGateway";
import CreateSubnet from "./VpcAttachInternetGateway";
import UsePost from "../../../../../../CustomHooks/usePost";
import UseCallApi from "../../../../../../CustomHooks/useCallApi";
import VpcAttachInternetGateway from "./VpcAttachInternetGateway";

export default function InternetGatewayVpc(props) {
    var query = window.location.href;
    var vars = query.split("/");
    var id = vars[5];

    const [subnetData, setSubnetData] = useState(null)
    const [dataFetched, setDataFetched] = useState(false);
    const [pageCount, setPageCount] = useState(1);
    const [perPage, setPerPage] = useState(5);
    const [itemOffset, setItemOffset] = useState(0);
    const [activePage, setActivePage] = useState(0)
    const [internetGatewayDataAll, setInternetGatewayDataAll] = useState(null);
    const [dummysubnetData, setDummySubnetData] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const dataFetchedRef = useRef(false);
    const [subnetAvailable, setSubnetAvailable] = useState(null);
    const [vpcData, setVpcData] = useState(null);


    const getSubnets = async () => {
        const datas = await UseApi('get', 'vpc', '');
        if (datas.status == "error" && datas.message == "No VPC.") {
            setSubnetAvailable([]);
        } else {
            const vpcIsZero = datas?.vpc.filter(item => item.is_vpc == "0");
            setSubnetAvailable(vpcIsZero)
        }
    }

    const getInternetGateway = async () => {
        const dt = toast.dismiss();
        const datas = await UseApi("get", `vpc/internetgateway/${id}`, "");
        if (datas) {
            if (Array.isArray(datas.data[0].vpcs)) {
                setDataFetched(true)
                setInternetGatewayDataAll(datas?.data)
                setDummySubnetData(datas?.data[0]?.vpcs)
                setSubnetData(datas?.data[0]?.vpcs)
            } else {
                setDataFetched(true)
                setInternetGatewayDataAll(datas?.data)
                setDummySubnetData([datas?.data[0]?.vpcs])
                setSubnetData([datas?.data[0]?.vpcs])
            }
        }

    }

    console.log(internetGatewayDataAll, dummysubnetData);


    const dettachedSunbet = async (subnetid) => {
        try {
            // Dismiss any previous toasts
            toast.dismiss();

            // Show a loading toast
            const dt = toast.loading("Please wait... System is processing your request.");

            // Find the submit button element
            const submitBtn = document.querySelector(`#del_subnet_btn_${subnetid}`);

            if (submitBtn) {
                // Save the current button content and disable it while processing
                const preContent = submitBtn.innerHTML;
                submitBtn.classList.add("disabled");
                submitBtn.innerHTML = `Please wait... <span class="indicator-label">
              <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>`;

                // Send a POST request
                const responseData = await UseCallApi("post", `vpc/internetgateway/${id}/dettach`, { "vpc": subnetid });

                // Check for errors in the response
                if (responseData.status === "error") {
                    toast.update(dt, {
                        render: responseData.message,
                        type: "error",
                        isLoading: false,
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                // Handle success
                else if (responseData.status === "success") {
                    toast.update(dt, {
                        render: responseData.message,
                        type: "success",
                        isLoading: false,
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    // Call the getGateways function if it exists in props
                    getInternetGateway()
                }

                // Re-enable the button and restore its content
                submitBtn.classList.remove("disabled");
                submitBtn.innerHTML = preContent;
            }
        } catch (error) {
            // Catch any errors and display an error toast
            toast.error("An unexpected error occurred. Please try again.");
            console.error("Error adding VPC route:", error);
        }
    };

    function ProductInfo({ productdata }) {
        return (
            <>
                {productdata != null && Object.keys(productdata).length != 0 ? (
                    productdata &&
                    productdata.map((value, index) => (
                        <tr>
                            {/* <td /> */}
                            <td className="text-start ps-9">
                                <div className="d-flex flex-column w-100 me-2">
                                    <div className="d-flex flex-stack mb-2">
                                        <span className=" me-2 fs-7 fw-bold">{value.id}</span>
                                    </div>
                                </div>
                            </td>
                            <td className="text-center">
                                {/* <div className="d-flex flex-column w-100 me-2">
                  <div className="d-flex flex-stack mb-2"> */}
                                <span className="me-2 fs-7 fw-bold">
                                    {value.name}
                                </span>
                                {/* </div>
                </div> */}
                            </td>
                            <td className="text-center">
                                {/* <div className="d-flex flex-column w-100 me-2">
                  <div className="d-flex flex-stack mb-2"> */}
                                <span className="me-2 fs-7 fw-bold">
                                    {value.is_default == 0 ? "yes" : 'no'}
                                </span>
                                {/* </div>
                </div> */}
                            </td>
                            <td className="text-center">
                                {/* <div className="d-flex flex-column w-100 me-2">
                  <div className="d-flex flex-stack mb-2"> */}
                                <span className="me-2 fs-7 fw-bold">
                                    {value.network}
                                </span>
                                {/* </div>
                </div> */}
                            </td>
                            <td className="text-center">
                                {/* <div className="d-flex flex-column w-100 me-2">
                  <div className="d-flex flex-stack mb-2"> */}
                                {value.status == "Active" ?
                                    <span className="badge badge-success badge-lg fw-bold">
                                        {value.status}
                                    </span>
                                    :
                                    <span className="badge badge-warning badge-lg fw-bold">
                                        {value.status}
                                    </span>
                                }
                                {/* </div>
                </div> */}
                            </td>
                            <td className="text-center d-none">
                                {/* <div className="d-flex flex-column w-100 me-2">
                  <div className="d-flex flex-stack mb-2"> */}
                                <span className="me-2 fs-7 fw-bold">{value.created_at}</span>
                                {/* </div>
                </div> */}
                            </td>
                            <td className="text-end pe-9">
                                {" "}
                                <a
                                    onClick={(e) => dettachedSunbet(value.id)}
                                    id={`del_subnet_btn_${value.id}`}
                                    className="btn btn-sm btn-danger sphide"
                                >
                                    Dettached
                                </a>
                            </td>
                        </tr>
                    ))
                ) : (
                    <>
                        <tr>
                            <td colspan="12" className="" id="no_record" valign="top">
                                <div className="d-flex flex-column flex-center">
                                    <div className="fs-5 fw-bolder text-dark mb-4">
                                        No items found.
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </>
                )}
            </>
        );
    }

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        toast.dismiss();
        getInternetGateway()
        getSubnets();
    }, []);

    const handleSearch = (text) => {
        let data = subnetData.filter((row) => {
            return (
                (row.name && row.name.toString().toLowerCase().includes(text.toLowerCase())))
        });

        // Update the state with the filtered data
        setDummySubnetData(data)
        setPageCount(Math.ceil(data.length / perPage));
    }


    function PaginatedItems({ itemsPerPage, ProductData }) {
        setPageCount(Math.ceil(ProductData.length / parseInt(itemsPerPage)));
        const endOffset = parseInt(itemOffset) + parseInt(itemsPerPage);
        const handlePageClick = (event) => {
            setActivePage((old) => old = event.selected)
            const newOffset = event.selected * parseInt(itemsPerPage);
            setItemOffset(newOffset);
        };
        return (
            <>
                <ProductInfo productdata={ProductData?.slice(parseInt(itemOffset), endOffset)} />
                {ProductData.length > 0 && ProductData.length > 5 ?
                    <td colspan="12" className="ps-3">
                        <ReactPaginate
                            nextLabel="next >"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            pageCount={pageCount}
                            initialPage={activePage}
                            previousLabel="< previous"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                        />
                    </td>
                    :
                    ""
                }
            </>
        );
    }

    if (dataFetched) {
        return (
            <>
                <div className="col-xl-12">
                    {/*begin::Tables Widget 9*/}
                    <div className="card card-xl-stretch mb-5 mb-xl-12">
                        {/*begin::Header*/}
                        <div className="card-header border-0 pt-5">
                            <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bold fs-3 mb-1">Vpc</span>
                            </h3>
                            <div className="card-toolbar">
                                <div
                                    className="position-relative my-1"
                                    style={{ marginRight: 20 }}
                                >
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        data-kt-table-widget-4="search"
                                        id="searchClient"
                                        onChange={(e) => handleSearch(e.target.value)}
                                        placeholder="Search"
                                    />
                                </div>
                                <div style={{ marginRight: 20 }}>
                                    <div className="dataTables_length">
                                        <label className="w-125px">
                                            <select
                                                onChange={(e) => setPerPage(e.target.value)}
                                                //data-control="select2"
                                                className="form-select form-select-sm form-select-solid"
                                                defaultValue={5}
                                                id="perPage"
                                            >
                                                <option value={5} selected="">
                                                    5 Records
                                                </option>
                                                <option value={10}>10 Records</option>
                                                <option value={25}>25 Records</option>
                                                <option value={50}>50 Records</option>
                                                <option value={100}>100 Records</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>
                                <VpcAttachInternetGateway getGateways={getInternetGateway} />
                            </div>
                        </div>
                        {/*end::Header*/}
                        {/*begin::Body*/}
                        {/*begin::Table container*/}
                        <div className="table-responsive">
                            {/*begin::Table*/}
                            <table
                                id="vpcTable"
                                className="table table-striped table-row-gray-300 align-middle gs-0 gy-4"
                            >
                                {/*begin::Table head*/}
                                <thead className="bg-secondary">
                                    <tr className="fw-bold ">
                                        {/* <th className="w-20px p-3" /> */}
                                        <th className="w-250px text-start ps-9">ID</th>
                                        <th className="w-100px text-center sphide">Name</th>
                                        <th className="w-100px text-center sphide d-none">VPC ID</th>
                                        <th className="w-100px text-center sphide">Default</th>
                                        <th className="w-100px text-center sphide">network</th>
                                        <th className="w-250px text-center">Status</th>
                                        <th className="w-150px text-center d-none">Created_at</th>
                                        {/* <th className="w-150px d-none">Status</th> */}
                                        <th className="w-150px text-center"></th>
                                    </tr>
                                </thead>
                                {/*end::Table head*/}
                                {/*begin::Table body*/}
                                <tbody id="mainTable">
                                    {dummysubnetData != null ? (
                                        <>
                                            {dummysubnetData.length != 0 ? (
                                                <>
                                                    <PaginatedItems
                                                        ProductData={dummysubnetData}
                                                        itemsPerPage={perPage}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <tr>
                                                        <td colspan={12}>
                                                            <div className="d-flex flex-column flex-center">
                                                                <div className="fs-5 fw-bolder text-dark mb-4">
                                                                    No Vpc found.
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <tr>
                                                <td colspan={12}>
                                                    <TableLoader />
                                                    <TopBarHeaderLine />
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                </tbody>
                                {/*end::Table body*/}
                            </table>
                            {/*end::Table*/}
                        </div>
                        {/*end::Table container*/}
                        {/*begin::Body*/}
                    </div>
                    {/*end::Tables Widget 9*/}
                </div>
            </>
        );
    } else {
        return (<>
            <TableLoader />
            <TopBarHeaderLine />
        </>)
    }
}
