import { useEffect, useState } from "react"
import getUrlDetails from "../services/Url";
import { Link, useNavigate } from "react-router-dom"; 
import { getAppInfo } from "../middleware/AppConfig";

export default function Unauthorized(){
  const [homeUrl, setHomeUrl] =useState(''); 
  const [whiteLogo, setWhiteLogo] =useState(''); 
  
  useEffect(()=>{
    (async function(){
    const UrlInfo = await getUrlDetails();
    const appInfo = await getAppInfo();
    console.log(appInfo);
    if(UrlInfo.origin){
      setWhiteLogo(appInfo.origin+'/'+appInfo.publicDir+'/'+appInfo.logo);
      setHomeUrl(UrlInfo.origin);
    }
  })();
  },[])
 
    return (
        <> 
  {/*begin::Head*/}
  <base />
  <title>
    Utho - unauthorized access
  </title>
  <meta charSet="utf-8" /> 
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  <meta property="og:locale" content="en_US" /> 
  <link rel="shortcut icon" href="assets/media/logos/favicon.ico" />
  {/*begin::Fonts*/}
  <link
    rel="stylesheet"
    href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700"
  />
  {/*end::Fonts*/}
  {/*begin::Global Stylesheets Bundle(used by all pages)*/}
  <link
    href="assets/plugins/global/plugins.bundle.css"
    rel="stylesheet"
    type="text/css"
  />
  <link href="assets/css/style.bundle.css" rel="stylesheet" type="text/css" />
  {/*end::Global Stylesheets Bundle*/}
  {/*end::Head*/}
  {/*begin::Body*/}
  {/*begin::Main*/}
  {/*begin::Root*/}
  <div className="d-flex flex-column flex-root">
    {/*begin::Authentication - Signup Welcome Message */}
    <div className="d-flex flex-column flex-column-fluid">
      {/*begin::Content*/}
      <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
        {/*begin::Logo*/}
        <Link to= {homeUrl}>
        <a className="mb-20 pt-lg-10">
          <img
            alt="Logo"
            src={whiteLogo}
            className="h-80px mb-5"
          />
        </a>
        </Link>
        {/*end::Logo*/}
        {/*begin::Wrapper*/}
        <div className="pt-lg-10 mb-10 mt-20">
          {/*begin::Logo*/}
          <h1 className="fw-bolder fs-2qx text-gray-800 mb-7">
          Unauthorized Attempt Detected
          </h1>
          {/*end::Logo*/}
          {/*begin::Message*/}
          <div className="fw-bold fs-3 text-muted mb-15">
          It looks like you're trying to access developer tools.<br/> This action is not allowed.
          </div>
          {/*end::Message*/}
          {/*begin::Action*/}
          <div className="text-center">
            <Link to= {homeUrl}>
            <a 
              className="btn btn-lg btn-primary fw-bolder"
            >
              Go to homepage
            </a>
            </Link>
          </div>
          {/*end::Action*/}
        </div>
        {/*end::Wrapper*/} 
      </div>
      {/*end::Content*/} 
    </div>
    {/*end::Authentication - Signup Welcome Message*/}
  </div>
  {/*end::Root*/}
  {/*end::Main*/} 
  {/*end::Body*/}
</>

    ) 
}