import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom/dist"; 
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import swal from 'sweetalert';   
import localStorage from "local-storage";   
import ReactPaginate from 'react-paginate';
import UsePlatfromApi from "../../../../../CustomHooks/usePlatfromApi";
import UseDigioPost from "../../../../../CustomHooks/useDigioPost";
import UseApi from "../../../../../CustomHooks/useApi";
import UsePostData from "../../../../../CustomHooks/usePostData";
const KycVerifcation = () => { 
  const location=useLocation(); 
  const navigate = useNavigate();
  const [userData, setUserData]=useState(null); 
  const dataFetchedRef=useRef(false);
  async function getUser(){
    const data=await UseApi('get','account/info','');  
    if(data){
        if(data.user){
            setUserData(data.user);
        }
    }
  }
  useEffect(()=>{
    if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
    getUser(); 
    // const queryParams=new URLSearchParams(location.search);
    //   const kyc_doc_id=queryParams.get("digio_doc_id");
    //   if(!kyc_doc_id)return; 
    //   saveKyc(kyc_doc_id); 
  },[]) 
  // const saveKyc=async(id)=>{
  //   const ResKycData=await UsePostData("POST","account/kyc",{"requestid":id});
  //   if(ResKycData.status=="success"){ 
  //     const saveRequest=await UsePostData('POST','account/kyc',{"digio_data":ResKycData.data,"requestid":id});
  //     if(saveRequest){
  //       if(saveRequest.status=="success"){ 
  //         navigate("/");
  //        //window.location.reload();

  //       }else{
  //         const dt=toast.loading("Please wait...");
  //         toast.update(dt,{render: "Something went wrong contact to support!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
  //       });
  //      return;

  //       }
  //     } 
      
  //   }
  // }
   const kyc_process=async()=>{
  var kycBtn=document.querySelector("#kycBtn");
  if(kycBtn){
    var btnHtml=kycBtn.innerHTML;
    kycBtn.innerHTML='<span class="spinner-border spinner-border-sm align-middle ms-2"></span>';
    kycBtn.classList.add("disabled");
       if(userData!=null){
         const kycData=await UsePostData("POST","account/kyc/new",{"email":userData.email,"fullname":userData.fullname});
         if(kycData){
          kycBtn.innerHTML=btnHtml;
          kycBtn.classList.remove("disabled"); 
         if(kycData.status=="success"){ 
            const ResData=await UsePostData("POST","account/kyc",{"requestid":kycData.data.id});
            if(ResData.data.status=="requested"){
           // const saveRequest=await UsePostData('POST','account/kyc',{"digio_data":ResData.data,"requestid":kycData.data.id});
            //console.log(ResData.data);
             
              window.location.href="https://ext.digio.in/#/gateway/login/"+kycData.data.id+"/"+kycData.data.transaction_id+"/"+kycData.data.customer_identifier+"?redirect_url="+window.location.href+"kyc/complete";
              
            }  
         }else{
          swal({
              title: "Kyc Request Failed",
              text: kycData.message,
              icon: "warning",
              button: "OK",
            });
         } 
      }
    }
  }
 }
    return (
        <>
             <div className="card">
  {/*begin::Body*/}
  <div className="mt-5 d-flex flex-column flex-center">
    {/*begin::Heading*/}
    <div className="mb-2">
      {/*begin::Title*/}
      <h1 className="fw-bold text-gray-800 text-center lh-lg">
      Complete Your KYC 
        <br />
        <span className="fw-boldest">Click the below to complete KYC</span>
      </h1>
      {/*end::Title*/}
      {/*begin::Illustration*/}
       {/*begin::Svg Icon | path: assets/media/icons/duotune/finance/fin002.svg*/}
      <div className="text-center">
      <span className="mw-100 mh-300px">
      <svg
  xmlns="http://www.w3.org/2000/svg"
  width="140px"
  height="140px"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    opacity="0.3"
    d="M21 10.7192H3C2.4 10.7192 2 11.1192 2 11.7192C2 12.3192 2.4 12.7192 3 12.7192H6V14.7192C6 18.0192 8.7 20.7192 12 20.7192C15.3 20.7192 18 18.0192 18 14.7192V12.7192H21C21.6 12.7192 22 12.3192 22 11.7192C22 11.1192 21.6 10.7192 21 10.7192Z"
    fill="#21325B"
  />
  <path
    d="M11.6 21.9192C11.4 21.9192 11.2 21.8192 11 21.7192C10.6 21.4192 10.5 20.7191 10.8 20.3191C11.7 19.1191 12.3 17.8191 12.7 16.3191C12.8 15.8191 13.4 15.4192 13.9 15.6192C14.4 15.7192 14.8 16.3191 14.6 16.8191C14.2 18.5191 13.4 20.1192 12.4 21.5192C12.2 21.7192 11.9 21.9192 11.6 21.9192ZM8.7 19.7192C10.2 18.1192 11 15.9192 11 13.7192V8.71917C11 8.11917 11.4 7.71917 12 7.71917C12.6 7.71917 13 8.11917 13 8.71917V13.0192C13 13.6192 13.4 14.0192 14 14.0192C14.6 14.0192 15 13.6192 15 13.0192V8.71917C15 7.01917 13.7 5.71917 12 5.71917C10.3 5.71917 9 7.01917 9 8.71917V13.7192C9 15.4192 8.4 17.1191 7.2 18.3191C6.8 18.7191 6.9 19.3192 7.3 19.7192C7.5 19.9192 7.7 20.0192 8 20.0192C8.3 20.0192 8.5 19.9192 8.7 19.7192ZM6 16.7192C6.5 16.7192 7 16.2192 7 15.7192V8.71917C7 8.11917 7.1 7.51918 7.3 6.91918C7.5 6.41918 7.2 5.8192 6.7 5.6192C6.2 5.4192 5.59999 5.71917 5.39999 6.21917C5.09999 7.01917 5 7.81917 5 8.71917V15.7192V15.8191C5 16.3191 5.5 16.7192 6 16.7192ZM9 4.71917C9.5 4.31917 10.1 4.11918 10.7 3.91918C11.2 3.81918 11.5 3.21917 11.4 2.71917C11.3 2.21917 10.7 1.91916 10.2 2.01916C9.4 2.21916 8.59999 2.6192 7.89999 3.1192C7.49999 3.4192 7.4 4.11916 7.7 4.51916C7.9 4.81916 8.2 4.91918 8.5 4.91918C8.6 4.91918 8.8 4.81917 9 4.71917ZM18.2 18.9192C18.7 17.2192 19 15.5192 19 13.7192V8.71917C19 5.71917 17.1 3.1192 14.3 2.1192C13.8 1.9192 13.2 2.21917 13 2.71917C12.8 3.21917 13.1 3.81916 13.6 4.01916C15.6 4.71916 17 6.61917 17 8.71917V13.7192C17 15.3192 16.8 16.8191 16.3 18.3191C16.1 18.8191 16.4 19.4192 16.9 19.6192C17 19.6192 17.1 19.6192 17.2 19.6192C17.7 19.6192 18 19.3192 18.2 18.9192Z"
    fill="#21325B"
  />
</svg>



      </span> 

    </div>
  {/*end::Svg Icon*/} 
      {/*end::Illustration*/}
    </div>
    {/*end::Heading*/}
    {/*begin::Links*/}
    <div className="text-center mb-16">
      {/*begin::Link*/}
      <a id="kycBtn" onClick={kyc_process} class="btn btn-primary">Add New Kyc</a>
      {/*end::Link*/}
    </div>
    {/*end::Links*/}
  </div>
  {/*end::Body*/}
</div> 
        </>
    );
}

export default KycVerifcation;
