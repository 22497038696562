import {React} from 'react';

export default function Exp2(){
    return(
        <>
  {/* Begin backgroundTable */}
  <table
    align="center"
    bgcolor="#ebebeb"
    border={0}
    cellPadding={0}
    cellSpacing={0}
    height="100%"
    width="100%"
    id="backgroundTable"
    style={{
      WebkitTextSizeAdjust: "100%",
      msTextSizeAdjust: "100%",
      msoTableLspace: "0pt",
      msoTableRspace: "0pt",
      borderCollapse: "collapse !important",
      backgroundColor: "#ebebeb",
      fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
      height: "100% !important",
      margin: 0,
      padding: 0,
      width: "100% !important"
    }}
  >
    <tbody>
      <tr>
        <td
          align="center"
          valign="top"
          id="bodyCell"
          style={{
            WebkitTextSizeAdjust: "100%",
            msTextSizeAdjust: "100%",
            msoTableLspace: "0pt",
            msoTableRspace: "0pt",
            height: "100% !important",
            margin: 0,
            padding: 0,
            width: "100% !important"
          }}
          height="100%"
          width="100%"
        >
          {" "}
          {/* When nesting tables within a TD, align center keeps it well, centered. */}
          {/* Begin Template Container */}
          {/* This holds everything together in a nice container */}
          <table
            border={0}
            cellPadding={0}
            cellSpacing={0}
            id="templateTable"
            style={{
              WebkitTextSizeAdjust: "100%",
              msTextSizeAdjust: "100%",
              msoTableLspace: "0pt",
              msoTableRspace: "0pt",
              borderCollapse: "collapse !important",
              width: 600,
              backgroundColor: "#ffffff",
              WebkitFontSmoothing: "antialiased"
            }}
            bgcolor="#ffffff"
          >
            <tbody>
              <tr>
                <td
                  align="center"
                  valign="top"
                  style={{
                    WebkitTextSizeAdjust: "100%",
                    msTextSizeAdjust: "100%",
                    msoTableLspace: "0pt",
                    msoTableRspace: "0pt",
                    width: "100% !important"
                  }}
                >
                  {/* Begin Template Preheader */}
                  <div className="header-container-wrapper"></div>
                  <table
                    border={0}
                    cellPadding={0}
                    cellSpacing={0}
                    width="100%"
                    id="headerTable"
                    style={{
                      WebkitTextSizeAdjust: "100%",
                      msTextSizeAdjust: "100%",
                      msoTableLspace: "0pt",
                      msoTableRspace: "0pt",
                      backgroundColor: "#ebebeb",
                      color: "#000000",
                      fontFamily:
                        '"Helvetica Neue", Helvetica, Arial, sans-serif',
                      fontSize: 10,
                      lineHeight: "120%",
                      textAlign: "right",
                      borderCollapse: "separate !important",
                      paddingRight: 30
                    }}
                    bgcolor="#ebebeb"
                    align="right"
                  >
                    <tbody>
                      <tr>
                        <td
                          align="left"
                          valign="top"
                          className="bodyContent"
                          width="100%"
                          colSpan={12}
                          style={{
                            WebkitTextSizeAdjust: "100%",
                            msTextSizeAdjust: "100%",
                            msoTableLspace: "0pt",
                            msoTableRspace: "0pt",
                            color: "#000000",
                            fontFamily:
                              '"Helvetica Neue", Helvetica, Arial, sans-serif',
                            fontSize: 16,
                            lineHeight: "150%",
                            textAlign: "left"
                          }}
                        >
                          <table
                            cellPadding={0}
                            cellSpacing={0}
                            border={0}
                            width="100%"
                            className="templateColumnWrapper"
                            style={{
                              WebkitTextSizeAdjust: "100%",
                              msTextSizeAdjust: "100%",
                              msoTableLspace: "0pt",
                              msoTableRspace: "0pt",
                              borderCollapse: "collapse !important"
                            }}
                          >
                            <tbody>
                              <tr>
                                <td
                                  valign="top"
                                  colSpan={12}
                                  width="100.0%"
                                  className=" column"
                                  style={{
                                    WebkitTextSizeAdjust: "100%",
                                    msTextSizeAdjust: "100%",
                                    msoTableLspace: "0pt",
                                    msoTableRspace: "0pt",
                                    width: "100.0%",
                                    textAlign: "left",
                                    padding: 0,
                                    fontFamily:
                                      '"Helvetica Neue", Helvetica, Arial, sans-serif',
                                    fontSize: 16,
                                    lineHeight: "1.5em",
                                    color: "#000000"
                                  }}
                                  align="left"
                                >
                                  {/*end widget-span */}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      {/*end header wrapper */}
                    </tbody>
                  </table>
                  {/* End Template Preheader */}
                </td>
              </tr>
              <tr>
                <td
                  align="center"
                  valign="top"
                  id="contentCell"
                  style={{
                    WebkitTextSizeAdjust: "100%",
                    msTextSizeAdjust: "100%",
                    msoTableLspace: "0pt",
                    msoTableRspace: "0pt",
                    padding: "10px 20px",
                    backgroundColor: "#ebebeb"
                  }}
                  bgcolor="#ebebeb"
                >
                  {/* Begin Template Wrapper */}
                  {/* This separates the preheader which usually contains the "open in browser, etc" content
                          from the actual body of the email. Can alternatively contain the footer too, but I choose not
                          to so that it stays outside of the border. */}
                  <table
                    border={0}
                    cellPadding={0}
                    cellSpacing={0}
                    width="100%"
                    id="contentTableOuter"
                    style={{
                      WebkitTextSizeAdjust: "100%",
                      msTextSizeAdjust: "100%",
                      msoTableLspace: "0pt",
                      msoTableRspace: "0pt",
                      borderCollapse: "separate !important",
                      backgroundColor: "#ffffff"
                    }}
                    bgcolor="#ffffff"
                  >
                    <tbody>
                      <tr>
                        <td
                          align="center"
                          valign="top"
                          style={{
                            WebkitTextSizeAdjust: "100%",
                            msTextSizeAdjust: "100%",
                            msoTableLspace: "0pt",
                            msoTableRspace: "0pt"
                          }}
                        >
                          <div className="body-container-wrapper"></div>
                          <table
                            border={0}
                            cellPadding={0}
                            cellSpacing={0}
                            id="contentTableInner"
                            style={{
                              WebkitTextSizeAdjust: "100%",
                              msTextSizeAdjust: "100%",
                              msoTableLspace: "0pt",
                              msoTableRspace: "0pt",
                              borderCollapse: "collapse !important",
                              width: 600
                            }}
                            width={600}
                          >
                            <tbody>
                              <tr>
                                <td
                                  align="left"
                                  valign="top"
                                  className="bodyContent"
                                  width="100%"
                                  colSpan={12}
                                  style={{
                                    WebkitTextSizeAdjust: "100%",
                                    msTextSizeAdjust: "100%",
                                    msoTableLspace: "0pt",
                                    msoTableRspace: "0pt",
                                    color: "#000000",
                                    fontFamily:
                                      '"Helvetica Neue", Helvetica, Arial, sans-serif',
                                    fontSize: 16,
                                    lineHeight: "150%",
                                    textAlign: "left"
                                  }}
                                >
                                  <table
                                    cellPadding={0}
                                    cellSpacing={0}
                                    border={0}
                                    width="100%"
                                    className="templateColumnWrapper"
                                    style={{
                                      WebkitTextSizeAdjust: "100%",
                                      msTextSizeAdjust: "100%",
                                      msoTableLspace: "0pt",
                                      msoTableRspace: "0pt",
                                      borderCollapse: "collapse !important"
                                    }}
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          valign="top"
                                          colSpan={12}
                                          width="100.0%"
                                          className=" column"
                                          style={{
                                            WebkitTextSizeAdjust: "100%",
                                            msTextSizeAdjust: "100%",
                                            msoTableLspace: "0pt",
                                            msoTableRspace: "0pt",
                                            width: "100.0%",
                                            textAlign: "left",
                                            padding: 0,
                                            fontFamily:
                                              '"Helvetica Neue", Helvetica, Arial, sans-serif',
                                            fontSize: 16,
                                            lineHeight: "1.5em",
                                            color: "#000000"
                                          }}
                                          align="left"
                                        >
                                          <div
                                            className="widget-span widget-type-custom_widget "
                                            style={{}}
                                            data-widget-type="custom_widget"
                                          >
                                            <div className="layout-widget-wrapper">
                                              <div
                                                id="hs_cos_wrapper_module_150964864725218"
                                                className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_custom_widget"
                                                style={{
                                                  color: "inherit",
                                                  fontSize: "inherit",
                                                  lineHeight: "inherit"
                                                }}
                                                data-hs-cos-general-type="widget"
                                                data-hs-cos-type="custom_widget"
                                              >
                                                <table
                                                  style={{
                                                    WebkitTextSizeAdjust:
                                                      "100%",
                                                    msTextSizeAdjust: "100%",
                                                    msoTableLspace: "0pt",
                                                    msoTableRspace: "0pt",
                                                    borderCollapse:
                                                      "collapse !important",
                                                    backgroundColor: "#b40006",
                                                    width: "100%"
                                                  }}
                                                  bgcolor="#b40006"
                                                >
                                                  <tbody>
                                                    <tr
                                                      className="cpi-template-header"
                                                      style={{ minHeight: 70 }}
                                                    >
                                                      <td
                                                        className="cpi-template-logo"
                                                        style={{
                                                          WebkitTextSizeAdjust:
                                                            "100%",
                                                          msTextSizeAdjust:
                                                            "100%",
                                                          msoTableLspace: "0pt",
                                                          msoTableRspace: "0pt",
                                                          width: 70,
                                                          height: 70,
                                                          minHeight: 70,
                                                          padding:
                                                            "10px 0 10px 15px"
                                                        }}
                                                        width={70}
                                                        height={70}
                                                      >
                                                        <div
                                                          id="hs_cos_wrapper_module_150964864725218_logo_image"
                                                          className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_logo"
                                                          style={{
                                                            color: "inherit",
                                                            fontSize: "inherit",
                                                            lineHeight:
                                                              "inherit"
                                                          }}
                                                          data-hs-cos-general-type="widget"
                                                          data-hs-cos-type="logo"
                                                        >
                                                          <a
                                                            href="http://www.cpisecurity.com/?utm_source=hs_email&utm_medium=email&_hsenc=p2ANqtz---jjhvdVMBSeVN_OE_6qybBesNHDJQiWlJY41EAmVv_68P3eiiRAHI6dCngdsBGXfUwD3ZRNTPr051x3BfEnzYk0BI1w"
                                                            id="hs-link-module_150964864725218_logo_image"
                                                            style={{
                                                              WebkitTextSizeAdjust:
                                                                "100%",
                                                              msTextSizeAdjust:
                                                                "100%",
                                                              borderWidth: 0,
                                                              border: 0
                                                            }}
                                                            data-hs-link-id={0}
                                                            target="_blank"
                                                          >
                                                            <img
                                                              src="https://utho.com/images/logo_white.png"
                                                              className="hs-image-widget "
                                                              style={{
                                                                verticalAlign:
                                                                  "bottom",
                                                                msInterpolationMode:
                                                                  "bicubic",
                                                                maxHeight: 70,
                                                                borderWidth: 0,
                                                                border: 0,
                                                                width: 70,
                                                                maxWidth: 70
                                                              }}
                                                              width={70} 
                                                            />
                                                          </a>
                                                        </div>
                                                      </td>
                                                      <td
                                                        className="cpi-template-title"
                                                        style={{
                                                          WebkitTextSizeAdjust:
                                                            "100%",
                                                          msTextSizeAdjust:
                                                            "100%",
                                                          msoTableLspace: "0pt",
                                                          msoTableRspace: "0pt",
                                                          padding:
                                                            "10px 20px 10px 0",
                                                          color: "#fff",
                                                          textAlign: "right"
                                                        }}
                                                        align="right"
                                                      >
                                                        <div
                                                          id="hs_cos_wrapper_module_150964864725218_simple_text_field"
                                                          className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text"
                                                          style={{
                                                            color: "inherit",
                                                            fontSize: "inherit",
                                                            lineHeight:
                                                              "inherit"
                                                          }}
                                                          data-hs-cos-general-type="widget"
                                                          data-hs-cos-type="text"
                                                        >
                                                          Appointment
                                                          Confirmation
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                            {/*end layout-widget-wrapper */}
                                          </div>
                                          {/*end widget-span */}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  align="left"
                                  valign="top"
                                  className="bodyContent"
                                  width="100%"
                                  colSpan={12}
                                  style={{
                                    WebkitTextSizeAdjust: "100%",
                                    msTextSizeAdjust: "100%",
                                    msoTableLspace: "0pt",
                                    msoTableRspace: "0pt",
                                    color: "#000000",
                                    fontFamily:
                                      '"Helvetica Neue", Helvetica, Arial, sans-serif',
                                    fontSize: 16,
                                    lineHeight: "150%",
                                    textAlign: "left"
                                  }}
                                >
                                  <table
                                    cellPadding={0}
                                    cellSpacing={0}
                                    border={0}
                                    width="100%"
                                    className="templateColumnWrapper"
                                    style={{
                                      WebkitTextSizeAdjust: "100%",
                                      msTextSizeAdjust: "100%",
                                      msoTableLspace: "0pt",
                                      msoTableRspace: "0pt",
                                      borderCollapse: "collapse !important"
                                    }}
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          valign="top"
                                          colSpan={12}
                                          width="100.0%"
                                          className=" column"
                                          style={{
                                            WebkitTextSizeAdjust: "100%",
                                            msTextSizeAdjust: "100%",
                                            msoTableLspace: "0pt",
                                            msoTableRspace: "0pt",
                                            width: "100.0%",
                                            textAlign: "left",
                                            padding: 0,
                                            fontFamily:
                                              '"Helvetica Neue", Helvetica, Arial, sans-serif',
                                            fontSize: 16,
                                            lineHeight: "1.5em",
                                            color: "#000000"
                                          }}
                                          align="left"
                                        >
                                          <table
                                            cellPadding={0}
                                            cellSpacing={0}
                                            border={0}
                                            width="100%"
                                            style={{
                                              WebkitTextSizeAdjust: "100%",
                                              msTextSizeAdjust: "100%",
                                              msoTableLspace: "0pt",
                                              msoTableRspace: "0pt",
                                              borderCollapse:
                                                "collapse !important"
                                            }}
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  align="left"
                                                  valign="top"
                                                  className="bodyContent"
                                                  width="100%"
                                                  colSpan={12}
                                                  style={{
                                                    WebkitTextSizeAdjust:
                                                      "100%",
                                                    msTextSizeAdjust: "100%",
                                                    msoTableLspace: "0pt",
                                                    msoTableRspace: "0pt",
                                                    color: "#000000",
                                                    fontFamily:
                                                      '"Helvetica Neue", Helvetica, Arial, sans-serif',
                                                    fontSize: 16,
                                                    lineHeight: "150%",
                                                    textAlign: "left"
                                                  }}
                                                >
                                                  <table
                                                    cellPadding={0}
                                                    cellSpacing={0}
                                                    border={0}
                                                    width="100%"
                                                    className="templateColumnWrapper"
                                                    style={{
                                                      WebkitTextSizeAdjust:
                                                        "100%",
                                                      msTextSizeAdjust: "100%",
                                                      msoTableLspace: "0pt",
                                                      msoTableRspace: "0pt",
                                                      borderCollapse:
                                                        "collapse !important"
                                                    }}
                                                  >
                                                    <tbody>
                                                      <tr>
                                                        <td
                                                          valign="top"
                                                          colSpan={12}
                                                          width="100.0%"
                                                          className=" column"
                                                          style={{
                                                            WebkitTextSizeAdjust:
                                                              "100%",
                                                            msTextSizeAdjust:
                                                              "100%",
                                                            msoTableLspace:
                                                              "0pt",
                                                            msoTableRspace:
                                                              "0pt",
                                                            width: "100.0%",
                                                            textAlign: "left",
                                                            padding: 0,
                                                            fontFamily:
                                                              '"Helvetica Neue", Helvetica, Arial, sans-serif',
                                                            fontSize: 16,
                                                            lineHeight: "1.5em",
                                                            color: "#000000"
                                                          }}
                                                          align="left"
                                                        >
                                                          <div
                                                            className="widget-span widget-type-raw_jinja "
                                                            style={{}}
                                                            data-widget-type="raw_jinja"
                                                          >
                                                            <div
                                                              id="hs_cos_wrapper_right_column"
                                                              className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text"
                                                              style={{
                                                                color:
                                                                  "inherit",
                                                                fontSize:
                                                                  "inherit",
                                                                lineHeight:
                                                                  "inherit"
                                                              }}
                                                              data-hs-cos-general-type="widget"
                                                              data-hs-cos-type="rich_text"
                                                            >
                                                              <div className="cpi-content-inner">
                                                                <table
                                                                  style={{
                                                                    WebkitTextSizeAdjust:
                                                                      "100%",
                                                                    msTextSizeAdjust:
                                                                      "100%",
                                                                    msoTableLspace:
                                                                      "0pt",
                                                                    msoTableRspace:
                                                                      "0pt",
                                                                    borderCollapse:
                                                                      "collapse !important"
                                                                  }}
                                                                >
                                                                  <tbody>
                                                                    <tr>
                                                                      <td
                                                                        style={{
                                                                          WebkitTextSizeAdjust:
                                                                            "100%",
                                                                          msTextSizeAdjust:
                                                                            "100%",
                                                                          msoTableLspace:
                                                                            "0pt",
                                                                          msoTableRspace:
                                                                            "0pt",
                                                                          width: 12,
                                                                          height: 25
                                                                        }}
                                                                        width={
                                                                          12
                                                                        }
                                                                        height={
                                                                          25
                                                                        }
                                                                      >
                                                                        &nbsp;
                                                                      </td>
                                                                      <td
                                                                        style={{
                                                                          WebkitTextSizeAdjust:
                                                                            "100%",
                                                                          msTextSizeAdjust:
                                                                            "100%",
                                                                          msoTableLspace:
                                                                            "0pt",
                                                                          msoTableRspace:
                                                                            "0pt",
                                                                          width: 12,
                                                                          height: 25
                                                                        }}
                                                                        width={
                                                                          12
                                                                        }
                                                                        height={
                                                                          25
                                                                        }
                                                                      >
                                                                        &nbsp;
                                                                      </td>
                                                                    </tr>
                                                                  </tbody>
                                                                </table>
                                                                <table
                                                                  style={{
                                                                    WebkitTextSizeAdjust:
                                                                      "100%",
                                                                    msTextSizeAdjust:
                                                                      "100%",
                                                                    msoTableLspace:
                                                                      "0pt",
                                                                    msoTableRspace:
                                                                      "0pt",
                                                                    borderCollapse:
                                                                      "collapse !important"
                                                                  }}
                                                                >
                                                                  <tbody>
                                                                    <tr>
                                                                      <td
                                                                        style={{
                                                                          WebkitTextSizeAdjust:
                                                                            "100%",
                                                                          msTextSizeAdjust:
                                                                            "100%",
                                                                          msoTableLspace:
                                                                            "0pt",
                                                                          msoTableRspace:
                                                                            "0pt",
                                                                          padding:
                                                                            "10px 25px"
                                                                        }}
                                                                      >
                                                                        <h2
                                                                          style={{
                                                                            display:
                                                                              "block",
                                                                            fontWeight:
                                                                              "bold",
                                                                            lineHeight:
                                                                              "100%",
                                                                            marginTop: 0,
                                                                            marginRight: 0,
                                                                            marginBottom: 10,
                                                                            marginLeft: 0,
                                                                            fontSize: 20
                                                                          }}
                                                                        >
                                                                          Something
                                                                          Powerful
                                                                        </h2>
                                                                        <h3
                                                                          style={{
                                                                            display:
                                                                              "block",
                                                                            fontWeight:
                                                                              "bold",
                                                                            lineHeight:
                                                                              "100%",
                                                                            marginTop: 0,
                                                                            marginRight: 0,
                                                                            marginBottom: 10,
                                                                            marginLeft: 0,
                                                                            fontSize: 15
                                                                          }}
                                                                        >
                                                                          Tell
                                                                          The
                                                                          Reader
                                                                          More
                                                                        </h3>
                                                                        <p
                                                                          style={{
                                                                            marginBottom:
                                                                              "1em",
                                                                            WebkitTextSizeAdjust:
                                                                              "100%",
                                                                            msTextSizeAdjust:
                                                                              "100%"
                                                                          }}
                                                                        >
                                                                          The
                                                                          headline
                                                                          and
                                                                          subheader
                                                                          tells
                                                                          us
                                                                          what
                                                                          you're
                                                                          offering,
                                                                          and
                                                                          the
                                                                          form
                                                                          header
                                                                          closes
                                                                          the
                                                                          deal.
                                                                          Over
                                                                          here
                                                                          you
                                                                          can
                                                                          explain
                                                                          why
                                                                          your
                                                                          offer
                                                                          is so
                                                                          great
                                                                          it's
                                                                          worth
                                                                          filling
                                                                          out a
                                                                          form
                                                                          for.
                                                                        </p>
                                                                        <p
                                                                          style={{
                                                                            marginBottom:
                                                                              "1em",
                                                                            WebkitTextSizeAdjust:
                                                                              "100%",
                                                                            msTextSizeAdjust:
                                                                              "100%"
                                                                          }}
                                                                        >
                                                                          Remember:
                                                                        </p>
                                                                        <ul>
                                                                          <li
                                                                            style={{
                                                                              WebkitTextSizeAdjust:
                                                                                "100%",
                                                                              msTextSizeAdjust:
                                                                                "100%"
                                                                            }}
                                                                          >
                                                                            Bullets
                                                                            are
                                                                            great
                                                                          </li>
                                                                          <li
                                                                            style={{
                                                                              WebkitTextSizeAdjust:
                                                                                "100%",
                                                                              msTextSizeAdjust:
                                                                                "100%"
                                                                            }}
                                                                          >
                                                                            For
                                                                            spelling
                                                                            out{" "}
                                                                            <a
                                                                              href="#"
                                                                              style={{
                                                                                WebkitTextSizeAdjust:
                                                                                  "100%",
                                                                                msTextSizeAdjust:
                                                                                  "100%"
                                                                              }}
                                                                              data-hs-link-id={
                                                                                0
                                                                              }
                                                                              target="_blank"
                                                                            >
                                                                              benefits
                                                                            </a>{" "}
                                                                            and
                                                                          </li>
                                                                          <li
                                                                            style={{
                                                                              WebkitTextSizeAdjust:
                                                                                "100%",
                                                                              msTextSizeAdjust:
                                                                                "100%"
                                                                            }}
                                                                          >
                                                                            Turning
                                                                            visitors
                                                                            into
                                                                            leads.
                                                                          </li>
                                                                        </ul>
                                                                      </td>
                                                                    </tr>
                                                                  </tbody>
                                                                </table>
                                                                <table
                                                                  style={{
                                                                    WebkitTextSizeAdjust:
                                                                      "100%",
                                                                    msTextSizeAdjust:
                                                                      "100%",
                                                                    msoTableLspace:
                                                                      "0pt",
                                                                    msoTableRspace:
                                                                      "0pt",
                                                                    borderCollapse:
                                                                      "collapse !important"
                                                                  }}
                                                                >
                                                                  <tbody>
                                                                    <tr>
                                                                      <td
                                                                        style={{
                                                                          WebkitTextSizeAdjust:
                                                                            "100%",
                                                                          msTextSizeAdjust:
                                                                            "100%",
                                                                          msoTableLspace:
                                                                            "0pt",
                                                                          msoTableRspace:
                                                                            "0pt",
                                                                          width: 12,
                                                                          height: 25
                                                                        }}
                                                                        width={
                                                                          12
                                                                        }
                                                                        height={
                                                                          25
                                                                        }
                                                                      >
                                                                        &nbsp;
                                                                      </td>
                                                                      <td
                                                                        style={{
                                                                          WebkitTextSizeAdjust:
                                                                            "100%",
                                                                          msTextSizeAdjust:
                                                                            "100%",
                                                                          msoTableLspace:
                                                                            "0pt",
                                                                          msoTableRspace:
                                                                            "0pt",
                                                                          width: 12,
                                                                          height: 25
                                                                        }}
                                                                        width={
                                                                          12
                                                                        }
                                                                        height={
                                                                          25
                                                                        }
                                                                      >
                                                                        &nbsp;
                                                                      </td>
                                                                    </tr>
                                                                  </tbody>
                                                                </table>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          {/*end widget-span */}
                                                          {/*end widget-span */}
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  align="left"
                                  valign="top"
                                  className="bodyContent"
                                  width="100%"
                                  colSpan={12}
                                  style={{
                                    WebkitTextSizeAdjust: "100%",
                                    msTextSizeAdjust: "100%",
                                    msoTableLspace: "0pt",
                                    msoTableRspace: "0pt",
                                    color: "#000000",
                                    fontFamily:
                                      '"Helvetica Neue", Helvetica, Arial, sans-serif',
                                    fontSize: 16,
                                    lineHeight: "150%",
                                    textAlign: "left"
                                  }}
                                >
                                  <table
                                    cellPadding={0}
                                    cellSpacing={0}
                                    border={0}
                                    width="100%"
                                    className="templateColumnWrapper"
                                    style={{
                                      WebkitTextSizeAdjust: "100%",
                                      msTextSizeAdjust: "100%",
                                      msoTableLspace: "0pt",
                                      msoTableRspace: "0pt",
                                      borderCollapse: "collapse !important"
                                    }}
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          valign="top"
                                          colSpan={6}
                                          width="50.0%"
                                          className=" column"
                                          style={{
                                            WebkitTextSizeAdjust: "100%",
                                            msTextSizeAdjust: "100%",
                                            msoTableLspace: "0pt",
                                            msoTableRspace: "0pt",
                                            width: "50.0%",
                                            textAlign: "left",
                                            padding: 0,
                                            fontFamily:
                                              '"Helvetica Neue", Helvetica, Arial, sans-serif',
                                            fontSize: 16,
                                            lineHeight: "1.5em",
                                            color: "#000000"
                                          }}
                                          align="left"
                                        >
                                          <div
                                            className="widget-span widget-type-raw_jinja "
                                            style={{}}
                                            data-widget-type="raw_jinja"
                                          >
                                            <table
                                              style={{
                                                WebkitTextSizeAdjust: "100%",
                                                msTextSizeAdjust: "100%",
                                                msoTableLspace: "0pt",
                                                msoTableRspace: "0pt",
                                                borderCollapse:
                                                  "collapse !important",
                                                width: 300,
                                                backgroundColor: "#b40006"
                                              }}
                                              className="setFullWidth"
                                              cellPadding={0}
                                              cellSpacing={0}
                                              width={300}
                                              bgcolor="#b40006"
                                            >
                                              <tbody>
                                                <tr
                                                  className="cpi-template-footer"
                                                  style={{
                                                    backgroundColor: "#b40006"
                                                  }}
                                                >
                                                  <td
                                                    className="cpi-template-footer-callout-right"
                                                    style={{
                                                      WebkitTextSizeAdjust:
                                                        "100%",
                                                      msTextSizeAdjust: "100%",
                                                      msoTableLspace: "0pt",
                                                      msoTableRspace: "0pt",
                                                      padding: "10px 0"
                                                    }}
                                                  >
                                                    <div
                                                      id="hs_cos_wrapper_left_column_phone"
                                                      className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text"
                                                      style={{
                                                        color: "inherit",
                                                        fontSize: "inherit",
                                                        lineHeight: "inherit"
                                                      }}
                                                      data-hs-cos-general-type="widget"
                                                      data-hs-cos-type="rich_text"
                                                    >
                                                      <div
                                                        style={{
                                                          textAlign: "center"
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color: "#ffffff"
                                                          }}
                                                        >
                                                          <strong>
                                                            <img
                                                              src="https://utho.com/assets/svg/icons/icon-16.svg"
                                                              width={15}
                                                              caption="false"
                                                              data-constrained="true"
                                                              style={{
                                                                msInterpolationMode:
                                                                  "bicubic",
                                                                width:
                                                                  "15px !important",
                                                                verticalAlign:
                                                                  "middle"
                                                              }}
                                                            />{" "}
                                                            <a
                                                              href="tel:+18558884716"
                                                              style={{
                                                                WebkitTextSizeAdjust:
                                                                  "100%",
                                                                msTextSizeAdjust:
                                                                  "100%",
                                                                color: "#ffffff"
                                                              }}
                                                              data-hs-link-id={
                                                                0
                                                              }
                                                              target="_blank"
                                                            >
                                                              855-888-4716
                                                            </a>
                                                          </strong>
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                          {/*end widget-span */}
                                          {/*end widget-span */}
                                        </td>
                                        <td
                                          valign="top"
                                          colSpan={6}
                                          width="50.0%"
                                          className=" column"
                                          style={{
                                            WebkitTextSizeAdjust: "100%",
                                            msTextSizeAdjust: "100%",
                                            msoTableLspace: "0pt",
                                            msoTableRspace: "0pt",
                                            width: "50.0%",
                                            textAlign: "left",
                                            padding: 0,
                                            fontFamily:
                                              '"Helvetica Neue", Helvetica, Arial, sans-serif',
                                            fontSize: 16,
                                            lineHeight: "1.5em",
                                            color: "#000000"
                                          }}
                                          align="left"
                                        >
                                          <div
                                            className="widget-span widget-type-raw_jinja "
                                            style={{}}
                                            data-widget-type="raw_jinja"
                                          >
                                            <table
                                              style={{
                                                WebkitTextSizeAdjust: "100%",
                                                msTextSizeAdjust: "100%",
                                                msoTableLspace: "0pt",
                                                msoTableRspace: "0pt",
                                                borderCollapse:
                                                  "collapse !important",
                                                width: 300,
                                                backgroundColor: "#b40006"
                                              }}
                                              className="setFullWidth"
                                              cellPadding={0}
                                              cellSpacing={0}
                                              width={300}
                                              bgcolor="#b40006"
                                            >
                                              <tbody>
                                                <tr
                                                  className="cpi-template-footer"
                                                  style={{
                                                    backgroundColor: "#b40006"
                                                  }}
                                                >
                                                  <td
                                                    className="cpi-template-footer-callout-right"
                                                    style={{
                                                      WebkitTextSizeAdjust:
                                                        "100%",
                                                      msTextSizeAdjust: "100%",
                                                      msoTableLspace: "0pt",
                                                      msoTableRspace: "0pt",
                                                      padding: "10px 0"
                                                    }}
                                                  >
                                                    <div
                                                      id="hs_cos_wrapper_right_column_url"
                                                      className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text"
                                                      style={{
                                                        color: "inherit",
                                                        fontSize: "inherit",
                                                        lineHeight: "inherit"
                                                      }}
                                                      data-hs-cos-general-type="widget"
                                                      data-hs-cos-type="rich_text"
                                                    >
                                                      <div
                                                        style={{
                                                          textAlign: "center"
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color: "#ffffff"
                                                          }}
                                                        >
                                                          <img
                                                            src="https://cdn2.hubspot.net/hub/3112098/hubfs/world-wide-web.png?t=1532619883109&width=15&name=world-wide-web.png"
                                                            width={15}
                                                            caption="false"
                                                            data-constrained="true"
                                                            style={{
                                                              msInterpolationMode:
                                                                "bicubic",
                                                              width:
                                                                "15px !important",
                                                              verticalAlign:
                                                                "middle"
                                                            }}
                                                          />{" "}
                                                          <a
                                                            href="https://cpisecurity.com/?utm_source=hs_email&utm_medium=email&_hsenc=p2ANqtz---jjhvdVMBSeVN_OE_6qybBesNHDJQiWlJY41EAmVv_68P3eiiRAHI6dCngdsBGXfUwD3ZRNTPr051x3BfEnzYk0BI1w"
                                                            style={{
                                                              WebkitTextSizeAdjust:
                                                                "100%",
                                                              msTextSizeAdjust:
                                                                "100%",
                                                              color: "#ffffff"
                                                            }}
                                                            data-hs-link-id={0}
                                                            target="_blank"
                                                          >
                                                            <strong>
                                                              cpisecurity.com
                                                            </strong>
                                                          </a>
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>{" "}
                                          </div>
                                          {/*end widget-span */}
                                          {/*end widget-span */}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr> 
                              {/*end body wrapper */}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {/* End Template Wrapper */}
                </td>
              </tr>
              <tr>
                <td
                  align="center"
                  valign="top"
                  style={{
                    WebkitTextSizeAdjust: "100%",
                    msTextSizeAdjust: "100%",
                    msoTableLspace: "0pt",
                    msoTableRspace: "0pt"
                  }}
                >
                  {/* Begin Template Footer */}
                  <div className="footer-container-wrapper"></div>
                  <table
                    border={0}
                    cellPadding={0}
                    cellSpacing={0}
                    width="100%"
                    id="footerTable"
                    style={{
                      WebkitTextSizeAdjust: "100%",
                      msTextSizeAdjust: "100%",
                      msoTableLspace: "0pt",
                      msoTableRspace: "0pt",
                      borderCollapse: "collapse !important",
                      backgroundColor: "#ebebeb",
                      color: "#4a4a4a",
                      fontFamily:
                        '"Helvetica Neue", Helvetica, Arial, sans-serif',
                      fontSize: 12,
                      lineHeight: "120%",
                      paddingTop: 20,
                      paddingRight: 20,
                      paddingBottom: 20,
                      paddingLeft: 20,
                      textAlign: "center"
                    }}
                    bgcolor="#ebebeb"
                    align="center"
                  >
                    {/*end footer wrapper */}
                    <tbody>
                      <tr>
                        <td
                          style={{
                            WebkitTextSizeAdjust: "100%",
                            msTextSizeAdjust: "100%",
                            msoTableLspace: "0pt",
                            msoTableRspace: "0pt"
                          }}
                        />
                      </tr>
                    </tbody>
                  </table>
                  {/* End Template Footer */}
                </td>
              </tr>
            </tbody>
          </table>
          {/* End Template Container */}
        </td>
      </tr>
    </tbody>
  </table>
</>

    )
}