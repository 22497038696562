import {React} from  "react";
import Template from "../../templates/Template";

export default function EmailTemplates(){
    return (<>
    <div className="row">
      <div className="col-6">
      <div className="card mb-5 mb-xl-10">
  {/*begin::Card header*/}
  <div
    className="card-header border-0 cursor-pointer"
    role="button"
    data-bs-toggle="collapse"
    data-bs-target="#kt_account_profile_details"
    aria-expanded="true"
    aria-controls="kt_account_profile_details"
  >
    {/*begin::Card title*/}
    <div className="card-title m-0">
      <h3 className="fw-bolder m-0">Profile Details</h3>
    </div>
    {/*end::Card title*/}
  </div>
  {/*begin::Card header*/}
  {/*begin::Content*/}
  <div id="kt_account_settings_profile_details" className="collapse show">
    {/*begin::Form*/}
    <form id="kt_account_profile_details_form" className="form">
      {/*begin::Card body*/}
      <div className="card-body border-top p-9">
        {/*begin::Input group*/}
        <div className="row mb-6">
          {/*begin::Label*/}
          <label className="col-lg-4 col-form-label fw-bold fs-6">Avatar</label>
          {/*end::Label*/}
          {/*begin::Col*/}
          <div className="col-lg-8">
            {/*begin::Image input*/}
            <div
              className="image-input image-input-outline"
              data-kt-image-input="true"
              style={{
                backgroundImage: 'url("assets/media/svg/avatars/blank.svg")'
              }}
            >
              {/*begin::Preview existing avatar*/}
              <div
                className="image-input-wrapper w-125px h-125px"
                style={{
                  backgroundImage: "url(assets/media/avatars/300-1.jpg)"
                }}
              />
              {/*end::Preview existing avatar*/}
              {/*begin::Label*/}
              <label
                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                data-kt-image-input-action="change"
                data-bs-toggle="tooltip"
                title="Change avatar"
              >
                <i className="bi bi-pencil-fill fs-7" />
                {/*begin::Inputs*/}
                <input type="file" name="avatar" accept=".png, .jpg, .jpeg" />
                <input type="hidden" name="avatar_remove" />
                {/*end::Inputs*/}
              </label>
              {/*end::Label*/}
              {/*begin::Cancel*/}
              <span
                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                data-kt-image-input-action="cancel"
                data-bs-toggle="tooltip"
                title="Cancel avatar"
              >
                <i className="bi bi-x fs-2" />
              </span>
              {/*end::Cancel*/}
              {/*begin::Remove*/}
              <span
                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                data-kt-image-input-action="remove"
                data-bs-toggle="tooltip"
                title="Remove avatar"
              >
                <i className="bi bi-x fs-2" />
              </span>
              {/*end::Remove*/}
            </div>
            {/*end::Image input*/}
            {/*begin::Hint*/}
            <div className="form-text">Allowed file types: png, jpg, jpeg.</div>
            {/*end::Hint*/}
          </div>
          {/*end::Col*/}
        </div>
        {/*end::Input group*/}
        {/*begin::Input group*/}
        <div className="row mb-6">
          {/*begin::Label*/}
          <label className="col-lg-4 col-form-label required fw-bold fs-6">
            Full Name
          </label>
          {/*end::Label*/}
          {/*begin::Col*/}
          <div className="col-lg-8">
            {/*begin::Row*/}
            <div className="row">
              {/*begin::Col*/}
              <div className="col-lg-6 fv-row">
                <input
                  type="text"
                  name="fname"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="First name"
                  defaultValue="Max"
                />
              </div>
              {/*end::Col*/}
              {/*begin::Col*/}
              <div className="col-lg-6 fv-row">
                <input
                  type="text"
                  name="lname"
                  className="form-control form-control-lg form-control-solid"
                  placeholder="Last name"
                  defaultValue="Smith"
                />
              </div>
              {/*end::Col*/}
            </div>
            {/*end::Row*/}
          </div>
          {/*end::Col*/}
        </div>
        {/*end::Input group*/}
        {/*begin::Input group*/}
        <div className="row mb-6">
          {/*begin::Label*/}
          <label className="col-lg-4 col-form-label required fw-bold fs-6">
            Company
          </label>
          {/*end::Label*/}
          {/*begin::Col*/}
          <div className="col-lg-8 fv-row">
            <input
              type="text"
              name="company"
              className="form-control form-control-lg form-control-solid"
              placeholder="Company name"
              defaultValue="Keenthemes"
            />
          </div>
          {/*end::Col*/}
        </div>
        {/*end::Input group*/}
        {/*begin::Input group*/}
        <div className="row mb-6">
          {/*begin::Label*/}
          <label className="col-lg-4 col-form-label fw-bold fs-6">
            <span className="required">Contact Phone</span>
            <i
              className="fas fa-exclamation-circle ms-1 fs-7"
              data-bs-toggle="tooltip"
              title="Phone number must be active"
            />
          </label>
          {/*end::Label*/}
          {/*begin::Col*/}
          <div className="col-lg-8 fv-row">
            <input
              type="tel"
              name="phone"
              className="form-control form-control-lg form-control-solid"
              placeholder="Phone number"
              defaultValue="044 3276 454 935"
            />
          </div>
          {/*end::Col*/}
        </div>
        {/*end::Input group*/}
        {/*begin::Input group*/}
        <div className="row mb-6">
          {/*begin::Label*/}
          <label className="col-lg-4 col-form-label fw-bold fs-6">
            Company Site
          </label>
          {/*end::Label*/}
          {/*begin::Col*/}
          <div className="col-lg-8 fv-row">
            <input
              type="text"
              name="website"
              className="form-control form-control-lg form-control-solid"
              placeholder="Company website"
              defaultValue="keenthemes.com"
            />
          </div>
          {/*end::Col*/}
        </div>
        {/*end::Input group*/}
         
       
        {/*begin::Input group*/}
        <div className="row mb-6">
          {/*begin::Label*/}
          <label className="col-lg-4 col-form-label fw-bold fs-6">
            Currency
          </label>
          {/*end::Label*/}
          {/*begin::Col*/}
          <div className="col-lg-8 fv-row">
            <select
              name="currnecy"
              aria-label="Select a Currency"
              data-control="select2"
              data-placeholder="Select a currency.."
              className="form-select form-select-solid form-select-lg"
            >
              <option value="">Select a currency..</option>
              <option data-kt-flag="flags/united-states.svg" value="USD">
                USD&nbsp;-&nbsp;USA dollar
              </option>
              <option data-kt-flag="flags/united-kingdom.svg" value="GBP">
                GBP&nbsp;-&nbsp;British pound
              </option>
              <option data-kt-flag="flags/australia.svg" value="AUD">
                AUD&nbsp;-&nbsp;Australian dollar
              </option>
              <option data-kt-flag="flags/japan.svg" value="JPY">
                JPY&nbsp;-&nbsp;Japanese yen
              </option>
              <option data-kt-flag="flags/sweden.svg" value="SEK">
                SEK&nbsp;-&nbsp;Swedish krona
              </option>
              <option data-kt-flag="flags/canada.svg" value="CAD">
                CAD&nbsp;-&nbsp;Canadian dollar
              </option>
              <option data-kt-flag="flags/switzerland.svg" value="CHF">
                CHF&nbsp;-&nbsp;Swiss franc
              </option>
            </select>
          </div>
          {/*end::Col*/}
        </div>
        {/*end::Input group*/}
        {/*begin::Input group*/}
        <div className="row mb-6">
          {/*begin::Label*/}
          <label className="col-lg-4 col-form-label required fw-bold fs-6">
            Communication
          </label>
          {/*end::Label*/}
          {/*begin::Col*/}
          <div className="col-lg-8 fv-row">
            {/*begin::Options*/}
            <div className="d-flex align-items-center mt-3">
              {/*begin::Option*/}
              <label className="form-check form-check-inline form-check-solid me-5">
                <input
                  className="form-check-input"
                  name="communication[]"
                  type="checkbox"
                  defaultValue={1}
                />
                <span className="fw-bold ps-2 fs-6">Email</span>
              </label>
              {/*end::Option*/}
              {/*begin::Option*/}
              <label className="form-check form-check-inline form-check-solid">
                <input
                  className="form-check-input"
                  name="communication[]"
                  type="checkbox"
                  defaultValue={2}
                />
                <span className="fw-bold ps-2 fs-6">Phone</span>
              </label>
              {/*end::Option*/}
            </div>
            {/*end::Options*/}
          </div>
          {/*end::Col*/}
        </div>
        {/*end::Input group*/}
        {/*begin::Input group*/}
        <div className="row mb-0">
          {/*begin::Label*/}
          <label className="col-lg-4 col-form-label fw-bold fs-6">
            Allow Marketing
          </label>
          {/*begin::Label*/}
          {/*begin::Label*/}
          <div className="col-lg-8 d-flex align-items-center">
            <div className="form-check form-check-solid form-switch fv-row">
              <input
                className="form-check-input w-45px h-30px"
                type="checkbox"
                id="allowmarketing"
                defaultChecked="checked"
              />
              <label className="form-check-label" htmlFor="allowmarketing" />
            </div>
          </div>
          {/*begin::Label*/}
        </div>
        {/*end::Input group*/}
      </div>
      {/*end::Card body*/}
      {/*begin::Actions*/}
      <div className="card-footer d-flex justify-content-end py-6 px-9">
        <button
          type="reset"
          className="btn btn-light btn-active-light-primary me-2"
        >
          Discard   
        </button>
        <button
          type="submit"
          className="btn btn-primary"
          id="kt_account_profile_details_submit"
        >
          Save Changes
        </button>
      </div>
      {/*end::Actions*/}
    </form>
    {/*end::Form*/}
  </div>
  {/*end::Content*/}
</div>

      </div>
      <div className="col-6"> 
      <div className="card mb-5 mb-xl-10">
  {/*begin::Card header*/}
  <div
    className="card-header border-0 cursor-pointer"
    role="button"
    data-bs-toggle="collapse"
    data-bs-target="#kt_account_email_preferences"
    aria-expanded="true"
    aria-controls="kt_account_email_preferences"
  >
    <div className="card-title m-0">
      <h3 className="fw-bolder m-0">Email Preview</h3>
    </div>
  </div>
  {/*begin::Card header*/}
  {/*begin::Content*/}
  <div id="kt_account_settings_email_preferences" className="collapse show">
    {/*begin::Card body*/}
    <div className="card-body border-top px-9 py-9"> 
     <Template />
  </div>
  {/*end::Content*/}
</div> 
</div>
    {/*end::Card body*/}
  
      </div>
    </div>
    </>)
}