import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

function Search(props) {
    const { results, onSearch, searchPlaceholder, fields} = props; 
    
    useEffect(() => {
      if (results===undefined) throw new Error("Missing prop: results");
      if (onSearch===undefined) throw new Error("Missing prop: onSearch");
      if (fields===undefined) throw new Error("Missing prop: onSearch");
      if (searchPlaceholder===undefined) throw new Error("Missing prop: searchPlaceholder");
    }, [results, onSearch, searchPlaceholder]);

    const handleSearch = (newQuery) => {
      // Convert the query to lowercase for case-insensitive comparison
      const lowerCaseQuery = newQuery.toLowerCase();
    
      // Filter the results based on the fields and query
      const filteredResults = results.filter(item => {
        return fields.some(field => {
          // Ensure the field exists in the item and includes the query
          return item[field] && item[field].toLowerCase().includes(lowerCaseQuery);
        });
      });
    
      // Call the onSearch function with the filtered results
      onSearch(filteredResults);
    };

    // const handleSearch = (newQuery) => { 
    //   // Simulate search by filtering the items array
    //   console.log(results);
    //   const filteredResults = results.filter(item =>
    //     item.city.toLowerCase().includes(newQuery.toLowerCase()) || item.country.toLowerCase().includes(newQuery.toLowerCase())
    //   );
    //   onSearch(filteredResults); 
    // };
    
  return (
    <>
  {/*begin::Main wrapper*/}
  <div
    id="kt_docs_search_handler_basic"
    data-kt-search-keypress="true"
    data-kt-search-min-length={2}
    data-kt-search-enter="true"
    data-kt-search-layout="inline"
  >
    {/*begin::Input Form*/}
    <div 
      className="w-100 position-relative" 
    > 
      {/*begin::Icon*/}
      {/*begin::Svg Icon | path: icons/duotune/general/gen021.svg*/}
      <span className="svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <rect
      opacity="0.5"
      x="17.0365"
      y="15.1223"
      width="8.15546"
      height={2}
      rx={1}
      transform="rotate(45 17.0365 15.1223)"
      fill="black"
    />
    <path
      d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
      fill="black"
    />
  </svg>
</span>

      {/*end::Icon*/}
      {/*begin::Input*/}
      <input
        type="text"
        className="form-control form-control-md px-15"
        defaultValue=""
        placeholder={"Search by " + searchPlaceholder}
        onChange={(e)=>handleSearch(e.target.value)}
       />
      {/*end::Input*/}
      {/*begin::Spinner*/}
      <span
        className="position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-5"
        data-kt-search-element="spinner"
      >
        <span className="spinner-border h-15px w-15px align-middle text-gray-400" />
      </span>
      {/*end::Spinner*/}
      {/*begin::Reset*/}
      <span
        className="btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 me-5 d-none"
        data-kt-search-element="clear"
      >
        {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
      </span>
      {/*end::Reset*/}
    </div>
    {/*end::Form*/} 
  </div>
  {/*end::Main wrapper*/}
</>

  )
}

Search.propTypes = {
    searchPlaceholder: PropTypes.string.isRequired,
    results: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.object),
      PropTypes.oneOf([null]),
    ]).isRequired,
    fields: PropTypes.arrayOf(PropTypes.object).isRequired,
    onSearch: PropTypes.func.isRequired,
  };

export default Search

