import React from 'react';
import "./custom.css";
const TableLoaderLight = () => {
    return (
        <>
         <div class='preloaderlight'>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
         </div>

        </>
    );
}

export default TableLoaderLight;
