/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-useless-concat */
import React, { useEffect } from 'react'
import TopBarHeaderLine from '../../../../../CustomHooks/TopBarHeaderLine'
import TableLoader from '../../../Loaders/TableLoader'
import { Link } from 'react-router-dom'
import copy from "copy-to-clipboard";
import UseApi from '../../../../../CustomHooks/useApi';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import swal from 'sweetalert';


export default function ProductInfo(props) {
    const count = 3
    const copyToClipboard = (id) => {
        var ele = document.querySelector("#ip_address_sec" + id);
        var eleText = document.querySelector("#ip_address" + id);
        if (ele) {
            var copyText = ele.innerHTML;
            var copyEle = eleText.innerHTML;
            copy(copyEle);
            ele.innerHTML = "Copied";
            ele.classList.remove("badge-primary");
            ele.classList.add("badge-success");
            setTimeout(() => {
                ele.classList.add("badge-primary");
                ele.classList.remove("badge-success");
                ele.innerHTML = copyText;
            }, 2000);
        }
    }

    const deleteGpu = async (id, fwid) => {
        toast.dismiss();
        const { value: isDelete } = await Swal.fire({
            title: 'Destroy GPU',
            html: `
            <div className="d-flex align-items-center flex-wrap"> 
            <div id="kt_clipboard_4" className="me-5 fw-bold text-gray-600">
              <p class="fs-5">Enter the GPU Count to Destroy:</p>
      
              <span class="fw-bold d-none" id="file_url" >${props.productdata.length} &nbsp;</span><span id="file_url_copy_btn" style="cursor:pointer" class="text-primary d-none"> ( copy ) </span> 
            </div>  
          </div>
         `,
            input: 'number',
            inputPlaceholder: 'GPU Count',
            showCancelButton: true,
            confirmButtonText: 'Destroy',
            cancelButtonText: 'Cancel',
            customClass: {
                confirmButton: 'delete-button bg-danger', // Add your custom class here
            },
        });
        if (isDelete <= props.productdata.length) {
            const dt = toast.loading("Please wait...");

            const toastConfig = {
                isLoading: false,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            };

            const submitBtn = document.querySelector(`#delete-fw-btn-${fwid}`);

            if (submitBtn) {
                const preContent = submitBtn.innerHTML;
                submitBtn.classList.add("disabled");
                submitBtn.innerHTML = '<span class="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
                try {
                    const responseData = await UseApi("delete", `cloud/${id}/gpu/${fwid}?confirm=` + isDelete, "");

                    if (responseData.status === "error") {
                        toast.update(dt, { render: responseData.message, type: "error", ...toastConfig });
                    } else if (responseData.status === "success") {
                        toast.update(dt, { render: responseData.message, type: "success", ...toastConfig });
                        props.getCloud();
                    }
                } catch (error) {
                    console.error("Error deleting GPU:", error);
                    toast.update(dt, { render: "An error occurred while deleting GPU", type: "error", ...toastConfig });
                } finally {
                    submitBtn.classList.remove("disabled");
                    submitBtn.innerHTML = preContent;
                }
            }
        } else {
            swal({
                title: "Invalid GPU Count",
                text: "Please confirm your enter digit less then GPU count!",
                icon: "warning",
                button: "OK",
            });
        }
    };

    useEffect(() => {
    }, [props.productdata])
    console.log(props);

    return (
        <>
            {props.productdata && Object.keys(props.productdata).length > 0 ? (
                props.productdata?.map((cloud, index) => (
                    <>
                        <tr>
                            {" "}
                            <td className="p-3 d-none">
                                {" "}
                                <div className="d-flex flex-column w-100 me-2" >
                                    {" "}
                                    <div className="d-flex flex-stack mb-2">
                                        {" "}
                                        <span className=" me-2 fs-7 fw-bold">{cloud.cloudid}</span>{" "}
                                    </div>{" "}
                                </div>{" "}
                            </td>{" "}
                            <td className="d-none">
                                {" "}
                                <div className="d-flex align-items-center sphide">
                                    {" "}
                                    <div className="symbol symbol-30px me-5">
                                        {" "}
                                        <img src={`/img/flag_${cloud.dclocation.dccc}.png`} />{" "}
                                    </div>{" "}
                                    <div className="d-flex justify-content-start flex-column">
                                        {" "}
                                        <div
                                            className="text-dark fw-bold text-hover-primary fs-6"
                                        >
                                            {cloud.dclocation.location}
                                        </div>{" "}
                                        <span className="text-muted fw-bold text-muted d-block fs-7">
                                            {cloud.dclocation.country}
                                        </span>{" "}
                                    </div>{" "}
                                </div>{" "}
                            </td>{" "}
                            <td>
                                <div className="d-flex align-items-center ps-10">
                                    {cloud.stack ? (<>
                                        <div className="symbol symbol-45px me-5 text-center">
                                            <img src={'/' + cloud.stack.logo_url} alt="" style={{ width: "45px", height: "45px", padding: "2px", background: "#ebebeb75" }} />
                                        </div>
                                    </>) : (<>
                                        <div className="symbol symbol-45px me-5  text-center pt-2" style={{ width: "45px", height: "45px", padding: "2px", background: "#ebebeb75" }}>
                                            <span className={`fonticon_${(cloud.image.distribution).replaceAll(" ", "")}`} />
                                        </div>
                                    </>)}
                                    <div className="d-flex justify-content-start flex-column">
                                        <Link to={`/cloud/${cloud.cloudid}`}>
                                            <div style={{ cursor: "pointer" }} className="text-dark fw-bolder text-hover-primary fs-6">
                                                {cloud.hostname}
                                            </div>
                                        </Link>
                                        <span className="text-muted fw-bold text-muted d-block fs-7">
                                            {cloud.cpu} vCPU /{(cloud.ram / 1024).toFixed(0)} GB / {cloud.disksize} GB Disk /<span className="w-10">
                                                {" "}
                                                <img src={`/img/flag_${cloud.dclocation.dccc}.png`} style={{ width: "7%" }} />{" "}
                                            </span> {cloud.dclocation.location}
                                        </span>
                                    </div>
                                </div>


                            </td>
                            <td className="d-none">
                                {cloud.stack ? (<>

                                    <div className="d-flex align-items-center">
                                        {" "}
                                        <div className="symbol symbol-45px me-2">
                                            <img src={'/' + cloud.stack.logo_url} className="" alt="" style={{ width: "45px", height: "36px", padding: "2px", background: "#ebebeb75" }} />
                                        </div>{" "}
                                        <div className="d-flex justify-content-start flex-column">
                                            {" "}
                                            <div
                                                className=" fw-bold text-hover-primary d-block fs-6"
                                            >
                                                {cloud.stack.title?.charAt(0).toUpperCase() + cloud.stack.title?.slice(1)}
                                            </div>{" "}
                                        </div>{" "}
                                    </div>
                                </>) : (<>
                                    <div className="d-flex align-items-center">
                                        {" "}
                                        <div className="symbol symbol-45px me-2 text-center" style={{ width: "45px", height: "36px", padding: "2px", background: "#ebebeb75" }}>
                                            {" "}
                                            <span className={"fonticon_" + cloud.image.distribution + ""} />{" "}
                                        </div>{" "}
                                        <div className="d-flex justify-content-start flex-column">
                                            {" "}
                                            <div
                                                className=" fw-bold text-hover-primary d-block fs-6"
                                            >
                                                {cloud.image.distribution.charAt(0).toUpperCase() + cloud.image.distribution.slice(1)}
                                            </div>{" "}
                                        </div>{" "}
                                    </div>
                                </>)}
                            </td>{" "}
                            <td className="text-center">
                                {" "}
                                <div className="d-flex flex-column w-100 me-2">
                                    {" "}
                                    <div className=" flex-stack mb-2">
                                        {" "}
                                        <span className="w-100 btn btn-active-primary btn-light-primary btn-sm sphide me-2 fs-7 fw-bold"
                                            htmlFor="IP Address"
                                            id={"ip_address_sec" + index}
                                            onClick={() => copyToClipboard(index)}
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="right"
                                            title="Click to copy IP Address"
                                        >
                                            <span id={"ip_address" + index}> {cloud.ip}</span>&nbsp;&nbsp;
                                            {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen054.svg*/}
                                            <span className="svg-icon svg-icon-light svg-icon-2 d-none">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={24}
                                                    height={24}
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                >
                                                    <path
                                                        opacity="0.5"
                                                        d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                                                        fill="black"
                                                    />
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                                                        fill="black"
                                                    />
                                                </svg>
                                            </span>
                                            {/*end::Svg Icon*/}
                                        </span>{" "}
                                    </div>{" "}
                                </div>{" "}
                            </td>{" "}
                            <td className='d-none'></td>
                            <td className='d-none'></td>
                            <td className="w-15 text-end">
                                {" "}
                                <div className=" flex-column w-100 me-2">
                                    {" "}
                                    <div className=" flex-stack mb-2">
                                        {" "}
                                        {cloud.stack ? (<>
                                            {cloud.stack.status === "Pending" ? (<>
                                                <span className="w-100 btn btn-active-warning btn-light-warning btn-sm sphide me-2 fs-7 fw-bold"> Installing Stack  </span>
                                            </>) : (<>
                                                <span className="w-100 btn btn-active-success btn-light-success btn-sm sphide me-2 fs-7 fw-bold" style={{ cursor: "default" }}> Active  </span>
                                            </>)}
                                        </>) : (<>
                                            {cloud.status === "Active" ? (<>
                                                <span className="w-100 btn btn-success btn-sm sphide me-2 fs-7 fw-bold"> {cloud.status}  </span>
                                            </>) : (<>
                                                <span className="w-100 btn btn-warning btn-sm sphide me-2 fs-7 fw-bold"> {cloud.status}  </span>
                                            </>)}
                                        </>)}

                                        {" "}
                                    </div>{" "}
                                </div>{" "}
                            </td>{" "}
                            {/* <td className="text-center d-none">
                                {" "}
                                <div className="d-flex flex-column w-100 me-2">
                                    {" "}
                                    <div className=" flex-stack mb-2">
                                        {" "}
                                        {cloud.gpus?.map((key, index) => {
                                            return (
                                                <div key={index}>
                                                    <div
                                                        className=" fw-bold text-hover-primary d-block fs-6"
                                                    >
                                                        <div className="symbol symbol-45px me-2">
                                                            <img src={`assets/media/logos/${key.make.toLowerCase()}.png `} className="" alt="" style={{ width: "45px", height: "36px", padding: "2px", background: "#ebebeb75" }} />
                                                            {" " + "-" + " " + key.model}
                                                        </div>{" "}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>{" "}
                                </div>{" "}
                            </td>{" "} */}
                        </tr>
                        <>
                            {
                                cloud.gpus && cloud.gpus.length > 0 ? (<>
                                    {cloud.gpus.map((key, index2) => (<>
                                        <tr>
                                            <td className="p-3 d-none">
                                                {" "}
                                                <div className="d-flex flex-column w-100 me-2" >
                                                    {" "}
                                                    <div className="d-flex flex-stack mb-2">
                                                        {" "}
                                                        <span className=" me-2 fs-7 fw-bold">{cloud.cloudid}</span>{" "}
                                                    </div>{" "}
                                                </div>{" "}
                                            </td>{" "}
                                            <td className="d-none">
                                                {" "}
                                                <div className="d-flex align-items-center sphide">
                                                    {" "}
                                                    <div className="symbol symbol-30px me-5">
                                                        {" "}
                                                        <img src={`/img/flag_${cloud.dclocation.dccc}.png`} />{" "}
                                                    </div>{" "}
                                                    <div className="d-flex justify-content-start flex-column">
                                                        {" "}
                                                        <div
                                                            className="text-dark fw-bold text-hover-primary fs-6"
                                                        >
                                                            {cloud.dclocation.location}
                                                        </div>{" "}
                                                        <span className="text-muted fw-bold text-muted d-block fs-7">
                                                            {cloud.dclocation.country}
                                                        </span>{" "}
                                                    </div>{" "}
                                                </div>{" "}
                                            </td>{" "}

                                            <td className='text-center'>

                                                <div className="d-flex  align-items-center ps-10">
                                                    <div className='symbol symbol-45px me-5  text-center pt-2'>
                                                        <span className="svg-icon svg-icon-muted svg-icon-2hx me-3">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={24}
                                                                height={24}
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M9 18H13.4V16H9C7.9 16 7 15.1 7 14V3C7 2.4 6.6 2 6 2C5.4 2 5 2.4 5 3V14C5 16.2 6.8 18 9 18Z"
                                                                    fill="black"
                                                                />
                                                                <path
                                                                    opacity="0.3"
                                                                    d="M13.4 12L17.7 16.3C18.1 16.7 18.1 17.3 17.7 17.7L13.4 22V12Z"
                                                                    fill="black"
                                                                />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    {/*begin::Symbol*/}
                                                    <div className="symbol symbol-40px me-4 d-none">
                                                        <div className="symbol-label fs-2 fw-bold bg-light-primary text-inverse-danger">
                                                            <svg
                                                                width={24}
                                                                height={24}
                                                                viewBox="0 0 84 84"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <g
                                                                    id="DBaaS-Icon-1C-BLK"
                                                                    transform="translate(0.189181, -0.000000)"
                                                                    fill="#494b74"
                                                                    fillRule="nonzero"
                                                                >
                                                                    <path d="m66.7 48.9c-7.7 2.1-17.7 3.3-28.4 3.3s-20.8-1.2-28.4-3.3c-2.2-.6-4.2-1.3-5.9-2v22c0 5.2 15.3 9.4 34.3 9.4s34.3-4.2 34.3-9.4v-22c-1.8.8-3.7 1.4-5.9 2zm-6.3 19.8c-2.6 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6 4.6 2.1 4.6 4.6-2 4.6-4.6 4.6z" />
                                                                    <path d="m38.3 5.1c-19 0-34.3 4.2-34.3 9.4v25.4c3.6 2.4 14.8 6.1 34.3 6.1 19.4 0 30.7-3.7 34.3-6.1v-25.4c-.1-5.2-15.4-9.4-34.3-9.4zm22.1 31.7c-2.6 0-4.6-2.1-4.6-4.6 0-2.6 2.1-4.6 4.6-4.6s4.6 2.1 4.6 4.6c.1 2.5-2 4.6-4.6 4.6zm-22.1-14.3c-20.9 0-32.3-4.9-32.3-7.4s11.4-7.4 32.3-7.4 32.3 4.9 32.3 7.4-11.5 7.4-32.3 7.4z" />
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    {/*end::Symbol*/}
                                                    {/* <div className="d-flex flex-column w-100 me-2">

                                                        <div className="flex-stack mb-2"> */}

                                                    <div key={index2}>
                                                        <div
                                                            className="d-flex justify-content-start flex-column fw-bold"
                                                        >
                                                            <div className="symbol symbol-45px me-2">
                                                                <img src={`assets/media/logos/${key.make.toLowerCase()}.png `} className="" alt="" style={{ width: "45px", height: "36px", padding: "2px", background: "#ebebeb75" }} />
                                                                {" " + "-" + " " + key.model}
                                                            </div>{" "}
                                                        </div>
                                                    </div>

                                                    {/* </div>
                                                    </div> */}
                                                    {/* <div className="d-flex justify-content-start flex-column">
                                                        <Link to={"/database-cluster/" + value.id + "/" + value2.cloudid}>
                                                            <a style={{ cursor: "pointer" }} className="text-dark fw-bold text-hover-primary fs-6 sphide">
                                                                {value2.hostname}
                                                            </a>
                                                        </Link>
                                                    </div> */}
                                                </div>
                                            </td>
                                            <td className="d-none">
                                                {cloud.stack ? (<>

                                                    <div className="d-flex align-items-center">
                                                        {" "}
                                                        <div className="symbol symbol-45px me-2">
                                                            <img src={'/' + cloud.stack.logo_url} className="" alt="" style={{ width: "45px", height: "36px", padding: "2px", background: "#ebebeb75" }} />
                                                        </div>{" "}
                                                        <div className="d-flex justify-content-start flex-column">
                                                            {" "}
                                                            <div
                                                                className=" fw-bold text-hover-primary d-block fs-6"
                                                            >
                                                                {cloud.stack.title?.charAt(0).toUpperCase() + cloud.stack.title?.slice(1)}
                                                            </div>{" "}
                                                        </div>{" "}
                                                    </div>
                                                </>) : (<>
                                                    <div className="d-flex align-items-center">
                                                        {" "}
                                                        <div className="symbol symbol-45px me-2 text-center" style={{ width: "45px", height: "36px", padding: "2px", background: "#ebebeb75" }}>
                                                            {" "}
                                                            <span className={"fonticon_" + cloud.image.distribution + ""} />{" "}
                                                        </div>{" "}
                                                        <div className="d-flex justify-content-start flex-column">
                                                            {" "}
                                                            <div
                                                                className=" fw-bold text-hover-primary d-block fs-6"
                                                            >
                                                                {cloud.image.distribution.charAt(0).toUpperCase() + cloud.image.distribution.slice(1)}
                                                            </div>{" "}
                                                        </div>{" "}
                                                    </div>
                                                </>)}
                                            </td>{" "}

                                            <td className="text-center">
                                                {" "}
                                                <div className="d-flex flex-column w-100 me-2">
                                                    {" "}
                                                    <div className=" flex-stack mb-2 text-center">
                                                        {" "}
                                                        <span className="badge badge-primary text-center"
                                                            htmlFor="IP Address"
                                                            id={"ip_address_sec" + index}
                                                            // onClick={() => copyToClipboard(index)}
                                                            data-bs-toggle="tooltip"
                                                            data-bs-placement="right"
                                                            title="Click to copy IP Address"
                                                        >
                                                            <span id={"ip_address" + index}> {count}/gpu</span>&nbsp;&nbsp;
                                                            {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen054.svg*/}
                                                            <span className="svg-icon svg-icon-light svg-icon-2 d-none">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={24}
                                                                    height={24}
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                >
                                                                    <path
                                                                        opacity="0.5"
                                                                        d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                                                                        fill="black"
                                                                    />
                                                                    <path
                                                                        fillRule="evenodd"
                                                                        clipRule="evenodd"
                                                                        d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                                                                        fill="black"
                                                                    />
                                                                </svg>
                                                            </span>
                                                            {/*end::Svg Icon*/}
                                                        </span>{" "}
                                                    </div>{" "}
                                                </div>{" "}
                                            </td>{" "}
                                            <td className='d-none'></td>
                                            <td className='d-none'></td>
                                            <td className='text-center'>

                                                {" "}
                                                <div className="d-flex justify-content-start flex-shrink-0 w-100">
                                                    {" "}

                                                    <a
                                                        className="btn btn-danger btn-sm me-1 me-5 sphide w-100" id={`delete-fw-btn-${key.id}`}
                                                        // className="btn btn-danger btn-active-light-danger btn-sm me-1 me-5 sphide" id={`delete-fw-btn-0`}
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => { deleteGpu(cloud.cloudid, key.id) }}
                                                    >
                                                        Detached
                                                    </a>{" "}
                                                </div>
                                            </td>
                                            {/* <td className="text-center d-none">
                                                {" "}
                                                <div className="d-flex flex-column w-100 me-2">
                                                    {" "}
                                                    <div className=" flex-stack mb-2">
                                                        {" "}
                                                        {cloud.gpus?.map((key, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    <div
                                                                        className=" fw-bold text-hover-primary d-block fs-6"
                                                                    >
                                                                        <div className="symbol symbol-45px me-2">
                                                                            <img src={`assets/media/logos/${key.make.toLowerCase()}.png `} className="" alt="" style={{ width: "45px", height: "36px", padding: "2px", background: "#ebebeb75" }} />
                                                                            {" " + "-" + " " + key.model}
                                                                        </div>{" "}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>{" "}
                                                </div>{" "}
                                            </td>{" "} */}
                                        </tr>
                                    </>))}
                                </>) : ('')
                            }
                        </>
                    </>
                ))
            ) : (<>
                <tr>
                    <td colspan="12" className="dataTables_empty" id="loader">
                        <TopBarHeaderLine />
                        <TableLoader />
                        {/* <div className="loading-spinner"></div> */}
                    </td>
                    <td colspan="12" className="d-none" id="no_record" valign="top">
                        <div className="d-flex flex-column flex-center">
                            <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                        </div>
                    </td>
                </tr>
            </>)}
        </>
    )
}
