import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom/dist";
import UsePost from "../../../../CustomHooks/usePost";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UseApi from "../../../../CustomHooks/useApi";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import validator from "validator";
import { publicIp, publicIpv4, publicIpv6 } from 'public-ip';
import {
  AddHostCount,
  cloudDeployUpdate,
} from "../../../../Redux/Services/Action/actions";
import useRazorpay from "react-razorpay";
import axios from "axios";
import UsePostData from "../../../../CustomHooks/usePostData";
import spinner from "../billing/spinner.svg";
import "./custom.css";
import PricingContainer from "../pricing/PricingContainer";
import Backup from "./deploy-tabs/backup/Backup";
import ServerManagement from "./deploy-tabs/server-management/ServerManagement";
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../Loaders/TableLoader";
import CpuModelTab from "../../../../CustomHooks/CpuModelTab";
import Dczone from "../../utils/dczone/Dczone";
import DistroTab from "./deploy-tabs/operating-systems/DistroTab";
import Stacks from "./deploy-tabs/stacks/Stacks";
import Iso from "./deploy-tabs/iso/Iso";
import Snapshots from "./deploy-tabs/snapshots/Snapshots";
import Backups from "./deploy-tabs/backups/Backups";
import Marketplace from "./deploy-tabs/marketplace/Marketplace";
import MarketplaceFields from "./deploy-tabs/marketplace/MarketplaceFields";
import localStorage from "local-storage";
import Vpc from "../../utils/vpc/Vpc";
import Firewall from "../../utils/firewall/Firewall";
import Storage from "./deploy-tabs/storage/Storage";
import PublicIpTab from "../../../../CustomHooks/PublicIpTab";
var serialize = require("form-serialize");
function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}
const CloudDeploy = (props) => {
  const [location, setLocation] = useState("innoida")
  const [marginBottom, setMarginBottom] = useState(window.innerWidth >= 768 ? '' : '5.5rem');

  function selectDeployStack() {
    var query = window.location.href;
    var vars = query.split("/");
    if (vars?.length == 7 && vars[4] == "deploy") {
      if (vars[6] == "private") {
        var private_stack = document.querySelector("#private_stack_" + vars[5]);
        var stack_nav = document.querySelector("#stack_nav");
        var private_tab = document.querySelector("#private_tab");
        if (private_stack && stack_nav && private_tab) {
          stack_nav.click();
          private_tab.click();
          private_stack.click();
        }
      } else if (vars[6] == "public") {
        var public_stack = document.querySelector("#public_stack_" + vars[5]);
        var stack_nav = document.querySelector("#stack_nav");
        var public_tab = document.querySelector("#public_tab");
        if (public_stack && stack_nav && public_tab) {
          stack_nav.click();
          public_tab.click();
          public_stack.click();
        }
      }

    }
  }
  // var id=vars[4];
  // const [pageStatus, setPageStatus] = useState(false)
  const makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters?.length;
    for (var i = 0; i < length; i++) {
      result += characters?.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
  const forceUpdate = useForceUpdate();
  const [adcdata, setAdcdata] = useState([]);
  const [ddcdata, setDdcdata] = useState([]);
  const [pageStatus, setPageStatus] = useState(false);
  const dataFetchedRef = useRef();
  const navigate = useNavigate();
  const [gpuData, setGpuData] = useState([])
  const [distroData, setDistroData] = useState(null);
  const [planData, setPlanData] = useState(null);
  const [ebsPlanData, setEbsPlanData] = useState(null);
  const [firewallData, setFirewallData] = useState(null);
  const [sshData, setSshData] = useState(null);
  const [hostCount, setHostCount] = useState(null);
  const [invoiceData, setInvoiceData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [recommendedCpu, setRecommendedCpu] = useState('')
  const user_data = useRef(null);
  const dczoneStatus = useRef(true);
  const [osDiskSize, setOsDiskSize] = useState(80);
  const [cpuValue, setCpuValue] = useState("amd")
  const [plansDiscount, setPlansDiscount] = useState({ hourly: 0, monthly: 0, month3: 0, month6: 0, month12: 0 });
  const filesUrl = localStorage.get("filesUrl");
  const [siteUrl, setSiteUrl] = useState(null);
  const app_info = localStorage.get("appInfo");
  const [ebsEnabled, setEbsEnabled] = useState('no');
  const [storageType, setStorageType] = useState('general');
  const [subnetData, setSubnetData] = useState(null)
  const [vpcId, setVpcId] = useState('')
  const formRef = useRef(null)
  useEffect(() => {
    if (filesUrl) {
      setSiteUrl(filesUrl);
    }
  }, [])

  function MarkRibbonTick(type, ribbon_id, action) {
    var tabSelect = document.querySelectorAll([".tickMark" + type]);
    if (tabSelect) {
      tabSelect.forEach((clist) => {
        clist.classList.add("d-none");
      });
    }
    if (action == "on") {
      var currentTab = document.querySelector(
        "#tickMark" + type + "-" + ribbon_id
      );
      if (currentTab) {
        currentTab.classList.remove("d-none");
      }
    }
  }

  const selectIso = (data, iso_id) => {
    selectOsApp("iso", data);
    if (props) {
      if (props.deployInfo) {
        props.deployInfo[0].isoid = iso_id;
        props.deployInfo[0].marketplaceid = 0;
        props.deployInfo[0].osid = 0;
        props.deployInfo[0].snapid = 0;
        props.deployInfo[0].backupid = 0;
      }
    }
    const all_versionText = document.getElementsByClassName(
      "osversionselectmultiple"
    );
    if (all_versionText) {
      for (var i = 0; i < all_versionText.length; i++) {
        all_versionText[i].innerHTML = "Select Version";
      }
    }
    MarkRibbon("iso", iso_id);
  };
  const selectSnapshot = (snapshot_id, size) => {
    selectOsApp("snapshot", snapshot_id);
    if (props) {
      if (props.deployInfo) {
        props.deployInfo[0].isoid = 0;
        props.deployInfo[0].marketplaceid = 0;
        props.deployInfo[0].osid = 0;
        props.deployInfo[0].snapid = snapshot_id;
        props.deployInfo[0].backupid = 0;
      }
    }
    const all_versionText = document.getElementsByClassName(
      "osversionselectmultiple"
    );
    if (all_versionText) {
      for (var i = 0; i < all_versionText.length; i++) {
        all_versionText[i].innerHTML = "Select Version";
      }
    }
    selectOsDiskSize(size);
    MarkRibbon("snapshot", snapshot_id);
  };
  const selectBackup = (backup_id, size) => {
    selectOsApp("backup", backup_id);
    if (props) {
      if (props.deployInfo) {
        props.deployInfo[0].isoid = 0;
        props.deployInfo[0].marketplaceid = 0;
        props.deployInfo[0].osid = 0;
        props.deployInfo[0].snapid = 0;
        props.deployInfo[0].backupid = backup_id;
      }
    }
    const all_versionText = document.getElementsByClassName(
      "osversionselectmultiple"
    );
    if (all_versionText) {
      for (var i = 0; i < all_versionText.length; i++) {
        all_versionText[i].innerHTML = "Select Version";
      }
    }
    selectOsDiskSize(size);
    MarkRibbon("backup", backup_id);
  };
  function MarkRibbon(type, select_id) {
    var appData = ["Os", "marketplace", "backup", "snapshot", "iso"];
    if (appData) {
      appData.forEach((value) => {
        MarkRibbonTick(value, 0, "off");
      });
      MarkRibbonTick(type, select_id, "on");
    }
  }
  const selectOne = (type, select_id) => {
    var appData = ["Os", "marketplace", "backup", "snapshot", "iso"];
    if (appData) {
      appData.forEach((value) => {
        MarkRibbonTick(value, 0, "off");
      });
      MarkRibbonTick(type, select_id, "on");
    }
  };

  const getDczone = async (dczones, custom = 'no') => {
    if (!dczones || dczones.length === 0) {
      if (custom == 'no') {
        setAdcdata([]);
        setDdcdata([]);
      }
      return;
    }

    // console.log(dczoneStatus.current);

    if (dczoneStatus.current) {
      const inactiveZones = dczones.filter(zone => zone.status !== "active");
      const activeZones = dczones.filter(zone => zone.status === "active");

      try {
        // const { ip: serverIp } = await fetch("https://api.ipify.org?format=json").then(res => res.json());
        const serverIp = await publicIpv4();
        const allowedIps = new Set([
          "103.209.144.51", "103.209.144.66", "103.209.145.208", "103.209.144.81",
          "103.209.144.82", "103.209.144.83", "103.127.30.91", "103.209.144.60",
          "103.127.30.239", "103.127.28.52"
        ]);

        const updatedDdcdata = inactiveZones.filter(zone => !allowedIps.has(serverIp));
        setDdcdata(updatedDdcdata);
      } catch (error) {
        console.error("Failed to fetch IP:", error);
      }

      setAdcdata(activeZones);
      dczoneStatus.current = false;

      forceUpdate();

      setTimeout(() => {
        let dc = "innoida";
        if (props?.deployInfo[0]?.dc_location) {
          dc = props.deployInfo[0].dc_location;
        }
        selectDc(dc, 'no');
        // const clickTo = document.querySelector("#dcslugl_" + dc);
        // if (clickTo) {
        //   clickTo.click();
        // }
      }, 50);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setMarginBottom(window.innerWidth >= 768 ? '' : '5.5rem');
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [isPlanSet, setIsPlanSet] = useState(false);
  const getDistro = async (dc = 'innoida', custom = 'no') => {

    toast.dismiss();
    if (custom == 'no') {
      setDistroData(null);
    }

    setVpcLoader(true);
    setVpcData(null);

    setBackupLoader(true);
    setBackupData(null);

    setSnapLoader(true);
    setSnapData(null);

    setIsoLoader(true);
    setIsoData(null);

    setPlanData(null);
    setIsPlanSet(false);
    setEbsPlanData(null);

    setGpuData(null);
    setSshData(null);
    setHostCount(null);
    setFirewallData(null);


    try {
      const [deployData] = await Promise.all([
        UseApi("get", `cloud/getdeploy?dcslug=${dc}`, ""),
      ]);

      const userInfo = localStorage.get('user_info');
      user_data.current = userInfo;
      setUserData(user_data.current);


      if (deployData?.length === 0) {
        if (custom == 'no') {
          setDistroData(null);
        }
        setPlanData([]);
        setEbsPlanData([]);
        setIsPlanSet(true);
        setFirewallData(null);
        setSshData(null);
        setHostCount(null);
        setEbsEnabled('no')
      } else {
        // setPlanData(deployData.plans)
        var generalPlans = [];
        var ebsPlans = [];
        if (deployData.plans) {
          deployData.plans.map((value, index) => {
            if (value.disk == "0") {
              ebsPlans.push(value);
            }
            if (value.disk != "0") {
              generalPlans.push(value);
            }
          })
        }
        setPlanData(generalPlans);
        setEbsPlanData(ebsPlans);
        await Promise.all([
          getDczone(deployData?.dczones, custom),
          getMarketplace(deployData?.stacks),
          getStackPublic(deployData?.stacks_public),
          getStackPrivate(deployData?.stacks_private)
        ]);
        setGpuData(deployData?.gpus);
        setDistroData(deployData?.distro);

        setSshData(deployData?.keys);
        setHostCount(props?.hostCountData || ["host"]);

        setFirewallLoader(false);
        setFirewallData(deployData?.firewalls ?? []);

        setBackupLoader(false);
        setBackupData(deployData?.backups ?? []);

        setSnapLoader(false);
        setSnapData(deployData?.snapshots ?? []);

        setIsoLoader(false);
        setIsoData(deployData?.isos ?? []);

        if (deployData?.ebs == 1 || deployData?.ebs == '1') {
          setEbsEnabled('yes');
        } else {
          setEbsEnabled('no');
        }
        if (deployData?.vpc?.status == "error") {
          setVpcLoader(false);
          setVpcData(null);
        } else {
          setVpcLoader(false);
          setVpcData(deployData?.vpc?.vpc);
        }
        if (deployData.cpu_selected) {
          setCpuValue(deployData?.cpu_selected);
        }

        setIsPlanSet(true);
      }

      forceUpdate();
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const [vpcData, setVpcData] = useState(null);
  const [backupData, setBackupData] = useState(null);
  const [snapData, setSnapData] = useState(null);
  const [isoData, setIsoData] = useState(null);

  const [firewallLoader, setFirewallLoader] = useState(false);
  const [vpcLoader, setVpcLoader] = useState(false);
  const [backupLoader, setBackupLoader] = useState(false);
  const [snapLoader, setSnapLoader] = useState(false);
  const [isoLoader, setIsoLoader] = useState(false);

  const selectDc = async (slug, custom = 'no') => {
    // getAllProductData(slug);
    if (slug != "inmumbaizone2") {
      changeStorageType('general');
    }
    if (custom == 'yes') {
      getDistro(slug, custom)
    }
    if (props && props.deployInfo[0] && props.deployInfo[0].dc_location) {
      props.deployInfo[0].dc_location = slug;
    }
    setLocation(slug)
    var dcSelect = document.querySelectorAll([".tickMarkDc"]);
    var currentDc = document.querySelector("#tickMarkDc-" + slug);
    if (dcSelect) {
      dcSelect.forEach((clist) => {
        clist.classList.add("d-none");
      });
    }
    if (currentDc) {
      currentDc.classList.remove("d-none");
    }
    // setPlanData([]);
  };

  console.log(vpcData);

  useEffect(() => {
    getDistro();
    changeStorageType('general');
    const form = document.getElementById('clouddeploy');
    if (form) {
      form.reset();
    }
  }, []);

  function selectOsApp(type, data) {
    if (type != "snapshot") {
      selectOsDiskSize(80);
    }
    const select_image = document.querySelector("#select_image");
    const main_stack = document.querySelector("#main_stack");
    const iso_field = document.querySelector("#iso_field");
    const backup_field = document.querySelector("#backup_field");
    const snapshot_field = document.querySelector("#snapshot_field");
    if (
      select_image &&
      main_stack &&
      iso_field &&
      backup_field &&
      snapshot_field
    ) {
      select_image.value = "";
      main_stack.value = "";
      iso_field.value = "";
      backup_field.value = "";
      snapshot_field.value = "";
    }
    if (props.deployInfo) {
      props.deployInfo[0].select_image = "";
      props.deployInfo[0].stack = "";
      props.deployInfo[0].iso = "";
      props.deployInfo[0].backup = "";
      props.deployInfo[0].snapshot = "";
    }
    if (type == "os") {
      if (select_image) {
        select_image.value = data;
      }
      if (props.deployInfo) {
        props.deployInfo[0].select_image = data;
      }
    }
    if (type == "marketplace") {
      if (main_stack) {
        main_stack.value = data;
      }
      if (props.deployInfo) {
        props.deployInfo[0].stack = data;
      }
    }
    if (type == "iso") {
      if (iso_field) {
        iso_field.value = data;
      }
      if (props.deployInfo) {
        props.deployInfo[0].iso = data;
      }
    }
    if (type == "backup") {
      if (backup_field) {
        backup_field.value = data;
      }
      if (props.deployInfo) {
        props.deployInfo[0].backup = data;
      }
    }
    if (type == "snapshot") {
      if (snapshot_field) {
        snapshot_field.value = data;
      }
      if (props.deployInfo) {
        props.deployInfo[0].snapshot = data;
      }
    }
  }

  function containsWindows(inputString) {
    // Use a regular expression to match the word "windows" with optional digits
    const windowsRegex = /\bwindows\d*\b/i;

    // Test if the input string matches the regular expression
    return windowsRegex.test(inputString);
  }
  const [showSSH, setShowSSH] = useState(true);
  const [updateOs, setUpdateOs] = useState('no');

  const selectOs = (image, distribution, cost, id) => {
    if (distribution != "almalinux") {
      changeStorageType('general');
    }
    setUpdateOs('no');
    if (containsWindows(image)) {
      setShowSSH(false);
    } else {
      setShowSSH(true);
    }
    setCurrMarketplace(null);
    setCurrMarketplaceDistro(null);

    MarkRibbon("Os", distribution);
    if (props) {
      const os_cost = document.querySelector("#os_cost");
      if (os_cost) {
        os_cost.value = cost;
      }
      if (props.deployInfo) {
        props.deployInfo[0].osid = id;
        props.deployInfo[0].marketplaceid = 0;
        props.deployInfo[0].isoid = 0;
        props.deployInfo[0].snapid = 0;
        props.deployInfo[0].backupid = 0;
        props.deployInfo[0].os_cost = cost;
        props.deployInfo[0].distribution = distribution;
      }
      setTimeout(() => {
        setUpdateOs('yes');
      }, 1000)
      const os_version_list_ = document.querySelector(
        "#os_version_list_" + distribution
      );
      if (os_version_list_) {
        os_version_list_.classList.remove("d-block");
      }
      const all_radio = document.getElementsByClassName("radio_image");
      if (all_radio) {
        for (var i = 0; i < all_radio.length; i++) {
          all_radio[i].checked = false;
        }
      }
      var versionDropdown = document.querySelectorAll([".deploy_osversioncontainer"]);
      if (versionDropdown) {
        versionDropdown.forEach((clist) => {
          clist.classList.remove("d-block");
        });
      }
      const all_versionText = document.getElementsByClassName(
        "osversionselectmultiple"
      );
      if (all_versionText) {
        for (var i = 0; i < all_versionText.length; i++) {
          all_versionText[i].innerHTML = "Select Version";
        }
      }
      selectOsApp("os", image);

      const current_radio = document.querySelector("#select_" + distribution);
      if (current_radio) {
        current_radio.checked = true;
      }
      const current_select_version = document.querySelector(
        "#osversion_" + distribution
      );
      if (current_select_version) {
        current_select_version.innerHTML = image;
      }
      cloudCost();
    }
  };

  const getVpcById = async (id) => {
    const data = await UseApi('get', 'vpc/' + id, '');
    if (data?.subnets?.length > 0) {
      setSubnetData(data?.subnets)
    } else {
      setSubnetData([])
    }
  }

  const selectTickMark = (serviceid, name, input_name, custom = 'no') => {
    getVpcById(serviceid);
    var currentPlan = document.querySelector("#tickMark" + name + "-" + serviceid);
    var currentMain = document.querySelector("#border" + name + "-" + serviceid);
    const inputElement = document.getElementById(input_name + serviceid);


    if (currentPlan && currentMain) {
      // Toggle the tick mark visibility
      if (custom == 'no') {
        currentPlan.classList.toggle("d-none");
      } else {
        currentPlan.classList.remove("d-none");
      }

      // Toggle the border's active class
      if (currentPlan.classList.contains("d-none")) {
        inputElement.checked = false;
        inputElement.classList.remove('deploy_checkbox')
        currentMain.classList.remove("activeBorder");
        if (name == 'Vpc') {
          if (props) {
            if (props.deployInfo) {
              props.deployInfo[0].vpcid = 0;
            }
          }
        }

        if (name == 'Firewall') {
          if (props) {
            if (props.deployInfo) {
              props.deployInfo[0].firewallid = 0;
            }
          }
        }
      } else {
        inputElement.checked = true;
        currentMain.classList.add("activeBorder");
        inputElement.classList.add('deploy_checkbox')
        if (name == 'Vpc') {
          if (props) {
            if (props.deployInfo) {
              props.deployInfo[0].vpcid = serviceid;
            }
          }
        }

        if (name == 'Firewall') {
          if (props) {
            if (props.deployInfo) {
              props.deployInfo[0].firewallid = serviceid;
            }
          }
        }
      }

      // If the tick mark is now visible, remove the tick mark from other elements
      if (!currentPlan.classList.contains("d-none")) {
        var allTickmarks = document.querySelectorAll(".tickMark" + name);
        allTickmarks.forEach((tickMark) => {
          if (tickMark !== currentPlan) {
            tickMark.classList.add("d-none");
          }
        });
        // Remove active border from other elements
        var mainTickMarks = document.querySelectorAll(".border" + name);
        mainTickMarks.forEach((border) => {
          if (border !== currentMain) {
            border.classList.remove("activeBorder");
          }
        });
        // console.log("All Tickmarks:", allTickmarks);
        // console.log("Main TickMarks:", mainTickMarks);
      }
    }
  };

  const openDrawer = () => {
    var drawerElement = document.querySelector("#importkey-modal");
    var drawer_overlay_ssh = document.querySelector("#drawer_overlay_ssh");
    drawer_overlay_ssh.classList.toggle("d-none");
    drawerElement.classList.toggle("drawer-on");
    if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
      drawerElement.style.width = "100%";
    } else {
      drawerElement.style.width = "40%";
    }
  };
  const importSshKey = async () => {
    toast.dismiss();
    setPageStatus(true);
    const dt = toast.loading("Please wait...");
    const responseData = await UsePost("post", "key/import", "importkey");

    //////console.log(responseData);
    if (responseData.status === "error") {
      setPageStatus(false);
      toast.update(dt, {
        render: responseData.message,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      var SshForm = document.querySelector("#importkey");
      SshForm.reset();
    } else if (responseData.status === "success") {
      setPageStatus(false);
      toast.update(dt, {
        render: responseData.message,
        type: "success",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      getDistro();
      openDrawer();
      var SshForm = document.querySelector("#importkey");
      SshForm.reset();
    }
  };

  // end ssh keys
  // const deployCloudServer = async () => {
  //   var submitBtn = document.querySelector("#deploy-btn");
  //   var form = document.querySelector("#clouddeploy");
  //   var requestData = serialize(form, { hash: true });
  //   const dt = toast.loading("Please wait...");
  //   // if (vpcData != null && vpcData?.length > 0) {
  //   const validationError = checkObjectForEmpty(requestData);
  //   // if (validationError) {
  //   if (submitBtn) {
  //     setPageStatus(true);
  //     var preContent = submitBtn.innerHTML;
  //     submitBtn.classList.add("disabled");
  //     submitBtn.innerHTML =
  //       '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
  //     toast.dismiss();

  //     // if (requestData.hasOwnProperty('vpc') && requestData.vpc) {
  //     const responseData = await UsePost("post", "cloud/deploy", "clouddeploy");

  //     if (responseData) {

  //       setPageStatus(false);
  //       submitBtn.classList.remove("disabled");
  //       submitBtn.innerHTML = preContent;
  //       console.log(!responseData.status);

  //       if (!responseData.status) {
  //         if (Array.isArray(responseData.messages)) {
  //           responseData.messages.forEach((ele) => {
  //             if (ele.status == "success") {
  //               toast.dismiss(dt)
  //               toast.update(dt, {
  //                 render: "Cloud ID: " + ele?.cloudid + ", " + ele?.message,
  //                 type: "success",
  //                 isLoading: false,
  //                 autoClose: 5000,
  //                 hideProgressBar: false,
  //                 closeOnClick: true,
  //                 pauseOnHover: true,
  //                 draggable: true,
  //                 progress: undefined,
  //               });
  //               window.onDeployEvents('deploy_cloud_instances');
  //             } else {
  //               toast.dismiss(dt)
  //               toast.update(dt, {
  //                 render: ele.message,
  //                 type: "error",
  //                 isLoading: false,
  //                 autoClose: 5000,
  //                 hideProgressBar: false,
  //                 closeOnClick: true,
  //                 pauseOnHover: true,
  //                 draggable: true,
  //                 progress: undefined,
  //               });
  //             }
  //           })
  //           setTimeout(() => {
  //             navigate("/cloud");
  //           }, 3000)
  //           return;
  //         }
  //       }
  //       console.log(responseData.status);
  //       if (responseData.status == "error") {
  //         toast.update(dt, {
  //           render: responseData.message,
  //           type: "error",
  //           isLoading: false,
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //         });
  //       } else {
  //         toast.update(dt, {
  //           render: responseData.message,
  //           type: "success",
  //           isLoading: false,
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //         });
  //         if (props) {
  //           if (props.deployInfo) {
  //             props.deployInfo[0]={dc_location:"innoida",os_disk_size:80, os_cost:0,distribution:"",select_image:"",stack:"",iso:"",backup:"",snapshot:"",plan_value:"10045",plan_cost:0,plan_final_cost:0,plan_cost_without_discount:0,select_support_option_cost:0,select_support_option:"unmanaged",billing_info:"hourly", isBackup:false, planDiscount:0, coupon:'uthodeploy', marketplaceid:0, osid:0, isoid:0, snapid:0, backupid:0, vpcid:0, firewallid:0, storage:[{id:1, size:8, type:'nvme'}]};
  //           }
  //         }      
  //         const billing_info = document.querySelector("#billing_info").value;
  //         if (billing_info != "hourly") {
  //           openInvoice(responseData.invoiceid);
  //         } else {
  //           var cloudCount = document.querySelector("#total_chq");
  //           if (cloudCount) {
  //             if (cloudCount > 1) {
  //               setTimeout(() => {
  //                 navigate("/cloud");
  //               }, 6000)
  //             } else {
  //               navigate("/cloud/" + responseData.cloudid);
  //             }
  //           }
  //         }
  //       }
  //     }
  //     const form = document.getElementById('clouddeploy');
  //     if (form) {
  //       form.reset();
  //     }
  //     selectTickMark(vpcId, "Vpc", "vpc")
  //   }
  //   // } else {
  //   //   selectTickMark(vpcId, "Vpc", "vpc")
  //   //   setTimeout(() => {
  //   //     toast.update(dt, {
  //   //       render: "Please select vpc", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
  //   //     });
  //   //   }, 1000)
  //   // }
  //   // } else {
  //   //   setTimeout(() => {
  //   //     toast.update(dt, {
  //   //       render: "Vpc must required to deploy kubernetes for the selected dczone", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
  //   //     });
  //   //   }, 1000)
  //   // }
  //   selectTickMark(vpcId, "Vpc", "vpc")

  // };

  const deployCloudServer = async () => {
    var submitBtn = document.querySelector("#deploy-btn");
    var form = document.querySelector("#clouddeploy");
    const dt = toast.loading("Please wait...");

    const preContent = disableSubmitButton(submitBtn);

    try {
      const responseData = await UsePost("post", "cloud/deploy", "clouddeploy");
      handleApiResponse(responseData, dt);
    } catch (error) {
      showToast("error", "An error occurred during deployment.", dt);
    } finally {
      resetSubmitButton(submitBtn, preContent);
      resetForm();
      selectTickMark(vpcId, "Vpc", "vpc");
    }
  };

  const disableSubmitButton = (submitBtn) => {
    if (submitBtn) {
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML =
        '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      return preContent;
    }
  };

  const showToast = (type, message, dt) => {
    toast.update(dt, {
      render: message,
      type: type,
      isLoading: false,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleApiResponse = (responseData, dt) => {
    if (responseData) {
      if (Array.isArray(responseData.messages)) {
        responseData.messages.forEach((ele) => {
          if (ele.status == "success") {
            showToast("success", `Cloud ID: ${ele.cloudid}, ${ele.message}`, dt);
            window.onDeployEvents('deploy_cloud_instances');
          } else {
            showToast("error", ele.message, dt);
          }
        });

        // Navigate based on response after showing the toast messages
        setTimeout(() => {
          navigate("/cloud");
        }, 3000);
      } else if (responseData.status === "error") {
        showToast("error", responseData.message, dt);
      } else {
        showToast("success", responseData.message, dt);

        // Handle billing info and navigate accordingly
        const billingInfo = document.querySelector("#billing_info").value;
        if (billingInfo !== "hourly") {
          openInvoice(responseData.invoiceid);
        } else {
          const cloudCount = document.querySelector("#total_chq");
          if (cloudCount) {
            if (cloudCount > 1) {
              setTimeout(() => navigate("/cloud"), 6000);
            } else {
              navigate(`/cloud/${responseData.cloudid}`);
            }
          }
        }
      }
    }
  };

  const resetSubmitButton = (submitBtn, preContent) => {
    if (submitBtn) {
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML = preContent;
    }
  };

  const resetForm = () => {
    const form = document.getElementById('clouddeploy');
    if (form) form.reset();
  };

  const getCount = () => {
    getDczone();
    cloudCost();
    forceUpdate();
  };

  const fetchHostCount = () => {
    if (props.hostCountData) {
      setHostCount(props.hostCountData);
    }
  }

  const updateHostCount = (status) => {
    if (props) {
      const node_count = document.querySelector("#total_chq");
      const downCountBtn = document.querySelector("#hostDown");
      const upCountBtn = document.querySelector("#hostUp");
      if (status === "up") {
        downCountBtn.classList.remove("disabled");
        if (parseInt(node_count.value) >= 5) {
          upCountBtn.classList.add("disabled");
        } else {
          node_count.value = parseInt(node_count.value) + 1;
          if (props.hostCountData) {
            props.hostCountData.push("host");
            fetchHostCount();
          }
          getCount();
        }
      } else if (status === "down") {
        upCountBtn.classList.remove("disabled");
        if (parseInt(node_count.value) <= 1) {
          downCountBtn.classList.add("disabled");
        } else {
          node_count.value = parseInt(node_count.value) - 1;
          if (props.hostCountData) {
            props.hostCountData.pop();
            fetchHostCount();
          }
          getCount();
        }
      }
    }
  };

  const cloudCost = () => {
    const total_chq = document.querySelector("#total_chq"); //html

    //EBS Storage

    var ebsTotalCost = document.querySelector("#ebsTotalCost");

    //Billing cycle
    const billing_cycle = document.querySelector("#billing_cycle_value"); //html
    //Oringnal cost
    const original_server_cost = document.querySelector(
      "#original_server_cost"
    ); //html
    const original_cost_server = document.querySelector(
      "#original_cost_server"
    ); //html

    //Discount Percentage
    const discount_server_price = document.querySelector(
      "#discount_server_price"
    ); //html
    const discount_offer = document.querySelector("#discount_offer"); //html
    const discount_price = document.querySelector("#discount_price");

    //Discount cost
    const instance_cost_sec = document.querySelector("#instance_cost_sec"); //html
    const instance_cost = document.querySelector("#instance_cost"); //html

    //Backup cost
    const weekly_backup = document.querySelector("#weekly_backup"); //html
    const weekly_backup_cost = document.querySelector("#weekly_backup_cost"); //html

    //Operating System cost
    const os_price_sec = document.querySelector("#os_price_sec"); //html
    const os_cost_price = document.querySelector("#os_cost_price"); //html

    //Server Management cost
    const server_management = document.querySelector("#server_management"); //html
    const management_server = document.querySelector("#management_server"); //html
    //Configured Instance Sec
    const configured_instance_sec = document.querySelector(
      "#configured_instance_sec"
    );
    const configured_instance_cost = document.querySelector(
      "#configured_instance_cost"
    );

    //Final Instance Cost Sec
    const final_instance_cost_sec = document.querySelector(
      "#final_instance_cost_sec"
    );
    const final_instance_cost = document.querySelector("#final_instance_cost");
    const final_instance_count = document.querySelector(
      "#final_instance_count"
    );

    //instance_calc_sec
    const instance_calc_sec = document.querySelector("#instance_calc_sec");
    //Total cost
    const order_total = document.querySelector("#order_total"); //html
    const order_total_hour = document.querySelector("#order_toatl_hr"); //html

    if (
      ebsTotalCost &&
      total_chq &&
      billing_cycle &&
      original_server_cost &&
      original_cost_server &&
      discount_server_price &&
      discount_offer &&
      discount_price &&
      instance_cost_sec &&
      instance_cost &&
      weekly_backup &&
      weekly_backup_cost &&
      os_price_sec &&
      os_cost_price &&
      server_management &&
      management_server &&
      configured_instance_sec &&
      configured_instance_cost &&
      final_instance_cost_sec &&
      final_instance_cost &&
      final_instance_count &&
      instance_calc_sec &&
      order_total &&
      order_total_hour
    ) {

      var ebsStorageCost = 0;

      if (ebsTotalCost) {
        ebsStorageCost = calcEbsTotalCost();
        // ebsStorageCost = ebsTotalCost.value;
      }
      var serverCount = parseInt(total_chq.value);
      var InstanceCost = 0;
      var BackupCost = 0;
      var ManagementCost = 0;
      var OsCost = 0;
      if (props.deployInfo[0]) {
        if (props.deployInfo[0].billing_info != "hourly" && props.deployInfo[0].billing_info != "monthly") {
          if (props.deployInfo[0].billing_info == "3month") {
            billing_cycle.innerHTML = "3 Months";
          } else if (props.deployInfo[0].billing_info == "6month") {
            billing_cycle.innerHTML = "6 Months";
          } else {
            billing_cycle.innerHTML = "Annually";
          }
          var currentDiscountValue = parseInt(props.deployInfo[0].planDiscount);
          if (currentDiscountValue > 0) {
            discount_server_price.classList.remove("d-none");
            discount_offer.innerHTML = props.deployInfo[0].planDiscount;
            discount_price.innerHTML = parseInt(props.deployInfo[0].plan_cost_without_discount) - parseInt(props.deployInfo[0].plan_final_cost);
            original_server_cost.classList.remove("d-none");
            original_cost_server.innerHTML = props.deployInfo[0].plan_cost_without_discount;
          } else {
            discount_server_price.classList.add("d-none");
            discount_offer.innerHTML = 0;
            discount_price.innerHTML = parseInt(props.deployInfo[0].plan_final_cost);
            original_server_cost.classList.add("d-none");
            original_cost_server.innerHTML = props.deployInfo[0].plan_cost_without_discount;
          }
          instance_cost_sec.classList.remove("d-none");
          instance_cost.innerHTML = props.deployInfo[0].plan_final_cost;
          InstanceCost = parseInt(props.deployInfo[0].plan_final_cost);
        } else {
          if (props.deployInfo[0].billing_info == "monthly") {
            billing_cycle.innerHTML = "Monthly";
          } else {
            billing_cycle.innerHTML = "Hourly";
          }
          discount_server_price.classList.add("d-none");
          discount_offer.innerHTML = props.deployInfo[0].planDiscount;
          discount_price.innerHTML =
            parseInt(props.deployInfo[0].plan_cost_without_discount) -
            parseInt(props.deployInfo[0].plan_final_cost);

          instance_cost_sec.classList.remove("d-none");
          instance_cost.innerHTML = props.deployInfo[0].plan_final_cost;

          original_server_cost.classList.add("d-none");
          original_cost_server.innerHTML =
            props.deployInfo[0].plan_cost_without_discount;

          InstanceCost = parseInt(props.deployInfo[0].plan_final_cost);
        }

        if (props.deployInfo[0].isBackup == true) {
          weekly_backup.classList.remove("d-none");
          if (props.deployInfo[0].plan_final_cost) {

            weekly_backup_cost.innerHTML = parseInt(parseInt(props.deployInfo[0].plan_cost_without_discount) * 0.2);

            BackupCost = parseInt(parseInt(props.deployInfo[0].plan_cost_without_discount) * 0.2);

          }
        } else {
          weekly_backup.classList.add("d-none");
          if (props.deployInfo[0].plan_final_cost) {
            weekly_backup_cost.innerHTML = 0;

            BackupCost = 0;
          }
        }

        if (props.deployInfo[0].select_support_option != "unmanaged") {
          server_management.classList.remove("d-none");
          management_server.innerHTML = parseInt(
            props.deployInfo[0].select_support_option_cost
          );

          ManagementCost = parseInt(
            props.deployInfo[0].select_support_option_cost
          );
        } else {
          server_management.classList.add("d-none");
          management_server.innerHTML = parseInt(
            props.deployInfo[0].select_support_option_cost
          );

          ManagementCost = parseInt(
            props.deployInfo[0].select_support_option_cost
          );
        }

        if (props.deployInfo[0].os_cost != 0) {
          os_price_sec.classList.remove("d-none");
          // os_cost_price.innerHTML = props.deployInfo[0].os_cost;
          if (props.deployInfo[0].billing_info == "monthly" || props.deployInfo[0].billing_info == "hourly") {
            OsCost = parseInt(props.deployInfo[0].os_cost);
          } else if (props.deployInfo[0].billing_info == "3month") {
            OsCost = parseInt(props.deployInfo[0].os_cost) * 3;
          } else if (props.deployInfo[0].billing_info == "6month") {
            OsCost = parseInt(props.deployInfo[0].os_cost) * 6;
          } else {
            OsCost = parseInt(props.deployInfo[0].os_cost) * 12;
          }
          os_cost_price.innerHTML = OsCost;
        } else {
          os_price_sec.classList.add("d-none");
          // os_cost_price.innerHTML = props.deployInfo[0].os_cost;
          if (props.deployInfo[0].billing_info == "monthly" || props.deployInfo[0].billing_info == "hourly") {
            OsCost = parseInt(props.deployInfo[0].os_cost);
          } else if (props.deployInfo[0].billing_info == "3month") {
            OsCost = parseInt(props.deployInfo[0].os_cost) * 3;
          } else if (props.deployInfo[0].billing_info == "6month") {
            OsCost = parseInt(props.deployInfo[0].os_cost) * 6;
          } else {
            OsCost = parseInt(props.deployInfo[0].os_cost) * 12;
          }
          os_cost_price.innerHTML = OsCost;
        }
        if (
          parseInt(BackupCost) != 0 ||
          parseInt(ManagementCost) != 0 ||
          parseInt(OsCost) != 0
        ) {
          configured_instance_sec.classList.remove("d-none");
          instance_calc_sec.classList.remove("d-none");
          final_instance_cost_sec.classList.remove("d-none");
          configured_instance_cost.innerHTML =
            parseInt(InstanceCost) +
            parseInt(BackupCost) +
            parseInt(ebsStorageCost) +
            parseInt(ManagementCost) +
            parseInt(OsCost);
          final_instance_cost.innerHTML =
            parseInt(InstanceCost) +
            parseInt(BackupCost) +
            parseInt(ebsStorageCost) +
            parseInt(ManagementCost) +
            parseInt(OsCost);
          final_instance_count.innerHTML = serverCount;
        } else {
          if (parseInt(serverCount) > 1) {
            final_instance_cost_sec.classList.remove("d-none");
            final_instance_cost.innerHTML =
              parseInt(InstanceCost) +
              parseInt(BackupCost) +
              parseInt(ebsStorageCost) +
              parseInt(ManagementCost) +
              parseInt(OsCost);
            final_instance_count.innerHTML = serverCount;
          } else {
            configured_instance_sec.classList.add("d-none");
            final_instance_cost_sec.classList.add("d-none");
            instance_calc_sec.classList.add("d-none");
          }
        }
        //total price
        if (props && props.deployInfo[0]) {
          // console.log(props.deployInfo[0].billing_info);
          if (props.deployInfo[0].billing_info == "monthly" || props.deployInfo[0].billing_info == "hourly") {
            order_total_hour.innerHTML = (
              ((parseInt(InstanceCost) +
                parseInt(BackupCost) +
                parseInt(ebsStorageCost) +
                parseInt(ManagementCost) +
                parseInt(OsCost)) *
                serverCount) /
              720
            ).toFixed(2);
          } else if (props.deployInfo[0].billing_info == "3month") {
            order_total_hour.innerHTML = (
              ((parseInt(InstanceCost) +
                parseInt(BackupCost) +
                parseInt(ebsStorageCost) +
                parseInt(ManagementCost) +
                parseInt(OsCost)) *
                serverCount) /
              (720 * 3)
            ).toFixed(2);
          } else if (props.deployInfo[0].billing_info == "6month") {
            order_total_hour.innerHTML = (
              ((parseInt(InstanceCost) +
                parseInt(BackupCost) +
                parseInt(ebsStorageCost) +
                parseInt(ManagementCost) +
                parseInt(OsCost)) *
                serverCount) /
              (720 * 6)
            ).toFixed(2);
          } else {
            order_total_hour.innerHTML = (
              ((parseInt(InstanceCost) +
                parseInt(BackupCost) +
                parseInt(ebsStorageCost) +
                parseInt(ManagementCost) +
                parseInt(OsCost)) *
                serverCount) /
              (720 * 12)
            ).toFixed(2);
          }
        }


        order_total.innerHTML =
          (parseInt(InstanceCost) +
            parseInt(BackupCost) +
            parseInt(ebsStorageCost) +
            parseInt(ManagementCost) +
            parseInt(OsCost)) *
          serverCount;
      }
    }
  };

  const openInvoice = async (id) => {
    toast.dismiss();
    const invoice = await UseApi("get", "billing/" + id, "");
    setInvoiceData(invoice.invoices[0]);
    ////console.log(invoice);
    const transTabel = document.querySelector("#trans-tabel");
    const invoiceCredit = document.querySelector("#invoice-credit");
    var userAmount = 0;
    if (userData != null) {
      userAmount = userData.availablecredit;
    }
    if (invoice.invoices[0]) {
      if (transTabel) {
        if (invoice.invoices[0].transs.length <= 0) {
          transTabel.classList.add("d-none");
        } else {
          transTabel.classList.remove("d-none");
        }
      }
      // //console.log(parseFloat(invoice.invoices[0].rowtotal));
      if (invoiceCredit) {
        if (parseFloat(userAmount) > parseFloat(invoice.invoices[0].rowtotal)) {
          invoiceCredit.classList.remove("d-none");
        } else {
          invoiceCredit.classList.add("d-none");
        }
      }
    }
    var drawerElement = document.querySelector("#invoice_drawer");
    drawerElement.classList.toggle("drawer-on");
    if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
      drawerElement.style.width = "100%";
    } else {
      drawerElement.style.width = "82%";
    }
  };

  const closeInvoice = () => {
    var closeBtn = document.querySelector("#close-invoice-btn");

    if (closeBtn) {
      closeBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
    }
    var drawerElement = document.querySelector("#invoice_drawer");
    if (drawerElement) {
      drawerElement.classList.toggle("drawer-on");
      drawerElement.style.width = "1100px";
    }
    setTimeout(() => {
      if (closeBtn) {
        closeBtn.innerHTML = "close";
      }
    }, 1000);
  }
  const applyCreditAmount = async (id) => {
    swal({
      title: "Are you sure?",
      text: "Once Applied, your available credit will reduce according to this amount!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        toast.dismiss();
        const dt = toast.loading("Invoice processing...");
        const dnsp = await UsePost(
          "post",
          "billing/applycredit",
          "applycredit"
        );
        if (dnsp.status == "error") {
          toast.update(dt, {
            render: dnsp.message,
            type: "warning",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.update(dt, {
            render: dnsp.message,
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        swal({
          title: "Apply Credit",
          text: "Your not applied for the invoice!",
          icon: "success",
          button: "OK",
        });
      }
    });
  };
  const Razorpay = useRazorpay();
  const invoicePayment = useCallback(async () => {
    const payInvoiceBtn = document.querySelector("#invoice-payment-button");
    if (payInvoiceBtn) {
      payInvoiceBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
    }
    // const u_data = await UseApi("get", "account/info", "");
    const u_data = localStorage.get('user_info');
    const uData = u_data;
    const u_razorpay_info = await UseApi("get", "mhcsecinfo", "");

    ////console.log(razorpay_info);
    const amountFund = document.querySelector("#invoiceAmountFund");
    const descp_invoice_id = document.querySelector("#invoice_id");
    var invoice_id = "";
    //console.log(descp_invoice_id.value);
    if (descp_invoice_id) {
      invoice_id = descp_invoice_id.value;
    }
    var rorder_id = "";
    var rfullname = "";
    var rmobile = "";
    var remail = "";
    var ramount = 0;
    var rcurrency = "";
    if (amountFund) {
      ramount = amountFund.value;
    }

    if (uData) {
      rorder_id = uData.razorpay_orderid;
      rfullname = uData.fullname;
      rmobile = uData.mobile;
      remail = uData.email;
      rcurrency = uData.currency;
    }
    // //console.log(rorder_id);
    // //console.log(rfullname);
    // //console.log(rmobile);
    // //console.log(remail);
    // //console.log(ramount);
    // //console.log(invoice_id);
    const options = {
      //key: "rzp_live_aeMFERiIAsYFFF",
      key: u_razorpay_info.razorpay_key,
      amount: ramount * 100,
      currency: "INR",
      name: "Microhost Cloud",
      description: invoice_id,
      image: "https://www.microhost.com/assets/favicon/apple-touch-icon.png",
      prefill: {
        name: rfullname,
        email: remail,
        contact: rmobile,
      },
      options: {
        checkout: {
          method: {
            netbanking: 1,
            card: 0,
            upi: 1,
          },
        },
      },
      handler: async (res) => {
        //res.razorpay_payment_id
        //res.status_code
        //pay_L0C8DacFM1Voqi
        //console.log(res);
        if (res.status_code == 200) {
          swal({
            title: "Processing please wait...",
            icon: spinner,
            html: true,
            showSpinner: true,
          });
          const resData = await UsePostData("post", "billing/addpayment", {
            platform: "2023",
            invoiceid: invoice_id,
            transid: res.razorpay_payment_id,
            amount: ramount,
            currency: rcurrency,
            gateway: "Razorpay",
          });
          if (resData.status == "success") {
            swal({
              title: "Invoice",
              text: "Invoice paid successfuly",
              icon: "success",
              button: "OK",
            });
            setTimeout(() => {
              navigate("/cloud");
            }, 4000);
          } else {
            swal({
              title: "Invoice",
              text: "Invoice not paid!",
              icon: "warning",
              button: "OK",
            });
          }

          //console.log(resData);
          // const paymentInfo=await axios.get('https://'+u_razorpay_info.razorpay_key+':'+u_razorpay_info.razorpay_secret+'@api.razorpay.com/v1/payments/'+res.razorpay_payment_id+'');
          // //console.log(paymentInfo);
        }
      },
      notes: {
        address: "Utho Cloud- Fund add",
      },
      theme: {
        color: "#377dff",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
    if (payInvoiceBtn) {
      payInvoiceBtn.innerHTML = "Pay";
    }
  }, [Razorpay]);

  function ChangeScreen() {
    useEffect(() => {
      function handleResize() {
        const sideCheckout = document.querySelector("#sideCheckout");
        if (sideCheckout) {
          if (window.innerWidth > 990) {
            // sideCheckout.classList.add("mhc_checkout");
          } else {
            // sideCheckout.classList.remove("mhc_checkout");
          }
        }
      }
      handleResize();
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, [pageStatus]);
    return null;
  }

  const [marketplaceData, setMarketplaceData] = useState(null);
  const [marketplaceLoader, setMarketplaceLoader] = useState(false);

  const getMarketplace = async (marketData) => {
    setMarketplaceLoader(true);

    if (marketData && marketData.length !== 0) {
      setMarketplaceData(marketData);
    } else {
      setMarketplaceData([]);
    }

    setMarketplaceLoader(false);
  };

  const [stackPublicData, setStackPublicData] = useState(null);
  const [stackPublicLoader, setStackPublicLoader] = useState(false);

  const getStackPublic = async (publicData) => {
    setStackPublicLoader(true);

    if (publicData && publicData.length !== 0) {
      setStackPublicData(publicData);
    } else {
      setStackPublicData([]);
    }

    setStackPublicLoader(false);
  };


  const [stackPrivateData, setStackPrivateData] = useState(null);
  const [stackPrivateLoader, setStackPrivateLoader] = useState(false);

  const getStackPrivate = async (privateData) => {
    setStackPrivateLoader(true);

    if (privateData && privateData.length !== 0) {
      setStackPrivateData(privateData);
    } else {
      setStackPrivateData([]);
    }

    setStackPrivateLoader(false);
  };

  const selectStackImage = (stack_image) => {
    selectStackImageOs(stack_image);
    var stackImage = document.querySelector("#main_stackimage");
    if (stackImage) {
      stackImage.value = stack_image;
    }
  }
  useEffect(() => {
    if (stackPublicData != null) {
      selectDeployStack();
    }
  }, [stackPublicData])

  useEffect(() => {
    if (stackPrivateData != null) {
      selectDeployStack();
    }
  }, [stackPrivateData])

  const [currMarketplace, setCurrMarketplace] = useState(null);
  const [currMarketplaceDistro, setCurrMarketplaceDistro] = useState(null);
  const [currMarketplaceLoader, setCurrMarketplaceLoader] = useState(false);

  const [currMarketplacePublic, setCurrMarketplacePublic] = useState(null);
  const [currMarketplaceDistroPublic, setCurrMarketplaceDistroPublic] = useState(null);
  const [currMarketplaceLoaderPublic, setCurrMarketplaceLoaderPublic] = useState(false);

  const [currMarketplacePrivate, setCurrMarketplacePrivate] = useState(null);
  const [currMarketplaceDistroPrivate, setCurrMarketplaceDistroPrivate] = useState(null);
  const [currMarketplaceLoaderPrivate, setCurrMarketplaceLoaderPrivate] = useState(false);


  const selectStackImageOs = (stackImageOS) => {
    var select_image = document.querySelector("#select_image");
    if (select_image) {
      if (props) {
        if (props.deployInfo) {
          props.deployInfo[0].select_image = stackImageOS;
        }
      }
      select_image.value = stackImageOS;
    }
  }


  const selectMarketPlace = async (action, id) => {
    MarkRibbon("marketplace", id);
    var mainStack = document.querySelector("#main_stack");
    var select_image = document.querySelector("#select_image");
    selectOsApp("marketplace", id);
    if (props) {
      const os_cost = document.querySelector("#os_cost");
      os_cost.value = 0;
      if (props.deployInfo) {
        props.deployInfo[0].marketplaceid = id;
        props.deployInfo[0].isoid = 0;
        props.deployInfo[0].osid = 0;
        props.deployInfo[0].snapid = 0;
        props.deployInfo[0].backupid = 0;
        props.deployInfo[0].select_image = "";
        props.deployInfo[0].os_cost = 0;
      }
    }
    const all_versionText = document.getElementsByClassName(
      "osversionselectmultiple"
    );
    if (all_versionText) {
      for (var i = 0; i < all_versionText.length; i++) {
        all_versionText[i].innerHTML = "Select Version";
      }
    }

    var os_main = document.querySelector("#os_main");
    if (os_main) {
      os_main.value = "";
    }
    if (mainStack && select_image) {
      mainStack.value = id;
      setCurrMarketplaceLoader(true);
      // const marketData = await UseApi("get", "stacks", "nothing");
      var marketData = null;
      var market_fields = document.querySelector("#market_fields");
      var market_fields_public = document.querySelector("#market_fields_public");
      var market_fields_private = document.querySelector("#market_fields_private");
      if (market_fields && market_fields_public && market_fields_private) {
        if (action == "stack") {
          marketData = marketplaceData;
          if (marketData) {
            market_fields.classList.remove("d-none");
            market_fields_public.classList.add("d-none");
            market_fields_private.classList.add("d-none");
            setCurrMarketplaceLoader(false);
            if (marketData.length != 0) {
              marketData.map((value, index) => {
                if (value.id == id) {
                  if (value.distro.length == 0) {
                    setCurrMarketplaceDistro([]);
                  } else {
                    if (props) {
                      if (props.deployInfo) {
                        props.deployInfo[0].select_image = value.distro[0];
                      }
                    }
                    select_image.value = value.distro[0];
                    setCurrMarketplaceDistro(value.distro);
                  }
                  if (value.fields.length == 0) {
                    setCurrMarketplace([]);
                  } else {
                    setCurrMarketplace(value.fields);
                  }
                }
              });
            }
          }
        } else if (action == "public") {
          marketData = stackPublicData;
          if (marketData) {
            market_fields.classList.add("d-none");
            market_fields_public.classList.remove("d-none");
            market_fields_private.classList.add("d-none");
            setCurrMarketplaceLoaderPublic(false);
            if (marketData.length != 0) {
              marketData.map((value, index) => {
                if (value.id == id) {
                  if (value.distro.length == 0) {
                    setCurrMarketplaceDistroPublic([]);
                  } else {
                    if (props) {
                      if (props.deployInfo) {
                        props.deployInfo[0].select_image = value.distro[0];
                      }
                    }
                    select_image.value = value.distro[0];
                    setCurrMarketplaceDistroPublic(value.distro);
                  }
                  if (value.fields.length == 0) {
                    setCurrMarketplacePublic([]);
                  } else {
                    setCurrMarketplacePublic(value.fields);
                  }
                }
              });
            }
          }
        } else if (action == "private") {
          marketData = stackPrivateData;
          if (marketData) {
            market_fields.classList.add("d-none");
            market_fields_public.classList.add("d-none");
            market_fields_private.classList.remove("d-none");
            setCurrMarketplaceLoaderPrivate(false);
            if (marketData.length != 0) {
              marketData.map((value, index) => {
                if (value.id == id) {
                  if (value.distro.length == 0) {
                    setCurrMarketplaceDistroPrivate([]);
                  } else {
                    if (props) {
                      if (props.deployInfo) {
                        props.deployInfo[0].select_image = value.distro[0];
                      }
                    }
                    select_image.value = value.distro[0];
                    setCurrMarketplaceDistroPrivate(value.distro);
                  }
                  if (value.fields.length == 0) {
                    setCurrMarketplacePrivate([]);
                  } else {
                    setCurrMarketplacePrivate(value.fields);
                  }
                }
              });
            }
          }
        }
      }
    }
    cloudCost();
  };

  function updatedPlanInfo(plan = []) {
    const current_plan_id = document.querySelector("#current_plan_id");
    const current_plan_price = document.querySelector("#current_plan_price");
    const billing_info = document.querySelector("#billing_info");
    const plan_value = document.querySelector("#plan_value");
    const plan_cost = document.querySelector("#plan_cost");
    const plan_final_cost = document.querySelector("#plan_final_cost");

    if (
      current_plan_id &&
      current_plan_price &&
      billing_info &&
      plan_value &&
      plan_cost
    ) {
      current_plan_id.value = plan.planid;
      current_plan_price.value = plan.plan_org_value;
      billing_info.value = plan.billing;
      plan_value.value = plan.planid;
      plan_cost.value = plan.plan_org_value;
      plan_final_cost.value = plan.payable_price;
    }
    if (props.deployInfo) {
      props.deployInfo[0].plan_cost = plan.plan_org_value;
      props.deployInfo[0].plan_value = plan.planid;
      props.deployInfo[0].billing_info = plan.billing;
      props.deployInfo[0].plan_final_cost = plan.payable_price;
      props.deployInfo[0].plan_cost_without_discount =
        plan.plan_cost_without_discount;
      props.deployInfo[0].planDiscount = plan.plan_discount;
    }
    cloudCost();
  }

  function updateServerManagement(type, cost) {
    if (props.deployInfo) {
      var set_support_field = document.querySelector("#set_support_field");
      set_support_field.value = type;
      props.deployInfo[0].select_support_option = type;
      props.deployInfo[0].select_support_option_cost = cost;
    }
    cloudCost();
    // console.log("type : " + type + " <=> cost : " + cost);
  }

  function updateBackup(backupValue) {
    if (props.deployInfo) {
      var set_enablebackup_field = document.querySelector(
        "#set_enablebackup_field"
      );
      props.deployInfo[0].isBackup = backupValue;
      set_enablebackup_field.value = backupValue;
    }
    cloudCost();
  }

  function showAuth(action) {
    var sshSec = document.querySelector("#ssh-sec");
    var passwordSec = document.querySelector("#password-sec");
    if (sshSec && passwordSec) {
      if (action == "ssh") {
        sshSec.classList.remove("d-none");
        passwordSec.classList.add("d-none");
      } else {
        sshSec.classList.add("d-none");
        passwordSec.classList.remove("d-none");
      }
    }
  }
  const validate = (value) => {
    const password_strength = document.querySelector("#password_strength");
    const password_weak = document.querySelector("#password_weak");
    if (
      validator.isStrongPassword(value, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      password_strength.classList.remove("d-none");
      password_weak.classList.add("d-none");
    } else {
      password_strength.classList.add("d-none");
      password_weak.classList.remove("d-none");
    }
  };

  const [selectedOption, setSelectedOption] = useState("option1");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const selectOsDiskSize = (size) => {
    setOsDiskSize(0);
    if (props) {
      if (props.deployInfo) {
        props.deployInfo[0].os_disk_size = size;
        setTimeout(() => {
          setOsDiskSize(size);
        }, 1000)

      }
    }
  }

  useEffect(() => {
    if (props) {
      if (props.deployInfo) {
        setOsDiskSize(props.deployInfo[0].os_disk_size);
      }
    }
  }, [])

  const showPassword = (action) => {
    var password_field = document.querySelector("#password");
    var eye_close = document.querySelector("#eye-close");
    var eye_open = document.querySelector("#eye-open");
    if (eye_close && eye_open) {
      if (action == "close") {
        eye_close.classList.remove("d-none");
        eye_open.classList.add("d-none");
        password_field.type = "password";
      } else {
        eye_close.classList.add("d-none");
        eye_open.classList.remove("d-none");
        password_field.type = "text";
      }
    }
  }

  const generatePassword = () => {
    showPassword('open');
    var password_field = document.querySelector("#password");
    var password = makeid(20);
    if (password_field) {
      password_field.value = password;
    }
  }

  const removeDiscount = () => {
    var removeDiscountBtn = document.querySelector('#removeCouponBtn');
    var couponBtn = document.querySelector('#couponBtn');

    var couponSec = document.querySelector("#couponSec");
    if (couponSec) {
      couponSec.classList.add("d-none");
    }
    var couponSecBtn = document.querySelector("#couponSecBtn");
    if (couponSecBtn) {
      couponSecBtn.classList.remove("d-none");
    }

    if (couponBtn) {
      couponBtn.classList.remove("d-none");
    }
    if (removeDiscountBtn) {
      removeDiscountBtn.classList.add('d-none');
    }
    if (props) {
      if (props.deployInfo) {
        props.deployInfo[0].coupon = "";
      }
    }
    var couponId = document.querySelector("#couponId");
    if (couponId) {
      couponId.value = "";
    }

    setPlansDiscount(null);

    setTimeout(function () {
      setPlansDiscount({ hourly: 0, monthly: 0, month3: 0, month6: 0, month12: 0 });
      cloudCost();
    }, 1000)
  }

  const toggleCoupon = () => {
    var couponSec = document.querySelector("#couponSec");
    if (couponSec) {
      couponSec.classList.toggle("d-none");
    }
    var couponSecBtn = document.querySelector("#couponSecBtn");
    if (couponSecBtn) {
      couponSecBtn.classList.toggle("d-none");
    }
  }
  const applyCoupon = async () => {

    var couponValue = 'uthodeploy';

    var couponId = document.querySelector("#couponId");
    if (couponId) {
      if (couponId.value != 'uthodeploy') {
        var dt = toast.loading("Coupon applying, Please wait...");
        couponValue = couponId.value;
      }
    }
    setPlansDiscount(null);
    const responseData = await UsePostData('post', 'coupon', { code: couponValue });
    if (responseData != null) {
      if (responseData.status == "success") {
        if (couponId && couponId.value != 'uthodeploy') {
          toast.update(dt, {
            render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        }
        if (props) {
          if (props.deployInfo) {
            props.deployInfo[0].coupon = couponValue;
          }
        }
        if (responseData.type == 'percentage' && responseData.condition_values) {
          var monthlyValue = responseData.condition_values.monthly ?? 0;
          var month3Value = responseData.condition_values._3month ?? 0;
          var month6Value = responseData.condition_values._6month ?? 0;
          var month12Value = responseData.condition_values._12month ?? 0;

          setPlansDiscount({ hourly: 0, monthly: monthlyValue, month3: month3Value, month6: month6Value, month12: month12Value });
        }

        var removeDiscountBtn = document.querySelector('#removeCouponBtn');
        var couponBtn = document.querySelector('#couponBtn');

        if (couponBtn) {
          couponBtn.classList.add("d-none");
        }
        if (removeDiscountBtn) {
          removeDiscountBtn.classList.remove('d-none');
        }


      } else {
        if (couponId && couponId.value != 'uthodeploy') {
          toast.update(dt, {
            render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        }
        setPlansDiscount({ hourly: 0, monthly: 0, month3: 0, month6: 0, month12: 0 });
        setTimeout(() => {
          removeDiscount();
        }, 1000)
      }
    } else {
      removeDiscount();
      toast.update(dt, {
        render: "Coupon is invalid or expired.", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
    }
  }
  useEffect(() => {
    applyCoupon();
    const form = document.getElementById('clouddeploy');
    if (form) {
      form.reset();
    }
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', function () {
      var sideCheckout = document.querySelector('#sideCheckout');
      if (window.scrollY > 103) { // Adjust scroll value as needed
        if (sideCheckout) {
          sideCheckout.classList.add("mhc_checkout-margin-top");
        }
      } else {
        if (sideCheckout) {
          sideCheckout.classList.remove("mhc_checkout-margin-top");
        }
      }
    });
  }, [])

  function manageStorage(action, data) {
    const storage = props?.deployInfo[0]?.storage;
    if (action == 'create') {
      storage.push(data);
    } else if (action == 'update') {
      const index = storage.findIndex(item => item.id === data.id);
      if (index !== -1) {
        storage[index] = { ...storage[index], ...data };
      }
    } else if (action == 'delete') {
      const index = storage.findIndex(item => item.id === data);
      if (index !== -1) {
        storage.splice(index, 1);
      }
    }

    props.deployInfo[0].storage = storage;

  }

  const getFreshStorage = () => {
    return props.deployInfo[0].storage;
  }

  const changeStorageType = (type) => {
    var storageArrr = ['general', 'ebs'];
    storageArrr.map((value, index) => {
      var strType = document.querySelector("#label-" + value);
      if (strType) {
        strType.classList.remove("active");
        if (value == type) {
          strType.classList.add("active");
        }
      }
    })
    if (type == 'ebs') {
      setStorageType('ebs');
      if (ebsPlanData) {
        var firstIndex = 0;
        ebsPlanData.map((value, index) => {
          if (value.slug == 'basic') {
            if (firstIndex == 0) {
              props.deployInfo[0].plan_value = value.id;
            }
            firstIndex++;
          }
        })
      }

      // var tempEbsPlan = ebsPlanData; 
      // var tempGeneralPlan = planData; 
      // setEbsPlanData(null); 
      // setPlanData(null);  
      setIsPlanSet(false);
      setTimeout(() => {
        setIsPlanSet(true);
        // setPlanData(tempGeneralPlan);  
        // setEbsPlanData(tempEbsPlan); 
      }, 1000)
    } else {
      setStorageType('general');
      if (planData) {
        var firstIndex = 0;
        planData.map((value, index) => {
          if (value.slug == 'basic') {
            if (firstIndex == 0) {
              props.deployInfo[0].plan_value = value.id;
            }
            firstIndex++;
          }
        })
      }
      // var tempEbsPlan = ebsPlanData; 
      // var tempGeneralPlan = planData; 
      // setEbsPlanData(null); 
      // setPlanData(null); 
      setIsPlanSet(false);
      setTimeout(() => {
        setIsPlanSet(true);
        // setPlanData(tempGeneralPlan);  
        // setEbsPlanData(tempEbsPlan); 
      }, 1000)
    }


    updateEbsPriceCost();
  }
  const calcEbsTotalCost = () => {
    var ebsPrice = 1.5;
    // Get all input elements on the page
    const allInputs = document.querySelectorAll('input');

    // Filter inputs to find those with a specific name pattern
    const inputsWithPattern = Array.from(allInputs).filter(input => {
      const name = input.getAttribute('name');
      return name && name.startsWith('ebs[') && name.endsWith('][disk]');
    });

    const totalEbs = inputsWithPattern
      .map(input => parseFloat(input.value) || 0) // Convert value to a number, defaulting to 0 if not a number
      .reduce((total, value) => total + value, 0); // Sum all the values

    var totalCost = (totalEbs * ebsPrice).toFixed(1)
    return totalCost;
  }
  const updateEbsPriceCost = () => {

    var ebsPrice = 1.5;
    if (storageType == "ebs") {
      // Get all input elements on the page
      const allInputs = document.querySelectorAll('input');

      // Filter inputs to find those with a specific name pattern
      const inputsWithPattern = Array.from(allInputs).filter(input => {
        const name = input.getAttribute('name');
        return name && name.startsWith('ebs[') && name.endsWith('][disk]');
      });

      var ebsPriceSec = document.querySelector("#ebs_price_sec");
      var ebsCostPrice = document.querySelector("#ebs_cost_price");
      var ebsTotalCost = document.querySelector("#ebsTotalCost");

      if (ebsPriceSec && ebsCostPrice && ebsTotalCost) {
        if (inputsWithPattern.length > 0) {
          const totalEbs = inputsWithPattern
            .map(input => parseFloat(input.value) || 0) // Convert value to a number, defaulting to 0 if not a number
            .reduce((total, value) => total + value, 0); // Sum all the values

          var totalCost = (totalEbs * ebsPrice).toFixed(1);
          ebsPriceSec.classList.remove("d-none");
          ebsCostPrice.innerHTML = totalCost;
          ebsTotalCost.value = totalCost;
        } else {
          ebsPriceSec.classList.add("d-none");
          ebsCostPrice.innerHTML = "0";
          ebsTotalCost.value = 0;
        }
      }

    } else {
      var ebsPriceSec = document.querySelector("#ebs_price_sec");
      var ebsCostPrice = document.querySelector("#ebs_cost_price");
      var ebsTotalCost = document.querySelector("#ebsTotalCost");

      if (ebsPriceSec && ebsCostPrice && ebsTotalCost) {
        ebsPriceSec.classList.add("d-none");
        ebsCostPrice.innerHTML = "0";
        ebsTotalCost.value = 0;
      }
    }

    cloudCost();
  }

  useEffect(() => {
    updateEbsPriceCost();
  }, [storageType])
  console.log(recommendedCpu);

  return (
    <>
      {props && props.deployInfo && adcdata != null && distroData != null ? (
        <>
          <ChangeScreen />
          <form id="clouddeploy">
            <div className="row">
              <div className="col-xl-9">
                {/*begin::Tables Widget 9*/}
                <div className="mb-5 mb-xl-12">
                  <div className="col-xl-12 card mb-5">
                    {/*begin::data center*/}
                    <Dczone adcdata={adcdata} ddcdata={ddcdata} selectDc={selectDc} location={location} setRecommendedCpu={setRecommendedCpu} />
                    {/* End data center */}
                  </div>
                  {/*begin::Col*/}
                  <div className="col-xl-12 card mb-5">
                    {/*begin::List widget 10*/}
                    {/*begin::Header*/}
                    <div className="card-header border-3 mb-5 pt-2 p-0 ps-6">
                      <input
                        className="btn-check"
                        type="hidden"
                        name="imageOS"
                        id="os_main"
                      />
                      {/*begin::Title*/}
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bolder text-gray-800">
                          Select OS/APPS
                        </span>
                        <span className="text-gray-400 mt-1 fw-bold fs-6">
                          Operating System, Marketplace, Stacks, ISO, Snapshot, Backups
                        </span>
                      </h3>
                      {/*end::Title*/}
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="mx-5 mb-3">
                      {/*begin::Nav*/}
                      <ul className="mx-2 nav nav-stretch nav-line-tabs nav-line-tabs-2x border-trakt_charts_widget_1_chartnsparent fs-5 fw-bolder">
                        <li className="nav-item">
                          <a
                            className="nav-link text-active-primary py-5 me-6 active"
                            data-bs-toggle="tab"
                            href="#distros-tabs"
                          >
                            Operating System
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link text-active-primary py-5 me-6"
                            data-bs-toggle="tab"
                            href="#marketplace-tab"
                          >
                            Marketplace
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link text-active-primary py-5 me-6"
                            data-bs-toggle="tab"
                            href="#stack-tab"
                            id="stack_nav"
                          >
                            Stacks
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link text-active-primary py-5 me-6"
                            data-bs-toggle="tab"
                            href="#iso-tabs"
                          >
                            ISO
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link text-active-primary py-5 me-6"
                            data-bs-toggle="tab"
                            href="#snapshot-tabs"
                          >
                            Snapshots
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link text-active-primary py-5 me-6"
                            data-bs-toggle="tab"
                            href="#backups-tabs"
                          >
                            Backups
                          </a>
                        </li>
                      </ul>

                      {/*end::Nav*/}
                      {/*begin::Tab Content*/}
                      <div className="tab-content mt-5 pe-8">
                        {/*begin:: Disto*/}
                        <div
                          className="tab-pane fade show active"
                          id="distros-tabs"
                        >
                          {/*begin::Row*/}
                          <input
                            type="hidden"
                            className="form-control"
                            id="os_cost"
                            defaultValue={
                              props.deployInfo && props.deployInfo
                                ? props.deployInfo[0].os_cost
                                : ""
                            }
                          />

                          <DistroTab osid={props?.deployInfo[0]?.osid} currentImage={props?.deployInfo[0]?.select_image} currentImageCost={props?.deployInfo[0]?.os_cost} currentDistribution={props?.deployInfo[0]?.distribution} distroData={distroData} userData={userData} selectOne={selectOne} selectOs={selectOs} />

                          {/*end::Row*/}
                          <input
                            type="hidden"
                            name="image"
                            id="select_image"
                            defaultValue={
                              props.deployInfo && props.deployInfo
                                ? props.deployInfo[0].select_image
                                : ""
                            }
                            className="deploy_checkbox image_field"
                          />
                        </div>
                        {/*end:: Distro*/}
                        {/*begin::Tap pane*/}
                        <div className="tab-pane fade" id="iso-tabs">
                          <div
                            id="getiso"
                            className="row g-9"
                            data-kt-buttons="true"
                            data-kt-buttons-target="[data-kt-button]"
                          >
                            <input
                              type="hidden"
                              name="iso"
                              defaultValue={
                                props.deployInfo && props.deployInfo
                                  ? props.deployInfo[0].iso
                                  : ""
                              }
                              id="iso_field"
                            />
                            {isoLoader == true ? (
                              <>
                                <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                                  {/* <div className="small-spinner"></div> */}
                                  <TableLoader />
                                </div>
                              </>
                            ) : (
                              <>
                                <Iso iso={props?.deployInfo[0]?.iso} isoid={props?.deployInfo[0]?.isoid} isoData={isoData} selectIso={selectIso} />
                              </>
                            )}
                          </div>
                        </div>
                        {/*end::Tap pane*/}
                        {/*begin::Tap pane*/}
                        <div className="tab-pane fade" id="snapshot-tabs">
                          <div
                            id="getsnapshot"
                            className="row g-9"
                            data-kt-buttons="true"
                          >
                            <input
                              type="hidden"
                              name="snapshotid"
                              defaultValue={
                                props.deployInfo && props.deployInfo
                                  ? props.deployInfo[0].snapshot
                                  : ""
                              }
                              id="snapshot_field"
                            />
                            {snapLoader == true ? (
                              <>
                                <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                                  {/* <div className="small-spinner"></div> */}
                                  <TableLoader />
                                </div>
                              </>
                            ) : (
                              <>
                                <Snapshots snapid={props?.deployInfo[0]?.snapid} os_disk_size={props?.deployInfo[0]?.os_disk_size} snapData={snapData} selectSnapshot={selectSnapshot} />
                              </>
                            )}
                          </div>
                        </div>
                        {/*end::Tap pane*/}
                        {/*begin::Tap pane*/}
                        <div className="tab-pane fade" id="backups-tabs">
                          <div
                            id="getbackup"
                            className="row g-9"
                            data-kt-buttons="true"
                          >
                            <input
                              type="hidden"
                              name="backupid"
                              defaultValue={
                                props.deployInfo && props.deployInfo
                                  ? props.deployInfo[0].backup
                                  : ""
                              }
                              id="backup_field"
                            />
                            {backupLoader == true ? (
                              <>
                                <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                                  {/* <div className="small-spinner"></div> */}
                                  <TableLoader />
                                </div>
                              </>
                            ) : (
                              <>
                                <Backups backupid={props?.deployInfo[0]?.backupid} os_disk_size={props?.deployInfo[0]?.os_disk_size} backupData={backupData} selectBackup={selectBackup} />
                              </>
                            )}
                          </div>
                        </div>
                        {/*end::Tap pane*/}
                        {/*begin::Tap pane*/}
                        <div className="tab-pane fade" id="marketplace-tab">
                          {/*begin::Row*/}
                          <input type="hidden" name="stack" id="main_stack" />
                          <input type="hidden" name="stackimage" id="main_stackimage" />
                          <div
                            id="marketplace-data"
                            className="row g-9"
                            data-kt-buttons="true"
                            data-kt-buttons-target="[data-kt-button]"
                          >
                            {/*begin::Col*/}
                            {marketplaceLoader == false ? (
                              <>
                                <Marketplace marketplaceData={marketplaceData} marketplaceid={props?.deployInfo[0]?.marketplaceid} selectOne={selectOne} selectMarketPlace={selectMarketPlace} />
                              </>
                            ) : (
                              <>
                                <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                                  {/* <div className="small-spinner"></div> */}
                                  <TableLoader />
                                </div>
                              </>
                            )}

                            {/*end::Col*/}
                          </div>
                          {/*begin:: marketplace fields*/}
                          <div
                            className="col-xl-12 mb-xl-12 mt-10"
                            id="market_fields"
                          >
                            {currMarketplaceLoader == false ? (
                              <>
                                <MarketplaceFields currMarketplaceDistro={currMarketplaceDistro} currMarketplace={currMarketplace} selectStackImage={selectStackImage} />
                              </>
                            ) : ('')}
                          </div>
                          {/*end::marketplace fields*/}
                          {/*end::Row*/}
                        </div>
                        {/*end::Tap pane*/}
                        {/*begin::Tap pane*/}
                        <div className="tab-pane fade" id="stack-tab">
                          {stackPrivateData && stackPublicData ? (
                            <Stacks
                              stackPrivateData={stackPrivateData}
                              stackPublicData={stackPublicData}
                              currMarketplaceDistroPrivate={currMarketplaceDistroPrivate}
                              currMarketplaceDistroPublic={currMarketplaceDistroPublic}
                              currMarketplacePrivate={currMarketplacePrivate}
                              currMarketplacePublic={currMarketplacePublic}
                              selectOne={selectOne}
                              selectMarketPlace={selectMarketPlace}
                              selectStackImage={selectStackImage}
                            />
                          ) : (<TableLoader />)}
                          {/*end::marketplace fields*/}
                        </div>
                        {/*end::Tap pane*/}
                      </div>
                      {/*end::Tab Content*/}
                    </div>
                    {/*end: Card Body*/}
                    {/*end::List widget 10*/}
                  </div>
                  {/*end::Col*/}
                  {ebsEnabled == 'yes' ? (<>
                    <div className="col-xl-12 card mb-5">
                      {/*begin::List widget 10*/}
                      {/*begin::Header*/}
                      <div className="card-header border-3 mb-5 pt-2 p-0 ps-6">
                        {/*begin::Title*/}
                        <h3 className="card-title align-items-start flex-column">
                          <span className="card-label fw-bolder text-gray-800">
                            Select Storage Type
                          </span>
                          <span className="text-gray-400 mt-1 fw-bold fs-6">
                            General Storage/ EBS Storage
                          </span>
                        </h3>
                      </div>

                      {/*begin::Radio group*/}
                      <div
                        className="btn-group mx-5 pb-5 custom-grid"
                        data-kt-buttons="true"
                        data-kt-buttons-target="[data-kt-button]"
                      >
                        {/*begin::Radio*/}
                        <label
                          onClick={(e) => changeStorageType("general")}
                          className="text-center btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-primary active"
                          data-kt-button="true"
                          id="label-general"
                          style={{ borderRadius: '0.4rem 0rem 0rem 0.4rem' }}
                        >
                          General
                        </label>
                        {/*end::Radio*/}
                        {/*begin::Radio*/}
                        <label
                          onClick={(e) => changeStorageType("ebs")}
                          className="text-center btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-primary"
                          data-kt-button="true"
                          id="label-ebs"
                        >
                          EBS (Elastic Block Storage)
                        </label>
                        {/*end::Radio*/}
                      </div>
                      {/*end::Radio group*/}
                    </div>
                  </>) : ('')}
                  {/*begin::Col*/}
                  <input
                    type="hidden"
                    name="planid"
                    id="plan_value"
                    defaultValue={
                      props.deployInfo && props.deployInfo
                        ? props.deployInfo[0].plan_value
                        : ""
                    }
                  />
                  <input
                    type="hidden"
                    id="plan_cost"
                    defaultValue={
                      props.deployInfo && props.deployInfo
                        ? props.deployInfo[0].plan_cost
                        : ""
                    }
                  />
                  <input
                    type="hidden"
                    id="plan_final_cost"
                    defaultValue={
                      props.deployInfo && props.deployInfo
                        ? props.deployInfo[0].plan_final_cost
                        : ""
                    }
                  />

                  <input type="hidden" value="" id="current_plan_id" />
                  <input type="hidden" value="" id="current_plan_price" />

                  {isPlanSet == true ? (<>
                    {planData != null || ebsPlanData != null ? (
                      // osDiskSize >0 &&
                      // props &&
                      // props.deployInfo ? (
                      <>
                        {plansDiscount != null ? (<>
                          {storageType == "general" ? (<>
                            <PricingContainer
                              updatePlan={updatedPlanInfo}
                              currBilling={props.deployInfo[0].billing_info}
                              currPlanid={props.deployInfo[0].plan_value}
                              dcLocation={props.deployInfo[0].dc_location}
                              discounts={plansDiscount}
                              // disk_size={osDiskSize}
                              disk_size={0}
                              user={userData != null ? (<>{userData}</>) : ('')}
                              plans={planData}
                            />
                          </>) : (<>
                            <PricingContainer
                              updatePlan={updatedPlanInfo}
                              currBilling={props.deployInfo[0].billing_info}
                              currPlanid={props.deployInfo[0].plan_value}
                              dcLocation={props.deployInfo[0].dc_location}
                              discounts={plansDiscount}
                              // disk_size={osDiskSize}
                              disk_size={0}
                              user={userData != null ? (<>{userData}</>) : ('')}
                              plans={ebsPlanData}
                            />
                          </>)}
                        </>) : (<></>)}
                      </>
                    ) : (<>
                      <div className="col-xl-12 card mb-5">
                        <div className="card-header border-3 mb-5 pt-2 p-0 ps-6">
                          {/*begin::Title*/}
                          <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bolder text-gray-800">
                              Select Plan Type
                              {/* &nbsp;{planData.length}&nbsp;-&nbsp; {ebsPlanData.length} */}
                            </span>
                            <span className="text-gray-400 mt-1 fw-bold fs-6">
                              Basic/CPU/Memory Optimized
                            </span>
                          </h3>
                        </div>
                        <div className="mx-5 mb-20">
                          {/* <div className="small-spinner"></div> */}
                          <TableLoader />
                        </div>
                      </div></>)}
                  </>) : (<>
                    <div className="col-xl-12 card mb-5">
                      <div className="card-header border-3 mb-5 pt-2 p-0 ps-6">
                        {/*begin::Title*/}
                        <h3 className="card-title align-items-start flex-column">
                          <span className="card-label fw-bolder text-gray-800">
                            Select Plan Type
                            {/* &nbsp;{planData.length}&nbsp;-&nbsp; {ebsPlanData.length} */}
                          </span>
                          <span className="text-gray-400 mt-1 fw-bold fs-6">
                            Basic/CPU/Memory Optimized
                          </span>
                        </h3>
                      </div>
                      <div className="mx-5 mb-20">
                        {/* <div className="small-spinner"></div> */}
                        <TableLoader />
                      </div>
                    </div>
                  </>)}
                  {/*end::Col*/}
                  <input type="hidden" value="0" id="ebsTotalCost" />
                  {storageType == 'ebs' ? (<>
                    <Storage getFreshStorage={getFreshStorage} updateEbsStorage={updateEbsPriceCost} manageStorage={manageStorage} storage={props?.deployInfo[0]?.storage ?? []} distro={distroData ?? []} />
                  </>) : ('')}
                  {/*begin::Col*/}
                  {showSSH == true ? (<>
                    <div className="card border-3 mb-5 pt-2">
                      {/*begin::Header*/}
                      <div className="card-header border-3 mb-5 pt-2 p-0 ps-6">
                        {/*begin::Title*/}
                        <h3 className="card-title align-items-start flex-column">
                          <span className="card-label fw-bolder text-gray-800">
                            Auth Configuration
                          </span>
                          <span className="text-gray-400 mt-1 fw-bold fs-6">
                            Setup auth configuration
                          </span>
                        </h3>

                        {/*end::Title*/}
                      </div>
                      {/*end::Header*/}
                      <div className="p-2 border-3 pt-2">
                        {/*begin::Radio group*/}
                        <div data-kt-buttons="true" className="row p-3">
                          <div className="col-6">
                            {/*begin::Radio button*/}
                            <label
                              onClick={(e) => showAuth("password")}
                              className="btn btn-outline btn-outline-dashed d-flex flex-stack text-start p-6 mb-5"
                              style={{ height: "70px" }}
                            >
                              {/*end::Description*/}
                              <div className="d-flex align-items-center me-2">
                                {/*begin::Radio*/}
                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="auth"
                                    value="option1"
                                    checked={selectedOption === "option1"}
                                    onChange={handleOptionChange}
                                  />
                                </div>
                                {/*end::Radio*/}
                                {/*begin::Info*/}
                                <div className="flex-grow-1">
                                  <h2 className="d-flex align-items-center fs-3 fw-bolder flex-wrap">
                                    Password
                                  </h2>
                                  <div className="fw-bold opacity-50">
                                    Setup password to Cloud Instance
                                  </div>
                                </div>
                                {/*end::Info*/}
                              </div>
                              {/*end::Description*/}
                            </label>
                            {/*end::Radio button*/}
                          </div>
                          <div className="col-6">
                            {/*begin::Radio button*/}
                            <label
                              onClick={(e) => showAuth("ssh")}
                              className="btn btn-outline btn-outline-dashed d-flex flex-stack text-start p-6"
                              style={{ height: "70px" }}
                            >
                              {/*end::Description*/}
                              <div className="d-flex align-items-center me-2">
                                {/*begin::Radio*/}
                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="auth"
                                    value="option2"
                                    checked={selectedOption === "option2"}
                                    onChange={handleOptionChange}
                                  />
                                </div>
                                {/*end::Radio*/}
                                {/*begin::Info*/}
                                <div className="flex-grow-1">
                                  <h2 className="d-flex align-items-center fs-3 fw-bolder flex-wrap">
                                    SSH Keys
                                  </h2>
                                  <div className="fw-bold opacity-50">
                                    Deploy Cloud Instance via SSH Keys
                                  </div>
                                </div>
                                {/*end::Info*/}
                              </div>
                              {/*end::Description*/}
                            </label>
                            {/*end::Radio button*/}
                          </div>
                        </div>
                        {/*end::Radio group*/}
                      </div>
                      {/*begin::List widget 10*/}

                      <div className="col-xl-12 card mb-5 d-none" id="ssh-sec">
                        {/*begin::Header*/}
                        <div className="card-header border-3 mb-5 pt-2 d-none">
                          <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bolder text-gray-800">
                              SSH Keys
                            </span>
                            <span className="text-gray-400 mt-1 fw-bold fs-6">
                              Select SSH Keys for the server
                            </span>
                          </h3>
                        </div>

                        {/*end::Header*/}
                        {/*begin::Body*/}
                        <div className="mx-5 mb-3">
                          {/*begin::Radio group*/}
                          <div
                            id="ssh-data"
                            className="row"
                            data-kt-buttons="true"
                          >
                            {sshData != null &&
                              sshData &&
                              sshData.map((value, index) => (
                                <>
                                  <div className="col-md-4 col-lg-4 col-xxl-4 sshsibling pt-5">
                                    {/*begin::Radio button*/}

                                    <label
                                      htmlFor={"sshkeys" + value.id}
                                      onClick={() =>
                                        selectTickMark(value.id, "Ssh", "sshkeys")
                                      }
                                      className="borderSsh position-relative btn btn-outline btn-outline btn-outline-default d-flex flex-stack text-start"
                                      id={"borderSsh-" + value.id}
                                    >
                                      <input
                                        className="form-check-input deploy_checkbox"
                                        id={"sshkeys" + value.id}
                                        type="radio"
                                        name="sshkeys"
                                        defaultValue={value.id}
                                      />
                                      {/*end::Description*/}
                                      <div className="d-flex align-items-center me-2">
                                        <div className="flex-grow-1">
                                          <div className="fw-bold opacity-50">
                                            {" "}
                                            {value.name}
                                          </div>
                                          {/* <h2 className="d-flex align-items-center fs-3 fw-bolder flex-wrap">

                                                {value.name}
                                              </h2> */}
                                        </div>
                                        {/*end::Info*/}
                                      </div>
                                      {/*end::Description*/} {/*begin::Price*/}
                                      <div className="ms-5">
                                        {/*begin::Svg Icon | path: icons/duotune/communication/com003.svg*/}
                                        <span className="svg-icon svg-icon-primary svg-icon-3x me-4">
                                          <svg
                                            version="1.1"
                                            id="Capa_1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            x="0px"
                                            y="0px"
                                            viewBox="0 0 299.32 299.32"
                                            style={{
                                              enableBackground:
                                                "new 0 0 299.32 299.32",
                                            }}
                                            xmlSpace="preserve"
                                          >
                                            <g>
                                              <path d="M59.52,128.087c-11.895,0-21.572,9.678-21.572,21.573s9.678,21.573,21.572,21.573s21.572-9.678,21.572-21.573 S71.414,128.087,59.52,128.087z M59.52,156.233c-3.624,0-6.572-2.949-6.572-6.573s2.948-6.573,6.572-6.573s6.572,2.949,6.572,6.573 S63.144,156.233,59.52,156.233z" />
                                              <path d="M297.349,150.704l-19.257-24.072c-1.708-2.135-4.294-3.378-7.028-3.378H151.472c-10.746-30.638-40.05-51.476-73.591-51.476 C34.938,71.778,0,106.716,0,149.66s34.938,77.882,77.881,77.882c28.516,0,53.866-14.475,67.487-38.145h21.145l18.085,18.731 c1.79,1.855,4.297,2.841,6.865,2.74c2.575-0.112,4.979-1.323,6.602-3.326l11.169-13.782l14.784,14.534 c3.48,3.422,9.056,3.445,12.564,0.053l19.604-18.95h14.878c2.734,0,5.32-1.243,7.028-3.378l19.257-24.071 C299.978,158.662,299.978,153.991,297.349,150.704z M266.738,171.397h-14.191c-2.334,0-4.577,0.907-6.255,2.529l-15.912,15.381 l-15.593-15.329c-1.811-1.778-4.28-2.706-6.817-2.568c-2.533,0.144-4.888,1.349-6.484,3.32l-11.003,13.578l-13.675-14.163 c-1.696-1.756-4.033-2.749-6.475-2.749H139.94c-3.465,0-6.622,1.989-8.118,5.115c-9.751,20.374-30.42,33.03-53.941,33.03 C44.862,209.542,18,182.679,18,149.66s26.862-59.882,59.881-59.882c27.695,0,51.613,18.362,58.167,44.653 c0.999,4.009,4.601,6.823,8.732,6.823h121.957l12.058,15.072L266.738,171.397z" />
                                            </g>
                                          </svg>
                                        </span>
                                        {/*end::Svg Icon*/}
                                      </div>
                                      {/*end::Price*/}
                                      <div
                                        className="d-none tickMarkSsh ribbon ribbon-triangle ribbon-top-start border-primary"
                                        id={"tickMarkSsh-" + value.id}
                                      >
                                        <div className="ribbon-icon mt-n5">
                                          <i className="bi bi-check2 fs-2 text-white" />
                                        </div>
                                      </div>
                                    </label>
                                    {/*end::Radio button*/}
                                  </div>
                                </>
                              ))}
                            <a
                              onClick={openDrawer}
                              className="col-md-4 col-lg-4 col-xxl-4 pt-5"
                            >
                              {/*begin::Radio button*/}
                              <label className="btn btn-outline btn-outline btn-outline-default d-flex flex-stack text-start">
                                {/*end::Description*/}
                                <div className="d-flex align-items-center me-2">
                                  {/*begin::Info*/}
                                  <div className="flex-grow-1">
                                    <div className="fw-bold opacity-50">
                                      {" "}
                                      Add New
                                    </div>
                                  </div>
                                  {/*end::Info*/}
                                </div>
                                {/*end::Description*/} {/*begin::Price*/}
                                <div className="ms-5">
                                  {/*begin::Svg Icon | path: icons/duotune/communication/com003.svg*/}
                                  <span className="svg-icon svg-icon-3x me-4">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={24}
                                      height={24}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <rect
                                        opacity="0.3"
                                        x={2}
                                        y={2}
                                        width={20}
                                        height={20}
                                        rx={10}
                                        fill="black"
                                      />
                                      <rect
                                        x="10.8891"
                                        y="17.8033"
                                        width={12}
                                        height={2}
                                        rx={1}
                                        transform="rotate(-90 10.8891 17.8033)"
                                        fill="black"
                                      />
                                      <rect
                                        x="6.01041"
                                        y="10.9247"
                                        width={12}
                                        height={2}
                                        rx={1}
                                        fill="black"
                                      />
                                    </svg>
                                  </span>
                                  {/*end::Svg Icon*/}
                                </div>
                                {/*end::Price*/}
                              </label>
                              {/*end::Radio button*/}
                            </a>
                          </div>
                          {/*end::Radio group*/}
                        </div>
                        {/*end: Card Body*/}
                      </div>
                      <div className="col-xl-12 mb-5" id="password-sec">
                        <div className="card-header border-3 mb-5 pt-2 d-none">
                          <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bolder text-gray-800">
                              Enter Root Password
                            </span>
                            <span className="text-gray-400 mt-1 fw-bold fs-6">
                              Input the root passowrd for your cloud instance
                            </span>
                          </h3>
                        </div>
                        <div className="mx-5 mb-3">
                          <div
                            className="row g-9"
                            data-kt-buttons="true"
                            data-kt-buttons-target="[data-kt-button]"
                          >
                            <div className="col-12">
                              <div className="row mb-1">
                                <div className="col-6">
                                  <div className="position-relative fv-row mb-0">
                                    {/* <br/> <label htmlFor="newpassword" className="form-label fs-6 fw-bolder mb-3"> Enter Password </label> */}
                                    <input
                                      type="password"
                                      className="form-control form-control"
                                      name="root_password"
                                      id="password"
                                      onChange={(e) => validate(e.target.value)}
                                      placeholder="Enter Password"
                                      defaultValue={makeid(20)}
                                    />
                                    <span
                                      className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                                      data-kt-password-meter-control="visibility"
                                    >
                                      <i className="bi bi-eye-slash fs-2 " id="eye-close" onClick={(e) => showPassword("open")} />
                                      <i className="bi bi-eye fs-2 d-none" id="eye-open" onClick={(e) => showPassword("close")} />
                                    </span>
                                  </div>
                                </div>
                                <div className="col-4">
                                  <a onClick={generatePassword} className="btn btn-light-primary">
                                    <span className="svg-icon svg-icon-2">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                      >
                                        <path
                                          d="M14.5 20.7259C14.6 21.2259 14.2 21.826 13.7 21.926C13.2 22.026 12.6 22.0259 12.1 22.0259C9.5 22.0259 6.9 21.0259 5 19.1259C1.4 15.5259 1.09998 9.72592 4.29998 5.82592L5.70001 7.22595C3.30001 10.3259 3.59999 14.8259 6.39999 17.7259C8.19999 19.5259 10.8 20.426 13.4 19.926C13.9 19.826 14.4 20.2259 14.5 20.7259ZM18.4 16.8259L19.8 18.2259C22.9 14.3259 22.7 8.52593 19 4.92593C16.7 2.62593 13.5 1.62594 10.3 2.12594C9.79998 2.22594 9.4 2.72595 9.5 3.22595C9.6 3.72595 10.1 4.12594 10.6 4.02594C13.1 3.62594 15.7 4.42595 17.6 6.22595C20.5 9.22595 20.7 13.7259 18.4 16.8259Z"
                                          fill="black"
                                        />
                                        <path
                                          opacity="0.3"
                                          d="M2 3.62592H7C7.6 3.62592 8 4.02592 8 4.62592V9.62589L2 3.62592ZM16 14.4259V19.4259C16 20.0259 16.4 20.4259 17 20.4259H22L16 14.4259Z"
                                          fill="black"
                                        />
                                      </svg>
                                    </span>
                                    Generate Password</a>
                                </div>
                              </div>
                              <span
                                className="badge badge-light-danger"
                                id="PassValid"
                              />
                              <span
                                className="text-success fw-bold d-none"
                                id="password_strength"
                              >
                                Your password is strong
                              </span>
                              <span
                                className="text-danger fw-bold d-none"
                                id="password_weak"
                              >
                                Please ensure your password meets the following
                                requirements:
                                <ul>
                                  <li>- At least 1 uppercase letter (A-Z)</li>
                                  <li>
                                    - Alphabetic diversity (both lowercase and
                                    uppercase letters)
                                  </li>
                                  <li>- Include numbers (0-9)</li>
                                  <li>- 1 special character from: @ # $ % ...</li>
                                  <li>- Minimum length of 8 characters</li>
                                </ul>
                                Example of a strong password: P@$$w0rd93@mO
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/*end::List widget 10*/}
                    </div>
                  </>) : ('')}

                  {/* <div className="col-xl-12 card mb-5"> */}
                  <PublicIpTab />
                  {vpcLoader != true ? (<>
                    <Vpc vpcData={vpcData} selectTickMark={selectTickMark} vpcd={props.deployInfo[0]} vpcLoader={vpcLoader} setVpcDataId={setVpcId} />
                  </>) : (<><TableLoader /></>)}
                  {/* </div> */}

                  <div className="col-xl-12 card mb-5">
                    {firewallLoader != true ? (<>
                      <Firewall firewallData={firewallData} selectTickMark={selectTickMark} firewalld={props.deployInfo[0]} firewallLoader={firewallLoader} />
                    </>) : (<><TableLoader /></>)}
                    <div className=" row card-body d-none" style={{ paddingLeft: '1.5rem' }}>
                      <div className="col-md-6 col-lg-6 col-xxl-6">
                        <select
                          className="form-select"
                          id="get-firewall-list"
                          name="firewall"
                        >
                          {firewallData != null ?
                            firewallData &&
                            <>
                              <option value='' disabled selected className="text-gray-500">Select Firewall Group</option>
                              {firewallData.map((value, index) => (
                                <option value={value.id}>{value.name}</option>
                              ))}</> : <option>No Firewall Group</option>}
                        </select>
                      </div>
                    </div>
                    {/*end: Card Body*/}
                    {/*end::List widget 10*/}
                  </div>

                  {storageType == "ebs" ? (<>
                    <input
                      type="hidden"
                      name="cpumodel"
                      value="amd"
                    />
                  </>) : (<>
                    <CpuModelTab setCpuValue={setCpuValue} cpuValue={cpuValue} recommendedCpu={recommendedCpu} setRecommendedCpu={setRecommendedCpu} />
                    {/* <input
                      type="hidden"
                      name="cpumodel"
                      value={cpuValue}
                    /> */}
                  </>)}

                  {gpuData != null && gpuData?.length > 0 &&
                    <div className="col-xl-12 card mb-5 d-none">
                      {/*begin::List widget 10*/}
                      {/*begin::Header*/}
                      <div className="card-header pt-7">
                        {/*begin::Title*/}
                        <h3 className="card-title align-items-start flex-column">
                          <span className="card-label fw-bolder text-gray-800">
                            GPU &nbsp;&nbsp;
                            {/* <a target="_blank" href="/firewall">
                            <span className="badge badge-primary">Manage</span>
                          </a> */}
                          </span>
                        </h3>
                        {/*end::Title*/}
                      </div>
                      {/*end::Header*/}
                      {/*begin::Body*/}
                      <div className=" row card-body" style={{ paddingLeft: '1.5rem' }}>
                        <div className="col-md-6 col-lg-6 col-xxl-6">
                          <select
                            className="form-select"
                            id="get-gpus-list"
                            name="gpu"
                          >
                            <option value='' disabled selected>select Gpu Group</option>
                            {gpuData != null &&
                              gpuData &&
                              gpuData.map((value, index) => (
                                <option value={value.id}>{value.model + "-" + value.make} ( {userData?.currencyprefix}{value.cost}) </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      {/*end: Card Body*/}
                      {/*end::List widget 10*/}
                    </div>
                  }
                  <div className="col-xl-12 card mb-5">
                    {/*begin::List widget 10*/}
                    {/*begin::Header*/}
                    <div className="card-header border-3 pt-5 p-0 ps-6">
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bolder fs-3 mb-1">
                          Additional Features
                        </span>
                        <span className="text-muted mt-1 fw-bold fs-7">
                          Auto Backup, Server Management
                        </span>
                      </h3>
                    </div>

                    {/*end::Header*/}
                    {userData != null && props && props.deployInfo ? (
                      <>
                        <input
                          type="hidden"
                          name="enablebackup"
                          id="set_enablebackup_field"
                          defaultValue={props.deployInfo[0].isBackup}
                        />
                        <Backup
                          updateBackupService={updateBackup}
                          isEnabled={props.deployInfo[0].isBackup}
                        />

                        <input
                          type="hidden"
                          name="support"
                          id="set_support_field"
                          defaultValue={
                            props.deployInfo[0].select_support_option
                          }
                        />
                        <ServerManagement
                          updateManageService={updateServerManagement}
                          user={userData}
                        />
                      </>
                    ) : (
                      <>
                        {" "}
                        <div className="mx-5 mb-3">
                          {/* <div className="small-spinner"></div> */}
                          <TableLoader />
                        </div>
                      </>
                    )}

                    <input
                      type="hidden"
                      name="billingcycle"
                      // defaultValue="hourly"
                      defaultValue={
                        props.deployInfo && props.deployInfo
                          ? props.deployInfo[0].billing_info
                          : ""
                      }
                      id="billing_info"
                    />
                  </div>
                  <div className="col-xl-12 card mb-5">
                    {/*begin::List widget 10*/}
                    {/*begin::Header*/}
                    <div className="card-header border-3 pt-4 ps-6 p-0">
                      {/*begin::Title*/}
                      <h3 className="card-title align-items-start text-blue flex-column">
                        <span className="card-label fw-bolder text-gray-800">
                          Hostname &amp; Label
                        </span>
                        <span className="text-muted mt-1 fw-bold fs-7">
                          Name for the server instance
                        </span>
                      </h3>
                      {/*end::Title*/}
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="row pe-5 pb-5" style={{ paddingLeft: '1.5rem' }}>
                      {hostCount != null ? (<>
                        {hostCount.map((value, index) => (
                          <>
                            <div className="col-12 pt-5">
                              <input
                                type="text"
                                className="form-control w-lg-50"
                                name={"cloud[" + index + "][hostname]"}
                                defaultValue={
                                  "cloudserver-" + makeid(8) + ".mhc"
                                }
                                placeholder="host!.cloud"
                              />
                            </div>
                          </>
                        ))}
                      </>) : ('')}
                    </div>
                    {/*end: Card Body*/}
                    {/*end::List widget 10*/}
                  </div>
                  {/*end::Col*/}
                </div>
                {/*end::Tables Widget 9*/}

              </div>
              <div
                className="col-xl-3"
              // style={{
              //   position: "sticky",
              //   bottom: 0,
              //   zIndex: 99,
              //   borderTop: "outset",
              //   borderColor: "#009EF7"
              // }}
              >
                {/*begin::List widget 10*/}
                <div className="card me-5" id="sideCheckout" style={{ overflowY: "auto", maxHeight: "77vh", width: "267.75px" }}>
                  {/*begin::Body*/}
                  <div className="">
                    <div className=" row ps-5 pt-5">
                      <div className=" row">
                        {/*begin::Title*/}
                        <h5 className="card-title align-items-start text-blue flex-column">
                          <span className="card-label fw-bolder text-gray-800">
                            Instance Quantity
                          </span>
                        </h5>
                        {/*end::Title*/}
                        <div className="col-md-3 col-lg-12 col-xxl-3">
                          {/*begin::Dialer*/}
                          <div className="input-group w-md-150px">
                            {/*begin::Decrease control*/}
                            <a
                              className="btn btn-icon btn-outline btn-outline-secondary disabled"
                              onClick={() => {
                                updateHostCount("down");
                                cloudCost();
                              }}
                              id="hostDown"
                            >
                              <i className="bi bi-dash fs-1" />
                            </a>
                            {/*end::Decrease control*/}

                            {/*begin::Input control*/}
                            <input
                              type="text"
                              className="form-control text-center"
                              readOnly={true}
                              defaultValue={1}
                              id="total_chq"
                            />
                            {/*end::Input control*/}
                            {/*begin::Increase control*/}
                            <a
                              className="btn btn-icon btn-outline btn-outline-secondary"
                              onClick={() => {
                                updateHostCount("up");
                                cloudCost();
                              }}
                              id="hostUp"
                            >
                              <i className="bi bi-plus fs-1" />
                            </a>
                            {/*end::Increase control*/}
                          </div>
                          {/*end::Dialer*/}
                        </div>
                      </div>
                      <div></div>
                    </div>
                    {/*end: Card Body*/}

                    {/*end: Card Body*/}
                    <div className="border-0 p-5">
                      {/* <h5 className="card-title align-items-start text-blue flex-column">
          <span className="card-label fw-bolder text-gray-800">
            Server Quantity
          </span>
        </h5> */}
                      <h5 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bolder text-gray-800">
                          Cost Summary
                        </span>
                        <hr />
                        <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2">
                          <thead>
                            <tr className="border-0">
                              <th className="p-0 min-w-100px"></th>
                              <th className="p-0 min-w-100px text-end"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="d-flex justify-content-start flex-column">
                                    <a className="text-gray-800 text-hover-primary fw-bold text-muted d-block fs-7">
                                      <span className="text-gray-800">
                                        Billing Cycle
                                      </span>
                                    </a>
                                  </div>
                                </div>
                              </td>
                              <td className="text-center w-50">
                                <a className="fw-bolder badge badge-success text-hover-white  d-block mb-1 fs-7">
                                  <span id="billing_cycle_value"></span>
                                </a>
                              </td>
                            </tr>
                            <tr className="d-none" id="original_server_cost">
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="d-flex justify-content-start flex-column">
                                    <a className="text-gray-800 text-hover-primary fw-bold text-muted d-block fs-7">
                                      <span className="text-gray-800">
                                        Original cost
                                      </span>
                                    </a>
                                  </div>
                                </div>
                              </td>
                              <td className="text-end">
                                <a className="text-danger fw-bolder text-hover-primary d-block mb-1 fs-7">
                                  <del>
                                    {userData != null && userData.currencyprefix
                                      ? userData.currencyprefix
                                      : ""}
                                    <span id="original_cost_server"></span>
                                  </del>
                                </a>
                              </td>
                            </tr>
                            <tr className="d-none" id="discount_server_price">
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="d-flex justify-content-start flex-column">
                                    <a className="text-gray-800 text-hover-primary fw-bold text-muted d-block fs-7">
                                      <span className="text-gray-800 me-3 d-none">
                                        Discount
                                      </span>
                                      <span className="badge badge-success">
                                        <span id="discount_offer"></span>%
                                      </span>&nbsp;
                                      Discount
                                    </a>
                                  </div>
                                </div>
                              </td>
                              <td className="text-end">
                                <a className="text-success fw-bolder text-hover-success d-block mb-1 fs-7">
                                  {userData != null && userData.currencyprefix
                                    ? userData.currencyprefix
                                    : ""}
                                  <span id="discount_price"></span>
                                </a>
                              </td>
                            </tr>
                            <tr className="d-none" id="instance_cost_sec">
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="d-flex justify-content-start flex-column">
                                    <a className="text-gray-800 text-hover-primary fw-bold d-block fs-7">
                                      <span className="text-gray-800">
                                        Instance Cost{" "}
                                      </span>
                                    </a>
                                  </div>
                                </div>
                              </td>
                              <td className="text-end">
                                <a className="text-gray-800 fw-bolder text-hover-primary d-block mb-1 fs-7">
                                  {userData != null && userData.currencyprefix
                                    ? userData.currencyprefix
                                    : ""}
                                  <span id="instance_cost"></span>
                                </a>
                              </td>
                            </tr>
                            <tr className="d-none" id="weekly_backup">
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="d-flex justify-content-start flex-column">
                                    <a className="text-gray-800 text-hover-primary fw-bold d-block fs-7">
                                      <span className="text-gray-800">
                                        Weekly backup cost
                                      </span>
                                    </a>
                                  </div>
                                </div>
                              </td>
                              <td className="text-end">
                                <a className="text-gray-800 fw-bolder text-hover-primary d-block mb-1 fs-7">
                                  {userData != null && userData.currencyprefix
                                    ? userData.currencyprefix
                                    : ""}
                                  <span id="weekly_backup_cost"></span>
                                </a>
                              </td>
                            </tr>
                            <tr className="d-none" id="ebs_price_sec">
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="d-flex justify-content-start flex-column">
                                    <a className="text-gray-800 text-hover-primary fw-bold d-block fs-7">
                                      <span className="text-gray-800">
                                        EBS Cost
                                      </span>
                                    </a>
                                  </div>
                                </div>
                              </td>
                              <td className="text-end">
                                <a className="text-gray-800 fw-bolder text-hover-primary d-block mb-1 fs-7">
                                  {userData != null && userData.currencyprefix
                                    ? userData.currencyprefix
                                    : ""}
                                  <span id="ebs_cost_price"></span>
                                </a>
                              </td>
                            </tr>
                            <tr className="d-none" id="os_price_sec">
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="d-flex justify-content-start flex-column">
                                    <a className="text-gray-800 text-hover-primary fw-bold d-block fs-7">
                                      <span className="text-gray-800">
                                        OS cost
                                      </span>
                                    </a>
                                  </div>
                                </div>
                              </td>
                              <td className="text-end">
                                <a className="text-gray-800 fw-bolder text-hover-primary d-block mb-1 fs-7">
                                  {userData != null && userData.currencyprefix
                                    ? userData.currencyprefix
                                    : ""}
                                  <span id="os_cost_price"></span>
                                </a>
                              </td>
                            </tr>
                            <tr className="d-none" id="server_management">
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="d-flex justify-content-start flex-column">
                                    <a className="text-gray-800 text-hover-primary fw-bold d-block fs-7">
                                      <span className="text-dark">
                                        Server management
                                      </span>
                                    </a>
                                  </div>
                                </div>
                              </td>
                              <td className="text-end">
                                <a className="text-gray-800 fw-bolder text-hover-primary d-block mb-1 fs-7">
                                  {userData != null && userData.currencyprefix
                                    ? userData.currencyprefix
                                    : ""}
                                  <span id="management_server"></span>
                                </a>
                              </td>
                            </tr>
                            <tr className="d-none" id="configured_instance_sec">
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="d-flex justify-content-start flex-column">
                                    <a className="text-gray-800 text-hover-primary fw-bolder d-block fs-5 sphide">
                                      <span className="text-dark">Subtotal</span>
                                    </a>
                                  </div>
                                </div>
                              </td>
                              <td className="text-end">
                                <a className="text-gray-800 fw-bolder text-hover-primary d-block mb-1 fs-5">
                                  {userData != null && userData.currencyprefix
                                    ? userData.currencyprefix
                                    : ""}
                                  <span id="configured_instance_cost"></span>
                                </a>
                              </td>
                            </tr>
                            <tr className="d-none" id="instance_calc_sec">
                              <td colSpan={12} className="text-center">
                                <a className="text-gray-800 fw-bolder text-hover-primary d-block mt-1 mb-1 fs-7">
                                  ( Subtotal{" "}
                                  <span className="me-1 ms-1 text-gray-800">
                                    x
                                  </span>{" "}
                                  Instance Count )
                                </a>
                              </td>
                            </tr>
                            <tr className="d-none" id="final_instance_cost_sec">
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="d-flex justify-content-start flex-column">
                                    <a className="text-gray-800 text-hover-primary fw-bolder d-block fs-5 sphide">
                                      <span className="text-dark">
                                        Final Cost
                                      </span>
                                    </a>
                                  </div>
                                </div>
                              </td>
                              <td className="text-end">
                                <a className="text-gray-800 fw-bolder text-hover-primary d-block mb-1 fs-5">
                                  {userData != null && userData.currencyprefix
                                    ? userData.currencyprefix
                                    : ""}
                                  <span id="final_instance_cost"></span>
                                  <span className="me-1 ms-1 text-gray-800">
                                    x
                                  </span>
                                  <span id="final_instance_count"></span>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <span className="mt-1 fw-bolder fs-5">
                          Total{" "}
                          <span
                            className="card-label fw-bolder fs-5 mb-1"
                          // style={{ color: "#328cf1" }}
                          >
                            <span>
                              {user_data.current != null
                                ? user_data.current?.currencyprefix
                                : ""}
                            </span>{" "}
                            <span className="fw-bolder fs-5 " id="order_total">
                              0.00
                            </span>
                            <span className="badge badge-primary" />
                          </span>
                          <span>
                            &nbsp; &nbsp; (
                            <span>
                              {user_data.current != null
                                ? user_data.current?.currencyprefix
                                : ""}
                            </span>
                            <span id="order_toatl_hr">0.000</span>/hr)
                          </span>
                        </span>
                      </h5>
                      <div className="card-toolbar w-100">

                        <a
                          onClick={deployCloudServer}
                          className="btn btn-sm btn-primary w-100"
                          id="deploy-btn"
                          style={{
                            marginBottom: marginBottom
                          }}
                        >
                          {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
                          <span className="svg-icon svg-icon-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z"
                                fill="black"
                              />
                              <path
                                opacity="0.3"
                                d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z"
                                fill="black"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}Deploy Now
                        </a>


                        <div className="mt-5 text-center">
                          <span
                            className=" text-gray-600 fs-6 fw-bolder me-1 cursor-pointer d-none"
                            id="couponSecBtn"
                            onClick={toggleCoupon}
                            style={{ fontSize: 17 }}
                          >
                            Have a promocode?
                          </span>

                          <div className="input-group w-100" id="couponSec">
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              placeholder="Enter coupon"
                              id="couponId"
                              name="coupon"
                              defaultValue={props?.deployInfo[0]?.coupon}
                            />
                            <span className="btn btn-primary btn-sm d-none" id="couponBtn" onClick={applyCoupon}>
                              Apply
                            </span>
                            <span className="btn btn-light-danger  btn-sm" id="removeCouponBtn" onClick={removeDiscount}>
                              <span className="svg-icon svg-icon-2 svg-icon-danger">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                    fill="black"
                                  />
                                  <path
                                    opacity="0.5"
                                    d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                    fill="black"
                                  />
                                  <path
                                    opacity="0.5"
                                    d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                    fill="black"
                                  />
                                </svg>
                              </span>
                              remove
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*end: Card Body*/}
                </div>


                {/*end::List widget 10*/}
              </div>
            </div>
          </form>
        </>
      ) : (
        <>
          <TopBarHeaderLine />
          <TableLoader />
          {/* <div class="loading-spinner2"></div> */}
          {/* <div style={{zIndex: "109"}} id="overlay_id" className="drawer-overlay"></div> */}
        </>
      )}

      <div id="drawer_overlay_ssh" onClick={openDrawer} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>

      <div
        id="importkey-modal"
        className="bg-body shadow drawer drawer-end"
        // data-kt-drawer="true"
        // data-kt-drawer-name="chat"
        // data-kt-drawer-activate="true"
        // data-kt-drawer-direction="end"
        data-kt-drawer-close="#close_ssh"
        // data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
        style={{ important: true, zIndex: 110 }}
      >
        {/*begin::Messenger*/}
        <div
          className="card rounded-0 w-100 border-0"
          id="kt_drawer_chat_messenger"
        >
          {/*begin::Card header*/}
          <div
            className="card-header pe-5"
            id="kt_drawer_chat_messenger_header"
          >
            {/*begin::Title*/}
            <div className="card-title">
              {/*begin::User*/}
              <div className="d-flex justify-content-center flex-column me-3">
                <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                  Add New SSH Key
                </a>
              </div>
              {/*end::User*/}
            </div>
            {/*end::Title*/}
            {/*begin::Card toolbar*/}
            <div className="card-toolbar">
              {/*begin::Close*/}
              <div
                onClick={openDrawer}
                className="btn btn-sm btn-icon btn-active-light-primary"
                id="close_ssh"
              >
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                <span className="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x={6}
                      y="17.3137"
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(-45 6 17.3137)"
                      fill="black"
                    />
                    <rect
                      x="7.41422"
                      y={6}
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(45 7.41422 6)"
                      fill="black"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
              </div>
              {/*end::Close*/}
            </div>
            {/*end::Card toolbar*/}
          </div>
          {/*end::Card header*/}
          {/*begin::Card body*/}
          <div className="" id="">
            {/*begin::Form*/}
            <form name="importkey" id="importkey" className="form">
              {/*begin::Modal body*/}
              <div className="modal-body ">
                {/*begin::Scroll*/}
                <div
                  className="scroll-y me-n7 pe-7"
                  id="kt_modal_create_api_key_scroll"
                  data-kt-scroll="true"
                  data-kt-scroll-activate="{default: false, lg: true}"
                  data-kt-scroll-max-height="auto"
                  data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                  data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                  data-kt-scroll-offset="300px"
                >
                  {/*begin::Input group*/}
                  <div className="mb-5 fv-row">
                    {/*begin::Label*/}
                    <label className="required fs-5 fw-bold mb-2"> Name</label>
                    {/*end::Label*/}
                    {/*begin::Input*/}
                    <input
                      type="text"
                      className="form-control  "
                      placeholder="Name"
                      name="name"
                    />
                    {/*end::Input*/}
                  </div>
                  {/*end::Input group*/}
                  {/*begin::Input group*/}
                  <div className="d-flex flex-column mb-5 fv-row">
                    {/*begin::Label*/}
                    <label className="required fs-5 fw-bold mb-2">
                      SSH Key Content
                    </label>
                    {/*end::Label*/}
                    {/*begin::Input*/}
                    <textarea
                      className="form-control  "
                      type="text"
                      rows={3}
                      name="sshkey"
                      placeholder="SSH"
                      defaultValue={""}
                    />
                    {/*end::Input*/}
                  </div>
                  {/*end::Input group*/}
                </div>
                {/*end::Scroll*/}
                {/*begin::Button*/}
                <button
                  type="button"
                  onClick={importSshKey}
                  className="btn btn-primary w-100"
                >
                  <span className="indicator-label">Import SSH Key</span>
                </button>
                {/*end::Button*/}
              </div>
              {/*end::Modal body*/}
            </form>
            {/*end::Form*/}
          </div>
          {/*end::Card body*/}
        </div>
        {/*end::Messenger*/}
      </div>

      {/* Invoice start */}
      <div
        id="invoice_drawer"
        className="bg-white drawer drawer-end"
        data-kt-drawer="true"
        data-kt-drawer-overlay="true"
        data-kt-drawer-activate="true"
        data-kt-drawer-width="1200px"
        style={{ width: "1200px !important" }}
      >
        <div className=" container-fluid">
          {/*begin::Body*/}
          <div className=" p-5">
            {/*begin::Layout*/}
            <div className="d-flex flex-column flex-xl-row">
              {/*begin::Content*/}
              <div className="flex-lg-row-fluid me-xl-18 mb-10 mb-xl-0">
                {/*begin::Invoice 2 content*/}
                <div className="mt-n1">
                  {/*begin::Top*/}
                  <div className="d-flex flex-stack pb-10">
                    {/*begin::Logo*/}
                    <a >
                      <img
                        alt="Logo"
                        src={siteUrl != null ? (siteUrl + "/publicfiles/" + app_info.logo) : ('')}
                        width="200px"
                      />
                    </a>
                    {/*end::Logo*/}
                    {/*begin::Action*/}
                    <div id="payinvoice" />
                    <div id="paidinvoice" className="">
                      {invoiceData != null && invoiceData.status == "Paid" ? (<>
                        <button className="btn btn-success me-5">Paid</button>
                      </>) : (<>
                        <button className="btn btn-success me-5" id="invoice-payment-button" onClick={invoicePayment}>Pay</button>
                      </>)}



                      <button className="btn btn-light-danger me-5" onClick={closeInvoice}>close</button>

                    </div>

                    {/*end::Action*/}
                  </div>
                  {/*end::Top*/}
                  {/*begin::Wrapper*/}
                  <div className="m-0">
                    {/*begin::Label*/}
                    <div className="fw-bolder fs-3 text-gray-800 mb-8">
                      Invoice <span>{invoiceData != null && invoiceData.id ? (invoiceData.id) : ('')}</span>
                    </div>
                    {/*end::Label*/}
                    {/*begin::Row*/}
                    <div className="row g-5 mb-11">
                      {/*end::Col*/}
                      <div className="col-sm-6">
                        {/*end::Label*/}
                        <div className="fw-bold fs-7 text-gray-600 mb-1">
                          Issue Date:
                        </div>
                        {/*end::Label*/}
                        {/*end::Col*/}
                        <div
                          className="fw-bolder fs-6 text-gray-800"
                          id="invoice-date"
                        >
                          {invoiceData != null && invoiceData.date ? (invoiceData.date) : ('')}
                        </div>
                        {/*end::Col*/}
                      </div>
                      {/*end::Col*/}
                      {/*end::Col*/}
                      <div className="col-sm-6"></div>
                      {/*end::Col*/}
                    </div>
                    {/*end::Row*/}
                    {/*begin::Row*/}
                    <div className="row g-5 mb-12">
                      {/*end::Col*/}
                      <div className="col-sm-6">
                        {/*end::Label*/}
                        <div className="fw-bold fs-7 text-gray-600 mb-1">
                          Issue For:
                        </div>
                        {/*end::Label*/}
                        {/*end::Text*/}
                        <div
                          className="fw-bolder fs-6 text-gray-800"
                          id="company_name"
                        >
                          {userData != null && userData.company ? (userData.company) : ('')}
                        </div>
                        {/*end::Text*/}
                        {/*end::Description*/}
                        <div className="fw-bold fs-7 text-gray-600">
                          <span id="user-address">{userData != null && userData.address ? (userData.address + ", ") : ('')} {userData != null && userData.city ? (userData.city + ", ") : ('')} {userData != null && userData.country ? (userData.country + ", ") : ('')}</span>
                          <br />
                          <span id="user-mobile" >{userData != null && userData.mobile ? (userData.mobile) : ('')}</span>
                          <br />
                          <span id="user-gst" >{userData != null && userData.gstnumber ? (userData.gstnumber) : ('')}</span>
                        </div>
                        {/*end::Description*/}
                      </div>
                      {/*end::Col*/}
                      {/*end::Col*/}
                      <div className="col-sm-6 text-end">
                        {/*end::Label*/}
                        <div className="fw-bold fs-7 text-gray-600 mb-1">
                          Issued By:
                        </div>
                        {/*end::Label*/}
                        {/*end::Text*/}
                        <div className="fw-bolder fs-6 text-gray-800">
                          {app_info != null ? (app_info.company) : ('')}
                        </div>
                        {/*end::Text*/}
                        {/*end::Description*/}
                        <div className="fw-bold fs-7 text-gray-600">
                          {app_info != null ? (app_info.brand) : ('')}
                          <br />
                          {app_info != null ? (app_info.address) : ('')}
                          <br />
                          <strong>GSTIN:</strong> {app_info != null ? (app_info.gst) : ('')}
                          <br />
                          <strong>PAN Number:</strong> {app_info != null ? (app_info.pan) : ('')}
                          <br />
                          <strong>SAC:</strong> {app_info != null ? (app_info.sac) : ('')}
                        </div>
                        {/*end::Description*/}
                      </div>
                      {/*end::Col*/}
                    </div>
                    {/*end::Row*/}
                    {/*begin::Content*/}
                    <div className="flex-grow-1">
                      {/*begin::Table*/}
                      <div className="table-responsive border mb-9">
                        <table className="table mb-3 table-row-bordered">
                          <thead className="bg-secondary">
                            <tr className="border-bottom fs-6 fw-bolder">
                              <th className="min-w-10px" />
                              <th className="min-w-175px pb-2 ">Item Description</th>
                              <th className="min-w-70px text-end pb-2" />
                              <th className="min-w-100px text-start pb-2">Amount</th>
                              <th className="min-w-100px text-start pb-2">Discount</th>
                            </tr>
                          </thead>
                          <tbody id="item-list">
                            {invoiceData != null ? (invoiceData.items != null ? (invoiceData.items.map((value, index) => (<>
                              <tr className="fw-bold text-gray-700">
                                <td></td>
                                <td className="d-flex align-items-center pt-6">{value.description}</td>
                                <td></td>
                                <td className="pt-6 fw-bold">{userData != null && userData.currencyprefix ? (userData.currencyprefix) : ('')} {value.amount}</td>
                                <td className="pt-6 fw-bold">{userData != null && userData.currencyprefix ? (userData.currencyprefix) : ('')} {value.discount ? (value.discount) : (0)}</td>
                              </tr>
                            </>))) : ('')) : ('')}
                          </tbody>
                        </table>
                      </div>
                      {/*end::Table*/}
                      {/*begin::Container*/}
                      <div className="d-flex row">


                        <div className="col-lg-8 col-md-8 col-xxl-8">
                          {userData != null && invoiceData != null && invoiceData.status != "Paid" && (userData.credit !== undefined && userData.credit !== null && userData.credit != 0) ? (<>
                            <div
                              className="d-flex flex-wrap flex-stack mb-6"
                              id="invoice-credit"
                            >

                              {" "}
                              <div className="d-flex my-2">
                                {" "}
                                <div className="d-flex align-items-center position-relative me-4">
                                  {" "}
                                  <form id="applycredit" name="applycredit">
                                    {" "}
                                    <div className="input-group mb-5" id="wallet">
                                      <input
                                        type="hidden"
                                        name="invoiceid"
                                        value={invoiceData != null && invoiceData ? (invoiceData.id) : ('')} />
                                      <span class="input-group-text" >{userData.currencyprefix ? (userData.currencyprefix) : ('')}</span>  <input type="text" class="form-control" placeholder="amount" id="amount" name="amount" min="10" value={userData.credit > invoiceData.rowbalance ? (invoiceData.rowbalance) : (userData.credit)} /><a class="btn btn-primary btn-sm" id="applycredit-btn" onClick={applyCreditAmount}>Apply Credit</a>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </>) : (<></>)}
                        </div>


                        <div className="col-lg-4 col-md-4 col-xxl-4">
                          {/*begin::Section*/}
                          <div className="mw-300px" id="invoice-final">
                            {/*begin::Item*/}{" "}
                            <div className="d-flex flex-stack mb-3">
                              {/*begin::Accountname*/}
                              <div className="fw-bold pe-10 text-gray-600 fs-7">Subtotal:</div>
                              {/*end::Accountname*/}
                              {/*begin::Label*/}
                              <div className="text-end fw-bolder fs-6 text-gray-800" id="subtotal-amount">
                                {invoiceData != null && invoiceData.subtotal ? (invoiceData.subtotal) : ('')}
                              </div>
                              {/*end::Label*/}
                            </div>
                            {/*end::Item*/}

                            {invoiceData != null && invoiceData.taxrate != '0.00' && invoiceData.taxrate2 == '0.00' ? (<>
                              {/*begin::Item*/}{" "}
                              <div className="d-flex flex-stack mb-3">
                                {/*begin::Accountname*/}
                                <div className="fw-bold pe-10 text-gray-600 fs-7">{invoiceData.taxrate}% IGST</div>
                                {/*end::Accountname*/}
                                {/*begin::Label*/}
                                <div className="text-end fw-bolder fs-6 text-gray-800">{userData != null && userData.currencyprefix ? (userData.currencyprefix) : ('')} {invoiceData.tax}</div>
                                {/*end::Label*/}
                              </div>
                              {/*end::Item*/}

                            </>) : ('')}


                            {invoiceData != null && invoiceData.taxrate != '0.00' && invoiceData.taxrate2 != '0.00' ? (<>
                              {/*begin::Item*/}{" "}
                              <div className="d-flex flex-stack mb-3">
                                {/*begin::Accountname*/}
                                <div className="fw-bold pe-10 text-gray-600 fs-7">{invoiceData.taxrate}% CGST</div>
                                {/*end::Accountname*/}
                                {/*begin::Label*/}
                                <div className="text-end fw-bolder fs-6 text-gray-800">{userData != null && userData.currencyprefix ? (userData.currencyprefix) : ('')} {invoiceData.tax2}</div>
                                {/*end::Label*/}
                              </div>
                              {/*end::Item*/}

                            </>) : ('')}

                            {invoiceData != null && invoiceData.taxrate2 != '0.00' ? (<>
                              {/*begin::Item*/}{" "}
                              <div className="d-flex flex-stack mb-3">
                                {/*begin::Accountname*/}
                                <div className="fw-bold pe-10 text-gray-600 fs-7">{invoiceData.taxrate}% SGST</div>
                                {/*end::Accountname*/}
                                {/*begin::Label*/}
                                <div className="text-end fw-bolder fs-6 text-gray-800">{userData != null && userData.currencyprefix ? (userData.currencyprefix) : ('')} {invoiceData.tax2}</div>
                                {/*end::Label*/}
                              </div>
                              {/*end::Item*/}

                            </>) : ('')}

                            {invoiceData != null && invoiceData.credit != '0' ? (<>
                              {/*begin::Item*/}{" "}
                              <div className="d-flex flex-stack mb-3">
                                {/*begin::Accountname*/}
                                <div className="fw-bold pe-10 text-gray-600 fs-7">Credits</div>
                                {/*end::Accountname*/}
                                {/*begin::Label*/}
                                <div className="text-end fw-bolder fs-6 text-gray-800">{userData != null && userData.currencyprefix ? (userData.currencyprefix) : ('')} {invoiceData.credit}</div>
                                {/*end::Label*/}
                              </div>
                              {/*end::Item*/}

                            </>) : ('')}


                            {/*begin::Item*/}{" "}
                            <div className="d-flex flex-stack mb-3">
                              {/*begin::Accountname*/}
                              <div className="fw-bold pe-10 text-gray-600 fs-7">Total</div>
                              {/*end::Accountname*/}
                              {/*begin::Label*/}
                              <div className="text-end fw-bolder fs-6 text-gray-800" id="total-amount">
                                {userData != null && userData.currencyprefix ? (userData.currencyprefix) : ('')} {invoiceData != null && invoiceData.total}
                                <input type="hidden" id="invoiceAmountFund" value={invoiceData != null && invoiceData.rowbalance} />
                                <input type="hidden" id="invoice_id" value={invoiceData != null && invoiceData.id} />
                              </div>
                              {/*end::Label*/}
                            </div>
                            {/*end::Item*/}
                            {invoiceData != null && invoiceData.rowbalance > 0 ? (<>
                              {/*begin::Item*/}{" "}
                              <div className="d-flex flex-stack mb-3">
                                {/*begin::Accountname*/}
                                <div className="fw-bold pe-10 text-gray-600 fs-7">Balance</div>
                                {/*end::Accountname*/}
                                {/*begin::Label*/}
                                <div className="text-end fw-bolder fs-6 text-gray-800" id="total-amount">
                                  {userData != null && userData.currencyprefix ? (userData.currencyprefix) : ('')} {invoiceData != null && invoiceData.rowbalance}
                                </div>
                                {/*end::Label*/}
                              </div>
                              {/*end::Item*/}
                            </>) : ('')}
                          </div>

                        </div>
                        {/*end::Section*/}
                      </div>
                      {/*end::Container*/}
                      <div id="trans-tabel" className="d-none">
                        <div className="fw-bolder fs-2">Transactions</div>
                        <table className="table mb-3 table-row-bordered">
                          <thead className="bg-secondary">
                            <tr className="border-bottom fs-6 fw-bolder">
                              <th className="min-w-175px pb-2 text-center">
                                Transaction Date
                              </th>
                              <th className="min-w-70px text-end pb-2">
                                Transaction Gateways
                              </th>
                              <th className="min-w-80px text-end pb-2">
                                Transaction id
                              </th>
                              <th className="min-w-100px text-center pb-2">
                                Transaction Amount
                              </th>
                            </tr>
                          </thead>
                          <tbody
                            className="fw-bold text-gray-600"
                            id="trans-list"
                          >
                            {invoiceData != null && invoiceData.transs.map((value, index) => (<>
                              <tr>
                                <td class="text-center">{value.date}</td>
                                <td class="text-end">{value.gateway}</td>
                                <td class="text-end">{value.transid}</td>
                                <td class="text-center">{userData != null && userData.currencyprefix ? (userData.currencyprefix) : ('')} {value.amountin ? (value.amountin) : (value.amountout)}</td>
                              </tr>
                            </>))}
                          </tbody>
                        </table>
                        {/*end::Table*/}
                        <div id="due-price" style={{ marginTop: 25 }} >
                          <div class="d-flex justify-content-end">
                            <div class="mw-300px" id="invoice-final">
                              <div class="d-flex flex-stack mb-3">
                                <div class="fw-bold pe-10 text-danger-600 fs-7">Due Amount</div>
                                <div class="text-end fw-bolder fs-6 text-danger-800">{userData != null && userData.currencyprefix ? (userData.currencyprefix) : ('')} {invoiceData != null ? (invoiceData.balance) : ('')}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*end::Content*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Invoice 2 content*/}
              </div>
              {/*end::Content*/}
            </div>
            {/*end::Layout*/}
          </div>
          {/*end::Body*/}
        </div>
      </div>

      {/* Invoice end */}
    </>
  );
};

const mapStateToProps = (state) => ({
  hostCountData: state.cloudDeployData.hostCountDataInfo,
  deployInfo: state.cloudDeployData.deployDataInfo,
});

const mapDispatchToProps = (dispatch) => ({
  updateHostCountData: (data) => dispatch(AddHostCount(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CloudDeploy);
