import React, { useEffect, useState } from 'react';
// import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
// import GoogleLogin from 'react-google-login';
import { GoogleLogin } from '@react-oauth/google';
// import GoogleLogin from 'react-google-login';
import Cookies from "js-cookie";
import "../google/GoogleLogin.css"
// import { useGoogleLogin } from 'react-google-login';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import UseDirectApi from '../../../../CustomHooks/useDirectApi';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const GoogleLoginButton = ({ onSuccess, onFailure }) => {
  var current_url_string = window.location.href;
  var vars = current_url_string.split("/");
  var loginType = vars[3];
  const current_url = new URL(current_url_string);
  const current_domain = current_url.hostname;
  const navigate = useNavigate();
  const [showGoogleLogin, setShowGoogleLogin] = useState(false);
  const clientId = "627473708385-dlp4hcb8asaav7v12a6dqt4bfn7528b7.apps.googleusercontent.com";

  const handleSuccess = async (res) => {
    const dt = toast.loading("Please wait...");
     const tokenId = res.credential; // Access the ID token
    const decodetoken = jwtDecode(tokenId)

    // Step 1: Generate a random 5-digit number
    const random5Digit = Math.floor(10000 + Math.random() * 90000);

    // Step 2: Generate a random 7-digit number
    const random7Digit = Math.floor(1000000 + Math.random() * 9000000);

    // Original number
    const originalNumber = decodetoken.sub;

    // Step 3: Append the 5-digit number to the beginning
    const modifiedNumber1 = `${random5Digit}${originalNumber}`;

    // Step 4: Append the 7-digit number to the end
    const modifiedNumber2 = `${modifiedNumber1}${random7Digit}`;
    // You can send tokenId to your server for further processing

    // Step 5: Encode the final number (optional)
    const encodedNumber = btoa(modifiedNumber2);

    decodetoken.sub = encodedNumber
    let obj = { email: decodetoken.email, oauth_google_uid: decodetoken.sub, type: "platform" }
    
    let responseData = await UseDirectApi("post", "oauth", obj)
    if (responseData.status == 'signupsuccess' || 'loginsuccess') {
      toast.update(dt, {
        render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      localStorage.setItem("Auth", responseData.token);
      Cookies.set('microhost_api_auth', responseData.token, { expires: 30, path: '/', domain: "." + current_domain });
      var baseUrl;
      const platformName = localStorage.getItem("platformType");
      if (platformName) {
        if (platformName == "dev") {
          baseUrl = "https://dev.api.cloudplatformapp.com";
        } else {
          baseUrl = "https://api.utho.com"
        }
      }
      const apiUrl = baseUrl + '/v2/appinfo';
      const headers = {
        'Content-Type': 'application/json', // Adjust as needed
      };

      // Define the Fetch options
      const fetchOptions = {
        method: 'GET',
        headers: headers // Change to 'POST', 'PUT', 'DELETE', etc. if needed 
      };

      const AppData = fetch(apiUrl, fetchOptions)
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json(); // Parse the response body as JSON
        })
        .then(data => {
          if (data) {
            localStorage.setItem("appInfo", JSON.stringify(data));
          }

        })
        .catch(error => {
          console.error('API request failed:', error);
        });

      if (AppData) {
        setTimeout(() => {
          navigate("/");
        }, 2000)
        // const location_url = window.location.origin;
        //  window.location.href = location_url;
      }
    };
    if (responseData.status == 'loginfailed' || responseData.status == "error") {
      toast.update(dt, {
        render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
    }
  }
  const handleFailure = (res) => {
    console.log('Login Failure:', res);
  };
  useEffect(() => {
    const googleLoginButton = document.querySelector('.google-login-button');
    if (googleLoginButton) {
      googleLoginButton.style.borderRadius = '10px';
      const innerDiv = googleLoginButton.querySelector('div');
      if (innerDiv) {
        innerDiv.style.borderRadius = '10px';
      }
    }
  }, []);
  return (
    <div className='btn-light-primary fw-bolder w-100 mb-10'>
      <div className='h-20px me-3 '>
        <GoogleLogin
          clientId="1032049765956-sl9vblksjpv2rouvb3c1pgp6sjb4b6n8.apps.googleusercontent.com"
          // buttonText={"Sign up with google"}
          // text='Sign up with google'
          text='continue_with'
          onSuccess={handleSuccess}
          onFailure={handleFailure}
          size='large'
          shape='rectangular'
          width="230px"
        >
        </GoogleLogin>
        {/* )} */}
      </div>
    </div>
    // </a>
  );
};

export default GoogleLoginButton;
