import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import UsePost from "../../../../../../CustomHooks/usePost";
import { IsCloudUpdate } from "../../../../../../Redux/Services/Action/actions";
import TopBarHeaderLine from "../../../../../../CustomHooks/TopBarHeaderLine";
import UsePostData from "../../../../../../CustomHooks/usePostData";
import CustomDoc from "../../../../../../CustomHooks/CustomDoc";
import ProductsUrl from "../../../../Docs/Docsdata";
const Rescue = (props) => {
  const dispatch = useDispatch();
  const [pageStatus, setPageStatus] = useState(false);
  const dataFetchedRef = useRef(false);
  const navigate = useNavigate();
  var query = window.location.href;
  var vars = query.split("/");
  var id = vars[4];
  const navRedirect = () => {
    setTimeout(() => {
      navigate("/cloud/" + id);
    }, 3000);
  };
  
  const RescueServer = async () => {
    toast.dismiss();
    swal({
      title: "Are you sure?",
      text: "Once Rescue Server, your all service of this cloud will be rebooted with the server!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        var submitBtn = document.querySelector("#rescue-btn");
        if (submitBtn) {
          setPageStatus(true)
          var preContent = submitBtn.innerHTML;
          submitBtn.classList.add("disabled");
          submitBtn.innerHTML =
            '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
          const dt = toast.loading("Cloud server Rebooting...");
          const dnsp = await UsePostData("POST", "cloud/" + id + "/mountiso", {"iso":"rescue.iso"});
          //console.log(dnsp);
          if (dnsp) {
            setPageStatus(false);
          
          if (dnsp.status == "error") {
            toast.update(dt, {
              render: dnsp.message,
              type: "warning",
              isLoading: false,
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            setPageStatus(false);
            toast.update(dt, {
              render: dnsp.message,
              type: "success",
              isLoading: false,
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            navRedirect();
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
            // dispatch(props.updateCloudStatus("Hard Reboot"));
           
          }
          submitBtn.classList.remove("disabled");
          submitBtn.innerHTML = preContent;
        }
      } 
    });
  };

  return (
    <>
      {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        /> */}
      
          {/*begin::Notice*/}
          <div className="notice d-flex bg-light-primary rounded border-primary border p-6">
            
            {/*begin::Icon*/}
            {/*begin::Svg Icon | path: icons/duotune/finance/fin001.svg*/}
            <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
              
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
              >
                
                <path
                  d="M20 19.725V18.725C20 18.125 19.6 17.725 19 17.725H5C4.4 17.725 4 18.125 4 18.725V19.725H3C2.4 19.725 2 20.125 2 20.725V21.725H22V20.725C22 20.125 21.6 19.725 21 19.725H20Z"
                  fill="black"
                />
                <path
                  opacity="0.3"
                  d="M22 6.725V7.725C22 8.325 21.6 8.725 21 8.725H18C18.6 8.725 19 9.125 19 9.725C19 10.325 18.6 10.725 18 10.725V15.725C18.6 15.725 19 16.125 19 16.725V17.725H15V16.725C15 16.125 15.4 15.725 16 15.725V10.725C15.4 10.725 15 10.325 15 9.725C15 9.125 15.4 8.725 16 8.725H13C13.6 8.725 14 9.125 14 9.725C14 10.325 13.6 10.725 13 10.725V15.725C13.6 15.725 14 16.125 14 16.725V17.725H10V16.725C10 16.125 10.4 15.725 11 15.725V10.725C10.4 10.725 10 10.325 10 9.725C10 9.125 10.4 8.725 11 8.725H8C8.6 8.725 9 9.125 9 9.725C9 10.325 8.6 10.725 8 10.725V15.725C8.6 15.725 9 16.125 9 16.725V17.725H5V16.725C5 16.125 5.4 15.725 6 15.725V10.725C5.4 10.725 5 10.325 5 9.725C5 9.125 5.4 8.725 6 8.725H3C2.4 8.725 2 8.325 2 7.725V6.725L11 2.225C11.6 1.925 12.4 1.925 13.1 2.225L22 6.725ZM12 3.725C11.2 3.725 10.5 4.425 10.5 5.225C10.5 6.025 11.2 6.725 12 6.725C12.8 6.725 13.5 6.025 13.5 5.225C13.5 4.425 12.8 3.725 12 3.725Z"
                  fill="black"
                />
              </svg>
            </span>
            {/*end::Svg Icon*/} {/*end::Icon*/} {/*begin::Wrapper*/}
            <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
              
              {/*begin::Content*/}
              <div className="mb-3 mb-md-0 fw-bold">
                
                <h4 className="text-gray-900 fw-bolder">Rescue Server</h4>
                <div className="fs-6 text-gray-700 pe-7">
                This will boot you to a rescue server, allowing you to recover your corrupted disk data or data from an unresponsive server. You can then mount your data disk drive to retrieve your data. Please refer to the Utho doc for instructions on enabling SSH and the mounting process, <CustomDoc anchor={true} url={ProductsUrl[0]?.cloud+"/manage-cloud/power-actions/rescue-server/"}/>
                </div>
              </div>
              {/*end::Content*/} {/*begin::Action*/}
              <a
                id="rescue-btn"
                onClick={RescueServer}
                className="btn btn-primary btn-sm align-self-center text-nowrap"
                style={{padding:'0.627rem 2.5063rem 0.627rem 2.5063rem'}}
              >
                Rescue Server
              </a>
              {/*end::Action*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Notice*/}
          {pageStatus &&
    
        <TopBarHeaderLine />
      }
    </>
  );
};
const mapStateToProps = (state) => ({
  cloudData: state.cloud.cloudDataInfo,
});

const mapDispatchToProps = (dispatch) => ({
  updateCloudStatus: (data) => dispatch(IsCloudUpdate(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Rescue);
