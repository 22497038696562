import React, { useEffect, useRef, useState } from "react";
import TopBarHeaderLine from "../../../../../CustomHooks/TopBarHeaderLine";
import UseCallApi from "../../../../../CustomHooks/useCallApi";
import ReactPaginate from "react-paginate";
import copy from "copy-to-clipboard";
import ActionList from "../helpers/ActionList";
import { toast } from "react-toastify";
import UseApi from "../../../../../CustomHooks/useApi";
import { Tab } from "react-bootstrap";
import TableLoader from "../../../Loaders/TableLoader";
import UpdateRegistry from "./UpdateRegistry";
import swal from "sweetalert";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { formatISODate } from "../../../utils/utils";


export default function PublicRegistry(props) {
    let navigate = useNavigate()
    const [pageStatus, setPageStatus] = useState(false)
    const [isUpdate, setIsUpdate] = useState(false)
    const [objectForUpdate, setObjectForUpdate] = useState({})
    const [pageCount, setPageCount] = useState(1);
    const [perPage, setPerPage] = useState(5);
    const [itemOffset, setItemOffset] = useState(0);
    const [paddingBottom, setPaddingBottom] = useState(window.innerWidth >= 768 ? '0rem' : '5.5rem');
    const getAllRegistry = async () => {
        props.getAllRegistry()
    }

    useEffect(() => {
        if (props?.allPublicData?.length <= 0 && props?.allPrivateData?.length <= 0) {
            console.log("hello buddy");
            props.switchPage()
            // navigate('/container-registry')
        }
    }, [pageStatus])

    const openDrawerForUpdate = () => {
        var drawerElement = document.querySelector("#fw-modal");
        var drawer_overlay = document.querySelector("#drawer_overlay_firewall");
        if (drawerElement && drawer_overlay) {
            drawerElement.classList.toggle("drawer-on");
            if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
                drawerElement.style.width = "100%";
            } else {
                drawerElement.style.width = "40%";
            }
            drawer_overlay.classList.toggle("d-none");
        }
    };

    // useEffect(() => {
    //     getAllRegistry()
    // }, [props.pageStatus])

    const handleDeleteRegistry = async (name, index) => {
        toast.dismiss();
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Public Registry!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    const dt = toast.loading("Please wait...");
                    const submitBtn = document.querySelector("#delete-registry-btn-" + name);
                    if (submitBtn) {
                        var preContent = submitBtn.innerHTML;
                        submitBtn.classList.add("disabled");
                        submitBtn.innerHTML =
                            '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';

                        const responseData = await UseApi("delete", `registry/project/${name}`, "");

                        ////console.log(responseData);
                        if (responseData.status === "error") {
                            toast.update(dt, {
                                render: responseData.message,
                                type: "error",
                                isLoading: false,
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        } else if (responseData.status === "success") {
                            toast.update(dt, {
                                render: responseData.message,
                                type: "success",
                                isLoading: false,
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                            setPageStatus(!pageStatus)
                            // props.updateDatabase()
                            props.setAllPublicData((old) => {
                                old.splice(index, 1);
                                return [...old];
                            })
                        }
                        submitBtn.classList.remove("disabled");
                        submitBtn.innerHTML = preContent;
                    }
                }
            })
    }

    function convertTimestamp(timestamp) {
        const date = new Date(timestamp);

        // Format the date
        const formattedDate = new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZoneName: 'short',
            timeZone: 'UTC'
        }).format(date);

        return formattedDate.replace('GMT', '(UTC)');
    }

    const copyToClipboard = (target, targetBy) => {
        var btn_id = document.querySelector("#" + targetBy);
        var target_id = document.querySelector("#" + target);
        if (btn_id) {
            var copyText = btn_id.innerHTML;
            var copyEle = target_id.innerHTML;
            copy(copyEle);
            btn_id.classList.add("disabled")
            btn_id.innerHTML = `<svg
          xmlns="http://www.w3.org/2000/svg"
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M9.89557 13.4982L7.79487 11.2651C7.26967 10.7068 6.38251 10.7068 5.85731 11.2651C5.37559 11.7772 5.37559 12.5757 5.85731 13.0878L9.74989 17.2257C10.1448 17.6455 10.8118 17.6455 11.2066 17.2257L18.1427 9.85252C18.6244 9.34044 18.6244 8.54191 18.1427 8.02984C17.6175 7.47154 16.7303 7.47154 16.2051 8.02984L11.061 13.4982C10.7451 13.834 10.2115 13.834 9.89557 13.4982Z"
            fill="white"
          />
        </svg>`;
            setTimeout(() => {
                btn_id.innerHTML = copyText;
                btn_id.classList.remove("disabled")
            }, 2000);
        }

    }

    const findObjectForUpdate = (name) => {
        // setIsUpdate(true)
        // console.log(props.allPublicData, name);
        const foundObject = props.allPublicData.find(obj => obj.name === name);
        if (foundObject) {
            var selectElement = document.getElementById("update_cr_id");
            var selectIdElement = document.getElementById("public_id_for_update");
            selectElement.value = foundObject?.metadata?.public == 'true' ? 'public' : 'private';
            selectIdElement.value = foundObject.name
            let element = document.querySelector("#cr_type")
            element.value = foundObject?.metadata?.public
            setObjectForUpdate(foundObject)
        } else {
            setObjectForUpdate({})
        }
    }

    const handleSearch = (text) => {
        const endOffset = itemOffset + perPage;
        const escapedText = text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        let matches = props.allPublicData.filter((row) => {
            const regex = new RegExp(`${escapedText}`, "gi");
            return row.name.match(regex);
        });
        console.log(matches);
        props.setDummyAllPublicData(matches);;
        setPageCount(Math.ceil(matches.length / perPage));
    }

    function PaginatedItems({ itemsPerPage, ProductData, search }) {

        const handlePageClick = (event) => {
            const newOffset = event.selected * itemsPerPage % props.dummyAllPublicData.length;
            setItemOffset(newOffset);

        };

        return (
            <>
                <ProductInfo productdata={props.dummyAllPublicData} />
                <td colspan="12" className="ps-3">
                    <ReactPaginate
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        previousLabel="< previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                    />
                </td>
            </>
        );
    }

    const openDrawer = () => {

        var drawerElement = document.querySelector("#connection-modal");
        var drawer_overlay = document.querySelector("#drawer_overlay_connection");
        if (drawerElement && drawer_overlay) {
            drawerElement.classList.toggle("drawer-on");
            drawerElement.classList.toggle("opn_drawer");
            if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
                drawerElement.style.width = "100%";
                drawerElement.style.display = 'block !important'
            } else {
                drawerElement.style.width = "81%";
                drawerElement.style.display = 'block !important'
            }
            drawer_overlay.classList.toggle("d-none");
        }
    };

    function ProductInfo({ productdata }) {
        return (
            <>
                {productdata != null ? (
                    Object.keys(productdata).length !== 0 ? (
                        productdata.map((value, index) => (
                            <tr>
                                <td className="text-start ps-9 w-150px">
                                    <span className="fs-6 fw-bold sphide">
                                        {value.name}
                                    </span>
                                </td> 
                                <td className="text-center w-150px ps-9">
                                    <span className="fs-6 fw-bold">
                                        {value.repo_count}
                                    </span>
                                </td>
                                 <td className="text-center w-150px ps-9">
                                    <span className="fs-6 fw-bold">
                                        {formatISODate(value.creation_time)}
                                    </span>
                                </td>
                                <td className="text-center w-150px">
                                    <span className="fs-6 fw-bold">
                                        {formatISODate(value.update_time)}
                                    </span>
                                </td>
                                <td className="text-center pe-5 w-150px sphide">
                                    <div className="fs-7 fw-bold btn btn-sm btn-light-primary sphide border border-primary">
                                        <div onClick={() => {
                                            openDrawer()
                                            findObjectForUpdate(value.name)
                                        }}>
                                            View Push Commands&nbsp;
                                            {/*begin::Svg Icon | path: assets/media/icons/duotune/arrows/arr095.svg*/}
                                            <span className="svg-icon svg-icon-muted svg-icon-2">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={24}
                                                    height={24}
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                >
                                                    <path
                                                        opacity="0.3"
                                                        d="M4.7 17.3V7.7C4.7 6.59543 5.59543 5.7 6.7 5.7H9.8C10.2694 5.7 10.65 5.31944 10.65 4.85C10.65 4.38056 10.2694 4 9.8 4H5C3.89543 4 3 4.89543 3 6V19C3 20.1046 3.89543 21 5 21H18C19.1046 21 20 20.1046 20 19V14.2C20 13.7306 19.6194 13.35 19.15 13.35C18.6806 13.35 18.3 13.7306 18.3 14.2V17.3C18.3 18.4046 17.4046 19.3 16.3 19.3H6.7C5.59543 19.3 4.7 18.4046 4.7 17.3Z"
                                                        fill="black"
                                                    />
                                                    <rect
                                                        x="21.9497"
                                                        y="3.46448"
                                                        width={13}
                                                        height={2}
                                                        rx={1}
                                                        transform="rotate(135 21.9497 3.46448)"
                                                        fill="black"
                                                    />
                                                    <path
                                                        d="M19.8284 4.97161L19.8284 9.93937C19.8284 10.5252 20.3033 11 20.8891 11C21.4749 11 21.9497 10.5252 21.9497 9.93937L21.9497 3.05029C21.9497 2.498 21.502 2.05028 20.9497 2.05028L14.0607 2.05027C13.4749 2.05027 13 2.52514 13 3.11094C13 3.69673 13.4749 4.17161 14.0607 4.17161L19.0284 4.17161C19.4702 4.17161 19.8284 4.52978 19.8284 4.97161Z"
                                                        fill="black"
                                                    />
                                                </svg>
                                            </span>
                                            {/*end::Svg Icon*/}
                                        </div>

                                    </div>
                                </td>
                                 <td className="text-center pe-5 w-150px sphide" >
                                    <div className="card-toolbar d-none">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                // openDrawer()
                                                openDrawerForUpdate()
                                                findObjectForUpdate(value.name)
                                            }}
                                            className="btn  btn-icon btn-color-primary btn-active-light-primary"
                                        >
                                            <span className="svg-icon svg-icon-1">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={24}
                                                    height={24}
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                >
                                                    <path
                                                        opacity="0.3"
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z"
                                                        fill="black"
                                                    />
                                                    <path
                                                        d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z"
                                                        fill="black"
                                                    />
                                                    <path
                                                        d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z"
                                                        fill="black"
                                                    />
                                                </svg>

                                            </span>
                                        </button>
                                        <button
                                            type="button"
                                            className="btn  btn-icon btn-color-danger btn-active-light-danger"
                                            id={`delete-registry-btn-${value.name}`}
                                            onClick={() => handleDeleteRegistry(value.name, index)}
                                        >
                                            <span className="svg-icon svg-icon-1">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={24}
                                                    height={24}
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                                        fill="black"
                                                    />
                                                    <path
                                                        opacity="0.5"
                                                        d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                                        fill="black"
                                                    />
                                                    <path
                                                        opacity="0.5"
                                                        d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                                        fill="black"
                                                    />
                                                </svg>
                                            </span>
                                        </button>
                                    </div>
                                    <div className="d-flex justify-content-end flex-shrink-0">
                                        <Link to={"/container-registry/" + value.name + ""} >
                                            <a className="btn btn-primary btn-active-light-primary btn-sm"> Manage
                                                <span className="svg-icon ms-2 svg-icon-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"> <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black"></rect> <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black"></path>
                                                    </svg>
                                                </span>
                                            </a>
                                        </Link>
                                    </div>
                                </td>
                                {/* <ActionList cloudid={value.name} index={index} setAllData={setAllData} /> */}
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colspan="12">
                                <div className="d-flex flex-column flex-start">
                                    <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                                </div>
                            </td>
                            {/* <td></td>
                            <td></td>
                            <td></td>
                            <td></td> */}
                        </tr>
                    )
                ) : (
                    <td colspan="12">
                        <TableLoader />
                    </td>
                )}
            </>
        )
    }

    useEffect(() => {
        const handleResize = () => {
          setPaddingBottom(window.innerWidth >= 768 ? '0rem' : '5rem');
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    return (
        <>
            <div className="card" style={{ borderRadius: 'inherit', paddingBottom:paddingBottom }}>
                <div className="card-header">
                    <div className="card-title">
                        <h3>Public Repository</h3>
                    </div>
                    <div className="card-toolbar">
                        <div
                            className="position-relative my-1"
                            style={{ marginRight: 20 }}
                        >
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                data-kt-table-widget-4="search"
                                id="searchClient"
                                // value={searchTerm}
                                onChange={e => handleSearch(e.target.value)}
                                placeholder="Search"
                            />
                        </div>

                        <div style={{ marginRight: 0 }}>
                            <div className="dataTables_length">
                                <label className="w-125px">
                                    <select
                                        onChange={e => setPerPage(e.target.value)}
                                        //data-control="select2"
                                        className="form-select form-select-sm form-select-solid"
                                        defaultValue={5}
                                        id="perPage"

                                    >
                                        <option value={5} selected="">
                                            5 Records
                                        </option>
                                        <option value={10}>10 Records</option>
                                        <option value={25}>25 Records</option>
                                        <option value={50}>50 Records</option>
                                        <option value={100}>100 Records</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body p-0">
                    <div className="table-responsive">
                        <table
                            className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
                            id="kt_security_license_usage_table"
                        >
                            <thead className="border-gray-200 fs-5 fw-bold bg-secondary">
                                <tr>
                                <th className="text-start w-150px ps-9">Name</th>
                                     <th className="text-center w-150px ps-9">Repo</th>
                                     <th className="text-center w-200px ps-9 sphide">Created At</th>
                                     <th className="text-center w-200px sphide">Updated At</th>
                                     <th className="text-center w-150px ps-9"></th>
                                    <th className="text-center pe-5 w-150px sphide"></th>
                                </tr>
                            </thead>
                            <tbody id="public-IP-list" className="fw-6 fw-bold text-gray-600">
                                {props.dummyAllPublicData != null ? (<>
                                    {props.dummyAllPublicData.length > 0 ? (<>
                                        <PaginatedItems itemsPerPage={perPage} />
                                    </>) : (<>
                                        <tr>
                                            <td colspan="12" valign="top">
                                                <div className="d-flex flex-column flex-center">
                                                    <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                                                </div>
                                            </td>
                                            {/* <td className="text-center w-50px ps-9 sphide"></td>
                                            <td className="text-start w-150px sphide"></td>
                                            <td className="text-center w-150px ps-9 d-none">URI</td> */}
                                            {/* <td className="text-end w-150px ps-9 sphide">
                                                <div className="d-flex flex-column flex-end">
                                                    <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                                                </div>
                                            </td> */}
                                            {/* <td className="text-center w-150px ps-9 sphide"></td>
                                            <td className="text-center w-150px ps-9"></td>
                                            <td className="text-center pe-5 w-150px sphide"></td>
                                            <td className="text-center pe-5 w-150px sphide"></td> */}
                                        </tr>
                                    </>)}
                                </>) : <tr>
                                    <td colspan={12}>
                                        <TableLoader />
                                    </td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* Connection create modal start */}
            <div id="drawer_overlay_connection" onClick={openDrawer} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>

            <div
                id="connection-modal"
                className="bg-body shadow drawer drawer-end"
                // data-kt-drawer="true"
                // data-kt-drawer-name="chat"
                // data-kt-drawer-activate="true"
                // data-kt-drawer-direction="end"
                data-kt-drawer-close="#close_ssh"
                style={{ important: true, display: 'block !important', zIndex: 110 }}
            >

                <div className="mb-2">
                    {/*begin::Card Body*/}
                    <div className="card-body fs-6 text-gray-700">
                        {/*begin::Section*/}
                        <div className="pt-3">
                            {/*begin::Heading*/}
                            <div className="card-toolbar d-flex">
                                <h1 className=" fw-bolder mb-0" id="rtl-version">
                                    <a href="#rtl-version" />
                                    Push Commands
                                    {/* <div className="card-toolbar"> */}
                                    {/*begin::Close*/}
                                </h1>
                                <div
                                    onClick={openDrawer}
                                    className="btn btn-sm btn-icon btn-active-light-primary d-flex"
                                    id="close_ssh"
                                    style={{ marginLeft: 'auto' }}
                                >
                                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                                    <span className="svg-icon svg-icon-2">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <rect
                                                opacity="0.5"
                                                x={6}
                                                y="17.3137"
                                                width={16}
                                                height={2}
                                                rx={1}
                                                transform="rotate(-45 6 17.3137)"
                                                fill="black"
                                            />
                                            <rect
                                                x="7.41422"
                                                y={6}
                                                width={16}
                                                height={2}
                                                rx={1}
                                                transform="rotate(45 7.41422 6)"
                                                fill="black"
                                            />
                                        </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                </div>
                            </div>

                            {/*end::Close*/}
                            {/* </div> */}
                            {/* end::Heading */}
                            {/*begin::Block*/}
                            <div className="py-0">
                                <p className="mb-0">
                                    Command references for pushing an artifact to this registry.
                                </p>
                            </div>
                            {/*end::Block*/}
                            {/*begin::List*/}
                            <div className="separator border-2 my-10"></div>
                            <ol className="mb-2">
                                <li className="py-3">
                                    <h5>Docker Push Command</h5>
                                    Login to Utho Registry:
<div className="py-5">
  {/*begin::Highlight*/}
  <div className="highlight">
    <button
      className="highlight-copy btn"
      data-bs-toggle="tooltip"
      title=""
      data-bs-original-title="Copy code"
      style={{ important: true, display: 'block' }}
      id="docker_push_command_login_btn1"
      onClick={() => copyToClipboard('container_string_login', 'docker_push_command_login_btn1')}
    >
      copy
    </button>
    <div className="highlight-code">
      <pre className=" language-bash" tabIndex={0}>
        <code className=" language-bash" id="container_string_login">docker login registry.utho.io</code>
      </pre>
    </div>
  </div>
  {/*end::Highlight*/}
</div>


Create build image for this registry::
<div className="py-5">
  {/*begin::Highlight*/}
  <div className="highlight">
    <button
      className="highlight-copy btn"
      data-bs-toggle="tooltip"
      title=""
      data-bs-original-title="Copy code"
      style={{ important: true, display: 'block' }}
      id="docker_push_command_build_btn1"
      onClick={() => copyToClipboard('container_string_build', 'docker_push_command_build_btn1')}
    >
      copy
    </button>
    <div className="highlight-code">
      <pre className=" language-bash" tabIndex={0}>
        <code className=" language-bash" id="container_string_build">docker build -t [NAME]:latest .
</code>
      </pre>
    </div>
  </div>
  {/*end::Highlight*/}
</div>
                                    Tag an image for this registry:
                                    <div className="py-5">
                                        {/*begin::Highlight*/}
                                        <div className="highlight">
                                            <button
                                                className="highlight-copy btn"
                                                data-bs-toggle="tooltip"
                                                title=""
                                                data-bs-original-title="Copy code"
                                                style={{ important: true, display: 'block' }}
                                                id="docker_push_command_btn1"
                                                onClick={() => copyToClipboard('container_string_value1', 'docker_push_command_btn1')}
                                            >
                                                copy
                                            </button>
                                            <div className="highlight-code">
                                                <pre className=" language-bash" tabIndex={0}>
                                                    <code className=" language-bash" id="container_string_value1">docker tag SOURCE_IMAGE[:TAG] registry.utho.io/{objectForUpdate?.name}/REPOSITORY[:TAG]</code>
                                                </pre>
                                            </div>
                                        </div>
                                        {/*end::Highlight*/}
                                    </div>
                                    Push an image to this registry:
                                    <div className="py-5">
                                        {/*begin::Highlight*/}
                                        <div className="highlight">
                                            <button
                                                className="highlight-copy btn"
                                                data-bs-toggle="tooltip"
                                                title=""
                                                style={{ important: true, display: 'block' }}
                                                data-bs-original-title="Copy code"
                                                id="docker_push_command_btn2"
                                                onClick={() => copyToClipboard('container_string_value2', 'docker_push_command_btn2')}
                                            >
                                                copy
                                            </button>
                                            <div className="highlight-code">
                                                <pre className=" language-bash" tabIndex={0}>
                                                    <code className=" language-bash" id="container_string_value2">
                                                        docker push registry.utho.io/{objectForUpdate?.name}/REPOSITORY[:TAG]
                                                    </code>
                                                </pre>
                                            </div>
                                        </div>
                                        {/*end::Highlight*/}
                                    </div>
                                </li>
                                <li className="py-3">
                                    <h5>Podman Push Command</h5>

                                    Push an image to this registry:
                                    <div className="py-5">
                                        {/*begin::Highlight*/}
                                        <div className="highlight">
                                            <button
                                                className="highlight-copy btn"
                                                data-bs-toggle="tooltip"
                                                title=""
                                                style={{ important: true, display: 'block' }}
                                                data-bs-original-title="Copy code"
                                                id="docker_push_command_btn3"
                                                onClick={() => copyToClipboard('container_string_value3', 'docker_push_command_btn3')}
                                            >
                                                copy
                                            </button>
                                            <div className="highlight-code">
                                                <pre className=" language-bash" tabIndex={0}>
                                                    <code className=" language-bash" id="container_string_value3">
                                                        podman push IMAGE_ID registry.utho.io/{objectForUpdate?.name}/REPOSITORY[:TAG]
                                                    </code>
                                                </pre>
                                            </div>
                                        </div>
                                        {/*end::Highlight*/}
                                    </div>
                                </li>
                                <li className="py-3">
                                    <h5>Helm Push Command</h5>
                                    Package a chart for this registry:
                                    <div className="py-5">
                                        {/*begin::Highlight*/}
                                        <div className="highlight">
                                            <button
                                                className="highlight-copy btn"
                                                data-bs-toggle="tooltip"
                                                title=""
                                                style={{ important: true, display: 'block' }}
                                                data-bs-original-title="Copy code"
                                                id="docker_push_command_btn4"
                                                onClick={() => copyToClipboard('container_string_value4', 'docker_push_command_btn4')}
                                            >
                                                copy
                                            </button>
                                            <div className="highlight-code">
                                                <pre className=" language-bash" tabIndex={0}>
                                                    <code className=" language-bash" id="container_string_value4">
                                                        helm package CHART_PATH
                                                    </code>
                                                </pre>
                                            </div>
                                        </div>
                                        {/*end::Highlight*/}
                                    </div>

                                    Push a chart to this registry:
                                    <div className="py-5">
                                        {/*begin::Highlight*/}
                                        <div className="highlight">
                                            <button
                                                className="highlight-copy btn"
                                                data-bs-toggle="tooltip"
                                                title=""
                                                style={{ important: true, display: 'block' }}
                                                data-bs-original-title="Copy code"
                                                id="docker_push_command_btn5"
                                                onClick={() => copyToClipboard('container_string_value5', 'docker_push_command_btn5')}
                                            >
                                                copy
                                            </button>
                                            <div className="highlight-code">
                                                <pre className=" language-bash" tabIndex={0}>
                                                    <code className=" language-bash" id="container_string_value5">
                                                        helm push CHART_PACKAGE oci://registry.utho.io/{objectForUpdate?.name}
                                                    </code>
                                                </pre>
                                            </div>
                                        </div>
                                        {/*end::Highlight*/}
                                    </div>
                                </li>
                                <li className="py-3">
                                    <h5>CNAB Push Command </h5>
                                    Push a CNAB to this registry:
                                    <div className="py-5">
                                        {/*begin::Highlight*/}
                                        <div className="highlight">
                                            <button
                                                className="highlight-copy btn"
                                                data-bs-toggle="tooltip"
                                                title=""
                                                style={{ important: true, display: 'block' }}
                                                data-bs-original-title="Copy code"
                                                id="docker_push_command_btn6"
                                                onClick={() => copyToClipboard('container_string_value6', 'docker_push_command_btn6')}
                                            >
                                                copy
                                            </button>
                                            <div className="highlight-code">
                                                <pre className=" language-bash" tabIndex={0}>
                                                    <code className=" language-bash" id="container_string_value6">
                                                        cnab-to-oci push CNAB_PATH --target registry.utho.io/{objectForUpdate?.name}/REPOSITORY[:TAG] --auto-update-bundle
                                                    </code>
                                                </pre>
                                            </div>
                                        </div>
                                        {/*end::Highlight*/}
                                    </div>
                                </li>
                            </ol>
                            {/*end::List*/}
                        </div>
                        {/*end::Section*/}
                    </div>
                    {/*end::Card Body*/}
                </div>
            </div>
            <UpdateRegistry data={objectForUpdate} getAllRegistry={getAllRegistry} />
        </>
    )
}