
export default function SslDeploy(props) {
    // function handleChangeStateAndUrl() {
    //     const urlParams = new URLSearchParams(window.location.search);
    //     const actionParam = urlParams.get('action');
    //     if (actionParam === 'deploy') {
    //     const currentURL = window.location.href;
    //     const updatedURL = currentURL.split('?')[0]; // Remove everything after '?'
    //     window.history.replaceState(null, '', updatedURL);
    //     props.setActionType('page')
    //     }
    // }
    return (
        <>
            <div id="drawer_overlay"
                onClick={() => {
                    props.openDrawer()
                    // handleChangeStateAndUrl()
                }}
                className="drawer-overlay"
                style={{ zIndex: 109, width: "100%" }}></div>
            <div
                id="add_ssl-modal"
                className="drawer drawer-end drawer-on"
                data-kt-drawer="true"
                data-kt-drawer-activate="true"
                data-kt-drawer-overlay="false"
                data-kt-drawer-width="{default:'500px', 'md': '500px', 'sm': '300px'}"
                style={{ maxWidth: '70%', width: '70%', important: true }}
            >
                {/*begin::Messenger*/}
                <div className="card w-100 rounded-0 border-0" id="kt_drawer_chat_messenger">
                    {/*begin::Card header*/}
                    <div className="card-header ps-7 p-0 pe-7" id="kt_drawer_chat_messenger_header">
                        {/*begin::Title*/}
                        <div className="card-title">
                            {/*begin::User*/}
                            <div className="d-flex justify-content-center flex-column me-3">
                                <a

                                    className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1"
                                >
                                    Add SSL Certificate
                                </a>
                            </div>
                            {/*end::User*/}
                        </div>
                        {/*end::Title*/}
                        {/*begin::Card toolbar*/}
                        <div className="card-toolbar">
                            {/*begin::Close*/}
                            <div onClick={props.openDrawer}>
                                <div
                                    className="btn btn-sm btn-icon btn-active-light-primary"
                                // onClick={(e) => setUpdateBtn(false)}
                                >
                                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                                    <span className="svg-icon svg-icon-2">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <rect
                                                opacity="0.5"
                                                x={6}
                                                y="17.3137"
                                                width={16}
                                                height={2}
                                                rx={1}
                                                transform="rotate(-45 6 17.3137)"
                                                fill="black"
                                            />
                                            <rect
                                                x="7.41422"
                                                y={6}
                                                width={16}
                                                height={2}
                                                rx={1}
                                                transform="rotate(45 7.41422 6)"
                                                fill="black"
                                            />
                                        </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                </div>
                            </div>
                            {/*end::Close*/}
                        </div>
                        {/*end::Card toolbar*/}
                    </div>
                    {/*end::Card header*/}
                    {/*begin::Card body*/}
                    <div className="" id="">
                        {/*begin::Form*/}
                        {/*begin::Modal body*/}
                        <div className="modal-body ">
                            {/*begin::Scroll*/}
                            <div className="scroll-y me-n7 pe-7">
                                <form id="sslCreate">
                                    {/*begin::Input group*/}
                                    <div className="fv-row mb-10 fv-plugins-icon-container">
                                        {/*begin::Label*/}
                                        <label className="form-label required">Name</label>
                                        {/*end::Label*/}
                                        {/*begin::Input*/}
                                        <input type="hidden" id="id_field" className="form-control form-control-lg  w-50" />
                                        <input
                                            name="name"
                                            id="name_field"
                                            className="form-control form-control-lg  w-50"
                                            defaultValue=""
                                        />
                                        {/*end::Input*/}
                                        <div className="fv-plugins-message-container invalid-feedback" />
                                    </div>
                                    {/*end::Input group*/}
                                    <div className="row">
                                        {/*begin::Input group*/}
                                        <div className="col-lg-12 col-md-12">
                                            <div className="fv-row mb-10">
                                                {/*end::Label*/}
                                                <label className="form-label required">
                                                    Certificate
                                                </label>
                                                {/*end::Label*/}
                                                {/*begin::Input*/}
                                                <input type="hidden" name="type" defaultValue="Custom" />
                                                <textarea
                                                    name="certificate_key"
                                                    id="certificate_field"
                                                    className="form-control form-control-lg "
                                                    rows={7}
                                                    defaultValue={""}
                                                />
                                                {/*end::Input*/}
                                            </div>
                                            {/*end::Input group*/}
                                        </div>
                                        <div className="col-lg-12 col-md-12">
                                            {/*begin::Input group*/}
                                            <div className="fv-row mb-10">
                                                {/*end::Label*/}
                                                <label className="form-label required">
                                                    Private Key
                                                </label>
                                                {/*end::Label*/}
                                                {/*begin::Input*/}
                                                <textarea
                                                    name="private_key"
                                                    id="privateKey_field"
                                                    className="form-control form-control-lg "
                                                    rows={7}
                                                    defaultValue={""}
                                                />
                                                {/*end::Input*/}
                                            </div>
                                            {/*end::Input group*/}
                                        </div>
                                    </div>
                                    {/*begin::Input group*/}
                                    <div className="fv-row mb-10">
                                        {/*end::Label*/}
                                        <label className="form-label">Certificate chain</label>
                                        {/*end::Label*/}
                                        {/*begin::Input*/}
                                        <textarea
                                            name="certificateChain"
                                            id="certificateChain_field"
                                            className="form-control form-control-lg "
                                            rows={4}
                                            defaultValue={""}
                                        />
                                        {/*end::Input*/}
                                    </div>
                                    {/*end::Input group*/}
                                </form>
                            </div>
                            {/*end::Scroll*/}
                        </div>
                        {/*end::Modal body*/}
                        {/*begin::Modal footer*/}
                        <div className="modal-footer ">
                            {/*begin::Button*/}

                            <a
                                id="ssl-btn"
                                onClick={() => {props.addSslCertificate()}}
                                className="btn btn-primary w-100"
                            >
                                <span className="indicator-label" >+ Add SSL Certificate</span>
                            </a>

                            {/*end::Button*/}
                        </div>
                        {/*end::Modal footer*/}
                        {/*end::Form*/}
                    </div>
                    {/*end::Card body*/}
                </div>
                {/*end::Messenger*/}
            </div>
        </>
    )
}