import { CURRENT_OBJECT_PARENTID, OBJECT_BREADCRUMB, OBJECT_DEPLOY } from "../../../../Redux/Services/constants"

 
const initialState={
   currentObjectParentid:"/",
   objectBreadcrumb:[],
   objectDeploy: [{dcslug: 'innoida', billing: 'monthly', name: '', size: 0, price:0}] 
 }

export default function ObjectReducer(state=initialState,action){


   switch (action.type) {
       case OBJECT_BREADCRUMB: 
           return {...state, objectBreadcrumb:action.data}
       case CURRENT_OBJECT_PARENTID: 
           return {...state, currentObjectParentid:action.data}  
       case OBJECT_DEPLOY: 
           return {objectDeploy:action.data}           
       default:
           return state 
   }
}

