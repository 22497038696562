import { React, useEffect, useState } from 'react';
import copy from "copy-to-clipboard";
import Vpc from '../Vpc/Vpc';
import { Tooltip } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import UseApi from '../../../../../../CustomHooks/useApi';

export default function ConnectionDetails(props) {
  const [databaseInfo, setDatabaseInfo] = useState(null);
  const [databaseUserName, setDatabaseUserName] = useState('')
  const [databasePassword, setDatabasePassword] = useState('')
  const [currentNetworkType, setCurrentNetworkType] = useState('public')
  const [connectionName, setConnectionName] = useState('Connection Parameters')
  const [connectionString, setConnectionString] = useState('Configure this parameters in your server environment to connect.')
  const [databaseName, setDatabaseName] = useState('')
  const [currentDatabaseInfo, setCurrentDatabaseInfo] = useState(null);
  const getDatabaseInfo = () => {
    if (props && props.data) {
      setDatabaseInfo(props.data);
      setCurrentDatabaseInfo(props.currentDatabaseInfo);
    }
  }

  useEffect(() => {
    getDatabaseInfo();
  }, [props])

  const copyToClipboard = (target, targetBy) => {
    var btn_id = document.querySelector("#" + targetBy);
    var target_id = document.querySelector("#" + target);
    if (btn_id && !btn_id.classList.contains('disabled')) {
      var copyText = btn_id.innerHTML;
      var copyEle = target_id.innerHTML;
      btn_id.classList.add('disabled')
      btn_id.style.pointerEvents = "none";
      copy(copyEle);
      btn_id.innerHTML = `<svg
      xmlns="http://www.w3.org/2000/svg"
      width=24px
      height=24px
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9.89557 13.4982L7.79487 11.2651C7.26967 10.7068 6.38251 10.7068 5.85731 11.2651C5.37559 11.7772 5.37559 12.5757 5.85731 13.0878L9.74989 17.2257C10.1448 17.6455 10.8118 17.6455 11.2066 17.2257L18.1427 9.85252C18.6244 9.34044 18.6244 8.54191 18.1427 8.02984C17.6175 7.47154 16.7303 7.47154 16.2051 8.02984L11.061 13.4982C10.7451 13.834 10.2115 13.834 9.89557 13.4982Z"
        fill="black"
      />
    </svg>`;
      setTimeout(() => {
        btn_id.innerHTML = copyText;
        btn_id.style.pointerEvents = "auto";
        btn_id.classList.remove('disabled')
      }, 2000);
    }

  }
  let { clusterid, cloudid } = useParams();
  const downloadSslCertificate = async () => {
    const responseData = await UseApi('get', 'databases/' + clusterid + '/' + cloudid + '/certificate', '');
    const blob = new Blob([responseData], { type: 'application/pem' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = "ssl-" + currentDatabaseInfo?.hostname + '.pem'; // Specify the desired filename here
    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
  }

  const selectDatabaseUserName = (username) => {
    console.log(username);
    // if (username == "dbadmin") {
    //   setDatabasePassword(currentDatabaseInfo?.connection_strings?.password);
    // }
    if (databaseInfo.users && databaseInfo.users.length > 0) {
      databaseInfo.users.map((value, index) => {
        console.log(value.db_user);
        if (value.db_user == username) {
          setDatabaseUserName(value.db_user);
          setDatabasePassword(value.db_password);
        }
      })
    }
  }

  const toggleDatabasePassword = (type, sid) => {
    console.log(type, sid);
    var show_password = document.querySelector("#show_password_cp_" + sid);
    var hide_password = document.querySelector("#hide_password_cp_" + sid);
    console.log(show_password, hide_password);
    if (hide_password && show_password) {
      if (type == true) {
        show_password.classList.remove("d-none");
        hide_password.classList.add("d-none");
      } else {
        show_password.classList.add("d-none");
        hide_password.classList.remove("d-none");
      }
    }
  }
  const changeConnectionMethod = (method) => {
    if (method == 1) {
      setConnectionName('Connection Parameters')
      setConnectionString('Configure this parameters in your server environment to connect.')
    } else if (method == 2) {
      setConnectionName('Connection String')
      setConnectionString('The connection string typically includes details such as the host, port, database name, username, and password, formatted as a string')
    } else {
      setConnectionName("Flags")
      setConnectionString('Flags are used to specify additional options for the connection, such as SSL settings or timeouts.')
    }
    for (var i = 1; i <= 3; i++) {
      if (method == i) {
        var method_sec = document.querySelector("#pool_method_" + i);
        if (method_sec) {
          method_sec.classList.remove("d-none");
        }
      } else {
        var method_sec = document.querySelector("#pool_method_" + i);
        if (method_sec) {
          method_sec.classList.add("d-none");
        }
      }
    }
  }

  useEffect(() => {
    var database_users_list = document.querySelector("#database_users_list");
    var database_list = document.querySelector("#database_list");
    setTimeout(() => {
      if (database_users_list && database_list) {
        database_users_list.selectedIndex = 0;
        database_list.selectedIndex = 0;

        // Manually trigger the change event
        database_users_list.dispatchEvent(new Event('change', { bubbles: true }));
        database_list.dispatchEvent(new Event('change', { bubbles: true }));
      }
    }, 1000); // Adjust the delay if necessary
  }, [databaseInfo]);

  const networkType = (type) => {
    var pool_public_button = document.querySelector("#pool_public_button");
    var pool_private_button = document.querySelector("#pool_private_button");
    if (pool_public_button && pool_private_button) {
      if (type == 'public') {
        setCurrentNetworkType('public');
        pool_public_button.classList.add("active");
        pool_private_button.classList.remove("active");
      } else {
        setCurrentNetworkType('private');
        pool_public_button.classList.remove("active");
        pool_private_button.classList.add("active");
      }
    }
  }

  useEffect(() => {
    if(props){
    setDatabasePassword(props.data?.users[0]?.db_password);
    setDatabaseName(props?.data?.databases[0]?.db_name);
    setDatabaseUserName(props?.data?.users[0]?.db_user);
    }

  }, [currentDatabaseInfo])
  console.log(props);
  // console.log(databaseInfo, "props");
  if (databaseInfo != null && currentDatabaseInfo != null) {
    if (databaseInfo.length != 0 && currentDatabaseInfo.length != 0) {
      return (<>
        <div className="card pt-4 mb-6 mb-xl-9">
          {/*begin::Card header*/}
          <div className="card-header border-0">
            {/*begin::Card title*/}
            {/* <div className="mt-3"> */}
            {/*begin::Title*/}
            <h3 className="card-title align-items-start flex-column">Connection Details
              {/*end::Title*/}
              {/*begin::Notice*/}
              <div className="text-muted fw-bold fs-6">
                Here is your connection details of your database cluster.
              </div>
            </h3>
            {/*end::Notice*/}
            {/* </div> */}
            <div className="card-toolbar">
              <div className="btn btn-sm btn-icon btn-active-light-primary" id="close_ssh" data-bs-toggle="modal" data-bs-target="#kt_modal_1">
                <span className="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x={6}
                      y="17.3137"
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(-45 6 17.3137)"
                      fill="black"
                    />
                    <rect
                      x="7.41422"
                      y={6}
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(45 7.41422 6)"
                      fill="black"
                    />
                  </svg>
                </span>
              </div>
            </div>

            {/*end::Card title*/}
          </div>
          {/*end::Card header*/}
          {/*begin::Card body*/}
          <div className='separator border-2'></div>
          <div className="card-body pt-0 pb-5 mt-15">
            <div className='row'>
              <div className='col-lg-5'>
                <div className="w-100">

                  {/*begin::Input group*/}
                  <div className="mb-10 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                    {/*begin::Label*/}
                    <label className="d-flex align-items-center form-label mb-3">
                      Network Type
                      {/* <Tooltip title="Hello buddy" arrow> */}
                      <i
                        className="fas fa-exclamation-circle ms-2 fs-7 d-none"
                        data-bs-toggle="tooltip"
                        title=""
                        data-bs-original-title="Provide your team size to help us setup your billing"
                        aria-label="Provide your team size to help us setup your billing"
                      />
                      {/* </Tooltip> */}
                    </label>
                    {/*end::Label*/}
                    {/*begin::Row*/}
                    <div className="row mb-2" data-kt-buttons="true">
                      {/*begin::Col*/}
                      <div className="col">
                        {/*begin::Option*/}
                        <label className="sphide btn btn-outline btn-outline-dashed btn-outline-default w-100 active" onClick={(e) => networkType('public')} id="pool_public_button">
                          <input
                            type="radio"
                            className="btn-check btn-sm"
                            name="network_type"
                            defaultValue="public"
                          />
                          <span className="fw-bold fs-3">Public Network</span>
                        </label>
                        {/*end::Option*/}
                      </div>
                      {/*end::Col*/}
                      {/*begin::Col*/}
                      <div className="col">
                        {/*begin::Option*/}
                        <label className="sphide btn btn-outline btn-outline-dashed btn-outline-default w-100" onClick={(e) => networkType('private')} id="pool_private_button">
                          <input
                            type="radio"
                            className="btn-check btn-sm"
                            name="network_type"
                            defaultValue="vpc"
                          />
                          <span className="fw-bold fs-3">VPC Network</span>
                        </label>
                        {/*end::Option*/}
                      </div>
                      {/*end::Col*/}

                    </div>
                    {/*end::Row*/}
                  </div>
                  {/*end::Input group*/}

                  {/*begin::Input group*/}
                  <div className="mb-10 fv-row fv-plugins-icon-container">
                    {/*begin::Label*/}
                    <label className="form-label mb-3">Connection Method</label>
                    {/*end::Label*/}
                    {/*begin::Input*/}
                    <select
                      className="form-select form-select-solid"
                      name="connectionMethod"
                      onChange={(e) => changeConnectionMethod(e.target.value)}
                    >
                      <option value="1">Connection Parameters</option>
                      <option value="2">Connection String</option>
                      <option value="3">Flags</option>
                    </select>
                    {/*end::Input*/}
                  </div>
                  {/*end::Input group*/}

                  {/*begin::Input group*/}
                  <div className="mb-10 fv-row fv-plugins-icon-container">
                    {/*begin::Label*/}
                    <label className="form-label mb-3">Database User</label>
                    {/*end::Label*/}
                    {/*begin::Input*/}
                    <select
                      className="form-select form-select-solid"
                      name="dbusers"
                      id="database_users_list"
                      defaultValue={currentDatabaseInfo?.connection_strings?.user}
                      onChange={(e) => selectDatabaseUserName(e.target.value)}
                    >
                      {/* <option value={currentDatabaseInfo?.connection_strings?.user}>{currentDatabaseInfo?.connection_strings?.user}</option> */}
                      {databaseInfo.users && databaseInfo.users.length > 0 && databaseInfo.users.map((key, index) => {
                        // if(index==0){
                        //   setDatabaseUserName(key.db_user)
                        // }
                        return (
                          <option value={key.db_user}>{key.db_user}</option>
                        )
                      })}
                      {/* <option value="connection_string">User2</option> */}
                    </select>
                    {/*end::Input*/}
                  </div>
                  {/*end::Input group*/}

                  {/*begin::Input group*/}
                  <div className="mb-10 fv-row fv-plugins-icon-container">
                    {/*begin::Label*/}
                    <label className="form-label mb-3">Databse Name</label>
                    {/*end::Label*/}
                    {/*begin::Input*/}
                    <select
                      className="form-select form-select-solid"
                      name="dbname"
                      id="database_list"
                      defaultValue={currentDatabaseInfo?.connection_strings?.database}
                      onChange={(e) => setDatabaseName(e.target.value)}
                    >
                      {/* <option value={currentDatabaseInfo?.connection_strings?.database}>{currentDatabaseInfo?.connection_strings?.database}</option> */}
                      {databaseInfo.databases && databaseInfo.databases.length > 0 && databaseInfo.databases.map((key, index) => {
                        // if(index==0){
                        //   setDatabaseUserName(key.db_user)
                        // }
                        return (
                          <option value={key.db_name}>{key.db_name}</option>
                        )
                      })}
                    </select>
                    {/*end::Input*/}
                  </div>
                  {/*end::Input group*/}

                </div>



              </div>
              <div className='col-lg-7'>
                <div
                  className="collapse show fs-6 ps-10 border"
                >
                  {/*begin::Details*/}
                  <div className="d-flex flex-wrap py-5">
                    <div className="notice d-flex bg-light-primary rounded border-primary border p-6 mb-5 me-10" style={{ maxWidth: '52rem' }}>
                      {/*begin::Icon*/}
                      {/*begin::Svg Icon | path: icons/duotune/general/gen044.svg*/}
                      <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <rect
                            opacity="0.3"
                            x={2}
                            y={2}
                            width={20}
                            height={20}
                            rx={10}
                            fill="black"
                          />
                          <rect
                            x={11}
                            y={14}
                            width={7}
                            height={2}
                            rx={1}
                            transform="rotate(-90 11 14)"
                            fill="black"
                          />
                          <rect
                            x={11}
                            y={17}
                            width={2}
                            height={2}
                            rx={1}
                            transform="rotate(-90 11 17)"
                            fill="black"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}
                      {/*end::Icon*/}
                      {/*begin::Wrapper*/}
                      <div className="d-flex flex-stack flex-grow-1">
                        {/*begin::Content*/}
                        <div className="fw-bold">
                          <h4 className="text-gray-900 fw-bolder" id="connection_method_head">{connectionName}</h4>
                          <div className="fs-6 text-gray-700">
                            {connectionString}
                          </div>
                        </div>
                        {/*end::Content*/}
                      </div>
                      {/*end::Wrapper*/}
                    </div>


                    {/*begin::Col*/}

                    <div className="flex-equal me-5" style={{ minHeight: "201px" }}>
                      <div id="pool_method_1" className='mt-5'>
                        <table className="table table-flush fw-bold gy-1">
                          <tbody>
                            <tr>
                              <td className="text-dark fw-bolder w-75px">username</td>
                              <td className="text-muted w-25px">=</td>
                              <td className="text-gray-800 w-175px"><span id="pool_dbuser_val">{databaseUserName}</span>
                                {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen054.svg*/}
                                <span className="svg-icon svg-icon-muted svg-icon-2 ms-3" id="pool_dbuser_btn" onClick={(e) => copyToClipboard('pool_dbuser_val', 'pool_dbuser_btn')}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      opacity="0.5"
                                      d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                                      fill="black"
                                    />
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                                      fill="black"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}
                              </td>
                            </tr>
                            <tr>
                              <td className="text-dark fw-bolder w-75px">password</td>
                              <td className="text-muted w-25px">=</td>
                              <td className="text-gray-800 w-175px">
                                <span id="show_password_cp_1" className='d-none text-primary'><span id="pool_dbpass_val">{databasePassword != '' ? (databasePassword) : ('')}</span> <a onClick={(e) => toggleDatabasePassword(false, '1')} className='text-primary'>hide</a></span>
                                <span id="hide_password_cp_1">************************  <a onClick={(e) => toggleDatabasePassword(true, '1')} className='text-primary'>show</a></span>
                                {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen054.svg*/}
                                <span className="svg-icon svg-icon-muted svg-icon-2 ms-3" id="pool_dbpass_btn" onClick={(e) => copyToClipboard('pool_dbpass_val', 'pool_dbpass_btn')}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      opacity="0.5"
                                      d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                                      fill="black"
                                    />
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                                      fill="black"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}
                              </td>
                            </tr>
                            <tr>
                              <td className="text-dark fw-bolder w-75px">host</td>
                              <td className="text-muted w-25px">=</td>
                              <td className="text-gray-800 w-175px"><span id="pool_dbhost_val">{currentNetworkType == "public" ? (<>{currentDatabaseInfo?.connection_strings?.host}</>) : (<>{currentDatabaseInfo?.connection_strings?.host_private}</>)}</span>
                                {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen054.svg*/}
                                <span className="svg-icon svg-icon-muted svg-icon-2 ms-3" id="pool_dbhost_btn" onClick={(e) => copyToClipboard('pool_dbhost_val', 'pool_dbhost_btn')}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      opacity="0.5"
                                      d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                                      fill="black"
                                    />
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                                      fill="black"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}
                              </td>
                            </tr>
                            <tr>
                              <td className="text-dark fw-bolder w-75px">port</td>
                              <td className="text-muted w-25px">=</td>
                              <td className="text-gray-800 w-175px"><span id="pool_dbport_val">{currentDatabaseInfo?.connection_strings?.port}</span>
                                {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen054.svg*/}
                                <span className="svg-icon svg-icon-muted svg-icon-2 ms-3" id="pool_dbport_btn" onClick={(e) => copyToClipboard('pool_dbport_val', 'pool_dbport_btn')}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      opacity="0.5"
                                      d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                                      fill="black"
                                    />
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                                      fill="black"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}</td>
                            </tr>
                            <tr>
                              <td className="text-dark fw-bolder w-75px">database</td>
                              <td className="text-muted w-25px">=</td>
                              {/* {databaseInfo.databases()} */}
                              <td className="text-gray-800 w-175px"><span id="pool_dbname_val">{databaseName != '' ? (databaseName) : ('')}</span>
                                {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen054.svg*/}
                                <span className="svg-icon svg-icon-muted svg-icon-2 ms-3" id="pool_dbname_btn" onClick={(e) => copyToClipboard('pool_dbname_val', 'pool_dbname_btn')}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      opacity="0.5"
                                      d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                                      fill="black"
                                    />
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                                      fill="black"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}</td>
                            </tr>
                            <tr>
                              <td className="text-dark fw-bolder w-75px">ssl</td>
                              <td className="text-muted w-25px">=</td>
                              <td className="text-gray-800 w-175px"><span id="pool_dbssl_val">require</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div id="pool_method_2" className='mt-5 p-0 pe-5 d-none'>
                        <table className="table table-flush fw-bold me-5">
                          <tbody>
                            <tr>
                              <td className='border-0 rounded p-5 bg-light-dark'>
                                <pre class="sc-jrrFIm fs-4" style={{ whiteSpace: "normal" }}>postgresql://{databaseUserName}:<span id="show_password_cp_2" onClick={(e) => toggleDatabasePassword(false, '2')} className='d-none  text-primary'>{databasePassword != '' ? (databasePassword) : ('')}</span><span id="hide_password_cp_2" onClick={(e) => toggleDatabasePassword(true, '2')} className='text-primary'>show password</span>@{currentNetworkType == "public" ? (<>{currentDatabaseInfo?.connection_strings?.host}</>) : (<>{currentDatabaseInfo?.connection_strings?.host_private}</>)}:{currentDatabaseInfo?.connection_strings?.port}/{databaseName}?sslmode=require</pre>
                                <span className='d-none' id="pool_connection_string_value">postgresql://{databaseUserName}:{databasePassword != '' ? (databasePassword) : ('')}@{currentNetworkType == "public" ? (<>{currentDatabaseInfo?.connection_strings?.host}</>) : (<>{currentDatabaseInfo?.connection_strings?.host_private}</>)}:{databaseInfo?.port}/{databaseName}?sslmode=require</span>
                                <div className='d-flex' style={{ alignItems: 'center', height: '24px' }}>
                                <span className='text-primary fs-6' id="pool_connection_string_btn" style={{ display: 'inline-block', width: '85px', paddingTop: '3px' }} onClick={(e) => copyToClipboard('pool_connection_string_value', 'pool_connection_string_btn')}>Copy String</span>
                                {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen054.svg*/}
                                <span className="svg-icon svg-icon-primary svg-icon-2 ms-3 pt-2" style={{ display: 'inline-block', verticalAlign: 'middle' }} onClick={(e) => copyToClipboard('pool_connection_string_value', 'pool_connection_string_btn')}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      opacity="0.5"
                                      d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                                      fill="black"
                                    />
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                                      fill="black"
                                    />
                                  </svg>
                                </span>
                                </div>
                                {/*end::Svg Icon*/}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div id="pool_method_3" className='mt-5 p-0 pe-5 d-none'>
                        <table className="table table-flush fw-bold me-5">
                          <tbody>
                            <tr>
                              <td className='border-0 rounded p-5 bg-light-dark'>
                                <pre class="sc-jrrFIm fs-4" style={{ whiteSpace: "normal" }}>PGPASSWORD=<span id="show_password_cp_3" onClick={(e) => toggleDatabasePassword(false, '3')} className='d-none  text-primary'>{databasePassword != '' ? (databasePassword) : ('')}</span><span id="hide_password_cp_3" onClick={(e) => toggleDatabasePassword(true, '3')} className='text-primary'>show password</span> psql -U {databaseUserName} -h {currentNetworkType == "public" ? (<>{currentDatabaseInfo?.connection_strings?.host}</>) : (<>{currentDatabaseInfo?.connection_strings?.host_private}</>)} -p {currentDatabaseInfo?.connection_strings?.port} -d {databaseName} --set=sslmode=require</pre>
                                <span className='d-none' id="pool_connection_flags_value">PGPASSWORD={databasePassword != '' ? (databasePassword) : ('')} psql -U {databaseUserName} -h {currentNetworkType == "public" ? (<>{currentDatabaseInfo?.connection_strings?.host}</>) : (<>{currentDatabaseInfo?.connection_strings?.host_private}</>)} -p {databaseInfo?.port} -d {databaseName} --set=sslmode=require</span>
                                <div className='d-flex' style={{ alignItems: 'center', height: '24px' }}>
                                <span className='text-primary fs-6' id="pool_connection_flags_btn" style={{ display: 'inline-block', width: '85px', paddingTop: '3px' }} onClick={(e) => copyToClipboard('pool_connection_flags_value', 'pool_connection_flags_btn')}>Copy String</span>
                                {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen054.svg*/}
                                <span className="svg-icon svg-icon-primary svg-icon-2 ms-3 pt-2" style={{ display: 'inline-block', verticalAlign: 'middle' }} onClick={(e) => copyToClipboard('pool_connection_flags_value', 'pool_connection_flags_btn')}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      opacity="0.5"
                                      d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                                      fill="black"
                                    />
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                                      fill="black"
                                    />
                                  </svg>
                                </span>
                                </div>
                                {/*end::Svg Icon*/}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/*end::Col*/}
                    {databaseInfo?.is_ssl != 0 ? (<>
                      <div className="d-flex flex-stack mb-5 mt-2 rounded border border-secondary-2 me-10">
                        {/*begin::Section*/}
                        <div className="d-flex align-items-center ms-2 me-10">
                          {/*begin::Symbol*/}
                          <div className="symbol symbol-40px me-2 ms-2">
                            <div className="symbol-label bg-light">
                              <svg
                                viewBox="0 0 50 50"
                                enableBackground="new 0 0 50 50"
                                id="Layer_1"
                                version="1.1"
                                xmlSpace="preserve"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                fill="#000000"
                              >
                                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <g>
                                    {" "}
                                    <path
                                      d="M45.47913,42.49568H4.5208c-1.9445,0-3.52083-1.57633-3.52083-3.52083V6.5606 c0-1.9445,1.57633-3.52083,3.52083-3.52083h40.95833c1.9445,0,3.52083,1.57633,3.52083,3.52083v32.41425 C48.99996,40.91935,47.42363,42.49568,45.47913,42.49568z"
                                      fill="#E7E3E6"
                                    />{" "}
                                    <g>
                                      {" "}
                                      <path
                                        d="M19.02511,16.47119c-0.13232,0.1084-0.19873,0.25293-0.19873,0.43262s0.08105,0.32227,0.24268,0.42871 c0.16211,0.10547,0.53613,0.23047,1.12207,0.375c0.58545,0.14453,1.04053,0.36035,1.36426,0.64941 c0.32373,0.28809,0.48584,0.70898,0.48584,1.2627s-0.20752,1.00293-0.62256,1.34668 c-0.41504,0.34473-0.96143,0.5166-1.63818,0.5166c-0.97754,0-1.85791-0.36133-2.64111-1.08594l0.82129-1.00684 c0.66553,0.58301,1.28076,0.87402,1.84619,0.87402c0.25293,0,0.45166-0.05371,0.59619-0.16309 c0.14404-0.10938,0.21631-0.25586,0.21631-0.44141s-0.07666-0.33301-0.22949-0.44141 c-0.15332-0.10938-0.45654-0.21973-0.90967-0.33203c-0.71875-0.16992-1.24414-0.39258-1.57666-0.66602 c-0.33301-0.27441-0.49902-0.7041-0.49902-1.29004s0.21045-1.03808,0.63135-1.35547 c0.4209-0.31836,0.94629-0.47754,1.57666-0.47754c0.41211,0,0.82422,0.07129,1.23633,0.21289 c0.41211,0.14063,0.77148,0.34082,1.07764,0.60059l-0.69775,1.00683c-0.53564-0.40625-1.08936-0.60938-1.66064-0.60938 C19.33859,16.30811,19.15744,16.36182,19.02511,16.47119z"
                                        fill="#85BD57"
                                      />{" "}
                                      <path
                                        d="M24.65988,16.47119c-0.13232,0.1084-0.19873,0.25293-0.19873,0.43262s0.08105,0.32227,0.24268,0.42871 c0.16211,0.10547,0.53613,0.23047,1.12207,0.375c0.58545,0.14453,1.04053,0.36035,1.36426,0.64941 c0.32373,0.28809,0.48584,0.70898,0.48584,1.2627s-0.20752,1.00293-0.62256,1.34668 c-0.41504,0.34473-0.96143,0.5166-1.63818,0.5166c-0.97754,0-1.85791-0.36133-2.64111-1.08594l0.82129-1.00684 c0.66553,0.58301,1.28076,0.87402,1.84619,0.87402c0.25293,0,0.45166-0.05371,0.59619-0.16309 c0.14404-0.10938,0.21631-0.25586,0.21631-0.44141s-0.07666-0.33301-0.22949-0.44141 c-0.15332-0.10938-0.45654-0.21973-0.90967-0.33203c-0.71875-0.16992-1.24414-0.39258-1.57666-0.66602 c-0.33301-0.27441-0.49902-0.7041-0.49902-1.29004s0.21045-1.03808,0.63135-1.35547 c0.4209-0.31836,0.94629-0.47754,1.57666-0.47754c0.41211,0,0.82422,0.07129,1.23633,0.21289 c0.41211,0.14063,0.77148,0.34082,1.07764,0.60059l-0.69775,1.00683c-0.53564-0.40625-1.08936-0.60938-1.66064-0.60938 C24.97335,16.30811,24.7922,16.36182,24.65988,16.47119z"
                                        fill="#85BD57"
                                      />{" "}
                                      <path
                                        d="M28.8508,21.4126v-6.17383h1.37744v4.94629h2.63232v1.22754H28.8508z"
                                        fill="#85BD57"
                                      />{" "}
                                    </g>{" "}
                                    <path
                                      d="M48.99997,10.066V6.56059c0-1.94453-1.57633-3.52086-3.52081-3.52086H4.52079 c-1.94448,0-3.52081,1.57633-3.52081,3.52086V10.066H48.99997z"
                                      fill="#53B1E2"
                                    />{" "}
                                    <path
                                      d="M17.06462,10.71367H4.45078v-2.7194c0-0.67552,0.54762-1.22313,1.22313-1.22313h10.16758 c0.67552,0,1.22313,0.54762,1.22313,1.22313V10.71367z"
                                      fill="#E7E3E6"
                                    />{" "}
                                    <path
                                      d="M29.67847,10.066H17.06462V7.99427c0-0.67552,0.54762-1.22313,1.22313-1.22313h10.16758 c0.67552,0,1.22313,0.54762,1.22313,1.22313V10.066z"
                                      fill="#D2D2D2"
                                    />{" "}
                                    <circle cx="40.42995" cy="6.85486" fill="#E7E3E6" r="1.34955" />{" "}
                                    <circle cx="36.3145" cy="6.85486" fill="#E7E3E6" r="1.34955" />{" "}
                                    <g>
                                      {" "}
                                      <polygon
                                        fill="#656766"
                                        points="38.25036,36.57332 40.56499,46.96027 35.88287,44.40302 35.88287,36.57332 "
                                      />{" "}
                                      <polygon
                                        fill="#4E4C4D"
                                        points="33.51537,36.57332 31.20074,46.96027 35.88287,44.40302 35.88287,36.57332 "
                                      />{" "}
                                      <path
                                        d="M41.5854,35.56115l-0.00003,0.00003v0.00006c0,1.84353-1.49448,3.33801-3.33801,3.33801h0l-0.006,0.006 c-1.30357,1.30357-3.41708,1.30357-4.72065,0l-0.006-0.006h-0.00006c-1.84353,0-3.33801-1.49448-3.33801-3.33801v-0.00901 l-0.00006-0.00006c-1.30189-1.30192-1.30187-3.41271,0.00003-4.71461l0.00003-0.00003v-0.01326 c0-1.84118,1.49257-3.33375,3.33375-3.33375h0.01332l0,0c1.30191-1.30191,3.41272-1.30191,4.71464,0v0h0.00896 c1.84356,0,3.33806,1.4945,3.33806,3.33806v0l0,0C42.89226,32.13543,42.89227,34.25428,41.5854,35.56115z"
                                        fill="#EC6E62"
                                      />{" "}
                                      <circle cx="35.88287" cy="33.19849" fill="#FFC966" r="3.37482" />{" "}
                                    </g>{" "}
                                    <g>
                                      {" "}
                                      <rect
                                        fill="#D2D2D2"
                                        height="2.22496"
                                        width="18.92076"
                                        x="5.64066"
                                        y="26.51408"
                                      />{" "}
                                      <rect
                                        fill="#D2D2D2"
                                        height="2.22496"
                                        width="18.92076"
                                        x="5.64066"
                                        y="30.83174"
                                      />{" "}
                                      <rect
                                        fill="#D2D2D2"
                                        height="2.22491"
                                        width="11.15332"
                                        x="5.64065"
                                        y="35.14944"
                                      />{" "}
                                    </g>{" "}
                                  </g>{" "}
                                </g>
                              </svg>

                            </div>
                          </div>
                          {/*end::Symbol*/}
                          {/*begin::Title*/}
                          <div>
                            <a href="#" className="fs-6 text-gray-800 text-hover-primary fw-bolder">
                              Download CA Certificate
                            </a>
                            <div className="fs-8 text-muted fw-bold mt-1">
                              This Certificate attached with your Database cluster
                            </div>
                          </div>
                          {/*end::Title*/}
                        </div>
                        {/*end::Section*/}
                        {/*begin::Label*/}
                        <div className="fw-bold py-4 px-3" style={{marginLeft:'9.7rem'}}>
                          {/*begin::Svg Icon | path: assets/media/icons/duotune/arrows/arr091.svg*/}
                          <span onClick={downloadSslCertificate} className="svg-icon svg-icon-primary svg-icon-2hx" style={{ cursor: "pointer" }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <rect
                                opacity="0.3"
                                width={12}
                                height={2}
                                rx={1}
                                transform="matrix(0 -1 -1 0 12.75 19.75)"
                                fill="black"
                              />
                              <path
                                d="M12.0573 17.8813L13.5203 16.1256C13.9121 15.6554 14.6232 15.6232 15.056 16.056C15.4457 16.4457 15.4641 17.0716 15.0979 17.4836L12.4974 20.4092C12.0996 20.8567 11.4004 20.8567 11.0026 20.4092L8.40206 17.4836C8.0359 17.0716 8.0543 16.4457 8.44401 16.056C8.87683 15.6232 9.58785 15.6554 9.9797 16.1256L11.4427 17.8813C11.6026 18.0732 11.8974 18.0732 12.0573 17.8813Z"
                                fill="black"
                              />
                              <path
                                d="M18.75 15.75H17.75C17.1977 15.75 16.75 15.3023 16.75 14.75C16.75 14.1977 17.1977 13.75 17.75 13.75C18.3023 13.75 18.75 13.3023 18.75 12.75V5.75C18.75 5.19771 18.3023 4.75 17.75 4.75L5.75 4.75C5.19772 4.75 4.75 5.19771 4.75 5.75V12.75C4.75 13.3023 5.19771 13.75 5.75 13.75C6.30229 13.75 6.75 14.1977 6.75 14.75C6.75 15.3023 6.30229 15.75 5.75 15.75H4.75C3.64543 15.75 2.75 14.8546 2.75 13.75V4.75C2.75 3.64543 3.64543 2.75 4.75 2.75L18.75 2.75C19.8546 2.75 20.75 3.64543 20.75 4.75V13.75C20.75 14.8546 19.8546 15.75 18.75 15.75Z"
                                fill="#C4C4C4"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </div>
                        {/*end::Label*/}
                      </div>
                    </>) : ('')}
                  </div>
                  {/*end::Details*/}
                </div>

              </div>
            </div>

            <Vpc data={databaseInfo} />
          </div>
          {/*end::Card body*/}
        </div>
      </>);
    }
  }
}