import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UsePost from "../../../../CustomHooks/usePost";
import { toast } from "react-toastify";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import {InputValidation} from '../../utils/input-validation/InputValidation'

export default function RecieveResource() {
  const navigate = useNavigate();
  var query = window.location.href;
  const [resourceType, setResourceType] = useState("dns");
  // const [id, setId] = useState("");
  const [token, setToken] = useState("");
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [marginBottom, setMarginBottom] = useState(window.innerWidth >= 768 ? '0rem' : '5rem');

  const handleResourceType = (event) => {
    setResourceType(event.target.value);
  };

  useEffect(() => {
    const handleResize = () => {
      setMarginBottom(window.innerWidth >= 768 ? '0rem' : '5rem');
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleAttachResource = async () => {
    toast.dismiss();
    var submitBtn = document.querySelector("#attachresource-btn");
    var resource_id = document.querySelector("#resource_id");
    var token_id = document.querySelector("#token_id");

    if (resource_id && token_id) {
      if (resource_id.value == "" || token_id.value == "") {
        const dtAttachResource = toast.loading("Attach resource starting...");
        setTimeout(() => {
          toast.update(dtAttachResource, { render: "All fields are required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined })
        }, 2000)
        return;
      }
    }
    if (submitBtn) {
      setLoaderStatus(true);
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';

      const dtAttachResource = toast.loading("Attach resource starting...");
      const data = await UsePost('post', 'transfer/process', 'attachResource');
      if (data) {
        setLoaderStatus(false);
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
        if (data.status == "error") {
          toast.update(dtAttachResource, { render: data.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined })
        } else {
          toast.update(dtAttachResource, {
            render: data.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          })
          var resource_id = document.querySelector("#resource_id");
          var resource_type = document.querySelector("#resource-type");
          if (resource_id && resource_type) {
            var id_value = resource_id.value;
            var type_value = resource_type.value;
            const url = window.location.href
            if (query == url) {
              setTimeout(() => {
                if (type_value != "cloud") {
                  navigate("/" + type_value);
                } else {
                  navigate("/" + type_value + "/" + id_value);
                }
              }, 2000)
            }
          }
        }
      }
    }
  }
  return (
    <>
      <div className="col-xl-12 card mb-5">
        {/*begin::Header*/}
        <div className="card-header pt-5 pb-5 border-2">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder text-gray-800 mb-1" >Receive the resources</span>
            <span className="text-gray-400 fw-bold fs-6">Accept resources from another user.</span>
            {/* <ol class="breadcrumb text-muted fs-6 fw-bold">

              <li class="breadcrumb-item pe-3"><Link to="/resources"><a href="#" class="pe-3">resources</a></Link></li>

              <li class="breadcrumb-item pe-3"><a href="#" class="pe-3 text-muted">receive</a></li>
            </ol> */}
          </h3>
          <div className="card-toolbar">
            {/* <div style={{ marginRight: 20 }}>
              <div className="dataTables_length">

                <label className="w-100"> */}
            <Link to="/resource/transfer">
            <a className="btn btn-sm btn-primary" style={{ padding: '0.16rem 1rem' }} >

              {/*begin::Svg Icon | path: assets/media/icons/duotune/files/fil022.svg*/}
              <span className="svg-icon svg-icon-light svg-icon-2hx">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    opacity="0.3"
                    d="M5 16C3.3 16 2 14.7 2 13C2 11.3 3.3 10 5 10H5.1C5 9.7 5 9.3 5 9C5 6.2 7.2 4 10 4C11.9 4 13.5 5 14.3 6.5C14.8 6.2 15.4 6 16 6C17.7 6 19 7.3 19 9C19 9.4 18.9 9.7 18.8 10C18.9 10 18.9 10 19 10C20.7 10 22 11.3 22 13C22 14.7 20.7 16 19 16H5ZM8 13.6H16L12.7 10.3C12.3 9.89999 11.7 9.89999 11.3 10.3L8 13.6Z"
                    fill="black"
                  />
                  <path
                    d="M11 13.6V19C11 19.6 11.4 20 12 20C12.6 20 13 19.6 13 19V13.6H11Z"
                    fill="black"
                  />
                </svg>
              </span>
              {/*end::Svg Icon*/}
              Transfer Resource
              </a>
            </Link>
            {/* </label> */}
            {/* </div>
            </div> */}
          </div>
        </div>
        {/*end::Header*/}
      </div>
      <form id="attachResource">
        <div className="col-xl-12 card mb-5">
          {/*begin::List widget 10*/}
          {/*begin::Header*/}
          <div className="card-header border-2">
            {/*begin::Title*/}
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bolder text-gray-800">
                Choose Resource Type &nbsp;&nbsp;
              </span>
            </h3>
            {/*end::Title*/}
          </div>
          {/*end::Header*/}
          {/*begin::Body*/}
          <div className=" row card-body ps-9">
            <div className="col-md-6 col-sm-6 col-xl-6 col-lg-6 col-xxl-6">
              <select
                className="form-select"
                id="resource-type"
                name="type"
                onChange={handleResourceType}
              >
                <option value="cloud">Cloud Instance</option>
                {/* <option value="kubernetes">Kubernetes</option> */}
                {/* <option value="database">Database</option> */}
                {/* <option value="object">Object Storage</option> */}
                {/* <option value="loadbalancer">Load Balancer</option> */}
                {/* <option value="firewall">Cloud Firewall</option> */}
                <option value="dns">DNS</option>
                {/* <option value="snapshots">Snapshots</option> */}
                {/* <option value="backups">Backups</option> */}
                <option value="iso">ISO</option>
                {/* <option value="ssh">SSH</option> */}
                {/* <option value="api">API</option>   */}
              </select>
            </div>
          </div>
          {/*end: Card Body*/}
          {/*end::List widget 10*/}
        </div>
        <div className="col-xl-12 card mb-5">
          {/*begin::List widget 10*/}
          {/*begin::Header*/}
          <div className="card-header border-2">
            {/*begin::Title*/}
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bolder text-gray-800">
                Enter the resource ID &nbsp;&nbsp;
              </span>
            </h3>
            {/*end::Title*/}
          </div>
          {/*end::Header*/}
          {/*begin::Body*/}
          <div className=" row card-body">
            <div className="col-md-3 col-lg-3 col-xxl-3 col-sm-3 col-xl-3">
              <input type="text" className="form-control" name="id" id="resource_id" placeholder="Ex: 415266" onChange={(e) => InputValidation(e,"number")} />
            </div>
          </div>
          {/*end: Card Body*/}
          {/*end::List widget 10*/}
        </div>
        <div className="col-xl-12 card mb-5">
          {/*begin::List widget 10*/}
          {/*begin::Header*/}
          <div className="card-header border-2">
            {/*begin::Title*/}
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bolder text-gray-800">
                Enter the resource access token &nbsp;&nbsp;
              </span>
            </h3>
            {/*end::Title*/}
          </div>
          {/*end::Header*/}
          {/*begin::Body*/}
          <div className=" row card-body ps-9">
            <div className="col-md-6 col-lg-6 col-xxl-6">
              <input type="text" className="form-control" id="token_id" name="token" placeholder="Ex: FJUFD2YFJUFyu32qhjVCU3HJ3Fjhvjhv3432HG" defaultValue={token} onChange={(e) => setToken(e.target.value)} />
            </div>
          </div>
          {/*end: Card Body*/}
          {/*end::List widget 10*/}
        </div>

        <div className="col-xl-12 card" style={{ marginBottom: marginBottom }}>
          {/*begin::List widget 10*/}
          {/*begin::Header*/}
          <div className="card-header border-2">
            {/*begin::Title*/}
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bolder text-gray-800">
                Attach the resource to your account &nbsp;&nbsp;
              </span>
            </h3>
            {/*end::Title*/}
          </div>
          {/*end::Header*/}
          {/*begin::Body*/}
          <div className="card-body">
            <a id="attachresource-btn" className="btn btn-primary w-100" onClick={handleAttachResource}>Attach Resource </a>

          </div>
          {/*end: Card Body*/}
          {/*end::List widget 10*/}
        </div></form>

      {loaderStatus == true ? (<>
        <TopBarHeaderLine />
      </>) : ('')}
    </>
  )
}