import { combineReducers } from "redux";  
import CloudReducer from "../../../MicroApp/Body/Products/cloud/CloudReducer";
import KubernetesReducer from "../../../MicroApp/Body/Products/kubernetes/KubernetesReducer";
import DatabaseReducer from "../../../MicroApp/Body/Products/databases/DatabaseReducer";
import ObjectReducer from "../../../MicroApp/Body/Products/objectStorage/ObjectReducer";
import PricingReducer from "../../../MicroApp/Body/Products/pricing/PricingReducer";
import ActivityReducer from "../../../MicroApp/Body/Products/activity/ActivityReducer";
import CloudListReducer from "../../../MicroApp/Body/Products/cloud/CloudListReducer";
import CloudDeployReducer from "../../../MicroApp/Body/Products/cloud/CloudDeployReducer";
import AutoScalingReducer from "../../../MicroApp/Body/Products/autoScaling/AutoScalingReducer";
import LoadbalancerReducer from "../../../MicroApp/Body/Products/loadbalancer/LoadbalancerReducer";
const rootReducer= combineReducers({
    cloud:CloudReducer, 
    cloudDeployData:CloudDeployReducer, 
    cloudList:CloudListReducer, 
    kubernetes:KubernetesReducer, 
    databaseClusterCount:DatabaseReducer, 
    objectCluster:ObjectReducer, 
    planData:PricingReducer, 
    activityStore:ActivityReducer,
    autoScalingReducerData:AutoScalingReducer,
    aclConditionInfo:LoadbalancerReducer,

})

export default rootReducer