import CryptoJS from 'crypto-js';

export function capitalizeFirstLetter(word) {
    if (word.length === 0) {
        return word;
    }
    return word.charAt(0).toUpperCase() + word.slice(1);
}

export function formatISODate(isoDate) {
    const date = new Date(isoDate);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function getQueryParams() {
    const params = {};
    const queryString = window.location.search.substring(1);
    const queryArray = queryString.split('&');
    queryArray.forEach(function(param) {
        const pair = param.split('=');
        params[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    });
    return params;
}

export const randomid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };


  export function allowNumbersOnly(event) {
    var charCode = event.which ? event.which : event.keyCode;

    // Allow numbers (0-9), delete, backspace, arrow keys, and tab
    if (charCode > 31 && (charCode < 48 || charCode > 57) &&
        charCode !== 8 && charCode !== 46 &&
        charCode !== 37 && charCode !== 38 && charCode !== 39 && charCode !== 40 &&
        charCode !== 9) {
        event.preventDefault();
    }
}

export const handleInputChange = (e, allowSpace=true, allowNumeric=true) => {
    let inputValue = e.target.value;
    // Create a regex pattern based on the allowSpace and allowNumeric parameters
    let regexPattern = '[^a-zA-Z';
    if (allowSpace) {
        regexPattern += ' ';
    }
    if (allowNumeric) {
        regexPattern += '0-9';
    }
    regexPattern += '_-]';
    const regex = new RegExp(regexPattern, 'g');
    // Remove characters not matching the regex pattern
    const filteredValue = inputValue.replace(regex, '');
    // Update the input value with only the allowed characters
    e.target.value = filteredValue;
};

export function convertKbSize(kb) {
    const mb = kb / 1024; // Convert KB to MB
    const gb = mb / 1024; // Convert MB to GB
  
    if (gb >= 1) {
      return `${gb.toFixed(2)} GB`; // If size is greater than or equal to 1 GB, return in GB
    } else {
      return `${mb.toFixed(2)} MB`; // Otherwise, return in MB
    }
  }

export function getStringRange(longString, endIndex) {
    // Ensure the endIndex is within the bounds of the string length
    if (endIndex < 0 || endIndex > longString.length) {
      return "End index is out of bounds.";
    }
  
    // Use the substring method to get the range
    return longString.substring(0, endIndex);
  }

export  function createAlphanumericHash(inputString) {
   
    const userHash = CryptoJS.MD5(inputString).toString();

    return userHash;
}
 
export function openInNewTabAndClose() {
  // Open the current page in a new tab 
  const currUrl = window.location.href;
  window.location.replace('about:blank');
  window.location.href = currUrl;

  // Attempt to close the current tab
  // Note: This will work only if the tab was opened by JavaScript
   
}
