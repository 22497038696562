import React, { useEffect, useRef, useState } from "react";
import { Link ,useNavigate } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UsePost from "../../../../CustomHooks/usePost";
import swal from 'sweetalert';
import validator from 'validator';
import { stateObject } from "./state";
import { countryObject } from "./country"; 
import localStorage from "local-storage"; 
import Cookies from "js-cookie";
import ApplyCoupon from "../../../../Auth/ApplyCoupon";
import TableLoader from "../../Loaders/TableLoader";
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
export default function CompleteProfile(props){ 
  const [userData,setUserData]=useState(null);
  const dataFetchedRef=useRef (false);
  const navigate=useNavigate(); 
  const [stateData,setStateData]=useState('');

  function updateStates(countryValue){ 
    const newStateData = stateObject
      .filter(state => state.country_code === countryValue)
      .map(state => state.name);
    // console.log(newStateData);
    setStateData(newStateData);
  }

  const getUser=async()=>{ 
    if(props && props.userData){
      const data=props.userData;
        if(data && data.country){
          updateStates(data.country);
            }
        setUserData(data);   
      }
  }
  useEffect(()=>{
    if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
      toast.dismiss();
      getUser(); 
  },[]);   
  
  const filledValues=()=>{
    if(userData!=null){
    updateBusiness(userData.type);
    }
  }

  useEffect(()=>{
    filledValues();
  },[userData])

  const updateAccount=async ()=>{ 
    toast.dismiss();
    const dt=toast.loading("Please wait..."); 
    var isFields=checkAllField();
    if(!isFields){
      toast.update(dt,{render: "All fields are required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      }); 
      return;
    }
   
   const submitBtn=document.querySelector("#updateAccountBtn");
   if(submitBtn){
     var preContent=submitBtn.innerHTML; 
     submitBtn.classList.add("disabled");
     submitBtn.innerHTML='Please wait... <span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';

    const responseData=await UsePost('post','account/update','updateprofile');
    if(responseData){
      //console.log(responseData);
      if(responseData.status==='error'){
        toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      }); 
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML=preContent;
      }else{
        window.afterAccountTypeSelection();
        toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });  
        
        const userInfo=await UseApi('get','account/info','');  
        if(userInfo){
        localStorage.remove("user_info");
        localStorage.set("user_info",userInfo.user);  
        window.location.reload(); 
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML=preContent;  
       
        }
      }
    }
    }
  }
 
 const updateBusiness=(value)=>{
      var individual_field=document.querySelector("#individual_field");
      var business_field=document.querySelector("#business_field");
      var formf_accountType=document.querySelector("#formf_accountType"); 
      var business_section=document.querySelector("#business_section");
      if(business_section && individual_field && business_field && formf_accountType){
        if(value=="Individual"){ 
          formf_accountType.value=value;
          individual_field.classList.add("active");
          business_field.classList.remove("active");
          business_section.classList.add("d-none");
        }else{
          formf_accountType.value=value;
          individual_field.classList.remove("active");
          business_field.classList.add("active");
          business_section.classList.remove("d-none");
        }
      }
 }

 const checkAllField=()=>{
      var formf_fullname=document.querySelector("#formf_fullname");
      var formf_accountType=document.querySelector("#formf_accountType");
      var formf_company=document.querySelector("#formf_company");
      var formf_gstnumber=document.querySelector("#formf_gstnumber");
      var formf_address=document.querySelector("#formf_address");
      var formf_city=document.querySelector("#formf_city");
      var formf_postcode=document.querySelector("#formf_postcode");
      var formf_country=document.querySelector("#formf_country");
      var selectState=document.querySelector("#selectState"); 
      if(formf_fullname && formf_accountType && formf_company && formf_address && formf_city && formf_postcode && formf_country && selectState){
        
        if(formf_accountType.value!=""){
        if(formf_accountType.value=="Individual"){
          if(formf_fullname.value!="" && formf_address.value!="" && formf_city.value!="" && formf_postcode.value!="" && formf_country.value!="" && selectState.value!=""){
            return true;
          }
        }else{
          if(formf_fullname.value!="" && formf_accountType.value!="" && formf_company.value!=""  && formf_address.value!="" && formf_city.value!="" && formf_postcode.value!="" && formf_country.value!="" && selectState.value!=""){
            return true;
          }
        }
      }else{
           return false;
      }
      return false;  
      } 
 }
    return(
        <>  
         {userData!=null?(<> 
          <form id="updateprofile" name="updateprofile" className="form">
          {/*begin::Input group*/}
        <div className="row fv-row mb-7 fv-plugins-icon-container">
          {/*begin::Col*/}
          <div className="col-xl-12">
            <label className="form-label fw-bolder text-gray-400 fs-6 required">
              Account type
            </label> 
  {/*begin::Radio group*/}
  <div
    className="btn-group w-100 mb-5"
    data-kt-buttons="true"
    data-kt-buttons-target="[data-kt-button]"
  >
    {/*begin::Radio*/}
    <label
     onClick={(e)=>updateBusiness("Individual")}
       id="individual_field"
      className="btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success active"
      data-kt-button="true"
    >
      {/*begin::Input*/}
      <input
        className="btn-check"
        type="radio"
        name="accountType"
        defaultValue="Individual"   
      />
      {/*end::Input*/}
      Individual
    </label>
    {/*end::Radio*/}
    {/*begin::Radio*/}
    <label
      id="business_field"
    onClick={(e)=>updateBusiness("Buisness")}
      className="btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success"
      data-kt-button="true"
    >
      {/*begin::Input*/}
      <input
        className="btn-check"
        type="radio"
        name="accountType" 
        defaultValue="Buisness"
      />
      {/*end::Input*/}
      Business
    </label>
    {/*end::Radio*/}
  </div>
  {/*end::Radio group*/} 
    <input type="hidden" name="type" id="formf_accountType" defaultValue={userData!=null && userData.type!=null?(userData.type):('')}/>
             
          </div>
          {/*end::Col*/}

          {/*begin::Col*/}
          <div className="col-xl-12 mt-7">
            <label className="form-label fw-bolder text-gray-400 fs-6 required">
              Full Name
            </label>
            <input
              className="form-control form-control-lg form-control-solid"
              type="text"
              id="formf_fullname"
              name="fullname"
              defaultValue={userData!=null && userData.fullname!=null?(userData.fullname):('')}
              autoComplete="off"
            />
            <div className="fv-plugins-message-container invalid-feedback" />
          </div>
          {/*end::Col*/} 
        </div>
        {/*end::Input group*/}
         <div id="business_section" className="d-none">
          {/*begin::Input group*/}
          <div className="row fv-row mb-7 fv-plugins-icon-container">
          {/*begin::Col*/}
          <div className="col-xl-12">
            <label className="form-label fw-bolder text-gray-400 fs-6 required">
              Company Name
            </label>
            <input
              className="form-control form-control-lg form-control-solid"
              type="text"
              id="formf_company"
              name="company"
              defaultValue={userData!=null && userData.company!=null?(userData.company):('')}
              placeholder="Company Name"
              autoComplete="off"
            />
            <div className="fv-plugins-message-container invalid-feedback" />
          </div>
          {/*end::Col*/}

        </div>
        {/*end::Input group*/}
         {/*begin::Input group*/}
         <div className="row fv-row mb-7 fv-plugins-icon-container">
          {/*begin::Col*/}
          <div className="col-xl-12">
            <label className="form-label fw-bolder text-gray-400 fs-6">
              GST Number
            </label>
            <input
              className="form-control form-control-lg form-control-solid"
              type="text"
              id="formf_gstnumber"
              name="gstnumber"
              placeholder="GST number" 
              defaultValue={userData!=null && userData.gstnumber!=null?(userData.gstnumber):('')}
              autoComplete="off"
            />
            <div className="fv-plugins-message-container invalid-feedback" />
          </div>
          {/*end::Col*/}

        </div>
        {/*end::Input group*/}
        </div>
             
         {/*begin::Input group*/}
         <div className="row fv-row mb-7 fv-plugins-icon-container">
          {/*begin::Col*/}
          <div className="col-xl-12">
            <label className="form-label fw-bolder text-gray-400 fs-6 required">
              Address
            </label>
            <input
              className="form-control form-control-lg form-control-solid"
              type="text"
              id="formf_address"
              name="address"
              defaultValue={userData!=null && userData.address!=null?(userData.address):('')}
              placeholder="Enter the address"
              autoComplete="off"
            />
            <div className="fv-plugins-message-container invalid-feedback" />
          </div>
          {/*end::Col*/}

        </div>
        {/*end::Input group*/}

       
         {/*begin::Input group*/}
         <div className="row fv-row mb-7 fv-plugins-icon-container">
          {/*begin::Col*/}
          <div className="col-xl-6">
            <label className="form-label fw-bolder text-gray-400 fs-6 required">
              City
            </label>
            <input
              className="form-control form-control-lg form-control-solid"
              type="text"
              id="formf_city"
              name="city"
              placeholder="Enter City"
              defaultValue={userData!=null && userData.city!=null?(userData.city):('')}
              autoComplete="off"
            />
            <div className="fv-plugins-message-container invalid-feedback" />
          </div>
          {/*end::Col*/}
   {/*begin::Col*/}
   <div className="col-xl-6">
            <label className="form-label fw-bolder text-gray-400 fs-6 required">
              Postcode
            </label>
            <input
              className="form-control form-control-lg form-control-solid"
              type="text"
              id="formf_postcode"
              name="postcode"
              placeholder="Enter Postcode"
              defaultValue={userData!=null && userData.postcode!=null?(userData.postcode):('')}
              autoComplete="off"
            />
            <div className="fv-plugins-message-container invalid-feedback" />
          </div>
          {/*end::Col*/}
        </div>
        {/*end::Input group*/}

         {/*begin::Input group*/}
         <div className="row fv-row mb-7 fv-plugins-icon-container">
             {/*begin::Col*/}
   <div className="col-xl-6">
            <label className="form-label fw-bolder text-gray-400 fs-6 required">
              Country
            </label>
            <select
                        id="formf_country"
                        name="country" 
                        className="form-select  form-select-lg form-select-solid fw-bold"  
                        onChange={(e)=>updateStates(e.target.value)}
                      > 
                        <option value="">Select Country</option>
                        {countryObject && Object.entries(countryObject).map((value,index)=>(
                          <>
                          {userData!=null && userData.country==value[0]?(
                          <>
                            <option value={value[0]} selected>{value[1]}</option>
                          </>):(
                          <>
                          <option value={value[0]}>{value[1]}</option>
                          </>
                          )} 
                          </>
                        ))}
                      </select>   
          </div>
          {/*end::Col*/}
          {/*begin::Col*/}
          <div className="col-xl-6">
            <label className="form-label fw-bolder text-gray-400 fs-6 required">
              State
            </label>
            <select
              name="state"
              className="form-select form-select-solid form-select-lg fw-bold"
              id="selectState"
            >
              <option value="">Select State</option> 
              {stateData && stateData.map((value,index)=>(
                <>
                {userData!=null && userData.state==value?(
                <><option value={value} selected>{value}</option></>):
                (<><option value={value}>{value}</option></>)}
                </>
              ))}
            </select>
          </div>
          {/*end::Col*/}

        </div>
        {/*end::Input group*/}
    </form> 
       {/*begin::Actions*/}
       <div className="text-center">
          <a
            style={{cursor:"pointer"}}
            className="btn btn-lg btn-primary w-100"
            onClick={updateAccount}
            id="updateAccountBtn"
          >
            <span className="indicator-label" >Save Changes</span>
            </a>
        </div>
        {/*end::Actions*/}
</>):(<><TableLoader/><TopBarHeaderLine/></>)}
        </>
    )
}