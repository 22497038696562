import React, { useCallback, useState } from "react";  
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import swal from 'sweetalert';
import useRazorpay from "react-razorpay";  
import UseApi from "../../../../../../../CustomHooks/useApi"; 
import UsePostData from "../../../../../../../CustomHooks/usePostData";
import localStorage from "local-storage";
 export default function AddingRazorpayCard() {   
  const filesUrl=localStorage.get("filesUrl");
  const Razorpay = useRazorpay();

    const saveRazorpayCard=useCallback(async() =>{
        var cardBtn=document.querySelector("#rzp-button1");
      toast.dismiss();
      //change button innerHtml with loader onclick
      var btnHtml=cardBtn.innerHTML;
      if (cardBtn) {
        cardBtn.innerHTML =
          'Please wait...<span class="spinner-border spinner-border-sm align-middle ms-2"></span>';
          cardBtn.classList.add("disabled");
      }

      //calling userinfo api and application info
      const u_data=await UseApi('get','account/info',''); 
      const uData=u_data.user;
      const u_razorpay_info=await UseApi('get','mhcsecinfo','');
       
      var ruserid="";
      var rorder_id="";
      var rcustomer_id=""
      var rfullname="";
      var rmobile="";
      var remail="";
      var ramount=0;
      var rcurrency=""; 
  
      if(uData){
      ruserid=uData.id; 
      rcustomer_id=uData.razorpay_customerid;
      rfullname=uData.fullname;
      rmobile=uData.mobile;
      remail=uData.email;
      rcurrency=uData.currency;
      } 

     
      const createOrder=await UsePostData("post","razorpay/createorder",{"currency":"INR","type":"card","amount":1});
     console.log(createOrder,"kj");
      if(createOrder && createOrder.status=="success"){
      rorder_id=createOrder.id;
     }
      const saveOptions = {  
        key: u_razorpay_info.razorpay_key,
        amount: 100,
        currency: "INR",
        name: "Utho Cloud",
        description: "Welcome to Utho Cloud",
        image: (filesUrl!=null?(filesUrl):(''))+"/publicfiles/"+(u_razorpay_info?(u_razorpay_info.logo):('')),
        order_id: rorder_id,
        customer_id: rcustomer_id,
        prefill: { 
          contact: rmobile,
          },
        notes:{
            microhost_order_id: "cardauth-"+ruserid,
              },
        config: {
                display: {
                  blocks: {
                    banks: {
                      name: 'Pay via Card',
                      instruments: [
                        {
                          method: 'card'
                        }
                      ],
                    },
                  },
                  sequence: ['block.banks'],
                  preferences: {
                    show_default_blocks: false,
                  },
                },
              }, 
        recurring:1,
        handler: async(res) => { 
          if(res.status_code==200){  
                //console.log(res); 
                  const   ResData=await UsePostData("post","razorpay/addtoken",{"verify":1,"action":"saveCard","razorpay_payment_id":res.razorpay_payment_id,"razorpay_order_id":res.razorpay_order_id,"razorpay_signature":res.resrazorpay_signature})
                if(ResData.status=="success"){
                  window.afterCardVerification();
                  window.onWelcome();
                  swal({
                      title: "Card",
                      text: "Card added in your account!",
                      icon: "success",
                      button: "OK",
                    });
                    setTimeout(() => {
                      window.location.reload();
                    }, 4000);
                }else{
                  swal({
                      title: "Card",
                      text: "Card not added in your account!",
                      icon: "warning",
                      button: "OK",
                    });
                }
                if (cardBtn) {
                    cardBtn.innerHTML =btnHtml;
                    cardBtn.classList.remove("disabled");
                  }
              }
        },
        notes: {
          address: "Utho Cloud- Fund add",
        },
        theme: {
          color: "#377dff",
        },
      };

    const rzpay = new Razorpay(saveOptions); 
    rzpay.on("payment.failed", function (response) {
      swal({
          title: "Payment failed",
          text: response.error.description,
          icon: "warning",
          button: "OK",
        }); 
    }); 
    rzpay.open();
    if (cardBtn) {
      cardBtn.innerHTML =btnHtml;
      cardBtn.classList.remove("disabled");
    }
    
  }, [Razorpay]); 


   
    return (
        <> 
                        
                              <a
                                onClick={saveRazorpayCard}
                                id="rzp-button1"
                                className="btn btn-primary px-6 align-self-center text-nowrap"
                              >
                                Add New Card
                              </a> 
                            
        </>
    );
}
