// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.customCss .PhoneInputInput {
  border: none;
}

.customCss .PhoneInputInput:focus {
  outline: none; /* Remove the outline when input is focused */
}
`, "",{"version":3,"sources":["webpack://./src/App/MicroApp/Body/Products/setting/custom.css"],"names":[],"mappings":";AACA;EACE,YAAY;AACd;;AAEA;EACE,aAAa,EAAE,6CAA6C;AAC9D","sourcesContent":["\n.customCss .PhoneInputInput {\n  border: none;\n}\n\n.customCss .PhoneInputInput:focus {\n  outline: none; /* Remove the outline when input is focused */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
