import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AccessDenied from "../../AccessDenied";
import "../cloud/cloud.css";
import { connect } from "react-redux";
import { UpdateCloudPagination } from "../../../../Redux/Services/Action/actions";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../Loaders/TableLoader";
import PaginatedItems from "./shared/PaginatedItems";
import ProductsUrl from "../../Docs/Docsdata";
import CustomDoc from "../../../../CustomHooks/CustomDoc";

const Gpu = (props) => {
  // CSS End
  const [dataFetched, setDataFetched] = useState(false);
  const [cloudData, setCloudData] = useState(null);
  const [access, setAccess] = useState(true);
  const [metaData, setMetaData] = useState(null);
  const loaderFunc = () => {
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if (loader && no_record) {
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");

      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
  }

  const getCloud = async () => {
    toast.dismiss();
    const dt = toast
    if (!props?.paginationInfo) {
      return;
    }
    const { perpage, currentPage } = props.paginationInfo[0];

    if (!perpage || !currentPage) {
      return;
    }

    try {
      const data = await UseApi('get', `cloud?perpage=${perpage}&page=${currentPage}`, '');
      if (!data) {
        return;
      }

      if ('status' in data && data.status === 'error') {
        toast.update(dt, {
          render: data.message,
          type: 'warning',
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if(data.message == "Permission denied."){
          setAccess(false);
        }else{
          setAccess(true);
        }
        setCloudData([]);
        setDataFetched(true)
        // loaderFunc();
        return;
      }

      if (Object.keys(data).length === 0) {
        setCloudData(null);
        toast.update(dt, {
          render: 'No records found',
          type: 'warning',
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setDataFetched(true)
        // loaderFunc();
      } else {
        const newArray = data.cloud.filter(obj => obj.gpus && obj.gpus.length > 0);
        setCloudData(newArray);
        setMetaData(data.meta);
        setDataFetched(true)
        toast.dismiss();
      }
    } catch (error) {
      setDataFetched(true)
      console.error('Error fetching cloud data:', error);
      // Handle error, show toast, or perform other error handling as needed
    }
  };

  useEffect(() => {
    getCloud();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  function updatePerPage(count) {
    if (props?.updatePaginationData) {
      props.paginationInfo[0].perpage = count;
      setCloudData(null);
      getCloud();
    }
  }

  const openDoc = () => {
    var drawerElement = document.querySelector("#gpu-doc");
    var drawer_overlay = document.querySelector("#drawer_overlay_gpu_doc");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
        drawerElement.style.width = "100%";
      } else {
        drawerElement.style.width = "50%";
      }
      drawer_overlay.classList.toggle("d-none");
    }
  };

  if(dataFetched){
  if(access){
  return (
    <>
      {/* <div style={{zIndex: "109"}} id="overlay_id" className="drawer-overlay"></div> */}
      <div className="col-xl-12">
        {/*begin::Tables Widget 9*/}
        <div className="card card-xl-stretch mb-5 mb-xl-12 maindiv" id="">
          {/*begin::Header*/}
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold fs-3 mb-1">
                GPU Servers
                {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                <span className="svg-icon svg-icon-2 d-none">
                  <i className="las la-cloud" style={{ fontSize: 20 }} />
                </span>
                {/*end::Svg Icon*/}
              </span>
              <span className="text-muted mt-1 fw-bold fs-5">
                {/* Attach your Gpu here. */}
                <span className="text-primary ms-1 fw-bold fs-5 d-none" onClick={openDoc} style={{ cursor: 'pointer' }}>Learn more.
                  <span className="svg-icon svg-icon-2 svg-icon-primary d-none" style={{ color: '#009ef7' }}>
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M4.72718 2.71244C5.03258 2.41324 5.46135 2.21816 6.27103 2.11151C7.10452 2.00172 8.2092 2 9.7931 2H14.2069C15.7908 2 16.8955 2.00172 17.729 2.11151C18.5387 2.21816 18.9674 2.41324 19.2728 2.71244C19.5782 3.01165 19.7773 3.43172 19.8862 4.22499C19.9982 5.04159 20 6.12387 20 7.67568V15.5135L7.34563 15.5135C6.44305 15.5132 5.82716 15.513 5.29899 15.6517C4.82674 15.7756 4.38867 15.9781 4 16.2442V7.67568C4 6.12387 4.00176 5.04159 4.11382 4.225C4.22268 3.43172 4.42179 3.01165 4.72718 2.71244ZM7.58621 5.78378C7.12914 5.78378 6.75862 6.1468 6.75862 6.59459C6.75862 7.04239 7.12914 7.40541 7.58621 7.40541H16.4138C16.8709 7.40541 17.2414 7.04239 17.2414 6.59459C17.2414 6.1468 16.8709 5.78378 16.4138 5.78378H7.58621ZM6.75862 10.3784C6.75862 9.93058 7.12914 9.56757 7.58621 9.56757H13.1034C13.5605 9.56757 13.931 9.93058 13.931 10.3784C13.931 10.8262 13.5605 11.1892 13.1034 11.1892H7.58621C7.12914 11.1892 6.75862 10.8262 6.75862 10.3784Z" fill="#1C274D"></path> <path d="M7.47341 17.1351C6.39395 17.1351 6.01657 17.1421 5.72738 17.218C4.93365 17.4264 4.30088 18.0044 4.02952 18.7558C4.0463 19.1382 4.07259 19.4746 4.11382 19.775C4.22268 20.5683 4.42179 20.9884 4.72718 21.2876C5.03258 21.5868 5.46135 21.7818 6.27103 21.8885C7.10452 21.9983 8.2092 22 9.7931 22H14.2069C15.7908 22 16.8955 21.9983 17.729 21.8885C18.5387 21.7818 18.9674 21.5868 19.2728 21.2876C19.4894 21.0753 19.6526 20.8023 19.768 20.3784H7.58621C7.12914 20.3784 6.75862 20.0154 6.75862 19.5676C6.75862 19.1198 7.12914 18.7568 7.58621 18.7568H19.9704C19.9909 18.2908 19.9972 17.7564 19.9991 17.1351H7.47341Z" fill="#1C274D"></path> </g></svg>
                    {/* <i class="fas fa-exclamation-circle ms-2 fs-4" aria-label="Provide your team size to help us setup your billing"></i> */}
                    {/* <i className="las la-binoculars" style={{ fontSize: 20 }} /> */}
                  </span>
                </span>
              </span>
            </h3>
            <div
              className="card-toolbar"
            >
              {/* <div className="position-relative my-1" style={{ marginRight: 20 }}>
          <input
            type="text"
            className="form-control form-control-sm"
            data-kt-table-widget-4="search"
            id="searchClient"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            placeholder="Search"
          />
        </div> */}
              <div style={{ marginRight: 20 }}>
                <div className="dataTables_length">
                  <label className="w-125px">
                    <select
                      onChange={e => updatePerPage(e.target.value)}
                      data-control="select2"
                      className="form-select form-select-sm form-select-solid"
                      defaultValue={10}
                      id="perPage"

                    >
                      <option value={10} selected={true}>10 Records</option>
                      <option value={25}>25 Records</option>
                      <option value={50}>50 Records</option>
                      <option value={100}>100 Records</option>
                    </select>
                  </label>
                </div>
              </div>

              <Link to="/gpu/deploy">
                <div
                  className="btn btn-sm btn-primary btn-active-light-primary mx-5"
                >
                  {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
                  <span className="svg-icon svg-icon-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.5"
                        x="11.364"
                        y="20.364"
                        width={16}
                        height={2}
                        rx={1}
                        transform="rotate(-90 11.364 20.364)"
                        fill="black"
                      />
                      <rect
                        x="4.36396"
                        y="11.364"
                        width={16}
                        height={2}
                        rx={1}
                        fill="black"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}Attach Gpu
                </div>
              </Link>
              <Link to="/cloud/deploy">
                <div
                  className="btn btn-sm btn-primary btn-active-light-primary"
                >
                  {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
                  <span className="svg-icon svg-icon-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.5"
                        x="11.364"
                        y="20.364"
                        width={16}
                        height={2}
                        rx={1}
                        transform="rotate(-90 11.364 20.364)"
                        fill="black"
                      />
                      <rect
                        x="4.36396"
                        y="11.364"
                        width={16}
                        height={2}
                        rx={1}
                        fill="black"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}Deploy Server
                </div>
              </Link>
            </div>
          </div>
          {/*end::Header*/}
          {/*begin::Body*/}
          {/*begin::Table container*/}

          <div className="table-responsive">
            {/*begin::Table*/}
            <table
              id="clouddata"
              className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
            >
              {/*begin::Table head*/}
              <thead className="bg-secondary">
                <tr className="fw-bold ">
                  <th className="w-25px text-center d-none">#</th>
                  <th className="w-100px sphide text-center d-none">Data Centers</th>
                  <th className="w-40 text-center"></th>
                  <th className="w-100px text-center d-none">OS/App</th>
                  <th className="w-15 text-center">IP</th>
                  <th className="w-15 text-center d-none"></th>
                  <th className="w-15 text-center d-none"></th>
                  <th className="w-15 text-center">Status</th>
                  {/* <th className="w-15 text-center d-none">GPU</th> */}
                  {/*<th className="w-100px text-start sphide">Created/Due date</th>
                  <th className="w-10 text-center">Console</th> */}
                  {/* <th className="w-10 text-center" style={{ paddingRight: '5rem' }}></th> */}
                </tr>
              </thead>
              {/*end::Table head*/}
              {cloudData !== null ? (<>
                {access === false ? (<>
                  <tr>
                    <td colspan={12}>
                      <AccessDenied />
                    </td>
                  </tr>
                </>) : (<>
                  {Object.keys(cloudData).length === 0 ? (<>
                    <tr>
                      <td colspan={12}>
                        <div className="card-body p-0 mt-10">
                          {/*begin::Illustration*/}
                          <div className="text-center px-4 mt-10">
                            <span className="mw-100 mh-300px">
                             <svg
                              fill="#21325B"
                              height="200px"
                              width="200px"
                              version="1.1"
                              id="Capa_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              viewBox="0 0 512 512"
                              xmlSpace="preserve"
                            >
                              <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                              <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                              <g id="SVGRepo_iconCarrier">
                                {" "}
                                <g>
                                  {" "}
                                  <path d="M480.003,128H48c0-22.056-17.944-40-40-40c-4.418,0-8,3.582-8,8s3.582,8,8,8c13.234,0,24,10.766,24,24v288 c0,4.418,3.582,8,8,8s8-3.582,8-8v-8h16.01C77.238,408,88,397.238,88,384.01V384h392.003C497.646,384,512,369.646,512,352.003 V159.997C512,142.354,497.646,128,480.003,128z M496,352.003c0,8.821-7.176,15.997-15.997,15.997H80c-4.418,0-8,3.582-8,8v8.01 c0,4.406-3.584,7.99-7.99,7.99H48V144h432.003c8.821,0,15.997,7.176,15.997,15.997V352.003z" />{" "}
                                  <path d="M240,192c-22.922,0-43.057,12.12-54.363,30.28c-0.784,0.849-1.379,1.856-1.737,2.954c-5.03,9.136-7.9,19.621-7.9,30.766 c0,11.406,3.013,22.115,8.264,31.399c0.187,0.398,0.407,0.778,0.656,1.14C196.078,307.354,216.586,320,240,320 c35.29,0,64-28.71,64-64c0-11.406-3.013-22.115-8.264-31.399c-0.187-0.398-0.407-0.778-0.656-1.14 C283.922,204.646,263.414,192,240,192z M192,256c0-4.395,0.605-8.648,1.717-12.695c3.596,3.178,8.453,6.73,15.035,10.53 c6.376,3.681,11.742,6.078,16.208,7.612c-2.622,2.061-5.987,4.385-10.208,6.821c-8.449,4.878-14.816,7.039-18.36,7.752 C193.58,269.922,192,263.144,192,256z M288,256c0,4.103-0.52,8.087-1.493,11.891c-3.617-3.227-8.542-6.848-15.259-10.726 c-5.96-3.441-11.036-5.758-15.321-7.298c2.483-1.885,5.564-3.966,9.321-6.135c8.447-4.876,14.816-7.039,18.36-7.752 C286.42,242.078,288,248.856,288,256z M273.948,222.099c-4.562,1.524-10.087,3.96-16.699,7.777 c-6.252,3.61-10.952,6.997-14.49,10.051C242.31,236.682,242,232.717,242,228c0-9.763,1.314-16.361,2.469-19.785 C255.934,209.279,266.244,214.384,273.948,222.099z M227.619,209.627C226.655,214.344,226,220.354,226,228 c0,7.056,0.557,12.721,1.401,17.26c-3.022-1.232-6.59-2.938-10.65-5.282c-8.302-4.793-13.33-9.159-15.769-11.883 C207.376,219.18,216.739,212.535,227.619,209.627z M206.049,289.898c4.564-1.524,10.086-3.954,16.702-7.774 c6.252-3.61,10.952-6.997,14.49-10.051C237.69,275.318,238,279.283,238,284c0,9.763-1.314,16.361-2.469,19.785 C224.065,302.721,213.753,297.615,206.049,289.898z M252.381,302.373C253.345,297.656,254,291.646,254,284 c0-7.633-0.653-13.635-1.614-18.347c3.066,1.237,6.708,2.97,10.863,5.368c8.764,5.06,13.892,9.652,16.163,12.33 C273.012,292.546,263.486,299.405,252.381,302.373z" />{" "}
                                  <path d="M440,168c-4.418,0-8,3.582-8,8s3.582,8,8,8c8.822,0,16,7.178,16,16v112c0,8.822-7.178,16-16,16H240 c-39.701,0-72-32.299-72-72s32.299-72,72-72h168c4.418,0,8-3.582,8-8s-3.582-8-8-8H240c-48.523,0-88,39.477-88,88s39.477,88,88,88 h200c17.645,0,32-14.355,32-32V200C472,182.355,457.645,168,440,168z" />{" "}
                                  <path d="M112,232H88c-8.822,0-16,7.178-16,16v16c0,8.822,7.178,16,16,16h24c8.822,0,16-7.178,16-16v-16 C128,239.178,120.822,232,112,232z M88,264v-16h24l0.001,16H88z" />{" "}
                                  <path d="M112,176H88c-8.822,0-16,7.178-16,16v16c0,8.822,7.178,16,16,16h24c8.822,0,16-7.178,16-16v-16 C128,183.178,120.822,176,112,176z M88,208v-16h24l0.001,16H88z" />{" "}
                                  <path d="M112,288H88c-8.822,0-16,7.178-16,16v16c0,8.822,7.178,16,16,16h24c8.822,0,16-7.178,16-16v-16 C128,295.178,120.822,288,112,288z M88,320v-16h24l0.001,16H88z" />{" "}
                                  <path d="M432,200h-24c-4.418,0-8,3.582-8,8s3.582,8,8,8h24c4.418,0,8-3.582,8-8S436.418,200,432,200z" />{" "}
                                  <path d="M432,296h-24c-4.418,0-8,3.582-8,8s3.582,8,8,8h24c4.418,0,8-3.582,8-8S436.418,296,432,296z" />{" "}
                                  <path d="M432,224h-24c-4.418,0-8,3.582-8,8s3.582,8,8,8h24c4.418,0,8-3.582,8-8S436.418,224,432,224z" />{" "}
                                  <path d="M432,248h-24c-4.418,0-8,3.582-8,8s3.582,8,8,8h24c4.418,0,8-3.582,8-8S436.418,248,432,248z" />{" "}
                                  <path d="M432,272h-24c-4.418,0-8,3.582-8,8s3.582,8,8,8h24c4.418,0,8-3.582,8-8S436.418,272,432,272z" />{" "}
                                </g>{" "}
                              </g>
                            </svg>
                            

                            </span>
                          </div>
                          {/*end::Illustration*/}
                          {/*begin::Wrapper*/}
                          <div className="card-px text-center py-5 mb-20">
                            {/*begin::Title*/}
                            <h1 className="fs-2x fw-bolder mb-5">GPU</h1>
                            <h2 className="fs-1x fw-bolder mb-5 text-muted">
                              Deploy your first server with GPU
                            </h2>
                            {/*end::Title*/}
                            {/*begin::Description*/}
                            <p className="text-gray-400 fs-4 fw-bold mb-10">
                              Deploy cloud server with GPU with ease and take your cloud journey to the next level.<br /> Start enjoying the benefits from Utho's robust and scalable server solutions.
                            </p>
                            {/*end::Description*/}
                            {/*begin::Action*/}
                            <Link to="/cloud/deploy">
                              <div
                                className="btn btn-primary mb-10"
                              >
                                Create GPU Server
                              </div>
                            </Link>
                            {/*end::Action*/}
                          </div>
                          {/*end::Wrapper*/}
                        </div>
                      </td>
                    </tr>
                  </>) : (
                  <>
                    <PaginatedItems ProductData={cloudData} metaData={metaData} props={props} setCloudData={setCloudData} getCloud={getCloud} />
                  </>)}
                </>)}
              </>) : (<>
                <tr>
                  <td colspan={12}>
                    <TableLoader />
                    <TopBarHeaderLine />
                  </td>
                </tr>

              </>)}



            </table>
            {/*end::Table*/}
          </div>
          {/*end::Table container*/}
          {/*begin::Body*/}
        </div>
        {/*end::Tables Widget 9*/}
      </div>
      <CustomDoc id='gpu' openDoc={openDoc} url={ProductsUrl[0].gpu} iframeUrl={ProductsUrl[0].gpu} />

    </>
  )
} else {
  return (
    <>
      <AccessDenied />
    </>
  )
}
}else{
return (
  <>
    <TableLoader />
  </>
)
}
}

const mapStateToProps = (state) => ({
  paginationInfo: state.cloudList.cloudPaginationInfo,
});

const mapDispatchToProps = (dispatch) => ({
  updatePaginationData: (data) => dispatch(UpdateCloudPagination(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Gpu);
