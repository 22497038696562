import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; 
import UsePostData from "../../../../../../../CustomHooks/usePostData"; 
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";  
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
const stripePromise = loadStripe("pk_test_jBtU87tvR5Vg9kL6ZedQw7Tj");
function MyCheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    var cardBtn=document.querySelector("#stripe-button1");
    const dt=toast.loading("Card adding..."); 
     //change button innerHtml with loader onclick
    var btnHtml=cardBtn.innerHTML;
    if (cardBtn) {
      cardBtn.innerHTML =
        'Please wait...<span class="spinner-border spinner-border-sm align-middle ms-2"></span>';
        cardBtn.classList.add("disabled");
    }
    const { error, token } = await stripe.createToken(
      elements.getElement(CardElement)
    );

    if (error) {
      if (cardBtn) {
        cardBtn.innerHTML =btnHtml;
          cardBtn.classList.remove("disabled");
      } 
      toast.update(dt,{render: error.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    }); 
    } else {
       const saveInfo=await UsePostData('POST','stripe/addtoken/'+token.id,{});
      if(saveInfo){
        if(saveInfo.status=="success"){
          window.afterCardVerification();
          window.onWelcome();
          toast.update(dt,{render: saveInfo.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        setTimeout(() => {
          window.location.reload();
        }, 4000);
        }else{
          toast.update(dt,{render: saveInfo.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        }
      }
      if (cardBtn) {
        cardBtn.innerHTML =btnHtml;
          cardBtn.classList.remove("disabled");
      }
          //  //console.log(saveInfo);
          //  //console.log(token);
    }
  };

  return (
    <div className="card p-5">
      <form onSubmit={handleSubmit}>
        <CardElement className="my-card-element mb-5" />
        <button type="submit" className="btn btn-primary w-100" id="stripe-button1">
          Submit
        </button>
      </form>
    </div>
  );
}
export default function AddingStripeCard() {
  const [userData, setUserData] = useState(null);
  
  return (
    <> 
     <a href="#" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_1">Add new card</a>
       <div
 className="modal fade" tabIndex={-1} id="kt_modal_1"
>
  {/*begin::Modal dialog*/}
  <div className="modal-dialog modal-dialog-centered mw-650px">
    {/*begin::Modal content*/}
    <div className="modal-content">
      {/*begin::Modal header*/}
      <div className="modal-header">
        {/*begin::Modal title*/}
        <h2>Add New Card</h2>
        {/*end::Modal title*/}
        {/*begin::Close*/}
        <div 
          className="btn btn-sm btn-icon btn-active-color-primary"
          data-bs-dismiss="modal"
        >
          {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
          <span className="svg-icon svg-icon-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x={6}
                y="17.3137"
                width={16}
                height={2}
                rx={1}
                transform="rotate(-45 6 17.3137)"
                fill="black"
              />
              <rect
                x="7.41422"
                y={6}
                width={16}
                height={2}
                rx={1}
                transform="rotate(45 7.41422 6)"
                fill="black"
              />
            </svg>
          </span>
          {/*end::Svg Icon*/}
        </div>
        {/*end::Close*/}
      </div>
      {/*end::Modal header*/}
      {/*begin::Modal body*/}
      <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
        {/*begin::Form*/}
        <Elements stripe={stripePromise}>
            <MyCheckoutForm />
          </Elements>
        {/*end::Form*/}
      </div>
      {/*end::Modal body*/}
    </div>
    {/*end::Modal content*/}
  </div>
  {/*end::Modal dialog*/}
</div>

    </>
  );
}
