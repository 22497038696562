import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import TableLoader from "../../Loaders/TableLoader";
import Search from "../search/Search";
import "./custom.css"
import UseApi from "../../../../CustomHooks/useApi";
import { useNavigate } from "react-router-dom";
function Vpc(props) {
    const [selectedVpcId, setSelectedVpcId] = useState(null);  // Track selected VPC ID
    let navigate = useNavigate()
    const { vpcData, selectTickMark, vpcd } = props;
    const [subnetData, setSubnetData] = useState(null)
    // const [vpcId]
    const [vpcId, setVpcId] = useState(null)
    const [vpcList, setVpcList] = useState(vpcData);
    const [isClicked, setIsClicked] = useState(new Array(vpcData?.length).fill(false))
    const [subnetLoader, setSubnetLoader] = useState(false)
    const [subnetId, setSubnetId] = useState('')
    // const []

    useEffect(() => {
        if (vpcData === undefined) throw new Error("Missing prop: vpcData");
        if (selectTickMark === undefined) throw new Error("Missing prop: selectTickMark");
    }, [vpcData, selectTickMark, vpcd]);

    useEffect(() => {
        if (vpcData) {
            setVpcList(vpcData)
        }
        var vpcTarget = document.querySelector("#borderVpc-" + vpcd?.vpcid);
        var vpcInput = document.querySelector("#vpc" + vpcd?.vpcid);
        if (vpcTarget && vpcInput) {
            selectTickMark(vpcd?.vpcid, 'Vpc', 'vpc', 'yes');
            vpcInput.checked = true;
        }
    }, [vpcList, vpcData])

    console.log(subnetId, props);


    // const handleGetSubnet = async (id, index) => {
    //     try {
    //         const newIsClicked = [...isClicked];
    //         let subnet_section = document.querySelector('#subnet_section');
    //         let vpc_section = document.querySelector('#vpc_section');
    //         let vpcElementValue = document.querySelector(`#vpc${id}`);
    //         let borderVpc = document.querySelector(`#borderVpc-${id}`);
    //         var currentPlan = document.querySelector("#tickMarkVpc-" + id);
    //         setSelectedVpcId(id);  // Disable the selected VPC's radio button
    //         var preContent = borderVpc.innerHTML;
    //         borderVpc.classList.add("disabled");
    //         borderVpc.innerHTML =
    //             '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2 me-4"></span>Please wait...</span>';
    //         // openDrawer()
    //         console.log(isClicked[index], isClicked, "dfsvfeefgbvx");

    //         if (currentPlan.classList.contains('d-none')) {
    //             props?.setVpcDataId('')
    //             vpcElementValue.value = '';
    //         } else {
    //             // Otherwise, set the value to the Subnet ID
    //             props?.setVpcDataId(id)
    //             vpcElementValue.value = id || '';
    //         }

    //         if (newIsClicked[index]) {
    //             borderVpc.classList.remove("disabled");
    //             borderVpc.innerHTML = preContent;
    //             // newIsClicked[index] = false;
    //             setIsClicked(prevState =>
    //                 prevState.map((item, idx) => (idx == index ? false : false))
    //             );
    //         } else {
    //             setIsClicked(prevState =>
    //                 prevState.map((item, idx) => (idx == index ? true : false))
    //             );
    //         }
    //         if (subnetId != '') {
    //             selectTickMark(subnetId, "Subnet", "subnet")
    //             setSubnetId('')
    //         }
    //         // If the same VPC is clicked, reset the subnet data
    //         if (id === vpcId) {
    //             subnet_section.classList.add('d-none');
    //             setSubnetData(null);
    //             setSubnetLoader(true);
    //             setVpcId('');
    //             return;
    //         }

    //         // Set the selected VPC ID and initialize loader
    //         setVpcId(id);
    //         setSubnetLoader(true);
    //         if (vpcElementValue) {
    //             vpcElementValue.value = id || '';
    //         }

    //         // Make API call to get subnets
    //         const data = await UseApi('get', `vpc/${id}`, '');
    //         console.log(data);

    //         if (data?.subnets) {
    //             if (data.subnets.length > 0) {
    //                 if (vpc_section && subnet_section) {
    //                     subnet_section.classList.remove('d-none');
    //                     borderVpc.classList.remove("disabled");
    //                     borderVpc.innerHTML = preContent;
    //                     openDrawer()
    //                     // handleShowDiv()
    //                 }
    //                 setSubnetData(data.subnets);
    //             } else {
    //                 setSubnetData([]); // No subnets available
    //                 borderVpc.classList.remove("disabled");
    //                 borderVpc.innerHTML = preContent;
    //             }
    //         } else {
    //             borderVpc.classList.remove("disabled");
    //             borderVpc.innerHTML = preContent;
    //             setSelectedVpcId(null)
    //             throw new Error('No subnet data found.');
    //         }
    //     } catch (error) {
    //         console.error('Error fetching subnet data:', error);
    //         setSubnetData([]);
    //         setSubnetLoader(true);
    //         setSelectedVpcId(null)
    //         // borderVpc.classList.remove("disabled");
    //         // borderVpc.innerHTML = preContent;
    //         // Optional: Add user-friendly error messaging (e.g., via toast notification)
    //     } finally {
    //         // borderVpc.classList.remove("disabled");
    //         // borderVpc.innerHTML = preContent;
    //         setSubnetLoader(true);
    //         setSelectedVpcId(null)
    //     }
    // };

    const handleGetSubnet = async (id, index) => {
        try {
            const subnetSection = document.querySelector('#subnet_section');
            const vpcSection = document.querySelector('#vpc_section');
            const vpcElementValue = document.querySelector(`#vpc${id}`);
            const borderVpc = document.querySelector(`#borderVpc-${id}`);
            const currentPlan = document.querySelector(`#tickMarkVpc-${id}`);

            // Set selected VPC ID
            setSelectedVpcId(id);

            // Prepare loading state for the clicked VPC
            const preContent = prepareLoadingState(borderVpc);

            // Update VPC Data ID and input value
            updateVpcDataIdAndValue(currentPlan, id, vpcElementValue);

            // Toggle the clicked state
            toggleClickedState(isClicked, index, borderVpc, preContent);

            // Reset subnet selection if applicable
            resetSubnetSelection();

            // If the same VPC is clicked, reset the subnet data
            if (id === vpcId) {
                resetSubnetData();
                return;
            }

            // Set the selected VPC ID and initialize loader
            setVpcId(id);
            setSubnetLoader(true);

            // Disable all other VPCs
            disableOtherVpcs(id);

            // Fetch subnets from the API
            const data = await UseApi('get', `vpc/${id}`, '');

            // Process the API response
            processApiResponse(data, borderVpc, preContent, subnetSection);

            // newIsClicked[index] = true; // Mark current index as clicked
            // setIsClicked(newIsClicked); // Update the state
        } catch (error) {
            handleError(error);
        } finally {
            finalizeState();
        }
    };

    // Helper Functions

    const toggleClickedState = (newIsClicked, index, borderVpc, preContent) => {
        if (newIsClicked[index]) {
            borderVpc.classList.remove("disabled");
            borderVpc.innerHTML = preContent;
            // newIsClicked[index] = false; // Set to not clicked
            setIsClicked(prevState =>
                prevState.map((item, idx) => (idx == index ? false : false))
            );
        } else {
            setIsClicked(prevState =>
                prevState.map((item, idx) => (idx == index ? true : false))
            );
        }
    };

    const prepareLoadingState = (borderVpc) => {
        const preContent = borderVpc.innerHTML;
        borderVpc.classList.add("disabled");
        borderVpc.innerHTML = `
            <span class="indicator-label">
                <span class="spinner-border spinner-border-sm align-middle ms-2 me-4"></span>
                Please wait...
            </span>
        `;
        return preContent;
    };

    const updateVpcDataIdAndValue = (currentPlan, id, vpcElementValue) => {
        if (currentPlan.classList.contains('d-none')) {
            props?.setVpcDataId('');
            vpcElementValue.value = '';
        } else {
            props?.setVpcDataId(id);
            vpcElementValue.value = id || '';
        }
    };

    const resetSubnetSelection = () => {
        if (subnetId !== '') {
            selectTickMark(subnetId, "Subnet", "subnet");
            setSubnetId('');
        }
    };

    const resetSubnetData = () => {
        const subnetSection = document.querySelector('#subnet_section');
        subnetSection.classList.add('d-none');
        setSubnetData(null);
        setSubnetLoader(true);
        setVpcId('');
        enableAllVpcs();
    };

    const processApiResponse = (data, borderVpc, preContent, subnetSection) => {
        if (data?.subnets) {
            if (data?.subnets?.length > 0) {
                subnetSection.classList.remove('d-none');
                borderVpc.classList.remove("disabled");
                borderVpc.innerHTML = preContent;
                setSubnetData(data?.subnets);
                setSubnetLoader(true)
                openDrawer()
            } else {
                setSubnetData([]); // No subnets available
                borderVpc.classList.remove("disabled");
                borderVpc.innerHTML = preContent;
                setSubnetLoader(true)
            }
        } else {
            borderVpc.classList.remove("disabled");
            borderVpc.innerHTML = preContent;
            setSelectedVpcId(null);
            throw new Error('No subnet data found.');
        }
    };

    const handleError = (error) => {
        console.error('Error fetching subnet data:', error);
        setSubnetData([]);
        setSubnetLoader(true);
        setSelectedVpcId(null);
    };

    const finalizeState = () => {
        // setSubnetLoader(false);
        setSelectedVpcId(null);
        enableAllVpcs(); // Enable all VPCs again when done
    };

    const disableOtherVpcs = (activeVpcId) => {
        // Get all VPC borders and disable them except the active one
        const allBorderVpcs = document.querySelectorAll('[id^="borderVpc-"]');
        allBorderVpcs.forEach(borderVpc => {
            const id = borderVpc.id.split('-')[1];
            if (id !== activeVpcId.toString()) {
                borderVpc.classList.add("disabled");
            } else {
                borderVpc.classList.remove("disabled"); // Ensure the active one is enabled
            }
        });
    };

    const enableAllVpcs = () => {
        const allBorderVpcs = document.querySelectorAll('[id^="borderVpc-"]');
        allBorderVpcs.forEach(borderVpc => {
            borderVpc.classList.remove("disabled"); // Remove the disabled class from all VPCs
        });
    };


    const handleSetSubnetId = (id) => {
        try {
            // Get the current tick mark element for the subnet
            var currentPlan = document.querySelector("#tickMarkSubnet-" + id);

            // Get the VPC element and Subnet element by their IDs
            // setIds(id)
            let vpcElementValue = document.querySelector(`#vpc${vpcId}`);
            let subnetElementValue = document.querySelector(`#subnet${id}`);

            // Check if the required elements exist before proceeding
            if (!currentPlan || !vpcElementValue || !subnetElementValue) {
                throw new Error("Required DOM elements not found. Please check the element IDs.");
            }

            // Log the current subnet element value and tick mark visibility
            console.log(subnetElementValue.value, currentPlan.classList.contains('d-none'));

            // If tick mark is not visible, set VPC element's value to the VPC ID
            if (currentPlan.classList.contains('d-none')) {
                console.log("ok");

                vpcElementValue.value = vpcId || '';
            } else {
                // Otherwise, set the value to the Subnet ID
                vpcElementValue.value = id || '';
            }
            console.log(vpcElementValue.value);

            // Toggle the Subnet ID if the same ID is clicked again
            if (id === subnetId) {
                setSubnetId('');
            } else {
                setSubnetId(id);
            }
        } catch (error) {
            // Handle any errors that might occur and log them
            console.error("Error in handleSetSubnetId:", error.message);
            // alert("An error occurred while selecting the subnet. Please try again.");
        }
    };


    const openDrawer = () => {
        var drawerElement = document.querySelector("#subnet-modal");
        var drawer_overlay = document.querySelector("#drawer_overlay_subnet");
        if (drawerElement && drawer_overlay) {
            drawerElement.classList.toggle("drawer-on");
            console.log(window.innerWidth)
            if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
                console.log("skxcnkxjfcidk");
                
                drawerElement.style.width = "100%";
            } else {
                drawerElement.style.width = "60%";
            }
            drawer_overlay.classList.toggle("d-none");
        }
    };
    return (
        <>
            <div className="col-12 card mb-5">
                <div className=" h-lg-100 mb-5" id="vpc_section">
                    {/*begin::Header*/}
                    <div className="card-header border-3 mb-5 pt-2 p-0 ps-6">
                        {/*begin::Title*/}
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bolder text-gray-800">
                                VPC Network
                            </span>
                            <span className="text-gray-400 mt-1 fw-bold fs-6">
                                Select VPC for the server
                            </span>
                        </h3>
                        <div className="card-toolbar">
                            <a
                                onClick={() => navigate("/vpc/deploy")}
                                className="btn btn-sm btn-light-primary btn-active-light-primary me-5"
                                style={{ marginRight: "0.76rem" }}
                            >
                                <span className="svg-icon svg-icon-3">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <rect
                                            opacity="0.5"
                                            x="11.364"
                                            y="20.364"
                                            width={16}
                                            height={2}
                                            rx={1}
                                            transform="rotate(-90 11.364 20.364)"
                                            fill="black"
                                        />
                                        <rect x="4.36396" y="11.364" width={16} height={2} rx={1} fill="black" />
                                    </svg>
                                </span>
                                Add New VPC
                            </a>

                        </div>
                        {/*end::Title*/}
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    {props?.vpcLoader == true ? (
                        <>
                            <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                                <TableLoader />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="mx-5 mb-3">
                                <div
                                    id="getvpc"
                                >
                                    {vpcList != null ? (
                                        <> {vpcList?.length != 0 ? (<>
                                            <Search results={vpcData} onSearch={setVpcList} fields={['network', 'name']} searchPlaceholder="vpc" />
                                            <div className={vpcList?.length > 9 ? ("vpc-wrapper") : ("")}>
                                                <div className={vpcList?.length > 9 ? ("row vpc") : ("row")}>
                                                    {vpcList.map(
                                                        (value, index) => (
                                                            <>
                                                                <div className="col-md-4 col-lg-4 col-xxl-4 mt-5">
                                                                    {/*begin::Option*/}
                                                                    <input
                                                                        className="btn-check deploy_checkbox"
                                                                        type="radio"
                                                                        name="vpc"
                                                                        // value={ids}
                                                                        // value={}
                                                                        // defaultValue={value.id}
                                                                        id={"vpc" + value.id}
                                                                        disabled={selectedVpcId === value.id}
                                                                    />
                                                                    <label
                                                                        htmlFor={"vpc" + value.id}
                                                                        onClick={() => {
                                                                            selectTickMark(value.id, "Vpc", "vpc");
                                                                            handleGetSubnet(value.id, index);
                                                                            // setSubnetId(subnetId)
                                                                        }}

                                                                        className="borderVpc btn btn-outline btn-outline btn-outline-default d-flex align-items-center"
                                                                        id={"borderVpc-" + value.id}
                                                                        style={{ overflow: "hidden", height: "60px" }}
                                                                    >
                                                                        {/*begin::Svg Icon | path: icons/duotune/communication/com003.svg*/}
                                                                        <span className="svg-icon svg-icon-muted svg-icon-4x me-1">
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width={24}
                                                                                height={24}
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                            >
                                                                                <path
                                                                                    opacity="0.3"
                                                                                    d="M19 18C20.7 18 22 16.7 22 15C22 13.3 20.7 12 19 12C18.9 12 18.9 12 18.8 12C18.9 11.7 19 11.3 19 11C19 9.3 17.7 8 16 8C15.4 8 14.8 8.2 14.3 8.5C13.4 7 11.8 6 10 6C7.2 6 5 8.2 5 11C5 11.3 5.00001 11.7 5.10001 12H5C3.3 12 2 13.3 2 15C2 16.7 3.3 18 5 18H19Z"
                                                                                    fill="black"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        {/*end::Svg Icon*/}
                                                                        <span className="d-block fw-bold text-start">
                                                                            <span className="text-dark fw-bolder d-block fs-6">
                                                                                {value.network}
                                                                            </span>
                                                                            <span className="text-muted fw-bold fs-8 w-100">
                                                                                {" "}
                                                                                {value.name}
                                                                            </span>
                                                                        </span>
                                                                        <div
                                                                            className="d-none tickMarkVpc ribbon ribbon-triangle ribbon-top-start border-primary"
                                                                            id={
                                                                                "tickMarkVpc-" + value.id
                                                                            }
                                                                        >
                                                                            <div className="ribbon-icon mt-n5">
                                                                                <i className="bi bi-check2 fs-2 text-white" />
                                                                            </div>
                                                                        </div>
                                                                    </label>
                                                                    {/*end::Option*/}
                                                                </div>
                                                            </>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </>) : (<p className="text-gray-600 fw-bold fs-6 text-center">no vpc found</p>)}
                                        </>
                                    ) : (
                                        <p className="text-gray-600 fw-bold fs-6 text-center">no vpc found</p>
                                    )}

                                </div>
                                {/*end::Radio group*/}
                            </div>
                        </>
                    )}
                    {/*end: Card Body*/}
                </div>
            </div>
            <div id="drawer_overlay_subnet" onClick={openDrawer} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>
            <div
                id="subnet-modal"
                className="bg-body shadow drawer drawer-end"
                // data-kt-drawer="true"
                // data-kt-drawer-name="chat"
                // data-kt-drawer-activate="true"
                // data-kt-drawer-direction="end"
                data-kt-drawer-close="#close_ssh"
                // data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
                style={{ important: true, zIndex: 110 }}
            >
                <div className="col-xl-12 card mb-5">
                    <div className=" h-lg-100 mb-5 d-none" id="subnet_section">
                        <>
                            {/*begin::Header*/}
                            <div className="card-header border-3 mb-5 pt-2 p-0 ps-6">
                                {/*begin::Title*/}
                                <h3 className="card-title align-items-start flex-column">
                                    <span className="card-label fw-bolder text-gray-800">
                                        Subnet
                                    </span>
                                    <span className="text-gray-400 mt-1 fw-bold fs-6">
                                        Select Subnet for the server
                                    </span>
                                </h3>
                                <div className="card-toolbar">
                                    {/*begin::Close*/}
                                    <div
                                        onClick={openDrawer}
                                        className="btn btn-sm btn-icon btn-active-light-primary"
                                        id="close_ssh"
                                    >
                                        {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                                        <span className="svg-icon svg-icon-2">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                            >
                                                <rect
                                                    opacity="0.5"
                                                    x={6}
                                                    y="17.3137"
                                                    width={16}
                                                    height={2}
                                                    rx={1}
                                                    transform="rotate(-45 6 17.3137)"
                                                    fill="black"
                                                />
                                                <rect
                                                    x="7.41422"
                                                    y={6}
                                                    width={16}
                                                    height={2}
                                                    rx={1}
                                                    transform="rotate(45 7.41422 6)"
                                                    fill="black"
                                                />
                                            </svg>
                                        </span>
                                        {/*end::Svg Icon*/}
                                    </div>
                                    {/*end::Close*/}
                                </div>
                            </div>
                            {subnetLoader ?
                                <div className="mx-5 mb-3">
                                    <div
                                        id="getsubnet"
                                    >
                                        {subnetData != null ? (
                                            <> {subnetData?.length != 0 ? (<>
                                                <Search results={subnetData} onSearch={setSubnetData} fields={['network', 'name']} searchPlaceholder="subnet" />
                                                <div className={subnetData?.length > 9 ? ("subnet-wrapper") : ("")}>
                                                    <div className={subnetData?.length > 9 ? ("row subnet") : ("row")}>
                                                        {subnetData.map(
                                                            (value, index) => (
                                                                <>
                                                                    <div className="col-md-4 col-lg-4 col-xxl-4 mt-5">
                                                                        {/*begin::Option*/}
                                                                        <input
                                                                            className="btn-check deploy_checkbox"
                                                                            type="radio"
                                                                            name="subnet"
                                                                            defaultValue={value.id}
                                                                            id={"subnet" + value.id}
                                                                        />
                                                                        <label
                                                                            htmlFor={"subnet" + value.id}
                                                                            onClick={() => {
                                                                                selectTickMark(value.id, "Subnet", "subnet");
                                                                                handleSetSubnetId(value.id)
                                                                                openDrawer()
                                                                                // handleGetSubnet(value.id);
                                                                            }}

                                                                            className="borderSubnet btn btn-outline btn-outline btn-outline-default d-flex align-items-center"
                                                                            id={"borderSubnet-" + value.id}
                                                                            style={{ overflow: "hidden", height: "60px" }}
                                                                        >
                                                                            {/*begin::Svg Icon | path: icons/duotune/communication/com003.svg*/}
                                                                            <span className="svg-icon svg-icon-muted svg-icon-4x me-1">
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width={24}
                                                                                    height={24}
                                                                                    viewBox="0 0 24 24"
                                                                                    fill="none"
                                                                                >
                                                                                    <path
                                                                                        opacity="0.3"
                                                                                        d="M19 18C20.7 18 22 16.7 22 15C22 13.3 20.7 12 19 12C18.9 12 18.9 12 18.8 12C18.9 11.7 19 11.3 19 11C19 9.3 17.7 8 16 8C15.4 8 14.8 8.2 14.3 8.5C13.4 7 11.8 6 10 6C7.2 6 5 8.2 5 11C5 11.3 5.00001 11.7 5.10001 12H5C3.3 12 2 13.3 2 15C2 16.7 3.3 18 5 18H19Z"
                                                                                        fill="black"
                                                                                    />
                                                                                </svg>
                                                                            </span>
                                                                            {/*end::Svg Icon*/}
                                                                            <span className="d-block fw-bold text-start">
                                                                                <span className="text-dark fw-bolder d-block fs-6">
                                                                                    {value.network}
                                                                                </span>
                                                                                <span className="text-muted fw-bold fs-8 w-100">
                                                                                    {" "}
                                                                                    {value.name}
                                                                                </span>
                                                                            </span>
                                                                            <div
                                                                                className="d-none tickMarkSubnet ribbon ribbon-triangle ribbon-top-start border-primary"
                                                                                id={
                                                                                    "tickMarkSubnet-" + value.id
                                                                                }
                                                                            >
                                                                                <div className="ribbon-icon mt-n5">
                                                                                    <i className="bi bi-check2 fs-2 text-white" />
                                                                                </div>
                                                                            </div>
                                                                        </label>
                                                                        {/*end::Option*/}
                                                                    </div>
                                                                </>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            </>) : (<p className="text-gray-600 fw-bold fs-6 text-center">no subnet found</p>)}
                                            </>
                                        ) : (
                                            <p className="text-gray-600 fw-bold fs-6 text-center">no subnet found</p>
                                        )}

                                    </div>
                                    {/*end::Radio group*/}
                                </div>
                                :
                                <TableLoader />
                            }
                            {/*end: Card Body*/}
                        </>
                    </div >
                </div>
            </div>
            {/*end::List widget 10*/}
        </>
    );
}

Vpc.propTypes = {
    vpcData: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.object),
        PropTypes.oneOf([null]),
    ]).isRequired,
    selectTickMark: PropTypes.func.isRequired,
};

export default Vpc;
