import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import TableLoader from "../../Loaders/TableLoader";
import Search from "../search/Search";
import "../firewall/custom.css"

function Firewall(props) {
    
    const { firewallData, selectTickMark, firewalld } = props;
    const [firewallList, setFirewallList] = useState(firewallData || []);
    // console.log(firewallData);
    useEffect(() => {
        if (firewallData === undefined) throw new Error("Missing prop: firewallData");
        if (selectTickMark === undefined) throw new Error("Missing prop: selectTickMark");
    }, [firewallData, selectTickMark, firewalld]);


    useEffect(() => {
        if (firewallData) {
            setFirewallList(firewallData)
        }
        var firewallTarget = document.querySelector("#borderFirewall-" + firewalld.firewallid);
        var firewallInput = document.querySelector("#firewall" + firewalld.firewallid);
        if (firewallTarget && firewallInput) {
            selectTickMark(firewalld.firewallid, 'Firewall', 'firewall', 'yes');
            firewallInput.checked = true;
        }
    }, [firewallList,firewallData])
    // {Object.keys(firewallList).length != 0
    //   ? Object.entries(firewallList).map(
    // console.log(firewallList);
    return (
        <>
            <div className=" h-lg-100 mb-5">
                <div className="card-header border-3 mb-5 pt-2 p-0 ps-6">
                    {/*begin::Title*/}
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bolder text-gray-800">
                            Security Group
                        </span>
                        <span className="text-gray-400 mt-1 fw-bold fs-6">
                            Select security group for the server
                        </span>
                    </h3>
                    <div className="card-toolbar">
                        <a
                            href="/firewall/deploy" target="_blank"
                            className="btn btn-sm btn-light-primary btn-active-light-primary me-5"
                            style={{ marginRight: "0.76rem" }}
                        >
                            <span className="svg-icon svg-icon-3">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <rect
                                        opacity="0.5"
                                        x="11.364"
                                        y="20.364"
                                        width={16}
                                        height={2}
                                        rx={1}
                                        transform="rotate(-90 11.364 20.364)"
                                        fill="black"
                                    />
                                    <rect x="4.36396" y="11.364" width={16} height={2} rx={1} fill="black" />
                                </svg>
                            </span>
                            Add New Security Group
                        </a>

                    </div>
                </div>
                {props.firewallLoader ? (
                    <>
                        <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                            {/* <div className="small-spinner"></div> */}
                            <TableLoader />
                        </div>
                    </>
                ) : (
                    <>
                        <div className="mx-5 mb-3">
                            {/*begin::Radio group*/}
                            <div
                                id="getfirewall"
                            >
                                {firewallList != null ? (
                                    <>  {firewallList.length != 0 ? (<>
                                        <Search results={firewallData} onSearch={setFirewallList} fields={['name']} searchPlaceholder="firewall" />

                                        {/* <div className={firewallList.length>9?("getfirewall-wrapper"):("")}>
                                                   <div className={firewallList.length>9?("row getfirewall"):("row")}> */}
                                        <div className="getfirewall-wrapper">
                                            <div className="row getfirewall">
                                                {firewallList.map(
                                                    (value, index) => (
                                                        <>
                                                            <div className="col-md-4 col-lg-4 col-xxl-4 mt-5 firewall-content" style={{ position: "relative" }}>
                                                                {/*begin::Option*/}
                                                                <input
                                                                    className="btn-check deploy_checkbox"
                                                                    type="radio"
                                                                    name="firewall"
                                                                    defaultValue={value.id}
                                                                    id={"firewall" + value.id}
                                                                />
                                                                <label
                                                                    htmlFor={"firewall" + value.id}
                                                                    onClick={() =>
                                                                        selectTickMark(
                                                                            value.id,
                                                                            "Firewall",
                                                                            "firewall"
                                                                        )
                                                                    }
                                                                    className="borderFirewall btn btn-outline btn-outline btn-outline-default d-flex align-items-center"
                                                                    id={"borderFirewall-" + value.id}
                                                                    style={{ overflow: "hidden", height: "60px" }}
                                                                >
                                                                    {/*begin::Svg Icon | path: icons/duotune/communication/com003.svg*/}
                                                                    <span className="svg-icon svg-icon-primary svg-icon-4x me-1">
                                                                        <i className="lab la-hotjar" style={{ fontSize: "45px", color: "rgb(196, 196, 198)" }}></i>
                                                                    </span>
                                                                    {/*end::Svg Icon*/}
                                                                    <span className="d-block fw-bold text-start">
                                                                        <span className="text-dark fw-bolder d-block fs-6">
                                                                            {value.name}
                                                                        </span>
                                                                        <span className="text-muted fw-bold fs-8 w-100">
                                                                            {" "}
                                                                            {value.created_at}
                                                                        </span>
                                                                    </span>
                                                                    <div
                                                                        className="d-none tickMarkFirewall ribbon ribbon-triangle ribbon-top-start border-primary"
                                                                        id={
                                                                            "tickMarkFirewall-" + value.id
                                                                        }
                                                                    >
                                                                        <div className="ribbon-icon mt-n5">
                                                                            <i className="bi bi-check2 fs-2 text-white" />
                                                                        </div>
                                                                    </div>
                                                                </label>
                                                                {/*end::Option*/}
                                                            </div>
                                                        </>
                                                    )
                                                )}
                                            </div>
                                        </div></>)
                                        : (<><p className="text-gray-600 fw-bold fs-6 text-center">no security group found</p></>)}
                                    </>
                                ) : (
                                    <p className="text-gray-600 text-center fw-bold fs-6">no security group found</p>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

Firewall.propTypes = {
    firewallData: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.object),
        PropTypes.oneOf([null]),
    ]).isRequired,
    selectTickMark: PropTypes.func.isRequired,
};

export default Firewall;
