import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; 
import UseApi from "../../../../../../CustomHooks/useApi";  
import localStorage from "local-storage";
import AddFundRazorpay from "./sectionBtn/AddFundRazorpay";
export default function AddingFundBtns() {
  const dataFetchedRef = useRef(); 
  const [userData, setUserData] = useState(null);
  const [razorpayCardList, setRazorpayCardList] = useState(null);
  const [stripeCardList, setStripeCardList] = useState(null); 
  const getcards = async () => {
    const rdata = await UseApi("get", "razorpay/listcard", "");
    const sdata = await UseApi("get", "stripe/listcard", "");
    //const data = await UseApi("get", "account/info", "");
    setUserData(localStorage.get("user_info"));
    setStripeCardList(sdata.cards);
    setRazorpayCardList(rdata.cards); 
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getcards();
  }, []);


  return (
    <> 
      {userData!=null && userData.currency=="INR"?(<>
      <AddFundRazorpay />
      </>):(<>
        <AddFundRazorpay />
      </>)} 
    </>
  );
}
