import UseCallApi from "./useCallApi";
var serialize=require('form-serialize');
export default async function UseApi(type,url,formid){  
        var requestData={};
        if(formid!==''){
        var form=document.querySelector('#'+formid);
        var requestData=serialize(form,{hash:true});
        }
        const temp=await UseCallApi (type,url,requestData);
        return temp;
}

 