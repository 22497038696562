import { useNavigate } from "react-router-dom";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine"
import UseApi from "../../../../CustomHooks/useApi";
import copy from "copy-to-clipboard";
import Queue from "./Queue/Queue";
import swal from "sweetalert";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import CustomDoc from "../../../../CustomHooks/CustomDoc";
import ProductsUrl from "../../Docs/Docsdata";

export default function ManageSqs() {
    const [pageStatus, setPageStatus] = useState(false)
    const [sqsStatus, setSqsStatus] = useState("Pending")
    const [processCount, setProcessCount] = useState(0);
    const [sqsIp, setSqsIp] = useState('')
    const [sqs_name, setSqs_name] = useState('')
    const navigate = useNavigate();
    var query = window.location.href;
    var vars = query.split("/");
    var id = vars[4];
    const [sqsData, setSqsData] = useState(null);

    const getSqsById = async () => {
        const data = await UseApi('get', 'sqs/' + id, '');
        setSqsStatus(data?.sqs[0]?.status)
        setSqsData(data.sqs[0])
        setSqsIp(data.sqs[0].ip)
        setSqs_name(data.sqs[0].name)
    }

    const processBarRef = useRef(null);
    const process_bar_parentRef = useRef(null);
    const serverAlertRef = useRef(null);

    useEffect(() => {
        getSqsById();
        getSQSForStatus()
    }, []);

    const copyToClipboard = (target, targetBy) => {
        var ip_address = document.querySelector("#ip_address")
        var btn_id = document.querySelector("#" + targetBy);
        var target_id = document.querySelector("#" + target);
        if (btn_id && !ip_address.classList.contains('disabled')) {
            var copyText = btn_id.innerHTML;
            var copyEle = target_id.innerHTML;
            copy(copyEle);
            ip_address.classList.add("disabled")
            // svgIconElement.classList.add('d-none')
            btn_id.innerHTML = `<svg
          xmlns="http://www.w3.org/2000/svg"
          width=24px
          height=24px
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M9.89557 13.4982L7.79487 11.2651C7.26967 10.7068 6.38251 10.7068 5.85731 11.2651C5.37559 11.7772 5.37559 12.5757 5.85731 13.0878L9.74989 17.2257C10.1448 17.6455 10.8118 17.6455 11.2066 17.2257L18.1427 9.85252C18.6244 9.34044 18.6244 8.54191 18.1427 8.02984C17.6175 7.47154 16.7303 7.47154 16.2051 8.02984L11.061 13.4982C10.7451 13.834 10.2115 13.834 9.89557 13.4982Z"
            fill="black"
          />
        </svg>`;
            setTimeout(() => {
                btn_id.innerHTML = copyText;
                ip_address.classList.remove("disabled")
                // svgIconElement.classList.remove('d-none')
            }, 2000);
        }

    }

    const copySQSName = () => {
        var eleText = document.querySelector("#sqs_file_url");
        var ele = document.querySelector("#sqs_file_url_copy_btn");
    
        if (ele && eleText) {
          const copyText = eleText.innerText;
          const originalContent = ele.innerHTML;
    
          navigator.clipboard.writeText(copyText).then(() => {
            ele.innerHTML = "Copied";
    
            setTimeout(() => {
              ele.innerHTML = originalContent;
            }, 1000);
          }).catch(err => {
            console.error('Failed to copy text: ', err);
          });
        }
      };
      document.addEventListener('click', function (event) {
        if (event.target && event.target.id === 'sqs_file_url_copy_btn') {
            copySQSName();
          event.stopPropagation();
        }
      });

    const destroysqs = async () => {
        toast.dismiss();
        const { value: isDelete } = await Swal.fire({
            title: 'Destroy SQS',
            html: `
            <div className="d-flex align-items-center flex-wrap"> 
            <div id="kt_clipboard_4" className="me-5 fw-bold text-gray-600">
              <p class="fs-5">Enter the SQS name to Destroy:</p>
      
              <span class="fw-bold" id="sqs_file_url" >${sqs_name}</span><span id="sqs_file_url_copy_btn" style="cursor:pointer" class="text-primary"> ( copy ) </span> 
            </div>  
          </div>
         `,
            input: 'text',
            inputPlaceholder: 'SQS name',
            showCancelButton: true,
            confirmButtonText: 'Destroy',
            cancelButtonText: 'Cancel',
            customClass: {
                confirmButton: 'delete-button bg-danger', // Add your custom class here
            },
        });
        if (isDelete?.trim() == sqs_name) {
            // .then(async (willDelete) => {
            // if (willDelete) {
            setPageStatus(true)
            const dt = toast.loading("queue deleting...");
            const dnsp = await UseApi('DELETE', 'sqs/' + id + '/destroy?confirm=' + sqs_name, '');
            if (dnsp.status == 'error') {
                // setPageStatus(false)
                toast.update(dt, {
                    render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
                });
            } else {
                // setPageStatus(false)
                toast.update(dt, {
                    render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
                });
                const url = window.location.href
                if (query == url) {
                    navigate('/sqs');
                }
            }
        } else {
            swal({
                title: "Invalid SQS Name",
                text: "Please confirm the valid SQS!",
                icon: "warning",
                button: "OK",
            });
        }
    }
    const getSQSForStatus = async () => {
        const data = await UseApi('get', 'sqs/' + id, '');
        setSqsStatus(data?.sqs[0]?.status)
    }
    const SQSIntervalId = setInterval(() => {
        // Check if the API call is needed based on certain conditions
        if (!sqsStatus || (sqsStatus === "Pending")) {
            getSQSForStatus();
        }
    }, 9000);
    useEffect(() => {
        return () => clearInterval(SQSIntervalId);
    }, [SQSIntervalId]);

    // useEffect(() => {
    //     getActions()
    // }, [])
    // console.log(sqsData);
    // const getActions = async () => {
    //     const actionData = await UseApi("get", "actions", "action-form");
    //     console.log(actionData);
    //     if (actionData && actionData.actions) {
    //         //console.log(actionData);
    //         var dataInfo = null;
    //         var barStatus = false;
    //         actionData.actions.map((value, index) => {
    //             console.log(value.resource_id, sqsData,"axjizjxzijxo");
    //         })
    //         //     if (value.resource_id == kubernetesData?.info?.master?.cloudid, kubernetesData?.info) {
    //         //         console.log(value);
    //         //         dataInfo = value;
    //         //         barStatus = true;
    //         //     }
    //         // });
    //         // console.log(barStatus);
    //         // if (barStatus == false) {
    //         //     if (processBarRef.current && process_bar_parentRef.current && serverAlertRef.current) {
    //         //         process_bar_parentRef.current.classList.add("d-none");
    //         //         processBarRef.current.classList.add("d-none");
    //         //         serverAlertRef.current.classList.add("d-none");
    //         //         if (props.cloudInfo.stack) {
    //         //             // console.log("bar status false");
    //         //             var installing_stack = document.querySelector("#installing_stack");
    //         //             if (installing_stack) {
    //         //                 if (props.cloudInfo.stack.status != "Pending") {
    //         //                     installing_stack.classList.add("d-none");
    //         //                 } else {
    //         //                     installing_stack.classList.remove("d-none");
    //         //                 }
    //         //             }
    //         //         }

    //         //     }
    //         //     return;
    //         // }
    //         // if (barStatus == false && dataInfo != null) {
    //         //     if (processBarRef.current && process_bar_parentRef.current && serverAlertRef.current) {
    //         //         setProcessCount(100);
    //         //         process_bar_parentRef.current.classList.add("d-none");
    //         //         processBarRef.current.style.width = dataInfo.process + "%";
    //         //         processBarRef.current.classList.add("d-none");
    //         //         serverAlertRef.current.classList.add("d-none");
    //         //         if (props.cloudInfo.stack) {
    //         //             // console.log("bar status");
    //         //             var installing_stack = document.querySelector("#installing_stack");
    //         //             if (installing_stack) {
    //         //                 if (props.cloudInfo.stack.status != "Pending") {
    //         //                     installing_stack.classList.add("d-none");
    //         //                 } else {
    //         //                     installing_stack.classList.remove("d-none");
    //         //                 }
    //         //             }
    //         //         }
    //         //     }
    //         //     return;
    //         // }
    //         // if (dataInfo != null) {
    //         //     if (dataInfo.id != null) {

    //         //         if (dataInfo.status == "Pending") {
    //         //             toast.dismiss();
    //         //             if (processBarRef.current && process_bar_parentRef.current) {
    //         //                 setProcessCount(dataInfo.process);
    //         //                 process_bar_parentRef.current.classList.remove("d-none");
    //         //                 processBarRef.current.style.width = dataInfo.process + "%";
    //         //                 processBarRef.current.classList.remove("d-none");
    //         //             }
    //         //             setTimeout(() => {
    //         //                 getActions();
    //         //             }, 2000);
    //         //         } else if (dataInfo.status == "Support") {
    //         //             // const processBar=document.querySelector("#process_bar");
    //         //             // const process_bar_parent=document.querySelector("#process_bar_parent");
    //         //             // const server_status=document.querySelector("#server-status");
    //         //             //console.log("outer");
    //         //             if (processBarRef.current && process_bar_parentRef.current && serverAlertRef.current) {
    //         //                 //console.log("inner");
    //         //                 setProcessCount(dataInfo.process);
    //         //                 process_bar_parentRef.current.classList.remove("d-none");
    //         //                 processBarRef.current.style.width = dataInfo.process + "%";
    //         //                 processBarRef.current.classList.remove("d-none");
    //         //                 serverAlertRef.current.classList.remove("d-none");
    //         //             }
    //         //             setTimeout(() => {
    //         //                 getActions();
    //         //             }, 2000);
    //         //         } else if (dataInfo.status == "Complete") {
    //         //             if (processBarRef.current && process_bar_parentRef.current && serverAlertRef.current) {
    //         //                 setProcessCount(dataInfo.process);
    //         //                 process_bar_parentRef.current.classList.add("d-none");
    //         //                 processBarRef.current.style.width = dataInfo.process + "%";
    //         //                 processBarRef.current.classList.add("d-none");
    //         //                 serverAlertRef.current.classList.add("d-none");
    //         //                 if (props.cloudInfo.stack) {
    //         //                     // console.log("complete");
    //         //                     var installing_stack = document.querySelector("#installing_stack");
    //         //                     if (installing_stack) {
    //         //                         if (props.cloudInfo.stack.status != "Pending") {
    //         //                             installing_stack.classList.add("d-none");
    //         //                         } else {
    //         //                             installing_stack.classList.remove("d-none");
    //         //                         }
    //         //                     }
    //         //                 }
    //         //             }
    //         //         }

    //         //     } else {
    //         //         ////console.log(actionData);
    //         //     }
    //         //     return;
    //         // }

    //     }
    // }
    return (
        <div className="col-xl-12">
            {sqsData != null ? (<>
                {/* <div className="post d-flex flex-column-fluid">
                    <div className="container-xxl" id="appresult"> */}
                <div className="">
                    <div className="" id="appresult">
                        <div className="card mb-6 mb-xl-9">
                            <div className="card-body pt-9 pb-0">
                                {/*begin::Details*/}
                                <div className="d-flex flex-wrap flex-sm-nowrap mb-6">
                                    {/*begin::Image*/}
                                    <div className="d-flex flex-center flex-shrink-0 bg-light rounded w-55px h-55px w-lg-55px h-lg-55px me-7 mb-4" id="flag-icon">
                                        {/*begin::Image input*/}
                                        {/* <div className="image-input image-input-empty mt-5" data-kt-image-input="true"> */}
                                        <span className="w-50">
                                            <svg
                                                viewBox="-26.5 0 309 309"
                                                version="1.1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                preserveAspectRatio="xMidYMid"
                                                fill="#000000"
                                                width='30px'
                                                height='40px'
                                            >
                                                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                                                <g id="SVGRepo_iconCarrier">
                                                    {" "}
                                                    <g>
                                                        {" "}
                                                        <path
                                                            d="M255.991,100.211 L251.879,100.142 L128.087,63.492 L128.013,61.796 L128.013,0 L256,64.006 L255.991,100.211"
                                                            fill="#494b74"
                                                        >
                                                            {" "}
                                                        </path>{" "}
                                                        <path
                                                            d="M128,65.5 L128.013,0 L0.017,63.981 L0,244.996 L0.064,245.028 L0.064,245.03 L127.987,309.003 L128.412,308.412 L128.247,247.541 L127.996,247.202 L120,241.5 L21,212 L21.5,97.5 L128,65.5"
                                                            fill="#494b74"
                                                        >
                                                            {" "}
                                                        </path>{" "}
                                                        <path
                                                            d="M147.141,195.298 L16.351,213.697 L16.36,95.28 L147.15,113.704 L147.141,195.298"
                                                            fill="#343651"
                                                        >
                                                            {" "}
                                                        </path>{" "}
                                                        <path
                                                            d="M80.864,187.813 L127.996,193.813 L128.004,115.155 L80.873,121.15 L80.864,187.813"
                                                            fill="#494b74"
                                                        >
                                                            {" "}
                                                        </path>{" "}
                                                        <path
                                                            d="M33.385,181.771 L63.972,185.659 L63.981,123.296 L33.385,127.193 L33.385,181.771"
                                                            fill="#494b74"
                                                        >
                                                            {" "}
                                                        </path>{" "}
                                                        <path
                                                            d="M16.36,95.28 L128.013,61.796 L255.991,100.211 L147.231,113.713 L16.36,95.28"
                                                            fill="#343651"
                                                        >
                                                            {" "}
                                                        </path>{" "}
                                                        <path
                                                            d="M255.927,177.376 L127.996,193.521 L128.004,115.155 L255.927,131.451 L255.927,177.376"
                                                            fill="#494b74"
                                                        >
                                                            {" "}
                                                        </path>{" "}
                                                        <path
                                                            d="M255.918,208.834 L253.165,208.953 L128.389,246.723 L127.996,247.202 L127.987,309.003 L255.918,245.052 L255.918,208.834"
                                                            fill="#494b74"
                                                        >
                                                            {" "}
                                                        </path>{" "}
                                                        <path
                                                            d="M16.351,213.697 L127.996,247.202 L255.918,208.834 L147.141,195.298 L16.351,213.697"
                                                            fill="#343651"
                                                        >
                                                            {" "}
                                                        </path>{" "}
                                                    </g>{" "}
                                                </g>
                                            </svg>
                                        </span>

                                        {/* <div className="image-input-wrapper w-60px h-40px" /> */}
                                        {/* </div> */}
                                        {/*end::Image input*/}
                                    </div>
                                    {/*end::Image*/}
                                    {/*begin::Wrapper*/}
                                    <div className="flex-grow-1">
                                        {/*begin::Head*/}
                                        <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                            {/*begin::Details*/}
                                            <div className="d-flex flex-column">
                                                {/*begin::Status*/}
                                                <div className="d-flex align-items-center mb-1">
                                                    <a
                                                        className="text-gray-800 text-hover-primary fs-2 fw-bold me-3"
                                                        id="clustername"
                                                    >
                                                        {sqsData.name}
                                                    </a>
                                                </div>
                                                {/*end::Status*/}
                                                {/*begin::Description*/}
                                                <div className="flex-grow-1">
                                                    <p className="text-muted">
                                                        <span
                                                            className="text-muted text-hover-primary fw-bold fs-6"
                                                            id="country"
                                                        >
                                                            {sqsData?.dclocation?.country}
                                                        </span>
                                                        <span className="text-muted fw-bold" id="location">
                                                            {sqsData?.dclocation?.location}
                                                        </span>
                                                    </p>
                                                </div>
                                                {/*end::Description*/}
                                                {/*begin::Wrapper*/}
                                                <div className="d-flex flex-column flex-grow-1 pe-8 d-none">
                                                    {/*begin::Stats*/}
                                                    <div className="d-flex flex-wrap">
                                                        {/*begin::Stat*/}
                                                        <div className="min-w-125px py-3 px-4 me-6 mb-3">
                                                            {/*begin::Number*/}
                                                            <div className="d-flex align-items-center">
                                                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
                                                                <span className="svg-icon svg-icon-3 svg-icon-success me-2">
                                                                    {/*begin::Svg Icon | path: assets/media/icons/duotune/abstract/abs027.svg*/}
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={24}
                                                                        height={24}
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                    >
                                                                        <path
                                                                            opacity="0.3"
                                                                            d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z"
                                                                            fill="black"
                                                                        />
                                                                        <path
                                                                            d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z"
                                                                            fill="black"
                                                                        />
                                                                    </svg>
                                                                    {/*end::Svg Icon*/}
                                                                </span>
                                                                {/*end::Svg Icon*/}
                                                                {/*begin::Label*/}
                                                                <div className="fw-bold fs-6 text-gray-400 me-3">Cluster IP : </div>
                                                                {/*end::Label*/}
                                                                <div className="fs-2 fw-bold" id="masterip">
                                                                    {sqsIp}
                                                                </div>
                                                            </div>
                                                            {/*end::Number*/}

                                                        </div>
                                                        {/*end::Stat*/}
                                                    </div>
                                                    {/*end::Stats*/}
                                                </div>
                                                {/*end::Wrapper*/}
                                            </div>
                                            {/*end::Details*/}
                                            {/*begin::Actions*/}
                                            <div className="d-flex mb-4 ">

                                                {/*begin::Number*/}
                                                <div className=" zoom me-5 btn btn-sm btn-light-primary sphide" id="ip_address" onClick={() => copyToClipboard('ip_copy', "sqsname_btn")} style={{ width: '15rem' }}>
                                                    <div className="mt-1">
                                                        IP Address:{" "}
                                                        <span id="ip_copy" className="fw-bold">
                                                            {sqsIp.trim()}
                                                        </span>
                                                        <span className="svg-icon svg-icon-primary svg-icon-2 ms-1" id="sqsname_btn">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={24}
                                                                height={24}
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    opacity="0.5"
                                                                    d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                                                                    fill="black"
                                                                />
                                                                <path
                                                                    fillRule="evenodd"
                                                                    clipRule="evenodd"
                                                                    d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                                                                    fill="black"
                                                                />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </div>

                                                {/*end::Number*/}

                                                <div id="kstatus">
                                                    {/* <div className="text-gray-800 fs-1 me-3">
                                                        {sqsData.status == "Active" ? (<a className=" btn btn-sm btn-success" style={{cursor:'default'}}>Active</a>) : (<a className=" btn btn-sm btn-warning" style={{cursor:'default'}}>Pending</a>)}
                                                    </div> */}
                                                    {sqsStatus == "Active" ?
                                                        <div className="btn btn-success fw-bold ms-5">  {sqsStatus}  </div>
                                                        :
                                                        <div className="btn btn-primary fw-bold ms-5">  <span class="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>  </div>
                                                    }
                                                </div>
                                            </div>
                                            {/*end::Actions*/}
                                        </div>
                                        {/*end::Head*/}
                                    </div>
                                    {/*end::Wrapper*/}
                                </div>
                                {/*end::Details*/}
                                <div className="separator" />
                                {/*begin::Nav*/}
                                <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                                    {/*begin::Nav item*/}
                                    <li className="nav-item">
                                        <a
                                            className="nav-link text-active-primary py-5 me-6 active"
                                            data-bs-toggle="tab"
                                            href="#resources"
                                        >
                                            Queue
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-bs-toggle="tab" href="#destroy_vpc">
                                            Destroy SQS
                                        </a>
                                    </li>
                                    {/*end::Nav item*/}
                                </ul>
                                {/*end::Nav*/}
                            </div>
                        </div>
                        <div className="progress d-none mb-5" id="process_bar_parent" ref={process_bar_parentRef}>
                            <div
                                className="progress-bar progress-bar-striped progress-bar-animated bg-primary d-none"
                                ref={processBarRef}
                                role="progressbar"
                                id="process_bar"
                                style={{ width: "0%" }}
                                aria-valuenow={0}
                                aria-valuemin={0}
                                aria-valuemax={100}
                            >
                                {processCount}%
                            </div>
                        </div>
                        <div className="alert alert-danger d-none" id="server_alert" ref={serverAlertRef}>
                            <div className="d-flex flex-column">
                                <span>Uh Oh! That was unexpected! An error has occured and we're working to fix the problem!</span>
                            </div>
                        </div>
                        <div className="tab-content" id="myTabContent">
                            {sqsStatus == "Active" ?
                                <div
                                    className="tab-pane fade show active"
                                    id="resources"
                                    role="tabpanel"
                                >
                                    <Queue />
                                </div>
                                :
                                <div
                                    className="tab-pane fade show active" id="resources" role="tabpanel">
                                    <div className="text-center">
                                        <span className="fw-bold text-center text-gray-600">Installing SQS, Please Wait...</span>
                                        <div className="loadingStack">
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="tab-pane fade" id="destroy_vpc" role="tabpanel">
                                {/*begin::Deactivate Account*/}
                                <div className="card">
                                    {/*begin::Card header*/}
                                    <div
                                        className="card-header border-0 cursor-pointer"
                                        role="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#kt_account_deactivate"
                                        aria-expanded="true"
                                        aria-controls="kt_account_deactivate"
                                    >
                                        <div className="card-title m-0">
                                            <h3 className="fw-bold m-0">Destroy SQS</h3>
                                        </div>
                                        <div className="card-toolbar">
                                            <div className="position-relative my-1" style={{ marginRight: 20 }}>
                                                <CustomDoc url={ProductsUrl[0]?.sqs + "/manage-sqs/#destroy"} />
                                            </div>
                                        </div>
                                    </div>
                                    {/*end::Card header*/}
                                    {/*begin::Content*/}
                                    <div id="kt_account_settings_deactivate" className="collapse show">
                                        {/*begin::Form*/}
                                        <form id="destroyform" className="form">
                                            {/*begin::Card body*/}
                                            <div className="card-body border-top p-9">
                                                {/*begin::Notice*/}
                                                <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
                                                    {/*begin::Icon*/}
                                                    {/*begin::Svg Icon | path: icons/duotune/general/gen044.svg*/}
                                                    <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={24}
                                                            height={24}
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                        >
                                                            <rect
                                                                opacity="0.3"
                                                                x={2}
                                                                y={2}
                                                                width={20}
                                                                height={20}
                                                                rx={10}
                                                                fill="black"
                                                            />
                                                            <rect
                                                                x={11}
                                                                y={14}
                                                                width={7}
                                                                height={2}
                                                                rx={1}
                                                                transform="rotate(-90 11 14)"
                                                                fill="black"
                                                            />
                                                            <rect
                                                                x={11}
                                                                y={17}
                                                                width={2}
                                                                height={2}
                                                                rx={1}
                                                                transform="rotate(-90 11 17)"
                                                                fill="black"
                                                            />
                                                        </svg>
                                                    </span>
                                                    {/*end::Svg Icon*/}
                                                    {/*end::Icon*/}
                                                    {/*begin::Wrapper*/}
                                                    <div className="d-flex flex-stack flex-grow-1">
                                                        {/*begin::Content*/}
                                                        <div className="fw-bold">
                                                            <h4 className="text-gray-900 fw-bold">
                                                                This will destroy your SQS.
                                                            </h4>
                                                            <div className="fs-6 text-gray-700">
                                                                Note: This option will delete your SQS from the server
                                                                for permanently and this not be able to undo.
                                                            </div>
                                                        </div>
                                                        {/*end::Content*/}
                                                    </div>
                                                    {/*end::Wrapper*/}
                                                </div>
                                                {/*end::Notice*/}
                                                {/*begin::Form input row*/}
                                                <div className="mb-10">
                                                    <label htmlFor="size" className="required form-label">
                                                        Your SQS will be permanently destroyed. You will lose the
                                                        provisioned Network addresses.
                                                    </label>
                                                </div>
                                                {/*end::Form input row*/}
                                            </div>
                                            {/*end::Card body*/}
                                            {/*begin::Card footer*/}
                                            <div
                                                className="card-footer d-flex justify-content-end py-6 px-9"
                                                id="destroy_btn"
                                            >
                                                <button
                                                    id="destroy-btn"
                                                    onClick={destroysqs}
                                                    // onclick="call_api('DELETE','vpc/undefined/destroy','destroy','refdestroy','#vpc')"
                                                    type="button"
                                                    className="btn btn-danger fw-bold"
                                                >
                                                    Destroy SQS
                                                </button>
                                            </div>
                                            {/*end::Card footer*/}
                                        </form>
                                        {/*end::Form*/}
                                    </div>
                                    {/*end::Content*/}
                                </div>
                                {/*end::Deactivate Account*/}
                            </div>
                        </div>
                    </div>
                </div>
            </>) : (<><TopBarHeaderLine /></>)}
        </div>
    )
}