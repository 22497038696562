import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import Swal from 'sweetalert2';
import ReactPaginate from "react-paginate";
import UsePost from "../../../../../../CustomHooks/usePost";
import UseApi from "../../../../../../CustomHooks/useApi";
import TopBarHeaderLine from "../../../../../../CustomHooks/TopBarHeaderLine";
import VirtualHostPermissionDrawer from "../virtualHostPermissionDrawer/VirtualHostPermissionDrawer";

const RabbitMqVirtualHost = (props) => {
  var query = window.location.href;
  const [pageStatus, setPageStatus] = useState(false)
  const [activePage, setActivePage] = useState(0);
  var vars = query.split("/");
  var id = vars[4];

  const [pageCount, setPageCount] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [itemOffset, setItemOffset] = useState(0);
  const [databaseIPData, setDatabaseIPData] = useState([]);
  const [dummyDatabaseIPData, setDummyDatabaseIPData] = useState([]);
  const [currentDatabaseInfo, setCurrentDatabaseInfo] = useState(null);

  const openDrawer = () => {
    var drawerElement = document.querySelector("#databaseIp-modal");
    var drawer_overlay = document.querySelector("#drawer_overlay_com");
    drawerElement.classList.toggle("drawer-on");
    drawer_overlay.classList.toggle('d-none')
    drawerElement.style.width = '500px';
  }


  function ProductInfo({ productdata }) {
    console.log(productdata);

    return (
      <>
        {productdata != null && Object.keys(productdata).length != 0 ? (productdata && productdata.map((value, index) => (
          <tr>
            <td className="ps-9 text-gray-700 text-start">{value}</td>
            <td className="ps-9 text-gray-700 text-start">{value}</td>
            <td className="ps-9 text-gray-700 text-start">{value}</td>
            <td className="ps-9 text-gray-700 text-start">{value}</td>
            <td className="ps-9 text-gray-700 text-start">{value}</td>
            <td className="ps-9 text-gray-700 text-start">{value}</td>
            <td className="ps-9 text-gray-700 text-start">{value}</td>
            <td className="ps-9 text-gray-700 text-start">{value}</td>
            <td className="ps-9 text-gray-700 text-start">{value}</td>
            <td className="ps-9 text-gray-700 text-start">{value}</td>
            <td></td>
            <td className="text-end">
              <a className="btn btn-primary btn-active-light-primary btn-sm sphide" onClick={openPermissionDrawer}>
                Manage Permission
                <span className="svg-icon ms-2 svg-icon-3">

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >

                    <rect
                      opacity="0.5"
                      x={18}
                      y={13}
                      width={13}
                      height={2}
                      rx={1}
                      transform="rotate(-180 18 13)"
                      fill="black"
                    />
                    <path
                      d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                      fill="black"
                    />
                  </svg>
                </span>
              </a>
            </td>
          </tr>
        ))) : (<>
          <tr>
            {/* <td colspan="12" className="dataTables_empty" id="loader">
              <div className="loading-spinner"></div>
            </td> */}
            <td className="text-end">
              <a className="btn btn-primary btn-active-light-primary btn-sm sphide" onClick={openPermissionDrawer}>
                Manage Permission
                <span className="svg-icon ms-2 svg-icon-3">

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >

                    <rect
                      opacity="0.5"
                      x={18}
                      y={13}
                      width={13}
                      height={2}
                      rx={1}
                      transform="rotate(-180 18 13)"
                      fill="black"
                    />
                    <path
                      d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                      fill="black"
                    />
                  </svg>
                </span>
              </a>
            </td>
            <td colspan="12" className="" id="no_record" valign="top">
              {/* <div className="d-flex flex-column flex-center">
                <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
              </div> */}
              <div className="mt-20 mb-20 text-center">
                <span className="fs-6 text-gray-800 fw-bold">no record found</span>
              </div>
            </td>
          </tr>
        </>)}

      </>
    )
  }
  const handleSearch = (text) => {
    const endOffset = itemOffset + perPage;
    let matches = databaseIPData.filter((name) => {
      const regex = new RegExp(`${text}`, "gi");
      return name.match(regex);
    })
    setDummyDatabaseIPData(matches);;
    setPageCount(Math.ceil(matches.length / perPage));
  }
  function PaginatedItems({ itemsPerPage, ProductData }) {

    const [itemOffset, setItemOffset] = useState(0);
    const [activePage, setActivePage] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    useEffect(() => {
      setPageCount(Math.ceil(ProductData.length / itemsPerPage));
    }, [ProductData.length, itemsPerPage]);

    const endOffset = Math.min(itemOffset + itemsPerPage, ProductData.length);

    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % ProductData.length;
      setItemOffset(newOffset);
      setActivePage(event.selected);
    };

    return (
      <>
        <ProductInfo productdata={ProductData?.slice(itemOffset, endOffset)} />
        <td colspan="12">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            initialPage={activePage}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </td>
      </>
    );
  }


  // const handleKeyPress = (event) => {
  //   console.log(event.key);
  //   if (event.key === 'Enter') {
  //     // Call your function here
  //     whitelistIP(event);
  //   }
  // }

  const openPermissionDrawer = () => {
    var drawerElement = document.querySelector("#rabbitmqVirtualHost-modal");
    var drawer_overlay = document.querySelector("#rabbitmqDrawerVirtualHost_overlay");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
        drawerElement.style.width = "100%";
      } else {
        drawerElement.style.width = "70%";
      }
      drawer_overlay.classList.toggle("d-none");
    }
  }

  console.log(dummyDatabaseIPData);


  return (
    <>
      <div className="card">
        <div className="card-header">
          <div className="card-title">
            <h3>Virtual Host </h3>
          </div>
          <div className="card-toolbar">
            <div
              className="position-relative my-1"
              style={{ marginRight: 20 }}
            >
              <input
                type="text"
                className="form-control form-control-sm"
                data-kt-table-widget-4="search"
                id="searchClient"
                // value={searchTerm}
                onChange={e => handleSearch(e.target.value)}
                placeholder="Search"
              />
            </div>

            <div style={{ marginRight: 20 }}>
              <div className="dataTables_length">
                <label className="w-125px">
                  <select
                    onChange={e => setPerPage(e.target.value)}
                    //data-control="select2"
                    className="form-select form-select-sm form-select-solid"
                    defaultValue={5}
                    id="perPage"

                  >
                    <option value={5} selected="">
                      5 Records
                    </option>
                    <option value={10}>10 Records</option>
                    <option value={25}>25 Records</option>
                    <option value={50}>50 Records</option>
                    <option value={100}>100 Records</option>
                  </select>
                </label>
              </div>
            </div>

            {/* {currentDatabaseInfo?.ref_type=="dbaas_master" || currentDatabaseInfo?.ref_type=="dbaas_replica"?(<> */}
            {props.currentDatabaseInfo?.ref_type != "dbaas_node" ?
              <a onClick={openDrawer} id="" className="btn btn-sm btn-primary my-1 sphide">
                + Add Virtual Host
              </a>
              :
              ""
            }
            {/* </>):('')} */}
          </div>
        </div>

        <div className="card-body p-0">
          <div className="table-responsive">
            <table
              className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
              id="kt_security_license_usage_table"
            >
              <thead className="border-gray-200 fs-5 fw-bold bg-secondary">
                <tr>
                  <th className="w-150px ps-9 text-start">Name</th>
                  {/* <th className="w-50px ps-9"></th> */}
                  <th className="w-150px text-center">User</th>
                  <th className="w-150px text-center">State</th>
                  <th className="w-150px text-center">Ready</th>
                  <th className="w-150px text-center">Unacked</th>
                  {/* <th className="w-50px ps-9"></th> */}
                  <th className="w-150px text-center">Total</th>
                  <th className="w-150px text-center">From Client</th>
                  <th className="w-150px text-center">To Client</th>
                  {/* <th className="w-50px ps-9"></th> */}
                  <th className="w-150px text-center">published</th>
                  <th className="w-150px text-center">deliver</th>
                </tr>
              </thead>
              <tbody id="public-IP-list" className="fw-6 fw-bold text-gray-600">
                {dummyDatabaseIPData != null ? (<>
                  {dummyDatabaseIPData.length == 0 ? (<>
                    <tr>
                      <td className="text-end">
                        <a className="btn btn-primary btn-active-light-primary btn-sm sphide" onClick={openPermissionDrawer}>
                          Manage Permission
                          <span className="svg-icon ms-2 svg-icon-3">

                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                            >

                              <rect
                                opacity="0.5"
                                x={18}
                                y={13}
                                width={13}
                                height={2}
                                rx={1}
                                transform="rotate(-180 18 13)"
                                fill="black"
                              />
                              <path
                                d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                fill="black"
                              />
                            </svg>
                          </span>
                        </a>
                      </td>
                      <td colspan="12" id="no_record" valign="top">
                        {/* <div className="d-flex flex-column flex-center">
                          <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                        </div> */}
                        <div className="mt-20 mb-20 text-center">
                          <span className="fs-6 text-gray-800 fw-bold">no record found</span>
                        </div>
                      </td>
                    </tr>
                  </>) : (<>
                    <PaginatedItems itemsPerPage={perPage} ProductData={dummyDatabaseIPData} />
                  </>)}
                </>) : ('')}
              </tbody>
            </table>
          </div>
        </div>
        <div id="drawer_overlay_com" onClick={openDrawer} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>

        <div
          id="databaseIp-modal"
          className="bg-body shadow drawer drawer-end virtual-host-drawer"
          data-kt-drawer="true"
          data-kt-drawer-name="chat"
          data-kt-drawer-activate="true"
          data-kt-drawer-direction="end"
          data-kt-drawer-close="#close_ssh"
          data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
        >
          {/*begin::Messenger*/}
          <div className="card rounded-0 w-100 border-0" id="kt_drawer_chat_messenger">
            {/*begin::Card header*/}
            <div className="card-header pe-5" id="kt_drawer_chat_messenger_header">
              {/*begin::Title*/}
              <div className="card-title">
                {/*begin::User*/}
                <div className="d-flex justify-content-center flex-column me-3">
                  <a

                    className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1"
                  >
                    Add New virtual Host
                  </a>
                </div>
                {/*end::User*/}
              </div>
              {/*end::Title*/}
              {/*begin::Card toolbar*/}
              <div className="card-toolbar">
                {/*begin::Close*/}
                <div
                  onClick={openDrawer}
                  className="btn btn-sm btn-icon btn-active-light-primary"
                  id="close_ssh"
                >
                  {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                  <span className="svg-icon svg-icon-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.5"
                        x={6}
                        y="17.3137"
                        width={16}
                        height={2}
                        rx={1}
                        transform="rotate(-45 6 17.3137)"
                        fill="black"
                      />
                      <rect
                        x="7.41422"
                        y={6}
                        width={16}
                        height={2}
                        rx={1}
                        transform="rotate(45 7.41422 6)"
                        fill="black"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                </div>
                {/*end::Close*/}
              </div>
              {/*end::Card toolbar*/}
            </div>
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className="" id="">
              {/*begin::Form*/}
              <form id="databaseIp-form" className="form">
                {/*begin::Modal body*/}
                <div className="modal-body ">
                  {/*begin::Scroll*/}
                  <div
                    className="scroll-y me-n7 pe-7"
                    id="kt_modal_create_api_key_scroll"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                    data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                    data-kt-scroll-offset="300px"
                  >
                    {/*begin::Input group*/}
                    <div className="row">

                      <div className="col-12">
                        <label className="mt-5 required fs-5 fw-bold mb-2"> Name</label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <input type="hidden" name="type" value="add" />
                        <input
                          type="text"
                          className="form-control"
                          id="trustedHost"
                          name="trusted_host"
                          placeholder="Name"
                        // onKeyPress={handleKeyPress}
                        />
                      </div>

                      <div className="col-12">
                        <label className="mt-5 required fs-5 fw-bold mb-2"> Tags</label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <input type="hidden" name="type" value="add" />
                        <input
                          type="text"
                          className="form-control"
                          id="trustedHost"
                          name="trusted_host"
                          placeholder="Tags"
                        // onKeyPress={handleKeyPress}
                        />
                      </div>
                      <div className="col-12">
                        <label className="mt-5 required fs-5 fw-bold mb-2">Description</label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <input type="hidden" name="type" value="add" />
                        <input
                          type="text"
                          className="form-control"
                          id="trustedHost"
                          name="trusted_host"
                          placeholder="Description"
                        // onKeyPress={handleKeyPress}
                        />
                      </div>


                      {/*end::Input*/}
                    </div>


                    {/*end::Input group*/}








                  </div>




                  {/*end::Scroll*/}
                </div>
                {/*end::Modal body*/}
                {/*begin::Modal footer*/}
                <div className="modal-footer ">
                  {/*begin::Button*/}
                  <button
                    // onClick={whitelistIP}
                    className="btn btn-primary w-100"
                    id="create-databaseIp-btn"
                  >
                    <span className="indicator-label"> Add Virtual Host</span>
                  </button>
                  {/*end::Button*/}
                </div>
                {/*end::Modal footer*/}
              </form>
              {/*end::Form*/}
            </div>


            {/*end::Card body*/}
          </div>
          {/*end::Messenger*/}
        </div>
      </div>
      <VirtualHostPermissionDrawer openPermissionDrawer={openPermissionDrawer} />
    </>
  );
}

export default RabbitMqVirtualHost 
