import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import copy from "copy-to-clipboard";
import TableLoader from "../../../Loaders/TableLoader";
const History = (props) => {
  // CSS End
  const [perPage, setPerPage] = useState(5);
  const [cloudData, setCloudData] = useState(null);
  const [dummyCloudData, setDummyCloudData] = useState(null);
  const [activePage, setActivePage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const loaderFunc = () => {
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if (loader && no_record) {
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");

      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
  }

  const getCloud = async () => {
    if (props && props.cloud_data) {
      setCloudData(props.cloud_data);
      setDummyCloudData(props.cloud_data)
    }
  };


  useEffect(() => {
    getCloud();
    // //console.log("cloud coming");
  }, []);


  function ProductInfo({ productdata }) {
    return (
      <>
        {productdata != null && Object.keys(productdata).length != 0 ? (productdata && productdata.map((cloud, index) => (
          <tr>
            {" "}
            <td className="text-start">
              <div style={{ cursor: "pointer" }} className="text-dark fw-bold text-hover-primary fs-6">
                {cloud.hostname}
              </div>
            </td>
            <td className="text-center">
              {" "}
              <div className="d-flex flex-column w-100 me-2">
                {" "}
                <div className=" flex-stack mb-2">
                  {" "}
                  <span className="w-100  sphide me-2 fs-7 fw-bold"
                  >
                    <span> {cloud.hours == '0' ? ('') : (<>{cloud.hours == "1" ? (cloud.hours + ' hour, ') : (cloud.hours + ' hours, ')}</>)} {cloud.minutes == '0' ? ('') : (<>{cloud.minutes == "1" ? (cloud.minutes + ' minute ') : (cloud.minutes + ' minutes ')}</>)}</span>
                  </span>{" "}
                </div>{" "}
              </div>{" "}
            </td>
            <td className="text-center">
              {" "}
              <div className="d-flex flex-column w-100 me-2">
                {" "}
                <div className=" flex-stack mb-2">
                  {" "}
                  <span className="w-100  sphide me-2 fs-7 fw-bold"
                  >
                    <span> {cloud.created_at}</span>
                  </span>{" "}
                </div>{" "}
              </div>{" "}
            </td>
            <td className="text-center">
              {" "}
              <div className="d-flex flex-column w-100 me-2">
                {" "}
                <div className=" flex-stack mb-2">
                  {" "}
                  <span className="w-100 text-danger sphide me-2 fs-7 fw-bold"
                  >
                    <span> {cloud.deleted_at}</span>
                  </span>{" "}
                </div>{" "}
              </div>{" "}
            </td>
          </tr>
        ))) : (<>
          <tr>
            {/* <td colspan="12" className="dataTables_empty" id="loader"> */}
            {/* <TableLoader /> */}
            {/* <div className="loading-spinner"></div> */}
            {/* </td> */}
            <td colspan="12" className="" id="no_record" valign="top">
              <div className="d-flex flex-column flex-center">
                <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
              </div>
            </td>
          </tr>
        </>)}
      </>
    )
  }

  const handleSearch = (text) => {
    const endOffset = itemOffset + perPage;
    const data = cloudData.filter(row => {
      return (
        (row.hostname && row.hostname.toString().toLowerCase().includes(text.toLowerCase()))
        // (row.network && row.network.toString().toLowerCase().includes(text.toLowerCase()))
      )
    });
    setDummyCloudData(data.slice(itemOffset, endOffset));;
    setPageCount(Math.ceil(data.length / perPage));
  }

  function PaginatedItems({ itemsPerPage, ProductData, search }) {
    var endOffset = endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(ProductData.length / itemsPerPage));

    const handlePageClick = (event) => {
      setActivePage((old) => old = event.selected);
      const newOffset = event.selected * itemsPerPage;
      setItemOffset(newOffset);
    };
    return (
      <>
        <ProductInfo productdata={ProductData.slice(itemOffset, endOffset)} />
        <td colspan="12" className="ps-3">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            initialPage={activePage}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </td>
      </>
    );
  }
  return (
    <>
      <div className="col-xl-12">
        {/*begin::Tables Widget 9*/}
        <div className="card card-xl-stretch mb-5 mb-xl-12">
          {/*begin::Header*/}
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold fs-3 mb-1">
                Cloud Instances
                {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                <span className="svg-icon svg-icon-2 d-none">
                  <i className="las la-cloud" style={{ fontSize: 20 }} />
                </span>
                {/*end::Svg Icon*/}
              </span>
              <span className="text-muted mt-1 fw-bold fs-7" />
            </h3>
            <div
              className="card-toolbar"
            >

              <div
                className="position-relative my-1"
                style={{ marginRight: 20 }}
              >
                <input
                  type="text"
                  className="form-control form-control-sm"
                  data-kt-table-widget-4="search"
                  id="searchClient"
                  onChange={e => handleSearch(e.target.value)}
                  placeholder="Search"
                />
              </div>

              <div style={{ marginRight: 20 }}>
                <div className="dataTables_length">
                  <label className="w-125px">
                    <select
                      onChange={e => setPerPage(e.target.value)}
                      //data-control="select2"
                      className="form-select form-select-sm form-select-solid"
                      defaultValue={5}
                      id="perPage"

                    >
                      <option value={5} selected="">
                        5 Records
                      </option>
                      <option value={10}>10 Records</option>
                      <option value={25}>25 Records</option>
                      <option value={50}>50 Records</option>
                      <option value={100}>100 Records</option>
                    </select>
                  </label>
                </div>
              </div>

            </div>
          </div>
          {/*end::Header*/}
          {/*begin::Body*/}
          {/*begin::Table container*/}

          <div className="table-responsive">
            {/*begin::Table*/}
            <table
              id="clouddata"
              className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
            >
              {/*begin::Table head*/}
              <thead className="bg-secondary">
                <tr className="fw-bold ">
                  <th className="w-25 text-start ps-9">Name</th>
                  <th className="w-25 text-center">Usage</th>
                  <th className="w-25 text-center">Created_at</th>
                  <th className="w-50 text-center" >Deleted_at</th>
                </tr>
              </thead>
              {/*end::Table head*/}
              {cloudData != null ? (<>
                {Object.keys(cloudData).length != 0 ? (<>
                  {cloudData.length > 0 ? (<>
                    <PaginatedItems itemsPerPage={perPage} ProductData={dummyCloudData} />
                  </>) : (<>  </>)}
                </>) : (<>
                  <tr>
                    <td className="text-center" colspan={12}>
                      <span className="text-gray-800 fw-bold">No Instance Found</span>
                    </td>
                  </tr>
                </>)}
              </>) : (<>
                <tr>
                  <td colspan={12}>
                    <TableLoader />
                  </td>
                </tr>

              </>)}
            </table>
            {/*end::Table*/}
          </div>
          {/*end::Table container*/}
          {/*begin::Body*/}
        </div>
        {/*end::Tables Widget 9*/}
      </div>

    </>
  )

}

export default History;
