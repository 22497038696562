import React from 'react'

export default function KubernetesInstance(props) {

  return ( 
    <>
    {props.poolData != null && (props.poolData && props.poolData.map((value, index) => (
      <>
        {props.index === index &&
          <>
    <tr>
    <td colSpan={12}> 
    <h3 className="card-title align-items-start flex-column mb-5">
<span className="card-label text-gray-800 fs-4">
Instance Size
</span>
<br/>
<span className="text-gray-400 mt-1 fw-bold fs-6">
Select Max Size, Min Size, Desired Size
</span>
</h3>
    <div
className="row g-9"
data-kt-buttons="true"
data-kt-buttons-target="[data-kt-button]"
>
<div className="col-md-4 col-lg-4 col-xxl-4">
<div className="mb-10">
<label htmlFor="ClusterLabel" className="required form-label ps-3">
Min Size
</label>
<input
className="form-control  "
type="number"
// name="minsize"
name={"nodepools[" + index + "][minsize]"}
id={"min_size"+value.pool_id}
placeholder="Enter Min Size"
onChange={() => props.nodePoolUpdate(value.pool_id)}
defaultValue={value.minSize} 
/>
</div>
</div>
<div className="col-md-4 col-lg-4 col-xxl-4">
<div className="mb-10">
<label htmlFor="ClusterLabel" className="required form-label ps-3">
Max Size
</label>
<input
className="form-control  "
type="number"
// name="maxsize"
name={"nodepools[" + index + "][maxsize]"}
id={"max_size"+value.pool_id}
placeholder="Enter Max Size"
onChange={() => props.nodePoolUpdate(value.pool_id)}
defaultValue={value.maxSize} 
/>
</div>
</div>

<div className="col-md-4 col-lg-4 col-xxl-4">
<div className="mb-10">
<label htmlFor="ClusterLabel" className="required form-label ps-3">
Desired Size
</label>
<input
className="form-control  "
type="number"
// name="desiredsize"
id={"desired_size"+value.pool_id}
name={"nodepools[" + index + "][desiredsize]"}
placeholder="Enter Desired Size" 
onChange={() => props.nodePoolUpdate(value.pool_id)}
defaultValue={value.desiredSize} 
/>
</div>
</div>


</div>
    </td>
  </tr>
  </>}
  </>)))}
</>
  )
}
