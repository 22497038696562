import Permission from "../../rabbitmq/permission/Permission"
import TopicPermission from "../../rabbitmq/topicPermission/TopicPermission"
import UpdateUser from "../../rabbitmq/updateUser/UpdateUser"

const UsersPermissionDrawer = (props) => {

    return (
        <>
            <div id="rabbitmqDrawer_overlay" onClick={props.openPermissionDrawer} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>
            <div
                id="rabbitmqUser-modal"
                className="bg-body shadow drawer drawer-end"
                data-kt-drawer="true"
                data-kt-drawer-name="chat"
                data-kt-drawer-activate="true"
                data-kt-drawer-direction="end"
                data-kt-drawer-close="#close_ssh"
                data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
                style={{ maxWidth: '70%', important: true }}
            >
                <div
                    className="card rounded-0 w-100 border-0"
                    id="kt_drawer_chat_messenger"
                    style={{ height: 'max-content' }}
                >
                    {/*begin::Card header*/}
                    <div
                        className="card-header pe-5"
                        id="kt_drawer_chat_messenger_header"
                    >
                        {/*begin::Title*/}
                        <div className="card-title">
                            {/*begin::User*/}
                            <div className="d-flex justify-content-center flex-column me-3">
                                <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                                    Manage RabbitMq User
                                </a>
                            </div>
                            {/*end::User*/}
                        </div>
                        {/*end::Title*/}
                        {/*begin::Card toolbar*/}
                        <div className="card-toolbar">
                            {/*begin::Close*/}
                            <div
                                onClick={props.openPermissionDrawer}
                                className="btn btn-sm btn-icon btn-active-light-primary"
                                id="close_ssh"
                            >
                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                                <span className="svg-icon svg-icon-2">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <rect
                                            opacity="0.5"
                                            x={6}
                                            y="17.3137"
                                            width={16}
                                            height={2}
                                            rx={1}
                                            transform="rotate(-45 6 17.3137)"
                                            fill="black"
                                        />
                                        <rect
                                            x="7.41422"
                                            y={6}
                                            width={16}
                                            height={2}
                                            rx={1}
                                            transform="rotate(45 7.41422 6)"
                                            fill="black"
                                        />
                                    </svg>
                                </span>
                                {/*end::Svg Icon*/}
                            </div>
                            {/*end::Close*/}
                        </div>
                        {/*end::Card toolbar*/}
                    </div>
                    <div className="modal-body">
                        <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-trakt_charts_widget_1_chartnsparent fs-5 fw-bolder">
                            <li className="nav-item">
                                <a
                                    className="nav-link text-active-primary py-5 me-6"
                                    data-bs-toggle="tab"
                                    href="#configuration"
                                >
                                    Configuration
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link text-active-primary py-5 me-6"
                                    data-bs-toggle="tab"
                                    href="#permission"
                                >
                                    Permission
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link text-active-primary py-5 me-6"
                                    data-bs-toggle="tab"
                                    href="#topic_permission"
                                >
                                    Topic Permission
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link text-active-primary py-5 me-6"
                                    data-bs-toggle="tab"
                                    href="#update_user"
                                >
                                    Update User
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link text-active-primary py-5 me-6"
                                    data-bs-toggle="tab"
                                    href="#destroy_user_permission"
                                // onClick={handleShowPage}
                                >
                                    Destroy
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade" id="permission" role="tabpanel">
                                <Permission name='Virtual Host' />
                            </div>
                            <div className="tab-pane fade" id="topic_permission" role="tabpanel">
                                <TopicPermission name='Virtual Host' />
                            </div>
                            <div className="tab-pane fade" id="update_user" role="tabpanel">
                                <UpdateUser name='Virtual Host' />
                            </div>
                            <div className="tab-pane fade" id="destroy_user_permission" role="tabpanel">
                                <div className="card mt-5">
                                    <div
                                        className="card-header border-0 cursor-pointer"
                                        role="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#kt_account_deactivate"
                                        aria-expanded="true"
                                        aria-controls="kt_account_deactivate"
                                    >
                                        <div className="card-title m-0">
                                            <h3 className="fw-bold m-0">Destroy Permission</h3>
                                        </div>
                                    </div>
                                    <div id="kt_account_settings_deactivate" className="collapse show">
                                        <form id="destroyform" className="form">
                                            <div className="card-body border-top p-9">
                                                <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
                                                    <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={24}
                                                            height={24}
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                        >
                                                            <rect
                                                                opacity="0.3"
                                                                x={2}
                                                                y={2}
                                                                width={20}
                                                                height={20}
                                                                rx={10}
                                                                fill="black"
                                                            />
                                                            <rect
                                                                x={11}
                                                                y={14}
                                                                width={7}
                                                                height={2}
                                                                rx={1}
                                                                transform="rotate(-90 11 14)"
                                                                fill="black"
                                                            />
                                                            <rect
                                                                x={11}
                                                                y={17}
                                                                width={2}
                                                                height={2}
                                                                rx={1}
                                                                transform="rotate(-90 11 17)"
                                                                fill="black"
                                                            />
                                                        </svg>
                                                    </span>
                                                    <div className="d-flex flex-stack flex-grow-1">
                                                        <div className="fw-bold">
                                                            <h4 className="text-gray-900 fw-bold">
                                                                This will destroy your Cluster.
                                                            </h4>
                                                            <div className="fs-6 text-gray-700">
                                                                Note: This option will delete your Cluster from the server for
                                                                permanently and this not be able to undo.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="card-footer d-flex justify-content-end py-6 px-9"
                                                id="destroy_btn"
                                            >
                                                {/* {currentDatabaseInfo?.ref_type=="dbaas_master"?(<> */}
                                                <a
                                                    id="destroy-btn"
                                                    // onClick={(e) => deleteDatabase(databaseInfo.cluster_name)}
                                                    className="btn btn-danger fw-bold"
                                                >

                                                    Destroy Permission
                                                </a>
                                                {/* </>):('')} */}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="card mb-6 mb-xl-9 ">
                    <div className="card-body pt-9 pb-0 ">
                        <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-trakt_charts_widget_1_chartnsparent fs-5 fw-bolder">
                            <li className="nav-item">
                                <a
                                    className="nav-link text-active-primary py-5 me-6"
                                    data-bs-toggle="tab"
                                    href="#configuration"
                                >
                                    Configuration
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link text-active-primary py-5 me-6"
                                    data-bs-toggle="tab"
                                    href="#rabbit_user"
                                >
                                    Users
                                </a>
                            </li>
                            <li className="nav-item d-none">
                                <a
                                    className="nav-link text-active-primary py-5 me-6"
                                    data-bs-toggle="tab"
                                    href="#virtual_host"
                                >
                                    Virtual Host
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link text-active-primary py-5 me-6"
                                    data-bs-toggle="tab"
                                    href="#destroy"
                                // onClick={handleShowPage}
                                >
                                    Destroy
                                </a>
                            </li>
                        </ul>
                    </div>
                </div> */}
            </div>
        </>
    )
}

export default UsersPermissionDrawer