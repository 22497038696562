import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import store from "./App/Redux/store/store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Unauthorized from "./public-components/Unauthorized";
 
const root = ReactDOM.createRoot(document.getElementById("root")); 
root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <Provider store={store}>
      <Router>
        <Routes>
           <Route path="/unauthorized" element={<Unauthorized />} />
           <Route path="*" element={<App />} />
        </Routes>
      </Router>
    </Provider>
  </GoogleOAuthProvider>
);
