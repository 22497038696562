import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UsePost from "../../../../CustomHooks/usePost";
import swal from "sweetalert";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../Loaders/TableLoader";
import UseCallApi from "../../../../CustomHooks/useCallApi";
function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue(value => value + 1);
}
function useForceUpdate2() {
  const [value, setValue] = useState(0);
  return () => setValue(value => value + 1);
}

export default function ManageAccount() {
  const [pageStatus, setPageStatus] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  var permission_arr = {
    'compute': 'Compute',
    'kubernetes': 'Kubernetes',
    'autoscaling': 'Auto Scaling',
    'stacks': 'Stacks',
    'ssl': 'SSL',
    'database': 'Database',
    'objectstorage': 'Object Storage',
    'monitoring': 'Monitoring',
    'loadbalancer': 'Load balancer',
    'vpn': 'VPN',
    'vpc': 'VPC',
    'firewall': 'Firewall',
    'dns': 'DNS',
    'snapshot': 'Snapshot',
    'backup': 'Backup',
    'iso': 'ISO',
    'billing': 'Billing',
    'sshkey': 'SSH Key',
    'api': 'API',
    'activity': 'Activity',
    'ticket': 'Ticket',
    'billingaddfund': 'Billing Add Fund',
    'user': 'User',
    'image': 'Image',
    'kyc': 'KYC',
    "sqs": 'SQS',
    'container_registry': 'Container Registry',
    'target_group': 'Target Group',
    'account': "Account",
    'transfer': 'Resource Transfer',
    'active_session': 'Active Session'
  }

  permission_arr = Object.entries(permission_arr);

  const navigate = useNavigate();
  const forceUpdate = useForceUpdate();
  const forceUpdate2 = useForceUpdate2();
  var query = window.location.href;
  var vars = query.split("/");
  var id = vars[4];
  const [subData, setSubData] = useState(null);
  const [permissionsData, setPermissionsData] = useState(null);
  const [copyPermissionsData, setCopyPermissionsData] = useState(null);
  const [diffPermissions, setDiffPermissions] = useState(null);
  const dataFetchedRef = useRef(false);

  const navRedirect = () => {
    setTimeout(() => {
      navigate("/accountManagement/" + id);
    }, 3000);

  }
  const getSubAccount = async () => {

    // const dt=toast.loading("Sub Account loading..."); 
    const data = await UseApi('get', 'user/' + id, '');
    //console.log(data);
    if (Object.keys(data.info).length <= 2) {
      swal({
        title: "Account Manager",
        text: "No account manager found with id: " + id + "!",
        icon: "warning",
        button: "OK",
      }).then(() => {
        navigate("/accountManagement");
      })
      return;
    } else {
      setSubData(data.info);
      //   toast.update(dt,{render: "Your Subuser info listed below", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      // }); 
      const perm = data.info.permissions;
      var p_arr = perm.split(',');
      setPermissionsData(p_arr);
      setCopyPermissionsData(p_arr)
    }
  }
  console.log(subData, permissionsData);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getSubAccount();
  }, []);

  const handlePermissionChange = (e, value) => {
    const { id, checked } = e.target;

    // Clone the current permissionsData to avoid direct mutation
    let updatedPermissionsData = [...permissionsData];

    const readCheck = document.getElementById(`${value[0]}_read`);
    const writeCheck = document.getElementById(`${value[0]}_write`);
    const deleteCheck = document.getElementById(`${value[0]}_delete`);
    const eachPermission = document.querySelector(`#${value[0]}_set`);
    const p_info = document.querySelector('#p_info')

    // Handle checkbox changes based on which one was clicked
    if (id === `${value[0]}_read`) {
      if (checked) {
        // Add read permission
        if (!updatedPermissionsData.includes(`${value[0]}_read`)) {
          updatedPermissionsData.push(`${value[0]}_read`);
        }
      } else {
        // Remove read permission and uncheck write/delete
        updatedPermissionsData = updatedPermissionsData.filter((perm) => perm !== `${value[0]}_read`);
        writeCheck.checked = false;
        deleteCheck.checked = false;
        updatedPermissionsData = updatedPermissionsData.filter(
          (perm) => perm !== `${value[0]}_write` && perm !== `${value[0]}_delete`
        );
      }
    } else if (id === `${value[0]}_write`) {
      if (checked) {
        // Check read if write is checked
        readCheck.checked = true;
        if (!updatedPermissionsData.includes(`${value[0]}_read`)) {
          updatedPermissionsData.push(`${value[0]}_read`);
        }
        updatedPermissionsData.push(`${value[0]}_write`);
      } else {
        // Uncheck write
        updatedPermissionsData = updatedPermissionsData.filter((perm) => perm !== `${value[0]}_write`);
      }
    } else if (id === `${value[0]}_delete`) {
      if (checked) {
        // Check read and write if delete is checked
        readCheck.checked = true;
        writeCheck.checked = true;
        if (!updatedPermissionsData.includes(`${value[0]}_read`)) {
          updatedPermissionsData.push(`${value[0]}_read`);
        }
        if (!updatedPermissionsData.includes(`${value[0]}_write`)) {
          updatedPermissionsData.push(`${value[0]}_write`);
        }
        updatedPermissionsData.push(`${value[0]}_delete`);
      } else {
        // Uncheck delete
        updatedPermissionsData = updatedPermissionsData.filter((perm) => perm !== `${value[0]}_delete`);
      }
    }

    // Update the `eachPermission` hidden input based on selected checkboxes
    if (readCheck.checked && writeCheck.checked && deleteCheck.checked) {
      eachPermission.value = `${value[0]}_read,${value[0]}_write,${value[0]}_delete`;
    } else if (readCheck.checked && writeCheck.checked) {
      eachPermission.value = `${value[0]}_read,${value[0]}_write`;
    } else if (readCheck.checked) {
      eachPermission.value = `${value[0]}_read`;
    } else {
      eachPermission.value = "blank";
    }

    // Update the state with the modified permissions array
    let permissions_data = updatedPermissionsData.filter(item => item !== "");
    let copy_ermissions = copyPermissionsData.filter(item => item !== "");
    console.log(`'${permissions_data.join(", ")}'`);

    // Find differences (items that are in one array but not the other)
    let diffPermissions = [
      ...permissions_data.filter(item => !copy_ermissions.includes(item)),
      ...copy_ermissions.filter(item => !permissions_data.includes(item))
    ];

    console.log(`${diffPermissions.join(", ")},`);
    p_info.value = `${diffPermissions.join(", ")},`
    setPermissionsData(permissions_data);
    setDiffPermissions(`${diffPermissions.join(", ")}`)
  };

  console.log(permissionsData, diffPermissions);


  // const permissionInput = (id) => {
  //   // console.log(id);
  //   // let checkPresent = permissionsData.indexOf(id);
  //   // if (checkPresent > -1) {
  //   //   permissionsData.splice(checkPresent, 1)
  //   //   setPermissionsData((old) => {
  //   //     return [...old]
  //   //   })
  //   // } else {
  //   //   setPermissionsData((old) => {
  //   //     return [...old, id]
  //   //   })
  //   // }
  //   const p_footer = document.querySelector('#perm_footer');
  //   if (p_footer) {
  //     p_footer.classList.remove('d-none');
  //   }
  //   if (permission_arr) {
  //     permission_arr.map((value, index) => {
  //       const readCheck = document.querySelector('#' + value[0] + '_read');
  //       const writeCheck = document.querySelector('#' + value[0] + '_write');
  //       const deleteCheck = document.querySelector('#' + value[0] + '_delete');
  //       const eachPermission = document.querySelector('#' + value[0] + '_set');
  //       if (readCheck && writeCheck && deleteCheck && eachPermission) {
  //         if (deleteCheck.checked == true) {
  //           eachPermission.value = '' + value[0] + '_delete' + ',' + value[0] + '_read' + ',' + value[0] + '_write';
  //           readCheck.checked = true;
  //           writeCheck.checked = true;
  //         } else if (readCheck.checked === true && writeCheck.checked === true) {
  //           eachPermission.value = '' + value[0] + '_read' + ',' + value[0] + '_write';
  //           deleteCheck.checked = false;
  //         }
  //         else if (readCheck.checked === false && writeCheck.checked === true) {
  //           eachPermission.value = '' + value[0] + '_read' + ',' + value[0] + '_write';
  //           deleteCheck.checked = false;
  //           readCheck.checked = true;
  //         } else if (readCheck.checked === true && writeCheck.checked === true) {
  //           eachPermission.value = '' + value[0] + '_read' + ',' + value[0] + '_write';
  //           deleteCheck.checked = false;
  //         } else if (readCheck.checked === true && writeCheck.checked === false && deleteCheck.checked === false) {
  //           eachPermission.value = '' + value[0] + '_read';
  //         } else if (readCheck.checked === false && writeCheck.checked === false && deleteCheck.checked === false) {
  //           eachPermission.value = "blank";
  //         }

  //       }
  //     })
  //   }
  // }

  const checkPerm = () => {
    if (permissionsData != null) {
      permissionsData.map((value, index) => {
        if (permission_arr) {
          permission_arr.map((value2, index2) => {
            if (value == value2[0] + '_read') {
              //console.log(value+" == "+value2[0]+'_read');
              var readCheck2 = document.querySelector('#' + value2[0] + '_read');
              readCheck2.checked = true;
            }
            if (value == value2[0] + '_write') {
              //console.log(value+" == "+value2[0]+'_write');
              var writeCheck2 = document.querySelector('#' + value2[0] + '_write');
              writeCheck2.checked = true;
            }
            if (value == value2[0] + '_delete') {
              //console.log(value+" == "+value2[0]+'_delete');
              var deleteCheck2 = document.querySelector('#' + value2[0] + '_delete');
              deleteCheck2.checked = true;
            }

          })
        }
      })

    }
  }

  const checkedOrNotinUpdate = (id) => {
    if (permissionsData) {
      if (permissionsData.find(Element => Element === id)) {
        return true
      } else {
        return false
      }
    } else {
      return
    }

  }

  const addPermission = () => {
    toast.dismiss();
    const p_body = document.querySelector('#p_body');
    var v_btn = document.querySelector('#view-btn');
    var close_per = document.querySelector('#close_btn')
    swal({
      title: "Are you sure?",
      text: "Once permissions set for the user, your subuser can access your account with the given permissions!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          setPageStatus(true)
          const dt = toast.loading("Permissions updating...");
          const dnsp = await UsePost('PUT', 'user/' + id, 'permission_form');
          if (dnsp.status == 'error') {
            setPageStatus(false)
            toast.update(dt, {
              render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
          } else {
            setPageStatus(false)
            toast.update(dt, {
              render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
            p_body.classList.add('d-none')
            v_btn.classList.remove('d-none')
            close_per.classList.add('d-none')
            const subForm = document.querySelector('#permission_form');
            if (subForm) {
              subForm.reset();
            }
            getSubAccount();
            navRedirect();
          }
        }
        // else { 
        //   const subForm=document.querySelector('#permission_form');
        //   subForm.reset();
        //   swal({
        //       title: "Subuser",
        //       text: "Subuser permissions set successfully!",
        //       icon: "Warning",
        //       button: "OK",
        //     });
        // }
      });
  }
  const deleteUser = () => {
    toast.dismiss();
    swal({
      title: "Are you sure?",
      text: "Once user disabled, your subuser can not access your account!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          setPageStatus(true)
          const dt = toast.loading("User disabling...");
          const dnsp = await UsePost('DELETE', 'user/' + id, 'delete_subuser');
          if (dnsp.status == 'error') {
            setPageStatus(false)
            toast.update(dt, {
              render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
          } else {
            setPageStatus(false)
            toast.update(dt, {
              render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
            navigate('/accountManagement');

          }
        }
      });
  }
  const showPermissions = () => {
    var close_per = document.querySelector('#close_btn')
    const p_body = document.querySelector('#p_body');
    const v_btn = document.querySelector('#view-btn');
    if (p_body && v_btn && close_per) {
      p_body.classList.remove('d-none');
      close_per.classList.remove('d-none')
      checkPerm();
      v_btn.classList.add('d-none');
    }
  }

  const closePermissionButton = () => {
    var close_per = document.querySelector('#close_btn')
    const p_body = document.querySelector('#p_body');
    const v_btn = document.querySelector('#view-btn');
    if (p_body && v_btn && close_per) {
      p_body.classList.add('d-none');
      close_per.classList.add('d-none')
      checkPerm();
      v_btn.classList.remove('d-none');
    }
  }

  return (
    <>
      {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        /> */}
      {subData != null ? (<>
        <div className="card mb-6 mb-xl-9 ">

          <div className="card-body pt-9 pb-0 ">
            {/*begin::Details*/}
            <div className="d-flex flex-wrap flex-sm-nowrap mb-6">
              {/*begin::Image*/}
              <div className="d-flex flex-center flex-shrink-0 bg-light rounded w-55px h-55px w-lg-55px h-lg-55px me-7 mb-4">
                {/*begin::Svg Icon | path: assets/media/icons/duotune/communication/com014.svg*/}
                <span className="svg-icon svg-icon-primary svg-icon-2hx">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M16.0173 9H15.3945C14.2833 9 13.263 9.61425 12.7431 10.5963L12.154 11.7091C12.0645 11.8781 12.1072 12.0868 12.2559 12.2071L12.6402 12.5183C13.2631 13.0225 13.7556 13.6691 14.0764 14.4035L14.2321 14.7601C14.2957 14.9058 14.4396 15 14.5987 15H18.6747C19.7297 15 20.4057 13.8774 19.912 12.945L18.6686 10.5963C18.1487 9.61425 17.1285 9 16.0173 9Z"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x={14}
                      y={4}
                      width={4}
                      height={4}
                      rx={2}
                      fill="black"
                    />
                    <path
                      d="M4.65486 14.8559C5.40389 13.1224 7.11161 12 9 12C10.8884 12 12.5961 13.1224 13.3451 14.8559L14.793 18.2067C15.3636 19.5271 14.3955 21 12.9571 21H5.04292C3.60453 21 2.63644 19.5271 3.20698 18.2067L4.65486 14.8559Z"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x={6}
                      y={5}
                      width={6}
                      height={6}
                      rx={3}
                      fill="black"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
              </div>
              {/*end::Image*/}
              {/*begin::Wrapper*/}
              <div className="flex-grow-1">
                {/*begin::Head*/}
                <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                  {/*begin::Details*/}
                  <div className="d-flex flex-column">
                    {/*begin::Status*/}
                    <div className="d-flex align-items-center mb-1">
                      <a
                        className="text-gray-800 text-hover-primary fs-2 fw-bolder me-3"
                        id="sub-name"
                      >
                        {subData.fullname}
                      </a>
                    </div>
                    {/*end::Status*/}
                    {/*begin::Description*/}
                    <div
                      className="d-flex flex-wrap fw-bold mb-4 fs-5 text-gray-400"
                      id="sub-contact"
                    >
                      {subData.email}
                    </div>
                    {/*end::Description*/}
                  </div>
                  {/*end::Details*/}
                  {/* <span id="action_process_cloud_82300"></span> */}
                  {/*begin::Actions*/}
                  <div className="d-flex mb-4 me-15">
                    {/*begin::Input*/}
                    <div className="zoom">
                      <button
                        id="subuser-update"
                        className="btn btn-primary btn-sm d-none"
                      >
                        Edit Subuser
                      </button>
                    </div>
                    <div className=" zoom me-5 btn btn-sm btn-light-primary sphide">
                      Subuser ID:{" "}
                      <span id="sub-id" className="fw-bold">
                        {subData.id}
                      </span>
                    </div>
                    {/*end::Input*/}
                    <form id="delete_subuser"></form>
                    <div className="zoom me-5" id="sub-status">
                      {subData.status == 'Active' ? (<span className="btn btn-sm btn-light-success">Active</span>) : (<span className="btn btn-sm btn-light-warning">Pending</span>)}

                    </div>
                    <a
                      onClick={deleteUser}
                      className="btn btn-sm btn-danger me-3"
                    >
                      Delete
                    </a>
                  </div>
                  {/*end::Actions*/}
                </div>
                {/*end::Head*/}
              </div>
              {/*end::Wrapper*/}
            </div>
            <div className="separator" />
            {/*begin::Nav*/}
            <ul className="d-none nav nav-stretch nav-line-tabs nav-line-tabs-2x border-trakt_charts_widget_1_chartnsparent fs-5 fw-bolder">
              {/*begin::Nav item*/}
              <li className="nav-item">
                <a
                  className="nav-link text-active-primary py-5 me-6 active"
                  data-bs-toggle="tab"
                  href="#special-permission"
                >
                  Special permission
                </a>
              </li>
              {/*end::Nav item*/}
            </ul>
            {/*end::Nav*/}
          </div>
        </div>
        <div id="myTabContent" className="tab-content">
          <div
            className="tab-pane fade show active"
            id="special-permission"
            role="tabpanel"
          >
            {/*begin::Post*/}
            <div className="post d-flex flex-column-fluid" id="kt_post">
              {/*begin::Container*/}
              <div className="card card-flush mb-6 mb-xl-9 w-100">
                {/*begin::Card header*/}
                <div className="card-header mt-6">
                  {/*begin::Card title*/}
                  <div className="card-title flex-column">
                    <h2 className="mb-1">Special Permissions</h2>
                    <div className="fs-6 fw-User'sbold text-muted">
                      Select permission here
                    </div>
                  </div>
                  <div className="card-toolbar">
                    <a
                      id="view-btn"
                      className="btn btn-sm btn-primary btn-active-light-primary"
                      onClick={showPermissions}
                    >
                      <span className="svg-icon svg-icon-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <rect
                            opacity="0.5"
                            x="11.364"
                            y="20.364"
                            width={16}
                            height={2}
                            rx={1}
                            transform="rotate(-90 11.364 20.364)"
                            fill="black"
                          />
                          <rect
                            x="4.36396"
                            y="11.364"
                            width={16}
                            height={2}
                            rx={1}
                            fill="black"
                          />
                        </svg>
                      </span>
                      View Permissions
                    </a>
                    <div
                      className="btn btn-sm btn-icon btn-active-light-primary d-none"
                      id="close_btn"
                      onClick={() => closePermissionButton()}
                    >
                      {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                      <span className="svg-icon svg-icon-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <rect
                            opacity="0.5"
                            x={6}
                            y="17.3137"
                            width={16}
                            height={2}
                            rx={1}
                            transform="rotate(-45 6 17.3137)"
                            fill="black"
                          />
                          <rect
                            x="7.41422"
                            y={6}
                            width={16}
                            height={2}
                            rx={1}
                            transform="rotate(45 7.41422 6)"
                            fill="black"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}
                    </div>
                    {/*end::Close*/}
                  </div>

                  {/*end::Card title*/}
                </div>
                <div className="separator"></div>
                {/*end::Card header*/}
                {/*begin::Card body*/}
                <div className="card-body d-flex flex-column d-none" id="p_body">
                  {/*begin::Permissions*/}
                  <div className="fv-row">
                    {/*begin::Label*/}
                    {/*end::Label*/}
                    {/*begin::Table wrapper*/}
                    <div className="table-responsive">
                      {/*begin::Table*/}
                      <form
                        id="permission_form"
                        name="permission_form"
                        className="form"
                      >
                        <input
                          type="hidden"
                          name="permissions"
                          id="p_info"
                          // value={permissionsData}
                          // defaultValue=""
                        />
                      </form>
                      <table className="table align-middle table-row-dashed fs-6 gy-5">
                        {/*begin::Table body*/}
                        <tbody className="text-gray-600 fw-bold" id="permission-list">
                          {permission_arr.map((value, index) => (
                            <>
                              {/*begin::Table row*/}
                              <tr>
                                {/*begin::Label*/}
                                <td className="text-gray-800">{value[1]}</td>
                                <input type="hidden" class="form-control" id={"" + value[0] + "_set"} />
                                {/*end::Label*/}
                                {/*begin::Options*/}
                                <td>
                                  {/*begin::Wrapper*/}
                                  <div className="d-flex">
                                    {/*begin::Checkbox*/}
                                    <label className="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        onChange={(e) => handlePermissionChange(e, value)}
                                        // onChange={() => permissionInput(value[0] + "_read")}
                                        defaultValue={"" + value[0] + "_read"}
                                        checked={permissionsData.includes(value[0] + "_read")}
                                        id={"" + value[0] + "_read"}
                                      />
                                      <span className="form-check-label">Read</span>
                                    </label>
                                    {/*end::Checkbox*/}
                                    {/*begin::Checkbox*/}
                                    <label className="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        // onChange={permissionInput}
                                        onChange={(e) => handlePermissionChange(e, value)}
                                        defaultValue={"" + value[0] + "_write"}
                                        checked={permissionsData.includes(value[0] + "_write")}
                                        // checked={permissionsData[index]}
                                        id={"" + value[0] + "_write"}
                                      />
                                      <span className="form-check-label">Write</span>
                                    </label>
                                    {/*end::Checkbox*/}
                                    {/*begin::Checkbox*/}
                                    <label className="form-check form-check-sm form-check-custom form-check-solid ">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        // onChange={permissionInput}
                                        onChange={(e) => handlePermissionChange(e, value)}
                                        defaultValue={"" + value[0] + "_delete"}
                                        checked={permissionsData.includes(value[0] + "_delete")}
                                        // checked={checkedOrNotinUpdate(value[0] + "_dele/te")}
                                        id={"" + value[0] + "_delete"}
                                      />
                                      <span className="form-check-label">Delete</span>
                                    </label>
                                    {/*end::Checkbox*/}
                                  </div>
                                  {/*end::Wrapper*/}
                                </td>
                                {/*end::Options*/}
                              </tr>
                              {/*end::Table row*/}
                            </>
                          ))}
                        </tbody>
                        {/*end::Table body*/}
                      </table>
                      {/*end::Table*/}
                      {/*begin::Action buttons*/}
                      <div className="d-flex justify-content-end align-items-center mt-12" id="perm_footer">
                        {/*begin::Button*/}
                        <button
                          className="btn btn-primary w-100"
                          onClick={addPermission}
                        >
                          <span className="indicator-label">Save Permissions</span>
                        </button>
                        {/*end::Button*/}
                      </div>
                      {/*begin::Action buttons*/}
                    </div>
                    {/*end::Table wrapper*/}
                  </div>
                  {/*end::Permissions*/}
                </div>
                {/*end::Card body*/}
              </div>
            </div>
            {pageStatus &&
              <TopBarHeaderLine />}
            {/*end::Container*/}
          </div>
        </div>
      </>) : (<><TopBarHeaderLine />
        <TableLoader /></>)}
    </>
  )
}