import React, { useEffect, useState } from "react";
import GrafanaViewer from "./GrafanaViwer";
import TableLoader from "../../../../Loaders/TableLoader";
import CustomDoc from "../../../../../../CustomHooks/CustomDoc";
import ProductsUrl from "../../../../Docs/Docsdata";


export default function Overview(props) {

  const [dataDuration, setDataDuration] = useState('4h');

  const toggleMenu = () => {
    var chartMenu = document.querySelector("#chart-menu");
    if (chartMenu) {
      chartMenu.classList.toggle("d-block");
    }
  };

  const changeCycle = (value, innerValue) => {
    // Remove this line: setDataDuration(null);
    var currentCycle = document.querySelector("#currentCycle");
    if (currentCycle) {
      currentCycle.innerHTML = innerValue;
    }
    setDataDuration(value);
    toggleMenu();
  };



  return (
    <>
      <div
        className="tab-pane fade active show"
        id="overview-tab"
        role="tabpanel"
      >
        <div className="post flex-column-fluid" id="kt_post">
          {/*begin::Container*/}
          <div className="card card-flush h-80px mb-10">
            <div className="card-header pt-5 pb-5">
              {/*begin::Title*/}
              <h3 className="align-items-start flex-column">
                <span className="card-label fw-bolder ">
                  Object Overview
                </span>
                <p className="text-gray-400 mt-3 fw-bold fs-6">
                  View Your Object Storage Graph
                </p>
              </h3>
              {/*end::Title*/}
              {/*begin::Toolbar*/}
              <div className="card-toolbar" style={{marginRight:'-8rem'}}>
                <ul className="nav" id="kt_chart_widget_8_tabs">
                  <li className="nav-item">
                    {/*begin::Trigger*/}
                    <p style={{ marginRight: "102px", marginLeft:'-2rem' }}>
                    <a  className="me-5" >
                    <CustomDoc url={ProductsUrl[0]?.object_stroage+"/manage-object-stroage/overview/"}/>
                    </a>
                      <button
                        type="button"
                        onClick={toggleMenu}
                        className="btn btn-primary btn-sm"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-start"
                      >
                        <span id="currentCycle">4 hours</span>
                        <span className="svg-icon svg-ico-5 rotate-180 ms-3 me-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                              fill="black"
                            />
                          </svg>

                        </span>
                      </button>
                    </p>
                    {/*end::Trigger*/}
                    {/*begin::Menu*/}
                    <div id="chart-menu"
                      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4"
                      data-kt-menu="true"
                    >
                      {/*begin::Menu item*/}
                      <div className="menu-item px-3">
                        <a onClick={(e) => changeCycle('1h', '1 hour')} className="menu-link px-3">
                          1 hours
                        </a>
                      </div>
                      {/*end::Menu item*/}
                      {/*begin::Menu item*/}
                      <div className="menu-item px-3">
                        <a onClick={(e) => changeCycle('4h', '4 hours')} className="menu-link px-3">
                          4 hours
                        </a>
                      </div>
                      {/*end::Menu item*/}
                      {/*begin::Menu item*/}
                      <div className="menu-item px-3">
                        <a onClick={(e) => changeCycle('12h', '12 hours')} className="menu-link px-3">
                          12 hours
                        </a>
                      </div>
                      {/*end::Menu item*/}
                      {/*begin::Menu item*/}
                      <div className="menu-item px-3">
                        <a onClick={(e) => changeCycle('24h', '24 hours')} className="menu-link px-3">
                          24 hours
                        </a>
                      </div>
                      {/*end::Menu item*/}
                      {/*begin::Menu item*/}
                      <div className="menu-item px-3">
                        <a onClick={(e) => changeCycle('1w', '7 days')} className="menu-link px-3">
                          7 days
                        </a>
                      </div>
                      {/*end::Menu item*/}
                      {/*begin::Menu item*/}
                      <div className="menu-item px-3">
                        <a onClick={(e) => changeCycle('1M', '30 days')} className="menu-link px-3">
                          30 days
                        </a>
                      </div>
                      {/*end::Menu item*/}
                      {/*begin::Menu item*/}
                      <div className="menu-item px-3">
                        <a onClick={(e) => changeCycle('2M', '60 days')} className="menu-link px-3">
                          60 days      </a>
                      </div>
                      {/*end::Menu item*/}
                    </div>
                    {/*end::Menu*/}
                  </li>
                </ul>
              </div>
              {/*end::Toolbar*/}
            </div>
          </div>

          <div id="" className="">
            {/*begin::Row*/}
            {dataDuration ? (<>
              <GrafanaViewer key={dataDuration} dataTime={dataDuration} />
            </>) : (
              <><TableLoader /></>
            )}
          </div>
          {/*end::Row*/}
        </div>
      </div>
    </>
  )
}


