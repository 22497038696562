import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UsePost from "../../../../../../../CustomHooks/usePost";
import UseApi from "../../../../../../../CustomHooks/useApi";

export default function UpdateRoutes(props) {
    const [routeData, setRouteData] = useState(null);
    const [subnetAvailable, setSubnetAvailable] = useState(null);

    var query = window.location.href;
    var vars = query.split("/");
    var id = vars[5];

    const getRoutes = async () => {
        const dt = toast;
        const datas = await UseApi("get", "vpc/routetable", "");

        if (datas.status == "error") {
            setRouteData([]);
        } else {
            setRouteData(datas.data);
        }
    };

    useEffect(() => {
        getRoutes();
    }, [])

    const updateSubnet = async () => {
        try {
            // Dismiss any previous toasts
            toast.dismiss();

            // Show a loading toast
            const dt = toast.loading("Please wait... System is processing your request.");

            // Find the submit button element
            const submitBtn = document.querySelector("#update-route");

            if (submitBtn) {
                // Save the current button content and disable it while processing
                const preContent = submitBtn.innerHTML;
                submitBtn.classList.add("disabled");
                submitBtn.innerHTML = `Please wait... <span class="indicator-label">
          <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>`;

                // Send a POST request
                const responseData = await UsePost("put", `vpc/route`, "update-subnet-form");

                // Check for errors in the response
                if (responseData.status === "error") {
                    toast.update(dt, {
                        render: responseData.message,
                        type: "error",
                        isLoading: false,
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                // Handle success
                else if (responseData.status === "success") {
                    toast.update(dt, {
                        render: responseData.message,
                        type: "success",
                        isLoading: false,
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    // Call the getGateways function if it exists in props
                    if (props?.getRoutes) {
                        props.getRoutes();
                    }
                    props.openDrawer()
                }

                // Re-enable the button and restore its content
                submitBtn.classList.remove("disabled");
                submitBtn.innerHTML = preContent;
            }
        } catch (error) {
            // Catch any errors and display an error toast
            toast.error("An unexpected error occurred. Please try again.");
            console.error("Error adding VPC route:", error);
        }
    };

    const handleChangeInput = (name, maxValue) => {
        props.setUpdatedObject((prev) => ({
            ...prev,
            [name]: maxValue
        }));
    };

    return (
        <>
            <div
                id="drawer_overlay_update_routes"
                onClick={() => props.openDrawer()}
                className="drawer-overlay d-none"
                style={{ zIndex: 109, width: "100%" }}
            ></div>
            <div
                id="create-routes-update-modal"
                className="bg-body shadow drawer drawer-end"
                data-kt-drawer="true"
                data-kt-drawer-name="chat"
                data-kt-drawer-activate="true"
                data-kt-drawer-direction="end"
                // data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
                style={{ important: true, zIndex: 110 }}
            >
                {/*begin::Messenger*/}
                <div
                    className="card w-100 rounded-0 border-0"
                    id="kt_drawer_chat_messenger"
                >
                    {/*begin::Card header*/}
                    <div
                        className="card-header pe-5"
                        id="kt_drawer_chat_messenger_header"
                    >
                        {/*begin::Title*/}
                        <div className="card-title">
                            {/*begin::User*/}
                            <div className="d-flex justify-content-center flex-column me-3">
                                <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                                    Update Route
                                </a>
                            </div>
                            {/*end::User*/}
                        </div>
                        {/*end::Title*/}
                        {/*begin::Card toolbar*/}
                        <div className="card-toolbar">
                            {/*begin::Close*/}
                            <div
                                onClick={() => props.openDrawer()}
                                className="btn btn-sm btn-icon btn-active-light-primary"
                                id="close_api"
                            >
                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                                <span className="svg-icon svg-icon-2">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <rect
                                            opacity="0.5"
                                            x={6}
                                            y="17.3137"
                                            width={16}
                                            height={2}
                                            rx={1}
                                            transform="rotate(-45 6 17.3137)"
                                            fill="black"
                                        />
                                        <rect
                                            x="7.41422"
                                            y={6}
                                            width={16}
                                            height={2}
                                            rx={1}
                                            transform="rotate(45 7.41422 6)"
                                            fill="black"
                                        />
                                    </svg>
                                </span>
                                {/*end::Svg Icon*/}
                            </div>
                            {/*end::Close*/}
                        </div>
                        {/*end::Card toolbar*/}
                    </div>
                    {/*end::Card header*/}
                    {/*begin::Card body*/}
                    <div className="" id="">
                        {/*begin::Form*/}
                        <form id="update-subnet-form" className="form">
                            {/*begin::Modal body*/}
                            <div className="modal-body ">
                                {/*begin::Scroll*/}
                                <div className=" me-n7 pe-7">
                                    <div className="pt-0 row">
                                        <div className="col-lg-12 d-none">
                                            {/*begin::Input group*/}
                                            <div className="mb-10 fv-row fv-plugins-icon-container">
                                                {/*begin::Label*/}
                                                <label className="required form-label">
                                                    Name
                                                </label>
                                                {/*end::Label*/}
                                                {/*begin::Input*/}
                                                <input
                                                    type="text"
                                                    name="name"
                                                    className="form-control mb-2"
                                                    placeholder="Enter the name"
                                                    defaultValue=""
                                                    id="internetGatewayName"
                                                />
                                                {/*end::Input*/}
                                                {/*begin::Description*/}
                                                <div className="text-muted fs-7">
                                                    A Gateway name is required and recommended to be
                                                    unique.
                                                </div>
                                                {/*end::Description*/}
                                                <div className="fv-plugins-message-container invalid-feedback" />
                                            </div>
                                            {/*end::Input group*/}
                                        </div>
                                        <div className="col-lg-6">
                                            {/*begin::Input group*/}
                                            <div className="mb-10 fv-row fv-plugins-icon-container">
                                                {/*begin::Label*/}
                                                <label className="required form-label">
                                                    Routes
                                                </label>
                                                {/*end::Label*/}
                                                {/*begin::Input*/}
                                                <select
                                                    type="text"
                                                    name="route_table_id"
                                                    className="form-select mb-2"
                                                    placeholder="Select subnet"
                                                    defaultValue=""
                                                    id="internetGatewaySubnet"
                                                    onChange={(e) => { handleChangeInput("route_table_id", e.target.value) }}
                                                    value={props?.updatedObject?.route_table_id}
                                                >
                                                    <option value="" disabled selected>Choose a Subnet</option>
                                                    {routeData != null && routeData.length > 0 &&
                                                        routeData.map((value, index) => {
                                                            return (
                                                                <option value={value.id}>{value.name}</option>
                                                            )
                                                        })}
                                                </select>
                                                {/*end::Input*/}
                                                <div className="fv-plugins-message-container invalid-feedback" />
                                            </div>
                                            {/*end::Input group*/}
                                        </div>
                                        <div className="col-lg-6">
                                            {/*begin::Input group*/}
                                            <div className="mb-10 fv-row fv-plugins-icon-container">
                                                {/*begin::Label*/}
                                                <label className="required form-label">
                                                    Destination CIDR Block
                                                </label>
                                                {/*end::Label*/}
                                                {/*begin::Input*/}
                                                <input
                                                    type="text"
                                                    name="destination_cidr_block"
                                                    className="form-control mb-2"
                                                    placeholder="Enter the destination CIDR block"
                                                    defaultValue=""
                                                    id="destination_cidr_block"
                                                    value={props?.updatedObject?.destination_cidr_block}
                                                    onChange={(e) => { handleChangeInput("destination_cidr_block", e.target.value) }}
                                                />
                                                {/*end::Input*/}
                                                {/*begin::Description*/}
                                                <div className="text-muted fs-7 d-none">
                                                    A Gateway name is required and recommended to be
                                                    unique.
                                                </div>
                                                {/*end::Description*/}
                                                <div className="fv-plugins-message-container invalid-feedback" />
                                            </div>
                                            {/*end::Input group*/}
                                        </div>
                                        <div className="col-lg-6">
                                            {/*begin::Input group*/}
                                            <div className="mb-10 fv-row fv-plugins-icon-container">
                                                {/*begin::Label*/}
                                                <label className="required form-label">
                                                    Target
                                                </label>
                                                {/*end::Label*/}
                                                {/*begin::Input*/}
                                                <input
                                                    type="text"
                                                    name="target"
                                                    className="form-control mb-2"
                                                    placeholder="Enter the target"
                                                    defaultValue=""
                                                    id="target"
                                                    value={props?.updatedObject?.target}
                                                    onChange={(e) => { handleChangeInput("target", e.target.value) }}
                                                />
                                                {/*end::Input*/}
                                                {/*begin::Description*/}
                                                <div className="text-muted fs-7 d-none">
                                                    A Gateway name is required and recommended to be
                                                    unique.
                                                </div>
                                                {/*end::Description*/}
                                                <div className="fv-plugins-message-container invalid-feedback" />
                                            </div>
                                            {/*end::Input group*/}
                                        </div>
                                        <div className="col-lg-6">
                                            {/*begin::Input group*/}
                                            <div className="mb-10 fv-row fv-plugins-icon-container">
                                                {/*begin::Label*/}
                                                <label className="required form-label">
                                                    Route Type
                                                </label>
                                                {/*end::Label*/}
                                                {/*begin::Input*/}
                                                <select
                                                    name="route_type"
                                                    className="form-select mb-2"
                                                    //   placeholder="Enter the name"
                                                    defaultValue=""
                                                    id="route_type"
                                                    value={props?.updatedObject?.route_type}
                                                    onChange={(e) => { handleChangeInput("route_type", e.target.value) }}
                                                >
                                                    <option value="" disabled selected>Choose Route Tpye</option>
                                                    <option value="local">Local</option>
                                                    <option value="static">Static</option>
                                                </select>
                                                {/*end::Input*/}
                                                {/*begin::Description*/}
                                                <div className="text-muted fs-7 d-none">
                                                    A Gateway name is required and recommended to be
                                                    unique.
                                                </div>
                                                {/*end::Description*/}
                                                <div className="fv-plugins-message-container invalid-feedback" />
                                            </div>
                                            {/*end::Input group*/}
                                        </div>
                                        <input
                                            type="hidden"
                                            name="route_id"
                                            className="form-control mb-2"
                                            value={props?.updatedObject?.id}
                                        />
                                        <div className="col-lg-12">
                                            <a
                                                className="btn btn-primary mt-5 w-100"
                                                onClick={updateSubnet}
                                                id="update-route"
                                            >
                                                <span className="svg-icon svg-icon-muted svg-icon-1hx">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={24}
                                                        height={24}
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                    >
                                                        <path
                                                            opacity="0.3"
                                                            d="M21 13H3C2.4 13 2 12.6 2 12C2 11.4 2.4 11 3 11H21C21.6 11 22 11.4 22 12C22 12.6 21.6 13 21 13Z"
                                                            fill="black"
                                                        />
                                                        <path
                                                            d="M12 22C11.4 22 11 21.6 11 21V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V21C13 21.6 12.6 22 12 22Z"
                                                            fill="black"
                                                        />
                                                    </svg>
                                                </span>
                                                Update Route
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                {/*end::Scroll*/}
                            </div>
                            {/*end::Modal body*/}
                        </form>
                        {/*end::Form*/}
                    </div>
                    {/*end::Card body*/}
                </div>

                {/*end::Messenger*/}
            </div>
        </>
    );
}
