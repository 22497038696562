import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import NotFound from "../../../../../public-components/NotFound";
import UseApi from "../../../../CustomHooks/useApi";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import UsePostData from "../../../../CustomHooks/usePostData";
import TableLoader from "../../Loaders/TableLoader";
import Contacts from "./tabs/manageContacts/Contacts";
import Cloud from "./tabs/manageClouds/Cloud";

export default function ManageMonitoringAlerts() {
  var query = window.location.href;
  var vars = query.split("/");
  var id = vars[4];
  const [comparisonArr, setComparsionArr] = useState(["below", "above", "equalto",]);

  const [alertData, setAlertData] = useState(null);
  const [access, setAccess] = useState(true);
  const [dataFetched, setDataFetched] = useState(false);
  const [perPage, setPerPage] = useState(5);
  const dataFetchedRef = useRef(false);

  const loaderFunc = () => {
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if (loader && no_record) {
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");

      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
  }
  const getAlert = async () => {
    const dt = toast;
    const data = await UseApi("get", "alert", "");
    if (data) {
      setDataFetched(true);
    }
    if ("status" in data) {
      if (data.status == "error") {
        toast.update(dt, {
          render: data.message,
          type: "warning",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setAccess(false);
        setAlertData([]);
        loaderFunc();
        return;
      }
    }
    if (Object.keys(data).length === 0) {
      setAlertData([]);
      toast.update(dt, {
        render: "no record found",
        type: "warning",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      loaderFunc();
    } else {
      var alertData = data.alerts;
      alertData.map((value, index) => {
        if (value.id == id) {
          setAlertData(value);
          toast.dismiss();
        }
      })

      //    toast.update(dt,{render: "Your Snapshots request has been successful", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      //  });
    }
  };
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getAlert();
  }, []);


  const updateAlertByID = async (aid, formName) => {
    var submitBtn = document.querySelector("#updatealert" + aid + "-btn-" + formName);
    var add_name = document.querySelector("#update_name_" + aid + "-" + formName);
    var add_type = document.querySelector("#update_type_" + aid + "-" + formName);
    var add_compare = document.querySelector("#update_compare_" + aid + "-" + formName);
    var add_value = document.querySelector("#update_value_" + aid + "-" + formName);
    var add_duration = document.querySelector("#update_duration_" + aid + "-" + formName);
    var add_cloud = document.querySelector("#update_cloud_" + aid + "-" + formName);
    var add_contact = document.querySelector("#update_contact_" + aid + "-" + formName);
    var add_status = document.querySelector("#update_status_" + aid + "-" + formName);
    var aname = "";
    var atype = "";
    var acompare = "";
    var avalue = "";
    var aduration = "";
    var acloud = "";
    var acontact = "";
    var astatus = "";
    if (add_name && add_type && add_compare && add_value && add_duration && add_cloud && add_contact && add_status) {



      if (add_name.value == "") {
        const dt = toast.loading("Please wait...");
        toast.update(dt, {
          render: "Name must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        return;
      } else {
        aname = add_name.value;
      }
      if (add_type.value == "") {
        const dt = toast.loading("Please wait...");
        toast.update(dt, {
          render: "Type must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        return;
      } else {
        atype = add_type.value;
      }
      if (add_compare.value == "") {
        const dt = toast.loading("Please wait...");
        toast.update(dt, {
          render: "Comparison Operator must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        return;
      } else {
        acompare = add_compare.value;
      }

      if (add_value.value == "") {
        const dt = toast.loading("Please wait...");
        toast.update(dt, {
          render: "Comparison value must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        return;
      } else {
        avalue = add_value.value;
      }

      if (add_duration.value == "") {
        const dt = toast.loading("Please wait...");
        toast.update(dt, {
          render: "Duration must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        return;
      } else {
        aduration = add_duration.value;
      }

      if (add_cloud.value == "") {
        const dt = toast.loading("Please wait...");
        toast.update(dt, {
          render: "Please attach Cloud!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        return;
      } else {
        acloud = add_cloud.value;
      }
      if (add_contact.value == "") {
        const dt = toast.loading("Please wait...");
        toast.update(dt, {
          render: "Please attach Contact!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        return;
      } else {
        acontact = add_contact.value;
      }

      if (add_status.value == "") {
        const dt = toast.loading("Please wait...");
        toast.update(dt, {
          render: "Please provide status", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        return;
      } else {
        astatus = add_status.value;
      }

      if (submitBtn) {
        var preContent = submitBtn.innerHTML;
        submitBtn.classList.add("disabled");
        submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
        const dt = toast.loading("Please wait...");
        const responseData = await UsePostData('post', 'alert/' + aid + '/update', { "name": aname, "ref_type": "cloud", "type": atype, "compare": acompare, "value": avalue, "for": aduration, "contacts": acontact, "status": astatus, "ref_ids": acloud });
        if (responseData) {
          submitBtn.classList.remove("disabled");
          submitBtn.innerHTML = preContent;
          if (responseData.status === 'error') {
            toast.update(dt, {
              render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
          } else if (responseData.status === 'success') {
            toast.update(dt, {
              render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });

            getAlert();

          }
        }
      }
    }
  }
  const deleteAlert = async (alertid) => {
    toast.dismiss();
    var submitBtn = document.querySelector("#deletealert" + alertid + "-btn");
    if (submitBtn) {

      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this alert rule!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {
            var preContent = submitBtn.innerHTML;
            submitBtn.classList.add("disabled");
            submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';

            const dt = toast.loading("Alert deleting...");
            const dnsp = await UseApi('DELETE', 'alert/' + alertid + '/delete', '');
            //////console.log(dnsp);
            if (dnsp) {
              submitBtn.classList.remove("disabled");
              submitBtn.innerHTML = preContent;
              if (dnsp.status == 'error') {
                toast.update(dt, {
                  render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
                });
              } else if (dnsp.status == 'success') {
                toast.update(dt, {
                  render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
                });
                getAlert();
              }
            }
          }
        });
    }
  }
  if (alertData != null) {
    if (alertData.length != 0) {
      // //console.log(databaseInfo);
      return (
        <>
          <div className="card mb-6 mb-xl-9 ">
            <div className="card-body pt-9 pb-0 ">
              <div className="d-flex flex-wrap flex-sm-nowrap mb-6">
                <div
                  className="d-flex flex-center flex-shrink-0 bg-light rounded w-55px h-55px w-lg-55px h-lg-55px me-7 mb-4"
                  id="imageDist"
                >
                  <span className="w-50">
                    {/*begin::Svg Icon | path: assets/media/icons/duotune/electronics/elc004.svg*/}
                    <span className="svg-icon svg-icon-muted svg-icon-2hx">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M2 16C2 16.6 2.4 17 3 17H21C21.6 17 22 16.6 22 16V15H2V16Z"
                          fill="black"
                        />
                        <path
                          opacity="0.3"
                          d="M21 3H3C2.4 3 2 3.4 2 4V15H22V4C22 3.4 21.6 3 21 3Z"
                          fill="black"
                        />
                        <path opacity="0.3" d="M15 17H9V20H15V17Z" fill="black" />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}

                  </span>

                </div>
                <div className="flex-grow-1">
                  <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                    <div className="d-flex flex-column">

                      <div className="d-flex align-items-center mb-1" id="showHost">
                        <a
                          className="text-gray-800 text-hover-primary fs-2 fw-bolder me-3"
                          id="hostname"
                        >
                          {alertData.name} &nbsp;<span className="badge badge-success">Beta</span>
                        </a>
                        <a>

                        </a>
                      </div>
                      <div className="d-flex flex-wrap fw-bold mb-4 fs-5 text-gray-400">
                        Metric Type - {alertData.type}

                      </div>
                    </div>
                    <div className="d-flex" id="console">
                      <div className="d-flex flex-stack mb-5">
                        {/*begin::Section*/}
                        <div className="d-flex align-items-center me-5" style={{ paddingRight: "28px", borderRight: "3px solid #b5b5c3" }}>
                          {/*begin::Symbol*/}
                          <div className="symbol symbol-50px me-8">
                            <div className="">

                            </div>
                          </div>
                          {/*end::Symbol*/}
                        </div>
                        {/*end::Section*/}
                        {/*begin::Label*/}
                        {/* {databaseInfo.status=="Active"?(<><div className="btn btn-success fw-bold ms-5">Active</div></>):
                    (<><div className="btn btn-warning fw-bold ms-5">Deploying.... </div></>)} */}
                        {/*end::Label*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="separator" />
              <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-trakt_charts_widget_1_chartnsparent fs-5 fw-bolder">
                <li className="nav-item">
                  <a
                    className="nav-link text-active-primary py-5 me-6 active sphide"
                    data-bs-toggle="tab"
                    href="#connection-details"
                  >
                    Overview
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link text-active-primary py-5 me-6 d sphide"
                    data-bs-toggle="tab"
                    href="#cloud"
                  >
                    Cloud Instances
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link text-active-primary py-5 me-6"
                    data-bs-toggle="tab"
                    href="#contacts"
                  >
                    Monitoring Contacts
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link text-active-primary py-5 me-6"
                    data-bs-toggle="tab"
                    href="#destroy"
                  >
                    Destroy
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade active show" id="connection-details" role="tabpanel">
              <div className="card pt-4 mb-6 mb-xl-9">
                {/*begin::Card header*/}
                <div className="card-header border-0">
                  {/*begin::Card title*/}
                  <div className="card-title">
                    <h2>Alert Details</h2>
                  </div>
                  {/*end::Card title*/}
                </div>
                {/*end::Card header*/}
                {/*begin::Card body*/}
                <div className="card-body pt-0 pb-5">
                  {/*begin::Table wrapper*/}
                  <div className="table-responsive">
                    {/*begin::Table*/}
                    <form id="info">
                      <table
                        className="table align-middle table-row-dashed gy-5"
                      >

                        {/*begin::Table body*/}
                        <tbody className="fs-6 fw-bold text-gray-600">

                          <tr>
                            <td>Alert Name</td>
                            <td>

                              <input
                                type="text"
                                name="name"
                                id={"update_name_" + alertData.id + "-info"}
                                defaultValue={alertData.name}
                                className="form-control w-50"
                              />
                              <input type='hidden' id={"update_type_" + alertData.id + "-info"} defaultValue={alertData.type} name="type" />
                              <input type='hidden' defaultValue={alertData.ref_type} name="ref_type" />
                              <input type='hidden' id={"update_cloud_" + alertData.id + "-info"} defaultValue={alertData.ref_ids} name="ref_ids" />
                              <input type='hidden' id={"update_contact_" + alertData.id + "-info"} defaultValue={alertData.contacts} name="contacts" />
                            </td>
                          </tr>
                          <tr>
                            <td>Comparison</td>
                            <td>
                              <select id={"update_compare_" + alertData.id + "-info"} className='form-select w-50' defaultValue={alertData.compare} name="compare" tabIndex={-1} aria-hidden="true">
                                {comparisonArr && comparisonArr.map((value2, index) => (<>
                                  {alertData.compare == value2 ? (<>
                                    <option value={value2} selected={true}>{value2}</option>
                                  </>) : (<>
                                    <option value={value2}>{value2}</option>
                                  </>)}

                                </>
                                ))}
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>Value</td>
                            <td>
                              <input
                                type="text"
                                id={"update_value_" + alertData.id + "-info"}
                                defaultValue={alertData.value}
                                name="value"
                                className="form-control w-50"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Duration</td>
                            <td>
                              <select id={"update_duration_" + alertData.id + "-info"} className='form-select w-50' defaultValue={alertData.for} name="for" tabIndex={-1} aria-hidden="true">
                                <option value="5m">5 minutes</option>
                                <option value="10m">10 minutes</option>
                                <option value="30m">30 minutes</option>
                                <option value="1h">1 hour</option>
                                <option value="3h">3 hours</option>
                                <option value="6h">6 hours</option>
                                <option value="12h">12 hours</option>
                                <option value="24h">24 hours</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>Status</td>
                            <td>
                              <select
                                id={"update_status_" + alertData.id + "-info"}
                                className="form-select w-50"
                                name="status"
                                defaultValue={alertData.status}
                              >
                                {alertData.status == "1" ? (<>
                                  <option value={1} selected={true}>Active</option>
                                  <option value={0}>Inactive </option>
                                </>) : (<>
                                  <option value={1}>Active</option>
                                  <option value={0} selected={true}>Inactive </option>
                                </>)}
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={12} className="text-end pe-20">
                              <a className="btn btn-primary" onClick={(e) => updateAlertByID(alertData.id, "info")} id={"updatealert" + alertData.id + "-btn-info"}> Update Alert</a>
                            </td>
                          </tr>
                        </tbody>

                        {/*end::Table body*/}
                      </table>
                    </form>
                    {/*end::Table*/}
                  </div>
                  {/*end::Table wrapper*/}
                </div>
                {/*end::Card body*/}
              </div>

            </div>
            <div className="tab-pane fade" id="cloud" role="tabpanel">
              <Cloud cloud_ids={alertData.ref_ids} />
            </div>
            <div className="tab-pane fade" id="contacts" role="tabpanel">
              <Contacts contact_ids={alertData.contacts} />
            </div>
            <div className="tab-pane fade" id="destroy" role="tabpanel">
              <div className="card">
                <div
                  className="card-header border-0 cursor-pointer"
                  role="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_account_deactivate"
                  aria-expanded="true"
                  aria-controls="kt_account_deactivate"
                >
                  <div className="card-title m-0">
                    <h3 className="fw-bold m-0">Delete Alert</h3>
                  </div>
                </div>
                <div id="kt_account_settings_deactivate" className="collapse show">
                  <form id="destroyform" className="form">
                    <div className="card-body border-top p-9">
                      <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
                        <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              opacity="0.3"
                              x={2}
                              y={2}
                              width={20}
                              height={20}
                              rx={10}
                              fill="black"
                            />
                            <rect
                              x={11}
                              y={14}
                              width={7}
                              height={2}
                              rx={1}
                              transform="rotate(-90 11 14)"
                              fill="black"
                            />
                            <rect
                              x={11}
                              y={17}
                              width={2}
                              height={2}
                              rx={1}
                              transform="rotate(-90 11 17)"
                              fill="black"
                            />
                          </svg>
                        </span>
                        <div className="d-flex flex-stack flex-grow-1">
                          <div className="fw-bold">
                            <h4 className="text-gray-900 fw-bold">
                              This will delete your alert rule.
                            </h4>
                            <div className="fs-6 text-gray-700">
                              Note: This option will delete your alert rule for
                              permanently and this not be able to undo.
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div
                      className="card-footer d-flex justify-content-end py-6 px-9"
                      id="destroy_btn"
                    >
                      <a
                        id="destroy-btn"
                        onClick={(e) => deleteAlert("deletealert" + alertData.id + "-btn")}
                        className="btn btn-danger fw-bold"
                      >

                        Delete Alert
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>




        </>
      );
    } else {
      return (<>
        <NotFound />
      </>)
    }
  } else {
    return (
      <>
        <TableLoader />
        <TopBarHeaderLine />
      </>
    )
  }
}

