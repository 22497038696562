export default function getUrlDetails() {
    const url = new URL(window.location.href);
  
    // Extract the hostname and split it to get the subdomain, domain, and TLD
    const hostnameParts = url.hostname.split('.');
    const hasSubdomain = hostnameParts.length > 2;
    const subdomain = hasSubdomain ? hostnameParts.slice(0, -2).join('.') : '';
    const domain = hasSubdomain ? hostnameParts.slice(-2).join('.') : hostnameParts.join('.');
  
    // Extract search parameters
    const params = {};
    url.searchParams.forEach((value, key) => {
      params[key] = value;
    });
  
    return {
      currentUrl: url.href,
      domain: domain,
      subdomain: subdomain,
      protocol: url.protocol,
      host: url.host,
      hostname: url.hostname,
      port: url.port,
      pathname: url.pathname,
      search: url.search,
      params: params,
      hash: url.hash,
      origin: url.origin,
    };
  }
  