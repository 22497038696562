import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import UsePostData from "../../../../../../CustomHooks/usePostData";
import TableLoader from "../../../../Loaders/TableLoader";
import UseApi from "../../../../../../CustomHooks/useApi";

export default function Firewall(props) {
    var query = window.location.href;
    var vars = query.split("/");
    var id = vars[4];
    const [firewallData, setFirewallData] = useState(props?.data.security_groups);
    const [dummyFirewallData, setDummyFirewallData] = useState(props?.data.security_groups);
    const [activePage, setActivePage] = useState(0);
    const [pageCount, setPageCount] = useState(1);
    const [perPage, setPerPage] = useState(5);
    const [itemOffset, setItemOffset] = useState(0);
    const [fwData, setFwData] = useState(null);
    const loaderFunc = () => {
        var loader = document.querySelector("#loader");
        var no_record = document.querySelector("#no_record");
        if (loader && no_record) {
            no_record.classList.add("d-none");
            loader.classList.remove("d-none");

            setTimeout(() => {
                loader.classList.add("d-none");
                no_record.classList.remove("d-none");
            }, 100);
        }
    }
    console.log(props);
    const getFirewall = async () => {
        setFirewallData(props?.data?.security_groups)
        setDummyFirewallData(props?.data?.security_groups)
        // getFw()
    }

    const getFw = async () => {
        const dt = toast
        const data = await UseApi('get', 'firewall', '');
        if (data) {
            if ('status' in data) {
                if (data.status == "error") {
                    toast.update(dt, {
                        render: data.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
                    });
                    setFirewallData([]);
                    setDummyFirewallData([])
                    return;
                }
            }
            if (Object.keys(data).length === 0) {
                setFirewallData(null);
                setDummyFirewallData(null)
                toast.update(dt, {
                    render: "no record found", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
                });
                // loaderFunc();
            } else {
                const fw_Data = data.firewalls.filter(obj1 => !firewallData?.some(obj2 => obj2.id === obj1.id));
                console.log(fw_Data);
                setFwData(fw_Data);
                toast.dismiss();
            }
        }
    }

    useEffect(() => {
        getFirewall();
    }, [props])

    useEffect(() => {
        getFw()
    }, [props])

    const openDrawer = () => {
        var drawerElement = document.querySelector("#fw-modal");
        var drawer_overlay = document.querySelector("#drawer_overlay_firewall");
        if (drawerElement && drawer_overlay) {
            drawerElement.classList.toggle("drawer-on");
            if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
                drawerElement.style.width = "100%";
            } else {
                drawerElement.style.width = "40%";
            }
            drawer_overlay.classList.toggle("d-none");
        }
    };
    function checkObjectForEmpty(obj) {
        for (const key in obj) {
            const value = obj[key];

            if (typeof value === 'string' && value.trim() === '') {
                return `Error: '${key}' should not be empty or blank.`;
            }

            if (typeof value === 'object') {
                const nestedError = checkObjectForEmpty(value);
                if (nestedError) {
                    return nestedError;
                }
            }
        }

        return null; // No empty or blank elements found
    }
    const attachFw = async () => {
        toast.dismiss();
        var add_firewall = document.querySelector("#add_fw_id");
        const submitBtn = document.querySelector("#add-fw-btn");
        const dt = toast.loading("Please wait...");

        if (add_firewall && submitBtn) {
            var updateData = { "security_groups": add_firewall.value };
            const validationError = checkObjectForEmpty(updateData);
            console.log(validationError);
            if (validationError) {
                setTimeout(() => {
                    toast.update(dt, {
                        render: validationError,
                        type: "error",
                        isLoading: false,
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                },1500)
                // return;
            } else {
                if (submitBtn) {
                    var preContent = submitBtn.innerHTML;
                    submitBtn.classList.add("disabled");
                    submitBtn.innerHTML = 'Please wait... <span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
                    const responseData = await UsePostData("post", "databases/" + id + "/securitygroup/" + add_firewall.value, {});

                    console.log(responseData);
                    if (responseData?.status === "error") {
                        toast.update(dt, {
                            render: responseData.message,
                            type: "error",
                            isLoading: false,
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (responseData?.status === "success") {
                        props.updateDatabase()
                        toast.update(dt, {
                            render: responseData.message,
                            type: "success",
                            isLoading: false,
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        openDrawer();
                        getFw()
                        getFirewall();
                    }
                    submitBtn.classList.remove("disabled");
                    submitBtn.innerHTML = preContent;
                }
            }
        }
    };

    const deleteFw = async (fwid, index) => {
        toast.dismiss();
        const dt = toast.loading("Please wait...");
        const submitBtn = document.querySelector("#delete-fw-btn-" + fwid);
        if (submitBtn) {
            var preContent = submitBtn.innerHTML;
            submitBtn.classList.add("disabled");
            submitBtn.innerHTML =
                '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';

            const responseData = await UseApi("delete", "databases/" + id + "/securitygroup/" + fwid, "");

            ////console.log(responseData);
            if (responseData.status === "error") {
                toast.update(dt, {
                    render: responseData.message,
                    type: "error",
                    isLoading: false,
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else if (responseData.status === "success") {
                toast.update(dt, {
                    render: responseData.message,
                    type: "success",
                    isLoading: false,
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setFirewallData((old) => {
                    old.splice(index, 1);
                    return [...old];
                })
                getFw()
                getFirewall();
            }
            submitBtn.classList.remove("disabled");
            submitBtn.innerHTML = preContent;
        }
    };

    function ProductInfo({ productdata }) {
        console.log(productdata);
        return (
            <>
                {productdata != null && Object.keys(productdata).length != 0 ? (productdata && productdata.map((value, index) => (
                    <tr>
                        <td className="p-3 text-start ps-10 text-gray-700">
                            {" "}
                            {/* <div className="d-flex justify-content-start flex-column"> */}
                            {" "}
                            <span className="fs-7 fw-bold text-gray-700">
                                {value.id}
                            </span>{" "}
                            {/* </div> */}
                        </td>
                        <td className="p-3 text-center text-gray-700">
                            {" "}
                            {/* <div className="d-flex justify-content-center flex-column"> */}
                            <a className="text-dark fw-bold text-hover-primary fs-6">
                                {value.name}
                            </a>
                            {/* </div>{" "} */}
                        </td>{" "}
                        {props.currentDatabaseInfo?.nodeType != "readonly" ?
                            <td className="p-3 text-end pe-10">
                                {/* {props.currentDatabaseInfo?.ref_type != "dbaas_node" && */}
                                <>
                                    {" "}
                                    {/* <div className="d-flex justify-content-center flex-shrink-0"> */}
                                    {" "}
                                    <button
                                        id={"delete-fw-btn-" + value.id}
                                        onClick={(e) => deleteFw(value.id, index)}
                                        className="btn btn-danger btn-active-light-danger btn-sm"
                                    >
                                        {" "}
                                        Detach
                                    </button>{" "}
                                    {/* </div>{" "} */}
                                </>
                                {/* } */}
                            </td>
                            :
                            ''
                        }
                    </tr>
                ))) : (<>
                    <tr>
                        <td colspan="12" id="no_record" valign="top">
                            <div className="mt-20 mb-20 text-center">
                                <span className="fs-6 text-gray-800 fw-bold">no record found</span>
                            </div>
                        </td>
                    </tr>
                </>)}

            </>
        )
    }

    const handleSearch = (text) => {
        const endOffset = itemOffset + perPage;
        // Remove special characters from the input text
        const escapedText = text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

        // Create a regular expression pattern to match the filtered text
        const regex = new RegExp(escapedText, "gi");

        // Filter the clusterData based on the regex pattern
        // console.log(firewallData);
        let data = firewallData.filter((row) => {
            // Check if any property in the row matches the regex pattern
            return (
                row.name && row.name.toString().toLowerCase().includes(text.toLowerCase())
            )
        });

        // Update the state with the filtered data
        setDummyFirewallData(data);;
        setPageCount(Math.ceil(data.length / perPage));
    }
    function PaginatedItems({ itemsPerPage, ProductData }) {
        const endOffset = itemOffset + itemsPerPage;
        setPageCount(Math.ceil(ProductData.length / itemsPerPage));
        const handlePageClick = (event) => {
            setActivePage((old) => old = event.selected);
            const newOffset = event.selected * itemsPerPage % ProductData.length;
            setItemOffset(newOffset);
        };

        return (
            <>
                <ProductInfo productdata={ProductData.slice(itemOffset, endOffset)} />
                <td colSpan="12" className="ps-3">
                    <ReactPaginate
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        initialPage={activePage}
                        previousLabel="< previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                    />
                </td>
            </>
        );
    }
    return (
        <>
            <div className="col-xl-12">
                {/*begin::Tables Widget 9*/}
                <div className="card card-xl-stretch mb-5 mb-xl-12">
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label  fw-bold fs-3 mb-1">
                                {" "}
                                Firewall Management{" "}
                                {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                                <span className="svg-icon svg-icon-2">
                                    {/* <i className="lab la-hotjar" style={{ fontSize: 20 }} /> */}
                                </span>
                                {/*end::Svg Icon*/}
                            </span>
                        </h3>
                        <div
                            className="card-toolbar"
                        >
                            <div className="position-relative my-1" style={{ marginRight: 20 }}>
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    data-kt-table-widget-4="search"
                                    id="searchClient"
                                    // value={searchTerm}
                                    onChange={e => handleSearch(e.target.value)}
                                    placeholder="Search"
                                />
                            </div>
                            <div style={{ marginRight: 20 }}>
                                <div className="dataTables_length">
                                    <label className="w-125px">
                                        <select
                                            onChange={e => setPerPage(e.target.value)}
                                            //data-control="select2"
                                            className="form-select form-select-sm form-select-solid"
                                            defaultValue={5}
                                            id="perPage"

                                        >
                                            <option value={5} selected="">
                                                5 Records
                                            </option>
                                            <option value={10}>10 Records</option>
                                            <option value={25}>25 Records</option>
                                            <option value={50}>50 Records</option>
                                            <option value={100}>100 Records</option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                            {props.currentDatabaseInfo?.ref_type != "dbaas_node" ?
                                <a
                                    onClick={openDrawer}
                                    className="btn btn-sm btn-active-light-primary btn-primary"
                                >
                                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
                                    <span className="svg-icon svg-icon-3">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <rect
                                                opacity="0.5"
                                                x="11.364"
                                                y="20.364"
                                                width={16}
                                                height={2}
                                                rx={1}
                                                transform="rotate(-90 11.364 20.364)"
                                                fill="black"
                                            />
                                            <rect
                                                x="4.36396"
                                                y="11.364"
                                                width={16}
                                                height={2}
                                                rx={1}
                                                fill="black"
                                            />
                                        </svg>
                                    </span>
                                    {/*end::Svg Icon*/}Add Firewall
                                </a>
                                : <></>}
                        </div>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    {/*begin::Table container*/}
                    {/* <div className="card-body"> */}
                    <div className="table-responsive">
                        {/*begin::Table*/}
                        <table
                            id="loadbalancer"
                            className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
                        >
                            {/*begin::Table head*/}
                            <thead className="bg-secondary">
                                <tr className="fw-bold ">
                                    <th className="p-3 text-start ps-9">#ID</th>
                                    <th className="p-3 text-center">Name</th>
                                    <th className="p-3 text-end pe-15">Action</th>
                                </tr>
                            </thead>
                            {/*end::Table head*/}
                            {/*begin::Table body*/}
                            <tbody>
                                {dummyFirewallData != null ? (<> {Object.keys(dummyFirewallData).length != 0 ? (<>
                                    <PaginatedItems itemsPerPage={perPage} ProductData={dummyFirewallData} />
                                </>) : (<>
                                    <tr>
                                        <td colSpan={3} className="text-center">
                                            <div className="mt-10 mb-10">
                                                <span className="fs-6 text-gray-800 fw-bold">no record found</span>
                                            </div>
                                        </td>
                                    </tr>
                                </>)}
                                </>) : (<>
                                    <tr>
                                        <td colSpan={3}>
                                            <TableLoader />
                                        </td>
                                    </tr>

                                </>)}

                            </tbody>
                            {/*end::Table body*/}
                        </table>
                        {/*end::Table*/}
                    </div>
                    {/* </div> */}
                    {/*end::Table container*/}
                    {/*begin::Body*/}
                </div>
                {/*end::Tables Widget 9*/}
            </div>

            <div id="drawer_overlay_firewall" onClick={openDrawer} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>

            <div
                id="fw-modal"
                className="bg-body shadow drawer drawer-end"
                // data-kt-drawer="true"
                // data-kt-drawer-name="chat"
                // data-kt-drawer-activate="true"
                // data-kt-drawer-direction="end"
                data-kt-drawer-close="#close_ssh"
                // data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
                style={{ important: true, zIndex: 110 }}
            >
                {/*begin::Messenger*/}
                <div
                    className="card rounded-0 w-100 border-0"
                    id="kt_drawer_chat_messenger"
                >
                    {/*begin::Card header*/}
                    <div
                        className="card-header pe-5"
                        id="kt_drawer_chat_messenger_header"
                    >
                        {/*begin::Title*/}
                        <div className="card-title">
                            {/*begin::User*/}
                            <div className="d-flex justify-content-center flex-column me-3">
                                <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                                    Add New Firewall
                                </a>
                            </div>
                            {/*end::User*/}
                        </div>
                        {/*end::Title*/}
                        {/*begin::Card toolbar*/}
                        <div className="card-toolbar">
                            {/*begin::Close*/}
                            <div
                                onClick={openDrawer}
                                className="btn btn-sm btn-icon btn-active-light-primary"
                                id="close_ssh"
                            >
                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                                <span className="svg-icon svg-icon-2">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <rect
                                            opacity="0.5"
                                            x={6}
                                            y="17.3137"
                                            width={16}
                                            height={2}
                                            rx={1}
                                            transform="rotate(-45 6 17.3137)"
                                            fill="black"
                                        />
                                        <rect
                                            x="7.41422"
                                            y={6}
                                            width={16}
                                            height={2}
                                            rx={1}
                                            transform="rotate(45 7.41422 6)"
                                            fill="black"
                                        />
                                    </svg>
                                </span>
                                {/*end::Svg Icon*/}
                            </div>
                            {/*end::Close*/}
                        </div>
                        {/*end::Card toolbar*/}
                    </div>
                    {/*end::Card header*/}
                    {/*begin::Card body*/}
                    <div className="" id="">
                        {/*begin::Form*/}
                        <form
                            name="importkey"
                            id="importkey"
                            className="form"
                        >
                            {/*begin::Modal body*/}
                            <div className="modal-body ">
                                {/*begin::Scroll*/}
                                <div
                                    className="scroll-y me-n7 pe-7"
                                    id="kt_modal_create_api_key_scroll"
                                    data-kt-scroll="true"
                                    data-kt-scroll-activate="{default: false, lg: true}"
                                    data-kt-scroll-max-height="auto"
                                    data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                                    data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                                    data-kt-scroll-offset="300px"
                                >
                                    {/*begin::Input group*/}
                                    <div className="mb-5 fv-row">
                                        {/*begin::Label*/}
                                        <label className="required fs-5 fw-bold mb-2">
                                            {" "}
                                            Firewall
                                        </label>
                                        {/*end::Label*/}
                                        {/*begin::Input*/}
                                        <select className="form-select" id="add_fw_id">
                                            <option value='' disabled selected>Please Select Firewall</option>
                                            {fwData != null ? (<>
                                                {fwData.map((value, index) => (<>
                                                    <option value={value.id}> {value.name}</option>
                                                </>))}
                                            </>) : ('')}
                                        </select>
                                        {/*end::Input*/}
                                    </div>
                                    {/*end::Input group*/}

                                </div>
                                {/*end::Scroll*/}
                            </div>
                            {/*end::Modal body*/}
                            {/*begin::Modal footer*/}
                            <div className="modal-footer ">
                                {/*begin::Button*/}
                                <a
                                    onClick={attachFw}
                                    className="btn btn-primary w-100"
                                    id="add-fw-btn"
                                >
                                    <span className="indicator-label">
                                        Add Firewall
                                    </span>
                                </a>
                                {/*end::Button*/}
                            </div>
                            {/*end::Modal footer*/}
                        </form>
                        {/*end::Form*/}
                    </div>
                    {/*end::Card body*/}
                </div>
                {/*end::Messenger*/}
            </div>
        </>
    )
} 