import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IsUserLogin } from "../../middleware/IsUserLogin";
import TopBarHeaderLine from "../../services/TopBarHeaderLine";
import TableLoader from "../../services/TableLoader";
import { IsSubuser, LogoutUser } from "../../middleware/User";
import SmsVerification from "./user-verification/SmsVerification";
import BuisnessVerification from "./user-verification/BuisnessVerification";
import IndivisualVerification from "./user-verification/IndivisualVerification";
import Config from "../../App/PlatformConfig/Config";
import BillingVerification from "../../App/MicroApp/Body/Products/verification/Billing/BillingVerification";
import TwoStepVerification from "./user-verification/TwoStepVerification";
import Cookies from "js-cookie";
import localStorage from "local-storage";

function PrivateRoutes() {
  var current_url_string = window.location.href;
  const current_url = new URL(current_url_string);
  const current_domain = current_url.hostname;
  
  const [userData, setUserData] = useState(null);
  const [isSubAccount, setIsSubAccount] = useState(false);
  const isProfile = useRef(false);

  useEffect(() => { 
        setTimeout(async ()=>{ 
      const [userRequest, subuserRequest] = await Promise.allSettled([IsUserLogin(), IsSubuser()]);
     
      if (userRequest.status === 'fulfilled') {
        const userDetails = userRequest.value; 
          
        if (userDetails.status === "error") {
          const dt = toast.loading("Please wait...");
          toast.update(dt, {
            render: userDetails.message,
            type: "warning",
            isLoading: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          LogoutUser();
        } else {
          localStorage.set("user_info", userDetails.user);
          setUserData(userDetails.user); 
        }
      }
  
      if (subuserRequest.status === 'fulfilled') {
        setIsSubAccount(subuserRequest.value);
      } 
    },3000) 
  }, []); 

 
      if (userData) {  
        var buildVersion = Cookies.get("buildVersion"); 
        if(buildVersion){
          Cookies.set("buildVersion",userData?.build,{ expires: 30, path: '/', domain: "." + current_domain });
        }

        if (userData.twofa != "Required") {
          if (userData.sms_verified == 0) {
            return (
              <>
                <SmsVerification />
              </>
            );
          } else {
            if (userData.type == "Individual") {
              if (
                userData.address &&
                userData.city &&
                userData.state &&
                userData.postcode &&
                userData.country
              ) {
                isProfile.current = true;
              } else {
                isProfile.current = false;
              }
            } else {
              if (
                userData.address &&
                userData.city &&
                userData.state &&
                userData.postcode &&
                userData.country &&
                userData.company
              ) {
                isProfile.current = true;
              } else {
                isProfile.current = false;
              }
            }

            if (isProfile.current) {
              
              if (
                userData.supportneed_usecase &&
                userData.supportneed_businesstype &&
                userData.supportneed_monthlyspend &&
                userData.supportneed_employeesize
              ) {  
                if ( 
                  isSubAccount=="no" && (userData.c_added == "no" && userData.rvn == null)
                ) {
                  return (
                    <>
                      <BillingVerification />
                    </>
                  );
                } else {
                  return (
                    <>
                      {/* {userData.type == "Individual" ? (
                        <IndivisualVerification />
                      ) : (
                        <BuisnessVerification />
                      )} */}
                      <Config />
                    </>
                  );
                }
              } else {
                if (userData.support_fields_required == "No") {  
                   if ( 
                    isSubAccount=="no" && (userData.c_added == "no" && userData.rvn == null)
                  ) {
                     return (
                      <>
                        <BillingVerification />
                      </>
                    );
                  } else {
                    return (
                      <>
                        <Config />
                      </>
                    );
                  }
                } else {
                  return (
                    <>
                      {userData.type == "Individual" ? (
                        <IndivisualVerification />
                      ) : (
                        <BuisnessVerification />
                      )}
                      <Config />
                    </>
                  );
                }
              }
            } else {
              return (
                <>
                  {userData.type == "Individual" ? (
                    <IndivisualVerification />
                  ) : (
                    <BuisnessVerification />
                  )}
                  {/* <Config /> */}
                </>
              );
            }
          }
        } else {
          return (
            <>
              <TwoStepVerification />
            </>
          );
        }
      } else {
        return (
          <> 
            <TopBarHeaderLine />
            <div className="mainLoad">
              <img src="assets/images/utho.png" alt="" width="100%" />
              <TableLoader />
            </div>
          </>
        );
      }   
     
}

export default PrivateRoutes;
