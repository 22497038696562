// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes slideLeftToRight {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  /* Apply the animation to your menu */
  #kt_header_menu.slide-animation {
    animation: slideLeftToRight 1s ease-in-out; /* Adjust the duration and timing function as needed */
  }`, "",{"version":3,"sources":["webpack://./src/App/MicroApp/Header/Toolbar/custom.css"],"names":[],"mappings":"AAAA;IACI;MACE,4BAA4B;IAC9B;IACA;MACE,wBAAwB;IAC1B;EACF;;EAEA,qCAAqC;EACrC;IACE,0CAA0C,EAAE,sDAAsD;EACpG","sourcesContent":["@keyframes slideLeftToRight {\n    from {\n      transform: translateX(-100%);\n    }\n    to {\n      transform: translateX(0);\n    }\n  }\n  \n  /* Apply the animation to your menu */\n  #kt_header_menu.slide-animation {\n    animation: slideLeftToRight 1s ease-in-out; /* Adjust the duration and timing function as needed */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
