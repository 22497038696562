import {React} from "react";
import { Link} from "react-router-dom"; 
export default function Index(){

    return (
        <>
        {/*begin::Post*/}
        <div className="post d-flex flex-column-fluid" id="kt_post">
          {/*begin::Container*/}
          <div id="kt_content_container" className="container-xxl">
            {/*begin::Navbar*/}
            <div className="card mb-6 mb-xl-9">
              <div className="card-body pt-9 pb-0">
                {/*begin::Details*/}
                <div className="d-flex flex-wrap flex-sm-nowrap mb-6">
                  {/*begin::Image*/}
                  <div className="d-flex flex-center flex-shrink-0 bg-light rounded w-100px h-100px w-lg-150px h-lg-150px me-7 mb-4">
                   {/*begin::Svg Icon | path: assets/media/icons/duotune/communication/com010.svg*/}
  <span className="svg-icon svg-icon-primary svg-icon-5hx">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M6 8.725C6 8.125 6.4 7.725 7 7.725H14L18 11.725V12.925L22 9.725L12.6 2.225C12.2 1.925 11.7 1.925 11.4 2.225L2 9.725L6 12.925V8.725Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M22 9.72498V20.725C22 21.325 21.6 21.725 21 21.725H3C2.4 21.725 2 21.325 2 20.725V9.72498L11.4 17.225C11.8 17.525 12.3 17.525 12.6 17.225L22 9.72498ZM15 11.725H18L14 7.72498V10.725C14 11.325 14.4 11.725 15 11.725Z"
        fill="black"
      />
    </svg>
  </span>
  {/*end::Svg Icon*/}
                    {/* <img
                      className="mw-50px mw-lg-75px"
                      src="assets/media/svg/brand-logos/volicity-9.svg"
                      alt="image"
                    /> */}
                  </div>
                  {/*end::Image*/}
                  {/*begin::Wrapper*/}
                  <div className="flex-grow-1">
                    {/*begin::Head*/}
                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                      {/*begin::Details*/}
                      <div className="d-flex flex-column">
                        {/*begin::Status*/}
                        <div className="d-flex align-items-center mb-1">
                          <a
                            href="#"
                            className="text-gray-800 text-hover-primary fs-2 fw-bolder me-3"
                          >
                            Utho Email Service
                          </a>
                          <span className="badge badge-light-success me-auto">
                            Beta
                          </span>
                        </div>
                        {/*end::Status*/}
                        {/*begin::Description*/}
                        <div className="d-flex flex-wrap fw-bold mb-4 fs-5 text-gray-400">
                          Send email to your contacts using UTHO email templates
                        </div>
                        {/*end::Description*/}
                      </div>
                      {/*end::Details*/}
                      {/*begin::Actions*/}
                      <div className="d-flex mb-4">
                        
                      <Link to="/emailTemplate">
                        <a 
                          className="btn btn-sm btn-bg-light btn-active-color-primary me-3" 
                        >
                         {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen041.svg*/}
  <span className="svg-icon svg-icon-muted svg-icon-2">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      {/* <rect
        opacity="0.3"
        x={2}
        y={2}
        width={20}
        height={20}
        rx={10}
        fill="black"
      /> */}
      <rect
        x="10.8891"
        y="17.8033"
        width={12}
        height={2}
        rx={1}
        transform="rotate(-90 10.8891 17.8033)"
        fill="black"
      />
      <rect x="6.01041" y="10.9247" width={12} height={2} rx={1} fill="black" />
    </svg>
  </span>
  {/*end::Svg Icon*/}
   Add Template
                        </a></Link>
                        <a
                          href="#"
                          className="btn btn-sm btn-primary me-3"
                          data-bs-toggle="modal"
                          data-bs-target="#kt_modal_new_target"
                        >
                          Send email &nbsp;

                          {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen016.svg*/}
  <span className="svg-icon svg-icon-primary svg-icon-2">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648Z"
        fill="black"
      />
    </svg>
  </span>
  {/*end::Svg Icon*/}
                        </a>
                        {/*begin::Menu*/}
                        <div className="me-0">
                          <button
                            className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                            data-kt-menu-trigger="click"
                            data-kt-menu-placement="bottom-end"
                          >
                            <i className="bi bi-three-dots fs-3" />
                          </button>
                          {/*begin::Menu 3*/}
                          <div
                            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3"
                            data-kt-menu="true"
                          >
                            {/*begin::Heading*/}
                            <div className="menu-item px-3">
                              <div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">
                                Payments
                              </div>
                            </div>
                            {/*end::Heading*/}
                            {/*begin::Menu item*/}
                            <div className="menu-item px-3">
                              <a href="#" className="menu-link px-3">
                                Create Invoice
                              </a>
                            </div>
                            {/*end::Menu item*/}
                            {/*begin::Menu item*/}
                            <div className="menu-item px-3">
                              <a href="#" className="menu-link flex-stack px-3">
                                Create Payment
                                <i
                                  className="fas fa-exclamation-circle ms-2 fs-7"
                                  data-bs-toggle="tooltip"
                                  title="Specify a target name for future usage and reference"
                                />
                              </a>
                            </div>
                            {/*end::Menu item*/}
                            {/*begin::Menu item*/}
                            <div className="menu-item px-3">
                              <a href="#" className="menu-link px-3">
                                Generate Bill
                              </a>
                            </div>
                            {/*end::Menu item*/}
                            {/*begin::Menu item*/}
                            <div
                              className="menu-item px-3"
                              data-kt-menu-trigger="hover"
                              data-kt-menu-placement="right-end"
                            >
                              <a href="#" className="menu-link px-3">
                                <span className="menu-title">Subscription</span>
                                <span className="menu-arrow" />
                              </a>
                              {/*begin::Menu sub*/}
                              <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                {/*begin::Menu item*/}
                                <div className="menu-item px-3">
                                  <a href="#" className="menu-link px-3">
                                    Plans
                                  </a>
                                </div>
                                {/*end::Menu item*/}
                                {/*begin::Menu item*/}
                                <div className="menu-item px-3">
                                  <a href="#" className="menu-link px-3">
                                    Billing
                                  </a>
                                </div>
                                {/*end::Menu item*/}
                                {/*begin::Menu item*/}
                                <div className="menu-item px-3">
                                  <a href="#" className="menu-link px-3">
                                    Statements
                                  </a>
                                </div>
                                {/*end::Menu item*/}
                                {/*begin::Menu separator*/}
                                <div className="separator my-2" />
                                {/*end::Menu separator*/}
                                {/*begin::Menu item*/}
                                <div className="menu-item px-3">
                                  <div className="menu-content px-3">
                                    {/*begin::Switch*/}
                                    <label className="form-check form-switch form-check-custom form-check-solid">
                                      {/*begin::Input*/}
                                      <input
                                        className="form-check-input w-30px h-20px"
                                        type="checkbox"
                                        defaultValue={1}
                                        defaultChecked="checked"
                                        name="notifications"
                                      />
                                      {/*end::Input*/}
                                      {/*end::Label*/}
                                      <span className="form-check-label text-muted fs-6">
                                        Recuring
                                      </span>
                                      {/*end::Label*/}
                                    </label>
                                    {/*end::Switch*/}
                                  </div>
                                </div>
                                {/*end::Menu item*/}
                              </div>
                              {/*end::Menu sub*/}
                            </div>
                            {/*end::Menu item*/}
                            {/*begin::Menu item*/}
                            <div className="menu-item px-3 my-1">
                              <a href="#" className="menu-link px-3">
                                Settings
                              </a>
                            </div>
                            {/*end::Menu item*/}
                          </div>
                          {/*end::Menu 3*/}
                        </div>
                        {/*end::Menu*/}
                      </div>
                      {/*end::Actions*/}
                    </div>
                    {/*end::Head*/}
                    {/*begin::Info*/}
                    <div className="d-flex flex-wrap justify-content-start">
                      {/*begin::Stats*/}
                      <div className="d-flex flex-wrap">
                        {/*begin::Stat*/}
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          {/*begin::Number*/}
                          <div className="text-center align-items-center">
                            <div className="fs-4 fw-bolder">29</div>
                          </div>
                          {/*end::Number*/}
                          {/*begin::Label*/}
                          <div className="fw-bold fs-6 text-gray-400">Total Emails</div>
                          {/*end::Label*/}
                        </div>
                        {/*end::Stat*/}
                        {/*begin::Stat*/}
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          {/*begin::Number*/}
                          <div className="text-center align-items-center"> 
                            <div
                              className="fs-4 fw-bolder"
                              data-kt-countup="true"
                              data-kt-countup-value={75}
                            >
                              0
                            </div>
                          </div>
                          {/*end::Number*/}
                          {/*begin::Label*/}
                          <div className="fw-bold fs-6 text-gray-400">Email Templates</div>
                          {/*end::Label*/}
                        </div>
                        {/*end::Stat*/}
                        {/*begin::Stat*/}
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          {/*begin::Number*/}
                          <div className="text-center align-items-center"> 
                            <div
                              className="fs-4 fw-bolder" 
                            >
                              0
                            </div>
                          </div>
                          {/*end::Number*/}
                          {/*begin::Label*/}
                          <div className="fw-bold fs-6 text-gray-400">
                           Total Contacts
                          </div>
                          {/*end::Label*/}
                        </div>
                        {/*end::Stat*/}
                      </div>
                      {/*end::Stats*/}

                      <Link to="/contacts">
                      {/*begin::Users*/}
                      <div className="symbol-group symbol-hover mb-3">
                        {/*begin::User*/}
                        <div
                          className="symbol symbol-35px symbol-circle"
                          data-bs-toggle="tooltip" 
                        >
                          <span className="symbol-label bg-warning text-inverse-warning fw-bolder">
                            A
                          </span>
                        </div>
                        {/*end::User*/}
                        {/*begin::User*/}
                        <div
                          className="symbol symbol-35px symbol-circle"
                          data-bs-toggle="tooltip" 
                        >
                          <img alt="Pic" src="assets/media/avatars/300-11.jpg" />
                        </div>
                        {/*end::User*/}
                        {/*begin::User*/}
                        <div
                          className="symbol symbol-35px symbol-circle"
                          data-bs-toggle="tooltip" 
                        >
                          <img alt="Pic" src="assets/media/avatars/300-7.jpg" />
                        </div>
                        {/*end::User*/}
                        {/*begin::User*/}
                        <div
                          className="symbol symbol-35px symbol-circle"
                          data-bs-toggle="tooltip" 
                        >
                          <img alt="Pic" src="assets/media/avatars/300-20.jpg" />
                        </div>
                        {/*end::User*/}
                        {/*begin::User*/}
                        <div
                          className="symbol symbol-35px symbol-circle"
                          data-bs-toggle="tooltip" 
                        >
                          <span className="symbol-label bg-primary text-inverse-primary fw-bolder">
                            S
                          </span>
                        </div>
                        {/*end::User*/}
                        {/*begin::User*/}
                        <div
                          className="symbol symbol-35px symbol-circle"
                          data-bs-toggle="tooltip" 
                        >
                          <img alt="Pic" src="assets/media/avatars/300-2.jpg" />
                        </div>
                        {/*end::User*/}
                        {/*begin::User*/}
                        <div
                          className="symbol symbol-35px symbol-circle"
                          data-bs-toggle="tooltip" 
                        >
                          <span className="symbol-label bg-info text-inverse-info fw-bolder">
                            P
                          </span>
                        </div>
                        {/*end::User*/}
                        {/*begin::User*/}
                        <div
                          className="symbol symbol-35px symbol-circle"
                          data-bs-toggle="tooltip" 
                        >
                          <img alt="Pic" src="assets/media/avatars/300-12.jpg" />
                        </div>
                        {/*end::User*/}
                        {/*begin::All users*/}
                        <a
                          href="#"
                          className="symbol symbol-35px symbol-circle"
                          data-bs-toggle="modal"
                          data-bs-target="#kt_modal_view_users"
                        >
                          <span
                            className="symbol-label bg-dark text-inverse-dark fs-8 fw-bolder"
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover"
                            title="View more users"
                          >
                            +
                          </span>
                        </a>
                        {/*end::All users*/}
                      </div>
                      {/*end::Users*/}
                      </Link>
                    </div>
                    {/*end::Info*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Details*/}
                <div className="separator" />
                {/*begin::Nav*/}
                <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder">
                  {/*begin::Nav item*/}
                  <li className="nav-item">
                    <a
                      className="nav-link text-active-primary py-5 me-6 active"
                      href="../../demo1/dist/apps/projects/project.html"
                    >
                      Overview
                    </a>
                  </li>
                  {/*end::Nav item*/}
                  {/*begin::Nav item*/}
                  <li className="nav-item">
                    <a
                      className="nav-link text-active-primary py-5 me-6"
                      href="../../demo1/dist/apps/projects/targets.html"
                    >
                      Targets
                    </a>
                  </li>
                  {/*end::Nav item*/}
                  {/*begin::Nav item*/}
                  <li className="nav-item">
                    <a
                      className="nav-link text-active-primary py-5 me-6"
                      href="../../demo1/dist/apps/projects/budget.html"
                    >
                      Budget
                    </a>
                  </li>
                  {/*end::Nav item*/}
                  {/*begin::Nav item*/}
                  <li className="nav-item">
                    <a
                      className="nav-link text-active-primary py-5 me-6"
                      href="../../demo1/dist/apps/projects/users.html"
                    >
                      Users
                    </a>
                  </li>
                  {/*end::Nav item*/}
                  {/*begin::Nav item*/}
                  <li className="nav-item">
                    <a
                      className="nav-link text-active-primary py-5 me-6"
                      href="../../demo1/dist/apps/projects/files.html"
                    >
                      Files
                    </a>
                  </li>
                  {/*end::Nav item*/}
                  {/*begin::Nav item*/}
                  <li className="nav-item">
                    <a
                      className="nav-link text-active-primary py-5 me-6"
                      href="../../demo1/dist/apps/projects/activity.html"
                    >
                      Activity
                    </a>
                  </li>
                  {/*end::Nav item*/}
                  {/*begin::Nav item*/}
                  <li className="nav-item">
                    <a
                      className="nav-link text-active-primary py-5 me-6"
                      href="../../demo1/dist/apps/projects/settings.html"
                    >
                      Settings
                    </a>
                  </li>
                  {/*end::Nav item*/}
                </ul>
                {/*end::Nav*/}
              </div>
            </div>
            {/*end::Navbar*/} 
            {/*begin::Table*/}
            <div className="card card-flush mt-6 mt-xl-9">
              {/*begin::Card header*/}
              <div className="card-header mt-5">
                {/*begin::Card title*/}
                <div className="card-title flex-column">
                  <h3 className="fw-bolder mb-1">Email Reports</h3>
                  <div className="fs-6 text-gray-400">
                    View your email reports here
                  </div>
                </div>
                {/*begin::Card title*/}
                {/*begin::Card toolbar*/}
                <div className="card-toolbar my-1">
                  {/*begin::Select*/}
                  <div className="me-6 my-1">
                    <select
                      id="kt_filter_year"
                      name="year"
                      data-control="select2"
                      data-hide-search="true"
                      className="w-125px form-select form-select-solid form-select-sm"
                    >
                      <option value="All" selected="selected">
                        All time
                      </option>
                      <option value="thisyear">This year</option>
                      <option value="thismonth">This month</option>
                      <option value="lastmonth">Last month</option>
                      <option value="last90days">Last 90 days</option>
                    </select>
                  </div>
                  {/*end::Select*/}
                  {/*begin::Select*/}
                  <div className="me-4 my-1">
                    <select
                      id="kt_filter_orders"
                      name="orders"
                      data-control="select2"
                      data-hide-search="true"
                      className="w-125px form-select form-select-solid form-select-sm"
                    >
                      <option value="All" selected="selected">
                        All Orders
                      </option>
                      <option value="Approved">Approved</option>
                      <option value="Declined">Declined</option>
                      <option value="In Progress">In Progress</option>
                      <option value="In Transit">In Transit</option>
                    </select>
                  </div>
                  {/*end::Select*/}
                  {/*begin::Search*/}
                  <div className="d-flex align-items-center position-relative my-1">
                    {/*begin::Svg Icon | path: icons/duotune/general/gen021.svg*/}
                    <span className="svg-icon svg-icon-3 position-absolute ms-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.5"
                          x="17.0365"
                          y="15.1223"
                          width="8.15546"
                          height={2}
                          rx={1}
                          transform="rotate(45 17.0365 15.1223)"
                          fill="black"
                        />
                        <path
                          d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}
                    <input
                      type="text"
                      id="kt_filter_search"
                      className="form-control form-control-solid form-select-sm w-150px ps-9"
                      placeholder="Search Order"
                    />
                  </div>
                  {/*end::Search*/}
                </div>
                {/*begin::Card toolbar*/}
              </div>
              {/*end::Card header*/}
              {/*begin::Card body*/}
              <div className="card-body pt-0">
                {/*begin::Table container*/}
                <div className="table-responsive">
                  {/*begin::Table*/}
                  <table
                    id="kt_profile_overview_table"
                    className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder"
                  >
                    {/*begin::Head*/}
                    <thead className="fs-7 text-gray-400 text-uppercase">
                      <tr>
                        <th className="min-w-250px">Contact</th>
                        <th className="min-w-150px">Date</th>
                        <th className="min-w-90px">Amount</th>
                        <th className="min-w-90px">Status</th>
                        <th className="min-w-50px text-end">Details</th>
                      </tr>
                    </thead>
                    {/*end::Head*/}
                    {/*begin::Body*/}
                    <tbody className="fs-6">
                      <tr>
                        <td>
                          {/*begin::User*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Wrapper*/}
                            <div className="me-5 position-relative">
                              {/*begin::Avatar*/}
                              <div className="symbol symbol-35px symbol-circle">
                                <img alt="Pic" src="assets/media/avatars/300-6.jpg" />
                              </div>
                              {/*end::Avatar*/}
                            </div>
                            {/*end::Wrapper*/}
                            {/*begin::Info*/}
                            <div className="d-flex flex-column justify-content-center">
                              <a
                                href=""
                                className="fs-6 text-gray-800 text-hover-primary"
                              >
                                Emma Smith
                              </a>
                              <div className="fw-bold text-gray-400">
                                smith@kpmg.com
                              </div>
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::User*/}
                        </td>
                        <td>Apr 15, 2022</td>
                        <td>$774.00</td>
                        <td>
                          <span className="badge badge-light-info fw-bolder px-4 py-3">
                            In progress
                          </span>
                        </td>
                        <td className="text-end">
                          <a href="#" className="btn btn-light btn-sm">
                            View
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {/*begin::User*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Wrapper*/}
                            <div className="me-5 position-relative">
                              {/*begin::Avatar*/}
                              <div className="symbol symbol-35px symbol-circle">
                                <span className="symbol-label bg-light-danger text-danger fw-bold">
                                  M
                                </span>
                              </div>
                              {/*end::Avatar*/}
                              {/*begin::Online*/}
                              <div className="bg-success position-absolute h-8px w-8px rounded-circle translate-middle start-100 top-100 ms-n1 mt-n1" />
                              {/*end::Online*/}
                            </div>
                            {/*end::Wrapper*/}
                            {/*begin::Info*/}
                            <div className="d-flex flex-column justify-content-center">
                              <a
                                href=""
                                className="fs-6 text-gray-800 text-hover-primary"
                              >
                                Melody Macy
                              </a>
                              <div className="fw-bold text-gray-400">
                                melody@altbox.com
                              </div>
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::User*/}
                        </td>
                        <td>Apr 15, 2022</td>
                        <td>$952.00</td>
                        <td>
                          <span className="badge badge-light-info fw-bolder px-4 py-3">
                            In progress
                          </span>
                        </td>
                        <td className="text-end">
                          <a href="#" className="btn btn-light btn-sm">
                            View
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {/*begin::User*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Wrapper*/}
                            <div className="me-5 position-relative">
                              {/*begin::Avatar*/}
                              <div className="symbol symbol-35px symbol-circle">
                                <img alt="Pic" src="assets/media/avatars/300-1.jpg" />
                              </div>
                              {/*end::Avatar*/}
                            </div>
                            {/*end::Wrapper*/}
                            {/*begin::Info*/}
                            <div className="d-flex flex-column justify-content-center">
                              <a
                                href=""
                                className="fs-6 text-gray-800 text-hover-primary"
                              >
                                Max Smith
                              </a>
                              <div className="fw-bold text-gray-400">max@kt.com</div>
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::User*/}
                        </td>
                        <td>Oct 25, 2022</td>
                        <td>$981.00</td>
                        <td>
                          <span className="badge badge-light-info fw-bolder px-4 py-3">
                            In progress
                          </span>
                        </td>
                        <td className="text-end">
                          <a href="#" className="btn btn-light btn-sm">
                            View
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {/*begin::User*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Wrapper*/}
                            <div className="me-5 position-relative">
                              {/*begin::Avatar*/}
                              <div className="symbol symbol-35px symbol-circle">
                                <img alt="Pic" src="assets/media/avatars/300-5.jpg" />
                              </div>
                              {/*end::Avatar*/}
                            </div>
                            {/*end::Wrapper*/}
                            {/*begin::Info*/}
                            <div className="d-flex flex-column justify-content-center">
                              <a
                                href=""
                                className="fs-6 text-gray-800 text-hover-primary"
                              >
                                Sean Bean
                              </a>
                              <div className="fw-bold text-gray-400">
                                sean@dellito.com
                              </div>
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::User*/}
                        </td>
                        <td>Jun 24, 2022</td>
                        <td>$561.00</td>
                        <td>
                          <span className="badge badge-light-warning fw-bolder px-4 py-3">
                            Pending
                          </span>
                        </td>
                        <td className="text-end">
                          <a href="#" className="btn btn-light btn-sm">
                            View
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {/*begin::User*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Wrapper*/}
                            <div className="me-5 position-relative">
                              {/*begin::Avatar*/}
                              <div className="symbol symbol-35px symbol-circle">
                                <img
                                  alt="Pic"
                                  src="assets/media/avatars/300-25.jpg"
                                />
                              </div>
                              {/*end::Avatar*/}
                            </div>
                            {/*end::Wrapper*/}
                            {/*begin::Info*/}
                            <div className="d-flex flex-column justify-content-center">
                              <a
                                href=""
                                className="fs-6 text-gray-800 text-hover-primary"
                              >
                                Brian Cox
                              </a>
                              <div className="fw-bold text-gray-400">
                                brian@exchange.com
                              </div>
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::User*/}
                        </td>
                        <td>Mar 10, 2022</td>
                        <td>$796.00</td>
                        <td>
                          <span className="badge badge-light-info fw-bolder px-4 py-3">
                            In progress
                          </span>
                        </td>
                        <td className="text-end">
                          <a href="#" className="btn btn-light btn-sm">
                            View
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {/*begin::User*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Wrapper*/}
                            <div className="me-5 position-relative">
                              {/*begin::Avatar*/}
                              <div className="symbol symbol-35px symbol-circle">
                                <span className="symbol-label bg-light-warning text-warning fw-bold">
                                  C
                                </span>
                              </div>
                              {/*end::Avatar*/}
                              {/*begin::Online*/}
                              <div className="bg-success position-absolute h-8px w-8px rounded-circle translate-middle start-100 top-100 ms-n1 mt-n1" />
                              {/*end::Online*/}
                            </div>
                            {/*end::Wrapper*/}
                            {/*begin::Info*/}
                            <div className="d-flex flex-column justify-content-center">
                              <a
                                href=""
                                className="fs-6 text-gray-800 text-hover-primary"
                              >
                                Mikaela Collins
                              </a>
                              <div className="fw-bold text-gray-400">mik@pex.com</div>
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::User*/}
                        </td>
                        <td>May 05, 2022</td>
                        <td>$680.00</td>
                        <td>
                          <span className="badge badge-light-danger fw-bolder px-4 py-3">
                            Rejected
                          </span>
                        </td>
                        <td className="text-end">
                          <a href="#" className="btn btn-light btn-sm">
                            View
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {/*begin::User*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Wrapper*/}
                            <div className="me-5 position-relative">
                              {/*begin::Avatar*/}
                              <div className="symbol symbol-35px symbol-circle">
                                <img alt="Pic" src="assets/media/avatars/300-9.jpg" />
                              </div>
                              {/*end::Avatar*/}
                            </div>
                            {/*end::Wrapper*/}
                            {/*begin::Info*/}
                            <div className="d-flex flex-column justify-content-center">
                              <a
                                href=""
                                className="fs-6 text-gray-800 text-hover-primary"
                              >
                                Francis Mitcham
                              </a>
                              <div className="fw-bold text-gray-400">
                                f.mit@kpmg.com
                              </div>
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::User*/}
                        </td>
                        <td>Dec 20, 2022</td>
                        <td>$435.00</td>
                        <td>
                          <span className="badge badge-light-success fw-bolder px-4 py-3">
                            Approved
                          </span>
                        </td>
                        <td className="text-end">
                          <a href="#" className="btn btn-light btn-sm">
                            View
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {/*begin::User*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Wrapper*/}
                            <div className="me-5 position-relative">
                              {/*begin::Avatar*/}
                              <div className="symbol symbol-35px symbol-circle">
                                <span className="symbol-label bg-light-danger text-danger fw-bold">
                                  O
                                </span>
                              </div>
                              {/*end::Avatar*/}
                              {/*begin::Online*/}
                              <div className="bg-success position-absolute h-8px w-8px rounded-circle translate-middle start-100 top-100 ms-n1 mt-n1" />
                              {/*end::Online*/}
                            </div>
                            {/*end::Wrapper*/}
                            {/*begin::Info*/}
                            <div className="d-flex flex-column justify-content-center">
                              <a
                                href=""
                                className="fs-6 text-gray-800 text-hover-primary"
                              >
                                Olivia Wild
                              </a>
                              <div className="fw-bold text-gray-400">
                                olivia@corpmail.com
                              </div>
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::User*/}
                        </td>
                        <td>Jun 24, 2022</td>
                        <td>$839.00</td>
                        <td>
                          <span className="badge badge-light-success fw-bolder px-4 py-3">
                            Approved
                          </span>
                        </td>
                        <td className="text-end">
                          <a href="#" className="btn btn-light btn-sm">
                            View
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {/*begin::User*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Wrapper*/}
                            <div className="me-5 position-relative">
                              {/*begin::Avatar*/}
                              <div className="symbol symbol-35px symbol-circle">
                                <span className="symbol-label bg-light-primary text-primary fw-bold">
                                  N
                                </span>
                              </div>
                              {/*end::Avatar*/}
                              {/*begin::Online*/}
                              <div className="bg-success position-absolute h-8px w-8px rounded-circle translate-middle start-100 top-100 ms-n1 mt-n1" />
                              {/*end::Online*/}
                            </div>
                            {/*end::Wrapper*/}
                            {/*begin::Info*/}
                            <div className="d-flex flex-column justify-content-center">
                              <a
                                href=""
                                className="fs-6 text-gray-800 text-hover-primary"
                              >
                                Neil Owen
                              </a>
                              <div className="fw-bold text-gray-400">
                                owen.neil@gmail.com
                              </div>
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::User*/}
                        </td>
                        <td>Jun 24, 2022</td>
                        <td>$810.00</td>
                        <td>
                          <span className="badge badge-light-info fw-bolder px-4 py-3">
                            In progress
                          </span>
                        </td>
                        <td className="text-end">
                          <a href="#" className="btn btn-light btn-sm">
                            View
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {/*begin::User*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Wrapper*/}
                            <div className="me-5 position-relative">
                              {/*begin::Avatar*/}
                              <div className="symbol symbol-35px symbol-circle">
                                <img
                                  alt="Pic"
                                  src="assets/media/avatars/300-23.jpg"
                                />
                              </div>
                              {/*end::Avatar*/}
                            </div>
                            {/*end::Wrapper*/}
                            {/*begin::Info*/}
                            <div className="d-flex flex-column justify-content-center">
                              <a
                                href=""
                                className="fs-6 text-gray-800 text-hover-primary"
                              >
                                Dan Wilson
                              </a>
                              <div className="fw-bold text-gray-400">
                                dam@consilting.com
                              </div>
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::User*/}
                        </td>
                        <td>Apr 15, 2022</td>
                        <td>$702.00</td>
                        <td>
                          <span className="badge badge-light-success fw-bolder px-4 py-3">
                            Approved
                          </span>
                        </td>
                        <td className="text-end">
                          <a href="#" className="btn btn-light btn-sm">
                            View
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {/*begin::User*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Wrapper*/}
                            <div className="me-5 position-relative">
                              {/*begin::Avatar*/}
                              <div className="symbol symbol-35px symbol-circle">
                                <span className="symbol-label bg-light-danger text-danger fw-bold">
                                  E
                                </span>
                              </div>
                              {/*end::Avatar*/}
                              {/*begin::Online*/}
                              <div className="bg-success position-absolute h-8px w-8px rounded-circle translate-middle start-100 top-100 ms-n1 mt-n1" />
                              {/*end::Online*/}
                            </div>
                            {/*end::Wrapper*/}
                            {/*begin::Info*/}
                            <div className="d-flex flex-column justify-content-center">
                              <a
                                href=""
                                className="fs-6 text-gray-800 text-hover-primary"
                              >
                                Emma Bold
                              </a>
                              <div className="fw-bold text-gray-400">
                                emma@intenso.com
                              </div>
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::User*/}
                        </td>
                        <td>Jun 20, 2022</td>
                        <td>$534.00</td>
                        <td>
                          <span className="badge badge-light-warning fw-bolder px-4 py-3">
                            Pending
                          </span>
                        </td>
                        <td className="text-end">
                          <a href="#" className="btn btn-light btn-sm">
                            View
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {/*begin::User*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Wrapper*/}
                            <div className="me-5 position-relative">
                              {/*begin::Avatar*/}
                              <div className="symbol symbol-35px symbol-circle">
                                <img
                                  alt="Pic"
                                  src="assets/media/avatars/300-12.jpg"
                                />
                              </div>
                              {/*end::Avatar*/}
                            </div>
                            {/*end::Wrapper*/}
                            {/*begin::Info*/}
                            <div className="d-flex flex-column justify-content-center">
                              <a
                                href=""
                                className="fs-6 text-gray-800 text-hover-primary"
                              >
                                Ana Crown
                              </a>
                              <div className="fw-bold text-gray-400">
                                ana.cf@limtel.com
                              </div>
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::User*/}
                        </td>
                        <td>Feb 21, 2022</td>
                        <td>$574.00</td>
                        <td>
                          <span className="badge badge-light-info fw-bolder px-4 py-3">
                            In progress
                          </span>
                        </td>
                        <td className="text-end">
                          <a href="#" className="btn btn-light btn-sm">
                            View
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {/*begin::User*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Wrapper*/}
                            <div className="me-5 position-relative">
                              {/*begin::Avatar*/}
                              <div className="symbol symbol-35px symbol-circle">
                                <span className="symbol-label bg-light-info text-info fw-bold">
                                  A
                                </span>
                              </div>
                              {/*end::Avatar*/}
                              {/*begin::Online*/}
                              <div className="bg-success position-absolute h-8px w-8px rounded-circle translate-middle start-100 top-100 ms-n1 mt-n1" />
                              {/*end::Online*/}
                            </div>
                            {/*end::Wrapper*/}
                            {/*begin::Info*/}
                            <div className="d-flex flex-column justify-content-center">
                              <a
                                href=""
                                className="fs-6 text-gray-800 text-hover-primary"
                              >
                                Robert Doe
                              </a>
                              <div className="fw-bold text-gray-400">
                                robert@benko.com
                              </div>
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::User*/}
                        </td>
                        <td>May 05, 2022</td>
                        <td>$686.00</td>
                        <td>
                          <span className="badge badge-light-success fw-bolder px-4 py-3">
                            Approved
                          </span>
                        </td>
                        <td className="text-end">
                          <a href="#" className="btn btn-light btn-sm">
                            View
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {/*begin::User*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Wrapper*/}
                            <div className="me-5 position-relative">
                              {/*begin::Avatar*/}
                              <div className="symbol symbol-35px symbol-circle">
                                <img
                                  alt="Pic"
                                  src="assets/media/avatars/300-13.jpg"
                                />
                              </div>
                              {/*end::Avatar*/}
                            </div>
                            {/*end::Wrapper*/}
                            {/*begin::Info*/}
                            <div className="d-flex flex-column justify-content-center">
                              <a
                                href=""
                                className="fs-6 text-gray-800 text-hover-primary"
                              >
                                John Miller
                              </a>
                              <div className="fw-bold text-gray-400">
                                miller@mapple.com
                              </div>
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::User*/}
                        </td>
                        <td>May 05, 2022</td>
                        <td>$992.00</td>
                        <td>
                          <span className="badge badge-light-info fw-bolder px-4 py-3">
                            In progress
                          </span>
                        </td>
                        <td className="text-end">
                          <a href="#" className="btn btn-light btn-sm">
                            View
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {/*begin::User*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Wrapper*/}
                            <div className="me-5 position-relative">
                              {/*begin::Avatar*/}
                              <div className="symbol symbol-35px symbol-circle">
                                <span className="symbol-label bg-light-success text-success fw-bold">
                                  L
                                </span>
                              </div>
                              {/*end::Avatar*/}
                              {/*begin::Online*/}
                              <div className="bg-success position-absolute h-8px w-8px rounded-circle translate-middle start-100 top-100 ms-n1 mt-n1" />
                              {/*end::Online*/}
                            </div>
                            {/*end::Wrapper*/}
                            {/*begin::Info*/}
                            <div className="d-flex flex-column justify-content-center">
                              <a
                                href=""
                                className="fs-6 text-gray-800 text-hover-primary"
                              >
                                Lucy Kunic
                              </a>
                              <div className="fw-bold text-gray-400">
                                lucy.m@fentech.com
                              </div>
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::User*/}
                        </td>
                        <td>Dec 20, 2022</td>
                        <td>$889.00</td>
                        <td>
                          <span className="badge badge-light-danger fw-bolder px-4 py-3">
                            Rejected
                          </span>
                        </td>
                        <td className="text-end">
                          <a href="#" className="btn btn-light btn-sm">
                            View
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {/*begin::User*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Wrapper*/}
                            <div className="me-5 position-relative">
                              {/*begin::Avatar*/}
                              <div className="symbol symbol-35px symbol-circle">
                                <img
                                  alt="Pic"
                                  src="assets/media/avatars/300-21.jpg"
                                />
                              </div>
                              {/*end::Avatar*/}
                              {/*begin::Online*/}
                              <div className="bg-success position-absolute h-8px w-8px rounded-circle translate-middle start-100 top-100 ms-n1 mt-n1" />
                              {/*end::Online*/}
                            </div>
                            {/*end::Wrapper*/}
                            {/*begin::Info*/}
                            <div className="d-flex flex-column justify-content-center">
                              <a
                                href=""
                                className="fs-6 text-gray-800 text-hover-primary"
                              >
                                Ethan Wilder
                              </a>
                              <div className="fw-bold text-gray-400">
                                ethan@loop.com.au
                              </div>
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::User*/}
                        </td>
                        <td>Jun 24, 2022</td>
                        <td>$951.00</td>
                        <td>
                          <span className="badge badge-light-info fw-bolder px-4 py-3">
                            In progress
                          </span>
                        </td>
                        <td className="text-end">
                          <a href="#" className="btn btn-light btn-sm">
                            View
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {/*begin::User*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Wrapper*/}
                            <div className="me-5 position-relative">
                              {/*begin::Avatar*/}
                              <div className="symbol symbol-35px symbol-circle">
                                <img
                                  alt="Pic"
                                  src="assets/media/avatars/300-21.jpg"
                                />
                              </div>
                              {/*end::Avatar*/}
                              {/*begin::Online*/}
                              <div className="bg-success position-absolute h-8px w-8px rounded-circle translate-middle start-100 top-100 ms-n1 mt-n1" />
                              {/*end::Online*/}
                            </div>
                            {/*end::Wrapper*/}
                            {/*begin::Info*/}
                            <div className="d-flex flex-column justify-content-center">
                              <a
                                href=""
                                className="fs-6 text-gray-800 text-hover-primary"
                              >
                                Ethan Wilder
                              </a>
                              <div className="fw-bold text-gray-400">
                                ethan@loop.com.au
                              </div>
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::User*/}
                        </td>
                        <td>Aug 19, 2022</td>
                        <td>$400.00</td>
                        <td>
                          <span className="badge badge-light-success fw-bolder px-4 py-3">
                            Approved
                          </span>
                        </td>
                        <td className="text-end">
                          <a href="#" className="btn btn-light btn-sm">
                            View
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {/*begin::User*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Wrapper*/}
                            <div className="me-5 position-relative">
                              {/*begin::Avatar*/}
                              <div className="symbol symbol-35px symbol-circle">
                                <img
                                  alt="Pic"
                                  src="assets/media/avatars/300-21.jpg"
                                />
                              </div>
                              {/*end::Avatar*/}
                              {/*begin::Online*/}
                              <div className="bg-success position-absolute h-8px w-8px rounded-circle translate-middle start-100 top-100 ms-n1 mt-n1" />
                              {/*end::Online*/}
                            </div>
                            {/*end::Wrapper*/}
                            {/*begin::Info*/}
                            <div className="d-flex flex-column justify-content-center">
                              <a
                                href=""
                                className="fs-6 text-gray-800 text-hover-primary"
                              >
                                Ethan Wilder
                              </a>
                              <div className="fw-bold text-gray-400">
                                ethan@loop.com.au
                              </div>
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::User*/}
                        </td>
                        <td>Mar 10, 2022</td>
                        <td>$693.00</td>
                        <td>
                          <span className="badge badge-light-danger fw-bolder px-4 py-3">
                            Rejected
                          </span>
                        </td>
                        <td className="text-end">
                          <a href="#" className="btn btn-light btn-sm">
                            View
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {/*begin::User*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Wrapper*/}
                            <div className="me-5 position-relative">
                              {/*begin::Avatar*/}
                              <div className="symbol symbol-35px symbol-circle">
                                <img
                                  alt="Pic"
                                  src="assets/media/avatars/300-12.jpg"
                                />
                              </div>
                              {/*end::Avatar*/}
                            </div>
                            {/*end::Wrapper*/}
                            {/*begin::Info*/}
                            <div className="d-flex flex-column justify-content-center">
                              <a
                                href=""
                                className="fs-6 text-gray-800 text-hover-primary"
                              >
                                Ana Crown
                              </a>
                              <div className="fw-bold text-gray-400">
                                ana.cf@limtel.com
                              </div>
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::User*/}
                        </td>
                        <td>Feb 21, 2022</td>
                        <td>$566.00</td>
                        <td>
                          <span className="badge badge-light-danger fw-bolder px-4 py-3">
                            Rejected
                          </span>
                        </td>
                        <td className="text-end">
                          <a href="#" className="btn btn-light btn-sm">
                            View
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {/*begin::User*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Wrapper*/}
                            <div className="me-5 position-relative">
                              {/*begin::Avatar*/}
                              <div className="symbol symbol-35px symbol-circle">
                                <span className="symbol-label bg-light-warning text-warning fw-bold">
                                  C
                                </span>
                              </div>
                              {/*end::Avatar*/}
                              {/*begin::Online*/}
                              <div className="bg-success position-absolute h-8px w-8px rounded-circle translate-middle start-100 top-100 ms-n1 mt-n1" />
                              {/*end::Online*/}
                            </div>
                            {/*end::Wrapper*/}
                            {/*begin::Info*/}
                            <div className="d-flex flex-column justify-content-center">
                              <a
                                href=""
                                className="fs-6 text-gray-800 text-hover-primary"
                              >
                                Mikaela Collins
                              </a>
                              <div className="fw-bold text-gray-400">mik@pex.com</div>
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::User*/}
                        </td>
                        <td>May 05, 2022</td>
                        <td>$608.00</td>
                        <td>
                          <span className="badge badge-light-danger fw-bolder px-4 py-3">
                            Rejected
                          </span>
                        </td>
                        <td className="text-end">
                          <a href="#" className="btn btn-light btn-sm">
                            View
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {/*begin::User*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Wrapper*/}
                            <div className="me-5 position-relative">
                              {/*begin::Avatar*/}
                              <div className="symbol symbol-35px symbol-circle">
                                <span className="symbol-label bg-light-warning text-warning fw-bold">
                                  C
                                </span>
                              </div>
                              {/*end::Avatar*/}
                              {/*begin::Online*/}
                              <div className="bg-success position-absolute h-8px w-8px rounded-circle translate-middle start-100 top-100 ms-n1 mt-n1" />
                              {/*end::Online*/}
                            </div>
                            {/*end::Wrapper*/}
                            {/*begin::Info*/}
                            <div className="d-flex flex-column justify-content-center">
                              <a
                                href=""
                                className="fs-6 text-gray-800 text-hover-primary"
                              >
                                Mikaela Collins
                              </a>
                              <div className="fw-bold text-gray-400">mik@pex.com</div>
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::User*/}
                        </td>
                        <td>Sep 22, 2022</td>
                        <td>$544.00</td>
                        <td>
                          <span className="badge badge-light-success fw-bolder px-4 py-3">
                            Approved
                          </span>
                        </td>
                        <td className="text-end">
                          <a href="#" className="btn btn-light btn-sm">
                            View
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {/*begin::User*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Wrapper*/}
                            <div className="me-5 position-relative">
                              {/*begin::Avatar*/}
                              <div className="symbol symbol-35px symbol-circle">
                                <img alt="Pic" src="assets/media/avatars/300-6.jpg" />
                              </div>
                              {/*end::Avatar*/}
                            </div>
                            {/*end::Wrapper*/}
                            {/*begin::Info*/}
                            <div className="d-flex flex-column justify-content-center">
                              <a
                                href=""
                                className="fs-6 text-gray-800 text-hover-primary"
                              >
                                Emma Smith
                              </a>
                              <div className="fw-bold text-gray-400">
                                smith@kpmg.com
                              </div>
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::User*/}
                        </td>
                        <td>Mar 10, 2022</td>
                        <td>$800.00</td>
                        <td>
                          <span className="badge badge-light-info fw-bolder px-4 py-3">
                            In progress
                          </span>
                        </td>
                        <td className="text-end">
                          <a href="#" className="btn btn-light btn-sm">
                            View
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {/*begin::User*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Wrapper*/}
                            <div className="me-5 position-relative">
                              {/*begin::Avatar*/}
                              <div className="symbol symbol-35px symbol-circle">
                                <span className="symbol-label bg-light-danger text-danger fw-bold">
                                  O
                                </span>
                              </div>
                              {/*end::Avatar*/}
                              {/*begin::Online*/}
                              <div className="bg-success position-absolute h-8px w-8px rounded-circle translate-middle start-100 top-100 ms-n1 mt-n1" />
                              {/*end::Online*/}
                            </div>
                            {/*end::Wrapper*/}
                            {/*begin::Info*/}
                            <div className="d-flex flex-column justify-content-center">
                              <a
                                href=""
                                className="fs-6 text-gray-800 text-hover-primary"
                              >
                                Olivia Wild
                              </a>
                              <div className="fw-bold text-gray-400">
                                olivia@corpmail.com
                              </div>
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::User*/}
                        </td>
                        <td>Dec 20, 2022</td>
                        <td>$535.00</td>
                        <td>
                          <span className="badge badge-light-danger fw-bolder px-4 py-3">
                            Rejected
                          </span>
                        </td>
                        <td className="text-end">
                          <a href="#" className="btn btn-light btn-sm">
                            View
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {/*begin::User*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Wrapper*/}
                            <div className="me-5 position-relative">
                              {/*begin::Avatar*/}
                              <div className="symbol symbol-35px symbol-circle">
                                <img
                                  alt="Pic"
                                  src="assets/media/avatars/300-25.jpg"
                                />
                              </div>
                              {/*end::Avatar*/}
                            </div>
                            {/*end::Wrapper*/}
                            {/*begin::Info*/}
                            <div className="d-flex flex-column justify-content-center">
                              <a
                                href=""
                                className="fs-6 text-gray-800 text-hover-primary"
                              >
                                Brian Cox
                              </a>
                              <div className="fw-bold text-gray-400">
                                brian@exchange.com
                              </div>
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::User*/}
                        </td>
                        <td>Sep 22, 2022</td>
                        <td>$825.00</td>
                        <td>
                          <span className="badge badge-light-danger fw-bolder px-4 py-3">
                            Rejected
                          </span>
                        </td>
                        <td className="text-end">
                          <a href="#" className="btn btn-light btn-sm">
                            View
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {/*begin::User*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Wrapper*/}
                            <div className="me-5 position-relative">
                              {/*begin::Avatar*/}
                              <div className="symbol symbol-35px symbol-circle">
                                <img alt="Pic" src="assets/media/avatars/300-9.jpg" />
                              </div>
                              {/*end::Avatar*/}
                            </div>
                            {/*end::Wrapper*/}
                            {/*begin::Info*/}
                            <div className="d-flex flex-column justify-content-center">
                              <a
                                href=""
                                className="fs-6 text-gray-800 text-hover-primary"
                              >
                                Francis Mitcham
                              </a>
                              <div className="fw-bold text-gray-400">
                                f.mit@kpmg.com
                              </div>
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::User*/}
                        </td>
                        <td>Nov 10, 2022</td>
                        <td>$963.00</td>
                        <td>
                          <span className="badge badge-light-danger fw-bolder px-4 py-3">
                            Rejected
                          </span>
                        </td>
                        <td className="text-end">
                          <a href="#" className="btn btn-light btn-sm">
                            View
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {/*begin::User*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Wrapper*/}
                            <div className="me-5 position-relative">
                              {/*begin::Avatar*/}
                              <div className="symbol symbol-35px symbol-circle">
                                <img alt="Pic" src="assets/media/avatars/300-9.jpg" />
                              </div>
                              {/*end::Avatar*/}
                            </div>
                            {/*end::Wrapper*/}
                            {/*begin::Info*/}
                            <div className="d-flex flex-column justify-content-center">
                              <a
                                href=""
                                className="fs-6 text-gray-800 text-hover-primary"
                              >
                                Francis Mitcham
                              </a>
                              <div className="fw-bold text-gray-400">
                                f.mit@kpmg.com
                              </div>
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::User*/}
                        </td>
                        <td>Jul 25, 2022</td>
                        <td>$957.00</td>
                        <td>
                          <span className="badge badge-light-warning fw-bolder px-4 py-3">
                            Pending
                          </span>
                        </td>
                        <td className="text-end">
                          <a href="#" className="btn btn-light btn-sm">
                            View
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {/*begin::User*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Wrapper*/}
                            <div className="me-5 position-relative">
                              {/*begin::Avatar*/}
                              <div className="symbol symbol-35px symbol-circle">
                                <img
                                  alt="Pic"
                                  src="assets/media/avatars/300-23.jpg"
                                />
                              </div>
                              {/*end::Avatar*/}
                            </div>
                            {/*end::Wrapper*/}
                            {/*begin::Info*/}
                            <div className="d-flex flex-column justify-content-center">
                              <a
                                href=""
                                className="fs-6 text-gray-800 text-hover-primary"
                              >
                                Dan Wilson
                              </a>
                              <div className="fw-bold text-gray-400">
                                dam@consilting.com
                              </div>
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::User*/}
                        </td>
                        <td>Jul 25, 2022</td>
                        <td>$672.00</td>
                        <td>
                          <span className="badge badge-light-success fw-bolder px-4 py-3">
                            Approved
                          </span>
                        </td>
                        <td className="text-end">
                          <a href="#" className="btn btn-light btn-sm">
                            View
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {/*begin::User*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Wrapper*/}
                            <div className="me-5 position-relative">
                              {/*begin::Avatar*/}
                              <div className="symbol symbol-35px symbol-circle">
                                <img alt="Pic" src="assets/media/avatars/300-1.jpg" />
                              </div>
                              {/*end::Avatar*/}
                            </div>
                            {/*end::Wrapper*/}
                            {/*begin::Info*/}
                            <div className="d-flex flex-column justify-content-center">
                              <a
                                href=""
                                className="fs-6 text-gray-800 text-hover-primary"
                              >
                                Max Smith
                              </a>
                              <div className="fw-bold text-gray-400">max@kt.com</div>
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::User*/}
                        </td>
                        <td>Dec 20, 2022</td>
                        <td>$524.00</td>
                        <td>
                          <span className="badge badge-light-warning fw-bolder px-4 py-3">
                            Pending
                          </span>
                        </td>
                        <td className="text-end">
                          <a href="#" className="btn btn-light btn-sm">
                            View
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {/*begin::User*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Wrapper*/}
                            <div className="me-5 position-relative">
                              {/*begin::Avatar*/}
                              <div className="symbol symbol-35px symbol-circle">
                                <span className="symbol-label bg-light-danger text-danger fw-bold">
                                  M
                                </span>
                              </div>
                              {/*end::Avatar*/}
                              {/*begin::Online*/}
                              <div className="bg-success position-absolute h-8px w-8px rounded-circle translate-middle start-100 top-100 ms-n1 mt-n1" />
                              {/*end::Online*/}
                            </div>
                            {/*end::Wrapper*/}
                            {/*begin::Info*/}
                            <div className="d-flex flex-column justify-content-center">
                              <a
                                href=""
                                className="fs-6 text-gray-800 text-hover-primary"
                              >
                                Melody Macy
                              </a>
                              <div className="fw-bold text-gray-400">
                                melody@altbox.com
                              </div>
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::User*/}
                        </td>
                        <td>Feb 21, 2022</td>
                        <td>$945.00</td>
                        <td>
                          <span className="badge badge-light-success fw-bolder px-4 py-3">
                            Approved
                          </span>
                        </td>
                        <td className="text-end">
                          <a href="#" className="btn btn-light btn-sm">
                            View
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {/*begin::User*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Wrapper*/}
                            <div className="me-5 position-relative">
                              {/*begin::Avatar*/}
                              <div className="symbol symbol-35px symbol-circle">
                                <img
                                  alt="Pic"
                                  src="assets/media/avatars/300-12.jpg"
                                />
                              </div>
                              {/*end::Avatar*/}
                            </div>
                            {/*end::Wrapper*/}
                            {/*begin::Info*/}
                            <div className="d-flex flex-column justify-content-center">
                              <a
                                href=""
                                className="fs-6 text-gray-800 text-hover-primary"
                              >
                                Ana Crown
                              </a>
                              <div className="fw-bold text-gray-400">
                                ana.cf@limtel.com
                              </div>
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::User*/}
                        </td>
                        <td>Dec 20, 2022</td>
                        <td>$469.00</td>
                        <td>
                          <span className="badge badge-light-danger fw-bolder px-4 py-3">
                            Rejected
                          </span>
                        </td>
                        <td className="text-end">
                          <a href="#" className="btn btn-light btn-sm">
                            View
                          </a>
                        </td>
                      </tr>
                    </tbody>
                    {/*end::Body*/}
                  </table>
                  {/*end::Table*/}
                </div>
                {/*end::Table container*/}
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Card*/}
          </div>
          {/*end::Container*/}
        </div>
        {/*end::Post*/}
      </>
      
        )
}