import {React} from 'react';
import Heading from './toolComponents/Heading';
import Paragraph from './toolComponents/Paragraph';
import Button from './toolComponents/Button';
import Regards from './toolComponents/Regards';
import ImgContent from './toolComponents/ImgContent';
import ImageSec from './toolComponents/ImageSec';
import Card3WithIcon from './toolComponents/Card3WithIcon';

export default function BodySection(){

    return(
        <>
          <tr>
                <td align="center" valign="center">
                  <div
                    style={{
                      textAlign: "center",
                      margin: "0 20px",
                      padding: 40,
                      backgroundColor: "#ffffff",
                      borderRadius: 6
                    }}
                  >
                    {/*begin:Email content*/}
                    
                     <Heading title="Welcome to Utho Cloud!" />

                     <Paragraph description= " You have been invited to join the Keenthemes team from support@keenthemes.com . To get started, accept the invite below:" />

                     <Button url="https://keenthemes.com/account/confirm/07579ae29b6?email=max%40kt.com" name="Accept Invite" />
                    
                    <ImgContent />

                    <ImageSec />

                     <Heading title="Join us today!" />

                    <Paragraph description="Joining the team will give you access to the team's dashboard,
                      including information about our products." />
 
                     {/*end:Email content*/}

                     <Regards regardsHead="Kind regards" name="Utho Platforms Private Limited" email="" mobile="" />
                     
                  </div>
                </td>
              </tr>
        </>
    )
}