import React, { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export default function VpcSwitch() {
  const navigate = useNavigate();
  const location = useLocation();

  console.log(location);
  
  function redirectToPrev() {
    const currentPath = location.pathname;  // e.g., '/switch/vpc/internetgateways'
    
    // Remove '/switch' from the URL
    const newPath = currentPath.replace('/switch', '');

    // Navigate to the new URL (e.g., '/vpc/internetgateways')
    navigate(newPath);
}

    useEffect(() => {
      redirectToPrev();
    }, [location])
    return (
      <></>
      // <div>process</div>
    )
}
