import React, { useState, useEffect, useRef, useCallback } from "react";
import UsePost from "../../../../CustomHooks/usePost";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UseApi from "../../../../CustomHooks/useApi";
import { publicIp, publicIpv4, publicIpv6 } from 'public-ip';
import {
  Link,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import swal from "sweetalert";
import useRazorpay from "react-razorpay";
import spinner from "../billing/spinner.svg";
import UsePostData from "../../../../CustomHooks/usePostData";
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../Loaders/TableLoader";
import Dczone from "../../utils/dczone/Dczone";
import {InputValidation} from "../../utils/input-validation/InputValidation";
 
function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}
export default function VpnDeploy() {
  const forceUpdate = useForceUpdate();
  const [adcdata, setAdcdata] = useState([]);
  const [location, setLocation] = useState("innoida")
  const [ddcdata, setDdcdata] = useState([]);
  const [userData, setUserData] = useState(null);
  const [invoiceData, setInvoiceData] = useState(null);
  const [pageStatus, setPageStatus] = useState(false);
  const dataFetchedRef = useRef();
  const reRender = useRef(false);
  const navigate = useNavigate();

  const deployVpn = async () => {
    toast.dismiss();
    const dt = toast.loading("Please wait...");
    var submitBtn = document.querySelector("#vpn-btn");
    var accountlimit = document.querySelector("#accountlimit");
    if (submitBtn && accountlimit) {
      if(accountlimit.value<1){
        toast.update(dt, {
          render: "Minimum 1 vpn user required",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      } 
      setPageStatus(true);
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML =
        '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      const responseData = await UsePost("post", "vpn/deploy", "addvpn");
      if (responseData) {
        setPageStatus(false);
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
        if (responseData.status === "error") {
          toast.update(dt, {
            render: responseData.message,
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (responseData.status === "success") {
          toast.update(dt, {
            render: responseData.message,
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          window.onDeployEvents('deploy_vpn');
          openInvoice(responseData.invoiceid);
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", function (event) {
      event.stopPropagation();
      var query = window.location.href;
      var vars = query.split("/");
      var action = vars[4];
      //console.log(vars);
      if (event.key == "Enter" && action == "deploy" && vars[3] == "vpn") {
        deployVpn();
      }
    });
  }, []);

  const getDczone = async () => {
    // setAdcdata([]);
    // setDdcdata([]);
    const data = await UseApi("get", "dczones", "");
    const user = await UseApi("get", "account/info", "");
    setUserData(user.user);
    if (Object.keys(data).length === 0) {
      setAdcdata([]);
      setDdcdata([]);
    } else {
      vpnPlanDisplay();
      data.dczones.map(async (value, index) => {
        if (value.status != "active") {
          // fetch("https://api.ipify.org?format=json")
          //   .then((response) => response.json())
          //   .then((data) => {
              var server_ip = await publicIpv4();
              if (server_ip) {
                if (
                  server_ip !== "103.209.144.51" ||
                  server_ip !== "103.209.144.66" ||
                  server_ip !== "103.209.145.208" ||
                  server_ip !== "103.209.144.81" ||
                  server_ip !== "103.209.144.82" ||
                  server_ip !== "103.209.144.83" ||
                  server_ip !== "103.127.30.91" ||
                  server_ip !== "103.209.144.60" ||
                  server_ip !== "103.127.30.239" ||
                  server_ip !== "103.127.28.52"
                ) {
                  ddcdata.push(value);
                }
                forceUpdate();
              }
          // var client=await fetch(`https://geolocation-db.com/json/`)
          // .then(res => res.json());
          // var server_ip=client.IPv4;
          // if(server_ip !== '103.209.144.51' || server_ip !== '103.209.144.66' || server_ip !==
          //   '103.209.145.208' || server_ip !== '103.209.144.81' || server_ip !== '103.209.144.82' ||
          //   server_ip !== '103.209.144.83' || server_ip !== '103.127.30.91' || server_ip !==
          //   '103.209.144.60' || server_ip !== '103.127.30.239' || server_ip !== '103.127.28.52'){
          //     ddcdata.push(value);
          //   }
          //   forceUpdate();
        } else {
          adcdata.push(value);
          forceUpdate();
        }
      });
    }
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getDczone();
    setTimeout(() => {
      selectDc('innoida');
    }, 1000); 
  }, []);

  const vpnPlanDisplay = () => {
    const accountlimit = document.querySelector("#accountlimit");
    const billingcycle = document.querySelector("#billingcycle");
    const order_total = document.querySelector("#order_total");

    var finalVpnAmount = 0;
    var usrLimit = 0;
    var billCycle = 1;
    //console.log(userData);
    if (userData != null) {
      if (userData.currencyprefix == "$") {
        finalVpnAmount = 9.28;
      } else {
        finalVpnAmount = 650;
      }
      if (accountlimit && accountlimit.value) {
        usrLimit = parseFloat(accountlimit.value);
      }
      if (billingcycle && billingcycle.value) {
        if (billingcycle.value == "monthly") {
          billCycle = 1;
        } else if (billingcycle.value == "3month") {
          billCycle = 3;
        } else if (billingcycle.value == "6month") {
          billCycle = 6;
        } else if (billingcycle.value == "12month") {
          billCycle = 12;
        } else if (billingcycle.value == "24month") {
          billCycle = 24;
        } else if (billingcycle.value == "36month") {
          billCycle = 36;
        }
      }
      finalVpnAmount *= billCycle * usrLimit;
      if (order_total) {
        order_total.innerHTML = userData.currencyprefix + " " + finalVpnAmount;
      }
    }
  };

  const openInvoice = async (id) => {
    toast.dismiss();
    const invoice = await UseApi("get", "billing/" + id, "");
    setInvoiceData(invoice.invoices[0]);
    ////console.log(invoice);
    const transTabel = document.querySelector("#trans-tabel");
    const invoiceCredit = document.querySelector("#invoice-credit");
    var userAmount = 0;
    if (userData != null) {
      userAmount = userData.availablecredit;
    }
    if (invoice.invoices[0]) {
      if (transTabel) {
        if (invoice.invoices[0].transs.length <= 0) {
          transTabel.classList.add("d-none");
        } else {
          transTabel.classList.remove("d-none");
        }
      }
      // //console.log(parseFloat(invoice.invoices[0].rowtotal));
      if (invoiceCredit) {
        if (parseFloat(userAmount) > parseFloat(invoice.invoices[0].rowtotal)) {
          invoiceCredit.classList.remove("d-none");
        } else {
          invoiceCredit.classList.add("d-none");
        }
      }
    }
    var drawerElement = document.querySelector("#invoice_drawer");
    drawerElement.classList.toggle("drawer-on");
    drawerElement.style.width = "1100px";
  };

  const closeDrawer = () => {
    var drawerElement = document.querySelector("#invoice_drawer");
    drawerElement.classList.toggle("drawer-on");
    drawerElement.style.width = "921px";
  };

  const applyCreditAmount = async (id) => {
    toast.dismiss();
    swal({
      title: "Are you sure?",
      text: "Once Applied, your available credit will reduce according to this amount!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const dt = toast.loading("Invoice processing...");
        const dnsp = await UsePost(
          "post",
          "billing/applycredit",
          "applycredit"
        );
        if (dnsp.status == "error") {
          toast.update(dt, {
            render: dnsp.message,
            type: "warning",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.update(dt, {
            render: dnsp.message,
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        swal({
          title: "Apply Credit",
          text: "Your not applied for the invoice!",
          icon: "success",
          button: "OK",
        });
      }
    });
  };

  const Razorpay = useRazorpay();
  const invoicePayment = useCallback(async () => {
    toast.dismiss();
    const payInvoiceBtn = document.querySelector("#invoice-payment-button");
    if (payInvoiceBtn) {
      payInvoiceBtn.value = "Loading...";
    }
    const u_data = await UseApi("get", "account/info", "");
    const uData = u_data.user;
    const u_razorpay_info = await UseApi("get", "mhcsecinfo", "");

    ////console.log(razorpay_info);
    const amountFund = document.querySelector("#invoiceAmountFund");
    const descp_invoice_id = document.querySelector("#invoice_id");
    var invoice_id = "";
    //console.log(descp_invoice_id.value);
    if (descp_invoice_id) {
      invoice_id = descp_invoice_id.value;
    }
    var rorder_id = "";
    var rfullname = "";
    var rmobile = "";
    var remail = "";
    var ramount = 0;
    var rcurrency = "";
    if (amountFund) {
      ramount = amountFund.value;
    }

    if (uData) {
      rorder_id = uData.razorpay_orderid;
      rfullname = uData.fullname;
      rmobile = uData.mobile;
      remail = uData.email;
      rcurrency = uData.currency;
    }
    // //console.log(rorder_id);
    // //console.log(rfullname);
    // //console.log(rmobile);
    // //console.log(remail);
    // //console.log(ramount);
    // //console.log(invoice_id);
    const options = {
      //key: "rzp_live_aeMFERiIAsYFFF",
      key: u_razorpay_info.razorpay_key,
      amount: ramount * 100,
      currency: "INR",
      name: "Utho Cloud",
      description: invoice_id,
      image: "https://www.utho.com/assets/favicon/apple-touch-icon.png",
      prefill: {
        name: rfullname,
        email: remail,
        contact: rmobile,
      },
      options: {
        checkout: {
          method: {
            netbanking: 1,
            card: 0,
            upi: 1,
          },
        },
      },
      handler: async (res) => {
        //res.razorpay_payment_id
        //res.status_code
        //pay_L0C8DacFM1Voqi
        //console.log(res);
        if (res.status_code == 200) {
          swal({
            title: "Processing please wait...",
            icon: spinner,
            html: true,
            showSpinner: true,
          });
          const resData = await UsePostData("post", "billing/addpayment", {
            platform: "2023",
            invoiceid: invoice_id,
            transid: res.razorpay_payment_id,
            amount: ramount,
            currency: rcurrency,
            gateway: "Razorpay",
          });
          if (resData.status == "success") {
            swal({
              title: "Invoice",
              text: "Invoice paid successfuly",
              icon: "success",
              button: "OK",
            });
            setTimeout(() => {
              navigate("/vpn");
            }, 4000);
          } else {
            swal({
              title: "Invoice",
              text: "Invoice not paid!",
              icon: "warning",
              button: "OK",
            });
          }

          //console.log(resData);
          // const paymentInfo=await axios.get('https://'+u_razorpay_info.razorpay_key+':'+u_razorpay_info.razorpay_secret+'@api.razorpay.com/v1/payments/'+res.razorpay_payment_id+'');
          // //console.log(paymentInfo);
        }
      },
      notes: {
        address: "Utho Cloud- Fund add",
      },
      theme: {
        color: "#377dff",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
    if (payInvoiceBtn) {
      payInvoiceBtn.value = "Pay";
    }
  }, [Razorpay]);

  const selectDc = async (slug) => {
    var dczone_slug = document.querySelector("#dcslug" + slug);
    if (dczone_slug) {
      dczone_slug.value = slug;
    }
    setLocation(slug)
    var dcSelect = document.querySelectorAll([".tickMark"]);
    var currentDc = document.querySelector("#tickMark-" + slug);
    if (dcSelect) {
      dcSelect.forEach((clist) => {
        clist.classList.add("d-none");
      });
    }
    if (currentDc) {
      currentDc.classList.remove("d-none");
    }
  }

useEffect(()=>{
  vpnPlanDisplay();
},[userData])

  return (
    <>
      {adcdata.length > 0 ? (
        <>
          {/*begin::Tables Widget 9*/}
          <form id="addvpn" className="form">
            <div className="col-xl-12 card mb-5">
            {/*begin::data center*/}
            <Dczone adcdata={adcdata} ddcdata={ddcdata} selectDc={selectDc} location={location}/>
            {/* End data center */}
            </div>
            <div className="col-xl-12 card mb-5">
              <div className="card-header border-3 mb-5 pt-2">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label  fw-bold fs-3 mb-1">
                    Select VPN Users
                  </span>
                  <span className="text-muted mt-1 fw-bold fs-7">
                    VPN Name/VPN Users/Billing
                  </span>
                </h3>
              </div>
              <div className="container pb-5">
                {/*begin::Row*/}
                <div
                  id="define-vpc"
                  className="row g-9"
                  data-kt-buttons="true"
                  data-kt-buttons-target="[data-kt-button]"
                >
                  <div className="col-lg-4 col-md-4 col-xxl-4 fw-bold">
                    <label htmlFor="vpcName">VPN Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      id="name"
                    />
                  </div>
                  <div className="col-lg-4 col-md-4 col-xxl-4 fw-bold">
                    <label htmlFor="vpcName">VPN Users</label>
                    <input
                      type="hidden"
                      name="planid"
                      id="planid"
                      defaultValue={1008}
                    />
                    <input
                      type="text"
                      className="form-control"
                      name="accountlimit"
                      id="accountlimit" 
                      onChange={(e)=>{
                        InputValidation(e, "number")
                        vpnPlanDisplay()
                      }}
                      defaultValue={1}
                      min={1}
                    />
                  </div>
                  <div className="col-lg-4 col-md-4 col-xxl-4 fw-bold">
                    <label htmlFor="vpcName">Billing Cycle</label>
                    <select
                      onChange={vpnPlanDisplay}
                      className="form-select"
                      id="billingcycle"
                      name="billingcycle"
                    >
                      {/* <option value='' disabled selected>Monthly/Yearly</option> */}
                      <option value="monthly" data-billing={1}>
                        Monthly
                      </option>
                      <option value="3month" data-billing={3}>
                        3 Months
                      </option>
                      <option value="6month" data-billing={6}>
                        6 Months
                      </option>
                      <option value="12month" data-billing={12}>
                        1 Year
                      </option>
                      {/* <option value="24month" data-billing={24}>
                        2 Years
                      </option>
                      <option value="36month" data-billing={36}>
                        3 Years
                      </option> */}
                    </select>
                  </div>
                  {/* <input 
                  type="hidden"
                  className="billc"
                  defaultValue={1}
                /> */}
                </div>
                {/*end::Row*/}
              </div>
            </div>

            <div className="col-xl-12 card mb-5">
              <div
                className="card-header  mb-5 pt-2"
                style={{ border: "none" }}
              >
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold fs-3 mb-1">
                    Total Cost
                  </span>
                  <span
                    className="fs-xl-2x fs-2 fw-bold text-dark"
                    id="order_total"
                  >
                    {userData ? userData.currencyprefix : ""} 0
                  </span>
                </h3>
                <div className="card-toolbar">
                  <a
                    style={{ width: "100%" }}
                    onClick={deployVpn}
                    id="vpn-btn"
                    className="btn btn-sm btn-primary btn-active-light-primary"
                  >
                    Deploy VPN &nbsp; &nbsp;
                    <span className="svg-icon svg-icon-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z"
                          fill="black"
                        />
                        <path
                          opacity="0.3"
                          d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </form>
          {/* Invoice start */}

          <div
            id="invoice_drawer"
            className="bg-white drawer drawer-end"
            data-kt-drawer="true"
            data-kt-drawer-overlay="true"
            data-kt-drawer-activate="true"
            data-kt-drawer-width="1200px"
            style={{ width: "1200px !important" }}
          >
            <div className=" container-fluid">
              {/*begin::Body*/}
              <div className=" p-5">
                {/*begin::Layout*/}
                <div className="d-flex flex-column flex-xl-row">
                  {/*begin::Content*/}
                  <div className="flex-lg-row-fluid me-xl-18 mb-10 mb-xl-0">
                    {/*begin::Invoice 2 content*/}
                    <div className="mt-n1">
                      {/*begin::Top*/}
                      <div className="d-flex flex-stack pb-10">
                        {/*begin::Logo*/}
                        <a>
                          <img
                            alt="Logo"
                            src="https://www.utho.com/images/logo-dark-registered-cloud-blue.png"
                            width="200px"
                          />
                        </a>
                        {/*end::Logo*/}
                        {/*begin::Action*/}
                        <div id="payinvoice" />
                        <div id="paidinvoice" className="">
                          {invoiceData != null &&
                          invoiceData.status == "Paid" ? (
                            <>
                              <button className="btn btn-success me-5">
                                Paid
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                className="btn btn-success me-5"
                                id="invoice-payment-button"
                                onClick={invoicePayment}
                              >
                                Pay
                              </button>
                            </>
                          )}

                          <button
                            className="btn btn-light-danger me-5"
                            onClick={openInvoice}
                          >
                            close
                          </button>
                        </div>

                        {/*end::Action*/}
                      </div>
                      {/*end::Top*/}
                      {/*begin::Wrapper*/}
                      <div className="m-0">
                        {/*begin::Label*/}
                        <div className="fw-bolder fs-3 text-gray-800 mb-8">
                          Invoice{" "}
                          <span>
                            {invoiceData != null && invoiceData.id
                              ? invoiceData.id
                              : ""}
                          </span>
                        </div>
                        {/*end::Label*/}
                        {/*begin::Row*/}
                        <div className="row g-5 mb-11">
                          {/*end::Col*/}
                          <div className="col-sm-6">
                            {/*end::Label*/}
                            <div className="fw-bold fs-7 text-gray-600 mb-1">
                              Issue Date:
                            </div>
                            {/*end::Label*/}
                            {/*end::Col*/}
                            <div
                              className="fw-bolder fs-6 text-gray-800"
                              id="invoice-date"
                            >
                              {invoiceData != null && invoiceData.date
                                ? invoiceData.date
                                : ""}
                            </div>
                            {/*end::Col*/}
                          </div>
                          {/*end::Col*/}
                          {/*end::Col*/}
                          <div className="col-sm-6"></div>
                          {/*end::Col*/}
                        </div>
                        {/*end::Row*/}
                        {/*begin::Row*/}
                        <div className="row g-5 mb-12">
                          {/*end::Col*/}
                          <div className="col-sm-6">
                            {/*end::Label*/}
                            <div className="fw-bold fs-7 text-gray-600 mb-1">
                              Issue For:
                            </div>
                            {/*end::Label*/}
                            {/*end::Text*/}
                            <div
                              className="fw-bolder fs-6 text-gray-800"
                              id="company_name"
                            >
                              {userData != null && userData.company
                                ? userData.company
                                : ""}
                            </div>
                            {/*end::Text*/}
                            {/*end::Description*/}
                            <div className="fw-bold fs-7 text-gray-600">
                              <span id="user-address">
                                {userData != null && userData.address
                                  ? userData.address + ", "
                                  : ""}{" "}
                                {userData != null && userData.city
                                  ? userData.city + ", "
                                  : ""}{" "}
                                {userData != null && userData.country
                                  ? userData.country + ", "
                                  : ""}
                              </span>
                              <br />
                              <span id="user-mobile">
                                {userData != null && userData.mobile
                                  ? userData.mobile
                                  : ""}
                              </span>
                              <br />
                              <span id="user-gst">
                                {userData != null && userData.gstnumber
                                  ? userData.gstnumber
                                  : ""}
                              </span>
                            </div>
                            {/*end::Description*/}
                          </div>
                          {/*end::Col*/}
                          {/*end::Col*/}
                          <div className="col-sm-6 text-end">
                            {/*end::Label*/}
                            <div className="fw-bold fs-7 text-gray-600 mb-1">
                              Issued By:
                            </div>
                            {/*end::Label*/}
                            {/*end::Text*/}
                            <div className="fw-bolder fs-6 text-gray-800">
                              Utho Platforms Private Limited
                            </div>
                            {/*end::Text*/}
                            {/*end::Description*/}
                            <div className="fw-bold fs-7 text-gray-600">
                              Utho Cloud
                              <br />
                              HD-204, WeWork, Berger Delhi One, Sector 16B, C-001/A2, Gautam Buddha Nagar, Noida 201301 Uttar Pradesh - India
                              <br />
                              <strong>GSTIN:</strong> 09AAICM9463A1ZB
                              <br />
                              <strong>PAN Number:</strong> AAICM9463A
                              <br />
                              <strong>SAC:</strong> 998315
                            </div>
                            {/*end::Description*/}
                          </div>
                          {/*end::Col*/}
                        </div>
                        {/*end::Row*/}
                        {/*begin::Content*/}
                        <div className="flex-grow-1">
                          {/*begin::Table*/}
                          <div className="table-responsive border mb-9">
                            <table className="table mb-3 table-row-bordered">
                              <thead className="bg-secondary">
                                <tr className="border-bottom fs-6 fw-bolder">
                                  <th className="w-10px" />
                                  <th className="w-175px pb-2 ">
                                    Item Description
                                  </th>
                                  <th className="w-70px text-end pb-2" />
                                  <th className="w-80px text-end pb-2" />
                                  <th className="w-100px text-start pb-2">
                                    Amount
                                  </th>
                                </tr>
                              </thead>
                              <tbody id="item-list">
                                {invoiceData != null
                                  ? invoiceData.items != null
                                    ? invoiceData.items.map((value, index) => (
                                        <>
                                          <tr className="fw-bold text-gray-700">
                                            <td></td>
                                            <td className="d-flex align-items-center pt-6">
                                              {value.description}
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td className="pt-6 fw-bold">
                                              {userData != null &&
                                              userData.currencyprefix
                                                ? userData.currencyprefix
                                                : ""}{" "}
                                              {value.amount}
                                            </td>
                                          </tr>
                                        </>
                                      ))
                                    : ""
                                  : ""}
                              </tbody>
                            </table>
                          </div>
                          {/*end::Table*/}
                          {/*begin::Container*/}
                          <div className="d-flex row">
                            <div className="col-lg-8 col-md-8 col-xxl-8">
                              {userData != null &&
                              invoiceData != null &&
                              invoiceData.status != "Paid" &&
                              userData.credit !== undefined &&
                              userData.credit !== null &&
                              userData.credit != 0 ? (
                                <>
                                  <div
                                    className="d-flex flex-wrap flex-stack mb-6"
                                    id="invoice-credit"
                                  >
                                    {" "}
                                    <div className="d-flex my-2">
                                      {" "}
                                      <div className="d-flex align-items-center position-relative me-4">
                                        {" "}
                                        <form
                                          id="applycredit"
                                          name="applycredit"
                                        >
                                          {" "}
                                          <div
                                            className="input-group mb-5"
                                            id="wallet"
                                          >
                                            <input
                                              type="hidden"
                                              name="invoiceid"
                                              value={
                                                invoiceData != null &&
                                                invoiceData
                                                  ? invoiceData.id
                                                  : ""
                                              }
                                            />
                                            <span class="input-group-text">
                                              {userData.currencyprefix
                                                ? userData.currencyprefix
                                                : ""}
                                            </span>{" "}
                                            <input
                                              type="text"
                                              class="form-control"
                                              placeholder="amount"
                                              id="amount"
                                              name="amount"
                                              min="10"
                                              value={
                                                userData.credit >
                                                invoiceData.rowbalance
                                                  ? invoiceData.rowbalance
                                                  : userData.credit
                                              }
                                            />
                                            <a
                                              class="btn btn-primary btn-sm"
                                              id="applycredit-btn"
                                              onClick={applyCreditAmount}
                                            >
                                              Apply Credit
                                            </a>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>

                            <div className="col-lg-4 col-md-4 col-xxl-4">
                              {/*begin::Section*/}
                              <div className="mw-300px" id="invoice-final">
                                {/*begin::Item*/}{" "}
                                <div className="d-flex flex-stack mb-3">
                                  {/*begin::Accountname*/}
                                  <div className="fw-bold pe-10 text-gray-600 fs-7">
                                    Subtotal:
                                  </div>
                                  {/*end::Accountname*/}
                                  {/*begin::Label*/}
                                  <div
                                    className="text-end fw-bolder fs-6 text-gray-800"
                                    id="subtotal-amount"
                                  >
                                    {invoiceData != null && invoiceData.subtotal
                                      ? invoiceData.subtotal
                                      : ""}
                                  </div>
                                  {/*end::Label*/}
                                </div>
                                {/*end::Item*/}
                                {invoiceData != null &&
                                invoiceData.taxrate != "0.00" &&
                                invoiceData.taxrate2 == "0.00" ? (
                                  <>
                                    {/*begin::Item*/}{" "}
                                    <div className="d-flex flex-stack mb-3">
                                      {/*begin::Accountname*/}
                                      <div className="fw-bold pe-10 text-gray-600 fs-7">
                                        {invoiceData.taxrate}% IGST
                                      </div>
                                      {/*end::Accountname*/}
                                      {/*begin::Label*/}
                                      <div className="text-end fw-bolder fs-6 text-gray-800">
                                        {userData != null &&
                                        userData.currencyprefix
                                          ? userData.currencyprefix
                                          : ""}{" "}
                                        {invoiceData.tax}
                                      </div>
                                      {/*end::Label*/}
                                    </div>
                                    {/*end::Item*/}
                                  </>
                                ) : (
                                  ""
                                )}
                                {invoiceData != null &&
                                invoiceData.taxrate != "0.00" &&
                                invoiceData.taxrate2 != "0.00" ? (
                                  <>
                                    {/*begin::Item*/}{" "}
                                    <div className="d-flex flex-stack mb-3">
                                      {/*begin::Accountname*/}
                                      <div className="fw-bold pe-10 text-gray-600 fs-7">
                                        {invoiceData.taxrate}% CGST
                                      </div>
                                      {/*end::Accountname*/}
                                      {/*begin::Label*/}
                                      <div className="text-end fw-bolder fs-6 text-gray-800">
                                        {userData != null &&
                                        userData.currencyprefix
                                          ? userData.currencyprefix
                                          : ""}{" "}
                                        {invoiceData.tax2}
                                      </div>
                                      {/*end::Label*/}
                                    </div>
                                    {/*end::Item*/}
                                  </>
                                ) : (
                                  ""
                                )}
                                {invoiceData != null &&
                                invoiceData.taxrate2 != "0.00" ? (
                                  <>
                                    {/*begin::Item*/}{" "}
                                    <div className="d-flex flex-stack mb-3">
                                      {/*begin::Accountname*/}
                                      <div className="fw-bold pe-10 text-gray-600 fs-7">
                                        {invoiceData.taxrate}% SGST
                                      </div>
                                      {/*end::Accountname*/}
                                      {/*begin::Label*/}
                                      <div className="text-end fw-bolder fs-6 text-gray-800">
                                        {userData != null &&
                                        userData.currencyprefix
                                          ? userData.currencyprefix
                                          : ""}{" "}
                                        {invoiceData.tax2}
                                      </div>
                                      {/*end::Label*/}
                                    </div>
                                    {/*end::Item*/}
                                  </>
                                ) : (
                                  ""
                                )}
                                {invoiceData != null &&
                                invoiceData.credit != "0" ? (
                                  <>
                                    {/*begin::Item*/}{" "}
                                    <div className="d-flex flex-stack mb-3">
                                      {/*begin::Accountname*/}
                                      <div className="fw-bold pe-10 text-gray-600 fs-7">
                                        Credits
                                      </div>
                                      {/*end::Accountname*/}
                                      {/*begin::Label*/}
                                      <div className="text-end fw-bolder fs-6 text-gray-800">
                                        {userData != null &&
                                        userData.currencyprefix
                                          ? userData.currencyprefix
                                          : ""}{" "}
                                        {invoiceData.credit}
                                      </div>
                                      {/*end::Label*/}
                                    </div>
                                    {/*end::Item*/}
                                  </>
                                ) : (
                                  ""
                                )}
                                {/*begin::Item*/}{" "}
                                <div className="d-flex flex-stack mb-3">
                                  {/*begin::Accountname*/}
                                  <div className="fw-bold pe-10 text-gray-600 fs-7">
                                    Total
                                  </div>
                                  {/*end::Accountname*/}
                                  {/*begin::Label*/}
                                  <div
                                    className="text-end fw-bolder fs-6 text-gray-800"
                                    id="total-amount"
                                  >
                                    {userData != null && userData.currencyprefix
                                      ? userData.currencyprefix
                                      : ""}{" "}
                                    {invoiceData != null && invoiceData.total}
                                    <input
                                      type="hidden"
                                      id="invoiceAmountFund"
                                      value={
                                        invoiceData != null &&
                                        invoiceData.rowbalance
                                      }
                                    />
                                    <input
                                      type="hidden"
                                      id="invoice_id"
                                      value={
                                        invoiceData != null && invoiceData.id
                                      }
                                    />
                                  </div>
                                  {/*end::Label*/}
                                </div>
                                {/*end::Item*/}
                                {invoiceData != null &&
                                invoiceData.rowbalance > 0 ? (
                                  <>
                                    {/*begin::Item*/}{" "}
                                    <div className="d-flex flex-stack mb-3">
                                      {/*begin::Accountname*/}
                                      <div className="fw-bold pe-10 text-gray-600 fs-7">
                                        Balance
                                      </div>
                                      {/*end::Accountname*/}
                                      {/*begin::Label*/}
                                      <div
                                        className="text-end fw-bolder fs-6 text-gray-800"
                                        id="total-amount"
                                      >
                                        {userData != null &&
                                        userData.currencyprefix
                                          ? userData.currencyprefix
                                          : ""}{" "}
                                        {invoiceData != null &&
                                          invoiceData.rowbalance}
                                      </div>
                                      {/*end::Label*/}
                                    </div>
                                    {/*end::Item*/}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            {/*end::Section*/}
                          </div>
                          {/*end::Container*/}
                          <div id="trans-tabel" className="d-none">
                            <div className="fw-bolder fs-2">Transactions</div>
                            <table className="table mb-3 table-row-bordered">
                              <thead className="bg-secondary">
                                <tr className="border-bottom fs-6 fw-bolder">
                                  <th className="w-175px pb-2 text-center">
                                    Transaction Date
                                  </th>
                                  <th className="w-70px text-end pb-2">
                                    Transaction Gateways
                                  </th>
                                  <th className="w-80px text-end pb-2">
                                    Transaction id
                                  </th>
                                  <th className="w-100px text-center pb-2">
                                    Transaction Amount
                                  </th>
                                </tr>
                              </thead>
                              <tbody
                                className="fw-bold text-gray-600"
                                id="trans-list"
                              >
                                {invoiceData != null &&
                                  invoiceData.transs.map((value, index) => (
                                    <>
                                      <tr>
                                        <td class="text-center">
                                          {value.date}
                                        </td>
                                        <td class="text-end">
                                          {value.gateway}
                                        </td>
                                        <td class="text-end">
                                          {value.transid}
                                        </td>
                                        <td class="text-center">
                                          {userData != null &&
                                          userData.currencyprefix
                                            ? userData.currencyprefix
                                            : ""}{" "}
                                          {value.amountin
                                            ? value.amountin
                                            : value.amountout}
                                        </td>
                                      </tr>
                                    </>
                                  ))}
                              </tbody>
                            </table>
                            {/*end::Table*/}
                            <div id="due-price" style={{ marginTop: 25 }}>
                              <div class="d-flex justify-content-end">
                                <div class="mw-300px" id="invoice-final">
                                  <div class="d-flex flex-stack mb-3">
                                    <div class="fw-bold pe-10 text-danger-600 fs-7">
                                      Due Amount
                                    </div>
                                    <div class="text-end fw-bolder fs-6 text-danger-800">
                                      {userData != null &&
                                      userData.currencyprefix
                                        ? userData.currencyprefix
                                        : ""}{" "}
                                      {invoiceData != null
                                        ? invoiceData.balance
                                        : ""}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*end::Content*/}
                      </div>
                      {/*end::Wrapper*/}
                    </div>
                    {/*end::Invoice 2 content*/}
                  </div>
                  {/*end::Content*/}
                </div>
                {/*end::Layout*/}
              </div>
              {pageStatus && <TopBarHeaderLine />}
              {/*end::Body*/}
            </div>
          </div>

          {/* Invoice end */}
        </>
      ) : (
        <>
          <TopBarHeaderLine />
          <TableLoader />
        </>
      )}
    </>
  );
}
