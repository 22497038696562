import React, { useEffect, useRef, useState } from "react"; 
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; 
import swal from "sweetalert";  
import UseDevPost from "../../../../../CustomHooks/useDevPost";
import TableLoader from "../../../../../PartnerApp/Body/Loaders/TableLoader";
import TopBarHeaderLine from "../../../../../CustomHooks/TopBarHeaderLine";
export default function Resize(props) {
  
  var query = window.location.href; 
  var vars = query.split("/"); 
  var id=vars[4]; 

  const navigate=useNavigate();
  const navRedirect=()=>{
    setTimeout(() => {
      navigate('/archiveStorage');
     }, 2000);
      
  }  
  const [archiveInfo, setArchiveInfo]= useState(null);
useEffect(()=>{
  if(props){
    setArchiveInfo(props.data);
  }
},[props])
 


const resizeStorage=async()=>{ 
  var submitBtn=document.querySelector("#resize-btn"); 
 
  if(submitBtn){
    var preContent=submitBtn.innerHTML;
    submitBtn.classList.add("disabled");
    submitBtn.innerHTML='<span className="indicator-label">Resizing <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>'; 
  const dt=toast.loading("Please wait..."); 
  const responseData=await UseDevPost('put','archive/'+id+'/resize','resizeStorageForm');
  if(responseData){
    submitBtn.classList.remove("disabled");
    submitBtn.innerHTML=preContent;
    if(responseData.status==='error'){
      toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });
    }else if(responseData.status==='success'){
      toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });  
    
    var resizeForm=document.querySelector("#resizeStorageForm");
    var storagePrice= document.querySelector("#storagePrice");

    if(resizeForm && storagePrice){
      storagePrice.classList.add("d-none");
      resizeForm.reset();
    }
    if(props){
      props.updateArchive();
      }
  }
}
    }
  }


  

const calculateStoragePrice=(size)=>{

  var storagePrice= document.querySelector("#storagePrice");
  if(storagePrice){
    if(size!=undefined || size!=0 || size!=null || size!=''){
      if(size>10240){ 
        swal({
            title: "Invalid size",
            text: "This volume can range from 10 GB to 10240 GB in size.!",
            icon: "warning",
            button: "OK",
          });
          var resizeForm=document.querySelector("#resizeStorageForm");  
          if(resizeForm && storagePrice){
            storagePrice.classList.add("d-none");
            resizeForm.reset();
          }
          return;
       }else{
    var orgPrice=size*5; 
       }
    } 
    storagePrice.classList.remove("d-none");
    storagePrice.innerHTML="Rs."+orgPrice;
    
  }
}

const handleSubmit = (e) => {
  e.preventDefault();
  // Perform your form submission logic here
  resizeStorage();
};
if(archiveInfo!=null){
  return (
      <>  
        <div className="tab-pane fade" id="access-control" role="tabpanel">
          <div className="card">
            <div
              className="card-header border-0 cursor-pointer"
              role="button"
              data-bs-toggle="collapse"
              data-bs-target="#kt_account_deactivate"
              aria-expanded="true"
              aria-controls="kt_account_deactivate"
            >
              <div className="card-title m-0">
                <h3 className="fw-bolder m-0">Resize Storage</h3>
              </div>
            </div>
            <div id="kt_account_settings_deactivate" className="collapse show">
              <form id="resizeStorageForm" className="form" onSubmit={handleSubmit}>
                <div className="card-body border-top p-9">
                  <div className="notice d-flex bg-light-primary rounded border-primary border mb-9 p-6">
                    <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          opacity="0.3"
                          d="M22.0318 8.59998C22.0318 10.4 21.4318 12.2 20.0318 13.5C18.4318 15.1 16.3318 15.7 14.2318 15.4C13.3318 15.3 12.3318 15.6 11.7318 16.3L6.93177 21.1C5.73177 22.3 3.83179 22.2 2.73179 21C1.63179 19.8 1.83177 18 2.93177 16.9L7.53178 12.3C8.23178 11.6 8.53177 10.7 8.43177 9.80005C8.13177 7.80005 8.73176 5.6 10.3318 4C11.7318 2.6 13.5318 2 15.2318 2C16.1318 2 16.6318 3.20005 15.9318 3.80005L13.0318 6.70007C12.5318 7.20007 12.4318 7.9 12.7318 8.5C13.3318 9.7 14.2318 10.6001 15.4318 11.2001C16.0318 11.5001 16.7318 11.3 17.2318 10.9L20.1318 8C20.8318 7.2 22.0318 7.59998 22.0318 8.59998Z"
                          fill="black"
                        />
                        <path
                          d="M4.23179 19.7C3.83179 19.3 3.83179 18.7 4.23179 18.3L9.73179 12.8C10.1318 12.4 10.7318 12.4 11.1318 12.8C11.5318 13.2 11.5318 13.8 11.1318 14.2L5.63179 19.7C5.23179 20.1 4.53179 20.1 4.23179 19.7Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                    <div className="d-flex flex-stack flex-grow-1">
                      <div className="fw-bold">
                        <h4 className="text-gray-900 fw-bolder">
                          You can resize archive storage on your server..
                        </h4>
                        <div className="fs-6 text-gray-700">
                          This volume can range from 10 GB to 10240 GB in size.
                          <br />
                          The size of the current volume =  <span className="badge badge-primary">{archiveInfo.size} GB</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-5">
                      <div className="mb-1">
                        <label htmlFor="size" className="required form-label">
                          Please fill size in GB
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="size"
                          id="size" 
                          onChange={(e)=>calculateStoragePrice(e.target.value)}
                          placeholder={50}
                          defaultValue=""
                        />
                      </div>
                    </div>
                    <div className="col-4"></div>
                    <div className="col-3">
                      <div className="mt-13">
                        <label className="fs-1 fw-bolder text-gray-600 d-none" id="storagePrice">
                         
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" d-flex justify-content-start py-6 px-9">
                  <a id="resize-btn" onClick={resizeStorage}  className="btn btn-primary fw-bold">
                    Resize Storage
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>  
      </>); 
}else{
  return(<>
    <div>
    <TableLoader/>
    <TopBarHeaderLine/>
    </div>
    </>);
}
}
