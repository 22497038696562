import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import AccessDenied from "../../AccessDenied";
import DeployDns from "./DeployDns";
import { ActionTypes } from "@redux-devtools/core";
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../Loaders/TableLoader";
import CustomDoc from "../../../../CustomHooks/CustomDoc";
import ProductsUrl from "../../Docs/Docsdata";
export default function Dns() {
  var currentUrl = window.location.href;
  const [dataFetched, setDataFetched] = useState(false);
  const [access, setAccess] = useState(true);
  const [perPage, setPerPage] = useState(5);
  const [dnsData, setDnsData] = useState(null);
  const [dummyDnsData, setDummyDnsData] = useState(null);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [activePage, setActivePage] = useState(0);
  const dataFetchedRef = useRef(false);
  const [actionType, setActionType] = useState("page");
  const loaderFunc = () => {
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if (loader && no_record) {
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");

      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
  }
  const getDns = async () => {
    const dt = toast
    setDnsData(null);
    const data = await UseApi('get', 'dns', '');
    if (data) {
      setDataFetched(true);
    }
    if (data && Array.isArray) {
      if ('status' in data) {
        if (data.status == "error" && data.message == "Permission denied.") {
          toast.update(dt, {
            render: data.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          setDataFetched(true)
          setAccess(false);
          setDnsData([]);
          setDummyDnsData([])
          // loaderFunc();
          return;
        }
      }
    } else {
      setDataFetched(true)
      setDnsData([]);
      setDummyDnsData([])
      return
    }
    if (Object.keys(data).length === 0) {
      setDataFetched(true)
      setDnsData([]);
      setDummyDnsData([])
      toast.update(dt, {
        render: "no record found", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      // loaderFunc();
    } else {
      setDataFetched(true)
      setDnsData(data.domains);
      setDummyDnsData(data.domains)
      setPageCount(Math.ceil(data.domains.length / perPage));
      toast.dismiss();
      //   toast.update(dt,{render: "Your DNS request has been successful", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      // });  

    }
  }


  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    const urlSearchParams = new URLSearchParams(new URL(currentUrl).search);
    const actionValue = urlSearchParams.get('action');
    setActionType(actionValue);
    getDns();
  }, []);
  const dnsPoint = async (domain) => {
    toast.dismiss();
    const dt = toast.loading("Finding Name servers...");
    const dnsp = await UseApi('GET', 'dns/' + domain + '/checkns/', '');
    if (dnsp.status == 'error') {
      toast.update(dt, {
        render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
    } else {
      toast.update(dt, {
        render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
    }
    //window.location.href=domain;
  }

  function convertTimestamp(timestamp) {
    const date = new Date(timestamp);

    // Format the date
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
      timeZone: 'UTC'
    }).format(date);

    return formattedDate.replace('GMT', '(UTC)');
  }

  function ProductInfo({ productdata }) {
    return (
      <>
        {productdata != null && Object.keys(productdata).length != 0 ? (productdata && productdata.map((value, index) => (
          <tr>
            <td className="p-3 text-center d-none">
              <div className="d-flex text-center justify-content-center">
                <div className="d-flex  fw-bold text-center">{++index}</div>
              </div>
            </td>
            {/* <td></td> */}
            <td className="text-start ps-9">
              <a className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">
                {value.domain}
              </a>
            </td>
            <td></td>
            <td className="text-center">
              <span className="badge badge-secondary badge-lg">&nbsp;{value.dnsrecord_count}</span>
            </td>
            <td></td>
            <td className="text-center">
              <div className="d-flex" style={{ justifyContent: "center" }}>
                {value.nspoint === 'YES' ? (<span className="badge badge-success" style={{ marginRight: 10 }}> {value.nspoint} </span>) : (<span className="badge badge-danger" style={{ marginRight: 10 }}> No </span>)}
                <span className="badge badge-primary" style={{ cursor: "pointer" }}>
                  <a
                    id="checknsundefined-btn"
                    onClick={() => dnsPoint(value.domain)}
                  >
                    Check Now{" "}
                  </a>
                </span>
              </div>
            </td>
            <td></td>
            <td className="text-center">
              <span className="fs-7 fw-bold">{(value.created_at)}</span>
            </td>
            <td></td>
            <td className="text-end pe-8 sphide">
              {" "}
              {/* <div className="d-flex justify-content-center flex-shrink-0"> */}
              {" "}
              <Link to={"/dns/" + value.domain + ""}>
                <a
                  className="btn btn-primary btn-active-light-primary btn-sm"
                >
                  {" "}
                  Manage{" "}
                  <span className="svg-icon ms-2 svg-icon-3">
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      {" "}
                      <rect
                        opacity="0.5"
                        x={18}
                        y={13}
                        width={13}
                        height={2}
                        rx={1}
                        transform="rotate(-180 18 13)"
                        fill="black"
                      />{" "}
                      <path
                        d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                        fill="black"
                      />{" "}
                    </svg>{" "}
                  </span>{" "}
                </a>{" "}
              </Link>
              {/* </div> */}
            </td>
          </tr>
        ))) : (<>
          <tr>
            {/* <td colspan="12" className="dataTables_empty" id="loader"> */}
            {/* <div className="loading-spinner"></div> */}
            {/* <TableLoader /> */}
            {/* </td> */}
            <td colspan="12" className="" id="no_record" valign="top">
              <div className="d-flex flex-column flex-center">
                <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
              </div>
            </td>
          </tr>
        </>)}


      </>
    )
  }


  const handleSearch = (text) => {
    const endOffset = itemOffset + perPage;
    const data = dnsData.filter(row => {
      console.log(row);
      return Object.values(row).some(value => {
        if (value != null) {
          if (value.toString().toLowerCase().includes(text.toLowerCase())) {
            return value;
          }
        }
      });
    });
    setDummyDnsData(data);;
    setPageCount(Math.ceil(data.length / perPage));
    // }
  }

  function PaginatedItems({ itemsPerPage, ProductData }) {
    // Calculate the total number of pages based on itemsPerPage and ProductData
    const pageCount = Math.ceil(ProductData.length / parseInt(itemsPerPage));

    // Calculate the current end offset
    const endOffset = itemOffset + parseInt(itemsPerPage);

    // Handle page click
    const handlePageClick = (event) => {
      const selectedPage = event.selected;
      const newOffset = selectedPage * parseInt(itemsPerPage);
      setActivePage(selectedPage);
      setItemOffset(newOffset);
    };
    console.log(itemOffset, endOffset);

    return (
      <>
        <ProductInfo productdata={ProductData.slice(itemOffset, endOffset)} />
        {ProductData.length > perPage &&
          <td colspan="12" className="ps-3">
            <ReactPaginate
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              initialPage={activePage}
              previousLabel="< previous"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </td>
        }
      </>
    );
  }

  const handleItemsPerPageChange = (newItemsPerPage) => {
    // If the current page goes beyond the total page count, reset to the first page
    const newPageCount = Math.ceil(dummyDnsData.length / parseInt(newItemsPerPage));
    const newActivePage = activePage >= newPageCount ? 0 : activePage;

    // Set the new offset based on the current page and itemsPerPage
    const newOffset = newActivePage * parseInt(newItemsPerPage);

    // Update state
    setItemOffset(newOffset);
    setActivePage(newActivePage);
    setPerPage(newItemsPerPage);  // Update the itemsPerPage state
  };

  if (dataFetched) {
    if (access) {
      return (
        <>
          <div className="col-xl-12">
            {/*begin::Tables Widget 9*/}
            <div className="card card-xl-stretch mb-5 mb-xl-12">
              {/*begin::Header*/}
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold fs-3 mb-1">
                    DNS Management
                  </span>
                  <span className="text-muted mt-1 fw-bold fs-7">Manage your DNS</span>
                </h3>
                <div
                  className="card-toolbar"
                >
                  <div className="position-relative my-1" style={{ marginRight: 20 }}>
                    <CustomDoc url={ProductsUrl[0]?.dns + "/getting-started/"} />
                  </div>

                  <div className="position-relative my-1" style={{ marginRight: 20 }}>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      data-kt-table-widget-4="search"
                      id="searchClient"
                      style={{ padding: '0.6rem 0.6rem' }}
                      onChange={(e) => handleSearch(e.target.value)}
                      placeholder="Search"
                    />
                  </div>
                  <div style={{ marginRight: 20 }}>
                    <div className="dataTables_length">
                      <label className="w-125px">
                        <select
                          onChange={e => handleItemsPerPageChange(e.target.value)}
                          style={{ padding: '0.6rem 0.6rem' }}
                          //data-control="select2"
                          className="form-select form-select-sm form-select-solid"
                          defaultValue={5}
                          id="perPage"
                        >
                          <option value={5} selected="">
                            5 Records
                          </option>
                          <option value={10}>10 Records</option>
                          <option value={25}>25 Records</option>
                          <option value={50}>50 Records</option>
                          <option value={100}>100 Records</option>
                        </select>
                      </label>
                    </div>
                  </div>
                  {actionType != "page" ? (<>
                    <DeployDns type={actionType} updateDnsList={getDns} />
                  </>) : ('')}
                </div>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              {/*begin::Table container*/}
              <div className="table-responsive">
                {/*begin::Table*/}
                <table
                  id="dnsTable"
                  className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
                >
                  {/*begin::Table head*/}
                  <thead className="bg-secondary">
                    <tr className="fw-bold ">
                      <th className="w-150px text-start ps-9 sphide">Domain Name</th>
                      <th className="w-50px text-center"></th>
                      <th className="text-center w-100px sphide">DNS Record</th>
                      <th className="text-center w-20px"></th>
                      <th className="text-center w-100px">DNS Point</th>
                      <th className="text-center w-20px"></th>
                      <th className="text-center w-150px">Created_at</th>
                      <th className="text-center w-20px"></th>
                      <th className="w-100px text-end pe-20">Action</th>
                    </tr>
                  </thead>
                  {/*end::Table head*/}
                  {/*begin::Table body*/}
                  <tbody id="dns-list">

                    {dnsData != null ? (<>
                      {access == false ? (<>
                        <tr>
                          <td colspan={12}>
                            <AccessDenied />
                          </td>
                        </tr>
                      </>) : (<>
                        {Object.keys(dnsData).length != 0 ? (<>
                          <PaginatedItems ProductData={dummyDnsData} itemsPerPage={perPage} />
                        </>) : (<>
                          <tr>
                            <td colspan={12}>
                              <div className="card-body p-0 mt-10">
                                {/*begin::Illustration*/}
                                <div className="text-center px-4 mt-10">
                                  <span className="mw-100 mh-300px">
                                    <svg
                                      width="234px"
                                      height="234px"
                                      fill="#21325B"
                                      viewBox="0 0 32 32"
                                      id="icon"
                                      xmlns="http://www.w3.org/2000/svg"
                                      stroke="#21325B"
                                    >
                                      <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                                      <g id="SVGRepo_iconCarrier">
                                        {" "}
                                        <defs>
                                          {" "}
                                          <style
                                            dangerouslySetInnerHTML={{ __html: " .cls-1 { fill: none; } " }}
                                          />{" "}
                                        </defs>{" "}
                                        <path
                                          d="M16,2a8,8,0,1,0,8,8A8.0092,8.0092,0,0,0,16,2Zm5.91,7H19.4724a15.2457,15.2457,0,0,0-.7917-4.36A6.0088,6.0088,0,0,1,21.91,9ZM16.022,15.999h-.0076c-.3813-.1206-1.3091-1.8213-1.479-4.999h2.9292C17.2952,14.1763,16.3711,15.877,16.022,15.999ZM14.5354,9c.1694-3.1763,1.0935-4.877,1.4426-4.999h.0076c.3813.1206,1.3091,1.8213,1.479,4.999ZM13.3193,4.64A15.2457,15.2457,0,0,0,12.5276,9H10.09A6.0088,6.0088,0,0,1,13.3193,4.64ZM10.09,11h2.4373a15.2457,15.2457,0,0,0,.7917,4.36A6.0088,6.0088,0,0,1,10.09,11Zm8.59,4.36A15.2457,15.2457,0,0,0,19.4724,11H21.91A6.0088,6.0088,0,0,1,18.6807,15.36Z"
                                          transform="translate(0 0)"
                                        />{" "}
                                        <path
                                          d="M28,30H4a2.0021,2.0021,0,0,1-2-2V22a2.0021,2.0021,0,0,1,2-2H28a2.0021,2.0021,0,0,1,2,2v6A2.0021,2.0021,0,0,1,28,30ZM4,22v6H28V22Z"
                                          transform="translate(0 0)"
                                        />{" "}
                                        <circle cx={7} cy={25} r={1} />{" "}
                                      </g>
                                    </svg>


                                  </span>
                                </div>
                                {/*end::Illustration*/}
                                {/*begin::Wrapper*/}
                                <div className="card-px text-center py-5 mb-20">
                                  {/*begin::Title*/}
                                  <h1 className="fs-2x fw-bolder mb-5">DNS</h1>
                                  <h2 className="fs-1x fw-bolder mb-5 text-muted">
                                    Reliable, scalable and secure DNS service
                                  </h2>
                                  {/*end::Title*/}
                                  {/*begin::Description*/}
                                  <p className="text-gray-400 fs-4 fw-bold mb-10">
                                    Easy and cost-effective to connect user requests to your applications. <br />Navigate the web smoothly.</p>
                                  {/*end::Description*/}
                                  {/*begin::Action*/}
                                  <Link to="/dns/deploy">
                                    <a
                                      href="#"
                                      className="btn btn-primary mb-10"
                                    >
                                      Create DNS
                                    </a>
                                  </Link>
                                  {/*end::Action*/}
                                </div>
                                {/*end::Wrapper*/}
                              </div>
                            </td>
                          </tr>
                        </>)}
                      </>)}
                    </>) : (<>
                      <tr>
                        <td colspan={12}>
                          <TableLoader />
                          <TopBarHeaderLine />
                        </td>
                      </tr>

                    </>)}
                  </tbody>
                  {/*end::Table body*/}
                </table>
                {/*end::Table*/}
              </div>
              {/*end::Table container*/}
              {/*begin::Body*/}
            </div>
            {/*end::Tables Widget 9*/}
          </div>

        </>
      )
    } else {
      return (
        <>
          <AccessDenied />
        </>
      )
    }
  } else {
    return (
      <>
        <TableLoader />
      </>
    )
  }
}