import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import AccessDenied from "../../AccessDenied";
import UseDevApi from "../../../../CustomHooks/useDevApi";
import TableLoader from "../../../../PartnerApp/Body/Loaders/TableLoader";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";

export default function ArchiveStorage(){
  const [dataFetched, setDataFetched] = useState(false);
  const [access, setAccess]=useState(true);
  const [perPage, setPerPage]=useState(5);
  const [archiveData,setArchiveData]=useState(null);
  const dataFetchedRef=useRef (false);
  const loaderFunc=()=>{
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if(loader && no_record){
    no_record.classList.add("d-none");
    loader.classList.remove("d-none");

    setTimeout(() => {
      loader.classList.add("d-none");
      no_record.classList.remove("d-none");
    }, 100);
  }
  }
  const getArchiveStorage=async()=>{ 
    const dt=toast.loading("Archive Storage loading..."); 
       const data=await UseDevApi('get','archive','');
       if(data){
        setDataFetched(true);
       }
       if('status' in data){
        if(data.status=="error"){ 
           toast.update(dt,{render: data.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });  
          setAccess(false);
          setArchiveData(null); 
          loaderFunc(); 
          return;
        }
     }
       if(Object.keys(data).length === 0){
        setArchiveData(null);
             toast.update(dt,{render: "no record found", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            }); 
            loaderFunc();
       }else{
        setArchiveData(data.archiveStorage);  
        toast.dismiss();  
          
       }
  }
  useEffect(()=>{
    if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
      toast.dismiss(); 
      getArchiveStorage(); 
  },[]); 

  function ProductInfo({ productdata }) { 
    return(
        <> 
 
        {productdata!=null && Object.keys(productdata).length!=0?(productdata && productdata.map((value, index) => (
         <tr>
          <td className="text-gray-700 fw-bold fs-6 text-center"> {value.id}</td>
         <td className="text-start">
           <div className="align-items-start">
             {" "}
             <div className="d-flex justify-content-center flex-column">
               {" "}
               <a className="text-gray-700 fw-bold text-hover-primary fs-6">
                 {value.label}
               </a>{" "}
             </div>{" "}
           </div>
         </td>
         <td className="text-center">
           <div className="d-flex align-items-center sphide ms-10">
             {" "}
             <div className="symbol symbol-30px me-5">
               {" "}
               {value.location && value.location.dczones?(<>
                <img src={"/img/flag_"+value.location.dczones[0].cc+".png"} />
                  </>):('')}
               
             </div>{" "}
             <div className="d-flex justify-content-start flex-column">
               {" "}
               <a className="text-gray-700 fw-bold text-hover-primary fs-6 text-start">
                 {value.location && value.location.dczones?(<>
                 {value.location.dczones[0].city}
                 </>):('')}
               </a>{" "}
               <span className="text-muted fw-bold text-muted d-block fs-7 text-start">
               {value.location && value.location.dczones?(<>
                 {value.location.dczones[0].country}
                 </>):('')}
               </span>{" "}
             </div>{" "}
           </div>
         </td>
         <td className="text-start">
         <div className="d-flex align-items-center">
             {" "}
             <div className="d-flex justify-content-start flex-column">
               {" "}
               <a className="text-gray-700 fw-bold text-hover-primary fs-6">
                 {value.size} GB
               </a> 
             </div>{" "}
           </div> 
         </td> 
         <td className="text-center">
         <div className=" align-items-center"> 
             <div className="justify-content-center flex-column ">
              {value.status=="active"?(<>
                <span className="fw-bold fs-6 text-gray-700"><span className="status-circle"></span>
               Active
               </span> 
              </>):(<>
                <span className="fw-bold fs-6 text-gray-700"><span className="status-circle-danger"></span>
               Inactive
               </span> 
              </>)}
                  
             </div>{" "}
           </div> 
         </td> 
         <td className="text-center">
         <div className=" align-items-center">
             {" "}
             <div className="d-flex justify-content-center flex-column">
               {" "}
               <a className="text-gray-700 fw-bold text-hover-primary fs-6">
               cloud server- <Link to={"/cloud/"+value.cloudid}><span className="text-primary">{value.cloudid}</span></Link>
               </a> 
             </div>{" "}
           </div> 
         </td>  
         <td className="text-center">
           <div className="d-flex justify-content-center flex-shrink-0">
             {" "}
             {value.status=="active"?(<>
             <Link to={"/ArchiveStorage/"+value.id}>
               <a className="btn btn-primary btn-active-light-primary btn-sm me-1 me-5 ">
                 {" "}
                 Manage{" "}
                 <span className="svg-icon ms-2 svg-icon-3">
                   {" "}
                   <svg
                     xmlns="http://www.w3.org/2000/svg"
                     width={24}
                     height={24}
                     viewBox="0 0 24 24"
                     fill="none"
                   >
                     {" "}
                     <rect
                       opacity="0.5"
                       x={18}
                       y={13}
                       width={13}
                       height={2}
                       rx={1}
                       transform="rotate(-180 18 13)"
                       fill="black"
                     />{" "}
                     <path
                       d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                       fill="black"
                     />{" "}
                   </svg>{" "}
                 </span>{" "}
               </a>{" "}
             </Link>
             </>):(<>
              <a className="btn btn-primary btn-active-light-primary btn-sm me-1 me-5 disabled">
                 {" "}
                 Manage{" "}
                 <span className="svg-icon ms-2 svg-icon-3">
                   {" "}
                   <svg
                     xmlns="http://www.w3.org/2000/svg"
                     width={24}
                     height={24}
                     viewBox="0 0 24 24"
                     fill="none"
                   >
                     {" "}
                     <rect
                       opacity="0.5"
                       x={18}
                       y={13}
                       width={13}
                       height={2}
                       rx={1}
                       transform="rotate(-180 18 13)"
                       fill="black"
                     />{" "}
                     <path
                       d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                       fill="black"
                     />{" "}
                   </svg>{" "}
                 </span>{" "}
               </a>{" "}
             </>)}
           </div>{" "}
         </td>
       </tr>  
           ))):( <>
            <tr>
                     <td colspan="12" className="dataTables_empty" id="loader">
                      <div className="loading-spinner"></div>
                    </td>
                    <td colspan="12" className="d-none" id="no_record" valign="top">
                      <div className="d-flex flex-column flex-center"> 
                        <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                      </div>
                    </td>
                    </tr>
            </>)}
            
        </>
    )
}


const [searchTerm,setSearchTerm]=useState('');
function PaginatedItems({ itemsPerPage, ProductData, search }) {
  // We start with an empty list of items. 
  const [pageCount, setPageCount] = useState(0);
  const [vpcData,setVpcData]=useState(null);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    
    const endOffset = itemOffset + itemsPerPage;


  //  ////console.log(`Loading items from ${itemOffset} to ${endOffset}`);
     if(ProductData!=null){
    //   var str="Noida";
   // ////console.log(ProductData);
    const data= ProductData.filter(row => {
     return  Object.values(row).some(value => { 
        if(value!=null){
          if(value.toString().toLowerCase().includes(search.toLowerCase())){
            return value;
          } 
        }
      });
    });
    //////console.log(data);
      // const data=ProductData.filter(item => 
      //        item.hostname.toLowerCase().includes(search.toLowerCase())
      //              ); 

                   if(data.length<=0){
                    loaderFunc();
                   }
    setVpcData(data.slice(itemOffset, endOffset));
    //setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / itemsPerPage));
    // alert(pageCount);
      }
  }, [itemOffset, itemsPerPage]);

  // Invoke when user click to request another page.
  
  const handlePageClick = (event) => { 
    const newOffset = event.selected * itemsPerPage % ProductData.length;
    ////console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setItemOffset(newOffset);
   
  };
  var searchEle=document.querySelector("#searchClient");
  if(searchEle){
    searchEle.focus();
  }
  return (
    <>
      <ProductInfo productdata={vpcData} />
      <td colspan="12">
      <ReactPaginate
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="< previous"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
      </td>
    </>
  );
} 
if(dataFetched){
  if(access==false){
    return (
      <>
       <AccessDenied />
      </>
    );
  }

  if(archiveData!=null){
    if(Object.keys(archiveData).length!=0){
return(
  <> 
  <div className="col-xl-12">
              <div className="card card-xl-stretch mb-5 mb-xl-12">
                <div className="card-header border-0 pt-5">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold fs-3 mb-1">
                    Archive Storage
                      <span className="svg-icon svg-icon-2 ms-3">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 84 84"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g
                            id="DBaaS-Icon-1C-BLK"
                            transform="translate(0.189181, -0.000000)"
                            fill="#a1a5b7"
                            fillRule="nonzero"
                          >
                            <path d="m66.7 48.9c-7.7 2.1-17.7 3.3-28.4 3.3s-20.8-1.2-28.4-3.3c-2.2-.6-4.2-1.3-5.9-2v22c0 5.2 15.3 9.4 34.3 9.4s34.3-4.2 34.3-9.4v-22c-1.8.8-3.7 1.4-5.9 2zm-6.3 19.8c-2.6 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6 4.6 2.1 4.6 4.6-2 4.6-4.6 4.6z" />
                            <path d="m38.3 5.1c-19 0-34.3 4.2-34.3 9.4v25.4c3.6 2.4 14.8 6.1 34.3 6.1 19.4 0 30.7-3.7 34.3-6.1v-25.4c-.1-5.2-15.4-9.4-34.3-9.4zm22.1 31.7c-2.6 0-4.6-2.1-4.6-4.6 0-2.6 2.1-4.6 4.6-4.6s4.6 2.1 4.6 4.6c.1 2.5-2 4.6-4.6 4.6zm-22.1-14.3c-20.9 0-32.3-4.9-32.3-7.4s11.4-7.4 32.3-7.4 32.3 4.9 32.3 7.4-11.5 7.4-32.3 7.4z" />
                          </g>
                        </svg>
                      </span>
                    </span>
                    <span className="text-muted mt-1 fw-bold fs-7">
                      Manage your Archive storage
                    </span>
                  </h3>
                  <div className="card-toolbar">
                    <div
                      className="position-relative my-1"
                      style={{ marginRight: 20 }}
                    >
                       <input
            type="text"
            className="form-control form-control-sm"
            data-kt-table-widget-4="search"
            id="searchClient"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            placeholder="Search"
          />
        </div>
        <div style={{ marginRight: 20 }}>
  <div className="dataTables_length">
    <label className="w-125px">
      <select  
        onChange={e => setPerPage(e.target.value)}
        //data-control="select2"
        className="form-select form-select-sm form-select-solid"
        defaultValue={5}
        id="perPage"
        
      >
        <option value={5} selected="">
          5 Records
        </option>
        <option value={10}>10 Records</option>
        <option value={25}>25 Records</option>
        <option value={50}>50 Records</option>
        <option value={100}>100 Records</option>
      </select>
    </label>
                      </div>
                    </div>
                    <Link to="/archiveStorage/deploy">
                      <a className="btn btn-sm btn-primary btn-active-light-primary">
                        <span className="svg-icon svg-icon-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              opacity="0.5"
                              x="11.364"
                              y="20.364"
                              width={16}
                              height={2}
                              rx={1}
                              transform="rotate(-90 11.364 20.364)"
                              fill="black"
                            />
                            <rect
                              x="4.36396"
                              y="11.364"
                              width={16}
                              height={2}
                              rx={1}
                              fill="black"
                            />
                          </svg>
                        </span>
                        Create Storage
                      </a>
                    </Link>
                  </div>
                </div>
                <div className="table-responsive">
                  <table
                    id="firewallTable"
                    className="table table-striped table-row-dashed table-row-gray-400 align-middle gs-0 gy-4"
                  >
                    <thead className="bg-secondary">
                      <tr className="fw-bold ">
                      <th className="w-100px text-center"># ID</th> 
                        <th className="w-200px text-center">Storage</th> 
                        <th className="w-225px p-3 text-center">Location</th>   
                        <th className="w-150px text-start">Size</th>
                        <th className="w-150px text-center">Status</th>
                        <th className="w-250px text-center">Linked to</th> 
                        <th className="w-250px text-center"></th>
                      </tr>
                    </thead>
                    <tbody>
  {archiveData!=null?(<>
<PaginatedItems itemsPerPage={perPage} ProductData={archiveData} search={searchTerm}  />
  </>):(<>
    <tr>
                     <td colspan="12" className="dataTables_empty" id="loader">
                      <div className="loading-spinner"></div>
                    </td>
                    <td colspan="12" className="d-none" id="no_record" valign="top">
                      <div className="d-flex flex-column flex-center"> 
                        <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                      </div>
                    </td>
                    </tr>
  </>)}
  </tbody>
                  </table>
                </div>
              </div>
            </div>
  </>
)
} else{
  return (
    <>
      <div className="col-xl-12">
        <div className="card">
          {/*begin::Card body*/}
          <div className="card-body p-0 mt-10">
            {/*begin::Illustration*/}
            <div className="text-center px-4 mt-10">
              <span className="mw-100 mh-300px">
              <svg   
              width="234px"
              height="234px" 
              version="1.1"
              id="_x32_"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 612 612"
              xmlSpace="preserve"
              fill="#21325B"
              stroke="#21325B"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth={0} />
              <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
              <g id="SVGRepo_iconCarrier">
                {" "}
                <style
                  type="text/css"
                  dangerouslySetInnerHTML={{ __html: " .st0{fill:#21325B;} " }}
                />{" "}
                <g>
                  {" "}
                  <rect
                    x="0.034"
                    y="0.787"
                    className="st0"
                    width="155.35"
                    height="94.936"
                  />{" "}
                  <rect
                    x="172.645"
                    y="0.787"
                    className="st0"
                    width="155.35"
                    height="94.936"
                  />{" "}
                  <rect x={0} y="112.959" className="st0" width="69.044" height="94.936" />{" "}
                  <rect
                    x="86.34"
                    y="112.985"
                    className="st0"
                    width="155.35"
                    height="94.936"
                  />{" "}
                  <path
                    className="st0"
                    d="M266.78,160.9l16.756-26.634l9.111,8.58c1.892-10.19,5.36-20.152,10.22-29.862h-43.916v60.279 C263.7,165.796,266.68,161.059,266.78,160.9z"
                  />{" "}
                  <path
                    className="st0"
                    d="M436.126,148.283c22.542,18.028,41.586,38.129,56.938,59.613h7.509v-94.936h-69.044v31.32 C433.046,145.611,434.47,146.959,436.126,148.283z"
                  />{" "}
                  <rect
                    x="0.034"
                    y="225.182"
                    className="st0"
                    width="155.35"
                    height="94.936"
                  />{" "}
                  <rect x={0} y="337.354" className="st0" width="69.044" height="77.675" />{" "}
                  <path
                    className="st0"
                    d="M368.494,54.627c4.479,0,8.996,1.003,13.068,2.908c9.836,4.517,16.654,14.16,17.653,25.083l1.193,13.106h100.2 V0.787h-155.35V62.87c3.902-2.562,7.324-4.24,10.097-5.302C359.441,55.639,363.904,54.627,368.494,54.627z"
                  />{" "}
                  <path
                    className="st0"
                    d="M419.957,168.501c-29.116-23.278-40.498-48.985-44.873-67.266c-3.502-14.682-1.656-16.258-1.656-16.258 c-0.16-1.753-1.252-3.262-2.827-3.986c-1.606-0.75-3.456-0.599-4.888,0.405c0,0-5.66,1.222-14.838,10.139 c-20.936,20.328-44.616,54.228-29.023,100.583c16.756,49.743,7.897,60.144-0.864,59.47c-11.37-0.868-13.106-7.872-11.29-29.668 c2.107-25.479-20.999-47.232-20.999-47.232s-35.901,57.076-63.891,97.904c-16.545,24.13-24.471,54.809-24.471,82.799h-0.561 c0,86.044,69.761,155.822,155.827,155.822c86.078,0,155.822-69.778,155.822-155.822 C517.358,280.64,476.759,213.939,419.957,168.501z M355.872,467.335c-47.461,0-85.969-38.492-85.969-85.968 c0-16.157,4.488-31.286,12.238-44.198c6.688,24.855,29.372,43.169,56.352,43.169c32.204,0,58.332-26.11,58.332-58.315 c0-6.153-0.965-12.086-2.735-17.657c28.298,14.076,47.75,43.254,47.75,77.001C441.84,428.843,403.365,467.335,355.872,467.335z"
                  />{" "}
                  <path
                    className="st0"
                    d="M177.959,320.118c4.522-22.748,13.279-44.35,25.496-62.167c7.021-10.24,14.577-21.568,21.943-32.77h-52.752 v94.936H177.959z"
                  />{" "}
                  <path
                    className="st0"
                    d="M173.884,337.379H86.34v77.675h97.814c-6.536-18.719-10.27-38.744-10.27-59.664V337.379z"
                  />{" "}
                </g>{" "}
              </g>
            </svg>
            

              </span>
            </div>
            {/*end::Illustration*/}
            {/*begin::Wrapper*/}
            <div className="card-px text-center py-5 mb-20">
              {/*begin::Title*/}
              <h1 className="fs-2x fw-bolder mb-5">Archive Storage</h1>
              <h2 className="fs-1x fw-bolder mb-5 text-muted">
              Efficient Data Management Solution
              </h2>
              {/*end::Title*/}
              {/*begin::Description*/}
              <p className="text-gray-400 fs-4 fw-bold mb-10">
              Archive storage provides a scalable and reliable data management solution. With its ability to allocate storage in fixed-sized blocks, it offers optimal flexibility and performance for storing and retrieving data. Harness the power of block storage to enhance your data infrastructure and streamline your operations.
              </p>
              {/*end::Description*/}
              {/*begin::Action*/}
              <Link to="/archive/deploy">
              <a
                href="#"
                className="btn btn-primary mb-10"
              >
                Create Archive Storage
              </a>
              </Link>
              {/*end::Action*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Card body*/}
        </div>
      </div>
    </>
  );
}
}
}else{
return (
<>
<TableLoader/>
<TopBarHeaderLine/>
</>
);
}
}