import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import Swal from 'sweetalert2';
import ReactPaginate from "react-paginate";
import UsePostData from "../../../../../../CustomHooks/usePostData";
const Backup = (props) => {
  const [pageStatus, setPageStatus] = useState(true)
  const [pageCount, setPageCount] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [itemOffset, setItemOffset] = useState(0);
  const [backupData, setBackupData] = useState(null);
  const [activePage, setActivePage] = useState(0);
  const [dummyBackupData, setDummyBackupData] = useState(null);
  const [currentDatabaseInfo, setCurrentDatabaseInfo] = useState(null);
  const loaderFunc = () => {
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if (loader && no_record) {
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");

      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
  }
  const copyBackupName = () => {
    var eleText = document.querySelector("#backup_file_url");
    var ele = document.querySelector("#backup_file_url_copy_btn");

    if (ele && eleText) {
      const copyText = eleText.innerText;
      const originalContent = ele.innerHTML;
      console.log(originalContent);

      navigator.clipboard.writeText(copyText).then(() => {
        ele.innerHTML = "(Copied)";

        setTimeout(() => {
          ele.innerHTML = "(copy)";
        }, 1000);
      }).catch(err => {
        console.error('Failed to copy text: ', err);
      });
    }
  };

  const getBackup = async () => {
    if (props) {
      if (props.backup_data && props.backup_data.backups) {
        setBackupData(props?.backup_data?.backups?.reverse());
        setDummyBackupData(props?.backup_data?.backups?.reverse())
        setCurrentDatabaseInfo(props.currentDatabaseInfo);
      }
    }
  }
  useEffect(() => {
    getBackup();
  }, [props])

  const restoreBackup = async (clusterid, backupid, dbname) => {
    toast.dismiss();
    const { value: isDelete } = await Swal.fire({
      title: 'Restore Database',
      html: `
          <div className="d-flex align-items-center flex-wrap"> 
          <div id="kt_clipboard_4" className="me-5 fw-bold text-gray-600">
            <p class="fs-5">Enter the Database name to restore:</p>
    
            <span class="fw-bold" id="backup_file_url" >${dbname} &nbsp;</span><span id="backup_file_url_copy_btn" style="cursor:pointer" class="text-primary"> ( copy ) </span> 
          </div>  
        </div>
       `,
      input: 'text',
      inputPlaceholder: 'Database name',
      showCancelButton: true,
      confirmButtonText: 'Restore',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'delete-button bg-danger', // Add your custom class here
      },
    });
    if (isDelete?.trim() == dbname) {
      setPageStatus(true)
      const dt = toast.loading("Backup restoring...");
      const dnsp = await UsePostData('POST', 'databases/' + clusterid + '/backup/' + backupid + '/restore', { 'dbname': dbname });
      if (dnsp.status == 'error') {
        setPageStatus(false)
        toast.update(dt, {
          render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
      } else {
        setPageStatus(false)
        toast.update(dt, {
          render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        getBackup();
      }
    } else {
      swal({
        title: "Invalid Database Name",
        text: "Please confirm the valid Database name or check the extra white space in the given instance name!",
        icon: "warning",
        button: "OK",
      });
    }

  }
  function handleButtonClick(event) {
    console.log(event.target);
    if (event.target && event.target.id === 'backup_file_url_copy_btn') {
      copyBackupName();
      event.stopPropagation();
    }
  }
  
  // Remove any existing listener before adding a new one (if necessary)
  document.removeEventListener('click', handleButtonClick);
  document.addEventListener('click', handleButtonClick);

  function convertTimestamp(timestamp) {
    const date = new Date(timestamp);

    // Format the date
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
      timeZone: 'UTC'
    }).format(date);

    return formattedDate.replace('GMT', '(UTC)');
  }

  const createBackup = async () => {
    var clusterid = '';
    var clustername = '';
    if (props && props.backup_data) {
      clustername = props.backup_data.cluster_name;
      clusterid = props.backup_data.id;
    }
    toast.dismiss();
    swal({
      title: "Create Backup",
      text: "This will create your databse backup",
      icon: "info",
      buttons: true,
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          setPageStatus(true)
          const dt = toast.loading("Backup creating...");
          const dnsp = await UsePostData('POST', 'databases/' + clusterid + '/backup', { 'clustername': clustername });
          if (dnsp.status == 'error') {
            setPageStatus(false)
            toast.update(dt, {
              render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
          } else {
            setPageStatus(false)
            toast.update(dt, {
              render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
            props.updateDatabase()
            getBackup();
          }
        }
      })

  }
  function ProductInfo({ productdata }) {
    return (
      <>
        {productdata != null && Object.keys(productdata).length != 0 ? (productdata && productdata.map((value, index) => (
          <tr>
            <td className="text-start ps-11">
              <div className="align-items-center w-100 me-2">
                <div className=" flex-stack mb-2">
                  <span className=" me-2 fs-6 fw-bold">{value.id}</span>
                </div>
              </div>
            </td>
            <td className="text-center">
              <div className=" flex-column w-100 me-2">
                <div className=" flex-stack mb-2">
                  <span className=" me-2 fs-6 fw-bold">{value.dbname}</span>
                </div>
              </div>
            </td>

            <td className="text-center">
              {value.status == "Created" ? (<>
                <div className=" flex-column w-100">
                  <div className=" flex-stack mb-2">
                    <span className=" me-2 fs-6 fw-bold">
                      <span className="status-circle" style={{ top: '3px' }}></span>
                      Created
                    </span>
                  </div>
                </div>
              </>) : (<>
                <span className="fw-bold fs-6 text-gray-700"><span className="status-circle-danger"></span>
                  Inactive
                </span>
              </>)}
            </td>
            <td className="text-center">
              <div className="flex-column w-100 me-2">
                <div className="flex-stack mb-2">
                  <span className="text-muted me-2 fs-6 fw-bold">
                    {convertTimestamp(value.created_at)}
                  </span>
                </div>
              </div>
            </td>

            {currentDatabaseInfo?.ref_type != "dbaas_node" ? (<>
              <td className="text-center ps-17">
                <div className="card-toolbar">
                  <a
                    onClick={(e) => restoreBackup(value.clusterid, value.id, value.dbname)}
                    className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                  >
                    <span className="svg-icon svg-icon-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M14.5 20.7259C14.6 21.2259 14.2 21.826 13.7 21.926C13.2 22.026 12.6 22.0259 12.1 22.0259C9.5 22.0259 6.9 21.0259 5 19.1259C1.4 15.5259 1.09998 9.72592 4.29998 5.82592L5.70001 7.22595C3.30001 10.3259 3.59999 14.8259 6.39999 17.7259C8.19999 19.5259 10.8 20.426 13.4 19.926C13.9 19.826 14.4 20.2259 14.5 20.7259ZM18.4 16.8259L19.8 18.2259C22.9 14.3259 22.7 8.52593 19 4.92593C16.7 2.62593 13.5 1.62594 10.3 2.12594C9.79998 2.22594 9.4 2.72595 9.5 3.22595C9.6 3.72595 10.1 4.12594 10.6 4.02594C13.1 3.62594 15.7 4.42595 17.6 6.22595C20.5 9.22595 20.7 13.7259 18.4 16.8259Z"
                          fill="black"
                        />
                        <path
                          opacity="0.3"
                          d="M2 3.62592H7C7.6 3.62592 8 4.02592 8 4.62592V9.62589L2 3.62592ZM16 14.4259V19.4259C16 20.0259 16.4 20.4259 17 20.4259H22L16 14.4259Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                  </a>
                </div>
              </td>
            </>) : ('')}
          </tr>
        ))) : (<>
          <tr>
            <td colspan="12" className="dataTables_empty" id="loader">
              <div className="loading-spinner"></div>
            </td>
            <td colspan="12" className="d-none" id="no_record" valign="top">
              {/* <div className="d-flex flex-column flex-center">
                <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
              </div> */}
              <div className="mt-20 mb-20 text-center">
                <span className="fs-6 text-gray-800 fw-bold">no record found</span>
              </div>
            </td>
          </tr>
        </>)}

      </>
    )
  }

  const handleSearch = (text) => {
    const endOffset = itemOffset + perPage;
    // Remove special characters from the input text
    const escapedText = text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    // Create a regular expression pattern to match the filtered text
    const regex = new RegExp(escapedText, "gi");

    // Filter the clusterData based on the regex pattern
    console.log(backupData);
    let data = backupData.filter((row) => {
      // Check if any property in the row matches the regex pattern
      return (
        row.dbname && row.dbname.toString().toLowerCase().includes(text.toLowerCase())
      )
    });

    // Update the state with the filtered data
    setDummyBackupData(data);;
    setPageCount(Math.ceil(data.length / perPage));
  }

  function PaginatedItems({ itemsPerPage, ProductData }) {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(ProductData.length / itemsPerPage));
    const handlePageClick = (event) => {
      setActivePage((old) => old = event.selected);
      const newOffset = event.selected * itemsPerPage % ProductData.length;
      setItemOffset(newOffset);
    };

    return (
      <>
        <ProductInfo productdata={ProductData.slice(itemOffset, endOffset)} />
        <td colspan="12">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            initialPage={activePage}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </td>
      </>
    );
  }
  return (
    <>
      <div className="card">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold fs-3 mb-1">
                Database Backups
              </span>
              <span className="text-muted mt-1 fw-bold fs-6">View your Backups</span>
            </h3>
          </div>
          <div className="card-toolbar">
            <div
              className="position-relative my-1"
              style={{ marginRight: 20 }}
            >
              <input
                type="text"
                className="form-control form-control-sm"
                data-kt-table-widget-4="search"
                id="searchClient"
                // value={searchTerm}
                onChange={e => handleSearch(e.target.value)}
                placeholder="Search"
              />
            </div>

            <div style={{ marginRight: 20 }}>
              <div className="dataTables_length">
                <label className="w-125px">
                  <select
                    onChange={e => setPerPage(e.target.value)}
                    //data-control="select2"
                    className="form-select form-select-sm form-select-solid"
                    defaultValue={5}
                    id="perPage"

                  >
                    <option value={5} selected="">
                      5 Records
                    </option>
                    <option value={10}>10 Records</option>
                    <option value={25}>25 Records</option>
                    <option value={50}>50 Records</option>
                    <option value={100}>100 Records</option>
                  </select>
                </label>
              </div>
            </div>

            {currentDatabaseInfo?.ref_type && currentDatabaseInfo?.ref_type != "dbaas_node" ? (<>
              <a className="btn btn-sm btn-primary" onClick={createBackup}>+ Add New Backup</a>
            </>) : ('')}
          </div>
        </div>
        <div className="card-body p-0">
          <div className="table-responsive">
            <table
              className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
              id="kt_security_license_usage_table"
            >
              <thead className="border-gray-200 fs-5 fw-bold bg-secondary">
                <tr className="fw-bold">
                  <th className="w-100px text-start ps-9"> #ID </th>
                  <th className="w-100px text-center pe-3">Database</th>
                  <th className="w-100px text-center">Status</th>
                  <th className="w-100px text-center">Created_at</th>
                  <th className="w-100px text-center ps-17">Restore</th>
                </tr>
              </thead>
              <tbody id="public-IP-list" className="fw-6 fw-bold text-gray-600">
                {dummyBackupData != null ? (<>
                  {dummyBackupData.length == 0 ? (<>
                    <tr>
                      <td colspan="12" id="no_record" valign="top">
                        {/* <div className="d-flex flex-column flex-center">
                          <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                        </div> */}
                        <div className="mt-20 mb-20 text-center">
                          <span className="fs-6 text-gray-800 fw-bold">no record found</span>
                        </div>
                      </td>
                    </tr>
                  </>) : (<>
                    <PaginatedItems itemsPerPage={perPage} ProductData={dummyBackupData} />

                  </>)}
                </>) : ('')}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default Backup; 
