import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UseApi from "../../../../../CustomHooks/useApi";
import UseCallApi from "../../../../../CustomHooks/useCallApi";

export default function AttachElasticIp(props) {
    const [vpcData, setVpcData] = useState(null);
    const [subnetAvailable, setSubnetAvailable] = useState(null);

    var query = window.location.href;
    var vars = query.split("/");
    var vpcid = vars[5];

    const openDrawer = () => {
        var drawerElement = document.querySelector("#create-vpc-elasticip-modal");
        var drawer_overlay = document.querySelector("#drawer_overlay_vpc_elasticip");
        if (drawerElement && drawer_overlay) {
            drawerElement.classList.toggle("drawer-on");
            if (window.innerWidth <= 576) {
                // Adjust this value based on your breakpoint
                drawerElement.style.width = "100%";
            } else {
                drawerElement.style.width = "40%";
            }
            drawer_overlay.classList.toggle("d-none");
        }
    }

    const [dczoneData, setDczoneData] = useState(null);
    const getDczone = async () => {
        let arr = [];
        const data = await UseApi("get", "dczones", "");
        if (data) {
            if (Object.keys(data).length === 0) {
                setDczoneData(null);
            } else {
                // var dcArr=[];
                data.dczones.map((value, index) => {
                    if (value.status === "active" && value.product_vpc === "1") {
                        arr.push(value);
                        // dcArr.push(value);
                        setDczoneData(arr);
                        // ddata.push(value);
                    }
                });
                // setDdata(dcArr);
            }
        }
    };

    useEffect(() => {
        getDczone();
    }, [])

    const allocateIp = async () => {
        toast.dismiss();
        const dt = toast.loading(
            "Please wait... System is processing your request."
        );
        const submitBtn = document.querySelector("#allocateIpBtn");
        let dc_name = document.querySelector('#dcslug')

        if (submitBtn && dc_name.value) {
            var preContent = submitBtn.innerHTML;
            submitBtn.classList.add("disabled");
            submitBtn.innerHTML =
                'Please wait... <span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
            const responseData = await UseCallApi("post", "elasticip/allocate", { "dcslug": dc_name.value });

            if (responseData.status === "error") {
                toast.update(dt, {
                    render: responseData.message,
                    type: "error",
                    isLoading: false,
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else if (responseData.status === "success") {
                toast.update(dt, {
                    render: responseData.message,
                    type: "success",
                    isLoading: false,
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                if (props && props.getElasticIp) {
                    props.getElasticIp()
                }
                openDrawer()
            }
            submitBtn.classList.remove("disabled");
            submitBtn.innerHTML = preContent;
        }
    }

    const handleChangeValue = (dcname) => {
        let dc_name = document.querySelector('#dcslug')
        dc_name.value = dcname
    }

    return (
        <>
            <a className="btn btn-sm btn-primary me-2" onClick={openDrawer}>
                {/*begin::Svg Icon | path: assets/media/icons/duotune/arrows/arr009.svg*/}
                <span className="svg-icon svg-icon-muted svg-icon-1hx">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                        <path
                            opacity="0.3"
                            d="M21 13H3C2.4 13 2 12.6 2 12C2 11.4 2.4 11 3 11H21C21.6 11 22 11.4 22 12C22 12.6 21.6 13 21 13Z"
                            fill="black"
                        />
                        <path
                            d="M12 22C11.4 22 11 21.6 11 21V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V21C13 21.6 12.6 22 12 22Z"
                            fill="black"
                        />
                    </svg>
                </span>
                {/*end::Svg Icon*/}
                Add Elastic Ip
            </a>
            <div
                id="drawer_overlay_vpc_elasticip"
                onClick={openDrawer}
                className="drawer-overlay d-none"
                style={{ zIndex: 109, width: "100%" }}
            ></div>
            <div
                id="create-vpc-elasticip-modal"
                className="bg-body shadow drawer drawer-end"
                data-kt-drawer="true"
                data-kt-drawer-name="chat"
                data-kt-drawer-activate="true"
                data-kt-drawer-direction="end"
                // data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
                style={{ important: true, zIndex: 110 }}
            >
                {/*begin::Messenger*/}
                <div
                    className="card w-100 rounded-0 border-0"
                    id="kt_drawer_chat_messenger"
                >
                    {/*begin::Card header*/}
                    <div
                        className="card-header pe-5"
                        id="kt_drawer_chat_messenger_header"
                    >
                        {/*begin::Title*/}
                        <div className="card-title">
                            {/*begin::User*/}
                            <div className="d-flex justify-content-center flex-column me-3">
                                <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                                    Add Elastic Ip
                                </a>
                            </div>
                            {/*end::User*/}
                        </div>
                        {/*end::Title*/}
                        {/*begin::Card toolbar*/}
                        <div className="card-toolbar">
                            {/*begin::Close*/}
                            <div
                                onClick={openDrawer}
                                className="btn btn-sm btn-icon btn-active-light-primary"
                                id="close_api"
                            >
                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                                <span className="svg-icon svg-icon-2">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <rect
                                            opacity="0.5"
                                            x={6}
                                            y="17.3137"
                                            width={16}
                                            height={2}
                                            rx={1}
                                            transform="rotate(-45 6 17.3137)"
                                            fill="black"
                                        />
                                        <rect
                                            x="7.41422"
                                            y={6}
                                            width={16}
                                            height={2}
                                            rx={1}
                                            transform="rotate(45 7.41422 6)"
                                            fill="black"
                                        />
                                    </svg>
                                </span>
                                {/*end::Svg Icon*/}
                            </div>
                            {/*end::Close*/}
                        </div>
                        {/*end::Card toolbar*/}
                    </div>
                    {/*end::Card header*/}
                    {/*begin::Card body*/}
                    <div className="" id="">
                        {/*begin::Form*/}
                        <form id="create-subnet-form" className="form">
                            {/*begin::Modal body*/}
                            <div className="modal-body ">
                                {/*begin::Scroll*/}
                                <div className=" me-n7 pe-7">
                                    <div className="pt-0 row">
                                        <div className="col-lg-12 d-none">
                                            {/*begin::Input group*/}
                                            <div className="mb-10 fv-row fv-plugins-icon-container">
                                                {/*begin::Label*/}
                                                <label className="required form-label">
                                                    Name
                                                </label>
                                                {/*end::Label*/}
                                                {/*begin::Input*/}
                                                <input
                                                    type="text"
                                                    name="name"
                                                    className="form-control mb-2"
                                                    placeholder="Enter the name"
                                                    defaultValue=""
                                                    id="internetGatewayName"
                                                />
                                                {/*end::Input*/}
                                                {/*begin::Description*/}
                                                <div className="text-muted fs-7">
                                                    A Gateway name is required and recommended to be
                                                    unique.
                                                </div>
                                                {/*end::Description*/}
                                                <div className="fv-plugins-message-container invalid-feedback" />
                                            </div>
                                            {/*end::Input group*/}
                                        </div>
                                        <div className="col-lg-12">
                                            {/*begin::Input group*/}
                                            <div className="mb-10 fv-row fv-plugins-icon-container">
                                                {/*begin::Label*/}
                                                <label className="required form-label">
                                                    Select the data-center location
                                                </label>
                                                {/*end::Label*/}
                                                {/*begin::Input*/}
                                                <select
                                                    // name="dcslug"
                                                    defaultValue=""
                                                    // id="dcslug"
                                                    className="form-select mb-2"
                                                    onChange={(e) => handleChangeValue(e.target.value)}
                                                >
                                                    <option value="" disabled selected>Select Dc-zone</option>
                                                    {dczoneData != null && dczoneData.length > 0 &&
                                                        dczoneData.map((value, index) => {
                                                            return (
                                                                <option value={value.slug}>{value.city} ({value.country})</option>
                                                            )
                                                        })}
                                                </select>
                                                {/*end::Input*/}
                                                <div className="fv-plugins-message-container invalid-feedback" />
                                            </div>
                                            {/*end::Input group*/}
                                            <input
                                                type="hidden"
                                                name="dcslug"
                                                id="dcslug"
                                            />
                                        </div>
                                        <div className="col-lg-12">
                                            <a
                                                className="btn btn-primary mt-5 w-100"
                                                onClick={allocateIp}
                                                id="allocateIpBtn"
                                            >
                                                <span className="svg-icon svg-icon-muted svg-icon-1hx">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={24}
                                                        height={24}
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                    >
                                                        <path
                                                            opacity="0.3"
                                                            d="M21 13H3C2.4 13 2 12.6 2 12C2 11.4 2.4 11 3 11H21C21.6 11 22 11.4 22 12C22 12.6 21.6 13 21 13Z"
                                                            fill="black"
                                                        />
                                                        <path
                                                            d="M12 22C11.4 22 11 21.6 11 21V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V21C13 21.6 12.6 22 12 22Z"
                                                            fill="black"
                                                        />
                                                    </svg>
                                                </span>
                                                Add Elastic Ip
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                {/*end::Scroll*/}
                            </div>
                            {/*end::Modal body*/}
                        </form>
                        {/*end::Form*/}
                    </div>
                    {/*end::Card body*/}
                </div>

                {/*end::Messenger*/}
            </div>
        </>
    );
}
