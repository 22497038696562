import {React} from 'react';

export default function LogoSection(props){
    if(props && props.logo){ 
    return(
        <>
          <tr>
                <td
                  align="center"
                  valign="center"
                  style={{ textAlign: "center", padding: 40 }}
                >
                    <div className="row">
                       <div className="col-6">
                       <a href="#" rel="noopener">
                            <img alt="Logo" src={props.logo} style={{width:"40%"}} />
                        </a>
                       </div>
                       <div className="col-6">
                       <a href="#" rel="noopener">
                            <img alt="Logo" src="https://utho.com/images/logo_black.png" style={{width:"40%"}} />
                        </a>
                       </div>
                    </div>
                 
                </td>
              </tr>
        </>
    )
      } 
}