import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import UseApi from "../../../../../CustomHooks/useApi";
import copy from "copy-to-clipboard";
import UsePostData from "../../../../../CustomHooks/usePostData";
import UsePost from "../../../../../CustomHooks/usePost";
export default function Attach(props) {
  // CSS Start
  const sphide = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
  // CSS End
  var query = window.location.href;
  var vars = query.split("/");
  var id = vars[4];
  const dataFetchedRef2 = useRef();
  const navigate = useNavigate();
  const navRedirect = () => {
    setTimeout(() => {
      navigate('/blockStorage');
    }, 2000);

  }
  const openDrawer = () => {
    var drawerElement = document.querySelector("#attach-modal");
    drawerElement.classList.toggle("drawer-on");
    drawerElement.style.width = "500px";
  };
  const [cloudData, setCloudData] = useState(null);
  const [currentCloud, setCurrentCloud] = useState(null);
  const getCloud = async () => {
    const data = await UseApi('get', 'cloud', '');

    if ('status' in data) {
      if (data.status == "error") {
        setCloudData(null);
      }
    }
    if (Object.keys(data).length === 0) {
      setCloudData(null);
    } else {
      setCloudData(data.cloud);
    }

  }
  useEffect(() => {
    getCloud();
    if (props) {
      if (props.data) {
        setCurrentCloud(props.data.cloudid);
        // setCurrentCloud(1276289);
      }
    }
  }, [props]);

  const attachServer = async () => {
    var submitBtn = document.querySelector("#attach-btn");

    if (submitBtn) {
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = '<span className="indicator-label">Attaching server <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      const dt = toast.loading("Please wait...");
      const responseData = await UsePost('put', 'ebs/' + id + '/attach', 'attachForm');
      if (responseData) {
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
        if (responseData.status === 'error') {
          toast.update(dt, {
            render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        } else if (responseData.status === 'success') {
          toast.update(dt, {
            render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });

          var attachForm = document.querySelector("#attachForm");

          if (attachForm) {
            attachForm.reset();
            openDrawer();
          }
          if (props) {
            props.updateBlock();
          }
        }
      }
    }
  }

  const copyToClipboard = (id) => {

    var ele = document.querySelector("#ip_address_sec" + id);
    var eleText = document.querySelector("#ip_address" + id);
    if (ele) {
      var copyText = ele.innerHTML;
      var copyEle = eleText.innerHTML;
      copy(copyEle);
      ele.innerHTML = "Copied";
      ele.classList.remove("badge-primary");
      ele.classList.add("badge-success");
      setTimeout(() => {
        ele.classList.add("badge-primary");
        ele.classList.remove("badge-success");
        ele.innerHTML = copyText;
      }, 2000);
    }

  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform your form submission logic here
    attachServer();
  };

  const dettachServer = async (rid) => {
    var submitBtn = document.querySelector("#dettach-btn");
    swal({
      title: "Are you sure?",
      text: "Clicking this button will detach this EBS storage from your server. Ensure all necessary data is backed up, as detaching the storage will make the data inaccessible to the server until reattached.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
    if (submitBtn) {
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = '<span className="indicator-label">Attaching server <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      const dt = toast.loading("Dettaching storage, Please wait...");
      const responseData = await UsePostData('put', 'ebs/' + id + '/dettach', {resourceid:rid,type:"cloud"});
      if (responseData) {
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
        if (responseData.status === 'error') {
          toast.update(dt, {
            render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        } else if (responseData.status === 'success') {
          toast.update(dt, {
            render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
 
          getCloud();
          if (props) {
            props.updateBlock();
          }
        }
      }
    }
  }})
  }

  const forceDettachServer = async (rid) => {
    var submitBtn = document.querySelector("#force-dettach-btn");

    if (submitBtn) {
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = '<span className="indicator-label">Attaching server <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      const dt = toast.loading("Force Dettaching storage, Please wait...");
      const responseData = await UsePostData('put', 'ebs/' + id + '/dettach', {resourceid:rid,type:"cloud"});
      if (responseData) {
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
        if (responseData.status === 'error') {
          toast.update(dt, {
            render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        } else if (responseData.status === 'success') {
          toast.update(dt, {
            render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
 
          getCloud();
        }
      }
    }
  }
  return (
    <>
      <div className="tab-pane fade" id="backups" role="tabpanel">
        <div className="card mb-5 mb-xxl-10">
          <div className="card-header">
            <div className="card-title">
              <h3>Attached Resource</h3>
            </div>
          </div>
          <div className="">
          {currentCloud && currentCloud !=0?(<>
            {/*begin::Table container*/}
            <div className="table-responsive">
            {/*begin::Table*/}
            <table
              id="clouddata"
              className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
            >
              {/*begin::Table head*/}
              <thead className="bg-secondary">
                    <tr className="fw-bold ">
                      <th className="w-25px text-center d-none">#</th>
                      <th className="w-100px sphide text-center d-none">Data Centers</th>
                      <th className="w-40 text-center">Name</th>
                      <th className="w-100px text-center d-none">OS/App</th>
                      <th className="w-15 text-center">IPv4</th>
                      <th className="w-15 text-center">Status</th>
                      <th className="w-10 text-start sphide ps-10">Created_at/Due date</th>
                      <th className="w-10 text-center"></th>  
                    </tr>
                  </thead>
              {/*end::Table head*/}
              <tbody id="cloud-list">
                {cloudData != null && Object.keys(cloudData).length != 0 ? (cloudData && cloudData.map((cloud, index) => (
                  <>{currentCloud != null && cloud.cloudid == currentCloud ? (<>
                   <tr>
            
            <td className="p-3 d-none">
              
              <div className="d-flex flex-column w-100 me-2" >
                
                <div className="d-flex flex-stack mb-2">
                  
                  <span className=" me-2 fs-7 fw-bold">{cloud.cloudid}</span>
                </div>
              </div>
            </td>
            <td className="d-none">
              
              <div className="d-flex align-items-center sphide">
                
                <div className="symbol symbol-30px me-5">
                  
                  <img src={"/img/flag_" + cloud.dclocation.dccc + ".png"} />
                </div>
                <div className="d-flex justify-content-start flex-column">
                  
                  <a

                    className="text-dark fw-bold text-hover-primary fs-6"
                  >
                    {cloud.dclocation.location}
                  </a>
                  <span className="text-muted fw-bold text-muted d-block fs-7">
                    {cloud.dclocation.country}
                  </span>
                </div>
              </div>
            </td>
            <td>
              <div className="d-flex align-items-center ps-9">
                {cloud.stack ? (<>
                  <div className="symbol symbol-45px me-5 text-center">
                    <img src={'/' + cloud.stack.logo_url} alt="" style={{ width: "45px", height: "45px", padding: "2px", background: "#ebebeb75" }} />
                  </div>
                </>) : (<>
                  <div className="symbol symbol-45px me-5  text-center pt-2" style={{ width: "45px", height: "45px", padding: "2px", background: "#ebebeb75" }}>
                    <span className={"fonticon_" + (cloud.image.distribution).replaceAll(" ", "")} />
                  </div>
                </>)}
                <div className="d-flex justify-content-start flex-column">
                  <Link to={"/cloud/" + cloud.cloudid}>
                    <div style={{ cursor: "pointer" }} className="text-dark fw-bolder text-hover-primary fs-6">
                      {cloud.hostname}
                    </div>
                  </Link>
                  <span className="text-muted fw-bold text-muted d-block fs-7">
                    <span className="w-10">
                      <img src={"/img/flag_" + cloud.dclocation.dccc + ".png"} style={{ width: "7%" }} /></span>
                    {cloud.dclocation.location}
                    <br/>
                    {cloud.cpu} vCPU /{(cloud.ram / 1024).toFixed(0)} GB / {cloud.disksize} GB Disk
                    
                  </span>
                </div>
              </div>
            </td>
            <td className="d-none">
              {cloud.stack ? (<>

                <div className="d-flex align-items-center">
                  
                  <div className="symbol symbol-45px me-2">
                    <img src={'/' + cloud.stack.logo_url} className="" alt="" style={{ width: "45px", height: "36px", padding: "2px", background: "#ebebeb75" }} />
                  </div>
                  <div className="d-flex justify-content-start flex-column">
                    
                    <a

                      className=" fw-bold text-hover-primary d-block fs-6"
                    >
                      {cloud.stack.title?.charAt(0).toUpperCase() + cloud.stack.title?.slice(1)}
                    </a>
                  </div>
                </div>
              </>) : (<>
                <div className="d-flex align-items-center">
                  
                  <div className="symbol symbol-45px me-2 text-center" style={{ width: "45px", height: "36px", padding: "2px", background: "#ebebeb75" }}>
                    
                    <span className={"fonticon_" + cloud.image.distribution + ""} />
                  </div>
                  <div className="d-flex justify-content-start flex-column">
                    
                    <a

                      className=" fw-bold text-hover-primary d-block fs-6"
                    >
                      {cloud.image.distribution.charAt(0).toUpperCase() + cloud.image.distribution.slice(1)}
                    </a>
                  </div>
                </div>
              </>)}
            </td>
            <td className="text-center" id={`ip_address_${index}`}>
              
              <div className="d-flex flex-column me-2">
                
                <div className=" flex-stack mb-2">
                  
                  <span className=" btn btn-active-primary btn-light-primary btn-sm sphide me-2 fs-7 fw-bold"
                    onClick={() => copyToClipboard(index)}
                    htmlFor="IP Address"
                    id={"ip_address_sec" + index}
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    title="Click to copy IP Address"
                  >
                    <span id={"ip_address" + index}> {cloud?.ip?.trim()}</span>&nbsp;&nbsp;
                    {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen054.svg*/}
                    <span className="svg-icon svg-icon-light svg-icon-2" id={`ipIcon_${index}`}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          opacity="0.5"
                          d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                          fill="black"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}
                  </span>
                </div>
              </div>
            </td>
            <td className="text-center">
              
              <div className=" flex-column w-100 me-2">
                
                <div className=" flex-stack mb-2">
                  
                  {cloud.stack ? (<>
                    {cloud.stack.status != "Pending" ? (<>
                      <span className="w-100 btn btn-light-success btn-sm sphide me-2 fs-7 fw-bold" style={{ cursor: "default" }}> Active  </span>
                    </>) : (<>
                      <span className="w-100 btn btn-light-warning btn-sm sphide me-2 fs-7 fw-bold" style={{ cursor: "default" }}> Installing Stack  </span>
                    </>)}
                  </>) : (<>
                    {cloud.status == "Active" ? (<>
                      <span className="w-100 btn btn-light-success btn-sm sphide me-2 fs-7 fw-bold" style={{ cursor: "default" }}> {cloud.status}  </span>
                    </>) : (<>
                      <span className="w-100 btn btn-light-warning btn-sm sphide me-2 fs-7 fw-bold" style={{ cursor: "default" }}> {cloud.status}  </span>
                    </>)}
                  </>)}

                  
                </div>
              </div>
            </td>
            <td className="text-start ps-7">
              
              <div className="d-flex flex-column w-100 me-2">
                
                <div className=" flex-stack mb-2 sphide">
                  
                  <p className="fw-bold text-gray-700">Created:&nbsp;&nbsp; <span className="text-muted me-2 fs-7 fw-bold">
                    {cloud.created_at}
                  </span>
                  </p>
                  <p className="fw-bold text-gray-700">Due date: &nbsp;&nbsp;
                    <span className="text-muted me-2 fs-7 fw-bold">
                      {cloud.nextduedate}
                    </span>
                  </p>
                </div>
              </div>
            </td>
            <td className="text-center">
            <div className=" flex-column w-100 me-2">
  <div className=" flex-stack mb-2">
  <a onClick={(e)=>dettachServer(cloud.cloudid)}
                  id="dettach-btn"
                  className="btn btn-sm btn-danger me-1 "
                >
                <span className="svg-icon svg-icon-muted svg-icon-2">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="black" />
    <path
      opacity="0.3"
      d="M12 14.4L9.89999 16.5C9.69999 16.7 9.39999 16.8 9.19999 16.8C8.99999 16.8 8.7 16.7 8.5 16.5C8.1 16.1 8.1 15.5 8.5 15.1L10.6 13L12 14.4ZM13.4 13L15.5 10.9C15.9 10.5 15.9 9.90001 15.5 9.50001C15.1 9.10001 14.5 9.10001 14.1 9.50001L12 11.6L13.4 13Z"
      fill="black"
    />
    <path
      d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.2C9.7 3 10.2 3.20001 10.4 3.60001ZM13.4 13L15.5 10.9C15.9 10.5 15.9 9.9 15.5 9.5C15.1 9.1 14.5 9.1 14.1 9.5L12 11.6L9.89999 9.5C9.49999 9.1 8.9 9.1 8.5 9.5C8.1 9.9 8.1 10.5 8.5 10.9L10.6 13L8.5 15.1C8.1 15.5 8.1 16.1 8.5 16.5C8.7 16.7 9 16.8 9.2 16.8C9.4 16.8 9.69999 16.7 9.89999 16.5L12 14.4L14.1 16.5C14.3 16.7 14.6 16.8 14.8 16.8C15 16.8 15.3 16.7 15.5 16.5C15.9 16.1 15.9 15.5 15.5 15.1L13.4 13Z"
      fill="black"
    />
  </svg>
</span>

Dettach
                </a>
  </div>
</div> 
            </td> 
          </tr>
                  </>) : (<>
                    <tr>
                   
                   <td colspan="12" className="d-none" id="no_record" valign="top">
                     <div className="d-flex flex-column flex-center">
                       <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                     </div>
                   </td>
                 </tr>
                 </>)}
                  </>
                ))) : ('')}
              </tbody>
            </table>
            {/*end::Table*/}
          </div>
          {/*end::Table container*/}
          </>):(<>
            <div className="card-body">
            <div className="row mb-10">
              <div className="notice d-flex bg-light-primary rounded border-primary border p-6">
                
                {/*begin::Svg Icon | path: assets/media/icons/duotune/arrows/arr029.svg*/}
                <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      opacity="0.3"
                      d="M18.4 5.59998C18.7766 5.9772 18.9881 6.48846 18.9881 7.02148C18.9881 7.55451 18.7766 8.06577 18.4 8.44299L14.843 12C14.466 12.377 13.9547 12.5887 13.4215 12.5887C12.8883 12.5887 12.377 12.377 12 12C11.623 11.623 11.4112 11.1117 11.4112 10.5785C11.4112 10.0453 11.623 9.53399 12 9.15698L15.553 5.604C15.9302 5.22741 16.4415 5.01587 16.9745 5.01587C17.5075 5.01587 18.0188 5.22741 18.396 5.604L18.4 5.59998ZM20.528 3.47205C20.0614 3.00535 19.5074 2.63503 18.8977 2.38245C18.288 2.12987 17.6344 1.99988 16.9745 1.99988C16.3145 1.99988 15.661 2.12987 15.0513 2.38245C14.4416 2.63503 13.8876 3.00535 13.421 3.47205L9.86801 7.02502C9.40136 7.49168 9.03118 8.04568 8.77863 8.6554C8.52608 9.26511 8.39609 9.91855 8.39609 10.5785C8.39609 11.2384 8.52608 11.8919 8.77863 12.5016C9.03118 13.1113 9.40136 13.6653 9.86801 14.132C10.3347 14.5986 10.8886 14.9688 11.4984 15.2213C12.1081 15.4739 12.7616 15.6039 13.4215 15.6039C14.0815 15.6039 14.7349 15.4739 15.3446 15.2213C15.9543 14.9688 16.5084 14.5986 16.975 14.132L20.528 10.579C20.9947 10.1124 21.3649 9.55844 21.6175 8.94873C21.8701 8.33902 22.0001 7.68547 22.0001 7.02551C22.0001 6.36555 21.8701 5.71201 21.6175 5.10229C21.3649 4.49258 20.9947 3.93867 20.528 3.47205Z"
                      fill="black"
                    />
                    <path
                      d="M14.132 9.86804C13.6421 9.37931 13.0561 8.99749 12.411 8.74695L12 9.15698C11.6234 9.53421 11.4119 10.0455 11.4119 10.5785C11.4119 11.1115 11.6234 11.6228 12 12C12.3766 12.3772 12.5881 12.8885 12.5881 13.4215C12.5881 13.9545 12.3766 14.4658 12 14.843L8.44699 18.396C8.06999 18.773 7.55868 18.9849 7.02551 18.9849C6.49235 18.9849 5.98101 18.773 5.604 18.396C5.227 18.019 5.0152 17.5077 5.0152 16.9745C5.0152 16.4413 5.227 15.93 5.604 15.553L8.74701 12.411C8.28705 11.233 8.28705 9.92498 8.74701 8.74695C8.10159 8.99737 7.5152 9.37919 7.02499 9.86804L3.47198 13.421C2.52954 14.3635 2.00009 15.6417 2.00009 16.9745C2.00009 18.3073 2.52957 19.5855 3.47202 20.528C4.41446 21.4704 5.69269 21.9999 7.02551 21.9999C8.35833 21.9999 9.63656 21.4704 10.579 20.528L14.132 16.975C14.5987 16.5084 14.9689 15.9544 15.2215 15.3447C15.4741 14.735 15.6041 14.0815 15.6041 13.4215C15.6041 12.7615 15.4741 12.108 15.2215 11.4983C14.9689 10.8886 14.5987 10.3347 14.132 9.86804Z"
                      fill="black"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
               
                <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                  
                  <div className="mb-3 mb-md-0 fw-bold">
                    
                    <h4 className="text-gray-900 fw-bolder">
                      Attach your storage with server
                    </h4>
                    <div className="fs-6 text-gray-700 pe-7">
                      Enhance your server's capacity and functionality by
                      seamlessly integrating additional storage solutions,
                      empowering you to securely store and manage your data
                      with ease.
                    </div>
                  </div>
                  <a
                    onClick={openDrawer}
                    className="btn btn-primary px-6 align-self-center text-nowrap"
                  >
                    Attach
                  </a>
                </div>
              </div>
            </div>
            </div>
            </>)}  
        </div> 
        </div>
        <div
          id="attach-modal"
          className="bg-body shadow drawer drawer-end"
          data-kt-drawer="true"
          data-kt-drawer-name="chat"
          data-kt-drawer-activate="true"
          data-kt-drawer-direction="end"
          data-kt-drawer-close="#close_ssh"
          data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
          style={{ maxWidth: "70%", important: true }}
        >
          {/*begin::Messenger*/}
          <div
            className="card rounded-0 w-100 border-0"
            id="kt_drawer_chat_messenger"
          >
            {/*begin::Card header*/}
            <div
              className="card-header pe-5"
              id="kt_drawer_chat_messenger_header"
            >
              {/*begin::Title*/}
              <div className="card-title">
                {/*begin::User*/}
                <div className="d-flex justify-content-center flex-column me-3">
                  <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                    Attach Storage block_storage_1
                  </a>
                </div>
                {/*end::User*/}
              </div>
              {/*end::Title*/}
              {/*begin::Card toolbar*/}
              <div className="card-toolbar">
                {/*begin::Close*/}
                <div
                  onClick={openDrawer}
                  className="btn btn-sm btn-icon btn-active-light-primary"
                  id="close_ssh"
                >
                  {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                  <span className="svg-icon svg-icon-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.5"
                        x={6}
                        y="17.3137"
                        width={16}
                        height={2}
                        rx={1}
                        transform="rotate(-45 6 17.3137)"
                        fill="black"
                      />
                      <rect
                        x="7.41422"
                        y={6}
                        width={16}
                        height={2}
                        rx={1}
                        transform="rotate(45 7.41422 6)"
                        fill="black"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                </div>
                {/*end::Close*/}
              </div>
              {/*end::Card toolbar*/}
            </div>
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className="" id="">
              {/*begin::Form*/}
              <form id="attachForm" className="form" onSubmit={handleSubmit}>
                {/*begin::Modal body*/}
                <div className="modal-body ">
                  {/*begin::Scroll*/}
                  <div
                    className="scroll-y me-n7 pe-7"
                    id="kt_modal_create_api_key_scroll"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                    data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                    data-kt-scroll-offset="300px"
                  >
                    {/*begin::Input group*/}
                    <div className="mb-5 fv-row">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        
                        Servers
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <select
                        className="form-select"
                        name="resourceid"
                      >
                        <option value="">
                          Please select cloud server
                        </option>
                        {cloudData != null && Object.keys(cloudData).length != 0 ? (cloudData && cloudData.map((cloud, index) => (<>
                         
                          <option value={cloud.cloudid}>
                            {cloud.hostname}
                          </option>
                        </>))) : ('')}
                      </select>
                    <input type="hidden" name="type" defaultValue={"cloud"}/>
                      {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                  </div>
                  {/*end::Scroll*/}
                </div>
                {/*end::Modal body*/}
                {/*begin::Modal footer*/}
                <div className="modal-footer ">
                  {/*begin::Button*/}
                  <button
                    type="button"
                    className="btn btn-primary w-100"
                    id="attach-btn"
                    onClick={attachServer}
                  >
                    <span className="indicator-label"> Attach</span>
                  </button>
                  {/*end::Button*/}
                </div>
                {/*end::Modal footer*/}
              </form>
              {/*end::Form*/}
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Messenger*/}
        </div>
      </div>
    </>);
}
