import React from 'react' 

function NetworkType() {
  return (
    <>
      <div className="col-xl-12 card mb-5">
  <div className="card-header border-3 pt-4 ps-6 p-0">
    <h3 className="card-title align-items-start flex-column">
      <span className="card-label fw-bolder text-gray-800">Network Type</span>
      <span className="text-gray-400 mt-1 fw-bold fs-6">
        Please select the network type: Public (enable external IP access) or
        Private (internal network only).
      </span>
    </h3>
  </div>
  <div className=" border-3 mb-5 pt-2">
    <div className="p-2 border-3 pt-2"> 
  {/*begin:Option*/}
  <label className="d-flex flex-stack mb-5 cursor-pointer">
    {/*begin:Label*/}
    <span className="d-flex align-items-center me-2">
      {/*begin:Icon*/}
      <span className="symbol symbol-50px me-6">
        <span className="symbol-label bg-light-primary">
          {/*begin::Svg Icon | path: icons/duotune/maps/map004.svg*/}
          <span className="svg-icon svg-icon-1 svg-icon-primary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              ....
            </svg>
          </span>
          {/*end::Svg Icon*/}
        </span>
      </span>
      {/*end:Icon*/}
      {/*begin:Info*/}
      <span className="d-flex flex-column">
        <span className="fw-bolder fs-6">Public</span>
        <span className="fs-7 text-muted">
        The cluster endpoint is accessible from outside of your VPC. Worker node traffic will leave your VPC to connect to the endpoint.
        </span>
      </span>
      {/*end:Info*/}
    </span>
    {/*end:Label*/}
    {/*begin:Input*/}
    <span className="form-check form-check-custom form-check-solid">
      <input
        className="form-check-input"
        type="radio"
        name="category"
        defaultValue={1}
      />
    </span>
    {/*end:Input*/}
  </label>
  {/*end::Option*/}
  {/*begin:Option*/}
  <label className="d-flex flex-stack mb-5 cursor-pointer">
    {/*begin:Label*/}
    <span className="d-flex align-items-center me-2">
      {/*begin:Icon*/}
      <span className="symbol symbol-50px me-6">
        <span className="symbol-label bg-light-danger">
          {/*begin::Svg Icon | path: icons/duotune/general/gen025.svg*/}
          <span className="svg-icon svg-icon-1 svg-icon-danger">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              ....
            </svg>
          </span>
          {/*end::Svg Icon*/}
        </span>
      </span>
      {/*end:Icon*/}
      {/*begin:Info*/}
      <span className="d-flex flex-column">
        <span className="fw-bolder fs-6">Public d private</span>
        <span className="fs-7 text-muted">
        The cluster endpoint is accessible from outside of your VPC. Worker node traffic to the endpoint will stay within your VPC.
        </span>
      </span>
      {/*end:Info*/}
    </span>
    {/*end:Label*/}
    {/*begin:Input*/}
    <span className="form-check form-check-custom form-check-solid">
      <input
        className="form-check-input"
        type="radio"
        name="category"
        defaultValue={2}
      />
    </span>
    {/*end:Input*/}
  </label>
  {/*end::Option*/}
  {/*begin:Option*/}
  <label className="d-flex flex-stack cursor-pointer">
    {/*begin:Label*/}
    <span className="d-flex align-items-center me-2">
      {/*begin:Icon*/}
      <span className="symbol symbol-50px me-6">
        <span className="symbol-label bg-light-success">
          {/*begin::Svg Icon | path: icons/duotune/general/gen013.svg*/}
          <span className="svg-icon svg-icon-1 svg-icon-danger">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              ....
            </svg>
          </span>
          {/*end::Svg Icon*/}
        </span>
      </span>
      {/*end:Icon*/}
      {/*begin:Info*/}
      <span className="d-flex flex-column">
        <span className="fw-bolder fs-6">Private</span>
        <span className="fs-7 text-muted">
        The cluster endpoint is only accessible through your VPC. Worker node traffic to the endpoint will stay within your VPC.
        </span>
      </span>
      {/*end:Info*/}
    </span>
    {/*end:Label*/}
    {/*begin:Input*/}
    <span className="form-check form-check-custom form-check-solid">
      <input
        className="form-check-input"
        type="radio"
        name="category"
        defaultValue={3}
      />
    </span>
    {/*end:Input*/}
  </label>
  {/*end::Option*/} 
    </div>
  </div>
</div>

    </>
  )
}  
export default NetworkType

