import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UsePost from "../../../../CustomHooks/usePost";
import swal from "sweetalert";
import { findAllByDisplayValue } from "@testing-library/react";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import * as XLSX from 'xlsx'
// const csv = require('csv-parse')
import jsPDF from "jspdf";
import 'jspdf-autotable'
import Papa from 'papaparse'
import UsePostData from "../../../../CustomHooks/usePostData";
import CustomDoc from "../../../../CustomHooks/CustomDoc";
import ProductsUrl from "../../Docs/Docsdata";
// import { parse } from "csv-parse";

function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue(value => value + 1);
}
export default function ManageFirewall() {
  const [pageStatus, setPageStatus] = useState(false)
  const [formate, setFormate] = useState('csv')
  const [ruleType, setRuleType] = useState('incoming')
  const [bulkData, setBulkData] = useState([])
  const forceUpdate = useForceUpdate();
  var query = window.location.href;
  var vars = query.split("/");
  const [fileData, setFileData] = useState()
  var id = vars[4];
  const navigate = useNavigate();
  const [firewallData, setFirewallData] = useState(null);
  const [serverForFw, setServerForFw] = useState(null);
  const [incomingOption, setIncomingOption] = useState([<option value='tcp'>TCP</option>]);
  const [outgoingOption, setOutgoingOption] = useState([<option value='tcp'>TCP</option>]);
  const dataFetchedRef = useRef(false);
  const navRedirect = () => {
    setTimeout(() => {
      navigate("/firewall/" + id);
    }, 3000);

  }

  useEffect(() => {
    window.KTScroll.init();
    window.KTDrawer.init();
    window.KTMenu.createInstances();
    window.KTToggle.createInstances();
  }, []);

  const getFirewall = async () => {
    const data = await UseApi('get', 'firewall/' + id, '');
    if (data.firewalls.length <= 0) {
      setFirewallData(null);
    } else {
      setFirewallData(data.firewalls[0]);
      //   toast.update(dt,{render: "Your Firewall info listed below", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      // });  
      // //console.log(data.firewalls[0]);
    }
  }
  const getServerList = async () => {
    const data = await UseApi('get', 'forfirewall', '');
    if (Object.keys(data).length === 0) {
      setServerForFw(null);
    } else {
      setServerForFw(data.serversforfw);
      //console.log(data.serversforfw);  
    }
  }
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getFirewall();
    getServerList();

  }, []);
  ////console.log(firewallData);


  const addIncomingFirewall = async () => {
    toast.dismiss();
    setPageStatus(true)
    const dtincoming = toast.loading("Please wait...");
    const responseData = await UsePost('post', 'firewall/' + id + '/rule/add', 'addincomingrule');
    if (responseData.status === 'error') {
      setPageStatus(false)
      toast.update(dtincoming, {
        render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
    } else {
      setPageStatus(false)
      toast.update(dtincoming, {
        render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      forceUpdate();
      getFirewall();
      navRedirect();

    }

  }
  const addOutgoingFirewall = async () => {
    toast.dismiss();
    setPageStatus(true)
    const dtoutgoing = toast.loading("Please wait...");
    const responseData = await UsePost('post', 'firewall/' + id + '/rule/add', 'addoutgoingrule');
    if (responseData.status === 'error') {
      setPageStatus(false)
      toast.update(dtoutgoing, {
        render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
    } else {
      setPageStatus(false)
      toast.update(dtoutgoing, {
        render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      forceUpdate();
      getFirewall();
      navRedirect();

    }

  }
  const addFirewallServer = async () => {
    toast.dismiss();
    setPageStatus(true)
    const dtServer = toast.loading("Please wait...");
    const serverField = document.querySelector('#select_server');
    if (serverField.value != '0') {
      const responseData = await UsePost('post', 'firewall/' + id + '/server/add', 'addserver');
      //console.log(responseData); 
      if (responseData) {
        setPageStatus(false)
        if (responseData.status === 'error') {
          toast.update(dtServer, {
            render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        } else {
          toast.update(dtServer, {
            render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          forceUpdate();
          getFirewall();
          navRedirect();

        }
      } else {
        setPageStatus(false)
        toast.update(dtServer, {
          render: "Sorry we unable to find this server or you don't have access!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
      }
    } else {
      setPageStatus(false)
      toast.update(dtServer, {
        render: "Sorry we unable to find this server or you don't have access!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
    }

  }

  const destroyFirewall = async () => {
    toast.dismiss();
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Firewall!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          setPageStatus(true)
          const dt = toast.loading("Firewall deleting...");
          const dnsp = await UseApi('DELETE', 'firewall/' + id + '/destroy', '');
          //console.log(dnsp);
          if (dnsp.status == 'error') {
            setPageStatus(false)
            toast.update(dt, {
              render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
          } else if (dnsp.status == 'success') {
            setPageStatus(false)
            toast.update(dt, {
              render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
            forceUpdate();
            getFirewall();
            const url = window.location.href
            if (query == url) {
              navigate('/firewall');
            }
          }
        } else {
          setPageStatus(false)
          swal({
            title: "Firewall",
            text: "Your Firewall is safe!",
            icon: "success",
            button: "OK",
          });
        }
      });
  }

  const deleteFirewallRule = async (fid, fwType) => {
    toast.dismiss();
    swal({
      title: "Are you sure?",
      text: `Once deleted, you will not be able to recover this ${fwType} firewall rule!`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          setPageStatus(true)
          const dt = toast.loading("firewall rule deleting...");
          const dnsp = await UseApi('DELETE', 'firewall/' + id + '/rule/' + fid + '/delete', '');
          //console.log(dnsp);
          if (dnsp.status == 'error') {
            setPageStatus(false)
            toast.update(dt, {
              render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
          } else if (dnsp.status == 'success') {
            setPageStatus(false)
            toast.update(dt, {
              render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
            forceUpdate();
            getFirewall();
            navRedirect();
          }
        } else {
          setPageStatus(false)
          swal({
            title: "Firewall",
            text: "Your Firewall rule is safe!",
            icon: "success",
            button: "OK",
          });
        }
      });
  }

  const deleteFirewallServers = async (fid) => {
    toast.dismiss();
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this firewall server!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          setPageStatus(true)
          const dt = toast.loading("firewall server deleting...");
          const dnsp = await UseApi('DELETE', 'firewall/' + id + '/server/' + fid + '/delete', '');
          //console.log(dnsp);
          if (dnsp.status == 'error') {
            setPageStatus(false)
            toast.update(dt, {
              render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
          } else if (dnsp.status == 'success') {
            setPageStatus(false)
            toast.update(dt, {
              render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
            forceUpdate();
            getFirewall();
            navRedirect();
          }
        } else {
          setPageStatus(false)
          swal({
            title: "Firewall",
            text: "Your Firewall server is safe!",
            icon: "success",
            button: "OK",
          });
        }
      });
  }

  const setFirewallRule = (type) => {
    toast.dismiss();
    if (type == 'incoming') {
      const serviceType = document.querySelector('#service_incoming');
      const protocol_incoming = document.querySelector('#protocol_incoming');
      const port_tincoming = document.querySelector('#port_tincoming');

      if (serviceType.value === "SSH") {
        setIncomingOption([<option value='tcp'>TCP</option>]);
        protocol_incoming.innerHtml = "<option value='tcp'>TCP</option>";
        port_tincoming.value = "22";

      }
      else if (serviceType.value == "RDP") {
        setIncomingOption([<option value='tcp'>TCP</option>]);
        protocol_incoming.innerHtml = "<option value='tcp'>TCP</option>";
        port_tincoming.value = "3389";

      }
      else if (serviceType.value === "MYSQL") {
        setIncomingOption([<option value='tcp'>TCP</option>]);
        protocol_incoming.innerHtml = "<option value='tcp'>TCP</option>";
        port_tincoming.value = "3306";

      }
      else if (serviceType.value === "MSSQL") {
        setIncomingOption([<option value='tcp'>TCP</option>]);
        protocol_incoming.innerHtml = "<option value='tcp'>TCP</option>";
        port_tincoming.value = "1433";

      }
      else if (serviceType.value === "HTTP") {
        setIncomingOption([<option value='tcp'>TCP</option>]);
        protocol_incoming.innerHtml = "<option value='tcp'>TCP</option>";
        port_tincoming.value = "80";
      }
      else if (serviceType.value === "HTTPS") {
        setIncomingOption([<option value='tcp'>TCP</option>]);
        protocol_incoming.innerHtml = "<option value='tcp'>TCP</option>";
        port_tincoming.value = "443";

      }
      else if (serviceType.value === "DNS TCP") {
        setIncomingOption([<option value='tcp'>TCP</option>]);
        protocol_incoming.innerHtml = "<option value='tcp'>TCP</option>";
        port_tincoming.value = "53";

      }
      else if (serviceType.value == "DNS UDP") {
        setIncomingOption([<option value='udp'>UDP</option>]);
        protocol_incoming.innerHtml = "<option value='udp'>UDP</option>";
        port_tincoming.value = "53";

      }
      else if (serviceType.value === "ALL TCP") {
        setIncomingOption([<option value='tcp'>TCP</option>]);
        protocol_incoming.innerHtml = "<option value='tcp'>TCP</option>";
        port_tincoming.value = "ALL";
      }
      else if (serviceType.value === "ALL UDP") {
        setIncomingOption([<option value='udp'>UDP</option>]);
        protocol_incoming.innerHtml = "<option value='udp'>UDP</option>";
        port_tincoming.value = "ALL";

      }
      else if (serviceType.value === "PING") {
        setIncomingOption([<option value='ICMP'>ICMP</option>]);
        protocol_incoming.innerHtml = "<option value='ICMP'>ICMP</option>";
        //	$("#incomingportru").val("ICMP");
        port_tincoming.value = "PING";
      }
      else if (serviceType.value === "CUSTOM") {
        setIncomingOption([<option value='tcp'>TCP</option>, <option value='udp'>UDP</option>]);
        protocol_incoming.innerHtml = "<option value='tcp'>TCP</option><option value='udp'>UDP</option>";
        port_tincoming.value = "ALL";

      }
    }
    if (type == 'outgoing') {
      const serviceType = document.querySelector('#service_outgoing');
      const protocol_outgoing = document.querySelector('#protocol_outgoing');
      const port_toutgoing = document.querySelector('#port_toutgoing');

      if (serviceType.value === "SSH") {
        setOutgoingOption([<option value='tcp'>TCP</option>]);
        protocol_outgoing.innerHtml = "<option value='tcp'>TCP</option>";
        port_toutgoing.value = "22";

      }
      else if (serviceType.value === "RDP") {
        setOutgoingOption([<option value='tcp'>TCP</option>]);
        protocol_outgoing.innerHtml = "<option value='tcp'>TCP</option>";
        port_toutgoing.value = "3389";

      }
      else if (serviceType.value === "MYSQL") {
        setOutgoingOption([<option value='tcp'>TCP</option>]);
        protocol_outgoing.innerHtml = "<option value='tcp'>TCP</option>";
        port_toutgoing.value = "3306";

      }
      else if (serviceType.value === "MSSQL") {
        setOutgoingOption([<option value='tcp'>TCP</option>]);
        protocol_outgoing.innerHtml = "<option value='tcp'>TCP</option>";
        port_toutgoing.value = "1433";

      }
      else if (serviceType.value === "HTTP") {
        setOutgoingOption([<option value='tcp'>TCP</option>]);
        protocol_outgoing.innerHtml = "<option value='tcp'>TCP</option>";
        port_toutgoing.value = "80";
      }
      else if (serviceType.value === "HTTPS") {
        setOutgoingOption([<option value='tcp'>TCP</option>]);
        protocol_outgoing.innerHtml = "<option value='tcp'>TCP</option>";
        port_toutgoing.value = "443";

      }
      else if (serviceType.value === "DNS TCP") {
        setOutgoingOption([<option value='tcp'>TCP</option>]);
        protocol_outgoing.innerHtml = "<option value='tcp'>TCP</option>";
        port_toutgoing.value = "53";

      }
      else if (serviceType.value === "DNS UDP") {
        setOutgoingOption([<option value='udp'>UDP</option>]);
        protocol_outgoing.innerHtml = '<option value="udp">UDP</option>';
        port_toutgoing.value = "53";

      }
      else if (serviceType.value === "ALL TCP") {
        setOutgoingOption([<option value='tcp'>TCP</option>]);
        protocol_outgoing.innerHtml = "<option value='tcp'>TCP</option>";
        port_toutgoing.value = "ALL";
      }
      else if (serviceType.value === "ALL UDP") {
        setOutgoingOption([<option value='udp'>UDP</option>]);
        protocol_outgoing.innerHtml = "<option value='udp'>UDP</option>";
        port_toutgoing.value = "ALL";

      }
      else if (serviceType.value === "PING") {
        setOutgoingOption([<option value='ICMP'>ICMP</option>]);
        protocol_outgoing.innerHtml = "<option value='ICMP'>ICMP</option>";
        port_toutgoing.value = "PING";
      }
      else if (serviceType.value === "CUSTOM") {
        setOutgoingOption([<option value='tcp'>TCP</option>, <option value='udp'>UDP</option>]);
        protocol_outgoing.innerHtml = "<option value='tcp'>TCP</option><option value='udp'>UDP</option>";
        port_toutgoing.value = "ALL";

      }
    }
  }

  const fileInputRef = useRef(null);

  const handleAttachFilesClick = () => {
    // Trigger the click event of the file input when the "Attach files" button is clicked
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    let data_element = document.querySelector('#dropzoneData')
    let upload_element = document.querySelector("#uploadData")
    data_element.style.display = ''
    upload_element.style.display = 'inline-block'
    // Handle the selected files
    setFileData(files[0])
    readFile(files[0]).then(data => {
      console.log(data); // Array containing CSV data
      const keys = data[0];
      const result = [];
      for (let i = 1; i < data.length; i++) {
        const obj = {};
        for (let j = 0; j < keys.length; j++) {
          obj[keys[j]] = data[i][j];
        }
        result.push(obj);
      }
      console.log(result);
      setBulkData(JSON.stringify(result))
      // Further processing of CSV data can be done here
    }).catch(error => {
      console.error('Error reading CSV file:', error);
    });

    // console.log(files[0]);
  };

  function readFile(file) {
    console.log(file);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        console.log(event.target.result);
        const data = event.target.result;
        console.log(file.name.endsWith('.csv'));
        if (file.name.endsWith('.csv')) {
          // If the file is a CSV, parse it as CSV
          parseCSV(data)
            .then(csvData => resolve(csvData))
            .catch(error => reject(error));
        } else if (file.name.endsWith('.xlsx')) {
          // If the file is an Excel file, parse it as Excel
          parseExcel(data)
            .then(excelData => resolve(excelData))
            .catch(error => reject(error));
        } else {
          reject(new Error('Unsupported file type'));
        }
      };
      reader.onerror = (error) => reject(error);
      reader.readAsBinaryString(file);
    });
  }

  function parseCSV(data) {
    return new Promise((resolve, reject) => {
      // Parse the CSV data using csv-parse
      Papa.parse(data, {
        delimiter: ',', // Specify the delimiter used in the CSV file
        header: false, // Treat the first row as data instead of headers
        complete: (results) => {
          // Parsed data is available in results.data
          resolve(results.data);
        },
        error: (error) => {
          reject(error);
        }

      });
    });
  }

  function parseExcel(data) {
    return new Promise((resolve, reject) => {
      const workbook = XLSX.read(data, { type: 'binary' });
      const sheetName = workbook.SheetNames[0]; // Assuming data is in the first sheet
      const sheet = workbook.Sheets[sheetName];
      const excelData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      resolve(excelData);
    });
  }

  // // Usage example:
  // readCSVFile(yourCSVFile).then(data => {
  //   console.log(data); // Array containing CSV data
  // });


  const handleRemoveData = () => {
    console.log("heloo buddy");
    let data_element = document.querySelector('#dropzoneData')
    let upload_element = document.querySelector("#uploadData")
    data_element.style.display = 'none'
    upload_element.style.display = 'none'
    setFileData()
  }

  function removeFieldsAndFilter(data, fieldsToRemove, type) {
    return data
      .map(item => {
        const newItem = { ...item };
        fieldsToRemove.forEach(field => delete newItem[field]);
        return newItem;
      })
      .filter(item => item.type === type);
  }

  function exportToExcel(data, fileName, fieldsToRemove) {
    console.log(data, fileName);
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, fileName + '.xlsx');
  }

  function removeFieldsAndFilter(data, fieldsToRemove, type) {
    return data
      .map(item => {
        const newItem = { ...item };
        fieldsToRemove.forEach(field => delete newItem[field]);
        return newItem;
      })
      .filter(item => item.type === type);
  }

  function convertToCSV(data) {
    const dtoutgoing = toast;
    if (data != null && data.length > 0) {
      const headers = Object.keys(data[0]);
      const csvHeader = headers.join(',') + '\n';
      const csvRows = data.map(item => {
        return headers.map(header => item[header]).join(',');
      });
      const csvContent = csvHeader + csvRows.join('\n');
      return csvContent;
    } else {
      setTimeout(() => {
        toast.update(dtoutgoing, {
          render: "No firewall rule found to export", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        }, 1000);
      })
    }
  }

  function downloadCSV(csvContent, fileName) {
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName + '.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  function convertToPDF(data, ruleType) {
    if (data) {
      const headers = Object.keys(data[0]);
      const doc = new jsPDF();
      doc.text(`${ruleType?.charAt(0)?.toUpperCase() + ruleType?.slice(1)} Rule`, 15, 10);

      // Convert data to table format
      const table = data.map(item => headers.map(header => item[header]));

      // Add data as a table to the PDF
      doc.autoTable({
        head: [headers],
        body: table
      });

      return doc;
    }
  }

  function downloadPDF(doc, fileName) {
    doc.save(fileName + '.pdf');
  }


  const handleExportFile = () => {
    const fieldsToRemove = ['id', 'firewallid', 'note'];
    const filteredData = removeFieldsAndFilter(firewallData.rules, fieldsToRemove, ruleType);

    if (formate === "excel" && filteredData) {
      exportToExcel(filteredData, ruleType, fieldsToRemove);
    } else if (formate === "csv" && filteredData) {
      const csvContent = convertToCSV(filteredData);
      if (csvContent) {
        downloadCSV(csvContent, ruleType);
      }
    } else if (filteredData) {
      const pdfDoc = convertToPDF(filteredData, ruleType);
      if (pdfDoc) {
        downloadPDF(pdfDoc, ruleType);
      }
    }
  }


  const handleSubmitData = async () => {
    toast.dismiss();
    setPageStatus(true)
    const dtoutgoing = toast.loading("Please wait...");
    let elements = document.querySelector("#kt_modal_upload")
    let data_element = document.querySelector('#dropzoneData')
    let upload_element = document.querySelector("#uploadData")
    const responseData = await UsePostData('post', 'firewall/' + id + '/rules/add', { rules: bulkData });
    if (responseData.status === 'error') {
      setPageStatus(false)
      toast.update(dtoutgoing, {
        render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
    } else {
      setPageStatus(false)
      toast.update(dtoutgoing, {
        render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      // setFileData()
      console.log(elements);
      if (elements) {
        elements.classList.remove("show")
        elements.style.display = "none"
      }
      console.log(data_element);
      if (data_element) {
        data_element.style.display = 'none'
      }
      console.log(upload_element);
      if (upload_element) {
        upload_element.style.display = 'none'
      }
      forceUpdate();
      getFirewall();
      navRedirect();

    }
  }

  const handleDragStart = (event) => {
    console.log(event);
    event.preventDefault();
    event.stopPropagation();
    // Add any necessary logic for drag start
  };

  const handleDragOver = (event) => {
    console.log(event);
    event.preventDefault();
    event.stopPropagation();
    // Add any necessary logic for drag over
  };

  const handleDragEnter = (event) => {
    console.log(event);
    event.preventDefault();
    event.stopPropagation();
    // Add any necessary logic for drag enter
  };

  const handleDragLeave = (event) => {
    console.log(event);
    event.preventDefault();
    event.stopPropagation();
    // Add any necessary logic for drag leave
  };

  const handleDrop = (event) => {
    console.log(event);
    event.preventDefault();
    event.stopPropagation();
    // Add any necessary logic for drop
    const files = event.dataTransfer.files;
    fileInputRef.current.click();
    // Handle dropped files here
  };

  return (
    <>
      {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        /> */}
      {firewallData != null ? (<>
        <div className="col-xl-12">
          {/*begin::Tables Widget 5*/}
          <div className="card card-xxl-stretch mb-5 mb-xl-12">
            {/*begin::Header*/}
            <div className="card-header border-0 pt-5">

              <h3 className="card-title align-items-start flex-column">
                <span className=" card-label fw-bold fs-3 mb-1">
                  Firewall Management:{" "}
                  <span
                    className="badge badge-success mt-0 fw-bold fs-7"
                    id="firewall_name"
                  >
                    {firewallData != null && firewallData.name ? (<>{firewallData.name}</>) : ('')}
                  </span>
                </span>
              </h3>
              <div className="card-toolbar">
                <CustomDoc url={ProductsUrl[0]?.firewall + "/manage-firewall/"} />
                <ul className="nav">
                  <li className="nav-item">
                    <a
                      className="nav-link btn btn-sm btn-color-primary btn-active btn-active-primary fw-bold px-4 me-1"
                      data-bs-target="#kt_modal_upload"
                      data-bs-toggle="modal"
                    >
                      Upload Rules
                      <span class="svg-icon svg-icon-primary svg-icon-2 ms-1"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.3" width="12" height="2" rx="1" transform="matrix(0 -1 -1 0 12.75 19.75)" fill="black" />
                        <path d="M12.0573 17.8813L13.5203 16.1256C13.9121 15.6554 14.6232 15.6232 15.056 16.056C15.4457 16.4457 15.4641 17.0716 15.0979 17.4836L12.4974 20.4092C12.0996 20.8567 11.4004 20.8567 11.0026 20.4092L8.40206 17.4836C8.0359 17.0716 8.0543 16.4457 8.44401 16.056C8.87683 15.6232 9.58785 15.6554 9.9797 16.1256L11.4427 17.8813C11.6026 18.0732 11.8974 18.0732 12.0573 17.8813Z" fill="black" />
                        <path d="M18.75 15.75H17.75C17.1977 15.75 16.75 15.3023 16.75 14.75C16.75 14.1977 17.1977 13.75 17.75 13.75C18.3023 13.75 18.75 13.3023 18.75 12.75V5.75C18.75 5.19771 18.3023 4.75 17.75 4.75L5.75 4.75C5.19772 4.75 4.75 5.19771 4.75 5.75V12.75C4.75 13.3023 5.19771 13.75 5.75 13.75C6.30229 13.75 6.75 14.1977 6.75 14.75C6.75 15.3023 6.30229 15.75 5.75 15.75H4.75C3.64543 15.75 2.75 14.8546 2.75 13.75V4.75C2.75 3.64543 3.64543 2.75 4.75 2.75L18.75 2.75C19.8546 2.75 20.75 3.64543 20.75 4.75V13.75C20.75 14.8546 19.8546 15.75 18.75 15.75Z" fill="#C4C4C4" />
                      </svg></span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link btn btn-sm btn-color-primary btn-active btn-active-primary fw-bold px-4 me-1"
                      data-bs-target="#kt_modal_export"
                      data-bs-toggle="modal"
                    >
                      Export Rules
                      <span class="svg-icon svg-icon-primary svg-icon-2 ms-1"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.3" x="12.75" y="4.25" width="12" height="2" rx="1" transform="rotate(90 12.75 4.25)" fill="black" />
                        <path d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z" fill="black" />
                        <path d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z" fill="#C4C4C4" />
                      </svg></span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bold px-4 me-1 active"
                      data-bs-toggle="tab"
                      href="#rule_tab_1"
                    >
                      Rules
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bold px-4 me-1 "
                      data-bs-toggle="tab"
                      href="#server_tab_2"
                    >
                      Servers
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bold px-4"
                      data-bs-toggle="tab"
                      href="#destroy_tab_3"
                    >
                      Destroy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <form id="uploadBulkData">
              <input
                type="hidden"
                name="rules"
                value={bulkData} />
            </form>
            {/*end::Header*/}
            {/*begin::incoming rules*/}
            <hr />
            <div className="card-body py-3">
              <div className="tab-content">
                {/*begin::Tap pane*/}
                <div className="tab-pane fade show active" id="rule_tab_1">
                  <i className="bi bi-arrow-down-left-square-fill fs-2x text-success d-none" />
                  <span className="card-label fw-bold fs-3 ms-1 mb-1">Incoming Rules</span>
                  {/*begin::Table container*/}
                  <div className="table-responsive">
                    {/*begin::Table*/}
                    <table className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9">
                      {/*begin::Table head*/}
                      <thead>
                        <tr className="fw-bold">
                          <th className="w-100px fw-bold">Service</th>
                          <th className="w-100px text-center fw-bold">Protocol</th>
                          <th className="w-100px text-center fw-bold">Port Range</th>
                          <th className="w-100px text-center fw-bold">Sources</th>
                          <th className="w-100px d-none" />
                          <th className="w-100px" />
                        </tr>
                      </thead>
                      {/*end::Table head*/}
                      <tbody id="incoming_rules_list">
                        {firewallData != null && (firewallData.rules && firewallData.rules.map((value, index) => (
                          <>
                            {value.type == 'incoming' ? (
                              <tr>
                                {" "}
                                <td className="fw-bold">
                                  {" "}
                                  <div className="symbol symbol-45px me-2"> {value.service} </div>{" "}
                                </td>{" "}
                                <td className="fw-bold text-center">
                                  {" "}
                                  <div className="symbol symbol-45px me-2"> {value.protocol} </div>{" "}
                                </td>{" "}
                                <td className="fw-bold text-center">
                                  {" "}
                                  <div className="position-relative me-md-2"> {value.port} </div>{" "}
                                </td>{" "}
                                <td className="fw-bold text-center">
                                  {" "}
                                  <div className="position-relative me-md-2"> {value.addresses == '0' ? ('ALL') : (value.addresses)} </div>{" "}
                                </td>{" "}
                                <td className="d-none">
                                  <a
                                    className="btn btn-success btn-sm"
                                    data-bs-toggle="modal"
                                    data-bs-target="#update-incoming"
                                    onclick="updater_incoming_rules('SSH','TCP','22','ALL')"
                                  >
                                    Update
                                  </a>{" "}
                                </td>
                                <td className="text-end">
                                  {" "}
                                  <a
                                    className="btn btn-danger btn-sm"
                                    onClick={() => deleteFirewallRule(value.id, "Incoming")}
                                  >
                                    &nbsp;&nbsp;Delete&nbsp;&nbsp;
                                  </a>
                                </td>
                              </tr>
                            ) : ('')}
                          </>
                        )))}
                      </tbody>
                    </table>
                    {/*begin::Table body*/}
                    <form id="addincomingrule" name="addincomingrule">
                      <input
                        type="hidden"
                        id="type"
                        name="type"
                        defaultValue="incoming"
                      />
                      <table className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9">
                        <tbody>
                          <tr>
                            <td className="text-start w-300px ps-7">
                              <div className="symbol symbol-45px me-2">
                                <select
                                  className="form-select"
                                  name="service"
                                  id="service_incoming"
                                  onChange={() => setFirewallRule("incoming")}
                                >
                                  <option value="SSH">SSH</option>
                                  <option value="RDP">RDP</option>
                                  <option value="HTTP">HTTP</option>
                                  <option value="HTTPS">HTTPS</option>
                                  <option value="MYSQL">MYSQL</option>
                                  <option value="MSSQL">MSSQL</option>
                                  <option value="DNS TCP">DNS TCP</option>
                                  <option value="DNS UDP">DNS UDP</option>
                                  <option value="ALL TCP">ALL TCP</option>
                                  <option value="ALL UDP">ALL UDP</option>
                                  <option value="PING">PING</option>
                                  <option value="CUSTOM">CUSTOM</option>
                                </select>

                              </div>
                            </td>
                            {/* <td>
                      <div className="symbol symbol-45px me-2">
                        <select
                          className="form-select form-solid-select"
                          name="protocol"
                          id="protocol_incoming"
                        >
                          <option value="tcp">TCP</option>
                          
                        </select>
                      </div>
                    </td> */}
                            <td className="text-start w-200px ps-8">
                              <div className="symbol symbol-45px me-2">
                                <select
                                  className="form-select"
                                  name="protocol"
                                  id="protocol_incoming"
                                >
                                  {incomingOption.length > 0 ? (<>
                                    {incomingOption.map((value, index) => (
                                      <>{value}</>
                                    ))}
                                  </>) : (<></>)}
                                  {/* <option value='tcp'>TCP</option>
                          <option value='udp'>UDP</option>
                          <option value='ICMP'>ICMP</option> */}
                                </select>

                              </div>
                            </td>
                            <td className="text-center w-100px text-muted fw-bold pe-10">
                              <div
                                className="position-relative w-md-100px me-md-2"
                                id="port_incoming"
                              >
                                <input
                                  type="text"
                                  className="form-control "
                                  id="port_tincoming"
                                  name="port"
                                  minvalue={1}
                                  maxvalue={65444}
                                  placeholder="22 OR 5001:5003"
                                  defaultValue={22}
                                />
                              </div>
                            </td>
                            <td className="text-end w-100px">
                              <div className="position-relative w-md-100px me-md-2">
                                <input
                                  type="text"
                                  className="form-control "
                                  id="addresses"
                                  name="addresses"
                                  placeholder="0.0.0.0"
                                  defaultValue="ALL"
                                />
                              </div>
                            </td>
                            <td>
                              <div className="symbol symbol-45px me-2"></div>
                            </td>
                            <td className="text-end pt-7">
                              <a
                                className="btn btn-sm btn-primary button sphide"
                                onClick={addIncomingFirewall}
                              >
                                + Add New
                              </a>
                            </td>
                          </tr>
                        </tbody>
                        {/*end::Table body*/}
                      </table>
                    </form>
                  </div>
                  <hr />
                  <i className="bi bi-arrow-up-right-square-fill fs-2x text-danger d-none" />
                  <span className="card-label fw-bold fs-3 ms-1 mb-1">Outgoing Rules</span>
                  {/*begin::Table container*/}
                  <div className="table-responsive">
                    {/*begin::Table*/}
                    <table className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9">
                      {/*begin::Table head*/}
                      <thead>
                        <tr className="fw-bold">
                          <th className="w-100px fw-bold">Service</th>
                          <th className="w-100px text-center fw-bold">Protocol</th>
                          <th className="w-100px text-center fw-bold">Port Range</th>
                          <th className="w-100px text-center fw-bold">Sources</th>
                          <th className="w-100px d-none" />
                          <th className="w-100px" />
                        </tr>
                      </thead>
                      {/*end::Table head*/}
                      <tbody id="outgoing_rules_list">
                        {firewallData != null && (firewallData.rules && firewallData.rules.map((value, index) => (
                          <>
                            {value.type == 'outgoing' ? (
                              <tr>
                                {" "}
                                <td className="fw-bold">
                                  {" "}
                                  <div className="symbol symbol-45px me-2"> {value.service} </div>{" "}
                                </td>{" "}
                                <td className="fw-bold text-center">
                                  {" "}
                                  <div className="symbol symbol-45px me-2"> {value.protocol} </div>{" "}
                                </td>{" "}
                                <td className="fw-bold text-center">
                                  {" "}
                                  <div className="position-relative me-md-2"> {value.port} </div>{" "}
                                </td>{" "}
                                <td className="fw-bold text-center">
                                  {" "}
                                  <div className="position-relative me-md-2"> {value.addresses == '0' ? ('ALL') : (value.addresses)} </div>{" "}
                                </td>{" "}
                                <td className="d-none">
                                  <a
                                    className="btn btn-success btn-sm"
                                    data-bs-toggle="modal"
                                    data-bs-target="#update-incoming"
                                    onclick="updater_incoming_rules('SSH','TCP','22','ALL')"
                                  >
                                    Update
                                  </a>{" "}
                                </td>
                                <td className="text-end">
                                  {" "}
                                  <button
                                    className="btn btn-danger btn-sm"
                                    onClick={() => deleteFirewallRule(value.id, 'Outgoing')}
                                  >
                                    &nbsp;&nbsp;Delete&nbsp;&nbsp;
                                  </button>
                                </td>
                              </tr>
                            ) : ('')}
                          </>
                        )))}
                      </tbody>
                    </table>
                    {/*begin::Table body*/}
                    <form id="addoutgoingrule" name="addoutgoingrule">
                      <input
                        type="hidden"
                        id="type"
                        name="type"
                        defaultValue="outgoing"
                      />
                      <table className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9">
                        <tbody>
                          <tr>
                            <td className="text-start w-300px ps-7">
                              <div className="symbol symbol-45px me-2">
                                <select
                                  className="form-select "
                                  name="service"
                                  id="service_outgoing"
                                  onChange={() => setFirewallRule("outgoing")}
                                >
                                  <option value="SSH">SSH</option>
                                  <option value="RDP">RDP</option>
                                  <option value="HTTP">HTTP</option>
                                  <option value="HTTPS">HTTPS</option>
                                  <option value="MYSQL">MYSQL</option>
                                  <option value="MSSQL">MSSQL</option>
                                  <option value="DNS TCP">DNS TCP</option>
                                  <option value="DNS UDP">DNS UDP</option>
                                  <option value="ALL TCP">ALL TCP</option>
                                  <option value="ALL UDP">ALL UDP</option>
                                  <option value="PING">PING</option>
                                  <option value="CUSTOM">CUSTOM</option>
                                </select>
                              </div>
                            </td>
                            <td className="text-start w-200px ps-8">
                              <div className="symbol symbol-45px me-2">
                                <select
                                  className="form-select"
                                  name="protocol"
                                  id="protocol_outgoing"
                                >
                                  {outgoingOption.length > 0 ? (<>
                                    {outgoingOption.map((value, index) => (
                                      <>{value}</>
                                    ))}
                                  </>) : (<></>)}
                                  {/* <option value='tcp'>TCP</option>
                          <option value='udp'>UDP</option>
                          <option value='ICMP'>ICMP</option> */}
                                </select>
                              </div>
                            </td>
                            <td className="text-center w-100px text-muted fw-bold pe-10">
                              <div
                                className="position-relative w-md-100px me-md-2"
                                id="port_outgoing"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="port_toutgoing"
                                  name="port"
                                  minvalue={1}
                                  maxvalue={65444}
                                  placeholder="22 OR 5001:5003"
                                  defaultValue={22}
                                />
                              </div>
                            </td>
                            <td className="text-end w-100px">
                              <div className="position-relative w-md-100px me-md-2">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="addresses"
                                  name="addresses"
                                  placeholder="0.0.0.0"
                                  defaultValue="ALL"
                                />
                              </div>
                            </td>
                            <td>
                              <div className="symbol symbol-45px me-2"></div>
                            </td>
                            <td className="text-end ps-0 pt-5">
                              <a
                                className="btn btn-primary btn-sm sphide"
                                onClick={addOutgoingFirewall}
                              >
                                + Add New
                              </a>
                            </td>
                          </tr>
                        </tbody>
                        {/*end::Table body*/}
                      </table>
                    </form>
                  </div>
                </div>
                {/*end::Tap pane*/}
                {/*begin::Tap pane*/}
                <div className="tab-pane fade " id="server_tab_2">
                  <div className="card-header border-0 p-0">
                    <h3 className="card-title align-items-start flex-column ms-1">
                      <span className="text-primary card-label fw-bold fs-5 mb-1">
                        Add Servers
                      </span>
                      <span className="text-muted mt-1 fw-bold fs-7">
                        You can add multiple servers in your single firewall.
                      </span>
                      <div id="server_option" />
                    </h3>
                  </div>
                  <form id="addserver">
                    {/*begin::Table container*/}
                    <div className="d-flex align-items-center">
                      {/*begin::Input group*/}
                      <div className="symbol symbol-45px me-2">
                        <select
                          className="form-select"
                          id="select_server"
                          name="cloudid"
                          aria-label="Select example"
                        > <option value={0}>Select Servers or Apps</option>
                          {serverForFw != null ? (serverForFw && serverForFw.map((value, index) => (
                            <option value={value.cloudid}>{value.hostname + ' ' + value.ip}</option>
                          ))) : ('')}


                        </select>
                      </div>
                      {/*end::Input group*/}
                      {/*begin:Action*/}
                      <div className="d-flex align-items-center">
                        <a
                          className="btn btn-primary me-5 sphide"
                          onClick={addFirewallServer}
                        >
                          Add Server
                        </a>
                      </div>
                      {/*end:Action*/}
                    </div>
                  </form>
                  <hr />
                  <>
                    {firewallData != null && firewallData.servers && firewallData.servers.length > 0 ? <>
                      <h3 className="card-title  align-items-start flex-column">
                        <span className="text-center card-label fw-bold fs-5 mb-1 ms-1">
                          Firewall Servers
                        </span>
                      </h3>
                      <div className="table-responsive">
                        {/*begin::Table*/}
                        <table className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9">
                          {/*begin::Table head*/}
                          <thead>
                            <tr className="fw-bold">
                              <th className="w-300px">Name</th>
                              <th className="w-300px ps-12">IP</th>
                              <th className="w-100px" />
                              <th className="w-100px" />
                            </tr>
                          </thead>
                          {/*end::Table head*/}
                          <tbody id="firewall-server-add">
                            {firewallData != null && (firewallData.servers && firewallData.servers.map((value, index) => (<>
                              {" "}
                              <tr className="">
                                <td className="fw-bold">
                                  {" "}
                                  <div className="symbol symbol-45px me-2">
                                    {" "}
                                    {value.name}
                                  </div>
                                  <br />{" "}
                                  <span className="text-muted mt-1 fw-bold fs-9">
                                    Location: {value.city}
                                  </span>{" "}
                                </td>{" "}
                                <td className="fw-bold">
                                  {" "}
                                  <div className="symbol symbol-45px me-2">
                                    {" "}
                                    {value.ip}
                                  </div>{" "}
                                </td>{" "}
                                <td></td>
                                <td className="text-end d-none">
                                  {" "}
                                  <Link to={"/cloud/" + value.cloudid}>
                                    <a
                                      className="btn btn-success btn-sm sphide"
                                    >
                                      View Cloud{" "}
                                    </a>
                                  </Link>
                                </td>{" "}
                                <td className="text-end">
                                  {" "}
                                  <button
                                    className="btn btn-danger btn-sm"
                                    onClick={() => deleteFirewallServers(value.cloudid)}
                                  >
                                    Delete
                                  </button>{" "}
                                </td>{" "}
                              </tr>{" "}
                            </>)))}
                          </tbody>
                        </table>
                      </div>
                    </>
                      :
                      ""
                    }
                  </>
                </div>
                {/*end::Tap pane*/}
                {/*begin::Tap pane*/}
                <div className="tab-pane fade" id="destroy_tab_3">
                  {/*begin::Deactivate Account*/}
                  <div className="p-0">
                    {/*begin::Card header*/}
                    <div
                      className="card-header border-0 cursor-pointer p-0"
                      role="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#kt_account_deactivate"
                      aria-expanded="true"
                      aria-controls="kt_account_deactivate"
                    >
                      <div className="card-title m-0">
                        <h3 className="fw-bold m-0 ms-1">Destroy Firewall</h3>
                      </div>
                    </div>
                    {/*end::Card header*/}
                    {/*begin::Content*/}
                    <div id="kt_account_settings_deactivate" className="collapse show">
                      {/*begin::Form*/}
                      <form id="destroyform" className="form">
                        {/*begin::Card body*/}
                        <div className="card-body border-top p-0">
                          {/*begin::Notice*/}
                          <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
                            {/*begin::Icon*/}
                            {/*begin::Svg Icon | path: icons/duotune/general/gen044.svg*/}
                            <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <rect
                                  opacity="0.3"
                                  x={2}
                                  y={2}
                                  width={20}
                                  height={20}
                                  rx={10}
                                  fill="black"
                                />
                                <rect
                                  x={11}
                                  y={14}
                                  width={7}
                                  height={2}
                                  rx={1}
                                  transform="rotate(-90 11 14)"
                                  fill="black"
                                />
                                <rect
                                  x={11}
                                  y={17}
                                  width={2}
                                  height={2}
                                  rx={1}
                                  transform="rotate(-90 11 17)"
                                  fill="black"
                                />
                              </svg>
                            </span>
                            {/*end::Svg Icon*/}
                            {/*end::Icon*/}
                            {/*begin::Wrapper*/}
                            <div className="d-flex flex-stack flex-grow-1">
                              {/*begin::Content*/}
                              <div className="fw-bold">
                                <h4 className="text-gray-900 fw-bold">
                                  Your Firewall will be destroyed, and any cloud server
                                  will be dissociated from them.
                                </h4>
                                <div className="fs-6 text-gray-700">
                                  Once this happens, the cloud servers will allow any
                                  type of inbound and outbound traffic, unless you’ve
                                  configured a software firewall in them, or they still
                                  belong to other Firewalls.
                                </div>
                              </div>
                              {/*end::Content*/}
                            </div>
                            {/*end::Wrapper*/}
                          </div>
                          {/*end::Notice*/}
                          {/*begin::Form input row*/}
                          {/*end::Form input row*/}
                        </div>
                        {/*end::Card body*/}
                        {/*begin::Card footer*/}
                        <div className="card-footer d-flex justify-content-end py-6 px-9">
                          <button
                            onClick={destroyFirewall}
                            type="button"
                            className="btn btn-danger fw-bold"
                          >
                            Destroy Firewall
                          </button>
                        </div>
                        {/*end::Card footer*/}
                      </form>
                      {/*end::Form*/}
                    </div>
                    {/*end::Content*/}
                  </div>
                  {/*end::Deactivate Account*/}
                </div>
                {/*end::Tap pane*/}
              </div>
              {pageStatus &&
                <TopBarHeaderLine />}
            </div>
            {/*end::incoming rules*/}
          </div>
          <div
            className="modal fade"
            id="kt_modal_upload"
            tabIndex={-1}
            aria-hidden="true"
          >
            {/*begin::Modal dialog*/}
            <div className="modal-dialog modal-dialog-centered mw-650px">
              {/*begin::Modal content*/}
              <div className="modal-content">
                {/*begin::Form*/}
                <form className="form" action="none" id="kt_modal_upload_form">
                  {/*begin::Modal header*/}
                  <div className="modal-header">
                    {/*begin::Modal title*/}
                    <h2 className="fw-bolder">Upload files</h2>
                    {/*end::Modal title*/}
                    {/*begin::Close*/}
                    <div
                      className="btn btn-icon btn-sm btn-active-icon-primary"
                      data-bs-dismiss="modal"
                    >
                      {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                      <span className="svg-icon svg-icon-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <rect
                            opacity="0.5"
                            x={6}
                            y="17.3137"
                            width={16}
                            height={2}
                            rx={1}
                            transform="rotate(-45 6 17.3137)"
                            fill="black"
                          />
                          <rect
                            x="7.41422"
                            y={6}
                            width={16}
                            height={2}
                            rx={1}
                            transform="rotate(45 7.41422 6)"
                            fill="black"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}
                    </div>
                    {/*end::Close*/}
                  </div>
                  {/*end::Modal header*/}
                  {/*begin::Modal body*/}
                  <div className="modal-body pt-10 pb-15 px-lg-17">
                    {/*begin::Input group*/}
                    <div className="form-group">
                      {/*begin::Dropzone*/}
                      <div
                        className="dropzone dropzone-queue mb-2"
                        id="kt_modal_upload_dropzone"
                      >
                        {/*begin::Controls*/}
                        <div className="dropzone-panel mb-4">
                          <a className="dropzone-select btn btn-sm btn-primary me-2"
                            onClick={handleAttachFilesClick}
                          // onDragStart={handleDragStart}
                          // onDragOver={handleDragOver}
                          // onDragEnter={handleDragEnter}
                          // onDragLeave={handleDragLeave}
                          // onDrop={handleDrop}
                          >
                            Attach files
                          </a>
                          <div className="border btn rounded border-dashed border-primary d-none">
                            <div style={{
                              height: '5rem',
                              // width: '4rem',
                              marginTop: '4rem',
                              marginLeft: '13rem'
                            }}>
                              <span className="svg-icon svg-icon-primary svg-icon-1 ms-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 22h12a2 2 0 0 0 2-2V8l-6-6H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2zm7-18l5 5h-5V4zm-4.5 7a1.5 1.5 0 1 1-.001 3.001A1.5 1.5 0 0 1 8.5 11zm.5 5l1.597 1.363L13 13l4 6H7l2-3z" fill="currentColor" /></svg>
                              </span>
                            </div>
                          </div>
                          <a className="dropzone-upload btn btn-sm btn-light-primary me-2" id="uploadData" onClick={handleSubmitData}>
                            Upload
                          </a>
                          <a className="dropzone-remove-all btn btn-sm btn-light-primary">
                            Remove All
                          </a>
                        </div>
                        <input
                          ref={fileInputRef}
                          type="file"
                          className="visually-hidden"
                          accept=".csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          onChange={handleFileChange}

                        />
                        {/*end::Controls*/}
                        {/*begin::Items*/}
                        <div className="dropzone-items wm-200px">
                          <div className="dropzone-item p-5" id="dropzoneData" style={{ display: "none" }}>
                            {/*begin::File*/}
                            <div className="dropzone-file">
                              <div
                                className="dropzone-filename text-dark"
                                title="some_image_file_name.jpg"
                              >
                                <span data-dz-name="">{fileData?.name}</span>
                                <strong>
                                  (<span data-dz-size="">{(fileData?.size / (1024 * 1024)).toFixed(3)}</span>)
                                </strong>
                              </div>
                              <div
                                className="dropzone-error mt-0"
                                data-dz-errormessage=""
                              />
                            </div>
                            {/*end::File*/}
                            {/*begin::Progress*/}
                            <div className="dropzone-progress">
                              <div className="progress bg-light-primary">
                                <div
                                  className="progress-bar bg-primary"
                                  role="progressbar"
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                  aria-valuenow={0}
                                  data-dz-uploadprogress=""
                                />
                              </div>
                            </div>
                            {/*end::Progress*/}
                            {/*begin::Toolbar*/}
                            <div className="dropzone-toolbar">
                              <span className="dropzone-start d-none">
                                <i className="bi bi-play-fill fs-3" />
                              </span>
                              <span
                                className="dropzone-cancel"
                                data-dz-remove=""
                                style={{ display: "none" }}
                              >
                                <i className="bi bi-x fs-3" />
                              </span>
                              <span className="dropzone-delete" data-dz-remove="" onClick={handleRemoveData}>
                                <i className="bi bi-x fs-1" />
                              </span>
                            </div>
                            {/*end::Toolbar*/}
                          </div>
                        </div>
                        {/*end::Items*/}
                      </div>
                      {/*end::Dropzone*/}
                      {/*begin::Hint*/}
                      <span className="form-text fs-6 text-muted">
                        Max file size is 1MB per file.
                      </span>
                      {/*end::Hint*/}
                    </div>
                    {/*end::Input group*/}
                  </div>
                  {/*end::Modal body*/}
                </form>
                {/*end::Form*/}
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="kt_modal_export"
            tabIndex={-1}
            aria-hidden="true"
          >
            {/*begin::Modal dialog*/}
            <div className="modal-dialog modal-dialog-centered mw-650px">
              {/*begin::Modal content*/}
              <div className="modal-content">
                {/*begin::Form*/}
                <form className="form" action="none" id="kt_modal_export_form">
                  {/*begin::Modal header*/}
                  <div className="modal-header">
                    {/*begin::Modal title*/}
                    <h2 className="fw-bolder">Export Rules</h2>
                    {/*end::Modal title*/}
                    {/*begin::Close*/}
                    <div
                      className="btn btn-icon btn-sm btn-active-icon-primary"
                      data-bs-dismiss="modal"
                    >
                      {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                      <span className="svg-icon svg-icon-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <rect
                            opacity="0.5"
                            x={6}
                            y="17.3137"
                            width={16}
                            height={2}
                            rx={1}
                            transform="rotate(-45 6 17.3137)"
                            fill="black"
                          />
                          <rect
                            x="7.41422"
                            y={6}
                            width={16}
                            height={2}
                            rx={1}
                            transform="rotate(45 7.41422 6)"
                            fill="black"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}
                    </div>
                    {/*end::Close*/}
                  </div>
                  {/*end::Modal header*/}
                  {/*begin::Modal body*/}
                  <div className="modal-body pt-10 pb-15 px-lg-17">
                    {/*begin::Input group*/}
                    <div className="form-group">
                      {/*begin::Dropzone*/}
                      <div
                        className="dropzone dropzone-queue mb-2"
                        id="kt_modal_export_dropzone"
                      >
                        {/*begin::Controls*/}
                        <div className="dropzone-panel mb-4">
                          <div className="row">
                            <div className="col-4">
                              <select className="form-select form-select-sm" onChange={(e) => setRuleType(e.target.value)}>
                                <option value="incoming">Incoming Rules</option>
                                <option value="outgoing">Outgoing Rules</option>
                                {/* <option value="pdf">PDF</option> */}
                              </select>
                            </div>
                            <div className="col-4">
                              <select className="form-select form-select-sm" onChange={(e) => setFormate(e.target.value)}>
                                <option value="csv">CSV</option>
                                <option value="excel">EXCEL</option>
                                <option value="pdf">PDF</option>
                              </select>
                            </div>
                            <div className="col-4">
                              <a className="dropzone-select btn btn-sm btn-primary me-2" onClick={handleExportFile}>
                                Export Rules
                              </a>
                            </div>
                          </div>
                          <a className="dropzone-upload btn btn-sm btn-light-primary me-2" id="uploadData" onClick={handleSubmitData}>
                            Upload
                          </a>
                          <a className="dropzone-remove-all btn btn-sm btn-light-primary">
                            Remove All
                          </a>
                        </div>
                        <input
                          ref={fileInputRef}
                          type="file"
                          className="visually-hidden"
                          accept=".csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          onChange={handleFileChange}

                        />
                        {/*end::Controls*/}
                        {/*begin::Items*/}
                        <div className="dropzone-items wm-200px">
                          <div className="dropzone-item p-5" id="dropzoneData" style={{ display: "none" }}>
                            {/*begin::File*/}
                            <div className="dropzone-file">
                              <div
                                className="dropzone-filename text-dark"
                                title="some_image_file_name.jpg"
                              >
                                <span data-dz-name="">{fileData?.name}</span>
                                <strong>
                                  (<span data-dz-size="">{(fileData?.size / (1024 * 1024)).toFixed(3)}</span>)
                                </strong>
                              </div>
                              <div
                                className="dropzone-error mt-0"
                                data-dz-errormessage=""
                              />
                            </div>
                            {/*end::File*/}
                            {/*begin::Progress*/}
                            <div className="dropzone-progress">
                              <div className="progress bg-light-primary">
                                <div
                                  className="progress-bar bg-primary"
                                  role="progressbar"
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                  aria-valuenow={0}
                                  data-dz-uploadprogress=""
                                />
                              </div>
                            </div>
                            {/*end::Progress*/}
                            {/*begin::Toolbar*/}
                            <div className="dropzone-toolbar">
                              <span className="dropzone-start d-none">
                                <i className="bi bi-play-fill fs-3" />
                              </span>
                              <span
                                className="dropzone-cancel"
                                data-dz-remove=""
                                style={{ display: "none" }}
                              >
                                <i className="bi bi-x fs-3" />
                              </span>
                              <span className="dropzone-delete" data-dz-remove="" onClick={handleRemoveData}>
                                <i className="bi bi-x fs-1" />
                              </span>
                            </div>
                            {/*end::Toolbar*/}
                          </div>
                        </div>
                        {/*end::Items*/}
                      </div>
                      {/*end::Dropzone*/}
                      {/*begin::Hint*/}
                      <span className="form-text fs-6 text-muted d-none">
                        Max file size is 1MB per file.
                      </span>
                      {/*end::Hint*/}
                    </div>
                    {/*end::Input group*/}
                  </div>
                  {/*end::Modal body*/}
                </form>
                {/*end::Form*/}
              </div>
            </div>
          </div>

          {/*end::Tables Widget 5*/}
        </div>
      </>) : (<><TopBarHeaderLine /></>)}
    </>
  )
}