import UseCallApi from "./useCallApi";
var serialize = require("form-serialize");
export default async function UsePost(type, url, formid) {
  // console.log(type,url,formid);
  var form = document.querySelector("#" + formid);
  var requestData = serialize(form, { hash: true });
  // console.log(requestData);
  // if (form.elements.attachment) {
  //         // If the file input with the name "attachment" exists in the form
  //         const fileInput = form.elements.attachment;
  //         if (fileInput.files.length > 0) {
  //           // If a file is selected, add it to FormData
  //           requestData.attachment = fileInput.files[0];
  //         }
  //       }
  // console.log(requestData);
  const temp = await UseCallApi(type, url, requestData);

  return temp;
}
