import React, { useEffect, useState } from 'react';
import UseLogin from '../CustomHooks/useLogin';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useParams } from 'react-router-dom';
import localStorage from 'local-storage';

const ApplyCoupon = () => {
    var current_url_string = window.location.href;
      const current_url = new URL(current_url_string);
      const current_domain = current_url.hostname;
    // let {coupon} = useParams() 
   const [couponMessage, setCouponMessage]=useState(null);
    const getCoupon=async(coupon_name)=>{ 
        var baseUrl="https://api.utho.com";
  const platformName=localStorage.get("platformType");
  if(platformName){
    if(platformName=="dev"){
     baseUrl= "https://dev.api.cloudplatformapp.com";
    }
  } 
        const res=await axios.get(baseUrl+"/v2/coupon/"+coupon_name);
        // console.log(res);
        if(res && res.data.status=="success"){ 
            setCouponMessage(res.data.message);
            Cookies.set('coupon_message', res.data.message, { expires: 30, path: '/', domain: "."+current_domain });
         }
    } 
    useEffect(()=>{ 
        let couponNmae  = Cookies.get('coupon');
        if(couponNmae){
            var coupon_message=Cookies.get('coupon_message');
            if(coupon_message){
                setCouponMessage(coupon_message);
            }else{
             getCoupon(couponNmae);
            } 
        } 
        
    },[])
    return (
        <>
        {couponMessage!=null?(<>
        <div className="alert alert-success text-center fs-2" style={{marginBottom:"-3px"}}>{couponMessage}</div>
        </>):("")}
        </>
    );
}

export default ApplyCoupon;
