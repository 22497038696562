// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-header-custom {
    background-color: #ffffff;  
    padding: 16px;  
    position: relative;  
  }
  
  .card-header-custom::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8px;  
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    
   }`, "",{"version":3,"sources":["webpack://./src/App/PartnerApp/Body/Products/ticket/custom.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,WAAW;IACX,WAAW;IACX,wCAAwC;;GAEzC","sourcesContent":[".card-header-custom {\n    background-color: #ffffff;  \n    padding: 16px;  \n    position: relative;  \n  }\n  \n  .card-header-custom::after {\n    content: \"\";\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    width: 100%;\n    height: 8px;  \n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n    \n   }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
