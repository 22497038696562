import React, { useEffect, useRef, useState } from "react";
import UseDevApi from "../../../../CustomHooks/useDevApi";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; 
import swal from "sweetalert";  
import Resize from "./tabs/Resize";
import Attach from "./tabs/Attach";
import Configuration from "./tabs/Configuration";
import TableLoader from "../../Loaders/TableLoader";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
export default function ManageArchiveStorage() {
  
  var query = window.location.href; 
  var vars = query.split("/"); 
  var id=vars[4]; 

  const navigate=useNavigate();
  const navRedirect=()=>{
    setTimeout(() => {
      navigate('/archiveStorage');
     }, 2000);
      
  }
const dataFetchedRef=useRef(false); 
const [archiveData, setArchiveData] = useState(null);

const getArchiveStorage=async()=>{   
  const data=await UseDevApi('get','archive/'+id,'');
  //console.log(data);
  
  if(data.status == "error"){
    setArchiveData(null);
    swal({
        title: "No Record found ",
        text: "We are unable to find record with this Id: "+id+"!",
        icon: "warning",
        button: "OK",
      });
      navRedirect();
  }else{ 
    if(data.archiveStorage){
    setArchiveData(data.archiveStorage[0]);
     
  } 
 }
}  

const deleteArchive=async()=>{
  var submitBtn=document.querySelector("#destroy-btn"); 
   
  if(submitBtn){
    var preContent=submitBtn.innerHTML;
    submitBtn.classList.add("disabled");
    submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>'; 

  toast.dismiss();
  swal({
    title:"Are you sure?",
    text: "Once deleted, you will not be able to recover this archive storage!",
    icon: "warning",
    buttons: true,
    dangerMode: true,
    })
    .then(async(willDelete) => {
    if(willDelete) {
      const dt=toast.loading("Archive Storage deleting..."); 
      const dnsp=await UseDevApi('DELETE','archive/'+id+'/delete','');
      //console.log(dnsp);
      if(dnsp.status=='error'){
        toast.update(dt,{render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      }else{
        toast.update(dt,{render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });  
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML=preContent;
      navRedirect();
      }
    } 
    });
  }
  
}

useEffect(()=>{  
  if(dataFetchedRef.current) return;
    dataFetchedRef.current=true; 
    getArchiveStorage();  
},[]);   
if(archiveData!=null){
  return (
    <>  
      <div className="card mb-6 mb-xl-9 ">
        <div className="card-body pt-9 pb-0 ">
          <div className="d-flex flex-wrap flex-sm-nowrap mb-6">
            <div
              className="d-flex flex-center flex-shrink-0 bg-light rounded w-55px h-55px w-lg-55px h-lg-55px me-7 mb-4"
              id="imageDist"
            >
              <span className="w-50">
              <svg
  fill="#494b74"
  viewBox="0 0 38 32"
  id="icon"
  xmlns="http://www.w3.org/2000/svg"
  width={44}
  height={44}
>
  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
  <g id="SVGRepo_iconCarrier">
    <defs>
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n                                .cls-1 {\n                                    fill: none;\n                                }\n                            "
        }}
      />
    </defs>
    <path d="M28,20H26v2h2v6H4V22H14V20H4a2.0023,2.0023,0,0,0-2,2v6a2.0023,2.0023,0,0,0,2,2H28a2.0023,2.0023,0,0,0,2-2V22A2.0023,2.0023,0,0,0,28,20Z" />
    <circle cx={7} cy={25} r={1} />
    <path d="M30,8H22v6H16v8h8V16h6ZM22,20H18V16h4Zm6-6H24V10h4Z" />
    <path d="M18,10H10V2h8ZM12,8h4V4H12Z" />
  </g>
</svg>
              </span>
            </div>
            <div className="flex-grow-1">
              <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-1" id="showHost">
                    <a
                      className="text-gray-800 text-hover-primary fs-2 fw-bolder me-3"
                     
                    >
                      {archiveData.label}
                    </a>
                    <a></a>
                  </div>
                  <div className="d-flex flex-wrap fw-bold mb-4 fs-5 text-gray-400">
                    Size - &nbsp;<span className="fw-bolder"> {archiveData.size} GB</span> /
                    Linked to - &nbsp;
                    <Link to={"/"+archiveData.cloudid}><span className="fw-bolder"> cloudserver-{archiveData.cloudid}</span></Link>
                  </div>
                </div>
                <div className="d-flex" id="console">
                  <div className="d-flex flex-stack mb-5">
                    {/*begin::Section*/}
                    <div
                      className="d-flex align-items-center me-5"
                      style={{
                        paddingRight: "28px",
                        borderRight: "3px solid #b5b5c3",
                      }}
                    >
                      {/*begin::Symbol*/}
                      <div className="symbol symbol-50px me-8">
                        <div className="">
                        {archiveData.location && archiveData.location.dczones?(<> 
                            <img
                            src={"/img/flag_"+archiveData.location.dczones[0].cc+".png"}
                            className="h-50 rounded"
                            alt=""
                          />
                 </>):('')}
                          
                        </div>
                      </div>
                      {/*end::Symbol*/}
                      {/*begin::Title*/}
                      <div>
                        <a
                          className="fs-6 text-gray-800 text-hover-primary fw-bolder"
                        >
                         {archiveData.location && archiveData.location.dczones?(<> 
                            {archiveData.location.dczones[0].city}
                 </>):('')}
                        </a>
                        <div className="fs-7 text-muted fw-bold mt-1">
                        {archiveData.location && archiveData.location.dczones?(<> 
                            {archiveData.location.dczones[0].country}
                 </>):('')}
                        </div>
                      </div>
                      {/*end::Title*/}
                    </div>
                    {/*end::Section*/}
                    {/*begin::Label*/}
                    {archiveData.status=="active"?(<><div className="btn btn-success fw-bold ms-5">Active</div></>):
                    (<><div className="btn btn-danger fw-bold ms-5">Inactive</div></>)}
                    
                    {/*end::Label*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="separator" />
          <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-trakt_charts_widget_1_chartnsparent fs-5 fw-bolder">
            <li className="nav-item">
              <a
                className="nav-link text-active-primary py-5 me-6 active sphide"
                data-bs-toggle="tab"
                href="#connection-details"
              >
                Configuration
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link text-active-primary py-5 me-6 d sphide"
                data-bs-toggle="tab"
                href="#access-control"
              >
                Resize
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link text-active-primary py-5 me-6"
                data-bs-toggle="tab"
                href="#backups"
              >
                Attach
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link text-active-primary py-5 me-6"
                data-bs-toggle="tab"
                href="#destroy"
              >
                Destroy
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="tab-content" id="myTabContent">
        <Configuration />
        <Resize data={archiveData} updateArchive={getArchiveStorage} />
         <Attach data={archiveData} updateArchive={getArchiveStorage} />
        <div className="tab-pane fade" id="destroy" role="tabpanel">
          <div className="card">
            <div
              className="card-header border-0 cursor-pointer"
              role="button"
              data-bs-toggle="collapse"
              data-bs-target="#kt_account_deactivate"
              aria-expanded="true"
              aria-controls="kt_account_deactivate"
            >
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">Destroy Cluster</h3>
              </div>
            </div>
            <div id="kt_account_settings_deactivate" className="collapse show">
              <form id="destroyform" className="form">
                <div className="card-body border-top p-9">
                  <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
                    <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={10}
                          fill="black"
                        />
                        <rect
                          x={11}
                          y={14}
                          width={7}
                          height={2}
                          rx={1}
                          transform="rotate(-90 11 14)"
                          fill="black"
                        />
                        <rect
                          x={11}
                          y={17}
                          width={2}
                          height={2}
                          rx={1}
                          transform="rotate(-90 11 17)"
                          fill="black"
                        />
                      </svg>
                    </span>
                    <div className="d-flex flex-stack flex-grow-1">
                      <div className="fw-bold">
                        <h4 className="text-gray-900 fw-bold">
                          This will destroy your Archive Storage.
                        </h4>
                        <div className="fs-6 text-gray-700">
                          Note: This option will delete your archive storage from the
                          server for permanently and this not be able to undo.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-10">
                    <label htmlFor="size" className="required form-label">
                      Your archive storage will be permanently destroyed.
                    </label>
                  </div>
                </div>
                <div
                  className="card-footer d-flex justify-content-end py-6 px-9"
                  id="destroy_btn"
                >
                  <button
                    id="destroy-btn"
                    type="button"
                    onClick={deleteArchive}
                    className="btn btn-danger fw-bold"
                  >
                    Destroy
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      
      </>);
      
    }else{
      return(<>
      <div>
      <TableLoader/>
      <TopBarHeaderLine/>
      </div>
      </>);
    }  
}
