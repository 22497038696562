function generateCode(language, method, apiEndpoint, data = null) {
    const tokenPlaceholder = "YOUR_BEARER_TOKEN";
    const dataPlaceholder = "data";

    const methods = {
        'get': 'GET',
        'post': 'POST',
        'put': 'PUT',
        'delete': 'DELETE'
    };

    if (!methods[method]) {
        return "Invalid method. Supported methods are: get, post, put, delete.";
    }

    const selectedMethod = methods[method];

    switch (language) {
        case 'Curl':
            let curlCode = `curl -X ${selectedMethod} ${apiEndpoint} -H 'Authorization: Bearer ${tokenPlaceholder}'`;
            if (selectedMethod === 'POST' || selectedMethod === 'PUT') {
                curlCode += ` -H 'Content-Type: application/json' -d '${dataPlaceholder}'`;
            }
            return curlCode;

        case 'Java':
            let javaCode = `import java.io.*;
import java.net.*;

public class HttpRequest {
    public static void main(String[] args) throws Exception {
        URL url = new URL("${apiEndpoint}");
        HttpURLConnection connection = (HttpURLConnection) url.openConnection();
        connection.setRequestMethod("${selectedMethod}");
        connection.setRequestProperty("Authorization", "Bearer ${tokenPlaceholder}");`;

            if (selectedMethod === 'POST' || selectedMethod === 'PUT') {
                javaCode += `
        connection.setRequestProperty("Content-Type", "application/json");
        connection.setDoOutput(true);
        DataOutputStream wr = new DataOutputStream(connection.getOutputStream());
        wr.writeBytes("${dataPlaceholder}");
        wr.flush();
        wr.close();`;
            }

            javaCode += `
        BufferedReader in = new BufferedReader(new InputStreamReader(connection.getInputStream()));
        String inputLine;
        StringBuffer content = new StringBuffer();

        while ((inputLine = in.readLine()) != null) {
            content.append(inputLine);
        }
        in.close();
        connection.disconnect();
        System.out.println(content.toString());
    }
}`;
            return javaCode;

        case 'Python':
            let pythonCode = `import requests

url = "${apiEndpoint}"
headers = {
    "Authorization": "Bearer ${tokenPlaceholder}",`;

            if (selectedMethod === 'POST' || selectedMethod === 'PUT') {
                pythonCode += `
    "Content-Type": "application/json"
}`;
            } else {
                pythonCode += `
}`;
            }

            if (selectedMethod === 'GET') {
                pythonCode += `
response = requests.get(url, headers=headers)`;
            } else if (selectedMethod === 'POST') {
                pythonCode += `
data = '${dataPlaceholder}'
response = requests.post(url, headers=headers, data=data)`;
            } else if (selectedMethod === 'PUT') {
                pythonCode += `
data = '${dataPlaceholder}'
response = requests.put(url, headers=headers, data=data)`;
            } else if (selectedMethod === 'DELETE') {
                pythonCode += `
response = requests.delete(url, headers=headers)`;
            }

            pythonCode += `
print(response.json())`;
            return pythonCode;

        case 'PHP':
            let phpCode = `<?php
$curl = curl_init();

curl_setopt_array($curl, array(
    CURLOPT_URL => "${apiEndpoint}",
    CURLOPT_RETURNTRANSFER => true,
    CURLOPT_CUSTOMREQUEST => "${selectedMethod}",
    CURLOPT_HTTPHEADER => array(
        "Authorization: Bearer ${tokenPlaceholder}",`;

            if (selectedMethod === 'POST' || selectedMethod === 'PUT') {
                phpCode += `
        "Content-Type: application/json"
    ),
    CURLOPT_POSTFIELDS => '${dataPlaceholder}',`;
            } else {
                phpCode += `
    ),
);`;
            }

            phpCode += `
$response = curl_exec($curl);
curl_close($curl);
echo $response;
?>`;
            return phpCode;

        case 'Ruby':
            let rubyCode = `require 'net/http'
require 'uri'

uri = URI.parse("${apiEndpoint}")
request = Net::HTTP::${selectedMethod.capitalize}.new(uri)
request["Authorization"] = "Bearer ${tokenPlaceholder}"`;

            if (selectedMethod === 'POST' || selectedMethod === 'PUT') {
                rubyCode += `
request["Content-Type"] = "application/json"
request.body = '${dataPlaceholder}'`;
            }

            rubyCode += `
response = Net::HTTP.start(uri.hostname, uri.port, use_ssl: uri.scheme == "https") do |http|
    http.request(request)
end

puts response.body`;
            return rubyCode;

        case 'Node.js':
            let nodejsCode = `const https = require('https');

const options = {
    hostname: '${apiEndpoint.split("//")[1].split("/")[0]}',
    port: 443,
     path: '${'/' + apiEndpoint.split('//')[1].split('/').slice(1).join('/')}',
    method: '${selectedMethod}',
    headers: {
        'Authorization': 'Bearer ${tokenPlaceholder}',
        'Content-Type': 'application/json'
    }
};

const req = https.request(options, (res) => {
    let data = '';

    res.on('data', (chunk) => {
        data += chunk;
    });

    res.on('end', () => {
        console.log(JSON.parse(data));
    });
});

req.on('error', (error) => {
    console.error(error);
});`;

            if (selectedMethod === 'POST' || selectedMethod === 'PUT') {
                nodejsCode += `
req.write('${dataPlaceholder}');`;
            }

            nodejsCode += `
req.end();`;
            return nodejsCode;

        case '.NET':
            let dotnetCode = `using System;
using System.Net.Http;
using System.Text;
using System.Threading.Tasks;

class Program
{
    static async Task Main(string[] args)
    {
        using (var client = new HttpClient())
        {
            client.DefaultRequestHeaders.Add("Authorization", "Bearer ${tokenPlaceholder}");`;

            if (selectedMethod === 'GET') {
                dotnetCode += `
            var response = await client.GetAsync("${apiEndpoint}");`;
            } else if (selectedMethod === 'POST') {
                dotnetCode += `
            var content = new StringContent("${dataPlaceholder}", Encoding.UTF8, "application/json");
            var response = await client.PostAsync("${apiEndpoint}", content);`;
            } else if (selectedMethod === 'PUT') {
                dotnetCode += `
            var content = new StringContent("${dataPlaceholder}", Encoding.UTF8, "application/json");
            var response = await client.PutAsync("${apiEndpoint}", content);`;
            } else if (selectedMethod === 'DELETE') {
                dotnetCode += `
            var response = await client.DeleteAsync("${apiEndpoint}");`;
            }

            dotnetCode += `
            var responseString = await response.Content.ReadAsStringAsync();
            Console.WriteLine(responseString);
        }
    }
}`;
            return dotnetCode;

        case 'Go':
            let goCode = `package main

import (
    "bytes"
    "fmt"
    "io/ioutil"
    "net/http"
)

func main() {
    client := &http.Client{}
    req, _ := http.NewRequest("${selectedMethod}", "${apiEndpoint}", nil)
    req.Header.Set("Authorization", "Bearer ${tokenPlaceholder}")
    req.Header.Set("Content-Type", "application/json")`;

           
    goCode += `
    `;
            if (selectedMethod === 'POST' || selectedMethod === 'PUT') {
                goCode += `req.Body = ioutil.NopCloser(bytes.NewBuffer([]byte("${dataPlaceholder}")))`;
            }

            goCode += `
    resp, err := client.Do(req)
    if err != nil {
        fmt.Println("Error:", err)
        return
    }
    defer resp.Body.Close()

    body, _ := ioutil.ReadAll(resp.Body)
    fmt.Println(string(body))
}
`;
            return goCode;

        default:
            return "Unsupported language. Supported languages are: Curl, Java, Python, PHP, Ruby, Node.js, .NET, Go";
    }
}

export default generateCode;