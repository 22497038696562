import React, { useEffect, useState } from 'react';
import UsePost from '../../CustomHooks/usePost';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from "react-router-dom";
import UseApi from '../../CustomHooks/useApi';
export default function Feedback() {
  toast.dismiss();
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  async function getUserInfo() {
    const data = await UseApi('get', 'account/info', '');
    if (data) {
      if (data.user) {
        setUserData(data.user);
      }
    }
  }
  useEffect(() => {
    getUserInfo();
  }, [])
  const submitFeedback = async () => {

    const dt = toast.loading("Feedback submitting...");
    const dnsp = await UsePost('post', 'process/start', 'start_fms');
    if (dnsp.status == 'error') {
      toast.update(dt, {
        render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
    } else {
      toast.update(dt, {
        render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
    }
    var form = document.getElementById("start_fms");
    form.reset();
    navigate("/");
  }
  return (
    <>
      <div className="engage-toolbar d-flex position-fixed px-5 fw-bolder zindex-2  end-0 transform-90 mt-10 gap-2" style={{ top: "70%" }}>
        {/*begin::Demos drawer toggle*/}
        <button
          id="feedback-btn"
          className="engage-demos-toggle btn btn-flex h-35px bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm fs-6 px-4 rounded-top-0"
          title=""
          data-bs-toggle="tooltip"
          data-bs-placement="left"
          data-bs-dismiss="click"
          data-bs-trigger="hover"
          data-bs-original-title="Give your feedback"
        >
          <span id="kt_engage_demos_label">Feedback</span>
        </button>
        {/*end::Demos drawer toggle*/}
      </div>
      <div
        id="start_fms-drawer"
        className="bg-white drawer drawer-end"
        data-kt-drawer="true"
        data-kt-drawer-activate="true"
        data-kt-drawer-toggle="#feedback-btn"
        data-kt-drawer-close="#feedback_close"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="{default:'auto', 'md': 'auto'}"
        style={{ width: "auto !important" }}
      >
        {/*begin::Messenger*/}
        <div
          className="card w-100 rounded-0 border-0"
          id="kt_drawer_chat_messenger"
        >
          {/*begin::Card header*/}
          <div className="card-header pe-5" id="kt_drawer_chat_messenger_header">
            {/*begin::Title*/}
            <div className="card-title">
              {/*begin::User*/}
              <div className="d-flex justify-content-center flex-column me-3">
                <a

                  className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1"
                >
                  Give you feedback
                </a>
              </div>
              {/*end::User*/}
            </div>
            {/*end::Title*/}
            {/*begin::Card toolbar*/}
            <div className="card-toolbar">
              {/*begin::Close*/}
              <div
                id="feedback_close"
                className="btn btn-sm btn-icon btn-active-light-primary"
              >
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                <span className="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x={6}
                      y="17.3137"
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(-45 6 17.3137)"
                      fill="black"
                    />
                    <rect
                      x="7.41422"
                      y={6}
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(45 7.41422 6)"
                      fill="black"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
              </div>
              {/*end::Close*/}
            </div>
            {/*end::Card toolbar*/}
          </div>
          {/*end::Card header*/}
          {/*begin::Card body*/}
          <div className="" id="">
            {/*begin::Form*/}
            <form id="start_fms" encType="multipart/form-data" className="form fms">
              {/*begin::Modal body*/}
              <div className="modal-body ">
                {/*begin::Scroll*/}
                <div
                  className="scroll-y me-n7 pe-7"
                  id="kt_modal_create_api_key_scroll"
                  data-kt-scroll="true"
                  data-kt-scroll-activate="{default: false, lg: true}"
                  data-kt-scroll-max-height="auto"
                  data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                  data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                  data-kt-scroll-offset="300px"
                  style={{ maxHeight: 199 }}
                >
                  {/*begin::Input group*/}
                  <div className="fv-row mb-8 fv-plugins-icon-container">
                    {/*begin::Label*/}
                    <label className="required fs-6 fw-bold mb-2">Ratings</label>
                    {/*end::Label*/}
                    <div className="rating">
                      {/*begin::Reset rating*/}
                      <label
                        className="btn btn-light fw-bolder btn-sm rating-label me-3"
                        htmlFor="kt_rating_input_0"
                      >
                        Reset
                      </label>
                      <input
                        className="rating-input"
                        name="rating"
                        defaultValue={0}
                        defaultChecked="checked"
                        type="radio"
                        id="kt_rating_input_0"
                      />
                      {/*end::Reset rating*/}
                      {/*begin::Star 1*/}
                      <label className="rating-label" htmlFor="kt_rating_input_1">
                        {/*begin::Svg Icon | path: icons/duotune/general/gen029.svg*/}
                        <span className="svg-icon svg-icon-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M11.1359 4.48359C11.5216 3.82132 12.4784 3.82132 12.8641 4.48359L15.011 8.16962C15.1523 8.41222 15.3891 8.58425 15.6635 8.64367L19.8326 9.54646C20.5816 9.70867 20.8773 10.6186 20.3666 11.1901L17.5244 14.371C17.3374 14.5803 17.2469 14.8587 17.2752 15.138L17.7049 19.382C17.7821 20.1445 17.0081 20.7069 16.3067 20.3978L12.4032 18.6777C12.1463 18.5645 11.8537 18.5645 11.5968 18.6777L7.69326 20.3978C6.99192 20.7069 6.21789 20.1445 6.2951 19.382L6.7248 15.138C6.75308 14.8587 6.66264 14.5803 6.47558 14.371L3.63339 11.1901C3.12273 10.6186 3.41838 9.70867 4.16744 9.54646L8.3365 8.64367C8.61089 8.58425 8.84767 8.41222 8.98897 8.16962L11.1359 4.48359Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}
                      </label>
                      <input
                        className="rating-input"
                        name="rating"
                        defaultValue={1}
                        type="radio"
                        id="kt_rating_input_1"
                      />
                      {/*end::Star 1*/}
                      {/*begin::Star 2*/}
                      <label className="rating-label" htmlFor="kt_rating_input_2">
                        {/*begin::Svg Icon | path: icons/duotune/general/gen029.svg*/}
                        <span className="svg-icon svg-icon-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M11.1359 4.48359C11.5216 3.82132 12.4784 3.82132 12.8641 4.48359L15.011 8.16962C15.1523 8.41222 15.3891 8.58425 15.6635 8.64367L19.8326 9.54646C20.5816 9.70867 20.8773 10.6186 20.3666 11.1901L17.5244 14.371C17.3374 14.5803 17.2469 14.8587 17.2752 15.138L17.7049 19.382C17.7821 20.1445 17.0081 20.7069 16.3067 20.3978L12.4032 18.6777C12.1463 18.5645 11.8537 18.5645 11.5968 18.6777L7.69326 20.3978C6.99192 20.7069 6.21789 20.1445 6.2951 19.382L6.7248 15.138C6.75308 14.8587 6.66264 14.5803 6.47558 14.371L3.63339 11.1901C3.12273 10.6186 3.41838 9.70867 4.16744 9.54646L8.3365 8.64367C8.61089 8.58425 8.84767 8.41222 8.98897 8.16962L11.1359 4.48359Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}
                      </label>
                      <input
                        className="rating-input"
                        name="rating"
                        defaultValue={2}
                        type="radio"
                        id="kt_rating_input_2"
                      />
                      {/*end::Star 2*/}
                      {/*begin::Star 3*/}
                      <label className="rating-label" htmlFor="kt_rating_input_3">
                        {/*begin::Svg Icon | path: icons/duotune/general/gen029.svg*/}
                        <span className="svg-icon svg-icon-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M11.1359 4.48359C11.5216 3.82132 12.4784 3.82132 12.8641 4.48359L15.011 8.16962C15.1523 8.41222 15.3891 8.58425 15.6635 8.64367L19.8326 9.54646C20.5816 9.70867 20.8773 10.6186 20.3666 11.1901L17.5244 14.371C17.3374 14.5803 17.2469 14.8587 17.2752 15.138L17.7049 19.382C17.7821 20.1445 17.0081 20.7069 16.3067 20.3978L12.4032 18.6777C12.1463 18.5645 11.8537 18.5645 11.5968 18.6777L7.69326 20.3978C6.99192 20.7069 6.21789 20.1445 6.2951 19.382L6.7248 15.138C6.75308 14.8587 6.66264 14.5803 6.47558 14.371L3.63339 11.1901C3.12273 10.6186 3.41838 9.70867 4.16744 9.54646L8.3365 8.64367C8.61089 8.58425 8.84767 8.41222 8.98897 8.16962L11.1359 4.48359Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}
                      </label>
                      <input
                        className="rating-input"
                        name="rating"
                        defaultValue={3}
                        type="radio"
                        id="kt_rating_input_3"
                      />
                      {/*end::Star 3*/}
                      {/*begin::Star 4*/}
                      <label className="rating-label" htmlFor="kt_rating_input_4">
                        {/*begin::Svg Icon | path: icons/duotune/general/gen029.svg*/}
                        <span className="svg-icon svg-icon-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M11.1359 4.48359C11.5216 3.82132 12.4784 3.82132 12.8641 4.48359L15.011 8.16962C15.1523 8.41222 15.3891 8.58425 15.6635 8.64367L19.8326 9.54646C20.5816 9.70867 20.8773 10.6186 20.3666 11.1901L17.5244 14.371C17.3374 14.5803 17.2469 14.8587 17.2752 15.138L17.7049 19.382C17.7821 20.1445 17.0081 20.7069 16.3067 20.3978L12.4032 18.6777C12.1463 18.5645 11.8537 18.5645 11.5968 18.6777L7.69326 20.3978C6.99192 20.7069 6.21789 20.1445 6.2951 19.382L6.7248 15.138C6.75308 14.8587 6.66264 14.5803 6.47558 14.371L3.63339 11.1901C3.12273 10.6186 3.41838 9.70867 4.16744 9.54646L8.3365 8.64367C8.61089 8.58425 8.84767 8.41222 8.98897 8.16962L11.1359 4.48359Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}
                      </label>
                      <input
                        className="rating-input"
                        name="rating"
                        defaultValue={4}
                        type="radio"
                        id="kt_rating_input_4"
                      />
                      {/*end::Star 4*/}
                      {/*begin::Star 5*/}
                      <label className="rating-label" htmlFor="kt_rating_input_5">
                        {/*begin::Svg Icon | path: icons/duotune/general/gen029.svg*/}
                        <span className="svg-icon svg-icon-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M11.1359 4.48359C11.5216 3.82132 12.4784 3.82132 12.8641 4.48359L15.011 8.16962C15.1523 8.41222 15.3891 8.58425 15.6635 8.64367L19.8326 9.54646C20.5816 9.70867 20.8773 10.6186 20.3666 11.1901L17.5244 14.371C17.3374 14.5803 17.2469 14.8587 17.2752 15.138L17.7049 19.382C17.7821 20.1445 17.0081 20.7069 16.3067 20.3978L12.4032 18.6777C12.1463 18.5645 11.8537 18.5645 11.5968 18.6777L7.69326 20.3978C6.99192 20.7069 6.21789 20.1445 6.2951 19.382L6.7248 15.138C6.75308 14.8587 6.66264 14.5803 6.47558 14.371L3.63339 11.1901C3.12273 10.6186 3.41838 9.70867 4.16744 9.54646L8.3365 8.64367C8.61089 8.58425 8.84767 8.41222 8.98897 8.16962L11.1359 4.48359Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}
                      </label>
                      <input
                        className="rating-input"
                        name="rating"
                        defaultValue={5}
                        type="radio"
                        id="kt_rating_input_5"
                      />
                      {/*end::Star 5*/}
                    </div>
                    {/*begin::Input*/}
                    {/*end::Input*/}
                    <div className="fv-plugins-message-container invalid-feedback" />
                  </div>
                  {/*end::Input group*/}
                  {/*begin::Input group*/}
                  <div className="mb-5 fv-row">
                    {/*begin::Label*/}
                    <label htmlFor="type" className="required fs-5 fw-bold mb-2">
                      {" "}
                      Type
                    </label>
                    {/*end::Label*/}
                    {/*begin::Input*/}
                    <select
                      name="field[774]"
                      id="type"
                      data-control="select2"
                      className="form-select form-select-solid "

                    >
                      <option value="" data-select2-id="select2-data-12-5gy7">
                        Choose type
                      </option>
                      <option value="Feedback">Feedback</option>
                      <option value="Report Bug">Report Bug</option>
                      <option value="Request Feature">Request Feature</option>
                    </select>
                    {/*end::Input*/}
                  </div>
                  {/*end::Input group*/}
                  {/*begin::Input group*/}
                  <div className="d-flex flex-column mb-5 fv-row">
                    {/*begin::Label*/}
                    <label
                      htmlFor="feedback"
                      className="required fs-5 fw-bold mb-2"
                    >
                      Feedback
                    </label>
                    {/*end::Label*/}
                    {/*begin::Input*/}
                    <textarea
                      className="form-control"
                      type="text"
                      rows={3}
                      name="field[775]"
                      id="feedback"
                      placeholder="give your feedback here"
                      defaultValue={""}
                    />
                    {/*end::Input*/}
                    <input
                      type="hidden"
                      name="templateid"
                      defaultValue="platform-feedback"
                    />
                    <input type="hidden" name="field[777]" value={userData != null ? (userData.id) : ('')} />
                  </div>
                  {/*end::Input group*/}
                </div>
                {/*end::Scroll*/}
              </div>
              {/*end::Modal body*/}
              {/*begin::Modal footer*/}
              <div className="modal-footer ">
                {/*begin::Button*/}
                <button
                  type="button"
                  id="start_fms-btn"
                  style={{ width: "100%" }}
                  onClick={submitFeedback}
                  className="btn btn-primary"
                >
                  <span className="indicator-label">submit</span>
                </button>
                {/*end::Button*/}
              </div>
              {/*end::Modal footer*/}
            </form>
            {/*end::Form*/}
          </div>
          {/*end::Card body*/}
        </div>
        {/*end::Messenger*/}
      </div>
    </>

  );
};
