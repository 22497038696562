import React, { useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Activity from "./Products/activity/Activity";
import Billing from "./Products/billing/Billing";
import Dashboard from "./Products/Dashboard/Dashboard";
import Kyc from "./Products/kyc/Kyc";
import KycSent from "./Products/kyc/KycSent";
import Account from "./Products/setting/Account";
import ManageTicket from "./Products/ticket/ManageTicket";
import Ticket from "./Products/ticket/Ticket";
import localStorage from "local-storage";
import Verification from "./Products/verification/Verification";
import MobileUpdate from "./Products/setting/MobileUpdate";
import Welcome from "./Products/verification/Welcome";
import NotFound from "./Products/NotFound";
import Process from "../../../process/Process";
import UseApi from "../../CustomHooks/useApi";
import Customers from "./Products/customers/Customers";
// import Earnings from "./Products/earnings/Earnings";
// import Leads from "./Products/leads/Leads";
// import Renewals from "./Products/renewals/Renewals";
// import Services from "./Products/orders/Orders";
import Orders from "./Products/orders/Orders";
import Email from "./Products/Emails/Email";
import EmailTemplates from "./Products/Emails/components/emailTemplates/EmailTemplates";

export default function Body() {
  useEffect(() => {
    // Use setInterval to execute a function every 1 minute (60000 milliseconds).
    const interval = setInterval(() => {
      getUserInfo();
    }, 60000);
    async function getUserInfo() {
      const data = await UseApi('get', 'account/info', '');
      if (data) {
        if (data.user) {
          localStorage.set("user_info", data.user);
        }
      }
    }
    // Clear the interval when the component unmounts to avoid memory leaks.
    return () => clearInterval(interval);
  }, []);
  const location = useLocation();
  useEffect(() => {
    var query = window.location.href;
    var vars = query.split("/");
    const app_info = localStorage.get("appInfo");
    const user_info = localStorage.get("user_info");
    if (app_info) {
      //Setting title
      if (vars.length == 4) {
        var id = vars.at(3);
        document.title = app_info.brand + " | " + id;
      } else if (vars.length == 5) {
        var id = vars.at(3);
        document.title = app_info.brand + " | Manage " + id;
      }
      //setting description

      const descriptionTag = document.querySelector('meta[name="description"]');
      if (descriptionTag) {
        descriptionTag.setAttribute('content', app_info.disc);
      }
    }
    //setting keywords
    const keywordsTag = document.querySelector('meta[name="keywords"]');
    if (keywordsTag) {
      keywordsTag.setAttribute('content', app_info.keywords);
    }

    //console.log('URL changed:', location.pathname);
  }, [location.pathname]);

  const user_info = localStorage.get("user_info");
  if (user_info) {
    // if(user_info.sms_verified==0 || (user_info.c_added=='no' && user_info.rvn==null)){
    if (user_info.sms_verified == 0) {
      return (
        <>
          {/*begin::Content*/}
          <div className="d-flex flex-column flex-column-fluid" id="kt_content">
            {/* Body content Start */}

            {/* <div className="container-xxl"> */}
            <div className="p-3">
              <Routes>
                <Route path="/kyc/complete" element={<Welcome />} />
                <Route path="/account" element={<Account />} />
                <Route path="/ticket/" element={<Ticket />} />
                <Route path="/ticket/:id" element={<ManageTicket />} />
                <Route path="*" element={<Verification />} />
              </Routes>
            </div>
          </div>
        </>
      )
    } else {
      return (
        <>
          {/*begin::Content*/}
          <div className="d-flex flex-column flex-column-fluid" id="kt_content">
            {/* Body content Start */}
            {/* <div className="container-xxl"> */}
            <div className="p-3">
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/customers" element={<Customers />} />
                {/* <Route path="/earnings" element={<Earnings />}   /> */}
                {/* <Route path="/leads" element={<Leads />}   />  */}
                {/* <Route path="/renewals" element={<Renewals />}   />  */}
                <Route path="/orders" element={<Orders />} />
                <Route path="/processing/:id" element={<Process />} />
                <Route path="/account/updateMobile" element={<MobileUpdate />} />
                <Route path="/account/kyc" element={<Kyc />} />
                <Route path="/account/kyc/sent" element={<KycSent />} />
                <Route path="/account" element={<Account />} />
                <Route path="/invoices" element={<Billing />} />
                <Route path="/activity" element={<Activity />} />
                <Route path="/ticket/" element={<Ticket />} />
                <Route path="/ticket/:id" element={<ManageTicket />} />
                <Route path="/email" element={<Email />} />
                <Route path="/emailTemplate" element={<EmailTemplates />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
            {/* Body Content end */}
          </div>
          {/*end::Content*/}
        </>
      )
    }
  }
}
