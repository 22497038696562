import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';

export default function DbSwitch() {
    const navigate = useNavigate();
    var { clusterid, cloudid } = useParams();
    function redirectToPrev() {
      //  if (id === "firewall" || id === "dns" || id === "ssl") {
      //   navigate('/' + id + '?action=deploy');
      // } else {
        navigate('/database-cluster/' + clusterid + '/' + cloudid );
      // }
    }
    useEffect(() => {
      redirectToPrev();
    }, [clusterid, cloudid])
    return (
      <></>
      // <div>process</div>
    )
}
