import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import Swal from 'sweetalert2';
import ReactPaginate from "react-paginate";
import copy from "copy-to-clipboard";
import UsePost from "../../../../../../CustomHooks/usePost";
import UseApi from "../../../../../../CustomHooks/useApi";
import TopBarHeaderLine from "../../../../../../CustomHooks/TopBarHeaderLine";
import ConnectionDetails from "../pgbouncer/ConnectionDetails";
import CreateTopics from "./CreateTopics";

const Topics = (props) => {
  const [pageStatus, setPageStatus] = useState(false)
  const [databaseInfo, setDatabaseInfo] = useState(null);
  const [objectForUpdate, setObjectForUpdate] = useState({})
  const [isUpdate, setIsUpdate] = useState(false)
  const [pageCount, setPageCount] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [itemOffset, setItemOffset] = useState(0);
  const [databaseUserData, setDatabaseUserData] = useState(null);
  const [currentDatabaseInfo, setCurrentDatabaseInfo] = useState(null);
  const [dummyDatabaseUserData, setDummyDatabaseUserData] = useState(null);
  var query = window.location.href;
  var vars = query.split("/");
  var id = vars[4];
  let { clusterid, cloudid } = useParams();
  const openDrawer = () => {
    var drawerElement = document.querySelector("#topics-modal");
    var drawer_overlay = document.querySelector("#drawer_overlay_topics");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
        drawerElement.style.width = "100%";
      } else {
        drawerElement.style.width = "40%";
      }
      drawer_overlay.classList.toggle("d-none");
    }
  };
  const editOpenDrawer = (value) => {
    var retention_in_bytes = document.querySelector('#retention_in_bytes')
    var retention_in_hours = document.querySelector('#retention_in_hours')
    var partitionCount = document.querySelector('#partitionCount')
    var topicName = document.querySelector('#topicName')
    var replicationFactor = document.querySelector('#replicationFactor')
    replicationFactor.value = value.replication
    retention_in_bytes.value = value.retension_size
    retention_in_hours.value = value.retension_time
    partitionCount.value = value.partitions
    topicName.value = value.topic
    var drawerElement = document.querySelector("#topics-modal");
    var drawer_overlay = document.querySelector("#drawer_overlay_topics");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
        drawerElement.style.width = "100%";
      } else {
        drawerElement.style.width = "40%";
      }
      drawer_overlay.classList.toggle("d-none");
    }
  };
  const loaderFunc = () => {
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if (loader && no_record) {
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");

      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
  }


  const togglePassword = (action, pid) => {
    // console.log(action,pid);
    var hide_passwd = document.querySelector("#hide_passwd_" + pid);
    var show_passwd = document.querySelector("#show_passwd_" + pid);
    // console.log(hide_passwd,show_passwd);
    if (hide_passwd && show_passwd) {
      // console.log("sjc");
      if (action == "hide") {
        hide_passwd.classList.remove("d-none");
        show_passwd.classList.add("d-none");
      } else {
        hide_passwd.classList.add("d-none");
        show_passwd.classList.remove("d-none");
      }
    }
  }

  const copyToClipboard = (target, targetBy) => {

    var btn_id = document.querySelector("#" + targetBy);
    var target_id = document.querySelector("#" + target);
    if (btn_id) {
      var copyText = btn_id.innerHTML;
      var copyEle = target_id.innerHTML;
      copy(copyEle);
      btn_id.innerHTML = "Copied";
      btn_id.classList.add("text-success");
      setTimeout(() => {
        btn_id.innerHTML = copyText;
        btn_id.classList.remove("text-success");
      }, 2000);
    }
  }

  function callBack() {
    props.updateDatabase()
  }
  const getDatabaseUsers = async () => {
    if (props) {
      if (props.data) {
        if (props.data.users.length == 0) {
          setDatabaseUserData([]);
          setDummyDatabaseUserData([])
        } else {
          setDatabaseUserData(props.data.topics);
          setDummyDatabaseUserData(props.data.topics)
          setCurrentDatabaseInfo(props.currentDatabaseInfo);
          setDatabaseInfo(props.data.topics);
        }
      }
    }
  }


  useEffect(() => {
    getDatabaseUsers();
  }, [props])

  console.log(props);
  function ProductInfo({ productdata }) {
    console.log(productdata);
    return (
      <>
        {productdata != null && Object.keys(productdata).length != 0 ? (productdata && productdata.map((value, index) => (
          <tr>
            <td className="text-start ps-10">
              <span className="fs-6 fw-bold">
                {value.topic?.charAt(0)?.toUpperCase() + value.topic?.slice(1)?.toLowerCase()}
              </span>
            </td>
            <td></td>
            <td className="text-center">
              <span className="fs-6 fw-bold">
                {value.partitions?.charAt(0)?.toUpperCase() + value.partitions?.slice(1)?.toLowerCase()}
              </span>
            </td>
            <td className="text-center">
              <span className="fs-6 fw-bold">
                {value.replication?.charAt(0)?.toUpperCase() + value.replication?.slice(1)?.toLowerCase()}
              </span>
            </td>
            <td className="text-center">
              <span className="fs-6 fw-bold">
                {value.retension_time?.charAt(0)?.toUpperCase() + value.retension_time?.slice(1)?.toLowerCase()}
              </span>
            </td>
            <td className="text-center">
              <span className="fs-6 fw-bold">
                {value.retension_size?.charAt(0)?.toUpperCase() + value.retension_size?.slice(1)?.toLowerCase()}
              </span>
            </td>
            <td className="text-center">
              <span className="fs-6 fw-bold">
                {value.status}
              </span>
            </td>
            {currentDatabaseInfo?.ref_type != "dbaas_node" ? (<>

              <td className="text-center" >
                <div className="card-toolbar">
                  <button
                    type="button"
                    onClick={() => {
                      // editOpenDrawer(value)
                      // findObjectForUpdate(value.id)
                    }}
                    className="btn  btn-icon btn-color-primary btn-active-light-primary"
                  >
                    <span className="svg-icon svg-icon-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          opacity="0.3"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z"
                          fill="black"
                        />
                        <path
                          d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z"
                          fill="black"
                        />
                        <path
                          d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z"
                          fill="black"
                        />
                      </svg>

                    </span>
                  </button>
                  <button
                    type="button"
                    className="btn  btn-icon btn-color-danger btn-active-light-danger"
                    id={`delete-kafka-btn-${value.id}`}
                    onClick={() => handleDeletePgBouncer(value.id, index, value.topic)}
                  >
                    <span className="svg-icon svg-icon-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                          fill="black"
                        />
                        <path
                          opacity="0.5"
                          d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                          fill="black"
                        />
                        <path
                          opacity="0.5"
                          d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                  </button>
                </div>
              </td>
            </>) : ('')}
          </tr>
        ))) : (<>
          <tr>
            <td colspan="12" className="dataTables_empty" id="loader">
              {/* <div className="loading-spinner"></div> */}
            </td>
            <td colspan="12" className="d-none" id="no_record" valign="top">
              {/* <div className="d-flex flex-column flex-center">
                <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
              </div> */}
              <div className="mt-20 mb-20 text-center">
                <span className="fs-6 text-gray-800 fw-bold">no record found</span>
              </div>
            </td>
          </tr>
        </>)}

      </>
    )
  }

  const copyClusterName = () => {
    var eleText = document.querySelector("#kafka_file_url");
    var ele = document.querySelector("#kafka_file_url_copy_btn");

    if (ele && eleText) {
      const copyText = eleText.innerText;
      const originalContent = ele.innerHTML;
      console.log(originalContent);
      
      navigator.clipboard.writeText(copyText).then(() => {
        ele.innerHTML = "(Copied)";

        setTimeout(() => {
          ele.innerHTML = "(copy)";
        }, 1000);
      }).catch(err => {
        console.error('Failed to copy text: ', err);
      });
    }
  };

  function handleButtonClick(event) {
    console.log(event.target);
    if (event.target && event.target.id === 'kafka_file_url_copy_btn') {
      copyClusterName();
      event.stopPropagation();
    }
  }
  
  // Remove any existing listener before adding a new one (if necessary)
  document.removeEventListener('click', handleButtonClick);
  document.addEventListener('click', handleButtonClick);

  const handleDeletePgBouncer = async (pgbId, index, name) => {
    toast.dismiss();
    const submitBtn = document.querySelector("#delete-kafka-btn-" + pgbId);
    if (submitBtn) {
      const { value: isDelete } = await Swal.fire({
        title: 'Delete Database User',
        html: `
        <div className="d-flex align-items-center flex-wrap"> 
        <div id="kt_clipboard_4" className="me-5 fw-bold text-gray-600">
          <p class="fs-5">Enter the Kafka Topic name to Destroy:</p>
  
          <span class="fw-bold" id="kafka_file_url" >${name} &nbsp;</span><span id="kafka_file_url_copy_btn" style="cursor:pointer" class="text-primary"> ( copy ) </span> 
        </div>  
      </div>
     `,
        input: 'text',
        inputPlaceholder: 'Kafka Topic',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'delete-button bg-danger', // Add your custom class here
        },
      });
      if (isDelete == name) {
        const dt = toast.loading("Please wait...");
        var preContent = submitBtn.innerHTML;
        submitBtn.classList.add("disabled");
        submitBtn.innerHTML =
          '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';

        const responseData = await UseApi("delete", `databases/${clusterid}/topic?id=${pgbId}&confirm=${name}`, "");

        ////console.log(responseData);
        if (responseData.status === "error") {
          toast.update(dt, {
            render: responseData.message,
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (responseData.status === "success") {
          toast.update(dt, {
            render: responseData.message,
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          props.updateDatabase()
          // setFirewallData((old) => {
          //     old.splice(index, 1);
          //     return [...old];
          // })
        }
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
      }
    }
  };
  const handleUpdatePgBouncer = async (pgbId, index) => {
    toast.dismiss();
    const dt = toast.loading("Please wait...");
    const submitBtn = document.querySelector("#delete-pgb-btn-" + pgbId);
    if (submitBtn) {
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML =
        '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';

      const responseData = await UseApi("put", `databases/${clusterid}/${cloudid}/pool?id=${pgbId}`, "pgbouncerData");

      ////console.log(responseData);
      if (responseData.status === "error") {
        toast.update(dt, {
          render: responseData.message,
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (responseData.status === "success") {
        toast.update(dt, {
          render: responseData.message,
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        props.updateDatabase()
        // setFirewallData((old) => {
        //     old.splice(index, 1);
        //     return [...old];
        // })
      }
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML = preContent;
    }
  };
  const handleAddConnectionPool = async () => {
    var submitBtn = document.querySelector("#create-pgbouncer-btn");

    if (submitBtn) {
      setPageStatus(true)
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      const dt = toast.loading("Please wait...");

      const responseData = await UsePost('post', `databases/${clusterid}/${cloudid}/pool`, 'pgbouncerData');
      if (responseData) {
        setPageStatus(false)
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
        if (responseData.status === 'error') {
          toast.update(dt, {
            render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        } else if (responseData.status === 'success') {
          toast.update(dt, {
            render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          if (props) {
            openDrawer();
            props.updateDatabase();
          }

        }
      }
    }
  }
  const findObjectForUpdate = (targetId) => {
    setIsUpdate(true)
    const foundObject = currentDatabaseInfo.pools.find(obj => obj.id === targetId);
    if (foundObject) {
      setObjectForUpdate(foundObject)
    } else {
      setObjectForUpdate({})
    }
  }
  const handleSearch = (text) => {
    const endOffset = itemOffset + perPage;
    const data = currentDatabaseInfo.pools.filter(row => {
      return Object.values(row).some(value => {
        if (value != null) {
          if (value.toString().toLowerCase().includes(text.toLowerCase())) {
            return value;
          }
        }
      });
    });
    setDummyDatabaseUserData(data);;
    setPageCount(Math.ceil(data.length / perPage));
    // }
  }

  function PaginatedItems({ itemsPerPage }) {

    const handlePageClick = (event) => {
      const newOffset = event.selected * itemsPerPage % databaseUserData.length;
      ////console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
      setItemOffset(newOffset);

    };

    return (
      <>
        <ProductInfo productdata={dummyDatabaseUserData} />
        <td colspan="12">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </td>
      </>
    );
  }

  if (databaseUserData != null) {
    // if (databaseUserData.length != 0 && currentDatabaseInfo.length != 0) {
    return (
      <>
        <div className="card scroll-y mh-200px mh-lg-300px">
          <div className="card-header">
            <div className="card-title">
              <h3>Topics</h3>
            </div>
            <div className="card-toolbar">
              <div
                className="position-relative my-1"
                style={{ marginRight: 20 }}
              >
                <input
                  type="text"
                  className="form-control form-control-sm"
                  data-kt-table-widget-4="search"
                  id="searchClient"
                  // value={searchTerm}
                  onChange={e => handleSearch(e.target.value)}
                  placeholder="Search"
                />
              </div>

              <div style={{ marginRight: 20 }}>
                <div className="dataTables_length">
                  <label className="w-125px">
                    <select
                      onChange={e => setPerPage(e.target.value)}
                      //data-control="select2"
                      className="form-select form-select-sm form-select-solid"
                      defaultValue={5}
                      id="perPage"

                    >
                      <option value={5} selected="">
                        5 Records
                      </option>
                      <option value={10}>10 Records</option>
                      <option value={25}>25 Records</option>
                      <option value={50}>50 Records</option>
                      <option value={100}>100 Records</option>
                    </select>
                  </label>
                </div>
              </div>
              {currentDatabaseInfo?.ref_type != "dbaas_node" ? (<>
                <a onClick={() => {
                  openDrawer()
                  setObjectForUpdate({})
                  setIsUpdate(false)
                }}
                  className="btn btn-sm btn-primary my-1 sphide">
                  + Add Create Topics
                </a>
              </>) : ('')}
            </div>
          </div>
          <div className="card-body p-0">
            <div className="table-responsive">
              <table
                className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
                id="kt_security_license_usage_table"
              >
                <thead className="border-gray-200 fs-5 fw-bold bg-secondary">
                  <tr>
                    <th className="w-150px text-start ps-9 sphide">Topic Name</th>
                    <th className="w-50px ps-9"></th>
                    <th className="w-150px ps-9 sphide">Partition Count</th>
                    <th className="w-150px ps-9 sphide">Replication Factor</th>
                    <th className="w-150px ps-9 sphide">Retention (hours)</th>
                    <th className="w-150px ps-9 sphide">Retention (bytes)</th>
                    <th className="w-150px text-center">Status</th>
                    <th className="w-150px ps-18" >Action</th>
                    {/* <th className="text-center pe-5 w-150px sphide">Connection Details</th> */}
                    {/* {currentDatabaseInfo?.ref_type != "dbaas_node" ? (<>
                        <th className="w-150px ps-9" />
                      </>) : ('')} */}
                  </tr>
                </thead>
                <tbody id="public-IP-list" className="fw-6 fw-bold text-gray-600">
                  {dummyDatabaseUserData != null ? (<>
                    {dummyDatabaseUserData.length == 0 ? (<>
                      <tr>
                        <td colspan="12" valign="top">
                          {/* <div className="d-flex flex-column flex-center">
                              <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                            </div> */}
                          <div className="mt-20 mb-20 text-center">
                            <span className="fs-6 text-gray-800 fw-bold">no record found</span>
                          </div>
                        </td>
                      </tr>
                    </>) : (<>
                      <PaginatedItems itemsPerPage={perPage} ProductData={currentDatabaseInfo.pools} />
                    </>)}
                  </>) : ('')}
                </tbody>
              </table>
            </div>
          </div>

          {/* Connection create modal start */}
          <div id="drawer_overlay_topics" onClick={openDrawer} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>
          <CreateTopics openDrawer={openDrawer} callBack={callBack} />
        </div>
      </>
    );
    // }
  }
}

export default Topics; 
