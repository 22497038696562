import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import UseApi from "../../../../../CustomHooks/useApi";
import TableLoader from "../../../Loaders/TableLoader";
import UsePostData from "../../../../../CustomHooks/usePostData";
import CustomDoc from "../../../../../CustomHooks/CustomDoc";
import ProductsUrl from "../../../Docs/Docsdata";

export default function Vpc(props) {
  var query = window.location.href;
  var vars = query.split("/");
  var id = vars[4];
  const [dataFetched, setDataFetched] = useState(false);
  const [access, setAccess] = useState(true);
  const [perPage, setPerPage] = useState(5);
  const [pageCount, setPageCount] = useState(1);
  const [vpcDataAll, setVpcDataAll] = useState(null);
  const [dummyVpcDataAll, setDummyVpcDataAll] = useState(null);
  const [activePage, setActivePage] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const currentPage = useRef(0);
  const loaderFunc = () => {
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if (loader && no_record) {
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");

      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
  }

  const handleSearch = (text) => {
    const endOffset = itemOffset + perPage;
    const data = vpcDataAll.filter(row => {
      return Object.values(row).some(value => {
        if (value != null) {
          if (value.toString().toLowerCase().includes(text.toLowerCase())) {
            return value;
          }
        }
      });
    });
    setDummyVpcDataAll(data);;
    setPageCount(Math.ceil(data.length / perPage));
  }

  function ProductInfo({ productdata }) {
    return (
      <>
        {productdata != null && Object.keys(productdata).length != 0 ? (productdata && productdata.map((value, index) => (
          <tr>
            <td className="text-start ps-9">
              {" "}
              <div className="d-flex flex-column w-100 me-2">
                {" "}
                <div className="d-flex flex-stack mb-2">
                  {" "}
                  <span className=" me-2 fs-7 fw-bold">
                    {value.id}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
            </td>{" "}
            <td />
            <td className="text-center">
              {" "}
              <div className="d-flex flex-column w-100 me-2">
                {" "}
                <div className="flex-stack mb-2">
                  {" "}
                  <span className="me-2 fs-7 fw-bold">
                    {value.vpc_network}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
            </td>{" "}
          </tr>
        ))) : (<>
          <tr>
            {/* <td colspan="12" className="dataTables_empty" id="loader"> */}
            {/* <div className="loading-spinner"></div> */}
            {/* <TableLoader /> */}
            {/* </td> */}
            <td colspan="12" className="" id="no_record" valign="top">
              <div className="d-flex flex-column flex-center">
                <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
              </div>
            </td>
          </tr>
        </>)}
      </>
    );
  }


  useEffect(() => {
    getVPC();
  }, [props])


  // const [searchTerm, setSearchTerm] = useState('');
  const dataFetchedRef = useRef(false);

  const getVPC = () => {
    if (props && props.data) {
      console.log(props.data);
      setVpcDataAll(props.data);
      setDummyVpcDataAll(props.data)
    }
  }


  function PaginatedItems({ itemsPerPage, ProductData }) {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(ProductData.length / itemsPerPage));
    const handlePageClick = (event) => {
      currentPage.current = event.selected;
      const newOffset = event.selected * itemsPerPage % ProductData.length;
      setItemOffset(newOffset);
    };

    return (
      <>
        <ProductInfo productdata={ProductData.slice(itemOffset, endOffset)} />
        <td colspan="12">
          <ReactPaginate
            nextLabel={currentPage.current === pageCount - 1 ? null : "next >"}
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount > 1 && pageCount}
            previousLabel={currentPage.current === 0 ? null : "< previous"}
            pageClassName="page-item"
            initialPage={activePage}
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </td>
      </>
    );


  }
  return (
    <>
      <div className="col-xl-12">
        {/*begin::Tables Widget 9*/}
        <div className="card card-xl-stretch mb-5 mb-xl-12">
          {/*begin::Header*/}
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold fs-3 mb-1">
                {" "}
                VPC {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                <span className="svg-icon svg-icon-2 d-none">
                  <svg
                    viewBox="0 0 64 64"
                    width="234px"
                    height="234px"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    fill="#21325B"
                    stroke="#21325B"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <title>clo-cloud-network-folder</title> <desc>Created with Sketch.</desc>{" "}
                      <defs> </defs>{" "}
                      <g
                        id="64px-Glyph"
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        {" "}
                        <g id="clo-cloud-network-folder" fill="#21325B">
                          {" "}
                          <path
                            d="M12,13 C12,12.447 12.447,12 13,12 L51.444,12 C50.75,10.81 49.474,10 48,10 L30,10 C28,10 27.497,9.329 26.8,8.4 C25.998,7.331 25,6 22,6 L12,6 C9.794,6 8,7.794 8,10 L8,38 C8,40.206 9.794,42 12,42 L12,13 Z"
                            id="Fill-216"
                          >
                            {" "}
                          </path>{" "}
                          <path
                            d="M38.8887,36 L27.8887,36 C24.6417,36 21.9997,33.458 21.9997,30.333 C21.9997,27.51 24.1567,25.163 26.9677,24.736 C27.4307,22.053 29.8597,20 32.7777,20 C34.7387,20 36.5317,20.919 37.6217,22.441 C38.0377,22.369 38.4617,22.333 38.8887,22.333 C42.8097,22.333 45.9997,25.398 45.9997,29.167 C45.9997,32.935 42.8097,36 38.8887,36 M54.9997,14 L51.9997,14 L13.9997,14 L13.9997,42 L51.9997,42 C54.2057,42 55.9997,40.206 55.9997,38 L55.9997,15 C55.9997,14.447 55.5527,14 54.9997,14"
                            id="Fill-217"
                          >
                            {" "}
                          </path>{" "}
                          <path
                            d="M38.8887,24.333 C38.3887,24.333 37.8947,24.401 37.4197,24.535 C36.9657,24.661 36.4837,24.457 36.2637,24.039 C35.6017,22.781 34.2657,22 32.7777,22 C30.6327,22 28.8887,23.645 28.8887,25.667 C28.8887,26.22 28.4417,26.667 27.8887,26.667 C25.7437,26.667 23.9997,28.312 23.9997,30.333 C23.9997,32.356 25.7437,34 27.8887,34 L38.8887,34 C41.7067,34 43.9997,31.832 43.9997,29.167 C43.9997,26.502 41.7067,24.333 38.8887,24.333"
                            id="Fill-218"
                          >
                            {" "}
                          </path>{" "}
                          <path
                            d="M24,50 L5,50 C4.447,50 4,50.447 4,51 L4,55 C4,55.553 4.447,56 5,56 L24,56 L24,50 Z"
                            id="Fill-219"
                          >
                            {" "}
                          </path>{" "}
                          <polygon id="Fill-220" points="30 46 36 46 36 44 30 44">
                            {" "}
                          </polygon>{" "}
                          <path
                            d="M39,48 L27,48 C26.447,48 26,48.447 26,49 L26,57 C26,57.553 26.447,58 27,58 L39,58 C39.553,58 40,57.553 40,57 L40,49 C40,48.447 39.553,48 39,48"
                            id="Fill-221"
                          >
                            {" "}
                          </path>{" "}
                          <path
                            d="M61,50 L42,50 L42,56 L61,56 C61.553,56 62,55.553 62,55 L62,51 C62,50.447 61.553,50 61,50"
                            id="Fill-222"
                          >
                            {" "}
                          </path>{" "}
                        </g>{" "}
                      </g>{" "}
                    </g>
                  </svg>


                </span>
                {/*end::Svg Icon*/}
              </span>
            </h3>
            <div
              className="card-toolbar"
            >
              <div className="position-relative my-1" style={{ marginRight: 20 }}>
                <CustomDoc url={ProductsUrl[0]?.kubernetes + "/manage-kubernetes/vpc/"} />
              </div>
              <div className="position-relative my-1 d-none" style={{ marginRight: 20 }}>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  data-kt-table-widget-4="search"
                  id="searchClient"
                  // value={searchTerm}
                  onChange={e => handleSearch(e.target.value)}
                  placeholder="Search"
                />
              </div>
              <div style={{ marginRight: 20 }} className="d-none">
                <div className="dataTables_length">
                  <label className="w-125px">
                    <select
                      onChange={e => setPerPage(e.target.value)}
                      //data-control="select2"
                      className="form-select form-select-sm form-select-solid"
                      defaultValue={5}
                      id="perPage"

                    >
                      <option value={5} selected="">
                        5 Records
                      </option>
                      <option value={10}>10 Records</option>
                      <option value={25}>25 Records</option>
                      <option value={50}>50 Records</option>
                      <option value={100}>100 Records</option>
                    </select>
                  </label>
                </div>
              </div>

            </div>
          </div>
          {/*end::Header*/}
          {/*begin::Body*/}
          {/*begin::Table container*/}
          <div className="table-responsive">
            {/*begin::Table*/}
            <table
              id="vpcTable"
              className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
            >
              {/*begin::Table head*/}
              <thead className="bg-secondary">
                <tr className="fw-bold ">
                  <th className=" w-150px text-start ps-9">ID</th>
                  <th className="  w-20px p-3" />
                  <th className=" w-100px text-center">Network</th>
                </tr>
              </thead>
              {/*end::Table head*/}
              {/*begin::Table body*/}
              <tbody id="mainTable" >
                {vpcDataAll != null ? (<>

                  {Object.keys(vpcDataAll).length != 0 ? (<>
                    <PaginatedItems itemsPerPage={perPage} ProductData={dummyVpcDataAll} />
                  </>) : (<>
                    <tr>
                      <td colspan={12}>
                        <div className="card-body p-0 mt-10">
                          {/*begin::Illustration*/}
                          <div className="text-center px-4 mt-10">
                            <span className="mw-100 mh-300px">
                              <svg
                                viewBox="0 0 64 64"
                                width="234px"
                                height="234px"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                fill="#21325B"
                                stroke="#21325B"
                              >
                                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <title>clo-cloud-network-folder</title> <desc>Created with Sketch.</desc>{" "}
                                  <defs> </defs>{" "}
                                  <g
                                    id="64px-Glyph"
                                    stroke="none"
                                    strokeWidth={1}
                                    fill="none"
                                    fillRule="evenodd"
                                  >
                                    {" "}
                                    <g id="clo-cloud-network-folder" fill="#21325B">
                                      {" "}
                                      <path
                                        d="M12,13 C12,12.447 12.447,12 13,12 L51.444,12 C50.75,10.81 49.474,10 48,10 L30,10 C28,10 27.497,9.329 26.8,8.4 C25.998,7.331 25,6 22,6 L12,6 C9.794,6 8,7.794 8,10 L8,38 C8,40.206 9.794,42 12,42 L12,13 Z"
                                        id="Fill-216"
                                      >
                                        {" "}
                                      </path>{" "}
                                      <path
                                        d="M38.8887,36 L27.8887,36 C24.6417,36 21.9997,33.458 21.9997,30.333 C21.9997,27.51 24.1567,25.163 26.9677,24.736 C27.4307,22.053 29.8597,20 32.7777,20 C34.7387,20 36.5317,20.919 37.6217,22.441 C38.0377,22.369 38.4617,22.333 38.8887,22.333 C42.8097,22.333 45.9997,25.398 45.9997,29.167 C45.9997,32.935 42.8097,36 38.8887,36 M54.9997,14 L51.9997,14 L13.9997,14 L13.9997,42 L51.9997,42 C54.2057,42 55.9997,40.206 55.9997,38 L55.9997,15 C55.9997,14.447 55.5527,14 54.9997,14"
                                        id="Fill-217"
                                      >
                                        {" "}
                                      </path>{" "}
                                      <path
                                        d="M38.8887,24.333 C38.3887,24.333 37.8947,24.401 37.4197,24.535 C36.9657,24.661 36.4837,24.457 36.2637,24.039 C35.6017,22.781 34.2657,22 32.7777,22 C30.6327,22 28.8887,23.645 28.8887,25.667 C28.8887,26.22 28.4417,26.667 27.8887,26.667 C25.7437,26.667 23.9997,28.312 23.9997,30.333 C23.9997,32.356 25.7437,34 27.8887,34 L38.8887,34 C41.7067,34 43.9997,31.832 43.9997,29.167 C43.9997,26.502 41.7067,24.333 38.8887,24.333"
                                        id="Fill-218"
                                      >
                                        {" "}
                                      </path>{" "}
                                      <path
                                        d="M24,50 L5,50 C4.447,50 4,50.447 4,51 L4,55 C4,55.553 4.447,56 5,56 L24,56 L24,50 Z"
                                        id="Fill-219"
                                      >
                                        {" "}
                                      </path>{" "}
                                      <polygon id="Fill-220" points="30 46 36 46 36 44 30 44">
                                        {" "}
                                      </polygon>{" "}
                                      <path
                                        d="M39,48 L27,48 C26.447,48 26,48.447 26,49 L26,57 C26,57.553 26.447,58 27,58 L39,58 C39.553,58 40,57.553 40,57 L40,49 C40,48.447 39.553,48 39,48"
                                        id="Fill-221"
                                      >
                                        {" "}
                                      </path>{" "}
                                      <path
                                        d="M61,50 L42,50 L42,56 L61,56 C61.553,56 62,55.553 62,55 L62,51 C62,50.447 61.553,50 61,50"
                                        id="Fill-222"
                                      >
                                        {" "}
                                      </path>{" "}
                                    </g>{" "}
                                  </g>{" "}
                                </g>
                              </svg>


                            </span>
                          </div>
                          {/*end::Illustration*/}
                          {/*begin::Wrapper*/}
                          <div className="card-px text-center py-5 mb-20">
                            {/*begin::Title*/}
                            <h1 className="fs-2x fw-bolder mb-5"> VPC</h1>
                            <h2 className="fs-1x fw-bolder mb-5 text-muted">
                              Build a custom network in the cloud
                            </h2>
                            {/*end::Title*/}
                            {/*begin::Description*/}
                            <p className="text-gray-400 fs-4 fw-bold mb-10">
                              Take control with Utho's VPC service. Create a secure environment for your<br /> business-critical applications and data.                </p>
                            {/*end::Description*/}
                            {/*begin::Action*/}

                            {/*end::Action*/}
                          </div>
                          {/*end::Wrapper*/}
                        </div>
                      </td>
                    </tr>
                  </>)}
                </>) : (<>
                  <tr>
                    <td colspan={12}>
                      <TableLoader />
                    </td>
                  </tr>

                </>)}
              </tbody>
              {/*end::Table body*/}
            </table>
            {/*end::Table*/}
          </div>
          {/*end::Table container*/}
          {/*begin::Body*/}
        </div>
        {/*end::Tables Widget 9*/}
      </div>

    </>
  )

}
