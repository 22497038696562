import React from "react";
import "react-toastify/dist/ReactToastify.css";
import UseApi from "../../../../../CustomHooks/useApi";
import { toast } from "react-toastify";
import { elements } from "chart.js";

export default function ReceiveSend(props) {
  var query = window.location.href;
  var vars = query.split("/");
  var id = vars[4];
  const handleSendMessage = async() => {
    toast.dismiss()
    const dt = toast.loading("Please wait...");
    const submitBtn = document.querySelector("#sendMessage-btn");
    var preContent = submitBtn.innerHTML;
    submitBtn.classList.add("disabled");
    submitBtn.innerHTML = 'Please wait... <span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
    let responseData = await UseApi('post',`sqs/${id}/sendmessage`,'sendMessage')
    if(responseData.status === 'success'){
      toast.update(dt, {
        render: responseData.message,
        type: "success",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    submitBtn.classList.remove("disabled");
    submitBtn.innerHTML = preContent
    props.openDrawerSR()
    }else{
      toast.update(dt, {
        render: responseData.message,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    submitBtn.classList.remove("disabled");
    submitBtn.innerHTML = preContent
    }
  }

  const handleClearMessage = ()  => {
    let element = document.querySelector('#message_box')
    element.value = ''
  }
  return (
    <>
      <div style={{ zIndex: 110 }}>
        <form id="sendMessage">
          <div className="col-xl-12 card mb-5" style={{ border: "none !important" }}>
            <div className="card-header border-3 mb-5 pt-2 d-none">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder text-gray-800">
                  Send message
                </span>
                <span className="text-gray-400 mt-1 fw-bold fs-6">
                  Send messages from a queue. </span>
              </h3>

            </div>
            <div className="mx-5 mb-3 mt-10">
              <div
                className="row g-9"
                data-kt-buttons="true"
                data-kt-buttons-target="[data-kt-button]"
              >
                <div className="col-lg-12 col-xxl-12">
                  <label class="required form-label ps-3">Message body</label>
                  <p className="text-gray-600 ps-3">Enter the message to send to the queue.</p>

                  <div className="input-group">
                    <textarea
                      type="text"
                      className="form-control"
                      id="message_box"
                      name="message"
                      rows="5"
                    >
                    </textarea>
                  </div>
                </div>
                <input
                type="hidden"
                name="name"
                id="queue_name"/>
                <div className="col-lg-8 col-xxl-8 d-none">
                  <label class="required form-label ps-3">Delivery delay</label>

                  <div className="input-group mb-3 w-75">
                    <input
                      type="text"
                      name="visibility_timeout"
                      className="form-control"
                      defaultValue="0"
                    />
                    <select className="form-select" defaultValue="days">
                      <option value="seconds">Seconds</option>
                      <option value="minutes">Minutes</option>
                    </select>
                  </div>
                  <span className="text-gray-600 ps-3">Should be between 0 seconds and 15 minutes.</span>
                </div>
              </div>
            </div>
            <div className="mt-10 mb-10 container">
              <a className="btn btn-secondary btn-sm me-3" onClick={handleClearMessage}>Clear Content</a>
              <a className="btn btn-primary btn-sm" id="sendMessage-btn" onClick={handleSendMessage}>Send Message &nbsp;
                {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen016.svg*/}
                <span className="svg-icon svg-icon-muted svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z"
                      fill="black"
                    />
                    <path
                      opacity="0.3"
                      d="M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648Z"
                      fill="black"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
              </a>
            </div>
          </div>
          {/*end::Tables Widget 9*/}
        </form>
      </div>
    </>
  );
};


