import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { IsCloudUpdate } from "../../../../../Redux/Services/Action/actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import UseApi from "../../../../../CustomHooks/useApi";
import UsePost from "../../../../../CustomHooks/usePost";
import TopBarHeaderLine from "../../../../../CustomHooks/TopBarHeaderLine";
import ProductsUrl from "../../../Docs/Docsdata";
import CustomDoc from "../../../../../CustomHooks/CustomDoc";
import UsePostData from "../../../../../CustomHooks/usePostData";
import { json } from "d3";
import TableLoader from "../../../Loaders/TableLoader";
const Storage = (props) => {
  const navigate = useNavigate();
  var query = window.location.href;
  var vars = query.split("/");
  const [pageStatus, setPageStatus] = useState(false);
  var id = vars[4];
  const dataUseRef = useRef();
  const [storageData, setStorageData] = useState(null);
  const [isLoader, setIsLoader] = useState(false)
  const dataFetchedRef = useRef(false);
  const dispatch = useDispatch();

  const navRedirect = () => {
    setTimeout(() => {
      navigate("/cloud/" + id);
    }, 3000);
  };
  useEffect(() => {
    setStorageData(props.cloudData.storages);
  }, [props.cloudData]);

  const deleteStorage = async (storageId) => {
    toast.dismiss();
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this additional storage!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        var submitBtn = document.querySelector("#delete-storage-" + storageId);
        var updateButton = document.querySelector(`#update-storage-${storageId}`)
        if (submitBtn) {
          setPageStatus(true);
          updateButton.classList.add('disabled')
          var preContent = submitBtn.innerHTML;
          submitBtn.classList.add("disabled");
          submitBtn.innerHTML =
            '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
          const dt = toast.loading(
            "Additional Storage " + storageId + " deleting, Please wait..."
          );
          const dnsp = await UseApi(
            "delete",
            "cloud/" + id + "/storage/" + storageId + "/delete",
            ""
          );
          if (dnsp.status == "error") {
            setPageStatus(false);
            toast.update(dt, {
              render: dnsp.message,
              type: "warning",
              isLoading: false,
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            setPageStatus(false);
            toast.update(dt, {
              render: dnsp.message,
              type: "success",
              isLoading: false,
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            const data = await UseApi("get", "cloud/" + id, "");
            dispatch(props.updateCloudStatus(data.cloud[0]));
            navRedirect();
          }
          updateButton.classList.remove('disabled')
          submitBtn.classList.remove("disabled");
          submitBtn.innerHTML = preContent;
        }
      } else {
        setPageStatus(false);
      }
    });
  };

  function checkStorageSize(storage_size) {
    // toast.dismiss();
    // if (storage_size < 1) {
    //   var sizeField = document.querySelector("#size");
    //   if (sizeField) {
    //     sizeField.value = 1;
    //   }
    //   // const dt=toast.loading("Additional storage assigning, Please wait...");
    //   // toast.update(dt,{render: "Storage size can not be less then 1GB", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    //   // });
    //   return;
    // }
  }
  const addAdditionalStorage = async () => {
    toast.dismiss();
    swal({
      title: "Are you sure?",
      text: "Do you really want to assign additional storage in your cloud!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        var submitBtn = document.querySelector("#add-additional-ip");
        if (submitBtn) {
          setPageStatus(true);
          var preContent = submitBtn.innerHTML;
          submitBtn.classList.add("disabled");
          submitBtn.innerHTML =
            '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';

          const dt = toast.loading(
            "Additional storage assigning, Please wait..."
          );
          const responseData = await UsePost(
            "post",
            "cloud/" + id + "/storage/add/",
            "addstorageform"
          );
          //  console.log(preContent);
          if (responseData) {
            submitBtn.classList.remove("disabled");
            submitBtn.innerHTML = preContent;
            if (responseData.status === "error") {
              setPageStatus(false);
              toast.update(dt, {
                render: responseData.message,
                type: "error",
                isLoading: false,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else if (responseData.status === "success") {
              setPageStatus(false);
              toast.update(dt, {
                render: responseData.message,
                type: "success",
                isLoading: false,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              const data = await UseApi("get", "cloud/" + id, "");
              dispatch(props.updateCloudStatus(data.cloud[0]));
            }
          }
        }
      } else {
        setPageStatus(false);
      }
    });
  };
  const updateRdns = async (storageId) => {
    toast.dismiss();
    swal({
      title: "Are you sure?",
      text: "Changing this secondary storage to primary will automatically designate all other storages in this cloud as secondary.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          const dt = toast.loading(
            "Updating storage " + storageId + " , Please wait..."
          );
          setIsLoader(true)
          var deleteButton = document.querySelector(`#delete-storage-${storageId}`)
          const bus_data = document.querySelector("#bus_data" + storageId);
          const type_data = document.querySelector("#type_data" + storageId);
          const bus_field = document.querySelector("#bus");
          const type_field = document.querySelector("#type");
          bus_field.value = bus_data.value;
          type_field.value = type_data.value;

          var submitBtn = document.querySelector("#update-storage-" + storageId);
          if (submitBtn) {
            setPageStatus(true);
            var preContent = submitBtn.innerHTML;
            submitBtn.classList.add("disabled");
            if (deleteButton) {
              deleteButton.classList.add("disabled")
            }
            submitBtn.innerHTML =
              '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
            const responseData = await UsePost(
              "post",
              "cloud/" + id + "/storage/" + storageId + "/update",
              "updatestorage_"
            );

            //////console.log(responseData);
            if (responseData.status === "error") {
              setPageStatus(false);
              setIsLoader(false)
              toast.update(dt, {
                render: responseData.message,
                type: "error",
                isLoading: false,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else if (responseData.status === "success") {
              setPageStatus(false);
              setIsLoader(false)
              toast.update(dt, {
                render: responseData.message,
                type: "success",
                isLoading: false,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              const data = await UseApi("get", "cloud/" + id, "");
              dispatch(props.updateCloudStatus(data.cloud[0]));
              if (props && props.getCloud) {
                props.getCloud()
              }
              navRedirect();
            }
            if (deleteButton) {
              deleteButton.classList.remove("disabled");
            }
            submitBtn.classList.remove("disabled");
            submitBtn.innerHTML = preContent;
          }
        }
      })
  };
  console.log(storageData);

  function convertTimestamp(timestamp) {
    const date = new Date(timestamp);
    // Format the date
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
      timeZone: 'UTC'
    }).format(date);

    return formattedDate.replace('GMT', '(UTC)');
  }

  const dettachServer = async (ebsid) => {

    swal({
      title: "Are you sure to Detach Volume #" + ebsid,
      text: "After detaching this volume, you may still incur storage charges. if you no longer need the volume, delete it from the EBS section to stop incurring charges.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        var submitBtn = document.querySelector("#delete-storage-" + ebsid);
        if (submitBtn) {
          var preContent = submitBtn.innerHTML;
          submitBtn.classList.add("disabled");
          submitBtn.innerHTML = '<span className="indicator-label"> <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
          const dt = toast.loading("Dettaching storage, Please wait...");
          const responseData = await UsePostData('put', 'ebs/' + ebsid + '/dettach', { type: 'cloud', resourceid: id });
          if (responseData) {
            submitBtn.classList.remove("disabled");
            submitBtn.innerHTML = preContent;
            if (responseData.status === 'error') {
              toast.update(dt, {
                render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
              });
            } else if (responseData.status === 'success') {
              toast.update(dt, {
                render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
              });
              const data = await UseApi("get", "cloud/" + id, "");
              dispatch(props.updateCloudStatus(data.cloud[0]));
              navRedirect();

            }
          }
        }
      }
    })
  }
  return (
    <>
      {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        /> */}

      <div className="card">
        {/*begin::Card header*/}
        <div className="card-header">
          {/*begin::Heading*/}
          <div className="card-title">
            <h3>Current Storage on this server </h3>
          </div>
          {/*end::Heading*/}
          <div className="card-toolbar">
            <CustomDoc url={ProductsUrl[0]?.cloud + "/manage-cloud/storage/"} />
          </div>
        </div>
        {/*end::Card header*/}
        {/*begin::Card body*/}
        {!isLoader ?
          <div className="card-body p-0">
            {/*begin::Table wrapper*/}
            <div className="table-responsive">
              {/*begin::Table*/}
              <form className="form" id="updatestorage_" name="updatestorage_">
                <input type="hidden" name="bus" id="bus" defaultValue="" />
                <input type="hidden" name="type" id="type" defaultValue="" />
              </form>
              <table
                className="table table-striped table-flush align-middle table-row-bordered table-row-solid gy-4"
                id="kt_security_license_usage_table"
              >
                {/*begin::Thead*/}
                <thead className="border-gray-200 fs-5 fw-bold bg-secondary">
                  <tr>
                    <th className="w-150px ps-9">ID</th>
                    <th className="w-250px text-center">Size</th>
                    <th className="w-150px pe-9 text-center">Disk-type</th>
                    <th className="w-150px text-center">BUS</th>
                    <th className="pe-5 w-200px text-center">Type</th>
                    <th className="w-250px text-center">Created_at</th>
                    <th className="w-250px ps-9" />
                  </tr>
                </thead>
                <tbody className="fw-6 fw-bold text-gray-600" id="storage-list">
                  {storageData != null &&
                    storageData &&
                    storageData.map((value, index) => (
                      <tr>
                        <td className="w-150px ps-9 text-gray-600">{value.id}</td>
                        <td className="w-150px text-gray-600 text-center">
                          {value.size} GB
                          <span className="badge badge-light-success fs-7 fw-bolder d-none" style={{ border: "1px solid #50cd89" }} >
                            Used {value.disk_usedp}%
                          </span>
                        </td>
                        <td className="w-150px text-gray-600 pe-9 text-center">
                          {value.ebs ? (<>
                            <span className="badge badge-light-success fs-7 fw-bolder" style={{ border: "1px solid #50cd89" }} >
                              EBS
                            </span></>) : (<>
                              <span className="badge badge-light-success fs-7 fw-bolder" style={{ border: "1px solid #50cd89" }} >
                                General
                              </span>
                            </>)}

                        </td>
                        <td className="w-150px text-center text-gray-600">
                          <select
                            className="form-select form-select-sm"
                            id={"bus_data" + value.id}
                          >
                            {value.bus == "virtio" ? (
                              <>
                                <option value="virtio" selected="Selected">
                                  virtio
                                </option>
                                <option value="ide">ide</option>
                              </>
                            ) : (
                              <>
                                <option value="virtio">virtio</option>
                                <option value="ide" selected="Selected">
                                  ide
                                </option>
                              </>
                            )}
                          </select>
                        </td>
                        <td className="p-5 pe-5 w-150px text-center text-gray-600">
                          <select
                            className="form-select form-select-sm"
                            id={"type_data" + value.id}
                          >
                            {value.type == "Primary" ? (
                              <>
                                <option value="Primary" selected="Selected">
                                  Primary
                                </option>
                                {/* <option value="Additional">Additional</option> */}
                              </>
                            ) : (
                              <>
                                <option value="Primary">Primary</option>
                                <option value="Additional" selected="Selected">
                                  Additional
                                </option>
                              </>
                            )}
                          </select>
                        </td>
                        <td className="text-center text-gray-600">
                          {value.created_at}
                        </td>
                        <td className="w-250px ps-9">
                          <a
                            id={"update-storage-" + value.id}
                            onClick={() => updateRdns(value.id)}
                            className="btn btn-sm btn-primary me-5"
                          >
                            Update
                            {/* <span class="svg-icon svg-icon-1"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z" fill="black"></path><path d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z" fill="black"></path><path d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z" fill="black"></path></svg></span> */}
                          </a>
                          {value.type == "Additional" ? (
                            <>
                              {value.ebs ? (<>
                                <a
                                  id={"delete-storage-" + value.id}
                                  onClick={() => dettachServer(value.id)}
                                  className="btn btn-sm btn-secondary"
                                >
                                  Detach
                                </a>
                              </>) : (<>
                                <a
                                  id={"delete-storage-" + value.id}
                                  onClick={() => deleteStorage(value.id)}
                                  className="btn btn-sm btn-danger btn-active-light-danger"
                                >
                                  Delete
                                </a>
                              </>)}
                            </>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            {/*end::Table wrapper*/}
          </div>
          :
          <TableLoader />
        }
        {/*end::Card body*/}
      </div>
      {/*end::License usage*/}
      <div className="separator my-10" />
      {/*begin::Deactivate Account*/}
      <div className="card">
        {/*begin::Card header*/}
        <div
          className="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_deactivate"
          aria-expanded="true"
          aria-controls="kt_account_deactivate"
        >
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">Add Storage</h3>
          </div>
        </div>
        {/*end::Card header*/}
        {/*begin::Content*/}
        <div id="kt_account_settings_deactivate" className="collapse show">
          {/*begin::Form*/}
          <form id="addstorageform" className="form">
            {/*begin::Card body*/}
            <div className="card-body border-top p-9">
              {/*begin::Notice*/}
              <div className="notice d-flex bg-light-primary rounded border-primary border mb-9 p-6">
                {/*begin::Icon*/}
                {/*begin::Svg Icon | path: icons/duotune/general/gen044.svg*/}
                <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.3"
                      x={2}
                      y={2}
                      width={20}
                      height={20}
                      rx={10}
                      fill="black"
                    />
                    <rect
                      x={11}
                      y={14}
                      width={7}
                      height={2}
                      rx={1}
                      transform="rotate(-90 11 14)"
                      fill="black"
                    />
                    <rect
                      x={11}
                      y={17}
                      width={2}
                      height={2}
                      rx={1}
                      transform="rotate(-90 11 17)"
                      fill="black"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
                {/*end::Icon*/}
                {/*begin::Wrapper*/}
                <div className="d-flex flex-stack flex-grow-1">
                  {/*begin::Content*/}
                  <div className="fw-bold">
                    <h4 className="text-gray-900 fw-bolder">
                      You can add/remove additional storage on your server.
                    </h4>
                    <div className="fs-6 text-gray-700">
                      You will charge INR 9/GB monthly for additional
                      storage.
                    </div>
                  </div>
                  {/*end::Content*/}
                </div>
                {/*end::Wrapper*/}
              </div>
              {/*end::Notice*/}
              {/*begin::Form input row*/}
              <div className="mb-1">
                <label htmlFor="size" className="required form-label">
                  Please fill size in GB
                </label>
                <input
                  type="number"
                  className="form-control"
                  min={1}
                  name="size"
                  id="size"
                  defaultValue=""
                  placeholder={50}
                  onChange={(e) => checkStorageSize(e.target.value)}
                />
              </div>
              {/*end::Form input row*/}
            </div>
            {/*end::Card body*/}
            {/*begin::Card footer*/}
            <div className=" d-flex justify-content-start py-6 px-9">
              <a
                id="add-additional-ip"
                onClick={addAdditionalStorage}
                className="btn btn-primary fw-bold"
              >
                + Add Storage
              </a>
            </div>
            {/*end::Card footer*/}
          </form>
          {/*end::Form*/}
        </div>
        {/*end::Content*/}
      </div>
      {pageStatus && <TopBarHeaderLine />}
    </>
  );
};

const mapStateToProps = (state) => ({
  cloudData: state.cloud.cloudDataInfo,
});

const mapDispatchToProps = (dispatch) => ({
  updateCloudStatus: (data) => dispatch(IsCloudUpdate(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Storage);
