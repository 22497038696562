import Cookies from 'js-cookie';
import localStorage from 'local-storage';
import React, { useEffect } from 'react'
import PartnerApp from '../PartnerApp/PartnerApp';
import MicroApp from '../MicroApp/MicroApp';
import BillingVerification from '../MicroApp/Body/Products/verification/Billing/BillingVerification';

export default function Config() {
 
     var userInfo=localStorage.get("user_info");
      if(userInfo){
        if(userInfo.is_partner=="1"){
         var login_type= Cookies.get('login_type');
            if(login_type=="partner"){
                return (
                    <>
                    <PartnerApp />
                    </>
                  )
            }else{
                return (
                    <>
                    {/* <BillingVerification /> */}
                    <MicroApp />
                    </>
                  ) 
            }
        }else{
            return (
                <>
                {/* <BillingVerification /> */}
                <MicroApp />
                </>
              )
        }
      } 

 
}
