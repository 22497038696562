import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import "../custom.css";
import UseApi from "../../../../../CustomHooks/useApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UsePost from "../../../../../CustomHooks/usePost";
import swal from "sweetalert";
import TableLoader from "../../../Loaders/TableLoader";
import TopBarHeaderLine from "../../../../../CustomHooks/TopBarHeaderLine";
import UsePostData from "../../../../../CustomHooks/usePostData";
import CreateLoadbalancerTargetGroup from "../Header/CreateLoadbalancerTargetGroup";
import Targets from "./Targets";

export default function ManageTargetsGroups() {

  var query = window.location.href;
  var vars = query.split("/");
  var id = vars[4];

  const navigate = useNavigate();
  const [targetGroup, setTargetGroup] = useState({
    protocol: "HTTP", // Initial protocol, you can modify it based on your requirement
  });
  const [pageStatus, setPageStatus] = useState(false)
  const dataFetchedRef = useRef(false);

  const navRedirect = () => {
    setTimeout(() => {
      navigate("/targetgroup/" + id);
    }, 3000);
  };
  const getTargetGroup = async () => {
    const data = await UseApi("get", "targetgroup/" + id, "");
    if (data.status != "success") {
      swal({
        title: "Target Group",
        text: "No targetGroup record found with id: " + id + "!",
        icon: "warning",
        button: "OK",
      }).then(() => {
        navigate("/targetgroup");
      });
      return;
    } else {
      setTargetGroup(data.targetgroup);
    }
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getTargetGroup();
  }, []);


  const handleProtocolChange = (event) => {
    setTargetGroup({
      ...targetGroup,
      protocol: event.target.value, // Update the protocol when the dropdown changes
    });
  };

  const destroyTarget = async (TargetName) => {

    const { value: isDelete } = await Swal.fire({
      title: 'Delete Target Group',
      html: `
       <div className="d-flex align-items-center flex-wrap"> 
       <div id="kt_clipboard_4" className="me-5 fw-bold text-gray-600">
         <p class="fs-5">Enter the Target Group name to delete:</p>
 
         <span class="fw-bold" id="file_url_target" >${TargetName}</span>
         <span id="file_url_target_copy_btn" style="cursor:pointer" class="text-primary">( copy )</span> 
       </div>  
     </div>
    `,
      input: 'text',
      inputPlaceholder: 'Group name',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'delete-button bg-danger', // Add your custom class here
      },
    });
    if (isDelete?.trim() == TargetName) {

      toast.dismiss();
      const dt = toast.loading("Target Group deleting...");
      const dnsp = await UseApi('DELETE', 'targetgroup/' + id + '?name=' + TargetName, "");
      ////console.log(dnsp);
      if (dnsp.status == 'error') {
        toast.update(dt, {
          render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
      } else if (dnsp.status == 'success') {
        toast.update(dt, {
          render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        navigate('/targetgroups');
      }
    }
  }

  const copyClusterName = () => {
    var eleText = document.querySelector("#file_url_target");
    var ele = document.querySelector("#file_url_target_copy_btn");

    if (ele && eleText) {
      const copyText = eleText.innerText;
      const originalContent = ele.innerHTML;
      console.log(originalContent);

      navigator.clipboard.writeText(copyText).then(() => {
        ele.innerHTML = "(Copied)";

        setTimeout(() => {
          ele.innerHTML = "(copy)";
        }, 1000);
      }).catch(err => {
        console.error('Failed to copy text: ', err);
      });
    }
  };

  function handleButtonClick(event) {
    console.log(event.target);
    if (event.target && event.target.id === 'file_url_target_copy_btn') {
      copyClusterName();
      event.stopPropagation();
    }
  }

  // Remove any existing listener before adding a new one (if necessary)
  document.removeEventListener('click', handleButtonClick);
  document.addEventListener('click', handleButtonClick);



  const updateTargetGroup = async () => {
    const submitBtn = document.querySelector("#update-target-btn");
    const form = document.getElementById('targetGroup-update-form');

    if (submitBtn) {
      let preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = '<span class="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      toast.dismiss();
      const dt = toast.loading("Please wait...");

      // Form validation: Prevent form submission if any input has a value of 0
      const formValues = new FormData(form);
      for (let [key, value] of formValues.entries()) {
        if (parseFloat(value) === 0) {
          toast.update(dt, {
            render: `Field ${key} cannot have a value of 0`, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: true
          });
          submitBtn.innerHTML = preContent;
          submitBtn.classList.remove("disabled");
          return; // Stop the submission
        }
      }

      try {
        const responseData = await UsePost('put', 'targetgroup/' + id, 'targetGroup-update-form');

        if (responseData) {
          submitBtn.innerHTML = preContent;
          submitBtn.classList.remove("disabled");

          if (responseData.status === 'error') {
            toast.update(dt, {
              render: responseData.message,
              type: "error",
              isLoading: false,
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
          } else if (responseData.status === 'success') {
            toast.update(dt, {
              render: responseData.message,
              type: "success",
              isLoading: false,
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
            // navigate("/targetgroup/"+responseData.id);
          }
        }
      } catch (error) {
        // Handle any errors during the post request
        toast.update(dt, {
          render: "An error occurred while updating.",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false
        });
        submitBtn.innerHTML = preContent;
        submitBtn.classList.remove("disabled");
      }
    }
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Use regex to remove non-numeric characters (including minus sign and alphabet)
    const filteredValue = value.replace(/[^0-9]/g, '');

    setTargetGroup((prev) => ({
      ...prev,
      [name]: filteredValue,
    }));
  };

  return (
    <>
      {targetGroup != null ? (
        <>
          {/* <CreateLoadbalancerTargetGroup /> */}
          <div className="card mb-6 mb-xl-9">
            <div className="card-body pt-9 pb-0">
              {/*begin::Details*/}
              <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                {/*begin: Pic*/}
                <div className="me-7 mb-4">
                  <div
                    id="flag-icon"
                    className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative"
                  >
                    {/*begin::Image input*/}
                    <div
                      className="image-input image-input-empty"
                      data-kt-image-input="true"
                    >

                      <span className="svg-icon svg-icon-muted svg-icon-4tx">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            opacity="0.3"
                            d="M5 8.04999L11.8 11.95V19.85L5 15.85V8.04999Z"
                            fill="black"
                          />
                          <path
                            d="M20.1 6.65L12.3 2.15C12 1.95 11.6 1.95 11.3 2.15L3.5 6.65C3.2 6.85 3 7.15 3 7.45V16.45C3 16.75 3.2 17.15 3.5 17.25L11.3 21.75C11.5 21.85 11.6 21.85 11.8 21.85C12 21.85 12.1 21.85 12.3 21.75L20.1 17.25C20.4 17.05 20.6 16.75 20.6 16.45V7.45C20.6 7.15 20.4 6.75 20.1 6.65ZM5 15.85V7.95L11.8 4.05L18.6 7.95L11.8 11.95V19.85L5 15.85Z"
                            fill="black"
                          />
                        </svg>
                      </span>

                    </div>{" "}
                    {/*end::Image input*/}
                  </div>
                </div>
                {/*end::Pic*/}
                {/*begin::Info*/}
                <div className="flex-grow-1">
                  {/*begin::Title*/}
                  <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                    {/*begin::User*/}
                    <div className="d-flex flex-column">
                      {/*begin::Name*/}
                      <div className="d-flex align-items-center mb-2">
                        <a
                          className="text-gray-900 text-hover-primary fs-2 fw-bolder me-1"
                          id="head-name"
                        >
                          {targetGroup != null ? <> {targetGroup.name} </> : ""}
                        </a>
                      </div>
                      {/*end::Name*/}
                      {/*begin::Info*/}



                      <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                        <a
                          className="d-flex align-items-center text-gray-500 me-5 mb-2"
                        >
                          {/*begin::Svg Icon | path: icons/duotune/communication/com006.svg*/}
                          <span className="text-gray-700"> Protocol</span>&nbsp;
                          <span className="svg-icon svg-icon-4 me-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                opacity="0.3"
                                d="M18.4 5.59998C18.7766 5.9772 18.9881 6.48846 18.9881 7.02148C18.9881 7.55451 18.7766 8.06577 18.4 8.44299L14.843 12C14.466 12.377 13.9547 12.5887 13.4215 12.5887C12.8883 12.5887 12.377 12.377 12 12C11.623 11.623 11.4112 11.1117 11.4112 10.5785C11.4112 10.0453 11.623 9.53399 12 9.15698L15.553 5.604C15.9302 5.22741 16.4415 5.01587 16.9745 5.01587C17.5075 5.01587 18.0188 5.22741 18.396 5.604L18.4 5.59998ZM20.528 3.47205C20.0614 3.00535 19.5074 2.63503 18.8977 2.38245C18.288 2.12987 17.6344 1.99988 16.9745 1.99988C16.3145 1.99988 15.661 2.12987 15.0513 2.38245C14.4416 2.63503 13.8876 3.00535 13.421 3.47205L9.86801 7.02502C9.40136 7.49168 9.03118 8.04568 8.77863 8.6554C8.52608 9.26511 8.39609 9.91855 8.39609 10.5785C8.39609 11.2384 8.52608 11.8919 8.77863 12.5016C9.03118 13.1113 9.40136 13.6653 9.86801 14.132C10.3347 14.5986 10.8886 14.9688 11.4984 15.2213C12.1081 15.4739 12.7616 15.6039 13.4215 15.6039C14.0815 15.6039 14.7349 15.4739 15.3446 15.2213C15.9543 14.9688 16.5084 14.5986 16.975 14.132L20.528 10.579C20.9947 10.1124 21.3649 9.55844 21.6175 8.94873C21.8701 8.33902 22.0001 7.68547 22.0001 7.02551C22.0001 6.36555 21.8701 5.71201 21.6175 5.10229C21.3649 4.49258 20.9947 3.93867 20.528 3.47205Z"
                                fill="black"
                              />
                              <path
                                d="M14.132 9.86804C13.6421 9.37931 13.0561 8.99749 12.411 8.74695L12 9.15698C11.6234 9.53421 11.4119 10.0455 11.4119 10.5785C11.4119 11.1115 11.6234 11.6228 12 12C12.3766 12.3772 12.5881 12.8885 12.5881 13.4215C12.5881 13.9545 12.3766 14.4658 12 14.843L8.44699 18.396C8.06999 18.773 7.55868 18.9849 7.02551 18.9849C6.49235 18.9849 5.98101 18.773 5.604 18.396C5.227 18.019 5.0152 17.5077 5.0152 16.9745C5.0152 16.4413 5.227 15.93 5.604 15.553L8.74701 12.411C8.28705 11.233 8.28705 9.92498 8.74701 8.74695C8.10159 8.99737 7.5152 9.37919 7.02499 9.86804L3.47198 13.421C2.52954 14.3635 2.00009 15.6417 2.00009 16.9745C2.00009 18.3073 2.52957 19.5855 3.47202 20.528C4.41446 21.4704 5.69269 21.9999 7.02551 21.9999C8.35833 21.9999 9.63656 21.4704 10.579 20.528L14.132 16.975C14.5987 16.5084 14.9689 15.9544 15.2215 15.3447C15.4741 14.735 15.6041 14.0815 15.6041 13.4215C15.6041 12.7615 15.4741 12.108 15.2215 11.4983C14.9689 10.8886 14.5987 10.3347 14.132 9.86804Z"
                                fill="black"
                              />
                            </svg>:
                            {targetGroup != null ? <> {targetGroup.protocol} </> : ""}
                          </span>
                          {/*end::Svg Icon*/}
                        </a>

                        <a
                          className="d-flex align-items-center text-gray-500 me-5 mb-2"
                        >
                          <span className="text-gray-700">Port</span>&nbsp;
                          {/*begin::Svg Icon | path: icons/duotune/communication/com006.svg*/}
                          <span className="svg-icon svg-icon-4 me-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z"
                                fill="black"
                              />
                              <path
                                opacity="0.3"
                                d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z"
                                fill="black"
                              />
                            </svg>:
                            {targetGroup != null ? <> {targetGroup.port} </> : ""}
                          </span>
                          {/*end::Svg Icon*/}
                        </a>
                      </div>




                      {/*end::Info*/}
                    </div>
                    {/*end::User*/}

                  </div>
                  {/*end::Title*/}
                </div>
                {/*end::Info*/}
              </div>
              {/*end::Details*/}
              <div className="separator" />
              {/*begin::Nav*/}
              <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder">
                {/*begin::Nav item*/}
                <li className="nav-item">
                  <a
                    className="nav-link text-active-primary py-5 me-6 active"
                    data-bs-toggle="tab"
                    href="#configurations"
                  >
                    Configuration
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link text-active-primary py-5 me-6"
                    data-bs-toggle="tab"
                    href="#targets"
                  >
                    Targets
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link text-active-primary py-5 me-6"
                    data-bs-toggle="tab"
                    href="#destroy-target"
                  >
                    Destroy
                  </a>
                </li>
                {/*end::Nav item*/}
              </ul>
              {/*end::Nav*/}
            </div>
          </div>


          <div className="tab-content" id="myTabContent">

            <div className="tab-pane fade show active" id="configurations" role="tabpanel">
              <div className="card w-100 rounded-0 border-0" id="kt_drawer_chat_messenger">
                <div className="card-header pe-5" id="kt_drawer_chat_messenger_header">
                  <div className="card-title">
                    <div className="d-flex justify-content-center flex-column me-3">
                      <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                        Update Target Group
                      </a>
                    </div>
                  </div>

                </div>
                <div className="" id="">
                  <form id="targetGroup-update-form">
                    <div className="modal-body ">
                      <div className="scroll-y me-n7 pe-7 row">
                        <div className="col-lg-4 col-md-4 col-xl-4">
                          <div className="mb-5 fv-row">
                            <label htmlFor="type" className="required fs-5 fw-bold mb-2">
                              {" "}
                              Name
                            </label>
                            <input
                              type="text"
                              name="name"
                              className="form-control"
                              defaultValue={targetGroup.name}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-xl-4">
                          <div className="mb-5 fv-row">
                            <label htmlFor="type" className="required fs-5 fw-bold mb-2">
                              {" "}
                              Protocol
                            </label>
                            <select
                              className="form-select"
                              value={targetGroup.protocol} // Use the current value from state
                              onChange={handleProtocolChange} // Call the change handler when a new option is selected
                              name="protocol"
                            >
                              <option value="HTTP">HTTP</option>
                              <option value="HTTPS">HTTPS</option>
                              <option value="TCP">TCP</option>
                              <option value="UDP">UDP</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-xl-4">
                          <div className="mb-5 fv-row">
                            <label htmlFor="type" className="required fs-5 fw-bold mb-2">
                              {" "}
                              Port
                            </label>
                            <input
                              type="text"
                              name="port"
                              className="form-control"
                              value={targetGroup.port}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        {/* <div className="col-lg-4 col-md-4 col-xl-4 d-none">
                          <div className="mb-5 fv-row">
                            <label htmlFor="type" className="required fs-5 fw-bold mb-2">
                              {" "}
                              Port
                            </label>
                            <input
                              type="number"
                               name="port"
                              className="form-control"
                              defaultValue={targetGroup.port}
                            />
                          </div>
                        </div> */}
                        <div className="col-lg-8 col-md-8 col-xl-8">
                          <div className="mb-5 fv-row">
                            <label htmlFor="type" className="required fs-5 fw-bold mb-2">
                              {" "}
                              Health Check Path
                            </label>
                            <input
                              type="url"
                              name="health_check_path"
                              className="form-control"
                              defaultValue={targetGroup.health_check_path}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-xl-4">
                          <div className="mb-5 fv-row">
                            <label htmlFor="type" className="required fs-5 fw-bold mb-2 sphide">
                              Health Check Protocol
                            </label>
                            <select className="form-select" defaultValue={targetGroup.health_check_protocol} name="health_check_protocol">
                              <option value="HTTP">HTTP</option>
                              <option value="HTTPS">HTTPS</option>
                              <option value="TCP">TCP</option>
                              <option value="UDP">UDP</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-xl-3">
                          <div className="mb-5 fv-row">
                            <label htmlFor="type" className="required fs-5 fw-bold mb-2">
                              {" "}
                              Health Check Interval
                            </label>
                            <input
                              type="text"
                              name="health_check_interval"
                              className="form-control"
                              value={targetGroup.health_check_interval}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-xl-3">
                          <div className="mb-5 fv-row">
                            <label htmlFor="type" className="required fs-5 fw-bold mb-2">
                              {" "}
                              Health Check Timeout
                            </label>
                            <input
                              type="text"
                              name="health_check_timeout"
                              className="form-control"
                              value={targetGroup.health_check_timeout}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-xl-3">
                          <div className="mb-5 fv-row">
                            <label htmlFor="type" className="required fs-5 fw-bold mb-2">
                              {" "}
                              Healthy Threshold
                            </label>
                            <input
                              type="text"
                              name="healthy_threshold"
                              className="form-control"
                              value={targetGroup.healthy_threshold}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-xl-3">
                          <div className="mb-5 fv-row">
                            <label htmlFor="type" className="required fs-5 fw-bold mb-2">
                              {" "}
                              Unhealthy Threshold
                            </label>
                            <input
                              type="text"
                              name="unhealthy_threshold"
                              className="form-control"
                              value={targetGroup.unhealthy_threshold}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-xl-12 mt-10">
                          <div className="mb-5 fv-row">
                            <a onClick={updateTargetGroup} className="btn btn-primary w-100" id="update-target-btn">Update Target Group</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="targets" role="tabpanel">
              {targetGroup != null ? (<>
                <Targets refreshTarget={getTargetGroup} trData={targetGroup} />
              </>) : ('')}
            </div>
            <div className="tab-pane fade" id="destroy-target" role="tabpanel">
              {/*begin::Deactivate Account*/}
              <div className="card">
                {/*begin::Card header*/}
                <div
                  className="card-header border-0 cursor-pointer"
                  role="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_account_deactivate"
                  aria-expanded="true"
                  aria-controls="kt_account_deactivate"
                >
                  <div className="card-title m-0">
                    <h3 className="fw-bolder m-0">Destroy Target Group</h3>
                  </div>
                </div>
                {/*end::Card header*/}
                {/*begin::Content*/}
                <div
                  id="kt_account_settings_deactivate"
                  className="collapse show"
                >
                  {/*begin::Form*/}
                  {/*begin::Card body*/}
                  <div className="card-body border-top p-9">
                    {/*begin::Form input row*/}
                    <div className="mb-10">
                      <label htmlFor="size" className=" form-label">
                        Your target group will be permanently destroyed. Any
                        associated servers will be disconnected and will stop
                        receiving distributed traffic. Servers will not be
                        destroyed. This will not affect any associated Servers.
                      </label>
                    </div>
                    {/*end::Form input row*/}
                  </div>
                  {/*end::Card body*/}
                  {/*begin::Card footer*/}
                  <div
                    className="card-footer d-flex justify-content-end py-6 px-9"
                    id="destroy-load"
                  >
                    <button
                      onClick={(e) => destroyTarget(targetGroup.name)}
                      id="destroy-btn"
                      type="button"
                      className="btn btn-sm btn-danger fw-bold"
                    >
                      Destroy
                    </button>
                  </div>
                  {/*end::Card footer*/}
                  {/*end::Form*/}
                </div>
                {/*end::Content*/}
              </div>
              {/*end::Deactivate Account*/}
            </div>
          </div>
        </>
      ) : (
        <> <TableLoader />
          <TopBarHeaderLine /></>
      )}
    </>
  );
}
