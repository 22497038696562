import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { IsCloudUpdate } from "../../../../../../Redux/Services/Action/actions";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import swal from 'sweetalert';
import UseApi from "../../../../../../CustomHooks/useApi";
import UsePost from "../../../../../../CustomHooks/usePost";
import TopBarHeaderLine from "../../../../../../CustomHooks/TopBarHeaderLine";
import UsePostData from "../../../../../../CustomHooks/usePostData";
import TableLoader from "../../../../Loaders/TableLoader";
import PublicNetwork from "./components/PublicNetwork";
import PrivateNetwork from "./components/PrivateNetwork"; 
import Vpc from "./components/Vpc";
const Network = (props) => {
  const navigate = useNavigate();
  var query = window.location.href;
  const [pageStatus, setPageStatus] = useState(false)
  var vars = query.split("/");
  var id = vars[4];
  const [vpcsDisabled, setVpcsDisabled] = useState(false);
  const [privateData, setPrivateData] = useState(null);
  const [publicData, setPublicData] = useState(null);
  const [vpcData, setVpcData] = useState(null);
   const [state, setState] = useState("enable")
  const dataFetchedRef = useRef(false);
  const dispatch = useDispatch();
  // if(props.cloudData.networks){
  // ////console.log(props.cloudData.networks.private.v4);
  // }
  const navRedirect = () => {
    setTimeout(() => {
      navigate("/cloud/" + id);
    }, 3000);
  }
  useEffect(() => {
    if (props.cloudData.networks) {
       setPublicData(props.cloudData.networks.public.v4);
      setPrivateData(props.cloudData.networks.private.v4);
    } else {
      setPublicData([])
      setPrivateData([])
    }
  }, [props.cloudData]);

  const getVpc = async () => {

    setVpcData(null);
    const data = await UseApi('get', 'vpc', '');
    // delete (data.html);
    // if (Object.keys(data).length === 0) {
    // console.log(data);
    if (data?.status == "success") {
      var vpcList = data?.vpc.filter((item)=>{
        return item.dcslug== props?.cloudData?.dclocation?.dc;
      })
      console.log("vpcList", vpcList);
      setVpcData(vpcList)
    } else {
      setVpcData(null);
    }
  }
  useEffect(() => { 
    getVpc();
  }, []);

  const deletePrivateIp = async (privateId) => {
    toast.dismiss();
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Private IP!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          var submitBtn = document.querySelector("#delete-private-ip-" + privateId.replace(/\./g, "-"));
          if (submitBtn) {
            var preContent = submitBtn.innerHTML;
            submitBtn.classList.add("disabled");
            setPageStatus(true)
            submitBtn.innerHTML = '<span className="indicator-label">Please wait...<span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
            const dt = toast.loading("Private IP " + privateId + " deleting, Please wait...");
            const dnsp = await UseApi('delete', 'cloud/' + id + '/ip/' + privateId + '/delete', '');
            if (dnsp.status == 'error') {
              setPageStatus(false)
              toast.update(dt, {
                render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
              });
            } else {
              setPageStatus(false)
              toast.update(dt, {
                render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
              });
              const data = await UseApi('get', 'cloud/' + id, '');
              dispatch(props.updateCloudStatus(data.cloud[0]));
              navRedirect();
            }
            submitBtn.classList.remove("disabled");
            submitBtn.innerHTML = preContent;
          }
        } else {
          setPageStatus(false)
          swal({
            title: "Private IP",
            text: "Your Private IP is safe!",
            icon: "success",
            button: "OK",
          });
        }
      });

  }

  const deletePublicIp = async (publicId) => {
    toast.dismiss();
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Public IP!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          var submitBtn = document.querySelector("#delete-ip-" + publicId.replace(/\./g, "-"));
          if (submitBtn) {
            setPageStatus(true)
            var preContent = submitBtn.innerHTML;
            submitBtn.classList.add("disabled");
            submitBtn.innerHTML = '<span className="indicator-label">Please wait...<span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
            const dt = toast.loading("Public IP " + publicId + " deleting, Please wait....");
            const dnsp = await UseApi('delete', 'cloud/' + id + '/ip/' + publicId + '/delete', '');
            if (dnsp.status == 'error') {
              setPageStatus(false)
              toast.update(dt, {
                render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
              });
            } else {
              setPageStatus(false)
              toast.update(dt, {
                render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
              });
              const data = await UseApi('get', 'cloud/' + id, '');
              dispatch(props.updateCloudStatus(data.cloud[0]));
              navRedirect();
            }
            submitBtn.classList.remove("disabled");
            submitBtn.innerHTML = preContent;
          }
        } else {
          setPageStatus(false)
          swal({
            title: "Public IP",
            text: "Your Public IP is safe!",
            icon: "success",
            button: "OK",
          });
        }
      });

  }

  const addAdditionalIp = async () => {
    toast.dismiss();
    const dt = toast.loading("Additional IP assigning, Please wait...");
    var submitBtn = document.querySelector("#add-additional-ip");
    if (submitBtn) {
      setPageStatus(true)
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = '<span className="indicator-label">Please wait...<span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      const responseData = await UsePost('post', 'cloud/' + id + '/assignpublicip', 'assignpublicip');
      if (responseData.status === 'error') {
        setPageStatus(false)
        toast.update(dt, {
          render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
      } else if (responseData.status === 'success') {
        setPageStatus(false)
        toast.update(dt, {
          render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        const data = await UseApi('get', 'cloud/' + id, '');
        dispatch(props.updateCloudStatus(data.cloud[0]));
        navRedirect();
      }
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML = preContent;
    }
  }

  const addVpc = async (vpcid) => {
    toast.dismiss()
    setVpcsDisabled(true)
    swal({
      title: "Are you sure?",
      text: "Do you really want to assign VPC in your cloud!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          var submitBtn = document.querySelector("#vpc-" + vpcid);
          if (submitBtn) {
            setPageStatus(true)
            var preContent = submitBtn.innerHTML;
            submitBtn.classList.add("disabled");
            submitBtn.innerHTML = '<span class="svg-icon svg-icon-primary svg-icon-4x me-4"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path opacity="0.3" d="M19 18C20.7 18 22 16.7 22 15C22 13.3 20.7 12 19 12C18.9 12 18.9 12 18.8 12C18.9 11.7 19 11.3 19 11C19 9.3 17.7 8 16 8C15.4 8 14.8 8.2 14.3 8.5C13.4 7 11.8 6 10 6C7.2 6 5 8.2 5 11C5 11.3 5.00001 11.7 5.10001 12H5C3.3 12 2 13.3 2 15C2 16.7 3.3 18 5 18H19Z" fill="black"></path></svg></span> <span class="d-block fw-bold text-start"> <span classname="indicator-label sphide">Please wait...<span class="spinner-border spinner-border-sm align-middle ms-2"></span></span> </span>';
            const dt = toast.loading("Additional IP assigning, Please wait...");
            const responseData = await UsePost('post', 'cloud/' + id + '/assignvpcip/' + vpcid, 'addvpc');
            // ////console.log(responseData);
            if (responseData.status === 'error') {
              setPageStatus(false)
              toast.update(dt, {
                render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
              });
            } else if (responseData.status === 'success') {
              setPageStatus(false)
              toast.update(dt, {
                render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
              });
              getVpc();
              setTimeout(async () => {
                const data = await UseApi('get', 'cloud/' + id, '');
                dispatch(props.updateCloudStatus(data.cloud[0]));
                // navRedirect();
              }, 3000)

            }
            submitBtn.classList.remove("disabled");
            submitBtn.innerHTML = preContent;
            setVpcsDisabled(false)
          }
        } else {
          setVpcsDisabled(false)
          setPageStatus(false)
        }
      });

  }
  const updateRdns = async (publicId) => {
    toast.dismiss();
    const dt = toast.loading("Public IP publicId , Please wait...");
    var submitBtn = document.querySelector("#update-rdns-" + publicId.replace(/\./g, "-"));
    if (submitBtn) {
      setPageStatus(true)
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = '<span className="indicator-label sphide">Please wait...<span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      const ip_addres = publicId.replaceAll('.', '_');
      const responseData = await UsePost('post', 'cloud/' + id + '/updaterdns/' + publicId, 'updaterdns_' + ip_addres);

      // ////console.log(responseData);
      if (responseData.status === 'error') {
        setPageStatus(false)
        toast.update(dt, {
          render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
      } else if (responseData.status === 'success') {
        setPageStatus(false)
        toast.update(dt, {
          render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        const data = await UseApi('get', 'cloud/' + id, '');
        dispatch(props.updateCloudStatus(data.cloud[0]));
        navRedirect();
      }
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML = preContent;
    } else {
      setPageStatus(false)
    }
  }
   const handleChangeIpStatus = async (endpoint, name, element_id) => {
    toast.dismiss(); 
    swal({
      title: "Are you sure?",
      text: "Do you really want to "+name+" Public IP",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          const dt = toast.loading(`Public IP ${name} Please wait...`);
          var submitBtn = document.querySelector(`#${element_id}`);
    if (submitBtn) {
      setPageStatus(true)
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = '<span className="indicator-label sphide">Please wait...<span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      // const ip_addres = publicId.replaceAll('.', '_');
      const responseData = await UsePostData('post', 'cloud/' + id + '/' + endpoint, {});

      // ////console.log(responseData);
      if (responseData.status === 'error') {
        setPageStatus(false)
        toast.update(dt, {
          render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
      } else if (responseData.status === 'success') {
        setPageStatus(false)
        toast.update(dt, {
          render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        props.getCloud()
        // const data = await UseApi('get', 'cloud/' + id, '');
        // dispatch(props.updateCloudStatus(data.cloud[0]));
        navRedirect();
      }
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML = preContent;
    } else {
      setPageStatus(false)
    }
  }})
  }
  console.log(props.cloudData);
  return (
    <>
      {/*begin::public network*/}
      {props?.cloudData!=null?(<>
       <PublicNetwork 
           cloudData={props?.cloudData ?? null}
           publicData={publicData ?? null}
           handleChangeIpStatus={handleChangeIpStatus}
           addAdditionalIp={addAdditionalIp}
           deletePublicIp={deletePublicIp}
           updateRdns={updateRdns}
        />
        </>):(<><TableLoader /></>)}
      {/*end::public network*/}
      <div className="separator my-10" />
      {/*begin::private network*/}
      {privateData!=null?(<>
       <PrivateNetwork 
          privateData={privateData ?? null} 
          deletePrivateIp={deletePrivateIp}
          vpcsDisabled={vpcsDisabled}
       />
        </>):(<><TableLoader /></>)}
      {/*end::private network*/}
      <div className="separator my-10" />
      {/*begin::vpc network*/}
      {/* {vpcData!=null?(<>
       <Vpc 
           vpcData={vpcData ?? null}
           privateData={privateData ?? null} 
           addVpc={addVpc}
           vpcsDisabled={vpcsDisabled}
       />
       </>):(<><TableLoader /></>)} */}
       {/*end::vpc network*/}
      {pageStatus &&
        <TopBarHeaderLine />
      }
    </>
  )
}

const mapStateToProps = state => ({
  cloudData: state.cloud.cloudDataInfo
})

const mapDispatchToProps = dispatch => ({
  updateCloudStatus: data => dispatch(IsCloudUpdate(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Network) 