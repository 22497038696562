import { React, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import TableLoader from '../../../Loaders/TableLoader';
import TopBarHeaderLine from '../../../../../CustomHooks/TopBarHeaderLine';
import ModifyScript from '../manage/ModifyScript';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import Swal from 'sweetalert2';
import UseApi from '../../../../../CustomHooks/useApi';

export default function OwnStack(props) {
  const [privateData, setPrivateData] = useState(null);

  const openDrawer = () => {

    var drawerElement = document.querySelector("#stack-modal");
    var drawer_overlay = document.querySelector("#drawer_overlay");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
        drawerElement.style.width = "100%";
      } else {
        drawerElement.style.width = "70%";
      }
      drawer_overlay.classList.toggle("d-none");
    }
  };

  function callStack() {
    if (props && props.callData) {
      props.callData();
    }
  }
  useEffect(() => {
    if (props && props.privateData) {
      setPrivateData(props.privateData);
    }
  }, [props])

  const [scriptInfo, setScriptInfo] = useState(null);
  const [updateInfo, setUpdateInfo] = useState(null);
  const [stackName, setStackName] = useState(null);
  const updateAction = (action, data, name) => {
    console.log(action + " " + data + " " + name);
    if (action == "script") {
      setUpdateInfo(null);
      setScriptInfo(data);
      setStackName(name);
    } else if (action == "update") {
      setUpdateInfo(data);
      setScriptInfo(null);
      setStackName(name);
    }
    openDrawer();

  }

  const deleteStack = async (sid) => {
    toast.dismiss();
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Stack!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        var deploy_server = document.querySelector(`#deploy_server_${sid}`)
        const deletesshkey = document.querySelector("#delete-stack-" + sid);
        if (deletesshkey) {
          var preContent = deletesshkey.innerHTML;
          deletesshkey.classList.add("disabled");
          deploy_server.classList.add("disabled")
          deletesshkey.innerHTML =
            '<span className="indicator-label" style="padding:0.558rem 0.5rem"><span class="spinner-border spinner-border-sm align-middle ms-2" style="margin-top: 0.7rem"></span></span>';
          const dt = toast.loading("SSH Keys deleting...");
          const dnsp = await UseApi("delete", "stacks/" + sid, "");
          if (dnsp.status == "error") {
            toast.update(dt, {
              render: dnsp.message,
              type: "warning",
              isLoading: false,
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            deletesshkey.classList.remove("disabled");
          } else {
            toast.update(dt, {
              render: dnsp.message,
              type: "success",
              isLoading: false,
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setTimeout(() => {
              callStack();
              deletesshkey.classList.remove("disabled");
            }, 1500)
          }
          deploy_server.classList.remove("disabled")
          deletesshkey.innerHTML = preContent;
        }
      }
    });
  };


  return (<>
    {privateData != null ? (<>
      {privateData.length > 0 ? (<>
        <div className="mb-5 mb-xl-12">
          <div className="table-responsive">
            <table
              id="firewallTable"
              className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
            >
              <thead className="bg-secondary">
                <tr className="fw-bold ">
                  <th className="w-100px text-start ps-9">Stack</th>
                  <th className="w-250px text-center sphide">Compatible Images</th>
                  <th className="w-50px text-center">Status</th>
                  <th className="w-100px text-center">Script</th>
                  <th className="w-100px text-center">Cloud</th>
                  <th className="w-200px text-center"></th>
                </tr>
              </thead>
              <tbody>
                {privateData != null ? (<>
                  {privateData.length > 0 ? (<>
                    {privateData.map((value, index) => (<>
                      <tr>
                        <td className="text-start ps-9">
                          <div className="d-flex">
                            {" "}
                            <div className=" flex-column">
                              {" "}
                              <a className="text-dark fw-bold text-hover-primary fs-6">
                                {value?.title}
                              </a>{" "}
                              <span className="text-muted fw-bold text-muted d-block fs-7 w-94px sphide" style={{width: "94px"}}>
                                {value?.description}
                              </span>{" "}
                            </div>{" "}
                          </div>
                        </td>
                        <td className="text-center">
                          <div className=" align-items-center">
                            {" "}
                            <div className="symbol symbol-45px me-2 ms-10 d-none">
                              {" "}
                              <img src="/assets/marketplace/private.svg" style={{ width: "80%" }} />
                            </div>{" "}
                            <div className=" justify-content-center flex-column">
                              {" "}
                              <a className=" fw-bold text-gray-600  d-block fs-6">
                                {value.distro && value.distro.length > 0 ? (<>
                                  {value.distro.map((item, index2) => (<>
                                    <span className='badge badge-light-dark text-gray-600'>{item}</span>
                                  </>))}
                                </>) : (<>-</>)}
                              </a>
                            </div>{" "}
                          </div>
                        </td>
                        <td className="text-center">
                          <div className="align-items-center">
                            {" "}
                            <div className="d-flex justify-content-center flex-column">
                              {" "}
                              {value.status == "1" ? (<>
                                <div className="btn btn-sm btn-light-success fw-bold fs-6" style={{ cursor: 'default', border: '1px solid #50cd89' }}>Active</div>
                              </>) : (<>
                                <div className="btn btn-sm btn-light-warning fw-bold fs-6" style={{ cursor: 'default', border: '1px solid #ffc700' }}>Draft</div>
                              </>)}
                            </div>{" "}
                          </div>
                        </td>

                        <td className="text-center">
                            {" "}
                            <div className="d-flex justify-content-center flex-shrink-0 sphide">
                              {" "}
                              <a onClick={(e) => updateAction('script', '' + value.script + '', '' + value.title + '')} className="btn btn-light-primary btn-sm ms-5" style={{ border: '1px solid #009ef7' }}>
                              <span className=' fs-8'>
                                    View Script
                                  </span> 
                              </a>
                            </div>
                        </td>
                        <td className="text-center">
                            {" "}
                            <div className="d-flex justify-content-center flex-shrink-0 sphide">
                              {" "}
                              <a className="btn btn-primary btn-active-light-primary btn-sm ms-5" id={`deploy_server_${value.id}`}>
                                <Link to={"/cloud/deploy/" + value.id + "/private"}>
                                  {" "}
                                  <span className='text-white fs-8'>
                                    Deploy server{" "}
                                  </span>
                                  <span className="svg-icon ms-2 svg-icon-3">
                                    {" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={24}
                                      height={24}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      {" "}
                                      <rect
                                        opacity="0.5"
                                        x={18}
                                        y={13}
                                        width={13}
                                        height={2}
                                        rx={1}
                                        transform="rotate(-180 18 13)"
                                        fill="black"
                                      />{" "}
                                      <path
                                        d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                        fill="black"
                                      />{" "}
                                    </svg>{" "}
                                  </span>{" "}
                                </Link>
                              </a>
                            </div>
                        </td>
                        <td className='text-end ps-20'>
                          <div className="d-flex justify-content-center flex-shrink-0"> 
                            {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen055.svg*/}
                            <span onClick={(e) => updateAction('update', value, '' + value.title + '')} className="svg-icon svg-icon-primary svg-icon-2 me-5" style={{ cursor: "pointer" }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  opacity="0.3"
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z"
                                  fill="black"
                                />
                                <path
                                  d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z"
                                  fill="black"
                                />
                                <path
                                  d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z"
                                  fill="black"
                                />
                              </svg>
                            </span>
                            {/*end::Svg Icon*/}
                            {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen055.svg*/}
                            <span onClick={(e) => deleteStack(value.id)} id={"delete-stack-" + value.id} className="svg-icon svg-icon-danger svg-icon-2" style={{ cursor: "pointer" }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                  fill="black"
                                />
                                <path
                                  opacity="0.5"
                                  d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                  fill="black"
                                />
                                <path
                                  opacity="0.5"
                                  d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                  fill="black"
                                />
                              </svg>
                            </span>
                            {/*end::Svg Icon*/}

                          </div>{" "}
                        </td>
                      </tr >
                    </>))}
                  </>) : (<>
                    <tr>
                      <td className='text-center fw-bold fs-6' colSpan={12}>no record found</td>
                    </tr>
                  </>)}
                </>) : (<><TableLoader /></>)}

              </tbody>
            </table>
          </div>
        </div>
      </>) : (<>
        <div className="col-xl-12">
          <div className="">
            {/*begin::Card body*/}
            <div className="card-body p-0 mt-10">
              {/*begin::Illustration*/}
              <div className="text-center px-4 mt-10">
                <span className="mw-100 mh-300px">
                  <svg viewBox="0 0 24 24"
                    width="234px"
                    height="234px" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M8 10L6 12L8 14"
                        stroke="#21325B"
                        strokeWidth="1.5"
                        strokeMiterlimit={10}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />{" "}
                      <path
                        d="M16 10L18 12L16 14"
                        stroke="#21325B"
                        strokeWidth="1.5"
                        strokeMiterlimit={10}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />{" "}
                      <path
                        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                        stroke="#21325B"
                        strokeWidth="1.5"
                        strokeMiterlimit={10}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />{" "}
                      <path
                        d="M13 9.66992L11 14.33"
                        stroke="#21325B"
                        strokeWidth="1.5"
                        strokeMiterlimit={10}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />{" "}
                    </g>
                  </svg>

                </span>
              </div>
              {/*end::Illustration*/}
              {/*begin::Wrapper*/}
              <div className="card-px text-center py-5 mb-20">
                {/*begin::Title*/}
                <h1 className="fs-2x fw-bolder mb-5">Stacks</h1>
                <h2 className="fs-1x fw-bolder mb-5 text-muted">
                  Build your own stack with Utho
                </h2>
                {/*end::Title*/}
                {/*begin::Description*/}

                {/*end::Description*/}
                {/*begin::Action*/}
                <Link to="/stacks/deploy">
                  <a className="btn btn-primary mb-10">
                    Create Stack
                  </a>
                </Link>
                {/*end::Action*/}
              </div>
              {/*end::Wrapper*/}
            </div>
            {/*end::Card body*/}
          </div>
        </div>



      </>)}
    </>) : (<>
      <TableLoader />
      <TopBarHeaderLine />
    </>)
    }

    <div id="drawer_overlay" onClick={openDrawer} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>

    <div
      id="stack-modal"
      className="bg-body shadow drawer drawer-end"
      data-kt-drawer-close="#close_ssh"
      style={{ important: true, zIndex: 1051 }}
    >
      {/*begin::Messenger*/}
      <div
        className="card rounded-0 w-100 border-0"
        id="kt_drawer_chat_messenger"
      >
        {/*begin::Card header*/}
        <div
          className="card-header pe-5 ps-7"
          id="kt_drawer_chat_messenger_header"
        >
          {/*begin::Title*/}
          <div className="card-title">
            {/*begin::User*/}
            <div className="d-flex justify-content-center flex-column me-3">
              <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                {stackName != null ? (stackName) : ('')}
              </a>
            </div>
            {/*end::User*/}
          </div>
          {/*end::Title*/}
          {/*begin::Card toolbar*/}
          <div className="card-toolbar">
            {/*begin::Close*/}
            <div
              onClick={openDrawer}
              className="btn btn-sm btn-icon btn-active-light-primary"
              id="close_ssh"
            >
              {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
              <span className="svg-icon svg-icon-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x={6}
                    y="17.3137"
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(-45 6 17.3137)"
                    fill="black"
                  />
                  <rect
                    x="7.41422"
                    y={6}
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(45 7.41422 6)"
                    fill="black"
                  />
                </svg>
              </span>
              {/*end::Svg Icon*/}
            </div>
            {/*end::Close*/}
          </div>
          {/*end::Card toolbar*/}
        </div>
        {/*end::Card header*/}
        {/*begin::Card body*/}
        <div className="" id="">
          {/*begin::Form*/}
          <form
            name="importkey"
            id="importkey"
            className="form"
          >
            {/*begin::Modal body*/}
            <div className="modal-body ">
              {/*begin::Scroll*/}
              {/* <div
                                className="scroll-y me-n7 pe-7"
                                id="kt_modal_create_api_key_scroll"
                                data-kt-scroll="true"
                                data-kt-scroll-activate="{default: false, lg: true}"
                                data-kt-scroll-max-height="auto"
                                data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                                data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                                data-kt-scroll-offset="300px"
                              > */}
              {scriptInfo != null ? (<>
                <div className="highlight">
                  <div className="highlight-code">
                    <pre className=" language-html" tabIndex={0}>
                      <code className=" language-html">

                        <span className="token tag">
                          <span className="token tag">
                            <span className="token punctuation">{scriptInfo}</span>
                          </span>
                        </span>

                      </code>
                    </pre>
                  </div>
                </div>
              </>) : (<></>)}

              {updateInfo != null ? (<>
                <ModifyScript sdata={updateInfo} closeDrawer={openDrawer} callInfo={callStack} />
              </>) : (<></>)}
              {/* </div> */}
              {/*end::Scroll*/}
            </div>
            {/*end::Modal body*/}

          </form>
          {/*end::Form*/}
        </div>
        {/*end::Card body*/}
      </div>
      {/*end::Messenger*/}
    </div>
  </>);
}