import { React, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import TableLoader from '../../../Loaders/TableLoader';

export default function CommunityStack(props) {

  const [publicData, setPublicData] = useState(null);

  useEffect(() => {
    if (props && props.publicData) {
      setPublicData(props.publicData);
    }
  }, [props])

  const [scriptInfo, setScriptInfo] = useState(null);
  const [stackName, setStackName] = useState(null);
  const updateAction = (action, data, name) => {
    console.log(action + " " + data + " " + name);
    setScriptInfo(null);
    if (action == "script") {
      setScriptInfo(data);
      setStackName(name);
    }
    openDrawer();

  }

  const openDrawer = () => {

    var drawerElement = document.querySelector("#com-modal");
    var drawer_overlay = document.querySelector("#drawer_overlay_com");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
        drawerElement.style.width = "100%";
      } else {
        drawerElement.style.width = "70%";
      }
      drawer_overlay.classList.toggle("d-none");
    }
  };
  return (<>
    <div className="mb-5 mb-xl-12">

      <div className="table-responsive">
        <table
          id="firewallTable"
          className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
          >
          <thead className="bg-secondary">
            <tr className="fw-bold ">
              <th className="w-100px ps-9" >Stack</th>
              <th className="w-200px text-start ps-20">Compatible Images</th>
              <th className="w-20px text-center">Status</th>
              <th className="w-20px text-center">Script</th>
              <th className="w-100px text-center"></th>
            </tr>
          </thead>
          <tbody>
            {publicData != null ? (<>
              {publicData.length > 0 ? (<>
                {publicData.map((value, index) => (<>
                  <tr>
                    <td className="text-start ps-9">
                      <div className="d-flex">
                        {" "}
                        <div className=" flex-column">
                          {" "}
                          <a className="text-dark fw-bold text-hover-primary fs-6">
                            {value?.title}
                          </a>{" "}
                          <span className="text-muted fw-bold text-muted d-block fs-7">
                            {value?.description}
                          </span>{" "}
                        </div>{" "}
                      </div>

                    </td>
                    <td className="text-center">
                          <div className=" align-items-center">
                            {" "}
                            <div className="symbol symbol-45px me-2 ms-10 d-none">
                              {" "}
                              <img src="/assets/marketplace/private.svg" style={{ width: "80%" }} />
                            </div>{" "}
                            <div className=" justify-content-center flex-column">
                              {" "}
                              <a className=" fw-bold text-gray-600  d-block fs-6">
                                {value.distro && value.distro.length > 0 ? (<>
                                  {value.distro.map((item, index2) => (<>
                                    <span className='badge badge-light-dark text-gray-600 me-2'>{item}</span>
                                  </>))}
                                </>) : (<>-</>)}
                              </a>
                            </div>{" "}
                          </div>
                        </td>
                    <td className="text-center">
                      <div className="align-items-center">
                        {" "}
                        <div className="d-flex justify-content-center flex-column">
                          {" "}
                          {value.status == "1" ? (<>
                            <div className="btn btn-sm btn-success fw-bold fs-6" style={{cursor:'default'}}>Active</div>
                          </>) : (<>
                            <div className="btn btn-sm btn-warning fw-bold fs-6" style={{cursor:'default'}}>Inactive</div>
                          </>)}
                        </div>{" "}
                      </div>
                    </td>
                    <td className="text-center">
                      <span onClick={(e) => updateAction('script', '' + value.script + '', '' + value.title + '')} className="btn btn-light-primary btn-sm ms-5" style={{ border: '1px solid #009ef7' }}>
                        View Script
                      </span>
                    </td>
                    <td className="text-center sphide">
                      <div className="d-flex justify-content-center flex-shrink-0">
                        {" "}
                        <Link to={"/cloud/deploy/" + value.id + "/public"}>
                          <a className="btn btn-primary btn-active-light-primary btn-sm me-1 me-5 ">
                            {" "}
                            Deploy server{" "}
                            <span className="svg-icon ms-2 svg-icon-3">
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                {" "}
                                <rect
                                  opacity="0.5"
                                  x={18}
                                  y={13}
                                  width={13}
                                  height={2}
                                  rx={1}
                                  transform="rotate(-180 18 13)"
                                  fill="black"
                                />{" "}
                                <path
                                  d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                  fill="black"
                                />{" "}
                              </svg>{" "}
                            </span>{" "}
                          </a>
                        </Link>
                      </div>{" "}
                    </td>
                  </tr>
                </>))}
              </>) : (<>
                <tr>
                  <td className='text-center fw-bold fs-6' colSpan={12}>no record found</td>
                </tr>
              </>)}
            </>) : (<><TableLoader /></>)}

          </tbody>
        </table>
      </div>
    </div>

    <div id="drawer_overlay_com" onClick={openDrawer} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>

    <div
      id="com-modal"
      className="bg-body shadow drawer drawer-end"
      data-kt-drawer-close="#close_ssh"
      style={{ important: true, zIndex: 1051 }}
    >
      {/*begin::Messenger*/}
      <div
        className="card rounded-0 w-100 border-0"
        id="kt_drawer_chat_messenger"
      >
        {/*begin::Card header*/}
        <div
          className="card-header ps-7"
          id="kt_drawer_chat_messenger_header"
        >
          {/*begin::Title*/}
          <div className="card-title">
            {/*begin::User*/}
            <div className="d-flex justify-content-center flex-column me-3">
              <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                {stackName != null ? (stackName) : ('')}
              </a>
            </div>
            {/*end::User*/}
          </div>
          {/*end::Title*/}
          {/*begin::Card toolbar*/}
          <div className="card-toolbar">
            {/*begin::Close*/}
            <div
              onClick={openDrawer}
              className="btn btn-sm btn-icon btn-active-light-primary"
              id="close_ssh"
            >
              {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
              <span className="svg-icon svg-icon-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x={6}
                    y="17.3137"
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(-45 6 17.3137)"
                    fill="black"
                  />
                  <rect
                    x="7.41422"
                    y={6}
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(45 7.41422 6)"
                    fill="black"
                  />
                </svg>
              </span>
              {/*end::Svg Icon*/}
            </div>
            {/*end::Close*/}
          </div>
          {/*end::Card toolbar*/}
        </div>
        {/*end::Card header*/}
        {/*begin::Card body*/}
        <div className="" id="">
          {/*begin::Form*/}
          <form
            name="importkey"
            id="importkey"
            className="form"
          >
            {/*begin::Modal body*/}
            <div className="modal-body ">
              {/*begin::Scroll*/}
              {/* <div
                                className="scroll-y me-n7 pe-7"
                                id="kt_modal_create_api_key_scroll"
                                data-kt-scroll="true"
                                data-kt-scroll-activate="{default: false, lg: true}"
                                data-kt-scroll-max-height="auto"
                                data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                                data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                                data-kt-scroll-offset="300px"
                              > */}
              {scriptInfo != null ? (<>
                <div className="highlight">
                  <div className="highlight-code">
                    <pre className=" language-html" tabIndex={0}>
                      <code className=" language-html">

                        <span className="token tag">
                          <span className="token tag">
                            <span className="token punctuation">{scriptInfo}</span>
                          </span>
                        </span>

                      </code>
                    </pre>
                  </div>
                </div>
              </>) : (<></>)}


              {/* </div> */}
              {/*end::Scroll*/}
            </div>
            {/*end::Modal body*/}

          </form>
          {/*end::Form*/}
        </div>
        {/*end::Card body*/}
      </div>
      {/*end::Messenger*/}
    </div>
  </>)
}