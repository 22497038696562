// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.getfirewall-wrapper {
    position: relative;
    height: 230px; 
    overflow: unset; 
}

.getfirewall { 
    height: 100%;
    overflow-y: scroll; 
    box-sizing: border-box;
}

.getfirewall::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;  
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    pointer-events: none;  
}
 `, "",{"version":3,"sources":["webpack://./src/App/MicroApp/Body/utils/firewall/custom.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,QAAQ;IACR,YAAY;IACZ,sFAAsF;IACtF,oBAAoB;AACxB","sourcesContent":[".getfirewall-wrapper {\n    position: relative;\n    height: 230px; \n    overflow: unset; \n}\n\n.getfirewall { \n    height: 100%;\n    overflow-y: scroll; \n    box-sizing: border-box;\n}\n\n.getfirewall::after {\n    content: '';\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    height: 50px;  \n    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));\n    pointer-events: none;  \n}\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
