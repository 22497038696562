import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom/dist";
import UsePost from "../../../../../../CustomHooks/usePost";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UseApi from "../../../../../../CustomHooks/useApi";
import { connect } from "react-redux";
import "../../custom.css";
import { AddDatabaseReplica } from "../../../../../../Redux/Services/Action/actions";
import TopBarHeaderLine from "../../../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../../../Loaders/TableLoader";
import { Tooltip } from "@material-ui/core";
import PricingContainerWithoutBillingCycle from "../../../pricing/PricingContainerWithoutBillingCycle";
import { publicIp, publicIpv4, publicIpv6 } from 'public-ip';
import Dczone from "../../../../utils/dczone/Dczone";
var serialize = require("form-serialize");
function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}

const DeployReplica = (props) => {
  const forceUpdate = useForceUpdate();
  const [adcdata, setAdcdata] = useState([]);
  const [ddcdata, setDdcdata] = useState([]);
  const [location, setLocation] = useState("innoida")
  const dataFetchedRef2 = useRef();
  const [planData, setPlanData] = useState([]);
  const [userData, setUserData] = useState(null);
  const user_data = useRef(null);
  const dczoneStatus = useRef(true);

  const navigate = useNavigate();
  const navRedirect = () => {
    setTimeout(() => {
      navigate('/database-cluster');
    }, 2000);
  }

  var query = window.location.href;
  var vars = query.split("/");
  var clusterid = vars[4];

  const getDczone = async () => {
    const data = await UseApi("get", "dczones", "");
    user_data.current = await UseApi("get", "account/info", "");
    const PlanData = await UseApi("get", "plans?type=db_rdbms", "");
    if (PlanData) {
      setPlanData((prePlanData) => [...PlanData.plans]);
    }
    setUserData(user_data.current.user);
    if (Object.keys(data).length === 0) {
      setAdcdata([]);
      setDdcdata([]);
    } else {
      if (dczoneStatus.current) {
        data.dczones.map(async (value, index) => {
          if (value.status !== "active") {
                var server_ip = await publicIpv4();
                if (server_ip) {
                  if (
                    server_ip !== "103.209.144.51" ||
                    server_ip !== "103.209.144.66" ||
                    server_ip !== "103.209.145.208" ||
                    server_ip !== "103.209.144.81" ||
                    server_ip !== "103.209.144.82" ||
                    server_ip !== "103.209.144.83" ||
                    server_ip !== "103.127.30.91" ||
                    server_ip !== "103.209.144.60" ||
                    server_ip !== "103.127.30.239" ||
                    server_ip !== "103.127.28.52"
                  ) {
                    ddcdata.push(value);
                  }
                  forceUpdate();
                }
          } else {
            adcdata.push(value);
            forceUpdate();
          }
        });
        dczoneStatus.current = false;
      }
      setTimeout(() => {
        var clickTo = document.querySelector("#dcslugl_innoida");
        if (clickTo) {
          clickTo.click();
        }
      }, 1000)
    }
  };

  useEffect(() => {
    if (dataFetchedRef2.current) return;
    dataFetchedRef2.current = true;
    getDczone();
  }, []);

  const selectDc = async (slug) => {
    var dczone_slug = document.querySelector("#dczone_slug");
    if (dczone_slug) {
      dczone_slug.value = slug;
    }
    if (props) {
      if (props.deployInfo[0]) {
        if (props.deployInfo[0].dcslug != slug) {
          const updatedObject = { ...props.deployInfo[0], dcslug: slug };
          const newArray = [updatedObject];
          props.updateDeployInfo(newArray);
          console.log(newArray);
        }
      }
    }
    setLocation(slug)
    var dcSelect = document.querySelectorAll([".tickMarkDc"]);
    var currentDc = document.querySelector("#tickMarkDc-" + slug);
    if (dcSelect) {
      dcSelect.forEach((clist) => {
        clist.classList.add("d-none");
      });
    }
    if (currentDc) {
      currentDc.classList.remove("d-none");
    }
  };
  const makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
  // end- select plans
  function ChangeScreen() {
    useEffect(() => {
      function handleResize() {
        const sideCheckout = document.querySelector("#sideCheckout");
        if (sideCheckout) {
          if (window.innerWidth > 990) {
            sideCheckout.classList.add("mhc_checkout");
          } else {
            sideCheckout.classList.remove("mhc_checkout");
          }
        }
      }
      handleResize();
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
    return null;
  }

  useEffect(() => {
    updateDeploy();
    getUserInfo();
  }, [props])

  const getUserInfo = async () => {
    toast.dismiss();
    user_data.current = await UseApi("get", "account/info", "");
    setUserData(user_data.current.user);

  };

  const addReplicaCluster = async () => {
    var submitBtn = document.querySelector("#database-btn");
    if (submitBtn) {
      const readOnlyToastBar = toast.loading("Please wait...");
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      const responseData = await UsePost('post', 'databases/' + clusterid + '/readonly', 'databaseDeploy');
      if (responseData) {
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
        if (responseData.status === 'error') {
          toast.update(readOnlyToastBar, {
            render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        } else if (responseData.status === 'success') {
          toast.update(readOnlyToastBar, {
            render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          navRedirect();
        }
      }

    }
  }
  function updateDeploy() {
    if (props && props.deployInfo[0]) {
      if (props.deployInfo[0].cluster_label != "") {
        var db_label = document.querySelector("#db_label");
        if (db_label) {
          db_label.value = props.deployInfo[0].cluster_label;
        }
      }
      if (props.deployInfo[0].dcslug != "") {
        var dczone_slug = document.querySelector("#dczone_slug");
        if (dczone_slug) {
          dczone_slug.value = props.deployInfo[0].dcslug;
          selectDc(props.deployInfo[0].dcslug);
        }
      }
    }
    // calcFinalCost();
  }

  useEffect(() => {
    if (props) {
      updateDeploy();
    }
  }, [props])


  // function calcFinalCost() {
  //   var current_plan_price = document.querySelector("#plan_cost");
  //   var finalCost = 0;
  //   if (current_plan_price) {
  //     finalCost = parseInt(current_plan_price.value);

  //   }
  //   var currency_final = document.querySelector("#currency_final");
  //   var total_cost = document.querySelector("#total_cost");
  //   var billing_final = document.querySelector("#billing_final");

  //   if (currency_final && total_cost && billing_final) {
  //     if (user_data && user_data.current && user_data.current.user) {
  //       currency_final.innerHTML = user_data.current.user.currencyprefix;
  //     }
  //     total_cost.innerHTML = finalCost;
  //     billing_final.innerHTML = " /mon";

  //   }
  // }


  function updatedPlanInfo(plan = []) {
    const plan_value = document.querySelector("#size_value");
    const plan_cost = document.querySelector("#plan_cost");

    if (plan_value && plan_cost) {
      plan_value.value = plan.planid;
      plan_cost.value = plan.payable_price;

    }
    if (props.deployInfo) {
      props.deployInfo[0].plan_id = plan.planid;
      props.deployInfo[0].price = plan.payable_price;
    }
    // calcFinalCost();
  }
  const openDrawer = () => {

    var drawerElement = document.querySelector("#replica-modal");
    var drawer_overlay = document.querySelector("#drawer_overlay");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
        drawerElement.style.width = "100%";
      } else {
        drawerElement.style.width = "70%";
      }
      drawer_overlay.classList.toggle("d-none");
    }
  };

  const [databaseInfo, setDatabaseInfo] = useState(null);

  const getReplica = async () => {
    if (props) {
      if (props.data && props.data) {
        setDatabaseInfo(props.data);
      }
    }
  }
  useEffect(() => {
    getReplica();
  }, [props])

  return (
    <>
      {databaseInfo?.nodes?.readonly?.length >= 2 ?
        <Tooltip title="You have reached to maximum replica nodes" arrow>
          <span>
            <a
              onClick={openDrawer}
              className="btn btn-sm btn-primary btn-active-light-primary"
            >
              {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
              <span className="svg-icon svg-icon-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="11.364"
                    y="20.364"
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(-90 11.364 20.364)"
                    fill="black"
                  />
                  <rect
                    x="4.36396"
                    y="11.364"
                    width={16}
                    height={2}
                    rx={1}
                    fill="black"
                  />
                </svg>
              </span>
              {/*end::Svg Icon*/}Add Read-Only Node
            </a>
          </span>
        </Tooltip>
        :
        <a
          onClick={openDrawer}
          className="btn btn-sm btn-primary btn-active-light-primary"
        >
          {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
          <span className="svg-icon svg-icon-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x="11.364"
                y="20.364"
                width={16}
                height={2}
                rx={1}
                transform="rotate(-90 11.364 20.364)"
                fill="black"
              />
              <rect
                x="4.36396"
                y="11.364"
                width={16}
                height={2}
                rx={1}
                fill="black"
              />
            </svg>
          </span>
          {/*end::Svg Icon*/}Add Read-Only Node
        </a>
      }
      <div id="drawer_overlay" onClick={openDrawer} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>

      <div
        id="replica-modal"
        className="bg-body shadow drawer drawer-end"
        data-kt-drawer="true"
        data-kt-drawer-name="chat"
        data-kt-drawer-activate="true"
        data-kt-drawer-direction="end"
        // data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
        style={{ important: true, zIndex: 110 }}
      >
        {/*begin::Messenger*/}
        <div
          className="card w-100 rounded-0 border-0"
          id="kt_drawer_chat_messenger"
        >
          {/*begin::Card header*/}
          <div
            className="card-header pe-5"
            id="kt_drawer_chat_messenger_header"
          >
            {/*begin::Title*/}
            <div className="card-title">
              {/*begin::User*/}
              <div className="d-flex justify-content-center flex-column me-3">
                <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                  Create Replica Node
                </a>
              </div>
              {/*end::User*/}
            </div>
            {/*end::Title*/}
            {/*begin::Card toolbar*/}
            <div className="card-toolbar">
              {/*begin::Close*/}
              <div
                onClick={openDrawer}
                className="btn btn-sm btn-icon btn-active-light-primary"
                id="close_api"
              >
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                <span className="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x={6}
                      y="17.3137"
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(-45 6 17.3137)"
                      fill="black"
                    />
                    <rect
                      x="7.41422"
                      y={6}
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(45 7.41422 6)"
                      fill="black"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
              </div>
              {/*end::Close*/}
            </div>
            {/*end::Card toolbar*/}
          </div>
          {/*end::Card header*/}
          {/*begin::Card body*/}
          <div className="" id="">
            {/*begin::Form*/}
            <form name="generateapi" id="generateapi" className="form">
              {/*begin::Modal body*/}
              <div className="modal-body bg-light-dark">
                {/*begin::Scroll*/}
                <div
                  className="scroll-y me-n7 pe-7"
                  id="kt_modal_create_api_key_scroll"
                  data-kt-scroll="true"
                  data-kt-scroll-activate="{default: false, lg: true}"
                  data-kt-scroll-max-height="auto"
                  data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                  data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                  data-kt-scroll-offset="300px"
                >
                  {props && adcdata.length > 0 ? (<>
                    <ChangeScreen />
                    <form id="databaseDeploy">
                      {/*begin::Tables Widget 9*/}
                      <input
                        type="hidden"
                        name=""
                        id="dczone_slug"
                        className="form-control text-center"
                        defaultValue={props?.deployInfo[0]?.dcslug}
                      />
                      <div className=" col-xl-12 card mb-5">
                        {/*begin::data center*/}
                        <Dczone adcdata={adcdata} ddcdata={ddcdata} selectDc={selectDc} location={location}/>
                        {/* End data center */}
                      </div>

                      {/*begin::Col*/}
                      <input
                        type="hidden"
                        name="size"
                        id="size_value"
                        defaultValue={
                          props.deployInfo && props.deployInfo
                            ? props.deployInfo[0].plan_id
                            : ""
                        }
                      />
                      <input
                        type="hidden"
                        id="plan_cost"
                        defaultValue={
                          props.deployInfo && props.deployInfo
                            ? props.deployInfo[0].price
                            : ""
                        }
                      />
                      {userData != null && planData.length > 0 ? (<>
                        {props.deployInfo[0] ? (<>

                          {/* <PricingContainerWithoutBillingCycle updatePlan={updatedPlanInfo} currBilling={props.deployInfo[0].billingcycle} currPlanid={props.deployInfo[0].plan_id} user={userData} plans={planData} /> */}
                        </>) : ('')}
                      </>) : (<>

                      </>)}
                      {/*end::Col*/}

                      {databaseInfo && databaseInfo.vpc ? (<>
                        {/*begin::Col*/}
                        <div className="col-xl-12 card mb-5">
                          {/* begin::List widget 10*/}
                          <div className="col-xl-12 card mb-5">
                            {/*begin::List widget 10*/}
                            <div className=" h-lg-100 mb-5" id="vpc_section">
                              {/*begin::Header*/}
                              <div className="card-header border-3 mb-5 pt-2">
                                {/*begin::Title*/}
                                <h3 className="card-title align-items-start flex-column">
                                  <span className="card-label fw-bolder text-gray-800">
                                    VPC Network
                                  </span>
                                  <span className="text-gray-400 mt-1 fw-bold fs-6">
                                    VPC for the Cluster
                                  </span>
                                </h3>
                                {/*end::Title*/}
                              </div>
                              <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">
                                {/*begin::Icon*/}
                                {/*begin::Svg Icon | path: icons/duotune/general/gen044.svg*/}
                                <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <rect
                                      opacity="0.3"
                                      x={2}
                                      y={2}
                                      width={20}
                                      height={20}
                                      rx={10}
                                      fill="black"
                                    />
                                    <rect
                                      x={11}
                                      y={14}
                                      width={7}
                                      height={2}
                                      rx={1}
                                      transform="rotate(-90 11 14)"
                                      fill="black"
                                    />
                                    <rect
                                      x={11}
                                      y={17}
                                      width={2}
                                      height={2}
                                      rx={1}
                                      transform="rotate(-90 11 17)"
                                      fill="black"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}
                                {/*end::Icon*/}
                                {/*begin::Wrapper*/}
                                <div className="d-flex flex-stack flex-grow-1">
                                  {/*begin::Content*/}
                                  <div className="fw-bold">
                                    <h4 className="text-gray-900 fw-bolder"> VPC - <a className="fw-bolder">
                                      {databaseInfo?.vpc}
                                    </a></h4>
                                    <div className="fs-6 text-gray-700">
                                      All resources created in this datacenter will be members of the same VPC network. <br /> They can communicate securely over their Private IP addresses.
                                    </div>
                                  </div>
                                  {/*end::Content*/}
                                </div>
                                {/*end::Wrapper*/}
                              </div>

                            </div>
                          </div>

                          {/*end::List widget 10 */}
                        </div>
                        {/*end::Col*/}
                      </>) : ('')}

                      <div className="col-xl-12 card mb-5">
                        <div className="card-header border-3 mb-5 pt-2">
                          <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bolder text-gray-800">
                              Name Your Read Only Replica Node
                            </span>
                            <span className="text-gray-400 mt-1 fw-bold fs-6">
                              Input the name for your replica node
                            </span>
                          </h3>
                        </div>
                        <div className="mx-5 mb-3">
                          <div
                            className="row g-9"
                            data-kt-buttons="true"
                            data-kt-buttons-target="[data-kt-button]"
                          >
                            <div className="col-md-4 col-lg-4 col-xxl-4">
                              <div className="">
                                <div className="input-group mb-5">
                                  <input
                                    type="text"
                                    name="cluster_label"
                                    id="db_label"
                                    className="form-control"
                                    placeholder="cluster label"
                                    defaultValue={"utho-" + makeid(8) + "-cluster"}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xl-12 card mb-5">
                        <div className="card-header  mb-5 pt-2" style={{ border: "none" }}>
                          <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold fs-3 mb-1">
                              {/* Total Cost */}
                            </span>
                            <span className="fs-xl-2x fs-2 fw-boldest">
                              <span id="currency_final1"></span>
                              <span id="total_cost1"></span>
                              <span id="billing_final1"></span> </span>
                            <span className="text-muted mt-1 fw-bold fs-7" />
                          </h3>
                          <div className="card-toolbar">
                            <a style={{ width: "100%" }}
                              className="btn  btn-primary btn-active-light-primary" id="database-btn" onClick={addReplicaCluster}>
                              Create Read Only Replica Node &nbsp; &nbsp;
                              <span className="svg-icon svg-icon-3">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z"
                                    fill="black"
                                  />
                                  <path
                                    opacity="0.3"
                                    d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z"
                                    fill="black"
                                  />
                                </svg>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>

                      {/*end::Tables Widget 9*/}
                    </form>

                  </>) : (<><TopBarHeaderLine /><TableLoader /></>)}
                </div>
              </div>
            </form>
            {/*end::Form*/}
          </div>
          {/*end::Card body*/}
        </div>

        {/*end::Messenger*/}
      </div>

    </>
  );
};


const mapStateToProps = (state) => ({
  deployInfo: state.databaseClusterCount.deployReplica,
});

const mapDispatchToProps = (dispatch) => ({
  updateDeployInfo: (data) => dispatch(AddDatabaseReplica(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeployReplica); 
