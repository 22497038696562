import React, { useEffect, useState } from 'react';
import UseApi from '../../../../../CustomHooks/useApi';
import AddingFundBtns from '../../billing/billing-onboard/AddFund/AddingFundBtns';
import AddingCardBtns from '../../billing/billing-onboard/AddCard/AddingCardBtns';
import ApplyCoupon from '../../../../../Auth/ApplyCoupon';

const BillingVerification = () => {
  const [logo_dark, setLogo_dark] = useState(null);

  async function CallAppInfo() {
    const app_info = await UseApi('get', '/appinfo', '');
    if (app_info) {
      ////console.log("app info =>");
      ////console.log(app_info);
      var siteUrlString = app_info.site;

      var siteUrlArr = siteUrlString.split(".");
      var siteLength = siteUrlArr.length;
      var siteOrg = "https://api." + siteUrlArr[siteLength - 2] + "." + siteUrlArr[siteLength - 1];
      var whiteLogo = siteOrg + "/publicfiles/" + app_info.logo;
      setLogo_dark(whiteLogo);
    }
  }

  useEffect(() => {
    CallAppInfo();
  }, [])

  const [selectedValue, setSelectedValue] = useState('fund'); // Set the default selected value

  const handleRadioChange = (event) => {
    // Update the selected value when a radio button is clicked
    setSelectedValue(event.target.value);
  };

  // function toggleBilling(){
  //       var billingSec=document.querySelector("#billingSec");
  //       var mainDiv=document.querySelector("#mainDiv");
  //       var toggleBtn=document.querySelector("#toggleBtn"); 

  //       if(billingSec && mainDiv && toggleBtn){
  //         billingSec.classList.toggle("current");
  //         mainDiv.classList.toggle("current");
  //         toggleBtn.classList.toggle("d-none");
  //       }
  // }


  return (
    <>
      <ApplyCoupon />
      {/*begin::Authentication - Multi-steps*/}
      <div
        className="d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column bg-white"
        id="kt_create_account_stepper"
      >

        {/*begin::Body*/}
        <div className="d-flex flex-column flex-lg-row-fluid py-2">
          {/*begin::Content*/}
          <div className="d-flex flex-center flex-column flex-column-fluid">
            {/*begin::Wrapper*/}
            <div className=" p-10 mx-auto">
              {/*begin::Logo*/}
              <a className="mb-4">
                {logo_dark != null ? (<>
                  <img
                    alt="Logo"
                    src={logo_dark}
                    className="h-70px logo mb-10"
                  />
                </>) : ('')}
              </a>
              {/*end::Logo*/}
              {/*begin::Form*/}
              <form
                className="my-auto pb-5"
                noValidate="novalidate"
                id="kt_create_account_form"
              >
                {/*begin::Step 1*/}
                <div className="current" id="mainDiv" data-kt-stepper-element="content">
                  {/*begin::Wrapper*/}
                  <div className="w-100">
                    {/*begin::Heading*/}
                    <div className="pb-10 pb-lg-15">
                      {/*begin::Title*/}
                      <h2 className="fw-bolder d-flex align-items-center text-dark">
                        Account Verification
                      </h2>
                      {/*end::Title*/}
                      {/*begin::Notice*/}
                      <div className="text-muted fw-bold fs-6">
                        To ensure the security and quality of our services, we need to verify your identity.<br /> Here are two simple ways to do it.
                        .
                      </div>
                      {/*end::Notice*/}
                    </div>
                    {/*end::Heading*/}
                    {/*begin::Input group*/}
                    <div className="fv-row">
                      {/*begin::Row*/}
                      <div className="row">
                        {/*begin::Col*/}
                        <div className="col-lg-6">
                          {/*begin::Option*/}
                          <input
                            type="radio"
                            className="btn-check"
                            name="verification_type"
                            defaultValue="fund"
                            checked={selectedValue === 'fund'}
                            onChange={handleRadioChange}
                            id="kt_create_account_form_account_type_personal"
                          />
                          <label
                            className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10"
                            htmlFor="kt_create_account_form_account_type_personal"
                          >
                            {/*begin::Svg Icon | path: icons/duotune/communication/com005.svg*/}
                            <span className="svg-icon svg-icon-3x me-5">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  opacity="0.3"
                                  d="M3.20001 5.91897L16.9 3.01895C17.4 2.91895 18 3.219 18.1 3.819L19.2 9.01895L3.20001 5.91897Z"
                                  fill="black"
                                />
                                <path
                                  opacity="0.3"
                                  d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21C21.6 10.9189 22 11.3189 22 11.9189V15.9189C22 16.5189 21.6 16.9189 21 16.9189H16C14.3 16.9189 13 15.6189 13 13.9189ZM16 12.4189C15.2 12.4189 14.5 13.1189 14.5 13.9189C14.5 14.7189 15.2 15.4189 16 15.4189C16.8 15.4189 17.5 14.7189 17.5 13.9189C17.5 13.1189 16.8 12.4189 16 12.4189Z"
                                  fill="black"
                                />
                                <path
                                  d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21V7.91895C21 6.81895 20.1 5.91895 19 5.91895H3C2.4 5.91895 2 6.31895 2 6.91895V20.9189C2 21.5189 2.4 21.9189 3 21.9189H19C20.1 21.9189 21 21.0189 21 19.9189V16.9189H16C14.3 16.9189 13 15.6189 13 13.9189Z"
                                  fill="black"
                                />
                              </svg>
                            </span>
                            {/*end::Svg Icon*/}
                            {/*begin::Info*/}
                            <span className="d-block fw-bold text-start">
                              <span className="text-dark fw-bolder d-block fs-4 mb-2">
                                Small Payment Verification
                              </span>
                              <span className="text-muted fw-bold fs-6">
                                Make a small payment via multiple payment methods, the amount of the payment will be refundable to verify your identity.
                              </span>
                            </span>
                            {/*end::Info*/}
                          </label>
                          {/*end::Option*/}
                        </div>
                        {/*end::Col*/}
                        {/*begin::Col*/}
                        <div className="col-lg-6">
                          {/*begin::Option*/}
                          <input
                            type="radio"
                            className="btn-check"
                            name="verification_type"
                            defaultValue="card"
                            checked={selectedValue === 'card'}
                            onChange={handleRadioChange}
                            id="kt_create_account_form_account_type_corporate"
                          />
                          <label
                            className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center"
                            htmlFor="kt_create_account_form_account_type_corporate"
                          >
                            {/*begin::Svg Icon | path: icons/duotune/finance/fin006.svg*/}
                            <span className="svg-icon svg-icon-3x me-5">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path d="M22 7H2V11H22V7Z" fill="black" />
                                <path
                                  opacity="0.3"
                                  d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19ZM14 14C14 13.4 13.6 13 13 13H5C4.4 13 4 13.4 4 14C4 14.6 4.4 15 5 15H13C13.6 15 14 14.6 14 14ZM16 15.5C16 16.3 16.7 17 17.5 17H18.5C19.3 17 20 16.3 20 15.5C20 14.7 19.3 14 18.5 14H17.5C16.7 14 16 14.7 16 15.5Z"
                                  fill="black"
                                />
                              </svg>

                            </span>
                            {/*end::Svg Icon*/}
                            {/*begin::Info*/}
                            <span className="d-block fw-bold text-start">
                              <span className="text-dark fw-bolder d-block fs-4 mb-2">
                                Add a Credit/Debit Card
                              </span>
                              <span className="text-muted fw-bold fs-6">
                                Add your Credit or Debit Card. we'll only authorize a negligible amount and won't charge you without your permission.
                              </span>
                            </span>
                            {/*end::Info*/}
                          </label>
                          {/*end::Option*/}
                        </div>
                        {/*end::Col*/}
                      </div>
                      {/*end::Row*/}
                    </div>
                    {/*end::Input group*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Step 1*/}
                {/*begin::Actions*/}
                <div className="d-flex flex-stack pt-15" id="toggleBtn">
                  <div className="mr-2"></div>
                  <div>
                    {selectedValue == "fund" ? (<><AddingFundBtns /></>) : (<><AddingCardBtns /></>)}
                  </div>
                </div>
                {/*end::Actions*/}
              </form>
              {/*end::Form*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Body*/}
        {/*begin::Aside*/}
        <div className="d-flex flex-column flex-lg-row-auto w-xl-500px bg-lighten shadow-sm mt-3">
          {/*begin::Wrapper*/}
          <div className="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-500px scroll-y">
            {/*begin::Header*/}
            <div className="mt-1 flex-row-fluid flex-column flex-center p-10">
              <div className=''>
                <h2 className="fs-2 fw-boldest mb-0 ms-4 pb-10 pt-20">Join our 1,79,000+ satisfied Utho users!</h2>
              </div>
              {/*begin::Accordion*/}
              <div className="accordion accordion-icon-toggle" id="kt_accordion_2">
                {/*begin::Item*/}
                <div className="">
                  {/*begin::Header*/}
                  <div
                    className="accordion-header py-3 d-flex"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_accordion_2_item_1"
                  >
                    <span className="accordion-icon">
                      <span className="svg-icon svg-icon-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <rect
                            opacity="0.5"
                            x={18}
                            y={13}
                            width={13}
                            height={2}
                            rx={1}
                            transform="rotate(-180 18 13)"
                            fill="black"
                          />
                          <path
                            d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                    </span>
                    <h3 className="fs-4 fw-bold mb-0 ms-4">
                      Why do I have to add a payment method ?
                    </h3>
                  </div>
                  {/*end::Header*/}
                  {/*begin::Body*/}
                  <div
                    id="kt_accordion_2_item_1"
                    className="fs-6 collapse show ps-10"
                    data-bs-parent="#kt_accordion_2"
                  >
                    In order to keep a safe and sustainable platform, we require new members to verify their identity with a payment method.
                  </div>
                  {/*end::Body*/}
                </div>
                {/*end::Item*/}
                {/*begin::Item*/}
                <div className="">
                  {/*begin::Header*/}
                  <div
                    className="accordion-header py-3 d-flex collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_accordion_2_item_2"
                  >
                    <span className="accordion-icon">
                      <span className="svg-icon svg-icon-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <rect
                            opacity="0.5"
                            x={18}
                            y={13}
                            width={13}
                            height={2}
                            rx={1}
                            transform="rotate(-180 18 13)"
                            fill="black"
                          />
                          <path
                            d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                    </span>
                    <h3 className="fs-4 fw-bold mb-0 ms-4">Will I be charged during the free trial ?</h3>
                  </div>
                  {/*end::Header*/}
                  {/*begin::Body*/}
                  <div
                    id="kt_accordion_2_item_2"
                    className="collapse fs-6 ps-10"
                    data-bs-parent="#kt_accordion_2"
                  >
                    We will not charge you during the trial. We may need to make a temporary $1 or ₹1 authorisation which is used to validate your credit card and will be refunded immediately.
                  </div>
                  {/*end::Body*/}
                </div>
                {/*end::Item*/}
                {/*begin::Item*/}
                <div className="">
                  {/*begin::Header*/}
                  <div
                    className="accordion-header py-3 d-flex collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_accordion_2_item_3"
                  >
                    <span className="accordion-icon">
                      <span className="svg-icon svg-icon-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <rect
                            opacity="0.5"
                            x={18}
                            y={13}
                            width={13}
                            height={2}
                            rx={1}
                            transform="rotate(-180 18 13)"
                            fill="black"
                          />
                          <path
                            d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                    </span>
                    <h3 className="fs-4 fw-bold mb-0 ms-4">
                      How does your secure signup process work ?
                    </h3>
                  </div>
                  {/*end::Header*/}
                  {/*begin::Body*/}
                  <div
                    id="kt_accordion_2_item_3"
                    className="collapse fs-6 ps-10"
                    data-bs-parent="#kt_accordion_2"
                  >
                    We want to make sure that your identity is not abused. When you sign up, we verify your contact information by validating your credit card details. Your personal data is fully secured throughout the whole process. <br />Credit card details not save with us, Our payment gateway (stripe,razorpay) directly validate card information and provide us last 4 digit and expiry.
                  </div>
                  {/*end::Body*/}
                </div>
                <div className='mt-5'>
                  <h3 className='text-start fs-5 fw-bold mb-0 ms-4 pt-3'>Trusted by</h3>
                  <img src="assets/media/logos/trusted.png" className='w-100' />
                </div>
                {/*end::Item*/}
              </div>
              {/*end::Accordion*/}
            </div>
            {/*end::Header*/}
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*begin::Aside*/}
      </div>
      {/*end::Authentication - Multi-steps*/}
    </>
  );
}

export default BillingVerification;
