import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import UseApi from "../../../../../CustomHooks/useApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import TopBarHeaderLine from "../../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../../Loaders/TableLoader";
import { capitalizeFirstLetter } from "../../../utils/utils";
import ManageSubnet from "../subnet/ManageSubnet";
import CreateInternetGateway from "./CreateInternetGateway";

export default function InternetGateway() {

  const [dataFetched, setDataFetched] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [itemOffset, setItemOffset] = useState(0);
  const [activePage, setActivePage] = useState(0)
  const [internetGatewayDataAll, setInternetGatewayDataAll] = useState(null);
  const [dummyinternetGatewayDataAll, setDummyInternetGatewayDataAll] = useState(null);

  const dataFetchedRef = useRef(false);

  function ProductInfo({ productdata }) {
    return (
      <>
        {productdata != null && Object.keys(productdata).length != 0 ? (
          productdata &&
          productdata.map((value, index) => (
            <tr>
              {/* <td /> */}
              <td className="text-start ps-9">
                <div className="d-flex flex-column w-100 me-2">
                  <div className="d-flex flex-stack mb-2">
                    <span className=" me-2 fs-7 fw-bold">{value.name}</span>
                  </div>
                </div>
              </td>
              <td className="text-center">
                {/* <div className="d-flex flex-column w-100 me-2">
                  <div className="d-flex flex-stack mb-2"> */}
                <span className="me-2 fs-7 fw-bold">
                  {value.uuid}
                </span>
                {/* </div>
                </div> */}
              </td>
              <td className="text-center">
                {/* <div className="d-flex flex-column w-100 me-2">
                  <div className="d-flex flex-stack mb-2"> */}
                <span className="me-2 fs-7 fw-bold">
                  {value.vpc}
                </span>
                {/* </div>
                </div> */}
              </td>
              <td className="text-center">
                {/* <div className="d-flex flex-column w-100 me-2">
                  <div className="d-flex flex-stack mb-2"> */}
                {value.status == "active" ?
                  <span className="badge badge-success badge-lg fw-bold">
                    {value.status}
                  </span>
                  :
                  <span className="badge badge-warning badge-lg fw-bold">
                    {value.status}
                  </span>
                }
                {/* </div>
                </div> */}
              </td>
              <td className="text-center">
                {/* <div className="d-flex flex-column w-100 me-2">
                  <div className="d-flex flex-stack mb-2"> */}
                <span className="me-2 fs-7 fw-bold">{value.created_at}</span>
                {/* </div>
                </div> */}
              </td>
              <td className="text-end pe-9">
                {" "}
                <div className=" justify-content-end flex-shrink-0">
                  <Link to={"/vpc/internetgateways/" + value.id + ""} >
                    <a
                      className="btn btn-primary btn-active-light-primary btn-sm sphide"
                    >
                      {" "}
                      Manage{" "}
                      <span className="svg-icon ms-2 svg-icon-3">
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          {" "}
                          <rect
                            opacity="0.5"
                            x={18}
                            y={13}
                            width={13}
                            height={2}
                            rx={1}
                            transform="rotate(-180 18 13)"
                            fill="black"
                          />{" "}
                          <path
                            d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                            fill="black"
                          />{" "}
                        </svg>{" "}
                      </span>{" "}
                    </a>{" "}
                  </Link>
                </div>
              </td>
            </tr>
          ))
        ) : (
          <>
            <tr>
              <td colspan="12" className="" id="no_record" valign="top">
                <div className="d-flex flex-column flex-center">
                  <div className="fs-5 fw-bolder text-dark mb-4">
                    No items found.
                  </div>
                </div>
              </td>
            </tr>
          </>
        )}
      </>
    );
  }

  const getGateways = async () => {
    const dt = toast;
    const datas = await UseApi("get", "vpc/internetgateway", "");
    if (datas) {
      setDataFetched(true);
    }

    if (datas.status == "error") {
      setInternetGatewayDataAll([]);
      setDummyInternetGatewayDataAll([])
    } else {
      setInternetGatewayDataAll(datas.data);
      setDummyInternetGatewayDataAll(datas.data)
    }
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getGateways();
  }, []);

  const handleSearch = (text) => {
    let data = internetGatewayDataAll.filter((row) => {
      return (
        (row.name && row.name.toString().toLowerCase().includes(text.toLowerCase())))
    });

    // Update the state with the filtered data
    setDummyInternetGatewayDataAll(data)
    setPageCount(Math.ceil(data.length / perPage));
  }

  function PaginatedItems({ itemsPerPage, ProductData }) {
    // Calculate the total number of pages based on itemsPerPage and ProductData
    const pageCount = Math.ceil(ProductData.length / parseInt(itemsPerPage));

    // Calculate the current end offset
    const endOffset = itemOffset + parseInt(itemsPerPage);

    // Handle page click
    const handlePageClick = (event) => {
      const selectedPage = event.selected;
      const newOffset = selectedPage * parseInt(itemsPerPage);
      setActivePage(selectedPage);
      setItemOffset(newOffset);
    };
    return (
      <>
        <ProductInfo productdata={ProductData.slice(parseInt(itemOffset), endOffset)} />
        {ProductData.length > 0 && ProductData.length > 5 ?
          <td colspan="12" className="ps-3">
            <ReactPaginate
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              initialPage={activePage}
              previousLabel="< previous"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </td>
          :
          ""
        }
      </>
    );
  }

  const handleItemsPerPageChange = (newItemsPerPage) => {
    // If the current page goes beyond the total page count, reset to the first page
    const newPageCount = Math.ceil(dummyinternetGatewayDataAll.length / parseInt(newItemsPerPage));
    const newActivePage = activePage >= newPageCount ? 0 : activePage;

    // Set the new offset based on the current page and itemsPerPage
    const newOffset = newActivePage * parseInt(newItemsPerPage);

    // Update state
    setItemOffset(newOffset);
    setActivePage(newActivePage);
    setPerPage(newItemsPerPage);  // Update the itemsPerPage state
  };

  if (dataFetched) {
    return (
      <>
        <div className="col-xl-12">
          {/*begin::Tables Widget 9*/}
          <div className="card card-xl-stretch mb-5 mb-xl-12">
            {/*begin::Header*/}
            <div className="card-header border-0 pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1">Internet gateways</span>
              </h3>
              <div className="card-toolbar">
                <div
                  className="position-relative my-1"
                  style={{ marginRight: 20 }}
                >
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    data-kt-table-widget-4="search"
                    id="searchClient"
                    onChange={(e) => handleSearch(e.target.value)}
                    placeholder="Search"
                  />
                </div>
                <div style={{ marginRight: 20 }}>
                  <div className="dataTables_length">
                    <label className="w-125px">
                      <select
                        onChange={(e) => handleItemsPerPageChange(e.target.value)}
                        //data-control="select2"
                        className="form-select form-select-sm form-select-solid"
                        defaultValue={5}
                        id="perPage"
                      >
                        <option value={5} selected="">
                          5 Records
                        </option>
                        <option value={10}>10 Records</option>
                        <option value={25}>25 Records</option>
                        <option value={50}>50 Records</option>
                        <option value={100}>100 Records</option>
                      </select>
                    </label>
                  </div>
                </div>
                <CreateInternetGateway getGateways={getGateways} />
              </div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            {/*begin::Table container*/}
            <div className="table-responsive">
              {/*begin::Table*/}
              <table
                id="vpcTable"
                className="table table-striped table-row-gray-300 align-middle gs-0 gy-4"
              >
                {/*begin::Table head*/}
                <thead className="bg-secondary">
                  <tr className="fw-bold ">
                    {/* <th className="w-20px p-3" /> */}
                    <th className="w-100px text-start ps-9">Name</th>
                    <th className="w-250px text-center sphide">InternetGateway_ID</th>
                    <th className="w-250px text-center sphide">VPC ID</th>
                    <th className="w-100px text-center">Status</th>
                    <th className="w-150px text-center">Created_at</th>
                    {/* <th className="w-150px d-none">Status</th> */}
                    <th className="w-150px text-center"></th>
                  </tr>
                </thead>
                {/*end::Table head*/}
                {/*begin::Table body*/}
                <tbody id="mainTable">
                  {dummyinternetGatewayDataAll != null ? (
                    <>
                      {dummyinternetGatewayDataAll.length != 0 ? (
                        <>
                          <PaginatedItems
                            ProductData={dummyinternetGatewayDataAll}
                            itemsPerPage={perPage}
                          />
                        </>
                      ) : (
                        <>
                          <tr>
                            <td colspan={12}>
                              <div className="card-body p-0 mt-10">
                                {/*begin::Illustration*/}
                                <div className="text-center px-4 mt-10">
                                  <span className="mw-100 mh-300px">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="234px" height="234px" fill="currentColor" class="bi bi-bezier" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M0 10.5A1.5 1.5 0 0 1 1.5 9h1A1.5 1.5 0 0 1 4 10.5v1A1.5 1.5 0 0 1 2.5 13h-1A1.5 1.5 0 0 1 0 11.5zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm10.5.5A1.5 1.5 0 0 1 13.5 9h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zM6 4.5A1.5 1.5 0 0 1 7.5 3h1A1.5 1.5 0 0 1 10 4.5v1A1.5 1.5 0 0 1 8.5 7h-1A1.5 1.5 0 0 1 6 5.5zM7.5 4a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z"></path><path d="M6 4.5H1.866a1 1 0 1 0 0 1h2.668A6.52 6.52 0 0 0 1.814 9H2.5q.186 0 .358.043a5.52 5.52 0 0 1 3.185-3.185A1.5 1.5 0 0 1 6 5.5zm3.957 1.358A1.5 1.5 0 0 0 10 5.5v-1h4.134a1 1 0 1 1 0 1h-2.668a6.52 6.52 0 0 1 2.72 3.5H13.5q-.185 0-.358.043a5.52 5.52 0 0 0-3.185-3.185"></path></svg>
                                  </span>
                                </div>
                                {/*end::Illustration*/}
                                {/*begin::Wrapper*/}
                                <div className="card-px text-center py-5 mb-20">
                                  {/*begin::Title*/}
                                  <h2 className="fs-1x fw-bolder mb-5 mt-5 text-muted">
                                    Build a custom route in the VPC
                                  </h2>
                                  {/*end::Title*/}
                                  {/*begin::Description*/}
                                  <p className="text-gray-400 fs-4 fw-bold mb-10">
                                    Take control with Utho's VPC routes. Create a
                                    route for your VPC
                                  </p>
                                  {/*end::Description*/}
                                  {/*begin::Action*/}
                                  <CreateInternetGateway getGateways={getGateways} />
                                  {/*end::Action*/}
                                </div>
                                {/*end::Wrapper*/}
                              </div>
                            </td>
                          </tr>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <tr>
                        <td colspan={12}>
                          <TableLoader />
                          <TopBarHeaderLine />
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
                {/*end::Table body*/}
              </table>
              {/*end::Table*/}
            </div>
            {/*end::Table container*/}
            {/*begin::Body*/}
          </div>
          {/*end::Tables Widget 9*/}
        </div>
      </>
    );
  } else {
    return (<>
      <TableLoader />
      <TopBarHeaderLine />
    </>)
  }
}
