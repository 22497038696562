import localStorage from "local-storage";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";   
import UseApi from "../../../CustomHooks/useApi"; 
import Cookies from "js-cookie";
import "./custom.css";
import copy from "copy-to-clipboard";
import SwicthPartner from "./SwicthPartner";
export default function Toolbar(){
  const navigate=useNavigate();

  function renderComponent(type){

    navigate("/processing/"+type)
    // console.log(type); 
  }
  const copyToClipboard=()=> {   
       
    var eleText = document.querySelector("#file_url");
    var ele = document.querySelector("#file_url_copy_btn");
    if(ele && eleText){
      var copyText=ele.innerHTML;
      var copyEle=eleText.value;
    copy(copyEle);   
    ele.innerHTML="Copied";
    eleText.classList.add("bg-primary");
    eleText.classList.add("text-light");
     setTimeout(() => {
      eleText.classList.remove("bg-primary");
      eleText.classList.remove("text-light");
      ele.innerHTML=copyText;
    }, 2000);
  }
   
  }

  const openDrawerFile=()=>{
    var drawerElement = document.querySelector("#files-modal");
        drawerElement.classList.toggle("drawer-on");
        drawerElement.style.width='500px';
  }

  var current_url_string = window.location.href;
      const current_url = new URL(current_url_string);
      const current_domain = current_url.hostname;
  const dataFetchedRef=useRef (false); 
  const logout=()=>{
    localStorage.clear(); 
    // document.cookie = "microhost_api_auth=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //localStorage.remove("Auth");
    
    const cookieNames = Object.keys(Cookies.get());
    const domain = '.'+current_domain; // Specify the domain

// Remove each cookie with the specified domain
cookieNames.forEach((cookieName) => {
  Cookies.remove(cookieName, { domain, path: '/' }); // Make sure to include the path
});

cookieNames.forEach((cookieName) => {
  Cookies.remove(cookieName, { current_domain, path: '/' }); // Make sure to include the path
});
   window.location.reload();
    // const location_url = window.location.origin;
    // window.location.href = location_url; 
  } 
 
  const [userData,setUserData]=useState(null); 
  const getUserInfo=async()=>{
    const themeInfo=document.querySelector("#themeSelect");
    //console.log(localStorage.get("darkmode"));
    if(localStorage.get("darkmode")=="yes"){
    if(themeInfo){
      themeInfo.checked=true; 
    }
  }
      //  const data=await UseApi('get','account/info',''); 
      const data=localStorage.get("user_info");
        if(data){
        setUserData(data);  
        }
        ////console.log(data.user);
  }
  const [logo_dark, setLogo_dark]=useState(null);
  const getAppInfo=async ()=>{
    let toolbarData = localStorage.get('app_info')
    if(toolbarData == null){
    const app_info=await UseApi('get','/appinfo','');
    if(app_info && app_info){
      var siteUrlString=app_info.site;
      
      var siteUrlArr=siteUrlString.split(".");
      var siteLength=siteUrlArr.length;
      
      var siteOrg="https://api."+siteUrlArr[siteLength-2]+"."+siteUrlArr[siteLength-1];  
      var DarkLogo=siteOrg+"/publicfiles/"+app_info.logo;
      setLogo_dark(DarkLogo);
      window.localStorage.setItem('app_info', JSON.stringify(app_info))
      }
    }else{
      var siteUrlString=toolbarData.site;
      
      var siteUrlArr=siteUrlString.split(".");
      var siteLength=siteUrlArr.length;
      
      var siteOrg="https://api."+siteUrlArr[siteLength-2]+"."+siteUrlArr[siteLength-1];  
      var DarkLogo=siteOrg+"/publicfiles/"+toolbarData.logo;
      setLogo_dark(DarkLogo);
    }
  }

  useEffect(()=>{ 
    // if (dataFetchedRef.current) return;
    // dataFetchedRef.current = true;
      getUserInfo();
      // setTimeout(() =>{

        getAppInfo();
      // },100) 
  },[]);  

  const setTheme=(event)=>{
    
    if (event.target.checked) {
      localStorage.set("darkmode",'yes');
      window.location.reload(); 
    } else {
      localStorage.set("darkmode",'no');
      window.location.reload();
        }
  }

  const handleMenu=(type)=>{
    renderComponent(type);
    const parentMenuDiv=document.querySelector("#parentMenuDiv");
    const childMenuDiv=document.querySelector("#childMenuDiv");
    if(parentMenuDiv && childMenuDiv){
      parentMenuDiv.classList.remove('show');
      childMenuDiv.classList.remove('show');
    }
  }
    return(
      <> 
        {/* {userData!=null?(<>  */}
      {/*begin::Header*/}
      <div id="kt_header" style={{}} className="header align-items-stretch">
    
        {/*begin::Container*/}
        <div className="container-fluid d-flex align-items-stretch justify-content-between">
          {/*begin::Aside mobile toggle*/}



          {/* <div
            className="d-none d-flex align-items-center d-lg-none ms-n2 me-2"
            title="Show aside menu"
          >
            <div
              className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
              id="kt_aside_mobile_toggle"
            > 
              <span className="svg-icon svg-icon-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                    fill="black"
                  />
                  <path
                    opacity="0.3"
                    d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                    fill="black"
                  />
                </svg>
              </span> 
            </div>
          </div> */}
          {/*end::Aside mobile toggle*/}
          {/*begin::Mobile logo*/}
          <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
            <a  className="d-lg-none">
            {logo_dark!=null?(<>
              <img
              alt="Logo"
              src={logo_dark}
              className="h-30px logo"
            />
            </>):('')}
               
            </a>
          </div>
          {/*end::Mobile logo*/}
          {/*begin::Wrapper*/}
          <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
            {/*begin::Navbar*/}
            <div className="d-flex align-items-stretch" id="kt_header_nav">
              {/*begin::Menu wrapper*/}
              <div
                className="header-menu align-items-stretch"
                data-kt-drawer="true"
                data-kt-drawer-name="header-menu"
                data-kt-drawer-activate="{default: true, lg: false}"
                data-kt-drawer-overlay="true"
                data-kt-drawer-width="{default:'200px', '300px': '250px'}"
                data-kt-drawer-direction="end"
                data-kt-drawer-toggle="#kt_header_menu_mobile_toggle"
                data-kt-swapper="true"
                data-kt-swapper-mode="prepend"
                data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
              >
                {/*begin::Menu*/}
                
                <div
                  className="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch slide-animation"
                  id="#kt_header_menu"
                  data-kt-menu="true"
                > 
                    <div
                  data-kt-menu-trigger="click"
                  data-kt-menu-placement="bottom-start"
                  className="menu-item here show menu-lg-down-accordion me-lg-1"
                >
                  <span className="menu-link py-3 bg-primary">
                    <span className="menu-title text-light" onClick={openDrawerFile}> 
                    {/*begin::Svg Icon | path: assets/media/icons/duotune/arrows/arr013.svg*/}
  <span className="svg-icon svg-icon-light svg-icon-2">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        opacity="0.3"
        d="M11 13H7C6.4 13 6 12.6 6 12C6 11.4 6.4 11 7 11H11V13ZM17 11H13V13H17C17.6 13 18 12.6 18 12C18 11.4 17.6 11 17 11Z"
        fill="black"
      />
      <path
        d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM17 11H13V7C13 6.4 12.6 6 12 6C11.4 6 11 6.4 11 7V11H7C6.4 11 6 11.4 6 12C6 12.6 6.4 13 7 13H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V13H17C17.6 13 18 12.6 18 12C18 11.4 17.6 11 17 11Z"
        fill="black"
      />
    </svg>
  </span>
  {/*end::Svg Icon*/}
   &nbsp;Create new Customer</span>
                    <span className="menu-arrow d-lg-none" />
                  </span>
                </div>
                <div
                  data-kt-menu-trigger="click"
                  data-kt-menu-placement="bottom-start"
                  className="menu-item menu-lg-down-accordion me-lg-1"
                >
                  <span className="menu-link py-3 bg-light-primary">
                    <span className="menu-title text-primary">Earnings : {userData!=null?(userData.currencyprefix):(<></>)} {userData!=null && userData.partner_comissions?(<>{userData.partner_comissions.total_commission?(userData.partner_comissions.total_commission):('')}</>):(<></>)}</span>
                    <span className="menu-arrow d-lg-none" />
                  </span>
                </div>
                <div
                  data-kt-menu-trigger="click"
                  data-kt-menu-placement="bottom-start"
                  className="menu-item menu-lg-down-accordion me-lg-1"
                >
                  <span className="menu-link py-3 bg-light-primary">
                    <span className="menu-title text-primary">Customers : {userData!=null?(userData.partner_customers):(<></>)}</span>
                    <span className="menu-arrow d-lg-none" />
                  </span>
     
                </div> 
                  {userData!=null?(<>   
                  <div
                    data-kt-menu-trigger="click"
                    className=" menu-item here menu-lg-down-accordion me-lg-1"
                  >
                    <a  className="btn btn-sm btn-light-primary py-3 sphide" style={{cursor:'arow'}}>
                      User ID <b># {userData!=null?(userData.id):(<></>)}</b>.
                    </a>
                  </div> 
                  {/* {userData.is_partner==1?(<><SwicthPartner /></>):(<></>)} */}
                 </>):(<> </>)}
                </div>
                
                {/*end::Menu*/}
              </div>
              {/*end::Menu wrapper*/}
            </div>
            {/*end::Navbar*/}
            {/*begin::Toolbar wrapper*/}
            <div className="d-flex align-items-stretch flex-shrink-0">
              {/*begin::User menu*/}
              <div
                className="d-flex align-items-center ms-1 ms-lg-3"
                id="kt_header_user_menu_toggle"
              >
                {/*begin::Menu wrapper*/}
                <div
                  className="cursor-pointer symbol symbol-30px symbol-md-40px"
                  data-kt-menu-trigger="click"
                  data-kt-menu-attach="parent"
                  data-kt-menu-placement="bottom-end"
                >
                  <div
                    className="vis-item-content"
                    style={{ transform: "translateX(0px)" }}
                  >
                    <div className="rounded-pill bg-light-danger d-flex align-items-center position-relative h-40px w-100 p-2 overflow-hidden">
                      <div
                        className="position-absolute rounded-pill d-block bg-light-primary start-0 top-0 h-100 z-index-1"
                        style={{ width: "100%" }}
                      />
                      <div className="d-flex align-items-center position-relative z-index-2">
                        <div className="symbol-group symbol-hover flex-nowrap me-3">
                          <div className="symbol symbol-circle symbol-25px">
                            {/*begin::Svg Icon | path: assets/media/icons/duotune/communication/com006.svg*/}
                            <span className="svg-icon svg-icon-primary svg-icon-2hx">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  opacity="0.3"
                                  d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z"
                                  fill="black"
                                />
                                <path
                                  d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z"
                                  fill="black"
                                />
                              </svg>
                            </span>
                            {/*end::Svg Icon*/}
                          </div>
                        </div>
                        <a
                          
                          className="fw-bold  text-hover-dark"
                          id="header_profile_name"
                        >
                         {userData!=null?(userData.fullname):(<><div className="small-spinner2"></div></>)}
                        </a>
                        <div className="d-flex flex-center bg-body rounded-pill fs-7 fw-bolder ms-auto h-100 px-3 position-relative z-index-2"></div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*begin::Header menu toggle*/}
                {/* <div
                  className="d-flex align-items-center d-lg-none ms-2 me-n3"
                  title="Show header menu"
                >
                  <div
                    className="btn btn-icon btn-active-light-primary w-50px h-30px w-md-40px h-md-40px"
                    id="kt_header_menu_mobile_toggle"
                  >
                    <img src="https://www.microhost.com/assets/favicon/favicon-32x32.png?v=3" />
                  </div>
                </div> */}
                {/*end::Header menu toggle*/}
                {/*begin::User account menu*/}
                <div
                  className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
                  data-kt-menu="true"
                >
                  {/*begin::Menu item*/}
                  <div className="menu-item px-3">
                    <div className="menu-content d-flex align-items-center px-3">
                      {/*begin::Avatar*/}
                      <div className="symbol symbol-50px me-5 d-none">
                        <img
                          alt="Logo"
                          src="https://www.microhost.com/assets/favicon/favicon-32x32.png?v=3"
                        />
                      </div>
                      {/*end::Avatar*/}
                      {/*begin::Username*/}
                      <div className="d-flex flex-column">
                        <div className="fw-bolder d-flex align-items-center fs-5">
                          <span id="profile_name">{userData!=null?(userData.fullname):(<></>)}</span>
                        </div>
                        <a
                          
                          className="fw-bold text-muted text-hover-primary fs-7"
                          id="profile_email"
                        >
                          {userData!=null?(userData.email):(<></>)}
                        </a>
                      </div>
                      {/*end::Username*/}
                    </div>
                  </div>
                  {/*end::Menu item*/}
                  {/*begin::Menu separator*/}
                  <div className="separator my-2" />
                  {/*end::Menu separator*/}
                  {/*begin::Menu item*/}
                  <div className="menu-item px-5">
                    <Link to="account" >
                    <a  className="menu-link px-5">
                      My Profile
                    </a>
                    </Link>
                  </div>
                  {/*end::Menu item*/}
                  {/*begin::Menu item*/}
                  <div className="menu-item px-5">
                    <Link to="/orders" >
                    <a  className="menu-link px-5">
                      <span className="menu-text">Orders</span>
                      <span className="menu-badge"></span>
                    </a>
                    </Link>
                  </div>
                  {/*end::Menu item*/}
                  {/*begin::Menu separator*/}
                  <div className="separator my-2" />
                  {/*end::Menu separator*/}
                 
                  {/*begin::Menu item*/}
                  <div className="menu-item px-5">
                    <a
                    onClick={logout}
                      
                      className="menu-link px-5"
                    >
                      Sign Out
                    </a>
                  </div>
                  {/*end::Menu item*/}
                  {/*begin::Menu separator*/}
                  <div className="separator my-2" />
                  {/*end::Menu separator*/}
                   {/*begin::Menu item*/}
                   <div className="menu-item px-5 my-1">
                   {userData!=null && userData.is_partner==1?(<><SwicthPartner /></>):(<></>)}
                  </div>
                  {/*end::Menu item*/}
                  {/*begin::Menu separator*/}
                  <div className="separator my-2" />
                  {/*end::Menu separator*/}
                  {/*begin::Menu item*/}
                  <div className="menu-item px-5">
                    <div className="menu-content px-5">
                      <label
                        className="form-check form-switch form-check-custom form-check-solid pulse pulse-success"
                        htmlFor="kt_user_menu_dark_mode_toggle"
                      >
                        <div id="darkmodeChange">
                          <input
                            className="form-check-input w-30px h-20px"
                            type="checkbox" 
                            name="mode"
                            id="themeSelect" 
                             onChange={setTheme}
                          />
                        </div>
                        <span className="pulse-ring ms-n1" />
                        <span className="form-check-label text-gray-600 fs-7">
                          Dark Mode{" "}
                        </span>
                      </label>
                    </div>
                  </div>
                  {/*begin::Menu separator*/}
                  <div className="separator my-2" /> 
                </div>
                {/*end::User account menu*/}
                {/*end::Menu wrapper*/}
              </div>
              {/*end::User menu*/}
            </div>
            {/*end::Toolbar wrapper*/}
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*end::Container*/}
        
      </div>
      {/*end::Header*/}
      {/* </>):(<></>)} */}

      {/*begin::Modal - Create account*/}
      <div
            id="files-modal"
            className="bg-body shadow drawer drawer-end"
            data-kt-drawer="true"
            data-kt-drawer-name="chat"
            data-kt-drawer-activate="true"
            data-kt-drawer-direction="end"
            data-kt-drawer-close="#close_ssh"
            data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
            style={{ maxWidth: "70%", important: true }}
          >
            {/*begin::Messenger*/}
            <div
              className="card rounded-0 w-100 border-0"
              id="kt_drawer_chat_messenger"
            >
              {/*begin::Card header*/}
              <div
                className="card-header pe-5"
                id="kt_drawer_chat_messenger_header"
              >
                {/*begin::Title*/}
                <div className="card-title">
                  {/*begin::User*/}
                  <div className="d-flex justify-content-center flex-column me-3">
                    <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                      Add New Customer
                    </a>
                  </div>
                  {/*end::User*/}
                </div>
                {/*end::Title*/}
                {/*begin::Card toolbar*/}
                <div className="card-toolbar">
                  {/*begin::Close*/}
                  <div
                    onClick={openDrawerFile}
                    className="btn btn-sm btn-icon btn-active-light-primary"
                    id="close_ssh"
                  >
                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                    <span className="svg-icon svg-icon-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.5"
                          x={6}
                          y="17.3137"
                          width={16}
                          height={2}
                          rx={1}
                          transform="rotate(-45 6 17.3137)"
                          fill="black"
                        />
                        <rect
                          x="7.41422"
                          y={6}
                          width={16}
                          height={2}
                          rx={1}
                          transform="rotate(45 7.41422 6)"
                          fill="black"
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}
                  </div>
                  {/*end::Close*/}
                </div>
                {/*end::Card toolbar*/}
              </div>
              {/*end::Card header*/}
              {/*begin::Card body*/}
              <div className="" id="">
                {/*begin::Form*/}
                <form name="routing" id="importkey" className="form">
                  {/*begin::Modal body*/}
                  <div className="modal-body ">
                    {/*begin::Scroll*/}
                    <div
                      className="scroll-y me-n7 pe-7"
                      id="kt_modal_create_api_key_scroll"
                      data-kt-scroll="true"
                      data-kt-scroll-activate="{default: false, lg: true}"
                      data-kt-scroll-max-height="auto"
                      data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                      data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                      data-kt-scroll-offset="300px"
                    >
                      {/*begin::Input group*/}
                      <div>
  <label className="form-label mb-5">Copy the share link </label>
  <div className="d-flex">
  {userData!=null?(<>
                  <input
                    id="file_url"
                    type="text"
                    className="form-control form-control-solid me-3 flex-grow-1 readonly"
                    name="search"
                    defaultValue={"https://console.utho.com/partner-signup?partnerid="+userData.id}
                  />
                  <a style={{cursor:"pointer"}}
                     onClick={copyToClipboard}
                    id="file_url_copy_btn"
                    className="btn btn-light fw-bolder flex-shrink-0" 
                  >
                    Copy Link
                  </a>
                  </>):(<></>)}
                </div>
  <div className="text-muted fs-6 mb-7 mt-5">
 <p className="fw-bold text-gray-800"> To share this signup link with your customers, follow these steps:</p>
<ol>
  <li>Click the "Copy Link" button to copy the link to your clipboard.</li>
  <li>Open your preferred communication method (e.g., email, messaging app).</li>
  <li>Create a new message or email to your customer.</li>
  <li>In the message body, right-click and select "Paste" to insert the link.</li>
  <li>Send the message to your customer and encourage them to click the link to sign up.</li>
 </ol> 
   </div>
                      </div> 
                      {/*end::Input group*/} 
                    </div>
                    {/*end::Scroll*/}
                  </div>
                  {/*end::Modal body*/} 
                </form>
                {/*end::Form*/}
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Messenger*/}
          </div>
  {/*end::Modal - Create project*/}
     </>
    
    )
}