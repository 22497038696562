import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
export default function KubernetesSchedule({ props }) {
  const [scheduleArr, setScheduleArr] = useState([]);

  const makeid = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  function createTimeFormat(timeString) {
    // Split the time string into hours and minutes
    const [hours, minutes] = timeString.split(':').map(Number);

    // Create a new Date object and set the hours and minutes in UTC
    const currentDateUTC = new Date();
    currentDateUTC.setUTCHours(hours);
    currentDateUTC.setUTCMinutes(minutes);
    currentDateUTC.setUTCSeconds(0); // Optional: Set seconds to 0

    // Adjust to IST (UTC+5:30)
    const offsetInMinutes = 5 * 60 + 30;
    const timestampInIST = currentDateUTC.getTime() + offsetInMinutes * 60 * 1000;

    // Format the timestamp as 'hh:mmA'
    const formattedTime = new Date(timestampInIST);
    const hours24 = formattedTime.getHours();
    const hours12 = hours24 % 12 || 12; // Convert to 12-hour format
    const ampm = hours24 < 12 ? 'AM' : 'PM';
    const formattedTimeString = `${String(hours12).padStart(2, '0')}:${String(minutes).padStart(2, '0')}${ampm}`;

    return formattedTimeString;
  }
  function updateRecurrence(id, adata, rvalue) {
    var scArr = adata;
    var recurrence_ = document.querySelector("#recurrence_" + id);
    var week_sec = document.querySelector("#week_sec_" + id);
    var date_sec = document.querySelector("#date_sec_" + id);
    var time_sec = document.querySelector("#time_sec_" + id);
    var date_value_ = document.querySelector("#date_value_" + id);
    //  var start_date = document.querySelector("#start_date_"+id);
    var time_value = document.querySelector("#time_value" + id);
    var week_value = document.querySelector("#week_value" + id);
    if (week_value && date_value_ && time_value && recurrence_ && week_sec && date_sec && time_sec) {
      if (rvalue == "Cron") {
        week_value.disabled = true;
        date_value_.disabled = true;
        time_value.disabled = true;
        scArr.recurrence = "Cron_30 2 * * MON";
        recurrence_.value = "Cron_30 2 * * MON";
      } else if (rvalue == "Every week") {
        week_value.disabled = false;
        date_value_.disabled = true;
        time_value.disabled = false;
        scArr.recurrence = rvalue + " " + scArr.recurrence_week + " " + createTimeFormat(time_value.value);
        recurrence_.value = rvalue + " " + scArr.recurrence_week + " " + createTimeFormat(time_value.value);
      } else if (rvalue == "Every day") {
        week_value.disabled = true;
        date_value_.disabled = true;
        time_value.disabled = false;
        scArr.recurrence = rvalue + " " + createTimeFormat(time_value.value);
        recurrence_.value = rvalue + " " + createTimeFormat(time_value.value);
      } else if (rvalue == "Once") {
        week_value.disabled = true;
        date_value_.disabled = true;
        time_value.disabled = true;
        recurrence_.value = rvalue;
        scArr.recurrence = rvalue;
        // start_date.value=createTimestampFromDateAndTimeInIST(date_value_.value, time_value.value);
      } else {
        week_value.disabled = true;
        date_value_.disabled = true;
        time_value.disabled = true;
        recurrence_.value = rvalue;
        scArr.recurrence = rvalue;
        // start_date.value=createTimeFormat(time_value.value);
      }
      //console.log(scArr);
      scArr.recurrence_duration = rvalue;
      modifySchedules("update", "" + id + "", scArr);
    }
  }
  function changeWeekRecurrence(id, adata, value) {
    var scArr = adata;
    var recurrence_ = document.querySelector("#recurrence_" + id);
    if (recurrence_) {
      scArr.recurrence_week = value;
      scArr.recurrence = scArr.recurrence_duration + " " + value + " " + createTimeFormat(scArr.selectedTime);
      recurrence_.value = scArr.recurrence_duration + " " + value + " " + createTimeFormat(scArr.selectedTime);
    }
    console.log(scArr);
    modifySchedules("update", "" + id + "", scArr);
  }
  function changeTimeRecurrence(id, adata, value) {
    var scArr = adata;
    var recurrence_ = document.querySelector("#recurrence_" + id);
    // var start_date = document.querySelector("#start_date_"+id); 
    if (recurrence_) {
      if (scArr.recurrence_duration == "Every week") {
        scArr.selectedTime = value;
        scArr.recurrence = scArr.recurrence_duration + " " + scArr.recurrence_week + " " + createTimeFormat(value);
        recurrence_.value = scArr.recurrence_duration + " " + scArr.recurrence_week + " " + createTimeFormat(value);
        //  start_date.value=createTimestampFromTimeInIST(value);
      } else if (scArr.recurrence_duration == "Every day") {
        scArr.selectedTime = value;
        scArr.recurrence = scArr.recurrence_duration + " " + createTimeFormat(value);
        recurrence_.value = scArr.recurrence_duration + " " + createTimeFormat(value);
        // start_date.value=createTimestampFromTimeInIST(value);
      }

    }
    //console.log(scArr);
    modifySchedules("update", "" + id + "", scArr);
  }
  function updateAdjustmentValue(id, action, indexid, actionType) {
    const field = document.getElementById(id);

    if (field) {
      // Get the current value and convert it to a number
      let currentValue = parseFloat(field.value) || 0;

      // Update the value based on the action
      if (action === 'up') {
        currentValue += 1;
      } else if (action === 'down') {
        currentValue -= 1;
      }

      // Update the field with the new value
      if (actionType == "policy") {
        var compareIndex = document.querySelector("#compare" + indexid);
        if (compareIndex) {
          if (compareIndex.value == "below") {
            if (currentValue >= 0) {
              field.value = -1;
            } else {
              field.value = currentValue;
            }
          } else {
            if (currentValue <= 0) {
              field.value = 1;
            } else {
              field.value = currentValue;
            }
          }
        }
      } else {
        if (currentValue <= 0) {
          field.value = 1;
        } else {
          field.value = currentValue;
        }
      }
    }
  }
  function modifySchedules(action, index, newData) {
    // Create a new Date object to get the current timestamp
    const currentDate = new Date();

    // Format the date and time
    const formattedDate = currentDate.toISOString().split('T')[0]; // YYYY-MM-DD

    // Get hours and minutes in 24-hour format
    const hours = currentDate.getHours().toString().padStart(2, '0'); // 01-23
    const minutes = currentDate.getMinutes().toString().padStart(2, '0'); // 00-59

    const formattedTime = `${hours}:${minutes}`;
    // //console.log(index);
    // Clone the existing array to avoid modifying the original array directly
    if (props && props.deployInfo[0]) {
      var parr = props.deployInfo[0].schedules;
      const updatedPolicies = [...parr];

      switch (action) {
        case 'add':
          // Add a new policy to the array 
          const newPolicy = {
            name: "Schedule-" + makeid(6),
            desiredsize: "2",
            recurrence: "Once",
            start_date: new Date(),
            selectedTime: formattedTime,
            selectedDate: formattedDate
          };
          updatedPolicies.push(newPolicy);
          // setScheduleArr(null);
          break;
        case 'delete':
          // Delete a policy at the specified index
          if (index !== null && index >= 0 && index < updatedPolicies.length) {
            updatedPolicies.splice(index, 1);
          } else {
            console.error('Invalid index for deletion.');
          }
          break;
        case 'update':
          // Update a policy at the specified index with new data
          if (index !== null && index >= 0 && index < updatedPolicies.length) {
            updatedPolicies[index] = { ...updatedPolicies[index], ...newData };
          } else {
            console.error('Invalid index for update.');
          }
          break;
        default:
          console.error('Invalid action.');
          break;
      }

      // Log the updated array for verification (you can remove this in production)
      //console.log(updatedPolicies);

      // Return the updated array
      if (props && props.deployInfo[0]) {
        props.deployInfo[0].schedules = updatedPolicies;
      }
      if (action != "update") {
        setScheduleArr(null);
        getSchedule();
      }

      return updatedPolicies;
    }

  }
  const addNewPolicy = () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split('T')[0];
    const hours = currentDate.getHours().toString().padStart(2, '0');
    const minutes = currentDate.getMinutes().toString().padStart(2, '0');
    const formattedTime = `${hours}:${minutes}`;

    const newPolicy = {
      name: "Schedule-" + makeid(6),
      desiredsize: "2",
      recurrence: "Once",
      start_date: new Date(),
      selectedTime: formattedTime,
      selectedDate: formattedDate
    };

    // Update the state by spreading the existing array and adding the new policy
    setScheduleArr(prevArray => [...prevArray, newPolicy]);
  };

  function getSchedule() {
    // if (props && props.deployInfo[0] && props.deployInfo[0].schedules.length > 0) {
    //   setScheduleArr(props.deployInfo[0].schedules);
    // }
    // Create a new Date object to get the current timestamp
    const currentDate = new Date();

    // Format the date and time
    const formattedDate = currentDate.toISOString().split('T')[0]; // YYYY-MM-DD

    // Get hours and minutes in 24-hour format
    const hours = currentDate.getHours().toString().padStart(2, '0'); // 01-23
    const minutes = currentDate.getMinutes().toString().padStart(2, '0'); // 00-59

    const formattedTime = `${hours}:${minutes}`;
    const newPolicy = {
      name: "Schedule-" + makeid(6),
      desiredsize: "2",
      recurrence: "Once",
      start_date: new Date(),
      selectedTime: formattedTime,
      selectedDate: formattedDate
    };
    setScheduleArr(newPolicy)
  }
  console.log(scheduleArr);

  useEffect(() => {
    // Run this code on page load
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split('T')[0];
    const hours = currentDate.getHours().toString().padStart(2, '0');
    const minutes = currentDate.getMinutes().toString().padStart(2, '0');
    const formattedTime = `${hours}:${minutes}`;

    const newPolicy = {
      name: "Schedule-" + makeid(6),
      desiredsize: "2",
      recurrence: "Once",
      start_date: new Date(),
      selectedTime: formattedTime,
      selectedDate: formattedDate
    };

    // Set the initial state with the new policy
    setScheduleArr([newPolicy]);
  }, []);
  // useEffect(() => {
  //   // getSchedule();
  //   addNewPolicy()
  //   // modifySchedules('add', 0, {})
  // }, [scheduleArr])
  return (
    // <div className='d-flex flex-column flex-column-fluid'>
    //   <div className='p-3'>
    //     <div className='row'>
    //       <div className='mb-5 mb-xl-12'>
            <div className="col-xl-12 card mb-5">
              <div className="card-header border-3 mb-5 pt-2">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bolder text-gray-800">Schedules</span>
                  <span className="text-gray-400 mt-1 fw-bold fs-6">
                    Configure you auto scaling schedules here
                  </span>
                </h3>
              </div>
              <div className="pb-5">
                <div
                  id="kt_table_users_wrapper"
                  className="dataTables_wrapper dt-bootstrap4 no-footer"
                >
                  <div className="table-responsive">
                    <table
                      className="table table-row-dashed gy-5 gs-7"
                      id="page_list"
                    >
                      <thead className="bg-light-dark">
                        <tr className="text-start fw-bolder fs-7 text-uppercase gs-0">
                          <th className="w-300px text-center">Name </th>
                          <th className="w-150px text-center sphide">Desired size </th>
                          <th className="w-150px text-center">Time Zone</th>
                          <th className="w-150px text-center">Recurrence</th>
                          <th className="w-150px text-center">Date</th>
                          <th className="w-100px text-center">Time</th>
                          <th className="w-300px  text-center">Start_At</th>
                          <th className="w-100px" />
                        </tr>
                      </thead>
                      <tbody className="text-gray-600" id="con-data">
                        {scheduleArr != null ? (<>
                          {scheduleArr.length > 0 ? (<>
                            {scheduleArr.map((value, index) => (<>
                              <tr className="openCustomer">
                                <td className="text-center">
                                  <input
                                    type="text"
                                    name="name"
                                    className="form-control"
                                    placeholder="Enter Name"
                                    defaultValue={value.name}
                                    onBlur={(e) => modifySchedules('update', index, { name: e.target.value, desiredsize: value.desiredsize, recurrence: value.recurrence, start_date: value.start_date, recurrence_duration: value.recurrence_duration, recurrence_week: value.recurrence_week, selectedTime: value.selectedTime, selectedDate: value.selectedDate })}
                                  />
                                </td>
                                <td className="text-center">
                                  <div className="d-flex align-items-center">
                                    {/*begin::Dialer*/}
                                    <div
                                      className="input-group w-md-150px"
                                      data-kt-dialer="true"
                                    >
                                      {/*begin::Decrease control*/}
                                      <div
                                        className="btn btn-icon btn-outline btn-outline-secondary"
                                        onClick={(e) => updateAdjustmentValue("desiredsize_" + index + "", "down")}
                                      >
                                        <i className="bi bi-dash fs-1" />
                                      </div>
                                      {/*end::Decrease control*/}
                                      {/*begin::Input control*/}
                                      <input
                                        type="text"
                                        name="desiredsize"
                                        className="form-control text-center"
                                        readOnly={true}
                                        id={"desiredsize_" + index}
                                        defaultValue={value.desiredsize}
                                        onBlur={(e) => modifySchedules('update', index, { name: value.name, desiredsize: e.target.value, recurrence: value.recurrence, start_date: value.start_date, recurrence_duration: value.recurrence_duration, recurrence_week: value.recurrence_week, selectedTime: value.selectedTime, selectedDate: value.selectedDate })}
                                      />
                                      {/*end::Input control*/}
                                      {/*begin::Increase control*/}
                                      <div
                                        className="btn btn-icon btn-outline btn-outline-secondary"
                                        onClick={(e) => updateAdjustmentValue("desiredsize_" + index + "", "up")}
                                      >
                                        <i className="bi bi-plus fs-1" />
                                      </div>
                                      {/*end::Increase control*/}
                                    </div>
                                    {/*end::Dialer*/}
                                  </div>
                                </td>
                                <td>
                                  <select className='form-select' id="ist" name="ist" defaultValue="IST">
                                    <option value="IST">IST</option> updateRecurrence
                                  </select>
                                </td>
                                <td>
                                  <select className='form-select' id={"recurrence_duration_" + index} value={value.recurrence_duration} onChange={(e) => {
                                    updateRecurrence("" + index + "", {
                                      name: value.name,
                                      desiredsize: value.desiredsize,
                                      recurrence: value.recurrence,
                                      start_date: value.start_date,
                                      recurrence_duration: e.target.value,
                                      recurrence_week: value.recurrence_week,
                                      selectedTime: value.selectedTime,
                                      selectedDate: value.selectedDate
                                    }, e.target.value)
                                  }}>
                                    {[
                                      "Cron",
                                      "Every 5 Min",
                                      "Every 30 Min",
                                      "Every 1 hour",
                                      "Every day",
                                      "Every week",
                                      "Once"
                                    ].map((option, optionIndex) => (
                                      <option key={optionIndex} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </select>
                                  <input type="hidden" defaultValue={value.recurrence} id={"recurrence_" + index} onChange={(e) => modifySchedules('update', index, { name: value.name, desiredsize: value.desiredsize, recurrence: e.target.value, start_date: value.start_date, recurrence_duration: value.recurrence_duration, recurrence_week: value.recurrence_week, selectedTime: value.selectedTime, selectedDate: value.selectedDate })} />
                                  <input type="hidden" defaultValue={value.start_date} id={"start_date_" + index} onChange={(e) => modifySchedules('update', index, { name: value.name, desiredsize: value.desiredsize, recurrence: value.recurrence, start_date: e.target.value, recurrence_duration: value.recurrence_duration, recurrence_week: value.recurrence_week, selectedTime: value.selectedTime, selectedDate: value.selectedDate })} />

                                </td>

                                <td className="text-center">
                                  <span className="" id={"week_sec_" + index}>
                                    {value.recurrence_duration == "Every week" ? (<>
                                      <select className='form-select' id={"week_value" + index} value={value.recurrence_week} onChange={(e) => {
                                        changeWeekRecurrence(index, {
                                          name: value.name,
                                          desiredsize: value.desiredsize,
                                          recurrence: value.recurrence,
                                          start_date: value.start_date,
                                          recurrence_duration: value.recurrence_duration,
                                          recurrence_week: e.target.value,
                                          selectedTime: value.selectedTime,
                                          selectedDate: value.selectedDate
                                        }, e.target.value)
                                      }}>
                                        <option value="Monday">Monday</option>
                                        <option value="Tuesday">Tuesday</option>
                                        <option value="Wednesday">Wednesday</option>
                                        <option value="Thursday">Thursday</option>
                                        <option value="Friday">Friday</option>
                                        <option value="Saturday">Saturday</option>
                                        <option value="Sunday">Sunday</option>
                                      </select>
                                    </>) : (<>
                                      <input type="hidden" defaultValue={value.recurrence_duration} id={"week_value" + index} />
                                    </>)}
                                  </span>
                                  <span id={"date_sec_" + index}>
                                    {value.recurrence == "Once" || value.recurrence == "Crone" ? (<>
                                      <input
                                        type="date"
                                        className="form-control"
                                        id={"date_value_" + index}
                                        value={value.selectedDate}
                                        onBlur={(e) => modifySchedules('update', index, { name: value.name, desiredsize: value.desiredsize, start_date: value.start_date, recurrence_duration: value.recurrence_duration, recurrence_week: value.recurrence_week, selectedTime: value.selectedTime, selectedDate: e.target.value })}
                                      />
                                    </>) : (<>
                                      <input
                                        type="hidden"
                                        className="form-control"
                                        id={"date_value_" + index}
                                        defaultValue={value.selectedDate}
                                      />
                                    </>)}
                                  </span>
                                </td>
                                <td className="text-center">
                                  <span id={"time_sec_" + index}>
                                    {value.recurrence == "Crone" || value.recurrence == "Once" || value.recurrence == "Every day" || value.recurrence == "Every week" ? (<>
                                      <input
                                        type="time"
                                        id={"time_value" + index}
                                        className="form-control"
                                        defaultValue={value.selectedTime}
                                        onBlur={(e) => {
                                          changeTimeRecurrence(index, {
                                            name: value.name,
                                            desiredsize: value.desiredsize,
                                            recurrence: value.recurrence,
                                            start_date: value.start_date,
                                            recurrence_duration: value.recurrence_duration,
                                            recurrence_week: value.recurrence_week,
                                            selectedTime: e.target.value,
                                            selectedDate: value.selectedDate
                                          }, e.target.value)
                                        }}
                                      />
                                    </>) : (<>
                                      <input
                                        type="hidden"
                                        id={"time_value" + index}
                                        className="form-control"
                                        defaultValue={value.selectedTime}
                                      />
                                    </>)}
                                  </span>
                                </td>
                                <td>
                                  <div>
                                    <DatePicker
                                      className="form-control"
                                      selected={new Date(value.start_date)}
                                      // defaultValue={value.start_date}
                                      onSelect={(eValue) => modifySchedules('update', index, { name: value.name, desiredsize: value.desiredsize, recurrence: value.recurrence, start_date: eValue, recurrence_duration: value.recurrence_duration, recurrence_week: value.recurrence_week, selectedTime: value.selectedTime, selectedDate: value.selectedDate })}
                                      showTimeSelect
                                      timeFormat="HH:mm"
                                      timeIntervals={15}
                                      timeCaption="time"
                                      dateFormat="MMMM d, yyyy h:mm aa"
                                    />
                                  </div>
                                </td>
                                <td className="text-center sphide">
                                  {scheduleArr.length > 1 ? (<>
                                    <div
                                      onClick={(e) => {
                                        // openDrawerForDeployAutoScalingUpdate(value, index)
                                      }}
                                      className="btn btn-icon btn-light-primary sphide"
                                      style={{ marginRight: 20 }}
                                    >
                                      {/*begin::Svg Icon | path: assets/media/icons/duotune/files/fil017.svg*/}{" "}
                                      <span className="svg-icon svg-icon-muted svg-icon-2">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <path
                                            opacity="0.3"
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z"
                                            fill="black"
                                          />
                                          <path
                                            d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z"
                                            fill="black"
                                          />
                                          <path
                                            d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z"
                                            fill="black"
                                          />
                                        </svg>
                                      </span>{" "}
                                      {/*end::Svg Icon*/}
                                    </div>{" "}
                                    <div
                                      onClick={(e) => modifySchedules('delete', index, {})}
                                      className="btn btn-icon btn-light-danger"
                                    >
                                      {" "}
                                      <span className="svg-icon svg-icon-muted svg-icon-2">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <path
                                            opacity="0.3"
                                            d="M12 10.6L14.8 7.8C15.2 7.4 15.8 7.4 16.2 7.8C16.6 8.2 16.6 8.80002 16.2 9.20002L13.4 12L12 10.6ZM10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 8.99999 16.4 9.19999 16.2L12 13.4L10.6 12Z"
                                            fill="black"
                                          />
                                          <path
                                            d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM13.4 12L16.2 9.20001C16.6 8.80001 16.6 8.19999 16.2 7.79999C15.8 7.39999 15.2 7.39999 14.8 7.79999L12 10.6L9.2 7.79999C8.8 7.39999 8.2 7.39999 7.8 7.79999C7.4 8.19999 7.4 8.80001 7.8 9.20001L10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 9 16.4 9.2 16.2L12 13.4L14.8 16.2C15 16.4 15.3 16.5 15.5 16.5C15.7 16.5 16 16.4 16.2 16.2C16.6 15.8 16.6 15.2 16.2 14.8L13.4 12Z"
                                            fill="black"
                                          />
                                        </svg>
                                      </span>{" "}
                                    </div>
                                  </>) : ('')}
                                </td>
                              </tr>
                            </>))}
                          </>) : ('')}
                        </>) : ('')}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  )
}
