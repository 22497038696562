import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom/dist";
import UsePost from "../../../../CustomHooks/usePost";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UseApi from "../../../../CustomHooks/useApi";
import { connect } from "react-redux";
import "./custom.css";
import { AddDatabaseDeploy, AddDatabaseNodeCount } from "../../../../Redux/Services/Action/actions";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import PricingContainer from "../pricing/PricingContainer";
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../Loaders/TableLoader";
import { publicIp, publicIpv4, publicIpv6 } from 'public-ip';
import Dczone from "../../utils/dczone/Dczone";
import localStorage from "local-storage";
import Vpc from "../../utils/vpc/Vpc";
var serialize = require("form-serialize");
function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}

const DeployDatabaseCluster = (props) => {
  const [nodeCount, setNodeCount] = useState([]);
  const formRef = useRef(null);
  const forceUpdate = useForceUpdate();
  const [adcdata, setAdcdata] = useState([]);
  const [ddcdata, setDdcdata] = useState([]);
  const [vpcLoader, setVpcLoader] = useState(false);
  const [vpcArray, setVpcArray] = useState([])
  const [firewallData, setFirewallData] = useState(null);
  const [vpcId, setVpcId] = useState('')
  const [distroData, setDistroData] = useState(null);
  const [securityGroups, setSecurityGroups] = useState([])
  const [openDropdown, setOpenDropdown] = useState(null);
  const [location, setLocation] = useState("innoida")
  const [dbclusterType, setDbClusterType] = useState('')
  const [vpc_id, setVpc_id] = useState('')
  const [vpcMultipleInfo, setVpcMultipleInfo] = useState([]);
  const [vpcData, setVpcData] = useState(null);
  const dataFetchedRef2 = useRef();
  const [planData, setPlanData] = useState([]);
  const [userData, setUserData] = useState(null);
  const user_data = useRef(null);
  const dczoneStatus = useRef(true);
  const vpcd = { vpcid: '0' }
  const navigate = useNavigate();
  const navRedirect = () => {
    setTimeout(() => {
      navigate('/database-cluster');
    }, 2000);

  }

  const getAllPlan = async () => {
    user_data.current = localStorage.get("user_info");
    setPlanData([])
    const PlanData = await UseApi("get", "plans?type=db_rdbms&currency=" + user_data.current.currency, "");
    console.log(PlanData);

    if (PlanData) {
      setPlanData((prePlanData) => [...PlanData.plans]);
    }
    setUserData(user_data.current);
  }

  const getDczone = async () => {
    const data = await UseApi("get", "dczones", "");
    // user_data.current = await UseApi("get", "account/info", "");
    if (Object.keys(data).length === 0) {
      setAdcdata([]);
      setDdcdata([]);
    } else {
      if (dczoneStatus.current) {
        data.dczones.map(async (value, index) => {
          if (value.status !== "active") {
            var server_ip = await publicIpv4();
            if (server_ip) {
              if (
                server_ip !== "103.209.144.51" ||
                server_ip !== "103.209.144.66" ||
                server_ip !== "103.209.145.208" ||
                server_ip !== "103.209.144.81" ||
                server_ip !== "103.209.144.82" ||
                server_ip !== "103.209.144.83" ||
                server_ip !== "103.127.30.91" ||
                server_ip !== "103.209.144.60" ||
                server_ip !== "103.127.30.239" ||
                server_ip !== "103.127.28.52"
              ) {
                ddcdata.push(value);
              }
              forceUpdate();
            }
          } else {
            adcdata.push(value);
            forceUpdate();
          }
        });
        dczoneStatus.current = false;
      }
      setTimeout(() => {
        var clickTo = document.querySelector("#dcslugl_innoida");
        if (clickTo) {
          clickTo.click();
        }
      }, 1000)
    }
  };

  useEffect(() => {
    if (dataFetchedRef2.current) return;
    dataFetchedRef2.current = true;
    getDczone();
    getAllPlan()
  }, []);

  const selectDc = async (slug) => {
    var dczone_slug = document.querySelector("#dczone_slug");
    if (dczone_slug) {
      dczone_slug.value = slug;
    }
    if (props) {
      if (props.deployInfo[0]) {
        if (props.deployInfo[0].dcslug != slug) {
          const updatedObject = { ...props.deployInfo[0], dcslug: slug };
          const newArray = [updatedObject];
          props.updateDeployInfo(newArray);
          // console.log(newArray);
        }
      }
    }
    setLocation(slug)
    var dcSelect = document.querySelectorAll([".tickMarkDc"]);
    var currentDc = document.querySelector("#tickMarkDc-" + slug);
    if (dcSelect) {
      dcSelect.forEach((clist) => {
        clist.classList.add("d-none");
      });
    }
    if (currentDc) {
      currentDc.classList.remove("d-none");
    }
    const vpc_data = await UseApi("get", "vpc/dc/" + slug, "");
    if (vpc_data.status === "error") {
      setVpcLoader(false);
      setVpcData(null);
    } else {
      setVpcLoader(false);
      // delete vpc_data.html;
      // if (Object.keys(vpc_data).length != 0) {
      //   var vpcArr = Object.values(vpc_data);
      setVpcData(vpc_data?.vpc);
      // }
    }
  };
  const makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
  // end- select plans
  function ChangeScreen() {
    useEffect(() => {
      function handleResize() {
        const sideCheckout = document.querySelector("#sideCheckout");
        if (sideCheckout) {
          if (window.innerWidth > 990) {
            sideCheckout.classList.add("mhc_checkout");
          } else {
            sideCheckout.classList.remove("mhc_checkout");
          }
        }
      }
      handleResize();
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
    return null;
  }

  const [allowIp, setAllowIp] = useState(null);
  function getAllowIp() {
    if (props) {
      if (props.nodeCountData) {
        setAllowIp(props.nodeCountData);
      }
    }
  }
  // useEffect(() => {
  //   let vdata = [];
  //   if (vpcData && vpcData.length > 0) {
  //     vpcData.map((value, index) => {

  //       vdata.push(value[1].name + " (" + value[1].id + ")");
  //     })
  //   }

  //   setVpcMultipleInfo(vdata);
  // }, [vpcData])
  useEffect(() => {
    getAllowIp();
    updateDeploy();
    getDistro();
  }, [props])
  const addIpField = async () => {
    if (props) {
      if (props.nodeCountData) {
        const newArray = [...props.nodeCountData, makeid(8)];
        props.updateNodeCountData(newArray);
        //   console.log(newArray);
      }
    }
  }
  const deleteIpField = async (ip_id) => {
    if (props) {
      if (props.nodeCountData) {
        const newArray = props.nodeCountData;
        // console.log(newArray);
        for (var i = 0; i < newArray.length; i++) {
          if (newArray[i] == ip_id) {
            const index = newArray.indexOf(newArray[i]);
            if (index > -1) { // only splice array when item is found
              newArray.splice(index, 1); // 2nd parameter means remove one item only
            }
          }
        }
        //    console.log(newArray);
        props.updateNodeCountData([]);
        props.updateNodeCountData(newArray);

      }
    }
  }
  const getDistro = async () => {
    toast.dismiss();
    setDistroData(null);
    var dc = "innoida"
    // if(props && props.deployInfo[0] && props.deployInfo[0].dcslug){
    //      dc=props.deployInfo[0].dcslug;
    // }
    const data = await UseApi("get", "cloud/getdeploy?dcslug=" + dc, "");
    // user_data.current = await UseApi("get", "account/info", "");
    user_data.current = localStorage.get("user_info");
    setUserData(user_data.current);
    if (data.firewalls.length <= 0) {
      setFirewallData(null);
    } else {
      setVpcLoader(false)
      setFirewallData(data.firewalls);
    }
  };
  useEffect(() => {
    var vpc_field = document.querySelector("#vpc_field");
    if (vpc_field && vpcMultipleInfo) {
      //console.log(vpcMultipleInfo);
      const contactTagify = new window.Tagify(vpc_field, {
        whitelist: vpcMultipleInfo,
        maxTags: 20,
        dropdown: {
          maxItems: 20,
          classname: "",
          enabled: 0,
          closeOnSelect: false
        }
      });
    }
  }, [vpcMultipleInfo])


  useEffect(() => {
    var firewall_field = document.querySelector("#firewall_field");
    if (firewall_field && firewallData) {
      var fdata = [];
      firewallData.map((value, index) => {
        fdata.push(value.name + " (" + value.id + ")");
      })
      const contactTagify = new window.Tagify(firewall_field, {
        whitelist: fdata,
        maxTags: 20,
        dropdown: {
          maxItems: 20,
          classname: "",
          enabled: 0,
          closeOnSelect: false
        }
      });
    }
  }, [firewallData])
  function updateNewVpc(e) {
    if (e.target.value) {
      var eleData = JSON.parse(e.target.value);
      var eleIds = eleData.map(item => {
        // Extract string portion from the value within parentheses
        var match = item.value.match(/\(([^)]+)\)/);
        return match ? match[1] : null;
      }).filter(Boolean); // Filter out null values

      // Concatenate the extracted strings into a comma-separated string
      var concatenatedVpcIds = eleIds.join(',');
      let arr = []
      arr.push(concatenatedVpcIds)
      const filteredFirewallIds = arr.filter(id => id !== '');
      setVpcArray(() => [...filteredFirewallIds])
      // // Set the concatenated string as the value of the add_cloud input
      // if (props && props.deployInfo[0]) {
      //   props.deployInfo[0].vpc = concatenatedVpcIds;
      // }
    }
  }
  function updateNewFirewall(e) {
    if (e.target.value) {
      var eleData = JSON.parse(e.target.value);
      var eleIds = eleData.map(item => {
        // Extract numeric portion from the value (assuming it's always in parentheses)
        var match = item.value.match(/\((\d+)\)/);
        return match ? match[1] : null;
      }).filter(Boolean); // Filter out null values

      // Concatenate the cloud IDs into a comma-separated string
      var concatenatedFirewallIds = eleIds.join(',');
      let arr = []
      arr.push(concatenatedFirewallIds)
      const filteredFirewallIds = arr.filter(id => id !== '');
      setSecurityGroups(() => [...filteredFirewallIds])

      // // Set the concatenated string as the value of the add_cloud input
      // if (props && props.deployInfo[0]) {
      //   props.deployInfo[0].security_groups = concatenatedFirewallIds;
      // }
    }
  }

  const [databaseData, setDatabaseData] = useState(null);
  const getDatabases = async () => {

    const data = {
      "databases": [
        {
          "id": "1",
          "appid": "1",
          "type": "SQL",
          "name": "MySQL",
          "slug": "mysql",
          // "versions": "mysql-version-8.0,mysql-version-8.2,mysql-version-8.3",
          "versions": "mysql-version-8.0",
          "status": "active",
        },
        {
          "id": "2",
          "appid": "1",
          "type": "SQL",
          "name": "Postgre SQL",
          "slug": "pg",
          "versions": "pg-version-16,pg-version-15,pg-version-14",
          "status": "active",
        },
        {
          "id": "3",
          "appid": "1",
          "type": "SQL",
          "name": "Mariadb",
          "slug": "mariadb",
          "versions": "mariadb-version-11.3, mariadb-version-11.2, mariadb-version-11.1, mariadb-version-11.0, mariadb-version-10.11, mariadb-version-10.6",
          "status": "active",
        },
        {
          "id": "4",
          "appid": "1",
          "type": "Redis",
          "name": "Redis",
          "slug": "redis",
          "versions": "redis-version-7.0.0,redis-version-7.2.4",
          "status": "active",
        },
        {
          "id": "5",
          "appid": "1",
          "type": "Kafka",
          "name": "Kafka",
          "slug": "kafka",
          "versions": "kafka-version-3.5.1,kafka-version-7.2.4",
          "status": "active",
        }
        // ,
        // {
        //   "id": "6",
        //   "appid": "1",
        //   "type": "RabbitMq",
        //   "name": "RabbitMq",
        //   "slug": "rabbit",
        //   "versions": "rabbit-version-3.13.7,rabbit-version-3.12.14",
        //   "status": "active",
        // }
        // {
        //   "id": "4",
        //   "appid": "1",
        //   "type": "SQL",
        //   "name": "MongoDb",
        //   "slug": "mongodb",
        //   // "versions": "mariadb-version-15.1",
        //   "status": "active",
        // }
      ]
    }
    if (data) {
      setDatabaseData(data.databases);
    }
  }

  useEffect(() => {
    getDatabases();
    var total_price = document.querySelector("#total_cost");
    var plan_cost = document.querySelector("#plan_cost");
    var currency_final = document.querySelector("#currency_final");
    var billing_final = document.querySelector("#billing_final");
    // props.deployInfo[0].plan_id = ""
    // props.deployInfo[0].billingcycle = "";
    // props.deployInfo[0].price = ""
    if (plan_cost && total_price) {
      plan_cost.value = ''
      total_price.innerHTML = '0'
      currency_final.innerHTML = 'Rs.'
      billing_final.innerHTML = '/mon'
    }
  }, []);

  const dropdownRef = useRef(new Map());

  const databaseVersion = (dbImage) => {
    console.log(dbImage);
    setDbClusterType((old) => old = dbImage)
    setOpenDropdown((old) => old = dbImage);
    let element = document.querySelector("#replicaNode")
    let kafka_element = document.querySelector("#kafkaNode")
    if (dbImage === 'redis') {
      element.classList.add('d-none')
      kafka_element.classList.add("d-none")
    } else {
      element.classList.remove('d-none')
      kafka_element.classList.add("d-none")
    }
    if (dbImage == "kafka") {
      element.classList.add('d-none')
      kafka_element.classList.remove("d-none")
    }
    var databaseVersionList = document.querySelector("#database_version_list_" + dbImage);

    // Check if the dropdown is already open
    if (databaseVersionList.classList.contains("d-block")) {
      // Close the dropdown
      databaseVersionList.classList.remove("d-block");
    } else {
      var allDropdowns = document.querySelectorAll(".deploy_dbVersioncontainer");
      var allDropdownHeights = document.querySelectorAll(".versionDb");

      allDropdowns.forEach((dropdown) => {
        dropdown.classList.remove("d-block");
      });

      allDropdownHeights.forEach((dropdownHeight) => {
        dropdownHeight.style.height = "auto";
        dropdownHeight.classList.remove("active");
      });

      databaseVersionList.classList.add("d-block");
    }
  };

  const handleClickOutside = (event) => {
    dropdownRef.current.forEach((dropdownElement, key) => {
      if (dropdownElement && !dropdownElement.contains(event.target)) {
        if (openDropdown === key) {
          dropdownElement.classList.remove('d-none');
        } else {
          dropdownElement.classList.add('d-none');
        }
      }
    });
    setOpenDropdown((old) => old = null);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [openDropdown]);


  // const handleClickOutside = (event) => {
  //   // Check if the click is outside the dropdown
  //   const allDropdowns = document.querySelectorAll(".deploy_dbVersioncontainer.d-block");
  //   let isClickInside = false;

  //   allDropdowns.forEach(dropdown => {
  //     if (dropdown.contains(event.target)) {
  //       isClickInside = true;
  //     }
  //   });

  //   if (!isClickInside) {
  //     allDropdowns.forEach(dropdown => {
  //       dropdown.classList.remove("d-block");
  //     });

  //     // Remove the event listener after handling the click
  //     document.removeEventListener('click', handleClickOutside);
  //   }
  // };


  function database_Name(str) {
    if (!str) {
      return "";
    }
    // Split the string into an array using the delimiter "-"
    var arr = str.split("-");

    // Return the element at the 0th index
    return arr[0].toLowerCase();
  }
  function database_Version(str) {
    if (!str) {
      return "";
    }
    // Split the string into an array using the delimiter "-" 
    var arr = str.split("-");

    // Return the element at the 0th index
    return arr[2].toLowerCase();
  }
  console.log(user_data, props.deployInfo[0]);

  const selectDb = (slug, version) => {
    console.log(slug, version);
    props.deployInfo[0].price = 0
    props.deployInfo[0].plan_id = ''
    props.deployInfo[0].billingcycle = ''
    var replica_input = document.querySelector("#replica_input");
    var total_price = document.querySelector("#total_cost");
    var plan_price = document.querySelector("#plan_cost");
    console.log(total_price, plan_price, user_data);

    if (total_price && plan_price) {
      total_price.innerHTML = user_data.current != null ? user_data?.current?.currencyprefix + 0.00 + "/mon" : 'Rs.0.00/mon'
      // Check if plan_price is an input field
      console.log("plan_price tagName:", plan_price.tagName);
      if (plan_price.tagName === 'INPUT' || plan_price.tagName === 'TEXTAREA') {
        plan_price.value = 0;  // for input elements
      } else {
        plan_price.innerHTML = 0;  // for non-input elements
      }
    }
    console.log(total_price, plan_price);

    if (props) {
      if (props.deployInfo[0]) {
        if (props.deployInfo[0].db_version != version) {
          const updatedObject = { ...props.deployInfo[0], db_version: version };
          const newArray = [updatedObject];
          props.updateDeployInfo(newArray);
          // console.log(newArray);
        }
      }
    }
    if (replica_input) {
      replica_input.value = 0
    }

    var database_version = document.querySelector("#database_Version");
    if (database_version) {
      database_version.value = database_Version(version);
    }
    var database_engine = document.querySelector("#database_engine");
    if (database_engine) {
      database_engine.value = database_Name(version);
    }

    var osSelect = document.querySelectorAll([".tickMarkDb"]);
    var currentOs = document.querySelector("#tickMarkDb-" + slug);
    if (osSelect) {
      osSelect.forEach((clist) => {
        clist.classList.add("d-none");
      });
    }
    if (currentOs) {
      currentOs.classList.remove("d-none");
    }

    const os_cost = document.querySelector("#selectedDatabaseVersion");
    if (os_cost) {
      os_cost.value = version;
    }
    const current_db_version = document.querySelector("#database_version_list_" + slug);
    if (current_db_version) {
      current_db_version.classList.remove("d-block");
    }
    const all_radio = document.getElementsByClassName("database_radio");
    if (all_radio) {
      for (var i = 0; i < all_radio.length; i++) {
        all_radio[i].checked = false;
      }
    }
    const all_label = document.getElementsByClassName("versionDb");
    if (all_label) {
      for (var i = 0; i < all_label.length; i++) {
        //all_label[i].classList.remove('active');
        all_label[i].classList.remove("shadowBox");
      }
    }
    var pselect = document.querySelectorAll([".deploy_dbversioncontainer"]);
    if (pselect) {
      pselect.forEach((clist) => {
        clist.classList.remove("d-block");
      });
    }
    const all_versionText = document.getElementsByClassName(
      "dbversionselectmultiple"
    );
    if (all_versionText) {
      for (var i = 0; i < all_versionText.length; i++) {
        all_versionText[i].innerHTML = "Select Version";
      }
    }


    const current_radio = document.querySelector("#select_" + slug);
    if (current_radio) {
      current_radio.checked = true;
    }
    const current_label = document.querySelector(
      "#versionDb_" + slug
    );
    if (current_label) {
      current_label.classList.add("shadowBox");
    }
    //current_label.classList.add('active');
    const current_select_version = document.querySelector(
      "#dbversion_" + slug
    );
    if (current_select_version) {
      current_select_version.innerHTML = version;
    }
    if (dbclusterType) {
      if (dbclusterType == "kafka" || dbclusterType == "redis") {
        fetchKafkaPlan();
      } else {
        console.log("other type");

        getAllPlan()
      }
    }
  };



  const addDatabase = async () => {
    const dt = toast.loading("Please wait...");
    var submitBtn = document.querySelector("#database-btn");
    var replica_input = document.querySelector("#replica_input");
    var total_price = document.querySelector("#total_cost");
    var plan_cost = document.querySelector("#plan_cost");
    console.log(plan_cost.value, total_price.innerHTML);

    if (
      (total_price.innerHTML == "0" || total_price.innerHTML == '') &&
      (plan_cost.value == "0" || plan_cost.value != '')
    ) {
      setTimeout(() => {
        toast.update(dt, {
          render: "Please select plan type",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      }, 1000);
      return;
    }

    if (submitBtn) {
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      const responseData = await UsePost('post', 'databases', 'databaseDeploy');
      if (responseData) {
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
        if (responseData.status === 'error') {
          toast.update(dt, {
            render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        } else if (responseData.status === 'success') {
          toast.update(dt, {
            render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          replica_input.value = 0
          navRedirect();
          props.deployInfo[0].plan_id = ""
          props.deployInfo[0].billingcycle = "";
          props.deployInfo[0].price = ""
          plan_cost.value = ''
          total_price.innerHTML = ''
          if (formRef.current) {
            formRef.current.reset(); // Reset the form fields
          }
          selectTickMark(
            vpcId,
            "Vpc",
            "vpc"
          )
        }
      }

    }
  }
  function databaseName(str) {
    // Split the string into an array using the delimiter "-"
    if (str != "" && str != undefined && str != null) {
      var arr = str.split("-");

      // Return the element at the 0th index
      return arr[0].toLowerCase();
    }
  }


  function updateDeploy() {
    console.log(props.deployInfo[0], "hggh");

    if (props && props.deployInfo[0]) {
      if (props.deployInfo[0].db_version != "") {
        var database_version = document.querySelector("#database_version");
        var database_engine = document.querySelector("#database_engine");
        if (database_version && database_engine) {
          database_version.value = database_Version(props.deployInfo[0].db_version);
          database_engine.value = database_Name(props.deployInfo[0].db_version);
          var dbslug = databaseName(props.deployInfo[0].db_version);
          selectDb(dbslug, props.deployInfo[0].db_version);
        }
      }
      //   if(props.deployInfo[0].price!=""){
      //   var plan_value=document.querySelector("#plan_value");
      //   if(plan_value){
      //     plan_value.value=props.deployInfo[0].plan_id;
      //     show_plan_price(props.deployInfo[0].plan_id, props.deployInfo[0].price)
      //   }
      // }
      // if(props.deployInfo[0].billingcycle!=""){
      //   var billing_info=document.querySelector("#billing_info");
      //   if(billing_info){
      //     billing_info.value=props.deployInfo[0].billingcycle;
      //   }
      // }
      if (props.deployInfo[0].nodes_count != "") {
        var replica_input = document.querySelector("#replica_input");
        if (replica_input) {
          replica_input.value = props.deployInfo[0].nodes_count;
        }
      }
      if (props.deployInfo[0].cluster_label != "") {
        var db_label = document.querySelector("#db_label");
        if (db_label) {
          db_label.value = props.deployInfo[0].cluster_label;
        }
      }
      if (props.deployInfo[0].dcslug != "") {
        var dczone_slug = document.querySelector("#dczone_slug");
        if (dczone_slug) {
          dczone_slug.value = props.deployInfo[0].dcslug;
          selectDc(props.deployInfo[0].dcslug);
        }
      }
    }
    calcFinalCost();
  }

  useEffect(() => {
    if (props) {
      updateDeploy();
      // console.log(props.deployInfo[0]);
    }
  }, [props])

  function checkInput(val) {
    var replica_input = document.querySelector("#replica_input");
    if (replica_input) {
      if (val < 1) {
        replica_input.value = 1;
      }
    }
    calcFinalCost();
  }

  function calcFinalCost() {
    var current_plan_price = document.querySelector("#plan_cost");
    var replica_input = document.querySelector("#replica_input");
    console.log(current_plan_price);

    var finalCost = 0;
    // console.log(current_plan_price.value,replica_input.value);

    if (current_plan_price && replica_input) {
      // console.log(parseInt(current_plan_price.value)+" => "+parseInt(replica_input.value));
      finalCost = parseInt(current_plan_price.value ? current_plan_price.value : 0) * (parseInt(replica_input.value) + 1);

    }
    console.log(finalCost);

    var currency_final = document.querySelector("#currency_final");
    var total_cost = document.querySelector("#total_cost");
    var billing_final = document.querySelector("#billing_final");
    console.log(currency_final, total_cost, billing_final);

    if (currency_final && total_cost && billing_final) {
      if (user_data && user_data.current && user_data.current) {
        currency_final.innerHTML = user_data.current.currencyprefix;
      }
      total_cost.innerHTML = finalCost;
      const billing_info = document.querySelector("#billing_info");
      if (billing_info) {
        if (billing_info.value == "12month") {
          billing_final.innerHTML = " /year";
        } else if (billing_info.value == "monthly") {
          billing_final.innerHTML = " /mon";
        } else if (billing_info.value == "hourly") {
          billing_final.innerHTML = " /mon";
        }
      }
    }
  }


  function updatedPlanInfo(plan = []) {
    console.log(plan, "plandata");
    const billing_info = document.querySelector("#billing_info");
    const plan_value = document.querySelector("#size_value");
    const plan_cost = document.querySelector("#plan_cost");

    if (billing_info && plan_value && plan_cost) {
      billing_info.value = plan.billing;
      plan_value.value = plan.planid;
      plan_cost.value = plan.payable_price;
    }
    if (props.deployInfo) {
      props.deployInfo[0].plan_id = plan.planid;
      props.deployInfo[0].billingcycle = plan.billing;
      props.deployInfo[0].price = plan.payable_price;
    }
    calcFinalCost();
  }
  // const selectTickMark = (sshid, name) => {
  //   console.log(sshid,name);
  //   var allTickmarks = document.querySelectorAll([".tickMark" + name]);
  //   var currentPlan = document.querySelector("#tickMark" + name + "-" + sshid);
  //   var mainTickMark = document.querySelectorAll([".border" + name]);
  //   var currentMain = document.querySelector("#border" + name + "-" + sshid);
  //   setVpc_id(sshid)
  //   // props.deployInfo[0].vpc = sshid
  //   if (mainTickMark) {
  //     mainTickMark.forEach((clist) => {
  //       clist.classList.remove("activeBorder");
  //     });
  //   }
  //   if (allTickmarks) {
  //     allTickmarks.forEach((clist) => {
  //       clist.classList.add("d-none");
  //     });
  //   }
  //   if (currentPlan) {
  //     currentPlan.classList.remove("d-none");
  //   }
  //   if (currentMain) {
  //     currentMain.classList.add("activeBorder");
  //   }
  // };

  const selectTickMark = (sshid, name, inputElement) => {
    var currentPlan = document.querySelector("#tickMark" + name + "-" + sshid);
    var currentMain = document.querySelector("#border" + name + "-" + sshid);
    var input_element = document.querySelector("#" + inputElement + sshid)

    if (currentPlan && currentMain) {
      // Toggle the tick mark visibility
      currentPlan.classList.toggle("d-none");

      // Toggle the border's active class
      if (currentPlan.classList.contains("d-none")) {
        currentMain.classList.remove("activeBorder");
        input_element.classList.remove("deploy_checkbox")
      } else {
        currentMain.classList.add("activeBorder");
        input_element.classList.add("deploy_checkbox")
      }
      // If the tick mark is now visible, remove the tick mark from other elements
      if (!currentPlan.classList.contains("d-none")) {
        var allTickmarks = document.querySelectorAll(".tickMark" + name);
        allTickmarks.forEach((tickMark) => {
          if (tickMark !== currentPlan) {
            tickMark.classList.add("d-none");
          }
        });

        // Remove active border from other elements
        var mainTickMarks = document.querySelectorAll(".border" + name);
        mainTickMarks.forEach((border) => {
          if (border !== currentMain) {
            border.classList.remove("activeBorder");
          }
        });
      }
    }
  };

  const fetchKafkaPlan = async () => {
    setPlanData([])
    if (dbclusterType == "kafka") {
      let resData = await UseApi('get', `plans/db_kafka/${location}`, '')
      if (resData?.plans) {
        setPlanData((prePlanData) => [...resData.plans]);
      } else {
        setPlanData([])
      }
    } else if (dbclusterType == "redis") {
      let resData = await UseApi('get', `plans/db_redis/${location}`, '')
      if (resData?.plans) {
        setPlanData((prePlanData) => [...resData.plans]);
      } else {
        setPlanData([])
      }
    }
    //  else {
    //   getAllPlan()
    // }
  }

  const handleChangeReplicaCount = (status) => {
    let plusElement = document.querySelector(`#replica_up`)
    let inputElement = document.querySelector("#replica_input")
    let minusElement = document.querySelector('#replica_down')

    const increaseReplicaCount = () => {
      minusElement.classList.remove('disabled');
      if (parseInt(inputElement.value) < 3) {
        inputElement.value = parseInt(inputElement.value) + 1;
      } else {
        plusElement.classList.add('disabled');
      }
    };
    const decreaseReplicaCount = () => {
      plusElement.classList.remove('disabled');
      if (parseInt(inputElement.value) > 0) {
        inputElement.value = parseInt(inputElement.value) - 1;
      } else {
        minusElement.classList.add('disabled');
      }
    };

    if (status == 'up') {
      increaseReplicaCount();
    } else if (status == 'down') {
      decreaseReplicaCount();
    }
    calcFinalCost()
  }
  return (
    <>
      {props && adcdata.length > 0 ? (<>
        <ChangeScreen />
        <form id="databaseDeploy" ref={formRef}>
          {/*begin::Tables Widget 9*/}
          <div className=" col-xl-12 card mb-5">
            {/*begin::Header*/}
            <input
              type="hidden"
              name=""
              id="dczone_slug"
              className="form-control text-center"
              defaultValue={props?.deployInfo[0]?.dcslug}
            />
            {/*begin::data center*/}
            <Dczone adcdata={adcdata} ddcdata={ddcdata} selectDc={selectDc} location={location} />
            {/* End data center */}
          </div>
          {/*begin::Col*/}
          <input
            type="hidden"
            name="cpumodel"
            value="amd"
          />
          <div className="col-xl-12 card mb-5">
            {/*begin::List widget 10*/}

            <div className="card-header border-3 mb-5 pt-2 ps-6 p-0">
              {/*begin::Title*/}
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder text-gray-800">
                  Select Database Cluster
                </span>
                <span className="text-gray-400 mt-1 fw-bold fs-6">
                  MySQL/Mariadb/Postgre/Redis/Kafka
                </span>
              </h3>
            </div>
            <div className="mx-5 mb-3">
              <div
                className="row g-7 "
                data-kt-buttons="true"
                data-kt-buttons-target="[data-kt-button]"
              >
                <input
                  type="hidden"
                  name="cluster_version"
                  id="database_version"
                  defaultValue={database_Version(props?.deployInfo[0]?.db_version)}
                />
                <input
                  type="hidden"
                  name="cluster_engine"
                  id="database_engine"
                  defaultValue={database_Name(props?.deployInfo[0]?.db_version)}
                />
                {/* <DatabaseDropdown /> */}
                {databaseData != null ? (<>
                  {databaseData.length != 0 ? (<>
                    {databaseData.map((value, index) => (
                      <>
                        <div className="col-md-4 col-lg-4 col-xxl-4">
                          <input
                            type="radio"
                            id={"select_" + value.slug}
                            className="deploy_checkbox database_radio"
                            defaultValue=""
                          />
                          <label
                            onClick={(e) => (databaseVersion(value.slug))}
                            className=" label versionDb text-start border-1 border-gray-300 border px-6 py-2"
                            id={"version" + value.name}
                            style={{ position: "relative" }}
                          >
                            <span className="ms-5">
                              <div className="d-flex  ">
                                <div className="flex-grow-1">
                                  <div className="row">
                                    <div className="col-4">
                                      <div className="symbol symbol-55px me-5">
                                        <img src={"/assets/media/databases/" + value.slug + ".svg"} className="pb-3" />
                                      </div>
                                    </div>
                                    <div className="col-8">
                                      <p className="text-dark fw-bolder text-hover-primary fs-6">
                                        {value.name}
                                        <span
                                          className="text-muted d-block fw-bold deploy_checkbox_line2 dbversionselectmultiple w-100px"
                                          id={"dbversion_" + value.slug}
                                        >
                                          Select Version
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  <span
                                    ref={(el) => {
                                      if (el) dropdownRef.current.set(value.slug, el);
                                      else dropdownRef.current.delete(value.slug);
                                    }}
                                    className={`deploy_dbVersioncontainer bg-white p-5 border-left-right-bottom`}
                                    id={"database_version_list_" + value.slug}
                                    style={{
                                      position: "absolute",
                                      top: "100%",
                                      left: 0,
                                      width: "100%",
                                      zIndex: 99,
                                      display: "none"
                                    }}
                                  >
                                    {value.versions && value.versions.split(",").map((value2, index2) => (<>
                                      <span
                                        onClick={(event) => { event.stopPropagation(); selectDb(value.slug, value2); }}
                                        className="deploy_osversionselector alert alert-primary p-3"
                                      >
                                        {value2}
                                      </span>
                                    </>))}

                                  </span>
                                </div>
                              </div>
                            </span>
                            <div
                              className="d-none tickMarkDb ribbon ribbon-triangle ribbon-top-start border-primary"
                              id={"tickMarkDb-" + value.slug}
                            >
                              <div className="ribbon-icon mt-n5">
                                <i className="bi bi-check2 fs-2 text-white" />
                              </div>
                            </div>
                          </label>
                        </div>
                      </>))}

                  </>) : ('')}
                </>) : (<>
                  <div className="small-spinner"></div>
                </>)}
              </div>
            </div>
          </div>
          {/*end::Col*/}

          {/*begin::Col*/}
          <input
            type="hidden"
            name="size"
            id="size_value"
            defaultValue={
              props.deployInfo && props.deployInfo
                ? props.deployInfo[0].plan_id
                : ""
            }
          />
          <input
            type="hidden"
            id="plan_cost"
            defaultValue={
              props.deployInfo && props.deployInfo
                ? props.deployInfo[0].price
                : ""
            }
          />
          <input
            type="hidden"
            name="billing"
            id="billing_info"
            defaultValue={
              props.deployInfo && props.deployInfo
                ? props.deployInfo[0].billingcycle
                : ""
            }
          />
          {userData != null && planData.length > 0 ? (<>
            {props.deployInfo[0] ? (<>
              <PricingContainer updatePlan={updatedPlanInfo} currBilling={props.deployInfo[0].billingcycle} currPlanid={props.deployInfo[0].plan_id} user={userData} plans={planData} />
            </>) : ('')}
          </>) : (<>
            <div className="col-xl-12 card mb-5">
              <div className="card-header border-3 mb-5 pt-2">
                {/*begin::Title*/}
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bolder text-gray-800">
                    Select Plan Type
                  </span>
                  <span className="text-gray-400 mt-1 fw-bold fs-6">
                    Basic/CPU Optimized/Memory Optimized
                  </span>
                </h3>
              </div>
              <div className="mx-5 mb-20">
                <TableLoader />
                {/* <div className="small-spinner"></div> */}
              </div>
            </div>
          </>)}
          {/*end::Col*/}

          <div className="col-xl-12 card mb-5" id="replicaNode">
            <div className="card-header border-3 mb-5 pt-2 ps-6 p-0">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder text-gray-800">
                  Set Number of Replica
                </span>
                <span className="text-gray-400 mt-1 fw-bold fs-6">
                  Input the number of Replica for your cluster
                </span>
              </h3>
            </div>
            <div className="mx-5 mb-3">
              <div
                className="input-group w-md-150px"
                data-kt-dialer="true"
              >
                {/*begin::Decrease control*/}
                <a
                  className={`btn btn-icon btn-outline btn-outline-secondary`}
                  id="replica_down"
                  onClick={(e) => handleChangeReplicaCount("down", "replica_down")}
                >
                  <i className="bi bi-dash fs-1" />
                </a>
                <input
                  type="text"
                  className="form-control text-center"
                  name="replica_count"
                  id="replica_input"
                  readOnly={true}
                  defaultValue={0}
                />
                <a
                  className={`btn btn-icon btn-outline btn-outline-secondary`}
                  id="replica_up"
                  onCli
                  onClick={(e) => handleChangeReplicaCount("up", "replica_up")}
                >
                  <i className="bi bi-plus fs-1" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-12 card mb-5 d-none" id="kafkaNode">
            <div className="card-header border-3 mb-5 pt-2">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder text-gray-800">
                  Set Number of Brokers
                </span>
                <span className="text-gray-400 mt-1 fw-bold fs-6">
                  Select the number of Brokers for your cluster
                </span>
              </h3>
            </div>
            <div className="mx-5 mb-3">
              <div className="col-md-4 col-lg-4 col-xxl-4">
                <select className="form-select">
                  <option value='3'>3 Brokers</option>
                  <option value='6'>6 Brokers</option>
                  <option value='9'>9 Brokers</option>
                  <option value='15'>15 Brokers</option>
                </select>
              </div>
            </div>
          </div>
          {<Vpc vpcData={vpcData} selectTickMark={selectTickMark} vpcd={vpcd} vpcLoader={vpcLoader} setVpcDataId={setVpcId} />}
          {/*begin::Col*/}
          <div className="col-xl-12 card mb-5 d-none">
            {/* begin::List widget 10*/}
            {vpcData != null && vpcData?.length > 0 ? (<>
              <div className="col-xl-12 card mb-5">
                {/*begin::List widget 10*/}
                <div className=" h-lg-100 mb-5" id="vpc_section">
                  {/*begin::Header*/}
                  <div className="card-header border-3 mb-5 pt-2 ps-6 p-0">
                    {/*begin::Title*/}
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bolder text-gray-800">
                        VPC Network
                      </span>
                      <span className="text-gray-400 mt-1 fw-bold fs-6">
                        Select VPC for the Cluster
                      </span>
                    </h3>
                    {/*end::Title*/}
                  </div>
                  {vpcLoader == true ? (
                    <>
                      <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                        {/* <div className="small-spinner"></div> */}
                        <TableLoader />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="mx-5 mb-3">
                        {/*begin::Radio group*/}
                        <div
                          id="getvpc"
                          className="row"
                          data-kt-buttons="true"
                        >
                          {vpcData != null ? (
                            <>
                              {Object.keys(vpcData).length != 0
                                ? Object.entries(vpcData).map(
                                  (value, index) => (
                                    <>
                                      <div className="col-md-4 col-lg-4 col-xxl-4 mt-5">
                                        {/*begin::Option*/}
                                        <input
                                          className="btn-check deploy_checkbox"
                                          type="radio"
                                          name="vpc"
                                          defaultValue={value[1].id}
                                          id={"vpc" + value[1].id}
                                        // value={vpc_id}
                                        />
                                        <label
                                          htmlFor={"vpc" + value[1].id}
                                          onClick={() =>
                                            selectTickMark(
                                              value[1].id,
                                              "Vpc",
                                              "vpc"
                                            )
                                          }
                                          className="borderVpc btn btn-outline btn-outline btn-outline-default  d-flex align-items-center"
                                          id={"borderVpc-" + value[1].id}
                                          style={{ overflow: "hidden" }}
                                        >
                                          {/*begin::Svg Icon | path: icons/duotune/communication/com003.svg*/}
                                          <span className="svg-icon svg-icon-primary svg-icon-4x me-1">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width={24}
                                              height={24}
                                              viewBox="0 0 24 24"
                                              fill="none"
                                            >
                                              <path
                                                opacity="0.3"
                                                d="M19 18C20.7 18 22 16.7 22 15C22 13.3 20.7 12 19 12C18.9 12 18.9 12 18.8 12C18.9 11.7 19 11.3 19 11C19 9.3 17.7 8 16 8C15.4 8 14.8 8.2 14.3 8.5C13.4 7 11.8 6 10 6C7.2 6 5 8.2 5 11C5 11.3 5.00001 11.7 5.10001 12H5C3.3 12 2 13.3 2 15C2 16.7 3.3 18 5 18H19Z"
                                                fill="black"
                                              />
                                            </svg>
                                          </span>
                                          {/*end::Svg Icon*/}
                                          <span className="d-block fw-bold text-start">
                                            <span className="text-dark fw-bolder d-block fs-3">
                                              {value[1].network}
                                            </span>
                                            <span className="text-muted fw-bold fs-8 w-100">
                                              {" "}
                                              {value[1].name}
                                            </span>
                                          </span>
                                          <div
                                            className="d-none tickMarkVpc ribbon ribbon-triangle ribbon-top-start border-primary"
                                            id={
                                              "tickMarkVpc-" + value[1].id
                                            }
                                          >
                                            <div className="ribbon-icon mt-n5">
                                              <i className="bi bi-check2 fs-2 text-white" />
                                            </div>
                                          </div>
                                        </label>
                                        {/*end::Option*/}
                                      </div>
                                    </>
                                  )
                                )
                                : ""}
                            </>
                          ) : (
                            ""
                          )}

                          <div className="col-md-4 col-lg-4 col-xxl-4 mt-5">
                            {/*begin::Option*/}
                            {/*begin::Radio button*/}
                            <a href="/vpc/deploy" target="_blank">
                              <input
                                className="btn-check deploy_checkbox"
                                type="radio"
                                name="vpc_create"
                              // defaultValue={value[1].id}
                              // id={"vpc" + value[1].id}
                              // value={vpc_id}
                              />
                              <label className="btn btn-outline btn-outline btn-outline-default d-flex flex-stack text-start">
                                {/*end::Description*/}
                                <div className="d-flex align-items-center me-2">
                                  {/*begin::Info*/}
                                  <div className="flex-grow-1">
                                    <div className="fw-bold opacity-50 mt-5">
                                      {" "}
                                      Add New VPC
                                    </div>
                                  </div>
                                  {/*end::Info*/}
                                </div>
                                {/*end::Description*/} {/*begin::Price*/}
                                <div className="ms-2 mt-2">
                                  {/*begin::Svg Icon | path: icons/duotune/communication/com003.svg*/}
                                  <span className="svg-icon svg-icon-3x me-4">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={24}
                                      height={24}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <rect
                                        opacity="0.3"
                                        x={2}
                                        y={2}
                                        width={20}
                                        height={20}
                                        rx={10}
                                        fill="black"
                                      />
                                      <rect
                                        x="10.8891"
                                        y="17.8033"
                                        width={12}
                                        height={2}
                                        rx={1}
                                        transform="rotate(-90 10.8891 17.8033)"
                                        fill="black"
                                      />
                                      <rect
                                        x="6.01041"
                                        y="10.9247"
                                        width={12}
                                        height={2}
                                        rx={1}
                                        fill="black"
                                      />
                                    </svg>
                                  </span>
                                  {/*end::Svg Icon*/}
                                </div>
                                {/*end::Price*/}
                              </label>
                            </a>
                            {/*end::Radio button*/}
                            {/*end::Option*/}
                          </div>
                        </div>
                        {/*end::Radio group*/}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>) : ('')}

            {/*end::List widget 10 */}
          </div>
          {/*end::Col*/}
          {/*begin::Col*/}
          <div className="col-xl-12 card mb-5">
            {/*begin::List widget 10*/}
            <div className=" h-lg-100 mb-5">
              {/*begin::Header*/}
              <div className="card-header border-3 mb-5 pt-2 ps-6 p-0">
                {/*begin::Title*/}
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bolder text-gray-800">
                    Add security group
                  </span>
                  <span className="text-gray-400 mt-1 fw-bold fs-6">
                    Select firewalls for the server
                  </span>
                </h3>

                {/*end::Title*/}
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="mx-5 mb-3">
                {/*begin::Radio group*/}
                <div
                  className="row"
                  data-kt-buttons="true"
                >
                  <div className="col-md-12 col-lg-12 col-xxl-12 mt-5">
                    <input class="form-control" onChange={(e) => updateNewFirewall(e)} id="firewall_field" />
                  </div>
                  <input type="hidden" name="security_groups" value={securityGroups} />
                </div>
                {/*end::Radio group*/}
              </div>
              {/*end: Card Body*/}
            </div>
            {/*end::List widget 10*/}
          </div>
          {/*end::Col*/}
          <div className="col-xl-12 mb-5 d-none">
            <div className="card-header border-0 mb-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder text-gray-800">
                  Allowed IP Address(es) or Range(s)
                </span>
                <span className="text-gray-400 mt-1 fw-bold fs-6">
                  Add any IPv4 address or range that should be authorized to access this cluster.
                  <br />By default, all public and private connections are denied.
                </span>
              </h3>
            </div>
            <div
              className="row g-9 mx-5 mb-3"
              data-kt-buttons="true"
              data-kt-buttons-target="[data-kt-button]"
            >
              <div className="col-md-4 col-lg-4 col-sm-2 col-xxl-4">
                <div className="">
                  <div className="input-group mb-5">
                    <input
                      type="text"
                      name={"databaseIp[0][ip_address]"}
                      className="form-control form-control-sm"
                      placeholder="103.212.109.456"
                    />
                  </div>
                </div>
              </div>

            </div>
            {allowIp != null ? (<>
              {allowIp.length > 0 ? (<>
                {allowIp.map((value, index) => (<>
                  <div
                    className="row g-9 mx-5 mb-3"
                    data-kt-buttons="true"
                    data-kt-buttons-target="[data-kt-button]"
                  >
                    <div className="col-md-4 col-lg-4 col-sm-2 col-xxl-4">
                      <div className="">
                        <div className="input-group mb-5">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="103.212.109.456"
                            id={"clusterNode-" + index}
                            name={"databaseIp[" + (index + 1) + "][ip_address]"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 col-lg-2 col-sm-2 col-xxl-2">
                      {/*begin::Svg Icon | path: assets/media/icons/duotune/arrows/arr015.svg*/}
                      <span style={{ cursor: "pointer" }} onClick={(e) => deleteIpField(value)} className="svg-icon svg-icon-danger svg-icon-2hx">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <rect
                            opacity="0.5"
                            x={6}
                            y="17.3137"
                            width={16}
                            height={2}
                            rx={1}
                            transform="rotate(-45 6 17.3137)"
                            fill="black"
                          />
                          <rect
                            x="7.41422"
                            y={6}
                            width={16}
                            height={2}
                            rx={1}
                            transform="rotate(45 7.41422 6)"
                            fill="black"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}
                    </div>
                  </div>
                </>))}
              </>) : ('')}
            </>) : ('')}

            <div className="card-header border-0 pt-5">
              <div className="card-toolbar align-items-start flex-column">
                <a id="addnodepool" onClick={addIpField} className="btn btn-sm btn-light-primary">
                  <span className="svg-icon svg-icon-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.5"
                        x="11.364"
                        y="20.364"
                        width={16}
                        height={2}
                        rx={1}
                        transform="rotate(-90 11.364 20.364)"
                        fill="black"
                      />
                      <rect
                        x="4.36396"
                        y="11.364"
                        width={16}
                        height={2}
                        rx={1}
                        fill="black"
                      />
                    </svg>
                  </span>
                  Add An IP
                </a>
              </div>
            </div>

          </div>


          <div className="col-xl-12 card mb-5">
            <div className="card-header border-3 mb-5 pt-2 ps-6 p-0">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder text-gray-800">
                  Name Your Cluster
                </span>
                <span className="text-gray-400 mt-1 fw-bold fs-6">
                  Input the name for your database cluster
                </span>
              </h3>
            </div>
            <div className="mx-5 mb-3">
              <div
                className="row g-9"
                data-kt-buttons="true"
                data-kt-buttons-target="[data-kt-button]"
              >
                <div className="col-md-4 col-lg-4 col-xxl-4">
                  <div className="">
                    <div className="input-group mb-5">
                      <input
                        type="text"
                        name="cluster_label"
                        id="db_label"
                        className="form-control"
                        placeholder="cluster label"
                        defaultValue={"utho-" + makeid(8) + "-cluster"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-12 card mb-5">
            <div className="card-header  mb-5 pt-2 ps-6 p-0 pe-6" style={{ border: "none" }}>
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mt-3">
                  Total Cost
                </span>
                <span className="fs-xl-2x fs-2 fw-boldest">
                  <span id="currency_final"></span>
                  <span id="total_cost"></span>
                  <span id="billing_final"></span> </span>
                <span className="text-muted mt-1 fw-bold fs-7" />
              </h3>
              <div className="card-toolbar">
                <a style={{ width: "100%" }}
                  className="btn  btn-primary btn-active-light-primary mt-3" id="database-btn" onClick={addDatabase}>
                  Create Cluster &nbsp; &nbsp;
                  <span className="svg-icon svg-icon-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z"
                        fill="black"
                      />
                      <path
                        opacity="0.3"
                        d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                </a>
              </div>
            </div>
          </div>

          {/*end::Tables Widget 9*/}
        </form>
      </>) : (<><TopBarHeaderLine /><TableLoader /></>)}
    </>
  );
};


const mapStateToProps = (state) => ({
  nodeCountData: state.databaseClusterCount.nodeCountDataInfo,
  deployInfo: state.databaseClusterCount.deployClusterInfo,
});

const mapDispatchToProps = (dispatch) => ({
  updateNodeCountData: (data) => dispatch(AddDatabaseNodeCount(data)),
  updateDeployInfo: (data) => dispatch(AddDatabaseDeploy(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeployDatabaseCluster); 
