import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import UsePost from '../../../../../CustomHooks/usePost';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import copy from "copy-to-clipboard";
import TopBarHeaderLine from '../../../../../CustomHooks/TopBarHeaderLine';
import ProductsUrl from '../../../Docs/Docsdata';
import CustomDoc from '../../../../../CustomHooks/CustomDoc';
const CreateAccesskey = (props) => {
  const [pageStatus, setPageStatus] = React.useState(false)
  const [width, setWidth] = useState(window.innerWidth >= 768 ? '30%' : '70%');
  const [marginRight, setMarginRight] = useState(window.innerWidth >= 768 ? '0.823rem' : '0rem');

  const addAccesskey = async () => {
    var accesskeyid = document.querySelector("#accesskeyid")
    var submitBtn = document.querySelector("#accesskey-btn");
    const dt = toast.loading("Please wait...");
    if (submitBtn) {
      setPageStatus(true)
      if (accesskeyid.value == '') {
        setTimeout(() => {
          toast.update(dt, {
            render: "Enter the key name", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        }, 700)
      } else {
        var preContent = submitBtn.innerHTML;
        submitBtn.classList.add("disabled");
        submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
        const responseData = await UsePost('post', 'objectstorage/innoida/accesskey/create', 'accesskey-add');
        if (responseData) {
          setPageStatus(false)
          submitBtn.classList.remove("disabled");
          submitBtn.innerHTML = preContent;
          if (responseData.status === 'error') {
            toast.update(dt, {
              render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
          } else if (responseData.status === 'success') {
            toast.update(dt, {
              render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
            // props.callBackFun()
            accesskeyid.value = ''
            var copy_keys = document.querySelector("#copy_keys");
            var accesskey_copy = document.querySelector("#accesskey_copy");
            var secretkey_copy = document.querySelector("#secretkey_copy");
            if (accesskey_copy && secretkey_copy && copy_keys) {
              accesskey_copy.value = responseData.accesskey;
              secretkey_copy.value = responseData.secretkey;
              copy_keys.classList.remove("d-none");
            }
          }
        }
      }
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth >= 768 ? '30%' : '70%');
      setMarginRight(window.innerWidth >= 768 ? '0.823rem' : '0rem');
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const copyToClipboard = (key) => {
    var eleText = document.querySelector("#" + key + "_copy");
    var ele = document.querySelector("#" + key + "_copy_btn");
    if (ele && eleText && !ele.classList.contains("disabled")) {
      var copyText = ele.innerHTML;
      var copyEle = eleText.value;
      copy(copyEle);
      // ele.innerHTML = "Copied";
      ele.innerHTML = `Copied <svg
      xmlns="http://www.w3.org/2000/svg"
      width=24px
      height=24px
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9.89557 13.4982L7.79487 11.2651C7.26967 10.7068 6.38251 10.7068 5.85731 11.2651C5.37559 11.7772 5.37559 12.5757 5.85731 13.0878L9.74989 17.2257C10.1448 17.6455 10.8118 17.6455 11.2066 17.2257L18.1427 9.85252C18.6244 9.34044 18.6244 8.54191 18.1427 8.02984C17.6175 7.47154 16.7303 7.47154 16.2051 8.02984L11.061 13.4982C10.7451 13.834 10.2115 13.834 9.89557 13.4982Z"
        fill="black"
      />
    </svg>`;
      eleText.classList.add("bg-primary");
      eleText.classList.add("text-light");
      setTimeout(() => {
        eleText.classList.remove("bg-primary");
        eleText.classList.remove("text-light");
        ele.classList.remove('disabled')
        ele.innerHTML = copyText;
      }, 2000);
    }

  }
  const openDrawerCreate = () => {
    var copy_keys = document.querySelector("#copy_keys");
    if (copy_keys) {
      copy_keys.classList.add("d-none");
    }
    var drawerElement = document.querySelector("#create-keys-modal");
    var drawer_overlay = document.querySelector("#drawer_overlay");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      drawerElement.style.width = width;
      drawer_overlay.classList.toggle("d-none");
    }
  };

  const openDoc = () => {
    var drawerElement = document.querySelector("#objStroage-doc");
    var drawer_overlay = document.querySelector("#drawer_overlay_objStroage_doc");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
        drawerElement.style.width = "100%";
      } else {
        drawerElement.style.width = "50%";
      }
      drawer_overlay.classList.toggle("d-none");
    }
  };

  return (
    <>
      <div className="card-header border-2 mb-5 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder text-gray-800">Object Storage</span>
          <span className="text-gray-400 mt-1 fw-bold fs-6">
            Buckets/Access Keys
          </span>
        </h3>
        <div className="card-toolbar sphide">
          <div className="position-relative my-1" style={{ marginRight: 20 }}>
            <CustomDoc url={ProductsUrl[0]?.object_stroage + "/getting-started/"} />
          </div>
          <Link to="/objectStorage/deploy">
            <div style={{ marginRight: 20 }}>
              <a className="btn btn-sm btn-primary" >+ Create Bucket</a>
            </div>
          </Link>
          <div >
            <a className="btn btn-sm btn-primary" onClick={openDrawerCreate}>+ Create Access Keys</a>
          </div>

        </div>

      </div>
      <div id="drawer_overlay" onClick={openDrawerCreate} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>

      <div
        id="create-keys-modal"
        className="bg-body shadow drawer drawer-end"
        data-kt-drawer="true"
        data-kt-drawer-name="chat"
        data-kt-drawer-activate="true"
        data-kt-drawer-direction="end"
        data-kt-drawer-close="#close_ssh"
        data-kt-drawer-width="{default:'650px', 'md': '650px', 'sm': '300px'}"
        style={{ maxWidth: "70%", important: true }}
      >
        <div className="card w-100 rounded-0 border-0" id="kt_drawer_chat_messenger" style={{height:'max-content'}}>
          <div className="card-header pe-5 ps-7" id="kt_drawer_chat_messenger_header">
            <div className="card-title">
              <div className="d-flex justify-content-center flex-column me-3">
                <div className="fs-4 fw-bolder text-gray-900 me-1 mb-2 lh-1">
                  Create Access Key
                </div>
              </div>
            </div>
            <div className="card-toolbar">
              <div className="btn btn-sm btn-icon btn-active-light-primary" onClick={openDrawerCreate}>
                <span className="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x={6}
                      y="17.3137"
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(-45 6 17.3137)"
                      fill="black"
                    />
                    <rect
                      x="7.41422"
                      y={6}
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(45 7.41422 6)"
                      fill="black"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <div className="" id="">
            <form id="accesskey-add">
              <div className="modal-body ">
                <div className="scroll-y me-n7 pe-7">
                  <div className="mb-5 fv-row">
                    <label htmlFor="type" className="required fs-5 fw-bold mb-2">
                      {" "}
                      Key Name
                    </label>
                    <input
                      type="text"
                      id="accesskeyid"
                      name="accesskey"
                      className="form-control"
                    />
                  </div>

                </div>

                <div className="" style={{ paddingRight: marginRight }}>
                  <a style={{ cursor: "pointer" }} id="accesskey-btn" onClick={addAccesskey} className="btn btn-primary w-100">
                    <span className="indicator-label">+ Create Key</span>
                  </a>
                </div>
                <div className="mt-10 d-none" id="copy_keys">
                  {/*begin::Input group*/}
                  <div>
                    <label className="form-label mb-5">Access key</label>
                    <div className="d-flex">
                      <input
                        id="accesskey_copy"
                        type="text"
                        className="form-control form-control-solid me-3 flex-grow-1"
                        defaultValue=""
                      />
                      <a style={{ cursor: "pointer", display:'inline-block',width:'150px' }}
                        id="accesskey_copy_btn"
                        onClick={(e) => copyToClipboard('accesskey')}
                        className="btn btn-light fw-bolder flex-shrink-0"
                      >
                        Copy key
                        <span className="svg-icon svg-icon-primary svg-icon-2 ms-2" id="accesskey_copy_btn" >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              opacity="0.5"
                              d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                              fill="black"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                      </a>
                    </div>
                  </div>
                  {/*end::Input group*/}
                  {/*begin::Input group*/}
                  <div>
                    <label className="form-label mb-5">Secret key</label>
                    <div className="d-flex">
                      <input
                        id="secretkey_copy"
                        type="text"
                        className="form-control form-control-solid me-3 flex-grow-1"
                        defaultValue=""
                      />
                      <a style={{ cursor: "pointer", display:'inline-block',width:'150px'  }}
                        id="secretkey_copy_btn"
                        onClick={(e) => copyToClipboard('secretkey')}
                        className="btn btn-light fw-bolder flex-shrink-0"
                      >
                        Copy Link
                        <span className="svg-icon svg-icon-primary svg-icon-2 ms-2" id="accesskey_copy_btn" >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              opacity="0.5"
                              d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                              fill="black"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                      </a>
                    </div>
                    <div className="text-danger fs-7 mb-7 mt-5">
                      Please collect the credentials, these credentials shows only once!
                    </div>
                  </div>
                  {/*end::Input group*/}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

    </>
  );
}

export default CreateAccesskey;
