import {React} from 'react';

export default function Button(props){
    if(props && props.name && props.url){
    
    return(
        <>
        <div style={{ paddingBottom: 40, textAlign: "center" }}>
                      <a
                        href={props.url}
                        rel="noopener"
                        style={{
                          textDecoration: "none",
                          display: "inline-block",
                          textAlign: "center",
                          padding: "0.75575rem 1.3rem",
                          fontSize: "0.925rem",
                          lineHeight: "1.5",
                          borderRadius: "0.35rem",
                          color: "#ffffff",
                          backgroundColor: "#009EF7",
                          border: 0,
                          marginRight: "0.75rem!important",
                          fontWeight: "600!important",
                          outline: "none!important",
                          verticalAlign: "middle"
                        }}
                        target="_blank"
                      >
                        {props.name}
                      </a>
                    </div>
        </>
    )
        }
}