import localStorage from 'local-storage';
import {React, useEffect} from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export default function PlatformChange(){
    const platformName=localStorage.get("platformType");
    const { env } = useParams();
    const navigate = useNavigate();
    useEffect(()=>{
    if(platformName){
      if(platformName=="dev"){
        navigate("/Welcome");
       } 
    }else{ 
        if(env=="dev"){
            localStorage.set("platformType", "dev");
        }else if(env=="prod"){
            localStorage.remove("platformType");
        }
        navigate("/Welcome");
    }
},[])
    return(<></>);

}