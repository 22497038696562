import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import copy from "copy-to-clipboard";
import AccessDenied from "../../AccessDenied";
import "./cloud.css";
import LoaderGif from "../LoaderGif";
import ActionList from "./helper/ActionList";
import { connect } from "react-redux";
import { UpdateCloudPagination } from "../../../../Redux/Services/Action/actions";
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../Loaders/TableLoader";
import CustomDoc from "../../../../CustomHooks/CustomDoc";
import ProductsUrl from "../../Docs/Docsdata";
import Tooltip from "../../utils/tooltip/Tooltip";

const Cloud = (props) => {
  let nevigate = useNavigate()
  // CSS Start
  const sphide = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }

  // CSS End
  const [dataFetched, setDataFetched] = useState(false);
  const [cloudData, setCloudData] = useState(null);
  const [access, setAccess] = useState(true);
  const [pageStatus, setPageStatus] = useState(false)
  const [itemOffset, setItemOffset] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [metaData, setMetaData] = useState(null);
  const [dummyCloudData, setDummyCloudData] = useState(null)
  const [pageCount, setPageCount] = useState(1);
  const [activePage, setActivePage] = useState(0);
  const dataFetchedRef = useRef(false);
  const currentPage = useRef(0);
  const loaderFunc = () => {
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if (loader && no_record) {
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");

      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
  }

  const getCloud = async () => {
    toast.dismiss();
    const dt = toast
    setPageStatus(false)
    // //console.log("come on");
    if (props && props.paginationInfo) {
      // //console.log("come in");
      var pageCount = props.paginationInfo[0].perpage;
      var CurrPage = props.paginationInfo[0].currentPage;
      console.log(pageCount, CurrPage);
      if (pageCount && CurrPage) {
        const data = await UseApi('get', 'cloud?perpage=' + pageCount + '&page=' + CurrPage, '');
        if (data) {
          if ('status' in data) {
            if (data.status == "error") {
              // toast.update(dt, {
              //   render: data.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
              // });
              if (data.message == "Permission denied.") {
                setAccess(false);
              } else {
                setAccess(true)
              }
              setCloudData([]);
              setDummyCloudData([])
              setDataFetched(true)
              // loaderFunc();
              return;
            }
          } else {
            if (Object.keys(data).length === 0) {
              setCloudData(null);
              setDummyCloudData(null)
              toast.update(dt, {
                render: "no record found", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
              });
              setDataFetched(true)

              // loaderFunc();
            } else {
              setPageStatus(true)
              setCloudData(data.cloud);
              setDummyCloudData(data.cloud)
              setMetaData(data.meta);
              setDataFetched(true)
              toast.dismiss();
              //   toast.update(dt,{render: "Your cloud server request has been successful", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
              // });
            }
          }
        }
      }
    }
  }

  useEffect(() => {
    getCloud();
  }, [props]);

  const copyToClipboard = (id) => {
    var ip_address = document.querySelector(`#ip_address_sec${id}`)
    var ele = document.querySelector("#ipIcon_" + id);
    var eleText = document.querySelector("#ip_address" + id);
    if (ip_address && eleText && ele && !ele.classList.contains("disabled")) {
      var copyText = ele.innerHTML;
      var copyEle = eleText.innerHTML;
      copy(copyEle?.trim());
      ip_address.classList.add("disabled")
      ele.innerHTML = `<svg
      xmlns="http://www.w3.org/2000/svg"
      width=24px
      height=24px
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9.89557 13.4982L7.79487 11.2651C7.26967 10.7068 6.38251 10.7068 5.85731 11.2651C5.37559 11.7772 5.37559 12.5757 5.85731 13.0878L9.74989 17.2257C10.1448 17.6455 10.8118 17.6455 11.2066 17.2257L18.1427 9.85252C18.6244 9.34044 18.6244 8.54191 18.1427 8.02984C17.6175 7.47154 16.7303 7.47154 16.2051 8.02984L11.061 13.4982C10.7451 13.834 10.2115 13.834 9.89557 13.4982Z"
        fill="black"
      />
    </svg>`;
      // ele.classList.remove("badge-primary");
      // ele.classList.add("badge-success");
      setTimeout(() => {
        // ele.classList.add("badge-primary");
        // ele.classList.remove("badge-success");
        ip_address.classList.remove("disabled")
        ele.innerHTML = copyText;
      }, 2000);
    }

  }

  function convertTimestamp(timestamp) {
    const date = new Date(timestamp);
    // Format the date
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
      timeZone: 'UTC'
    }).format(date);

    return formattedDate.replace('GMT', '(UTC)');
  }

  function ProductInfo({ productdata }) {
    console.log(productdata);
    return (
      <>
        {productdata != null && Object.keys(productdata).length != 0 ? (productdata && productdata.map((cloud, index) => (
          <tr>
            {" "}
            <td className="p-3 d-none">
              {" "}
              <div className="d-flex flex-column w-100 me-2" >
                {" "}
                <div className="d-flex flex-stack mb-2">
                  {" "}
                  <span className=" me-2 fs-7 fw-bold">{cloud.cloudid}</span>{" "}
                </div>{" "}
              </div>{" "}
            </td>{" "}
            <td className="d-none">
              {" "}
              <div className="d-flex align-items-center sphide">
                {" "}
                <div className="symbol symbol-30px me-5">
                  {" "}
                  <img src={"/img/flag_" + cloud.dclocation.dccc + ".png"} />{" "}
                </div>{" "}
                <div className="d-flex justify-content-start flex-column">
                  {" "}
                  <a

                    className="text-dark fw-bold text-hover-primary fs-6"
                  >
                    {cloud.dclocation.location}
                  </a>{" "}
                  <span className="text-muted fw-bold text-muted d-block fs-7">
                    {cloud.dclocation.country}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
            </td>{" "}
            <td>
              <div className="d-flex align-items-start ps-9">
                {cloud.stack ? (<>
                  <div className="symbol symbol-45px me-5 text-center">
                    <img src={'/' + cloud.stack.logo_url} alt="" style={{ width: "45px", height: "45px", padding: "2px", background: "#ebebeb75" }} />
                  </div>
                </>) : (<>
                  <div className="symbol symbol-45px me-5  text-center pt-2" style={{ width: "45px", height: "45px", padding: "2px", background: "#ebebeb75" }}>
                    <span className={"fonticon_" + (cloud.image.distribution).replaceAll(" ", "")} />
                  </div>
                </>)}
                <div className="d-flex justify-content-start flex-column">
                  <Link to={"/cloud/" + cloud.cloudid}>
                    <div style={{ cursor: "pointer" }} className="text-dark fw-bolder text-hover-primary fs-6">
                      {cloud.hostname}
                    </div>
                  </Link>
                  <span className="text-muted fw-bold text-muted d-block fs-7">
                    <span className="w-10">
                      <img src={"/img/flag_" + cloud.dclocation.dccc + ".png"} style={{ width: "7%" }} /></span>{" "}
                    {cloud.dclocation.location}
                    <br/>
                    {cloud.cpu} vCPU /{(cloud.ram / 1024).toFixed(0)} GB / {cloud.disksize} GB Disk
                    {" "}
                  </span>
                </div>
              </div>
            </td>
            <td className="d-none">
              {cloud.stack ? (<>

                <div className="d-flex align-items-center">
                  {" "}
                  <div className="symbol symbol-45px me-2">
                    <img src={'/' + cloud.stack.logo_url} className="" alt="" style={{ width: "45px", height: "36px", padding: "2px", background: "#ebebeb75" }} />
                  </div>{" "}
                  <div className="d-flex justify-content-start flex-column">
                    {" "}
                    <a

                      className=" fw-bold text-hover-primary d-block fs-6"
                    >
                      {cloud.stack.title?.charAt(0).toUpperCase() + cloud.stack.title?.slice(1)}
                    </a>{" "}
                  </div>{" "}
                </div>
              </>) : (<>
                <div className="d-flex align-items-center">
                  {" "}
                  <div className="symbol symbol-45px me-2 text-center" style={{ width: "45px", height: "36px", padding: "2px", background: "#ebebeb75" }}>
                    {" "}
                    <span className={"fonticon_" + cloud.image.distribution + ""} />{" "}
                  </div>{" "}
                  <div className="d-flex justify-content-start flex-column">
                    {" "}
                    <a

                      className=" fw-bold text-hover-primary d-block fs-6"
                    >
                      {cloud.image.distribution.charAt(0).toUpperCase() + cloud.image.distribution.slice(1)}
                    </a>{" "}
                  </div>{" "}
                </div>
              </>)}
            </td>{" "}
            <td className="text-center" id={`ip_address_${index}`}>
              {" "}
              <div className="d-flex flex-column w-100 me-2">
                {" "}
                <div className=" flex-stack mb-2">
                  {" "}
                  <span className="w-100 btn btn-active-primary btn-light-primary btn-sm sphide me-2 fs-7 fw-bold"
                    onClick={() => copyToClipboard(index)}
                    htmlFor="IP Address"
                    id={"ip_address_sec" + index}
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    title="Click to copy IP Address"
                  >
                    <span id={"ip_address" + index}>{cloud?.ip != null ? cloud?.ip?.trim() : cloud?.v4private?.ip_address?.trim()}</span>&nbsp;&nbsp;
                    {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen054.svg*/}
                    <span className="svg-icon svg-icon-light svg-icon-2" id={`ipIcon_${index}`}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          opacity="0.5"
                          d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                          fill="black"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
            </td>{" "}
            <td className="text-center">
              {" "}
              <div className=" flex-column w-100 me-2">
                {" "}
                <div className=" flex-stack mb-2">
                  {" "}
                  {cloud.stack ? (<>
                    {cloud.stack.status != "Pending" ? (<>
                      <span className="w-100 btn btn-success btn-sm sphide me-2 fs-7 fw-bold" style={{ cursor: "default" }}> Active  </span>
                    </>) : (<>
                      <span className="w-100 btn btn-warning btn-sm sphide me-2 fs-7 fw-bold" style={{ cursor: "default" }}> Installing Stack  </span>
                    </>)}
                  </>) : (<>
                    {cloud.status == "Active" ? (<>
                      <span className="w-100 btn btn-success btn-sm sphide me-2 fs-7 fw-bold" style={{ cursor: "default" }}> {cloud.status}  </span>
                    </>) : (<>
                      <span className="w-100 btn btn-warning btn-sm sphide me-2 fs-7 fw-bold" style={{ cursor: "default" }}> {cloud.status}  </span>
                    </>)}
                  </>)}

                  {" "}
                </div>{" "}
              </div>{" "}
            </td>{" "}
            <td className="text-start ps-7">
              {" "}
              <div className="d-flex flex-column w-100 me-2">
                {" "}
                <div className=" flex-stack mb-2 sphide">
                  {" "}
                  <p className="fw-bold text-gray-700">Created:&nbsp;&nbsp; <span className="text-muted me-2 fs-7 fw-bold">
                    {cloud.created_at}
                  </span>
                  </p>
                  <p className="fw-bold text-gray-700">Due date: &nbsp;&nbsp;
                    <span className="text-muted me-2 fs-7 fw-bold">
                      {cloud.nextduedate}
                    </span>
                  </p>
                </div>{" "}
              </div>{" "}
            </td>{" "}
            <td className="text-center">
              {" "}
              <div className="justify-content-end flex-shrink-0">
                {" "}
                <a
                  target="_blank"
                  href={"/cloud/console/" + cloud.cloudid}
                  className="btn btn-bg-light-primary btn-active-color-primary me-1 "
                >
                  {" "}
                  <span className="svg-icon ms-2 svg-icon-2 svg-icon-primary">
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      {" "}
                      <path
                        d="M2 16C2 16.6 2.4 17 3 17H21C21.6 17 22 16.6 22 16V15H2V16Z"
                        fill="black"
                      />{" "}
                      <path
                        opacity="0.3"
                        d="M21 3H3C2.4 3 2 3.4 2 4V15H22V4C22 3.4 21.6 3 21 3Z"
                        fill="black"
                      />{" "}
                      <path opacity="0.3" d="M15 17H9V20H15V17Z" fill="black" />{" "}
                    </svg>
                  </span>{" "}
                </a>{" "}
              </div>{" "}
            </td>{" "}
            <td className="text-end">

              {" "}
              <div className="justify-content-start flex-shrink-0">
                {" "}
                <ActionList cloudid={cloud.cloudid} />
                {/* <Link to={"/cloud/"+cloud.cloudid+""}>
                <a

                  className="btn btn-primary btn-active-light-primary btn-sm me-1 me-5 " style={sphide}
                >

                  Manage ➔
                </a>{" "}
                </Link> */}
              </div>{" "}
            </td>{" "}
          </tr>
        ))) : (<>
          <tr>
            {/* <td colspan="12" className="dataTables_empty" id="loader">
              <TopBarHeaderLine />
              <TableLoader /> */}
            {/* <div className="loading-spinner"></div> */}
            {/* </td> */}
            <td colspan="12" className="" id="no_record" valign="top">
              <div className="d-flex flex-column flex-center">
                <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
              </div>
            </td>
          </tr>
        </>)}


      </>
    )
  }

  function updatePerPage(count) {
    if (props && props.updatePaginationData) {
      props.paginationInfo[0].perpage = count;
      setCloudData(null);
      getCloud();
      setPerPage(count)
    }
  }

  const handlePageClick = (event) => {
    console.log(event, "lkxkjcxkl");
    var matched = true;
    if (props.paginationInfo[0].currentPage != (parseInt(event.selected + 1))) {
      matched = false;
    }
    if (props && props.updatePaginationData) {
      props.paginationInfo[0].currentPage = event.selected + 1;
      if (!matched) {
        setCloudData(null);
        setDummyCloudData(null)
        getCloud()
      }
    }
  };

  function PaginatedItems({ itemsPerPage, ProductData }) {
    const [listData, setListData] = useState(ProductData);

    // console.log(metaData);
    if (metaData != null && metaData.totalpages) {
      // console.log(itemOffset);
      return (
        <>
          <ProductInfo productdata={listData} />
          <td colspan="12" className="ps-6">
            <ReactPaginate
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={metaData.totalpages}
              previousLabel="< previous"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
              initialPage={props.paginationInfo[0].currentPage - 1}
            />
          </td>
        </>
      );
    } else {
    }
  }
  // if(dataFetched){
  // if(access==false){
  //   return (
  //     <>
  //      <AccessDenied />
  //     </>
  //   );
  // }

  // if(cloudData!=null){
  //   if(Object.keys(cloudData).length!=0){
  const openDoc = () => {
    var drawerElement = document.querySelector("#cloud-doc");
    var drawer_overlay = document.querySelector("#drawer_overlay_cloud_doc");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
        drawerElement.style.width = "100%";
      } else {
        drawerElement.style.width = "50%";
      }
      drawer_overlay.classList.toggle("d-none");
    }
  };

  const handleSearch = (text) => {
    const endOffset = itemOffset + perPage;
    // Remove special characters from the input text
    const escapedText = text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    // Create a regular expression pattern to match the filtered text
    const regex = new RegExp(escapedText, "gi");

    // Filter the clusterData based on the regex pattern
    let data = cloudData.filter((row) => {
      console.log(row);
      // Check if any property in the row matches the regex pattern
      return Object.keys(row).some(key => {
        console.log(row[key]);
        // Exclude 'created_at' property from the search
        if (key !== 'created_at') {
          return regex.test(row[key]);
        }
        return false;
      });
    });

    // Update the state with the filtered data
    console.log(data);
    setDummyCloudData(data);
    setMetaData(Math.ceil(data.length / perPage));
  }
  if (dataFetched) {
    if (access) {
      return (
        <>
          {/* <div style={{zIndex: "109"}} id="overlay_id" className="drawer-overlay"></div> */}
          <div className="col-xl-12">
            {/*begin::Tables Widget 9*/}
            <div className="card card-xl-stretch mb-5 mb-xl-12 maindiv" id="">
              {/*begin::Header*/}
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold fs-3 mb-1">
                    Cloud Instances
                  </span> 
                    <span className="text-gray-500 fw-bold fs-7">View all your Cloud Instances here</span>
                </h3>
                <div
                  className="card-toolbar"
                >
                  <div className="position-relative my-1" style={{ marginRight: 20 }}>
                  <CustomDoc url={ProductsUrl[0]?.cloud+"/getting-started/"}/>
                  </div>
                  <div className="position-relative my-1 d-none" style={{ marginRight: 20 }}>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      data-kt-table-widget-4="search"
                      id="searchClient"
                      // value={searchTerm}
                      onChange={e => handleSearch(e.target.value)}
                      placeholder="Search"
                    />
                  </div>
                  <div className="" style={{ marginRight: 20 }}>
                    <div className="dataTables_length">
                      <label className="w-125px">
                        <select
                          onChange={e => updatePerPage(e.target.value)}
                          data-control="select2"
                          className="form-select form-select-sm form-select-solid"
                          defaultValue={10}
                          id="perPage"

                        >
                          <option value={10} selected={true}>10 Records</option>
                          {/* <option value={10}>10 Records</option> */}
                          <option value={25}>25 Records</option>
                          <option value={50}>50 Records</option>
                          <option value={100}>100 Records</option>
                        </select>
                      </label>
                    </div>
                  </div>

                  <Link to="/cloud/deploy">
                    <a
                      className="btn btn-sm btn-primary btn-active-light-primary"
                      style={{marginRight:'0.76rem'}}
                    >
                      {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
                      <span className="svg-icon svg-icon-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <rect
                            opacity="0.5"
                            x="11.364"
                            y="20.364"
                            width={16}
                            height={2}
                            rx={1}
                            transform="rotate(-90 11.364 20.364)"
                            fill="black"
                          />
                          <rect
                            x="4.36396"
                            y="11.364"
                            width={16}
                            height={2}
                            rx={1}
                            fill="black"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}Deploy New
                    </a>
                  </Link>
                </div>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              {/*begin::Table container*/}

              <div className="table-responsive">
                {/*begin::Table*/}
                <table
                  id="clouddata"
                  className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
                >
                  {/*begin::Table head*/}
                  <thead className="bg-secondary">
                    <tr className="fw-bold ">
                      <th className="w-25px text-center d-none">#</th>
                      <th className="w-100px sphide text-center d-none">Data Centers</th>
                      <th className="w-40 text-start ps-9">Name</th>
                      <th className="w-100px text-center d-none">OS/App</th>
                      <th className="w-15 text-center">IPv4</th>
                      <th className="w-15 text-center">Status</th>
                      <th className="w-100px text-start sphide ps-10">Created_at/Due date</th>
                      <th className="w-10 text-center">Console</th>
                      <th className="w-10 text-center" />
                    </tr>
                  </thead>
                  {/*end::Table head*/}
                  {cloudData !== null ? (<>
                    {access == false ? (<>
                      <tr>
                        <td colspan={12}>
                          <AccessDenied />
                        </td>
                      </tr>
                    </>) : (<>
                      {Object.keys(cloudData).length != 0 ? (<>
                        <PaginatedItems itemsPerPage={perPage} ProductData={dummyCloudData} />
                      </>) : (<>
                        <tr>
                          <td colspan={12}>
                            <div className="card-body p-0 mt-10">
                              {/*begin::Illustration*/}
                              <div className="text-center px-4 mt-10">
                                <span className="mw-100 mh-300px">
                                  <svg
                                    viewBox="0 0 64 64"
                                    width="234px"
                                    height="234px"
                                    version={1.0}
                                    id="Layer_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    enableBackground="new 0 0 64 64"
                                    xmlSpace="preserve"
                                    fill="#21325B"
                                    stroke="#21325B"
                                  >
                                    <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                                    <g id="SVGRepo_iconCarrier">
                                      {" "}
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        fill="#21325B"
                                        d="M53.802,25.104C52.003,24.4,50.05,24,48,24c-0.553,0-1-0.447-1-1 s0.447-1,1-1c1.841,0,3.607,0.297,5.278,0.812C50.992,14.287,43.246,8,34,8c-7.532,0-14.079,4.172-17.49,10.324 c1.515,0.393,2.89,1.133,4.037,2.128c0,0,1.182,1.028,0.433,1.777s-1.847-0.363-1.847-0.363c-1.142-0.957-2.556-1.593-4.106-1.792 C14.689,20.03,14.349,20,14,20c-4.418,0-8,3.582-8,8c0,1.1,0.228,2.145,0.632,3.098C8.286,30.404,10.092,30,12,30 c0.553,0,1,0.447,1,1s-0.447,1-1,1c-1.585,0-3.091,0.318-4.474,0.879c-0.123,0.05-0.243,0.105-0.364,0.158 c-0.389,0.174-0.772,0.356-1.138,0.568C2.426,35.675,0,39.551,0,44c0,6.627,5.373,12,12,12h36c8.837,0,16-7.164,16-16 C64,33.214,59.767,27.43,53.802,25.104z M23,45c-0.553,0-1-0.447-1-1c0-2.762-1.119-5.262-2.929-7.071c0,0-0.993-0.913-0.243-1.647 s1.657,0.233,1.657,0.233C22.656,37.687,24,40.687,24,44C24,44.553,23.553,45,23,45z M57,41c-0.553,0-1-0.447-1-1 c0-4.418-3.582-8-8-8c-0.553,0-1-0.447-1-1s0.447-1,1-1c5.522,0,10,4.477,10,10C58,40.553,57.553,41,57,41z"
                                      />{" "}
                                    </g>
                                  </svg>

                                </span>
                              </div>
                              {/*end::Illustration*/}
                              {/*begin::Wrapper*/}
                              <div className="card-px text-center py-5 mb-20">
                                {/*begin::Title*/}
                                <h1 className="fs-2x fw-bolder mb-5">Cloud Server</h1>
                                <h2 className="fs-1x fw-bolder mb-5 text-muted">
                                  Deploy your first server
                                </h2>
                                {/*end::Title*/}
                                {/*begin::Description*/}
                                <p className="text-gray-400 fs-4 fw-bold mb-10">
                                  Deploy cloud servers with ease and take your cloud journey to the next level.<br /> Start enjoying the benefits from Utho's robust and scalable server solutions.
                                </p>
                                {/*end::Description*/}
                                {/*begin::Action*/}
                                <Link to="/cloud/deploy">
                                  <a
                                    href="#"
                                    className="btn btn-primary mb-10"
                                  >
                                    Create Cloud Server
                                  </a>
                                </Link>
                                {/*end::Action*/}
                              </div>
                              {/*end::Wrapper*/}
                            </div>
                          </td>
                        </tr>
                      </>)}
                    </>)}
                  </>) : (<>
                    <tr>
                      <td colspan={12}>
                        <TableLoader />
                        <TopBarHeaderLine />
                      </td>
                    </tr>

                  </>)}



                </table>
                {/*end::Table*/}
              </div>
              {/*end::Table container*/}
              {/*begin::Body*/}
            </div>
            {/*end::Tables Widget 9*/}
          </div>
         </>
      )
    } else {
      return (
        <>
          <AccessDenied />
        </>
      )
    }
  } else {
    return (
      <>
        <TableLoader />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  paginationInfo: state.cloudList.cloudPaginationInfo,
});

const mapDispatchToProps = (dispatch) => ({
  updatePaginationData: (data) => dispatch(UpdateCloudPagination(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cloud);
