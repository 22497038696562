import React from 'react';

const Incidents = () => {
    return (
        <>
        <div id="" className="card">
           {/*begin::Card header*/}
      <div
        className="card-header border-0 cursor-pointer"
        role="button"
        data-bs-toggle="collapse"
        data-bs-target="#kt_account_deactivate"
        aria-expanded="true"
        aria-controls="kt_account_deactivate"
      >
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">Current Alerts</h3>
        </div>
      </div>
      {/*end::Card header*/}
      <div className="table-responsive mb-10">
      {/*begin::Table*/}
      <table 
        className="table  table-row-dashed table-row-gray-400 align-middle gs-0 gy-4"
      >
        {/*begin::Table head*/}
        <thead className="bg-secondary">
          <tr className="fw-bold "> 
            <th className="w-50px text-start" style={{paddingLeft:"20px"}}>Type</th>
            <th className="w-50px">Comparison</th>
            <th className="w-50px sphide">Start Time</th>
            <th className="w-50px text-start">End Time</th>
            
          </tr>
        </thead>
        {/*end::Table head*/} 
        <tbody className='border'>
          {/* {diskInfo?(<>
          {diskInfo.length==0?(<></>):(<> 
          {diskInfo.map((value,index)=>(<> 
          <tr> 
            <td className='text-center' style={{paddingLeft:"20px"}}><span class="me-2 fs-7 fw-bold sphide">{value.interface}</span></td>
            <td><span class="me-2 fs-7 fw-bold sphide">{(((parseInt(value.in)/1024)/1024)/1024).toFixed(2)} GB</span></td>
            <td><span class="me-2 fs-7 fw-bold sphide">{(((parseInt(value.out)/1024)/1024)/1024).toFixed(2)} GB</span></td>
            <td><span class="me-2 fs-7 fw-bold sphide">{(((parseInt(value.total)/1024)/1024)/1024).toFixed(2)} GB</span></td>
            <td><span class="me-2 fs-7 fw-bold sphide">{(((parseInt(value.in_speed)/1024)/1024)/1024).toFixed(2)} GB</span></td>
            <td><span class="me-2 fs-7 fw-bold sphide">{(((parseInt(value.out_speed)/1024)/1024)/1024).toFixed(2)} GB</span></td>
            <td><span class="me-2 fs-7 fw-bold sphide">{(((parseInt(value.total_speed)/1024)/1024)/1024).toFixed(2)} GB</span></td>
            <td> {" "}
              <div className="d-flex flex-column w-100 me-2">
                {" "}
                <div className=" flex-stack mb-2 sphide">
                  {" "}
                  <p>Total:&nbsp;&nbsp; <span className="text-muted me-2 fs-7 fw-bold">
                    {value.in_packets.total}
                  </span>
                  </p>
                  <p>Speed: &nbsp;&nbsp;
                  <span className="text-primary me-2 fs-7 fw-bold">
                  {value.in_packets.speed}
                  </span>
                  </p>
                </div>{" "}
              </div>{" "}</td>
              <td> {" "}
              <div className="d-flex flex-column w-100 me-2">
                {" "}
                <div className=" flex-stack mb-2 sphide">
                  {" "}
                  <p>Total:&nbsp;&nbsp; <span className="text-muted me-2 fs-7 fw-bold">
                    {value.out_packets.total}
                  </span>
                  </p>
                  <p>Speed: &nbsp;&nbsp;
                  <span className="text-primary me-2 fs-7 fw-bold">
                  {value.out_packets.speed}
                  </span>
                  </p>
                </div>{" "}
              </div>{" "}</td>
              <td> {" "}
              <div className="d-flex flex-column w-100 me-2">
                {" "}
                <div className=" flex-stack mb-2 sphide">
                  {" "}
                  <p>Total:&nbsp;&nbsp; <span className="text-muted me-2 fs-7 fw-bold">
                    {value.total_packets.total}
                  </span>
                  </p>
                  <p>Speed: &nbsp;&nbsp;
                  <span className="text-primary me-2 fs-7 fw-bold">
                  {value.total_packets.speed}
                  </span>
                  </p>
                </div>{" "}
              </div>{" "}</td>
          </tr>
          </>))}
          </>)}
          </>):('')} */}
        </tbody>
     </table>
      {/*end::Table*/}
    </div>
        </div> 
        </>
    );
}

export default Incidents;
