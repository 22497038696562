import { DEPLOY_AUTO_SCALING } from "../../../../Redux/Services/constants";
// Create a new Date object to get the current timestamp
const currentDate = new Date();

// Format the date and time
const formattedDate = currentDate.toISOString().split('T')[0]; // YYYY-MM-DD

// Get hours and minutes in 24-hour format
const hours = currentDate.getHours().toString().padStart(2, '0'); // 01-23
const minutes = currentDate.getMinutes().toString().padStart(2, '0'); // 00-59

const formattedTime = `${hours}:${minutes}`;
const makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

const initialState={
    autoScalingData:[{name:"Auto-scaling-"+makeid(8)+".utho",os_disk_size:80, dcslug:"innoida", minsize:"1", maxsize:"5", desiredsize:"1", planid:"10045", planname:"basic", instance_templateid:"none", image_id:"", image_name:"", public_ip_enabled:true, vpc:"", load_balancers:"", security_groups:"", policies:[{policyname:"Policy-16H2jh", policytype:"cpu", compare:"above", value:"90", adjust:"1", period:"5m",cooldown:"300"}], schedules:[]}]
}
// {name:"Schedule-3P28Ngs", desiredsize:"3", recurrence:"Once",recurrence_duration:"Once", recurrence_week:"Monday", start_date: new Date(), selectedTime:formattedTime, selectedDate:formattedDate}
export default function AutoScalingReducer(state=initialState, action){

    switch (action.type) {
        case DEPLOY_AUTO_SCALING:
            
            return (
                {autoScalingData:action.data}
            );
    
        default:
            return state;
    }
}