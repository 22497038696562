import {React} from 'react';

export default function ImageSec(){
    return(<>
    <a
  target="_blank"
  style={{ textDecoration: "none"}}
  href="https://github.com/konsav/email-templates/"
>
  <img
    border={0}
    vspace={0}
    hspace={0}
    src="https://utho.com/images/logo_black.png"
    alt="Please enable images to view this content"
    title="Hero Image"
    width={560}
    style={{
      width: "100%",
      maxWidth: 560,
      color: "#000000",
      fontSize: 13,
      marginBottom: "20px",
      padding: 0,
      outline: "none",
      textDecoration: "none",
      msInterpolationMode: "bicubic",
      border: "none",
      display: "block"
    }}
  />
</a>

    </>)
}