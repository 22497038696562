import {React} from 'react'; 
import LogoSection from '../components/LogoSection';
import BodySection from '../components/BodySection';
import FooterSection from '../components/FooterSection';
export default function Custom(){
    return(<>
        <style
          dangerouslySetInnerHTML={{ __html: "html,body { padding: 0; margin:0; }" }}
        />
        <div
          style={{
            fontFamily: "Arial,Helvetica,sans-serif",
            lineHeight: "1.5",
            fontWeight: "normal",
            fontSize: 15,
            color: "#2F3044",
            minHeight: "100%",
            margin: 0,
            padding: 0,
            width: "100%",
            backgroundColor: "#edf2f7"
          }}
        >
          <br />
          <table
            align="center"
            border={0}
            cellPadding={0}
            cellSpacing={0}
            width="100%"
            style={{
              borderCollapse: "collapse",
              margin: "0 auto",
              padding: 0,
              maxWidth: 600
            }}
          >
            <tbody>
            
            <LogoSection logo="	https://utho.com/assets/media/partners/v2-tech.png" /> 
            <BodySection />
            <FooterSection address="Floor 5, 450 Avenue of the Red Field, SF, 10050, USA." year="2023" name="Utho Cloud" />
             
            </tbody>
          </table>
        </div>
      </>
      )
}