import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Search from '../../../../utils/search/Search';
import TableLoader from '../../../../Loaders/TableLoader';

function DistroTab(props) {
  const { osid, distroData, userData, selectOne, selectOs, currentDistribution, currentImage, currentImageCost } = props;
  useEffect(() => {
    // if (osid==undefined || osid==null) throw new Error("Missing prop: osid"); 
    if (distroData == undefined || distroData == null) throw new Error("Missing prop: distroData");
    if (userData == undefined || userData == null) throw new Error("Missing prop: userData");
    if (selectOne == undefined || selectOne == null) throw new Error("Missing prop: selectOne");
    if (selectOs == undefined || selectOs == null) throw new Error("Missing prop: selectOs");
  }, [distroData, selectOne, selectOs]);

  const [operatingSystems, setOperatingSystems] = useState(distroData)

  useEffect(() => {
    if (osid) {
      selectOne("Os", currentDistribution);
      selectOs(currentImage, currentDistribution, currentImageCost);
    }
  }, [operatingSystems])

  const closeVersionDropdown = () => {
    var drawer_overlay = document.querySelector("#os_drawer_overlay");
    var versionDropdown = document.querySelectorAll([".deploy_osversioncontainer"]);

    if (drawer_overlay) {
      drawer_overlay.classList.add('d-none');
    }
    if (versionDropdown) {
      versionDropdown.forEach((clist) => {
        clist.classList.remove("d-block");
      });
    }
  }
  const openVersionDropdown = (distribution) => {
    var versionDropdown = document.querySelectorAll([".deploy_osversioncontainer"]);
    var drawer_overlay = document.querySelector("#os_drawer_overlay");
    if (drawer_overlay) {
      drawer_overlay.classList.remove('d-none');
    }
    if (versionDropdown) {
      versionDropdown.forEach((versionTab) => {
        versionTab.classList.remove("d-block");
      });
    }
    const os_version_list_ = document.querySelector("#os_version_list_" + distribution);
    if (os_version_list_) {
      os_version_list_.classList.add("d-block");
    }
  };
  return (
    <>
      <div
        id="distros"
        className="row g-3"
        data-kt-buttons="true"
        data-kt-buttons-target="[data-kt-button]"
      >
        <Search results={distroData} onSearch={setOperatingSystems} fields={['distribution']} searchPlaceholder="operating system" />
        {operatingSystems != null ? (<>
          {operatingSystems.length > 0 ? (
            <>{operatingSystems.map((value, index) => (
              <>
                {/*begin::Col*/}
                <div
                  className="col-md-4 col-lg-4 col-xxl-4"
                  style={{ height: "73.06px !important" }}
                >
                  <input
                    type="radio"
                    name="image_OS"
                    id={"select_" + value.distro}
                    defaultValue=""
                    className="deploy_checkbox distro_radio Os_radio"
                  />
                  <label
                    onClick={() => openVersionDropdown(value.distro)}
                    //className="label versionOs btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start"
                    className=" label versionOs text-start border-1 border-gray-300 border px-6 py-2"
                    id={"versionOs_" + value.distro}
                    style={{ position: "relative" }}
                  >
                    <span className="ms-5">
                      {/*begin::Item*/}
                      <div className="d-flex  ">
                        {/*begin::Text*/}
                        <div className="flex-grow-1">
                          <div className="row">
                            <div className="col-4">
                              {/*begin::Avatar*/}
                              <div className="symbol symbol-50px me-5">
                                <span
                                  className={
                                    "fonticon_" +
                                    value.distro
                                  }
                                />
                              </div>
                              {/*end::Avatar*/}
                            </div>
                            <div className="col-8">
                              <p className="text-dark fw-bolder text-hover-primary fs-6">
                                {value.distribution}

                                <span
                                  className="text-muted d-block fw-bold deploy_checkbox_line2 osversionselectmultiple w-100p sphide2"
                                  id={
                                    "osversion_" +
                                    value.distro
                                  }
                                >
                                  Select Version
                                </span>
                              </p>
                            </div>
                          </div>
                          <span
                            className="deploy_osversioncontainer bg-white p-5 border-left-right-bottom"
                            style={{
                              position: "absolute",
                              top: "100%",
                              left: 0,
                              width: "100%",
                              zIndex: 99,
                              display: "none",
                            }}
                            id={
                              "os_version_list_" +
                              value.distro
                            }
                          >
                            {value.images &&
                              value.images.map(
                                (value2, index2) => (
                                  <>
                                    <span
                                      className="deploy_osversionselector alert alert-primary p-3"
                                      data-image={
                                        value2.image
                                      }
                                      data-oscost={
                                        value2.cost
                                      }
                                      data-title={
                                        value2.distro
                                      }
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        selectOne("Os", value.distribution);
                                        selectOs(
                                          value2.image,
                                          value2.distro,
                                          value2.cost,
                                          value2.id
                                        ); closeVersionDropdown()
                                      }}
                                    >
                                      {value2.version}&nbsp;
                                      {value2.cost > 0 ? (
                                        <>
                                          <span className="badge badge-success">
                                            {userData?.currencyprefix}{value2.cost}/mon
                                          </span>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </>
                                )
                              )}
                          </span>
                        </div>
                        {/*end::Text*/}
                      </div>
                      {/*end::Item*/}
                    </span>

                    {/*begin::Ribbon*/}
                    <div
                      className="d-none tickMarkOs ribbon ribbon-triangle ribbon-top-start border-primary"
                      id={"tickMarkOs-" + value.distro}
                    >
                      {/*begin::Ribbon icon*/}
                      <div className="ribbon-icon mt-n5">
                        <i className="bi bi-check2 fs-2 text-white" />
                      </div>
                      {/*end::Ribbon icon*/}
                    </div>
                    {/*end::Ribbon*/}
                  </label>
                </div>
                {/*end::Col*/}
              </>
            ))}
              <div id="os_drawer_overlay" onClick={closeVersionDropdown} className="drawer-overlay d-none" style={{ zIndex: 98, width: "100%", backgroundColor: "revert" }}></div>
            </>
          ) : (
            <>
              <p className='text-gray-400 fw-bold fs-6 text-center'>No operating system found</p>
            </>
          )}
        </>) : (<TableLoader />)}
      </div>
    </>
  )
}

DistroTab.propTypes = {
  distroData: PropTypes.arrayOf(PropTypes.object).isRequired,
  userData: PropTypes.arrayOf(PropTypes.object).isRequired,
  osid: PropTypes.number.isRequired,
  selectOne: PropTypes.func.isRequired,
  selectOs: PropTypes.func.isRequired
};

export default DistroTab

