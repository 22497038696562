import React, { useEffect, useState } from 'react';
import TableLoader from '../../../../Loaders/TableLoader';

const GrafanaViewerBandwidthOverview = (props) => {
  // Function to get a cookie by its name
   
   const [isLoading, setIsLoading] = useState(true);
  const [dataForm, setDataFrom]= useState('');
  const [cloudid, setCloudid] = useState(null);

  useState(()=>{
      if(props && props.dataTime){
        if(props.dataTime=="-0"){
          setDataFrom("");
        }else{
        setDataFrom(props.dataTime+"M");
        }
        setCloudid(props.cloudid);
      }
  },[props.dataTime])

  const getCookie = (name) => {
    const cookieArray = document.cookie.split('; ');
    const cookie = cookieArray.find((row) => row.startsWith(name + '='));
    return cookie ? cookie.split('=')[1] : null;
  };

  // Function to set the iframe URL
  const setIframeSrc = () => {
    const apiKey = getCookie('microhost_api_auth');
    if (apiKey && cloudid) {
      const iframeUrl = `https://mon.utho.com/d/InstanceBandwidth/instance-bandwidth?orgId=1&var-host_id=${cloudid}&kiosk&theme=light&from=now${dataForm}%2FM&to=now${dataForm}%2FM&refresh=10s&apikey=${apiKey}`;
      document.getElementById('grafanaframeBandwidth').src = iframeUrl;
      setTimeout(()=>{
        setIsLoading(false);
      },1000)
     } else {
      console.error('API key not found');
    }
  };

  useEffect(() => {
    // Set the iframe source after the component is mounted
    setIframeSrc();

    // Add event listener for blocking keys
    const grafanaFrame = document.getElementById('grafanaframeBandwidth');
    const keydownListener = (event) => {
      // Block user from exiting Grafana UI easily (esc and F keys)
      if (event.keyCode === 27 || event.keyCode === 70) {
        event.preventDefault();
        event.stopPropagation();
        return false;
      }
    };
    if(grafanaFrame && grafanaFrame.contentWindow){
    grafanaFrame.contentWindow.addEventListener('keydown', keydownListener, true);
      }
    // Clean up the event listener when the component unmounts
    

    return () => {
      if(grafanaFrame && grafanaFrame.contentWindow){
      grafanaFrame.contentWindow.removeEventListener('keydown', keydownListener, true);
      }
    };
  }, [dataForm]); // Empty dependency array ensures this effect runs once after initial render

  return (
    <div style={{ height: '370px', margin: 0, padding: 0, overflow: 'hidden' }}>
      {isLoading && <TableLoader />}
      <iframe
      
        id="grafanaframeBandwidth"
        title="Grafana Viewer"
        style={{ border: 'none', width: '100%', height: '100%' }}
      >
        {/* Iframe content will be loaded here */}
      </iframe>
    </div>
  );
};

export default GrafanaViewerBandwidthOverview;
