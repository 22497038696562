import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import copy from "copy-to-clipboard";
import Access from "./storage/tabs/Access";
import FileFolder from "./storage/tabs/FileFolder";
import Ssl from "./storage/tabs/Ssl";
import UseApi from "../../../../CustomHooks/useApi";
import Permissions from "./storage/tabs/Permissions";
import UsePost from "../../../../CustomHooks/usePost";
import CreateAccesskey from "./accessKey/CreateAccesskey";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import Overview from "./storage/tabs/Overview";
import CustomDoc from "../../../../CustomHooks/CustomDoc";
import ProductsUrl from "../../Docs/Docsdata";

export default function ManageObjectStorage() {

  const [pageStatus, setPageStatus] = useState(false)
  var query = window.location.href;
  var vars = query.split("/");
  var id = vars[5];

  const navigate = useNavigate();
  const navRedirect = () => {
    setTimeout(() => {
      navigate('/objectStorage');
    }, 2000);

  }
  const dataFetchedRef = useRef(false);
  const [objectData, setObjectData] = useState(null);

  const getObjectStorage = async () => {
    const data = await UseApi('get', 'objectstorage/innoida/bucket/' + id, '');
    //console.log(data);

    if (data.status == "error") {
      setObjectData(null);
      swal({
        title: "No Record found ",
        text: "We are unable to find record with this Id: " + id + "!",
        icon: "warning",
        button: "OK",
      });
      navRedirect();
    } else {
      if (data) {
        setObjectData(data);
      }
    }
  }

  const deleteObject = async () => {
    var submitBtn = document.querySelector("#destroy-btn");
    var confirmString = document.querySelector("#confirm");
    if (confirmString) {
      if (confirmString?.value?.trim() != "I am aware this action will delete data and bucket permanently") {
        const dt = toast.loading("");
        toast.update(dt, {
          render: "Please provide the correct confirmation", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        return;
      }
    }
    if (submitBtn) {

      toast.dismiss();
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this object storage!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {
            setPageStatus(true)
            var preContent = submitBtn.innerHTML;
            submitBtn.classList.add("disabled");
            submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';

            const dt = toast.loading("Object Storage deleting...");
            const dnsp = await UseApi('DELETE', 'objectstorage/innoida/bucket/' + id + '/delete', '');
            //console.log(dnsp);
            if (dnsp.status == 'error') {
              setPageStatus(false)
              toast.update(dt, {
                render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
              });
            } else {
              setPageStatus(false)
              toast.update(dt, {
                render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
              });
              submitBtn.classList.remove("disabled");
              submitBtn.innerHTML = preContent;
              navRedirect();
            }
          }
        });
    }

  }

  useEffect(() => {
    // if (dataFetchedRef.current) return;
    // dataFetchedRef.current = true;
    getObjectStorage();
  }, []);

  const [isActivePermission, setIsActivePermission] = useState(false);

  function togglePermission(data) {
    setIsActivePermission(data);
  }

  const openDrawerBucketUrl = () => {
    var drawerElement = document.querySelector("#bucketUrl-modal");
    var drawer_overlay = document.querySelector("#bucket_drawer_overlay");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      drawerElement.style.width = '500px';
      drawer_overlay.classList.toggle("d-none");
    }
  }

  const copyToClipboard = () => {
    var eleText = document.querySelector("#file_url");
    var ele = document.querySelector("#file_url_copy_btn");
    if (ele && eleText) {
      var copyText = eleText.innerText; // Use innerText to get the text content
      var innerEle = ele.innerHTML;
      var tempInput = document.createElement("textarea");
      document.body.appendChild(tempInput);
      tempInput.value = copyText;
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      ele.classList.add('disabled')
      ele.innerHTML = `<svg
      xmlns="http://www.w3.org/2000/svg"
      width=24px
      height=24px
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9.89557 13.4982L7.79487 11.2651C7.26967 10.7068 6.38251 10.7068 5.85731 11.2651C5.37559 11.7772 5.37559 12.5757 5.85731 13.0878L9.74989 17.2257C10.1448 17.6455 10.8118 17.6455 11.2066 17.2257L18.1427 9.85252C18.6244 9.34044 18.6244 8.54191 18.1427 8.02984C17.6175 7.47154 16.7303 7.47154 16.2051 8.02984L11.061 13.4982C10.7451 13.834 10.2115 13.834 9.89557 13.4982Z"
        fill="black"
      />
    </svg>`;
      eleText.classList.add("bg-primary");
      eleText.classList.add("text-light");
      ele.style.pointerEvents = "none";
      setTimeout(() => {
        eleText.classList.remove("bg-primary");
        eleText.classList.remove("text-light");
        ele.classList.remove('disabled')
        ele.style.pointerEvents = "auto";
        ele.innerHTML = innerEle; // Set your original content here
      }, 2000);
    }
  };

  function roundSize(sizeStr) {
 // Split the input into number and unit (e.g., "34.83 KB" -> ["34.83", "KB"])
 const [size, unit] = sizeStr.split(' ');

 // Convert the number part into a float and round it
 const roundedSize = Math.round(parseFloat(size));

 // Return the rounded number along with the unit (e.g., "35 KB")
 return `${roundedSize} ${unit}`;
  }

  console.log(objectData?.size,objectData);
  
  if (objectData != null) {
    return (
      <>
        <div className="card mb-6 mb-xl-9 ">
          {/* <CreateAccesskey /> */}
        </div>
        <div className="card mb-6 mb-xl-9 ">

          <div className="card-body pt-9 pb-0 ">
            <div className="d-flex flex-wrap flex-sm-nowrap mb-6">
              <div
                className="d-flex flex-center flex-shrink-0 bg-light rounded w-55px h-55px w-lg-55px h-lg-55px me-7 mb-4"
                id="imageDist"
              >
                <span className="w-50">
                  <svg
                    width={44}
                    height={44}
                    viewBox="0 0 28 22"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <title>dropbox [#158]</title>
                    <desc>Created with Sketch.</desc>
                    <defs />
                    <g
                      id="Page-1"
                      stroke="none"
                      strokeWidth={1}
                      fill="none"
                      fillRule="evenodd"
                    >
                      <g
                        id="Dribbble-Light-Preview"
                        transform="translate(-300.000000, -7479.000000)"
                        fill="#494b74"
                      >
                        <g
                          id="icons"
                          transform="translate(56.000000, 160.000000)"
                        >
                          <path
                            d="M254.012,7330.74707 L249.825,7334.24637 L248,7333.0687 L248,7334.38937 L254,7338 L260,7334.38937 L260,7333.0687 L258.187,7334.24637 L254.012,7330.74707 Z M264,7322.92318 L258.117,7319 L254,7322.50952 L259.932,7326.25089 L264,7322.92318 Z M254,7329.99226 L258.117,7333.50177 L264,7329.57859 L259.932,7326.25089 L254,7329.99226 Z M244,7329.57859 L249.883,7333.50177 L254,7329.99226 L248.068,7326.25089 L244,7329.57859 Z M254,7322.50952 L248.068,7326.25089 L244,7322.92318 L249.883,7319 L254,7322.50952 Z"
                            id="dropbox-[#158]"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
              </div>
              <div className="flex-grow-1">
                <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center mb-1" id="showHost">
                      <a
                        className="text-gray-800 text-hover-primary fs-2 fw-bolder me-3"
                        id="hostname"
                      >
                        {objectData.name}
                      </a>
                      <a></a>
                    </div>
                    <div className="d-flex flex-wrap fw-bold mb-4 fs-5 text-gray-400">
                    <a 
                    className="d-flex align-items-center text-gray-400 me-3 mb-2"
                    >
                    {/*begin::Svg Icon | path: icons/duotune/communication/com006.svg*/}
                    <span className="svg-icon svg-icon-4 me-2 pb-1">
                    <img src={"/img/flag_" + objectData.dclocation.dccc + ".png"} style={{ width: "16px" }} /> 
                    </span>
                    {/*end::Svg Icon*/}
                    {objectData.dclocation.location}
                    </a>
                     <a 
                    className="d-flex align-items-center text-gray-400 me-5 mb-2"
                    >
                    {/*begin::Svg Icon | path: icons/duotune/communication/com006.svg*/}
                    <span className="svg-icon svg-icon-4 me-1">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z"
                            fill="black"
                          />
                          <path
                            opacity="0.3"
                            d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z"
                            fill="black"
                          />
                        </svg> 
                    </span>
                    {/*end::Svg Icon*/}

                      {objectData.current_size ? (<>Size - &nbsp;{roundSize(objectData.current_size)} / {(parseInt(objectData.size)/1024).toFixed(0)} GB</>) : ("")}

                      </a>
                      <a 
                    className="d-flex align-items-center text-gray-400 me-5 mb-2"
                    >
                    {/*begin::Svg Icon | path: icons/duotune/communication/com006.svg*/}
                    <span className="svg-icon svg-icon-2 me-1">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          opacity="0.3"
                          d="M5 8.04999L11.8 11.95V19.85L5 15.85V8.04999Z"
                          fill="black"
                        />
                        <path
                          d="M20.1 6.65L12.3 2.15C12 1.95 11.6 1.95 11.3 2.15L3.5 6.65C3.2 6.85 3 7.15 3 7.45V16.45C3 16.75 3.2 17.15 3.5 17.25L11.3 21.75C11.5 21.85 11.6 21.85 11.8 21.85C12 21.85 12.1 21.85 12.3 21.75L20.1 17.25C20.4 17.05 20.6 16.75 20.6 16.45V7.45C20.6 7.15 20.4 6.75 20.1 6.65ZM5 15.85V7.95L11.8 4.05L18.6 7.95L11.8 11.95V19.85L5 15.85Z"
                          fill="black"
                        />
                      </svg> 
                    </span>
                    {/*end::Svg Icon*/}

                     Count - {objectData.object_count}

                      </a>
                    </div>
                  </div>
                  <div className="d-flex" id="console">
                    <div className="d-flex flex-stack mb-5"> 
                      {/*begin::Label*/}
                      {objectData.status == "active" || objectData.status == "Active" ? (<><div className="btn btn-sm btn-success fw-bold ms-5">Active</div></>) :
                        (<><div className="btn btn-sm btn-success fw-bold ms-5">Active</div></>)}

                      {/*end::Label*/}
                    </div>
                  </div>
                </div>

              </div>
            </div>
            {/* <div className="sepa/rator" /> */}
            <div className="d-flex flex-wrap flex-stack p-0 mb-3">
              {/*begin::Wrapper*/}
              <div className="d-flex flex-column flex-grow-1 pe-3 d-none">
                {/*begin::Stats*/}
                {/*end::Stats*/}
              </div>
              {/*end::Wrapper*/}
              {/*begin::Progress*/}
              <div className="d-flex align-items-start w-50 flex-column mt-3">
                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3">
                  <div className="fs-6 text-gray-800 fw-bolder mb-3">Bucket Source URL &nbsp;

                    {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen044.svg*/}
                    <span onClick={openDrawerBucketUrl} data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="tooltip-dark" title="Click to view the source details" className="svg-icon svg-icon-muted svg-icon-2" style={{ cursor: "pointer" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={10}
                          fill="black"
                        />
                        <rect
                          x={11}
                          y={14}
                          width={7}
                          height={2}
                          rx={1}
                          transform="rotate(-90 11 14)"
                          fill="black"
                        />
                        <rect
                          x={11}
                          y={17}
                          width={2}
                          height={2}
                          rx={1}
                          transform="rotate(-90 11 17)"
                          fill="black"
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}
                  </div>
                  <div className="d-flex align-items-center flex-wrap">
                    {/*begin::Input*/}
                    <div id="kt_clipboard_4" className="me-5 fw-bold text-gray-600 sphide">
                      <span id="file_url" onClick={openDrawerBucketUrl}>
                        https://{objectData.name}.{objectData.dclocation && objectData.dclocation.dc ? (objectData.dclocation.dc) : ('')}.utho.io/</span><span className="text-primary"> + file path</span>

                      {/*end::Input*/}
                      {/*begin::Svg Icon | path: icons/duotune/general/gen054.svg*/}
                      <span id="file_url_copy_btn" onClick={copyToClipboard} className="svg-icon svg-icon-2 ms-1" style={{ cursor: "pointer" }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            opacity="0.5"
                            d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                            fill="black"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}
                    </div>
                  </div>
                </div>

              </div>
              {/*end::Progress*/}
            </div>
            <div className="separator" />
            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-trakt_charts_widget_1_chartnsparent fs-5 fw-bolder">
              <li className="nav-item">
                <a
                  className="nav-link text-active-primary py-5 active me-6"
                  data-bs-toggle="tab"
                  href="#overview-tab"
                >
                  Overview
                </a>
              </li>
              <li className="nav-item">
                <a onClick={(e) => togglePermission(false)}
                  className="nav-link text-active-primary py-5 me-6"
                  data-bs-toggle="tab"
                  href="#object-tab"
                >
                  Object
                </a>
              </li>
              <li className="nav-item">
                <a onClick={(e) => togglePermission(false)}
                  className="nav-link text-active-primary py-5 me-6 sphide"
                  data-bs-toggle="tab"
                  href="#connection-details"
                >
                  Access Control
                </a>
              </li>
              <li className="nav-item" >
                <a onClick={(e) => togglePermission(true)}
                  className="nav-link text-active-primary py-5 me-6 sphide"
                  data-bs-toggle="tab"
                  href="#permissions-tab"
                >
                  Permissions
                </a>
              </li>

              <li className="nav-item d-none">
                <a onClick={(e) => togglePermission(false)}
                  className="nav-link text-active-primary py-5 me-6"
                  data-bs-toggle="tab"
                  href="#sslCertificate-tab"
                >
                  SSL/TLS
                </a>
              </li>
              <li className="nav-item">
                <a onClick={(e) => togglePermission(false)}
                  className="nav-link text-active-primary py-5 me-6"
                  data-bs-toggle="tab"
                  href="#destroy"
                >
                  Destroy
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="tab-content" id="myTabContent">
          <Overview />
          <Access data={objectData} updateObject={getObjectStorage} />
          <FileFolder data={objectData} updateObject={getObjectStorage} />
          {/* <Ssl data={objectData} updateObject={getObjectStorage} /> */}
          <div className="tab-pane fade" id="permissions-tab" role="tabpanel">
            {isActivePermission ? (<>
              <Permissions data={objectData} updateObject={getObjectStorage} />
            </>) : (<>
              {/* <Permissions data={objectData} updateObject={getObjectStorage}/> */}
            </>)}
          </div>
          <div className="tab-pane fade" id="destroy" role="tabpanel">
            <div className="card">
              <div
                className="card-header border-0 cursor-pointer"
                role="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_account_deactivate"
                aria-expanded="true"
                aria-controls="kt_account_deactivate"
              >
                <div className="card-title m-0">
                  <h3 className="fw-bold m-0">Destroy Cluster</h3>
                </div>
                <div className="card-toolbar">
                <CustomDoc url={ProductsUrl[0]?.object_stroage+"/manage-object-stroage/destroy/"}/>
              </div>
              </div>
              <div id="kt_account_settings_deactivate" className="collapse show">
                <form id="destroyform" className="form">
                  <div className="card-body border-top p-9">
                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
                      <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <rect
                            opacity="0.3"
                            x={2}
                            y={2}
                            width={20}
                            height={20}
                            rx={10}
                            fill="black"
                          />
                          <rect
                            x={11}
                            y={14}
                            width={7}
                            height={2}
                            rx={1}
                            transform="rotate(-90 11 14)"
                            fill="black"
                          />
                          <rect
                            x={11}
                            y={17}
                            width={2}
                            height={2}
                            rx={1}
                            transform="rotate(-90 11 17)"
                            fill="black"
                          />
                        </svg>
                      </span>
                      <div className="d-flex flex-stack flex-grow-1">
                        <div className="fw-bold">
                          <h4 className="text-gray-900 fw-bold">
                            This will destroy your object Storage.
                          </h4>
                          <div className="fs-6 text-gray-700">
                            Note: This option will delete your object storage from
                            the server for permanently and this not be able to
                            undo.
                          </div>
                        </div>
                      </div>
                    </div>
                    <form id="destroyform" className="form">
                      <div className="mb-10 mt-20 d-none">
                        <label htmlFor="size" className="required form-label">
                          Please fill text in below form to delete Object Storage "I am aware this
                          action will delete data and bucket permanently"
                        </label>
                        <input
                          type="text"
                          className="form-control d-none"
                          name="confirm"
                          id="confirm"
                          defaultValue="I am aware this action will delete data and bucket permanently"
                          value="I am aware this action will delete data and bucket permanently"
                          placeholder="I am aware this action will delete data and bucket permanently"
                        />
                      </div>
                    </form>
                  </div>
                  <div
                    className="card-footer d-flex justify-content-end py-6 px-9"
                    id="destroy_btn"
                  >
                    <button
                      onClick={deleteObject}
                      id="destroy-btn"
                      type="button"
                      className="btn btn-sm btn-danger fw-bold"
                    >
                      Destroy
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div id="bucket_drawer_overlay" onClick={openDrawerBucketUrl} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>

          <div
            id="bucketUrl-modal"
            className="bg-body shadow drawer drawer-end"
            // data-kt-drawer="true"
            // data-kt-drawer-name="chat"
            // data-kt-drawer-activate="true"
            // data-kt-drawer-direction="end"
            data-kt-drawer-close="#close_ssh"
            data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
            style={{ maxWidth: "70%", important: true, zIndex: 110 }}
          >
            {/*begin::Messenger*/}
            <div
              className="card rounded-0 w-100 border-0"
              id="kt_drawer_chat_messenger"
            >
              {/*begin::Card header*/}
              <div
                className="card-header pe-5"
                id="kt_drawer_chat_messenger_header"
              >
                {/*begin::Title*/}
                <div className="card-title">
                  {/*begin::User*/}
                  <div className="d-flex justify-content-center flex-column me-3">
                    <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                      Bucket URL Info
                    </a>
                  </div>
                  {/*end::User*/}
                </div>
                {/*end::Title*/}
                {/*begin::Card toolbar*/}
                <div className="card-toolbar">
                  {/*begin::Close*/}
                  <div
                    onClick={openDrawerBucketUrl}
                    className="btn btn-sm btn-icon btn-active-light-primary"
                    id="close_ssh"
                  >
                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                    <span className="svg-icon svg-icon-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.5"
                          x={6}
                          y="17.3137"
                          width={16}
                          height={2}
                          rx={1}
                          transform="rotate(-45 6 17.3137)"
                          fill="black"
                        />
                        <rect
                          x="7.41422"
                          y={6}
                          width={16}
                          height={2}
                          rx={1}
                          transform="rotate(45 7.41422 6)"
                          fill="black"
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}
                  </div>
                  {/*end::Close*/}
                </div>
                {/*end::Card toolbar*/}
              </div>
              {/*end::Card header*/}
              {/*begin::Card body*/}
              <div className="" id="">
                {/*begin::Form*/}
                <form name="routing" id="importkey" className="form">
                  {/*begin::Modal body*/}
                  <div className="modal-body ">
                    {/*begin::Scroll*/}
                    <div
                      className="scroll-y me-n7 pe-7"
                      id="kt_modal_create_api_key_scroll"
                      data-kt-scroll="true"
                      data-kt-scroll-activate="{default: false, lg: true}"
                      data-kt-scroll-max-height="auto"
                      data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                      data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                      data-kt-scroll-offset="300px"
                    >
                      {/*begin::Input group*/}
                      <div>
                        <label className="form-label mb-5">Here are the details of Bucket Url</label>

                        <div className="text-muted fs-6 mb-7 mt-0">
                          <ol>
                            <li className="mb-3"> <span className="text-gray-500 fw-bolder">Overview :</span> This URL helps us find files in our storage.</li>
                            <li className="mb-3"><span className="text-gray-500 fw-bolder">Bucket Name :</span> "<span className="text-primary">{objectData.name}</span>." is the storage container.</li>
                            <li className="mb-3"><span className="text-gray-500 fw-bolder">DC Zone :</span> "<span className="text-primary">{objectData.dclocation ? (objectData.dclocation.dc) : ('')}</span>" shows the data center zone.</li>
                            <li className="mb-3"><span className="text-gray-500 fw-bolder">Endpoint :</span> "<span className="text-primary">utho.io</span>" is where our storage is located.</li>
                            <li className="mb-3"><span className="text-gray-500 fw-bolder">File Path :</span> "<span className="text-primary">file name</span>" is the specific file we're looking for.</li>
                          </ol>

                        </div>
                        {/*begin::Alert*/}
                        <div className="alert alert-dismissible bg-light-primary d-flex flex-column flex-sm-row p-5 mb-10">
                          {/*begin::Icon*/}
                          <span className="svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                opacity="0.3"
                                d="M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z"
                                fill="black"
                              />
                              <path
                                d="M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z"
                                fill="black"
                              />
                            </svg>

                          </span>
                          {/*end::Icon*/}
                          {/*begin::Wrapper*/}
                          <div className="d-flex flex-column w-100">
                            {/*begin::Title*/}
                            {/* <h4 className="fw-bold">Help</h4> */}
                            {/*end::Title*/}
                            {/*begin::Content*/}
                            <span className="fw-bold fs-7 text-gray-600">
                              Each part helps locate and manage files in our storage. If you're unsure, ask our support team <a className="text-primary">support@utho.com</a> or <Link to="/ticket"><span className="text-primary">raise a ticket here</span></Link>.
                            </span>
                            {/*end::Content*/}
                          </div>
                          {/*end::Wrapper*/}
                        </div>
                        {/*end::Alert*/}
                      </div>
                      {/*end::Input group*/}
                    </div>
                    {/*end::Scroll*/}
                  </div>
                  {/*end::Modal body*/}
                </form>
                {/*end::Form*/}
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Messenger*/}
          </div>

          {pageStatus &&
            <TopBarHeaderLine />}
        </div>

      </>
    );
  } else {
    return (<>
      <TopBarHeaderLine />
    </>);
  }
}

