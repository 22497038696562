const PermissionInfoModal = (props) => {
    console.log(props);
    
    return (
        <div
            className="modal fade"
            id={"permissionInfo-modal"}
            tabIndex={-1}
            aria-hidden="true"
        >
            {/*begin::Modal dialog*/}
            <div className="modal-dialog modal-dialog-centered mw-650px">
                {/*begin::Modal content*/}
                <div className="modal-content">
                    {/*begin::Modal header*/}
                    <div
                        className="modal-header ps-18 d-none"
                        id="kt_modal_create_api_key_header"
                    >
                        <h2 >Add Target</h2>
                    </div>
                    <div className="modal-body">
                        <p className="fs-4">
                            Comma-separated list of tags to apply to the user. Currently{" "}
                            <a
                                // href="https://www.rabbitmq.com/management.html#permissions"
                                target="_blank"
                            >
                                supported by the management plugin
                            </a>
                            :
                        </p>
                        <dl>
                            <dt className="fw-bolder fs-4 mt-3">Management</dt>
                            <dd style={{ marginLeft: 'revert' }} className="fs-5 text-dark-300">User can access the management plugin.</dd>
                            <dt className="fw-bolder fs-4 mt-3">Policymaker</dt>
                            <dd style={{ marginLeft: 'revert' }} className="fs-5 text-dark-300">
                                User can access the management plugin and manage policies and parameters
                                for the vhosts they have access to.
                            </dd>
                            <dt className="fw-bolder fs-4 mt-3">Monitoring</dt>
                            <dd style={{ marginLeft: 'revert' }} className="fs-5 text-dark-300">
                                User can access the management plugin and see all connections and
                                channels, as well as node-related information.
                            </dd>
                            <dt className="fw-bolder fs-4 mt-3">Administrator</dt>
                            <dd style={{ marginLeft: 'revert' }} className="fs-5 text-dark-300">
                                User can do everything monitoring can do, manage users, vhosts, and
                                permissions, close other users' connections, and manage policies and
                                parameters for all vhosts.
                            </dd>
                        </dl>
                        <p className="fs-5 text-dark-300">
                            Note that you can set any tag here; the links for the above four tags are
                            just for convenience.
                        </p>
                    </div>

                    <div className="modal-footer flex-start" id="cloudFooter">
                        {/*begin::Button*/}
                        <button
                            id="addserver"
                            type="button"
                            onClick={() => props.openModal()}
                            // onClick={addBackendServer}
                            className="btn btn-primary w-20"
                        >
                            Close
                        </button>
                        {/*end::Button*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PermissionInfoModal