import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom/dist";
import UsePost from "../../../../CustomHooks/usePost";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UseApi from "../../../../CustomHooks/useApi";
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../Loaders/TableLoader";
import VpcComponent from "../../utils/vpc/VpcComponent";
import Dczone from "../../utils/dczone/Dczone";
import NetworkType from "../../utils/networkType/NetworkType";
import Firewall from "../../utils/firewall/Firewall";

function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue(value => value + 1);
}

export default function LoadbalancerDeploy() {
  const [pageStatus, setPageStatus] = useState(false)
  const forceUpdate = useForceUpdate();
  const [adcdata, setAdcdata] = useState([]);
  const [location, setLocation] = useState("innoida")
  const [ddcdata, setDdcdata] = useState([]);
  const [currentDcslug, setCurrentDcslug] = useState("innoida");
  const [firewallData, setFirewallData] = useState(null);
  const [firewallLoader, setFirewalLoader] = useState(true);
  const firewalld = { "firewallid": 0 }
  const dataFetchedRef = useRef();

  let navigate = useNavigate();
  const deployLoadbalancer = async () => {
    const dcSlug = document.querySelectorAll('input[name="dcslug"]');
    const lbName = document.querySelector('#name');
    var submitBtn = document.querySelector("#loadbalancer-btn");
    if (submitBtn) {
      setPageStatus(true)
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      toast.dismiss();
      const dt = toast.loading("Please wait...");
      if (dcSlug) {
        let checkedValue = null;
        for (const radioButton of dcSlug) {
          if (radioButton.checked) {
            checkedValue = radioButton.value;
            break;
          }
        }
        if (!checkedValue) {
          toast.update(dt, {
            render: "Please select data center!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          submitBtn.innerHTML = preContent;
          submitBtn.classList.remove("disabled");
          return;
        }
      }
      if (lbName) {
        if (lbName.value == "") {
          toast.update(dt, {
            render: "Loabalancer name must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          submitBtn.innerHTML = preContent;
          submitBtn.classList.remove("disabled");
          return;
        }
      }


      const responseData = await UsePost('post', 'loadbalancer', 'addloadbalancer');
      if (responseData) {
        setPageStatus(false)
        submitBtn.innerHTML = preContent;
        submitBtn.classList.remove("disabled");
        if (responseData.status === 'error') {
          toast.update(dt, {
            render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        } else if (responseData.status === 'success') {
          toast.update(dt, {
            render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          window.onDeployEvents('deploy_loadbalancer');
          setTimeout(() => {
            navigate("/loadbalancer/" + responseData.loadbalancerid);
          }, 1500)
        }
      }
    }
  }

  const getFirewall = async () => {
    try {
      const data = await UseApi('get', 'firewall', '');
      console.log(data,"sdjisjdois");
      
      if (!data) return;

      if (data.status === "error") {
        toast.warning(data.message || "Permission denied.", { autoClose: 5000 });
        setFirewallData([]);
        setFirewalLoader(false)
        return;
      }

      const firewalls = data?.firewalls || [];
      console.log(firewalls);
      
      setFirewallData(firewalls);
      setFirewalLoader(false)

      if (firewalls.length === 0) {
        toast.warning("No records found", { autoClose: 5000 });
      } else {
        toast.dismiss();
      }
    } catch (error) {
      console.error("Error fetching firewall data:", error);
    }
  };

  console.log(firewallData);
  

  const selectTickMark = (serviceid, name, input_name, custom = 'no') => {
    var currentPlan = document.querySelector("#tickMark" + name + "-" + serviceid);
    var currentMain = document.querySelector("#border" + name + "-" + serviceid);
    const inputElement = document.getElementById(input_name + serviceid);


    if (currentPlan && currentMain) {
      // Toggle the tick mark visibility
      if (custom == 'no') {
        currentPlan.classList.toggle("d-none");
      } else {
        currentPlan.classList.remove("d-none");
      }

      // Toggle the border's active class
      if (currentPlan.classList.contains("d-none")) {
        inputElement.checked = false;
        inputElement.classList.remove('deploy_checkbox')
        currentMain.classList.remove("activeBorder");

      } else {
        inputElement.checked = true;
        currentMain.classList.add("activeBorder");
        inputElement.classList.add('deploy_checkbox')
      }

      // If the tick mark is now visible, remove the tick mark from other elements
      if (!currentPlan.classList.contains("d-none")) {
        var allTickmarks = document.querySelectorAll(".tickMark" + name);
        allTickmarks.forEach((tickMark) => {
          if (tickMark !== currentPlan) {
            tickMark.classList.add("d-none");
          }
        });
        // Remove active border from other elements
        var mainTickMarks = document.querySelectorAll(".border" + name);
        mainTickMarks.forEach((border) => {
          if (border !== currentMain) {
            border.classList.remove("activeBorder");
          }
        });
        // console.log("All Tickmarks:", allTickmarks);
        // console.log("Main TickMarks:", mainTickMarks);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", function (event) {
      event.stopPropagation();
      var query = window.location.href;
      var vars = query.split("/");
      var action = vars[4];
      //console.log(vars);
      if (event.key == "Enter" && action == "deploy" && vars[3] == "loadbalancer") {
        deployLoadbalancer();
      }
    });
  }, []);
  const getDczone = async () => {
    // setAdcdata([]);
    // setDdcdata([]);
    const data = await UseApi('get', 'dczones', '');
    if (Object.keys(data).length === 0) {
      setAdcdata([]);
      setDdcdata([]);
    } else {

      data.dczones.map(async (value, index) => {
        if (value.status != 'active') {
          fetch("https://api.ipify.org?format=json")
            .then(response => response.json())
            .then(data => {
              var server_ip = data.ip;
              if (server_ip) {
                if (server_ip !== '103.209.144.51' || server_ip !== '103.209.144.66' || server_ip !==
                  '103.209.145.208' || server_ip !== '103.209.144.81' || server_ip !== '103.209.144.82' ||
                  server_ip !== '103.209.144.83' || server_ip !== '103.127.30.91' || server_ip !==
                  '103.209.144.60' || server_ip !== '103.127.30.239' || server_ip !== '103.127.28.52') {
                  ddcdata.push(value);
                }
              }
            })
            .catch(error => console.error(error));
          // var client=await fetch(`https://geolocation-db.com/json/`)
          // .then(res => res.json());
          //  if(client){
          // var server_ip=client.IPv4;
          // if(server_ip !== '103.209.144.51' || server_ip !== '103.209.144.66' || server_ip !==
          //   '103.209.145.208' || server_ip !== '103.209.144.81' || server_ip !== '103.209.144.82' ||
          //   server_ip !== '103.209.144.83' || server_ip !== '103.127.30.91' || server_ip !==
          //   '103.209.144.60' || server_ip !== '103.127.30.239' || server_ip !== '103.127.28.52'){
          //     ddcdata.push(value); 
          //   } 
          //   forceUpdate();
          // }
          forceUpdate();
        } else {
          adcdata.push(value);
          forceUpdate();
        }
      })

    }
  }
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getDczone();
    getFirewall()
    selectDc("innoida")
  }, []);

  const selectDc = async (slug) => {
    setCurrentDcslug(null);
    var dczone_slug = document.querySelector("#dcslug" + slug);
    if (dczone_slug) {
      dczone_slug.value = slug;
    }
    setLocation(slug)
    var dcSelect = document.querySelectorAll([".tickMark"]);
    var currentDc = document.querySelector("#tickMark-" + slug);
    if (dcSelect) {
      dcSelect.forEach((clist) => {
        clist.classList.add("d-none");
      });
    }
    if (currentDc) {
      currentDc.classList.remove("d-none");
    }

    setTimeout(() => {
      if (currentDc) {
        currentDc.click();
      }
      setCurrentDcslug(slug);
    }, 1000)
  }


  return (
    <>
      {adcdata.length > 0 ? (<>
        {/*begin::Tables Widget 9*/}
        <form id="addloadbalancer" className="form">
          <div className="col-xl-12 card mb-5">
            {/*begin::data center*/}
            <Dczone adcdata={adcdata} ddcdata={ddcdata} selectDc={selectDc} location={location} />
            {/* End data center */}
          </div>

          <div className="col-xl-12 card mb-5">
            {/*begin::Header*/}
            <div className="card-header border-3 mb-5 pt-2">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label  fw-bold fs-3 mb-1">
                  Load Balancer Setting
                </span>
                <span className="text-muted mt-1 fw-bold fs-7">
                  Load Balancer Algorithm
                </span>
              </h3>
            </div>
            {/*end::Header*/}
            <div className="mx-9 pb-5">
              {/*begin::Row*/}
              <div
                className="row g-9"
                data-kt-buttons="true"
                data-kt-buttons-target="[data-kt-button]"
              >
                <div className="col-md-4 col-lg-4 col-xxl-4">
                  <div className="mb-5 fv-row">
                    <label className="required fs-5 fw-bold mb-2">Type</label>
                    <select className="form-select" name="type" defaultValue="application">
                      <option value="application">Application</option>
                      <option value="network">Network</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-8 col-lg-8 col-xxl-8"></div>
              </div>
              {/*end::Row*/}
            </div>
          </div>
          {/* <NetworkType /> */}
          {currentDcslug != null ? (<>
            <VpcComponent dcslug={currentDcslug} />
          </>) : (<><TableLoader /></>)}
          <div className="col-xl-12 card mb-5">
            <Firewall firewallData={firewallData} selectTickMark={selectTickMark} firewalld={firewalld} firewallLoader={firewallLoader} />
          </div>
          <div className="col-xl-12 card mb-5">
            {/*begin::Header*/}
            <div className="card-header border-3 mb-5 pt-2">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label  fw-bold fs-3 mb-1">
                  Load Balancer Name
                </span>
              </h3>
            </div>
            <div className="mx-9 pb-5">
              {/*begin::Row*/}
              <div
                className="row g-9"
                data-kt-buttons="true"
                data-kt-buttons-target="[data-kt-button]"
              >
                <div className="col-md-6 col-lg-12 col-xxl-6">
                  <div className="h-lg-100">
                    <input
                      type="text"
                      className="form-control  "
                      name="name"
                      id="name"
                      placeholder="loadbalancer_app"
                    />
                    {/*end: Card Body*/}
                  </div>
                </div>
              </div>
              {/*end::Row*/}
            </div>

            <button
              type="button"
              // style={{ width: "100%" }}
              className="btn btn-primary m-0 mb-5 mx-9"
              onClick={deployLoadbalancer}
              id="loadbalancer-btn"
            >
              Create Load Balancer +
            </button>
          </div>
          {/* {pageStatus &&
        <TopBarHeaderLine/><TableLoader />} */}
        </form>
      </>) : (<><TopBarHeaderLine /><TableLoader /></>)}
    </>

  )
}