import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UsePost from "../../../../CustomHooks/usePost";
import swal from "sweetalert";
import ReactPaginate from 'react-paginate';
import AccessDenied from "../../AccessDenied";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../Loaders/TableLoader";
import UsePostData from "../../../../CustomHooks/usePostData";
import SslDeploy from "./SslDeploy";
import ProductsUrl from "../../Docs/Docsdata";
import CustomDoc from "../../../../CustomHooks/CustomDoc";
export default function Ssl() {
  var currentUrl = window.location.href;
  const [dataFetched, setDataFetched] = useState(false);
  const [access, setAccess] = useState(true);
  const [perPage, setPerPage] = useState(5);
  const [activePage, setActivePage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [sslData, setSslData] = useState(null);
  const [sslDummyData, setSslDummyData] = useState(null);
  const [updateBtn, setUpdateBtn] = useState(false);
  const [actionType, setActionType] = useState("page")
  const dataFetchedRef = useRef(false);
  const loaderFunc = () => {
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if (loader && no_record) {
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");

      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
  }
  const getSsl = async () => {
    const datas = await UseApi('get', 'certificates', '');
    if (datas) {
      if (datas.rcode == "error") {
        const dt = toast.loading("SSL loading...");
        toast.update(dt, {
          render: datas.message,
          type: "warning",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setDataFetched(true)
        setSslData([]);
        setSslDummyData([])
        // loaderFunc();
        return;
      } else {
        setDataFetched(true)
        setSslData(datas.certificates);
        setSslDummyData(datas.certificates)
      }
      if (datas.status == "error") {
        if (datas.message == "Permission denied.") {
          setDataFetched(true)
          setAccess(false);
        } else {
          setDataFetched(true)
          setAccess(true)
          setSslData(datas.certificates);
          setSslDummyData(datas.certificates)
        }
      }
    }
  }



  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getSsl();
    const urlSearchParams = new URLSearchParams(new URL(currentUrl).search);
    const actionValue = urlSearchParams.get('action');
    // console.log(actionValue, "actionValue");
    if (actionValue) {
      // console.log(actionValue, "hxzcushdij");
      setActionType((old) => old = actionValue);
    }
  }, []);
  // console.log(actionType);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    var currentUrl = window.location.href;
    const urlSearchParams = new URLSearchParams(new URL(currentUrl).search);
    const actionValue = urlSearchParams.get('action');
    if (actionValue == "deploy") {
      openDrawer();
      getSsl();
    }
  }, []);

  const openDrawer = () => {
    var certificateChain_field = document.querySelector('#certificateChain_field')
    var privateKey_field = document.querySelector('#privateKey_field')
    var certificate_field = document.querySelector('#certificate_field')
    var name_field = document.querySelector('#name_field')
    var drawerElement = document.querySelector("#add_ssl-modal");
    var drawer_overlay = document.querySelector("#drawer_overlay");
    
    if(certificateChain_field && privateKey_field && certificate_field && name_field){
      certificateChain_field.value = ''
      privateKey_field.value = ''
      certificate_field.value = ''
      name_field.value = ''
    }
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
        drawerElement.style.width = "100%";
      } else {
        drawerElement.style.width = "70%";
      }
      drawer_overlay.classList.toggle("d-none");
    }
    const urlParams = new URLSearchParams(window.location.search);
    const actionParam = urlParams.get('action');
    if (actionParam === 'deploy') {
      const currentURL = window.location.href;
      const updatedURL = currentURL.split('?')[0]; // Remove everything after '?'
      window.history.replaceState(null, '', updatedURL);
      setActionType('page')
    }
  };
  const addSslCertificate = async () => {
    var submitBtn = document.querySelector("#ssl-btn");

    if (submitBtn) {
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      const dt = toast.loading("Please wait...");
      const responseData = await UsePost('post', 'certificates', 'sslCreate');
      if (responseData) {
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
        if (responseData.status === 'error') {
          toast.update(dt, {
            render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        } else if (responseData.status === 'success') {
          toast.update(dt, {
            render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          window.onDeployEvents('add_ssl_certificate');
          openDrawer();
          getSsl();
          // navigate("/ssl");
        }
      }
    }
  }
  const deleteSsl = async (sid) => {
    toast.dismiss();
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this SSL Certificate!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        var submitBtn = document.querySelector("#delete-ssl-" + sid);

        if (submitBtn) {
          var preContent = submitBtn.innerHTML;
          submitBtn.classList.add("disabled");
          submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
          const dt = toast.loading("Please wait...");
          const responseData = await UsePostData('delete', 'certificates/' + sid, {});
          if (responseData) {
            submitBtn.classList.remove("disabled");
            submitBtn.innerHTML = preContent;
            if (responseData.status === 'error') {
              toast.update(dt, {
                render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
              });
            } else if (responseData.status === 'success') {
              toast.update(dt, {
                render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
              });
              getSsl();
            }
          }
        }
      }
    })
  }
  function formatMessageTime(dateString) {
    const date = new Date(dateString);
    const currentDate = new Date();
    const timeDiff = Math.abs(currentDate.getTime() - date.getTime());
    const minutes = Math.floor(timeDiff / (1000 * 60));
    const hours = Math.floor(minutes / 60);

    if (minutes <= 1) {
      return 'Just now';
    } else if (minutes < 30) {
      return `${minutes} mins ago`;
    } else if (minutes < 90) {
      return 'An hour ago';
    } else if (hours < 24) {
      const formattedTime = formatTime(date);
      return `Today at ${formattedTime}`;
    } else if (hours < 48) {
      const formattedTime = formatTime(date);
      return `Yesterday at ${formattedTime}`;
    } else {
      const formattedDate = formatDate(date);
      const formattedTime = formatTime(date);
      return `${formattedDate} at ${formattedTime}`;
    }
  }
  function formatTime(date) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}${period}`;
  }
  function formatDate(date) {
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
  }
  function ProductInfo({ productdata }) {
    return (
      <>
        {productdata != null && Object.keys(productdata).length != 0 ? (productdata && productdata.map((value, index) => (
          <tr>
            {/*begisetSslDatan::ID*/}
            {/*end::ID*/}
            {/*begin::User=*/}
            <td className="text-start ps-9">
              {/*begin::User details*/}
              <div className="d-flex flex-column">
                <a className="text-gray-800 fw-bolder text-hover-primary mb-1"   >
                  {value.name}
                </a>
              </div>
              {/*begin::User details*/}
            </td>
            {/* <td className="text-center">
              <span className="fw-bold text-center"></span>
            </td> */}
            {/*end::User=*/}
            <td className="text-center">
              {/*begin::User details*/}
              <div className="d-flex flex-column">
                <span className="text-muted fw-bold">{value.dns_names}</span>
              </div>
              {/*begin::User details*/}
            </td>
            <td className="text-center">
              {/*begin::User details*/}
              <div className="d-flex flex-column">
                <span className="text-muted fw-bold"><div dangerouslySetInnerHTML={{ __html: value.issuer }} /></span>
              </div>
              {/*begin::User details*/}
            </td>
            <td className="text-center">

              <div className="d-flex flex-column w-100 me-2">

                <div className="flex-stack mb-2">

                  <span className="text-muted me-2 fs-7 fw-bold">
                    {(value.expire_at)}
                  </span>
                </div>
              </div>
            </td>
            <td className="text-center">

              <div className="d-flex flex-column w-100 me-2">

                <div className="flex-stack mb-2">

                  <span className="text-muted me-2 fs-7 fw-bold">
                    {(value.created_at)}
                  </span>
                </div>
              </div>
            </td>
            <td className="text-center ps-20">
              <div className="d-flex justify-content-center flex-shrink-0">
                <a
                  onClick={(e) => deleteSsl(value.id)}
                  id={"delete-ssl-" + value.id}
                  className="btn btn-danger btn-active-light-danger btn-sm"
                >

                  Delete

                </a>
              </div>
            </td>
          </tr>
        ))) : (<>
          <tr>
            {/* <td colspan="12" className="dataTables_empty" id="loader">
              <div className="loading-spinner"></div>
            </td> */}
            <td colspan="12" className="" id="no_record" valign="top">
              <div className="d-flex flex-column flex-center">
                <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
              </div>
            </td>
          </tr>
        </>)}

      </>
    )

  }
  function PaginatedItems({ itemsPerPage, ProductData }) {
    var endOffset = endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(ProductData.length / itemsPerPage));

    const handlePageClick = (event) => {
      setActivePage((old) => old = event.selected);
      const newOffset = event.selected * itemsPerPage;
      setItemOffset(newOffset);
    };
    return (
      <>
        <ProductInfo productdata={ProductData?.slice(itemOffset, endOffset)} />
        <td colspan="12" className="ps-3">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            initialPage={activePage}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </td>
      </>
    );
  }

  const handleSearch = (text) => {
    const endOffset = itemOffset + perPage;
    const data = sslData?.filter(row => {
      return Object.values(row).some(value => {
        if (value != null) {
          if (value.toString().toLowerCase().includes(text.toLowerCase())) {
            return value;
          }
        }
      });
    });
    setSslData(data);;
    setPageCount(Math.ceil(data.length / perPage));
  }

  if (dataFetched) {
    if (access) {
      if (sslData != null) {
        return (
          <>
            {sslData.length != 0 ? (<>
              <div className="col-xl-12">
                {/*begin::Tables Widget 9*/}
                <div className="card card-xl-stretch mb-5 mb-xl-12">
                  {/*begin::Header*/}
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">
                        SSL Certificates
                      </span>
                      <span className="text-muted mt-1 fw-bold fs-7" />
                    </h3>
                    <div
                      className="card-toolbar"
                    >
                      <div className="position-relative my-1" style={{ marginRight: 20 }}>
                        <CustomDoc url={ProductsUrl[0]?.ssl}/>
                    </div>
                      <div className="position-relative my-1" style={{ marginRight: 20 }}>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          data-kt-table-widget-4="search"
                          id="searchClient"
                          style={{ padding: '0.6rem 0.6rem' }}
                          // value={searchTerm}
                          onChange={e => handleSearch(e.target.value)}
                          placeholder="Search"
                        />
                      </div>
                      <div style={{ marginRight: 20 }}>
                        <div className="dataTables_length">
                          <label className="w-125px">
                            <select
                              onChange={e => setPerPage(e.target.value)}
                              style={{ padding: '0.6rem 0.6rem' }}
                              //data-control="select2"
                              className="form-select form-select-sm form-select-solid"
                              defaultValue={5}
                              id="perPage"

                            >
                              <option value={5} selected="">
                                5 Records
                              </option>
                              <option value={10}>10 Records</option>
                              <option value={25}>25 Records</option>
                              <option value={50}>50 Records</option>
                              <option value={100}>100 Records</option>
                            </select>
                          </label>
                        </div>
                      </div>
                      {actionType != "page" ? (<>
                        <SslDeploy type={actionType} openDrawer={openDrawer} addSslCertificate={addSslCertificate} setActionType={setActionType} />
                      </>) : ('')}
                      <a
                        onClick={openDrawer}
                        className="btn btn-sm btn-primary btn-active-light-primary"
                        style={{ padding: '0.7rem 1rem' }}
                      >
                        {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
                        <span className="svg-icon svg-icon-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              opacity="0.5"
                              x="11.364"
                              y="20.364"
                              width={16}
                              height={2}
                              rx={1}
                              transform="rotate(-90 11.364 20.364)"
                              fill="black"
                            />
                            <rect
                              x="4.36396"
                              y="11.364"
                              width={16}
                              height={2}
                              rx={1}
                              fill="black"
                            />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}Add SSL Certificate
                      </a>
                    </div>
                  </div>
                  {/*end::Header*/}
                  {/*begin::Body*/}
                  {/*begin::Table container*/}
                  <div className="table-responsive">
                    {/*begin::Table*/}
                    <table
                      id="ssl_data"
                      className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
                    >
                      {/*begin::Table head*/}
                      <thead className="bg-secondary">
                        <tr className="fw-bold ">
                          <th className="w-100px text-start ps-9">Name</th>
                          <th className="w-50px text-center"></th>
                          {/* <th className="w-150px text-center sphide">DNS Name</th> */}
                          <th className="w-150px text-center">Issuer</th>
                          <th className="w-150px text-center sphide">Expire_at</th>
                          <th className="w-150px text-center sphide">Created_at</th>
                          <th className="w-150px text-center ps-20">Action</th>
                        </tr>
                      </thead>
                      {/*end::Table head*/}
                      {/*begin::Table body*/}
                      <tbody id="list-ssl">
                        {sslDummyData != null ? (<>
                          <PaginatedItems
                            itemsPerPage={perPage}
                            ProductData={sslDummyData} 
                            />
                        </>) : (<>
                          <tr>
                            {/* <td colspan="12" className="dataTables_empty" id="loader">
                              <div className="loading-spinner"></div>
                            </td> */}
                            <td colspan="12" className="" id="no_record" valign="top">
                              <div className="d-flex flex-column flex-center">
                                <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                              </div>
                            </td>
                          </tr>
                        </>)}

                      </tbody>
                      {/*end::Table body*/}
                    </table>
                    {/*end::Table*/}
                  </div>
                  {/*end::Table container*/}
                  {/*begin::Body*/}
                </div>
                {/*end::Tables Widget 9*/}
              </div>
            </>) : (<>
              <div className="col-xl-12">
                <div className="card">
                  {/*begin::Card body*/}
                  <div className="card-body p-0 mt-10">
                    {/*begin::Illustration*/}
                    <div className="text-center px-4 mt-10">
                      <span className="mw-100 mh-300px">
                        <svg
                          viewBox="0 0 24 24"
                          width="234px"
                          height="234px"
                          version={1.0}
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          enableBackground="new 0 0 64 64"
                          xmlSpace="preserve"
                          fill="#21325B"
                          stroke="#21325B"
                        >
                          <path
                            opacity="0.3"
                            d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                            fill="#21325B"
                          />
                          <path
                            d="M14.854 11.321C14.7568 11.2282 14.6388 11.1818 14.4998 11.1818H14.3333V10.2272C14.3333 9.61741 14.1041 9.09378 13.6458 8.65628C13.1875 8.21876 12.639 8 12 8C11.361 8 10.8124 8.21876 10.3541 8.65626C9.89574 9.09378 9.66663 9.61739 9.66663 10.2272V11.1818H9.49999C9.36115 11.1818 9.24306 11.2282 9.14583 11.321C9.0486 11.4138 9 11.5265 9 11.6591V14.5227C9 14.6553 9.04862 14.768 9.14583 14.8609C9.24306 14.9536 9.36115 15 9.49999 15H14.5C14.6389 15 14.7569 14.9536 14.8542 14.8609C14.9513 14.768 15 14.6553 15 14.5227V11.6591C15.0001 11.5265 14.9513 11.4138 14.854 11.321ZM13.3333 11.1818H10.6666V10.2272C10.6666 9.87594 10.7969 9.57597 11.0573 9.32743C11.3177 9.07886 11.6319 8.9546 12 8.9546C12.3681 8.9546 12.6823 9.07884 12.9427 9.32743C13.2031 9.57595 13.3333 9.87594 13.3333 10.2272V11.1818Z"
                            fill="#21325B"
                          />

                        </svg>

                      </span>
                    </div>
                    {/*end::Illustration*/}
                    {/*begin::Wrapper*/}
                    <div className="card-px text-center py-5 mb-20">
                      {/*begin::Title*/}
                      {/* <h1 className="fs-2x fw-bolder mb-5">SSL Certificate</h1> */}
                      <h1 className="fs-1x fw-bolder mb-5 text-muted">
                        Add SSL in your account
                      </h1>
                      {/*end::Title*/}
                      {/*begin::Description*/}
                      <p className="text-gray-400 fs-4 fw-bold mb-10">
                        Enhance the security of your account by effortlessly adding an SSL certificate.<br /> Safeguard sensitive data and ensure encrypted communication for trusted <br />and secure interactions with your website or application.                      </p>
                      {/*end::Description*/}
                      {/*begin::Action*/}
                      <a
                        onClick={openDrawer}
                        style={{ cursor: "pointer" }}
                        className="btn btn-primary mb-10"
                      >
                        Add SSL Certificate
                      </a>
                      {/*end::Action*/}
                    </div>
                    {/*end::Wrapper*/}
                  </div>
                  {/*end::Card body*/}
                </div>
              </div>
              {actionType != "page" ? (<>
                <SslDeploy type={actionType} openDrawer={openDrawer} addSslCertificate={addSslCertificate} setActionType={setActionType} />
              </>) : ('')}
            </>
            )}
            <div id="drawer_overlay" onClick={openDrawer} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>

            <div
              id="add_ssl-modal"
              className="drawer drawer-end"
              data-kt-drawer="true"
              data-kt-drawer-activate="true"
              data-kt-drawer-overlay="false"
              data-kt-drawer-width="{default:'500px', 'md': '500px', 'sm': '300px'}"
              style={{ maxWidth: '70%', important: true }}
            >
              {/*begin::Messenger*/}
              <div className="card w-100 rounded-0 border-0" id="kt_drawer_chat_messenger">
                {/*begin::Card header*/}
                <div className="card-header pe-5" id="kt_drawer_chat_messenger_header">
                  {/*begin::Title*/}
                  <div className="card-title">
                    {/*begin::User*/}
                    <div className="d-flex justify-content-center flex-column me-3">
                      <a

                        className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1"
                      >
                        Add SSL Certificate
                      </a>
                    </div>
                    {/*end::User*/}
                  </div>
                  {/*end::Title*/}
                  {/*begin::Card toolbar*/}
                  <div className="card-toolbar">
                    {/*begin::Close*/}
                    <div onClick={openDrawer}>
                      <div
                        className="btn btn-sm btn-icon btn-active-light-primary"
                        onClick={(e) => setUpdateBtn(false)}
                      >
                        {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                        <span className="svg-icon svg-icon-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              opacity="0.5"
                              x={6}
                              y="17.3137"
                              width={16}
                              height={2}
                              rx={1}
                              transform="rotate(-45 6 17.3137)"
                              fill="black"
                            />
                            <rect
                              x="7.41422"
                              y={6}
                              width={16}
                              height={2}
                              rx={1}
                              transform="rotate(45 7.41422 6)"
                              fill="black"
                            />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}
                      </div>
                    </div>
                    {/*end::Close*/}
                  </div>
                  {/*end::Card toolbar*/}
                </div>
                {/*end::Card header*/}
                {/*begin::Card body*/}
                <div className="" id="">
                  {/*begin::Form*/}
                  {/*begin::Modal body*/}
                  <div className="modal-body ">
                    {/*begin::Scroll*/}
                    <div className="scroll-y me-n7 pe-7">
                      <form id="sslCreate">
                        {/*begin::Input group*/}
                        <div className="fv-row mb-10 fv-plugins-icon-container">
                          {/*begin::Label*/}
                          <label className="form-label required">Name</label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <input type="hidden" id="id_field" className="form-control form-control-lg  w-50" />
                          <input
                            name="name"
                            id="name_field"
                            className="form-control form-control-lg  w-50"
                            defaultValue=""
                          />
                          {/*end::Input*/}
                          <div className="fv-plugins-message-container invalid-feedback" />
                        </div>
                        {/*end::Input group*/}
                        <div className="row">
                          {/*begin::Input group*/}
                          <div className="col-lg-12 col-md-12">
                            <div className="fv-row mb-10">
                              {/*end::Label*/}
                              <label className="form-label required">
                                Certificate
                              </label>
                              {/*end::Label*/}
                              {/*begin::Input*/}
                              <input type="hidden" name="type" defaultValue="Custom" />
                              <textarea
                                name="certificate_key"
                                id="certificate_field"
                                className="form-control form-control-lg "
                                rows={7}
                                defaultValue={""}
                              />
                              {/*end::Input*/}
                            </div>
                            {/*end::Input group*/}
                          </div>
                          <div className="col-lg-12 col-md-12">
                            {/*begin::Input group*/}
                            <div className="fv-row mb-10">
                              {/*end::Label*/}
                              <label className="form-label required">
                                Private Key
                              </label>
                              {/*end::Label*/}
                              {/*begin::Input*/}
                              <textarea
                                name="private_key"
                                id="privateKey_field"
                                className="form-control form-control-lg "
                                rows={7}
                                defaultValue={""}
                              />
                              {/*end::Input*/}
                            </div>
                            {/*end::Input group*/}
                          </div>
                        </div>
                        {/*begin::Input group*/}
                        <div className="fv-row mb-10">
                          {/*end::Label*/}
                          <label className="form-label">Certificate chain</label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <textarea
                            name="certificateChain"
                            id="certificateChain_field"
                            className="form-control form-control-lg "
                            rows={4}
                            defaultValue={""}
                          />
                          {/*end::Input*/}
                        </div>
                        {/*end::Input group*/}
                      </form>
                    </div>
                    {/*end::Scroll*/}
                  </div>
                  {/*end::Modal body*/}
                  {/*begin::Modal footer*/}
                  <div className="modal-footer ">
                    {/*begin::Button*/}

                    <a
                      id="ssl-btn"
                      onClick={addSslCertificate}
                      className="btn btn-primary w-100"
                    >
                      <span className="indicator-label" >+ Add SSL Certificate</span>
                    </a>

                    {/*end::Button*/}
                  </div>
                  {/*end::Modal footer*/}
                  {/*end::Form*/}
                </div>
                {/*end::Card body*/}
              </div>
              {/*end::Messenger*/}
            </div>


          </>
        )
      } else {
        return (
          <>
            <TopBarHeaderLine />
            <TableLoader />
          </>
        );
      }
    } else {
      return (
        <>
          <AccessDenied />
        </>
      )
    }
  } else {
    return (
      <>
        <TableLoader />
      </>
    )
  }
}