// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-fade-in {
    opacity: 0; /* Start with the image hidden */
    animation: fadeInLogo 1s ease-in both;
    /* transform: translateY(20px);  
    transition: opacity 1s ease-in-out, transform 0.5s ease;  */
  }

  @keyframes fadeInLogo {
	from {
		opacity: 0;
		transform: translate3d(0, -20%, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@media (min-width: 992px) {
	.marginRight-sm {
	  margin-right: -18px; /* Adjust this value according to your needs */
	}
  }`, "",{"version":3,"sources":["webpack://./src/routes/private-routes/user-verification/profile/custom.css"],"names":[],"mappings":"AAAA;IACI,UAAU,EAAE,gCAAgC;IAC5C,qCAAqC;IACrC;+DAC2D;EAC7D;;EAEA;CACD;EACC,UAAU;EACV,kCAAkC;CACnC;CACA;EACC,UAAU;EACV,+BAA+B;CAChC;AACD;;AAEA;CACC;GACE,mBAAmB,EAAE,8CAA8C;CACrE;EACC","sourcesContent":[".logo-fade-in {\n    opacity: 0; /* Start with the image hidden */\n    animation: fadeInLogo 1s ease-in both;\n    /* transform: translateY(20px);  \n    transition: opacity 1s ease-in-out, transform 0.5s ease;  */\n  }\n\n  @keyframes fadeInLogo {\n\tfrom {\n\t\topacity: 0;\n\t\ttransform: translate3d(0, -20%, 0);\n\t}\n\tto {\n\t\topacity: 1;\n\t\ttransform: translate3d(0, 0, 0);\n\t}\n}\n\n@media (min-width: 992px) {\n\t.marginRight-sm {\n\t  margin-right: -18px; /* Adjust this value according to your needs */\n\t}\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
