import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom"; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import AddingCard from "../billing/sections/AddCard/AddingCard";
import AddingRazorpayCard from "../billing/sections/AddCard/AddCards/AddingRazorpayCard";
import AddingStripeCard from "../billing/sections/AddCard/AddCards/AddingStripeCard";
import localStorage from "local-storage";

export default function Addcard(){
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    setUserData(localStorage.get("user_info"));
  }, []); 
return(
  <> 
  <div className="col-xl-12">
  <div className="card">
  {/*begin::Card body*/}
  <div className="card-body p-0 mt-10">
     {/*begin::Illustration*/}
     <div className="text-center px-4 mt-10">
      <span className="mw-100 mh-300px">
      <svg
  xmlns="http://www.w3.org/2000/svg"
  width="240px"
  height="240px"
  viewBox="0 0 24 24"
  fill="#21325B"
>
  <path d="M22 7H2V11H22V7Z" fill="#21325B" />
  <path
    opacity="0.3"
    d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19ZM14 14C14 13.4 13.6 13 13 13H5C4.4 13 4 13.4 4 14C4 14.6 4.4 15 5 15H13C13.6 15 14 14.6 14 14ZM16 15.5C16 16.3 16.7 17 17.5 17H18.5C19.3 17 20 16.3 20 15.5C20 14.7 19.3 14 18.5 14H17.5C16.7 14 16 14.7 16 15.5Z"
    fill="#21325B"
  />
</svg>


      </span> 

    </div>
    {/*end::Illustration*/}
    {/*begin::Wrapper*/}
    <div className="card-px text-center py-5 mb-20">
      {/*begin::Title*/}
      <h1 className="fs-2x fw-bolder mb-5">Add Card</h1>
      <h2 className="fs-1x fw-bolder mb-5 text-muted">Access Exclusive Services by Adding a Card Today</h2>
      {/*end::Title*/}
      {/*begin::Description*/}
      <p className="text-gray-400 fs-4 fw-bold mb-10">
       Unlock a world of premium features and enjoy<br/> the benefits at your fingertips
      </p>
      {/*end::Description*/}
      {/*begin::Action*/}
      {userData != null && userData.currency == "INR" ? (
              <>
                <AddingRazorpayCard />
              </>
            ) : (
              <>
                <AddingStripeCard />
              </>
            )}
      {/* <a
        href="#"
        className="btn btn-primary mb-10"
      >
        Add Credit/Debit Card
      </a> */}
      {/*end::Action*/}
    </div>
    {/*end::Wrapper*/} 
  </div>
  {/*end::Card body*/}
</div>

</div>
  </>
)

}