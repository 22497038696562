import {React} from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ActivateUser from '../../../App/MicroApp/Body/Products/subuser/ActivateUser';
import { LogoutUser } from '../../../middleware/User'; 
import AccountVerifyMobile from '../../../App/MicroApp/Body/Products/verification/mobile/AccountVerifyMobile';

export default function SmsVerification(){
    return (
        <> 
          <Routes>
            <Route path="/activateuser/:id" element={<ActivateUser />} />
            <Route path="/logout" element={<LogoutUser />} />
            <Route path="*" element={<AccountVerifyMobile />} />
          </Routes>
        </>
      );
}