import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CryptoJS from 'crypto-js';
import Search from "../../../../utils/search/Search";

function Iso(props) {
  const { isoData, selectIso, iso, isoid } = props;

  useEffect(() => {
     if (isoData === undefined) throw new Error("Missing prop: isoData");
    if (selectIso === undefined)
      throw new Error("Missing prop: selectIso");
  }, [isoData, selectIso]);

  const [isoList, setIsoList] = useState(isoData);

  useEffect(()=>{
    if(isoid){
    selectIso(iso, isoid);
    }
  },[isoList])

  const hashString = (message) => {
    return CryptoJS.SHA256(message).toString(CryptoJS.enc.Hex);
  };
  return (
    <>
      {isoList != null ? (
        <>
          <Search results={isoData} onSearch={setIsoList} fields={['name']} searchPlaceholder="iso" />
          {isoList.length != 0 ? (
            <>
              {isoList.map((value, index) => (
                <>
                  <div className="col-md-4 col-lg-4 col-xxl-4 ">
                    {/*begin::Option*/}
                    <input
                      className="btn-check deploy_checkbox iso_radio"
                      type="radio"
                      name="image_OS"
                      defaultValue={value.file}
                      id={"iso" + value.file}
                    />
                    <label
                      onClick={(e) => selectIso(value.file, hashString(value.name))}
                      htmlFor={"iso" + value.file}
                      className=" label versionOs text-start border-1 border-gray-300 border px-6 py-2 d-flex"
                    >
                      {/*begin::Svg Icon | path: icons/duotune/communication/com003.svg*/}
                      <span className="svg-icon svg-icon-muted svg-icon-4x me-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            opacity="0.3"
                            d="M18.4 5.59998C21.9 9.09998 21.9 14.8 18.4 18.3C14.9 21.8 9.2 21.8 5.7 18.3L18.4 5.59998Z"
                            fill="black"
                          />
                          <path
                            d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM19.9 11H13V8.8999C14.9 8.6999 16.7 8.00005 18.1 6.80005C19.1 8.00005 19.7 9.4 19.9 11ZM11 19.8999C9.7 19.6999 8.39999 19.2 7.39999 18.5C8.49999 17.7 9.7 17.2001 11 17.1001V19.8999ZM5.89999 6.90002C7.39999 8.10002 9.2 8.8 11 9V11.1001H4.10001C4.30001 9.4001 4.89999 8.00002 5.89999 6.90002ZM7.39999 5.5C8.49999 4.7 9.7 4.19998 11 4.09998V7C9.7 6.8 8.39999 6.3 7.39999 5.5ZM13 17.1001C14.3 17.3001 15.6 17.8 16.6 18.5C15.5 19.3 14.3 19.7999 13 19.8999V17.1001ZM13 4.09998C14.3 4.29998 15.6 4.8 16.6 5.5C15.5 6.3 14.3 6.80002 13 6.90002V4.09998ZM4.10001 13H11V15.1001C9.1 15.3001 7.29999 16 5.89999 17.2C4.89999 16 4.30001 14.6 4.10001 13ZM18.1 17.1001C16.6 15.9001 14.8 15.2 13 15V12.8999H19.9C19.7 14.5999 19.1 16.0001 18.1 17.1001Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}
                      <span className="d-block fw-bold text-start py-1 sphide">
                        <span className="text-dark fw-bolder d-block fs-6 sphide2">
                          {value.name}
                        </span>
                        <span className="text-muted fw-bold fs-7">
                          {value.added_at}
                        </span>
                      </span>
                      {/*begin::Ribbon*/}
                      <div
                        className="d-none tickMarkiso ribbon ribbon-triangle ribbon-top-start border-primary"
                        id={"tickMarkiso-" + hashString(value.name)}
                      >
                        {/*begin::Ribbon icon*/}
                        <div className="ribbon-icon mt-n5">
                          <i className="bi bi-check2 fs-2 text-white" />
                        </div>
                        {/*end::Ribbon icon*/}
                      </div>
                      {/*end::Ribbon*/}
                    </label>
                    {/*end::Option*/}
                  </div>
                </>
              ))}
            </>
          ) : (
            <>
              <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                {/*begin::Wrapper*/}

                <div className="pt-lg-10 mb-10">
                  {/*begin::Message*/}
                  <div className="fw-bold fs-5  mb-15 badge badge-light-primary">
                    You don't have any ISO on this location
                  </div>
                  {/*end::Message*/}
                </div>
                {/*end::Wrapper*/}
              </div>
            </>
          )}
        </>
      ) : (
        <>
          {/*begin::Content*/}
          <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
            {/*begin::Wrapper*/}
            <div className="pt-lg-10 mb-10">
              {/*begin::Message*/}
              <div className="fw-bold fs-5  mb-15 badge badge-light-primary">
                Please select Datacenter Location to view ISO list.
              </div>
              {/*end::Message*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Content*/}
        </>
      )}
    </>
  );
}

Iso.propTypes = {
  isoData: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.oneOf([null]),
  ]).isRequired,
  selectIso: PropTypes.func.isRequired,
};

export default Iso;
