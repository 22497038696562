import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UsePost from "../../../../CustomHooks/usePost";
import swal from "sweetalert";
import copy from "copy-to-clipboard";
import ReactPaginate from "react-paginate";
import { event } from "jquery";
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../Loaders/TableLoader";
import CustomDoc from "../../../../CustomHooks/CustomDoc";
import ProductsUrl from "../../Docs/Docsdata";
import AccessDenied from '../../AccessDenied.js';

export default function AutoScaling() {
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [perPage, setPerPage] = useState(5);
  const [asData, setAsData] = useState(null);
  const [isaccess, setIsaccess] = useState(false);
  const [dummyAsData, setDummyAsData] = useState(null);
  const [dataFetched, setDataFetched] = useState(false);
  const dataFetchedRef = useRef(false);
  const navigate = useNavigate();

  const navRedirect = () => {
    setTimeout(() => {
      navigate("/ssh");
    }, 3000);
  };
  const loaderFunc = () => {
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if (loader && no_record) {
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");

      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
  };
  const getAutoScaling = async () => {
    const dt = toast;
    const data = await UseApi("get", "autoscaling", "");
    if (data) {
      if (data.rcode == "error") {
        setIsaccess(true);
        toast.update(dt, {
          render: data.message,
          type: "warning",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setDataFetched(true)
        setAsData([]);
        setDummyAsData([])
        // loaderFunc();
        return;
      } else if (data.status == "error") {
        toast.update(dt, {
          render: data.message,
          type: "warning",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setIsaccess(false);
        setDataFetched(true)
        // loaderFunc();
        return;
      } else {
        setDataFetched(true)
        setIsaccess(true);
        var groupdata = data.groups;
        setTimeout(() => {
          setAsData(groupdata?.reverse());
          setDummyAsData(groupdata?.reverse())
          setPageCount(Math.ceil(groupdata.length / perPage))
        }, 200)
      }
    }

  };
  useEffect(() => {
    // if (dataFetchedRef.current) return;
    // dataFetchedRef.current = true;
    // toast.dismiss();
    getAutoScaling();
  }, []);

  const copyToClipboard = (data, sid) => {
    var copyText = document.querySelector("#sshKeyData");
    copy(data);
    document
      .querySelector("#sshKeyData" + sid)
      .classList.remove("btn-secondary");
    document.querySelector("#sshKeyData" + sid).classList.add("btn-success");
    document.querySelector("#sshKeyData" + sid).innerHTML = "Copied";
    setTimeout(() => {
      document
        .querySelector("#sshKeyData" + sid)
        .classList.add("btn-secondary");
      document
        .querySelector("#sshKeyData" + sid)
        .classList.remove("btn-success");
      document.querySelector("#sshKeyData" + sid).innerHTML = "Copy Key";
    }, 2000);
  };
  function ProductInfo({ productdata, endOffset }) {
    console.log(productdata);
    return (
      <>
        {productdata != null && Object.keys(productdata).length != 0 ? (
          productdata &&
          productdata.map((value, index) => (
            <tr>
              <td className="ps-9">
                <div className="d-flex align-items-center">
                  <div className="d-flex justify-content-start flex-column">
                    <Link to={"/auto-scaling/" + value.id}>
                      <div style={{ cursor: "pointer" }} className="text-dark fw-bolder text-hover-primary fs-6">
                        {value.name}
                      </div>
                    </Link>
                    <span className="text-muted fw-bold text-muted d-block fs-7">
                      {value.plan && value.plan.cpu ? (value.plan.cpu) : ('')} vCPU /{value.plan && value.plan.ram ? ((value.plan.ram / 1024).toFixed(0)) : ('')} GB / {value.plan && value.plan.disk ? (value.plan.disk) : ('')} GB Storage /<span className="w-10">
                        {" "}
                        {value.dclocation && value.dclocation.dccc ? (
                          <img
                            src={"/img/flag_" + value.dclocation.dccc + ".png"}
                            style={{ width: "7%" }} />) : ('')}
                      </span> {value.dclocation && value.dclocation.location ? (value.dclocation.location) : ('')}
                    </span>
                  </div>
                </div>
              </td>
              <td></td>
              <td className="text-center d-none">
                <div className=" align-items-start">
                  <div className="d-flex justify-content-start flex-column">
                    <a className=" text-gray-700 fw-bold d-block fs-6 sphide">
                      {value.image_name}
                    </a>
                  </div>
                </div>
              </td>
              <td></td>
              <td className="text-center">
                <div className=" align-items-center">
                  <div className="d-flex justify-content-center flex-column">
                    <a className="w-100 btn btn-active-primary btn-primary btn-sm sphide me-2 fs-7 fw-bold" style={{ cursor: 'default' }}>
                      {value.minsize}
                    </a>
                  </div>
                </div>
              </td>
              <td></td>
              <td className="text-center">
                <div className=" align-items-center">
                  <div className="d-flex justify-content-center flex-column">
                    <a className="w-100 btn btn-active-primary btn-primary btn-sm sphide me-2 fs-7 fw-bold" style={{ cursor: 'default' }}>
                      {value.maxsize}
                    </a>
                  </div>
                </div>
              </td>
              <td></td>
              <td className="text-center">
                <div className=" align-items-center">
                  <div className="d-flex justify-content-center flex-column">
                    <a className="w-100 btn btn-active-primary btn-primary btn-sm sphide me-2 fs-7 fw-bold" style={{ cursor: 'default' }}>
                      {value.desiredsize}
                    </a>
                  </div>
                </div>
              </td>
              <td></td>
              <td>
                {value.status == "Active" ? (<>
                  <span className="w-100 btn btn-active-success btn-success btn-sm sphide me-2 fs-7 fw-bold" style={{ cursor: 'default' }}> {value.status}  </span>
                </>) : (<>
                  <span className="w-100 btn btn-active-warning btn-warning btn-sm sphide me-2 fs-7 fw-bold" style={{ cursor: 'default' }}> {value.status}  </span>
                </>)}
              </td>
              <td></td>
              <td className="text-center">
                <div className=" align-items-center">
                  <div className="d-flex justify-content-center flex-column">
                    <a className=" text-gray-700 fw-bold d-block fs-6 sphide">
                      {value.created_at}
                    </a>
                  </div>
                </div>
              </td>
              <td></td>
              <td className="text-center pe-5">
                <div className="d-flex justify-content-center flex-shrink-0 sphide">
                  {" "}
                  <Link to={"/auto-scaling/" + value.id}>
                    <a className="btn btn-primary btn-active-light-primary btn-sm">
                      {" "}
                      Manage{" "}
                      <span className="svg-icon ms-2 svg-icon-3">
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          {" "}
                          <rect
                            opacity="0.5"
                            x={18}
                            y={13}
                            width={13}
                            height={2}
                            rx={1}
                            transform="rotate(-180 18 13)"
                            fill="black"
                          />{" "}
                          <path
                            d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                            fill="black"
                          />{" "}
                        </svg>{" "}
                      </span>{" "}
                    </a>{" "}
                  </Link>
                </div>{" "}
              </td>
            </tr>
          ))
        ) : (
          <>
            <tr>
              {/* <td colspan="12" className="dataTables_empty" id="loader"> */}
              {/* <div className="loading-spinner"></div> */}
              {/* <TableLoader /> */}
              {/* </td> */}
              <td colspan="12" className="" id="no_record" valign="top">
                <div className="d-flex flex-column flex-center">
                  <div className="fs-5 fw-bolder text-dark mb-4">
                    No items found.
                  </div>
                </div>
              </td>
            </tr>
          </>
        )}
      </>
    );
  }

  const handleItemsPerPageChange = (newItemsPerPage) => {
    // If the current page goes beyond the total page count, reset to the first page
    const newPageCount = Math.ceil(dummyAsData.length / parseInt(newItemsPerPage));
    const newActivePage = activePage >= newPageCount ? 0 : activePage;

    // Set the new offset based on the current page and itemsPerPage
    const newOffset = newActivePage * parseInt(newItemsPerPage);

    // Update state
    setItemOffset(newOffset);
    setActivePage(newActivePage);
    setPerPage(newItemsPerPage);  // Update the itemsPerPage state
  };


  function PaginatedItems({ itemsPerPage, ProductData }) {
    // Calculate the total number of pages based on itemsPerPage and ProductData
    const pageCount = Math.ceil(ProductData.length / parseInt(itemsPerPage));

    // Calculate the current end offset
    const endOffset = itemOffset + parseInt(itemsPerPage);

    // Handle page click
    const handlePageClick = (event) => {
      const selectedPage = event.selected;
      const newOffset = selectedPage * parseInt(itemsPerPage);
      setActivePage(selectedPage);
      setItemOffset(newOffset);
    };
    return (
      <>
        <ProductInfo productdata={ProductData.slice(itemOffset, endOffset)} />
        <td colspan="12" className="ps-3">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            initialPage={activePage}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </td>
      </>
    );
  }


  const openDoc = () => {
    var drawerElement = document.querySelector("#autoScaling-doc");
    var drawer_overlay = document.querySelector("#drawer_overlay_autoScaling_doc");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
        drawerElement.style.width = "100%";
      } else {
        drawerElement.style.width = "50%";
      }
      drawer_overlay.classList.toggle("d-none");
    }
  };

  const handleSearch = (text) => {
    const endOffset = itemOffset + perPage;
    const data = asData.filter(row => {
      return (
        (row.name && row.name.toString().toLowerCase().includes(text.toLowerCase()))
        // (row.network && row.network.toString().toLowerCase().includes(text.toLowerCase()))
      )
    });
    setDummyAsData(data.slice(itemOffset, endOffset));;
    setPageCount(Math.ceil(data.length / perPage));
  }

  if (dataFetched) {
    if (isaccess) {
      return (
        <>
          <div className="col-xl-12">
            {/*begin::Tables Widget 9*/}
            <div className="card card-xl-stretch mb-5 mb-xl-12">
              {/*begin::Header*/}
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold fs-3 mb-1">
                    Auto Scaling{" "}
                    {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                    {/*end::Svg Icon*/}
                  </span>
                  <span className="text-muted mt-1 fw-bold fs-7">
                    View your Auto Scaling groups here.
                    <span className="text-primary ms-1 fw-bold fs-7 d-none" onClick={openDoc} style={{ cursor: 'pointer' }}>Learn more.
                    </span>
                  </span>
                </h3>
                <div className="card-toolbar">
                  <div
                    className="position-relative my-1"
                    style={{ marginRight: 20 }}
                  >
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      data-kt-table-widget-4="search"
                      id="searchClient"
                      // defaultValue={searchTerm}
                      // value={searchTerm}
                      onChange={(e) => {
                        // setAsData(null)
                        handleSearch(e.target.value)
                        // getAutoScaling()
                      }}
                      placeholder="Search"
                    // ref={inputRef}
                    />
                  </div>
                  <div style={{ marginRight: 20 }}>
                    <div className="dataTables_length">
                      <label className="w-125px">
                        <select
                          onChange={(e) => {
                            // setAsData(null)
                            handleItemsPerPageChange(e.target.value)
                            // getAutoScaling()
                          }}
                          //data-control="select2"
                          className="form-select form-select-sm form-select-solid"
                          defaultValue={5}
                          id="perPage"
                        >
                          <option value={5} selected="">
                            5 Records
                          </option>
                          <option value={10}>10 Records</option>
                          <option value={25}>25 Records</option>
                          <option value={50}>50 Records</option>
                          <option value={100}>100 Records</option>
                        </select>
                      </label>
                    </div>
                  </div>
                  <Link to="/auto-scaling/create" className="pe-6">
                    <a
                      className="btn btn-sm btn-primary btn-active-light-primary"
                      id="importkey-open"
                    >
                      + Create New
                    </a>
                  </Link>
                </div>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              {/*begin::Table container*/}
              <div className="table-responsive">
                {/*begin::Table*/}
                <table
                  id="asTable"
                  className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
                >
                  {/*begin::Table head*/}
                  <thead className="bg-secondary">
                    <tr className="fw-bold">
                      <th className="ps-9">Name</th>
                      <th className="w-20px"></th>
                      <th className="text-center d-none">Image</th>
                      <th className="w-20px"></th>
                      <th className=" text-center sphide">Min Size</th>
                      <th className="w-20px"></th>
                      <th className=" text-center sphide">Max Size</th>
                      <th className="w-20px"></th>
                      <th className=" text-center sphide">Desired Size</th>
                      <th className="w-20px"></th>
                      <th className="text-center">Status</th>
                      <th className="w-20px"></th>
                      <th className="text-center">Created_at</th>
                      <th className="w-20px"></th>
                      <th className="w-200px text-center">Action</th>
                    </tr>
                  </thead>
                  {/*end::Table head*/}
                  {/*begin::Table body*/}
                  <tbody id="sshList" className="fs-6 fw-bold text-gray-600">

                    {asData != null ? (
                      <>
                        {asData.length != 0 ? (
                          <>
                            {asData &&
                              <PaginatedItems
                                itemsPerPage={perPage}
                                ProductData={dummyAsData}
                              // search={searchTerm}
                              />
                            }
                          </>
                        ) : (
                          <>
                            <tr>
                              <td colspan={12}>
                                <div className="col-xl-12">
                                  {/* <div className="card"> */}
                                  {/*begin::Card body*/}
                                  <div className="mt-10" style={{ paddingLeft: '10rem' }}>
                                    {/*begin::Illustration*/}
                                    <div className="text-center px-4 mt-20">
                                      <span className="mw-100 mh-300px me-2">
                                        <svg
                                          width="204px"
                                          height="234px"
                                          fill="#21325B"
                                          viewBox="0 0 16 36"
                                          version="1.1"
                                          xmlns="http://www.w3.org/2000/svg"
                                          stroke="#21325B"
                                        >
                                          <path
                                            opacity="0.3"
                                            d="M4.05424 15.1982C8.34524 7.76818 13.5782 3.26318 20.9282 2.01418C21.0729 1.98837 21.2216 1.99789 21.3618 2.04193C21.502 2.08597 21.6294 2.16323 21.7333 2.26712C21.8372 2.37101 21.9144 2.49846 21.9585 2.63863C22.0025 2.7788 22.012 2.92754 21.9862 3.07218C20.7372 10.4222 16.2322 15.6552 8.80224 19.9462L4.05424 15.1982ZM3.81924 17.3372L2.63324 20.4482C2.58427 20.5765 2.5735 20.7163 2.6022 20.8507C2.63091 20.9851 2.69788 21.1082 2.79503 21.2054C2.89218 21.3025 3.01536 21.3695 3.14972 21.3982C3.28408 21.4269 3.42387 21.4161 3.55224 21.3672L6.66524 20.1802L3.81924 17.3372ZM16.5002 5.99818C16.2036 5.99818 15.9136 6.08615 15.6669 6.25097C15.4202 6.41579 15.228 6.65006 15.1144 6.92415C15.0009 7.19824 14.9712 7.49984 15.0291 7.79081C15.0869 8.08178 15.2298 8.34906 15.4396 8.55884C15.6494 8.76862 15.9166 8.91148 16.2076 8.96935C16.4986 9.02723 16.8002 8.99753 17.0743 8.884C17.3484 8.77046 17.5826 8.5782 17.7474 8.33153C17.9123 8.08486 18.0002 7.79485 18.0002 7.49818C18.0002 7.10035 17.8422 6.71882 17.5609 6.43752C17.2796 6.15621 16.8981 5.99818 16.5002 5.99818Z"
                                            fill="#21325B"
                                          />
                                          <path
                                            d="M4.05423 15.1982L2.24723 13.3912C2.15505 13.299 2.08547 13.1867 2.04395 13.0632C2.00243 12.9396 1.9901 12.8081 2.00793 12.679C2.02575 12.5498 2.07325 12.4266 2.14669 12.3189C2.22013 12.2112 2.31752 12.1219 2.43123 12.0582L9.15323 8.28918C7.17353 10.3717 5.4607 12.6926 4.05423 15.1982ZM8.80023 19.9442L10.6072 21.7512C10.6994 21.8434 10.8117 21.9129 10.9352 21.9545C11.0588 21.996 11.1903 22.0083 11.3195 21.9905C11.4486 21.9727 11.5718 21.9252 11.6795 21.8517C11.7872 21.7783 11.8765 21.6809 11.9402 21.5672L15.7092 14.8442C13.6269 16.8245 11.3061 18.5377 8.80023 19.9442ZM7.04023 18.1832L12.5832 12.6402C12.7381 12.4759 12.8228 12.2577 12.8195 12.032C12.8161 11.8063 12.725 11.5907 12.5653 11.4311C12.4057 11.2714 12.1901 11.1803 11.9644 11.1769C11.7387 11.1736 11.5205 11.2583 11.3562 11.4132L5.81323 16.9562L7.04023 18.1832Z"
                                            fill="#21325B"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                    {/*end::Illustration*/}
                                    {/*begin::Wrapper*/}
                                    <div className="text-center py-5 mb-20" style={{ marginTop: '-5rem' }}>
                                      {/*begin::Title*/}
                                      <h1 className="fs-2x fw-bolder mb-5">
                                        Auto Scaling Group
                                      </h1>
                                      <h2 className="fs-1x fw-bolder mb-5 text-muted">
                                        Auto Scale your server Instances
                                      </h2>
                                      {/*end::Title*/}
                                      {/*begin::Description*/}
                                      <p className="text-gray-400 fs-4 fw-bold mb-10">

                                      </p>
                                      {/*end::Description*/}
                                      {/*begin::Action*/}
                                      <Link to="/auto-scaling/create">
                                        <a
                                          className="btn btn-primary mb-10"
                                        >
                                          Create New
                                        </a>
                                      </Link>
                                      {/*end::Action*/}
                                    </div>
                                    {/*end::Wrapper*/}
                                  </div>
                                  {/*end::Card body*/}
                                </div>
                                {/* </div> */}

                              </td>
                            </tr>
                          </>
                        )}
                      </>

                    ) : (
                      <>
                        <tr>
                          <td colspan={12}>
                            <TableLoader />
                            <TopBarHeaderLine />
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                  {/*end::Table body*/}
                </table>
                {/*end::Table*/}
              </div>
              {/*end::Table container*/}
              {/*begin::Body*/}
            </div>
            {/*end::Tables Widget 9*/}
          </div>

        </>
      );
    } else {
      return (
        <>
          <AccessDenied />
        </>
      )
    }
  } else {
    return (
      <>
        <TableLoader />
      </>
    )
  }
}
