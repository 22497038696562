import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import UseApi from "../../../../../CustomHooks/useApi";
import TableLoader from "../../../Loaders/TableLoader";
import UsePostData from "../../../../../CustomHooks/usePostData";
import copy from "copy-to-clipboard";

export default function LoadBalancer() {
  var query = window.location.href;
  var vars = query.split("/");
  var id = vars[4];
  const [dataFetched, setDataFetched] = useState(false);
  const [access, setAccess] = useState(true);
  const [perPage, setPerPage] = useState(5);
  const [loadBalancerData, setLoadBalancerData] = useState(null);
  const [dummyLoadBalancerData, setDummyLoadBalancerData] = useState(null);
  const [itemOffset, setItemOffset] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [lbData, setLbData] = useState(null);
  const dataFetchedRef = useRef(false);
  const loaderFunc = () => {
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if (loader && no_record) {
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");

      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
  }
  const getLoadBalancer = async () => {
    const dt = toast
    const data = await UseApi('get', 'autoscaling/' + id + '/loadbalancer', '');
    if (data) {
      if (data.load_balancers && data.load_balancers.length == 0) {
        setLoadBalancerData([]);
        setDummyLoadBalancerData([])
        toast.update(dt, {
          render: "no record found", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
      } else {
        console.log(data);
        setLoadBalancerData(data.load_balancers);
        setDummyLoadBalancerData(data.load_balancers)
        toast.dismiss();
      }
    }
  }

  const getLB = async () => {
    const dt = toast
    const data = await UseApi('get', 'loadbalancer', '');
    if (data) {
      if ('status' in data) {
        if (data.status == "error") {
          toast.update(dt, {
            render: data.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          setLbData([])
          // setLoadBalancerData([]);
          // setDummyLoadBalancerData([])
          return;
        }
      }
      if (Object.keys(data).length === 0) {
        setLbData(null);
        toast.update(dt, {
          render: "no record found", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        // loaderFunc();
      } else {
        var lb_Data = data.loadbalancers.filter((item) => {
          console.log(item, loadBalancerData);
          // console.log(item,loadBalancerData.includes(item.id),item.type );
          if (loadBalancerData) {
            return !loadBalancerData.some((lb) => lb.lbid == item.id)
            // return !loadBalancerData.includes(item.id) && item.type == "network";
          } else {
            return item.id;
          }
        })
        setLbData(lb_Data);
        toast.dismiss();
      }
    }
  }
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getLoadBalancer();
  }, []);

  useEffect(() => {
    getLB();
  }, [loadBalancerData])


  const openDrawer = () => {
    var add_lb_id = document.querySelector("#add_lb_id");
    var drawerElement = document.querySelector("#lb-modal");
    var drawer_overlay = document.querySelector("#drawer_overlay_lb");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
        drawerElement.style.width = "100%";
      } else {
        drawerElement.style.width = "40%";
      }
      drawer_overlay.classList.toggle("d-none");
    }
    if (add_lb_id) {
      add_lb_id.value = ''
    }
  };
  function checkObjectForEmpty(obj) {
    for (const key in obj) {
      const value = obj[key];
      console.log(key);

      if (typeof value === 'string' && value.trim() === '') {
        return `Error: '${key}' should not be empty or blank.`;
      }

      if (typeof value === 'object') {
        const nestedError = checkObjectForEmpty(value);
        if (nestedError) {
          return nestedError;
        }
      }
    }

    return null; // No empty or blank elements found
  }
  const attachLb = async () => {
    var add_vpc = document.querySelector("#add_lb_id");
    const submitBtn = document.querySelector("#add-lb-btn");

    if (add_vpc && submitBtn) {
      toast.dismiss();
      const dt = toast.loading("Please wait...");
      var updateData = { "load_balancer": add_vpc.value };
      const validationError = checkObjectForEmpty(updateData);

      if (validationError) {
        setTimeout(() => {
          toast.update(dt, {
            render: validationError,
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }, 1000)
        return;
      }
      if (submitBtn) {
        var preContent = submitBtn.innerHTML;
        submitBtn.classList.add("disabled");
        submitBtn.innerHTML =
          'Please wait... <span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';

        const responseData = await UsePostData("post", "autoscaling/" + id + "/loadbalancer/" + add_vpc.value, {});

        ////console.log(responseData);
        if (responseData.status === "error") {
          toast.update(dt, {
            render: responseData.message,
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (responseData.status === "success") {
          toast.update(dt, {
            render: responseData.message,
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          openDrawer();
          getLoadBalancer();
        }
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
      }
    }
  };

  const deleteLb = async (lbid) => {
    toast.dismiss();
    const dt = toast.loading("Please wait...");
    const submitBtn = document.querySelector("#delete-lb-btn-" + lbid);
    if (submitBtn) {
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML =
        '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';

      const responseData = await UseApi("delete", "autoscaling/" + id + "/loadbalancer/" + lbid, "");

      ////console.log(responseData);
      if (responseData.status === "error") {
        toast.update(dt, {
          render: responseData.message,
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (responseData.status === "success") {
        toast.update(dt, {
          render: responseData.message,
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        getLoadBalancer();
      }
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML = preContent;
    }
  };

  const copyToClipboard = (id) => {

    var ele = document.querySelector("#ip_address_lb" + id);
    var eleText = document.querySelector("#ip_addressLB" + id);
    // console.log(ele);
    if (ele && !ele.classList.contains('disabled')) {
      var copyText = ele.innerHTML;
      var copyEle = eleText.innerHTML;
      copy(copyEle);
      ele.innerHTML = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Copied&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";
      ele.classList.add('disabled')
      setTimeout(() => {
        ele.classList.remove('disabled')
        ele.innerHTML = copyText;
      }, 2000);
    }

  }
  function ProductInfo({ productdata, endOffset }) {
    return (
      <>
        {productdata != null && Object.keys(productdata).length != 0 ? (productdata &&
          productdata.slice(itemOffset, endOffset).map((value, index) => (
            <tr>
              <td className="text-start ps-9">
                {" "}
                <div className="d-flex justify-content-start flex-column">
                  {" "}
                  <span className=" me-2 fs-7 fw-bold">
                    {value.lbid}
                  </span>{" "}
                </div>
              </td>
              <td className="p-3 text-center">
                {" "}
                <div className="d-flex justify-content-start flex-column">
                  <a className="text-dark fw-bold text-hover-primary fs-6"
                  >
                    {value.name}
                  </a>
                </div>{" "}
              </td>{" "}
              <td className="text-center">
                {" "}
                <div className="d-flex flex-column w-100 me-2">
                  {" "}
                  <div className=" flex-stack mb-2">
                    {" "}
                    <span className="w-50 btn btn-active-primary btn-light-primary btn-sm sphide me-2 fs-7 fw-bold"
                      htmlFor="IP Address"
                      id={"ip_address_lb" + index}
                      onClick={() => copyToClipboard(index)}
                      data-bs-toggle="tooltip"
                      data-bs-placement="right"
                      title="Click to copy IP Address"
                    >
                      <span id={"ip_addressLB" + index}> {value.ip}</span>&nbsp;&nbsp;
                      {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen054.svg*/}
                      <span className="svg-icon svg-icon-light svg-icon-2 d-none">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            opacity="0.5"
                            d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                            fill="black"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}
                    </span>{" "}
                  </div>{" "}
                </div>{" "}
              </td>{" "}

              <td className="text-end pe-10">
                {" "}
                {/* <div className="d-flex justify-content-center flex-shrink-0"> */}
                {" "}
                <a
                  id={"delete-lb-btn-" + value.lbid}
                  onClick={(e) => deleteLb(value.lbid)}
                  className="btn  btn-danger btn-active-light-danger btn-sm"
                >
                  {" "}
                  Detach
                </a>{" "}
                {/* </div>{" "} */}
              </td>
            </tr>
          ))) : (<>
            <tr>
              <td colspan="12" className="dataTables_empty" id="loader">
                {/* <div className="loading-spinner"></div> */}
                {/* <TableLoader /> */}
              </td>
              <td colspan="12" className="d-none" id="no_record" valign="top">
                <div className="d-flex flex-column flex-center">
                  <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                </div>
              </td>
            </tr>
          </>)}

      </>
    )
  }


  function PaginatedItems({ itemsPerPage, ProductData, search }) {
    // Calculate the total number of pages based on itemsPerPage and ProductData
    const pageCount = Math.ceil(ProductData.length / parseInt(itemsPerPage));

    // Calculate the current end offset
    const endOffset = itemOffset + parseInt(itemsPerPage);

    // Handle page click
    const handlePageClick = (event) => {
      const selectedPage = event.selected;
      const newOffset = selectedPage * parseInt(itemsPerPage);
      setActivePage(selectedPage);
      setItemOffset(newOffset);
    };
    return (
      <>
        <ProductInfo productdata={ProductData.slice(itemOffset, endOffset)} />
        <td colspan="12" className="ps-3">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            initialPage={activePage}
            pageCount={pageCount}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </td>
      </>
    );
  }

  const handleItemsPerPageChange = (newItemsPerPage) => {
    // If the current page goes beyond the total page count, reset to the first page
    const newPageCount = Math.ceil(dummyLoadBalancerData.length / parseInt(newItemsPerPage));
    const newActivePage = activePage >= newPageCount ? 0 : activePage;

    // Set the new offset based on the current page and itemsPerPage
    const newOffset = newActivePage * parseInt(newItemsPerPage);

    // Update state
    setItemOffset(newOffset);
    setActivePage(newActivePage);
    setPerPage(newItemsPerPage);  // Update the itemsPerPage state
  };


  const handleSearch = (text) => {
    const endOffset = itemOffset + perPage;
    const data = loadBalancerData.filter(row => {
      return (
        (row.name && row.name.toString().toLowerCase().includes(text.toLowerCase())) ||
        row.ip && row.ip.toString().toLowerCase().includes(text.toLowerCase())
      )
    });
    setDummyLoadBalancerData(data);;
    setPageCount(Math.ceil(data.length / perPage));
    // }
  }

  return (
    <>
      <div className="col-xl-12">
        {/*begin::Tables Widget 9*/}
        <div className="card card-xl-stretch mb-5 mb-xl-12">
          {/*begin::Header*/}
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label  fw-bold fs-3 mb-1">
                {" "}
                Load Balancer Management{" "}
                {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                {/* <span className="svg-icon svg-icon-2">
            <i className="bi bi-diagram-3 " style={{ fontSize: 20 }} />
          </span> */}
                {/*end::Svg Icon*/}
              </span>
            </h3>
            <div
              className="card-toolbar"
            >
              <div className="position-relative my-1" style={{ marginRight: 20 }}>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  data-kt-table-widget-4="search"
                  id="searchClient"
                  style={{ padding: '0.6rem 0.6rem' }}
                  onChange={e => handleSearch(e.target.value)}
                  placeholder="Search"
                />
              </div>
              <div style={{ marginRight: 20 }}>
                <div className="dataTables_length">
                  <label className="w-125px">
                    <select
                      onChange={e => handleItemsPerPageChange(e.target.value)}
                      //data-control="select2"
                      style={{ padding: '0.6rem 0.6rem' }}
                      className="form-select form-select-sm form-select-solid"
                      defaultValue={5}
                      id="perPage"

                    >
                      <option value={5} selected="">
                        5 Records
                      </option>
                      <option value={10}>10 Records</option>
                      <option value={25}>25 Records</option>
                      <option value={50}>50 Records</option>
                      <option value={100}>100 Records</option>
                    </select>
                  </label>
                </div>
              </div>
              <a
                onClick={openDrawer}
                className="btn btn-sm btn-active-light-primary btn-primary"
                style={{ padding: '0.55rem 1.5rem' }}
              >
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
                <span className="svg-icon svg-icon-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x="11.364"
                      y="20.364"
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(-90 11.364 20.364)"
                      fill="black"
                    />
                    <rect
                      x="4.36396"
                      y="11.364"
                      width={16}
                      height={2}
                      rx={1}
                      fill="black"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}Attach Load Balancer
              </a>
            </div>
          </div>
          {/*end::Header*/}
          {/*begin::Body*/}
          {/*begin::Table container*/}
          <div className="table-responsive">
            {/*begin::Table*/}
            <table
              id="loadbalancer"
              className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
            >
              {/*begin::Table head*/}
              <thead className="bg-secondary">
                <tr className=" fw-bold ">
                  <th className="w-50px p-3 text-start ps-9" >#LB</th>
                  <th className="w-250px p-3 text-center">Name</th>
                  <th className="w-200px text-center">IP Address</th>
                  <th className="w-200px text-end pe-20">Action</th>
                </tr>
              </thead>
              {/*end::Table head*/}
              {/*begin::Table body*/}
              <tbody>
                {dummyLoadBalancerData != null ? (<> {Object.keys(dummyLoadBalancerData).length != 0 ? (<>
                  <PaginatedItems itemsPerPage={perPage} ProductData={dummyLoadBalancerData} />
                </>) : (<>
                  <tr>
                    <td colSpan={12} className="text-center">
                      <span className="fs-6 text-gray-800 fw-bold">no record found</span>
                    </td>
                  </tr>
                </>)}
                </>) : (<>
                  <tr>
                    <td colspan={12}>
                      <TableLoader />
                    </td>
                  </tr>

                </>)}

              </tbody>
              {/*end::Table body*/}
            </table>
            {/*end::Table*/}
          </div>
          {/*end::Table container*/}
          {/*begin::Body*/}
        </div>
        {/*end::Tables Widget 9*/}
      </div>

      <div id="drawer_overlay_lb" onClick={openDrawer} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>

      <div
        id="lb-modal"
        className="bg-body shadow drawer drawer-end"
        // data-kt-drawer="true"
        // data-kt-drawer-name="chat"
        // data-kt-drawer-activate="true"
        // data-kt-drawer-direction="end"
        data-kt-drawer-close="#close_ssh"
        // data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
        style={{ important: true, zIndex: 110 }}
      >
        {/*begin::Messenger*/}
        <div
          className="card rounded-0 w-100 border-0"
          id="kt_drawer_chat_messenger"
        >
          {/*begin::Card header*/}
          <div
            className="card-header pe-5"
            id="kt_drawer_chat_messenger_header"
          >
            {/*begin::Title*/}
            <div className="card-title">
              {/*begin::User*/}
              <div className="d-flex justify-content-center flex-column me-3">
                <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                  Add New Loadbalancer
                </a>
              </div>
              {/*end::User*/}
            </div>
            {/*end::Title*/}
            {/*begin::Card toolbar*/}
            <div className="card-toolbar">
              {/*begin::Close*/}
              <div
                onClick={openDrawer}
                className="btn btn-sm btn-icon btn-active-light-primary"
                id="close_ssh"
              >
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                <span className="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x={6}
                      y="17.3137"
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(-45 6 17.3137)"
                      fill="black"
                    />
                    <rect
                      x="7.41422"
                      y={6}
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(45 7.41422 6)"
                      fill="black"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
              </div>
              {/*end::Close*/}
            </div>
            {/*end::Card toolbar*/}
          </div>
          {/*end::Card header*/}
          {/*begin::Card body*/}
          <div className="" id="">
            {/*begin::Form*/}
            <form
              name="importkey"
              id="importkey"
              className="form"
            >
              {/*begin::Modal body*/}
              <div className="modal-body ">
                {/*begin::Scroll*/}
                <div
                  className="scroll-y me-n7 pe-7"
                  id="kt_modal_create_api_key_scroll"
                  data-kt-scroll="true"
                  data-kt-scroll-activate="{default: false, lg: true}"
                  data-kt-scroll-max-height="auto"
                  data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                  data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                  data-kt-scroll-offset="300px"
                >
                  {/*begin::Input group*/}
                  <div className="mb-5 fv-row">
                    {/*begin::Label*/}
                    <label className="required fs-5 fw-bold mb-2">
                      {" "}
                      LoadBalancer
                    </label>
                    {/*end::Label*/}
                    {/*begin::Input*/}
                    <select className="form-select" id="add_lb_id">
                      {lbData != null ? (<>
                        {lbData.length > 0 ? <>
                          <option value='' disabled selected>Select loadbalancer</option>
                          {lbData.map((value, index) => (<>
                            <option value={value.id}> {value.name}</option>
                          </>))}
                        </> :
                          <option value='' disabled selected>No loadbalancer found</option>
                        }
                      </>) : ""}
                    </select>
                    {/*end::Input*/}
                  </div>
                  {/*end::Input group*/}

                </div>
                {/*end::Scroll*/}
              </div>
              {/*end::Modal body*/}
              {/*begin::Modal footer*/}
              <div className="modal-footer pe-9">
                {/*begin::Button*/}
                <a
                  onClick={attachLb}
                  className="btn btn-primary w-100"
                  id="add-lb-btn"
                >
                  <span className="indicator-label">
                    Add Loadbalancer
                  </span>
                </a>
                {/*end::Button*/}
              </div>
              {/*end::Modal footer*/}
            </form>
            {/*end::Form*/}
          </div>
          {/*end::Card body*/}
        </div>
        {/*end::Messenger*/}
      </div>
    </>
  )
} 