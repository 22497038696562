import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom"; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import copy from "copy-to-clipboard"; 
import "./cloud.css";
import UseApi from "../../../../../../CustomHooks/useApi";
import AccessDenied from "../../../../AccessDenied";
import TopBarHeaderLine from "../../../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../../../Loaders/TableLoader"; 
import ChartOverview from "../../../cloud/cloudFeatures/ChartOverview";
const Cloud=({cloud_ids})=>{
  
  // CSS End
  const [perPage, setPerPage]=useState(5); 
  const [dataFetched, setDataFetched] = useState(false);
  const [cloudData,setCloudData]=useState(null);
  const [access, setAccess]=useState(true);
  const [pageStatus, setPageStatus] = useState(false)
  const [metaData, setMetaData]=useState(null);
  const dataFetchedRef=useRef(false);
  const currentPage=useRef(0);
  const loaderFunc=()=>{
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if(loader && no_record){
    no_record.classList.add("d-none");
    loader.classList.remove("d-none");

    setTimeout(() => {
      loader.classList.add("d-none");
      no_record.classList.remove("d-none");
    }, 100);
  }
  }

  const getCloud = async () => {
    setPageStatus(false) 
      const clouds = await UseApi('get', 'cloud', '');
    
      if (clouds) {
        setPageStatus(true);
        // Split the comma-separated ids string into an array
        const cloudIdsArray = cloud_ids.split(',');
    
        // Filter the clouds array based on the provided cloud IDs
        const filteredCloud = clouds.cloud.filter(cloudEle => cloudIdsArray.includes(cloudEle.cloudid));
        const filteredClouds = clouds.cloud.filter(cloudEle => !cloudIdsArray.includes(cloudEle.cloudid));
  
        // Set the filtered cloud data
        setCloudData(filteredCloud);
        // setCloudsData(filteredClouds);
      } 
    };
    
    const [cloudid, setCloudid]= useState(null);
    const getCloudOverview = async (bid) => {
      setCloudid(null);
      var cloudBtn= document.querySelector("#cloud_overview_btn_"+bid);
      if(cloudBtn){
        var cloudBtnHtml=cloudBtn.innerHTML;
        cloudBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
        cloudBtn.classList.add("disabled");
      
        setCloudid(bid)
        cloudBtn.innerHTML=cloudBtnHtml;
        cloudBtn.classList.remove("disabled");
        openCloudOverviewDrawer();
      }
      };
 
  useEffect(()=>{
    getCloud();
    // //console.log("cloud coming");
  },[]);

  const copyToClipboard=(id)=> {

    var ele = document.querySelector("#ip_address_sec"+id);
    var eleText = document.querySelector("#ip_address"+id);
    if(ele){
      var copyText=ele.innerHTML;
      var copyEle=eleText.innerHTML;
    copy(copyEle);
    ele.innerHTML="Copied";
    ele.classList.remove("badge-primary");
    ele.classList.add("badge-success");
    setTimeout(() => {
      ele.classList.add("badge-primary");
    ele.classList.remove("badge-success");
      ele.innerHTML=copyText;
    }, 2000);
  }

  }
  const openCloudOverviewDrawer = () => {
 
    var drawerElement = document.querySelector("#cloud-overview-modal");
    var drawer_overlay = document.querySelector("#drawer_overlay_cloud_overview");
    if(drawerElement && drawer_overlay){ 
    drawerElement.classList.toggle("drawer-on");
    drawerElement.style.width = "75%";
    drawer_overlay.classList.toggle("d-none");
    }
   };
   const closeCloudOverviewDrawer = () => {
    setCloudid(null);
    var drawerElement = document.querySelector("#cloud-overview-modal");
    var drawer_overlay = document.querySelector("#drawer_overlay_cloud_overview");
    if(drawerElement && drawer_overlay){ 
    drawerElement.classList.toggle("drawer-on");
    drawerElement.style.width = "75%";
    drawer_overlay.classList.toggle("d-none");
    }
   };

  function ProductInfo({ productdata }) {
    return(
        <>
        {productdata!=null && Object.keys(productdata).length!=0?(productdata && productdata.map((cloud, index) => (
          <tr>
            {" "}
            
            <td>
            <div className="d-flex align-items-center ps-10">
            {cloud.stack?(<>
  <div className="symbol symbol-45px me-5 text-center">
    <img src={'/'+cloud.stack.logo_url} alt="" style={{ width: "45px",height: "45px",padding: "2px",background: "#ebebeb75"}} />
  </div>
  </>):(<>
    <div className="symbol symbol-45px me-5  text-center pt-2" style={{ width: "45px",height: "45px",padding: "2px",background: "#ebebeb75"}}>
    <span className={"fonticon_"+(cloud.image.distribution).replaceAll(" ","")} />
    </div>
  </>)}
  <div className="d-flex justify-content-start flex-column">
    <Link to={"/cloud/"+cloud.cloudid}>
    <div style={{cursor:"pointer"}} className="text-dark fw-bolder text-hover-primary fs-6">
    {cloud.hostname}
    </div>
    </Link>
    <span className="text-muted fw-bold text-muted d-block fs-7">
    {cloud.cpu} vCPU /{(cloud.ram/1024).toFixed(0)} GB / {cloud.disksize} GB Disk /<span className="w-10">
                  {" "}
                  <img src={"/img/flag_"+cloud.dclocation.dccc+".png"} style={{width: "7%"}} />{" "}
                </span> {cloud.dclocation.location}
    </span>
  </div>
</div>


            </td>
             
            <td className="text-center">
              {" "}
              <div className="d-flex flex-column w-100 me-2">
                {" "}
                <div className=" flex-stack mb-2">
                  {" "}
                  <span className="w-100 btn btn-active-primary btn-light-primary btn-sm sphide me-2 fs-7 fw-bold"
                  htmlFor="IP Address"
                  id={"ip_address_sec"+index}
                  onClick={()=>copyToClipboard(index)}
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  title="Click to copy IP Address"
                  >
                   <span id={"ip_address"+index}> {cloud.ip}</span>&nbsp;&nbsp;
                    {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen054.svg*/}
  <span className="svg-icon svg-icon-light svg-icon-2 d-none">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        opacity="0.5"
        d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
        fill="black"
      />
    </svg>
  </span>
  {/*end::Svg Icon*/}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
            </td>{" "} 
            <td className="text-end">

              {" "}
              <div className="d-flex justify-content-end flex-shrink-0">
                {" "}  
                <a onClick={(e)=>getCloudOverview(cloud.cloudid)}
                id={"cloud_overview_btn_"+cloud.cloudid}
                  className="btn btn-primary btn-active-light-primary btn-sm me-1 me-5 sphide"
                >

                  Monitor ➔
                </a>{" "} 
              </div>{" "}
            </td>{" "}
            <td>

              {" "}
              <div className="d-flex justify-content-start flex-shrink-0">
                {" "}  
                <a  
                  className="btn btn-danger btn-active-light-danger btn-sm me-1 me-5 sphide"
                >

                  Delete
                </a>{" "} 
              </div>{" "}
            </td>{" "}
          </tr>
           ))):( <>
            <tr>
                     <td colspan="12" className="dataTables_empty" id="loader">
                      <TopBarHeaderLine />
                      <TableLoader />
                      {/* <div className="loading-spinner"></div> */}
                    </td>
                    <td colspan="12" className="d-none" id="no_record" valign="top">
                      <div className="d-flex flex-column flex-center">
                        <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                      </div>
                    </td>
                    </tr>
           </>)}


        </>
    )
           }

  
           const [searchTerm,setSearchTerm]=useState('');
           function PaginatedItems({ itemsPerPage, ProductData, search }) {
             // We start with an empty list of items. 
             const [pageCount, setPageCount] = useState(0);
             const [vpcData,setVpcData]=useState(null);
             // Here we use item offsets; we could also use page offsets
             // following the API or data you're working with.
             const [itemOffset, setItemOffset] = useState(0);
           
             useEffect(() => {
               // Fetch items from another resources.
               
               const endOffset = itemOffset + itemsPerPage;
           
           
             //  //////console.log(`Loading items from ${itemOffset} to ${endOffset}`);
                if(ProductData!=null){
               //   var str="Noida";
              // //////console.log(ProductData);
               const data= ProductData.filter(row => {
                return  Object.values(row).some(value => { 
                   if(value!=null){
                     if(value.toString().toLowerCase().includes(search.toLowerCase())){
                       return value;
                     } 
                   }
                 });
               });
               ////////console.log(data);
                 // const data=ProductData.filter(item => 
                 //        item.hostname.toLowerCase().includes(search.toLowerCase())
                 //              ); 
           
                              if(data.length<=0){
                               loaderFunc();
                              }
               setVpcData(data.slice(itemOffset, endOffset));
               //setCurrentItems(items.slice(itemOffset, endOffset));
               setPageCount(Math.ceil(data.length / itemsPerPage));
               // alert(pageCount);
                 }
             }, [itemOffset, itemsPerPage]);
           
             // Invoke when user click to request another page.
             
             const handlePageClick = (event) => { 
               const newOffset = event.selected * itemsPerPage % ProductData.length;
               //////console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
               setItemOffset(newOffset);
              
             };
             var searchEle=document.querySelector("#searchClient");
             if(searchEle){
               searchEle.focus();
             }
             return (
               <>
                 <ProductInfo productdata={vpcData} />
                 <td colspan="12">
                 <ReactPaginate
                   nextLabel="next >"
                   onPageChange={handlePageClick}
                   pageRangeDisplayed={3}
                   marginPagesDisplayed={2}
                   pageCount={pageCount}
                   previousLabel="< previous"
                   pageClassName="page-item"
                   pageLinkClassName="page-link"
                   previousClassName="page-item"
                   previousLinkClassName="page-link"
                   nextClassName="page-item"
                   nextLinkClassName="page-link"
                   breakLabel="..."
                   breakClassName="page-item"
                   breakLinkClassName="page-link"
                   containerClassName="pagination"
                   activeClassName="active"
                   renderOnZeroPageCount={null}
                 />
                 </td>
               </>
             );
           } 
        return(
          <>
          {/* <div style={{zIndex: "109"}} id="overlay_id" className="drawer-overlay"></div> */}
           <div className="col-xl-12">
  {/*begin::Tables Widget 9*/}
  <div className="card card-xl-stretch mb-5 mb-xl-12">
    {/*begin::Header*/}
    <div className="card-header border-0 pt-5">
      <h3 className="card-title align-items-start flex-column">
        <span className="card-label fw-bold fs-3 mb-1">
          Cloud Instances
          {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
          <span className="svg-icon svg-icon-2 d-none">
            <i className="las la-cloud" style={{ fontSize: 20 }} />
          </span>
          {/*end::Svg Icon*/}
        </span>
        <span className="text-muted mt-1 fw-bold fs-7" />
      </h3>
      <div
        className="card-toolbar"
      >
        
        <div
                      className="position-relative my-1"
                      style={{ marginRight: 20 }}
                    >
                       <input
            type="text"
            className="form-control form-control-sm"
            data-kt-table-widget-4="search"
            id="searchClient"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            placeholder="Search"
          />
        </div>

                  <div style={{ marginRight: 20 }}>
  <div className="dataTables_length">
    <label className="w-125px">
      <select  
        onChange={e => setPerPage(e.target.value)}
        //data-control="select2"
        className="form-select form-select-sm form-select-solid"
        defaultValue={5}
        id="perPage"
        
      >
        <option value={5} selected="">
          5 Records
        </option>
        <option value={10}>10 Records</option>
        <option value={25}>25 Records</option>
        <option value={50}>50 Records</option>
        <option value={100}>100 Records</option>
      </select>
    </label>
                      </div>
                    </div>

        <Link to="/cloud/deploy">
        <a

          className="btn btn-sm btn-primary btn-active-light-primary"
        >
          {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
          <span className="svg-icon svg-icon-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x="11.364"
                y="20.364"
                width={16}
                height={2}
                rx={1}
                transform="rotate(-90 11.364 20.364)"
                fill="black"
              />
              <rect
                x="4.36396"
                y="11.364"
                width={16}
                height={2}
                rx={1}
                fill="black"
              />
            </svg>
          </span>
          {/*end::Svg Icon*/}Deploy New
        </a>
        </Link>
      </div>
    </div>
    {/*end::Header*/}
    {/*begin::Body*/}
    {/*begin::Table container*/}

    <div className="table-responsive">
      {/*begin::Table*/}
      <table
        id="clouddata"
        className="table  table-row-dashed table-row-gray-400 align-middle gs-0 gy-4"
      >
        {/*begin::Table head*/}
        <thead className="bg-secondary">
          <tr className="fw-bold "> 
            <th className="w-50 text-center"></th> 
            <th className="w-25 text-center">IPv4</th> 
            <th className="w-25 text-center" ></th>
            <th className="w-25 text-center" ></th>
          </tr>
        </thead>
        {/*end::Table head*/}
        {cloudData!=null?(<>
        {access==false?(<>
          <tr>
          <td colspan={12}>
         <AccessDenied />
         </td>
         </tr>
        </>):(<> 
        {Object.keys(cloudData).length!=0?(<>
            {cloudData.length > 0?(<>
                            <PaginatedItems itemsPerPage={perPage} ProductData={cloudData} search={searchTerm}  />
                          </>):(<>  </>)}
        </>):(<>
          <tr>
          <td colspan={12}>
          <div className="card-body p-0 mt-10">
                    {/*begin::Illustration*/}
                    <div className="text-center px-4 mt-10">
                      <span className="mw-100 mh-300px">
                      <svg
                      viewBox="0 0 64 64"
                      width="234px"
                      height="234px"
  version={1.0}
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
  enableBackground="new 0 0 64 64"
  xmlSpace="preserve"
  fill="#21325B"
  stroke="#21325B"
>
  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
  <g id="SVGRepo_iconCarrier">
    {" "}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#21325B"
      d="M53.802,25.104C52.003,24.4,50.05,24,48,24c-0.553,0-1-0.447-1-1 s0.447-1,1-1c1.841,0,3.607,0.297,5.278,0.812C50.992,14.287,43.246,8,34,8c-7.532,0-14.079,4.172-17.49,10.324 c1.515,0.393,2.89,1.133,4.037,2.128c0,0,1.182,1.028,0.433,1.777s-1.847-0.363-1.847-0.363c-1.142-0.957-2.556-1.593-4.106-1.792 C14.689,20.03,14.349,20,14,20c-4.418,0-8,3.582-8,8c0,1.1,0.228,2.145,0.632,3.098C8.286,30.404,10.092,30,12,30 c0.553,0,1,0.447,1,1s-0.447,1-1,1c-1.585,0-3.091,0.318-4.474,0.879c-0.123,0.05-0.243,0.105-0.364,0.158 c-0.389,0.174-0.772,0.356-1.138,0.568C2.426,35.675,0,39.551,0,44c0,6.627,5.373,12,12,12h36c8.837,0,16-7.164,16-16 C64,33.214,59.767,27.43,53.802,25.104z M23,45c-0.553,0-1-0.447-1-1c0-2.762-1.119-5.262-2.929-7.071c0,0-0.993-0.913-0.243-1.647 s1.657,0.233,1.657,0.233C22.656,37.687,24,40.687,24,44C24,44.553,23.553,45,23,45z M57,41c-0.553,0-1-0.447-1-1 c0-4.418-3.582-8-8-8c-0.553,0-1-0.447-1-1s0.447-1,1-1c5.522,0,10,4.477,10,10C58,40.553,57.553,41,57,41z"
    />{" "}
  </g>
</svg>

                      </span>
                    </div>
                    {/*end::Illustration*/}
                    {/*begin::Wrapper*/}
                    <div className="card-px text-center py-5 mb-20">
                      {/*begin::Title*/}
                      <h1 className="fs-2x fw-bolder mb-5">Cloud Server</h1>
                      <h2 className="fs-1x fw-bolder mb-5 text-muted">
                      Deploy your first server
                      </h2>
                      {/*end::Title*/}
                      {/*begin::Description*/}
                      <p className="text-gray-400 fs-4 fw-bold mb-10">
                      Deploy cloud servers with ease and take your cloud journey to the next level.<br/> Start enjoying the benefits from Utho's robust and scalable server solutions.
                      </p>
                      {/*end::Description*/}
                      {/*begin::Action*/}
                      <Link to="/cloud/deploy">
                      <a
                        href="#"
                        className="btn btn-primary mb-10"
                      >
                        Create Cloud Server
                      </a>
                      </Link>
                      {/*end::Action*/}
                    </div>
                    {/*end::Wrapper*/}
          </div>
          </td>
          </tr>
          </>)}
        </>)}
        </>):(<>
          <tr>
            <td colspan={12}>
              <TableLoader />
              <TopBarHeaderLine />
            </td>
          </tr>
          
        </>)} 
    


    </table>
      {/*end::Table*/}
    </div>
    {/*end::Table container*/}
    {/*begin::Body*/}
  </div>
  {/*end::Tables Widget 9*/}
           </div>

           <div id="drawer_overlay_cloud_overview" onClick={closeCloudOverviewDrawer} className="drawer-overlay d-none" style={{zIndex: 109, width:"100%"}}></div>   

<div
id="cloud-overview-modal"
className="bg-body shadow drawer drawer-end"
data-kt-drawer="true"
data-kt-drawer-name="chat"
data-kt-drawer-activate="true"
data-kt-drawer-direction="end"
data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
style={{ maxWidth: "70%", important: true }}
>
{/*begin::Messenger*/}
<div
className="card w-100 rounded-0 border-0"
id="kt_drawer_chat_messenger"
> 
{/*begin::Card body*/}
<div className="" id="">
  {cloudid!=null?(<>
   <ChartOverview cloudid={cloudid} />
   </>):(<><TableLoader /></>)}
</div>

{/*end::Card body*/}
</div>

{/*end::Messenger*/}
</div>

          </>
      ) 

} 

export default Cloud;
