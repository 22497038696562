import Cookies from "js-cookie";
import UseApi from "../App/CustomHooks/useApi";
import { deleteCookie } from "../services/Cookies";

export const LogoutUser = () => {
  var current_url_string = window.location.href;
  const current_url = new URL(current_url_string);
  const current_domain = current_url.hostname;
  console.log(current_url);
  const cookieNames = Object.keys(Cookies.get());
  var domain = "." + current_domain; // Specify the domain
  var Uthodomain = ".utho.com"; // Specify the domain
  var webDomain = "utho.com";
  // Remove each cookie with the specified domain
  cookieNames.forEach((cookieName) => {
    deleteCookie(cookieName, domain);
    deleteCookie(cookieName, current_domain);
    deleteCookie(cookieName, Uthodomain);
    deleteCookie(cookieName, webDomain); 
  });
  // setTimeout(() => {
  //   window.location.reload();
  // }, 2000);
};

export const IsSubuser = async () => {
  const responseData = await UseApi("get", "amaccess", "");
  if (responseData) {
    if ("auth" in responseData) {
      if (responseData.auth == "error") {
        return "no";
      }
    } else {
      if (responseData.status == "success") {
        if (
          responseData.accountaccess &&
          responseData.accountaccess.length > 0
        ) {
          return "yes";
        } else {
          return "no";
        }
      } else {
        return "no";
      }
    }
  }
};
