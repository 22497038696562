import React, { useState, useEffect, useRef } from "react";
import './custom.css'

export default function CpuModelTab(props) {
  console.log(props);

  const [selectedCpu, setSelectedCpu] = useState(props.recommendedCpu || null);

  const selectTickMark = (cpuid, name, input_name) => {
    var currentPlan = document.querySelector("#tickMark" + name + "-" + cpuid);
    var currentMain = document.querySelector("#border" + name + "-" + cpuid);
    const inputElement = document.getElementById(input_name + cpuid);
    setSelectedCpu(cpuid);
    props.setRecommendedCpu(cpuid)
    if (currentPlan && currentMain) {
      // Toggle the tick mark visibility
      currentPlan.classList.remove("d-none");

      currentMain.classList.add("activeBorder");
      inputElement.classList.add('deploy_checkbox')

      var allTickmarks = document.querySelectorAll(".tickMark" + name);
      allTickmarks.forEach((tickMark) => {
        if (tickMark !== currentPlan) {
          tickMark.classList.add("d-none");
        }
      });
      // Remove active border from other elements
      var mainTickMarks = document.querySelectorAll(".border" + name);
      mainTickMarks.forEach((border) => {
        if (border !== currentMain) {
          border.classList.remove("activeBorder");
        }
      });
    }
  };

  useEffect(() => {
    if (props) {
      if (props.recommendedCpu) {
        setSelectedCpu(props.recommendedCpu);
        selectTickMark(props.recommendedCpu, "Cpu", "Cpu");
      }
    }
  }, [props.recommendedCpu])
  console.log(selectedCpu, props.recommendedCpu);

  return (
    <>
      <div className="col-xl-12 card mb-5">
        {/*begin::List widget 10*/}
        {/*begin::Header*/}
        <div className="card-header border-3 pt-4 ps-6 p-0">
          {/*begin::Title*/}
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder text-gray-800">
              CPU Model
            </span>
            <span className="text-gray-400 mt-1 fw-bold fs-6">
              Select CPU model for the server
            </span>
          </h3>
          {/*end::Title*/}
        </div>
        {/*end::Header*/}
        {/*begin::Body*/}
        {/* <form id="cpuModelTab"> */}
        <input
          type="hidden"
          name="cpumodel"
          value={selectedCpu}
        />
        <div className=" border-3 mb-5 pt-2">
          <div className="p-2 border-3 pt-2">
            {/*begin::Radio group*/}
            <div data-kt-buttons="true" className="row p-3">

              <div className="col-xxl-4 col-xl-4 col-lg-4 col-sm-6">
                {/*begin::Option*/}
                <input
                  className="btn-check deploy_checkbox"
                  type="radio"
                  // name="modelCpu"
                  id={"Cpuamd"}
                  onChange={() => {
                    // props.setRecommendedCpu("amd")
                    // selectTickMark("amd", "Cpu", "Cpu");
                  }
                  }
                  checked={props.recommendedCpu == "amd"}
                />
                <label
                  htmlFor={"Cpuamd"}
                  onClick={() =>
                    selectTickMark(
                      'amd',
                      "Cpu",
                      "Cpu"
                    )
                  }
                  className="borderCpu btn btn-outline btn-outline btn-outline-default d-flex align-items-center"
                  id={"borderCpu-amd"}
                  style={{ overflow: "hidden", height: "60px" }}
                >
                  {/*begin::Svg Icon | path: icons/duotune/communication/com003.svg*/}
                  <div class="symbol symbol-40px bg-white">
                    <img src="/assets/cpu/amd.png" alt="image" class="p-3 w-100" />
                  </div>
                  {/*end::Svg Icon*/}
                  <span className="d-block fw-bold text-start">
                    <span className="text-dark fw-bolder d-block fs-3 d-none">
                      AMD
                    </span>
                    <span className=" badge badge-success fs-8 w-100 d-none">
                      {" "}
                      High Performance
                    </span>
                  </span>
                  <div
                    className="d-none tickMarkCpu ribbon ribbon-triangle ribbon-top-start border-primary"
                    id={
                      "tickMarkCpu-amd"
                    }
                  >
                    <div className="ribbon-icon mt-n5">
                      <i className="bi bi-check2 fs-2 text-white" />
                    </div>
                  </div>
                </label>
                {/*end::Option*/}
              </div>

              <div className="col-4">
                {/*begin::Option*/}
                <input
                  className="btn-check deploy_checkbox"
                  type="radio"
                  // name="modelCpu"
                  id={"Cpuintel"}
                  onChange={() => {
                    // props.setRecommendedCpu("intel")
                    // selectTickMark("intel", "Cpu", "Cpu");
                  }
                  }
                  checked={props.recommendedCpu == "intel"}
                />
                <label
                  htmlFor={"Cpuintel"}
                  onClick={() =>
                    selectTickMark(
                      "intel",
                      "Cpu",
                      "Cpu"
                    )
                  }
                  className="borderCpu btn btn-outline btn-outline btn-outline-default d-flex align-items-center"
                  id={"borderCpu-intel"}
                  style={{ overflow: "hidden", height: "60px" }}
                >
                  {/*begin::Svg Icon | path: icons/duotune/communication/com003.svg*/}
                  <div class="symbol symbol-50px bg-white">
                    <img src="../assets/cpu/intel.png" alt="image" class="p-3 w-100" />
                  </div>
                  {/*end::Svg Icon*/}
                  <span className="d-block fw-bold text-start">
                    <span className="text-dark fw-bolder d-block fs-3">
                      Intel
                    </span>
                    <span className="text-muted fw-bold fs-8 w-100 d-none">
                      {" "}
                      Efficiency, stability and reliability
                    </span>
                  </span>
                  <div
                    className="d-none tickMarkCpu ribbon ribbon-triangle ribbon-top-start border-primary"
                    id={
                      "tickMarkCpu-intel"
                    }
                  >
                    <div className="ribbon-icon mt-n5">
                      <i className="bi bi-check2 fs-2 text-white" />
                    </div>
                  </div>
                </label>
                {/*end::Option*/}
              </div>
            </div>
            {/*end::Radio group*/}
          </div>
          {/*begin::List widget 10*/}


          {/*end::List widget 10*/}
        </div>
        {/* </form> */}
        {/*end: Card Body*/}
        {/*end::List widget 10*/}
      </div>
    </>
  )
}