import localStorage from 'local-storage';
import React, { useEffect, useState } from 'react'

export default function KubernetesPolicy(props) {
  const [policyArr, setPolicyArr] = useState(null)
  const [policyType, setPolicyType] = useState('CPU')
  const [addWorker, setAddWorker] = useState(props?.poolData[0]?.aboveValue ? props?.poolData[0]?.aboveValue : 60)
  const [removeWorker, setRemoveWorker] = useState(props?.poolData[0]?.belowValue ? props?.poolData[0]?.belowValue : 20)
  const [period, setPeriod] = useState(props?.poolData[0]?.policyPeriod ? props?.poolData[0]?.policyPeriod : '5m')
  const [policies, setPolicies] = useState(props?.poolData);

  const makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  // console.log(policies,"policy");
  function formatTimeDuration(input) {
    const durationRegex = /^(\d+)([mh])$/; // Regex to match digits followed by 'm' or 'h'

    const match = input?.match(durationRegex);

    if (match) {
      const value = match[1];
      const unit = match[2];

      if (unit === 'm') {
        return `${value} minute${value > 1 ? 's' : ''}`;
      } else if (unit === 'h') {
        return `${value} hour${value > 1 ? 's' : ''}`;
      }
    }

    return 'Invalid duration format';
  }
  // function getPolicy() {
  //   if (props && props.deployInfo[0] && props.deployInfo[0].policies.length > 0) {
  //     setPolicyArr(props.deployInfo[0].policies);
  //   }
  // }

  // useEffect(() => {
  //   getPolicy();
  // }, [policyArr])

  function modifyPolicies(action, index, newData) {
    // //console.log(index);
    // Clone the existing array to avoid modifying the original array directly
    if (props && props.deployInfo[0]) {
      var parr = props.deployInfo[0].policies;
      const updatedPolicies = [...parr];

      switch (action) {
        case 'add':
          // Add a new policy to the array
          const newPolicy = {
            policyname: "Policy-" + makeid(5),
            policytype: "cpu",
            compare: "above",
            value: "90",
            adjust: "1",
            period: "5m",
            cooldown: "300"
          };
          updatedPolicies.push(newPolicy);
          break;
        case 'delete':
          // Delete a policy at the specified index
          if (index !== null && index >= 0 && index < updatedPolicies.length) {
            updatedPolicies.splice(index, 1);
          } else {
            console.error('Invalid index for deletion.');
          }
          break;
        case 'update':
          // Update a policy at the specified index with new data
          if (index !== null && index >= 0 && index < updatedPolicies.length) {
            updatedPolicies[index] = { ...updatedPolicies[index], ...newData };
          } else {
            console.error('Invalid index for update.');
          }
          break;
        default:
          console.error('Invalid action.');
          break;
      }

      // Log the updated array for verification (you can remove this in production)
      //console.log(updatedPolicies);

      // Return the updated array
      if (props && props.deployInfo[0]) {
        props.deployInfo[0].policies = updatedPolicies;
      }
      if (action != "update") {
        setPolicyArr(null);
      }
      return updatedPolicies;
    }
  }

  function updateAdjustmentValue(id, action, indexid, actionType, value) {
    const field = document.getElementById(id);
    if (field) {
      // Get the current value and convert it to a number
      let currentValue = parseFloat(field.value) || 0;

      // Update the value based on the action
      if (action === 'up') {
        currentValue += 1;
      } else if (action === 'down') {
        currentValue -= 1;
      }

      // Update the field with the new value
      if (actionType == "policy") {
        var compareIndex = document.querySelector("#compare" + indexid);
        console.log(compareIndex, "ok got it");
        if (compareIndex) {
          if (compareIndex.value == "below") {
            if (currentValue >= 0) {
              field.value = -1;
            } else {
              field.value = currentValue;
            }
          } else {
            if (currentValue <= 0) {
              field.value = 1;
            } else {
              field.value = currentValue;
            }
          }
        }
      } else {
        if (currentValue <= 0) {
          console.log("inside");
          field.value = 1;
        } else {
          field.value = currentValue;
        }
      }
    }
  }

  const manageAdjustment = (indexid, e) => {
    if (e.target.value == "above") {
      updateAdjustmentValue("policy_adjust" + indexid + "", "down", indexid, "policy");
    } else {
      updateAdjustmentValue("policy_adjust" + indexid + "", "up", indexid, "policy");
    }
  }

  const handleChangePolicyArr = (poolDataIndex, policyIndex, key, value) => {
    setPolicies(prevPolicies => {
      const updatedPolicies = [...prevPolicies];
      updatedPolicies[poolDataIndex].policies[policyIndex][key] = value;
      return updatedPolicies;
    });
  }

  return (
    <>
      {/* <div className='separator border-2'></div> */}
      <tr>
        <td class="text-start mt-3 ps-8">
          <h3 class="card-title align-items-start ">
            <span class="card-label text-gray-800 fs-4">Scaling Policy</span>
            <br /><span class="text-gray-400 mt-1 fw-bold fs-6 sphide">
              Configure your auto scaling policy here</span></h3>
        </td>
        <td class="text-end pe-10" colspan="6">
          <div class="" onClick={() => props.handleAddPolicy(props.index)}>
            <span class="btn btn-sm btn-primary" id="addnodepool">
              <span class="svg-icon svg-icon-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="black">
                  </rect><rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="black"></rect>
                </svg>
              </span>Add Policy</span>
          </div>
        </td>
      </tr>
      {props.poolData != null && (props.poolData && props.poolData.map((policiesArr, index) => {
        console.log(policiesArr.policies.slice(0, -1));
        return (
          <>
            {policiesArr.policies && policiesArr.policies.length > 0 && policiesArr.policies.map((value, index1) => (
              <>
                {props.index === index &&
                  <>
                    <tr>
                      <td colSpan={12} id={`policy_card_${value.policyName}`}>
                        <div class="separator separator-dotted separator-content border-primary my-15"><span class="h3 w-100px">Policy-{index1 + 1}</span></div>
                        {/* <h3 className="card-title align-items-start flex-column mb-5">
                          <span className="card-label text-gray-800 fs-4">
                            Scaling Policy
                          </span>
                          <br />
                          <span className="text-gray-400 mt-1 fw-bold fs-6">
                            Configure you auto scaling policy here
                          </span>
                        </h3> */}

                        <div
                          className="row g-9 ps-3"
                          data-kt-buttons="true"
                          data-kt-buttons-target="[data-kt-button]"
                        >
                          <div className="col-md-4 col-lg-4 col-xxl-4 d-none">
                            <div className="">
                              <label htmlFor="PolicyName" className="required form-label ps-3">
                                Policy Name
                              </label>
                              <input
                                type="text"
                                name={"nodepools[" + props.index + "][policies]=[" + index1 + "][policyName]"}
                                id={"policy_name" + value.policyName}
                                className="form-control"
                                placeholder="Enter Name"
                                onChange={() => props.nodePoolUpdate(value.policyName)}
                                defaultValue={value.policyName}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-lg-4 col-xxl-4 d-none">
                            <div className="">
                              <label htmlFor="type" className="required form-label ps-3">
                                Policy Type
                              </label>
                              <select className="form-select form-select-sm" id={"compare" + value.policyName} defaultValue={value.compare} onChange={(e) => { modifyPolicies("update", index, { policyname: value.policyname, policytype: value.policytype, compare: e.target.value, value: value.value, adjust: value.adjust, period: value.period, cooldown: value.cooldown }); manageAdjustment(index, e) }}>
                                <option value="above">Is above</option>
                                <option value="below">Is below</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4 col-lg-4 col-xxl-4">
                            <div className="">
                              <label htmlFor="type" className="required form-label ps-3">
                                Policy Type
                              </label>
                              <select className="form-select"
                                // disabled={props.isUpdated && index1 != 0}
                                name={"nodepools[" + props.index + "][policies]=[" + index1 + "][policyType]"}
                                id={"policy_type" + value.policyName}
                                onChange={(e) => {
                                  props.nodePoolUpdate(value.policyName)
                                  handleChangePolicyArr(index, index1, "policyType", e.target.value)
                                  setPolicyType(e.target.value)
                                }}
                                defaultValue={value.policyType}
                              >
                                <option value="cpu">CPU</option>
                                <option value="memory_utilization_percent">RAM</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4 col-lg-4 col-xxl-4">
                            <div className="">
                              <label htmlFor="Compare" className="required form-label ps-3">
                                Add Worker When {policies[index]?.policies[index1]?.policyType == "cpu" ? policies[index]?.policies[index1]?.policyType.toUpperCase() : "RAM"} Utilization (%)
                              </label>
                              <input
                                type="number"
                                name={"nodepools[" + props.index + "][policies]=[" + index1 + "][above_value]"}
                                id={"above_value" + value.policyName}
                                // disabled={props.isUpdated && index1 != 0}
                                className="form-control"
                                placeholder="Enter value"
                                min='0'
                                max="100"
                                onInput={(e) => {
                                  const inputValue = e.target.value;
                                  const parsedValue = parseInt(inputValue, 10);

                                  if (isNaN(parsedValue) || parsedValue < 0) {
                                    e.target.value = '';
                                    setAddWorker('0'); // You may need to update your state accordingly
                                  } else if (parsedValue > 100) {
                                    e.target.value = '100';
                                    setAddWorker('100'); // You may need to update your state accordingly
                                  } else {
                                    setAddWorker(inputValue);
                                  }
                                }}
                                onKeyDown={(e) => {
                                  console.log(e.key, e.target.value);
                                  // Allow only digits and prevent typing when value is already greater than or equal to 100
                                  if (
                                    (e.key >= '0' && e.key <= '9') ||
                                    (e.key === 'Backspace' || e.key === 'Delete') ||
                                    (e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Tab') ||
                                    (parseInt(e.target.value + e.key, 10) <= 100)
                                  ) {
                                    return true;
                                  } else {
                                    e.preventDefault();
                                  }
                                }}
                                onChange={(e) => {
                                  props.nodePoolUpdate(value.policyName, "Add Worker")
                                  handleChangePolicyArr(index, index1, "aboveValue", e.target.value)
                                  setAddWorker(e.target.value)
                                }}
                                defaultValue={value.aboveValue}
                              />
                              <span className='mt-5 text-gray-500 fs-8'>Please specify the {policies[index]?.policies[index1]?.policyType == "cpu" ? policies[index]?.policies[index1]?.policyType.toUpperCase() : "RAM"} utilization (%) for adding automatically a worker for your scale group & the maximum limit is 100</span>
                            </div>
                            {/* <span className={`text-danger fs-8 ${parseFloat(addWorker) <= 100 ? "d-none" : ""}`} id={`error_message1_${value.policyName}`}>Kindly adjust the add worker count before proceeding.</span> */}
                          </div>
                          <div className="col-md-4 col-lg-4 col-xxl-4">
                            <div className="">
                              <label htmlFor="PolicyName" className="required form-label ps-3">
                                Remove Worker When {policies[index]?.policies[index1]?.policyType == "cpu" ? policies[index]?.policies[index1]?.policyType.toUpperCase() : "RAM"} Utilization (%)
                              </label>
                              <input
                                type="number"
                                name={"nodepools[" + props.index + "][policies]=[" + index1 + "][below_value]"}
                                id={"below_value" + value.policyName}
                                className="form-control"
                                // disabled={props.isUpdated && index1 != 0}
                                placeholder="Enter value"
                                min='0'
                                max="100"
                                onInput={(e) => {
                                  const inputValue = e.target.value;
                                  const parsedValue = parseInt(inputValue, 10);

                                  if (isNaN(parsedValue) || parsedValue < 0) {
                                    e.target.value = '';
                                    setAddWorker('0'); // You may need to update your state accordingly
                                  } else if (parsedValue > 100) {
                                    e.target.value = '100';
                                    setAddWorker('100'); // You may need to update your state accordingly
                                  } else {
                                    setAddWorker(inputValue);
                                  }
                                }}
                                onKeyDown={(e) => {
                                  // Allow only digits and prevent typing when value is already greater than or equal to 100
                                  if (
                                    (e.key >= '0' && e.key <= '9') ||
                                    (e.key === 'Backspace' || e.key === 'Delete') ||
                                    (e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Tab') ||
                                    (parseInt(e.target.value + e.key, 10) <= 100)
                                  ) {
                                    return true;
                                  } else {
                                    e.preventDefault();
                                  }
                                }}
                                onChange={(e) => {
                                  props.nodePoolUpdate(value.policyName, "Remove Worker")
                                  handleChangePolicyArr(index, index1, "belowValue", e.target.value)
                                  setRemoveWorker(e.target.value)
                                }}
                                defaultValue={value.belowValue}
                              />
                              <span className='mt-5 text-gray-500 fs-8'>Please specify the {policies[index]?.policies[index1]?.policyType == "cpu" ? policies[index]?.policies[index1]?.policyType.toUpperCase() : "RAM"} utilization (%) for removing automatically a worker for your scale group & the maximum limit is 100</span>
                            </div>
                            {/* <span className={`text-danger fs-8 ${parseFloat(removeWorker) <= 100 ? "d-none" : ""}`} id={`error_message_${value.policyName}`}>Kindly adjust the remove worker count before proceeding.</span> */}
                          </div>
                          <div className="col-md-4 col-lg-4 col-xxl-4">
                            <div className="">
                              <label htmlFor="Compare" className="required form-label ps-3">
                                Period
                              </label>
                              <select className='form-select'
                                name={"nodepools[" + props.index + "][policies]=[" + index1 + "][policyPeriod]"}
                                id={"policy_period" + value.policyName}
                                // disabled={props.isUpdated && index1 != 0}
                                onChange={(e) => {
                                  props.nodePoolUpdate(value.policyName)
                                  handleChangePolicyArr(index, index1, "policyPeriod", e.target.value)
                                  setPeriod(e.target.value)
                                }}
                                defaultValue={value.policyPeriod}
                              >
                                <option value="5m">5 minutes</option>
                                <option value="10m">10 minutes</option>
                                <option value="30m">30 minutes</option>
                                <option value="1h">1 hour</option>
                                <option value="3h">3 hours</option>
                                <option value="6h">6 hours</option>
                                <option value="12h">12 hours</option>
                                <option value="24h">24 hours</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4 col-lg-4 col-xxl-4">
                            <div className="">
                              <label htmlFor="Compare" className="required form-label ps-3">
                                CoolDown
                              </label>
                              <div className="input-group mb-5">
                                <input
                                  type="number"
                                  name={"nodepools[" + props.index + "][policies]=[" + index1 + "][policyCooldown]"}
                                  id={"policy_cooldown" + value.policyName}
                                  className="form-control"
                                  // disabled={props.isUpdated && index1 != 0}
                                  placeholder="Enter Cooldown value"
                                  onChange={() => props.nodePoolUpdate(value.policyName)}
                                  defaultValue={value.policyCooldown}
                                  aria-describedby="basic-addon1"
                                />
                                <span className="input-group-text" style={{ lineHeight: "12px" }} id="basic-addon1">sec</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4 col-lg-4 col-xxl-4">
                            {/*begin::Dialer*/}
                            <label htmlFor="PolicyName" className="required form-label ps-3">
                              Adjust
                            </label>
                            <div className='d-flex'>
                              <div
                                className="input-group w-md-150px"
                                data-kt-dialer="true"
                              >
                                {/*begin::Decrease control*/}
                                <a
                                  className={`btn btn-icon btn-outline btn-outline-secondary`}
                                  name="poolAdjust"
                                  onClick={(e) => updateAdjustmentValue("policy_adjust" + value.policyName + "", "down", value.policyName, "policy", value)}
                                >
                                  <i className="bi bi-dash fs-1" />
                                </a>
                                {/*end::Decrease control*/}
                                {/*begin::Input control*/}
                                <input
                                  type="text"
                                  className="form-control text-center"
                                  name={"nodepools[" + props.index + "][policies]=[" + index1 + "][policyAdjust]"}
                                  id={"policy_adjust" + value.policyName}
                                  onChange={() => props.nodePoolUpdate(value.policyName)}
                                  readOnly={true}
                                  defaultValue={value.policyAdjust}
                                />
                                {/*end::Input control*/}
                                {/*begin::Increase control*/}
                                <a
                                  className={`btn btn-icon btn-outline btn-outline-secondary`}
                                  onClick={(e) => updateAdjustmentValue("policy_adjust" + value.policyName + "", "up", value.policyName, "policy")}
                                >
                                  <i className="bi bi-plus fs-1" />
                                </a>
                                {/*end::Increase control*/}
                              </div>
                              {policiesArr?.policies.length > 1 &&
                                <span className="btn btn-sm btn-danger m-auto" id={`delete_btn_${index1}`} onClick={() => props.handleDeletePolicy(props.index, index1, value.id, value.belowPolicyId)}>Delete policy</span>
                              }
                            </div>
                            {/*end::Dialer*/}
                          </div>
                          {props.isUpdated &&
                            <input
                              type='hidden'
                              name={"nodepools[" + props.index + "][policies]=[" + index1 + "][id]"}
                              value={value?.id}
                            />
                          }
                          {/* <span className='text-gray-800'>This scaler will watch out for {formatTimeDuration(policies[index].policies[index1]?.policyPeriod)}</span> */}
                          <span className='text-gray-800'>This scaler will watch out for {formatTimeDuration(policies[index].policies[index1]?.policyPeriod)} when the {policies[index]?.policies[index1]?.policyType == "cpu" ? policies[index]?.policies[index1]?.policyType.toUpperCase() : "RAM"} utilization (%) stays at above {policies[index]?.policies[index1]?.aboveValue} % or less {policies[index].policies[index1]?.belowValue} %. And such a condition occurs the scaling operation will be initiated.</span>
                          {/* {policiesArr?.policies && policiesArr?.policies?.length>0 && policiesArr?.policies?.slice(0, -1).map((policy, index) => (
                            // <div className="separator border-3 my-10" key={`separator-${index}`}></div>
                          ))} */}
                          {index1 < policiesArr?.policies.length - 1 && (
                            <div className="separator border-5 my-5" key={`separator-${index}`}></div>
                          )}
                        </div>
                      </td>
                    </tr>
                  </>
                }
              </>
            ))}</>
        )
      })
      )}
    </>
  )
}
