import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UseApi from "../../../../../CustomHooks/useApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UsePost from "../../../../../CustomHooks/usePost";
import swal from "sweetalert";
import TableLoader from "../../../Loaders/TableLoader";
import TopBarHeaderLine from "../../../../../CustomHooks/TopBarHeaderLine";
import UsePostData from "../../../../../CustomHooks/usePostData"; 
export default function Rules(props){
    var query = window.location.href;
    var vars = query.split("/");
    var id = vars[4];
    const [lbData, setLbData] = useState(null);
    useEffect(()=>{
        if(props && props.rulesData){
            setLbData(props.rulesData);
        }
    },[props])


  const chnageRule = () => {
    const src_proto = document.querySelector("#src_proto");
    const src_port = document.querySelector("#src_port");
    const dst_proto = document.querySelector("#dst_proto");
    const dst_port = document.querySelector("#dst_port");

    if (src_proto.value == "tcp") {
      dst_proto.innerHTML = "<option value='tcp'>TCP</option>";
      src_port.value = 80;
      dst_port.value = 80;
    }
    if (src_proto.value == "http") {
      dst_proto.innerHTML = "<option value='http'>HTTP</option>";
      src_port.value = 80;
      dst_port.value = 80;
    }
    if (src_proto.value == "https") {
      dst_proto.innerHTML = "<option value='https'>HTTPS</option>";
      src_port.value = 443;
      dst_port.value = 443;
    }
  }; 

    const addNewRule = async () => {
        toast.dismiss(); 
        const dt = toast.loading("Please wait...");
        const responseData = await UsePost(
          "post",
          "loadbalancer/" + id,
          "addrule"
        );
       
        if (responseData.status === "error") { 
          toast.update(dt, {
            render: responseData.message,
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          if(props && props.refreshLb){
            props.refreshLb();
          }
        } else { 
          toast.update(dt, {
            render: responseData.message,
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          var addrule_modal = document.querySelector("#addrule-modal-close");
if (addrule_modal) {
  addrule_modal.click();
}
          if(props && props.refreshLb){
            props.refreshLb();
          }
        }
      };
      const deleteRule = async (ruleid) => {
        toast.dismiss();
        swal({
          title: "Are you sure?",
          text: "Once deleted, you will not be able to recover this loadbalancer rule!",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then(async (willDelete) => {
          if (willDelete) { 
            const dt = toast.loading("Rule deleting...");
            const dnsp = await UseApi(
              "DELETE",
              "loadbalancer/" + id + "/rule/" + ruleid,
              ""
            );
            //console.log(dnsp);
            if (dnsp.status == "error") { 
              toast.update(dt, {
                render: dnsp.message,
                type: "warning",
                isLoading: false,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else if (dnsp.status == "success") { 
              toast.update(dt, {
                render: dnsp.message,
                type: "success",
                isLoading: false,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              if(props && props.refreshLb){
                props.refreshLb();
              }
            }
          }  
        });
      };

    return (<>
    <div className="card">
                {/*begin::incoming rules*/}
                <div className="">
                  <div className="tab-content">
                    {/*begin::Tap pane*/}
                    <div className="tab-pane fade show active" id="rule_tab_1">
                      {/*begin::API keys*/}
                      <div className="">
                        {/*begin::Card header*/}
                        <div className="card-header">
                          {/*begin::Heading*/}
                          <div className="card-title">
                            <h3>Load Balancer Rules</h3>
                          </div>
                          {/*end::Heading*/}
                          {/*begin::Toolbar*/}
                          <div className="card-toolbar">
                            <a
                              className="btn btn-sm btn-primary me-2"
                              data-bs-toggle="modal"
                              data-bs-target="#addrule-modal"
                            >
                              Add Rules
                            </a>
                          </div>
                          {/*end::Toolbar*/}
                        </div>
                        {/*end::Card header*/}
                      </div>
                      {/*begin::Table container*/}
                      <div className="table-responsive">
                        {/*begin::Table*/} 
                                <table className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9">
                                  {/*begin::Table head*/}
                                  <thead className="border-gray-200 fs-5 fw-bold bg-secondary">
                                    <tr className="fw-bolder">
                                      <th className="w-250px w-175px ps-9">
                                        Protocol
                                      </th>
                                      <th className="text-center w-275px w-250px px-0">
                                        Port
                                      </th>
                                      <th className="text-center w-275px w-250px px-0"></th>
                                    </tr>
                                  </thead>
                                  <tbody
                                    id="server_"
                                    className="fs-6 fw-bold text-gray-600"
                                  >
                                    {lbData != null &&
                                      lbData &&
                                      lbData.rules.map((value, index) => (
                                        <tr>
                                          <td className="fw-bold">
                                            {value.src_proto}
                                          </td>{" "}
                                          <td className="text-center fw-bold">
                                            <a className="badge badge-light-success me-1 me-5">
                                              {value.src_port}
                                            </a>
                                          </td>
                                          <td className="text-end fw-bold">
                                            <a
                                              onClick={() =>
                                                deleteRule(value.id)
                                              }
                                              className="btn btn-sm btn-danger"
                                            >
                                              Delete
                                            </a>
                                            {/*end::Svg Icon*/}
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              
                        {/*begin::Table body*/}
                      </div>
                    </div>
                    {/*end::Tap pane*/}
                    {/*end::Tap pane*/}
                  </div>
                </div>
                {/*end::incoming rules*/}
              </div>

              <div
            className="modal fade"
            id="addrule-modal"
            tabIndex={-1}
            style={{ display: "none" }}
          >
            {/*begin::Modal dialog*/}
            <div className="modal-dialog modal-dialog-centered mw-650px">
              {/*begin::Modal content*/}
              <div className="modal-content">
                {/*begin::Modal header*/}
                <div
                  className="modal-header"
                  id="kt_modal_create_api_key_header"
                >
                  {/*begin::Modal title*/}
                  <h2>Add Load Balancer Rules</h2>
                  {/*end::Modal title*/}
                  {/*begin::Close*/}
                  <div

                    className="btn btn-sm btn-icon btn-active-color-primary"
                    data-bs-dismiss="modal"
                    id="addrule-modal-close"
                  >
                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                    <span className="svg-icon svg-icon-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.5"
                          x={6}
                          y="17.3137"
                          width={16}
                          height={2}
                          rx={1}
                          transform="rotate(-45 6 17.3137)"
                          fill="black"
                        />
                        <rect
                          x="7.41422"
                          y={6}
                          width={16}
                          height={2}
                          rx={1}
                          transform="rotate(45 7.41422 6)"
                          fill="black"
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}
                  </div>
                  {/*end::Close*/}
                </div>
                {/*end::Modal header*/}
                {/*begin::Form*/}
                <form id="addrule" name="addrule" className="form">
                  {/*begin::Modal body*/}
                  <div className="modal-body py-10 px-lg-17">
                    {/*begin::Scroll*/}
                    <div
                      className="scroll-y me-n7 pe-7"
                      id="kt_modal_create_api_key_scroll"
                      data-kt-scroll="true"
                      data-kt-scroll-activate="{default: false, lg: true}"
                      data-kt-scroll-max-height="auto"
                      data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                      data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                      data-kt-scroll-offset="300px"
                    >
                      <div className="row">
                        <h2 className="text-center">Load Balancer</h2>
                        {/*begin::Input group*/}
                        <div className="col-lg-6 col-md-6 col-xxl-6">
                          <div className="d-flex flex-column mb-10 fv-row">
                            {/*begin::Label*/}
                            <label className="required fs-5 fw-bold mb-2 text-muted">
                              Protocol
                            </label>
                            {/*end::Label*/}
                            {/*begin::Select*/}
                            <select
                              className="form-select form-select-solid"
                              name="src_proto"
                              id="src_proto"
                              data-hide-search="true"
                              onClick={chnageRule}
                            >
                              <option value="tcp">TCP</option>
                              <option value="http">HTTP</option>
                              <option value="https">HTTPS</option>
                            </select>
                            {/*end::Select*/}
                          </div>
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="col-lg-6 col-md-6 col-xxl-6">
                          <div className="d-flex flex-column mb-10 fv-row">
                            {/*begin::Label*/}
                            <label className="required fs-5 fw-bold mb-2  text-muted">
                              Port
                            </label>
                            {/*end::Label*/}
                            {/*begin::Select*/}
                            <input
                              className="form-control"
                              type="text"
                              id="src_port"
                              name="src_port"
                              minvalue={1}
                              maxvalue={65444}
                              placeholder={80}
                              defaultValue={80}
                            />
                            {/*end::Select*/}
                          </div>
                        </div>
                        {/*end::Input group*/}
                      </div>
                      <div className="row">
                        <h2 className="text-center">Servers</h2>
                        {/*begin::Input group*/}
                        <div className="col-lg-6 col-md-6 col-xxl-6">
                          <div className="d-flex flex-column mb-10 fv-row">
                            {/*begin::Label*/}
                            <label className="required fs-5 fw-bold mb-2  text-muted">
                              Protocol
                            </label>
                            {/*end::Label*/}
                            {/*begin::Select*/}
                            <select
                              className="form-select form-select-solid "
                              name="dst_proto"
                              id="dst_proto"
                              data-hide-search="true"
                              onClick={chnageRule}
                            >
                              <option value="tcp">TCP</option>
                              <option value="http">HTTP</option>
                              <option value="https">HTTPS</option>
                            </select>
                            {/*end::Select*/}
                          </div>
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="col-lg-6 col-md-6 col-xxl-6">
                          <div className="d-flex flex-column mb-10 fv-row">
                            {/*begin::Label*/}
                            <label className="required fs-5 fw-bold mb-2  text-muted">
                              Port
                            </label>
                            {/*end::Label*/}
                            {/*begin::Select*/}
                            <input
                              className="form-control"
                              type="text"
                              id="dst_port"
                              name="dst_port"
                              minvalue={1}
                              maxvalue={65444}
                              placeholder={80}
                              defaultValue={80}
                            />
                            {/*end::Select*/}
                          </div>
                        </div>
                        {/*end::Input group*/}
                      </div>
                    </div>
                    {/*end::Scroll*/}
                  </div>
                  {/*end::Modal body*/}
                  {/*begin::Modal footer*/}
                  <div className="modal-footer flex-center">
                    {/*begin::Button*/}
                    <button
                      type="button"
                      onClick={addNewRule}
                      className="btn btn-primary w-100"
                    >
                      <span className="indicator-label">Add New Rule</span>
                    </button>
                    {/*end::Button*/}
                  </div>
                  {/*end::Modal footer*/}
                </form>
                {/*end::Form*/}
              </div>
              {/*end::Modal content*/}
            </div>
            {/*end::Modal dialog*/}
          </div>
    </>)
}