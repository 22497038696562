import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { IsCloudUpdate } from "../../../../../Redux/Services/Action/actions";
import { ToastContainer, toast } from 'react-toastify';
import useRazorpay from "react-razorpay";
import 'react-toastify/dist/ReactToastify.css';
import swal from 'sweetalert';
import UseApi from "../../../../../CustomHooks/useApi";
import UsePost from "../../../../../CustomHooks/usePost";
import spinner from "./../spinner.svg";
import TopBarHeader from "../../../../../CustomHooks/TopBarHeader";
import TopBarHeaderLine from "../../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../../Loaders/TableLoader";
import UsePostData from "../../../../../CustomHooks/usePostData";
import localStorage from "local-storage";
import CustomDoc from "../../../../../CustomHooks/CustomDoc";
import ProductsUrl from "../../../Docs/Docsdata";
const Resize = (props) => {
  const navigate = useNavigate();
  var query = window.location.href;
  var vars = query.split("/");
  var id = vars[4];
  const [planData, setPlanData] = useState(null);
  const [pageStatus, setPageStatus] = useState(false)
  const [diskPlanData, setDiskPlanData] = useState(null);
  const [width, setWidth] = useState(window.innerWidth >= 768 ? '10.4rem' : '10.4rem')
  const [resizeCloudName, setResizeCloudName] = useState('')
  const hour_nextinvoiceamount = useRef(0);
  const [invoiceData, setInvoiceData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [marginLeft, setMarginLeft] = useState(window.innerWidth >= 768 ? '' : '9rem');
  const [cpuWidth, setCpuWidth] = useState(window.innerWidth >= 768 ? '8.3rem' : '8.3rem')

  const dataFetchedRef = useRef(false);
  const dispatch = useDispatch();
  const user_data = useRef(null);
  const navRedirect = () => {
    setTimeout(() => {
      navigate("/cloud/" + id);
    }, 3000);
  }

  const getPlans = async () => {
     setResizeCloudName('RAM')
    setDiskPlanData(null);
    const data = await UseApi('get', 'cloud/' + id + '/resizeplans?withdisk=0', '');
    // user_data.current = await UseApi('get', 'account/info', '');
     if (Object.keys(data).length === 0) {
      setPlanData(null);
    } else {
       setPlanData(data.plans);
     }
  }
  const getResizePlans = async () => {
    setResizeCloudName("DISK")
    setPlanData(null);
    const data = await UseApi('get', 'cloud/' + id + '/resizeplans?withdisk=1', '');
    // user_data.current = await UseApi('get', 'account/info', '');
    // if (user_data.current && user_data.current) {
    //   var user_info = user_data.current;
    //   hour_nextinvoiceamount.current = user_info.nextinvoiceamount;
    // }
    if (Object.keys(data).length === 0) {
      setDiskPlanData(null);
    } else {
      setDiskPlanData(data.plans);
     }
  }
  useEffect(() => { 
    var user_info = localStorage.get('user_info');

     user_data.current = user_info;
    
    getPlans()
  }, [pageStatus]);

  const show_plan_price = (planid, planType) => {
    const plan_type = document.querySelector("#plan_type");
    const plan_value = document.querySelector("#plan_value");
    plan_type.value = planType;
    plan_value.value = planid;
    var planLabels = document.querySelectorAll(".tick-parent");
    var PlansTicks = document.querySelectorAll(".tickMarkPlan");
     planLabels.forEach((lfield) => {
      lfield.classList.remove("plan_list_active");
    })
    PlansTicks.forEach((tfield) => {
      tfield.classList.add("d-none");
    })
    var activeLabels = document.querySelector("#tick_parent_" + planid);
    var activePlansTicks = document.querySelector("#tickMarkPlan-" + planid);
    if (activeLabels && activePlansTicks) {
      activeLabels.classList.add("plan_list_active");
      activePlansTicks.classList.remove("d-none");
    }
    //const change_plan=document.querySelector('#change_plan_'+planid);
    // const tickParent = document.querySelector('#tick_parent_' + planid);
    // const tick2 = document.getElementsByClassName('tick_');
    // const tickmain = document.getElementsByClassName('tick-parent');
    // for (var i = 0; i < tickmain.length; i++) {
    //   tickmain[i].classList.remove("activeplan");
    // }
    // for (var i = 0; i < tick2.length; i++) {
    //   tick2[i].innerHTML = "";
    // }
    // // tickmain.classList.remove("activeplan");
    // tickParent.classList.remove("planseffect");
    // tickParent.classList.add("activeplan");
    // const tick = document.querySelector('#tick_' + planid);
    // tick.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg"  width={24} height={24} viewBox="0 0 24 24" fill="none" > <path  opacity="0.3" d="M10.3 14.3L11 13.6L7.70002 10.3C7.30002 9.9 6.7 9.9 6.3 10.3C5.9 10.7 5.9 11.3 6.3 11.7L10.3 15.7C9.9 15.3 9.9 14.7 10.3 14.3Z" fill="black" /><path d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM11.7 15.7L17.7 9.70001C18.1 9.30001 18.1 8.69999 17.7 8.29999C17.3 7.89999 16.7 7.89999 16.3 8.29999L11 13.6L7.70001 10.3C7.30001 9.89999 6.69999 9.89999 6.29999 10.3C5.89999 10.7 5.89999 11.3 6.29999 11.7L10.3 15.7C10.5 15.9 10.8 16 11 16C11.2 16 11.5 15.9 11.7 15.7Z" fill="black"  /> </svg>';

    //tick.innerHtml='<span classname="svg-icon svg-icon-success svg-icon-2hx"> <svg xmlns="http://www.w3.org/2000/svg"  width={24} height={24} viewBox="0 0 24 24" fill="none" > <path  opacity="0.3" d="M10.3 14.3L11 13.6L7.70002 10.3C7.30002 9.9 6.7 9.9 6.3 10.3C5.9 10.7 5.9 11.3 6.3 11.7L10.3 15.7C9.9 15.3 9.9 14.7 10.3 14.3Z" fill="black" /><path d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM11.7 15.7L17.7 9.70001C18.1 9.30001 18.1 8.69999 17.7 8.29999C17.3 7.89999 16.7 7.89999 16.3 8.29999L11 13.6L7.70001 10.3C7.30001 9.89999 6.69999 9.89999 6.29999 10.3C5.89999 10.7 5.89999 11.3 6.29999 11.7L10.3 15.7C10.5 15.9 10.8 16 11 16C11.2 16 11.5 15.9 11.7 15.7Z" fill="black"  /> </svg> </span> ';
   }
  const resizeCloud = async () => {
    toast.dismiss();
    swal({
      title: "Are you sure?",
      text: "Do you really want to resize your cloud server!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          setPageStatus(true)
          var submitBtn = document.querySelector("#resizeCloudServer")
          if(submitBtn){
          submitBtn.classList.add("disabled");
          submitBtn.innerHTML = '<span class="d-block fw-bold text-start"> <span classname="indicator-label sphide">Please wait...<span class="spinner-border spinner-border-sm align-middle ms-2"></span></span> </span>';
          const dt = toast.loading("cloud server resizing, Please wait...");
          const responseData = await UsePost('post', 'cloud/' + id + '/resize', 'cloudresize');
          // ////console.log(responseData);
          if (responseData.status == 'error') {
            setPageStatus(false)
            toast.update(dt, {
              render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
            submitBtn.classList.remove("disabled");
          } else if (responseData.status == 'success') {
            props.getCloud()
            setPageStatus(false)
            toast.update(dt, {
              render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });

            if (responseData.invoiceid) {
              openInvoice(responseData.invoiceid);
              const data = await UseApi('get', 'cloud/' + id, '');
              dispatch(props.updateCloudStatus(data.cloud[0]));
              navRedirect();

            }
          }
          submitBtn.classList.remove("disabled");
          submitBtn.innerHTML = 'Resize Cloud Server';
        }
        }
      });

  }

  const openInvoice = async (id) => {
    toast.dismiss();
    const invoice = await UseApi('get', 'billing/' + id, '');
    setInvoiceData(invoice.invoices[0]);
    ////console.log(invoice);
    const transTabel = document.querySelector("#trans-tabel");
    const invoiceCredit = document.querySelector("#invoice-credit");
    var userAmount = 0;
    if (userData != null) {
      userAmount = userData.availablecredit;
    }
    if (invoice.invoices[0]) {
      if (transTabel) {

        if (invoice.invoices[0].transs.length <= 0) {
          transTabel.classList.add("d-none");
        } else {
          transTabel.classList.remove("d-none");
        }
      }
      // //console.log(parseFloat(invoice.invoices[0].rowtotal));
      if (invoiceCredit) {
        if (parseFloat(userAmount) > parseFloat(invoice.invoices[0].rowtotal)) {
          invoiceCredit.classList.remove("d-none");
        } else {
          invoiceCredit.classList.add("d-none");
        }
      }
    }
    var drawerElement = document.querySelector("#invoice_drawer");
    drawerElement.classList.toggle("drawer-on");
    drawerElement.style.width = '1100px';

  }
  const applyCreditAmount = async (id) => {
    toast.dismiss();
    swal({
      title: "Are you sure?",
      text: "Once Applied, your available credit will reduce according to this amount!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          setPageStatus(true)
          const dt = toast.loading("Invoice processing...");
          const dnsp = await UsePost('post', 'billing/applycredit', 'applycredit');
          if (dnsp.status == 'error') {
            setPageStatus(false)
            toast.update(dt, {
              render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
          } else {
            setPageStatus(false)
            toast.update(dt, {
              render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
            navRedirect();
          }
        } else {
          swal({
            title: "Apply Credit",
            text: "Your not applied for the invoice!",
            icon: "success",
            button: "OK",
          });
        }
      });

  }
  const Razorpay = useRazorpay();
  const invoicePayment = useCallback(async () => {
    toast.dismiss();
    const payInvoiceBtn = document.querySelector("#invoice-payment-button");
    if (payInvoiceBtn) {
      payInvoiceBtn.value = "Loading...";
    }
    const u_data = await UseApi('get', 'account/info', '');
    const uData = u_data.user;
    const u_razorpay_info = await UseApi('get', 'mhcsecinfo', '');

    ////console.log(razorpay_info); 
    const amountFund = document.querySelector("#invoiceAmountFund");
    const descp_invoice_id = document.querySelector("#invoice_id");
    var invoice_id = "";
    //console.log(descp_invoice_id.value);
    if (descp_invoice_id) {
      invoice_id = descp_invoice_id.value;
    }
    var rorder_id = "";
    var rfullname = "";
    var rmobile = "";
    var remail = "";
    var ramount = 0;
    var rcurrency = "";
    if (amountFund) {
      ramount = amountFund.value;
    }

    if (uData) {
      rorder_id = uData.razorpay_orderid;
      rfullname = uData.fullname;
      rmobile = uData.mobile;
      remail = uData.email;
      rcurrency = uData.currency;
    }
    // //console.log(rorder_id);
    // //console.log(rfullname);
    // //console.log(rmobile);
    // //console.log(remail);
    // //console.log(ramount);
    // //console.log(invoice_id);
    const options = {
      //key: "rzp_live_aeMFERiIAsYFFF",
      key: u_razorpay_info.razorpay_key,
      amount: ramount * 100,
      currency: "INR",
      name: "Microhost Cloud",
      description: invoice_id,
      image: "https://www.microhost.com/assets/favicon/apple-touch-icon.png",
      prefill: {
        name: rfullname,
        email: remail,
        contact: rmobile,
      },
      options: {
        checkout: {
          method: {
            netbanking: 1,
            card: 0,
            upi: 1,
          }
        }
      },
      handler: async (res) => {
        //res.razorpay_payment_id
        //res.status_code
        //pay_L0C8DacFM1Voqi
        //console.log(res);
        if (res.status_code == 200) {
          swal({
            title: "Processing please wait...",
            icon: spinner,
            html: true,
            showSpinner: true
          });
          const resData = await UsePostData('post', 'billing/addpayment', { 'platform': '2023', 'invoiceid': invoice_id, 'transid': res.razorpay_payment_id, 'amount': ramount, 'currency': rcurrency, 'gateway': 'Razorpay' });
          if (resData.status == "success") {
            swal({
              title: "Invoice",
              text: "Invoice paid successfuly",
              icon: "success",
              button: "OK",
            });
            setTimeout(() => {
              window.location.reload();
            }, 4000);
          } else {
            swal({
              title: "Invoice",
              text: "Invoice not paid!",
              icon: "warning",
              button: "OK",
            });
          }

          //console.log(resData);
          // const paymentInfo=await axios.get('https://'+u_razorpay_info.razorpay_key+':'+u_razorpay_info.razorpay_secret+'@api.razorpay.com/v1/payments/'+res.razorpay_payment_id+'');
          // //console.log(paymentInfo);
        }
      },
      notes: {
        address: "Microhost Cloud- Fund add",
      },
      theme: {
        color: "#377dff",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
    if (payInvoiceBtn) {
      payInvoiceBtn.value = "Pay";
    }
  }, [Razorpay]);
  console.log(diskPlanData);

  useEffect(() => {
    const handleResize = () => {
      setMarginLeft(window.innerWidth >= 768 ? '' : '9rem')
      setWidth(window.innerWidth >= 768 ? '10.4rem' : '10.4rem')
      setCpuWidth(window.innerWidth >= 768 ? '8.3rem' : '8.3rem')
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <> 

      <div className="card">
        {/*begin::Header*/}
        <div className="card-header card-header-stretch">
          {/*begin::Title*/}
          <div className="card-title">
            <h3 className="me-5">Resize Cloud</h3>
            <CustomDoc url={ProductsUrl[0]?.cloud+"/manage-cloud/resize/"}/>
          </div>
          {/*end::Title*/}
          {/*begin::Toolbar*/}
          <div className="card-toolbar">
            
            <ul
              className="nav nav-stretch fs-5 fw-bold nav-line-tabs border-transparent"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <a
                  onClick={getPlans}
                  className="nav-link text-active-gray-800 me-4 active"
                  data-bs-toggle="tab"
                  role="tab"
                >
                  RAM,CPU PLANS
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  onClick={getResizePlans}
                  className="nav-link text-active-gray-800 me-4"
                  data-bs-toggle="tab"
                  role="tab"
                >
                  DISK,RAM,CPU PLANS
                </a>
              </li>
            </ul>
            {/*end::Tab nav*/}
          </div>
          {/*end::Toolbar*/}
        </div>
        {/*end::Header*/}
        <div className="">
          {/*begin::Body*/}
          {/*begin::Nav*/}
          <ul className="nav nav-pills nav-pills-custom row position-relative mx-0 mb-9">
            {/*begin::Item*/}
            <li className="nav-item col-2 mx-0 p-0">
              {/*begin::Link*/}
              <a
                className="nav-link active d-flex justify-content-center w-100 border-0 h-100"
                data-bs-toggle="pill"
                href="#basic-tab"
              >
                {/*begin::Subtitle*/}
                <span className="nav-text text-gray-800 fw-bolder fs-6 mb-3">
                  Basic
                </span>
                {/*end::Subtitle*/}
                {/*begin::Bullet*/}
                <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded" />
                {/*end::Bullet*/}
              </a>
              {/*end::Link*/}
            </li>
            {/*end::Item*/}
            {/*begin::Item*/}
            <li className="d-none nav-item col-2 mx-0 px-0">
              {/*begin::Link*/}
              <a
                className="nav-link d-flex justify-content-center w-100 border-0 h-100"
                data-bs-toggle="pill"
                href="#dedicated-standard-tab"
              >
                {/*begin::Subtitle*/}
                <span className="nav-text text-gray-800 fw-bolder fs-6 mb-3">
                  Dedicated Standard
                </span>
                {/*end::Subtitle*/}
                {/*begin::Bullet*/}
                <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded" />
                {/*end::Bullet*/}
              </a>
              {/*end::Link*/}
            </li>
            {/*end::Item*/}
            {/*begin::Item*/}
            <li className="nav-item col-2 mx-0 px-0">
              {/*begin::Link*/}
              <a
                className="nav-link justify-content-center border-0 h-100"
                data-bs-toggle="pill"
                href="#dedicated-memory-tab"
                style={{ width: width }}
              >
                {/*begin::Subtitle*/}
                <span className="nav-text text-gray-800 fw-bolder fs-6 mb-3 sphide">
                  Optimized Memory
                </span>
                {/*end::Subtitle*/}
                {/*begin::Bullet*/}
                <span className="bullet-custom position-absolute z-index-2 bottom-0 h-4px bg-primary rounded" style={{
                  width: width
                }} />
                {/*end::Bullet*/}
              </a>
              {/*end::Link*/}
            </li>
            {/*end::Item*/}
            {/*begin::Item*/}
            <li className="nav-item col-2 mx-0 px-0">
              {/*begin::Link*/}
              <a
                className="nav-link justify-content-center w-100 border-0 h-100"
                data-bs-toggle="pill"
                href="#dedicated-cpu-tab"
                style={{
                  marginLeft: marginLeft
                }}
              >
                {/*begin::Subtitle*/}
                <span className="nav-text text-gray-800 fw-bolder fs-6 mb-3 sphide">
                Optimized CPU
                </span>
                {/*end::Subtitle*/}
                {/*begin::Bullet*/}
                <span className="bullet-custom position-absolute z-index-2 bottom-0 h-4px bg-primary rounded" style={{ width: cpuWidth }} />
                {/*end::Bullet*/}
              </a>
              {/*end::Link*/}
            </li>
            {/*end::Item*/}
            {/*begin::Bullet*/}
            <span className="position-absolute z-index-1 bottom-0 w-100 h-4px bg-light rounded" />
            {/*end::Bullet*/}
          </ul>
          {/*end::Nav*/}
          {/*begin::Tab Content*/}
          <div className="tab-content">
            {/*begin::Tap pane*/}
            <div className="tab-pane fade show active" id="basic-tab">
              {/*begin::Radio group*/}
              <form id="cloudresize">
                <input
                  type="hidden"
                  name="type"
                  id="plan_type"
                  defaultValue=""
                />
                <input
                  type="hidden"
                  name="plan"
                  id="plan_value"
                  defaultValue=""
                />
              </form>
              <div className="row" data-kt-buttons="true">
                <div className="table-responsive" id="ramcpuplans">
                  {/*begin::Table*/}
                  <table className="table  table-row-bordered table-flush align-middle gy-6 tableramcpu">
                    {/*begin::Thead*/}
                    <thead className="border-bottom border-gray-200 fs-6 fw-bolder bg-secondary">
                      <tr>
                        <th className="w-50px ps-0 text-center" />
                        <th className="w-125px px-0 text-start">RAM</th>
                        <th className="w-125px text-center">vCPU</th>
                        <th className="w-125px text-center sphide">SSD Disk</th>
                        <th className="w-125px text-center">Bandwidth</th>
                        <th className="w-125px ps-0 text-center">Price</th>
                        <th className="w-125px ps-0 text-center" />
                      </tr>
                    </thead>
                    {/*end::Thead*/}
                    {/*begin::Tbody*/}
                    <tbody
                      id="basic-plan"
                      className="fs-6 fw-bold text-gray-600"
                    >
                      {resizeCloudName == "RAM" &&
                        <>
                          {planData != null ? (planData && planData.map((value, index) => (
                            <>
                              {value.slug == 'basic' ? (<>
                                <tr class="planseffect tick-parent" id={"tick_parent_" + value.id} onClick={() => show_plan_price(value.id, "ramcpu")}>
                                  <td class="ps-0 text-center">
                                    <span class="svg-icon svg-icon-success svg-icon-2hx tick_" id={"tick_" + value.id}></span>
                                  </td>
                                  <td class="ps-0 text-start">{(value.ram / 1024).toFixed(0)} GB</td>
                                  <td className="text-center">{value.cpu} vCPU</td>
                                  <td className="text-center">{value.disk} GB</td>
                                  <td className="text-center">{value.bandwidth} GB</td>
                                  <td className="text-center">
                                    {user_data.current != null ?
                                      (props.cloudData.billingcycle == 'hourly' ? (<>Hourly {user_data.current.currencyprefix} {parseFloat(parseInt(value.price) / 720).toFixed(2)}</>) :
                                        (props.cloudData.billingcycle == 'monthly' ? (<>Monthly {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 1)).toFixed(2)}</>) :
                                          (props.cloudData.billingcycle == '3month' ? (<>3 Months {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 3)).toFixed(2)}</>) :
                                            (props.cloudData.billingcycle == '6month' ? (<>6 Months {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 6)).toFixed(2)}</>) :
                                              (props.cloudData.billingcycle == '12month' ? (<>12 Months {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 12)).toFixed(2)}</>) :
                                                (props.cloudData.billingcycle == '24month' ? (<>24 Months {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 24)).toFixed(2)}</>) :
                                                  (props.cloudData.billingcycle == '36month' ? (<>36 Months {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 36)).toFixed(2)}</>) :
                                                    (''))))))
                                        )) : ('')}
                                  </td>
                                  <td className="ps-0 text-center ribbon ribbon-end ribbon-clip">
                                    <div
                                      className="d-none tickMarkPlan ribbon ribbon-triangle ribbon-top-start border-primary"
                                      id={"tickMarkPlan-" + value.id}
                                      style={{ marginTop: '-1px' }}
                                    >
                                      <div className="ribbon-icon mt-n5">
                                        <i className="bi bi-check2 fs-2 text-white" />
                                      </div>
                                    </div>
                                  </td>
                                  {/* <td className="ps-2">
                                    <a onClick={() => show_plan_price(value.id, "ramcpu")} id={"change_plan_" + value.id} class="btn btn-sm btn-primary fw-bold">Select</a>
                                  </td> */}
                                </tr>
                              </>) : ('')}
                            </>
                          ))) : <>
                            <tr>
                              <td colSpan={12}>
                                <TableLoader />
                              </td>
                            </tr>
                          </>}
                        </>}
                      {resizeCloudName == "DISK" &&
                        <>
                          {diskPlanData != null ? (diskPlanData && diskPlanData.map((value, index) => (
                            <>
                              {value.slug == 'basic' ? (<>
                                <tr class="planseffect tick-parent" id={"tick_parent_" + value.id} onClick={() => show_plan_price(value.id, "full")}>
                                  <td class="ps-0 text-center">
                                    <span class="svg-icon svg-icon-success svg-icon-2hx tick_" id={"tick_" + value.id}></span>
                                  </td>
                                  <td class="ps-0 text-start">{(value.ram / 1024).toFixed(0)} GB</td>
                                  <td className="text-center">{value.cpu} vCPU</td>
                                  <td className="text-center">{value.disk} GB</td>
                                  <td className="text-center">{value.bandwidth} GB</td>
                                  <td className="text-center">
                                    {user_data.current != null ?
                                      (props.cloudData.billingcycle == 'hourly' ? (<>Hourly {user_data.current.currencyprefix} {parseFloat(parseInt(value.price) / 720).toFixed(2)}</>) :
                                        (props.cloudData.billingcycle == 'monthly' ? (<>Monthly {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 1)).toFixed(2)}</>) :
                                          (props.cloudData.billingcycle == '3month' ? (<>3 Months {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 3)).toFixed(2)}</>) :
                                            (props.cloudData.billingcycle == '6month' ? (<>6 Months {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 6)).toFixed(2)}</>) :
                                              (props.cloudData.billingcycle == '12month' ? (<>12 Months {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 12)).toFixed(2)}</>) :
                                                (props.cloudData.billingcycle == '24month' ? (<>24 Months {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 24))}</>) :
                                                  (props.cloudData.billingcycle == '36month' ? (<>36 Months {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 36)).toFixed(2)}</>) :
                                                    (''))))))
                                        )) : ('')}
                                  </td>
                                  <td className="ps-0 text-center ribbon ribbon-end ribbon-clip">
                                    <div
                                      className="d-none tickMarkPlan ribbon ribbon-triangle ribbon-top-start border-primary"
                                      id={"tickMarkPlan-" + value.id}
                                      style={{ marginTop: '-1px' }}
                                    >
                                      <div className="ribbon-icon mt-n5">
                                        <i className="bi bi-check2 fs-2 text-white" />
                                      </div>
                                    </div>
                                  </td>
                                  {/* <td className="ps-2">
                                    <a onClick={() => show_plan_price(value.id, "full")} id={"change_plan_" + value.id} class="btn btn-sm btn-primary fw-bold">Select</a>
                                  </td> */}
                                </tr>
                              </>) : ('')}
                            </>
                          ))) : <>
                            <tr>
                              <td colSpan={12}>
                                <TableLoader />
                              </td>
                            </tr>
                          </>}
                        </>
                      }
                    </tbody>
                    {/*end::Tbody*/}
                  </table>
                  {/*end::Table*/}
                </div>
              </div>
              {/*end::Radio group*/}
              {/*begin::Card footer*/}
              <div className="card-footer d-flex justify-content-end py-6 px-20">
                <a
                  onClick={resizeCloud}
                  className="btn btn-primary fw-bold"
                  id="resizeCloudServer"
                >
                  Resize Cloud Server
                </a>
              </div>
              {/*end::Card footer*/}

            </div>
            {/*end::Tap pane*/}
            {/*begin::Tap pane*/}
            <div className="d-none tab-pane fade" id="dedicated-standard-tab">
              {/*begin::Radio group*/}

              <div className="row" data-kt-buttons="true">
                <div className="table-responsive">
                  {/*begin::Table*/}
                  <table className="table  table-row-bordered table-flush align-middle gy-6 tableramcpu">
                    {/*begin::Thead*/}
                    <thead className="border-bottom border-gray-200 fs-6 fw-bolder bg-secondary">
                      <tr>
                        <th className="w-50px ps-0 text-center" />
                        <th className="w-125px px-0 text-start">RAM</th>
                        <th className="w-125px text-center">vCPU</th>
                        <th className="w-125px text-center sphide">SSD Disk</th>
                        <th className="w-125px text-center">Bandwidth</th>
                        <th className="w-125px ps-0 text-center">Price</th>
                        <th className="w-125px ps-0 text-center" />
                      </tr>
                    </thead>
                    {/*end::Thead*/}
                    {/*begin::Tbody*/}
                    <tbody
                      id="standard-plan"
                      className="fs-6 fw-bold text-gray-600"
                    >
                      {planData != null && (planData && planData.map((value, index) => (
                        <>
                          {value.slug == 'dedicated-standard' ? (<>
                            <tr class="planseffect tick-parent" id={"tick_parent_" + value.id} onClick={() => show_plan_price(value.id, "ramcpu")}>
                              <td class="ps-0 text-center">
                                <span class="svg-icon svg-icon-success svg-icon-2hx tick_" id={"tick_" + value.id}></span>
                              </td>
                              <td class="ps-0 text-start">{(value.ram / 1024).toFixed(0)} GB</td>
                              <td className="text-center">{value.cpu} vCPU</td>
                              <td className="text-center">{value.disk} GB</td>
                              <td className="text-center">{value.disk} GB</td>
                              <td className="text-center">{value.bandwidth} GB</td>
                              <td className="text-center">
                                {user_data.current != null ?
                                  (props.cloudData.billingcycle == 'hourly' ? (<>Hourly {user_data.current.currencyprefix} {parseFloat(parseInt(value.price) / 720).toFixed(2)}</>) :
                                    (props.cloudData.billingcycle == 'monthly' ? (<>Monthly {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 1)).toFixed(2)}</>) :
                                      (props.cloudData.billingcycle == '3month' ? (<>3 Months {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 3)).toFixed(2)}</>) :
                                        (props.cloudData.billingcycle == '6month' ? (<>6 Months {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 6)).toFixed(2)}</>) :
                                          (props.cloudData.billingcycle == '12month' ? (<>12 Months {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 12)).toFixed(2)}</>) :
                                            (props.cloudData.billingcycle == '24month' ? (<>24 Months {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 24)).toFixed(2)}</>) :
                                              (props.cloudData.billingcycle == '36month' ? (<>36 Months {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 36)).toFixed(2)}</>) :
                                                (''))))))
                                    )) : ('')}
                              </td>
                              <td className="ps-0 text-center ribbon ribbon-end ribbon-clip">
                                <div
                                  className="d-none tickMarkPlan ribbon ribbon-triangle ribbon-top-start border-primary"
                                  id={"tickMarkPlan-" + value.id}
                                  style={{ marginTop: '-1px' }}
                                >
                                  <div className="ribbon-icon mt-n5">
                                    <i className="bi bi-check2 fs-2 text-white" />
                                  </div>
                                </div>
                              </td>
                              {/* <td className="ps-2">
                                <a onClick={() => show_plan_price(value.id, "ramcpu")} id={"change_plan_" + value.id} class="btn btn-sm btn-primary fw-bold">Select</a>
                              </td> */}
                            </tr>
                          </>) : ('')}
                        </>
                      )))}


                      {diskPlanData != null && (diskPlanData && diskPlanData.map((value, index) => (
                        <>
                          {value.slug == 'dedicated-standard' ? (<>
                            <tr class="planseffect tick-parent" id={"tick_parent_" + value.id} onClick={() => show_plan_price(value.id, "full")}>
                              <td class="ps-0 text-center">
                                <span class="svg-icon svg-icon-success svg-icon-2hx tick_" id={"tick_" + value.id}></span>
                              </td>
                              <td class="ps-0 text-start">{(value.ram / 1024).toFixed(0)} GB</td>
                              <td className="text-center">{value.cpu} vCPU</td>
                              <td className="text-center">{value.disk} GB</td>
                              <td className="text-center">{value.bandwidth} GB</td>
                              <td className="text-center">
                                {user_data.current != null ?
                                  (props.cloudData.billingcycle == 'hourly' ? (<>Hourly {user_data.current.currencyprefix} {parseFloat(parseInt(value.price) / 720).toFixed(2)}</>) :
                                    (props.cloudData.billingcycle == 'monthly' ? (<>Monthly {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 1)).toFixed(2)}</>) :
                                      (props.cloudData.billingcycle == '3month' ? (<>3 Months {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 3)).toFixed(2)}</>) :
                                        (props.cloudData.billingcycle == '6month' ? (<>6 Months {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 6)).toFixed(2)}</>) :
                                          (props.cloudData.billingcycle == '12month' ? (<>12 Months {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 12)).toFixed(2)}</>) :
                                            (props.cloudData.billingcycle == '24month' ? (<>24 Months {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 24)).toFixed(2)}</>) :
                                              (props.cloudData.billingcycle == '36month' ? (<>36 Months {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 36)).toFixed(2)}</>) :
                                                (''))))))
                                    )) : ('')}
                              </td>
                              <td className="ps-0 text-center ribbon ribbon-end ribbon-clip">
                                <div
                                  className="d-none tickMarkPlan ribbon ribbon-triangle ribbon-top-start border-primary"
                                  id={"tickMarkPlan-" + value.id}
                                  style={{ marginTop: '-1px' }}
                                >
                                  <div className="ribbon-icon mt-n5">
                                    <i className="bi bi-check2 fs-2 text-white" />
                                  </div>
                                </div>
                              </td>
                              {/* <td className="ps-2">
                                <a onClick={() => show_plan_price(value.id, "full")} id={"change_plan_" + value.id} class="btn btn-sm btn-primary fw-bold">Select</a>
                              </td> */}
                            </tr>
                          </>) : ('')}
                        </>
                      )))}

                    </tbody>
                    {/*end::Tbody*/}
                  </table>
                  {/*end::Table*/}
                </div>
              </div>
              {/*end::Radio group*/}
              {/*begin::Card footer*/}
              <div className="card-footer d-flex justify-content-end py-6 px-20">
                <a
                  onClick={resizeCloud}
                  className="btn btn-primary fw-bold"
                >
                  Resize Cloud Server
                </a>
              </div>
              {/*end::Card footer*/}
            </div>
            {/*end::Tap pane*/}
            {/*begin::Tap pane*/}
            <div className="tab-pane fade" id="dedicated-cpu-tab">
              {/*begin::Radio group*/}
              <div className="row" data-kt-buttons="true">
                <div className="table-responsive">
                  {/*begin::Table*/}
                  <table className="table  table-row-bordered table-flush align-middle gy-6 tableramcpu">
                    {/*begin::Thead*/}
                    <thead className="border-bottom border-gray-200 fs-6 fw-bolder bg-secondary">
                      <tr>
                        <th className="w-50px ps-0 text-center" />
                        <th className="w-125px px-0 text-start">RAM</th>
                        <th className="w-125px text-center">vCPU</th>
                        <th className="w-125px text-center sphide">SSD Disk</th>
                        <th className="w-125px text-center">Bandwidth</th>
                        <th className="w-125px ps-0 text-center">Price</th>
                        <th className="w-125px ps-0 text-center" />
                      </tr>
                    </thead>
                    {/*end::Thead*/}
                    {/*begin::Tbody*/}
                    <tbody
                      id="memory-plan"
                      className="fs-6 fw-bold text-gray-600"
                    >
                      {planData != null && (planData && planData.map((value, index) => (
                        <>
                          {value.slug == 'dedicated-cpu' ? (<>
                            <tr class="planseffect tick-parent" id={"tick_parent_" + value.id} onClick={() => show_plan_price(value.id, "ramcpu")}>
                              <td class="ps-0 text-center">
                                <span class="svg-icon svg-icon-success svg-icon-2hx tick_" id={"tick_" + value.id}></span>
                              </td>
                              <td class="ps-0 text-start">{(value.ram / 1024).toFixed(0)} GB</td>
                              <td className="text-center">{value.cpu} vCPU</td>
                              <td className="text-center">{value.disk} GB</td>
                              <td className="text-center">{value.bandwidth} GB</td>
                              <td className="text-center">
                                {user_data.current != null ?
                                  (props.cloudData.billingcycle == 'hourly' ? (<>Hourly {user_data.current.currencyprefix} {parseFloat(parseInt(value.price) / 720).toFixed(2)}</>) :
                                    (props.cloudData.billingcycle == 'monthly' ? (<>Monthly {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 1)).toFixed(2)}</>) :
                                      (props.cloudData.billingcycle == '3month' ? (<>3 Months {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 3)).toFixed(2)}</>) :
                                        (props.cloudData.billingcycle == '6month' ? (<>6 Months {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 6)).toFixed(2)}</>) :
                                          (props.cloudData.billingcycle == '12month' ? (<>12 Months {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 12)).toFixed(2)}</>) :
                                            (props.cloudData.billingcycle == '24month' ? (<>24 Months {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 24)).toFixed(2)}</>) :
                                              (props.cloudData.billingcycle == '36month' ? (<>36 Months {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 36)).toFixed(2)}</>) :
                                                (''))))))
                                    )) : ('')}
                              </td>
                              <td className="ps-0 text-center ribbon ribbon-end ribbon-clip">
                                <div
                                  className="d-none tickMarkPlan ribbon ribbon-triangle ribbon-top-start border-primary"
                                  id={"tickMarkPlan-" + value.id}
                                  style={{ marginTop: '-1px' }}
                                >
                                  <div className="ribbon-icon mt-n5">
                                    <i className="bi bi-check2 fs-2 text-white" />
                                  </div>
                                </div>
                              </td>
                              {/* <td className="ps-2">
                                <a onClick={() => show_plan_price(value.id, "ramcpu")} id={"change_plan_" + value.id} class="btn btn-sm btn-primary fw-bold">Select</a>
                              </td> */}
                            </tr>
                          </>) : ('')}
                        </>
                      )))}


                      {diskPlanData != null && (diskPlanData && diskPlanData.map((value, index) => (
                        <>
                          {value.slug == 'dedicated-cpu' ? (<>
                            <tr class="planseffect tick-parent" id={"tick_parent_" + value.id} onClick={() => show_plan_price(value.id, "full")}>
                              <td class="ps-0 text-center">
                                <span class="svg-icon svg-icon-success svg-icon-2hx tick_" id={"tick_" + value.id}></span>
                              </td>
                              <td class="ps-0 text-start">{(value.ram / 1024).toFixed(0)} GB</td>
                              <td className="text-center">{value.cpu} vCPU</td>
                              <td className="text-center">{value.disk} GB</td>
                              <td className="text-center">{value.bandwidth} GB</td>
                              <td className="text-center">
                                {user_data.current != null ?
                                  (props.cloudData.billingcycle == 'hourly' ? (<>Hourly {user_data.current.currencyprefix} {parseFloat(parseInt(value.price) / 720).toFixed(2)}</>) :
                                    (props.cloudData.billingcycle == 'monthly' ? (<>Monthly {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 1)).toFixed(2)}</>) :
                                      (props.cloudData.billingcycle == '3month' ? (<>3 Months {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 3)).toFixed(2)}</>) :
                                        (props.cloudData.billingcycle == '6month' ? (<>6 Months {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 6)).toFixed(2)}</>) :
                                          (props.cloudData.billingcycle == '12month' ? (<>12 Months {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 12)).toFixed(2)}</>) :
                                            (props.cloudData.billingcycle == '24month' ? (<>24 Months {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 24)).toFixed(2)}</>) :
                                              (props.cloudData.billingcycle == '36month' ? (<>36 Months {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 36)).toFixed(2)}</>) :
                                                (''))))))
                                    )) : ('')}
                              </td>
                              <td className="ps-0 text-center ribbon ribbon-end ribbon-clip">
                                <div
                                  className="d-none tickMarkPlan ribbon ribbon-triangle ribbon-top-start border-primary"
                                  id={"tickMarkPlan-" + value.id}
                                  style={{ marginTop: '-1px' }}
                                >
                                  <div className="ribbon-icon mt-n5">
                                    <i className="bi bi-check2 fs-2 text-white" />
                                  </div>
                                </div>
                              </td>
                              {/* <td className="ps-2">
                                <a onClick={() => show_plan_price(value.id, "full")} id={"change_plan_" + value.id} class="btn btn-sm btn-primary fw-bold">Select</a>
                              </td> */}
                            </tr>
                          </>) : ('')}
                        </>
                      )))}

                    </tbody>
                    {/*end::Tbody*/}
                  </table>
                  {/*end::Table*/}
                </div>
              </div>
              {/*end::Radio group*/}
              {/*begin::Card footer*/}
              <div className="card-footer d-flex justify-content-end py-6 px-20">
                <a
                  onClick={resizeCloud}
                  className="btn btn-primary fw-bold"
                >
                  Resize Cloud Server
                </a>
              </div>
              {/*end::Card footer*/}
            </div>
            {/*end::Tap pane*/}
            {/*begin::Tap pane*/}
            <div className="tab-pane fade" id="dedicated-memory-tab">
              {/*begin::Radio group*/}

              <div className="row" data-kt-buttons="true">
                <div className="table-responsive" id="ramcpuplans">
                  {/*begin::Table*/}
                  <table className="table table-row-bordered table-flush align-middle gy-6 tableramcpu">
                    {/*begin::Thead*/}
                    <thead className="border-bottom border-gray-200 fs-6 fw-bolder bg-secondary">
                      <tr>
                        <th className="w-50px ps-0 text-center" />
                        <th className="w-125px px-0 text-start">RAM</th>
                        <th className="w-125px text-center">vCPU</th>
                        <th className="w-125px text-center sphide">SSD Disk</th>
                        <th className="w-125px text-center">Bandwidth</th>
                        <th className="w-125px ps-0 text-center">Price</th>
                        <th className="w-125px ps-0 text-center" />
                      </tr>
                    </thead>
                    {/*end::Thead*/}
                    {/*begin::Tbody*/}
                    <tbody
                      id="cpu-plan"
                      className="fs-6 fw-bold text-gray-600"
                    >
                      {planData != null && (planData && planData.map((value, index) => (
                        <>
                          {value.slug == 'dedicated-memory' ? (<>
                            <tr class="planseffect tick-parent" id={"tick_parent_" + value.id} onClick={() => show_plan_price(value.id, "ramcpu")}>
                              <td class="ps-0 text-center">
                                <span class="svg-icon svg-icon-success svg-icon-2hx tick_" id={"tick_" + value.id}></span>
                              </td>
                              <td class="ps-0 text-start">{(value.ram / 1024).toFixed(0)} GB</td>
                              <td className="text-center">{value.cpu} vCPU</td>
                              <td className="text-center">{value.disk} GB</td>
                              <td className="text-center">{value.bandwidth} GB</td>
                              <td className="text-center">
                                {user_data.current != null ?
                                  (props.cloudData.billingcycle == 'hourly' ? (<>Hourly {user_data.current.currencyprefix} {parseFloat(parseInt(value.price) / 720).toFixed(2)}</>) :
                                    (props.cloudData.billingcycle == 'monthly' ? (<>Monthly {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 1)).toFixed(2)}</>) :
                                      (props.cloudData.billingcycle == '3month' ? (<>3 Months {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 3)).toFixed(2)}</>) :
                                        (props.cloudData.billingcycle == '6month' ? (<>6 Months {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 6)).toFixed(2)}</>) :
                                          (props.cloudData.billingcycle == '12month' ? (<>12 Months {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 12)).toFixed(2)}</>) :
                                            (props.cloudData.billingcycle == '24month' ? (<>24 Months {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 24)).toFixed(2)}</>) :
                                              (props.cloudData.billingcycle == '36month' ? (<>36 Months {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 36)).toFixed(2)}</>) :
                                                (''))))))
                                    )) : ('')}
                              </td>
                              <td className="ps-0 text-center ribbon ribbon-end ribbon-clip">
                                <div
                                  className="d-none tickMarkPlan ribbon ribbon-triangle ribbon-top-start border-primary"
                                  id={"tickMarkPlan-" + value.id}
                                  style={{ marginTop: '-1px' }}
                                >
                                  <div className="ribbon-icon mt-n5">
                                    <i className="bi bi-check2 fs-2 text-white" />
                                  </div>
                                </div>
                              </td>
                              {/* <td className="ps-2">
                                <a onClick={() => show_plan_price(value.id, "ramcpu")} id={"change_plan_" + value.id} class="btn btn-sm btn-primary fw-bold">Select</a>
                              </td> */}
                            </tr>
                          </>) : ('')}
                        </>
                      )))}


                      {diskPlanData != null && (diskPlanData && diskPlanData.map((value, index) => (
                        <>
                          {value.slug == 'dedicated-memory' ? (<>
                            <tr class="planseffect tick-parent" id={"tick_parent_" + value.id} onClick={() => show_plan_price(value.id, "full")}>
                              <td class="ps-0 text-center">
                                <span class="svg-icon svg-icon-success svg-icon-2hx tick_" id={"tick_" + value.id}></span>
                              </td>
                              <td class="ps-0 text-start">{(value.ram / 1024).toFixed(0)} GB</td>
                              <td className="text-center">{value.cpu} vCPU</td>
                              <td className="text-center">{value.disk} GB</td>
                              <td className="text-center">{value.bandwidth} GB</td>
                              <td className="text-center">
                                {user_data.current != null ?
                                  (props.cloudData.billingcycle == 'hourly' ? (<>Hourly {user_data.current.currencyprefix} {parseFloat(parseInt(value.price) / 720).toFixed(2)}</>) :
                                    (props.cloudData.billingcycle == 'monthly' ? (<>Monthly {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 1)).toFixed(2)}</>) :
                                      (props.cloudData.billingcycle == '3month' ? (<>3 Months {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 3)).toFixed(2)}</>) :
                                        (props.cloudData.billingcycle == '6month' ? (<>6 Months {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 6)).toFixed(2)}</>) :
                                          (props.cloudData.billingcycle == '12month' ? (<>12 Months {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 12)).toFixed(2)}</>) :
                                            (props.cloudData.billingcycle == '24month' ? (<>24 Months {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 24)).toFixed(2)}</>) :
                                              (props.cloudData.billingcycle == '36month' ? (<>36 Months {user_data.current.currencyprefix} {parseFloat(parseInt(value.price * 36)).toFixed(2)}</>) :
                                                (''))))))
                                    )) : ('')}
                              </td>
                              <td className="ps-0 text-center ribbon ribbon-end ribbon-clip">
                                <div
                                  className="d-none tickMarkPlan ribbon ribbon-triangle ribbon-top-start border-primary"
                                  id={"tickMarkPlan-" + value.id}
                                  style={{ marginTop: '-1px' }}
                                >
                                  <div className="ribbon-icon mt-n5">
                                    <i className="bi bi-check2 fs-2 text-white" />
                                  </div>
                                </div>
                              </td>
                              {/* <td className="ps-2">
                                <a onClick={() => show_plan_price(value.id, "full")} id={"change_plan_" + value.id} class="btn btn-sm btn-primary fw-bold">Select</a>
                              </td> */}
                            </tr>
                          </>) : ('')}
                        </>
                      )))}

                    </tbody>
                    {/*end::Tbody*/}
                  </table>
                  {/*end::Table*/}
                </div>
              </div>
              {/*end::Radio group*/}
              {/*begin::Card footer*/}
              <div className="card-footer d-flex justify-content-end py-6 px-20">
                <a
                  onClick={resizeCloud}
                  className="btn btn-primary fw-bold"
                >
                  Resize Cloud Server
                </a>
              </div>
              {/*end::Card footer*/}
            </div>
            {/*end::Tap pane*/}
          </div>
          {/*end::Tab Content*/}
        </div>
        {/*end: Card Body*/}
      </div>
      <div
        id="invoice_drawer"
        className="bg-white drawer drawer-end"
        data-kt-drawer="true"
        data-kt-drawer-overlay="true"
        data-kt-drawer-activate="true"
        data-kt-drawer-width="1200px"
        style={{ width: "1200px !important" }}
      >
        <div className=" container-fluid">
          {/*begin::Body*/}
          <div className=" p-5">
            {/*begin::Layout*/}
            <div className="d-flex flex-column flex-xl-row">
              {/*begin::Content*/}
              <div className="flex-lg-row-fluid me-xl-18 mb-10 mb-xl-0">
                {/*begin::Invoice 2 content*/}
                <div className="mt-n1">
                  {/*begin::Top*/}
                  <div className="d-flex flex-stack pb-10">
                    {/*begin::Logo*/}
                    <a >
                      <img
                        alt="Logo"
                        src="https://www.microhost.com/images/logo-dark-registered-cloud-blue.png"
                        width="200px"
                      />
                    </a>
                    {/*end::Logo*/}
                    {/*begin::Action*/}
                    <div id="payinvoice" />
                    <div id="paidinvoice" className="">
                      {invoiceData != null && invoiceData.status == "Paid" ? (<>
                        <button className="btn btn-success me-5">Paid</button>
                      </>) : (<>
                        <button className="btn btn-success me-5" id="invoice-payment-button" onClick={invoicePayment}>Pay</button>
                      </>)}



                      <button className="btn btn-light-danger me-5" onClick={openInvoice}>close</button>

                    </div>

                    {/*end::Action*/}
                  </div>
                  {/*end::Top*/}
                  {/*begin::Wrapper*/}
                  <div className="m-0">
                    {/*begin::Label*/}
                    <div className="fw-bolder fs-3 text-gray-800 mb-8">
                      Invoice <span>{invoiceData != null && invoiceData.id ? (invoiceData.id) : ('')}</span>
                    </div>
                    {/*end::Label*/}
                    {/*begin::Row*/}
                    <div className="row g-5 mb-11">
                      {/*end::Col*/}
                      <div className="col-sm-6">
                        {/*end::Label*/}
                        <div className="fw-bold fs-7 text-gray-600 mb-1">
                          Issue Date:
                        </div>
                        {/*end::Label*/}
                        {/*end::Col*/}
                        <div
                          className="fw-bolder fs-6 text-gray-800"
                          id="invoice-date"
                        >
                          {invoiceData != null && invoiceData.date ? (invoiceData.date) : ('')}
                        </div>
                        {/*end::Col*/}
                      </div>
                      {/*end::Col*/}
                      {/*end::Col*/}
                      <div className="col-sm-6"></div>
                      {/*end::Col*/}
                    </div>
                    {/*end::Row*/}
                    {/*begin::Row*/}
                    <div className="row g-5 mb-12">
                      {/*end::Col*/}
                      <div className="col-sm-6">
                        {/*end::Label*/}
                        <div className="fw-bold fs-7 text-gray-600 mb-1">
                          Issue For:
                        </div>
                        {/*end::Label*/}
                        {/*end::Text*/}
                        <div
                          className="fw-bolder fs-6 text-gray-800"
                          id="company_name"
                        >
                          {userData != null && userData.company ? (userData.company) : ('')}
                        </div>
                        {/*end::Text*/}
                        {/*end::Description*/}
                        <div className="fw-bold fs-7 text-gray-600">
                          <span id="user-address">{userData != null && userData.address ? (userData.address + ", ") : ('')} {userData != null && userData.city ? (userData.city + ", ") : ('')} {userData != null && userData.country ? (userData.country + ", ") : ('')}</span>
                          <br />
                          <span id="user-mobile" >{userData != null && userData.mobile ? (userData.mobile) : ('')}</span>
                          <br />
                          <span id="user-gst" >{userData != null && userData.gstnumber ? (userData.gstnumber) : ('')}</span>
                        </div>
                        {/*end::Description*/}
                      </div>
                      {/*end::Col*/}
                      {/*end::Col*/}
                      <div className="col-sm-6 text-end">
                        {/*end::Label*/}
                        <div className="fw-bold fs-7 text-gray-600 mb-1">
                          Issued By:
                        </div>
                        {/*end::Label*/}
                        {/*end::Text*/}
                        <div className="fw-bolder fs-6 text-gray-800">
                          Utho Platforms Private Limited
                        </div>
                        {/*end::Text*/}
                        {/*end::Description*/}
                        <div className="fw-bold fs-7 text-gray-600">
                          Utho Cloud
                          <br />
                          HD-204, WeWork, Berger Delhi One, Sector 16B, C-001/A2, Gautam Buddha Nagar, Noida 201301 Uttar Pradesh - India
                          <br />
                          <strong>GSTIN:</strong> 09AAICM9463A1ZB
                          <br />
                          <strong>PAN Number:</strong> AAICM9463A
                          <br />
                          <strong>SAC:</strong> 998315
                        </div>
                        {/*end::Description*/}
                      </div>
                      {/*end::Col*/}
                    </div>
                    {/*end::Row*/}
                    {/*begin::Content*/}
                    <div className="flex-grow-1">
                      {/*begin::Table*/}
                      <div className="table-responsive border mb-9">
                        <table className="table mb-3 table-row-bordered">
                          <thead className="bg-secondary">
                            <tr className="border-bottom fs-6 fw-bolder">
                              <th className="w-10px" />
                              <th className="w-175px pb-2 ">Item Description</th>
                              <th className="w-70px text-end pb-2" />
                              <th className="w-100px text-start pb-2">Amount</th>
                              <th className="w-100px text-start pb-2">Discount</th>
                            </tr>
                          </thead>
                          <tbody id="item-list">
                            {invoiceData != null ? (invoiceData.items != null ? (invoiceData.items.map((value, index) => (<>
                              <tr className="fw-bold text-gray-700">
                                <td></td>
                                <td className="d-flex align-items-center pt-6">{value.description}</td>
                                <td></td>
                                <td className="pt-6 fw-bold">{userData != null && userData.currencyprefix ? (userData.currencyprefix) : ('')} {value.amount}</td>
                                <td className="pt-6 fw-bold">{userData != null && userData.currencyprefix ? (userData.currencyprefix) : ('')} {value.discount ? (value.discount) : (0)}</td>
                              </tr>
                            </>))) : ('')) : ('')}
                          </tbody>
                        </table>
                      </div>
                      {/*end::Table*/}
                      {/*begin::Container*/}
                      <div className="d-flex row">


                        <div className="col-lg-8 col-md-8 col-xxl-8">
                          {invoiceData != null && invoiceData.status != "Paid" ? (<>
                            <div
                              className="d-flex flex-wrap flex-stack mb-6"
                              id="invoice-credit"
                            >

                              {" "}
                              <div className="d-flex my-2">
                                {" "}
                                <div className="d-flex align-items-center position-relative me-4">
                                  {" "}
                                  <form id="applycredit" name="applycredit">
                                    {" "}
                                    <div className="input-group mb-5" id="wallet">
                                      <input
                                        type="hidden"
                                        name="invoiceid"
                                        value={invoiceData != null && invoiceData ? (invoiceData.id) : ('')} />
                                      <span class="input-group-text" >{userData != null && userData.currencyprefix ? (userData.currencyprefix) : ('')}</span>  <input type="text" class="form-control" placeholder="amount" id="amount" name="amount" min="10" value={invoiceData != null && invoiceData.rowtotal ? (invoiceData.rowtotal) : ('')} /><a class="btn btn-primary btn-sm" id="applycredit-btn" onClick={applyCreditAmount}>Apply Credit</a>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </>) : (<></>)}
                        </div>


                        <div className="col-lg-4 col-md-4 col-xxl-4">
                          {/*begin::Section*/}
                          <div className="mw-300px" id="invoice-final">
                            {/*begin::Item*/}{" "}
                            <div className="d-flex flex-stack mb-3">
                              {/*begin::Accountname*/}
                              <div className="fw-bold pe-10 text-gray-600 fs-7">Subtotal:</div>
                              {/*end::Accountname*/}
                              {/*begin::Label*/}
                              <div className="text-end fw-bolder fs-6 text-gray-800" id="subtotal-amount">
                                {invoiceData != null && invoiceData.subtotal ? (invoiceData.subtotal) : ('')}
                              </div>
                              {/*end::Label*/}
                            </div>
                            {/*end::Item*/}

                            {invoiceData != null && invoiceData.taxrate != '0.00' && invoiceData.taxrate2 == '0.00' ? (<>
                              {/*begin::Item*/}{" "}
                              <div className="d-flex flex-stack mb-3">
                                {/*begin::Accountname*/}
                                <div className="fw-bold pe-10 text-gray-600 fs-7">{invoiceData.taxrate}% IGST</div>
                                {/*end::Accountname*/}
                                {/*begin::Label*/}
                                <div className="text-end fw-bolder fs-6 text-gray-800">{userData != null && userData.currencyprefix ? (userData.currencyprefix) : ('')} {invoiceData.tax}</div>
                                {/*end::Label*/}
                              </div>
                              {/*end::Item*/}

                            </>) : ('')}


                            {invoiceData != null && invoiceData.taxrate != '0.00' && invoiceData.taxrate2 != '0.00' ? (<>
                              {/*begin::Item*/}{" "}
                              <div className="d-flex flex-stack mb-3">
                                {/*begin::Accountname*/}
                                <div className="fw-bold pe-10 text-gray-600 fs-7">{invoiceData.taxrate}% CGST</div>
                                {/*end::Accountname*/}
                                {/*begin::Label*/}
                                <div className="text-end fw-bolder fs-6 text-gray-800">{userData != null && userData.currencyprefix ? (userData.currencyprefix) : ('')} {invoiceData.tax2}</div>
                                {/*end::Label*/}
                              </div>
                              {/*end::Item*/}

                            </>) : ('')}

                            {invoiceData != null && invoiceData.taxrate2 != '0.00' ? (<>
                              {/*begin::Item*/}{" "}
                              <div className="d-flex flex-stack mb-3">
                                {/*begin::Accountname*/}
                                <div className="fw-bold pe-10 text-gray-600 fs-7">{invoiceData.taxrate}% SGST</div>
                                {/*end::Accountname*/}
                                {/*begin::Label*/}
                                <div className="text-end fw-bolder fs-6 text-gray-800">{userData != null && userData.currencyprefix ? (userData.currencyprefix) : ('')} {invoiceData.tax2}</div>
                                {/*end::Label*/}
                              </div>
                              {/*end::Item*/}

                            </>) : ('')}

                            {invoiceData != null && invoiceData.credit != '0' ? (<>
                              {/*begin::Item*/}{" "}
                              <div className="d-flex flex-stack mb-3">
                                {/*begin::Accountname*/}
                                <div className="fw-bold pe-10 text-gray-600 fs-7">Credits</div>
                                {/*end::Accountname*/}
                                {/*begin::Label*/}
                                <div className="text-end fw-bolder fs-6 text-gray-800">{userData != null && userData.currencyprefix ? (userData.currencyprefix) : ('')} {invoiceData.credit}</div>
                                {/*end::Label*/}
                              </div>
                              {/*end::Item*/}

                            </>) : ('')}


                            {/*begin::Item*/}{" "}
                            <div className="d-flex flex-stack mb-3">
                              {/*begin::Accountname*/}
                              <div className="fw-bold pe-10 text-gray-600 fs-7">Total</div>
                              {/*end::Accountname*/}
                              {/*begin::Label*/}
                              <div className="text-end fw-bolder fs-6 text-gray-800" id="total-amount">
                                {userData != null && userData.currencyprefix ? (userData.currencyprefix) : ('')} {invoiceData != null && invoiceData.total}
                                <input type="hidden" id="invoiceAmountFund" value={invoiceData != null && invoiceData.total} />
                                <input type="hidden" id="invoice_id" value={invoiceData != null && invoiceData.id} />
                              </div>
                              {/*end::Label*/}
                            </div>
                            {/*end::Item*/}
                          </div>

                        </div>
                        {/*end::Section*/}
                      </div>
                      {/*end::Container*/}
                      <div id="trans-tabel" className="d-none">
                        <div className="fw-bolder fs-2">Transactions</div>
                        <table className="table mb-3 table-row-bordered">
                          <thead className="bg-secondary">
                            <tr className="border-bottom fs-6 fw-bolder">
                              <th className="w-175px pb-2 text-center">
                                Transaction Date
                              </th>
                              <th className="w-70px text-end pb-2">
                                Transaction Gateways
                              </th>
                              <th className="w-80px text-end pb-2">
                                Transaction id
                              </th>
                              <th className="w-100px text-center pb-2">
                                Transaction Amount
                              </th>
                            </tr>
                          </thead>
                          <tbody
                            className="fw-bold text-gray-600"
                            id="trans-list"
                          >
                            {invoiceData != null && invoiceData.transs.map((value, index) => (<>
                              <tr>
                                <td class="text-center">{value.date}</td>
                                <td class="text-end">{value.gateway}</td>
                                <td class="text-end">{value.transid}</td>
                                <td class="text-center">{userData != null && userData.currencyprefix ? (userData.currencyprefix) : ('')} {value.amountin ? (value.amountin) : (value.amountout)}</td>
                              </tr>
                            </>))}
                          </tbody>
                        </table>
                        {/*end::Table*/}
                        <div id="due-price" style={{ marginTop: 25 }} >
                          <div class="d-flex justify-content-end">
                            <div class="mw-300px" id="invoice-final">
                              <div class="d-flex flex-stack mb-3">
                                <div class="fw-bold pe-10 text-danger-600 fs-7">Due Amount</div>
                                <div class="text-end fw-bolder fs-6 text-danger-800">{userData != null && userData.currencyprefix ? (userData.currencyprefix) : ('')} </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*end::Content*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Invoice 2 content*/}
              </div>
              {/*end::Content*/}
            </div>
            {/*end::Layout*/}
          </div>
          {/*end::Body*/}
        </div>
      </div>

      {pageStatus &&
        <TopBarHeaderLine />
      }
    </>
  )
}

const mapStateToProps = state => ({
  cloudData: state.cloud.cloudDataInfo
})

const mapDispatchToProps = dispatch => ({
  updateCloudStatus: data => dispatch(IsCloudUpdate(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Resize)

