import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UsePost from "../../../../../CustomHooks/usePost";
import UseApi from "../../../../../CustomHooks/useApi";
import CustomDoc from "../../../../../CustomHooks/CustomDoc";
import ProductsUrl from "../../../Docs/Docsdata";
import TopBarHeaderLine from "../../../../../CustomHooks/TopBarHeaderLine";
import SubnetVpcManager from "./tab/subnetVpcManager/SubnetVpcManager";
import UseCallApi from "../../../../../CustomHooks/useCallApi";
import RouteTable from "./tab/routetable/RouteTable";
import SubnetNatGateway from "./tab/subnetNatGateway/SubnetNatGateway";

export default function ManageSubnet(props) {
  let navigate = useNavigate()
  var query = window.location.href;
  var vars = query.split("/");
  var subnetid = vars[5];

  const [vpcData, setVpcData] = useState(null);
  const [subnetData, setSubnetData] = useState(null);

  const getVPC = async () => {
    const datas = await UseApi('get', 'vpc', '');
    if (datas.status == "error" && datas.message == "No VPC.") {
      setVpcData([]);
    } else {
      setVpcData(datas?.vpc);
    }
  }

  const getSubnet = async () => {
    try {
      const response = await UseApi('get', `vpc/subnet/${subnetid}`, '');

      // Ensure the response is not empty and set the VPC data
      if (response && response.status !== "error") {
        setSubnetData(response);
      } else {
        setSubnetData(null); // or [] depending on your use case
      }
    } catch (error) {
      setSubnetData(null);
    }
  };

  useEffect(() => {
    getSubnet()
    getVPC()
  }, [subnetid])

  const addVpcSubnet = async () => {
    toast.dismiss();
    const dt = toast.loading("Please wait... System is processing your request.");
    const submitBtn = document.querySelector("#add-vpc-subnet");

    if (submitBtn) {
      const originalContent = submitBtn.innerHTML;
      toggleSubmitButton(submitBtn, true);

      try {
        const responseData = await UsePost("post", "subnet/create", "create-subnet-form");

        handleToast(responseData, dt);
        if (responseData.status === "success" && props?.getSubnets) {
          getSubnet()
        }
      } catch (error) {
        console.error("Error adding VPC subnet:", error);
      } finally {
        toggleSubmitButton(submitBtn, false, originalContent);
      }
    }
  };

  const handleToast = (responseData, dt) => {
    const toastType = responseData.status === "error" ? "error" : "success";
    const message = responseData.status === "error" ? responseData.message : responseData.message;

    toast.update(dt, {
      render: message,
      type: toastType,
      isLoading: false,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const toggleSubmitButton = (submitBtn, isDisabled, originalContent = '') => {
    if (isDisabled) {
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = `Please wait... <span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>`;
    } else {
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML = originalContent;
    }
  };

  const handleUpdateSubnet = (value, key) => {
    setSubnetData((prev) => ({
      ...prev,
      [key]: value
    }));
  }

  const destroySubnet = async () => {
    // Dismiss any previous toasts
    toast.dismiss();

    // Show a loading toast
    const dt = toast.loading("Please wait... System is processing your request.");

    // Find the submit button element
    const submitBtn = document.querySelector(`#destroy-btn`);

    if (submitBtn) {
      // Save the current button content and disable it while processing
      const preContent = submitBtn.innerHTML;
      toggleSubmitButton(submitBtn, true);
      try {
        // Send a POST request
        const responseData = await UseCallApi("delete", `vpc/subnet/${subnetid}`, "");

        // Check for errors in the response
        handleToast(responseData, dt);
        if (responseData.status === "success") {
          getSubnet()
          navigate('/vpc/subnets')
        }
      } catch (error) {
        console.error("Error adding VPC subnet:", error);
      } finally {
        toggleSubmitButton(submitBtn, false, preContent);
      }
    };
  }

  return (
    <>
      <div className="col-xl-12">
        {subnetData != null ? (
          <>
            <div className="">
              <div className="" id="appresult">
                <div className="card mb-6 mb-xl-9">
                  <div className="card-body pt-9 pb-0">
                    {/*begin::Details*/}
                    <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                      {/*begin: Pic*/}
                      <div className="me-7 mb-4 mt-1">
                        <div
                          id="flag-icon"
                          className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative"
                        >
                          {/*begin::Image input*/}
                          <div
                            className="image-input image-input-empty"
                            data-kt-image-input="true"
                          >
                            {" "}
                            <img
                              src={"/img/flag_" + subnetData.dclocation.dccc + ".png"}
                              className="image-input-wrapper w-100 h-45px"
                            />{" "}
                          </div>
                          {/*end::Image input*/}
                        </div>
                      </div>
                      {/*end::Pic*/}
                      {/*begin::Info*/}
                      <div className="flex-grow-1">
                        {/*begin::Title*/}
                        <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                          {/*begin::User*/}
                          <div className="d-flex flex-column">
                            {/*begin::Name*/}
                            <div className="d-flex align-items-center mb-1 d-none" id="update_host">
                              <form id="updateHostname" name="updateHostname">
                                {/*begin::Title*/}
                                <div className="d-flex">
                                  <input
                                    type="text"
                                    className="form-control form-control-sm  me-3 flex-grow-1"
                                    name="name"
                                    defaultValue={subnetData.name}
                                  />
                                  <input
                                    type={"button"}
                                    // onClick={() => ActionHostname('updateHost')}
                                    className="btn btn-primary btn-sm fw-bolder flex-shrink-0 me-2"
                                    value={"save"}
                                  />
                                  <input
                                    type={"button"}
                                    // onClick={() => ActionHostname('cancelHost')}
                                    className="btn btn-danger btn-sm fw-bolder flex-shrink-0"
                                    value={"cancel"}
                                  />

                                </div>
                              </form>
                              {/*end::Title*/}
                            </div>
                            <div className="d-flex align-items-center mb-1" id="showHost">
                              <a
                                // onClick={() => ActionHostname('showHost')}
                                style={{ cursor: "pointer" }}
                                className="text-gray-900 text-hover-primary fs-2 fw-bold me-1"
                                id="head-name"
                              >
                                {subnetData.name}
                              </a>
                            </div>
                            {/*end::Name*/}
                            {/*begin::Info*/}
                            <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                              <div
                                className="d-flex flex-wrap fw-bold mb-4 fs-5 text-gray-400"
                                id="location"
                              >
                                {subnetData.dclocation.location}
                              </div>
                            </div>
                            {/*end::Info*/}
                          </div>
                          <div className="d-flex mb-4 ">
                            <div className="text-gray-800 fs-1 me-3">Status: </div>
                            <a className=" btn btn-sm btn-success" style={{ cursor: "default" }}>
                              Running
                            </a>
                          </div>
                          {/*end::User*/}
                        </div>
                        {/*end::Title*/}
                      </div>
                      {/*end::Info*/}
                    </div>
                    {/*end::Details*/}
                    <div className="separator" />
                    {/*begin::Nav*/}
                    <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                      {/*begin::Nav item*/}
                      <li className="nav-item">
                        <a
                          className="nav-link text-active-primary py-5 me-6 active"
                          data-bs-toggle="tab"
                          href="#configuration"
                        >
                          Configuration
                        </a>
                      </li>
                      <li className="nav-item d-none">
                        <a
                          className="nav-link text-active-primary py-5 me-6"
                          data-bs-toggle="tab"
                          href="#vpc"
                        >
                          VPC
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link text-active-primary py-5 me-6"
                          data-bs-toggle="tab"
                          href="#nat_gateway"
                        >
                          Nat Gateway
                        </a>
                      </li>
                      <li className="nav-item d-none">
                        <a
                          className="nav-link text-active-primary py-5 me-6"
                          data-bs-toggle="tab"
                          href="#route_table"
                        >
                          Route Table
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" data-bs-toggle="tab" href="#destroy_vpc">
                          Destroy Subnet
                        </a>
                      </li>
                      {/*end::Nav item*/}
                    </ul>
                    {/*end::Nav*/}
                  </div>
                </div>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="configuration"
                    role="tabpanel"
                  >
                    {/*begin::License usage*/}
                    <div className="card">
                      {/*begin::Card header*/}
                      <div className="card-header">
                        {/*begin::Heading*/}
                        <div className="card-title">
                          <h3>Configuration</h3>
                        </div>
                        <div className="card-toolbar ">
                          <div className="d-flex justify-content-end py-6" style={{ marginRight: 1 }}>
                            <CustomDoc url={ProductsUrl[0]?.vpc + "/manage-vpc/"} />
                          </div>
                        </div>
                        {/*end::Heading*/}
                      </div>
                      {/*end::Card header*/}
                      <div className="" id="">
                        {/*begin::Form*/}
                        <form id="create-subnet-form" className="form">
                          {/*begin::Modal body*/}
                          <div className="modal-body ">
                            {/*begin::Scroll*/}
                            <div className=" me-n7 pe-7">
                              <div className="pt-0 row">
                                <div className="col-lg-12">
                                  {/*begin::Input group*/}
                                  <div className="mb-10 fv-row fv-plugins-icon-container">
                                    {/*begin::Label*/}
                                    <label className="required form-label">
                                      Subnet Name
                                    </label>
                                    {/*end::Label*/}
                                    {/*begin::Input*/}
                                    <input
                                      type="text"
                                      name="name"
                                      className="form-control mb-2"
                                      placeholder="Enter the name"
                                      value={subnetData.name}
                                      readOnly={true}
                                      onChange={(e) => { handleUpdateSubnet(e.target.value, "name") }}
                                    />
                                    {/*end::Input*/}
                                    {/*begin::Description*/}
                                    <div className="text-muted fs-7">
                                      A subnet name is required and recommended to be
                                      unique.
                                    </div>
                                    {/*end::Description*/}
                                    <div className="fv-plugins-message-container invalid-feedback" />
                                  </div>
                                  {/*end::Input group*/}
                                </div>
                                <div className="col-lg-12">
                                  {/*begin::Input group*/}
                                  <div className="mb-10 fv-row fv-plugins-icon-container">
                                    {/*begin::Label*/}
                                    <label className="required form-label">
                                      Choose VPC
                                    </label>
                                    {/*end::Label*/}
                                    {/*begin::Input*/}
                                    <input
                                      name="vpcid"
                                      id="vpc_id"
                                      type="text"
                                      className="form-select mb-2"
                                      onChange={(e) => { handleUpdateSubnet(e.target.value, "vpcid") }}
                                      value={subnetData.vpcid}
                                      readOnly={true}
                                    />
                                    {/* <option value='' disabled selected>Choose a vpc</option>
                                      {vpcData != null && vpcData?.length > 0 &&
                                        vpcData.map((value, index) => {
                                          return (
                                            <option value={value.id}>
                                              vpc-{value.id}
                                            </option>
                                          )
                                        })}
                                    </input> */}
                                    {/*end::Input*/}
                                    {/*begin::Description*/}
                                    <div className="text-muted fs-7">
                                      A VPC Id which will be attached with this subnet
                                    </div>
                                    {/*end::Description*/}
                                    <div className="fv-plugins-message-container invalid-feedback" />
                                  </div>
                                  {/*end::Input group*/}
                                </div>
                                <div className="col-lg-6">
                                  {/*begin::Input group*/}
                                  <div className="mb-10 fv-row fv-plugins-icon-container">
                                    {/*begin::Label*/}
                                    <label className="required form-label">
                                      Auto Assign Public IPv4 Address
                                    </label>
                                    {/*end::Label*/}
                                    {/*begin::Input*/}
                                    <input
                                      name="assign_public"
                                      type="text"
                                      defaultValue="monthly"
                                      className="form-select mb-2"
                                      readOnly={true}
                                      value={subnetData.assign_public}
                                      onChange={(e) => { handleUpdateSubnet(e.target.value, "type") }}
                                    />
                                    {/* <option value="enable">Enable</option>
                                      <option value="disable">Disable</option>
                                    </select> */}
                                    {/*end::Input*/}
                                    {/*begin::Description*/}
                                    <div className="text-muted fs-7">
                                      Select whether to assign a public IPv4 address automatically.
                                    </div>
                                    {/*end::Description*/}
                                    <div className="fv-plugins-message-container invalid-feedback" />
                                  </div>
                                  {/*end::Input group*/}
                                </div>
                                <div className="col-lg-6">
                                  {/*begin::Input group*/}
                                  <div className="mb-10 fv-row fv-plugins-icon-container">
                                    {/*begin::Label*/}
                                    <label className="required form-label">
                                      Default Subnet
                                    </label>
                                    {/*end::Label*/}
                                    {/*begin::Input*/}
                                    <input
                                      name="is_default"
                                      // defaultValue="monthly"
                                      className="form-select mb-2"
                                      readOnly={true}
                                      value={subnetData.is_default}
                                      onChange={(e) => { handleUpdateSubnet(e.target.value, "type") }}
                                    />
                                    {/* <option value="0">Yes</option>
                                      <option value="1">No</option>
                                    </input> */}
                                    {/*end::Input*/}
                                    {/*begin::Description*/}
                                    <div className="text-muted fs-7">
                                      Specify whether this is the default subnet for your VPC configuration.
                                    </div>
                                    {/*end::Description*/}
                                    <div className="fv-plugins-message-container invalid-feedback" />
                                  </div>
                                  {/*end::Input group*/}
                                </div>
                                <div className="col-lg-4">
                                  {/*begin::Input group*/}
                                  <div className="mb-10 fv-row fv-plugins-icon-container">
                                    {/*begin::Label*/}
                                    <label className="required form-label">
                                      Subnet Type
                                    </label>
                                    {/*end::Label*/}
                                    {/*begin::Input*/}
                                    <input
                                      type="text"
                                      name="type"
                                      defaultValue="monthly"
                                      className="form-select mb-2"
                                      readOnly={true}
                                      value={subnetData.type}
                                      onChange={(e) => { handleUpdateSubnet(e.target.value, "type") }}

                                    />
                                    {/* <option value="public">Public</option>
                                      <option value="private">Private</option>
                                    </select> */}
                                    {/*end::Input*/}
                                    {/*begin::Description*/}
                                    <div className="text-muted fs-7">
                                      A Subnet type can be public or private.
                                    </div>
                                    {/*end::Description*/}
                                    <div className="fv-plugins-message-container invalid-feedback" />
                                  </div>
                                  {/*end::Input group*/}
                                </div>
                                <div className="col-lg-4">
                                  {/*begin::Input group*/}
                                  <div className="mb-10 fv-row fv-plugins-icon-container">
                                    {/*begin::Label*/}
                                    <label className="required form-label">Network</label>
                                    {/*end::Label*/}
                                    {/*begin::Input*/}
                                    <input
                                      className="form-control"
                                      id="network_input"
                                      name="network"
                                      inputMode="text"
                                      placeholder="___.___.___.___"
                                      readOnly={true}
                                      value={subnetData.network}
                                      onChange={(e) => { handleUpdateSubnet(e.target.value, "network") }}
                                    />
                                    {/*end::Input*/}
                                    {/*begin::Description*/}
                                    <div className="text-muted fs-7">
                                      A network is the IP of the VPC.
                                    </div>
                                    {/*end::Description*/}
                                    <div className="fv-plugins-message-container invalid-feedback" />
                                  </div>
                                  {/*end::Input group*/}
                                </div>
                                <div className="col-lg-4">
                                  {/*begin::Input group*/}
                                  <div className="mb-10 fv-row fv-plugins-icon-container">
                                    {/*begin::Label*/}
                                    <label className="required form-label">IP Range</label>
                                    {/*end::Label*/}
                                    {/*begin::Input*/}
                                    <input
                                      type="text"
                                      name="size"
                                      readOnly={true}
                                      id="network_size"
                                      className="form-control mb-2"
                                      value={subnetData.size}
                                      onChange={(e) => { handleUpdateSubnet(e.target.value, "size") }}
                                    />
                                    {/*end::Input*/}
                                    {/*begin::Description*/}
                                    <div className="text-muted fs-7">
                                      This define the vpc ip-pool range.
                                    </div>
                                    {/*end::Description*/}
                                    <div className="fv-plugins-message-container invalid-feedback" />
                                  </div>
                                  {/*end::Input group*/}
                                </div>
                                <div className="col-lg-12 d-none">
                                  <a
                                    className="btn btn-primary mt-5 w-100"
                                    onClick={addVpcSubnet}
                                    id="add-vpc-subnet"
                                  >
                                    <span className="svg-icon svg-icon-muted svg-icon-1hx">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                      >
                                        <path
                                          opacity="0.3"
                                          d="M21 13H3C2.4 13 2 12.6 2 12C2 11.4 2.4 11 3 11H21C21.6 11 22 11.4 22 12C22 12.6 21.6 13 21 13Z"
                                          fill="black"
                                        />
                                        <path
                                          d="M12 22C11.4 22 11 21.6 11 21V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V21C13 21.6 12.6 22 12 22Z"
                                          fill="black"
                                        />
                                      </svg>
                                    </span>
                                    Update Order
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/*end::Scroll*/}
                          </div>
                          {/*end::Modal body*/}
                        </form>
                        {/*end::Form*/}
                      </div>
                    </div>
                    {/*end::License usage*/}
                  </div>
                  <div className="tab-pane fade" id="vpc" role="tabpanel">
                    <SubnetVpcManager subnetData={subnetData} />
                  </div>
                  <div className="tab-pane fade" id="nat_gateway" role="tabpanel">
                    <SubnetNatGateway subnetData={subnetData} />
                  </div>
                  <div className="tab-pane fade" id="route_table" role="tabpanel">
                    <RouteTable />
                  </div>
                  <div className="tab-pane fade" id="destroy_vpc" role="tabpanel">
                    {/*begin::Deactivate Account*/}
                    <div className="card">
                      {/*begin::Card header*/}
                      <div
                        className="card-header border-0 cursor-pointer"
                        role="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#kt_account_deactivate"
                        aria-expanded="true"
                        aria-controls="kt_account_deactivate"
                      >
                        <div className="card-title m-0">
                          <h3 className="fw-bold m-0">Destroy Subnet</h3>
                        </div>
                        <div className="card-toolbar">
                          <div className="d-flex justify-content-end py-6" style={{ marginRight: 1 }}>
                            <CustomDoc url={ProductsUrl[0]?.vpc + "/manage-vpc/#destroy"} />
                          </div>
                        </div>
                      </div>
                      {/*end::Card header*/}
                      {/*begin::Content*/}
                      <div id="kt_account_settings_deactivate" className="collapse show">
                        {/*begin::Form*/}
                        <form id="destroyform" className="form">
                          {/*begin::Card body*/}
                          <div className="card-body border-top p-9">
                            {/*begin::Notice*/}
                            <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
                              {/*begin::Icon*/}
                              {/*begin::Svg Icon | path: icons/duotune/general/gen044.svg*/}
                              <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <rect
                                    opacity="0.3"
                                    x={2}
                                    y={2}
                                    width={20}
                                    height={20}
                                    rx={10}
                                    fill="black"
                                  />
                                  <rect
                                    x={11}
                                    y={14}
                                    width={7}
                                    height={2}
                                    rx={1}
                                    transform="rotate(-90 11 14)"
                                    fill="black"
                                  />
                                  <rect
                                    x={11}
                                    y={17}
                                    width={2}
                                    height={2}
                                    rx={1}
                                    transform="rotate(-90 11 17)"
                                    fill="black"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}
                              {/*end::Icon*/}
                              {/*begin::Wrapper*/}
                              <div className="d-flex flex-stack flex-grow-1">
                                {/*begin::Content*/}
                                <div className="fw-bold">
                                  <h4 className="text-gray-900 fw-bold">
                                    This will destroy your Subnet.
                                  </h4>
                                  <div className="fs-6 text-gray-700">
                                    Note: This option will delete your Subnet from the server
                                    for permanently and this not be able to undo.
                                  </div>
                                </div>
                                {/*end::Content*/}
                              </div>
                              {/*end::Wrapper*/}
                            </div>
                            {/*end::Notice*/}
                            {/*begin::Form input row*/}
                            <div className="mb-10 d-none">
                              <label htmlFor="size" className="required form-label">
                                Your Subnet will be permanently destroyed. You will lose the
                                provisioned Network addresses.
                              </label>
                            </div>
                            {/*end::Form input row*/}
                          </div>
                          {/*end::Card body*/}
                          {/*begin::Card footer*/}
                          <div
                            className="card-footer d-flex justify-content-end py-6 px-9"
                            id="destroy_btn"
                          >
                            <button
                              id="destroy-btn"
                              onClick={() => destroySubnet()}
                              // onclick="call_api('DELETE','vpc/undefined/destroy','destroy','refdestroy','#vpc')"
                              type="button"
                              className="btn btn-danger btn-sm fw-bold"
                            >
                              Destroy Subnet
                            </button>
                          </div>
                          {/*end::Card footer*/}
                        </form>
                        {/*end::Form*/}
                      </div>
                      {/*end::Content*/}
                    </div>
                    {/*end::Deactivate Account*/}
                  </div>
                </div>
              </div>
            </div>
          </>) : (<><TopBarHeaderLine /></>)}
      </div>
    </>
  )
}
