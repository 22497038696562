import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../custom.css";
import UseApi from "../../../../../CustomHooks/useApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UsePost from "../../../../../CustomHooks/usePost";
import swal from "sweetalert";
import TableLoader from "../../../Loaders/TableLoader";
import TopBarHeaderLine from "../../../../../CustomHooks/TopBarHeaderLine";
import UsePostData from "../../../../../CustomHooks/usePostData";
export default function Setting(props) {
  var query = window.location.href;
  var vars = query.split("/");
  var id = vars[4];

  const [lbData, setLbData] = useState(null);
  const [lbParentData, setLbParentData] = useState(null);
  const [pageStatus, setPageStatus] = useState(false);
  const [currentCertificate, setCurrentCertificate] = useState(0);
  const [currentAlgorithm, setCurrentAlgorithm] = useState("roundrobin");
  const [sslData, setSslData] = useState(null);
  const dataFetchedRef = useRef(false);
  const [frontendid, setFrontendid] = useState(0);
  const getSsl = async () => {
    const datas = await UseApi('get', 'certificates', '');
    if (datas) {
      if (datas.rcode == "error") {
        const dt = toast.loading("SSL loading...");
        toast.update(dt, {
          render: datas.message,
          type: "warning",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setSslData([]);
        return;
      } else {
        setSslData(datas.certificates);
      }
    }
  }
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getSsl();
  }, []);

  useEffect(() => {
    if (props && props.lData) {
      setLbData(props.lData);
      setCurrentAlgorithm(props.lData.algorithm);
      setCurrentCertificate(props.lData.certificate_id)
      setFrontendid(props.lData.id);
      // console.log(props.lbParentsData, "parent data");
      setLbParentData(props.lbParentsData)
      setTimeout(() => {
        handleSrcProto();
      }, 1000)
    }
  }, [props])

  const updateLb = async () => {
    toast.dismiss();
    setPageStatus(true)
    const dt = toast.loading(
      "Updating the current Loadbalancer, Please wait..."
    );
    const responseData = await UsePost(
      "put",
      "loadbalancer/" + id + "/frontend/" + frontendid,
      "lbupdate"
    );
    if (responseData.status === "error") {
      setPageStatus(false)
      toast.update(dt, {
        render: responseData.message,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (props && props.refreshLb) {
        props.refreshLb();
      }
    } else {
      setPageStatus(false)
      toast.update(dt, {
        render: responseData.message,
        type: "success",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (props && props.refreshLb) {
        props.refreshLb();
      }
    }
  };

  function optionFormat(item) {
    if (!item.id) {
      return item.text;
    }

    var span = document.createElement('span');
    var serverValue = item.element.getAttribute('data-server-value');
    var template = '';
    template += ''
    template += item.text;

    span.innerHTML = template;

    return span;
  }


  const handleSrcProto = () => {
    var port = document.querySelector(`#src_port${frontendid}`)
    var src_proto = document.querySelector("#src_proto" + frontendid);
    var ssl_certificate_sec = document.querySelector("#ssl_certificate_sec" + frontendid);
    if (src_proto && ssl_certificate_sec) {
      if (src_proto.value == "https") {
        ssl_certificate_sec.classList.remove("d-none");
        // port.value = 443
      } else {
        ssl_certificate_sec.classList.add("d-none");
        // port.value = 80
      }
    }
  }

  console.log(lbData,"------------->>>><<<<<<<<");

  return (<>
    {lbData != null ? (<>
      <div className="card">
        {/*begin::Card header*/}
        <div
          className="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_deactivate"
          aria-expanded="true"
          aria-controls="kt_account_deactivate"
        >
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">Load Balancer Settings</h3>
          </div>
        </div>
        {/*end::Card header*/}
        {/*begin::Content*/}
        <div
          id="kt_account_settings_deactivate"
          className="collapse show"
        >
          <form id="lbupdate">
            <div className="container">
              {/*begin::Row*/}
              <div
                className="row g-9"
                data-kt-buttons="true"
                data-kt-buttons-target="[data-kt-button]"
                id="load_bal"
              >
                <div className="col-md-2 col-lg-2 col-xxl-2">
                  <div className="mb-5 fv-row">
                    <label className="required fs-5 fw-bold mb-2">Name</label>
                    <input type="text" className="form-control form-sm" id="name" name="name" defaultValue={lbData.name} />
                  </div>
                </div>
                <div className="col-md-2 col-lg-2 col-xxl-2">
                  <div className="mb-5 fv-row">
                    <label className="required fs-5 fw-bold mb-2">Protocol</label>
                    <select className="form-select" id={"src_proto" + frontendid} name="proto" onChange={(e) => handleSrcProto(e)} defaultValue={lbData.proto}>
                      <option value="http">HTTP</option>
                      {lbParentData != null && lbParentData.type == "network" ? (<>
                        <option value="tcp">TCP</option>
                      </>) : ('')}
                      <option value="https">HTTPS</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-2 col-lg-2 col-xxl-2">
                  <div className="mb-5 fv-row">
                    <label className="required fs-5 fw-bold mb-2">Port</label>
                    <input type="number" className="form-control form-sm" id={"src_port" + frontendid} name="port" defaultValue={lbData.port} />
                  </div>
                </div>

                <div id={"ssl_certificate_sec" + frontendid} className="col-md-3 col-lg-3 col-xxl-3 d-none">
                  <div className="mb-5 fv-row" >
                    <label className="required fs-5 fw-bold mb-2">SSL Certificate</label>

                    <select className="form-select"
                      id="certificate_id"
                      name="certificate_id"
                      value={currentCertificate}
                      onChange={(e) => { setCurrentCertificate(e.target.value) }}
                    >
                      <option value="0">Select SSL</option>
                      {sslData != null ? (<>
                        {sslData.map((value, index) => (<>
                          <option value={value.id}>{value.name}</option>
                        </>))}
                      </>) : ("")}
                    </select>
                  </div>
                </div>
                {handleSrcProto()}

                <div className="col-md-3 col-lg-3 col-xxl-3">
                  <div className="mb-5 fv-row">
                    <label className="required fs-5 fw-bold mb-2">Algorithm</label>
                    <select className="form-select" id="algorithm" onChange={(e) => { setCurrentAlgorithm(e.target.value) }} name="algorithm" value={currentAlgorithm}>
                      <option value="roundrobin">Round Robin</option>
                      <option value="leastconn">Least Connections</option>
                    </select>
                  </div>
                </div>
              </div>
              {/*end::Row*/}
              <div className="col-md-6 col-lg-12 col-xxl-6">
                {/*begin::Input group*/}
                <div className=" mt-10 d-flex flex-stack">
                  {/*begin::Label*/}
                  <div className="me-5">
                    <label className="fs-5 form-label">
                      Redirect to HTTPS
                    </label>
                    <div className="fs-6 fw-bold text-muted">
                      If you need to redirect all traffic to https, please check redirect to HTTPS
                    </div>
                  </div>
                  {/*end::Label*/}
                  {/*begin::Switch*/}
                  <label className="form-check form-switch form-check-custom form-check">
                    <input type="hidden" name="redirecthttps" defaultValue={lbData.redirecthttps} id={"redirect_https" + frontendid + "" + lbData.id} />
                    {lbData.redirecthttps == "1" ? (<>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue={1}
                        defaultChecked="checked"
                        onChange={(e) => {
                          document.getElementById("redirect_https" + frontendid + lbData.id).value = e.target.checked ? "1" : "0";
                        }}
                      />
                    </>) : (<>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue={0}
                        onChange={(e) => {
                          document.getElementById("redirect_https" + frontendid + lbData.id).value = e.target.checked ? "1" : "0";
                        }}
                      />
                    </>)}

                  </label>
                  {/*end::Switch*/}
                </div>
                {/*end::Input group*/}
              </div>
              <div className="col-md-6 col-lg-12 col-xxl-6">
                {/*begin::Input group*/}
                <div className="mt-10 d-flex flex-stack">
                  {/*begin::Label*/}
                  <div className="me-5">
                    <label className="fs-5 form-label">
                      Sticky Sessions
                    </label>
                    <div className="fs-6 fw-bold text-muted">
                      If you need cookie session for your algorithm, please check sticky sessions
                    </div>
                  </div>
                  {/*end::Label*/}
                  {/*begin::Switch*/}
                  <label className="form-check form-switch form-check-custom form-check">
                    <input type="hidden" name="cookie" defaultValue={lbData.cookie} id={"cookie" + frontendid + "" + lbData.id} />
                    {lbData.cookie == "1" ? (<>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue={1}
                        defaultChecked="checked"
                        onChange={(e) => {
                          document.getElementById("cookie" + frontendid + lbData.id).value = e.target.checked ? "1" : "0";
                        }}
                      />
                    </>) : (<>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue={0}
                        onChange={(e) => {
                          document.getElementById("cookie" + frontendid + lbData.id).value = e.target.checked ? "1" : "0";
                        }}
                      />
                    </>)}

                  </label>
                  {/*end::Switch*/}
                </div>
                {/*end::Input group*/}
              </div>
              {/*begin::Card footer*/}
              <div className="card-footer border-0 d-flex justify-content-end py-6 px-3">
                <button
                  onClick={updateLb}
                  type="button"
                  className="btn btn-sm btn-primary fw-bold"
                >
                  Update Setting
                </button>
              </div>
              {/*end::Card footer*/}
            </div>
          </form>
        </div>
        {/*end::Content*/}
      </div>
    </>) : (
      <>
      <TableLoader />
      <TopBarHeaderLine/>
    </>
  )}
  </>)
}