import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UseApi from '../../../../../CustomHooks/useApi';
import UsePostData from "../../../../../CustomHooks/usePostData";
import TableLoader from "../../../Loaders/TableLoader";

export default function Policy() {
  var query = window.location.href;
  var vars = query.split("/");
  var id = vars[4];
  const makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
  const [policyName, setPolicyName] = useState("Policy-" + makeid(6))
  const loaderFunc = () => {
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if (loader && no_record) {
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");

      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
  };
  function checkObjectForEmpty(obj) {
    for (const key in obj) {
      const value = obj[key];

      if (typeof value === 'string' && value.trim() === '') {
        return `Error: '${key}' should not be empty or blank.`;
      }

      if (typeof value === 'object') {
        const nestedError = checkObjectForEmpty(value);
        if (nestedError) {
          return nestedError;
        }
      }
    }

    return null; // No empty or blank elements found
  }

  const [policyEle, setPolicyEle] = useState({});
  const [policyArr, setPolicyArr] = useState(null);
  const [pageStatus, setPageStatus] = useState(false)

  const getAutoScalingPolicy = async () => {

    // const data = await UseApi("get", "autoscaling/" + "scaling_group/" + id + "/policy", "");

    const data = await UseApi("get", `autoscaling/policy?product=scaling_group&productid=${id}`, "")
    if (data) {
      if (data.rcode == "error") {
        const dt = toast
        toast.update(dt, {
          render: data.message,
          type: "warning",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setPolicyArr(null);
        loaderFunc();
        return;
      } else {
        var groupdata = data.policies;
        setPolicyArr(groupdata?.reverse());
      }
    }

  };

  useEffect(() => {
    getAutoScalingPolicy();
  }, [policyEle, pageStatus])

  function updateAdjustmentValue(id, action, actionType) {
    const field = document.getElementById(id);

    if (field) {
      // Get the current value and convert it to a number
      let currentValue = parseFloat(field.value) || 0;

      // Update the value based on the action
      if (action === 'up') {
        currentValue += 1;
      } else if (action === 'down') {
        currentValue -= 1;
      }

      // Update the field with the new value
      if (actionType == "add") {
        var compareIndex = document.querySelector("#add_compare");
        if (compareIndex) {
          if (compareIndex.value == "below") {
            if (currentValue >= 0) {
              field.value = -1;
            } else {
              field.value = currentValue;
            }
          } else {
            if (currentValue <= 0) {
              field.value = 1;
            } else {
              field.value = currentValue;
            }
          }
        }
      } else {
        var compareIndex = document.querySelector("#update_compare");
        if (compareIndex) {
          if (compareIndex.value == "below") {
            if (currentValue >= 0) {
              field.value = -1;
            } else {
              field.value = currentValue;
            }
          } else {
            if (currentValue <= 0) {
              field.value = 1;
            } else {
              field.value = currentValue;
            }
          }
        }
      }
    }
  }


  const manageAdjustment = (adjustType, e) => {
    if (e.target.value == "above") {
      updateAdjustmentValue(adjustType + "_adjust", "down", adjustType);
    } else {
      updateAdjustmentValue(adjustType + "_adjust", "down", adjustType)
    }
  }



  const updateAutoScalingPolicy = async (pid) => {
    //console.log(pid, "log====>><<<");
    const dt = toast.loading("Please wait...");
    var update_name1 = document.querySelector("#update_name1");
    var update_type = document.querySelector("#update_type");
    var update_compare = document.querySelector("#update_compare");
    var update_value = document.querySelector("#update_value");
    var update_adjust = document.querySelector("#update_adjust");
    var update_period = document.querySelector("#update_period");
    var update_cooldown = document.querySelector("#update_cooldown");
    if (update_value?.value <= 0) {
      setTimeout(() => {
        toast.update(dt, {
          render: 'Value should not be zero',
          type: 'error',
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }, 1000)
      return;
    }
    if (update_cooldown?.value <= 0) {
      setTimeout(() => {
        toast.update(dt, {
          render: 'Cooldown value should be greater then zero and above than zero',
          type: 'error',
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }, 1000)
      return;
    }

    //console.log(update_name1, update_adjust, update_compare, "------------->>>>>>>>><<<<<<<<<<<<<<");
    toast.dismiss();
    if (update_name1 && update_type && update_compare && update_value && update_adjust && update_period && update_cooldown) {
      const submitBtn = document.querySelector("#update-policy-btn");
      var updateData = { "name": update_name1.value, "type": update_type.value, "compare": update_compare.value, "value": update_value.value, "adjust": update_adjust.value, "period": update_period.value, "cooldown": update_cooldown.value };
      const validationError = checkObjectForEmpty(updateData);

      if (validationError) {
        toast.update(dt, {
          render: validationError,
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
      if (submitBtn) {
        var preContent = submitBtn.innerHTML;
        submitBtn.classList.add("disabled");
        submitBtn.innerHTML =
          'Please wait... <span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';

        const responseData = await UsePostData("put", "autoscaling/" + "policy/" + pid, updateData);

        ////console.log(responseData);
        if (responseData.status === "error") {
          toast.update(dt, {
            render: responseData.message,
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (responseData.status === "success") {
          toast.update(dt, {
            render: responseData.message,
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          openDrawerUpdate();
          getAutoScalingPolicy();
        }
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
      }
    }
  };

  const addAutoScalingPolicy = async () => {
    toast.dismiss();
    const dt = toast.loading("Please wait...");
    var update_name1 = document.querySelector("#add_name");
    var update_type = document.querySelector("#add_type");
    var update_compare = document.querySelector("#add_compare");
    var update_value = document.querySelector("#add_value");
    var update_adjust = document.querySelector("#add_adjust");
    var update_period = document.querySelector("#add_period");
    var update_cooldown = document.querySelector("#add_cooldown");
    // //console.log(update_name1, "updated_name");
    if (update_value?.value <= 0 || update_cooldown?.value <= 0) {
      setTimeout(() => {
        toast.update(dt, {
          render: 'Value should not be zero',
          type:'error',
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }, 1000)
      return;
    }
    if (update_name1 && update_type && update_compare && update_value && update_adjust && update_period && update_cooldown) {
      const submitBtn = document.querySelector("#add-policy-btn");
      var updateData = { "name": update_name1.value, "type": update_type.value, "compare": update_compare.value, "value": update_value.value, "adjust": update_adjust.value, "period": update_period.value, "cooldown": update_cooldown.value, "product": "scaling_group", "productid": id };
      const validationError = checkObjectForEmpty(updateData);

      if (validationError) {
        setTimeout(() => {
          toast.update(dt, {
            render: validationError,
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }, 2000)
        return;
      }
      if (submitBtn) {
        var preContent = submitBtn.innerHTML;
        submitBtn.classList.add("disabled");
        submitBtn.innerHTML =
          'Please wait... <span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';

        const responseData = await UsePostData("post", "autoscaling/policy", updateData);

        ////console.log(responseData);
        if (responseData.status === "error") {
          toast.update(dt, {
            render: responseData.message,
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (responseData.status === "success") {
          toast.update(dt, {
            render: responseData.message,
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          const scalingForm = document.querySelector('#addScaling');
          scalingForm.reset();
          setPolicyArr(null);
          getAutoScalingPolicy();
        }
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
        openDrawer()
      }
    }
  };

  const deleteAutoScalingPolicy = async (pid) => {
    toast.dismiss();
    const dt = toast.loading("Please wait...");
    const submitBtn = document.querySelector("#delete-policy-btn-" + pid);
    if (submitBtn) {
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML =
        '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';

      const responseData = await UseApi("delete", "autoscaling/policy/" + pid, "");

      ////console.log(responseData);
      if (responseData.status === "error") {
        toast.update(dt, {
          render: responseData.message,
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (responseData.status === "success") {
        toast.update(dt, {
          render: responseData.message,
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setPolicyArr(null);
        getAutoScalingPolicy();
      }
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML = preContent;
    }
  };

  function togglePolicy() {
    var add_policy_sec = document.querySelector("#add_policy_sec");
    if (add_policy_sec) {
      add_policy_sec.classList.toggle("d-none");
    }
  }

  const openDrawer = () => {
    // var add_name = document.querySelector("#add_name");
    var drawerElement = document.querySelector("#add-policy-modal");
    var drawer_overlay = document.querySelector("#policy_drawer_overlay");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
        drawerElement.style.width = "100%";
      } else {
        drawerElement.style.width = "40%";
      }
      drawer_overlay.classList.toggle("d-none");
    }
    const scalingForm = document.querySelector('#addScaling');
    scalingForm.reset();
    let policy_name = "Policy-" + makeid(6)
    setPolicyName(policy_name)
    // if (add_name) {
    //   add_name.defaultValue = ''
    // }
  };
  const openDrawerUpdate = () => {
    var drawerElement = document.querySelector("#update-policy-modal");
    var drawer_overlay = document.querySelector("#policy_update_drawer_overlay");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
        drawerElement.style.width = "100%";
      } else {
        drawerElement.style.width = "40%";
      }
      drawer_overlay.classList.toggle("d-none");
    }
  };

  const handleChangeState = (value) => {
    setPolicyEle({
      "id": value.id,
      "name": value.name,
      "type": value.type,
      "compare": value.compare,
      "period": value.period,
      "value": value.value,
      "cooldown": value.cooldown
    });
  }

  const updateP = (pdata) => {
    openDrawerUpdate();
    var update_name1 = document.querySelector("#update_name1");
    var update_type = document.querySelector("#update_type");
    var update_compare = document.querySelector("#update_compare");
    var update_value = document.querySelector("#update_value");
    var update_adjust = document.querySelector("#update_adjust");
    var update_period = document.querySelector("#update_period");
    var update_cooldown = document.querySelector("#update_cooldown");
    //console.log(policyEle, pdata,"->>>>>>>>>>><<<<<<<<<<");
    //console.log(update_name1, update_type, update_value, update_adjust, update_period, update_cooldown, update_compare, update_type, "----------->>>>>>>>><<<<<<<<<<<<F");
    if (update_name1 && update_type && update_compare && update_value && update_adjust && update_period && update_cooldown) {
      update_name1.value = pdata.name;
      update_type.value = pdata.type;
      update_compare.value = pdata.compare;
      update_value.value = pdata.value;
      update_adjust.value = pdata.adjust;
      update_period.value = pdata.period;
      update_cooldown.value = pdata.cooldown;
    }
  }
  return (<>
    <div className="col-xl-12 card mb-5">
      <div className="card-header border-3 mb-5 pt-2">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder text-gray-800">Scaling Policy</span>

          <span className="text-gray-400 mt-1 fw-bold fs-6">
            Configure your auto scaling policy here
          </span>
        </h3>
        <div className='card-toolbar'>
          <a
            onClick={openDrawer}
            className="btn btn-sm btn-primary"
            style={{ marginRight: 20 }}
          >
            {/*begin::Svg Icon | path: assets/media/icons/duotune/files/fil013.svg*/}
            <span className="svg-icon svg-icon-muted svg-icon-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  opacity="0.3"
                  d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z"
                  fill="black"
                />
                <path
                  d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.2C9.7 3 10.2 3.20001 10.4 3.60001ZM16 12H13V9C13 8.4 12.6 8 12 8C11.4 8 11 8.4 11 9V12H8C7.4 12 7 12.4 7 13C7 13.6 7.4 14 8 14H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
                  fill="black"
                />
                <path
                  opacity="0.3"
                  d="M11 14H8C7.4 14 7 13.6 7 13C7 12.4 7.4 12 8 12H11V14ZM16 12H13V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
                  fill="black"
                />
              </svg>
            </span>
            {/*end::Svg Icon*/}
            {/*end::Svg Icon*/}
            Add New
          </a>
        </div>
      </div>
      <div className="pb-5">
        <div
          id="kt_table_users_wrapper"
          className="dataTables_wrapper dt-bootstrap4 no-footer"
        >
          <div className="table-responsive">
            <table
              className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
              id="page_list"
            >
              <thead className="bg-light-dark">
                <tr className="text-start fw-bolder fs-7 text-uppercase gs-0">
                  <th className="w-150px text-start ps-9">Name </th>
                  <th className="w-150px text-center pe-5">Type </th>
                  <th className="w-150px text-center">Compare</th>
                  <th className="w-100px text-center">value</th>
                  <th className="w-100px text-center">Adjust</th>
                  <th className="w-150px text-center">Period</th>
                  <th className="w-100px text-center">CoolDown</th>
                  <th className="w-100px" />
                </tr>
              </thead>

              <tbody className="text-gray-600">
                {policyArr != null ? (<>
                  {policyArr.length > 0 ? (<>
                    {policyArr.map((value, index) => {
                      return (<>
                        <tr className="openCustomer">

                          <td className="text-start ps-9">
                            {" "}
                            <div className="flex-column w-100 me-2" >
                              {" "}
                              <div className=" flex-stack mb-2">
                                {" "}
                                <span className=" me-2 fs-6 fw-bold">{value.name}</span>{" "}
                              </div>{" "}
                            </div>{" "}
                          </td>
                          <td className="text-center">
                            {" "}
                            <div className="flex-column w-100 me-2" >
                              {" "}
                              <div className=" flex-stack mb-2">
                                {" "}
                                <span className=" me-2 fs-6 fw-bold">{value.type == "memory_utilization_percent" ? "RAM" : value.type.toUpperCase()}</span>{" "}
                              </div>{" "}
                            </div>{" "}
                          </td>

                          <td className="text-center pe-2">
                            {" "}
                            <div className="flex-column w-100 me-2" >
                              {" "}
                              <div className=" flex-stack mb-2">
                                {" "}
                                <span className=" me-2 fs-6 fw-bold">{value.compare}</span>{" "}
                              </div>{" "}
                            </div>{" "}
                          </td>
                          <td className="text-center pe-2">
                            {" "}
                            <div className="flex-column w-100 me-2" >
                              {" "}
                              <div className=" flex-stack mb-2">
                                {" "}
                                <span className=" me-2 fs-6 fw-bold">{value.value}</span>{" "}
                              </div>{" "}
                            </div>{" "}
                          </td>
                          <td className="text-center pe-2">
                            {" "}
                            <div className="flex-column w-100 me-2" >
                              {" "}
                              <div className=" flex-stack mb-2">
                                {" "}
                                <span className=" me-2 fs-6 fw-bold">{value.adjust}</span>{" "}
                              </div>{" "}
                            </div>{" "}
                          </td>
                          <td className="text-center pe-2">
                            {" "}
                            <div className="flex-column w-100 me-2" >
                              {" "}
                              <div className=" flex-stack mb-2">
                                {" "}
                                <span className=" me-2 fs-6 fw-bold">{value.period}</span>{" "}
                              </div>{" "}
                            </div>{" "}
                          </td>
                          <td className="text-center pe-2">
                            {" "}
                            <div className="flex-column w-100 me-2" >
                              {" "}
                              <div className=" flex-stack mb-2">
                                {" "}
                                <span className=" me-2 fs-6 fw-bold">{value.cooldown} sec</span>{" "}
                              </div>{" "}
                            </div>{" "}
                          </td>

                          <td className="text-center sphide">
                            <a
                              onClick={(e) => {
                                handleChangeState(value)
                                updateP({ "id": value.id, "name": value.name, "type": value.type, "compare": value.compare, "period": value.period, "value": value.value, "cooldown": value.cooldown, "adjust": value.adjust })
                              }}
                              className="btn btn-icon btn-light-primary sphide"
                              style={{ marginRight: 20 }}
                            >
                              {/*begin::Svg Icon | path: assets/media/icons/duotune/files/fil017.svg*/}{" "}
                              <span className="svg-icon svg-icon-muted svg-icon-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    opacity="0.3"
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z"
                                    fill="black"
                                  />
                                  <path
                                    d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z"
                                    fill="black"
                                  />
                                  <path
                                    d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z"
                                    fill="black"
                                  />
                                </svg>
                              </span>{" "}
                              {/*end::Svg Icon*/}
                            </a>{" "}
                            <a
                              onClick={(e) => deleteAutoScalingPolicy(value.id)}
                              id={"delete-policy-btn-" + value.id}
                              className="btn btn-icon btn-light-danger"
                            >
                              {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen027.svg*/}{" "}
                              <span className="svg-icon svg-icon-muted svg-icon-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  {" "}
                                  <path
                                    d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                    fill="black"
                                  />{" "}
                                  <path
                                    opacity="0.5"
                                    d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                    fill="black"
                                  />{" "}
                                  <path
                                    opacity="0.5"
                                    d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                    fill="black"
                                  />{" "}
                                </svg>
                              </span>{" "}
                              {/*end::Svg Icon*/}
                            </a>
                          </td>

                        </tr>
                      </>)
                    })}
                  </>) : ('')}
                </>) : (<><tr><td colSpan={12}><TableLoader /></td></tr></>)}

              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>

    <div id="policy_drawer_overlay" onClick={openDrawer} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>

    <div
      id="add-policy-modal"
      className="bg-body shadow drawer drawer-end"
      data-kt-drawer-close="#close_ssh"
      // data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
      style={{ important: true, zIndex: 110 }}
    >
      {/*begin::Messenger*/}
      <div
        className="card rounded-0 w-100 border-0"
        id="kt_drawer_chat_messenger"
        style={{ height: 'max-content' }}
      >
        {/*begin::Card header*/}
        <div
          className="card-header pe-5 ps-7"
          id="kt_drawer_chat_messenger_header"
        >
          {/*begin::Title*/}
          <div className="card-title">
            {/*begin::User*/}
            <div className="d-flex justify-content-center flex-column me-3">
              <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                Add New Policy

              </a>
            </div>
            {/*end::User*/}
          </div>
          {/*end::Title*/}
          {/*begin::Card toolbar*/}
          <div className="card-toolbar">
            {/*begin::Close*/}
            <div
              onClick={openDrawer}
              className="btn btn-sm btn-icon btn-active-light-primary"
              id="close_ssh"
            >
              {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
              <span className="svg-icon svg-icon-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x={6}
                    y="17.3137"
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(-45 6 17.3137)"
                    fill="black"
                  />
                  <rect
                    x="7.41422"
                    y={6}
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(45 7.41422 6)"
                    fill="black"
                  />
                </svg>
              </span>
              {/*end::Svg Icon*/}
            </div>
            {/*end::Close*/}
          </div>
          {/*end::Card toolbar*/}
        </div>
        {/*end::Card header*/}
        {/*begin::Card body*/}
        <div className="" id="">
          {/*begin::Form*/}

          <form
            id="addScaling"
            className="form"
          >
            {/*begin::Modal body*/}
            <div className="modal-body ">
              {/*begin::Scroll*/}
              <div
                className="scroll-y me-n7 pe-7"
                id="kt_modal_create_api_key_scroll"
                data-kt-scroll="true"
                data-kt-scroll-activate="{default: false, lg: true}"
                data-kt-scroll-max-height="auto"
                data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                data-kt-scroll-offset="300px"
              >
                <div className="row">
                  <div className="col-8">
                    {/*begin::Input group*/}
                    <div className="mb-5 fv-row">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Name
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Enter Name"
                        id={"add_name"}
                        value={policyName}
                        onChange={(e) => setPolicyName(e.target.value)}
                      />
                      {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                  </div>
                  <div className="col-4">
                    {/*begin::Input group*/}
                    <div className="mb-5 fv-row">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Type
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <select className="form-select" id="add_type" defaultValue={"cpu"} >
                        <option value="cpu">CPU</option>
                        <option value="memory_utilization_percent">RAM</option>
                      </select>
                      {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                  </div>
                  <div className="col-4">
                    {/*begin::Input group*/}
                    <div className="mb-5 fv-row">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Compare
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <select className="form-select" id={"add_compare"} onChange={(e) => manageAdjustment("add", e)} defaultValue={"above"} name="compare" >
                        <option value="above">Is above</option>
                        <option value="below">Is below</option>
                        {/* <option value="equalto">Is equal-to</option> */}
                      </select>

                      {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                  </div>

                  <div className="col-4">
                    {/*begin::Input group*/}
                    <div className="mb-5 fv-row">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Value
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <input
                        type="number"
                        id={"add_value"}
                        name="value"
                        className="form-control"
                        defaultValue={"50"}
                        onChange={(e) => {
                          const value = e.target.value;

                          // Prevent negative values by only allowing digits (0-9)
                          const filteredValue = value.replace(/[^0-9]/g, '');

                          // Set the filtered value back to the input
                          e.target.value = filteredValue;
                        }}
                      />

                      {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                  </div>

                  <div className="col-4">
                    {/*begin::Input group*/}
                    <div className="mb-5 fv-row">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        CoolDown
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <div className="input-group mb-5">
                        <input
                          type="number"
                          id={"add_cooldown"}
                          name="cooldown"
                          className="form-control"
                          aria-describedby="basic-addon1"
                          defaultValue={300}
                          onChange={(e) => {
                            const value = e.target.value;

                            // Prevent negative values by only allowing digits (0-9)
                            const filteredValue = value.replace(/[^0-9]/g, '');

                            // Set the filtered value back to the input
                            e.target.value = filteredValue;
                          }}
                        />
                        <span className="input-group-text" id="basic-addon1">sec</span>
                      </div>
                      {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                  </div>

                  <div className="col-6">
                    {/*begin::Input group*/}
                    <div className="mb-5 fv-row">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Period
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <select className='form-select' id={"add_period"} defaultValue={"5m"} name="period">
                        <option value="5m">5 minutes</option>
                        <option value="10m">10 minutes</option>
                        <option value="30m">30 minutes</option>
                        <option value="1h">1 hour</option>
                        <option value="3h">3 hours</option>
                        <option value="6h">6 hours</option>
                        <option value="12h">12 hours</option>
                        <option value="24h">24 hours</option>
                      </select>
                      {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                  </div>

                  <div className="col-6">
                    {/*begin::Input group*/}
                    <div className="mb-5 fv-row">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Adjust
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <div className="d-flex align-items-center">
                        {/*begin::Dialer*/}
                        <div
                          className="input-group w-md-150px"
                          data-kt-dialer="true"
                        >
                          {/*begin::Decrease control*/}
                          <a
                            className="btn btn-icon btn-outline btn-outline-secondary"
                            onClick={(e) => updateAdjustmentValue("add_adjust", "down", "add")}
                          >
                            <i className="bi bi-dash fs-1" />
                          </a>
                          {/*end::Decrease control*/}
                          {/*begin::Input control*/}
                          <input
                            type="text"
                            className="form-control text-center"
                            readOnly={true}
                            id={"add_adjust"}
                            value="1"
                          />
                          {/*end::Input control*/}
                          {/*begin::Increase control*/}
                          <a
                            className="btn btn-icon btn-outline btn-outline-secondary"
                            onClick={(e) => updateAdjustmentValue("add_adjust", "up", "add")}
                          >
                            <i className="bi bi-plus fs-1" />
                          </a>
                          {/*end::Increase control*/}
                        </div>
                        {/*end::Dialer*/}
                      </div>

                      {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                  </div>

                </div>
              </div>
              {/*end::Scroll*/}
            </div>
            {/*end::Modal body*/}
            {/*begin::Modal footer*/}
            <div className="modal-footer ">
              {/*begin::Button*/}

              <button
                type="button"
                onClick={addAutoScalingPolicy}
                className="btn btn-primary w-100"
                id="add-policy-btn"
              >
                <span className="indicator-label">
                  Add New Policy
                </span>
              </button>
              {/*end::Button*/}
            </div>
            {/*end::Modal footer*/}
          </form>
          {/*end::Form*/}
        </div>
        {/*end::Card body*/}
      </div>
      {/*end::Messenger*/}
    </div>

    <div id="policy_update_drawer_overlay" onClick={openDrawerUpdate} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>

    <div
      id="update-policy-modal"
      className="bg-body shadow drawer drawer-end"
      data-kt-drawer-close="#close_ssh"
      // data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
      style={{ important: true, zIndex: 110 }}
    >
      {/*begin::Messenger*/}
      <div
        className="card rounded-0 w-100 border-0"
        id="kt_drawer_chat_messenger"
        style={{ height: 'max-content' }}
      >
        {/*begin::Card header*/}
        <div
          className="card-header pe-5 ps-7"
          id="kt_drawer_chat_messenger_header"
        >
          {/*begin::Title*/}
          <div className="card-title">
            {/*begin::User*/}
            <div className="d-flex justify-content-center flex-column me-3">
              <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                Update Policy
              </a>
            </div>
            {/*end::User*/}
          </div>
          {/*end::Title*/}
          {/*begin::Card toolbar*/}
          <div className="card-toolbar">
            {/*begin::Close*/}
            <div
              onClick={openDrawerUpdate}
              className="btn btn-sm btn-icon btn-active-light-primary"
              id="close_ssh"
            >
              {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
              <span className="svg-icon svg-icon-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x={6}
                    y="17.3137"
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(-45 6 17.3137)"
                    fill="black"
                  />
                  <rect
                    x="7.41422"
                    y={6}
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(45 7.41422 6)"
                    fill="black"
                  />
                </svg>
              </span>
              {/*end::Svg Icon*/}
            </div>
            {/*end::Close*/}
          </div>
          {/*end::Card toolbar*/}
        </div>
        {/*end::Card header*/}
        {/*begin::Card body*/}
        <div className="" id="">
          {/*begin::Form*/}
          {/* {pageStatus ? (<> */}
          <form
            id="updateScaling"
            className="form"
          >
            {/*begin::Modal body*/}
            <div className="modal-body ">
              {/*begin::Scroll*/}
              <div
                className="scroll-y me-n7 pe-7"
                id="kt_modal_create_api_key_scroll"
                data-kt-scroll="true"
                data-kt-scroll-activate="{default: false, lg: true}"
                data-kt-scroll-max-height="auto"
                data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                data-kt-scroll-offset="300px"
              >
                <div className="row">
                  <div className="col-8">
                    {/*begin::Input group*/}
                    <div className="mb-5 fv-row">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Name
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Enter Name"
                        id="update_name1"
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    {/*begin::Input group*/}
                    <div className="mb-5 fv-row">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Type
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <select className="form-select" id="update_type">
                        <option value="cpu">CPU</option>
                        <option value="memory_utilization_percent">RAM</option>
                      </select>
                      {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                  </div>
                  <div className="col-4">
                    {/*begin::Input group*/}
                    <div className="mb-5 fv-row">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Compare
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <select className="form-select" id="update_compare" onChange={(e) => manageAdjustment("update", e)} name="compare" >
                        <option value="above">Is above</option>
                        <option value="below">Is below</option>
                        {/* <option value="equalto">Is equal-to</option> */}
                      </select>

                      {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                  </div>

                  <div className="col-4">
                    {/*begin::Input group*/}
                    <div className="mb-5 fv-row">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Value
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <input
                        type="text"
                        id="update_value"
                        name="value"
                        className="form-control"
                        onChange={(e) => {
                          const value = e.target.value;
                          
                          // Prevent negative values by only allowing digits (0-9)
                          const filteredValue = value.replace(/[^0-9]/g, ''); 
                          
                          // Set the filtered value back to the input
                          e.target.value = filteredValue;
                        }}
                      />

                      {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                  </div>

                  <div className="col-4">
                    {/*begin::Input group*/}
                    <div className="mb-5 fv-row">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        CoolDown
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <div className="input-group mb-5">
                        <input
                          type="text"
                          id="update_cooldown"
                          name="cooldown"
                          className="form-control"
                          aria-describedby="basic-addon2"
                          onChange={(e) => {
                            const value = e.target.value;

                            // Prevent negative values by only allowing digits (0-9)
                            const filteredValue = value.replace(/[^0-9]/g, '');

                            // Set the filtered value back to the input
                            e.target.value = filteredValue;
                          }}
                        />
                        <span className="input-group-text" id="basic-addon2">sec</span>
                      </div>
                      {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                  </div>

                  <div className="col-6">
                    {/*begin::Input group*/}
                    <div className="mb-5 fv-row">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Period
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <select className='form-select' id="update_period" name="period">
                        <option value="5m">5 minutes</option>
                        <option value="10m">10 minutes</option>
                        <option value="30m">30 minutes</option>
                        <option value="1h">1 hour</option>
                        <option value="3h">3 hours</option>
                        <option value="6h">6 hours</option>
                        <option value="12h">12 hours</option>
                        <option value="24h">24 hours</option>
                      </select>
                      {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                  </div>

                  <div className="col-6">
                    {/*begin::Input group*/}
                    <div className="mb-5 fv-row">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        {" "}
                        Adjust
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <div className="d-flex align-items-center">
                        {/*begin::Dialer*/}
                        <div
                          className="input-group w-md-150px"
                          data-kt-dialer="true"
                        >
                          {/*begin::Decrease control*/}
                          <a
                            className="btn btn-icon btn-outline btn-outline-secondary"
                            onClick={(e) => updateAdjustmentValue("update_adjust", "down", "update")}
                          >
                            <i className="bi bi-dash fs-1" />
                          </a>
                          {/*end::Decrease control*/}
                          {/*begin::Input control*/}
                          <input
                            type="text"
                            className="form-control text-center"
                            readOnly={true}
                            id="update_adjust"
                          />
                          {/*end::Input control*/}
                          {/*begin::Increase control*/}
                          <a
                            className="btn btn-icon btn-outline btn-outline-secondary"
                            onClick={(e) => updateAdjustmentValue("update_adjust", "up", "update")}
                          >
                            <i className="bi bi-plus fs-1" />
                          </a>
                          {/*end::Increase control*/}
                        </div>
                        {/*end::Dialer*/}
                      </div>

                      {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                  </div>
                </div>
              </div>
              {/*end::Scroll*/}
            </div>
            {/*end::Modal body*/}
            {/*begin::Modal footer*/}
            <div className="modal-footer ">
              {/*begin::Button*/}
              <button
                type="button"
                onClick={(e) => updateAutoScalingPolicy(policyEle.id)}
                className="btn btn-primary w-100"
                id="update-policy-btn"
              >
                <span className="indicator-label">
                  Update Policy
                </span>
              </button>

              {/*end::Button*/}
            </div>
            {/*end::Modal footer*/}
          </form>
          {/* </>) : (<><TableLoader /></>)} */}
          {/*end::Form*/}
        </div>
        {/*end::Card body*/}
      </div>
      {/*end::Messenger*/}
    </div>
  </>)
}
