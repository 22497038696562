// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-text {
    font-size: 12px; /* Or any other size */
  }
  .axis-label {
    font-size: 14px; /* For axis labels specifically */
  }`, "",{"version":3,"sources":["webpack://./src/App/MicroApp/Body/Products/cloud/cloudFeatures/charts/custom.css"],"names":[],"mappings":"AAAA;IACI,eAAe,EAAE,sBAAsB;EACzC;EACA;IACE,eAAe,EAAE,iCAAiC;EACpD","sourcesContent":[".chart-text {\n    font-size: 12px; /* Or any other size */\n  }\n  .axis-label {\n    font-size: 14px; /* For axis labels specifically */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
