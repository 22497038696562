import React from 'react';
import "./custom.css";
const ComponentLoader = () => {
    return (
        <>
          <div className="mainComponent-div">
            <div className="loaderComponent-icon1 loaderComponent"></div>
            <div className="loaderComponent-icon2 loaderComponent"></div>
            <div className="loaderComponent-icon3 loaderComponent"></div>
            <div className="textComponent">Loading...</div>
          </div>
          {/* <div style={{zIndex: 109}} className="drawer-overlay"></div> */}
        </>
    );
}

export default ComponentLoader;
