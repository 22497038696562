//import React, { useEffect, useState } from "react"; 
import axios from "axios"; 
 export default async function CallPlatfrom(type,url,data){  
   const darr= await axios({
        method: type,
        url:'https://nitin.cloudplatformapp.com/'+url,  
        headers: {
                 'Content-Type': 'application/json'
                },
        data:data
    }).then(function(response){ 
        return response.data;
    })
    ////console.log(darr);
    return darr;
}