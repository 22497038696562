import {React} from 'react';  
import Exp3 from './samples/Exp3';
import Exp2 from './samples/Exp2';
import Exp1 from './samples/Exp1';
import Custom from './samples/Custom';
export default function Template(){
    return(<>
      <style
          dangerouslySetInnerHTML={{ __html: "html,body { padding: 0; margin:0; }" }}
        />
        <Exp1 />

      </>)
}