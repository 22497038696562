import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import UsePost from "../../../../../CustomHooks/usePost";

export default function CreateLoadbalancerTargetGroup() {

  const navigate = useNavigate();
  const openDrawerCreate = () => {
    var copy_keys = document.querySelector("#copy_keys");
    if (copy_keys) {
      copy_keys.classList.add("d-none");
    }
    var drawerElement = document.querySelector("#create-target-modal");
    var drawer_overlay = document.querySelector("#drawer_overlay");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      drawerElement.style.width = "70%";
      drawer_overlay.classList.toggle("d-none");
    }
  };

  const CreateGroup = async () => {
    var submitBtn = document.querySelector("#add-target-btn");
    if (submitBtn) {
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      toast.dismiss();
      const dt = toast.loading("Please wait...");

      const responseData = await UsePost('post', 'targetgroup', 'targetGroup-add-form');
      if (responseData) {
        submitBtn.innerHTML = preContent;
        submitBtn.classList.remove("disabled");
        if (responseData.status === 'error') {
          toast.update(dt, {
            render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        } else if (responseData.status === 'success') {
          toast.update(dt, {
            render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          navigate("/targetgroup/" + responseData.id);
        }
      }
    }
  }
  return (
    <>
      <div className="col-xl-12">

        <div className="card card-xl-stretch mb-5 mb-xl-12">
          <div className="card-header border-0 mb-5 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bolder text-gray-800">Load Balancers </span>
              <span className="text-gray-400 mt-1 fw-bold fs-6">
                Load Balancer, Target Group
              </span>
            </h3>
            <div className="card-toolbar sphide">
              <Link to="/loadbalancer/deploy">
                <div style={{ marginRight: 20 }}>
                  <a className="btn btn-sm btn-primary" >
                    <span className="svg-icon svg-icon-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.5"
                          x="11.364"
                          y="20.364"
                          width={16}
                          height={2}
                          rx={1}
                          transform="rotate(-90 11.364 20.364)"
                          fill="black"
                        />
                        <rect x="4.36396" y="11.364" width={16} height={2} rx={1} fill="black" />
                      </svg>
                    </span>
                    Create LoadBalancer</a>
                </div>
              </Link>
              <div >
                {/* <a className="btn btn-sm btn-primary" onClick={openDrawerCreate}>
                  <span className="svg-icon svg-icon-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.5"
                        x="11.364"
                        y="20.364"
                        width={16}
                        height={2}
                        rx={1}
                        transform="rotate(-90 11.364 20.364)"
                        fill="black"
                      />
                      <rect x="4.36396" y="11.364" width={16} height={2} rx={1} fill="black" />
                    </svg>
                  </span>
                  Create Target Group</a> */}
              </div>

            </div>

          </div>
        </div>
      </div>


      <div id="drawer_overlay" onClick={openDrawerCreate} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>

      <div
        id="create-target-modal"
        className="bg-body shadow drawer drawer-end"
        data-kt-drawer="true"
        data-kt-drawer-name="chat"
        data-kt-drawer-activate="true"
        data-kt-drawer-direction="end"
        data-kt-drawer-close="#close_ssh"
        data-kt-drawer-width="{default:'650px', 'md': '650px', 'sm': '300px'}"
        style={{ maxWidth: "70%", important: true }}
      >
        <div className="card w-100 rounded-0 border-0" id="kt_drawer_chat_messenger">
          <div className="card-header pe-5" id="kt_drawer_chat_messenger_header">
            <div className="card-title">
              <div className="d-flex justify-content-center flex-column me-3">
                <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                  Create Target Group
                </a>
              </div>
            </div>
            <div className="card-toolbar">
              <div onClick={openDrawerCreate} className="btn btn-sm btn-icon btn-active-light-primary">
                <span className="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x={6}
                      y="17.3137"
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(-45 6 17.3137)"
                      fill="black"
                    />
                    <rect
                      x="7.41422"
                      y={6}
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(45 7.41422 6)"
                      fill="black"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <div className="" id="">
            <form id="targetGroup-add-form">
              <div className="modal-body ">
                <div className="scroll-y me-n7 pe-7 row">
                  <div className="col-lg-4 col-md-4 col-xl-4">
                    <div className="mb-5 fv-row">
                      <label htmlFor="type" className="required fs-5 fw-bold mb-2">
                        {" "}
                        Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-xl-4">
                    <div className="mb-5 fv-row">
                      <label htmlFor="type" className="required fs-5 fw-bold mb-2">
                        {" "}
                        Protocol
                      </label>
                      <select className="form-select" name="protocol">
                        <option value="HTTP">HTTP</option>
                        <option value="HTTPS">HTTPS</option>
                        <option value="TCP">TCP</option>
                        <option value="UDP">UDP</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-xl-4">
                    <div className="mb-5 fv-row">
                      <label htmlFor="type" className="required fs-5 fw-bold mb-2">
                        {" "}
                        Port
                      </label>
                      <input
                        type="number"
                        name="port"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-8 col-xl-8">
                    <div className="mb-5 fv-row">
                      <label htmlFor="type" className="required fs-5 fw-bold mb-2">
                        {" "}
                        Health Check Path
                      </label>
                      <input
                        type="url"
                        name="health_check_path"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-xl-4">
                    <div className="mb-5 fv-row">
                      <label htmlFor="type" className="required fs-5 fw-bold mb-2 sphide">
                        Health Check Protocol
                      </label>
                      <select className="form-select" name="health_check_protocol">
                        <option value="HTTP">HTTP</option>
                        <option value="HTTPS">HTTPS</option>
                        <option value="TCP">TCP</option>
                        <option value="UDP">UDP</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-xl-3">
                    <div className="mb-5 fv-row">
                      <label htmlFor="type" className="required fs-5 fw-bold mb-2">
                        {" "}
                        Health Check Interval
                      </label>
                      <input
                        type="number"
                        name="health_check_interval"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-xl-3">
                    <div className="mb-5 fv-row">
                      <label htmlFor="type" className="required fs-5 fw-bold mb-2">
                        {" "}
                        Health Check Timeout
                      </label>
                      <input
                        type="number"
                        name="health_check_timeout"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-xl-3">
                    <div className="mb-5 fv-row">
                      <label htmlFor="type" className="required fs-5 fw-bold mb-2">
                        {" "}
                        Healthy Threshold
                      </label>
                      <input
                        type="number"
                        name="healthy_threshold"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-xl-3">
                    <div className="mb-5 fv-row">
                      <label htmlFor="type" className="required fs-5 fw-bold mb-2">
                        {" "}
                        Unhealthy Threshold
                      </label>
                      <input
                        type="number"
                        name="unhealthy_threshold"
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12 col-xl-12 mt-10">
                    <div className="mb-5 fv-row">
                      <a onClick={CreateGroup} className="btn btn-primary w-100" id="add-target-btn">Create Target group</a>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

      </div>
    </>
  )
} 