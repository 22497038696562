import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import Swal from 'sweetalert2';
import NotFound from "../../../../../public-components/NotFound";
import UseApi from "../../../../CustomHooks/useApi";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../Loaders/TableLoader";
import Overview from "./tabs/Overview";
import UsePostData from "../../../../CustomHooks/usePostData";
import Vpc from "./tabs/Vpc";
import Firewall from "./tabs/Firewall";
import LoadBalancer from "./tabs/LoadBalanacer";
import History from "./tabs/History";
import Policy from "./tabs/Policy";
import Schedules from "./tabs/Schedules";
import { useNavigate } from "react-router-dom";
import TargetGroups from "./tabs/TargetGroups";
import SnapshotListComponent from "./tabs/SnapshotListComponent";

export default function ManageAutoScaling() {
  var query = window.location.href;
  var vars = query.split("/");
  var id = vars[4];
  const navigate = useNavigate();
  const [scalingData, setScalingData] = useState(null);
  const [dataFetched, setDataFetched] = useState(false);
  const dataFetchedRef = useRef(false);

  const loaderFunc = () => {
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if (loader && no_record) {
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");

      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
  }

  const getAutoScaling = async () => {
    // setScalingData(null);
    const data = await UseApi("get", "autoscaling/" + id, "");
    if (data) {
      if (data.rcode == "error") {
        setScalingData([]);
        const dt = toast
        toast.update(dt, {
          render: "no record found",
          type: "warning",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // loaderFunc();
      } else {
        console.log(data.groups[0]);
        setScalingData((old) => old = data.groups[0]);

        toast.dismiss();
      }
    }
  };
  console.log(scalingData);
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getAutoScaling();
  }, []);
  const copyToClipboard = () => {
    var eleText = document.querySelector("#autoScaling_file_url");
    var ele = document.querySelector("#autoScaling_file_url_copy_btn");

    if (ele && eleText) {
      const copyText = eleText.innerText;
      const originalContent = ele.innerHTML;

      navigator.clipboard.writeText(copyText).then(() => {
        ele.innerHTML = "Copied";

        setTimeout(() => {
          ele.innerHTML = originalContent;
        }, 1000);
      }).catch(err => {
        console.error('Failed to copy text: ', err);
      });
    }
  };

  function getIdFromUrl(url) {
    // Split the URL by '/'
    const parts = url.split('/');
    // The ID should be the last part
    const id = parts.pop();
    return id;
}

  const deleteAutoScaling = async (scalingName) => {
    var submitBtn = document.querySelector("#deleteScaling-btn");
    if (submitBtn) {
      const { value: isDelete } = await Swal.fire({
        title: 'Delete Scaling Group',
        html: `
       <div className="d-flex align-items-center flex-wrap"> 
       <div id="kt_clipboard_4" className="me-5 fw-bold text-gray-600">
         <p class="fs-5">Enter the Auto Scaling name to delete:</p>

         <span class="fw-bold" id="autoScaling_file_url" > ${scalingName} </span><span id="autoScaling_file_url_copy_btn" style="cursor:pointer; width: 70px; display: inline-block;" class="text-primary">( copy )</span> 
       </div>  
     </div>
    `,
        input: 'text',
        inputPlaceholder: 'Scaling name',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'delete-button bg-danger', // Add your custom class here
        },
      });
      if (isDelete) {
        var preContent = submitBtn.innerHTML;
        submitBtn.classList.add("disabled");
        submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
        toast.dismiss();
        const dt = toast.loading("Auto Scaling deleting...");
        const dnsp = await UseApi('DELETE', 'autoscaling/' + id + "?name=" + isDelete, '');
        if (dnsp) {
          submitBtn.classList.remove("disabled");
          submitBtn.innerHTML = preContent;

          //////console.log(dnsp);
          if (dnsp.status == 'error') {
            toast.update(dt, {
              render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
          } else if (dnsp.status == 'success') {
            toast.update(dt, {
              render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
            const url = window.location.href
            console.log(url,query,"---------------------------------->>>>>>>>>>><<<<<<<<<<<<<<<");
            if(query == url){
            navigate("/auto-scaling");
            }
          }
        }
      }
      //  else {
      //    swal({
      //        title: "Invalid Auto Scaling Name",
      //        text: "Please confirm the valid Scaling name or check the extra white space in the given scaling name!",
      //        icon: "warning",
      //        button: "OK",
      //      });
      //  }
    }
  }

  const handleClick = () => {
    document.addEventListener('click', function (event) {
      if (event.target && event.target.id === 'autoScaling_file_url_copy_btn') {
        copyToClipboard();
        event.stopPropagation();
      }
    });
  }
  document.removeEventListener('click', handleClick);
  document.addEventListener('click', handleClick);

  const updateAutoScaling = async () => {
    toast.dismiss();
    const dt = toast.loading("Please wait...");
    var update_name = document.querySelector("#update_name");
    var update_minsize = document.querySelector("#update_minsize");
    var update_maxsize = document.querySelector("#update_maxsize");
    var update_desiredsize = document.querySelector("#update_desiredsize");
    if (update_name && update_minsize && update_maxsize && update_desiredsize) {
      //console.log("update scaling");
      if (update_name.value == "") {
        setTimeout(() => {
          toast.update(dt, { render: "Name can not be empty", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
          return;
        }, 2000)
      }
      if (update_minsize.value < 1 || update_minsize.value > update_maxsize.value) {
        setTimeout(() => {
          toast.update(dt, { render: "Min size can not be less than 0 and not more than max size!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
          return;
        }, 2000)
      }
      if (update_maxsize.value < update_minsize.value) {
        setTimeout(() => {
          toast.update(dt, { render: "Max size can not be less than min size!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
          return;
        }, 2000)
      }
      if (update_desiredsize.value < update_minsize.value || update_desiredsize.value > update_maxsize.value) {
        setTimeout(() => {
          toast.update(dt, { render: "Desired size can not be less than min size and not more than max size!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
          return;
        }, 2000)
      }
      const submitBtn = document.querySelector("#update-scaling-config-btn");
      if (submitBtn) {
        var preContent = submitBtn.innerHTML;
        submitBtn.classList.add("disabled");
        submitBtn.innerHTML = 'Please wait...<span className="indicator-label sphide"><span class="spinner-border spinner-border-sm align-middle ms-1 sphide"></span></span>';
        const responseData = await UsePostData("put", "autoscaling/" + id, { "name": update_name.value, "minsize": update_minsize.value, "maxsize": update_maxsize.value, "desiredsize": update_desiredsize.value });

        if (responseData.status === "error") {
          toast.update(dt, {
            render: responseData.message,
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (responseData.status === "success") {
          toast.update(dt, {
            render: responseData.message,
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          getAutoScaling();
        }
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
      }
    }
  };

  const updateAutoScalingSnapshot = async (type, imageid, imageName) => {
    toast.dismiss();

    var autoScalingName = '';
    if (scalingData != null) {
      autoScalingName = scalingData.name
    }
    const { value: isDelete } = await Swal.fire({
      title: 'Update Snapshot',
      html: `
      <div className="d-flex align-items-center flex-wrap"> 
      <div id="kt_clipboard_4" className="me-5 fw-bold text-gray-600">
        <p class="fs-5">Enter the Auto Scaling name to update Snapshot:</p>

        <span class="fw-bold" id="file_url" >${autoScalingName} &nbsp;</span><span id="file_url_copy_btn" style="cursor:pointer; width: 70px" class="text-primary"> ( copy ) </span> 
      </div>  
    </div>
   `,
      input: 'text',
      inputPlaceholder: 'Auto Scaling Name',
      showCancelButton: true,
      confirmButtonText: 'Update',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'delete-button bg-danger', // Add your custom class here
      },
    });
    if (isDelete?.trim() == autoScalingName) {
      const dt = toast.loading("Please wait...");
      var update_name = document.querySelector("#update_name");
      var update_minsize = document.querySelector("#update_minsize");
      var update_maxsize = document.querySelector("#update_maxsize");
      var update_desiredsize = document.querySelector("#update_desiredsize");
      if (update_name && update_minsize && update_maxsize && update_desiredsize) {
        //console.log("update scaling");
        if (update_name.value == "") {
          toast.update(dt, { render: "Name can not be empty", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
          return;
        }
        if (update_minsize.value < 1 || update_minsize.value > update_maxsize.value) {
          toast.update(dt, { render: "Min size can not be less than 0 and not more than max size!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
          return;
        }
        if (update_maxsize.value < update_minsize.value) {
          toast.update(dt, { render: "Max size can not be less than min size!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
          return;
        }
        if (update_desiredsize.value < update_minsize.value || update_desiredsize.value > update_maxsize.value) {
          toast.update(dt, { render: "Desired size can not be less than min size and not more than max size!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
          return;
        }
      }
      if (type == 'snapshot') {
        var responseData = await UsePostData("put", "autoscaling/" + id, { "snapshotid": imageid, "name": update_name.value, "minsize": update_minsize.value, "maxsize": update_maxsize.value, "desiredsize": update_desiredsize.value });
      } else {
        var responseData = await UsePostData("put", "autoscaling/" + id, { "stack": imageid, "stackid": imageid, "stackimage": imageName, "name": update_name.value, "minsize": update_minsize.value, "maxsize": update_maxsize.value, "desiredsize": update_desiredsize.value });
      }

      if (responseData.status === "error") {
        toast.update(dt, {
          render: responseData.message,
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (responseData.status === "success") {
        toast.update(dt, {
          render: responseData.message,
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        getAutoScaling();
      }
    } else {
      swal({
        title: "Invalid Auto Scaling name",
        text: "Please confirm the valid Auto Scaling name!",
        icon: "warning",
        button: "OK",
      });
    }
  };

  const CopyToClipboard = () => {
    var eleText = document.querySelector("#file_url");
    var ele = document.querySelector("#file_url_copy_btn");
    // var copiedEle = document.querySelector('#autoScaling_copied_btn')

    if (ele && eleText) {
      const copyText = eleText.innerText;
      const originalContent = ele.innerHTML;
      console.log(originalContent);
      
      navigator.clipboard.writeText(copyText).then(() => {
        ele.innerHTML = "(Copied)";

        setTimeout(() => {
          ele.innerHTML = "(copy)";
        }, 1000);
      }).catch(err => {
        console.error('Failed to copy text: ', err);
      });
    }
  };


  const HandleClick = () => {
    document.addEventListener('click', function (event) {
      if (event.target && event.target.id === 'file_url_copy_btn') {
        CopyToClipboard();
        event.stopPropagation();
      }
    });
  }
  document.removeEventListener('click', HandleClick);
  document.addEventListener('click', HandleClick);

  const StartStopAutoScaling = async (action) => {
    toast.dismiss();
    var submitBtn = document.querySelector("#action-btn");
    if (submitBtn) {

      swal({
        title: "Are you sure?",
        text: "This will " + action + " your auto scaling",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {
            var preContent = submitBtn.innerHTML;
            submitBtn.classList.add("disabled");
            submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';

            const dt = toast.loading("Auto Scaling deleting...");
            const dnsp = await UseApi('post', 'autoscaling/' + id + "/" + action, '');
            //////console.log(dnsp);
            if (dnsp) {
              submitBtn.classList.remove("disabled");
              submitBtn.innerHTML = preContent;
              if (dnsp.status == 'error') {
                toast.update(dt, {
                  render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
                });
              } else if (dnsp.status == 'success') {
                toast.update(dt, {
                  render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
                });
                setScalingData(null);
                getAutoScaling();
              }
            }
          }
        });
    }
  }

  const toggleDeploymentConfiguration = () => {
    var deployment_button = document.querySelector("#deployment_button");
    var deployment_sec = document.querySelector("#deployment_sec");
    if (deployment_sec && deployment_button) {
      var val = deployment_button.innerHTML;
      if (val == "Deployment Configuration") {
        deployment_button.innerHTML = "Hide Configuration";
        deployment_button.classList.remove("btn-light-primary");
        deployment_button.classList.add("btn-light-danger");

      } else {
        deployment_button.innerHTML = "Deployment Configuration";
        deployment_button.classList.add("btn-light-primary");
        deployment_button.classList.remove("btn-light-danger");
      }
      deployment_sec.classList.toggle("d-none");
    }
  }

  const handleMinSizeChange = (e) => {
    let minValue = parseInt(e.target.value, 10);
    let maxValue = parseInt(scalingData.maxsize, 10);
    console.log(minValue);
    if (minValue < 1) {
      minValue = 1;
    }
    if (minValue >= maxValue) {
      minValue = maxValue - 1;
    }
    console.log(minValue);
    scalingData.minsize = minValue;
    e.target.value = minValue;
    document.getElementById('update_minsize').value = minValue;
  };

  const handleMaxSizeChange = (e) => {
    let maxValue = parseInt(e.target.value, 10);
    let minValue = parseInt(scalingData.minsize, 10);
    if (maxValue > 5) {
      maxValue = 5;
    }
    if (maxValue <= minValue) {
      maxValue = minValue + 1;
    }
    scalingData.maxsize = maxValue;
    e.target.value = maxValue;
    document.getElementById('update_maxsize').value = maxValue;
  };

  const handleDesiredSizeChange = (e) => {
    let value = parseInt(e.target.value, 10);
    const minValue = parseInt(scalingData.minsize, 10);
    const maxValue = parseInt(scalingData.maxsize, 10);
    if (value < minValue) {
      value = minValue;
    } else if (value > maxValue) {
      value = maxValue;
    }
    scalingData.desiredsize = value;
    e.target.value = value;
  };

  return (
    <>
      {scalingData != null ? (<>
        {scalingData.length != 0 ? (<>
          <div className="card mb-5 mb-xl-10">
            <div className="card-body pt-9 pb-0">
              {/*begin::Details*/}
              <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                {/*begin: Pic*/}
                <div className="me-7 mb-4">
                  <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40px"
                      height="40px"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        opacity="0.3"
                        d="M4.05424 15.1982C8.34524 7.76818 13.5782 3.26318 20.9282 2.01418C21.0729 1.98837 21.2216 1.99789 21.3618 2.04193C21.502 2.08597 21.6294 2.16323 21.7333 2.26712C21.8372 2.37101 21.9144 2.49846 21.9585 2.63863C22.0025 2.7788 22.012 2.92754 21.9862 3.07218C20.7372 10.4222 16.2322 15.6552 8.80224 19.9462L4.05424 15.1982ZM3.81924 17.3372L2.63324 20.4482C2.58427 20.5765 2.5735 20.7163 2.6022 20.8507C2.63091 20.9851 2.69788 21.1082 2.79503 21.2054C2.89218 21.3025 3.01536 21.3695 3.14972 21.3982C3.28408 21.4269 3.42387 21.4161 3.55224 21.3672L6.66524 20.1802L3.81924 17.3372ZM16.5002 5.99818C16.2036 5.99818 15.9136 6.08615 15.6669 6.25097C15.4202 6.41579 15.228 6.65006 15.1144 6.92415C15.0009 7.19824 14.9712 7.49984 15.0291 7.79081C15.0869 8.08178 15.2298 8.34906 15.4396 8.55884C15.6494 8.76862 15.9166 8.91148 16.2076 8.96935C16.4986 9.02723 16.8002 8.99753 17.0743 8.884C17.3484 8.77046 17.5826 8.5782 17.7474 8.33153C17.9123 8.08486 18.0002 7.79485 18.0002 7.49818C18.0002 7.10035 17.8422 6.71882 17.5609 6.43752C17.2796 6.15621 16.8981 5.99818 16.5002 5.99818Z"
                        fill="black"
                      />
                      <path
                        d="M4.05423 15.1982L2.24723 13.3912C2.15505 13.299 2.08547 13.1867 2.04395 13.0632C2.00243 12.9396 1.9901 12.8081 2.00793 12.679C2.02575 12.5498 2.07325 12.4266 2.14669 12.3189C2.22013 12.2112 2.31752 12.1219 2.43123 12.0582L9.15323 8.28918C7.17353 10.3717 5.4607 12.6926 4.05423 15.1982ZM8.80023 19.9442L10.6072 21.7512C10.6994 21.8434 10.8117 21.9129 10.9352 21.9545C11.0588 21.996 11.1903 22.0083 11.3195 21.9905C11.4486 21.9727 11.5718 21.9252 11.6795 21.8517C11.7872 21.7783 11.8765 21.6809 11.9402 21.5672L15.7092 14.8442C13.6269 16.8245 11.3061 18.5377 8.80023 19.9442ZM7.04023 18.1832L12.5832 12.6402C12.7381 12.4759 12.8228 12.2577 12.8195 12.032C12.8161 11.8063 12.725 11.5907 12.5653 11.4311C12.4057 11.2714 12.1901 11.1803 11.9644 11.1769C11.7387 11.1736 11.5205 11.2583 11.3562 11.4132L5.81323 16.9562L7.04023 18.1832Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                </div>
                {/*end::Pic*/}
                {/*begin::Info*/}
                <div className="flex-grow-1">
                  {/*begin::Title*/}
                  <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                    {/*begin::User*/}
                    <div className="d-flex flex-column">
                      {/*begin::Name*/}
                      <div className="d-flex align-items-center mb-2">
                        <a

                          className="text-gray-900 text-hover-primary fs-2 fw-bolder me-1"
                        >
                          {scalingData.name}
                        </a>
                        {scalingData.status == "Active" ? (<>
                          <a className="btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3"   >
                            {scalingData.status}
                          </a>
                        </>) : (<>
                          <a className="btn btn-sm btn-light-danger fw-bolder ms-2 fs-8 py-1 px-3"   >
                            {scalingData.status}
                          </a>
                        </>)}

                      </div>
                      {/*end::Name*/}
                      {/*begin::Info*/}
                      <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                        <a

                          className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                        >

                          {scalingData.plan && scalingData.dclocation ? (<>
                            {scalingData.plan.cpu} vCPU /{(scalingData.plan.ram / 1024).toFixed(0)} GB / {scalingData.plan.disk} GB Disk /<span className="w-10">
                              {" "}
                              &nbsp;<img src={"/img/flag_" + scalingData.dclocation.dccc + ".png"} style={{ width: "15%" }} /> &nbsp;{scalingData.dclocation.location}
                            </span>
                          </>) : ('')}
                        </a>
                      </div>
                      {/*end::Info*/}
                    </div>
                    {/*end::User*/}
                    <div className="d-flex my-4">

                      {/*begin::Indicator*/}
                      {/* {scalingData.status=="Active"?(<>
      <span className="btn btn-success btn-sm" >{scalingData.status}</span>
    </>):(<>
      <span className="btn btn-warning btn-sm"  >{scalingData.status}</span>
    </>)} */}

                      {scalingData.status == "Active" ? (<>
                        <span className="btn btn-danger btn-sm" id="action-btn" onClick={(e) => StartStopAutoScaling("stop")}>Stop Auto Scaling</span>
                      </>) : (<>
                        <span className="btn btn-success btn-sm" id="action-btn" onClick={(e) => StartStopAutoScaling("start")}>Start Auto Scaling</span>
                      </>)}
                      {/*end::Indicator*/}
                    </div>

                  </div>
                  {/*end::Title*/}
                </div>
                {/*end::Info*/}
              </div>
              {/*end::Details*/}
              {/*begin::Navs*/}
              <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-trakt_charts_widget_1_chartnsparent fs-5 fw-bolder">
                <li className="nav-item">
                  <a
                    className="nav-link text-active-primary py-5 me-6 active sphide"
                    data-bs-toggle="tab"
                    href="#connection-details"
                  >
                    Overview
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link text-active-primary py-5 me-6 d sphide"
                    data-bs-toggle="tab"
                    href="#scaling-firewall"
                  >
                    Firewall
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link text-active-primary py-5 me-6 d sphide"
                    data-bs-toggle="tab"
                    href="#scaling-loadbalancer"
                  >
                    Load Balancers
                    {/* {scalingData!=null?(<>
    {scalingData.target_groups.length>0?('Target Groups'):(<>
    {scalingData.load_balancers.length>0?('Loadbalancer'):('')}
    </>)}
    </>):('')} */}

                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link text-active-primary py-5 me-6 d sphide"
                    data-bs-toggle="tab"
                    href="#scaling-policy"
                  >
                    Scaling Policy
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link text-active-primary py-5 me-6"
                    data-bs-toggle="tab"
                    href="#scaling-schedules"
                  >
                    Scaling Schedules
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link text-active-primary py-5 me-6"
                    data-bs-toggle="tab"
                    href="#history"
                  >
                    History
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link text-active-primary py-5 me-6"
                    data-bs-toggle="tab"
                    href="#destroy"
                  >
                    Destroy
                  </a>
                </li>
              </ul>
              {/*begin::Navs*/}
            </div>
          </div>

          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade active show" id="connection-details" role="tabpanel">
              <div className="col-xl-12 card mb-5">
                <div className="card-header border-3 mb-5 pt-2">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder text-gray-800">Scaling Configuration</span>
                    <span className="text-gray-400 mt-1 fw-bold fs-6">
                      Name/Min Size/Max Size/Desired Size
                    </span>
                  </h3>
                  <div className="card-toolbar">
                    <a onClick={toggleDeploymentConfiguration} id="deployment_button" className="btn btn-light-primary btn-sm">Deployment Configuration</a>
                  </div>
                </div>
                <div className="mx-9 pb-5">
                  <div className="row g-9">
                    <div className="col-md-4 col-lg-4 col-xxl-4">
                      <div className="mb-10 pe-3">
                        <label htmlFor="ClusterLabel" className="required form-label sphide">
                          Name
                        </label>
                        <input
                          className="form-control form-control-sm "
                          type="text"
                          name="name"
                          id="update_name"
                          defaultValue={scalingData.name}
                          placeholder="Enter Name"
                        />
                      </div>
                    </div>

                    <div className="col-md-2 col-lg-2 col-xxl-2">
                      <div className="mb-10">
                        <label htmlFor="ClusterLabel" className="required form-label sphide">
                          Min Size
                        </label>
                        <input
                          className="form-control form-control-sm"
                          type="number"
                          name="minsize"
                          id="update_minsize"
                          defaultValue={scalingData.minsize}
                          placeholder="Enter Min Size"
                          onChange={handleMinSizeChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-2 col-lg-2 col-xxl-2">
                      <div className="mb-10">
                        <label htmlFor="ClusterLabel" className="required form-label sphide">
                          Max Size
                        </label>
                        <input
                          className="form-control form-control-sm"
                          type="number"
                          name="maxsize"
                          id="update_maxsize"
                          defaultValue={scalingData.maxsize}
                          placeholder="Enter Max Size"
                          onChange={handleMaxSizeChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-2 col-lg-2 col-xxl-2">
                      <div className="mb-10">
                        <label htmlFor="ClusterLabel" className="required form-label sphide">
                          Desired Size
                        </label>
                        <input
                          className="form-control form-control-sm"
                          type="number"
                          name="desiredsize"
                          id="update_desiredsize"
                          defaultValue={scalingData.desiredsize}
                          placeholder="Enter Desired Size"
                          onChange={handleDesiredSizeChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-2 col-lg-2 col-xxl-2">
                      <div className="mb-10" style={{marginTop:"0.3rem"}}>
                        <label className="form-label text-white">
                          .
                        </label>
                        <a className="form-control btn btn-sm btn-primary text-right" onClick={updateAutoScaling} id="update-scaling-config-btn" style={{padding:'0.72rem 0.6rem'}}> Update</a>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div id="deployment_sec" className="d-none">
                {scalingData ? (<>
                  <SnapshotListComponent snapshotid={scalingData.snapshotid} stack={scalingData.stack} image={scalingData.image} dcslug={scalingData.dcslug} selectSnapshot={updateAutoScalingSnapshot} />
                </>) : (<></>)}
              </div>
              {scalingData ? (<>
                <Overview cloud_data={scalingData.instances} />
                <Vpc vpcArr={scalingData.vpc} getAutoScaling={getAutoScaling}/>
              </>) : (<></>)}

            </div>

            <div className="tab-pane fade" id="scaling-firewall" role="tabpanel">
              <Firewall />
            </div>
            <div className="tab-pane fade" id="scaling-loadbalancer" role="tabpanel">
              {/* {scalingData!=null?(<>
    {scalingData.target_groups.length>0?(<><TargetGroups /></>):(<><LoadBalancer /></>)}
    </>):('')} */}
              <LoadBalancer />
              <TargetGroups />
            </div>
            <div className="tab-pane fade" id="scaling-policy" role="tabpanel">
              <Policy />
            </div>
            <div className="tab-pane fade" id="scaling-schedules" role="tabpanel">
              <Schedules />
            </div>
            <div className="tab-pane fade" id="history" role="tabpanel">
              {scalingData ? (<>
                <History cloud_data={scalingData.deleted_instances} />
              </>) : (<></>)}
            </div>
            <div className="tab-pane fade" id="destroy" role="tabpanel">
              <div className="card">
                <div
                  className="card-header border-0 cursor-pointer"
                  role="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_account_deactivate"
                  aria-expanded="true"
                  aria-controls="kt_account_deactivate"
                >
                  <div className="card-title m-0">
                    <h3 className="fw-bold m-0">Delete Auto Scaling</h3>
                  </div>
                </div>
                <div id="kt_account_settings_deactivate" className="collapse show">
                  <form id="destroyform" className="form">
                    <div className="card-body border-top p-9">
                      <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
                        <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              opacity="0.3"
                              x={2}
                              y={2}
                              width={20}
                              height={20}
                              rx={10}
                              fill="black"
                            />
                            <rect
                              x={11}
                              y={14}
                              width={7}
                              height={2}
                              rx={1}
                              transform="rotate(-90 11 14)"
                              fill="black"
                            />
                            <rect
                              x={11}
                              y={17}
                              width={2}
                              height={2}
                              rx={1}
                              transform="rotate(-90 11 17)"
                              fill="black"
                            />
                          </svg>
                        </span>
                        <div className="d-flex flex-stack flex-grow-1">
                          <div className="fw-bold">
                            <h4 className="text-gray-900 fw-bold">
                              This will delete your auto scaling.
                            </h4>
                            <div className="fs-6 text-gray-700">
                              Note: This option will delete your auto scaling rule for
                              permanently and this not be able to undo.
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div
                      className="card-footer d-flex justify-content-end py-6 px-9"
                      id="destroy_btn"
                    >
                      <a
                        id="deleteScaling-btn"
                        onClick={(e) => deleteAutoScaling('' + scalingData.name + '')}
                        className="btn btn-sm btn-danger fw-bold"
                      >

                        Delete
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>) : (<>
          <NotFound />
        </>)}
      </>) : (<>
        <TableLoader />
        <TopBarHeaderLine />
      </>
      )}
    </>)
}

