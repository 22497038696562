import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';
import ProductsUrl from '../../../../../Docs/Docsdata';
import CustomDoc from '../../../../../../../CustomHooks/CustomDoc';
function PublicNetwork(props) {
  const {
    cloudData,
    publicData,
    handleChangeIpStatus,
    addAdditionalIp,
    deletePublicIp,
    updateRdns,
  } = props;

  useEffect(() => {
    if (cloudData === undefined) throw new Error("Missing prop: cloudData");
    if (handleChangeIpStatus == undefined)
      throw new Error("Missing prop: handleChangeIpStatus");
    if (addAdditionalIp === undefined)
      throw new Error("Missing prop: addAdditionalIp");
    if (publicData === undefined)
      throw new Error("Missing prop: publicData");
    if (deletePublicIp === undefined)
      throw new Error("Missing prop: deletePublicIp");
    if (updateRdns === undefined) throw new Error("Missing prop: updateRdns");
  }, [cloudData]);

  console.log(publicData);

  return (
    <>
      <div className="card">
        {/*begin::Card header*/}
        <div className="card-header">
          {/*begin::Heading*/}
          <div className="card-title">
            <h3>IPv4 Public IP Address </h3>
          </div>
          {/*end::Heading*/}
          {/*begin::Toolbar*/}
          <div className="card-toolbar">
            <CustomDoc url={ProductsUrl[0]?.cloud + "/manage-cloud/network/"} />
            {cloudData?.networks ? (
              <>
                {cloudData?.networks?.public?.v4?.length > 1 ? (
                  <a
                    id="disable_public_ip"
                    onClick={() => {
                      handleChangeIpStatus(
                        "disablepublicip",
                        "disabled",
                        "disable_public_ip"
                      );
                    }}
                    className="btn btn-sm btn-danger my-1 me-3 ms-3 sphide"
                  >
                    + Disable Public IP
                  </a>
                )
                  : (
                    <a
                      id="enable_public_ip"
                      onClick={() => {
                        handleChangeIpStatus(
                          "enablepublicip",
                          "enabled",
                          "enable_public_ip"
                        );
                      }}
                      className="btn btn-sm btn-primary mx-3 my-1 me-3 sphide"
                    >
                      + Enable Public IP
                    </a>
                  )}
                <a
                  id="add-additional-ip"
                  onClick={addAdditionalIp}
                  className="btn btn-sm btn-primary my-1 sphide"
                >
                  + Assign Additional Public IP
                </a>
              </>
            ) : (
              <a
                id="enable_public_ip"
                onClick={() => {
                  handleChangeIpStatus(
                    "enablepublicip",
                    "enabled",
                    "enable_public_ip"
                  );
                }}
                className="btn btn-sm btn-primary my-1 me-3 sphide"
              >
                + Enable Public IP
              </a>
            )}
          </div>
          {/*end::Toolbar*/}
        </div>
        {/*end::Card header*/}
        {/*begin::Card body*/}
        <div className="card-body p-0">
          {/*begin::Table wrapper*/}
          <div className="table-responsive">
            {/*begin::Table*/}
            <table
              className="table table-striped table-flush align-middle table-row-bordered table-row-solid gy-4"
              id="kt_security_license_usage_table"
            >
              {/*begin::Thead*/}
              <thead className="border-gray-200 fs-5 fw-bold bg-secondary">
                <tr>
                  <th className="w-250px ps-9">IP Address</th>
                  <th className="w-25px ps-9" />
                  <th className="w-250px">Gateway</th>
                  <th className="w-25px ps-9" />
                  <th className="w-250px ps-3">Netmask</th>
                  <th className="w-25px ps-9" />
                  <th className="text-center pe-5 w-250px">RDNS</th>
                  <th className="w-25px ps-9" />
                  <th className="w-250px text-start ps-4"></th>
                  <th className="w-150px ps-9" />
                </tr>
              </thead>
              {/*end::Thead*/}
              {/*begin::Tbody*/}
              <tbody id="public-IP-list" className="fw-6 fw-bold text-gray-600">
                {publicData && publicData.length > 0 ? (
                  publicData
                    .filter(value => Object.keys(value).length !== 0)  // Filter out empty objects/arrays
                    .length > 0 ? (
                    publicData
                      .filter(value => Object.keys(value).length !== 0)
                      .map((value, index) => (
                        <React.Fragment key={index}>
                          <tr>
                            <td className="ps-9 text-dark">{value.ip_address}</td>
                            <td></td>
                            <td className="text-dark">{value.gateway}</td>
                            <td></td>
                            <td className="text-dark">{value.netmask}</td>
                            <td></td>
                            <td className="ps-5 text-center text-dark">
                              <form
                                className="form"
                                id={`updaterdns_${value.ip_address.replaceAll(".", "_")}`}
                                name={`updaterdns_${value.ip_address.replaceAll(".", "_")}`}
                              >
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  id="rdns"
                                  name="rdns"
                                  placeholder="server1.domain.com"
                                  defaultValue={value.rdns || ""}
                                />
                              </form>
                            </td>
                            <td></td>
                            <td className="text-end">
                              <a
                                id={`update-rdns-${value.ip_address.replace(/\./g, "-")}`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  updateRdns(value.ip_address);
                                }}
                                className="btn btn-primary btn-sm sphide"
                              >
                                Update
                              </a>
                            </td>
                            {value.primary === "0" ? (
                              <td className="text-end">
                                <a
                                  id={`delete-ip-${value.ip_address.replace(/\./g, "-")}`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    deletePublicIp(value.ip_address);
                                  }}
                                  className="btn btn-danger btn-sm sphide"
                                >
                                  Delete
                                </a>
                              </td>
                            ) : (
                              <td className="text-center" />
                            )}
                          </tr>
                        </React.Fragment>
                      ))
                  ) : (
                    <tr>
                      <td colSpan="12">
                        <div className="d-flex flex-column flex-center">
                          <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                        </div>
                      </td>
                    </tr>
                  )
                ) : (
                  <tr>
                    <td colSpan="12">
                      <div className="d-flex flex-column flex-center">
                        <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                      </div>
                    </td>
                  </tr>
                )}

                {/* {publicData && publicData.length > 0 ? (
                  publicData
                    .filter(value => Object.keys(value).length !== 0)  // Filter out empty objects/arrays
                    .length > 0 ? (
                    publicData
                      .filter(value => Object.keys(value).length !== 0)
                      .map((value, index) => (
                        <>
                        <tr>
                          <td colspan="12" >
                            <div className="d-flex flex-column flex-center">
                              <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                            </div>
                          </td>
                        </tr>
                        </>
                      ) : (
                    <>
                      <tr>
                        <td className="ps-9 text-dark">
                          {value.ip_address}
                        </td>
                        <td></td>
                        <td className="text-dark">{value.gateway}</td>
                        <td></td>
                        <td className="text-dark">{value.netmask}</td>{" "}
                        <td></td>
                        <td className="ps-5 text-center text-dark">
                          {" "}
                          <form
                            className="form"
                            id={
                              "updaterdns_" +
                              value.ip_address.replaceAll(".", "_")
                            }
                            name={
                              "updaterdns_" +
                              value.ip_address.replaceAll(".", "_")
                            }
                          >
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="rdns"
                              name="rdns"
                              placeholder="server1.domain.com"
                              defaultValue={"" + value.rdns + ""}
                            // style={{ width: "65%" }}
                            />{" "}
                          </form>
                        </td>
                        <td></td>
                        <td
                          className="text-end"
                        >
                          <a
                            id={
                              "update-rdns-" +
                              value.ip_address.replace(/\./g, "-")
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              updateRdns(value.ip_address);
                            }}
                            className="btn btn-primary btn-sm sphide"
                          >
                            Update
                          </a>
                        </td>{" "}
                        {value.primary == "0" ? (
                          <td
                            className="text-end"
                          >
                            <a
                              id={
                                "delete-ip-" +
                                value.ip_address.replace(/\./g, "-")
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                deletePublicIp(value.ip_address);
                              }}
                              className="btn btn-danger btn-sm sphide"
                            >
                              Delete
                            </a>
                          </td>
                        ) : (
                          <td className="text-center" />
                        )}
                      </tr>
                    </>
                  )}
              </>
                  ))} */}
              </tbody>
              {/*end::Tbody*/}
            </table>
            {/*end::Table*/}
          </div>
          {/*end::Table wrapper*/}
        </div>
        {/*end::Card body*/}
      </div >
    </>
  );
}

PublicNetwork.propTypes = {

  cloudData: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.oneOf([null]),
  ]).isRequired,

  publicData: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.oneOf([null]),
  ]).isRequired,

  handleChangeIpStatus: PropTypes.func.isRequired,

  addAdditionalIp: PropTypes.func.isRequired,

  deletePublicIp: PropTypes.func.isRequired,

  updateRdns: PropTypes.func.isRequired,
};

export default PublicNetwork;
