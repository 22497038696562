import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { IsCloudUpdate } from "../../../../../Redux/Services/Action/actions";
import { ToastContainer, toast } from 'react-toastify';
import useRazorpay from "react-razorpay";
import 'react-toastify/dist/ReactToastify.css';
import swal from 'sweetalert';
import UseApi from "../../../../../CustomHooks/useApi";
import UsePost from "../../../../../CustomHooks/usePost";
import spinner from "./../spinner.svg";
import UsePostData from "../../../../../CustomHooks/usePostData";
import TopBarHeader from "../../../../../CustomHooks/TopBarHeader";
import TopBarHeaderLine from "../../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../../Loaders/TableLoader";
import localStorage from "local-storage";
import ProductsUrl from "../../../Docs/Docsdata";
import CustomDoc from "../../../../../CustomHooks/CustomDoc";
const Billing = (props) => {
  const navigate = useNavigate();
  const billing_Cycle = useRef();
  var query = window.location.href;
  var vars = query.split("/");
  var id = vars[4];
  const [billingData, setBillingData] = useState(null);
  const dispatch = useDispatch();
  const hour_nextinvoiceamount = useRef(0);
  const hour_hourlycost = useRef(0);
  const hour_cloudhourlycost = useRef(0);
  const [pageStatus, setPageStatus] = useState(false)
  const hour_imagehourlycost = useRef(0);
  const hour_backuphourlycost = useRef(0);
  const [invoiceData, setInvoiceData] = useState(null);
  const [userData, setUserData] = useState(null);

  const obc_cost = useRef(0);
  const obc_cloudcost = useRef(0);
  const obc_cimagecostost = useRef(0);
  const obc_backupcost = useRef(0);

  const user_data = useRef(null);
  const navRedirect = () => {
    setTimeout(() => {
      navigate("/cloud/" + id);
    }, 3000);
  }
  const getBilling = async () => {

    const rbc = await UseApi('get', 'cloud/' + id + '/getbillingcycle', '');
    const data = Object.entries(rbc);
    if (Object.keys(data).length === 0) {
      setBillingData(null);
    } else {
      setBillingData(data);
    }
  }

  useEffect(() => {
    var user_info = localStorage.get('user_info');
    user_data.current = user_info;
    setUserData(user_info);
    getBilling();
  }, [props.cloudData]);

  const changeBillingCycle = async () => {
    toast.dismiss();
    const billingcycle_select = document.querySelector("#billingcycle_select");
    if (billingcycle_select) {
      if (billingcycle_select.value == "") {
        const dt = toast.loading("Billing cycle changing, Please wait...");
        toast.update(dt, {
          render: "Please select a billingcycle", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
      } else {
        swal({
          title: "Are you sure?",
          text: "Do you really want to change your billing cycle!",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
          .then(async (willDelete) => {
            if (willDelete) {
              setPageStatus(true)
              const dt = toast.loading("Billing cycle changing, Please wait...");
              const responseData = await UsePost('post', 'cloud/' + id + '/billingcycle', 'cloudbillingcycle');
              // ////console.log(responseData);
              if (responseData.status === 'error') {
                setPageStatus(false)
                toast.update(dt, {
                  render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
                });
              } else if (responseData.status === 'success') {
                setPageStatus(false)
                toast.update(dt, {
                  render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
                });
                //console.log(responseData);
                if (responseData.invoiceid) {
                  openInvoice(responseData.invoiceid);

                  const data = await UseApi('get', 'cloud/' + id, '');
                  dispatch(props.updateCloudStatus(data.cloud[0]));
                  navRedirect();

                }

              }
            } else {
              swal({
                title: "Billing cycle",
                text: "Your billing cycle not changed!",
                icon: "success",
                button: "OK",
              });
            }
          });
      }
    }
  }
  const openInvoice = async (id) => {
    toast.dismiss();
    const invoice = await UseApi('get', 'billing/' + id, '');
    setInvoiceData(invoice.invoices[0]);
    ////console.log(invoice);
    const transTabel = document.querySelector("#trans-tabel");
    const invoiceCredit = document.querySelector("#invoice-credit");
    var userAmount = 0;
    if (userData != null) {
      userAmount = userData.availablecredit;
    }
    if (invoice.invoices[0]) {
      if (transTabel) {

        if (invoice.invoices[0].transs.length <= 0) {
          transTabel.classList.add("d-none");
        } else {
          transTabel.classList.remove("d-none");
        }
      }
      // //console.log(parseFloat(invoice.invoices[0].rowtotal));
      if (invoiceCredit) {
        if (parseFloat(userAmount) > parseFloat(invoice.invoices[0].rowtotal)) {
          invoiceCredit.classList.remove("d-none");
        } else {
          invoiceCredit.classList.add("d-none");
        }
      }
    }
    var drawerElement = document.querySelector("#invoice_drawer_billing");
    var drawer_overlay_invoice = document.querySelector("#drawer_overlay_invoice");
    if(drawerElement && drawer_overlay_invoice){
      drawer_overlay_invoice.classList.remove("d-none");
      drawerElement.classList.add("drawer-on");
      drawerElement.style.width = '80%';
    }
  }

  const closeInvoice = async () => {
    var drawerElement = document.querySelector("#invoice_drawer_billing");
    var drawer_overlay_invoice = document.querySelector("#drawer_overlay_invoice");
    if(drawerElement && drawer_overlay_invoice){
      drawer_overlay_invoice.classList.add("d-none"); 
      drawerElement.classList.remove("drawer-on");
      drawerElement.style.width = '80%';
    }
  }

  const applyCreditAmount = async (id) => {
    toast.dismiss();
    swal({
      title: "Are you sure?",
      text: "Once Applied, your available credit will reduce according to this amount!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          setPageStatus(true)
          const dt = toast.loading("Invoice processing...");
          const dnsp = await UsePost('post', 'billing/applycredit', 'applycredit');
          if (dnsp.status == 'error') {
            setPageStatus(false)
            toast.update(dt, {
              render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
          } else {
            setPageStatus(false)
            toast.update(dt, {
              render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
            navRedirect();
          }
        } 
      });

  }
  const Razorpay = useRazorpay();
  const invoicePayment = useCallback(async () => {
    toast.dismiss();
    const payInvoiceBtn = document.querySelector("#invoice-payment-button");
    if (payInvoiceBtn) {
      payInvoiceBtn.value = "Loading...";
    }
    const u_data = await UseApi('get', 'account/info', '');
    const uData = u_data.user;
    const u_razorpay_info = await UseApi('get', 'mhcsecinfo', '');

    ////console.log(razorpay_info); 
    const amountFund = document.querySelector("#invoiceAmountFund");
    const descp_invoice_id = document.querySelector("#invoice_id");
    var invoice_id = "";
    //console.log(descp_invoice_id.value);
    if (descp_invoice_id) {
      invoice_id = descp_invoice_id.value;
    }
    var rorder_id = "";
    var rfullname = "";
    var rmobile = "";
    var remail = "";
    var ramount = 0;
    var rcurrency = "";
    if (amountFund) {
      ramount = amountFund.value;
    }

    if (uData) {
      rorder_id = uData.razorpay_orderid;
      rfullname = uData.fullname;
      rmobile = uData.mobile;
      remail = uData.email;
      rcurrency = uData.currency;
    }
    // //console.log(rorder_id);
    // //console.log(rfullname);
    // //console.log(rmobile);
    // //console.log(remail);
    // //console.log(ramount);
    // //console.log(invoice_id);
    const options = {
      //key: "rzp_live_aeMFERiIAsYFFF",
      key: u_razorpay_info.razorpay_key,
      amount: ramount * 100,
      currency: "INR",
      name: "Microhost Cloud",
      description: invoice_id,
      image: "https://www.microhost.com/assets/favicon/apple-touch-icon.png",
      prefill: {
        name: rfullname,
        email: remail,
        contact: rmobile,
      },
      options: {
        checkout: {
          method: {
            netbanking: 1,
            card: 0,
            upi: 1,
          }
        }
      },
      handler: async (res) => {
        //res.razorpay_payment_id
        //res.status_code
        //pay_L0C8DacFM1Voqi
        //console.log(res);
        if (res.status_code == 200) {
          swal({
            title: "Processing please wait...",
            icon: spinner,
            html: true,
            showSpinner: true
          });
          const resData = await UsePostData('post', 'billing/addpayment', { 'platform': '2023', 'invoiceid': invoice_id, 'transid': res.razorpay_payment_id, 'amount': ramount, 'currency': rcurrency, 'gateway': 'Razorpay' });
          if (resData.status == "success") {
            swal({
              title: "Invoice",
              text: "Invoice paid successfuly",
              icon: "success",
              button: "OK",
            });
            setTimeout(() => {
              window.location.reload();
            }, 4000);
          } else {
            swal({
              title: "Invoice",
              text: "Invoice not paid!",
              icon: "warning",
              button: "OK",
            });
          }

          //console.log(resData);
          // const paymentInfo=await axios.get('https://'+u_razorpay_info.razorpay_key+':'+u_razorpay_info.razorpay_secret+'@api.razorpay.com/v1/payments/'+res.razorpay_payment_id+'');
          // //console.log(paymentInfo);
        }
      },
      notes: {
        address: "Microhost Cloud- Fund add",
      },
      theme: {
        color: "#377dff",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
    if (payInvoiceBtn) {
      payInvoiceBtn.value = "Pay";
    }
  }, [Razorpay]);
  return (
    <>
      {billingData != null && billingData.length > 0 ?
        <div className="card">
          {/*begin::Card header*/}
          <div
            className="card-header border-0 cursor-pointer"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_account_deactivate"
            aria-expanded="true"
            aria-controls="kt_account_deactivate"
          >
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">Billing Cycle</h3>
            </div>
            <div className="card-toolbar">
          <CustomDoc url={ProductsUrl[0]?.cloud+"/manage-cloud/billing/"}/>
          </div>
          </div>
          {/*end::Card header*/}
          <div className="mx-9 mt-5">
            {/*begin::Notice*/}
            <div className="notice d-flex  rounded border-primary border mb-9 p-6">
              {/* <!--begin::Wrapper--> */}
              <div className="d-flex flex-stack flex-grow-1">
                {/* <!--begin::Content--> */}
                <div className="fw-bold">
                  <div id="billing-Cycle" className="fs-6 text-light-700">
                    Current billing cycle is :
                    <span className="badge badge-light-success fs-7 fw-bolder me-3">{props.cloudData.billingcycle}</span>
                    {props.cloudData.discount_type ? (<>
                      {props.cloudData.discount_type == "Fixed" ? (<>
                        <span className="badge badge-light-success fs-7 fw-bolder"> Discount: {user_data.current != null && user_data.current.currencyprefix ? (user_data.current.currencyprefix) : ('')} {props.cloudData.discount_value} </span>
                      </>) : (<>
                        <span className="badge badge-light-success fs-7 fw-bolder"> Discount:  {props.cloudData.discount_value}% </span>
                      </>)}
                    </>) : ('')}
                    <br /><br />
                    {/* {user_data.current!=null?(user_data.current.currencyprefix):('CCCCsadsads')} */}
                    {props.cloudData.billingcycle == "hourly" ? (<>
                      Current Hours : <span className="badge badge-light-success fs-7 fw-bolder">{props.cloudData.nextinvoicehours ? (props.cloudData.nextinvoicehours) : (0)}</span> <br /><br />
                      <span className="badge badge-light-primary fs-7 fw-bolder">Total Hourly Usage: {user_data.current != null ? (user_data.current ? (user_data.current.currencyprefix) : ('')) : ('')} {props.cloudData.nextinvoicehours ? (props.cloudData.nextinvoiceamount) : (0)}</span> <br /><br />
                      <span className="badge badge-success fs-7 fw-bolder">Hourly Cost: {user_data.current != null ? (user_data.current ? (user_data.current.currencyprefix) : ('')) : ('')} {props.cloudData.hourlycost ? (props.cloudData.hourlycost) : (0)}</span> &nbsp; = &nbsp;
                      <span className="badge badge-primary fs-7 fw-bolder"> Cloud Hourly Cost: {user_data.current != null ? (user_data.current ? (user_data.current.currencyprefix) : ('')) : ('')} {props.cloudData.cloudhourlycost ? (props.cloudData.cloudhourlycost) : (0)}</span> &nbsp; + &nbsp;
                      <span className="badge badge-primary fs-7 fw-bolder">OS Hourly Cost: {user_data.current != null ? (user_data.current ? (user_data.current.currencyprefix) : ('')) : ('')} {props.cloudData.imagehourlycost ? (props.cloudData.imagehourlycost) : (0)}</span> &nbsp; + &nbsp;

                      <span className="badge badge-primary fs-7 fw-bolder">Backup Hourly Cost: {user_data.current != null ? (user_data.current ? (user_data.current.currencyprefix) : ('')) : ('')} {props.cloudData.backuphourlycost ? (props.cloudData.backuphourlycost) : (0)}

                      </span>
                    </>) : (<>
                      <p>
                        <span className="badge badge-success fs-7 fw-bolder me-3">Cost : {user_data.current != null ? (user_data.current ? (user_data.current.currencyprefix) : ('')) : ('')} {props.cloudData.cost ? (props.cloudData.cost) : (0)}</span>
                        <span className="badge badge-success fs-7 fw-bolder me-3">Cloud Cost : {user_data.current != null ? (user_data.current ? (user_data.current.currencyprefix) : ('')) : ('')} {props.cloudData.vmcost ? (props.cloudData.vmcost) : (0)}</span>
                        <span className="badge badge-success fs-7 fw-bolder me-3">OS Cost : {user_data.current != null ? (user_data.current ? (user_data.current.currencyprefix) : ('')) : ('')} {props.cloudData.imagecost ? (props.cloudData.imagecost) : (0)}</span>
                        <span className="badge badge-success fs-7 fw-bolder">Backup Cost : {user_data.current != null ? (user_data.current ? (user_data.current.currencyprefix) : ('')) : ('')} {props.cloudData.backupcost ? (props.cloudData.backupcost) : (0)}</span>
                      </p>
                    </>
                    )}
                    <br /><br />
                    <span className="text-info">18% GST would be extra</span>

                  </div>

                </div>
                {/* <!--end::Content--> */}
              </div>
              {/* <!--end::Wrapper--> */}
            </div>
            {/*end::Notice*/}
          </div>
          {/*begin::Content*/}
          <div id="kt_account_settings_deactivate" className="collapse show">
            {/*begin::Form*/}
            <form id="cloudbillingcycle" className="form">
              {/*begin::Card body*/}
              <div className="card-body border-top p-9">
                {/*begin::Form input row*/}
                <div className="mb-10">
                  <label htmlFor="size" className="required form-label">
                    Available Billing Cycles
                  </label>
                  <select
                    className="form-select"
                    name="billingcycle"
                    id="billingcycle_select"
                  >
                    <option value="">Select New Billing Cycles</option>
                    {billingData != null && (billingData && billingData.map((value, index) => (
                      <option value={value[0]}>{value[1] + " /" + value[0]}</option>
                    )))}
                  </select>

                </div>
                {/*end::Form input row*/}
                {/*begin::Notice*/}
                <div className=" notice d-flex bg-light-primary rounded border-primary border mb-9 p-6">
                  {/*begin::Wrapper*/}
                  <div className="d-flex flex-stack flex-grow-1">
                    {/*begin::Content*/}
                    <div className="fw-bold">
                      <p className="text-gray-900 ">
                        On click "Change Billing Cycle" you will receive a invoice
                        for new billing cycle and asap you get it paid your cloud
                        server billing cycle will get change.
                      </p>
                    </div>
                    {/*end::Content*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Notice*/}
              </div>
              {/*end::Card body*/}
              {/*begin::Card footer*/}
              <div className="card-footer d-flex justify-content-end py-6 px-9">
                <a
                  onClick={changeBillingCycle}
                  className="btn btn-sm btn-primary fw-bold"
                >
                  Change Billing Cycle
                </a>
              </div>
              {/*end::Card footer*/}
            </form>
            {/*end::Form*/}
          </div>
          {/*end::Content*/}
        </div>
        :
        <TableLoader />
      }

     <div id="drawer_overlay_invoice" onClick={closeInvoice} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>
      <div
        id="invoice_drawer_billing"
        className="bg-white drawer drawer-end"
        data-kt-drawer="true"
        data-kt-drawer-overlay="true"
        data-kt-drawer-activate="true"
        data-kt-drawer-width="1200px"
        style={{ width: "1200px !important" }}
      >
        <div className=" container-fluid">
          {/*begin::Body*/}
          <div className=" p-5">
            {/*begin::Layout*/}
            <div className="d-flex flex-column flex-xl-row">
              {/*begin::Content*/}
              <div className="flex-lg-row-fluid me-xl-18 mb-10 mb-xl-0">
                {/*begin::Invoice 2 content*/}
                <div className="mt-n1">
                  {/*begin::Top*/}
                  <div className="d-flex flex-stack pb-10">
                    {/*begin::Logo*/}
                    <a >
                      <img
                        alt="Logo"
                        src="https://www.microhost.com/images/logo-dark-registered-cloud-blue.png"
                        width="200px"
                      />
                    </a>
                    {/*end::Logo*/}
                    {/*begin::Action*/}
                    <div id="payinvoice" />
                    <div id="paidinvoice" className="">
                      {invoiceData != null && invoiceData.status == "Paid" ? (<>
                        <button className="btn btn-success me-5">Paid</button>
                      </>) : (<>
                        <button className="btn btn-success me-5" id="invoice-payment-button" onClick={invoicePayment}>Pay</button>
                      </>)}
                      <button className="btn btn-light-danger me-5" onClick={closeInvoice}>close</button>
                    </div>
                    {/*end::Action*/}
                  </div>
                  {/*end::Top*/}
                  {/*begin::Wrapper*/}
                  <div className="m-0">
                    {/*begin::Label*/}
                    <div className="fw-bolder fs-3 text-gray-800 mb-8">
                      Invoice <span>{invoiceData != null && invoiceData.id ? (invoiceData.id) : ('')}</span>
                    </div>
                    {/*end::Label*/}
                    {/*begin::Row*/}
                    <div className="row g-5 mb-11">
                      {/*end::Col*/}
                      <div className="col-sm-6">
                        {/*end::Label*/}
                        <div className="fw-bold fs-7 text-gray-600 mb-1">
                          Issue Date:
                        </div>
                        {/*end::Label*/}
                        {/*end::Col*/}
                        <div
                          className="fw-bolder fs-6 text-gray-800"
                          id="invoice-date"
                        >
                          {invoiceData != null && invoiceData.date ? (invoiceData.date) : ('')}
                        </div>
                        {/*end::Col*/}
                      </div>
                      {/*end::Col*/}
                      {/*end::Col*/}
                      <div className="col-sm-6"></div>
                      {/*end::Col*/}
                    </div>
                    {/*end::Row*/}
                    {/*begin::Row*/}
                    <div className="row g-5 mb-12">
                      {/*end::Col*/}
                      <div className="col-sm-6">
                        {/*end::Label*/}
                        <div className="fw-bold fs-7 text-gray-600 mb-1">
                          Issue For:
                        </div>
                        {/*end::Label*/}
                        {/*end::Text*/}
                        <div
                          className="fw-bolder fs-6 text-gray-800"
                          id="company_name"
                        >
                          {userData != null && userData.company ? (userData.company) : ('')}
                        </div>
                        {/*end::Text*/}
                        {/*end::Description*/}
                        <div className="fw-bold fs-7 text-gray-600">
                          <span id="user-address">{userData != null && userData.address ? (userData.address + ", ") : ('')} {userData != null && userData.city ? (userData.city + ", ") : ('')} {userData != null && userData.country ? (userData.country + ", ") : ('')}</span>
                          <br />
                          <span id="user-mobile" >{userData != null && userData.mobile ? (userData.mobile) : ('')}</span>
                          <br />
                          <span id="user-gst" >{userData != null && userData.gstnumber ? (userData.gstnumber) : ('')}</span>
                        </div>
                        {/*end::Description*/}
                      </div>
                      {/*end::Col*/}
                      {/*end::Col*/}
                      <div className="col-sm-6 text-end">
                        {/*end::Label*/}
                        <div className="fw-bold fs-7 text-gray-600 mb-1">
                          Issued By:
                        </div>
                        {/*end::Label*/}
                        {/*end::Text*/}
                        <div className="fw-bolder fs-6 text-gray-800">
                          Utho Platforms Private Limited
                        </div>
                        {/*end::Text*/}
                        {/*end::Description*/}
                        <div className="fw-bold fs-7 text-gray-600">
                          MicroHost Cloud
                          <br />
                          HD-204, WeWork, Berger Delhi One, Sector 16B, C-001/A2, Gautam Buddha Nagar, Noida 201301 Uttar Pradesh - India
                          <br />
                          <strong>GSTIN:</strong> 09AAICM9463A1ZB
                          <br />
                          <strong>PAN Number:</strong> AAICM9463A
                          <br />
                          <strong>SAC:</strong> 998315
                        </div>
                        {/*end::Description*/}
                      </div>
                      {/*end::Col*/}
                    </div>
                    {/*end::Row*/}
                    {/*begin::Content*/}
                    <div className="flex-grow-1">
                      {/*begin::Table*/}
                      <div className="table-responsive border mb-9">
                        <table className="table mb-3 table-row-bordered">
                          <thead className="bg-secondary">
                            <tr className="border-bottom fs-6 fw-bolder">
                              <th className="w-10px" />
                              <th className="w-175px pb-2 ">Item Description</th>
                              <th className="w-70px text-end pb-2" />
                              <th className="w-100px text-start pb-2">Amount</th>
                              <th className="w-100px text-start pb-2">Discount</th>
                            </tr>
                          </thead>
                          <tbody id="item-list">
                            {invoiceData != null ? (invoiceData.items != null ? (invoiceData.items.map((value, index) => (<>
                              <tr className="fw-bold text-gray-700">
                                <td></td>
                                <td className="d-flex align-items-center pt-6">{value.description}</td>
                                <td></td>
                                <td className="pt-6 fw-bold">{userData != null && userData.currencyprefix ? (userData.currencyprefix) : ('')} {value.amount}</td>
                                <td className="pt-6 fw-bold">{userData != null && userData.currencyprefix ? (userData.currencyprefix) : ('')} {value.discount ? (value.discount) : (0)}</td>
                              </tr>
                            </>))) : ('')) : ('')}
                          </tbody>
                        </table>
                      </div>
                      {/*end::Table*/}
                      {/*begin::Container*/}
                      <div className="d-flex row">


                        <div className="col-lg-8 col-md-8 col-xxl-8">
                          {invoiceData != null && invoiceData.status != "Paid" ? (<>
                            <div
                              className="d-flex flex-wrap flex-stack mb-6"
                              id="invoice-credit"
                            >

                              {" "}
                              <div className="d-flex my-2">
                                {" "}
                                <div className="d-flex align-items-center position-relative me-4">
                                  {" "}
                                  <form id="applycredit" name="applycredit">
                                    {" "}
                                    <div className="input-group mb-5" id="wallet">
                                      <input
                                        type="hidden"
                                        name="invoiceid"
                                        value={invoiceData != null && invoiceData ? (invoiceData.id) : ('')} />
                                      <span class="input-group-text" >{userData != null && userData.currencyprefix ? (userData.currencyprefix) : ('')}</span>  <input type="text" class="form-control" placeholder="amount" id="amount" name="amount" min="10" value={invoiceData != null && invoiceData.rowtotal ? (invoiceData.rowtotal) : ('')} /><a class="btn btn-primary btn-sm" id="applycredit-btn" onClick={applyCreditAmount}>Apply Credit</a>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </>) : (<></>)}
                        </div>


                        <div className="col-lg-4 col-md-4 col-xxl-4">
                          {/*begin::Section*/}
                          <div className="mw-300px" id="invoice-final">
                            {/*begin::Item*/}{" "}
                            <div className="d-flex flex-stack mb-3">
                              {/*begin::Accountname*/}
                              <div className="fw-bold pe-10 text-gray-600 fs-7">Subtotal:</div>
                              {/*end::Accountname*/}
                              {/*begin::Label*/}
                              <div className="text-end fw-bolder fs-6 text-gray-800" id="subtotal-amount">
                                {invoiceData != null && invoiceData.subtotal ? (invoiceData.subtotal) : ('')}
                              </div>
                              {/*end::Label*/}
                            </div>
                            {/*end::Item*/}

                            {invoiceData != null && invoiceData.taxrate != '0.00' && invoiceData.taxrate2 == '0.00' ? (<>
                              {/*begin::Item*/}{" "}
                              <div className="d-flex flex-stack mb-3">
                                {/*begin::Accountname*/}
                                <div className="fw-bold pe-10 text-gray-600 fs-7">{invoiceData.taxrate}% IGST</div>
                                {/*end::Accountname*/}
                                {/*begin::Label*/}
                                <div className="text-end fw-bolder fs-6 text-gray-800">{userData != null && userData.currencyprefix ? (userData.currencyprefix) : ('')} {invoiceData.tax}</div>
                                {/*end::Label*/}
                              </div>
                              {/*end::Item*/}

                            </>) : ('')}


                            {invoiceData != null && invoiceData.taxrate != '0.00' && invoiceData.taxrate2 != '0.00' ? (<>
                              {/*begin::Item*/}{" "}
                              <div className="d-flex flex-stack mb-3">
                                {/*begin::Accountname*/}
                                <div className="fw-bold pe-10 text-gray-600 fs-7">{invoiceData.taxrate}% CGST</div>
                                {/*end::Accountname*/}
                                {/*begin::Label*/}
                                <div className="text-end fw-bolder fs-6 text-gray-800">{userData != null && userData.currencyprefix ? (userData.currencyprefix) : ('')} {invoiceData.tax2}</div>
                                {/*end::Label*/}
                              </div>
                              {/*end::Item*/}

                            </>) : ('')}

                            {invoiceData != null && invoiceData.taxrate2 != '0.00' ? (<>
                              {/*begin::Item*/}{" "}
                              <div className="d-flex flex-stack mb-3">
                                {/*begin::Accountname*/}
                                <div className="fw-bold pe-10 text-gray-600 fs-7">{invoiceData.taxrate}% SGST</div>
                                {/*end::Accountname*/}
                                {/*begin::Label*/}
                                <div className="text-end fw-bolder fs-6 text-gray-800">{userData != null && userData.currencyprefix ? (userData.currencyprefix) : ('')} {invoiceData.tax2}</div>
                                {/*end::Label*/}
                              </div>
                              {/*end::Item*/}

                            </>) : ('')}

                            {invoiceData != null && invoiceData.credit != '0' ? (<>
                              {/*begin::Item*/}{" "}
                              <div className="d-flex flex-stack mb-3">
                                {/*begin::Accountname*/}
                                <div className="fw-bold pe-10 text-gray-600 fs-7">Credits</div>
                                {/*end::Accountname*/}
                                {/*begin::Label*/}
                                <div className="text-end fw-bolder fs-6 text-gray-800">{userData != null && userData.currencyprefix ? (userData.currencyprefix) : ('')} {invoiceData.credit}</div>
                                {/*end::Label*/}
                              </div>
                              {/*end::Item*/}

                            </>) : ('')}


                            {/*begin::Item*/}{" "}
                            <div className="d-flex flex-stack mb-3">
                              {/*begin::Accountname*/}
                              <div className="fw-bold pe-10 text-gray-600 fs-7">Total</div>
                              {/*end::Accountname*/}
                              {/*begin::Label*/}
                              <div className="text-end fw-bolder fs-6 text-gray-800" id="total-amount">
                                {userData != null && userData.currencyprefix ? (userData.currencyprefix) : ('')} {invoiceData != null && invoiceData.total}
                                <input type="hidden" id="invoiceAmountFund" value={invoiceData != null && invoiceData.total} />
                                <input type="hidden" id="invoice_id" value={invoiceData != null && invoiceData.id} />
                              </div>
                              {/*end::Label*/}
                            </div>
                            {/*end::Item*/}
                          </div>

                        </div>
                        {/*end::Section*/}
                      </div>
                      {/*end::Container*/}
                      <div id="trans-tabel" className="d-none">
                        <div className="fw-bolder fs-2">Transactions</div>
                        <table className="table mb-3 table-row-bordered">
                          <thead className="bg-secondary">
                            <tr className="border-bottom fs-6 fw-bolder">
                              <th className="w-175px pb-2 text-center">
                                Transaction Date
                              </th>
                              <th className="w-70px text-end pb-2">
                                Transaction Gateways
                              </th>
                              <th className="w-80px text-end pb-2">
                                Transaction id
                              </th>
                              <th className="w-100px text-center pb-2">
                                Transaction Amount
                              </th>
                            </tr>
                          </thead>
                          <tbody
                            className="fw-bold text-gray-600"
                            id="trans-list"
                          >
                            {invoiceData != null && invoiceData.transs.map((value, index) => (<>
                              <tr>
                                <td class="text-center">{value.date}</td>
                                <td class="text-end">{value.gateway}</td>
                                <td class="text-end">{value.transid}</td>
                                <td class="text-center">{userData != null && userData.currencyprefix ? (userData.currencyprefix) : ('')} {value.amountin ? (value.amountin) : (value.amountout)}</td>
                              </tr>
                            </>))}
                          </tbody>
                        </table>
                        {/*end::Table*/}
                        <div id="due-price" style={{ marginTop: 25 }} >
                          <div class="d-flex justify-content-end">
                            <div class="mw-300px" id="invoice-final">
                              <div class="d-flex flex-stack mb-3">
                                <div class="fw-bold pe-10 text-danger-600 fs-7">Due Amount</div>
                                <div class="text-end fw-bolder fs-6 text-danger-800">{userData != null && userData.currencyprefix ? (userData.currencyprefix) : ('')} </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*end::Content*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Invoice 2 content*/}
              </div>
              {/*end::Content*/}
            </div>
            {/*end::Layout*/}
          </div>
          {/*end::Body*/}
        </div>
      </div>
      {pageStatus &&
        <TopBarHeaderLine />
      }
    </>
  )
}

const mapStateToProps = state => ({
  cloudData: state.cloud.cloudDataInfo
})

const mapDispatchToProps = dispatch => ({
  updateCloudStatus: data => dispatch(IsCloudUpdate(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Billing) 