
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import swal from 'sweetalert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import TableLoader from "../../../Loaders/TableLoader";
import TopBarHeaderLine from "../../../../../CustomHooks/TopBarHeaderLine";
import UsePost from "../../../../../CustomHooks/usePost";
import UseApi from "../../../../../CustomHooks/useApi";

export default function Targets(props) {
  var query = window.location.href;
  var vars = query.split("/");
  var id = vars[4];
  const [dataFetched, setDataFetched] = useState(false);
  const [access, setAccess] = useState(true);
  const [perPage, setPerPage] = useState(5);
  const [targetsData, setTargetsData] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const dataFetchedRef = useRef(false);
  const [pageStatus, setPageStatus] = useState(false)
  const [cloudData, setCloudData] = useState(null);
  const [kubernetesData, setKubernetesData] = useState(null);
  const [kubernetesDataInfo, setKubernetesDataInfo] = useState(null);
  const [backendServerType, setBackendServerType] = useState("");
  const [dummyTargetsData, setDummyTargetsData] = useState(null)
  const [kWorkersData, setKWorkersData] = useState([]);
  const [knodepoolData, setKnodepoolData] = useState([]);
  const [lbBackendData, setLbBackendData] = useState(null);
  const [frontendid, setFrontendid] = useState(0);
  const [lbData, setLbData] = useState(null);
  const loaderFunc = () => {
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if (loader && no_record) {
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");

      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
  }

  useEffect(() => {
    if (props && props.trData) {
      setTargetsData(props.trData.targets);
      setDummyTargetsData(props.trData.targets)
    }
  }, [props]);

  function ProductInfo({ productdata }) {
    return (
      <>

        {productdata != null && Object.keys(productdata).length != 0 ? (productdata && productdata.map((value, index) => (
          <tr>
            {/* <td />{" "} */}
            <td className="text-start ps-9">
              {" "}
              <div className="d-flex justify-content-start flex-column">
                <a className="text-dark  fw-bold text-hover-primary fs-6">
                  {value.cloudid}
                </a>
              </div>{" "}
            </td>{" "}
            <td className="text-center">
              {" "}
              <div className="flex-column w-100 me-2">
                {" "}
                <div className="flex-stack mb-2">
                  {" "}
                  <span className=" me-2 fs-6 fw-bold">
                    {value.ip}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
            </td>
            <td className="text-start">
              {" "}
              <div className="flex-column w-100 me-2">
                {" "}
                <div className="flex-stack mb-2">
                  {" "}
                  <span className=" ms-10 fs-6 fw-bold">
                    {value.backend_port}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
            </td>
            <td className="text-start">
              {" "}
              <div className="flex-column w-100 me-2">
                {" "}
                <div className="flex-stack mb-2">
                  {" "}
                  <span className=" ms-10 fs-6 fw-bold">
                    {value.backend_protocol}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
            </td>
            {/* <td></td> */}
            <td className="text-end">
              {" "}
              <div className=" justify-content-center flex-shrink-0">
                {" "}
                <a onClick={(e) => deleteBackend(value.id)} className="btn  btn-danger btn-active-light-danger btn-sm" >
                  Delete
                </a>
              </div>{" "}
            </td>
          </tr>
        ))) : (<>
          <tr>
            {/* <td colspan="12" className="dataTables_empty" id="loader"> */}
            {/* <div className="loading-spinner"></div> */}
            {/* <TableLoader /> */}
            {/* </td> */}
            <td colspan="12" className="" id="no_record" valign="top">
              <div className="d-flex flex-column flex-center">
                <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
              </div>
            </td>
          </tr>
        </>)}

      </>
    )
  }

  function PaginatedItems({ itemsPerPage, ProductData }) {

    const [itemOffset, setItemOffset] = useState(0);
    const [activePage, setActivePage] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    useEffect(() => {
      setPageCount(Math.ceil(ProductData.length / itemsPerPage));
    }, [ProductData.length, itemsPerPage]);

    const endOffset = Math.min(itemOffset + itemsPerPage, ProductData.length);

    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % ProductData.length;
      setItemOffset(newOffset);
      setActivePage(event.selected);
    };

    return (
      <>
        <ProductInfo productdata={ProductData.slice(itemOffset, endOffset)} />
        <td colspan="12">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            initialPage={activePage}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </td>
      </>
    );
  }

  useEffect(() => {
    if (props && props.bData) {
      if (props.bData.backends) {
        setLbBackendData(props.bData.backends);
        setFrontendid(props.bData.id);
      }
      setLbData(props.bData);
    }
  }, [props])

  const addBackendServer = async () => {
    toast.dismiss();
    setPageStatus(true)
    let form = document.getElementById('addbackend')
    let backend_port = document.querySelector('#backend_port')
    let backend_protocol_id = document.querySelector('#backend_protocol_id')
    let ip_field = document.querySelector('#ip_field')
    let cloud_id_field = document.querySelector('#cloud_id_field')
    const dt = toast.loading("Target adding in process, Please wait...");
    console.log(backend_protocol_id.value == "");
    
    if (backend_protocol_id.value != "") {
      if (backend_port != "") {
        const responseData = await UsePost("post", "targetgroup/" + id + "/target", "addbackend");
        if (responseData.status === "error") {
          setPageStatus(false)
          toast.update(dt, {
            render: responseData.message,
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          if (props && props.refreshLb) {
            props.refreshLb();
          }
        } else {
          setPageStatus(false)
          toast.update(dt, {
            render: responseData.message,
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          cloud_id_field.value = ''
          backend_port.value = ''
          backend_protocol_id.value = ''
          ip_field.value = ''
          var kmodal = document.querySelector("#close-server-modal" + frontendid);
          if (kmodal) {
            kmodal.click();
          }
          if (props && props.refreshTarget) {
            props.refreshTarget();
          }
          form.reset();
          // setKubernetesData(null)
          // setCloudData(null)
        }
      }else{
        setTimeout(() =>{
          toast.update(dt, {
            render: "Please enter the port number",
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        },1000)
      }

    }else{
      setTimeout(() =>{
        toast.update(dt, {
          render: "Please select protocol",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },1000)
    }
  };

  const addCustomBackendServer = async () => {
    toast.dismiss();
    setPageStatus(true)
    let backend_port = document.querySelector('#backend_port')
    let backend_protocol_id = document.querySelector('#backend_protocol_id')
    let ip_field = document.querySelector('#ip_field')
    let cloud_id_field = document.querySelector('#cloud_id_field')
    const dt = toast.loading("Target Adding in process, Please wait...");
    const responseData = await UsePost("post", "targetgroup/" + id + "/target", "addcustombackend");
    if (responseData.status === "error") {
      setPageStatus(false)
      toast.update(dt, {
        render: responseData.message,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (props && props.refreshLb) {
        props.refreshLb();
      }
    } else {
      setPageStatus(false)
      toast.update(dt, {
        render: responseData.message,
        type: "success",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      cloud_id_field.value = ''
      backend_port.value = ''
      backend_protocol_id.value = ''
      ip_field.value = ''
      var kmodal = document.querySelector("#close-server-modal" + frontendid);
      if (kmodal) {
        kmodal.click();
      }
      if (props && props.refreshTarget) {
        props.refreshTarget();
      }
    }
  };

  const custom_show_sec = (id) => {
    const customBody = document.querySelector("#customBody");
    const cloudBody = document.querySelector("#cloudBody");
    const customShow = document.querySelector("#custom_show");
    const cloudShow = document.querySelector("#cloud_show");
    const customFooter = document.querySelector("#customFooter");
    const cloudFooter = document.querySelector("#cloudFooter");

    if (id === "custom") {
      customShow.classList.add("d-none");
      cloudShow.classList.remove("d-none");
      customBody.classList.remove("d-none");
      customFooter.classList.remove("d-none");
      cloudBody.classList.add("d-none");
      cloudFooter.classList.add("d-none");
    }
    if (id === "cloud") {
      customShow.classList.remove("d-none");
      cloudShow.classList.add("d-none");
      customBody.classList.add("d-none");
      customFooter.classList.add("d-none");
      cloudBody.classList.remove("d-none");
      cloudFooter.classList.remove("d-none");
    }
  };

  const getCloud = async () => {
    const data = await UseApi("get", "cloud", "");
    if (Object.keys(data).length === 0) {
      setCloudData(null);
    } else {
      setCloudData(data.cloud);
    }
  };

  const getKubernetes = async () => {
    const data = await UseApi('get', 'kubernetes', '');
    // //console.log(user_data.current);
    if (data) {
      if (data.rcode != "success") {
        setKubernetesData(null);
      } else {
        console.log(data);
        if (data.k8s) {
          setKubernetesData(data.k8s);
        }
      }
    }
  }

  const getKubernetesById = async (kid) => {
    const data = await UseApi('get', 'kubernetes/' + kid, '');
    // //console.log(user_data.current);
    if (data) {
      if (data.rcode != "success") {
        setKubernetesDataInfo(null);
      } else {
        if (data) {
          setKubernetesDataInfo(data);
        }
      }
    }
  }

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getCloud();
    getKubernetes();
  }, []);

  const deleteBackend = async (lbid) => {
    toast.dismiss();
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this target!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        setPageStatus(true)
        const dt = toast.loading("Target deleting...");
        const dnsp = await UseApi("DELETE", "targetgroup/" + id + "/target/" + lbid, "nothing");
        //console.log(dnsp);
        if (dnsp.status == "error") {
          setPageStatus(false)
          toast.update(dt, {
            render: dnsp.message,
            type: "warning",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        if (dnsp.status == "success") {
          setPageStatus(true)
          toast.update(dt, {
            render: dnsp.message,
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          if (props && props.refreshTarget) {
            props.refreshTarget();
          }
        }
      }
    });
  };

  const handleTargetCloud = (val) => {
    console.log(val);
    var cloud_id_field = document.querySelector("#cloud_id_field");
    var ip_field = document.querySelector("#ip_field");
    if (cloud_id_field && ip_field) {
      var keyval = val.split(":");
      cloud_id_field.value = keyval[0];
      ip_field.value = keyval[1];
    }
  }

  const handleSearch = (text) => {
    const endOffset = itemOffset + perPage;
    // Remove special characters from the input text
    const escapedText = text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    // Create a regular expression pattern to match the filtered text
    const regex = new RegExp(escapedText, "gi");

    // Filter the clusterData based on the regex pattern
    let data = targetsData.filter((row) => {
      // Check if any property in the row matches the regex pattern
      return (
        (row.name && row.name.toString().toLowerCase().includes(text.toLowerCase()))
        // ||
        // (row.tid && row.tid.toString().toLowerCase().includes(text.toLowerCase()))
      )
    });

    // Update the state with the filtered data
    setDummyTargetsData(data);
    setPageCount(Math.ceil(data.length / perPage));
  }

  const handleChangeServerType = (e) => {
    let ip_field = document.querySelector('#ip_field')
    let cloud_id_field = document.querySelector('#cloud_id_field')
    cloud_id_field.value = ''
    ip_field.value = ''
    setBackendServerType(e.target.value)
  }

  const handleChangeNodePool = (data) => {
    let ip_field = document.querySelector('#ip_field')
    let cloudid = document.querySelector('#cloudid')
    if(data == ""){
      ip_field.value = ""
      // cloudid.value = ""
      setKnodepoolData([])
    }else{
      setKnodepoolData(data)
    }
  }

  // console.log(Object.keys(targetsData),targetsData);
  
  return (
    <>
      {targetsData != null ? (<>
        <div className="col-xl-12">
          {/*begin::Tables Widget 9*/}
          <div className="card card-xl-stretch mb-5 mb-xl-12">
            {/*begin::Header*/}
            <div className="card-header border-0 pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label  fw-bold fs-3 mb-1">
                  Targets
                </span>
              </h3>
              <div
                className="card-toolbar"
              >
                <div className="position-relative my-1" style={{ marginRight: 20 }}>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    data-kt-table-widget-4="search"
                    id="searchClient"
                    // value={searchTerm}
                    onChange={e => handleSearch(e.target.value)}
                    placeholder="Search"
                  />
                </div>
                <div style={{ marginRight: 20 }}>
                  <div className="dataTables_length">
                    <label className="w-125px">
                      <select
                        onChange={e => setPerPage(e.target.value)}
                        //data-control="select2"
                        className="form-select form-select-sm form-select-solid"
                        defaultValue={5}
                        id="perPage"

                      >
                        <option value={5} selected="">
                          5 Records
                        </option>
                        <option value={10}>10 Records</option>
                        <option value={25}>25 Records</option>
                        <option value={50}>50 Records</option>
                        <option value={100}>100 Records</option>
                      </select>
                    </label>
                  </div>
                </div>

                {targetsData.length > 0 ? (<>
                  <a
                    className="btn btn-sm btn-primary btn-active-light-primary"
                    data-bs-toggle="modal"
                    data-bs-target={"#addbackend-modal"}
                  >
                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
                    <span className="svg-icon svg-icon-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.5"
                          x="11.364"
                          y="20.364"
                          width={16}
                          height={2}
                          rx={1}
                          transform="rotate(-90 11.364 20.364)"
                          fill="black"
                        />
                        <rect
                          x="4.36396"
                          y="11.364"
                          width={16}
                          height={2}
                          rx={1}
                          fill="black"
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}
                    Add Target
                  </a>
                </>) : ('')}
              </div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            {/*begin::Table container*/}
            <div className="table-responsive">
              {/*begin::Table*/}
              <table
                id="loadbalancer"
                className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
              >
                {/*begin::Table head*/}
                <thead className="bg-secondary">
                  <tr className=" fw-bold ">
                    {/* <th className="p-3" /> */}
                    <th className="text-start ps-9"> Resource ID</th>
                    <th className="text-center">IP</th>
                    <th className="sphide">Backend Port</th>
                    <th className="sphide">Backend Protocol</th>
                    <th className="text-end pe-15">Action</th>
                    {/* <th className="text-center w-10"></th> */}
                  </tr>
                </thead>
                {/*end::Table head*/}
                {/*begin::Table body*/}
                <tbody>
                  {targetsData != null ? (<>
                    {Object.keys(targetsData).length != 0 ? (<>
                      <PaginatedItems itemsPerPage={perPage} ProductData={dummyTargetsData} />
                    </>) : (<>
                      <tr>
                        <td colSpan={12}>
                          <div className="card-body p-0 mt-10">
                            {/*begin::Illustration*/}
                            <div className="text-center px-4 mt-10">
                              <span className="mw-100 mh-300px">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="234px"
                                  height="234px"
                                  viewBox="0 0 24 24"
                                  fill="#21325B"
                                >
                                  <path
                                    opacity="0.3"
                                    d="M5 8.04999L11.8 11.95V19.85L5 15.85V8.04999Z"
                                    fill="#21325B"
                                  />
                                  <path
                                    d="M20.1 6.65L12.3 2.15C12 1.95 11.6 1.95 11.3 2.15L3.5 6.65C3.2 6.85 3 7.15 3 7.45V16.45C3 16.75 3.2 17.15 3.5 17.25L11.3 21.75C11.5 21.85 11.6 21.85 11.8 21.85C12 21.85 12.1 21.85 12.3 21.75L20.1 17.25C20.4 17.05 20.6 16.75 20.6 16.45V7.45C20.6 7.15 20.4 6.75 20.1 6.65ZM5 15.85V7.95L11.8 4.05L18.6 7.95L11.8 11.95V19.85L5 15.85Z"
                                    fill="#21325B"
                                  />
                                </svg>
                              </span>
                            </div>
                            {/*end::Illustration*/}
                            {/*begin::Wrapper*/}
                            <div className="card-px text-center py-5 mb-20">
                              {/*begin::Title*/}
                              <h1 className="fs-2x fw-bolder mb-5">Target</h1>
                              <h2 className="fs-1x fw-bolder mb-5 text-muted">
                                Add target to your Target group
                              </h2>
                              {/*end::Title*/}
                              {/*begin::Description*/}
                              <p className="text-gray-400 fs-4 fw-bold mb-10">
                                Easily manage your resource using the Utho's Target group
                              </p>
                              {/*end::Description*/}
                              {/*begin::Action*/}
                              <a
                                data-bs-toggle="modal"
                                data-bs-target={"#addbackend-modal"}
                                className="btn btn-primary mb-10"
                              >
                                Add Target
                              </a>
                              {/*end::Action*/}
                            </div>
                            {/*end::Wrapper*/}
                          </div>
                        </td>
                      </tr>
                    </>)}
                  </>) : (<>
                    <tr>
                      <td colspan={12}>
                        <TableLoader />
                        <TopBarHeaderLine />
                      </td>
                    </tr>

                  </>)}

                </tbody>
                {/*end::Table body*/}
              </table>
              {/*end::Table*/}
            </div>
            {/*end::Table container*/}
            {/*begin::Body*/}
          </div>
          {/*end::Tables Widget 9*/}
        </div>
      </>) : (<><TableLoader /></>)}



      <div
        className="modal fade"
        id={"addbackend-modal"}
        tabIndex={-1}
        aria-hidden="true"
      >
        {/*begin::Modal dialog*/}
        <div className="modal-dialog modal-dialog-centered mw-650px">
          {/*begin::Modal content*/}
          <div className="modal-content">
            {/*begin::Modal header*/}
            <div
              className="modal-header ps-18"
              id="kt_modal_create_api_key_header"
            >
              {/*begin::Modal title*/}
              <h2 >Add Target</h2>
              {/*end::Modal title*/}
              {/*begin::Close*/}
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
                id={"close-server-modal" + frontendid}
              >
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                <span className="svg-icon svg-icon-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x={6}
                      y="17.3137"
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(-45 6 17.3137)"
                      fill="black"
                    />
                    <rect
                      x="7.41422"
                      y={6}
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(45 7.41422 6)"
                      fill="black"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
              </div>
              {/*end::Close*/}
            </div>
            {/*end::Modal header*/}
            {/*begin::Form*/}

            {/*begin::Modal body*/}
            <div className="modal-body py-10 px-lg-17">
              {/*begin::Scroll*/}
              <div
                className="scroll-y me-n7 pe-7"
                id="kt_modal_create_api_key_scroll"
                data-kt-scroll="true"
                data-kt-scroll-activate="{default: false, lg: true}"
                data-kt-scroll-max-height="auto"
                data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                data-kt-scroll-offset="300px"
              >
                {/*begin::Notice*/}
                <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-10 p-6">
                  {/*begin::Icon*/}
                  {/*begin::Svg Icon | path: icons/duotune/general/gen044.svg*/}
                  <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.3"
                        x={2}
                        y={2}
                        width={20}
                        height={20}
                        rx={10}
                        fill="black"
                      />
                      <rect
                        x={11}
                        y={14}
                        width={7}
                        height={2}
                        rx={1}
                        transform="rotate(-90 11 14)"
                        fill="black"
                      />
                      <rect
                        x={11}
                        y={17}
                        width={2}
                        height={2}
                        rx={1}
                        transform="rotate(-90 11 17)"
                        fill="black"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                  {/*end::Icon*/}
                  {/*begin::Wrapper*/}
                  <div className="d-flex flex-stack flex-grow-1">
                    {/*begin::Content*/}
                    <div className="fw-bold">
                      <div className="fs-6 text-gray-700">
                        You can add multiple servers in backend of your
                        load balancer.
                      </div>
                    </div>
                    {/*end::Content*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Notice*/}
                <div id="cloudBody">

                  <form id={"addbackend"} className="form">
                    <div className="row">
                      <div className="col-5">
                        {/*begin::Input group*/}
                        <div className="d-flex flex-column mb-10 fv-row">
                          {/*begin::Label*/}
                          <label className="required fs-5 fw-bold mb-2">
                            Server type
                          </label>
                          {/*end::Label*/}
                          {/*begin::Select*/}
                          <select
                            className="form-select form-select-solid"
                            id="serverType"
                            onChange={(e) => handleChangeServerType(e)}
                          >
                            <option value="0">
                              Choose Server Type</option>
                            <option value="cloud"> Cloud Instance </option>
                            <option value="kubernetes"> Kubernetes Cluster </option>
                          </select>
                          {/*end::Select*/}
                        </div>
                      </div>
                      <div className="col-4">
                        {/*begin::Input group*/}
                        <div className="d-flex flex-column mb-10 fv-row">
                          <label htmlFor="type" className="required fs-5 fw-bold mb-2">
                            Protocol
                          </label>
                          <select className="form-select form-select-solid" name="backend_protocol" id="backend_protocol_id">
                            <option value="" disabled selected>Select Backend Protocol</option>
                            <option value="HTTP">HTTP</option>
                            <option value="HTTPS">HTTPS</option>
                            <option value="TCP">TCP</option>
                            <option value="UDP">UDP</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-3">
                        {/*begin::Input group*/}
                        <div className="d-flex flex-column mb-10 fv-row">
                          {/*begin::Label*/}
                          <label className="required fs-5 fw-bold mb-2">
                            Port
                          </label>
                          {/*end::Label*/}
                          <input type="text" className="form-control form-sm form-control-solid" id="backend_port" name="backend_port" defaultValue="" />
                        </div>
                      </div>
                    </div>
                    {backendServerType == "kubernetes" ? (<>
                      <div className="d-flex flex-column mb-10 fv-row">
                        {/*begin::Label*/}
                        <label className="required fs-5 fw-bold mb-2">
                          Choose Kubernetes Cluster
                        </label>
                        {/*end::Label*/}
                        {/*begin::Select*/}
                        <select
                          className="form-select form-select-solid"
                          id="k_id"
                          onChange={(e) => getKubernetesById(e.target.value)}
                        >
                          <option value="" disabled selected>Choose Kubernetes Cluster</option>
                          {kubernetesData &&
                            kubernetesData.map((value, index) => (
                              <option value={value.id}>
                                {value.hostname}
                              </option>
                            ))}
                        </select>
                        {/*end::Select*/}
                      </div>

                      <div className="d-flex flex-column mb-10 fv-row">
                        {/*begin::Label*/}
                        <label className="required fs-5 fw-bold mb-2">
                          Node Pool
                        </label>
                        {/*end::Label*/}
                        {/*begin::Select*/}
                        <select
                          className="form-select form-select-solid"
                          id="k_id"
                          onChange={(e) => handleChangeNodePool(e.target.value)
                          }
                        >
                          <option value="">Choose Node Pool</option>
                          {kubernetesDataInfo && Object.keys(kubernetesDataInfo.nodepools).length != 0 && Object.entries(kubernetesDataInfo.nodepools).map((value, index) => (
                            <>
                              <option value={value[0]}>
                                {value[0]}
                              </option>
                            </>
                          ))}
                        </select>
                        {/*end::Select*/}
                      </div>
                    </>) : ('')}
                    {/*begin::Input group*/}
                    <div className="d-flex flex-column mb-10 fv-row">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-bold mb-2">
                        Choose Backend Server
                      </label>
                      {/*end::Label*/}
                      {/*begin::Select*/}
                      <input type="hidden" name="cloudid" id="cloud_id_field" />
                      <input type="hidden" name="ip" id="ip_field" />
                      <select
                        className="form-select form-select-solid"
                        id="backend_server"
                        onChange={(e) => handleTargetCloud(e.target.value)}
                      >
                        <option value="">Choose backend server</option>
                        {backendServerType ? (<>
                          {backendServerType == "cloud" ? (<>
                            {cloudData != null &&
                              cloudData &&
                              cloudData.map((value, index) => (
                                <option value={value.cloudid + ":" + value.ip}>
                                  {value.hostname + " " + value.ip}
                                </option>
                              ))}
                          </>) : (<>
                            {backendServerType == "kubernetes" ? (<>
                              {kubernetesDataInfo && Object.keys(kubernetesDataInfo.nodepools).length != 0 && Object.entries(kubernetesDataInfo.nodepools).map((value, index) => (
                                <>
                                  {value[0] == knodepoolData ? (<>
                                    {value[1].workers.length > 0 && (value[1].workers && value[1].workers.map((value2, index2) => (<>
                                      {value2.status == 'Installed' ? (<>
                                        <option value={value2.cloudid + ":" + value2.ip}>
                                          {value2.hostname + " " + value2.ip}
                                        </option>
                                      </>) : ('')}
                                    </>)))}
                                  </>) : ('')}
                                </>
                              ))}
                            </>) : ('')}
                          </>)}

                        </>) : ('')}

                      </select>
                      {/*end::Select*/}
                    </div>
                  </form>
                </div>


                <div id="customBody" className="d-none">
                  <form id={"addcustombackend"} className="form">
                    <div className="row">
                      <div className="col-5">
                        <div className="d-flex flex-column mb-10 fv-row">
                          {/*begin::Label*/}
                          <input
                            type="hidden"
                            className="form-control"
                            name="cloudid"
                            defaultValue={0}
                          />
                          <label className="required fs-5 fw-bold mb-2">
                            Custom IP
                          </label>
                          {/*end::Label*/}
                          <input
                            className="form-control "
                            defaultValue=""
                            name="ip"
                            placeholder="Enter your custom IP"
                          />
                        </div>
                      </div>
                      <div className="col-4">
                        {/*begin::Input group*/}
                        <div className="d-flex flex-column mb-10 fv-row">
                          <label htmlFor="type" className="required fs-5 fw-bold mb-2">
                            Protocol
                          </label>
                          <select className="form-select form-select-solid" name="backend_protocol">
                            <option value="HTTP">HTTP</option>
                            <option value="HTTPS">HTTPS</option>
                            <option value="TCP">TCP</option>
                            <option value="UDP">UDP</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-3">
                        {/*begin::Input group*/}
                        <div className="d-flex flex-column mb-10 fv-row">
                          {/*begin::Label*/}
                          <label className="required fs-5 fw-bold mb-2">
                            Port
                          </label>
                          {/*end::Label*/}
                          <input type="text" className="form-control form-sm form-control-solid" name="backend_port" defaultValue="" />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                {/*end::Input group*/}
                <div className="separator separator-content my-15">
                  or
                </div>
                {/*begin::Input group*/}
                <div className="text-center">
                  <a
                    className=" btn btn-sm btn-secondary"
                    onClick={() => custom_show_sec("custom")}
                    id="custom_show"
                  >
                    Custom IP
                  </a>
                  <a
                    className=" btn btn-sm btn-secondary d-none"
                    onClick={() => custom_show_sec("cloud")}
                    id="cloud_show"
                  >
                    Cloud servers
                  </a>
                </div>
                {/*end::Input group*/}
              </div>
              {/*end::Scroll*/}
            </div>
            {/*end::Modal body*/}
            {/*begin::Modal footer*/}
            <div className="modal-footer flex-center ps-15 pe-18" id="cloudFooter">
              {/*begin::Button*/}
              <button
                id="addserver"
                type="button"
                onClick={addBackendServer}
                className="btn btn-primary w-100"
              >
                Add Server
              </button>
              {/*end::Button*/}
            </div>
            <div
              className="modal-footer flex-center d-none"
              id="customFooter"
            >
              {/*begin::Button*/}
              <button
                id="addcustomserver"
                type="button"
                onClick={addCustomBackendServer}
                className="btn btn-primary w-100"
              >
                Add Custom Server
              </button>
              {/*end::Button*/}
            </div>
            {/*end::Modal footer*/}

            {/*end::Form*/}
          </div>
          {/*end::Modal content*/}
        </div>
        {/*end::Modal dialog*/}
      </div>
    </>
  )
} 