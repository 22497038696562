import React from "react";
import { useNavigate } from "react-router-dom/dist";
import UsePost from "../../../../CustomHooks/usePost";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from "react";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";

export default function FirewallDeploy(){
  const [pageStatus,setPageStatus] = React.useState(false)
  let navigate=useNavigate();
  const deployFirewall=async()=>{ 
    toast.dismiss();
    var submitBtn=document.querySelector("#firewall-btn");
    var firewallName=document.querySelector("#name");
   
    if(submitBtn){
      setPageStatus(true)
      var preContent=submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>'; 
    const dt=toast.loading("Please wait...");
    if(firewallName){
      if(firewallName.value==""){
        submitBtn.classList.remove("disabled");
      submitBtn.innerHTML=preContent;
        toast.update(dt,{render: "Firewall name must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
        return;
      }
    }
    const responseData=await UsePost('post','firewall/create','createfirewall');
    if(responseData){
      setPageStatus(false)
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML=preContent;
      if(responseData.status==='error'){
        toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      }else if(responseData.status==='success'){
        toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });  
      window.onDeployEvents('deploy_cloud_firewall');
      navigate("/firewall");
    }
  }
      }
    }
    useEffect(() => {
      window.addEventListener("keydown", function(event) {
        event.stopPropagation();
        var query = window.location.href; 
        var vars = query.split("/"); 
        var action=vars[4]; 
        //console.log(vars);
        if(event.key=="Enter" && action=="deploy" && vars[3]=="firewall"){
          deployFirewall();
      }
    });
    }, []);
    return(
        <>
        {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        /> */}
        <div className="col-xl-12">
  {/*begin::Tables Widget 9*/}
  <div className="card card-xl-stretch mb-5 mb-xl-12">
    {/*begin::Header*/}
    <div className="card-header border-2 pt-5">
      <h3 className="card-title align-items-start flex-column">
        <span className="card-label fw-bold fs-3 mb-1">Firewall </span>
        <span className="text-muted mt-1 fw-bold fs-7">
          Deploy your Firewalls
        </span>
      </h3>
    </div>
    {/* <div class="separator border-2 my-10"></div> */}
    {/*end::Header*/}
    {/*begin::Body*/}
    <div className="card-body py-3">
      {/*begin::Modal header*/}
      <div className="modal-header d-none" id="kt_modal_create_api_key_header">
        {/*begin::Modal title*/}
        <h2>Create New Firewall</h2>
        {/*end::Modal title*/}
      </div>
      {/*end::Modal header*/}
      {/*begin::Form*/}
      <form id="createfirewall" className="form">
        {/*begin::Modal body*/}
        {/*begin::Scroll*/}
        {/*begin::Input group*/}
        <div className="modal-body row" style={{padding:'3rem 0rem 3rem 0rem'}}>
          <div className="col-md-9 col-lg-9 col-xxl-9">

          {/*begin::Label*/}
          <label className="required fs-5 fw-bold mb-2">
            {" "}
            New Firewall Name
          </label>
          {/*end::Label*/}
          {/*begin::Input*/}
          <input
            type="text"
            className="form-control"
            placeholder="My_web_firewall"
            name="name"
            id="name"
          />
          </div>
          {/*end::Input*/}
          {/*end::Input group*/}

        {/*end::Scroll*/}
        {/*begin::Modal footer*/}
        {/*begin::Button*/}
        <div className="col-md-3 col-lg-3 col-xxl-3 mt-9">
        <button
          type="button"
          style={{ width: "100%" }}
          onClick={deployFirewall}
          className="btn btn-primary"
          id="firewall-btn"
        >
          <span className="indicator-label">Deploy Firewall</span>
        </button>
        </div>
        </div>
        {/*end::Button*/}
        {/*end::Modal footer*/}
      </form>
      {/*end::Form*/}
      {pageStatus&&
      <TopBarHeaderLine/>}
    </div>
    {/*begin::Body*/}
  </div>
  {/*end::Tables Widget 9*/}
</div>

        </>
    )
}