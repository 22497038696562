import React from 'react';

const Finish = () => {
    return (
        <div>
            
        </div>
    );
}

export default Finish;
