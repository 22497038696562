import { toast } from "react-toastify";
import PermissionInfoModal from "../../permissioInfoModal/PermissionInfoModal";
import { useState } from "react";
import UsePost from "../../../../../../../CustomHooks/usePost";

export default function UpdateRabbitMqUsers(props) {
    const [roleName, setRoleName] = useState(null)

    var query = window.location.href;
    var vars = query.split("/");
    var id = vars[4];

    const openModal = () => {
        let addbackend_modal = document.querySelector('#permissionInfo-modal')
        if (addbackend_modal) {
            addbackend_modal.classList.toggle('show')
            addbackend_modal.classList.toggle('d-block')
        }
    }

    const updateRabbitMqUser = async (event) => {
        toast.dismiss()
        event.preventDefault()
        var submitBtn = document.querySelector("#update-databaseUser-btn");
        // var databases_user = document.querySelector('#databasesuser')
        if (submitBtn) {
            // setPageStatus(true)
            var preContent = submitBtn.innerHTML;
            submitBtn.classList.add("disabled");
            submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
            const dt = toast.loading("Please wait...");

            const responseData = await UsePost('put', 'databases/' + id + '/user', 'rabbitmqUser-form');
            if (responseData) {
                // setPageStatus(false)
                submitBtn.classList.remove("disabled");
                submitBtn.innerHTML = preContent;
                if (responseData.status === 'error') {
                    toast.update(dt, {
                        render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
                    });
                } else if (responseData.status === 'success') {
                    toast.update(dt, {
                        render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
                    });
                    // databases_user.value = ''
                    if (props) {
                        // openDrawer();
                        props.openUpdateDrawer();
                    }

                }
            }
        }
    }

    return (
        <>
            <div id="drawer_overlay-rabbitmqUpdate" onClick={props.openUpdateDrawer} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>
            <div
                id="rabbitmqUpdate-modal"
                className="bg-body shadow drawer drawer-end"
                data-kt-drawer="true"
                data-kt-drawer-name="chat"
                data-kt-drawer-activate="true"
                data-kt-drawer-direction="end"
                data-kt-drawer-close="#close_ssh"
                data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
                style={{ maxWidth: '70%', important: true }}

            >
                {/*begin::Messenger*/}
                <div className="card rounded-0 w-100 border-0" id="kt_drawer_chat_messenger">
                    {/*begin::Card header*/}
                    <div className="card-header pe-5" id="kt_drawer_chat_messenger_header">
                        {/*begin::Title*/}
                        <div className="card-title">
                            {/*begin::User*/}
                            <div className="d-flex justify-content-center flex-column me-3">

                                <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                                    Update New RabbitMq User
                                </a>
                            </div>
                            {/*end::User*/}
                        </div>
                        {/*end::Title*/}
                        {/*begin::Card toolbar*/}
                        <div className="card-toolbar">
                            {/*begin::Close*/}
                            <div
                                onClick={props.openUpdateDrawer}
                                className="btn btn-sm btn-icon btn-active-light-primary"
                                id="close_ssh"
                            >
                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                                <span className="svg-icon svg-icon-2">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <rect
                                            opacity="0.5"
                                            x={6}
                                            y="17.3137"
                                            width={16}
                                            height={2}
                                            rx={1}
                                            transform="rotate(-45 6 17.3137)"
                                            fill="black"
                                        />
                                        <rect
                                            x="7.41422"
                                            y={6}
                                            width={16}
                                            height={2}
                                            rx={1}
                                            transform="rotate(45 7.41422 6)"
                                            fill="black"
                                        />
                                    </svg>
                                </span>
                                {/*end::Svg Icon*/}
                            </div>
                            {/*end::Close*/}
                        </div>
                        {/*end::Card toolbar*/}
                    </div>
                    {/*end::Card header*/}
                    {/*begin::Card body*/}
                    <div className="" id="">
                        {/*begin::Form*/}
                        <form id="rabbitmqUser-form" className="form">
                            {/*begin::Modal body*/}
                            <div className="modal-body ">
                                {/*begin::Scroll*/}
                                <div
                                    className="scroll-y me-n7 pe-7"
                                    id="kt_modal_create_api_key_scroll"
                                    data-kt-scroll="true"
                                    data-kt-scroll-activate="{default: false, lg: true}"
                                    data-kt-scroll-max-height="auto"
                                    data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                                    data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                                    data-kt-scroll-offset="300px"
                                >
                                    {/*begin::Input group*/}
                                    {/* {databaseInfo?.engine?.toLowerCase() == "kafka" ? */}
                                    <input
                                        type="hidden"
                                        className="form-control"
                                        id="rabbitmquser"
                                        name="user"
                                        value={props?.updateObject?.db_user}
                                    />
                                    <input
                                        type="hidden"
                                        className="form-control"
                                        id="clusterid"
                                        name="clusterid"
                                        value={id}
                                    />
                                    <div className="mb-5 fv-row d-none">
                                        {/*begin::Label*/}
                                        <label className="required fs-5 fw-bold mb-2">Name</label>
                                        {/*end::Label*/}
                                        {/*begin::Input*/}
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="databasesuser"
                                            name="name"
                                            placeholder="Name"
                                        // onChange={handleInputChange}
                                        // onKeyPress={handleKeyPress}
                                        />


                                    </div>


                                    <div className="mb-5 fv-row d-none">
                                        {/*begin::Label*/}
                                        <label className="required fs-5 fw-bold mb-2"> Password</label>
                                        {/*end::Label*/}
                                        {/*begin::Input*/}
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="databasesuser"
                                            name="name"
                                            placeholder="Password"
                                        // onChange={handleInputChange}
                                        // onKeyPress={handleKeyPress}
                                        />
                                        {/*end::Input*/}
                                    </div>

                                    <div className="mb-5 fv-row">
                                        {/*begin::Label*/}
                                        <label className="required fs-5 fw-bold mb-2"> Tags</label>
                                        {/*end::Label*/}
                                        {/*begin::Input*/}
                                        <select className="form-select" name="permission" id="tags"
                                            onChange={(e) => {
                                                openModal()
                                                setRoleName(e.target.value)
                                            }}
                                            value={roleName != null ? roleName : props?.updateObject?.role}
                                        >
                                            <option className="administrator">Admin</option>
                                            <option className="monitoring">Monitoring</option>
                                            <option className="policymaker">Policymaker</option>
                                            <option className="management">Management</option>
                                            <option className="impersonator">Impersonator</option>
                                            <option className="none">None</option>
                                        </select>
                                        {/*end::Input*/}
                                    </div>



                                    <div className="mb-5 fv-row d-none">
                                        {/*begin::Label*/}
                                        <label className="required fs-5 fw-bold mb-2">Can Access virtual Host</label>
                                        {/*end::Label*/}
                                        {/*begin::Input*/}
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="databasesuser"
                                            name="name"
                                            placeholder="Can Access virtual Host"
                                        // onChange={handleInputChange}
                                        // onKeyPress={handleKeyPress}
                                        />
                                        {/*end::Input*/}
                                    </div>
                                    {/*end::Input group*/}
                                </div>
                                {/*end::Scroll*/}
                            </div>
                            {/*end::Modal body*/}
                            {/*begin::Modal footer*/}
                            <div className="modal-footer">
                                {/*begin::Button*/}
                                <button
                                    onClick={updateRabbitMqUser}
                                    className="btn btn-primary w-100"
                                    id="update-databaseUser-btn"
                                >

                                    <span className="indicator-label">  Update RabbitMq User</span>

                                </button>
                                {/*end::Button*/}
                            </div>
                            {/*end::Modal footer*/}
                        </form>
                        {/*end::Form*/}
                    </div>
                    {/*end::Card body*/}
                </div>
                {/*end::Messenger*/}
            </div>
            <PermissionInfoModal openModal={openModal} />
        </>
    )
}