import React, { useState } from "react";
import { Link } from "react-router-dom";
import UseLogin from "../../../../App/CustomHooks/useLogin";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from "react";
import UseApi from "../../../../App/CustomHooks/useApi";
import CallAppinfo from "../../../../App/CustomHooks/CallAppinfo";
import axios from "axios";
import localStorage from "local-storage";
import Cookies from "js-cookie";
import "./custom.css";
import CompleteProfile from "../../../../App/MicroApp/Body/Products/setting/CompleteProfile";
import ApplyCoupon from "../../../../App/Auth/ApplyCoupon";
import TableLoader from "../../../../App/MicroApp/Body/Loaders/TableLoader";
import TopBarHeaderLine from "../../../../App/CustomHooks/TopBarHeaderLine";
export default function UpdateUserProfile() {
  var current_url_string = window.location.href;
  const current_url = new URL(current_url_string);
  const current_domain = current_url.hostname;
  const [app_info, setApp_info] = useState(null);
  const [logo_white, setLogo_white] = useState(null);
  async function currApp() {
    var baseUrl="https://api.utho.com";
    var baseEndPoint="api";
  const platformName=localStorage.get("platformType");
  if(platformName){
    if(platformName=="dev"){
     baseUrl= "https://dev.api.cloudplatformapp.com";
     baseEndPoint="dev.api";
    }
  } 
    const AppData = await axios.get(baseUrl+'/v2/appinfo');
    if (AppData && AppData.data) {
      //console.log("data come");
      setApp_info(AppData.data);
      //console.log(AppData);
      var siteUrlString = AppData.data.site;

      var siteUrlArr = siteUrlString.split(".");
      var siteLength = siteUrlArr.length;

      var siteOrg = "https://"+baseEndPoint+"." + siteUrlArr[siteLength - 2] + "." + siteUrlArr[siteLength - 1];
      localStorage.set("filesUrl", siteOrg);
      var whiteLogo = siteOrg + "/publicfiles/" + AppData.data.logo_light;
      setLogo_white(whiteLogo);
    }
  }
  useEffect(() => {
    currApp();
  }, []);

  const [userInfo, setUserInfo] = useState(null);

  async function CallAccountInfo() {
    const account_info = await UseApi('get', 'account/info', '');
    if (account_info) {
      if (account_info.user) {
        setUserInfo(account_info.user);
      }
    }
  }

  useEffect(() => {
    CallAccountInfo();
  }, [])

  if (userInfo != null) {
    return (
      <>
        <ApplyCoupon />
        <div className="page d-flex flex-row flex-column-fluid loginbg">
          <div
            className="d-flex flex-column flex-lg-row-auto w-xl-600px positon-xl-relative d-none d-lg-block d-md-block d-xxl-block"
          >
            {/*begin::Wrapper*/}
            <div className="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y">

            </div>
          </div>

          <div className="d-flex flex-column flex-lg-row-fluid py-10 top-front">
            {/*begin::Logo*/}
            <a href="/" className="text-center ">
              {logo_white != null ? (<>
                <img
                  alt="Logo"
                  src={logo_white}
                  className="h-60px logo-fade-in"
                />
              </>) : (<>
                <img
                  alt="Logo"
                  src="./assets/media/logos/loader-black.png"
                  className="h-60px logo-fade-in"
                />
              </>)}
            </a>
            {/*end::Logo*/}
            {/*begin::Content*/}
            <div className="d-flex flex-center flex-column flex-column-fluid">
              {/*begin::Wrapper*/}
              <div className="w-lg-600px p-10 p-lg-15 mx-auto">
                {/*begin::Form*/}
                <form
                  className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
                  noValidate="novalidate"
                  id="signup"
                >
                  {/*begin::Heading*/}
                  <div className="mb-10 text-center">
                    {/*begin::Title*/}
                    <h1 className="text-light mb-3">Update Profile</h1>
                    {/*end::Title*/}
                  </div>
                  {/*end::Heading*/}
                  <CompleteProfile userData={userInfo} />

                  <div />
                </form>
                {/*end::Form*/}
              </div>
              {/*end::Wrapper*/}
            </div>
            {/*end::Content*/}
          </div>
        </div>
      </>
    );
  } else {
    return (<>
      <TableLoader />
      <TopBarHeaderLine />
    </>);
  }
}
