import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UsePost from "../../../../../../CustomHooks/usePost";
import UseApi from "../../../../../../CustomHooks/useApi";

export default function AttachSubnet(props) {
  var query = window.location.href;
  var vars = query.split("/");
  var id = vars[4];
  const openDrawer = () => {
    var drawerElement = document.querySelector("#create-vpc-subnet-modal");
    var drawer_overlay = document.querySelector("#drawer_overlay_vpc_subnet");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      if (window.innerWidth <= 576) {
        // Adjust this value based on your breakpoint
        drawerElement.style.width = "100%";
      } else {
        drawerElement.style.width = "70%";
      }
      drawer_overlay.classList.toggle("d-none");
    }
  };
  const [vpcData, setVpcData] = useState(null);

  const getVPC = async () => {
    const datas = await UseApi('get', `vpc/${id}`, '')
    if (datas) {
      var network_input = document.querySelector("#network_input");
      var network_size = document.querySelector("#network_size");
      var vpc_id = document.querySelector("#vpc_id")
      if (network_input && network_size && vpc_id) {
        network_input.value = datas.network;
        network_size.value = datas.size;
        vpc_id.value = datas.id
      }
      setVpcData(datas?.vpc);
    }
  }

  const getVpcDetails = () => {
    // var vpc_id = document.querySelector("#vpc_id");
    // if (vpcData && vpc_id) {
    //   vpcData.map((value, index) => {
    //     if (value.id == vpc_id.value) {
    //       var network_input = document.querySelector("#network_input");
    //       var network_size = document.querySelector("#network_size");
    //       if (network_input && network_size) {
    //         network_input.value = val.network;
    //         network_size.value = datas.size;
    //       }
    //     }
    //   })
    // }
  }
  useEffect(() => {
    getVPC();
  }, [])

  console.log(props);
  
  const addVpcSubnet = async () => {
    toast.dismiss();
    const dt = toast.loading(
      "Please wait... System is processing your request."
    );
    const submitBtn = document.querySelector("#add-vpc-subnet");
    if (submitBtn) {
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML =
        'Please wait... <span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      const responseData = await UsePost(
        "post",
        "vpc/subnet/create",
        "create-subnet-form"
      );

      if (responseData.status === "error") {
        toast.update(dt, {
          render: responseData.message,
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (responseData.status === "success") {
        toast.update(dt, {
          render: responseData.message,
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (props && props.fetchData) {
          props.fetchData();
        }
        openDrawer()
      }
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML = preContent;
    }
  };
  return (
    <>
      <a className="btn btn-sm btn-primary me-2" onClick={openDrawer}>
        {/*begin::Svg Icon | path: assets/media/icons/duotune/arrows/arr009.svg*/}
        <span className="svg-icon svg-icon-muted svg-icon-1hx">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              opacity="0.3"
              d="M21 13H3C2.4 13 2 12.6 2 12C2 11.4 2.4 11 3 11H21C21.6 11 22 11.4 22 12C22 12.6 21.6 13 21 13Z"
              fill="black"
            />
            <path
              d="M12 22C11.4 22 11 21.6 11 21V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V21C13 21.6 12.6 22 12 22Z"
              fill="black"
            />
          </svg>
        </span>
        {/*end::Svg Icon*/}
        Create Subnet
      </a>
      <div
        id="drawer_overlay_vpc_subnet"
        onClick={openDrawer}
        className="drawer-overlay d-none"
        style={{ zIndex: 109, width: "100%" }}
      ></div>
      <div
        id="create-vpc-subnet-modal"
        className="bg-body shadow drawer drawer-end"
        data-kt-drawer="true"
        data-kt-drawer-name="chat"
        data-kt-drawer-activate="true"
        data-kt-drawer-direction="end"
        // data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
        style={{ important: true, zIndex: 110 }}
      >
        {/*begin::Messenger*/}
        <div
          className="card w-100 rounded-0 border-0"
          id="kt_drawer_chat_messenger"
          style={{ height: 'max-content' }}
        >
          {/*begin::Card header*/}
          <div
            className="card-header pe-5"
            id="kt_drawer_chat_messenger_header"
          >
            {/*begin::Title*/}
            <div className="card-title">
              {/*begin::User*/}
              <div className="d-flex justify-content-center flex-column me-3">
                <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                  Create Subnet
                </a>
              </div>
              {/*end::User*/}
            </div>
            {/*end::Title*/}
            {/*begin::Card toolbar*/}
            <div className="card-toolbar">
              {/*begin::Close*/}
              <div
                onClick={openDrawer}
                className="btn btn-sm btn-icon btn-active-light-primary"
                id="close_api"
              >
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                <span className="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x={6}
                      y="17.3137"
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(-45 6 17.3137)"
                      fill="black"
                    />
                    <rect
                      x="7.41422"
                      y={6}
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(45 7.41422 6)"
                      fill="black"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
              </div>
              {/*end::Close*/}
            </div>
            {/*end::Card toolbar*/}
          </div>
          {/*end::Card header*/}
          {/*begin::Card body*/}
          <div className="" id="">
            {/*begin::Form*/}
            <form id="create-subnet-form" className="form">
              {/*begin::Modal body*/}
              <div className="modal-body ">
                {/*begin::Scroll*/}
                <div className=" me-n7 pe-7">
                  <div className="pt-0 row">
                    <div className="col-lg-12">
                      {/*begin::Input group*/}
                      <div className="mb-10 fv-row fv-plugins-icon-container">
                        {/*begin::Label*/}
                        <label className="required form-label">
                          Subnet Name
                        </label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <input
                          type="text"
                          name="name"
                          className="form-control mb-2"
                          placeholder="Enter the name"
                        />
                        {/*end::Input*/}
                        {/*begin::Description*/}
                        <div className="text-muted fs-7">
                          A subnet name is required and recommended to be
                          unique.
                        </div>
                        {/*end::Description*/}
                        <div className="fv-plugins-message-container invalid-feedback" />
                      </div>
                      {/*end::Input group*/}
                    </div>
                    <input
                    type="hidden"
                    name="vpcid"
                    id="vpc_id"
                    />
                    <div className="col-lg-12 d-none">
                      {/*begin::Input group*/}
                      <div className="mb-10 fv-row fv-plugins-icon-container">
                        {/*begin::Label*/}
                        <label className="required form-label">
                          Choose VPC
                        </label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <select
                          // name="vpcid"
                          // id="vpc_id"
                          className="form-select mb-2"
                          onSelect={getVpcDetails}
                          onChange={getVpcDetails}
                          onClick={getVpcDetails}
                        >
                          <option value="" disabled selected>Select VPC from List</option>
                          {vpcData != null ? (<>
                            {vpcData.map((value, index) => (<>
                              <option value={value.id}>vpc-{value.id} ({value.name})</option>
                            </>))}
                          </>) : ('')}
                        </select>
                        {/*end::Input*/}
                        {/*begin::Description*/}
                        <div className="text-muted fs-7">
                          A VPC Id which will be attached with this subnet
                        </div>
                        {/*end::Description*/}
                        <div className="fv-plugins-message-container invalid-feedback" />
                      </div>
                      {/*end::Input group*/}
                    </div>
                    <div className="col-lg-6">
                      {/*begin::Input group*/}
                      <div className="mb-10 fv-row fv-plugins-icon-container">
                        {/*begin::Label*/}
                        <label className="required form-label">
                          Auto Assign Public IPv4 Address
                        </label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <select
                          name="assign_public"
                          // defaultValue="monthly"
                          className="form-select mb-2"
                        >
                          <option value="" disabled selected>Select Public IP Assignment</option>
                          <option value="enable">Enable</option>
                          <option value="disable">Disable</option>
                        </select>
                        {/*end::Input*/}
                        {/*begin::Description*/}
                        <div className="text-muted fs-7">
                          Select whether to assign a public IPv4 address automatically.
                        </div>
                        {/*end::Description*/}
                        <div className="fv-plugins-message-container invalid-feedback" />
                      </div>
                      {/*end::Input group*/}
                    </div>
                    <div className="col-lg-6">
                      {/*begin::Input group*/}
                      <div className="mb-10 fv-row fv-plugins-icon-container">
                        {/*begin::Label*/}
                        <label className="required form-label">
                          Default Subnet
                        </label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <select
                          name="is_default"
                          // defaultValue="monthly"
                          className="form-select mb-2"
                        >
                          <option value="" disabled selected>Select Default Subnet Option</option>
                          <option value="0">Yes</option>
                          <option value="1">No</option>
                        </select>
                        {/*end::Input*/}
                        {/*begin::Description*/}
                        <div className="text-muted fs-7">
                          Specify whether this is the default subnet for your VPC configuration.
                        </div>
                        {/*end::Description*/}
                        <div className="fv-plugins-message-container invalid-feedback" />
                      </div>
                      {/*end::Input group*/}
                    </div>
                    <div className="col-lg-4">
                      {/*begin::Input group*/}
                      <div className="mb-10 fv-row fv-plugins-icon-container">
                        {/*begin::Label*/}
                        <label className="required form-label">
                          Subnet Type
                        </label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <select
                          name="type"
                          defaultValue="monthly"
                          className="form-select mb-2"
                        >
                          <option value="" disabled selected>Select Subnet Type</option>
                          <option value="public">Public</option>
                          <option value="private">Private</option>
                        </select>
                        {/*end::Input*/}
                        {/*begin::Description*/}
                        <div className="text-muted fs-7">
                          A Subnet type can be public or private.
                        </div>
                        {/*end::Description*/}
                        <div className="fv-plugins-message-container invalid-feedback" />
                      </div>
                      {/*end::Input group*/}
                    </div>
                    <div className="col-lg-4">
                      {/*begin::Input group*/}
                      <div className="mb-10 fv-row fv-plugins-icon-container">
                        {/*begin::Label*/}
                        <label className="required form-label">Network</label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <input
                          className="form-control"
                          id="network_input"
                          name="network"
                          inputMode="text"
                          placeholder="___.___.___.___"
                        />
                        {/*end::Input*/}
                        {/*begin::Description*/}
                        <div className="text-muted fs-7">
                          A network is the IP of the VPC.
                        </div>
                        {/*end::Description*/}
                        <div className="fv-plugins-message-container invalid-feedback" />
                      </div>
                      {/*end::Input group*/}
                    </div>
                    <div className="col-lg-4">
                      {/*begin::Input group*/}
                      <div className="mb-10 fv-row fv-plugins-icon-container">
                        {/*begin::Label*/}
                        <label className="required form-label">IP Range</label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <input
                          type="text"
                          name="size"
                          id="network_size"
                          className="form-control mb-2"
                          defaultValue=""
                        />
                        {/*end::Input*/}
                        {/*begin::Description*/}
                        <div className="text-muted fs-7">
                          This define the vpc ip-pool range.
                        </div>
                        {/*end::Description*/}
                        <div className="fv-plugins-message-container invalid-feedback" />
                      </div>
                      {/*end::Input group*/}
                    </div>
                    <div className="col-lg-12">
                      <a
                        className="btn btn-primary mt-5 w-100"
                        onClick={addVpcSubnet}
                        id="add-vpc-subnet"
                      >
                        <span className="svg-icon svg-icon-muted svg-icon-1hx">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              opacity="0.3"
                              d="M21 13H3C2.4 13 2 12.6 2 12C2 11.4 2.4 11 3 11H21C21.6 11 22 11.4 22 12C22 12.6 21.6 13 21 13Z"
                              fill="black"
                            />
                            <path
                              d="M12 22C11.4 22 11 21.6 11 21V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V21C13 21.6 12.6 22 12 22Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        Create Order
                      </a>
                    </div>
                  </div>
                </div>
                {/*end::Scroll*/}
              </div>
              {/*end::Modal body*/}
            </form>
            {/*end::Form*/}
          </div>
          {/*end::Card body*/}
        </div>

        {/*end::Messenger*/}
      </div>
    </>
  );
}
