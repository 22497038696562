import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getPlatformType } from "./AppConfig";
import { getCookie } from "../services/Cookies";
import { setLocalStorage } from "../services/LocalStorage";
import { LogoutUser } from "./User";
import localStorage from "local-storage";

export async function IsUserLogin() {
  var lsAuth = getCookie("microhost_api_auth");
  const userInfo = {};
  var platformInfo = getPlatformType();
   console.log(platformInfo);
  const apiUrl = platformInfo?.baseUrl + "/v2/account/info";
  const bearerToken = lsAuth;

  const headers = {
    Authorization: "Bearer " + bearerToken,
    "Content-Type": "application/json",
  };

  const fetchOptions = {
    method: "GET",
    headers: headers,
  };

  try {
    const response = await fetch(apiUrl, fetchOptions);

    if (!response.ok) {
      if (response.status === 403) {
        return { status: "error", message: "Access denied. Please check your credentials." };
      } else if (response.status === 404) {
        return { status: "error", message: "API endpoint not found." };
      } else if (response.status === 500) {
        return { status: "error", message: "Internal server error. Please try again later." };
      } else {
        return { status: "error", message: `Unexpected error: ${response.statusText}` };
      }
    }

    const data = await response.json();

    if (data.status === "error") {
      return { status: "error", message: data.message };
    } else if (data.user) {
      localStorage.set("user_info", data.user);
      return { status: "success", user: data.user };
    } else if (data.auth === "error") {
      return { status: "error", message: data.error };
    } else {
      return { status: "error", message: "Unable to fetch your account details, Please contact support" };
    }
  } catch (error) {
    return { status: "error", message: "Session has expired!" };
  }
}
