import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UsePostData from "../../../../CustomHooks/usePostData";
import InstanceTypeCloud from "./deploy/InstanceTypeCloud";
import InstanceTypeKubernetes from "./deploy/InstanceTypeKubernetes";

export default function GpuDeploy() {
    const [instanceName, setInstanceName] = useState("Cloud Server")
    const [cloudData, setCloudData] = useState([]);
    const [kubernetesData, setKubernetesData] = useState([]);
    const [kubernetesDataInfo, setKubernetesDataInfo] = useState(null)
    const [cloudId, setCloudId] = useState('')
    const [dataToShow, setDataToShow] = useState([])
    const [gpu, setGpu] = useState([])
    let navigate = useNavigate()
    // const [gpu, setGpu] = useState([{
    //     id: 0,
    //     make: "nvidia",
    //     model: "A100",
    //     cost: 1000
    // }, {
    //     id: 1,
    //     make: "nvidia",
    //     model: "A400",
    //     cost: 1500
    // }
    // ])


    const getCloud = async () => {
        const data = await UseApi("get", "cloud", "");
        if (Object.keys(data).length === 0) {
            setCloudData([]);
        } else {
            setCloudData(data.cloud);
        }
    };

    const getKubernetes = async () => {
        const data = await UseApi('get', 'kubernetes', '');
        if (data) {
            if (data.rcode != "success") {
                setKubernetesData([]);
            } else {
                if (data.k8s) {
                    setKubernetesData(data.k8s);
                }
            }
        }
    }

    const getKubernetesById = async (kid) => {
        const data = await UseApi('get', 'kubernetes/' + kid, '');
        if (data) {
            if (data.rcode != "success") {
                setKubernetesDataInfo(null);
            } else {
                if (data) {
                    setKubernetesDataInfo(data);
                }
            }
        }
    }

    const handleGetGpu = async (id) => {
        let responseData = await UseApi('get', `gpu/${id}`, '')
        if (responseData.status === "success") {
            setCloudId(id)
            setGpu((preGpu) => [...preGpu, ...responseData.gpu])
        } else {
            setGpu([])
        }
    }

    useEffect(() => {
        getCloud();
        getKubernetes();
    }, []);

    const handleAttachGpu = () => {
        const order_total = document.querySelector("#total_cost");
        const selectElement = document.getElementById('selected_gpu'); // Replace 'yourSelectId' with the actual ID of your select element
        const selectedValue = selectElement.value;
        const filterGpuArray = gpu.filter(item => item.id != selectedValue);
        const selectedGpuArray = gpu.filter(item => item.id == selectedValue);
        setGpu((preGpu) => [...filterGpuArray])
        setDataToShow((preDataToShow) => [...preDataToShow, ...selectedGpuArray])
        const orderTotal = order_total.textContent;
        let totalCost = parseInt(orderTotal) + parseInt(selectedGpuArray[0]?.cost)
        order_total.innerHTML = totalCost.toString();
        // const 

    }

    const handleDeAttachGpu = (id) => {
        const order_total = document.querySelector("#total_cost");
        const indexToRemove = dataToShow.findIndex(obj => obj.id === id);
        if (indexToRemove !== -1) {
            const removedObject = dataToShow.splice(indexToRemove, 1)[0];
            setGpu((preGpu) => [...preGpu, removedObject])
            const orderTotal = order_total.textContent;
            let totalCost = parseInt(orderTotal) - parseInt(removedObject?.cost)
            order_total.innerHTML = totalCost.toString();
        }
    }

    function checkObjectForEmpty(obj) {
        for (const [key, value] of Object.entries(obj)) {
            if (typeof value === 'string' && value.trim() === '') {
                return `Error: '${key}' should not be empty or blank.`;
            }

            if (typeof value === 'object') {
                const nestedError = checkObjectForEmpty(value);
                if (nestedError) {
                    return nestedError;
                }
            }
        }
    }

    const deployGpu = async () => {
        var selected_gpu = document.querySelector("#selected_gpu");
        var updateData = { "security_groups": selected_gpu.value };
        const dt = toast.loading("Gpu deploying, Please wait...");
        // const validationError = checkObjectForEmpty(updateData);
        // if (validationError) {
        //     toast.update(dt, {
        //         render: validationError,
        //         type: "error",
        //         isLoading: false,
        //         autoClose: 5000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //     });
        //     return;
        // }
        const gpuIds = dataToShow.map(item => item.id);
        if (gpuIds.length > 0) {
            const responseData = await UsePostData("post", "cloud/" + cloudId + "/gpu", { gpus: gpuIds })
            if (responseData.status === "success") {
                toast.update(dt, {
                    render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
                });
                navigate('/gpu')
            } else {
                toast.update(dt, {
                    render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
                });
            }
        }
    }

    const nodePoolCount = (npid, status, cost) => {
        const total_cost = document.querySelector("#total_cost");
        const max_node_count = document.querySelector(`#maxcount_${npid}`);
        const max_downCountBtn = document.querySelector(`#maxdowncount_${npid}`);
        const max_upCountBtn = document.querySelector(`#maxupcount_${npid}`);
        const order_total = document.querySelector(`#total_cost_${npid}`);
        const increaseMaxCount = () => {
            max_downCountBtn.classList.remove('disabled');
            if (parseInt(max_node_count.value) < 5) {
                max_node_count.value = parseInt(max_node_count.value) + 1;
                updateNodeCount(npid);
            } else {
                max_upCountBtn.classList.add('disabled');
            }
        };

        const decreaseMaxCount = () => {
            max_upCountBtn.classList.remove('disabled');
            if (parseInt(max_node_count.value) > 1) {
                max_node_count.value = parseInt(max_node_count.value) - 1;
                updateNodeCount(npid);
            } else {
                max_downCountBtn.classList.add('disabled');
            }
        };

        const updateNodeCount = (id) => {
            let total_cost1 = parseFloat(max_node_count.value) * parseFloat(cost)
            order_total.innerHTML = total_cost1
            total_cost.innerHTML = `${total_cost1}`
        }

        if (status == 'up') {
            increaseMaxCount();
        } else if (status == 'down') {
            decreaseMaxCount();
        }
    }

    return (
        <div className="d-flex flex-column flex-column-fluid">
            <div className="p-3">
                <div className="col-xl-12">
                    <div className="mb-5 mb-xl-12">
                        <div className="col-xl-12 card mb-5">
                            <div className=" h-lg-100 mb-5">
                                <div className="card-header border-3 mb-5 pt-2">
                                    <h3 className="card-title align-items-start flex-column">
                                        <span className="card-label fw-bolder text-gray-800">
                                            Instance/Server Type
                                        </span>
                                        <span className="text-gray-400 mt-1 fw-bold fs-6">
                                            Select Instance/Server for deploy your Gpu
                                        </span>
                                    </h3>
                                </div>
                                <div className="container-fluid">
                                    <div className="border-3 mb-5 pt-2">
                                        <div className="p-2 border-3 pt-2">
                                            <div data-kt-buttons="true" className="row">
                                                <div className="col-6">
                                                    <label className="btn btn-outline btn-outline-dashed d-flex flex-stack text-start p-6 mb-5">
                                                        <div className="d-flex align-items-center me-2">
                                                            <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="auth"
                                                                    checked={instanceName === "Cloud Server" && "checked"}
                                                                    // defaultValue="option1"
                                                                    // defaultChecked=""
                                                                    onClick={() => {
                                                                        setInstanceName("Cloud Server")
                                                                        getCloud()
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <h2 className="d-flex align-items-center fs-3 fw-bolder flex-wrap">
                                                                    {/* Choose from your loadbalancer target groups */}
                                                                    Cloud Server
                                                                </h2>
                                                                <div className="fw-bold opacity-50">
                                                                    Select target group for you auto scaling groups
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>
                                                <div className="col-6">
                                                    <label className="btn btn-outline btn-outline-dashed d-flex flex-stack text-start p-6">
                                                        <div className="d-flex align-items-center me-2">
                                                            <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="auth"
                                                                    checked={instanceName === "Kubernetes" && "checked"}
                                                                    // defaultValue="option2"
                                                                    onClick={() => {
                                                                        setInstanceName("Kubernetes")
                                                                        getKubernetes()
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <h2 className="d-flex align-items-center fs-3 fw-bolder flex-wrap">
                                                                    {/* Choose from Network Load Balancer */}
                                                                    Kubernetes
                                                                </h2>
                                                                <div className="fw-bold opacity-50">
                                                                    Select network load balancer for your auto scaling group
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {instanceName !== "Cloud Server" ?
                            <>
                                <InstanceTypeKubernetes getKubernetesById={getKubernetesById} cloudData={kubernetesData} />
                            </>
                            :
                            <>
                                <InstanceTypeCloud getKubernetesById={handleGetGpu} cloudData={cloudData} />
                            </>
                        }
                        <div className="col-xl-12 card mb-5">
                            <div className=" h-lg-100 mb-5">
                                <div className="card-header border-3 mb-5 pt-2">
                                    <h3 className="card-title align-items-start flex-column">
                                        <span className="card-label fw-bolder text-gray-800">
                                            GPU Type
                                        </span>
                                        <span className="text-gray-400 mt-1 fw-bold fs-6">
                                            Select GPU To Attach with Cloud Server
                                        </span>
                                    </h3>
                                </div>
                                <div className="container-fluid">
                                    <div className="border-3 mb-5 pt-2">
                                        <div className="col-xl-12  mb-5" id="application-lb-sec">
                                            <div className="pb-5">
                                                <label htmlFor="targetgroups" className="form-label">
                                                    Existing GPU Below
                                                </label>
                                                <div className="row g-9">
                                                    <div className="col-md-4 col-lg-4 col-xxl-4">
                                                        <select className="form-select form-select-sm" id="selected_gpu" style={{ cursor: "pointer" }} disabled={gpu.length <= 0}>
                                                        <option value="">Choose GPU</option>
                                                            {gpu?.length > 0 && gpu?.map((key, index) =>
                                                                <option value={key.id}>{key.make + "-" + key.model}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                    <div className="col-md-4 col-lg-4 col-xxl-4"></div>
                                                    <div className="col-md-4 col-lg-4 col-xxl-4 text-end">
                                                        <button className="btn btn-sm btn-primary" onClick={handleAttachGpu}>Attach Gpu</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {dataToShow.length > 0 &&
                                <div className="table-responsive">
                                    {/*begin::Table*/}
                                    <table
                                        id="clouddata"
                                        className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
                                    >
                                        {/*begin::Table head*/}
                                        <thead className="bg-secondary">
                                            <tr className="fw-bold ">
                                                <th className="w-15 text-center">GPU</th>
                                                <th className="w-10 text-center" />
                                                <th className="w-10 text-start">Number of Gpu</th>
                                                <th className="w-15 text-start sphide">Cost</th>
                                                <th className="w-10 text-center" />
                                                <th className="w-10 text-end">Action</th>
                                                <th className="w-10 text-center" />
                                            </tr>
                                        </thead>
                                        {/*end::Table head*/}
                                        {dataToShow.length > 0 && dataToShow.map((key, index) =>
                                            <tr>
                                                <td className="w-15 text-center text-gray-500">
                                                    <img src={`/assets/media/logos/${key.make.toLowerCase()}.png `} className="" alt="" style={{ width: "45px", height: "36px", padding: "2px", background: "#ebebeb75" }} />
                                                    {" " + "-" + " " + key.model}
                                                </td>
                                                <td className="w-10 text-center"></td>
                                                <td className="w-10 text-center">
                                                    <div className="d-flex align-items-center">
                                                        {/*begin::Dialer*/}
                                                        <div
                                                            className="input-group w-md-150px"
                                                            data-kt-dialer="true"
                                                        >
                                                            {/*begin::Decrease control*/}
                                                            <a
                                                                className="btn btn-icon btn-outline btn-outline-secondary disabled"
                                                                id={"maxdowncount_" + key.id}
                                                                onClick={() => { nodePoolCount(key.id, "down", key.cost) }}
                                                            // onClick={() => props.nodePoolCount(key.pool_id, 'max_down')}
                                                            >
                                                                <i className="bi bi-dash fs-1" />
                                                            </a>
                                                            {/*end::Decrease control*/}
                                                            {/*begin::Input control*/}
                                                            <input
                                                                type="text"
                                                                className="form-control text-center"
                                                                readOnly={true}
                                                                id={"maxcount_" + key.id}
                                                                name={"number_of_gpu[" + key + "][count]"}
                                                                // value={key.nodeCount}
                                                                // name={"nodepools"+index+"count"}
                                                                defaultValue={1}
                                                            // onChange={() => props.nodePoolUpdate(key.pool_id)}
                                                            />
                                                            {/*end::Input control*/}
                                                            {/*begin::Increase control*/}
                                                            <a
                                                                className="btn btn-icon btn-outline btn-outline-secondary"
                                                                id={"maxupcount_" + key.id}
                                                                onClick={() => { nodePoolCount(key.id, "up", key.cost) }}
                                                            // onClick={() => props.nodePoolCount(value.pool_id, 'max_up')}
                                                            >
                                                                <i className="bi bi-plus fs-1" />
                                                            </a>
                                                            {/*end::Increase control*/}
                                                        </div>
                                                        {/*end::Dialer*/}
                                                    </div>
                                                </td>
                                                <td className="w-15 text-start text-gray-500 sphide" id={`total_cost_${key.id}`}>
                                                    {parseFloat(key.cost)}
                                                </td>
                                                <td className="w-10 text-center"></td>
                                                <td className="w-10 text-end">
                                                    <button className="btn btn-sm btn-danger" onClick={() => { handleDeAttachGpu(key.id) }}>DeAttached GPU</button>
                                                </td>
                                                <td className="w-10 text-center"></td>
                                            </tr>
                                        )}
                                    </table>
                                    {/*end::Table*/}
                                </div>
                            }
                        </div>
                        <div className="col-xl-12 card mb-5">
                            <div className="card-header  mb-5 pt-2" style={{ border: "none" }}>
                                <h3 className="card-title align-items-start flex-column">
                                    <span className="card-label fw-bold fs-3 mb-1">Total Cost</span>
                                    <span className="fs-xl-2x fs-2 fw-boldest">
                                        <span id="currency_final">Rs.</span>
                                        <span id="total_cost">0.00</span>
                                        <span id="billing_final">/mon</span>{" "}
                                    </span>
                                    <span className="text-muted mt-1 fw-bold fs-7" />
                                </h3>
                                <div className="card-toolbar">
                                    <a
                                        id="deploykubernetes-btn"
                                        className="btn btn-sm btn-primary btn-active-light-primary"
                                        style={{ width: "100%" }}
                                        onClick={deployGpu}
                                    >
                                        Deploy Gpu &nbsp; &nbsp;
                                        <span className="svg-icon svg-icon-3">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                            >
                                                <path
                                                    d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z"
                                                    fill="black"
                                                />
                                                <path
                                                    opacity="0.3"
                                                    d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z"
                                                    fill="black"
                                                />
                                            </svg>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}