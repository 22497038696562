import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import UseApi from "../../../../../../CustomHooks/useApi";
export default function ListCards() {
  const dataFetchedRef = useRef();
  const [razorpayCardList, setRazorpayCardList] = useState(null);
  const [stripeCardList, setStripeCardList] = useState(null);

  const getcards = async () => {
    const rdata = await UseApi("get", "razorpay/listcard", "");
    const sdata = await UseApi("get", "stripe/listcard", "");

    setStripeCardList(sdata.cards);
    setRazorpayCardList(rdata.cards);
  };

  const deleteCard = async (cardid) => {
    toast.dismiss();
    swal({
      title: "Are you sure?",
      text: "Do you really want to delete this card from your account!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const dt = toast.loading("Card deleting...");
        const dnsp = await UseApi(
          "DELETE",
          "razorpay/" + cardid + "/deletecard",
          "delete"
        );
        if (dnsp.status == "error") {
          toast.update(dt, {
            render: dnsp.message,
            type: "warning",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.update(dt, {
            render: dnsp.message,
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          getcards();
        }
      }
    });
  };
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getcards();
  }, []);

  return (
    <>
      {/*begin::Tab panel*/}
      <div
        id="kt_billing_creditcard"
        className="tab-pane fade show active"
        role="tabpanel"
      >
        {/*begin::Row*/}
        <div className="row mb-5 gx-9 gy-6" id="card-list">
          {razorpayCardList != null &&
            razorpayCardList &&
            razorpayCardList.map((value, index) => (
              <>
                <div className="col-xl-6">
                  <div className="card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6">
                    <div className="d-flex flex-column py-2">
                      <div className="d-flex align-items-center fs-4 fw-bolder mb-5">
                        {value.rpay_card_name}
                      </div>
                      <div className="d-flex align-items-center">
                        {value.rpay_card_network == "MasterCard" ? (
                          <img
                            src={"./assets/media/svg/card-logos/mastercard.svg"}
                            style={{ width: "80px", height: "48px" }}
                            className="me-4"
                          />
                        ) : value.rpay_card_network == "visa" ? (
                          <img
                            src={"./assets/media/svg/card-logos/visa.svg"}
                            style={{ width: "80px", height: "48px" }}
                            className="me-4"
                          />
                        ) : value.rpay_card_network == "American Express" ? (
                          <img
                            src={
                              "./assets/media/svg/card-logos/american-express.svg"
                            }
                            style={{ width: "80px", height: "48px" }}
                            className="me-4"
                          />
                        ) : value.rpay_card_network == "Diners Club" ? (
                          <img
                            src={
                              "./assets/media/svg/card-logos/diners-club.svg"
                            }
                            style={{ width: "80px", height: "48px" }}
                            className="me-4"
                          />
                        ) : value.rpay_card_network == "maestro" ? (
                          <img
                            src={"./assets/media/svg/card-logos/maestro.svg"}
                            style={{ width: "80px", height: "48px" }}
                            className="me-4"
                          />
                        ) : value.rpay_card_network == "Unknown" ? (
                          <img
                            src={"./assets/media/svg/card-logos/unknown.svg"}
                            style={{ width: "80px", height: "48px" }}
                            className="me-4"
                          />
                        ) : (
                          ""
                        )}

                        <div>
                          <div className="fs-4 fw-bolder">
                            {value.rpay_card_network} ****{" "}
                            {value.rpay_card_last4}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center py-2">
                      <button
                        type="reset"
                        className="btn btn-sm btn-light-danger btn-danger me-3"
                        onClick={() => deleteCard(value.id)}
                      >
                        Delete
                      </button>
                      <button
                        className="btn btn-sm btn-light d-none btn-active-light-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_new_card"
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ))}
        </div>
        <div className="row mb-5 gx-9 gy-6" id="stripe-card-list">
          {stripeCardList != null &&
            stripeCardList &&
            stripeCardList.map((value, index) => (
              <>
                <div className="col-xl-6">
                  <div className="card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6">
                    <div className="d-flex flex-column py-2">
                      <div className="d-flex align-items-center fs-4 fw-bolder mb-5"></div>
                      <div className="d-flex align-items-center">
                        {value.brand == "Mastercard" ? (
                          <img
                            src={"./assets/media/svg/card-logos/mastercard.svg"}
                            style={{ width: "80px", height: "48px" }}
                            className="me-4"
                          />
                        ) : value.brand == "visa" ? (
                          <img
                            src={"./assets/media/svg/card-logos/visa.svg"}
                            style={{ width: "80px", height: "48px" }}
                            className="me-4"
                          />
                        ) : value.brand == "American Express" ? (
                          <img
                            src={
                              "./assets/media/svg/card-logos/american-express.svg"
                            }
                            style={{ width: "80px", height: "48px" }}
                            className="me-4"
                          />
                        ) : value.brand == "Diners Club" ? (
                          <img
                            src={
                              "./assets/media/svg/card-logos/diners-club.svg"
                            }
                            style={{ width: "80px", height: "48px" }}
                            className="me-4"
                          />
                        ) : value.brand == "maestro" ? (
                          <img
                            src={"./assets/media/svg/card-logos/maestro.svg"}
                            style={{ width: "80px", height: "48px" }}
                            className="me-4"
                          />
                        ) : value.brand == "Unknown" ? (
                          <img
                            src={"./assets/media/svg/card-logos/unknown.svg"}
                            style={{ width: "80px", height: "48px" }}
                            className="me-4"
                          />
                        ) : (
                          ""
                        )}

                        <div>
                          <div className="fs-4 fw-bolder">
                            **** {value.last4}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center py-2">
                      <button
                        type="reset"
                        className="btn btn-sm btn-light-danger btn-danger me-3"
                        onClick={() => deleteCard(value.id)}
                      >
                        Delete
                      </button>
                      <button
                        className="btn btn-sm btn-light d-none btn-active-light-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_new_card"
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ))}
        </div>
        {/*end::Row*/}
      </div>
      {/*end::Tab panel*/}
    </>
  );
}
