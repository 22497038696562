import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import CallAppinfo from "./App/CustomHooks/CallAppinfo";
import DisableDevtools from "./middleware/DisableDevtools";
import { setPlatformType } from "./middleware/AppConfig";
import PublicRoutes from "./routes/public-routes/PublicRoutes";
import PrivateRoutes from "./routes/private-routes/PrivateRoutes";
import localStorage from "local-storage";
 
function App() {
  const location = useLocation();
  var lsAuth = Cookies.get("microhost_api_auth");
  useEffect(() => {
    (async function () {
      setPlatformType(location);
     setTimeout(async()=>{
      var isdebug = localStorage.get("debug");
      if(isdebug!='on'){
        DisableDevtools();
        }
      await CallAppinfo();
     },1000) 
    })();
  }, []);

  if (lsAuth) {
    localStorage.set("Auth", lsAuth);
    return (
      <>
           <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        <PrivateRoutes />
      </>
    ); 
  } else {
    return (
      <>
           <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        <PublicRoutes />
      </>
    );
  }
}

export default App;
