import {React} from 'react';

export default function ImgContent(){
    return (<>
        <>
  {/* LINE */}
  {/* Set line color */}
  <hr
  align="center"
  width="100%"
  size={1}
  noshade=""
  style={{ margin: 0, padding: 0, color: "black", border: "1px solid #a29c9c" }}
/>

  {/* LIST */}
  <table
    align="center"
    border={0}
    cellSpacing={0}
    cellPadding={0}
    style={{
      width: "inherit",
      marginBottom: "57px",
      padding: 0,
      borderCollapse: "collapse",
      borderSpacing: 0
    }}
  >
    {/* LIST ITEM */}
    <tbody>
      <tr>
        {/* LIST ITEM IMAGE */}
        {/* Image text color should be opposite to background color. Set your url, image src, alt and title. Alt text should fit the image size. Real image size should be x2 */}
        <td
          align="left"
          valign="top"
          style={{
            borderCollapse: "collapse",
            borderSpacing: 0,
            paddingTop: 30,
            paddingRight: 20
          }}
        >
          <img
            border={0}
            vspace={0}
            hspace={0}
            style={{
              padding: 0,
              margin: 0,
              outline: "none",
              textDecoration: "none",
              msInterpolationMode: "bicubic",
              border: "none",
              display: "block",
              color: "#000000"
            }}
            src="https://utho.com/assets/favicon/utho-apple-touch-icon.png"
            alt="H"
            title="Highly compatible"
            width={50}
            height={50}
          />
        </td>
        {/* LIST ITEM TEXT */}
        {/* Set text color and font family ("sans-serif" or "Georgia, serif"). Duplicate all text styles in links, including line-height */}
        <td
          align="left"
          valign="top"
          style={{
            fontSize: 17,
            fontWeight: 400,
            lineHeight: "160%",
            borderCollapse: "collapse",
            borderSpacing: 0,
            margin: 0,
            padding: 0,
            paddingTop: 25,
            color: "#000000",
            fontFamily: "sans-serif"
          }}
          className="paragraph"
        >
          <b style={{ color: "#333333" }}>Highly compatible</b>
          <br />
          Tested on the most popular email clients for web, desktop and mobile.
          Checklist included.
        </td>
      </tr>
      {/* LIST ITEM */}
      <tr>
        {/* LIST ITEM IMAGE */}
        {/* Image text color should be opposite to background color. Set your url, image src, alt and title. Alt text should fit the image size. Real image size should be x2 */}
        <td
          align="left"
          valign="top"
          style={{
            borderCollapse: "collapse",
            borderSpacing: 0,
            paddingTop: 30,
            paddingRight: 20
          }}
        >
          <img
            border={0}
            vspace={0}
            hspace={0}
            style={{
              padding: 0,
              margin: 0,
              outline: "none",
              textDecoration: "none",
              msInterpolationMode: "bicubic",
              border: "none",
              display: "block",
              color: "#000000"
            }}
            src="https://utho.com/assets/favicon/utho-apple-touch-icon.png"
            alt="D"
            title="Designer friendly"
            width={50}
            height={50}
          />
        </td>
        {/* LIST ITEM TEXT */}
        {/* Set text color and font family ("sans-serif" or "Georgia, serif"). Duplicate all text styles in links, including line-height */}
        <td
          align="left"
          valign="top"
          style={{
            fontSize: 17,
            fontWeight: 400,
            lineHeight: "160%",
            borderCollapse: "collapse",
            borderSpacing: 0,
            margin: 0,
            padding: 0,
            paddingTop: 25,
            color: "#000000",
            fontFamily: "sans-serif"
          }}
          className="paragraph"
        >
          <b style={{ color: "#333333" }}>Designer friendly</b>
          <br />
          Sketch app resource file and a&nbsp;bunch of&nbsp;social media icons
          are&nbsp;also included in&nbsp;GitHub repository.
        </td>
      </tr>
    </tbody>
  </table>
  {/* LINE */}
</>

        </>)
}