export function InputValidation(event, type, allowedSpecialChars) {
    const { value } = event.target;
    console.log(event, type);
    if (type == "string") {
        const filteredValue = value.split('').filter(char => {
            // Allow letters, whitespace, and specified special characters
            return /[a-zA-Z\s]/.test(char) || allowedSpecialChars.includes(char);
        }).join('');
        event.target.value = filteredValue;
    } else if (type == "number") {
        // const numberValue = parseInt(value);
        let inputValue = '';
        console.log(parseFloat(value),value);
        if (!isNaN(value) && parseFloat(value) >= 1) {
            inputValue = value;
            event.target.value = value; // Update input value only if it's valid
          } else {
            event.target.value = inputValue; // Revert to previous value if invalid
          }
      
    }
}

export function HandleKeyDown (event) {
    if (event.key === 'Enter') {
      // rebuildServer();
      event.preventDefault();
    }
  };