import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import localStorage from "local-storage";  
import UsePost from "../../../../../CustomHooks/usePost";
import UseApi from "../../../../../CustomHooks/useApi";
import ApplyCoupon from "../../../../../Auth/ApplyCoupon";
var serialize=require('form-serialize');
const OtpVerify = (props) => {
  const [logo_white, setLogo_white]=useState(null);

  async function CallAppInfo(){  
    const app_info=await UseApi('get','/appinfo',''); 
    if (app_info) {
       ////console.log("app info =>");
       ////console.log(app_info);
      var siteUrlString=app_info.site;
      
      var siteUrlArr=siteUrlString.split(".");
      var siteLength=siteUrlArr.length; 
      var siteOrg="https://api."+siteUrlArr[siteLength-2]+"."+siteUrlArr[siteLength-1];  
      var whiteLogo=siteOrg+"/publicfiles/"+app_info.logo_light;
      setLogo_white(whiteLogo); 
   }
  }

  useEffect(()=>{
    CallAppInfo();
  },[])
    const [dcode,setDcode]=useState("");
  const [dvalue,setDvalue]=useState("");
  const codeForm=async()=>{
        //const userInfo=await UseApi('get','account/info',''); 
        var form=document.querySelector('#code_form');
        var requestData=serialize(form,{hash:true});
        const codeFa=requestData.code_1+''+requestData.code_2+''+requestData.code_3+''+requestData.code_4;
          setDcode(codeFa); 
          setDvalue(localStorage.get("loginEmail"));
  }
  const submitCode=async()=>{
    var submitBtn=document.querySelector("#otp-btn");
    if(submitBtn){
      var preContent=submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';  
    const dt=toast.loading("Please wait...");
    const responseData=await UsePost('post','account/otp/verify','verification-otp');
      if(responseData){
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML=preContent;
      if(responseData.status!='success'){
        toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      }else{
        toast.update(dt,{render: "Mobile number verified successfully", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });   
      window.afterMobileVerification();
         const location_url = window.location.origin;
         window.location.href = location_url;  
      }
    }
  }
}

const showMobileSec=()=>{
    var mobile_verify_sec=document.querySelector("#mobile-verify-sec");
    var otp_verify_sec=document.querySelector("#otp-verify-sec");
    if(mobile_verify_sec && otp_verify_sec){
     mobile_verify_sec.classList.remove("d-none");
     otp_verify_sec.classList.add("d-none");
    }
}
    
    const backKeyPress=(event)=>{
      event.preventDefault();

      ////console.log(event); 
      //////console.log(event.target.name); 
    if((event.key==0 || event.key==1 || event.key==2 || event.key==3 || event.key==4 || event.key==5 || event.key==6 || event.key==7 || event.key==8 || event.key==9 || event.key=="Backspace" || event.key=="Delete") && event.ctrlKey==false && event.altKey==false){
      var inputName=event.target.name;
      ////console.log(inputName.split("_"));
      var nameArr=inputName.split("_");
      var currentIndex=nameArr[1];
      if(event.key=="Backspace" || event.key=="Delete"){
        currentIndex--;
        if(currentIndex<7 && currentIndex>=1){
        ////console.log(currentIndex);
        ////console.log("current index");
        document.querySelector('#pc'+currentIndex).focus();
        }
      }else{
        if(event.key=="1" || event.key=="2" || event.key=="3" || event.key=="4" || event.key=="5" || event.key=="6" || event.key=="7" || event.key=="8" || event.key=="9" || event.key=="0"){
          document.querySelector('#pc'+currentIndex).value=event.key;
        }else{
          document.querySelector('#pc'+currentIndex).value="";
        }
        currentIndex++;
        if(currentIndex<5){
        ////console.log(currentIndex);
        document.querySelector('#pc'+currentIndex).focus();
        }
      }
    } 
      codeForm(); 
       //  ////console.log(event.key); 
    }

    const jumpToNextPcInput=(currentIndex)=>{ 
      codeForm();
      currentIndex++;
      if(currentIndex<7){
      ////console.log(currentIndex);
      document.querySelector('#pc'+currentIndex).focus();
      }
     }

     const handleChange = e => { 
      e.stopPropagation();
      ////console.log("copy data");
        var otp=e.clipboardData.getData('Text'); 
        document.querySelector("#pc1").value=otp[0];
        document.querySelector("#pc2").value=otp[1];
        document.querySelector("#pc3").value=otp[2];
        document.querySelector("#pc4").value=otp[3]; 
    };

    const resendOtp=async()=>{
      const dt=toast.loading("Please wait...");
      const responseData=await UsePost('post','2fa/resend','resendotp');
        ////console.log(responseData);
        if(responseData.status==='error'){
          toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        }else{
          toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
     }
    }

    const resendingOtp=async ()=>{ 
      const dt=toast.loading("OTP resending, Please wait..."); 
     
      const user_data= await UseApi("get","account/info", "");
            if(user_data){
              if(user_data.user){
               if(user_data.user.mobile && user_data.user.mobilecc){
                const responseData2=await UsePost('post','account/otp/sendotp','nothing');
                if(responseData2){  
                  
                if(responseData2.status=="success"){
  
                  var phoneNo=user_data.user.mobilecc+" "+user_data.user.mobile;
                toast.update(dt,{render: "OTP has been sent to "+phoneNo, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
              });   
              }else{
                toast.update(dt,{render: responseData2.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
              }); 
              } 
            }
               }
              }
            }
    }
    const updatePhone=()=>{
      if(props && props.updateMobile){
        props.updateMobile();
      }
    }

    const callUs=async()=>{
      const dt=toast.loading("Please wait...");
      const responseData=await UsePost('post','account/otp/resendvoice','nothing');
        ////console.log(responseData);
        if(responseData.status==='error'){
          toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        }else{
          toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
     }
    }
    return (
        <>
         <div className=' p-10 p-lg-15 mx-auto text-center d-none' id="otp-verify-sec">
              <form
        className="form w-100 mb-10"
        noValidate="novalidate" 
        id="code_form"
      >
        {/*begin::Icon*/}
        <div className="text-center mb-10">
        {logo_white!=null?(<>
    <img
          alt="Logo"
          src={logo_white}
          className="h-60px logo-fade-in"
        />
  </>):(<>
    <img
          alt="Logo"
          src="./assets/media/logos/loader-black.png"
          className="h-60px logo-fade-in"
        />
  </>)}
        </div>
        {/*end::Icon*/}
        {/*begin::Heading*/}
        <div className="text-center mb-10">
          {/*begin::Title*/}
          <h1 className="text-light mb-3">Verify Mobile OTP</h1>
          {/*end::Title*/}
          {/*begin::Sub-title*/}
          <div className="text-gray-400 fw-bold fs-5 mb-5 d-none">
            Enter the verification code we sent <b>or</b> <a onClick={showMobileSec}>Update mobile</a>
          </div>
          {/*end::Sub-title*/} 
        </div>
        {/*end::Heading*/}
        {/*begin::Section*/}
        <div className="mb-10 px-md-10">
          {/*begin::Label*/}
          <div className="fw-bolder text-center text-gray-400 fs-6 mb-5 ms-1">
            Enter 4 digit security code sent to your registered Mobile
          </div>
          {/*end::Label*/}
          {/*begin::Input group*/}
          <div className="d-flex flex-wrap flex-center ">
          <input
               
              onPaste={handleChange} 
              onKeyUp={(e) => backKeyPress(e)}
              data-id={1}
              id="pc1"
               type="number" style={{marginRight:'15px'}}
              data-inputmask="'mask': '9', 'placeholder': ''"
              maxLength={1}
              className="hide-arrows form-control form-control-solid h-45px w-45px fs-2qx text-center border-primary border  "
              defaultValue=""
              inputMode="number"
              name="code_1"
            />
            <input 
             
            onPaste={handleChange}
            onKeyUp={(e) => backKeyPress(e)}
            data-id={2}
            id="pc2"
               type="number" style={{marginRight:'15px'}}
              data-inputmask="'mask': '9', 'placeholder': ''"
              maxLength={1}
              className="hide-arrows form-control form-control-solid h-45px w-45px fs-2qx text-center border-primary border  "
              defaultValue=""
              inputMode="number"
              name="code_2"
            />
            <input  
            onPaste={handleChange}
            onKeyUp={(e) => backKeyPress(e)}
              data-id={3}
            id="pc3"
               type="number" style={{marginRight:'15px'}}
              data-inputmask="'mask': '9', 'placeholder': ''"
              maxLength={1}
              className="hide-arrows form-control form-control-solid h-45px w-45px fs-2qx text-center border-primary border  "
              defaultValue=""
              inputMode="number"
              name="code_3"
            />
            <input  
            onPaste={handleChange}
            onKeyUp={(e) => backKeyPress(e)}
              data-id={4}
            id="pc4" 
               type="number" style={{marginRight:'15px'}}
              data-inputmask="'mask': '9', 'placeholder': ''"
              maxLength={1}
              className="hide-arrows form-control form-control-solid h-45px w-45px fs-2qx text-center border-primary border  "
              defaultValue=""
              inputMode="number"
              name="code_4"
            />
          </div>
          {/*begin::Input group*/}
        </div>
        {/*end::Section*/}
        {/*begin::Submit*/}
        <div className="d-flex flex-center">
          <button
            type="button"
            onClick={submitCode}
            className="btn btn-lg btn-primary fw-bolder"
            id="otp-btn"
          >
            <span className="indicator-label">Submit</span>
          </button>
        </div>
            {/*begin::Notice*/}
      <div className="text-center fw-bold fs-5 mt-5">
        <span className="text-muted me-1">Didn’t get the code ?</span>
        <a  onClick={resendingOtp} className="link-primary fw-bolder fs-5 me-1" style={{cursor:"pointer"}}>
          Resend
        </a>
        <span className="text-muted me-1">or</span>
        <a onClick={callUs} className="link-primary fw-bolder fs-5">
          Call Us
        </a>
      </div> 
      <br/>
      {props && props.updateMobile?(<>
      <div className="text-center fw-bold fs-5">
        <p className="text-muted me-1">or</p> 
         <span className="text-muted me-1">To update Mobile, </span>
        <a onClick={updatePhone}  className="link-primary fw-bolder fs-5" style={{cursor:"pointer"}}>
          Click here
        </a>
      </div>
      </>):('')}
      {/*end::Notice*/}
        {/*end::Submit*/}
      </form>
      <form className="form w-100 mb-10"
        noValidate="novalidate" 
        id="verification-otp"
        onSubmit={(event)=>event.preventDefault()}
        >
      <input 
              className="form-control form-control-lg form-control-solid"
              type="hidden"
              name="otpcode"
              value={dcode} 
              autoComplete="off"
            /> 
            <input 
              className="form-control form-control-lg form-control-solid"
              type="hidden"
              name="email"
              value={dvalue} 
              autoComplete="off"
            /> 
      </form>
      </div>
        </>
    );
}

export default OtpVerify;
