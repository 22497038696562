import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import TableLoader from "../../../../../Loaders/TableLoader";
import TopBarHeaderLine from "../../../../../../../CustomHooks/TopBarHeaderLine";
import AccessDenied from "../../../../../AccessDenied";
import UseApi from "../../../../../../../CustomHooks/useApi";
import UseCallApi from "../../../../../../../CustomHooks/useCallApi";
import SubnetAttachNatGateway from "./SubnetAttachNatGateway";

export default function SubnetNatGateway(props) {
  const [dataFetched, setDataFetched] = useState(false);
  const [access, setAccess] = useState(true);
  const [perPage, setPerPage] = useState(5);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [activePage, setActivePage] = useState(0);
  const [routesDataAll, setRoutesDataAll] = useState(null);
  const [dummyRoutesDataAll, setDummyRoutesDataAll] = useState(null);
  const [subnetData, setSubnetData] = useState(null)
  const [dummySubnetData, setDummySubnetData] = useState(null)

  var query = window.location.href;
  var vars = query.split("/");
  var subnetid = vars[5];

  function ProductInfo({ productdata }) {
    return (
      <>
        {productdata != null && Object.keys(productdata).length != 0 ? (productdata && productdata.map((value, index) => (
          <tr>
            <td className="text-start">
              <div className="d-flex flex-column w-100 me-2">
                <span className="me-2 fs-7 fw-bold">
                  {value.name}
                </span>
              </div>
            </td>
            <td className="text-center">
              <span className=" me-2 fs-7 fw-bold">
                {value.uuid}
              </span>
            </td>
            <td className="text-center">
              <div className="d-flex flex-column w-100 me-2">
                <span className=" me-2 fs-7 fw-bold">
                  {value.ip_type}
                </span>
              </div>
            </td>
            <td className="text-center">
              {value.status == "active" ?
                <span className="badge badge-sm badge-success">
                  {value.status}
                </span>
                :
                <span className="badge badge-sm badge-warning">
                  {value.status}
                </span>
              }
            </td>
            <td className="text-end pe-9">
              {" "}
              {" "}
              <a
                onClick={(e) => dettachedSubnet(value.id)}
                id={`del_subnet_btn_${value.id}`}
                className="btn btn-sm btn-danger sphide"
              >
                Dettached
              </a>
            </td>
          </tr>
        ))) :
          (<>
            <tr>
              <td colspan="12" className="" id="no_record" valign="top">
                <div className="d-flex flex-column flex-center">
                  <div className="fs-5 fw-bolder text-dark mb-4">No Nat Gateway found.</div>
                </div>
              </td>
            </tr>
          </>
          )}
      </>
    );
  }

  const dettachedSubnet = async (ngid) => {
    try {
      // Dismiss any previous toasts
      toast.dismiss();

      // Show a loading toast
      const dt = toast.loading("Please wait... System is processing your request.");

      // Find the submit button element
      const submitBtn = document.querySelector(`#del_subnet_btn_${ngid}`);

      if (submitBtn) {
        // Save the current button content and disable it while processing
        const preContent = submitBtn.innerHTML;
        submitBtn.classList.add("disabled");
        submitBtn.innerHTML = `Please wait... <span class="indicator-label">
          <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>`;

        // Send a POST request
        const responseData = await UseCallApi("post", `vpc/natgateway/${ngid}/dettach`, { "subnet": subnetid });

        // Check for errors in the response
        if (responseData.status === "error") {
          toast.update(dt, {
            render: responseData.message,
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        // Handle success
        else if (responseData.status === "success") {
          toast.update(dt, {
            render: responseData.message,
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          // Call the getGateways function if it exists in props
          getSubnet()
        }

        // Re-enable the button and restore its content
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
      }
    } catch (error) {
      // Catch any errors and display an error toast
      toast.error("An unexpected error occurred. Please try again.");
      console.error("Error adding VPC route:", error);
    }
  };

  const dataFetchedRef = useRef(false);

  const getSubnet = async () => {
    try {
      const response = await UseApi('get', `vpc/subnet/${subnetid}`, '');
      console.log(response);

      // Ensure the response is not empty and set the VPC data
      if (response && response.status !== "error") {
        if (response?.nat_gateway?.length > 0) {
          setDataFetched(true)
          setSubnetData(response?.nat_gateway);
          setDummySubnetData(response?.nat_gateway)
        } else {
          setDataFetched(true)
          setSubnetData([])
          setDummySubnetData([])
        }
      } else {
        setDataFetched(true)
        setSubnetData([]); // or [] depending on your use case
        setDummySubnetData([])
      }
    } catch (error) {
      setDataFetched(true)
      setSubnetData([]); // or [] depending on your use case
      setDummySubnetData([])
    }
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getSubnet();
  }, [pageCount, dummyRoutesDataAll]);

  const handleSearch = (text) => {
    const data = routesDataAll.filter(row => {
      return (
        (row.name && row.name.toString().toLowerCase().includes(text.toLowerCase())) ||
        row.dclocation.location && row.dclocation.location.toString().toLowerCase().includes(text.toLowerCase())
      )
    });
    setDummyRoutesDataAll(data)
    setPageCount((old) => old = Math.ceil(data.length / perPage));
  }

  function PaginatedItems({ itemsPerPage, ProductData }) {
    setPageCount(Math.ceil(ProductData.length / parseInt(itemsPerPage)));
    const endOffset = parseInt(itemOffset) + parseInt(itemsPerPage);
    const handlePageClick = (event) => {
      setActivePage((old) => old = event.selected)
      const newOffset = event.selected * parseInt(itemsPerPage);
      setItemOffset(newOffset);
    };
    return (
      <>
        <ProductInfo productdata={ProductData.slice(parseInt(itemOffset), endOffset)} />
        {ProductData.length > 0 && ProductData.length > 5 ?
          <td colspan="12" className="ps-3">
            <ReactPaginate
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              initialPage={activePage}
              previousLabel="< previous"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </td>
          :
          ""
        }
      </>
    );
  }

  if (dataFetched) {
    if (access) {
      return (
        <>
          <div className="col-xl-12">
            {/*begin::Tables Widget 9*/}
            <div className="card card-xl-stretch mb-5 mb-xl-12">
              {/*begin::Header*/}
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold fs-3 mb-1">
                    Nat Gateway
                  </span>
                </h3>
                <div
                  className="card-toolbar"
                >
                  <div className="position-relative my-1" style={{ marginRight: 20 }}>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      data-kt-table-widget-4="search"
                      id="searchClient"
                      style={{ padding: '0.6rem' }}
                      onChange={e => handleSearch(e.target.value)}
                      placeholder="Search"
                    />
                  </div>
                  <div style={{ marginRight: 20 }}>
                    <div className="dataTables_length">
                      <label className="w-125px">
                        <select
                          onChange={e => setPerPage(e.target.value)}
                          //data-control="select2"
                          style={{ padding: '0.6rem' }}
                          className="form-select form-select-sm form-select-solid"
                          defaultValue={5}
                          id="perPage"

                        >
                          <option value={5} selected="">
                            5 Records
                          </option>
                          <option value={10}>10 Records</option>
                          <option value={25}>25 Records</option>
                          <option value={50}>50 Records</option>
                          <option value={100}>100 Records</option>
                        </select>
                      </label>
                    </div>
                  </div>
                  <SubnetAttachNatGateway getSubnet={getSubnet} />
                </div>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              {/*begin::Table container*/}
              <div className="table-responsive">
                {/*begin::Table*/}
                <table
                  id="vpcTable"
                  className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
                >
                  {/*begin::Table head*/}
                  <thead className="bg-secondary">
                    <tr className="fw-bold ">
                      <th className="w-100px text-start">Name</th>
                      <th className="w-100px text-center">Nat Gateway ID</th>
                      <th className="w-100px text-center">Type</th>
                      <th className="w-100px text-center">Status</th>
                      <th className="w-100px text-center"></th>
                    </tr>
                  </thead>
                  {/*end::Table head*/}
                  {/*begin::Table body*/}
                  <tbody id="mainTable" >

                    {dummySubnetData != null ? (<>
                      {access == false ? (<>
                        <tr>
                          <td colspan={12}>
                            <AccessDenied />
                          </td>
                        </tr>
                      </>) : (<>
                        {dummySubnetData.length != 0 ? (<>
                          <PaginatedItems ProductData={dummySubnetData} itemsPerPage={perPage} />
                        </>) : (<>
                          <tr>
                            <td colspan={12}>
                              <div className="d-flex flex-column flex-center">
                                <div className="fs-5 fw-bolder text-dark mb-4">
                                  No Nat Gateway found.
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>)}
                      </>)}
                    </>) : (<>
                      <tr>
                        <td colspan={12}>
                          <TableLoader />
                          <TopBarHeaderLine />
                        </td>
                      </tr>

                    </>)}
                  </tbody>
                  {/*end::Table body*/}
                </table>
                {/*end::Table*/}
              </div>
              {/*end::Table container*/}
              {/*begin::Body*/}
            </div>
            {/*end::Tables Widget 9*/}
          </div>
        </>
      )
    } else {
      return (
        <>
          <AccessDenied />
        </>
      )
    }
  } else {
    return (
      <>
        <TableLoader />
      </>
    )
  }
}