import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import AccessDenied from "../../AccessDenied";
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";
import TableLoader from "../../Loaders/TableLoader";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import UsePost from "../../../../CustomHooks/usePost";
import { propTypes } from "react-bootstrap/esm/Image";

export default function TargetGroupList(props) {
  let navigate = useNavigate()
  const [dataFetched, setDataFetched] = useState(false);
  const [access, setAccess] = useState(true);
  const [perPage, setPerPage] = useState(5);
  const [targetGroupData, setTargetGroupData] = useState(null);
  const [dummyTargetGroupData, setDummyTargetGroupData] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [targetGroup, setTargetGroup] = useState({
    "health_check_interval": 300,
    "health_check_timeout": 300,
    "healthy_threshold": 300,
    "unhealthy_threshold": 300,
    "port": 80
  })
  const dataFetchedRef = useRef(false);
  const loaderFunc = () => {
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if (loader && no_record) {
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");

      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
  }
  const getTargetGroups = async () => {
    const dt = toast
    const data = await UseApi('get', 'targetgroup', '');
    if (data.status != "success") {
      setTargetGroupData(null);
      setDummyTargetGroupData(null)
      toast.update(dt, {
        render: "no record found", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      // loaderFunc();
      setDataFetched(true)
      if (data.message == "Permission denied.") {
        setAccess(false);
      } else {
        setAccess(true)
      }
    } else {
      setDataFetched(true)
      setAccess(true)
      setTargetGroupData(data.targetgroups);
      setDummyTargetGroupData(data.targetgroups)
      toast.dismiss();
      //   toast.update(dt,{render: "Your Loadbalancers request has been successful", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      // });  

    }
  }
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getTargetGroups();
  }, []);

  function convertTimestamp(timestamp) {
    const date = new Date(timestamp);

    // Format the date
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
      timeZone: 'UTC'
    }).format(date);

    return formattedDate.replace('GMT', '(UTC)');
  }

  function ProductInfo({ productdata }) {
    return (
      <>
        {productdata != null && Object.keys(productdata).length != 0 ? (productdata && productdata.map((value, index) => (
          <tr>
            {/* <td /> */}
            <td className="text-start">

              <div className="d-flex justify-content-start flex-column">
                <a className="text-dark text-gray-700 fw-bold text-hover-primary fs-6 sphide">
                  {value.name}
                </a>
              </div>
            </td>
            <td className="text-center">

              <div className="flex-column w-100 me-2">

                <div className="flex-stack mb-2">

                  <span className="me-2 fs-6 badge badge-primary">
                    {value.targets.length}
                  </span>
                </div>
              </div>
            </td>
            <td className="text-center">

              <div className="flex-column w-100 me-2">

                <div className="flex-stack mb-2">

                  <span className="text-gray-700 me-2 fs-6 fw-bold">
                    {value.health_check_path}
                  </span>
                </div>
              </div>
            </td>
            <td className="text-center">

              <div className="flex-column w-100 me-2">

                <div className="flex-stack mb-2">

                  <span className="text-gray-700 me-2 fs-6 fw-bold">
                    {value.health_check_interval}
                  </span>
                </div>
              </div>
            </td>
            <td className="text-center">

              <div className="flex-column w-100 me-2">

                <div className="flex-stack mb-2">

                  <span className="text-gray-700 me-2 fs-6 fw-bold">
                    {value.health_check_timeout}
                  </span>
                </div>
              </div>
            </td>
            <td className="text-center">
              <div className="d-flex flex-column w-100 me-2">

                <div className=" flex-stack mb-2 sphide">
                  <p className="fw-bold text-gray-700">Protocol: &nbsp;&nbsp;
                    <span className="text-muted me-2 fs-7 fw-bold">
                      {value.protocol}
                    </span>
                  </p>
                  <p className="fw-bold text-gray-700">Port:&nbsp;&nbsp; <span className="text-muted me-2 fs-7 fw-bold">
                    {value.port}
                  </span>
                  </p>
                </div>
              </div>
            </td>
            <td className="text-center">
              <div className="d-flex flex-column w-100 me-2">

                <div className=" flex-stack mb-2 sphide">
                  <p className="fw-bold text-gray-700">Unhealthy: &nbsp;&nbsp;
                    <span className="text-muted me-2 fs-7 fw-bold">
                      {value.unhealthy_threshold}
                    </span>
                  </p>
                  <p className="fw-bold text-gray-700">Healthy:&nbsp;&nbsp; <span className="text-muted me-2 fs-7 fw-bold">
                    {value.healthy_threshold}
                  </span>
                  </p>
                </div>
              </div>
            </td>
            <td className="text-center">

              <div className=" flex-column w-100 me-2">

                <div className=" flex-stack mb-2">

                  <span className=" me-2 fs-6 fw-bold text-gray-700 sphide">
                    {value.created_at}
                  </span>
                </div>
              </div>
            </td>
            <td className="text-end">

              <div className="justify-content-end flex-shrink-0">

                <Link to={"/targetgroup/" + value.id + ""}>
                  <a
                    className="btn  btn-primary btn-active-light-primary btn-sm me-1 me-5 sphide"
                  >

                    Manage
                    <span className="svg-icon ms-2 svg-icon-3">

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >

                        <rect
                          opacity="0.5"
                          x={18}
                          y={13}
                          width={13}
                          height={2}
                          rx={1}
                          transform="rotate(-180 18 13)"
                          fill="black"
                        />
                        <path
                          d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                  </a>
                </Link>
              </div>
            </td>
          </tr>
        ))) : (<>
          <tr>
            <td colspan="12" className="" id="no_record" valign="top">
              <div className="d-flex flex-column flex-center">
                <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
              </div>
            </td>
          </tr>
        </>)}

      </>
    )
  }

  const handleSearch = (text) => {
    const endOffset = itemOffset + perPage;
    // Remove special characters from the input text
    const escapedText = text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    // Create a regular expression pattern to match the filtered text
    const regex = new RegExp(escapedText, "gi");

    // Filter the clusterData based on the regex pattern
    let data = targetGroupData.filter((row) => {
      // Check if any property in the row matches the regex pattern
      return Object.keys(row).some(key => {
        // Exclude 'created_at' property from the search
        if (key !== 'created_at') {
          return regex.test(row[key]);
        }
        return false;
      });
    });

    // Update the state with the filtered data
    setDummyTargetGroupData(data);
    setPageCount(Math.ceil(data.length / perPage));
  }

  function PaginatedItems({ itemsPerPage, ProductData }) {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(ProductData.length / itemsPerPage));
    const handlePageClick = (event) => {
      setActivePage((old) => old = event.selected);
      const newOffset = event.selected * itemsPerPage;
      setItemOffset(newOffset);

    };
    // var searchEle = document.querySelector("#searchClient");
    // if (searchEle) {
    //   searchEle.focus();
    // }
    return (
      <>
        <ProductInfo productdata={ProductData.slice(itemOffset, endOffset)} />
        {ProductData.length > 0 &&
          <td colspan="12" className="ps-3">
            <ReactPaginate
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              initialPage={activePage}
              previousLabel="< previous"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </td>
        }
      </>
    );
  }
  // if(dataFetched){
  //   if(access==false){
  //     return (
  //       <>
  //        <AccessDenied />
  //       </>
  //     );
  //   }
  // if(targetGroupData!=null){
  //   if(Object.keys(targetGroupData).length!=0){
  const openDrawerCreate = () => {
    var copy_keys = document.querySelector("#copy_keys");
    if (copy_keys) {
      copy_keys.classList.add("d-none");
    }
    var drawerElement = document.querySelector("#create-target-modal");
    var drawer_overlay = document.querySelector("#drawer_overlay");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      drawerElement.style.width = "70%";
      drawer_overlay.classList.toggle("d-none");
    }
    const form = document.getElementById('targetGroup-add-form');
    if (form) {
      form.reset();  // This will reset all form fields to their default values
    }
  };

  const CreateGroup = async () => {
    var submitBtn = document.querySelector("#add-target-btn");
    var formData = new FormData(document.querySelector("#targetGroup-add-form"));
    for (let value of formData.values()) {
      if (value === "0") { // Check if any form field has a value of "0"
        toast.error("Form contains invalid values. Please ensure no field has a value of zero.");
        return; // Prevent form submission
      }
    }
    if (submitBtn) {
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      toast.dismiss();
      const dt = toast.loading("Please wait...");

      const responseData = await UsePost('post', 'targetgroup', 'targetGroup-add-form');
      if (responseData) {
        submitBtn.innerHTML = preContent;
        submitBtn.classList.remove("disabled");
        if (responseData.status === 'error') {
          toast.update(dt, {
            render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        } else if (responseData.status === 'success') {
          toast.update(dt, {
            render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          window.onDeployEvents('create_targetgroup');
          navigate("/targetgroup/" + responseData.id);
        }
      }
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Use regex to remove non-numeric characters (including minus sign and alphabet)
    const filteredValue = value.replace(/[^0-9]/g, '');

    setTargetGroup((prev) => ({
      ...prev,
      [name]: filteredValue,
    }));
  };

  if (dataFetched) {
    if (access) {
      return (
        <>
          <div className="col-xl-12">
            {/*begin::Tables Widget 9*/}
            <div className="card card-xl-stretch mb-5 mb-xl-12">
              {/*begin::Header*/}
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label  fw-bold fs-3 mb-1">
                    Target groups
                  </span>
                </h3>
                <div
                  className="card-toolbar"
                >
                  <div className="position-relative my-1" style={{ marginRight: 20 }}>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      data-kt-table-widget-4="search"
                      id="searchClient"
                      // value={searchTerm}
                      onChange={e => handleSearch(e.target.value)}
                      placeholder="Search"
                    />
                  </div>
                  <div style={{ marginRight: 20 }}>
                    <div className="dataTables_length">
                      <label className="w-125px">
                        <select
                          onChange={e => setPerPage(e.target.value)}
                          //data-control="select2"
                          className="form-select form-select-sm form-select-solid"
                          defaultValue={5}
                          id="perPage"

                        >
                          <option value={5} selected="">
                            5 Records
                          </option>
                          <option value={10}>10 Records</option>
                          <option value={25}>25 Records</option>
                          <option value={50}>50 Records</option>
                          <option value={100}>100 Records</option>
                        </select>
                      </label>
                    </div>
                  </div>
                  {props.showButton &&
                    <a className="btn btn-sm btn-primary" onClick={openDrawerCreate}>
                      <span className="svg-icon svg-icon-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <rect
                            opacity="0.5"
                            x="11.364"
                            y="20.364"
                            width={16}
                            height={2}
                            rx={1}
                            transform="rotate(-90 11.364 20.364)"
                            fill="black"
                          />
                          <rect x="4.36396" y="11.364" width={16} height={2} rx={1} fill="black" />
                        </svg>
                      </span>
                      Create Target Group</a>
                    //  </div>
                  }
                </div>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              {/*begin::Table container*/}
              <div className="table-responsive">
                {/*begin::Table*/}
                <table
                  id="loadbalancer"
                  className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
                >
                  {/*begin::Table head*/}
                  <thead className="bg-secondary">
                    <tr className=" fw-bold ">
                      {/* <th className="text-center" /> */}
                      <th className="text-start ps-9"> Name</th>
                      <th className="text-center">Targets</th>
                      <th className="text-center">Path</th>
                      <th className="text-center">Interval</th>
                      <th className="text-center">Timeout</th>
                      <th className="text-center">Network</th>
                      <th className="text-center sphide">Threshold</th>
                      <th className="text-center sphide">Created _at</th>
                      <th className="text-center"></th>
                    </tr>
                  </thead>
                  {/*end::Table head*/}
                  {/*begin::Table body*/}
                  <tbody>
                    {targetGroupData != null ? (<>
                      {access == false ? (<>
                        <tr>
                          <td colspan={12}>
                            <AccessDenied />
                          </td>
                        </tr>
                      </>) : (<>
                        {Object.keys(targetGroupData).length != 0 ? (<>
                          <PaginatedItems itemsPerPage={perPage} ProductData={dummyTargetGroupData} />
                        </>) : (<>
                          <tr>
                            <td colSpan={12}>
                              <div className="card-body p-0 mt-10">
                                {/*begin::Illustration*/}
                                <div className="text-center px-4 mt-10">
                                  <span className="mw-100 mh-300px">
                                    <svg
                                      width="234px"
                                      height="234px"
                                      fill="#21325B"
                                      viewBox="0 0 32 32"
                                      id="icon"
                                      xmlns="http://www.w3.org/2000/svg"
                                      stroke="#21325B"
                                    >
                                      <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                                      <g id="SVGRepo_iconCarrier">

                                        <defs>

                                          <style
                                            dangerouslySetInnerHTML={{ __html: " .cls-1 { fill: none; } " }}
                                          />
                                        </defs>
                                        <path d="M8,30H2V24H8ZM4,28H6V26H4Z" transform="translate(0 0)" />
                                        <path d="M19,30H13V24h6Zm-4-2h2V26H15Z" transform="translate(0 0)" />
                                        <path d="M30,30H24V24h6Zm-4-2h2V26H26Z" transform="translate(0 0)" />
                                        <path
                                          d="M16,10a4,4,0,1,1,4-4A4.0045,4.0045,0,0,1,16,10Zm0-6a2,2,0,1,0,2,2A2.002,2.002,0,0,0,16,4Z"
                                          transform="translate(0 0)"
                                        />
                                        <path
                                          d="M26,16H21a4.0045,4.0045,0,0,1-4-4H15a4.0045,4.0045,0,0,1-4,4H6a2.0023,2.0023,0,0,0-2,2v4H6V18h5A5.9694,5.9694,0,0,0,15,16.46V22h2V16.46A5.9694,5.9694,0,0,0,21,18h5v4h2V18A2.0023,2.0023,0,0,0,26,16Z"
                                          transform="translate(0 0)"
                                        />
                                      </g>
                                    </svg>
                                  </span>
                                </div>
                                {/*end::Illustration*/}
                                {/*begin::Wrapper*/}
                                <div className="card-px text-center py-5 mb-20">
                                  {/*begin::Title*/}
                                  <h1 className="fs-2x fw-bolder mb-5">Target Groups</h1>
                                  <h2 className="fs-1x fw-bolder mb-5 text-muted">
                                    Distribute your web traffic evenly
                                  </h2>
                                  {/*end::Title*/}
                                  {/*begin::Description*/}
                                  <p className="text-gray-400 fs-4 fw-bold mb-10">
                                    Ensure a seamless experience for your users even during high traffic peaks.              </p>
                                  {/*end::Description*/}
                                  {/*begin::Action*/}
                                  {/* <Link to="/loadbalancer/deploy"> */}
                                  <a
                                    href="#"
                                    className="btn btn-primary mb-10"
                                    onClick={openDrawerCreate}
                                  >
                                    Create Target Groups
                                  </a>
                                  {/* </Link> */}
                                  {/*end::Action*/}
                                </div>
                                {/*end::Wrapper*/}
                              </div>
                            </td>
                          </tr>
                        </>)}
                      </>)}
                    </>) : (<>
                      <tr>
                        <td colspan={12}>
                          <TableLoader />
                          <TopBarHeaderLine />
                        </td>
                      </tr>

                    </>)}

                  </tbody>
                  {/*end::Table body*/}
                </table>
                {/*end::Table*/}
              </div>
              {/*end::Table container*/}
              {/*begin::Body*/}
            </div>
            {/*end::Tables Widget 9*/}
          </div>
          <div id="drawer_overlay" onClick={openDrawerCreate} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>

          <div
            id="create-target-modal"
            className="bg-body shadow drawer drawer-end"
            data-kt-drawer="true"
            data-kt-drawer-name="chat"
            data-kt-drawer-activate="true"
            data-kt-drawer-direction="end"
            data-kt-drawer-close="#close_ssh"
            data-kt-drawer-width="{default:'650px', 'md': '650px', 'sm': '300px'}"
            style={{ maxWidth: "70%", important: true }}
          >
            <div className="card w-100 rounded-0 border-0" id="kt_drawer_chat_messenger">
              <div className="card-header pe-5" id="kt_drawer_chat_messenger_header">
                <div className="card-title">
                  <div className="d-flex justify-content-center flex-column me-3">
                    <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                      Create Target Group
                    </a>
                  </div>
                </div>
                <div className="card-toolbar">
                  <div onClick={openDrawerCreate} className="btn btn-sm btn-icon btn-active-light-primary">
                    <span className="svg-icon svg-icon-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.5"
                          x={6}
                          y="17.3137"
                          width={16}
                          height={2}
                          rx={1}
                          transform="rotate(-45 6 17.3137)"
                          fill="black"
                        />
                        <rect
                          x="7.41422"
                          y={6}
                          width={16}
                          height={2}
                          rx={1}
                          transform="rotate(45 7.41422 6)"
                          fill="black"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
              <div className="" id="">
                <form id="targetGroup-add-form">
                  <div className="modal-body ">
                    <div className="scroll-y me-n7 pe-7 row">
                      <div className="col-lg-4 col-md-4 col-xl-4">
                        <div className="mb-5 fv-row">
                          <label htmlFor="type" className="required fs-5 fw-bold mb-2">
                            Name
                          </label>
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            defaultValue="Nginx"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-xl-4">
                        <div className="mb-5 fv-row">
                          <label htmlFor="type" className="required fs-5 fw-bold mb-2">

                            Protocol
                          </label>
                          <select className="form-select" name="protocol" defaultValue="HTTP">
                            <option value="HTTP">HTTP</option>
                            <option value="HTTPS">HTTPS</option>
                            <option value="TCP">TCP</option>
                            <option value="UDP">UDP</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-xl-4">
                        <div className="mb-5 fv-row">
                          <label htmlFor="type" className="required fs-5 fw-bold mb-2" >
                            Port
                          </label>
                          <input
                            type="text"
                            name="port"
                            className="form-control"
                            // defaultValue="80"
                            value={targetGroup.port}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3 col-xl-3 d-none">
                        <div className="mb-5 fv-row">
                          <label htmlFor="type" className="required fs-5 fw-bold mb-2" >

                            HTTP path
                          </label>
                          <input
                            type="number"
                            name="httpPath"
                            className="form-control"
                            defaultValue="/"
                          />
                        </div>
                      </div>
                      <div className="col-lg-8 col-md-8 col-xl-8">
                        <div className="mb-5 fv-row">
                          <label htmlFor="type" className="required fs-5 fw-bold mb-2">

                            Health Check Path
                          </label>
                          <input
                            type="url"
                            name="health_check_path"
                            className="form-control"
                            defaultValue="/"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-xl-4">
                        <div className="mb-5 fv-row">
                          <label htmlFor="type" className="required fs-5 fw-bold mb-2 sphide">
                            Health Check Protocol
                          </label>
                          <select className="form-select" name="health_check_protocol" defaultValue="HTTP">
                            <option value="HTTP">HTTP</option>
                            <option value="HTTPS">HTTPS</option>
                            <option value="TCP">TCP</option>
                            <option value="UDP">UDP</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3 col-xl-3">
                        <div className="mb-5 fv-row">
                          <label htmlFor="type" className="required fs-5 fw-bold mb-2">

                            Health Check Interval
                          </label>
                          <input
                            type="text"
                            name="health_check_interval"
                            className="form-control"
                            // defaultValue="300"
                            value={targetGroup.health_check_interval}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3 col-xl-3">
                        <div className="mb-5 fv-row">
                          <label htmlFor="type" className="required fs-5 fw-bold mb-2">

                            Health Check Timeout
                          </label>
                          <input
                            type="text"
                            name="health_check_timeout"
                            className="form-control"
                            // defaultValue="300"
                            value={targetGroup.health_check_timeout}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3 col-xl-3">
                        <div className="mb-5 fv-row">
                          <label htmlFor="type" className="required fs-5 fw-bold mb-2">

                            Healthy Threshold
                          </label>
                          <input
                            type="text"
                            name="healthy_threshold"
                            className="form-control"
                            // defaultValue="300"
                            value={targetGroup.healthy_threshold}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3 col-xl-3">
                        <div className="mb-5 fv-row">
                          <label htmlFor="type" className="required fs-5 fw-bold mb-2">

                            Unhealthy Threshold
                          </label>
                          <input
                            type="text"
                            name="unhealthy_threshold"
                            className="form-control"
                            // defaultValue="300"
                            value={targetGroup.unhealthy_threshold}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12 col-xl-12 mt-10">
                        <div className="mb-5 fv-row">
                          <a onClick={CreateGroup} className="btn btn-primary w-100" id="add-target-btn">Create Target group</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

          </div>
        </>
      )
    } else {
      return (
        <>
          <AccessDenied />
        </>
      )
    }
  } else {
    return (
      <>
        <TableLoader />
      </>
    )
  }
} 