import localStorage from "local-storage";

var baseUrl="https://utho.com/docs";
const platformName=localStorage.get("platformType");
if(platformName){
  if(platformName=="dev"){
  //  baseUrl= "http://103.127.30.65:1313";
   baseUrl="https://utho.com/docs";
  }
}

export const ProductsUrl = [{
    'cloud': baseUrl+"/products/compute/cloud",
    'kubernetes': baseUrl+"/products/compute/kubernetes",
    'ssl': baseUrl+'/products/compute/ssl-certificate',
    'stack': baseUrl+'/products/compute/stack',
    'databases': baseUrl+"/products/databases",
    'loadbalancer': baseUrl+"/products/networking/loadbalancer",
    'target_group':  baseUrl+"/products/networking/target-group",
    'vpc': baseUrl+"/products/networking/vpc",
    'vpn': baseUrl+"/products/networking/vpn",
    'firewall': baseUrl+"/products/networking/firewall",
    'dns': baseUrl+"/products/networking/dns-management", 
    'monitoring': baseUrl+'/products/networking/monitoring', 
    'object_stroage': baseUrl+'/products/storage/object-storage',
    'container_registry': baseUrl+'/products/storage/container-registry',
    'sqs': baseUrl+'/products/storage/sqs',  
    'iso': baseUrl+'/products/images/iso',
    'backups': baseUrl+'/products/images/backups',
    'snapshots': baseUrl+'/products/images/snapshots', 
    'active_session': baseUrl+'/products/account/active-session',
    'activity_logs': baseUrl+'/products/account/activity-logs',
    'api_token': baseUrl+'/products/account/api-token',
    'subuser':  baseUrl+"/products/account/subuser",
    'transfer':  baseUrl+"/products/account/resource-transfer",
    'ticket': baseUrl+'/products/ticket',
  }]

export default ProductsUrl;