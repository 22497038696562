import React, { useEffect, useRef, useState } from "react";

export default function InstanceTypeCloud(props) {
    return (
        <div className="col-xl-12 card mb-5">
            <div className=" h-lg-100 mb-5">
                <div className="card-header border-3 mb-5 pt-2">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bolder text-gray-800">Instance Type</span>
                        <span className="text-gray-400 mt-1 fw-bold fs-6">
                            Cloud Server
                        </span>
                    </h3>
                </div>
                <div className="container-fluid">
                    <div className="border-3 mb-5 pt-2">
                        <div className="col-xl-12  mb-5" id="application-lb-sec">
                            <div className="pb-5">
                                <label htmlFor="targetgroups" className="form-label">
                                    Cloud Server
                                </label>
                                <select className="form-select form-select-sm" disabled={props.cloudData.length <= 0} style={{cursor:"pointer"}} onChange={(e) => {
                                props.getKubernetesById(e.target.value)
                            }}>
                                 <option value="0">Choose Cloud Instance</option>
                                {props.cloudData.length > 0 && props.cloudData.map((key, index) =>
                                    <option value={key.cloudid}>{key.hostname}</option>
                                )}
                            </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}