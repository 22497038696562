import React, { useEffect, useRef, useState } from "react";
import 'react-toastify/dist/ReactToastify.css';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UseApi from "../../../CustomHooks/useApi";
import UsePost from "../../../CustomHooks/usePost";

export default function JoinUs() {
  const [userData, setUserData] = useState(null);
  var query = window.location.href;
  var vars = query.split("/");
  var productName = vars[3];
  async function getUserInfo() {
    const user_data = await UseApi('get', 'account/info', '');
    if (user_data) {
      setUserData(user_data.user);
    }
  }
  useEffect(() => {
    getUserInfo();
  }, []);

  function requestWaitingList() {
    swal({
      title: "Join Waiting List",
      text: "As this product in beta phase, so may be you have to wait to gain the access of this product.",
      icon: "info",
      buttons: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          if (userData != null) {
            var requestArr = []
            const dt = toast.loading("processing request...");
            const dnsp = await UsePost('post', 'process/start', 'start_fms');

            if (dnsp.status == 'error') {
              toast.update(dt, {
                render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
              });
            } else {
              toast.update(dt, {
                render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
              });
            }
          }
        }

      })
  }


  return (
    <>
      <form id="start_fms" encType="multipart/form-data" className="form fms">
        <input type="hidden" name="field[800]" value={userData != null ? (userData.id) : ('')} />
        <input type="hidden" name="field[801]" value={userData != null ? (userData.fullname) : ('')} />
        <input type="hidden" name="field[802]" value={productName.charAt(0).toUpperCase() + productName.slice(1)} />
        <input type="hidden" name="templateid" value="Product-Waiting-List" />


      </form>
      <div className="col-xl-12">
        <div className="card">
          {/*begin::Card body*/}
          <div className="card-body p-0 mt-10">
            {/*begin::Illustration*/}
            <div className="text-center px-4 mt-10">
              <span className="mw-100 mh-300px">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="240px"
                  height="240px"
                  viewBox="0 0 24 24"
                  fill="#21325B"
                >
                  <path
                    d="M16.0173 9H15.3945C14.2833 9 13.263 9.61425 12.7431 10.5963L12.154 11.7091C12.0645 11.8781 12.1072 12.0868 12.2559 12.2071L12.6402 12.5183C13.2631 13.0225 13.7556 13.6691 14.0764 14.4035L14.2321 14.7601C14.2957 14.9058 14.4396 15 14.5987 15H18.6747C19.7297 15 20.4057 13.8774 19.912 12.945L18.6686 10.5963C18.1487 9.61425 17.1285 9 16.0173 9Z"
                    fill="#21325B"
                  />
                  <rect opacity="0.3" x={14} y={4} width={4} height={4} rx={2} fill="black" />
                  <path
                    d="M4.65486 14.8559C5.40389 13.1224 7.11161 12 9 12C10.8884 12 12.5961 13.1224 13.3451 14.8559L14.793 18.2067C15.3636 19.5271 14.3955 21 12.9571 21H5.04292C3.60453 21 2.63644 19.5271 3.20698 18.2067L4.65486 14.8559Z"
                    fill="#21325B"
                  />
                  <rect opacity="0.3" x={6} y={5} width={6} height={6} rx={3} fill="black" />
                </svg>


              </span>

            </div>
            {/*end::Illustration*/}
            {/*begin::Wrapper*/}
            <div className="card-px text-center py-5 mb-20">
              {/*begin::Title*/}
              <h1 className="fs-2x fw-bolder mb-5">Join Our Waiting List</h1>
              {/*end::Title*/}
              {/*begin::Description*/}
              <p className="text-gray-400 fs-4 fw-bold mb-10">
                This product is currently in beta phase,<br /> join our waiting list for production launch.
              </p>
              {/*end::Description*/}
              {/*begin::Action*/}

              <a

                onClick={requestWaitingList}
                className="btn btn-primary mb-10"
              >
                Request For Access
              </a>
              {/*end::Action*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Card body*/}
        </div>

      </div>
    </>
  )

}