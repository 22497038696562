import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Search from "../../../../utils/search/Search";

function Backups(props) {
  const { backupData, selectBackup, backupid, os_disk_size } = props;

  useEffect(() => {
    if (backupData === undefined) throw new Error("Missing prop: backupData");
    if (backupid === undefined) throw new Error("Missing prop: backupid");
    if (os_disk_size === undefined) throw new Error("Missing prop: os_disk_size");
    if (selectBackup === undefined)
      throw new Error("Missing prop: selectBackup");
  }, [backupData, selectBackup]);

  const [backupList, setBackupList] = useState(backupData);

  useEffect(()=>{
    if(backupid){
      selectBackup(backupid, os_disk_size);
    }
  },[backupList])
  return (
    <>
      {backupList != null ? (
        <>
         <Search results={backupData} onSearch={setBackupList} fields={['id']} searchPlaceholder="backup" />
          {backupList.length != 0 ? (
            <>
              {backupList.map((value, index) => (
                <>
                  <div className="col-md-4 col-lg-12 col-xxl-4">
                    {/*begin::Option*/}
                    <input
                      className="btn-check deploy_checkbox backup_radio"
                      type="radio"
                      name="image_OS"
                      defaultValue={value.id}
                      id={"backupid" + value.id}
                    />
                    <label
                      onClick={(e) =>
                        selectBackup(value.id, value.size)
                      }
                      htmlFor={"backupid" + value.id}
                      className=" label versionOs text-start border-1 border-gray-300 border px-6 py-1 d-flex"
                      style={{
                        height: "73.06px !important",
                      }}
                    >
                      {/*begin::Svg Icon | path: icons/duotune/communication/com003.svg*/}
                      <span className="svg-icon svg-icon-muted svg-icon-4x me-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            opacity="0.3"
                            d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM6.39999 9.89999C6.99999 8.19999 8.40001 6.9 10.1 6.4C10.6 6.2 10.9 5.7 10.7 5.1C10.5 4.6 9.99999 4.3 9.39999 4.5C7.09999 5.3 5.29999 7 4.39999 9.2C4.19999 9.7 4.5 10.3 5 10.5C5.1 10.5 5.19999 10.6 5.39999 10.6C5.89999 10.5 6.19999 10.2 6.39999 9.89999ZM14.8 19.5C17 18.7 18.8 16.9 19.6 14.7C19.8 14.2 19.5 13.6 19 13.4C18.5 13.2 17.9 13.5 17.7 14C17.1 15.7 15.8 17 14.1 17.6C13.6 17.8 13.3 18.4 13.5 18.9C13.6 19.3 14 19.6 14.4 19.6C14.5 19.6 14.6 19.6 14.8 19.5Z"
                            fill="black"
                          />
                          <path
                            d="M16 12C16 14.2 14.2 16 12 16C9.8 16 8 14.2 8 12C8 9.8 9.8 8 12 8C14.2 8 16 9.8 16 12ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}
                      <span className="d-block fw-bold text-start py-2 sphide">
                        <span className="text-dark fw-bolder d-block fs-6 sphide2">
                          {value.id}
                        </span>
                        <span className="text-muted fw-bold fs-7">
                          {" "}
                          {value.created_at}
                        </span>
                      </span>
                      {/*begin::Ribbon*/}
                      <div
                        className="d-none tickMarkbackup ribbon ribbon-triangle ribbon-top-start border-primary"
                        id={"tickMarkbackup-" + value.id}
                      >
                        {/*begin::Ribbon icon*/}
                        <div className="ribbon-icon mt-n5">
                          <i className="bi bi-check2 fs-2 text-white" />
                        </div>
                        {/*end::Ribbon icon*/}
                      </div>
                      {/*end::Ribbon*/}
                    </label>
                    {/*end::Option*/}
                  </div>
                </>
              ))}
            </>
          ) : (
            <>
              <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                {/*begin::Wrapper*/}

                <div className="pt-lg-10 mb-10">
                  {/*begin::Message*/}
                  <div className="fw-bold fs-5  mb-15 badge badge-light-primary">
                    You don't have any Backups on this location
                  </div>
                  {/*end::Message*/}
                </div>
                {/*end::Wrapper*/}
              </div>
            </>
          )}
        </>
      ) : (
        <>
          {/*begin::Content*/}
          <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
            {/*begin::Wrapper*/}
            <div className="pt-lg-10 mb-10">
              {/*begin::Message*/}
              <div className="fw-bold fs-5  mb-15 badge badge-light-primary">
                Please select Datacenter Location to view backup list.
              </div>
              {/*end::Message*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Content*/}
        </>
      )}
    </>
  );
}

Backups.propTypes = {
  backupData: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.oneOf([null]),
  ]).isRequired,
  selectBackup: PropTypes.func.isRequired,
};

export default Backups;
