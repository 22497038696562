import {React} from 'react';

export default function Paragraph(props){
    if(props && props.description){
    return(
        <><div style={{ paddingBottom: 30 }}>
        {props.description}
      </div>
      
      <h1
  style={{
    fontSize: 20,
    lineHeight: 4,
    fontFamily: '"Helvetica", Arial, sans-serif',
    fontWeight: 600,
    textDecoration: "none",
    color: "#efb329"
  }}
>
  Resign GSM wine ($35 value)
</h1>

        </>
    )
  }
}