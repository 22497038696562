import React, { useEffect, useState } from 'react';
import { Line, Area } from '@ant-design/plots';
const Cpu = (props) => {

 
function sortByDateAscending(array) {
  array.sort((a, b) => new Date(a.Date) - new Date(b.Date));
  return array;
}
const CpuChart=()=>{
  const [data, setData] = useState([]);

  useEffect(() => { 
    asyncFetch();
  }, []);

  const asyncFetch = () => {
    if(props){
      if(props.chartInfo){
        if(props.chartInfo.load){
          ////console.log(props.chartInfo);
         var data=props.chartInfo.cpu;
        //  var cpu_data=props.chartInfo.cpu;
        //  console.log("cpu data");
        // console.log(data);

         var loadData=[]; 
         if(data){ 
          // for(var i=0;i<data.length;i++){ 
            var rdata=Object.entries(data);
            for(var j=0;j<rdata.length;j++){ 
              // console.log(Object.entries(rdata[j][1])); 
              Object.entries(rdata[j][1]).forEach((value)=>{
                // console.log(value[1].user);  
              loadData.push({Date:value[1].date, value:value[1]['guest'], category:'guest'});
              loadData.push({Date:value[1].date, value:value[1]['guestnice'], category:'guestnice'});
              loadData.push({Date:value[1].date, value:value[1]['idle'], category:'idle'});
              loadData.push({Date:value[1].date, value:value[1]['iowait'], category:'iowait'});
              loadData.push({Date:value[1].date, value:value[1]['irq'], category:'irq'});
              loadData.push({Date:value[1].date, value:value[1]['nice'], category:'nice'});
              loadData.push({Date:value[1].date, value:value[1]['softirq'], category:'softirq'});
              loadData.push({Date:value[1].date, value:value[1]['steal'], category:'steal'});
              loadData.push({Date:value[1].date, value:value[1]['system'], category:'system'});
              loadData.push({Date:value[1].date, value:value[1]['usage'], category:'usage'});
              loadData.push({Date:value[1].date, value:value[1]['user'], category:'user'});
            }) 
          }
          // }  
          
           ////console.log(netData);
          var dataLoad=sortByDateAscending(loadData);
    
          console.log(dataLoad);
          setData(dataLoad);
         }
        }
      }
    } 
  };
  const config5 = {
    key:'area5',
    data:data,
    xField: 'Date',
    yField: 'value',
    seriesField: 'category',
    color: ['#6897a7', '#60d7a7', '#12799C'],
    xAxis: {
      type: 'time',
       tickCount: 5,
      tickInterval: 1000 * 60 * 60 , // one day interval 
      mask: 'HH:mm:ss A', // time format
    },
    yAxis: {
      label: {
        formatter: (v) => `${v} %`,
      },
      tickCount: 6,
    }, 
    tooltip: {
      customContent: (date, items) => { 
        if (items.length > 0) {
          const guest = items.find(item => item.data.category === 'guest').data;
          const guestnice = items.find(item => item.data.category === 'guestnice').data;
          const idle = items.find(item => item.data.category === 'idle').data;
          const iowait = items.find(item => item.data.category === 'iowait').data;
          const irq = items.find(item => item.data.category === 'irq').data;
          const nice = items.find(item => item.data.category === 'nice').data;
          const softirq = items.find(item => item.data.category === 'softirq').data;
          const steal = items.find(item => item.data.category === 'steal').data;
          const system = items.find(item => item.data.category === 'system').data;
          const usage = items.find(item => item.data.category === 'usage').data;
          const user = items.find(item => item.data.category === 'user').data; 

    
          // ... (other code)
              const now = new Date(items[0].data.Date);
              const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
              const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
              const dayOfWeek = daysOfWeek[now.getDay()];
              const month = months[now.getMonth()];
              const dayOfMonth = now.getDate().toString().padStart(2, '0');
              const year = now.getFullYear();
              const hours = now.getHours() % 12 || 12;
              const minutes = now.getMinutes().toString().padStart(2, '0');
              const seconds = now.getSeconds().toString().padStart(2, '0');
              const ampm = now.getHours() >= 12 ? 'pm' : 'am';
              const formattedDate = `${dayOfWeek} ${month} ${dayOfMonth} ${year}`;
              const formattedTime= `${hours}:${minutes}:${seconds} ${ampm}`;
                return `<div class="p-5"> 
        <div class="d-flex fs-6 fw-bold align-items-center mb-2">  
        <div class=" me-3">
        <span class="svg-icon svg-icon-muted svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path opacity="0.3" d="M21 22H3C2.4 22 2 21.6 2 21V5C2 4.4 2.4 4 3 4H21C21.6 4 22 4.4 22 5V21C22 21.6 21.6 22 21 22Z" fill="black"/>
        <path d="M6 6C5.4 6 5 5.6 5 5V3C5 2.4 5.4 2 6 2C6.6 2 7 2.4 7 3V5C7 5.6 6.6 6 6 6ZM11 5V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V5C9 5.6 9.4 6 10 6C10.6 6 11 5.6 11 5ZM15 5V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V5C13 5.6 13.4 6 14 6C14.6 6 15 5.6 15 5ZM19 5V3C19 2.4 18.6 2 18 2C17.4 2 17 2.4 17 3V5C17 5.6 17.4 6 18 6C18.6 6 19 5.6 19 5Z" fill="black"/>
        <path d="M8.8 13.1C9.2 13.1 9.5 13 9.7 12.8C9.9 12.6 10.1 12.3 10.1 11.9C10.1 11.6 10 11.3 9.8 11.1C9.6 10.9 9.3 10.8 9 10.8C8.8 10.8 8.59999 10.8 8.39999 10.9C8.19999 11 8.1 11.1 8 11.2C7.9 11.3 7.8 11.4 7.7 11.6C7.6 11.8 7.5 11.9 7.5 12.1C7.5 12.2 7.4 12.2 7.3 12.3C7.2 12.4 7.09999 12.4 6.89999 12.4C6.69999 12.4 6.6 12.3 6.5 12.2C6.4 12.1 6.3 11.9 6.3 11.7C6.3 11.5 6.4 11.3 6.5 11.1C6.6 10.9 6.8 10.7 7 10.5C7.2 10.3 7.49999 10.1 7.89999 10C8.29999 9.90003 8.60001 9.80003 9.10001 9.80003C9.50001 9.80003 9.80001 9.90003 10.1 10C10.4 10.1 10.7 10.3 10.9 10.4C11.1 10.5 11.3 10.8 11.4 11.1C11.5 11.4 11.6 11.6 11.6 11.9C11.6 12.3 11.5 12.6 11.3 12.9C11.1 13.2 10.9 13.5 10.6 13.7C10.9 13.9 11.2 14.1 11.4 14.3C11.6 14.5 11.8 14.7 11.9 15C12 15.3 12.1 15.5 12.1 15.8C12.1 16.2 12 16.5 11.9 16.8C11.8 17.1 11.5 17.4 11.3 17.7C11.1 18 10.7 18.2 10.3 18.3C9.9 18.4 9.5 18.5 9 18.5C8.5 18.5 8.1 18.4 7.7 18.2C7.3 18 7 17.8 6.8 17.6C6.6 17.4 6.4 17.1 6.3 16.8C6.2 16.5 6.10001 16.3 6.10001 16.1C6.10001 15.9 6.2 15.7 6.3 15.6C6.4 15.5 6.6 15.4 6.8 15.4C6.9 15.4 7.00001 15.4 7.10001 15.5C7.20001 15.6 7.3 15.6 7.3 15.7C7.5 16.2 7.7 16.6 8 16.9C8.3 17.2 8.6 17.3 9 17.3C9.2 17.3 9.5 17.2 9.7 17.1C9.9 17 10.1 16.8 10.3 16.6C10.5 16.4 10.5 16.1 10.5 15.8C10.5 15.3 10.4 15 10.1 14.7C9.80001 14.4 9.50001 14.3 9.10001 14.3C9.00001 14.3 8.9 14.3 8.7 14.3C8.5 14.3 8.39999 14.3 8.39999 14.3C8.19999 14.3 7.99999 14.2 7.89999 14.1C7.79999 14 7.7 13.8 7.7 13.7C7.7 13.5 7.79999 13.4 7.89999 13.2C7.99999 13 8.2 13 8.5 13H8.8V13.1ZM15.3 17.5V12.2C14.3 13 13.6 13.3 13.3 13.3C13.1 13.3 13 13.2 12.9 13.1C12.8 13 12.7 12.8 12.7 12.6C12.7 12.4 12.8 12.3 12.9 12.2C13 12.1 13.2 12 13.6 11.8C14.1 11.6 14.5 11.3 14.7 11.1C14.9 10.9 15.2 10.6 15.5 10.3C15.8 10 15.9 9.80003 15.9 9.70003C15.9 9.60003 16.1 9.60004 16.3 9.60004C16.5 9.60004 16.7 9.70003 16.8 9.80003C16.9 9.90003 17 10.2 17 10.5V17.2C17 18 16.7 18.4 16.2 18.4C16 18.4 15.8 18.3 15.6 18.2C15.4 18.1 15.3 17.8 15.3 17.5Z" fill="black"/>
        </svg></span></div> 
        <div class="text-gray-500 flex-grow-1 me-4">Date :</div> 
        <div class="fw-boldest text-gray-700 text-xxl-end">${formattedDate}</div> 
      </div>
      <div class="d-flex fs-6 fw-bold align-items-center mb-2"> 
        <div class="me-3">
        <span class="svg-icon svg-icon-muted svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path opacity="0.3" d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z" fill="black"/>
        <path d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z" fill="black"/>
        </svg></span>
        </div> 
        <div class="text-gray-500 flex-grow-1 me-4">Time :</div> 
        <div class="fw-boldest text-gray-700 text-xxl-end">${formattedTime}</div> 
      </div>
      <div class="d-flex fs-6 fw-bold align-items-center mb-2"> 
      <div class="me-3">
      <span class="svg-icon svg-icon-primary svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path opacity="0.3" d="M21 13H15V11H21C21.6 11 22 10.6 22 10C22 9.4 21.6 9 21 9H15V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V9H11V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V9H3C2.4 9 2 9.4 2 10C2 10.6 2.4 11 3 11H9V13H3C2.4 13 2 13.4 2 14C2 14.6 2.4 15 3 15H9V21C9 21.6 9.4 22 10 22C10.6 22 11 21.6 11 21V15H13V21C13 21.6 13.4 22 14 22C14.6 22 15 21.6 15 21V15H21C21.6 15 22 14.6 22 14C22 13.4 21.6 13 21 13Z" fill="black"/>
      <path d="M16 17H8C7.4 17 7 16.6 7 16V8C7 7.4 7.4 7 8 7H16C16.6 7 17 7.4 17 8V16C17 16.6 16.6 17 16 17ZM14 10H10V14H14V10Z" fill="black"/>
      </svg></span>
      </div> 
      <div class="text-gray-500 flex-grow-1 me-4">guest :</div> 
      <div class="fw-boldest text-gray-700 text-xxl-end">${guest.value} </div> 
    </div>
    <div class="d-flex fs-6 fw-bold align-items-center"> 
      <div class="me-3">
        <span class="svg-icon svg-icon-success svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path opacity="0.3" d="M21 13H15V11H21C21.6 11 22 10.6 22 10C22 9.4 21.6 9 21 9H15V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V9H11V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V9H3C2.4 9 2 9.4 2 10C2 10.6 2.4 11 3 11H9V13H3C2.4 13 2 13.4 2 14C2 14.6 2.4 15 3 15H9V21C9 21.6 9.4 22 10 22C10.6 22 11 21.6 11 21V15H13V21C13 21.6 13.4 22 14 22C14.6 22 15 21.6 15 21V15H21C21.6 15 22 14.6 22 14C22 13.4 21.6 13 21 13Z" fill="black"/>
        <path d="M16 17H8C7.4 17 7 16.6 7 16V8C7 7.4 7.4 7 8 7H16C16.6 7 17 7.4 17 8V16C17 16.6 16.6 17 16 17ZM14 10H10V14H14V10Z" fill="black"/>
        </svg>
        </span>
      </div> 
      <div class="text-gray-500 flex-grow-1 me-4">guestnice :</div> 
      <div class="fw-boldest text-gray-700 text-xxl-end">${guestnice.value}</div> 
    </div>
    <div class="d-flex fs-6 fw-bold align-items-center"> 
      <div class="me-3">
        <span class="svg-icon svg-icon-info svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path opacity="0.3" d="M21 13H15V11H21C21.6 11 22 10.6 22 10C22 9.4 21.6 9 21 9H15V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V9H11V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V9H3C2.4 9 2 9.4 2 10C2 10.6 2.4 11 3 11H9V13H3C2.4 13 2 13.4 2 14C2 14.6 2.4 15 3 15H9V21C9 21.6 9.4 22 10 22C10.6 22 11 21.6 11 21V15H13V21C13 21.6 13.4 22 14 22C14.6 22 15 21.6 15 21V15H21C21.6 15 22 14.6 22 14C22 13.4 21.6 13 21 13Z" fill="black"/>
        <path d="M16 17H8C7.4 17 7 16.6 7 16V8C7 7.4 7.4 7 8 7H16C16.6 7 17 7.4 17 8V16C17 16.6 16.6 17 16 17ZM14 10H10V14H14V10Z" fill="black"/>
        </svg>
        </span>
      </div> 
      <div class="text-gray-500 flex-grow-1 me-4">idle :</div> 
      <div class="fw-boldest text-gray-700 text-xxl-end">${idle.value}</div> 
    </div>
    <div class="d-flex fs-6 fw-bold align-items-center"> 
      <div class="me-3">
        <span class="svg-icon svg-icon-warning svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path opacity="0.3" d="M21 13H15V11H21C21.6 11 22 10.6 22 10C22 9.4 21.6 9 21 9H15V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V9H11V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V9H3C2.4 9 2 9.4 2 10C2 10.6 2.4 11 3 11H9V13H3C2.4 13 2 13.4 2 14C2 14.6 2.4 15 3 15H9V21C9 21.6 9.4 22 10 22C10.6 22 11 21.6 11 21V15H13V21C13 21.6 13.4 22 14 22C14.6 22 15 21.6 15 21V15H21C21.6 15 22 14.6 22 14C22 13.4 21.6 13 21 13Z" fill="black"/>
        <path d="M16 17H8C7.4 17 7 16.6 7 16V8C7 7.4 7.4 7 8 7H16C16.6 7 17 7.4 17 8V16C17 16.6 16.6 17 16 17ZM14 10H10V14H14V10Z" fill="black"/>
        </svg>
        </span>
      </div> 
      <div class="text-gray-500 flex-grow-1 me-4">iowait :</div> 
      <div class="fw-boldest text-gray-700 text-xxl-end">${iowait.value}</div> 
    </div>
    <div class="d-flex fs-6 fw-bold align-items-center"> 
      <div class="me-3">
        <span class="svg-icon svg-icon-secondary svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path opacity="0.3" d="M21 13H15V11H21C21.6 11 22 10.6 22 10C22 9.4 21.6 9 21 9H15V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V9H11V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V9H3C2.4 9 2 9.4 2 10C2 10.6 2.4 11 3 11H9V13H3C2.4 13 2 13.4 2 14C2 14.6 2.4 15 3 15H9V21C9 21.6 9.4 22 10 22C10.6 22 11 21.6 11 21V15H13V21C13 21.6 13.4 22 14 22C14.6 22 15 21.6 15 21V15H21C21.6 15 22 14.6 22 14C22 13.4 21.6 13 21 13Z" fill="black"/>
        <path d="M16 17H8C7.4 17 7 16.6 7 16V8C7 7.4 7.4 7 8 7H16C16.6 7 17 7.4 17 8V16C17 16.6 16.6 17 16 17ZM14 10H10V14H14V10Z" fill="black"/>
        </svg>
        </span>
      </div> 
      <div class="text-gray-500 flex-grow-1 me-4">irq :</div> 
      <div class="fw-boldest text-gray-700 text-xxl-end">${irq.value}</div> 
    </div>
    <div class="d-flex fs-6 fw-bold align-items-center"> 
      <div class="me-3">
        <span class="svg-icon svg-icon-info svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path opacity="0.3" d="M21 13H15V11H21C21.6 11 22 10.6 22 10C22 9.4 21.6 9 21 9H15V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V9H11V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V9H3C2.4 9 2 9.4 2 10C2 10.6 2.4 11 3 11H9V13H3C2.4 13 2 13.4 2 14C2 14.6 2.4 15 3 15H9V21C9 21.6 9.4 22 10 22C10.6 22 11 21.6 11 21V15H13V21C13 21.6 13.4 22 14 22C14.6 22 15 21.6 15 21V15H21C21.6 15 22 14.6 22 14C22 13.4 21.6 13 21 13Z" fill="black"/>
        <path d="M16 17H8C7.4 17 7 16.6 7 16V8C7 7.4 7.4 7 8 7H16C16.6 7 17 7.4 17 8V16C17 16.6 16.6 17 16 17ZM14 10H10V14H14V10Z" fill="black"/>
        </svg>
        </span>
      </div> 
      <div class="text-gray-500 flex-grow-1 me-4">nice :</div> 
      <div class="fw-boldest text-gray-700 text-xxl-end">${nice.value}</div> 
    </div>
    <div class="d-flex fs-6 fw-bold align-items-center"> 
      <div class="me-3">
        <span class="svg-icon svg-icon-warning svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path opacity="0.3" d="M21 13H15V11H21C21.6 11 22 10.6 22 10C22 9.4 21.6 9 21 9H15V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V9H11V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V9H3C2.4 9 2 9.4 2 10C2 10.6 2.4 11 3 11H9V13H3C2.4 13 2 13.4 2 14C2 14.6 2.4 15 3 15H9V21C9 21.6 9.4 22 10 22C10.6 22 11 21.6 11 21V15H13V21C13 21.6 13.4 22 14 22C14.6 22 15 21.6 15 21V15H21C21.6 15 22 14.6 22 14C22 13.4 21.6 13 21 13Z" fill="black"/>
        <path d="M16 17H8C7.4 17 7 16.6 7 16V8C7 7.4 7.4 7 8 7H16C16.6 7 17 7.4 17 8V16C17 16.6 16.6 17 16 17ZM14 10H10V14H14V10Z" fill="black"/>
        </svg>
        </span>
      </div> 
      <div class="text-gray-500 flex-grow-1 me-4">softirq :</div> 
      <div class="fw-boldest text-gray-700 text-xxl-end">${softirq.value}</div> 
    </div>
    <div class="d-flex fs-6 fw-bold align-items-center"> 
      <div class="me-3">
        <span class="svg-icon svg-icon-primary svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path opacity="0.3" d="M21 13H15V11H21C21.6 11 22 10.6 22 10C22 9.4 21.6 9 21 9H15V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V9H11V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V9H3C2.4 9 2 9.4 2 10C2 10.6 2.4 11 3 11H9V13H3C2.4 13 2 13.4 2 14C2 14.6 2.4 15 3 15H9V21C9 21.6 9.4 22 10 22C10.6 22 11 21.6 11 21V15H13V21C13 21.6 13.4 22 14 22C14.6 22 15 21.6 15 21V15H21C21.6 15 22 14.6 22 14C22 13.4 21.6 13 21 13Z" fill="black"/>
        <path d="M16 17H8C7.4 17 7 16.6 7 16V8C7 7.4 7.4 7 8 7H16C16.6 7 17 7.4 17 8V16C17 16.6 16.6 17 16 17ZM14 10H10V14H14V10Z" fill="black"/>
        </svg>
        </span>
      </div> 
      <div class="text-gray-500 flex-grow-1 me-4">steal :</div> 
      <div class="fw-boldest text-gray-700 text-xxl-end">${steal.value}</div> 
    </div>
    <div class="d-flex fs-6 fw-bold align-items-center"> 
      <div class="me-3">
        <span class="svg-icon svg-icon-success svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path opacity="0.3" d="M21 13H15V11H21C21.6 11 22 10.6 22 10C22 9.4 21.6 9 21 9H15V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V9H11V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V9H3C2.4 9 2 9.4 2 10C2 10.6 2.4 11 3 11H9V13H3C2.4 13 2 13.4 2 14C2 14.6 2.4 15 3 15H9V21C9 21.6 9.4 22 10 22C10.6 22 11 21.6 11 21V15H13V21C13 21.6 13.4 22 14 22C14.6 22 15 21.6 15 21V15H21C21.6 15 22 14.6 22 14C22 13.4 21.6 13 21 13Z" fill="black"/>
        <path d="M16 17H8C7.4 17 7 16.6 7 16V8C7 7.4 7.4 7 8 7H16C16.6 7 17 7.4 17 8V16C17 16.6 16.6 17 16 17ZM14 10H10V14H14V10Z" fill="black"/>
        </svg>
        </span>
      </div> 
      <div class="text-gray-500 flex-grow-1 me-4">system :</div> 
      <div class="fw-boldest text-gray-700 text-xxl-end">${system.value}</div> 
    </div>
    <div class="d-flex fs-6 fw-bold align-items-center"> 
      <div class="me-3">
        <span class="svg-icon svg-icon-secondary svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path opacity="0.3" d="M21 13H15V11H21C21.6 11 22 10.6 22 10C22 9.4 21.6 9 21 9H15V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V9H11V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V9H3C2.4 9 2 9.4 2 10C2 10.6 2.4 11 3 11H9V13H3C2.4 13 2 13.4 2 14C2 14.6 2.4 15 3 15H9V21C9 21.6 9.4 22 10 22C10.6 22 11 21.6 11 21V15H13V21C13 21.6 13.4 22 14 22C14.6 22 15 21.6 15 21V15H21C21.6 15 22 14.6 22 14C22 13.4 21.6 13 21 13Z" fill="black"/>
        <path d="M16 17H8C7.4 17 7 16.6 7 16V8C7 7.4 7.4 7 8 7H16C16.6 7 17 7.4 17 8V16C17 16.6 16.6 17 16 17ZM14 10H10V14H14V10Z" fill="black"/>
        </svg>
        </span>
      </div> 
      <div class="text-gray-500 flex-grow-1 me-4">usage :</div> 
      <div class="fw-boldest text-gray-700 text-xxl-end">${(usage.value).toFixed(2)}</div> 
    </div>
    <div class="d-flex fs-6 fw-bold align-items-center"> 
    <div class="me-3">
      <span class="svg-icon svg-icon-warning svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path opacity="0.3" d="M21 13H15V11H21C21.6 11 22 10.6 22 10C22 9.4 21.6 9 21 9H15V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V9H11V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V9H3C2.4 9 2 9.4 2 10C2 10.6 2.4 11 3 11H9V13H3C2.4 13 2 13.4 2 14C2 14.6 2.4 15 3 15H9V21C9 21.6 9.4 22 10 22C10.6 22 11 21.6 11 21V15H13V21C13 21.6 13.4 22 14 22C14.6 22 15 21.6 15 21V15H21C21.6 15 22 14.6 22 14C22 13.4 21.6 13 21 13Z" fill="black"/>
      <path d="M16 17H8C7.4 17 7 16.6 7 16V8C7 7.4 7.4 7 8 7H16C16.6 7 17 7.4 17 8V16C17 16.6 16.6 17 16 17ZM14 10H10V14H14V10Z" fill="black"/>
      </svg>
      </span>
    </div> 
    <div class="text-gray-500 flex-grow-1 me-4">user :</div> 
    <div class="fw-boldest text-gray-700 text-xxl-end">${user.value}</div> 
  </div>
          </div>
        `;
        }
      },
    },  
    areaStyle: () => {
      return {
        fill: 'l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff',
      };
    },
    legend: {
      position: 'top',
    },
  };
  return <Area {...config5} />;
}
   
 
    return (
        <>
        <div className="card">
           {/*begin::Card header*/}
      <div
        className="card-header border-0 cursor-pointer" 
      >
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">Charts</h3>
        </div>
      </div>
      {/*end::Card header*/}
      <div className="row g-5 g-xl-8 container">
          <div className="col-xl-12">
            {/*begin::Charts Widget 2*/}
            <div className=" mb-5 mb-xl-8 border shadow">
              {/*begin::Header*/}
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bolder fs-3 mb-1">
                    CPU Utilization
                  </span>
                  <span className="text-muted fw-bold fs-7">
                    Monitor your CPU utilization here
                  </span>
                </h3>  
                </div>
 {/*begin::Body*/}
 <div className="card-body" id="chart-cpu-id">
              <CpuChart />
              </div>
              {/*end::Body*/}
              </div>
              {/*end::Header*/}
             
            </div> 
            {/*end::Charts Widget 2*/}
          </div>
          {/*end::Row*/}
        </div> 
        </>
    );
}

export default Cpu;
