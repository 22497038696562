import React from 'react';
import UseApi from '../../../CustomHooks/useApi';

const IsSubUserAccount = async () => {
    const responseData = await UseApi('get', 'amaccess', '');
    console.log(responseData);
    if (responseData && responseData.status == "success") {
        var selfAccount = true;
        responseData.accountaccess.map((value, index) => {
            if (value.type == "self") {
                selfAccount = false;
                // myAccount = value;
            }else{
                selfAccount = true
            }
        });
        if (selfAccount) {
            return false
        } else {
            return true
        }
    }else{
        return false
    }
};

export default IsSubUserAccount;
