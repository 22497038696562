import React from "react";
import Body from "./Body/Body"; 
import AsideBar from "./Header/Aside/AsideBar";
import Toolbar from "./Header/Toolbar/Toolbar";
import BottomNavbar from "./Header/BottomNavbar/BottomNavbar";  
import Footer from "./Footer/Footer";

export default function MicroApp() { 
  return (
    <> 
      <BottomNavbar />
      <AsideBar />
       <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <Toolbar />
        <Body />
       </div> 
      </>
  );
}
