import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';
import TableLoader from '../../../../../Loaders/TableLoader';
import Search from '../../../../../utils/search/Search';

function Vpc(props) {
    const { 
        vpcData,
        addVpc,
        privateData,
        vpcsDisabled
      } = props;

    const [vpcFilterData, setVpcFilterData] = useState(vpcData ?? []);
    const [vpcList, setVpcList] = useState(vpcData ?? []);

    useEffect(() => {
      console.log("private data", privateData);
      if (privateData != null && privateData.length > 1) { // Ensure there are at least two elements to compare
        // Extract the relevant part from privateData for comparison, excluding the first element
        const privateNetworks = privateData
        .filter(pd => pd.noip !== 0)
        .map(pd => pd?.network.split("/")[0]);
        const filteredData = vpcData.filter((item) => {
          // Return true if item.name is not found in privateNetworks
          console.log("item", item);
           return !privateNetworks.includes(item.network);
        });
         console.log("filteredData", filteredData);
        // Do something with filteredData, for example, set it in state
        setVpcFilterData(filteredData);
        setVpcList(filteredData);
      } else {
        // If privateData is null or has 1 or fewer elements, include all vpcData
        setVpcFilterData(vpcData);
        setVpcList(vpcData);
      }
    }, [vpcData, privateData]);
    
  return (
    <>
       <div className="card card-xl-stretch mb-5 mb-xl-12">
        {/*begin::Header*/}
        <div className="card-header border-2 pt-5">
          {/*begin::Heading*/}
          {/*end::Heading*/}
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold fs-3 mb-1">
              {" "}
              Select VPC to assign to your cloud
              <span className="svg-icon svg-icon-2">
                <i className="fonticon-share" style={{ fontSize: 20 }} />
              </span>
            </span>
          </h3>
        </div>
        {/*end::Header*/}
        {/*begin::Body*/}
        {vpcList != null?(<>
          <div className='mx-9 mt-5'>
          <Search results={vpcFilterData ?? []} onSearch={setVpcList} fields={['network', 'name']} searchPlaceholder="vpc" />
          </div>
        <div className="row mt-5 container" id="vpc-list"> 
          { vpcList.length > 0?(<>
          {vpcList.map((value, index) => (
            <div className="col-md-4 col-lg-12 col-xl-4 col-xxl-4 mb-5">
              <label
                htmlFor=""
                id={"vpc-" + value.id}
                onClick={(e) => { e.preventDefault(); addVpc(value.id) }}
                className={`btn btn-outline btn-outline-dashed btn-outline-default  d-flex align-items-center vpcNetwork ${vpcsDisabled ? "disabled" : ''}`}
              >
                <span className="svg-icon svg-icon-primary svg-icon-4x me-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      opacity="0.3"
                      d="M19 18C20.7 18 22 16.7 22 15C22 13.3 20.7 12 19 12C18.9 12 18.9 12 18.8 12C18.9 11.7 19 11.3 19 11C19 9.3 17.7 8 16 8C15.4 8 14.8 8.2 14.3 8.5C13.4 7 11.8 6 10 6C7.2 6 5 8.2 5 11C5 11.3 5.00001 11.7 5.10001 12H5C3.3 12 2 13.3 2 15C2 16.7 3.3 18 5 18H19Z"
                      fill="black"
                    />
                  </svg>
                </span>
                <span className="d-block fw-bold text-start">
                  <span className="text-dark fw-bolder d-block fs-3">
                    {value.network}
                  </span>
                  <span className="text-muted fw-bold fs-6">
                    {" "}
                    {value.name}
                  </span>
                </span>
              </label>
            </div>
          ))}</>):(<>
            <span className='text-gray-400 fw-bolder fs-6 text-center'>no vpc found</span>
          </>)}
        </div></>):(<>
          <TableLoader />
          </>)} 
        {/*begin::Body*/}
      </div>
    </>
  )
}
export default Vpc

