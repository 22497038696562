// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fade-out-front {
    animation: fadeOutAnimation 1s forwards;
    opacity: 1;
    transform-origin: bottom;
    display: none;
  }
  
  @keyframes fadeOutAnimation {
    to {
      opacity: 0;
      transform: scaleY(0);
    }
  }

  input[type="number"] {
    appearance: textfield;
  }`, "",{"version":3,"sources":["webpack://./src/App/MicroApp/Body/Products/loadbalancer/custom.css"],"names":[],"mappings":"AAAA;IACI,uCAAuC;IACvC,UAAU;IACV,wBAAwB;IACxB,aAAa;EACf;;EAEA;IACE;MACE,UAAU;MACV,oBAAoB;IACtB;EACF;;EAEA;IAEE,qBAAqB;EACvB","sourcesContent":[".fade-out-front {\n    animation: fadeOutAnimation 1s forwards;\n    opacity: 1;\n    transform-origin: bottom;\n    display: none;\n  }\n  \n  @keyframes fadeOutAnimation {\n    to {\n      opacity: 0;\n      transform: scaleY(0);\n    }\n  }\n\n  input[type=\"number\"] {\n    -moz-appearance: textfield;\n    appearance: textfield;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
