import React, { useEffect, useState } from "react";
import { CopyBlock, dracula } from "react-code-blocks";
import "./style.css";
import generateCode from "./generateCode";
 
function GenerateLangCode(props) {   
  // const languages = useState(['Curl', 'Java', 'Python', 'PHP', 'Ruby', 'Node.js', '.NET', 'Go']);
  const [language, changeLanguage] = useState("jsx");
  //  const [languagecode, setLanguageCode] = useState(sample["jsx"]); 
  const [languagecode, setLanguageCode] = useState(''); 
  const selectLanguage=(lang)=>{
    const languageDemo = generateCode(lang, props?.method, props?.url, props?.data);
    setLanguageCode(languageDemo);
  }
  useEffect(()=>{
    selectLanguage('Curl');
    changeLanguage("bash");
  },[])
  return (<>
    <div className="highlight" style={{background: "rgb(40, 42, 54)"}}> 
  <ul className="nav nav-pills" role="tablist">
    
    <li className="nav-item">
      <a
        className="nav-link active"
        data-bs-toggle="tab"
        href="#curl"
        role="tab"
        aria-selected="true"
        onClick={(e)=>{selectLanguage('Curl');changeLanguage("bash")}}
      >
        Curl
      </a>
    </li> 
    <li className="nav-item">
      <a
        className="nav-link"
        data-bs-toggle="tab"
        href="#java"
        role="tab"
        aria-selected="false"
        onClick={(e)=>{selectLanguage('Java');changeLanguage("java")}}
      >
        Java
      </a>
    </li>
    <li className="nav-item">
      <a
        className="nav-link"
        data-bs-toggle="tab"
        href="#python"
        role="tab"
        aria-selected="false"
        onClick={(e)=>{selectLanguage('Python');changeLanguage("python")}}
      >
        Python
      </a>
    </li>
    <li className="nav-item">
      <a
        className="nav-link"
        data-bs-toggle="tab"
        href="#php"
        role="tab"
        aria-selected="false"
        onClick={(e)=>{selectLanguage('PHP');changeLanguage("php")}}
      >
        PHP
      </a>
    </li>
    <li className="nav-item">
      <a
        className="nav-link"
        data-bs-toggle="tab"
        href="#ruby"
        role="tab"
        aria-selected="false"
        onClick={(e)=>{selectLanguage('Ruby');changeLanguage("ruby")}}
      >
        Ruby
      </a>
    </li>
    <li className="nav-item">
      <a
        className="nav-link"
        data-bs-toggle="tab"
        href="#nodejs"
        role="tab"
        aria-selected="false"
        onClick={(e)=>{selectLanguage('Node.js');changeLanguage("javascript")}}
      >
        Node.js
      </a>
    </li>
    <li className="nav-item">
      <a
        className="nav-link"
        data-bs-toggle="tab"
        href="#net"
        role="tab"
        aria-selected="false"
        onClick={(e)=>{selectLanguage('.NET');changeLanguage("javascript")}}
      >
        .NET
      </a>
    </li>
    <li className="nav-item">
      <a
        className="nav-link"
        data-bs-toggle="tab"
        href="#go"
        role="tab"
        aria-selected="false"
        onClick={(e)=>{selectLanguage('Go');changeLanguage("go")}}
      >
        Go
      </a>
    </li>
  </ul>
  <div className="tab-content">
    <div
      className="tab-pane fade active show"
      id="curl"
      role="tabpanel"
    >
      <div className="highlight-code">
        <pre className=" language-javascript code-block-container" tabIndex={0}>
         <CopyBlock 
          language={language}
          text={languagecode}
          showLineNumbers={true}
          theme={dracula}
          wrapLines={true} 
          codeBlock
        /> 
         </pre>
      </div>
    </div> 

  </div>
</div> 
  </>);
}
 export default GenerateLangCode;