import React, { useEffect, useState } from "react";
import copy from "copy-to-clipboard";

export default function Vpc(props) {
  const [vpcInfo, setVpcInfo] = useState(null);


  useEffect(() => {
    console.log(props.data);
    if (props && props.data && props.data.network) {
      props.data.network.map((value, index) => {
        if (value.networktype == "private") {
          setVpcInfo(value);
        }
      })

    }
  }, [props])

  const copyToClipboard = (target, targetBy) => {
    var btn_id = document.querySelector("#" + targetBy);
    var target_id = document.querySelector("#" + target);
    if (btn_id && !btn_id.classList.contains('disabled')) {
      var copyText = btn_id.innerHTML;
      var copyEle = target_id.innerHTML;
      copy(copyEle);
      btn_id.innerHTML = `<svg
          xmlns="http://www.w3.org/2000/svg"
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M9.89557 13.4982L7.79487 11.2651C7.26967 10.7068 6.38251 10.7068 5.85731 11.2651C5.37559 11.7772 5.37559 12.5757 5.85731 13.0878L9.74989 17.2257C10.1448 17.6455 10.8118 17.6455 11.2066 17.2257L18.1427 9.85252C18.6244 9.34044 18.6244 8.54191 18.1427 8.02984C17.6175 7.47154 16.7303 7.47154 16.2051 8.02984L11.061 13.4982C10.7451 13.834 10.2115 13.834 9.89557 13.4982Z"
            fill="black"
          />
        </svg>`;
        btn_id.classList.add("disabled");
      setTimeout(() => {
        btn_id.innerHTML = copyText;
        btn_id.classList.remove("disabled");
      }, 2000);
    }

  }
  if (vpcInfo != null && vpcInfo != "") {
    return (
      <>

        <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed h-lg-100 p-6 me- mt-5">
          <span className="svg-icon svg-icon-5tx svg-icon-primary me-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                opacity="0.3"
                d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                fill="black"
              />
              <path
                d="M14.854 11.321C14.7568 11.2282 14.6388 11.1818 14.4998 11.1818H14.3333V10.2272C14.3333 9.61741 14.1041 9.09378 13.6458 8.65628C13.1875 8.21876 12.639 8 12 8C11.361 8 10.8124 8.21876 10.3541 8.65626C9.89574 9.09378 9.66663 9.61739 9.66663 10.2272V11.1818H9.49999C9.36115 11.1818 9.24306 11.2282 9.14583 11.321C9.0486 11.4138 9 11.5265 9 11.6591V14.5227C9 14.6553 9.04862 14.768 9.14583 14.8609C9.24306 14.9536 9.36115 15 9.49999 15H14.5C14.6389 15 14.7569 14.9536 14.8542 14.8609C14.9513 14.768 15 14.6553 15 14.5227V11.6591C15.0001 11.5265 14.9513 11.4138 14.854 11.321ZM13.3333 11.1818H10.6666V10.2272C10.6666 9.87594 10.7969 9.57597 11.0573 9.32743C11.3177 9.07886 11.6319 8.9546 12 8.9546C12.3681 8.9546 12.6823 9.07884 12.9427 9.32743C13.2031 9.57595 13.3333 9.87594 13.3333 10.2272V11.1818Z"
                fill="black"
              />
            </svg>
          </span>

          {/*begin::Wrapper*/}
          <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
            {/*begin::Content*/}
            <div className="mb-3 mb-md-0 fw-bold">
              <h4 className="text-gray-900 fw-bolder">VPC Network</h4>
              <div className="fs-6 text-gray-700 pe-7">
                This VPC is attached to your database cluster. Ensure that your database cluster is configured to use the correct VPC network to enable secure communication within your network environment.

              </div>
            </div>
            {/*end::Content*/}
            {/*begin::Action*/}
            <a>
              <div className=" align-items-center">
                <span className={`w-100 btn btn-active-light-primary btn-light-primary btn-sm sphide me-2 fs-4 border border-primary mb-3`}>
                  Network :  <span id="vpc_network_sec">{vpcInfo?.vpc_network}</span>
                  <span id="vpc_network_Btn" onClick={(e) => copyToClipboard('vpc_network_sec', 'vpc_network_Btn')} className="svg-icon svg-icon-2 ms-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="#eff2f5"
                    >
                      <path
                        opacity="0.5"
                        d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                        fill="#eff2f5"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                        fill="#eff2f5"
                      />
                    </svg>
                  </span>
                </span>
                <span className={`w-100 btn btn-active-light-primary btn-light-primary btn-sm sphide me-2 fs-4 border border-primary`}>
                  IPv4 :  <span id="vpc_ip">{vpcInfo?.ip}</span>
                  <span id="vpc_ipBtn" onClick={(e) => copyToClipboard('vpc_ip', 'vpc_ipBtn')} className="svg-icon svg-icon-2 ms-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="#eff2f5"
                    >
                      <path
                        opacity="0.5"
                        d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                        fill="#eff2f5"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                        fill="#eff2f5"
                      />
                    </svg>
                  </span>
                </span>
              </div>
            </a>
            {/*end::Action*/}
          </div>
          {/*end::Wrapper*/}
        </div>

      </>
    )
  }

}  