import React, { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import QueueDeploy from "./QueueDeploy";
import ReceiveSend from "./ReceiveSend";
import UseApi from "../../../../../CustomHooks/useApi";
import TableLoader from "../../../Loaders/TableLoader";
import swal from "sweetalert";
import { toast } from "react-toastify";
import UrlDrawer from "./UrlDrawer";
import ActionList from "./ActionList";
import CustomDoc from "../../../../../CustomHooks/CustomDoc";
import ProductsUrl from "../../../Docs/Docsdata";


export default function Queue() {

  const [allData, setAllData] = useState(null)
  const [dummyAllData, setDummyAllData] = useState(null)
  const [pageStatus, setPageStatus] = useState(false)
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [perPage, setPerPage] = useState(5);
  const [randomName, setRandomName] = useState("")
  const [activePage, setActivePage] = useState(0)
  const [isUpdate, setIsUpdate] = useState(false)
  const [url, setUrl] = useState('')
  const [objectForUpdate, setObjectForUpdate] = useState({})
  let navigate = useNavigate()
  var query = window.location.href;
  var vars = query.split("/");
  var id = vars[4];

  const loaderFunc = () => {
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if (loader && no_record) {
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");

      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
  }

  const makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    console.log(result);
    // setRandomName(result?.toLowerCase())
    setRandomName((old) => old = result?.toLowerCase())
    return result.toLowerCase();
  };

  const getQueueById = async () => {
    const data = await UseApi('get', `sqs/${id}/queue`, '');
    if (data.status === "success") {
      setAllData(data.queues?.slice()?.reverse());
      setDummyAllData(data.queues?.slice()?.reverse());
    } else {
      setAllData([])
      setDummyAllData([])
    }
  }
  useEffect(() => {
    getQueueById()
    makeid(8)
  }, [pageStatus])

  const handleDestroy = (queueId, index) => {
    toast.dismiss();
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Queue!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          // setPageStatus(true)
          const dt = toast.loading("queue deleting...");
          const dnsp = await UseApi('DELETE', 'sqs/' + id + '/queue?id=' + queueId, '');
          if (dnsp.status == 'error') {
            // setPageStatus(false)
            toast.update(dt, {
              render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
          } else {
            // setPageStatus(false)
            toast.update(dt, {
              render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
            setPageStatus(!pageStatus)
            // setTimeout(() => {
            setAllData((old) => {
              old.splice(index, 1);
              return [...old];
            })
            // }, 1000)
            // navigate('/sqs/'+ id);
          }
        }
      });
  }

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  const handleSearch = (text) => {
    const endOffset = itemOffset + perPage;
    const escapedText = escapeRegExp(text);
    let matches = allData.filter((name) => {
      const regex = new RegExp(`${escapedText}`, "gi");
      return name.name.match(regex);
    })
    setDummyAllData(matches);;
    setPageCount(Math.ceil(matches.length / perPage));
    // }
  }

  function PaginatedItems({ itemsPerPage, ProductData }) {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(ProductData.length / itemsPerPage));
    const handlePageClick = (event) => {
      setActivePage((old) => old = event.selected);
      const newOffset = event.selected * itemsPerPage;
      setItemOffset(newOffset);

    };

    return (
      <>
        <ProductInfo productdata={ProductData.slice(itemOffset, endOffset)} />
        <td colspan="12">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            initialPage={activePage}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </td>
      </>
    );
  }
  // const openDrawerForUrl = (url) => {
  //   setUrl(url)
  //   var drawerElement = document.querySelector("#url-modal");
  //   var drawer_overlay = document.querySelector("#drawer_overlay_url");
  //   if (drawerElement && drawer_overlay) {
  //     drawerElement.classList.toggle("drawer-on");
  //     if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
  //       drawerElement.style.width = "100%";
  //     } else {
  //       drawerElement.style.width = "40%";
  //     }
  //     drawer_overlay.classList.toggle("d-none");
  //   }
  // };

  function convertTimestamp(timestamp) {
    const date = new Date(timestamp);
    // Format the date
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
      timeZone: 'UTC'
    }).format(date);

    return formattedDate.replace('GMT', '(UTC)');
  }

  function ProductInfo({ productdata }) {
    return (
      <>
        {productdata != null && Object.keys(productdata).length != 0 && (productdata && productdata.map((value, index) => (
          <tr>
            <td className="text-start ps-9">
              <span className="fs-6 fw-bold text-dark sphide">
                {value.name}
              </span>
            </td>
            <td className="text-start ps-5">
              <div className="d-flex justify-content-start">
                <ActionList url={value?.url} />
              </div>
            </td>
            <td className="text-center">
              <span className="fs-6 fw-bold text-dark">
                {/* Standard */}
                {value.fifoQueue == "false" ? "Standard" : "Fifo Queue"}
              </span>
            </td>
            <td className="text-center">
              <span className="fs-6 fw-bold">
                {(value.created_at)}
              </span>
            </td>
            <td className="text-center d-none">
              <span className="fs-6 fw-bold">
                0
              </span>
            </td>
            <td className="text-center d-none">
              <span className="fs-6 fw-bold">
                0
              </span>
            </td>
            <td className="text-center ps-9">
              <div onClick={() => openDrawerSROnClick(value.name)} className="fs-7 fw-bold btn btn-sm btn-light-primary sphide border border-primary">
                <div>
                  Send Message&nbsp;
                  {/*begin::Svg Icon | path: assets/media/icons/duotune/arrows/arr095.svg*/}
                  <span className="svg-icon svg-icon-muted svg-icon-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        opacity="0.3"
                        d="M4.7 17.3V7.7C4.7 6.59543 5.59543 5.7 6.7 5.7H9.8C10.2694 5.7 10.65 5.31944 10.65 4.85C10.65 4.38056 10.2694 4 9.8 4H5C3.89543 4 3 4.89543 3 6V19C3 20.1046 3.89543 21 5 21H18C19.1046 21 20 20.1046 20 19V14.2C20 13.7306 19.6194 13.35 19.15 13.35C18.6806 13.35 18.3 13.7306 18.3 14.2V17.3C18.3 18.4046 17.4046 19.3 16.3 19.3H6.7C5.59543 19.3 4.7 18.4046 4.7 17.3Z"
                        fill="black"
                      />
                      <rect
                        x="21.9497"
                        y="3.46448"
                        width={13}
                        height={2}
                        rx={1}
                        transform="rotate(135 21.9497 3.46448)"
                        fill="black"
                      />
                      <path
                        d="M19.8284 4.97161L19.8284 9.93937C19.8284 10.5252 20.3033 11 20.8891 11C21.4749 11 21.9497 10.5252 21.9497 9.93937L21.9497 3.05029C21.9497 2.498 21.502 2.05028 20.9497 2.05028L14.0607 2.05027C13.4749 2.05027 13 2.52514 13 3.11094C13 3.69673 13.4749 4.17161 14.0607 4.17161L19.0284 4.17161C19.4702 4.17161 19.8284 4.52978 19.8284 4.97161Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                </div>

              </div>
            </td>

            <td className="text-center" >
              <div className="card-toolbar sphide ">
                <button
                  onClick={openDrawer}
                  type="button"
                  className="btn  btn-icon btn-color-primary btn-active-light-primary d-none"
                  id={`update-registry-btn`}
                >
                  <span className="svg-icon svg-icon-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        opacity="0.3"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z"
                        fill="black"
                      />
                      <path
                        d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z"
                        fill="black"
                      />
                      <path
                        d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z"
                        fill="black"
                      />
                    </svg>

                  </span>
                </button>
                <button
                  type="button"
                  className="btn  btn-icon btn-color-danger btn-active-light-danger"
                  id={`delete-queue-btn`}
                  onClick={() => handleDestroy(value.id, index)}
                >
                  <span className="svg-icon svg-icon-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                        fill="black"
                      />
                      <path
                        opacity="0.5"
                        d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                        fill="black"
                      />
                      <path
                        opacity="0.5"
                        d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                </button>
              </div>
            </td>
            {/* <ActionList cloudid={value.name} index={index} setAllData={setAllData} /> */}
          </tr>
        )))}

      </>
    )
  }
  const openDrawer = () => {
    var drawerElement = document.querySelector("#addQueue-modal");
    var drawer_overlay = document.querySelector("#drawer_overlay");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
        drawerElement.style.width = "100%";
      } else {
        drawerElement.style.width = "80%";
      }
      drawer_overlay.classList.toggle("d-none");
    }
    let db_label_element = document.querySelector('#db_label')
    if (db_label_element) {
      db_label_element.value = ''
    }
  };
  const openDrawerSR = () => {
    var drawerElement = document.querySelector("#send-receive-modal");
    var drawer_overlay = document.querySelector("#drawer_overlay_sr");

    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
        drawerElement.style.width = "100%";
      } else {
        drawerElement.style.width = "40%";
      }
      drawer_overlay.classList.toggle("d-none");
    }
    let element = document.querySelector('#message_box')
    element.value = ''
  };
  const openDrawerSROnClick = (name) => {
    var drawerElement = document.querySelector("#send-receive-modal");
    var drawer_overlay = document.querySelector("#drawer_overlay_sr");
    var queue_name = document.querySelector("#queue_name")
    queue_name.value = name
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
        drawerElement.style.width = "100%";
      } else {
        drawerElement.style.width = "40%";
      }
      drawer_overlay.classList.toggle("d-none");
    }
  };
  return (
    <>
      <div className="card">
        <div className="card-header">
          <div className="card-title">
            <h3>Queue </h3>
          </div>
          <div className="card-toolbar">
            <div className="position-relative my-1" style={{ marginRight: 20 }}>
              <CustomDoc url={ProductsUrl[0]?.sqs + "/manage-sqs/"} />
            </div>
            <div
              className="position-relative my-1"
              style={{ marginRight: 20 }}
            >
              <input
                type="text"
                className="form-control form-control-sm"
                data-kt-table-widget-4="search"
                id="searchClient"
                // value={searchTerm}
                onChange={e => handleSearch(e.target.value)}
                placeholder="Search"
              />
            </div>

            <div style={{ marginRight: 20 }}>
              <div className="dataTables_length">
                <label className="w-125px">
                  <select
                    onChange={e => setPerPage(e.target.value)}
                    //data-control="select2"
                    className="form-select form-select-sm form-select-solid"
                    defaultValue={5}
                    id="perPage"

                  >
                    <option value={5} selected="">
                      5 Records
                    </option>
                    <option value={10}>10 Records</option>
                    <option value={25}>25 Records</option>
                    <option value={50}>50 Records</option>
                    <option value={100}>100 Records</option>
                  </select>
                </label>
              </div>
            </div>
            <a onClick={() => {
              openDrawer()
              makeid(8)
            }}
              className="btn btn-sm btn-primary my-1 sphide">
              + Create New Queue
            </a>
          </div>
        </div>
        <div className="card-body p-0">
          <div className="table-responsive">
            <table
              className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
              id="kt_security_license_usage_table"
            >
              <thead className="border-gray-200 fs-5 fw-bold bg-secondary">
                <tr>
                  <th className="text-start w-150px ps-9">Name</th>
                  <th className="text-start w-150px ps-9">Url</th>
                  <th className="text-center w-150px ps-9">Type</th>
                  <th className="text-center w-150px ps-9 sphide">Created_at</th>
                  <th className="text-center w-150px sphide d-none">Message available</th>
                  <th className="text-center pe-5 w-150px sphide d-none">Message in flight</th>
                  <th className="text-center pe-5 w-50px sphide"></th>
                  <th className="text-center pe-5 w-50px sphide">Action</th>
                </tr>
              </thead>
              <tbody id="public-IP-list" className="fw-6 fw-bold text-gray-600">
                {dummyAllData != null ? (<>
                  {dummyAllData.length == 0 ? (<>
                    <tr>
                      <td colspan="12" valign="top">
                        <div className="d-flex flex-column flex-center">
                          <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                        </div>
                      </td>
                    </tr>
                  </>) : (<>
                    <PaginatedItems itemsPerPage={perPage} ProductData={dummyAllData} />
                  </>)}
                </>) : <tr>
                  <td colspan={12}>
                    <TableLoader />
                  </td>
                </tr>}
              </tbody>
            </table>
          </div>
        </div>

      </div>

      <UrlDrawer data={url} />

      <div id="drawer_overlay" onClick={openDrawer} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>
      <div
        id="addQueue-modal"
        className="bg-body shadow drawer drawer-end bg-light-dark"
        // data-kt-drawer="true"
        // data-kt-drawer-name="chat"
        // data-kt-drawer-activate="true"
        // data-kt-drawer-direction="end"
        data-kt-drawer-close="#close_ssh"
        // data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
        style={{ important: true, zIndex: 110 }}
      >
        {/*begin::Messenger*/}
        <div
          className="card rounded-0 w-100 border-0 bg-transparent"
          id="kt_drawer_chat_messenger"
        >
          {/*begin::Card header*/}
          <div
            className="card-header pe-5 bg-white"
            id="kt_drawer_chat_messenger_header"
          >
            {/*begin::Title*/}
            <div className="card-title">
              {/*begin::User*/}
              <div className="d-flex justify-content-center flex-column me-3">
                <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                  Add New Queue
                </a>
              </div>
              {/*end::User*/}
            </div>
            {/*end::Title*/}
            {/*begin::Card toolbar*/}
            <div className="card-toolbar">
              {/*begin::Close*/}
              <div
                onClick={openDrawer}
                className="btn btn-sm btn-icon btn-active-light-primary"
                id="close_ssh"
              >
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                <span className="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x={6}
                      y="17.3137"
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(-45 6 17.3137)"
                      fill="black"
                    />
                    <rect
                      x="7.41422"
                      y={6}
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(45 7.41422 6)"
                      fill="black"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
              </div>
              {/*end::Close*/}
            </div>
            {/*end::Card toolbar*/}
          </div>
          {/*end::Card header*/}
          {/*begin::Card body*/}
          <div className="p-3" id="">
            <QueueDeploy openDrawer={openDrawer} getQueueById={getQueueById} name={randomName} />
          </div>
          {/*end::Card body*/}
        </div>
        {/*end::Messenger*/}
      </div>
      <div id="drawer_overlay_sr" onClick={openDrawerSR} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>

      <div
        id="send-receive-modal"
        className="bg-body shadow drawer drawer-end bg-light-dark"
        // data-kt-drawer="true"
        // data-kt-drawer-name="chat"
        // data-kt-drawer-activate="true"
        // data-kt-drawer-direction="end"
        data-kt-drawer-close="#close_ssh"
        // data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
        style={{ important: true, zIndex: 110 }}
      >
        {/*begin::Messenger*/}
        <div
          className="card rounded-0 w-100 border-0"
          id="kt_drawer_chat_messenger"
        >
          {/*begin::Card header*/}
          <div
            className="card-header pe-5 bg-white"
            id="kt_drawer_chat_messenger_header"
          >
            {/*begin::Title*/}
            <div className="card-title">
              {/*begin::User*/}
              <div className="d-flex justify-content-center flex-column me-3">
                <a className="fs-4 mt-5 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                  <span className="card-label fw-bolder text-gray-800">
                    Send message
                  </span>
                  <p className="text-gray-400 mt-3 fw-bold fs-6">
                    Send messages from a queue. </p>
                </a>
              </div>
              {/*end::User*/}
            </div>
            {/*end::Title*/}
            {/*begin::Card toolbar*/}
            <div className="card-toolbar">
              {/*begin::Close*/}
              <div
                onClick={openDrawerSR}
                className="btn btn-sm btn-icon btn-active-light-primary"
                id="close_ssh"
              >
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                <span className="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x={6}
                      y="17.3137"
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(-45 6 17.3137)"
                      fill="black"
                    />
                    <rect
                      x="7.41422"
                      y={6}
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(45 7.41422 6)"
                      fill="black"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
              </div>
              {/*end::Close*/}
            </div>
            {/*end::Card toolbar*/}
          </div>
          {/*end::Card header*/}
          {/*begin::Card body*/}
          <div className="" id="">
            <ReceiveSend openDrawerSR={openDrawerSR} />
          </div>
          {/*end::Card body*/}
        </div>
        {/*end::Messenger*/}
      </div>

    </>
  )
}