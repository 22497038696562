import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom/dist";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./custom.css";
import TableLoader from "../../../Loaders/TableLoader";
import TopBarHeaderLine from "../../../../../CustomHooks/TopBarHeaderLine";
import UsePost from "../../../../../CustomHooks/usePost";
import UsePostData from "../../../../../CustomHooks/usePostData";
import UseApi from "../../../../../CustomHooks/useApi";
import localStorage from "local-storage";
var serialize = require('form-serialize');
function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}
const ModifyScript = (props) => {

  const forceUpdate = useForceUpdate();
  const dataFetchedRef = useRef();
  const navigate = useNavigate();
  const [pageStatus, setPageStatus] = useState(false);
  const [distroData, setDistroData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [versionData, setVersionData] = useState(null);
  const [targetImagesData, setTargetImagesData] = useState(null);
  const user_data = useRef(null);


  const getDistro = async () => {
    toast.dismiss();
    setDistroData(null);
    const data = await UseApi("get", "cloud/getdeploy", "");
    // user_data.current = await UseApi("get", "account/info", "");
    user_data.current = localStorage.get('user_info');
    setUserData(user_data.current);
    // //////console.log(user_data.current);
    if (Object.keys(data).length === 0) {
      setDistroData(null);
    } else {
      setDistroData(data.distro);
    }
    forceUpdate();
  };
  // select dc


  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    getDistro();
  }, []);

  const makeTagify = () => {
    var target_images = document.querySelector("#target_images");
    if (target_images) {
      new window.Tagify(target_images);
    }
  }

  const selectOs = (image, distribution) => {
    toast.dismiss();
    var target_sec = document.querySelector("#target_sec");
    var target_images = document.querySelector("#target_images");
    //console.log(image);
    if (target_sec) {
      target_sec.classList.remove("d-none");
    } var prevImages = target_images.value;
    if (target_images) {
      console.log(target_images.value + " == " + image)
      if (isStringPresent(target_images.value, image)) {
        const dt = toast.loading("Please wait...");
        toast.update(dt, {
          render: image + " already selected, please choose another image",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      } else {
        var currentImage = image;
        if (prevImages != "") {
          var combineImage = prevImages + "," + currentImage;
        } else {
          var combineImage = currentImage;
        }
        // target_images.value="";
        setTargetImagesData(combineImage);
      }
    }

  };



  function selectVersion(distro) {
    distroData.map((value, index) => {
      if (value.distribution == distro) {
        setVersionData(value.images);
      }
    })
  }

  function findMissingKeys(newObject) {
    const originalObject = {
      title: "",
      status: "",
      description: "",
      images: "",
      is_public: "",
      script: ""
    };
    const originalKeys = Object.keys(originalObject);
    const newKeys = Object.keys(newObject);

    const missingKeys = originalKeys.filter(key => !newKeys.includes(key));

    return missingKeys.length > 0 ? missingKeys : null;
  }

  const updateStack = async () => {
    if (props && props.sdata) {
      const dt = toast.loading("Please wait...");
      var form = document.querySelector('#stackform');
      var requestData = serialize(form, { hash: true });
      const missingKeys = findMissingKeys(requestData);
      if (missingKeys) {
        setTimeout(() => {
          toast.update(dt, {
            render: `Missing keys:${missingKeys.join(", ")}`,
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }, 1000)
        return
      }
      toast.dismiss();
      const submitBtn = document.querySelector("#add-stack-btn");
      if (submitBtn) {
        setPageStatus(true);
        var preContent = submitBtn.innerHTML;
        submitBtn.classList.add("disabled");
        submitBtn.innerHTML =
          'Please wait... <span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
        const responseData = await UsePost("put", "stacks/" + props.sdata.id, "stackform");

        //console.log(responseData);
        if (responseData.status === "error") {
          setPageStatus(false);
          toast.update(dt, {
            render: responseData.message,
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (responseData.status === "success") {
          setPageStatus(false);
          toast.update(dt, {
            render: responseData.message,
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          if (props && props.closeDrawer && props.callInfo) {
            props.closeDrawer();
            props.callInfo();
          }
        }
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
      }
    }
  };

  function isStringPresent(mainString, searchString) {
    return mainString.includes(searchString);
  }
  function removeCommas(str) {
    // Remove commas from the beginning of the string
    while (str.charAt(0) === ',') {
      str = str.substring(1);
    }

    // Remove commas from the end of the string
    while (str.charAt(str.length - 1) === ',') {
      str = str.slice(0, -1);
    }

    return str;
  }
  const [stackInfo, setStackInfo] = useState(null);

  useEffect(() => {
    if (props && props.sdata) {
      if (props.sdata.distro && props.sdata.distro.length > 0) {
        var imagesInfo = "";
        props.sdata.distro.map((value, index) => {
          imagesInfo += value + ",";
        })
        imagesInfo = removeCommas(imagesInfo);
        setTargetImagesData(imagesInfo);
      }
      setStackInfo(props.sdata);
    }
  }, [props])
  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <form id="stackform">
            {/*begin::Tables Widget 9*/}
            <div className="card card-xl-stretch mb-5 mb-xl-12">

              {/*begin::Col*/}
              <div className="col-xl-12">
                {/*begin::List widget 10*/}
                {/*begin::Body*/}
                <div className="card-body">
                  <div className="mb-10">
                    <label className="form-label">Stack Label</label>
                    <input type="text" className="form-control w-100" name="title" placeholder="Enter Stack Label" defaultValue={stackInfo?.title} />
                  </div>
                  <div className="mb-10">
                    <label className="form-label">Description</label>
                    <input type="hidden" name="status" defaultValue={1} />
                    <textarea
                      name="description"
                      className="form-control form-control-lg "
                      rows={5}
                      placeholder="Enter the description"
                      defaultValue={stackInfo?.description}
                    />

                  </div>

                  <label className="form-label mb-5" >Select Target Image</label>
                  <div class="mb-10" id="target_sec">
                    <div className="row">
                      <div className="col-12">
                        <input class="form-control" name="images" value={targetImagesData != null ? targetImagesData : ''} id="target_images" />
                      </div>
                      <div className="col-2 d-none">
                        <a className="btn btn-primary w-100" id="tagify-btn" onClick={makeTagify}>Apply</a>
                      </div>
                    </div>
                  </div>
                  {/*begin::Tab Content*/}
                  <div className="tab-content">
                    {/*begin::Tap pane*/}
                    <div className="tab-pane fade show active" id="distros-tab">
                      {/*begin::Row*/}
                      <div
                        id="distros"
                        className="row g-3"
                        data-kt-buttons="true"
                        data-kt-buttons-target="[data-kt-button]"
                      >
                        {distroData != null ? (<>
                          {distroData &&
                            distroData.map((value, index) => (
                              <>
                                {/*begin::Col*/}
                                <div
                                  className="col-md-3 col-lg-3 col-xxl-3"
                                  style={{ height: "73.06px !important" }}
                                >
                                  <input
                                    type="radio"
                                    name="imageOS"
                                    id={"select_" + value.distribution}
                                    defaultValue=""
                                    className="deploy_checkbox radio_image"
                                  />
                                  <label onClick={() => selectVersion(value.distribution)}
                                    data-bs-toggle="modal" data-bs-target="#version-modal"
                                    //className="label versionOs btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start"
                                    className="uptrans label versionOs text-start border-1 border-gray-300 border px-6 py-2"
                                    id={"versionOs_" + value.distribution}
                                  >
                                    <span className="ms-5">
                                      {/*begin::Item*/}
                                      <div className="d-flex  ">
                                        {/*begin::Text*/}
                                        <div className="flex-grow-1">
                                          <div className="row">
                                            <div className="col-4">
                                              {/*begin::Avatar*/}
                                              <div className="symbol symbol-50px me-5">
                                                <span
                                                  className={
                                                    "fonticon_" + value.distro
                                                  }
                                                />
                                              </div>
                                              {/*end::Avatar*/}
                                            </div>
                                            <div className="col-8">
                                              <p className="text-dark fw-bolder text-hover-primary fs-6">
                                                {value.distribution}

                                                <span
                                                  className="text-muted d-block fw-bold deploy_checkbox_line2 osversionselectmultiple w-100px"
                                                  id={
                                                    "osversion_" +
                                                    value.distribution
                                                  }
                                                >
                                                  Select Version
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        {/*end::Text*/}
                                      </div>
                                      {/*end::Item*/}
                                    </span>

                                    {/*begin::Ribbon*/}
                                    <div
                                      className="d-none tickMarkOs ribbon ribbon-triangle ribbon-top-start border-primary"
                                      id={"tickMarkOs-" + value.distribution}
                                    >
                                      {/*begin::Ribbon icon*/}
                                      <div className="ribbon-icon mt-n5">
                                        <i className="bi bi-check2 fs-2 text-white" />
                                      </div>
                                      {/*end::Ribbon icon*/}
                                    </div>
                                    {/*end::Ribbon*/}
                                  </label>
                                </div>
                                {/*end::Col*/}
                              </>
                            ))}
                        </>) : (<>
                          <TableLoader />
                        </>)}
                      </div>
                      {/*end::Row*/}

                    </div>
                    {/*end::Tap pane*/}



                  </div>
                  {/*end::Tab Content*/}

                  <div className="mb-10 mt-10">
                    <label className="form-label">Script (required)</label>
                    <input type="hidden" name="is_public" defaultValue={1} />
                    <textarea
                      name="script"
                      className="form-control form-control-lg"
                      rows={18}
                      placeholder={'#!/bin/bash\n\n#Your script here'}
                      defaultValue={stackInfo?.script}
                    />

                  </div>
                </div>
                {/*end: Card Body*/}
                {/*end::List widget 10*/}
              </div>
              {/*end::Col*/}

              <div className="container">
                <a onClick={updateStack} id="add-stack-btn" className="btn btn-primary w-100 mb-10">Update Stack</a>
              </div>
            </div>
            {/*end::Tables Widget 9*/}
          </form>
        </div>

      </div>



      {/*begin::Modal - Upload File*/}
      <div

        className="modal fade"
        id="version-modal"
        tabIndex={-1}
        aria-hidden="true"
      >
        {/*begin::Modal dialog*/}
        <div className="modal-dialog modal-dialog-centered mw-650px d-flex justify-content-center">
          <div className="modal-content w-50 p-1" id="version-list"   >
            {versionData != null && versionData.map((value, index) => (
              <>
                <label
                  onClick={() => selectOs(value.image, value.distribution)}
                  data-bs-toggle="modal"
                  data-bs-target="#version-modal"
                  className="uptrans label versionOs text-start border-1 border-gray-300 border px-6"
                >
                  <span style={{}}>
                    <div className="d-flex" style={{ paddingTop: 7 }}>
                      <div className="flex-grow-1">
                        <div className="row">
                          <div className="col-3">
                            <div className="symbol symbol-50px me-5">
                              <span className={"fonticon_" + value.distro} />
                            </div>
                          </div>
                          <div className="col-9">
                            <p className="text-dark fw-bolder text-hover-primary fs-6">
                              {value.distribution}
                              <span
                                className="text-muted d-block fw-bold deploy_checkbox_line2 osversionselectmultiple w-100"
                                id="osversion_AlmaLinux"
                              >
                                {value.image}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                  <div
                    className="d-none tickMarkOs ribbon ribbon-triangle ribbon-top-start border-primary"
                    id="tickMarkOs-AlmaLinux"
                  >
                    <div className="ribbon-icon mt-n5">
                      <i className="bi bi-check2 fs-2 text-white" />
                    </div>
                  </div>
                </label>
              </>
            ))}

          </div>
        </div>

      </div>
      {/*end::Modal - Upload File*/}

      {pageStatus && <TopBarHeaderLine />}
    </>
  );
};


export default ModifyScript;
