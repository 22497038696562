import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import TableLoader from "../../Loaders/TableLoader";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import OwnStack from "./Tabs/OwnStack";
import CommunityStack from "./Tabs/CommunityStack";
import CustomDoc from "../../../../CustomHooks/CustomDoc";
import ProductsUrl from "../../Docs/Docsdata";
import AccessDenied from "../../AccessDenied";

export default function Stacks() {
  const [access, setAccess] = useState(true)
  const loaderFunc = () => {
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if (loader && no_record) {
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");

      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
  };
  const dataFetchedRef = useRef(false);
  const [StacksData, setStacksData] = useState(null);
  const [stackDataPublic, setStackDataPublic] = useState(null);
  const [stackDataPrivate, setStackDataPrivate] = useState(null);
  const [dataFetched, setDataFetched] = useState(false);

  const getStack = async () => {
    const dt = toast;
    const data = await UseApi("get", "stacks", "");
    if (data) {
      if ("status" in data) {
        if (data.status == "error") {
          toast.update(dt, {
            render: data.message,
            type: "warning",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setDataFetched(true)
          if (data.message == "Permission denied.") {
            setAccess(false);
          }else{
          setAccess(true);
          }
          setStacksData([]);
          // loaderFunc();
          return;
        }
      }
      if (Object.keys(data).length === 0) {
        setDataFetched(true)
        setStacksData([]);
        toast.update(dt, {
          render: "no record found",
          type: "warning",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setAccess(true)
        // loaderFunc();
      } else {
        var publicData = data.stacks.filter((item) => {
          return item.is_owner == "0";
        })
        var privateData = data.stacks.filter((item) => {
          return item.is_owner == "1";
        })
        setAccess(true)
        setDataFetched(true)
        setStackDataPrivate(privateData);
        setStackDataPublic(publicData);
        setStacksData(data.stacks);
        toast.dismiss();
      }
    }
  };
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getStack();
  }, []);

  function getStackInfo() {
    getStack();
  }

  const openDoc = () => {
    var drawerElement = document.querySelector("#stacks-doc");
    var drawer_overlay = document.querySelector("#drawer_overlay_stacks_doc");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
        drawerElement.style.width = "100%";
      } else {
        drawerElement.style.width = "50%";
      }
      drawer_overlay.classList.toggle("d-none");
    }
  };

  if(dataFetched){
  if (access) {
      if (StacksData != null) {
        return (
          <>
            <div className="col-xl-12 card">
              <div className="">
                <div className="card-header border-2 mb-5 pt-5">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder text-gray-800">Stacks</span>
                    <span className="text-gray-400 mt-1 fw-bold fs-6">
                      Own Stacks/Community Stacks
                      <span className="text-primary ms-1 fw-bold fs-6  d-none" onClick={openDoc} style={{ cursor: 'pointer' }}>Learn more.
                      </span>
                    </span>
                  </h3>
                  <div className="card-toolbar">
                  <div className="position-relative my-1" style={{ marginRight: 20 }}>
                        <CustomDoc url={ProductsUrl[0]?.stack+"/getting-started/"}/>
                    </div>
                    <Link to="/Stacks/deploy">
                      <a className="btn btn-sm btn-primary btn-active-light-primary">
                        <span className="svg-icon svg-icon-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              opacity="0.5"
                              x="11.364"
                              y="20.364"
                              width={16}
                              height={2}
                              rx={1}
                              transform="rotate(-90 11.364 20.364)"
                              fill="black"
                            />
                            <rect
                              x="4.36396"
                              y="11.364"
                              width={16}
                              height={2}
                              rx={1}
                              fill="black"
                            />
                          </svg>
                        </span>
                        Create Stacks
                      </a>
                    </Link>
                  </div>
                </div>
                <div className="">
                  <ul className="nav nav-pills nav-pills-custom row position-relative mx-0 mb-9">
                    <li className="nav-item col-6 mx-0 px-0">
                      <a
                        className="nav-link active d-flex justify-content-center w-100 border-0 h-100"
                        data-bs-toggle="pill"
                        href="#own-script"
                      >
                        <span className="sphide nav-text text-gray-800 fw-bolder fs-6 mb-3">
                          Own Stacks
                        </span>
                        <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded" />
                      </a>
                    </li>
                    <li className="nav-item col-6 mx-0 px-0">
                      <a
                        className="nav-link d-flex justify-content-center w-100 border-0 h-100"
                        data-bs-toggle="pill"
                        href="#community-script"
                      >
                        <span className="sphide nav-text text-gray-800 fw-bolder fs-6 mb-3">
                          Community Stacks
                        </span>
                        <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded" />
                      </a>
                    </li>
                    <span className="position-absolute z-index-1 bottom-0 w-100 h-4px bg-light rounded" />
                  </ul>
                </div>
              </div>
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="own-script" role="tabpanel">
                  {stackDataPrivate != null ? (<><OwnStack privateData={stackDataPrivate} callData={getStackInfo} /></>) : (<><TableLoader /></>)}

                </div>

                <div className="tab-pane fade" id="community-script" role="tabpanel">
                  {stackDataPrivate != null ? (<> <CommunityStack publicData={stackDataPublic} callData={getStackInfo} /></>) : (<><TableLoader /></>)}

                </div>
              </div>
            </div>
           </>
        );
      } else {
        return (
          <>
            <TableLoader />
            <TopBarHeaderLine />
          </>
        );
      }
  } else {
    return (
      <>
        <AccessDenied />
      </>
    )
  }
}else{
  return (
    <>
      <TableLoader />
    </>
  )
}
}
