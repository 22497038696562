import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UseApi from "../../../../../CustomHooks/useApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UsePost from "../../../../../CustomHooks/usePost";
import swal from "sweetalert";
import TableLoader from "../../../Loaders/TableLoader";
import TopBarHeaderLine from "../../../../../CustomHooks/TopBarHeaderLine";
import UsePostData from "../../../../../CustomHooks/usePostData";  
export default function Backends(props){
    var query = window.location.href;
    var vars = query.split("/");
    var id = vars[4];

    const [pageStatus,setPageStatus] = useState(false)
    const [cloudData, setCloudData] = useState(null);
    const [kubernetesData, setKubernetesData] = useState(null);
    const [kubernetesDataInfo, setKubernetesDataInfo] = useState(null);
    const [backendServerType, setBackendServerType]=useState("cloud");
    const [kWorkersData, setKWorkersData]=useState([]);
    const [knodepoolData, setKnodepoolData]=useState([]);
    const [lbBackendData, setLbBackendData] = useState(null);
    const [frontendid, setFrontendid] = useState(0);
    const dataFetchedRef = useRef(false);
    const [lbData, setLbData] = useState(null);

    useEffect(()=>{
        if(props && props.bData){ 
                if (props.bData.backends) {
                  setLbBackendData(props.bData.backends);
                  setFrontendid(props.bData.id);
                } 
            setLbData(props.bData); 
        }
    },[props])

    const addBackendServer = async () => {
        toast.dismiss();
        setPageStatus(true)
        const dt = toast.loading("Backend server in process, Please wait...");
        const responseData = await UsePost(
          "post",
          "loadbalancer/" + id + "/backend",
          "addbackend"+frontendid
        );
        if (responseData.status === "error") {
          setPageStatus(false)
          toast.update(dt, {
            render: responseData.message,
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          if(props && props.refreshLb){
            props.refreshLb();
          }
        } else {
          setPageStatus(false)
          toast.update(dt, {
            render: responseData.message,
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          var kmodal=document.querySelector("#close-server-modal"+frontendid);
          if(kmodal){
            kmodal.click();
          }
          if(props && props.refreshLb){
            props.refreshLb();
          }
        }
      };
      const addCustomBackendServer = async () => {
        toast.dismiss();
        setPageStatus(true)
        const dt = toast.loading("Backend server in process, Please wait...");
        const responseData = await UsePost(
          "post",
          "loadbalancer/" + id + "/backend",
          "addcustombackend"+frontendid
        );
        if (responseData.status === "error") {
          setPageStatus(false)
          toast.update(dt, {
            render: responseData.message,
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          if(props && props.refreshLb){
            props.refreshLb();
          }
        } else {
          setPageStatus(false)
          toast.update(dt, {
            render: responseData.message,
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          var kmodal=document.querySelector("#close-server-modal"+frontendid);
          if(kmodal){
            kmodal.click();
          }
          if(props && props.refreshLb){
            props.refreshLb();
          }
        }
      }; 

      const deleteBackend = async (lbid) => {
        toast.dismiss();
        swal({
          title: "Are you sure?",
          text: "Once deleted, you will not be able to recover this Backend server!",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then(async (willDelete) => {
          if (willDelete) {
             setPageStatus(true)
            const dt = toast.loading("LoadBalancer backend server deleting...");
            const dnsp = await UseApi( "DELETE","loadbalancer/" + id + "/backend/" + lbid, "nothing");
            //console.log(dnsp);
            if (dnsp.status == "error") {
              setPageStatus(false)
              toast.update(dt, {
                render: dnsp.message,
                type: "warning",
                isLoading: false,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            if (dnsp.status == "success") {
              setPageStatus(true)
              toast.update(dt, {
                render: dnsp.message,
                type: "success",
                isLoading: false,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              if(props && props.refreshLb){
                props.refreshLb();
              }
            }
          }  
        });
      };
    
      const custom_show_sec = (id) => {
        const customBody = document.querySelector("#customBody");
        const cloudBody = document.querySelector("#cloudBody");
        const customShow = document.querySelector("#custom_show");
        const cloudShow = document.querySelector("#cloud_show");
        const customFooter = document.querySelector("#customFooter");
        const cloudFooter = document.querySelector("#cloudFooter");
    
        if (id === "custom") {
          customShow.classList.add("d-none");
          cloudShow.classList.remove("d-none");
          customBody.classList.remove("d-none");
          customFooter.classList.remove("d-none");
          cloudBody.classList.add("d-none");
          cloudFooter.classList.add("d-none");
        }
        if (id === "cloud") {
          customShow.classList.remove("d-none");
          cloudShow.classList.add("d-none");
          customBody.classList.add("d-none");
          customFooter.classList.add("d-none");
          cloudBody.classList.remove("d-none");
          cloudFooter.classList.remove("d-none");
        }
      }; 
 
      const getCloud = async () => {
        const data = await UseApi("get", "cloud", "");
        if (Object.keys(data).length === 0) {
          setCloudData(null);
        } else {
          setCloudData(data.cloud);
        }
      };
    
      const getKubernetes =async()=>{  
        const data=await UseApi('get','kubernetes','');   
       // //console.log(user_data.current);
       if(data){
        if(data.rcode!="success"){ 
          setKubernetesData(null); 
        }else{ 
          console.log(data);
          if(data.k8s){
          setKubernetesData(data.k8s);  
          }  
        }  
      }
      }
    
      const getKubernetesById =async(kid)=>{  
        const data=await UseApi('get','kubernetes/'+kid,'');   
       // //console.log(user_data.current);
       if(data){
        if(data.rcode!="success"){ 
          setKubernetesDataInfo(null);
        }else{  
          if(data){ 
            setKubernetesDataInfo(data);  
          }  
        }  
      }
      }

      useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        toast.dismiss();
        getCloud();
        getKubernetes();
      }, []);
    
    return (<>
     {/*begin::Backend Rule*/}
     <div className="card">
                {/*begin::Card header*/}
                <div className="card-header">
                  {/*begin::Heading*/}
                  <div className="card-title">
                    <h3>Backend Servers</h3>
                  </div>
                  {/*end::Heading*/}
                  {/*begin::Toolbar*/}
                  <div className="card-toolbar">
                    <a
                      className="btn btn-sm btn-primary me-2"
                      data-bs-toggle="modal"
                      data-bs-target={"#addbackend-modal"+frontendid}
                    >
                      <span className="svg-icon svg-icon-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              opacity="0.5"
                              x="11.364"
                              y="20.364"
                              width={16}
                              height={2}
                              rx={1}
                              transform="rotate(-90 11.364 20.364)"
                              fill="black"
                            />
                            <rect x="4.36396" y="11.364" width={16} height={2} rx={1} fill="black" />
                          </svg>
                        </span> Add Backend Servers
                    </a>
                  </div>
                  {/*end::Toolbar*/}
                </div>
                {/*end::Card header*/}
                {/*begin::Body*/}
                <div className="card-body p-0">
                  {/*begin::Table wrapper*/}
                  <div className="table-responsive">
                    {/*begin::Table*/}
                    <table
                      className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
                      id="kt_api_keys_table"
                    >
                      {/*begin::Thead*/}
                      <thead className="border-gray-200 fs-5 fw-bold bg-secondary">
                        <tr>
                          <th className="w-100px text-start ps-9">ID</th>
                          <th className="w-250px d-none">Name</th>
                          <th className="w-225px text-center">IP</th>
                          <th className="w-225px text-center">Port</th>
                          <th className="w-225px text-center d-none">Added at</th>
                          <th className="w-225px text-center"></th>
                        </tr>
                      </thead>
                      {/*end::Thead*/}
                      {/*begin::Tbody*/}
                      <tbody
                        id="backends-list"
                        className="fs-6 fw-bold text-gray-600"
                      >
                        {lbBackendData != null &&
                          lbBackendData &&
                          lbBackendData.map((value, index) => (
                            <tr>
                              <td className="text-start ps-9">
                                <div className="d-flex flex-column w-100 me-2">
                                  <div className="d-flex flex-stack mb-2">
                                    <span className=" me-2 fs-5 fw-bold">
                                      {value.id}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td className="text-center d-none">
                                <div className="d-flex flex-column w-100 me-2">
                                  <div className="d-flex flex-stack mb-2">
                                    <span className=" me-2 fs-5 fw-bold">
                                      {value.name}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td className="text-center">
                                <div className="flex-column w-100 me-2">
                                  <div className="flex-stack mb-2">
                                    <span className=" me-2 fs-5 fw-bold">
                                      {value.ip}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td className="text-center">
                                <div className=" flex-column me-2">
                                  <div className=" flex-stack mb-2">
                                    <span className=" me-2 fs-5 fw-bold">
                                      {value.backend_port}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td className="text-center d-none">
                                <div className="d-flex flex-column w-100 me-2">
                                  <div className="d-flex flex-stack mb-2">
                                    <span className=" me-2 fs-7 fw-bold d-none">
                                      {value.created_at}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td className="text-end">
                                <div className="justify-content-end flex-shrink-0">
                                  <button
                                    className="btn btn-sm btn-danger"
                                    onClick={() => deleteBackend(value.id)}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                      {/*end::Tbody*/}
                    </table>
                    {/*end::Table*/}
                  </div>
                  {/*end::Table wrapper*/}
                </div>
                {/*end::Body*/}
              </div>
              {/*end::Backend Rule*/}
              {/*begin::Modals*/}
              {/*begin::Modal - Create Api Key*/}
              <div
                className="modal fade"
                id={"addbackend-modal"+frontendid}
                tabIndex={-1}
                aria-hidden="true"
              >
                {/*begin::Modal dialog*/}
                <div className="modal-dialog modal-dialog-centered mw-650px">
                  {/*begin::Modal content*/}
                  <div className="modal-content">
                    {/*begin::Modal header*/}
                    <div
                      className="modal-header"
                      id="kt_modal_create_api_key_header"
                    >
                      {/*begin::Modal title*/}
                      <h2 >Add Backend Servers</h2>
                      {/*end::Modal title*/}
                      {/*begin::Close*/}
                      <div
                        className="btn btn-sm btn-icon btn-active-color-primary"
                        data-bs-dismiss="modal"
                        id={"close-server-modal"+frontendid}
                      >
                        {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                        <span className="svg-icon svg-icon-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              opacity="0.5"
                              x={6}
                              y="17.3137"
                              width={16}
                              height={2}
                              rx={1}
                              transform="rotate(-45 6 17.3137)"
                              fill="black"
                            />
                            <rect
                              x="7.41422"
                              y={6}
                              width={16}
                              height={2}
                              rx={1}
                              transform="rotate(45 7.41422 6)"
                              fill="black"
                            />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}
                      </div>
                      {/*end::Close*/}
                    </div>
                    {/*end::Modal header*/}
                    {/*begin::Form*/}

                    {/*begin::Modal body*/}
                    <div className="modal-body py-10 px-lg-17">
                      {/*begin::Scroll*/}
                      <div
                        className="scroll-y me-n7 pe-7"
                        id="kt_modal_create_api_key_scroll"
                        data-kt-scroll="true"
                        data-kt-scroll-activate="{default: false, lg: true}"
                        data-kt-scroll-max-height="auto"
                        data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                        data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                        data-kt-scroll-offset="300px"
                      >
                        {/*begin::Notice*/}
                        <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-10 p-6">
                          {/*begin::Icon*/}
                          {/*begin::Svg Icon | path: icons/duotune/general/gen044.svg*/}
                          <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <rect
                                opacity="0.3"
                                x={2}
                                y={2}
                                width={20}
                                height={20}
                                rx={10}
                                fill="black"
                              />
                              <rect
                                x={11}
                                y={14}
                                width={7}
                                height={2}
                                rx={1}
                                transform="rotate(-90 11 14)"
                                fill="black"
                              />
                              <rect
                                x={11}
                                y={17}
                                width={2}
                                height={2}
                                rx={1}
                                transform="rotate(-90 11 17)"
                                fill="black"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                          {/*end::Icon*/}
                          {/*begin::Wrapper*/}
                          <div className="d-flex flex-stack flex-grow-1">
                            {/*begin::Content*/}
                            <div className="fw-bold">
                              <div className="fs-6 text-gray-700">
                                You can add multiple servers in backend of your
                                load balancer.
                              </div>
                            </div>
                            {/*end::Content*/}
                          </div>
                          {/*end::Wrapper*/}
                        </div>
                        {/*end::Notice*/}
                        <div id="cloudBody">
                          <form id={"addbackend"+frontendid} className="form">
                            <div className="row">
                              <div className="col-8">
                              {/*begin::Input group*/}
                              <div className="d-flex flex-column mb-10 fv-row">
                              {/*begin::Label*/}
                              <label className="required fs-5 fw-bold mb-2">
                                Server type
                              </label>
                              {/*end::Label*/}
                              {/*begin::Select*/}
                              <select
                                className="form-select form-select-solid" 
                                id="serverType" 
                                onChange={(e)=>setBackendServerType(e.target.value)}
                              >
                                    <option value="" disabled selected>Choose Server Type</option> 
                                    <option value="cloud"> Cloud Instance </option> 
                                    <option value="kubernetes"> Kubernetes Cluster </option> 
                              </select> 
                              {/*end::Select*/}
                            </div>
                            </div>
                              <div className="col-4">
                                {/*begin::Input group*/}
                              <div className="d-flex flex-column mb-10 fv-row">
                              {/*begin::Label*/}
                              <label className="required fs-5 fw-bold mb-2">
                                Port
                              </label>
                              {/*end::Label*/}
                              <input type="hidden" defaultValue={backendServerType} name="type" />
                              <input type="hidden" defaultValue={frontendid} name="frontend_id" />
                              <input type="number" className="form-control form-sm form-control-solid" id="backend_port" name="backend_port" defaultValue="" />
                              </div>
                              </div> 
                            </div>
                            {backendServerType=="kubernetes"?(<>
                            <div className="d-flex flex-column mb-10 fv-row">
                              {/*begin::Label*/}
                              <label className="required fs-5 fw-bold mb-2">
                                Choose Kubernetes Cluster
                              </label>
                              {/*end::Label*/}
                              {/*begin::Select*/}
                              <select
                                className="form-select form-select-solid" 
                                id="k_id"
                                name="kubernetes_clusterid"
                                onChange={(e)=>getKubernetesById(e.target.value)}
                              >
                                    <option value="0">Choose Kubernetes Cluster</option>  
                                  {kubernetesData &&
                                  kubernetesData.map((value, index) => (
                                    <option value={value.id}>
                                      {value.hostname}
                                    </option>
                                  ))} 
                              </select> 
                              {/*end::Select*/}
                            </div>

                            <div className="d-flex flex-column mb-10 fv-row">
                              {/*begin::Label*/}
                              <label className="required fs-5 fw-bold mb-2">
                                Node Pool
                              </label>
                              {/*end::Label*/}
                              {/*begin::Select*/}
                              <select
                                className="form-select form-select-solid" 
                                id="k_id"
                                name="pool_name"
                                onChange={(e)=>setKnodepoolData(e.target.value)}
                              >
                                    <option value="">Choose Node Pool</option>   
                                    {kubernetesDataInfo && Object.keys(kubernetesDataInfo.nodepools).length!=0 && Object.entries(kubernetesDataInfo.nodepools).map((value, index)=>(
                                      <> 
                                      <option value={value[0]}>
                                      {value[0]}
                                      </option>
                                      </>
                                    ))} 
                              </select> 
                              {/*end::Select*/}
                            </div> 
                            </>):('')}
                            {/*begin::Input group*/}
                            <div className="d-flex flex-column mb-10 fv-row">
                              {/*begin::Label*/}
                              <label className="required fs-5 fw-bold mb-2">
                                Choose Backend Server
                              </label>
                              {/*end::Label*/}
                              {/*begin::Select*/}
                              <select
                                className="form-select form-select-solid"
                                name="cloudid"
                                id="cloudSet"
                              >
                                <option value="">Choose backend server</option>
                                {backendServerType?(<>
                                {backendServerType=="cloud"?(<>
                                  {cloudData != null &&
                                  cloudData &&
                                  cloudData.map((value, index) => (
                                    <option value={value.cloudid}>
                                      {value.hostname + " " + value.ip}
                                    </option>
                                  ))}
                                </>):(<>
                                {backendServerType=="kubernetes"?(<>
                                  {kubernetesDataInfo && Object.keys(kubernetesDataInfo.nodepools).length!=0 && Object.entries(kubernetesDataInfo.nodepools).map((value, index)=>(
                                      <>
                                      {value[0]==knodepoolData?(<>
                                      {value[1].workers.length>0 && (value[1].workers && value[1].workers.map((value2,index2)=>(<>
                                         {value2.status=='Installed'?(<>
                                          <option value={value2.cloudid}>
                                           {value2.hostname + " " + value2.ip}
                                          </option>
                                         </>):('')}
                                      </>)))}
                                      </>):('')}
                                      </>
                                    ))} 
                                </>):('')}
                                </>)}

                                </>):('')}
                                
                              </select>
                              <input
                                type="hidden"
                                className="form-control "
                                defaultValue=""
                                name="ip"
                                placeholder="Enter your custom IP"
                              />
                              {/*end::Select*/}
                            </div>
                          </form>
                        </div>
                        <div id="customBody" className="d-none">
                          <form id={"addcustombackend"+frontendid} className="form">
                          <div className="row">
                              <div className="col-8">
                            <div className="d-flex flex-column mb-10 fv-row">
                              {/*begin::Label*/}
                              <input
                                type="hidden"
                                className="form-control"
                                name="type" 
                                defaultValue="custom"
                              />
                              <input
                                type="hidden"
                                className="form-control"
                                name="cloudid"
                                id="cloudSet"
                                defaultValue={0}
                              />
                              <label className="required fs-5 fw-bold mb-2">
                                Custom IP
                              </label>
                              {/*end::Label*/}
                              <input
                                className="form-control "
                                defaultValue=""
                                name="ip"
                                placeholder="Enter your custom IP"
                              />
                            </div>
                            </div>
                            <div className="col-4">
                                {/*begin::Input group*/}
                              <div className="d-flex flex-column mb-10 fv-row">
                              {/*begin::Label*/}
                              <label className="required fs-5 fw-bold mb-2">
                                Port
                              </label>
                              {/*end::Label*/}
                              <input type="hidden" defaultValue={frontendid} name="frontend_id" />
                              <input type="number" className="form-control form-sm" id="backend_port" name="backend_port" defaultValue="" />
                              </div>
                              </div> 
                            </div>
                          </form>
                        </div>
                        {/*end::Input group*/}
                        <div className="separator separator-content my-15">
                          or
                        </div>
                        {/*begin::Input group*/}
                        <div className="text-center">
                          <a
                            className=" btn btn-sm btn-secondary"
                            onClick={() => custom_show_sec("custom")}
                            id="custom_show"
                          >
                            Custom IP
                          </a>
                          <a
                            className=" btn btn-sm btn-secondary d-none"
                            onClick={() => custom_show_sec("cloud")}
                            id="cloud_show"
                          >
                            Cloud servers
                          </a>
                        </div>
                        {/*end::Input group*/}
                      </div>
                      {/*end::Scroll*/}
                    </div>
                    {/*end::Modal body*/}
                    {/*begin::Modal footer*/}
                    <div className="modal-footer flex-center" id="cloudFooter">
                      {/*begin::Button*/}
                      <button
                        id="addserver"
                        type="button"
                        onClick={addBackendServer}
                        className="btn btn-primary w-100"
                      >
                        Add Server
                      </button>
                      {/*end::Button*/}
                    </div>
                    <div
                      className="modal-footer flex-center d-none"
                      id="customFooter"
                    >
                      {/*begin::Button*/}
                      <button
                        id="addcustomserver"
                        type="button"
                        onClick={addCustomBackendServer}
                        className="btn btn-primary w-100"
                      >
                        Add Custom Server
                      </button>
                      {/*end::Button*/}
                    </div>
                    {/*end::Modal footer*/}

                    {/*end::Form*/}
                  </div>
                  {/*end::Modal content*/}
                </div>
                {/*end::Modal dialog*/}
              </div>
              {/*end::Modal - Create Api Key*/}
              {/*end::Modals*/}
    </>)
}