import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';

export default function Process() {
  const navigate = useNavigate();
  var { id } = useParams();
  function redirectToPrev(id) {
    // if (id == "firewall" || id == "dns" || id == "ssl") {
      if (id == "dns" || id == "ssl") {
      navigate('/' + id + '?action=deploy');
    }else if(id == "auto-scaling"){
      navigate('/' + id + '/create');
    }
     else {
      navigate('/' + id + '/deploy');
    }
  }
  useEffect(() => {
    redirectToPrev(id);
  }, [id])
  return (
    <></>
    // <div>process</div>
  )
}
