import React, { useEffect, useRef, useState } from "react";
import CreateLoadbalancerTargetGroup from "../loadbalancer/Header/CreateLoadbalancerTargetGroup";
import TargetGroupList from "../loadbalancer/TargetGroupList";

export default function TargetGroup() {
    return (
        <>
            {/* <div className="col-xl-12">
                <CreateLoadbalancerTargetGroup />
            </div> */}
            <TargetGroupList showButton={true}/>
        </>
    )
}