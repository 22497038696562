import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import './custom.css';

const BandwidthInboundLineChart = ({ data }) => {
  const d3Container = useRef(null);
  const d3OuterContainer = useRef(null);

  useEffect(() => {
    if (data && d3Container.current) {
      // Clear any previous chart
      d3.select(d3Container.current).selectAll('*').remove();
      const container = d3Container.current;
  
      // Set chart dimensions and margins
      const margin = { top: 30, right: 20, bottom: 70, left: 50 };
      const width = 660 - margin.left - margin.right;
      const height = 370 - margin.top - margin.bottom;
  
      const parseTime = d3.timeParse('%Y%m%d'); // Modify the format for parsing the date
  
      // Create scales
      const x = d3.scaleBand().range([0, width]).padding(0.1); // Use scaleBand for bar chart
      const y = d3.scaleLinear().range([height, 0]);
  
      // Create SVG container
      const svg = d3
        .select(container)
        .append('svg')
        .attr('viewBox', `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
        .append('g')
        .attr('transform', `translate(${margin.left}, ${margin.top})`);
  
      let dataFormatted = {};
      if (data) {
        // Format the data: parse date and map value
        dataFormatted = Object.entries(data).map((date) => ({
          clock: parseTime(date[0]), // Parse date from "YYYYMMDD"
          value: date[1]/1024,  // Inbound data value
        }));
      }
  
      if (dataFormatted && Array.isArray(dataFormatted)) {
        dataFormatted.sort((a, b) => a.clock - b.clock); // Sort the data by time
      }
  
      // Set domains for x and y scales
      x.domain(dataFormatted.map((d) => d.clock)); // x-axis based on the clock (date)
      y.domain([0, d3.max(dataFormatted, (d) => d.value)]); // y-axis based on the value
  
      // Add bars
      svg
        .selectAll('.bar')
        .data(dataFormatted)
        .enter()
        .append('rect')
        .attr('class', 'bar')
        .attr('x', (d) => x(d.clock))
        .attr('y', (d) => y(d.value))
        .attr('width', x.bandwidth())
        .attr('height', (d) => height - y(d.value))
        .attr('fill', '#4CAF50'); // Color of bars
  
      // Add X Axis
      svg
        .append('g')
        .attr('transform', `translate(0,${height})`)
        .call(d3.axisBottom(x).tickFormat(d3.timeFormat('%Y-%m-%d')))
        .selectAll('text')
        .attr('transform', 'rotate(-45)')
        .style('text-anchor', 'end');
  
      // Add Y Axis
      svg.append('g').call(d3.axisLeft(y));
  
      // Tooltip setup
      const tooltip = d3
        .select(container)
        .append('div')
        .attr('class', 'tooltip')
        .style('opacity', 0)
        .style('position', 'absolute');
  
      svg
        .selectAll('.bar')
        .on('mouseenter', (event, d) => {
          tooltip.style('opacity', 1);
        })
        .on('mousemove', (event, d) => {
          const tooltipWidth = parseFloat(tooltip.style('width'));
          const tooltipHeight = parseFloat(tooltip.style('height'));
          let leftPosition = event.pageX + 10;
  
          if (event.pageX + tooltipWidth + 10 > window.innerWidth) {
            leftPosition = event.pageX - tooltipWidth - 10;
          }
  
          tooltip
            .html(`
              <div class="card p-5 bg-light"> 
                <div class="d-flex fs-6 fw-bold align-items-center mb-2"> 
                  <div class="me-3">
                    <span class="svg-icon svg-icon-muted svg-icon-2"></span>
                  </div> 
                  <div class="text-gray-500 flex-grow-1 me-4">Time :</div> 
                  <div class="fw-boldest text-gray-700 text-xxl-end">${d.clock.toLocaleString()}</div> 
                </div>
                <div class="d-flex fs-6 fw-bold align-items-center"> 
                  <div class="me-3">
                    <span class="svg-icon svg-icon-primary svg-icon-2"></span>
                  </div> 
                  <div class="text-gray-500 flex-grow-1 me-4">Inbound Data :</div> 
                  <div class="fw-boldest text-gray-700 text-xxl-end">${d.value.toFixed(2)}GB</div> 
                </div>
              </div>`)
            .style('left', `${leftPosition}px`)
            .style('top', `${event.pageY - tooltipHeight - 10}px`);
        })
        .on('mouseleave', () => {
          tooltip.style('opacity', 0);
        });
    }
  }, [data]);
  

  return <div ref={d3OuterContainer}><div id="chart" ref={d3Container} /></div>;
};

export default BandwidthInboundLineChart;
