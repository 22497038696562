import React, { useEffect, useRef, useState } from 'react';
import { RingProgress, Gauge } from '@ant-design/plots';
import UseApi from '../../../../CustomHooks/useApi';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import ReactPaginate from "react-paginate";
import Contact from './Contact';
import UsePost from '../../../../CustomHooks/usePost';
import { Link } from 'react-router-dom';
import TableLoader from '../../Loaders/TableLoader';
import TopBarHeader from '../../../../CustomHooks/TopBarHeader';
import TopBarHeaderLine from '../../../../CustomHooks/TopBarHeaderLine';
const Monitoring = () => {

  const loaderFunc = () => {
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if (loader && no_record) {
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");

      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
  }
  function formatFloat(value) {
    const floatValue = parseFloat(value);
    return (floatValue < 1 ? "0." + (floatValue * 10000).toFixed(0) : floatValue.toFixed(2));
  }

  const ServerLoadProgress = ({ percent }) => {
    var serverLoad;
    if (percent == "0.00") {
      serverLoad = 0.00;
    } else {
      serverLoad = (parseFloat(percent) / 120) * 100;
    }

    var value = formatFloat(serverLoad);
    var percentValue = parseFloat(value);
    const config = {
      height: 100,
      width: 100,
      autoFit: false,
      percent: percentValue / 100,
      range: {
        color: 'l(0) 0:#B8E1FF 1:#3D76DD',
      },
      startAngle: Math.PI,
      endAngle: 2 * Math.PI,
      indicator: null,
      statistic: {
        title: {
          offsetY: -36,
          style: {
            fontSize: '36px',
            color: '#4B535E',
          },
        },
        content: {
          style: {
            fontSize: '19px',
            lineHeight: '24px',
            color: '#4B535E',
          },
        },
      },
    };
    return <Gauge {...config} />;;
  };

  const CpuRingProgress = ({ percent }) => {

    var value = formatFloat(percent);
    var percentValue = parseFloat(value);
    const config = {
      height: 80,
      width: 80,
      autoFit: false,
      percent: percentValue / 100, // Use the passed-in percent value
      color: ['#5B8FF9', '#E8EDF3'],
    };
    return <RingProgress {...config} />;
  };
  const RamRingProgress = ({ totalRam, usedRam }) => {
    var ramUsedPercentage;
    if (totalRam == "0.00") {
      ramUsedPercentage = 0.00;
    } else {
      ramUsedPercentage = (usedRam / totalRam) * 100;
    }

    var value = formatFloat(ramUsedPercentage);
    var percentValue = parseFloat(value);

    const config = {
      height: 80,
      width: 80,
      autoFit: false,
      percent: percentValue / 100, // Use the passed-in percent value
      color: ['#27AE12', '#E8EDF3'],
    };
    return <RingProgress {...config} />;
  };
  const DiskRingProgress = ({ percent }) => {
    var value = formatFloat(percent);
    var percentValue = parseFloat(value);
    const config = {
      height: 80,
      width: 80,
      autoFit: false,
      percent: percentValue / 100, // Use the passed-in percent value
      color: ['#FF5733', '#E8EDF3'],
    };
    return <RingProgress {...config} />;
  };


  const [monitoringData, setMonitoringData] = useState(null);
  const getMonitoringList = async () => {
    const data = await UseApi('get', 'monitoring', '');
    console.log(data,"chcucu");
    // console.log(data);
    if (data) {
      if (data.servers.length == 0) {
        setMonitoringData([]);
      } else {
        setMonitoringData(data.servers);
      }
    }
  }
  useEffect(() => {
    getMonitoringList();
  }, [])

  const openDrawer = (id) => {
    var drawerElement = document.querySelector("#" + id);
    drawerElement.classList.toggle("drawer-on");
  };

  function formatBytes(bytes) {
    if (bytes < 1024) {
      return bytes + " bytes";
    } else if (bytes < 1024 * 1024) {
      return (bytes / 1024).toFixed(2) + " KB";
    } else if (bytes < 1024 * 1024 * 1024) {
      return (bytes / (1024 * 1024)).toFixed(2) + " MB";
    } else {
      return (bytes / (1024 * 1024 * 1024)).toFixed(2) + " GB";
    }
  }



  const addNewServer = async () => {
    var submitBtn = document.querySelector("#addserver-btn");
    var server_name = document.querySelector("#server_name");
    var server_type = document.querySelector("#server_type");
    if (server_name && server_type) {
      if (server_name.value == "") {
        const dt = toast.loading("Please wait...");
        toast.update(dt, {
          render: "IP Address or Hostname must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        return;
      }
      if (server_name.value == "") {
        const dt = toast.loading("Please wait...");
        toast.update(dt, {
          render: "Please select server type!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        return;
      }
    }
    if (submitBtn) {
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      const dt = toast.loading("Please wait...");
      const responseData = await UsePost('post', 'monitoring/addserver', 'addserverForm');
      if (responseData) {
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
        if (responseData.status === 'error') {
          toast.update(dt, {
            render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        } else if (responseData.status === 'success') {
          toast.update(dt, {
            render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          openDrawer('addServer-drawer');
          getMonitoringList();

        }
      }
    }
  }
  return (
    <>
      {monitoringData != null ? (<>
        {monitoringData.length == 0 ? (<>
          <div className="col-xl-12">
            <div className="card">
              {/*begin::Card body*/}
              <div className="card-body p-0 mt-10">
                {/*begin::Illustration*/}
                <div className="text-center px-4 mt-10">
                  <span className="mw-100 mh-300px">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="234px"
                      height="234px"
                      fill="#21325B"
                      viewBox="-4 -4 32 32"
                    >
                      <path
                        d="M13 5.91517C15.8 6.41517 18 8.81519 18 11.8152C18 12.5152 17.9 13.2152 17.6 13.9152L20.1 15.3152C20.6 15.6152 21.4 15.4152 21.6 14.8152C21.9 13.9152 22.1 12.9152 22.1 11.8152C22.1 7.01519 18.8 3.11521 14.3 2.01521C13.7 1.91521 13.1 2.31521 13.1 3.01521V5.91517H13Z"
                        fill="#21325B"
                      />
                      <path
                        opacity="0.3"
                        d="M19.1 17.0152C19.7 17.3152 19.8 18.1152 19.3 18.5152C17.5 20.5152 14.9 21.7152 12 21.7152C9.1 21.7152 6.50001 20.5152 4.70001 18.5152C4.30001 18.0152 4.39999 17.3152 4.89999 17.0152L7.39999 15.6152C8.49999 16.9152 10.2 17.8152 12 17.8152C13.8 17.8152 15.5 17.0152 16.6 15.6152L19.1 17.0152ZM6.39999 13.9151C6.19999 13.2151 6 12.5152 6 11.8152C6 8.81517 8.2 6.41515 11 5.91515V3.01519C11 2.41519 10.4 1.91519 9.79999 2.01519C5.29999 3.01519 2 7.01517 2 11.8152C2 12.8152 2.2 13.8152 2.5 14.8152C2.7 15.4152 3.4 15.7152 4 15.3152L6.39999 13.9151Z"
                        fill="#21325B"
                      />
                    </svg>

                  </span>
                </div>
                {/*end::Illustration*/}
                {/*begin::Wrapper*/}
                <div className="card-px text-center py-5 mb-20">
                  {/*begin::Title*/}
                  <h1 className="fs-2x fw-bolder mb-5">Monitoring</h1>
                  <h2 className="fs-1x fw-bolder mb-5 text-muted">
                    Stay Ahead with Real-time Monitoring
                  </h2>
                  {/*end::Title*/}
                  {/*begin::Description*/}
                  <p className="text-gray-400 fs-4 fw-bold mb-10">
                    Discover the benefits of our monitoring solutions in enhancing performance and security. <br />Click the button below to create monitoring and gain real-time insights for<br /> better decision-making and a safer digital environment.              </p>
                  {/*end::Description*/}
                  {/*begin::Action*/}
                  <a
                    onClick={() => openDrawer('addServer-drawer')}
                    className="btn btn-primary mb-10"
                  >
                    Create Monitoring
                  </a>
                  {/*end::Action*/}
                </div>
                {/*end::Wrapper*/}
              </div>
              {/*end::Card body*/}
            </div>
          </div>
        </>) : (<>
          <div className="col-xl-12">
            {/*begin::Tables Widget 9*/}
            <div className="card card-xl-stretch mb-5 mb-xl-12">
              {/*begin::Header*/}
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold fs-3 mb-1">
                    Monitoring
                    <span className="text-muted mt-1 fw-bold fs-7" />
                  </span>
                </h3>
                <div
                  className="card-toolbar"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-trigger="hover"
                  title="Click to add a user"
                >
                  <div className="position-relative my-1" style={{ marginRight: 20 }}>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      data-kt-table-widget-4="search"
                      id="searchClient"
                      name="searchClient"
                      placeholder="Search"
                    />
                  </div>
                  <div id="cloud_select" style={{ marginRight: 20 }}>
                    <div className="dataTables_length">
                      <label className="w-125px">
                        <select
                          aria-controls="kt_datatable_example_1"
                          className="form-select form-select-sm form-select-solid select2-hidden-accessible"
                          value={300}
                          id="maxRows"
                          name="state"
                          data-select2-id="select2-data-maxRows"
                          tabIndex={-1}
                          aria-hidden="true"
                        >
                          <option
                            value={300}
                            selected=""
                            data-select2-id="select2-data-33-b863"
                          >
                            Show All
                          </option>
                          <option value={5}>5</option>
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                        <span
                          className="select2 select2-container select2-container--bootstrap5"
                          dir="ltr"
                          data-select2-id="select2-data-32-k8f6"
                          style={{ width: "100%" }}
                        >
                          <span className="selection">
                            <span
                              className="select2-selection select2-selection--single form-select form-select-sm form-select-solid"
                              role="combobox"
                              aria-haspopup="true"
                              aria-expanded="false"
                              tabIndex={0}
                              aria-disabled="false"
                              aria-labelledby="select2-maxRows-container"
                              aria-controls="select2-maxRows-container"
                            >
                              <span
                                className="select2-selection__rendered"
                                id="select2-maxRows-container"
                                role="textbox"
                                aria-readonly="true"
                                title="Show All"
                              >
                                Show All
                              </span>
                              <span
                                className="select2-selection__arrow"
                                role="presentation"
                              >
                                <b role="presentation" />
                              </span>
                            </span>
                          </span>
                          <span className="dropdown-wrapper" aria-hidden="true" />
                        </span>
                      </label>
                    </div>
                  </div>
                  <Contact />
                  <a
                    onClick={() => openDrawer('addServer-drawer')}
                    className="btn btn-sm btn-primary btn-active-light-primary"
                  >
                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
                    <span className="svg-icon svg-icon-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.5"
                          x="11.364"
                          y="20.364"
                          width={16}
                          height={2}
                          rx={1}
                          transform="rotate(-90 11.364 20.364)"
                          fill="black"
                        />
                        <rect
                          x="4.36396"
                          y="11.364"
                          width={16}
                          height={2}
                          rx={1}
                          fill="black"
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}Add New Server
                  </a>
                </div>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              {/*begin::Table container*/}
              <div className="table-responsive">
                {/*begin::Table*/}
                <table
                  id="clouddata"
                  className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
                >
                  {/*begin::Table head*/}
                  <thead className="bg-secondary">
                    <tr className="fw-bold ">
                      <th className="w-25px text-center"></th>
                      <th className="w-25px text-center">Name</th>
                      <th className="w-50px text-center">Type</th>
                      <th className="w-50px text-center">CPU</th>
                      <th className="w-50px text-center">RAM</th>
                      <th className="w-50px text-center">Disk</th>
                      <th className="w-50px text-center">Load</th>
                      <th className="w-50px text-center">Net</th>
                      <th className="w-50px text-start">Status</th>
                      <th className="w-100px text-center">Created At</th>
                      <th className="w-150px text-center" />
                    </tr>
                  </thead>
                  {/*end::Table head*/}
                  {/*begin::Table body*/}

                  <tbody id="monitor-body">
                    {monitoringData && monitoringData.map((value, index) => (<>

                      <tr>
                        <td></td>
                        <td className=" text-center">
                          {" "}
                          <div className="d-flex flex-column w-100 me-2">
                            {" "}
                            <div className=" flex-stack mb-2">
                              {" "}
                              <span className=" me-2 fs-7 fw-bold">{value.name}</span>{" "}
                            </div>{" "}
                          </div>{" "}
                        </td>{" "}
                        <td className="text-center">
                          {value.os ? (<>
                            <div className="d-flex flex-column w-100 ms-0">
                              {" "}
                              <div className="d-flex flex-stack">
                                {" "}
                                <span className={"fonticon_" + value.os.base} style={{ width: "50px", height: "50px" }} alt="" />{" "}
                              </div>{" "}
                            </div>
                          </>) : (<>
                            {/*begin::Svg Icon | path: assets/media/icons/duotune/social/soc002.svg*/}
                            <svg viewBox="-4 0 26 16" xmlns="http://www.w3.org/2000/svg" fill="none">
                              <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                              <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                              <g id="SVGRepo_iconCarrier">
                                <path
                                  fill="#202020"
                                  d="M13.338 12.033c-.1-.112-.146-.319-.197-.54-.05-.22-.107-.457-.288-.61v-.001a.756.756 0 00-.223-.134c.252-.745.153-1.487-.1-2.157-.312-.823-.855-1.54-1.27-2.03-.464-.586-.918-1.142-.91-1.963.014-1.254.138-3.579-2.068-3.582-.09 0-.183.004-.28.012-2.466.198-1.812 2.803-1.849 3.675-.045.638-.174 1.14-.613 1.764-.515.613-1.24 1.604-1.584 2.637-.162.487-.24.984-.168 1.454-.023.02-.044.041-.064.063-.151.161-.263.357-.388.489-.116.116-.282.16-.464.225-.183.066-.383.162-.504.395v.001a.702.702 0 00-.077.339c0 .108.016.217.032.322.034.22.068.427.023.567-.144.395-.163.667-.061.865.102.199.31.286.547.335.473.1 1.114.075 1.619.342l.043-.082-.043.082c.54.283 1.089.383 1.526.284a.99.99 0 00.706-.552c.342-.002.717-.146 1.318-.18.408-.032.918.145 1.503.113a.806.806 0 00.068.183l.001.001c.227.455.65.662 1.1.627.45-.036.928-.301 1.315-.762l-.07-.06.07.06c.37-.448.982-.633 1.388-.878.203-.123.368-.276.38-.499.013-.222-.118-.471-.418-.805z"
                                />
                                <path
                                  fill="#F8BF11"
                                  d="M13.571 12.828c-.007.137-.107.24-.29.35-.368.222-1.019.414-1.434.918-.362.43-.802.665-1.19.696-.387.03-.721-.13-.919-.526v-.002c-.123-.233-.072-.6.031-.987s.251-.785.271-1.108v-.001c.02-.415.044-.776.114-1.055.07-.28.179-.468.373-.575a.876.876 0 01.027-.014c.022.359.2.725.514.804.343.09.838-.204 1.047-.445l.122-.004c.184-.005.337.006.495.143v.001c.121.102.179.296.229.512.05.217.09.453.239.621.287.32.38.534.371.672zM6.592 13.843v.003c-.034.435-.28.672-.656.758-.377.086-.888 0-1.398-.266-.565-.3-1.237-.27-1.667-.36-.216-.045-.357-.113-.421-.238-.064-.126-.066-.345.071-.72v-.001l.001-.002c.068-.209.018-.438-.015-.653-.033-.214-.049-.41.024-.546l.001-.001c.094-.181.232-.246.403-.307.17-.062.373-.11.533-.27l.001-.001h.001c.148-.157.26-.353.39-.492.11-.117.22-.195.385-.196h.005a.61.61 0 01.093.008c.22.033.411.187.596.437l.533.971v.001c.142.296.441.622.695.954.254.333.45.666.425.921z"
                                />
                                <path
                                  fill="#D6A312"
                                  d="M9.25 4.788c-.043-.084-.13-.164-.28-.225-.31-.133-.444-.142-.617-.254-.28-.181-.513-.244-.706-.244a.834.834 0 00-.272.047c-.236.08-.392.25-.49.342-.02.019-.044.035-.104.08-.06.043-.15.11-.28.208-.117.086-.154.2-.114.332.04.132.167.285.4.417h.001c.145.085.244.2.358.291a.801.801 0 00.189.117c.072.031.156.052.26.058.248.015.43-.06.59-.151.16-.092.296-.204.452-.255h.001c.32-.1.548-.301.62-.493a.324.324 0 00-.008-.27z"
                                />
                                <path
                                  fill="#202020"
                                  d="M8.438 5.26c-.255.133-.552.294-.869.294-.316 0-.566-.146-.745-.289-.09-.07-.163-.142-.218-.193-.096-.075-.084-.181-.045-.178.066.008.076.095.117.134.056.052.126.12.211.187.17.135.397.266.68.266.284 0 .614-.166.816-.28.115-.064.26-.179.379-.266.09-.067.087-.147.162-.138.075.009.02.089-.085.18-.105.092-.27.214-.403.283z"
                                />
                                <path
                                  fill="#ffffff"
                                  d="M12.337 10.694a1.724 1.724 0 00-.104 0h-.01c.088-.277-.106-.48-.621-.713-.534-.235-.96-.212-1.032.265-.005.025-.009.05-.011.076a.801.801 0 00-.12.054c-.252.137-.389.386-.465.692-.076.305-.098.674-.119 1.09-.013.208-.099.49-.186.79-.875.624-2.09.894-3.122.19-.07-.11-.15-.22-.233-.328a13.85 13.85 0 00-.16-.205.65.65 0 00.268-.05.34.34 0 00.186-.192c.063-.17 0-.408-.202-.68-.201-.273-.542-.58-1.043-.888-.368-.23-.574-.51-.67-.814-.097-.305-.084-.635-.01-.96.143-.625.51-1.233.743-1.614.063-.046.023.086-.236.567-.232.44-.667 1.455-.072 2.248.016-.564.15-1.14.377-1.677.329-.747 1.018-2.041 1.072-3.073.029.02.125.086.169.11.126.075.221.184.344.283a.85.85 0 00.575.2c.24 0 .427-.079.582-.168.17-.096.304-.204.433-.245.27-.085.486-.235.608-.41.21.83.7 2.027 1.014 2.611.167.31.5.969.643 1.762.091-.002.191.01.299.038.375-.973-.319-2.022-.636-2.314-.128-.124-.135-.18-.07-.177.343.304.795.917.96 1.608.075.315.09.646.01.973.04.017.08.034.12.054.603.293.826.548.719.897z"
                                />
                                <path
                                  fill="#E6E6E6"
                                  d="M8.04 8.062c-.556.002-1.099.251-1.558.716-.46.464-.814 1.122-1.018 1.888l.061.038v.004c.47.298.805.598 1.012.878.219.296.316.584.223.834a.513.513 0 01-.27.283l-.041.015c.074.097.146.197.213.3.944.628 2.042.396 2.867-.172.08-.278.153-.536.163-.698.021-.415.042-.792.124-1.12.082-.33.242-.63.544-.795.017-.01.034-.015.051-.023a.756.756 0 01.022-.094c-.242-.622-.591-1.14-1.01-1.5-.42-.36-.897-.551-1.382-.554zm2.37 2.155l-.002.005v-.002l.001-.004z"
                                />
                                <path
                                  fill="#ffffff"
                                  d="M9.278 3.833a1.05 1.05 0 01-.215.656 4.119 4.119 0 00-.218-.09l-.127-.045c.029-.035.085-.075.107-.127a.669.669 0 00.05-.243l.001-.01a.673.673 0 00-.035-.236.434.434 0 00-.108-.184.223.223 0 00-.156-.07H8.57a.228.228 0 00-.151.06.434.434 0 00-.122.175.676.676 0 00-.05.243v.01a.718.718 0 00.009.14 1.773 1.773 0 00-.354-.12 1.196 1.196 0 01-.01-.133v-.013a1.035 1.035 0 01.088-.447.793.793 0 01.25-.328.554.554 0 01.346-.123h.006c.125 0 .232.036.342.116a.78.78 0 01.257.324c.063.138.094.273.097.433l.001.012zM7.388 3.997a1.05 1.05 0 00-.277.125.623.623 0 00.002-.15v-.008a.651.651 0 00-.048-.192.37.37 0 00-.096-.141.158.158 0 00-.119-.045c-.042.004-.077.024-.11.065a.372.372 0 00-.07.156.626.626 0 00-.013.205v.008a.634.634 0 00.048.193.367.367 0 00.116.156l-.102.08-.078.056a.706.706 0 01-.16-.24c-.053-.12-.082-.24-.09-.381v-.001a1.071 1.071 0 01.045-.39.668.668 0 01.167-.292.359.359 0 01.264-.118c.084 0 .158.028.235.09a.68.68 0 01.199.271c.053.12.08.24.089.382v.001c.003.06.003.115-.002.17z"
                                />
                                <path
                                  fill="#202020"
                                  d="M7.806 4.335c.01.034.065.029.097.045.027.014.05.045.08.046.03.001.076-.01.08-.04.005-.038-.052-.063-.088-.077-.047-.019-.107-.028-.151-.003-.01.005-.021.018-.018.03zM7.484 4.335c-.01.034-.065.029-.096.045-.028.014-.05.045-.081.046-.03.001-.076-.01-.08-.04-.005-.038.052-.063.088-.077.047-.019.108-.028.152-.003.01.005.02.018.017.03z"
                                />
                              </g>
                            </svg>
                            {/*end::Svg Icon*/}
                          </>)}

                        </td>{" "}
                        <td className="p-3">
                          {value.quickstats ? (<>
                            {value.quickstats.cpuused != null ? (<>
                              <CpuRingProgress percent={value.quickstats.cpuused} />
                            </>) : (<>
                              <CpuRingProgress percent="0.00" />
                            </>)}
                          </>) : ('')}
                        </td>
                        <td className="p-3">
                          {value.quickstats ? (<>
                            {value.quickstats.ramused ? (<>
                              <RamRingProgress totalRam={value.quickstats.ramtotal} usedRam={value.quickstats.ramused} />
                            </>) : (<>
                              <RamRingProgress totalRam="0.00" usedRam="0.00" />
                            </>)}
                          </>) : ('')}
                        </td>{" "}
                        <td className="p-3">
                          {value.quickstats ? (<>
                            {value.quickstats.totaldiskusedp ? (<>
                              <DiskRingProgress percent={value.quickstats.totaldiskusedp} />
                            </>) : (<>
                              <DiskRingProgress percent="0.00" />
                            </>)}
                          </>) : ('')}

                        </td>{" "}
                        <td className="text-center">
                          {" "}
                          {value.quickstats ? (<>
                            {value.quickstats.load1 == "" ? (<><ServerLoadProgress percent="0.00" /></>) : (<><ServerLoadProgress percent={value.quickstats.load1} /></>)}
                          </>) : ('')}

                          <div className="w-100 me-2 d-none">
                            {" "}
                            <div className="mb-2">
                              {" "}
                              <span className=" me-2 fs-7 fw-bold">{value.quickstats ? (<>
                                {value.quickstats.load1 == "" ? (<>-</>) : (<>{value.quickstats.load1}</>)}
                              </>) : ('')}</span>{" "}
                            </div>{" "}
                          </div>{" "}
                        </td>{" "}
                        <td className="text-center">
                          {" "}
                          <div className=" w-125px py-3 px-12 me-6 mb-3">
                            {" "}
                            {/*begin::Number*/}{" "}
                            <div className="d-flex align-items-center">
                              {" "}
                              <div className="fs-7 fw-bold sphide">
                                {value.quickstats ? (<>
                                  {formatBytes(value.quickstats.totalin)}
                                </>) : ('')}
                              </div>{" "}
                              {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}{" "}
                              <span className="svg-icon svg-icon-3 svg-icon-danger me-2">
                                {" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  {" "}
                                  <path
                                    d="M13 14.4V3C13 2.4 12.6 2 12 2C11.4 2 11 2.4 11 3V14.4H13Z"
                                    fill="black"
                                  />{" "}
                                  <path
                                    opacity="0.9"
                                    d="M4 14.4H20L12.7 21.7C12.3 22.1 11.7 22.1 11.3 21.7L4 14.4Z"
                                    fill="black"
                                  />{" "}
                                </svg>{" "}
                              </span>{" "}
                              {/*end::Svg Icon*/}{" "}
                            </div>{" "}
                            {/*end::Number*/} {/*begin::Number*/}{" "}
                            <div className="d-flex align-items-center">
                              {" "}
                              <div className="fs-7 fw-bold sphide">
                                {value.quickstats ? (<>
                                  {formatBytes(value.quickstats.totalout)}
                                </>) : ('')}
                              </div>{" "}
                              {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}{" "}
                              <span className="svg-icon svg-icon-3 svg-icon-success me-2">
                                {" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  {" "}
                                  <path
                                    d="M13 9.59998V21C13 21.6 12.6 22 12 22C11.4 22 11 21.6 11 21V9.59998H13Z"
                                    fill="black"
                                  />{" "}
                                  <path
                                    opacity="0.9"
                                    d="M4 9.60002H20L12.7 2.3C12.3 1.9 11.7 1.9 11.3 2.3L4 9.60002Z"
                                    fill="black"
                                  />{" "}
                                </svg>{" "}
                              </span>{" "}
                              {/*end::Svg Icon*/}{" "}
                            </div>{" "}
                            {/*end::Number*/}{" "}
                          </div>{" "}
                        </td>{" "}
                        <td className="text-start">
                          {" "}
                          <div className="flex-stack mb-2">
                            {" "}
                            {value.status == "OK" ? (<>
                              <span className=" me-2 fs-7 fw-bold badge badge-success badge-l">
                                Active
                              </span>
                            </>) : (<>
                              <span className=" me-2 fs-7 fw-bold badge badge-warning badge-l">
                                {value.status}
                              </span>
                            </>)}

                          </div>{" "}
                        </td>{" "}
                        <td className="text-center">
                          {" "}
                          <div className="d-flex flex-column w-100 me-2">
                            {" "}
                            <div className="d-flex flex-stack mb-2">
                              {" "}
                              <span className=" me-2 fs-7 fw-bold sphide">
                                {value.created_at}
                              </span>{" "}
                            </div>{" "}
                          </div>{" "}
                        </td>{" "}
                        <td className="text-center">
                          {" "}
                          <div className="d-flex justify-content-end flex-shrink-0">
                            {" "}
                            <Link to={"/monitoring/" + value.id}>
                              <a
                                className="btn btn-primary btn-active-light-primary btn-sm me-1 me-5 sphide"
                              >
                                {" "}
                                View{" "}
                                <span className="svg-icon ms-2 svg-icon-3">
                                  {" "}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    {" "}
                                    <rect
                                      opacity="0.5"
                                      x={18}
                                      y={13}
                                      width={13}
                                      height={2}
                                      rx={1}
                                      transform="rotate(-180 18 13)"
                                      fill="black"
                                    />{" "}
                                    <path
                                      d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                      fill="black"
                                    />{" "}
                                  </svg>{" "}
                                </span>{" "}
                              </a>
                            </Link>
                            {" "}
                          </div>{" "}
                        </td>{" "}
                      </tr>
                    </>))}
                  </tbody>
                  {/*end::Table body*/}
                </table>
                {/*end::Table*/}
              </div>
              {/*end::Table container*/}
              {/*begin::Body*/}
            </div>
            {/*end::Tables Widget 9*/}
          </div>
        </>)}
      </>) : (<>
        <TableLoader />
        <TopBarHeaderLine />
      </>)}




      <div
        id="addServer-drawer"
        className="bg-white drawer drawer-end"
        data-kt-drawer="true"
        data-kt-drawer-activate="true"
        data-kt-drawer-close="#kt_drawer_example_dismiss_close"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
        style={{ maxWidth: "70%", important: true }}
      >
        {/*begin::Messenger*/}
        <div
          className="card w-100 "
          id="kt_drawer_chat_messenger"
          style={{ width: 500 }}
        >
          {/*begin::Card header*/}
          <div
            className="card-header pe-5 bg-light-primary"
            id="kt_drawer_chat_messenger_header"
          >
            {/*begin::Title*/}
            <div className="card-title">
              {/*begin::User*/}
              <div className="d-flex justify-content-center flex-column me-3">
                {" "}
                <a href="#" className="fs-4 fw-bolder text-primary me-1 mb-2 lh-1">
                  Add New Server
                </a>{" "}
              </div>
              {/*end::User*/}
            </div>
            {/*end::Title*/}
            {/*begin::Card toolbar*/}
            <div className="card-toolbar">
              {/*begin::Close*/}
              <div onClick={() => openDrawer('addServer-drawer')}
                id="kt_drawer_example_dismiss_close"
                className="btn btn-sm btn-icon btn-active-light-primary"
              >
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                <span className="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x={6}
                      y="17.3137"
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(-45 6 17.3137)"
                      fill="black"
                    />
                    <rect
                      x="7.41422"
                      y={6}
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(45 7.41422 6)"
                      fill="black"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
              </div>
              {/*end::Close*/}
            </div>
            {/*end::Card toolbar*/}
          </div>
          {/*end::Card header*/}
          {/*begin::Card body*/}
          <div className="" id="">
            {/*begin::Form*/}
            <form className="form" id="addserverForm">
              {/*begin::Modal body*/}
              <div className="modal-body ">
                {/*begin::Scroll*/}
                <div
                  className="scroll-y me-n7 pe-7"
                  id="kt_modal_create_api_key_scroll"
                  data-kt-scroll="true"
                  data-kt-scroll-activate="{default: false, lg: true}"
                  data-kt-scroll-max-height="auto"
                  data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                  data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                  data-kt-scroll-offset="300px"
                >
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-xxl-12 mb-10">
                      {" "}
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="required form-label"
                      >
                        Server Name
                      </label>{" "}
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        id="server_name"
                        placeholder="IP or Hostname"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-xxl-12 mb-10">
                      {" "}
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="required form-label"
                      >
                        Platform
                      </label>{" "}
                      <select
                        className="form-select"
                        name="type"
                        id="server_type"
                        tabIndex={0}
                        aria-hidden="false"
                      >
                        <option value="linux">Linux</option>
                        <option value="windows">Windows</option>
                      </select>
                    </div>{" "}
                    `
                  </div>
                </div>
                <div className="text-center">
                  <a
                    href="#"
                    className="btn btn-primary"
                    id="addserver-btn"
                    onClick={addNewServer}
                    style={{ width: "100%" }}
                  >
                    Add Server
                  </a>
                </div>
                {/*end::Scroll*/}
              </div>
              {/*end::Modal body*/}
              {/*begin::Modal footer*/}
              {/*end::Modal footer*/}
            </form>
            {/*end::Form*/}
          </div>
          {/*end::Card body*/}
        </div>
        {/*end::Messenger*/}
      </div>

    </>
  );
}

export default Monitoring;
