import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom/dist";
import UseDevPost from "../../../../CustomHooks/useDevPost";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UseApi from "../../../../CustomHooks/useApi";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import UsePostData from "../../../../CustomHooks/usePostData";
import spinner from "../billing/spinner.svg";
import { publicIp, publicIpv4, publicIpv6 } from 'public-ip';
function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}

const DeployArchiveStorage = () => {
  const forceUpdate = useForceUpdate();
  const [adcdata, setAdcdata] = useState([]);
  const [ddcdata, setDdcdata] = useState([]);
  const [cloudData, setCloudData] = useState(null);
  const dataFetchedRef2 = useRef();
  const navigate = useNavigate();
  const dczoneStatus = useRef(true);

  const makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
  const getDczone = async () => {
    const data = await UseApi("get", "dczones", "");
    if (Object.keys(data).length === 0) {
      setAdcdata([]);
      setDdcdata([]);
    } else {
      if (dczoneStatus.current) {
        data.dczones.map(async (value, index) => {
          if (value.status !== "active") {
            var server_ip = await publicIpv4();
            if (server_ip) {
              if (
                server_ip !== "103.209.144.51" ||
                server_ip !== "103.209.144.66" ||
                server_ip !== "103.209.145.208" ||
                server_ip !== "103.209.144.81" ||
                server_ip !== "103.209.144.82" ||
                server_ip !== "103.209.144.83" ||
                server_ip !== "103.127.30.91" ||
                server_ip !== "103.209.144.60" ||
                server_ip !== "103.127.30.239" ||
                server_ip !== "103.127.28.52"
              ) {
                ddcdata.push(value);
              }
              forceUpdate();
            }
          } else {
            adcdata.push(value);
            forceUpdate();
          }
        });
        dczoneStatus.current = false;
      }
    }
  };

  const getCloud = async () => {
    const data = await UseApi('get', 'cloud', '');

    if ('status' in data) {
      if (data.status == "error") {
        setCloudData(null);
      }
    }
    if (Object.keys(data).length === 0) {
      setCloudData(null);
    } else {
      setCloudData(data.cloud);
    }

  }
  useEffect(() => {
    if (dataFetchedRef2.current) return;
    dataFetchedRef2.current = true;
    getDczone();
    getCloud();
  }, []);



  const selectDc = async (slug) => {
    var dcSelect = document.querySelectorAll([".tickMark"]);
    var currentDc = document.querySelector("#tickMark-" + slug);
    if (dcSelect) {
      dcSelect.forEach((clist) => {
        clist.classList.add("d-none");
      });
    }
    if (currentDc) {
      currentDc.classList.remove("d-none");
    }

  };


  const addArchiveStorage = async () => {
    var submitBtn = document.querySelector("#archive-btn");

    if (submitBtn) {
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML = '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      const dt = toast.loading("Please wait...");
      const responseData = await UseDevPost('post', 'archive/deploy', 'archivedeploy');
      if (responseData) {
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
        if (responseData.status === 'error') {
          toast.update(dt, {
            render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
        } else if (responseData.status === 'success') {
          toast.update(dt, {
            render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });

          navigate("/archiveStorage");
        }
      }
    }
  }

  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <form id="archivedeploy">
            {/*begin::Tables Widget 9*/}
            <div className="card card-xl-stretch mb-5 mb-xl-12">
              {/*begin::Header*/}
              <div className="card-header border-0 mb-5 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bolder text-gray-800 mb-1">
                    Select DC Location
                  </span>
                  <span className="text-muted mt-1 fw-bold fs-7">
                    Data Centers
                  </span>
                </h3>
              </div>
              {/*end::Header*/}
              {/* Begin body */}
              <div className="container mb-5">
                {/*begin::Row*/}
                <div
                  id="dc-location"
                  className="row g-7"
                  data-kt-buttons="true"
                  data-kt-buttons-target="[data-kt-button]"
                >
                  {adcdata.length > 0
                    ? adcdata &&
                    adcdata.map((value, index) => (
                      <>
                        <div className="col-md-4 col-lg-4 col-sm-6 col-xxl-4 dc_sibling">
                          <input
                            className="form-check-input deploy_checkbox "
                            type="radio"
                            name="dcslug"
                            defaultValue={value.slug}
                            id={"dcslug" + value.slug + ""}
                          />

                          <label
                            onClick={() => selectDc(value.slug)}
                            style={{ WebkitJustifyContent: "unset" }}
                            id={"dcslugl_" + value.slug + ""}
                            htmlFor={"dcslug" + value.slug + ""}
                            className="position-relative uptrans dcsluglable border-1 border-gray-300 border px-1 py-1"
                          >
                            <div className=" child">
                              <div className="p-3 ">
                                <div className="row">
                                  <div className="col-4">
                                    <img
                                      src={
                                        "/assets/media/flags/svg/" +
                                        value.cc +
                                        ".svg"
                                      }
                                      className="w-50px "
                                      alt=""
                                    />
                                  </div>
                                  <div className="col-8">
                                    <p className="no-margin text-dark fw-bolder text-hover-primary ">
                                      {value.city}
                                      <span className="text-muted d-block fw-bold ">
                                        {value.country}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <span className="d-none child position-absolute top-0 start-100 translate-middle  badge badge-circle badge-primary">
                              5
                            </span>
                            {/*begin::Ribbon*/}
                            <div
                              className="d-none tickMark ribbon ribbon-triangle ribbon-top-start border-primary"
                              id={"tickMark-" + value.slug}
                            >
                              {/*begin::Ribbon icon*/}
                              <div className="ribbon-icon mt-n5">
                                <i className="bi bi-check2 fs-2 text-white" />
                              </div>
                              {/*end::Ribbon icon*/}
                            </div>
                            {/*end::Ribbon*/}
                          </label>
                        </div>
                      </>
                    ))
                    : (<><div className="small-spinner"></div></>)}
                  {ddcdata.length > 0
                    ? !ddcdata &&
                    ddcdata.map((value, index) => (
                      <>
                        <div className="col-md-4 col-lg-4 col-sm-6 col-xxl-4 dc_sibling">
                          <input
                            className="form-check-input deploy_checkbox deploylocationsoldout"
                            type="radio"
                            name="dcslug"
                            onClick={(e) => (e.target.checked = false)}
                            defaultValue={value.slug}
                            id={"dcslug" + value.slug + ""}
                          />
                          <label
                            onClick={() => selectDc(value.slug)}
                            style={{ WebkitJustifyContent: "unset" }}
                            id={"dcslugl_" + value.slug + ""}
                            htmlFor={"dcslug" + value.slug + ""}
                            className="uptrans dcsluglable border-1 border-gray-300 border px-1 py-1"
                          >
                            <div className="child">
                              <div className="p-3 ">
                                <div className="row">
                                  <div className="col-4">
                                    <img
                                      src={
                                        "/assets/media/flags/svg/" +
                                        value.cc +
                                        ".svg"
                                      }
                                      className="w-50px "
                                      alt=""
                                    />
                                  </div>
                                  <div className="col-8">
                                    <p className="no-margin text-dark fw-bolder text-hover-primary ">
                                      {value.city}
                                      <span className="text-muted d-block fw-bold ">
                                        {value.country}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </label>
                        </div>
                      </>
                    ))
                    : (<></>)}
                </div>
                {/*end::Row*/}
              </div>
              {/* End body */}

              {/*begin::Col*/}

              {/*begin::Col*/}
              {/*begin::Col*/}
              <div className="col-xl-12">
                {/*begin::List widget 10*/}

                <div className="card-header border-0">
                  {/*begin::Title*/}
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder text-gray-800">
                      Select storage size
                    </span>
                    <span className="text-gray-400 mt-1 fw-bold fs-6">
                      Storage size as per your server need
                    </span>
                  </h3>
                </div>

                <div
                  className="row g-9 container"
                  data-kt-buttons="true"
                  data-kt-buttons-target="[data-kt-button]"
                >
                  <div className="col-md-4 col-lg-4 col-xxl-4">
                    <div className="">
                      {/*begin::Input group*/}
                      <div className="input-group mb-5">
                        <input
                          type="number"
                          name="size"
                          className="form-control"
                          defaultValue={10}
                        />
                        <span className="input-group-text" id="basic-addon2">
                          GB
                        </span>
                      </div>
                      {/*end::Input group*/}
                    </div>
                  </div>
                </div>


              </div>
              {/*end::Col*/}
              {/*begin::Col*/}
              <div className="col-xl-12">
                {/*begin::List widget 10*/}

                <div className="card-header border-0">
                  {/*begin::Title*/}
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder text-gray-800">
                      Select cloud server
                    </span>
                    <span className="text-gray-400 mt-1 fw-bold fs-6">
                      choose server for archive storage
                    </span>
                  </h3>
                </div>

                <div
                  className="row g-9 container"
                  data-kt-buttons="true"
                  data-kt-buttons-target="[data-kt-button]"
                >

                  <div className="col-md-4 col-lg-4 col-xxl-4">
                    <div className="mb-5" id="cluster_v">
                      <select
                        className="form-select"
                        name="cloudid"
                      >
                        <option value="">
                          Please select cloud server
                        </option>
                        {cloudData != null && Object.keys(cloudData).length != 0 ? (cloudData && cloudData.map((cloud, index) => (<>
                          <option value={cloud.cloudid}>
                            {cloud.hostname}
                          </option>
                        </>))) : ('')}

                      </select>
                    </div>
                  </div>
                </div>


              </div>
              {/*end::Col*/}
              {/*begin::Col*/}
              <div className="col-xl-12 mb-5">
                {/*begin::List widget 10*/}

                <div className="card-header border-0">
                  {/*begin::Title*/}
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder text-gray-800">
                      Label Archive Storage
                    </span>
                    <span className="text-gray-400 mt-1 fw-bold fs-6">
                      Input the archive storage name
                    </span>
                  </h3>
                </div>

                <div
                  className="row g-9 container"
                  data-kt-buttons="true"
                  data-kt-buttons-target="[data-kt-button]"
                >
                  <div className="col-md-4 col-lg-4 col-xxl-4">
                    <div className="">
                      {/*begin::Input group*/}
                      <div className="input-group mb-5">
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={"archive-" + makeid(8) + "-storage"}
                          placeholder="storage_1"
                          name="label"
                        />
                      </div>
                      {/*end::Input group*/}
                    </div>
                  </div>
                </div>


              </div>
              {/*end::Col*/}
              <div className="container">
                <a class="btn btn-primary w-100 mb-10" id="archive-btn" onClick={addArchiveStorage}>Create Storage</a>
              </div>
            </div>
            {/*end::Tables Widget 9*/}
          </form>
        </div>

      </div>

    </>
  );
};

export default DeployArchiveStorage;
