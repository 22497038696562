import React, {useEffect, useState} from 'react';
import TableLoader from '../../../../Loaders/TableLoader';
import { connect } from 'react-redux';
import { appendAclConditions } from '../../../../../../Redux/Services/Action/actions';

function Conditions(props){
    function formatString(inputString) {
        // Split the input string by underscores
        if(inputString){
        const words = inputString.split('_');
      
        // Capitalize the first letter of each word
        const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
      
        // Join the words into a formatted string
        const formattedString = capitalizedWords.join(' ');
      
        return formattedString;
        }
      }
      
      const [conditionData, setConditionData] = useState(null);

      const getConInfo=()=>{
        if(props && props.toggleBtn){
          props.toggleBtn("close");
        } 
        setConditionData(null);
        if(props && props.aclConditionData.length>0){ 
          setConditionData(props.aclConditionData[props.indexId])
         }
         setTimeout(()=>{
          if(props && props.toggleBtn){
            props.toggleBtn("show");
          } 
        },100)
      }
      useEffect(()=>{ 
        getConInfo();
      },[props])

      const addNewCondition=()=>{ 
        if(props && props.toggleBtn){
          props.toggleBtn("close");
        } 
        
        setConditionData(null);
        if(props && props.aclConditionData){
             if(props.aclConditionData[props.indexId].data){ 
            props.aclConditionData[props.indexId].data.push("");
            console.log(props.aclConditionData[props.indexId]); 
            }
            setTimeout(()=>{
              setConditionData(props.aclConditionData[props.indexId]); 
              if(props && props.toggleBtn){ 
              } 
            },1)
            setTimeout(()=>{
              if(props && props.toggleBtn){
                props.toggleBtn("show");
              } 
            },100)
           
        }
      }

    const handleChangeCon=(indexNumber, eleValue)=>{
      console.log(eleValue, indexNumber);
      if(props && props.aclConditionData){
        if(props.aclConditionData[props.indexId].data){ 
          props.aclConditionData[props.indexId].data.map((value,index)=>{
            if(index==indexNumber){ 
              props.aclConditionData[props.indexId].data[index]=eleValue;
            }
          }) 
       }
    }
    }

    const handleDeleteCon=(indexNumber)=>{ 
      if(props && props.toggleBtn){
        props.toggleBtn("close");
      } 
      setConditionData(null);
       if(props && props.aclConditionData){
        if(props.aclConditionData[props.indexId].data){ 
          props.aclConditionData[props.indexId].data.map((value,index)=>{
            if(index==indexNumber){ 
              props.aclConditionData[props.indexId].data.splice(index,1);
            }
          }) 
       }
       setTimeout(()=>{
        setConditionData(props.aclConditionData[props.indexId]);  
      },1)
      setTimeout(()=>{
        if(props && props.toggleBtn){
          props.toggleBtn("show");
        } 
      },100)
     
   }
    }
 
    return(<>
    {conditionData!=null?(<>
     <div className="mb-5 fv-row row">
        <div className='col-12'>
    {/*begin::Label*/}
    <label className="fs-5 mb-2">
        {" "}
       <span className='fw-bold required'>{formatString(conditionData.type)}</span>
     </label>
    {/*end::Label*/}  
    </div>
     
     {conditionData.data && conditionData.data.length>0?(<>
     {conditionData.data.map((value,index)=>(<>
      <React.Fragment key={`condition-${index}`}>
     {/* Fields inputs-item start */}
    <div className='col-lg-2 col-xl-2 col-md-2'>
     {/*begin::Input*/}
     {index==0?(<>    
     <input
        type="text"
        className="form-control border-0 read text-center fs-3"
        value="is"
    />
    </>):(<>
        <input
        type="text"
        className="form-control border-0 read text-center fs-3"
        value="or"
    />
    </>)}

    {/*end::Input*/}
    </div> 
    <div className='col-lg-8 col-xl-8 col-md-8'>
        {/*begin::Input*/}
    <input
        type="text"
        className="form-control"
        placeholder="Value" 
        defaultValue={value}
        onChange={(e)=>handleChangeCon(index, e.target.value)}
        id={conditionData.type+"-"+index}
    />
    {/*end::Input*/}
    </div>
    <div className='col-lg-2 col-xl-2 col-md-2'>
    {conditionData.data.length>1?(<>
{/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen027.svg*/}
<span style={{cursor:"pointer"}} onClick={(e)=>handleDeleteCon(index)} className="svg-icon svg-icon-danger svg-icon-2hx">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
        fill="black"
      />
      <path
        opacity="0.5"
        d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
        fill="black"
      />
      <path
        opacity="0.5"
        d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
        fill="black"
      />
    </svg>
  </span>
  {/*end::Svg Icon*/}
  </>):('')}
    </div>
    <div className='col-lg-2 col-xl-2 col-md-2'></div>    
    <div className='col-lg-8 col-xl-8 col-md-8'>
        <span className='fs-6 text-gray-500 pt-3'> Maximum 128 characters. Allowed characters are a-z, A-Z, 0-9; the following special characters:
        _-.$/~"'@:+&()!,;=; and wildcards (* and ?).
        </span>
    </div>
    <div className='col-lg-2 col-xl-2 col-md-2'></div>
    {/* Fields inputs-item end */}
    </React.Fragment>
    </>))}
     </>):('')}

    <div className='col-lg-12 col-xl-12 col-md-12 mb-8'></div> 

    <div className='col-lg-9 col-xl-9 col-md-9 mb-8'></div> 
    <div className='col-lg-3 col-xl-3 col-md-3 mb-8'>
        <a onClick={addNewCondition} className='btn btn-active-light-dark btn-light-dark border w-100 text-gray-600'> 
        <span className="svg-icon svg-icon-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              opacity="0.5"
                              x="11.364"
                              y="20.364"
                              width={16}
                              height={2}
                              rx={1}
                              transform="rotate(-90 11.364 20.364)"
                              fill="black"
                            />
                            <rect x="4.36396" y="11.364" width={16} height={2} rx={1} fill="black" />
                          </svg>
                        </span>
         Add New Value</a>
    </div>
   
    </div>
    </>):(<><TableLoader /></>)}
    </>)
}

const mapStateToProps = (state) => ({
  aclConditionData: state.aclConditionInfo?.aclConditions, 
});

const mapDispatchToProps = (dispatch) => ({
  addAclProps: (data) => dispatch(appendAclConditions(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Conditions);