import { toast } from "react-toastify";

export default function ToastMessage(response, toastId){
    const type = response.status === "success" ? "success" : "error";
    toast.update(toastId, {
      render: response.message,
      type,
      isLoading: false,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };