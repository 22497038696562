import React, { useState, useEffect } from 'react';
import PhoneInput from 'react-phone-number-input';
import Select from 'react-select';
import CountryFlag  from 'react-country-flag';
import swal from 'sweetalert';   
import MobileVerification from './steps/MobileVerification';
import CardVerification from './steps/CardVerification';
import KycVerifcation from './steps/KycVerifcation';
import Finish from './steps/Finish';
import UseApi from '../../../../CustomHooks/useApi';
import './steps/custom.css';
import { Link, useNavigate } from 'react-router-dom';
import TableLoader from '../../Loaders/TableLoader';
import TopBarHeaderLine from '../../../../CustomHooks/TopBarHeaderLine';
const Verification = () => {

  const navigate=useNavigate();
    const navRedirect=()=>{
      setTimeout(() => {
        navigate('/');
       }, 2000);
        
    }
  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  const [userDatas, setUserDatas]=useState(null); 

  async function getUserInfo(){

    const data=await UseApi('get','account/info','');  
    if(data){
        if(data.user){
            setUserDatas(data.user); 
        }
    }
  }

  function getTabs(userD){ 
      var chooseStepTab1=document.querySelector("#chooseStep-tab-1");
      var chooseStepTab2=document.querySelector("#chooseStep-tab-2");
      // var chooseStepTab3=document.querySelector("#chooseStep-tab-3");
      // if(chooseStepTab1 && chooseStepTab2 && chooseStepTab3){ 
        if(chooseStepTab1 && chooseStepTab2){ 
      chooseStepTab1.classList.remove("current");
        chooseStepTab2.classList.remove("current");
        // chooseStepTab3.classList.remove("current");
      }
      if(userD){
        if(userD.sms_verified==0){
          // alert("step1 ");
           var chooseStepTab=document.querySelector("#chooseStep-tab-1"); 
          if(chooseStepTab){ 
            chooseStepTab.classList.add("current");
          }
          return;
        }else if(userD.c_added=='no' && userD.rvn==null){
          // alert("step2 ");
         var chooseStepTab=document.querySelector("#chooseStep-tab-2"); 
        if(chooseStepTab){ 
          chooseStepTab.classList.add("current");
        }
        return;
      }
      
      // else if(userD.kyc==0){
      //     var chooseStepTab=document.querySelector("#chooseStep-tab-3"); 
      //   if(chooseStepTab){ 
      //     chooseStepTab.classList.add("current");
      //   }
      //   return;
      // } 
    } 
  }

  useEffect(()=>{
    getUserInfo();  
  },[])

  useEffect(()=>{
    if(userDatas!=null){
      getTabs(userDatas);
    }
  },[userDatas])
 
  // function chooseStep(id){
  //     for(var i=1;i<=4;i++){
  //       var chooseStep=document.querySelector("#chooseStep-"+i);
  //       var chooseStepTab=document.querySelector("#chooseStep-tab-"+i);
  //       if(i==id){
  //         if(chooseStep && chooseStepTab){
  //           chooseStep.classList.add("current");
  //           chooseStepTab.classList.add("current");
  //         }
  //       }else{
  //         if(chooseStep && chooseStepTab){
  //           chooseStep.classList.remove("current");
  //           chooseStepTab.classList.remove("current");
  //         }
  //       }
  //     }
  // }



    return (
        <> 
           {userDatas != null?(<>
         
<div className="card">
  <div className="card-body">
  {/*begin::Stepper*/}
  <div className="stepper stepper-pills mb-20" id="kt_stepper_example_clickable">
    {/*begin::Nav*/}
    <div className="stepper-nav flex-center flex-wrap mb-10">
   
      {/*begin::Step 1*/}
      {userDatas.sms_verified == "1" ? (<>
        <div
        className="stepper-item mx-2 my-4"
        id="chooseStep-1" 
      >
        {/*begin::Line*/}
        <div className="stepper-line w-40px" />
        {/*end::Line*/}
        {/*begin::Icon*/}
        <div className="stepper-icon w-40px h-40px">
          <i className="stepper-check fas fa-check text-primary d-block" /> 
        </div>
        {/*end::Icon*/}
        {/*begin::Label*/}
        <div className="stepper-label">
          <h3 className="stepper-title">Mobile</h3>
          <div className="stepper-desc">Verification</div>
        </div>
        {/*end::Label*/}
      </div>
      </>):(<> 
      <div
        className="stepper-item mx-2 my-4 current"
        id="chooseStep-1" 
      >
        {/*begin::Line*/}
        <div className="stepper-line w-40px" />
        {/*end::Line*/}
        {/*begin::Icon*/}
        <div className="stepper-icon w-40px h-40px">
          <i className="stepper-check fas fa-check text-primary" />
          <span className="stepper-number">1</span>
        </div>
        {/*end::Icon*/}
        {/*begin::Label*/}
        <div className="stepper-label">
          <h3 className="stepper-title">Mobile</h3>
          <div className="stepper-desc">Verification</div>
        </div>
        {/*end::Label*/}
      </div>
      </>)} 
      {/*end::Step 1*/}
      {/*begin::Step 2*/} 
      {userDatas.c_added? (<>
        {userDatas.c_added != "no" || userDatas.rvn !=null?(<>
        
          <div
        className="stepper-item mx-2 my-4"
        id="chooseStep-2" 
      >
        {/*begin::Line*/}
        <div className="stepper-line w-40px" />
        {/*end::Line*/}
        {/*begin::Icon*/}
        <div className="stepper-icon w-40px h-40px">
          <i className="stepper-check fas fa-check d-block text-primary" /> 
        </div>
        {/*begin::Icon*/}
        {/*begin::Label*/}
        <div className="stepper-label">
          <h3 className="stepper-title">Billing</h3>
          <div className="stepper-desc">Verify Card</div>
        </div>
        {/*end::Label*/}
      </div>
        </>):(<> 
      <div
        className="stepper-item mx-2 my-4"
        id="chooseStep-2" 
      >
        {/*begin::Line*/}
        <div className="stepper-line w-40px" />
        {/*end::Line*/}
        {/*begin::Icon*/}
        <div className="stepper-icon w-40px h-40px">
          <i className="stepper-check fas fa-check" />
          <span className="stepper-number">2</span>
        </div>
        {/*begin::Icon*/}
        {/*begin::Label*/}
        <div className="stepper-label">
          <h3 className="stepper-title">Billing</h3>
          <div className="stepper-desc">Verify Card</div>
        </div>
        {/*end::Label*/}
      </div>
      </>)}
      </>):('')} 
      {/*end::Step 2*/}
      {/*begin::Step 3*/}
      {/* {userDatas.kyc == "1" ? (<>
        <div
        className="stepper-item mx-2 my-4"
        id="chooseStep-3"  
      > 
        <div className="stepper-line w-40px" />
      
        <div className="stepper-icon w-40px h-40px">
          <i className="stepper-check fas fa-check d-block text-primary" /> 
        </div>
       
        <div className="stepper-label">
          <h3 className="stepper-title">KYC</h3>
          <div className="stepper-desc">Complete KYC</div>
        </div> 
      </div>
      </>):(<> 
      <div
        className="stepper-item mx-2 my-4"
        id="chooseStep-3"  
      > 
        <div className="stepper-line w-40px" />
       
        <div className="stepper-icon w-40px h-40px">
          <i className="stepper-check fas fa-check" />
          <span className="stepper-number">3</span>
        </div> 
        <div className="stepper-label">
          <h3 className="stepper-title">KYC</h3>
          <div className="stepper-desc">Complete KYC</div>
        </div>
       </div>
      </>)}  */}
      {/*end::Step 3*/} 
     
    </div>
    {/*end::Nav*/}
    {/*begin::Form*/}
    <form
      className="form w-100 mx-auto mb-20"
      noValidate="novalidate"
      id="kt_stepper_example_basic_form"
    >
      {/*begin::Group*/}
      <div className="mb-5">
     
        {/*begin::Step 1*/}
        <div className="flex-column current" id="chooseStep-tab-1" data-kt-stepper-element="content">
        {userDatas == null?(''):(<>{ userDatas.sms_verified == "1" ? (''):(<>
          <MobileVerification />
          </>)}
        </>)}
        </div>
        {/*end::Step 1*/}
        {/*begin::Step 2*/}
        <div className="flex-column" id="chooseStep-tab-2" data-kt-stepper-element="content">
        {userDatas != null?(<>
      {userDatas.c_added? (<>
        {userDatas.c_added != "no" || userDatas.rvn !=null?(''):(<> 
            <CardVerification />
          </>)}
          </>):('')}
          </>):('')}
        </div>
        {/*end::Step 2*/}
        {/*begin::Step 3*/}
        {/* <div className="flex-column" id="chooseStep-tab-3" data-kt-stepper-element="content">       
          {userDatas != null && userDatas.kyc == "1" ? (''):(<>
            <KycVerifcation />
          </>)}
        </div> */}
        {/*end::Step 3*/}
 
        
      </div>
      {/*end::Group*/} 
    </form>
    {/*end::Form*/}
  </div>
  {/*end::Stepper*/} 
  </div>
  </div> 
      </>):(<> <TableLoader/>
      <TopBarHeaderLine/></>)}
        </>
    );
}

export default Verification;
