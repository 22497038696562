import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UsePost from "../../../../CustomHooks/usePost";
import swal from "sweetalert";
import copy from "copy-to-clipboard";
import ReactPaginate from "react-paginate";
import AccessDenied from "../../AccessDenied";
import { event } from "jquery";
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../Loaders/TableLoader";
import CustomDoc from "../../../../CustomHooks/CustomDoc";
import ProductsUrl from "../../Docs/Docsdata";
export default function Ssh() {
  const [dataFetched, setDataFetched] = useState(false);
  const [pageStatus, setPageStatus] = useState(false);
  const [access, setAccess] = useState(true);
  const [perPage, setPerPage] = useState(5);
  const [sshData, setSshData] = useState(null);
  const [dummySshData, setDummySshData] = useState(null);
  const [activePage, setActivePage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const dataFetchedRef = useRef(false);
  const navigate = useNavigate();
  const navRedirect = () => {
    setTimeout(() => {
      navigate("/ssh");
    }, 3000);
  };
   
  const getSSH = async () => {
     const data = await UseApi("get", "key", "");
    if (data) {
      setDataFetched(true);
    }
    if ("status" in data) {
      if (data.status == "error") {
        const dt = toast.loading("loading...");
        toast.update(dt, {
          render: data.message,
          type: "warning",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (data.message == "Permission denied.") {
          setAccess(false);
        } else {
          setAccess(true)
        }
        setSshData([]);
        setDummySshData([])
        // loaderFunc();
        return;
      }
    } 
    if (data && data?.key?.length === 0) {
      setSshData([]);
      setDummySshData([]) 
      // loaderFunc();
    } else {
      console.log(data.key);
      setSshData(data?.key);
      setDummySshData(data?.key)
      setPageCount(Math.ceil(data?.key?.length / perPage))
      toast.dismiss();
      //    toast.update(dt,{render: "Your SSH Keys request has been successful", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      //  });
    }
  };
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getSSH();
  }, []);

  const deleteSSH = async (id) => {
    toast.dismiss();
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this SSH Key!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const deletesshkey = document.querySelector("#deletessh-" + id);
        if (deletesshkey) {
          setPageStatus(true);
          var preContent = deletesshkey.innerHTML;
          deletesshkey.classList.add("disabled");
          deletesshkey.innerHTML =
            'Please wait... <span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';

          const dt = toast.loading("SSH Keys deleting...");
          const dnsp = await UseApi("delete", "key/" + id + "/delete", "");
          if (dnsp.status == "error") {
            setPageStatus(false);
            toast.update(dt, {
              render: dnsp.message,
              type: "warning",
              isLoading: false,
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            setPageStatus(false);
            toast.update(dt, {
              render: dnsp.message,
              type: "success",
              isLoading: false,
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            getSSH();
            navigate("/ssh");
          }
          deletesshkey.classList.remove("disabled");
          deletesshkey.innerHTML = preContent;
        }
      }
    });
  };



  const openDrawer = () => {
    var ssh_key = document.querySelector("#ssh_key");
    var ssh_name = document.querySelector("#ssh_name");
    var drawerElement = document.querySelector("#importkey-modal");
    var drawer_overlay = document.querySelector("#drawer_overlay");
    if (drawerElement && drawer_overlay) {
      drawerElement.classList.toggle("drawer-on");
      if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
        drawerElement.style.width = "100%";
      } else {
        drawerElement.style.width = "40%";
      }
      drawer_overlay.classList.toggle("d-none");
    }
    if(ssh_name && ssh_key){
      ssh_key.value = ''
      ssh_name.value = ''
    }
  };

  const importSshKey = async () => {
    toast.dismiss();
    const dt = toast.loading("Please wait...");
    const submitBtn = document.querySelector("#importKeyBtn");
    if (submitBtn) {
      setPageStatus(true);
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML =
        'Please wait... <span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      const responseData = await UsePost("post", "key/import", "importkey");

      //console.log(responseData);
      if (responseData.status === "error") {
        setPageStatus(false);
        toast.update(dt, {
          render: responseData.message,
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        var SshForm = document.querySelector("#importkey");
        SshForm.reset();
      } else if (responseData.status === "success") {
        setPageStatus(false);
        toast.update(dt, {
          render: responseData.message,
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        getSSH();
        openDrawer();
        var SshForm = document.querySelector("#importkey");
        SshForm.reset(); 
      }
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML = preContent;
    }
  };

  function convertTimestamp(timestamp) {
    const date = new Date(timestamp);
    // Format the date
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
      timeZone: 'UTC'
    }).format(date);

    return formattedDate.replace('GMT', '(UTC)');
  }

  const copyToClipboard = (data, sid,id) => {
    var svgIcon = document.querySelector(`#${id}`)
    var copyText = document.querySelector(`#${id}`).innerHTML;
    copy(data);
    svgIcon.innerHTML = `<svg
    xmlns="http://www.w3.org/2000/svg"
    width=24px
    height=24px
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M9.89557 13.4982L7.79487 11.2651C7.26967 10.7068 6.38251 10.7068 5.85731 11.2651C5.37559 11.7772 5.37559 12.5757 5.85731 13.0878L9.74989 17.2257C10.1448 17.6455 10.8118 17.6455 11.2066 17.2257L18.1427 9.85252C18.6244 9.34044 18.6244 8.54191 18.1427 8.02984C17.6175 7.47154 16.7303 7.47154 16.2051 8.02984L11.061 13.4982C10.7451 13.834 10.2115 13.834 9.89557 13.4982Z"
      fill="black"
    />
  </svg>`;
    setTimeout(() => {
      svgIcon.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path opacity="0.5" d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z" fill="black"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z" fill="black"></path></svg>`;
      svgIcon.removeAttribute('disabled');
    }, 2000);
  };
  function ProductInfo({ productdata }) {
    return (
      <>
        {productdata != null && Object.keys(productdata).length != 0 ? (
          productdata &&
          productdata.map((value, index) => (
            <tr>
              <td class="ps-9 text-gray-600 fs-6">{value.id}</td>
              <td data-bs-target="license" class="text-dark text-start ps-20">
                {value.name}
              </td>
              <td data-bs-target="license" class="text-center">
                <button
                  type="button"
                  class="btn btn-primary btn-sm sphide"
                  // data-bs-toggle="tooltip"
                  // data-bs-html="true"
                  title={value.value}
                  id={"sshKeyData" + value.id}
                  onClick={(e) => copyToClipboard(value.value, value.id, `copyKey_btn_${value.id}`)}
                >
                  Copy Key
                  <span className="svg-icon svg-icon-primary svg-icon-2 ms-2" id={`copyKey_btn_${value.id}`}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        opacity="0.5"
                        d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                        fill="black"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                </button>
              </td>
              <td class="text-center text-gray-600 sphide">{(value.created_at)}</td>
              <td class="pe-9 text-center">
                <button
                  type="button"
                  onClick={() => deleteSSH(value.id)}
                  class="btn btn-danger btn-sm btn-active-light-danger"
                  id={"deletessh-" + value.id}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))
        ) : (
          <>
            <tr>
              {/* <td colspan="12" className="dataTables_empty" id="loader"> */}
              {/* <div className="loading-spinner"></div> */}
              {/* <TableLoader /> */}
              {/* </td> */}
              <td colspan="12" className="" id="no_record" valign="top">
                <div className="d-flex flex-column flex-center">
                  <div className="fs-5 fw-bolder text-dark mb-4">
                    No items found.
                  </div>
                </div>
              </td>
            </tr>
          </>
        )}
      </>
    );
  }

  function PaginatedItems({ itemsPerPage, ProductData }) {
    var endOffset = endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(ProductData.length / itemsPerPage));

    const handlePageClick = (event) => {
      setActivePage((old) => old = event.selected);
      const newOffset = event.selected * itemsPerPage;
      setItemOffset(newOffset);
    };
    return (
      <>
        <ProductInfo productdata={ProductData.slice(itemOffset, endOffset)} />
        <td colspan="12" className="ps-3">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            initialPage={activePage}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </td>
      </>
    );
  }

  const handleSearch = (text) => {
    const endOffset = itemOffset + perPage;
    const data = sshData.filter(row => {
      console.log(row);
      return(
        (row.name && row.name.toString().toLowerCase().includes(text.toLowerCase())) ||
        (row.id && row.id.toString().toLowerCase().includes(text.toLowerCase()))
      )
    });
    setDummySshData(data);;
    setPageCount(Math.ceil(data.length / perPage));
  }

  if (dataFetched) {
    if (access) {
      return (
        <>
          <div className="col-xl-12">
            {/*begin::Tables Widget 9*/}
            <div className="card card-xl-stretch mb-5 mb-xl-12">
              {/*begin::Header*/}
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold fs-3 mb-1">
                    SSH Key 
                  </span>
                  <span className="text-muted mt-1 fw-bold fs-7">
                    View your SSH Keys
                  </span>
                </h3>
                <div className="card-toolbar">
                {/* <div className="position-relative my-1" style={{ marginRight: 20 }}>
                        <CustomDoc url={ProductsUrl[0]?.ssh}/>
                </div> */}
                  <div
                    className="position-relative my-1"
                    style={{ marginRight: 20 }}
                  >
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      data-kt-table-widget-4="search"
                      id="searchClient"
                      style={{padding:'0.6rem'}}
                      onChange={(e) => handleSearch(e.target.value)}
                      placeholder="Search"
                    />
                  </div>
                  <div style={{ marginRight: 20 }}>
                    <div className="dataTables_length">
                      <label className="w-125px">
                        <select
                          onChange={(e) => setPerPage(e.target.value)}
                          //data-control="select2"
                          style={{padding:'0.6rem'}}
                          className="form-select form-select-sm form-select-solid"
                          defaultValue={5}
                          id="perPage"
                        >
                          <option value={5} selected="">
                            5 Records
                          </option>
                          <option value={10}>10 Records</option>
                          <option value={25}>25 Records</option>
                          <option value={50}>50 Records</option>
                          <option value={100}>100 Records</option>
                        </select>
                      </label>
                    </div>
                  </div>
                  <a
                    onClick={openDrawer}
                    className="btn btn-sm btn-primary btn-active-light-primary"
                    id="importkey-open"
                  >
                    Create SSH Key
                  </a>
                </div>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              {/*begin::Table container*/}
              <div className="table-responsive">
                {/*begin::Table*/}
                <table
                  id="sshTable"
                  className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
                >
                  {/*begin::Table head*/}
                  <thead className="bg-secondary">
                    <tr className="fw-bold">
                      <th className="w-15px ps-9">#ID</th>
                      <th className="w-150px text-start ps-20">Name</th>
                      <th className="w-125px text-center">SSH Key</th>
                      <th className="w-125px text-center">Created_at</th>
                      <th className="w-200px text-end pe-14"></th>
                    </tr>
                  </thead>
                  {/*end::Table head*/}
                  {/*begin::Table body*/}
                  <tbody id="sshList" className="fs-6 fw-bold text-gray-600">

                    {sshData != null ? (
                      <>
                        {access == false ? (
                          <>
                            <tr>
                              <td colspan={12}>
                                <AccessDenied />
                              </td>
                            </tr>
                          </>
                        ) : (
                          <>
                            {sshData.length > 0 ? (
                              <>
                                <PaginatedItems
                                  itemsPerPage={perPage}
                                  ProductData={dummySshData}
                                />
                              </>
                            ) : (
                              <>
                                <tr>
                                  <td colspan={12}>
                                    <div className="col-xl-12">
                                      <div className="">
                                        {/*begin::Card body*/}
                                        <div className="">
                                          {/*begin::Illustration*/}
                                          <div className="text-center px-4 mt-10">
                                            <span className="mw-100 mh-300px">
                                              <svg
                                                width="204px"
                                                height="234px"
                                                fill="#21325B"
                                                viewBox="0 0 36 36"
                                                version="1.1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                stroke="#21325B"
                                              >
                                                <g
                                                  id="SVGRepo_bgCarrier"
                                                  strokeWidth={0}
                                                />
                                                <g
                                                  id="SVGRepo_tracerCarrier"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                />
                                                <g id="SVGRepo_iconCarrier">
                                                  {" "}
                                                  <title>alt-key</title>{" "}
                                                  <path d="M0 10.016q0 2.016 0.8 3.872t2.144 3.2 3.168 2.112 3.904 0.8q2.048 0 4-0.832v2.848h4v1.984h4v4h4v4h5.984v-8l-9.984-8v-1.984h-2.848q0.832-1.952 0.832-4t-0.8-3.872-2.112-3.2-3.2-2.144-3.872-0.8q-2.72 0-5.024 1.344t-3.648 3.648-1.344 5.024zM4 8q0-1.632 1.184-2.816t2.816-1.184 2.816 1.184 1.184 2.816-1.184 2.848-2.816 1.152-2.816-1.152-1.184-2.848z" />{" "}
                                                </g>
                                              </svg>
                                            </span>
                                          </div>
                                          {/*end::Illustration*/}
                                          {/*begin::Wrapper*/}
                                          <div className="card-px text-center py-5 mb-20">
                                            {/*begin::Title*/}
                                            <h1 className="fs-2x fw-bolder mb-5">
                                              SSH Keys
                                            </h1>
                                            <h2 className="fs-1x fw-bolder mb-5 text-muted">
                                              Secure your server connections
                                            </h2>
                                            {/*end::Title*/}
                                            {/*begin::Description*/}
                                            <p className="text-gray-400 fs-4 fw-bold mb-10">
                                              SSH Keys for secure, password-less
                                              logins to your servers. Secure your
                                              cloud.{" "}
                                            </p>
                                            {/*end::Description*/}
                                            {/*begin::Action*/}
                                            <a
                                              onClick={openDrawer}
                                              href="#"
                                              className="btn btn-primary mb-10"
                                            >
                                              Create New SSH Key
                                            </a>
                                            {/*end::Action*/}
                                          </div>
                                          {/*end::Wrapper*/}
                                        </div>
                                        {/*end::Card body*/}
                                      </div>
                                    </div>

                                  </td>
                                </tr>
                              </>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colspan={12}>
                            <TableLoader />
                            <TopBarHeaderLine />
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                  {/*end::Table body*/}
                </table>
                {/*end::Table*/}
                <div id="drawer_overlay" onClick={openDrawer} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>

                <div
                  id="importkey-modal"
                  className="bg-body shadow drawer drawer-end"
                  // data-kt-drawer="true"
                  // data-kt-drawer-name="chat"
                  // data-kt-drawer-activate="true"
                  // data-kt-drawer-direction="end"
                  data-kt-drawer-close="#close_ssh"
                  // data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
                  style={{ important: true, zIndex: 110 }}
                >
                  {/*begin::Messenger*/}
                  <div
                    className="card rounded-0 w-100 border-0"
                    id="kt_drawer_chat_messenger"
                  >
                    {/*begin::Card header*/}
                    <div
                      className="card-header pe-10 ps-7"
                      id="kt_drawer_chat_messenger_header"
                    >
                      {/*begin::Title*/}
                      <div className="card-title">
                        {/*begin::User*/}
                        <div className="d-flex justify-content-center flex-column me-3">
                          <div className="fs-4 fw-bolder text-gray-900 me-1 mb-2 lh-1">
                            Add New SSH Key
                          </div>
                        </div>
                        {/*end::User*/}
                      </div>
                      {/*end::Title*/}
                      {/*begin::Card toolbar*/}
                      <div className="card-toolbar">
                        {/*begin::Close*/}
                        <div
                          onClick={openDrawer}
                          className="btn btn-sm btn-icon btn-active-light-primary"
                          id="close_ssh"
                        >
                          {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                          <span className="svg-icon svg-icon-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <rect
                                opacity="0.5"
                                x={6}
                                y="17.3137"
                                width={16}
                                height={2}
                                rx={1}
                                transform="rotate(-45 6 17.3137)"
                                fill="black"
                              />
                              <rect
                                x="7.41422"
                                y={6}
                                width={16}
                                height={2}
                                rx={1}
                                transform="rotate(45 7.41422 6)"
                                fill="black"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </div>
                        {/*end::Close*/}
                      </div>
                      {/*end::Card toolbar*/}
                    </div>
                    {/*end::Card header*/}
                    {/*begin::Card body*/}
                    <div className="" id="">
                      {/*begin::Form*/}
                      <form
                        name="importkey"
                        id="importkey"
                        className="form"
                      >
                        {/*begin::Modal body*/}
                        <div className="modal-body ">
                          {/*begin::Scroll*/}
                          <div
                            className="scroll-y me-n7 pe-7"
                            id="kt_modal_create_api_key_scroll"
                            data-kt-scroll="true"
                            data-kt-scroll-activate="{default: false, lg: true}"
                            data-kt-scroll-max-height="auto"
                            data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                            data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                            data-kt-scroll-offset="300px"
                          >
                            {/*begin::Input group*/}
                            <div className="mb-5 fv-row">
                              {/*begin::Label*/}
                              <label className="required fs-5 fw-bold mb-2">
                                {" "}
                                Name
                              </label>
                              {/*end::Label*/}
                              {/*begin::Input*/}
                              <input
                                type="text"
                                className="form-control  "
                                placeholder="Name"
                                name="name"
                                id="ssh_name"
                              />
                              {/*end::Input*/}
                            </div>
                            {/*end::Input group*/}
                            {/*begin::Input group*/}
                            <div className="d-flex flex-column mb-5 fv-row">
                              {/*begin::Label*/}
                              <label className="required fs-5 fw-bold mb-2">
                                SSH Key Content
                              </label>
                              {/*end::Label*/}
                              {/*begin::Input*/}
                              <textarea
                                className="form-control  "
                                type="text"
                                rows={3}
                                name="sshkey"
                                placeholder="SSH"
                                defaultValue={""}
                                id="ssh_key"
                              />
                              {/*end::Input*/}
                            </div>
                            {/*end::Input group*/}
                          </div>
                          {/*end::Scroll*/}
                        </div>
                        {/*end::Modal body*/}
                        {/*begin::Modal footer*/}
                        <div className="modal-footer ps-7 pe-9">
                          {/*begin::Button*/}
                          <button
                            type="button"
                            onClick={importSshKey}
                            className="btn btn-primary w-100"
                            id="importKeyBtn"
                          >
                            <span className="indicator-label">
                              Create SSH Key
                            </span>
                          </button>
                          {/*end::Button*/}
                        </div>
                        {/*end::Modal footer*/}
                      </form>
                      {/*end::Form*/}
                    </div>
                    {pageStatus && <TopBarHeaderLine />}
                    {/*end::Card body*/}
                  </div>
                  {/*end::Messenger*/}
                </div>
              </div>
              {/*end::Table container*/}
              {/*begin::Body*/}
            </div>
            {/*end::Tables Widget 9*/}
          </div>

        </>
      );
    } else {
      return (
        <>
          <AccessDenied />
        </>
      )
    }
  } else {
    return (
      <>
        <TableLoader />
      </>
    )
  }
}
