import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import UseApi from '../../../../CustomHooks/useApi';
import TableLoader from '../../Loaders/TableLoader';
import AccessDenied from '../../AccessDenied';
const ResourceTransfer = () => {
  const [dataFetched, setDataFetched] = useState(false);
  const [access, setAccess] = useState(true);
  const [selectedValue, setSelectedValue] = useState('transfer'); // Set the default selected value
  const [marginBottom, setMarginBottom] = useState(window.innerWidth >= 768 ? '0rem' : '3rem');

  const getCloud = async () => {
    const data = await UseApi("get", 'cloud?perpage=1000&page=1', "");
    if (data) {
      setDataFetched(true);
    }
    if ("status" in data) {
      if (data.status == "error") {
        setDataFetched(true)
        if (data.message == "Permission denied.") {
          setAccess(false);
        } else {
          setAccess(true)
        }
        return;
      }
    }
  };

  const getIso = async () => {
    const data = await UseApi('get', 'iso', '');
    if (data) {
      setDataFetched(true);
    }
    if ('status' in data) {
      if (data.status == "error") {
        if (data.message == "Permission denied.") {
          setAccess(false);
        } else {
          setAccess(true)
        }
        return;
      }
    }
  }

  const getDns = async () => {
    // const dt = toast
    const data = await UseApi('get', 'dns', '');
    if (data) {
      setDataFetched(true);
    }
    if ('status' in data) {
      if (data.status == "error" && data.message == "Permission denied.") {
        setAccess(false);
        // loaderFunc();
        return;
      }
    }
  }

  useEffect(() => {
    getCloud()
    getDns()
    getIso()
  }, [])
  const handleRadioChange = (type) => {
    // Update the selected value when a radio button is clicked
    setSelectedValue(type);
  };

  useEffect(() => {
    const handleResize = () => {
      setMarginBottom(window.innerWidth >= 768 ? '0rem' : '3rem');
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (dataFetched) {
    if (access) {
      return (
        <>
          {/*begin::Authentication - Multi-steps*/}
          <div
            className="d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column"
            id="kt_create_account_stepper"
            style={{paddingBottom:marginBottom}}
          >

            {/*begin::Body*/}
            <div className="d-flex flex-column flex-lg-row-fluid py-2">
              {/*begin::Content*/}
              <div className="d-flex flex-center flex-column flex-column-fluid">
                {/*begin::Wrapper*/}
                <div className=" p-10 mx-auto">
                  {/*begin::Logo*/}
                  <a className="mb-4">
                    {/* {logo_dark!=null?(<>
              <img
              alt="Logo"
              src={logo_dark}
              className="h-70px logo mb-10"
            />
            </>):('')}  */}
                  </a>
                  {/*end::Logo*/}
                  {/*begin::Form*/}
                  <form
                    className="my-auto pb-5 mt-10"
                    noValidate="novalidate"
                    id="kt_create_account_form"
                  >
                    {/*begin::Step 1*/}
                    <div className="current" id="mainDiv" data-kt-stepper-element="content">
                      {/*begin::Wrapper*/}
                      <div className="w-100">
                        {/*begin::Heading*/}
                        <div className="pb-10 pb-lg-15 mb-20">
                          {/*begin::Title*/}
                          <h1 className="fw-bolder d-flex align-items-center text-dark">
                            Resource Transfer and Receive Resource
                          </h1>
                          {/*end::Title*/}
                          {/*begin::Notice*/}
                          <div className="text-muted fw-bold fs-5">
                            Choose between 'Resource Transfer' or 'Receive Resource' to efficiently move assets between accounts.
                          </div>
                          {/*end::Notice*/}
                        </div>
                        {/*end::Heading*/}
                        {/*begin::Input group*/}
                        <div className="fv-row">
                          {/*begin::Row*/}
                          <div className="row">
                            {/*begin::Col*/}
                            <div className="col-lg-6">
                              {/*begin::Option*/}
                              <input
                                type="radio"
                                className="btn-check"
                                name="verification_type"
                                defaultValue="fund"
                                checked={selectedValue === 'transfer'}
                                onChange={(e) => handleRadioChange("transfer")}
                                id="kt_create_account_form_account_type_personal"
                              />
                              <label
                                className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10"
                                style={{ height: "250px" }}
                                htmlFor="kt_create_account_form_account_type_personal"
                              >
                                <div className="bg-light bg-opacity-50 rounded-3 p-10 mx-md-5 h-md-100">
                                  <div className="d-flex flex-center rounded-3 text-center">
                                    {/*begin::Svg Icon | path: assets/media/icons/duotune/files/fil022.svg*/}
                                    <span className="svg-icon svg-icon-primary svg-icon-4hx">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                      >
                                        <path
                                          opacity="0.3"
                                          d="M5 16C3.3 16 2 14.7 2 13C2 11.3 3.3 10 5 10H5.1C5 9.7 5 9.3 5 9C5 6.2 7.2 4 10 4C11.9 4 13.5 5 14.3 6.5C14.8 6.2 15.4 6 16 6C17.7 6 19 7.3 19 9C19 9.4 18.9 9.7 18.8 10C18.9 10 18.9 10 19 10C20.7 10 22 11.3 22 13C22 14.7 20.7 16 19 16H5ZM8 13.6H16L12.7 10.3C12.3 9.89999 11.7 9.89999 11.3 10.3L8 13.6Z"
                                          fill="black"
                                        />
                                        <path
                                          d="M11 13.6V19C11 19.6 11.4 20 12 20C12.6 20 13 19.6 13 19V13.6H11Z"
                                          fill="black"
                                        />
                                      </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                  </div>
                                  <h2 className="mb-5">Resource Transfer</h2>
                                  <div className="fs-4 text-gray-600 pb-5">
                                    Transfer your resources securely to another account.
                                  </div>
                                </div>

                              </label>
                              {/*end::Option*/}
                            </div>
                            {/*end::Col*/}
                            {/*begin::Col*/}
                            <div className="col-lg-6">
                              {/*begin::Option*/}
                              <input
                                type="radio"
                                className="btn-check"
                                name="verification_type"
                                defaultValue="card"
                                checked={selectedValue === 'receive'}
                                onClick={(e) => handleRadioChange("receive")}
                                id="kt_create_account_form_account_type_corporate"
                              />
                              <label
                                className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center"
                                style={{ height: "250px" }}
                                htmlFor="kt_create_account_form_account_type_corporate"
                              >
                                <div className="bg-light bg-opacity-50 rounded-3 p-10 mx-md-5 h-md-100">
                                  <div className="d-flex flex-center rounded-3 text-center">
                                    {/*begin::Svg Icon | path: assets/media/icons/duotune/files/fil022.svg*/}
                                    <span className="svg-icon svg-icon-primary svg-icon-4hx">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                      >
                                        <path
                                          opacity="0.3"
                                          d="M19 15C20.7 15 22 13.7 22 12C22 10.3 20.7 9 19 9C18.9 9 18.9 9 18.8 9C18.9 8.7 19 8.3 19 8C19 6.3 17.7 5 16 5C15.4 5 14.8 5.2 14.3 5.5C13.4 4 11.8 3 10 3C7.2 3 5 5.2 5 8C5 8.3 5 8.7 5.1 9H5C3.3 9 2 10.3 2 12C2 13.7 3.3 15 5 15H19Z"
                                          fill="black"
                                        />
                                        <path
                                          d="M13 17.4V12C13 11.4 12.6 11 12 11C11.4 11 11 11.4 11 12V17.4H13Z"
                                          fill="black"
                                        />
                                        <path
                                          opacity="0.3"
                                          d="M8 17.4H16L12.7 20.7C12.3 21.1 11.7 21.1 11.3 20.7L8 17.4Z"
                                          fill="black"
                                        />
                                      </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                  </div>
                                  <h2 className="mb-5">Receive Resource</h2>
                                  <div className="fs-4 text-gray-600 pb-5">
                                    Easily collect resources into your account.
                                  </div>
                                </div>

                              </label>
                              {/*end::Option*/}
                            </div>
                            {/*end::Col*/}
                          </div>
                          {/*end::Row*/}
                        </div>
                        {/*end::Input group*/}
                      </div>
                      {/*end::Wrapper*/}
                    </div>
                    {/*end::Step 1*/}
                    {/*begin::Actions*/}
                    <div className="d-flex flex-stack pt-15" id="toggleBtn">
                      <div className="mr-2"></div>
                      <div>


                        {selectedValue == "transfer" ? (<>
                          <Link to="/resource/transfer">
                            <a className="btn btn-primary btn-active-light-primary">
                              Continue
                              <span className="svg-icon ms-2 svg-icon-3">
                                {" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  {" "}
                                  <rect
                                    opacity="0.5"
                                    x={18}
                                    y={13}
                                    width={13}
                                    height={2}
                                    rx={1}
                                    transform="rotate(-180 18 13)"
                                    fill="black"
                                  />{" "}
                                  <path
                                    d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                    fill="black"
                                  />{" "}
                                </svg>{" "}
                              </span>{" "}
                            </a>
                          </Link>
                        </>) : (<>
                          <Link to="/resource/receive">
                            <a className="btn btn-primary btn-active-light-primary">
                              Continue
                              <span className="svg-icon ms-2 svg-icon-3">
                                {" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  {" "}
                                  <rect
                                    opacity="0.5"
                                    x={18}
                                    y={13}
                                    width={13}
                                    height={2}
                                    rx={1}
                                    transform="rotate(-180 18 13)"
                                    fill="black"
                                  />{" "}
                                  <path
                                    d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                    fill="black"
                                  />{" "}
                                </svg>{" "}
                              </span>{" "}
                            </a>
                          </Link>
                        </>)}

                      </div>
                    </div>
                    {/*end::Actions*/}
                  </form>
                  {/*end::Form*/}
                </div>
                {/*end::Wrapper*/}
              </div>
              {/*end::Content*/}
            </div>
            {/*end::Body*/}

          </div>
          {/*end::Authentication - Multi-steps*/}
        </>
      );
    } else {
      return (
        <>
          <AccessDenied />
        </>
      )
    }
  } else {
    return (
      <>
        <TableLoader />
      </>
    )
  }
}

export default ResourceTransfer;
