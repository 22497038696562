import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import Activity from "./Products/activity/Activity";
import CurrentUsage from "./Products/activity/CurrentUsage";
import Api from "./Products/api/Api";
import Backups from "./Products/backups/Backups";
import Billing from "./Products/billing/Billing";
import Cloud from "./Products/cloud/Cloud";
import CloudDeploy from "./Products/cloud/CloudDeploy";
import ManageCloud from "./Products/cloud/ManageCloud";
import Dashboard from "./Products/Dashboard";
import Dns from "./Products/dns/Dns";
import DnsDeploy from "./Products/dns/DnsDeploy";
import ManageDns from "./Products/dns/ManageDns";
import Firewall from "./Products/firewall/Firewall";
import FirewallDeploy from "./Products/firewall/FirewallDeploy";
import ManageFirewall from "./Products/firewall/ManageFirewall";
import Iso from "./Products/iso/Iso";
import Kubernetes from "./Products/kubernetes/Kubernetes";
import KubernetesDeploy from "./Products/kubernetes/KubernetesDeploy";
import ManageKubernetes from "./Products/kubernetes/ManageKubernetes";
import Kyc from "./Products/kyc/Kyc";
import KycSent from "./Products/kyc/KycSent";
import LoadBalancer from "./Products/loadbalancer/LoadBalanacer";
import LoadbalancerDeploy from "./Products/loadbalancer/LoadbalancerDeploy";
import ManageLoadBalancer from "./Products/loadbalancer/ManageLoadBalancer";
import Account from "./Products/setting/Account";
import Snapshot from "./Products/snapshots/Snapshot";
import Ssh from "./Products/ssh/Ssh";
import AccountManage from "./Products/subuser/AccountManage";
import ActivateUser from "./Products/subuser/ActivateUser";
import ManageAccount from "./Products/subuser/ManageAccount";
import Tally from "./Products/tally/Tally";
import ManageTicket from "./Products/ticket/ManageTicket";
import Ticket from "./Products/ticket/Ticket";
import ManageVpc from "./Products/vpc/ManageVpc";
import Vpc from "./Products/vpc/Vpc";
import VpcDeploy from "./Products/vpc/VpcDeploy";
import ManageVpn from "./Products/vpn/ManageVpn";
import Vpn from "./Products/vpn/Vpn";
import VpnDeploy from "./Products/vpn/VpnDeploy";
import Monitoring from "./Products/monitoring/Monitoring";
import localStorage from "local-storage";
import ObjectStorage from "./Products/objectStorage/ObjectStorage";
import ManageObjectStorage from "./Products/objectStorage/ManageObjectStorage";
import DeployDatabaseCluster from "./Products/databases/DeployDatabaseCluster";
import DeployObjectStorage from "./Products/objectStorage/DeployObjectStorage";
import BlockStorage from "./Products/blockStorage/BlockStorage";
import ManageBlockStorage from "./Products/blockStorage/ManageBlockStorage";
import DeployBlockStorage from "./Products/blockStorage/DeployBlockStorage";
import DeployArchiveStorage from "./Products/archiveStorage/DeployArchiveStorage";
import ManageArchiveStorage from "./Products/archiveStorage/ManageArchiveStorage";
import ArchiveStorage from "./Products/archiveStorage/ArchiveStorage";
import DatabaseCluster from "./Products/databases/DatabaseCluster";
import ManageDatabaseCluster from "./Products/databases/ManageDatabaseCluster";
import Addcard from "./Products/card/Addcard";
import StackScripts from "./Products/stackScript/StackScripts";
import ManageStackScripts from "./Products/stackScript/ManageStackScripts";
import DeployStackScript from "./Products/stackScript/DeployStackScript";
import JoinUs from "./Products/JoinUs";
import Verification from "./Products/verification/Verification";
import Ssl from "./Products/ssl/Ssl";
import MobileUpdate from "./Products/setting/MobileUpdate";
import Welcome from "./Products/verification/Welcome";
import NotFound from "../../../public-components/NotFound";
import ManageMonitoring from "./Products/monitoring/ManageMonitoring";
import PricingContainer from "./Products/pricing/PricingContainer";
import GetCloud from "./Products/helpers/GetCloud";
import SelectComp from "./Products/helper/SelectComp";
import ActionList from "./Products/cloud/helper/ActionList";
import Process from "../../../process/Process";
import UseApi from "../../CustomHooks/useApi";
import ReservedIP from "./Products/reservedIP/ReservedIP";
import ReservedIPDeploy from "./Products/reservedIP/ReservedIPDeploy";
import ResourceTransfer from "./Products/transfer/ResourceTransfer";
import TransferResource from "./Products/transfer/TransferResource";
import RecieveResource from "./Products/transfer/RecieveResource";
import BillingVerification from "./Products/verification/Billing/BillingVerification";
import Alerts from "./Products/alerts/Alerts";
import MonitoringAlerts from "./Products/monitoringAlerts/MonitoringAlerts";
import ManageMonitoringAlerts from "./Products/monitoringAlerts/ManageMonitoringAlerts";
import AutoScaling from "./Products/autoScaling/AutoScaling";
import ManageAutoScaling from "./Products/autoScaling/ManageAutoScaling";
import DeployAutoScaling from "./Products/autoScaling/DeployAutoScaling";
import ManageTargetsGroups from "./Products/loadbalancer/ManageTargets/ManageTargetsGroups";
import PlatformChange from "../../PlatformConfig/PlatformChange";
import Switch from "../../../Switch/Switch";
import Gpu from "./Products/gpu/Gpu";
import GpuDeploy from "./Products/gpu/GpuDeploy";
import DbSwitch from "./Products/databases/tabs/replicaNodes/DbSwitch";
import CreateRegistry from "./Products/containerRegistry/CreateRegistry";
import ContainerRegistry from "./Products/containerRegistry/ContainerRegistry";
import TargetGroup from "./Products/targetGroup/TargetGroup";
import DeployContainerRegistry from "./Products/containerRegistry/DeployContainerRegistry";
import SqsDeploy from "./Products/sqs/SqsDeploy";
import Sqs from "./Products/sqs/Sqs"; 
import ManageSqs from "./Products/sqs/ManageSqs";
import ManageContainerRegistry from "./Products/containerRegistry/ManageContainerRegistry"; 
 import LoginSession from "./Products/loginSession/LoginSession";
import Cookies from "js-cookie";  
import Subnet from "./Products/vpc/subnet/Subnet";
import ManageSubnet from "./Products/vpc/subnet/ManageSubnet";
import RouteTable from "./Products/vpc/RouteTable/RouteTable";
import ManageRouteTable from "./Products/vpc/RouteTable/ManageRouteTable";
import InternetGateway from "./Products/vpc/InternetGateway/InternetGateway";
import NatGateway from "./Products/vpc/NatGateway/NatGateway";
import ManageInternetGateway from "./Products/vpc/InternetGateway/ManageInternetGateway";
import ManageNatGateway from "./Products/vpc/NatGateway/ManageNatGateway";
import PeeringConnection from "./Products/vpc/peering-connection/PeeringConnection";
import CreatePeeringConnection from "./Products/vpc/peering-connection/CreatePeeringConnection";
import ElasticIp from "./Products/vpc/elastic-ip/ElasticIp"; 

export default function Body() {
  const [ipAccess, setIpAccess] = useState(false);
  useEffect(() => { 
    // Fetch the IP address from ipinfo.io
    fetch('https://ipinfo.io/json')
      .then(response => response.json())
      .then(data => {
        const ipArr = ['103.209.144.81', '103.127.30.239'];
        if (ipArr.includes(data.ip)) {
          setIpAccess(true)
        } else {
          setIpAccess(true);
        }
        console.log(data.ip);
      })
      .catch(error => {
        console.error('Error fetching IP address:', error);
      });
  }, []);

  var current_url_string = window.location.href;
  const current_url = new URL(current_url_string);
  const current_domain = current_url.hostname;

  const logoutAccount = () => {
    localStorage.clear();
    // document.cookie = "microhost_api_auth=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //localStorage.remove("Auth");

    const cookieNames = Object.keys(Cookies.get());
    const domain = '.' + current_domain; // Specify the domain

    // Remove each cookie with the specified domain
    cookieNames.forEach((cookieName) => {
      Cookies.remove(cookieName, { domain, path: '/' }); // Make sure to include the path
    });

    cookieNames.forEach((cookieName) => {
      Cookies.remove(cookieName, { current_domain, path: '/' }); // Make sure to include the path
    });
    setTimeout(() => {
      window.location.reload();
      // window.location.href="https://dev.utho.com/?env=dev";
      // navigate("/login");
    }, 6000)

    // const location_url = window.location.origin;
    // window.location.href = location_url; 
  }
  useEffect(() => {
    // Use setInterval to execute a function every 1 minute (60000 milliseconds).
    const interval = setInterval(() => {
      getUserInfo();
    }, 30000);

    async function getUserInfo() {
      var lsAuth = Cookies.get('microhost_api_auth');
      if (lsAuth) {
        var baseUrl = "https://api.utho.com";
        const platformName = localStorage.get("platformType");
        if (platformName) {
          if (platformName == "dev") {
            baseUrl = "https://dev.api.cloudplatformapp.com";
          } else {
            baseUrl = "https://api.utho.com";
          }
        }
        const apiUrl = baseUrl + '/v2/account/info';
        const bearerToken = lsAuth;

        const headers = {
          'Authorization': 'Bearer ' + bearerToken,
          'Content-Type': 'application/json',
        };

        const fetchOptions = {
          method: 'GET',
          headers: headers,
        };

        fetch(apiUrl, fetchOptions)
          .then(response => {
            // if (!response.ok) {
            //   console.log(response.json());
            //   throw new Error(`HTTP error! Status: ${response.status}`);
            // }
            // console.log(response);
            return response.json();
          })
          .then(data => {
            if (data.status == "error") {
              const dt = toast.loading("Please wait...");
              toast.update(dt, {
                render: data.message, type: "warning", isLoading: false, autoClose: 10000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
              });
              logoutAccount();
            } else if (data.user) {
              // console.log(data.user);
              // alert(data.user.build)
              localStorage.set("user_info", data.user);
              // Cookies.set('buildVersion', data.user.build, { expires: 30, path: '/', domain: "." + current_domain });

            } else if (data.auth == "error") {
              const dt = toast.loading("Please wait...");
              toast.update(dt, {
                render: data.error, type: "warning", isLoading: false, autoClose: 10000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
              });
              logoutAccount();
            } else {
              const dt = toast.loading("Please wait...");
              toast.update(dt, {
                render: "Unable to fetch your account details, Please contact to support", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
              });
              logoutAccount();
            }
          })
          .catch(error => {
            // toast.success("", {
            //   position: toast.POSITION.TOP_RIGHT,
            // });
            // const dt = toast.loading("Please wait, while we fetching your account details...");
            // toast.update(dt, {
            //   render: "Session is Expired!", type: "warning", isLoading: false, autoClose: 10000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            // });
            // console.log('API request failed:', error);
          });
      }
    }
    // Clear the interval when the component unmounts to avoid memory leaks.
    return () => clearInterval(interval);
  }, []);
  const location = useLocation();
  useEffect(() => {
    var query = window.location.href;
    var vars = query.split("/");
    const app_info = localStorage.get("appInfo");
    const user_info = localStorage.get("user_info");
    if (app_info) {
      //Setting title
      if (vars.length == 4) {
        var id = vars.at(3);
        document.title = app_info.brand + " | " + id;
      } else if (vars.length == 5) {
        var id = vars.at(3);
        document.title = app_info.brand + " | Manage " + id;
      }
      //setting description

      const descriptionTag = document.querySelector('meta[name="description"]');
      if (descriptionTag) {
        descriptionTag.setAttribute('content', app_info.disc);
      }
    }
    //setting keywords
    const keywordsTag = document.querySelector('meta[name="keywords"]');
    if (keywordsTag) {
      keywordsTag.setAttribute('content', app_info.keywords);
    }

    //console.log('URL changed:', location.pathname);
  }, [location.pathname]);

  const user_info = localStorage.get("user_info");
  if (user_info) {
    // if(user_info.sms_verified==0 || (user_info.c_added=='no' && user_info.rvn==null)){
    if (user_info.sms_verified == 0) {
      return (
        <>
          {/*begin::Content*/}
          <div className="d-flex flex-column flex-column-fluid" style={{ paddingBottom: '0rem' }} id="kt_content">
            {/* Body content Start */}

            {/* <div className="container-xxl"> */}
            <div className="p-3" style={{ paddingBottom: '0rem' }}>
              <Routes>
                <Route path="/activateuser/:id" element={<ActivateUser />} />
                <Route path="/kyc/complete" element={<Welcome />} />
                <Route path="/account" element={<Account />} />
                <Route path="/ticket/" element={<Ticket />} />
                <Route path="/ticket/:id" element={<ManageTicket />} />
                <Route path="*" element={<Verification />} />
              </Routes>
            </div>
          </div>
        </>
      )
    } else {
      return (
        <>
          {/*begin::Content*/}
          <div className="d-flex flex-column flex-column-fluid" style={{ paddingBottom: '0rem' }} id="kt_content">
            {/* Body content Start */}
            {/*<div id="overlay" style="display:none;position: absolute;" >
        <div class="dot-floating">
          <span class="sr-onl y overlay_text">Loading...</span>
        </div>
       </div>
    */}
            {/* <div className="container-xxl"> */}
            <div className="p-3" style={{ paddingBottom: '0rem' }}>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/dashboard" element={<Dashboard />} />

                {/* Cloud Routing start */}
                <Route path="/cloud" element={<Cloud />} />
                <Route path="/cloud/:id" element={<ManageCloud />} />
                <Route path="/cloud/:id/:id" element={<ManageCloud />} />
                {/* Cloud Routing end */}

                {/* Kubernetes Routing start */}
                <Route path="/kubernetes" element={<Kubernetes />} />
                <Route path="/kubernetes/:id" element={<ManageKubernetes />} />
                {/* Kubernetes Routing end */}

                {/* loadbalancer Routing start */}
                <Route path="/targetgroup" element={<LoadBalancer />} />
                <Route path="/targetgroups" element={<TargetGroup />} />
                <Route path="/targetgroup/:id" element={<ManageTargetsGroups />} />
                <Route path="/loadbalancer" element={<LoadBalancer />} />
                <Route path="/loadbalancer/:id" element={<ManageLoadBalancer />} />
                {/* loadbalancer Routing end */}

                {/* VPN Routing start */}
                <Route path="/vpn" element={<Vpn />} />
                <Route path="/vpn/:id" element={<ManageVpn />} />
                {/* VPN Routing end */}

                {/* VPC Routing start */} 
                <Route path="/vpc" element={<Vpc />} />
                <Route path="/vpc/:id" element={<ManageVpc />} />
            
                   {/* VPC Subnet start */} 
                     <Route path="vpc/subnets" element={<Subnet />} />
                     <Route path="/vpc/subnets/:id" element={<ManageSubnet />} />
                   {/* VPC Subnet end */}

                   {/* VPC route-table start */} 
                     {/* <Route path="/vpc/routetable" element={<RouteTable />} />
                     <Route path="/vpc/routetable/:id" element={<ManageRouteTable />} /> */}
                   {/* VPC route-table end */}

                   {/* VPC internet-gateways start */} 
                     <Route path="/vpc/internetgateways" element={<InternetGateway />} />
                     <Route path="/vpc/internetgateways/:id" element={<ManageInternetGateway />} />
                   {/* VPC internet-gateways end */}

                   {/* VPC nat-gateways start */} 
                   <Route path="/vpc/natgateways" element={<NatGateway />} />
                   <Route path="/vpc/natgateways/:id" element={<ManageNatGateway />} />
                   {/* VPC nat-gateways end */}

                   {/* VPC elastic-ip start */} 
                   <Route path="/vpc/elasticip" element={<ElasticIp />} />
                   {/* VPC elastic-ip end */}

                   {/* VPC peering-connection start */} 
                   <Route path="/vpc/peeringconnection" element={<PeeringConnection />} />
                   <Route path="/vpc/peeringconnection/deploy" element={<CreatePeeringConnection />} />
                   {/* <Route path="/vpc-peering-connection" element={<VpcPeering />} />
                   <Route path="/vpc-peering-connection/deploy" element={<VpcPeeringDeploy />} /> */}
                   {/* VPC peering-connection end */}
                       
                {/* VPC Routing end */}

                {/* Firewall Routing start */}
                <Route path="/firewall" element={<Firewall />} />
                <Route path="/firewall/:id" element={<ManageFirewall />} />
                {/* Firewall Routing end */}

                {/* DNS Routing start */}
                <Route path="/dns" element={<Dns />} />
                <Route path="/dns/:id" element={<ManageDns />} />
                {/* DNS Routing end */}

                {/* Backup Routing start */}
                <Route path="/backups" element={<Backups />} />
                {/* Backup Routing start */}

                {/* Snapshot Routing start */}
                <Route path="/snapshots" element={<Snapshot />} />
                {/* Snapshot Routing start */}

                {/* ISO Routing start */}
                <Route path="/iso" element={<Iso />} />
                {/* ISO Routing start */}

                {/* SSH Routing start */}
                <Route path="/ssh" element={<Ssh />} />
                {/* SSH Routing start */}

                {/* API key Routing start */}
                <Route path="/api" element={<Api />} />
                {/* API key Routing start */}

                {/* Billing Routing start */}
                <Route path="/billing" element={<Billing />} />
                {/* Billing Routing start */}
                <Route path="/active-session" element={<LoginSession />} />
                {/* Account Routing start */}
                <Route path="/accountManagement" element={<AccountManage />} />
                <Route path="/accountManagement/:id" element={<ManageAccount />} />
                <Route path="/account/updateMobile" element={<MobileUpdate />} />
                <Route path="/account-kyc" element={<Kyc />} />
                <Route path="/account/kyc/sent" element={<KycSent />} />
                <Route path="/account" element={<Account />} />
                <Route path="/activity" element={<Activity />} />
                <Route path="/currentUsage" element={<CurrentUsage />} />
                <Route path="/user/activate/:id" element={<ActivateUser />} />
                <Route path="/processing/:id" element={<Process />} />
                <Route path="/switch/*" element={<Switch />} />
                {/* Account Routing start */}

                {/* Ticket Routing start */}
                <Route path="/ticket/" element={<Ticket />} />
                <Route path="/ticket/:id" element={<ManageTicket />} />
                {/* Ticket Routing start */}

                {/* Resources Routing start */}
                <Route path="/resources" element={<ResourceTransfer />} />
                <Route path="/resource/transfer" element={<TransferResource />} />
                <Route path="/resource/receive" element={<RecieveResource />} />
                {/* Resources Routing start */}

                {/* Object Storage Routing start */}
                <Route path="/objectStorage" element={<ObjectStorage />} />
                <Route path="/objectStorage/:zone/:id" element={<ManageObjectStorage />} />
                <Route path="/objectStorage/deploy" element={<DeployObjectStorage />} />
                {/* Object Storage Routing end */}

                {/* database Storage Routing start */}
                <Route path="/database-cluster" element={<DatabaseCluster />} />
                <Route path="/database-cluster/:clusterid/:cloudid" element={<ManageDatabaseCluster />} />
                <Route path="/database-cluster/switch/:clusterid/:cloudid" element={<DbSwitch />} />
                <Route path="/database-cluster/deploy" element={<DeployDatabaseCluster />} />
                {/* database Storage Routing end */}

                {/* SQS Routing start */}
                <Route path="/sqs/deploy" element={<SqsDeploy />} />
                <Route path="/sqs" element={<Sqs />} />
                {/* <Route path="/sqs" element={<JoinUs />} />  */}
                <Route path="/sqs/:id" element={<ManageSqs />} />
                <Route path="/sqs/:id/queue/:queue_id" element={<Sqs />} />
                {/* SQS Routing end */}

                {/* Container Registry Routing start */}
                {/* <Route path="/container-registry" element={<ContainerRegistry />} />  */}
                <Route path="/container-registry" element={<CreateRegistry />} />
                <Route path="/container-registry/:id" element={<ManageContainerRegistry />} />
                {/* <Route path="/container-registry/manage" element={<ManageContainerRegistry />} /> */}
                <Route path="/container-registry/create" element={<DeployContainerRegistry />} />
                {/* Container Registry Routing end */}
                {/* <Route path="/container-registry" element={<JoinUs />} />  */}


                {/* {ipAccess==true?(<> */}
                {/* Auto Scaling Routing start */}

                <Route path="/auto-scaling" element={<AutoScaling />} />
                <Route path="/auto-scaling/:id" element={<ManageAutoScaling />} />
                <Route path="/auto-scaling/create" element={<DeployAutoScaling />} />

                {/* Auto Scaling Routing end */}
                {/* Monitoring Alerts Routing start */}
                <Route path="/monitoring/" element={<MonitoringAlerts />} />
                {/* Monitoring Alerts Routing end */}
                {/* </>):('')} */}
                {/* Reserved IP Routing start */}
                {/* <Route path="/reserved-ip" element={<JoinUs />}   />  */}
                {/* <Route path="/reserved-ip" element={<ReservedIP />}   /> 
          <Route path="/reserved-ip/deploy" element={<ReservedIPDeploy />}   />   */}
                {/* Reserved IP Routing end */}


                {/* Block Storage Routing start */}
                <Route path="/ebs" element={<BlockStorage />}   />  
                <Route path="/ebs/:id" element={<ManageBlockStorage />}   /> 
                <Route path="/ebs/deploy" element={<DeployBlockStorage />}   /> 
                {/* Block Storage Routing end */}

                {/* Archive Storage Routing start */}
                {/* <Route path="/archiveStorage" element={<ArchiveStorage />}   /> 
          <Route path="/archiveStorage/:id" element={<ManageArchiveStorage />}   /> 
          <Route path="/archiveStorage/deploy" element={<DeployArchiveStorage />}   />  */}
                {/* Archive Storage Routing end */}


                {/* Stackscripts Routing start */}
                {/* <Route path="/stacks" element={<JoinUs />}   />  */}
                <Route path="/stacks" element={<StackScripts />} />
                <Route path="/stacks/deploy" element={<DeployStackScript />} />
                <Route path="/stacks/:id" element={<ManageStackScripts />} />
                {/* Stackscripts Routing end */}

                {/* SSL Routing start */}
                {/* <Route path="/ssl" element={<JoinUs />}   />  */}
                <Route path="/ssl" element={<Ssl />} />
                <Route path="/ssl/:id" element={<ManageFirewall />} />
                {/* SSL Routing end */}

                {/* Monitoring Advanced Routing start */}
                <Route path="/monitoring-advanced" element={<Monitoring />} />
                <Route path="/monitoring-advanced/:id" element={<ManageMonitoring />} />
                {/* Monitoring Advanced Routing end */}


                {/* Deploy Pages start*/}
                <Route path="/select" element={<ActionList />} />
                <Route path="/cloud/deploy" element={<CloudDeploy />} />
                 <Route path="/cloud/deploy/:id/:type" element={<CloudDeploy />} />
                <Route path="/kubernetes/deploy" element={<KubernetesDeploy />} />
                <Route path="/loadbalancer/deploy" element={<LoadbalancerDeploy />} />
                <Route path="/vpn/deploy" element={<VpnDeploy />} />
                <Route path="/vpc/deploy" element={<VpcDeploy />} />
                <Route path="/firewall/deploy" element={<FirewallDeploy />} />
                <Route path="/dns/deploy" element={<DnsDeploy />} />

                {/* Deploy Pages end*/}

                <Route path="/activateuser/:id" element={<ActivateUser />} />

                <Route path="/gpu" element={<JoinUs />} />
                {/* <Route path="/gpu" element={<Gpu />} />
                <Route path="/gpu/deploy" element={<GpuDeploy />} /> */}
                <Route path="/archiveStorage" element={<JoinUs />} />
                <Route path="/blockStorage" element={<JoinUs />} />
                <Route path="/reserved-ip" element={<JoinUs />} />
                <Route path="/tally" element={<Tally />} />

                <Route path="/change-platform/:pType" element={<PlatformChange />} />
                 <Route path="*" element={<NotFound />} />

              </Routes>

            </div>
            {/* Body Content end */}
          </div>
          {/*end::Content*/}

        </>

      )
    }
  }
}
