import localStorage from 'local-storage';
import React, { useEffect, useState } from 'react'

export default function WithdrawalMoney() {
  const [userData, setUserData]=useState(null);
  const [remaningAmount, setRemaningAmount]=useState(0);
  const [withdrawAmount, setWithdrawAmount]=useState(0);
   useEffect(()=>{
   const udata=localStorage.get("user_info");
     if(udata){
      console.log(udata);
      setUserData(udata);
      if(udata.currencyprefix){
        setWithdrawAmount(udata.currencyprefix+""+0);
      }
      if(udata.partner_comissions){
        if(udata.partner_comissions.total_commission){
          setRemaningAmount(udata.currencyprefix+""+udata.partner_comissions.total_commission);
        }else{
          if(udata.currencyprefix){
            setRemaningAmount(udata.currencyprefix+""+0);
          }
        }
      }
     
     }
  },[])
  return (
    <>
    {/*begin::Forms widget 1*/}
    <div className="card h-xl-100">
                {/*begin::Header*/}
                <div className="card-header position-relative min-h-50px p-0 border-bottom-2">
                  {/*begin::Nav*/}
                  <ul className="nav nav-pills nav-pills-custom d-flex position-relative w-100">
                    {/*begin::Item*/}
                    <li className="nav-item mx-0 p-0 w-100">
                      {/*begin::Link*/}
                      <a className="nav-link btn btn-color-muted active border-0 h-100 px-0" data-bs-toggle="pill" id="kt_forms_widget_1_tab_1" href="#kt_forms_widget_1_tab_content_1">
                        {/*begin::Subtitle*/}
                        <span className="nav-text fw-bolder fs-4 mb-3">Withdraw Money</span>
                        {/*end::Subtitle*/}
                        {/*begin::Bullet*/}
                        <span className="bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded" />
                        {/*end::Bullet*/}
                      </a>
                      {/*end::Link*/}
                    </li>
                    {/*end::Item*/}
                    
                  </ul>
                  {/*end::Nav*/}
                </div>
                {/*end::Header*/}
                {/*begin::Body*/}
                <div className="card-body">
                  {/*begin::Tab Content*/}
                  <div className="tab-content">
                    {/*begin::Tap pane*/}
                    <div className="tab-pane fade active show" id="kt_forms_widget_1_tab_content_1">
                      {/*begin::Input group*/}
                      <div className="form-floating border rounded mb-7">
                        <select className="form-select form-select-transparent" id="kt_forms_widget_1_select_1">
                           <option value={0} data-kt-select2-icon="assets/media/svg/coins/bitcoin.svg" selected="selected">Full Amount</option>
                          <option value={1} data-kt-select2-icon="assets/media/svg/coins/ethereum.svg">Custom Amount</option> 
                        </select>
                        <label htmlFor="floatingInputValue">Payment Type</label>
                      </div>
                      {/*end::Input group*/}
                      {/*begin::Row*/}
                      <div className="row mb-7">
                        {/*begin::Col*/}
                        <div className="col-6">
                          {/*begin::Input group*/}
                          <div className="form-floating">
                            <input type="text" className="form-control text-gray-800 fw-bolder readonly"    value={remaningAmount} />
                            <label htmlFor="floatingInputValue" className="sphide">Remaning Amount</label>
                          </div>
                          {/*end::Input group*/}
                        </div>
                        {/*end::Col*/}
                        {/*begin::Col*/}
                        <div className="col-6">
                          {/*begin::Input group*/}
                          <div className="form-floating">
                            <input type="text" className="form-control text-gray-800 fw-bolder readonly"   value={withdrawAmount} />
                            <label htmlFor="floatingInputValue" className="sphide">Withdraw Amount</label>
                          </div>
                          {/*end::Input group*/}
                        </div>
                        {/*end::Col*/}
                      </div>
                      {/*end::Row*/}
                      {/*begin::Action*/}
                      <div className="d-flex align-items-end">
                        <a href="#" data-bs-toggle="modal" data-bs-target="#kt_modal_top_up_wallet" className="btn btn-primary fs-3 w-100 disabled">
                          {/*begin::Svg Icon | path: assets/media/icons/duotune/finance/fin008.svg*/}
  <span className="svg-icon svg-icon-2">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        opacity="0.3"
        d="M3.20001 5.91897L16.9 3.01895C17.4 2.91895 18 3.219 18.1 3.819L19.2 9.01895L3.20001 5.91897Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21C21.6 10.9189 22 11.3189 22 11.9189V15.9189C22 16.5189 21.6 16.9189 21 16.9189H16C14.3 16.9189 13 15.6189 13 13.9189ZM16 12.4189C15.2 12.4189 14.5 13.1189 14.5 13.9189C14.5 14.7189 15.2 15.4189 16 15.4189C16.8 15.4189 17.5 14.7189 17.5 13.9189C17.5 13.1189 16.8 12.4189 16 12.4189Z"
        fill="black"
      />
      <path
        d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21V7.91895C21 6.81895 20.1 5.91895 19 5.91895H3C2.4 5.91895 2 6.31895 2 6.91895V20.9189C2 21.5189 2.4 21.9189 3 21.9189H19C20.1 21.9189 21 21.0189 21 19.9189V16.9189H16C14.3 16.9189 13 15.6189 13 13.9189Z"
        fill="black"
      />
    </svg>
  </span>
  {/*end::Svg Icon*/}
       Coming soon</a>
                      </div>
                      {/*end::Action*/}
                    </div>
                    {/*end::Tap pane*/}
                    {/*begin::Tap pane*/}
                    <div className="tab-pane fade" id="kt_forms_widget_1_tab_content_2">
                      {/*begin::Input group*/}
                      <div className="form-floating border rounded mb-7">
                        <select className="form-select form-select-transparent" id="kt_forms_widget_1_select_2">
                          <option />
                          <option value={0} data-kt-select2-icon="assets/media/svg/coins/bitcoin.svg" selected="selected">Bitcoin/BTC</option>
                          <option value={1} data-kt-select2-icon="assets/media/svg/coins/ethereum.svg">Ethereum/ETH</option>
                          <option value={2} data-kt-select2-icon="assets/media/svg/coins/filecoin.svg">Filecoin/FLE</option>
                          <option value={3} data-kt-select2-icon="assets/media/svg/coins/chainlink.svg">Chainlink/CIN</option>
                          <option value={4} data-kt-select2-icon="assets/media/svg/coins/binance.svg">Binance/BCN</option>
                        </select>
                        <label htmlFor="floatingInputValue">Coin Name</label>
                      </div>
                      {/*end::Input group*/}
                      {/*begin::Row*/}
                      <div className="row mb-7">
                        {/*begin::Col*/}
                        <div className="col-6">
                          {/*begin::Input group*/}
                          <div className="form-floating">
                            <input type="email" className="form-control text-gray-800 fw-bolder" placeholder={0} id="floatingInputValue" defaultValue="$0,0000005" />
                            <label htmlFor="floatingInputValue">Amount(BTC)</label>
                          </div>
                          {/*end::Input group*/}
                        </div>
                        {/*end::Col*/}
                        {/*begin::Col*/}
                        <div className="col-6">
                          {/*begin::Input group*/}
                          <div className="form-floating">
                            <input type="email" className="form-control text-gray-800 fw-bolder" placeholder={0} id="floatingInputValue" defaultValue="$1230.00" />
                            <label htmlFor="floatingInputValue">Amount(USD)</label>
                          </div>
                          {/*end::Input group*/}
                        </div>
                        {/*end::Col*/}
                      </div>
                      {/*end::Row*/}
                      {/*begin::Action*/}
                      <div className="d-flex align-items-end">
                        <a href="#" data-bs-toggle="modal" data-bs-target="#kt_modal_top_up_wallet" className="btn btn-primary fs-3 w-100">Place Offer</a>
                      </div>
                      {/*end::Action*/}
                    </div>
                    {/*end::Tap pane*/}
                  </div>
                  {/*end::Tab Content*/}
                </div>
                {/*end: Card Body*/}
              </div>
              {/*end::Forms widget 1*/}
    </>
  )
}
