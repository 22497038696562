import ReactPaginate from "react-paginate";
import UpdateRegistry from "./UpdateRegistry";
import { useEffect, useState } from "react";
import copy from "copy-to-clipboard";
import { ProductInfo } from "./ProductInfo";

export function PaginatedItems({ itemsPerPage, ProductData, search }) {
    // We start with an empty list of items. 
    const [pageCount, setPageCount] = useState(0);
    const [objectForUpdate, setObjectForUpdate] = useState({})
    const [vpcData, setVpcData] = useState(null);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    const getAllRegistry = async () => {
        // props.getAllRegistry()
    }

    // const findObjectForUpdate = (name) => {
    //     // setIsUpdate(true)
    //     // console.log(props.allPublicData, name);
    //     const foundObject = props.allPublicData.find(obj => obj.name === name);
    //     if (foundObject) {
    //         var selectElement = document.getElementById("update_cr_id");
    //         var selectIdElement = document.getElementById("public_id_for_update");
    //         selectElement.value = foundObject?.metadata?.public == 'true' ? 'public' : 'private';
    //         selectIdElement.value = foundObject.name
    //         let element = document.querySelector("#cr_type")
    //         element.value = foundObject?.metadata?.public
    //         setObjectForUpdate(foundObject)
    //     } else {
    //         setObjectForUpdate({})
    //     }
    // }

    const openDrawer = () => {

        var drawerElement = document.querySelector("#connection-modal");
        var drawer_overlay = document.querySelector("#drawer_overlay_connection");
        if (drawerElement && drawer_overlay) {
            drawerElement.classList.toggle("drawer-on");
            if (window.innerWidth <= 576) { // Adjust this value based on your breakpoint
                drawerElement.style.width = "100%";
            } else {
                drawerElement.style.width = "80%";
            }
            drawer_overlay.classList.toggle("d-none");
        }
    };

    const loaderFunc = () => {
        var loader = document.querySelector("#loader");
        var no_record = document.querySelector("#no_record");
        if (loader && no_record) {
            no_record.classList.add("d-none");
            loader.classList.remove("d-none");

            setTimeout(() => {
                loader.classList.add("d-none");
                no_record.classList.remove("d-none");
            }, 100);
        }
    }

    useEffect(() => {
        // Fetch items from another resources.

        const endOffset = itemOffset + itemsPerPage;


        //  ////console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        if (ProductData != null) {
            //   var str="Noida";
            // ////console.log(ProductData);
            const data = ProductData.filter(row => {
                return Object.values(row).some(value => {
                    if (value != null) {
                        if (value.toString().toLowerCase().includes(search.toLowerCase())) {
                            return value;
                        }
                    }
                });
            });
            if (data.length <= 0) {
                loaderFunc();
            }
            setVpcData(data.slice(itemOffset, endOffset));
            //setCurrentItems(items.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(data.length / itemsPerPage));
            // alert(pageCount);
        }
    }, [itemOffset, itemsPerPage]);

    // Invoke when user click to request another page.

    const handlePageClick = (event) => {
        const newOffset = event.selected * itemsPerPage % ProductData.length;
        ////console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
        setItemOffset(newOffset);

    };
    var searchEle = document.querySelector("#searchClient");
    // if (searchEle) {
    //     searchEle.focus();
    // }
    const copyToClipboard = (target, targetBy) => {

        var btn_id = document.querySelector("#" + targetBy);
        var target_id = document.querySelector("#" + target);
        if (btn_id) {
            var copyText = btn_id.innerHTML;
            var copyEle = target_id.innerHTML;
            copy(copyEle);
            btn_id.innerHTML = `<svg
          xmlns="http://www.w3.org/2000/svg"
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M9.89557 13.4982L7.79487 11.2651C7.26967 10.7068 6.38251 10.7068 5.85731 11.2651C5.37559 11.7772 5.37559 12.5757 5.85731 13.0878L9.74989 17.2257C10.1448 17.6455 10.8118 17.6455 11.2066 17.2257L18.1427 9.85252C18.6244 9.34044 18.6244 8.54191 18.1427 8.02984C17.6175 7.47154 16.7303 7.47154 16.2051 8.02984L11.061 13.4982C10.7451 13.834 10.2115 13.834 9.89557 13.4982Z"
            fill="black"
          />
        </svg>`;
            setTimeout(() => {
                btn_id.innerHTML = copyText;
            }, 2000);
        }

    }

    return (
        <>
            <ProductInfo productdata={vpcData} />
            <td colspan="12">
                <ReactPaginate
                    nextLabel="next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="< previous"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                />
            </td>
            <div id="drawer_overlay_connection" onClick={openDrawer} className="drawer-overlay d-none" style={{ zIndex: 109, width: "100%" }}></div>

            <div
                id="connection-modal"
                className="bg-body shadow drawer drawer-end"
                // data-kt-drawer="true"
                // data-kt-drawer-name="chat"
                // data-kt-drawer-activate="true"
                // data-kt-drawer-direction="end"
                data-kt-drawer-close="#close_ssh"
                style={{ important: true, zIndex: 110 }}
            >
                <div className="card-toolbar">
                    {/*begin::Close*/}
                    <div
                        onClick={openDrawer}
                        className="btn btn-sm btn-icon btn-active-light-primary"
                        id="close_ssh"
                    >
                        {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                        <span className="svg-icon svg-icon-2">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <rect
                                    opacity="0.5"
                                    x={6}
                                    y="17.3137"
                                    width={16}
                                    height={2}
                                    rx={1}
                                    transform="rotate(-45 6 17.3137)"
                                    fill="black"
                                />
                                <rect
                                    x="7.41422"
                                    y={6}
                                    width={16}
                                    height={2}
                                    rx={1}
                                    transform="rotate(45 7.41422 6)"
                                    fill="black"
                                />
                            </svg>
                        </span>
                        {/*end::Svg Icon*/}
                    </div>
                    {/*end::Close*/}
                </div>
                <div className="mb-2">
                    {/*begin::Card Body*/}
                    <div className="card-body fs-6 text-gray-700">
                        {/*begin::Section*/}
                        <div className="pt-3">
                            {/*begin::Heading*/}
                            <h1 className=" fw-bolder mb-0" id="rtl-version">
                                <a href="#rtl-version" />
                                Push Commands
                            </h1>
                            {/*end::Heading*/}
                            {/*begin::Block*/}
                            <div className="py-0">
                                <p className="mb-0">
                                    Command references for pushing an artifact to this project.
                                </p>
                            </div>
                            {/*end::Block*/}
                            {/*begin::List*/}
                            <ol className="py-5">
                                <li className="py-3">
                                    <h5>Docker Push Command</h5>

                                    Tag an image for this project:
                                    <div className="py-5">
                                        {/*begin::Highlight*/}
                                        <div className="highlight">
                                            <button
                                                className="highlight-copy btn"
                                                data-bs-toggle="tooltip"
                                                title=""
                                                data-bs-original-title="Copy code"
                                                id="docker_push_command_btn1"
                                                onClick={() => copyToClipboard('container_string_value1', 'docker_push_command_btn1')}
                                            >
                                                copy
                                            </button>
                                            <div className="highlight-code">
                                                <pre className=" language-bash" tabIndex={0}>
                                                    <code className=" language-bash" id="container_string_value1">docker tag SOURCE_IMAGE[:TAG] registry.utho.io/{objectForUpdate?.name}/REPOSITORY[:TAG]</code>
                                                </pre>
                                            </div>
                                        </div>
                                        {/*end::Highlight*/}
                                    </div>
                                    Push an image to this project:
                                    <div className="py-5">
                                        {/*begin::Highlight*/}
                                        <div className="highlight">
                                            <button
                                                className="highlight-copy btn"
                                                data-bs-toggle="tooltip"
                                                title=""
                                                data-bs-original-title="Copy code"
                                                id="docker_push_command_btn2"
                                                onClick={() => copyToClipboard('container_string_value2', 'docker_push_command_btn2')}
                                            >
                                                copy
                                            </button>
                                            <div className="highlight-code">
                                                <pre className=" language-bash" tabIndex={0}>
                                                    <code className=" language-bash" id="container_string_value2">
                                                        docker push registry.utho.io/{objectForUpdate?.name}/REPOSITORY[:TAG]
                                                    </code>
                                                </pre>
                                            </div>
                                        </div>
                                        {/*end::Highlight*/}
                                    </div>
                                </li>
                                <li className="py-3">
                                    <h5>Podman Push Command</h5>

                                    Push an image to this project:
                                    <div className="py-5">
                                        {/*begin::Highlight*/}
                                        <div className="highlight">
                                            <button
                                                className="highlight-copy btn"
                                                data-bs-toggle="tooltip"
                                                title=""
                                                data-bs-original-title="Copy code"
                                                id="docker_push_command_btn3"
                                                onClick={() => copyToClipboard('container_string_value3', 'docker_push_command_btn3')}
                                            >
                                                copy
                                            </button>
                                            <div className="highlight-code">
                                                <pre className=" language-bash" tabIndex={0}>
                                                    <code className=" language-bash" id="container_string_value3">
                                                        podman push IMAGE_ID registry.utho.io/{objectForUpdate?.name}/REPOSITORY[:TAG]
                                                    </code>
                                                </pre>
                                            </div>
                                        </div>
                                        {/*end::Highlight*/}
                                    </div>
                                </li>
                                <li className="py-3">
                                    <h5>Helm Push Command</h5>
                                    Package a chart for this project:
                                    <div className="py-5">
                                        {/*begin::Highlight*/}
                                        <div className="highlight">
                                            <button
                                                className="highlight-copy btn"
                                                data-bs-toggle="tooltip"
                                                title=""
                                                data-bs-original-title="Copy code"
                                                id="docker_push_command_btn4"
                                                onClick={() => copyToClipboard('container_string_value4', 'docker_push_command_btn4')}
                                            >
                                                copy
                                            </button>
                                            <div className="highlight-code">
                                                <pre className=" language-bash" tabIndex={0}>
                                                    <code className=" language-bash" id="container_string_value4">
                                                        helm package CHART_PATH
                                                    </code>
                                                </pre>
                                            </div>
                                        </div>
                                        {/*end::Highlight*/}
                                    </div>

                                    Push a chart to this project:
                                    <div className="py-5">
                                        {/*begin::Highlight*/}
                                        <div className="highlight">
                                            <button
                                                className="highlight-copy btn"
                                                data-bs-toggle="tooltip"
                                                title=""
                                                data-bs-original-title="Copy code"
                                                id="docker_push_command_btn5"
                                                onClick={() => copyToClipboard('container_string_value5', 'docker_push_command_btn5')}
                                            >
                                                copy
                                            </button>
                                            <div className="highlight-code">
                                                <pre className=" language-bash" tabIndex={0}>
                                                    <code className=" language-bash" id="container_string_value5">
                                                        helm push CHART_PACKAGE oci://registry.utho.io/{objectForUpdate?.name}
                                                    </code>
                                                </pre>
                                            </div>
                                        </div>
                                        {/*end::Highlight*/}
                                    </div>
                                </li>
                                <li className="py-3">
                                    <h5>CNAB Push Command </h5>
                                    Push a CNAB to this project:
                                    <div className="py-5">
                                        {/*begin::Highlight*/}
                                        <div className="highlight">
                                            <button
                                                className="highlight-copy btn"
                                                data-bs-toggle="tooltip"
                                                title=""
                                                data-bs-original-title="Copy code"
                                                id="docker_push_command_btn6"
                                                onClick={() => copyToClipboard('container_string_value6', 'docker_push_command_btn6')}
                                            >
                                                copy
                                            </button>
                                            <div className="highlight-code">
                                                <pre className=" language-bash" tabIndex={0}>
                                                    <code className=" language-bash" id="container_string_value6">
                                                        cnab-to-oci push CNAB_PATH --target registry.utho.io/{objectForUpdate?.name}/REPOSITORY[:TAG] --auto-update-bundle
                                                    </code>
                                                </pre>
                                            </div>
                                        </div>
                                        {/*end::Highlight*/}
                                    </div>
                                </li>
                            </ol>
                            {/*end::List*/}
                        </div>
                        {/*end::Section*/}
                    </div>
                    {/*end::Card Body*/}
                </div>

            </div>

            <UpdateRegistry data={objectForUpdate} getAllRegistry={getAllRegistry} />
            {/* Connection create modal end */}
            {/* {pageStatus &&
                <>
                    <TopBarHeaderLine />
                    <TableLoader />
                </>
            } */}
        </>
    );
}