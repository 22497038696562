import React, { useCallback, useState } from "react";  
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import swal from 'sweetalert';
import useRazorpay from "react-razorpay";  
import UseApi from "../../../../../../../CustomHooks/useApi"; 
import UsePostData from "../../../../../../../CustomHooks/usePostData";
import localStorage from "local-storage";
import Cookies from "js-cookie";
 export default function AddingRazorpayCard() {   
  var current_url_string = window.location.href;
  const current_url = new URL(current_url_string);
  const current_domain = current_url.hostname;

  const filesUrl=localStorage.get("filesUrl");
  const Razorpay = useRazorpay();

    const saveRazorpayCard=useCallback(async() =>{
        var cardBtn=document.querySelector("#rzp-button1");
      toast.dismiss();
      //change button innerHtml with loader onclick
      var btnHtml=cardBtn.innerHTML;
      if (cardBtn) {
        cardBtn.innerHTML =
          'Please wait...<span class="spinner-border spinner-border-sm align-middle ms-2"></span>';
          cardBtn.classList.add("disabled");
      }

      //calling userinfo api and application info
      const u_data=await UseApi('get','account/info',''); 
      const uData=u_data.user;
      const u_razorpay_info=await UseApi('get','mhcsecinfo','');
       
      var ruserid="";
      var rorder_id="";
      var rcustomer_id=""
      var rfullname="";
      var rmobile="";
      var remail="";
      var ramount=0;
      var rcurrency=""; 
  
      if(uData){
      ruserid=uData.id; 
      rcustomer_id=uData.razorpay_customerid;
      rfullname=uData.fullname;
      rmobile=uData.mobile;
      remail=uData.email;
      rcurrency=uData.currency;
      } 

     const createOrder=await UsePostData("post","razorpay/createorder",{"currency":"INR","type":"card","amount":1});
     if(createOrder && createOrder.status=="success"){
      rorder_id=createOrder.id;
     }
      const saveOptions = {  
        key: u_razorpay_info.razorpay_key,
        amount: 100,
        currency: "INR",
        name: "Utho Cloud",
        description: "Welcome to Utho Cloud",
        image: (filesUrl!=null?(filesUrl):(''))+"/publicfiles/"+(u_razorpay_info?(u_razorpay_info.logo):('')),
        order_id: rorder_id,
        customer_id: rcustomer_id,
        prefill: { 
          contact: rmobile,
          },
        notes:{
            microhost_order_id: "cardauth-"+ruserid,
              },
        options: {
          checkout: {
            method: {
              netbanking: 0,
              card: 1,
              upi: 0,
            }
          }
        },
        recurring:1,
        handler: async(res) => { 
          if(res.status_code==200){  
                //console.log(res); 
                  const   ResData=await UsePostData("post","razorpay/addtoken",{"verify":1,"action":"saveCard","razorpay_payment_id":res.razorpay_payment_id,"razorpay_order_id":res.razorpay_order_id,"razorpay_signature":res.resrazorpay_signature})
                if(ResData.status=="success"){
                  swal({
                      title: "Card",
                      text: "Card added in your account!",
                      icon: "success",
                      button: "OK",
                    });
                    window.afterCardVerification();
                    const uthoDomain=".utho.com";
                    const domain = '.'+current_domain;
                    Cookies.remove("coupon", { domain, path: '/' }); 
                    Cookies.remove("coupon_message", { domain, path: '/' }); 
                    Cookies.remove("coupon", { current_domain, path: '/' }); 
                    Cookies.remove("coupon_message", { current_domain, path: '/' });  
                    Cookies.remove("coupon", {uthoDomain, path: '/' }); 
                    Cookies.remove("coupon_message", {uthoDomain, path: '/' }); 
                    setTimeout(() => {
                      window.location.reload();
                    }, 4000);
                }else{
                  swal({
                      title: "Card",
                      text: "Card not added in your account!",
                      icon: "warning",
                      button: "OK",
                    });
                }
                if (cardBtn) {
                    cardBtn.innerHTML =btnHtml;
                    cardBtn.classList.remove("disabled");
                  }
              }
        },
        notes: {
          address: "Utho Cloud- Fund add",
        },
        theme: {
          color: "#377dff",
        },
      };

    const rzpay = new Razorpay(saveOptions); 
    rzpay.on("payment.failed", function (response) {
      swal({
          title: "Payment failed",
          text: response.error.description,
          icon: "warning",
          button: "OK",
        }); 
    }); 
    rzpay.open();
    if (cardBtn) {
      cardBtn.innerHTML =btnHtml;
      cardBtn.classList.remove("disabled");
    }
    
  }, [Razorpay]); 


   
    return (
        <> 
                        
                              <a
                                onClick={saveRazorpayCard}
                                id="rzp-button1"
                                className="btn btn-primary"
                              >
                                 Continue
                  {/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
                  <span className="svg-icon svg-icon-4 ms-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.5"
                        x={18}
                        y={13}
                        width={13}
                        height={2}
                        rx={1}
                        transform="rotate(-180 18 13)"
                        fill="black"
                      />
                      <path
                        d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                              </a> 
                            
        </>
    );
}
