import { DATABASE_ACCESS_IP_DATA, DATABASE_CLUSTER_INFO, DATABASE_NODE_COUNT, DEPLOY_DB_REPLICA } from "../../../../Redux/Services/constants"

 
const initialState={ 
   nodeCountDataInfo:[],
   databaseAccessIpInfo:[],
   deployClusterInfo:[{dcslug:"",plan_id:"",price:"",billingcycle:"hourly",db_version:"",nodes_count:"", cluster_label:""}],
   deployReplica:[{dcslug:"",plan_id:"",price:"", cluster_label:""}]
 }

export default function DatabaseReducer(state=initialState,action){


   switch (action.type) {
       case DATABASE_NODE_COUNT: 
           return {...state, nodeCountDataInfo:action.data}
       case DATABASE_ACCESS_IP_DATA: 
           return {...state, databaseAccessIpInfo:action.data} 
       case DATABASE_CLUSTER_INFO: 
           return {...state, deployClusterInfo:action.data}
       case DEPLOY_DB_REPLICA: 
           return {...state, deployReplica:action.data}           
       default:
           return state 
   }
}



