import React, { useEffect, useRef, useState } from "react";
import UseApi from "../../../../../CustomHooks/useApi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import TopBarHeaderLine from "../../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../../Loaders/TableLoader";
import UseCallApi from "../../../../../CustomHooks/useCallApi";
import CreateNatGateway from "../NatGateway/CreateNatGateway";
import AttachElasticIp from "./AttachElasticIp";

export default function ElasticIp() {

    const [dataFetched, setDataFetched] = useState(false);
    const [pageCount, setPageCount] = useState(1);
    const [perPage, setPerPage] = useState(5);
    const [itemOffset, setItemOffset] = useState(0);
    const [activePage, setActivePage] = useState(0)
    const [subnetDataAll, setSubnetDataAll] = useState(null);
    const [dummySubnetDataAll, setDummySubnetDataAll] = useState(null);
    const dataFetchedRef = useRef(false);

    const deallocateIp = async (ip, id) => {
        toast.dismiss();
        const dt = toast.loading("Please wait... System is processing your request.");
        const submitBtn = document.querySelector(`#destroy_elasticip_btn_${id}`);

        if (submitBtn) {
            var preContent = submitBtn.innerHTML;
            submitBtn.classList.add("disabled");
            submitBtn.innerHTML =
                'Please wait... <span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
            const responseData = await UseCallApi("post", `elasticip/${ip}/deallocate`, {});

            if (responseData.status === "error") {
                toast.update(dt, {
                    render: responseData.message,
                    type: "error",
                    isLoading: false,
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else if (responseData.status === "success") {
                toast.update(dt, {
                    render: responseData.message,
                    type: "success",
                    isLoading: false,
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                getElasticIp()
                // openDrawer()
            }
            submitBtn.classList.remove("disabled");
            submitBtn.innerHTML = preContent;
        }
    }

    function ProductInfo({ productdata }) {
        return (
            <>
                {productdata != null && Object.keys(productdata).length != 0 ? (
                    productdata &&
                    productdata.map((value, index) => (
                        <tr>
                            {/* <td /> */}
                            <td className="text-start ps-9">
                                <div className="d-flex flex-column w-100 me-2">
                                    <div className="d-flex flex-stack mb-2">
                                        <span className=" me-2 fs-7 fw-bold">{value.uuid}</span>
                                    </div>
                                </div>
                            </td>
                            <td className="text-center">
                                {/* <div className="d-flex flex-column w-100 me-2">
                  <div className="d-flex flex-stack mb-2"> */}
                                <span className="me-2 fs-7 fw-bold">
                                    {value.ip}
                                </span>
                                {/* </div>
                </div> */}
                            </td>
                            <td className="text-center">
                                {/* <div className="d-flex flex-column w-100 me-2">
                  <div className="d-flex flex-stack mb-2"> */}
                                <span className="me-2 fs-7 fw-bold">
                                    {value.iptype}
                                </span>
                                {/* </div>
                </div> */}
                            </td>
                            <td className="text-center">
                                {/* <div className="d-flex flex-column w-100 me-2">
                  <div className="d-flex flex-stack mb-2"> */}
                                <span className="me-2 fs-7 fw-bold">
                                    {value?.networktype}
                                </span>
                            </td>
                            <td className="text-end pe-9">
                                {" "}
                                <div className="justify-content-end flex-shrink-0">
                                    {/* <Link to={"/vpc-nat-gateways/" + value.id + ""} > */}
                                    <a
                                        className="btn btn-danger btn-active-light-danger btn-sm sphide"
                                        onClick={() => deallocateIp(value.ip, value.id)}
                                        id={`destroy_elasticip_btn_${value.id}`}
                                    >
                                        {" "}
                                        Deallocate{" "}
                                        <span className="svg-icon ms-2 svg-icon-3 d-none">
                                            {" "}
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                            >
                                                {" "}
                                                <rect
                                                    opacity="0.5"
                                                    x={18}
                                                    y={13}
                                                    width={13}
                                                    height={2}
                                                    rx={1}
                                                    transform="rotate(-180 18 13)"
                                                    fill="black"
                                                />{" "}
                                                <path
                                                    d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                                    fill="black"
                                                />{" "}
                                            </svg>{" "}
                                        </span>{" "}
                                    </a>{" "}
                                    {/* </Link> */}
                                </div>
                            </td>
                        </tr>
                    ))
                ) : (
                    <>
                        <tr>
                            <td colspan="12" className="" id="no_record" valign="top">
                                <div className="d-flex flex-column flex-center">
                                    <div className="fs-5 fw-bolder text-dark mb-4">
                                        No items found.
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </>
                )}
            </>
        );
    }

    const getElasticIp = async () => {
        const dt = toast;
        const datas = await UseApi("get", "elasticip", "");
        if (datas) {
            setDataFetched(true);
        }

        if (datas.status == "error") {
            setSubnetDataAll([]);
            setDummySubnetDataAll([])
        } else {
            setSubnetDataAll(datas.ips.reverse());
            setDummySubnetDataAll(datas.ips.reverse());
        }
    };

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        toast.dismiss();
        getElasticIp();
    }, []);

    const handleSearch = (text) => {
        let data = subnetDataAll.filter((row) => {
            return (
                (row.ip && row.ip.toString().toLowerCase().includes(text.toLowerCase())) ||
                (row.networktype && row.networktype.toString().toLowerCase().includes(text.toLowerCase())
                )
            )
        });

        // Update the state with the filtered data
        setDummySubnetDataAll(data)
        setPageCount(Math.ceil(data.length / perPage));
    }

    const handleItemsPerPageChange = (newItemsPerPage) => {
        // If the current page goes beyond the total page count, reset to the first page
        const newPageCount = Math.ceil(dummySubnetDataAll.length / parseInt(newItemsPerPage));
        const newActivePage = activePage >= newPageCount ? 0 : activePage;

        // Set the new offset based on the current page and itemsPerPage
        const newOffset = newActivePage * parseInt(newItemsPerPage);

        // Update state
        setItemOffset(newOffset);
        setActivePage(newActivePage);
        setPerPage(newItemsPerPage);  // Update the itemsPerPage state
    };


    function PaginatedItems({ itemsPerPage, ProductData }) {
        // Calculate the total number of pages based on itemsPerPage and ProductData
        const pageCount = Math.ceil(ProductData.length / parseInt(itemsPerPage));

        // Calculate the current end offset
        const endOffset = itemOffset + parseInt(itemsPerPage);

        // Handle page click
        const handlePageClick = (event) => {
            const selectedPage = event.selected;
            const newOffset = selectedPage * parseInt(itemsPerPage);
            setActivePage(selectedPage);
            setItemOffset(newOffset);
        };
        return (
            <>
                <ProductInfo productdata={ProductData.slice(parseInt(itemOffset), endOffset)} />
                {ProductData.length > 0 && ProductData.length > 5 ?
                    <td colspan="12" className="ps-3">
                        <ReactPaginate
                            nextLabel="next >"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            pageCount={pageCount}
                            initialPage={activePage}
                            previousLabel="< previous"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                        />
                    </td>
                    :
                    ''
                }
            </>
        );
    }

    const allocateIp = async () => {
        toast.dismiss();
        const dt = toast.loading(
            "Please wait... System is processing your request."
        );
        const submitBtn = document.querySelector("#allocateIpBtn");

        if (submitBtn) {
            var preContent = submitBtn.innerHTML;
            submitBtn.classList.add("disabled");
            submitBtn.innerHTML =
                'Please wait... <span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
            const responseData = await UseCallApi("post", "elasticip/allocate", {});

            if (responseData.status === "error") {
                toast.update(dt, {
                    render: responseData.message,
                    type: "error",
                    isLoading: false,
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else if (responseData.status === "success") {
                toast.update(dt, {
                    render: responseData.message,
                    type: "success",
                    isLoading: false,
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                getElasticIp()
                // openDrawer()
            }
            submitBtn.classList.remove("disabled");
            submitBtn.innerHTML = preContent;
        }
    }

    if (dataFetched) {
        return (
            <>
                <div className="col-xl-12">
                    {/*begin::Tables Widget 9*/}
                    <div className="card card-xl-stretch mb-5 mb-xl-12">
                        {/*begin::Header*/}
                        <div className="card-header border-0 pt-5">
                            <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bold fs-3 mb-1">Elastic Ip</span>
                            </h3>
                            <div className="card-toolbar">
                                <div
                                    className="position-relative my-1"
                                    style={{ marginRight: 20 }}
                                >
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        data-kt-table-widget-4="search"
                                        id="searchClient"
                                        onChange={(e) => handleSearch(e.target.value)}
                                        placeholder="Search"
                                    />
                                </div>
                                <div style={{ marginRight: 20 }}>
                                    <div className="dataTables_length">
                                        <label className="w-125px">
                                            <select
                                                onChange={(e) => handleItemsPerPageChange(e.target.value)}
                                                //data-control="select2"
                                                className="form-select form-select-sm form-select-solid"
                                                defaultValue={5}
                                                id="perPage"
                                            >
                                                <option value={5} selected="">
                                                    5 Records
                                                </option>
                                                <option value={10}>10 Records</option>
                                                <option value={25}>25 Records</option>
                                                <option value={50}>50 Records</option>
                                                <option value={100}>100 Records</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>
                                <AttachElasticIp getElasticIp={getElasticIp} />
                            </div>
                        </div>
                        {/*end::Header*/}
                        {/*begin::Body*/}
                        {/*begin::Table container*/}
                        <div className="table-responsive">
                            {/*begin::Table*/}
                            <table
                                id="vpcTable"
                                className="table table-striped table-row-gray-300 align-middle gs-0 gy-4"
                            >
                                {/*begin::Table head*/}
                                <thead className="bg-secondary">
                                    <tr className="fw-bold ">
                                        {/* <th className="w-20px p-3" /> */}
                                        <th className="w-250px text-start ps-9">Elastic ID</th>
                                        <th className="w-100px text-center">IP</th>
                                        <th className="w-100px text-center">IP Type</th>
                                        <th className="w-100px text-center">Network Type</th>
                                        <th className="w-100px text-center"></th>
                                    </tr>
                                </thead>
                                {/*end::Table head*/}
                                {/*begin::Table body*/}
                                <tbody id="mainTable">
                                    {dummySubnetDataAll != null ? (
                                        <>
                                            {dummySubnetDataAll.length != 0 ? (
                                                <>
                                                    <PaginatedItems
                                                        ProductData={dummySubnetDataAll}
                                                        itemsPerPage={perPage}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <tr>
                                                        <td colspan={12}>
                                                            <div className="card-body p-0 mt-10">
                                                                {/*begin::Illustration*/}
                                                                <div className="text-center px-4 mt-10">
                                                                    <span className="mw-100 mh-300px">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="234px" height="234px" fill="currentColor" class="bi bi-hdd-network-fill" viewBox="0 0 16 16">
                                                                            <path d="M2 2a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h5.5v3A1.5 1.5 0 0 0 6 11.5H.5a.5.5 0 0 0 0 1H6A1.5 1.5 0 0 0 7.5 14h1a1.5 1.5 0 0 0 1.5-1.5h5.5a.5.5 0 0 0 0-1H10A1.5 1.5 0 0 0 8.5 10V7H14a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zm.5 3a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1m2 0a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1" />
                                                                        </svg>
                                                                    </span>
                                                                </div>
                                                                {/*end::Illustration*/}
                                                                {/*begin::Wrapper*/}
                                                                <div className="card-px text-center py-5 mb-20">
                                                                    {/*begin::Title*/}
                                                                    <h2 className="fs-1x fw-bolder mb-5 mt-5 text-muted">
                                                                        Take full control of your network by building custom routes within your VPC.
                                                                    </h2>
                                                                    {/*end::Title*/}
                                                                    {/*begin::Description*/}
                                                                    <p className="text-gray-400 fs-4 fw-bold mb-10">
                                                                        Use Utho's VPC routing features to create and manage routes tailored to your needs.
                                                                    </p>
                                                                    {/*end::Description*/}
                                                                    {/*begin::Action*/}
                                                                    <a className="btn btn-sm btn-primary me-2" onClick={allocateIp} id="">
                                                                        {/*begin::Svg Icon | path: assets/media/icons/duotune/arrows/arr009.svg*/}
                                                                        <span className="svg-icon svg-icon-muted svg-icon-1hx d-none">
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width={24}
                                                                                height={24}
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                            >
                                                                                <path
                                                                                    opacity="0.3"
                                                                                    d="M21 13H3C2.4 13 2 12.6 2 12C2 11.4 2.4 11 3 11H21C21.6 11 22 11.4 22 12C22 12.6 21.6 13 21 13Z"
                                                                                    fill="black"
                                                                                />
                                                                                <path
                                                                                    d="M12 22C11.4 22 11 21.6 11 21V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V21C13 21.6 12.6 22 12 22Z"
                                                                                    fill="black"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        {/*end::Svg Icon*/}
                                                                        <AttachElasticIp getElasticIp={getElasticIp} />
                                                                    </a>
                                                                    {/*end::Action*/}
                                                                </div>
                                                                {/*end::Wrapper*/}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <tr>
                                                <td colspan={12}>
                                                    <TableLoader />
                                                    <TopBarHeaderLine />
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                </tbody>
                                {/*end::Table body*/}
                            </table>
                            {/*end::Table*/}
                        </div>
                        {/*end::Table container*/}
                        {/*begin::Body*/}
                    </div>
                    {/*end::Tables Widget 9*/}
                </div>
            </>
        );
    } else {
        return (<>
            <TableLoader />
            <TopBarHeaderLine />
        </>)
    }
}
