// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GOOGLE button {
    background-color: rgb(255, 255, 255);
    display: inline-flex;
  
    align-items: center;
    color: rgba(0, 0, 0, 0.54);
    box-shadow: 0px !important;
    padding: 0px;
    border-radius: 2px;
    border: 0px solid transparent !important;
    font-size: 0.9vw;
    font-weight: 500;
    font-family: Roboto, sans-serif;
  }

  button.githubbutton.btn {
    position: relative;
    top: -0.7rem;
    margin-left: 24px;
}
  button.GOOGLE div {
    background-color: #f94033 !important;
    display: none;
    border-radius: 25px;
  }
  
  .GOOGLE {
    background-color: #f94033 !important;
    width: 20vw;
    margin-bottom: 10px;
    height: 4.5vh;
    text-align: center !important;
    border-radius: 25px !important;
    border: 0 !important;
  }
  .GOOGLE span {
    font-size: 0.9vw !important;
    color: #eaebed;
    /* font-family: Helvetica,sans-serif !important; */
    font-weight: 600 !important;
  
    margin: auto;
  }`, "",{"version":3,"sources":["webpack://./src/App/Auth/authComponents/Login/google/GoogleLogin.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,oBAAoB;;IAEpB,mBAAmB;IACnB,0BAA0B;IAC1B,0BAA0B;IAC1B,YAAY;IACZ,kBAAkB;IAClB,wCAAwC;IACxC,gBAAgB;IAChB,gBAAgB;IAChB,+BAA+B;EACjC;;EAEA;IACE,kBAAkB;IAClB,YAAY;IACZ,iBAAiB;AACrB;EACE;IACE,oCAAoC;IACpC,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,oCAAoC;IACpC,WAAW;IACX,mBAAmB;IACnB,aAAa;IACb,6BAA6B;IAC7B,8BAA8B;IAC9B,oBAAoB;EACtB;EACA;IACE,2BAA2B;IAC3B,cAAc;IACd,kDAAkD;IAClD,2BAA2B;;IAE3B,YAAY;EACd","sourcesContent":[".GOOGLE button {\n    background-color: rgb(255, 255, 255);\n    display: inline-flex;\n  \n    align-items: center;\n    color: rgba(0, 0, 0, 0.54);\n    box-shadow: 0px !important;\n    padding: 0px;\n    border-radius: 2px;\n    border: 0px solid transparent !important;\n    font-size: 0.9vw;\n    font-weight: 500;\n    font-family: Roboto, sans-serif;\n  }\n\n  button.githubbutton.btn {\n    position: relative;\n    top: -0.7rem;\n    margin-left: 24px;\n}\n  button.GOOGLE div {\n    background-color: #f94033 !important;\n    display: none;\n    border-radius: 25px;\n  }\n  \n  .GOOGLE {\n    background-color: #f94033 !important;\n    width: 20vw;\n    margin-bottom: 10px;\n    height: 4.5vh;\n    text-align: center !important;\n    border-radius: 25px !important;\n    border: 0 !important;\n  }\n  .GOOGLE span {\n    font-size: 0.9vw !important;\n    color: #eaebed;\n    /* font-family: Helvetica,sans-serif !important; */\n    font-weight: 600 !important;\n  \n    margin: auto;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
