import React, { useEffect } from 'react'
import ReactPaginate from 'react-paginate';
import ProductInfo from './ProductInfo';

export default function PaginatedItems(props) {

    const handlePageClick = (event) => {
        var matched = props.props.paginationInfo[0].currentPage === (parseInt(event.selected + 1));
        if (props?.updatePaginationData) {
            props.props.paginationInfo[0].currentPage = event.selected + 1;
            if (!matched) {
                props.setCloudData(null);
                props.getCloud();
            }
        }
    };

    useEffect(() => {
    }, [props.ProductData])


    if (props.metaData?.totalpages) {
        return (
            <>
                <ProductInfo productdata={props.ProductData} getCloud={props.getCloud} />
                {/* {props.ProductData.length > 5 && ( */}
                    <td colspan="12">
                        <ReactPaginate
                            nextLabel="next >"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            pageCount={props.metaData.totalpages}
                            previousLabel="< previous"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                            initialPage={props.props.paginationInfo[0].currentPage - 1}
                        />
                    </td>
                {/* )} */}
            </>
        );
    }

}
